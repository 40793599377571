import React from 'react';
import SumologicData from './sumologicData/SumologicData';
import SumologicDimensionsAndMetrics from './sumologicDimensionsAndMetrics/SumologicDimensionsAndMetrics';
import SumologicSchedular from './sumologicSchedular/SumologicSchedular';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';
import './SumologicEditor.module.scss';

const SumologicEditor = () => (
  <div className="shell-col" automation-id="mixpanelEditorSteps">
    <StreamEditorSteps>
      <SumologicData />
      <SumologicDimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <SumologicSchedular />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default SumologicEditor;
