// @flow
import React from 'react';
import {connect} from 'react-redux';
import SchedularDateRange from 'bc/components/streams/editor/common/SchedularDateRange';
import * as selectors from 'bc/store/selectors';
import * as commonSelectors from 'profile/store/selectors';
import {bcTypes} from 'bc/services/bcTypes';
import {
  excludeTemplatesNames,
  resolutionsShort,
  getAllowedRangeTypesByPollingInterval,
} from 'bc/services/dataStreamService';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import {
  applyTemplateOnSelectedStream as applyTemplateOnSelectedStreamAction,
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
} from 'bc/store/actions';
import GaMaxDelay from './GaMaxDelay';
import GaResolution from './GaResolution';
import './SchedulerEditor.module.scss';

type PropTypes = {
  pollingInterval: String,
  pollingResolution: String,
  historicalDateRange: String,
  timeZoneName: String,
  delayMinutes: Number,
  maxGoldenDelayMinutes: Number,
  type: String,
  templates: Array,
  setSelectedStreamKeyVal: Function,
  applyTemplateOnSelectedStream: Function,
};

class SchedulerEditor extends React.PureComponent {
  props: PropTypes;

  componentDidUpdate(previous) {
    const {
      pollingInterval,
      delayMinutes,
      templates,
      setSelectedStreamKeyVal,
      applyTemplateOnSelectedStream,
    } = this.props;

    if (pollingInterval === 'm15' || pollingInterval === 'm30') {
      if (previous.pollingInterval !== 'm15' && previous.pollingInterval !== 'm30') {
        const templatesArr = templates.filter((item) => excludeTemplatesNames.indexOf(item.name) === -1);
        applyTemplateOnSelectedStream(templatesArr[0].id);
      }
      if (delayMinutes > 90) {
        setSelectedStreamKeyVal({delayMinutes: 90});
      }
    } else if (delayMinutes % 60 !== 0) {
      setSelectedStreamKeyVal({delayMinutes: Math.floor(delayMinutes / 60)});
    }
  }

  setResolutionToStream = (res) =>
    bcTypes.google_analytics.type === this.props.type
      ? this.props.setSelectedStreamKeyVal({
          pollingResolution: res === 'm30' || res === 'm15' ? null : res,
          pollingInterval: resolutionsShort[res].defaultPolling,
        })
      : this.props.setSelectedStreamKeyVal({pollingResolution: res});

  valueChanged = (e) => {
    let val = e.target.value !== '' ? parseInt(e.target.value, 10) * 60 : null;

    if (parseInt(e.target.value, 10) <= 0) {
      val = 0;
    } else if (parseInt(e.target.value, 10) > 48) {
      val = 2880;
    }
    if (this.props.maxGoldenDelayMinutes !== null && val > this.props.maxGoldenDelayMinutes && val <= 2880) {
      this.props.setSelectedStreamKeyVal({
        delayMinutes: val,
        maxGoldenDelayMinutes: val,
      });
    } else {
      this.props.setSelectedStreamKeyVal({delayMinutes: val});
    }
  };

  valueChangedMinutes = (e) => {
    let val = e.target.value !== '' ? parseInt(e.target.value, 10) : null;

    if (parseInt(e.target.value, 10) <= 0) {
      val = 0;
    } else if (parseInt(e.target.value, 10) > 90) {
      val = 90;
    }

    if (this.props.maxGoldenDelayMinutes !== null && val > this.props.maxGoldenDelayMinutes && val <= 2880) {
      this.props.setSelectedStreamKeyVal({
        delayMinutes: val,
        maxGoldenDelayMinutes: val,
      });
    } else {
      this.props.setSelectedStreamKeyVal({delayMinutes: val});
    }
  };

  maxDelayValueChanged = (val) => {
    this.props.setSelectedStreamKeyVal({maxGoldenDelayMinutes: val});
  };

  render() {
    return (
      <div styleName="root">
        <PanelHeader title="Historical & Live time properties" />
        <div styleName="inner-root">
          <div styleName="item">
            <span className="ellipsis" styleName="item-title">
              Historical & Live timescale
            </span>
            <div styleName="item-width">
              <GaResolution
                resolution={this.props.pollingResolution}
                interval={this.props.pollingInterval}
                dateRange={this.props.historicalDateRange}
                onChange={(v) => this.setResolutionToStream(v)}
              />
            </div>
          </div>

          <div styleName="item">
            <span className="ellipsis" styleName="item-title">
              Historical time span
            </span>
            <div styleName="item-width">
              <SchedularDateRange
                dateRange={this.props.historicalDateRange}
                title="Time span"
                isUnix
                allowedRangeTypes={getAllowedRangeTypesByPollingInterval(this.props.pollingInterval)}
                timeZoneName={this.props.timeZoneName}
                onChange={(v) => this.props.setSelectedStreamKeyVal({historicalDateRange: v})}
              />
            </div>
          </div>
        </div>
        <div styleName="inner-root">
          {this.props.pollingInterval === 'm15' || this.props.pollingInterval === 'm30' ? (
            <div styleName="item collect-on">
              <span className="ellipsis" styleName="item-title">
                Minimal Delay (Minutes)
              </span>
              <span styleName="item-description">Minimum 0 (No delay), maximum 90 minutes</span>
              <input
                type="number"
                styleName={this.props.delayMinutes < 0 || this.props.delayMinutes > 90 ? 'error' : ''}
                onChange={this.valueChangedMinutes}
                min="0"
                max="90"
                value={this.props.delayMinutes}
              />
            </div>
          ) : (
            <div styleName="item collect-on">
              <span className="ellipsis" styleName="item-title">
                Minimal Delay (Hours)
              </span>
              <span styleName="item-description">Minimum 0 (No delay), maximum 48 hours</span>
              <input
                type="number"
                styleName={this.props.delayMinutes < 0 || this.props.delayMinutes > 2880 ? 'error' : ''}
                onChange={this.valueChanged}
                min="0"
                max="48"
                value={this.props.delayMinutes / 60}
              />
            </div>
          )}

          <div styleName="item">
            <span className="ellipsis" styleName="item-title">
              Maximal Delay
            </span>
            <span styleName="item-description">How long should we wait for Golden data</span>
            <div styleName="item-width">
              <GaMaxDelay
                minValueAllowed={this.props.delayMinutes}
                maxGoldenDelayMinutes={this.props.maxGoldenDelayMinutes}
                onChange={this.maxDelayValueChanged}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    pollingInterval: selectors.getSelectedDataStream(state).pollingInterval,
    pollingResolution: selectors.getSelectedDataStream(state).pollingResolution,
    historicalDateRange: selectors.getSelectedDataStream(state).historicalDateRange,
    delayMinutes: selectors.getSelectedDataStream(state).delayMinutes,
    maxGoldenDelayMinutes: selectors.getSelectedDataStream(state).maxGoldenDelayMinutes,
    timeZoneName: commonSelectors.getTimeZoneName(state),
    type: selectors.getSelectedDataStream(state).type,
    isGAShortIntervalEnabled: commonSelectors.getIsGAShortIntervalEnabled(state),
    templates: selectors.getGoogleAnalyticsTemplatesItems(state),
  }),
  {
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
    applyTemplateOnSelectedStream: applyTemplateOnSelectedStreamAction,
  },
)(SchedulerEditor);
