// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import * as filterSelector from 'alerts.management/store/filterSelector';

const EMPTY_ARRAY = [];

type PropTypes = {
  setQueryParams: Function,

  // connect
  severityList?: Array,
  selectedSeverity: Array,
};

export default connect(
  (state) => ({
    severityList: filterSelector.getFilterSeverityWithCount(state),
    selectedSeverity: filterSelector.getSelectedFilterSeverity(state),
  }),
  {},
)(
  class SeverityFilter extends React.PureComponent {
    props: PropTypes;

    static defaultProps = {
      severityList: EMPTY_ARRAY,
    };

    onChange = (items) => {
      const severityVal = items.length ? items.map((item) => item.value).join(',') : undefined;
      this.props.setQueryParams({severity: severityVal});
    };

    render() {
      const {severityList, selectedSeverity} = this.props;
      return (
        <SelectAndt
          automationId="alertSeverityFilter"
          type={TYPE_NEW_MULTI_NO_SEARCH}
          theme={THEME_HIGHLIGHTED}
          onChange={this.onChange}
          getOptionLabel={(val) => val.text}
          getOptionValue={(val) => val.value}
          value={selectedSeverity}
          options={severityList}
          isMulti
          optionHeight={40}
          isClearable={false}
          closeMenuOnSelect={false}
          menuWidth={155}
          placeholder="Severity"
          noAutoReorder
        />
      );
    }
  },
);
