// @flow
import React, {Fragment} from 'react';
import DataManagerNew from 'bc/pages/DataManagerNew';

type PropTypes = {};

export default class DataManagerRouter extends React.Component {
  props: PropTypes;

  render() {
    return (
      <Fragment>
        <DataManagerNew {...this.props} />
      </Fragment>
    );
  }
}
