// @flow
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import 'moment-timezone';

import * as dateRangeService from 'common/utils/dateRangeService';
import {RANGE_TYPES} from 'dataCollectors/services/scheduleService';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';

const datesWithoutCustom = Object.values(RANGE_TYPES).filter((r) => [RANGE_TYPES.c.key].indexOf(r.key) < 0);
const datesWithCustom = Object.values(RANGE_TYPES);

export default function DateRange({
  allowedRangeTypes,
  isUnix,
  timeZoneName,
  onChange,
  isLoading,
  selectedValue,
  isCustomAllowed,
}) {
  const [preDefinedDatesState, setPreDefinedDatesState] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const onSelect = (key) => {
    onChange(dateRangeService.getDateValue({constRange: key}, isUnix, timeZoneName));
  };

  const getPredefinedDateRange = () => {
    if (allowedRangeTypes && allowedRangeTypes.length) {
      const dates = isCustomAllowed ? datesWithCustom : datesWithoutCustom;

      setPreDefinedDatesState(dates.filter((r) => allowedRangeTypes.indexOf(r.value) !== -1));
    }
  };

  useEffect(() => {
    if (allowedRangeTypes && allowedRangeTypes.length) {
      getPredefinedDateRange();
    }
  }, [allowedRangeTypes]);

  useEffect(() => {
    const filteredOptions = isCustomAllowed
      ? preDefinedDatesState
      : preDefinedDatesState.filter((item) => item.key !== 'c');

    setOptions(filteredOptions);
  }, [preDefinedDatesState]);

  useEffect(() => {
    const index = options.findIndex(
      (v) =>
        v.key === selectedValue.constRange || v.id === selectedValue.constRange || v.value === selectedValue.constRange,
    );
    setSelectedItem(index > -1 ? options[index] : options[0]);
  }, [options, selectedValue]);

  return (
    <>
      <FormDdlSelect
        placement="top"
        isDisabledSorting
        options={options}
        selected={selectedItem}
        onChange={(val) => onSelect(val.key)}
        button={
          <BigDropdownButton
            isPristine={!selectedItem}
            label={selectedItem?.label}
            placeholder={isLoading ? 'Loading..' : 'Select Date range'}
            shortBlueStyle
            isFullWidth
          />
        }
        optionComponent={<OptionComponentSimple />}
        width={240}
        height={260}
      />
    </>
  );
}

DateRange.defaultProps = {
  timeZoneName: 'UTC',
};

DateRange.propTypes = {
  allowedRangeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  isUnix: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedValue: PropTypes.objectOf(PropTypes.string).isRequired,
  isCustomAllowed: PropTypes.bool.isRequired,
  timeZoneName: PropTypes.string,
};
