// @flow
import React, {useEffect, useState, useCallback, Fragment, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useQueryParams} from 'use-query-params';
import {isEmpty} from 'lodash';
import {Box} from '@material-ui/core';
import {
  getTMLeftPanelConstRange,
  getTMLeftPanelIssuesType,
  getTMLeftPanel,
  getTMLeftPanelTabId,
  getTMLeftPanelSelectedIssueId,
  getTMFetchTopologyIssuesIsLoading,
  getTMLeftPanelSelectedIssue,
  getTMFetchTopologySpecialIssuesIsLoading,
  getTMLeftPanelSpecialIssue,
  getTMFetchTopologyAlert,
  getTMFetchTopologyAnomaly,
  getTMLeftPanelSearchSelectedItemId,
} from 'topologyLeaflet/store/selectors';
import {
  getTopologyAnomalies,
  getTopologyAlerts,
  setMapBoundsIssueClicked,
  metricItemClicked,
  setIntoLeftPanelView,
  getTopologySpecialIssue,
  clearSpecialIssue,
  setMapBoundsSearchAssetClicked,
} from 'topologyLeaflet/store/actions';
import {segmentClickEvent} from 'common/store/actions';
import {getDateValue} from 'common/utils/dateRangeService';
import SmartTooltip from 'common/componentsV2/Tooltip';
import IssuesPanel from 'topologyLeaflet/components/leftPanel/IssuesPanel';
import SelectedIssuePanel from 'topologyLeaflet/components/leftPanel/SelectedIssuePanel';
import TabsBar from 'topologyLeaflet/components/common/TabsBar';
import TPSearchPanel from 'topologyLeaflet/components/leftPanel/TPSearchPanel';
import {QUERY_PARAM_MAP, PANEL_TABS, PANEL_SIZES} from 'topologyLeaflet/services/leftPanelService';
import './LeftPanel.module.scss';
import {palette} from 'app/styles/theme';

type PropTypes = {
  mapRef: Object,
};

const LeftPanel = ({mapRef}: PropTypes) => {
  const dispatch = useDispatch();
  const constRange = useSelector(getTMLeftPanelConstRange);
  const selectedIssueId = useSelector(getTMLeftPanelSelectedIssueId);
  const searchSelectedItemId = useSelector(getTMLeftPanelSearchSelectedItemId);
  const issuesType = useSelector(getTMLeftPanelIssuesType);
  const leftPanel = useSelector(getTMLeftPanel);
  const tabId = useSelector(getTMLeftPanelTabId);
  const isIssuesListLoading = useSelector(getTMFetchTopologyIssuesIsLoading);
  const isSpecailIssueLoading = useSelector(getTMFetchTopologySpecialIssuesIsLoading);
  const specialIssue = useSelector(getTMLeftPanelSpecialIssue);
  const selectedIssue = useSelector(getTMLeftPanelSelectedIssue);
  const specialIssueAlertCall = useSelector(getTMFetchTopologyAlert);
  const specialIssueAnomalyCall = useSelector(getTMFetchTopologyAnomaly);
  const [showTabBar, setShowTabBar] = useState(true);
  const [hover, setHover] = useState(false);
  const [minimized, setMinimized] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);

  useEffect(() => {
    dispatch(setMapBoundsIssueClicked({map: mapRef}));
  }, [selectedIssueId]);

  useEffect(() => {
    dispatch(setMapBoundsSearchAssetClicked({map: mapRef}));
  }, [searchSelectedItemId]);

  useEffect(() => {
    const newLeftPanelState = {};
    Object.keys(queryParams).forEach((key) => {
      if (queryParams[key] !== leftPanel[key]) {
        newLeftPanelState[key] = queryParams[key];
        if (key === 'selectedIssueId') {
          dispatch(clearSpecialIssue());
        }
      }
    });

    if (!isEmpty(newLeftPanelState)) {
      dispatch(setIntoLeftPanelView(newLeftPanelState));
    }
  }, [queryParams]);

  useEffect(() => {
    const dateRange = getDateValue(queryParams, true);

    if (issuesType === 'anomalies') {
      dispatch(getTopologyAnomalies({startDate: dateRange.startDate, endDate: dateRange.endDate}));
    }
    if (issuesType === 'alerts') {
      dispatch(getTopologyAlerts({startTime: dateRange.startDate, endTime: dateRange.endDate}));
    }
  }, [
    queryParams.constRange,
    queryParams.startDate,
    queryParams.endDate,
    queryParams.relativeLast,
    queryParams.relativeNext,
    issuesType,
  ]);

  useEffect(() => {
    setShowTabBar(!selectedIssueId);
  }, [selectedIssueId]);

  useEffect(() => {
    if (
      selectedIssueId &&
      !isIssuesListLoading &&
      leftPanel.issues &&
      !selectedIssue &&
      !specialIssue &&
      !isSpecailIssueLoading &&
      ((issuesType === 'anomalies' &&
        isEmpty(specialIssueAnomalyCall.data) &&
        specialIssueAnomalyCall.error === undefined) ||
        (issuesType === 'alerts' && isEmpty(specialIssueAlertCall.data) && specialIssueAlertCall.error === undefined))
    ) {
      dispatch(
        getTopologySpecialIssue({
          constRange,
          issuesType,
          selectedIssueId,
        }),
      );
    }
  }, [selectedIssueId, isIssuesListLoading, selectedIssue, leftPanel.issues, isSpecailIssueLoading, specialIssue]);

  const minimizedHeight = useMemo(() => (queryParams.selectedIssueId ? 84 : 56), [queryParams.selectedIssueId]);

  const tabClicked = useCallback((tabIdLoc) => {
    setMinimized(false);
    setQueryParams({tabId: tabIdLoc});
    dispatch(segmentClickEvent({category: 'topology', name: `panel-${tabIdLoc}`}));
  }, []);

  const onIssueItemClicked = () => {
    dispatch(segmentClickEvent({category: 'topology', name: `click-${issuesType}-issue-item`}));
  };

  const onSelectedIssueBackClick = () => {
    setMinimized(false);
    setQueryParams({selectedIssueId: undefined});
    dispatch(setIntoLeftPanelView({specialIssue: null}));
  };

  const onSelectedMetricItemClick = (item) => {
    dispatch(metricItemClicked({map: mapRef, metricItem: item}));
    dispatch(segmentClickEvent({category: 'topology', name: 'click-metric-item'}));
  };

  return (
    <Fragment>
      <div styleName="outer-layer">
        <div styleName="inner-layer" onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
          <SmartTooltip placement="right" content={minimized ? 'Expand' : 'Collapse'} delayHide={0}>
            <div styleName={`minimize-btn ${hover ? 'show' : ''}`} onClick={() => setMinimized(!minimized)}>
              <i className={`icon icn-arrow16-chevron${minimized ? 'down' : 'up'}`} />
            </div>
          </SmartTooltip>
        </div>
      </div>
      <Box
        styleName="root-left-panel"
        css={minimized ? {height: `${minimizedHeight}px`} : {}}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {showTabBar ? (
          <Box
            display="flex"
            width={PANEL_SIZES.tinyScrollBox}
            height={48}
            borderBottom={`1px solid ${palette.gray[300]}`}
            pt={2}
            mb={1}
          >
            <TabsBar tabs={Object.values(PANEL_TABS)} selectedTabId={tabId} onTabClick={tabClicked} />
          </Box>
        ) : null}
        <Box
          display="block"
          top={showTabBar ? 55 : 16}
          bottom={0}
          width={PANEL_SIZES.tinyScrollBox}
          position="absolute"
        >
          <Box
            component="span"
            display={tabId === PANEL_TABS.issues.id && !queryParams.selectedIssueId ? 'block' : 'none'}
            position="absolute"
            top={0}
            bottom={0}
          >
            <IssuesPanel onIssueItemClick={onIssueItemClicked} setQueryParams={setQueryParams} />
          </Box>
          <Box
            component="span"
            display={tabId === PANEL_TABS.search.id && !queryParams.selectedIssueId ? 'block' : 'none'}
          >
            <TPSearchPanel setQueryParams={setQueryParams} />
          </Box>
          <Box
            component="span"
            display={queryParams.selectedIssueId ? 'block' : 'none'}
            position="absolute"
            top={0}
            bottom={0}
            right={0}
            left={0}
          >
            <SelectedIssuePanel
              onBackClick={onSelectedIssueBackClick}
              onMetricItemClick={onSelectedMetricItemClick}
              isLoading={isIssuesListLoading || isSpecailIssueLoading}
              isHideInvestigationPanel={minimized}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default LeftPanel;
