import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MinimizingModal from 'common/componentsV2/modal/MinimizingModal';
import {getIsTimelineEnabled, getStaticNoDataTriageEnabled} from 'profile/store/selectors';
import {copyToClipBoard} from 'common/store/actions';
import {
  setIsOpen,
  setIsMinimize,
  setInvestigationSettings,
  setIncidentFilterNewAlertConsole,
} from 'investigationNewAlertConsole/store/actions';
import {getIsOpen, getIsMinimize} from 'investigationNewAlertConsole/store/selectors';
import {
  ALERT_TYPES,
  INVESTIGATION_MODAL_TABS,
  modalRouting,
  getInvestigationModalTabs,
} from 'investigationNewAlertConsole/services/investigationService';
import {StringParam, BooleanParam, useQueryParams} from 'use-query-params';
import fetchGroupAlertsWithMetricsProvider from 'investigationNewAlertConsole/api/fetchGroupAlertsWithMetricsProvider';
import {makeStyles} from '@material-ui/core/styles';
import {setMeasures} from 'investigationNewAlertConsole/api/utils';
import InvModalHeader from 'investigationNewAlertConsole/components/header/InvModalHeader';
import InvTabsBar from 'investigationNewAlertConsole/components/tabs/InvTabsBar';
import OverviewTab from 'investigationNewAlertConsole/components/overview/OverviewTab';
import IncidentTab from 'investigationNewAlertConsole/components/incident/IncidentTab';
import CorrelationsTab from 'investigationNewAlertConsole/components/correlations/CorrelationsTab';
import TimelineTab from 'investigationNewAlertConsole/components/timeline/TimelineTab';
import TimelineTabIncremented from 'investigationNewAlertConsole/components/timeline/TimelineTabIncremented';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';
import MinimizeTitle from 'investigationNewAlertConsole/pages/MinimizeTitle';

const useStyles = makeStyles(() => ({
  tabsContainer: {
    paddingRight: 20,
    paddingLeft: 20,
  },
  tabsBarContainer: {
    marginTop: 12,
    marginBottom: 12,
  },
  loader: {
    position: 'absolute',
    top: '20%',
    left: '40%',
  },
}));

const InvestigationModal = () => {
  const classes = useStyles();
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsOpen);
  const isMinimize = useSelector(getIsMinimize);
  const isTimelineEnabled = useSelector(getIsTimelineEnabled);
  const isStaticNoDataTriageEnabled = useSelector(getStaticNoDataTriageEnabled);

  const [queryParams, setQueryParams] = useQueryParams({
    [modalRouting.ANOMALY_ID]: StringParam,
    [modalRouting.TRIGGER_ID]: StringParam,
    [modalRouting.INVESTIGATION_MODAL]: BooleanParam,
  });
  const anomalyId = queryParams.anomalyIdInv;
  const triggerId = queryParams.triggerIdInv;

  const triggeredAlertResult = fetchGroupAlertsWithMetricsProvider(queryParams)?.useQueryGetGroupAlertsWithMetrics();
  const isTriggeredAlertLoading = triggeredAlertResult?.isLoading;
  const alertsGroup = fetchGroupAlertsWithMetricsProvider(queryParams)?.useQueryGetGroupAlertsWithMetrics()?.data;

  const trigger = useMemo(() => {
    let tmpTrigger;
    if (triggeredAlertResult?.data?.alerts?.length > 0) {
      tmpTrigger = triggeredAlertResult.data.alerts.find(
        (alert) => alert.alertTriggerId === queryParams?.[modalRouting.TRIGGER_ID],
      );
    }
    return tmpTrigger;
  }, [JSON.stringify(triggeredAlertResult?.data?.alerts), queryParams?.[modalRouting.TRIGGER_ID]]);

  const usePreviousValue = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previousTriggerId = usePreviousValue(triggerId);
  const previousAnomalyId = usePreviousValue(anomalyId);

  // Param used to make new design work. If it is decided to be deprecated, please, clear it from server as well
  // const useTimelineIncremented = useCallback(getEnableNewTimelineDesign);

  const [selectedTabId, setSelectedTabId] = useState(INVESTIGATION_MODAL_TABS.overview.id);
  const [availableTabs, setAvailableTabs] = useState([]);

  const [minimizingTitle, setMinimizingTitle] = useState();

  const checkFF = () => {
    const modalTabs = getInvestigationModalTabs(trigger?.type);
    return Object.values(modalTabs).map((tab) => {
      if (tab.id === INVESTIGATION_MODAL_TABS.timeline.id) {
        return {...tab, isEnabled: isTimelineEnabled};
      }
      return tab;
    });
  };

  const cleanQueryParams = () => {
    if (
      queryParams[modalRouting.ANOMALY_ID] ||
      queryParams[modalRouting.TRIGGER_ID] ||
      queryParams[modalRouting.INVESTIGATION_MODAL]
    ) {
      setQueryParams({
        [modalRouting.ANOMALY_ID]: undefined,
        [modalRouting.TRIGGER_ID]: undefined,
        [modalRouting.INVESTIGATION_MODAL]: undefined,
      });
    }
  };

  const setQueryParamsToPreviousState = () => {
    if (
      !queryParams[modalRouting.ANOMALY_ID] &&
      !queryParams[modalRouting.TRIGGER_ID] &&
      !queryParams[modalRouting.INVESTIGATION_MODAL]
    ) {
      setQueryParams({
        [modalRouting.ANOMALY_ID]: previousAnomalyId,
        [modalRouting.TRIGGER_ID]: previousTriggerId,
        [modalRouting.INVESTIGATION_MODAL]: true,
      });
    }
  };

  useEffect(() => {
    firstUpdate.current = false;
  }, []);

  useEffect(() => {
    if (trigger) {
      setAvailableTabs(checkFF());
    }
  }, [isTimelineEnabled, trigger]);

  // fetch tokenMapIncident
  useEffect(() => {
    if (trigger?.type === ALERT_TYPES.ANOMALY) {
      dispatch(
        setIncidentFilterNewAlertConsole(undefined, {
          groupId: anomalyId,
          timeScale: trigger?.timeScale,
          what: trigger?.metrics?.length ? trigger?.metrics[0].what : '',
          alertMeasures: setMeasures(trigger?.metrics),
        }),
      );
    }
  }, [trigger]);

  const onClose = () => {
    dispatch(setIsOpen(false));
    cleanQueryParams();
    setSelectedTabId(INVESTIGATION_MODAL_TABS.overview.id);
  };

  const onMinimize = () => {
    const newIsMinimize = !isMinimize;
    dispatch(setIsMinimize(newIsMinimize));
    if (newIsMinimize) {
      setMinimizingTitle(trigger ? trigger?.title : 'Loading trigger...');
      cleanQueryParams();
    } else {
      setQueryParamsToPreviousState();
    }
  };

  const onShareClicked = () => {
    const text = `${
      window.location.href.split('?')[0]
    }?anomalyIdInv=${anomalyId}&triggerIdInv=${triggerId}&investigationModal=1`;
    dispatch(
      copyToClipBoard({
        event: {},
        text,
        description:
          'You can send this link to anyone who has an Anodot account with the sufficient permission to view this data',
        title: 'Link to this investigation copied',
      }),
    );
  };

  const onCloseBalloon = useCallback((tabName, operation) => {
    dispatch(setInvestigationSettings(true, {tabName, operation}));
  }, []);

  const tabClicked = useCallback((tabId) => {
    setSelectedTabId(tabId);
  }, []);

  const overviewInfoCubeClicked = useCallback((tabId) => {
    if (tabId) {
      setSelectedTabId(tabId);
    }
  }, []);

  const selectedTabComponent = useMemo(() => {
    switch (selectedTabId) {
      case INVESTIGATION_MODAL_TABS.overview.id:
        return (
          <OverviewTab
            onCubeClick={overviewInfoCubeClicked}
            onShareClicked={onShareClicked}
            onCloseBalloon={onCloseBalloon}
            trigger={trigger}
            isLoading={isTriggeredAlertLoading}
          />
        );
      case INVESTIGATION_MODAL_TABS.incident.id:
        return <IncidentTab trigger={trigger} groupId={anomalyId} isTriggerLoading={isTriggeredAlertLoading} />;
      case INVESTIGATION_MODAL_TABS.correlations.id:
        return <CorrelationsTab trigger={trigger} groupId={anomalyId} isTriggerLoading={isTriggeredAlertLoading} />;
      case INVESTIGATION_MODAL_TABS.timeline.id:
        if (isStaticNoDataTriageEnabled) {
          return <TimelineTabIncremented trigger={trigger} groupId={anomalyId} />;
        }
        return <TimelineTab trigger={trigger} groupId={anomalyId} />;
      default:
        return null;
    }
  }, [selectedTabId, trigger, anomalyId, isTriggeredAlertLoading]);

  return (
    <>
      {trigger ? (
        <MinimizingModal
          isOpen={isOpen}
          isMinimize={isMinimize}
          onMinimize={onMinimize}
          onCloseBtnClick={onClose}
          titleWhenMinimize={<MinimizeTitle title={trigger?.title} />}
        >
          <InvModalHeader
            alertsGroup={alertsGroup}
            onShareClicked={onShareClicked}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            selectedTabId={selectedTabId}
          />
          <div className={classes.tabsContainer}>
            <div className={classes.tabsBarContainer}>
              <InvTabsBar tabs={availableTabs} onTabClick={tabClicked} selectedTabId={selectedTabId} />
            </div>
            {selectedTabComponent}
          </div>
        </MinimizingModal>
      ) : (
        <MinimizingModal
          isOpen={isOpen}
          isMinimize={isMinimize}
          onMinimize={onMinimize}
          onCloseBtnClick={onClose}
          titleWhenMinimize={minimizingTitle}
        >
          <div className={classes.loader}>
            <Spinner color={palette.gray[500]} size={SIZES.XX_BIG_150} />
          </div>
        </MinimizingModal>
      )}
    </>
  );
};

export default InvestigationModal;
