/* eslint-disable react/no-find-dom-node */

// @flow

/* This code is probably legacy and won't be active. In case it is, the line inside componentDidMount wasn't properly
tested. It was changed to adequate to the new directives of the use for findDOMNode.

 */
import React, {PureComponent} from 'react';
import {RootCloseWrapper} from 'react-overlays';
import {Transition} from 'react-transition-group';
import {objectiveOffsetOf} from './positioning';

const combinePropsAndStyles = (props0, ...propsX) =>
  propsX.reduce(
    (result, props1) => ({...result, ...props1, style: {...(result.style || {}), ...props1.style}}),
    props0,
  );

class PopoverButton extends PureComponent {
  props: any;

  state = {
    isPopoverVisible: false,
    triggerEl: null,
    alignWithEl: null,
  };

  componentDidMount() {
    //  const alignWithEl = ReactDOM.findDOMNode(this.props.alignwith || this.trigger); // old line
    const alignWithEl = this.props.alignwith || this.trigger;
    this.setState({triggerEl: this.trigger, alignWithEl});
  }

  get popoverProps() {
    const {triggerEl, alignWithEl} = this.state;

    if (!triggerEl) {
      return {};
    }

    const triggerObjectiveOffset = objectiveOffsetOf(triggerEl);
    const dockingObjectiveOffset = objectiveOffsetOf(alignWithEl);
    const relativeOffsetToDocking = {
      left: triggerObjectiveOffset.left - dockingObjectiveOffset.left,
      top: triggerObjectiveOffset.top - dockingObjectiveOffset.top,
    };
    const {widthfactor = 1.0, minwidth} = this.props;

    let maxwidth = alignWithEl.offsetWidth * widthfactor;
    if (minwidth) {
      maxwidth = Math.max(maxwidth, minwidth);
    }

    const positionLeft = 0;

    return {
      positionLeft,
      positionTop: relativeOffsetToDocking.top + triggerEl.offsetHeight,
      arrowOffsetLeft: relativeOffsetToDocking.left - positionLeft + 0.5 * triggerEl.offsetWidth,
      style: {
        maxwidth,
      },
    };
  }

  handleClick = (event) => {
    this.setState({isPopoverVisible: true});
    event.stopPropagation();
  };

  handleRootClose = () => {
    this.setState({isPopoverVisible: false});

    const {onClose} = this.props;
    if (onClose) {
      onClose();
    }
  };

  render() {
    const {popover, title, ...rest} = this.props;
    const {isPopoverVisible} = this.state;

    return (
      <span>
        <button
          type="button"
          onClick={this.handleClick}
          ref={(trigger) => {
            this.trigger = trigger;
          }}
          {...rest}
        >
          {title}
        </button>
        <RootCloseWrapper event="click" disabled={!isPopoverVisible} onRootClose={this.handleRootClose}>
          <Transition
            mountOnEnter
            unmountOnExit
            in={isPopoverVisible}
            enteredclassname="in"
            enteringclassname="in"
            timeout={500}
          >
            {React.cloneElement(popover, combinePropsAndStyles(popover.props, this.popoverProps))}
          </Transition>
        </RootCloseWrapper>
      </span>
    );
  }
}

export default PopoverButton;
