// @flow
import React from 'react';

import './ConceptPanel.module.scss';

const DeselectIcon = () => <div styleName="deselect" className="icon icn-icon-white-close" />;

const ConceptPanel = ({sourceColumn, name, onRemoveItem}: any) => (
  <div styleName="panel" automation-id="diametricsItem">
    <span className="ellipsis">{name}</span>
    <button
      type="button"
      className="btn btn-flat btn-icon btn-blue-light"
      automation-id={`remove-item-${name}`}
      onClick={() => setTimeout(() => onRemoveItem(sourceColumn), 0)}
    >
      <DeselectIcon />
    </button>
  </div>
);

export default ConceptPanel;
