// @flow
import React, {useEffect, useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useQueryParams} from 'use-query-params';
import D3TopologyMap from 'topologyLeaflet/components/D3TopologyMap';
import LeftPanel from 'topologyLeaflet/components/leftPanel/LeftPanel';
import * as mapService from 'topologyLeaflet/services/tpMapService';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {segmentClickEvent} from 'common/store/actions';
import {fetchTopologyMap} from 'topologyLeaflet/store/actions';
import {palette} from 'app/styles/theme';
import {
  getProcessedNodes,
  getProcessedLinks,
  getTMFilterOptions,
  getProcessedRingLinks,
  getProcessedRings,
  getTMFetchTopologyMapIsLoading,
  getTMFetchTopologyMapDataDate,
  getTMLeftPanelSearchSelectedAsset,
} from 'topologyLeaflet/store/selectors';
import {QUERY_PARAM_MAP, PANEL_TABS} from 'topologyLeaflet/services/leftPanelService';
import './TopologyPage.module.scss';

const TopologyPage = () => {
  const dispatch = useDispatch();
  const mapRef = useRef();
  const nodes = useSelector(getProcessedNodes);
  const links = useSelector(getProcessedLinks);
  const rings = useSelector(getProcessedRings);
  const ringLinks = useSelector(getProcessedRingLinks);
  const topologyDataDate = useSelector(getTMFetchTopologyMapDataDate);
  const topologyMapDataIsLoading = useSelector(getTMFetchTopologyMapIsLoading);
  const filterOptions = useSelector(getTMFilterOptions);
  const selectedSearchAsset = useSelector(getTMLeftPanelSearchSelectedAsset);
  const [dateAttrSet, setDateAttrSet] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);

  useEffect(() => {
    dispatch(fetchTopologyMap());
    return () => {
      mapRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (topologyDataDate && !dateAttrSet && mapRef.current) {
      mapRef.current.attributionControl.addAttribution(`Topology date: ${topologyDataDate}`);
      setDateAttrSet(true);
    }
  }, [topologyDataDate, dateAttrSet]);

  const getMapRef = (map) => {
    mapRef.current = map;
  };

  const onNodeClick = (n) => {
    dispatch(segmentClickEvent({category: 'topology', name: 'click-on-node'}));
    if (!filterOptions.anomaly) {
      if (queryParams.tabId === PANEL_TABS.issues.id) {
        mapRef.current.setView(n.latLng, mapService.MIN_RINGS_ZOOM, {animate: true});
      } else if (queryParams.tabId === PANEL_TABS.search.id) {
        let node = n;
        if (n.anomalousNodes) {
          // eslint-disable-next-line prefer-destructuring
          node = n.anomalousNodes[0];
        } else if (n.nodes) {
          // eslint-disable-next-line prefer-destructuring
          node = n.nodes[0];
        }
        setQueryParams({tabId: PANEL_TABS.search.id, searchQuery: node.name, searchSelectedItemId: node.id});
      }
    }
  };

  const onRingClick = (r) => {
    dispatch(segmentClickEvent({category: 'topology', name: 'click-on-ring'}));
    if (filterOptions.anomaly) {
      mapRef.current.setView(r.latLng, mapService.MIN_NODES_ZOOM, {animate: true});
    } else {
      let ring = r;
      if (r.anomalousRings) {
        // eslint-disable-next-line prefer-destructuring
        ring = r.anomalousRings[0];
      } else if (r.rings) {
        // eslint-disable-next-line prefer-destructuring
        ring = r.rings[0];
      }
      setQueryParams({tabId: PANEL_TABS.search.id, searchQuery: ring.name, searchSelectedItemId: ring.id});
    }
  };

  return (
    <div className="shell-col" styleName="root">
      <LeftPanel mapRef={mapRef.current} />
      <D3TopologyMap
        nodes={nodes}
        links={links}
        rings={rings}
        ringLinks={ringLinks}
        filterOptions={filterOptions}
        searchItem={selectedSearchAsset}
        onNodeClick={onNodeClick}
        onRingClick={onRingClick}
        getMapRef={getMapRef}
      />
      {topologyMapDataIsLoading ? (
        <div styleName="map-data-is-loading">
          <div styleName="spinner">
            <Spinner color={palette.gray[500]} size={SIZES.XX_BIG_100} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TopologyPage;
