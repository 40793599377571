import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ICONS, Sidebar} from '@pileus-cloud/anodot-frontend-common';
import {
  getDashboardV2Enabled,
  getEmbeddedValueReportEnabled,
  getIsImpersonate,
  getNewAlertConsoleEnabled,
  getNewAlertConsoleMigrationFinished,
  getRecommendationsEnabled,
  getTopologyEnabled,
  getUserProfile,
  isAnodotAdmin,
} from 'profile/store/selectors';
import {getAppNavBarFilteredItems} from 'app/store/selectors';
import {getRoutingLocation} from 'common/store/selectors';
import ImpersonationMenuItem from 'app/components/appNavBar/ImpersonateMenuItem';
import {Observable} from 'rxjs/Observable';
import {saveUrlWithParams} from 'app/store/actions';

import classes from './Sidebar.module.scss';

const ICONS_COMPONENTS = {
  ALERTS_CONSOLE: ICONS.bell.name,
  ALERTS_CONSOLE_NEW: ICONS.bell.name,
  METRIC_SEARCH: ICONS.magnifyingGlass.name,
  ANOMALIES: ICONS.monitor.name,
  DASHBOARDS: ICONS.objectsColumn.name,
  TOPOLOGY: ICONS.mapLocationDot.name,
  CATALOG: ICONS.bell.name,
  INTEGRATIONS: ICONS.plug.name,
  MANAGEMENT: ICONS.sliders.name,
  NOTIFICATIONS: ICONS.bell.name,
  SETTINGS: ICONS.bell.name,
};

function SideBar() {
  const dispatch = useDispatch();
  const location = useSelector(getRoutingLocation);
  const appNavBarFilteredItems = useSelector(getAppNavBarFilteredItems);
  const isNewAlertConsoleMigrationFinished = useSelector(getNewAlertConsoleMigrationFinished);
  const isNewAlertConsoleEnabled = useSelector(getNewAlertConsoleEnabled);
  const isTopologyEnabled = useSelector(getTopologyEnabled);
  const isDashboardV2Enabled = useSelector(getDashboardV2Enabled);
  const isImpersonating = useSelector(getIsImpersonate);
  const me = useSelector(getUserProfile);
  const isSuperAdmin = useSelector(isAnodotAdmin);
  const isEmbeddedValueReportEnabled = useSelector(getEmbeddedValueReportEnabled);
  const isRecommendationsEnabled = useSelector(getRecommendationsEnabled);

  const isAlertConsoleNew = isNewAlertConsoleMigrationFinished && isNewAlertConsoleEnabled;

  const getProperLink = (lastVisitedUrl, actualLink) => {
    const {origin} = window.location;
    const url = `${origin}/${actualLink}`;
    if (actualLink.indexOf('/r/dashboards') !== -1) {
      return url;
    }
    return lastVisitedUrl || url;
  };

  useEffect(() => {
    const windowLocationChangeSubscription = Observable.fromEvent(window, 'hashchange').subscribe((event) => {
      dispatch(saveUrlWithParams({oldURL: event.oldURL, newURL: event.newURL}));
    });
    return () => {
      windowLocationChangeSubscription.unsubscribe();
    };
  }, []);

  const handleOnValueChange = useCallback(
    (item) => {
      if (item) {
        const index = item.indexOf('/');
        let parentItem = item;
        let childItem = null;
        let found;

        if (index > -1) {
          // The selected item is a child inside a parent.
          parentItem = item.slice(0, index);
          childItem = item.slice(index + 1, item.length);
          found = appNavBarFilteredItems.find((i) => i.id === parentItem).items.find((j) => j.id === childItem);
        } else {
          found = appNavBarFilteredItems.find((i) => i.id === parentItem);
        }
        if (found) {
          const url = getProperLink(found.lastVisitedurl, found.links[0]);
          window.open(url, '_top');
        }
      }
    },
    [location],
  );

  const checkUrl = (links) => {
    const pathNameArr = location.pathname.split('/').filter((i) => i && i !== 'admin');

    if (!pathNameArr || !pathNameArr.length) {
      return null;
    }

    const cleanPathName = pathNameArr[0].replace('/', '');
    const linksArr = links.map((i) => i.split('/'));

    return linksArr.some((i) => i.some((j) => j === cleanPathName));
  };

  const updateAppNavBarAccordingToFeatureFlags = () => {
    appNavBarFilteredItems.forEach((i) => {
      if (i.id === 'DASHBOARDS' && isDashboardV2Enabled) {
        const dashboard = appNavBarFilteredItems.find((item) => item.id === 'DASHBOARDS');
        if (dashboard) {
          dashboard.links = ['#!/r/dashboards'];
        }
      }
      if (i.id === 'MANAGEMENT' && i.items) {
        if (isImpersonating || isEmbeddedValueReportEnabled) {
          const valueReportSubItem = i.items.find((item) => item.id === 'VALUE_REPORT');
          if (valueReportSubItem) {
            valueReportSubItem.isHidden = false;
          }
        }
        if (isRecommendationsEnabled) {
          const recommendationsSubItem = i.items.find((item) => item.id === 'RECOMMENDATIONS');
          if (recommendationsSubItem) {
            recommendationsSubItem.isHidden = false;
          }
        }
      }
    });
  };

  const filterItems = () => {
    return appNavBarFilteredItems.filter((i) => {
      if (i.isHidden) {
        return false;
      }
      if (i.id === 'NOTIFICATIONS' || i.id === 'SETTINGS') {
        return false;
      }
      if (i.id === 'ALERTS_CONSOLE' && isAlertConsoleNew) {
        return false;
      }
      if (i.id === 'INTEGRATIONS' && isSuperAdmin) {
        return false;
      }
      if (i.id === 'ALERTS_CONSOLE_NEW' && !isAlertConsoleNew && !isImpersonating) {
        return false;
      }
      if (i.id === 'TOPOLOGY' && !isTopologyEnabled) {
        return false;
      }

      return true;
    });
  };

  const configChildren = (i) => {
    let children;
    if (i?.items?.length) {
      children = i.items
        .filter((item) => item.isHidden !== true)
        .map((j) => ({
          value: j.id,
          title: j.title,
          isActive: j.links ? checkUrl(j.links) : false,
        }));
    }
    return children;
  };

  const options = useMemo(() => {
    updateAppNavBarAccordingToFeatureFlags();
    const appNavBarFilteredItemsFiltered = filterItems();

    return appNavBarFilteredItemsFiltered?.map((i) => ({
      value: i.id,
      title: i.title,
      icon: ICONS_COMPONENTS[i.id],
      isActive: i.links ? checkUrl(i.links) : false,
      children: configChildren(i),
    }));
  }, [location, appNavBarFilteredItems]);

  return (
    <>
      <div className={classes.shiftingDiv} />
      <div className={classes.root}>
        <Sidebar options={options} onValueChange={handleOnValueChange} />
        <div className={classes.impersonationWrapper}>{isImpersonating && <ImpersonationMenuItem me={me} />}</div>
      </div>
    </>
  );
}

export default SideBar;
