import React from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';
import TooltipArea from 'common/componentsV2/TooltipArea';

const Title = () => {
  const {
    input: {value, onChange},
    meta,
  } = useField('title');

  return (
    <TooltipArea isAlwaysVisible automationId="chartTitle" text="Set title for chart">
      {(info) => (
        <React.Fragment>
          <div className="text14reg lineHeight_16 mb_1">
            Title:
            {info}
          </div>
          <Input
            automationId="title"
            additionalProps={{name: 'title'}}
            isInvalid={meta.touched && meta.error}
            invalidMessage={meta.error}
            value={value}
            onChange={onChange}
            placeHolder="Optional"
          />
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default Title;
