// @flow
import React, {Fragment} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import {palette} from 'app/styles/theme';
import SmartTooltip from 'common/componentsV2/Tooltip';
import Button, {COLORS} from 'common/componentsV2/Button';

const useStyles = makeStyles(() => ({
  activeTitle: {
    color: palette.gray['600'],
    cursor: 'default',
  },
  btnWrapper: {
    position: 'relative',
  },
  preText: {
    position: 'absolute',
    top: 9,
    left: 0,
  },
  btnSelected: {
    marginLeft: 30,
  },
}));

const RoutingModalOpenBtn = (props: {selectedRoutingFile: Object, openModal: Function}) => {
  const classes = useStyles();

  return (
    <Fragment>
      {props.selectedRoutingFile && (
        <SmartTooltip content="Click to replace the Routing table" placement="top">
          <Box className={classes.btnWrapper}>
            <span className={`text16reg lineHeight_16 ${classes.preText}`}>From:</span>
            <Button
              extraClassName={classes.btnSelected}
              colorSchema={COLORS.TRANSPARENT}
              icon="icn-nav16-info"
              text={props.selectedRoutingFile.title}
              onClick={props.openModal}
            />
          </Box>
        </SmartTooltip>
      )}

      {!props.selectedRoutingFile && (
        <div style={{marginLeft: -16}}>
          <Button
            text="Lookup Table"
            colorSchema={COLORS.TRANSPARENT_BLUE}
            icon="icn-action16-plusa"
            onClick={props.openModal}
          />
        </div>
      )}
    </Fragment>
  );
};

export default React.memo(RoutingModalOpenBtn);
