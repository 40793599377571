import {getUniqueId} from 'common/utils/guid';
import {cloneDeep} from 'lodash';
import ExpressionItem from 'common/componentsV2/ExpressionBuilderV2/ExpressionItem';

export const eventOptions = [
  {
    value: 'DISPLAY',
    label: 'Display',
    name: 'Display events',
    properties: {usesMatchingProp: true, metricWithFunc: false},
  },
  {
    value: 'INFLUENCE',
    label: 'Influence',
    name: 'Influencing events',
    properties: {usesMatchingProp: true, metricWithFunc: true},
  },
  {
    value: 'SUPPRESS',
    label: 'Suppressing',
    name: 'Suppressing events',
    properties: {usesMatchingProp: true, metricWithFunc: false},
  },
  {
    value: 'OFFICE_HOURS',
    label: 'Office hours',
    name: 'Office hours events',
    properties: {usesMatchingProp: false, metricWithFunc: false},
  },
];

const checkValidity = (event, isInfluencingDisabled) => {
  if (event.expression.length === 0) {
    return {
      isValid: false,
      message: 'Select an Expression',
    };
  }

  if (event.eventType.properties?.metricWithFunc && isInfluencingDisabled) {
    return {
      isValid: false,
      message:
        'Influencing events can be used when:\n' +
        'a) There is a function defined for the alert,\n' +
        'b) The alert timescale is at least one hour.',
    };
  }

  let validity = {
    isValid: true,
    message: null,
  };

  event.eventMetricFilter.forEach((filter) => {
    if (filter.eventProperty && filter.metricDimension == null) {
      validity = {
        isValid: false,
        message: 'Define event property',
      };
    }
    if (filter.metricDimension && filter.eventProperty == null) {
      validity = {
        isValid: false,
        message: 'Define metric dimension',
      };
    }
  });

  return validity;
};

export const createJsonFromState = (state, aggregation) => {
  const ret = [];
  if (state.validation.length !== state.events.length) {
    return ret;
  }
  state.events.forEach((event, index) => {
    if (state.validation[index].isValid) {
      const eventMetricFilter = [];
      event.eventMetricFilter.forEach((filter) => {
        if (filter.eventProperty != null) {
          eventMetricFilter.push(filter);
        }
      });
      const e = {
        aggregation,
        filter: {
          categories: [],
          type: event.eventType.value,
          q: {
            q: null,
            expression: event.expression.map((exp) => exp.getExpressionTreeObjectification()),
            ids: null,
            originSourceIds: null,
          },
        },
        lookupTable: null,
        eventMetricFilter: eventMetricFilter.length > 0 ? eventMetricFilter : null,
      };
      ret.push(e);
    }
  });
  return ret;
};

export const localActions = {
  ADD_INIT_EVENT_FILTER: 'addInitEventFilter',
  ADD_EVENT_FILTER: 'addEventFilter',
  REMOVE_EVENT_FILTER: 'removeEventFilter',
  CHANGE_QUERY_EXPRESSION: 'changeQueryExpression',
  CHANGE_EVENT_TYPE: 'changeEventType',
  CHANGE_EVENT_PROPERTY: 'changeEventProperty',
  CHANGE_EVENT_METRIC: 'changeEventMetric',
  ADD_PROP_AND_DIM: 'addPropAndDim',
  REMOVE_PROP_AND_DIM: 'removePropAndDim',
  CHECK_VALIDATION: 'checkValidation',
  SET_EVENTS_COUNT: 'setEventsCount',
  SET_TOTAL_EVENTS: 'setTotalEvents',
  RESET_PROP_AND_DIM: 'resetPropAndDim',
};

const getClonedEventById = (action, state) => {
  const index = state.events.findIndex((event) => event.id === action.payload.id);
  const changedEvent = cloneDeep(state.events[index]);
  return {index, changedEvent};
};

export const localReducer = (state, action) => {
  switch (action.type) {
    case localActions.ADD_INIT_EVENT_FILTER: {
      const expressions = (action.payload.filter.q.expression || []).map((ex) =>
        ExpressionItem.setExpressionFromObject(ex),
      );
      return {
        ...state,
        events: [
          ...state.events,
          {
            eventType: eventOptions.find((opt) => opt.value === action.payload.filter.type) || '',
            id: getUniqueId(),
            expression: expressions,
            eventMetricFilter: action.payload.eventMetricFilter || [{eventProperty: null, metricDimension: null}],
            isPristine: false,
            totalEvents: 0,
          },
        ],
      };
    }
    case localActions.ADD_EVENT_FILTER: {
      return {
        ...state,
        events: [
          ...state.events,
          {
            eventType: action.payload,
            id: getUniqueId(),
            expression: [],
            eventMetricFilter: [{eventProperty: null, metricDimension: null}],
            isPristine: true,
          },
        ],
      };
    }
    case localActions.REMOVE_EVENT_FILTER: {
      const index = state.events.findIndex((event) => event.id === action.payload);
      return {
        ...state,
        events: [...state.events.slice(0, index), ...state.events.slice(index + 1)],
      };
    }
    case localActions.CHANGE_QUERY_EXPRESSION: {
      const {index, changedEvent} = getClonedEventById(action, state);
      changedEvent.expression = action.payload.expression;
      changedEvent.isPristine = false;
      return {
        ...state,
        events: [...state.events.slice(0, index), changedEvent, ...state.events.slice(index + 1)],
      };
    }
    case localActions.CHANGE_EVENT_TYPE: {
      const {index, changedEvent} = getClonedEventById(action, state);
      changedEvent.eventType = action.payload.eventType;
      changedEvent.isPristine = false;
      return {
        ...state,
        events: [...state.events.slice(0, index), changedEvent, ...state.events.slice(index + 1)],
      };
    }
    case localActions.CHANGE_EVENT_PROPERTY: {
      const {index, changedEvent} = getClonedEventById(action, state);
      changedEvent.eventMetricFilter[action.payload.index].eventProperty = action.payload.eventMetricFilter.value;
      changedEvent.isPristine = false;
      return {
        ...state,
        events: [...state.events.slice(0, index), changedEvent, ...state.events.slice(index + 1)],
      };
    }
    case localActions.CHANGE_EVENT_METRIC: {
      const {index, changedEvent} = getClonedEventById(action, state);
      changedEvent.eventMetricFilter[action.payload.index].metricDimension = action.payload.metricDimension.value;
      changedEvent.isPristine = false;
      return {
        ...state,
        events: [...state.events.slice(0, index), changedEvent, ...state.events.slice(index + 1)],
      };
    }
    case localActions.ADD_PROP_AND_DIM: {
      const {index, changedEvent} = getClonedEventById(action, state);
      changedEvent.eventMetricFilter = [
        ...changedEvent.eventMetricFilter,
        {eventProperty: null, metricDimension: null},
      ];
      changedEvent.isPristine = false;
      return {
        ...state,
        events: [...state.events.slice(0, index), changedEvent, ...state.events.slice(index + 1)],
      };
    }
    case localActions.REMOVE_PROP_AND_DIM: {
      const {index, changedEvent} = getClonedEventById(action, state);
      changedEvent.eventMetricFilter = [
        ...changedEvent.eventMetricFilter.slice(0, action.payload.index),
        ...changedEvent.eventMetricFilter.slice(action.payload.index + 1),
      ];
      changedEvent.isPristine = false;
      return {
        ...state,
        events: [...state.events.slice(0, index), changedEvent, ...state.events.slice(index + 1)],
      };
    }
    case localActions.CHECK_VALIDATION: {
      const validation = [];
      state.events.forEach((event) => {
        validation.push(checkValidity(event, action.payload));
      });
      return {
        ...state,
        validation,
        checkCount: state.checkCount + 1,
      };
    }
    case localActions.SET_TOTAL_EVENTS: {
      const index = state.events.findIndex((event) => event.id === action.payload.id);
      if (index > -1) {
        const changedEvent = cloneDeep(state.events[index]);
        changedEvent.totalEvents = action.payload.totalEvents;
        return {
          ...state,
          events: [...state.events.slice(0, index), changedEvent, ...state.events.slice(index + 1)],
        };
      }
      return state;
    }

    case localActions.RESET_PROP_AND_DIM: {
      const {index, changedEvent} = getClonedEventById(action, state);
      changedEvent.eventMetricFilter = [{eventProperty: null, metricDimension: null}];
      changedEvent.isPristine = false;
      return {
        ...state,
        events: [...state.events.slice(0, index), changedEvent, ...state.events.slice(index + 1)],
      };
    }

    default:
      return state;
  }
};

export const localInitialState = {
  events: [],
  validation: [],
  checkCount: 0,
};
