import React, {useEffect, useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import useSectionsClasses from 'dataCollectors/styles/classes.useSectionsClasses';
import {useFetchUsers} from 'dataCollectors/api/useFetchUsers';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';

const {sections} = useSectionsClasses();

const StreamInfo = ({onChange, selectedValue, isLoading, name, width}) => {
  const sectionsClasses = sections();

  const {users, isUsersLoading} = useFetchUsers();

  const [selectedItem, setSelectedItem] = useState(null);

  const onSelect = useCallback((item) => {
    onChange(item.value);
  }, []);

  useEffect(() => {
    const selectedIndex = users.findIndex((val) => val.value === selectedValue);
    setSelectedItem(users[selectedIndex]);
  }, [users, selectedValue]);

  return (
    <div className={sectionsClasses.input} style={{width}}>
      <div className={sectionsClasses.label}>{name}</div>
      <FormDdlSelect
        isDisabledSorting
        options={users}
        selected={selectedItem}
        onChange={onSelect}
        button={
          <BigDropdownButton
            isPristine={!selectedItem}
            label={selectedItem?.label}
            placeholder={isLoading || isUsersLoading ? 'Loading..' : 'Select'}
            shortBlueStyle
            isFullWidth
          />
        }
        optionComponent={<OptionComponentSimple />}
        width={240}
      />
    </div>
  );
};

StreamInfo.defaultProps = {
  width: '',
  isLoading: false,
};

StreamInfo.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  width: PropTypes.string,
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default StreamInfo;
