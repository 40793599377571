import React from 'react';
import FeedbackInvestigationWrapper from 'feedback/FeedbackInvestigationWrapper';
import {modalRouting} from 'investigationNewAlertConsole/services/investigationService';
import Acknowledged from 'investigationNewAlertConsole/components/header/Acknowledged';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {getAlertAssigneeEnabled} from 'profile/store/selectors';
import {useSelector} from 'react-redux';
import AlertAssignee from 'investigationNewAlertConsole/components/header/AlertAssignee';
import SnoozeAction from 'alerts.console.new/components/alertsList/alertActions/SnoozeAction';
import TriageMoreActions from 'investigationNewAlertConsole/components/header/TriageMoreActions';

import './TriageHeader.module.scss';

const TriageHeaderActionsBar = ({
  trigger,
  queryParams,
  setQueryParams,
  timeline,
}: {
  trigger: Object,
  queryParams: Object,
  setQueryParams: Function,
  timeline: Object,
}) => {
  const isAlertAssigneeEnabled = useSelector(getAlertAssigneeEnabled);

  const backButtonClicked = () => {
    if (
      queryParams[modalRouting.ANOMALY_ID] ||
      queryParams[modalRouting.TRIGGER_ID] ||
      queryParams[modalRouting.INVESTIGATION_MODAL]
    ) {
      setQueryParams(
        {
          [modalRouting.ANOMALY_ID]: undefined,
          [modalRouting.TRIGGER_ID]: undefined,
          [modalRouting.INVESTIGATION_MODAL]: undefined,
        },
        'replace',
      );
    }
  };

  return trigger && timeline ? (
    <div styleName="triage-actions-bar">
      <Tooltip content="Back to Alert Console" type={TYPES.SMALL} placement="bottom">
        <div styleName="back-button" onClick={backButtonClicked}>
          ⬅
        </div>
      </Tooltip>
      {isAlertAssigneeEnabled && trigger ? <AlertAssignee queryParams={queryParams} /> : null}
      <Acknowledged queryParams={queryParams} />
      <FeedbackInvestigationWrapper queryParams={queryParams} />
      <SnoozeAction trigger={trigger} queryParams={queryParams} isTriage />
      <TriageMoreActions trigger={trigger} timeline={timeline} />
    </div>
  ) : (
    <div />
  );
};

export default TriageHeaderActionsBar;
