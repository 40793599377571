import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {getTriageFullScreenEnabled} from 'profile/store/selectors';
import {StringParam, useQueryParams} from 'use-query-params';
import AlertsConsole from 'alerts.console.new/pages/AlertsConsole';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS} from 'alerts.console.new/services/alertsConsoleService';
import {
  modalRouting as INVESTIGATION_PARAMS,
  modalRouting,
} from 'investigationNewAlertConsole/services/investigationService';
import {
  modalRouting1 as IMPACT_PARAMS1,
  modalRouting2 as IMPACT_PARAMS2,
} from 'impactNewAlertConsole/services/constants';
import {
  mapAlertConsoleOldToNewQueryParameters,
  OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS,
} from 'alerts.console.new/services/alertConsoleRoutingMapOldToNew';
import Triage from 'investigationNewAlertConsole/pages/Triage';
import {parseQS} from 'common/utils/http';

const useComponentWillMount = (callback) => {
  const willMount = useRef(true);
  if (willMount.current) {
    callback();
  }
  willMount.current = false;
};

const AlertsConsoleWrapper = (props) => {
  const isTriageFullscreenEnabled = useSelector(getTriageFullScreenEnabled);
  const oldParams = Object.fromEntries(
    Object.values(OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS).map((key) => [key, StringParam]),
  );
  const newParams = Object.fromEntries(Object.values(ALERT_CONSOLE_QUERY_PARAMS_KEYS).map((key) => [key, StringParam]));
  const invParams = Object.fromEntries(Object.values(INVESTIGATION_PARAMS).map((key) => [key, StringParam]));
  const impactParams = Object.fromEntries(
    [...Object.values(IMPACT_PARAMS1), ...Object.values(IMPACT_PARAMS2)].map((key) => [key, StringParam]),
  );
  const [queryParams, setQueryParams] = useQueryParams({
    ...oldParams,
    ...newParams,
    ...invParams,
    ...impactParams,
    paramsMapping: StringParam,
  });
  const [isLoading, setLoading] = useState(true);

  const [isTriage, setIsTriage] = useState(false);

  useEffect(() => {
    const tmpIsTriage = Object.values(modalRouting).every((triageQueryParam) => queryParams[triageQueryParam]);
    setIsTriage(tmpIsTriage);
  }, [queryParams[modalRouting.INVESTIGATION_MODAL]]);

  useComponentWillMount(() => {
    const mappedQueryParams = mapAlertConsoleOldToNewQueryParameters(queryParams);
    if (Object.keys(mappedQueryParams)?.length === 0) {
      return;
    }

    setQueryParams(
      {
        ...mappedQueryParams,
        paramsMapping: 'inProgress',
      },
      'replace',
    );
  });

  useEffect(() => {
    if (queryParams.paramsMapping === 'inProgress') {
      // queryParams is not updated/synchronized if query params were updated from another component. Thus need to take the "latest" queryParams from location.
      const currentQueryParams = parseQS(window.location.href);
      setQueryParams(
        {
          ...currentQueryParams,
          paramsMapping: undefined,
        },
        'replace',
      );
    }
    setLoading(false);
  }, [queryParams.paramsMapping]);

  return !isLoading && (isTriage && isTriageFullscreenEnabled ? <Triage {...props} /> : <AlertsConsole {...props} />);
};

export default AlertsConsoleWrapper;
