import React from 'react';
import ContentLoader from 'common/componentsV2/ContentLoader';

const ContentLoaderPreviewBig = () => {
  return (
    <>
      <ContentLoader rows={[{marginTop: 24, width: '20%', height: 32}]} />
      <ContentLoader rows={[{marginTop: 24, width: '100%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '75%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '100%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '75%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '75%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '100%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '100%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '75%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '100%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '50%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '75%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '100%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '75%', height: 16}]} />
      <ContentLoader rows={[{marginTop: 24, width: '100%', height: 16}]} />
    </>
  );
};

export default ContentLoaderPreviewBig;
