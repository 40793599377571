// @flow
import React from 'react';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import connect from 'react-redux/es/connect/connect';
import * as selector from 'alerts.management/store/selectors';
import {simpleAlertSetTimeValue} from 'alerts.management/store/actions';

type PropTypes = {
  disabled: boolean,
  durationOptions: Array,
  durationValue: Object,
  simpleAlertSetTimeValue: Function,
};

export default connect(
  (state) => ({
    durationOptions: selector.getSimpleAlertMinDurationOptions(state),
    durationValue: selector.getSimpleAlertSelectedDurationValue(state),
  }),
  {
    simpleAlertSetTimeValue,
  },
)(
  class TimeScaleDdl extends React.PureComponent {
    props: PropTypes;

    onChange = (item) => {
      this.props.simpleAlertSetTimeValue(item);
    };

    render() {
      const {durationValue, durationOptions} = this.props;
      return (
        <SelectAndt
          automationId="simpleAlertDuration"
          type={TYPE_NEW_NO_SEARCH}
          theme={THEME_BLUE_LEAN}
          onChange={this.onChange}
          options={durationOptions}
          value={durationValue}
          placeholder="Num"
          optionHeight={40}
          buttonWidth={68}
          menuWidth={100}
          getOptionLabel={(val) => val.label}
          getOptionValue={(val) => val.value}
          disabled={this.props.disabled}
        />
      );
    }
  },
);
