import './globalStyles';
import {isEmpty} from 'lodash';
import React, {useEffect} from 'react';
import {Provider, useSelector, useDispatch} from 'react-redux';
import {Route} from 'react-router-dom';
import {createHashHistory} from 'history';
import {ConnectedRouter} from 'connected-react-router';
import {QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import Shell from 'app/components/Shell';
import MandatoryNameModal from 'app/components/MandatoryNameModal';
import {ThemeProvider} from '@material-ui/core/styles';
import theme from 'app/styles/theme';
import DashboardConversionModal from 'dashboards/components/modals/ConversionModal';
import {QueryParamProvider, StringParam, useQueryParam} from 'use-query-params';
import {getUserProfile} from 'profile/store/selectors';
import {fetchMe, updateUserSettings} from 'profile/store/actions';
import moment from 'moment';
import TopNavbar from './app/components/TopNavbar/TopNavbar';
import SideBar from './app/components/Sidebar/Sidebar';
import {queryClient} from './reactQuery/queryClient';
import {setGlobalUser, processAndtToken} from './appUtils';
import initSubscriptions from './globalSubscriptions';
import configureStore from './configureStore';
import Gauge from './metrics/components/gauge/Gauge';
import SimulationFilters from './alerts.management/components/editor/simulationFilters/SimulationFiltersWrapper';
import UsersGroupsSelect from './admin.users/components/UsersGroupsSelectWrapper';
import {getAnonymousUser} from './dashboards/store/actions';

import './app/styles/utils.scss';

// const isDev = process.env.NODE_ENV === 'development';
const originalHistory = createHashHistory({
  basename: '/r',
  hashType: 'hashbang',
});

const store = configureStore(originalHistory);
const history = originalHistory; // isDev ? syncHistoryWithStore(originalHistory, store) : originalHistory;

/* eslint-disable */
const segment =
  window.isOnPremise ||
  ((window.isProduction || window.isPoc) &&
    (function() {
      'use strict';
      !(function(i, n, s, t, c) {
        var e = 'aptrinsic';
        (i[e] =
          i[e] ||
          function() {
            (i[e].q = i[e].q || []).push(arguments);
          }),
          (i[e].p = t);
        var o = n.createElement('script');
        (o.async = !0), (o.src = 'https://web-sdk.aptrinsic.com/api/aptrinsic.js?a=' + t), (i[e].c = void 0);
        var r = n.getElementsByTagName('script')[0];
        r.parentNode.insertBefore(o, r);
      })(window, document, 0, isPoc ? 'AP-4CYEWK6EG7HX-2-4' : 'AP-4CYEWK6EG7HX-2');
    })());

if (!window.isLocalHost) {
  !(function() {
    var analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.');
      else {
        analytics.invoked = !0;
        analytics.methods = [];
        analytics.factory = function(t) {
          return function() {
            var e = Array.prototype.slice.call(arguments);
            e.unshift(t);
            analytics.push(e);
            return analytics;
          };
        };
        for (var t = 0; t < analytics.methods.length; t++) {
          var e = analytics.methods[t];
          analytics[e] = analytics.factory(e);
        }
        analytics.load = function(t, e) {
          var n = document.createElement('script');
          n.type = 'text/javascript';
          n.async = !0;
          n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
          var a = document.getElementsByTagName('script')[0];
          a.parentNode.insertBefore(n, a);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.1.0';
        const segmentKey =
          window.isProduction && !window.isPoc && !window.isMonitoring
            ? 'HSNzO1RSmJ1ycqpZP4b11hf5kFyOE5dK'
            : window.isPoc
            ? '1PxPmUD0JCrB68tYcC3WMcqInM9Qzrwf'
            : 'vNH4nCHmtcH4rtTRp6nCKaC6yDxWMOyH';
        analytics.load(segmentKey);
      }
  })();
}
/* eslint-enable */
const Content = () => {
  const [invitationId] = useQueryParam('invitationId', StringParam);
  const dispatch = useDispatch();
  const me = useSelector(getUserProfile);

  useEffect(() => {
    processAndtToken();
    window.angular.element(document).ready(function() {
      if (invitationId) {
        dispatch(getAnonymousUser({invitationId: invitationId.replace(';', '')}));
      } else {
        dispatch(fetchMe());
      }
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(me)) {
      setGlobalUser(me);

      // If timezone name of the user is not set - set the timezone in MongoDB.
      const timeZoneName = me?.appSettings?.timeZone?.name;
      if (!timeZoneName) {
        dispatch(
          updateUserSettings({
            ...me.appSettings,
            timeZone: {
              name: moment.tz.guess(),
            },
          }),
        );
      }
    }
  }, [me]);

  if (isEmpty(me)) {
    return null;
  }

  return (
    <>
      <TopNavbar />
      <SideBar />
      <MandatoryNameModal />
      <div className="shell-col position_relative">
        <div
          style={{paddingTop: 50}}
          className="shell-col stretch_absolute"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: '<ui-view autoscroll="false" class="shell-col" />',
          }}
        />
        <Shell />
      </div>
    </>
  );
};

class App extends React.PureComponent {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <ConnectedRouter history={history}>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Content />
              </QueryParamProvider>
            </ConnectedRouter>
          </ThemeProvider>
        </Provider>
        <ReactQueryDevtools panelProps={{className: 'react-query-dev-tools'}} position="bottom-right" />
      </QueryClientProvider>
    );
  }
}

export const AngularDashboardConversionModal = (props) => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <DashboardConversionModal {...props} />
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>
);

window.AnodotBusinessCollectors = {
  App,
  Gauge,
  SimulationFilters,
  UsersGroupsSelect,
  AngularDashboardConversionModal,
};

initSubscriptions(App, store);

export default App;
