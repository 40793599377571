import React, {PureComponent} from 'react';
import {isString} from 'lodash';
import {components} from 'react-select2';
import Highlighter from 'react-highlight-words';
import {getFocusOptionSearchableEmitter} from '../communication';
import '../SelectAndt.module.scss';

type PropTypes = {
  // accepted values by the original Select
  isFocused: boolean,
  isSelected: boolean,
  isMulti: boolean,
  innerProps: any,
  children: any,
  selectProps: any,
  data: Object,
};

export default class OptionSelectedSigned extends PureComponent {
  props: PropTypes;

  render() {
    if (this.props.isMulti && this.props.isFocused) {
      const i = this.props.innerProps.id.split('-');
      const index = i[i.length - 1];
      if (getFocusOptionSearchableEmitter && getFocusOptionSearchableEmitter())
        getFocusOptionSearchableEmitter().next(index);
    }
    if (this.props.data.type === 'header' || this.props.data.type === 'HEADER' || this.props.data.category === true) {
      return (
        <components.Option className="andt-dropdown-option-header" {...this.props}>
          <div className={this.props.data.headerClass}>
            <i className={`icon ${this.props.data.iconClass} ${this.props.data.icon}`} />
            <span>{this.props.children}</span>
          </div>
        </components.Option>
      );
    }
    const optionStyle = ['option-signed', this.props.isSelected ? 'option-selected' : ''];
    return this.props.children ? (
      <components.Option className="andt-dropdown-option" {...this.props}>
        <div styleName={optionStyle.join(' ')} className={this.props.data.optionClass}>
          {isString(this.props.children) ? (
            <Highlighter
              autoEscape
              searchWords={[this.props.selectProps.inputValue]}
              textToHighlight={this.props.children}
            />
          ) : (
            this.props.children
          )}
          {this.props.data.count ? <span styleName="count">{this.props.data.count}</span> : null}
          <i styleName="icon-signed" className="icon icn-general16-checkmark" />
        </div>
      </components.Option>
    ) : null;
  }
}
