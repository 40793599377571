import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {isEmpty} from 'lodash';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/event-streams/';

const fetchEventStreamLog = (streamId) => {
  return get(`${root}${streamId}/stream_log`);
};

export default function useFetchEventStreamLog(streamId) {
  const selectFn = useCallback(
    (res) => {
      let columns = [];
      let data = [];
      let total = 0;
      let success = 0;
      let failure = 0;

      if (res && !isEmpty(res)) {
        total = res.rows.length;
        columns = Object.keys(res.rows[0]).map((key) => ({title: key, id: key}));

        data = res.rows.map((row) => {
          const rowData = {};
          Object.keys(row).forEach((key) => {
            if (key === 'resultStatus' && row[key] === 'OK') {
              success += 1;
            } else if (key === 'resultStatus' && row[key] !== 'OK') {
              failure += 1;
            }
            rowData[key] = row[key];
          });
          return rowData;
        });
      }
      return {columns, data, total, success, failure};
    },
    [streamId],
  );

  const fallback = {
    columns: [],
    data: [],
    total: 0,
    success: 0,
    failure: 0,
  };

  const {
    data: eventStreamLogData = fallback,
    isLoading: eventStreamLogIsLoading,
    isSuccess: eventStreamLogIsSuccess,
    isError: eventStreamLogIsError,
    error: eventStreamLogError,
  } = useQuery(QUERY_KEYS.eventStreamLog, () => fetchEventStreamLog(streamId), {
    enabled: !!streamId,
    select: selectFn,
  });

  return {
    eventStreamLogData,
    eventStreamLogIsLoading,
    eventStreamLogIsSuccess,
    eventStreamLogIsError,
    eventStreamLogError,
  };
}
