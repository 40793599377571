import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/alerts/');
const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/alerts/');

//* **alerts fired
export const resetAlertsConsole = mac('RESET_ALERTS_CONSOLE');
export const fetchAlertConsoleMetricsDataPoints = macAsync('FETCH_METRICS_DATA_POINTS_NEW_ALERT_CONSOLE');
export const fetchAlertEvents = macAsync('FETCH_ALERT_EVENTS_NEW_ALERT_CONSOLE');
export const fetchAlertMultipleEvents = macAsync('FETCH_ALERT_MULTIPLE_EVENTS_NEW_ALERT_CONSOLE');

export const setSelectedTriggeredAlert = mac('SET_SELECTED_TRIGGERED_ALERT');

export const changeScrollbarWidth = mac('CHANGE_SCROLLBAR_WIDTH');
export const listScrolled = mac('LIST_SCROLLED');
export const setIsAlertsListScrolling = mac('SET_IS_ALERTS_LIST_SCROLLING');

export const updateQueryParams = mac('UPDATE_QUERY_PARAMS');
export const updateFilterBtn = mac('UPDATE_FILTER_BTN');
export const IsMoreFilterOpen = mac('IS_MORE_FILTER_OPEN');

export const setQueryParamsToView = mac('SET_QUERY_PARAMS_TO_VIEW');

export const setAlertsFiltersNewAlertConsole = mac('SET_ALERTS_FILTERS_NEW_ALERTS_CONSOLE');
export const setAlertsConsoleFiltersKeyVal = mac('SET_ALERT_FILTERS_KEY_VAL');

export const toggleInvestigationModal = mac('TOGGLE_INVESTIGATION_MODAL');
