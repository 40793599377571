// @flow
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {getFormattedDateMini} from 'common/utils/dateService';
import {checkSnoozeType, setTtlDate} from 'alerts.console.new/services/alertsConsoleSnoozeService';

import './SnoozeAction.module.scss';

const SnoozeIndicator = ({trigger, isTriage}: {trigger: Object, isTriage: Boolean}) => {
  const snoozeType = checkSnoozeType(trigger?.numSnoozedMetrics, trigger?.numStopLearningMetrics);

  const [summaryTtlDate, setSummaryTtlDate] = useState(
    setTtlDate(trigger?.snoozeSummary, trigger?.stopLearningSummary),
  );

  useEffect(() => {
    const tmpSummaryTtlDate = setTtlDate(trigger?.snoozeSummary, trigger?.stopLearningSummary);
    setSummaryTtlDate(tmpSummaryTtlDate);
  }, [trigger?.snoozeSummary, trigger?.stopLearningSummary]);

  const setTooltipContent = () => {
    if (!summaryTtlDate) {
      return null;
    }
    const date = getFormattedDateMini(moment(summaryTtlDate).unix());

    if (trigger?.numSnoozedMetrics === trigger?.numMetrics && trigger?.numStopLearningMetrics === trigger?.numMetrics) {
      return <span>{`Snooze alerts and pause learning until: ${date}.`}</span>;
    }
    return (
      <div style={{textAlign: 'left'}}>
        <span>{`Snoozed until: ${date}.`}</span>

        {(snoozeType === 'snoozed' || snoozeType === 'snoozed-and-stl') && (
          <div>
            <span>
              {`${
                trigger?.numMetrics > 0 && trigger?.numSnoozedMetrics === trigger?.numMetrics
                  ? 'All metrics:'
                  : `${trigger?.numSnoozedMetrics} metrics:`
              } Snoozed updates.`}
            </span>
          </div>
        )}

        {(snoozeType === 'stl' || snoozeType === 'snoozed-and-stl') && (
          <div>
            <span>
              {`${
                trigger?.numMetrics > 0 && trigger?.numStopLearningMetrics === trigger?.numMetrics
                  ? 'All metrics:'
                  : `${trigger?.numStopLearningMetrics} metrics:`
              } Pause learning.`}
            </span>
          </div>
        )}
      </div>
    );
  };

  return summaryTtlDate ? (
    <div styleName="snooze-container">
      <div styleName="resume-time">{getFormattedDateMini(moment(summaryTtlDate).unix(), 'Browser')} </div>
      <SmartTooltip placement={isTriage ? 'bottom' : 'top'} content={setTooltipContent()}>
        <div styleName="value-container">
          <i className="icon icn-action24-snooze" styleName={`snooze-icon ${snoozeType}`} />
        </div>
      </SmartTooltip>
    </div>
  ) : (
    <div />
  );
};

export default SnoozeIndicator;
