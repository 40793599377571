import React, {useEffect} from 'react';
import {
  ALERT_ACTION_PARAMS,
  ICONS_TYPES,
} from 'alerts.management/components/editor/simulationArea/alertActions/services/service';
import Button, {COLORS, WIDTHS} from 'common/componentsV2/Button';
import {makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {getActionsInvestigationSettings} from 'investigationNewAlertConsole/store/selectors';
import fetchTimelineProvider from 'investigationNewAlertConsole/api/fetchTimelineProvider';
import BaloonMessage from 'common/componentsV2/BaloonMessage';

import '../OverwiewTab.module.scss';

const AlertActionsCube = ({
  onShareClicked,
  onCloseBalloon,
  onIsLoading,
  isTriggerLoading,
  trigger,
}: {
  onShareClicked: Function,
  onCloseBalloon: Function,
  onIsLoading: Function,
  isTriggerLoading: Boolean,
  trigger: Object,
}) => {
  const css = makeStyles(() => ({
    actionBtn: {
      maxWidth: 254,
    },
    whatTabChip: {
      maxWidth: 253,
    },
  }));
  const classes = css();
  const actionsSettings = useSelector(getActionsInvestigationSettings);
  const triggeredAlertTimeline = fetchTimelineProvider(trigger?.alertTriggerId);

  useEffect(() => {
    onIsLoading(isTriggerLoading);
  }, [isTriggerLoading]);

  const goTo = (url) => {
    window.open(url);
    return triggeredAlertTimeline?.invalidate();
  };

  return (
    <>
      {trigger ? (
        <div styleName="info-cube-container alert-action-cube">
          {trigger.alertActions?.map((action) => (
            <div key={action[ALERT_ACTION_PARAMS.ACTION_ID]}>
              <Button
                extraClassName={classes.actionBtn}
                text={action[ALERT_ACTION_PARAMS.BTN_NAME]}
                colorSchema={COLORS.BLUE_500}
                width={WIDTHS.NARROW}
                icon={`icon ${[ICONS_TYPES[action[ALERT_ACTION_PARAMS.ACTION_TYPE]]]}`}
                onClick={() => goTo(action[ALERT_ACTION_PARAMS.DATA][ALERT_ACTION_PARAMS.DATA_URL])}
              />
            </div>
          ))}
          {(!trigger.alertActions || trigger.alertActions.length < 3) && (
            <Button
              text="Send to.."
              width={WIDTHS.NARROW_TIGHT}
              colorSchema={COLORS.BLUE_500}
              icon="icn-action16-send"
              onClick={onShareClicked}
            />
          )}
          {(!trigger.alertActions || !trigger.alertActions.length) &&
            ((!actionsSettings || !actionsSettings.isBalloonOpened) && (
              <>
                <BaloonMessage
                  onClose={() => onCloseBalloon('actions', 'isBalloonOpened')}
                  width={255}
                  link="Add an action"
                  icon="icn-general24-actions"
                  url={`${window.location.origin}/#!/r/alert-manager/edit/${trigger.alertConfigurationId}/settings?ref=investigation`}
                >
                  <div>Did you know you can add actions to alerts?</div>
                </BaloonMessage>
              </>
            ))}
        </div>
      ) : null}
    </>
  );
};

export default AlertActionsCube;
