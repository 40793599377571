import {useCallback} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'dashboards/services/constants';
import {getQueryParamsUrl} from 'dashboards/utils/helpers';

const root = 'api/v1/anomalies';

const fetchAnomalies = async (params) => {
  return get(root + getQueryParamsUrl(params));
};

export function useFetchMetricAnomalies(params) {
  const selectFn = useCallback(({anomalies, total}) => anomalies.map((a) => ({...a, totalAnomalies: total})), []);
  const queryFn = useCallback(() => fetchAnomalies(params), [params]);
  const options = {
    select: selectFn,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  };

  const {data = [], isFetching, isLoading} = useQuery(
    [QUERY_KEYS.anomalies, ...Object.values(params)],
    queryFn,
    options,
  );

  return {anomalies: data, isAnomaliesFetching: isFetching, isAnomaliesLoading: isLoading};
}

export function usePrefetchMetricAnomalies(params) {
  const queryFn = useCallback(() => fetchAnomalies(params), [params]);
  const queryClient = useQueryClient();
  queryClient.prefetchQuery([QUERY_KEYS.anomalies, ...Object.values(params)], queryFn);
}
