import {useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';
import {stringifyQS} from 'common/utils/http';
import {useSelector} from 'react-redux';
import {getTimeZoneName} from 'profile/store/selectors';
import {getDateValue} from 'common/utils/dateRangeService';
import {alertsInsightsRoot} from 'insightsPanelNewAlertConsole/api/utils';

const fetchTriggeredAlertsNoFeedback = async ({payload}) => {
  return get(`${alertsInsightsRoot}no-feedback-alerts?${stringifyQS(payload)}`);
};

const fetchParams = (queryParams) => {
  const timeZone = useSelector(getTimeZoneName);
  const dateRange = getDateValue(
    {
      constRange: queryParams?.constRange,
    },
    true,
    timeZone,
  );

  const triggeredAlertsParams = {
    ...queryParams,
    constRange: dateRange.constRange,
    alertCloseFrom: dateRange.startDate,
  };

  return triggeredAlertsParams;
};

export default function fetchTriggeredAlertsNoFeedbackProvider(queryParams) {
  const queryClient = useQueryClient();

  const calculatedQueryParams = fetchParams(queryParams);
  // Removing the alertCloseFrom react query key, since it is calculated using Now time, so it keeps changing,
  // thus changing the queryKey. Also, there is no need for pageNum in the total alerts queryKey API.
  const {alertCloseFrom, ...queryKeyParams} = calculatedQueryParams;
  // Removing the constRange, since it is not a parameter of the alerts-console/triggered API.
  const {constRange, ...params} = calculatedQueryParams;

  const queryKey = [QUERY_KEYS.triggeredAlertsInsightPanelNoFeedback, queryKeyParams];
  return {
    invalidate: (partialKey) => queryClient.invalidateQueries(partialKey ? [partialKey] : queryKey),
    reset: (partialKey) => queryClient.resetQueries(partialKey ? [partialKey] : queryKey),
    fetchQuery: () =>
      queryClient.fetchQuery(
        queryKey,
        fetchTriggeredAlertsNoFeedback({
          payload: {
            breachTimeFrom: params.breachTimeFrom,
          },
        }),
        {
          retry: false,
        },
      ),
    useQuery: () =>
      useQuery(
        queryKey,
        () =>
          fetchTriggeredAlertsNoFeedback({
            payload: {...params},
          }),
        {
          retry: false,
        },
      ),
  };
}
