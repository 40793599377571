// @flow
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {fetchChannels} from 'alerts.channels/store/actions';
import {isEqual, cloneDeep} from 'lodash';
import {useField} from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';
import * as channelsSelectors from 'alerts.channels/store/selectors';
import * as usersSelectors from 'admin.users/store/selectors';

import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {Box} from '@material-ui/core';
import TooltipArea from 'common/componentsV2/TooltipArea';
import {getStreamsInExpression} from 'metrics/store/selectors';
import * as actions from 'admin.permissions/store/actions';
import {
  getAccessListAllSourcesData,
  getAccessListChannelsData,
  getChannelListByStream,
  getIsRbacEnabled,
} from 'admin.permissions/store/selectors';
import {getMeOwnerOrganization} from 'profile/store/selectors';
import {fetchGroups, fetchUsers} from 'admin.users/store/actions';

const Recipients = () => {
  const {
    input: {value: subscribers, onChange: onChangeSubscribers},
    meta: {touched, error},
  } = useField('subscribers', {isEqual});

  const {
    input: {value: channels, onChange: onChangeChannels},
  } = useField('channels', {isEqual});

  const selectRef = useRef();
  const dispatch = useDispatch();

  const isLoadingChannels = useSelector(channelsSelectors.getChannelsLoading);
  const isLoadingUsers = useSelector(usersSelectors.getUsersIsLoading);
  const sortedFlattenChannels = useSelector(channelsSelectors.getSortedFlattenChannels);
  const accessListChannelsData = useSelector(getAccessListChannelsData);
  const streamsInExpression = useSelector(getStreamsInExpression);
  const accessListAllSourcesData = useSelector(getAccessListAllSourcesData);
  const isRbacEnabled = useSelector(getIsRbacEnabled);
  const ownerOrganization = useSelector(getMeOwnerOrganization);
  const groupData = useSelector(usersSelectors.fetchGroupsData);
  const channelByMetricSource = useSelector(getChannelListByStream); // ==> GABPAC -- more data of the channels

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchGroups({ownerOrganization}));
  }, [ownerOrganization]);

  useEffect(() => {
    dispatch(fetchChannels());
  }, []);

  const prevStreamsInExpression = useRef(streamsInExpression);

  const handleChange = useCallback((arr) => {
    //
    onChangeSubscribers(arr.filter((item) => item.type === 'USERS').map((item) => item.value));
    onChangeChannels(arr.filter((item) => item.type !== 'USERS').map((item) => item.value));
  }, []);

  useEffect(() => {
    if (
      isRbacEnabled &&
      prevStreamsInExpression.current.length < streamsInExpression.length &&
      prevStreamsInExpression.current.length !== 0
    ) {
      handleChange([]); // <-- This clear the recipients in case of changes in the streams in the expression
    }
    if (streamsInExpression.length) {
      streamsInExpression.forEach((stream) => {
        dispatch(actions.fetchChannelByMetricSource({type: 'STREAM', streamId: stream}, {streamId: stream}));
      });
    }
    prevStreamsInExpression.current = streamsInExpression;
  }, [JSON.stringify(streamsInExpression)]);

  // const channelsAvailableByAllStreams = useMemo(() => {
  //   const intersection = Object.values(channelListByStream).reduce((a, b) => a.filter((c) => b.includes(c)), ['*']);
  //   return intersection;
  // }, [JSON.stringify(channelListByStream)]);

  const streamsFilteredByAccesslist = useMemo(() => {
    if (!isRbacEnabled) {
      return sortedFlattenChannels;
    }

    if (streamsInExpression.length === 0) {
      return sortedFlattenChannels;
    }

    sortedFlattenChannels.forEach((channel) => {
      if (groupData.length > 0) {
        const group = groupData.find((gd) => gd.users.includes(channel.value));
        // eslint-disable-next-line no-param-reassign
        channel.groupId = group ? group.id : null;
      }
    });

    let filtered = cloneDeep(sortedFlattenChannels);

    Object.values(streamsInExpression).forEach((stream) => {
      const allowedGroupsForThisStream = accessListAllSourcesData[stream];
      if (allowedGroupsForThisStream) {
        filtered = filtered.filter(
          (ch) =>
            allowedGroupsForThisStream.includes(ch.groupId) ||
            allowedGroupsForThisStream.includes('*') ||
            ch.type === 'HEADER' ||
            (channelByMetricSource[stream] || []).includes(ch.value), // ==> GABPAC information on the channels
        );
      }
    });

    const listOfOrfansHeaders = filtered.filter((channel) => {
      if (channel.type === 'HEADER') {
        const contentCount = filtered.filter((chan) => chan.type === channel.value).length;
        if (contentCount === 0) {
          return true;
        }
      }
      return false;
    });

    listOfOrfansHeaders.forEach((header) => {
      const index = filtered.findIndex((channel) => channel.value === header.value);
      filtered.splice(index, 1);
    });

    return filtered;
  }, [
    JSON.stringify(streamsInExpression),
    JSON.stringify(sortedFlattenChannels),
    JSON.stringify(accessListAllSourcesData),
    JSON.stringify(accessListAllSourcesData),
    JSON.stringify(accessListChannelsData),
    JSON.stringify(channelByMetricSource),
  ]);

  const value = useMemo(
    () =>
      [...subscribers, ...channels]
        .map((item) => sortedFlattenChannels.find((option) => option.value === item))
        .filter((item) => item),
    [subscribers, channels, sortedFlattenChannels],
  );

  const onClearAll = useCallback(
    (arr) => {
      if (arr.length > 0) {
        onChangeSubscribers([]);
        onChangeChannels([]);
      } else {
        const optionsSubscribers = streamsFilteredByAccesslist
          .filter((item) => item.type === 'USERS')
          .map((item) => item.value);
        const optionsChannels = sortedFlattenChannels.filter((item) => item.type !== 'USERS').map((item) => item.value);
        onChangeSubscribers(optionsSubscribers);
        onChangeChannels(optionsChannels);
      }
    },
    [streamsFilteredByAccesslist],
  );

  useEffect(() => {
    value.forEach((v) => {
      // eslint-disable-next-line no-param-reassign
      delete v.groupId;
    });
  }, [value]);

  useEffect(() => {
    streamsFilteredByAccesslist.forEach((v) => {
      // eslint-disable-next-line no-param-reassign
      delete v.groupId;
    });
  }, [streamsFilteredByAccesslist]);

  return (
    <TooltipArea
      isAlwaysVisible
      automationId="recipientsContainer"
      text="Who will get the alert triggeres in case of an anomaly"
    >
      {(info) => (
        <Box width="102%">
          <div className="text18med lineHeight_16 mb_1">Recipients</div>
          <div>
            Who should we notify about triggers?
            {info}
          </div>
          <Box width={230} position="relative">
            <Box
              component="input"
              width={0}
              height={0}
              border={0}
              fontSize={0}
              position="absolute"
              name="subscribers"
              onFocus={() => selectRef?.current?.focus()}
              zIndex={-1}
            />
            <UsersChannelsFilter
              refSelectComponent={(el) => {
                selectRef.current = el;
              }}
              placeholder={isLoadingChannels || isLoadingUsers ? 'Loading...' : 'Select'}
              onChange={handleChange}
              sortedFlattenChannels={streamsFilteredByAccesslist}
              value={value}
              footerComponent={
                <FormDdlActionBar isVisible selectedCount={value.length} onClearAll={() => onClearAll(value)} />
              }
            />
          </Box>
          {touched && error && (
            <TypographyBox variant="subtitle2" mt={0.5} color="red.500">
              {error}
            </TypographyBox>
          )}
        </Box>
      )}
    </TooltipArea>
  );
};

export default Recipients;
