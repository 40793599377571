import React, {useMemo} from 'react';

const COLORS = {
  BLUE_500: {color: '#2671FF', name: 'blue-500'},
  GRAY_200: {color: '#E9E9F0', name: 'gray-200'},
};

export default function RecommendationFalsePositivesSvg({
  falsePositivesPercentage,
}: {
  falsePositivesPercentage: Number,
}) {
  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
    const d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');
    return d;
  };

  const positivesAngle = falsePositivesPercentage * (220 / 100) - 110;
  const x = 510;
  const y = 460;
  const r = 420;
  const startAngle = 110; // This defined the start angle of the
  const strokeWidth = 65;
  const spaceBetweenPositiveAndNegativeCurve = 7;

  const positivesLinePath = describeArc(
    x,
    y,
    r,
    -startAngle,
    falsePositivesPercentage === 100 ? positivesAngle : positivesAngle - spaceBetweenPositiveAndNegativeCurve,
  );
  const negativesPath = describeArc(
    x,
    y,
    r,
    falsePositivesPercentage === 0 ? positivesAngle : positivesAngle + spaceBetweenPositiveAndNegativeCurve,
    startAngle,
  );

  const xTAxisValue = useMemo(() => {
    const digitsNumber = falsePositivesPercentage?.toString()?.length ?? 0;
    if (digitsNumber === 1) {
      return '35%';
    }
    if (digitsNumber === 2) {
      return '27%';
    }
    if (digitsNumber === 3) {
      return '20%';
    }
    return '25%';
  }, [falsePositivesPercentage]);

  return (
    <svg width={300} height={100} viewBox="0 0 1000 650">
      <text fill={COLORS.BLUE_500.color} fontFamily="Roboto" fontSize="280" fontWeight={400} letterSpacing="-0.055em">
        <tspan x={xTAxisValue} y="80%">
          {falsePositivesPercentage}%
        </tspan>
      </text>
      {falsePositivesPercentage !== 0 ? (
        <path
          id="arc1"
          fill="none"
          stroke={COLORS.BLUE_500.color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          d={positivesLinePath}
        />
      ) : (
        undefined
      )}
      {falsePositivesPercentage !== 100 ? (
        <path
          id="arc2"
          fill="none"
          stroke={COLORS.GRAY_200.color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          d={negativesPath}
        />
      ) : (
        undefined
      )}
    </svg>
  );
}
