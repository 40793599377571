import React from 'react';
import {useField} from 'react-final-form';
import MultiButton from 'common/componentsV2/multiButton/MultiButton';
import TooltipArea from 'common/componentsV2/TooltipArea';
import {palette} from 'app/styles/theme';

const SEVERITY_TYPES = [
  {
    label: 'Info',
    value: 'info',
    color: palette.gray[500],
  },
  {
    label: 'Low',
    value: 'low',
    color: palette.green[400],
  },
  {
    label: 'Medium',
    value: 'medium',
    color: palette.yellow[300],
  },
  {
    label: 'High',
    value: 'high',
    color: palette.orange[400],
  },
  {
    label: 'Critical',
    value: 'critical',
    color: palette.red[500],
  },
];

const AlertName = () => {
  const {
    input: {value, onChange},
  } = useField('severity');
  return (
    <TooltipArea
      isAlwaysVisible
      automationId="severityContainer"
      text="Choose the severity of this alert, this will show up on every trigger"
    >
      {(info) => (
        <React.Fragment>
          <div className="text16reg lineHeight_16 mb_1">
            Severity
            {info}
          </div>
          <MultiButton automationId="severityTypes" value={value} options={SEVERITY_TYPES} onChange={onChange} />
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default AlertName;
