// @flow
import React, {useCallback, useState} from 'react';
import {Form} from 'react-final-form';
import {Backdrop, Modal, makeStyles} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {getPredefinedDateUserSetting} from 'profile/store/selectors';
import {updatePredefinedDatesUserSettings} from 'common/components/dateTime/store/actions';
import {getUniqueId} from 'common/utils/guid';
import * as dateRangeService from '../../utils/dateRangeService';
import TimePreset from './TimePreset';
import DateRange from './DateRange';
import {calculateScale, OPTIONS_VALUE, transformToSeconds} from './customDateRangeService';

type PropsType = {
  isOpen: boolean,
  onClose: Function,
  onSelect: Function,
  dateRange: Object,
  timeZoneName: String,
  isUnix: boolean,
};
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    margin: 'auto',
    width: 800,
    height: 536,
    top: '50%',
    right: 0,
    left: 0,
    transform: 'translateY(-50%)',
    backgroundColor: theme.palette.white[500],
    padding: 30,
    borderRadius: 6,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.2)',
  },
  title: {
    fontWeight: 300,
    fontSize: 22,
    lineHeight: '26px',
  },
  closeBtn: {
    position: 'absolute',
    right: 23,
    top: 23,
    cursor: 'pointer',
    borderRadius: '1px',
  },
}));

const validateForm = (formValues) => {
  const errors = {};
  // Relative validations
  if (formValues.rangeType === OPTIONS_VALUE.RELATIVE || formValues.rangeType === OPTIONS_VALUE.RELATIVE_RANGE) {
    if (formValues.relativeLast === undefined) errors.relativeLast = 'required';
    if (formValues.relativeLast <= 0) errors.relativeLast = 'must be grater than 0';
    if (formValues.rangeType === OPTIONS_VALUE.RELATIVE_RANGE && formValues.relativeNext === undefined)
      errors.relativeNext = 'required';
  }
  // Range validations
  if (formValues.rangeType === OPTIONS_VALUE.RANGE) {
    if (!formValues.dateRange.endDate) errors.dateRange = {endDate: 'required'};
    if (formValues.dateRange.isInvalid) errors.dateRange = {isInvalid: 'Error with start/end time'};
    if (formValues.dateRange.endDate && formValues.dateRange.endDate < formValues.dateRange.startDate)
      errors.dateRange = {endTime: 'must be greater than start date', startTime: 'must be less than end date'};
  }

  return errors;
};

const CustomDateRangeModalV2 = ({isOpen, onSelect, onClose, dateRange, timeZoneName, isUnix}: PropsType) => {
  const classes = useStyles();
  const dataPredefinedDates = useSelector(getPredefinedDateUserSetting);
  const dispatch = useDispatch();

  const [isManagePresets, setIsManagePresets] = useState(false);

  const isCustomRangeType = dateRange.constRange === 'c';

  const {value: relativeLast, scale: relativeLastScale} = calculateScale(dateRange.relativeLast || 3600 * 24); // default last 1 day
  const {value: relativeNext, scale: relativeNextScale} = calculateScale(dateRange.relativeNext, relativeLastScale);

  const handleSubmit = useCallback(
    (formValues) => {
      // Create new custom time frame
      if (formValues.rangeType === OPTIONS_VALUE.RANGE) {
        onSelect({
          dateRange: dateRangeService.getDateValue(
            {
              constRange: 'c',
              startDate: formValues.dateRange.startDate,
              endDate: formValues.dateRange.endDate,
            },
            isUnix,
            timeZoneName,
          ),
        });
      } else {
        onSelect({
          dateRange: dateRangeService.getDateValue(
            {
              constRange: 'r',
              relativeLast: transformToSeconds(formValues.relativeLast, formValues.relativeLastScale),
              relativeNext: transformToSeconds(formValues.relativeNext, formValues.relativeNextScale),
            },
            isUnix,
            timeZoneName,
          ),
        });
      }
      // handle save preset
      if (formValues.savePreset) {
        const isRange = formValues.rangeType === OPTIONS_VALUE.RANGE;
        const isRelative =
          formValues.rangeType === OPTIONS_VALUE.RELATIVE || formValues.rangeType === OPTIONS_VALUE.RELATIVE_RANGE;
        dispatch(
          updatePredefinedDatesUserSettings({
            preDefinedDates: dataPredefinedDates.preDefinedDates,
            customDates: [
              ...dataPredefinedDates.customDates,
              {
                value: isRange ? 'c' : 'r',
                startDate: isRange ? formValues.dateRange.startDate : undefined,
                endDate: isRange ? formValues.dateRange.endDate : undefined,
                relativeLast: isRelative
                  ? transformToSeconds(formValues.relativeLast, formValues.relativeLastScale)
                  : undefined,
                relativeNext: isRelative
                  ? transformToSeconds(formValues.relativeNext, formValues.relativeNextScale)
                  : undefined,
                text: isRange
                  ? dateRangeService.createCustomLabel(formValues.dateRange.startDate, formValues.dateRange.endDate)
                  : dateRangeService.createRelativeLabel(
                      transformToSeconds(formValues.relativeLast, formValues.relativeLastScale),
                      transformToSeconds(formValues.relativeNext, formValues.relativeNextScale),
                    ),
                isSelected: true,
                id: getUniqueId(),
              },
            ],
          }),
        );
      }
      onClose();
    },
    [onClose, onSelect, dataPredefinedDates],
  );

  const initialRangeType = (range) => {
    if (range.constRange === 'c') {
      return OPTIONS_VALUE.RANGE;
    }
    if (range.constRange === 'r' && range.relativeLast && range.relativeNext === 0) {
      return OPTIONS_VALUE.RELATIVE;
    }
    if (range.constRange === 'r') {
      return OPTIONS_VALUE.RELATIVE_RANGE;
    }

    return OPTIONS_VALUE.RELATIVE;
  };

  const initialDateRange = {
    rangeType: initialRangeType(dateRange),
    dateRange: {
      ...dateRange,
      startDate: isCustomRangeType ? dateRange.startDate : '',
      endDate: isCustomRangeType ? dateRange.endDate : '',
      // since time is not a field in the form, we need this flag to set the for as invalid
      isInvalid: false,
      savePreset: false,
    },
    relativeLast,
    relativeLastScale,
    relativeNext,
    relativeNextScale,
  };

  return (
    <Modal open={isOpen} onClose={onClose} BackdropComponent={Backdrop} disableEnforceFocus>
      <Form initialValues={initialDateRange} onSubmit={handleSubmit} validate={validateForm} keepDirtyOnReinitialize>
        {() => (
          <div className={classes.wrapper}>
            <div
              className={classes.closeBtn}
              onClick={() => {
                setIsManagePresets(false);
                onClose();
              }}
            >
              <i className="icon icn-general16-closea" />
            </div>
            {!isManagePresets ? (
              <DateRange
                showPresets={() => {
                  setIsManagePresets(true);
                }}
              />
            ) : (
              <>
                <TimePreset
                  showDateRange={() => {
                    setIsManagePresets(false);
                  }}
                />
              </>
            )}
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default CustomDateRangeModalV2;
