import {createSelector} from 'reselect';
import {selectors as commonSelectors} from 'common';
import {get, isNil} from 'lodash';

import {getUsersData} from 'admin.users/store/selectors';
import {selectors as profileSelector} from 'profile';
import * as commonSelector from 'common/store/selectors';
import {getPageNameSegment} from 'common/utils/http';

const EMPTY_ARRAY = [];

const mapAcknowledgedOwners = (users = [], triggeredAlerts) => {
  const configureAlertGroups = triggeredAlerts.alertGroups.map((alertGroup) => {
    const configureAlerts = alertGroup.alerts.map((alert) => {
      const acknowledgedBy = [];
      alert.stars.forEach((id) => {
        users.forEach((user) => {
          if (user._id === id) {
            acknowledgedBy.push({
              fullName: `${user.firstName} ${user.lastName}`,
              shortName: user.firstName.substring(0, 1).toUpperCase(),
              id,
            });
          }
        });
      });
      return {
        ...alert,
        acknowledgedBy,
      };
    });
    return {
      ...alertGroup,
      alerts: configureAlerts,
    };
  });

  return {
    ...triggeredAlerts,
    alertGroups: configureAlertGroups,
  };
};

export const {getAlertsConsole} = commonSelectors;

// Views
export const getAlertConsoleViews = createSelector(
  getAlertsConsole,
  (ac) => ac.views,
);

export const getQueryParamsViews = createSelector(
  getAlertConsoleViews,
  (views) => views.queryParams || {},
);

export const getQueryParamsTagsViews = createSelector(
  getQueryParamsViews,
  (qp) => qp.labels,
);

export const getScrollbarWidth = createSelector(
  getAlertConsoleViews,
  (acViews) => (acViews.scrollbarWidth ? acViews.scrollbarWidth * 2 : 0),
);

export const getIsListScrolled = createSelector(
  getAlertConsoleViews,
  (acViews) => acViews.isListScrolled,
);

export const getIsAlertsListScrolling = createSelector(
  getAlertConsoleViews,
  (acViews) => acViews.isListScrolled,
);

export const getIsMoreFilterOpen = createSelector(
  profileSelector.getMeAppSettings,
  (appSettings) => get(appSettings, 'alertsConsole.isMoreFilterOpen', false),
);

export const getIsFiltersBarOpen = createSelector(
  profileSelector.getMeAppSettings,
  (appSettings) => get(appSettings, 'alertsConsole.isFiltersBarOpen', true),
);

export const getAlertConsoleOpenItems = createSelector(
  getAlertConsoleViews,
  (acViews) => acViews.openItems,
);

export const getAlertConsoleInvestigationModal = createSelector(
  getAlertConsoleViews,
  (acViews) => acViews.investigationModal,
);

// Data
export const getAlertConsoleData = createSelector(
  getAlertsConsole,
  (ac) => ac.data,
);

export const getQueryParamsData = createSelector(
  profileSelector.getMeAppSettings,
  (appSettings) => get(appSettings, 'alertsConsole.queryParams', {}),
);

//* ** Snooze trigger data
export const getSnoozeData = createSelector(
  getAlertConsoleData,
  (ac) => ac.snoozeData,
);

export const getSnoozedModalState = createSelector(
  getAlertConsoleViews,
  (sd) => sd.modalState,
);

export const getSnoozedMetricsFilter = createSelector(
  getSnoozedModalState,
  (ms) => ms.metricsFilter,
);

export const getSnoozeDataTriggeredAlert = createSelector(
  getSnoozeData,
  (ac) => ac.triggeredAlert,
);

export const isLoadingSnoozeData = createSelector(
  getSnoozeDataTriggeredAlert,
  (ta) => ta.isLoading !== false,
);

export const getSnoozedAllMetrics = createSelector(
  getSnoozeDataTriggeredAlert,
  (sd) => sd.data.metrics || EMPTY_ARRAY,
);

export const getSnoozedFilteredMetrics = createSelector(
  getSnoozedAllMetrics,
  getSnoozedMetricsFilter,
  (metrics, filterText) => metrics.filter((m) => m.id.toLowerCase().indexOf(filterText.toLowerCase()) >= 0),
);

export const getIsSnoozeAllInSnoozedMetrics = createSelector(
  getSnoozedAllMetrics,
  (metrics) => metrics.filter((m) => !isNil(m.snooze)).length === metrics.length && metrics.length !== 0,
);

//* ** STL(stop the learning) trigger data
export const getStl = createSelector(
  getSnoozeData,
  (sd) => sd.stopLearning,
);

export const getSTLAllMetrics = createSelector(
  getSnoozeDataTriggeredAlert,
  (sd) => sd.data.metrics || EMPTY_ARRAY,
);

export const getIsStlAllInMetrics = createSelector(
  getSTLAllMetrics,
  (metrics) => metrics.filter((m) => !isNil(m.stopLearning)).length === metrics.length && metrics.length !== 0,
);

//* ** Acknowledged
export const getAckAlerts = createSelector(
  getUsersData,
  getAlertConsoleData,
  (users, triggeredAlerts) => mapAcknowledgedOwners(users, triggeredAlerts.ackAlerts),
);

export const getAckAlertsIsLoading = createSelector(
  getAckAlerts,
  (ac) => ac.isLoading,
);

export const getAlertAckEvents = createSelector(
  getAlertConsoleData,
  (ac) => ac.ackEvents,
);

export const getAckMetrics = createSelector(
  getAlertConsoleData,
  (ac) => ac.ackMetrics,
);

export const getAckTotal = createSelector(
  getAckAlerts,
  (ack) => ack.alertGroups.length,
);

//* ** Not Acknowledged
export const getNoAckAlerts = createSelector(
  getUsersData,
  getAlertConsoleData,
  (users, triggeredAlerts) => mapAcknowledgedOwners(users, triggeredAlerts.noAckAlerts),
);

export const getNoAckAlertsIsLoading = createSelector(
  getNoAckAlerts,
  (ac) => ac.isLoading,
);

export const getAlertNoAckEvents = createSelector(
  getAlertConsoleData,
  (ac) => ac.noAckEvents,
);

export const getNoAckMetrics = createSelector(
  getAlertConsoleData,
  (ac) => ac.noAckMetrics,
);

export const getNoAckTotal = createSelector(
  getNoAckAlerts,
  (noAck) => noAck.alertGroups.length,
);

export const getDisplayedTotalAlerts = createSelector(
  getNoAckTotal,
  getAckTotal,
  (noAckT, ackT) => {
    const totalAlerts = noAckT + ackT;
    return Number.isInteger(totalAlerts) ? totalAlerts : null;
  },
);

// Feedback

export const getFeedbackRequest = createSelector(
  getAlertConsoleData,
  (data) => data.setFeedbackRequest,
);

export const getFeedbackRequestId = createSelector(
  getFeedbackRequest,
  (feedbackRequest) => get(feedbackRequest, 'data.id', null),
);

export const getFeedbackRequestIsLoading = createSelector(
  getFeedbackRequest,
  (feedbackRequest) => feedbackRequest.isLoading,
);

export const getDeleteFeedbackRequest = createSelector(
  getAlertConsoleData,
  (data) => data.deleteFeedbackRequest,
);

export const getDeleteFeedbackRequestIsLoading = createSelector(
  getDeleteFeedbackRequest,
  (data) => data.isLoading,
);

export const getUpdateFeedbackRequest = createSelector(
  getAlertConsoleData,
  (data) => data.updateFeedbackRequest,
);

export const getUpdateFeedbackRequestIsLoading = createSelector(
  getUpdateFeedbackRequest,
  (data) => data.isLoading,
);

// End Feedback
export const getAlertsTriggeredTotal = createSelector(
  getAlertConsoleData,
  (data) => get(data, 'totalTriggeredAlerts.data.total', null),
);

export const getAlertsTriggeredTotalLastDay = createSelector(
  getAlertConsoleData,
  (data) => get(data, 'totalTriggeredAlertsLastDay.data.total', null),
);

export const getIsLoadingAlertsTriggeredTotalLastDay = createSelector(
  getAlertConsoleData,
  (data) => data.totalTriggeredAlertsLastDay.isLoading,
);

export const getIsFirstTimeVisit = createSelector(
  profileSelector.getPageVisited,
  commonSelector.getRoutingLocation,
  (pageVisited, getRoutingLocation) => {
    const isThisPageVisited = pageVisited.find((i) => getPageNameSegment(getRoutingLocation.pathname) === i.name);
    const date = new Date();
    const now = date.getTime();
    if (isThisPageVisited && isThisPageVisited.firstTimeSig) {
      const passingTime = isThisPageVisited ? now - isThisPageVisited.firstTimeSig : 0;
      return passingTime < 30 * 1000;
    }
    return true;
  },
);
