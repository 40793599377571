import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/';

// const fetchSchemas = async (sourceId) => {
const getTables = async (type, sourceId, schemaName) => {
  return get(`${root}data-sources/${type}/${sourceId}/tables?schemaName=${schemaName}`);
};

export default function useFetchTables(type, sourceId, schemaName) {
  const fallback = [];

  const selectFn = useCallback(
    (data) => {
      return data.map((i) => ({...i, value: i.name, label: i.name}));
    },
    [sourceId],
  );

  const {
    data: tables = fallback,
    isLoading: isTablesLoading,
    isFetching: isTablesFetching,
    refetch: refetchTablesFetching,
  } = useQuery(QUERY_KEYS.tables, () => getTables(type, sourceId, schemaName), {
    enabled: !!(type && sourceId && schemaName),
    select: selectFn,
  });

  return {tables, isTablesLoading, isTablesFetching, refetchTablesFetching};
}
