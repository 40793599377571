import React, {useMemo} from 'react';
import {Backdrop, Box, Modal} from '@material-ui/core';
import Button, {COLORS} from 'common/componentsV2/Button';
import {StringParam, useQueryParams} from 'use-query-params';
import {RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM} from '../../../../recommendations/services/consts';

const CancellationModal = ({
  isOpened,
  onClose,
  onConfirm,
}: {
  isOpened: boolean,
  onClose: Function,
  onConfirm: Function,
}) => {
  const [queryParams] = useQueryParams({
    [RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM.RECOMMENDATION_TYPES]: StringParam,
  });

  const cancellationText = useMemo(() => {
    let text = 'All changes made to the alert will not be saved, are you sure you want to cancel?';
    if (queryParams[RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM.RECOMMENDATION_TYPES]) {
      text = 'Recommendations and other changes will not be saved, are you sure you want to cancel?';
    }
    return text;
  }, [queryParams[RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM.RECOMMENDATION_TYPES]]);

  return (
    <Modal BackdropComponent={Backdrop} open={isOpened} onClose={onClose}>
      <Box
        automation-id="cancellationModal"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width={378}
        height={195}
        bgcolor="white.500"
        borderRadius={4}
        css={{boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)'}}
        margin="auto"
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        left={0}
        p={3}
        pb={2}
      >
        <div>
          <div className="text16med lineHeight_16 mb_1">Exit without saving?</div>
          <div>{cancellationText}</div>
        </div>
        <div className="display_flex justifyContent_space-between">
          <Button colorSchema={COLORS.GRAY_300} text="Back to Editing" onClick={onClose} />
          <Button colorSchema={COLORS.BLUE_500} text="Exit Without Saving" onClick={onConfirm} />
        </div>
      </Box>
    </Modal>
  );
};

export default CancellationModal;
