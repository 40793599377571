/* eslint-disable no-param-reassign */
import {createSelector} from 'reselect';
import {get} from 'lodash';
import {selectors as commonSelectors} from 'common';
import {getAckAlerts, getNoAckAlerts} from 'alerts.console/store/selectors';
import {selectors as profileSelector} from 'profile';
import {getAlertConfigurationsItems} from 'alerts.management/store/selectors';

export const {getInvestigationNew} = commonSelectors;

const EMPTY_OBJECT = {};

// View
export const getInvestigationView = createSelector(
  getInvestigationNew,
  (inv) => inv.views,
);

export const getInvModalTrigger = createSelector(
  getInvestigationView,
  (view) => view.trigger || EMPTY_OBJECT,
);

export const getInvAlertMeasures = createSelector(
  getInvestigationView,
  (view) => view.alertMeasures,
);

export const getInvOtherMeasures = createSelector(
  getInvestigationView,
  (view) => view.otherMeasures,
);

export const getInvAnomalyStarted = createSelector(
  getInvestigationView,
  (view) => view.anomalyStarted,
);

export const getInvScore = createSelector(
  getInvestigationView,
  (view) => view.score,
);

export const getInvWhere = createSelector(
  getInvestigationView,
  (view) => view.where,
);

export const getInvModalIncident = createSelector(
  getInvestigationView,
  (modal) => modal.incident,
);

export const getInvModalIncidentFilters = createSelector(
  getInvModalIncident,
  (incident) => incident.filters,
);

export const getInvModalCorrelations = createSelector(
  getInvestigationView,
  (view) => view.correlations,
);

export const getInvModalCorrelationsFilters = createSelector(
  getInvModalCorrelations,
  (correlations) => correlations.filters,
);

export const getIsOpen = createSelector(
  getInvestigationView,
  (view) => view.isOpen,
);

export const getIsMinimize = createSelector(
  getInvestigationView,
  (view) => view.isMinimize,
);

export const getAnomalyId = createSelector(
  getInvestigationView,
  (view) => view.anomalyId,
);

export const getTriggerId = createSelector(
  getInvestigationView,
  (view) => view.triggerId,
);

// *** Data ****

export const getMoreAlertsInAnomalyGroup = createSelector(
  getAckAlerts,
  getNoAckAlerts,
  getAnomalyId,
  (ackAlertsGroups, noAckAlertsGroups, anomalyId) => {
    if (ackAlertsGroups.alertGroups.length || noAckAlertsGroups.alertGroups.length) {
      const group = [...ackAlertsGroups.alertGroups, ...noAckAlertsGroups.alertGroups].find((i) => i.id === anomalyId);
      return group && group.alerts ? group.alerts.map((alert) => alert.id) : [];
    }
    return [];
  },
);

export const getInvestigationData = createSelector(
  getInvestigationNew,
  (inv) => inv.data,
);

// fetch triggered alert
export const getInvDataFetchTriggeredAlert = createSelector(
  getInvestigationData,
  (data) => data.fetchTriggeredAlert || EMPTY_OBJECT,
);

export const getInvDataFetchTriggeredAlertData = createSelector(
  getInvDataFetchTriggeredAlert,
  (trigger) => trigger.data,
);

export const getTriggeredAlertConfigurations = createSelector(
  getInvDataFetchTriggeredAlertData,
  getAlertConfigurationsItems,
  (trigger, alerts) => {
    const overview = EMPTY_OBJECT;

    if (!trigger || !trigger.metrics || !trigger.metrics.length) {
      return overview;
    }

    const triggerAlertId = trigger.alertConfigurationId;
    const alertConfiguration = alerts.find((alert) => alert.data.id === triggerAlertId);
    return alertConfiguration || EMPTY_OBJECT;
  },
);

export const getAlertThresholdConditions = createSelector(
  getTriggeredAlertConfigurations,
  (alertConfig) => {
    const thresholds = alertConfig.data?.conditions?.find((condition) => condition.type === 'THRESHOLD_CONDITION');
    if (thresholds) {
      return {minValue: thresholds.minValue, maxValue: thresholds.maxValue};
    }
    return {};
  },
);

export const getNumberActiveMetrics = createSelector(
  getTriggeredAlertConfigurations,
  (alertConfig) => alertConfig.data?.numOfActiveMetrics || 0,
);

// fetch Incident TokenMap
export const getIncidentTokenMap = createSelector(
  getInvestigationData,
  (data) => data.fetchAnomaliesTokenMap,
);

export const getIncidentTokenMapData = createSelector(
  getIncidentTokenMap,
  (anomaliesTokenMap) => anomaliesTokenMap.data,
);

export const getIncidentTokenMapCounter = createSelector(
  getIncidentTokenMapData,
  (data) => (data && data.metricsCounter ? data.metricsCounter : EMPTY_OBJECT),
);

export const getIncidentTokenMapTotal = createSelector(
  getIncidentTokenMapData,
  (data) => (data && data.totalMetrics ? data.totalMetrics : EMPTY_OBJECT),
);

export const getIncidentTokenMapIsLoading = createSelector(
  getIncidentTokenMap,
  (anomaliesTokenMap) => anomaliesTokenMap.isLoading,
);

// fetch tokenMap Correlations
export const getCorrelationsTokenMap = createSelector(
  getInvestigationData,
  (data) => data.fetchAnomaliesTokenMapCorrelations,
);

export const getCorrelationsTokenMapData = createSelector(
  getCorrelationsTokenMap,
  (anomaliesTokenMap) => anomaliesTokenMap.data,
);

export const getCorrelationsTokenMapCounter = createSelector(
  getCorrelationsTokenMapData,
  (data) => (data && data.metricsCounter ? data.metricsCounter : EMPTY_OBJECT),
);

export const getCorrelationsTokenMapTotal = createSelector(
  getCorrelationsTokenMapData,
  (data) => (data && data.totalMetrics ? data.totalMetrics : EMPTY_OBJECT),
);

export const getCorrelationsTokenMapIsLoading = createSelector(
  getCorrelationsTokenMap,
  (anomaliesTokenMap) => anomaliesTokenMap.isLoading,
);

export const getCorrelationsTokenMapDataFiltered = createSelector(
  getCorrelationsTokenMapData,
  getInvOtherMeasures,
  (tokenMap, measures) => {
    const filteredTokens = {};

    if (!tokenMap || !measures.length) {
      return filteredTokens;
    }
    measures.forEach((what) => {
      if (tokenMap.dimMap[what]) {
        filteredTokens[what] = tokenMap.dimMap[what];
      }
    });
    return filteredTokens;
  },
);

// Data fetch alert's metrics
export const getFetchAlertMetrics = createSelector(
  getInvestigationData,
  (data) => data.fetchAlertMetrics || EMPTY_OBJECT,
);

// Data fetch all metrics
export const getFetchAllMetrics = createSelector(
  getInvestigationData,
  (data) => data.fetchAllMetrics || EMPTY_OBJECT,
);

export const getFetchAllMetricsIsLoading = createSelector(
  getInvestigationData,
  (data) => data.fetchAllMetrics || EMPTY_OBJECT,
);

export const getFetchAlertMetricsCorrelation = createSelector(
  getFetchAllMetrics,
  getInvOtherMeasures,
  (metrics, measures) => {
    const filteredMetrics = {};
    Object.keys(metrics).forEach((what) => {
      if (measures.includes(what)) {
        filteredMetrics[what] = metrics[what];
      }
    });
    return filteredMetrics;
  },
);

export const getFeedbackRequest = createSelector(
  getInvestigationData,
  (data) => data.feedbackRequest || EMPTY_OBJECT,
);

export const getMetricList = createSelector(
  getInvestigationData,
  (data) => data.metricList || EMPTY_OBJECT,
);

export const getFeedbackRequestId = createSelector(
  getFeedbackRequest,
  (feedbackRequest) => get(feedbackRequest, 'data.id', null),
);

export const getFeedbackRequestIsLoading = createSelector(
  getFeedbackRequest,
  (feedbackRequest) => feedbackRequest.isLoading,
);

// Timeline
export const getTimeline = createSelector(
  getInvestigationData,
  (investigationData) => investigationData.fetchTimeline,
);

export const getTimelineData = createSelector(
  getTimeline,
  (timeline) => timeline.data,
);

export const getTimelineIsLoading = createSelector(
  getTimeline,
  (timeline) => timeline.isLoading,
);

export const getPostComment = createSelector(
  getInvestigationData,
  (timeline) => timeline.postComment,
);

export const getPostCommentIsLoading = createSelector(
  getPostComment,
  (postComment) => postComment.isLoading,
);

export const getInvestigationSettings = createSelector(
  profileSelector.getMeAppSettings,
  (appSettings) => get(appSettings, 'investigation', null),
);

export const getActionsInvestigationSettings = createSelector(
  getInvestigationSettings,
  (investigation) => get(investigation, 'actions', null),
);
