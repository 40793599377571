// @flow
import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/user-events/');

const mac = (actionName) => makeAction(actionName, 'anodot/user-events/');

export const fetchUserEventsCategories = macAsync('FETCH_USER_EVENTS_CATEGORIES');
export const createUserEventsCategories = macAsync('CREATE_USER_EVENTS_CATEGORIES');
export const deleteUserEventsCategory = macAsync('DELETE_USER_EVENTS_CATEGORY');

export const fetchUserEventsSources = macAsync('FETCH_USER_EVENTS_SOURCES');
export const crateUserEventsSource = macAsync('CREATE_USER_EVENTS_SOURCE');
export const deleteUserEventsSource = macAsync('DELETE_USER_EVENTS_SOURCE');

export const fetchPropAndVal = macAsync('FETCH_PROP_AND_VAL');
export const fetchPropAndValList = mac('FETCH_PROP_AND_VAL_LIST');
export const fetchPropAndValListApi = macAsync('FETCH_PROP_AND_VAL_LIST_API');
export const fetchProps = mac('FETCH_PROPS');
export const fetchPropsApi = macAsync('FETCH_PROPS_API');
export const setExpression = mac('SET_EXPRESSION');
export const setExecuteParams = mac('SET_EXECUTE_PARAMS');
export const setShouldShowEvents = mac('SET_SHOULD_SHOW_EVENTS');
export const getEvents = mac('GET_EVENTS');
export const getMultipleEvents = mac('GET_MULTIPLE_EVENTS');
export const setMultipleEventsFilters = mac('SET_MULTIPLE_EVENTS_FILTERS');
export const execute = macAsync('EXECUTE');
export const executeMultipleEvents = macAsync('EXECUTE_MULTIPLE_EVENTS');
export const getMoreTopEvents = mac('GET_MORE_TOP_EVENTS');
export const executeMoreTopEvents = macAsync('EXECUTE_MORE_TOP_EVENTS');
export const fetchEventsCount = macAsync('FETCH_EVENTS_COUNT');
export const fetchMetricDimensions = macAsync('FETCH_METRIC_DIMENSIONS');
export const fetchEventsProperties = macAsync('FETCH_EVENTS_PROPERTIES');
export const resetEventsProperties = mac('RESET_EVENTS_PROPERTIES');
