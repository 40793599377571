import React from 'react';
import {palette} from 'app/styles/theme';

import '../IncidentTab.module.scss';

type PropTypes = {
  filters: Object,
  subtitle: String,
  counter: Number,
};

const IncidentTabSubtitle = ({filters, subtitle, counter}: PropTypes) => {
  return (
    <div styleName="subtitle">
      {subtitle}
      <div style={{color: filters.length ? palette.teal['500'] : palette.gray['400']}}>({counter})</div>
    </div>
  );
};

export default IncidentTabSubtitle;
