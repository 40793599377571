import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {makeStyles} from '@material-ui/core';

import {getTimeZoneName} from 'profile/store/selectors';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {guessTimeZone} from 'dataCollectors/utils/timeZone';

const useStyles = makeStyles(({typography, palette}) => ({
  accountTimeZone: {
    ...typography.body,
    color: palette.gray['350'],
  },
}));

const timeZones = moment.tz.names().map((i) => ({
  value: i,
  label: i,
}));

timeZones.unshift({value: 'Browser', label: 'Browser'});

export default function TimeZone({onChange, selectedValue, isLoading}) {
  const classes = useStyles();

  const userTimeZone = useSelector(getTimeZoneName);
  const [selectedItem, setSelectedItem] = useState(null);

  const onSelect = (item) => {
    const value = guessTimeZone(item.value);
    onChange(value);
  };

  useEffect(() => {
    const selectedIndex = timeZones.findIndex((i) => i.value === selectedValue);

    setSelectedItem(timeZones[selectedIndex]);
  }, [selectedValue]);

  return (
    <>
      <FormDdlSelect
        placement="top"
        isDisabledSorting
        options={timeZones}
        selected={selectedItem}
        onChange={onSelect}
        button={
          <BigDropdownButton
            isPristine={!selectedItem}
            label={selectedItem?.label}
            placeholder={isLoading ? 'Loading..' : 'Select'}
            shortBlueStyle
            isFullWidth
          />
        }
        optionComponent={<OptionComponentSimple />}
        width={240}
        height={260}
      />
      <div className={classes.accountTimeZone}>This account is set to {guessTimeZone(userTimeZone || 'Browser')}</div>
    </>
  );
}

TimeZone.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
