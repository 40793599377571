// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {intersection} from 'lodash';
import Button, {COLORS as ButtonColors, HEIGHTS as ButtonHeights} from 'common/componentsV2/Button';
import SelectAndt, {
  THEME_HIGHLIGHTED,
  THEME_NOT_HIGHLIGHTED,
  THEME_BLUE_LEAN,
  TYPE_NEW_MULTI,
  TYPE_NEW_NO_SEARCH,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import Input from 'common/componentsV2/Input';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import * as selectors from 'admin.users/store/selectors';
import {isAnodot, getUserProfile} from 'profile/store/selectors';
import {
  setUserEditModalKeyVal,
  toggleUserEditModal,
  updateUser,
  fetchGroupsEditNewUser,
} from 'admin.users/store/actions';
import './EditUserForm.module.scss';
import {palette} from 'app/styles/theme';

type PropTypes = {
  me: Object,
  isMeAnodot: boolean,
  isLoading: boolean,
  groupsList: Array,
  isGroupsLoading: boolean,
  updatedUser: Object,
  editingUserId: String,
  firstName: String,
  lastName: String,
  email: String,
  ownerOrganization: String,
  orgName: String,
  selectedGroups: Array,
  allowedUserRoles: Array,
  selectedRole: Object,
  selectedDefaultGroup: Object,
  disabled: boolean,
  setUserEditModalKeyVal: Function,
  toggleUserEditModal: Function,
  updateUser: Function,
  fetchGroupsEditNewUser: Function,
};

export default connect(
  (state) => ({
    me: getUserProfile(state),
    isMeAnodot: isAnodot(state),
    isLoading: selectors.fetchUserIsLoading(state),
    groupsList: selectors.getGroupsDataList(state),
    isGroupsLoading: selectors.fetchGroupsEditNewUserIsLoading(state),
    updatedUser: selectors.getEditingUserObject(state),
    editingUserId: selectors.getEditingUserId(state),
    firstName: selectors.getEditingUserFirstName(state) || '',
    lastName: selectors.getEditingUserLastName(state) || '',
    email: selectors.getEditingUserEmail(state) || '',
    ownerOrganization: selectors.getEditingUserOwnerOrganization(state) || '',
    orgName: selectors.getEditingUserOrgName(state),
    selectedGroups: selectors.getEditingUserSelectedGroups(state),
    allowedUserRoles: selectors.getEditingUserAllowedRoles(state),
    disabled: selectors.getEditingUserIsDisabled(state),
    selectedRole: selectors.getEditingUserRole(state),
    selectedDefaultGroup: selectors.getEditingUserSelectedDefaultGroup(state),
  }),
  {
    setUserEditModalKeyVal,
    toggleUserEditModal,
    updateUser,
    fetchGroupsEditNewUser,
  },
)(
  class EditUserForm extends React.PureComponent {
    props: PropTypes;

    componentDidUpdate(prevProps) {
      const {isMeAnodot, ownerOrganization} = this.props;
      if (isMeAnodot && ownerOrganization && prevProps.ownerOrganization !== ownerOrganization) {
        this.props.fetchGroupsEditNewUser({ownerOrganization});
      }
    }

    groupsSelected = (items) => {
      if (!items || !items.length) {
        this.props.setUserEditModalKeyVal({
          groups: [],
          defaultGroup: '',
        });
      } else if (items.length === 1) {
        this.props.setUserEditModalKeyVal({
          groups: [items[0].id],
          defaultGroup: items[0].id,
        });
      } else {
        const groups = items.map((item) => item.id);

        if (intersection([this.props.selectedDefaultGroup.id || ''], groups).length === 0) {
          this.props.setUserEditModalKeyVal({
            groups,
            defaultGroup: groups[0],
          });
        } else {
          this.props.setUserEditModalKeyVal({
            groups,
          });
        }
      }
    };

    userDefaultGroupSelected = (item) => {
      this.props.setUserEditModalKeyVal({
        defaultGroup: item.id,
      });
    };

    userRoleSelected = (item) => {
      this.props.setUserEditModalKeyVal({
        roles: [item.role],
      });
    };

    firstNameChange = (e) => {
      this.props.setUserEditModalKeyVal({
        firstName: e.target.value,
      });
    };

    lastNameChange = (e) => {
      this.props.setUserEditModalKeyVal({
        lastName: e.target.value,
      });
    };

    setUserStatus = (e) => {
      this.props.setUserEditModalKeyVal({
        disabled: e.target.value === 'disabled',
      });
    };

    saveClicked = () => {
      this.props.updateUser(this.props.updatedUser);
      this.props.toggleUserEditModal({isOpen: false});
    };

    render() {
      const {
        me,
        isLoading,
        editingUserId,
        firstName,
        lastName,
        email,
        groupsList,
        isGroupsLoading,
        selectedGroups,
        allowedUserRoles,
        selectedRole,
        disabled,
        selectedDefaultGroup,
        orgName,
      } = this.props;

      if (isLoading) {
        return (
          <div styleName="container center">
            <Spinner color={palette.gray[500]} size={SpinnerSizes.XX_BIG_100} />
          </div>
        );
      }

      return (
        <div styleName="container">
          <div styleName="header">Edit User</div>
          <div styleName="names-row">
            <div styleName="item">
              <div styleName="label">First Name</div>
              <Input styleName="name-inputs" value={firstName} isInvalid={!firstName} onChange={this.firstNameChange} />
            </div>
            <div styleName="item">
              <div styleName="label">Last Name</div>
              <Input styleName="name-inputs" value={lastName} isInvalid={!lastName} onChange={this.lastNameChange} />
            </div>
          </div>
          <div styleName="row">
            <div styleName="label">Email</div>
            <Input styleName="name-inputs" value={email} isDisabled />
          </div>
          <div styleName="roles-group-row">
            <div styleName="roles">
              <div styleName="label">Roles</div>
              <SelectAndt
                automationId="SelectUserRole"
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_NOT_HIGHLIGHTED}
                onChange={this.userRoleSelected}
                options={allowedUserRoles}
                value={selectedRole}
                placeholder="Select"
                optionHeight={40}
                getOptionLabel={(val) => val.name}
                getOptionValue={(val) => val.role}
                disabled={me._id === editingUserId}
              />
            </div>
            <div styleName="groups">
              <div styleName="label">
                Assign to Groups
                <span styleName="sub">Optional</span>
              </div>
              <SelectAndt
                automationId="addGroupsUserEditModal"
                type={TYPE_NEW_MULTI}
                theme={THEME_HIGHLIGHTED}
                onChange={this.groupsSelected}
                options={groupsList}
                value={selectedGroups}
                isMulti
                placeholder="Select"
                optionHeight={40}
                isClearable={false}
                closeMenuOnSelect={false}
                getOptionLabel={(val) => val.name}
                getOptionValue={(val) => val.id}
                isLoading={isGroupsLoading}
                disabled={isGroupsLoading}
              />
            </div>
          </div>
          {selectedGroups.length ? (
            <div styleName="default-group-row">
              <div styleName="label">Primary Group is</div>
              <SelectAndt
                automationId="SelectUserDefaultGroup"
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_BLUE_LEAN}
                onChange={this.userDefaultGroupSelected}
                options={selectedGroups}
                value={selectedDefaultGroup}
                placeholder="Select"
                optionHeight={40}
                buttonWidth={150}
                menuWidth={200}
                disabled={selectedGroups.length === 1 && selectedDefaultGroup.id}
                getOptionLabel={(val) => val.name}
                getOptionValue={(val) => val.id}
              />
            </div>
          ) : null}
          <div styleName="row status-row">
            <div styleName="label">Status</div>
            <label key="active">
              <input
                type="radio"
                name="status"
                value="active"
                checked={!disabled}
                onChange={this.setUserStatus}
                disabled={me._id === editingUserId}
              />
              <span>Active</span>
            </label>
            <label key="disabled">
              <input
                type="radio"
                name="status"
                value="disabled"
                checked={!!disabled}
                onChange={this.setUserStatus}
                disabled={me._id === editingUserId}
              />
              <span>Disabled</span>
            </label>
          </div>
          <div styleName="footer">
            <span styleName="org-name">{orgName}</span>
            <Button
              text="Save"
              fixedWidth={96}
              colorSchema={ButtonColors.BLUE_500}
              height={ButtonHeights.TALL}
              onClick={this.saveClicked}
            />
          </div>
        </div>
      );
    }
  },
);
