// @flow

import React from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import './ToggleSwitch.module.scss';
import {palette} from 'app/styles/theme';

const SIZE = {
  regular: {},
  small: {
    transform: 'scale(.6)',
  },
};

const THEMES = {
  blue: {
    before: {
      backgroundColor: palette.blue['500'],
    },
    after: {
      backgroundColor: palette.gray['300'],
    },
    grip: {
      backgroundColor: palette.indigo['500'],
    },
    gripUnchecked: {
      backgroundColor: palette.gray['400'],
    },
  },
  purple: {
    before: {
      backgroundColor: palette.purple['200'],
    },
    after: {
      backgroundColor: palette.gray['300'],
    },
    grip: {
      backgroundColor: palette.purple['500'],
    },
    gripUnchecked: {
      backgroundColor: palette.gray['400'],
    },
  },
  green: {
    before: {
      backgroundColor: palette.green['500'],
    },
    after: {
      backgroundColor: palette.gray['300'],
    },
    grip: {
      backgroundColor: palette.green['700'],
    },
    gripUnchecked: {
      backgroundColor: palette.green['100'],
    },
  },
};

type PropTypes = {
  isChecked: Boolean,
  id?: String,
  name?: String,
  onToggle: Function,
  theme?: String,
  size?: String,
  disabled?: Boolean,
  automationId: string,
  segmentClickEvent: Function,
};

export default connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)(
  class ToggleSwitch extends React.PureComponent {
    props: PropTypes;

    static defaultProps = {
      id: null,
      name: null,
      disabled: false,
      theme: 'blue',
      size: 'regular',
    };

    size = SIZE[this.props.size];

    theme = THEMES[this.props.theme];

    onToggle = () => {
      const {onToggle, id, name, isChecked} = this.props;
      this.props.segmentClickEvent({
        type: 'toggle',
        name: name || id || this.props.automationId,
        automationId: this.props.automationId,
      });
      onToggle(id, name, isChecked);
    };

    render() {
      const {disabled, isChecked, automationId} = this.props;

      return (
        <div styleName="main" style={this.size}>
          <div>
            <label styleName="toggle-group">
              <input
                automation-id={automationId}
                type="checkbox"
                checked={isChecked}
                onChange={this.onToggle}
                disabled={disabled}
              />

              <div styleName={disabled ? 'onoffswitch disabled' : 'onoffswitch'} aria-hidden="true">
                <div styleName="onoffswitch-label">
                  <div styleName="onoffswitch-inner">
                    <span styleName="before" style={this.theme.before} />
                    <span styleName="after" style={this.theme.after} />
                  </div>
                  <div styleName="onoffswitch-switch" style={isChecked ? this.theme.grip : this.theme.gripUnchecked} />
                </div>
              </div>
            </label>
          </div>
        </div>
      );
    }
  },
);
