import React, {useCallback, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as metricsSelectors from 'metrics/store/selectors';
import {getEnableNewMetricExplorer} from 'profile/store/selectors';
import {initExpressionTreeModel, setIsShowComposites} from 'metrics/store/actions';
import {makeStyles} from '@material-ui/core';
import {getUniqueId} from 'common/utils/guid';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import SwitchButton from 'common/componentsV2/SwitchButton';

const useStyles = makeStyles(() => ({
  option: {
    display: 'flex',
    height: 40,
    alignItems: 'center',
    padding: 10,
    cursor: 'pointer',
    margin: 3,
    borderRadius: 4,
    fontSize: 16,
    '&:hover': {
      backgroundColor: `rgba(38, 113, 255, 0.1)`,
    },
  },
}));

const CompositeBuilderActions = ({
  isSimulationDisabled,
  onClear,
  isAddComposite,
}: {
  isSimulationDisabled: boolean,
  onClear: Function,
  isAddComposite: boolean,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const expressionTrees = useSelector(metricsSelectors.getExpressionTrees);
  const isNewMetricExplorerEnabled = useSelector(getEnableNewMetricExplorer);
  const [isMoreOptionsPanelOpen, setMoreOptionsPanelOpen] = useState(false);

  const isShowComposites = useMemo(() => expressionTrees[0].isShowComposites, [expressionTrees]);

  const toggleActionMenuPanel = (isOpen) => {
    setMoreOptionsPanelOpen(isOpen);
  };

  const handleShowInMetricExplorer = useCallback(() => {
    window.sessionStorage.setItem(
      'metricExplorerTree',
      JSON.stringify(expressionTrees.map((treeModel) => treeModel.expressionTree)),
    );
    window.open('/#!/r/metrics-explorer');
  }, [expressionTrees]);

  const handleDelete = () => {
    if (onClear) {
      onClear();
    } else {
      dispatch(
        initExpressionTreeModel({
          expressionTree: {
            root: {
              searchObject: {
                expression: [],
              },
              children: [],
              type: 'metric',
              id: getUniqueId(),
              uiIndex: 0,
            },
          },
        }),
      );
    }
  };

  const renderMoreOptionsPanel = useMemo(
    () => (
      <div>
        <div className={classes.option} onClick={handleDelete} aria-disabled={isSimulationDisabled}>
          <div>Clear Expression Tree</div>
        </div>
        {isNewMetricExplorerEnabled && (
          <div className={classes.option} onClick={handleShowInMetricExplorer} aria-disabled={isSimulationDisabled}>
            <div>Show In Metric Explorer</div>
          </div>
        )}
        {isAddComposite && (
          <div className={classes.option}>
            <div className="mr_0-5">Show Composites</div>
            <SwitchButton
              isChecked={isShowComposites}
              onChange={(checked) => dispatch(setIsShowComposites(checked))}
              automationId="showComposites"
            />
          </div>
        )}
      </div>
    ),
    [handleShowInMetricExplorer, isSimulationDisabled, isNewMetricExplorerEnabled, isShowComposites],
  );

  return (
    <FormDdl
      popoverComponent={renderMoreOptionsPanel}
      buttonComponent={<div className="icon icn-general16-3dot" />}
      onToggle={(isOpen) => toggleActionMenuPanel(isOpen)}
      width={200}
      isOpen={isMoreOptionsPanelOpen}
      placement="top"
      automationId="alertMoreOptions"
    />
  );
};

export default CompositeBuilderActions;
