// @flow
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import * as actions from 'alerts.console.new/store/actions';
import ReactResizeDetector from 'react-resize-detector';
import {getScrollbarWidth} from 'assets/store/selectors';
import AlertGroupsList from './AlertGroupsList';

import './AlertsListBody.module.scss';

const AlertsListBody = ({alertsTriggers, queryParams}: {alertsTriggers: Array<Object>, queryParams: Object}) => {
  const scrollbarWidth = useSelector(getScrollbarWidth);

  const [isAlertsListScrolling, setIsAlertsListScrolling] = useState(false);

  const containerStyle = {
    width: scrollbarWidth ? 'calc(100%)' : 'calc(100% - 5px)',
  };

  let timeout = null;
  let listBody = null;

  const handleResize = () => {
    actions.changeScrollbarWidth(listBody);
  };

  const onScroll = (e) => {
    const elementScroll = e.target.scrollTop;

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      timeout = null;
      setIsAlertsListScrolling(false);
      actions.setIsAlertsListScrolling(false);
    }, 300);
    if (!isAlertsListScrolling) {
      setIsAlertsListScrolling(true);
      actions.setIsAlertsListScrolling(true);
    }

    if (elementScroll > 0) {
      actions.listScrolled(true);
    } else if (elementScroll === 0) {
      actions.listScrolled(false);
    }
  };

  const listBodyRef = (e) => {
    listBody = e;
  };

  return (
    <div styleName="container" ref={listBodyRef} onScroll={onScroll}>
      <ReactResizeDetector handleHeight handleWidth onResize={handleResize} />

      <div style={containerStyle}>
        <AlertGroupsList
          triggers={alertsTriggers}
          isAlertsListScrolling={isAlertsListScrolling}
          queryParams={queryParams}
        />
      </div>
    </div>
  );
};

export default AlertsListBody;
