import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {bcTypes} from 'bc/services/bcTypes';
import * as commonSelectors from 'profile/store/selectors';
import * as actions from '../actions';
import * as api from '../../services/api';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';

const debouncedSearch = (action$) =>
  action$
    .ofType(actions.setDataSourceTypeFilterTextInput.TYPE)
    .debounceTime(200)
    .distinctUntilChanged((x, y) => x.payload === y.payload)
    .map((x) => actions.setDataSourceTypeFilterText(x.payload));

const isBcEnabledFilterCall = (action, getState) => !!commonSelectors.getOrganizationSettingsBC(getState()).enabled;

const fetchDataSourceTypes = makeAsyncEpic(
  actions.fetchDataSourceTypes,
  api.fetchDataSourceTypes,
  isBcEnabledFilterCall,
);
const fetchDataSourceIntegrationTypes = makeAsyncEpic(
  actions.fetchDataSourceIntegrationTypes,
  api.fetchDataSourceIntegrationTypes,
  isBcEnabledFilterCall,
);
const fetchDataSources = makeAsyncEpic(actions.fetchDataSources, api.fetchDataSources, isBcEnabledFilterCall);
const createDataSource = makeAsyncEpic(actions.createDataSource, api.createDataSource);
const testDataSource = makeAsyncEpic(actions.testDataSource, api.testDataSource);
const updateDataSource = makeAsyncEpic(actions.updateDataSource, api.updateDataSource);
const deleteDataSource = makeAsyncEpic(actions.deleteDataSource, api.deleteDataSource);
const deleteAgentDataSource = makeAsyncEpic(actions.deleteAgentDataSource, api.deleteAgentDataSource);

const addDataSourceSuccess = (action$) =>
  action$.ofType(actions.createDataSource.success.TYPE).flatMap((action) => [
    actions.createDataStream({
      type: action.payload.type,
      dataSourceId: action.payload.id,
    }),
  ]);

const uploadFileSuccess = (action$) =>
  action$.ofType(actions.setUploadedFileUrl.TYPE).flatMap((action) => {
    if (!action.payload.s3url) {
      // reset file URL in the store before uploading new file
      return [];
    }
    const [fileName, folderName] = action.payload.s3url.split('/').reverse();
    const fileNameDecoded = decodeURIComponent(fileName);
    const folderNameDecoded = decodeURIComponent(folderName);

    if (action.payload.s3url.includes('/lookups/')) {
      return [
        actions.createLookupTable({
          name: fileNameDecoded,
          fileName: `${folderNameDecoded}/${fileNameDecoded}`,
        }),
        // close the progress modal (if the file cannot be parsed when creating a stream,
        // at least the growl will be shown not behind modal)
        actions.setFileUploadName(),
        actions.setUploadedFileUrl({}),
      ];
    }
    return [
      actions.createDataSource({
        type: bcTypes.local_file.type,
        name: `${fileNameDecoded} Data Source ${new Date().getTime().toString()}`,
        fileName: `${folderNameDecoded}/${fileNameDecoded}`,
      }),
      // close the progress modal (if the file cannot be parsed when creating a stream,
      // at least the growl will be shown not behind modal)
      actions.setFileUploadName(),
      actions.setUploadedFileUrl({}),
    ];
  });

const dataSourcesEpic = combineEpics(
  debouncedSearch,
  fetchDataSourceTypes,
  fetchDataSourceIntegrationTypes,
  fetchDataSources,
  createDataSource,
  testDataSource,
  updateDataSource,
  addDataSourceSuccess,
  deleteDataSource,
  deleteAgentDataSource,
  uploadFileSuccess,
);
export default dataSourcesEpic;
