// @flow
import React from 'react';
import {connect} from 'react-redux';
import {fetchGoogleAdsTemplates, setSelectedGoogleAdsTemplate} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import Button, {COLORS as BUTTON_COLORS, WIDTHS as BITTON_WIDTHS} from 'common/componentsV2/Button';
import Checkbox from 'common/componentsV2/Checkbox';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import Loader from 'common/components/Loader';
import SmartTooltip from 'common/componentsV2/Tooltip';
import './GadsTemplate.module.scss';

type PropTypes = {
  dataStream: Object,
  isLoading: boolean,
  templates: Array,
  selectedTemplate: Object,

  fetchGoogleAdsTemplates: Function,
  setSelectedGoogleAdsTemplate: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoading: selectors.getGoogleAdsTemplates(state).isLoading,
    templates: selectors.getGoogleAdsTemplatesItemsOrdered(state),
    selectedTemplate: selectors.getGoogleAdsSelectedTemplate(state),
  }),
  {
    fetchGoogleAdsTemplates,
    setSelectedGoogleAdsTemplate,
  },
)(
  class GadsTemplates extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      this.props.fetchGoogleAdsTemplates(this.props.dataStream.type);
    }

    onButtonClick = (selectedId) => {
      const selectedTemplate = this.props.templates.find((item) => item.id === selectedId);
      this.props.setSelectedGoogleAdsTemplate(selectedTemplate);
    };

    render() {
      const {dataStream, templates, selectedTemplate} = this.props;

      let isChecked = false;
      if (selectedTemplate) {
        if (
          selectedTemplate.filterInactiveCampaigns === undefined &&
          dataStream.filterInactiveCampaigns === undefined &&
          selectedTemplate.canFilterByCampaignState
        ) {
          // set default to checked;
          selectedTemplate.filterInactiveCampaigns = true;
          this.onButtonClick(selectedTemplate.id);
        }
        if (selectedTemplate.filterInactiveCampaigns !== undefined) {
          isChecked = selectedTemplate.filterInactiveCampaigns;
        } else if (dataStream.filterInactiveCampaigns !== undefined) {
          isChecked = dataStream.filterInactiveCampaigns;
          selectedTemplate.filterInactiveCampaigns = isChecked;
        }
      }

      const toggleRadio = (checked) => {
        selectedTemplate.filterInactiveCampaigns = checked;
        this.onButtonClick(this.props.selectedTemplate.id);
      };

      return (
        <div styleName="root">
          <PanelHeader title="Select Use Case" isEditable="true" />
          {this.props.isLoading !== false && <Loader />}

          {templates.map((item) => (
            <div key={item.id} styleName="button-wrap">
              <SmartTooltip placement="top" content={item.description}>
                <div>
                  <Button
                    colorSchema={
                      selectedTemplate && selectedTemplate.id === item.id
                        ? BUTTON_COLORS.BLUE_500
                        : BUTTON_COLORS.GRAY_200
                    }
                    width={BITTON_WIDTHS.REGULAR}
                    extraClassName="button_270"
                    height="tall"
                    text={item.name}
                    onClick={() => this.onButtonClick(item.id)}
                  />
                </div>
              </SmartTooltip>
            </div>
          ))}

          <div styleName="button-wrap">
            <Checkbox
              text="Collect only active campaigns"
              onChange={toggleRadio}
              isChecked={isChecked}
              isDisabled={!selectedTemplate || !selectedTemplate.canFilterByCampaignState}
            />
          </div>
        </div>
      );
    }
  },
);
