import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/event-streams/';

const fetchEventLastRun = (streamId) => {
  return get(`${root}${streamId}/last_run`);
};

export default function useFetchEventStreamLastRun(streamId) {
  const fallback = {};

  const {
    data: eventStreamLastRunData = fallback,
    isLoading: eventStreamLastRunIsLoading,
    isSuccess: eventStreamLastRunIsSuccess,
    isError: eventStreamLastRunIsError,
    error: eventStreamLastRunError,
  } = useQuery(QUERY_KEYS.eventStreamLastRun, () => fetchEventLastRun(streamId), {
    enabled: !!streamId,
  });

  return {
    eventStreamLastRunData,
    eventStreamLastRunIsLoading,
    eventStreamLastRunIsSuccess,
    eventStreamLastRunIsError,
    eventStreamLastRunError,
  };
}
