import moment from 'moment';

export const guessTimeZone = (value) => {
  if (value === 'Browser') {
    return moment.tz.guess();
  }
  return value;
};

export const example = {};
