// @flow
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {fetchGroupsData} from 'admin.users/store/selectors';
import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import {StringParam, useQueryParam} from 'use-query-params';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import fetchChannelsProvider from 'alerts.console.new/api/fetchChannelsProvider';
import {
  getUsersDataForAvatar,
  getSortedFlattenChannels,
  getSelectedItems,
} from 'alerts.console.new/services/alertsConsoleFiltersService';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS} from 'alerts.console.new/services/alertsConsoleService';

const ChannelsFilter = () => {
  const groupsData = useSelector(fetchGroupsData);
  const [channelsQueryString, setChannelsQueryString] = useQueryParam(
    ALERT_CONSOLE_QUERY_PARAMS_KEYS.CHANNELS,
    StringParam,
  );
  const [subscribersQueryString, setSubscribersQueryString] = useQueryParam(
    ALERT_CONSOLE_QUERY_PARAMS_KEYS.SUBSCRIBERS,
    StringParam,
  );

  const usersProviderData = fetchUsersProvider().useQuery();
  const users = usersProviderData?.data;

  const channelsProviderData = fetchChannelsProvider().useQuery();
  const channelsData = channelsProviderData?.data;

  const isLoading = usersProviderData?.isLoading || channelsProviderData?.isLoading;

  const usersOptions = useMemo(() => {
    if (Array.isArray(users) && Array.isArray(groupsData)) {
      return getUsersDataForAvatar(users, groupsData);
    }
    return undefined;
  }, [users, groupsData]);

  const sortedFlattenChannels = useMemo(() => {
    // Need both of the ReactQuery providers to return value - i.e. not undefined, and also at least one not empty.
    if (
      !isLoading &&
      (Array.isArray(usersOptions) && Array.isArray(channelsData)) &&
      (usersOptions?.length > 0 || channelsData?.length > 0)
    ) {
      return getSortedFlattenChannels(usersOptions, channelsData);
    }
    return undefined;
  }, [usersOptions, channelsData]);

  const selectedItems = useMemo(() => {
    if (sortedFlattenChannels?.length) {
      return getSelectedItems(sortedFlattenChannels, {
        subscribers: subscribersQueryString,
        channels: channelsQueryString,
      });
    }
    return undefined;
  }, [sortedFlattenChannels, subscribersQueryString, channelsQueryString]);

  const onChange = useCallback(
    (values) => {
      const subscribers = [];
      const channels = [];

      values.forEach((i) => {
        if (i.type === 'USERS') {
          subscribers.push(i.value);
        } else {
          channels.push(i.value);
        }
      });
      setSubscribersQueryString(subscribers.length ? subscribers.map((i) => i).join(',') : undefined);
      setChannelsQueryString(channels.length ? channels.map((i) => i).join(',') : undefined);
    },
    [setSubscribersQueryString, setChannelsQueryString],
  );

  const onClearAll = useCallback(() => {
    if (selectedItems?.length > 0) {
      setSubscribersQueryString(undefined);
      setChannelsQueryString(undefined);
    } else {
      const subscribers = [];
      const channels = [];
      if (sortedFlattenChannels?.length) {
        sortedFlattenChannels.forEach((i) => {
          if (i.type === 'USERS') {
            subscribers.push(i.value);
          } else {
            channels.push(i.value);
          }
        });
      }

      setSubscribersQueryString(subscribers?.length ? subscribers.map((i) => i).join(',') : undefined);
      setChannelsQueryString(channels?.length ? channels.map((i) => i).join(',') : undefined);
    }
  }, [selectedItems, sortedFlattenChannels, setSubscribersQueryString, setChannelsQueryString]);

  return (
    <UsersChannelsFilter
      placeholder={isLoading ? 'Loading...' : 'Select'}
      onChange={onChange}
      sortedFlattenChannels={sortedFlattenChannels}
      value={selectedItems}
      automationId="channelsFilter"
      isFullWidthButton
      footerComponent={
        <FormDdlActionBar isVisible selectedCount={selectedItems?.length ?? 0} onClearAll={onClearAll} />
      }
    />
  );
};
export default ChannelsFilter;
