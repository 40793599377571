import React from 'react';

const AlertConsoleQueryParamsContext = React.createContext({
  contextQueryParams: {},
  setContextQueryParams: () => {},

  contextRefreshInterval: undefined,
  setContextRefreshInterval: () => {},
});
export default AlertConsoleQueryParamsContext;
