import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const viewReducer = composeReducers((state = {accessListByStream: {}, channelByStream: {}}, {type, payload, meta}) => {
  switch (type) {
    case actions.getAccesslistForSource.success.TYPE: {
      return {
        ...state,
        accessListByStream: {
          ...state.accessListByStream,
          [meta.streamId]: payload,
        },
      };
    }
    case actions.fetchChannelByMetricSource.success.TYPE: {
      return {
        ...state,
        channelByStream: {
          ...state.channelByStream,
          [meta.streamId]: payload,
        },
      };
    }
    default:
      return state;
  }
});

export default viewReducer;
