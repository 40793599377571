// @flow
import React from 'react';
import {connect} from 'react-redux';

import * as actions from 'admin.cs-portal/store/actions';
import * as selectors from 'admin.cs-portal/store/selectors';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';
import StreamData from './StreamData';

import './StreamsSummary.module.scss';

type PropTypes = {
  // connect
  selectedCustomer: Object,
  fetchStreamsReport: Function,
  summary: Array,
  activeSources: Array,
  onToggleSourceActive: Function,
};

export default connect(
  (state) => ({
    summary: selectors.getStreamsReportSummary(state),
    selectedCustomer: selectors.getSelectedCustomer(state),
  }),
  {
    fetchStreamsReport: actions.fetchStreamsReport,
  },
)(
  class StreamsSummary extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {selectedCustomer} = this.props;
      if (selectedCustomer && selectedCustomer.value) {
        this.fetchStreamsReport(selectedCustomer);
      }
    }

    componentDidUpdate(prevProps) {
      const {selectedCustomer} = prevProps;
      if (selectedCustomer && selectedCustomer.value && selectedCustomer !== this.props.selectedCustomer) {
        this.fetchStreamsReport(this.props.selectedCustomer);
      }
    }

    fetchStreamsReport = (selectedCustomer) => {
      this.props.fetchStreamsReport({
        _id: selectedCustomer.value,
      });
    };

    handleToggleSourceActive = (item) => {
      this.props.onToggleSourceActive(item);
    };

    renderStreamData = () =>
      Object.keys(this.props.summary).map((item) => (
        <StreamData
          key={item}
          source={item}
          sources={this.props.summary[item].sources.length}
          streams={this.props.summary[item].streams}
          failure={this.props.summary[item].failure}
          isActive={this.props.activeSources.includes(item)}
          onToggleSourceActive={this.handleToggleSourceActive}
        />
      ));

    render() {
      return (
        <div styleName="container">
          {this.props.summary ? this.renderStreamData() : <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />}
        </div>
      );
    }
  },
);
