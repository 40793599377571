// @flow
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Observable} from 'rxjs/Observable';
import {Link} from 'react-router-dom';
import {isEqual} from 'lodash';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import Button, {COLORS} from 'common/componentsV2/Button';
import {
  deleteDataStream,
  deletePipelineStream,
  fetchDataStreams,
  fetchLookupTables,
  setDataManagerOpenedSources,
  setSelectedDataStream,
  setViewStreamSummaryAssets,
  setEventStreamsFromReactQueryToReducer,
} from 'bc/store/actions';
import {getUserProfile, getIsOnPremNewStreamEnabled} from 'profile/store/selectors';

import {fetchUsers} from 'admin.users/store/actions';
import * as selectors from 'bc/store/selectors';
import {isStreamEditable} from 'bc/services/dataStreamService';
import {getStreamsCount} from 'bc/services/dataManagerService';
import {getUsersData} from 'admin.users/store/selectors';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import {ReactComponent as NoData} from 'bc/svgs/no-streams.svg';
import {ReactComponent as EmptyData} from 'bc/svgs/empty-streams.svg';
import {getTypeDetails} from 'bc/services/bcTypes';

import {STREAMS_FAMILY_TYPES} from 'dataCollectors/services/dataCollectorsService';
import {palette} from 'app/styles/theme';
import DataManagerSourceItem from './DataManagerSourceItem';
import DataManagerStreamItem from './DataManagerStreamItem';
import DeleteMetricsConfirmation from './DeleteMetricsConfirmation';
import EditLiveStreamModal from './editLiveStreamModal/EditLiveStreamModal';
import ViewStreamSummaryModal from './viewStreamSummaryModalV2/ViewStreamSummaryModalV2';
import StreamsLimitModal from './StreamsLimitModal';

import './DataManagerListBody.module.scss';

type PropTypes = {
  setQueryParams: Function,
  onListStateChange: Function,
  listState: String,
  eventStreams: Array,
  isLoadingEventStreams: Boolean,

  // connect
  fetchDataStreams: Function,
  fetchLookupTables: Function,
  setViewStreamSummaryAssets: Function,
  setSelectedDataStream: Function,
  setDataManagerOpenedSources: Function,
  deleteDataStream: Function,
  deletePipelineStream: Function,
  fetchUsers: Function,
  users: Array,
  dataSources: Array,
  dataStreams: Array,
  dataStreamNotFiltered: Array,
  dataQueryParams: Object,
  selectedStream: Object,
  tooltip: Object,
  isBcReadOnly: Boolean,
  isLoading: Boolean,
  dataStreamsCount: Number,
  savedOpenSources: Array,
  setEventStreamsFromReactQueryToReducer: Function,
  isOnPremNewStreamEnabled: Boolean,
  userProfile: Object,
};

export default connect(
  (state) => ({
    dataSources: selectors.getDataSourcesItemsOrdered(state),
    dataStreams: selectors.getDataStreamsItemsOrderedAndFiltered(state),
    dataStreamNotFiltered: selectors.getDataStreamsItems(state),
    isLoading: selectors.getDataStreams(state).streams.isLoading,
    dataStreamsCount: selectors.getDataStreamsItemsOrdered(state).length,
    selectedStream: selectors.getSelectedDataStream(state),
    dataQueryParams: selectors.getDataMangerQueryParams(state),
    tooltip: selectors.getDataMangerFilterToolTip(state),
    isBcReadOnly: selectors.isBcReadOnlyPermision(state),
    savedOpenSources: selectors.getDataManagerOpenedSources(state),
    userProfile: getUserProfile(state),
    isOnPremNewStreamEnabled: getIsOnPremNewStreamEnabled(state),
    users: getUsersData(state),
  }),
  {
    fetchDataStreams,
    fetchLookupTables,
    fetchUsers,
    setViewStreamSummaryAssets,
    deleteDataStream,
    deletePipelineStream,
    setSelectedDataStream,
    setDataManagerOpenedSources,
    setEventStreamsFromReactQueryToReducer,
  },
)(
  class DataManagerListBody extends React.Component {
    props: PropTypes;

    state = {
      openSources: [],
      isStreamViewModalOpen: false,
      isStreamConfirmationModalOpen: false,
      shouldDeleteMetrics: true,
      isLiveStreamEditModalOpen: false,
      isLimitModalOpen: false,
      isSourceViewModalOpen: false,
    };

    componentDidMount() {
      this.props.fetchDataStreams();
      this.props.fetchLookupTables();
      this.refreshInterval = Observable.interval(1000 * 60).subscribe(() => this.props.fetchDataStreams());
      if (!this.props.users.length) {
        this.props.fetchUsers();
      }
    }

    componentDidUpdate() {
      const {dataSources, savedOpenSources, listState, eventStreams, isLoadingEventStreams} = this.props;
      if (dataSources.length) {
        if (savedOpenSources !== null && !isEqual(savedOpenSources, this.state.openSources)) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({openSources: savedOpenSources});
          this.props.onListStateChange();
        } else {
          if (listState === 'COLLAPSE' || (listState === null && savedOpenSources === null)) {
            this.closeAllSources();
          }
          if (listState === 'EXPAND') {
            this.openAllSources();
          }
        }
        if (
          this.props.dataQueryParams &&
          this.props.dataQueryParams.streamPreviewId &&
          !this.state.isStreamViewModalOpen
        ) {
          this.props.setSelectedDataStream(this.props.dataQueryParams.streamPreviewId);
          this.openStreamViewModal();
        }
      }
      if (!isLoadingEventStreams) {
        this.props.setEventStreamsFromReactQueryToReducer(eventStreams);
      }
    }

    componentWillUnmount() {
      this.refreshInterval.unsubscribe();
    }

    stopPropagation = (e) => e.stopPropagation();

    isSourceToBeDisplayed = (source) => {
      const {dataStreams, dataQueryParams, tooltip} = this.props;
      return (
        !tooltip.length || // no filter show all
        getStreamsCount(source.id, dataStreams) || // has streams
        (!getStreamsCount(source.type, dataStreams) &&
          dataQueryParams.accessGroups &&
          dataQueryParams.type &&
          dataQueryParams.type.split(',').includes(source.type) &&
          !dataQueryParams.searchQuery &&
          !dataQueryParams.status &&
          !dataQueryParams.owner)
      );
    };

    closeAllSources = () => {
      // eslint-disable-next-line react/no-unused-state
      this.setState({openSources: []});
      this.props.setDataManagerOpenedSources([]);
      this.props.onListStateChange();
    };

    openAllSources = () => {
      const idArr = this.props.dataSources.map((source) => source.id);
      // eslint-disable-next-line react/no-unused-state
      this.setState({openSources: idArr});
      this.props.setDataManagerOpenedSources(idArr);
      this.props.onListStateChange();
    };

    handleClick = (id) => {
      const {openSources} = this.state;
      let openSourcesArr = [...openSources];
      if (openSources.includes(id)) {
        openSourcesArr = openSourcesArr.filter((val) => val !== id);
      } else {
        openSourcesArr.push(id);
      }
      this.setState({openSources: openSourcesArr});
      this.props.setDataManagerOpenedSources(openSourcesArr);
      this.props.onListStateChange();
    };

    isSourceOpen = (id) => this.state.openSources.includes(id);

    getStreamsItems = (id) => {
      const streamsArr = this.props.dataStreams.filter((stream) => stream.dataSourceId === id);
      if (streamsArr.length) {
        return streamsArr.map((stream) => (
          <DataManagerStreamItem
            key={`stream-item-${stream.id}`}
            openStreamViewModal={this.openStreamViewModal}
            openConfirmationModal={this.openStreamConfirmationModal}
            openLiveStreamEditModal={this.openLiveStreamEditModal}
            openStreamsLimitModal={this.openStreamsLimitModal}
            isBcReadOnly={this.props.isBcReadOnly}
            stream={stream}
          />
        ));
      }
      return (
        <div styleName="no-stream" automation-id="No_stream_to_show">
          No streams in this source
        </div>
      );
    };

    openSourceViewModal = (source) => {
      this.setState({isSourceViewModalOpen: true});
      this.modal = getTypeDetails(source.type).view(source, source, !this.props.isBcReadOnly);
    };

    closeSourceViewModal = () => {
      this.setState({isSourceViewModalOpen: false});
    };

    openStreamViewModal = () => {
      this.setState({isStreamViewModalOpen: true});
    };

    closeStreamViewModal = () => {
      if (this.props.dataQueryParams && this.props.dataQueryParams.streamPreviewId) {
        this.props.setQueryParams({streamPreviewId: undefined});
      }
      if (this.props.selectedStream) {
        this.props.setSelectedDataStream(null);
      }
      this.props.setViewStreamSummaryAssets({assets: {streamId: null}});
      this.setState({isStreamViewModalOpen: false});
    };

    openStreamConfirmationModal = () => {
      this.setState({isStreamConfirmationModalOpen: true});
    };

    closeStreamConfirmationModal = () => {
      this.setState({isStreamConfirmationModalOpen: false});
    };

    onConfirmModal = () => {
      if (this.props.selectedStream.family === STREAMS_FAMILY_TYPES.agents) {
        this.props.deletePipelineStream({
          pipelineId: this.props.selectedStream.id,
          type: this.props.selectedStream.type,
          shouldDeleteMetrics: this.state.shouldDeleteMetrics,
        });
      } else {
        this.props.deleteDataStream({
          id: this.props.selectedStream.id,
          type: this.props.selectedStream.type,
          shouldDeleteMetrics: this.state.shouldDeleteMetrics,
        });
      }
      this.setState({isStreamConfirmationModalOpen: false, shouldDeleteMetrics: true});
    };

    onCheckBoxChange = (event) => {
      const {target} = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const {name} = target;

      this.setState({
        [name]: value,
      });
    };

    openLiveStreamEditModal = () => {
      this.setState({isLiveStreamEditModalOpen: true});
    };

    closeLiveStreamEditModal = () => {
      this.setState({isLiveStreamEditModalOpen: false});
    };

    openStreamsLimitModal = () => {
      this.setState({isLimitModalOpen: true});
    };

    closeStreamsLimitModal = () => {
      this.setState({isLimitModalOpen: false});
    };

    render() {
      const {
        isStreamViewModalOpen,
        isStreamConfirmationModalOpen,
        shouldDeleteMetrics,
        isLimitModalOpen,
        isLiveStreamEditModalOpen,
        isSourceViewModalOpen,
      } = this.state;
      const {
        dataSources,
        dataStreams,
        selectedStream,
        dataStreamsCount,
        dataStreamNotFiltered,
        isBcReadOnly,
        isLoading,
        tooltip,
        userProfile,
        isOnPremNewStreamEnabled,
      } = this.props;
      let isEmptyList = true;

      if (dataStreams) {
        const foundRestApi = dataStreams.find((stream) => stream.type === 'api30schema');
        const alreadyAddRestApi = dataSources.find((source) => source.type === 'api30schema');
        if (foundRestApi && !alreadyAddRestApi) {
          dataSources.push({
            family: 'agents',
            name: 'REST API',
            type: 'api30schema',
          });
        }
      }

      // new source button enabled check
      const authorizedRoles = ['anodot-admin', 'anodot-user', 'reseller-admin', 'reseller-user', 'customer-admin'];
      let isAuthorizedNewStream = userProfile.roles.some((r) => authorizedRoles.includes(r));
      if (isAuthorizedNewStream && window.isOnPremise) {
        // also check feature flag for OnPremise users
        isAuthorizedNewStream = isOnPremNewStreamEnabled;
      }

      if (isLoading && !dataStreams.length) {
        return (
          <div styleName="centered-text">
            <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
          </div>
        );
      }

      return (
        <div styleName="container">
          <TinyScrollBox width="100%" pr={1.25} height={1} css={{overflowY: 'auto'}}>
            {!dataSources.length && !tooltip.length && !isLoading ? (
              <div styleName="no-data-container">
                <div styleName="icon">
                  <NoData />
                </div>
                <div styleName="title">No Data Sources</div>
                <div styleName="text">Start getting alerts by connecting a data source</div>
                {isAuthorizedNewStream && (
                  <div styleName="button">
                    <Link to="/integrations?filterText=&selectedCategory=all&selectedTab=sources&origin=StreamsList">
                      <Button
                        colorSchema={COLORS.BLUE_500}
                        icon="icn-action16-plusa"
                        text="New Source"
                        automation-id="dataManagerNewSourceButton"
                      />
                    </Link>
                  </div>
                )}
              </div>
            ) : null}

            {dataSources.map((source) => {
              if (this.isSourceToBeDisplayed(source)) {
                isEmptyList = false;
                return (
                  <Fragment key={`source_${source.id}`}>
                    <DataManagerSourceItem
                      source={source}
                      streams={dataStreamNotFiltered}
                      streamsCount={getStreamsCount(source.id, dataStreams)}
                      isBcReadOnly={isBcReadOnly}
                      isOpen={this.isSourceOpen(source.id)}
                      openModal={this.openStreamsLimitModal}
                      onClickFunction={this.handleClick}
                      openSourceViewModal={this.openSourceViewModal}
                    />
                    {this.isSourceOpen(source.id) ? (
                      <div styleName="table-container">{this.getStreamsItems(source.id)}</div>
                    ) : null}
                  </Fragment>
                );
              }
            })}

            {isEmptyList && dataStreamsCount && tooltip.length ? (
              <div styleName="no-data-container">
                <div styleName="icon">
                  <EmptyData />
                </div>
                <div styleName="title" automation-id="No_source_to_show">
                  No Data Sources to Show
                </div>
                <div styleName="text">Change filters to see data source</div>
              </div>
            ) : null}
          </TinyScrollBox>

          {/* MODALS START */}

          {isStreamViewModalOpen && (
            <ViewStreamSummaryModal onClose={this.closeStreamViewModal} isOpen={isStreamViewModalOpen} />
          )}

          {isLimitModalOpen && (
            <StreamsLimitModal isOpen={isLimitModalOpen} onClose={this.closeStreamsLimitModal} scenario="incomplete" />
          )}

          {isLiveStreamEditModalOpen && (
            <EditLiveStreamModal onClose={this.closeLiveStreamEditModal} isOpen={isLiveStreamEditModalOpen} />
          )}

          {isStreamConfirmationModalOpen && (
            <ConfirmationModal
              onClose={this.closeStreamConfirmationModal}
              onConfirm={this.onConfirmModal}
              title="Delete stream"
              message={`Are you sure you want to delete ${selectedStream.name}?`}
              confirmBtnText="Yes, Delete"
              modalSize="medium"
              isOpen={isStreamConfirmationModalOpen}
            >
              {!isStreamEditable(selectedStream.state) && (
                <DeleteMetricsConfirmation
                  shouldDeleteMetrics={shouldDeleteMetrics}
                  onShouldDeleteMetricsChange={this.onCheckBoxChange}
                />
              )}
            </ConfirmationModal>
          )}

          <div onKeyDown={this.stopPropagation} onClick={this.stopPropagation} role="presentation">
            {isSourceViewModalOpen &&
              React.cloneElement(this.modal, {
                isOpen: isSourceViewModalOpen,
                onClose: this.closeSourceViewModal,
              })}
          </div>
        </div>
      );
    }
  },
);
