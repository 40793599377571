import React, {Fragment, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {Box} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import * as selectors from 'impact/store/selectors';
import * as actions from 'impact/store/actions';
import * as bcSelectors from 'bc/store/selectors';
import {getTimeZoneName} from 'profile/store/selectors';
import {ORIGIN_TYPES, IMPACT_DEFAULT_VALUES, CURRENCY_OPTIONS} from 'impact/services/constants';
import ImpactListItem from 'impact/ImpactListItem';
import Measure from 'impact/impactSettings/Measure';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import InputAuto from 'alerts.management/components/editor/simulationArea/utils/InputAuto';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {segmentClickEvent} from 'common/store/actions';
import {palette} from 'app/styles/theme';

const ImpactsList = () => {
  const dispatch = useDispatch();
  const timeZone = useSelector(getTimeZoneName);
  const impactsView = useSelector(selectors.getImpacts);
  const impactsData = useSelector(selectors.getMergedMeasures);
  const selectedDataStreamSource = useSelector(bcSelectors.getSelectedDataStream);
  const alertsView = useSelector(selectors.getAlertTriggeredView);
  const isImpactsLoading = useSelector(selectors.getIsImpactsListLoading);
  const updateImpactIsLoading = useSelector(selectors.getUpdateImpactIsLoading);
  const createImpactIsLoading = useSelector(selectors.getCreateImpactIsLoading);
  const removeImpactIsLoading = useSelector(selectors.getRemoveImpactIsLoading);
  const isLoading = isImpactsLoading || updateImpactIsLoading || createImpactIsLoading || removeImpactIsLoading;

  const [activeId, setActiveId] = useState(null);
  const [activeMeasure, setActiveMeasure] = useState(null);
  const [overMeasure, setOverMeasure] = useState('');

  const theme = useTheme();

  const getImpacts = () => {
    const {id} = selectedDataStreamSource;

    dispatch(actions.getImpactsByOrigin({originId: id, originType: ORIGIN_TYPES.stream}));
  };

  const openRow = (impactId, measure) => {
    const {id} = selectedDataStreamSource;
    if (!impactId) {
      dispatch(
        actions.setImpactDefaultValues({
          ...IMPACT_DEFAULT_VALUES,
          measure,
          originId: id,
          originType: ORIGIN_TYPES.stream,
        }),
      );
    }
    setActiveId(impactId || IMPACT_DEFAULT_VALUES.id);
    setActiveMeasure(measure);
  };

  const closeRow = () => {
    setActiveMeasure(null);
  };

  const setValue = (e) => {
    const impactValue = {
      value: e.value,
      name: e.name,
      id: activeId,
    };
    dispatch(actions.setImpactValue(impactValue));
  };

  const submitImpact = () => {
    if (activeId && activeId !== IMPACT_DEFAULT_VALUES.id) {
      dispatch(actions.updateImpact(activeId));
      dispatch(segmentClickEvent({category: 'impact', name: 'update-impact-from-stream'}));
    } else {
      dispatch(actions.createImpact(IMPACT_DEFAULT_VALUES.id));
      dispatch(segmentClickEvent({category: 'impact', name: 'set-new-impact-from-stream'}));
    }
    closeRow();
  };

  const removeImpact = () => {
    dispatch(actions.removeImpact(activeId));
    dispatch(segmentClickEvent({category: 'impact', name: 'remove-impact-from-stream'}));
    closeRow();
  };

  const setImpactAndCurrency = (factor, currency) => {
    const currencyDirection = CURRENCY_OPTIONS[currency].direction;
    const currencySymbol = CURRENCY_OPTIONS[currency].symbol;
    const fixedFactor = parseFloat(factor).toFixed(2);

    if (currencyDirection === 'ltr') {
      return `${currencySymbol} ${fixedFactor}`;
    }
    return `${fixedFactor} ${currencySymbol}`;
  };

  const dismiss = () => {
    dispatch(segmentClickEvent({category: 'impact', name: 'not-now-button-from-stream'}));
    closeRow();
  };

  const noAlertLink = () => {
    dispatch(segmentClickEvent({category: 'impact', name: 'impact-learn-more-link'}));
    window.open('https://support.anodot.com/hc/en-us/articles/360016317859', '_blank');
  };

  useEffect(() => {
    getImpacts();
  }, []);

  return (
    <Fragment>
      <TypographyBox variant="h2" mb={2}>
        Set Impact value for the stream measures
      </TypographyBox>

      {isLoading && (
        <Box
          css={{
            position: 'absolute',
            width: '77%',
            height: '100%',
            top: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner color={palette.gray[500]} size={SIZES.X_BIG_90} />
        </Box>
      )}
      <Grid container style={{opacity: isLoading ? '0.5' : '1', pointerEvents: isLoading ? 'none' : 'auto'}}>
        <Grid item sm={6} style={{marginBottom: '12px'}}>
          <TypographyBox variant="subtitle1" component="span" mr={1}>
            Measures
          </TypographyBox>
        </Grid>
        <Grid item sm={4} style={{marginBottom: '12px'}}>
          <TypographyBox variant="subtitle1" component="span" mr={1}>
            Impact
          </TypographyBox>
        </Grid>

        {impactsData.map((impact) => (
          <Fragment key={impact.measure}>
            {activeMeasure === impact.measure && (
              <ImpactListItem
                impact={impactsView[activeId]}
                alert={alertsView[activeMeasure + selectedDataStreamSource.id]}
                timeZone={timeZone}
                onSetValue={setValue}
                onSubmitImpact={submitImpact}
                onRemoveImpact={removeImpact}
                onDismiss={dismiss}
                onNoAlertLink={noAlertLink}
              />
            )}
            {activeMeasure !== impact.measure && (
              <Grid
                container
                onMouseOver={() => setOverMeasure(impact.measure)}
                onMouseLeave={() => setOverMeasure('')}
                onClick={() => openRow(impact.id, impact.measure)}
              >
                <Grid item sm={6} style={{opacity: impact.id ? '1' : '0.6', display: 'flex', marginBottom: '16px'}}>
                  <Measure measure={impact.measure} />
                </Grid>

                <Grid item sm={2} style={{marginBottom: '16px', display: 'flex', alignItems: 'center'}}>
                  {impact.id ? (
                    <InputAuto
                      style={{padding: '3px 8px 2px', cursor: 'pointer'}}
                      fullSize
                      value={setImpactAndCurrency(impact.factor, impact.currency)}
                      isDisabled
                    />
                  ) : (
                    <TypographyBox
                      variant="subtitle1"
                      component="span"
                      color={theme.palette.blue['500']}
                      css={{cursor: 'pointer'}}
                    >
                      Set Value
                    </TypographyBox>
                  )}
                </Grid>

                <Grid item sm={2} style={{marginBottom: '16px', display: 'flex', alignItems: 'center'}}>
                  {impact.id && impact.measure === overMeasure && (
                    <TypographyBox
                      variant="subtitle1"
                      component="span"
                      color={theme.palette.blue['500']}
                      css={{cursor: 'pointer'}}
                      ml={1}
                    >
                      Edit Value
                    </TypographyBox>
                  )}
                </Grid>
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>
    </Fragment>
  );
};

export default ImpactsList;
