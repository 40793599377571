// @flow
import React from 'react';
import moment from 'moment';
import {getCorrectTimezoneName, getFormattedMDH} from 'common/utils/dateService';
import './UserEvents.module.scss';

const UserEventsTooltipTemplate = (props: {
  event: Object,
  resolution: String,
  timeZoneName: String,
  timeOfIncident: Number,
}) => {
  const firstEvent = props.event?.topEvents[0] || '';

  const getDate = () => {
    const momentDate = moment.unix(firstEvent?.startDate);
    const timeZone = getCorrectTimezoneName(props.timeZoneName);

    if (['weekly', 'longlong'].includes(props.resolution)) {
      return momentDate.tz(timeZone).format('MMM D, YYYY');
    }

    return getFormattedMDH(firstEvent?.startDate, timeZone);
  };

  const getTimeToIncident = () => {
    let timeToIncident =
      moment.unix(props.timeOfIncident).from(moment.unix(firstEvent.startDate)) !== 'Invalid date'
        ? `${moment.unix(props.timeOfIncident).from(moment.unix(firstEvent.startDate))}`
        : '';

    if (!timeToIncident) return '';

    if (timeToIncident.includes('ago')) {
      timeToIncident = timeToIncident.replace('ago', 'after incident');
    } else if (timeToIncident.startsWith('in')) {
      timeToIncident = timeToIncident.replace('in ', '').concat(' before incident');
    }

    // Product requested to show 'h' instead of hours and 'd' instead of days.
    const values = {' hours': 'h', ' days': 'd'};
    const searchExp = new RegExp(Object.keys(values).join('|'), 'gi');
    timeToIncident = timeToIncident.replace(searchExp, function(matched) {
      return values[matched];
    });

    return `| ${timeToIncident}`;
  };

  return (
    <>
      <div styleName="events-tooltip-wrapper">
        <div styleName="events-tooltip-content">
          <div styleName="events-info">
            <div styleName="events-name tooltip-line-clamp">{firstEvent?.title ?? 'N/A'}</div>
            <div styleName="events-description tooltip-line-clamp">{firstEvent?.description ?? ''}</div>
            <div styleName="events-date">
              {getDate()} {getTimeToIncident()}
            </div>
          </div>
          {props.event.totalEvents > 1 && <div styleName="events-count">+{props.event.totalEvents - 1}</div>}
        </div>
      </div>
    </>
  );
};

export default UserEventsTooltipTemplate;
