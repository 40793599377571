import React from 'react';
import NewRelicData from './newRelicData/NewRelicData';
import NewrelicDimensionsAndMetrics from './newrelicDimensionsAndMetrics/NewrelicDimensionsAndMetrics';
import NewrelicSchedular from './newrelicSchedular/NewrelicSchedular';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

import './NewRelicStreamEditor.module.scss';

const GoogleAnalyticsStreamEditor = () => (
  <div className="shell-col">
    <StreamEditorSteps>
      <NewRelicData />
      <NewrelicDimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <NewrelicSchedular />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default GoogleAnalyticsStreamEditor;
