// @flow
import React from 'react';
import {connect} from 'react-redux';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import Button, {COLORS, WIDTHS} from 'common/componentsV2/Button';
import {segmentClickEvent} from 'common/store/actions';
import FilterButton from 'common/componentsV2/FilterButton';
import MetricsUsage from 'common/componentsV2/metricsUsage/MetricsUsage';
import {setDataManagerFiltersIsOpen} from 'bc/store/actions';
import {fetchAccessListForAllSources} from 'admin.permissions/store/actions';
import {
  getDataManagerFiltersIsOpen,
  getDataMangerFilterToolTip,
  getDataStreamsItemsOrdered,
  getDataStreamsItemsOrderedAndFiltered,
  getDataManagerOpenedSources,
} from 'bc/store/selectors';
import {getUserProfile, getIsOnPremNewStreamEnabled} from 'profile/store/selectors';
import {fetchMetricsUsage} from 'insightsPanel/store/actions';
import {getMetricsUsage} from 'insightsPanel/store/selectors';
import {types as accessListTypes} from 'admin.permissions/services/accessListService';
import NewStreamBySourceModal from '../dataManager/NewStreamBySourceModal';

import './DataManagerHeader.module.scss';

type PropTypes = {
  setDataManagerFiltersIsOpen: Function,
  dataStreamsCount: Array,
  onCollapseAll: Function,
  onExpandAll: Function,
  savedOpenSources: Array,

  // connect
  isFilterBarOpen: Boolean,
  filterTooltip: Object,
  filteredDataStreamsCount: Number,
  dataStreamsCount: Number,
  fetchMetricsUsage: Function,
  segmentClickEvent: Function,
  userProfile: Object,
  fetchAccessListForAllSources: Function,
  isOnPremNewStreamEnabled: Boolean,
};

export default connect(
  (state) => ({
    isFilterBarOpen: getDataManagerFiltersIsOpen(state),
    filterTooltip: getDataMangerFilterToolTip(state),
    filteredDataStreamsCount: getDataStreamsItemsOrderedAndFiltered(state).length,
    dataStreamsCount: getDataStreamsItemsOrdered(state).length,
    metricsUsage: getMetricsUsage(state),
    savedOpenSources: getDataManagerOpenedSources(state),
    userProfile: getUserProfile(state),
    isOnPremNewStreamEnabled: getIsOnPremNewStreamEnabled(state),
  }),
  {
    setDataManagerFiltersIsOpen,
    fetchMetricsUsage,
    segmentClickEvent,
    fetchAccessListForAllSources,
  },
)(
  class AlertManagerHeader extends React.PureComponent {
    props: PropTypes;

    state = {
      isModalOpen: false,
    };

    componentDidMount() {
      this.props.fetchMetricsUsage();
      this.props.fetchAccessListForAllSources({}, {type: accessListTypes.stream});
    }

    openModal = () => {
      this.props.segmentClickEvent({category: 'data-management-main', name: 'new-stream-main-click'});
      this.setState({isModalOpen: true});
    };

    closeModal = () => {
      this.setState({isModalOpen: false});
    };

    onFilterButtonClick = () => {
      const value = this.props.isFilterBarOpen ? 'collapse-filter' : 'open-filter';
      this.props.segmentClickEvent({category: 'data-management-filter', name: `${value}`});
      this.props.setDataManagerFiltersIsOpen(!this.props.isFilterBarOpen);
    };

    onMenuButtonClick = (action) => {
      if (action === 'expand') {
        this.props.segmentClickEvent({category: 'data-management-main', name: 'expand-all'});
        this.props.onExpandAll();
      } else {
        this.props.segmentClickEvent({category: 'data-management-main', name: 'collapse-all'});
        this.props.onCollapseAll();
      }
    };

    render() {
      const {
        filteredDataStreamsCount,
        dataStreamsCount,
        savedOpenSources,
        userProfile,
        isOnPremNewStreamEnabled,
      } = this.props;
      const {isModalOpen} = this.state;
      const buttonSufix = !savedOpenSources || !savedOpenSources.length ? 'expand' : 'collapse';

      // new stream button enabled check
      const authorizedRoles = ['anodot-admin', 'anodot-user', 'reseller-admin', 'reseller-user', 'customer-admin'];
      let isAuthorizedNewStream = userProfile.roles.some((r) => authorizedRoles.includes(r));
      if (isAuthorizedNewStream && window.isOnPremise) {
        // also check feature flag for OnPremise users
        isAuthorizedNewStream = isOnPremNewStreamEnabled;
      }

      let automationIdFilters = '';
      this.props.filterTooltip.forEach((item) => {
        automationIdFilters = `${automationIdFilters + item.id}:${item.value}|`;
      });

      return (
        <header styleName="page-header">
          <div styleName="container">
            <Title type={TITLE_TYPES.PAGE_TITLE}>Sources</Title>
            <Title type={TITLE_TYPES.PAGE_LINE_COUNTER}>
              (
              {filteredDataStreamsCount !== dataStreamsCount
                ? `${filteredDataStreamsCount}/${dataStreamsCount}`
                : `${dataStreamsCount}`}
              )
            </Title>
            <FilterButton
              tooltipItems={this.props.filterTooltip}
              onClick={this.onFilterButtonClick}
              automationId={`applied_filters ${automationIdFilters}`}
            />
          </div>
          <div styleName="buttons-wrap">
            <MetricsUsage />
            <div styleName="dropdown-option-wrap">
              <Button
                fixedWidth={35}
                width={WIDTHS.NARROW_TIGHT}
                colorSchema={COLORS.GRAY_300}
                icon={`icn-action16-${buttonSufix}`}
                onClick={() => this.onMenuButtonClick(buttonSufix)}
                automationId="dataManagerExpendAll"
              />
            </div>
            {isAuthorizedNewStream && (
              <Button
                colorSchema={COLORS.BLUE_500}
                icon="icn-action16-plusa"
                text="New Stream"
                automationId="dataManagerNewSourceButton"
                onClick={this.openModal}
              />
            )}
          </div>

          {isModalOpen && <NewStreamBySourceModal isOpen={isModalOpen} onClose={this.closeModal} />}
        </header>
      );
    }
  },
);
