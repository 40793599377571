const SNOWFLAKE_REGIONS = [
  {text: 'AWS US East (Ohio)', value: 'us-east-2.aws'},
  {text: 'AWS US East (N. Virginia)', value: 'us-east-1'},
  {text: 'AWS US East (Commercial Gov - N. Virginia)', value: 'us-east-1-gov.aws'},
  {text: 'AWS US West (Oregon)', value: 'us-west-2'},
  {text: 'AWS US Gov West 1', value: 'us-gov-west-1'},
  {text: 'AWS Canada (Central)', value: 'ca-central-1.aws'},
  {text: 'AWS South America (Sao Paulo)', value: 'sa-east-1.aws'},
  {text: 'AWS EU (Ireland)', value: 'eu-west-1'},
  {text: 'AWS EU (London)', value: 'eu-west-2.aws'},
  {text: 'AWS EU (Frankfurt)', value: 'eu-central-1'},
  {text: 'AWS EU (Stockholm)', value: 'eu-north-1.aws'},
  {text: 'AWS Asia Pacific (Tokyo)', value: 'ap-northeast-1.aws'},
  {text: 'AWS Asia Pacific (Osaka)', value: 'ap-northeast-3.aws'},
  {text: 'AWS Asia Pacific (Seoul)', value: 'ap-northeast-2.aws'},
  {text: 'AWS Asia Pacific (Mumbai)', value: 'ap-south-1.aws'},
  {text: 'AWS Asia Pacific (Jakarta)', value: 'ap-southeast-3.aws'},
  {text: 'AWS Asia Pacific (Singapore)', value: 'ap-southeast-1'},
  {text: 'AWS Asia Pacific (Sydney)', value: 'ap-southeast-2'},
  {text: 'GCP US Central1 (Iowa)', value: 'us-central1.gcp'},
  {text: 'GCP US East4 (N. Virginia)', value: 'us-east4.gcp'},
  {text: 'GCP Europe West2 (London)', value: 'europe-west2.gcp'},
  {text: 'GCP Europe West4 (Netherlands)', value: 'europe-west4.gcp'},
  {text: 'Azure West US 2 (Washington)', value: 'west-us-2.azure'},
  {text: 'Azure Central US (Iowa)', value: 'central-us.azure'},
  {text: 'Azure South Central US (Texas)', value: 'south-central-us.azure'},
  {text: 'Azure East US 2 (Virginia)', value: 'east-us-2.azure'},
  {text: 'Azure US Gov Virginia', value: 'us-gov-virginia.azure'},
  {text: 'Azure Canada Central (Toronto)', value: 'canada-central.azure'},
  {text: 'Azure North Europe (Ireland)', value: 'north-europe.azure'},
  {text: 'Azure West Europe (Netherlands)', value: 'west-europe.azure'},
  {text: 'Azure Switzerland North (Zurich)', value: 'switzerland-north.azure'},
  {text: 'Azure UAE North (Dubai)', value: 'uae-north.azure'},
  {text: 'Azure Central India (Pune)', value: 'central-india.azure'},
  {text: 'Azure Japan East (Tokyo)', value: 'japan-east.azure'},
  {text: 'Azure Southeast Asia (Singapore)', value: 'southeast-asia.azure'},
  {text: 'Azure Australia East (New South Wales)', value: 'australia-east.azure'},
  {text: 'EU (Paris)', value: 'eu-west-3.aws'},
  {text: 'EU (Stockholm)', value: 'eu-north-1.aws'},
  {text: 'UK South (London)', value: 'uk-south.azure'},
  {text: 'US Gov West 1 (FedRAMP High Plus)', value: 'xy12345.fhplus.us-gov-west-1.aws'},
];

export default SNOWFLAKE_REGIONS;
