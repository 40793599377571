// @flow
import React, {useEffect, useState} from 'react';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import SearchBox from 'common/componentsV2/SearchBox';
import Button, {COLORS} from 'common/componentsV2/Button';
import EmptyListImageComponent from 'common/componentsV2/EmptyListImageComponent';
import UserEventsTable from 'userEvents/components/UserEventsTable/UserEventsTable';
import './UserEvents.module.scss';
import {CHART_HEIGHT} from 'alerts.console/services/alertsConsoleService';

type PropTypes = {
  events: Array,
  selectedEventId: string,
  timeZoneName: string,
  chart: React.Node,
  chartTitle: React.Node,
  isOpen: boolean,
  onClose: Function,
};

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const EmptyListIndication = (props: {isLoading: boolean, topEvents: Array}) => {
  if (props.isLoading || props.topEvents.length) {
    return null;
  }

  return (
    <div styleName="empty-list">
      <EmptyListImageComponent />
      <div styleName="empty-text">No Events Found - Refine Search</div>
    </div>
  );
};

const UserEventsChartModal = ({
  events,
  selectedEventId,
  timeZoneName,
  chart,
  chartTitle,
  isOpen,
  onClose,
}: PropTypes) => {
  const [event, setEvent] = useState(EMPTY_OBJECT);
  const [topEvents, setTopEvents] = useState(EMPTY_ARRAY);
  const [filter, setFilter] = useState('');

  // set the selected user event from which to take topEvents
  useEffect(() => {
    if (events && events.length) {
      const findEvent = events.find((ev) => ev.id === selectedEventId);
      setEvent(findEvent || EMPTY_OBJECT);
    } else {
      setEvent(EMPTY_OBJECT);
    }
  }, [events, selectedEventId]);

  // set the selected topEvents and filtered
  useEffect(() => {
    if (event && event.topEvents) {
      setTopEvents(event.topEvents.filter((ev) => ev.title.toLowerCase().indexOf(filter.toLowerCase()) !== -1));
    } else {
      setTopEvents(EMPTY_ARRAY);
    }
  }, [event, filter]);

  const onFilter = (val) => {
    setFilter(val);
  };

  return (
    <Modal
      isOpen={isOpen}
      size={SIZES.LARGE}
      onClose={onClose}
      isCloseButtonHidden
      isStatic
      className="alert-clickable-item"
    >
      <div styleName="modal-events-wrapper">
        <div styleName="modal-title">Event Details</div>
        {chartTitle && <div>{chartTitle}</div>}
        <div style={{height: CHART_HEIGHT}}>{chart}</div>
        <div styleName="events-table-title">
          {topEvents.length !== event.totalEvents
            ? `Events (${topEvents.length}/${event.totalEvents})`
            : `Events (${event.totalEvents})`}
          <SearchBox filter={filter} onFilter={onFilter} placeHolder="Search events" />
        </div>
        <div style={{minHeight: CHART_HEIGHT, marginTop: 12}}>
          {topEvents.length ? (
            <UserEventsTable
              events={topEvents}
              isLoading={event === EMPTY_OBJECT}
              searchString={filter}
              timeZoneName={timeZoneName}
            />
          ) : null}
          <EmptyListIndication topEvents={topEvents} />
        </div>
        <div styleName="close-button-container">
          <Button colorSchema={COLORS.BLUE_500} text="Close" automationId="closeEventsModal" onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(UserEventsChartModal);
