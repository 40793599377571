// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedEditor} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import AwsCurTemplates from './AwsCurTemplates';
import AwsCurTags from './AwsCurTags';

import './DimensionsAndMetrics.module.scss';

const List = ({title, list}: any) => (
  <div styleName="dm-list">
    <div styleName="title">{title}</div>
    <div styleName="scroll">
      {list.map((_item) => (
        <div key={_item}>{_item}</div>
      ))}
    </div>
  </div>
);

type PropTypes = {
  selectedDimensions: Array,
  selectedMetrics: Array,

  // connect
  setSelectedEditor: Function,
};

export default connect(
  (state) => ({
    selectedMetrics: selectors.getSelectedDataStreamMetaMetrics(state),
    selectedDimensions: selectors.getSelectedDataStreamMetaDimensions(state),
  }),
  {
    setSelectedEditor,
  },
)(
  class DimensionsAndMetrics extends React.PureComponent {
    props: PropTypes;

    render() {
      const {selectedMetrics, selectedDimensions} = this.props;

      return (
        <div className="shell-col" styleName="root" automation-id="streamQueryMeasuresAndDimensions">
          <PanelHeader
            title="Measures & Dimensions"
            onSelect={() => this.props.setSelectedEditor({type: 'dm'})}
            isEditable
            isEditHidden
          />

          <AwsCurTemplates />

          <table className="shell" styleName="sections-container" automation-id="dimensionsAndMetricsTable">
            <tbody styleName="sections-container">
              <tr>
                <td styleName="section">
                  <List title="List of Measures" list={selectedMetrics} />
                </td>
                <td styleName="section">
                  <List title="List of Dimensions" list={selectedDimensions} />
                </td>
              </tr>
            </tbody>
          </table>

          <AwsCurTags />
        </div>
      );
    }
  },
);
