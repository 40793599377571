import {useSelector} from 'react-redux';
import React, {useEffect, useMemo} from 'react';
import {getFormattedHumanDuration} from 'common/utils/dateService';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import {makeStyles} from '@material-ui/core';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {getAlertConfigurationsItems} from 'alerts.management/store/selectors';
import {alertConditionTypes} from 'alerts.management/services/alertsService';

import '../OverwiewTab.module.scss';

const WhatInfoCubeStatic = ({
  onIsLoading,
  isTriggerLoading,
  trigger,
}: {
  onIsLoading: Function,
  isTriggerLoading: Boolean,
  trigger: Object,
}) => {
  const css = makeStyles(() => ({
    actionBtn: {
      maxWidth: 254,
    },
    whatTabChip: {
      maxWidth: 253,
      marginLeft: 0,
    },
  }));
  const classes = css();

  const alertConfigurations = useSelector(getAlertConfigurationsItems);
  const what = trigger?.metrics[0]?.what;

  const alertThreshold = useMemo(() => {
    const alertConfiguration = alertConfigurations?.find((alert) => alert.data.id === trigger.alertConfigurationId);
    const thresholds = alertConfiguration?.data?.conditions?.find(
      (condition) => condition.type === alertConditionTypes.staticThreshold,
    );
    if (thresholds) {
      return {minValue: thresholds.minValue, maxValue: thresholds.maxValue};
    }
    return {};
  }, [alertConfigurations]);

  const isDirectionDown = useMemo(() => {
    if (!trigger || !trigger.metrics || !trigger.metrics.length) {
      return undefined;
    }

    const lastMetric = trigger.metrics[trigger.metrics.length - 1];
    const lastInterval = lastMetric?.intervals[lastMetric.intervals.length - 1];
    return lastInterval?.direction === 'DOWN';
  }, [trigger.metrics]);

  useEffect(() => {
    onIsLoading(isTriggerLoading);
  }, [isTriggerLoading]);

  const isOpen = trigger.status.toLowerCase() === 'open';
  const durText = getFormattedHumanDuration(trigger.duration, isOpen ? 'absolute' : null).trim();

  const fullDurText = isOpen ? ` for ${durText}` : `, lasted ${durText}`;
  const statusText = isOpen ? 'Open' : 'Closed';

  return (
    <>
      {trigger && trigger?.metrics?.length ? (
        <div styleName="info-cube-container">
          <div>
            <Tooltip content={what} type={TYPES.SMALL}>
              <div styleName="tooltip-container">
                <InnerChip className={classes.whatTabChip} title={what} displayOnly type="measure" />
              </div>
            </Tooltip>
            <div styleName="what-info-cube-direction">
              {isDirectionDown && <div>{`Lower Threshold (${alertThreshold.minValue}) was crossed`}</div>}
              {!isDirectionDown && <div>{`Upper Threshold (${alertThreshold.maxValue}) was crossed`}</div>}
            </div>
          </div>
          <div>
            <div styleName="what-info-cube-status-container">
              <div styleName={['what-info-cube-status-text', isOpen ? 'open' : ''].join(' ')}>{statusText}</div>
              <span>{fullDurText}</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WhatInfoCubeStatic;
