// @flow
import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import * as selectors from 'userSettings/store/selectors';
import * as profileSelector from 'profile/store/selectors';
import ErrorBoundary from 'errorBoundaries/ErrorBoundary';
import {Form} from 'react-final-form';
import get from 'lodash/get';
import {updateUserNameOnly, updateUserSettings, updateMe} from 'profile/store/actions';
import {updatePasswordRequest} from 'userSettings/store/actions';
import useAsyncAction from 'common/utils/useAsyncAction';
import PersonalInfoSettings from './PersonalInfoSettings';
import PasswordSettings from './PasswordSettings';
import TimeZoneSettings from './TimeZoneSettings';
import BottomPanel from '../BottomPanel';

const useStyles = makeStyles({
  root: {
    paddingBottom: '60px',
  },
});

const DetailsAndSecuritySection = () => {
  const dispatch = useDispatch();
  const me = useSelector(profileSelector.getUserProfile);
  const getDataUser = useSelector(profileSelector.getProfileData);
  const getPassword = useSelector(selectors.getPassword);
  const getTimeZone = useSelector(profileSelector.getTimeZoneName);

  const getFirstName = get(me, 'firstName');
  const getLastName = get(me, 'lastName');
  const getEmail = get(me, 'email');

  const initialValuesForm = {
    firstName: getFirstName,
    lastName: getLastName,
    email: getEmail,
    timeZoneName: getTimeZone,
  };
  const fetchUserName = useAsyncAction(updateUserNameOnly, getDataUser);
  const fetchUpdatePassword = useAsyncAction(updatePasswordRequest, getPassword);
  const fetchUpdateMe = useAsyncAction(updateMe, getDataUser);

  const actionSubmit = (values, form) => {
    const formState = form.getState();
    const {firstName, lastName, timeZoneName, currentPassword, newPassword} = values;
    const {
      firstName: dirtyFirstName,
      lastName: dirtyLastName,
      timeZoneName: dirtyTimeZoneName,
      currentPassword: dirtyCurrentPassword,
      newPassword: dirtyNewPassword,
      confirmNewPassword: dirtyConfirmNewPassword,
    } = formState.dirtyFields;

    const isUserNameUpdate = dirtyFirstName || dirtyLastName;
    const isUpdatePassword = dirtyCurrentPassword && dirtyNewPassword && dirtyConfirmNewPassword;
    const updateUserName = {
      firstName,
      lastName,
      id: me._id,
    };
    const updatePassword = {
      currentPassword,
      newPassword,
    };
    const updateMeBody = {
      ...me,
      firstName,
      lastName,
      id: me._id,
      appSettings: {
        timeZone: {
          name: timeZoneName,
        },
      },
    };

    if ((isUserNameUpdate && isUpdatePassword && dirtyTimeZoneName) || (isUserNameUpdate && isUpdatePassword)) {
      return Promise.all([fetchUpdateMe(updateMeBody), fetchUpdatePassword(updatePassword)]).then(() => {
        setTimeout(form.reset);
      });
    }
    if (isUserNameUpdate) {
      return fetchUserName(updateUserName).then(() => {
        setTimeout(form.reset);
      });
    }
    if (isUpdatePassword) {
      return fetchUpdatePassword(updatePassword)
        .then(() => {
          setTimeout(form.reset);
        })
        .catch(() => {
          setTimeout(form.reset);
        });
    }
    if (dirtyTimeZoneName) {
      dispatch(
        updateUserSettings({
          ...me.appSettings,
          timeZone: {
            name: timeZoneName,
          },
        }),
      );
    }

    return false;
  };

  const classes = useStyles();
  return (
    <ErrorBoundary>
      <Form initialValues={initialValuesForm} onSubmit={actionSubmit}>
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit} className={classes.root}>
            <PersonalInfoSettings email={getEmail} />
            <PasswordSettings />
            <TimeZoneSettings />
            {formProps.dirty && (
              <BottomPanel
                errorNumbers={Object.keys(formProps.errors).length}
                isOpenPopper={formProps.submitFailed && formProps.hasValidationErrors}
              />
            )}
          </form>
        )}
      </Form>
    </ErrorBoundary>
  );
};

export default DetailsAndSecuritySection;
