// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import {getHolidayChristmas, getHolidayHalloween, getHolidayStPatrickDay} from 'profile/store/selectors';
import HalloweenIcons from 'common/componentsV2/holiday/halloween/HalloweenIcons';
import ChristmasIcons from 'common/componentsV2/holiday/christmas/ChristmasIcons';
import StPatrickDayIcons from 'common/componentsV2/holiday/stPatrickDay/StPatrickDayIcons';

const HolidayIcons = ({title}: {title: string}) => {
  const isHalloween = useSelector(getHolidayHalloween);
  const isChristmas = useSelector(getHolidayChristmas);
  const isStPatrickDay = useSelector(getHolidayStPatrickDay);

  if (isHalloween) {
    return <HalloweenIcons title={title} />;
  }
  if (isChristmas) {
    return <ChristmasIcons title={title} />;
  }
  if (isStPatrickDay) {
    return <StPatrickDayIcons title={title} />;
  }
  return null;
};

export default HolidayIcons;
