import React from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';
import TooltipArea from 'common/componentsV2/TooltipArea';
import AutocompleteSelection from 'common/componentsV2/autocompleteSelection/AutocompleteSelection';

const AlertName = ({alertTitleInputRef}: {alertTitleInputRef: Object}) => {
  const {
    input: {value, onChange},
    meta,
  } = useField('title');

  return (
    <TooltipArea
      isAlwaysVisible
      automationId="alertNameContainer"
      text="You can apply dynamic values to the alert name and description by using {{"
    >
      {(info) => (
        <React.Fragment>
          <div className="text16reg lineHeight_16 mb_1">
            Alert name
            <a
              href="https://support.anodot.com/hc/en-us/articles/360017853639-Applying-Variables-to-Alert-Names-and-Descriptions"
              rel="noopener noreferrer"
              target="_blank"
            >
              {info}
            </a>
          </div>
          <AutocompleteSelection onChange={onChange} inputValue={value} automationId="alertNameAutocomplete">
            {(handleChange, handleKeyDown) => (
              <Input
                automationId="alertTitle"
                additionalProps={{name: 'title', ref: alertTitleInputRef, autoComplete: 'off'}}
                isInvalid={meta.touched && meta.error}
                invalidMessage={meta.error}
                value={value}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
              />
            )}
          </AutocompleteSelection>
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default AlertName;
