import {rangeTypes} from 'common/utils/dateRangeService';
import {info as infoNotificationToast} from 'common/utils/notifications/notificationsService';

export const CHART_HEIGHT = 250;

export const ALERT_CONSOLE_COLUMN_HEADERS = {
  SEVERITY: {id: 7, name: 'SEVERITY', title: ''},
  RECOMMENDATIONS: {
    id: 6,
    name: 'RECOMMENDATIONS',
    title: '',
    tooltip: 'Recommendations',
    isSortable: false,
  },
  ASSIGNEE: {id: 5, name: 'ASSIGNEE', title: 'Assignee', isSortable: false},
  START_TIME: {id: 0, name: 'START_TIME', title: 'Started'},
  UPDATE_TIME: {id: 1, name: 'UPDATE_TIME', title: 'Updated'},
  DURATION: {id: 2, name: 'DURATION', title: 'Duration'},
  SCORE: {id: 3, name: 'SCORE', title: 'Score'},
  NUM_METRICS: {id: 4, name: 'NUM_METRICS', title: 'Metrics'},
};

export const ORDER_DIRECTIONS = {
  DESC: 'DESC',
  ASC: 'ASC',
};

export const ALERT_CONSOLE_QUERY_PARAMS_KEYS = {
  ACK_BY: 'ackBy',
  ALERT_CONFIGURATION_IDS: 'configIds',
  ANALYTICS: 'analytics',
  BREACH_TIME_FROM: 'breachTimeFrom',
  BREACH_TIME_TO: 'breachTimeTo',
  ASSIGNEES: 'assignees',
  CHANNELS: 'channels',
  CONST_RANGE: 'constRange',
  FEEDBACK: 'feedback',
  HAS_ASSIGNEE: 'hasAssignee',
  IS_OPEN: 'isOpen',
  LABELS: 'labels',
  MAKE_ACK: 'makeAck',
  NOT_ACK_BY: 'notAckBy',
  ORDER: 'order',
  PAGE_NUM: 'pageNum',
  PAGE_SIZE: 'pageSize',
  REF: 'ref',
  SEARCH_EXPRESSION: 'searchExpression',
  SEVERITIES: 'severities',
  SEVERITY_VIEW: 'severityView',
  SORT_BY: 'sortBy',
  SUBSCRIBERS: 'subscribers',
  TRIGGER_IDS: 'triggerIds',
  TYPES: 'types',
};

export const REF_VALUES = {
  ALERT_MANAGER: 'alertmanager',
  EMAIL: 'email',
  INVESTIGATE: 'investigate',
  SLACK: 'slack',
};

export const ALERT_IS_OPEN = {
  ALL: {label: 'All', value: undefined, id: 'all'},
  OPEN: {label: 'isOpen', value: 'true', id: 'open', text: 'true'},
};

export const ACK_OPTIONS = {
  ALL: {label: 'All', id: 'all', value: undefined},
  ACK_BY: {label: 'Acknowledged', id: 'ackBy', value: 'ackBy'},
  NOT_ACK_BY: {label: 'Not Acknowledged', id: 'notAckBy', value: 'notAckBy'},
  ACK_BY_ME: {label: 'Acknowledged by me', id: 'ackBy', value: 'me'},
};

export const PAGINATION_MENU_OPTIONS = {
  NEWEST: {value: 'newest', label: 'Newest'},
  OLDEST: {value: 'oldest', label: 'Oldest'},
};

export const FEEDBACK_OPTIONS = {
  POSITIVE: {
    label: 'Good Catch',
    value: 'positive',
    id: 'positive',
    icon: 'icon icn-general24-like',
  },
  NEGATIVE: {
    label: 'Not Interesting',
    value: 'negative',
    id: 'negative',
    icon: 'icon icn-general24-dislike',
  },
  SEPARATOR: {
    label: 'FEEDBACK_SEPARATOR',
    value: 'FEEDBACK_SEPARATOR',
    type: 'SEPARATOR',
  },
  NONE: {
    label: 'No Feedback',
    value: 'none',
    id: 'none',
  },
  ANY: {
    value: 'any',
    id: 'any',
    label: 'Any',
    isHidden: true,
  },
};

export const ALERT_TYPES = {
  ANOMALY: 'ANOMALY',
  STATIC: 'STATIC',
  NO_DATA: 'NO_DATA',
};

export const TYPE = {
  ANOMALY: {label: 'Anomaly', value: ALERT_TYPES.ANOMALY, id: 'anomaly'},
  STATIC: {label: 'Static', value: ALERT_TYPES.STATIC, id: 'static'},
  NO_DATA: {label: 'No Data', value: ALERT_TYPES.NO_DATA, id: 'noData'},
};

export const SEVERITY = {
  CRITICAL: {label: 'Critical', value: 'CRITICAL', id: 'critical'},
  HIGH: {label: 'High', value: 'HIGH', id: 'high'},
  MEDIUM: {label: 'Medium', value: 'MEDIUM', id: 'medium'},
  LOW: {label: 'Low', value: 'LOW', id: 'low'},
  INFO: {label: 'Info', value: 'INFO', id: 'info'},
};

export const DEFAULT_QUERY_PARAMS = {
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ACK_BY]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ALERT_CONFIGURATION_IDS]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ANALYTICS]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.BREACH_TIME_FROM]: null,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.BREACH_TIME_TO]: null,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ASSIGNEES]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.CHANNELS]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.CONST_RANGE]: rangeTypes.d1.key,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.HAS_ASSIGNEE]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.IS_OPEN]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.LABELS]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.MAKE_ACK]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.NOT_ACK_BY]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ORDER]: ORDER_DIRECTIONS.DESC,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_NUM]: 0,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_SIZE]: 11,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.REF]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEARCH_EXPRESSION]: '',
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITIES]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITY_VIEW]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SORT_BY]: ALERT_CONSOLE_COLUMN_HEADERS.START_TIME.name,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SUBSCRIBERS]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.TRIGGER_IDS]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.TYPES]: undefined,
};

export const DEFAULT_QUERY_PARAMS_FILTERS = {
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ACK_BY]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ASSIGNEES]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.CHANNELS]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.CONST_RANGE]: rangeTypes.d1.key,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.IS_OPEN]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.LABELS]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.NOT_ACK_BY]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEARCH_EXPRESSION]: '',
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITIES]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITY_VIEW]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SUBSCRIBERS]: undefined,
  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.TYPES]: undefined,
};

export const REFRESH_ALERTS = {
  NOW: 'now',
  OFF: 'off',
  ONE_MINUTE: '1min',
  FIVE_MINUTES: '5min',
  TEN_MINUTES: '10min',
  FIFTEEN_MINUTES: '15min',
  THIRTY_MINUTES: '30min',
  SIXTY_MINUTES: '1hour',
};

// Beginning for the Filters Tooltip button
export const FILTER_KEY_HEADERS = {
  searchExpression: {
    id: 'searchExpression',
    label: 'Search:',
  },
  constRange: {
    id: 'constRange',
    label: 'Date range:',
  },
  isOpen: {
    id: 'isOpen',
    label: 'Is Open:',
  },
  usersAndChannels: {
    id: 'usersAndChannels',
    label: 'Recipients:',
  },
  types: {
    id: 'types',
    label: 'Alert type:',
  },
  labels: {
    id: 'labels',
    label: 'Labels:',
  },
  severities: {
    id: 'severities',
    label: 'Severities:',
  },
  assignees: {
    id: 'assignees',
    label: 'Assignees:',
  },
  feedback: {
    id: 'feedback',
    label: 'Feedback:',
  },
  acknowledgement: {
    id: 'acknowledgement',
    label: 'Acknowledgement:',
  },
};

export const ALLOWED_FILTER_KEYS = {
  constRange: rangeTypes,
  isOpen: Object.values(ALERT_IS_OPEN),
  feedback: Object.values(FEEDBACK_OPTIONS),
  types: Object.values(TYPE),
  severities: Object.values(SEVERITY),
};
// End Filters Tooltip button

export const ANOMALY_BUFFER_TIME = {
  '1m': 120,
  '5m': 600,
  '1h': 3600,
  '1d': 86400,
  '1w': 0,
};

export const calculateAcknowledgedUsers = (acknowledgedBy) => {
  const registeredUsersNames = acknowledgedBy?.userIds?.length
    ? acknowledgedBy.userIds.map((user) => user?.fullName || `${user?.firstName || ''} ${user?.lastName || ''}` || '')
    : [];
  const unregisteredUsersNames = acknowledgedBy?.unregisteredUserNames?.length
    ? acknowledgedBy.unregisteredUserNames
    : [];

  const usersAcked = [...registeredUsersNames, ...unregisteredUsersNames].join(', ');

  return usersAcked;
};

export const triggerTheSnackBar = (triggerIds, callbackFunc) => {
  const uid = 'filterByTriggerMsg';

  const notificationObj = {
    title: 'Alert Console is Filtered by an Alert',
    description: 'You’re seeing only data relevant to the Alert you clicked on',
    actions: [
      {
        label: 'Show all data ',
        callback: callbackFunc,
      },
    ],
    settings: {
      canClose: true,
      autoDismiss: 0,
      uid,
    },
  };
  return infoNotificationToast(notificationObj);
};

export const makeAckSnackbar = (triggeredAlert, meUser) => {
  const alertName = triggeredAlert?.title;

  // Trigger ack for the alert
  const notificationObj = {
    title: `Alert is acknowledged${meUser.firstName ? ` by ${meUser.firstName} ${meUser.lastName}` : ''}`,
    description: alertName,
    settings: {
      canClose: true,
      autoDismiss: 0,
      uid: 'ackMsg',
    },
  };
  return infoNotificationToast(notificationObj);
};

export const ALERT_ACTIONS = {
  INVESTIGATE: {value: 'investigate', label: 'Investigate'},
  SET_IMPACT: {value: 'setImpact', label: 'Edit Impact'},
  TRIAGE: {value: 'investigateV2', label: 'Triage'},
  TUNE_ALERT: {value: 'tuneAlert', label: 'Edit Alert'},
};
