/* eslint-disable consistent-return */
import {cloneDeep, defaultsDeep, set, get, pick, some} from 'lodash';
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import {get as httpGet} from 'common/utils/http';
import {toast} from 'react-toastify';
import React from 'react';
import SnackBar, {TYPES} from '../componentsV2/snackBar/SnackBar';

export {default as getEmptyExpression} from 'common/utils/metrics/getEmptyExpression';
export {default as getEmptyTree} from 'common/utils/metrics/getEmptyTree';
export {default as findFuncInTree} from 'common/utils/metrics/findFuncInTree';
export {default as findExpressionInTree} from 'common/utils/metrics/findExpressionInTree';
export {default as getApiTreeForNode} from 'common/utils/metrics/getApiTreeForNode';
export {
  default as getServerTreesModelFromCompositeDefinition,
} from 'common/utils/metrics/getServerTreesModelFromCompositeDefinition';
export {
  default as getTreesDisplayPropertiesFromCompositeDefinition,
} from 'common/utils/metrics/getTreesDisplayPropertiesFromCompositeDefinition';

localStorage.setItem('renewingToken', false);
sessionStorage.setItem('renewingToken', false);

const tempSetRequestAuthorizationHeader = function(config) {
  const updateHeader = function(tk) {
    if (config.headers.common) {
      // eslint-disable-next-line no-param-reassign
      config.headers.common.Authorization = `Bearer ${tk}`;
    } else {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${tk}`;
    }
  };

  const token = localStorage.getItem('andt-token');
  const sessionToken = sessionStorage.getItem('andt-token');

  if (sessionToken || token) {
    updateHeader(sessionToken || token);
  }
  /* if (isLeaving(config.url)) {
    localStorage.removeItem('andt-token');
    localStorage.removeItem('andt-token-ttl');
    localStorage.removeItem('andt-token-iat');
    sessionStorage.removeItem('andt-token');
    sessionStorage.removeItem('andt-token-ttl');
    sessionStorage.removeItem('andt-token-iat');
  } */
  return config;
};

const getAngularService = (svcName) => {
  try {
    return window.angular
      ? window.angular
          .element(document.body)
          .injector()
          .get(svcName)
      : null;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(svcName, 'Angular app is not connected');
  }
};

export const broadcast = (eventName, data) => {
  const svc = getAngularService('$rootScope');
  if (svc) {
    svc.$broadcast(eventName, data);
  }
};

export const updateUserSettings = (appSettings) => {
  const authSvc = getAngularService('andtAuthSvc');
  if (!authSvc) {
    return;
  }
  authSvc.me.appSettings = appSettings;
};

export const updateUser = (me) => {
  const authSvc = getAngularService('andtAuthSvc');
  if (!authSvc) {
    return;
  }
  authSvc.setMe(me);
};

export const signOut = (errMessage) => {
  const svc = getAngularService('andtUnauthorizedHelperSvc');
  if (!svc) {
    return;
  }
  svc.signout(errMessage);
};

export const showCompositeModal = (data, modalOverrides) => {
  const svc = getAngularService('andtCompositeService');
  if (!svc) {
    return;
  }
  return svc.showCompositeModal(data, modalOverrides);
};

export const showCompositeDelayModal = (expressionTree) => {
  const svc = getAngularService('andtCompositeDelayService');
  if (!svc) {
    return;
  }
  return svc.compositeDelayClick(expressionTree);
};

const constants = {
  alertType: {
    anomaly: {
      text: 'Anomaly',
      value: 'anomaly',
    },
    static: {
      text: 'Static',
      value: 'static',
    },
    noData: {
      text: 'No Data Only',
      text2: 'No Data',
      value: 'noData',
    },
  },
};

function extractEventsFilterData(eventsFilter) {
  return pick(get(eventsFilter, 'current'), ['aggregation', 'filter']);
}

function extractFilterData(filter) {
  let eventsFilter = null;
  const shouldAddEvents = get(filter, 'eventsFilter.shouldShowEvents', false);

  if (shouldAddEvents) {
    eventsFilter = extractEventsFilterData(get(filter, 'eventsFilter'));
    set(eventsFilter, 'aggregation.resolution', filter.rollup);
  }

  return {
    data: {
      type: 'incident',
      noDataDuration: filter.noDataDuration.value,
      eventsFilter,
      isInfluencingEvents: filter.isInfluencingEvents,
      staticAlert: filter.type.value === constants.alertType.static.value,
      anomalyAlert: filter.type.value === constants.alertType.anomaly.value,
      noDataAlert: filter.noDataAlert || filter.type.value === constants.alertType.noData.value,
      rollups: filter.rollup,
      updatePolicy: filter.updatePolicy,
      conditions: filter.conditions,
      enableAutoTuneByAnodot: filter.enableAutoTuneByAnodot,
      notifyOnlyOpen: filter.notifyOnlyOpen,
    },
    advancedModeOpen: filter.advancedModeOpen,
  };
}

export const mergeFilterWithDefinition = (filter, defintion) => {
  const filterData = extractFilterData(filter);
  const newDef = cloneDeep(defintion);
  newDef.data.conditions = null; // we don't want to merge arrays
  newDef.data.eventsFilter = null; // we don't want to merge arrays
  return defaultsDeep(filterData, newDef);
};

export const setResizablePanelDimension = (id, width, height) => {
  const svc = getAngularService('andtPanelResizerService');
  if (!svc) {
    return;
  }
  return svc.setPanelDimensions(id, {width, height});
};

export const logErrorToServer = (data) => {
  const svc = getAngularService('andtApplicationLoggingService');
  if (!svc) {
    return;
  }
  return svc.error(data);
};

export const setRequestAuthorizationHeader = (config) => {
  // const svc = getAngularService('andtAuthSvc');
  // if (!svc) {
  //  return config;
  // }
  return tempSetRequestAuthorizationHeader(config, true);
};

export const renewTokenIfNeeded = function(url) {
  const isLeaving = function() {
    return some(['signout', 'unanonymise'], function(value) {
      return url.indexOf(value) > 0;
    });
  };
  const generateLogoutNotification = (storage, tokenTtl) => {
    // This function generate timeout for notification about token that is about to expire
    const timeoutId = storage.getItem('andt-logout-timeout');
    clearTimeout(timeoutId);
    // set notification to pop 60 sec before expiration
    // 2147483.646 (* 1000) is the max delay allowed for setTimeout
    const notificationDelay = Math.min(Number(tokenTtl) - moment().unix() - 60, 2147483.646);
    const newLogoutTimeoutId = setTimeout(() => {
      toast(
        <SnackBar
          type={TYPES.INFO}
          isDismissible
          actions={[
            {
              label: 'Logout',
              callback: () => {
                storage.removeItem('andt-token');
                storage.removeItem('andt-token-ttl');
                storage.removeItem('andt-token-iat');

                window.location.href = `${window.location.origin}/signin/?err=401&returnUrl=${encodeURIComponent(
                  window.location.href,
                )
                  .split('%')
                  .join('~')}`;
              },
            },
            {
              label: 'Stay logged in',
              callback: () => {
                window.location.reload();
              },
            },
          ]}
          notificationData={{
            title: 'Your session is about to expire',
          }}
        />,
        {
          autoClose: false,
          uid: 'logout-notification',
        },
      );
    }, notificationDelay * 1000);
    storage.setItem('andt-logout-timeout', newLogoutTimeoutId);
  };

  const renewToken = function(isSessionStorageToken = false) {
    const storage = isSessionStorageToken ? sessionStorage : localStorage;
    return httpGet('/api/v1/access-token/renewal')
      .promise.then(function(response) {
        const {token} = response;
        const tokenTtl = JSON.parse(atob(token.split('.')[1])).exp;
        const tokenIat = JSON.parse(atob(token.split('.')[1])).iat;

        storage.setItem('andt-token', token);
        storage.setItem('andt-token-ttl', tokenTtl);
        storage.setItem('andt-token-iat', tokenIat);

        generateLogoutNotification(storage, tokenTtl);
      })
      .finally(function() {
        storage.setItem('renewingToken', false);
      });
  };

  const renewingToken = localStorage.getItem('renewingToken') === 'true';
  const tokenTtl = localStorage.getItem('andt-token-ttl');
  const tokenIat = localStorage.getItem('andt-token-iat') || 0;
  // used in anonymize
  const sessionTokenTtl = sessionStorage.getItem('andt-token-ttl');
  const sessionTokenIat = sessionStorage.getItem('andt-token-iat') || 0;
  // renew the token once 75% of the time passes or if there are less than 5 minutes
  const renewalTime = Math.max((tokenTtl - tokenIat) * 0.25, 300);
  if (!renewingToken && tokenTtl && Number(tokenTtl) - moment().unix() < renewalTime) {
    if (!isLeaving()) {
      // This global var is needed in order to protect from several asyc requests on the same time
      localStorage.setItem('renewingToken', true);
      renewToken();
    }
  }
  // Need both local storage and session storage since we migt have both login and anonymous
  const renewalTimeSession = Math.max((sessionTokenTtl - sessionTokenIat) * 0.25, 300);
  const renewingSessionToken = sessionStorage.getItem('renewingToken') === 'true';
  if (!renewingSessionToken && sessionTokenTtl && Number(sessionTokenTtl) - moment().unix() < renewalTimeSession) {
    if (!isLeaving()) {
      // This global var is needed in order to protect from several asyc requests on the same time
      sessionStorage.setItem('renewingToken', true);
      renewToken(true);
    }
  }
};
export const copyToClipboard = (text, event, onSuccess, onFailure) => {
  try {
    navigator.clipboard.writeText(text);
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    if (onFailure) {
      onFailure('Failed to copy to clipboard');
    }
  }
};

export const setStateModel = (stateName, model) => {
  const svc = getAngularService('andtViewsStateService');
  if (!svc) {
    return;
  }
  return svc.setStateModel(stateName, model);
};

export const gotoState = (stateName, stateParams = null) => {
  const svc = getAngularService('$state');

  if (!svc) {
    return;
  }
  svc.go(stateName, stateParams);
};

export const getToState = (stateName) => {
  const svc = getAngularService('$state');

  if (!svc) {
    return;
  }
  return svc.get(stateName);
};

export const getSearchObjectPropertyExpression = (prop, val, isExact = true) => {
  const svc = getAngularService('andtSearchAheadService');
  if (!svc) {
    return;
  }
  return svc.getSearchObjectPropertyExpression(prop, val, isExact);
};

export const getSearchObjectOriginExpression = (prop, val, isExact) => {
  const svc = getAngularService('andtSearchAheadService');
  if (!svc) {
    return;
  }
  return svc.getSearchObjectOriginExpression(prop, val, isExact);
};

export const setOriginTitlesCache = (payload) => {
  const svc = getAngularService('andtSearchAheadService');
  if (!svc) {
    return;
  }
  return svc.setOriginTitlesCache(payload);
};

export const getNewIsolatedScope = () => {
  const $rootScope = getAngularService('$rootScope');
  return $rootScope.$new(true);
};

export const compileElement = (elm, scope) => {
  const $compile = getAngularService('$compile');
  $compile(elm)(scope);
};
/* eslint-enable consistent-return */
