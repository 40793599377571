import {palette} from 'app/styles/theme';

export const colors = {
  white: palette.white[500], // '#fff',
  lightGray: palette.white[50], // '#f5f5f5',
  darkGray: palette.black[600], // '#212121',
  darkerGray: palette.black[700], // '#222222',
  oliveGreen: palette.green[475], // '#82bd36',
  skyBlue: palette.azure[400], // '#0982b6',
  pictonBlue: palette.azure[500], // '#38AEE8',
  curiousBlue: palette.azure[450], // '#2EA3DD',
  altoGray: palette.gray[250], // '#dbdbdb',
  hawkesBlue: palette.blue.hawkesBlue, // '#d7effd',
  zirconBlue: palette.gray[500], // '#F4F8FF',
  athensGray: palette.gray[200], // '#ecebf2',
  riverbedGrey: palette.gray[525], // '#415058',
  oxfordBlue: palette.gray[500], // '#3d4c59',
  dodgerBlue: palette.blue[500], // '#2671ff',
  silverGray: palette.gray[325], // '#c6c6c6',
  blue_100: palette.blue[200], // '#e9f0ff',
};

export const colorsV2 = {
  white: palette.white[500], // '#fff',
  white500: `${palette.white[500]}26`, // 'rgba(255, 255, 255, 0.15)',
  gray200: palette.gray[200], // '#e9e9f0',
  gray300: palette.gray[300], // '#d4d9e0',
  gray500: palette.gray[500], // '#3d4c59',
  indigo500: palette.indigo[500], // '#17406f',
  blue100: palette.blue[200], // '#e9f0ff',
  blue500: palette.blue[500], // '#2671ff',
  blueLight500: palette.azure[500], // '#00b7f1',
  blue500_01: palette.opaqueColors.blue500_01, // 'rgba(38, 113, 255, 0.1)',
  blue500_05: palette.opaqueColors.blue500_05, // 'rgba(38, 113, 255, 0.5)',
};

export const styling = {
  THEME_GREEN: {
    backgroundColor: {
      isSelected: {
        isFocused: colors.lightGray,
        notFocused: colors.white,
      },
      notSelected: {
        isFocused: colors.lightGray,
        notFocused: colors.white,
      },
    },
    color: {
      isSelected: {
        isFocused: colors.darkGray,
        notFocused: colors.darkGray,
      },
      notSelected: {
        isFocused: colors.darkGray,
        notFocused: colors.darkGray,
      },
    },
    buttonColor: colors.oliveGreen,
    buttonMarginColor: colors.oliveGreen,
    buttonTextColor: colors.white,
  },
  THEME_LIGHT: {
    backgroundColor: {
      isSelected: {
        isFocused: colors.lightGray,
        notFocused: colors.white,
      },
      notSelected: {
        isFocused: colors.lightGray,
        notFocused: colors.white,
      },
    },
    color: {
      isSelected: {
        isFocused: colors.skyBlue,
        notFocused: colors.skyBlue,
      },
      notSelected: {
        isFocused: colors.darkGray,
        notFocused: colors.darkGray,
      },
    },
    buttonColor: colors.white,
    buttonMarginColor: colors.altoGray,
    buttonTextColor: colors.darkGray,
  },
  THEME_BLUE: {
    backgroundColor: {
      isSelected: {
        isFocused: colors.pictonBlue,
        notFocused: colors.curiousBlue,
      },
      notSelected: {
        isFocused: colors.pictonBlue,
        notFocused: colors.curiousBlue,
      },
    },
    color: {
      isSelected: {
        isFocused: colors.white,
        notFocused: colors.white,
      },
      notSelected: {
        isFocused: colors.white,
        notFocused: colors.white,
      },
    },
    buttonColor: colors.curiousBlue,
    buttonMarginColor: colors.curiousBlue,
    buttonTextColor: colors.white,
  },
  THEME_LIGHT_BLUE: {
    backgroundColor: {
      isSelected: {
        isFocused: colors.hawkesBlue,
        notFocused: colors.hawkesBlue,
      },
      notSelected: {
        isFocused: colors.lightGray,
        notFocused: colors.white,
      },
    },
    color: {
      isSelected: {
        isFocused: colors.skyBlue,
        notFocused: colors.skyBlue,
      },
      notSelected: {
        isFocused: colors.darkGray,
        notFocused: colors.darkGray,
      },
    },
    buttonColor: colors.white,
    buttonMarginColor: colors.altoGray,
    buttonTextColor: colors.darkGray,
  },
  THEME_GREY_LIGHT_BLUE: {
    backgroundColor: {
      isSelected: {
        isFocused: colors.hawkesBlue,
        notFocused: colors.hawkesBlue,
      },
      notSelected: {
        isFocused: colors.lightGray,
        notFocused: colors.white,
      },
    },
    color: {
      isSelected: {
        isFocused: colors.skyBlue,
        notFocused: colors.skyBlue,
      },
      notSelected: {
        isFocused: colors.darkGray,
        notFocused: colors.darkGray,
      },
    },
    buttonColor: colors.athensGray,
    buttonMarginColor: colors.athensGray,
    buttonTextColor: colors.oxfordBlue,
  },
  THEME_TRANSPARENT: {
    backgroundColor: {
      isSelected: {
        isFocused: colorsV2.blue100,
        notFocused: colorsV2.blue100,
      },
      notSelected: {
        isFocused: colors.white,
        notFocused: colors.white,
      },
    },
    color: {
      isSelected: {
        isFocused: colors.skyBlue,
        notFocused: colors.skyBlue,
      },
      notSelected: {
        isFocused: colors.darkGray,
        notFocused: colors.darkGray,
      },
    },
    buttonColor: colors.athensGray,
    buttonMarginColor: colors.athensGray,
    buttonTextColor: colors.oxfordBlue,
  },
  THEME_DARK_GREY: {
    backgroundColor: {
      isSelected: {
        isFocused: colors.zirconBlue,
        notFocused: colors.white,
      },
      notSelected: {
        isFocused: colors.zirconBlue,
        notFocused: colors.white,
      },
    },
    color: {
      isSelected: {
        isFocused: colors.riverbedGrey,
        notFocused: colors.riverbedGrey,
      },
      notSelected: {
        isFocused: colors.riverbedGrey,
        notFocused: colors.riverbedGrey,
      },
    },
    buttonColor: colors.athensGray,
    buttonMarginColor: colors.athensGray,
    buttonTextColor: colors.oxfordBlue,
  },
};

export const stylingV2 = {
  THEME_HIGHLIGHTED: {
    control: {
      backgroundColor: {
        isOpen: {
          hasValue: colorsV2.blue500,
          noValue: colorsV2.gray200,
        },
        notOpen: {
          hasValue: colorsV2.blue500,
          noValue: colorsV2.gray200,
        },
      },
      borderColor: {
        isOpen: {
          hasValue: colorsV2.blue500,
          noValue: colorsV2.gray200,
        },
        notOpen: {
          hasValue: colorsV2.blue500,
          noValue: colorsV2.gray200,
        },
      },
    },
    dropdownIndicator: {
      borderColor: {
        hasValue: colorsV2.white,
        noValue: colorsV2.gray500,
      },
    },
    valueContainer: {
      color: colorsV2.white,
    },
  },
  THEME_NOT_HIGHLIGHTED: {
    control: {
      backgroundColor: {
        isOpen: {
          hasValue: colorsV2.gray300,
          noValue: colorsV2.gray300,
        },
        notOpen: {
          hasValue: colorsV2.gray300,
          noValue: colorsV2.gray200,
        },
      },
      borderColor: {
        isOpen: {
          hasValue: colorsV2.gray300,
          noValue: colorsV2.gray300,
        },
        notOpen: {
          hasValue: colorsV2.gray300,
          noValue: colorsV2.gray200,
        },
      },
    },
    dropdownIndicator: {
      borderColor: {
        hasValue: colorsV2.gray500,
        noValue: colorsV2.gray500,
      },
    },
    valueContainer: {
      color: colorsV2.indigo500,
    },
  },
  THEME_HIGHLIGHTED_TRANSPARENT: {
    control: {
      backgroundColor: {
        isOpen: {
          hasValue: 'transparent',
          noValue: 'transparent',
        },
        notOpen: {
          hasValue: 'transparent',
          noValue: 'transparent',
        },
      },
      borderColor: {
        isOpen: {
          hasValue: 'transparent',
          noValue: 'transparent',
        },
        notOpen: {
          hasValue: 'transparent',
          noValue: 'transparent',
        },
      },
    },
    dropdownIndicator: {
      borderColor: {
        hasValue: 'transparent',
        noValue: 'transparent',
      },
    },
    valueContainer: {
      color: 'transparent',
    },
  },
  THEME_LIGHT: {
    control: {
      backgroundColor: {
        isOpen: {
          hasValue: colors.white,
          noValue: colors.white,
        },
        notOpen: {
          hasValue: colors.white,
          noValue: colors.white,
        },
      },
      borderColor: {
        isOpen: {
          hasValue: colors.altoGray,
          noValue: colors.altoGray,
        },
        notOpen: {
          hasValue: colors.altoGray,
          noValue: colors.altoGray,
        },
      },
    },
    dropdownIndicator: {
      borderColor: {
        hasValue: colors.darkGray,
        noValue: colors.darkGray,
      },
    },
    valueContainer: {
      color: colors.darkGray,
    },
  },
  THEME_BLUE_LEAN: {
    control: {
      backgroundColor: {
        isOpen: {
          hasValue: colorsV2.blue500_01,
          noValue: colorsV2.blue500_01,
        },
        notOpen: {
          hasValue: colorsV2.blue500_01,
          noValue: colorsV2.blue500_01,
        },
      },
      borderColor: {
        isOpen: {
          hasValue: colorsV2.blue500_01,
          noValue: colorsV2.blue500_01,
        },
        notOpen: {
          hasValue: colorsV2.blue500_01,
          noValue: colorsV2.blue500_01,
        },
      },
    },
    dropdownIndicator: {
      borderColor: {
        hasValue: colorsV2.blue500_05,
        noValue: colorsV2.blue500_05,
      },
    },
    valueContainer: {
      color: colorsV2.gray500,
    },
  },
  THEME_BLUE_LEAN_MODIFIED: {
    control: {
      backgroundColor: {
        isOpen: {
          hasValue: colorsV2.blue500_01,
          noValue: colorsV2.blue500_01,
        },
        notOpen: {
          hasValue: colorsV2.blue500_01,
          noValue: colorsV2.blue500_01,
        },
      },
      borderColor: {
        isOpen: {
          hasValue: colorsV2.blue500_01,
          noValue: colorsV2.blue500_01,
        },
        notOpen: {
          hasValue: colorsV2.blue500_01,
          noValue: colorsV2.blue500_01,
        },
      },
    },
    dropdownIndicator: {
      borderColor: {
        hasValue: colorsV2.blue500_05,
        noValue: colorsV2.blue500_05,
      },
    },
    valueContainer: {
      color: colorsV2.gray500,
    },
  },
  THEME_DARK_LEAN: {
    control: {
      backgroundColor: {
        isOpen: {
          hasValue: colorsV2.white500,
          noValue: colorsV2.white500,
        },
        notOpen: {
          hasValue: colorsV2.white500,
          noValue: colorsV2.white500,
        },
      },
      borderColor: {
        isOpen: {
          hasValue: colorsV2.blue500_01,
          noValue: colorsV2.blue500_01,
        },
        notOpen: {
          hasValue: colorsV2.blue500_01,
          noValue: colorsV2.blue500_01,
        },
      },
    },
    dropdownIndicator: {
      borderColor: {
        hasValue: colorsV2.white500,
        noValue: colorsV2.white500,
      },
    },
    valueContainer: {
      color: colorsV2.gray500,
    },
  },
  THEME_BLUE_DARK: {
    control: {
      backgroundColor: {
        isOpen: {
          hasValue: colorsV2.white500,
          noValue: colorsV2.white500,
        },
        notOpen: {
          hasValue: colorsV2.white500,
          noValue: colorsV2.white500,
        },
      },
      borderColor: {
        isOpen: {
          hasValue: colorsV2.white500,
          noValue: colorsV2.white500,
        },
        notOpen: {
          hasValue: colorsV2.white500,
          noValue: colorsV2.white500,
        },
      },
    },
    dropdownIndicator: {
      borderColor: {
        hasValue: colorsV2.blueLight500,
        noValue: colorsV2.blueLight500,
      },
    },
    valueContainer: {
      color: colorsV2.blueLight500,
    },
  },
};

export const getColor = (theme, item, isSelected = false, isFocused = false) => {
  let ret = null;
  const select = styling[theme][item];
  switch (item) {
    case 'backgroundColor':
    case 'color':
      if (isSelected) {
        ret = isFocused ? select.isSelected.isFocused : select.isSelected.notFocused;
      } else {
        ret = isFocused ? select.notSelected.isFocused : select.notSelected.notFocused;
      }
      break;
    case 'buttonColor':
    case 'buttonMarginColor':
    case 'buttonTextColor':
      ret = select;
      break;
    default:
      ret = null;
  }
  return ret;
};
