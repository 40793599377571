// @flow
import React from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import SmartTooltip from 'common/components/SmartTooltip';
import {reduceArrayItem} from 'common/utils/reducers';
import * as selectors from 'bc/store/selectors';
import {fetchTransformFunctions, setStreamSchemaTransform} from 'bc/store/actions';
import Loader from 'common/components/Loader';
import {get, defaultsDeep, omit, cloneDeep, isEmpty, findLastIndex} from 'lodash';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {components} from 'react-select2';
import {segmentCausingEvent} from 'common/store/actions';
import style from './TransformFuncForm.module.scss';

const reducerHelper = (item, payload) => ({...item, ...payload});

type PropTypes = {
  dataStreamId: String,
  isOpen: boolean,
  col: Object,
  lookupTables: Array,
  onClose: Function,
  transformFunctions: Array,
  isEditable: boolean,
  setStreamSchemaTransform: Function,
  fetchTransformFunctions: Function,
  segmentCausingEvent: Function,
};

const EMPTY_TRANSFORM = [{}];
const BOOLEAN_ENAM = [{displayName: 'Yes', value: 'true'}, {displayName: 'No', value: 'false'}];

const convertColToList = (transform, transformFunctions) => {
  const res = [];
  const traverse = (trans) => {
    if (trans.sourceColumn) {
      return;
    }
    // eslint-disable-next-line no-param-reassign
    trans.parameters = trans.parameters.map((p) => ({value: p}));
    res.push(defaultsDeep(trans, transformFunctions.find((f) => f.name === trans.name)));
    if (trans.input.length > 0) {
      traverse(trans.input[0].transform || trans.input[0]);
    }
  };
  traverse(transform);

  let isHidden = false;
  for (let j = 0; j < res.length; j++) {
    if (!isHidden && (res[j].name === 'concat' || res[j].name === 'const')) {
      res[j].isHidden = true;
      isHidden = true;
    }
    res[j].isHidden = isHidden;
  }
  return res.reverse().map((r) => omit(r, 'input'));
};

const areTransformsSame = (transformA, transformB) => {
  if (transformA.length !== transformB.length) {
    return false;
  }
  for (let i = 0; i < transformA.length; i++) {
    if (transformA[i].name !== transformB[i].name) {
      return false;
    }
    for (let j = 0; j < transformA[i].parameters.length; j++) {
      if (transformA[i].parameters[j].value !== transformB[i].parameters[j].value) {
        return false;
      }
    }
  }
  return true;
};

const isValidRegEx = (input) => {
  let isValid = true;
  try {
    RegExp(input);
  } catch (err) {
    isValid = false;
  }
  return isValid;
};

export default connect(
  (state) => ({
    transformFunctions: selectors.getTransformFunctionsItems(state),
    lookupTables: selectors.getLookupTablesItems(state),
    dataStreamId: selectors.getSelectedDataStreamId(state),
  }),
  {
    setStreamSchemaTransform,
    fetchTransformFunctions,
    segmentCausingEvent,
  },
)(
  class TransformFuncForm extends React.PureComponent {
    props: PropTypes;

    state = {
      transforms: EMPTY_TRANSFORM,
      isValid: false,
      isSameTransform: true,
      isTransformFunctionsLoaded: false,
    };

    componentDidMount() {
      this.props.fetchTransformFunctions();
    }

    componentDidUpdate() {
      if (!this.state.isTransformFunctionsLoaded && this.props.transformFunctions.length > 0) {
        this.originalTransform = this.props.col.sourceColumn
          ? []
          : convertColToList(cloneDeep(this.props.col.transform), this.props.transformFunctions);

        const convertedCol = this.props.col.sourceColumn
          ? []
          : convertColToList(cloneDeep(this.props.col.transform), this.props.transformFunctions);
        convertedCol.push({});
        // TODO: Check what can be done instead
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          transforms: convertedCol,
          rootItemIndex: findLastIndex(convertedCol, (item) => item.isHidden === true) + 1,
          isTransformFunctionsLoaded: true,
        });
      }
      this.setIsValid();
    }

    originalTransform = [];

    handleClose = (isOk) => {
      if (isOk) {
        this.state.transforms.forEach((f) => {
          if (!isEmpty(f) && f.name && f.name !== 'replacelookuptable' && f.name !== 'filterByLookup') {
            this.props.segmentCausingEvent({
              category: `bc/data-streams/${this.props.dataStreamId}/preview`,
              name: f.name,
            });
          }
        });

        this.props.setStreamSchemaTransform({
          colId: this.props.col.id,
          transforms: this.state.transforms.filter((f) => !isEmpty(f)),
        });
        if (this.state.transforms.some((trans) => trans.name === 'replacelookuptable')) {
          this.props.segmentCausingEvent({
            segment: {category: `bc/data-streams/${this.props.dataStreamId}/preview`, name: 'Replace by Lookup Table'},
          });
        }
        if (this.state.transforms.some((trans) => trans.name === 'filterByLookup')) {
          this.props.segmentCausingEvent({
            segment: {category: `bc/data-streams/${this.props.dataStreamId}/preview`, name: 'Filter by Lookup'},
          });
        }
      }
      this.props.onClose();
    };

    setIsValid = () => {
      const isValid = !this.state.transforms.find(
        (i) =>
          i.parameters &&
          i.parameters.find((p) => {
            const isMandatory = !p.value && p.mandatory;
            const isInvalidIntegerValue = p.type === 'Integer' && p.value < (p.name === 'Lookup Column' ? 0 : 1);
            const isInvalidRegexValue = p.type === 'Regex' && !isValidRegEx(p.value);
            return isMandatory || isInvalidIntegerValue || isInvalidRegexValue;
          }),
      );
      const isSameTransform = areTransformsSame(this.state.transforms, this.originalTransform);
      if (isValid !== this.state.isValid || isSameTransform !== this.state.isSameTransform) {
        this.setState({
          isValid,
          isSameTransform,
        });
      }
    };

    funcSelected = (func, index) => {
      const t = {...func};
      t.parameters.forEach((param, i) => {
        const defVal = get(param, 'defaultValue', null);
        if (defVal !== null) {
          t.parameters[i].value = defVal;
        }
      });

      this.setState((prevState) => ({
        transforms: reduceArrayItem(reducerHelper, prevState.transforms, index, t),
      }));
    };

    paramValueChanged = (val, param, paramIndex, transform, transformIndex) => {
      let paramValue;
      if (param.type === 'Boolean') {
        paramValue = val;
      } else {
        paramValue = !val ? get(param, 'defaultValue', '') : val;
      }
      const validationMsg = param.type === 'Regex' && !isValidRegEx(val) ? 'Invalid regular Expression' : '';
      const t = {
        ...transform,
        parameters: reduceArrayItem(reducerHelper, transform.parameters, paramIndex, {
          value: paramValue,
          validationMsg,
        }),
      };

      this.setState((prevState) => ({
        transforms: reduceArrayItem(reducerHelper, prevState.transforms, transformIndex, t),
      }));
    };

    removeTransform = (index) => {
      if (index === this.state.rootItemIndex && this.state.transforms.length === index + 1) {
        this.setState((prevState) => {
          prevState.transforms.splice(index, 1);
          return {
            transforms: [...prevState.transforms, {}],
          };
        });
      } else {
        this.setState((prevState) => {
          prevState.transforms.splice(index, 1);
          return {
            transforms: [...prevState.transforms],
          };
        });
      }
    };

    addTransform = () => {
      this.setState((prevState) => ({
        transforms: [...prevState.transforms, {}],
      }));
    };

    render() {
      const {isOpen, onClose, col, transformFunctions} = this.props;
      const {isTransformFunctionsLoaded} = this.state;
      const isMeasureCol = col.type === 'metric';
      return (
        <div className={style.root}>
          <Modal show={isOpen} dialogClassName="bc overflow-override" onHide={onClose} bsSize="large">
            <Modal.Header bsClass="bc-modal-header">
              <Modal.Title>
                <span styleName={isMeasureCol ? 'property what-property' : 'property'} className="ellipsis">
                  {col.name}
                </span>
                <span styleName="action">
                  Adjust - Transform
                  {!isMeasureCol && ' and Filter'}
                </span>
              </Modal.Title>
              <button
                type="button"
                className="btn btn-flat btn-icon-36 btn-secondary"
                onClick={() => this.handleClose(false)}
              >
                <i className="icon icn-icon-table-delete" />
              </button>
            </Modal.Header>

            <Modal.Body>
              {!isTransformFunctionsLoaded && <Loader />}
              {isTransformFunctionsLoaded && (
                <div className={style.transformInner}>
                  {this.state.transforms.map((t, i) => (
                    <SingleTransform
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      index={i}
                      transform={t}
                      isMeasureCol={isMeasureCol}
                      lookupTables={this.props.lookupTables}
                      isEditable={this.props.isEditable}
                      funcSelected={this.funcSelected}
                      transformFunctions={transformFunctions}
                      removeTransform={this.removeTransform}
                      paramValueChanged={this.paramValueChanged}
                    />
                  ))}
                  <button
                    type="button"
                    className="btn btn-flat btn-icon"
                    disabled={!this.props.isEditable}
                    onClick={() => this.addTransform()}
                  >
                    <i
                      className={`icon icn-icon-white-plus ${style.plusIcon}${
                        !this.props.isEditable ? ` ${style.disabled}` : ''
                      }`}
                    />
                  </button>
                </div>
              )}
            </Modal.Body>

            <Modal.Footer className={style.footer}>
              <button
                type="button"
                className={`btn btn-raised btn-outline ${style.transformBtn}`}
                disabled={!this.props.isEditable || this.state.isSameTransform || !this.state.isValid}
                onClick={() => this.handleClose(true)}
              >
                APPLY
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  },
);

const TransformFuncDdl = (props: {
  func: Object,
  transformFunctions: Array,
  onSelect: Function,
  isMeasureCol: boolean,
}) => {
  const filteredTransFunctions = props.transformFunctions
    .filter((i) => i.type === 'Transform')
    .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
  const filteredFilterFunctions = props.transformFunctions
    .filter((i) => i.type === 'Filter')
    .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
  const filteredUnaryOperationFunctions = props.transformFunctions
    .filter((i) => i.type === 'UnaryOperation')
    .sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));

  const option = (p) => {
    if (p.data.type === 'HEADER') {
      return (
        <components.Option {...p}>
          <div className="transform-function-form-dropdown-option-header">{p.children}</div>
        </components.Option>
      );
    }
    return (
      <components.Option {...p}>
        <SmartTooltip content={p.data.description}>
          <div style={{marginLeft: '10px'}}>{p.children}</div>
        </SmartTooltip>
      </components.Option>
    );
  };

  const options = [];
  if (!props.isMeasureCol) {
    options.push({type: 'HEADER', label: 'Transforms', value: 'Transforms'});
    filteredTransFunctions.forEach((item) => {
      options.push({
        ...item,
        label: item.displayName || item.name,
        value: item.name,
        description: item.description,
      });
    });
    options.push({type: 'HEADER', label: 'Filters', value: 'Filters'});
    filteredFilterFunctions.forEach((item) => {
      options.push({
        ...item,
        label: item.displayName || item.name,
        value: item.name,
        description: item.description,
      });
    });
  } else {
    options.push({type: 'HEADER', label: 'Transforms', value: 'Transforms'});
    filteredUnaryOperationFunctions.forEach((item) => {
      options.push({
        ...item,
        label: item.displayName || item.name,
        value: item.name,
        description: item.description,
      });
    });
  }
  const selectedIndex = options.findIndex((val) => val.name === props.func);

  return (
    <div styleName="dropdown-select">
      <SelectAndt
        automationId="transformFunc"
        options={options}
        type={TYPE_NO_SEARCH}
        theme={THEME_LIGHT}
        value={selectedIndex === -1 ? null : options[selectedIndex]}
        onChange={props.onSelect}
        placeholder="Choose"
        styleName="transform-type"
        customComponent={{Option: option}}
      />
    </div>
  );
};

const Param = (props: {
  param: Object,
  lookupTables: Array,
  paramValueChanged: Function,
  isEditable: boolean,
  smallItem: boolean,
}) => {
  if (props.param.type === 'Enum') {
    return (
      <EnumParam
        param={props.param}
        paramValueChanged={props.paramValueChanged}
        isEditable={props.isEditable}
        smallItem={props.smallItem}
      />
    );
  }
  if (props.param.type === 'Boolean') {
    return (
      <BooleanParam param={props.param} paramValueChanged={props.paramValueChanged} isEditable={props.isEditable} />
    );
  }
  if (props.param.type === 'LookupTable') {
    return (
      <LookupParam
        param={props.param}
        lookupTables={props.lookupTables}
        paramValueChanged={props.paramValueChanged}
        isEditable={props.isEditable}
        smallItem={props.smallItem}
      />
    );
  }
  return (
    <InputParam
      param={props.param}
      paramValueChanged={props.paramValueChanged}
      isEditable={props.isEditable}
      smallItem={props.smallItem}
    />
  );
};

const BooleanParam = (props: {param: Object, paramValueChanged: Function, isEditable: boolean}) => {
  let value;
  if (props.param.value === undefined) {
    value = props.param.defaultValue === 'true' ? BOOLEAN_ENAM[0].displayName : BOOLEAN_ENAM[1].displayName;
  } else {
    value = props.param.value === 'true' ? BOOLEAN_ENAM[0].displayName : BOOLEAN_ENAM[1].displayName;
  }
  let selectedIndex = BOOLEAN_ENAM.findIndex((val) => val.value === value);
  if (selectedIndex === -1) {
    selectedIndex = 0;
  }

  return (
    <SmartTooltip placement="top" content={props.param.description}>
      <div styleName="dropdown-select">
        <SelectAndt
          automationId="boolParam"
          options={BOOLEAN_ENAM}
          type={TYPE_NO_SEARCH}
          theme={THEME_LIGHT}
          value={BOOLEAN_ENAM[selectedIndex]}
          disabled={!props.isEditable}
          onChange={(val) => props.paramValueChanged(val.value)}
          placeholder={props.param.name}
          getOptionLabel={(val) => val.displayName}
        />
      </div>
    </SmartTooltip>
  );
};

const EnumParam = (props: {param: Object, paramValueChanged: Function, isEditable: boolean, smallItem: boolean}) => {
  const selectedIndex = props.param.values.findIndex(
    (val) => val.value === (props.param.value || props.param.defaultValue),
  );
  return (
    <SmartTooltip placement="top" content={props.param.description}>
      <div styleName={props.smallItem ? 'small-width' : 'dropdown-select'}>
        <SelectAndt
          automationId="enumParam"
          options={props.param.values}
          type={TYPE_NO_SEARCH}
          theme={THEME_LIGHT}
          value={props.param.values[selectedIndex]}
          disabled={!props.isEditable}
          onChange={(val) => props.paramValueChanged(val.value)}
          placeholder={props.param.name}
          getOptionLabel={(val) => val.displayName}
        />
      </div>
    </SmartTooltip>
  );
};

const LookupParam = (props: {
  param: Object,
  lookupTables: Array,
  paramValueChanged: Function,
  isEditable: boolean,
  smallItem: boolean,
}) => {
  let selectedIndex = props.lookupTables.findIndex((val) => val.id === props.lookupTables.id);
  if (selectedIndex === -1) {
    selectedIndex = props.lookupTables.findIndex((val) => val.id === props.param.value);
  }
  return (
    <SmartTooltip placement="top" content={props.param.description}>
      <div styleName={props.smallItem ? 'small-width' : 'dropdown-select'}>
        <SelectAndt
          automationId="lookupParam"
          options={props.lookupTables}
          type={TYPE_NO_SEARCH}
          theme={THEME_LIGHT}
          value={props.lookupTables[selectedIndex]}
          disabled={!props.isEditable}
          onChange={(val) => props.paramValueChanged(val.id)}
          placeholder={props.param.name}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.id}
        />
      </div>
    </SmartTooltip>
  );
};

const InputParam = (props: {param: Object, paramValueChanged: Function, isEditable: boolean, smallItem: boolean}) => {
  let type = 'hidden';
  let minValue = '0';
  if (!props.param.hidden) {
    type = ['String', 'Regex'].includes(props.param.type) ? 'text' : 'number';
    minValue = type === 'number' && props.param.type !== 'Integer' ? '' : '0';
  }
  const className = props.param.validationMsg
    ? `${style.transformInput} ${style.transformInputInvalid}`
    : style.transformInput;

  return (
    <SmartTooltip placement="top" content={props.param.validationMsg || props.param.description}>
      <input
        type={type}
        styleName={props.smallItem ? 'small-width' : ''}
        className={className}
        disabled={!props.isEditable}
        min={minValue}
        required={props.param.mandatory}
        placeholder={props.param.name}
        onChange={(e) => props.paramValueChanged(e.target.value)}
        value={props.param.value || props.param.defaultValue || ''}
      />
    </SmartTooltip>
  );
};

const SingleTransform = (props: {
  index: number,
  lookupTables: Array,
  transform: Object,
  transformFunctions: Array,
  isEditable: boolean,
  isMeasureCol: boolean,
  funcSelected: Function,
  removeTransform: Function,
  paramValueChanged: Function,
}) => {
  const hidden = props.transform.isHidden ? ` ${style.hidden}` : '';
  const params = get(props.transform, 'parameters', []);
  const paramsCount = params?.filter((par) => par.hidden === false) || 0;
  return (
    <div className={style.transformRow + hidden}>
      <TransformFuncDdl
        func={props.transform.name}
        isMeasureCol={props.isMeasureCol}
        onSelect={(evKey) => props.funcSelected(evKey, props.index)}
        transformFunctions={props.transformFunctions}
      />
      {params.map((p, i) => (
        <Param
          // eslint-disable-next-line react/no-array-index-key
          key={i + p.name + props.index + props.transform.name}
          param={p}
          smallItem={paramsCount.length >= 3}
          lookupTables={props.lookupTables}
          isEditable={props.isEditable}
          paramValueChanged={(v) => props.paramValueChanged(v, p, i, props.transform, props.index)}
        />
      ))}
      {get(props.transform, 'name', false) && (
        <button
          type="button"
          className={`btn btn-flat btn-icon ${style.removeTransformRowButton}`}
          disabled={!props.isEditable}
          onClick={() => props.removeTransform(props.index)}
        >
          <i className={`icon icn-icon-white-close ${style.xIcon}${!props.isEditable ? ` ${style.disabled}` : ''}`} />
        </button>
      )}
    </div>
  );
};
