// @flow
import React, {useEffect, useMemo, useRef, useState} from 'react';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {get} from 'lodash';
import EmptySearch from 'common/componentsV2/EmptySearch';
import {useMultipleFinalFormFields} from 'common/hooks/useMultipleFinalFormFields';
import {useFetchMetricAnomalies} from 'dashboards/api/useFetchMetricAnomalies';
import {palette} from 'app/styles/theme';
import {getMeasureNameAndExpression} from './helpers';
import SingleChartContainer from './SingleChartContainer';

export const presavedParamsPath = 'tileConfig.displayProperties.byTreeExp[0].options.anomaliesList.mainValue';
const centeredStyles = {
  display: 'flex',
  width: '100%',
  height: '50vh',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};

const wrapperStyle = {
  overflow: 'auto',
  height: '100%',
  flexGrow: 1,
  maxWidth: '100%',
  width: '100%',
  backgroundColor: palette.opaqueColors.white5,
};

type PropTypes = {
  dateRange: Object,
  resizeTrigger: string,
  width: string,
  tileData: Object,
  onGetData: Function,
  localSorting: string,
};
const AnomalyChartsContainer = ({
  dateRange,
  resizeTrigger = 'default',
  width = 400,
  onGetData,
  tileData,
  localSorting,
}: PropTypes) => {
  const ref = useRef(null);
  const [tileSize, setTileSize] = useState([width, 100]);
  const presavedParams = get(tileData, presavedParamsPath);

  useEffect(() => {
    if (ref.current) {
      setTileSize([ref.current.offsetWidth, ref.current.offsetHeight]);
    }
  }, [resizeTrigger]);

  const currentForm = useMultipleFinalFormFields(
    [
      'sortAnomalies',
      'timeScale',
      'anomaliesAmount',
      'score',
      'durationUnits',
      'durationValue',
      'deltaValue',
      'deltaType',
      'openOnly',
      'showEvents',
      'directionUp',
      'directionDown',
      'simpleExpressionTree',
    ],
    undefined,
    (f) => f.input.value,
  );

  const {
    timeScale,
    openOnly,
    directionUp,
    directionDown,
    sortAnomalies,
    anomaliesAmount,
    simpleExpressionTree = {},
    ...fields
  } = {...currentForm, ...presavedParams};

  const {expression} = getMeasureNameAndExpression(simpleExpressionTree);
  const filteredExpression = expression // For Anomalies -send just "*" instead "* OR option1 OR option2"
    ?.filter((d) => d.value)
    .map((d) => {
      if (d.value.startsWith('*')) {
        return {...d, value: '*'};
      }
      return d;
    });
  const anomalyParams = {
    startDate: Math.floor(dateRange.startDate / 1000),
    endDate: Math.floor(dateRange.endDate / 1000),
    index: 0,
    size: anomaliesAmount ?? 5,
    score: Number(fields.score ?? 70) / 100,
    durationUnit: fields.durationUnit || 'minutes',
    durationValue: fields.durationValue ?? 5,
    resolution: timeScale || 'long',
    anomalyType: 'all',
    bookmark: '',
    correlation: '',
    delta: String(fields.deltaValue),
    deltaType: fields.deltaType || 'absolute',
    order: 'desc',
    q: JSON.stringify({expression: filteredExpression || ''}),
    sort: sortAnomalies || 'delta',
    startBucketMode: true,
    state: openOnly ? 'open' : 'both',
    // eslint-disable-next-line no-nested-ternary
    valueDirection: directionUp === directionDown ? 'both' : directionUp ? 'up' : 'down',
  };
  const {anomalies, isAnomaliesLoading} = useFetchMetricAnomalies(anomalyParams);

  const sortedAnomalies = useMemo(() => {
    if (localSorting && anomalies?.[0] && localSorting in anomalies[0]) {
      const des = localSorting === 'score' ? -1 : 1;
      return [...anomalies].sort((a, b) => (a[localSorting] - b[localSorting]) * des);
    }
    return anomalies;
  }, [localSorting, anomalies]);

  useEffect(() => {
    if (onGetData && !isAnomaliesLoading && anomalies) {
      onGetData([anomalies?.length || 0, anomalies?.[0]?.totalAnomalies || 0, Object.keys(anomalies?.[0] || {})]);
    }
  }, [anomalies, isAnomaliesLoading, onGetData]);

  return (
    <div style={wrapperStyle} ref={ref}>
      {isAnomaliesLoading && (
        <div style={centeredStyles}>
          <Spinner color="gray.500" size={SIZES.MEDIUM_50} />
        </div>
      )}
      {!isAnomaliesLoading &&
        (anomalies?.length ? (
          <div
            style={{
              maxWidth: tileSize[0],
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: palette.blue[50],
            }}
          >
            {sortedAnomalies.map((anomaly) => (
              <SingleChartContainer
                key={anomaly.id}
                anomaly={anomaly}
                width={tileSize[0]}
                timeInterval={dateRange}
                height={240}
              />
            ))}
          </div>
        ) : (
          <EmptySearch errorExplanation="No Anomalies for these filters" />
        ))}
    </div>
  );
};

export default AnomalyChartsContainer;
