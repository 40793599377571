// @flow
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import * as dateRangeService from 'common/utils/dateRangeService';
import {has, isEmpty} from 'lodash';
import {getTimeFrameV2Enabled, getPredefinedDateUserSetting, getTimeZoneName} from 'profile/store/selectors';
import CustomDateRangeModal from 'common/componentsV2/customDateRange/CustomDateRangeModal';
import CustomDateRangeModalV2 from 'common/componentsV2/customDateRangeV2/CustomDateRangeModal';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';

import {updatePredefinedDatesUserSettings} from 'common/components/dateTime/store/actions';
import {StringParam, useQueryParam} from 'use-query-params';
import {getTimeInTimeZone, getTimeInBrowserTime} from 'common/utils/dateService';

type PropTypes = {
  onChange: Function,
  dateRange: Object,
  isUnix: boolean,
  timeZoneName: String,
  analytics?: Object,
  theme?: String,
  menuWidth?: Number,
  // eslint-disable-next-line react/no-unused-prop-types
  buttonWidth?: Number,
  footerComponent?: Node,
  position?: String,
  allowedRangeTypes?: Array,
  allowedCustomDates?: boolean,
};

export const THEME = {
  GRAY: 'gray',
  GRAY_200: 'gray_200',
  BLUE_LEAN: 'blueLean',
  HIGHLIGHTED: 'highlighted',
};

const CUSTOM_DATES_HEADER = {
  label: 'Custom Dates',
  text: 'Custom Dates',
  value: 'Custom Dates',
  type: 'HEADER',
};

const useStyles = makeStyles(({palette}) => ({
  gray: {
    height: 35,
    padding: '0px 12px 0 12px',
    color: palette.indigo[500],
    backgroundColor: palette.gray[300],
  },
  blueLean: {
    height: 20,
    paddingLeft: 5,
    color: palette.gray[500],
    minWidth: 40,
    backgroundColor: `${palette.blue[500]}1a`,
    '& $triangle': {
      color: `${palette.blue[500]}80`,
    },
  },
  highlighted: {
    height: 38,
    backgroundColor: palette.blue[500],
    color: palette.white[500],
    '& $triangle': {
      color: palette.white[500],
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    padding: '0 12px 0 12px',
    borderRadius: 6,
    width: ({buttonWidth}) => buttonWidth || undefined,
  },
  title: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    flexGrow: 1,
  },
  triangle: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    height: '100%',
    color: palette.gray[500],
    fontSize: 14,
  },
}));

const preDefinedDatesDefault = dateRangeService.predefinedOptions
  .filter((d) => dateRangeService.DEFAULT_RANGE_OPTIONS.includes(d.key))
  .map((o) => o.key);
const defaultOptions = preDefinedDatesDefault.map((o) => dateRangeService.rangeTypes[o]);
const defaultRangePublicOptions = dateRangeService.DEFAULT_SHARE_PUBLIC_RANGE_OPTIONS.map(
  (o) => dateRangeService.rangeTypes[o],
);

const createCustomOption = ({startDate, endDate, relativeLast, relativeNext, constRange}) => {
  const isRange = constRange === dateRangeService.rangeTypes.c.value;
  return {
    value: constRange,
    startDate: isRange ? startDate : undefined,
    endDate: isRange ? endDate : undefined,
    relativeLast: !isRange ? relativeLast : undefined,
    relativeNext: !isRange ? relativeNext : undefined,
    text: isRange
      ? dateRangeService.createCustomLabel(startDate, endDate)
      : dateRangeService.createRelativeLabel(relativeLast, relativeNext),
  };
};

const isPresetOption = (option, dateRange) => {
  const {correctTimestampUnix} = dateRangeService;
  if (
    dateRange.constRange === dateRangeService.rangeTypes.c.value &&
    correctTimestampUnix(option.startDate) === correctTimestampUnix(dateRange.startDate) &&
    correctTimestampUnix(option.endDate) === correctTimestampUnix(dateRange.endDate)
  ) {
    return true;
  }

  if (
    dateRange.constRange === dateRangeService.rangeTypes.r.value &&
    String(option.relativeLast) === String(dateRange.relativeLast) &&
    (!dateRange.relativeNext || !option.relativeNext || String(option.relativeNext) === String(dateRange.relativeNext))
  ) {
    return true;
  }
  return false;
};

const DateRangesDdl = (props: PropTypes) => {
  let isInit = true;
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const predefinedDateUserSetting = useSelector(getPredefinedDateUserSetting);
  const timeZoneName = useSelector(getTimeZoneName);
  const isTimeFrameV2 = useSelector(getTimeFrameV2Enabled);
  const [invitationId] = useQueryParam('invitationId', StringParam);
  const {dateRange, isUnix, allowedRangeTypes, allowedCustomDates} = props;
  const customKey = dateRangeService.rangeTypes.c.value;
  const relativeKey = dateRangeService.rangeTypes.r.value;

  // eslint-disable-next-line complexity
  const options = useMemo(() => {
    if (!isEmpty(predefinedDateUserSetting)) {
      const customDates = predefinedDateUserSetting.customDates || [];
      const preDefinedDates = allowedRangeTypes || predefinedDateUserSetting.preDefinedDates || preDefinedDatesDefault;
      const {constRange} = dateRange;
      const customDatesOptions = customDates.filter((c) => c.isSelected);
      const isExistInPreset =
        // eslint-disable-next-line max-len
        (constRange === customKey || constRange === relativeKey) &&
        customDates.some((c) => isPresetOption(c, dateRange));
      const isCreateCustomOption = !isInit && !isExistInPreset;
      const optionsUserSettings = Object.values(dateRangeService.rangeTypes).filter((o) =>
        preDefinedDates.includes(o.key),
      );
      const isExistCustom = preDefinedDates.includes(dateRangeService.rangeTypes.custom.value);

      const predefinedOption =
        isInit &&
        Object.values(dateRangeService.rangeTypes).find(
          (r) => r.value === constRange && constRange !== customKey && constRange !== relativeKey,
        );
      // eslint-disable-next-line max-len
      const isPredefinedOptionHide =
        predefinedOption && !optionsUserSettings.some((o) => o.key === predefinedOption.key);

      const isQueryCustom =
        isInit &&
        (constRange === customKey || constRange === relativeKey) &&
        !customDates.some((c) => isPresetOption(c, dateRange));

      const newState = [].concat(
        isPredefinedOptionHide ? predefinedOption : [],
        isQueryCustom || isCreateCustomOption ? createCustomOption(dateRange) : [],
        optionsUserSettings.length ? optionsUserSettings : defaultOptions,
        !isExistCustom && !invitationId && !allowedRangeTypes ? dateRangeService.rangeTypes.custom : [],
        customDatesOptions.length && allowedCustomDates ? CUSTOM_DATES_HEADER : [],
        customDatesOptions.length && allowedCustomDates ? customDatesOptions : [],
      );
      isInit = false;
      return newState;
    }
    // eslint-disable-next-line max-len
    return invitationId
      ? defaultOptions
          .filter((o) => o.value !== dateRangeService.rangeTypes.custom.value)
          .concat(defaultRangePublicOptions)
      : defaultOptions;
  }, [predefinedDateUserSetting, dateRange]);

  useEffect(() => {
    if (isEmpty(predefinedDateUserSetting)) {
      dispatch(updatePredefinedDatesUserSettings({predefinedDates: preDefinedDatesDefault, customDates: []}));
    }
  }, []);

  const closeModal = useCallback(
    (range) => {
      setIsModalOpen(false);
      if (has(range, 'startDate')) {
        props.onChange(dateRangeService.getDateValue(range, isUnix, props.timeZoneName));
      }
    },
    [isUnix, props.timeZoneName],
  );

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onMenuItemClick = useCallback(
    (item) => {
      if (item.key === dateRangeService.rangeTypes.custom.value) {
        openModal();
      } else {
        props.onChange(dateRangeService.getDateValue(item, isUnix, props.timeZoneName));
      }
    },
    [isUnix, props.timeZoneName],
  );

  const labelButton = (selectedItem = {}) => {
    const {relativeLast, relativeNext, startDate, endDate} = dateRange;
    if (selectedItem.value === dateRangeService.rangeTypes.r.value) {
      return dateRangeService.createRelativeLabel(relativeLast, relativeNext);
    }
    if (selectedItem.value === dateRangeService.rangeTypes.c.value) {
      return dateRangeService.createCustomLabel(startDate, endDate);
    }

    return selectedItem.text;
  };

  const selectedOption = options.find((v) => {
    if (isPresetOption(v, dateRange)) {
      return true;
    }

    if (
      (dateRange.constRange === v.value || dateRange.constRange === v.key) &&
      ![customKey, relativeKey].includes(dateRange.constRange)
    ) {
      return true;
    }
    return false;
  });

  const setDateByTimeZone = (dateRangeValue) => {
    const newDateRange = {
      ...dateRangeValue,
      startDate: getTimeInTimeZone(dateRangeValue.startDate, timeZoneName),
      endDate: getTimeInTimeZone(dateRangeValue.endDate, timeZoneName),
    };

    props.onChange(newDateRange);
  };

  const getDateByTimeZone = (dateRangeValue) => {
    const newDateRange = {
      ...dateRangeValue,
      startDate: getTimeInBrowserTime(dateRangeValue.startDate, timeZoneName),
      endDate: getTimeInBrowserTime(dateRangeValue.endDate, timeZoneName),
    };

    return newDateRange;
  };

  const buttonComponent = (
    <Box className={`${classes[props.theme]} ${classes.button}`}>
      <Box className={classes.title}>{labelButton(selectedOption)}</Box>
      <i className={`icon icn-arrow16-triangledown ${classes.triangle}`} />
    </Box>
  );

  return (
    <div
      role="button"
      onClick={(event) => event.preventDefault()}
      className="analytics"
      data-andt-analytics-category={props.analytics ? props.analytics.category : null}
      data-andt-analytics-label={props.analytics ? props.analytics.label : null}
    >
      <FormDdlSelect
        options={options}
        button={buttonComponent}
        selected={selectedOption}
        optionComponent={<OptionComponentSimple />}
        onChange={(item) => onMenuItemClick(item)}
        footerComponent={props.footerComponent}
        height={240}
        width={props.menuWidth}
        maxWidth={props.menuWidth}
        position={props.position}
        id="dateRangesPicker"
        automationId="dateRangesPicker"
        isDisabledSorting
      />
      {isTimeFrameV2 ? (
        <CustomDateRangeModalV2
          onClose={closeModal}
          isOpen={isModalOpen}
          dateRange={getDateByTimeZone(dateRange)}
          onSelect={(value) => setDateByTimeZone(value.dateRange)}
          timeZoneName={props.timeZoneName || timeZoneName}
          isUnix={props.isUnix}
        />
      ) : (
        <CustomDateRangeModal
          onClose={closeModal}
          isOpen={isModalOpen}
          dateRange={getDateByTimeZone(dateRange)}
          onSelect={(value) => setDateByTimeZone(value.dateRange)}
          timeZoneName={props.timeZoneName || timeZoneName}
          isUnix={props.isUnix}
        />
      )}
    </div>
  );
};

DateRangesDdl.defaultProps = {
  analytics: null,
  theme: THEME.GRAY,
  menuWidth: 250,
  buttonWidth: null,
  footerComponent: undefined,
  position: 'left',
  allowedRangeTypes: undefined,
  allowedCustomDates: true,
};

export default React.memo(DateRangesDdl);
