import React from 'react';
import PropTypes from 'prop-types';
import {calculateTime, getDelayText} from 'composites/services/compositesService';
import OriginSourceList from './OriginSourceList';
import useFetchAlertTrigger from '../api/useFetchAlertTriggers';

import './CompositeExpandablePanel.module.scss';

const AlertCompositeExpandablePanel = React.memo(({item, compositeName, compositeDelay}: PropTypes) => {
  const thirtyDaysTimestamp = Math.floor(Date.now() / 1000) - 60 * 60 * 24 * 30;
  const queryParams = {
    startTime: thirtyDaysTimestamp,
    alertConfigurationIds: item?.composite?.id,
    startBucketMode: true,
  };

  const {alertTrigger} = useFetchAlertTrigger(queryParams);

  const timeScale = calculateTime(
    item?.composite?.calculationDelay ? item?.composite?.calculationDelay : compositeDelay,
  );

  const delayNumber = (delay, scale) => {
    return (
      <div styleName="value">
        {item?.composite?.calculationDelay
          ? getDelayText('Manual', item?.composite?.calculationDelay, scale)
          : getDelayText('Auto', delay, scale)}
      </div>
    );
  };

  const detailValue = (title, value) => {
    return (
      <div className="mr_4">
        <div styleName="title">{title}</div>
        <div styleName="value" className="pb_0">
          {value || 0}
        </div>
      </div>
    );
  };
  return (
    <div className="display_flex">
      {detailValue('Input Metrics', compositeName?.validation?.totalMetricsInput)}
      {detailValue('Output Metrics', item?.metricsUsageCount)}
      {detailValue('Triggered', alertTrigger?.total)}
      <div className="mr_4">
        <div styleName="title">Stream Source</div>
        <div styleName="value" className="pb_0">
          <OriginSourceList composite={item} />
        </div>
      </div>
      {detailValue('Composite Delay', delayNumber(compositeDelay, timeScale))}
    </div>
  );
});

AlertCompositeExpandablePanel.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  compositeDelay: PropTypes.number.isRequired,
};

export default AlertCompositeExpandablePanel;
