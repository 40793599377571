// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setEditableTopCubeKeyVal} from 'bc.cubes/store/actions';
import * as selectors from '../store/selectors';
import './CubeName.module.scss';

const MIN_LENGTH = 2;

type PropTypes = {
  editableTopCube: Object,
  setEditableTopCubeKeyVal: Function,
};

export default connect(
  (state) => ({
    editableTopCube: selectors.getEditableTopCube(state),
  }),
  {
    setEditableTopCubeKeyVal,
  },
)(
  class CubeName extends React.PureComponent {
    props: PropTypes;

    state = {
      name: this.props.editableTopCube.name || '',
    };

    render() {
      return (
        <div styleName="root">
          <input
            type="text"
            styleName="name-input"
            minLength={MIN_LENGTH}
            value={this.state.name}
            onChange={(e) => this.setState({name: e.target.value})}
            onBlur={(e) =>
              this.state.name && this.state.name !== this.props.editableTopCube.name
                ? this.props.setEditableTopCubeKeyVal({name: e.target.value})
                : this.setState({name: this.props.editableTopCube.name})
            }
            placeholder="Set a name for the cube"
          />
        </div>
      );
    }
  },
);
