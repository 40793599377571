// flow
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {Transition} from 'react-transition-group';
import {getNumLastDays} from 'insightsPanelNewAlertConsole/store/selectors';
import {segmentClickEvent} from 'common/store/actions';
import PieChart from 'charts/pieChart/PieChart';
import {fadeOpacity, formatAlertsDistribution} from 'insightsPanelNewAlertConsole/services/constants';
import {ALERTS_SEVERITY, ALERTS_TYPE} from 'charts/pieChart/pieChartSettingsService';
import {getConstRangeByNumberOfLastDays} from 'insightsPanelNewAlertConsole/api/utils';
import fetchTriggeredAlertsCountByProvider from 'insightsPanelNewAlertConsole/api/fetchTriggeredAlertsCountByProvider';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS} from 'alerts.console.new/services/alertsConsoleService';

import './AlertsDistribution.module.scss';

const AlertsDistribution = ({title, onFilterAlerts}: {title: String, onFilterAlerts: Function}) => {
  const [showData, setShowData] = useState(false);
  const numLastDays = useSelector(getNumLastDays);
  const dispatch = useDispatch();

  const constRange = useMemo(() => {
    return getConstRangeByNumberOfLastDays(numLastDays?.value)?.constRange;
  }, [numLastDays?.value]);

  const alertsDistributionProvider = fetchTriggeredAlertsCountByProvider({constRange})?.useQuery();
  const alertsDistributionResult = alertsDistributionProvider?.data;
  const isLoading = alertsDistributionProvider?.isLoading;

  const alertsDistributionData = useMemo(() => {
    let alertsDistribution;
    if (!isEmpty(alertsDistributionResult?.resultByAlertType) && !isEmpty(alertsDistributionResult?.resultBySeverity)) {
      alertsDistribution = formatAlertsDistribution(
        alertsDistributionResult.resultByAlertType,
        alertsDistributionResult.resultBySeverity,
      );
    }
    return alertsDistribution;
  }, [alertsDistributionResult?.resultByAlertType, alertsDistributionResult?.resultBySeverity]);

  useEffect(() => {
    if (alertsDistributionResult) {
      setShowData(true);
    }
  }, [alertsDistributionResult]);

  const segmentTracking = (name) => {
    dispatch(
      segmentClickEvent({
        category: 'Insights Panel',
        name,
      }),
    );
  };

  const clickOnType = (event) => {
    const type = event.point.id;
    onFilterAlerts({[ALERT_CONSOLE_QUERY_PARAMS_KEYS.TYPES]: type});
    segmentTracking('Alert Distribution Widget - Filter by Type');
  };

  const clickOnSeverity = (event) => {
    const severity = event.point.id;
    onFilterAlerts({[ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITIES]: severity});
    segmentTracking('Alert Distribution Widget - Filter by Type');
  };

  const tooltipFormatter = {
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      return `<div class="pie-chart-tooltip">${Math.round(this.percentage)}%${' '}(${this.y})${' - '}${this.key}</div>`;
    },
  };

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && alertsDistributionResult && (
        <Transition in={showData} timeout={fadeOpacity.duration}>
          {(state) => (
            <>
              <header
                styleName="header"
                style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state], marginBottom: '3px'}}
              >
                {title}
              </header>
              <div styleName="main-container">
                <div styleName="chart-wrapper">
                  <PieChart
                    onClickEvent={clickOnType}
                    id="insightsPanelAlertsDistributionByType"
                    height={77}
                    width={77}
                    data={alertsDistributionData?.byType}
                    theme={ALERTS_TYPE}
                    tooltipFormatter={tooltipFormatter}
                  />
                  <span styleName="text">By Alert Type</span>
                </div>
                <div styleName="chart-wrapper">
                  <PieChart
                    onClickEvent={clickOnSeverity}
                    id="insightsPanelAlertsDistributionBySeverity"
                    height={77}
                    width={77}
                    data={alertsDistributionData?.bySeverity}
                    theme={ALERTS_SEVERITY}
                    tooltipFormatter={tooltipFormatter}
                  />
                  <span styleName="text">By Alert Severity</span>
                </div>
              </div>
            </>
          )}
        </Transition>
      )}
    </div>
  );
};

export default AlertsDistribution;
