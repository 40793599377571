import {useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';

const alertActionsRoot = 'api/v1/alert-actions/';

const fetchAllAlertActions = () => {
  return get(`${alertActionsRoot}all`);
};

export default function fetchAlertActionsProvider() {
  const queryClient = useQueryClient();

  const queryKey = [QUERY_KEYS.alertActions];
  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchQuery: () => queryClient.fetchQuery(queryKey, fetchAllAlertActions()),
    useQuery: () =>
      useQuery(queryKey, () => fetchAllAlertActions(), {
        retry: false,
      }),
  };
}
