import {useCallback} from 'react';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';

export default function useFieldsToTriggerByStep(index, level = 'step') {
  const fieldToTrigger = useCallback(() => {
    return Object.keys(EVENT_STREAM_FIELDS)
      .filter((key) => EVENT_STREAM_FIELDS[key][level] === index)
      .map((key) => EVENT_STREAM_FIELDS[key].id);
  }, [index]);

  return fieldToTrigger();
}
