// @flow
import React from 'react';
import {connect} from 'react-redux';
import {doGaAuthFlow} from 'bc/services/gaService';
import {doGa4AuthFlow} from 'bc/services/ga4Service';
import {doGadsAuthFlow} from 'bc/services/gadsService';
import {doGsearchAuthFlow} from 'bc/services/gsearchService';
import {doGsAuthFlow} from 'bc/services/gsService';
import {doBqAuthFlow} from 'bc/services/bqService';
import {bcTypes} from 'bc/services/bcTypes';
import {uploadFile} from 'bc/services/fileUploadToAwsService';
import * as selectors from 'bc/store/selectors';
import {
  createDataSource as createDataSourceAction,
  resetAddSourceError as resetAddSourceErrorAction,
} from 'bc/store/actions';
import DataSourceModal from '../../DataSourceModal';
import '../../DataSourceModal.module.scss';

type PropTypes = {
  isOpen: Boolean,
  sourceType: Object,
  source: Object,
  onClose: Function,
  me: Object,

  // connect
  isLoading: boolean,
  sourceError: Object,

  createDataSource: Function,
  resetAddSourceError: Function,
};

export default connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource: createDataSourceAction,
    resetAddSourceError: resetAddSourceErrorAction,
  },
)(
  class GoogleSourceModal extends React.PureComponent {
    props: PropTypes;

    state = {
      file: null,
      isFileUploading: false,
    };

    onCloseInternal = () => {
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    createBtnClicked = () => {
      const {sourceType} = this.props;

      if (this.state.file) {
        this.setState({
          isFileUploading: true,
        });

        uploadFile(
          this.state.file,
          sourceType.serviceAccountBucket,
          sourceType.serviceAccountPath,
          this.onSuccess,
          this.onFailure,
        );
      }
    };

    onSuccess = (res) => {
      const {sourceType, createDataSource} = this.props;
      const sourceNamePrefix = sourceType.name;

      const [fileName, folderName] = res.s3url.split('/').reverse();
      const fileNameDecoded = decodeURIComponent(fileName);
      const folderNameDecoded = decodeURIComponent(folderName);

      this.setState({
        isFileUploading: false,
      });

      createDataSource({
        type: sourceType.type,
        name: `${sourceNamePrefix} Data Source ${new Date().getTime().toString()}`,
        authenticationType: 'service_account',
        serviceAccountFileName: `${folderNameDecoded}/${fileNameDecoded}`,
      });
    };

    onFailure = () => {
      this.setState({
        isFileUploading: false,
      });
    };

    onFileChange = (e) => {
      if (e.target.files.length) {
        this.setState({
          file: e.target.files[0],
        });
      } else {
        this.setState({
          file: null,
        });
      }
    };

    gaAuthFlow = () => {
      const {sourceType, createDataSource, me} = this.props;

      if (sourceType.type === bcTypes.google_analytics.type) {
        doGaAuthFlow(sourceType.clientId, createDataSource, me);
      } else if (sourceType.type === bcTypes.google_analytics_ga4.type) {
        doGa4AuthFlow(sourceType.clientId, createDataSource, me);
      } else if (sourceType.type === bcTypes.google_ads.type) {
        doGadsAuthFlow(sourceType.clientId, createDataSource, me);
      } else if (sourceType.type === bcTypes.google_search.type) {
        doGsearchAuthFlow(sourceType.clientId, createDataSource, me);
      } else if (sourceType.type === bcTypes.bigquery.type) {
        doBqAuthFlow(sourceType.clientId, createDataSource, me);
      } else {
        doGsAuthFlow(sourceType.clientId, createDataSource, me);
      }
      this.props.onClose();
    };

    clicked = () => {
      document.getElementById('serviceFileInput').click();
      return false;
    };

    getExtraInfoText = () => {
      const {sourceType} = this.props;
      switch (sourceType.type) {
        case bcTypes.google_analytics.type:
          return '* The service or user account you choose should have credentials to Read and Analyze GA data,';
        case bcTypes.google_ads.type:
          return '* The service or user account you choose should have credentials to Read and Analyze Google Ads data,';
        case bcTypes.bigquery.type:
          /* eslint-disable */
          return '* The service or user  account you choose should have credentials to query the database in a periodic manner,';
        /* eslint-enable */
        default:
          return '';
      }
    };

    render() {
      const {isOpen, isLoading, sourceError, source, sourceType} = this.props;
      const {file, isFileUploading} = this.state;

      return (
        <DataSourceModal
          isOpen={isOpen}
          isContinueDisabled={!file}
          isProcessing={isLoading || isFileUploading}
          source={source}
          sourceType={sourceType}
          logoImageClass="source-logo-s3"
          onClose={this.onCloseInternal}
          onContinue={this.createBtnClicked}
        >
          <div styleName="inputs-wrapper">
            <div styleName="header">
              <span>Use Service account</span>
            </div>

            <form styleName="server-account-form">
              <input
                type="text"
                readOnly
                disabled
                styleName="file-name"
                placeholder="Credentials filename"
                value={file ? file.name : ''}
              />
              <button
                type="button"
                className="btn btn-raised btn-outline"
                styleName="browse-btn"
                onClick={this.clicked}
              >
                Browse
              </button>
              <input styleName="file-input" id="serviceFileInput" type="file" onChange={(e) => this.onFileChange(e)} />
            </form>

            <div styleName="seperator">
              <div styleName="line" />
              <span>OR</span>
              <div styleName="line" />
            </div>

            <button type="button" className="btn" styleName="ga-auth-btn" onClick={this.gaAuthFlow}>
              <div styleName="google-logo">
                <img
                  alt="Google's logo"
                  src="../../../../../../../../../src/modules/andt.app/style/images/logo_googleg_48dp.png"
                />
              </div>
              <div styleName="google-sign-in">Sign in with Google</div>
            </button>

            {!isLoading && sourceError ? (
              <div styleName="error-wrapper">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" /> {sourceError.uMessage}
                </span>
              </div>
            ) : null}

            <div styleName="extra-info">
              <span>{this.getExtraInfoText()}</span>
              <a
                href="https://support.anodot.com/hc/en-us/articles/360009413613"
                rel="noopener noreferrer"
                target="_blank"
              >
                learn more.
              </a>
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
