// @flow
import React, {useMemo} from 'react';
import {useQueryParams} from 'use-query-params';
import {omitBy} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import FilterButton from 'common/componentsV2/FilterButton';
import {
  EMPTY_OBJECT,
  RECOMMENDATIONS_QUERY_PARAMS,
  RECOMMENDATIONS_QUERY_PARAMS_CONFIG,
} from 'recommendations/services/consts';
import {updateFilterBtn as updateFilterBtnAction} from 'recommendations/store/actions';
import {getIsFiltersBarOpen} from 'recommendations/store/selectors';

import './Recommendations.module.scss';

const RecommendationsHeader = ({count}: {count: String}) => {
  const dispatch = useDispatch();
  const isFilterOpen = useSelector(getIsFiltersBarOpen);
  const [queryParams] = useQueryParams(RECOMMENDATIONS_QUERY_PARAMS_CONFIG);

  const tooltipItems = useMemo(() => {
    const obj = omitBy(
      queryParams,
      (value, key) =>
        !value || value === Object.values(RECOMMENDATIONS_QUERY_PARAMS)?.find((p) => p.name === key)?.defaultValue,
    );
    return Object.entries(obj).map(([key, value]) => {
      switch (key) {
        case RECOMMENDATIONS_QUERY_PARAMS.SEARCH_EXPRESSION.name:
          return {
            header: 'Search',
            value,
            id: value,
          };
        default:
          return EMPTY_OBJECT;
      }
    });
  }, [queryParams]);

  const toggleFiltersBar = () => {
    dispatch(updateFilterBtnAction(isFilterOpen));
  };

  return (
    <div className="display_flex width_1">
      <div className="mr_0-5">
        <Title type={TITLE_TYPES.PAGE_TITLE}>Recommendations</Title>
      </div>
      <div className="mr_1-5">
        <Title type={TITLE_TYPES.PAGE_LINE_COUNTER}>{count}</Title>
      </div>
      <FilterButton tooltipItems={tooltipItems} onClick={toggleFiltersBar} />
    </div>
  );
};

export default RecommendationsHeader;
