// @flow
import React from 'react';
import {makeStyles} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import SmartTooltip from '../../common/components/SmartTooltip';

const darkMagenta = palette.purple[600];
const darkGreen = palette.mint[600];

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    paddingLeft: 16,
    cursor: 'pointer',
    fontSize: 14,
    color: palette.gray[400],
    fontWeight: 500,
    width: '100%',
  },
  chartInfoS: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    flexGrow: 1,
  },
  metricName: {
    color: darkMagenta,
  },
  propertyKey: {
    color: darkGreen,
    padding: '0 3px',
    display: 'inline-block',
    backgroundColor: palette.gray[100],
    borderRadius: 2,
    marginRight: 5,
  },
  alertName: {
    padding: '0 3px',
    display: 'inline-block',
    backgroundColor: palette.gray[100],
    borderRadius: 2,
    marginRight: 5,
  },
  valueWrapper: {
    maxWidth: 348,
    minWidth: 56,
  },
  value: {
    color: palette.mint[600],
  },
  pointer: {
    cursor: 'pointer',
  },
  keyS: {
    maxWidth: 114,
    minWidth: 56,
    color: palette.gray[400],
  },
  tooltipContent: {
    color: palette.gray[500],
    fontWeight: 500,
    fontSize: 14,
  },
  tooltipBox: {
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    maxWidth: 493,
    borderRadius: 8,
    '&.tooltip': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  customPlacement: {
    '&.tooltip': {
      marginTop: -18,
      marginLeft: -12,
    },
    '@global': {
      '.tooltip-inner': {
        position: 'relative',
        transform: 'translate(100%, -50%)',
      },
    },
  },
}));

type Proptypes = {
  width: number,
  tags: string,
  metricName: string,
  origin: string,
  prefix: string,
  properties: Array,
};

const SummaryHeader = ({properties, tags, metricName, origin, prefix, width}: Proptypes) => {
  const classes = useStyles();
  const tooltipPropertiesTable = (
    <div>
      <span className={classes.metricName}>{metricName}</span>
      <table>
        <tbody>
          {properties.map(({key, value}) => (
            <tr key={key}>
              <td className={classes.keyS}>{key}</td>
              <td className={classes.valueWrapper}>
                <span className={classes.value}>{value}</span>
              </td>
            </tr>
          ))}
          {tags.map(({key, value}) => (
            <tr key={key}>
              <td className={classes.keyS}>{key}</td>
              <td className={classes.valueWrapper}>
                <span className={classes.value}>{value}</span>
              </td>
            </tr>
          ))}
          {origin?.type}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className={classes.wrapper} style={{width}}>
      {prefix && <div style={{flexShrink: 0}}>{prefix}</div>}
      <div className={classes.chartInfoS}>
        <SmartTooltip
          content={tooltipPropertiesTable}
          extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
          placement="bottom"
        >
          <span className={classes.metricName}>{metricName}</span>
        </SmartTooltip>{' '}
        for{' '}
        {properties
          .sort((a, b) => a.value.length - b.value.length)
          .map(({key, value}) => (
            <SmartTooltip
              content={<div className={classes.tooltipContent}>{key}</div>}
              extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
              placement="bottom"
            >
              <span key={key} className={classes.propertyKey}>
                {value}
              </span>
            </SmartTooltip>
          ))}
        {tags.map(({key, value}) => (
          // <Tooltip key={key} content={key} theme="info">
          <span key={key} className={classes.alertName}>
            #{value}
          </span>
          // </Tooltip>
        ))}
        {origin?.type && (
          // <Tooltip content={origin.title} theme="info">
          <span className={classes.alertName}>@{origin.type.toLowerCase()}</span>
          // </Tooltip>
        )}
      </div>
    </div>
  );
};

export default SummaryHeader;
