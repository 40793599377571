// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {segmentClickEvent as segmentTrackingMouseEventAction} from 'common/store/actions';
import {
  fetchSumologicPreview as fetchSumologicPreviewAction,
  setSumologicSchema as setSumologicSchemaAction,
} from 'bc/store/actions';
import {
  getSelectedDataStream,
  getSumologicPreviewData,
  getSumologicPreviewDataError,
  getSumologicPreviewDataIsLoading,
  getSumologicPreviewDataItems,
} from 'bc/store/selectors';
import {isStreamEditable as isStreamEditableService} from 'bc/services/dataStreamService';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import QueryEditorModal from '../../sql/queryEditorModal/QueryEditorModal';
import './SumologicData.module.scss';

const DEFAULT_QUERY = '';

const GUIDELINES = [
  <Fragment>
    Timelice
    <ul>
      <li>The query must contain the “timeslice” operation, please use 1m, 5m, 1h as the time-slicing options.</li>
      <li>The timeslice value must be smaller than or equal to the collection interval.</li>
      <li>The _timeslice must be one of the outputs of the query, Anodot expects that value as the timestamp field.</li>
    </ul>
  </Fragment>,
  <Fragment>
    Aggregation & Sorting
    <ul>
      <li>The query must contain aggregations (see in the example ‘count by’ and the dimensions).</li>
      <li>Include a ‘sort by’ clause, to sort on the _timeslice in an ascending manner.</li>
    </ul>
  </Fragment>,
  <Fragment>Do not use aliases in the query</Fragment>,
];

type PropTypes = {
  dataStream: Object,
  // connect
  previewData: Object,
  queryPreviewItems: Object,
  previewDataError: Object,
  isPreviewDataLoading: boolean,
  fetchSumologicPreview: Function,
  setSumologicSchema: Function,
  segmentClickEvent: Function,
};

export default connect(
  (state) => ({
    dataStream: getSelectedDataStream(state),
    previewData: getSumologicPreviewData(state),
    queryPreviewItems: getSumologicPreviewDataItems(state),
    previewDataError: getSumologicPreviewDataError(state),
    isPreviewDataLoading: getSumologicPreviewDataIsLoading(state),
  }),
  {
    fetchSumologicPreview: fetchSumologicPreviewAction,
    setSumologicSchema: setSumologicSchemaAction,
    segmentClickEvent: segmentTrackingMouseEventAction,
  },
)(
  class SumologicData extends React.PureComponent {
    props: PropTypes;

    state = {
      isStreamEditable: isStreamEditableService(this.props.dataStream),
      isOpenQueryEditorModal: false,
    };

    closeQueryEditorModal = () => {
      this.setState({isOpenQueryEditorModal: false});
    };

    openQueryEditorModal = () => {
      const {dataStream, segmentClickEvent} = this.props;
      this.setState({isOpenQueryEditorModal: true});
      segmentClickEvent({category: `Data collectors - ${dataStream.type}`, name: 'Query editor'});
    };

    handelContinueClick = (payload) => {
      const {setSumologicSchema, queryPreviewItems} = this.props;
      setSumologicSchema({
        query: payload.query,
        metaData: queryPreviewItems,
      });
      this.closeQueryEditorModal();
    };

    runQuery = (payload) => {
      this.props.fetchSumologicPreview({
        query: payload.query,
        pollingInterval: 'HOURLY',
        dataSourceId: this.props.dataStream.dataSourceId,
        timeZone: this.props.dataStream.timeZone || moment.tz.guess(),
      });
    };

    render() {
      const {dataStream, previewData, previewDataError, isPreviewDataLoading, queryPreviewItems} = this.props;
      const {isStreamEditable} = this.state;
      const query = dataStream.query ? dataStream.query : DEFAULT_QUERY;

      return (
        <div styleName="left-container">
          <PanelHeader title="Stream query" isEditable={isStreamEditable} />

          <div styleName="select-panel">
            <button
              type="button"
              className="btn btn-raised"
              styleName="btn-compose"
              automation-id="bigQueryComposeQueryButton"
              disabled={false}
              onClick={this.openQueryEditorModal}
            >
              Compose Query
            </button>

            {this.state.isOpenQueryEditorModal && (
              <QueryEditorModal
                isOpen={this.state.isOpenQueryEditorModal}
                onClose={this.closeQueryEditorModal}
                dataStream={dataStream}
                query={query}
                runQueryPreview={this.runQuery}
                hideActualQuery
                previewData={previewData}
                previewDataError={previewDataError}
                isPreviewDataLoading={isPreviewDataLoading}
                queryPreviewColumns={queryPreviewItems}
                fetchVerifyQueryFunction={this.handelContinueClick}
                isVerifyQueryLoading=""
                verifyQueryData=""
                guidelines={GUIDELINES}
              />
            )}
          </div>
        </div>
      );
    }
  },
);
