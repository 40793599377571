// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {isStreamEditable} from 'bc/services/dataStreamService';
import {getEditorValidationMessage} from 'bc/services/coralogixService';
import DimensionsAndMetrics from 'bc/components/streams/editor/common/DimensionsAndMetrics';
import {get} from 'lodash';

type PropTypes = {
  // connect
  dataStream: Object,
  dimensions: Array,
  metrics: Array,
  isLoadingLabels: Boolean,
  isLoadingMeasures: Boolean,

  setSelectedEditor: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dimensions: selectors.getCoralogixMetaDimensions(state),
    metrics: selectors.getCoralogixMetaMetrics(state),
    isLoadingLabels: selectors.getCoralogixStreamLabelsIsLoading(state),
    isLoadingMeasures: selectors.getCoralogixStreamMeasuresIsLoading(state),
  }),
  {
    setSelectedEditor,
  },
)(
  class CoralogixDimensionsAndMetrics extends React.PureComponent {
    props: PropTypes;

    setSelectedEditor = () => {
      this.props.setSelectedEditor({
        type: 'dm_coralogix',
        editorHeight: 'calc(100% - 210px)',
      });
    };

    render() {
      const {dataStream, dimensions, metrics, isLoadingLabels, isLoadingMeasures} = this.props;

      const isEditable =
        isStreamEditable(dataStream.state) &&
        get(this.props.dataStream, 'metricName', false) !== false &&
        dataStream.metrics.length !== 0 &&
        dataStream.dimensions.length !== 0 &&
        !isLoadingLabels &&
        !isLoadingMeasures;

      return (
        <DimensionsAndMetrics
          dataStream={dataStream}
          dimensions={dimensions}
          metrics={metrics}
          isEditable={isEditable}
          isVertical
          validatorFunction={getEditorValidationMessage}
          onSelectEditor={() => this.setSelectedEditor()}
          dimensionColumnName="Dimension Fields"
          measureColumnsName="Measure Fields"
        />
      );
    }
  },
);
