import {composeReducers} from 'common/utils/reducers';
import {pick} from 'lodash';
import {
  ALLOWED_FILTER_KEYS,
  DEFAULT_QUERY_PARAMS,
  FILTER_KEY_HEADERS,
} from 'alerts.console.new/services/alertsConsoleService';
import * as actions from 'alerts.console.new/store/actions';

const EMPTY_ARRAY = [];

const checkAggregateValues = (filterName, values) => {
  if (!values) {
    return false;
  }
  return ALLOWED_FILTER_KEYS[filterName].some((i) => values.includes(i.value));
};

const validParams = (queryParams) => {
  let cleanQueryParams = pick(queryParams, Object.keys(DEFAULT_QUERY_PARAMS));
  Object.keys(queryParams).forEach((filterKey) => {
    let value = queryParams[filterKey];
    switch (filterKey) {
      case FILTER_KEY_HEADERS.isOpen.id:
        value = value?.toString();
        if (checkAggregateValues(filterKey, value)) {
          cleanQueryParams = {
            ...cleanQueryParams,
            [filterKey]: value,
          };
        } else {
          cleanQueryParams = {
            ...cleanQueryParams,
            [filterKey]: undefined,
          };
        }
        break;
      case FILTER_KEY_HEADERS.types.id:
      case FILTER_KEY_HEADERS.severities.id:
      case FILTER_KEY_HEADERS.feedback.id:
        if (checkAggregateValues(filterKey, value)) {
          cleanQueryParams = {
            ...cleanQueryParams,
            [filterKey]: value,
          };
        } else {
          cleanQueryParams = {
            ...cleanQueryParams,
            [filterKey]: undefined,
          };
        }
        break;
      default:
    }
  });
  return cleanQueryParams;
};

export default composeReducers(
  (
    state = {
      investigationModal: {
        isOpen: false,
        anomalyId: '',
        triggerId: '',
      },
      openItems: EMPTY_ARRAY,
      modalState: {metricsFilter: ''},
      isListScrolled: false,
    },
    {type, payload},
  ) => {
    switch (type) {
      case actions.setAlertsFiltersNewAlertConsole.TYPE:
        return {
          ...state,
          queryParams: validParams(payload),
        };

      case actions.setSelectedTriggeredAlert.TYPE: {
        const exists = !!state.openItems.find((a) => a === payload);
        const openItems = exists ? state.openItems.filter((a) => a !== payload) : [...state.openItems, payload];
        return {...state, openItems};
      }

      case actions.changeScrollbarWidth.TYPE: {
        const scrollbarWidth = payload ? payload.offsetWidth - payload.clientWidth : 0;
        return {...state, scrollbarWidth};
      }

      case actions.listScrolled.TYPE: {
        return {...state, isListScrolled: payload};
      }

      case actions.setIsAlertsListScrolling.TYPE: {
        return {...state, isAlertsListScrolling: payload};
      }

      case actions.updateFilterBtn.TYPE: {
        return {...state, isFiltersBarOpen: !payload};
      }

      case actions.toggleInvestigationModal.TYPE: {
        return {...state, investigationModal: {...state.investigationModal, ...payload}};
      }

      default:
        return state;
    }
  },
);
