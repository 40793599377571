// flow
import React, {useEffect, useState} from 'react';
import {palette} from 'app/styles/theme';
import {Transition} from 'react-transition-group';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {abbrNum, commasSepNumber} from 'common/utils/numbers';
import {fadeOpacity} from 'insightsPanelNewAlertConsole/services/constants';
import fetchTriggeredAlertsMetricsUsageProvider from 'insightsPanelNewAlertConsole/api/fetchTriggeredAlertsMetricsUsageProvider';

import './MetricsUsage.module.scss';

const MetricsUsage = ({title}: {title: String}) => {
  const metricsUsageProvider = fetchTriggeredAlertsMetricsUsageProvider()?.useQuery();
  const metricsUsageData = metricsUsageProvider?.data;
  const isLoading = metricsUsageProvider?.isLoading;

  const [showData, setShowData] = useState(false);
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);

  useEffect(() => {
    if (metricsUsageData) {
      setProgressBarPercentage(Math.round((metricsUsageData?.monthlyUsage / metricsUsageData?.limit) * 100));
      setShowData(true);
    }
  }, [metricsUsageData]);

  const tooltipBody = () => (
    <div style={{textAlign: 'left'}}>
      <div>
        <strong>Used</strong>
        <span>
          : {progressBarPercentage}% ({commasSepNumber(metricsUsageData?.monthlyUsage)}){' '}
        </span>
      </div>
      <div>
        <strong>Total Capacity</strong>
        <span>:{`${' '}${commasSepNumber(metricsUsageData?.limit)}`}</span>
      </div>
      <div>
        <strong>Remaining</strong>
        <span>:{`${' '}${commasSepNumber(metricsUsageData?.limit - metricsUsageData?.monthlyUsage)}`}</span>
      </div>
    </div>
  );

  const duration = 750;

  const setFillColor = (percentage) => {
    if (percentage === 0) {
      return palette.gray[400];
    }
    if (percentage < 85) {
      return palette.blue[500];
    }
    if (percentage >= 85 && percentage < 95) {
      return palette.orange[500];
    }
    return palette.red[500];
  };

  const defaultStyle = {
    transition: `width ${duration}ms ease-out`,
    width: 0,
    backgroundColor: setFillColor(progressBarPercentage),
  };

  const transitionStyles = {
    entering: {width: 0},
    entered: {width: `${progressBarPercentage}%`},
    exiting: {width: `${progressBarPercentage}%`},
    exited: {width: 0},
  };

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && metricsUsageData && (
        <Transition in={showData} timeout={fadeOpacity.duration}>
          {(state) => (
            <>
              <header
                styleName="header"
                style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state], marginBottom: '20px'}}
              >
                {title}
              </header>
              <div style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}>
                <Transition in={!!progressBarPercentage} timeout={fadeOpacity.duration}>
                  {(stateTwo) => (
                    <div>
                      <SmartTooltip content={tooltipBody()} delay={300}>
                        <div styleName="progressBar-wrapper">
                          <span styleName="progressBar-fill" style={{...defaultStyle, ...transitionStyles[stateTwo]}} />
                        </div>
                      </SmartTooltip>
                    </div>
                  )}
                </Transition>
                <div styleName="legend">
                  <p>
                    <span style={{color: defaultStyle.backgroundColor}}>
                      {abbrNum(metricsUsageData?.monthlyUsage, 1, true)}
                    </span>{' '}
                    of
                    {` ${abbrNum(metricsUsageData?.limit, 1, true)}`} used
                  </p>
                </div>
              </div>
            </>
          )}
        </Transition>
      )}
    </div>
  );
};

export default MetricsUsage;
