import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {useFormContext} from 'react-hook-form';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import {getAllowedRangeTypesByPollingInterval} from 'dataCollectors/services/scheduleService';
import {useFetchEventStream} from 'dataCollectors/api/useFetchEventStreams';
import {useQueryParam, StringParam} from 'use-query-params';
import InfoBox from 'dataCollectors/components/infoBox/InfoBox';
import useSectionsClasses from 'dataCollectors/styles/classes.useSectionsClasses';
import PollingInterval from './PollingInterval';
import TimeZone from './TimeZone';
import DateRange from './DateRange';

const {POLLING_INTERVAL, HISTORY_DATE_RANGE, TIMEZONE} = EVENT_STREAM_FIELDS;
const {sections} = useSectionsClasses();

function Schedule({info, leftSideWidth, isVisible}) {
  const sectionsClasses = sections({leftSideWidth});

  const [streamId] = useQueryParam('streamId', StringParam);
  const {isEventStreamLoading} = useFetchEventStream(streamId);

  const [allowedRangeTypes, setAllowedRangeTypes] = useState([]);

  const {setValue, getValues} = useFormContext();

  const values = getValues();

  useEffect(() => {
    setAllowedRangeTypes(getAllowedRangeTypesByPollingInterval(values[POLLING_INTERVAL.id]));
  }, [values[POLLING_INTERVAL.id]]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={`${sectionsClasses.rootLast}`}>
      <div className={sectionsClasses.container}>
        <div className={sectionsClasses.left}>
          <div className={sectionsClasses.title}>Schedule</div>
          <div className={`${sectionsClasses.subTitle} ${sectionsClasses.marginTop}`}>Set the streaming schedule.</div>

          <div className={sectionsClasses.inputsWrapper2}>
            <div className={`${sectionsClasses.input} ${sectionsClasses.marginTop}`}>
              <div className={sectionsClasses.label}>{POLLING_INTERVAL.name}</div>
              <PollingInterval
                selectedValue={values[POLLING_INTERVAL.id]}
                onChange={(value) => setValue(POLLING_INTERVAL.id, value)}
                isLoading={isEventStreamLoading}
              />
            </div>

            <div className={`${sectionsClasses.input} ${sectionsClasses.marginTop}`}>
              <div className={sectionsClasses.label}>{HISTORY_DATE_RANGE.name}</div>
              <DateRange
                isCustomAllowed={false}
                allowedRangeTypes={allowedRangeTypes}
                selectedValue={values[HISTORY_DATE_RANGE.id]}
                onChange={(value) => setValue(HISTORY_DATE_RANGE.id, value)}
                isUnix
                isLoading={isEventStreamLoading}
              />
            </div>
          </div>
          <div className={sectionsClasses.inputsWrapper2}>
            <div className={`${sectionsClasses.input} ${sectionsClasses.marginTop}`}>
              <div className={sectionsClasses.label}>{TIMEZONE.name}</div>
              <TimeZone
                selectedValue={values[TIMEZONE.id]}
                onChange={(value) => setValue(TIMEZONE.id, value)}
                isLoading={isEventStreamLoading}
              />
            </div>
          </div>
        </div>
        {info && <InfoBox title={info.title} body={info.body} />}
      </div>
    </div>
  );
}

Schedule.defaultProps = {
  info: false,
  leftSideWidth: '100%',
  isVisible: false,
};

Schedule.propTypes = {
  info: PropTypes.bool,
  leftSideWidth: PropTypes.number,
  isVisible: PropTypes.bool,
};

export default Schedule;
