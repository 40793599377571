// @flow
import Footer from 'bc/components/streams/footer/Footer';
import StreamItButton from 'bc/components/streams/preview/StreamItButton';
import StreamEditorCancelActions from 'bc/components/streams/editor/StreamEditorCancelActions';
import WizardStepMenu, {crumbs} from 'bc/components/streams/footer/WizardStepMenu';
import React from 'react';
import {connect} from 'react-redux';
import {fetchCustomerMetricsCount} from 'metrics/store/actions';
import {setRoute} from 'common/store/actions';
import StreamTablePreview from 'bc/components/streams/preview/StreamTablePreview';
import Loader from 'common/components/Loader';
import {getStreamPreviewData, fetchLiveDataStreamPreview} from 'bc/store/actions';
import * as selectors from '../store/selectors';
import './DataStreamPreview.module.scss';

type PropTypes = {
  isLoading: boolean,
  match: Object,
  dataStream: Object,
  hasError: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  fetchCustomerMetricsCount: Function,
  getStreamPreviewData: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  fetchLiveDataStreamPreview: Function,
  setRoute: Function,
};

export default connect(
  (state) => ({
    hasError: selectors.hasPreviewErrors(state) || selectors.hasStatsErrors(state),
    isLoading: selectors.getIsPreviewLoading(state),
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    getStreamPreviewData,
    fetchLiveDataStreamPreview,
    fetchCustomerMetricsCount,
    setRoute,
  },
)(
  class DataStreamPreview extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      if (!this.props.dataStream || this.props.dataStream.id !== this.props.match.params.id) {
        this.props.setRoute('/bc/data-manager');
      } else {
        this.props.getStreamPreviewData();
      }
    }

    render() {
      if (this.props.hasError) {
        return null;
      }
      if (this.props.isLoading !== false) {
        return (
          <div styleName="loading-container" className="shell-col">
            <span styleName="message">
              <span styleName="main-msg">Collecting data </span>
              <br />
              <span styleName="sub-msg">may take a moment</span>
            </span>
            <Loader size="extra-large" />
          </div>
        );
      }

      return (
        <div styleName="root">
          <StreamTablePreview />
          <Footer
            leftChildren={<StreamEditorCancelActions />}
            centerChildren={<WizardStepMenu current={crumbs.tablePreview.key} />}
            rightChildren={<StreamItButton />}
          />
        </div>
      );
    }
  },
);
