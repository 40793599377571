import axios from 'axios';
import {renewTokenIfNeeded, setRequestAuthorizationHeader} from 'common/utils/angularServices';
import {
  generalErrorMsg,
  insufficientPermissionsErrorMsg,
  timeoutErrorMsg,
} from 'common/utils/notifications/generalNotificationCodes';

// Please note that this file is a duplication of react-client/src/common/utils/http.js with adjustments to react query
// Any general changes should be made for both files
const baseURL = window.location.origin;

const req = axios.create({
  baseURL,
  responseType: 'json',
  withCredentials: true,
});

req.interceptors.request.use(
  (config) =>
    // Do something before request is sent
    setRequestAuthorizationHeader(config),
  (error) =>
    // Do something with request error
    Promise.reject(error),
);

req.interceptors.response.use(
  async (config) => {
    // Check if token renewal is needed
    const url = config.request.responseURL;
    await renewTokenIfNeeded(url);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const postLogNotification = async (type) => {
  // This is not using axios in order not to get into infinite loop in case request for notification will fail too
  const url = 'api/v1/logger/notifications';
  const token = window.sessionStorage['andt-token'] || window.localStorage['andt-token'];
  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify({
      type: type.toString(),
    }),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  try {
    await fetch(request);
  } catch {
    // no need to do anything
  }
};

const signout = () => {
  if (window.isProduction && window.FS) {
    window.FS.identify(false);
  }

  localStorage.removeItem('andt-token');
  localStorage.removeItem('andt-token-ttl');
  localStorage.removeItem('andt-token-iat');
  sessionStorage.removeItem('andt-token');
  sessionStorage.removeItem('andt-token-ttl');
  sessionStorage.removeItem('andt-token-iat');

  window.location.href = `${window.location.origin}/signin/?err=401&returnUrl=${encodeURIComponent(window.location.href)
    .split('%')
    .join('~')}`;
};

const api = (fn, args) => {
  return fn.apply(req, args).then(
    (res) => res.data,
    (res) => {
      if (args[0] === 'api/v1/anonymise') {
        window.location.href = `${window.location.origin}/signin/?err=401&returnUrl=${encodeURIComponent(
          window.location.href,
        )
          .split('%')
          .join('~')}`;
      }
      if (res?.response?.status === 401) {
        postLogNotification('unAuthorized');
        signout();
      }
      if (res?.response?.data?.andtErrorCode) {
        postLogNotification(generalErrorMsg.code);
        return Promise.reject(res.response.data);
      }
      if (res?.response?.status === 408) {
        postLogNotification(timeoutErrorMsg.code);
        return Promise.reject(timeoutErrorMsg);
      }
      if (res?.response?.status === 403) {
        postLogNotification(insufficientPermissionsErrorMsg.code);
        return Promise.reject(insufficientPermissionsErrorMsg);
      }
      postLogNotification(generalErrorMsg.code);
      return Promise.reject(generalErrorMsg);
    },
  );
};

export function get(...rest) {
  return api(req.get, rest);
}

export function post(...rest) {
  return api(req.post, rest);
}

export function put(...rest) {
  return api(req.put, rest);
}

// eslint-disable-next-line no-underscore-dangle
export function delete_(...rest) {
  const params = rest;
  params[1] = {data: rest[1]};

  return api(req.delete, params);
}
