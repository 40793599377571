import React, {memo, useCallback} from 'react';
import {useHistory} from 'react-router';
import {useFormContext} from 'react-hook-form';
import {makeStyles} from '@material-ui/core';
import useUpdateEventStream from 'dataCollectors/api/useUpdateEventStream';
import useCreateEventStream from 'dataCollectors/api/useCreateEventStream';
import useStartEventStream from 'dataCollectors/api/useStartEventStream';
import {useToast} from 'common/utils/notifications/notificationsService';
import {useEventStreamContext} from 'dataCollectors/hooks/useEventStreamContext';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import SimpleButton, {COLORS} from 'common/simpleBtn/SimpleButton';
import useNavigationAndTriggerForm from 'dataCollectors/hooks/useNavigationAndTriggerForm';
import {palette} from 'app/styles/theme';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxWidth: 1680,
    minWidth: 1200,
    marginLeft: 90,
    marginRight: 90,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  spaceRight: {
    marginRight: 12,
  },
}));

const FooterNav = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {STREAM_ID} = EVENT_STREAM_FIELDS;

  const {currentStepIndex, maxPages} = useEventStreamContext();
  const {updateEventStream, isUpdateEventStreamLoading} = useUpdateEventStream();
  const {createEventStream, isCreateEventStreamLoading} = useCreateEventStream();
  const {startEventStream, startEventStreamIsLoading} = useStartEventStream();
  const {jumpToStep} = useNavigationAndTriggerForm(currentStepIndex);
  const {success} = useToast(dispatch);
  const {getValues} = useFormContext();

  const stream = getValues();

  const isLastStep = currentStepIndex === maxPages - 1;

  const goToList = useCallback((streamId) => {
    history.push(`/bc/data-manager?updateId=${streamId ?? ''}`); // the "updateId" is to mark the stream that just been created/edited, it will erase after couple of second.
  }, []);

  const onSuccessSave = (data, id = null) => {
    goToList(data.id);

    if (id) {
      success({
        title: 'Stream updated',
        description: null,
      });
    } else {
      success({
        title: 'Stream created',
        description: 'It will inject events shortly.',
      });
    }
  };

  const onSuccessStart = (data) => {
    goToList(data.id);

    success({
      title: 'Stream created',
      description: 'It will inject events shortly.',
    });
  };

  const save = () => {
    if (stream[STREAM_ID.id]) {
      updateEventStream({streamId: stream[STREAM_ID.id], payload: stream, onSuccess: onSuccessSave});
    } else {
      createEventStream({payload: stream, onSuccess: onSuccessSave});
    }
  };

  const startStream = () => {
    startEventStream({
      payload: stream,
      onSuccess: onSuccessStart,
    });
  };

  const previous = () => {
    const prevPage = currentStepIndex - 1;

    jumpToStep(prevPage);
  };

  const next = () => {
    const nextPage = currentStepIndex + 1;

    jumpToStep(nextPage, startStream);
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <SimpleButton
          automationId="exportActivityLog"
          colorSchema={COLORS.GRAY_200}
          onClick={() => goToList()}
          extraClassName={classes.spaceRight}
        >
          <div>
            <span>Cancel</span>
          </div>
        </SimpleButton>
        <SimpleButton automationId="exportActivityLog" colorSchema={COLORS.GRAY_200} onClick={save}>
          <div>
            {(isUpdateEventStreamLoading || isCreateEventStreamLoading) && (
              <Spinner color={palette.gray[500]} size={SIZES.SMALL_30} />
            )}
            <span>Save</span>
          </div>
        </SimpleButton>
      </div>

      <div className={classes.wrapper}>
        {currentStepIndex > 0 && (
          <SimpleButton
            automationId="exportActivityLog"
            colorSchema={COLORS.GHOST_BLUE}
            onClick={previous}
            extraClassName={classes.spaceRight}
          >
            <div>
              <i className="icn-arrow16-arrowleft" />
              <span>Previous</span>
            </div>
          </SimpleButton>
        )}
        <SimpleButton automationId="exportActivityLog" colorSchema={COLORS.BLUE_500} onClick={next}>
          <div>
            {startEventStreamIsLoading && <Spinner color={palette.white[500]} size={SIZES.SMALL_30} />}
            <span>{!isLastStep ? 'Next' : 'Start streaming'}</span>
            {!isLastStep && <i className="icn-arrow16-arrowright" />}
          </div>
        </SimpleButton>
      </div>
    </div>
  );
};

export default memo(FooterNav);
