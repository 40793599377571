// @flow
import React, {Fragment, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import {makeStyles} from '@material-ui/core/styles';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Input from 'common/componentsV2/Input';
import Button, {COLORS} from 'common/componentsV2/Button';
import * as actions from 'userSettings/store/actions';
import * as selectors from 'userSettings/store/selectors';
import moment from 'moment';
import {getUsersData} from 'admin.users/store/selectors';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';

import useUserSettingsStyles from 'userSettings/pages/useUserSettings.styles';
import ConfirmModal from './ConfirmModal';

const DATE_FORMAT = 'lll';

const useStyles = makeStyles((theme) => ({
  tokenLine: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    alignItems: 'center',
  },
  field: {
    marginBottom: 28,
  },
  title: {
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.gray[200]}`,
    height: '40px',
    alignItems: 'center',
    paddingRight: '25px',
  },
  normalCol: {
    flexGrow: 0,
    maxWidth: '25%',
    flexBasis: '25%',
  },
  smallCol: {
    flexGrow: 0,
    maxWidth: '16.6%',
    flexBasis: '16.6%',
  },
  token: {
    position: 'relative',
    padding: '8px 25px 8px 0',
    borderBottom: `1px solid ${theme.palette.gray[200]}`,
    '&:hover': {
      borderColor: `${theme.palette.blue[300]}`,
      backgroundColor: `${theme.palette.blue[100]}`,
    },
  },
  button: {
    position: 'absolute',
    top: '50%',
    right: '-100px',
    transform: 'translateY(-50%)',
  },
  removeBtn: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.blue[500]}`,
    },
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  relative: {
    position: 'relative',
  },
  tokenCopyBtn: {
    opacity: 0,
    height: '18px',
  },
  tokenCopyWrapper: {
    paddingLeft: 8,
  },
  tokenWrapper: {
    '&:hover $tokenCopyBtn': {
      opacity: 1,
    },
  },
  revoked: {
    color: `${theme.palette.red[500]}`,
    '& $ellipsis': {
      color: `${theme.palette.red[500]}`,
    },
  },
}));

const TokenSettings = () => {
  const dispatch = useDispatch();
  const tokens = useSelector((state) => selectors.getTokens(state));
  const users = useSelector((state) => getUsersData(state));

  const [modalOpen, setModalOpen] = useState(false);
  const [tokenId, setTokenId] = useState('');
  const {
    input: {value},
  } = useField('key');
  const {
    input: {value: inputValue, onChange: inputOnChange},
  } = useField('name');

  useEffect(() => {
    dispatch(actions.fetchRefreshTokens());
  }, []);

  const showModal = (id) => {
    setModalOpen(true);
    setTokenId(id);
  };

  const hideModal = () => {
    setModalOpen(false);
  };

  const removeToken = () => {
    hideModal();
    dispatch(actions.revokeToken(null, {id: tokenId}));
  };

  const addToken = () => {
    dispatch(
      actions.refreshTokens({
        name: inputValue,
      }),
    );
    inputOnChange(' ');
  };

  const classes = useStyles();
  const userSettingsClasses = useUserSettingsStyles();

  const getUserName = (createdBy) => {
    let userName = '';
    users.find((item) => {
      if (item._id === createdBy) {
        userName = `${item.firstName} ${item.lastName[0].toUpperCase()}`;
        return true;
      }

      return false;
    });

    return userName;
  };

  return (
    <div className={userSettingsClasses.section}>
      <div className={userSettingsClasses.container}>
        <div>
          <TypographyBox variant="body1" width={220}>
            Data Collection:
          </TypographyBox>
        </div>
        <div className={userSettingsClasses.subSection}>
          <div className={classes.field}>
            <TypographyBox variant="body1">Key:</TypographyBox>
            <div className={userSettingsClasses.copyWrapper}>
              <Input type="text" value={value} fullSize isDisabled />
              <div className={userSettingsClasses.copyBtn}>
                <CopyToClipboardButton copyStr={value} tooltipMessage="Copied to clipboard" shouldUseIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={userSettingsClasses.container}>
        <div>
          <TypographyBox variant="body1" width={220}>
            Access Keys:
          </TypographyBox>
        </div>
        <div className={userSettingsClasses.subSection}>
          <div className={classes.field}>
            <TypographyBox variant="body1">Name:</TypographyBox>
            <div className={userSettingsClasses.copyWrapper}>
              <Input value={inputValue} onChange={inputOnChange} />
              <div className={classes.button}>
                <Button text="Add" colorSchema={COLORS.BLUE_500} onClick={addToken} icon="icn-action16-plusa" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {tokens && tokens.length > 0 && (
        <>
          <div className={`${userSettingsClasses.container} ${classes.title}`}>
            <div className={classes.normalCol}>
              <TypographyBox variant="h5">Name</TypographyBox>
            </div>
            <div className={classes.smallCol}>
              <TypographyBox variant="h5">Token</TypographyBox>
            </div>
            <div className={classes.normalCol}>
              <TypographyBox variant="h5">Created by</TypographyBox>
            </div>
            <div className={classes.smallCol}>
              <TypographyBox variant="h5">Created at</TypographyBox>
            </div>
            <div className={classes.smallCol}>
              <TypographyBox variant="h5">Revoked at</TypographyBox>
            </div>
          </div>
          {tokens.map((token) => (
            <Fragment key={token.token}>
              <div className={`${classes.token} ${token.revoked ? classes.revoked : null}`}>
                <div className={classes.tokenLine}>
                  <div className={classes.normalCol}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {token.name}
                    </TypographyBox>
                  </div>
                  <div className={`${userSettingsClasses.container} ${classes.tokenWrapper} ${classes.smallCol}`}>
                    <div>
                      <TypographyBox variant="body1" className={classes.ellipsis}>
                        {token.token && `${token.token.slice(0, 5)}**********`}
                      </TypographyBox>
                    </div>
                    <div className={classes.tokenCopyBtn}>
                      <div className={classes.tokenCopyWrapper}>
                        <CopyToClipboardButton
                          className="inline"
                          copyStr={token.token}
                          tooltipMessage="Copied to clipboard"
                          shouldUseIcon
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.normalCol}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {getUserName(token.createdBy)}
                    </TypographyBox>
                  </div>
                  <div className={classes.smallCol}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {token.created && moment(token.created).format(DATE_FORMAT)}
                    </TypographyBox>
                  </div>
                  <div className={classes.smallCol}>
                    <TypographyBox variant="body1" className={classes.ellipsis}>
                      {token.revoked && moment(token.revoked).format(DATE_FORMAT)}
                    </TypographyBox>
                  </div>
                </div>
                {!token.revoked && (
                  <i
                    className={`icon icn-general16-closea ${classes.removeBtn}`}
                    onClick={() => showModal(token._id)}
                  />
                )}
              </div>
            </Fragment>
          ))}
          <ConfirmModal isOpen={modalOpen} onClose={hideModal} onConfirm={removeToken} />
        </>
      )}
    </div>
  );
};

export default TokenSettings;
