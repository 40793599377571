import React, {useCallback, useRef, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core';

const MIN_SIZE = 20;

const patchSize = (size) => {
  if (size < MIN_SIZE) {
    return MIN_SIZE;
  }
  return size;
};

const convertToRatio = (size, wrapperSize) => size / wrapperSize;

const useStyles = makeStyles(({palette}) => ({
  dragControlWrapper: {
    display: 'flex',
    justifyContent: 'center',
    userSelect: 'none',
    flexShrink: 0,
    padding: ({isHorizontal}) => (isHorizontal ? '0 2px' : '2px 0'),
    '&:hover': {
      cursor: ({isHorizontal}) => (isHorizontal ? 'ew-resize' : 'ns-resize'),
    },
    '&:hover $dragControl': {
      backgroundColor: palette.blue[300],
    },
  },
  dragControl: {
    userSelect: 'none',
    height: ({isHorizontal}) => (isHorizontal ? '80px' : '2px'),
    width: ({isHorizontal}) => (isHorizontal ? '2px' : '80px'),
    backgroundColor: palette.gray[200],
    alignSelf: 'center',
    visibility: 'hidden',
  },
  boxWrapper: {
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: ({isHorizontal}) => (isHorizontal ? 'row' : 'column'),
    overflow: 'hidden',
    '&:hover $dragControl': {
      visibility: 'visible',
    },
  },
  firstElementWrapper: {
    minWidth: `${MIN_SIZE}%`,
    minHeight: `${MIN_SIZE}%`,
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'relative',
    backgroundColor: palette.white[500],
    flexGrow: 1,
    flexShrink: 1,
    marginRight: ({isHorizontal}) => (isHorizontal ? '4px' : 0),
    pointerEvents: ({isResizing}) => (isResizing ? 'none' : 'auto'),
  },
  secondElementWrapper: {
    minWidth: `${MIN_SIZE}%`,
    minHeight: `${MIN_SIZE}%`,
    maxWidth: ({isHorizontal}) => (isHorizontal ? `${100 - MIN_SIZE}%` : '100%'),
    maxHeight: ({isHorizontal}) => (isHorizontal ? '100%' : `${100 - MIN_SIZE}%`),
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    flexBasis: ({isHorizontal, sizeState}) => `${patchSize(isHorizontal ? sizeState.w * 100 : sizeState.h * 100)}%`,
    marginTop: ({isHorizontal}) => (isHorizontal ? '4px' : 0),
    marginLeft: ({isHorizontal}) => (isHorizontal ? '4px' : undefined),
    userSelect: 'none',
    pointerEvents: ({isResizing}) => (isResizing ? 'none' : 'auto'),
  },
}));

const getAbsoluteOffsetFromBody = function(element) {
  let x = 0;
  let y = 0;
  let el = element;
  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    x += el.offsetLeft - el.scrollLeft + el.clientLeft;
    y += el.offsetTop - el.scrollTop + el.clientTop;
    el = el.offsetParent;
  }
  return {top: y, left: x};
};

type PropTypes = {
  isHorizontal: boolean,
  onResize: Function,
  size: number,
  firstElement: Node,
  secondElement: Node,
};

const ResizableBox = ({isHorizontal, onResize, size, firstElement, secondElement}: PropTypes) => {
  const [sizeState, setSizeState] = useState(size);
  const [isResizing, setIsResizing] = useState(false);
  const classes = useStyles({isHorizontal, isResizing, sizeState});
  const secondBlockWrapper = useRef();
  const boxWrapperRef = useRef();
  const sizeStateRef = useRef();
  sizeStateRef.current = sizeState;

  useEffect(() => {
    setSizeState(size);
  }, [isHorizontal]);

  const handleMouseMove = useCallback(
    (e) => {
      if (isHorizontal) {
        const height = secondBlockWrapper.current.clientHeight;
        const firstBlockSize = e.clientX - getAbsoluteOffsetFromBody(boxWrapperRef.current).left;
        const secondBlockSize = boxWrapperRef.current.clientWidth - firstBlockSize;
        if (firstBlockSize > MIN_SIZE && secondBlockSize > MIN_SIZE) {
          setSizeState({
            h: convertToRatio(height, boxWrapperRef.current.clientHeight),
            w: convertToRatio(secondBlockSize, boxWrapperRef.current.clientWidth),
          });
        }
      } else {
        const width = secondBlockWrapper.current.clientWidth;
        const firstBlockSize = e.clientY - getAbsoluteOffsetFromBody(boxWrapperRef.current).top;
        const secondBlockSize = boxWrapperRef.current.clientHeight - firstBlockSize;
        if (firstBlockSize > MIN_SIZE && secondBlockSize > MIN_SIZE) {
          setSizeState({
            w: convertToRatio(width, boxWrapperRef.current.clientWidth),
            h: convertToRatio(secondBlockSize, boxWrapperRef.current.clientHeight),
          });
        }
      }
    },
    [isHorizontal],
  );

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
    setIsResizing(false);
    onResize(sizeStateRef.current, isHorizontal);
  }, [onResize]);

  const handleMouseDown = useCallback(
    (e) => {
      document.addEventListener('mouseup', handleMouseUp, true);
      document.addEventListener('mousemove', handleMouseMove, true);
      e.stopPropagation();
      setIsResizing(true);
    },
    [onResize],
  );

  return (
    <div className={classes.boxWrapper} ref={boxWrapperRef}>
      <div
        // Commented the stopPropagation as it seams that it does not needed anymore in React 17 // for more info look at
        // https://reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation // Keeping this causing chart
        // zoom to not work as a result of not getting the mouse event.
        // onMouseDownCapture={stopPropagationCallback}
        className={classes.firstElementWrapper}
      >
        {firstElement}
      </div>
      <div onMouseDown={handleMouseDown} className={classes.dragControlWrapper}>
        <div className={classes.dragControl} />
      </div>
      <div ref={secondBlockWrapper} className={classes.secondElementWrapper}>
        {secondElement}
      </div>
    </div>
  );
};

export default ResizableBox;
