// @flow
import React from 'react';
import {useField} from 'react-final-form';
import {TypographyBox} from 'common/componentsV2/boxTools';
import SubTitle from 'userSettings/components/SubTitle';
import Input from 'common/componentsV2/Input';
import SelectAndt, {TYPE_NEW_NO_SEARCH, THEME_NOT_HIGHLIGHTED} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

import useUserSettingsStyles from 'userSettings/pages/useUserSettings.styles';
import {makeStyles} from '@material-ui/core/styles';

type PropsType = {
  options: Array,
};
const useStyles = makeStyles(() => ({
  section: {
    marginBottom: 56,
  },
  field: {
    width: 100,
    height: 36,
    marginRight: 8,
  },
}));

const SessionTimeout = ({options}: PropsType) => {
  const classes = useStyles();
  const userSettingsClasses = useUserSettingsStyles();

  const {
    input: {value: inputValue, onChange: actionInputChange},
    meta: {invalid},
  } = useField('jwtTtl', {
    validate: (val, allValues) => {
      switch (allValues.selectTimeoutScale.value) {
        case options[0].value: // minutes
          // There is a 15 minutes minimum since the short term session token is for 5 minutes
          // Therefore session timeout less than 5 minutes will not trigger auto token renewal
          // And setting the session timeout to less than 15 mi utes will trigger too many renewal requests
          return !(val >= 15 && val <= 180 * 24 * 60);
        case options[1].value: // hours
          return !(val >= 1 && val <= 180 * 24);
        case options[2].value: // days
          return !(val >= 1 && val <= 180);
        default:
          return !(val >= 1 && val <= 180 * 24);
      }
    },
  });
  const {
    input: {value: selectValue, onChange: actionSelectChange},
  } = useField('selectTimeoutScale');

  const handleInputChange = (e) => {
    actionInputChange(e.target.value);
  };

  return (
    <div className={classes.section}>
      <SubTitle title="User Session Timeout" />
      <div className={userSettingsClasses.container}>
        <div>
          <TypographyBox variant="body1" mr={6}>
            Set Timeout:
          </TypographyBox>
        </div>
        <div>
          <div className={userSettingsClasses.container}>
            <div>
              <div className={classes.field}>
                <Input type="number" onChange={handleInputChange} value={inputValue} isInvalid={invalid} fullSize />
              </div>
            </div>
            <div>
              <SelectAndt
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_NOT_HIGHLIGHTED}
                onChange={actionSelectChange}
                optionHeight={40}
                automationId="SelectTimeout"
                value={selectValue || options[0]}
                options={options}
                buttonWidth={140}
              />
            </div>
          </div>
          <TypographyBox mt={1.5} variant="hintText">
            Minimum 15 minutes, Maximum 180 days
          </TypographyBox>
          <TypographyBox variant="hintText">
            Note: Changing the user session timeout disconnects all active users
          </TypographyBox>
        </div>
      </div>
    </div>
  );
};

export default SessionTimeout;
