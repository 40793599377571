import axios from 'axios';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import {get as _get} from 'lodash';
import {renewTokenIfNeeded, setRequestAuthorizationHeader} from './angularServices';

// Please note that this file is a duplication of react-client/src/react-query/axiosInstance.js with adjustments to react query
// Any general changes should be made for both files
export const baseURL = window.location.origin;

export const getBaseClusterURL = (user) => {
  let locOrigin = window.location.origin;
  if (locOrigin.indexOf('localhost:') === -1) {
    const baseDnsName = user.baseDNSName === 'anodot-test.com' ? 'ano-dev.com' : user.baseDNSName;
    locOrigin = `https://${user.clusterDNSPrefix}.${baseDnsName}`;
  }
  return locOrigin;
};

const req = axios.create({
  baseURL,
  responseType: 'json',
  withCredentials: true,
});

req.interceptors.request.use(
  (config) =>
    // Do something before request is sent
    setRequestAuthorizationHeader(config),
  (error) =>
    // Do something with request error
    Promise.reject(error),
);

req.interceptors.response.use(
  async (config) => {
    // Check if token renewal is needed
    const url = config.request.responseURL;
    await renewTokenIfNeeded(url);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

class HttpError extends Error {
  constructor(response) {
    const error =
      response?.message || (response?.statusText && response?.status)
        ? `${response.statusText} (${response.status})`
        : '' || '';
    super(error);
    this.uMessage = response.message;
    this.andtErrorCode = response.andtErrorCode;
    this.path = response.path;
    this.additionalInfo = response.additionalInfo;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(response).stack;
    }
  }
}

const api = (fn, args) =>
  Observable.fromPromise(
    fn.apply(req, args).then(
      (res) => res.data,
      (res) => {
        if (args[0] === 'api/v1/anonymise') {
          window.location.href = `${window.location.origin}/signin/?err=401&returnUrl=${encodeURIComponent(
            window.location.href,
          )}`;
        }
        if (_get(res, 'response.status') === 401) {
          return Promise.reject(res.response);
        }
        if (_get(res, 'response.data.andtErrorCode') || _get(res, 'response.status') === 401) {
          const httpError = new HttpError(res.response.data);
          return Promise.reject(httpError);
        }
        if (_get(res, 'response.status') === 403) {
          return Promise.reject(res.response);
        }
        const error =
          res?.response?.statusText && res?.response?.status
            ? `${res.response.statusText} (${res.response.status})`
            : '';
        return Promise.reject(new Error(error));
      },
    ),
  );

export const stringifyQS = (obj) =>
  Object.keys(obj)
    .map((key) => `${key}=${obj[key]}`)
    .join('&');

export const stringifyAndEscapeQS = (obj) =>
  Object.keys(obj)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join('&');

export function parseQS(search) {
  const hashes = search?.indexOf('?') !== -1 ? search.slice(search.indexOf('?') + 1).split('&') : [];
  const params = {};
  // eslint-disable-next-line array-callback-return
  hashes.map((hash) => {
    const [key, val] = hash.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
}

export const getPageNameSegment = (hash, allSegments) => {
  if (!hash) {
    return 'undefined';
  }
  const tmpObj = {};
  const index = hash.indexOf('?');

  if (index > 0) {
    tmpObj['?'] = index;
  }
  const name = tmpObj['?'] ? hash.slice(0, tmpObj['?']) : hash;
  let cleanName = name.replace('#!/r/', '').replace('#!/', '');

  if (cleanName.indexOf('/') === 0) {
    cleanName = cleanName.slice(1, cleanName.length);
  }
  if (allSegments) {
    return cleanName;
  }
  const cleanNameIndex = cleanName.indexOf('/') === -1 ? cleanName.length : cleanName.indexOf('/');
  return cleanName.slice(0, cleanNameIndex);
};

export function get(...rest) {
  return api(req.get, rest);
}

export function post(...rest) {
  return api(req.post, rest);
}

export function put(...rest) {
  return api(req.put, rest);
}

// eslint-disable-next-line no-underscore-dangle
export function delete_(...rest) {
  const params = rest;
  params[1] = {data: rest[1]};
  return api(req.delete, params);
  // return api(req.delete, rest);
}
