// @flow
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useQueryParams} from 'use-query-params';
// import {intersection} from 'lodash';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {QUERY_PARAM_MAP} from 'topologyGeneral/services/sidePanelService';
import GeneralTopologyMap from 'topologyGeneral/components/GeneralTopologyMap';
import SidePanel from 'topologyGeneral/components/sidePanel/SidePanel';
import {palette} from 'app/styles/theme';
import {fetchTopologyMapRegions, fetchTopologyMapRegionSitesList} from 'topologyGeneral/store/actions';
import {
  getGtpMapRegions,
  getGtpMapSites,
  isLoadingGtpMapRegionsSitesList,
  getIsMapDataLoading,
  getGtpMapLinks,
  getGtpMapSelectedIssue,
  getSelectedIssueRegionSites,
  getGtpMapSelectedIssueSites,
  getGtpMapSelectedIssueMetric,
} from 'topologyGeneral/store/selectors';

import './GeneralTopologyPage.module.scss';

const EMPTY_ARRAY = [];

const GeneralTopologyPage = () => {
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);
  const mapRef = useRef();
  const dispatch = useDispatch();

  const allLinks = useSelector(getGtpMapLinks);
  const allSites = useSelector(getGtpMapSites);
  const selectedIssue = useSelector(getGtpMapSelectedIssue);
  const selectedIssueRegionSites = useSelector(getSelectedIssueRegionSites);
  const selectedIssueSites = useSelector(getGtpMapSelectedIssueSites);
  const selectedIssueMetric = useSelector(getGtpMapSelectedIssueMetric);

  const isSitesLoading = useSelector(isLoadingGtpMapRegionsSitesList);
  const isMapDataLoading = useSelector(getIsMapDataLoading);
  const [nodeHoveredId, setNodeHoveredId] = useState(undefined);

  const allRegions = useSelector(getGtpMapRegions);
  const [sites, setSites] = useState([]);
  const [regions, setRegions] = useState([]);
  const [links, setLinks] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});

  useEffect(() => {
    dispatch(fetchTopologyMapRegions());
    mapRef.current = null;
  }, []);

  useEffect(() => {
    if (allRegions.length) {
      let procAllRegions = allRegions;
      if (selectedIssue && selectedIssue.id === queryParams.issueId) {
        procAllRegions = allRegions.map((reg) => {
          if (selectedIssue.entityMapping.REGION.includes(reg.serverId)) {
            return reg;
          }
          return {...reg, unSelected: true};
        });
      }

      const zeroLevelRegions = procAllRegions.filter((r) => r.level === 0);

      if (!queryParams.regionId) {
        setRegions(zeroLevelRegions);
      }

      const findRegion = procAllRegions.find((r) => r.id === queryParams.regionId);
      if (!findRegion) {
        setRegions(zeroLevelRegions);
        setSelectedRegion({});
        if (queryParams.regionId) {
          setQueryParams({regionId: undefined});
        }
      } else {
        setSelectedRegion(findRegion);
        setRegions(procAllRegions.filter((r) => r.parentRegionId === queryParams.regionId));
      }
    } else {
      setRegions([]);
    }
  }, [queryParams.regionId, allRegions, queryParams.issueId, selectedIssue]);

  useEffect(() => {
    if (allLinks.length) {
      let procAllLinks = allLinks;
      if (selectedIssue && selectedIssue.id === queryParams.issueId) {
        procAllLinks = allLinks.map((link) => {
          if (selectedIssue.entityMapping.REGION.includes(link.id)) {
            return link;
          }
          return {...link, unSelected: true};
        });
      }
      setLinks(procAllLinks);
    } else {
      setLinks([]);
    }
  }, [allLinks, queryParams.issueId, selectedIssue]);

  useEffect(() => {
    if (allSites.length && !queryParams.issueId) {
      const sitesArr = [];
      let selSite = null;
      allSites.forEach((it) => {
        if (queryParams.siteId && queryParams.siteId === it.id) {
          selSite = {...it};
          return;
        }
        if (queryParams.siteId && queryParams.siteId !== it.id) {
          sitesArr.push({...it, unSelected: true});
        } else {
          sitesArr.push({...it});
        }
      });
      sitesArr.sort((a, b) => b.percent - a.percent);
      if (selSite) {
        sitesArr.unshift(selSite);
      }

      let domainSelectedSiteArray = null;
      if (queryParams.domainId) {
        domainSelectedSiteArray = sitesArr
          .map((sItem) => {
            let isDomainSelected = false;
            const nodes = sItem.nodes.map((n) => {
              const locIsDomSelected = n.domain.indexOf(queryParams.domainId) !== -1;
              if (locIsDomSelected) {
                isDomainSelected = locIsDomSelected;
              }
              return {...n, isDomainSelected: locIsDomSelected};
            });
            const topWing = sItem.cells.topWing.map((c) => {
              const locIsDomSelected = c.domain.indexOf(queryParams.domainId) !== -1;
              if (locIsDomSelected) {
                isDomainSelected = locIsDomSelected;
              }
              return {...c, isDomainSelected: locIsDomSelected};
            });
            const rightWing = sItem.cells.rightWing.map((c) => {
              const locIsDomSelected = c.domain.indexOf(queryParams.domainId) !== -1;
              if (locIsDomSelected) {
                isDomainSelected = locIsDomSelected;
              }
              return {...c, isDomainSelected: locIsDomSelected};
            });
            const leftWing = sItem.cells.leftWing.map((c) => {
              const locIsDomSelected = c.domain.indexOf(queryParams.domainId) !== -1;
              if (locIsDomSelected) {
                isDomainSelected = locIsDomSelected;
              }
              return {...c, isDomainSelected: locIsDomSelected};
            });
            return {...sItem, nodes, cells: {topWing, rightWing, leftWing}, isDomainSelected};
          })
          .sort((a, b) => {
            if (b.isDomainSelected && !a.isDomainSelected) {
              return 1;
            }
            if (!b.isDomainSelected && a.isDomainSelected) {
              return -1;
            }
            return 0;
          });
      }

      setSites(domainSelectedSiteArray || sitesArr);
    } else {
      setSites([]);
    }
  }, [queryParams.siteId, allSites, queryParams.domainId, queryParams.issueId /* selectedIssue */]);

  useEffect(() => {
    let selectedIssueSitesForMetric = [];
    const selectedIssueSitesForMetricIds = [];
    let selectedParentRegionId = null;

    // get sites related to the metric
    if (selectedIssueMetric && selectedIssueMetric.entityMapping) {
      selectedIssueSitesForMetric = selectedIssueSites.map((site) => {
        selectedIssueSitesForMetricIds.push(site.id);
        if (selectedIssueMetric.entityMapping.SITE.length) {
          if (site.serverId !== selectedIssueMetric.entityMapping.SITE[0]) {
            return site;
          }
          if (site.parentRegionId) {
            selectedParentRegionId = site.parentRegionId;
          }
          return {...site, selected: true};
        }
        if (selectedIssueMetric.entityMapping.NODE.length) {
          if (site.nodes.find((node) => node.id === selectedIssueMetric.entityMapping.NODE[0])) {
            if (site.parentRegionId) {
              selectedParentRegionId = site.parentRegionId;
            }
            return {...site, selected: true};
          }
          return site;
        }
        if (selectedIssueMetric.entityMapping.LINK.length) {
          if (site.links.find((node) => node.id === selectedIssueMetric.entityMapping.LINK[0])) {
            if (site.parentRegionId) {
              selectedParentRegionId = site.parentRegionId;
            }
            return {...site, selected: true};
          }
          return site;
        }
        if (selectedIssueMetric.entityMapping.CELL.length) {
          if (site.cells.leftWing.length) {
            if (site.cells.leftWing.find((node) => node.id === selectedIssueMetric.entityMapping.CELL[0])) {
              if (site.parentRegionId) {
                selectedParentRegionId = site.parentRegionId;
              }
              return {...site, selected: true};
            }
          } else if (site.cells.rightWing.length) {
            if (site.cells.rightWing.find((node) => node.id === selectedIssueMetric.entityMapping.CELL[0])) {
              if (site.parentRegionId) {
                selectedParentRegionId = site.parentRegionId;
              }
              return {...site, selected: true};
            }
          } else if (site.cells.topWing.length) {
            if (site.cells.topWing.find((node) => node.id === selectedIssueMetric.entityMapping.CELL[0])) {
              if (site.parentRegionId) {
                selectedParentRegionId = site.parentRegionId;
              }
              return {...site, selected: true};
            }
          } else {
            return site;
          }
        }
        if (selectedIssueMetric.entityMapping.INTERFACE.length) {
          const interfacesList = [];
          site.nodes.forEach((n) => {
            interfacesList.push(...n.interfaces);
          });
          if (interfacesList.find((inter) => inter.id === selectedIssueMetric.entityMapping.INTERFACE[0])) {
            return {...site, selected: true};
          }
          return site;
        }
        // not fond
        return site;
      });
    }

    // get region sites
    if (selectedParentRegionId && allRegions) {
      if (
        !selectedIssueRegionSites.length ||
        (selectedIssueRegionSites.length && selectedIssueRegionSites[0].parentRegionId !== selectedParentRegionId)
      ) {
        const selectedRegionForMetric = allRegions.find((r) => r.id === selectedParentRegionId);
        dispatch(
          fetchTopologyMapRegionSitesList(
            {regionId: selectedRegionForMetric.serverId},
            {map: mapRef, region: selectedRegionForMetric},
          ),
        );
      }
    }

    if (selectedIssue && selectedIssue.id === queryParams.issueId) {
      if (selectedIssueSitesForMetric.length) {
        if (
          selectedParentRegionId &&
          selectedIssueRegionSites.length &&
          selectedIssueRegionSites[0].parentRegionId === selectedParentRegionId
        ) {
          // add the region sites
          selectedIssueRegionSites.forEach((site) => {
            if (!selectedIssueSitesForMetricIds.includes(site.id)) {
              // eslint-disable-next-line no-param-reassign
              site.unSelected = true;
              selectedIssueSitesForMetric.push(site);
            }
          });
        }

        // move selected element to top
        const selectedItemIndex = selectedIssueSitesForMetric.findIndex((item) => item.selected);
        if (selectedItemIndex > -1) {
          const selectedItem = selectedIssueSitesForMetric[selectedItemIndex];
          selectedIssueSitesForMetric.splice(selectedItemIndex, 1);
          selectedIssueSitesForMetric.unshift(selectedItem);
        }
        setSites(selectedIssueSitesForMetric);
      } else {
        // no site related
        setSites(selectedIssueSites);
      }
    }
  }, [queryParams.issueId, selectedIssue, selectedIssueSites, selectedIssueMetric, selectedIssueRegionSites]);

  useEffect(() => {
    if (queryParams.issueId && !queryParams.regionId) {
      if (selectedIssueSites.length) {
        setQueryParams({regionId: selectedIssueSites[0].parentRegionId});
      }
    }
  }, [queryParams.issueId, queryParams.regionId, selectedIssueSites]);

  const getMapRef = (map) => {
    mapRef.current = map;
  };

  const onRegionClick = (item) => {
    setQueryParams({regionId: item.id});
  };
  const onRegionMouseEvent = (ev) => {
    setNodeHoveredId(ev.eventName === 'mouseenter' ? ev.item.id : undefined);
  };

  const onSiteClick = (item) => {
    setQueryParams({siteId: item.id, selectedEntityId: undefined, selectedSubEntityId: undefined, issueId: undefined});
  };
  const onSiteMouseEvent = (ev) => {
    setNodeHoveredId(ev.eventName === 'mouseenter' ? ev.item.id : undefined);
  };

  return (
    <div className="shell" styleName="root">
      <SidePanel mapRef={mapRef} nodeHoveredId={nodeHoveredId} />
      <GeneralTopologyMap
        regions={selectedRegion.isLeaf ? EMPTY_ARRAY : regions.sort((a, b) => a.percent - b.percent)}
        sites={selectedRegion.isLeaf && !isSitesLoading ? sites : EMPTY_ARRAY}
        links={selectedRegion.isLeaf && sites.length ? links : EMPTY_ARRAY}
        getMapRef={getMapRef}
        onRegionNodeClick={onRegionClick}
        onRegionNodeMouseEvent={onRegionMouseEvent}
        onSiteNodeClick={onSiteClick}
        onSiteNodeMouseEvent={onSiteMouseEvent}
      />
      {isMapDataLoading ? (
        <div styleName="map-data-is-loading">
          <Spinner color={palette.gray[500]} size={SIZES.XX_BIG_100} />
        </div>
      ) : null}
    </div>
  );
};

export default GeneralTopologyPage;
