// @flow
import React from 'react';
import {connect} from 'react-redux';
import {get, intersection, difference} from 'lodash';
import {getUniqueId} from 'common/utils/guid';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {AGGREGATION_GROUP_BY_FUNCTION} from 'bc/services/datadogService';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SelectAndt, {
  THEME_NOT_HIGHLIGHTED,
  TYPE_NEW_MULTI,
  TYPE_NEW_NO_SEARCH,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import '../datadogData/DatadogData.module.scss';

type PropTypes = {
  stream: Object,
  tags: Array,
  isLoading: boolean,
  setSelectedStreamKeyVal: Function,
};

// gets sources ids used by items recursively
const getSourceIdsArr = (sourcesIdArr, item) => {
  if (item.sourceColumn !== undefined) {
    if (!sourcesIdArr.includes(item.sourceColumn)) {
      sourcesIdArr.push(item.sourceColumn);
    }
  } else if (item.transform && item.transform.input) {
    item.transform.input.forEach((a) => {
      getSourceIdsArr(sourcesIdArr, a);
    });
  }
  return sourcesIdArr;
};

export default connect(
  (state) => ({
    stream: selectors.getSelectedDataStream(state),
    tags: selectors.getDatadogStreamUiStateTags(state),
    isLoading: selectors.getDatadogStreamMetricDescriptionIsLoading(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)(
  class DatadogGrouping extends React.PureComponent {
    props: PropTypes;

    onChange = (items) => {
      const {stream} = this.props;
      const itemsIds = (items || []).map((m) => m.id);
      const streamCurrentSourceIds = (stream.schema.sourceColumns || []).map((m) => m.id);
      const newSourceColumnsIds = [...stream.metrics, ...itemsIds];
      const columnsToKeep = [];
      stream.schema.columns.forEach((col) => {
        // keep metric or dummy columns
        if (col.type === 'metric' || get(col, 'transform.name', false) === 'dummy') {
          columnsToKeep.push(col);
          return;
        }

        const colSources = getSourceIdsArr([], col);
        const inter = intersection(colSources, newSourceColumnsIds);
        // keep columns that has all sorceColumns in the new one
        if (inter.length === colSources.length) {
          columnsToKeep.push(col);
        }
      });

      const newSchema = {
        columns: columnsToKeep,
        sourceColumns: newSourceColumnsIds.map((n) => ({
          id: n,
          name: n,
        })),
      };

      // need to check which new dimension columns to add (sourceColumn was dealt before)
      const addedDimensions = difference(newSourceColumnsIds, streamCurrentSourceIds);
      if (addedDimensions.length) {
        addedDimensions.forEach((dim) => {
          newSchema.columns.push({
            id: getUniqueId(),
            sourceColumn: dim,
            name: dim,
            type: 'dimension',
          });
        });
      }

      this.props.setSelectedStreamKeyVal({
        dimensions: items.map((i) => i.name),
        schema: newSchema,
      });
    };

    onAggregationChange = (val) => {
      if (val.value !== this.props.stream.aggregation) {
        this.props.setSelectedStreamKeyVal({
          aggregation: val.value,
        });
      }
    };

    render() {
      const {stream, tags, isLoading} = this.props;
      const selectedValues = stream.dimensions.map((dim) => ({
        id: dim,
        name: dim,
      }));
      const aggrSelectedIndex = AGGREGATION_GROUP_BY_FUNCTION.findIndex((val) => val.value === stream.aggregation);
      const isEditable = !stream.customQuery;

      return (
        <div styleName="root">
          <PanelHeader title="Grouping" isEditable={isEditable} />
          <div styleName="datadog-select-block">
            <SelectAndt
              type={TYPE_NEW_MULTI}
              theme={THEME_NOT_HIGHLIGHTED}
              onChange={this.onChange}
              options={tags}
              getOptionLabel={(val) => val.name}
              getOptionValue={(val) => val.id}
              buttonWidth={225}
              optionHeight={40}
              isMulti
              isClearable={false}
              closeMenuOnSelect={false}
              disabled={!isEditable || isLoading}
              value={selectedValues}
              placeholder={isLoading ? 'Loading...' : 'Dimensions'}
              automationId="setDatadogDimensions"
            />
          </div>
          <div styleName="datadog-select-block">
            <SelectAndt
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_NOT_HIGHLIGHTED}
              onChange={this.onAggregationChange}
              options={AGGREGATION_GROUP_BY_FUNCTION}
              buttonWidth={225}
              optionHeight={40}
              disabled={!isEditable || isLoading}
              value={aggrSelectedIndex !== -1 ? AGGREGATION_GROUP_BY_FUNCTION[aggrSelectedIndex] : null}
              placeholder={isLoading ? 'Loading...' : 'Group by function'}
              automationId="setDatadogGroupBy"
            />
          </div>
        </div>
      );
    }
  },
);
