// @flow
import React from 'react';

import ConceptButton from './ConceptButton';

import './ConceptGroup.module.scss';

const ConceptGroup = ({selectedCategory, onAddItem, onRemoveItem, concepts}: any) => (
  <div styleName="group">
    {concepts
      .filter(({category}) => category === selectedCategory)
      .sort((a, b) => a.uiName.localeCompare(b.uiName))
      .map(({apiName, uiName, selected, disabled, description}) => (
        <ConceptButton
          key={apiName}
          {...{
            apiName,
            selected,
            disabled,
            description,
            onAddItem,
            onRemoveItem,
          }}
        >
          {uiName}
        </ConceptButton>
      ))}
  </div>
);

export default ConceptGroup;
