import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {isEmpty, isEqual} from 'lodash';
import {getUniqueId} from 'common/utils/guid';

const mixpanelDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  // gets sources ids used by items recursively
  const getSourceIdsArr = (sourcesIdArr, item) => {
    if (item.sourceColumn) {
      if (!sourcesIdArr.includes(item.sourceColumn)) {
        sourcesIdArr.push(item.sourceColumn);
      }
    } else if (item.transform && item.transform.input) {
      item.transform.input.forEach((a) => {
        getSourceIdsArr(sourcesIdArr, a);
      });
    }
    return sourcesIdArr;
  };

  const getOriginalName = (item) => {
    if (item.transform && item.transform.input) {
      return getSourceIdsArr([], item)[0].name;
    }
    if (item.sourceColumn) {
      return item.sourceColumn;
    }
    return item.name;
  };

  const getIsItemSourceMerged = (unifiedSchema, itemId, itemName) =>
    unifiedSchema.find(
      (a) =>
        (a.sourceColumn === itemId.toString() || (getSourceIdsArr([], a)[0] && getSourceIdsArr([], a)[0] === itemId)) &&
        a.name !== itemName &&
        a.isMerged,
    );

  const findItemInSchema = (columns, item) =>
    columns.find(
      (a) =>
        a.sourceColumn === item.name ||
        (a.transform && a.transform.input && a.transform.input.length === 1 && item.name === getSourceIdsArr([], a)[0]),
    );

  const updateItemMatch = (stream, item) => {
    let itemMatch = {...item};
    if (stream.metrics.includes(item.name) || stream.dimensions.includes(item.name)) {
      itemMatch = {
        ...itemMatch,
        ...findItemInSchema(stream.schema && stream.schema.columns ? stream.schema.columns : [], item),
        isMerged: true,
      };
    } else if (itemMatch.type) {
      delete itemMatch.type;
    }
    return itemMatch;
  };

  switch (type) {
    case actions.removeMixpanelStreamDiametrics.TYPE: {
      // const removeId = payload.startsWith('id_') ? +payload.substr(3) : payload;
      const stream = state.streams.data[getSelectedIndex()];
      const mod = {};
      if (stream.metrics.indexOf(payload) >= 0) {
        mod.metrics = stream.metrics.filter((a) => a !== payload);
      } else if (stream.dimensions.indexOf(payload) >= 0) {
        mod.dimensions = stream.dimensions.filter((a) => a !== payload);
      }
      mod.uiState = {
        ...stream.uiState,
        unAssignedColumns: [...stream.uiState.unAssignedColumns, payload],
      };
      return redWrapper(mod);
    }

    case actions.setMixpanelStreamClearAllDiametrics.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const analysisResult = stream.uiState.eventMetadata;
      const mod = {
        metrics: [],
        dimensions: [],
        uiState: {
          ...stream.uiState,
          unAssignedColumns: analysisResult.map((a) => a.name),
        },
      };
      return redWrapper(mod);
    }

    case actions.setMixpanelStreamDiametricsChange.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const streamModifications = {
        uiState: {...stream.uiState},
      };

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      if (payload.source.droppableId === payload.destination.droppableId) {
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = reorder(
              stream.uiState.unAssignedColumns,
              payload.source.droppableId,
              payload.destination.droppableId,
            );
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = reorder(
              stream.metrics,
              payload.source.droppableId,
              payload.destination.droppableId,
            );
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = reorder(
              stream.dimensions,
              payload.source.droppableId,
              payload.destination.droppableId,
            );
            break;
          }
          default:
            return state;
        }
      } else {
        const {draggableId} = payload;
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = [...stream.uiState.unAssignedColumns];
            streamModifications.uiState.unAssignedColumns.splice(payload.destination.index, 0, draggableId);
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = [...stream.metrics];
            streamModifications.metrics.splice(payload.destination.index, 0, draggableId);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = [...stream.dimensions];
            streamModifications.dimensions.splice(payload.destination.index, 0, draggableId);
            break;
          }
          default:
            return state;
        }

        switch (payload.source.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = [
              ...(streamModifications.uiState.unAssignedColumns || stream.uiState.unAssignedColumns),
            ];
            streamModifications.uiState.unAssignedColumns.splice(
              streamModifications.uiState.unAssignedColumns.findIndex((a) => a === draggableId),
              1,
            );
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = [...stream.metrics];
            streamModifications.metrics.splice(streamModifications.metrics.findIndex((a) => a === draggableId), 1);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = [...stream.dimensions];
            streamModifications.dimensions.splice(
              streamModifications.dimensions.findIndex((a) => a === draggableId),
              1,
            );
            break;
          }

          default:
            return state;
        }
      }
      return redWrapper(streamModifications);
    }

    case actions.setMixpanelDiametricsStreamAnalysisSchema.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];

      const unifiedSchema = [];
      let itemMatch = {};

      if (!stream.customQuery && stream.uiState && stream.uiState.eventMetadata) {
        Object.keys(payload).forEach((newKey) => {
          itemMatch = {};
          stream.uiState.eventMetadata.forEach((oldItem) => {
            if (isEqual(payload[newKey], oldItem)) {
              itemMatch = updateItemMatch(stream, payload[newKey]);
            }
          });
          if (isEmpty(itemMatch)) {
            itemMatch = {...payload[newKey]};
          }
          unifiedSchema.push({...itemMatch});
        });
      } else if (!stream.customQuery && (stream.metrics.length || stream.dimensions.length)) {
        Object.keys(payload).forEach((newKey) => {
          itemMatch = updateItemMatch(stream, payload[newKey]);
          unifiedSchema.push({...itemMatch});
        });
      } else {
        Object.keys(payload).forEach((key) => {
          const value = payload[key];
          const dimensionsOptions = ['String', 'Boolean', 'JSONArray'];

          if (dimensionsOptions.includes(value.objType) || key.includes('time')) {
            unifiedSchema.push({
              sourceColumn: key,
              name: key,
              id: getUniqueId(),
              type: 'dimension',
              path: value.path,
            });
          } else {
            unifiedSchema.push({
              sourceColumn: key,
              name: key,
              id: getUniqueId(),
              type: 'metric',
              path: value.path,
            });
          }
        });
      }

      const unAssignedColumnsItems = unifiedSchema
        .filter((item) => !item.type || (item.type && !['dimension', 'metric'].includes(item.type)))
        .map((item) => item.name);

      // extract the updated unifiedSchema
      const propsToUpdate = {
        uiState: {
          ...stream.uiState,
          eventMetadata: Object.keys(payload).map((key) => ({...payload[key]})),
          unAssignedColumns: unAssignedColumnsItems,
        },
        dimensions: unifiedSchema.filter((item) => item.type === 'dimension').map((item) => getOriginalName(item)),
        metrics: unifiedSchema.filter((item) => item.type === 'metric').map((item) => getOriginalName(item)),
        schema: {
          columns: [],
          sourceColumns: [],
        },
      };

      // schema population
      let unifiedItem = {};
      propsToUpdate.metrics.forEach((itemName) => {
        const metric = unifiedSchema.find((a) => getOriginalName(a) === itemName);
        unifiedItem = {
          id: metric.id || getUniqueId(),
          name: metric.name,
          type: 'metric',
        };
        if (metric.sourceColumn) {
          unifiedItem.sourceColumn = metric.sourceColumn;
        }
        if (metric.transform) {
          unifiedItem.transform = {...metric.transform};
        }
        if (!metric.sourceColumn && !metric.transform) {
          unifiedItem.sourceColumn = metric.name;
        }
        if (metric.targetType) {
          unifiedItem.targetType = metric.targetType;
        }
        if (metric.hidden !== undefined) {
          unifiedItem.hidden = metric.hidden;
        }
        const originalName = getOriginalName(unifiedItem);
        propsToUpdate.schema.columns.push(unifiedItem);
        let exsistInSource = false;
        propsToUpdate.schema.sourceColumns.forEach((sourceItem) => {
          if (sourceItem.id === originalName) {
            exsistInSource = true;
          }
        });
        if (!exsistInSource) {
          propsToUpdate.schema.sourceColumns.push({
            id: originalName,
            name: originalName,
            path: metric.path,
          });
        }
      });
      propsToUpdate.dimensions.forEach((itemName) => {
        const dimension = unifiedSchema.find((a) => getOriginalName(a) === itemName);
        unifiedItem = {
          id: dimension.id || getUniqueId(),
          name: dimension.name,
          type: 'dimension',
        };
        if (dimension.sourceColumn) {
          unifiedItem.sourceColumn = dimension.sourceColumn;
        }
        if (dimension.transform) {
          unifiedItem.transform = {...dimension.transform};
        }
        if (!dimension.sourceColumn && !dimension.transform) {
          unifiedItem.sourceColumn = dimension.name;
        }
        if (dimension.hidden !== undefined) {
          unifiedItem.hidden = dimension.hidden;
        }
        const originalName = getOriginalName(unifiedItem);
        propsToUpdate.schema.columns.push(unifiedItem);
        let exsistInSource = false;
        propsToUpdate.schema.sourceColumns.forEach((sourceItem) => {
          if (sourceItem.id === originalName) {
            exsistInSource = true;
          }
        });
        if (!exsistInSource) {
          propsToUpdate.schema.sourceColumns.push({
            id: originalName,
            name: originalName,
            path: dimension.path,
          });
        }
      });

      // get a list of all the current indexes
      const existingIndexArr = [...propsToUpdate.dimensions, ...propsToUpdate.metrics];

      // compare and copy the user created columns from the current schema
      if (stream.schema && stream.schema.columns) {
        stream.schema.columns.forEach((item) => {
          if (
            item.sourceColumn !== undefined ||
            (item.transform &&
              item.transform.input &&
              item.transform.input.length === 1 &&
              getSourceIdsArr([], item).length === 1)
          ) {
            const sourceColumn = item.sourceColumn || getSourceIdsArr([], item)[0];
            if (
              existingIndexArr.includes(parseInt(sourceColumn, 10)) &&
              getIsItemSourceMerged(unifiedSchema, sourceColumn, item.name)
            ) {
              // user added columns that are a copy of existing column
              propsToUpdate.schema.columns.push({...item});
            }
          } else if (item.transform && item.transform.name === 'const') {
            // user added const column
            propsToUpdate.schema.columns.push({...item});
          } else if (item.transform && item.transform.input && item.transform.input.length > 1) {
            // user added concat columns
            let shouldPush = true;
            getSourceIdsArr([], item).forEach((id) => {
              if (!existingIndexArr.includes(id) || getIsItemSourceMerged(unifiedSchema, id, item.name) === undefined) {
                shouldPush = false;
              }
            });
            if (shouldPush) {
              propsToUpdate.schema.columns.push({...item});
            }
          }
        });
      }

      return {
        ...redWrapper(propsToUpdate),
        isStreamNeedUpdating: false,
      };
    }
    default:
      return state;
  }
});

export default mixpanelDataStreamReducer;
