// @flow
import connect from 'react-redux/es/connect/connect';
import React, {PureComponent} from 'react';
import {Modal} from 'react-bootstrap';
import './NewCubeModal.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {palette} from 'app/styles/theme';
import {fetchDataStreams, fetchDataSourceTypes} from '../../bc/store/actions';
import * as selectors from '../../bc/store/selectors';
import {getTypeDetails} from '../../bc/services/bcTypes';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  onStartClicked: Function,
  fetchDataStreams: Function,
  dataStreams: any,
  isLoading: boolean,
  types: any,
  fetchDataSourceTypes: Function,
};

export default connect(
  (state) => ({
    isLoading: selectors.getIsDataStreamsLoading(state),
    dataStreams: selectors.getDataStreamsItems(state),
    types: selectors.getDataSourceTypesItems(state),
  }),
  {
    fetchDataStreams,
    fetchDataSourceTypes,
  },
)(
  class NewCubeModal extends PureComponent {
    props: PropTypes;

    state = {
      isStartEnabled: false,
      selectedStream: {},
    };

    componentDidMount() {
      this.props.fetchDataSourceTypes();
    }

    componentDidUpdate(prevProps) {
      if (!prevProps.isOpen && this.props.isOpen) {
        this.props.fetchDataStreams();
      }
    }

    getIcon = (type) => {
      const details = getTypeDetails(type);
      if (details) {
        return details.iconStyle;
      }
      return 'ico';
    };

    render() {
      const {isOpen, onClose, onStartClicked, dataStreams, isLoading, types} = this.props;

      const flatData = [];

      types.forEach((type) => {
        const ico = `menu-header image-${this.getIcon(type.type)}`;
        const filtered = dataStreams.filter(
          (dataStream) => dataStream.type === type.type && dataStream.state === 'running' && dataStream.status === 'ok',
        );

        if (filtered.length > 0) {
          flatData.push({
            label: type.name,
            value: type.type,
            type: 'header',
            icon: ico,
            color: palette.red[600],
            iconClass: 'dropdown-icon',
            headerClass: 'dropdown-header-icons',
          });
        }

        filtered.forEach((dataStream) => {
          flatData.push({
            label: dataStream.name,
            value: dataStream.id,
            type: dataStream.type,
            optionClass: 'dropdown-option',
          });
        });
      });

      const handleDropdownSelect = (val) => {
        this.setState({selectedStream: val, isStartEnabled: true});
      };
      const selectedIndex = flatData.findIndex((val) => val.value === this.state.selectedStream.value);
      return (
        <Modal
          className="new-cube-dialog-modal"
          show={isOpen}
          onHide={onClose}
          dialogClassName="bc new-create-cube-modal"
          backdrop="static"
        >
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <div>Create Cube</div>
              <div className="subtitle">Choose from which stream you would like to create a cube</div>
            </Modal.Title>
            <button type="button" className="btn btn-flat btn-icon-36 btn-secondary" onClick={onClose}>
              <i className="icon icn-icon-table-delete" />
            </button>
          </Modal.Header>

          <Modal.Body>
            <div styleName="root">
              <SelectAndt
                type={TYPE_SEARCH}
                theme={THEME_LIGHT}
                onChange={handleDropdownSelect}
                options={flatData}
                isLoading={isLoading}
                value={flatData[selectedIndex]}
                placeholder="Streams"
                optionHeight={40}
                menuListClass="newCube"
                getOptionLabel={(val) => val.label}
                getOptionValue={(val) => val.value}
                automationId="cubeStreams"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-raised"
              disabled={!this.state.isStartEnabled}
              onClick={() => onStartClicked(this.state.selectedStream.value)}
            >
              START
            </button>
          </Modal.Footer>
        </Modal>
      );
    }
  },
);
