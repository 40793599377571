// @flow
import React, {Fragment} from 'react';
import {
  ROLLUP_AGGREGATION_FUNCTION,
  ROLLUP_PERIODS,
  getMaxRollupPeriodPerPollingInterval,
} from 'bc/services/datadogService';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

import './DatadogSchedularModal.module.scss';

type PropTypes = {
  disabled: boolean,
  pollingInterval: string,
  rollupAggregation: string,
  resolutionMinutes: number,
  onRollupFunctionChange: Function,
  onRollupPeriodChange: Function,
};

export default class DatadogRollup extends React.PureComponent {
  props: PropTypes;

  getFilteredRollupPeriodsValues = () => {
    const maxVal = getMaxRollupPeriodPerPollingInterval(this.props.pollingInterval) || null;
    if (!maxVal) {
      return [];
    }
    return ROLLUP_PERIODS.filter((period) => {
      return period.value <= maxVal;
    });
  };

  render() {
    const {resolutionMinutes, rollupAggregation, onRollupFunctionChange, onRollupPeriodChange, disabled} = this.props;

    const rollupFuncSelectedIndex = ROLLUP_AGGREGATION_FUNCTION.findIndex((val) => val.value === rollupAggregation);

    const filteredRollupPeriodsValues = this.getFilteredRollupPeriodsValues();
    const rollupPeriodSelectedIndex = filteredRollupPeriodsValues.findIndex((val) => val.value === resolutionMinutes);

    return (
      <Fragment>
        <div styleName="itemContainer">
          <div styleName="titleWrapper">Rollup Function:</div>
          <div>
            <SelectAndt
              id="schedulerEditorModalDatadogRollupFunction"
              automationId="schedulerEditorModalDatadogRollupFunction"
              options={ROLLUP_AGGREGATION_FUNCTION}
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_NOT_HIGHLIGHTED}
              value={ROLLUP_AGGREGATION_FUNCTION[rollupFuncSelectedIndex]}
              onChange={(val) => onRollupFunctionChange(val.value)}
              placeholder=""
              optionHeight={40}
              buttonWidth={180}
              isClearable={false}
              disabled={disabled}
            />
          </div>
        </div>

        <div styleName="titleWrapper">Rollup Period:</div>
        <SelectAndt
          id="schedulerEditorModalDatadogRollupPeriod"
          automationId="schedulerEditorModalDatadogRollupPeriod"
          options={filteredRollupPeriodsValues}
          type={TYPE_NEW_NO_SEARCH}
          theme={THEME_NOT_HIGHLIGHTED}
          value={filteredRollupPeriodsValues[rollupPeriodSelectedIndex]}
          onChange={(val) => onRollupPeriodChange(val.value)}
          placeholder=""
          optionHeight={40}
          buttonWidth={180}
          isClearable={false}
          disabled={disabled}
        />
      </Fragment>
    );
  }
}
