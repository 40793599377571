import {get, post, put, stringifyQS} from 'common/utils/http';

const root = 'api/v1/channels';

export const fetchChannels = () => get(root);
export const postChannel = ({payload}) => post(`${root}/${payload.channelType}`, payload.body);
export const putChannel = ({payload}) => put(`${root}/${payload.channelType}/${payload.channelId}`, payload.body);
export const bulkPutChannels = ({payload}) => put(`${root}/bulk`, payload.body);
export const testChannel = ({payload}) =>
  post(`${root}/${payload.channelType}/test?${stringifyQS({channelId: payload.channelId})}`, payload.body);
export const createInitialConnection = ({payload}) => get(`${root}/jira/projects?${stringifyQS(payload)}`);
