export const regionOptions = [
  {value: 'AU', text: 'AU'},
  {value: 'CA', text: 'CA'},
  {value: 'DE', text: 'DE'},
  {value: 'EU', text: 'EU'},
  {value: 'FED', text: 'FED'},
  {value: 'IN', text: 'IN'},
  {value: 'JP', text: 'JP'},
  {value: 'US1', text: 'US1'},
  {value: 'US2', text: 'US2'},
];

export const getEditorValidationMessage = (stream) => {
  if (!stream.dimensions || !stream.dimensions.length) {
    return 'At least one dimension is required';
  }
  if (!stream.metrics || !stream.metrics.length) {
    return 'At least one measure is required';
  }
  return null;
};
