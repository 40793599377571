import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';

import useHandleResize from 'common/hooks/useHandleResize';
import PageLayout from 'common/componentsV2/PageLayout';
import Tabs from 'dataCollectors/pages/eventStream/previewPages/Tabs';
import Overview from 'dataCollectors/pages/eventStream/previewPages/Overview';
import HistoryLog from 'dataCollectors/pages/eventStream/previewPages/HistoryLog';
import Settings from 'dataCollectors/pages/eventStream/previewPages/Settings';
import StreamHeaderPreview from 'dataCollectors/components/StreamHeader/StreamHeaderPreview';
import {PREVIEW_TABS} from 'dataCollectors/services/dataCollectorsService';

const headerHeight = 127;
const footerHeight = 60;

function PageSelector({activeTabId, bodyHeight}) {
  const pageSelector = useMemo(() => {
    switch (activeTabId) {
      case PREVIEW_TABS.overview.id:
        return <Overview />;
      case PREVIEW_TABS.historyLog.id:
        return <HistoryLog bodyHeight={bodyHeight} />;
      case PREVIEW_TABS.settings.id:
        return <Settings />;
      default:
        return <Overview />;
    }
  }, [activeTabId]);

  return <>{pageSelector}</>;
}

PageSelector.propTypes = {
  activeTabId: PropTypes.string.isRequired,
  bodyHeight: PropTypes.number.isRequired,
};

const EventStreamEdit = () => {
  const [activeTabId, setActiveTabId] = useState(PREVIEW_TABS.overview.id);
  const bodyContainerHeight = useHandleResize([headerHeight, footerHeight]);

  return (
    <PageLayout header={<StreamHeaderPreview />}>
      <Tabs activeTabId={activeTabId} onSetActiveTabId={(id) => setActiveTabId(id)} />
      <div style={{height: bodyContainerHeight}}>
        <PageSelector activeTabId={activeTabId} bodyHeight={bodyContainerHeight} />
      </div>
    </PageLayout>
  );
};

export default EventStreamEdit;
