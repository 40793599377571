import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {palette} from 'app/styles/theme';

import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {getGroups, getAccessListChannelsData, getAccessListChannelsIsLoading} from 'admin.permissions/store/selectors';
import {fetchGroupsIsLoading} from 'admin.users/store/selectors';
import {fetchAccessListForAllChannels} from 'admin.permissions/store/actions';
import {ACCESS_LIST_CHANNELS_OPTIONS} from 'admin.permissions/services/accessListService';
import RadioButtonsList from 'common/componentsV2/RadioButtonsList';

const AccessGroups = ({channelId, onUpdateContainer}) => {
  const dispatch = useDispatch();
  const allAccessList = useSelector(getAccessListChannelsData);
  const accessListIsLoading = useSelector(getAccessListChannelsIsLoading);

  const groups = useSelector(getGroups);
  const groupsIsLoading = useSelector(fetchGroupsIsLoading);

  const [selectedGroups, setSelectedGroups] = useState([]); // DropDown
  const [accessListOption, setAccessListOption] = useState({}); // Radio Btns selected option
  const [accessListOptions, setAccesListOptions] = useState([]); // Radio Btns options
  const [displaySelectedGroups, setDisplaySelectedGroups] = useState(false);

  const update = (groupsIds) => {
    onUpdateContainer(groupsIds);
  };

  const syncWithServer = () => {
    const accessList = channelId ? allAccessList[channelId] : null;

    if (!accessList || accessList[0] === ACCESS_LIST_CHANNELS_OPTIONS.EVERYONE.payload) {
      setAccessListOption(ACCESS_LIST_CHANNELS_OPTIONS.EVERYONE);
      setSelectedGroups([]);
      return;
    }
    setAccessListOption(ACCESS_LIST_CHANNELS_OPTIONS.SELECTED_GROUPS);
    setSelectedGroups(groups.filter((i) => accessList.find((j) => i.value === j)));
  };

  const onSelectGroups = (selectedItems) => {
    setSelectedGroups(selectedItems);
  };

  const onSelectOptions = (selectedOption) => {
    setAccessListOption(selectedOption);
  };

  useEffect(() => {
    dispatch(fetchAccessListForAllChannels());
  }, []);

  useEffect(() => {
    if (accessListIsLoading === false && groupsIsLoading === false) {
      syncWithServer();
    }
  }, [accessListIsLoading, groupsIsLoading]);

  useEffect(() => {
    if (accessListOption.value === ACCESS_LIST_CHANNELS_OPTIONS.SELECTED_GROUPS.value) {
      setDisplaySelectedGroups(true);
    } else {
      setDisplaySelectedGroups(false);
    }
  }, [accessListOption]);

  useEffect(() => {
    setAccesListOptions(
      Object.keys(ACCESS_LIST_CHANNELS_OPTIONS).map((key) => ({
        value: ACCESS_LIST_CHANNELS_OPTIONS[key].value,
        label: ACCESS_LIST_CHANNELS_OPTIONS[key].title,
        info: ACCESS_LIST_CHANNELS_OPTIONS[key].info,
        disabled: accessListIsLoading || groupsIsLoading,
      })),
    );
  }, [accessListIsLoading, groupsIsLoading]);

  useEffect(() => {
    if (accessListOption.value === ACCESS_LIST_CHANNELS_OPTIONS.EVERYONE.value) {
      update([ACCESS_LIST_CHANNELS_OPTIONS.EVERYONE.payload]);
    }
    if (accessListOption.value === ACCESS_LIST_CHANNELS_OPTIONS.SELECTED_GROUPS.value) {
      update(selectedGroups.length ? selectedGroups.map((i) => i.value) : ['_']);
    }
  }, [selectedGroups, accessListOption]);

  return (
    <Fragment>
      <span style={{fontWeight: '500'}}>Access Settings</span>
      <div style={{marginTop: 8, marginBottom: 18}}>
        <RadioButtonsList
          options={accessListOptions}
          value={accessListOption.value}
          onChange={onSelectOptions}
          direction="column"
        />
      </div>

      {displaySelectedGroups && (
        <Fragment>
          <span>Who can access</span>
          <SelectAndt
            noAutoReorder
            type={TYPE_NEW_MULTI}
            theme={THEME_NOT_HIGHLIGHTED}
            isClearable={false}
            closeMenuOnSelect={false}
            onChange={onSelectGroups}
            options={groups}
            buttonWidth={225}
            optionHeight={40}
            isMulti
            value={selectedGroups}
            placeholder={accessListIsLoading || groupsIsLoading ? 'Loading..' : 'None'}
            automationId="EditAccessListCreatedStreamFromCreateStreamPage"
          />
          <div style={{width: 397, height: 1, backgroundColor: palette.gray['300'], marginTop: 16}} />
        </Fragment>
      )}
    </Fragment>
  );
};

AccessGroups.propTypes = {
  channelId: PropTypes.string.isRequired,
  onUpdateContainer: PropTypes.func.isRequired,
};

export default AccessGroups;
