// @flow
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';
import {getAlertEditorV2Enabled} from 'alerts.management/store/selectors';
import {segmentClickEvent} from 'common/store/actions';
import {
  getImpactEnabled,
  getInvestigationV2Enabled,
  getIsImpersonate,
  getStaticNoDataTriageEnabled,
} from 'profile/store/selectors';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {modalRouting as investigationRouting} from 'investigation/services/investigationService';
import {modalRouting1 as impactRouting1, modalRouting2 as impactRouting2} from 'impact/services/constants';
import './MoreDdlActions.module.scss';

type PropTypes = {
  id: string,
  triggerId: string,
  alertConfigurationId: string,
  anomalyId: string,
  timeScale: string,
  impact: Object,
  isImpactEligible: boolean,
  origin: Object,
  measure: String,
};

const MoreDdlActions = ({
  triggerId,
  anomalyId,
  impact,
  origin,
  measure,
  alertConfigurationId,
  timeScale,
  id,
  isImpactEligible,
}: PropTypes) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAlertEditorV2Enabled = useSelector(getAlertEditorV2Enabled);
  const isImpactEnabled = useSelector(getImpactEnabled);
  const isStaticNoDataTriageEnabled = useSelector(getStaticNoDataTriageEnabled);
  const isInvestigationV2Enabled = useSelector(getInvestigationV2Enabled);
  const isImpersonating = useSelector(getIsImpersonate);

  const ORIGIN_SOURCE_IDS_TYPE = 'STREAM';

  const [, setQueryParams] = useQueryParams({
    [investigationRouting.ANOMALY_ID]: StringParam,
    [investigationRouting.TRIGGER_ID]: StringParam,
    [investigationRouting.INVESTIGATION_MODAL]: BooleanParam,

    [impactRouting1.IMPACT_MODAL]: BooleanParam,
    [impactRouting1.ORIGIN_ID]: StringParam,
    [impactRouting1.ORIGIN_TYPE]: StringParam,
    [impactRouting1.ALERT_TRIGGERED_ID]: StringParam,
    [impactRouting1.MEASURE]: StringParam,
    [impactRouting2.IMPACT_ID]: StringParam,
  });

  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);

  const setImpactUrl = () => {
    setQueryParams({
      [impactRouting1.IMPACT_MODAL]: true,
      [impactRouting1.ALERT_TRIGGERED_ID]: triggerId,
      [impactRouting1.ORIGIN_ID]: origin,
      [impactRouting1.ORIGIN_TYPE]: ORIGIN_SOURCE_IDS_TYPE,
      [impactRouting1.MEASURE]: !impact ? measure : undefined,
      [impactRouting2.IMPACT_ID]: impact ? impact.impactId : undefined,
    });
  };

  const setInvestigationUrl = () => {
    setQueryParams({
      [investigationRouting.ANOMALY_ID]: anomalyId || triggerId,
      [investigationRouting.TRIGGER_ID]: triggerId,
      [investigationRouting.INVESTIGATION_MODAL]: true,
    });
  };

  const actionItemSelected = () => {
    switch (value) {
      case 'tuneAlert':
        if (isAlertEditorV2Enabled) {
          history.push(
            `/alert-manager/edit/${alertConfigurationId}/settings?backUrl=${
              history.location.pathname
            }${encodeURIComponent(history.location.search)}`,
          );
        } else {
          window.open(`/#!/alert/${alertConfigurationId}`, `_newtab-${alertConfigurationId}`);
        }
        break;
      case 'investigate':
        window.open(
          `/#!/anomalies?tabs=main;0&activeTab=1&anomalies=;0(${anomalyId})&duration=;1(1)&durationScale=;minutes(minutes)&delta=;1(1)&deltaType=;percentage(percentage)&resolution=;${timeScale}(${timeScale})&score=;0(0)&state=;both(both)&direction=;both(both)&alertId=;(${triggerId})&sort=;significance(significance)&q=;()&constRange=;1h(c)&startDate=;0(0)&endDate=;0(0)`,
          '_newtab',
        );
        break;
      case 'setImpact':
        setImpactUrl();
        break;
      case 'investigateV2':
        setInvestigationUrl(anomalyId, triggerId);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    actionItemSelected();
    if (value) {
      dispatch(
        segmentClickEvent({
          type: 'click',
          name: value,
        }),
      );
    }
  }, [value]);

  const getSelectOptions = () => {
    const ret = [];

    if (anomalyId) {
      ret.push({value: 'investigate', label: 'Investigate'});
    }
    if (isStaticNoDataTriageEnabled || isInvestigationV2Enabled || isImpersonating) {
      ret.push({value: 'investigateV2', label: 'Triage'});
    }
    if (isImpactEnabled && isImpactEligible) {
      ret.push({value: 'setImpact', label: 'Edit Impact'});
    }

    ret.push({value: 'tuneAlert', label: 'Edit Alert'});
    return ret;
  };

  useEffect(() => {
    setOptions(getSelectOptions());
  }, []);

  return (
    <div styleName="more-action-wrapper" role="presentation">
      <FormDdlSelect
        automationId={`more-actions-ddl-${id}`}
        className="alert-clickable-item"
        position="right"
        width={135}
        options={options}
        selected={value}
        button={<div styleName="button-like-dropdown" className="icon icn-icon-3-dots alert-clickable-item" />}
        optionComponent={<OptionComponentSimple />}
        onChange={(e) => setValue(e.value)}
      />
    </div>
  );
};

export default MoreDdlActions;
