// @flow
import React, {useState} from 'react';
import {deleteFeedback, setAlertFeedbackPost as setFeedback, updateFeedback} from 'feedback/services/api';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import Feedback from 'feedback/FeedbackNew';
import {FEEDBACK_ORIGIN, FEEDBACK_TYPE, feedbackReasons} from 'feedback/services/constants';
import {FEEDBACK_ACTION_TYPES} from 'alerts.console.new/api/triggeredAlertsCacheUpdate';
import {queryErrorHandler} from 'reactQuery/queryClient';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import fetchGroupAlertsWithMetricsProvider from 'investigationNewAlertConsole/api/fetchGroupAlertsWithMetricsProvider';
import {modalRouting} from 'investigationNewAlertConsole/services/investigationService';
import fetchTriggeredAlertsNoFeedbackProvider from 'insightsPanelNewAlertConsole/api/fetchTriggeredAlertsNoFeedbackProvider';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';

const FeedbackWrapper = ({
  trigger,
  alertGroupId,
  isRowOpen,
  meId,
  onMouseOver,
  queryParams,
}: {
  trigger: Object,
  alertGroupId: String,
  isRowOpen: Boolean,
  meId: Object,
  onMouseOver: Boolean,
  queryParams: Object,
}) => {
  const users = fetchUsersProvider().useQuery();
  const meUser = users?.data?.find((user) => user.id === meId);

  const triggeredAlertsProvider = fetchTriggeredAlertsProvider(queryParams);

  // Need to update the trigger of triage react query cache
  const investigationModalCacheParams = {
    [modalRouting.ANOMALY_ID]: alertGroupId,
    [modalRouting.TRIGGER_ID]: trigger?.alertTriggerId,
    [modalRouting.INVESTIGATION_MODAL]: true,
  };
  const triggeredAlertGroupProvider = fetchGroupAlertsWithMetricsProvider(investigationModalCacheParams);
  const triggeredAlertsNoFeedbackProvider = fetchTriggeredAlertsNoFeedbackProvider();

  const [currentFeedbackId, setCurrentFeedbackId] = useState();
  const [isFeedbackUpdating, setIsFeedbackUpdating] = useState(false);

  const setIsFeedbackByMe = (type) => {
    const feedbackByMe = trigger?.feedback?.find((f) => f.userId === meId);
    if (feedbackByMe) {
      return feedbackByMe.type === FEEDBACK_TYPE[type];
    }
    return false;
  };

  const setFeedbacks = (type) => {
    return trigger?.feedback?.filter((f) => f.type === FEEDBACK_TYPE[type] && f.userId !== meId);
  };

  const othersGoodCatch = setFeedbacks(FEEDBACK_TYPE.GOOD_CATCH);
  const otherNotInteresting = setFeedbacks(FEEDBACK_TYPE.NOT_INTERESTING);
  const isGoodCatchByMe = setIsFeedbackByMe(FEEDBACK_TYPE.GOOD_CATCH);
  const isNotInterestingByMe = setIsFeedbackByMe(FEEDBACK_TYPE.NOT_INTERESTING);

  const setFeedbackParams = (type) => ({
    type,
    userId: meId,
    triggerIds: [trigger?.alertTriggerId],
    origin: FEEDBACK_ORIGIN.ALERT_CONSOLE,
    userName: meUser.fullName || 'Unknown',
  });

  const setGoodCatchOrNotInteresting = async (feedbackType) => {
    const params = setFeedbackParams(feedbackType);
    triggeredAlertsProvider.syncFeedbackInCache(trigger?.alertTriggerId, FEEDBACK_ACTION_TYPES.SET, params);
    try {
      const res = await setFeedback({payload: params}).promise;
      if (res?.id) {
        setCurrentFeedbackId(res.id);
        params.feedbackId = res.id;
        triggeredAlertsProvider.syncFeedbackInCache(trigger?.alertTriggerId, FEEDBACK_ACTION_TYPES.SET, params);
      }
      await triggeredAlertsProvider.resetAllExceptCurrent();
      await triggeredAlertGroupProvider.invalidate();
      await triggeredAlertsNoFeedbackProvider.reset(QUERY_KEYS.triggeredAlertsInsightPanelNoFeedback);
    } catch (e) {
      const error = {
        title: 'Feedback error',
        description: `Error while setting ${feedbackType} feedback`,
      };
      queryErrorHandler(error);
      // Need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
      await triggeredAlertsProvider.invalidate();
    }
  };

  const deleteGoodCatchOrNotInteresting = async (feedbackType) => {
    const feedback = trigger?.feedback?.find((f) => f.userId === meId);
    if (!feedback?.id) {
      return;
    }
    const deleteFeedbackParams = {
      userId: meId,
      feedbackId: feedback.id,
    };
    triggeredAlertsProvider.syncFeedbackInCache(
      trigger?.alertTriggerId,
      FEEDBACK_ACTION_TYPES.DELETE,
      deleteFeedbackParams,
    );
    try {
      await deleteFeedback({payload: feedback.id}).promise;
      setCurrentFeedbackId();
      await triggeredAlertsProvider.resetAllExceptCurrent();
      await triggeredAlertGroupProvider.invalidate();
      await triggeredAlertsNoFeedbackProvider.reset(QUERY_KEYS.triggeredAlertsInsightPanelNoFeedback);
    } catch (e) {
      const error = {
        title: 'Feedback error',
        description: `Error while deleting ${feedbackType} feedback`,
      };
      queryErrorHandler(error);
      // Need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
      await triggeredAlertsProvider.invalidate();
    }
  };

  const updateFeedbackComment = async (feedbackParams, feedbackType) => {
    const feedback = trigger?.feedback?.find((f) => f.userId === meId);
    if (!feedback) {
      return;
    }
    const comment = feedbackParams?.comment || '';
    const reason = feedbackParams?.reason || '';
    if (comment || reason) {
      setIsFeedbackUpdating(true);
    }
    const params = setFeedbackParams(feedbackType);
    params.feedbackId = feedback.id;

    const updateFeedbackParams = {
      ...params,
      type: feedbackType,
      comment,
      reason,
    };
    triggeredAlertsProvider.syncFeedbackInCache(
      trigger?.alertTriggerId,
      FEEDBACK_ACTION_TYPES.UPDATE,
      updateFeedbackParams,
    );
    try {
      await updateFeedback({
        payload: {
          ...params,
          id: feedback.id,
          comment,
          reason: reason ? feedbackReasons(feedbackType, trigger?.type).find((r) => r.value === reason)?.title : '',
        },
      }).promise;
      await triggeredAlertsProvider.resetAllExceptCurrent();
      await triggeredAlertGroupProvider.invalidate();
    } catch (e) {
      const error = {
        title: 'Feedback error',
        description: `Error while updating ${feedbackType} feedback from alert console`,
      };
      queryErrorHandler(error);
      // Need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
      await triggeredAlertsProvider.invalidate();
    } finally {
      setIsFeedbackUpdating(false);
    }
  };

  return isFeedbackUpdating ? (
    <Spinner color={palette.gray[500]} size={SIZES.SMALL_30} />
  ) : (
    <Feedback
      alertType={trigger?.type}
      isGoodCatchByMe={isGoodCatchByMe}
      isNotInterestingByMe={isNotInterestingByMe}
      othersGoodCatch={othersGoodCatch || []}
      otherNotInteresting={otherNotInteresting || []}
      onSetGoodCatch={() => setGoodCatchOrNotInteresting(FEEDBACK_TYPE.GOOD_CATCH)}
      onSetNotInteresting={() => setGoodCatchOrNotInteresting(FEEDBACK_TYPE.NOT_INTERESTING)}
      onRemoveGoodCatch={() => deleteGoodCatchOrNotInteresting(FEEDBACK_TYPE.GOOD_CATCH)}
      onRemoveNotInteresting={() => deleteGoodCatchOrNotInteresting(FEEDBACK_TYPE.NOT_INTERESTING)}
      onUpdateGoodCatch={(feedbackParams) => updateFeedbackComment(feedbackParams, FEEDBACK_TYPE.GOOD_CATCH)}
      onUpdateNotInteresting={(feedbackParams) => updateFeedbackComment(feedbackParams, FEEDBACK_TYPE.NOT_INTERESTING)}
      isGoodCatchDisplay={!!othersGoodCatch?.length || isGoodCatchByMe || !!onMouseOver || isRowOpen}
      isNotInterestingDisplay={!!otherNotInteresting?.length || isNotInterestingByMe || !!onMouseOver || isRowOpen}
      currentFeedbackId={currentFeedbackId}
    />
  );
};

export default FeedbackWrapper;
