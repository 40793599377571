// @flow
import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import {getAllowedRangeTypesByPollingInterval, getNotificationDelayOption} from 'bc/services/dataStreamService';
import Button, {COLORS} from 'common/componentsV2/Button';
import NotificationDelay from 'bc/components/streams/editor/scheduler/NotificationDelay';
import PollingInterval from 'bc/components/streams/editor/scheduler/PollingInterval';
import DelayMinutes from 'bc/components/streams/editor/scheduler/DelayMinutes';
import TimeZone from 'bc/components/streams/editor/scheduler/TimeZone';
import DateRange from 'bc/components/streams/editor/scheduler/DateRange';

import './GA4SchedularModal.module.scss';

type PropTypes = {
  setSelectedStreamKeyVal: Function,
  dataStream: Object,
  onClose: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)(
  class GA4SchedularModal extends React.PureComponent {
    props: PropTypes;

    state = {
      pollingInterval: this.props.dataStream.pollingInterval,
      delayMinutes: this.props.dataStream.delayMinutes,
      historicalDateRange: this.props.dataStream.historicalDateRange,
      timeZone: this.props.dataStream.timeZone,
      notificationDelay: this.props.dataStream.notificationDelay,
    };

    onCloseButtonClicked = () => {
      this.props.onClose();
    };

    doNothing = () => {};

    pollingChanged = (val) => {
      this.setState({pollingInterval: val});
      if (!getAllowedRangeTypesByPollingInterval(val).includes(this.state.historicalDateRange.constRange)) {
        this.historicalDateRangeChanged({constRange: getAllowedRangeTypesByPollingInterval(val)[0]});
      }
    };

    delayMinutesChanged = (val) => {
      this.setState({delayMinutes: val});
    };

    timeZoneChanged = (val) => {
      const isTimeZoneAlertOpen = false;
      let value = val;
      if (value === 'Browser') {
        value = moment.tz.guess();
      }
      this.setState({
        isTimeZoneAlertOpen,
        timeZone: value,
      });
    };

    historicalDateRangeChanged = (val) => {
      this.setState({historicalDateRange: val});
    };

    notificationDelayChanged = (val) => {
      this.setState({notificationDelay: val});
    };

    onSave = () => {
      this.props.setSelectedStreamKeyVal({
        delayMinutes: this.state.delayMinutes,
        pollingInterval: this.state.pollingInterval,
        historicalDateRange: this.state.historicalDateRange,
        timeZone: this.state.timeZone,
        notificationDelay: this.state.notificationDelay,
      });
      this.onCloseButtonClicked();
    };

    render() {
      return (
        <div styleName="modal-body">
          <div styleName="itemContainer">
            <PollingInterval
              pollingInterval={this.state.pollingInterval}
              allowedValues={['daily', 'hourly']}
              onChange={this.pollingChanged}
              title="Collect every"
            />
          </div>

          <div styleName="itemContainer1">
            <DelayMinutes delayMinutes={this.state.delayMinutes} onChange={this.delayMinutesChanged} maxVal={1440} />
          </div>

          <div styleName="itemContainer">
            <DateRange
              allowedRangeTypes={getAllowedRangeTypesByPollingInterval(this.state.pollingInterval)}
              dateRange={this.state.historicalDateRange}
              onChange={this.historicalDateRangeChanged}
              isUnix
              title="Collect data from"
            />
          </div>

          <div styleName="itemContainer">
            <TimeZone
              timeZone={this.state.timeZone}
              onChange={this.timeZoneChanged}
              warning={
                this.state.isTimeZoneAlertOpen
                  ? 'You have chosen a Timezone different than the data records’ timezone. This could result in ignored rows in the streaming process.'
                  : ''
              }
            />
          </div>

          <div styleName="itemContainer">
            <NotificationDelay
              notificationDelayOption={getNotificationDelayOption()}
              pollingInterval={this.state.pollingInterval}
              notificationDelay={this.state.notificationDelay}
              onChange={this.notificationDelayChanged}
            />
          </div>

          <div styleName="footer">
            <div styleName="left-button">
              <Button
                colorSchema={COLORS.GRAY_400}
                text="Cancel"
                automation-id="schedulerEditorModalCancel"
                onClick={this.onCloseButtonClicked}
              />
            </div>
            <div>
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Save"
                automation-id="schedulerEditorModalSave"
                onClick={this.onSave}
              />
            </div>
          </div>
        </div>
      );
    }
  },
);
