import {post, get, stringifyQS, delete_, put} from 'common/utils/http';

const root = 'api/v1/';
const alertsRoot = 'api/v1/alerts/';
const dashboardsRoot = 'api/v1/dashboards/';
const metricsRoot = 'api/v1/metrics/';

//* data source
export const fetchInfluencingMetricsInfo = ({payload}) => post(`${alertsRoot}influencing-metrics/info`, payload);
export const validateSelectedAlertConditions = ({payload}) => post(`${alertsRoot}validate/conditions`, payload);

//*  alerts from stream
export const createAlertsFromStreamApi = ({payload}) => post(`${alertsRoot}bulk/create/stream`, payload);

//*  alerts management
export const fetchAlertConfigurations = () => get(`${root}alerts`);

export const fetchTriggeredAlertsByConfiguration = ({payload}) => get(`${alertsRoot}triggered?${stringifyQS(payload)}`);

//*  alerts actions
export const createAlert = ({payload}) => post(`${alertsRoot}?${stringifyQS(payload.qs || {})}`, payload.body);
export const deleteAlert = ({payload}) => delete_(`${alertsRoot}${payload}`);
export const pauseAlert = ({payload}) => post(`${alertsRoot}${payload.id}/pause`, payload.request);
export const resumeAlert = ({payload}) => post(`${alertsRoot}${payload.id}/resume`);
export const subscribeToAlert = ({payload}) => post(`${alertsRoot}${payload.id}/subscription`, {muted: false});
export const unsubscribeToAlert = ({payload}) => delete_(`${alertsRoot}${payload.id}/subscription`);
export const editAlertBulkApi = ({payload}) => put(`${alertsRoot}bulk`, payload);
export const deleteAlertBulkApi = ({payload}) => delete_(`${alertsRoot}bulk`, payload);
export const executeEstimation = ({payload}) =>
  post(`api/v1/metrics/simulation/execute?${payload.urlExt}`, payload.body);
export const fetchAlertsConfigurationTotal = () => get(`${root}alerts/count`);
export const getAlertsTags = () => get(`${root}alerts/labels`);

//* dashboard action
export const fetchDashboard = ({payload}) =>
  get(`${dashboardsRoot}${payload.id}?schemaType=${payload.schemaType || 'dashboard'}`);

// names
export const fetchDataStreamMetricsCountApiCall = ({payload}) =>
  post(`${metricsRoot}composite/names?index=0&size=10`, payload);

export const getVolumeCondition = ({payload}) => post(`${alertsRoot}volumeCondition`, payload);

export const getVolumeConditionValidationOnly = ({payload}) =>
  post(`${alertsRoot}volumeCondition?validationOnly=true`, payload);

export const fetchAlert = ({payload}) => get(`${root}alerts/${payload.alertId}`);
export const updateAlert = ({payload}) =>
  put(`${root}alerts/${payload.alertId}?${stringifyQS(payload.qs || {})}`, payload.body);

export const simulationExecute = ({payload}) =>
  post(`${metricsRoot}simulation/execute?${payload.urlExt}`, payload.body);
