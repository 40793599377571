// @flow
import React from 'react';
import {ReactComponent as AlertConsole} from 'common/componentsV2/holiday/christmas/images/alert-console.svg';
import {ReactComponent as Anomalies} from 'common/componentsV2/holiday/christmas/images/anomalies.svg';
import {ReactComponent as Catalog} from 'common/componentsV2/holiday/christmas/images/catalog.svg';
import {ReactComponent as Dashboard} from 'common/componentsV2/holiday/christmas/images/dashboard.svg';
import {ReactComponent as Integrations} from 'common/componentsV2/holiday/christmas/images/integrations.svg';
import {ReactComponent as Management} from 'common/componentsV2/holiday/christmas/images/management.svg';
import {ReactComponent as MetricExplorer} from 'common/componentsV2/holiday/christmas/images/metric-explorer.svg';
import {ReactComponent as User} from 'common/componentsV2/holiday/christmas/images/user.svg';
import {ReactComponent as Logo} from 'common/componentsV2/holiday/christmas/images/logo-anodot.svg';
import {ReactComponent as Topology} from 'common/componentsV2/holiday/christmas/images/topology.svg';
import {ReactComponent as AlertItem} from 'common/componentsV2/holiday/christmas/images/alert-item.svg';
import {ReactComponent as Modal} from 'common/componentsV2/holiday/christmas/images/modal.svg';

const ChristmasIcons = ({title}: {title: string}) => {
  const renderBody = () => {
    switch (title) {
      case 'Logo':
        return <Logo width={70} height={70} />;
      case 'Alert Console':
        return <AlertConsole />;
      case 'Anomalies':
        return <Anomalies />;
      case 'Metric Explorer':
        return <MetricExplorer />;
      case 'Dashboards':
        return <Dashboard />;
      case 'Catalog':
        return <Catalog />;
      case 'Integrations':
        return <Integrations />;
      case 'Management':
        return <Management />;
      case 'Topology':
        return <Topology />;
      case 'User':
        return <User />;
      case 'AlertItemIcon':
        return <AlertItem />;
      case 'Modal':
        return <Modal className="alert-clickable-item" />;
      default:
        return null;
    }
  };
  return renderBody();
};

export default ChristmasIcons;
