// @flow
import React from 'react';
import Input from 'common/componentsV2/Input';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import {RELATIVE_OPTIONS} from 'common/componentsV2/customDateRangeV2/customDateRangeService';

type PropsType = {
  value: Object,
  onChange: Function,
  scaleValue: String,
  onChangeScaleValue: Function,
  disabled: boolean,
  invalid: boolean,
};

const RelativeField = ({value, onChange, scaleValue, onChangeScaleValue, disabled, invalid}: PropsType) => (
  <>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      <Input
        type="number"
        automationId="customDateRangeRelativeField"
        onChange={(e) => onChange(e.target.value)}
        isInvalid={invalid}
        style={{height: 36, width: 55, marginRight: 10, textAlign: 'center', paddingRight: '0px', paddingLeft: '0px'}}
        value={value}
        fullSize
        tabIndex={disabled ? '-1' : null}
      />
      <FormDdlSelect
        automationId="customDateRangeRelativeScale"
        options={RELATIVE_OPTIONS}
        selected={RELATIVE_OPTIONS.find((item) => item.value === scaleValue)}
        onChange={(item) => onChangeScaleValue(item.value)}
        optionComponent={<OptionComponentSimple />}
        width={180}
        button={
          <div style={{width: 126}}>
            <BigDropdownButton
              isPristine
              shortBlueStyle
              isFullWidth
              placeholder={RELATIVE_OPTIONS.find((item) => item.value === scaleValue).label}
            />
          </div>
        }
      />
    </div>
  </>
);

export default RelativeField;
