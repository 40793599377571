import {isEmpty} from 'lodash';

const removeIdsFromEventProperties = (eventProperties) => {
  const newEventProperties = {};

  if (!eventProperties || isEmpty(eventProperties)) {
    return null;
  }

  Object.entries(eventProperties).forEach(([key, value]) => {
    newEventProperties[key] = {sourceColumn: value.sourceColumn};
  });

  return newEventProperties;
};

export const EVENT_STREAM_FIELDS = {
  STREAM_FAMILY: {
    id: 'family',
  },
  CREATOR: {id: 'creator'},
  CREATE_TIME: {id: 'createTime'},
  MODIFIER: {id: 'modifier'},
  MODIFIER_TIME: {id: 'modifyTime'},
  SOURCE_ID: {
    id: 'dataSourceId',
  },
  SOURCE_NAME: {
    id: 'sourceName',
  },
  DATABASE_NAME: {
    id: 'dbName',
    name: 'Database name',
  },
  TYPE: {
    id: 'type',
  },
  STREAM_ID: {
    id: 'id',
  },
  STREAM_NAME: {
    id: 'name',
    step: 0,
    section: 0,
    percentage: 10,
  },
  STREAM_STATE: {
    id: 'state',
  },
  STREAM_PAUSED: {
    id: 'paused',
  },
  STREAM_STATUS: {
    id: 'status',
  },
  STREAM_OWNER: {
    id: 'owner',
    name: 'Owner',
    step: 0,
    section: 0,
    percentage: 10,
  },
  STREAM_DESCRIPTION: {
    id: 'description',
    step: 0,
    section: 0,
  },
  SCHEMA_NAME: {
    id: 'schemaName',
    name: 'Schema',
    step: 0,
    section: 1,
    percentage: 5,
  },
  TABLE_NAME: {
    id: 'tableName',
    name: 'Table',
    step: 0,
    section: 1,
    percentage: 5,
  },
  SOURCE_COLUMNS: {
    id: 'sourceColumns',
  },
  EVENT_TITLE: {
    step: 0,
    section: 2,
    id: 'eventTitle',
    percentage: 5,
    name: 'Title',
  },
  EVENT_DESCRIPTION: {
    id: 'eventDescription',
    step: 0,
    section: 2,
    name: 'Description',
  },
  EVENT_TYPE: {
    id: 'eventType',
    step: 0,
    section: 2,
    name: 'Type',
  },
  EVENT_ACTION: {
    id: 'eventAction',
    step: 0,
    section: 2,
    name: 'Action',
  },
  EVENT_SOURCE: {
    id: 'eventSource',
    step: 0,
    section: 2,
    percentage: 5,
    name: 'Source',
  },
  EVENT_CATEGORY: {
    id: 'eventCategory',
    step: 0,
    section: 2,
    percentage: 5,
    name: 'Category',
  },
  EVENT_START_TIME: {
    id: 'eventStart',
    step: 0,
    section: 2,
    name: 'StartDate',
  },
  EVENT_END_TIME: {
    id: 'eventEnd',
    step: 0,
    section: 2,
    name: 'EndDate',
  },
  EVENT_PROPERTIES: {
    id: 'eventProperties',
    step: 0,
    section: 2,
    name: 'Properties',
  },
  EVENT_PROGRESS_INDICATOR: {
    id: 'eventProgressIndicator',
    step: 0,
    section: 2,
    name: 'Progress indicator',
    percentage: 5,
  },
  EVENT_KEY: {
    id: 'eventKey',
    step: 0,
    section: 2,
    name: 'Event Key',
  },
  POLLING_INTERVAL: {
    id: 'pollingInterval',
    step: 0,
    section: 3,
    name: 'Collect Every',
  },
  HISTORY_DATE_RANGE: {
    id: 'historicalDateRange',
    step: 0,
    section: 3,
    name: 'Collect Since',
  },
  TIMEZONE: {
    id: 'timeZone',
    step: 0,
    section: 3,
    name: 'Time zone',
  },
  TRANSFORM_TABLE: {
    id: 'transformTable',
    step: 1,
  },
  NOTIFY_ON_NO_DATA: {
    id: 'notificationDelay',
    step: 2,
    section: 1,
    name: 'Notifications on no-data',
  },
  NOTIFY_ON_ERRORS: {
    id: 'notificationsOnErrors',
    step: 2,
    section: 1,
    name: 'Notifications on errors',
  },
  NOTIFY_RECIPIENTS: {
    id: 'notificationsRecipients',
    step: 2,
    section: 1,
    name: 'Recipients',
  },
  FILE_NAME: {
    id: 'fileName',
  },
  FILE_FORMAT: {
    id: 'fileFormat',
  },
  START_TIME_DEFINITION: {
    id: 'startTimeDefinition',
    step: 0,
    section: 2,
  },
  END_TIME_DEFINITION: {
    id: 'endTimeDefinition',
    step: 0,
    section: 2,
  },
  PROJECT_ID: {
    id: 'projectId',
    step: 0,
    section: 1,
  },
  DATA_SET: {
    id: 'tableDataset',
    step: 0,
    section: 1,
  },
  CUSTOM_QUERY: {
    id: 'customQuery',
  },
};

export const EVENT_STREAM_TYPES = {
  LOCAL_FILE: 'local_file',
  BIG_QUERY: 'bigquery',
  PSQL: 'psql',
};
export const setCleanStream = (stream) => {
  return {
    ...stream,
    eventProperties: removeIdsFromEventProperties(stream.eventProperties),
  };
};
