// @flow
import React from 'react';
import './AlertTriggerPanel.module.scss';
import connect from 'react-redux/es/connect/connect';
import {fetchTriggeredAlertsByConfiguration} from 'alerts.management/store/actions';
import * as selectors from 'alerts.management/store/selectors';
import {getBucketStartTimeEnabled} from 'profile/store/selectors';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';
import AlertTriggersTable from './AlertTriggersTable';

type PropTypes = {
  alert: Object,
  fetchTriggeredAlertsByConfiguration: Function,
  isLoading: boolean,
  triggers: Array,
  totalTriggers: Number,
  startBucketMode: boolean,
};

export default connect(
  (state) => ({
    isLoading: selectors.getTriggeredAlertsByConfigurationIsLoading(state),
    triggers: selectors.getTriggeredAlertsByConfigurationList(state),
    totalTriggers: selectors.getTriggeredAlertsByConfigurationCount(state),
    startBucketMode: getBucketStartTimeEnabled(state),
  }),
  {
    fetchTriggeredAlertsByConfiguration,
  },
)(
  class AlertTriggerPanel extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const thirtyDaysTimestamp = Math.floor(Date.now() / 1000) - 60 * 60 * 24 * 30;

      const queryAPI = {
        startTime: thirtyDaysTimestamp,
        alertConfigurationIds: this.props.alert.id,
        startBucketMode: this.props.startBucketMode,
      };

      this.props.fetchTriggeredAlertsByConfiguration(queryAPI);
    }

    render() {
      const {isLoading, triggers, totalTriggers, alert} = this.props;

      return (
        <div styleName="container">
          <div styleName="column column-1">
            {isLoading ? (
              <div styleName="spinner">
                <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
              </div>
            ) : (
              <AlertTriggersTable triggers={triggers} totalTriggers={totalTriggers} alertId={alert.id} />
            )}
          </div>
        </div>
      );
    }
  },
);
