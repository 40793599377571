import React, {useState, useEffect, memo} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import {Box, makeStyles} from '@material-ui/core';
import {
  getFetchTriggeredAlertIsLoading,
  getInvModalTrigger,
  getMoreAlertsInAnomalyGroup,
} from 'investigation/store/selectors';
import {ReactComponent as StatusIcon} from 'investigation/images/open_alert.svg';
import Button, {COLORS} from 'common/componentsV2/Button';
import {palette} from 'app/styles/theme';
import {StringParam, useQueryParams} from 'use-query-params';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {Severity} from '../tabs/Badges';
import FeedbackContainer from './FeedbackContainer';
import Acknowledged from './Acknowledged';
import MoreActions from './MoreActions';

import './InvModalHeader.module.scss';

const useActionBarStyles = makeStyles(() => ({
  iconBtn: {
    height: '36px',
    width: '36px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.gray['500'],
  },
}));

const InvModalHeader = ({onShareClicked}: PropTypes) => {
  const [, setQueryParams] = useQueryParams({
    triggerId: StringParam,
  });

  const classes = useActionBarStyles();
  const trigger = useSelector(getInvModalTrigger);
  const isLoading = useSelector(getFetchTriggeredAlertIsLoading);
  const alertsGroupIds = useSelector(getMoreAlertsInAnomalyGroup);
  const [currentTriggerIndex, setCurrentTriggerIndex] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [isPrev, setIsPrev] = useState(false);

  useEffect(() => {
    if (alertsGroupIds?.length > 1) {
      const triggerIndex = alertsGroupIds.findIndex((id) => id === trigger.id);
      setCurrentTriggerIndex(triggerIndex);
      setIsNext(alertsGroupIds.length > triggerIndex + 1);
      setIsPrev(triggerIndex > 0);
    }
  }, [trigger, alertsGroupIds]);

  const nextTrigger = () => {
    if (isNext) {
      setQueryParams({
        triggerIdInv: alertsGroupIds[currentTriggerIndex + 1],
      });
    }
  };

  const prevTrigger = () => {
    if (isPrev) {
      setQueryParams({
        triggerIdInv: alertsGroupIds[currentTriggerIndex - 1],
      });
    }
  };

  if (isLoading || isEmpty(trigger)) {
    return <Box className={classes.main} />;
  }

  return (
    <div styleName="main">
      <div styleName="title">
        {alertsGroupIds?.length > 1 && (
          <div styleName="nav">
            <i
              onClick={prevTrigger}
              styleName="iconArrow"
              className="icon icn-arrow16-triangleleft"
              style={{
                opacity: isPrev ? '1' : '0.45',
                cursor: isPrev ? 'pointer' : 'default',
              }}
            />

            <div styleName="alertsTitle">
              {currentTriggerIndex + 1}/{alertsGroupIds.length} Alerts
            </div>
            <i
              onClick={nextTrigger}
              styleName="iconArrow"
              className="icon icn-arrow16-triangleright"
              style={{
                opacity: isNext ? '1' : '0.45',
                cursor: isNext ? 'pointer' : 'default',
              }}
            />
          </div>
        )}
        <div styleName="trigger-title-container">
          <div styleName="status-icon-container">
            {trigger.status?.toLowerCase() === 'open' ? <StatusIcon /> : <div />}
          </div>
          <Tooltip placement="top" content={trigger.title} type={TYPES.SMALL} delay={50}>
            <div styleName="trigger-title" className="ellipsis">
              {trigger.title}
            </div>
          </Tooltip>
          <Severity level={trigger.severity} />
        </div>
      </div>

      <div styleName="acknowledge-container">
        <Acknowledged />
        <FeedbackContainer />
        <div styleName="share-button-container">
          <Button
            colorSchema={COLORS.GRAY_300}
            icon="icn-Share24"
            extraClassName={classes.iconBtn}
            onClick={onShareClicked}
          />
        </div>
        <MoreActions />
      </div>
    </div>
  );
};

InvModalHeader.propTypes = {
  onShareClicked: PropTypes.func.isRequired,
};

export default memo(InvModalHeader);
