// @flow
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

import useUserSettingsStyles from 'userSettings/pages/useUserSettings.styles';
import SubTitle from '../SubTitle';

const useStyles = makeStyles(() => ({
  emailField: {width: 200, marginTop: 24},
}));

type PropsType = {
  email: string,
};

const isRequired = (val) => val === undefined;

const PersonalInfoSettings = ({email}: PropsType) => {
  const classes = useStyles();
  const userSettingsClasses = useUserSettingsStyles();

  const {
    input: {value: firstName, onChange: handleChangeFirstName},
    meta: {invalid: validateFirstName, touched: touchedFirstName},
  } = useField('firstName', {
    validate: isRequired,
    formatOnBlur: true,
    format: (val) => val.trim(),
  });
  const {
    input: {value: lastName, onChange: handleChangeLastName},
    meta: {invalid: validateLastName, touched: touchedLastName},
  } = useField('lastName', {
    validate: isRequired,
    formatOnBlur: true,
    format: (val) => val.trim(),
  });
  return (
    <>
      <SubTitle title="Your information" />
      <div className={`flexDirection_column ${userSettingsClasses.detailsSection}`}>
        <div className={userSettingsClasses.container}>
          <div className={userSettingsClasses.detailsField}>
            <div className={userSettingsClasses.title}>First Name</div>
            <Input
              isInvalid={touchedFirstName && validateFirstName}
              value={firstName}
              onChange={handleChangeFirstName}
              invalidMessage="First name is required"
            />
          </div>
          <div className={userSettingsClasses.detailsField}>
            <div className={userSettingsClasses.title}>Last Name</div>
            <Input
              isInvalid={touchedLastName && validateLastName}
              value={lastName}
              onChange={handleChangeLastName}
              invalidMessage="Last name is required"
            />
          </div>
        </div>
        <div className={`${userSettingsClasses.detailsField} ${classes.emailField}`}>
          <div className={userSettingsClasses.title}>Email Address</div>
          <Tooltip type={TYPES.SMALL} content="To change email address, contact your admin.">
            <div>
              <Input value={email} placeHolder="Email" isDisabled />
            </div>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default PersonalInfoSettings;
