import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useMemo} from 'react';
import {fetchDataStreams} from 'bc/store/actions';
import {getSourceIcon, getStreamChip} from 'bc/services/dataManagerService';
import {getAlertConfigurationsItems} from 'alerts.management/store/selectors';
import {isEmpty} from 'lodash';
import {getDataStreamsItems} from 'bc/store/selectors';
import Tooltip from 'common/componentsV2/Tooltip';

import '../OverwiewTab.module.scss';

const HowOftenCubeStatic = ({
  onIsLoading,
  isTriggerLoading,
  trigger,
}: {
  onIsLoading: Function,
  isTriggerLoading: Boolean,
  trigger: Object,
}) => {
  const dispatch = useDispatch();
  const dataStreams = useSelector(getDataStreamsItems);
  const alertConfigurations = useSelector(getAlertConfigurationsItems);

  const originSourceIds = useMemo(() => {
    const alertConfiguration = alertConfigurations?.find((alert) => alert.data?.id === trigger?.alertConfigurationId);
    return alertConfiguration?.data?.originSourceIds;
  }, [trigger.alertConfigurationId, alertConfigurations]);

  const linkClicked = (e) => e.stopPropagation();

  let alertData = null;
  let icon = null;
  let sourceId = null;

  if (originSourceIds) {
    // eslint-disable-next-line prefer-destructuring
    sourceId = originSourceIds[0];
    alertData = dataStreams?.find((stream) => stream.id === sourceId) || {};
    icon = getSourceIcon(alertData?.type);
  }

  useEffect(() => {
    dispatch(fetchDataStreams());
  }, []);

  useEffect(() => {
    onIsLoading(isTriggerLoading);
  }, [isTriggerLoading]);

  const streamChip = alertData ? getStreamChip(alertData) : {};

  return (
    <>
      {(!originSourceIds && !alertData) || isEmpty(alertData) ? (
        <div styleName="info-cube-container">
          <div styleName="stream-name">No specific stream</div>
        </div>
      ) : (
        <div styleName="info-cube-container">
          <div styleName="stream-container">
            <div styleName="icon">{icon}</div>
            <div styleName="stream-name">
              {alertData && (
                <a
                  href={`/#!/r/bc/data-manager?searchQuery=${alertData.name}&expandAll=true`}
                  rel="noopener noreferrer"
                  target="_blank"
                  onClick={linkClicked}
                >
                  {`${alertData.name}`}
                  <Tooltip content={streamChip?.tooltip}>
                    <span styleName={`stream-chip ${streamChip?.style}`}>{streamChip?.text}</span>
                  </Tooltip>
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HowOftenCubeStatic;
