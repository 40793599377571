// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import * as filterSelector from 'alerts.management/store/filterSelector';

type PropTypes = {
  setQueryParams: Function,

  // connect
  anomalyDirections: Array,
  selectedAnomalyDirections: Array,
};

export default connect(
  (state) => ({
    anomalyDirections: filterSelector.getFilterAnomalyDirectionsList(state),
    selectedAnomalyDirections: filterSelector.getSelectedAnomalyDirections(state),
  }),
  {},
)(
  class AnomalyDirectionFilter extends React.PureComponent {
    props: PropTypes;

    onChange = (items) => {
      const dirVal = items.length ? items.map((item) => item.value).join(',') : undefined;
      this.props.setQueryParams({anomalyDirection: dirVal});
    };

    render() {
      const {anomalyDirections, selectedAnomalyDirections} = this.props;
      return (
        <SelectAndt
          type={TYPE_NEW_MULTI_NO_SEARCH}
          theme={THEME_HIGHLIGHTED}
          onChange={this.onChange}
          value={selectedAnomalyDirections}
          options={anomalyDirections}
          isMulti
          optionHeight={40}
          isClearable={false}
          closeMenuOnSelect={false}
          menuWidth={155}
          minMenuHeight={145}
          placeholder="Select"
          automationId="anomalyDirectionFilter"
        />
      );
    }
  },
);
