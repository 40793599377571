// @flow

import React from 'react';
import './RadioButton.module.scss';

type PropTypes = {
  checked: boolean,
  label: String,
  onClick: Function,
  isDisabled: boolean,
  large: boolean,
};
const RadioButton = ({checked, label, onClick, isDisabled, large}: PropTypes) => (
  <div styleName={['container', checked ? 'selected' : '', isDisabled ? 'disabled' : ''].join(' ')} onClick={onClick}>
    <div
      styleName="ico"
      className={['icon', checked ? 'icn-selection16-radioon' : 'icn-selection16-radiooff'].join(' ')}
    />
    <div styleName={`label ${large ? 'large' : ''}`}>{label}</div>
  </div>
);

export default RadioButton;
