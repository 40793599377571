// @flow

import React from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Info from 'common/componentsV2/Info';
import RadioButton from './RadioButton';

type PropTypes = {
  options: Array,
  value: String,
  onChange: Function,
  direction: string,
};

const useStyle = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    '&:hover $info': {
      display: 'inline-block',
    },
  },
  info: {
    display: 'none',
    position: 'relative',
    top: 8,
    left: 5,
  },
}));

const RadioButtonsList = ({options, value, onChange, direction}: PropTypes) => {
  const classes = useStyle();

  return (
    <Grid container spacing={1} direction={direction}>
      {options.map((opt) => (
        <div key={opt.label} className={classes.wrapper}>
          <RadioButton
            key={opt.label}
            checked={opt.value === value}
            label={opt.label}
            onClick={() => onChange(opt)}
            isDisabled={opt.disabled}
          />

          {opt.info && (
            <div className={classes.info}>
              <Info text={opt.info} />
            </div>
          )}
        </div>
      ))}
    </Grid>
  );
};

export default RadioButtonsList;
