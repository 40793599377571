// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {replaceRoute} from 'common/store/actions';
import {isStreamRunning} from 'bc/services/dataStreamService';
import Button, {COLORS} from 'common/componentsV2/Button';
import './StreamEditorUserActions.module.scss';

type PropTypes = {
  // connect
  // eslint-disable-next-line react/no-unused-prop-types
  stream: Object,
  replaceRoute: Function,
  dataStream: Object,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    replaceRoute,
  },
)(
  class StreamEditorCancelActions extends React.PureComponent {
    props: PropTypes;

    // eslint-disable-next-line react/no-unused-state
    state = {isModalOpen: false};

    onClickEvent = () => {
      this.props.replaceRoute('/bc/data-manager');
    };

    getButtonText = () => {
      if (isStreamRunning(this.props.dataStream.state)) {
        return 'Cancel';
      }
      return 'Back';
    };

    render() {
      return (
        <div styleName="root">
          <Button
            colorSchema={COLORS.GRAY_300}
            text={this.getButtonText()}
            onClick={this.onClickEvent}
            automationId="dataCollectorStreamEditorBackButton"
          />
        </div>
      );
    }
  },
);
