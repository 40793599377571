import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/groupBy';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/delay';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/observable/merge';
import {toast} from 'react-toastify';

import {push, replace} from 'connected-react-router';
import * as actions from '../actions';

const urlChanges = Observable.merge(
  // Observable.fromEvent(window, 'popstate'),
  Observable.fromEvent(window, 'hashchange'),
);

export const initRoutChange = (action$) =>
  action$
    .ofType(actions.appInit.TYPE)
    .switchMap(() => urlChanges)
    .map(({newURL, oldURL}) =>
      actions.routeChanged({
        newURL,
        oldURL,
      }),
    );

export const initRout = (action$) =>
  action$.ofType(actions.appInit.TYPE).switchMap(() => [
    actions.routeChanged({
      newURL: window.location.href,
      oldURL: null,
    }),
  ]);

export const setRoute = (action$) => action$.ofType(actions.setRoute.TYPE).flatMap((action) => [push(action.payload)]);

export const replaceRoute = (action$) =>
  action$.ofType(actions.replaceRoute.TYPE).flatMap((action) => [replace(action.payload)]);

export const routeChange = (action$) =>
  action$.ofType(actions.routeChanged.TYPE).flatMap(() => {
    const isToAngular = window.location.href.indexOf('/r/') < 0;
    if (isToAngular) {
      toast.dismiss();
    }
    return [];
  });
