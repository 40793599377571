import {useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {cloneDeep, cloneDeepWith, get, pick} from 'lodash';

import {getBucketStartTimeEnabled} from 'profile/store/selectors';
import {fetchDatapoints, setChartActualData, clearChartCache} from 'dashboards/store/actions';
import {stringifyQS} from 'common/utils/http';
import {
  getChartDataPointsFactory,
  getChartDataPointsLoadingFactory,
  getExpressionErrorsFactory,
} from 'dashboards/store/selectors';
import {getRefetchExecuteData} from 'metrics/store/selectors';
import hashcode from 'common/utils/hashcode';
import deletePropFromObj from 'common/utils/deletePropFromObj';
import {getDateValue} from 'common/utils/dateRangeService';
import {makeFiltersPayload} from '../utils';

const EMPTY_ARRAY = [];

const cleanMeaninglessData = (tree) => {
  const treeCleaned = cloneDeep(tree);
  deletePropFromObj(treeCleaned, 'id');
  deletePropFromObj(treeCleaned, 'uiData');
  deletePropFromObj(treeCleaned, 'q');
  deletePropFromObj(treeCleaned, 'ids');
  return treeCleaned;
};

const cleanUIData = (tree) => {
  const treeCleaned = cloneDeep(tree);
  deletePropFromObj(treeCleaned, 'uiData');
  return treeCleaned;
};

// eslint-disable-next-line consistent-return
const makeFiltersCustomizer = (filters) => (value) => {
  const activeFilters = filters.flatMap((filter) => (filter[0] ? [filter[0].key] : []));
  if (value && value.type === 'metric' && value.searchObject) {
    return {
      ...value,
      searchObject: {
        ...value.searchObject,
        expression: [
          ...value.searchObject.expression.filter((expressionItem) => expressionItem.type !== 'selector'),
          ...activeFilters.map((filter) => ({type: 'selector', value: `$${filter}`})),
        ],
      },
    };
  }
};

const isEmptyExpression = (expressionTree) =>
  get(expressionTree, 'root.searchObject.expression.length') === 0 && get(expressionTree, 'root.type') === 'metric';

const useCompositeExecute = ({
  expressionTrees,
  filters,
  dateRange,
  chartId,
  resolution,
  refetchRelativeDateRange,
  isVisible,
  cacheOnlyLast,
}) => {
  const dispatch = useDispatch();
  const computedFilters = useMemo(
    () => (filters || EMPTY_ARRAY).map((arr) => arr.filter((filter) => filter.value)).filter((arr) => arr.length > 0),
    [filters],
  );
  const amountOfFilters = computedFilters.reduce((sum, current) => sum + current.length, 0);

  // invalidates cache on const type change for predefined ranges OR on any change if refetchRelativeDateRange equals true,
  // invalidates on any change for custom range and custom relative range
  const cachedDateRange =
    ['r', 'c'].includes(dateRange.constRange) || refetchRelativeDateRange ? dateRange : dateRange.constRange;

  const compositeResponseSelector = useMemo(() => getChartDataPointsFactory(chartId), [chartId]);
  const compositeResponse = useSelector(compositeResponseSelector);
  const compositeResponseLoadingSelector = useMemo(() => getChartDataPointsLoadingFactory(chartId), [chartId]);
  const compositeResponseLoading = useSelector(compositeResponseLoadingSelector);
  const isCompositeDataLoading = useMemo(() => Object.values(compositeResponseLoading).some((item) => item === true), [
    compositeResponseLoading,
  ]);

  const startBucketMode = useSelector(getBucketStartTimeEnabled);
  const refetchExecuteData = useSelector(getRefetchExecuteData);

  const prevRefetchRef = useRef(); // in order to persist prev value

  useEffect(
    () => () => {
      dispatch(clearChartCache({}, {chartId}));
    },
    [],
  );

  useEffect(() => {
    const isRefresh = refetchExecuteData !== prevRefetchRef.current;
    prevRefetchRef.current = refetchExecuteData;
    if (expressionTrees && isVisible) {
      Object.entries(expressionTrees).forEach(([expressionTreeId, compositeObject]) => {
        if (isEmptyExpression(compositeObject.expressionTree)) {
          // add optimization here (dispatch success with empty result)
          return;
        }

        const cachingKey = hashcode([
          cleanMeaninglessData(compositeObject),
          computedFilters,
          cachedDateRange,
          resolution,
        ]);

        if (!isRefresh) {
          // disable caching checks on refresh
          if (cacheOnlyLast) {
            if (get(compositeResponse, `[${expressionTreeId}].lastKey`) === cachingKey) {
              return;
            }
          } else if (get(compositeResponse, `[${expressionTreeId}][${cachingKey}]`)) {
            dispatch(
              setChartActualData(compositeResponse[expressionTreeId][cachingKey], {
                cachingKey,
                expressionTreeId,
                chartId,
              }),
            );
            return;
          }
        }
        let currentDateRange;
        if (['c'].includes(dateRange.constRange)) {
          currentDateRange = dateRange;
        } else if (['r'].includes(dateRange.constRange)) {
          currentDateRange = getDateValue({
            constRange: dateRange.constRange,
            relativeLast: dateRange.relativeLast,
            relativeNext: dateRange.relativeNext,
          });
        } else {
          currentDateRange = getDateValue(dateRange.constRange);
        }

        dispatch(
          fetchDatapoints(
            {
              body: {
                composite: cloneDeepWith(cleanUIData(compositeObject), makeFiltersCustomizer(computedFilters)),
                selectors: makeFiltersPayload(computedFilters),
              },
              urlExt: stringifyQS({
                schemaType: 'dashboardsV2',
                fromDate: Math.floor(currentDateRange.startDate / 1000),
                includeBaseline: true,
                index: 0,
                maxDataPoints: 750,
                resolution,
                size: compositeObject.filter.parameters[0].value,
                startBucketMode,
                toDate: Math.floor(currentDateRange.endDate / 1000),
              }),
            },
            {
              chartId,
              expressionTreeId,
              cachingKey,
              cacheOnlyLast,
            },
          ),
        );
      });
    }
  }, [expressionTrees, dateRange, amountOfFilters, chartId, resolution, isVisible, refetchExecuteData]);

  const compositeDataMap = useMemo(
    () =>
      Object.entries(compositeResponse)
        .filter(([key]) => Object.keys(expressionTrees).includes(key))
        .reduce((acc, [key, value]) => {
          const cachingKey = hashcode([
            cleanMeaninglessData(expressionTrees[key]),
            computedFilters,
            cachedDateRange,
            resolution,
          ]);
          acc[key] = {...(value[cachingKey] || EMPTY_ARRAY), compositeObject: expressionTrees[key]};
          return acc;
        }, {}),
    [compositeResponse, expressionTrees, resolution, amountOfFilters, cachedDateRange],
  );

  const expressionErrorsSelector = useMemo(() => getExpressionErrorsFactory(chartId), [chartId]);
  const expressionErrors = useSelector(expressionErrorsSelector);
  const compositeErrorsMap = useMemo(() => pick(expressionErrors, Object.keys(expressionTrees || {})), [
    expressionErrors,
    expressionTrees,
  ]);
  return {
    compositeDataMap,
    compositeErrorsMap,
    isCompositeDataLoading,
  };
};

export default useCompositeExecute;
