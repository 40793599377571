import React, {memo} from 'react';
import './Direction.module.scss';

type PropTypes = {
  arrow?: boolean,
  isDrop: boolean,
  isSpike: boolean,
  percentageText: string,
};

const Direction = ({isDrop, isSpike, percentageText, arrow}: PropTypes) => {
  const bothDirections = isDrop && isSpike;

  let dirText = '';
  if (bothDirections) {
    dirText = 'Drop\\Spike in';
  } else {
    dirText = isDrop ? 'Drop in' : 'Spike in';
  }
  return (
    <div styleName="container">
      {arrow && (
        <div styleName="arrow-container">
          {isDrop && (
            <span styleName={['icon-down', bothDirections ? 'both-directions' : ''].join(' ')}>
              <i className="icon icn-arrow16-arrowdown" />
            </span>
          )}
          {isSpike && (
            <span styleName={['icon-up', bothDirections ? 'both-directions' : ''].join(' ')}>
              <i className="icon icn-arrow16-arrowup" />
            </span>
          )}
        </div>
      )}
      {percentageText && <div styleName="percentage">{`${percentageText}%`}</div>}
      <div styleName={['text', arrow ? 'with-arrow' : ''].join(' ')}>{dirText}</div>
    </div>
  );
};

Direction.defaultProps = {
  arrow: true,
};

export default memo(Direction);
