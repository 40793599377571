import {combineEpics} from 'redux-observable';
import fetchCurUserOpenAlertsCountApi from 'app/services/api';

import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {fetchGroups} from 'admin.users/store/actions';
import * as profileActions from 'profile/store/actions';
import * as profileSelectors from 'profile/store/selectors';
import * as actions from '../actions';

const fetchCurUserOpenAlertsCount = makeAsyncEpic(actions.fetchCurUserOpenAlertsCount, fetchCurUserOpenAlertsCountApi);

const updateBarOnSetMe = (action$, {getState}) =>
  action$.ofType(profileActions.setMe.TYPE, profileActions.fetchMe.success.TYPE).flatMap(({meta}) => {
    const curState = getState();
    const isDashboardV2Enabled = profileSelectors.getDashboardV2Enabled(curState);
    const ownerOrganization = profileSelectors.getMeOwnerOrganization(curState);
    const isMeAnodot = profileSelectors.isAnodot(curState);
    const invitationId = profileSelectors.getInvitationId(curState);
    const isBcDisabled = profileSelectors.getOrganizationSettingsBC(curState).enabled === false;
    const isTopologyEnabled = profileSelectors.getTopologyEnabled(curState);
    const isActivityLogEnabled = profileSelectors.getActivityLogEnabled(curState);
    const isEnableNewComposite = profileSelectors.getEnableNewCompositeManagement(curState);
    const isImpersonating = profileSelectors.getIsImpersonate(curState);
    const isNewAlertConsoleMigrationFinished = profileSelectors.getNewAlertConsoleMigrationFinished(curState);
    const isNewAlertConsoleEnabled = profileSelectors.getNewAlertConsoleEnabled(curState);

    const changeActionsArr = [];

    if (isDashboardV2Enabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'DASHBOARDS',
          subId: 'DASHBOARDSV2',
          navItemProps: {links: ['#!/r/dashboards']},
        }),
      );
    }

    if (!isMeAnodot && !invitationId && !(meta && meta.skip)) {
      changeActionsArr.push(fetchGroups({ownerOrganization}));
    }

    if (isBcDisabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'INTEGRATIONS',
          subId: 'STREAMS',
          navItemProps: {isHidden: true},
        }),
      );
    } else {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'INTEGRATIONS',
          subId: 'STREAMS',
          navItemProps: {isHidden: false},
        }),
      );
    }

    if (isTopologyEnabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'TOPOLOGY',
          navItemProps: {isHidden: false},
        }),
      );
    } else {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'TOPOLOGY',
          navItemProps: {isHidden: true},
        }),
      );
    }

    if (isActivityLogEnabled) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'MANAGEMENT',
          subId: 'ACTIVITY_LOG',
          navItemProps: {isHidden: false},
        }),
      );
    } else {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'MANAGEMENT',
          subId: 'ACTIVITY_LOG',
          navItemProps: {isHidden: true},
        }),
      );
    }
    if (isEnableNewComposite) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'MANAGEMENT',
          subId: 'COMPOSITE_MANAGEMENT',
          navItemProps: {links: ['#!/r/composites']},
        }),
      );
    }

    if (isNewAlertConsoleEnabled && isImpersonating) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'ALERTS_CONSOLE_NEW',
          navItemProps: {iconName: 'icn-alert_new'},
        }),
      );
    }

    if (isNewAlertConsoleEnabled && (isImpersonating || isNewAlertConsoleMigrationFinished)) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'ALERTS_CONSOLE_NEW',
          navItemProps: {isHidden: false},
        }),
      );
    }

    if (isNewAlertConsoleEnabled && isNewAlertConsoleMigrationFinished && !isImpersonating) {
      changeActionsArr.push(
        actions.updateNavBarItemById({
          id: 'ALERTS_CONSOLE',
          navItemProps: {isHidden: true},
        }),
      );
    }

    return changeActionsArr;
  });

const appNavBarEpic = combineEpics(fetchCurUserOpenAlertsCount, updateBarOnSetMe);
export default appNavBarEpic;
