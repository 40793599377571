import React from 'react';
import {Box} from '@material-ui/core';
import {useField} from 'react-final-form';
import Checkbox from 'common/componentsV2/Checkbox';
import RadioButtonsList from 'common/componentsV2/RadioButtonsList';
import {palette} from 'app/styles/theme';

const legendOptions = [{label: 'Under graph', value: 'bottom'}, {label: 'Right-side panel', value: 'right'}];

const LegendOptions = ({needsPositioning}: {needsPositioning: boolean}) => {
  const {
    input: {value: legendPosition, onChange: legendPositionOnChange},
    // meta,
  } = useField('legendPosition');

  const {
    input: {value: showLegendValue, onChange: showLegendOnChange},
    // meta,
  } = useField('showLegend');

  return (
    <>
      <Box my={2}>
        <Checkbox isChecked={showLegendValue} text="Show Legend on Tile" onChange={showLegendOnChange} />
      </Box>
      {needsPositioning && (
        <>
          <Box fontSize={14} fontWeight={500} color={palette.gray[400]} mb={1}>
            Position:{' '}
          </Box>
          <RadioButtonsList
            direction="column"
            options={legendOptions}
            value={legendPosition}
            onChange={(option) => legendPositionOnChange(option.value)}
          />
        </>
      )}
    </>
  );
};

export default LegendOptions;
