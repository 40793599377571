// @flow
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import Tooltip from 'common/componentsV2/Tooltip';
import {getUsersData, fetchGroupsData} from 'admin.users/store/selectors';

import Highlighter from 'react-highlight-words';
import {
  getAccessGroups,
  accessGroupsShortedNames,
  accessGroupsNames,
} from 'admin.permissions/services/accessListService';

const useStyles = makeStyles(({palette}) => ({
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: palette.gray[500],
    paddingTop: '16px',
    height: '48px',
  },
  col: {
    flex: '0 0 170px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: 25,
  },
  colSmall: {
    flex: '0 0 120px',
  },
  colTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    lineHeight: '16px',
    flexGrow: 1,
    marginLeft: '32px',
    fontWeight: 500,
  },
}));

const ChannelItemData = ({
  channel,
  searchQuery,
  isRbacEnabled,
}: {
  channel: Object,
  searchQuery: string,
  isRbacEnabled: Boolean,
}) => {
  const classes = useStyles();
  const users = useSelector(getUsersData);
  const groupsData = useSelector(fetchGroupsData);

  const ownerEmail = JSON.parse(channel.tags).owner;
  const ownerName = useMemo(() => {
    const user = users.find((usr) => usr.email === ownerEmail);
    return user ? `${user.firstName} ${user.lastName}` : null;
  }, [users, ownerEmail]);

  const accessGroups = isRbacEnabled
    ? useMemo(() => {
        return getAccessGroups(channel.accessGroups, groupsData);
      }, [channel.accessGroups, groupsData])
    : null;

  return (
    <div className={classes.row} role="button">
      <div className={classes.colTitle}>
        <Highlighter autoEscape searchWords={[searchQuery]} textToHighlight={channel.name} />
      </div>
      <div className={classes.col}>{ownerName || ownerEmail}</div>
      {isRbacEnabled && (
        <div className={classes.col}>
          <Tooltip content={accessGroupsNames(accessGroups)}>
            <span>{accessGroupsShortedNames(accessGroups)}</span>
          </Tooltip>
        </div>
      )}

      <div className={classes.col}>{channel.alerts.length}</div>
      <div className={classes.colSmall}>-</div>
    </div>
  );
};

export default ChannelItemData;
