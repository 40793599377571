// @flow

import React from 'react';
import Highlighter from 'react-highlight-words';
import './MultiOptionComponent.module.scss';
import {getSourceIcon} from 'bc/services/dataManagerService';
import Tooltip, {TYPES} from '../../../Tooltip';

type PropTypes = {
  roleState: String,
  highlightText: String,
  data: Object,
  isSelected: boolean,
  useMulti: boolean,
  isForceCheckbox: boolean,
};

const maxStringLength = 30;

export default class MultiOptionComponent extends React.PureComponent {
  props: PropTypes;

  state = {};

  renderCheckbox = () => {
    const {data, useMulti, isSelected, isForceCheckbox} = this.props;
    if (data.value === 'streams-all-sources') {
      return null;
    }
    if (!(!data.itemValues && (data.alowMulti || useMulti))) {
      if (!isForceCheckbox) {
        return null;
      }
    }
    return isSelected ? (
      <i styleName="ico selected" className="icon icn-selection16-checkboxon" />
    ) : (
      <i styleName="ico" className="icon icn-selection16-checkboxoff" />
    );
  };

  renderIcon = () => {
    const {data} = this.props;
    return <div styleName="type-icon">{getSourceIcon(data.iconType)}</div>;
  };

  renderCount = () => {
    const count = this.props.data.streamsAvailable.length;
    return <div styleName="count-sources">{`${count} Sources`}</div>;
  };

  renderHighlight = () => {
    const {data, highlightText} = this.props;
    if (data.label.length > maxStringLength) {
      return (
        <Tooltip content={data.label} type={TYPES.SMALL}>
          <div styleName="highlighter">
            <Highlighter autoEscape searchWords={[highlightText]} textToHighlight={data.label} />
          </div>
        </Tooltip>
      );
    }
    return <Highlighter autoEscape searchWords={[highlightText]} textToHighlight={data.label} />;
  };

  render() {
    const {data, roleState} = this.props;
    let lastSelected = false;
    if (data.uiData) {
      // eslint-disable-next-line prefer-destructuring
      lastSelected = data.uiData.lastSelected;
    }
    const isKey = data.itemValues || (data.streamsAvailable || []).length > 1;
    if (data.footer) {
      return (
        <div styleName="container container-footer">
          <div styleName="name-and-count">
            <span styleName="footer">{data.label}</span>
          </div>
        </div>
      );
    }
    if (data.header) {
      return (
        <div styleName="container container-header">
          <div styleName="name-and-count">
            <span styleName="header">{data.label}</span>
          </div>
        </div>
      );
    }

    return (
      // eslint-disable-next-line prefer-template,no-useless-concat
      <div
        className="option-list-item"
        styleName={['container', roleState, lastSelected ? 'last-selected' : ''].join(' ').trim()}
      >
        <div styleName="name-and-count">
          {data.iconType ? this.renderIcon() : this.renderCheckbox()}
          {this.renderHighlight()}
          {(data.streamsAvailable || []).length > 1 && this.renderCount()}
        </div>
        {isKey && <div styleName="chevron" className="icon icn-arrow16-chevronright" />}
      </div>
    );
  }
}
