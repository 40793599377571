// @flow
import React from 'react';
import {cloneDeep, isEmpty, isEqual} from 'lodash';
import {FunctionDefinition} from 'anodot-objects-models';
import FunctionParameterInput from 'common/componentsV2/ExpressionBuilderV2/FunctionForExpressionBuilder/FunctionParameterInput';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import MultiLevelDropdown from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/MultiLevelDropdown';
import OptionFunctionWithInfo from 'common/componentsV2/ExpressionBuilderV2/FunctionForExpressionBuilder/OptionFunctionWithInfo';
import './FunctionForExpressionBuilder.module.scss';

type PropTypes = {
  functionDefinitions: Array<FunctionDefinition>,
  fetchProperties: Function,
  values: Object,
  onParameterChange: Function,
  onFunctionChange: Function,
  onClick: Function,
  selectedElementId: string,
  isFunctionPropertiesLoading: boolean,
  isShowDisplayOnlyFunctions: boolean,
  errors: Object,
  isDragging: boolean,
  renderActionMenu: Function,
  isHideTextFunctions: boolean,
  isShowComposites: boolean,
};

export default class FunctionForExpressionBuilder extends React.PureComponent {
  props: PropTypes;

  state = {
    selectedFunction: {},
    // It will be used when the tooltips are implemented
    // eslint-disable-next-line react/no-unused-state
    validationErrors: {},
    isMenuOpen: false,
    isPristine: true,
    functionDefinitions: [],
  };

  componentDidMount() {
    this.setupData();
  }

  componentDidUpdate(prevState) {
    if (prevState.functionDefinitions.length !== this.props.functionDefinitions.length) {
      this.setupData();
    }
    if (!isEqual(prevState.values.children, this.props.values.children)) {
      this.setupData();
    }
    if (prevState.values.function !== this.props.values.function) {
      this.setupData();
    }
  }

  openMenu = () => {
    this.setState({isMenuOpen: true});
  };

  setupData = () => {
    if (this.props.isDragging) {
      return;
    }
    const retDefinitions = cloneDeep(this.props.functionDefinitions);
    if (this.props.isShowDisplayOnlyFunctions !== true) {
      retDefinitions.forEach((fd) => {
        if (fd.multi) {
          // eslint-disable-next-line no-param-reassign
          fd.multi = fd.multi.filter((ele) => !ele.displayOnly);
        }
      });
    }

    if (this.props.isHideTextFunctions) {
      retDefinitions.forEach((fd) => {
        if (fd.multi) {
          // eslint-disable-next-line no-param-reassign
          fd.multi = fd.multi.filter((ele) => ele.type !== 'alias');
        }
      });
    }

    let selectedFunction = {};

    retDefinitions.forEach((fd) => {
      if (fd.multi) {
        fd.multi.forEach((multi) => {
          if (multi.name === this.props.values.function) {
            selectedFunction = multi;
          }
        });
      }
      if (fd.name === this.props.values.function) {
        selectedFunction = fd;
      }
    });
    this.setState({functionDefinitions: retDefinitions});
    let isUpdateFunctionProperties = false;
    this.setState({selectedFunction});
    if (selectedFunction.parameters) {
      selectedFunction.parameters.forEach((param) => {
        if (param.displayType === 'token') {
          isUpdateFunctionProperties = true;
        }
      });
    }
    if (isUpdateFunctionProperties) {
      this.props.fetchProperties(this.props.values.id);
    }
  };

  onFunctionChange = (val) => {
    this.props.onFunctionChange({value: val, id: this.props.values.id});
    this.setState({isPristine: false});
  };

  handleParamChange = (paramName, newValue, isValid, validationErrors, isInit) => {
    this.props.onParameterChange({
      paramName,
      newValue,
      id: this.props.values.id,
      isInit,
    });

    this.setState((prevState) => {
      const val = {...prevState.validationErrors};
      val[paramName] = validationErrors;
      return {
        validationErrors: val,
      };
    });
  };

  handleTouched = () => {
    this.setState({isPristine: false});
  };

  getParameterValue = (parameter) => {
    const p = this.props.values.parameters.find((param) => param.name === parameter.name);
    if (p) {
      return p.value;
    }
    return null;
  };

  getIsAllValid = () => {
    const ret = true;
    // This code will add as error (!) also the parameters checking
    // Object.keys(this.state.validationErrors).forEach((validation) => {
    //   if (this.state.validationErrors[validation].length > 0) {
    //     ret = false;
    //   }
    // });

    const {uiData, function: typeFunction} = this.props.values;
    if (uiData) {
      if (uiData.failures) {
        if (uiData.failures.length) {
          return false;
        }
      }
    }

    if (this.props.errors) {
      return false;
    }

    if (this.props.isShowComposites && !!typeFunction) {
      return false;
    }
    return ret;
  };

  getValidationText = () => {
    const ret = [];
    // This code will add as error (!) also the parameters checking
    // Object.keys(this.state.validationErrors).forEach((validation) => {
    //   if (this.state.validationErrors[validation].length > 0) {
    //     ret.push(...this.state.validationErrors[validation]);
    //   }
    // });

    const {uiData, function: typeFunction} = this.props.values;
    let failures = [];

    if (uiData && uiData.failures) {
      // eslint-disable-next-line prefer-destructuring
      failures = uiData.failures;
    }

    if (this.props.errors) {
      failures = this.props.errors;
    }

    if (this.props.isShowComposites && !!typeFunction) {
      failures = [{message: 'showCompositesError'}];
    }

    failures.forEach((f) => {
      let message = null;
      switch (f.message) {
        case "Function 'groupBy', parameter 'Group By' is missing.":
        case 'Missing metric property or token position(s).':
          message = 'A Group Must Be Selected';
          break;
        case "Function 'scale', parameter 'Scale Factor' is missing.":
          message = 'Value is Needed';
          break;
        case 'showCompositesError':
          message =
            'You are trying to create a composite expression while allowing composite metrics as part of the input. Set the “Show composites” toggle to off in order to create a valid expression';
          break;
        default:
      }
      if (!message) {
        ret.push(f.message);
      } else {
        ret.push(message);
      }
    });

    return ret;
  };

  onToggle = () => {
    this.setState((prevState) => ({isMenuOpen: !prevState.isMenuOpen}));
    if (this.firstMultilevel) {
      this.firstMultilevel.reset();
    }
  };

  filterFunction = (val, options) => {
    const filtered = [];
    let flattenOptions = [];
    if (val !== '') {
      options.forEach((option) => {
        if (option.multi) {
          option.multi.forEach((multiOption) => {
            flattenOptions.push(multiOption);
          });
        } else {
          flattenOptions.push(option);
        }
      });
      flattenOptions.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
    }
    if (!flattenOptions.length) {
      flattenOptions = options;
    }
    flattenOptions.forEach((option) => {
      const lab = option.displayName.toLocaleLowerCase();
      const ret = lab.indexOf(val.toLocaleLowerCase());
      if (ret > -1) {
        filtered.push(option);
      }
    });
    return filtered;
  };

  renderTooltip = () => {
    const isAllValid = this.getIsAllValid();
    const validationText = this.getValidationText();

    if (!isAllValid && !this.state.isPristine) {
      return (
        <Tooltip placement="right" content={validationText} extraTtClass="validation-tooltip" type={TYPES.SMALL}>
          <div styleName="validation" className="icon icn-general16-warning" />
        </Tooltip>
      );
    }
    return null;
  };

  getOptions = () => {
    const ret = this.state.functionDefinitions.filter((fd) => {
      if (fd.multi) {
        return fd.multi.length;
      }
      return true;
    });
    return ret;
  };

  render() {
    const value = isEmpty(this.state.selectedFunction) ? null : this.state.selectedFunction;
    const functionProperties = this.props.values.uiData ? this.props.values.uiData.expressionProperties : [];
    let disabled = false;
    if (this.props.values.uiData && this.props.values.uiData.isDeletable === 'resetParams') {
      disabled = true;
    }
    const isSummarizeFunc = value && value.name.includes('SummarizeToDate');
    return (
      <div
        styleName={['container', this.props.selectedElementId === this.props.values.id ? 'selected' : ''].join(' ')}
        onClick={this.props.onClick}
        automation-id="metricExplorerFunctionForExpressionBuilder"
      >
        <div styleName={`function ${isSummarizeFunc ? 'summarize' : ''}`} automation-id="metricExplorerAddAFunction">
          <div className={`display_flex alignItems_center ${isSummarizeFunc ? 'flexWrap_wrap' : ''}`}>
            <MultiLevelDropdown
              ref={(firstMultilevel) => {
                this.firstMultilevel = firstMultilevel;
              }}
              optionComponent={<OptionFunctionWithInfo />}
              options={this.getOptions()}
              onSelect={this.onFunctionChange}
              rowHeight={40}
              isUseSearch
              width={250}
              height={330}
              disabled={disabled}
              useHeader
              button={
                <div automation-id="metricExplorerRecentMeasureTitle" styleName="button">
                  <div styleName="functionDisplayName">{value ? value.displayName : 'Add a Function'}</div>
                  {disabled ? (
                    <span className="icon" styleName="verticalAlign">
                      :
                    </span>
                  ) : (
                    <i className="icon icn-arrow16-triangledown" styleName="verticalAlign" />
                  )}
                </div>
              }
              getOptionLabel={(val) => val.displayName}
              getOptionValue={(val) => val.displayName}
              value={value}
              isOpen={this.state.isMenuOpen}
              usePropsForOpenState
              onToggle={this.onToggle}
              filterFunction={this.filterFunction}
              automationId="functionSelection"
            />
            {this.state.selectedFunction.parameters
              ? this.state.selectedFunction.parameters.map((parameter, index) => (
                  // eslint-disable-next-line react/jsx-indent
                  <div key={parameter.name} styleName="parameter">
                    <FunctionParameterInput
                      onValueChange={this.handleParamChange}
                      functionParameter={parameter}
                      functionProperties={functionProperties}
                      onTouched={this.handleTouched}
                      value={this.getParameterValue(parameter)}
                      isFunctionPropertiesLoading={this.props.isFunctionPropertiesLoading}
                      isForceFocusIfAvailable={index === 0 && typeof this.getParameterValue(parameter) === 'undefined'}
                      isSummarizeFunc={isSummarizeFunc}
                    />
                  </div>
                ))
              : null}
          </div>
          <div styleName="function_action" className={`${isSummarizeFunc ? 'alignSelf_flex-start' : ''}`}>
            {this.props.renderActionMenu()}
            {this.renderTooltip()}
          </div>
        </div>
      </div>
    );
  }
}
