import React, {useMemo} from 'react';
import {getUsersData} from 'admin.users/store/selectors';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import ChannelField from './ChannelField';

const useStyles = makeStyles(() => ({
  firstColumn: {
    width: '137px',
    maxWidth: '137px',
  },
  secondColumn: {
    width: '230px',
    maxWidth: '230px',
  },
}));

const EMPTY_ARRAY = [];
const EmailTemplate = ({channel}: {channel: Object}) => {
  // icn-general16-user, icn-general16-group
  const classes = useStyles();
  const tags = JSON.parse(channel.tags);
  const users = tags.users || EMPTY_ARRAY;
  const usersList = useSelector(getUsersData);

  const usersNamesMap = useMemo(
    () => usersList.reduce((acc, user) => ({...acc, [user.email]: `${user.firstName} ${user.lastName}`}), {}),
    [usersList],
  );

  const filteredEmails = useMemo(
    () => (channel.channelData.emailAddresses || []).filter((item) => !users.includes(item)),
    [channel, users],
  );

  return (
    <div className="display_table">
      <div className="display_table-row">
        <div className={`display_table-cell pr_4 boxSizing_content-box ${classes.firstColumn}`}>
          <div className="fontWeight_500 color_gray-400">Users ({users.length})</div>
          {users.map((user) => (
            <div key={users} className={`mb_0-5 ellipsis ${classes.firstColumn}`}>
              <i className="icon icn-general16-user mr_0-5" />
              {usersNamesMap[user]}
            </div>
          ))}
        </div>
        <div className="display_table-cell boxSizing_content-box">
          <div className={`fontWeight_500 color_gray-400 ${classes.secondColumn}`}>
            Emails ({filteredEmails.length})
          </div>
          {filteredEmails.map((email) => (
            <div key={email} className={`mb_0-5 ellipsis ${classes.secondColumn}`}>
              {email}
            </div>
          ))}
        </div>
      </div>
      <div className="display_table-row height_0">
        <div className="display_table-cell pr_4">
          <ChannelField label="Time Zone" value={channel.timezone || 'Browser'} />
        </div>

        <div className="display_table-cell">
          <ChannelField label="Created by" value={channel.createdBy} />
        </div>
      </div>
    </div>
  );
};

export default EmailTemplate;
