// @flow
import React from 'react';
import {connect} from 'react-redux';
import {applyAwsCurTags as applyAwsCurTagsAction} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';

import './AwsCurTemplate.module.scss';

type PropTypes = {
  isLoading: Boolean,
  tags: Array,
  selectedTags: Array,

  // connect
  applyAwsCurTags: Function,
};

export default connect(
  (state) => ({
    isLoading: selectors.awsCurManifestsIsLoading(state),
    tags: selectors.awsCurTagsItems(state),
    selectedTags: selectors.awsCurSelectedTagsItems(state),
  }),
  {
    applyAwsCurTags: applyAwsCurTagsAction,
  },
)(
  class AwsCurTags extends React.PureComponent {
    props: PropTypes;

    onChange = (values) => {
      this.props.applyAwsCurTags(values);
    };

    render() {
      const {isLoading, tags, selectedTags} = this.props;

      return (
        <div styleName="root">
          <PanelHeader title="AWS Resource Tags" isEditable="false" />
          <span styleName="input-description">Tags you choose will be added to this data stream as Dimensions</span>
          <SelectAndt
            type={TYPE_NEW_MULTI}
            theme={THEME_NOT_HIGHLIGHTED}
            onChange={this.onChange}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.id}
            isMulti
            optionHeight={40}
            closeMenuOnSelect={false}
            closeMenuOnScroll={false}
            disabled={isLoading}
            options={tags}
            value={selectedTags}
            isClearable={false}
            placeholder={isLoading ? 'Loading...' : 'Choose Tags'}
            automationId="awsCurChooseTags"
          />
        </div>
      );
    }
  },
);
