// @flow
import React from 'react';
import Highlighter from 'react-highlight-words';
import {StringParam, useQueryParam} from 'use-query-params';
import moment from 'moment';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {correctTimestampUnix} from 'common/utils/dateRangeService';
import {RECOMMENDATION_TYPES, RECOMMENDATIONS_QUERY_PARAMS} from 'recommendations/services/consts';
import RecommendationProgressBar from 'recommendations/components/recommendationsList/listComponents/RecommendationProgressBar';
import {GenerateIcon, ICONS, SIZES} from '@pileus-cloud/anodot-frontend-common';

import classes from './RecommendationItemHeader.module.scss';

const RecommendationItemHeader = ({item}: {item: Object}) => {
  const [searchExpression] = useQueryParam(RECOMMENDATIONS_QUERY_PARAMS.SEARCH_EXPRESSION.name, StringParam);
  const {alertConfigurationName, type, createdAt, updatedAt} = item;
  const createdDateFormat = createdAt ? moment.unix(correctTimestampUnix(createdAt)).format('MMM DD, YYYY') : '';
  const updatedDateFormat = updatedAt ? moment.unix(correctTimestampUnix(updatedAt)).format('MMM DD, YYYY') : '';

  const openAlertConfiguration = (e) => {
    e.stopPropagation();
    window.open(`/#!/r/alert-manager/edit/${item?.alertConfigurationId}/settings`, `_blank-new`);
  };

  return (
    <div className={`${classes.container} ${classes.listItem}`}>
      <div className={classes.title}>
        <Tooltip content={alertConfigurationName} type={TYPES.SMALL} delay={500}>
          <div className={`${classes.titleText} ${classes.firstItem}`} automation-id="recommendation-title">
            <Highlighter autoEscape searchWords={[searchExpression]} textToHighlight={alertConfigurationName} />
          </div>
        </Tooltip>
        <Tooltip content="View in Alert Editor" type={TYPES.SMALL} delay={500}>
          <div className={classes.viewInAlertEditor} onClick={openAlertConfiguration}>
            <GenerateIcon iconName={ICONS.arrowUpRightFromSquare.name} size={SIZES.sm} />
          </div>
        </Tooltip>
      </div>
      <div>
        <span>{RECOMMENDATION_TYPES[type]}</span>
      </div>
      <div className={`${classes.field} ${classes.dateGiven}`}>
        <div className={classes.titleText} automation-id="recommendation-created">
          <Highlighter autoEscape searchWords={[searchExpression]} textToHighlight={createdDateFormat} />
        </div>
      </div>
      <div className={`${classes.field} ${classes.dateUpdated}`}>
        <div className={classes.titleText} automation-id="recommendation-updated">
          <Highlighter autoEscape searchWords={[searchExpression]} textToHighlight={updatedDateFormat} />
        </div>
      </div>
      <div className={`${classes.field} ${classes.recommendationsApplied}`}>
        <RecommendationProgressBar recommendations={item?.recommendations} />
      </div>
    </div>
  );
};

export default RecommendationItemHeader;
