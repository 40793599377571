// @flow

import React from 'react';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {ReactComponent as StatusIcon} from 'alerts.console.new/images/open_alert.svg';
import './alertContent.module.scss';

const AlertStatusIcon = (props: {status: string}) => (
  <div styleName="status">
    {props.status === 'OPEN' && (
      <SmartTooltip placement="top" content="Open Alert" delay={400}>
        <i styleName="status-icon">
          <StatusIcon />
        </i>
      </SmartTooltip>
    )}
  </div>
);

export default AlertStatusIcon;
