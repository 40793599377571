import {useSelector} from 'react-redux';
import {isNil, pickBy} from 'lodash';
import {getBucketStartTimeEnabled, getTimeZoneName} from 'profile/store/selectors';
import {getDateValue, rangeTypes} from 'common/utils/dateRangeService';
import {modalRouting as investigationQueryParams} from 'investigationNewAlertConsole/services/investigationService';
import {
  modalRouting1 as impactQueryParams1,
  modalRouting2 as impactQueryParams2,
} from 'impactNewAlertConsole/services/constants';

export const alertsConsoleRoot = 'api/v1/alerts-console/';

export const fetchTriggeredAlertsParams = (queryParams) => {
  const startBucketMode = useSelector(getBucketStartTimeEnabled);
  const timeZone = useSelector(getTimeZoneName);
  const dateRange = getDateValue(
    {
      constRange: queryParams?.constRange,
      startTime: queryParams?.startTime,
      endTime: queryParams?.endTime,
      relativeLast: queryParams?.relativeLast,
      relativeNext: queryParams?.relativeNext,
    },
    true,
    timeZone,
  );
  const isConst = dateRange.constRange === rangeTypes.c.value || dateRange.constRange === rangeTypes.r.value;

  const params = {
    ...queryParams,
    searchExpression: queryParams?.searchExpression,
    constRange: dateRange.constRange,
    startBucketMode,
    breachTimeFrom: dateRange.startDate,
    breachTimeTo: isConst ? dateRange.endDate : null,
  };

  const cleanParams = pickBy(params, (p) => !isNil(p) && p !== '' && p !== undefined && p !== 'undefined');

  // Removing the query parameters of Investigation and Impact modals, since they don't affect the result,
  // but cause change of query thus react query cache is not used, because a new request to the server is created.
  [
    ...Object.values(investigationQueryParams),
    ...Object.values(impactQueryParams1),
    ...Object.values(impactQueryParams2),
  ].forEach((qp) => delete cleanParams[qp]);

  return cleanParams;
};

export const fetchGroupByAlertTriggeredIdParams = (queryParams) => {
  const timeZone = useSelector(getTimeZoneName);
  const dateRange = getDateValue(
    {
      constRange: queryParams?.constRange,
    },
    true,
    timeZone,
  );

  const params = {
    ...queryParams,
    breachTimeFrom: dateRange.startDate,
    constRange: dateRange.constRange,
  };

  const cleanParams = pickBy(params, (v) => !isNil(v) && v !== '');

  const triggeredAlertsParams = {...cleanParams};

  return triggeredAlertsParams;
};

export const mapAcknowledgedOwners = (triggers, users = []) => {
  const triggeredAlerts = triggers?.map((alertGroup) => {
    const alerts = alertGroup.alerts?.map((alert) => {
      const userIds = alert.acks?.userIds?.map((userId) => {
        const user = users.find((u) => u.id === userId);
        if (user) {
          return user;
        }
        return null;
      });
      const nonEmptyUserIds = userIds?.filter((user) => !!user);
      const acks = {...alert.acks, userIds: nonEmptyUserIds};
      return userIds?.length ? {...alert, acks} : alert;
    });
    return {...alertGroup, alerts};
  });
  return triggeredAlerts;
};
