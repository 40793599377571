// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import * as selector from 'alerts.management/store/selectors';
import {simpleAlertSetRecipients} from 'alerts.management/store/actions';
import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import * as channelsSelectors from 'alerts.channels/store/selectors';
import {cloneDeep} from 'lodash';
import {fetchChannels} from 'alerts.channels/store/actions';
import * as usersSelectors from 'admin.users/store/selectors';
import {fetchUsers} from 'admin.users/store/actions';

type PropTypes = {
  simpleAlertSetRecipients: Function,
  selectedItems: Array,
  isLoadingChannels: Boolean,
  isLoadingUsers: Boolean,
  fetchChannels: Function,
  fetchUsers: Function,
  sortedFlattenChannels: Array<Object>,
  disabled: boolean,
};

export default connect(
  (state) => ({
    users: usersSelectors.getUsersData(state),
    selectedItems: selector.getSimpleAlertRecipients(state),
    isLoadingChannels: channelsSelectors.getChannelsLoading(state),
    isLoadingUsers: usersSelectors.getUsersIsLoading(state),
    sortedFlattenChannels: channelsSelectors.getSortedFlattenChannels(state),
  }),
  {
    simpleAlertSetRecipients,
    fetchChannels,
    fetchUsers,
  },
)(
  class RecipientsDdl extends React.PureComponent {
    props: PropTypes;

    state = {
      sortedOptions: [],
    };

    componentDidMount() {
      this.props.fetchChannels();
      this.props.fetchUsers();
      this.onMenuClose();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.sortedFlattenChannels !== this.props.sortedFlattenChannels) {
        this.onMenuClose(this.props);
      }
    }

    onChange = (item) => {
      this.props.simpleAlertSetRecipients(item);
    };

    onMenuClose = (props = this.props) => {
      const {sortedFlattenChannels, selectedItems} = props;
      const sortedOptions = cloneDeep(sortedFlattenChannels);

      if (selectedItems) {
        sortedOptions.sort((a, b) => {
          const foundA = selectedItems.find((item) => a.value === item.value);
          const foundB = selectedItems.find((item) => b.value === item.value);
          if (foundA && foundB) {
            return foundA.label.localeCompare(foundB.label);
          }
          return foundA && !foundB ? -1 : 0;
        });

        this.setState({
          sortedOptions,
        });
      } else {
        this.setState({
          sortedOptions: sortedFlattenChannels,
        });
      }
    };

    render() {
      const {isLoadingChannels, isLoadingUsers, selectedItems, disabled} = this.props;
      const {sortedOptions} = this.state;
      return (
        <UsersChannelsFilter
          placeholder={isLoadingChannels || isLoadingUsers ? 'Loading...' : 'Select'}
          onChange={this.onChange}
          onMenuClose={this.onMenuClose}
          sortedFlattenChannels={sortedOptions}
          value={selectedItems}
          disabled={disabled}
          menuWidth={180}
        />
      );
    }
  },
);
