/* eslint-disable import/prefer-default-export */
import React, {useMemo} from 'react';
import StreamContext from 'dataCollectors/components/sourceData/streamContext/StreamContext';
import FileUpload from 'dataCollectors/components/sourceData/uploadFile/FileUpload';
import StreamTable from 'dataCollectors/components/sourceData/streamTable/StreamTable';
import {EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';
import PropTypes from 'prop-types';

export const SourceData = ({template, type}) => {
  const {LOCAL_FILE, BIG_QUERY} = EVENT_STREAM_TYPES;
  const sourceData = useMemo(() => {
    switch (type) {
      case '':
        return <></>;
      case BIG_QUERY:
        return <StreamContext leftSideWidth={590} info={template?.tableInfo} />;
      case LOCAL_FILE:
        return <FileUpload leftSideWidth={590} info={template?.tableInfo} />;
      default:
        // default is for SQL collector
        return <StreamTable leftSideWidth={590} info={template?.tableInfo} />;
    }
  }, [type]);

  return <>{sourceData}</>;
};

SourceData.defaultProps = {
  template: null,
  type: '',
};

SourceData.propTypes = {
  template: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
};
