// @flow
import React, {useState, useEffect} from 'react';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import MetricsTableRow from './MetricsTableRow';

import './MetricsTable.module.scss';
import {ANOMALY_COLUMNS} from '../../services/investigationService';

const EMPTY_ARRAY = [];
const DEFAULT_SORT_COLUMN = 'score';

export const ORDER_DIRECTIONS = {
  DESC: 'DESC',
  ASC: 'ASC',
};

const sortingFunction = (arr, sortBy, direction) => {
  let tmpArr = [];

  if (sortBy === ANOMALY_COLUMNS.DELTA.name) {
    arr.forEach((metric) => {
      const tmpMetric = {
        ...metric,
        [ANOMALY_COLUMNS.DELTA.name]: (metric.lowerAbsoluteDelta || 0) + (metric.upperAbsoluteDelta || 0),
      };
      tmpArr.push(tmpMetric);
    });
  } else {
    tmpArr = [...arr];
  }
  return tmpArr.sort((a, b) => {
    const itemA = a[sortBy];
    const itemB = b[sortBy];

    if (itemA < itemB) {
      return -1 * direction;
    }
    if (itemA > itemB) {
      return direction;
    }
    return 0;
  });
};

const MetricsTable = ({
  columns,
  trigger,
  metrics,
  isLoading,
  timeZoneName,
  optionalTitle = '',
  listOrder,
  setListOrder,
  isSingleLine,
}: {
  columns: Array<Object>,
  trigger: Object,
  metrics: Array<Object>,
  isLoading: boolean,
  timeZoneName: string,
  optionalTitle: string,
  listOrder: Object,
  setListOrder: Function,
  isSingleLine: Boolean,
}) => {
  const [selectedItemsIdsList, setSelectedItemsIdsList] = useState(EMPTY_ARRAY);
  const [sortedMetrics, setSortedMetrics] = useState(EMPTY_ARRAY);
  const [localColumns, setLocalColumns] = useState(EMPTY_ARRAY);

  useEffect(() => {
    if (columns?.length > 0) {
      setLocalColumns([...columns]);
      if (optionalTitle) {
        const titleObj = localColumns.find((o) => o.name === 'title');
        titleObj.title = optionalTitle;
      }
    }
  }, [columns, optionalTitle]);

  useEffect(() => {
    const sortBy = listOrder.column ? listOrder.column : DEFAULT_SORT_COLUMN;
    const direction = !listOrder.direction || listOrder.direction === ORDER_DIRECTIONS.DESC ? -1 : 1;

    setSelectedItemsIdsList([]);
    setSortedMetrics([...sortingFunction(metrics, sortBy, direction)]);
  }, [metrics, listOrder, setSortedMetrics]);

  const handleTitleOrderChange = (columnName) => {
    let updatedDirection = ORDER_DIRECTIONS.DESC;
    if (columnName === listOrder.column) {
      updatedDirection = listOrder.direction === ORDER_DIRECTIONS.DESC ? ORDER_DIRECTIONS.ASC : ORDER_DIRECTIONS.DESC;
    }

    setListOrder({
      column: columnName,
      direction: updatedDirection,
    });
  };

  const setSelectedItem = (itemId) => {
    if (selectedItemsIdsList.indexOf(itemId) === -1) {
      setSelectedItemsIdsList((state) => [...state, itemId]);
    } else {
      setSelectedItemsIdsList((state) => state.filter((item) => item !== itemId));
    }
  };

  if (isLoading) {
    return (
      <div styleName="spinner-wrapper">
        <Spinner color="#3d4c59" size={SpinnerSizes.XX_BIG_100} />
      </div>
    );
  }

  return (
    <div styleName="table">
      <div styleName="table-header">
        <div styleName="col-is-open" />
        {localColumns.map((col) => (
          <div styleName={['col-header', `col-${col.name}`].join(' ')} key={`user-events-list-${col.id}`}>
            <TitleHeaderTableOrder
              title={col.title}
              columnName={col.name}
              onChange={handleTitleOrderChange}
              selectedDirection={listOrder.direction}
              isSortable={col.isSortable}
              isActive={col.name === listOrder.column}
            />
          </div>
        ))}
      </div>

      {sortedMetrics.map((metric) => (
        <MetricsTableRow
          trigger={trigger}
          key={metric.id}
          metric={metric}
          columns={localColumns}
          timeZoneName={timeZoneName}
          setSelectedItem={setSelectedItem}
          isSelected={selectedItemsIdsList?.indexOf(metric.id) !== -1}
          isSingleLine={isSingleLine ?? true}
        />
      ))}
    </div>
  );
};

export default MetricsTable;
