import React, {useRef, useState, useEffect, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {useTable, useBlockLayout, useResizeColumns, useSortBy, useGlobalFilter, useFlexLayout} from 'react-table';

// import Button, {HEIGHTS, COLORS} from 'common/componentsV2/Button';
import EmptyMessage, {IMAGE_TYPES} from 'common/componentsV2/table/EmptyMessage';
import {getFormattedDateYMD} from 'common/utils/dateService';
import EditableCell from './EditableCell';
import GlobalFilter from './GlobalFilter';
import './table1.module.scss';

const formatDateCell = (value, timeZone) => {
  if (!value) {
    return null;
  }
  if (value && !isNaN(value) && !isNaN(new Date(value).getTime()) && value.toString().length === 13) {
    return getFormattedDateYMD(value / 1000, timeZone);
  }
  if (value && !isNaN(new Date(value).getTime()) && !isNaN(value) && value.toString().length === 10) {
    return getFormattedDateYMD(value, timeZone);
  }
  return value;
};

export default function Table1({
  columns,
  timeZone,
  isGlobalSearch,
  updateData,
  data,
  // resetData,
  height,
  isSortable,
  supportAccessorFormat,
}) {
  const containerDiv = useRef(null);
  const [columnsState, setColumns] = useState([]);
  const [containerDivWidth, setContainerDivWidth] = useState(0);
  // const [isColumnsValuesChanged, setIsColumnsValuesChanged] = useState(false);
  const [columnIdInFocus, setColumnIdInFocus] = useState(null);

  const createColumns = useCallback(() => {
    const columnsTotal = columns.length;
    const borders = columnsTotal * 2;
    const columnWidth = (containerDivWidth - borders) / columnsTotal;
    const newColumns = columns.map((column) => {
      if (!column.title) {
        return null;
      }

      return {
        Header: column.editable
          ? (hook) => {
              return (
                <EditableCell
                  onFocus={hook.setOnFocus}
                  originalValue={column.id}
                  value={column.title}
                  updateData={hook.updateData}
                />
              );
            }
          : column.title,
        editable: column.editable,
        id: column.id,
        accessor: supportAccessorFormat
          ? (value) => {
              if (!isNaN(new Date(value[column.id]).getTime())) {
                return formatDateCell(value[column.id], timeZone);
              }
              return value[column.id];
            }
          : column.id,

        width: columnWidth,
        Cell: ({value}) => formatDateCell(value, timeZone),
      };
    });

    setColumns(newColumns);
  }, [columns, containerDivWidth]);

  // const checkForColumnsChanges = useCallback(() => {
  //   setIsColumnsValuesChanged(columns.some((column) => column.editable && column.originalValue !== column.value));
  // }, [columns])

  useEffect(() => {
    if (columns?.length) {
      createColumns();
    }
  }, [columns, containerDivWidth]);

  // useEffect(() => {
  //   checkForColumnsChanges(columns);
  // }, [columns]);

  useEffect(() => {
    if (containerDiv.current) {
      setContainerDivWidth(containerDiv.current.offsetWidth);
    }
  }, [containerDiv.current]);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      maxWidth: 400,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {globalFilter},
    setGlobalFilter,
  } = useTable(
    {
      columns: columnsState,
      data,
      updateData,
      setOnFocus: (id) => setColumnIdInFocus(id),
      defaultColumn,
    },
    useBlockLayout,
    useResizeColumns,
    useFlexLayout,
    useGlobalFilter,
    useSortBy,
  );

  return (
    <>
      {columnsState?.length > 0 && (
        <div styleName="table-container" ref={containerDiv}>
          <div styleName="search-wrapper">
            {isGlobalSearch && <GlobalFilter filter={globalFilter || ''} setFilter={setGlobalFilter} />}
            {/* {resetData && ( */}
            {/*  <div */}
            {/*    style={{ */}
            {/*      opacity: isColumnsValuesChanged ? 1 : 0.5, */}
            {/*      pointerEvents: isColumnsValuesChanged ? 'auto' : 'none', */}
            {/*    }} */}
            {/*  > */}
            {/*    <Button */}
            {/*      automationId="table1-clear-changes" */}
            {/*      text="Clear changes" */}
            {/*      colorSchema={COLORS.TRANSPARENT_BLUE} */}
            {/*      onClick={resetData} */}
            {/*      height={HEIGHTS.TIGHT30} */}
            {/*    /> */}
            {/*  </div> */}
            {/* )} */}
          </div>

          <div {...getTableProps()} styleName="table">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} styleName="thead">
                {headerGroup.headers.map((column) => (
                  <div {...column.getHeaderProps(isSortable ? '' : '')} styleName="th">
                    <div styleName="text">
                      {columnIdInFocus !== column.id ? (
                        <div {...column.getSortByToggleProps()}>{column.render('Header')}</div>
                      ) : (
                        <div>{column.render('Header')}</div>
                      )}
                      {/* eslint-disable-next-line no-nested-ternary */}
                      <span styleName="sort-arrow">{column.isSorted ? (column.isSortedDesc ? '↓' : '↑') : ''}</span>
                    </div>
                    <div {...column.getResizerProps()} styleName={`resizer ${column.isResizing ? 'isResizing' : ''}`} />
                  </div>
                ))}
              </div>
            ))}

            <div styleName="tbody" style={{maxHeight: height - 120}} {...getTableBodyProps()}>
              <div {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <div {...row.getRowProps()} styleName="tr">
                      {row.cells.map((cell) => {
                        return (
                          <div {...cell.getCellProps()} styleName="td">
                            <span styleName="text"> {cell.render('Cell')}</span>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {!(columnsState?.length > 0) && (
        <div style={{marginTop: '15%'}}>
          <EmptyMessage
            imageType={IMAGE_TYPES.NO_ALERTS}
            title="No data to preview"
            subtitle="We could not find data records to show here"
          />
        </div>
      )}
    </>
  );
}

Table1.defaultProps = {
  updateData: null,
  resetData: null,
  timeZone: moment.tz.guess(),
  isGlobalSearch: false,
  isSortable: false,
  height: '',
  supportAccessorFormat: false,
};

Table1.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired, // {title: 'title', id: 'id', editable: true}
  data: PropTypes.arrayOf(PropTypes.object).isRequired, // {title: 'value'}
  timeZone: PropTypes.string,
  updateData: PropTypes.func,
  resetData: PropTypes.func,
  isGlobalSearch: PropTypes.bool,
  isSortable: PropTypes.bool,
  height: PropTypes.number,
  supportAccessorFormat: PropTypes.bool,
};
