// @flow
import React from 'react';
import ExpressionItem from './ExpressionItem';
import Chip, {TYPE} from './Chip';
import {getUniqueId} from '../../utils/guid';
import './ExpressionBuilder.module.scss';

/* GABPAC - 04/11/19 - The expressionline component is made to print out the given expression
 * using chips and innerChips.
 *
 * the textInput prop is passed down to the chip for its use -- so events will flow smoothier.
 */

type PropTypes = {
  expression: Array<ExpressionItem>,
  onChipClick: Function,
  onInnerChipRemoveClicked: Function,
  onClickOutside: Function,
  currentSelectedKey: String,
  textInput: Node,
};

export default class ExpressionLine extends React.PureComponent {
  props: PropTypes;

  isFocus = (expression) => {
    let ignoreFocus = false;
    if (['Streams', 'Alerts', 'Composites', 'Forecasts'].some((ele) => ele === expression.label)) {
      ignoreFocus = true;
    }
    return ignoreFocus ? false : expression.value === this.props.currentSelectedKey;
  };

  getType = (expItem) => {
    if (expItem.isLegacy) {
      return TYPE.LEGACY;
    }
    return expItem.value === 'Measures' ? TYPE.MEASURE : TYPE.FILTER;
  };

  render() {
    return this.props.expression.map((expItem: ExpressionItem) => (
      <Chip
        key={expItem.value || `emptyValue-${getUniqueId()}`}
        type={this.getType(expItem)}
        expressionItem={expItem}
        onClick={this.props.onChipClick}
        onClickOutside={this.props.onClickOutside}
        onInnerChipRemoveClicked={this.props.onInnerChipRemoveClicked}
        isFocus={this.isFocus(expItem)}
        textInput={this.props.textInput}
        isShowChildrenOnly={expItem.label === 'Measures'}
      />
    ));
  }
}
