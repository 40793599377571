import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/alerts/');
const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/alerts/');

//* **alerts fired
export const resetAlertsConsole = mac('RESET_ALERTS_CONSOLE');

export const fetchTriggeredAlertsAcknowledged = macAsync('FETCH_TRIGGERED_ALERTS_ACKNOWLEDGED');
export const fetchTriggeredAlertsNotAcknowledged = macAsync('FETCH_TRIGGERED_ALERTS_NOT_ACKNOWLEDGED');
export const fetchAlertMetricDataPoints = macAsync('FETCH_METRIC_DATA_POINTS');
export const fetchAlertEvents = macAsync('FETCH_ALERT_EVENTS');
export const fetchAlertMultipleEvents = macAsync('FETCH_ALERT_MULTIPLE_EVENTS');

export const fetchAlerts = mac('FETCH_ALERTS');

export const setSelectedTriggeredAlert = mac('SET_SELECTED_TRIGGERED_ALERT');

export const setStar = macAsync('SET_STAR');

export const fetchTriggeredAlertForSnooze = macAsync('FETCH_TRIGGERED_ALERT_FOR_SNOOZE');
export const setSnoozeMetricsFilter = mac('SET_SNOOZE_METRICS_FILTER');
export const resetSnoozeModalState = mac('RESET_SNOOZE_MODAL_STATE');
export const snoozeAllTriggerMetrics = macAsync('SNOOZE_ALL_TRIGGER_METRICS'); // by trigger id
export const endSnoozeAllTriggerMetrics = macAsync('END_SNOOZE_ALL_TRIGGER_METRICS'); // by trigger id
export const endSnoozeTriggerMetrics = macAsync('END_SNOOZE_TRIGGER_METRICS'); // by specific metrics
// add indication to metric model so snooze modal will mark metric as selected or not
export const setSnoozeIndicationForMetric = mac('SET_SNOOZE_INDICATION_FOR_METRIC');
export const snoozeTriggerMetrics = macAsync('SNOOZE_TRIGGER_METRICS'); // by specific metrics
// once snooze modal closes, gather the metrics changes and trigger API calls (add/remove) snooze
export const applySnoozeToMetrics = mac('APPLY_SNOOZE_TO_METRICS');

export const setSTLIndicationForMetric = mac('SET_STL_INDICATION_FOR_METRIC');
export const applySTLtoMetrics = mac('APPLY_STL_TO_METRICS');
export const stlAllTriggerMetrics = macAsync('STL_ALL_TRIGGER_METRICS');
export const endStlAllTriggerMetrics = macAsync('END_STL_ALL_TRIGGER_METRICS');
export const STLTriggerMetrics = macAsync('STL_TRIGGER_METRICS');
export const endStlTriggerMetrics = macAsync('END_STL_TRIGGER_METRICS');

export const changeScrollbarWidth = mac('CHANGE_SCROLLBAR_WIDTH');
export const listScrolled = mac('LIST_SCROLLED');
export const setIsAlertsListScrolling = mac('SET_IS_ALERTS_LIST_SCROLLING');

export const updateQueryParams = mac('UPDATE_QUERY_PARAMS');
export const updateFilterBtn = mac('UPDATE_FILTER_BTN');
export const IsMoreFilterOpen = mac('IS_MORE_FILTER_OPEN');

export const fetchTriggeredAlertsTotal = macAsync('FETCH_TRIGGERED_ALERTS_TOTAL');
export const fetchTriggeredAlertsTotalLastDay = macAsync('FETCH_TRIGGERED_ALERTS_TOTAL_LAST_DAY');
export const setQueryParamsToView = mac('SET_QUERY_PARAMS_TO_VIEW');

export const setAlertsFilters = mac('SET_ALERTS_FILTERS');
export const setAlertsConsoleFiltersKeyVal = mac('SET_ALERT_FILTERS_KEY_VAL');

export const setFeedback = mac('SET_FEEDBACK');
export const deleteFeedback = mac('DELETE_FEEDBACK');
export const setQuickFeedback = mac('SET_FEEDBACK_QUICK_FEEDBACK');
export const updateFeedback = mac('UPDATE_FEEDBACK');
export const setFeedbackRequest = macAsync('SET_FEEDBACK_REQUEST');
export const deleteFeedbackRequest = macAsync('DELETE_FEEDBACK_REQUEST');
export const updateFeedbackRequest = macAsync('UPDATE_FEEDBACK_REQUEST');

export const setMarkAsRead = mac('SET_MARK_AS_READ');
export const setMarkAsReadRequest = macAsync('SET_MARK_AS_READ_REQUEST');

export const setAssignee = macAsync('SET_ASSIGNEE');
export const deleteAssignee = macAsync('DELETE_ASSIGNEE');

export const toggleInvestigationModal = mac('TOGGLE_INVESTIGATION_MODAL');
