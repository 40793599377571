import React from 'react';
import {Box} from '@material-ui/core';
import './MetricExplorerModalBottomPanel.module.scss';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import Button, {COLORS} from 'common/componentsV2/Button';
import {useSelector} from 'react-redux';
import * as selectors from '../../store/selectors';

type PropTypes = {
  onSave: Function,
  onCancel: Function,
};

const MetricExplorerModalBottomPanel = ({onSave, onCancel}: PropTypes) => {
  const isExecuteValid = useSelector(selectors.getIsExecuteValid);

  const handleCancel = () => {
    onCancel();
  };

  const handleSave = () => {
    if (isExecuteValid) {
      onSave();
    }
  };

  const renderButtonSave = () => (
    <Button onClick={handleSave} colorSchema={COLORS.BLUE_500} text="Save as influencing metrics" />
  );

  return (
    <div styleName="container">
      <Box
        display="flex"
        justifyContent="flex-end"
        maxWidth={1680}
        width={1}
        px="20px"
        alignItems="center"
        className="idle-external-click"
      >
        <div styleName="buttons-container">
          <Button onClick={handleCancel} colorSchema={COLORS.TRANSPARENT} text="Cancel" />
          {isExecuteValid ? (
            <div>{renderButtonSave()}</div>
          ) : (
            <Tooltip
              content={
                <span>
                  The expression is invalid and <br /> therefore cannot be saved
                </span>
              }
              type={TYPES.SMALL}
              extraTtClass="validation-tooltip"
            >
              <div>{renderButtonSave()}</div>
            </Tooltip>
          )}
        </div>
      </Box>
    </div>
  );
};

export default MetricExplorerModalBottomPanel;
