import React from 'react';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  inputFieldInfo: {
    opacity: '0.5',
  },
}));

const LabelWithInfo = ({label, tooltipContent}: {label: String, tooltipContent: String}) => {
  const classes = useStyles();
  return (
    <div>
      <div className="display_flex alignItems_center flex_gap_5">
        {label}
        {tooltipContent && (
          <SmartTooltip content={tooltipContent}>
            <i className={`icon icn-general16-info ${classes.inputFieldInfo}`} />
          </SmartTooltip>
        )}
      </div>
    </div>
  );
};

export default LabelWithInfo;
