// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {segmentClickEvent} from 'common/store/actions';
import {useDispatch} from 'react-redux';

import './TriageTabsBar.module.scss';

const TriageTab = ({label, id, isSelected, icon, onClick, isEnabled}: PropTypes) => {
  const dispatch = useDispatch();

  const reportToSegment = () => {
    dispatch(segmentClickEvent({type: 'click', name: `alert-triage-tab-${label.toLowerCase()}`}));
  };

  const clicked = () => {
    reportToSegment();
    onClick(id);
  };

  return (
    <>
      {isEnabled && (
        <div styleName={`tab-item ${isSelected ? 'tab-item-selected' : ''}`} onClick={clicked}>
          {icon ? <i className={`icon ${icon}`} /> : null}
          {label}
        </div>
      )}
    </>
  );
};

TriageTab.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool.isRequired,
};

export default React.memo(TriageTab);
