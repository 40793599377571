import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1';

const getEmbeddedToken = () => {
  return get(`${root}/value-report/token/`);
};

export default function useGetToken() {
  const selectFn = useCallback((res) => {
    return res;
  }, []);

  const options = {
    select: selectFn,
  };

  const {data: embeddedToken, isLoading: getTokenIsLoading} = useQuery(
    QUERY_KEYS.valueReportToken,
    () => getEmbeddedToken(),
    options,
  );

  return {embeddedToken, getTokenIsLoading};
}
