import React, {useEffect, useMemo, useState} from 'react';
import moment from 'moment';
import Tooltip from 'common/componentsV2/Tooltip';
import {commasSepNumber} from 'common/utils/numbers';

import './MetricsUsage.module.scss';
import useFetchMetricsUsage from './api/useFetchMetricsUsage';
import PERCENTAGE_RANGE from './services/metricsService';

const MetricsUsage = React.memo(() => {
  const duration = 750;
  const {metricsUsageData} = useFetchMetricsUsage();
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);

  useEffect(() => {
    setProgressBarPercentage(Math.round((metricsUsageData?.monthlyUsage / metricsUsageData?.limit) * 100));
  }, [metricsUsageData]);

  const setFillColor = (percentage) => {
    if (!percentage || percentage === PERCENTAGE_RANGE.Low.value) {
      return PERCENTAGE_RANGE.Low.color;
    }
    if (percentage < PERCENTAGE_RANGE.Medium.value) {
      return PERCENTAGE_RANGE.Medium.color;
    }
    if (percentage >= PERCENTAGE_RANGE.High.minValue && percentage < PERCENTAGE_RANGE.High.maxValue) {
      return PERCENTAGE_RANGE.High.color;
    }
    return PERCENTAGE_RANGE.Max.color;
  };

  const defaultStyle = {
    transition: `width ${duration}ms ease-out`,
    width: 0,
    backgroundColor: setFillColor(0),
  };

  const transitionStyles = {
    transition: `width ${duration}ms ease-out`,
    width: `${progressBarPercentage}%`,
    backgroundColor: setFillColor(progressBarPercentage),
  };

  const tooltipBody = useMemo(
    () => (
      <div styleName="tooltip-wrap">
        <div>
          <strong>{`For  ${moment().format('MMM')} 1 to ${moment().format('MMM D')}`}</strong>
        </div>
        <div>
          <strong>Used</strong>
          <span>{`: ${progressBarPercentage || 0}% (${commasSepNumber(metricsUsageData?.monthlyUsage || 0)}) `}</span>
        </div>
        <div>
          <strong>Total Capacity</strong>
          <span>{`: ${' '}${commasSepNumber(metricsUsageData?.limit ? metricsUsageData?.limit : 0)}`}</span>
        </div>
        <div>
          <strong>Remaining</strong>
          <span>{`: ${' '}${commasSepNumber(metricsUsageData?.limit - metricsUsageData?.monthlyUsage || 0)}`}</span>
        </div>
      </div>
    ),

    [progressBarPercentage, metricsUsageData?.monthlyUsage, metricsUsageData?.limit],
  );

  const noDataTooltipBody = useMemo(
    () => (
      <div styleName="tooltip-wrap">
        <div>
          <span>Not Enough Data</span>
        </div>
      </div>
    ),
    [],
  );

  return (
    <>
      <div styleName="buttons-wrap">
        <div styleName="metrics-wrap">
          <span styleName="metrics-text">Metrics Usage</span>
          <span styleName="metrics-widget">
            <Tooltip
              content={
                !metricsUsageData?.monthlyUsage && metricsUsageData?.limit === 0 ? noDataTooltipBody : tooltipBody
              }
              delay={300}
              placement="bottom"
            >
              <div styleName="progressBar-wrapper">
                <span styleName="progressBar-fill" style={{...defaultStyle, ...transitionStyles}} />
              </div>
            </Tooltip>
          </span>
        </div>
      </div>
    </>
  );
});
export default MetricsUsage;
