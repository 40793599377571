import React, {PureComponent} from 'react';
import {components} from 'react-select2';
import '../SelectAndt.module.scss';

type PropTypes = {
  // accepted values by the original Select
  menuIsOpen: boolean,
  selectProps: any,
  children: any,
  onChange: Function,
  searchPlaceholder: string,
};

export default class InputInMenu extends PureComponent {
  props: PropTypes;

  render() {
    const {searchPlaceholder, ...otherProps} = this.props;
    if (this.props.menuIsOpen) {
      return (
        <div styleName="input-search">
          <i className="icon icn-general16-search" />
          {this.props.selectProps && !this.props.selectProps.inputValue && (
            <span styleName="pseudo-placeholder">
              {this.props.searchPlaceholder ? this.props.searchPlaceholder : 'Search'}
            </span>
          )}
          {this.props.selectProps && this.props.selectProps.inputValue && (
            <i
              className="icon icn-general16-closeb"
              styleName="icon-clear"
              onClick={() => this.props.onChange({currentTarget: {value: ''}})}
            />
          )}
          <components.Input className="andt-dropdown-input-menu-open" {...otherProps}>
            {this.props.children}
          </components.Input>
        </div>
      );
    }
    return (
      <components.Input className="andt-dropdown-input-menu-close" {...otherProps}>
        {this.props.children}
      </components.Input>
    );
  }
}
