// @flow
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useQueryParams} from 'use-query-params';
import {cloneDeep} from 'lodash';
import {
  PANEL_SIZES,
  QUERY_PARAM_MAP,
  REGIONS_LABEL_PER_LEVEL,
  SUB_REGION_PANEL_TABS,
  filteredIssuesList,
} from 'topologyGeneral/services/sidePanelService';
import {getEntitiesBounds, MAX_REGIONS_ZOOM} from 'topologyGeneral/services/mapService';
import TabsBar from 'topologyGeneral/components/common/TabsBar';
import BackButton from 'topologyGeneral/components/common/BackButton';
import SelectedRegionItem from 'topologyGeneral/components/common/SelectedRegionItem';
import LocationsList from 'topologyGeneral/components/sidePanel/LocationsList';
import TinyScrollBox from 'common/componentsV2/boxTools/TinyScrollBox';
import IssuesSubList from 'topologyGeneral/components/sidePanel/issuesSubPanel/IssuesSubList';
import {palette} from 'app/styles/theme';
import {getGtpMapRegions, getGtpMapIssues} from 'topologyGeneral/store/selectors';
import './SidePanel.module.scss';

const SubRegionsPanel = ({mapRef, isVisible, nodeHoveredId}: PropTypes) => {
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);
  const regionItems = useSelector(getGtpMapRegions);
  const issues = useSelector(getGtpMapIssues);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [subRegionsItems, setSubRegionsItems] = useState([]);
  const [subRegionsPanelTabs, setSubRegionsPanelTabs] = useState(SUB_REGION_PANEL_TABS);
  const [filteredIssues, setFilteredIssues] = useState([]);

  useEffect(() => {
    const findRegion = regionItems.find((r) => r.id === queryParams.regionId);
    if (findRegion) {
      setSelectedRegion(findRegion);
      setSubRegionsItems(regionItems.filter((r) => r.parentRegionId === findRegion.id));
    } else {
      setSelectedRegion(null);
      setSubRegionsItems([]);
    }
  }, [queryParams.regionId, regionItems]);

  useEffect(() => {
    if (selectedRegion) {
      const fil = filteredIssuesList(issues, subRegionsItems);
      const cloned = cloneDeep(SUB_REGION_PANEL_TABS);
      const regionLabel = REGIONS_LABEL_PER_LEVEL[selectedRegion.level + 1];
      cloned.subRegions.label += ` ${regionLabel} (${subRegionsItems.length}/${subRegionsItems.length})`;
      cloned.alerts.label += ` (${fil.relevant}/${fil.total})`;
      setFilteredIssues(fil.filteredIssues);
      setSubRegionsPanelTabs(cloned);
    } else {
      setSubRegionsPanelTabs(SUB_REGION_PANEL_TABS);
    }
  }, [selectedRegion, subRegionsItems, issues]);

  useEffect(() => {
    if (subRegionsItems.length) {
      const {current: map} = mapRef;
      const subRegionBounds = getEntitiesBounds(subRegionsItems);

      if (subRegionBounds && subRegionBounds.center) {
        map.setView(subRegionBounds.center);
      } else if (subRegionBounds && subRegionBounds.maxPoint) {
        map.fitBounds([subRegionBounds.minPoint, subRegionBounds.maxPoint], {
          maxZoom: MAX_REGIONS_ZOOM,
          padding: [0, 0],
          animate: false,
        });
      }
    }
  }, [subRegionsItems]);

  const tabClicked = useCallback((tabId) => {
    setQueryParams({subRegionsTabId: tabId});
  }, []);

  const onBackClick = () => {
    setQueryParams({
      subRegionsTabId: undefined,
      regionId: selectedRegion.level === 0 ? undefined : selectedRegion.parentRegionId,
    });
  };

  const locItemClicked = (locItem) => {
    const newSelectedLocationId = queryParams.regionId === locItem.id ? undefined : locItem.id;
    setQueryParams({regionId: newSelectedLocationId});
  };

  if (!isVisible || !selectedRegion) {
    return null;
  }

  return (
    <Box mr={2} ml={2}>
      <Box display="flex" mt={3} mb={1} height={16}>
        <BackButton text={`${REGIONS_LABEL_PER_LEVEL[selectedRegion.level]}`} onClick={onBackClick} />
      </Box>
      <SelectedRegionItem region={selectedRegion} />
      <Box display="flex" borderBottom={`1px solid ${palette.gray[300]}`} pl={0.5} mt={2.75}>
        <TabsBar
          tabs={Object.values(subRegionsPanelTabs)}
          selectedTabId={queryParams.subRegionsTabId}
          onTabClick={tabClicked}
        />
      </Box>
      <TinyScrollBox
        position="absolute"
        top={166}
        bottom={0}
        right={3}
        left={0}
        css={{overflowY: 'auto', overflowX: 'hidden'}}
      >
        <Box width={PANEL_SIZES.innerPanelWidth} ml={2}>
          {queryParams.subRegionsTabId === subRegionsPanelTabs.subRegions.id && (
            <LocationsList items={subRegionsItems} onItemClick={locItemClicked} selectedItemId={nodeHoveredId} />
          )}
          {queryParams.subRegionsTabId === subRegionsPanelTabs.alerts.id && <IssuesSubList issues={filteredIssues} />}
        </Box>
      </TinyScrollBox>
    </Box>
  );
};

SubRegionsPanel.propTypes = {
  mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
  nodeHoveredId: PropTypes.string,
  isVisible: PropTypes.bool,
};

SubRegionsPanel.defaultProps = {
  nodeHoveredId: undefined,
  isVisible: true,
};

export default SubRegionsPanel;
