// @flow
import React, {PureComponent} from 'react';

import PopoverButton from './PopoverButton';
import AddConceptPopover from './AddConceptPopover';

import ConceptGroups from './ConceptGroups';
import ConceptGroup from './ConceptGroup';
import FilteredConcepts from './FilteredConcepts';

import './ConceptsPanelHeader.module.scss';

const PlusIcon = () => (
  <div styleName="plus" automation-id="ad-item">
    <i className="icon icn-icon-white-plus" />
  </div>
);

type PropTypes = {
  title: string,
  concepts: Array<Object>,
  onAddItem: Function,
  onRemoveItem: Function,
};

class ConceptsPanelHeader extends PureComponent {
  props: PropTypes;

  state = {
    alignWith: null,
    selectedCategory: null,
    filter: '',
  };

  componentDidMount() {
    this.setState({alignWith: this.self});
  }

  setSelectedCategory = (selectedCategory) => this.setState({selectedCategory});

  setFilter = (filter) => this.setState({filter});

  render() {
    const {title, concepts, onAddItem, onRemoveItem} = this.props;
    const {alignWith, selectedCategory, filter} = this.state;
    const {setSelectedCategory, setFilter} = this;

    return (
      <header
        styleName="header"
        ref={(self) => {
          this.self = self;
        }}
      >
        <h4>{title} </h4>
        {alignWith && (
          <PopoverButton
            alignwith={alignWith}
            minwidth={350}
            widthfactor={filter.length === 0 && selectedCategory ? 1.0 : 0.5}
            onClose={() => {
              setFilter('');
              setSelectedCategory(null);
            }}
            popover={
              <AddConceptPopover styleName="popover" placement="bottom" filter={filter} onFilter={setFilter}>
                <div styleName="concepts-panel-titles">
                  {filter.length === 0 && <div>Categories</div>}
                  {filter.length === 0 && selectedCategory && <div>{title}</div>}
                  {filter.length > 0 && <div>{title}</div>}
                </div>
                <div styleName="concepts-panel-popover">
                  {filter.length === 0 && <ConceptGroups {...{concepts, selectedCategory, setSelectedCategory}} />}
                  {filter.length === 0 && selectedCategory && (
                    <ConceptGroup
                      {...{
                        selectedCategory,
                        concepts,
                        onAddItem,
                        onRemoveItem,
                      }}
                    />
                  )}
                  {filter.length > 0 && (
                    <FilteredConcepts
                      {...{
                        filter,
                        concepts,
                        onAddItem,
                        onRemoveItem,
                      }}
                    />
                  )}
                </div>
              </AddConceptPopover>
            }
            title={<PlusIcon />}
            className="btn btn-flat btn-icon btn-blue-light"
          />
        )}
      </header>
    );
  }
}

export default ConceptsPanelHeader;
