// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import Loading from 'metrics/components/Loading';
import * as selectors from 'bc/store/selectors';
import {treeVisitor} from 'metrics/services/compositeService';
import {fetchTransformFunctions as fetchTransformFunctionsAction} from 'bc/store/actions';
import {isEmpty, cloneDeep, get} from 'lodash';
import './ViewStreamSummaryModalV2.module.scss';

const listSortFunction = (a, b) => {
  if (a.hidden) return 1;
  if (b.hidden) return -1;
  return 0;
};

type PropTypes = {
  dataStream: Object,
  streamCardinality: Object,
  isStreamCardinalityLoading: boolean,

  lookupTablesData: Array,
  transformFunctions: Array,
  fetchTransformFunctions: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    streamCardinality: selectors.getSelectedStreamCardinalityItems(state),
    isStreamCardinalityLoading: selectors.getSelectedStreamCardinalityIsLoading(state),
    lookupTablesData: selectors.getFetchLookupTablesData(state),
    transformFunctions: selectors.getTransformFunctionsItems(state),
  }),
  {
    fetchTransformFunctions: fetchTransformFunctionsAction,
  },
)(
  class MeasuresDimesionsData extends PureComponent {
    props: PropTypes;

    componentDidMount() {
      this.props.fetchTransformFunctions();
    }

    getColTranforms = (column) => {
      const tranformsArray = [];
      const locCol = cloneDeep(column);
      treeVisitor(
        locCol.transform,
        'input',
        (child) => {
          if (!child.sourceColumn) {
            const newChild = cloneDeep(child);
            newChild.displayName = newChild.name;
            if (this.props.transformFunctions.length > 0) {
              const foundItem = this.props.transformFunctions.find((item) => item.name === newChild.name);
              newChild.displayName = foundItem ? foundItem.displayName : newChild.name;
              newChild.type = foundItem ? foundItem.type : null;
              if (newChild.parameters && newChild.parameters.length) {
                newChild.parameters.forEach((param, index) => {
                  if (param === null) {
                    newChild.parameters[index] = foundItem.parameters[index].defaultValue;
                  }
                });
              }
            }
            tranformsArray.unshift(newChild);
          }
        },
        'transform',
      );
      return {
        columnId: locCol.id,
        transformList: tranformsArray,
      };
    };

    getColumnsWithTransforms = () =>
      this.props.dataStream.schema.columns
        .filter((col) => !isEmpty(col.transform))
        .map((col) => this.getColTranforms(col));

    getColumnsWithMetricTags = () =>
      this.props.dataStream.schema.columns
        .filter((col) => !isEmpty(col.metricTags))
        .map((col) => ({
          columnId: col.id,
          metricTags: col.metricTags,
        }));

    getDimensionsList = () =>
      this.props.dataStream.schema.columns.filter((col) => col.type === 'dimension').sort(listSortFunction);

    getMetricsList = () =>
      this.props.dataStream.schema.columns.filter((col) => col.type === 'metric').sort(listSortFunction);

    render() {
      const {streamCardinality, isStreamCardinalityLoading, lookupTablesData} = this.props;
      const columnsWithTransforms = this.getColumnsWithTransforms();
      const columnsWithMetricTags = this.getColumnsWithMetricTags();

      return (
        <div styleName="dimetrics-section">
          <div styleName="vs-section-header">MEASURES & DIMENSIONS</div>
          <List
            type="metric"
            list={this.getMetricsList()}
            filteredMetricTagsColumns={columnsWithMetricTags}
            filteredTransformColumns={columnsWithTransforms}
            lookupTablesData={lookupTablesData}
          />
          <List
            type="dimension"
            list={this.getDimensionsList()}
            cardinality={streamCardinality}
            isCardinalityLoading={isStreamCardinalityLoading}
            filteredTransformColumns={columnsWithTransforms}
            lookupTablesData={lookupTablesData}
          />
        </div>
      );
    }
  },
);

const getTooltipContent = (foundTransformItem, foundMetricTagsItem, lookupTablesData) => {
  const rowArray = [];

  if (foundMetricTagsItem) {
    const metricTagKeys = Object.keys(foundMetricTagsItem.metricTags);
    metricTagKeys.forEach((mtKey) => {
      rowArray.push(<div key={`metrigTag-${mtKey}`}>{`${mtKey}: ${foundMetricTagsItem.metricTags[mtKey]}`}</div>);
    });
  }

  /* eslint-disable react/no-array-index-key */
  if (foundTransformItem) {
    foundTransformItem.transformList.forEach((transform, index) => {
      if (transform.name === 'replacelookuptable' || transform.name === 'filterByLookup') {
        const displayName = transform.name === 'replacelookuptable' ? 'Lookup Replace' : 'Lookup Filter';
        const foundLookupTable = lookupTablesData.find((tb) => tb.id === transform.parameters[0]);
        const ltbName = foundLookupTable ? foundLookupTable.name : '';
        rowArray.push(<div key={`transform-${index}`}>{`${displayName}: ${ltbName}`}</div>);
      } else if (transform.type === 'BinaryOperation') {
        rowArray.push(<div key={`transform-${index}`}>{`Function: ${transform.displayName}`}</div>);
      } else if (transform.parameters.length === 0) {
        rowArray.push(<div key={`transform-${index}`}>{`${transform.displayName}`}</div>);
      } else if (transform.parameters.length === 1) {
        rowArray.push(<div key={`transform-${index}`}>{`${transform.displayName}: ${transform.parameters[0]}`}</div>);
      } else if (transform.parameters.length === 2) {
        rowArray.push(
          <div key={`transform-${index}`}>
            {`${transform.displayName} from ${transform.parameters[0]} to ${transform.parameters[1]}`}
          </div>,
        );
      }
    });
  }
  /* eslint-enable react/no-array-index-key */

  return <div>{rowArray}</div>;
};

const getListItemValue = (item, filteredTransformColumns, filteredMetricTagsColumns, lookupTablesData) => {
  const foundTransformItem = filteredTransformColumns
    ? filteredTransformColumns.find((col) => col.columnId === item.id)
    : null;
  const foundMetricTagsItem = filteredMetricTagsColumns
    ? filteredMetricTagsColumns.find((col) => col.columnId === item.id)
    : null;

  // column is ignored - anything else is irrelevant
  if (item.hidden) {
    return <div styleName="vs-label with-val">Ignored</div>;
  }

  // count number of changes and show if more then 1
  let numberOfChanges = 0;
  if (foundTransformItem) {
    numberOfChanges += foundTransformItem.transformList.length;
  }
  if (foundMetricTagsItem) {
    numberOfChanges += Object.keys(foundMetricTagsItem.metricTags).length;
  }

  if (numberOfChanges > 1) {
    const tooltipContext = getTooltipContent(foundTransformItem, foundMetricTagsItem, lookupTablesData);
    return (
      <Tooltip placement="top" content={tooltipContext} delay={200} type={TYPES.LARGE}>
        <div styleName="vs-label with-val">{`${numberOfChanges} Changes`}</div>
      </Tooltip>
    );
  }

  // only 1 change - show it
  if (foundMetricTagsItem) {
    const metricTagKeys = Object.keys(foundMetricTagsItem.metricTags);
    return (
      <div styleName="vs-label with-val">
        <span className="ellipsis">{`${metricTagKeys[0]}: ${foundMetricTagsItem.metricTags[metricTagKeys[0]]}`}</span>
      </div>
    );
  }

  if (foundTransformItem) {
    const transform = foundTransformItem.transformList[0];
    if (transform.name === 'replacelookuptable' || transform.name === 'filterByLookup') {
      const foundLookupTable = lookupTablesData.find((tb) => tb.id === transform.parameters[0]);
      const ltbName = foundLookupTable ? foundLookupTable.name : '';
      return (
        <div styleName="vs-label with-val">
          <Tooltip placement="top" content={ltbName} delay={200}>
            <span className="ellipsis">{`${transform.displayName}`}</span>
          </Tooltip>
        </div>
      );
    }
    if (transform.parameters.length === 0) {
      return (
        <div styleName="vs-label with-val">
          <span className="ellipsis">{`${transform.displayName}`}</span>
        </div>
      );
    }
    if (transform.parameters.length === 1) {
      return (
        <div styleName="vs-label with-val">
          <Tooltip placement="top" content={transform.parameters[0]} delay={200}>
            <span>{`${transform.displayName}`}</span>
          </Tooltip>
        </div>
      );
    }
    if (transform.parameters.length === 2) {
      return (
        <div styleName="vs-label with-val">
          <Tooltip
            placement="top"
            content={`from ${transform.parameters[0]} to ${transform.parameters[1]}`}
            delay={400}
          >
            <span className="ellipsis">{transform.displayName}</span>
          </Tooltip>
        </div>
      );
    }
    if (transform.type === 'BinaryOperation') {
      return (
        <div styleName="vs-label with-val">
          <span className="ellipsis">{`Function: ${transform.displayName}`}</span>
        </div>
      );
    }
  }

  return <div styleName="vs-label">None</div>;
};

const List = ({
  list,
  type,
  cardinality,
  isCardinalityLoading,
  filteredTransformColumns,
  filteredMetricTagsColumns,
  lookupTablesData,
}: any) => {
  const aggregation = {
    counter: {
      icon: 'icn-general16-sigma',
      tooltip: 'Sum',
    },
    gauge: {
      icon: 'icn-general16-average',
      tooltip: 'Average',
    },
  };

  return (
    <div styleName="section">
      <div styleName="vs-row">
        {type === 'metric' ? (
          <div styleName="measure-wrapper">{`Measures - ${list.length}`}</div>
        ) : (
          <div styleName="dimension-wrapper">{`Dimensions - ${list.length} (Cardinality)`}</div>
        )}
        <div styleName="vs-label">Changes</div>
      </div>
      {list.map((item) => {
        const itemCardinality = isCardinalityLoading ? (
          <Loading />
        ) : (
          `(${get(cardinality, item.name.replace(/[\s.]+/gi, '_'), {cardinality: 'NA'}).cardinality})`
        );

        if (item.type === 'metric') {
          return (
            <div styleName="vs-row" key={item.id + item.name}>
              <div styleName={`measure-wrapper ${item.hidden ? 'ignored' : ''}`}>
                <div styleName="measure" className="ellipsis">
                  <span className="ellipsis">{item.name}</span>
                </div>
                {item.targetType ? (
                  <Tooltip placement="top" content={aggregation[item.targetType].tooltip} delay={400}>
                    <i className={`icon ${aggregation[item.targetType].icon}`} />
                  </Tooltip>
                ) : null}
              </div>
              {getListItemValue(item, filteredTransformColumns, filteredMetricTagsColumns, lookupTablesData)}
            </div>
          );
        }
        return (
          <div styleName="vs-row" key={item.id + item.name}>
            <div styleName={`dimension-wrapper ${item.hidden ? 'ignored' : ''}`}>
              <div styleName="dimension" className="ellipsis">
                <span className="ellipsis">{item.name}</span>
              </div>
              <div styleName="cardinality">{itemCardinality}</div>
            </div>
            {getListItemValue(item, filteredTransformColumns, filteredMetricTagsColumns, lookupTablesData)}
          </div>
        );
      })}
    </div>
  );
};
