import React, {PureComponent} from 'react';
import {Box} from '@material-ui/core';
import {components} from 'react-select2';
import Highlighter from 'react-highlight-words';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {getFocusOptionSearchableEmitter} from '../communication';

// eslint-disable-next-line import/no-cycle
import * as style from '../SelectAndtStyling';
import '../SelectAndt.module.scss';

type PropTypes = {
  // accepted values by the original Select
  isFocused: boolean,
  isSelected: boolean,
  value: any,
  children: any,
  selectProps: any,
  options: any,
  data: Object,
};

export default class OptionMulti extends PureComponent {
  props: PropTypes;

  render() {
    if (this.props.isFocused) {
      const index = this.props.options.findIndex((i) => i.value === this.props.value);
      getFocusOptionSearchableEmitter().next(index);
    }

    const classIcon = ['icon-checkbox', 'icon'];
    // eslint-disable-next-line no-underscore-dangle
    if (this.props.data.__isNew__) {
      classIcon.push('icn-action16-plusa');
    } else {
      // eslint-disable-next-line no-lonely-if
      if (this.props.selectProps.useBoxHalf) {
        if (this.props.isSelected) {
          classIcon.push(this.props.data.isSome ? 'icn-selection16-checkboxhalf' : '');
          classIcon.push(this.props.data.isAll ? 'icn-selection16-checkboxon' : '');
        } else {
          classIcon.push('icn-selection16-checkboxoff');
        }
      } else {
        classIcon.push(this.props.isSelected ? 'icn-selection16-checkboxon' : 'icn-selection16-checkboxoff');
      }
    }
    const {isDisabled} = this.props.data;

    const optStyle = style.optionMulti();
    const renderEle =
      this.props.data.type === 'HEADER' ? (
        <components.Option className="andt-dropdown-option-header" {...this.props}>
          <div style={optStyle.header()}>{this.props.children}</div>
        </components.Option>
      ) : (
        <components.Option className="andt-dropdown-option" {...this.props}>
          <div style={optStyle.checkbox()} className={classIcon.join(' ')}>
            {' '}
          </div>
          <Tooltip content={this.props.children || ''} type={TYPES.LARGE} delay={300}>
            <div automation-id={this.props.isSelected ? 'selected' : 'not-selected'} style={optStyle.option()}>
              <Highlighter
                autoEscape
                searchWords={[this.props.selectProps.inputValue]}
                textToHighlight={this.props.children || ''}
              />
            </div>
          </Tooltip>
          <span styleName="count">{this.props.data.count || ''}</span>
        </components.Option>
      );
    return isDisabled ? <Box css={{opacity: 0.5, pointerEvents: 'none'}}>{renderEle}</Box> : renderEle;
  }
}
