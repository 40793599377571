import React from 'react';
import {makeStyles} from '@material-ui/core';
import RelativeTimeFrame from './RelativeTimeFrame';
import RangeTimeFrame from './RangeTimeFrame';
import CustomDateRangeFooter from './CustomDateRangeFooter';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 300,
    fontSize: 22,
    lineHeight: '26px',
  },
}));

type PropsType = {
  showPresets: Function,
};

const DateRange = ({showPresets}: PropsType) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.title}>Custom Time Frame</div>
      <RelativeTimeFrame />
      <RangeTimeFrame />
      <CustomDateRangeFooter showPresets={showPresets} />
    </>
  );
};

export default DateRange;
