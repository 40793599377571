/* eslint-disable radix */
export const DVP = {
  dvpConfig: {
    type: 'dvpConfig',
    options: [{label: 'Data gaps should not be filled', value: null}, {label: 'Fill in the gaps', value: true}],
  },
  maxDVPDurationHours: {
    type: 'maxDVPDurationHours',
    value: 24,
  },
  maxDVPDurationUnit: {
    type: 'maxDVPDurationUnit',
    options: [{label: 'Hours', value: 'hour'}, {label: 'Days', value: 'day'}],
  },
  gaugeKeepLastValue: {
    type: 'gaugeKeepLastValue',
    options: [{label: 'Last Value', value: true}, {label: 'Custom Value', value: false}],
  },
  gaugeValue: {
    type: 'gaugeValue',
    value: 0,
  },
  counterKeepLastValue: {
    type: 'counterKeepLastValue',
    options: [{label: 'Custom Value', value: false}, {label: 'Last Value', value: true}],
  },
  counterValue: {
    type: 'counterValue',
    value: 0,
  },
  baseRollup: {
    type: 'baseRollup',
    value: '',
  },
  preventNoData: {
    type: 'preventNoData',
    value: true,
  },
};

export const getUnitFormatted = (value) => {
  if (value >= 24 && value <= 744 && value % 24 === 0) {
    return DVP.maxDVPDurationUnit.options[1];
  }
  return DVP.maxDVPDurationUnit.options[0];
};

export const getValueFormated = (value) => {
  if (value >= 24 && value <= 744 && value % 24 === 0) {
    return value / 24;
  }
  return value;
};

const getHours = (value, unit) => {
  if (unit === DVP.maxDVPDurationUnit.options[0].value) {
    return parseInt(value);
  }
  return parseInt(value * 24);
};

export const getMaxDurationHours = (timeScale) => {
  if (timeScale === 'short' || timeScale === 'medium') {
    return 24;
  }
  if (timeScale === 'long') {
    return 168;
  }
  return 744;
};

export const createDvpObjForServer = (data) => {
  if (!data[DVP.dvpConfig.type]) {
    return {dvpConfig: null};
  }
  return {
    dvpConfig: {
      [DVP.baseRollup.type]: data[DVP.baseRollup.type],
      [DVP.preventNoData.type]: DVP.preventNoData.value,
      [DVP.maxDVPDurationHours.type]: getHours(data[DVP.maxDVPDurationHours.type], data[DVP.maxDVPDurationUnit.type]),
      [DVP.gaugeValue.type]: {
        keepLastValue: data[DVP.gaugeKeepLastValue.type],
        value: data[DVP.gaugeKeepLastValue.type] ? 0.0 : parseInt(data[DVP.gaugeValue.type]),
      },
      [DVP.counterValue.type]: {
        keepLastValue: data[DVP.counterKeepLastValue.type],
        value: data[DVP.counterKeepLastValue.type] ? 0.0 : parseInt(data[DVP.counterValue.type]),
      },
    },
  };
};

// ** Object from the server ** //
// const dvpConfig = {
//   baseRollup: 'MEDIUMROLLUP',
//   maxDVPDurationHours: 24,
//   preventNoData: true,
//   gaugeValue: {
//     keepLastValue: false,
//     value: 0.0,
//   },
//   counterValue: {
//     keepLastValue: false,
//     value: 0.0,
//   },
// };
