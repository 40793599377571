import {useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {
  getFetchTriggeredAlertIsLoading,
  getIncidentTokenMapIsLoading,
  getInvDataFetchTriggeredAlertData,
  getNumberActiveMetrics,
} from '../../../store/selectors';
import '../OverwiewTab.module.scss';

const WhereInfoCubeStatic = ({onIsLoading}: {onIsLoading: Function}) => {
  const isTriggerLoading = useSelector(getFetchTriggeredAlertIsLoading);
  const isTokenMapLoading = useSelector(getIncidentTokenMapIsLoading);
  const triggeredAlertData = useSelector(getInvDataFetchTriggeredAlertData);
  const numberActiveMetrics = useSelector(getNumberActiveMetrics);
  const isLoading = isTriggerLoading || isTokenMapLoading;

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);

  const percentage = (
    Math.round((triggeredAlertData.summary.totalMetrics * 10000) / numberActiveMetrics) / 100
  ).toFixed(2);

  return (
    <div styleName="info-cube-container">
      <div>
        <div styleName="where-percentage">{numberActiveMetrics === 0 ? '--' : `${percentage}%`}</div>
        <div styleName="where-percentage-commentary">of included metrics</div>
      </div>
      <div styleName="info-cube-where-grid">
        <div styleName="grid-cell left">
          {triggeredAlertData.type === 'noData' ? 'Metrics in no data' : 'Metrics in alert'}
        </div>
        <div styleName="grid-cell right">{triggeredAlertData.summary.totalMetrics}</div>
        <div styleName="grid-cell left">Metrics defined in the alert</div>
        <div styleName="grid-cell right">{numberActiveMetrics}</div>
      </div>
    </div>
  );
};

export default WhereInfoCubeStatic;
