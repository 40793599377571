import {useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';

const alertsConfigurationRoot = 'api/v1/alerts/';

const fetchConfigurationAlerts = () => {
  return get(`${alertsConfigurationRoot}`);
};

export default function fetchConfigurationAlertsProvider() {
  const queryClient = useQueryClient();

  const queryKey = [QUERY_KEYS.configurationAlerts];
  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchQuery: () => queryClient.fetchQuery(queryKey, fetchConfigurationAlerts()),
    useQuery: () =>
      useQuery(queryKey, () => fetchConfigurationAlerts(), {
        retry: false,
      }),
  };
}
