// @flow
import React, {useEffect, useMemo, useRef, useState} from 'react';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {isObject} from 'lodash';
import * as commonSelectors from 'profile/store/selectors';
import SmartTooltip from 'common/componentsV2/Tooltip';
import SelectAndt, {DIRECTION_LEFT, THEME_LIGHT, TYPE_SIMPLE} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {Modal} from 'react-bootstrap';
import {queryErrorHandler} from 'reactQuery/queryClient';
import Calender from 'common/components/dateTime/Calender';
import TimeInput from 'common/components/dateTime/TimeInput';
import SnoozeActionMetrics from 'alerts.console.new/components/alertsList/alertActions/SnoozeActionMetrics';
import {
  QUICK_SNOOZE_KEYS,
  QUICK_SNOOZE_OPTIONS,
  setTtlDate,
} from 'alerts.console.new/services/alertsConsoleSnoozeService';
import {ALERT_TYPES} from 'alerts.console.new/services/alertsConsoleService';
import {
  endSnoozeTriggerMetrics,
  endStopLearningTriggerMetrics,
  snoozeTriggerMetrics,
  stopLearningTriggerMetrics,
} from 'alerts.console.new/api/api';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import fetchTriggeredAlertMetricsProvider from 'alerts.console.new/api/fetchTriggeredAlertMetricsProvider';
import fetchGroupAlertsWithMetricsProvider from 'investigationNewAlertConsole/api/fetchGroupAlertsWithMetricsProvider';
import {modalRouting} from 'investigationNewAlertConsole/services/investigationService';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import SnoozeActionIndicator from './SnoozeActionIndicator';

import './SnoozeAction.module.scss';

const SnoozeAction = ({
  alertGroupId,
  isTriage,
  queryParams,
  trigger,
}: {
  alertGroupId: Number,
  isTriage: Boolean,
  queryParams: Object,
  trigger: Object,
}) => {
  const triggeredAlertsProvider = fetchTriggeredAlertsProvider(queryParams);

  const {pageNum, pageSize, ...metricsQueryParams} = queryParams ?? {};
  const triggeredAlertMetricsProvider = fetchTriggeredAlertMetricsProvider(trigger?.alertTriggerId, metricsQueryParams);

  // Need to update the trigger of triage react query cache
  const triageReactQueryParams = queryParams[modalRouting.TRIGGER_ID]
    ? queryParams
    : {
        [modalRouting.ANOMALY_ID]: alertGroupId,
        [modalRouting.TRIGGER_ID]: trigger?.alertTriggerId,
        [modalRouting.INVESTIGATION_MODAL]: true,
      };
  const triggeredAlertGroupProvider = fetchGroupAlertsWithMetricsProvider(triageReactQueryParams);

  const meId = useSelector(commonSelectors.getProfileId);

  const snoozedMetricsAddedArrayRef = useRef();
  const snoozedMetricsRemovedArrayRef = useRef();
  const stopLearningMetricsAddedArrayRef = useRef();
  const stopLearningMetricsRemovedArrayRef = useRef();

  const tomorrowsDate = moment()
    .add(1, 'days')
    .toDate();

  const minDate = new Date();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [summaryTtlDate, setSummaryTtlDate] = useState(
    setTtlDate(trigger?.snoozeSummary, trigger?.stopLearningSummary),
  );

  useEffect(() => {
    const tmpSummaryTtlDate = setTtlDate(trigger?.snoozeSummary, trigger?.stopLearningSummary);
    setSummaryTtlDate(tmpSummaryTtlDate);
  }, [trigger?.snoozeSummary, trigger?.stopLearningSummary]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const dateChanged = (date) => {
    setSummaryTtlDate(date);
  };

  const okClicked = async () => {
    const payload = {
      alertTriggerId: trigger.alertTriggerId,
      duration: summaryTtlDate
        ? moment(summaryTtlDate).unix() - moment().unix()
        : moment(tomorrowsDate).unix() - moment().unix(), // put in var and use once
      timeScale: trigger?.timeScale,
      userId: meId,
    };
    const meta = {
      alertConfigurationId: trigger.alertConfigurationId,
      includeSTL: false,
    };

    try {
      const promises = [];
      if (snoozedMetricsAddedArrayRef.current?.length > 0) {
        const snoozeAddPayload = {
          ...payload,
          metricIds: snoozedMetricsAddedArrayRef.current,
        };
        promises.push(snoozeTriggerMetrics({payload: snoozeAddPayload, meta}).promise);
      }

      if (snoozedMetricsRemovedArrayRef.current?.length > 0) {
        const snoozeRemovePayload = {
          ...payload,
          metricIds: snoozedMetricsRemovedArrayRef.current,
        };
        promises.push(endSnoozeTriggerMetrics({payload: snoozeRemovePayload, meta}).promise);
      }

      if (stopLearningMetricsAddedArrayRef.current?.length > 0) {
        const stopLearningAddedPayload = {
          ...payload,
          metricIds: stopLearningMetricsAddedArrayRef.current,
        };
        promises.push(stopLearningTriggerMetrics({payload: stopLearningAddedPayload}).promise);
      }

      if (stopLearningMetricsRemovedArrayRef.current?.length > 0) {
        const stopLearningRemovePayload = {
          ...payload,
          metricIds: stopLearningMetricsRemovedArrayRef.current,
        };
        promises.push(endStopLearningTriggerMetrics({payload: stopLearningRemovePayload}).promise);
      }
      await Promise.all(promises);
    } catch (e) {
      const error = {
        title: 'Snooze Action',
        description: `Error while snoozing trigger:\n${JSON.stringify(e)}`,
      };
      queryErrorHandler(error);
    } finally {
      await triggeredAlertsProvider.invalidate(QUERY_KEYS.triggeredAlerts);
      await triggeredAlertMetricsProvider.invalidate(QUERY_KEYS.triggeredAlertMetrics);
      await triggeredAlertGroupProvider.invalidate();
    }

    closeModal();
  };

  const snoozeDdlItemSelected = async (option) => {
    const payload = {
      alertTriggerId: trigger.alertTriggerId,
      userId: meId,
      timeScale: trigger.timeScale,
    };
    const meta = {
      alertConfigurationId: trigger.alertConfigurationId,
      includeSTL: trigger?.type === ALERT_TYPES.ANOMALY,
    };
    const key = option.value;
    if (key === QUICK_SNOOZE_KEYS.CUSTOM) {
      openModal();
      return;
    }
    try {
      if (key === QUICK_SNOOZE_KEYS.END_SNOOZE) {
        setSummaryTtlDate(null);
        await endSnoozeTriggerMetrics({payload, meta}).promise;
      } else if (Object.values(QUICK_SNOOZE_KEYS).includes(key)) {
        const snoozeDuration = QUICK_SNOOZE_OPTIONS.find((a) => a.key === key).value;
        const newDate = moment()
          .add(snoozeDuration, 'seconds')
          .toDate();
        setSummaryTtlDate(newDate);
        const snoozePayload = {...payload, duration: snoozeDuration};
        await snoozeTriggerMetrics({payload: snoozePayload, meta});
      }
    } catch (er) {
      const error = {
        title: 'Snooze Action',
        description: `Error while snoozing trigger for ${key}:\n${isObject(er) ? JSON.stringify(er) : er}`,
      };
      queryErrorHandler(error);
    } finally {
      await triggeredAlertsProvider.invalidate(QUERY_KEYS.triggeredAlerts);
      await triggeredAlertMetricsProvider.invalidate(QUERY_KEYS.triggeredAlertMetrics);
      await triggeredAlertGroupProvider.invalidate();
    }
  };

  const getSnoozeOptions = useMemo(() => {
    const ret = [];

    QUICK_SNOOZE_OPTIONS.forEach((opt) => {
      switch (opt.key) {
        case 'endSnooze':
          if (summaryTtlDate) {
            ret.push({value: opt.key, label: opt.text});
          }
          break;
        default:
          ret.push({value: opt.key, label: opt.text});
      }
    });
    return ret;
  }, [summaryTtlDate]);

  return (
    <>
      <div
        className="alert-clickable-item"
        styleName={['snooze-container', isTriage ? 'snooze-container-triage' : ''].join(' ')}
        role="button"
      >
        <SelectAndt
          options={getSnoozeOptions}
          type={TYPE_SIMPLE}
          theme={THEME_LIGHT}
          onChange={snoozeDdlItemSelected}
          direction={DIRECTION_LEFT}
          automationId="alertSnoozeAction"
          customComponent={{
            DropdownIndicator: (props) => (
              <SmartTooltip {...props} placement={isTriage ? 'bottom' : 'top'} content="Snooze" delay={400}>
                {isTriage && summaryTtlDate ? (
                  <SnoozeActionIndicator trigger={trigger} isTriage={isTriage} />
                ) : (
                  <div styleName="value-container">
                    <i className="icon icn-action24-snooze" styleName="snooze-icon action" />
                  </div>
                )}
              </SmartTooltip>
            ),
          }}
        />
      </div>

      <Modal
        show={isModalOpen}
        dialogClassName="bc modal-xlg"
        bsSize="large"
        onHide={closeModal}
        className="alert-clickable-item"
      >
        <div>
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>Custom Snooze</Modal.Title>
            <button className="btn btn-flat btn-icon-36 btn-secondary" onClick={closeModal} type="button">
              <i className="icon icn-icon-table-delete" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body-message" styleName="snooze-modal-body">
              <div styleName="left-panel">
                <Calender
                  date={summaryTtlDate || tomorrowsDate}
                  shownDate={summaryTtlDate || tomorrowsDate}
                  onChange={dateChanged}
                  minDate={minDate}
                />

                <TimeInput date={summaryTtlDate || tomorrowsDate} onChange={dateChanged} />
              </div>

              <div styleName="right-panel">
                <SnoozeActionMetrics
                  trigger={trigger}
                  queryParams={queryParams}
                  onDataChanged={(data) => {
                    snoozedMetricsAddedArrayRef.current = data.snoozedMetricsAdded;
                    snoozedMetricsRemovedArrayRef.current = data.snoozedMetricsRemoved;
                    stopLearningMetricsAddedArrayRef.current = data.stopLearningMetricsAdded;
                    stopLearningMetricsRemovedArrayRef.current = data.stopLearningMetricsRemoved;
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-flat btn-secondary" onClick={() => closeModal()} type="button">
              CANCEL
            </button>
            <button className="btn btn-flat btn-primary" onClick={okClicked} type="button">
              OK
            </button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default SnoozeAction;
