import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {getSelectedDataStreamDvp} from 'bc/store/selectors';
import {
  DVP,
  getUnitFormatted,
  getValueFormated,
} from 'bc/components/streams/editor/missingDataSection/missingDataService';

const title = {
  fontWeight: '500',
  marginRight: 4,
};

const MissingDataSummary = () => {
  const dvpConfig = useSelector(getSelectedDataStreamDvp);

  return (
    <div>
      {dvpConfig && (
        <Fragment>
          <div>
            <span style={title}>In case of No Data</span> <span>{DVP.dvpConfig.options[1].label}</span>
          </div>
          <div>
            <span style={title}>For max duration of</span>{' '}
            <span>{getValueFormated(dvpConfig.maxDVPDurationHours)}</span>{' '}
            <span>{getUnitFormatted(dvpConfig.maxDVPDurationHours).label}</span>
          </div>
          <div>
            <span style={title}>For Gauge fill in with</span>{' '}
            {dvpConfig?.gaugeValue?.keepLastValue ? (
              <span>{DVP.gaugeKeepLastValue.options[0].label}</span>
            ) : (
              <span>{dvpConfig.gaugeValue?.value}</span>
            )}
          </div>
          <div>
            <span style={title}>For Counters fill in with</span>{' '}
            {dvpConfig?.counterValue?.keepLastValue ? (
              <span>{DVP.counterKeepLastValue.options[1].label}</span>
            ) : (
              <span>{dvpConfig.counterValue?.value}</span>
            )}
          </div>
        </Fragment>
      )}
      {!dvpConfig && (
        <Fragment>
          <div>
            <span style={title}>In case of No Data</span> <span>{DVP.dvpConfig.options[0].label}</span>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default MissingDataSummary;
