import {combineEpics} from 'redux-observable';
import * as profileActions from 'profile/store/actions';
import * as commonActions from 'common/store/actions';
import highchartsInit from 'charts/highchartsInit';
import Highcharts from 'highcharts';
import {getCorrectTimezoneName} from 'common/utils/dateService';
import moment from 'moment';

const setTimeZoneToCharts = (action$) =>
  action$.ofType(profileActions.timeZoneChanged.TYPE).flatMap(({payload}) => {
    const tzName = getCorrectTimezoneName(payload);
    Highcharts.setOptions({
      time: {
        // The support for DST in Highcharts doesn't seem to work, thus needed to add this calculation instead of simply using the timezone property.
        // Consider upgrading the Highcharts component.
        // The epic setTimeZoneToCharts running when action timeZoneChanged is called. This happens when setMe action (updateTimeZoneSetMe epic) is called
        // which means that timeZone will always be initialized.
        getTimezoneOffset(timestamp) {
          const timezoneOffset = moment.tz(timestamp, tzName).utcOffset();
          return -timezoneOffset;
        },
      },
    });
    return [];
  });

const init = (action$) =>
  action$
    .ofType(commonActions.appInit.TYPE)
    .map(() => highchartsInit())
    .flatMap(() => []);

export default combineEpics(init, setTimeZoneToCharts);
