// @flow
import React from 'react';
import {useField} from 'react-final-form';
import {makeStyles} from '@material-ui/core';
import Input from 'common/componentsV2/Input';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {useSelector} from 'react-redux';
import useUserSettingsStyles from 'userSettings/pages/useUserSettings.styles';
import {palette} from 'app/styles/theme';
import {getIsAdminUser} from 'profile/store/selectors';
import {getUserMinPasswordLength, getAdminMinPasswordLength} from 'userSettings/store/selectors';
import SubTitle from '../SubTitle';

const useStyles = makeStyles((theme) => ({
  passwordHint: {
    padding: '8px',
    backgroundColor: `${theme.palette.blue[100]}`,
    border: `2px solid ${theme.palette.blue[300]}`,
    borderRadius: '6px',
    marginTop: '28px',
  },
  error: {
    backgroundColor: palette.white[500],
    border: `2px solid ${theme.palette.red[500]}`,
  },
}));

const PasswordSettings = () => {
  const minUserPasswordLength = useSelector(getIsAdminUser)
    ? useSelector(getAdminMinPasswordLength)
    : useSelector(getUserMinPasswordLength);

  const regex = new RegExp(`^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{${minUserPasswordLength},101}$`);
  const isValid = (val) => regex.test(val || '');
  const {
    input: {value: currentPassword, onChange: handleCurrentPassword},
  } = useField('currentPassword');
  const {
    input: {value: newPassword, onChange: handleNewPassword, onFocus: onFocusNewPassword, onBlur: onBlurNewPassword},
    meta: {invalid: invalidNewPassword, touched: touchedNewPassword, active: activeNewPassword},
  } = useField('newPassword', {
    validate: (val, allVal) => allVal.currentPassword !== undefined && !isValid(val),
  });
  const {
    input: {
      value: confirmNewPassword,
      onChange: handleConfirmNewPassword,
      onFocus: onFocusConfirmPassword,
      onBlur: onBlurConfirmPassword,
    },
    meta: {invalid: invalidConfirmNewPassword, touched: touchedConfirmPassword, active: activeConfirmPassword},
  } = useField('confirmNewPassword', {
    validate: (val, allVal) => allVal.currentPassword !== undefined && !isValid(val),
  });

  const classes = useStyles();
  const userSettingsClasses = useUserSettingsStyles();

  const isInValidNewPassword = touchedNewPassword && invalidNewPassword;
  const isInValidConfirmPassword = touchedConfirmPassword && invalidConfirmNewPassword;

  return (
    <>
      <SubTitle title="Password" />
      <div className={userSettingsClasses.detailsSection}>
        <div className={userSettingsClasses.detailsField}>
          <div className={userSettingsClasses.title}>Current Password</div>
          <Input type="password" value={currentPassword} onChange={handleCurrentPassword} placeHolder="Required" />
        </div>
        <div>
          <div className={userSettingsClasses.detailsField}>
            <div className={userSettingsClasses.title}>New Password</div>
            <Input
              type="password"
              isInvalid={isInValidNewPassword}
              value={newPassword}
              onChange={handleNewPassword}
              onFocus={onFocusNewPassword}
              onBlur={onBlurNewPassword}
              placeHolder={`Min. ${minUserPasswordLength} Characters`}
            />
          </div>
        </div>
        <div>
          <div className={userSettingsClasses.detailsField}>
            <div className={userSettingsClasses.title}>Confirm Password</div>
            <Input
              type="password"
              isInvalid={isInValidConfirmPassword}
              value={confirmNewPassword}
              onChange={handleConfirmNewPassword}
              onFocus={onFocusConfirmPassword}
              onBlur={onBlurConfirmPassword}
              placeHolder={`Min. ${minUserPasswordLength} Characters`}
            />
          </div>
        </div>
        {(activeNewPassword || activeConfirmPassword || isInValidConfirmPassword || isInValidNewPassword) && (
          <div>
            <div
              className={`${classes.passwordHint} ${
                isInValidConfirmPassword || isInValidNewPassword ? classes.error : ''
              }`}
            >
              <TypographyBox variant="body1">- At least {minUserPasswordLength} characters</TypographyBox>
              <TypographyBox variant="body1">- Upper and lower case</TypographyBox>
              <TypographyBox variant="body1">- At least 1 number</TypographyBox>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PasswordSettings;
