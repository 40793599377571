// @flow
import React from 'react';
import './GaDropdown.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  isLoading: boolean,
  items: Array,
  item: Object,
  disabled: boolean,
  onChange: Function,
  ddlId: string,
  ddlTitle: string,
};

const GaDropdown = (props: PropTypes) => {
  const selectedIndex = props.items.findIndex((val) => (props.item ? props.item.id === val.id : false));
  return (
    <div styleName="root">
      <SelectAndt
        id={props.ddlId}
        className="andt-dropdown"
        styleName="dropdown-width"
        disabled={(props.isLoading !== false && !props.items.length) || props.disabled}
        options={props.items}
        getOptionLabel={(val) => val.displayName}
        getOptionValue={(val) => val.id}
        type={TYPE_NO_SEARCH}
        theme={THEME_LIGHT}
        value={props.isLoading ? null : props.items[selectedIndex]}
        onChange={(item) => props.onChange(item.id)}
        placeholder={props.isLoading !== false ? 'Loading...' : props.ddlTitle}
        automationId={props.ddlTitle}
      />
    </div>
  );
};

export default GaDropdown;
