// @flow
import React, {useMemo, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import SwitchButton from 'common/componentsV2/SwitchButton';
import {
  duplicateExpressionTree,
  initExpressionTreeModel,
  removeExpression,
  selectTreeBranch,
  setIsShowComposites,
} from 'metrics/store/actions';
import * as selectors from 'metrics/store/selectors';
import {getEnableNewMetricExplorer} from 'profile/store/selectors';
import {emptyExpressionTree} from 'metrics/store/reducers/expressionBuilderReducer';

const useStyles = makeStyles(({palette}) => ({
  option: {
    display: 'flex',
    height: 40,
    alignItems: 'center',
    padding: 10,
    cursor: 'pointer',
    margin: 3,
    borderRadius: 4,
    fontSize: 16,
    '&:hover': {
      backgroundColor: `rgba(38, 113, 255, 0.1)`,
    },
  },
  disabled: {
    color: palette.gray[400],
    pointerEvents: 'none',
  },
}));

const ExpressionActions = ({expressionTreeId, isModal}: {expressionTreeId: string, isModal: boolean}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [isMoreOptionsPanelOpen, setMoreOptionsPanelOpen] = useState(false);
  const expressionTrees = useSelector(selectors.getExpressionTrees);
  const selectedExpressionId = useSelector(selectors.getSelectedExpressionId);
  const isNewMetricExplorerEnabled = useSelector(getEnableNewMetricExplorer);

  const expressionTreeModel = useMemo(() => expressionTrees.find((item) => item.id === expressionTreeId), [
    expressionTrees,
    expressionTreeId,
  ]);

  const isShowComposite = useMemo(() => expressionTreeModel.isShowComposites, [expressionTreeModel]);
  const expressionTreesLength = useMemo(() => expressionTrees.length, [expressionTrees]);

  const isMetricExplorerPage = history.location.pathname.includes('metrics-explorer');

  const toggleActionMenuPanel = useCallback(
    (isOpen) => {
      if (selectedExpressionId !== expressionTreeId) {
        const branchId = expressionTreeModel.expressionTree.root.id;
        dispatch(selectTreeBranch({branchId, selectedExpressionId: expressionTreeId}));
      }
      setMoreOptionsPanelOpen(isOpen);
    },
    [selectedExpressionId, expressionTreeId],
  );

  const handleDuplicate = useCallback(() => {
    if (expressionTreesLength < 5) {
      dispatch(duplicateExpressionTree(expressionTreeId));
    }
  }, [expressionTreeId, expressionTreesLength]);

  const handleDelete = useCallback(() => {
    dispatch(removeExpression(expressionTreeId));
  }, [expressionTreeId]);

  const handleShowInMetricExplorer = useCallback(() => {
    window.sessionStorage.setItem('metricExplorerTree', JSON.stringify(expressionTreeModel.expressionTree));
    window.open('/#!/r/metrics-explorer');
  }, [expressionTreeModel]);

  const handleClear = useCallback(() => {
    dispatch(initExpressionTreeModel({expressionTree: emptyExpressionTree}));
  }, []);

  const optionsHeaderMenu = useMemo(() => {
    return (
      <React.Fragment key={expressionTreeId}>
        <div
          className={`${classes.option} ${expressionTreesLength === 1 ? classes.disabled : ''}`}
          onClick={handleDelete}
        >
          Delete
        </div>
        <div className={classes.option} onClick={handleClear}>
          Clear Expression Tree
        </div>
        <div
          className={`${classes.option} ${expressionTreesLength > 4 || isModal ? classes.disabled : ''}`}
          onClick={handleDuplicate}
          automation-id={
            expressionTreesLength > 4
              ? 'metricExplorerDuplicateExpressionDisabled'
              : 'metricExplorerDuplicateExpression'
          }
        >
          Duplicate
        </div>
        {isNewMetricExplorerEnabled && !isMetricExplorerPage && (
          <div className={classes.option} onClick={handleShowInMetricExplorer}>
            <div>Show In Metric Explorer</div>
          </div>
        )}
        <div className={`${classes.option}`}>
          <div className="mr_0-5">Show Composites</div>
          <SwitchButton
            isChecked={isShowComposite}
            onChange={(checked) => dispatch(setIsShowComposites(checked))}
            automationId="showComposites"
          />
        </div>
      </React.Fragment>
    );
  }, [expressionTreeModel, expressionTreeId, expressionTreesLength, isShowComposite, isModal]);

  return (
    <FormDdl
      popoverComponent={optionsHeaderMenu}
      buttonComponent={
        <div
          automation-id="metricExplorerExpressionTree3DotsMenu"
          className={`icon icn-general16-3dot ${isMoreOptionsPanelOpen ? 'color_blue-500' : ''}`}
        />
      }
      onToggle={(isOpen) => toggleActionMenuPanel(isOpen)}
      width={125}
      isOpen={isMoreOptionsPanelOpen}
    />
  );
};

export default React.memo(ExpressionActions);
