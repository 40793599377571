// @flow
import React from 'react';
import {connect} from 'react-redux';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import Info from 'common/componentsV2/Info';
import * as selectors from 'bc/store/selectors';
import {
  clearSegmentData,
  setSegmentMessageTypes,
  fetchSegmentMessageTypes as fetchSegmentMessageTypesAction,
  fetchSegmentJsonDimensions as fetchSegmentJsonDimensionsAction,
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
  setSelectedStreamUiKeyVal as setSelectedStreamUiKeyValAction,
} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isStreamEditable} from 'bc/services/dataStreamService';
import JsonEditorModal from './jsonEditorModal/JsonEditorModal';

import './SegmentStreamEditor.module.scss';

const EMPTY_MESSAGE_TYPE = [];

type PropTypes = {
  dataStream: Object,

  // connect
  messageTypes: Array,
  isMessageTypesLoading: boolean,
  clearSegmentData: Function,
  setSelectedStreamKeyVal: Function,
  setSelectedStreamUiKeyVal: Function,
  setSegmentMessageTypes: Function,
  fetchSegmentMessageTypes: Function,
  fetchSegmentJsonDimensions: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    messageTypes: selectors.getSegmentMessageTypesItems(state),
    isMessageTypesLoading: selectors.getSegmentMessageTypesIsLoading(state),
  }),
  {
    clearSegmentData,
    setSegmentMessageTypes,
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
    setSelectedStreamUiKeyVal: setSelectedStreamUiKeyValAction,
    fetchSegmentMessageTypes: fetchSegmentMessageTypesAction,
    fetchSegmentJsonDimensions: fetchSegmentJsonDimensionsAction,
  },
)(
  class EventTypes extends React.PureComponent {
    props: PropTypes;

    state = {
      messageTypesSelected: EMPTY_MESSAGE_TYPE,
      isOpenJsonEditorModal: false,
      selectedRadio: 'list',
    };

    componentDidMount() {
      const {fetchSegmentMessageTypes, dataStream} = this.props;

      fetchSegmentMessageTypes();
      if (dataStream?.messageTypes && dataStream?.messageTypes.length) {
        this.setState({messageTypesSelected: dataStream.messageTypes});
      } else if (dataStream?.uiState?.customJson) {
        this.setState({selectedRadio: 'json'});
      }
    }

    setSelectedRadio = (changeEvent) => {
      this.setState({selectedRadio: changeEvent.target.value}, () => {
        this.props.setSelectedStreamUiKeyVal({customJson: this.state.selectedRadio === 'json'});
      });
      this.props.clearSegmentData();
    };

    onChange = (items) => {
      const arr = items.map((item) => item.value);
      this.setState({messageTypesSelected: arr});
    };

    onCollect = () => {
      this.props.setSegmentMessageTypes({messageTypes: this.state.messageTypesSelected});
      this.setState({selectedRadio: 'list'});
    };

    openJsonEditorModal = () => {
      this.setState({isOpenJsonEditorModal: true});
    };

    closeJsonEditorModal = () => {
      this.setState({isOpenJsonEditorModal: false});
    };

    setStreamJson = (val) => {
      const {dataStream, setSelectedStreamKeyVal, setSelectedStreamUiKeyVal, fetchSegmentJsonDimensions} = this.props;

      const jsonObj = val.json.replaceAll('\n', '').replaceAll(' ', '');

      // clear message types selection
      this.setState({messageTypesSelected: EMPTY_MESSAGE_TYPE, selectedRadio: 'json'});
      setSelectedStreamKeyVal({messageTypes: EMPTY_MESSAGE_TYPE});

      // set and send json
      setSelectedStreamUiKeyVal({json: jsonObj});
      fetchSegmentJsonDimensions({
        dataSourceId: dataStream.dataSourceId,
        json: jsonObj,
      });
    };

    render() {
      const {dataStream, messageTypes, isMessageTypesLoading} = this.props;
      const {selectedRadio} = this.state;
      const selectedMessageTypes = messageTypes.filter((msgType) =>
        this.state.messageTypesSelected.includes(msgType.value),
      );
      const isDisabled = selectedMessageTypes.length === 0;

      return (
        <div className="shell-col" styleName="root">
          <PanelHeader title="Spec Type" isEditable={isStreamEditable(dataStream.state)} />

          <div styleName="panel">
            <div styleName="radio-panel">
              <input
                type="radio"
                name="queryType"
                id="queryTypeList"
                value="list"
                checked={selectedRadio === 'list'}
                onChange={this.setSelectedRadio}
              />
            </div>
            <div styleName="title-panel">
              <label htmlFor="queryTypeList">Auto Segment Spec</label>
            </div>
            <div styleName="text-wrap">
              These are the Segment message types.
              <div styleName="info">
                <Info
                  placement="top"
                  toolTipType="large"
                  text={
                    <span>
                      Options are:
                      <br />
                      Identify (indicates which user),
                      <br />
                      Track (indicates what the user is doing),
                      <br />
                      Page (indicates the web page being used)
                      <br />
                      Screen (the application screen that is visited by the user).
                    </span>
                  }
                />
              </div>
            </div>
            <div styleName="type-wrap">
              <div styleName="input-wrap">
                <SelectAndt
                  id="messageTypes"
                  automationId="messageTypes"
                  extraClassName="alerts-dropdown-btn"
                  options={messageTypes}
                  type={TYPE_NEW_MULTI_NO_SEARCH}
                  theme={THEME_HIGHLIGHTED}
                  onChange={this.onChange}
                  value={selectedMessageTypes || EMPTY_MESSAGE_TYPE}
                  isMulti
                  isClearable={false}
                  closeMenuOnSelect={false}
                  buttonWidth={190}
                  optionHeight={40}
                  isLoading={isMessageTypesLoading}
                  disabled={isMessageTypesLoading || selectedRadio === 'json'}
                />
              </div>
              <div styleName="button-wrap">
                <button
                  type="button"
                  className="btn btn-raised"
                  styleName="btn-collect"
                  automation-id="segmentGetTypes"
                  disabled={isDisabled || selectedRadio === 'json'}
                  onClick={this.onCollect}
                >
                  Collect
                </button>
              </div>
            </div>
          </div>

          <div styleName="panel">
            <div styleName="radio-panel">
              <input
                type="radio"
                name="queryType"
                id="queryTypeJson"
                value="json"
                checked={selectedRadio === 'json'}
                onChange={this.setSelectedRadio}
              />
            </div>
            <div styleName="title-panel">
              <label htmlFor="queryTypeJson">Manual Segment Spec</label>
            </div>
            <div styleName="text-wrap">Enter a sample JSON with the relevant fields</div>
            <div styleName="button-wrap">
              <button
                type="button"
                className="btn btn-raised"
                styleName="btn-open"
                automation-id="segmentOpenJsonModal"
                disabled={selectedRadio !== 'json'}
                onClick={this.openJsonEditorModal}
              >
                Event Sample
              </button>
            </div>
          </div>

          {this.state.isOpenJsonEditorModal && (
            <JsonEditorModal
              isOpen={this.state.isOpenJsonEditorModal}
              onClose={this.closeJsonEditorModal}
              dataStream={dataStream}
              json={dataStream.uiState?.json ? dataStream.uiState.json : ''}
              setStreamJson={this.setStreamJson}
            />
          )}
        </div>
      );
    }
  },
);
