// eslint-disable-next-line import/no-cycle
import {pollingIntervals} from 'bc/services/dataStreamService';

export const FILTER_TYPES = [
  {
    label: 'Include',
    value: 'in',
  },
  {
    label: 'Exclude',
    value: 'notIn',
  },
];

export const AGGREGATION_GROUP_BY_FUNCTION = [
  {
    label: 'Average',
    value: 'avg',
  },
  {
    label: 'Sum',
    value: 'sum',
  },
  {
    label: 'Max',
    value: 'max',
  },
  {
    label: 'Min',
    value: 'min',
  },
];

export const ROLLUP_AGGREGATION_FUNCTION = [
  {
    label: 'Average',
    value: 'avg',
  },
  {
    label: 'Sum',
    value: 'sum',
  },
];

export const ROLLUP_PERIODS = [
  {
    label: '1 Minute',
    value: 1,
  },
  {
    label: '5 Minutes',
    value: 5,
  },
  {
    label: '1 Hour',
    value: 60,
  },
];

export const getMaxRollupPeriodPerPollingInterval = (pollingInterval) => {
  switch (pollingInterval) {
    case pollingIntervals.m1.key:
      return ROLLUP_PERIODS[0].value;
    case pollingIntervals.m5.key:
    case pollingIntervals.m10.key:
    case pollingIntervals.m15.key:
    case pollingIntervals.m30.key:
      return ROLLUP_PERIODS[1].value;
    case pollingIntervals.hourly.key:
      return ROLLUP_PERIODS[2].value;
    default:
      return 5;
  }
};

export const getEditorValidationMessage = (stream) => {
  if (stream.id === 'EditRunning') {
    return 'This stream is active, recollection is not enabled. Please duplicate the stream to change the collected data.';
  }
  if (!stream.customQuery && !stream.metricName) {
    return 'Stream metric name is empty, select a metric to continue.';
  }
  if (stream.customQuery && !stream.query) {
    return 'Empty query, must write a query.';
  }
  if (!stream.dimensions.length) {
    return 'No dimension grouping selected.';
  }
  return null;
};
