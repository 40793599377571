import {createSelector} from 'reselect';
import {selectors as commonSelectors} from 'common';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const {getLabels} = commonSelectors;

// Data
export const getLabelsData = createSelector(
  getLabels,
  (i) => i?.data || EMPTY_OBJECT,
);
export const getFetchLabels = createSelector(
  getLabelsData,
  (i) => i?.fetchLabels || EMPTY_OBJECT,
);
export const getIsLabelsLoading = createSelector(
  getFetchLabels,
  (i) => {
    return i.isLoading;
  },
);
export const getFetchLabelsData = createSelector(
  getFetchLabels,
  (i) => i?.data || EMPTY_ARRAY,
);
export const getLabelsForFilter = createSelector(
  getFetchLabelsData,
  (i) =>
    i.map((o) => ({
      label: o.label || o.name,
      value: o.name || o._id,
      isDisabled: false,
      type: 'LABELS',
    })),
);
