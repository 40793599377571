import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PageLayout from 'common/componentsV2/PageLayout';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import './IntegrationsPage.module.scss';
import {fetchConfigurationFile} from 'common/store/actions';
import {getDataSourceTypesItemsIsLoading, getIntegrationPageConfig} from 'bc/store/selectors';
import {StringParam, useQueryParams} from 'use-query-params';
import NewEditChannel from 'channels/pages/NewEditChannel';
import {Route, useHistory} from 'react-router-dom';
import IntegrationsFilter from './IntegrationsFilter';
import IntegrationsBoxesContainer from './IntegrationsBoxesContainer';
import {fetchDataSources, fetchDataSourceTypes} from '../../store/actions';
import {initDefaults} from './IntegrationsPageService';

const IntegrationsPage = ({match}: {match: Object}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dataSourceTypesIsLoading = useSelector(getDataSourceTypesItemsIsLoading);
  const integrationPageConfig = useSelector(getIntegrationPageConfig);

  const [query, setQuery] = useQueryParams({
    selectedCategory: StringParam,
    filterText: StringParam,
    selectedTab: StringParam,
  });

  const {selectedTab, filterText, selectedCategory} = query;

  const onModalClose = useCallback(() => {
    const search = `filterText=${filterText}&selectedCategory=${selectedCategory}&selectedTab=${selectedTab}`;
    history.replace(`/integrations?${search}`);
  }, [query]);

  useEffect(() => {
    if (!dataSourceTypesIsLoading) {
      dispatch(fetchDataSourceTypes());
    }
    if (!integrationPageConfig) {
      dispatch(
        fetchConfigurationFile({template: 'integrationsConfig.json', resource: 'bc'}, {name: 'integrationsConfig'}),
      );
    }
    dispatch(fetchDataSources());
    if (!selectedTab || selectedTab.length === 0) {
      setQuery(initDefaults);
    }
  }, []);

  return (
    <PageLayout
      header={
        <header styleName="page-title">
          <Title type={TITLE_TYPES.PAGE_TITLE}>Catalog</Title>
        </header>
      }
      shouldOverflowY
    >
      <div styleName="content">
        <Route
          path={[`${match.url}/new/:channelType`]}
          render={(props) => <NewEditChannel {...props} onClose={onModalClose} />}
        />
        <div styleName="filter-container">
          <IntegrationsFilter pageConfig={integrationPageConfig} />
        </div>
        <div styleName="boxes-container">
          <IntegrationsBoxesContainer pageConfig={integrationPageConfig} />
        </div>
      </div>
    </PageLayout>
  );
};

export default IntegrationsPage;
