import React from 'react';

import {toast} from 'react-toastify';
import {generalErrorMsg} from 'common/utils/notifications/generalNotificationCodes';
import 'react-toastify/dist/ReactToastify.css';
import './ReactToastifyOverrides.css';

import SnackBar, {TYPES} from 'common/componentsV2/snackBar/SnackBar';
import {logNotification} from 'common/store/actions';

const NOTIFICATION_TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};
const formatNotification = (notificationParams, type) => {
  return {
    type,
    title: notificationParams.title || 'No title',
    description: notificationParams.description || '',
  };
};

const toasterParams = (params) => {
  const toastOptions = {
    toastId: params.settings?.uid || 'toast',
  };
  if (params.settings?.autoDismiss) {
    toastOptions.autoClose = params.settings?.autoDismiss !== 0 ? params.settings?.autoDismiss * 1000 : false;
  }
  return toastOptions;
};
const snackBarParams = (params) => {
  return {
    notificationData: params,
    isDismissible: params.settings?.canClose || true,
    uid: params.settings?.uid || 'toast',
    actions: params.actions,
  };
};

const errorToast = (params) => {
  if (params.code !== generalErrorMsg.code) {
    toast(<SnackBar type={TYPES.ERROR} {...snackBarParams(params)} />, {
      ...toasterParams(params),
    });
  }
};

const warningToast = (params) => {
  toast(<SnackBar type={TYPES.WARNING} {...snackBarParams(params)} />, {
    ...toasterParams(params),
  });
};

const infoToast = (params) => {
  toast(<SnackBar type={TYPES.INFO} {...snackBarParams(params)} />, {
    ...toasterParams(params),
  });
};

const successToast = (params) => {
  toast(<SnackBar type={TYPES.SUCCESS} {...snackBarParams(params)} />, {
    ...toasterParams(params),
  });
};

export const error = (params) => {
  errorToast(params);
  return {type: logNotification.TYPE, payload: formatNotification(params, NOTIFICATION_TYPES.ERROR)};
};

export const warning = (params) => {
  warningToast(params);
  return {type: logNotification.TYPE, payload: formatNotification(params, NOTIFICATION_TYPES.WARNING)};
};

export const info = (params) => {
  infoToast(params);
  return {type: logNotification.TYPE, payload: formatNotification(params, NOTIFICATION_TYPES.INFO)};
};

export const success = (params) => {
  successToast(params);
  return {type: logNotification.TYPE, payload: formatNotification(params, NOTIFICATION_TYPES.SUCCESS)};
};

export function useToast(dispatch) {
  // Since this is not actually a hook, and it can be called from outside react
  // Need to pass the dispatch as param
  return {
    error: (params) => {
      dispatch(logNotification(formatNotification(params, NOTIFICATION_TYPES.ERROR)));
      errorToast(params);
    },
    warning: (params) => {
      dispatch(logNotification(formatNotification(params, NOTIFICATION_TYPES.WARNING)));
      warningToast(params);
    },
    info: (params) => {
      dispatch(logNotification(formatNotification(params, NOTIFICATION_TYPES.INFO)));
      infoToast(params);
    },
    success: (params) => {
      dispatch(logNotification(formatNotification(params, NOTIFICATION_TYPES.SUCCESS)));
      successToast(params);
    },
  };
}
