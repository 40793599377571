import {useQuery} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';
import {stringifyAndEscapeQS} from 'common/utils/http';
import {
  syncAcksInCache,
  syncFeedbackInCache,
  calculateQueryKey,
  syncAssigneeInCache,
} from 'investigationNewAlertConsole/api/investigationTriggerCacheUpdate';
import {mapAcknowledgedOwners} from 'alerts.console.new/api/utils';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import {modalRouting} from 'investigationNewAlertConsole/services/investigationService';
import {queryClient} from 'reactQuery/queryClient';

export const alertsConsoleRoot = 'api/v1/alerts-console/';

const fetchTriggeredAlertWithMetricsById = ({payload}) => {
  const url = `${alertsConsoleRoot}triggered-by-id/get-with-metrics?${stringifyAndEscapeQS(payload)}`;
  return get(url);
};

const fetchTriggeredAlertsGroupByAnomaly = ({payload}) => {
  return get(`${alertsConsoleRoot}triggered-group-by-anomaly/get-with-metrics?${stringifyAndEscapeQS(payload)}`);
};

const fetchHelper = async (queryParams) => {
  const calculatedQueryParams = calculateQueryKey(queryParams);
  let triggeredAlert;
  if (calculatedQueryParams?.anomalyIdInv) {
    triggeredAlert = await fetchTriggeredAlertsGroupByAnomaly({
      payload: {anomalyId: calculatedQueryParams.anomalyIdInv},
    });
  } else {
    triggeredAlert = await fetchTriggeredAlertWithMetricsById({
      payload: {triggerId: calculatedQueryParams?.triggerIdInv},
    });
  }

  const users = await fetchUsersProvider().fetchQuery();
  [triggeredAlert] = mapAcknowledgedOwners([triggeredAlert], users);
  return triggeredAlert;
};

export default function fetchGroupAlertsWithMetricsProvider(queryKeyParams) {
  const queryKey = [QUERY_KEYS.triggeredAlertGroupMetrics, queryKeyParams];
  return {
    invalidate: (partialKey) => queryClient.invalidateQueries(partialKey ? [partialKey] : queryKey),
    useQueryGetGroupAlertsWithMetrics: () =>
      useQuery(queryKey, () => fetchHelper(queryKeyParams), {
        retry: false,
        enabled: !(
          queryKeyParams[modalRouting.ANOMALY_ID] === undefined && queryKeyParams[modalRouting.TRIGGER_ID] === undefined
        ),
      }),
    fetchQueryGetGroupAlertsWithMetrics: () =>
      queryClient.fetchQuery(queryKey, () => fetchHelper(queryKeyParams), {
        retry: false,
        enabled: !(
          queryKeyParams[modalRouting.ANOMALY_ID] === undefined && queryKeyParams[modalRouting.TRIGGER_ID] === undefined
        ),
      }),
    useQueryGetGroupAlertIds: () =>
      useQuery(
        queryKey,
        () => fetchHelper(queryKeyParams),
        {
          select: (data) => {
            return data.alerts?.flatMap((alert) => alert.alertTriggerId);
          },
        },
        {
          retry: false,
          enabled: !(
            queryKeyParams[modalRouting.ANOMALY_ID] === undefined &&
            queryKeyParams[modalRouting.TRIGGER_ID] === undefined
          ),
        },
      ),
    useQueryGetTriggeredAlert: () =>
      useQuery(
        queryKey,
        () => fetchHelper(queryKeyParams),
        {
          select: (data) => {
            return data.alerts?.find((alert) => alert.alertTriggerId === queryKeyParams.triggerIdInv);
          },
        },
        {
          retry: false,
          enabled: !(
            queryKeyParams[modalRouting.ANOMALY_ID] === undefined &&
            queryKeyParams[modalRouting.TRIGGER_ID] === undefined
          ),
        },
      ),
    syncAcksInCache: (ackParams) => syncAcksInCache(queryKeyParams, ackParams, queryClient),
    syncAssigneeInCache: (assigneeParams) => syncAssigneeInCache(queryKeyParams, assigneeParams, queryClient),
    syncFeedbackInCache: (feedbackActionType, feedbackParams) =>
      syncFeedbackInCache(queryKeyParams, feedbackActionType, feedbackParams, queryClient),
  };
}
