// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  seKinesisDiametricsChange as seKinesisDiametricsChangeAction,
  setKinesisDiametricsTimeDefinition as setKinesisDiametricsTimeDefinitionAction,
  removeKinesisDiametrics as removeKinesisDiametricsAction,
  setKinesisDiametricsClearAll as setKinesisDiametricsClearAllAction,
  setStreamDiametricsEditorFilterTextInput as setStreamDiametricsEditorFilterTextInputAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import DimensionsAndMetricsEditor from 'bc/components/streams/editor/common/DimensionsAndMetricsEditor';

type PropTypes = {
  dimensions: Array<Object>,
  metrics: Array<Object>,
  filteredUnassignedColumns: Array<Object>,
  timeDefinitionArr: Array,
  timeDefinition: Object,
  filterTextInput: String,
  seKinesisDiametricsChange: Function,
  setKinesisDiametricsClearAll: Function,
  setKinesisDiametricsTimeDefinition: Function,
  removeKinesisDiametrics: Function,
  setStreamDiametricsEditorFilterTextInput: Function,
  dateFormats: Array<Object>,
  isStreamUpdating: Boolean,
};

export default connect(
  (state) => ({
    dimensions: selectors.getKinesisMetaDimensions(state),
    metrics: selectors.getKinesisMetaMetrics(state),
    timeDefinition: selectors.getKinesisTimeDefinition(state),
    timeDefinitionArr: selectors.getKinesisStreamSelectedTimeDefinitionColArr(state),
    filteredUnassignedColumns: selectors.getFilteredKinesisStreamUnAssignedColumns(state),
    filterTextInput: selectors.getStreamMetricsDimentionsFilterTextInput(state),
    dateFormats: selectors.getDataStreamsDateFormats(state),
    isStreamUpdating: selectors.getIsDataStreamUpdating(state),
  }),
  {
    seKinesisDiametricsChange: seKinesisDiametricsChangeAction,
    setKinesisDiametricsClearAll: setKinesisDiametricsClearAllAction,
    setKinesisDiametricsTimeDefinition: setKinesisDiametricsTimeDefinitionAction,
    removeKinesisDiametrics: removeKinesisDiametricsAction,
    setStreamDiametricsEditorFilterTextInput: setStreamDiametricsEditorFilterTextInputAction,
  },
)(
  class KinesisDimensionsAndMetricsEditor extends React.PureComponent {
    props: PropTypes;

    render() {
      const {
        metrics,
        dimensions,
        setKinesisDiametricsTimeDefinition,
        filterTextInput,
        filteredUnassignedColumns,
        timeDefinition,
        timeDefinitionArr,
        removeKinesisDiametrics,
        setStreamDiametricsEditorFilterTextInput,
        seKinesisDiametricsChange,
        setKinesisDiametricsClearAll,
        dateFormats,
        isStreamUpdating,
      } = this.props;

      if (!timeDefinitionArr.length) {
        timeDefinitionArr.push(timeDefinition);
      }

      return (
        <DimensionsAndMetricsEditor
          dimensions={dimensions}
          metrics={metrics}
          filteredUnassignedColumns={filteredUnassignedColumns}
          timeDefinitionArr={timeDefinitionArr}
          timeDefinition={timeDefinition}
          filterTextInput={filterTextInput}
          setStreamDiametricsChange={seKinesisDiametricsChange}
          setStreamClearAllDiametrics={setKinesisDiametricsClearAll}
          setStreamTimeDefinition={setKinesisDiametricsTimeDefinition}
          removeStreamDiametrics={removeKinesisDiametrics}
          setMetricDimentionEditorFilterTextInput={setStreamDiametricsEditorFilterTextInput}
          dateFormats={dateFormats}
          isStreamUpdating={isStreamUpdating}
        />
      );
    }
  },
);
