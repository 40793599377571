// @flow
import React, {useCallback, useMemo} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import {get, isString} from 'lodash';
import {Form, FormSpy, useField} from 'react-final-form';
import {Box, makeStyles} from '@material-ui/core';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import DashboardTitle from 'dashboards/components/fields/DashboardTitle';
import DateRanges from 'dashboards/components/fields/DateRanges';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import Button, {COLORS} from 'common/componentsV2/Button';
import {OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';
import SelectAndt, {
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import OptionWithIcon from 'common/componentsV2/ddl/selectAndt/innerComponents/OptionWithIcon';
import FiltersField from 'dashboards/components/filters/FiltersField';
import TimeScale from 'dashboards/components/fields/TimeScale';
import useTileSync from 'dashboards/hooks/useTileSync';
import TileChartTemplate from 'dashboards/components/TileChartTemplate';
import useExpressionTreesTile from 'dashboards/components/metricTiles/useExpressionTreesTile';
import FilterDDL from 'dashboards/components/metricTiles/FilterDDL';
import WarningNavigationIcon from 'dashboards/components/WarningNavigationIcon';
import TileStatTemplate from 'dashboards/components/TileStatTemplate';
import AlertBellContainer from 'dashboards/components/AlertBellContainer';
import Spinner from 'common/componentsV2/Spinner';
import {useSelector} from 'react-redux';
import {getDashboardUserSettings} from 'dashboards/store/selectors';
import {makeFiltersPayload, parseFiltersPayload} from 'dashboards/utils';
import {getDateValue} from 'common/utils/dateRangeService';
import AnomalyChartsContainer from 'dashboards/components/metricTiles/anomaliesTile/AnomalyChartsContainer';
import {palette} from 'app/styles/theme';

type PropTypes = {
  history: Object,
  dashboardData: Object,
  returnToDashboardPage: Function,
  computedTileOptions: Array,
  tileId: string,
  tileData: Object,
  matchUrl: string,
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexShrink: 0,
    '@global': {
      '.andt-dropdown-control': {
        minHeight: 35,
      },
      '.andt-dropdown-value-container': {
        height: 35,
      },
    },
  },
  squareButton: {
    padding: '10px',
    height: 35,
  },
  cancelEditMode: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 32,
    cursor: 'pointer',
  },
  warningIcon: {
    position: 'relative',
    top: 1,
    color: theme.palette.red[500],
    cursor: 'pointer',
  },
  settingHeader: {
    position: 'absolute',
    left: 10,
    right: 0,
    top: 5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 18,
  },
  wrapperFullSize: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    backgroundColor: theme.palette.white[500],
    borderRadius: 4,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
    padding: 12,
  },
  titleWrapper: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const EMPTY_ARRAY = [];

const CompositeExecuteContainer = ({
  chartId,
  tileData,
  dashboardData,
  isAnonymous,
}: {
  chartId: String,
  tileData: Object,
  dashboardData: Object,
  isAnonymous: boolean,
}) => {
  const {
    input: {value: dateRange},
  } = useField('dateRange');

  const {
    input: {value: filters},
  } = useField('filters');

  const {
    input: {value: timeScale},
  } = useField('timeScale');

  const {
    metrics,
    byTreeExp,
    totalAmount,
    providedResolution,
    validationMessage,
    isCompositeDataLoading,
    compositeErrorsMap,
  } = useExpressionTreesTile({
    dateRange,
    filters,
    timeScale,
    tileData,
    chartId,
    isVisible: true,
  });

  const classes = useStyles();
  const tileType = get(tileData, 'andtGauge.displayProperties.byTreeExp[0].options.andtGauge.mainValue.tileType', '');
  const isPieOrDonut = ['pie', 'donut'].includes(tileType);
  let renderBody;
  if (tileData.tileType === 'anomaliesList') {
    renderBody = <AnomalyChartsContainer tileData={tileData} dateRange={dateRange} />;
  } else if (get(tileData, 'lineChart', false)) {
    renderBody = (
      <TileChartTemplate
        chartId={chartId}
        timeScale={timeScale}
        dateRange={dateRange}
        totalAmount={totalAmount}
        isLoading={isCompositeDataLoading}
        isZoomLoading={isCompositeDataLoading && get(dateRange, 'constRange') === 'c'}
        isAnyLoading={isCompositeDataLoading}
        metrics={metrics || EMPTY_ARRAY}
        validationMessage={validationMessage}
        providedResolution={providedResolution}
        tileData={tileData}
        byTreeExp={byTreeExp}
        dashboardId={dashboardData._id}
        isFullSize
      />
    );
  } else {
    renderBody = (
      <Box width={isPieOrDonut ? 1 : 550} height={300} minWidth={isPieOrDonut ? 850 : 550}>
        <TileStatTemplate
          tileData={tileData}
          metrics={metrics || EMPTY_ARRAY}
          isLoading={isCompositeDataLoading}
          isFullSize
        />
      </Box>
    );
  }

  return (
    <div className={classes.wrapperFullSize}>
      <div className={classes.settingHeader}>
        <div>
          {validationMessage && tileData.tileType !== 'anomaliesList' && (
            <div className={`${classes.warningIcon}`}>
              <WarningNavigationIcon tileId={tileData.id} compositeErrorsMap={compositeErrorsMap} />
            </div>
          )}
          {!isAnonymous && <AlertBellContainer dashboardData={dashboardData} tileId={tileData.id} />}
        </div>
        {isCompositeDataLoading && (
          <div>
            <Spinner size={25} />
          </div>
        )}
      </div>
      <div className="display_flex width_1 justifyContent_center">
        <Tooltip content={tileData.title.text} type={TYPES.SMALL} delay={50}>
          <TypographyBox variant="h1" color="gray.600" pb={1.5} className={classes.titleWrapper}>
            {tileData.title.text}
          </TypographyBox>
        </Tooltip>
      </div>
      {renderBody}
    </div>
  );
};

const TileFullSizeContainer = ({
  history,
  dashboardData,
  returnToDashboardPage,
  computedTileOptions,
  tileId,
  tileData,
  matchUrl,
}: PropTypes) => {
  const chartId = `chart_${tileId}`;
  const classes = useStyles();
  const [invitationId] = useQueryParam('invitationId', StringParam);

  const {
    input: {value: dateRange},
  } = useField('dateRange');
  const {
    input: {value: timeScale},
  } = useField('timeScale');
  const {
    input: {value: filters},
  } = useField('filters');

  const commonValues = useMemo(
    () => ({
      dateRange: getDateValue(dateRange),
      timeScale,
      filters: parseFiltersPayload(makeFiltersPayload(filters)),
    }),
    [dateRange, timeScale, filters],
  );

  const {data: dashboardUserSettings} = useSelector(getDashboardUserSettings);

  const savedTimeScale = get(dashboardUserSettings, `tiles[${tileId}].timeScale`);
  const {initialTileState, syncTile, initializeTile} = useTileSync({
    dashboardData,
    tileId,
    dateRange: getDateValue(get(dashboardUserSettings, `tiles[${tileId}].dateRange`, dateRange)),
    filters: get(dashboardUserSettings, `tiles[${tileId}].selectorsFilter`, makeFiltersPayload(filters)),
    timeScale: isString(savedTimeScale) ? savedTimeScale : timeScale,
  });

  const onUpdateTile = useCallback(
    (values, formProps) => {
      if (Object.values(formProps.getState().modified).some((field) => field === true)) {
        syncTile(values);
      }
    },
    [syncTile, filters],
  );

  if (!initialTileState) {
    return null;
  }

  return (
    <Form initialValues={initialTileState} onSubmit={onUpdateTile}>
      {({handleSubmit, values}) => (
        <React.Fragment>
          <FormSpy subscription={{values: true}} onChange={handleSubmit} />
          <header className={classes.header}>
            <Box display="flex" flexGrow={1}>
              <Box mr={1.5}>
                <DashboardTitle dashboardData={dashboardData} history={history} isFullSize />
              </Box>
            </Box>
            <Box mr={1} display="flex" alignItems="center" color={palette.gray[400]}>
              <Box mr={0.5}>Time Frame</Box>
              <DateRanges />
              <Box mr={0.5} ml={1.5}>
                Timescale
              </Box>
              <TimeScale />
            </Box>
            <Box mr={1}>
              <FilterDDL
                initializeTile={initializeTile}
                dashboardData={dashboardData}
                tileId={tileId}
                onSave={() => syncTile(values, true)}
                position="right"
                formValues={values}
                commonValues={commonValues}
                isFullSize
              />
            </Box>
            <Tooltip content="Exit full screen" type={TYPES.SMALL} placement="bottom">
              <Box mr={1}>
                <Button
                  automationId="exitTileFullscreen"
                  colorSchema={COLORS.GRAY_300}
                  onClick={() => returnToDashboardPage(false)}
                  icon="icn-general16-fullscreen_exit"
                  extraClassName={classes.squareButton}
                />
              </Box>
            </Tooltip>
            {!invitationId && (
              <SelectAndt
                automationId="dashboardTileEdit"
                type={TYPE_SIMPLE}
                theme={THEME_TRANSPARENT}
                direction={DIRECTION_LEFT}
                offsetLeft={34}
                options={computedTileOptions.filter((o) => o.value !== OPTION_TILES_VALUES.SHOW_LEGEND)}
                onChange={(option) => history.push(`${matchUrl}/${option.value}${history.location.search}`)}
                optionHeight={40}
                menuWidth={150}
                customComponent={{
                  Option: OptionWithIcon,
                  DropdownIndicator: (p) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      bgcolor="gray.300"
                      borderRadius={6}
                      className={classes.squareButton}
                    >
                      <i {...p.innerProps} className="icon icn-nav16-settings" />
                    </Box>
                  ),
                }}
                closeMenuOnSelect={false}
                appendToBody={false}
              />
            )}
          </header>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box mt={1.5} mb={2} flexShrink={0}>
              {values.filters && <FiltersField isEditable={false} />}
            </Box>
            <CompositeExecuteContainer
              chartId={chartId}
              tileData={tileData}
              dashboardData={dashboardData}
              isAnonymous={!!invitationId}
            />
          </Box>
        </React.Fragment>
      )}
    </Form>
  );
};

export default TileFullSizeContainer;
