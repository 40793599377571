// @flow
import React from 'react';
import {useField} from 'react-final-form';
import {useFetchAnomalyMetrics} from 'dashboards/api/useFetchAnomalyMetrics';
import {AnomalyChart} from 'charts/AnomalyChart';
import {makeAnomalyTimeSeriesParams} from './helpers';

type Proptypes = {
  width: number,
  height: number,
  anomaly: Object,
  timeInterval: Array,
  isLoading: boolean,
};

const SingleChartContainer = ({anomaly, ...otherProps}: Proptypes) => {
  const {
    input: {value: dateRange},
  } = useField('dateRange');
  const {anomalyMetricsResponse, isAnomalyMetricsLoading} = useFetchAnomalyMetrics(
    makeAnomalyTimeSeriesParams(anomaly, {timeInterval: dateRange, baseline: true}),
  );

  return (
    <AnomalyChart
      metricsData={anomalyMetricsResponse}
      isLoading={isAnomalyMetricsLoading}
      score={anomaly.score}
      {...otherProps}
    />
  );
};

export default SingleChartContainer;
