// @flow
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import {Box} from '@material-ui/core';
import ChartType from 'metrics/components/metricExplorer/ChartSettings/ChartType';
import Title from 'dashboards/components/fields/Title';
import Measure from 'dashboards/components/fields/Measure';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import {CHART_TYPE, STACKING, Y_AXIS_TYPE} from 'metrics/components/metricExplorer/seriesPropertiesConsts';
import RadioButtonsList from 'common/componentsV2/RadioButtonsList';
import InputAuto from 'alerts.management/components/editor/simulationArea/utils/InputAuto';
import LegendOptions from 'dashboards/components/fields/LegendOptions';
import {getAllExpressionsSelected, getExpressionTrees, getSelectedExpressionId} from 'metrics/store/selectors';
import SelectAndt, {THEME_BLUE_LEAN_MODIFIED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import useByTreeExpManager from 'common/hooks/useByTreeExpManager';
import SwitchButton from 'common/componentsV2/SwitchButton';
import ColorPicker, {COLOR_NAME} from 'common/componentsV2/ColorPicker';

const stackingOptions = [
  {label: 'None', value: null},
  {label: 'Normal', value: STACKING.NORMAL},
  {label: 'Percent', value: STACKING.PERCENT},
];

const yAxisOptions = [
  {label: 'Linear', value: Y_AXIS_TYPE.LINEAR},
  {label: 'Logarithmic', value: Y_AXIS_TYPE.LOGARITHMIC},
];

const yAxisDirectionOptions = [{label: 'Left', value: false}, {label: 'Right', value: true}];

const GraphTileSettings = () => {
  const {
    input: {value: expressionTreeType},
  } = useField('expressionTreeType');

  const {
    input: {checked: enabledStaticLine, onChange: onChangeEnabledStaticLine},
  } = useField('staticLine.enabled', {type: 'checkbox'});

  const {
    input: {value: titleStaticLine, onChange: onChangeTitleStaticLine},
  } = useField('staticLine.title');

  const {
    input: {value: colorStaticLine, onChange: onChangeColorStaticLine},
  } = useField('staticLine.color');

  const {
    input: {value: valueStaticLine, onChange: onChangeValueStaticLine},
  } = useField('staticLine.value');

  const {makeByTreeExpChangeHandler, onByTreeExpIdChange, byTreeExp, byTreeExpId} = useByTreeExpManager();

  const expressionTrees = useSelector(getExpressionTrees);
  const selectedExpressionId = useSelector(getSelectedExpressionId);
  const isAllSelected = useSelector(getAllExpressionsSelected);

  useEffect(() => {
    onByTreeExpIdChange(
      isAllSelected || expressionTreeType === 'simple'
        ? expressionTrees[0].id
        : selectedExpressionId || expressionTrees[0].id,
    );
  }, [selectedExpressionId, isAllSelected, expressionTreeType]);

  const expressionTreesOptions = expressionTrees.map((item, index) => ({
    value: item.id,
    label: `Expression Tree ${String.fromCharCode(65 + index)}`,
  }));

  const [isGraphOptionsOpen, setGraphOptionsOpen] = useState(false);
  const [isLegendOptionsOpen, setLegendOptionsOpen] = useState(false);

  const computedStackingOptions = useMemo(
    () =>
      stackingOptions.map((o) => {
        if (byTreeExp.type === CHART_TYPE.COLUMN && o.value === null) {
          return {...o, disabled: true};
        }
        return o;
      }),
    [byTreeExp],
  );

  return (
    <Fragment>
      <div className="mb_3">
        <Title />
      </div>
      <div className="mb_3">
        <Measure isGroupByEnabled isMultipleTrees />
      </div>
      <div className="mb_1">
        <ExpandableBox
          automationId="graphOptions"
          label="Graph Options"
          expanded={isGraphOptionsOpen}
          onChange={setGraphOptionsOpen}
          rightHeaderElement={
            isGraphOptionsOpen && expressionTreesOptions.length > 1 ? (
              <SelectAndt
                id="selectExpressionTree"
                automationId="selectExpressionTree"
                extraClassName="alerts-dropdown-btn"
                options={expressionTreesOptions}
                optionHeight={40}
                menuWidth={170}
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_BLUE_LEAN_MODIFIED}
                onChange={(option) => onByTreeExpIdChange(option.value)}
                value={expressionTreesOptions.find((item) => item.value === byTreeExpId)}
              />
            ) : null
          }
        >
          <Box mt={1.5} width="300px">
            <ChartType chartType={byTreeExp.type} onChange={makeByTreeExpChangeHandler('type')} />
          </Box>
          <div className="text14reg-gray400 mb_0-5 mt_1-5">Stacking</div>
          <div>
            <RadioButtonsList
              direction="column"
              options={computedStackingOptions}
              value={byTreeExp.stacking}
              onChange={makeByTreeExpChangeHandler('stacking')}
            />
          </div>
          <div className="text14reg-gray400 mb_0-5 mt_1-5">Y Axis</div>
          <div className="display_flex">
            <Box heigth={50} flex={1}>
              <RadioButtonsList
                direction="column"
                options={yAxisOptions}
                value={byTreeExp.yAxis.type}
                onChange={makeByTreeExpChangeHandler('yAxis.type')}
              />
            </Box>
            <Box heigth={50} flex={1}>
              <RadioButtonsList
                direction="column"
                options={yAxisDirectionOptions}
                value={byTreeExp.yAxis.opposite}
                onChange={makeByTreeExpChangeHandler('yAxis.opposite')}
              />
            </Box>
          </div>
          <div className="text14reg-gray400 mb_0-5 mt_1-5">Set a Specific Range</div>
          <Box display="flex" flexDirection="row" css={{'& input': {minWidth: '0', width: '124px'}}}>
            <InputAuto
              onChange={makeByTreeExpChangeHandler('yAxis.min')}
              allowEmpty
              type="number"
              placeHolder="From"
              value={byTreeExp.yAxis.min}
              delay={1000}
            />
            <div className="ml_1">
              <InputAuto
                onChange={makeByTreeExpChangeHandler('yAxis.max')}
                allowEmpty
                type="number"
                placeHolder="To"
                value={byTreeExp.yAxis.max}
                delay={1000}
              />
            </div>
          </Box>
          <div className="mt_3">
            <div className="display_flex">
              <div className="text14reg-gray400 mb_0-5">Static Line</div>
              <div className="ml_1">
                <SwitchButton
                  isChecked={enabledStaticLine}
                  onChange={onChangeEnabledStaticLine}
                  automationId="staticLine"
                />
              </div>
            </div>
            {enabledStaticLine && (
              <React.Fragment>
                <Box width={255}>
                  <InputAuto
                    onChange={onChangeTitleStaticLine}
                    allowEmpty
                    type="text"
                    placeHolder="Name (max. 20 characters)"
                    value={titleStaticLine}
                    delay={1000}
                    additionalProps={{maxLength: 20}}
                  />
                </Box>
                <div className="text14reg-gray400 mb_0-5 mt_1 ml_3-5">Value</div>
                <div className="display_flex alignItems_center">
                  <div className="mr_1-5">
                    <ColorPicker
                      value={colorStaticLine}
                      onChange={onChangeColorStaticLine}
                      options={[COLOR_NAME.GRAY_400, COLOR_NAME.MINT, COLOR_NAME.YELLOW, COLOR_NAME.RED]}
                      buttonSize={16}
                      menuWidth={110}
                    />
                  </div>
                  <Box width={117} height={44}>
                    <InputAuto
                      onChange={onChangeValueStaticLine}
                      allowEmpty
                      type="number"
                      placeHolder="Value"
                      value={valueStaticLine}
                      delay={1000}
                      fullSize
                    />
                  </Box>
                </div>
              </React.Fragment>
            )}
          </div>
        </ExpandableBox>
      </div>

      <ExpandableBox
        automationId="legendOptions"
        label="Legend Options"
        expanded={isLegendOptionsOpen}
        onChange={setLegendOptionsOpen}
      >
        <LegendOptions needsPositioning />
      </ExpandableBox>
    </Fragment>
  );
};

export default GraphTileSettings;
