// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import * as selectors from 'bc/store/selectors';
import {
  createDataSource as createDataSourceAction,
  resetAddSourceError as resetAddSourceErrorAction,
} from 'bc/store/actions';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {newRelicRegionsUrls} from 'bc/services/genericStorageService';
import DataSourceModal from '../../DataSourceModal';
import '../../DataSourceModal.module.scss';

export default connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource: createDataSourceAction,
    resetAddSourceError: resetAddSourceErrorAction,
  },
)(
  class NewRelicSourceModal extends React.PureComponent {
    props: {
      isOpen: boolean,
      isViewOnly: boolean,
      isEditable: boolean,
      source: Object,
      sourceType: Object,
      isLoading: boolean,
      sourceError: Object,
      onClose: Function,
      createDataSource: Function,
      resetAddSourceError: Function,
    };

    // eslint-disable-next-line no-extra-boolean-cast
    state = !!this.props.source
      ? {
          region: this.props.source.region
            ? newRelicRegionsUrls.find((reg) => reg.value === this.props.source.region).value
            : null,
          apiKey: this.props.source.apiKey,
        }
      : {
          region: newRelicRegionsUrls[0].value,
          apiKey: '',
        };

    valueChanged = (val, inputId) => {
      const newState = {};
      newState[inputId] = val;
      this.setState(newState);
    };

    regionChanged = (val) => {
      this.setState({region: val.value});
    };

    createBtnClicked = () => {
      const {sourceType, createDataSource} = this.props;

      const obj = {
        type: getTypeDetails(sourceType.type).type,
        name: `${getTypeDetails(sourceType.type).shortName} Data Source ${new Date().getTime().toString()}`,
        region: this.state.region,
        apiKey: this.state.apiKey,
      };
      createDataSource(obj);
    };

    onCloseInternal = () => {
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    render() {
      const {isOpen, isLoading, source, sourceError, sourceType, isViewOnly, isEditable} = this.props;
      const {region, apiKey} = this.state;

      const selectedIndex = newRelicRegionsUrls.findIndex((val) => region === val.value);

      return (
        <DataSourceModal
          isOpen={isOpen}
          isContinueDisabled={!apiKey}
          isProcessing={isLoading}
          isViewOnly={isViewOnly}
          isRenameEnabled={isEditable}
          source={source}
          sourceType={sourceType}
          logoImageClass={`source-logo-${sourceType.type}`}
          onClose={this.onCloseInternal}
          onContinue={this.createBtnClicked}
        >
          <div styleName="inputs-wrapper">
            <SelectAndt
              id="NewRelicRegion"
              automationId="NewRelicRegion"
              styleName="region-dropdown"
              className="andt-dropdown"
              options={newRelicRegionsUrls}
              getOptionLabel={(val) => val.text}
              getOptionValue={(val) => val.value}
              type={TYPE_NO_SEARCH}
              theme={THEME_LIGHT}
              value={newRelicRegionsUrls[selectedIndex]}
              onChange={this.regionChanged}
              disabled={isViewOnly}
              placeholder="Choose Region"
            />

            <input
              type="password"
              automation-id="NewRelicApiKey"
              onChange={(e) => this.valueChanged(e.target.value, 'apiKey')}
              placeholder="apiKey"
              value={apiKey}
            />

            {!isLoading && sourceError ? (
              <div styleName="error-wrapper error-wrapper-s3">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" /> {sourceError.uMessage}
                </span>
              </div>
            ) : null}

            <div styleName="extra-info">
              <a
                href="https://support.anodot.com/hc/en-us/articles/4403988906002"
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn more
              </a>
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
