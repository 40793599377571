// @flow
import React, {PureComponent} from 'react';
import StaticAlertMetric from './StaticAlertMetric';
import '../alertContent.module.scss';

type PropTypes = {
  alert: Object,
  getWrapperStyle: Function,
  queryParams: Object,
  isSingleLine: boolean,
  specialHeader: boolean,
  searchExpression: String,
};

class StaticAlert extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    // eslint-disable-next-line react/no-string-refs
    const alertContentHeight = this.refs.staticAlertMetric.clientHeight;
    this.props.getWrapperStyle(alertContentHeight);
  }

  render() {
    const {alert, queryParams, isSingleLine, specialHeader, searchExpression} = this.props;

    return (
      // eslint-disable-next-line react/no-string-refs
      <div styleName="alert-content" ref="staticAlertMetric">
        <div styleName="sep" />
        {alert.metrics?.map((m) => (
          <StaticAlertMetric
            metric={m}
            alertId={alert.alertTriggerId}
            alertConfigurationId={alert.alertConfigurationId}
            startDate={m.displayStartTime || m.startTime}
            endDate={m.displayEndTime || Math.floor(new Date() / 1000)}
            timeScale={alert.timeScale}
            key={m.id}
            queryParams={queryParams}
            isSingleLine={isSingleLine}
            specialHeader={specialHeader}
            searchExpression={searchExpression}
          />
        ))}
      </div>
    );
  }
}

export default StaticAlert;
