import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';
import {DIMENSIONS_ARR, MEASURES_ARR} from 'bc/services/gsearchService';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

const fetchGoogleSearchSites = makeAsyncEpic(actions.fetchGoogleSearchStreamSites, api.fetchGoogleSearchStreamSites);

const setGoogleSearchSchemaToStream = (action$) =>
  action$.ofType(actions.setGoogleSearchSchemaToStream.TYPE).switchMap(() => {
    const res = {
      filters: [],
      dimensions: [],
      metrics: [],
      schema: {
        columns: [],
        sourceColumns: [],
      },
      uiState: {
        unAssignedColumns: [],
      },
    };

    DIMENSIONS_ARR.forEach((item) => {
      res.dimensions.push(item.id);
      res.schema.columns.push({
        id: item.id,
        name: item.id,
        type: 'dimension',
        sourceColumn: item.id,
      });
      res.schema.sourceColumns.push({
        id: item.id,
        name: item.id,
      });
    });

    MEASURES_ARR.forEach((item) => {
      res.metrics.push(item.id);
      res.schema.columns.push({
        id: item.id,
        name: item.id,
        type: 'metric',
        sourceColumn: item.id,
        targetType: item.aggr,
      });
      res.schema.sourceColumns.push({
        id: item.id,
        name: item.id,
      });
    });

    return [actions.setSelectedStreamKeyVal(res)];
  });

const setGoogleSearchDataStreamSchema = (action$, {getState}) =>
  action$
    .ofType(actions.setGoogleSearchStreamDiametricsChange.TYPE, actions.removeGoogleSearchStreamDiametrics.TYPE)
    .flatMap(() => {
      const stream = selectors.getSelectedDataStream(getState());

      const res = {
        schema: {
          columns: [],
          sourceColumns: [],
        },
      };
      const metrics = MEASURES_ARR || [];
      const dimensions = DIMENSIONS_ARR || [];
      const items = metrics.concat(dimensions);

      stream.metrics.forEach((item) => {
        const metric = items.find((m) => m.id === item);
        res.schema.columns.push({
          id: metric.id,
          sourceColumn: metric.id,
          name: metric.id,
          type: 'metric',
          targetType: metric.aggr,
        });
        res.schema.sourceColumns.push({
          id: metric.id,
          name: metric.id,
        });
      });

      stream.dimensions.forEach((item) => {
        const dimension = items.find((m) => m.id === item);
        res.schema.columns.push({
          id: dimension.id,
          sourceColumn: dimension.id,
          name: dimension.id,
          type: 'dimension',
        });
        res.schema.sourceColumns.push({
          id: dimension.id,
          name: dimension.id,
        });
      });

      return [actions.setSelectedStreamKeyVal(res)];
    });

const googleSearchEpic = combineEpics(
  fetchGoogleSearchSites,
  setGoogleSearchSchemaToStream,
  setGoogleSearchDataStreamSchema,
);
export default googleSearchEpic;
