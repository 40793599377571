// @flow
import React from 'react';
import moment from 'moment';
import 'moment-timezone';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {components} from 'react-select2';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {palette} from 'app/styles/theme';

const classes = {
  timeZone: {
    margin: '0px 2px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    lineHeight: '20px',
    overflow: 'hidden',
    color: palette.indigo[500],
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    padding: '0px 5px 0px 10px',
    width: '170px',
  },
  timeZoneAbbr: {
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: palette.gray[400],
    paddingLeft: '7px',
  },
};

const BROWSER = 'Browser';
const timeZones = moment.tz.names().map((item) => ({
  label: item,
  value: item,
}));
timeZones.unshift({
  label: BROWSER,
  value: BROWSER,
});

type PropTypes = {
  value: string,
  onChange: Function,
  selectProps: Object,
};

const onSelectTimeZone = (tz, newTz, action) => {
  if (tz === newTz) {
    return;
  }

  action(newTz);
};

const TimeZone = (props: PropTypes) => {
  const timeZone = timeZones.find((val) => props.value === val.label) || null;

  let browserTimeZone = '';
  const tempTimeZone = {...timeZone};
  if (timeZone?.label === 'Browser' || !timeZone) {
    tempTimeZone.label = 'Browser Time';
    browserTimeZone = moment.tz(moment.tz.guess()).zoneAbbr();
  }
  const selected = timeZone?.label ? timeZone : null;

  const browserTimeZoneName = String(String(new Date()).split('(')[1]).split(')')[0];
  const timeZoneNameTooltip = tempTimeZone?.label === 'Browser Time' ? browserTimeZoneName : timeZone?.label;

  return (
    <Tooltip content={timeZoneNameTooltip} placement="bottom" type={TYPES.SMALL}>
      <div>
        <SelectAndt
          type={TYPE_NEW_SEARCH}
          theme={THEME_NOT_HIGHLIGHTED}
          onChange={(newTz) => onSelectTimeZone(props.value, newTz.label, props.onChange)}
          automationId="timeZones"
          value={selected || timeZones[0]}
          options={timeZones}
          optionHeight={40}
          customComponent={{
            ValueContainer: (p) => (
              <components.ValueContainer {...{...p}}>
                <div style={classes.timeZone}>
                  <span>{tempTimeZone?.label}</span>
                  {browserTimeZone ? <span style={classes.timeZoneAbbr}>{browserTimeZone}</span> : null}
                </div>
                {p.children}
              </components.ValueContainer>
            ),
          }}
          {...props.selectProps}
        />
      </div>
    </Tooltip>
  );
};

export default TimeZone;
