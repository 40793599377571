import React, {useEffect, useState} from 'react';
import {useField, useForm} from 'react-final-form';
import {makeStyles} from '@material-ui/core';
import {isEmpty} from 'lodash';
import {fetchPropAndValListApi} from 'metrics/store/actions';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'metrics/store/selectors';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';

const useStyles = makeStyles(() => ({
  selectDimension: {
    width: 200,
    pointerEvents: ({errors}) => (isEmpty(errors) ? 'auto' : 'none'),
    opacity: ({errors}) => (isEmpty(errors) ? 1 : 0.5),
  },
}));

const MAX_DIMENSIONS_AND_TAGS = 9999;

const SelectChannelDimOrTag = () => {
  const dispatch = useDispatch();

  const propsAndVals = useSelector(selectors.getFetchPropAndValListApi)?.data;
  const isPropAndValLoading = useSelector(selectors.getIsFetchPropAndValApiListLoading);

  const {getState} = useForm();
  const {errors} = getState();

  const classes = useStyles({errors});

  const {
    input: {value: selectedDimension, onChange: onDimensionSelected},
  } = useField('accountDimension');
  const {
    input: {onChange: onUngroupChannel},
  } = useField('isChannelUngrouped');

  const [dimensionsAndTags, setDimensionsAndTags] = useState([]);
  const [selectedDimensionValueLabel, setSelectedDimensionValueLabel] = useState(
    selectedDimension
      ? {
          value: selectedDimension,
          label: selectedDimension,
        }
      : undefined,
  );

  const dimensionsAndTagsPayload = {
    expression: '',
    listDescription: [
      {valueType: 'dimensions', count: MAX_DIMENSIONS_AND_TAGS},
      {valueType: 'tags', count: MAX_DIMENSIONS_AND_TAGS},
    ],
    filter: [],
    search: '',
  };

  useEffect(() => {
    if (!isPropAndValLoading) {
      dispatch(fetchPropAndValListApi(dimensionsAndTagsPayload, JSON.stringify(dimensionsAndTagsPayload)));
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(propsAndVals)) {
      const options = propsAndVals.flatMap((item) => item.values).map((option) => ({label: option, value: option}));
      setDimensionsAndTags(options);
    }
  }, [propsAndVals]);

  useEffect(() => {
    if (selectedDimensionValueLabel) {
      onUngroupChannel(true);
    }
  }, [selectedDimension]);

  const onChange = (dimensionValueLabel) => {
    let tmpSelectedDimension = dimensionValueLabel;
    if (selectedDimensionValueLabel?.value === dimensionValueLabel?.value) {
      tmpSelectedDimension = undefined;
    }
    setSelectedDimensionValueLabel(tmpSelectedDimension);
    onDimensionSelected(tmpSelectedDimension?.value);
  };

  return (
    <div>
      <div className={classes.selectDimension}>
        <div>Map dimension to account id:</div>
        <FormDdlSelect
          id="salesforceChannelConfiguration"
          isUseSearch
          selected={selectedDimensionValueLabel}
          button={
            <BigDropdownButton
              id="accountDimension"
              isPristine={!selectedDimensionValueLabel}
              placeholder={isPropAndValLoading ? 'Loading...' : 'Select Dimension or Tag'}
              label={selectedDimensionValueLabel?.label}
              blueLean
            />
          }
          options={dimensionsAndTags}
          optionComponent={<OptionComponentSimple />}
          onChange={onChange}
          width={200}
          maxWidth={200}
          buttonWidth={200}
          height={220}
          placement="auto"
          automationId="salesforce-channel-configuration"
          disabled={isPropAndValLoading}
        />
      </div>
    </div>
  );
};

export default SelectChannelDimOrTag;
