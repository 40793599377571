// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import * as selectors from 'bc/store/selectors';
import {fetchDashAndAlertTitles, createSelectedAlertsAndDashboard} from 'bc/store/actions';
import Button, {COLORS} from 'common/componentsV2/Button';
import {get} from 'lodash';

import './AlertsFromStreamModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  dataStream: Object,
  jsonTitles: Array,
  onCreateClicked: Function,
  selectedAccount: Object,

  // connect
  fetchDashAndAlertTitles: Function,
  createSelectedAlertsAndDashboard: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    jsonTitles: selectors.getDashAndAlertsTitlesItems(state),
    selectedAccount: selectors.getGoogleAdsSelectedAccount(state),
  }),
  {
    fetchDashAndAlertTitles,
    createSelectedAlertsAndDashboard,
  },
)(
  class AlertsAndDashboardsStreamModal extends React.Component {
    props: PropTypes;

    state = {
      alertIds: [],
      dashboard: true,
      updated: false,
    };

    componentDidMount() {
      this.props.fetchDashAndAlertTitles({streamType: this.props.dataStream.type});
    }

    componentDidUpdate() {
      const {jsonTitles, dataStream} = this.props;
      if (this.props.jsonTitles && this.state.updated === false) {
        const titlesListObj = jsonTitles.find((item) => item.name === dataStream.basedOnTemplateId);
        const alertsList = get(titlesListObj, 'alerts', null);
        let alertIdsArr = [];
        if (alertsList) {
          alertIdsArr = alertsList.map((item) => item.id);
        }

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          alertIds: alertIdsArr,
          dashboard: true,
          updated: true,
        });
      }
    }

    handleClose = () => {
      this.props.onClose();
    };

    onCreateStream = () => {
      if (this.props.onCreateClicked) {
        this.props.createSelectedAlertsAndDashboard({
          id: this.props.dataStream.id,
          alertIds: this.state.alertIds,
          dashboard: this.state.dashboard,
        });
        this.props.onCreateClicked();
      }
    };

    onToggleAlertCheckbox = (e) => {
      // eslint-disable-next-line react/no-access-state-in-setstate
      let alertIdsArr = this.state.alertIds;
      if (e.target.checked) {
        if (!alertIdsArr.includes(e.target.name)) {
          alertIdsArr.push(e.target.name);
        }
      } else if (alertIdsArr.includes(e.target.name)) {
        alertIdsArr = alertIdsArr.filter((id) => id !== e.target.name);
      }
      this.setState({alertIds: alertIdsArr});
    };

    render() {
      const {dataStream, jsonTitles, isOpen, onClose, selectedAccount} = this.props;

      const titlesListObj = jsonTitles.find((item) => item.name === dataStream.basedOnTemplateId);
      const selectedAccountName = selectedAccount ? `(${selectedAccount.name})` : '';

      let alertsList = null;
      let dashName = null;
      if (titlesListObj) {
        alertsList = get(titlesListObj, 'alerts', null);
        dashName = get(titlesListObj, 'dashboard', null);
      }

      return (
        <Fragment>
          <Modal id="measure-dimensions-modal" show={isOpen} dialogClassName="bc" onHide={onClose} bsSize="large">
            <Modal.Header bsClass="bc-modal-header">
              <Modal.Title>
                <span>Alerts & Dashboards</span>
                <div styleName="description">
                  <span styleName="header">
                    Based on your use case we will create the following alerts and dashboards.
                  </span>
                  <br />
                  <span styleName="header">You can always edit those later.</span>
                  <br />
                </div>
              </Modal.Title>
              <button
                className="btn btn-flat btn-icon-36 btn-secondary"
                type="button"
                onClick={() => this.handleClose(false)}
              >
                <i className="icon icn-general16-closeb" styleName="new-close" />
              </button>
            </Modal.Header>
            <Modal.Body>
              <div styleName="measure-dimension-section">
                <div styleName="row">
                  <div styleName="col">
                    <span styleName="header">Alerts</span>
                  </div>
                </div>

                {!alertsList ? (
                  <div styleName="col normal">
                    <div>None</div>
                  </div>
                ) : (
                  alertsList.map((p, i) => (
                    <div styleName="row" key={i.toString()}>
                      <div styleName="col normal">
                        <input
                          type="checkbox"
                          styleName="checkbox-spacer"
                          name={p.id}
                          id={p.id}
                          checked={this.state.alertIds.includes(p.id)}
                          onChange={(e) => this.onToggleAlertCheckbox(e)}
                        />
                        <label htmlFor={p.id}>
                          {/* eslint-disable-next-line react/no-danger */}
                          <div dangerouslySetInnerHTML={{__html: p.name}} />
                        </label>
                        &nbsp;
                        {selectedAccountName}
                      </div>
                    </div>
                  ))
                )}

                <div styleName="row">
                  <div styleName="col" />
                </div>
                <div styleName="row">
                  <div styleName="col">
                    <span styleName="header">Dashboard</span>
                  </div>
                </div>
                <div styleName="row">
                  {!dashName ? (
                    <div styleName="col normal">
                      <div>None</div>
                    </div>
                  ) : (
                    <div styleName="col normal">
                      <input
                        type="checkbox"
                        name="dashboard"
                        id="dashboard"
                        styleName="checkbox-spacer"
                        checked={this.state.dashboard}
                        onChange={(e) => this.setState({dashboard: e.target.checked})}
                      />
                      <label htmlFor="dashboard">
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{__html: dashName}} />
                      </label>
                      &nbsp;-&nbsp;
                      {dataStream.name}
                    </div>
                  )}
                </div>
              </div>
              <div styleName="actions-footer-section">
                <Button colorSchema={COLORS.BLUE_500} text="Create" onClick={this.onCreateStream} />
              </div>
            </Modal.Body>
          </Modal>
        </Fragment>
      );
    }
  },
);
