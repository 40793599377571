import React from 'react';
import Highcharts from 'highcharts';

import './SolidGauge.module.scss';
import {palette} from 'app/styles/theme';

require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

type PropTypes = {
  id: string,
  min: Number,
  max: Number,
  current: Number,
  cutoff: Number,
  chartHeight: Number,
};

export default class GraphContainer extends React.Component {
  props: PropTypes;

  constructor(props) {
    super(props);
    this.hchart = null;
    const {min, current, cutoff, chartHeight} = props;

    Highcharts.setOptions({
      lang: {
        decimalPoint: '.',
        thousandsSep: ',',
      },
    });

    this.defaultChartSettings = {
      chart: {
        type: 'solidgauge',
        height: chartHeight || 200,
        spacingTop: 0,
      },
      title: {
        text: '',
        y: undefined,
      },
      pane: {
        center: ['50%', '50%'],
        size: '100%',
        startAngle: -90,
        endAngle: 90,
        background: {
          innerRadius: '65%',
          outerRadius: '100%',
          shape: 'arc',
          borderWidth: 0,
          backgroundColor: palette.gray[200],
        },
      },
      tooltip: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        max: 0,
        linearGradient: [0, 0, 0, 300],
        stops: [
          [0.1, palette.green[500]], // green
          [0.5, palette.yellow[100]], // yellow
          [0.9, palette.red[450]], // red
        ],
        lineWidth: 0,
        tickWidth: 3,
        tickLength: 40,
        tickColor: palette.gray[200],
        minorTickInterval: null,
        tickPositioner() {
          return [min, cutoff, this.max];
        },
        title: {
          text: '',
          y: -70,
        },
        labels: {
          text: '',
          y: 16,
          align: 'auto',
        },
      },
      legend: {
        enabled: false,
      },
      exporting: {enabled: false},
      plotOptions: {
        solidgauge: {
          innerRadius: '65%',
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          data: current || [],
          dataLabels: {
            format:
              '<div style="position: relative;top: -2em;text-align:center;' +
              'font-family: Roboto, sans-serif;font-weight: 300;color:palette.gray[500];font-size: 25px">{y:,.0f}</div>',
          },
        },
      ],
      lang: {
        noData: '',
        thousandsSep: ',',
      },
    };
  }

  componentDidMount() {
    this.hchart = Highcharts.chart(this.props.id, this.defaultChartSettings);
    this.hchart.yAxis[0].ticks[this.props.cutoff].mark.attr({
      stroke: palette.red[100],
    });
    this.hchart.series[0].setData([this.props.current]);
    this.hchart.yAxis[0].setExtremes(this.props.min, this.props.max);
  }

  componentWillUnmount() {
    if (this.hchart) {
      this.hchart.destroy();
    }
  }

  setRef = (e) => {
    this.hchart = e;
  }; // will be set with null when unmounted

  render() {
    const {id} = this.props;

    return (
      <div id={id} ref={this.setRef} style={{width: '100%', marginTop: '20px'}}>
        Chart is here
      </div>
    );
  }
}
