import React from 'react';
import usePreviewClasses from 'dataCollectors/styles/classes.usePreviewClasses';

import StreamMetaData from 'dataCollectors/components/streamInfo/StreamInfoPreview';
import StreamInfoPreview from 'dataCollectors/components/dataMapper/DataMapperPreview';

export default function Overview() {
  const previewClasses = usePreviewClasses();

  return (
    <div className={previewClasses.container}>
      <StreamMetaData />
      <StreamInfoPreview />
    </div>
  );
}
