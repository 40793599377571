import 'moment-timezone';
import {getHumanDate} from 'common/utils/dateService';
import './columnChartSettingsService.module.scss';
import {palette} from 'app/styles/theme';

const getStepsCategories = (categories) => {
  if (!categories.length) {
    return null;
  }
  if (categories.length < 14) {
    return null;
  }
  if (categories.length < 28) {
    return Math.round(categories.length / 4);
  }
  if (categories.length >= 28) {
    return Math.round(categories.length / 5);
  }
  return null;
};

export const BASIC = {
  1: {
    basic: palette.blue[500],
    hover: palette.indigo[400],
  },
};

export const ALERTS_TRIGGERED = {
  1: {
    basic: palette.blue[500],
    hover: palette.indigo[400],
  },
};

const TOOLTIP = {
  shadow: false,
  useHTML: true,
  outside: true,
  backgroundColor: 'transparent',
  borderWidth: 0,
  hideDelay: 50,
};

export const getHchartConfig = (data = {}, theme = BASIC, steps = false, {formatter}) => ({
  chart: {
    type: 'column',
  },
  title: {text: null},

  margin: [0, 0, 0, 0],
  spacingTop: 0,
  spacingBottom: 0,
  spacingLeft: 0,
  spacingRight: 0,
  xAxis: {
    className: 'xAxis-label',
    categories: getHumanDate(Object.keys(data)),
    crosshair: false,
    labels: {
      useHTML: true,
      step: steps ? getStepsCategories(Object.keys(data), steps) : null,
    },
  },
  yAxis: {
    className: 'yAxis-label',
    min: 0,
    title: {
      text: null,
    },
    labels: {
      useHTML: true,
    },
  },
  plotOptions: {
    column: {
      borderWidth: 0,
    },
    series: {
      allowPointSelect: false,
      states: {
        hover: {
          color: theme[1].hover,
        },
      },
      color: theme[1].basic,
      pointWidth: 8,
      animation: {
        duration: 500,
      },
      borderRadius: 2,
    },
  },
  tooltip: formatter ? {...TOOLTIP, formatter} : {enabled: false},
  legend: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  series: [
    {
      data: Object.values(data),
    },
  ],
});
