// @flow
import React from 'react';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import TimeScaleConditionComponent from './TimeScaleConditionComponent';
import './TimeScaleCondition.module.scss';

type PropTypes = {
  rollup: String,
  type: String,
  useAnomalyValues: Boolean,
  omitRollups: Array,
  onChange: Function,
};

class TimeScaleCondition extends React.PureComponent {
  props: PropTypes;

  render() {
    const {rollup, useAnomalyValues, omitRollups, onChange, type} = this.props;
    const yesNoBtns = [
      {value: false, label: 'Before', disable: false},
      {value: true, label: 'During', disable: type === 'noData'},
    ];
    return (
      <div>
        <div styleName="time-scale">
          <div className="mr_1 display_inline-block">In the</div>
          <TimeScaleConditionComponent
            rollup={rollup}
            onChange={(v) => onChange({rollup: v})}
            omitRollups={omitRollups}
          />
          <SelectAndt
            id="timeScaleFilter"
            automationId="timeScaleFilterIndication"
            extraClassName="alerts-dropdown-btn"
            options={yesNoBtns}
            optionHeight={40}
            menuWidth={100}
            type={TYPE_NEW_NO_SEARCH}
            theme={THEME_NOT_HIGHLIGHTED}
            onChange={(item) => onChange({useAnomalyValues: item.value})}
            value={yesNoBtns.find((item) => item.value === useAnomalyValues)}
            placeholder="All"
          />
          the anomaly
        </div>
      </div>
    );
  }
}

export default TimeScaleCondition;
