import {NUM_LAST_DAYS_OPTIONS} from 'insightsPanelNewAlertConsole/services/constants';

export const alertsInsightsRoot = 'api/v1/alerts-insights/';
export const insightsPanelRoot = 'api/v1/insightsPanel/';

export const getConstRangeByNumberOfLastDays = (numberOfLastDays) => {
  if (Number(numberOfLastDays) > 0) {
    return NUM_LAST_DAYS_OPTIONS.find((option) => option.value === Number(numberOfLastDays));
  }
  return undefined;
};
