// @flow
import React from 'react';
import {connect} from 'react-redux';
import {bcTypes} from 'bc/services/bcTypes';
import {REGIONS} from 'bc/services/genericStorageService';
import * as selectors from 'bc/store/selectors';
import {createDataSource, resetAddSourceError} from 'bc/store/actions';
import '../../DataSourceModal.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import DataSourceModal from '../../DataSourceModal';

type PropTypes = {
  isOpen: boolean,
  isViewOnly: boolean,
  isEditable: boolean,
  sourceType: Object,
  source: Object,
  onClose: Function,

  // connect
  isLoading: boolean,
  sourceError: Object,

  createDataSource: Function,
  resetAddSourceError: Function,
};

export default connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource,
    resetAddSourceError,
  },
)(
  class CurSourceModal extends React.PureComponent {
    props: PropTypes;

    // eslint-disable-next-line no-extra-boolean-cast
    state = !!this.props.source
      ? {
          bucket: this.props.source.bucket,
          path: this.props.source.path,
          region: REGIONS.find((reg) => reg.value === this.props.source.region),
          externalId: this.props.sourceType.externalId,
          roleARN: this.props.source.roleARN,
        }
      : {
          bucket: '',
          path: '',
          region: REGIONS[0],
          externalId: this.props.sourceType.externalId,
          roleARN: '',
        };

    valueChanged = (val, inputId) => {
      const newState = {};
      newState[inputId] = val;
      this.setState(newState);
    };

    regionChanged = (val) => {
      this.setState({region: val});
    };

    createBtnClicked = () => {
      this.props.createDataSource({
        type: bcTypes.aws_cur.type,
        name: `AWS CUR_${this.state.bucket} Data Source ${new Date().getTime().toString()}`,
        bucket: this.state.bucket,
        path: this.state.path ? this.state.path : '',
        region: this.state.region.value,
        externalId: this.props.sourceType.externalId,
        roleARN: this.state.roleARN,
      });
    };

    onClose = () => {
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    render() {
      const {isOpen, source, sourceType, isLoading, sourceError, isViewOnly, isEditable} = this.props;
      const {bucket, path, region, roleARN} = this.state;
      const selectedIndex = REGIONS.findIndex((val) => region.value === val.value);
      return (
        <DataSourceModal
          isOpen={isOpen}
          isProcessing={isLoading}
          isViewOnly={isViewOnly}
          isRenameEnabled={isEditable}
          source={source}
          sourceType={sourceType}
          logoImageClass="source-logo-s3"
          onClose={this.onClose}
          isContinueDisabled={!bucket || !roleARN}
          onContinue={this.createBtnClicked}
        >
          <div styleName="inputs-wrapper">
            <SelectAndt
              id="s3RegionDdl"
              automationId="s3Regions"
              styleName="region-dropdown"
              className="andt-dropdown"
              options={REGIONS}
              getOptionLabel={(val) => val.text}
              getOptionValue={(val) => val.value}
              type={TYPE_NO_SEARCH}
              theme={THEME_LIGHT}
              value={REGIONS[selectedIndex]}
              onChange={this.regionChanged}
              disabled={isViewOnly}
              placeholder="Choose Bucket Region"
            />
            <input
              type="text"
              className={!isLoading && sourceError ? 'invalid' : ''}
              onChange={(e) => this.valueChanged(e.target.value, 'bucket')}
              placeholder="Bucket name"
              required
              readOnly={isViewOnly}
              disabled={isViewOnly}
              value={bucket}
            />

            <input
              type="text"
              onChange={(e) => this.valueChanged(e.target.value, 'path')}
              placeholder="Folder Name (optional)"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              value={path}
            />

            <input type="text" placeholder="Anodot ID" readOnly disabled={isViewOnly} value={sourceType.externalId} />

            <CopyToClipboardButton
              copyStr={sourceType.externalId}
              tooltipMessage="Copy the Anodot ID to clipboard"
              styleName="copy-clipboard-btn-pos"
              shouldUseIcon
            />

            <input
              type="text"
              onChange={(e) => this.valueChanged(e.target.value, 'roleARN')}
              placeholder="Role ARN"
              required
              styleName="role-margin"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              value={roleARN}
            />

            {!isLoading && sourceError ? (
              <div styleName="error-wrapper error-wrapper-s3">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" /> {sourceError.uMessage}
                </span>
              </div>
            ) : null}

            <div styleName="extra-info">
              <span>
                *To add a new AWS CUR data source, you need to allow Anodot to access the files. Follow these guidelines
              </span>
              <a
                href="https://support.anodot.com/hc/en-us/articles/360014492419-AWS-CUR-Data-Source-Configuration"
                rel="noopener noreferrer"
                target="_blank"
              >
                to give Anodot the required access.
              </a>
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
