import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import Input from 'common/componentsV2/Input';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import {AMPM_OPTIONS} from './customDateRangeService';

type PropsType = {
  value: string,
  errors: string,
  onChange: Function,
  // since time is not a field in the form, we need this flag to set the for as invalid
  setValidity: Function,
  automationId: string,
  tabIndex: string,
};

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 14,
    display: 'flex',
  },
}));

const isValidHour = (hour) => {
  const intHour = parseInt(hour, 10);
  if (!isNaN(intHour) && intHour >= 0 && intHour < 24) return true;
  return false;
};
const isValidMinute = (min) => {
  const intMin = parseInt(min, 10);
  if (!isNaN(intMin) && intMin >= 0 && intMin <= 59) return true;
  return false;
};

const TimePicker = ({value, errors, onChange, setValidity, automationId, tabIndex}: PropsType) => {
  const classes = useStyles();
  const [appm, setAmpm] = useState('am');
  const [time, setTime] = useState('00:00');
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (value) {
      setIsInvalid(false);
      const dateValue = new Date(value);
      const hours = dateValue.getHours() % 12;
      const min = dateValue.getMinutes();
      setAmpm(dateValue.getHours() > 11 ? 'pm' : 'am');
      setTime(`${hours.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`);
    }
  }, [value]);

  const handleTimeChange = useCallback(
    (newTime) => {
      // if valid call external onChange
      // eslint-disable-next-line prefer-const
      let [hh, mm] = newTime.split(':');
      // set default mm if missing
      if (isValidHour(hh) && !mm) {
        mm = '00';
      }
      if (isValidHour(hh) && isValidMinute(mm)) {
        setIsInvalid(false);
        if (appm === 'pm') hh = (parseInt(hh, 10) % 12) + 12;
        const modifiedDate = new Date(value).setHours(hh, mm);
        onChange(modifiedDate);
      } else {
        // not valid time
        setIsInvalid(true);
        setValidity(true);
      }
    },
    [value, appm, onChange, setIsInvalid, setValidity],
  );

  const handleAmPmChange = useCallback(
    (newValue) => {
      setAmpm(newValue);
      // Adjust date
      const newHour = (new Date(value).getHours() + 12) % 24;
      const modifiedDate = new Date(value).setHours(newHour);
      onChange(modifiedDate);
    },
    [value, onChange, setAmpm],
  );

  return (
    <div className={classes.wrapper}>
      <Input
        type="string"
        automationId={automationId || 'timePicker'}
        onChange={(e) => setTime(e.target.value)}
        onBlur={(e) => handleTimeChange(e.target.value)}
        style={{height: 38, width: 73, marginRight: 7}}
        value={time}
        isInvalid={isInvalid || errors}
        fullSize
        tabIndex={tabIndex}
      />
      <FormDdlSelect
        automationId="timePickerAmPm"
        options={AMPM_OPTIONS}
        selected={AMPM_OPTIONS.find((item) => item.value === appm)}
        onChange={(item) => handleAmPmChange(item.value)}
        optionComponent={<OptionComponentSimple />}
        width={59}
        button={
          <div style={{width: 65}}>
            <BigDropdownButton
              isPristine
              shortBlueStyle
              isFullWidth
              placeholder={AMPM_OPTIONS.find((item) => item.value === appm).label}
            />
          </div>
        }
      />
    </div>
  );
};

export default TimePicker;
