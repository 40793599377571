import React, {useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {
  ICONS,
  UserSettings,
  UserSettingsContext,
  ListItem,
  ListLabel,
  ListSeparator,
  ListContent,
} from '@pileus-cloud/anodot-frontend-common';

import {getRoutingLocation} from 'common/store/selectors';
import {getUserProfile, getTimeZoneName} from 'profile/store/selectors';
import {signOut} from 'profile/store/actions';

function UsrSettings() {
  const dispatch = useDispatch();
  const location = useSelector(getRoutingLocation);
  const userProfile = useSelector(getUserProfile);
  const timeZoneName = useSelector(getTimeZoneName);
  const me = {
    ...userProfile,
    name: `${userProfile.firstName} ${userProfile.lastName.split('')[0]}.`,
  };
  const [isOpen, setIsOpen] = useState(false);
  const timeZoneToDisplay =
    timeZoneName === 'Browser' ? moment.tz(moment.tz.guess()).zoneAbbr() : moment.tz(timeZoneName).zoneAbbr();
  const isSettingActive = location.pathname === '/user/settings';

  // eslint-disable-next-line react/prop-types
  function UserSettingListItem({value, iconName, isActive, onSelect}) {
    const {toggleOpen} = useContext(UserSettingsContext);
    const handleOnSelect = () => {
      onSelect();
      toggleOpen();
    };
    return <ListItem value={value} iconName={iconName} onSelect={handleOnSelect} isActive={isActive} />;
  }

  const onOpenChange = (open) => {
    setIsOpen(open);
  };

  const handleSignOut = () => {
    dispatch(signOut());
    onOpenChange(false);
  };

  const goto = (url) => () => {
    const {origin} = window.location;
    window.open(`${origin}/#!/r/${url}`, '_top');
    onOpenChange(false);
  };

  return (
    <UserSettings onOpenChange={onOpenChange} isOpen={isOpen} tooltip={me.email}>
      <ListLabel value={me.name} />
      <ListLabel value={me.email} />
      <ListLabel value={`Time Zone: ${timeZoneToDisplay}`} />
      <ListSeparator />
      <ListContent>
        <UserSettingListItem
          value="Settings"
          iconName={ICONS.gear.name}
          onSelect={goto('user/settings')}
          isActive={isSettingActive}
        />
        <UserSettingListItem value="Log out" iconName={ICONS.arrowRightFromBracket.name} onSelect={handleSignOut} />
      </ListContent>
    </UserSettings>
  );
}

export default UsrSettings;
