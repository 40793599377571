import React, {useRef, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {palette} from 'app/styles/theme';
import {makeStyles} from '@material-ui/core/styles';
import {
  getDynamicRoutingFilesIsLoading,
  getSortedDynamicRoutingAndLookup,
  getDynamicRoutingAndLookupData,
  getSelectedFilterSearchQuery,
} from 'assets/store/selectors';
import {getFetchLookupTablesIsLoading, getUpdateLookupTableIsLoading} from 'bc/store/selectors';
import {getDynamicRoutingFiles, setScrollbarWidth} from 'assets/store/actions';
import {fetchLookupTables} from 'bc/store/actions';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import TableHeader from 'assets/components/table/TableHeader';
import TableItem from 'assets/components/table/TableItem';
import EmptyState from 'assets/components/page/EmptyState';

const useStyles = makeStyles(() => ({
  main: {
    width: '100%',
  },
  loaderWrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableWrapper: {
    height: 'calc(100% - 55px)',
    overflowY: 'auto',
  },
}));

const Table = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fetchLookupTablesIsLoading = useSelector(getFetchLookupTablesIsLoading);
  const updateLookupTableIsLoading = useSelector(getUpdateLookupTableIsLoading);
  const lookupFilesIsLoading = fetchLookupTablesIsLoading || updateLookupTableIsLoading;
  const dynamicRoutingFilesIsLoading = useSelector(getDynamicRoutingFilesIsLoading);
  const dynamicRoutingAndLookupData = useSelector(getDynamicRoutingAndLookupData);
  const sortedDynamicRoutingAndLookupData = useSelector(getSortedDynamicRoutingAndLookup);
  const searchedWords = useSelector(getSelectedFilterSearchQuery);
  const tableWrapper = useRef(null);

  const onSetScrollbarWidth = () => {
    dispatch(setScrollbarWidth(tableWrapper.current));
  };

  useEffect(() => {
    dispatch(getDynamicRoutingFiles());
    dispatch(fetchLookupTables('lookupSource'));
  }, []);

  useEffect(() => {
    onSetScrollbarWidth();
  }, [sortedDynamicRoutingAndLookupData]);

  useEffect(() => {
    window.addEventListener('resize', onSetScrollbarWidth);
    return () => window.removeEventListener('resize', onSetScrollbarWidth);
  });

  return (
    <div className={classes.main}>
      <TableHeader />
      {(lookupFilesIsLoading || dynamicRoutingFilesIsLoading) && (
        <div className={classes.loaderWrapper}>
          <Spinner color={palette.gray['600']} size={SIZES.XX_BIG_100} />
        </div>
      )}

      {!lookupFilesIsLoading && !dynamicRoutingFilesIsLoading && !!sortedDynamicRoutingAndLookupData.length ? (
        <div className={classes.tableWrapper} ref={tableWrapper}>
          {sortedDynamicRoutingAndLookupData.map((i) => (
            <TableItem {...i} searchedWords={searchedWords} key={i.id} />
          ))}
        </div>
      ) : null}

      {!lookupFilesIsLoading &&
      !dynamicRoutingFilesIsLoading &&
      dynamicRoutingAndLookupData &&
      !dynamicRoutingAndLookupData.length ? (
        <EmptyState state="noData" />
      ) : null}

      {!lookupFilesIsLoading &&
      !dynamicRoutingFilesIsLoading &&
      dynamicRoutingAndLookupData?.length &&
      !sortedDynamicRoutingAndLookupData.length ? (
        <EmptyState state="filteredOut" />
      ) : null}
    </div>
  );
};

export default Table;
