import React, {useCallback, memo} from 'react';
import {makeStyles} from '@material-ui/core';

import {useEventStreamContext} from 'dataCollectors/hooks/useEventStreamContext';
import useNavigationAndTriggerForm from 'dataCollectors/hooks/useNavigationAndTriggerForm';

const useStyles = makeStyles(({typography, palette}) => ({
  container: {
    display: 'flex',
    alignItems: 'baseline',
  },
  name: {
    ...typography.hintText,
    color: palette.gray[500],
    marginLeft: 12,
    textTransform: 'capitalize',
  },
  wrapper: {
    ...typography.hintText,
    color: palette.gray[500],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: 'white',
    border: `1px solid ${palette.gray[500]}`,
    cursor: 'pointer',
  },
  active: {
    border: 'none',
    backgroundColor: palette.blue[500],
    color: 'white',
  },
  checked: {
    border: 'none',
    backgroundColor: palette.blue[200],
    color: palette.blue[500],
  },
  line: {
    position: 'relative',
    marginTop: 5,
    marginBottom: 5,
    left: 15,
    width: 2,
    height: 25,
    backgroundColor: palette.blue[200],
  },
}));

const StepsIndicator = () => {
  const classes = useStyles();
  const {editStreamPageReducerState, currentStepIndex} = useEventStreamContext();
  const {jumpToStep} = useNavigationAndTriggerForm(currentStepIndex);

  const setClassName = useCallback(
    (step, index) => {
      if (step.isCurrent) {
        return `${classes.wrapper} ${classes.active}`;
      }
      if (!step.isCurrent && currentStepIndex > index) {
        return `${classes.wrapper} ${classes.checked}`;
      }
      if (!step.isCurrent && currentStepIndex <= index) {
        return `${classes.wrapper}`;
      }
      return null;
    },
    [currentStepIndex],
  );

  const setContent = useCallback(
    (step, index) => {
      if (!step.isCurrent && currentStepIndex > index) {
        return <i className="icn-general16-checkmark" />;
      }
      return index + 1;
    },
    [currentStepIndex],
  );

  const setCurrentStage = (nextStepId) => {
    jumpToStep(nextStepId);
  };

  return editStreamPageReducerState.map((step, index) => (
    <>
      <div key={step?.label} className={classes.container}>
        <div>
          <div className={setClassName(step, index)} onClick={() => setCurrentStage(index)}>
            {setContent(step, index)}
          </div>
          {index !== editStreamPageReducerState.length - 1 && <div className={classes.line} />}
        </div>
        <span className={classes.name}>{step.label}</span>
      </div>
    </>
  ));
};

export default memo(StepsIndicator);
