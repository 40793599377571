// @flow
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {getBadgeColor, MAP_ITEMS_COLOR_PALETTE, useTooltipStyles} from 'topologyGeneral/services/mapService';

const NodesSection = ({nodes}: {nodes: Array}) => {
  if (!nodes || !nodes.length) {
    return null;
  }

  const classes = useTooltipStyles();

  const getNodeItem = (node) => {
    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <Box
            className={classes.statusIndicator}
            bgcolor={node.isError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
            mr={0.5}
          >
            &nbsp;
          </Box>
          <Box component="span" className={classes.title14Normal}>
            {node.name}
          </Box>
        </Box>
        <Box component="span" className={classes.title14Normal} pl={1.5}>
          {node.type}
        </Box>
      </Box>
    );
  };

  const rows = [];
  const totalNodes = nodes.length;
  rows.push(
    <Box display="flex" mt={1} key="s-t-row1">
      {nodes.slice(0, 3).map((node, index) => {
        return (
          <Box
            className={classes.nodeItemWrapper}
            pr={index === 2 ? 0 : 0.75}
            flexGrow={index === 2 ? 0 : 1}
            key={`t-n-${node.id}`}
          >
            {getNodeItem(node)}
          </Box>
        );
      })}
    </Box>,
  );
  if (totalNodes > 3) {
    if (totalNodes < 7) {
      rows.push(
        <Box display="flex" mt={1} key="s-t-row1">
          {nodes.slice(3, 6).map((node, index) => {
            return (
              <Box
                className={classes.nodeItemWrapper}
                pr={index === 5 ? 0 : 0.75}
                flexGrow={index === 5 ? 0 : 1}
                key={`t-n-${node.id}`}
              >
                {getNodeItem(node)}
              </Box>
            );
          })}
        </Box>,
      );
    } else if (totalNodes > 6) {
      rows.push(
        <Box display="flex" mt={1} key="s-t-row2">
          {nodes.slice(3, 5).map((node) => {
            return (
              <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1} key={`t-n-${node.id}`}>
                {getNodeItem(node)}
              </Box>
            );
          })}
          <Box className={classes.nodeItemWrapper}>
            <Box component="span" className={classes.title12Bold} pl={1.5}>{`+ ${totalNodes - 5} More`}</Box>
          </Box>
        </Box>,
      );
    }
  }

  return <Fragment>{rows}</Fragment>;
};

const SiteTooltip = ({site}: PropTypes) => {
  const classes = useTooltipStyles();

  return (
    <Box className="gtp-tp-wrapper" pt={1.5} pb={1.5} pl={2} pr={2} borderRadius="6px">
      <Box display="flex" mb={0.25}>
        <Box component="span" className={classes.title12BoldLight} mr={0.5}>
          {site.parentRegionName}
        </Box>
      </Box>
      <Box display="flex">
        <Box component="span" className={classes.title14Bold} mr={0.5}>
          {site.title}
        </Box>
        {site.alertsTotal ? (
          <Fragment>
            <Box component="span" className={classes.alertsBadge} pr={0.75} pl={0.75} bgcolor={getBadgeColor(site)}>
              {site.alertsTotal.toLocaleString()}
            </Box>
            <Box component="span" className={classes.title12BoldLight} pl={0.25}>
              Alerts
            </Box>
          </Fragment>
        ) : null}
      </Box>
      {site.address ? (
        <Fragment>
          <Box display="flex" mt={1}>
            <Box component="span" className={classes.title12BoldLight} mr={0.5}>
              Address
            </Box>
          </Box>
          <Box display="flex">
            <Box component="span" className={classes.title14Normal} mr={0.5}>
              {site.address}
            </Box>
          </Box>
        </Fragment>
      ) : null}
      {site.nodes && site.nodes.length ? (
        <Fragment>
          <Box className={classes.horizontalDivider} />
          <Box display="flex" mb={1}>
            <Box component="span" className={classes.title12Bold} mr={0.5}>
              Nodes
            </Box>
          </Box>
          <NodesSection nodes={site.nodes} />
        </Fragment>
      ) : null}
    </Box>
  );
};

SiteTooltip.propTypes = {
  site: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SiteTooltip;
