// flow
import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Transition} from 'react-transition-group';
import {isEmpty} from 'lodash';
import {getNumLastDays} from 'insightsPanelNewAlertConsole/store/selectors';
import {fadeOpacity, formatTopFeedbackers} from 'insightsPanelNewAlertConsole/services/constants';
import fetchTriggeredAlertsFeedbackStatsProvider from 'insightsPanelNewAlertConsole/api/fetchTriggeredAlertsFeedbackStatsProvider';
import TopFeedbackerItem from 'insightsPanelNewAlertConsole/widgets/topFeedbackers/TopFeedbackerItem';

import './TopFeedbackers.module.scss';

const TopFeedbackers = ({title}: {title: String}) => {
  const numLastDays = useSelector(getNumLastDays);
  const [showData, setShowData] = useState(false);

  const feedbackPerMeasureDataProvider = fetchTriggeredAlertsFeedbackStatsProvider({
    numLastDays: numLastDays?.value,
  }).useQuery();

  const isLoading = feedbackPerMeasureDataProvider?.isLoading;

  const topFeedbackersData = useMemo(() => {
    let topFeedbackers;
    if (!isEmpty(feedbackPerMeasureDataProvider?.data)) {
      topFeedbackers = formatTopFeedbackers(feedbackPerMeasureDataProvider?.data);
    }
    return topFeedbackers;
  }, [feedbackPerMeasureDataProvider?.data]);

  useEffect(() => {
    if (topFeedbackersData) {
      setShowData(true);
    }
  }, [topFeedbackersData]);

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && topFeedbackersData && (
        <Transition in={showData} timeout={fadeOpacity.duration}>
          {(state) => (
            <>
              <header
                styleName="header"
                style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state], marginBottom: '3px'}}
              >
                {title}
              </header>
              <div styleName="main-container">
                {!topFeedbackersData?.length ? (
                  <div styleName="no-feedback">
                    <span>Not enough data to show</span>
                  </div>
                ) : (
                  topFeedbackersData.map((i, index) => (
                    <TopFeedbackerItem
                      name={i.name}
                      feedbackCount={i.feedbackCount}
                      index={index}
                      key={`topFeedbackerItem${i.name}`}
                    />
                  ))
                )}
              </div>
            </>
          )}
        </Transition>
      )}
    </div>
  );
};

export default TopFeedbackers;
