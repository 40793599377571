// @flow
import React, {PureComponent, Fragment} from 'react';
import {Modal} from 'react-bootstrap';
import Script from 'react-load-script';
import Loader from 'common/components/Loader';
import {loadStyleSheet} from 'common/utils/css';
import JsonGuideLines from './JsonGuideLines';
import './JsonEditorModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  json: string,
  setStreamJson: Function,
};

export default class JsonEditorModal extends PureComponent {
  props: PropTypes;

  state = {
    codeMirrorLoaded: false,
    jsonModeLoaded: false,
  };

  componentDidMount() {
    loadStyleSheet('codemirrorStylesheet', 'statics/query.editor/codemirror/lib/codemirror.css');
  }

  componentDidUpdate() {
    const {codeMirrorLoaded, jsonModeLoaded} = this.state;
    if (codeMirrorLoaded && jsonModeLoaded && !this.CodeMirror) {
      /* eslint-disable no-undef */
      const editor = CodeMirror.fromTextArea(document.getElementById('codeJson'), {
        lineNumbers: true,
        autofocus: true,
        matchBrackets: true,
        autoCloseBrackets: true,
        mode: {name: 'javascript', json: true},
        lineWrapping: true,
      });
      /* eslint-enable no-undef */
      editor.doc.setValue(this.props.json);
      this.CodeMirror = editor;
    }
  }

  handleClose = () => {
    this.props.onClose();
  };

  handleCodeMirrorScriptLoad = () => {
    this.setState({
      codeMirrorLoaded: true,
    });
  };

  handleJsModeScriptLoad = () => {
    this.setState({
      jsonModeLoaded: true,
    });
  };

  handleNext = () => {
    this.props.setStreamJson({
      json: this.CodeMirror.doc.getValue(),
    });
    this.props.onClose();
  };

  getEditor = () => {
    const {codeMirrorLoaded, jsonModeLoaded} = this.state;

    if (codeMirrorLoaded && jsonModeLoaded) {
      return (
        <Fragment>
          <div styleName="editor-wrapper">
            <textarea id="codeJson" />
          </div>
        </Fragment>
      );
    }
    return <Loader />;
  };

  render() {
    const {isOpen, onClose} = this.props;

    const {codeMirrorLoaded, jsonModeLoaded} = this.state;

    return (
      <div>
        {!codeMirrorLoaded && (
          <Script url="statics/query.editor/codemirror/lib/codemirror.js" onLoad={this.handleCodeMirrorScriptLoad} />
        )}
        {codeMirrorLoaded && !jsonModeLoaded && (
          <Script
            url="statics/query.editor/codemirror/mode/javascript/javascript.js"
            onLoad={this.handleJsModeScriptLoad}
          />
        )}
        <Modal show={isOpen} onHide={onClose} dialogClassName="bc sql-query-modal" backdrop="static">
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <span>JSON Editor</span>
            </Modal.Title>
            <button type="button" className="btn btn-flat btn-icon-36 btn-secondary" onClick={this.handleClose}>
              <i className="icon icn-icon-table-delete" automation-id="exitQueryEditor" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div styleName="root">
              {this.getEditor()}
              <div styleName="info-wrapper">
                <JsonGuideLines />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-raised" onClick={this.handleNext}>
              CONTINUE
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
