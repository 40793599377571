import React, {memo, useMemo, useState, useEffect, useCallback} from 'react';

import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import PropTypes from 'prop-types';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import OptionComponentCheckbox from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentCheckbox';
import useSectionsClasses from 'dataCollectors/styles/classes.useSectionsClasses';
import useDataMapperClasses from 'dataCollectors/styles/classes.useDataMapperClasses';

const {sections} = useSectionsClasses();
const {classes} = useDataMapperClasses();

const DataMapperItem = ({
  eventType,
  onChange,
  options,
  disabled,
  selectedItem,
  isLoading,
  name,
  errorMsg,
  isMandatory,
  onClear,
  isMulti,
}) => {
  const sectionsClasses = sections({leftSideWidth: '50%'});
  const dataMapperClasses = classes();

  const [selectedItemState, setSelectedItemState] = useState(null);
  const [label, setLabel] = useState(null);

  const setIsPristine = useMemo(() => {
    if (!isMulti) {
      return !selectedItemState;
    }
    return !selectedItemState || !selectedItemState.length;
  }, [selectedItemState]);

  const setItemsLabels = () => {
    // at first run when only the stream data arrived and not the metaData we can have a selections with undefined so, we check if at least the first one really exist.
    if (selectedItemState && selectedItemState.length && selectedItemState[0]) {
      if (selectedItemState.length === 1) {
        setLabel(selectedItemState[0].label);
      } else {
        setLabel(`${selectedItemState[0].label} + ${selectedItemState.length - 1}`);
      }
    } else {
      setLabel(null);
    }
  };

  const setItemLabel = () => {
    if (selectedItemState) {
      setLabel(selectedItemState.label);
    }
  };

  const setSelectedItem = (item) => {
    if (item && options) {
      setSelectedItemState(options.find((i) => i.value === item.sourceColumn));
    } else {
      setSelectedItemState(null);
    }
  };

  const setSelectedItems = (items) => {
    const newArr = [];

    if (items) {
      Object.keys(items).forEach((key) => {
        newArr.push(options.find((i) => i.value === items[key].sourceColumn));
      });
    }
    setSelectedItemState(newArr);
  };

  const handleOnChange = useCallback((newItems) => {
    onChange(eventType, newItems, isMulti);
  }, []);

  const handleOnClear = useCallback(() => {
    onClear(eventType, null, selectedItem);
  }, [selectedItem]);

  useEffect(() => {
    if (isMulti) {
      setItemsLabels();
    } else {
      setItemLabel();
    }
  }, [selectedItemState]);

  useEffect(() => {
    if (isMulti) {
      setSelectedItems(selectedItem);
    } else {
      setSelectedItem(selectedItem);
    }
  }, [options, selectedItem]);

  return (
    <div className={sectionsClasses.inputsWrapper2}>
      <div className={`${sectionsClasses.input} ${sectionsClasses.inputWrapper3}`}>
        <div className={sectionsClasses.inputWrapper4}>
          <FormDdlSelect
            isMulti={isMulti}
            isDisabledSorting
            disabled={disabled}
            isUseSearch
            options={options}
            selected={selectedItemState}
            button={
              <BigDropdownButton
                isPristine={setIsPristine}
                label={label}
                placeholder={isLoading ? 'Loading..' : 'Select'}
                shortBlueStyle
                isFullWidth
              />
            }
            optionComponent={isMulti ? <OptionComponentCheckbox /> : <OptionComponentSimple />}
            onChange={handleOnChange}
            width={240}
            height={300}
            footerComponent={
              <FormDdlActionBar isVisible={selectedItem} onApply={handleOnClear} ApplyButtonText="Clear" />
            }
          />
          {errorMsg && !selectedItemState && <span className={sectionsClasses.inputError}>{errorMsg}</span>}
        </div>
      </div>

      <div className={`${sectionsClasses.input} ${dataMapperClasses.type} ${sectionsClasses.inputWrapper4}`}>
        <i className={`${dataMapperClasses.arrowWrapper} icon icn-arrow16-arrowright`} />
        <span>
          {name}
          {isMandatory ? '*' : ''}
        </span>
      </div>
    </div>
  );
};

DataMapperItem.defaultProps = {
  errorMsg: null,
  isMandatory: false,
  isMulti: false,
  selectedItem: null,
};

DataMapperItem.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  eventType: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  errorMsg: PropTypes.string,
  isMandatory: PropTypes.bool,
  isMulti: PropTypes.bool,
  selectedItem: PropTypes.objectOf(PropTypes.string),
};

export default memo(DataMapperItem);
