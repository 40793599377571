import {useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';
import {stringifyAndEscapeQS} from 'common/utils/http';
import {alertsConsoleRoot, fetchTriggeredAlertsParams} from './utils';

const fetchTriggeredAlertsTotal = ({payload}) => {
  return get(`${alertsConsoleRoot}triggered/count?${stringifyAndEscapeQS(payload)}`);
};

export default function fetchTriggeredAlertsTotalProvider(queryParams) {
  const queryClient = useQueryClient();

  const params = fetchTriggeredAlertsParams(queryParams);

  // Removing the breachTimeFrom react query key, since it is calculated using Now time, so it keeps changing,
  // thus changing the queryKey. Also, there is no need for pageNum in the total alerts queryKey API.
  const {breachTimeFrom, ...queryKeyParams} = params;
  // Removing the constRange, since it is not a parameter of the alerts-console/triggered API.
  const {constRange, ...fetchParams} = params;

  const queryKey = [QUERY_KEYS.triggeredAlertsTotal, queryKeyParams];
  return {
    invalidate: (partialKey) => queryClient.invalidateQueries(partialKey ? [partialKey] : queryKey),
    reset: (partialKey) => queryClient.resetQueries(partialKey ? [partialKey] : queryKey),
    useQuery: () =>
      useQuery(queryKey, () => fetchTriggeredAlertsTotal({payload: {breachTimeFrom: fetchParams.breachTimeFrom}}), {
        retry: false,
      }),
  };
}

// Is this Needed now that the total is returned with the triggered alerts, except for the AlertsTriggeredGauge.js
// fetchTotalTriggeredAlertsByConstRange() {
//   if (this.queryParams.constRange === rangeTypes.c.value) {
//     this.props.fetchTriggeredAlertsTotal({startTime: this.queryParams.startTime});
//     return;
//   }
//
//   if (this.queryParams.constRange === rangeTypes.r.value) {
//     const computedDate = getDate(this.queryParams);
//     this.props.fetchTriggeredAlertsTotal({
//       startTime: correctTimestampUnix(computedDate.startDate),
//       endTime: correctTimestampUnix(computedDate.endDate),
//     });
//     return;
//   }
//
//   const startTime = moment()
//     .subtract(
//       rangeTypes[this.queryParams.constRange].momentStart[0],
//       rangeTypes[this.queryParams.constRange].momentStart[1],
//     )
//     .unix(this.props.timeZoneName);
//   this.props.fetchTriggeredAlertsTotal({startTime});
// }
