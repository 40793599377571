import {get, post} from 'common/utils/http';

const root = 'api/v1/access-list/';

export const fetchAccessListForAllSources = ({meta}) => get(`${root}metricSources/${meta.type}`);

export const fetchAccessListForAllChannels = () => get(`${root}channels/`);

export const fetchAccessListByGroups = ({payload, meta}) =>
  post(`${root}metricSources/${meta.type}/searchByUserGroups`, payload.groupsIds);

export const setAccesslistForSource = ({payload, meta}) =>
  post(`${root}metricSources/${meta.type}/${payload.streamId}`, payload.groupsIds);

export const getAccesslistForSource = ({payload, meta}) => get(`${root}metricSources/${meta.type}/${payload.streamId}`);

export const setAccesslistForChannel = ({payload}) => post(`${root}channels/${payload.channelId}`, payload.groupsIds);

export const fetchChannelByMetricSource = ({payload}) =>
  get(`${root}channels/searchByMetricSource?type=${payload.type}&streamId=${payload.streamId}`);
