import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import HeaderRow from 'common/componentsV2/accordion/HeaderRow';
import OuterRow from 'common/componentsV2/accordion/OuterRow';
import * as actions from 'admin.cs-portal/store/actions';
import * as selectors from 'admin.cs-portal/store/selectors';
import * as profileSelectors from 'profile/store/selectors';

import './ErrorsView.module.scss';

type PropTypes = {
  timeZone: String,
  streamErrors: Array,
  areStreamErrorsLoading: Boolean,
};

export default connect(
  (state) => ({
    selectedCustomer: selectors.getSelectedCustomer(state),
    timeZone: profileSelectors.getTimeZoneName(state),
    streamErrors: selectors.getStreamErrors(state),
    areStreamErrorsLoading: selectors.getIsStreamsReportLoading(state),
  }),
  {
    fetchStreamsReport: actions.fetchStreamsReport,
  },
)(
  class StreamErrors extends React.PureComponent {
    props: PropTypes;

    renderStreamErrors = () => {
      const {streamErrors, timeZone} = this.props;
      const err = [];
      streamErrors.map((stream) =>
        err.push(
          <OuterRow
            key={stream.stream_id}
            titles={[
              stream.stream_name,
              stream.failure_message,
              moment.tz(stream.modify_time, 'X', timeZone === 'Browser' ? 'UTC' : timeZone).format('HH:mm A'),
            ]}
            isOpen={false}
            canOpen={false}
            leftAlignLastItem
            showTooltip
            tooltip={[
              null,
              <div style={{textAlign: 'left', width: 'fit-content'}}>
                <p>{stream.failure_message}</p>
              </div>,
              null,
            ]}
            tooltipIndices={[1]}
          />,
        ),
      );
      return (
        <div key={streamErrors.length} style={{maxHeight: '30vh', overflowY: 'auto'}}>
          <HeaderRow titles={['Name', 'Error Message', 'Modify Time']} leftAlignLastItem />
          {err}
        </div>
      );
    };

    render() {
      const {streamErrors, areStreamErrorsLoading} = this.props;

      return (
        <div styleName="container">
          <header styleName="section-header">
            <span styleName="title">
              Stream Errors ({!areStreamErrorsLoading && streamErrors ? streamErrors.length : 'No Errors'})
            </span>
          </header>
          {this.renderStreamErrors()}
        </div>
      );
    }
  },
);
