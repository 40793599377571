// @flow
import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {fetchGroupsData} from 'admin.users/store/selectors';
import {getAlertConfigurationsItems} from 'alerts.management/store/selectors';
import AvatarBadge, {COLORS} from 'common/componentsV2/AvatarBadge';
import {ReactComponent as UserIcon} from 'app/images/profile_empty.svg';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {deleteAssignee, setAssignee} from 'alerts.console.new/api/api';
import {getAlertAssigneeEnabled, isCustomerAdmin as getIsCustomerAdmin, getUserProfile} from 'profile/store/selectors';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import {getUsersDataForAvatar} from 'alerts.console.new/services/alertsConsoleFiltersService';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import {queryErrorHandler} from 'reactQuery/queryClient';
import {ASSIGN_ACTION_TYPES} from 'alerts.console.new/api/triggeredAlertsCacheUpdate';
import {modalRouting} from 'investigationNewAlertConsole/services/investigationService';
import fetchGroupAlertsWithMetricsProvider from 'investigationNewAlertConsole/api/fetchGroupAlertsWithMetricsProvider';

import './alertContent.module.scss';

const useStyles = makeStyles((theme) => ({
  assigneeWrapper: {
    display: 'flex',
    '&:hover': {
      '& $closeIcon': {
        opacity: 1,
      },
    },
  },
  closeIcon: {
    borderRadius: 1,
    fontSize: 12,
    opacity: 0,
  },
  userIcon: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.gray[400]}`,
    marginTop: 0,
    marginLeft: 0,
  },
  usersList: {
    position: 'absolute',
  },
}));

const AlertAssignee = ({
  alertConfigurationId,
  alertGroupId,
  assignee,
  alertTriggerId,
  isDisplay,
  queryParams,
}: {
  alertConfigurationId: String,
  alertGroupId: String,
  assignee: String,
  alertTriggerId: String,
  isDisplay: Boolean,
  queryParams: Object,
}) => {
  const isAlertAssigneeEnabled = useSelector(getAlertAssigneeEnabled);

  const classes = useStyles();
  const me = useSelector(getUserProfile);
  const alertList = useSelector(getAlertConfigurationsItems);
  const isCustomerAdmin = useSelector(getIsCustomerAdmin);
  const groupsData = useSelector(fetchGroupsData);

  const triggeredAlertsProvider = fetchTriggeredAlertsProvider(queryParams);

  // Need to update the trigger of triage react query cache
  const investigationModalCacheParams = {
    [modalRouting.ANOMALY_ID]: alertGroupId,
    [modalRouting.TRIGGER_ID]: alertTriggerId,
    [modalRouting.INVESTIGATION_MODAL]: true,
  };
  const triggeredAlertGroupProvider = fetchGroupAlertsWithMetricsProvider(investigationModalCacheParams);

  const users = fetchUsersProvider()?.useQuery()?.data;

  const usersData = useMemo(() => {
    return getUsersDataForAvatar(users, groupsData);
  }, [users, groupsData]);

  const [assigneeUser, setAssigneeUser] = useState(usersData?.find((user) => user.id === assignee));

  useEffect(() => {
    if (assignee) {
      setAssigneeUser(usersData?.find((user) => user.id === assignee));
    } else {
      setAssigneeUser(undefined);
    }
  }, [assignee]);

  const alertAutoAssignmentGroup = useMemo(() => {
    return alertList.find((alert) => alert.data.id === alertConfigurationId)?.data?.autoAssignmentGroup;
  }, [alertList, alertConfigurationId]);

  const filteredUsersList = useMemo(() => {
    // if the alert has auto assigned group - allow assigning only users inside that group
    // if there is no auto assigned group - allow selecting all users
    return usersData
      .filter(
        (user) => !alertAutoAssignmentGroup || user.allGroups?.find((group) => group === alertAutoAssignmentGroup),
      )
      .map((user) => {
        return {label: user.fullName, value: user.id};
      });
  }, [usersData, alertAutoAssignmentGroup]);

  const isEditable = useMemo(() => {
    // Enable change assignee only for Admins or users from the same group
    return (
      isCustomerAdmin || !alertAutoAssignmentGroup || me.groups.find((group) => group === alertAutoAssignmentGroup)
    );
  }, [isCustomerAdmin, alertAutoAssignmentGroup]);

  const handleChange = async (selectedAssignee) => {
    const queryCacheParams = {
      assignee: selectedAssignee?.value,
      action: ASSIGN_ACTION_TYPES.ASSIGN,
    };
    triggeredAlertsProvider.syncAssigneeInCache(alertTriggerId, queryCacheParams);
    try {
      await setAssignee({alertTriggerId, assignee: selectedAssignee.value}).promise;
      await triggeredAlertsProvider.resetAllExceptCurrent();
      // Update the React Query cache of Triage alert trigger in case of success
      triggeredAlertGroupProvider.invalidate();
    } catch (err) {
      const error = {
        title: 'Assignment error',
        description: `Error while assigning alert to ${selectedAssignee.label}.\nError is: ${err}`,
      };
      queryErrorHandler(error);
      // Need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
      await triggeredAlertsProvider.invalidate();
    }
  };

  const handleRemove = async () => {
    const queryCacheParams = {
      action: ASSIGN_ACTION_TYPES.UN_ASSIGN,
    };
    triggeredAlertsProvider.syncAssigneeInCache(alertTriggerId, queryCacheParams);
    try {
      await deleteAssignee({alertTriggerId}).promise;
      await triggeredAlertsProvider.resetAllExceptCurrent();
      // Update the React Query cache of Triage alert trigger in case of success
      triggeredAlertGroupProvider.invalidate();
    } catch (err) {
      const error = {
        title: 'Assignment error',
        description: `Error while trying to un-assign user from alert.\nError is: ${err}`,
      };
      queryErrorHandler(error);
      // Need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
      await triggeredAlertsProvider.invalidate();
    }
  };

  const emptyAvatar =
    isDisplay && isEditable ? (
      <i className={classes.userIcon}>
        <UserIcon width={24} height={24} />
      </i>
    ) : (
      <div />
    );

  const assigneeAvatar = assigneeUser ? (
    <div className={classes.assigneeWrapper}>
      <AvatarBadge
        text={assigneeUser.abbr}
        tooltipText={assigneeUser.fullName}
        colorSchema={assigneeUser.defaultGroup ? assigneeUser.defaultGroup?.colorSchema : COLORS.GRAY}
      />
      {isEditable && (
        <div className={classes.closeIcon}>
          <i className="icon icn-general16-closea alert-clickable-item" role="presentation" onClick={handleRemove} />
        </div>
      )}
    </div>
  ) : (
    emptyAvatar
  );

  return (
    <>
      {isAlertAssigneeEnabled ? (
        <div className="alert-clickable-item" styleName="assignee" role="button">
          <FormDdlSelect
            button={assigneeAvatar}
            placement="auto"
            options={filteredUsersList}
            disabled={!isEditable}
            isUseSearch
            optionComponent={<OptionComponentSimple />}
            onChange={handleChange}
            width={290}
            height={260}
            automationId="assigneeSelection"
          />
        </div>
      ) : null}
    </>
  );
};

export default React.memo(AlertAssignee);
