import React, {Fragment} from 'react';
import {getFormattedUTCLLDate, getFormattedUTCTime} from 'common/utils/dateService';
import styles from './ViewStreamSummaryModalV2.module.scss';

const TimeValue = (props: {time: number, timeZoneName: string}) => (
  <Fragment>
    {`${getFormattedUTCLLDate(props.time, props.timeZoneName)}. ${getFormattedUTCTime(props.time, props.timeZoneName)}`}
  </Fragment>
);

const AgentProgress = (props: {progress: Object, timeZoneName: string}) => {
  if (!props.progress.last_offset) {
    return null;
  }
  const lastOffset = JSON.parse(props.progress.last_offset);

  return (
    <div className={styles.section}>
      <div className={styles['vs-section-header']}>PROGRESS</div>
      <div className={styles['vs-row']}>
        <div className={styles['vs-label']}>version</div>
        <div className={styles['vs-value']}>{lastOffset?.version}</div>
      </div>
      <div className={styles['vs-row']}>
        <div className={styles['vs-label']}>offsets</div>
        {lastOffset?.offsets &&
          lastOffset?.offsets[''] &&
          (!Number.isNaN(Date.parse(lastOffset.offsets[''])) ? (
            <TimeValue time={Date.parse(lastOffset?.offsets[''])} timeZoneName={props.timeZoneName} />
          ) : (
            <span>{lastOffset?.offsets['']}</span>
          ))}
      </div>
    </div>
  );
};

const AgentScheduler = (props: {scheduling: Object}) => {
  return (
    <div className={styles.section}>
      <div className={styles['vs-section-header']}>SCHEDULING</div>
      {Object.keys(props.scheduling).map((key) => {
        return (
          <div className={styles['vs-row']}>
            <div className={styles['vs-label']}>{key}</div>
            <div className={styles['vs-value']}>{props.scheduling[key]}</div>
          </div>
        );
      })}
    </div>
  );
};

const AgentConfig = (props: {config: Object}) => {
  if (!props.config?.properties) {
    return null;
  }
  return (
    <div className={styles.section}>
      {Object.keys(props.config.properties).length > 0 && (
        <div>
          <div className={styles['vs-section-header']}>CONFIG</div>
          {Object.keys(props.config.properties).map((key) => {
            return (
              <div className={styles['vs-row']}>
                <div className={styles['vs-label']}>{key}</div>
                <div className={styles['vs-value']}>{props.config.properties[key]}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const AgentData = (props: {dataStream: Object, timeZoneName: string}) => {
  if (!props.dataStream) {
    return null;
  }
  return (
    <div>
      {props.dataStream.scheduling && <AgentScheduler scheduling={props.dataStream.scheduling} />}
      {props.dataStream.progress && (
        <AgentProgress progress={props.dataStream.progress} timeZoneName={props.timeZoneName} />
      )}
      {props.dataStream.config && <AgentConfig config={props.dataStream.config} />}
    </div>
  );
};
export default AgentData;
