import {makeStyles} from '@material-ui/core';

export default function useSectionsClasses() {
  const sections = makeStyles(({typography, palette}) => ({
    root: {
      marginBottom: 70,
      paddingBottom: 50,
      borderBottom: `1px solid ${palette.gray['300']}`,
    },
    rootLast: {
      marginBottom: 30,
    },
    container: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
    },
    left: {
      width: ({leftSideWidth}) => leftSideWidth,
      right: 0,
      height: '100%',
    },
    right: {
      width: 300,
      textAlign: 'right',
    },
    title: {
      ...typography.h4,
      marginBottom: 4,
      '&.error': {
        color: palette.red['400'],
      },
    },
    subTitle: {
      ...typography.body,
      color: palette.gray['350'],
      '&.error': {
        color: palette.red['500'],
      },
    },
    fileWrapper: {
      ...typography.body,
      width: '587px',
      // height: '59px',
      border: '1px solid #C9DBFF',
      borderRadius: '8px',
      padding: '15px',
      fontSize: '14px',
    },
    inputsWrapper2: {
      display: 'grid',
      gridGap: 20,
      gridTemplateColumns: 'repeat(2, minmax(auto, 1fr));',
      rowGap: 46,
      marginTop: 24,
      transition: 'all 0.1s ease-in-out',
    },
    inputWrapper3: {
      display: 'flex',
    },
    inputWrapper4: {
      width: 200,
    },
    input: {
      position: 'relative',
    },

    description: {
      gridArea: '2 / 1 / 3 / 3',
    },
    label: {
      ...typography.body,
    },
    wrapperHalfRow: {
      flex: 1,
      marginTop: 5,
      position: 'relative',
    },
    marginRight: {
      marginRight: 10,
    },
    marginLeft: {
      marginLeft: 10,
    },
    marginTop: {
      marginTop: 15,
    },
    inputError: {
      ...typography.subtitle4,
      color: palette.red['500'],
      position: 'absolute',
      bottom: -16,
    },
    spacer: {
      width: '100%',
      height: 1,
      marginTop: 80,
      marginBottom: 90,
      backgroundColor: palette.gray['300'],
    },
    clearSpacer: {
      width: '100%',
      marginTop: 80,
      marginBottom: 90,
    },
  }));

  return {sections};
}
