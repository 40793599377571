import React, {useState} from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';
import {makeStyles} from '@material-ui/core';

const additionalProps = {
  autoComplete: 'off',
};

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
  },
  input: {
    width: '100%',
    padding: '12px 36px 12px 12px',
    boxSizing: 'border-box',
  },
  eye: {
    position: 'absolute',
    top: '28%',
    right: '4%',
  },
}));

const InputField = ({
  fieldFormName,
  placeholder,
  isPassword,
  isCreate,
}: {
  fieldFormName: String,
  placeholder: String,
  isPassword: Boolean,
  isCreate: Boolean,
}) => {
  const classes = useStyles();

  const [initialValue, setInitialValue] = useState(isPassword && !isCreate ? 'fake password' : '');

  const {
    input: {value, onChange},
    meta,
  } = useField(fieldFormName);

  return (
    <div className={classes.container}>
      <Input
        additionalProps={additionalProps}
        automationId={`inputField-${fieldFormName}`}
        id={fieldFormName}
        isInvalid={meta.touched && meta.error}
        invalidMessage={meta.error}
        onChange={onChange}
        onFocus={() => {
          if (isPassword) {
            setInitialValue('');
          }
        }}
        placeHolder={placeholder}
        value={isPassword ? value || initialValue : value}
        type={isPassword ? 'password' : 'text'}
      />
      {isPassword && <i className={`icon icn-view-icon ${classes.eye}`} />}
    </div>
  );
};

export default InputField;
