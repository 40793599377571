import {createSelector} from 'reselect';
import * as usersSelectors from 'admin.users/store/selectors';
import * as channelsSelectors from 'alerts.channels/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import {allowedFilterKeys, DEFAULT_QUERY_PARAMS} from 'alerts.management/services/alertManagementService';
import {cloneDeep, uniq, isEqual, omitBy, get, merge, intersection} from 'lodash';
import {findExpressionInTree} from 'metrics/services/compositeService';
import {resolutionTypes} from 'metrics/services/metricsService';
import {getAlertConfigurationsItems, getAlertFilters, getTagsList} from './selectors';
import {getDataStreamsItems} from '../../bc/store/selectors';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const GROUPS_HEADER = {
  label: 'GROUPS',
  value: 'GROUPS',
  type: 'HEADER',
  headerClass: 'andt-dropdown-option-selected-signed-header',
};

const USERS_HEADER = {
  label: 'USERS',
  value: 'USERS',
  type: 'HEADER',
  headerClass: 'andt-dropdown-option-selected-signed-header',
};

const filterKeyHeaders = {
  searchQuery: {
    id: 'searchQuery',
    label: 'Search:',
  },
  whose: {
    id: 'whose',
    label: 'Whose:',
  },
  status: {
    id: 'status',
    label: 'Status:',
  },
  tag: {
    id: 'tag',
    label: 'Labels:',
  },
  type: {
    id: 'type',
    label: 'Type:',
  },
  severity: {
    id: 'severity',
    label: 'Severity:',
  },
  notifications: {
    id: 'notifications',
    label: 'Has Notifications:',
  },
  recommendations: {
    id: 'recommendations',
    label: 'Has Recommendations:',
  },
  timeScale: {
    id: 'timeScale',
    label: 'Timescale:',
  },
  owner: {
    id: 'owner',
    label: 'Owner:',
  },
  stream: {
    id: 'stream',
    label: 'From Data Stream:',
  },
  recipients: {
    id: 'recipients',
    label: 'Recipients:',
  },
  anomalyDirection: {
    id: 'anomalyDirection',
    label: 'Anomaly Direction:',
  },
};

//* alert manager - filters - searchQuery
export const getAlertFiltersSearchQuery = createSelector(
  getAlertFilters,
  (filters) => filters.searchQuery,
);

//* alert manager - filters - whose
export const getAlertFiltersWhose = createSelector(
  getAlertFilters,
  (filters) => filters.whose,
);

//* alert manager - filters - status
export const getAlertFiltersStatus = createSelector(
  getAlertFilters,
  (filters) => filters.status,
);

//* alert manager - filters - type
export const getAlertFiltersType = createSelector(
  getAlertFilters,
  (filters) => filters.type,
);

//* alert manager - filters - severity
export const getAlertFiltersSeverity = createSelector(
  getAlertFilters,
  (filters) => filters.severity,
);

//* alert manager - filters - notifications
export const getAlertFiltersNotifications = createSelector(
  getAlertFilters,
  (filters) => filters.notifications,
);

//* alert manager - filters - recommendations
export const getAlertFiltersRecommendations = createSelector(
  getAlertFilters,
  (filters) => filters.recommendations,
);

//* alert manager - filters - owner
export const getAlertFiltersOwner = createSelector(
  getAlertFilters,
  (filters) => filters.owner,
);

//* alert manager - filters - subscribers
export const getAlertFiltersSubscibers = createSelector(
  getAlertFilters,
  (filters) => filters.subscribers,
);

//* alert manager - filters - channels
export const getAlertFiltersChannels = createSelector(
  getAlertFilters,
  (filters) => filters.channels,
);

//* alert manager - filters - stream
export const getAlertFiltersStream = createSelector(
  getAlertFilters,
  (filters) => filters.stream,
);

//* alert manager - filters - anomalyDirection
export const getAlertFiltersAnomalyDirection = createSelector(
  getAlertFilters,
  (filters) => filters.anomalyDirection,
);

//* alert manager - filters - timeScale
export const getAlertFiltersTimeScale = createSelector(
  getAlertFilters,
  (filters) => filters.timeScale,
);

export const getFilterOwnerList = createSelector(
  getAlertConfigurationsItems,
  usersSelectors.getUsersGroupsLists,
  (items, userGroupLists) => {
    if (!items.length || !userGroupLists) {
      return EMPTY_ARRAY;
    }

    const ownerIdList = [];
    items.forEach((item) => {
      if (ownerIdList.indexOf(item.ownerId) === -1) {
        ownerIdList.push(item.ownerId);
      }
    });

    const userArr = userGroupLists.userList.filter((user) => ownerIdList.indexOf(user.value) !== -1);
    const groupsArr = userGroupLists.groupList.filter((gr) => ownerIdList.indexOf(gr.value) !== -1);

    let resArr = EMPTY_ARRAY;
    if (groupsArr.length) {
      resArr = [GROUPS_HEADER, ...groupsArr];
    }

    if (userArr.length) {
      resArr = [...resArr, USERS_HEADER, ...userArr];
    }

    return resArr;
  },
);

export const getFilterStreamNameList = createSelector(
  getAlertConfigurationsItems,
  getDataStreamsItems,
  (items, streamItems) => {
    if (!items.length) {
      return EMPTY_ARRAY;
    }

    const streamNamesList = [];
    const streamsList = [];
    items.forEach((item) => {
      if (item.data?.expressionTreeModel?.expressionTree?.root) {
        const expressionNode = findExpressionInTree(item.data.expressionTreeModel.expressionTree.root);
        expressionNode.searchObject.expression.forEach((exp) => {
          if (exp.type === 'origin' && exp.originType === 'STREAM' && streamNamesList.indexOf(exp.value) === -1) {
            streamNamesList.push(exp.value);
            let streamName = exp.value;
            let isStream = true;
            if (exp.key === 'originId') {
              const streamObject = streamItems.find(
                (streamItem) => streamItem.id === exp.value || streamItem.schemaId === exp.value,
              );
              if (streamObject) {
                streamName = streamObject.name;
              } else {
                isStream = false;
              }
            }
            if (isStream) {
              streamsList.push({
                name: streamName,
                value: exp.value,
              });
            }
          }
        });
      }
    });

    streamsList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    return streamsList;
  },
);

export const getFilterAnomalyDirectionsList = createSelector(() => Object.values(allowedFilterKeys.anomalyDirection));

export const getSelectedAnomalyDirections = createSelector(
  getFilterAnomalyDirectionsList,
  getAlertFiltersAnomalyDirection,
  (anomalyDirList, anomalyDirection) =>
    anomalyDirList.filter((item) => anomalyDirection && anomalyDirection.indexOf(item.value) !== -1),
);

export const getFilterSeverityWithCount = createSelector(
  getAlertConfigurationsItems,
  (items) => {
    if (!items.length) {
      return EMPTY_ARRAY;
    }

    const severityObgClone = cloneDeep(allowedFilterKeys.severity);
    items.forEach((item) => {
      const severityItem = severityObgClone[item.data.severity];
      severityItem.count = severityItem.count === undefined ? 1 : severityItem.count + 1;
    });

    return Object.values(severityObgClone);
  },
);

export const getSelectedFilterSeverity = createSelector(
  getFilterSeverityWithCount,
  getAlertFiltersSeverity,
  (severityList, severity) => severityList.filter((item) => severity && severity.indexOf(item.value) !== -1),
);

export const getFilterAlertTypeWithCount = createSelector(
  getAlertConfigurationsItems,
  (items) => {
    if (!items.length) {
      return EMPTY_ARRAY;
    }

    const typesObgClone = cloneDeep(allowedFilterKeys.type);
    items.forEach((item) => {
      if (item.data.anomalyAlert) {
        typesObgClone.anomaly.count = typesObgClone.anomaly.count === undefined ? 1 : typesObgClone.anomaly.count + 1;
      }
      if (item.data.staticAlert) {
        typesObgClone.static.count = typesObgClone.static.count === undefined ? 1 : typesObgClone.static.count + 1;
      }
      if (item.data.noDataAlert) {
        typesObgClone.noData.count = typesObgClone.noData.count === undefined ? 1 : typesObgClone.noData.count + 1;
      }
    });

    return Object.values(typesObgClone);
  },
);

export const getSelectedFilterType = createSelector(
  getFilterAlertTypeWithCount,
  getAlertFiltersType,
  (typeList, type) => typeList.filter((item) => type && type.indexOf(item.value) !== -1),
);

export const getFilterAlertTimeScaleWithCount = createSelector(
  getAlertConfigurationsItems,
  (items) => {
    if (!items.length) {
      return EMPTY_ARRAY;
    }

    const resolutionTypesObgClone = cloneDeep(resolutionTypes);
    delete resolutionTypesObgClone.auto;

    items.forEach((item) => {
      const resolutionTypeItem = resolutionTypesObgClone[item.data.rollups];
      resolutionTypeItem.count = resolutionTypeItem.count === undefined ? 1 : resolutionTypeItem.count + 1;
    });

    return Object.values(resolutionTypesObgClone);
  },
);

export const getSelectedFilterTimeScale = createSelector(
  getFilterAlertTimeScaleWithCount,
  getAlertFiltersTimeScale,
  // eslint-disable-next-line max-len
  (timeScaleTypes, timeScale) =>
    timeScaleTypes.filter((item) => timeScale && timeScale.split(',').indexOf(item.value) !== -1) || null,
);

export const getFilterAlertRecipients = createSelector(
  channelsSelectors.getSortedFlattenChannels,
  getAlertConfigurationsItems,
  (channels, rawAlerts) => {
    if (!rawAlerts.length || !channels.length) {
      return EMPTY_ARRAY;
    }

    const userIds = [];
    const channelIds = [];
    rawAlerts.forEach((alert) => {
      if (alert.data.subscribers && alert.data.subscribers.length) {
        userIds.push(...alert.data.subscribers);
      }
      if (alert.data.channels && alert.data.channels.length) {
        channelIds.push(...alert.data.channels);
      }
    });

    const uniqUserIds = uniq(userIds);
    const uniqChannelsIds = uniq(channelIds);

    const recipientsListFilteredUsersAndChannels = channels.filter(
      (channel) =>
        channel.type === 'HEADER' ||
        uniqUserIds.indexOf(channel.value) !== -1 ||
        uniqChannelsIds.indexOf(channel.value) !== -1,
    );

    return recipientsListFilteredUsersAndChannels.filter((channel) => {
      if (channel.type === 'HEADER') {
        return recipientsListFilteredUsersAndChannels.findIndex((item) => item.type === channel.value) !== -1;
      }
      return true;
    });
  },
);

export const getSelectedFilterRecipients = createSelector(
  getFilterAlertRecipients,
  getAlertFiltersSubscibers,
  getAlertFiltersChannels,
  (alertRecipients, subscribers, channels) => {
    const selectedRecipients = alertRecipients.filter(
      (item) =>
        (subscribers && subscribers.split(',').indexOf(item.value) !== -1) ||
        (channels && channels.split(',').indexOf(item.value) !== -1),
    );
    return selectedRecipients && selectedRecipients.length !== 0 ? selectedRecipients : null;
  },
);

export const getChangedFilters = createSelector(
  getAlertFilters,
  (filters) => omitBy(filters, (value, key) => value === DEFAULT_QUERY_PARAMS[key]),
);

export const getIsMoreFilters = createSelector(
  getChangedFilters,
  (changedFilters) => {
    const changedKeys = Object.keys(changedFilters);
    const moreKeys = [
      'notifications',
      'recommendations',
      'timeScale',
      'owner',
      'stream',
      'subscribers',
      'channels',
      'anomalyDirection',
    ];
    return !!intersection(changedKeys, moreKeys).length;
  },
);

const getFilterTooltipValue = (filterKey, filterValue, filterValueLabel = 'text') =>
  filterValue
    .split(',')
    .map((filterVal) => allowedFilterKeys[filterKey][filterVal][filterValueLabel])
    .join(', ');

const getOwnerTooltipValue = (ownerList, filterValue) => {
  if (!filterValue) {
    return null;
  }
  return ownerList
    .filter((owner) => filterValue.split(',').indexOf(owner.value) !== -1)
    .map((item) => item.label)
    .join(', ');
};

export const getFiltersButtonTooltipItems = createSelector(
  getChangedFilters,
  getFilterOwnerList,
  getSelectedFilterRecipients,
  (changedFilters, ownerList, selectedRecipients) => {
    const filterItemsList = [];
    Object.keys(changedFilters).forEach((filterKey) => {
      const item = {
        id: get(filterKeyHeaders[filterKey], 'id', null),
        header: get(filterKeyHeaders[filterKey], 'label', null),
      };

      switch (filterKey) {
        case filterKeyHeaders.searchQuery.id:
          filterItemsList.push({
            ...item,
            value: changedFilters[filterKey],
          });
          break;
        case filterKeyHeaders.whose.id:
        case filterKeyHeaders.status.id:
          filterItemsList.push({
            ...item,
            value: allowedFilterKeys[filterKey][changedFilters[filterKey]].label,
          });
          break;
        case filterKeyHeaders.severity.id:
        case filterKeyHeaders.type.id:
        case filterKeyHeaders.timeScale.id:
          filterItemsList.push({
            ...item,
            value: getFilterTooltipValue(filterKey, changedFilters[filterKey]),
          });
          break;
        case filterKeyHeaders.anomalyDirection.id:
          filterItemsList.push({
            ...item,
            value: getFilterTooltipValue(filterKey, changedFilters[filterKey], 'label'),
          });
          break;
        case filterKeyHeaders.tag.id:
        case filterKeyHeaders.stream.id:
          filterItemsList.push({
            ...item,
            value: changedFilters[filterKey].split(',').join(', '),
          });
          break;
        case filterKeyHeaders.notifications.id:
          filterItemsList.push({
            ...item,
            value: changedFilters[filterKey] ? 'Yes' : DEFAULT_QUERY_PARAMS[filterKey],
          });
          break;
        case filterKeyHeaders.recommendations.id:
          filterItemsList.push({
            ...item,
            value: changedFilters[filterKey] ? 'Yes' : DEFAULT_QUERY_PARAMS[filterKey],
          });
          break;
        case filterKeyHeaders.owner.id:
          filterItemsList.push({
            ...item,
            value: getOwnerTooltipValue(ownerList, changedFilters[filterKey]),
          });
          break;
        default:
          break;
      }
    });

    if (selectedRecipients) {
      let val = null;
      switch (selectedRecipients.length) {
        case 1:
          val = selectedRecipients[0].label;
          break;
        case 2:
          val = `${selectedRecipients[0].label}, ${selectedRecipients[1].label}`;
          break;
        default:
          val = `${selectedRecipients[0].label}, ${selectedRecipients[1].label}, +${selectedRecipients.length - 2}`;
          break;
      }
      filterItemsList.push({
        id: filterKeyHeaders.recipients.id,
        header: filterKeyHeaders.recipients.label,
        value: val,
      });
    }

    return filterItemsList;
  },
);

export const getAlertManagerAppSettings = createSelector(
  profileSelectors.getMeAppSettings,
  (appSettings) => appSettings.alertManager || EMPTY_OBJECT,
);

export const getAlertManagerFilersViewSaved = createSelector(
  getAlertManagerAppSettings,
  (amSettings) => amSettings.changedFilters || EMPTY_OBJECT,
);

export const getIsShowClearAll = createSelector(
  getAlertFilters,
  getAlertManagerFilersViewSaved,
  (filters, savedFilterView) => {
    const clearFilterParams = merge({}, DEFAULT_QUERY_PARAMS, savedFilterView);
    return !isEqual(filters, clearFilterParams);
  },
);

export const getTagListFilter = createSelector(
  getTagsList,
  (tagList) => tagList || EMPTY_ARRAY,
);

export const getAlertFiltersTag = createSelector(
  getAlertFilters,
  (filters) => filters.tag,
);

export const getSelectedFilterTag = createSelector(
  getTagListFilter,
  getAlertFiltersTag,
  (tagList, tag) => {
    const selectedTagsArr = tag ? tag.split(',') : null;
    return selectedTagsArr && tagList.length !== 0
      ? tagList.filter((item) => selectedTagsArr.indexOf(item.name) !== -1)
      : null;
  },
);

export const getSelectedFilterOwner = createSelector(
  getFilterOwnerList,
  getAlertFiltersOwner,
  (ownerList, owner) => {
    const selectedOwnersArr = owner ? owner.split(',') : null;
    return selectedOwnersArr && ownerList.length !== 0
      ? ownerList.filter((item) => selectedOwnersArr.indexOf(item.value) !== -1)
      : null;
  },
);

export const getSelectedFilterStreamNames = createSelector(
  getFilterStreamNameList,
  getAlertFiltersStream,
  (streamList, stream) => {
    const selectedStreamsArr = stream ? stream.split(',') : null;
    return selectedStreamsArr && streamList.length !== 0
      ? streamList.filter((item) => selectedStreamsArr.indexOf(item.value) !== -1)
      : null;
  },
);
