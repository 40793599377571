export const POLLING_INTERVAL_OPTIONS = {
  m1: {
    value: 'm1',
    label: '1 Minute',
    labelShort: '1 Minute',
    timeScale: 'short',
  },
  m5: {
    value: 'm5',
    label: '5 Minutes',
    labelShort: '5 Minutes',
    timeScale: 'medium',
  },
  m10: {
    value: 'm10',
    label: '10 Minutes',
    labelShort: '10 Minutes',
    timeScale: 'medium',
  },
  m15: {
    value: 'm15',
    label: '15 Minutes',
    labelShort: '15 Minutes',
    timeScale: 'medium',
  },
  m30: {
    value: 'm30',
    label: '30 Minutes',
    labelShort: '30 Minutes',
    timeScale: 'medium',
  },
  hourly: {
    value: 'hourly',
    label: 'Hourly',
    labelShort: 'Hour',
    timeScale: 'long',
  },
  h2: {
    value: 'h2',
    label: '2 Hours',
    labelShort: '2 Hours',
    timeScale: 'long',
  },
  h3: {
    value: 'h3',
    label: '3 Hours',
    labelShort: '3 Hours',
    timeScale: 'long',
  },
  h4: {
    value: 'h4',
    label: '4 Hours',
    labelShort: '4 Hours',
    timeScale: 'long',
  },
  h6: {
    value: 'h6',
    label: '6 Hours',
    labelShort: '6 Hours',
    timeScale: 'long',
  },
  h8: {
    value: 'h8',
    label: '8 Hours',
    labelShort: '8 Hours',
    timeScale: 'long',
  },
  h12: {
    value: 'h12',
    label: '12 Hours',
    labelShort: '12 Hours',
    timeScale: 'long',
  },
  daily: {
    value: 'daily',
    label: 'Daily',
    labelShort: 'Day',
    timeScale: 'longlong',
  },
};

export const RANGE_TYPES = {
  h1: {
    value: '1h',
    key: 'h1',
    momentStart: [1, 'hours'],
    label: 'Last Hour',
    shortText: 'an hour',
  },
  h3: {
    value: '3h',
    key: 'h3',
    momentStart: [3, 'hours'],
    label: 'Last 3 Hours',
  },
  h4: {
    value: '4h',
    key: 'h4',
    momentStart: [4, 'hours'],
    label: 'Last 4 Hours',
  },
  h12: {
    value: '12h',
    key: 'h12',
    momentStart: [12, 'hours'],
    label: 'Last 12 Hours',
  },
  d1: {
    value: '1d',
    key: 'd1',
    momentStart: [1, 'days'],
    label: 'Last 24 Hours',
    shortText: 'a day',
  },
  d2: {
    value: '2d',
    key: 'd2',
    momentStart: [2, 'days'],
    label: 'Last 48 Hours',
  },
  d3: {
    value: '3d',
    key: 'd3',
    momentStart: [3, 'days'],
    label: 'Last 3 Days',
  },
  w1: {
    value: '1w',
    key: 'w1',
    momentStart: [7, 'days'],
    label: 'Last 7 Days',
    shortText: 'a week',
  },
  d8: {
    value: '8d',
    key: 'd8',
    momentStart: [8, 'days'],
    label: 'Last 8 Days',
  },
  w2: {
    value: '2w',
    key: 'w2',
    momentStart: [14, 'days'],
    label: 'Last 14 Days',
    shortText: '2 weeks',
  },
  d15: {
    value: '15d',
    key: 'd15',
    momentStart: [15, 'days'],
    label: 'Last 15 Days',
  },
  m1: {
    value: '1m',
    key: 'm1',
    momentStart: [1, 'month'],
    label: 'Last 30 Days',
    shortText: 'a month',
  },
  m2: {
    value: '2m',
    key: 'm2',
    momentStart: [2, 'month'],
    label: 'Last 60 Days',
  },
  m3: {
    value: '3m',
    key: 'm3',
    momentStart: [3, 'month'],
    label: 'Last 90 Days',
    shortText: '3 month',
  },
  m6: {
    value: '6m',
    key: 'm6',
    momentStart: [6, 'month'],
    label: 'Last 180 Days',
    shortText: '6 month',
  },
  y1: {
    value: '1y',
    key: 'y1',
    momentStart: [1, 'year'],
    label: 'Last 365 Days',
    shortText: '1 year',
  },
  y2: {
    value: '2y',
    key: 'y2',
    momentStart: [2, 'year'],
    label: 'Last 2 Years',
    shortText: '2 year',
  },
  y5: {
    value: '5y',
    key: 'y5',
    momentStart: [5, 'year'],
    label: 'Last 5 Years',
    shortText: '5 year',
  },
  y10: {
    value: '10y',
    key: 'y10',
    momentStart: [10, 'year'],
    label: 'Last 10 Years',
    shortText: '10 year',
  },
  y15: {
    value: '15y',
    key: 'y15',
    momentStart: [15, 'year'],
    label: 'Last 15 Years',
    shortText: '15 year',
  },
  y20: {
    value: '20y',
    key: 'y20',
    momentStart: [20, 'year'],
    label: 'Last 20 Years',
    shortText: '120 year',
  },
  today: {
    value: 'today',
    key: 'today',
    momentStart: [0, 'days'],
    timePeriod: 'day',
    label: 'Today',
    shortText: 'Today',
  },
  yesterday: {
    value: 'yesterday',
    key: 'yesterday',
    momentStart: [1, 'days'],
    momentEnd: [1, 'days'],
    timePeriod: 'day',
    label: 'Yesterday',
    shortText: 'yesterday',
  },
  thisWeek: {
    value: 'thisWeek',
    key: 'thisWeek',
    momentStart: [0, 'days'],
    timePeriod: 'week',
    label: 'This Week',
    shortText: 'This Week',
  },
  lastWeek: {
    value: 'lastWeek',
    key: 'lastWeek',
    momentStart: [7, 'days'],
    momentEnd: [7, 'days'],
    timePeriod: 'week',
    label: 'Last Week',
    shortText: 'Last Week',
  },
  thisMonth: {
    value: 'thisMonth',
    key: 'thisMonth',
    momentStart: [0, 'months'],
    timePeriod: 'month',
    label: 'This Month',
    shortText: 'This Month',
  },
  lastMonth: {
    value: 'lastMonth',
    key: 'lastMonth',
    momentStart: [1, 'months'],
    momentEnd: [1, 'months'],
    timePeriod: 'month',
    label: 'Last Month',
    shortText: 'Last Month',
  },
  custom: {
    value: 'custom',
    key: 'custom',
    label: 'Custom',
  },
  c: {
    value: 'c',
    key: 'c',
    label: 'Custom Date',
  },
  r: {
    value: 'r',
    key: 'r',
    label: 'Relative',
  },
};

export const getAllowedRangeTypesByPollingInterval = (pollingInterval, isBcUnlimitedFileUploadTimeRange) => {
  if (isBcUnlimitedFileUploadTimeRange) {
    return [
      RANGE_TYPES.d1.key,
      RANGE_TYPES.d3.key,
      RANGE_TYPES.w1.key,
      RANGE_TYPES.m1.key,
      RANGE_TYPES.m3.key,
      RANGE_TYPES.m6.key,
      RANGE_TYPES.y1.key,
      RANGE_TYPES.y2.key,
      RANGE_TYPES.y5.key,
      RANGE_TYPES.y10.key,
      RANGE_TYPES.y15.key,
      RANGE_TYPES.y20.key,
    ];
  }
  switch (pollingInterval) {
    case POLLING_INTERVAL_OPTIONS.m1.value:
      return [
        RANGE_TYPES.h1.value,
        RANGE_TYPES.h4.value,
        RANGE_TYPES.d1.value,
        RANGE_TYPES.d3.value,
        RANGE_TYPES.w1.value,
      ];
    case POLLING_INTERVAL_OPTIONS.m5.value:
    case POLLING_INTERVAL_OPTIONS.m10.value:
    case POLLING_INTERVAL_OPTIONS.m15.value:
    case POLLING_INTERVAL_OPTIONS.m30.value:
      return [
        RANGE_TYPES.h1.value,
        RANGE_TYPES.h4.value,
        RANGE_TYPES.d1.value,
        RANGE_TYPES.d3.value,
        RANGE_TYPES.w1.value,
        RANGE_TYPES.m1.value,
      ];
    case POLLING_INTERVAL_OPTIONS.hourly.value:
    case POLLING_INTERVAL_OPTIONS.h2.value:
    case POLLING_INTERVAL_OPTIONS.h3.value:
    case POLLING_INTERVAL_OPTIONS.h4.value:
    case POLLING_INTERVAL_OPTIONS.h6.value:
    case POLLING_INTERVAL_OPTIONS.h8.value:
    case POLLING_INTERVAL_OPTIONS.h12.value:
      return [
        RANGE_TYPES.d1.value,
        RANGE_TYPES.d3.value,
        RANGE_TYPES.w1.value,
        RANGE_TYPES.m1.value,
        RANGE_TYPES.m3.value,
        RANGE_TYPES.m6.value,
        RANGE_TYPES.y1.value,
      ];
    case POLLING_INTERVAL_OPTIONS.daily.value:
      return [
        RANGE_TYPES.d1.value,
        RANGE_TYPES.d3.value,
        RANGE_TYPES.w1.value,
        RANGE_TYPES.m1.value,
        RANGE_TYPES.m3.value,
        RANGE_TYPES.m6.value,
        RANGE_TYPES.y1.value,
        RANGE_TYPES.y2.value,
        RANGE_TYPES.y5.value,
        RANGE_TYPES.y10.value,
        RANGE_TYPES.y15.value,
        RANGE_TYPES.y20.value,
      ];
    default:
      return [];
  }
};
