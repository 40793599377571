import {combineReducers} from 'redux';
import moment from 'moment';
import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {unionBy} from 'lodash';
import * as actions from '../actions';
import {anomalyIntervalsToAlertTriggerFormat, BUFFER_TIMES} from '../../services/investigationService';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const setDuration = (metric) => {
  const lastAnomalyInterval = metric.currentAnomalyIntervals.length ? metric.currentAnomalyIntervals.length - 1 : 0;
  const {startDate} = metric.currentAnomalyIntervals[0];
  const {endDate} = metric.currentAnomalyIntervals[lastAnomalyInterval];

  return endDate - startDate;
};

const mergeMetrics = (metrics, resolution) => {
  return metrics.map((metric) => ({
    ...metric,
    duration: setDuration(metric),
    deltaPercentage: metric.lowerPercentageDelta ? metric.lowerPercentageDelta * -1 : metric.upperPercentageDelta,
    bufferedStartTime: metric.currentAnomalyIntervals[0].startDate - (BUFFER_TIMES.anomalies[resolution] || 0),
    bufferedEndTime: Math.min(
      metric.currentAnomalyIntervals[0].endDate + (BUFFER_TIMES.anomalies[resolution] || 0),
      Math.floor(moment.now() / 1000),
    ),
    intervals: anomalyIntervalsToAlertTriggerFormat(
      metric.otherAnomalyIntervals.concat(metric.currentAnomalyIntervals),
    ),
  }));
};

const groupTokens = (payload) => {
  const dimMap = {};
  const metricsCounter = {};

  payload.forEach((i) => {
    i.tokens.forEach((tk) => {
      const item = {
        id: `${tk.key}-${tk.value}`,
        key: tk.key,
        value: tk.value,
        weight: tk.weight,
        anomalyOccurrences: tk.anomalyOccurrences,
      };

      if (!dimMap[i.value]) {
        dimMap[i.value] = {};
        metricsCounter[i.value] = {
          anomalyOccurrences: i.anomalyOccurrences,
        };
      }

      if (dimMap[i.value][tk.key]) {
        dimMap[i.value][tk.key].data.push(item);
        dimMap[i.value][tk.key].totalValues += 1;
      } else {
        dimMap[i.value][tk.key] = {
          data: [item],
          key: tk.key,
          totalValues: 1,
        };
      }
    });
  });
  return {dimMap, metricsCounter};
};

const fetchTriggeredAlert = composeReducers(
  makeAsyncReducer(actions.fetchTriggeredAlertApi, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type}) => {
    switch (type) {
      case actions.resetFetchTriggeredAlertApi.TYPE: {
        return EMPTY_OBJECT;
      }
      default:
        return state;
    }
  },
);

const feedbackRequest = composeReducers(
  makeAsyncReducer(actions.setFeedbackRequest, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const fetchTimeline = composeReducers(
  makeAsyncReducer(actions.fetchTimeLine, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload}) => {
    switch (type) {
      case actions.setIsOpen.TYPE:
        if (!payload) {
          return {...state, isLoading: undefined};
        }
        return state;
      default:
        return state;
    }
  },
);

const postComment = composeReducers(
  makeAsyncReducer(actions.sendCommentNewAlertConsole, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const fetchAnomaliesTokenMap = composeReducers((state = {}, {type, payload, meta}) => {
  switch (type) {
    case actions.resetFetchAnomaliesTokenMapApi.TYPE: {
      return EMPTY_OBJECT;
    }

    case actions.fetchAnomaliesTokenMapApiNewAlertConsole.TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.fetchAnomaliesTokenMapApiNewAlertConsole.success.TYPE: {
      const {dimMap, metricsCounter} = groupTokens(payload);

      return {
        ...state,
        isLoading: false,
        data: {
          dimMap,
          metricsCounter,
          totalMetrics: meta.isFiltered ? state.data?.totalMetrics : metricsCounter,
        },
      };
    }

    case actions.fetchAnomaliesTokenMapApiNewAlertConsole.failure.TYPE: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    default:
      return state;
  }
});

const fetchAnomaliesTokenMapCorrelations = composeReducers((state = EMPTY_OBJECT, {type, payload, meta}) => {
  switch (type) {
    case actions.resetFetchAnomaliesTokenMapApiCorrelations.TYPE: {
      return EMPTY_OBJECT;
    }

    case actions.fetchAnomaliesTokenMapApiCorrelations.TYPE: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.fetchAnomaliesTokenMapApiCorrelations.success.TYPE: {
      const {dimMap, metricsCounter} = groupTokens(payload);

      return {
        ...state,
        isLoading: false,
        data: {
          dimMap,
          metricsCounter,
          totalMetrics: meta.isFiltered ? state.data.totalMetrics : metricsCounter,
        },
      };
    }

    case actions.fetchAnomaliesTokenMapApiCorrelations.failure.TYPE: {
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    }

    default:
      return state;
  }
});

const fetchAlertMetrics = composeReducers((state = {}, {type, payload, meta}) => {
  if (!meta) {
    return state;
  }

  switch (type) {
    case actions.resetFetchAlertMetrics.TYPE: {
      return EMPTY_OBJECT;
    }

    case actions.fetchAlertMetrics.TYPE: {
      if (meta.isMore) {
        return {
          ...state,
          [meta.what]: {
            ...state[meta.what],
            isLoadingMore: true,
          },
        };
      }
      return {
        ...state,
        [meta.what]: {
          ...state[meta.what],
          isLoading: true,
        },
      };
    }

    case actions.fetchAlertMetrics.success.TYPE: {
      if (meta.isMore) {
        return {
          ...state,
          [meta.what]: {
            ...state[meta.what],
            isLoadingMore: false,
            data: {
              ...payload,
              metrics: mergeMetrics([...state[meta.what].data.metrics, ...payload.metrics], payload.resolution),
            },
          },
        };
      }
      return {
        ...state,
        [meta.what]: {
          ...state[meta.what],
          isLoading: false,
          data: {
            ...payload,
            metrics: mergeMetrics(payload.metrics, payload.resolution),
          },
        },
      };
    }

    case actions.fetchAlertMetrics.failure.TYPE: {
      if (meta.isMore) {
        return {
          ...state,
          [meta.what]: {
            ...state[meta.what],
            isLoadingMore: false,
            error: payload,
          },
        };
      }
      return {
        ...state,
        [meta.what]: {
          ...state[meta.what],
          isLoading: false,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
});

const fetchAllMetrics = composeReducers((state = {}, {type, payload, meta}) => {
  if (!meta) {
    return state;
  }

  switch (type) {
    case actions.resetFetchAllMetrics.TYPE: {
      return EMPTY_OBJECT;
    }

    case actions.fetchAllMetrics.TYPE: {
      if (meta.isMore) {
        return {
          ...state,
          [meta.what]: {
            ...state[meta.what],
            isLoadingMore: true,
          },
        };
      }
      return {
        ...state,
        [meta.what]: {
          ...state[meta.what],
          isLoading: true,
        },
      };
    }

    case actions.fetchAllMetrics.success.TYPE: {
      if (meta.isMore) {
        // union two arrays of metrics, no duplicates.
        const tmpMetrics = unionBy(state[meta.what].data.metrics, payload.metrics, 'id');
        return {
          ...state,
          [meta.what]: {
            ...state[meta.what],
            isLoadingMore: false,
            data: {
              ...payload,
              metrics: mergeMetrics(tmpMetrics, payload.resolution),
            },
          },
        };
      }
      return {
        ...state,
        [meta.what]: {
          ...state[meta.what],
          isLoading: false,
          data: {
            ...payload,
            metrics: mergeMetrics(payload.metrics, payload.resolution),
          },
        },
      };
    }

    case actions.fetchAllMetrics.failure.TYPE: {
      if (meta.isMore) {
        return {
          ...state,
          [meta.what]: {
            ...state[meta.what],
            isLoadingMore: false,
            error: payload,
          },
        };
      }
      return {
        ...state,
        [meta.what]: {
          ...state[meta.what],
          isLoading: false,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
});

const metricList = composeReducers((state = {}, {type, payload, meta}) => {
  if (!meta) {
    return state;
  }

  switch (type) {
    case actions.fetchAlertMetricDataPoints.TYPE: {
      return {
        ...state,
        [meta.key]: {
          ...state[meta.key],
          isLoading: true,
          startDate: payload.startDate,
          endDate: payload.endDate,
          seriesProperties: {byTreeExp: []},
        },
      };
    }

    case actions.fetchAlertMetricDataPoints.success.TYPE: {
      return {
        ...state,
        [meta.key]: {
          ...state[meta.key],
          isLoading: false,
          data: payload,
        },
      };
    }

    case actions.fetchAlertMetricDataPoints.failure.TYPE: {
      return {
        ...state,
        [meta.key]: {
          ...state[meta.key],
          isLoading: false,
          error: payload,
        },
      };
    }

    default:
      return state;
  }
});

export default combineReducers({
  fetchTriggeredAlert,
  fetchAnomaliesTokenMap,
  fetchAnomaliesTokenMapCorrelations,
  fetchAlertMetrics,
  fetchAllMetrics,
  feedbackRequest,
  metricList,
  fetchTimeline,
  postComment,
});
