import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import usePostFileUploadAnalyze from 'dataCollectors/api/usePostFileUploadAnalyze';
import InfoBox from 'dataCollectors/components/infoBox/InfoBox';
import PreviewTable from 'dataCollectors/components/previewTable/PreviewTable';
import useSectionsClasses from 'dataCollectors/styles/classes.useSectionsClasses';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import Tooltip from 'common/componentsV2/Tooltip';

import {isEmpty} from 'lodash';
import FileFormatSettings from './FileFormatSettings';

const {
  TYPE,
  SOURCE_ID,
  FILE_FORMAT,
  START_TIME_DEFINITION,
  END_TIME_DEFINITION,
  EVENT_END_TIME,
  EVENT_START_TIME,
} = EVENT_STREAM_FIELDS;
const {sections} = useSectionsClasses();

const useStyles = makeStyles((theme) => ({
  previewWrapper: {
    position: 'relative',
  },
  previewLink: {
    ...theme.typography.subtitle3,
    color: theme.palette.blue['500'],
    cursor: 'pointer',
  },
  previewLinkDisabled: {
    ...theme.typography.subtitle3,
    pointerEvents: 'none',
    cursor: 'default',
    color: theme.palette.blue['300'],
  },
  loaderWrapper: {
    position: 'absolute',
    marginTop: '5px',
  },
  tableContainer: {
    overflow: 'auto',
    width: '100%',
  },
  fileHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  settings: {
    color: '#8995A0',
  },
  smallText: {
    color: '#8995A0',
    fontSize: '10px',
  },
}));

function FileUpload({info, leftSideWidth}) {
  const [refetchTablePreview, setRefetchTablePreview] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const sectionsClasses = sections({leftSideWidth});
  const {
    getValues,
    setValue,
    formState: {dirtyFields},
  } = useFormContext();
  const stream = getValues();
  const classes = useStyles();

  const {analyzeData, refetchMetadataFetching} = usePostFileUploadAnalyze(
    stream[TYPE.id],
    stream[SOURCE_ID.id],
    stream[FILE_FORMAT.id],
  );

  useEffect(() => {
    if (!isSettingsModalOpen) {
      refetchMetadataFetching();
    }
  }, [isSettingsModalOpen]);

  useEffect(() => {
    // update form with fetched information from analyze api - only when data changed
    if (!isEmpty(analyzeData)) {
      if (!dirtyFields[FILE_FORMAT.id]) setValue(FILE_FORMAT.id, analyzeData?.fileFormat || {});

      if (!dirtyFields[START_TIME_DEFINITION.id]) {
        const startDate =
          analyzeData[START_TIME_DEFINITION.id] &&
          analyzeData.fileSchema.find((s) => s.name.toLowerCase() === EVENT_START_TIME.name.toLowerCase());
        if (
          startDate &&
          startDate.timeDefinition?.timeColumnIdx !== analyzeData[START_TIME_DEFINITION.id].timeColumnIdx
        ) {
          const startTimeDefenition =
            stream[START_TIME_DEFINITION.id].timeColumnIdx === startDate.timeDefinition.timeColumnIdx
              ? stream[START_TIME_DEFINITION.id]
              : analyzeData?.startTimeDefinition;
          setValue(START_TIME_DEFINITION.id, startTimeDefenition);
        } else setValue(START_TIME_DEFINITION.id, analyzeData?.startTimeDefinition || stream[START_TIME_DEFINITION.id]);
      }

      if (!dirtyFields[END_TIME_DEFINITION.id]) {
        const endDate = analyzeData?.fileSchema?.find(
          (s) => s.name.toLowerCase() === EVENT_END_TIME.name.toLowerCase(),
        );
        if (endDate && endDate.timeDefinition.timeColumnIdx !== analyzeData[END_TIME_DEFINITION.id].timeColumnIdx) {
          const endTimeDefinition =
            stream[END_TIME_DEFINITION.id].timeColumnIdx === endDate.timeDefinition.timeColumnIdx
              ? stream[END_TIME_DEFINITION.id]
              : analyzeData?.endTimeDefinition;
          setValue(END_TIME_DEFINITION.id, endTimeDefinition);
        } else setValue(END_TIME_DEFINITION.id, analyzeData?.endTimeDefinition || stream[END_TIME_DEFINITION.id]);
      }
      setRefetchTablePreview(!refetchTablePreview);
    }
  }, [analyzeData, dirtyFields]);

  return (
    <div className={sectionsClasses.root}>
      <div className={sectionsClasses.container}>
        <div className={sectionsClasses.left}>
          <div className={classes.fileHeaderWrapper}>
            <div className={sectionsClasses.title}>Upload file</div>
            <Tooltip content="File format settings" placement="bottom">
              <span onClick={() => setIsSettingsModalOpen(true)} className={classes.previewLink}>
                <div display="flex" alignItems="center" borderRadius={6} className={classes.settings}>
                  <i className="icon icn-nav16-settings" />
                </div>
              </span>
            </Tooltip>
          </div>
          <div className={sectionsClasses.subTitle}>{analyzeData?.columnsCount} columns</div>
          <div className={sectionsClasses.fileWrapper}>
            <span>{analyzeData?.fileName}</span>
          </div>
          <PreviewTable
            leftSideWidth={leftSideWidth}
            reftechPreviewTable={refetchTablePreview}
            fileFormat={analyzeData?.fileFormat}
          />
        </div>

        <Modal
          id="fileFormatSettings"
          isOpen={isSettingsModalOpen}
          onClose={() => setIsSettingsModalOpen(false)}
          size={SIZES.MEDIUM}
          isStatic={false}
          height={390}
        >
          <FileFormatSettings closeModal={() => setIsSettingsModalOpen(false)} />
        </Modal>
        {info && <InfoBox title={info.title} body={info.body} />}
      </div>
    </div>
  );
}

FileUpload.defaultProps = {
  info: null,
  leftSideWidth: '100%',
};

FileUpload.propTypes = {
  info: PropTypes.objectOf(PropTypes.string),
  leftSideWidth: PropTypes.number,
};

export default FileUpload;
