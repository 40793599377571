// @flow
import React from 'react';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import {COMPOSITE_COLUMNS} from 'composites/services/compositesService';

import './CompositeItemHeader.module.scss';

type PropsType = {
  offset: Number,
  order: Object,
  setCompositeListOrder: Function,
};

const fields = [
  {id: 0, name: 'title', title: 'Title', sortField: COMPOSITE_COLUMNS.TITLE},
  {id: 1, name: 'origin', title: 'Type', sortField: COMPOSITE_COLUMNS.TYPE},
  {id: 2, name: 'status', title: 'Errors', sortField: COMPOSITE_COLUMNS.ERROR},
  {id: 3, name: 'owner', title: 'Owner', sortField: COMPOSITE_COLUMNS.OWNER},
  {id: 4, name: 'dateCreated', title: 'Date Created', sortField: COMPOSITE_COLUMNS.DATECREATE},
  {
    id: 5,
    name: 'metricsUsage',
    title: 'Metric Usage',
    sortField: COMPOSITE_COLUMNS.METRICS_USAGE,
    // tooltip: 'Triggered in the last 7 days',
  },
  {
    id: 6,
    name: 'usedInAlerts',
    title: 'Alert Usage',
    sortField: COMPOSITE_COLUMNS.ALERT_USAGE,
    // tooltip: 'Triggered in the last 7 days',
  },
];

const CompositesTableHeader = React.memo(({offset, setCompositeListOrder, order}: PropsType) => {
  const handleTitleOrderChange = (columnName) => {
    const {column, direction} = order;
    let updatedDirection = 'desc';
    const sortColumn = fields.find((f) => f.name === columnName);
    if (sortColumn.sortField === column) {
      updatedDirection = direction === 'desc' ? 'asc' : 'desc';
    }
    setCompositeListOrder({column: sortColumn.sortField, direction: updatedDirection});
  };

  return (
    <div styleName="container list-header" style={{marginRight: offset}}>
      {fields.map((field) => (
        <Tooltip key={`field-${field.id}`} content={field.tooltip} hideIfEqualTo={null} type={TYPES.SMALL}>
          <div
            styleName={['header-field', field.name, order.column === field.sortField ? 'ordered-by' : null].join(' ')}
          >
            <TitleHeaderTableOrder
              onChange={handleTitleOrderChange}
              title={field.title}
              selectedDirection={order.direction}
              columnName={field.name}
              isSortable
              isActive={field.sortField === order.column}
            />
          </div>
        </Tooltip>
      ))}
    </div>
  );
});

export default CompositesTableHeader;
