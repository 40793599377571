// @flow

import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import {Box, Grid} from '@material-ui/core';
import {TypographyBox} from 'common/componentsV2/boxTools';
import * as selectors from 'metrics/store/selectors';

import Sort from './components/Sort';
import Show from './components/Show';

const MultiPreviewOptions = ({value, onChange}: {value: Object, onChange: Function}) => {
  const [isOpen, setOpen] = useState(false);

  const expressionTrees = useSelector(selectors.getExpressionTrees);

  const handleToggle = () => {
    setOpen(!isOpen);
  };

  const renderPopoverContent = () => (
    <div>
      {expressionTrees.map(({id}) => (
        <Box key={id} display="flex" className="idle-external-click" p={2}>
          <Grid item>
            <Box display="flex" flexDirection="column" mr={3}>
              <TypographyBox variant="subtitle2" mb={1}>
                Show
              </TypographyBox>
              <Show value={value[id].show} onChange={(val) => onChange({...value, [id]: {...value[id], show: val}})} />
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" flexDirection="column">
              <TypographyBox mr={0.5} variant="subtitle2" mb={1}>
                Sort by
              </TypographyBox>
              <Sort value={value[id].sort} onChange={(val) => onChange({...value, [id]: {...value[id], sort: val}})} />
            </Box>
          </Grid>
        </Box>
      ))}
    </div>
  );

  return (
    <div>
      <FormDdl
        popoverComponent={renderPopoverContent()}
        buttonComponent={<Box position="relative" height={41} css={{zIndex: 1}} className="idle-external-click" />}
        onToggle={handleToggle}
        width={269}
        buttonWidth={269}
        isOpen={isOpen}
        position="right"
        exceptionsClickOutside={['andt-dropdown-menu-list-virtualize']}
      />
    </div>
  );
};

export default MultiPreviewOptions;
