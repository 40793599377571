// @flow
import React, {Fragment} from 'react';
import './EditCustomerForm.module.scss';
import {Field} from 'react-final-form';
import Input from 'common/componentsV2/Input';

const EditCustomerFormClientAdmin = () => {
  return (
    <Fragment>
      <div styleName="title">Admin</div>
      <div styleName="split-row">
        <Field name="admin.firstName">
          {({input}) => (
            <div styleName="split-field">
              <div styleName="label">First Name</div>
              <Input
                name={input.name}
                value={input.value}
                onChange={input.onChange}
                placeHolder="Required"
                automationId="firstName"
              />
            </div>
          )}
        </Field>
        <Field name="admin.lastName">
          {({input}) => (
            <div styleName="split-field">
              <div styleName="label">Last Name</div>
              <Input
                name={input.name}
                value={input.value}
                onChange={input.onChange}
                placeHolder="Required"
                automationId="lastName"
              />
            </div>
          )}
        </Field>
      </div>
      <div styleName="row">
        <div styleName="label">Email</div>
        <Field name="admin.email">
          {({input, meta}) => (
            <Input
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              placeHolder="Required"
              isInvalid={meta.error && meta.error !== 'Required'}
              invalidMessage={meta.error}
              automationId="email"
            />
          )}
        </Field>
      </div>
      <div styleName="row">
        <div styleName="label">Confirm Email</div>
        <Field name="admin.confirmEmail">
          {({input, meta}) => (
            <Input
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              placeHolder="Required"
              isInvalid={meta.error && meta.error !== 'Required'}
              invalidMessage={meta.error}
              automationId="confirmEmail"
            />
          )}
        </Field>
        {/* br is to have extra space in the screen in case last field validation is become visible */}
        <br />
      </div>
    </Fragment>
  );
};
export default EditCustomerFormClientAdmin;
