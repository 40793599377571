import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import Button, {COLORS} from 'common/componentsV2/Button';
import {palette} from 'app/styles/theme';
import {getProfileId, getTriageFullScreenEnabled, isReadOnlyUser} from 'profile/store/selectors';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import {calculateAcknowledgedUsers} from 'alerts.console.new/services/alertsConsoleService';
import {segmentClickEvent} from 'common/store/actions';
import {setAlertTriggerAcknowledgement} from 'alerts.console.new/api/api';
import {queryErrorHandler} from 'reactQuery/queryClient';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import fetchGroupAlertsWithMetricsProvider from 'investigationNewAlertConsole/api/fetchGroupAlertsWithMetricsProvider';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import fetchTimelineProvider from 'investigationNewAlertConsole/api/fetchTimelineProvider';

const useActionBarStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  iconBtn: {
    height: '36px',
    width: '36px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  displayAck: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    borderRadius: '6px',
    paddingRight: '10px',
    paddingLeft: '10px',
    marginRight: '12px',
    backgroundColor: palette.blue['300'],
    color: palette.blue['500'],
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 500,
  },
  displayAckIcon: {
    marginRight: '4px',
  },
  acksTooltip: {
    whiteSpace: 'pre-line',
  },
  ackButton: {
    fontSize: 18,
    color: palette.gray['400'],
    '&:hover': {
      cursor: 'pointer',
    },
  },
  ackedBy: {
    color: palette.blue['500'],
  },
}));

const Acknowledged = ({queryParams}: {queryParams: Object}) => {
  const classes = useActionBarStyles();

  const meId = useSelector(getProfileId);
  const isReadOnly = useSelector(isReadOnlyUser);

  const isTriageFullscreenEnabled = useSelector(getTriageFullScreenEnabled);

  const triggeredAlertGroupProvider = fetchGroupAlertsWithMetricsProvider(queryParams);
  const trigger = triggeredAlertGroupProvider?.useQueryGetTriggeredAlert()?.data;

  const triggeredAlertsProvider = fetchTriggeredAlertsProvider();

  const triggeredAlertTimeline = fetchTimelineProvider(trigger?.alertTriggerId);

  const users = fetchUsersProvider().useQuery();
  const meUser = users?.data?.find((user) => user.id === meId);

  const isAcknowledgedByMe = useMemo(() => {
    let isAckByMe = false;
    if (trigger?.acks?.userIds?.length) {
      const searchedMeUser = trigger.acks.userIds.find((user) => user.id === meId);
      if (searchedMeUser) {
        isAckByMe = true;
      }
    }
    return isAckByMe;
  }, [JSON.stringify(trigger?.acks)]);

  const ackNamesTooltip = useMemo(() => {
    let namesList = '';
    if (trigger?.acks?.userIds?.length || trigger?.acks?.unregisteredUserNames?.length) {
      namesList = calculateAcknowledgedUsers(trigger.acks);
    }
    return namesList;
  }, [JSON.stringify(trigger?.acks)]);

  const ackNamesDisplay = useMemo(() => {
    let ackDisplay = '';
    if (trigger?.acks?.userIds?.length || trigger?.acks?.unregisteredUserNames?.length) {
      const acksCount = (trigger?.acks?.userIds?.length || 0) + (trigger?.acks?.unregisteredUserNames?.length || 0);
      if (isAcknowledgedByMe) {
        ackDisplay = meUser.shortName;
      } else if (trigger?.acks?.userIds?.length > 0) {
        ackDisplay = trigger?.acks?.userIds[0].shortName;
      } else if (trigger?.acks?.unregisteredUserNames?.length > 0) {
        ackDisplay = trigger?.acks?.unregisteredUserNames[0];
      }
      if (acksCount > 0) {
        ackDisplay = acksCount === 1 ? ackDisplay.concat('.') : ackDisplay.concat(`, +${acksCount - 1}`);
      }
    }
    return ackDisplay;
  }, [JSON.stringify(trigger?.acks), isAcknowledgedByMe, meUser]);

  const acknowledgeClicked = async () => {
    if (!isReadOnly) {
      segmentClickEvent({
        type: 'click',
        name: 'acknowledge alert',
      });
      try {
        const queryCacheParams = {
          alertTriggerId: trigger?.alertTriggerId,
          isAcknowledged: !isAcknowledgedByMe,
          meUser,
        };
        triggeredAlertGroupProvider.syncAcksInCache(queryCacheParams);

        const payload = {
          isAcknowledged: !isAcknowledgedByMe,
          meId,
          alertTriggerId: trigger?.alertTriggerId,
        };
        await setAlertTriggerAcknowledgement(payload).promise;
        await triggeredAlertsProvider.invalidate(QUERY_KEYS.triggeredAlerts);
        await triggeredAlertTimeline?.invalidate();
      } catch (e) {
        const error = {
          title: 'Acknowledgement error',
          description: `Error while ${!isAcknowledgedByMe ? 'setting' : 'removing'} acknowledgement`,
        };
        queryErrorHandler(error);
        // In case of exception, need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
        await triggeredAlertGroupProvider.invalidate(QUERY_KEYS.triggeredAlertGroupMetrics);
      }
    }
  };

  return (
    <div className={classes.container}>
      {!isTriageFullscreenEnabled && ackNamesDisplay && (
        <Tooltip content={ackNamesTooltip} type={TYPES.SMALL} placement="bottom">
          <div className={classes.displayAck}>
            <i className={`icon icn-general16-checkmark ${classes.displayAckIcon}`} />
            {ackNamesDisplay}
          </div>
        </Tooltip>
      )}
      <Tooltip
        content={trigger?.acks?.userIds?.length ? `Acknowledged by: ${ackNamesTooltip}` : 'Acknowledge this alert'}
        type={TYPES.SMALL}
        placement="bottom"
      >
        {isTriageFullscreenEnabled ? (
          <div
            onClick={acknowledgeClicked}
            className={`icon icn-action16-acknowledge ${classes.ackButton} ${
              trigger?.acks?.userIds?.length ? classes.ackedBy : ''
            }`}
          />
        ) : (
          <span>
            <Button
              colorSchema={isAcknowledgedByMe ? COLORS.BLUE_500 : COLORS.GHOST_BLUE}
              icon="icn-action16-acknowledge"
              extraClassName={classes.iconBtn}
              onClick={acknowledgeClicked}
            />
          </span>
        )}
      </Tooltip>
    </div>
  );
};

export default Acknowledged;
