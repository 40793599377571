import React from 'react';
import {makeStyles} from '@material-ui/core';
import {useField} from 'react-final-form';
import RadioButton from 'common/componentsV2/RadioButton';
import {OPTIONS_VALUE} from 'common/componentsV2/customDateRangeV2/customDateRangeService';
import CalendarRange from 'common/componentsV2/customDateRangeV2/CalendarRange';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 20,
    minHeight: 215,
    maxHeight: 215,
  },
  option: {
    paddingBottom: '9px',
  },
  optionText: {
    color: theme.palette.gray[400],
  },
  timeFrameOptions: {
    padding: '20px 0px 4px 0px',
    display: 'flex',
  },
  disabled: {
    opacity: 0.2,
  },
}));

const RangeTimeFrame = () => {
  const classes = useStyles();

  const {
    input: {value: rangeTypeValue, onChange: onChangeRangeType},
  } = useField('rangeType');
  const {
    input: {value: dateRangeValue, onChange: onChangeDateRange},
    meta: {error: dateRangeValidationErrors},
  } = useField('dateRange');

  const isSelected = rangeTypeValue === OPTIONS_VALUE.RANGE;
  return (
    <div className={classes.wrapper}>
      <div className={classes.option}>
        <RadioButton checked={isSelected} label="Range" onClick={() => onChangeRangeType(OPTIONS_VALUE.RANGE)} large />
      </div>
      <span className={classes.optionText}>Select fixed dates for your time range.</span>
      <div
        className={`${classes.timeFrameOptions} ${!isSelected && classes.disabled}`}
        style={{
          pointerEvents: !isSelected ? 'none' : 'auto',
        }}
      >
        <CalendarRange
          value={dateRangeValue}
          errors={dateRangeValidationErrors}
          onChange={onChangeDateRange}
          disabled={!isSelected}
        />
      </div>
    </div>
  );
};

export default RangeTimeFrame;
