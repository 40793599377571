// @flow
import React from 'react';
import Gauge from 'common/componentsV2/gauges/Gauge';
import fetchConfigurationAlertsTotalProvider from 'alerts.management/api/fetchConfigurationAlertsTotalProvider';

const AlertsConfigurationGauge = ({
  selectedItem,
  index,
  description,
}: {
  selectedItem: Number,
  index: Number,
  description: String,
}) => {
  const alertConfigurationTotalProvider = fetchConfigurationAlertsTotalProvider();
  const alertConfigurationResult = alertConfigurationTotalProvider?.useQuery();

  return alertConfigurationResult ? (
    <Gauge
      description={description}
      value={alertConfigurationResult?.data?.total}
      isLoading={alertConfigurationResult?.isLoading}
      selectedItem={selectedItem}
      index={index}
    />
  ) : null;
};

export default AlertsConfigurationGauge;
