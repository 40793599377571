// @flow
import React from 'react';
import {useField} from 'react-final-form';
import TimeZone from 'common/componentsV2/TimeZone';
import useUserSettingsStyles from 'userSettings/pages/useUserSettings.styles';
import SubTitle from '../SubTitle';

export const timeFormats = [{label: '24 Hours', value: '24H', id: '24h'}, {label: 'AM/PM', value: 'APMP', id: 'AMPM'}];

const TimeZoneSettings = () => {
  const userSettingsClasses = useUserSettingsStyles();
  const {
    input: {value: timeZoneValue, onChange: onTimeZoneChange},
  } = useField('timeZoneName');

  return (
    <>
      <SubTitle title="Settings" />
      <div className={userSettingsClasses.detailsSection}>
        <div className={userSettingsClasses.detailsField}>
          <div className={userSettingsClasses.title}>Time Zone</div>
          <TimeZone value={timeZoneValue} onChange={onTimeZoneChange} />
        </div>
      </div>
    </>
  );
};

export default TimeZoneSettings;
