// @flow
import React from 'react';
import {makeStyles} from '@material-ui/core';
import {palette} from 'app/styles/theme';

export const SIZE = {
  SMALL: {
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 500,
    padding: '4px',
    height: '22px',
  },
  MEDIUM: {
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 500,
    padding: '5px 8px',
    height: '26px',
  },
};

const SEVERITY_TYPES = [
  {
    label: 'Info',
    value: 'info',
    color: palette.indigo[500],
    bgcolor: palette.opaqueColors.indigo,
  },
  {
    label: 'Low',
    value: 'low',
    color: palette.green[500],
    bgcolor: palette.opaqueColors.green,
  },
  {
    label: 'Medium',
    value: 'medium',
    color: palette.mango[400],
    bgcolor: palette.opaqueColors.mango,
  },
  {
    label: 'High',
    value: 'high',
    color: palette.orange[500],
    bgcolor: palette.opaqueColors.orange,
  },
  {
    label: 'Critical',
    value: 'critical',
    color: palette.red[500],
    bgcolor: palette.opaqueColors.red,
  },
];

const TRIGGER_TYPES = [
  {
    label: 'Anomaly',
    value: 'anomaly',
    color: palette.blue[500],
    bgcolor: palette.blue[200],
  },
  {
    label: 'Static',
    value: 'static',
    color: palette.blue[600],
    bgcolor: palette.blue[200],
  },
  {
    label: 'No Data',
    value: 'noData',
    color: palette.brorange[500],
    bgcolor: palette.blue[200],
  },
];

const useSeverityStyles = makeStyles(() => ({
  severity: {
    borderRadius: '4px',
    border: 0,
    display: 'inline-block',
    flexShrink: 0,
    backgroundColor: (props) => props.bgcolor,
    color: (props) => props.color,
    fontSize: (props) => props.size.fontSize,
    lineHeight: (props) => props.size.lineHeight,
    fontWeight: (props) => props.size.fontWeight,
    padding: (props) => props.size.padding,
    height: (props) => props.size.height,
  },
}));

export const Severity = ({level, size}: {level: String, size: Object}) => {
  const sevFound = SEVERITY_TYPES.find((sev) => sev.value === level) || null;
  if (!sevFound) {
    return null;
  }

  const classes = useSeverityStyles({...sevFound, size: size || SIZE.SMALL});

  return <div className={classes.severity}>{sevFound.label}</div>;
};

export const TriggerType = ({type, size}: {type: String, size: Object}) => {
  const triTypeFound = TRIGGER_TYPES.find((tri) => tri.value === type) || null;
  if (!triTypeFound) {
    return null;
  }

  const classes = useSeverityStyles({...triTypeFound, size: size || SIZE.SMALL});

  return <div className={classes.severity}>{triTypeFound.label}</div>;
};
