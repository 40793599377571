// @flow
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StringParam, useQueryParam} from 'use-query-params';
import {getMergedTags} from 'alerts.management/store/selectors';
import {getAlertsTags} from 'alerts.management/store/actions';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import {getLabelsEnabled} from 'profile/store/selectors';
import LabelFilter from 'admin.labels/LabelFilter';

const sortByName = (a, b) => {
  const nameA = a.value.toUpperCase();
  const nameB = b.value.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
};

const LabelsFilter = () => {
  const dispatch = useDispatch();
  const labels = useSelector(getMergedTags);
  const isLabelsEnabled = useSelector(getLabelsEnabled);
  const [selectedLabels, setSelectedLabels] = useQueryParam('labels', StringParam);
  const [sortedLabels, setSortedLabels] = useState([]);

  useEffect(() => {
    dispatch(getAlertsTags());
  }, []);

  useEffect(() => {
    setSortedLabels(labels.sort((a, b) => sortByName(a, b)));
  }, [labels]);

  const onChange = useCallback(
    (items) => {
      const currentValues = items.length ? items.map((item) => item.value || item.name).join(',') : undefined;
      setSelectedLabels(currentValues);
    },
    [setSelectedLabels],
  );

  const onClearAll = useCallback(() => {
    if (selectedLabels && selectedLabels.length > 0) {
      setSelectedLabels(undefined);
    } else {
      const optionCurrent = sortedLabels.map((i) => i.value).join(',');
      setSelectedLabels(optionCurrent);
    }
  }, [selectedLabels, setSelectedLabels, sortedLabels]);

  return isLabelsEnabled ? (
    <LabelFilter automationId="alertlabelsFilter" onChange={onChange} queryParamsName="labels" isFullWidthButton />
  ) : (
    <SelectAndt
      automationId="alertlabelsFilter"
      type={TYPE_NEW_MULTI}
      theme={THEME_HIGHLIGHTED}
      onChange={onChange}
      options={sortedLabels}
      value={sortedLabels.filter((item) => selectedLabels?.split(',')?.includes(item.value))}
      isMulti
      placeholder="Labels"
      optionHeight={40}
      menuWidth={280}
      isClearable={false}
      closeMenuOnSelect={false}
      getOptionLabel={(item) => item.label}
      getOptionValue={(item) => item.value}
      menuFooterComponent={
        <FormDdlActionBar
          isVisible
          selectedCount={selectedLabels && selectedLabels.length ? selectedLabels.length : 0}
          onClearAll={onClearAll}
        />
      }
    />
  );
};

export default LabelsFilter;
