import React, {memo} from 'react';
import {
  getCorrectTimezoneName,
  getFormattedHumanDuration,
  getFormattedMDH,
  getFormattedTime,
  getIsBetweenTime,
} from 'common/utils/dateService';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

import './IncidentTab.module.scss';

type PropsType = {
  label: string,
  startTime: number,
  timeZoneName: string,
  triggerTime: number,
};

const IncidentDateTimeProperties = ({label, startTime, timeZoneName, triggerTime}: PropsType) => {
  const isSameDay = getIsBetweenTime(startTime, undefined, 'day', timeZoneName);
  return (
    <>
      <div styleName="info">
        <div styleName="info-label">{label}</div>
        <Tooltip content={getCorrectTimezoneName(timeZoneName)} type={TYPES.SMALL}>
          <div styleName="info-value">
            {isSameDay ? getFormattedTime(startTime, timeZoneName) : getFormattedMDH(startTime, timeZoneName)} (
            {getCorrectTimezoneName(timeZoneName)})
          </div>
        </Tooltip>
      </div>
      <div styleName="info">
        <div styleName="info-label">Alert Sent After:</div>
        <div styleName="info-value">{getFormattedHumanDuration(Math.abs(triggerTime - startTime), false) || 0}</div>
      </div>
      <div styleName="info">
        <div styleName="info-label">Alert Opened:</div>
        <Tooltip content={getCorrectTimezoneName(timeZoneName)} type={TYPES.SMALL}>
          <div styleName="info-value">
            {isSameDay ? getFormattedTime(triggerTime, timeZoneName) : getFormattedMDH(triggerTime, timeZoneName)} (
            {getCorrectTimezoneName(timeZoneName)})
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default memo(IncidentDateTimeProperties);
