// @flow
import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {StringParam, useQueryParams} from 'use-query-params';
import PageLayout from 'common/componentsV2/PageLayout';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import FilterButton from 'common/componentsV2/FilterButton';
import * as actions from 'dashboards/store/actions';
import * as selectors from 'dashboards/store/selectors';
import DashboardsFilters from 'dashboards/components/DashboardsFilters';
import DashboardsMain from 'dashboards/components/DashboardsMain';
import DashboardNewButton from 'dashboards/components/DashboardNewButton';
import {DEFAULT_PARAMS, OPTION_TILES_VALUES, PARAMS_HEADERS} from 'dashboards/services/dashboardService';
import {keyBy, omitBy} from 'lodash';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {getUsersGroupsListsWithoutDeleteGroup} from 'admin.users/store/selectors';
import Button, {COLORS} from 'common/componentsV2/Button';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

const useStyles = makeStyles(({palette}) => ({
  squareButton: {
    padding: '10px',
    height: 35,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: palette.gray[300],
    borderRadius: '6px',
    marginRight: '8px',
  },
  tooltipItemValue: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '20px',
    letterSpacing: 'normal',
    color: palette.white[500],
  },
}));
const DashboardsManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [queryParams, setQueryParams] = useQueryParams({
    q: StringParam,
    ownerUser: StringParam,
    tags: StringParam,
  });
  const isOpenFilters = useSelector(selectors.isOpenFilters);
  const totalCount = useSelector(selectors.getTotalDashboards);
  const dataDashboards = useSelector(selectors.getDataDashboards);
  const v1Duplicates = useMemo(() => keyBy(dataDashboards, 'v1ID'), [dataDashboards]);
  const duplicatesCount = dataDashboards.filter((item) => v1Duplicates[item._id]).length;
  const dataIsLoading = useSelector(selectors.getFetchDashboardsLoading);
  const users = useSelector(getUsersGroupsListsWithoutDeleteGroup);

  useEffect(() => {
    dispatch(actions.fetchDataDashboardsManagementPage());
  }, []);

  const onFilter = useCallback((val) => {
    setQueryParams({q: val === '' ? undefined : val});
  }, []);

  const toggleFilters = () => {
    dispatch(actions.updateFilterBtn(isOpenFilters));
  };

  const handleChange = ({value}, name) => {
    setQueryParams({[name]: value});
  };

  const changedFilter = useMemo(() => {
    // Extract none default filters
    const obj = omitBy(queryParams, (value, key) => value === DEFAULT_PARAMS[key]);
    // For each filter option create entry in the filters array
    return Object.keys(obj).map((item) => ({
      id: item, // query param name
      header: PARAMS_HEADERS[item], // Convert query param name to a user fondly header
      value:
        // If filter option is by user - convert ID to name
        item === 'ownerUser'
          ? users
              // Filter users and groups list by selected values
              .filter((user) => obj[item].split(',').includes(user._id))
              // Extract name of user or group
              .map((userFilter) => userFilter.label)
              // convert to comma seperated string
              .toString()
          : // For all other filter optioins, display value as is
            obj[item],
    }));
  }, [queryParams]);

  const importTooltip = <div className={classes.tooltipItemValue}>Import dashboard from file</div>;

  const header = (
    <div className="display_flex width_1 justifyContent_space-between">
      <div className="display_flex">
        <div className="mr_1-5">
          <Title type={TITLE_TYPES.PAGE_TITLE}>Dashboards</Title>
        </div>
        <div className="mr_1-5">
          <Title type={TITLE_TYPES.PAGE_LINE_COUNTER}>{`(${totalCount - duplicatesCount})`}</Title>
        </div>
        <FilterButton tooltipItems={changedFilter} onClick={toggleFilters} />
      </div>
      <div className="display_flex alignItems_center">
        <Tooltip content={importTooltip} type={TYPES.SMALL} placement="bottom" delay={100}>
          <span>
            <Button
              automationId="importDashboard"
              colorSchema={COLORS.GRAY_300}
              extraClassName={classes.squareButton}
              icon="icon icn-action16-upload"
              onClick={() => history.push(`/dashboards/${OPTION_TILES_VALUES.IMPORT}`)}
            />
          </span>
        </Tooltip>
        <DashboardNewButton />
      </div>
    </div>
  );
  return (
    <PageLayout header={header}>
      <div className="display_flex flexGrow_1">
        <DashboardsFilters
          dataQueryParams={queryParams}
          toggleFilters={toggleFilters}
          setQueryParams={setQueryParams}
          onFilter={onFilter}
          onChange={handleChange}
        />
        {dataIsLoading ? (
          <div className="display_flex alignItems_center justifyContent_center height_1vh width_1vw">
            <Spinner color="gray.500" size={SIZES.XX_BIG_100} />
          </div>
        ) : (
          <DashboardsMain isOpenFilters={isOpenFilters} dataQueryParams={queryParams} changedFilter={changedFilter} />
        )}
      </div>
    </PageLayout>
  );
};

export default React.memo(DashboardsManagement);
