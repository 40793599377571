// @flow
import React, {useEffect, useState} from 'react';
import {getDate, rangeTypes} from 'common/utils/dateRangeService';
import DateRangesDdl, {THEME} from 'common/components/dateTime/DateRangesDdl';
import {useSelector} from 'react-redux';
import {getTimeZoneName} from 'profile/store/selectors';
import {StringParam, useQueryParams} from 'use-query-params';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS} from 'alerts.console.new/services/alertsConsoleService';

const DateRangeFilter = ({defaultDateRange}: {defaultDateRange: string}) => {
  const timeZoneName = useSelector(getTimeZoneName);
  const [queryParams, setQueryParams] = useQueryParams({
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.CONST_RANGE]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.BREACH_TIME_FROM]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.BREACH_TIME_TO]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.RELATIVE_LAST]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.RELATIVE_NEXT]: StringParam,
  });
  const [dateRange, setDateRange] = useState({});
  useEffect(() => {
    setDateRange(
      getDate(
        {
          constRange: queryParams.constRange,
          startDate: queryParams.startTime,
          endDate: queryParams.endTime,
          relativeLast: queryParams.relativeLast,
          relativeNext: queryParams.relativeNext,
        },
        timeZoneName,
        rangeTypes[defaultDateRange],
      ),
    );
  }, [queryParams, timeZoneName, defaultDateRange]);

  const dateRangeChange = (dr) => {
    const isCustom = dr.constRange === rangeTypes.c.value;
    const isRelative = dr.constRange === rangeTypes.r.value;
    const times = {
      [ALERT_CONSOLE_QUERY_PARAMS_KEYS.BREACH_TIME_FROM]: isCustom ? dr.startDate : undefined, // null is OK, before api req will calc relative date
      [ALERT_CONSOLE_QUERY_PARAMS_KEYS.BREACH_TIME_TO]: isCustom ? dr.endDate : undefined,
      [ALERT_CONSOLE_QUERY_PARAMS_KEYS.RELATIVE_LAST]: isRelative ? dr.relativeLast : undefined,
      [ALERT_CONSOLE_QUERY_PARAMS_KEYS.RELATIVE_NEXT]:
        isRelative && String(dr.relativeNext) !== String(0) ? dr.relativeNext : undefined,
    };

    setQueryParams({[ALERT_CONSOLE_QUERY_PARAMS_KEYS.CONST_RANGE]: dr.constRange, ...times});
  };

  return (
    <DateRangesDdl
      onChange={dateRangeChange}
      dateRange={dateRange}
      theme={THEME.HIGHLIGHTED}
      allowedRangeTypes={['h1', 'h4', 'd1', 'd3', 'w1', 'w2', 'm1', 'm3', 'm6', 'y1']}
      allowedCustomDates={false}
      isUnix
    />
  );
};
export default React.memo(DateRangeFilter);
