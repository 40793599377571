import {combineEpics} from 'redux-observable';
import * as api from 'bc/services/api';
import {getDefaultAlertDefinition, alertConditionTypes} from 'alerts.management/services/alertsService';
import {getRootExpressionWithGroupByFunction, getEmptyFunction} from 'metrics/services/compositeService';
import {cleanupSpecialChars} from 'metrics/services/metricsService';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import * as bcSelectors from 'bc/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import {
  getEmptyExpression,
  getSearchObjectOriginExpression,
  getSearchObjectPropertyExpression,
} from 'common/utils/angularServices';
import {get} from 'lodash';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const generateAlertTitle = (metricCol, dimensionCols, stream) => {
  let title = `Anomaly on ${metricCol.name}`;

  if (dimensionCols.length) {
    title += ' by ';
    dimensionCols.forEach((col, index) => {
      title += `{{${cleanupSpecialChars(col.name)}}}`;
      title = index < dimensionCols.length - 1 ? `${title}, ` : title;
    });
  }
  title = `${title} from ${stream.name}`;

  return title;
};

const createSelectedAlertsAndDashboard = makeAsyncEpic(
  actions.createSelectedAlertsAndDashboard,
  api.createSelectedAlertsAndDashboard,
);
const createAlertFromStreamApi = makeAsyncEpic(actions.createAlertFromStreamApi, api.createAlertFromStreamApi);

const createAlertsFromStream = (action$, {getState}) =>
  action$.ofType(actions.createAlertsFromStream.TYPE).flatMap((action) => {
    const currentState = getState();
    const stream = bcSelectors.getSelectedDataStream(currentState);
    const streamSchemaMetricColumns = bcSelectors.getSelectedDataStreamSchemaColumnsMetrics(currentState);
    const streamSchemaDimensionColumns = bcSelectors.getSelectedDataStreamSchemaColumnsDimensions(currentState);
    const me = profileSelectors.getUserProfile(currentState);
    const newAlertDefinitions = [];

    action.payload.selectedItems.forEach((selectedItem) => {
      const metricCol = streamSchemaMetricColumns.find((col) => col.id === selectedItem.metricId);
      const dimnesionIds = get(selectedItem, 'dimensions', EMPTY_ARRAY);
      const dimensionCols = streamSchemaDimensionColumns.filter((col) => dimnesionIds.indexOf(col.id) !== -1);
      const node = getEmptyExpression();
      const volumeConEnable = profileSelectors.getAutoVolumeEnabled(getState());
      node.searchObject.expression.push(getSearchObjectPropertyExpression('what', cleanupSpecialChars(metricCol.name)));
      node.searchObject.expression.push(getSearchObjectOriginExpression('@Stream', stream.id, true));

      let compositeName;
      const aggregationValues = {
        counter: 'Sum',
        gauge: 'Avg',
      };
      const seriesFunction = {
        counter: 'sumSeries',
        gauge: 'averageSeries',
      };
      let groupByPropsValue = '{"properties":[';
      dimensionCols.forEach((dimCol, index) => {
        const comma = index === dimensionCols.length - 1 ? '' : ',';
        groupByPropsValue = `${groupByPropsValue}"${cleanupSpecialChars(dimCol.name)}"${comma}`;
      });
      groupByPropsValue += ']}';

      let root;
      if (dimensionCols.length) {
        root = getRootExpressionWithGroupByFunction(aggregationValues[metricCol.targetType], groupByPropsValue, node);
        compositeName = `what=${cleanupSpecialChars(metricCol.name)}.func=${aggregationValues[metricCol.targetType]}`;
      } else {
        root = getEmptyFunction();
        root.function = seriesFunction[metricCol.targetType];
        root.children = [node];
        compositeName = `what=${cleanupSpecialChars(metricCol.name)}.func=${seriesFunction[metricCol.targetType]}`;
      }

      const alertDefinition = getDefaultAlertDefinition(
        me,
        generateAlertTitle(metricCol, dimensionCols, stream),
        root,
        'stream',
      );
      alertDefinition.data.expressionTreeModel.name = {
        auto: false,
        prefix: compositeName,
      };

      const volumeCondition = alertDefinition.data.conditions.find(
        (con) => con.type === alertConditionTypes.volumeCondition,
      );

      if (volumeCondition) {
        volumeCondition.enabled = true;
        volumeCondition.enableAutoTuning = true;
        volumeCondition.numLastPoints = 1;
        volumeCondition.rollup = 'long';
        if (metricCol.targetType === 'gauge') {
          volumeCondition.enabled = false;
          volumeCondition.enableAutoTuning = false;
          volumeCondition.numLastPoints = null;
          volumeCondition.rollup = null;
        }
      }

      if (!volumeConEnable) {
        // Feature Flag
        alertDefinition.data.conditions = alertDefinition.data.conditions.filter(
          (con) => con.type !== alertConditionTypes.volumeCondition,
        );
      }

      newAlertDefinitions.push(alertDefinition);
    });

    return [actions.createAlertFromStreamApi({alertDefinitions: newAlertDefinitions, id: stream.id})];
  });

const alertsFromStreamEpic = combineEpics(
  createAlertFromStreamApi,
  createSelectedAlertsAndDashboard,
  createAlertsFromStream,
);
export default alertsFromStreamEpic;
