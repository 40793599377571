// @flow
import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';
import {getAlertEditorV2Enabled} from 'alerts.management/store/selectors';
import {segmentClickEvent} from 'common/store/actions';
import {
  getImpactEnabled,
  getInvestigationV2Enabled,
  getIsImpersonate,
  getStaticNoDataTriageEnabled,
} from 'profile/store/selectors';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {modalRouting as investigationRouting} from 'investigation/services/investigationService';
import {
  modalRouting1 as impactRouting1,
  modalRouting2 as impactRouting2,
} from 'impactNewAlertConsole/services/constants';
import fetchAlertsForImpactProvider from 'impactNewAlertConsole/api/fetchAlertForImpactProvider';
import {ALERT_ACTIONS} from 'alerts.console.new/services/alertsConsoleService';

import './MoreDdlActions.module.scss';

const MoreDdlActions = ({
  id,
  triggerId,
  anomalyId,
  alertConfigurationId,
  impact,
  isImpactEligible,
  timeScale,
}: {
  id: string,
  triggerId: string,
  anomalyId: string,
  alertConfigurationId: string,
  impact: Object,
  isImpactEligible: boolean,
  timeScale: string,
}) => {
  const dispatch = useDispatch();
  const isAlertEditorV2Enabled = useSelector(getAlertEditorV2Enabled);
  const isImpactEnabled = useSelector(getImpactEnabled);
  const isStaticNoDataTriageEnabled = useSelector(getStaticNoDataTriageEnabled);
  const isInvestigationV2Enabled = useSelector(getInvestigationV2Enabled);
  const isImpersonating = useSelector(getIsImpersonate);

  const ORIGIN_SOURCE_IDS_TYPE = 'STREAM';

  // Not calling the useQuery() here, since this will cause a lot of requests from the server for each displayed alert. Calling fetchQuery upon need.
  const alertForImpactProvider = fetchAlertsForImpactProvider();

  const [, setQueryParams] = useQueryParams({
    [investigationRouting.ANOMALY_ID]: StringParam,
    [investigationRouting.TRIGGER_ID]: StringParam,
    [investigationRouting.INVESTIGATION_MODAL]: BooleanParam,

    [impactRouting1.IMPACT_MODAL]: BooleanParam,
    [impactRouting1.ORIGIN_ID]: StringParam,
    [impactRouting1.ORIGIN_TYPE]: StringParam,
    [impactRouting1.ALERT_TRIGGER_ID]: StringParam,
    [impactRouting1.MEASURE]: StringParam,
    [impactRouting2.IMPACT_ID]: StringParam,
  });

  const [options, setOptions] = useState([]);

  const windowRef = useRef();

  const setImpactUrl = async () => {
    const alertForImpactResult = await alertForImpactProvider?.fetchQuery(triggerId);
    const alertMetrics = alertForImpactResult?.alerts[0]?.metrics;
    setQueryParams({
      [impactRouting1.IMPACT_MODAL]: true,
      [impactRouting1.ALERT_TRIGGER_ID]: triggerId,
      [impactRouting1.ORIGIN_ID]: Array.isArray(alertMetrics[0]?.originSourceIds)
        ? alertMetrics[0]?.originSourceIds[0]
        : undefined,
      [impactRouting1.ORIGIN_TYPE]: Array.isArray(alertMetrics[0]?.originSourceIds)
        ? ORIGIN_SOURCE_IDS_TYPE
        : undefined,
      [impactRouting1.MEASURE]: !impact ? alertMetrics[0]?.what : undefined,
      [impactRouting2.IMPACT_ID]: impact?.impactId || undefined,
    });
  };

  const setInvestigationUrl = () => {
    setQueryParams({
      [investigationRouting.ANOMALY_ID]: anomalyId || triggerId,
      [investigationRouting.TRIGGER_ID]: triggerId,
      [investigationRouting.INVESTIGATION_MODAL]: true,
    });
  };

  const actionItemSelected = async (event) => {
    if (event.value) {
      dispatch(
        segmentClickEvent({
          type: 'click',
          name: event.value,
        }),
      );

      let url;
      switch (event.value) {
        case 'tuneAlert':
          if (isAlertEditorV2Enabled) {
            window.open(`/#!/r/alert-manager/edit/${alertConfigurationId}/settings`, `_blank-${alertConfigurationId}`);
          } else {
            window.open(`/#!/alert/${alertConfigurationId}`, `_newtab-${alertConfigurationId}`);
          }
          break;
        case 'investigate':
          url = `/#!/anomalies?tabs=main;0&activeTab=1&anomalies=;0(${anomalyId})&alertId=;(${triggerId})&duration=;1(1)&durationScale=;minutes(minutes)&delta=;1(1)&deltaType=;percentage(percentage)&resolution=;${timeScale}(${timeScale})&score=;0(0)&state=;both(both)&direction=;both(both)&sort=;significance(significance)&q=;()&constRange=;1h(c)&startDate=;0(0)&endDate=;0(0)`;
          if (!windowRef.current) {
            windowRef.current = window.open(url, `_newtab`);
          }
          windowRef.current.location.assign(url);
          break;
        case 'setImpact':
          await setImpactUrl();
          break;
        case 'investigateV2':
          setInvestigationUrl(anomalyId, triggerId);
          break;
        default:
          break;
      }
    }
  };

  const getSelectOptions = () => {
    const ret = [];

    if (anomalyId) {
      ret.push(ALERT_ACTIONS.INVESTIGATE);
    }
    if (isStaticNoDataTriageEnabled || isInvestigationV2Enabled || isImpersonating) {
      ret.push(ALERT_ACTIONS.TRIAGE);
    }
    if (isImpactEnabled && isImpactEligible) {
      ret.push(ALERT_ACTIONS.SET_IMPACT);
    }

    ret.push(ALERT_ACTIONS.TUNE_ALERT);
    return ret;
  };

  useEffect(() => {
    setOptions(getSelectOptions());
  }, []);

  return (
    <div styleName="more-action-wrapper" role="presentation">
      <FormDdlSelect
        automationId={`more-actions-ddl-${id}`}
        className="alert-clickable-item"
        position="right"
        width={135}
        options={options}
        button={<div styleName="button-like-dropdown" className="icon icn-icon-3-dots alert-clickable-item" />}
        optionComponent={<OptionComponentSimple />}
        onChange={actionItemSelected}
        placement="auto"
      />
    </div>
  );
};

export default MoreDdlActions;
