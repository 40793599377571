// @flow
import React from 'react';
import {GenerateIcon, ICONS, SIZES} from '@pileus-cloud/anodot-frontend-common';

const RecommendationsEmptyState = ({
  recommendationsCount,
  isError = false,
}: {
  recommendationsCount: Number,
  isError: Boolean,
}) => {
  return (
    <div className="mt_5 display_flex flexDirection_column alignItems_center justifyContent_center flexGrow_1">
      <GenerateIcon iconName={ICONS.recommendations.name} size={SIZES.xxl} />
      {isError === true ? (
        <>
          <h3>Error</h3>
          <h5>Error while bringing recommendations</h5>
        </>
      ) : (
        <>{recommendationsCount === 0 ? <h5>No Recommendations Yet</h5> : null}</>
      )}
    </div>
  );
};

export default RecommendationsEmptyState;
