// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setKinesisIsExternalTimeUsed, setSelectedEditor} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import {getEditorValidationMessage} from 'bc/services/fuService';
import DimensionsAndMetrics from 'bc/components/streams/editor/common/DimensionsAndMetrics';
import {isKinesisDiametricsEditable} from 'bc/services/kinesisService';
import {get} from 'lodash';

type PropTypes = {
  // connect
  dataStream: Object,
  dimensions: Array,
  metrics: Array,
  timeDefinition: Array,
  useExternalTime: Boolean,
  isLoadingAnalysis: boolean,

  setSelectedEditor: Function,
  setKinesisIsExternalTimeUsed: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dimensions: selectors.getKinesisMetaDimensions(state),
    metrics: selectors.getKinesisMetaMetrics(state),
    timeDefinition: selectors.getKinesisTimeDefinition(state),
    useExternalTime: selectors.getKinesisIsExternalTimeUsed(state),
    isLoadingAnalysis: selectors.getKinesIsLoadingAnalysis(state),
  }),
  {
    setSelectedEditor,
    setKinesisIsExternalTimeUsed,
  },
)(
  class KinesisDimensionsAndMetrics extends React.PureComponent {
    props: PropTypes;

    setSelectedEditor = () => {
      this.props.setSelectedEditor({
        type: 'dm_kinesis',
        editorHeight: 'calc(100% - 210px)',
      });
    };

    onUseExternalTimeChanged = (value) => {
      this.props.setKinesisIsExternalTimeUsed(value);
    };

    render() {
      const {dataStream, dimensions, metrics, timeDefinition, useExternalTime, isLoadingAnalysis} = this.props;

      const isEditable =
        (isStreamEditable(dataStream.state) &&
          isKinesisDiametricsEditable(dataStream) &&
          get(dataStream, 'uiState.analysisResult.streamSchema', []).length > 0) ||
        (isStreamRunning(dataStream.state) &&
          isKinesisDiametricsEditable(dataStream) &&
          get(dataStream, 'uiState.analysisResult.streamSchema', []).length > 0);

      return (
        <DimensionsAndMetrics
          dataStream={dataStream}
          dimensions={dimensions}
          metrics={metrics}
          timeDefinition={timeDefinition}
          isLoading={isLoadingAnalysis}
          isEditable={isEditable}
          isVertical
          validatorFunction={getEditorValidationMessage}
          onSelectEditor={() => this.setSelectedEditor()}
          dimensionColumnName="Dimension Fields"
          measureColumnsName="Measure Fields"
          dateColumnsName="Timestamp Field"
          useExternalTime={useExternalTime}
          onUseExternalTimeChanged={this.onUseExternalTimeChanged}
        />
      );
    }
  },
);
