// @flow
import React, {useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {getUsersDataForAvatar} from 'admin.users/store/selectors';
import AvatarBadge, {COLORS} from 'common/componentsV2/AvatarBadge';
import {ReactComponent as UserIcon} from 'app/images/profile_empty.svg';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import './alertContent.module.scss';
import {deleteAssignee, setAssignee} from 'alerts.console/store/actions';
import {getAlertAssigneeEnabled, isCustomerAdmin as getIsCustomerAdmin, getUserProfile} from 'profile/store/selectors';
import {getAlertConfigurationsItems} from 'alerts.management/store/selectors';

const useStyles = makeStyles((theme) => ({
  assigneeWrapper: {
    display: 'flex',
    '&:hover': {
      '& $closeIcon': {
        opacity: 1,
      },
    },
  },
  closeIcon: {
    borderRadius: 1,
    fontSize: 12,
    opacity: 0,
  },
  userIcon: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.gray[400]}`,
    marginTop: 0,
    marginLeft: 0,
  },
  usersList: {
    position: 'absolute',
  },
}));

const AlertAssignee = ({
  userId,
  isDisplay,
  triggerId,
  alertConfigurationId,
}: {
  userId: string,
  isDisplay: boolean,
  triggerId: string,
  alertConfigurationId: string,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isCustomerAdmin = useSelector(getIsCustomerAdmin);
  const me = useSelector(getUserProfile);
  const isAlertAssigneeEnabled = useSelector(getAlertAssigneeEnabled);
  const usersData = useSelector(getUsersDataForAvatar);
  const userObj = usersData.find((u) => u.id === userId);
  const alertList = useSelector(getAlertConfigurationsItems);

  const alertAutoAssignmentGroup = useMemo(() => {
    return alertList.find((alert) => alert.data.id === alertConfigurationId)?.data?.autoAssignmentGroup;
  }, [alertList, alertConfigurationId]);

  const filteredUsersList = useMemo(() => {
    // if the alert has auto assigned group - allow assigning only users inside that group
    // if there is no auto assigned group - allow selecting all users
    return usersData
      .filter(
        (user) => !alertAutoAssignmentGroup || user.allGroups?.find((group) => group === alertAutoAssignmentGroup),
      )
      .map((user) => {
        return {label: user.name, value: user.id};
      });
  }, [usersData, alertAutoAssignmentGroup]);

  const isEditable = useMemo(() => {
    // Enable change assignee only for Admins or users from the same group
    return (
      isCustomerAdmin || !alertAutoAssignmentGroup || me.groups.find((group) => group === alertAutoAssignmentGroup)
    );
  }, [isCustomerAdmin, alertAutoAssignmentGroup]);

  const handleChange = useCallback((selectedAssignee) => {
    dispatch(setAssignee({triggerId, assignee: selectedAssignee.value}));
  }, []);

  const handleRemove = useCallback(() => {
    dispatch(deleteAssignee({triggerId}));
  }, []);

  const assigneeAvatar = userObj && (
    <div className={classes.assigneeWrapper}>
      <AvatarBadge
        text={userObj.abbr}
        tooltipText={userObj.name}
        colorSchema={userObj.defaultGroup ? userObj.defaultGroup.colorSchema : COLORS.GRAY}
      />
      {isEditable && (
        <div className={classes.closeIcon}>
          <i className="icon icn-general16-closea alert-clickable-item" role="presentation" onClick={handleRemove} />
        </div>
      )}
    </div>
  );
  const emptyAvatar = isDisplay && isEditable && (
    <i className={classes.userIcon}>
      <UserIcon width={24} height={24} />
    </i>
  );
  return (
    <>
      {isAlertAssigneeEnabled && (
        <div className="alert-clickable-item" styleName="assignee" role="button">
          <FormDdlSelect
            button={userObj ? assigneeAvatar : emptyAvatar}
            placement="auto"
            options={filteredUsersList}
            disabled={!isEditable}
            isUseSearch
            optionComponent={<OptionComponentSimple />}
            onChange={handleChange}
            width={290}
            height={260}
            automationId="assigneeSelection"
          />
        </div>
      )}
    </>
  );
};

export default React.memo(AlertAssignee);
