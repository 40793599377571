// @flow
import React, {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import * as actions from 'alerts.management/store/actions';
import * as selectors from 'alerts.management/store/selectors';
import {useField} from 'react-final-form';
import InputAuto from '../simulationArea/utils/InputAuto';

const UpdatePolicyCondition = () => {
  const {meta} = useField('updatePolicyInterval');

  const {updatePolicyInterval} = useSelector(selectors.getSelectedAlertSimulationFilters);
  const dispatch = useDispatch();
  const onChange = useCallback(
    (value) => {
      dispatch(
        actions.setSelectedAlertUpdatePolicy({
          value,
          enabled: updatePolicyInterval.enabled,
        }),
      );
    },
    [dispatch, updatePolicyInterval.enabled],
  );

  return (
    <div style={{display: 'flex'}}>
      <span>Min time between updates:</span>
      <InputAuto
        additionalProps={{
          name: 'updatePolicyInterval',
          min: updatePolicyInterval.min,
        }}
        multipleOf={updatePolicyInterval.step}
        style={{width: 30, padding: 0, textAlign: 'center', margin: '0px 8px'}}
        fullSize
        delay={1000}
        type="number"
        automationId="updatePolicyInterval"
        className="dark-input"
        value={updatePolicyInterval.value || ''}
        onChange={(e) => onChange(e.target.value)}
        isInvalid={meta.touched && meta.error}
        invalidMessage={meta.error}
      />
      <div>{updatePolicyInterval.scaleText}</div>
    </div>
  );
};

export default UpdatePolicyCondition;
