import React, {useCallback, useMemo} from 'react';
import {get, isObject} from 'lodash';
import {createContentStateFromText, createEditorState} from 'common/componentsV2/textEditor/TextEditor';
import {Editor} from 'react-draft-wysiwyg';
import {convertFromRaw} from 'draft-js';
import {useHistory} from 'react-router-dom';
import {Box, makeStyles, useTheme} from '@material-ui/core';
import {useField} from 'react-final-form';
import {allowedUserTileActions, tileActions} from 'dashboards/services/dashboardService';
import OptionWithIcon from 'common/componentsV2/ddl/selectAndt/innerComponents/OptionWithIcon';

import SelectAndt, {
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.DraftEditor-editorContainer': {
      zIndex: 0,
    },
    '.rdw-link-decorator-wrapper': {
      '& a': {
        '& span': {
          color: `${theme.palette.blue[500]} !important`,
          textDecoration: 'underline !important',
        },
      },
    },
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexShrink: 0,
    '@global': {
      '.andt-dropdown-control': {
        minHeight: 35,
      },
      '.andt-dropdown-value-container': {
        height: 35,
      },
    },
  },
  emptyTilesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  wrapperTile: {
    flexGrow: 1,
    borderRadius: 6,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 0',
    '&:hover $editMode': {
      display: 'flex',
    },
  },
  settingHeader: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: theme.palette.black[500],
    fontWeight: 500,
    lineHeight: '24px',
    padding: '0 16px 0 20px',
    flexShrink: 0,
    fontSize: 22,
  },
  title: {
    height: 24,
    marginBottom: 4,
    ...theme.palette.ellipsis,
  },
  editWrapper: {
    display: 'none',
    position: 'absolute',
    color: theme.palette.gray[500],
    right: 5,
    top: 3,
    fontSize: 16,
    lineHeight: 1,
    zIndex: 1,
  },
  dragWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 25,
    position: 'absolute',
    left: 3,
    top: 0,
    fontSize: 16,
    color: theme.palette.gray[400],
    zIndex: 1,
  },
  dragIcon: {
    position: 'relative',
    top: -1,
    display: 'none',
    '&:hover': {
      cursor: 'grab',
    },
  },
  warningIcon: {
    position: 'relative',
    top: 1,
    color: theme.palette.red[500],
    cursor: 'pointer',
  },
  fullSize: {
    position: 'absolute',
    right: 10,
    top: 5,
    fontSize: 18,
  },
  editMode: {},
  iconAction: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.blue[500],
    },
  },
  wrapperFullSize: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.white[500],
    borderRadius: 4,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
    padding: 12,
  },
  description: {
    flexGrow: 1,
    fontSize: 16,
    lineHeight: '20px',
    padding: '0 16px 0 20px',
    overflowY: 'auto',
    '@global': {
      '.public-DraftStyleDefault-block': {
        margin: 0,
      },
    },
  },
}));

const TextTileContainer = ({
  tileData,
  isOwnerUser,
  matchUrl,
  tileId,
  isAnonymous,
}: {
  tileData: Object,
  isOwnerUser: boolean,
  isAnonymous: boolean,
  matchUrl: string,
  tileId: string,
}) => {
  const history = useHistory();
  const theme = useTheme();
  const {
    input: {value: isEditableMode},
  } = useField('isEditableMode');
  const classes = useStyles();

  const options = useMemo(
    () =>
      tileActions
        .map((option) => {
          if (isAnonymous || (!isOwnerUser && !allowedUserTileActions.includes(option.value))) {
            return {...option, disabled: true};
          }
          return option;
        })
        .filter(
          (o) =>
            o.value !== 'legend' &&
            o.value !== 'alert' &&
            o.value !== 'showInMetricExplorer' &&
            o.value !== 'exportToCsv',
        ),
    [isOwnerUser],
  );

  const isTextTile = tileData && tileData.type === 'title';
  const isNoteTile = tileData && tileData.type === 'note';

  const descriptionValue = get(tileData, 'freeText.text', '');
  const editorState =
    descriptionValue && isObject(descriptionValue)
      ? createEditorState(convertFromRaw(descriptionValue))
      : createEditorState(createContentStateFromText(descriptionValue));

  const onTileAction = useCallback(
    (option) => {
      history.push(`${matchUrl}/${tileId}/${option.value}/${history.location.search}`);
    },
    [history, tileId],
  );

  return (
    <Box
      className={`${classes.wrapperTile} ${isEditableMode ? classes.editMode : ''}`}
      bgcolor={theme.palette.white[500]}
    >
      <Box
        className={classes.settingHeader}
        justifyContent={isNoteTile ? 'flex-start' : 'center'}
        height={isTextTile ? '100%' : undefined}
        padding={isNoteTile ? '4px 16px 4px 16px' : '6px 16px 14px 16px'}
      >
        <div className={classes.title}>{tileData.title.text}</div>
        <div className={`${classes.dragWrapper} dragHandlerClassName`}>
          <i
            className={`icon icn-general16-draghandle ${classes.dragIcon} ${isEditableMode ? classes.editMode : ''}`}
          />
        </div>
        <div className={`${classes.editWrapper} ${classes.editMode}`}>
          <SelectAndt
            automationId="dashboardTileEdit"
            type={TYPE_SIMPLE}
            theme={THEME_TRANSPARENT}
            direction={DIRECTION_LEFT}
            options={options}
            onChange={(option) => onTileAction(option, tileData.id)}
            optionHeight={40}
            menuWidth={150}
            customComponent={{
              Option: OptionWithIcon,
              DropdownIndicator: (p) => (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.iconAction}
                  color={p.selectProps.menuIsOpen ? 'blue.500' : undefined}
                >
                  <i {...p.innerProps} className="icon icn-nav16-settings" />
                </Box>
              ),
            }}
            closeMenuOnSelect={false}
            appendToBody={false}
          />
        </div>
      </Box>
      {isNoteTile && (
        <div className={classes.description}>
          <Editor editorState={editorState} toolbarHidden readOnly />
        </div>
      )}
    </Box>
  );
};

export default TextTileContainer;
