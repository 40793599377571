// @flow
import React from 'react';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import Loader from 'common/components/Loader';
import Checkbox from 'common/componentsV2/Checkbox';

import './DimensionsAndMetrics.module.scss';

const getItemIndexOrName = (item) => {
  if (item.index >= 0) {
    return item.index;
  }
  if (item.name) {
    return item.name;
  }
  return item.path;
};

type PropTypes = {
  dataStream: Object,
  dimensions: Array<Object>,
  metrics: Array<Object>,
  timeDefinition: Object,
  isLoading: boolean,
  isEditable: boolean,
  isEditHidden: boolean,
  isVertical: boolean,
  dimensionColumnName: string,
  measureColumnsName: string,
  dateColumnsName: string,
  useExternalTime: Boolean,
  onUseExternalTimeChanged: Function,
  onSelectEditor: Function,
  validatorFunction: Function,
};

class DimensionsAndMetrics extends React.PureComponent {
  props: PropTypes;

  render() {
    const {
      onSelectEditor,
      dataStream,
      dimensions,
      metrics,
      timeDefinition,
      isLoading,
      isEditable,
      isEditHidden,
      isVertical,
      validatorFunction,
      dimensionColumnName,
      measureColumnsName,
      dateColumnsName,
      useExternalTime,
      onUseExternalTimeChanged,
    } = this.props;
    const validationMessages = validatorFunction(dataStream, true);

    const timestampField = () => {
      return (
        <div>
          <span styleName="title">{dateColumnsName || 'Date Columns'}</span>
          <div className="display_flex flexDirection_column flex_gap_5">
            {!onUseExternalTimeChanged ? null : (
              <div>
                <Checkbox
                  isChecked={useExternalTime}
                  text="Use external timestamp"
                  onChange={onUseExternalTimeChanged}
                />
              </div>
            )}
            {useExternalTime === true ? (
              <div />
            ) : (
              <div styleName="list-item" automation-id="dateItem">
                {timeDefinition?.name || timeDefinition?.path}
              </div>
            )}
          </div>
        </div>
      );
    };

    if (isLoading) {
      return (
        <div className="shell-col" styleName="root">
          <PanelHeader title="Measures & Dimensions" isEditable={isEditable} isEditHidden={isEditHidden} />
          <Loader size="small" />
        </div>
      );
    }

    return (
      <div
        className="shell-col"
        styleName={['root', isEditable ? '' : 'disabled', isVertical ? 'vertical' : ''].join(' ')}
        automation-id="streamQueryMeasuresAndDimensions"
      >
        <PanelHeader
          title="Measures & Dimensions"
          onSelect={onSelectEditor}
          isEditable={isEditable}
          isEditHidden={isEditHidden}
        />

        {validationMessages && validationMessages.isArray && validationMessages.length > 0 && !isLoading && (
          <div styleName="errors-container">
            {validationMessages.map((m, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i} styleName="error">
                {m}
              </div>
            ))}
          </div>
        )}

        {!isVertical ? (
          <table className="shell" automation-id="dimensionsAndMetricsTable">
            <tbody styleName="sections-container">
              <tr>
                {timeDefinition && (
                  <td styleName="section date" automation-id="dateColumn">
                    {timestampField()}
                  </td>
                )}
                <td styleName="section" automation-id="measureColumn">
                  <span styleName="title">{measureColumnsName || 'Measure Columns'}</span>
                  {metrics.map((m) => (
                    <div automation-id="measureItem" key={getItemIndexOrName(m)} styleName="list-item">
                      {m.name || m.path}
                    </div>
                  ))}
                </td>
                <td styleName="section" automation-id="dimensionColumn">
                  <span styleName="title">{dimensionColumnName || 'Dimension Columns'}</span>
                  {dimensions.map((d) => (
                    <div automation-id="dimensionItem" key={getItemIndexOrName(d)} styleName="list-item">
                      {d.name || d.path}
                    </div>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <div>
            {timeDefinition && <div styleName="vertical-section">{timestampField()}</div>}
            <div styleName="vertical-section">
              <span styleName="title">{measureColumnsName || 'Measure Columns'}</span>
              {metrics.map((m) => (
                <div automation-id="stream" key={getItemIndexOrName(m)} styleName="list-item">
                  {m.name || m.path}
                </div>
              ))}
            </div>
            <div styleName="vertical-section">
              <span styleName="title">{dimensionColumnName || 'Dimension Columns'}</span>
              {dimensions.map((d) => (
                <div key={getItemIndexOrName(d)} styleName="list-item">
                  {d.name || d.path}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default DimensionsAndMetrics;
