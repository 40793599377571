// @flow
import React, {useEffect, useState} from 'react';

const styles = {
  wrapper: {
    height: 20,
    display: 'flex',
    justifyContent: 'flex - end',
  },

  text: {
    fontSize: 14,
    fontWeight: '500',
    color: '#8995a0',
  },
};

const AlertsCounter = ({
  displayedTotalTriggeredAlerts,
  totalTriggeredAlerts,
}: {
  displayedTotalTriggeredAlerts: Number,
  totalTriggeredAlerts: Number,
}) => {
  const [counters, setCounters] = useState('n/a');

  useEffect(() => {
    let tmpCounters = 'n/a';
    if (displayedTotalTriggeredAlerts >= 0 && totalTriggeredAlerts >= 0) {
      if (displayedTotalTriggeredAlerts < totalTriggeredAlerts) {
        tmpCounters = `${displayedTotalTriggeredAlerts}/${totalTriggeredAlerts}`;
      }
      if (displayedTotalTriggeredAlerts === totalTriggeredAlerts) {
        tmpCounters = `${totalTriggeredAlerts}`;
      }
    }
    setCounters(tmpCounters);
  }, [displayedTotalTriggeredAlerts, totalTriggeredAlerts]);

  return (
    <div style={styles.wrapper}>
      <span style={styles.text}>{`Alerts (${counters})`}</span>
    </div>
  );
};

export default AlertsCounter;
