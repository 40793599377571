// @flow
import React from 'react';
import {palette} from 'app/styles/theme';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {getFormattedDateMini} from 'common/utils/dateService';
import RadioButton from 'common/componentsV2/RadioButton';
import Tooltip from 'common/componentsV2/Tooltip';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
    borderBottom: `1px solid ${palette.gray['200']}`,
    cursor: 'pointer',
  },
  radio: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  activeWrapper: {
    backgroundColor: palette.gray[350],
  },
  tooltipWrapper: {
    whiteSpace: 'pre-line',
  },
}));

const RoutingModalTableItem = ({
  timeZone,
  id,
  onChange,
  activeTable,
  title,
  creationTime,
  alertsUsed,
  editTime,
}: {
  timeZone: String,
  id: String,
  onChange: Function,
  activeTable: Boolean,
  title: String,
  creationTime: String,
  alertsUsed: Number,
  editTime: String,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        onClick={() => onChange(id)}
        className={classes.wrapper}
        style={{backgroundColor: activeTable ? palette.blue['100'] : null}}
      >
        <Grid item sm={6}>
          <Grid container>
            <Grid item sm={1} className={classes.radio}>
              <RadioButton checked={activeTable} />
            </Grid>
            <Grid item sm={11}>
              <span className="text16reg lineHeight_16">{title}</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={2}>
          <span className="text14reg lineHeight_14">{getFormattedDateMini(creationTime, timeZone)}</span>
        </Grid>
        <Grid item sm={2}>
          <Tooltip
            content={Array.isArray(alertsUsed) ? alertsUsed.map((alert) => alert.title).join('\r\n') : undefined}
            extraTtClass={classes.tooltipWrapper}
          >
            <span className="text14reg lineHeight_14">{alertsUsed?.length || 0}</span>
          </Tooltip>
        </Grid>
        <Grid item sm={2}>
          <span className="text14reg lineHeight_14">
            {!editTime ? 'Never' : getFormattedDateMini(editTime, timeZone)}
          </span>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(RoutingModalTableItem);
