// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import Button, {COLORS} from 'common/componentsV2/Button';
import DateRange from 'bc/components/streams/editor/scheduler/DateRange';
import {rangeTypes} from 'common/utils/dateRangeService';
import PollingInterval from 'bc/components/streams/editor/scheduler/PollingInterval';
import NotificationDelay from 'bc/components/streams/editor/scheduler/NotificationDelay';
import DatadogRollup from 'bc/components/streams/editor/byType/datadog/datadogSchedular/DatadogRollup';
import {getNotificationDelayOption, getPollingIntervalOption} from 'bc/services/dataStreamService';
import {getMaxRollupPeriodPerPollingInterval} from 'bc/services/datadogService';

import './DatadogSchedularModal.module.scss';

type PropTypes = {
  setSelectedStreamKeyVal: Function,

  dataStream: Object,
  onClose: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)(
  class DatadogSchedularModal extends React.PureComponent {
    props: PropTypes;

    state = {
      pollingInterval: this.props.dataStream.pollingInterval,
      historicalDateRange: this.props.dataStream.historicalDateRange,
      notificationDelay: this.props.dataStream.notificationDelay,
      rollupAggregation: this.props.dataStream.rollupAggregation,
      resolutionMinutes: this.props.dataStream.resolutionMinutes,
    };

    onCloseButtonClicked = () => {
      this.props.onClose();
    };

    pollingChanged = (val) => {
      const maxVal = getMaxRollupPeriodPerPollingInterval(val) || null;
      if (maxVal && maxVal < this.state.resolutionMinutes) {
        this.setState({
          pollingInterval: val,
          resolutionMinutes: maxVal,
        });
      } else {
        this.setState({pollingInterval: val});
      }
    };

    historicalDateRangeChanged = (val) => {
      this.setState({historicalDateRange: val});
    };

    notificationDelayChanged = (val) => {
      this.setState({notificationDelay: val});
    };

    onRollupFunctionChange = (val) => {
      this.setState({rollupAggregation: val});
    };

    onRollupPeriodChange = (val) => {
      this.setState({resolutionMinutes: val});
    };

    onSave = () => {
      this.props.setSelectedStreamKeyVal({
        pollingInterval: this.state.pollingInterval,
        historicalDateRange: this.state.historicalDateRange,
        notificationDelay: this.state.notificationDelay,
        rollupAggregation: this.state.rollupAggregation,
        resolutionMinutes: this.state.resolutionMinutes,
      });
      this.onCloseButtonClicked();
    };

    render() {
      const {dataStream} = this.props;

      return (
        <div styleName="modal-body">
          <div styleName="itemContainer">
            <PollingInterval
              title="Collect Interval"
              pollingInterval={this.state.pollingInterval}
              allowedValues={getPollingIntervalOption(dataStream.type)}
              onChange={this.pollingChanged}
            />
          </div>

          <div styleName="itemContainer">
            <DateRange
              title="Collect Since"
              allowedRangeTypes={[rangeTypes.h1.key, rangeTypes.h4.key, rangeTypes.d1.key]}
              dateRange={this.state.historicalDateRange}
              onChange={this.historicalDateRangeChanged}
              isUnix
            />
          </div>

          <div styleName="itemContainer">
            <NotificationDelay
              notificationDelayOption={getNotificationDelayOption()}
              pollingInterval={this.state.pollingInterval}
              notificationDelay={this.state.notificationDelay}
              onChange={this.notificationDelayChanged}
            />
          </div>

          <div styleName="itemContainer">
            <DatadogRollup
              disabled={dataStream.customQuery}
              pollingInterval={this.state.pollingInterval}
              resolutionMinutes={this.state.resolutionMinutes}
              rollupAggregation={this.state.rollupAggregation}
              onRollupFunctionChange={this.onRollupFunctionChange}
              onRollupPeriodChange={this.onRollupPeriodChange}
            />
          </div>

          <div styleName="footer">
            <div styleName="left-button">
              <Button
                colorSchema={COLORS.GRAY_400}
                text="Cancel"
                automation-id="schedulerEditorModalCancel"
                onClick={this.onCloseButtonClicked}
              />
            </div>
            <div>
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Save"
                automation-id="schedulerEditorModalSave"
                onClick={this.onSave}
              />
            </div>
          </div>
        </div>
      );
    }
  },
);
