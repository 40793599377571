// @flow
import React from 'react';
import {useHistory} from 'react-router-dom';
import Button, {COLORS as BUTTON_COLORS} from 'common/componentsV2/Button';

import './ChannelsGroup.module.scss';

const ChannelsGroup = ({
  channelType,
  onClickFunction,
  channelsCount,
  isOpen,
}: {
  channelType: Object,
  onClickFunction: Function,
  isOpen: boolean,
  channelsCount: number,
}) => {
  const history = useHistory();
  const Icon = channelType.icon;
  return (
    <div
      styleName="itemContainer"
      className="justifyContent_space-between"
      key={channelType.value}
      automation-id={`ChannelGroup${channelType.label}`}
    >
      <span styleName="titleWrapper" onClick={() => onClickFunction(channelType.value)}>
        <span styleName="arrow">
          <span className={`icon ${isOpen ? 'icn-arrow16-chevrondown' : 'icn-arrow16-chevronright'}`} />
        </span>
        <div className="mt_-1-25 mr_-1 ml_0-5">
          <Icon width={50} height={50} />
        </div>
        <span styleName="name">{channelType.label}</span>
        <span styleName="type">{channelsCount} channels</span>
      </span>
      <div styleName="button">
        <Button
          colorSchema={BUTTON_COLORS.BLUE_500}
          text="New Channel"
          icon="icn-action16-plusa"
          onClick={() => history.push(`/channels/new/${channelType.value}${history.location.search}`)}
        />
      </div>
    </div>
  );
};

export default ChannelsGroup;
