// @flow
import React, {useCallback, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {
  ENTITY_TYPE_VALUE,
  DEFAULT_PARAMS,
  getNameEntityType,
  OPTIONS_ACTION_TYPE,
  OPTIONS_ENTITY_TYPE,
  PARAMS_NAME,
  SYSTEM_USER,
} from 'admin.activityLog/services/activityLogService';
import {isEqual} from 'lodash';
import DateRangesDdl, {THEME} from 'common/components/dateTime/DateRangesDdl';
import {getDateValue} from 'common/utils/dateRangeService';
// import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import OptionComponentCheckboxHeaders from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentCheckboxHeaders';
import FiltersPanel from 'common/componentsV2/FiltersPanel';

const EMPTY_ARRAY = [];

type PropsType = {
  queryParams: Object,
  setQueryParams: Function,
  isOpenFilters: boolean,
  toggleFilters: Function,
  usersData: Array<Object>,
  timeZoneName: string,
  listEntityId: Array<Object>,
  isLoading: boolean,
  listEntityIdIsLoading: boolean,
};

const useStyles = makeStyles(({typography}) => ({
  labelFilter: {
    ...typography.subtitle1,
    lineHeight: '24px',
    marginBottom: 4,
    display: 'flex',
  },
  labelActivityName: {
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}));

const ActivityLogFilter = ({
  queryParams,
  setQueryParams,
  isOpenFilters,
  toggleFilters,
  usersData,
  timeZoneName,
  listEntityId,
  isLoading,
  listEntityIdIsLoading,
}: PropsType) => {
  const classes = useStyles();

  const usersOptions = useMemo(() => {
    const result = usersData.map((o) => ({
      label: `${o.firstName} ${o.lastName}`,
      value: o._id,
    }));
    result.unshift({label: 'All', value: ENTITY_TYPE_VALUE.ALL});
    result.push(SYSTEM_USER);
    return result;
  }, [usersData]);

  const optionsEntityId = useMemo(() => {
    const result = [];

    listEntityId
      .map((o) => ({label: o.entityName, value: o.entityDefinition.id || o.entityDefinition._id}))
      .forEach((item) => {
        const i = result.findIndex((x) => x.value === item.value);
        if (i <= -1) {
          result.push(item);
        }
      });
    if (result.length) {
      result.unshift({label: 'All', value: ENTITY_TYPE_VALUE.ALL});
    }
    return result;
  }, [listEntityId]);

  // const selectedOptionUser = useMemo(
  //   () => usersOptions.filter((item) => (queryParams[PARAMS_NAME.USERS] || '').includes(item.value)),
  //   [usersOptions, queryParams[PARAMS_NAME.USERS]],
  // );

  const selectedActionType = useMemo(
    () => OPTIONS_ACTION_TYPE.filter((item) => (queryParams[PARAMS_NAME.ACTION_TYPE] || '').includes(item.value)),
    [queryParams[PARAMS_NAME.ACTION_TYPE]],
  );

  // const selectedEntityId = useMemo(
  //   () => optionsEntityId.filter((item) => (queryParams[PARAMS_NAME.ENTITY_ID] || '').includes(item.value)),
  //   [optionsEntityId, queryParams[PARAMS_NAME.ENTITY_ID]],
  // );

  const onSetDefault = useCallback(() => {
    setQueryParams(DEFAULT_PARAMS);
  }, []);

  // const onClear = useCallback((param) => {
  //   setQueryParams({[param]: undefined});
  // }, []);

  // const onClearUsers = useCallback(() => {
  //   if (selectedOptionUser && selectedOptionUser.length > 0) {
  //     setQueryParams({userId: undefined});
  //   } else {
  //     const selectedOption = usersOptions.map((i) => i.value).join(',');
  //     setQueryParams({userId: selectedOption});
  //   }
  // }, [usersOptions, selectedOptionUser]);

  const onClearAction = useCallback(
    (options) => {
      if (selectedActionType && selectedActionType.length > 0) {
        setQueryParams({actionType: undefined});
      } else {
        const selectedOption = options.map((i) => i.value).join(',');
        setQueryParams({actionType: selectedOption});
      }
    },
    [selectedActionType],
  );

  const onChange = useCallback((items, nameField) => {
    const value = items.length ? items.map((item) => item.value || item.name).join(',') : undefined;
    setQueryParams({[nameField]: value});
  }, []);

  const onChangeHandler = useCallback(({value, constRange}, nameField) => {
    if (String(nameField) === String(PARAMS_NAME.ENTITY_TYPE)) {
      setQueryParams({
        [PARAMS_NAME.ENTITY_ID]: undefined,
        [PARAMS_NAME.ENTITY_TYPE]: value === ENTITY_TYPE_VALUE.ALL ? undefined : value,
      });
    } else {
      setQueryParams({[nameField]: value === ENTITY_TYPE_VALUE.ALL ? undefined : value || constRange});
    }
  }, []);

  const selectedOption = (options, name) => {
    const index = options.findIndex((o) => o.value === queryParams[name]);
    if (index > -1) {
      return options[index];
    }
    return EMPTY_ARRAY;
  };

  return (
    <FiltersPanel
      isOpen={isOpenFilters}
      isSaveView={false}
      isShareLink={false}
      onClose={toggleFilters}
      onClearAll={onSetDefault}
      isClearAll={!isEqual(queryParams, DEFAULT_PARAMS)}
    >
      <div className="mt_2-5">
        <div className={classes.labelFilter}>Time Frame</div>
        <DateRangesDdl
          dateRange={getDateValue(
            {[PARAMS_NAME.CONST_RANGE]: queryParams[PARAMS_NAME.CONST_RANGE]},
            false,
            timeZoneName,
          )}
          onChange={(option) => onChangeHandler({value: option[PARAMS_NAME.CONST_RANGE]}, PARAMS_NAME.CONST_RANGE)}
          menuWidth={241}
          timeZoneName={timeZoneName}
          buttonWidth="100%"
          theme={THEME.HIGHLIGHTED}
          allowedRangeTypes={['h1', 'h4', 'd1', 'd3', 'w1', 'w2', 'm1', 'm3']}
          allowedCustomDates={false}
        />
      </div>
      <div className="mt_2-5">
        <div className={classes.labelFilter}>Users</div>
        {/* <UsersChannelsFilter */}
        {/*  onChange={(items) => onChange(items, PARAMS_NAME.USERS)} */}
        {/*  sortedFlattenChannels={usersOptions} */}
        {/*  sortAlphabetic */}
        {/*  value={selectedOptionUser} */}
        {/*  isFullWidthButton */}
        {/*  footerComponent={ */}
        {/*    <FormDdlActionBar isVisible selectedCount={selectedOptionUser.length} onClearAll={onClearUsers} /> */}
        {/*  } */}
        {/*  menuWidth={280} */}
        {/* /> */}
        <FormDdlSelect
          options={usersOptions}
          selected={selectedOption(usersOptions, PARAMS_NAME.USERS)}
          button={
            <BigDropdownButton
              isPristine={!Object.keys(selectedOption(usersOptions, PARAMS_NAME.USERS)).length}
              placeholder="All users"
              label={selectedOption(usersOptions, PARAMS_NAME.USERS).label}
              shortBlueStyle
              isFullWidth
            />
          }
          optionComponent={<OptionComponentSimple />}
          onChange={(option) => onChangeHandler(option, PARAMS_NAME.USERS)}
          width={241}
          maxWidth={241}
        />
      </div>
      <div className="mt_2-5">
        <div className={classes.labelFilter}>Action Type</div>
        <FormDdlSelect
          options={OPTIONS_ACTION_TYPE}
          selected={selectedActionType}
          button={
            <BigDropdownButton
              isPristine={!selectedActionType.length}
              placeholder="All Actions"
              label={
                selectedActionType.length === 1 ? selectedActionType[0].label : `${selectedActionType.length} Selected`
              }
              shortBlueStyle
              isFullWidth
            />
          }
          optionComponent={<OptionComponentCheckboxHeaders />}
          onChange={(items) => onChange(items, PARAMS_NAME.ACTION_TYPE)}
          width={280}
          maxWidth={280}
          isMulti
          height={260}
          footerComponent={
            <FormDdlActionBar
              isVisible
              selectedCount={selectedActionType.length}
              onClearAll={() => onClearAction(OPTIONS_ACTION_TYPE)}
            />
          }
        />
      </div>
      <div className="mt_2-5">
        <div className={classes.labelFilter}>Show Activity of</div>
        <FormDdlSelect
          options={OPTIONS_ENTITY_TYPE}
          selected={selectedOption(OPTIONS_ENTITY_TYPE, PARAMS_NAME.ENTITY_TYPE)}
          button={
            <BigDropdownButton
              isPristine={!Object.keys(selectedOption(OPTIONS_ENTITY_TYPE, PARAMS_NAME.ENTITY_TYPE)).length}
              placeholder="All Activities"
              label={selectedOption(OPTIONS_ENTITY_TYPE, PARAMS_NAME.ENTITY_TYPE).label}
              shortBlueStyle
              isFullWidth
            />
          }
          optionComponent={<OptionComponentSimple />}
          onChange={(option) => onChangeHandler(option, PARAMS_NAME.ENTITY_TYPE)}
          width={241}
          maxWidth={241}
        />
      </div>
      {queryParams[PARAMS_NAME.ENTITY_TYPE] && (
        <div className="mt_2-5">
          <div className={classes.labelFilter}>
            <div>Filter by&#xa0;</div>
            <div className={classes.labelActivityName}>{getNameEntityType(queryParams[PARAMS_NAME.ENTITY_TYPE])}</div>
          </div>
          {/* <FormDdlSelect */}
          {/*  options={optionsEntityId} */}
          {/*  selected={selectedEntityId} */}
          {/*  isUseSearch */}
          {/*  button={ */}
          {/*    <BigDropdownButton */}
          {/*      isPristine={!selectedEntityId.length} */}
          {/*      placeholder="Select" */}
          {/*      label={ */}
          {/*        selectedEntityId.length === 1 */}
          {/*          ? selectedEntityId[0].label */}
          {/*          : `${selectedEntityId.length} Selected` */}
          {/*      } */}
          {/*      shortBlueStyle */}
          {/*      isFullWidth */}
          {/*    /> */}
          {/*  } */}
          {/*  optionComponent={<OptionComponentCheckboxHeaders />} */}
          {/*  onChange={(items) => onChange(items, getNameEntityType(queryParams[PARAMS_NAME.ENTITY_TYPE]))} */}
          {/*  width={280} */}
          {/*  maxWidth={280} */}
          {/*  height={260} */}
          {/*  placement="top" */}
          {/*  isMulti */}
          {/*  footerComponent={ */}
          {/*    <FormDdlActionBar */}
          {/*      isVisible */}
          {/*      isClearAllDisabled={!selectedEntityId || !selectedEntityId.length} */}
          {/*      onClearAll={() => onClear(getNameEntityType(queryParams[PARAMS_NAME.ENTITY_TYPE]))} */}
          {/*    /> */}
          {/*  } */}
          {/* /> */}
          <FormDdlSelect
            options={optionsEntityId}
            selected={selectedOption(optionsEntityId, PARAMS_NAME.ENTITY_ID)}
            button={
              <BigDropdownButton
                isPristine={!Object.keys(selectedOption(optionsEntityId, PARAMS_NAME.ENTITY_ID)).length}
                placeholder={`All ${getNameEntityType(queryParams[PARAMS_NAME.ENTITY_TYPE])}s`}
                label={selectedOption(optionsEntityId, PARAMS_NAME.ENTITY_ID).label}
                shortBlueStyle
                isFullWidth
              />
            }
            optionComponent={<OptionComponentSimple />}
            onChange={(option) => onChangeHandler(option, PARAMS_NAME.ENTITY_ID)}
            width={241}
            maxWidth={241}
            height={260}
            disabled={isLoading || listEntityIdIsLoading}
          />
        </div>
      )}
    </FiltersPanel>
  );
};

export default React.memo(ActivityLogFilter);
