import {useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';
import {stringifyQS} from 'common/utils/http';
import {useSelector} from 'react-redux';
import {getTimeZoneName} from 'profile/store/selectors';
import {getDateValue} from 'common/utils/dateRangeService';
import {INSIGHT_PANEL_GROUP_BY_OPTIONS} from 'insightsPanelNewAlertConsole/services/constants';
import {alertsInsightsRoot} from 'insightsPanelNewAlertConsole/api/utils';

const fetchTriggeredAlertsCountBy = async ({payload}) => {
  const groupByDayPromise = get(
    `${alertsInsightsRoot}count-alerts-by/${INSIGHT_PANEL_GROUP_BY_OPTIONS.DAILY}?${stringifyQS(payload)}`,
  );
  const groupBySeverityPromise = get(
    `${alertsInsightsRoot}count-alerts-by/${INSIGHT_PANEL_GROUP_BY_OPTIONS.SEVERITY}?${stringifyQS(payload)}`,
  );
  const groupByAlertTypePromise = get(
    `${alertsInsightsRoot}count-alerts-by/${INSIGHT_PANEL_GROUP_BY_OPTIONS.ALERT_TYPE}?${stringifyQS(payload)}`,
  );

  const [resultByDay, resultBySeverity, resultByAlertType] = await Promise.all([
    groupByDayPromise,
    groupBySeverityPromise,
    groupByAlertTypePromise,
  ]);
  return {resultByDay, resultBySeverity, resultByAlertType};
};

const fetchParams = (queryParams) => {
  const timeZone = useSelector(getTimeZoneName);
  const dateRange = getDateValue(
    {
      constRange: queryParams?.constRange,
    },
    true,
    timeZone,
  );
  const triggeredAlertsParams = {
    constRange: dateRange.constRange,
    breachTimeFrom: dateRange.startDate,
  };

  // If only want alerts that are open - add isOpen parameter to the api request.
  if (queryParams.isOpen) {
    return {...triggeredAlertsParams, isOpen: queryParams?.isOpen};
  }
  return triggeredAlertsParams;
};

export default function fetchTriggeredAlertsCountByProvider(queryParams) {
  const queryClient = useQueryClient();

  const calculatedQueryParams = fetchParams(queryParams);
  // Removing the breachTimeFrom react query key, since it is calculated using Now time, so it keeps changing,
  // thus changing the queryKey. Also, there is no need for pageNum in the total alerts queryKey API.
  const {breachTimeFrom, ...queryKeyParams} = calculatedQueryParams;
  // Removing the constRange, since it is not a parameter of the alerts-console/triggered API.
  const {constRange, ...params} = calculatedQueryParams;

  const queryKey = [QUERY_KEYS.triggeredAlertsInsightPanelCountBy, queryKeyParams];
  return {
    invalidate: (partialKey) => queryClient.invalidateQueries(partialKey ? [partialKey] : queryKey),
    reset: (partialKey) => queryClient.resetQueries(partialKey ? [partialKey] : queryKey),
    fetchQuery: () =>
      queryClient.fetchQuery(queryKey, fetchTriggeredAlertsCountBy({payload: params}), {
        retry: false,
      }),
    useQuery: () =>
      useQuery(queryKey, () => fetchTriggeredAlertsCountBy({payload: params}), {
        retry: false,
      }),
  };
}
