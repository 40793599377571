import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';
import Button, {COLORS, HEIGHTS as BUTTON_HEIGHTS, WIDTHS as BUTTON_WIDTHS} from 'common/componentsV2/Button';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {DELIMITERS, ESCAPE_CHARACTERS, QUOTE_CHARACTERS} from 'bc/services/fuService';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import styles from './FileFormatSettings.module.scss';

const {FILE_FORMAT} = EVENT_STREAM_FIELDS;

export default function FileFormatSettings({closeModal}) {
  const {getValues, setValue} = useFormContext();
  const values = getValues();
  const [fileFormat, setFileFormat] = useState(null);

  const applySettings = () => {
    const fileFormatSettings = values.fileFormat;
    fileFormatSettings.delimiter = fileFormat.delimiter?.value;
    fileFormatSettings.escapeChar = fileFormat.escapeChar?.value;
    fileFormatSettings.quoteChar = fileFormat.quoteChar?.value;
    fileFormatSettings.hasHeader = fileFormat.hasHeader;
    setValue(FILE_FORMAT.id, fileFormatSettings, {shouldDirty: true});
    closeModal();
  };

  useEffect(() => {
    const formatSettings = {
      delimiter: DELIMITERS.find((d) => d.value === values.fileFormat.delimiter),
      escapeChar: ESCAPE_CHARACTERS.find((d) => d.value === values.fileFormat.escapeChar),
      quoteChar: QUOTE_CHARACTERS.find((d) => d.value === values.fileFormat.quoteChar),
      hasHeader: values.fileFormat.hasHeader,
    };
    setFileFormat(formatSettings);
  }, []);

  return (
    <>
      <div className={styles.header}>File parsing settings</div>
      <span>The file will be parsed using the settings below</span>

      {fileFormat && (
        <div className={styles.contentWrapper}>
          <div className={styles.label}>File Delimiter</div>
          <div className={styles.selectWrapper}>
            <FormDdlSelect
              isDisabledSorting
              options={DELIMITERS}
              selected={fileFormat?.delimiter}
              onChange={(value) => setFileFormat({...fileFormat, delimiter: value})}
              button={
                <BigDropdownButton
                  isPristine={!fileFormat?.delimiter?.text?.length}
                  placeholder="Select"
                  label={fileFormat?.delimiter?.text}
                  shortBlueStyle
                  isFullWidth
                />
              }
              optionComponent={<OptionComponentSimple />}
              width={200}
            />
          </div>
          <div className={styles.label}>Escaping Character</div>
          <div className={styles.selectWrapper}>
            <FormDdlSelect
              isDisabledSorting
              options={ESCAPE_CHARACTERS}
              selected={fileFormat?.escapeChar}
              onChange={(value) => setFileFormat({...fileFormat, escapeChar: value})}
              button={
                <BigDropdownButton
                  isPristine={!fileFormat?.escapeChar?.text?.length}
                  placeholder="Select"
                  label={fileFormat?.escapeChar?.text}
                  shortBlueStyle
                  isFullWidth
                />
              }
              optionComponent={<OptionComponentSimple />}
              width={200}
            />
          </div>
          <div className={styles.label}>Quote Character</div>
          <div className={styles.selectWrapper}>
            <FormDdlSelect
              isDisabledSorting
              options={QUOTE_CHARACTERS}
              selected={fileFormat?.quoteChar}
              onChange={(value) => setFileFormat({...fileFormat, quoteChar: value})}
              button={
                <BigDropdownButton
                  isPristine={!fileFormat?.quoteChar?.text?.length}
                  placeholder="Select"
                  label={fileFormat?.quoteChar?.text}
                  shortBlueStyle
                  isFullWidth
                />
              }
              optionComponent={<OptionComponentSimple />}
              width={200}
            />
          </div>
          <div className={styles.checkboxWrapper}>
            <input
              type="checkbox"
              checked={fileFormat.hasHeader === false}
              onChange={() => setFileFormat({...fileFormat, hasHeader: !fileFormat.hasHeader})}
              name="Hide header row"
            />
            <div>File has a header row</div>
          </div>
        </div>
      )}
      <div className={styles.footerWrapper}>
        <div className={styles.buttonWrapper}>
          <Button
            colorSchema={COLORS.BLUE_500}
            height={BUTTON_HEIGHTS.REGULAR}
            width={BUTTON_WIDTHS.REGULAR}
            text="Apply"
            onClick={() => applySettings()}
            automation-id="applySettingsButton"
          />
        </div>
        <Button text="Cancel" onClick={closeModal} colorSchema={COLORS.GRAY_200} />
      </div>
    </>
  );
}

FileFormatSettings.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
