import React, {memo, useState, Fragment, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {TIMELINE_ENTRIES} from 'investigationNewAlertConsole/services/investigationService';
import {palette} from 'app/styles/theme';
import {makeStyles} from '@material-ui/core/styles';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import NameFormatShort from 'metrics/components/metricName/NameFormatShort';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {getTimeZoneName} from 'profile/store/selectors';
import {useSelector} from 'react-redux';
import Date from './Date';
import TimelineStatusLine from './TimelineStatusLine';

const useStyles = makeStyles(() => ({
  mainContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  eventWrapper: {
    position: 'relative',
    zIndex: '1',
    display: 'flex',
    width: '100%',
    margin: '0 auto 12px auto',
  },
  line: {
    position: 'absolute',
    height: 'calc(100% + 10px)',
    width: 2,
    left: 16,
    top: 15,
  },
  iconWrapper: {
    paddingTop: 9,
  },
  icon: {
    position: 'relative',
    width: 33,
    height: 33,
    border: `2px solid ${palette.white['500']}`,
    borderRadius: '50%',
    marginRight: 6,
    backgroundColor: palette.gray['200'],
    fontSize: 16,
    '&::before': {
      position: 'absolute',
      top: 6,
      left: 7,
    },
  },
  dateWrapper: {
    width: 70,
    paddingTop: 14,
  },
  infoWrapper: {
    width: 'calc(100% - 110px)',
    padding: 13,
    fontSize: 14,
    fontWeight: '500',
    background: palette.gray['100'],
    textAlign: 'left',
    overflow: 'hidden',
    borderRadius: 8,
  },
  body: {
    display: 'block',
    marginTop: 13,
    lineHeight: '20px',
  },
  conjunctions: {
    display: 'inline-block',
    color: palette.gray['500'],
  },
  plain: {
    display: 'inline',
    color: palette.gray['500'],
  },
  name: {
    textTransform: 'capitalize',
    display: 'inline-block',
    color: palette.gray['500'],
  },
  innerChip: {
    margin: '-5px 5px -5px 5px',
  },
  directionMeta: {
    marginTop: 7,
    fontSize: 14,
    fontWeight: '400',
    color: palette.gray['400'],
  },
}));

const TimelineEvent = (props: PropTypes) => {
  const classes = useStyles();
  const timeZoneName = useSelector(getTimeZoneName);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpendable, seIsExpendable] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const rowWrapper = useRef(null);
  const row = useRef(null);
  const {
    alertStatus,
    type,
    dataTime,
    createdTime,
    metricMeasures,
    title,
    timelineMetrics,
    updateType,
    userFullName,
    comment,
    topEvents,
    feedbackType,
    ackOperation,
    userId,
    newAssignee,
    newAssigneeFullName,
    previousAssignee,
    prevAssigneeFullName,
  } = props.timelineEvent;

  useEffect(() => {
    const rowContentHeight = rowWrapper.current.scrollHeight;
    const rowDefaultHeight = TIMELINE_ENTRIES[type].rowDefaultSize;

    seIsExpendable(rowContentHeight > rowDefaultHeight);
  }, []);

  useEffect(() => {
    if (isExpanded) {
      setMaxHeight(rowWrapper.current.scrollHeight);
    } else {
      setMaxHeight(TIMELINE_ENTRIES[type].rowDefaultSize);
    }
  }, [isExpanded]);

  const renderMetricMeta = (anomalyType, metrics) => {
    if (anomalyType === TIMELINE_ENTRIES.ANOMALY_UPDATED.updateType.DIRECTION_CHANGED.value) {
      return metrics.map((metric, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={`tleMetric${index}`}>
          {(metric.direction === 'UP' || metric.direction === 'MIXED') && (
            <Box className={classes.directionMeta}>
              <Box component="span" fontWeight={500}>
                Spike
              </Box>{' '}
              of {metric.upperPercentageDelta}% (from {metric.upperComparisonValue} to {metric.upperPeak})
            </Box>
          )}

          {metric.direction === 'DOWN' && (
            <Box className={classes.directionMeta}>
              <Box component="span" fontWeight={500}>
                Drop
              </Box>{' '}
              of {metric.lowerPercentageDelta}% (from {metric.lowerComparisonValue} to {metric.lowerPeak})
            </Box>
          )}

          <NameFormatShort measure={metric.measure} dimensions={metric.dimensions} />
        </Box>
      ));
    }
    return (
      <Box marginTop="14px">
        {metrics.map((metric, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <NameFormatShort key={`tleMetric${index}`} measure={metric.measure} dimensions={metric.dimensions} />
        ))}
      </Box>
    );
  };

  const renderMetricAdded = () => (
    <Fragment>
      <TypographyBox variant="h5" color={palette.orange['500']}>
        Anomaly updated
      </TypographyBox>

      {timelineMetrics.length > 1 && (
        <TypographyBox variant="hintText" className={classes.body}>
          {timelineMetrics.length} Metrics were added to this anomaly
        </TypographyBox>
      )}
      {timelineMetrics.length >= 1 && renderMetricMeta(updateType, timelineMetrics)}
    </Fragment>
  );
  const renderMetricClosed = () => (
    <Fragment>
      <TypographyBox variant="h5" color={palette.orange['500']}>
        Anomaly updated
      </TypographyBox>

      {timelineMetrics.length > 1 && (
        <TypographyBox variant="hintText" className={classes.body}>
          {timelineMetrics.length} metrics were closed (returned to normal range)
        </TypographyBox>
      )}
      {timelineMetrics.length >= 1 && renderMetricMeta(updateType, timelineMetrics)}
    </Fragment>
  );
  const renderDirectionChanged = () => (
    <Fragment>
      <TypographyBox variant="h5" color={palette.orange['500']}>
        Anomaly direction has changed
      </TypographyBox>

      {timelineMetrics.length > 1 && (
        <TypographyBox variant="hintText" className={classes.body}>
          {timelineMetrics.length} metrics changed their direction
        </TypographyBox>
      )}
      {timelineMetrics.length >= 1 && renderMetricMeta(updateType, timelineMetrics)}
    </Fragment>
  );
  const renderRowByType = () => {
    switch (type) {
      // Alert Events
      case TIMELINE_ENTRIES.ALERT_OPENED.value:
        return (
          <Fragment>
            <Box display="flex" alignItems="center">
              <TypographyBox variant="h5" color={palette.red['500']}>
                Alert on
              </TypographyBox>
              <InnerChip title={metricMeasures[0]} displayOnly type="measure" className={classes.innerChip} />
              <TypographyBox variant="h5" color={palette.red['500']}>
                triggered (this incident)
              </TypographyBox>
            </Box>
            <Box>
              <TypographyBox variant="hintText" isEllipsis className={classes.body}>
                {title}
              </TypographyBox>
            </Box>
          </Fragment>
        );
      case TIMELINE_ENTRIES.ALERT_CLOSED.value:
        return (
          <Fragment>
            <Box display="flex" alignItems="center">
              <TypographyBox variant="h5" color={palette.mint['600']}>
                Alert on
              </TypographyBox>
              <InnerChip title={metricMeasures[0]} displayOnly type="measure" className={classes.innerChip} />
              <TypographyBox variant="h5" color={palette.mint['600']}>
                closed
              </TypographyBox>
            </Box>
            <TypographyBox variant="hintText" className={classes.body}>
              All metrics returned to normal range.
            </TypographyBox>
          </Fragment>
        );
      case TIMELINE_ENTRIES.ANOTHER_ALERT_ADDED.value:
        return (
          <Fragment>
            <TypographyBox variant="h5" color={palette.orange['500']}>
              Another alert added to this incident
            </TypographyBox>
            <TypographyBox variant="hintText" isEllipsis className={classes.body}>
              {title}
            </TypographyBox>
          </Fragment>
        );
      // Anomaly Events
      case TIMELINE_ENTRIES.ANOMALY_STARTED.value:
        return (
          <Box display="flex" alignItems="center">
            <TypographyBox variant="h5" color={palette.red['500']}>
              Anomaly on{' '}
            </TypographyBox>
            {metricMeasures.map((measure, index) => (
              <InnerChip
                // eslint-disable-next-line react/no-array-index-key
                key={`tleMeasure${index}`}
                title={measure}
                displayOnly
                type="measure"
                className={classes.innerChip}
              />
            ))}
            <TypographyBox variant="h5" color={palette.red['500']}>
              started (this incident)
            </TypographyBox>
          </Box>
        );
      case TIMELINE_ENTRIES.ANOMALY_UPDATED.value:
        if (updateType === 'METRIC_ADDED') {
          return renderMetricAdded();
        }
        if (updateType === 'METRIC_CLOSED') {
          return renderMetricClosed();
        }
        if (updateType === 'DIRECTION_CHANGED') {
          return renderDirectionChanged();
        }
        break;
      // Other Events
      case TIMELINE_ENTRIES.COMMENT.value:
        return (
          <Fragment>
            <TypographyBox variant="h5" className={classes.name}>
              {userFullName || 'Anonymous'}
            </TypographyBox>{' '}
            <TypographyBox variant="caption" className={classes.body}>
              {comment}
            </TypographyBox>
          </Fragment>
        );
      case TIMELINE_ENTRIES.FEEDBACK.value:
        return (
          <Fragment>
            <TypographyBox variant="h5" className={classes.name}>
              {userFullName || 'Anonymous'}
            </TypographyBox>{' '}
            <TypographyBox variant="h5" className={classes.conjunctions}>
              marked this alert as {TIMELINE_ENTRIES.FEEDBACK[feedbackType].title}
            </TypographyBox>
            {comment ? (
              <TypographyBox variant="caption" className={classes.body}>
                {comment}
              </TypographyBox>
            ) : null}
          </Fragment>
        );
      case TIMELINE_ENTRIES.ACKNOWLEDGE.value:
        return (
          <Fragment>
            <TypographyBox variant="h5" className={classes.conjunctions}>
              {TIMELINE_ENTRIES.ACKNOWLEDGE[ackOperation].title} by
            </TypographyBox>{' '}
            <TypographyBox variant="h5" className={classes.name}>
              {userFullName || 'Anonymous'}
            </TypographyBox>
          </Fragment>
        );
      case TIMELINE_ENTRIES.USER_EVENT.value:
        return (
          <Fragment>
            <TypographyBox variant="h5" className={classes.conjunctions}>
              {topEvents.length} Events occurred -{topEvents[0].category}
            </TypographyBox>
            {topEvents.map((topEvent, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TypographyBox key={`tleUserEvents${index}`} variant="caption" className={classes.body}>
                {topEvent.title}
              </TypographyBox>
            ))}
          </Fragment>
        );
      case TIMELINE_ENTRIES.ASSIGNEE_CHANGED.value:
        return (
          <Fragment>
            {newAssignee ? (
              <>
                <TypographyBox variant="caption" className={classes.plain}>
                  Assigned
                </TypographyBox>
                {previousAssignee && (
                  <>
                    <TypographyBox variant="caption" className={classes.plain}>
                      {' from '}
                    </TypographyBox>
                    <TypographyBox variant="h5" className={classes.name}>
                      {`${prevAssigneeFullName || ''}`}
                    </TypographyBox>
                  </>
                )}
                <TypographyBox variant="caption" className={classes.plain}>
                  {' to '}
                </TypographyBox>
                <TypographyBox variant="h5" className={classes.name}>
                  {`${newAssigneeFullName || ''}`}
                </TypographyBox>
              </>
            ) : (
              <>
                <TypographyBox variant="caption" className={classes.plain}>
                  {'Unassigned from '}
                </TypographyBox>
                <TypographyBox variant="h5" className={classes.name}>
                  {`${prevAssigneeFullName || ''}`}
                </TypographyBox>
              </>
            )}
            {userId && (
              <>
                <TypographyBox variant="caption" className={classes.plain}>
                  {' by '}
                </TypographyBox>
                <TypographyBox variant="h5" className={classes.name}>
                  {`${userFullName || null}`}
                </TypographyBox>
              </>
            )}
            <Box>
              <TypographyBox variant="hintText" isEllipsis className={classes.body}>
                {title}
              </TypographyBox>
            </Box>
          </Fragment>
        );
      default:
        return <Box>default</Box>;
    }
    return false;
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.eventWrapper}>
        <Box className={classes.iconWrapper}>
          <i
            className={`icon ${
              TIMELINE_ENTRIES[type] === TIMELINE_ENTRIES.FEEDBACK
                ? TIMELINE_ENTRIES.FEEDBACK[feedbackType].icon
                : TIMELINE_ENTRIES[type].icon
            } ${classes.icon}`}
            style={{color: TIMELINE_ENTRIES[type].color, backgroundColor: TIMELINE_ENTRIES[type].bgColor}}
          />
        </Box>
        <Box className={classes.dateWrapper}>
          <Date startDate={dataTime || createdTime} timeZoneName={timeZoneName} />
        </Box>
        <Box
          ref={rowWrapper}
          className={classes.infoWrapper}
          height={maxHeight}
          style={{cursor: isExpendable ? 'pointer' : 'default'}}
          onClick={() => {
            if (isExpendable) {
              setIsExpanded(!isExpanded);
            }
          }}
        >
          <Box ref={row}>{renderRowByType(TIMELINE_ENTRIES[type].value)}</Box>
        </Box>
      </Box>
      <TimelineStatusLine isLast={props.isLast} alertStatus={alertStatus} top={20} />
    </Box>
  );
};

TimelineEvent.propTypes = {
  timelineEvent: PropTypes.objectOf(PropTypes.any).isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default memo(TimelineEvent);
