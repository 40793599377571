// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import {getHolidayChristmas, getHolidayHalloween} from 'profile/store/selectors';
import HalloweenAnimation from 'common/componentsV2/holiday/halloween/HalloweenAnimation';
import ChristmasAnimation from 'common/componentsV2/holiday/christmas/ChristmasAnimation';

const HolidayAnimation = () => {
  const isHalloweenEnabled = useSelector(getHolidayHalloween);
  const isChristmasEnabled = useSelector(getHolidayChristmas);

  if (isHalloweenEnabled) {
    return <HalloweenAnimation />;
  }
  if (isChristmasEnabled) {
    return <ChristmasAnimation />;
  }

  return null;
};

export default HolidayAnimation;
