// @flow
import React from 'react';
import {get} from 'lodash';
import TileStatElement from 'dashboards/components/TileStatElement';
import {STAT_TILES_VALUES} from 'dashboards/components/modals/statTile/StatTileSettings';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const TileStatTemplate = ({
  tileData,
  metrics,
  isLoading,
  isResize,
  dashboardId,
  nameMeasure,
  actualTileLayout,
  isFullSize,
  isOwnerUser,
  isAnonymous,
  dashboardData,
  isTooMuchData,
}: {
  tileData: Object,
  dashboardId: string,
  metrics: Array,
  isLoading: boolean,
  isResize: boolean,
  isFullSize: boolean,
  nameMeasure: string,
  actualTileLayout: Object,
  isOwnerUser: boolean,
  isAnonymous: boolean,
  dashboardData: Object,
  isTooMuchData: boolean,
}) => {
  const expressionTree = Object.values(tileData.andtGauge.expressionTrees)[0];
  const scalarTransformsType = get(expressionTree, 'scalarTransforms[0].function', 'current');
  const currentValue = get(metrics, `[0].scalarTransforms.${scalarTransformsType}.value`, 0);
  const displayProperties = get(tileData, 'andtGauge.displayProperties.byTreeExp[0].options.andtGauge', EMPTY_OBJECT);
  const tileType = get(displayProperties, 'mainValue.tileType', STAT_TILES_VALUES.NUMBER);
  const isAbbreviate = get(displayProperties, 'mainValue.abbreviate', false);
  const isDecimal = get(displayProperties, 'mainValue.displayDecimal', false);
  const isShowGraph = get(displayProperties, 'mainValue.showGraph', true);
  const isShowMeasure = get(displayProperties, 'mainValue.showMeasure', true);
  const unitText = get(displayProperties, 'mainValue.unitText', '');
  const inputNoData = isTooMuchData ? 'Too many results' : get(displayProperties, 'mainValue.inputNoData', 'No Data');
  const minScale = get(displayProperties, 'mainValue.minScale');
  const maxScale = get(displayProperties, 'mainValue.maxScale');
  const isShowValueLabels = get(displayProperties, 'mainValue.isShowValueLabels', true);
  const thresholds = get(displayProperties, 'mainValue.thresholds', EMPTY_ARRAY);
  const showLegend = get(tileData, 'andtGauge.legend.enabled', false);

  return (
    <TileStatElement
      dashboardData={dashboardData}
      dashboardId={dashboardId}
      id={tileData.id}
      tileType={tileType}
      currentValue={currentValue}
      isLoading={isLoading}
      nameMeasure={nameMeasure}
      isShowGraph={isShowGraph}
      title={tileData.title.text}
      isAbbreviate={isAbbreviate}
      isDecimal={isDecimal}
      isShowMeasure={isShowMeasure}
      unitText={unitText}
      isResize={isResize}
      inputNoData={inputNoData}
      metrics={metrics}
      minScale={minScale}
      maxScale={maxScale}
      isShowValueLabels={isShowValueLabels}
      thresholds={thresholds}
      tileData={tileData}
      showLegend={showLegend}
      actualTileLayout={actualTileLayout}
      isFullSize={isFullSize}
      isOwnerUser={isOwnerUser}
      isAnonymous={isAnonymous}
    />
  );
};

export default React.memo(TileStatTemplate);
