// flow
import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {queryErrorHandler} from 'reactQuery/queryClient';
import {getIsPanelOpen, getNumLastDays} from 'insightsPanelNewAlertConsole/store/selectors';
import {getProfileId, getTimeZoneName, getUserProfile} from 'profile/store/selectors';
import {segmentCausingEvent, segmentClickEvent} from 'common/store/actions';
import {setAlertFeedbackPost as setFeedback} from 'feedback/services/api';
import {FEEDBACK_ORIGIN, FEEDBACK_TYPE} from 'feedback/services/constants';
import {Carousel} from 'react-responsive-carousel';
import MetricName from 'metrics/components/metricName/MetricName';
import AnomalyAlertDelta from 'alerts.console.new/components/alertsList/alertContent/anomalyAlert/AnomalyAlertDelta';
import AlertDate from 'alerts.console.new/components/alertsList/alertContent/AlertDate';
import AlertDuration from 'alerts.console.new/components/alertsList/alertContent/AlertDuration';
import fetchTriggeredAlertsNoFeedbackProvider from 'insightsPanelNewAlertConsole/api/fetchTriggeredAlertsNoFeedbackProvider';
import {getConstRangeByNumberOfLastDays} from 'insightsPanelNewAlertConsole/api/utils';
import {formatNoFeedbackAlerts} from 'insightsPanelNewAlertConsole/services/constants';
import {FEEDBACK_ACTION_TYPES} from 'alerts.console.new/api/triggeredAlertsCacheUpdate';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import AlertConsoleQueryParamsContext from 'alerts.console.new/context';

import './QuickFeedback.module.scss';

const CAROUSEL_SETTINGS = {
  showArrows: false,
  showIndicators: false,
  infiniteLoop: false,
  showThumbs: false,
  showStatus: false,
  transitionTime: 400,
};

const ALERT_INFO_WRAPPER_CLOSE = 80;

const QuickFeedback = ({title}: {title: string}) => {
  const dispatch = useDispatch();
  const meId = useSelector(getProfileId);
  const meUser = useSelector(getUserProfile);
  const numLastDays = useSelector(getNumLastDays);
  const timeZoneName = useSelector(getTimeZoneName);
  const isPanelOpen = useSelector(getIsPanelOpen);

  const {contextQueryParams} = useContext(AlertConsoleQueryParamsContext);

  const constRange = useMemo(() => {
    return getConstRangeByNumberOfLastDays(numLastDays?.value)?.constRange;
  }, [numLastDays?.value]);

  const noFeedbackQueryParams = {
    subscribers: meId,
    loginId: meId,
    numAlerts: 3,
    constRange,
  };
  const triggeredAlertsNoFeedbackProvider = fetchTriggeredAlertsNoFeedbackProvider(noFeedbackQueryParams)?.useQuery();
  const isLoading = triggeredAlertsNoFeedbackProvider?.isLoading;

  const triggeredAlertsProvider = fetchTriggeredAlertsProvider(contextQueryParams);

  const triggeredAlertsNoFeedbackData = useMemo(() => {
    let triggeredAlertsNoFeedback;
    if (!isEmpty(triggeredAlertsNoFeedbackProvider?.data)) {
      triggeredAlertsNoFeedback = formatNoFeedbackAlerts(triggeredAlertsNoFeedbackProvider?.data);
    }
    return triggeredAlertsNoFeedback;
  }, [triggeredAlertsNoFeedbackProvider?.data]);

  const [firstLaunch, setFirstLaunch] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [tmpNoFeedbackAlerts, setTmpNoFeedbackAlerts] = useState([]);
  const [isCompletedTask, setIsCompletedTask] = useState(false);
  const [isMetricInfoActive, setIsMetricInfoActive] = useState(false);
  const [isMetricInfoActive2, setIsMetricInfoActive2] = useState(false); // for timer to set animation
  const [infoWrapperHeight, setInfoWrapperHeight] = useState(ALERT_INFO_WRAPPER_CLOSE);

  let metricInfoTimer;

  const metricWrapper = useRef(null);

  const resetState = () => {
    setTmpNoFeedbackAlerts(triggeredAlertsNoFeedbackData);
    setCurrentSlide(0);
    setIsCompletedTask(false);
    setIsMetricInfoActive(false);
  };

  useEffect(() => {
    if (isLoading === false && triggeredAlertsNoFeedbackData?.length) {
      dispatch(segmentCausingEvent({category: 'Insights Panel', name: 'Quick Feedback - Widget Loaded'}));
    } else if (isLoading === false && !triggeredAlertsNoFeedbackData?.length) {
      dispatch(segmentCausingEvent({category: 'Insights Panel', name: 'Quick Feedback - Widget Not Loaded'}));
    }
  }, [triggeredAlertsNoFeedbackData]);

  useEffect(() => {
    return () => {
      clearTimeout(metricInfoTimer);
    };
  }, [isPanelOpen]);

  useEffect(() => {
    if (triggeredAlertsNoFeedbackData?.length && firstLaunch) {
      resetState();
      setFirstLaunch(false);
    }
  }, [isLoading, triggeredAlertsNoFeedbackData, firstLaunch]);

  useEffect(() => {
    if (isMetricInfoActive) {
      setInfoWrapperHeight(metricWrapper.current.offsetHeight + (ALERT_INFO_WRAPPER_CLOSE + 15));
      metricInfoTimer = setTimeout(() => setIsMetricInfoActive2(true), 100);
    } else {
      setInfoWrapperHeight(ALERT_INFO_WRAPPER_CLOSE);
      setIsMetricInfoActive2(false);
      clearTimeout(metricInfoTimer);
    }
  }, [isMetricInfoActive]);

  const showMetricInfo = (e) => {
    if (e) {
      setIsMetricInfoActive(!isMetricInfoActive);
      if (!isMetricInfoActive) {
        dispatch(segmentClickEvent({category: 'Insights Panel', name: 'Quick Feedback - Show metric info'}));
      }
    } else {
      setIsMetricInfoActive(false);
    }
  };

  const nextSlide = () => {
    if (currentSlide + 1 === tmpNoFeedbackAlerts?.length) {
      setIsCompletedTask(true);
      dispatch(segmentClickEvent({category: 'Insights Panel', name: 'Quick Feedback - All Done'}));
    } else {
      setCurrentSlide(currentSlide + 1);
      dispatch(segmentClickEvent({category: 'Insights Panel', name: 'Quick Feedback - Skip'}));
    }
    showMetricInfo();
  };

  const setFeedbackAction = async (triggeredAlert, feedbackType) => {
    const params = {
      type: feedbackType,
      triggerIds: [triggeredAlert?.alertTriggerId],
      userId: meId,
      userName: `${meUser.firstName} ${meUser.lastName}` || 'Unknown',
      origin: FEEDBACK_ORIGIN.INSIGHT_PANEL,
      id: undefined,
    };
    triggeredAlertsProvider.syncFeedbackInCache(triggeredAlert?.alertTriggerId, FEEDBACK_ACTION_TYPES.SET, params);
    try {
      const res = await setFeedback({payload: params}).promise;
      if (res?.id) {
        params.feedbackId = res.id;
        triggeredAlertsProvider.syncFeedbackInCache(triggeredAlert?.alertTriggerId, FEEDBACK_ACTION_TYPES.SET, params);
      }
    } catch (e) {
      triggeredAlertsProvider.syncFeedbackInCache(triggeredAlert?.alertTriggerId, FEEDBACK_ACTION_TYPES.DELETE, params);
      const error = {
        title: 'Quick Feedback error',
        description: `Error while setting quick feedback`,
      };
      queryErrorHandler(error);
    }
  };

  const setQuickFeedback = async (type) => {
    const triggeredAlert = tmpNoFeedbackAlerts[currentSlide];

    await setFeedbackAction(triggeredAlert, type);
    dispatch(segmentClickEvent({category: 'Insights Panel', name: `Quick Feedback - ${type}`}));

    nextSlide();
  };

  const renderMetricInfo = () => {
    // we don't want to open all the info containers at once, so we do it one by one.
    const alert = tmpNoFeedbackAlerts[currentSlide];

    return (
      <div ref={metricWrapper} styleName={isMetricInfoActive2 ? 'metric-dec-wrapper active' : 'metric-dec-wrapper'}>
        <div styleName="metric-measure-dimensions">
          <MetricName metric={alert.metrics[0]} />
        </div>
        <div styleName="metric-delta">
          <AnomalyAlertDelta metric={alert.metrics[0]} />
        </div>
      </div>
    );
  };

  return (
    !isLoading &&
    !!tmpNoFeedbackAlerts?.length && (
      <div styleName="body gray">
        <header styleName="header">
          {title} ({currentSlide + 1}/{tmpNoFeedbackAlerts.length})
        </header>

        <div styleName="container">
          {isCompletedTask && (
            <div styleName="completed-task-wrapper">
              <span role="img" aria-label="complete">
                👌
              </span>
              <p>You’re all done for now, Thank you for your feedback!</p>
            </div>
          )}

          {!isCompletedTask && (
            <>
              <p styleName="title">Was this alert a good catch?</p>
              <Carousel {...CAROUSEL_SETTINGS} selectedItem={currentSlide}>
                {tmpNoFeedbackAlerts.map((alert) => (
                  <div
                    style={{height: `${infoWrapperHeight}px`}}
                    styleName="alert-info-wrapper"
                    key={alert.alertTriggerId}
                  >
                    <p styleName="alert-title">{alert.title}</p>
                    {isMetricInfoActive && renderMetricInfo()}
                    <div styleName="alert-dec-wrapper">
                      <div styleName="alert-timing-wrapper">
                        <div styleName="start">
                          <span>Started</span> <AlertDate startDate={alert.startTime} timeZone={timeZoneName} />
                        </div>
                        <div styleName="duration">
                          <span>Lasted</span> <AlertDuration duration={alert.duration} />
                        </div>
                      </div>
                      <p styleName="alert-measure" onClick={showMetricInfo}>
                        {alert?.metrics[0]?.what}
                      </p>
                    </div>
                  </div>
                ))}
              </Carousel>
              <div styleName="feedback-wrapper">
                <div onClick={() => setQuickFeedback(FEEDBACK_TYPE.GOOD_CATCH)}>
                  <span role="img" aria-label="good-catch">
                    👍
                  </span>
                  <p>Good Catch</p>
                </div>
                <div onClick={() => setQuickFeedback(FEEDBACK_TYPE.NOT_INTERESTING)}>
                  <span role="img" aria-label="not-interesting">
                    👎
                  </span>
                  <p>Not Interesting</p>
                </div>
                <div onClick={nextSlide}>
                  <p>Skip</p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    )
  );
};

export default QuickFeedback;
