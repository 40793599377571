// @flow
import React from 'react';
import {FILTER_TYPES_ARR} from 'bc/services/gsearchService';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

import './Filters.module.scss';

type PropTypes = {
  filter: Object,
  onChange: Function,
  onRemove: Function,
  index: number,
  disabled: boolean,
  dimensions: Array,
};

export default class FilterItem extends React.PureComponent {
  props: PropTypes;

  render() {
    const {filter, onChange, onRemove, index, disabled, dimensions} = this.props;

    const dimensionsChanged = (d) => {
      onChange({
        value: d.sourceColumn,
        target: 'key',
        index,
      });
    };

    const filterChanged = (d) => {
      onChange({
        value: d.id,
        target: 'op',
        index,
      });
    };

    const inputValueChanged = (e) => {
      onChange({
        value: e.target.value,
        target: 'value',
        index,
      });
    };

    const removeItem = () => {
      onRemove(index);
    };

    const selectedDimension = dimensions.find((dim) => dim.sourceColumn === filter.key) || null;
    const selectedFilter = FILTER_TYPES_ARR.find((i) => i.id === filter.op) || null;

    return (
      <div styleName="advance-root ga4-select-block">
        <i styleName="remove-item" className="icon icn-icon-table-delete" onClick={removeItem} />
        <div styleName="dim-wrapper">
          <SelectAndt
            type={TYPE_NO_SEARCH}
            theme={THEME_LIGHT}
            onChange={dimensionsChanged}
            options={dimensions}
            value={selectedDimension}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.sourceColumn}
            isCreatable
            automationId="filterDimension"
            optionHeight={40}
            placeholder="Filter Dimension"
          />
        </div>

        <div styleName="txt-wrapper">
          <SelectAndt
            type={TYPE_NO_SEARCH}
            theme={THEME_LIGHT}
            onChange={filterChanged}
            options={FILTER_TYPES_ARR}
            value={selectedFilter}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.id}
            isCreatable
            automationId="filterType"
            optionHeight={40}
            placeholder="Filter Type"
          />
        </div>

        <div styleName="val-wrapper">
          <input
            type="text"
            disabled={disabled}
            onChange={inputValueChanged}
            value={filter.value || ''}
            automation-id="filterValues"
            placeholder="Filter Value"
          />
        </div>
        <div styleName="seperator" />
      </div>
    );
  }
}
