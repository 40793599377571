// @flow
import React, {useMemo} from 'react';
import {useField} from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import DashboardTitle from 'dashboards/components/fields/DashboardTitle';
import DateRanges from 'dashboards/components/fields/DateRanges';
import Button, {COLORS} from 'common/componentsV2/Button';
import {allowedUserTileActions, dashboardActions, OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';
import AsyncButton from 'common/componentsV2/AsyncButton';
import {DropdownTiles} from 'dashboards/components/DropdownTiles';
import SelectAndt, {
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import OptionWithIcon from 'common/componentsV2/ddl/selectAndt/innerComponents/OptionWithIcon';
import AutoRefreshContainer from 'dashboards/components/AutoRefreshContainer';
import {getCleanDashboardForExport} from 'dashboards/utils';
import {displayDashboardSnackBar} from 'dashboards/store/actions';
import {isReadOnlyUser} from 'profile/store/selectors';

const useStyles = makeStyles(({palette}) => ({
  header: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexShrink: 0,
    '@global': {
      '.andt-dropdown-control': {
        minHeight: 35,
      },
      '.andt-dropdown-value-container': {
        height: 35,
      },
    },
  },
  squareButton: {
    padding: '10px',
    height: 35,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: palette.gray[300],
    borderRadius: '6px',
  },
  cancelEditMode: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 32,
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: '16px',
    color: palette.gray[500],
    lineHeight: '16px',
  },
  timeFrameLabel: {
    marginRight: '12px',
    fontWeight: 500,
    color: palette.gray[400],
    whiteSpace: 'nowrap',
  },
  dashboardName: {
    color: palette.gray[600],
    fontWeight: 400,
    fontSize: '30px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

type PropsType = {
  history: Object,
  invitationId: string,
  dashboardData: Object,
  matchUrl: string,
  handleSaveEdit: Function,
  isLoading: boolean,
  isOwnerUser: boolean,
  values: Object,
  onReset: Function,
};

const DashboardHeader = ({
  history,
  invitationId,
  dashboardData,
  matchUrl,
  handleSaveEdit,
  isLoading,
  isOwnerUser,
  values,
  onReset,
}: PropsType) => {
  const classes = useStyles();
  const isReadOnlySelector = useSelector(isReadOnlyUser);
  const dispatch = useDispatch();
  const {
    input: {value: isEditableMode, onChange: setEditableMode},
  } = useField('isEditableMode');

  const computedDashboardOptions = useMemo(
    () =>
      dashboardActions
        .filter((option) => option.value !== 'convert' && (option.value !== 'revert' || dashboardData.v1ID))
        .map((option) => {
          if (!isOwnerUser && !allowedUserTileActions.includes(option.value)) {
            return {...option, disabled: true};
          }
          return option;
        }),
    [isOwnerUser],
  );

  const handleSelect = (option) => {
    if (option.value === OPTION_TILES_VALUES.IMPORT) {
      const cleanDashboard = getCleanDashboardForExport(dashboardData, isOwnerUser, values);
      const dataStr = JSON.stringify(cleanDashboard, null, 2);
      const dataUri = `data:application/json;charset=utf-8,${encodeURIComponent(dataStr)}`;
      const exportFileDefaultName = `${dashboardData.name}.json`;

      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', dataUri);
      linkElement.setAttribute('download', exportFileDefaultName);
      linkElement.click();

      dispatch(
        displayDashboardSnackBar({
          title: 'Dashboard exported successfully!',
          description: 'You can use the exported file and create a dashboard from it.',
        }),
      );
    } else {
      history.push(`${matchUrl}/${option.value}${history.location.search}`);
    }
  };

  const handleCancelEditMode = () => {
    onReset();
    setEditableMode(!isEditableMode);
  };

  return (
    <header className={classes.header}>
      <div className="display_flex flexGrow_1">
        <div className="mr_1-5">
          {invitationId ? (
            <div className={classes.dashboardName}>{dashboardData.name}</div>
          ) : (
            <DashboardTitle dashboardData={dashboardData} history={history} />
          )}
        </div>
      </div>
      <div className="display_flex alignItems_center flexShrink_0">
        <div>
          <div className="mr_1-5 display_flex alignItems_center">
            <div className={classes.timeFrameLabel}>Time Frame</div>
            <DateRanges isFooterComponent position={invitationId ? 'right' : 'left'} />
          </div>
        </div>
        {!isEditableMode && (
          <div className="mr_1-5">
            <AutoRefreshContainer />
          </div>
        )}
        {!isEditableMode && !invitationId && !isReadOnlySelector && (
          <div className="mr_1-5">
            <Button
              automationId="dashboardShare"
              colorSchema={COLORS.GRAY_300}
              extraClassName={classes.squareButton}
              onClick={() => history.push(`${matchUrl}/share${history.location.search}`)}
              icon="icn-Share24"
            />
          </div>
        )}
        {!isEditableMode && !invitationId && !isReadOnlySelector && (
          <SelectAndt
            automationId="dashboardSettings"
            type={TYPE_SIMPLE}
            theme={THEME_TRANSPARENT}
            direction={DIRECTION_LEFT}
            offsetLeft={34}
            options={computedDashboardOptions}
            onChange={handleSelect}
            optionHeight={40}
            className="mr_1-5"
            customComponent={{
              Option: OptionWithIcon,
              DropdownIndicator: (p) => (
                <div className={classes.squareButton}>
                  <i {...p.innerProps} className="icon icn-nav16-settings" />
                </div>
              ),
            }}
            closeMenuOnSelect={false}
            appendToBody={false}
          />
        )}
        {!invitationId && isOwnerUser && (
          <DropdownTiles
            onSelect={(value) => history.push(`${matchUrl}/${value}${history.location.search}`)}
            isDisabled={!isEditableMode}
          />
        )}
        {isEditableMode && (
          <div
            automation-id="dashboardCancelEditMode"
            className={classes.cancelEditMode}
            onClick={handleCancelEditMode}
          >
            Cancel
          </div>
        )}
        {isOwnerUser && (
          <div className="ml_1-5" ml={1.5}>
            {isEditableMode ? (
              <AsyncButton
                automationId="dashboardsEditSave"
                text="Save"
                colorSchema="blue.500"
                onClick={handleSaveEdit}
                isLoading={isLoading}
              />
            ) : (
              <Button
                automationId="dashboardsEdit"
                text="Edit"
                colorSchema={COLORS.BLUE_500}
                onClick={() => setEditableMode(!isEditableMode)}
              />
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default DashboardHeader;
