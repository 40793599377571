import {useEffect, useCallback} from 'react';

const inputs = ['input', 'select', 'button', 'textarea'];

const useKeydown = (callback, keyCode) => {
  const cb = useCallback(
    (event) => {
      if (event.keyCode === keyCode && !inputs.includes(document.activeElement.tagName.toLowerCase())) {
        callback(event);
      }
    },
    [event],
  );

  useEffect(() => {
    window.addEventListener('keydown', cb);
    return () => window.removeEventListener('keydown', cb);
  }, [callback]);
};

export default useKeydown;
