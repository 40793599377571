import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {palette} from 'app/styles/theme';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    gap: 23,
    alignItems: 'center',
    padding: '0 8px',
  },
  progressBarWrapper: {
    width: 180,
    borderRadius: 15,
    margin: '0 auto',
    height: 15,
    padding: '2px 3px',
    backgroundColor: palette.blue[300],
    display: 'flex',
    alignItems: 'center',
  },
  progressBarFill: {
    display: 'block',
    maxWidth: '100%',
    borderRadius: 15,
    height: '100%',
    transition: `width 750ms ease-out, background-color 750ms ease-out`,
  },
  progress: {
    fontWeight: 500,
    fontSize: 12,
    color: palette.gray[400],
  },
  progressCounter: {
    color: palette.blue[500],
  },
}));

export default function RecommendationProgressBar({recommendations}: {recommendations: Array}) {
  const classes = useStyles();

  const totalValue = useMemo(() => {
    return recommendations?.length;
  }, [recommendations]);

  const totalTouchedValue = useMemo(() => {
    return recommendations?.filter((r) => r.applied === true).length;
  }, [recommendations]);

  const progressBarPercentage = useMemo(() => {
    return Math.round((totalTouchedValue / totalValue) * 100);
  }, [recommendations]);

  const setDynamicColor = useMemo(() => {
    if (progressBarPercentage === 100) {
      return palette.blue[500];
    }
    return palette.blue[500];
  }, [progressBarPercentage]);

  const setDynamicColorBackground = useMemo(() => {
    if (progressBarPercentage === 100) {
      return palette.blue[300];
    }
    return palette.blue[300];
  }, [progressBarPercentage]);

  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.progressBarWrapper} style={{backgroundColor: setDynamicColorBackground}}>
          <span
            className={classes.progressBarFill}
            style={{width: `${progressBarPercentage}%`, backgroundColor: setDynamicColor}}
          />
        </div>
        <div className={classes.progress}>
          <span className={classes.progressCounter}>{totalTouchedValue}</span> / {totalValue}
        </div>
      </div>
    </div>
  );
}
