import {makeAsyncAction, makeAction} from 'common/utils/simplifiedAsync';

const ACTION_PREFIX = 'anodot/impactNewAlertConsole/';
const mac = (actionName) => makeAction(actionName, ACTION_PREFIX);
const macAsync = (actionName) => makeAsyncAction(actionName, ACTION_PREFIX);

export const isImpactModalOpenNewAlertConsole = mac('IS_IMPACT_MODAL_OPEN_NEW_ALERT_CONSOLE');

export const getSingleImpactNewAlertConsole = macAsync('GET_SINGLE_IMPACT_NEW_ALERT_CONSOLE');
export const getImpactsByOriginNewAlertConsole = macAsync('GET_IMPACTS_BY_ORIGIN_NEW_ALERT_CONSOLE');
export const putImpactNewAlertConsole = macAsync('PUT_IMPACT_NEW_ALERT_CONSOLE');
export const postImpactNewAlertConsole = macAsync('POST_IMPACT_NEW_ALERT_CONSOLE');
export const deleteImpactNewAlertConsole = macAsync('DELETE_IMPACT_NEW_ALERT_CONSOLE');
export const deleteImpactsByOriginNewAlertConsole = macAsync('DELETE_IMPACTS_BY_ORIGIN_NEW_ALERT_CONSOLE');

export const addImpactNewAlertConsole = mac('ADD_IMPACT_NEW_ALERT_CONSOLE');
export const addImpactsNewAlertConsole = mac('ADD_IMPACTS_NEW_ALERT_CONSOLE');
export const setImpactValueNewAlertConsole = mac('SET_IMPACT_VALUE_NEW_ALERT_CONSOLE');
export const setImpactDefaultValuesNewAlertConsole = mac('SET_IMPACT_DEFAULT_VALUES_NEW_ALERT_CONSOLE');
export const updateImpactNewAlertConsole = mac('UPDATE_IMPACT_NEW_ALERT_CONSOLE');
export const createImpactNewAlertConsole = mac('CREATE_IMPACT_NEW_ALERT_CONSOLE');
export const removeImpactNewAlertConsole = mac('REMOVE_IMPACT_NEW_ALERT_CONSOLE');
