import React, {useCallback, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';

import InfoBox from 'dataCollectors/components/infoBox/InfoBox';
import useSectionsClasses from 'dataCollectors/styles/classes.useSectionsClasses';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import useFetchProjects from 'dataCollectors/api/useFetchProjects';
import useFetchDatasets from 'dataCollectors/api/useFetchDatasets';
import useIsMount from 'dataCollectors/hooks/useIsMount';
import useFetchDatasetTables from 'dataCollectors/api/useFetchDatasetTables';
import PreviewTable from 'dataCollectors/components/previewTable/PreviewTable';
import PropTypes from 'prop-types';

const {PROJECT_ID, DATA_SET, TYPE, SOURCE_ID, TABLE_NAME} = EVENT_STREAM_FIELDS;

const {sections} = useSectionsClasses();

export default function StreamContext({info, leftSideWidth}) {
  const sectionsClasses = sections({leftSideWidth});
  const [selectedItemProjects, setSelectedItemProjects] = useState(null);
  const [selectedItemDataSets, setSelectedItemDataSets] = useState(null);
  const [selectedItemTables, setSelectedItemTables] = useState(null);
  const [refetchTablePreview, setRefetchTablePreview] = useState(false);
  const isMount = useIsMount();
  const {
    setValue,
    getValues,
    formState: {errors, touchedFields},
  } = useFormContext();

  const values = getValues();

  const {projects, isProjectsLoading} = useFetchProjects('big-query', values[SOURCE_ID.id]);
  const {datasets, isDatasetsLoading, refetchDatasetsFetching} = useFetchDatasets(
    'big-query',
    values[SOURCE_ID.id],
    values[PROJECT_ID.id],
  );
  const {datasetTables, isDatasetTablesLoading, refetchDatasetTablesFetching} = useFetchDatasetTables(
    'big-query',
    values[SOURCE_ID.id],
    values[PROJECT_ID.id],
    values[DATA_SET.id],
  );

  const setProject = useCallback((item) => {
    setValue(PROJECT_ID.id, item.value, {shouldTouch: true});
  }, []);

  const setDataset = useCallback((item) => {
    setValue(DATA_SET.id, item.value, {shouldTouch: true});
  }, []);
  const setTable = useCallback((item) => {
    setValue(TABLE_NAME.id, item.value, {shouldTouch: true});
  }, []);

  useEffect(() => {
    const selectedIndex = projects.findIndex((i) => i.value === values[PROJECT_ID.id]);
    setSelectedItemProjects(projects[selectedIndex]);
  }, [projects, values[PROJECT_ID.id]]);

  useEffect(() => {
    const selectedIndex = datasets.findIndex((i) => i.value === values[DATA_SET.id]);
    setSelectedItemDataSets(datasets[selectedIndex]);
  }, [datasets, values[DATA_SET.id]]);

  useEffect(() => {
    const selectedIndex = datasetTables.findIndex((i) => i.value === values[TABLE_NAME.id]);
    setSelectedItemTables(datasetTables[selectedIndex]);
  }, [datasetTables, values[TABLE_NAME.id]]);

  useEffect(() => {
    if (touchedFields[PROJECT_ID.id]) {
      if (!isMount) {
        setValue(DATA_SET.id, null, {shouldTouch: true});
      }
      refetchDatasetsFetching();
    }
  }, [values[PROJECT_ID.id]]);

  useEffect(() => {
    if (touchedFields[DATA_SET.id]) {
      if (!isMount) {
        setValue(TABLE_NAME.id, null, {shouldTouch: true});
      }
      refetchDatasetTablesFetching();
    }
  }, [values[DATA_SET.id]]);

  useEffect(() => {
    if (
      values[TYPE.id] &&
      values[SOURCE_ID.id] &&
      values[PROJECT_ID.id] &&
      values[DATA_SET.id] &&
      values[TABLE_NAME.id]
    ) {
      setRefetchTablePreview(!refetchTablePreview);
    }
  }, [values[TABLE_NAME.id]]);

  return (
    <div className={sectionsClasses.root}>
      <div className={sectionsClasses.container}>
        <div className={sectionsClasses.left}>
          <div className={sectionsClasses.title}>Context</div>
          <div className={sectionsClasses.subTitle}>
            Choose the project containing the data you want to load as events.
          </div>
          <div className={sectionsClasses.inputsWrapper}>
            <div className={`${sectionsClasses.container} ${sectionsClasses.marginTop}`}>
              <div className={`${sectionsClasses.wrapperHalfRow} ${sectionsClasses.marginRight}`}>
                <div className={sectionsClasses.label}>Project</div>
                <FormDdlSelect
                  isDisabledSorting
                  options={projects}
                  selected={selectedItemProjects}
                  onChange={setProject}
                  button={
                    <BigDropdownButton
                      isPristine={!selectedItemProjects}
                      label={selectedItemProjects?.label}
                      placeholder={isProjectsLoading ? 'Loading...' : 'Select'}
                      shortBlueStyle
                      isFullWidth
                    />
                  }
                  optionComponent={<OptionComponentSimple />}
                  width={240}
                />
                {errors[PROJECT_ID.id] && !selectedItemProjects && (
                  <span className={sectionsClasses.inputError}>{errors[PROJECT_ID.id]}</span>
                )}
              </div>

              <div className={`${sectionsClasses.wrapperHalfRow} ${sectionsClasses.marginLeft}`}>
                <div className={sectionsClasses.label}>DataSet</div>
                <FormDdlSelect
                  disabled={!selectedItemProjects}
                  isDisabledSorting
                  isUseSearch
                  options={datasets}
                  selected={selectedItemDataSets}
                  onChange={setDataset}
                  button={
                    <BigDropdownButton
                      isPristine={!selectedItemDataSets}
                      label={selectedItemDataSets?.label}
                      placeholder={isDatasetsLoading ? 'Loading...' : 'Select'}
                      shortBlueStyle
                      isFullWidth
                    />
                  }
                  optionComponent={<OptionComponentSimple />}
                  width={240}
                />
                {errors[DATA_SET.id] && !selectedItemDataSets && (
                  <span className={sectionsClasses.inputError}>{errors[DATA_SET.id]}</span>
                )}
              </div>

              <div className={`${sectionsClasses.wrapperHalfRow} ${sectionsClasses.marginLeft}`}>
                <div className={sectionsClasses.label}>Table</div>
                <FormDdlSelect
                  disabled={!selectedItemDataSets}
                  isDisabledSorting
                  isUseSearch
                  options={datasetTables}
                  selected={selectedItemTables}
                  onChange={setTable}
                  button={
                    <BigDropdownButton
                      isPristine={!selectedItemTables}
                      label={selectedItemTables?.label}
                      placeholder={isDatasetTablesLoading ? 'Loading...' : 'Select'}
                      shortBlueStyle
                      isFullWidth
                    />
                  }
                  optionComponent={<OptionComponentSimple />}
                  width={240}
                />
                {errors[TABLE_NAME.id] && !selectedItemTables && (
                  <span className={sectionsClasses.inputError}>{errors[TABLE_NAME.id]}</span>
                )}
              </div>
            </div>
          </div>
          <PreviewTable leftSideWidth={leftSideWidth} reftechPreviewTable={refetchTablePreview} />
        </div>
        {info && <InfoBox title={info.title} body={info.body} />}
      </div>
    </div>
  );
}

StreamContext.defaultProps = {
  info: null,
  leftSideWidth: '100%',
};

StreamContext.propTypes = {
  info: PropTypes.objectOf(PropTypes.string),
  leftSideWidth: PropTypes.number,
};
