import React from 'react';
import ReactDom from 'react-dom';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import moment from 'moment';
import 'moment-timezone';
import momentDurationFormatSetup from 'moment-duration-format';
import EventBus from 'common/utils/EventBus';
import {includes} from 'lodash';
import * as actions from '../actions';

const UNSUPPORRTED_TIMEZONES = ['HST', 'EST', 'ROC', 'GMT+0', 'GMT-0', 'MST'];

// eslint-disable-next-line import/prefer-default-export
export const init = (action$) =>
  action$
    .ofType(actions.appInit.TYPE)
    .do(() => {
      momentDurationFormatSetup(moment);

      const timezones = moment.tz.names();
      window.moment.tz.names = () => timezones.filter((item) => !includes(UNSUPPORRTED_TIMEZONES, item));

      // for ng-react
      window.React = React;
      window.ReactDOM = ReactDom;

      // for angular-react integration
      window.EventBus = EventBus;
    })
    .flatMap(() => {
      return [];
    });
