// @flow
import React from 'react';
import ErrorBoundary from 'errorBoundaries/ErrorBoundary';
import QuickFeedback from './quickFeedback/QuickFeedback';
import OpenAlerts from './openAlerts/OpenAlerts';
import MetricsUsage from './metricsUsage/MetricsUsage';
import FeedbackDistribution from './feedbackDistribution/FeedbackDistribution';
import AlertsTriggered from './alertsTriggered/AlertsTriggered';
import AlertsDistribution from './alertsDistribution/AlertsDistribution';
import FeedbackPerMeasure from './feedbackPerMeasure/FeedbackPerMeasure';
import TopFeedbackers from './topFeedbackers/TopFeedbackers';

import './insightsWidgets.module.scss';

const fallbackElement = (title) => (
  <div styleName="body gray">
    <header styleName="header">{title}</header>
    <h3>Ooops! something went wrong.</h3>
  </div>
);

const InsightsWidgets = ({onFilterAlerts}: {onFilterAlerts: Function}) => (
  <div>
    <header styleName="title-category">
      <i className="icon icn-general24-insight" />
      <h1>Insights</h1>
    </header>
    <ErrorBoundary showFallback fallbackElement={fallbackElement('Open Alerts')}>
      <OpenAlerts title="Open Alerts" onFilterAlerts={onFilterAlerts} />
    </ErrorBoundary>
    <ErrorBoundary showFallback fallbackElement={fallbackElement('Alerts Triggered')}>
      <AlertsTriggered title="Alerts Triggered" />
    </ErrorBoundary>
    <ErrorBoundary showFallback fallbackElement={fallbackElement('Alerts Distribution')}>
      <AlertsDistribution title="Alerts Distribution" onFilterAlerts={onFilterAlerts} />
    </ErrorBoundary>
    <header styleName="title-category">
      <h2>Feedback</h2>
    </header>
    <ErrorBoundary showFallback fallbackElement={fallbackElement('Feedback Distribution')}>
      <QuickFeedback title="Quick Feedback" />
    </ErrorBoundary>
    <ErrorBoundary showFallback fallbackElement={fallbackElement('Feedback Distribution')}>
      <FeedbackDistribution title="Feedback Distribution" onFilterAlerts={onFilterAlerts} />
    </ErrorBoundary>
    <ErrorBoundary showFallback fallbackElement={fallbackElement('Feedback Per Measure')}>
      <FeedbackPerMeasure title="Feedback Per Measure" onFilterAlerts={onFilterAlerts} />
    </ErrorBoundary>
    <ErrorBoundary showFallback fallbackElement={fallbackElement('Top Feedbackers')}>
      <TopFeedbackers title="Top Feedbackers" onFilterAlerts={onFilterAlerts} />
    </ErrorBoundary>
    <header styleName="title-category">
      <h2>Usage</h2>
    </header>
    <ErrorBoundary showFallback fallbackElement={fallbackElement('Metrics Usage')}>
      <MetricsUsage title="Metrics Usage" />
    </ErrorBoundary>
  </div>
);

export default InsightsWidgets;
