import {palette} from 'app/styles/theme';

const commonStyle = {
  container: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
  },
  impactValue: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  iconWrapper: {
    marginRight: 2,
    fontSize: 14,
    color: palette.gray['500'],
  },
  icon: {
    position: 'relative',
    top: 2,
    marginRight: 1,
    fontSize: 16,
  },
  text: {
    fontSize: 14,
    fontWeight: '500',
    color: palette.gray['500'],
    marginRight: 2,
  },
};

const createTheme = (themeName) => {
  const tiny = (direction, isPositive) => ({
    container: {
      ...commonStyle.container,
      marginLeft: 8,
      marginTop: -2,
    },
    impactValue: {
      ...commonStyle.impactValue,
      padding: '0 3px',
      marginTop: 2,
      borderRadius: 2,
      fontSize: 12,
      fontWeight: 500,
      flexDirection: direction === 'rtl' ? 'row-reverse' : '',
      background: isPositive ? palette.green[50] : palette.red[200],
      color: isPositive ? palette.green[500] : palette.tomato[500],
    },
    iconWrapper: {
      ...commonStyle.iconWrapper,
    },
    icon: {
      ...commonStyle.icon,
    },
    text: {
      ...commonStyle.text,
    },
  });

  const small = (direction, isPositive) => ({
    container: {
      ...commonStyle.container,
    },
    impactValue: {
      ...commonStyle.impactValue,
      padding: '2px 4px',
      borderRadius: 5,
      fontSize: 16,
      fontWeight: 400,
      flexDirection: direction === 'rtl' ? 'row-reverse' : '',
      background: isPositive ? palette.green[50] : palette.red[200],
      borderColor: isPositive ? palette.green[500] : palette.tomato[500],
      borderStyle: 'solid',
      borderWidth: 2,
      color: palette.gray['500'],
    },
    iconWrapper: {
      ...commonStyle.iconWrapper,
    },
    icon: {
      ...commonStyle.icon,
    },
    text: {
      ...commonStyle.text,
    },
  });

  const medium = (direction, isPositive) => ({
    container: {
      ...commonStyle.container,
      borderColor: isPositive ? palette.green['500'] : palette.tomato['500'],
      borderStyle: 'solid',
      borderWidth: 1,
      backgroundColor: palette.white['500'],
      borderRadius: 5,
      padding: '2px 6px',
    },
    impactValue: {
      ...commonStyle.impactValue,
      fontSize: 18,
      fontWeight: 500,
      flexDirection: direction === 'rtl' ? 'row-reverse' : '',
      color: isPositive ? palette.green['500'] : palette.tomato['500'],
    },
    iconWrapper: {
      ...commonStyle.iconWrapper,
    },
    icon: {
      ...commonStyle.icon,
      color: isPositive ? palette.green['500'] : palette.tomato['500'],
    },
    text: {
      ...commonStyle.text,
      color: isPositive ? palette.green['500'] : palette.tomato['500'],
      fontSize: 18,
      marginRight: 4,
    },
  });

  const themes = {tiny, small, medium};

  return themes[themeName];
};

const themesOptions = {tiny: 'tiny', small: 'small', medium: 'medium'};

export {createTheme, themesOptions};
