// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import * as filterSelector from 'alerts.management/store/filterSelector';
import {segmentClickEvent} from 'common/store/actions';
import {getIsDataStreamsLoading} from 'bc/store/selectors';

type PropTypes = {
  setQueryParams: Function,

  // connect
  streamNameList: Array,
  selectedStreamNames: Array,
  segmentClickEvent: Function,
  isDataStreamsLoading: boolean,
};

export default connect(
  (state) => ({
    streamNameList: filterSelector.getFilterStreamNameList(state),
    selectedStreamNames: filterSelector.getSelectedFilterStreamNames(state),
    isDataStreamsLoading: getIsDataStreamsLoading(state),
  }),
  {
    segmentClickEvent,
  },
)(
  class DataStreamFilter extends React.PureComponent {
    props: PropTypes;

    onChange = (items) => {
      const streamVal = items.length ? items.map((item) => item.value).join(',') : undefined;
      this.props.segmentClickEvent({category: 'alert-manager', name: `filter-stream-${streamVal}`});
      this.props.setQueryParams({stream: streamVal});
    };

    render() {
      const {selectedStreamNames, streamNameList} = this.props;
      return (
        <SelectAndt
          type={TYPE_NEW_MULTI}
          theme={THEME_HIGHLIGHTED}
          onChange={this.onChange}
          options={streamNameList}
          value={selectedStreamNames}
          isMulti
          placeholder="Select"
          optionHeight={40}
          menuWidth={270}
          isClearable={false}
          closeMenuOnSelect={false}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.value}
          automationId="dataStreamFilter"
          isLoading={this.props.isDataStreamsLoading}
          disabled={this.props.isDataStreamsLoading}
        />
      );
    }
  },
);
