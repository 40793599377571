import React from 'react';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import {RECOMMENDATIONS_COLUMN_HEADERS, RECOMMENDATIONS_ORDER} from 'recommendations/services/consts';

import './RecommendationItemHeader.module.scss';

const RecommendationsTableHeader = ({
  offset,
  setRecommendationsListOrder,
  order,
}: {
  offset: Number,
  order: Object,
  setRecommendationsListOrder: Function,
}) => {
  const handleTitleOrderChange = (columnName) => {
    const {column, direction} = order;
    let updatedDirection = RECOMMENDATIONS_ORDER.DESC;
    const sortColumn = Object.values(RECOMMENDATIONS_COLUMN_HEADERS)?.find((f) => f.name === columnName);
    if (sortColumn?.propertyName === column) {
      updatedDirection =
        direction === RECOMMENDATIONS_ORDER.DESC ? RECOMMENDATIONS_ORDER.ASC : RECOMMENDATIONS_ORDER.DESC;
    }
    setRecommendationsListOrder({column: sortColumn.propertyName, direction: updatedDirection});
  };

  return (
    <div styleName="container list-header" style={{marginRight: offset}}>
      {Object.values(RECOMMENDATIONS_COLUMN_HEADERS).map((field) => (
        <Tooltip key={`field-${field.id}`} content={field.tooltip} hideIfEqualTo={null} type={TYPES.SMALL}>
          <div
            styleName={['header-field', field.name, order.column === field.propertyName ? 'ordered-by' : null].join(
              ' ',
            )}
          >
            <TitleHeaderTableOrder
              onChange={handleTitleOrderChange}
              title={field.title}
              selectedDirection={order.direction}
              columnName={field.name}
              isSortable={field.isSortable}
              isActive={field.propertyName === order.column}
            />
          </div>
        </Tooltip>
      ))}
    </div>
  );
};

export default RecommendationsTableHeader;
