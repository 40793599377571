import {useQuery} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';
import {queryClient} from 'reactQuery/queryClient';

const alertsConfigurationRoot = 'api/v1/alerts/';

const fetchConfigurationAlertById = (alertId) => {
  return get(`${alertsConfigurationRoot}${alertId}`);
};

export default function fetchConfigurationAlertByIdProvider(alertConfigurationId) {
  const queryKey = [QUERY_KEYS.configurationAlertById, alertConfigurationId];
  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchQuery: (alertConfigId) => {
      queryKey[1] = alertConfigId;
      return queryClient.fetchQuery(queryKey, () => fetchConfigurationAlertById(alertConfigId));
    },
    useQuery: () =>
      useQuery(queryKey, () => fetchConfigurationAlertById(alertConfigurationId), {
        retry: false,
      }),
  };
}
