// @flow
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {segmentClickEvent} from 'common/store/actions';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {ReactComponent as EmptyIcon} from 'app/images/empty-dropdown.svg';
import {delay, isEqual, isString} from 'lodash';
import {palette} from 'app/styles/theme';
import FormDdl from './FormDdl';
import './FormDdlSelect.module.scss';
import FormDdlSearchBar from './FormDdlSearchBar';
import Spinner, {SIZES} from '../../Spinner';

type PropTypes = {
  options: Array<Object>,
  selected: any,
  button: Node,
  optionComponent: Node,
  onChange: Function,
  width: number,
  maxWidth: number,
  height: number,
  disabled: boolean,
  isMulti: boolean,
  isUseSearch: boolean,
  toggleOpen: boolean, // this value, when changed, doesn't matter
  // if from true to false or false to true, will open the DD
  isFlexibleDDL: boolean,
  placement: string,
  popoverContainerClassName: string,
  buttonWidth: number,
  isLoading: boolean,
  onParentSearchChange: Function,
  onParentSearchValue: string,
  onHoverOpenMenu: boolean,
  heightEmptyDDL: number,
  footerComponent: Node,
  position: String,
  onToggle: Function,
  automationId: String,
  id: String,
  specialCharacterLastSorting: string,
  isDisabledSorting: boolean,
  sortAlphabetic: boolean,
  horizontalOffset: number,
  isOpen: boolean,
  verticalOffset: number,
  checkSelectedByProp: string,
  onOpen: Function,
  setSearchedOptions: Function,
};

const FormDdlSelect = (props: PropTypes) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(props.isOpen || false);
  const [highlightText, setHighlightText] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(props.options);
  // When isUseSearch is true - the SearchBar will be displayed for single or multi select.
  // Specifically for the multi select menus - the search is enabled, unless specified otherwise.
  const isUseSearch = typeof props.isUseSearch !== 'undefined' ? props.isUseSearch : props.isMulti;

  const findIndexSelected = (option) => {
    if (props.checkSelectedByProp && props.checkSelectedByProp !== '') {
      return (props.selected || []).findIndex(
        (s) => s[props.checkSelectedByProp] === option[props.checkSelectedByProp],
      );
    }
    return (props.selected || []).findIndex((s) => s === option);
  };

  const isSelected = (option) => {
    if (props.isMulti) {
      if (props.checkSelectedByProp && props.checkSelectedByProp !== '') {
        return (
          (props.selected || []).findIndex((s) => s[props.checkSelectedByProp] === option[props.checkSelectedByProp]) >
          -1
        );
      }
      return (props.selected || []).findIndex((s) => s === option) > -1;
    }

    return isEqual(props.selected, option);
  };

  const searchChanged = (highlight, items) => {
    const f = items?.filter((o) => {
      if (o.type === 'HEADER') {
        return true;
      }

      if (o.label) {
        return o.label?.toLocaleLowerCase()?.indexOf(highlight?.toLocaleLowerCase()) > -1;
      }
      return isString(o) ? o.toLocaleLowerCase()?.indexOf(highlight?.toLocaleLowerCase()) > -1 : false;
    });
    const filtered = f.filter((ele) => {
      if (ele.type !== 'HEADER') {
        return true;
      }
      return f.findIndex((element) => element.type === ele.value) > -1;
    });
    setFilteredOptions(filtered);
  };

  const orderList = (val) => {
    const {options, isMulti, selected, specialCharacterLastSorting, sortAlphabetic = false} = props;
    if (options && options.length && isMulti && selected && !props.isDisabledSorting) {
      const f = [...options];
      f.sort((a, b) => {
        const aIsSelected = isSelected(a);
        const bIsSelected = isSelected(b);
        if (aIsSelected && !bIsSelected) {
          return -1;
        }
        if (bIsSelected && !aIsSelected) {
          return 1;
        }
        if (sortAlphabetic && ((aIsSelected && bIsSelected) || (!aIsSelected && !bIsSelected))) {
          if (typeof specialCharacterLastSorting === 'undefined') {
            return a > b ? 1 : -1;
          }
          if (b.charAt(0) === specialCharacterLastSorting && a.charAt(0) !== specialCharacterLastSorting) {
            return -1;
          }
          if (a.charAt(0) === specialCharacterLastSorting && b.charAt(0) !== specialCharacterLastSorting) {
            return 1;
          }
          return a > b ? 1 : -1;
        }
        return 0;
      });
      // If text was typed in the searchbar of the selection form - need to filter the items that will be displayed.
      const value = val || '';
      searchChanged(value, f);
    }
  };

  const onSearchChange = (val) => {
    setHighlightText(val);
    if (props.isMulti) {
      orderList(val);
    } else {
      // If text was typed in the searchbar of the selection form - need to filter the items that will be displayed.
      searchChanged(val, props.options);
    }
  };

  useEffect(() => {
    if (props.onToggle) {
      props.onToggle(isOpen);
    }
    if (isUseSearch) {
      setHighlightText('');
      orderList('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (props.setSearchedOptions) props.setSearchedOptions(filteredOptions.filter((o) => o.type !== 'HEADER'));
  }, [filteredOptions]);

  useEffect(() => {
    setFilteredOptions(props.options);
    orderList(highlightText);
    // The conditions for this dependency is designed to support the functionality in custom time frame
    // where if you add new custom time frame, it should be placed on top and not sort the list
    // so only in DateRangeDDL we have the isDisabledSorting flag turned on
  }, [props.isDisabledSorting ? props.options : props.options?.length]);

  useEffect(() => {
    orderList(highlightText);
  }, [props.selected]);

  useEffect(() => {
    if (typeof props.toggleOpen !== 'undefined') {
      setOpen(true); // Changing toggleOpen, never mind if true or false, will open the menu;
    }
  }, [props.toggleOpen]);

  const toggleOpen = (isHover) => {
    if (!isOpen) {
      orderList(highlightText);
    }
    if (props.onHoverOpenMenu) {
      delay(() => setOpen(isHover), 200);
      return;
    }
    if (!isOpen && props.onOpen) {
      props.onOpen();
    }
    setOpen(!isOpen);
  };

  const onClick = (e, val) => {
    e.stopPropagation();
    dispatch(
      segmentClickEvent({
        type: 'option-selected',
        name: props.automationId || 'form ddl',
        value: val,
        automationId: props.automationId,
      }),
    );
    if (props.isMulti) {
      if (val.type === 'HEADER') {
        return;
      }
      const index = findIndexSelected(val);
      if (isSelected(val)) {
        props.onChange((props.selected || []).slice(0, index).concat(props.selected.slice(index + 1)));
      } else {
        props.onChange([...props.selected, val]);
      }
    } else {
      props.onChange(val);
      if (val.type !== 'HEADER') {
        setOpen(false);
      }
    }
  };

  const mapKey = (option) => {
    if (typeof option === 'string') {
      return option;
    }
    if (typeof option.label === 'string' || typeof option.text === 'string') {
      return option.startDate || option.relativeLast || option.value;
    }
    return null;
  };

  const nothingToShow = (
    <div styleName="empty-icon-container" style={{height: `${props.height || props.heightEmptyDDL || 260}px`}}>
      <EmptyIcon width={101} height={71} />
      <TypographyBox variant="subtitle2" marginTop={2}>
        {highlightText.length > 0 ? 'Nothing Found' : 'Nothing to Show'}
      </TypographyBox>
    </div>
  );

  const popOverComponent = (
    <div className="form-menu alert-clickable-item" styleName="pop-over-container">
      {isUseSearch && (
        <FormDdlSearchBar
          isAutofocus
          value={props.onParentSearchValue ? props.onParentSearchValue : highlightText}
          onChange={props.onParentSearchChange ? props.onParentSearchChange : onSearchChange}
          automationId={`${props.automationId}SearchBar`}
        />
      )}
      {filteredOptions?.length ? (
        <div
          styleName={`scroll-container ${props.isFlexibleDDL ? 'flexible' : ''}`}
          style={{height: props.height ? props.height : undefined}}
        >
          {filteredOptions.map((option) => (
            <div
              automationId={`${props.automationId}Option`}
              key={mapKey(option)}
              onClick={(e) => {
                if (option && !option.isDisabled) {
                  onClick(e, option);
                }
              }}
            >
              {React.cloneElement(props.optionComponent, {
                data: option,
                isSelected: isSelected(option),
              })}
            </div>
          ))}
          {filteredOptions.length === 1 && filteredOptions[0].isCreateMode && nothingToShow}
        </div>
      ) : (
        nothingToShow
      )}
      <div styleName="footer-component">{props.footerComponent}</div>
    </div>
  );

  const loading = (
    <div className="form-menu" styleName="pop-over-container is-loading">
      <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
    </div>
  );

  let placement = 'bottom';
  if (props.placement) {
    // eslint-disable-next-line prefer-destructuring
    placement = props.placement;
  }
  return (
    <FormDdl
      popoverComponent={props.isLoading ? loading : popOverComponent}
      buttonComponent={props.button}
      popoverContainerClassName={props.popoverContainerClassName}
      onToggle={toggleOpen}
      isOpen={isOpen}
      width={props.width}
      disabled={props.disabled}
      maxWidth={props.maxWidth}
      placement={placement}
      buttonWidth={props.buttonWidth}
      onHoverOpenMenu={props.onHoverOpenMenu}
      position={props.position}
      automationId={props.automationId}
      id={props.id}
      horizontalOffset={props.horizontalOffset}
      verticalOffset={props.verticalOffset}
    />
  );
};

export default FormDdlSelect;
