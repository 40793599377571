// @flow
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AutoSizer, List} from 'react-virtualized';
import Loader from 'common/components/Loader';
import {isNil, cloneDeep, differenceBy} from 'lodash';
import SearchBox from 'common/components/SearchBox';
import MetricName from 'metrics/components/metricName/MetricName';
import {ALERT_TYPES as ANOMALY_TYPES} from 'alerts.console.new/services/alertsConsoleService';
import fetchTriggeredAlertMetricsProvider from 'alerts.console.new/api/fetchTriggeredAlertMetricsProvider';
import AlertStatusIcon from '../alertContent/AlertStatusIcon';

import './SnoozeActionMetrics.module.scss';

const SnoozeActionMetrics = ({
  trigger,
  queryParams,
  onDataChanged,
}: {
  trigger: Object,
  queryParams: Object,
  onDataChanged: Function,
}) => {
  // The pageNum and pageSize of query params in the url are for the alerts list and are not relevant for metrics.
  // TODO: (IB) - Need to understand which params are relevant for metrics. Maybe need to use a different query params mechanism for each one?
  const {pageNum, pageSize, ...metricsQueryParams} = queryParams ?? {};
  const triggerMetricsResult = fetchTriggeredAlertMetricsProvider(
    trigger?.alertTriggerId,
    metricsQueryParams,
  ).useQuery();
  const {isLoading} = triggerMetricsResult;

  const triggerMetricsOriginal = useMemo(() => {
    return triggerMetricsResult?.data?.triggers || [];
  }, [JSON.stringify(triggerMetricsResult?.data?.triggers)]);

  const listRef = useRef();

  const [metricsFilter, setMetricsFilter] = useState('');

  const [triggerMetricsViewModel, setTriggerMetricsViewModel] = useState([]);

  useEffect(() => {
    setTriggerMetricsViewModel(cloneDeep(triggerMetricsOriginal));
  }, [triggerMetricsOriginal]);

  const [areAllFilteredMetricsSnoozed, setAreAllFilteredMetricsSnoozed] = useState(
    triggerMetricsViewModel?.length === triggerMetricsViewModel?.filter((m) => m?.snooze)?.length,
  );
  const [areAllFilteredMetricsStoppedLearning, setAreAllFilteredMetricsStoppedLearning] = useState(
    triggerMetricsViewModel?.length === triggerMetricsViewModel?.filter((m) => m?.stopLearning)?.length,
  );

  const metricsFilterChanged = useCallback((filter) => {
    setMetricsFilter(filter);
  }, []);

  const filteredMetrics = useMemo(() => {
    if (metricsFilter) {
      return triggerMetricsViewModel?.filter(
        (metric) => metric?.id?.toLowerCase().indexOf(metricsFilter?.toLowerCase()) >= 0,
      );
    }
    return triggerMetricsViewModel;
  }, [JSON.stringify(triggerMetricsViewModel), metricsFilter]);

  const isMetricSnoozed = (metric) => !isNil(metric?.snooze);
  const isMetricStoppedLearning = (metric) => !isNil(metric?.stopLearning);

  const updateState = (metric) => {
    const index = triggerMetricsViewModel?.findIndex((m) => m.id === metric.id);
    if (index > -1) {
      const newArray = [
        ...triggerMetricsViewModel.slice(0, index === 0 ? 0 : index),
        metric,
        ...triggerMetricsViewModel.slice(index + 1),
      ];
      setTriggerMetricsViewModel(newArray);
    }

    const snoozedCount = filteredMetrics.filter((m) => m.snooze).length;
    if (snoozedCount === filteredMetrics?.length) {
      setAreAllFilteredMetricsSnoozed(true);
    } else {
      setAreAllFilteredMetricsSnoozed(false);
    }

    const stopLearningCount = filteredMetrics.filter((m) => m.stopLearning).length;
    if (stopLearningCount === filteredMetrics?.length) {
      setAreAllFilteredMetricsStoppedLearning(true);
    } else {
      setAreAllFilteredMetricsStoppedLearning(false);
    }
  };

  const snoozeCheckboxClicked = (metric) => () => {
    if (metric?.id) {
      const searchedMetric = filteredMetrics?.find((m) => m.id === metric.id);
      if (isMetricSnoozed(metric)) {
        delete searchedMetric.snooze;
      } else if (!isMetricSnoozed(metric)) {
        searchedMetric.snooze = {};
      }
      updateState(searchedMetric);
    }
  };

  const stopLearningCheckboxClicked = (metric) => () => {
    if (metric?.id) {
      const searchedMetric = filteredMetrics?.find((m) => m.id === metric.id);
      if (isMetricStoppedLearning(metric)) {
        delete searchedMetric.stopLearning;
      } else if (!isMetricStoppedLearning(metric)) {
        searchedMetric.stopLearning = {};
      }
      updateState(searchedMetric);
    }
  };

  const onCheckSnoozeAllMetrics = (isChecked) => {
    let newArray;
    if (isChecked) {
      newArray = triggerMetricsViewModel.map((metric) =>
        filteredMetrics.find((m) => m.id === metric.id) ? {...metric, snooze: {}} : metric,
      );
    } else {
      newArray = triggerMetricsViewModel.map((metric) => {
        const searchedMetric = filteredMetrics.find((m) => m.id === metric.id);
        if (searchedMetric?.snooze) {
          const {snooze, ...rest} = searchedMetric;
          return rest;
        }
        return searchedMetric;
      });
    }
    setTriggerMetricsViewModel(newArray);
    setAreAllFilteredMetricsSnoozed(isChecked);
  };

  const onCheckStopLearningAllClick = (isChecked) => {
    let newArray;
    if (isChecked) {
      newArray = triggerMetricsViewModel.map((metric) =>
        filteredMetrics.find((m) => m.id === metric.id) ? {...metric, stopLearning: {}} : metric,
      );
    } else {
      newArray = triggerMetricsViewModel.map((metric) => {
        const searchedMetric = filteredMetrics.find((m) => m.id === metric.id);
        if (searchedMetric?.stopLearning) {
          const {stopLearning, ...rest} = searchedMetric;
          return rest;
        }
        return searchedMetric;
      });
    }
    setTriggerMetricsViewModel(newArray);
    setAreAllFilteredMetricsStoppedLearning(isChecked);
  };

  useEffect(() => {
    if (listRef.current) {
      listRef.current.recomputeRowHeights(0);
    }
  }, [listRef.current]);

  useEffect(() => {
    const snoozedMetricsAddedIds = differenceBy(
      triggerMetricsViewModel.filter((m) => m.snooze),
      triggerMetricsOriginal.filter((m) => m.snooze),
      (metric) => metric.id,
    ).map((metric) => metric.id);

    const snoozedMetricsRemovedIds = differenceBy(
      triggerMetricsViewModel.filter((m) => !m.snooze),
      triggerMetricsOriginal.filter((m) => !m.snooze),
      (metric) => metric.id,
    ).map((metric) => metric.id);

    const stopLearningMetricsAddedIds = differenceBy(
      triggerMetricsViewModel.filter((m) => m.stopLearning),
      triggerMetricsOriginal.filter((m) => m.stopLearning),
      (metric) => metric.id,
    ).map((metric) => metric.id);

    const stopLearningMetricsRemovedIds = differenceBy(
      triggerMetricsViewModel.filter((m) => !m.stopLearning),
      triggerMetricsOriginal.filter((m) => !m.stopLearning),
      (metric) => metric.id,
    ).map((metric) => metric.id);

    return onDataChanged({
      snoozedMetricsAdded: snoozedMetricsAddedIds,
      snoozedMetricsRemoved: snoozedMetricsRemovedIds,
      stopLearningMetricsAdded: stopLearningMetricsAddedIds,
      stopLearningMetricsRemoved: stopLearningMetricsRemovedIds,
    });
  }, [JSON.stringify(triggerMetricsViewModel)]);

  const noRowsRenderer = () => <div styleName="no-results">no metrics found</div>;

  const rowRenderer = (rowInfo) => {
    const metric = filteredMetrics[rowInfo.index];
    const isSnoozed = isMetricSnoozed(metric);
    const isStoppedLearning = isMetricStoppedLearning(metric);

    return (
      <div key={metric?.id} style={rowInfo.style} automation-id="alertConsole" styleName="metric-item">
        <div styleName="metric-wrapper">
          <div styleName="status">
            <AlertStatusIcon status={metric.status} />
          </div>
          <MetricName metric={metric} isSingleLine />
        </div>

        <i
          styleName="snooze-checkbox"
          className={['icon', isSnoozed ? 'icn-icon-checkbox-full' : 'icn-icon-checkbox'].join(' ')}
          onClick={snoozeCheckboxClicked(metric)}
          role="button"
        />

        {trigger.type === ANOMALY_TYPES.ANOMALY && (
          <i
            styleName="stl-checkbox"
            className={['icon', isStoppedLearning ? 'icn-icon-checkbox-full' : 'icn-icon-checkbox'].join(' ')}
            onClick={stopLearningCheckboxClicked(metric)}
            role="button"
          />
        )}
      </div>
    );
  };

  return (
    <div styleName="root">
      <div styleName="metrics-header">Choose Metrics</div>

      <SearchBox filter={metricsFilter} onFilter={metricsFilterChanged} isDisabled={isLoading} />

      <div styleName="metrics">
        <div styleName="metrics-titles">
          <div styleName={trigger.type === ANOMALY_TYPES.ANOMALY ? 'title-wrapper' : 'title-wrapper alone'}>
            <span>Snooze</span>
            <i
              className={['icon', areAllFilteredMetricsSnoozed ? 'icn-icon-checkbox-full' : 'icn-icon-checkbox'].join(
                ' ',
              )}
              onClick={() => onCheckSnoozeAllMetrics(!areAllFilteredMetricsSnoozed)}
              role="button"
            />
          </div>

          {trigger.type === ANOMALY_TYPES.ANOMALY && (
            <div styleName="title-wrapper stl">
              <span>Pause learning</span>
              <i
                className={[
                  'icon',
                  areAllFilteredMetricsStoppedLearning ? 'icn-icon-checkbox-full' : 'icn-icon-checkbox',
                ].join(' ')}
                onClick={() => onCheckStopLearningAllClick(!areAllFilteredMetricsStoppedLearning)}
                role="button"
              />
            </div>
          )}
        </div>

        <div styleName="metrics-list">
          {isLoading && <Loader size="small" />}

          {!isLoading && (
            <AutoSizer>
              {({height, width}) => (
                <List
                  ref={(listParam) => {
                    listRef.current = listParam;
                  }}
                  height={height}
                  rowCount={filteredMetrics.length}
                  rowHeight={38}
                  rowRenderer={rowRenderer}
                  noRowsRenderer={noRowsRenderer}
                  width={width}
                  style={{overflowY: 'scroll'}}
                />
              )}
            </AutoSizer>
          )}
        </div>
      </div>
    </div>
  );
};

export default SnoozeActionMetrics;
