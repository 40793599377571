/* eslint-disable */
import React, {Fragment, useState, useMemo, useEffect} from 'react';
import './IntegrationsBoxesContainer.module.scss';
import {useQueryParams, StringParam} from 'use-query-params';
import EmptySearch from 'common/componentsV2/EmptySearch';
import {getParsedItemsList} from './IntegrationsPageService';
import IntegrationsBox from './IntegrationsBox';

const EMPTY_ARRAY = [];

type PropTypes = {
  pageConfig: Array,
};

const IntegrationsBoxesContainer = ({pageConfig}: PropTypes) => {
  const [query, setQuery] = useQueryParams({
    selectedCategory: StringParam,
    filterText: StringParam,
    selectedTab: StringParam,
  });
  const [tabsState, setTabsState] = useState({sources: {}, channels: {}});

  const {selectedCategory, filterText, selectedTab} = query;

  useEffect(() => {
    setTabsState({...tabsState, [selectedTab]: {selectedCategory: selectedCategory, filterText: filterText}});
  }, [selectedCategory, filterText]);
  useEffect(() => {
    setQuery({...tabsState[selectedTab]});
  }, [selectedTab]);

  const filterList = pageConfig ? pageConfig[selectedTab] : EMPTY_ARRAY;
  const parsedData = getParsedItemsList(filterList);

  const parsedDataFiltered = parsedData.filter((data) => {
    if (selectedCategory === 'all') {
      return true;
    }
    return data.categoryName === selectedCategory;
  });

  const total = useMemo(() => {
    if (filterList && filterList.sourcesItems) {
      return filterList.sourcesItems.length;
    }
    return 0;
  }, [filterList]);

  const filterByTitle = useMemo(() => {
    if (filterList && filterList.sourcesItems) {
      return filterList.sourcesItems.filter(
        (item) => item.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1,
      );
    }
    return [];
  }, [filterText, filterList]);

  const renderInnerList = (group) => (
    <div styleName="categories-boxes-container">
      {(group.items || group).map((item, index) => (
        <IntegrationsBox
          key={index}
          isFeatured={group.categoryName === 'featured'}
          boxData={item}
          isChannel={selectedTab === 'channels'}
        />
      ))}
    </div>
  );

  return (
    <Fragment>
      <div />
      {filterText === '' ? null : (
        <div styleName="search-results">{`Search Results (${filterByTitle.length}/${total})`}</div>
      )}
      {filterByTitle.length === 0 && (
        <EmptySearch
          error={`No ${(selectedTab || '').charAt(0).toUpperCase() + (selectedTab || '').slice(1)} to Show`}
          errorExplanation={`Change filters to see ${selectedTab}`}
        />
      )}
      {filterText !== ''
        ? renderInnerList(filterByTitle)
        : parsedDataFiltered.map((category) =>
            // eslint-disable-next-line react/jsx-indent
            category.items.length > 0 ? (
              <div key={category.categoryName}>
                <div styleName="category-name">{category.categoryTitle}</div>
                {renderInnerList(category)}
              </div>
            ) : null,
          )}
    </Fragment>
  );
};

export default IntegrationsBoxesContainer;
