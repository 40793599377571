import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {post} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {COLUMNS_TYPES} from 'dataCollectors/services/dataCollectorsService';

const root = 'api/v1/bc/';

const postFileUploadMetadata = async (type, sourceId) => {
  return post(`${root}data-sources/${type}/${sourceId}/parsing/analyze-events`, {});
};

export default function usePostFileUploadMetaData(type, sourceId) {
  const fallback = [];
  const selectFn = useCallback(
    (data) => {
      return data.fileSchema.map((i) => ({
        type: i.type,
        label: i.name,
        value: i.index.toString(),
        icon: i.type && COLUMNS_TYPES[i.type] ? COLUMNS_TYPES[i.type].icon : null,
      }));
    },
    [sourceId],
  );

  const {
    data: metadata = fallback,
    isLoading: isMetadataLoading,
    isFetching: isMetadataFetching,
    refetch: refetchMetadataFetching,
  } = useQuery(QUERY_KEYS.fileMetaData, () => postFileUploadMetadata(type, sourceId), {
    enabled: !!sourceId,
    select: selectFn,
  });

  return {metadata, isMetadataLoading, isMetadataFetching, refetchMetadataFetching};
}
