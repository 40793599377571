/* eslint-disable import/no-extraneous-dependencies */
// @flow
import React from 'react';
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import {AutoSizer, List} from 'react-virtualized';
import connect from 'react-redux/es/connect/connect';
import ReactResizeDetector from 'react-resize-detector';
import AlertsEmptyState from 'alerts.management/components/manager/AlertsEmptyState';
import AlertsManagerTableHeader from './AlertsManagerTableHeader';
import * as selectors from '../../store/selectors';
import * as filterSelector from '../../store/filterSelector';
import AlertsManagerHeader from './AlertsManagerHeader';
import AlertManagerActionToolbar from './AlertManagerActionToolbar';
import AlertManagerListItemExpendablePanel from './AlertManagerListItemExpendablePanel';
import {
  setSelectedAlert as setSelectedAlertAction,
  singleAlertCheckboxClick as singleAlertCheckboxClickAction,
  allAlertCheckboxClick as allAlertCheckboxClickAction,
  setAlertManagementKeyVal as setAlertManagementKeyValAction,
  setAlertToScrollAndOpen as setAlertToScrollAndOpenAction,
} from '../../store/actions';

type PropTypes = {
  setSelectedAlert: Function,
  alerts: Array,
  searchQuery: String,
  selectedId: String,
  singleAlertCheckboxClick: Function,
  selectedAlertsCheckbox: Array,
  allAlertCheckboxClick: Function,
  setAlertManagementKeyVal: Function,
  alertManagementKeyVal: Array,
  alertsCount: Number,
  alertToScrollAndOpen: String,
  setAlertToScrollAndOpen: Function,
};

export default connect(
  (state) => ({
    alerts: selectors.getSortedAlertManagerHeadersList(state),
    searchQuery: filterSelector.getAlertFiltersSearchQuery(state),
    selectedId: selectors.getSelectedItemId(state),
    selectedAlertsCheckbox: selectors.getSelectedAlertsCheckbox(state),
    alertManagementKeyVal: selectors.getAlertManagementKeyVal(state),
    alertsCount: selectors.getAlertConfigurationsCount(state),
    alertToScrollAndOpen: selectors.getAlertToScrollAndOpen(state),
  }),
  {
    setSelectedAlert: setSelectedAlertAction,
    singleAlertCheckboxClick: singleAlertCheckboxClickAction,
    allAlertCheckboxClick: allAlertCheckboxClickAction,
    setAlertManagementKeyVal: setAlertManagementKeyValAction,
    setAlertToScrollAndOpen: setAlertToScrollAndOpenAction,
  },
)(
  class AlertsManagerTable extends React.PureComponent {
    props: PropTypes;

    state = {
      scrollBarWidth: 0,
      scrollToIndex: -1,
    };

    componentDidUpdate(prevProps) {
      const {alerts, alertToScrollAndOpen, selectedId, setSelectedAlert, setAlertToScrollAndOpen} = this.props;
      if (alerts.length === 0) {
        return;
      }
      this.changedIndex = alerts.findIndex((alert) => alert.id === selectedId);
      this.isSelectionNew = selectedId !== prevProps.selectedId;
      this.list.recomputeRowHeights(0);

      this.recalculateScrolbarWidth();
      if (alerts && alerts.length > 0 && alertToScrollAndOpen !== '') {
        const index = alerts.findIndex((alert) => alert.id === alertToScrollAndOpen);
        setSelectedAlert(alertToScrollAndOpen);
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({scrollToIndex: index});
        setAlertToScrollAndOpen('');
      }
    }

    changedIndex = -1;

    isSelectionNew = false;

    handleCheckboxChange = (id) => () => {
      this.props.singleAlertCheckboxClick(id);
    };

    handleHeaderCheckboxChange = (val) => {
      const {allAlertCheckboxClick} = this.props;
      allAlertCheckboxClick(val);
    };

    isFindIdInCheckedItems = (id) => {
      const ret = this.props.selectedAlertsCheckbox.find((a) => id === a);
      return !!ret;
    };

    onDropdownStateChange = (state, id) => {
      const currentOpenId = this.props.alertManagementKeyVal.currentDropdownOpen;
      if (state === 'open') {
        this.props.setAlertManagementKeyVal({currentDropdownOpen: id});
      } else if (state !== 'open' && currentOpenId === id) {
        this.props.setAlertManagementKeyVal({currentDropdownOpen: ''});
      }
    };

    recalculateScrolbarWidth = () => {
      const elements = document.getElementsByClassName('ReactVirtualized__List');
      let scrollBarWidth = 0;
      if (elements && elements.length > 0) {
        const ele = elements[0];
        scrollBarWidth = ele.offsetWidth - ele.clientWidth;
      }
      this.setState({scrollBarWidth});
    };

    handleResize = () => {
      this.recalculateScrolbarWidth();
    };

    rowRenderer = ({index, style}) => {
      const {
        alerts,
        searchQuery,
        setSelectedAlert,
        selectedId,
        selectedAlertsCheckbox,
        alertManagementKeyVal,
      } = this.props;
      const shouldSimulateHoverId = alertManagementKeyVal.currentDropdownOpen;
      const alert = alerts[index];
      let passOnStyle = {...style};
      if (!this.isSelectionNew) {
        passOnStyle = {
          ...style,
          transition: 'none',
        };
      }
      return (
        <TableListItem
          automationId="Table list item"
          key={alert.id}
          id={alert.id}
          style={passOnStyle}
          checkboxVisibility={
            selectedAlertsCheckbox.length > 0 ? CHECKBOX_VISIBILITY_TYPES.alwaysShow : CHECKBOX_VISIBILITY_TYPES.onHover
          }
          isSelected={selectedId === alert.id}
          onCheckboxChange={this.handleCheckboxChange(alert.id)}
          setSelectedItem={setSelectedAlert}
          isCheckboxChecked={this.isFindIdInCheckedItems(alert.id)}
          headerComponent={<AlertsManagerHeader alert={alert} searchQuery={searchQuery} />}
          actionToolbarComponent={
            <AlertManagerActionToolbar
              onDropdownStateChange={(state) => this.onDropdownStateChange(state, alert.id)}
              alert={alert}
            />
          }
          expandedPanel={<AlertManagerListItemExpendablePanel alert={alert} />}
          shouldSimulateHover={shouldSimulateHoverId === alert.id}
        />
      );
    };

    getRowHeight = (obj) => {
      if (obj.index !== this.changedIndex) {
        return 49;
      }
      return 332;
    };

    render() {
      if (this.props.alerts.length === 0) {
        return <AlertsEmptyState alertsCount={this.props.alertsCount} />;
      }
      return (
        <div className="shell-col">
          <ReactResizeDetector handleHeight handleWidth onResize={this.handleResize} />
          <AlertsManagerTableHeader offset={this.state.scrollBarWidth - 5} onCheck={this.handleHeaderCheckboxChange} />
          <div className="shell-col">
            <AutoSizer>
              {({height, width}) => (
                <List
                  ref={(list) => {
                    this.list = list;
                  }}
                  width={width}
                  height={height}
                  rowCount={this.props.alerts.length}
                  rowHeight={this.getRowHeight}
                  rowRenderer={this.rowRenderer}
                  scrollToIndex={this.state.scrollToIndex}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      );
    }
  },
);
