// @flow
import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {getTypeDetails} from 'bc/services/bcTypes';
import * as selectors from 'bc/store/selectors';
import * as actions from 'bc/store/actions';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import DataSourceModal from '../../DataSourceModal';

import '../../DataSourceModal.module.scss';

const mxRegionOptions = [{value: 'US', text: 'US Standard Server'}, {value: 'EU', text: 'EU Residency Server'}];

export default connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createMixpanelDataSource: actions.createMixpanelDataSource,
    resetAddSourceError: actions.resetAddSourceError,
  },
)(
  class MixpanelSourceModal extends React.PureComponent {
    props: {
      isOpen: boolean,
      isViewOnly: boolean,
      isEditable: boolean,
      source: Object,
      sourceType: Object,
      isLoading: boolean,
      sourceError: Object,
      onClose: Function,
      createMixpanelDataSource: Function,
      resetAddSourceError: Function,
    };

    // eslint-disable-next-line no-extra-boolean-cast
    state = !!this.props.source
      ? {
          name: this.props.source.name,
          mxRegion: this.props.source.mxRegion,
          projectId: this.props.source.projectId,
          mxUserId: this.props.source.mxUserId,
          mxSecret: this.props.source.mxSecret,
        }
      : {
          name: '',
          mxRegion: mxRegionOptions[0],
          projectId: '',
          mxUserId: '',
          mxSecret: '',
        };

    valueChanged = (val, inputId) => {
      const newState = {};
      newState[inputId] = val;
      this.setState(newState);
    };

    regionChanged = (val) => {
      this.setState({mxRegion: val});
    };

    createBtnClicked = () => {
      const {sourceType, createMixpanelDataSource} = this.props;

      createMixpanelDataSource({
        type: getTypeDetails(sourceType.type).type,
        name: `${getTypeDetails(sourceType.type).shortName} ${
          this.state.name
        } Data Source ${new Date().getTime().toString()}`,
        mxRegion: this.state.mxRegion.value,
        projectId: this.state.projectId,
        mxUserId: this.state.mxUserId,
        mxSecret: this.state.mxSecret,
        timeZone: this.state.timeZone || moment.tz.guess(),
      });
    };

    isContinueDisabled = () => {
      const {name, projectId, mxUserId, mxSecret, mxRegion} = this.state;
      return !name || !projectId || !mxUserId || !mxSecret || !mxRegion;
    };

    onCloseInternal = () => {
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    render() {
      const {isOpen, isLoading, sourceError, source, sourceType, isViewOnly, isEditable} = this.props;
      const {name, projectId, mxUserId, mxSecret, mxRegion} = this.state;

      const selectedIndex = mxRegionOptions.findIndex((val) => val.value === mxRegion);

      return (
        <DataSourceModal
          isOpen={isOpen}
          isContinueDisabled={this.isContinueDisabled()}
          isProcessing={isLoading}
          isViewOnly={isViewOnly}
          isRenameEnabled={isEditable}
          source={source}
          sourceType={sourceType}
          logoImageClass="source-mxpnl"
          onClose={this.onCloseInternal}
          onContinue={this.createBtnClicked}
        >
          <div styleName="inputs-wrapper">
            {!isViewOnly && (
              <input
                type="text"
                automation-id="dataCollectorMixpanelServerLocationTextbox"
                onChange={(e) => this.valueChanged(e.target.value, 'name')}
                placeholder="Name"
                readOnly={isViewOnly}
                disabled={isViewOnly}
                value={name}
              />
            )}
            <SelectAndt
              id="s3RegionDdl1"
              automationId="s3Regions"
              styleName="region-dropdown"
              className="andt-dropdown"
              disabled={isViewOnly}
              options={mxRegionOptions}
              getOptionLabel={(val) => val.text}
              getOptionValue={(val) => val.value}
              type={TYPE_NO_SEARCH}
              theme={THEME_LIGHT}
              value={mxRegionOptions[selectedIndex]}
              onChange={(item) => this.regionChanged(item)}
              placeholder="Server region"
            />
            <input
              type="text"
              automation-id="dataCollectorDatabricksUserTextbox"
              onChange={(e) => this.valueChanged(e.target.value, 'projectId')}
              placeholder="Project ID"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              value={projectId}
            />
            <input
              type="text"
              automation-id="dataCollectorDatabricksPasswordTextbox"
              onChange={(e) => this.valueChanged(e.target.value, 'mxUserId')}
              placeholder="Username/UserId"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              value={mxUserId}
            />
            <input
              type="password"
              automation-id="dataCollectorDatabricksPathTextbox"
              onChange={(e) => this.valueChanged(e.target.value, 'mxSecret')}
              placeholder="Secret"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              value={mxSecret}
            />
            {!isLoading && sourceError ? (
              <div styleName="error-wrapper">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" />{' '}
                  {sourceError.uMessage || 'Could not get list of databases, check the settings and try again'}
                </span>
              </div>
            ) : null}
            <div styleName="extra-info">
              <a
                href="https://support.anodot.com/hc/en-us/articles/360019360819-Mixpanel-Collector"
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn more
              </a>
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
