import errorHandler from 'common/utils/notifications/errorHandler';

export const bcErrorCodes = {
  11014: {
    title: 'Process Failed',
    description: 'Could not get results in time',
    settings: {
      // optional
      autoDismiss: 5, // default is 5 sec
      position: 'bl', // default is bottom-left(bl)
    },
  },
  // General
  13001: {
    title: 'Permission Failure',
    description:
      // eslint-disable-next-line max-len
      'User has insufficient permissions to access data source. Contact your data source administrator to change permissions.',
  },
  13002: {
    title: 'Query Failure',
    description:
      'Unable to retrieve data due to data source query limits. Refine your query or timespan and then retry.',
  },
  13003: {
    title: 'Data Source Failure',
    description: 'Unknown data source error',
  },
  13004: {
    title: 'Query Failed',
    description: 'Invalid query',
  },
  13005: {
    title: 'Authentication Failed',
    description:
      'Another data source was already authenticated using this Google account.\n' +
      'This means you can use this source if it still exists in Anodot.\n' +
      'If this source was deleted from Anodot:\n' +
      '1. Go to your Google account\n' +
      '2. Revoke the permissions given to Anodot\n' +
      '3. Go back to Anodot and add the source again as a new data source',
  },
  13006: {
    title: 'Authentication Failure',
    description: 'The data source could not be created.',
  },
  13007: {
    title: 'Resource Retrieval Failure',
    description: 'Resource does not exist. Unable to retrieve data. Validate resource name and retry.',
  },
  // Google Analytics
  12001: {
    title: 'Authentication Failure',
    description: 'Illegal Google Analytics source authentication type. Retry with a service/user account.',
  },
  12002: {
    title: 'Authentication Failure',
    description: 'Unsupported Google Analytics source authentication type. Retry with a service/user account.',
  },
  12003: {
    title: 'API Error',
    description: 'Google API error',
  },
  12004: {
    title: 'Authentication Failure',
    description: 'Authentication failed for Google Analytics source. Check your credentials and then retry.',
  },
  // Big Query
  12005: {
    title: 'BigQuery Not Found',
    description: 'The Google project has not enabled BigQuery. Choose a project with BigQuery enabled and retry.',
  },
  12006: {
    title: 'Warning - Static Query ',
    description:
      'The query should contain at least one of the time definition place holders {{from_time}}, {{to_time}}.\n' +
      'Are you sure you want to continue?',
  },
  // File Upload
  14001: {
    title: 'File is Empty',
    description: 'Check the file content and parsing parameters and then reload the file.',
  },
  14002: {
    title: 'File Parsing Failure',
    description:
      'The file should have at least 2 fields (date, metric/dimension). Fix the file and then reload the file. ',
  },
  14003: {
    title: 'File Parsing Failure',
    description: 'File header contains invalid characters. Fix the file header and then reload the file.',
  },
  14004: {
    title: 'File Parsing Failure',
    description: 'Can not parse file header. Fix the file header and then reload the file.',
  },
  14005: {
    title: 'File Parsing Failure',
    description:
      'Inconsistent fields count. Data lines contain varying number of columns. Fix and then reload the file.',
  },
  14006: {
    title: 'File Parsing Failure',
    description:
      // eslint-disable-next-line max-len
      'Row parsing failed. Possible reasons: unmatched quotes, character escaping, missing delimiter etc. Fix the line content and then reload the file.',
  },
  14007: {
    title: 'File Parsing Failure',
    description:
      // eslint-disable-next-line max-len
      'Invalid timestamp pattern. The timestamp found does not match the defined format. Update the timestamp pattern and then reload the file.',
  },
  14008: {
    title: 'File Parsing Failure',
    description:
      'Invalid time definition. Update the timestamp pattern to match the file content and then reload the file.',
  },
  // s3
  14408: {
    title: 'Access Denied ',
    description: "Anodot can't access this bucket. Make sure the bucket's policy allows Anodot to access its files.",
  },
  14409: {
    title: 'Bucket Not Found ',
    description: "Anodot couldn't find this bucket. Make sure the bucket region and name are correct.",
  },
  // Adobe
  15001: {
    title: 'Authentication Failure ',
    description: 'Invalid user specified. Contact your data source administrator to change permissions.',
  },
  15002: {
    title: 'Process Failed',
    description: 'Error performing operation. ',
  },
  15003: {
    title: 'Report Parsing Failed',
    description: 'Error parsing report response. Try running the report again.',
  },
  15004: {
    title: 'Report is Running',
    description: 'Report is running in async mode. Stream status will be updated when the report is ready.',
  },
  15005: {
    title: 'Report Failure',
    description: 'The requested report is not available. Try running the report to continue.',
  },
  15006: {
    title: 'Report Expired',
    description:
      // eslint-disable-next-line max-len
      'The report results have expired on the Adobe server. A new report request was sent. The stream state will be updated when the report is ready.',
  },
  24005: {},
};

export const bcInfoCodes = {
  11001: {
    title: 'Streaming Started',
    description:
      'Streaming will take a while. When the stream state changes to Completed, your data will be available.',
  },
  11002: {
    title: 'Streaming Started',
    description: 'Streaming will take a while. When the stream state changes to Live, your data will be available.',
  },
};

export const bcErrorHandler = errorHandler(bcErrorCodes);
