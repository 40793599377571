import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import * as selectors from 'impactNewAlertConsole/store/selectors';
import {getRoutingLocation} from 'common/store/selectors';
import * as actions from 'impactNewAlertConsole/store/actions';
import {segmentClickEvent} from 'common/store/actions';
import Modal, {SIZES as MODAL_SIZES} from 'common/componentsV2/modal/Modal';
import {
  IMPACT_DEFAULT_VALUES,
  IMPACT_MODAL_HEIGHT,
  makeLeanAlert,
  modalRouting1 as impactRouting1,
  modalRouting2 as impactRouting2,
} from 'impactNewAlertConsole/services/constants';
import Measure from 'impactNewAlertConsole/impactSettings/Measure';
import FactorSelectorSlider from 'impactNewAlertConsole/impactSettings/FactorSelectorSlider';
import FactorSelectorInput from 'impactNewAlertConsole/impactSettings/FactorSelectorInput';
import CurrencySelector from 'impactNewAlertConsole/impactSettings/CurrencySelector';
import SpikeSelector from 'impactNewAlertConsole/impactSettings/SpikeSelector';
import DropSelector from 'impactNewAlertConsole/impactSettings/DropSelector';
import ImpactSum from 'impactNewAlertConsole/impactSettings/ImpactSum';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Button, {COLORS, HEIGHTS} from 'common/componentsV2/Button';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';
import fetchAlertsForImpactProvider from 'impactNewAlertConsole/api/fetchAlertForImpactProvider';
import AnomalyAlertDelta from 'alerts.console.new/components/alertsList/alertContent/anomalyAlert/AnomalyAlertDelta';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  removeBtn: {
    color: palette.gray['400'],
    cursor: 'pointer',
    '&:hover': {
      color: palette.gray['500'],
    },
  },
  impactIcon: {
    position: 'relative',
    top: 3,
    marginRight: 10,
    color: palette.tomato['500'],
  },
}));

const ImpactModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const routingLocation = useSelector(getRoutingLocation);
  const isModalOpen = useSelector(selectors.getIsModalOpen);
  const impactsView = useSelector(selectors.getImpacts);
  const impactsListData = useSelector(selectors.getImpactsListData);
  const isImpactsListLoading = useSelector(selectors.getIsImpactsListLoading);
  const isImpactLoading = useSelector(selectors.getIsImpactLoading);

  const [queryParams, setQueryParams] = useQueryParams({
    [impactRouting1.IMPACT_MODAL]: BooleanParam,
    [impactRouting1.ORIGIN_ID]: StringParam,
    [impactRouting1.ORIGIN_TYPE]: StringParam,
    [impactRouting1.ALERT_TRIGGER_ID]: StringParam,
    [impactRouting1.MEASURE]: StringParam,
    [impactRouting2.IMPACT_ID]: StringParam,
  });

  const alertForImpactProvider = fetchAlertsForImpactProvider();
  const [triggeredAlert, setTriggeredAlert] = useState(undefined);

  const impactId = queryParams.impactId || IMPACT_DEFAULT_VALUES.id;
  const measure = queryParams.measure || null;
  const originId = queryParams.originId || null;
  const originType = queryParams.originType || null;
  const isSameMeasure = () => impactsListData && impactsListData.find((impact) => impact.measure === measure);

  useEffect(async () => {
    let alertForImpactResult;
    if (isModalOpen && queryParams?.alertTriggerId) {
      alertForImpactResult = await alertForImpactProvider.fetchQuery(queryParams?.alertTriggerId);
      if (alertForImpactResult?.alerts?.length > 0) {
        const trigger = alertForImpactResult?.alerts[0];
        setTriggeredAlert(makeLeanAlert(trigger));
      }
    }
  }, [isModalOpen, queryParams?.alertTriggerId]);

  useEffect(() => {
    if (isModalOpen) {
      if (measure) {
        dispatch(actions.getImpactsByOriginNewAlertConsole({originId, originType}));
        dispatch(
          actions.setImpactDefaultValuesNewAlertConsole({
            ...IMPACT_DEFAULT_VALUES,
            measure,
            originType,
            originId,
          }),
        );
        dispatch(segmentClickEvent({category: 'impact', name: 'open-new-impact-modal-from-alert'}));
      }
      if (impactId && impactId !== IMPACT_DEFAULT_VALUES.id) {
        dispatch(actions.getSingleImpactNewAlertConsole(impactId));
        dispatch(segmentClickEvent({category: 'impact', name: 'open-set-impact-modal-from-alert'}));
      }
    }
  }, [isModalOpen, measure, originType, originId, impactId]);

  const closeModal = () => {
    dispatch(actions.isImpactModalOpenNewAlertConsole(false));
    setQueryParams({
      [impactRouting1.IMPACT_MODAL]: undefined,
      [impactRouting1.ALERT_TRIGGER_ID]: undefined,
      [impactRouting1.ORIGIN_ID]: undefined,
      [impactRouting1.ORIGIN_TYPE]: undefined,
      [impactRouting1.MEASURE]: undefined,
      [impactRouting2.IMPACT_ID]: undefined,
    });
  };

  const setValue = (e) => {
    const impactValue = {
      value: e.value,
      name: e.name,
      id: impactId,
    };
    dispatch(actions.setImpactValueNewAlertConsole(impactValue));
  };

  const submitImpact = () => {
    const pathnameArr = routingLocation.pathname.split('/');
    const isInBcPage = pathnameArr.includes('bc') || pathnameArr.includes('data-manager');
    const isImpactExist = (impactId && impactId !== IMPACT_DEFAULT_VALUES.id) || (isInBcPage && isSameMeasure());

    if (isImpactExist) {
      dispatch(actions.updateImpactNewAlertConsole(impactId));
      dispatch(segmentClickEvent({category: 'impact', name: 'update-impact-from-alert'}));
    } else {
      dispatch(actions.createImpactNewAlertConsole(IMPACT_DEFAULT_VALUES.id));
      dispatch(segmentClickEvent({category: 'impact', name: 'set-new-impact-from-alert'}));
    }

    closeModal();
  };

  const removeImpact = () => {
    dispatch(actions.removeImpactNewAlertConsole(impactId));
    dispatch(segmentClickEvent({category: 'impact', name: 'remove-impact-from-alert'}));
    closeModal();
  };

  const dismiss = () => {
    dispatch(segmentClickEvent({category: 'impact', name: 'impact-not-now-button'}));
    closeModal();
  };

  return (
    <>
      <Modal
        onClose={closeModal}
        isOpen={isModalOpen}
        size={MODAL_SIZES.LARGE}
        isCloseButtonHidden={false}
        isStatic
        height={IMPACT_MODAL_HEIGHT}
      >
        {isImpactLoading || isImpactsListLoading || !triggeredAlert ? (
          <Box className={classes.loader}>
            <Spinner color={palette.gray[500]} size={SIZES.X_BIG_90} />
          </Box>
        ) : (
          <>
            {(!impactsView || isEmpty(impactsView[impactId])) && (
              <Grid container className={classes.wrapper}>
                <Grid item>
                  <TypographyBox variant="h1" mb={2}>
                    Impact Was Deleted
                  </TypographyBox>
                </Grid>
              </Grid>
            )}

            {impactsView && !isEmpty(impactsView[impactId]) && (
              <Grid container className={classes.wrapper}>
                <Grid item>
                  <TypographyBox variant="h1" mb={2}>
                    <i className={`icn-general16-impact ${classes.impactIcon}`} />
                    <span>Impact</span>
                  </TypographyBox>
                </Grid>

                <Grid container item justify="space-between">
                  {/* Left Side */}
                  <Grid item sm={6}>
                    <Box display="flex" alignItems="center" mb={0.5}>
                      <TypographyBox variant="h4" component="span" mr={1}>
                        What is the value of
                      </TypographyBox>
                      <Measure measure={impactsView[impactId].measure} css={{maxWidth: 185, display: 'block'}} />
                    </Box>
                    <TypographyBox variant="h4" component="span">
                      to your business?
                    </TypographyBox>

                    <Box display="flex" alignItems="center" mb={3} mt={4.5}>
                      <Box mr={1}>
                        <FactorSelectorInput value={impactsView[impactId].factor} setValue={setValue} />
                      </Box>
                      <div>
                        <CurrencySelector value={impactsView[impactId].currency} setValue={setValue} />
                      </div>
                    </Box>

                    <Box mb={2}>
                      <FactorSelectorSlider value={impactsView[impactId].factor} setValue={setValue} />
                      <TypographyBox variant="subtitle2">You can change this impact value anytime later</TypographyBox>
                    </Box>

                    <div>
                      <TypographyBox variant="subtitle3" mb={1}>
                        Direction Behavior
                      </TypographyBox>

                      <Box display="flex" alignItems="center">
                        <TypographyBox component="span" variant="subtitle1" mr={0.5}>
                          Spikes are:{' '}
                        </TypographyBox>
                        <SpikeSelector value={impactsView[impactId].spike} setValue={setValue} />
                        <Box component="span" mr={1} />
                        <TypographyBox component="span" variant="subtitle1" mr={0.5}>
                          Drops are:{' '}
                        </TypographyBox>
                        <DropSelector value={impactsView[impactId].drop} setValue={setValue} />
                      </Box>
                    </div>
                  </Grid>

                  {/* Right Side */}
                  <Grid container item sm={5} justify="flex-end">
                    <div>
                      <TypographyBox variant="subtitle3" mt={1} mb={1}>
                        {triggeredAlert.title}
                      </TypographyBox>
                      <Box mb={2} ml={-1}>
                        <AnomalyAlertDelta metric={triggeredAlert.metric} size="lg" />
                      </Box>
                      <TypographyBox variant="caption" mb={1}>
                        The Impact of this anomaly is:
                      </TypographyBox>
                      <ImpactSum
                        sumDeltas={triggeredAlert.sumDeltas}
                        direction={triggeredAlert.direction}
                        factor={impactsView[impactId].factor}
                        currency={impactsView[impactId].currency}
                        spike={impactsView[impactId].spike}
                        drop={impactsView[impactId].drop}
                      />
                    </div>
                  </Grid>
                </Grid>

                <Grid container item justify="space-between" style={{marginTop: 'auto'}}>
                  {/* Bottom right Side */}
                  <Grid alignItems="center" container item sm={6}>
                    {impactId && impactId !== IMPACT_DEFAULT_VALUES.id && (
                      <TypographyBox
                        component="span"
                        variant="subtitle1"
                        onClick={removeImpact}
                        className={classes.removeBtn}
                      >
                        Remove Impact from these alerts
                      </TypographyBox>
                    )}
                  </Grid>
                  {/* Bottom Left Side */}
                  <Grid container item sm={6} justify="flex-end">
                    <Button colorSchema={COLORS.GRAY_400} height={HEIGHTS.REGULAR} text="Not Now" onClick={dismiss} />
                    <Box ml={1.5}>
                      <Button
                        colorSchema={COLORS.BLUE_500}
                        height={HEIGHTS.REGULAR}
                        text="Set Impact Value"
                        onClick={submitImpact}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default ImpactModal;
