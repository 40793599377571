// @flow
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {post, put} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'composites/services/compositesService';

const root = 'api/v1/metrics/';

const onUpdateComposite = (id, delay) => {
  const url = delay ? `${root}composite/${id}/delay?delay=${delay}` : `${root}composite/${id}/delay`;
  return put(url);
};

const getCompositeDelay = (id) => post(`${root}composite/${id}/delay`);

export function useUpdateCompositeDelay() {
  const queryClient = useQueryClient();

  const {mutate: updateCompositeDelay, isLoading: isUpdateCompositeDelayLoading} = useMutation(
    ({payload}) => onUpdateComposite(payload.id, payload.delay),
    {
      onSuccess: (data, meta) => {
        queryClient.invalidateQueries([QUERY_KEYS.COMPOSITE_LIST]);
        meta.onSuccess(data);
      },
      onError: (error, meta) => {
        meta.onError(error);
      },
    },
  );

  return {updateCompositeDelay, isUpdateCompositeDelayLoading};
}

export function usePostCompositeDelay(id) {
  const fallback = {};
  const {
    dataUpdatedAt,
    data: compositeDelay = fallback,
    isLoading: isCompositeDelayLoading,
    isFetch: isCompositeDelayFetching,
  } = useQuery(`${QUERY_KEYS.COMPOSITE_DELAY}${id}`, () => getCompositeDelay(id));

  return {
    updatedAt: dataUpdatedAt,
    compositeDelay,
    isCompositeDelayLoading,
    isCompositeDelayFetching,
  };
}
