// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
// eslint-disable-next-line import/no-cycle
import * as selectors from '../store/selectors';
import {setOrderCube as setOrderCubeAction, setSortCube} from '../store/actions';
import './CubesSorting.module.scss';

export const ASCENDANT = 'Asc';
export const DESCENDANT = 'Des';
export const SORT_BY_TITLE = {label: 'Sort by Title', value: 'title'};
export const SORT_BY_METRICS = {label: 'Sort by Metrics', value: 'metrics'};

type PropTypes = {
  // connect
  sort: string,
  order: string,
  setOrderCube: Function,
  setSortCube: Function,
};

export default connect(
  (state) => ({
    sort: selectors.getSchemasSort(state),
    order: selectors.getSchemasOrder(state),
  }),
  {
    setOrderCube: setOrderCubeAction,
    setSortCube,
  },
)(
  class CubesSorting extends PureComponent {
    props: PropTypes;

    onSortSelect = (value) => {
      this.props.setSortCube(value);
    };

    onOrderChange = () => {
      const {order, setOrderCube} = this.props;
      const newOrder = order === ASCENDANT ? DESCENDANT : ASCENDANT;
      setOrderCube(newOrder);
    };

    render() {
      const {sort, order} = this.props;
      const options = [SORT_BY_TITLE, SORT_BY_METRICS];
      const optionCurrent = options.findIndex((option) => option.value === sort);
      return (
        <div styleName="sorting">
          <SelectAndt
            id="dropdown-sort"
            options={options}
            type={TYPE_NO_SEARCH}
            theme={THEME_LIGHT}
            value={options[optionCurrent]}
            onChange={this.onSortSelect}
            placeholder="Sort by"
            automationId="cubeSorting"
          />
          <button type="button" onClick={this.onOrderChange}>
            {order}
          </button>
        </div>
      );
    }
  },
);
