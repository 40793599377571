import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Modal, {SIZES} from '../../../common/componentsV2/modal/Modal';
import TimeSettings from '../timeSettings/TimeSettings';
import useDataMapperClasses from '../../styles/classes.useDataMapperClasses';

const {classes} = useDataMapperClasses();

function TimeSettingsBtn({value, name, isLoading, isMoreSpace, errorMsg, sourceColumn}) {
  const dataMapperClasses = classes();
  const [isTimeSettingModalOpen, setIsTimeSettingModalOpen] = useState(false);

  return (
    <div>
      <div onClick={() => setIsTimeSettingModalOpen(true)} className={classes.previewLink}>
        <div className={dataMapperClasses.settings} style={{top: isMoreSpace ? 17 : 3}}>
          {isLoading ? (
            <span>Loading...</span>
          ) : (
            <>
              <i className="icon icn-nav16-settings" style={{marginRight: 3}} />
              <span style={{marginRight: 6}}>{value?.timePattern || 'Please set time pattern'}</span>{' '}
              {errorMsg && <span style={{color: errorMsg ? '#D10F37' : ''}}> ({errorMsg})</span>}
            </>
          )}
        </div>
      </div>

      <Modal
        id="timeSettings"
        isOpen={isTimeSettingModalOpen}
        onClose={() => setIsTimeSettingModalOpen(false)}
        size={SIZES.SMALL}
        isStatic={false}
        height={240}
      >
        <TimeSettings
          closeModal={() => setIsTimeSettingModalOpen(false)}
          settingsName={name}
          sourceColumnName={sourceColumn}
        />
      </Modal>
    </div>
  );
}

TimeSettingsBtn.defaultProps = {
  isLoading: false,
  isMoreSpace: false,
  errorMsg: '',
};

TimeSettingsBtn.propTypes = {
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  sourceColumn: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  isMoreSpace: PropTypes.bool,
  errorMsg: PropTypes.string,
};

export default TimeSettingsBtn;
