// @flow
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StringParam, useQueryParams} from 'use-query-params';
import {
  updateFilterBtn as updateFilterBtnAction,
  updateQueryParams as updateQueryParamsAction,
} from 'alerts.console/store/actions';
import * as alertsConsoleSelectors from 'alerts.console/store/selectors';
import {getAlertAssigneeEnabled} from 'profile/store/selectors';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import SearchBox from 'common/componentsV2/SearchBox';
import Checkbox from 'common/componentsV2/Checkbox';
import AlertStatusFilter from './availableFilters/AlertStatusFilter';
import AckFilter from './availableFilters/AckFilter';
import FeedbackFilter from './availableFilters/FeedbackFilter';
import DateRangeFilter from './availableFilters/DateRangeFilter';
import ChannelsFilter from './availableFilters/ChannelsFilter';
import TypeFilter from './availableFilters/TypeFilter';
import SeverityFilter from './availableFilters/SeverityFilter';
import LabelsFilter from './availableFilters/LabelsFilter';

import './AlertsConsoleFilters.module.scss';
import AssigneeFilter from './availableFilters/AssigneeFilter';

type PropTypes = {
  defaultQueryParams: Object,
  isFilteredFromEmail: Boolean,
};
/**
 * Convert boolean string to boolean type.
 * Any query param set to "true" or "false" will be converted to a boolean type.
 */
const boolify = (value) => {
  if (typeof value === 'string') {
    const value2 = value.toLowerCase().trim();
    if (value2 === 'true') {
      return true;
    }
    if (value2 === 'false') {
      return false;
    }
  }
  return value;
};
/**
 * Convert query string params object to an array of used params.
 */
const convertToUsedParams = (obj, queryStringParamsList) => {
  return (
    Object.entries(obj)
      // remove un used options
      .filter(([, v]) => v != null && v !== '')
      // remove query params not in use in this screen
      .filter(([k]) => Object.prototype.hasOwnProperty.call(queryStringParamsList, k))
  );
};

/**
 * Checks that all keys in queryString object exists and equal to keys in default query string
 */
const isDifferentThanDefault = (queryString, defaultQueryString) => {
  const allQueryStringKeys = Object.entries(queryString);
  // Check if not every query string params are same as default = added param
  const addedParms = !allQueryStringKeys.every(([key, value]) => !value || defaultQueryString[key] === value);
  // and also check that not every used defaults are in the query params = removed param
  const usedDefaultParam = convertToUsedParams(defaultQueryString, queryString);
  const removedParms = !usedDefaultParam.every(([key, value]) => queryString[key] === value);

  return addedParms || removedParms;
};

const FilterToolbar = ({defaultQueryParams, isFilteredFromEmail}: PropTypes) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(alertsConsoleSelectors.getIsFiltersBarOpen);
  const dataQueryParams = useSelector(alertsConsoleSelectors.getQueryParamsData);
  const isAlertAssigneeEnabled = useSelector(getAlertAssigneeEnabled);
  const [isFiltersChanges, setIsFiltersChanges] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams({
    searchQuery: StringParam,
    sort: StringParam,
    startTime: StringParam,
    endTime: StringParam,
    constRange: StringParam,
    relativeLast: StringParam,
    relativeNext: StringParam,
    status: StringParam,
    labels: StringParam,
    types: StringParam,
    subscribers: StringParam,
    channels: StringParam,
    assignee: StringParam,
    severities: StringParam,
    severityView: StringParam,
    ack: StringParam,
    positiveFeedback: StringParam,
    negativeFeedback: StringParam,
  });

  // Query params based on saved view are added in AlertConsole componentDidMount method
  // The purpose of this effect is to add any additional default parameters, such as sort
  // and to override default & data params with query params
  useEffect(() => {
    const usedDefaultParam = Object.fromEntries(convertToUsedParams(defaultQueryParams, queryParams));
    const usedDataParam = Object.fromEntries(convertToUsedParams(dataQueryParams, queryParams));
    const usedQueryParam = Object.fromEntries(convertToUsedParams(queryParams, queryParams));
    const mergedQueryParams = {
      ...usedDefaultParam,
      ...usedDataParam,
      ...usedQueryParam,
    };
    setQueryParams(mergedQueryParams);
  }, []);

  useEffect(() => {
    const usedQueryParam = {...queryParams};
    // convert strings to bool
    Object.keys(usedQueryParam).forEach((key) => {
      usedQueryParam[key] = boolify(usedQueryParam[key]);
    });
    // compare used query params with default
    const isQueryParamsChanged = isDifferentThanDefault(usedQueryParam, {...defaultQueryParams, ...dataQueryParams});
    setIsFiltersChanges(isQueryParamsChanged);
  }, [queryParams, dataQueryParams, defaultQueryParams]);

  const resetFilters = useCallback(
    (resetToManufacturer) => {
      const usedDefaultParam = Object.fromEntries(convertToUsedParams(defaultQueryParams, queryParams));
      if (resetToManufacturer) {
        // View only
        setQueryParams(usedDefaultParam, 'replace');
        // Save in Data
        dispatch(updateQueryParamsAction(true));
      } else {
        const mergedQueryParams = {
          ...usedDefaultParam,
          ...dataQueryParams,
        };
        // View only
        setQueryParams(mergedQueryParams, 'replace');
      }
    },
    [queryParams, setQueryParams, defaultQueryParams, dataQueryParams],
  );

  const onSaveViewValueChange = useCallback(
    (id) => {
      if (id === 'reset') {
        resetFilters(true);
      } else {
        dispatch(updateQueryParamsAction());
      }
    },
    [resetFilters],
  );

  return (
    <div styleName="root">
      <FiltersPanel
        isOpen={isOpen}
        onClose={() => dispatch(updateFilterBtnAction(true))}
        isClearAll={isFiltersChanges && !isFilteredFromEmail}
        onClearAll={() => resetFilters(false)}
        isSaveView={!isFilteredFromEmail}
        onSaveViewChange={onSaveViewValueChange}
      >
        <div styleName="filters-row">
          <SearchBox
            onFilter={(val) => setQueryParams({searchQuery: val || undefined})}
            filter={queryParams?.searchQuery || ''}
            placeHolder="Search Alerts"
          />
        </div>

        <div styleName="filters-row">
          <span styleName="filters-row-header">Started During</span>
          <DateRangeFilter defaultDateRange={dataQueryParams.constRange || defaultQueryParams.constRange} />
        </div>

        <div styleName="filters-row">
          <AlertStatusFilter />
        </div>

        <div styleName="filters-row">
          <span styleName="filters-row-header">Alert Labels</span>
          <LabelsFilter />
        </div>

        <div styleName="filters-row">
          <span styleName="filters-row-header">Alert Type</span>
          <TypeFilter />
        </div>

        <div styleName="filters-row">
          <span styleName="filters-row-header">Recipients</span>
          <ChannelsFilter />
        </div>

        {isAlertAssigneeEnabled && (
          <div styleName="filters-row">
            <span styleName="filters-row-header">Assignee</span>
            <AssigneeFilter />
          </div>
        )}

        <div styleName="filters-row">
          <span styleName="filters-row-header">Acknowledge</span>
          <AckFilter />
        </div>

        <div styleName="filters-row">
          <span styleName="filters-row-header">Feedback</span>
          <FeedbackFilter />
        </div>

        <div styleName="filters-row double" style={{marginBottom: 0}}>
          <div styleName="small">
            <span styleName="filters-row-header">Severity</span>
            <SeverityFilter />
          </div>
          <div styleName="small" style={{marginTop: 34}}>
            <Checkbox
              automationId="alertSeverityCheckboxFilter"
              isChecked={queryParams?.severityView === 'true'}
              text="Severity view"
              onChange={(checked) => {
                setQueryParams({severityView: checked, sort: checked ? 'severity' : defaultQueryParams.sort});
              }}
            />
          </div>
        </div>
      </FiltersPanel>
    </div>
  );
};
export default FilterToolbar;
