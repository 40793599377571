// @flow
import React from 'react';
import './ExpandableListItem.module.scss';
import connect from 'react-redux/es/connect/connect';
import {segmentClickEvent} from '../../store/actions';

type PropTypes = {
  setSelectedItem: Function,
  id: String,
  isSelected: boolean,
  header: Node,
  actionToolbar: Node,
  expandedPanel: Node,
  shouldSimulateHover: boolean,
  isChecked: boolean,
  expandedPanelClass: String,
  selectedActionItem: String,
  isLast: boolean,
  // connect
  segmentClickEvent: Function,
};

export default connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)(
  class ExpandableListItem extends React.PureComponent {
    props: PropTypes;

    state = {
      isOver: false,
      isOverTimeOut: false,
    };

    // This value should be a little bigger than the transition value in the CSS;

    // Close action menu after selecting an option
    componentDidUpdate(prevProps) {
      if (prevProps.selectedActionItem !== this.props.selectedActionItem) {
        this.handleOut();
      }
    }

    componentWillUnmount() {
      this.setState({isOver: false});
      this.setState({isOverTimeOut: false});
      if (this.sto1) {
        clearTimeout(this.sto1);
      }
      if (this.sto2) {
        clearTimeout(this.sto2);
      }
    }

    animationOpenCloseTime = 2;

    handleClick = () => {
      this.props.segmentClickEvent({type: 'click', name: 'Extended list item'});
      this.props.setSelectedItem(this.props.id);
    };

    handleOver = () => {
      // TODO GABPAC - frtom handle to on
      if (this.sto2) {
        clearTimeout(this.sto2);
      }
      this.setState({isOver: true});
      this.sto1 = setTimeout(() => this.setState({isOverTimeOut: true}), 1);
    };

    handleOut = () => {
      if (this.sto1) {
        clearTimeout(this.sto1);
      }
      this.setState({isOverTimeOut: false});
      this.sto2 = setTimeout(() => this.setState({isOver: false}), this.animationOpenCloseTime);
    };

    render() {
      const {
        isSelected,
        header,
        actionToolbar,
        expandedPanel,
        shouldSimulateHover,
        isChecked,
        expandedPanelClass,
        isLast,
      } = this.props;

      return (
        <div
          styleName={[
            'list-item',
            shouldSimulateHover ? 'force-hover' : '',
            isSelected ? 'open' : '',
            isChecked ? 'is-checked' : '',
            isLast ? 'last-child' : '',
          ].join(' ')}
          className={expandedPanelClass}
          onMouseEnter={this.handleOver}
          onMouseLeave={this.handleOut}
        >
          <header
            role="button"
            tabIndex={0}
            onKeyPress={this.handleClick}
            onClick={this.handleClick}
            styleName="expandable-header"
          >
            {header}
          </header>
          {this.state.isOver || shouldSimulateHover || isSelected ? (
            <aside
              styleName={[
                'action-toolbar',
                this.state.isOverTimeOut || shouldSimulateHover ? 'action-toolbar-timeout' : '',
              ].join(' ')}
            >
              {actionToolbar}
            </aside>
          ) : null}
          {isSelected && expandedPanel}
        </div>
      );
    }
  },
);
