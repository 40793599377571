// @flow
import React from 'react';
import * as selectors from 'bc/store/selectors';
import Button, {COLORS} from 'common/componentsV2/Button';
import {connect} from 'react-redux';
import {getStreamAssets as getStreamAssetsAction} from 'bc/store/actions';
import {segmentCausingEvent as segmentTrackingEventAction} from 'common/store/actions';
import './StreamItButton.module.scss';

type PropTypes = {
  dataStreamId: String,
  dataStreamName: String,

  // connect
  getStreamAssets: Function,
  segmentCausingEvent: Function,
  isLoading: boolean,
  streamId: String,
  alerts: Array,
  dashboards: Array,
  composites: Array,
};

export default connect(
  (state) => ({
    isLoading: selectors.getViewStreamSummaryAssetsIsLoading(state),
    streamId: selectors.getViewStreamSummaryAssetsStreamId(state),
    alerts: selectors.getViewStreamSummaryAlertsAssetsItems(state),
    dashboards: selectors.getViewStreamSummaryDashboardsAssetsItems(state),
    composites: selectors.getViewStreamSummaryCompositesAssetsItems(state),
  }),
  {
    getStreamAssets: getStreamAssetsAction,
    segmentCausingEvent: segmentTrackingEventAction,
  },
)(
  class InfoToCsv extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {getStreamAssets, dataStreamId, streamId} = this.props;

      if (dataStreamId !== streamId) {
        getStreamAssets(dataStreamId);
      }
    }

    exportCsv = () => {
      const {alerts, dashboards, composites, dataStreamName} = this.props;

      const LinkPre = `https://${window.location.hostname}`;
      const rows = [['Type', 'Title', 'Owner', 'Notification severity', 'Link']];

      this.props.segmentCausingEvent({category: 'bc/data-streams/EditRunning/stream-it-modal', name: 'Export CSV'});

      if (alerts.length) {
        alerts.map((item) =>
          rows.push([
            'Alert',
            item.title,
            item.owner,
            item.severity,
            `${LinkPre}/#!/r/alert-manager?alertIdToScrollTo=${item.id}`,
          ]),
        );
      }

      if (dashboards.length) {
        dashboards.map((item) =>
          rows.push([
            'Dashboard',
            item.title,
            item.owner,
            '',
            `${LinkPre}.#!/dashboards?activeTab=1&tabs=main;${item.id}`,
          ]),
        );
      }

      if (composites.length) {
        composites.map((item) => rows.push(['Composite', item.title, item.owner, '', '']));
      }

      const csvContent = rows.map((e) => e.join(',')).join('\n');

      const a = document.createElement('a');
      a.href = URL.createObjectURL(new Blob([csvContent], {type: 'text/csv;charset=utf-8'}));
      a.download = `${dataStreamName} data.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    render() {
      const {isLoading, alerts, dashboards, composites} = this.props;

      return (
        <Button
          colorSchema={COLORS.TRANSPARENT_BLUE}
          text="Export to CSV"
          icon="icn-action16-download"
          isDisabled={isLoading || (!alerts.length && !composites.length && !dashboards.length)}
          onClick={this.exportCsv}
        />
      );
    }
  },
);
