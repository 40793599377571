import {combineReducers} from 'redux';
import {composeReducers} from 'common/utils/reducers';
import data from 'alerts.console.new/store/reducers/dataReducer';
import views from 'alerts.console.new/store/reducers/viewsReducer';
import * as actions from 'alerts.console.new/store/actions';

const root = combineReducers({
  views,
  data,
});

export default composeReducers(root, (state, action) => {
  if (action.type === actions.resetAlertsConsole.TYPE) {
    return root(undefined, {type: 'no-action'});
  }
  return state;
});
