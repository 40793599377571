// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getGroupSectionGroupList} from 'admin.users/store/selectors';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';

type PropTypes = {
  value: String,
  onChange: Function,
  onMenuClose: Function,
  refSelectComponent: Function,
  onPrimaryGroupApply: Function,

  // connect
  groups: Array<Object>,
};

const EMPTY_OBJECT = {};

export default connect(
  (state) => ({
    groups: getGroupSectionGroupList(state),
  }),
  {},
)(
  class UGPrimaryGroup extends PureComponent {
    props: PropTypes;

    render() {
      const {groups, value, onChange, onMenuClose, refSelectComponent, onPrimaryGroupApply} = this.props;

      const selectedItem = groups.find((item) => item.id === value) || EMPTY_OBJECT;

      return (
        <SelectAndt
          automationId="bulkPrimaryGroup"
          type={TYPE_NEW_SEARCH}
          theme={THEME_NOT_HIGHLIGHTED}
          onChange={onChange}
          value={selectedItem || null}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.id}
          options={groups}
          optionHeight={40}
          menuWidth={310}
          showValue
          onMenuClose={onMenuClose}
          refSelectComponent={refSelectComponent}
          placeholder="Select Primary Group"
          closeMenuOnSelect={false}
          closeMenuOnScroll={false}
          openMenuOnFocus
          menuFooterComponent={
            <FormDdlActionBar isVisible isApplyDisabled={!selectedItem} onApply={onPrimaryGroupApply} />
          }
        />
      );
    }
  },
);
