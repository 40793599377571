// flow
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'insightsPanel/store/selectors';
import {getProfileId, getTimeZoneName, getUserProfile} from 'profile/store/selectors';
import {segmentCausingEvent, segmentClickEvent} from 'common/store/actions';
import * as actions from 'insightsPanel/store/actions';
import {setFeedback as setFeedbackAction} from 'alerts.console/store/actions';
import {FEEDBACK_ORIGIN, FEEDBACK_TYPE} from 'feedback/services/constants';
import {Carousel} from 'react-responsive-carousel';
import {shouldFetchData} from 'insightsPanel/services/constants';
import MetricName from 'metrics/components/metricName/MetricName';
import AnomalyAlertDelta from 'alerts.console/components/alertsList/alertContent/anomalyAlert/AnomalyAlertDelta';
import AlertDate from 'alerts.console/components/alertsList/alertContent/AlertDate';
import AlertDuration from 'alerts.console/components/alertsList/alertContent/AlertDuration';

import './QuickFeedback.module.scss';

const CAROUSEL_SETTINGS = {
  showArrows: false,
  showIndicators: false,
  infiniteLoop: false,
  showThumbs: false,
  showStatus: false,
  transitionTime: 400,
};

const ALERT_INFO_WRAPPER_CLOSE = 80;

const QuickFeedback = (props: {title: string}) => {
  const dispatch = useDispatch();
  const meId = useSelector(getProfileId);
  const meProfile = useSelector(getUserProfile);
  const timeZoneName = useSelector(getTimeZoneName);
  const isPanelOpen = useSelector(selectors.getIsPanelOpen);
  const noFeedbackAlerts = useSelector(selectors.getNoFeedbackAlertsData);
  const isLoading = useSelector(selectors.getNoFeedbackAlertsIsLoading);
  const openAlerts = useSelector(selectors.getOpenAlerts);

  const [firstLaunch, setFirstLaunch] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [tmpNoFeedbackAlerts, setTmpNoFeedbackAlerts] = useState([]);
  const [isCompletedTask, setIsCompletedTask] = useState(false);
  const [isMetricInfoActive, setIsMetricInfoActive] = useState(false);
  const [isMetricInfoActive2, setIsMetricInfoActive2] = useState(false); // for timer to set animation
  const [infoWrapperHeight, setInfoWrapperHeight] = useState(ALERT_INFO_WRAPPER_CLOSE);

  let metricInfoTimer;

  const metricWrapper = useRef(null);

  const resetState = () => {
    setTmpNoFeedbackAlerts(noFeedbackAlerts);
    setCurrentSlide(0);
    setIsCompletedTask(false);
    setIsMetricInfoActive(false);
  };

  useEffect(() => {
    if (isLoading === false && noFeedbackAlerts.length) {
      dispatch(segmentCausingEvent({category: 'Insights Panel', name: 'Quick Feedback - Widget Loaded'}));
    } else if (isLoading === false && !noFeedbackAlerts.length) {
      dispatch(segmentCausingEvent({category: 'Insights Panel', name: 'Quick Feedback - Widget Not Loaded'}));
    }
  }, [noFeedbackAlerts]);

  useEffect(() => {
    if (isPanelOpen && shouldFetchData(openAlerts)) {
      dispatch(
        actions.fetchNoFeedbackAlerts({
          subscribers: meId,
          loginId: meId,
          numAlerts: 3,
          alertCloseFrom: Math.round(new Date() / 1000) - 30 * 24 * 60 * 60, // alerts that didn't get feedback in the last 30 days
        }),
      );
    }
    return () => {
      clearTimeout(metricInfoTimer);
    };
  }, [isPanelOpen]);

  useEffect(() => {
    if (noFeedbackAlerts.length && firstLaunch) {
      resetState();
      setFirstLaunch(false);
    }
  }, [isLoading, noFeedbackAlerts, firstLaunch]);

  useEffect(() => {
    if (isMetricInfoActive) {
      setInfoWrapperHeight(metricWrapper.current.offsetHeight + (ALERT_INFO_WRAPPER_CLOSE + 15));
      metricInfoTimer = setTimeout(() => setIsMetricInfoActive2(true), 100);
    } else {
      setInfoWrapperHeight(ALERT_INFO_WRAPPER_CLOSE);
      setIsMetricInfoActive2(false);
      clearTimeout(metricInfoTimer);
    }
  }, [isMetricInfoActive]);

  const showMetricInfo = (e) => {
    if (e) {
      setIsMetricInfoActive(!isMetricInfoActive);
      if (!isMetricInfoActive) {
        dispatch(segmentClickEvent({category: 'Insights Panel', name: 'Quick Feedback - Show metric info'}));
      }
    } else {
      setIsMetricInfoActive(false);
    }
  };

  const nextSlide = () => {
    if (currentSlide + 1 === tmpNoFeedbackAlerts.length) {
      setIsCompletedTask(true);
      dispatch(segmentClickEvent({category: 'Insights Panel', name: 'Quick Feedback - All Done'}));
    } else {
      setCurrentSlide(currentSlide + 1);
      dispatch(segmentClickEvent({category: 'Insights Panel', name: 'Quick Feedback - Skip'}));
    }
    showMetricInfo();
  };

  const setFeedback = (type) => {
    const alert = tmpNoFeedbackAlerts[currentSlide];

    dispatch(
      setFeedbackAction(
        {
          type,
          triggeredId: alert.id,
          userId: meId,
          id: undefined,
          userName: `${meProfile.firstName}${' '}${meProfile.lastName}` || 'Unknown',
          origin: FEEDBACK_ORIGIN.INSIGHT_PANEL,
        },
        {alertId: alert.id, type: null},
      ),
    );
    dispatch(segmentClickEvent({category: 'Insights Panel', name: `Quick Feedback - ${type}`}));

    nextSlide();
  };

  const renderMetricInfo = () => {
    // we dont want to open all the info containers at once so we do it one by one.
    const alert = tmpNoFeedbackAlerts[currentSlide];

    return (
      <div ref={metricWrapper} styleName={isMetricInfoActive2 ? 'metric-dec-wrapper active' : 'metric-dec-wrapper'}>
        <div styleName="metric-measure-dimensions">
          <MetricName metric={alert.metrics[0]} />
        </div>
        <div styleName="metric-delta">
          <AnomalyAlertDelta metric={alert.metrics[0]} />
        </div>
      </div>
    );
  };

  return (
    !isLoading &&
    !!tmpNoFeedbackAlerts.length && (
      <div styleName="body gray">
        <header styleName="header">
          {props.title} ({currentSlide + 1}/{tmpNoFeedbackAlerts.length})
        </header>

        <div styleName="container">
          {isCompletedTask && (
            <div styleName="completed-task-wrapper">
              <span role="img" aria-label="complete">
                👌
              </span>
              <p>You’re all done for now, Thank you for your feedback!</p>
            </div>
          )}

          {!isCompletedTask && (
            <Fragment>
              <p styleName="title">Was this alert a good catch?</p>
              <Carousel {...CAROUSEL_SETTINGS} selectedItem={currentSlide}>
                {tmpNoFeedbackAlerts.map((alert) => (
                  <div style={{height: `${infoWrapperHeight}px`}} styleName="alert-info-wrapper" key={alert.id}>
                    <p styleName="alert-title">{alert.title}</p>
                    {isMetricInfoActive && renderMetricInfo()}
                    <div styleName="alert-dec-wrapper">
                      <div styleName="alert-timing-wrapper">
                        <div styleName="start">
                          <span>Started</span> <AlertDate startDate={alert.startTime} timeZone={timeZoneName} />
                        </div>
                        <div styleName="duration">
                          <span>Lasted</span> <AlertDuration duration={alert.duration} />
                        </div>
                      </div>
                      <p styleName="alert-measure" onClick={showMetricInfo}>
                        {alert.metrics[0].what}
                      </p>
                    </div>
                  </div>
                ))}
              </Carousel>
              <div styleName="feedback-wrapper">
                <div onClick={() => setFeedback(FEEDBACK_TYPE.GOOD_CATCH)}>
                  <span role="img" aria-label="good-catch">
                    👍
                  </span>
                  <p>Good Catch</p>
                </div>
                <div onClick={() => setFeedback(FEEDBACK_TYPE.NOT_INTERESTING)}>
                  <span role="img" aria-label="not-interesting">
                    👎
                  </span>
                  <p>Not Interesting</p>
                </div>
                <div onClick={nextSlide}>
                  <p>Skip</p>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    )
  );
};

export default QuickFeedback;
