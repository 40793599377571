// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc.cubes/store/selectors';
import Loader from 'common/components/Loader';
import './CubeStatus.module.scss';

type PropTypes = {
  className: string,
  cubePreviewLevelCount: Object,
  isLoading: boolean,
};

export default connect(
  (state) => ({
    cubePreviewLevelCount: selectors.getSelectedCubePreviewCubeLevelCount(state),
    isLoading: selectors.getSelectedCubePreviewIsLoading(state),
  }),
  {},
)(
  class CubeStatus extends PureComponent {
    props: PropTypes;

    render() {
      const {className, isLoading, cubePreviewLevelCount} = this.props;
      let total = 0;

      if (isLoading) {
        return (
          <div styleName="root" className={className}>
            <div className="andt-section-header">Cube Status</div>
            <Loader />
          </div>
        );
      }

      return (
        <div styleName="root" className={className}>
          <div className="andt-section-header">Cube Status</div>
          <div>
            {Object.keys(cubePreviewLevelCount).map((level) => {
              total += cubePreviewLevelCount[level];
              return <div key={level}>{`Level-${level}: ${cubePreviewLevelCount[level]}`}</div>;
            })}
            <div>{`Total: ${total}`}</div>
          </div>
        </div>
      );
    }
  },
);
