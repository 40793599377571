// @flow

import {getUniqueId} from 'common/utils/guid';
import {isEqual} from 'lodash';

export default class ExpressionItem {
  constructor(
    value: string,
    label: string,
    parent: string,
    itemValues: Array<Object>,
    allowMulti: boolean,
    isNew: boolean,
    uiData: Object,
    isNot: boolean,
    isExact: boolean,
    isLegacy: boolean,
    streamsAvailable: Array<Object>,
    iconType: string,
  ) {
    this._value = value;
    this._label = label;
    this._parent = parent;
    this._itemValues = itemValues;
    this._id = getUniqueId();
    this._allowMulti = allowMulti;
    this._isNew = isNew;
    this._uiData = uiData;
    this._isNot = isNot;
    this._isExact = isExact;
    this._isLegacy = isLegacy;
    this._streamsAvailable = streamsAvailable;
    this._iconType = iconType;
  }

  _value: string;

  _label: string;

  _parent: ?string;

  _showKey: ?boolean;

  _itemValues: ?Array<ExpressionItem>;

  _id: string;

  _allowMulti: boolean;

  _isNew: boolean;

  _uiData: Object;

  _isNot: boolean;

  _isExact: boolean;

  _isLegacy: boolean;

  _streamsAvailable: Array<Object>;

  _iconType: string;

  get value(): string {
    return this._value;
  }

  get label(): string {
    return this._label;
  }

  get showKey(): boolean {
    return this._showKey;
  }

  get itemValues(): Array<ExpressionItem> {
    return this._itemValues;
  }

  get parent(): String {
    return this._parent;
  }

  get id(): String {
    return this._id;
  }

  get allowMulti(): boolean {
    return this._allowMulti;
  }

  get isNew(): boolean {
    return this._isNew;
  }

  get uiData(): Object {
    return this._uiData;
  }

  get isNot(): Array<String> {
    return this._isNot;
  }

  get isExact(): boolean {
    return this._isExact;
  }

  get isLegacy(): boolean {
    return this._isLegacy;
  }

  get streamsAvailable(): Array<Object> {
    return this._streamsAvailable;
  }

  get iconType(): string {
    return this._iconType;
  }

  set value(val: string) {
    this._value = val;
  }

  set label(val: string) {
    this._label = val;
  }

  set parent(val: string) {
    this._parent = val;
  }

  set showKey(val: boolean) {
    this._showKey = val;
  }

  set itemValues(val: Array<ExpressionItem>) {
    this._itemValues = val;
  }

  set allowMulti(val: boolean) {
    this._allowMulti = val;
  }

  set isNew(val: boolean) {
    this._isNew = val;
  }

  set uiData(val: Object) {
    this._uiData = val;
  }

  set isNot(val: boolean) {
    this._isNot = val;
  }

  set isExact(val: boolean) {
    this._isExact = val;
  }

  set isLegacy(val: boolean) {
    this._isLegacy = val;
  }

  set streamsAvailable(val: Array<Object>) {
    this._streamsAvailable = val;
  }

  set iconType(val: string) {
    this._iconType = val;
  }

  duplicateExpression = () =>
    new ExpressionItem(
      this._value,
      this._label,
      this._parent,
      this._itemValues,
      this._allowMulti,
      this._isNew,
      this._uiData,
      this._isNot,
      this._isExact,
      this._isLegacy,
      this._streamsAvailable,
      this._iconType,
    );

  removeItemValue = (item: ExpressionItem) => {
    const index = this._itemValues.findIndex((itemValue) => itemValue.value === item.value);
    this._itemValues = this._itemValues.slice(0, index).concat(this._itemValues.slice(index + 1));
  };

  addItemValue = (item: ExpressionItem) => {
    this._itemValues = [...this._itemValues, item];
  };

  addItemValueByString = (value) => {
    const newItem = new ExpressionItem(
      value,
      value,
      this._value,
      this._itemValues,
      this._allowMulti,
      this._isNew,
      this._uiData,
      this._isNot,
      this._isExact,
    );
    this.addItemValue(newItem);
  };

  itemsToString = () => {
    if (!this._itemValues || this._itemValues.length === 0) {
      return '';
    }
    const retArr = [];
    this._itemValues.forEach((itemValue) => {
      retArr.push(itemValue.value);
    });
    let retString = retArr.join(' OR ');
    if (this._isNot) {
      retString = `NOT(${retString})`;
    }
    return retString;
  };

  static getlabelByOriginId = (type, originInfo, id) => {
    const searchArr = originInfo && originInfo[type];
    if (searchArr) {
      const searchItem = searchArr.find((item) => item.id === id);
      return searchItem ? searchItem.title : '';
    }
    return '';
  };

  static isCanonical = (value: string) => {
    if (value === 'NOT()') {
      // special case when building an expression
      return true;
    }

    // eslint-disable-next-line no-control-regex
    const canonicalTest = /^(NOT)?\(?((([^\u0000-\u007F]|\w)+\sOR\s)*)?((([^\u0000-\u007F]|\w)+\*?)+|\*)\)?$/gu;
    if (value === undefined || value === null || value === '') {
      return true;
    }
    if (value.indexOf('!') > -1 || value.indexOf(' AND ') > -1 || value.indexOf('AND ') > -1) {
      return false;
    }
    const indexMinus = value.indexOf(' -');
    if (indexMinus > -1) {
      const nextChar = value[indexMinus + 1];
      if (isNaN(parseInt(nextChar, 10))) {
        return false;
      }
    }
    let rewrittenValue = value.replaceAll('-', '_');

    const replaceChars = [
      '-',
      ';',
      ':',
      ',',
      '.',
      '~',
      '|',
      '/',
      '#',
      '$',
      '&',
      '^',
      '*',
      '+',
      '=',
      '[',
      ']',
      '(',
      ')',
      '{',
      '}',
      '@',
      '%',
      "'",
      '?',
      '>',
      '<',
      '!',
      '"',
      '^',
      '_',
      '`',
      '|',
      ' ',
      '\\',
    ];
    replaceChars.forEach((val) => {
      rewrittenValue = rewrittenValue.replaceAll(val, '_');
    });
    rewrittenValue = rewrittenValue.trim();
    rewrittenValue = rewrittenValue.replace(/  +/g, ' ');

    const res = canonicalTest.test(rewrittenValue);
    return res;
  };

  static setExpressionFromObject = (obj: Object, originInfo) => {
    // const values = obj.value.split(' OR ');
    let isNot = false;
    let values;
    let isExact = false;
    if (typeof obj.isExact !== 'undefined') {
      // eslint-disable-next-line prefer-destructuring
      isExact = obj.isExact;
    }

    if (typeof obj.exact !== 'undefined') {
      isExact = obj.exact;
    }

    if (ExpressionItem.isCanonical(obj.value)) {
      if (obj.value.indexOf('NOT(') > -1) {
        isNot = true;
        const vals = obj.value.substring(obj.value.lastIndexOf('(') + 1, obj.value.lastIndexOf(')'));
        values = vals.split(' OR ');
      } else {
        values = obj.value.split(' OR ');
      }
      let keyName = obj.key === 'what' ? 'Measures' : obj.key;
      let parent = keyName;
      if ((obj.originType || '').toLocaleLowerCase() === 'stream') {
        keyName = 'Streams';
        parent = 'Streams';
      }
      if ((obj.originType || '').toLocaleLowerCase() === 'alert') {
        keyName = 'Alerts';
        parent = 'Alerts';
      }
      if ((obj.originType || '').toLocaleLowerCase() === 'composite') {
        keyName = 'Composites';
        parent = 'Composites';
      }
      if ((obj.originType || '').toLocaleLowerCase() === 'forecast') {
        keyName = 'Forecasts';
        parent = 'Forecasts';
      }
      let itemValues = values.map((val) => {
        let value = val;
        if (value.charAt(0) === '\\') {
          value = val.slice(1);
        }
        if (obj.originType && obj.originType.toLocaleLowerCase() !== 'forecast') {
          value = ExpressionItem.getlabelByOriginId(obj.originType.toLocaleUpperCase(), originInfo, val);
        }
        return new ExpressionItem(val, value === '*' ? 'All (*)' : value, parent, null);
      });
      itemValues = itemValues.filter((val) => val.label !== '');
      return new ExpressionItem(keyName, keyName, null, itemValues, null, null, null, isNot, isExact);
    }
    const keyName = obj.key;
    const itemValues = [
      new ExpressionItem(obj.value, obj.value, keyName, null, null, null, null, false, isExact, true),
    ];
    return new ExpressionItem(keyName, keyName, null, itemValues, null, null, null, false, isExact, true, [], null);
  };

  isSingleAsterisc = (val) => {
    const ret = val.indexOf('*') > -1;
    if (val.indexOf('**') > -1) {
      return false;
    }
    // Todo  Gabi - Need to think how we enable * as whildcard also in the middle of the value.
    if (val.lastIndexOf('*') !== val.length - 1) {
      return false;
    }
    return ret;
  };

  getExpressionTreeObjectification = () => {
    const getScapeCharForNegativeNumber = (value) => {
      if (!isNaN(value - 0) && value - 0 < 0) {
        return `\\${value}`;
      }
      return value;
    };

    const ret = {};

    if (this.value === 'search') {
      ret.type = 'search';
      ret.value = this._itemValues.map((itemValue) => itemValue.value).join(' OR ');
      ret.key = 'Search';
      return ret;
    }
    ret.key = this._value === 'Measures' ? 'what' : this.value;
    ret.type = 'property';
    switch (this._value) {
      case 'Streams':
        ret.type = 'origin';
        ret.originType = 'Stream';
        ret.key = 'originId';
        break;
      case 'Alerts':
        ret.type = 'origin';
        ret.originType = 'Alert';
        ret.key = 'originId';
        break;
      case 'Composites':
        ret.type = 'origin';
        ret.originType = 'Composite';
        ret.key = 'originId';
        break;
      case 'Forecasts':
        ret.type = 'origin';
        ret.originType = 'Forecast';
        ret.key = 'originTitle';
        break;
      default:
    }

    if (this._isNot) {
      ret.value = `NOT(${this._itemValues
        .map((itemValue) => getScapeCharForNegativeNumber(itemValue.value))
        .join(' OR ')})`;
    } else {
      ret.value = this._itemValues.map((itemValue) => itemValue.value).join(' OR ');
    }

    ret.isExact = true;

    if (this.isSingleAsterisc(ret.value) || ret.key === 'Search') {
      ret.isExact = false;
    }
    if (this._isNot) {
      ret.isExact = false;
    }
    if (typeof ret.isExact === 'undefined') {
      ret.isExact = true;
    }
    if (this.value === 'Forecasts') {
      ret.isExact = false;
    }
    // ret.isExact = this._isNot ? false : !(ret.value.indexOf('*') > -1 || ret.key === 'Search');
    return ret;
  };

  isEqual = (otherExpression: ExpressionItem) => {
    if (this._value !== otherExpression.value) {
      return false;
    }
    if (this._itemValues.length !== otherExpression.itemValues.length) {
      return false;
    }
    if (
      this._isExact !== otherExpression.isExact ||
      this._isNew !== otherExpression.isNew ||
      this._allowMulti !== otherExpression.allowMulti ||
      this._isLegacy !== otherExpression.isLegacy ||
      this._isNot !== otherExpression.isNot
    ) {
      return false;
    }
    const thisItemsValues = this._itemValues.map((itemValue) => itemValue.value).sort();
    const otherItemsValues = otherExpression.itemValues.map((itemValue) => itemValue.value).sort();
    if (isEqual(thisItemsValues, otherItemsValues)) {
      return true;
    }
    return false;
  };
}
