// @flow
/* eslint-disable */
import React, {useState, useEffect, useRef} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import {COLORS} from 'common/componentsV2/Button';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import UserEventsTableRow from 'userEvents/components/UserEventsTable/UserEventsTableRow';
import {isString} from 'lodash';
import './UserEventsTable.module.scss';

type PropTypes = {
  events: Array<Object>,
  isLoading: boolean,
  searchString: string,
  timeZoneName: string,
};

const EMPTY_ARRAY = [];

const UserEventsTable = ({events, isLoading, searchString, timeZoneName}: PropTypes) => {
  const tableBodyRef = useRef(null);
  const [sortedEvents, setSortedEvents] = useState(EMPTY_ARRAY);
  const [listOrder, setListOrder] = useState({
    column: 'title',
    direction: 'asc',
  });
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    const sortingFunction = (arr, sortBy, direction) =>
      arr.sort((a, b) => {
        const itemA = isString(a[sortBy]) ? a[sortBy].toLowerCase() : a[sortBy];
        const itemB = isString(b[sortBy]) ? b[sortBy].toLowerCase() : b[sortBy];
        if (itemA < itemB) {
          return -1 * direction;
        }
        if (itemA > itemB) {
          return direction;
        }
        return 0;
      });

    setSortedEvents([...sortingFunction(events, listOrder.column, listOrder.direction === 'desc' ? -1 : 1)]);
  }, [events, listOrder]);

  useEffect(() => {
    recalculateScrolbarWidth();
  }, [sortedEvents, selectedItemId]);

  useEffect(() => {
    setSelectedItemId(null);
  }, [events]);

  const setTableBodyRef = (elm) => {
    tableBodyRef.current = elm;
  };

  const recalculateScrolbarWidth = () => {
    let scrollBarWidth = 0;
    if (tableBodyRef.current) {
      scrollBarWidth = tableBodyRef.current.offsetWidth - tableBodyRef.current.clientWidth;
    }
    setScrollBarWidth(scrollBarWidth);
  };

  const handleResize = () => {
    recalculateScrolbarWidth();
  };

  const handleTitleOrderChange = (columnName) => {
    let updatedDirection = 'desc';
    if (['title', 'category'].indexOf(columnName) !== -1) {
      updatedDirection = 'asc';
    }

    if (columnName === listOrder.column) {
      updatedDirection = listOrder.direction === 'desc' ? 'asc' : 'desc';
    }
    setListOrder({column: columnName, direction: updatedDirection});
  };

  const setSelectedItem = (itemId) => {
    if (itemId === selectedItemId) {
      setSelectedItemId(null);
    } else {
      setSelectedItemId(itemId);
    }
  };

  if (isLoading) {
    return (
      <div styleName="spinner-wrapper">
        <Spinner color={COLORS.GRAY_500} size={SpinnerSizes.XX_BIG_150} />
      </div>
    );
  }

  const columns = [
    {id: 0, name: 'title', title: 'Event'},
    {id: 1, name: 'category', title: 'Category'},
    {id: 2, name: 'startDate', title: 'Started'},
    {id: 3, name: 'endDate', title: 'Ended'},
  ];

  return (
    <div styleName="table">
      <ReactResizeDetector handleWidth handleHeight onResize={handleResize} />
      <div styleName="table-header" style={{paddingRight: `${16 + 2 + scrollBarWidth}px`}}>
        {columns.map((col) => (
          <div styleName="col-header" key={`user-events-list-${col.id}`}>
            <TitleHeaderTableOrder
              title={col.title}
              columnName={col.name}
              onChange={handleTitleOrderChange}
              selectedDirection={listOrder.direction}
              isSortable
              isActive={col.name === listOrder.column}
            />
          </div>
        ))}
      </div>

      <div styleName="table-body" ref={setTableBodyRef}>
        {sortedEvents.map((ev) => (
          <UserEventsTableRow
            key={ev.id}
            event={ev}
            searchString={searchString}
            timeZoneName={timeZoneName}
            setSelectedItem={setSelectedItem}
            isSelected={selectedItemId === ev.id}
          />
        ))}
      </div>
    </div>
  );
};

export default React.memo(UserEventsTable);
