import {StringParam, withDefault} from 'use-query-params';

export const TYPE_COMPOSITES = {
  COMPOSITES: 'COMPOSITE',
  ALERT: 'ALERT',
};

export const QUERY_KEYS = {
  COMPOSITE_LIST: 'compositesList',
  DELETE_COMPOSITE: 'deleteComposite',
  COMPOSITE_NAME: 'deleteName',
  COMPOSITE_DELAY: 'compositeDelay',
};

export const PARAMS_NAME = {
  SEARCH_QUERY: 'searchQuery',
  TYPE: 'type',
  OWNER: 'ownerId',
  STREAM: 'stream',
  STATUS: 'status',
  LABEL: 'label',
};

export const OPTIONS_TYPE_COMPOSITES_VALUES = {
  ALL: 'all',
  COMPOSITE: 'composite',
  ALERT: 'alert',
};

export const DEFAULT_PARAMS = {
  [PARAMS_NAME.SEARCH_QUERY]: undefined,
  [PARAMS_NAME.TYPE]: OPTIONS_TYPE_COMPOSITES_VALUES.ALL,
  [PARAMS_NAME.OWNER]: undefined,
  [PARAMS_NAME.STREAM]: undefined,
  [PARAMS_NAME.STATUS]: undefined,
  [PARAMS_NAME.LABEL]: undefined,
};

export const DEFAULT_PARAMS_CONFIG = {
  [PARAMS_NAME.SEARCH_QUERY]: StringParam,
  [PARAMS_NAME.TYPE]: withDefault(StringParam, OPTIONS_TYPE_COMPOSITES_VALUES.ALL),
  [PARAMS_NAME.OWNER]: StringParam,
  [PARAMS_NAME.STREAM]: StringParam,
  [PARAMS_NAME.STATUS]: StringParam,
  [PARAMS_NAME.LABEL]: StringParam,
};

export const OPTIONS_TYPE_COMPOSITES = [
  {
    value: OPTIONS_TYPE_COMPOSITES_VALUES.ALL,
    label: 'All',
  },
  {
    value: OPTIONS_TYPE_COMPOSITES_VALUES.COMPOSITE,
    label: 'Composite',
  },
  {
    value: OPTIONS_TYPE_COMPOSITES_VALUES.ALERT,
    label: 'Alert',
  },
];

export const OPTIONS_STATUS_VALUES = {
  ACTIVE: 'active',
  ERROR: 'error',
  PAUSED: 'paused',
};

export const OPTIONS_STATUS = [
  {
    value: OPTIONS_STATUS_VALUES.ACTIVE,
    label: 'Active',
  },
  {
    value: OPTIONS_STATUS_VALUES.ERROR,
    label: 'Error',
  },
  {
    value: OPTIONS_STATUS_VALUES.PAUSED,
    label: 'Paused',
  },
];

export const OPTIONS_ACTIONS_TOOLBAR_VALUE = {
  DUPLICATE: 'duplicate',
  EDIT: 'edit',
  DELETE: 'delete',
  CREATE_ALERT: 'create alert',
  COMPOSITE_DELAY: 'composite delay',
  USAGE_HISTORY: 'usage history',
  VIEW_ACTIVITY_LOG: 'view activity log',
};

export const OPTIONS_ACTIONS_TOOLBAR = [
  // {
  //   value: OPTIONS_ACTIONS_TOOLBAR_VALUE.DUPLICATE,
  //   label: 'Duplicate',
  //   type: ['composite'],
  //   icon: 'icn-action16-copy',
  // },
  {
    value: OPTIONS_ACTIONS_TOOLBAR_VALUE.EDIT,
    label: 'Edit',
    type: ['alert'],
    icon: 'icn-action16-edit',
  },
  // {
  //   value: OPTIONS_ACTIONS_TOOLBAR_VALUE.CREATE_ALERT,
  //   label: 'Create Alert',
  //   type: ['composite'],
  //   icon: 'icn-nav24-alertconsole',
  // },
  {
    value: OPTIONS_ACTIONS_TOOLBAR_VALUE.COMPOSITE_DELAY,
    label: 'Composite Delay',
    type: ['composite', 'alert'],
    icon: 'icn-general16-timescale',
  },
  // {
  //   value: OPTIONS_ACTIONS_TOOLBAR_VALUE.USAGE_HISTORY,
  //   label: 'Usage History',
  //   type: ['composite', 'alert'],
  //   icon: 'icn-time-stamp-icon',
  // },
  {
    value: OPTIONS_ACTIONS_TOOLBAR_VALUE.DELETE,
    label: 'Delete',
    type: ['composite'],
    icon: 'icn-action16-delete',
  },
];

export const DELAY_TIME_SCALES = [
  {text: 'Seconds', factor: 1, shortText: 's', displayVal: 'Second'},
  {text: 'Minutes', factor: 60, shortText: 'm', displayVal: 'Minute'},
  {text: 'Hours', factor: 60 * 60, shortText: 'h', displayVal: 'Hour'},
];

export const COMPOSITE_COLUMNS = {
  TITLE: 'title',
  TYPE: 'mtype',
  ERROR: 'error',
  OWNER: 'ownerName',
  DATECREATE: 'creationTime',
  METRICS_USAGE: 'metricsUsageCount',
  ALERT_USAGE: 'usedInAlerts',
};

export function calculateTime(calcDelayTime) {
  if (calcDelayTime >= 3600) {
    // 24h+
    return DELAY_TIME_SCALES[2];
  }
  if (calcDelayTime >= 60) {
    // 10h+
    return DELAY_TIME_SCALES[1];
  }
  return DELAY_TIME_SCALES[0];
}
export const getLabel = (options, value) =>
  options.flatMap((o) => (value.includes(o.value) ? [o.label] : [])).join(', ');

const roundNumber = (num, factor) => {
  return factor === 1
    ? num / factor
    : Number(num / factor)
        .toFixed(3)
        .replace(/\.0+$/, '');
};

export const getDelayText = (type, value, scale) => {
  if (!value) return 'None';
  const calcValue = roundNumber(value, scale?.factor);
  return `${type}: ${calcValue} ${calcValue > 1 ? scale?.text : scale?.displayVal}`;
};
