/* eslint-disable */
// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import {createRef} from 'react';

type WPDefaultProps = {|
  measureBeforeMount: boolean,
|};

// eslint-disable-next-line no-unused-vars
type WPProps = {|
  className?: string,
  style?: Object,
  ...WPDefaultProps,
|};

type WPState = {|
  width: number,
|};

/*
 * A simple HOC that provides facility for listening to container resizes.
 *
 * The Flow type is pretty janky here. I can't just spread `WPProps` into this returned object - I wish I could - but it triggers
 * a flow bug of some sort that causes it to stop typechecking.
 */
export default function WidthProvider<Config>(
  ComposedComponent: React.AbstractComponent<Config>,
): React.AbstractComponent<{|
  ...Config,
  measureBeforeMount?: boolean,
  className?: string,
  style?: Object,
  width?: number,
|}> {
  return class WidthProvider extends React.Component<
    {|
      ...Config,
      measureBeforeMount?: boolean,
      className?: string,
      style?: Object,
      width?: number,
    |},
    WPState,
  > {
    static defaultProps: WPDefaultProps = {
      measureBeforeMount: false,
    };

    static propTypes = {
      // If true, will not render children until mounted. Useful for getting the exact width before
      // rendering, to prevent any unsightly resizing.
      measureBeforeMount: PropTypes.bool,
    };

    state = {
      width: 1280,
    };

    mounted: boolean = false;

    componentDidMount() {
      this.mounted = true;

      window.addEventListener('resize', this.onWindowResize);
      // Call to properly set the breakpoint and resize the elements.
      // Note that if you're doing a full-width element, this can get a little wonky if a scrollbar
      // appears because of the grid. In that case, fire your own resize event, or set `overflow: scroll` on your body.
      this.onWindowResize();
    }

    componentWillUnmount() {
      this.mounted = false;
      window.removeEventListener('resize', this.onWindowResize);
    }

    wrapper = createRef();

    onWindowResize = () => {
      if (!this.mounted) return;
      const node = this.wrapper.current;
      if (node instanceof HTMLElement)
        this.setState({width: node.offsetWidth, rowHeight: Math.ceil(node.offsetWidth / 12 / 1.7)});
    };

    render() {
      const {measureBeforeMount, gridRef, ...rest} = this.props;
      if (measureBeforeMount && !this.mounted) {
        return <div ref={this.wrapper} className={this.props.className} style={this.props.style} />;
      }

      return (
        <div ref={this.wrapper}>
          <ComposedComponent {...rest} {...this.state} ref={gridRef} />
        </div>
      );
    }
  };
}
