import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {palette} from 'app/styles/theme';

const useStyles = makeStyles({
  '@keyframes placeholder_animation': {
    '0%': {
      backgroundColor: palette.blue[50],
      animationTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
    },
    '50%': {
      backgroundColor: palette.blue[250],
      animationTimingFunction: 'cubic-bezier(0, 0, 0.6, 1)',
    },
    '100%': {
      backgroundColor: palette.blue[50],
    },
  },
  dimensions: {
    width: '40%',
    height: '21px',
    borderRadius: '10.5px',
    marginBottom: '10px',
    animation: '$placeholder_animation 1.4s linear infinite both',
  },
  chart: {
    width: '100%',
    maxHeight: 250,
    flexGrow: 1,
    borderRadius: '10.5px',
    animation: '$placeholder_animation 1.4s linear infinite both',
  },
});

const LoadingPlaceholder = () => {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Box className={classes.dimensions} />
      <Box className={classes.chart} />
    </Box>
  );
};

export default LoadingPlaceholder;
