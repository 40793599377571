import {useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {isEmpty} from 'lodash';
import {getFormattedHumanDuration} from 'common/utils/dateService';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import {makeStyles} from '@material-ui/core';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import moment from 'moment';
import {getFetchTriggeredAlertIsLoading, getInvAlertMeasures, getInvModalTrigger} from 'investigation/store/selectors';

import '../OverwiewTab.module.scss';

const WhatInfoCubeNoData = ({onIsLoading}: {onIsLoading: Function}) => {
  const css = makeStyles(() => ({
    actionBtn: {
      maxWidth: 254,
    },
    whatTabChip: {
      maxWidth: 253,
      marginLeft: 0,
    },
  }));

  const alertMeasures = useSelector(getInvAlertMeasures);
  const trigger = useSelector(getInvModalTrigger);
  const isLoading = useSelector(getFetchTriggeredAlertIsLoading);
  const classes = css();
  const what = alertMeasures[0];

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);

  if (!alertMeasures.length || isEmpty(trigger)) {
    return null;
  }

  const isOpen = trigger.status.toLowerCase() === 'open';
  const durText = getFormattedHumanDuration(trigger.duration, isOpen ? 'absolute' : null).trim();
  const startTimeText = moment.unix(trigger.startTime).format('MMMM Do, YYYY h:mm:ss A');

  const fullDurText = isOpen ? ` for ${durText}` : `, lasted ${durText}`;
  const statusText = isOpen ? 'Open' : 'Closed';

  return (
    <div styleName="info-cube-container">
      <div>
        <Tooltip content={what} type={TYPES.SMALL}>
          <div styleName="tooltip-container">
            <InnerChip className={classes.whatTabChip} title={what} displayOnly type="measure" />
          </div>
        </Tooltip>
        <div styleName="what-info-cube-no-data">No Data Reported</div>
        <div styleName="what-info-cube-data-missing-time">Start time: {startTimeText}</div>
      </div>
      <div>
        <div styleName="what-info-cube-status-container">
          <div styleName={['what-info-cube-status-text', isOpen ? 'open' : ''].join(' ')}>{statusText}</div>
          <span>{fullDurText}</span>
        </div>
      </div>
    </div>
  );
};

export default WhatInfoCubeNoData;
