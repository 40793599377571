// @flow
import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';
import {getBucketStartTimeEnabled, getTimeZoneName} from 'profile/store/selectors';
import TimeSeriesChart from 'charts/timeSeries/components/TimeSeriesChart';
import TpMetricName from 'topologyGeneral/components/sidePanel/issuesSubPanel/TpMetricName';
import {fetchMetricDataPoints} from 'topologyGeneral/store/actions';
import useOnScreen from 'common/hooks/useOnScreen';
import './IssuesSubPanel.module.scss';

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: true,
};

const useStylesMetricItem = makeStyles(() => ({
  itemRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 8,
    backgroundColor: palette.white['500'],
    border: `1px solid ${palette.gray['300']}`,
    borderRadius: '8px',
    userSelect: 'none',
    '&:hover': {
      backgroundColor: palette.blue['50'],
      borderColor: palette.blue['300'],
    },
    minHeight: 68,
  },
  selected: {
    borderColor: palette.blue['500'],
    '&:hover': {
      borderColor: palette.blue['500'],
    },
  },
  metricName: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 12,
  },
  chart: {
    height: 150,
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    backgroundColor: palette.white['500'],
    paddingRight: 8,
    paddingLeft: 8,
  },
  open: {
    backgroundColor: palette.blue['50'],
  },
}));

const MetricItem = ({
  metric,
  chartId,
  timeScale,
  tooltipPlacement,
  isOpen,
  isSelected,
  onNameClick,
  onArrowClick,
}: PropTypes) => {
  const classes = useStylesMetricItem();
  const dispatch = useDispatch();

  const chartWrapperRef = useRef();
  const isVisible = useOnScreen(chartWrapperRef);

  const bucketStartTimeEnabled = useSelector(getBucketStartTimeEnabled);
  const timeZoneName = useSelector(getTimeZoneName);

  useEffect(() => {
    if (isOpen && isVisible) {
      dispatch(fetchMetricDataPoints(metric.metricId, {...metric, chartId}));
    }
  }, [isOpen, isVisible]);

  const locMetricNameClick = () => {
    if (onNameClick) {
      onNameClick(chartId);
    }
  };

  const locMetricArrowClick = () => {
    if (onArrowClick) {
      onArrowClick(chartId);
    }
  };

  return (
    <Box className={`${classes.itemRoot} ${isSelected ? classes.selected : ''} ${isOpen ? classes.open : ''}`}>
      <Box className={classes.metricName}>
        <TpMetricName
          metric={metric}
          measureTooltipPlacement={tooltipPlacement}
          onNameClick={locMetricNameClick}
          onArrowClick={locMetricArrowClick}
        />
      </Box>
      <Box
        ref={chartWrapperRef}
        styleName="chart-wrapper"
        className={classes.chart}
        display={isOpen ? 'block' : 'none'}
      >
        <TimeSeriesChart
          id={chartId}
          eventsMeta={{
            key: chartId,
            chartId,
          }}
          tooltip={TOOLTIP_OPTIONS}
          chartContainerStyle={{height: '150px'}}
          bucketStartTimeEnabled={bucketStartTimeEnabled}
          timeZoneName={timeZoneName}
          timeScale={timeScale}
        />
      </Box>
    </Box>
  );
};

MetricItem.propTypes = {
  metric: PropTypes.objectOf(PropTypes.any).isRequired,
  chartId: PropTypes.string.isRequired,
  timeScale: PropTypes.string.isRequired,
  tooltipPlacement: PropTypes.string,
  isOpen: PropTypes.bool,
  onNameClick: PropTypes.func,
  onArrowClick: PropTypes.func,
};

MetricItem.defaultProps = {
  tooltipPlacement: 'top',
  isOpen: false,
  onNameClick: null,
  onArrowClick: null,
};

export default MetricItem;
