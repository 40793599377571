import {intersection} from 'lodash';
import {palette} from 'app/styles/theme';

const ANOMALOUS = {
  fill: palette.white[500],
  fillRGBa: palette.opaqueColors.rose,
  stroke: palette.red[500],
};
const OPERATIONAL = {
  fill: palette.cyan[100],
  stroke: palette.mint[600],
};
const LABEL_STD = {
  fill: palette.white[500],
  fillRGBa: palette.opaqueColors.whiteO,
  stroke: palette.gray[500],
};
const NIS_STATUS = {
  inUse: {
    fill: palette.blue[300],
    stroke: palette.blue[500],
  },
  available: {
    fill: palette.cyan[100],
    stroke: palette.mint[600],
  },
  planned: {
    fill: palette.white[500],
    stroke: palette.gray[400],
  },
  deleted: {
    fill: palette.gray[400],
    stroke: palette.black[500],
  },
};

// some constants
export const DEFAULT_MAP_CENTER = [52.1217, 5.0499];
export const DEFAULT_MAP_ZOOM = 8;
export const MIN_RINGS_ZOOM = 11;
export const MIN_NODES_ZOOM = 11;
export const MAX_NODES_ZOOM = 14;
export const MAX_MAP_ZOOM = 16;
export const ZOOM_DELTA = 1;

export const DEFAULT_FILTER_OPTIONS = {
  zoom: true,
  bounds: true,
  anomaly: false,
  search: false,
};

export const ISSUE_SELECTED_FILTER_OPTIONS = {
  zoom: true,
  bounds: false,
  anomaly: true,
  search: false,
};

export const SEARCH_ITEM_SELECTED_FILTER_OPTIONS = {
  zoom: true,
  bounds: true,
  anomaly: false,
  search: true,
};

export const getStylesByNisStatus = (status) => {
  switch (status) {
    case '\u001e':
    case '\u001E':
      return {
        colors: NIS_STATUS.inUse,
      };
    case '\n':
      return {
        colors: NIS_STATUS.available,
      };
    case '\u0014':
    case '(':
    case '\u0000\u008C':
      return {
        colors: NIS_STATUS.planned,
        strokeDashArray: '6 2',
      };
    case 'Z':
    case 'z':
      return {
        colors: NIS_STATUS.deleted,
      };
    default:
      break;
  }

  return {
    colors: OPERATIONAL,
  };
};

export const nodeFillByType = (node) => {
  if (node.isAnomalous) {
    return ANOMALOUS.fill;
  }

  // eslint-disable-next-line prefer-destructuring
  let status = node.status;
  if (node.nodes && node.nodes.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    status = node.nodes[0].status;
  }
  if (status) {
    switch (status) {
      case '\u001e':
      case '\u001E':
        return NIS_STATUS.inUse.fill;
      case '\n':
        return NIS_STATUS.available.fill;
      case '\u0014':
      case '(':
      case '\u0000\u008C':
        return NIS_STATUS.planned.fill;
      case 'Z':
      case 'z':
        return NIS_STATUS.deleted.fill;
      default:
        break;
    }
  }

  return OPERATIONAL.fill;
};

export const nodeStrokeByType = (node) => {
  if (node.isAnomalous) {
    return ANOMALOUS.stroke;
  }

  // eslint-disable-next-line prefer-destructuring
  let status = node.status;
  if (node.nodes && node.nodes.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    status = node.nodes[0].status;
  }
  if (status) {
    switch (status) {
      case '\u001e':
      case '\u001E':
        return NIS_STATUS.inUse.stroke;
      case '\n':
        return NIS_STATUS.available.stroke;
      case '\u0014':
      case '(':
      case '\u0000\u008C':
        return NIS_STATUS.planned.stroke;
      case 'Z':
      case 'z':
        return NIS_STATUS.deleted.stroke;
      default:
        break;
    }
  }

  return OPERATIONAL.stroke;
};

export const getLabelStroke = (d) => {
  if (d.isAnomalous) {
    return ANOMALOUS.stroke;
  }

  return LABEL_STD.stroke;
};

export const getLabelFill = (d) => {
  if (d.isAnomalous) {
    return ANOMALOUS.fillRGBa;
  }

  return LABEL_STD.fillRGBa;
};

export const getNodeShapePoints = (d) => {
  const ncrShape = '12,0 24,12 12,24 0,12';
  const abrShape = '14,0 28,24 0,24';
  const accessNodeShapeHexagon = '12,0 1.584,6 1.584,18 12,24 22.416,18 22.416,6';

  if (d.highOrderType) {
    switch (d.highOrderType) {
      case 'NCR':
        return ncrShape;
      case 'ABR':
        return abrShape;
      default:
        return accessNodeShapeHexagon;
    }
  }

  switch (d.type) {
    case 'NCR':
      return ncrShape;
    case 'ABR':
      return abrShape;
    default:
      return accessNodeShapeHexagon;
  }
};

export const getNodeShapeFactor = (d) => {
  if (d.highOrderType) {
    switch (d.highOrderType) {
      case 'ABR':
        return 4;
      default:
        return 0;
    }
  }

  switch (d.type) {
    case 'ABR':
      return 4;
    default:
      return 0;
  }
};

export const getHighlightedShapeFactor = (d) => {
  if (d.highOrderType) {
    switch (d.highOrderType) {
      case 'ABR':
        return 6;
      default:
        return 4.5;
    }
  }

  switch (d.type) {
    case 'ABR':
      return 6;
    default:
      return 4.5;
  }
};

export const getLinkStroke = (link, ignoreAnomalous = false) => {
  if (link.isAnomalous && !ignoreAnomalous) {
    return ANOMALOUS.stroke;
  }

  return palette.gray[500];
};

export const sortNodesByType = (a, b) => {
  if (a.isAnomalous) return 4;
  if (b.isAnomalous) return -4;
  if (a.highOrderType === 'ABR') return 3;
  if (b.highOrderType === 'ABR') return -3;
  if (a.type === 'CLUSTER') return 2;
  if (b.type === 'CLUSTER') return -2;
  if (a.type === 'ABR') return 1;
  if (b.type === 'ABR') return -1;
  return 0;
};

export const sortRingsByType = (a, b) => {
  if (a.type === 'CLUSTER') return 1;
  if (b.type === 'CLUSTER') return -1;
  return 0;
};

export const getNodeDisplayName = (node) => {
  if (node.nodes && node.nodes.length === 1) {
    return node.nodes[0].name;
  }

  if (node.nodes && node.nodes.length > 1) {
    const locAddresses = [];
    node.nodes.forEach((n) => {
      const locAddress = n.hostName.split('-')[0];
      if (locAddresses.indexOf(locAddress) === -1) {
        locAddresses.push(locAddress);
      }
    });
    return locAddresses.join(', ');
  }

  return node.name;
};

export const getRingDisplayName = (node) => {
  const ringName = (nameList) => {
    const distinctAreas = [];
    nameList.forEach((name) => {
      const areaCode = name.split('.')[0];
      if (distinctAreas.indexOf(areaCode) === -1) {
        distinctAreas.push(areaCode);
      }
    });
    return distinctAreas.map((ar) => `${ar}.0.0.xx`).join(',');
  };

  let text = node.name;
  if (node.isAnomalous) {
    if (node.anomalousName) {
      text = node.anomalousName;
    }

    if (node.anomalousNameList) {
      text = ringName(node.anomalousNameList);
    }
  } else if (node.namesList) {
    text = ringName(node.namesList);
  }

  return text;
};

export const getNodesClusterNumIndicator = (node) => {
  if (node.namesList && node.namesList.length > 1) {
    return node.namesList.length;
  }

  return '';
};

export const getRingClusterNumIndicator = (node) => {
  if (node.isAnomalous) {
    if (node.anomalousNameList && node.anomalousNameList.length > 1) {
      return node.anomalousNameList.length;
    }

    return '';
  }

  if (node.namesList && node.namesList.length > 1) {
    return node.namesList.length;
  }

  return '';
};

export const getRingTooltipText = (node) => {
  let text = node.namesList ? node.namesList.join(', ') : node.name;
  if (node.isAnomalous) {
    text = node.anomalousNameList ? node.anomalousNameList.join(', ') : node.anomalousName;
  }
  return text;
};

export const getBoundsFromLeafletBoundsObject = (bounds) => ({
  north: bounds.getNorth(),
  south: bounds.getSouth(),
  east: bounds.getEast(),
  west: bounds.getWest(),
});

export const filterNodes = (zoom, bounds, nodes, filterOptions, searchItem) => {
  if (filterOptions.anomaly) {
    if (filterOptions.zoom && zoom < MIN_NODES_ZOOM) {
      return nodes.filter(
        (item) =>
          (item.isAnomalous && (!item.ringOspfAreaList || !item.ringOspfAreaList.length)) ||
          item.type === 'ABR' ||
          item.highOrderType === 'ABR',
      );
    }
    return nodes;
  }

  if (filterOptions.search) {
    if (searchItem) {
      switch (searchItem.type) {
        case 'RING': {
          const filteredNodes = [];
          nodes.forEach((node) => {
            if (node.nodes && node.nodes.length) {
              const innerFilteredNodes = node.nodes.filter((n) => n.ringOspfAreaList.indexOf(searchItem.name) !== -1);
              if (innerFilteredNodes && innerFilteredNodes.length) {
                filteredNodes.push({
                  ...node,
                  nodes: innerFilteredNodes,
                  namesList: innerFilteredNodes.map((n) => n.name),
                });
              }
            } else if (node.ringOspfAreaList.indexOf(searchItem.name) !== -1) {
              filteredNodes.push({...node});
            }
          });
          return filteredNodes;
        }
        case 'ABR': {
          const filteredNodes = [];
          nodes.forEach((node) => {
            if (node.highOrderType === 'ABR' && node.nodes && node.nodes.length) {
              const innerFilteredNodes = node.nodes.filter((n) => n.type === 'ABR' && n.hostName === searchItem.name);
              if (innerFilteredNodes && innerFilteredNodes.length) {
                filteredNodes.push({
                  ...node,
                  isHighlighted: true,
                  nodes: innerFilteredNodes,
                  namesList: innerFilteredNodes.map((n) => n.name),
                });
              }
            } else if (node.type === 'ABR' && node.hostName === searchItem.name) {
              filteredNodes.push({...node, isHighlighted: true});
            }
          });
          return filteredNodes;
        }
        case 'SAS':
        case 'AN': {
          const searchItemExtraRing = searchItem.extra.find((ex) => ex.key === 'ring');
          const ringOspfAreaList = [];
          if (searchItemExtraRing) {
            ringOspfAreaList.push(...searchItemExtraRing.value);
          }

          const filteredNodes = [];
          nodes.forEach((node) => {
            if (node.nodes && node.nodes.length) {
              const innerFilteredNodes = node.nodes.filter(
                (n) => intersection(n.ringOspfAreaList, ringOspfAreaList).length > 0,
              );
              if (innerFilteredNodes && innerFilteredNodes.length) {
                const namesList = innerFilteredNodes.map((n) => n.name);
                filteredNodes.push({
                  ...node,
                  isHighlighted: namesList.indexOf(searchItem.name) !== -1,
                  nodes: innerFilteredNodes,
                  namesList,
                });
              }
            } else if (intersection(node.ringOspfAreaList, ringOspfAreaList).length > 0) {
              filteredNodes.push({...node, isHighlighted: node.hostName === searchItem.name});
            }
          });
          return filteredNodes;
        }
        default:
          return [];
      }
    }
    return nodes.filter((item) => item.type === 'ABR' || item.highOrderType === 'ABR');
  }

  return nodes.filter((item) => item.type === 'ABR' || item.highOrderType === 'ABR');

  // old one
  /*
  if (filterOptions.zoom && zoom < 15) {
    return nodes.filter((item) => item.type === 'ABR' || item.highOrderType === 'ABR');
  }

  if (filterOptions.bounds) {
    const {
      north, south, east, west,
    } = bounds;
    return nodes.filter((item) => {
      const lat = item.latLng[0];
      const lng = item.latLng[1];
      return lat > south && lat < north && lng > west && lng < east;
    });
  }

  return nodes;
  */
};

export const filterLinks = (zoom, bounds, links, nodes, filterOptions, searchItem) => {
  if (filterOptions.anomaly) {
    if (filterOptions.zoom && zoom > MIN_NODES_ZOOM - ZOOM_DELTA) {
      const linksToReturn = links.filter((link) => {
        let isTo = false;
        let isFrom = false;
        nodes.forEach((node) => {
          if (node.name === link.to || (node.namesList && node.namesList.indexOf(link.to) !== -1)) {
            isTo = true;
          }
          if (node.name === link.from || (node.namesList && node.namesList.indexOf(link.from) !== -1)) {
            isFrom = true;
          }
        });

        return isFrom && isTo;
      });

      return linksToReturn;
    }
    return [];
  }

  if (filterOptions.search) {
    if (searchItem) {
      switch (searchItem.type) {
        case 'RING':
        case 'SAS':
        case 'AN': {
          return links.filter((link) => {
            let isTo = false;
            let isFrom = false;
            nodes.forEach((node) => {
              if (node.name === link.to || (node.namesList && node.namesList.indexOf(link.to) !== -1)) {
                isTo = true;
              }
              if (node.name === link.from || (node.namesList && node.namesList.indexOf(link.from) !== -1)) {
                isFrom = true;
              }
            });

            return isFrom && isTo;
          });
        }
        default:
          return [];
      }
    }
    return [];
  }

  return [];

  // old one
  /*
  if (filterOptions.zoom && zoom < 15) {
    return [];
  }

  if (filterOptions.bounds) {
    const {
      north, south, east, west,
    } = bounds;
    return links.filter((item) => {
      const fLat = item.fromLatLng[0];
      const fLng = item.fromLatLng[1];
      const tLat = item.toLatLng[0];
      const tLng = item.toLatLng[1];
      return (
        (fLat > south && fLat < north && fLng > west && fLng < east) ||
        (tLat > south && tLat < north && tLng > west && tLng < east)
      );
    });
  }

  return links;
  */
};

export const filterRings = (zoom, bounds, rings, filterOptions, searchItem) => {
  if (filterOptions.anomaly) {
    if (filterOptions.zoom && zoom > MIN_NODES_ZOOM - ZOOM_DELTA) {
      return [];
    }
    return rings.filter((item) => item.isAnomalous);
  }

  if (filterOptions.search) {
    if (searchItem) {
      if (searchItem.type === 'ABR') {
        const filteredRings = [];
        rings.forEach((ring) => {
          if (ring.rings && ring.rings.length) {
            const innerFilteredRings = ring.rings.filter((n) => n.roots.indexOf(searchItem.name) !== -1);
            if (innerFilteredRings && innerFilteredRings.length) {
              filteredRings.push({
                ...ring,
                rings: innerFilteredRings,
                namesList: innerFilteredRings.map((n) => n.name),
              });
            }
          } else if (ring.roots.indexOf(searchItem.name) !== -1) {
            filteredRings.push({...ring});
          }
        });
        return filteredRings;
      }
      return [];
    }
  }

  if (filterOptions.zoom && zoom < MIN_RINGS_ZOOM) {
    return [];
  }

  if (filterOptions.bounds) {
    const {north, south, east, west} = bounds;
    return rings.filter((item) => {
      const lat = item.latLng[0];
      const lng = item.latLng[1];
      return lat > south && lat < north && lng > west && lng < east;
    });
  }

  return [];
};

export const filterRingLinks = (zoom, bounds, links, filterOptions, searchItem) => {
  if (filterOptions.anomaly) {
    if (filterOptions.zoom && zoom > MIN_NODES_ZOOM - ZOOM_DELTA) {
      return [];
    }
    return links.filter((item) => item.isAnomalous);
  }

  if (filterOptions.search) {
    if (searchItem) {
      if (searchItem.type === 'ABR') {
        return links.filter((link) => link.from === searchItem.name);
      }
      return [];
    }
  }

  if (filterOptions.zoom && zoom < MIN_RINGS_ZOOM) {
    return [];
  }

  if (filterOptions.bounds) {
    const {north, south, east, west} = bounds;
    return links.filter((item) => {
      const fLat = item.fromLatLng[0];
      const fLng = item.fromLatLng[1];
      const tLat = item.toLatLng[0];
      const tLng = item.toLatLng[1];
      return (
        (fLat > south && fLat < north && fLng > west && fLng < east) ||
        (tLat > south && tLat < north && tLng > west && tLng < east)
      );
    });
  }

  return [];

  // old one
  /*
  if (filterOptions.zoom && (zoom > 14 || zoom < 12)) {
    return [];
  }

  if (filterOptions.bounds) {
    const {
      north, south, east, west,
    } = bounds;
    return links.filter((item) => {
      const fLat = item.fromLatLng[0];
      const fLng = item.fromLatLng[1];
      const tLat = item.toLatLng[0];
      const tLng = item.toLatLng[1];
      return (
        (fLat > south && fLat < north && fLng > west && fLng < east) ||
        (tLat > south && tLat < north && tLng > west && tLng < east)
      );
    });
  }

  return links;
  */
};

export const getNodesBounds = (nodes) => {
  const offset = 0.005;
  if (!nodes || !nodes.length) {
    return null;
  }

  if (nodes.length === 1) {
    return {
      center: nodes[0].latLng,
    };
  }

  let minLat = null;
  let maxLat = null;
  let minLon = null;
  let maxLon = null;

  /* eslint-disable prefer-destructuring */
  nodes.forEach((node, index) => {
    if (index === 0) {
      minLat = node.latLng[0];
      minLon = node.latLng[1];
    }

    if (index === 1) {
      if (node.latLng[0] > minLat) {
        maxLat = node.latLng[0];
      } else {
        maxLat = minLat;
        minLat = node.latLng[0];
      }

      if (node.latLng[1] > minLon) {
        maxLon = node.latLng[1];
      } else {
        maxLon = minLon;
        minLon = node.latLng[1];
      }
    }

    if (index > 1) {
      if (node.latLng[0] < minLat) {
        minLat = node.latLng[0];
      }
      if (node.latLng[1] < minLon) {
        minLon = node.latLng[1];
      }
      if (node.latLng[0] > maxLat) {
        maxLat = node.latLng[0];
      }
      if (node.latLng[1] > maxLon) {
        maxLon = node.latLng[1];
      }
    }
  });
  /* eslint-enable prefer-destructuring */

  if (minLat === maxLat && minLon === maxLon) {
    return {
      center: [minLat, minLon],
    };
  }

  return {
    maxPoint: [maxLat + offset, maxLon + offset],
    minPoint: [minLat - offset, minLon - offset],
  };
};
