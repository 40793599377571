import React, {useState, useRef, Fragment} from 'react';
import {makeStyles} from '@material-ui/core';
import Button, {COLORS} from 'common/componentsV2/Button';
import useClickOutside from 'common/hooks/useClickOutside';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {getDashboardAnomalyTileEnabled, getPieOrDonutEnabled} from 'profile/store/selectors';
import {ReactComponent as IconTextTileClear} from 'dashboards/images/textTileClear.svg';
import {ReactComponent as IconTextTile} from 'dashboards/images/textTile.svg';
import {ReactComponent as IconStatTile} from 'dashboards/images/statTile.svg';
import {ReactComponent as IconStatTileClear} from 'dashboards/images/statTileClear.svg';
import {ReactComponent as IconGraphTileClear} from 'dashboards/images/graphTileClear.svg';
import {ReactComponent as IconAnomalyTile} from 'app/images/anomaly.svg';
import {ReactComponent as IconAnomalyTileClear} from 'app/images/anomalyClear.svg';
import {ReactComponent as IconGraphTile} from 'dashboards/images/graphTile.svg';
import {ReactComponent as IconGaugeTileClear} from 'dashboards/images/statTileGaugeClear.svg';
import {ReactComponent as IconGaugeTile} from 'dashboards/images/statTileGauge.svg';

import {ReactComponent as IconPieTileClear} from 'dashboards/images/pieTileClear.svg';
import {ReactComponent as IconPieTile} from 'dashboards/images/pieTile.svg';

import {ReactComponent as IconDonutTileClear} from 'dashboards/images/donutTileClear.svg';
import {ReactComponent as IconDonutTile} from 'dashboards/images/donutTile.svg';
import {useSelector} from 'react-redux';

export const TILE_TYPES_VALUE = {
  TEXT_TILE: 'text',
  STAT_TILE: 'number',
  ANOMALY_TILE: 'anomaly',
  ALERTS_TILE: 'alerts',
  GRAPH: 'graph',
  GAUGE: 'gauge',
  PIE: 'pie',
  DONUT: 'donut',
};

const TILE_TYPES = [
  {
    label: 'Text',
    value: TILE_TYPES_VALUE.TEXT_TILE,
    iconHover: IconTextTileClear,
    icon: IconTextTile,
  },
  {
    label: 'Single Metric',
    value: TILE_TYPES_VALUE.STAT_TILE,
    iconHover: IconStatTileClear,
    icon: IconStatTile,
  },
  {
    label: 'Graph',
    value: TILE_TYPES_VALUE.GRAPH,
    iconHover: IconGraphTileClear,
    icon: IconGraphTile,
  },
  {
    label: 'Anomaly',
    value: TILE_TYPES_VALUE.ANOMALY_TILE,
    iconHover: IconAnomalyTileClear,
    icon: IconAnomalyTile,
  },
  {
    label: 'Gauge',
    value: TILE_TYPES_VALUE.GAUGE,
    iconHover: IconGaugeTileClear,
    icon: IconGaugeTile,
  },
  {
    label: 'Pie',
    value: TILE_TYPES_VALUE.PIE,
    iconHover: IconPieTileClear,
    icon: IconPieTile,
  },
  {
    label: 'Donut',
    value: TILE_TYPES_VALUE.DONUT,
    iconHover: IconDonutTileClear,
    icon: IconDonutTile,
  },
];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 308,
    borderRadius: 6,
    boxShadow: '0 4px 12px -2px rgba(0, 0, 36, 0.25)',
    zIndex: 3,
    backgroundColor: theme.palette.white[500],
    padding: 4,
    position: 'absolute',
    right: 0,
    top: 37,
  },
  tile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 100,
    height: 88,
    borderRadius: 5,
    fontSize: 14,
    fontWeight: 500,
    padding: 2,
    '&:hover': {
      backgroundColor: theme.palette.blue[100],
      color: theme.palette.blue[500],
      cursor: 'pointer',
      '& $icon': {
        display: 'none',
      },
      '& $iconHover': {
        display: 'block',
      },
    },
  },
  icon: {
    width: 24,
    height: 24,
    display: 'block',
  },
  iconHover: {
    width: 24,
    height: 24,
    fill: theme.palette.blue[500],
    display: 'none',
  },
}));

const notAvailableTypes = [];
const pieOrDonut = [TILE_TYPES_VALUE.PIE, TILE_TYPES_VALUE.DONUT];

const items = TILE_TYPES;

export const TilesType = ({
  onSelect,
  actionCloseModal = () => null,
}: {
  onSelect: Function,
  actionCloseModal: Function,
}) => {
  const classes = useStyles();
  const isPieOrDonutEnabled = useSelector(getPieOrDonutEnabled);
  const isAnomalyTileEnabled = useSelector(getDashboardAnomalyTileEnabled);

  const handleClick = (item) => {
    actionCloseModal(false);
    onSelect(item.value);
  };

  notAvailableTypes.push(...(!isPieOrDonutEnabled ? pieOrDonut : []));
  if (!isAnomalyTileEnabled) {
    notAvailableTypes.push(TILE_TYPES_VALUE.ANOMALY_TILE);
  }
  const availableItems = items.filter((type) => !notAvailableTypes.includes(type.value));

  return (
    <Fragment>
      {availableItems.map((item) => {
        const IconComponent = item.icon;
        const IconHoverComponent = item.iconHover;
        return (
          <div key={item.value} onClick={() => handleClick(item)} className={classes.tile}>
            <div className={classes.icon}>
              <IconComponent />
            </div>
            <div className={classes.iconHover}>
              <IconHoverComponent />
            </div>
            <div className="mt_2">{item.label}</div>
          </div>
        );
      })}
    </Fragment>
  );
};

export const DropdownTiles = ({onSelect, isDisabled}: {onSelect: Function, isDisabled: boolean}) => {
  const dropdownRef = useRef();
  const [isDropdownOpened, setDropdownOpened] = useState(false);
  useClickOutside(dropdownRef, () => setDropdownOpened(false));
  const classes = useStyles();

  return (
    <Tooltip
      content="Available in Edit Mode"
      hideIfEqualTo={!isDisabled ? 'Available in Edit Mode' : null}
      type={TYPES.SMALL}
      placement="bottom"
    >
      <div className="position_relative">
        <Button
          automationId="dashboardsNew"
          text="Add Tile"
          colorSchema={COLORS.BLUE_500}
          extraClassName="tooltip-btn"
          isDisabled={isDisabled}
          onClick={() => setDropdownOpened(true)}
          icon="icn-action16-plusa"
        />
        {isDropdownOpened && (
          <div ref={dropdownRef} className={classes.wrapper}>
            <TilesType onSelect={onSelect} actionCloseModal={setDropdownOpened} />
          </div>
        )}
      </div>
    </Tooltip>
  );
};
