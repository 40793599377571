// @flow
import React from 'react';
import Tooltip, {TYPES} from './Tooltip';

import './AvatarBadge.module.scss';

export const COLORS = {
  GRAY: 'gray',
  ROSE: 'rose',
  TEAL: 'teal',
  TOMATO: 'tomato',
  EUCALIPTUS: 'eucaliptus',
  LILAC: 'lilac',
  INDIGO: 'indigo',
  MINT: 'mint',
  YELLOW: 'yellow',
};

const AvatarBadge = ({
  text,
  tooltipText,
  colorSchema,
  className,
  isReverse,
  isShadow,
  isSmall,
}: {
  text: string,
  tooltipText: string,
  colorSchema: string,
  className: string,
  isReverse: boolean,
  isShadow: boolean,
  isSmall: boolean,
}) => {
  const rootStyle = [
    isSmall ? 'outer-ring-small' : 'outer-ring',
    colorSchema || COLORS.GRAY,
    isReverse ? 'reverse' : '',
    isShadow ? 'shadow' : '',
  ];

  return (
    <div styleName={rootStyle.join(' ')} className={className}>
      <Tooltip content={tooltipText} hideIfEqualTo={undefined} type={TYPES.SMALL} delay={500}>
        <div styleName={isSmall ? 'inner-ring-small' : 'inner-ring'}>
          <div styleName={isSmall ? 'badge-area-small' : 'badge-area'}>
            <span>{text}</span>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default React.memo(AvatarBadge);
