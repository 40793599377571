// @flow
import React from 'react';
import Button, {COLORS as BUTTON_COLORS} from 'common/componentsV2/Button';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import './FilterButton.module.scss';

export default class FilterButton extends React.PureComponent {
  props: {
    maxEntries?: Number,
    automationId: String,
    tooltipItems: Array,
    onClick: Function,
  };

  static defaultProps = {
    maxEntries: 8,
  };

  renderTooltipItem = (item, isEvenIndex) => (
    <div key={`tooltip-${item.id}`} styleName={['item-wrapper', isEvenIndex ? 'even' : ''].join(' ')}>
      <span styleName="item-header">{`${item.header}`}</span>
      <span styleName="item-value">{item.value}</span>
    </div>
  );

  renderTooltipFooter = () => {
    const {maxEntries, tooltipItems} = this.props;
    const extra = tooltipItems.length - maxEntries;
    if (extra > 0) {
      return <div styleName="tooltip-footer">{`(${extra} more filters apply)`}</div>;
    }
    return <span />;
  };

  renderTooltip = () => (
    <div styleName="tooltip-root">
      {this.props.tooltipItems.map((item, index) => {
        if (index < this.props.maxEntries) {
          return this.renderTooltipItem(item, index % 2);
        }
        return <span />;
      })}
      {this.renderTooltipFooter()}
    </div>
  );

  render() {
    const {tooltipItems, onClick, automationId} = this.props;
    const count = tooltipItems?.length || 0;
    const btnTextFilterCount = count > 0 ? `(${count})` : '';
    const btnColorSchema = count > 0 ? BUTTON_COLORS.BLUE_500 : BUTTON_COLORS.GRAY_200;
    const content = count > 0 ? this.renderTooltip() : null;

    return (
      <Tooltip content={content} type={TYPES.LARGE} placement="bottom" delay={100}>
        <span>
          <Button
            colorSchema={btnColorSchema}
            text={`Filters ${btnTextFilterCount}`}
            icon="icn-general16-filter"
            onClick={onClick}
            automationId={automationId}
          />
        </span>
      </Tooltip>
    );
  }
}
