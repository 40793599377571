import {commasSepNumber} from 'common/utils/numbers';

export const IMPACT_MODAL_HEIGHT = '485px';

export const modalRouting1 = {
  IMPACT_MODAL: 'impactModal',
  ALERT_TRIGGER_ID: 'alertTriggerId',
  ORIGIN_ID: 'originId',
  ORIGIN_TYPE: 'originType',
  MEASURE: 'measure',
};

export const modalRouting2 = {
  IMPACT_MODAL: 'impactModal',
  ALERT_TRIGGER_ID: 'alertTriggerId',
  ORIGIN_ID: 'originId',
  ORIGIN_TYPE: 'originType',
  IMPACT_ID: 'impactId',
};

export const ORIGIN_TYPES = {
  stream: 'STREAM',
};

export const CURRENCY_OPTIONS = {
  USD: {
    label: 'USD',
    value: 'USD',
    symbol: '$',
    direction: 'ltr',
  },
  EUR: {
    label: 'EUR',
    value: 'EUR',
    symbol: '€',
    direction: 'ltr',
  },
  GBP: {
    label: 'GBP',
    value: 'GBP',
    symbol: '£',
    direction: 'ltr',
  },
  CHF: {
    label: 'CHF',
    value: 'CHF',
    symbol: 'Fr',
    direction: 'ltr',
  },
  RUB: {
    label: 'RUB',
    value: 'RUB',
    symbol: '₽',
    direction: 'ltr',
  },
  CAD: {
    label: 'CAD',
    value: 'CAD',
    symbol: '$',
    direction: 'ltr',
  },
  AUD: {
    label: 'AUD',
    value: 'AUD',
    symbol: '$',
    direction: 'ltr',
  },
  INR: {
    label: 'INR',
    value: 'INR',
    symbol: '₹',
    direction: 'ltr',
  },
  JPY: {
    label: 'JPY',
    value: 'JPY',
    symbol: '¥',
    direction: 'ltr',
  },
  ILS: {
    label: 'ILS',
    value: 'ILS',
    symbol: '₪',
    direction: 'rtl',
  },
};

export const SPIKE_OPTIONS = [
  {value: 'GOOD', label: 'good'},
  {value: 'BAD', label: 'bad'},
  {value: 'NEUTRAL', label: 'neither'},
];

export const DROP_OPTIONS = [
  {value: 'GOOD', label: 'good'},
  {value: 'BAD', label: 'bad'},
  {value: 'NEUTRAL', label: 'neither'},
];

export const IMPACT_DEFAULT_VALUES = {
  id: 'TMP_IMPACT',
  factor: 1,
  currency: CURRENCY_OPTIONS.USD.label,
  spike: 'NEUTRAL',
  drop: 'NEUTRAL',
  measure: '',
  originType: '',
  originId: '',
};

export const IMPACT_SUCCESS_MSG = {
  create: {
    title: 'Impact Set Successfully',
    description: 'From now on, Anodot will display the business impact of alerts on this measure.',
  },
  update: {
    title: 'Impact Updated Successfully',
    description:
      'The new impact value you set has been updated. Future business impact calculations will use this value.',
  },
  remove: {
    title: 'Impact Value Removed',
    description: 'From now on, Anodot will not display the business impact calculation on this measure.',
  },
};

export const prettifyImpactNumber = (sum, factor = null) => {
  const impact = sum * (factor || 1);
  const roundImpact = Math.round(impact * 100) / 100;
  return commasSepNumber(roundImpact);
};

export const setSentiment = (direction, spikeIs, dropIs) => {
  if (direction === 'UP') {
    return spikeIs === 'GOOD';
  }
  if (direction === 'DOWN') {
    return dropIs === 'GOOD';
  }
  return null;
};

export const makeLeanAlert = (alert) => {
  if (alert) {
    const lastInterval = alert.metrics[0]?.intervals?.length - 1;
    return {
      endTime: alert.endTime,
      direction: alert.metrics[0]?.intervals[lastInterval]?.direction,
      metric: alert.metrics[0],
      startTime: alert.startTime,
      sumDeltas: alert.metrics[0]?.intervals[lastInterval]?.sumDeltas,
      title: alert.title,
    };
  }
  return undefined;
};
