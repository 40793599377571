// @flow
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Field, Form} from 'react-final-form';
import ErrorBoundary from 'errorBoundaries/ErrorBoundary';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import Input from 'common/componentsV2/Input';
import {updateCustomerGeneralSettings} from 'admin.customers/store/actions';
import {getProfileData} from 'profile/store/selectors';
import {fetchMe} from 'profile/store/actions';
import {
  getDisableAutoTuneAlerts,
  getDisablePublicDashboards,
  getOrganizationId,
  getUserMinPasswordLength,
  getAdminMinPasswordLength,
} from 'userSettings/store/selectors';
import useAsyncAction from 'common/utils/useAsyncAction';
import useUserSettingsStyles from 'userSettings/pages/useUserSettings.styles';
import {isEmpty} from 'lodash';
import BottomPanel from '../BottomPanel';
import './GeneralSection.module.scss';

const GeneralSection = () => {
  const dispatch = useDispatch();
  const disableAutoTuneAlerts = useSelector(getDisableAutoTuneAlerts);
  const disablePublicDashboards = useSelector(getDisablePublicDashboards);
  const organizationId = useSelector(getOrganizationId);
  const getUserProfile = useSelector(getProfileData);
  const minUserPasswordLength = useSelector(getUserMinPasswordLength); // set on reducer
  const minAdminPasswordLength = useSelector(getAdminMinPasswordLength); //
  const initialValuesForm = {
    disableAutoTuneAlerts,
    disablePublicDashboards,
    minUserPasswordLength,
    minAdminPasswordLength,
  };

  function formValidation(values) {
    const minPasswordMessage = 'Password minimum length is 8 characters';
    const errors = {};
    if (isEmpty(values)) return errors;
    if (!values.minUserPasswordLength || values.minUserPasswordLength < 8) {
      errors.minUserPasswordLength = minPasswordMessage;
    }
    if (!values.minAdminPasswordLength || values.minAdminPasswordLength < 8) {
      errors.minAdminPasswordLength = minPasswordMessage;
    }
    return errors;
  }

  const fetchUpdateCustomerGeneralSettings = useAsyncAction(updateCustomerGeneralSettings, getUserProfile);
  const actionSubmit = (val, form) => {
    const payload = {
      customer: {_id: organizationId},
      data: {
        disableAutoTuneAlerts: val.disableAutoTuneAlerts,
        disablePublicDashboards: val.disablePublicDashboards,
        minUserPasswordLength: val.minUserPasswordLength,
        minAdminPasswordLength: val.minAdminPasswordLength,
      },
    };
    return (
      fetchUpdateCustomerGeneralSettings(payload, {organizationId})
        .then(() => {
          dispatch(fetchMe());
          setTimeout(form.reset);
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error))
    );
  };

  const userSettingsClasses = useUserSettingsStyles();

  return (
    <ErrorBoundary>
      <Form initialValues={initialValuesForm} validate={formValidation} onSubmit={actionSubmit}>
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit} styleName="root">
            <div className={userSettingsClasses.section}>
              <Field name="disableAutoTuneAlerts">
                {({input}) => (
                  <div styleName="row">
                    <div>Enable automatic tuning of alerts by Anodot</div>
                    <ToggleSwitch
                      name={input.name}
                      theme="blue"
                      isChecked={!input.value}
                      onToggle={() => input.onChange(!input.value)}
                    />
                  </div>
                )}
              </Field>
              <Field name="disablePublicDashboards">
                {({input}) => (
                  <div styleName="row">
                    <div>Enable public dashboards</div>
                    <ToggleSwitch
                      name={input.name}
                      theme="blue"
                      isChecked={!input.value}
                      onToggle={() => input.onChange(!input.value)}
                    />
                  </div>
                )}
              </Field>
              <div className={userSettingsClasses.section} />
              <div styleName="input-row">
                <div styleName="container">
                  <div className={userSettingsClasses.detailsField}>
                    <div className={userSettingsClasses.title}>Min user password length</div>
                    <Field name="minUserPasswordLength">
                      {({input}) => (
                        <Input
                          type="number"
                          name={input.name}
                          value={input.value}
                          onChange={(event) => input.onChange(event)}
                          additionalProps={{maxLength: '50'}}
                          placeHolder="Required"
                          automationId="minUserPasswordLength"
                        />
                      )}
                    </Field>
                    <div styleName="error">{formProps.errors.minUserPasswordLength}</div>
                  </div>
                </div>
                <div styleName="container">
                  <div className={userSettingsClasses.detailsField}>
                    <div className={userSettingsClasses.title}>Min admin password length</div>
                    <Field name="minAdminPasswordLength">
                      {({input}) => (
                        <Input
                          type="number"
                          name={input.name}
                          value={input.value}
                          onChange={(event) => input.onChange(event)}
                          additionalProps={{maxLength: '50'}}
                          placeHolder="Required"
                          automationId="minAdminPasswordLength"
                        />
                      )}
                    </Field>
                    <div styleName="error">{formProps.errors.minAdminPasswordLength}</div>
                  </div>
                </div>
              </div>
            </div>
            {formProps.dirty && (
              <BottomPanel
                errorNumbers={Object.keys(formProps.errors).length}
                isOpenPopper={formProps.submitFailed && formProps.hasValidationErrors}
              />
            )}
          </form>
        )}
      </Form>
    </ErrorBoundary>
  );
};

export default GeneralSection;
