import {getSignedRequest} from 'bc/services/fileUploadToAwsService';
import {setFileUploadName, setFileUploadProgress, setUploadedFileUrl, setUploadFileError} from 'bc/store/actions';

import {getUniqueId} from 'common/utils/guid';
import * as api from './api';

export const CSV_TYPES_ARR = [
  'text/plain',
  'application/vnd.ms-excel',
  'text/x-csv',
  'application/csv',
  'application/x-csv',
  'text/csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values',
];

export const getEditorValidationMessage = (stream) => {
  if (!stream) {
    return null;
  }
  if (!stream.reportName) {
    return ['The report must have a name'];
  }
  if (!stream.timeZone) {
    return ['The report must have a time zone'];
  }
  if (!stream.dimensions.length && !stream.metrics.length) {
    return ['At least one dimension or metric is required'];
  }
  return null;
};

export const uploadFile = async (obj) => {
  const bcTypes = await api.fetchDataSourceTypes().toPromise();
  const googleAuctionType = bcTypes.find((bcItem) => bcItem.type === 'google_auctions');

  if (googleAuctionType && googleAuctionType.uploadBucket && googleAuctionType.uploadPath) {
    const file = {
      data: obj.fileData,
      errorCode: null,
    };
    setFileUploadProgress(0);
    setFileUploadName(file.data.name);
    setUploadedFileUrl({});

    const path = `${googleAuctionType.uploadPath}/`;
    const folder = getUniqueId();

    getSignedRequest(
      obj.fileData,
      googleAuctionType.uploadBucket,
      path,
      obj.successFunc(path, folder),
      setUploadFileError,
      setFileUploadProgress,
      folder,
    );
  }
};
