import {composeReducers} from 'common/utils/reducers';
import {setMaxPercentToDisplayTrimmed} from 'common/utils/numbers';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const initialState = {
  isOpen: false,
  isMinimize: false,
  anomalyId: null,
  triggerId: null,
  trigger: EMPTY_OBJECT,
  alertMeasures: EMPTY_ARRAY,
  anomalyStarted: null,
  score: null,
  otherMeasures: EMPTY_ARRAY,
  where: EMPTY_OBJECT,
  incident: {
    filters: EMPTY_ARRAY,
  },
  correlations: {
    filters: EMPTY_ARRAY,
  },
  timeline: [],
};

const setFeedback = (alert, payload) => {
  const feedbackFound = alert.feedback.find((f) => f.userId === payload.userId);

  if (!feedbackFound) {
    // no feedback by me given
    return {
      ...alert,
      feedback: [...alert.feedback, payload],
    };
  }
  return alert;
};

const deleteFeedback = (alert, payload) => {
  const feedbackFound = alert.feedback.find((f) => f.userId === payload.userId);

  if (feedbackFound) {
    // different feedback by me exist, need to delete it and set the new feedback
    const feedbackListWithoutMyFeedback = alert.feedback.filter((f) => f.userId !== payload.userId);
    if (feedbackFound.type !== payload.type) {
      return {
        ...alert,
        feedback: [...feedbackListWithoutMyFeedback, payload],
      };
    }
    if (feedbackFound.type === payload.type) {
      return {
        ...alert,
        feedback: feedbackListWithoutMyFeedback,
      };
    }
  }
  return alert;
};

const addFeedbackId = (alert, payload) => {
  const updatedFeedback = alert.feedback?.map((f) => {
    if (f.userId !== payload.meta.userId) {
      return f;
    }
    if (f.userId === payload.meta.userId) {
      return {...f, id: payload.id};
    }
    return f;
  });

  return {
    ...alert,
    feedback: updatedFeedback,
  };
};

const addFeedbackReason = (alert, payload) => {
  const updatedFeedback = alert.feedback?.map((f) => {
    if (f.id !== payload.id) {
      return f;
    }
    if (f.id === payload.id) {
      return {
        ...f,
        reason: payload.reason ? payload.reason : undefined,
        comment: payload.comment ? payload.comment : undefined,
      };
    }
    return f;
  });

  return {
    ...alert,
    feedback: updatedFeedback,
  };
};

const findFirstInterval = (metrics) => {
  const intervalsStartTime = [];

  if (metrics?.length) {
    metrics.forEach((metric) => {
      metric.intervals.forEach((interval) => intervalsStartTime.push(interval.startTime));
    });
  }
  return intervalsStartTime.sort((a, b) => a - b)[0];
};

const setScore = (metrics) => {
  let score = 0;

  if (metrics?.length) {
    metrics.forEach((metric) => {
      const interval = metric.intervals[metric.intervals.length - 1];
      score = interval.score > score ? interval.score : score;
    });
  }
  return score;
};

const setWhere = (tokenMap, alertMeasures) => {
  if (!alertMeasures[0]) {
    return EMPTY_OBJECT;
  }
  const whereTokens = tokenMap.find((i) => i.value === alertMeasures[0])?.tokens;
  if (whereTokens && whereTokens.length) {
    let singToken = whereTokens[0];
    whereTokens.forEach((whereTk) => {
      if (whereTk.weight > singToken.weight) {
        singToken = whereTk;
      }
    });
    return {
      key: singToken.key,
      value: singToken.value,
      percentage: setMaxPercentToDisplayTrimmed(singToken.weight * 100),
    };
  }
  return EMPTY_OBJECT;
};

const setMeasures = (metrics) => {
  const obj = {};

  if (metrics?.length) {
    metrics.forEach((metric) => {
      if (metric.what && obj[metric.what]) {
        // eslint-disable-next-line operator-assignment
        obj[metric.what] = obj[metric.what] + 1;
      } else if (metric.what && !obj[metric.what]) {
        obj[metric.what] = 1;
      }
    });
  }
  return Object.keys(obj)?.sort((a, b) => obj[b] - obj[a]);
};

const viewReducer = composeReducers((state = {...initialState}, {type, payload, meta}) => {
  switch (type) {
    case actions.setIsOpen.TYPE: {
      if (payload) {
        return {
          ...state,
          isOpen: true,
        };
      }
      if (!payload) {
        return {
          ...initialState,
        };
      }
      break;
    }
    case actions.setIsMinimize.TYPE: {
      return {
        ...state,
        isMinimize: payload,
      };
    }
    case actions.setAnomalyId.TYPE: {
      return {
        ...state,
        anomalyId: payload,
      };
    }
    case actions.setTriggerId.TYPE: {
      return {
        ...state,
        triggerId: payload,
      };
    }

    case actions.fetchTriggeredAlertApi.success.TYPE: {
      return {
        ...state,
        trigger: payload,
        alertMeasures: setMeasures(payload.metrics),
        anomalyStarted: findFirstInterval(payload.metrics),
        score: setScore(payload.metrics),
      };
    }

    case actions.fetchAnomaliesTokenMapApiNewAlertConsole.success.TYPE: {
      if (meta.isFiltered) {
        return state;
      }
      return {
        ...state,
        otherMeasures: payload.filter((i) => !meta.alertMeasures?.includes(i.value)).map((i) => i.value),
        where: setWhere(payload, meta.alertMeasures),
      };
    }

    case actions.setIncidentFilterNewAlertConsole.TYPE: {
      if (!payload) {
        return {
          ...state,
          incident: {
            ...state.incident,
            filters: EMPTY_ARRAY,
          },
        };
      }
      return {
        ...state,
        incident: {
          ...state.incident,
          filters: state.incident.filters.some((i) => i.id === payload.id)
            ? state.incident.filters.filter((i) => i.id !== payload.id)
            : [...state.incident.filters, payload],
        },
      };
    }

    case actions.setCorrelationsFilterNewAlertConsole.TYPE: {
      if (!payload) {
        return {
          ...state,
          correlations: {
            ...state.correlations,
            filters: EMPTY_ARRAY,
          },
        };
      }
      return {
        ...state,
        correlations: {
          ...state.correlations,
          filters: state.correlations.filters.some((i) => i.id === payload.id)
            ? state.correlations.filters.filter((i) => i.id !== payload.id)
            : [...state.correlations.filters, payload],
        },
      };
    }

    case actions.postAcknowledge.TYPE: {
      let stars = EMPTY_ARRAY;
      if (payload.action === 'add') {
        stars = [...state.trigger.stars, payload.userId];
      } else {
        stars = state.trigger.stars.filter((st) => st !== payload.userId);
      }
      return {
        ...state,
        trigger: {
          ...state.trigger,
          stars,
        },
      };
    }

    case actions.setFeedback.TYPE: {
      return {
        ...state,
        trigger: setFeedback(state.trigger, payload),
      };
    }
    case actions.setFeedbackRequest.success.TYPE: {
      return {
        ...state,
        trigger: addFeedbackId(state.trigger, payload),
      };
    }
    case actions.deleteFeedback.TYPE: {
      return {
        ...state,
        trigger: deleteFeedback(state.trigger, payload),
      };
    }
    case actions.updateFeedback.TYPE: {
      return {
        ...state,
        trigger: addFeedbackReason(state.trigger, payload),
      };
    }

    case actions.fetchTimeLine.success.TYPE: {
      return {
        ...state,
        timeline: payload,
      };
    }
    case actions.sendCommentNewAlertConsole.success.TYPE: {
      return {
        ...state,
        timeline: [...state.timeline, ...payload],
      };
    }

    default:
      return state;
  }
  return state;
});

export default viewReducer;
