// @flow
import React from 'react';
import {options} from 'userSettings/store/selectors';
import SubTitle from 'userSettings/components/SubTitle';
import {makeStyles} from '@material-ui/core/styles';
import useUserSettingsStyles from 'userSettings/pages/useUserSettings.styles';
import {Field} from 'react-final-form';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import SamlAuth from './SamlAuth';
import Google0Auth from './Google0Auth';
import AzureActiveDirectoryAuth from './AzureActiveDirectoryAuth';

const useStyles = makeStyles(() => ({
  option: {
    marginBottom: 32,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const renderBody = (name) => {
  switch (name) {
    case options[0].nameField:
      return <SamlAuth />;
    case options[1].nameField:
      return <Google0Auth />;
    case options[2].nameField:
      return <AzureActiveDirectoryAuth />;
    default:
      return null;
  }
};

const SsoConfigurationsSection = () => {
  const classes = useStyles();
  const userSettingsClasses = useUserSettingsStyles();

  return (
    <>
      <SubTitle title="Single Sign-On Configuration" />
      {options.map((ssoOption) => (
        <div className={`${userSettingsClasses.fullWidth} ${classes.option}`} key={ssoOption.nameField}>
          <Field name="radioSignOnConfig">
            {({input}) => {
              const isOptionChecked = input.value === ssoOption.nameField;
              return (
                <>
                  <div className={classes.row}>
                    <span>{ssoOption.label}</span>
                    <ToggleSwitch
                      name={input.name}
                      theme="blue"
                      isChecked={isOptionChecked}
                      onToggle={() => input.onChange(isOptionChecked ? ' ' : ssoOption.nameField)}
                    />
                  </div>
                  <div>{isOptionChecked && renderBody(ssoOption.nameField)}</div>
                </>
              );
            }}
          </Field>
        </div>
      ))}
    </>
  );
};

export default SsoConfigurationsSection;
