// @flow
import React from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import {setStreamSchemaTransform, setStreamSchemaColumnKeyVal} from 'bc/store/actions';
import './ExcludeModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  col: Object,

  setStreamSchemaColumnKeyVal: Function,
  setStreamSchemaTransform: Function,
};

export default connect(
  () => ({}),
  {
    setStreamSchemaTransform,
    setStreamSchemaColumnKeyVal,
  },
)(
  class ExcludeModal extends React.PureComponent {
    props: PropTypes;

    handleClose = (isRemoveFilters, isApplyExclude) => {
      if (isRemoveFilters) {
        this.props.setStreamSchemaTransform({
          colId: this.props.col.id,
          transforms: [],
        });
      }
      if (isApplyExclude) {
        this.props.setStreamSchemaColumnKeyVal(
          {
            colId: this.props.col.id,
            key: 'hidden',
            value: true,
          },
          {runStreamPreview: true},
        );
      }
      this.props.onClose();
    };

    render() {
      const {isOpen, onClose, col} = this.props;

      return (
        <div automation-id="excludeModal">
          <Modal
            show={isOpen}
            dialogClassName="bc overflow-override"
            onHide={onClose}
            bsSize={col.name.length > 30 ? 'lg' : 'small'}
          >
            <Modal.Header bsClass="bc-modal-header">
              <Modal.Title>
                <span styleName="property" className="ellipsis">
                  {col.name}
                </span>
                <span styleName="action">Filter by this column?</span>
              </Modal.Title>
              <button
                type="button"
                className="btn btn-flat btn-icon-36 btn-secondary"
                onClick={() => this.handleClose(false, false)}
              >
                <i className="icon icn-icon-table-delete" />
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-body-message">
                When you exclude this column, you can also exclude the column&#39;s filters, or you can apply these
                filters to the data stream.
              </div>
            </Modal.Body>
            <Modal.Footer styleName="footer">
              <button
                type="button"
                className="btn btn-raised btn-outline "
                automation-id="excludeModalUpdateButtonExclude"
                onClick={() => this.handleClose(true, true)}
              >
                Also exclude filters
              </button>
              <button
                type="button"
                className="btn btn-raised btn-dark-blue "
                automation-id="excludeModalUpdateButtonApply"
                onClick={() => this.handleClose(false, true)}
              >
                Apply filters
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  },
);
