// @flow
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {StringParam, useQueryParam} from 'use-query-params';
import {getUserProfile} from 'profile/store/selectors';
import {fetchGroupsData} from 'admin.users/store/selectors';
import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import {
  getUsersDataForAvatar,
  getUsersDataForAssigneeFilter,
} from 'alerts.console.new/services/alertsConsoleFiltersService';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS} from 'alerts.console.new/services/alertsConsoleService';

const AssigneeFilter = () => {
  const [assignees, setAssignees] = useQueryParam(ALERT_CONSOLE_QUERY_PARAMS_KEYS.ASSIGNEES, StringParam);

  const groupsData = useSelector(fetchGroupsData);
  const me = useSelector(getUserProfile);

  const usersProviderData = fetchUsersProvider().useQuery();
  const users = usersProviderData?.data;
  const isLoading = usersProviderData?.isLoading;

  const usersOptions = useMemo(() => {
    return getUsersDataForAvatar(users, groupsData);
  }, [users, groupsData]);

  const usersData = useMemo(() => {
    return getUsersDataForAssigneeFilter(users, me, groupsData, assignees);
  }, [users, me, groupsData, assignees]);

  const selectedOptionUser = useMemo(() => {
    return usersData?.filter((user) => (assignees || '').includes(user.value));
  }, [usersData, assignees]);

  const onChange = useCallback(
    (items) => {
      const value = items?.length ? items.map((item) => item.value).join(',') : undefined;
      setAssignees(value);
    },
    [setAssignees],
  );

  const onSelectAllOrClearAllUsers = useCallback(() => {
    // Clear
    if (selectedOptionUser?.length > 0) {
      setAssignees(undefined);
    }
    // Select all
    else {
      const selectedIds = usersOptions.map((item) => item.id).join(',');
      setAssignees(selectedIds);
    }
  }, [setAssignees, usersOptions, selectedOptionUser]);

  return (
    <UsersChannelsFilter
      placeholder={isLoading ? 'Loading...' : 'Select'}
      automationId="assigneeFilter"
      onChange={onChange}
      sortedFlattenChannels={usersData}
      value={selectedOptionUser}
      isFullWidthButton
      footerComponent={
        <FormDdlActionBar
          isVisible
          selectedCount={selectedOptionUser?.length || 0}
          onClearAll={onSelectAllOrClearAllUsers}
        />
      }
    />
  );
};

export default AssigneeFilter;
