// @flow
import React, {useCallback} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import {TYPE} from 'alerts.console/services/alertsConsoleService';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

const TypeFilter = () => {
  const [changedTypes, setChangedTypes] = useQueryParam('types', StringParam);

  const onChange = useCallback(
    (values) => {
      const currentType = values.length ? values.map((i) => i.value).join(',') : undefined;

      setChangedTypes(currentType);
    },
    [setChangedTypes],
  );

  return (
    <SelectAndt
      automationId="alertTypeFilter"
      type={TYPE_NEW_MULTI_NO_SEARCH}
      theme={THEME_HIGHLIGHTED}
      onChange={onChange}
      options={TYPE}
      value={TYPE.filter((item) => changedTypes?.split(',')?.includes(item.value))}
      isMulti
      placeholder="All"
      optionHeight={40}
      menuWidth={155}
      isClearable={false}
      closeMenuOnSelect={false}
      noAutoReorder
      minMenuHeight={100}
    />
  );
};
export default TypeFilter;
