// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {DEFAULT_QUERY_PARAMS} from 'alerts.management/services/alertManagementService';
import {
  setAlertFiltersIsOpen,
  setAlertFiltersIsMore,
  setAlertFiltersView as setAlertFiltersViewAction,
} from 'alerts.management/store/actions';
import {getMeAppSettings, getRecommendationsEnabled} from 'profile/store/selectors';
import * as filterSelector from 'alerts.management/store/filterSelector';
import * as selector from 'alerts.management/store/selectors';
import {Expandable} from 'common/componentsV2/ExpandableSections';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import {get, merge} from 'lodash';
import SearchQueryFilter from 'alerts.management/components/manager/alertFilters/SearchQueryFilter';
import StatusFilter from 'alerts.management/components/manager/alertFilters/StatusFilter';
import TypeFilter from 'alerts.management/components/manager/alertFilters/TypeFilter';
import SeverityFilter from 'alerts.management/components/manager/alertFilters/SeverityFilter';
import OwnerFilter from 'alerts.management/components/manager/alertFilters/OwnerFilter';
import RecipientsFilter from 'alerts.management/components/manager/alertFilters/RecipientsFilter';
import DataStreamFilter from 'alerts.management/components/manager/alertFilters/DataStreamFilter';
import TimeScaleFilter from 'alerts.management/components/manager/alertFilters/TimeScaleFilter';
import AnomalyDirectionFilter from 'alerts.management/components/manager/alertFilters/AnomalyDirectionFilter';
import NotificationsFilter from 'alerts.management/components/manager/alertFilters/NotificationsFilter';
import TagsFilter from 'alerts.management/components/manager/alertFilters/TagsFilter';
import RecommendationsFilter from 'alerts.management/components/manager/alertFilters/RecommendationsFilter';

import './AlertManagerFilters.module.scss';

type PropTypes = {
  setQueryParams: Function,

  // connect
  setAlertFiltersIsOpen: Function,
  setAlertFiltersIsMore: Function,
  setAlertFiltersView: Function,
  isClearAll: boolean,
  appSettings: Object,
  savedFilterView: Object,
  isMoreFiltersDefault: Boolean,
  isMoreFilters: Boolean,
  isRecommendationEnabled: Boolean,
};

export default connect(
  (state) => ({
    appSettings: getMeAppSettings(state),
    isClearAll: filterSelector.getIsShowClearAll(state),
    savedFilterView: filterSelector.getAlertManagerFilersViewSaved(state),
    isMoreFiltersDefault: filterSelector.getIsMoreFilters(state),
    isMoreFilters: selector.getAlertFiltersIsMore(state),
    isRecommendationEnabled: getRecommendationsEnabled(state),
  }),
  {
    setAlertFiltersIsOpen,
    setAlertFiltersIsMore,
    setAlertFiltersView: setAlertFiltersViewAction,
  },
)(
  class AlertManagerFilters extends React.PureComponent {
    props: PropTypes;

    state = {};

    onCloseFiltersClicked = () => {
      this.props.setAlertFiltersIsOpen(false);
    };

    onClearAllClicked = () => {
      this.props.setQueryParams(merge({}, DEFAULT_QUERY_PARAMS, this.props.savedFilterView));
    };

    onMoreFiltersClicked = (isOn) => {
      this.props.setAlertFiltersIsMore(isOn);
    };

    onSaveViewValueChange = (id) => {
      const {setAlertFiltersView, setQueryParams} = this.props;
      if (id === 'reset') {
        setQueryParams(DEFAULT_QUERY_PARAMS);
      }
      setAlertFiltersView(id);
    };

    render() {
      const {setQueryParams, isClearAll, appSettings, isMoreFiltersDefault, isRecommendationEnabled} = this.props;
      const isOpen = get(appSettings, 'alertManager.isFiltersBarOpen', false);
      const actualIsMoreFilters = isMoreFiltersDefault || this.props.isMoreFilters;

      return (
        <FiltersPanel
          automationId="Alert console filter wrapper"
          isOpen={isOpen}
          isClearAll={isClearAll}
          isSaveView
          onClearAll={this.onClearAllClicked}
          onClose={this.onCloseFiltersClicked}
          onSaveViewChange={this.onSaveViewValueChange}
        >
          <div styleName="filters-row">
            <SearchQueryFilter setQueryParams={setQueryParams} />
          </div>

          <div styleName="filters-row">
            <span styleName="filters-row-header">Alert Status</span>
            <StatusFilter setQueryParams={setQueryParams} />
          </div>

          <div styleName="filters-row">
            <TagsFilter setQueryParams={setQueryParams} />
          </div>

          <div styleName="filters-row cols-2 small-menu">
            <TypeFilter setQueryParams={setQueryParams} />
            <SeverityFilter setQueryParams={setQueryParams} />
          </div>

          <Expandable
            automationId="infoAndRecipients"
            label="More Filters"
            expanded={actualIsMoreFilters}
            onChange={this.onMoreFiltersClicked}
            reverse
          >
            <div styleName="more-filters-section">
              <div styleName="filters-row">
                <NotificationsFilter setQueryParams={setQueryParams} />
              </div>

              {isRecommendationEnabled && (
                <div styleName="filters-row">
                  <RecommendationsFilter setQueryParams={setQueryParams} />
                </div>
              )}

              <div styleName="filters-row">
                <span styleName="filters-row-header">Alert Owner</span>
                <OwnerFilter setQueryParams={setQueryParams} />
              </div>

              <div styleName="filters-row">
                <span styleName="filters-row-header">Recipients</span>
                <RecipientsFilter setQueryParams={setQueryParams} />
              </div>

              <div styleName="filters-row">
                <span styleName="filters-row-header">From Data Stream</span>
                <DataStreamFilter setQueryParams={setQueryParams} />
              </div>

              <div styleName="filters-row cols-2" style={{marginBottom: 0}}>
                <div styleName="small-menu">
                  <span styleName="filters-row-header">Timescale</span>
                  <TimeScaleFilter setQueryParams={setQueryParams} />
                </div>
                <div styleName="small-menu">
                  <span styleName="filters-row-header">Anomaly Direction</span>
                  <AnomalyDirectionFilter setQueryParams={setQueryParams} />
                </div>
              </div>
            </div>
          </Expandable>
        </FiltersPanel>
      );
    }
  },
);
