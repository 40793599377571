import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {segmentClickEvent} from 'common/store/actions';
import ContentLoader from 'common/componentsV2/ContentLoader';
import '../OverwiewTab.module.scss';

const InfoCube = ({
  title,
  children,
  isClickable,
  onClick,
  onShareClicked,
  onCloseBalloon,
}: {
  title: String,
  children: any,
  isClickable: boolean,
  onClick: Function,
  onShareClicked: Function,
  onCloseBalloon: Function,
}) => {
  const [isLoading, setIsLoading] = useState(undefined);
  const dispatch = useDispatch();

  const reportToSegment = (cubeTitle) => {
    const eventName = `alert-triage-overview-${cubeTitle.toLowerCase().replace(/\s/g, '_')}-cube`;
    dispatch(segmentClickEvent({type: 'click', name: eventName}));
  };

  const clicked = (event) => {
    reportToSegment(title);
    onClick(event);
  };

  const onIsLoading = (isLoadingFromComp) => {
    setIsLoading(isLoadingFromComp);
  };

  return (
    <div styleName={['info-cube', isClickable ? 'info-cube-clickable' : ''].join(' ')} onClick={clicked}>
      {isLoading && <ContentLoader rows={[{position: 'absolute', zIndex: '1', width: '100%', height: '100%'}]} />}
      <div styleName="info-cube-wrapper">
        <div styleName="info-cube-title">{title}</div>
      </div>
      <div styleName="info-cube-comp-wrapper">
        {React.cloneElement(children, {onShareClicked, onCloseBalloon, onIsLoading})}
      </div>
    </div>
  );
};

export default InfoCube;
