// @flow
import React from 'react';
import {noop} from 'lodash';
import {useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {segmentClickEvent} from 'common/store/actions';
import {GenerateIcon} from '@pileus-cloud/anodot-frontend-common';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    '&:hover': {
      '& $arrow': {
        color: `${theme.palette.blue[500]}`,
      },
      '& $text': {
        color: `${theme.palette.blue[500]} !important`,
      },
    },
  },
  wrapperNonSortable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'default',
  },
  text: {
    fontWeight: '500',
    fontSize: 12,
    marginBottom: '4px',
    color: ({isActive}) => (isActive ? theme.palette.gray[500] : theme.palette.gray[400]),
    cursor: ({isSortable}) => (isSortable ? 'pointer' : 'default'),
  },
  arrow: {
    color: theme.palette.gray[500],
    cursor: 'pointer',
    '&:before': {
      lineHeight: '20px',
    },
  },
}));

const DEFAULT_QUERY_KEY_ORDER = 'DESC';

const TitleHeaderTableOrder = ({
  columnName,
  title,
  faIcon,
  isActive,
  onChange,
  selectedDirection,
  isSortable = true,
  automationId,
}: {
  title: string,
  faIcon: string,
  columnName: string,
  onChange: Function,
  isActive: Boolean,
  selectedDirection: string,
  isSortable: boolean,
  automationId: string,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles({isActive, isSortable});

  const onSort = () => {
    dispatch(segmentClickEvent({type: 'sort', name: title}));
    onChange(columnName);
  };

  return (
    <div
      role="button"
      onClick={isSortable ? onSort : noop}
      onKeyPress={isSortable ? onSort : noop}
      tabIndex={0}
      className={isSortable ? classes.wrapper : classes.wrapperNonSortable}
      automation-id={automationId || `titleHeaderTableOrder-${title || columnName.toLowerCase()}`}
    >
      {title ? (
        <div className={classes.text}>{title}</div>
      ) : (
        <div>{faIcon ? <GenerateIcon iconName={faIcon} /> : <div />}</div>
      )}
      {isActive && isSortable && (
        <div
          className={
            selectedDirection.toUpperCase() === DEFAULT_QUERY_KEY_ORDER
              ? `${classes.arrow} icon icn-arrow16-arrowdown`
              : `${classes.arrow} icon icn-arrow16-arrowup`
          }
        />
      )}
    </div>
  );
};

export default TitleHeaderTableOrder;
