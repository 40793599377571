// @flow
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {orderBy, sumBy, get} from 'lodash';
import {updateColor} from 'charts/timeSeries/services/timeSeriesHchartService';
import PieChart from 'charts/pieChart/PieChart';
import {useField} from 'react-final-form';
import {useSelector, useDispatch} from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import {getBucketStartTimeEnabled, getTimeZoneName} from 'profile/store/selectors';
import {makeStyles} from '@material-ui/core';
import PieLegendRow from 'dashboards/components/PieLegendRow';
import PieSliceTooltip from 'dashboards/components/PieSliceTooltip';
import ResizableBox from 'dashboards/components/ResizableBox';
import {makeUpdatePieLegendPayload, makeUpdateTilePayload} from 'dashboards/utils';
import {updateDashboard} from 'dashboards/store/actions';
import useDashboardState from 'dashboards/hooks/useDashboardState';
import {PIE_CHART_SECTIONS_NUMBER} from 'dashboards/services/constants';

const tooltipFormatter = {
  formatter() {
    return ReactDOMServer.renderToString(<PieSliceTooltip data={this} />);
  },
};

const useStyles = makeStyles(() => ({
  pieContainer: {
    width: ({isFullSize}) => (isFullSize ? '20%' : '100%'),
    height: ({isFullSize}) => (isFullSize ? 250 : '100%'),
    flexShrink: 0,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 16,
    paddingRight: 12,
    marginBottom: ({isFullSize}) => (isFullSize ? 24 : 0),
  },
  legendContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
    marginLeft: 4,
    maxHeight: '100%',
    maxWidth: '100%',
  },
  label: {
    maxWidth: '70%',
  },
  pieChart: {
    marginTop: ({isFullSize}) => (isFullSize ? 0 : 16),
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexGrow: 1,
    flexDirection: ({isFullSize}) => (isFullSize ? 'column' : 'row'),
    pointerEvents: ({isLoading}) => (isLoading ? 'none' : 'auto'),
  },
}));

const PieChartContainer = ({
  nameMeasure,
  showLegend,
  id,
  metrics,
  isShowMeasure,
  isMetricEmpty,
  inputNoData,
  tileType,
  tileData,
  dashboardData,
  dashboardId,
  actualTileLayout,
  isAbbreviate,
  isDecimal,
  isFullSize,
  isOwnerUser,
  isAnonymous,
  isHideResizeHandler,
}: {
  showLegend: boolean,
  nameMeasure: string,
  id: string,
  metrics: Array,
  isShowMeasure: boolean,
  isMetricEmpty: boolean,
  inputNoData: string,
  tileType: string,
  tileData: Object,
  dashboardData: Object,
  dashboardId: string,
  actualTileLayout: Object,
  isAbbreviate: boolean,
  isDecimal: boolean,
  isFullSize: boolean,
  isOwnerUser: boolean,
  isAnonymous: boolean,
  isHideResizeHandler: boolean,
}) => {
  const dashboard = useDashboardState(dashboardId);
  const {isLoading} = dashboard;
  const classes = useStyles({isFullSize, isLoading});
  const dispatch = useDispatch();
  const [highlightedIndex, setHighlightedIndex] = useState();
  const [hchart, setHChart] = useState();
  const [isLegendVisable, setIsLegendVisable] = useState(showLegend);

  useEffect(() => {
    // replace chart formatter in case related props changed
    if (hchart && hchart.tooltip) {
      hchart.update({
        tooltip: {
          formatter() {
            return ReactDOMServer.renderToString(
              <PieSliceTooltip data={this} isDecimal={isDecimal} isAbbreviate={isAbbreviate} />,
            );
          },
        },
      });
    }
  }, [hchart, isDecimal, isAbbreviate]);

  const {
    input: {value: dateRange},
  } = useField('dateRange');

  const {
    input: {value: timeScale},
  } = useField('timeScale');
  const {
    input: {value: isResize, onChange: setResize},
  } = useField('isResize');

  useEffect(() => {
    // Hide legend in case height is less than 2
    if (actualTileLayout?.h < 3) setIsLegendVisable(false);
    else setIsLegendVisable(showLegend);
    setResize(!isResize);
  }, [actualTileLayout, showLegend, isShowMeasure]);

  const timeZoneName = useSelector(getTimeZoneName);
  const bucketStartTimeEnabled = useSelector(getBucketStartTimeEnabled);

  const [hiddenSeriesMap, updateHiddenSeriesMap] = useState({});

  const commonData = {
    dateRange,
    timeScale,
    timeZoneName,
    bucketStartTimeEnabled,
    what: nameMeasure,
  };
  const allData = orderBy(
    metrics.map((item) => ({
      ...item,
      name: item.id,
      y: Object.values(item.scalarTransforms)[0].value || 0,
      ...commonData,
    })),
    (s) => s.y,
    'desc',
  );

  const maxDataArr = allData.slice(0, PIE_CHART_SECTIONS_NUMBER);
  const otherDataSum = sumBy(allData.slice(PIE_CHART_SECTIONS_NUMBER), 'y');

  const data = otherDataSum
    ? [
        ...maxDataArr.map((item, index) => ({...item, index})),
        {
          id: `other_${get(tileData, 'id', '')}`,
          properties: [{key: 'Type', value: 'other'}],
          tags: [],
          name: 'other',
          y: otherDataSum,
          index: maxDataArr.length,
          ...commonData,
        },
      ]
    : maxDataArr;

  const formattedData = data.map((item, index, source) => ({
    ...item,
    // Make sure that percentage is a number and not divided by 0
    percentage: (isNaN(item.y / sumBy(source, 'y')) ? 0 : item.y / sumBy(source, 'y')) * 100,
  }));

  const visibleData = formattedData.filter((item, index) => !hiddenSeriesMap[index]);

  // Filter out 0 percent records
  const hasNonEmptyData = formattedData.filter((vdata) => vdata.percentage > 0).length > 0;

  const toggleVisibility = (index) => {
    updateHiddenSeriesMap({...hiddenSeriesMap, [index]: !hiddenSeriesMap[index]});
  };

  const theme = {
    basicColor: data
      .map((item, index) => updateColor({index, metric: {id: item.name}, tileData}))
      .filter((item, index) => !hiddenSeriesMap[index]),
    borderWidth: 0,
  };

  const chartElement = (
    <div className={classes.pieContainer}>
      {isMetricEmpty || !hasNonEmptyData || visibleData.length === 0 ? (
        // isMetricEmpty = no data from server
        // !hasNonEmptyData = there is no group without 0 percent
        // visibleData.length === 0 = all groups are hidden
        <>
          <div className="title30 color_gray-400 mt_0-5 alignSelf_center">
            {isMetricEmpty || !hasNonEmptyData ? inputNoData : 'N/A'}
          </div>
        </>
      ) : (
        <PieChart
          onMouseOverEvent={(event) => setHighlightedIndex(event.target.options.index)}
          onMouseOutEvent={() => setHighlightedIndex()}
          isResize={isResize}
          getHighChartRef={setHChart}
          key={`${visibleData.length}_${tileType}_${theme.basicColor.join('_')}`}
          onClickEvent={() => {}}
          id={id}
          height="100%"
          width="100%"
          data={visibleData}
          theme={theme}
          tooltipFormatter={tooltipFormatter}
          animation={false}
          isDonut={tileType === 'donut'}
          hoverEnabled
        />
      )}
    </div>
  );

  const legendElement =
    (isLegendVisable && hasNonEmptyData) || isFullSize ? (
      <div className={classes.legendContainer}>
        {data.map((item, index) => (
          <PieLegendRow
            isHighlighted={index === highlightedIndex}
            areValuesVisible={highlightedIndex !== undefined}
            data={visibleData}
            hchart={hchart}
            item={item}
            index={index}
            toggleVisibility={toggleVisibility}
            hiddenSeriesMap={hiddenSeriesMap}
            tileData={tileData}
            dashboardId={dashboardId}
            isDecimal={isDecimal}
            isAbbreviate={isAbbreviate}
          />
        ))}
      </div>
    ) : null;

  const legendSize = useMemo(() => {
    const width = tileData?.andtGauge?.legend?.horizontalRatio;
    return {
      w: width > 1 ? 0.3 : width,
    };
  }, [tileData]);

  const onChartResize = useCallback(
    (size) => {
      const tile = makeUpdatePieLegendPayload(tileData, Number(size.toFixed(2)), actualTileLayout);
      if (!isAnonymous && isOwnerUser) {
        dispatch(updateDashboard(makeUpdateTilePayload(dashboardData, tile, tileData.id)));
      }
    },
    [dashboardData, tileData, isAnonymous, isOwnerUser, actualTileLayout],
  );

  const onResizeHandler = useCallback(
    (size) => {
      onChartResize(size.w);
      setResize(!isResize);
    },
    [isResize, onChartResize],
  );

  if (isLegendVisable && !isFullSize && !isHideResizeHandler) {
    return (
      <div className={classes.pieChart}>
        <ResizableBox
          size={legendSize}
          onResize={onResizeHandler}
          firstElement={chartElement}
          secondElement={legendElement}
          isHorizontal
        />
      </div>
    );
  }

  return (
    <div className={classes.pieChart}>
      {chartElement}
      {legendElement}
    </div>
  );
};

export default React.memo(PieChartContainer);
