import React, {useEffect, useMemo} from 'react';
import {ALERT_TYPES} from 'investigationNewAlertConsole/services/investigationService';
import fetchConfigurationAlertByIdProvider from 'alerts.management/api/fetchConfigurationAlertByIdProvider';

import '../OverwiewTab.module.scss';

const WhereInfoCubeStatic = ({
  onIsLoading,
  isTriggerLoading,
  trigger,
}: {
  onIsLoading: Function,
  isTriggerLoading: Boolean,
  trigger: Object,
}) => {
  const alertConfigurationResult = fetchConfigurationAlertByIdProvider(trigger?.alertConfigurationId)?.useQuery();
  const alertConfiguration = alertConfigurationResult?.data;

  const numberOfActiveMetrics = useMemo(() => {
    return alertConfiguration?.data?.numOfActiveMetrics || 0;
  }, [alertConfiguration]);

  useEffect(() => {
    onIsLoading(isTriggerLoading);
  }, [isTriggerLoading]);

  const percentage = (Math.round((trigger.numMetrics * 10000) / numberOfActiveMetrics) / 100).toFixed(2);

  return (
    <>
      {trigger && trigger?.metrics?.length ? (
        <div styleName="info-cube-container">
          <div>
            <div styleName="where-percentage">{numberOfActiveMetrics === 0 ? '--' : `${percentage}%`}</div>
            <div styleName="where-percentage-commentary">of included metrics</div>
          </div>
          <div styleName="info-cube-where-grid">
            <div styleName="grid-cell left">
              {trigger.type === ALERT_TYPES.NO_DATA ? 'Metrics in no data' : 'Metrics in alert'}
            </div>
            <div styleName="grid-cell right">{trigger.numMetrics}</div>
            <div styleName="grid-cell left">Metrics defined in the alert</div>
            <div styleName="grid-cell right">{numberOfActiveMetrics}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WhereInfoCubeStatic;
