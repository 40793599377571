/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(({typography, palette}) => ({
  wrapper: {
    position: 'relative',
    width: 343,
    padding: '12px 24px 12px 12px',
  },
  icon: {
    position: 'absolute',
    left: 10,
    top: 10,
    color: palette.gray['400'],
    fontSize: 16,
  },
  title: {
    position: 'relative',
    left: 18,
    ...typography.subtitle3,
    display: 'block',
    marginBottom: 4,
  },
  text: {
    ...typography.body1,
    letterSpacing: 0,
  },
}));

export default function InfoTile({title, body}) {
  const classes = useStyles();

  if (!title || !body) {
    return null;
  }
  return (
    <>
      <div className={classes.wrapper}>
        <i className={`icon icn-general16-info ${classes.icon}`} />
        {title && <span className={classes.title}>{title}</span>}
        {body && <div dangerouslySetInnerHTML={{__html: body}} />}
      </div>
    </>
  );
}

InfoTile.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};
