// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import Button, {COLORS} from 'common/componentsV2/Button';
import {makeStyles} from '@material-ui/core/styles';
import CSSTransition from 'react-transition-group/CSSTransition';
import {getIsFetching} from 'userSettings/store/selectors';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    left: 60,
    right: 0,
    bottom: 0,
    borderTop: `2px solid ${theme.palette.blue[300]}`,
    backgroundColor: theme.palette.blue[100],
    transition: 'height ease-in-out .3s',
    overflow: 'visible',
    '&:before': {
      content: '""',
      position: 'fixed',
      left: '60px',
      display: 'block',
      height: '72px',
      width: '34px',
      // eslint-disable-next-line max-len
      backgroundImage: `linear-gradient(to right, ${theme.palette.white[500]}, ${
        theme.palette.white[500]
      } 8%, rgba(255, 255, 255, 0))`,
    },
    '&-appear': {
      height: 0,
    },
    '&-appear-active': {
      height: 70,
      transition: 'height 300ms',
    },
    '&-exit': {
      height: 70,
    },
    '&-exit-active': {
      height: 0,
      transition: 'height 300ms',
    },
    '&-enter-done': {
      height: 70,
    },
  },
  contentBottomBar: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    flex: '1 1 auto',
    maxWidth: 1680,
    margin: '0 90px',
    '@media screen and (max-width: 1290px)': {
      marginLeft: 20,
      marginRight: 0,
      paddingRight: 24,
    },
  },
  inner: {
    position: 'relative',
    display: 'flex',
  },
  popper: {
    borderRadius: 6,
    position: 'absolute',
    backgroundColor: theme.palette.red[500],
    padding: '8px 12px',
    color: theme.palette.white[500],
    fontWeight: 'bold',
    top: -70,
    right: 0,
    zIndex: 1,
    maxWidth: 350,
  },
  popperArrow: {
    position: 'absolute',
    width: 12,
    height: 12,
    bottom: -6,
    right: 29,
    backgroundColor: theme.palette.red[500],
    transform: 'rotate(45deg)',
  },
}));

const BottomPanel = ({isOpenPopper, errorNumbers}: {isOpenPopper: boolean, errorNumbers: number}) => {
  const isFetching = useSelector((state) => getIsFetching(state));
  const classes = useStyles();

  return (
    <CSSTransition timeout={300} classNames={classes.root} appear in>
      <div className={classes.root}>
        <div className={classes.contentBottomBar}>
          <div className={classes.inner}>
            {isOpenPopper && (
              <div className={classes.popper}>
                <TypographyBox variant="subtitle2" lineHeight="21px" color="white.500" minWidth="max-content">
                  {`${errorNumbers} Errors to fix`}
                </TypographyBox>
                <div className={classes.popperArrow} />
              </div>
            )}
            <Button type="submit" text="Save" colorSchema={COLORS.BLUE_500} isDisabled={isFetching} />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default BottomPanel;
