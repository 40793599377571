import {combineEpics} from 'redux-observable';
import * as chartActions from 'charts/timeSeries/store/actions';
import * as profileActions from 'profile/store/actions';
import * as alertsConsoleSelector from 'alerts.console.new/store/selectors';
import * as api from 'alerts.console.new/api/api';

import {generateChartSeriesMetricModel, processSeriesData} from 'charts/timeSeries/services/timeSeriesDataService';
import {CHART_COLORS, getThresholdPlotLineConfig} from 'charts/timeSeries/services/timeSeriesHchartSettingsService';
import {
  addPlotBand,
  addPlotLine,
  endLoad,
  pushSeries,
  startLoad,
} from 'charts/timeSeries/services/timeSeriesHchartService';
import {getApiTreeForNode, getEmptyTree} from 'common/utils/angularServices';
import {get} from 'lodash';

import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

import {makeFlatAsyncEpic} from 'common/utils/simplifiedAsync';
import {Observable} from 'rxjs/Observable';
// import {info} from 'common/utils/notifications/notificationsService';
import Highcharts from 'highcharts';
import * as actions from 'alerts.console.new/store/actions';

// eslint-disable-next-line no-undef
const timeSeriesCharts = new Map();

const fetchAlertConsoleMetricsDataPoints = (action$, {getState}) =>
  action$.ofType(actions.fetchAlertConsoleMetricsDataPoints.TYPE).flatMap((action) => {
    // TODO menny: add cancellation behaviour here (use rx.scan)

    const hchart = timeSeriesCharts.get(action.meta.chartId);
    const dataPoints = alertsConsoleSelector.getAlertMetrics(getState());
    const alertDataPoints = dataPoints ? dataPoints[action.meta.key] : null;

    startLoad(hchart);

    // if we have an alert that's open we cancel the request.
    if (alertDataPoints && alertDataPoints.data) {
      return [actions.fetchAlertConsoleMetricsDataPoints.success(alertDataPoints.data, action.meta)];
    }

    const res = getEmptyTree();
    res.expressionTree.root.searchObject = {
      ids: [action.payload.metricId],
    };

    const newAction = {
      ...action,
      payload: {
        ...action.payload,
        body: {
          composite: getApiTreeForNode(res.expressionTree.root, res, null, {
            alertId: action.meta.alertId, // the alert trigger ID
            // we dont measure metricID cause it will cause the metrics name to be invalid
            // metricId: action.meta.metricId,
            context: 'alertsConsoleNew',
          }),
        },
      },
    };

    return api
      .fetchAlertConsoleMetricsDataPoints(newAction, getState)
      .map((payload) => actions.fetchAlertConsoleMetricsDataPoints.success(payload, newAction.meta))
      .catch((error) => Observable.of(actions.fetchAlertConsoleMetricsDataPoints.failure(error, newAction.meta)));
  });

const fetchAlertConsoleMetricsDataPointsSuccess = (action$, {getState}) =>
  action$
    .ofType(actions.fetchAlertConsoleMetricsDataPoints.success.TYPE)
    .do(({payload, meta}) => {
      const hchart = timeSeriesCharts.get(meta.chartId);
      const chartData = alertsConsoleSelector.getAlertMetrics(getState())[meta.key];

      if (!hchart) {
        return; // console item was probably collapsed
      }
      if (!hchart || !get(payload, 'metrics.length') || !payload.validation.passed) {
        endLoad(hchart, chartData.startDate, chartData.endDate);
        return;
      }
      const metricData = payload.metrics[0];
      const metricsModel = generateChartSeriesMetricModel(metricData);
      const processedSeriesData = processSeriesData(
        metricData.dataPoints,
        metricData.baseline,
        meta.isAnomaly || meta.isStatic ? meta.intervals : null,
        null,
      );
      const isVisible = !chartData.hiddenSeries?.find((metricId) => metricId === meta.metricId);

      const metricThreshold = {};
      const noDataZonesConfig = {
        zones: [],
        zoneColor: CHART_COLORS.NO_DATA_LINE_COLOR,
        zoneDashStyle: 'Dot',
      };

      meta.intervals.forEach((interval, index) => {
        if (interval.direction === 'UP') {
          if (!metricThreshold.upper || metricThreshold.upper > interval.threshold) {
            metricThreshold.upper = interval.threshold;
          }
        } else if (interval.direction === 'DOWN') {
          if (!metricThreshold.lower || metricThreshold.lower > interval.threshold) {
            metricThreshold.lower = interval.threshold;
          }
        } else if (interval.lastSeenMachineTimeEpoch) {
          const noDataFrom = interval.lastSeenMachineTimeEpoch * 1000;
          const noDataTo = interval.endTime * 1000;
          const isBackFilled = metricData.dataPoints.some((p) => p[0] > noDataFrom && p[0] < noDataTo);
          const z = {
            startValue: noDataFrom,
            endValue: noDataTo,
          };
          if (!isBackFilled) {
            z.color = 'rgba(61, 76, 89, 0)';
          }
          noDataZonesConfig.zones.push(z);
          addPlotBand(
            hchart,
            {
              from: noDataFrom,
              to: noDataTo,
              zIndex: 3,
              color: 'rgba(61, 76, 89, 0.1)',
              id: `nodata-plot-band-${index}`,
            },
            'x',
          );
        }
      });

      pushSeries(
        hchart,
        chartData.seriesProperties,
        metricsModel,
        processedSeriesData,
        isVisible,
        false,
        noDataZonesConfig,
      );

      Object.keys(metricThreshold).forEach((key, index) => {
        const thresholdConfig = getThresholdPlotLineConfig();
        thresholdConfig.id = `threshold_${index}`;
        thresholdConfig.value = metricThreshold[key];
        thresholdConfig.label.text = key;
        addPlotLine(hchart, thresholdConfig);
      });

      endLoad(
        hchart,
        chartData.zoomRange ? chartData.zoomRange.startDate : chartData.startDate,
        chartData.zoomRange ? chartData.zoomRange.endDate : chartData.endDate,
      );

      if (chartData.zoomRange && hchart.andt) {
        hchart.andt.showResetZoomButton();
      }
    })
    .flatMap(() => []);

const fetchAlertConsoleMetricsDataPointsFailure = (action$) =>
  action$
    .ofType(actions.fetchAlertConsoleMetricsDataPoints.failure.TYPE)
    .do(({meta}) => {
      const hchart = timeSeriesCharts.get(meta.chartId);
      if (hchart) {
        return endLoad(hchart);
      }
      return null;
    })
    .flatMap(() => []);

const fetchAlertEvents = makeFlatAsyncEpic(actions.fetchAlertEvents, api.fetchAlertEvents);

const fetchAlertMultipleEvents = makeFlatAsyncEpic(actions.fetchAlertMultipleEvents, api.fetchAlertMultipleEvents);

const highChartCreated = (action$) =>
  action$
    .ofType(chartActions.highChartCreated.TYPE)
    .do((action) => {
      const hchart = Highcharts.charts.find((chart) => chart && chart.renderTo.id === action.payload.chartId);
      timeSeriesCharts.set(action.payload.chartId, hchart);
    })
    .flatMap(() => []);

const highChartDestroyed = (action$) =>
  action$
    .ofType(chartActions.highChartDestroyed.TYPE)
    .do((action) => {
      timeSeriesCharts.delete(action.payload.chartId);
    })
    .flatMap(() => []);

const timeZoneChanged = (action$) =>
  action$.ofType(profileActions.timeZoneChanged.TYPE).switchMap(() => {
    return [];
  });

const alertsConsoleEpic = combineEpics(
  fetchAlertConsoleMetricsDataPoints,
  fetchAlertConsoleMetricsDataPointsSuccess,
  fetchAlertConsoleMetricsDataPointsFailure,
  fetchAlertEvents,
  fetchAlertMultipleEvents,
  highChartCreated,
  highChartDestroyed,
  timeZoneChanged,
);
export default alertsConsoleEpic;
