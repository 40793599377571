// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setStreamDiametricsEditorFilterTextInput as setStreamDiametricsEditorFilterTextInputAction,
  setMixpanelStreamClearAllDiametrics as setMixpanelQueryStreamClearAllDiametricsAction,
  setMixpanelStreamDiametricsChange as setMixpanelStreamDiametricsChangeAction,
  removeMixpanelStreamDiametrics as removeMixpanelStreamDiametricsAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import DimensionsAndMetricsEditor from 'bc/components/streams/editor/common/DimensionsAndMetricsEditor';

type PropTypes = {
  dimensions: Array<Object>,
  metrics: Array<Object>,
  filteredUnassignedColumns: Array<Object>,
  filterTextInput: String,
  setStreamDiametricsEditorFilterTextInput: Function,
  setMixpanelStreamClearAllDiametrics: Function,
  setMixpanelStreamDiametricsChange: Function,
  removeMixpanelStreamDiametrics: Function,
  isStreamUpdating: Boolean,
};

class MixpanelDimensionsAndMetricsEditor extends React.PureComponent {
  props: PropTypes;

  render() {
    const {
      metrics,
      dimensions,
      filterTextInput,
      filteredUnassignedColumns,
      setStreamDiametricsEditorFilterTextInput,
      removeMixpanelStreamDiametrics,
      setMixpanelStreamClearAllDiametrics,
      setMixpanelStreamDiametricsChange,
      isStreamUpdating,
    } = this.props;

    return (
      <DimensionsAndMetricsEditor
        setMetricDimentionEditorFilterTextInput={setStreamDiametricsEditorFilterTextInput}
        dimensions={dimensions}
        metrics={metrics}
        filteredUnassignedColumns={filteredUnassignedColumns}
        filterTextInput={filterTextInput}
        isStreamUpdating={isStreamUpdating}
        setStreamClearAllDiametrics={setMixpanelStreamClearAllDiametrics}
        setStreamDiametricsChange={setMixpanelStreamDiametricsChange}
        removeStreamDiametrics={removeMixpanelStreamDiametrics}
        isRemoveDateSection
        overwriteIdentifier="name"
      />
    );
  }
}

export default connect(
  (state) => ({
    filterTextInput: selectors.getStreamMetricsDimentionsFilterTextInput(state),
    dimensions: selectors.getMixpanelStreamSelectedDimensions(state),
    metrics: selectors.getMixpanelStreamSelectedMetrics(state),
    filteredUnassignedColumns: selectors.getFilteredMixpanelStreamUnAssignedColumns(state),
    isStreamUpdating: selectors.getIsDataStreamUpdating(state),
  }),
  {
    setStreamDiametricsEditorFilterTextInput: setStreamDiametricsEditorFilterTextInputAction,
    setMixpanelStreamClearAllDiametrics: setMixpanelQueryStreamClearAllDiametricsAction,
    setMixpanelStreamDiametricsChange: setMixpanelStreamDiametricsChangeAction,
    removeMixpanelStreamDiametrics: removeMixpanelStreamDiametricsAction,
  },
)(MixpanelDimensionsAndMetricsEditor);
