// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import Button, {COLORS} from 'common/componentsV2/Button';
import DelayMinutes from 'bc/components/streams/editor/scheduler/DelayMinutes';

import './SegmentSchedularModal.module.scss';

type PropTypes = {
  setSelectedStreamKeyVal: Function,

  dataStream: Object,
  onClose: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)(
  class SegmentSchedularModal extends React.PureComponent {
    props: PropTypes;

    state = {
      delayMinutes: this.props.dataStream.delayMinutes || 5,
    };

    onCloseButtonClicked = () => {
      this.props.onClose();
    };

    doNothing = () => {};

    delayMinutesChanged = (val) => {
      this.setState({delayMinutes: val});
    };

    onSave = () => {
      this.props.setSelectedStreamKeyVal({
        delayMinutes: this.state.delayMinutes,
      });
      this.onCloseButtonClicked();
    };

    render() {
      return (
        <div styleName="modal-body">
          <div styleName="itemContainer">
            <DelayMinutes delayMinutes={this.state.delayMinutes} onChange={this.delayMinutesChanged} maxVal={60} />
          </div>

          <div styleName="footer">
            <div styleName="left-button">
              <Button
                colorSchema={COLORS.GRAY_400}
                text="Cancel"
                automation-id="schedulerEditorModalCancel"
                onClick={this.onCloseButtonClicked}
              />
            </div>
            <div>
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Save"
                automation-id="schedulerEditorModalSave"
                onClick={this.onSave}
              />
            </div>
          </div>
        </div>
      );
    }
  },
);
