import {useSelector} from 'react-redux';
import React, {useEffect, useMemo} from 'react';
import {isEmpty} from 'lodash';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {getIncidentTokenMapIsLoading, getIncidentTokenMapData} from 'investigationNewAlertConsole/store/selectors';
import DimensionChip from 'investigationNewAlertConsole/components/dimensions/DimensionChip';
import {setMaxPercentToDisplayTrimmed} from 'common/utils/numbers';
import {setMeasures} from 'investigationNewAlertConsole/api/utils';

import '../OverwiewTab.module.scss';

const WhereInfoCube = ({
  onIsLoading,
  isTriggerLoading,
  trigger,
}: {
  onIsLoading: Function,
  isTriggerLoading: Boolean,
  trigger: Object,
}) => {
  const isTokenMapLoading = useSelector(getIncidentTokenMapIsLoading);
  const tokenMap = useSelector(getIncidentTokenMapData);
  const isLoading = isTriggerLoading || isTokenMapLoading;

  const where = useMemo(() => {
    if (!trigger?.metrics[0] || isEmpty(tokenMap)) {
      return {};
    }
    const alertMeasures = setMeasures(trigger.metrics);
    const whereCalculation = Object.entries(tokenMap?.dimMap)?.find((entry) => entry[0] === alertMeasures[0]);
    if (whereCalculation?.length > 1) {
      const whereTokens = Object.entries(tokenMap?.dimMap)?.find((entry) => entry[0] === alertMeasures[0])[1];
      const whereTokensEntries = Object.entries(whereTokens);
      if (whereTokensEntries?.length) {
        const whereTokenWithMaxWeight = whereTokensEntries.find(
          (token) => token[1].data[0].weight === Math.max(...whereTokensEntries.map((t) => t[1].data[0].weight)),
        );
        return {
          key: whereTokenWithMaxWeight[1].data[0].key,
          value: whereTokenWithMaxWeight[1].data[0].value,
          percentage: setMaxPercentToDisplayTrimmed(whereTokenWithMaxWeight[1].data[0].weight * 100),
        };
      }
    }
    return {};
  }, [isTokenMapLoading, isTriggerLoading, trigger, tokenMap]);

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      {where ? (
        <div styleName="info-cube-container">
          <div>
            <div styleName="where-info-cube-text">Anomaly occurs in:</div>
            <div styleName="where-info-cube-where-container">
              <Tooltip content={where?.key} type={TYPES.LARGE}>
                <div styleName="tooltip-container">
                  <span styleName="where-text">{where?.key}</span>
                </div>
              </Tooltip>
              <DimensionChip label={where?.value} />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WhereInfoCube;
