// @flow
import React from 'react';
import {makeStyles} from '@material-ui/core';
import {ReactComponent as Net} from 'common/componentsV2/holiday/halloween/images/net.svg';
import {ReactComponent as Spider} from 'common/componentsV2/holiday/halloween/images/spider.svg';
import './styles.modules.scss';

const animationDuration = '4s';
const animationDelay = '10s';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
  spider: {
    position: 'absolute',
    top: 35,
    right: 0,
    animation: `bounce-in-top ${animationDuration}`,
    animationDelay,
  },
  cobweb: {
    position: 'absolute',
    top: 95,
    right: 40,
    width: 1,
    height: 0,
    background: '#979797',
    opacity: 0.3,
    animation: `cobweb-bounce-in-top ${animationDuration}`,
    animationDelay,
  },
}));

const HolidayAnimation = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <Net />
      <div className={classes.cobweb} />
      <Spider className={classes.spider} />
    </div>
  );
};

export default HolidayAnimation;
