// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import './AlertManagerListItemExpendablePanel.module.scss';
import {segmentClickEvent} from 'common/store/actions';
import {getNewAlertConsoleEnabledAndImpersonate, getNewAlertConsoleMigrationFinished} from 'profile/store/selectors';
import AlertSummaryPanel from './alertExpandablePanel/AlertSummaryPanel';
import AlertTriggerPanel from './alertExpandablePanel/AlertTriggerPanel';
import AlertDetailsPanel from './alertExpandablePanel/AlertDetailsPanel';
import AlertInfoSideNavigation from './alertExpandablePanel/AlertInfoSideNavigation';
import {setSelectedAlertTab} from '../../store/actions';
import * as selector from '../../store/selectors';
import AlertTriggerPanelNewAlertConsole from './alertExpandablePanel/AlertTriggerPanelNewAlertConsole';

type PropTypes = {
  alert: Object,

  selectedAlertTab: Number,
  isNewAlertConsoleEnabledAndImpersonate: Boolean,
  isNewAlertConsoleMigrationFinished: Boolean,

  setSelectedAlertTab: Function,
  segmentClickEvent: Function,
};
export default connect(
  (state) => ({
    selectedAlertTab: selector.getSelectedAlertTab(state),
    isNewAlertConsoleEnabledAndImpersonate: getNewAlertConsoleEnabledAndImpersonate(state),
    isNewAlertConsoleMigrationFinished: getNewAlertConsoleMigrationFinished(state),
  }),
  {
    setSelectedAlertTab,
    segmentClickEvent,
  },
)(
  class AlertManagerListItemExpendablePanel extends React.PureComponent {
    props: PropTypes;

    handleTabChange = (id) => {
      this.props.setSelectedAlertTab(id);
    };

    renderTab = (id) => {
      switch (id) {
        case 0:
          this.props.segmentClickEvent({type: 'click', name: 'tab-summary'});
          return <AlertSummaryPanel alert={this.props.alert} />;
        case 1:
          this.props.segmentClickEvent({type: 'click', name: 'tab-console'});
          return this.props.isNewAlertConsoleEnabledAndImpersonate || this.props.isNewAlertConsoleMigrationFinished ? (
            <AlertTriggerPanelNewAlertConsole configurationAlert={this.props.alert} />
          ) : (
            <AlertTriggerPanel alert={this.props.alert} />
          );
        case 2:
          this.props.segmentClickEvent({type: 'click', name: 'tab-details'});
          return <AlertDetailsPanel alert={this.props.alert} />;
        default:
          return null;
      }
    };

    render() {
      const buttonValues = [
        {
          icon: 'icn-nav24-summary',
          tooltipText: 'Summary',
        },
        {
          icon: 'icn-nav24-alertconsole',
          tooltipText: 'Alert Console',
        },
        {
          icon: 'icn-nav24-info',
          tooltipText: 'Details',
        },
      ];
      return (
        <div styleName="container">
          <AlertInfoSideNavigation
            onChange={this.handleTabChange}
            values={buttonValues}
            initialButton={this.props.selectedAlertTab}
          />
          <div styleName="tabs-container">{this.renderTab(this.props.selectedAlertTab)}</div>
        </div>
      );
    }
  },
);
