// @flow
import React, {Fragment} from 'react';
import connect from 'react-redux/es/connect/connect';
import ConfModal from 'common/componentsV2/modal/ConfModal';
import * as selector from 'admin.users/store/selectors';
import {toggleDeleteUserModal, setDeleteUserModalKeyVal, deleteUser} from 'admin.users/store/actions';
import AssetsCountSection from 'admin.users/components/AssetsCountSection';
import StreamsDelegationWarning from 'admin.users/components/UsersTable/StreamsDelegationWarning';
import DelegateUserSection from './DelegateUserSection';
import './UsersModals.module.scss';

type PropTypes = {
  deleteModal: Object,
  userName: string,
  userToDelete: Object,
  delegateUserList: Array,
  isDelegate: boolean,
  delegateUserId: string,
  selectedDelegateUser: Object,
  toggleDeleteUserModal: Function,
  setDeleteUserModalKeyVal: Function,
  deleteUser: Function,
};

export default connect(
  (state) => ({
    deleteModal: selector.getUsersViewDeleteModal(state),
    userName: selector.getUsersViewDeleteModalUserName(state),
    userToDelete: selector.getUsersViewDeleteModalUser(state),
    delegateUserList: selector.getUsersViewDeleteModalDelegateUsersList(state),
    isDelegate: selector.getUsersViewDeleteModalIsDelegate(state),
    delegateUserId: selector.getUsersViewDeleteModalDelegateUserId(state),
    selectedDelegateUser: selector.getUsersViewDeleteModalDelegateUser(state),
  }),
  {
    toggleDeleteUserModal,
    setDeleteUserModalKeyVal,
    deleteUser,
  },
)(
  class DeleteUserModal extends React.PureComponent {
    props: PropTypes;

    onModalClose = () => {
      this.props.toggleDeleteUserModal({isOpen: false});
    };

    onConfirm = () => {
      this.props.deleteUser({
        userId: this.props.userToDelete.id,
        delegateUserId: this.props.delegateUserId,
      });
      this.props.toggleDeleteUserModal({isOpen: false});
    };

    setIsUserDelegate = (isDelegate) => {
      this.props.setDeleteUserModalKeyVal({
        isDelegate,
        delegateUserId: isDelegate ? '' : 'andt-none',
      });
    };

    userDefaultSelected = (itemId) => {
      this.props.setDeleteUserModalKeyVal({
        delegateUserId: itemId,
      });
    };

    render() {
      const {userName, delegateUserList, userToDelete, isDelegate, selectedDelegateUser} = this.props;
      const {isOpen} = this.props.deleteModal;

      return (
        <ConfModal
          isOpen={isOpen}
          onClose={this.onModalClose}
          onConfirm={this.onConfirm}
          isDanger
          isConfirmDisabled={isDelegate && !selectedDelegateUser}
          confirmText="Delete User"
          title={`Delete ${userName}?`}
        >
          <div styleName="delete-body">
            <span>Deleting a user is permanent and cannot be undone.</span>
            {userToDelete && userToDelete.userRole !== 'customer-read-only' ? (
              <Fragment>
                <AssetsCountSection />
                <StreamsDelegationWarning />
                <DelegateUserSection
                  isDelegate={isDelegate}
                  usersList={delegateUserList}
                  selectedDelegateUser={selectedDelegateUser}
                  onIsDelegateChange={this.setIsUserDelegate}
                  onUserDelegateSelect={this.userDefaultSelected}
                />
              </Fragment>
            ) : null}
          </div>
        </ConfModal>
      );
    }
  },
);
