// flow
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Transition} from 'react-transition-group';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {sum} from 'lodash';
import {getNumLastDays} from 'insightsPanelNewAlertConsole/store/selectors';
import {segmentClickEvent} from 'common/store/actions';
import {fadeOpacity} from 'insightsPanelNewAlertConsole/services/constants';
import fetchTriggeredAlertsCountByProvider from 'insightsPanelNewAlertConsole/api/fetchTriggeredAlertsCountByProvider';
import {getConstRangeByNumberOfLastDays} from 'insightsPanelNewAlertConsole/api/utils';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS, SEVERITY} from 'alerts.console.new/services/alertsConsoleService';

import './OpenAlerts.module.scss';

const OpenAlerts = ({title, onFilterAlerts}: {title: String, onFilterAlerts: Function}) => {
  const [showData, setShowData] = useState(false);
  const numLastDays = useSelector(getNumLastDays);
  const dispatch = useDispatch();

  const constRange = useMemo(() => {
    return getConstRangeByNumberOfLastDays(numLastDays?.value)?.constRange;
  }, [numLastDays?.value]);

  const openAlertsProvider = fetchTriggeredAlertsCountByProvider({
    constRange,
    isOpen: true,
  }).useQuery();
  const openAlertsData = openAlertsProvider?.data?.resultBySeverity;
  const isLoading = openAlertsProvider?.isLoading;

  const [sumOpenAlerts, setSumOpenAlerts] = useState(0);

  useEffect(() => {
    if (openAlertsData) {
      setShowData(true);
    }
  }, [openAlertsData]);

  useEffect(() => {
    if (openAlertsData) {
      setSumOpenAlerts(sum(Object.values(openAlertsData)));
    }
  }, [JSON.stringify(openAlertsData), constRange]);

  const segmentTracking = (name) => {
    dispatch(
      segmentClickEvent({
        category: 'Insights Panel',
        name,
      }),
    );
  };

  const clickOnStatus = () => {
    onFilterAlerts({[ALERT_CONSOLE_QUERY_PARAMS_KEYS.IS_OPEN]: true});
    segmentTracking('Open Alerts Widget - Open Alerts ');
  };

  const clickOnSeverity = () => {
    onFilterAlerts({
      [ALERT_CONSOLE_QUERY_PARAMS_KEYS.IS_OPEN]: true,
      [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITIES]: [SEVERITY.CRITICAL.value],
    });
    segmentTracking('Open Alerts Widget - Critical Alerts');
  };

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && openAlertsData && (
        <Transition in={showData} timeout={fadeOpacity.duration}>
          {(state) => (
            <>
              <header
                styleName="header"
                style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state], marginBottom: '15px'}}
              >
                {title}
              </header>
              <div
                styleName="counters-container"
                style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}
              >
                {sumOpenAlerts === 0 ? (
                  <div styleName="no-open-alerts">
                    <span>No Open Alerts</span>
                  </div>
                ) : (
                  <>
                    <SmartTooltip content="Show Open Alerts" delay={300}>
                      <div styleName="counter-wrapper" onClick={clickOnStatus}>
                        <span styleName="number">{sumOpenAlerts}</span>
                        <span styleName="text">
                          Open
                          <br />
                          Alerts
                        </span>
                      </div>
                    </SmartTooltip>
                    <SmartTooltip content="Show Critical Alerts" delay={300}>
                      <div styleName="counter-wrapper red" onClick={clickOnSeverity}>
                        <span styleName="number">{openAlertsData?.Critical}</span>
                        <br />
                        <span styleName="text">
                          Critical
                          <br />
                          Alerts
                        </span>
                      </div>
                    </SmartTooltip>
                  </>
                )}
              </div>
            </>
          )}
        </Transition>
      )}
    </div>
  );
};

export default OpenAlerts;
