import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {isEmpty} from 'lodash';
import {
  createAlertsReducer,
  createEventsReducer,
  createMetricsReducer,
  createMultipleEventsReducer,
} from './consoleGroupReducerFactory';
import {totalTriggeredAlerts, totalTriggeredAlertsLastDay} from './totalTriggeredAlerts';
import * as actions from '../actions';

const initialState = {};

const red = (item, payload) => ({...item, ...payload});

const addAckToAlert = (groups, payload) =>
  groups.map((alertGroup) => {
    if (alertGroup.id === payload.groupId) {
      return {
        ...alertGroup,
        alerts: alertGroup.alerts.map((alert) => {
          if (alert.id === payload.alertId) {
            return {
              ...alert,
              stars: [...alert.stars, payload.meId],
            };
          }
          return alert;
        }),
      };
    }
    return alertGroup;
  });

const removeAckToAlert = (alertsGroups, payload) =>
  alertsGroups.map((alertGroup) => {
    if (alertGroup.id === payload.groupId) {
      return {
        ...alertGroup,
        alerts: alertGroup.alerts.map((alert) => {
          if (alert.id === payload.alertId) {
            return {
              ...alert,
              stars: alert.stars.filter((id) => id !== payload.meId),
            };
          }
          return alert;
        }),
      };
    }
    return alertGroup;
  });

const setStarAndMoveGroup = (payload, state) => {
  const currentList = payload.uiGroupName === 'ack' ? 'ackAlerts' : 'noAckAlerts';
  const otherList = payload.uiGroupName === 'ack' ? 'noAckAlerts' : 'ackAlerts';
  if (!payload.groupId) {
    const alertGroupeId = payload.alertId
      ? state[currentList].alertGroups.find((group) => group.alerts.find((alert) => alert.id === payload.alertId))?.id
      : null;
    // eslint-disable-next-line no-param-reassign
    payload.groupId = alertGroupeId;
  }

  let tmpAlertGroup = {};

  if (payload.isStarred) {
    // eslint-disable-next-line no-param-reassign
    state[currentList].alertGroups = addAckToAlert(state[currentList].alertGroups, payload);
  } else {
    // eslint-disable-next-line no-param-reassign
    state[currentList].alertGroups = removeAckToAlert(state[currentList].alertGroups, payload);
  }

  const alertGroupsFiltered = state[currentList].alertGroups.filter((alertGroup) => {
    if (alertGroup.id === payload.groupId) {
      if (payload.uiGroupName === 'ack') {
        const atListOne = alertGroup.alerts.some((alert) => alert.stars.includes(payload.meId));

        if (atListOne) {
          return true;
        }
        tmpAlertGroup = alertGroup;
        return false;
      }
      const allWithoutStars = alertGroup.alerts.every((alert) => !alert.stars.includes(payload.meId));
      if (allWithoutStars) {
        return true;
      }
      tmpAlertGroup = alertGroup;
      return false;
    }
    return true;
  });

  let alertGroupsAdded = [...state[otherList].alertGroups];

  if (!isEmpty(tmpAlertGroup)) {
    alertGroupsAdded = [tmpAlertGroup, ...state[otherList].alertGroups];
  }

  return {
    ...state,

    ackAlerts:
      payload.uiGroupName === 'ack'
        ? {
            ...state.ackAlerts,
            alertGroups: alertGroupsFiltered,
          }
        : {
            ...state.ackAlerts,
            alertGroups: alertGroupsAdded,
          },

    noAckAlerts:
      payload.uiGroupName === 'ack'
        ? {
            ...state.noAckAlerts,
            alertGroups: alertGroupsAdded,
          }
        : {
            ...state.noAckAlerts,
            alertGroups: alertGroupsFiltered,
          },
  };
};

const addFeedbackReason = (alertsGroups, payload) =>
  alertsGroups.map((alertGroup) => {
    const alerts = alertGroup.alerts.map((alert) => {
      if (payload.triggeredId !== alert.id) {
        return alert;
      }
      const updatedFeedback = alert.feedback.map((f) => {
        if (f.id !== payload.id) {
          return f;
        }
        if (f.id === payload.id) {
          return {
            ...f,
            reason: payload.reason ? payload.reason : undefined,
            comment: payload.comment ? payload.comment : undefined,
          };
        }
        return f;
      });

      return {
        ...alert,
        feedback: updatedFeedback,
      };
    });
    return {
      ...alertGroup,
      alerts,
    };
  });

const addFeedbackId = (alertsGroups, payload, triggeredId) =>
  alertsGroups.map((alertGroup) => {
    const alerts = alertGroup.alerts.map((alert) => {
      if (triggeredId !== alert.id) {
        return alert;
      }
      const updatedFeedback = alert.feedback.map((f) => {
        if (f.userId !== payload.meta.userId) {
          return f;
        }
        if (f.userId === payload.meta.userId) {
          return {...f, id: payload.id};
        }
        return f;
      });

      return {
        ...alert,
        feedback: updatedFeedback,
      };
    });
    return {
      ...alertGroup,
      alerts,
    };
  });

const setFeedback = (alertsGroups, payload) =>
  alertsGroups.map((alertGroup) => {
    const alerts = alertGroup.alerts.map((alert) => {
      if (payload.triggeredId !== alert.id) {
        return alert;
      }
      const feedbackFound = alert.feedback.find((f) => f.userId === payload.userId);

      if (!feedbackFound) {
        // no feedback by me given
        return {
          ...alert,
          feedback: [...alert.feedback, payload],
        };
      }

      return alert;
    });
    return {
      ...alertGroup,
      alerts,
    };
  });

const deleteFeedback = (alertsGroups, payload) =>
  alertsGroups.map((alertGroup) => {
    const alerts = alertGroup.alerts.map((alert) => {
      if (payload.triggeredId !== alert.id) {
        return alert;
      }
      const feedbackFound = alert.feedback.find((f) => f.userId === payload.userId);
      if (feedbackFound) {
        // different feedback by me exist, need to delete it and set the new feedback
        const feedbackListWithoutMyFeedback = alert.feedback.filter((f) => f.userId !== payload.userId);
        if (feedbackFound.type !== payload.type) {
          return {
            ...alert,
            feedback: [...feedbackListWithoutMyFeedback, payload],
          };
        }
        if (feedbackFound.type === payload.type) {
          return {
            ...alert,
            feedback: feedbackListWithoutMyFeedback,
          };
        }
      }
      return alert;
    });
    return {
      ...alertGroup,
      alerts,
    };
  });

const setMarkAsRead = (alertsGroups, payload) =>
  alertsGroups.map((alertGroup) => {
    const alerts = alertGroup.alerts.map((alert) => {
      if (payload.triggeredId !== alert.id) {
        return alert;
      }
      return {
        ...alert,
        reads: [...alert.reads, payload.userId],
      };
    });
    return {
      ...alertGroup,
      alerts,
    };
  });

const setAssignee = (alertsGroups, payload) =>
  alertsGroups.map((alertGroup) => {
    const alerts = alertGroup.alerts.map((alert) => {
      if (payload.triggerId !== alert.id) {
        return alert;
      }
      return {
        ...alert,
        assignee: payload.assignee || null,
      };
    });
    return {
      ...alertGroup,
      alerts,
    };
  });

export default composeReducers(
  (state = initialState, {type, meta, payload}) => {
    switch (type) {
      case actions.fetchAlerts.TYPE: {
        return {...state, noAckMetrics: {}, ackMetrics: {}};
      }

      case actions.setStar.TYPE: {
        return setStarAndMoveGroup(payload, state);
      }

      case actions.setMarkAsRead.TYPE: {
        const ackAlertsGroups = state.ackAlerts.alertGroups;
        const noAckAlertsGroups = state.noAckAlerts.alertGroups;
        const updatedAckAlertsGroups = setMarkAsRead(ackAlertsGroups, payload);
        const updatedNoAckAlertsGroups = setMarkAsRead(noAckAlertsGroups, payload);

        return {
          ...state,
          ackAlerts: {
            ...state.ackAlerts,
            alertGroups: updatedAckAlertsGroups,
          },
          noAckAlerts: {
            ...state.noAckAlerts,
            alertGroups: updatedNoAckAlertsGroups,
          },
        };
      }

      case actions.setAssignee.TYPE:
      case actions.deleteAssignee.TYPE: {
        const ackAlertsGroups = state.ackAlerts.alertGroups;
        const noAckAlertsGroups = state.noAckAlerts.alertGroups;
        const updatedAckAlertsGroups = setAssignee(ackAlertsGroups, payload);
        const updatedNoAckAlertsGroups = setAssignee(noAckAlertsGroups, payload);

        return {
          ...state,
          ackAlerts: {
            ...state.ackAlerts,
            alertGroups: updatedAckAlertsGroups,
          },
          noAckAlerts: {
            ...state.noAckAlerts,
            alertGroups: updatedNoAckAlertsGroups,
          },
        };
      }
      case actions.setFeedback.TYPE: {
        const ackAlertsGroups = state.ackAlerts.alertGroups;
        const noAckAlertsGroups = state.noAckAlerts.alertGroups;
        const updatedAckAlertsGroups = setFeedback(ackAlertsGroups, payload);
        const updatedNoAckAlertsGroups = setFeedback(noAckAlertsGroups, payload);

        return {
          ...state,
          ackAlerts: {
            ...state.ackAlerts,
            alertGroups: updatedAckAlertsGroups,
          },
          noAckAlerts: {
            ...state.noAckAlerts,
            alertGroups: updatedNoAckAlertsGroups,
          },
        };
      }

      case actions.setFeedbackRequest.success.TYPE: {
        const ackAlertsGroups = state.ackAlerts.alertGroups;
        const noAckAlertsGroups = state.noAckAlerts.alertGroups;
        const updatedAckAlertsGroups = addFeedbackId(ackAlertsGroups, payload, meta.alertId);
        const updatedNoAckAlertsGroups = addFeedbackId(noAckAlertsGroups, payload, meta.alertId);

        return {
          ...state,
          ackAlerts: {
            ...state.ackAlerts,
            alertGroups: updatedAckAlertsGroups,
          },
          noAckAlerts: {
            ...state.noAckAlerts,
            alertGroups: updatedNoAckAlertsGroups,
          },
        };
      }

      case actions.updateFeedback.TYPE: {
        const ackAlertsGroups = state.ackAlerts.alertGroups;
        const noAckAlertsGroups = state.noAckAlerts.alertGroups;
        const updatedAckAlertsGroups = addFeedbackReason(ackAlertsGroups, payload);
        const updatedNoAckAlertsGroups = addFeedbackReason(noAckAlertsGroups, payload);

        return {
          ...state,
          ackAlerts: {
            ...state.ackAlerts,
            alertGroups: updatedAckAlertsGroups,
          },
          noAckAlerts: {
            ...state.noAckAlerts,
            alertGroups: updatedNoAckAlertsGroups,
          },
        };
      }

      case actions.deleteFeedback.TYPE: {
        const ackAlertsGroups = state.ackAlerts.alertGroups;
        const noAckAlertsGroups = state.noAckAlerts.alertGroups;
        const updatedAckAlertsGroups = deleteFeedback(ackAlertsGroups, payload);
        const updatedNoAckAlertsGroups = deleteFeedback(noAckAlertsGroups, payload);

        return {
          ...state,
          ackAlerts: {
            ...state.ackAlerts,
            alertGroups: updatedAckAlertsGroups,
          },
          noAckAlerts: {
            ...state.noAckAlerts,
            alertGroups: updatedNoAckAlertsGroups,
          },
        };
      }
      default:
        return state;
    }
  },
  {
    setFeedbackRequest: composeReducers(
      makeAsyncReducer(actions.setFeedbackRequest, {
        includeUpdateAt: true,
        shouldDestroyData: false,
      }),
    ),
  },
  {
    deleteFeedbackRequest: composeReducers(
      makeAsyncReducer(actions.deleteFeedbackRequest, {
        includeUpdateAt: true,
        shouldDestroyData: false,
      }),
    ),
  },
  {
    updateFeedbackRequest: composeReducers(
      makeAsyncReducer(actions.updateFeedbackRequest, {
        includeUpdateAt: true,
        shouldDestroyData: false,
      }),
    ),
  },

  {
    snoozeData: combineReducers({
      triggeredAlert: composeReducers(
        makeAsyncReducer(actions.fetchTriggeredAlertForSnooze, {
          defaultData: {},
          dataProp: 'data',
        }),
        (state, {type, payload}) => {
          switch (type) {
            case actions.setSnoozeIndicationForMetric.TYPE:
              return {
                ...state,
                data: {
                  ...state.data,
                  metrics: payload.isAll
                    ? state.data.metrics.map((m) => ({...m, snooze: payload.isSnoozed ? {} : null}))
                    : reduceArrayItem(
                        red,
                        state.data.metrics,
                        state.data.metrics.findIndex((m) => m.id === payload.metricId),
                        {
                          snooze: payload.isSnoozed ? {} : null,
                        },
                      ),
                },
              };

            case actions.setSTLIndicationForMetric.TYPE:
              return {
                ...state,
                data: {
                  ...state.data,
                  metrics: payload.isAll
                    ? state.data.metrics.map((m) => ({...m, stopLearning: payload.isStopLearning ? {} : null}))
                    : reduceArrayItem(
                        red,
                        state.data.metrics,
                        state.data.metrics.findIndex((m) => m.id === payload.metricId),
                        {
                          stopLearning: payload.isStopLearning ? {} : null,
                        },
                      ),
                },
              };

            case actions.resetSnoozeModalState.TYPE: {
              return {...state, data: {}};
            }
            default:
              return state;
          }
        },
      ),
    }),
  },
  {
    ackAlerts: createAlertsReducer(actions.fetchTriggeredAlertsAcknowledged, 'ack'),
  },
  {
    ackEvents: createEventsReducer('ack'),
  },
  {
    ackEvents: createMultipleEventsReducer('ack'),
  },
  {
    ackMetrics: createMetricsReducer('ack'),
  },
  {
    noAckAlerts: createAlertsReducer(actions.fetchTriggeredAlertsNotAcknowledged, 'noAck'),
  },
  {
    noAckEvents: createEventsReducer('noAck'),
  },
  {
    noAckEvents: createMultipleEventsReducer('noAck'),
  },
  {
    noAckMetrics: createMetricsReducer('noAck'),
  },
  {
    totalTriggeredAlerts,
  },
  {
    totalTriggeredAlertsLastDay,
  },
);
