import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {getFormattedHumanDuration} from 'common/utils/dateService';
import {getTimeZoneName, getTriageFullScreenEnabled} from 'profile/store/selectors';
import {Severity} from 'investigationNewAlertConsole/components/tabs/Badges';
import {palette} from 'app/styles/theme';
import {ALERT_TYPES, ALERT_TYPES_LABELS} from 'investigationNewAlertConsole/services/investigationService';
import ImpactChip from 'impact/ImpactChip';
import IncidentDateTimeProperties from './IncidentDateTimeProperties';

import './IncidentTab.module.scss';

const IncidentDetails = ({isTriggerLoading, trigger}: {isTriggerLoading: Boolean, trigger: Object}) => {
  const timeZoneName = useSelector(getTimeZoneName);
  const isTriageFullscreenEnabled = useSelector(getTriageFullScreenEnabled);

  const isOpen = trigger?.status.toLowerCase() === 'open';
  const durText = trigger && getFormattedHumanDuration(trigger?.duration).trim();
  const fullDurText = isOpen ? `for ${durText}` : `lasted ${durText}`;
  const statusText = isOpen ? 'Open' : 'Closed,';

  const getMinLastSeenTime = (metrics) => {
    const lastSeenTimes = metrics
      ?.flatMap((metric) => metric.intervals)
      ?.filter((interval) => interval.lastSeenMachineTimeEpoch)
      .map((interval) => interval.lastSeenMachineTimeEpoch);
    return Math.min(...lastSeenTimes);
  };

  return (
    <>
      {!isTriggerLoading && trigger && (
        <div styleName="header-container">
          <div styleName="badges">
            {isTriageFullscreenEnabled ? <Severity level={trigger?.severity} /> : null}

            <div styleName="badge">{ALERT_TYPES_LABELS[(trigger?.type)]}</div>

            {trigger.type === ALERT_TYPES.ANOMALY && (
              <div styleName="badge">{`Score: ${Math.round(trigger?.score * 100)}`}</div>
            )}
          </div>

          <div styleName="status-container">
            <div styleName="info-label">Status:</div>
            <div styleName="alert-status-container">
              <div style={{color: isOpen ? palette.red['500'] : palette.gray['500']}}>{statusText}</div>
              <div styleName="info-value">{fullDurText}</div>
            </div>
          </div>

          {trigger.type === ALERT_TYPES.ANOMALY && (
            <IncidentDateTimeProperties
              label="Anomaly Started:"
              startTime={trigger?.startTime}
              triggerTime={trigger?.triggerTime || trigger?.startTime}
              timeZoneName={timeZoneName}
            />
          )}

          {trigger.type === ALERT_TYPES.STATIC && (
            <IncidentDateTimeProperties
              label="Breach Started:"
              startTime={trigger?.startTime}
              triggerTime={trigger?.triggerTime || trigger?.startTime}
              timeZoneName={timeZoneName}
            />
          )}

          {trigger.type === ALERT_TYPES.NO_DATA && (
            <IncidentDateTimeProperties
              label="Data missing since:"
              startTime={getMinLastSeenTime(trigger?.metrics)}
              triggerTime={trigger?.triggerTime || trigger?.startTime}
              timeZoneName={timeZoneName}
            />
          )}
          {trigger.type === ALERT_TYPES.ANOMALY && trigger?.impact && (
            <ImpactChip impact={trigger?.impact} tooltipContent="Total Impact" />
          )}
        </div>
      )}
    </>
  );
};

export default memo(IncidentDetails);
