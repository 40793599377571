// @flow
import React, {Component} from 'react';
import {get, last} from 'lodash';
import * as actions from 'alerts.console/store/actions';
import * as commonActions from 'common/store/actions';
import * as selectors from 'alerts.console/store/selectors';
import {getProfileId, getTimeZoneName, getAlertAssigneeEnabled} from 'profile/store/selectors';
import {connect} from 'react-redux';
import * as metricsSelectors from 'metrics/store/selectors';
import SeverityMarker from 'common/componentsV2/SeverityMarker';
import AlertDate from './alertContent/AlertDate';
import AlertStatusIcon from './alertContent/AlertStatusIcon';
import AlertDuration from './alertContent/AlertDuration';
import AlertScore from './alertContent/AlertScore';
import AlertTitle from './alertContent/AlertTitle';
import GraphContent from './alertContent/GraphContent';
import AlertActions from './alertContent/AlertActions';
import AlertAssignee from './alertContent/AlertAssignee';

import './AlertListItem.module.scss';

const EMPTY_OBJECT = {};
const ROW_DEFAULT_HEIGHT = 49;

type PropTypes = {
  alert: Object,
  index: Number,
  uiGroupName: string,
  // connect
  meId: Object,
  openItems: Array<string>,
  timeZoneName: string,
  resolutions: Object,
  setSelectedTriggeredAlert: Function,
  id: String,
  queryParamsViews: Object,
  segmentClickEvent: Function,
  isAlertsListScrolling: Boolean,
  setMarkAsRead: Function,
  isAlertAssigneeEnabled: Boolean,
};

export default connect(
  (state) => ({
    meId: getProfileId(state),
    openItems: selectors.getAlertConsoleOpenItems(state),
    timeZoneName: getTimeZoneName(state),
    resolutions: metricsSelectors.getMetricResolutions(state),
    queryParamsViews: selectors.getQueryParamsViews(state),
    isAlertAssigneeEnabled: getAlertAssigneeEnabled(state),
  }),
  {
    setSelectedTriggeredAlert: actions.setSelectedTriggeredAlert,
    setMarkAsRead: actions.setMarkAsRead,
    segmentClickEvent: commonActions.segmentClickEvent,
  },
)(
  class AlertListItem extends Component {
    props: PropTypes;

    state = {
      rowOpen: false,
      containerHeight: ROW_DEFAULT_HEIGHT,
      rowAnimation: 0,
      overflow: 'unset',
      opacityContent: 1,
      onMouseOver: false,
    };

    componentDidMount() {
      // if the alert wes loaded from outside and from advanced we open the alert automatically.
      const {openItems, alert} = this.props;

      if (openItems.length === 1 && openItems[0] === alert.id) {
        this.openRow(false);
      }
    }

    alertStyle = [];

    isMarkAsReadExist() {
      return this.props.alert.reads.find((userId) => userId === this.props.meId);
    }

    addMarkAsRead() {
      const {setMarkAsRead, meId, alert} = this.props;
      const found = this.isMarkAsReadExist();

      if (found) {
        return;
      }
      setMarkAsRead({triggeredId: alert.id, userId: meId});
    }

    closeRow() {
      this.setState({opacityContent: 0});

      setTimeout(() => {
        this.props.setSelectedTriggeredAlert(this.props.alert.id);
        this.setState((prevState) => ({
          rowOpen: false,
          overflow: 'unset',
          containerHeight: ROW_DEFAULT_HEIGHT,
          rowAnimation: prevState.rowAnimation / 1.5,
        }));
      }, 200);
    }

    openRow(alertClicked) {
      const {alert} = this.props;
      if (alertClicked) {
        // we checked if the alert was triggered from outside (for example: came from Email),
        // the Store already have the triggeredId.
        this.props.setSelectedTriggeredAlert(alert.id);
      }
      if (alert.reads) {
        this.addMarkAsRead();
      }

      this.setState({rowOpen: true});
      this.setState({opacityContent: 1});
    }

    alertClicked = (e) => {
      const {segmentClickEvent} = this.props;
      if (!e.target.closest('.alert-clickable-item')) {
        if (!this.state.rowOpen) {
          this.openRow(true);
          segmentClickEvent({type: 'click', name: 'alert-open'});
        } else {
          this.closeRow();
          segmentClickEvent({type: 'click', name: 'alert-close'});
        }
      }
    };

    changeChartWrapperStyle = (alertContentHeight = 0) => {
      this.setState({overflow: 'hidden'});
      this.setState({containerHeight: alertContentHeight + ROW_DEFAULT_HEIGHT});
      // eslint-disable-next-line no-restricted-properties
      this.setState({rowAnimation: Math.pow(alertContentHeight, 0.3) * 0.05});
    };

    maxScore = () => {
      const {alert} = this.props;
      let maxScore = 0;
      if (alert.type !== 'anomaly') {
        return null;
      }
      alert.metrics.forEach((m) => {
        const intervals = get(m, 'intervals', []);
        if (intervals?.length) {
          intervals.forEach((interval) => {
            if (interval?.score && interval.score > maxScore) {
              maxScore = interval.score;
            }
          });
        }
      });
      return Math.round(maxScore * 100);
    };

    render() {
      const {
        alert,
        index,
        uiGroupName,
        resolutions,
        timeZoneName,
        queryParamsViews,
        meId,
        isAlertsListScrolling,
        isAlertAssigneeEnabled,
      } = this.props;

      const {overflow, containerHeight, rowAnimation, rowOpen, onMouseOver} = this.state;

      const markAsRead = this.props.alert.reads && this.isMarkAsReadExist();

      const firstMetric = get(alert, 'metrics[0]', EMPTY_OBJECT);
      const lastInterval = last(get(firstMetric, 'intervals', [])) || EMPTY_OBJECT;

      const maxScore = this.maxScore();

      const style = {
        container: {
          overflow,
          height: containerHeight,
          transition: [`height ${rowAnimation}s ease-in-out`],
        },
      };

      this.alertStyle = [
        'row-wrapper',
        rowOpen ? 'open' : '',
        markAsRead ? 'markAsRead' : '',
        isAlertAssigneeEnabled ? '' : 'isNotAlertAssigneeEnabled',
      ];

      return (
        <div styleName="container" style={style.container}>
          {index !== 0 ? <div styleName="group-sep" className="group-sep-global" /> : null}
          <div
            style={{height: ROW_DEFAULT_HEIGHT}}
            styleName={this.alertStyle.join(' ')}
            className="row-wrapper-global"
            key={alert.id}
            onClick={this.alertClicked}
            onMouseOver={() => this.setState({onMouseOver: true})}
            onFocus={() => this.setState({onMouseOver: true})}
            onMouseLeave={() => this.setState({onMouseOver: false})}
            role="button"
          >
            <div style={{opacity: `${queryParamsViews.severityView ? 'inherit' : '0'}`}}>
              <SeverityMarker severity={alert.severity} isLargeMarker />
            </div>
            <AlertStatusIcon status={alert.status} />
            <AlertTitle
              alert={alert}
              searchWords={queryParamsViews.searchQuery}
              meId={meId}
              onMouseOver={onMouseOver}
              isRowOpen={rowOpen}
              isAlertsListScrolling={isAlertsListScrolling}
            />
            <AlertAssignee
              userId={alert.assignee}
              isDisplay={onMouseOver}
              triggerId={alert.id}
              alertConfigurationId={alert.alertConfigurationId}
            />
            <AlertDate startDate={alert.startTime} timeZone={timeZoneName} />
            <AlertDate startDate={alert.updateTime} timeZone={timeZoneName} />
            <AlertDuration duration={alert.duration} />
            <AlertScore value={maxScore} />
            <AlertScore value={alert.summary.totalMetrics} />
            <AlertActions
              alert={alert}
              lastInterval={lastInterval}
              resolutions={resolutions}
              uiGroupName={uiGroupName}
              anomalyId={lastInterval.anomalyId}
              timeScale={Object.values(resolutions).find((a) => a.value2 === alert.timeScale).value}
              isDisplay={onMouseOver}
            />
          </div>
          <GraphContent
            open={rowOpen}
            opacity={this.state.opacityContent}
            alert={alert}
            uiGroupName={uiGroupName}
            onChangeChartWrapperStyle={this.changeChartWrapperStyle}
            index={index}
          />
        </div>
      );
    }
  },
);
