// @flow
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {StringParam, useQueryParam} from 'use-query-params';

import SelectAndt, {TYPE_NEW_MULTI, THEME_HIGHLIGHTED} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';

import {getGroups} from 'admin.permissions/store/selectors';
import {fetchGroupsIsLoading} from 'admin.users/store/selectors';

const AccessGroupFilter = () => {
  const groups = useSelector(getGroups);
  const groupsIsLoading = useSelector(fetchGroupsIsLoading);
  const [accessGroups, setSccessGroups] = useQueryParam('accessGroups', StringParam);
  const selectedAccessGroups = accessGroups
    ? groups.filter((i) => accessGroups.split(',').find((j) => i.value === j))
    : [];

  const onChange = useCallback(
    (values) => {
      setSccessGroups(values.length ? values.map((i) => i.value).join(',') : undefined);
    },
    [setSccessGroups],
  );

  const onClearAll = useCallback(() => {
    if (selectedAccessGroups && selectedAccessGroups.length) {
      setSccessGroups(undefined);
    } else {
      const selectedOption = groups.map((i) => i.value).join(',');
      setSccessGroups(selectedOption);
    }
  }, [groups, selectedAccessGroups]);

  return (
    <div>
      <SelectAndt
        noAutoReorder
        type={TYPE_NEW_MULTI}
        theme={THEME_HIGHLIGHTED}
        isClearable={false}
        closeMenuOnSelect={false}
        onChange={onChange}
        options={groups}
        buttonWidth={190}
        optionHeight={40}
        menuWidth={240}
        isMulti
        value={selectedAccessGroups}
        placeholder={groupsIsLoading ? 'Loading..' : 'None'}
        automationId="accessGroupsFilter"
        menuFooterComponent={
          <FormDdlActionBar isVisible selectedCount={selectedAccessGroups.length} onClearAll={onClearAll} />
        }
      />
    </div>
  );
};

export default AccessGroupFilter;
