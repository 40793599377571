// @flow
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {AutoSizer, List} from 'react-virtualized';

import {computeDecimal} from 'common/utils/numbers';
import {useField} from 'react-final-form';
import {useSelector} from 'react-redux';
import Highcharts from 'highcharts';
import {get, sortBy} from 'lodash';
import {Box, useTheme} from '@material-ui/core';
import {TinyScrollDiv} from 'common/componentsV2/boxTools/TinyScrollBox';
import Checkbox from 'common/componentsV2/Checkbox';
import {generateChartSeriesMetricModel, processSeriesData} from 'charts/timeSeries/services/timeSeriesDataService';
import {
  addPlotLine,
  endLoad,
  getHSeries,
  pushSeries,
  removePlotLine,
  setBaseLineVisibilityForSeries,
} from 'charts/timeSeries/services/timeSeriesHchartService';
import TimeSeriesChart from 'charts/timeSeries/components/TimeSeriesChart';
import {resolutionTypes} from 'metrics/services/metricsService';
import * as commonSelectors from 'profile/store/selectors';
import {ReactComponent as IconLoader} from 'dashboards/images/anoloader.svg';
import ResizableBox from 'dashboards/components/ResizableBox';
import {getGraphTilePlotLineConfig} from 'charts/timeSeries/services/timeSeriesHchartSettingsService';
import {COLORS_PICKER} from 'common/componentsV2/ColorPicker';
import formatNumber from 'common/utils/formatNumber';
import usePrevious from 'common/utils/usePrevious';
import {getIsRbacEnabled} from 'admin.permissions/store/selectors';
import {palette} from 'app/styles/theme';
import LegendRow from './components/LegendRow';
import {getLegendValues, overscanIndicesGetter} from './utils';
import UnauthorizedChart from './UnauthorizedChart';

const EMPTY_ARRAY = [];

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: true,
};

type PropTypes = {
  metrics: Array,
  isLoading: boolean,
  isZoomLoading: boolean,
  timeScale: string,
  chartId: string,
  legendSize: Object,
  onMouseMove: Function,
  isTile: boolean,
  isLegendEnabled: boolean,
  isResize: boolean,
  setResize: Function,
  dateRange: Object,
  isLegendRight: boolean,
  isFullSize: boolean,
  byTreeExp: Array,
  isExtendedTooltip: boolean,
  staticLine: Object,
  tileData: Object,
  onChartResize: Function,
  dashboardId: String,
  compositeErrorsMap: Object,
};

const makeLegendSeries = (h) => {
  const series = h.series
    .filter((item) => item.options.andtVal.type === 'average')
    .map((item) => ({
      options: {
        andtVal: item.options.andtVal,
      },
      name: item.name,
      visible: item.visible,
      color: item.color,
      hasPoints: !!get(item, 'options.data.length', 0),
    }));
  return sortBy(series, function(s) {
    return s.options.andtVal.metric.uiIndex;
  });
};

const setSeriesVisibility = (h, series, index, isShow) => {
  const hseries = getHSeries(h, series[index].options.andtVal.metric, series[index].options.andtVal.type);
  const hseriesBaseline = getHSeries(h, series[index].options.andtVal.metric, 'average_baseline');
  const hseriesAnomalyPrimary = getHSeries(h, series[index].options.andtVal.metric, 'anomalyPrimary');
  const hseriesAnomalySecondary = getHSeries(h, series[index].options.andtVal.metric, 'anomalySecondary');

  if (isShow) {
    hseries.setVisible(true, false);
    if (hseriesAnomalyPrimary) {
      hseriesAnomalyPrimary.setVisible(true, false);
    }
    if (hseriesAnomalySecondary) {
      hseriesAnomalySecondary.setVisible(true, false);
    }
  } else {
    hseries.setVisible(false, false);
    if (hseriesBaseline) {
      hseriesBaseline.setVisible(false, false);
    }
    if (hseriesAnomalyPrimary) {
      hseriesAnomalyPrimary.setVisible(false, false);
    }
    if (hseriesAnomalySecondary) {
      hseriesAnomalySecondary.setVisible(false, false);
    }
  }

  setBaseLineVisibilityForSeries(h);
  h.redraw();
};

const MetricsList = ({
  metrics,
  timeScale,
  isLoading,
  // eslint-disable-next-line no-unused-vars
  isZoomLoading,
  chartId,
  legendSize,
  onMouseMove,
  isTile = false,
  isLegendEnabled,
  isResize = false,
  setResize,
  dateRange,
  isLegendRight,
  onChartResize,
  isFullSize,
  byTreeExp,
  isExtendedTooltip,
  staticLine,
  tileData,
  dashboardId = null,
  compositeErrorsMap,
}: PropTypes) => {
  const [hchart, setHighchartInstance] = useState(null);
  const legendSeries = useRef([]);
  const activeRow = useRef();
  const legendRefs = useRef([]);
  const rowRefs = useRef([]);
  const [hiddenSeriesMap, updateHiddenSeriesMap] = useState({});
  const list = useRef();
  const theme = useTheme();

  useEffect(() => {
    setHighchartInstance(Highcharts.charts.find((chart) => chart && chart.renderTo.id === chartId));
  }, [isLoading, isLegendEnabled]);

  const toggleVisibility = useCallback(
    (index) => {
      const isVisible = !hiddenSeriesMap[index];
      if (isVisible) {
        setSeriesVisibility(hchart, legendSeries.current, index, false);
      } else {
        setSeriesVisibility(hchart, legendSeries.current, index, true);
      }
      const updatedHiddenSeries = {...hiddenSeriesMap, [index]: isVisible};
      updateHiddenSeriesMap(updatedHiddenSeries);
    },
    [hchart, hiddenSeriesMap],
  );

  const toggleVisibilityAll = useCallback(() => {
    const isVisible = !Object.values(hiddenSeriesMap).some((item) => item === true);
    legendSeries.current.forEach((item, index, source) => {
      if (isVisible) {
        setSeriesVisibility(hchart, source, index, false);
      } else {
        setSeriesVisibility(hchart, source, index, true);
      }
    });

    metrics.forEach((item, index) => {
      updateHiddenSeriesMap((prevMap) => ({...prevMap, [index]: isVisible}));
    });
  }, [hchart, hiddenSeriesMap]);

  const {
    input: {onChange: onChangeDateRange},
  } = useField('dateRange');

  const {
    input: {onChange: onChangeIsAutoDateRange},
  } = useField('isAutoDateRange');

  const onRangeSelection = useCallback((data) => {
    onChangeIsAutoDateRange(false);
    onChangeDateRange({
      constRange: 'c',
      startDate: data.startDate * 1000,
      endDate: data.endDate * 1000,
    });
  });

  const bucketStartTimeEnabled = useSelector(commonSelectors.getBucketStartTimeEnabled);
  const timeZoneName = useSelector(commonSelectors.getTimeZoneName);
  const isUseRbac = useSelector(getIsRbacEnabled);

  const maxDataPoint = useMemo(() => {
    let max = 0;
    metrics.forEach((m) => {
      m.dataPoints.forEach(([dataPoint1]) => {
        if (dataPoint1 > max) {
          max = dataPoint1;
        }
      });
    });
    return max;
  }, [metrics]);

  const isTooMuchData = useMemo(() => {
    if (!compositeErrorsMap) return false;
    const failures = compositeErrorsMap[Object.keys(compositeErrorsMap)]?.failures;
    return failures && failures[Object.keys(failures)[0]] && failures[Object.keys(failures)[0]][0]?.id === 53;
  }, [compositeErrorsMap]);

  useEffect(() => {
    updateHiddenSeriesMap({});
  }, [metrics]);

  const prevMetrics = usePrevious(metrics);

  useEffect(() => {
    const timer = setTimeout(() => {
      const fromDate = dateRange ? Math.ceil(dateRange.startDate / 1000) : null;
      const toDate = dateRange ? Math.ceil(dateRange.endDate / 1000) : null;

      if (hchart && hchart.series) {
        while (hchart.series.length) {
          hchart.series[0].remove();
        }
        metrics.forEach((metric, index) => {
          const metricsModel = generateChartSeriesMetricModel(metric, metric.treeId, index || 0);
          const processedSeriesData = processSeriesData(metric.dataPoints, metric.baseline, false, null);

          pushSeries(
            hchart,
            {byTreeExp},
            metricsModel,
            processedSeriesData,
            prevMetrics !== metrics ? true : !hiddenSeriesMap[index],
            null,
            null,
            tileData,
          );
        });
        if (staticLine) {
          const {enabled, value, title, color} = staticLine;
          if (get(hchart, 'yAxis[0].options.plotLines', EMPTY_ARRAY).length) {
            removePlotLine(hchart, chartId);
          }
          if (enabled && get(hchart, 'yAxis', EMPTY_ARRAY).length) {
            const plotConfig = getGraphTilePlotLineConfig();
            plotConfig.id = chartId;
            plotConfig.value = value;
            plotConfig.color = theme.palette[COLORS_PICKER[color].color][COLORS_PICKER[color].contrast];
            plotConfig.label.text = title;
            plotConfig.label.style = {
              ...plotConfig.label.style,
              backgroundColor: theme.palette[COLORS_PICKER[color].color][COLORS_PICKER[color].contrast],
            };
            addPlotLine(hchart, plotConfig);
          }
        }
        endLoad(hchart, fromDate, toDate, staticLine, maxDataPoint, isTooMuchData);
        legendSeries.current = makeLegendSeries(hchart);
      }
    }, 0);

    return () => {
      clearTimeout(timer);
    };
  }, [metrics, hchart, byTreeExp, staticLine]);

  const mouseMoveHandler = useCallback(
    (data, argId) => {
      if (onMouseMove) {
        onMouseMove(data, argId);
      }
      if (!data.isInsidePlot && activeRow.current) {
        activeRow.current.style.backgroundColor = 'transparent';
      }
      const plotX = hchart.xAxis[0]?.translate(data.xValue, 0, 0, 0, 1, undefined, false);
      const arr = getLegendValues(hchart, plotX);
      if (arr) {
        arr.forEach((item, index) => {
          if (legendRefs.current[index]) {
            legendRefs.current[index].innerHTML = formatNumber(
              parseFloat(item.y ? item.y.toFixed(computeDecimal(item.y)) : 0),
              3,
              ',',
              '.',
            );
          }
        });
      }
    },
    [hchart],
  );

  const onMetricHover = useCallback(
    (series) => {
      if (activeRow.current) {
        activeRow.current.style.backgroundColor = 'transparent';
      } else {
        legendRefs.current.forEach((itemValue) => {
          if (itemValue) {
            // eslint-disable-next-line no-param-reassign
            itemValue.style.display = 'block';
          }
        });
      }
      activeRow.current = rowRefs.current[get(series, 'options.andtVal.metric.uiIndex')];

      if (activeRow.current) {
        activeRow.current.style.backgroundColor = palette.blue['200'];
      }
    },
    [metrics],
  );

  const onMouseLeave = useCallback(() => {
    if (onMouseMove) {
      onMouseMove();
    }
    if (activeRow.current) {
      activeRow.current.style.backgroundColor = 'transparent';
      activeRow.current = null;
    }
    legendRefs.current.forEach((itemValue) => {
      if (itemValue) {
        // eslint-disable-next-line no-param-reassign
        itemValue.style.display = 'none';
      }
    });
  });

  const onChangeColor = useCallback(
    ({metricId, color}) => {
      if (hchart && hchart.series) {
        const index = hchart.series.findIndex((s) => get(s, 'userOptions.id', '').includes(metricId));
        hchart.series[index].update({
          color: theme.palette[COLORS_PICKER[color].color][COLORS_PICKER[color].contrast],
        });
      }
    },
    [hchart, metrics],
  );

  const highlightSeries = useCallback(
    (index) => {
      legendSeries.current.forEach((item) => {
        const hseries = getHSeries(hchart, item.options.andtVal.metric, item.options.andtVal.type);
        if (hseries) {
          hseries.setState(index !== undefined ? 'inactive' : 'hover');
        }
      });
      const hoveredSeries = legendSeries.current[index];
      if (hoveredSeries) {
        const hseries = getHSeries(hchart, hoveredSeries.options.andtVal.metric, hoveredSeries.options.andtVal.type);
        if (hseries) {
          hseries.setState('hover');
        }
      }
    },
    [hchart],
  );

  const Row = useCallback(
    ({index, key, style}) => (
      <LegendRow
        key={key}
        highlightSeries={highlightSeries}
        rowRefs={rowRefs}
        legendRefs={legendRefs}
        metrics={metrics}
        index={index}
        style={style}
        hiddenSeriesMap={hiddenSeriesMap}
        toggleVisibility={toggleVisibility}
        tileData={tileData}
        onChangeColor={onChangeColor}
        dashboardId={dashboardId}
      />
    ),
    [hchart, hiddenSeriesMap, metrics, tileData],
  );

  const onResizeHandler = useCallback(
    (size) => {
      onChartResize(isLegendRight ? size.w : size.h, isLegendRight);
      setResize((prevIsResize) => !prevIsResize);
    },
    [isLegendRight, onChartResize],
  );

  if (isLoading) {
    return (
      <div className="display_flex flexGrow_1 justifyContent_center alignItems_center">
        <IconLoader width="30%" height="90%" />
      </div>
    );
  }

  const chartElement = (
    <React.Fragment>
      {!metrics.length && isUseRbac && (tileData ? !tileData.isAuthorized : false) ? (
        <UnauthorizedChart isTooMuchData={isTooMuchData} />
      ) : (
        <TimeSeriesChart
          isExtendedTooltip={isExtendedTooltip}
          isInactiveMode
          onMouseLeave={onMouseLeave}
          onMetricHover={onMetricHover}
          disableActions
          id={chartId}
          eventsMeta={{
            key: chartId,
            chartId,
          }}
          bucketStartTimeEnabled={bucketStartTimeEnabled}
          timeScale={timeScale ? resolutionTypes[timeScale].value2 : ''}
          timeZoneName={timeZoneName}
          theme="preview"
          tooltipConditions={{}}
          onRangeSelection={onRangeSelection}
          tooltip={TOOLTIP_OPTIONS}
          onMouseMove={mouseMoveHandler}
          isResize={isResize}
        />
      )}
    </React.Fragment>
  );

  const legendElement =
    !isTile || isLegendEnabled ? (
      <React.Fragment>
        {!isTile && (
          <div
            className="fontSize_16 fontWeight_500 p_1 fontSize_16 fontWeight_500 p_1 mb_1 pl_0 display_flex lineHeight_1"
            style={{borderBottom: `1px solid ${palette.gray[300]}`}}
          >
            <Box
              className="mr_1 flexShrink_0"
              css={{
                '& input[type="checkbox"] + label::before': {
                  color: theme.palette.gray['500'],
                },
                '& input[type="checkbox"]:checked + label::before': {
                  color: theme.palette.gray['500'],
                },
              }}
            >
              <Checkbox
                isChecked={!Object.values(hiddenSeriesMap).some((item) => item === true)}
                onChange={toggleVisibilityAll}
              />
            </Box>
            {isLoading ? 'Loading...' : `Showing ${metrics.length} metrics`}
          </div>
        )}
        {metrics && (
          <div className="flexGrow_1 userSelect_none">
            <AutoSizer>
              {({height, width}) => (
                <TinyScrollDiv>
                  <List
                    ref={list}
                    overscanRowCount={5}
                    overscanIndicesGetter={overscanIndicesGetter}
                    width={width}
                    height={height}
                    rowCount={metrics.length}
                    rowHeight={28}
                    rowRenderer={Row}
                  />
                </TinyScrollDiv>
              )}
            </AutoSizer>
          </div>
        )}
      </React.Fragment>
    ) : null;

  if (isTile && isLegendEnabled && !isFullSize) {
    return (
      <ResizableBox
        size={legendSize}
        onResize={onResizeHandler}
        firstElement={chartElement}
        secondElement={legendElement}
        isHorizontal={isLegendRight}
      />
    );
  }

  return (
    <div className="width_1 display_flex flexDirection_column position_relative height_1 pr_1 pl_0-5">
      <div className="flexGrow_1 display_flex flexDirection_column height_1">
        <div
          className={`position_relative bgcolor_white-500 width_1 flexShrink_0 my_${isTile ? 0 : 2}`}
          style={{height: !legendElement ? '100%' : '300px'}}
          automation-id="metricExplorerGraphComponent"
          id={isTile ? '' : 'chartWrapper'}
          // Commented the stopPropagation as it seams that it does not needed anymore in React 17
          // for more info look at https://reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation
          // Keeping this causing chart zoom to not work as a result of not getting the mouse event.
          // onMouseDownCapture={(event) => event.stopPropagation()}
        >
          {chartElement}
        </div>
        {legendElement && (
          <Box
            automation-id="metricExplorerShowMetricsList"
            display="flex"
            flexDirection="column"
            width={1}
            flexGrow={1}
            css={{userSelect: 'none'}}
          >
            {legendElement}
          </Box>
        )}
      </div>
    </div>
  );
};

export default React.memo(MetricsList);
