// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  getSelectedDataStream,
  getSumologicDataStreamDimensions,
  getSumologicDataStreamMetrics,
} from 'bc/store/selectors';
import DimensionsAndMetrics from 'bc/components/streams/editor/common/DimensionsAndMetrics';
import {getEditorValidationMessage} from 'bc/services/sumologicService';

type PropTypes = {
  dataStream: Object,
  dimensions: Array,
  metrics: Array,
};

class SumologicDimensionsAndMetrics extends React.PureComponent {
  props: PropTypes;

  render() {
    const {dataStream, dimensions, metrics} = this.props;
    const isEditable = false;

    return (
      <DimensionsAndMetrics
        dataStream={dataStream}
        dimensions={dimensions}
        metrics={metrics}
        isLoading={false}
        isEditable={isEditable}
        validatorFunction={getEditorValidationMessage}
      />
    );
  }
}

export default connect(
  (state) => ({
    dataStream: getSelectedDataStream(state),
    dimensions: getSumologicDataStreamDimensions(state),
    metrics: getSumologicDataStreamMetrics(state),
  }),
  {},
)(SumologicDimensionsAndMetrics);
