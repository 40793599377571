// @flow
import React, {useEffect, useMemo, useState} from 'react';
import {useQueryParams} from 'use-query-params';
import {omitBy} from 'lodash';
import moment from 'moment';
import PageLayout from 'common/componentsV2/PageLayout';
import {correctTimestampUnix} from 'common/utils/dateRangeService';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import FilterButton from 'common/componentsV2/FilterButton';

import {
  DEFAULT_PARAMS,
  OPTIONS_STATUS,
  getLabel,
  PARAMS_NAME,
  DEFAULT_PARAMS_CONFIG,
  OPTIONS_STATUS_VALUES,
  TYPE_COMPOSITES,
} from 'composites/services/compositesService';
import useFetchCompositesList from 'composites/api/useFetchComposites';
import CompositesFilters from 'composites/components/CompositesFilters';
import CompositesList from 'composites/components/CompositesList';
import MetricsUsage from 'common/componentsV2/metricsUsage/MetricsUsage';
import {useFetchUsers} from 'dataCollectors/api/useFetchUsers';
import useFetchSources from 'dataCollectors/api/useFetchSources';
import fetchConfigurationAlertsProvider from 'alerts.management/api/fetchConfigurationAlertsProvider';

const EMPTY_OBJECT = {};

const CompositesPage = () => {
  const [queryParams, setQueryParams] = useQueryParams(DEFAULT_PARAMS_CONFIG);
  const {compositesList, isCompositesListLoading} = useFetchCompositesList();
  const [filteredCompositesList, setFilteredCompositesList] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const {sources} = useFetchSources();
  const {users} = useFetchUsers();

  const alertsData = fetchConfigurationAlertsProvider()?.useQuery()?.data;

  useEffect(() => {
    if (!compositesList?.length) return;

    const filtersList = compositesList.filter((composite) => {
      if (!queryParams) return true;
      if (
        queryParams.searchQuery &&
        composite.composite?.title &&
        composite.composite?.title?.toLowerCase().indexOf(queryParams.searchQuery?.toLowerCase()) === -1 &&
        (!composite.composite?.ownerName ||
          composite.composite?.ownerName?.toLowerCase().indexOf(queryParams.searchQuery?.toLowerCase()) === -1) &&
        moment
          .unix(correctTimestampUnix(composite.creationTime))
          .format('MMM DD, YYYY')
          ?.toLowerCase()
          .indexOf(queryParams.searchQuery?.toLowerCase()) === -1
      ) {
        return false;
      }
      if (
        queryParams.type &&
        queryParams.type !== 'all' &&
        composite?.composite?.mtype?.toLowerCase() !== queryParams.type?.toLowerCase()
      ) {
        return false;
      }
      if (queryParams.ownerId) {
        const owners = queryParams.ownerId?.split(',');
        if (!owners.includes(composite?.composite?.ownerId)) {
          return false;
        }
      }
      if (queryParams.status) {
        const statuses = queryParams.status?.split(',');
        let matchStatus = false;
        statuses.forEach((s) => {
          switch (s) {
            case OPTIONS_STATUS_VALUES.ERROR:
              if (!composite.validation?.passed) matchStatus = true;
              break;
            case OPTIONS_STATUS_VALUES.PAUSED:
              if (composite.composite?.paused) matchStatus = true;
              break;
            default:
              if (composite.validation?.passed && !composite.composite?.paused) matchStatus = true;
          }
        });
        if (!matchStatus) return false;
      }

      if (queryParams.stream) {
        if (!composite?.originSourceIds || !composite?.originSourceIds?.length) {
          return false;
        }
        const streams = queryParams.stream?.split(',');
        let matchStream = false;
        streams.forEach((s) => {
          if (composite.originSourceIds.includes(s)) {
            matchStream = true;
          }
        });
        return matchStream;
      }
      return true;
    });
    setFilteredCompositesList(filtersList);
  }, [compositesList, queryParams]);

  const tooltipItems = useMemo(() => {
    const obj = omitBy(queryParams, (value, key) => value === DEFAULT_PARAMS[key]);
    return Object.entries(obj).map(([key, value]) => {
      switch (key) {
        case PARAMS_NAME.OWNER:
          return {
            id: value,
            header: 'Owner',
            value: getLabel(users, value),
          };
        case PARAMS_NAME.STREAM:
          return {
            id: value,
            header: 'Stream',
            value: getLabel(sources, value),
          };

        case PARAMS_NAME.STATUS:
          return {
            id: value,
            header: 'Status',
            value: getLabel(OPTIONS_STATUS, value),
          };
        case PARAMS_NAME.TYPE:
          return {
            id: value,
            header: 'Type',
            value,
          };

        case PARAMS_NAME.ORIGIN:
          return {
            header: 'Origin',
            value,
            id: value,
          };
        case PARAMS_NAME.SEARCH_QUERY:
          return {
            header: 'Search',
            value,
            id: value,
          };
        default:
          return EMPTY_OBJECT;
      }
    });
  }, [queryParams, filteredCompositesList, users]);

  const getRowCount = () => {
    if (isCompositesListLoading) return '(...)';
    return filteredCompositesList.length === compositesList.length
      ? `(${compositesList.length})`
      : `(${filteredCompositesList.length}/${compositesList.length})`;
  };

  const header = useMemo(
    () => (
      <div className="display_flex width_1 justifyContent_space-between">
        <div className="display_flex alignItems_baseline">
          <div className="mr_0-5">
            <Title type={TITLE_TYPES.PAGE_TITLE}>Calculated Metrics</Title>
          </div>
          <div className="mr_1-5">
            <Title type={TITLE_TYPES.PAGE_LINE_COUNTER}>{getRowCount()}</Title>
          </div>
          <FilterButton tooltipItems={tooltipItems} onClick={() => setIsFilterOpen(!isFilterOpen)} />
        </div>
        <MetricsUsage />
      </div>
    ),
    [isCompositesListLoading, filteredCompositesList?.length, queryParams, users, sources, isFilterOpen],
  );

  const getOwner = (composite) => {
    if (composite?.composite?.ownerId) return users.find((u) => u.value === composite?.composite?.ownerId);
    // TODO Remove when @alert ownerId will return from backend
    const alert =
      composite.composite.mtype === TYPE_COMPOSITES.ALERT &&
      alertsData.find((a) => a.data.id === composite?.composite?.originId);
    return alert && users.find((u) => u.value === alert.ownerId);
  };

  useEffect(() => {
    if (!compositesList?.length || !alertsData?.length || !users?.length) return;
    compositesList.forEach(function(c, index) {
      const owner = getOwner(c);
      compositesList[index].composite.ownerName = owner?.label;
      compositesList[index].composite.ownerId = owner?.value;
    });
  }, [alertsData, compositesList, users]);

  return (
    <PageLayout header={header}>
      <div className="display_flex flexGrow_1">
        <CompositesFilters
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        <CompositesList
          compositesList={filteredCompositesList}
          noData={compositesList?.length === 0}
          setCompositeList={(list) => setFilteredCompositesList(list)}
        />
      </div>
    </PageLayout>
  );
};
export default CompositesPage;
