import {createSelector} from 'reselect';
import {get} from 'lodash';
import {getOrganization} from 'profile/store/selectors';
import {fetchGroupsData} from 'admin.users/store/selectors';
import {getDataStreamsItemsOrderedAndFiltered} from 'bc/store/selectors';
import {selectors as commonSelectors} from 'common';
import {types} from 'admin.permissions/services/accessListService';

const EMPTY_OBJECT = {};

export const {getPermissions} = commonSelectors;

export const getIsRbacEnabled = createSelector(
  getOrganization,
  (org) => get(org, 'serverConfiguration.rbacEnabled', false),
);
// Streams
export const getAccessList = createSelector(
  getPermissions,
  (permissions) => permissions.accessList,
);

// View
export const getView = createSelector(
  getPermissions,
  (permissions) => permissions.viewReducer,
);

export const getAccessListByStream = createSelector(
  getView,
  (view) => view.accessListByStream || EMPTY_OBJECT,
);

export const getChannelListByStream = createSelector(
  getView,
  (view) => view.channelByStream || EMPTY_OBJECT,
);

export const getAccessListAllSourcesData = createSelector(
  getAccessList,
  (accessList) => accessList.fetchAccessListForAllSources.data,
);

export const getAccessListAllSourcesIsLoading = createSelector(
  getAccessList,
  (accessList) => accessList.fetchAccessListForAllSources.isLoading,
);

export const getAccessListAllSourcesIsError = createSelector(
  getAccessList,
  (accessList) => accessList.fetchAccessListForAllSources.error,
);

export const getAccessListByGroupsData = createSelector(
  getAccessList,
  (accessList) => accessList.fetchAccessListByGroups.data,
);

export const getAccessListByGroupsIsLoading = createSelector(
  getAccessList,
  (accessList) => accessList.fetchAccessListByGroups.isLoading,
);

export const getSetAccessListForSourceIsLoading = createSelector(
  getAccessList,
  (accessList) => accessList.setAccesslistForSource.isLoading,
);

export const getAccesslistForSourceData = createSelector(
  getAccessList,
  (accessList) => accessList.getAccesslistForSource.data,
);

export const getGroups = createSelector(
  fetchGroupsData,
  (groupsData) =>
    groupsData && groupsData.length
      ? // eslint-disable-next-line no-underscore-dangle
        groupsData.map((i) => ({value: i._id, label: i._name})).sort((a, b) => a.label.localeCompare(b.label))
      : [],
);

const orderByType = (streams) => {
  let obj = {};

  streams.forEach((s) => {
    if (obj[s.dataSourceId]) {
      obj = {
        ...obj,
        [s.dataSourceId]: [...obj[s.dataSourceId], s],
      };
    } else {
      obj = {
        ...obj,
        [s.dataSourceId]: [s],
      };
    }
  });
  return obj;
};

export const getSourcesByGroups = createSelector(
  getAccessListByGroupsData,
  getDataStreamsItemsOrderedAndFiltered,
  (accessListByGroupsData, dataStreams) => {
    const streams = dataStreams.filter((i) => accessListByGroupsData.find((j) => i.id === j.sourceId));

    const obj = {
      [types.stream]: orderByType(streams),
      [types.composite]: {},
      [types.channel]: {},
    };

    return obj;
  },
);

// Channels
export const getAccessListChannelsData = createSelector(
  getAccessList,
  (accessList) => accessList.fetchAccessListForAllChannels.data,
);

export const getAccessListChannelsIsLoading = createSelector(
  getAccessList,
  (accessList) => accessList.fetchAccessListForAllChannels.isLoading,
);

export const getSetAccessListForChannels = createSelector(
  getAccessList,
  (accessList) => accessList.setAccesslistForChannel,
);

export const getFetchChannelByMetricSourceIsLoading = createSelector(
  getAccessList,
  (accessList) => accessList.fetchChannelByMetricSource.isLoading,
);

export const getFetchChannelByMetricSourceData = createSelector(
  getAccessList,
  (accessList) => accessList.fetchChannelByMetricSource.data,
);
