// @flow
import React from 'react';
import Select from 'react-select2';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import * as style from './selectAndt/SelectAndtStyling';
import Control from './selectAndt/innerComponents/Control';
import DropdownIndicatorCaretNoTransform from './selectAndt/innerComponents/DropdownIndicatorCaretNoTransform';
import MenuListVirtualize from './selectAndt/innerComponents/MenuListVirtualize';
import OptionMulti from './selectAndt/innerComponents/OptionMulti';
import EmptyComponent from './selectAndt/innerComponents/EmptyComponent';
import ValueContainer from './selectAndt/innerComponents/ValueContainer';
import InputInMenu from './selectAndt/innerComponents/InputInMenu';

const styles = style.newMultiStyle(
  'THEME_BLUE_LEAN',
  {
    menuWidth: 290,
    buttonWidth: 'fit-content',
    minMenuHeight: 300,
    offsetLeft: 0,
  },
  true,
  true,
);

const components = {
  Control,
  DropdownIndicator: DropdownIndicatorCaretNoTransform,
  MenuList: MenuListVirtualize,
  Option: OptionMulti,
  MultiValue: EmptyComponent,
  SingleValue: EmptyComponent,
  MultiValueRemove: EmptyComponent,
  Placeholder: EmptyComponent,
  ValueContainer,
  Input: InputInMenu,
};

const MultiSearchBlueLeanSelect = ({
  options,
  onChange,
  onClearAll,
  onBlur,
  onApply,
  value,
  appliedValue,
  onMenuOpen,
  searchChanged,
  selectedCount,
}: {
  options: Array,
  onChange: Function,
  onClearAll: Function,
  onBlur: Function,
  onApply: Function,
  value: Array,
  appliedValue: Array,
  onMenuOpen: Function,
  searchChanged: Function,
  selectedCount: any,
}) => (
  <Select
    onBlur={onBlur}
    isSearchable
    className="select-menu"
    styles={styles}
    components={components}
    menuPlacement="auto"
    minMenuHeight={300}
    maxMenuHeight={300}
    maxHeight={300}
    hideSelectedOptions={false}
    animationDuration={1}
    isInputInMenu
    onInputChange={searchChanged}
    captureMenuScroll
    menuShouldScrollIntoView={false}
    closeMenuOnSelect={false}
    options={options}
    optionHeight={40}
    menuWidth={290}
    onChange={onChange}
    value={value}
    appliedValue={appliedValue}
    placeholder="Select"
    multiDelimiter=", "
    isMulti
    onMenuOpen={onMenuOpen}
    menuFooterComponent={
      <FormDdlActionBar
        isVisible
        isClearAllDisabled={!value.length}
        selectedCount={selectedCount}
        onClearAll={onClearAll}
        onApply={onApply}
      />
    }
  />
);

export default MultiSearchBlueLeanSelect;
