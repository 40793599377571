/* eslint-disable */
export const overscanIndicesGetter = ({
  // eslint-disable-next-line no-unused-vars
  direction,
  cellCount,
  // eslint-disable-next-line no-unused-vars
  scrollDirection,
  overscanCellsCount,
  startIndex,
  stopIndex,
}) => ({
  overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
  overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
});

const findPoint1 = (dataArray, tmpFakePoint) => {
  // go over the data array and find the closest point
  // todo: when delta starts growing break the loop bexause it means we found the point and now we are getting further
  let minDelta = Number.MAX_VALUE;
  let minIndex = -1;
  for (let i = 0, len = dataArray.length; i < len; i++) {
    const tmpDelta = Math.abs(dataArray[i]?.plotX - tmpFakePoint?.plotX);
    if (tmpDelta < minDelta) {
      tmpFakePoint.plotY = dataArray[i].plotY;
      tmpFakePoint.y = dataArray[i].y;
      minDelta = tmpDelta;
      minIndex = i;
    }
  }
  return {minIndex, minDelta};
};

export const getLegendValues = (hchart, plotX) => {
  // create a fake point to get the closest point that will be on the line graph
  const tmpFakePoint = {};
  tmpFakePoint.plotX = plotX;
  tmpFakePoint.plotY = 0;
  tmpFakePoint.y = null;

  // go over the series array and find the closest point at each one of them
  const findPoint1ArrResults = [];
  let baseSeriesIndex = -1;
  let minDelta = Number.MAX_VALUE;
  for (let j = 0; j < hchart.series.length; j++) {
    const dataArray = hchart.series[j].data; // get the data of this certain series
    const series1stPoint = findPoint1(dataArray, tmpFakePoint);

    // find the closest data point in order to use this series for base series
    if (series1stPoint.minDelta < minDelta) {
      minDelta = series1stPoint.minDelta;
      baseSeriesIndex = j;
    }
    findPoint1ArrResults.push(series1stPoint);
  }

  const fakePointArray = [];
  for (let i = 0; i < hchart.series.length; i++) {
    if (findPoint1ArrResults[i].minIndex !== -1) {
      const fakerPoint = hchart.series[i].data[findPoint1ArrResults[i].minIndex];
      fakerPoint.series.options.andtVal.y =
        fakerPoint.x === hchart.series[baseSeriesIndex].data[findPoint1ArrResults[baseSeriesIndex].minIndex].x
          ? fakerPoint.y
          : '-';
      fakePointArray.push(fakerPoint);
    }
  }

  return fakePointArray;
};
/* eslint-enable */
