import {makeStyles} from '@material-ui/core';

export default function useDataMapperClasses() {
  const classes = makeStyles(({typography, palette}) => ({
    type: {
      position: 'relative',
      ...typography.subtitle3,
      padding: '0 14px',
      display: 'flex',
      alignSelf: 'flex-start',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: palette.blue['100'],
      borderRadius: 6,
      height: 38,
    },
    icon: {
      position: 'relative',
      top: 2,
      marginRight: 6,
    },
    arrowWrapper: {
      position: 'absolute',
      left: -18,
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
    },
    settings: {
      cursor: 'pointer',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      color: '#8995A0',
    },
  }));

  return {classes};
}
