// @flow
import React, {PureComponent} from 'react';
import NoDataAlertMetric from './NoDataAlertMetric';
import '../alertContent.module.scss';

type PropTypes = {
  alert: Object,
  getWrapperStyle: Function,
  queryParams: Object,
  isSingleLine: boolean,
  specialHeader: boolean,
  searchExpression: String,
};

class NoDataAlert extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const alertContentHeight = this.noDataAlertMetric.clientHeight;
    this.props.getWrapperStyle(alertContentHeight);
  }

  render() {
    const {alert, queryParams, isSingleLine, specialHeader, searchExpression} = this.props;

    return (
      <div
        styleName="alert-content"
        ref={(c) => {
          this.noDataAlertMetric = c;
        }}
      >
        <div styleName="sep" />
        {alert.metrics?.map((m) => (
          <NoDataAlertMetric
            metric={m}
            alertId={alert.alertTriggerId}
            alertConfigurationId={alert.alertConfigurationId}
            startDate={m.displayStartTime || m.startTime}
            endDate={m.displayEndTime || Math.floor(new Date() / 1000)}
            timeScale={alert.timeScale}
            queryParams={queryParams}
            key={m.id}
            isSingleLine={isSingleLine}
            specialHeader={specialHeader}
            searchExpression={searchExpression}
          />
        ))}
      </div>
    );
  }
}

export default NoDataAlert;
