// @flow
import React, {Fragment, useMemo, useEffect, useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import {keyBy} from 'lodash';
import {useSelector, useDispatch} from 'react-redux';
import {Box, makeStyles} from '@material-ui/core';
import {Expandable} from 'common/componentsV2/ExpandableSections';
import {getDataDashboards, getSortingDashboards, getLastViewIsLoading} from 'dashboards/store/selectors';
import {getProfileId} from 'profile/store/selectors';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {ReactComponent as EmptyIcon} from 'app/images/empty-streams.svg';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {OPTION_SORTING_VALUES, optionsSortingDashboardsManagement} from 'dashboards/services/dashboardService';
import ErrorBoundary from 'errorBoundaries/ErrorBoundary';
import DashboardItem from 'dashboards/components/DashboardItem';
import {palette} from 'app/styles/theme';
import DashboardNewButton from 'dashboards/components/DashboardNewButton';
import * as actions from 'dashboards/store/actions';
import NoSearchBlueLeanSelect from 'common/componentsV2/ddl/NoSearchBlueLeanSelect';
import moment from 'moment';
import {ReactComponent as EmptyDashboard} from 'dashboards/images/empty_dashboard.svg';

const useStyles = makeStyles(() => ({
  item: {
    width: '25%',
    height: '186px',
    '&$withFilter': {
      width: '33.33%',
    },
    '@media (min-width: 1920px)': {
      width: '20%',
      '&$withFilter': {
        width: '25%',
      },
    },
  },
  withFilter: {},
}));

const renderEmptyState = () => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} height="100%">
    <EmptyIcon width={205} height={122} />
    <TypographyBox variant="body1" color={palette.indigo[500]} fontSize={18} fontWeight={500} mt={2}>
      Let’s get you dashboarding
    </TypographyBox>
    <TypographyBox variant="hintText" fontSize={16} mt={0.5}>
      Create your first dashboard by clicking the button below
    </TypographyBox>
    <div className="mt_3">
      <DashboardNewButton />
    </div>
  </Box>
);

const emptyFavorite = (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexWrap="wrap"
    flexGrow={1}
    height={164}
    borderRadius={6}
    border={`2px dashed ${palette.gray[200]}`}
    color={palette.gray[400]}
    fontWeight={500}
    ml={2.5}
    mb="50px"
  >
    <Box width={130} textAlign="center">
      Favorite Dashboards Will Appear Here
    </Box>
  </Box>
);

const favoriteNotFound = (
  <Box
    display="flex"
    flexDirection="column"
    flexGrow={1}
    width={1}
    height={214}
    justifyContent="center"
    alignItems="center"
  >
    <TypographyBox variant="caption" color="gray.350" fontWeight={500}>
      No Dashboards Were Found
    </TypographyBox>
  </Box>
);

const dashboardsNotFound = (
  <Box display="flex" flexDirection="column" flexGrow={1} width={1} justifyContent="center" alignItems="center" py={12}>
    <Box pb={2}>
      <EmptyDashboard width={126} height={68} />
    </Box>
    <div className="text16med lineHeight_16">No Dashboards Were Found</div>
    <TypographyBox variant="caption" color="gray.350" mt={1} width={210} lineHeight="20px" textAlign="center">
      There are no dashboards that match the applied filters
    </TypographyBox>
  </Box>
);

const searchWithQueryParams = (dashboard, dataQuery) => {
  const keys = Object.keys(dataQuery).filter((key) => dataQuery[key] !== undefined);

  return keys.every(
    (key) =>
      (key === 'q' && dashboard.name.toLowerCase().includes(dataQuery[key].toLowerCase())) ||
      (key === 'ownerUser' && dataQuery[key].includes(dashboard.ownerUser)) ||
      (key === 'tags' &&
        dashboard.tags.some((tag) =>
          dataQuery[key]
            .toLowerCase()
            .split(',')
            .some((i) => i === tag.display.toLowerCase()),
        )),
  );
};

export const visibilityOffset = {
  top: -500,
  bottom: -500,
};

const DashboardsMain = ({
  isOpenFilters,
  dataQueryParams,
  changedFilter,
}: {
  isOpenFilters: boolean,
  dataQueryParams: Object,
  changedFilter: Array<Object>,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataDashboards = useSelector(getDataDashboards);
  const sortingDashboards = useSelector(getSortingDashboards);
  const lastViewIsLoading = useSelector(getLastViewIsLoading);
  const userId = useSelector(getProfileId);
  const [sortingValue, setSortingValue] = useState({
    value: OPTION_SORTING_VALUES.LAST_VIEWED,
    label: optionsSortingDashboardsManagement[0].label,
  });
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    dispatch(actions.fetchLastViewDashboards(null, {userId}));
  }, []);

  const filteredListFavorite = useMemo(
    () =>
      dataDashboards.filter(
        (dashboard) => dashboard.star?.includes(userId) && searchWithQueryParams(dashboard, dataQueryParams),
      ),
    [dataDashboards, dataQueryParams],
  );

  const filteredListDashboards = useMemo(
    () =>
      dataDashboards.filter(
        (dashboard) => !dashboard.star?.includes(userId) && searchWithQueryParams(dashboard, dataQueryParams),
      ),
    [dataDashboards, dataQueryParams],
  );

  const filteredListLastView = useMemo(() => {
    const {lastView} = sortingDashboards;
    if (!lastView) {
      return filteredListDashboards;
    }
    const favoriteIds = filteredListFavorite.map((item) => item._id);
    const viewed = lastView
      .filter((viewer) => !favoriteIds?.includes(viewer.id))
      .map((dashboardView) => filteredListDashboards.find((dashboard) => dashboard._id === dashboardView.id))
      .filter((item) => item !== undefined);
    // eslint-disable-next-line max-len
    const rest = filteredListDashboards.filter(
      (dashboard) => viewed.find((viewer) => dashboard._id === viewer._id) === undefined,
    );
    return [...viewed, ...rest];
  }, [filteredListDashboards, sortingDashboards, lastViewIsLoading]);

  useEffect(() => {
    setFilteredList(filteredListLastView);
  }, [filteredListLastView]);

  const v1Duplicates = useMemo(() => keyBy(dataDashboards, 'v1ID'), [dataDashboards]);

  // eslint-disable-next-line max-len
  const filteredListWithoutConverted = useMemo(() => filteredList.filter((item) => !v1Duplicates[item._id]), [
    v1Duplicates,
    filteredList,
  ]);
  // eslint-disable-next-line max-len
  const filteredListFavoriteWithoutConverted = useMemo(
    () => filteredListFavorite.filter((item) => !v1Duplicates[item._id]),
    [v1Duplicates, filteredListFavorite],
  );

  if (lastViewIsLoading) {
    return (
      <div className="display_flex alignItems_center justifyContent_center height_1vh width_1vw">
        <Spinner color="gray.500" size={SIZES.XX_BIG_100} />
      </div>
    );
  }
  const onChangeSorting = (option) => {
    const {value} = option;
    switch (value) {
      case OPTION_SORTING_VALUES.LAST_VIEWED:
        dispatch(actions.fetchLastViewDashboards(null, {userId}));
        setSortingValue(option);
        setFilteredList(filteredListLastView);
        return value;
      case OPTION_SORTING_VALUES.ALPHABETICAL:
        setSortingValue(option);
        // eslint-disable-next-line no-case-declarations
        const filteredListAlphabetic = filteredListDashboards.sort((prev, next) => prev.name.localeCompare(next.name));
        setFilteredList(filteredListAlphabetic);
        return value;
      case OPTION_SORTING_VALUES.CREATION_DATE:
        setSortingValue(option);
        // eslint-disable-next-line no-case-declarations
        const filteredCreationDate = filteredListDashboards.sort(
          (prev, next) =>
            moment(next.created)
              .unix()
              .valueOf() -
            moment(prev.created)
              .unix()
              .valueOf(),
        );
        setFilteredList(filteredCreationDate);
        return value;
      default:
        return value;
    }
  };
  const sortingElement = (
    <div className="ml_2-5">
      <NoSearchBlueLeanSelect
        onChange={onChangeSorting}
        value={sortingValue}
        options={optionsSortingDashboardsManagement}
        buttonWidth={150}
        menuWidth={150}
      />
    </div>
  );

  return (
    <ErrorBoundary>
      <div className="flexGrow_1 overflowY_auto">
        {dataDashboards.length === 0 ? (
          renderEmptyState()
        ) : (
          <Fragment>
            <div>
              <Expandable
                automationId="dashboardsFavorite"
                label="Favorites"
                extraStyle={{fontWeight: 500, fontSize: 20}}
                collapsedBottomSpace={20}
                expanded
                reverse
              >
                <div
                  className={`${
                    filteredListFavoriteWithoutConverted.length ? 'mt_5' : 'mt_1-5'
                  } display_flex flexWrap_wrap ml_2-5-`}
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {filteredListFavoriteWithoutConverted.length ? (
                    filteredListFavoriteWithoutConverted.map((dashboard) => (
                      <VisibilitySensor partialVisibility offset={visibilityOffset} key={dashboard._id}>
                        {({isVisible}) => (
                          <div className={`${classes.item} ${isOpenFilters ? classes.withFilter : ''}`}>
                            {isVisible ? <DashboardItem dashboard={dashboard} searchQuery={dataQueryParams.q} /> : null}
                          </div>
                        )}
                      </VisibilitySensor>
                    ))
                  ) : changedFilter.length ? (
                    favoriteNotFound
                  ) : (
                    <div className={`${classes.item} ${isOpenFilters ? classes.withFilter : ''}`} mb={2}>
                      {emptyFavorite}
                    </div>
                  )}
                </div>
              </Expandable>
            </div>
            <div className="pb_3">
              <Expandable
                automationId="allDashboards"
                label="All Dashboards"
                actionElement={sortingElement}
                extraStyle={{fontWeight: 500, fontSize: 20}}
                justifyLabel="flex-start"
                collapsedBottomSpace={20}
                expanded
                reverse
              >
                <div className="display_flex flexWrap_wrap mt_5 ml_2-5-">
                  {!filteredListWithoutConverted.length && dashboardsNotFound}
                  {filteredListWithoutConverted.length &&
                    filteredListWithoutConverted.map((dashboard) => (
                      <VisibilitySensor partialVisibility offset={visibilityOffset} key={dashboard._id}>
                        {({isVisible}) => (
                          <div className={`${classes.item} ${isOpenFilters ? classes.withFilter : ''}`}>
                            {isVisible && <DashboardItem dashboard={dashboard} searchQuery={dataQueryParams.q} />}
                          </div>
                        )}
                      </VisibilitySensor>
                    ))}
                </div>
              </Expandable>
            </div>
          </Fragment>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default React.memo(DashboardsMain);
