import React, {useEffect, useState, useRef, useContext} from 'react';
import {useSelector} from 'react-redux';
import {getRecommendationsEnabled} from 'profile/store/selectors';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import Spinner from 'common/componentsV2/Spinner';
import {TypographyBox} from 'common/componentsV2/boxTools';
import TooltipArea from 'common/componentsV2/TooltipArea';
import AlertRecommendationsMenu from 'alerts.management/components/editor/simulationArea/alertRecommendations/AlertRecommendationsMenu';
import AlertSettingsContext from 'alerts.management/components/editor/simulationArea/context';

import './LoadingBar.module.scss';

type Props = {
  isLoading: Boolean,
  blurLabel: Boolean,
  label: String,
  loadingLabel: String,
  isLoadingBarEnabled: boolean,
  alert: Object,
};

const tooltip = (
  <div>
    <div className="text16med">Metric Data</div>
    <div className="mb_1 text14regg">
      Shows you the actual data of the metrics in the alert expression, without anomalies.
    </div>
    <div className="text16med">Anomaly Simulation</div>
    <div className="mb_1 text14regg">
      Shows you a simulation of data with anomalies. Anomalies that are gray did not meet all the alert conditions.
    </div>
  </div>
);

const LoadingBar = ({isLoading, loadingLabel, label, blurLabel, isLoadingBarEnabled, alert}: Props) => {
  const interval = useRef(null);
  const resetTimeout = useRef(null);
  const [value, setValue] = useState(0);
  const [isDoneVisible, setDoneVisible] = useState(true);

  const isRecommendationsEnabled = useSelector(getRecommendationsEnabled);

  const {
    isBlurVisible,
    setBlurVisible,
    isAutoSimulationEnabled,
    setAutoSimulationEnabled,
    setAutoSimulationPristine,
    isAutoSimulationPristine,
  } = useContext(AlertSettingsContext);

  useEffect(() => {
    if (isLoading) {
      clearTimeout(resetTimeout.current);
      setDoneVisible(true);
      interval.current = setInterval(() => {
        setValue((v) => (v < 95 ? v + 0.1 : 95));
      }, 50);
    } else {
      clearInterval(interval.current);
      setValue(0);
      resetTimeout.current = setTimeout(() => {
        setDoneVisible(false);
        if (blurLabel) {
          setBlurVisible(false);
        }
      }, 3000);
    }
    return () => {
      if (blurLabel) {
        setBlurVisible(false);
      }
      clearInterval(interval.current);
      clearTimeout(resetTimeout.current);
    };
  }, [isLoading, blurLabel]);

  return (
    <div className="display_flex justifyContent_space-between flexShrink_0">
      <div className="position_relative flexGrow_1">
        {blurLabel && isBlurVisible && (
          <TypographyBox
            mt={0.5}
            variant="h3"
            bgcolor="blue.300"
            color="transparent"
            borderRadius={5}
            css={{filter: 'blur(6px)'}}
            position="absolute"
          >
            {label}
          </TypographyBox>
        )}
        <TooltipArea isAlwaysVisible text={tooltip} placement="bottom">
          {(info) => (
            <div className="text20med-gray600 position_absolute">
              {label}
              {info}
            </div>
          )}
        </TooltipArea>
      </div>
      <div className="display_flex flex_gap_15 justifyContent_space-between">
        {isRecommendationsEnabled && <AlertRecommendationsMenu alert={alert} />}
        <div styleName="container" className={`visibility_${isLoadingBarEnabled ? 'visible' : 'hidden'}`}>
          {isLoadingBarEnabled && (
            <>
              <div styleName="filled-bar" style={{width: `${isLoading || !isDoneVisible ? value : 100}%`}} />
              <div styleName="label">{isLoading || !isDoneVisible ? loadingLabel : 'Done'}</div>
              {isLoading && (
                <div styleName="spinner-wrapper">
                  <Spinner size={36} />
                </div>
              )}
              <div className="mt_0-5 zIndex_1">
                <ToggleSwitch
                  automationId="toggleAutoSimulation"
                  isChecked={isAutoSimulationEnabled}
                  onToggle={() => {
                    setAutoSimulationEnabled(!isAutoSimulationEnabled);
                    if (isAutoSimulationPristine) {
                      setAutoSimulationPristine(false);
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoadingBar;
