// @flow
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import SearchBox from 'common/componentsV2/SearchBox';
import {Box} from '@material-ui/core';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import * as selectors from 'dashboards/store/selectors';
import {getUsersGroupsListsWithoutDeleteGroup} from 'admin.users/store/selectors';
import {DEFAULT_PARAMS} from 'dashboards/services/dashboardService';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import isEqual from 'lodash/isEqual';
import {getLabelsEnabled} from 'profile/store/selectors';
import LabelFilter from 'admin.labels/LabelFilter';

type PropTypes = {
  toggleFilters: Function,
  setQueryParams: Function,
  onFilter: Function,
  onChange: Function,
  dataQueryParams: Object,
};

const DashboardsFilters = React.memo(
  ({toggleFilters, setQueryParams, onFilter, onChange, dataQueryParams}: {...PropTypes}) => {
    const optionsOwner = useSelector(getUsersGroupsListsWithoutDeleteGroup);
    const optionsTags = useSelector(selectors.getTagsList);
    const isOpen = useSelector(selectors.isOpenFilters);
    const isLabelsEnabled = useSelector(getLabelsEnabled);
    const [filteredOptionsOwner, setFilteredOptionsOwner] = useState([]);
    const [filteredOptionsTag, setFilteredOptionsTag] = useState([]);

    const selectedOptionOwner = useMemo(
      () => optionsOwner.filter((item) => (dataQueryParams.ownerUser || '').includes(item.value)),
      [dataQueryParams.ownerUser, optionsOwner],
    );
    const selectedOptionTags = useMemo(
      () => optionsTags.filter((tag) => (dataQueryParams.tags || '').includes(tag.name)),
      [optionsTags, dataQueryParams.tags],
    );
    const sortedTagsList = useMemo(() => optionsTags.sort((a, b) => b.count - a.count), [optionsTags]);

    const onChangeHandler = useCallback((items, nameField) => {
      const value = items.length ? items.map((item) => item.value || item.name).join(',') : undefined;
      onChange({value}, nameField);
    }, []);

    const getFilteredSelectedCount = (selectedOptions, filteredOptions, fieldName) => {
      const selectedFilterOptions = selectedOptions?.filter(function(obj) {
        return filteredOptions.some(function(obj2) {
          return obj[fieldName] === obj2[fieldName];
        });
      });
      return selectedFilterOptions?.length || 0;
    };

    const onClearAll = useCallback((nameField, valueType, options, selectedOptions, allOptions) => {
      // if there filter search in the select
      const selectFilterOption =
        allOptions?.length > options?.length
          ? getFilteredSelectedCount(selectedOptions, options, valueType)
          : selectedOptions?.length;

      if (selectedOptions?.length > 0 && selectFilterOption > 0) {
        if (allOptions?.length > options?.length) {
          const filteredValues = selectedOptions.filter(function(obj) {
            return !options?.some(function(obj2) {
              return obj[valueType] === obj2[valueType];
            });
          });
          setQueryParams({
            [nameField]: filteredValues?.length ? filteredValues.map((i) => i[valueType]).join(',') : undefined,
          });
        } else {
          setQueryParams({[nameField]: undefined});
        }
      } else {
        const optionCurrent = (selectedOptions || [])
          .concat(options)
          .map((i) => i[valueType])
          .join(',');

        setQueryParams({[nameField]: optionCurrent});
      }
    }, []);

    const selectedTagsCount = useMemo(() => {
      if (filteredOptionsTag?.length === sortedTagsList?.length) return selectedOptionTags?.length;
      return getFilteredSelectedCount(selectedOptionTags, filteredOptionsTag, 'name');
    }, [selectedOptionTags, filteredOptionsTag, sortedTagsList]);

    const selectedOwnersCount = useMemo(() => {
      if (filteredOptionsOwner?.length === optionsOwner?.length) return selectedOptionOwner?.length;
      return getFilteredSelectedCount(selectedOptionOwner, filteredOptionsOwner, 'value');
    }, [selectedOptionOwner, filteredOptionsOwner, optionsOwner]);

    useEffect(() => {
      setFilteredOptionsOwner(optionsOwner);
    }, [optionsOwner]);

    useEffect(() => {
      setFilteredOptionsTag(optionsTags);
    }, [optionsTags]);

    return (
      <Fragment>
        <FiltersPanel
          isOpen={isOpen}
          isSaveView={false}
          isShareLink={false}
          onClose={toggleFilters}
          onClearAll={() => setQueryParams(DEFAULT_PARAMS)}
          isClearAll={!isEqual(dataQueryParams, DEFAULT_PARAMS)}
        >
          <SearchBox placeHolder="Search Dashboards" filter={dataQueryParams.q || ''} onFilter={onFilter} />
          <Box mt={2.5} width={210}>
            <div className="text14reg lineHeight_14 mb_0-5">Dashboard Owner</div>
            <UsersChannelsFilter
              onChange={(items) => onChangeHandler(items, 'ownerUser')}
              setFilteredOptions={(values) => setFilteredOptionsOwner(values)}
              sortedFlattenChannels={optionsOwner}
              value={selectedOptionOwner}
              footerComponent={
                <FormDdlActionBar
                  isVisible
                  selectedCount={selectedOwnersCount}
                  onClearAll={() =>
                    onClearAll('ownerUser', 'value', filteredOptionsOwner, selectedOptionOwner, optionsOwner)
                  }
                />
              }
            />
          </Box>
          <Box mt={2.5} width={210} pb={2.5}>
            <div className="text14reg lineHeight_14 mb_0-5">Labels</div>
            {isLabelsEnabled ? (
              <LabelFilter
                automationId="dashboardsLabels"
                onChange={(items) => onChangeHandler(items, 'tags')}
                queryParamsName="tags"
              />
            ) : (
              <SelectAndt
                automationId="dashboardsLabels"
                type={TYPE_NEW_MULTI}
                theme={THEME_HIGHLIGHTED}
                onChange={(items) => onChangeHandler(items, 'tags')}
                options={sortedTagsList}
                setFilteredOptions={(values) => setFilteredOptionsTag(values)}
                value={selectedOptionTags}
                isMulti
                placeholder="Select"
                optionHeight={40}
                menuWidth={270}
                isClearable={false}
                closeMenuOnSelect={false}
                getOptionLabel={(val) => (val.val ? val.val : val.name)}
                getOptionValue={(val) => val.name}
                minMenuHeight={300}
                menuFooterComponent={
                  <FormDdlActionBar
                    isVisible
                    selectedCount={selectedTagsCount}
                    onClearAll={() =>
                      onClearAll('tags', 'name', filteredOptionsTag, selectedOptionTags, sortedTagsList)
                    }
                  />
                }
              />
            )}
          </Box>
        </FiltersPanel>
      </Fragment>
    );
  },
);
export default DashboardsFilters;
