import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import useClickOutside from 'common/hooks/useClickOutside';
import Input from 'common/componentsV2/Input';
import './editableCell.module.scss';

export default function EditableCell({value: initialValue, originalValue, updateData, onFocus}) {
  const editBtn = useRef(null);
  const [value, setValue] = useState(initialValue);
  const [isEditOn, setIsEditOn] = useState(false);

  const edit = (e) => {
    e.stopPropagation();
    setIsEditOn((prevState) => !prevState);
  };

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateData(originalValue, value);
    setIsEditOn(false);
    onFocus(null);
  };

  useClickOutside(editBtn, () => setIsEditOn(false), [originalValue]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (isEditOn) {
      onFocus(originalValue);
    } else {
      onFocus(null);
    }
  }, [isEditOn]);

  return (
    <>
      <span style={{display: !isEditOn ? 'inline-block' : 'none'}}>{value}</span>
      <Input
        id={originalValue}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        style={{
          display: isEditOn ? 'inline-block' : 'none',
          minWidth: 30,
          width: 'calc(100% - 25px)',
          height: 24,
          fontSize: 13,
          padding: '0 2px',
        }}
      />

      <i ref={editBtn} styleName="edit-btn" className="icon icn-action16-edit" onClick={edit} />
    </>
  );
}

EditableCell.propTypes = {
  value: PropTypes.string.isRequired,
  originalValue: PropTypes.string.isRequired,
  updateData: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
};
