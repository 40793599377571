// @flow
import React, {useContext, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {
  ALERT_CONSOLE_COLUMN_HEADERS,
  ORDER_DIRECTIONS,
  REFRESH_ALERTS,
} from 'alerts.console.new/services/alertsConsoleService';
import AlertsCounter from 'alerts.console.new/components/alertsList/AlertsCounter';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import IntervalsDropDown from 'common/components/intervalsDropDown/IntervalsDropDown';
import {getAlertAssigneeEnabled, getRecommendationsEnabled} from 'profile/store/selectors';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import {queryErrorHandler} from 'reactQuery/queryClient';
import fetchTriggeredAlertMetricsProvider from 'alerts.console.new/api/fetchTriggeredAlertMetricsProvider';
import AlertConsoleQueryParamsContext from 'alerts.console.new/context';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const styles = {
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 40px 70px 80px 80px 80px 60px 60px',
    alignItems: 'center',
    paddingRight: 12,
  },
  isNotAlertAssigneeEnabled: {
    gridTemplateColumns: '1fr 40px 80px 80px 80px 60px 60px',
  },
  isNotRecommendationsEnabled: {
    gridTemplateColumns: '1fr 70px 80px 80px 80px 60px 60px',
  },
  isNotAlertAssigneeNorRecommendationsEnabled: {
    gridTemplateColumns: '1fr 80px 80px 80px 60px 60px',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
};

const AlertsListHeader = ({
  queryParams,
  setQueryParams,
  totalTriggeredAlerts,
  displayedTotalTriggeredAlerts,
}: {
  queryParams: Object,
  setQueryParams: Function,
  totalTriggeredAlerts: Number,
  displayedTotalTriggeredAlerts: Number,
}) => {
  const triggeredAlertsProvider = fetchTriggeredAlertsProvider(queryParams);
  const triggeredAlertsMetricsProvider = fetchTriggeredAlertMetricsProvider(queryParams);

  const isAlertAssigneeEnabled = useSelector(getAlertAssigneeEnabled);
  const isRecommendationsEnabled = useSelector(getRecommendationsEnabled);

  const handleTitleOrderChange = (columnName) => {
    let updatedDirection = ORDER_DIRECTIONS.DESC;

    if (columnName === queryParams.sortBy) {
      updatedDirection = queryParams.order === ORDER_DIRECTIONS.DESC ? ORDER_DIRECTIONS.ASC : ORDER_DIRECTIONS.DESC;
    }
    setQueryParams({sortBy: columnName, order: updatedDirection});
  };

  useEffect(() => {
    if (triggeredAlertsProvider.isError) {
      const error = {
        title: 'Triggered Alerts Error',
        description: `Error while getting triggered alerts from the server`,
      };
      queryErrorHandler(error);
    }
  }, [triggeredAlertsProvider.isError]);

  const refreshAlerts = async (interval) => {
    if (interval === REFRESH_ALERTS.NOW) {
      await triggeredAlertsProvider.reset();
      await triggeredAlertsMetricsProvider.reset(QUERY_KEYS.triggeredAlertMetrics);
    } else {
      await triggeredAlertsProvider.invalidate();
      await triggeredAlertsMetricsProvider.invalidate(QUERY_KEYS.triggeredAlertMetrics);
    }
  };

  const context = useContext(AlertConsoleQueryParamsContext);

  const updateContextRefreshInterval = (selectedInterval) => {
    context.setContextRefreshInterval(selectedInterval.id);
  };

  const getGridColumnsStyle = () => {
    if (isAlertAssigneeEnabled && isRecommendationsEnabled) {
      return styles.wrapper;
    }
    if (isAlertAssigneeEnabled && !isRecommendationsEnabled) {
      return {...styles.wrapper, ...styles.isNotRecommendationsEnabled};
    }
    if (!isAlertAssigneeEnabled && isRecommendationsEnabled) {
      return {...styles.wrapper, ...styles.isNotAlertAssigneeEnabled};
    }
    return {...styles.wrapper, ...styles.isNotAlertAssigneeNorRecommendationsEnabled};
  };

  const featureFlagsColumns = useMemo(() => {
    let tmpColumns = Object.values(ALERT_CONSOLE_COLUMN_HEADERS);
    if (!isAlertAssigneeEnabled) {
      tmpColumns = tmpColumns.filter((column) => column.id !== ALERT_CONSOLE_COLUMN_HEADERS.ASSIGNEE.id);
    }
    if (!isRecommendationsEnabled) {
      tmpColumns = tmpColumns.filter((column) => column.id !== ALERT_CONSOLE_COLUMN_HEADERS.RECOMMENDATIONS.id);
    }
    if (!queryParams.severityView === true) {
      tmpColumns = tmpColumns.filter((column) => column.id !== ALERT_CONSOLE_COLUMN_HEADERS.SEVERITY.id);
    }
    return tmpColumns;
  }, [isAlertAssigneeEnabled, isRecommendationsEnabled]);

  return (
    <div style={getGridColumnsStyle()}>
      <div style={styles.rightSide}>
        <AlertsCounter
          displayedTotalTriggeredAlerts={displayedTotalTriggeredAlerts}
          totalTriggeredAlerts={totalTriggeredAlerts}
        />
        <IntervalsDropDown
          onAction={refreshAlerts}
          listOfIntervals={Object.values(REFRESH_ALERTS)}
          dataSegment="alerts-console-refresh"
          initialInterval={context.contextRefreshInterval}
          onIntervalChange={updateContextRefreshInterval}
        />
      </div>

      {featureFlagsColumns.map((column) => (
        <div key={column.id} style={styles[column.name]}>
          <TitleHeaderTableOrder
            columnName={column.name}
            isActive={column.name === queryParams.sortBy}
            isSortable={column.isSortable}
            onChange={handleTitleOrderChange}
            selectedDirection={queryParams.order}
            title={column.title}
          />
        </div>
      ))}
    </div>
  );
};

export default AlertsListHeader;
