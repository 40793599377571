import React from 'react';
import DateRanges from 'alerts.management/components/editor/simulationArea/simulationFields/DateRanges';
import {makeStyles} from '@material-ui/core';
import TimeScale from 'alerts.management/components/editor/simulationArea/simulationFields/TimeScale';
import {useField} from 'react-final-form';
import {TypographyBox} from 'common/componentsV2/boxTools';
import SelectAndt, {THEME_BLUE_LEAN_MODIFIED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

const useStyles = makeStyles(({typography}) => ({
  labelFilter: {
    ...typography.subtitle1,
    lineHeight: '24px',
    marginBottom: 4,
    display: 'flex',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
}));

const amountOptions = {
  5: {
    value: '5',
    label: '5 anomalies',
  },
  10: {value: '10', label: '10 anomalies'},
  15: {
    value: '15',
    label: '15 anomalies',
  },
};

const sortAnomaliesOptions = {
  startDate: {value: 'startDate', label: 'Start Date'},
  score: {value: 'score', label: 'Score'},
  delta: {value: 'delta', label: 'Absolute Delta'},
};

const labelsMargin = 1.5;

const PreviewSettingsHeader = () => {
  const classes = useStyles();

  const {
    input: {value: anomaliesAmount, onChange: onChangeAmount},
  } = useField('anomaliesAmount');

  const {
    input: {value: sortAnomalies, onChange: onChangeSortAnomalies},
  } = useField('sortAnomalies');

  return (
    <div>
      <div className={classes.wrapper}>
        <div>
          <TypographyBox mb={labelsMargin} variant="subtitle2">
            Time Frame:
          </TypographyBox>
          <DateRanges />
        </div>
        <div>
          <TypographyBox mb={labelsMargin} variant="subtitle2">
            Timescale:
          </TypographyBox>
          <TimeScale theme={THEME_BLUE_LEAN_MODIFIED} />
        </div>
        <div>
          <TypographyBox mb={labelsMargin} variant="subtitle2">
            Show:
          </TypographyBox>
          <SelectAndt
            id="anomaliesAmount"
            automationId="anomaliesAmount"
            extraClassName="alerts-dropdown-btn"
            type={TYPE_NEW_NO_SEARCH}
            theme={THEME_BLUE_LEAN_MODIFIED}
            options={Object.values(amountOptions).sort()}
            optionHeight={40}
            onChange={(o) => onChangeAmount(o.value)}
            value={amountOptions[anomaliesAmount || 10]}
            buttonWidth={130}
            menuWidth={150}
            minMenuHeight={100}
            maxMenuHeight={250}
            appendToBody={false}
          />
        </div>
        <div>
          <TypographyBox mb={labelsMargin} variant="subtitle2">
            Sort by:
          </TypographyBox>
          <SelectAndt
            id="sortAnomalies"
            automationId="sortAnomalies"
            extraClassName="alerts-dropdown-btn"
            type={TYPE_NEW_NO_SEARCH}
            theme={THEME_BLUE_LEAN_MODIFIED}
            options={Object.values(sortAnomaliesOptions)}
            optionHeight={40}
            onChange={(o) => onChangeSortAnomalies(o.value)}
            value={sortAnomaliesOptions[sortAnomalies]}
            buttonWidth={130}
            menuWidth={150}
            minMenuHeight={100}
            maxMenuHeight={250}
            appendToBody={false}
          />
        </div>
      </div>
    </div>
  );
};

export default PreviewSettingsHeader;
