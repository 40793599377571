import {useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import {makeStyles} from '@material-ui/core';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {getIncidentTokenMapIsLoading, getInvOtherMeasures} from 'investigationNewAlertConsole/store/selectors';

import '../OverwiewTab.module.scss';

const CorrelationsInfoCube = ({onIsLoading, isTriggerLoading}: {onIsLoading: Function, isTriggerLoading: Boolean}) => {
  const css = makeStyles(() => ({
    actionBtn: {
      maxWidth: 254,
    },
    whatTabChip: {
      maxWidth: 253,
    },
  }));
  const classes = css();
  const otherMeasures = useSelector(getInvOtherMeasures);
  const isTokenMapLoading = useSelector(getIncidentTokenMapIsLoading);
  const isLoading = isTriggerLoading || isTokenMapLoading;

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);
  return (
    <div styleName="info-cube-container correlations-info-cube">
      {otherMeasures.length ? (
        <div styleName="measures">
          <Tooltip content={otherMeasures[0]} type={TYPES.SMALL}>
            <div styleName="tooltip-container">
              <InnerChip className={classes.whatTabChip} title={otherMeasures[0]} displayOnly type="measure" />
            </div>
          </Tooltip>
          <label>Has a strong correlation</label>
        </div>
      ) : (
        <>
          <label>We found the incident is contained within the original measure.</label>
          <h5>No other measures are affected</h5>
        </>
      )}
    </div>
  );
};

export default CorrelationsInfoCube;
