// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedEditor as setSelectedEditorAction} from 'bc/store/actions';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import PanelHeader from '../../../PanelHeader';
import Filters from './Filters';

import './DimensionsAndMetrics.module.scss';

type PropTypes = {
  isLoading: boolean,
  selectedDimensions: Array,
  selectedMetrics: Array,
  setSelectedEditor: Function,
  dataStream: Object,
};

const List = ({title, list}: any) => {
  return (
    <div styleName="dm-list">
      <div styleName="title">{title}</div>
      <div styleName="scroll">
        {list.map((item) => (
          <div>{item}</div>
        ))}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    selectedDimensions: selectors.getSelectedGoogleSearchDataStreamDimensions(state),
    selectedMetrics: selectors.getSelectedGoogleSearchDataStreamMetrics(state),
    isLoading: selectors.getGoogleSearchStreamSitesIsLoading(state),
  }),
  {
    setSelectedEditor: setSelectedEditorAction,
  },
)(
  class DimensionsAndMetrics extends React.PureComponent {
    props: PropTypes;

    render() {
      const {selectedMetrics, selectedDimensions, dataStream, setSelectedEditor, isLoading} = this.props;
      const isEditable = (isStreamEditable(dataStream.state) || isStreamRunning(dataStream.state)) && !isLoading;

      return (
        <div className="shell-col" styleName="mid-column" automation-id="streamQueryMeasuresAndDimensions">
          <PanelHeader
            title="Measures & Dimensions"
            onSelect={() => setSelectedEditor({type: 'dm_gsearch'})}
            isEditable={isEditable}
          />

          <div styleName="dm-container">
            <List title="List of Measures" list={selectedMetrics} />
            <List title="List of Dimensions" list={selectedDimensions} />
          </div>

          <Filters />
        </div>
      );
    }
  },
);
