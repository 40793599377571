import React from 'react';
import {ALERT_TYPES} from 'investigationNewAlertConsole/services/investigationService';
import IncidentTabAnomalyAlert from './IncidentTabComponents/IncidentTabAnomalyAlert';
import IncidentTabStaticNoDataAlert from './IncidentTabComponents/IncidentTabStaticNoDataAlert';

import './IncidentTab.module.scss';

const IncidentTab = ({
  isTriggerLoading,
  groupId,
  trigger,
}: {
  isTriggerLoading: Boolean,
  groupId: String,
  trigger: Object,
}) => {
  return (
    !isTriggerLoading &&
    trigger && (
      <>
        {trigger?.type === ALERT_TYPES.ANOMALY && <IncidentTabAnomalyAlert trigger={trigger} groupId={groupId} />}

        {(trigger?.type === ALERT_TYPES.STATIC || trigger?.type === ALERT_TYPES.NO_DATA) && (
          <IncidentTabStaticNoDataAlert trigger={trigger} />
        )}
      </>
    )
  );
};

export default IncidentTab;
