import React, {useEffect, useMemo, useState} from 'react';
import {components} from 'react-select2';
import PropTypes from 'prop-types';
import {ReactComponent as EmptyIcon} from 'app/images/empty-dropdown.svg';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import AnodotReactSelect from 'common/componentsV2/AnodotReactSelect';

import {palette} from 'app/styles/theme';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import styles from './FilterSelector.module.scss';

// /TODO add sub select options to filter dimension selector - in scss file
// const optionStyle = {
//   height: 36,
//   padding: 8,
//   position: 'relative',
// };
//
// const cbOptionStyle = {
//   height: 36,
//   padding: 8,
// };
//
// const checkBoxStyle = {paddingLeft: 8};

const FilterSelector = ({
  options,
  placeholder,
  onChange,
  value,
  onMenuOpen,
  isMulti,
  openMenuInLoading,
  width,
  icon,
  setMenuIsOpen,
  isLoading,
}) => {
  const customStyles = {
    option: (provided) => ({
      ...provided,
      height: 40,
      width: width - 10,
      borderRadius: 6,
      margin: 3,
      '&:hover': {
        borderRadius: 6,
        marginRight: 5,
      },
    }),
    container: (provided) => ({
      ...provided,
      width,
      position: 'absolute',
      backgroundColor: '#E9F0FF',
      borderRadius: 6,
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: '#E9F0FF',
      height: 50,
      borderRadius: 6,
      border: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: '1px solid #2671FF',
      boxShadow: '0 4px 12px -2px rgba(0, 0, 36, 0.25)',
      zIndex: 2,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: 0,
      border: 0,
      borderRadius: 6,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: '0 4px 12px -2px rgba(0, 0, 36, 0.25)',
      zIndex: 2,
    }),
    indicatorSeparator: () => ({}), // removes the "stick"
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      '& svg': {display: 'none'},
    }),
  };
  const [currentSubMenuIndex, setCurrentSubMenuIndex] = useState(-1);

  useEffect(() => {
    if (openMenuInLoading && onMenuOpen) {
      onMenuOpen();
    }
  }, []);

  // /TODO add sub select options to filter dimension selector
  // const [height, setHeight] = useState(0);
  // const subSelectComponents = useMemo(() => ({
  //   IndicatorSeparator: () => null,
  //   Option: (props) => (
  //     <div style={cbOptionStyle}>
  //       <input type="checkbox" checked={props.isSelected} onChange={() => null} />
  //       <label style={checkBoxStyle}> {props.label}</label>
  //     </div>
  //   ),
  // }));
  //
  // const CustomSubOption = (props) => {
  //   const [submenu, setSubmenu] = useState(false);
  //
  //   const index = props?.options[0]?.options.indexOf(props.data);
  //
  //   const dropdownSubmenuStyle = {
  //     position: 'fixed',
  //     left: 470,
  //     minHeight: 120,
  //     top: height,
  //     overflow: 'auto',
  //     background: '#ffffff',
  //   };
  //
  //   const handleOption = (e) => {
  //     if (currentSubMenuIndex === index) {
  //       setCurrentSubMenuIndex(-1);
  //     } else {
  //       setHeight(e.clientY);
  //       setCurrentSubMenuIndex(index);
  //       setSubmenu(true);
  //     }
  //   };
  //
  //   const onSubItemChange = () => {};
  //
  //   const {data} = props;
  //   const subOptions1 = [{label: `first ${data.label}`, value: '1'}, {label: 'second', value: '2'}];
  //   const value1 = {};
  //   return (
  //     <>
  //       <div onClick={handleOption} className="customs" style={optionStyle}>
  //         {data.label}
  //         {currentSubMenuIndex === index && (
  //           <div style={dropdownSubmenuStyle}>
  //             <FilterSelector
  //               options={subOptions1}
  //               onChange={onSubItemChange}
  //               value={value1}
  //               // isMulti={true}
  //               openMenuInLoading
  //               components={subSelectComponents}
  //               width={290}
  //               placeholder={`Search ${data.label}`}
  //             />
  //           </div>
  //         )}
  //       </div>
  //       <style jsx>{`
  //         .customs:hover {
  //           background-color: #f7faff;
  //         }
  //       `}</style>
  //     </>
  //   );
  // };

  const nothingToShow = () => (
    <div className={styles.emptyIconContainer}>
      {isLoading ? (
        <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
      ) : (
        <>
          <EmptyIcon width={101} height={71} />
          <TypographyBox variant="subtitle2" marginTop={2}>
            {isLoading ? 'Loading...' : 'Nothing Found'}
          </TypographyBox>
        </>
      )}
    </div>
  );

  // eslint-disable-next-line react/prop-types
  const ValueContainer = ({children, selectProps, ...props}) => {
    // eslint-disable-next-line react/prop-types
    const {inputValue} = selectProps;
    return (
      components.ValueContainer && (
        <div>
          <div className={styles.valueContainer} {...props} onClick={setMenuIsOpen}>
            {children}
            {!!children && (
              <i className={`icon icn-general16-search ${inputValue && styles.focusIcon}`} aria-hidden="true" />
            )}
          </div>
          {inputValue && (
            <i
              className={`icon icn-general16-closeb ${styles.iconClear}`}
              onClick={(e) => {
                e.currentTarget.name = 'clearBtn';
                setMenuIsOpen(e);
              }}
            />
          )}
        </div>
      )
    );
  };

  const filterSelectCustomComponents = useMemo(
    () => ({
      // for open sub menu select with filter options use custom option component
      // Option: (props) => CustomSubOption(props),
      NoOptionsMessage: () => nothingToShow(),
      ValueContainer: (props) => ValueContainer(props),
      IndicatorSeparator: () => null,
    }),
    [icon, currentSubMenuIndex, setCurrentSubMenuIndex, isLoading],
  );

  const formatGroupLabel = (data) => (
    <div className={styles.group}>
      <span>{`${data.label} `}</span>
      <span>({data.options.length})</span>
    </div>
  );

  return (
    <AnodotReactSelect
      styles={customStyles}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={options}
      components={filterSelectCustomComponents}
      isMulti={isMulti}
      autoFocus
      onMenuOpen={onMenuOpen}
      setMenuIsOpen={setMenuIsOpen}
      menuIsOpen={openMenuInLoading}
      formatGroupLabel={formatGroupLabel}
      name="filterSelector"
      automationId="filterDimension"
    />
  );
};

export default FilterSelector;

FilterSelector.defaultProps = {
  placeholder: '',
  onChange: () => null,
  onMenuOpen: () => null,
  isMulti: false,
  openMenuInLoading: false,
  width: 200,
  icon: '',
  isLoading: false,
};

FilterSelector.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  setMenuIsOpen: PropTypes.func.isRequired,
  value: PropTypes.instanceOf(Object).isRequired,
  onMenuOpen: PropTypes.func,
  isMulti: PropTypes.bool,
  openMenuInLoading: PropTypes.bool,
  width: PropTypes.number,
  icon: PropTypes.string,
  isLoading: PropTypes.bool,
};
