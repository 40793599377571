// @flow

import {makeStyles} from '@material-ui/core';
import React from 'react';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

const useStyles = makeStyles(({palette}) => ({
  titleWrapper: {
    height: 28,
    maxWidth: '100%',
    flexShrink: 0,
  },
  name: {
    maxWidth: '100%',
    padding: '0 8px',
    borderRadius: 4,
    backgroundColor: palette.purple[200],
    lineHeight: '28px',
    color: palette.purple[600],
    flexShrink: 0,
  },
  label: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },
  textClear: {
    maxWidth: '100%',
    padding: '0 8px',
    borderRadius: 4,
    backgroundColor: 'transparent',
    lineHeight: '28px',
    color: 'black',
    flexShrink: 0,
  },
}));

type TileHeadlineProps = {
  title: string,
  nameMeasure: string,
};

const TileHeadline = ({title, nameMeasure}: TileHeadlineProps) => {
  const classes = useStyles();
  const isEmptyTitle = (title || '').length === 0;
  const label = (
    <div className={classes.label}>
      <div className={`text14reg lineHeight_18 ellipsis ${classes.name}`}>{nameMeasure}</div>
    </div>
  );
  let titleHead = nameMeasure;
  let body = <div className={`maxWidth_1 ${classes.titleWrapper}`}>{label}</div>;

  if (!isEmptyTitle) {
    titleHead = title;
    body = <div className={`${classes.titleWrapper} ${classes.name} ${classes.textClear} ellipsis`}>{title}</div>;
  }

  return (
    <Tooltip content={titleHead} type={TYPES.SMALL} delay={50}>
      {body}
    </Tooltip>
  );
};

export default React.memo(TileHeadline);
