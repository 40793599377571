// @flow
import React from 'react';
import {OverlayTrigger, Tooltip as BootstrapTooltip} from 'react-bootstrap';
import {getUniqueId} from 'common/utils/guid';
import {pick} from 'lodash';

import './Tooltip.module.scss';

export const TYPES = {
  SMALL: 'small',
  INFO: 'info',
  LARGE: 'large',
  NAV_BAR: 'navigation',
};

type PropTypes = {
  id: String,
  content: Node,
  hideIfEqualTo?: Node,
  extraTtClass?: String, // set additional css class for the tooltip object
  type: string,
  children: Node,
  delay?: number,
  placement?: string,
};

export default class Tooltip extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    hideIfEqualTo: null,
    extraTtClass: null,
    delay: 300,
    placement: 'top',
  };

  render() {
    const {children, id, content, hideIfEqualTo, extraTtClass, type} = this.props;
    let tooltip = <EmptyComponent />;
    const styles = `tooltip ${type || ''} ${String(content).length > 30 ? 'full-alignment' : ''} ${
      (extraTtClass || '').includes('center-alignment') ? 'center-alignment' : ''
    }`;
    if (content) {
      const ttClass = extraTtClass ? {bsClass: `${extraTtClass} tooltip`} : {};
      tooltip = (
        <BootstrapTooltip styleName={styles} id={id || getUniqueId()} {...ttClass}>
          {content}
        </BootstrapTooltip>
      );
    }
    if (hideIfEqualTo !== null && hideIfEqualTo === content) {
      tooltip = <EmptyComponent />;
    }

    return (
      <OverlayTrigger
        overlay={tooltip}
        {...pick(this.props, ['trigger', 'placement', 'delay', 'delayHide', 'delayShow', 'shouldUpdatePosition'])}
      >
        {children}
      </OverlayTrigger>
    );
  }
}

const EmptyComponent = () => <span />;
