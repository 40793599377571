import React from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import Button, {COLORS} from 'common/componentsV2/Button';
import {useSelector} from 'react-redux';
import {getIsImpersonate} from 'profile/store/selectors';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';
import {ReactComponent as UnimpersonteIcon} from './icons/unimpersonate.svg';

const useStyles = makeStyles(() => ({
  noDataContainer: {
    textAlign: 'center',
    margin: 'auto',
  },
  icon: {},
  title: {
    fontSize: 18,
    fontWeight: 500,
    marginTop: 18,
  },
}));

const Unimpersonate = () => {
  const history = useHistory();
  const classes = useStyles();
  const isImpersonating = useSelector(getIsImpersonate);

  function handleClick() {
    history.push('/admin/users-groups');
  }

  return (
    <div className={classes.noDataContainer}>
      <div className={classes.icon}>
        <UnimpersonteIcon />
      </div>
      {isImpersonating ? (
        <div>
          <Spinner color={palette.gray[500]} size={SIZES.XX_BIG_150} />
        </div>
      ) : (
        <div>
          <div className={classes.title}>Anodot Admin Entry Page</div>
          <Button text="Go to Users" colorSchema={COLORS.BLUE_500} onClick={handleClick} />
        </div>
      )}
    </div>
  );
};

export default Unimpersonate;
