import {useQuery} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';

const root = 'api/v1/data-collectors/';

// const fetchSchemas = async (sourceId) => {
const getConfigurationData = async (template, resource) => {
  return get(`${root}configurationData?template=${template}&resource=${resource}`);
};

export default function useFetchTemplate(template, resource) {
  const {data: templateJson = null, isLoading: isTemplateJsonLoading, isFetching: isTemplateJsonFetching} = useQuery(
    [QUERY_KEYS.template, resource],
    () => getConfigurationData(template, resource),
    {
      retry: 3,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  return {templateJson, isTemplateJsonLoading, isTemplateJsonFetching};
}
