// flow
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Transition} from 'react-transition-group';
import * as selectors from 'insightsPanel/store/selectors';
import * as actions from 'insightsPanel/store/actions';
import {abbrNum, commasSepNumber} from 'common/utils/numbers';
import {fadeOpacity, shouldFetchData} from 'insightsPanel/services/constants';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {palette} from 'app/styles/theme';

import './MetricsUsage.module.scss';

type PropTypes = {
  title: String,
};

const MetricsUsage = (props: PropTypes) => {
  const metricsUsage = useSelector(selectors.getMetricsUsage);
  const metricsUsageData = useSelector(selectors.getMetricsUsageData);
  const metricsLimit = useSelector(selectors.getMetricsUsageDataLimit);
  const metricsMonthlyUsage = useSelector(selectors.getMetricsUsageDataMonthlyUsage);
  const isLoading = useSelector(selectors.getIsLoadingMetricsUsage);
  const isPanelOpen = useSelector(selectors.getIsPanelOpen);
  const numLastDays = useSelector(selectors.getNumLastDays);
  const dispatch = useDispatch();
  const isFirstRun = useRef(true);
  let timer1;
  let timer2;

  const [showData, setShowData] = useState(false);
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);

  useEffect(() => {
    const params = {
      numLastDays: numLastDays.value,
    };
    if (isPanelOpen && shouldFetchData(metricsUsage)) {
      dispatch(actions.fetchMetricsUsage(params));
    }
  }, [isPanelOpen]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const params = {
      numLastDays: numLastDays.value,
    };
    dispatch(actions.fetchMetricsUsage(params));
  }, [numLastDays]);

  useEffect(() => {
    if (metricsUsageData) {
      timer2 = setTimeout(() => {
        // for some reason Transition duration does not work.
        setProgressBarPercentage(Math.round((metricsMonthlyUsage / metricsLimit) * 100));
      }, fadeOpacity.duration);
    }
  }, [metricsUsageData]);

  useEffect(() => {
    if (metricsUsageData) {
      // for some reason Transition duration does not work.
      timer1 = setTimeout(() => setShowData(true), fadeOpacity.duration);
    }
  }, [metricsUsageData]);

  useEffect(
    () => () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    },
    [isPanelOpen],
  );

  const tooltipBody = () => (
    <div style={{textAlign: 'left'}}>
      <div>
        <strong>Used</strong>
        <span>
          : {progressBarPercentage}% ({commasSepNumber(metricsMonthlyUsage)}){' '}
        </span>
      </div>
      <div>
        <strong>Total Capacity</strong>
        <span>:{`${' '}${commasSepNumber(metricsLimit)}`}</span>
      </div>
      <div>
        <strong>Remaining</strong>
        <span>:{`${' '}${commasSepNumber(metricsLimit - metricsMonthlyUsage)}`}</span>
      </div>
    </div>
  );

  const duration = 750;

  const setFillColor = (percentage) => {
    if (percentage === 0) {
      return palette.gray[400];
    }
    if (percentage < 85) {
      return palette.blue[500];
    }
    if (percentage >= 85 && percentage < 95) {
      return palette.orange[500];
    }
    return palette.red[500];
  };

  const defaultStyle = {
    transition: `width ${duration}ms ease-out`,
    width: 0,
    backgroundColor: setFillColor(progressBarPercentage),
  };

  const transitionStyles = {
    entering: {width: 0},
    entered: {width: `${progressBarPercentage}%`},
    exiting: {width: `${progressBarPercentage}%`},
    exited: {width: 0},
  };

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && metricsUsageData && (
        <Transition in={showData} timeout={0}>
          {(state) => (
            <Fragment>
              <header
                styleName="header"
                style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state], marginBottom: '20px'}}
              >
                {props.title}
              </header>
              <div style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}>
                <Transition in={!!progressBarPercentage} timeout={0}>
                  {(stateTwo) => (
                    <div>
                      <SmartTooltip content={tooltipBody()} delay={300}>
                        <div styleName="progressBar-wrapper">
                          <span styleName="progressBar-fill" style={{...defaultStyle, ...transitionStyles[stateTwo]}} />
                        </div>
                      </SmartTooltip>
                    </div>
                  )}
                </Transition>
                <div styleName="legend">
                  <p>
                    <span style={{color: defaultStyle.backgroundColor}}>{abbrNum(metricsMonthlyUsage, 1, true)}</span>{' '}
                    of
                    {`${' '}${abbrNum(metricsLimit, 1, true)}`} used
                  </p>
                </div>
              </div>
            </Fragment>
          )}
        </Transition>
      )}
    </div>
  );
};

export default MetricsUsage;
