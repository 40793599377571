// @flow
import React, {Fragment, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {get, escapeRegExp} from 'lodash';
import {makeStyles} from '@material-ui/core';
import Spinner from 'common/componentsV2/Spinner';
import WarningNavigationIcon from 'dashboards/components/WarningNavigationIcon';
import AlertBellContainer from 'dashboards/components/AlertBellContainer';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import FilterDDL from 'dashboards/components/metricTiles/FilterDDL';
import SelectAndt, {
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import OptionWithIcon from 'common/componentsV2/ddl/selectAndt/innerComponents/OptionWithIcon';
import {allowedUserTileActions, OPTION_TILES_VALUES, tileActions} from 'dashboards/services/dashboardService';
import {setDashboardData} from 'dashboards/store/actions';
import Highcharts from 'highcharts';
import TileHeadline from './TileHeadline';

const EMPTY_OBJECT = {};

type PropTypes = {
  dashboardData: Object,
  tileData: Object,
  nameMeasure: string,
  isEditableMode: boolean,
  matchUrl: string,
  formValues: Object,
  commonValues: Object,
  isAnonymous: boolean,
  isOwnerUser: boolean,
  validationMessage: Object,
  compositeErrorsMap: Object,
  isCompositeDataLoading: boolean,
  titlePostfix: React,
};

const useStyles = makeStyles(({palette}) => ({
  settingHeader: {
    position: ({isPieOrDonut}) => (isPieOrDonut ? 'absolute' : 'relative'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 24,
    fontSize: 20,
    color: palette.black[500],
    fontWeight: 500,
    margin: '0 8px 0 4px',
    flexShrink: 0,
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  editWrapper: {
    display: 'flex',
    alignItems: 'center',
    visibility: 'hidden',
    color: palette.gray[500],
    backgroundColor: palette.white[500],
    fontSize: 16,
    lineHeight: 1,
    zIndex: 1,
  },
  dragWrapper: {
    alignItems: 'center',
    fontSize: 16,
    color: palette.gray[400],
    zIndex: 1,
  },
  dragIcon: {
    visibility: 'hidden',
    position: 'relative',
    top: -1,
    marginRight: 8,
    '&:hover': {
      cursor: 'grab',
    },
  },
  warningIcon: {
    position: 'relative',
    top: 1,
    color: palette.red[500],
    cursor: 'pointer',
    marginRight: 8,
  },
  fullSize: {
    position: 'absolute',
    right: 10,
    top: 5,
    fontSize: 18,
  },
  iconAction: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: palette.blue[500],
    },
  },
  wrapperFullSize: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: palette.white[500],
    borderRadius: 4,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
    padding: 12,
  },
  topWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    padding: '0 8px',
  },
  iconLeft: {
    flex: 1,
    justifyContent: 'start',
    display: 'flex',
  },
  iconRight: {
    flex: 1,
    justifyContent: 'end',
    display: 'flex',
  },
  labelWrapper: {
    display: 'flex',
    flex: 2,
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  },
  ellipsedTitle: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  iconsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const TileHeader = ({
  dashboardData,
  tileData,
  isEditableMode,
  nameMeasure,
  matchUrl,
  formValues,
  commonValues,
  isAnonymous,
  isOwnerUser,
  validationMessage,
  compositeErrorsMap,
  isCompositeDataLoading,
  titlePostfix,
}: PropTypes) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const tileId = get(tileData, 'id');
  const handleFullSize = useCallback(() => {
    history.push(`${matchUrl}/${tileId}/fullsize`);
  }, [matchUrl, tileId]);

  const computedTileOptions = useMemo(
    () =>
      tileActions.map((option) => {
        if (isAnonymous || (!isOwnerUser && !allowedUserTileActions.includes(option.value))) {
          return {...option, disabled: true};
        }
        return option;
      }),
    [isOwnerUser, isAnonymous],
  );

  const optionsGraphTile = useMemo(() => {
    const isMultipleExpressionBuilder =
      Object.keys(get(tileData, 'lineChart.expressionTrees', EMPTY_OBJECT)).length > 1;
    const isAlertCreated = dashboardData.tileAlerts.some((a) => a.tileId === tileData.id);
    const computedOptions =
      isMultipleExpressionBuilder || isAlertCreated
        ? computedTileOptions.filter((o) => o.value !== OPTION_TILES_VALUES.CREATE_ALERT)
        : computedTileOptions;

    return computedOptions.map((o) => {
      if (o.value === OPTION_TILES_VALUES.SHOW_LEGEND) {
        return {
          ...o,
          label: get(tileData, 'lineChart.legend.enabled') ? 'Hide Legend' : 'Show Legend',
        };
      }

      if (validationMessage && o.value === OPTION_TILES_VALUES.CREATE_ALERT) {
        return {
          ...o,
          disabled: true,
        };
      }

      return o;
    });
  }, [validationMessage, computedTileOptions, dashboardData, tileData]);

  const expressionTrees = useMemo(() => {
    return tileData.lineChart?.expressionTrees || tileData.andtGauge?.expressionTrees;
  }, [tileData]);

  const tileType = get(tileData, 'andtGauge.displayProperties.byTreeExp[0].options.andtGauge.mainValue.tileType', '');
  const isPieOrDonut = ['pie', 'donut'].includes(tileType);
  const isLegendEnabled = get(tileData, 'andtGauge.legend.enabled');
  const classes = useStyles({isPieOrDonut});

  const optionsStatTile = useMemo(() => {
    const isAlertCreated = dashboardData.tileAlerts.some((a) => a.tileId === tileId);
    const computedOptions = isAlertCreated
      ? computedTileOptions.filter((o) => o.value !== OPTION_TILES_VALUES.CREATE_ALERT)
      : computedTileOptions;

    return computedOptions
      .filter((o) => o.value !== OPTION_TILES_VALUES.SHOW_LEGEND || isPieOrDonut)
      .map((o) => {
        let updated = o;
        if (o.value === OPTION_TILES_VALUES.SHOW_LEGEND) {
          updated = {
            ...o,
            label: isLegendEnabled ? 'Hide Legend' : 'Show Legend',
          };
        }

        if (isAnonymous || (validationMessage && o.value === OPTION_TILES_VALUES.CREATE_ALERT)) {
          return {
            ...updated,
            disabled: true,
          };
        }
        return updated;
      });
  }, [isAnonymous, validationMessage, computedTileOptions, dashboardData, tileId, isPieOrDonut, isLegendEnabled]);

  const onTileAction = useCallback(
    (option) => {
      if (option.value === OPTION_TILES_VALUES.SHOW_LEGEND) {
        const dataKey = isPieOrDonut ? 'andtGauge' : 'lineChart';
        dispatch(
          setDashboardData({
            ...dashboardData,
            tiles: dashboardData.tiles.map((tile) => {
              if (tile.id === tileId) {
                return {
                  ...tile,
                  [dataKey]: {
                    ...tile[dataKey],
                    legend: {
                      ...tile[dataKey].legend,
                      enabled: !tile[dataKey].legend.enabled,
                    },
                  },
                };
              }
              return tile;
            }),
          }),
        );
      }
      if (option.value === OPTION_TILES_VALUES.SHOW_ME) {
        if (expressionTrees) {
          window.sessionStorage.setItem(
            'metricExplorerTree',
            JSON.stringify(Object.values(expressionTrees).map((treeModel) => treeModel.expressionTree)),
          );
        }
        window.open('/#!/r/metrics-explorer');
      }
      if (option.value === OPTION_TILES_VALUES.EXPORT_TO_CSV) {
        if (tileId) {
          const currentChart = Highcharts.charts.find((chart) => chart?.renderTo.id.includes(tileId.toString()));
          if (currentChart) {
            let csvContent = currentChart.getCSV();
            currentChart.series.forEach((s) => {
              const newName = s.name.replace(new RegExp('"', 'g'), '""');
              const seriesNameRegExp = new RegExp(escapeRegExp(s.name), 'g');
              csvContent = csvContent.replace(seriesNameRegExp, newName);
            });

            const csvWithTitleContent = `Dashboard Name: ${dashboardData.name}, ${
              tileData.title.text ? `Tile Name: ${tileData.title.text}` : ''
            }\n\n ${csvContent}`;

            const a = document.createElement('a');
            a.href = URL.createObjectURL(new Blob([csvWithTitleContent], {type: 'text/csv;charset=utf-8'}));
            a.download = `${currentChart.renderTo.id}__Dashboard_${dashboardData.name}${
              tileData.title.text ? `__Tile_${tileData.title.text}` : ''
            }.csv`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }
      } else {
        history.push(`${matchUrl}/${tileId}/${option.value}${history.location.search}`);
      }
    },
    [dashboardData, tileId, history, isPieOrDonut, expressionTrees],
  );

  let options = tileData.lineChart ? optionsGraphTile : optionsStatTile;

  if (tileData.tileType) {
    // TODO: update in AL-11366
    options = options.filter((o) => ['duplicate', 'settings', 'delete'].includes(o.value));
  }

  const getLeftIcons = () => {
    return (
      <div className={classes.iconsWrapper}>
        {isEditableMode && (
          <div className={`${classes.dragWrapper} bgcolor_white-500 dragHandlerClassName`}>
            <i className={`icon icn-general16-draghandle ${classes.dragIcon} ${isEditableMode ? 'editMode' : ''}`} />
          </div>
        )}
        {!isEditableMode && (
          <Fragment>
            {isCompositeDataLoading && (
              <div className="mr_1">
                <Spinner size={24} />
              </div>
            )}
            {validationMessage && (
              <div className={classes.warningIcon}>
                <WarningNavigationIcon tileId={tileData.id} compositeErrorsMap={compositeErrorsMap} />
              </div>
            )}
            {!isAnonymous && <AlertBellContainer dashboardData={dashboardData} tileId={tileData.id} />}
            <div className="mr_1">
              <FilterDDL
                dashboardData={dashboardData}
                tileId={tileId}
                formValues={formValues}
                commonValues={commonValues}
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  };

  const getRightIcons = () => {
    return (
      <div className={classes.iconsWrapper}>
        <div className={`${classes.editWrapper} editWrapper`}>
          <div className={`${classes.iconAction} mr_1`}>
            <Tooltip content="Fullscreen" type={TYPES.SMALL} placement="top">
              <i className="icon icn-general16-fullscreen" onClick={handleFullSize} />
            </Tooltip>
          </div>
          <SelectAndt
            automationId="dashboardTileEdit"
            type={TYPE_SIMPLE}
            theme={THEME_TRANSPARENT}
            direction={DIRECTION_LEFT}
            options={options}
            onChange={onTileAction}
            optionHeight={40}
            menuWidth={200}
            customComponent={{
              Option: OptionWithIcon,
              DropdownIndicator: (p) => (
                <div
                  className={`${classes.iconAction} display_flex alignItems_center`}
                  color={p.selectProps.menuIsOpen ? 'blue.500' : undefined}
                >
                  <i {...p.innerProps} className="icon icn-nav16-settings" />
                </div>
              ),
            }}
            closeMenuOnSelect={false}
            appendToBody={false}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.topWrapper}>
      <div className={classes.iconLeft}>{getLeftIcons()}</div>
      <div className={classes.labelWrapper}>
        <div className={classes.ellipsedTitle}>
          <TileHeadline nameMeasure={nameMeasure} title={tileData.title.text} />
        </div>
        {titlePostfix}
      </div>
      <div className={classes.iconRight}>{getRightIcons()}</div>
    </div>
  );
};

export default React.memo(TileHeader);
