// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  fetchCoralogixLabels as fetchCoralogixLabelsAction,
  fetchCoralogixMeasures as fetchCoralogixMeasuresAction,
  fetchCoralogixMetrics as fetchCoralogixMetricsAction,
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
} from 'bc/store/actions';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {get} from 'lodash';

import './CoralogixData.module.scss';

type PropTypes = {
  dataStream: Object,
  isLoadingMetrics: Boolean,
  metrics: Object,

  // connect
  fetchCoralogixMetrics: Function,
  fetchCoralogixLabels: Function,
  fetchCoralogixMeasures: Function,
  setSelectedStreamKeyVal: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoadingMetrics: selectors.getCoralogixStreamMetricsIsLoading(state),
    metrics: selectors.getCoralogixStreamMetricsItems(state),
    labels: selectors.getCoralogixStreamLabelsItems(state),
  }),
  {
    fetchCoralogixMetrics: fetchCoralogixMetricsAction,
    fetchCoralogixLabels: fetchCoralogixLabelsAction,
    fetchCoralogixMeasures: fetchCoralogixMeasuresAction,
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
  },
)(
  class CoralogixData extends React.PureComponent {
    props: PropTypes;

    // eslint-disable-next-line react/sort-comp
    _isMounted = false;

    // eslint-disable-next-line react/sort-comp
    _gotLabels = false;

    componentDidMount() {
      const {dataStream, fetchCoralogixMetrics} = this.props;

      if (!this._isMounted) {
        const payload = {
          type: dataStream.type,
          dataSourceId: dataStream.dataSourceId,
        };
        fetchCoralogixMetrics(payload);
        this._isMounted = true;
      }
    }

    componentDidUpdate() {
      const {dataStream, fetchCoralogixLabels, fetchCoralogixMeasures} = this.props;

      if (!this._gotLabels && get(dataStream, 'metricName') && !isEditRunning(dataStream.id)) {
        fetchCoralogixLabels({
          type: dataStream.type,
          dataSourceId: dataStream.dataSourceId,
          metricName: dataStream.metricName,
        });
        fetchCoralogixMeasures({
          type: dataStream.type,
          dataSourceId: dataStream.dataSourceId,
          metricName: dataStream.metricName,
        });
        this._gotLabels = true;
      }
    }

    metricChanged = (selectedMetric) => {
      const {dataStream, fetchCoralogixLabels, fetchCoralogixMeasures} = this.props;

      if (selectedMetric !== dataStream.metricName) {
        this.props.setSelectedStreamKeyVal({
          ...dataStream,
          dimensions: [],
          metrics: [],
          metricName: selectedMetric,
          schema: {
            columns: [],
            sourceColumns: [],
          },
          uiState: {
            ...dataStream.uiState,
            unAssignedColumns: [],
            uiFilters: [
              {
                label: '',
                selectedValue: [],
                isInclude: true,
              },
            ],
          },
        });
        fetchCoralogixLabels({
          type: dataStream.type,
          dataSourceId: dataStream.dataSourceId,
          metricName: selectedMetric,
        });
        fetchCoralogixMeasures({
          type: dataStream.type,
          dataSourceId: dataStream.dataSourceId,
          metricName: selectedMetric,
        });
      }
    };

    render() {
      const {dataStream, metrics, isLoadingMetrics} = this.props;
      const isEditable = isStreamEditable(dataStream.state);
      const metric = dataStream.metricName;
      const selectedIndex = metrics.findIndex((val) => metric === val);

      return (
        <div className="shell-col" styleName="root">
          <PanelHeader title="Coralogix Metric" isEditable={isEditable} />

          <div styleName="block">
            <div styleName="select-block">
              <SelectAndt
                id="coralogixMetricSelect"
                automationId="coralogixMetricSelect"
                options={metrics}
                getOptionLabel={(val) => val}
                getOptionValue={(val) => val}
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_NOT_HIGHLIGHTED}
                value={isLoadingMetrics ? null : metrics[selectedIndex]}
                onChange={(e) => this.metricChanged(e, 'metric')}
                placeholder={isLoadingMetrics ? 'Loading...' : 'Choose a Metric'}
                disabled={!isEditable || isLoadingMetrics}
                optionHeight={40}
                buttonWidth={340}
                isClearable={false}
              />
            </div>
          </div>
        </div>
      );
    }
  },
);
