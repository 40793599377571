// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setParquetStreamDiametricsChange as setParquetStreamDiametricsChangeAction,
  setParquetStreamDiametricsTimeDefinition as setParquetStreamDiametricsTimeDefinitionAction,
  removeParquetStreamDiametrics as removeParquetStreamDiametricsAction,
  setParquetStreamClearAllDiametrics as setParquetStreamClearAllDiametricsAction,
  setStreamDiametricsEditorFilterTextInput as setStreamDiametricsEditorFilterTextInputAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import DimensionsAndMetricsEditor from 'bc/components/streams/editor/common/DimensionsAndMetricsEditor';
// import S3FilePreview from '../s3Data/S3FilePreview';

type PropTypes = {
  dimensions: Array<Object>,
  metrics: Array<Object>,
  filteredUnassignedColumns: Array<Object>,
  timeDefinitionArr: Array,
  timeDefinition: Object,
  filterTextInput: String,
  setParquetStreamDiametricsChange: Function,
  setParquetStreamClearAllDiametrics: Function,
  setParquetStreamDiametricsTimeDefinition: Function,
  removeParquetStreamDiametrics: Function,
  setStreamDiametricsEditorFilterTextInput: Function,
  dateFormats: Array<Object>,
  isStreamUpdating: Boolean,
};

export default connect(
  (state) => ({
    dimensions: selectors.getParquetStreamSelectedDimensions(state),
    metrics: selectors.getParquetStreamSelectedMetrics(state),
    timeDefinition: selectors.getParquetStreamSelectedTimeDefinitionObj(state),
    timeDefinitionArr: selectors.getParquetStreamSelectedTimeDefinitionColArr(state),
    filteredUnassignedColumns: selectors.getFilteredParquetStreamUnAssignedColumns(state),
    filterTextInput: selectors.getStreamMetricsDimentionsFilterTextInput(state),
    dateFormats: selectors.getDataStreamsDateFormats(state),
    isStreamUpdating: selectors.getIsDataStreamUpdating(state),
  }),
  {
    setParquetStreamDiametricsChange: setParquetStreamDiametricsChangeAction,
    setParquetStreamClearAllDiametrics: setParquetStreamClearAllDiametricsAction,
    setParquetStreamDiametricsTimeDefinition: setParquetStreamDiametricsTimeDefinitionAction,
    removeParquetStreamDiametrics: removeParquetStreamDiametricsAction,
    setStreamDiametricsEditorFilterTextInput: setStreamDiametricsEditorFilterTextInputAction,
  },
)(
  class ParquetDimensionsAndMetricsEditor extends React.PureComponent {
    props: PropTypes;

    render() {
      const {
        metrics,
        dimensions,
        setParquetStreamDiametricsTimeDefinition,
        filterTextInput,
        filteredUnassignedColumns,
        timeDefinition,
        timeDefinitionArr,
        removeParquetStreamDiametrics,
        setStreamDiametricsEditorFilterTextInput,
        setParquetStreamDiametricsChange,
        setParquetStreamClearAllDiametrics,
        dateFormats,
        isStreamUpdating,
      } = this.props;

      return (
        <DimensionsAndMetricsEditor
          dimensions={dimensions}
          metrics={metrics}
          filteredUnassignedColumns={filteredUnassignedColumns}
          timeDefinitionArr={timeDefinitionArr}
          timeDefinition={timeDefinition}
          filterTextInput={filterTextInput}
          setStreamDiametricsChange={setParquetStreamDiametricsChange}
          setStreamClearAllDiametrics={setParquetStreamClearAllDiametrics}
          setStreamTimeDefinition={setParquetStreamDiametricsTimeDefinition}
          removeStreamDiametrics={removeParquetStreamDiametrics}
          setMetricDimentionEditorFilterTextInput={setStreamDiametricsEditorFilterTextInput}
          dateFormats={dateFormats}
          isStreamUpdating={isStreamUpdating}
        />
      );
    }
  },
);
