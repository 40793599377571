import {get} from 'lodash';

export function getQueryParamsUrl(params = {}, url = '') {
  const format = (str, key) => {
    if (params[key]) {
      return `${str + (str === url ? '?' : '&')}${key}=${params[key]}`;
    }
    return str;
  };

  return Object.keys(params).reduce(format, url);
}

export function makeAnomalyTimeSeriesParams(anomaly, {baseline = false, datapoints = true, timeInterval}) {
  const {resolution, startDate, endDate, id, metrics = []} = anomaly;
  const metricId = metrics[0]?.id;
  const anomalyDuration = endDate - startDate;
  const endOfRequestedPeriod = Math.floor((timeInterval?.endDate || Date.now()) / 1000);
  const sinceAnomaly = endOfRequestedPeriod - endDate;
  const margin = Math.max(anomalyDuration * 11, sinceAnomaly * 3);
  const marginedStartDate = Math.max(Math.floor(timeInterval.startDate / 1000), startDate - margin);
  const marginedEndDate = Math.min(Math.floor(timeInterval.endDate / 1000), endDate + margin);

  return {
    anomalyId: id,
    startDate: marginedStartDate,
    endDate: marginedEndDate,
    resolution,
    metricId,
    startBucketMode: true,
    baseline,
    datapoints,
  };
}

export function getOptions(url, payload, params) {
  const fullUrl = params ? getQueryParamsUrl(params, url) : url;
  return payload
    ? {
        url: fullUrl,
        method: 'POST',
        data: JSON.stringify(payload),
      }
    : {
        url: fullUrl,
        method: 'GET',
      };
}

export function getQ(metric, filters = [], stringify, notOperator = false) {
  const expression: any[] = [];
  if (metric && metric !== '*') {
    expression.push({
      type: 'property',
      key: 'what',
      value: (notOperator ? '!' : '') + metric,
      isExact: !notOperator,
    });
  }
  const q = {
    expression: [...expression, ...filters],
  };
  return stringify ? JSON.stringify(q) : q;
}

export const getMeasureNameAndExpression = (simpleExpressionTree = {}) => {
  const expression = get(simpleExpressionTree, 'expressionTree.root.children[0].searchObject.expression', []);
  const measureName = expression.find((exp) => exp.key === 'what')?.value;
  return {measureName, expression};
};
