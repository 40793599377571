import {useCallback} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/';

const getSources = () => get(`${root}data-sources`);

export default function useFetchSources() {
  // const enableEventsStreams = useSelector(getEnableEventsStreams);
  const formatDataSource = useCallback((data) => data.map((i) => ({...i, label: i.name, value: i.id})));

  const fallback = [];
  const {data: sources = fallback, isLoading: isSourcesLoading} = useQuery(QUERY_KEYS.sources, getSources, {
    select: formatDataSource,
  });

  return {
    sources,
    isSourcesLoading,
  };
}

export function useFetchSource(id) {
  const selectFn = useCallback(
    (data) => {
      return data.find((i) => i.id === id);
    },
    [id],
  );

  const fallback = {};
  const {
    data: source = fallback,
    isLoading: isSourceLoading,
    isFetching: isSourceFetching,
    isSuccess: isSourceSuccess,
  } = useQuery(QUERY_KEYS.sources, getSources, {
    enabled: !!id,
    select: selectFn,
  });

  return {
    source,
    isSourceLoading,
    isSourceFetching,
    isSourceSuccess,
  };
}

export function usePrefetchSources() {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery(QUERY_KEYS.sources, getSources);
}
