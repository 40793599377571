// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import * as filterSelector from 'admin.users/store/filterSelector';

const EMPTY_ARRAY = [];

type PropTypes = {
  setQueryParams: Function,

  // connect
  roleTypes?: Array,
  selectedUserRoles: Array,
};

export default connect(
  (state) => ({
    roleTypes: filterSelector.getFilterUserRolesWithCount(state),
    selectedUserRoles: filterSelector.getSelectedFilterRoles(state),
  }),
  {},
)(
  class UGRolesFilter extends React.PureComponent {
    props: PropTypes;

    static defaultProps = {
      roleTypes: EMPTY_ARRAY,
    };

    onChange = (items) => {
      const rolesVal = items.length ? items.map((item) => item.role).join(',') : null;
      this.props.setQueryParams({roles: rolesVal});
    };

    render() {
      const {roleTypes, selectedUserRoles} = this.props;

      return (
        <SelectAndt
          automationId="usersRolesFilter"
          type={TYPE_NEW_MULTI_NO_SEARCH}
          theme={THEME_HIGHLIGHTED}
          onChange={this.onChange}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.role}
          value={selectedUserRoles}
          options={roleTypes}
          isMulti
          optionHeight={40}
          isClearable={false}
          closeMenuOnSelect={false}
          menuWidth={230}
          placeholder="Role"
          noAutoReorder
        />
      );
    }
  },
);
