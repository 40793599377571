// @flow
import React from 'react';
import {connect} from 'react-redux';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import * as selectors from 'bc/store/selectors';
import {
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
  fetchNewRelicAccounts as fetchNewRelicAccountsAction,
  fetchNewRelicKeyset as fetchNewRelicKeysetAction,
} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import NewrelicPreview from './NewrelicPreview';

import '../NewRelicStreamEditor.module.scss';

type PropTypes = {
  dataStream: Object,

  // connect
  setSelectedStreamKeyVal: Function,
  fetchNewRelicAccounts: Function,
  fetchNewRelicKeyset: Function,
  accounts: Array,
  accountsIsLoading: Boolean,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    accounts: selectors.getNewRelicStreamAccountsItems(state),
    accountsIsLoading: selectors.getNewRelicStreamAccountsIsLoading(state),
  }),
  {
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
    fetchNewRelicAccounts: fetchNewRelicAccountsAction,
    fetchNewRelicKeyset: fetchNewRelicKeysetAction,
  },
)(
  class NewRelicData extends React.PureComponent {
    props: PropTypes;

    state = {
      accountId: this.props.dataStream.accountId || null,
      eventType: this.props.dataStream.eventType || null,
      isOpenFilePreviewModal: false,
    };

    componentDidMount() {
      const {dataStream} = this.props;

      if (isEditRunning(dataStream.id)) {
        this.props.fetchNewRelicKeyset({
          dataSourceId: dataStream.dataSourceId,
          accountId: this.state.accountId,
          eventType: this.state.eventType,
        });
      } else {
        this.props.fetchNewRelicAccounts({
          dataSourceId: dataStream.dataSourceId,
        });
      }
    }

    previewClicked = () => {
      const {isOpenFilePreviewModal} = this.state;
      this.setState({isOpenFilePreviewModal: !isOpenFilePreviewModal});
    };

    onChangeAccount = (val) => {
      this.setState({accountId: val, eventType: null});
    };

    onChangeEvent = (val) => {
      this.setState({eventType: val});
    };

    onSubmit = () => {
      this.props.setSelectedStreamKeyVal({
        accountId: this.state.accountId,
        eventType: this.state.eventType,
      });
      this.props.fetchNewRelicKeyset({
        dataSourceId: this.props.dataStream.dataSourceId,
        accountId: this.state.accountId,
        eventType: this.state.eventType,
      });
    };

    render() {
      const {dataStream, accounts, accountsIsLoading} = this.props;
      const {accountId, eventType} = this.state;
      const isEditable = isStreamEditable(dataStream.state);
      const isPreview =
        this.state.accountId &&
        this.state.eventType &&
        this.state.accountId === this.props.dataStream.accountId &&
        this.state.eventType === this.props.dataStream.eventType;

      const selectedAccountIndex = accounts.findIndex((val) => val.id === accountId);
      const reportingTypes = accounts[selectedAccountIndex]
        ? accounts[selectedAccountIndex].reportingEventTypes.map((val) => {
            return {name: val, id: val};
          })
        : [];
      const selectedReportingTypeIndex = reportingTypes.findIndex((val) => val.id === eventType);

      return (
        <div className="shell-col" styleName="root">
          <PanelHeader title="Table Data" isEditable={isEditable}>
            <button
              type="button"
              className="btn btn-icon btn-icon btn-dark-blue"
              automation-id="fileUploadDataPreviewButton"
              disabled={!isPreview}
              onClick={this.previewClicked}
            >
              <i className="icon icn-view-icon" />
            </button>
          </PanelHeader>

          {this.state.isOpenFilePreviewModal && (
            <NewrelicPreview isOpen={this.state.isOpenFilePreviewModal} onClose={this.previewClicked} />
          )}

          <div styleName="block">
            <SelectAndt
              type={TYPE_NO_SEARCH}
              theme={THEME_LIGHT}
              onChange={(item) => this.onChangeAccount(item.id)}
              options={accounts}
              value={accounts[selectedAccountIndex]}
              placeholder="Account"
              optionHeight={40}
              buttonWidth={200}
              disabled={accountsIsLoading}
              getOptionLabel={(val) => val.name}
              getOptionValue={(val) => val.id}
              automationId="newRelicAccountDdl"
            />
          </div>

          <div styleName="block">
            <SelectAndt
              type={TYPE_NO_SEARCH}
              theme={THEME_LIGHT}
              onChange={(item) => this.onChangeEvent(item.id)}
              options={reportingTypes}
              value={reportingTypes[selectedReportingTypeIndex]}
              placeholder="Report Type"
              optionHeight={40}
              buttonWidth={200}
              disabled={accountsIsLoading || selectedAccountIndex < 0}
              getOptionLabel={(val) => val.name}
              getOptionValue={(val) => val.id}
              automationId="newRelicAccountDdl"
            />
          </div>

          <dic styleName="block button">
            <button
              type="button"
              className="btn btn-raised"
              styleName="btn-compose"
              automation-id="bigQueryComposeQueryButton"
              disabled={selectedAccountIndex === -1 || selectedReportingTypeIndex === -1}
              onClick={this.onSubmit}
            >
              Collect
            </button>
          </dic>
        </div>
      );
    }
  },
);
