import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import DataMapperItem from 'dataCollectors/components/dataMapper/DataMapperItem';
import {EVENT_STREAM_FIELDS, EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';
import InfoBox from 'dataCollectors/components/infoBox/InfoBox';
import useSectionsClasses from 'dataCollectors/styles/classes.useSectionsClasses';
import TimeSettingsBtn from 'dataCollectors/components/dataMapper/TimeSettingsBtn';
import useDataMapper from './hook/useDataMapper';

const useStyles = makeStyles(({typography, palette}) => ({
  titleEventsWrapper: {
    ...typography.subtitle3,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
    marginBottom: 20,
    width: 'calc(50% - 25px)',
    '&>div': {
      display: 'flex',
      justifyContent: 'space-between',
      width: ({leftSideWidth}) => leftSideWidth,
    },
  },
  clearAllBtn: {
    color: palette.blue['500'],
    cursor: 'pointer',
  },
}));

const {sections} = useSectionsClasses();

const {
  TYPE,
  SOURCE_ID,
  SCHEMA_NAME,
  TABLE_NAME,
  DATA_SET,
  PROJECT_ID,
  EVENT_TITLE,
  EVENT_DESCRIPTION,
  EVENT_TYPE,
  EVENT_ACTION,
  EVENT_START_TIME,
  EVENT_END_TIME,
  EVENT_PROGRESS_INDICATOR,
  EVENT_PROPERTIES,
  EVENT_KEY,
  END_TIME_DEFINITION,
  START_TIME_DEFINITION,
} = EVENT_STREAM_FIELDS;

const {LOCAL_FILE, BIG_QUERY} = EVENT_STREAM_TYPES;

const EVENTS_FIELDS_ARR = [
  EVENT_TITLE.id,
  EVENT_DESCRIPTION.id,
  EVENT_TYPE.id,
  EVENT_ACTION.id,
  EVENT_START_TIME.id,
  EVENT_END_TIME.id,
  EVENT_PROGRESS_INDICATOR.id,
  EVENT_PROPERTIES.id,
  EVENT_KEY.id,
];

const DataMapper = ({info, leftSideWidth}) => {
  const localClasses = useStyles({leftSideWidth});
  const sectionsClasses = sections({leftSideWidth});

  const {
    setValue,
    getValues,
    formState: {errors, touchedFields},
  } = useFormContext();

  const stream = getValues();

  const {metadata, isMetadataFetching, refetchMetadataFetching} = useDataMapper(stream);

  const disabled = (!stream[TABLE_NAME.id] && stream[TYPE.id] !== LOCAL_FILE) || isMetadataFetching;

  const [eventsCounter, setEventsCounter] = useState(0);

  const [sortedMetaData, setSortedMetaData] = useState([]);

  const countFilledEvent = () => {
    let counter = 0;

    EVENTS_FIELDS_ARR.forEach((i) => {
      counter += stream[i] ? 1 : 0;
    });

    setEventsCounter(counter);
  };

  const setMulti = (items) => {
    const obj = {};

    items.forEach((i) => {
      const name = i.label;
      obj[name] = {sourceColumn: i.value};
    });

    return obj;
  };

  const onChange = (eventType, items, isMulti) => {
    if (isMulti) {
      setValue(eventType, setMulti(items), {shouldTouch: true});
    } else {
      setValue(eventType, {sourceColumn: items.value}, {shouldTouch: true});

      if (stream[TYPE.id] === LOCAL_FILE && eventType === EVENT_START_TIME.id) {
        let startTime = getValues(START_TIME_DEFINITION.id) || {};
        startTime = {...startTime, timeColumnIdx: Number(items.value)};

        setValue(START_TIME_DEFINITION.id, startTime);
      } else if (stream[TYPE.id] === LOCAL_FILE && eventType === EVENT_END_TIME.id) {
        let endTime = getValues(END_TIME_DEFINITION.id) || {};
        endTime = {...endTime, timeColumnIdx: Number(items.value)};

        setValue(END_TIME_DEFINITION.id, endTime);
      }
    }
  };

  const onClear = (eventType) => {
    setValue(eventType, null, {shouldTouch: true});
  };

  const clearAllEvents = () => {
    EVENTS_FIELDS_ARR.forEach((i) => {
      onClear(i);
    });
  };

  useEffect(() => {
    countFilledEvent();
  }, [stream]);

  useEffect(() => {
    if (isMetadataFetching) return;
    const sortedData = [...metadata];
    setSortedMetaData(sortedData.sort((a, b) => (a.type > b.type ? 1 : -1)));
  }, [isMetadataFetching, metadata]);

  useEffect(() => {
    if (touchedFields[TABLE_NAME.id] || touchedFields[DATA_SET.id]) {
      clearAllEvents();
    }
    if (
      stream[TYPE.id] &&
      stream[SOURCE_ID.id] &&
      ((stream[TYPE.id] !== BIG_QUERY && stream[SCHEMA_NAME.id] && stream[TABLE_NAME.id]) ||
        (stream[TYPE.id] === BIG_QUERY && stream[PROJECT_ID.id] && stream[DATA_SET.id] && stream[TABLE_NAME.id]))
    ) {
      refetchMetadataFetching({cancelRefetch: true});
    }
  }, [stream[TABLE_NAME.id], stream[DATA_SET.id]]);

  return (
    <div className={sectionsClasses.root}>
      <div className={sectionsClasses.container}>
        <div className={sectionsClasses.left}>
          <div className={sectionsClasses.title}>Event Field Mapper</div>
          <div className={sectionsClasses.subTitle}>Map the fields to the relevant place in the user event.</div>
          <div className={localClasses.dataMapperWrapper}>
            <div className={localClasses.titleEventsWrapper}>
              <span>Your data ({eventsCounter})</span>
              <span className={localClasses.clearAllBtn} onClick={clearAllEvents}>
                Clear
              </span>
            </div>

            <DataMapperItem
              options={metadata}
              eventType={EVENT_TITLE.id}
              selectedItem={stream[EVENT_TITLE.id]}
              onChange={onChange}
              onClear={onClear}
              disabled={disabled}
              isLoading={isMetadataFetching}
              name={EVENT_TITLE.name}
              errorMsg={errors[EVENT_TITLE.id]}
              isMandatory
            />
            <DataMapperItem
              options={metadata}
              eventType={EVENT_DESCRIPTION.id}
              selectedItem={stream[EVENT_DESCRIPTION.id]}
              onChange={onChange}
              onClear={onClear}
              disabled={disabled}
              isLoading={isMetadataFetching}
              name={EVENT_DESCRIPTION.name}
            />
            <DataMapperItem
              options={metadata}
              eventType={EVENT_TYPE.id}
              selectedItem={stream[EVENT_TYPE.id]}
              onChange={onChange}
              onClear={onClear}
              disabled={disabled}
              isLoading={isMetadataFetching}
              name={EVENT_TYPE.name}
            />
            <DataMapperItem
              options={metadata}
              eventType={EVENT_ACTION.id}
              selectedItem={stream[EVENT_ACTION.id]}
              onChange={onChange}
              onClear={onClear}
              disabled={disabled}
              isLoading={isMetadataFetching}
              name={EVENT_ACTION.name}
            />

            <DataMapperItem
              options={sortedMetaData}
              eventType={EVENT_START_TIME.id}
              selectedItem={stream[EVENT_START_TIME.id]}
              onChange={onChange}
              onClear={onClear}
              disabled={disabled}
              isLoading={isMetadataFetching}
              name={EVENT_START_TIME.name}
              errorMsg={errors[EVENT_START_TIME.id]}
              isMandatory
            />
            {stream[TYPE.id] === LOCAL_FILE && stream[EVENT_START_TIME.id] && (
              <TimeSettingsBtn
                value={stream[START_TIME_DEFINITION.id]}
                name={START_TIME_DEFINITION.id}
                sourceColumn={EVENT_START_TIME.id}
                isLoading={isMetadataFetching}
                errorMsg={errors[START_TIME_DEFINITION.id]}
                isMoreSpace={!!errors[EVENT_START_TIME.id]}
              />
            )}
            <DataMapperItem
              options={sortedMetaData}
              eventType={EVENT_END_TIME.id}
              selectedItem={stream[EVENT_END_TIME.id]}
              onChange={onChange}
              onClear={onClear}
              disabled={disabled}
              isLoading={isMetadataFetching}
              name={EVENT_END_TIME.name}
            />
            {stream[TYPE.id] === LOCAL_FILE && stream[EVENT_END_TIME.id] && (
              <TimeSettingsBtn
                value={stream[END_TIME_DEFINITION.id]}
                name={END_TIME_DEFINITION.id}
                sourceColumn={EVENT_END_TIME.id}
                isLoading={isMetadataFetching}
                errorMsg={errors[END_TIME_DEFINITION.id]}
                isMoreSpace={!!errors[EVENT_END_TIME.id]}
              />
            )}

            <DataMapperItem
              isMulti
              options={metadata}
              eventType={EVENT_PROPERTIES.id}
              selectedItem={stream[EVENT_PROPERTIES.id]}
              onChange={onChange}
              onClear={onClear}
              disabled={disabled}
              isLoading={isMetadataFetching}
              name={EVENT_PROPERTIES.name}
            />
            <DataMapperItem
              options={metadata}
              eventType={EVENT_KEY.id}
              selectedItem={stream[EVENT_KEY.id]}
              onChange={onChange}
              onClear={onClear}
              disabled={disabled}
              isLoading={isMetadataFetching}
              name={EVENT_KEY.name}
              isReuseable
            />
            {stream[TYPE.id] !== LOCAL_FILE && (
              <DataMapperItem
                options={sortedMetaData}
                eventType={EVENT_PROGRESS_INDICATOR.id}
                selectedItem={stream[EVENT_PROGRESS_INDICATOR.id]}
                onChange={onChange}
                onClear={onClear}
                disabled={disabled}
                isLoading={isMetadataFetching}
                name={EVENT_PROGRESS_INDICATOR.name}
                errorMsg={errors[EVENT_PROGRESS_INDICATOR.id]}
                isMandatory
              />
            )}
          </div>
        </div>
        {info && <InfoBox title={info.title} body={info.body} />}
      </div>
    </div>
  );
};

DataMapper.defaultProps = {
  info: false,
  leftSideWidth: '100%',
};

DataMapper.propTypes = {
  info: PropTypes.bool,
  leftSideWidth: PropTypes.number,
};

export default DataMapper;
