// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {getCustomersData} from 'admin.customers/store/selectors';
import * as filterSelector from 'admin.users/store/filterSelector';

const EMPTY_ARRAY = [];

type PropTypes = {
  setQueryParams: Function,

  // connect
  customers?: Array,
  selectedCustomers: Array,
};

export default connect(
  (state) => ({
    customers: getCustomersData(state),
    selectedCustomers: filterSelector.getSelectedFilterCustomers(state),
  }),
  {},
)(
  class UGCustomerFilter extends React.PureComponent {
    props: PropTypes;

    static defaultProps = {
      customers: EMPTY_ARRAY,
    };

    onChange = (items) => {
      const customersVal = items.length ? items.map((item) => item._id).join(',') : null;
      this.props.setQueryParams({customers: customersVal});
    };

    render() {
      const {customers, selectedCustomers} = this.props;

      return (
        <SelectAndt
          automationId="usersCustomerFilter"
          type={TYPE_NEW_MULTI}
          theme={THEME_HIGHLIGHTED}
          onChange={this.onChange}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val._id}
          value={selectedCustomers}
          options={customers}
          isMulti
          optionHeight={40}
          isClearable={false}
          closeMenuOnSelect={false}
          menuWidth={230}
          placeholder="Customers"
        />
      );
    }
  },
);
