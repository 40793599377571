// @flow
/* eslint-disable */
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import {AutoSizer, List} from 'react-virtualized';
import {isAnodot} from 'profile/store/selectors';
import {getUsersIsLoading, getUsersListOrder} from 'admin.users/store/selectors';
import {getFilteredUserSectionUsersList, getAllUsersCheckboxState} from 'admin.users/store/filterSelector';
import {setUserListOrder, allUsersCheckboxClick as allUsersCheckboxClickAction} from 'admin.users/store/actions';
import {palette} from 'app/styles/theme';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import Checkbox from 'common/componentsV2/Checkbox';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import UsersTableRow from './UsersTableRow';
import './UsersTable2.module.scss';

type PropTypes = {
  // connect
  isMeAnodot: boolean,
  isLoading: boolean,
  users: Array<Object>,
  listOrder: Object,
  allUsersCheckboxState: String,
  setUserListOrder: Function,
  allUsersCheckboxClick: Function,
};

export default connect(
  (state) => ({
    isMeAnodot: isAnodot(state),
    isLoading: getUsersIsLoading(state),
    users: getFilteredUserSectionUsersList(state),
    listOrder: getUsersListOrder(state),
    allUsersCheckboxState: getAllUsersCheckboxState(state),
  }),
  {
    setUserListOrder,
    allUsersCheckboxClick: allUsersCheckboxClickAction,
  },
)(
  class Table extends PureComponent {
    props: PropTypes;

    state = {
      scrollBarWidth: 0,
      tableHeight: 500,
    };

    componentDidUpdate() {
      this.recalculateScrolbarWidth();
      if (this.list) {
        this.list.recomputeRowHeights(0);
      }
    }

    setListRef = (elm) => {
      this.list = elm;
    };

    handleOnCheck = (val) => {
      const {allUsersCheckboxClick} = this.props;
      allUsersCheckboxClick(val);
    };

    handleTitleOrderChange = (columnName) => {
      const {listOrder} = this.props;
      let updatedDirection = 'desc';

      if (columnName === listOrder.column) {
        updatedDirection = listOrder.direction === 'desc' ? 'asc' : 'desc';
      }
      this.props.setUserListOrder({column: columnName, direction: updatedDirection});
    };

    recalculateScrolbarWidth = () => {
      const listElm = document.getElementsByClassName('ReactVirtualized__List');
      const innerElm = document.getElementsByClassName('ReactVirtualized__Grid__innerScrollContainer');
      let scrollBarWidth = 0;
      if (listElm && listElm.length > 0 && innerElm && innerElm.length > 0) {
        scrollBarWidth = listElm[0].offsetWidth - innerElm[0].offsetWidth;
      }
      this.setState({scrollBarWidth});
    };

    handleResize = (width, height) => {
      this.setState({tableHeight: height - 82});
      this.recalculateScrolbarWidth();
    };

    rowRenderer = ({index, style}) => {
      const {users} = this.props;
      const user = users[index];

      return <UsersTableRow key={user.id} user={user} style={style} />;
    };

    render() {
      const {isMeAnodot, isLoading, users, listOrder} = this.props;
      const columns = [
        {id: 0, name: 'name', title: 'Name'},
        {id: 1, name: 'role', title: 'Role'},
        {id: 2, name: 'groups', title: 'Groups'},
        {id: 3, name: 'email', title: 'Email'},
        {id: 4, name: 'status', title: 'Status'},
        {id: 5, name: 'lastActive', title: 'Last Active'},
      ];

      if (isLoading) {
        return (
          <div styleName="spinner-wrapper">
            <Spinner color={palette.gray[500]} size={SpinnerSizes.XX_BIG_150} />
          </div>
        );
      }

      if (isMeAnodot) {
        columns[2] = {id: 9, name: 'organization', title: 'Organization'};
      }

      return (
        <div styleName="table">
          <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize} />
          <div styleName="table-header" style={{paddingRight: `${this.state.scrollBarWidth}px`}}>
            <div styleName="col-checkbox">
              <Checkbox
                threeStatesStatus={this.props.allUsersCheckboxState}
                onChange={this.handleOnCheck}
                automationId="userTableAllCheckbox"
              />
            </div>
            <div styleName="col-avatar" />
            {columns.map((col) => (
              <div styleName={`col-header col-${col.name}`} key={`user-list-${col.id}`}>
                <TitleHeaderTableOrder
                  title={col.title}
                  columnName={col.name}
                  onChange={this.handleTitleOrderChange}
                  selectedDirection={listOrder.direction}
                  isSortable
                  isActive={col.name === listOrder.column}
                />
              </div>
            ))}
          </div>

          <div className="shell-col">
            <AutoSizer disableHeight>
              {({width}) => (
                <List
                  ref={this.setListRef}
                  width={width}
                  height={this.state.tableHeight}
                  rowCount={users.length}
                  rowHeight={49}
                  rowRenderer={this.rowRenderer}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      );
    }
  },
);
/* eslint-enable */
