// @flow
import UsersRowHeader from 'admin.users/components/UsersTable/UsersRowHeader';
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {isAnodot} from 'profile/store/selectors';
import {getFiltersSearchQuery} from 'admin.users/store/filterSelector';
import {getSelectedUsersCheckbox} from 'admin.users/store/selectors';
import {singleUserCheckboxClick} from 'admin.users/store/actions';
import {noop} from 'lodash';
import UsersActionToolbar from './UsersActionToolbar';
import './UsersTable2.module.scss';

const NOOP = () => {};

type PropTypes = {
  style: Object,
  user: Object,

  // connect
  isMeAnodot: boolean,
  searchString: string,
  selectedUsersCheckbox: Array,
  singleUserCheckboxClick: Function,
};

export default connect(
  (state) => ({
    isMeAnodot: isAnodot(state),
    searchString: getFiltersSearchQuery(state),
    selectedUsersCheckbox: getSelectedUsersCheckbox(state),
  }),
  {
    singleUserCheckboxClick,
  },
)(
  class UsersTableRow extends PureComponent {
    props: PropTypes;

    state = {};

    handleCheckboxChange = (id) => () => {
      this.props.singleUserCheckboxClick(id);
    };

    isFindIdInCheckedItems = (id) => {
      const ret = this.props.selectedUsersCheckbox.find((a) => id === a);
      return !!ret;
    };

    render() {
      const {user, isMeAnodot, style, searchString, selectedUsersCheckbox} = this.props;
      return (
        <div styleName="table-row">
          <TableListItem
            key={user.id}
            style={style}
            headerComponent={<UsersRowHeader user={user} searchString={searchString} isAnodot={isMeAnodot} />}
            expandedPanel={NOOP}
            checkboxVisibility={
              selectedUsersCheckbox.length > 0
                ? CHECKBOX_VISIBILITY_TYPES.alwaysShow
                : CHECKBOX_VISIBILITY_TYPES.onHover
            }
            checkboxAutomationId={`userTableItem-${user.id}`}
            onCheckboxChange={this.handleCheckboxChange(user.id)}
            isCheckboxChecked={this.isFindIdInCheckedItems(user.id)}
            setSelectedItem={noop}
            isSelected={false}
            actionToolbarComponent={<UsersActionToolbar user={user} />}
          />
        </div>
      );
    }
  },
);
