// flow
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Transition} from 'react-transition-group';
import {Carousel} from 'react-responsive-carousel';
import {isEmpty} from 'lodash';
import {getNumLastDays} from 'insightsPanelNewAlertConsole/store/selectors';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {fadeOpacity, formatFeedbackPerMeasure} from 'insightsPanelNewAlertConsole/services/constants';
import PieChart from 'charts/pieChart/PieChart';
import {FEEDBACK} from 'charts/pieChart/pieChartSettingsService';
import {segmentClickEvent} from 'common/store/actions';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS, FEEDBACK_OPTIONS} from 'alerts.console.new/services/alertsConsoleService';
import {FEEDBACK_TYPE} from 'feedback/services/constants';
import fetchTriggeredAlertsFeedbackStatsProvider from 'insightsPanelNewAlertConsole/api/fetchTriggeredAlertsFeedbackStatsProvider';

import './FeedbackPerMeasure.module.scss';

const CAROUSEL_SETTINGS = {
  showIndicators: true,
  infiniteLoop: false,
  showThumbs: false,
  showStatus: false,
  transitionTime: 400,
};

const FeedbackPerMeasure = ({title, onFilterAlerts}: {title: String, onFilterAlerts: Function}) => {
  const numLastDays = useSelector(getNumLastDays);
  const dispatch = useDispatch();

  const feedbackPerMeasureDataProvider = fetchTriggeredAlertsFeedbackStatsProvider({
    numLastDays: numLastDays?.value,
  }).useQuery();
  const isLoading = feedbackPerMeasureDataProvider?.isLoading;

  const [showData, setShowData] = useState(false);

  const feedbackPerMeasureData = useMemo(() => {
    let feedbacksMeasures;
    if (!isEmpty(feedbackPerMeasureDataProvider?.data)) {
      feedbacksMeasures = formatFeedbackPerMeasure(feedbackPerMeasureDataProvider?.data);
    }
    return feedbacksMeasures;
  }, [feedbackPerMeasureDataProvider?.data]);

  useEffect(() => {
    if (feedbackPerMeasureData) {
      setShowData(true);
    }
  }, [feedbackPerMeasureData]);

  const segmentTracking = (name) => {
    dispatch(
      segmentClickEvent({
        category: 'Insights Panel',
        name,
      }),
    );
  };

  const clickOnMetricsName = (metricsName) => {
    onFilterAlerts({[ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEARCH_EXPRESSION]: metricsName});
    segmentTracking('Feedback Per Measure Widget - Navigate');
  };

  const clickOnFeedback = (event, metricsName) => {
    const feedback = event.point.type;
    if (feedback === FEEDBACK_TYPE.GOOD_CATCH) {
      onFilterAlerts({
        [ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK]: FEEDBACK_OPTIONS.POSITIVE.value,
        [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEARCH_EXPRESSION]: metricsName,
      });
    } else if (feedback === FEEDBACK_TYPE.NOT_INTERESTING) {
      onFilterAlerts({
        [ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK]: FEEDBACK_OPTIONS.NEGATIVE.value,
        [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEARCH_EXPRESSION]: metricsName,
      });
    }
    segmentTracking(`Feedback Per Measure Widget - ${feedback} - ${metricsName}`);
  };

  const tooltipFormatter = {
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      return `<div class="pie-chart-tooltip">${Math.round(Math.round(this.percentage))}% (${this.y}) - ${
        // eslint-disable-next-line react/no-this-in-sfc
        this.point.typeName
      }</div>`;
    },
  };

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && feedbackPerMeasureData && (
        <Transition in={showData} timeout={fadeOpacity.duration}>
          {(state) => (
            <>
              <header styleName="header" style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}>
                {title}
              </header>
              <div
                styleName={feedbackPerMeasureData?.length < 3 ? 'container static' : 'container'}
                style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}
              >
                {!feedbackPerMeasureData?.length ? (
                  <div styleName="no-feedback">
                    <span>Not enough data to show</span>
                  </div>
                ) : (
                  <Carousel {...CAROUSEL_SETTINGS} showIndicators={feedbackPerMeasureData.length > 1}>
                    {feedbackPerMeasureData.map((primaryArr, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`group_${index}`} styleName="charts-group-wrapper">
                        {primaryArr.map((secondaryArr) => {
                          const feedbackItem = secondaryArr.find((i) => i);
                          return (
                            <div id={feedbackItem.id} styleName="chart-wrapper" key={feedbackItem.id}>
                              <PieChart
                                onClickEvent={(e) => clickOnFeedback(e, feedbackItem.name)}
                                id={`insightsPanelFeedbackPerMeasure_${feedbackItem.id}`}
                                height={82}
                                width={82}
                                data={secondaryArr}
                                theme={FEEDBACK}
                                labels
                                tooltipFormatter={tooltipFormatter}
                              />
                              <SmartTooltip content={feedbackItem.name} delay={300}>
                                <div styleName="feedback-name" onClick={() => clickOnMetricsName(feedbackItem.name)}>
                                  <span className="ellipsis">{feedbackItem.name}</span>
                                </div>
                              </SmartTooltip>
                            </div>
                          );
                        })}
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </>
          )}
        </Transition>
      )}
    </div>
  );
};

export default FeedbackPerMeasure;
