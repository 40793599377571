// @flow
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {StringParam, useQueryParam} from 'use-query-params';
import {getUsersDataForAssigneeFilter, getUsersDataForAvatar, getUsersIsLoading} from 'admin.users/store/selectors';
import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';

const AssigneeFilter = () => {
  const [assignee, setAssignee] = useQueryParam('assignee', StringParam);

  const isLoadingUsers = useSelector(getUsersIsLoading);
  const usersOptions = useSelector(getUsersDataForAvatar);
  const usersData = useSelector(getUsersDataForAssigneeFilter);

  const selectedOptionUser = useMemo(() => usersData.filter((user) => (assignee || '').includes(user.value)), [
    usersData,
    assignee,
  ]);

  const onChange = useCallback(
    (items) => {
      const value = items?.length ? items.map((item) => item.value).join(',') : undefined;
      setAssignee(value);
    },
    [setAssignee],
  );

  const onSelectAllOrClearAllUsers = useCallback(() => {
    // Clear
    if (selectedOptionUser?.length > 0) {
      setAssignee(undefined);
    }
    // Select all
    else {
      const selectedIds = usersOptions.map((item) => item.id).join(',');
      setAssignee(selectedIds);
    }
  }, [setAssignee, usersOptions, selectedOptionUser]);

  return (
    <UsersChannelsFilter
      placeholder={isLoadingUsers ? 'Loading...' : 'Select'}
      automationId="assigneeFilter"
      onChange={onChange}
      sortedFlattenChannels={usersData}
      value={selectedOptionUser}
      isFullWidthButton
      footerComponent={
        <FormDdlActionBar
          isVisible
          selectedCount={selectedOptionUser?.length || 0}
          onClearAll={onSelectAllOrClearAllUsers}
        />
      }
    />
  );
};

export default AssigneeFilter;
