// @flow
import React, {useMemo, useRef} from 'react';
import {defaultsDeep, get, isEqual} from 'lodash';
import {Backdrop, Modal} from '@material-ui/core';
import {emptyExpressionTree} from 'metrics/store/reducers/expressionBuilderReducer';
import {Form, useField} from 'react-final-form';
import GraphTileSettings from 'dashboards/components/modals/graphTile/GraphTileSettings';
import useAsyncAction from 'common/utils/useAsyncAction';
import {updateDashboard} from 'dashboards/store/actions';
import {findFreeSpace, makeGraphTilePayload, makeUpdateTilePayload} from 'dashboards/utils';
import {OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';
import useAlertUpdateConfirmation from 'dashboards/hooks/useAlertUpdateConfirmation';
import {getUniqueId} from 'common/utils/guid';
import ExpressionTreesPreviewContainer from 'common/containers/ExpressionTreesPreviewContainer';
import useDashboardState from 'dashboards/hooks/useDashboardState';
import ModalContainer from 'dashboards/components/modals/ModalContainer';
import {makeTileDataCompatible} from 'dashboards/compatibility';
import {COLOR_NAME} from 'common/componentsV2/ColorPicker';

const emptySimpleExpressionTree = {
  id: getUniqueId(),
  expressionTree: emptyExpressionTree,
  isShowComposites: true,
};

const defaultByTreeExp = {
  type: 'line',
  stacking: null,
  yAxis: {
    type: 'linear',
    lowerRange: '',
    higherRange: '',
    opposite: false,
    isStateDirty: false,
    minorTickInterval: 'auto',
  },
};

const defaultPreviewOptions = {
  sort: 'alphanumeric',
  show: 10,
};

const EMPTY_ARRAY = [];
type PropTypes = {onClose: Function, data: Object, layout: Object, dashboardId: string, type: string};

const LocalMetricChartContainer = () => <ExpressionTreesPreviewContainer isNoSettings />;

const AddGraphModalContainer = ({onClose, data: rawData, layout, dashboardId, type: modalType}: PropTypes) => {
  const data = useMemo(() => makeTileDataCompatible(rawData), [rawData]);
  const dashboardStateRef = useRef();
  const dashboard = useDashboardState(dashboardId);
  dashboardStateRef.current = dashboard;
  const updateDashboardAsync = useAsyncAction(updateDashboard, dashboard);
  const isNew = modalType === OPTION_TILES_VALUES.NEW;

  const {askForConfirmation, confirmationModalElement} = useAlertUpdateConfirmation({
    tileData: data,
    alerts: dashboard.data.tileAlerts,
    dashboard,
  });

  const {
    input: {value: dateRangeValue},
  } = useField('dateRange', {isEqual});

  const {
    input: {value: timeScaleValue},
  } = useField('timeScale');

  const handleAsyncSubmit = (formValues) =>
    // eslint-disable-next-line consistent-return
    askForConfirmation(formValues).then((isConfirmed) => {
      if (isConfirmed) {
        const tile = makeGraphTilePayload(
          data,
          {
            ...formValues,
            expressionTrees:
              formValues.expressionTreeType === 'simple' // eslint-disable-next-line max-len
                ? [formValues.simpleExpressionTree]
                : formValues.advancedExpressionTree,
          },
          findFreeSpace(layout, {x: data.sizeX, y: data.sizeY}),
        );
        return (
          updateDashboardAsync(makeUpdateTilePayload(dashboardStateRef.current.data, tile, data.id, layout))
            .then(() => {
              onClose();
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.log(error))
        );
      }
    });

  const advancedExpressionTree = useMemo(
    () =>
      Object.entries(data.lineChart.expressionTrees).map(([id, compositeObject]) => ({
        id,
        expressionTree: compositeObject.expressionTree,
        isShowComposites: !compositeObject.excludeComposites,
      })),
    [data],
  );

  const simpleExpressionTree =
    data.expressionTreeType === 'simple' ? advancedExpressionTree[0] : emptySimpleExpressionTree;

  const byTreeExp = useMemo(
    () =>
      // eslint-disable-next-line max-len
      data.lineChart.seriesProperties.byTreeExp.reduce(
        (acc, item) => ({...acc, [item.id]: defaultsDeep(item.options, defaultByTreeExp)}),
        {
          '*': defaultByTreeExp,
        },
      ),
    [data],
  );

  const multiPreviewOptions = useMemo(
    () =>
      Object.entries(data.lineChart.expressionTrees).reduce(
        (acc, [id, compositeObject]) => ({
          ...acc,
          [id]: {
            sort: compositeObject.filter.function,
            show: compositeObject.filter.parameters[0].value,
          },
        }),
        {'*': defaultPreviewOptions},
      ),
    [data],
  );

  const staticLine = useMemo(
    () => ({
      enabled: get(data, 'lineChart.staticLine.enabled', false),
      title: get(data, 'lineChart.staticLine.title', ''),
      color: get(data, 'lineChart.staticLine.color', COLOR_NAME.GRAY_400),
      value: get(data, 'lineChart.staticLine.value', null),
    }),
    [data],
  );

  const initialValues = {
    title: get(data, 'title.text', ''),
    legendPosition: data.lineChart.legend.position,
    showLegend: data.lineChart.legend.enabled,
    expressionTreeType: data.expressionTreeType,
    simpleExpressionTree: data.expressionTreeType === 'simple' ? simpleExpressionTree : emptySimpleExpressionTree,
    advancedExpressionTree: data.expressionTreeType === 'advanced' ? advancedExpressionTree : EMPTY_ARRAY,
    multiPreviewOptions,
    byTreeExp,
    dateRange: dateRangeValue,
    timeScale: timeScaleValue,
    staticLine,
  };

  return (
    <React.Fragment>
      <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={onClose}>
        <div>
          <Form initialValues={initialValues} onSubmit={handleAsyncSubmit}>
            {({handleSubmit}) => (
              <React.Fragment>
                <ModalContainer
                  title={`${isNew ? 'Add' : 'Edit'} Graph Tile`}
                  settingContainer={GraphTileSettings}
                  previewContainer={LocalMetricChartContainer}
                  onClose={onClose}
                  onSubmit={handleSubmit}
                  isLoading={dashboard.isLoading}
                />
                {confirmationModalElement}
              </React.Fragment>
            )}
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddGraphModalContainer;
