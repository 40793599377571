import React, {PureComponent} from 'react';
import {components} from 'react-select2';

type PropTypes = {
  // accepted values by the original Select
  selectProps: any,
};

export default class MenuList extends PureComponent {
  props: PropTypes;

  render() {
    return (
      <div className={this.props.selectProps.menuListClass || ''} automation-id="dropdownList">
        <components.MenuList className="andt-dropdown-menu-list alert-clickable-item" {...this.props} />
      </div>
    );
  }
}
