// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import Highcharts from 'highcharts';
import moment from 'moment';
import * as chartsActions from 'charts/timeSeries/store/actions';
import {get, isEmpty} from 'lodash';
import {getHchartConfig, HCHART_TYPES, POINT_HOVER_DIAMETER} from '../services/timeSeriesHchartSettingsService';
import TooltipTemplate from './TooltipTemplatesV2';
import ResetZoomButton from './ResetZoomButton';
import './TimeSeriesChart.module.scss';

import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/skip';

let chartMouseData; // all charts will share this

type PropTypes = {
  disableActions: boolean,
  isExtendedTooltip: boolean,
  id: string, // required
  eventsMeta: Object,
  tooltipConditions: Object,
  index: Number,
  enableClientZoom?: boolean,
  isMobile?: boolean, // TODO post-angular:get this from the store
  // {
  //   showMetricName: false,
  //   showAnomalyData: true
  //   should hold also shouldDisplayWeekNumbers (see TooltipTemplates.js->DateTemplate)
  // }

  // event listeners
  highChartCreated: Function,
  highChartDestroyed: Function,
  dateRangeChanged: Function,
  onRangeSelection: Function,
  resetZoom: Function,
  chartClicked: Function,
  onClick: Function,
  onMouseMove: Function,
  onMouseLeave: Function,
  onMetricHover: Function,
  theme: Object,
  isResize?: boolean,
  isInactiveMode: boolean,
  chartContainerStyle?: Object,
  shouldHideTooltip?: boolean,
};

export default connect(
  () => ({}),
  {
    highChartCreated: chartsActions.highChartCreated,
    highChartDestroyed: chartsActions.highChartDestroyed,
    dateRangeChanged: chartsActions.dateRangeChanged,
    resetZoom: chartsActions.resetZoom,
    chartClicked: chartsActions.chartClicked,
  },
)(
  class TimeSeriesChart extends React.Component {
    props: PropTypes;

    static defaultProps = {
      enableClientZoom: false,
      isMobile: false,
      isResize: false,
      chartContainerStyle: {},
      shouldHideTooltip: false,
    };

    state = {
      isResetZoomBtnVisible: false,
    };

    componentDidMount() {
      this.hchart = null;
      this.isMobileWidth = false;
      const {id, highChartCreated, theme, index, disableActions} = this.props;
      this.hchart = Highcharts.chart(id, this.configureHChart(theme));
      this.hchart.andt = {
        showResetZoomButton: this.showResetZoomButton,
      };
      if (!disableActions) {
        highChartCreated({
          chartId: id,
          theme,
          index,
        });
      }
      this.tooltipElm = document.createElement('div');
      this.tooltipElm.className = 'anch-chart-tooltip';
      document.body.appendChild(this.tooltipElm);

      this.registerResizeSubject();
    }

    shouldComponentUpdate(nextProps, nextState) {
      return (
        nextState.isResetZoomBtnVisible !== this.state.isResetZoomBtnVisible ||
        nextProps.isResize !== this.props.isResize ||
        this.props.shouldHideTooltip !== nextProps.shouldHideTooltip
      );
    }

    componentDidUpdate(prevProps) {
      if (prevProps.isResize !== this.props.isResize && !isEmpty(this.hchart)) {
        const width = this.chartContainerDomElm.clientWidth;
        const height = this.chartContainerDomElm.clientHeight;
        this.hchart.setSize(width, height);
      }
      if (this.props.shouldHideTooltip === true) {
        ReactDOM.unmountComponentAtNode(this.tooltipElm);
      }
    }

    componentWillUnmount() {
      const {id, highChartDestroyed, disableActions} = this.props;
      if (!disableActions) {
        highChartDestroyed({chartId: id});
      }
      document.body.removeChild(this.tooltipElm);
      this.tooltipElm = null;
      this.chartContainerDomElm.removeEventListener('click', this.clickHandler);
      this.chartContainerDomElm.removeEventListener('mousemove', this.pointerMoveHandler);
      this.chartContainerDomElm.removeEventListener('mouseleave', this.pointerLeaveHandler);
      this.chartContainerDomElm.removeEventListener('touchmove', this.touchMoveHandler);
      this.chartContainerDomElm.removeEventListener('touchend', this.pointerLeaveHandler);
      this.chartContainerDomElm.removeEventListener('touchcancel', this.pointerLeaveHandler);
      this.hchart.destroy();
      this.$resizeBehaviorSubject.unsubscribe();
    }

    registerResizeSubject = () => {
      // hchart handles window.resize but if the div changes size regardless of window it wont catch
      this.$resizeBehaviorSubject = new BehaviorSubject()
        .debounceTime(200)
        .distinctUntilChanged(
          (x, y) => this.hchart.chartWidth === Math.round(y), // div resize vs. window resize
        )
        .skip(1) // skip initial value when the chart is first displayed
        .map(() => !isEmpty(this.hchart) && this.hchart.reflow()); // see that the component did not unmount
      this.$resizeBehaviorSubject.subscribe();
    };

    syncChartVisual = (/* data, isSelf */) => {};

    clearAndtValY = () => {};

    chartClicked = () => {
      this.props.chartClicked(get(this.hchart.hoverPoint, 'x'));
    };

    pointerMoveHandler = (e) => {
      const elm = this.hchart.pointer.normalize(e);
      chartMouseData = {
        xCoordinate: elm.chartX - this.hchart.plotLeft,
        yCoordinate: elm.chartY - this.hchart.plotTop,
        xValue: this.hchart.xAxis[0].toValue(elm.chartX),
        yValue: this.hchart.yAxis[0].toValue(elm.chartY),
        isInsidePlot: this.hchart.isInsidePlot(elm.chartX - this.hchart.plotLeft, elm.chartY - this.hchart.plotTop),
        plotHeight: this.hchart.plotHeight,
        chartX: elm.chartX,
        chartWidth: this.hchart.chartWidth,
      };

      this.syncChartVisual(chartMouseData, true);
      if (this.props.onMouseMove) {
        this.props.onMouseMove(chartMouseData, this.props.id, e);
      }
    };

    pointerLeaveHandler = (e) => {
      this.clearAndtValY();
      this.syncChartVisual();
      if (this.props.onMouseLeave) {
        this.props.onMouseLeave(e);
      }
      ReactDOM.unmountComponentAtNode(this.tooltipElm);
    };

    clickHandler = (e) => {
      if (this.props.onClick) {
        this.props.onClick(e);
      }
    };

    touchMoveHandler = (e) => {
      e.preventDefault();
      const elm = e.originalEvent.touches[0] || e.originalEvent.changedTouches[0];
      this.pointerMoveHandler(elm);
    };

    configureHChart = (theme) => {
      const {isMobile, tooltipConditions, onMetricHover, isInactiveMode, isExtendedTooltip} = this.props;
      const config = getHchartConfig(isMobile, theme, isInactiveMode);
      const self = this;
      const offset = (elm) => {
        if (!elm) {
          return {};
        }
        const box = elm.getBoundingClientRect();
        return {
          top: box.top + window.pageYOffset - document.documentElement.clientTop,
          left: box.left + window.pageXOffset - document.documentElement.clientLeft,
        };
      };

      config.chart.events.selection = (event) => {
        if (this.props.enableClientZoom) {
          this.showResetZoomButton();
        } else {
          event.preventDefault();
        }

        if (moment(event.xAxis[0].max).diff(moment(event.xAxis[0].min), 'minutes') < 5) {
          return;
        }

        const selectedDateRange = {
          startDate: moment(event.xAxis[0].min).unix(),
          endDate: moment(event.xAxis[0].max).unix(),
        };

        if (!this.props.enableClientZoom && this.props.onRangeSelection) {
          this.props.onRangeSelection(selectedDateRange);
        }

        this.props.dateRangeChanged(selectedDateRange, this.props.eventsMeta);
      };

      config.chart.events.load = () => {
        this.chartContainerDomElm.addEventListener('click', this.clickHandler);
        this.chartContainerDomElm.addEventListener('mousemove', this.pointerMoveHandler);
        this.chartContainerDomElm.addEventListener('mouseleave', this.pointerLeaveHandler);

        if (this.props.isMobile) {
          this.chartContainerDomElm.addEventListener('touchmove', this.touchMoveHandler);
          this.chartContainerDomElm.addEventListener('touchend', this.pointerLeaveHandler);
          this.chartContainerDomElm.addEventListener('touchcancel', this.pointerLeaveHandler);
        }
      };

      config.chart.events.click = this.chartClicked;

      config.tooltip.positioner = (labelWidth, labelHeight, point) => {
        const tooltipElement = this.tooltipElm;
        const toolTipHeight = tooltipElement.offsetHeight;
        const chartContainerOffset = offset(this.chartContainerDomElm);
        let plotLeft = null;
        let plotTop = 0;
        let plotRight = null;

        if (isMobile || this.isMobileWidth) {
          plotLeft = chartContainerOffset.left - window.scrollX; // + point.plotX + this.hchart.plotLeft;
          plotTop = chartContainerOffset.top - window.scrollY - toolTipHeight;
        } else {
          const rightPosLimit = window.innerWidth;
          const bottomPosLimit = window.innerHeight;
          const panelWidth = this.chartContainerDomElm.offsetWidth;
          const toolTipWidth = tooltipElement.offsetWidth;
          const cursorBoxOffset = 10;
          const clientX = chartContainerOffset.left - window.scrollX + point.plotX + this.hchart.plotLeft;
          const clientY = chartContainerOffset.top - window.scrollY + point.plotY + this.hchart.plotTop;

          plotTop = clientY + cursorBoxOffset;
          if (plotTop + toolTipHeight > bottomPosLimit) {
            plotTop = clientY - cursorBoxOffset - toolTipHeight;
          }

          // Flip horizontal side if position is above 50%
          if (point.plotX > panelWidth / 2) {
            // Left
            plotRight = rightPosLimit - clientX + cursorBoxOffset;
          } else {
            // Right
            const toolTipRightBorderX = clientX + cursorBoxOffset + toolTipWidth;
            const delta = rightPosLimit - toolTipRightBorderX;
            if (delta > 0) {
              plotLeft = clientX + cursorBoxOffset;
            } else {
              plotLeft = clientX + cursorBoxOffset + delta;
            }
          }
        }

        const getPos = (val) => (val && val !== Number.MIN_VALUE ? `${val}px` : 'auto');
        const {style} = this.tooltipElm;
        style.top = getPos(plotTop);
        style.bottom = getPos(null);
        style.left = getPos(plotLeft);
        style.right = getPos(plotRight);
        style.display = 'block';

        return {x: Number.MIN_VALUE, y: Number.MIN_VALUE};
      };

      config.tooltip.formatter = function() {
        if (!chartMouseData || self.hchart.loadingShown) {
          return '';
        }
        if (onMetricHover) {
          onMetricHover(this.point.series);
        }

        // TODO: eli - delete in future after we sure that the new mode is good
        // with shared: true
        // let minPoint;
        // if (this.points && this.points.length > 1) {
        //   // get the nearest visible point to the mouse
        //   let point = null;
        //   /*  eslint prefer-destructuring: "off" */
        //   minPoint = this.points[0];
        //   for (let i = 0, len = this.points.length; i < len; i++) {
        //     point = this.points[i];
        //     point.series.options.andtVal.y = point.y;
        //     if (
        //       point.series.type !== HCHART_TYPES.column &&
        //       point.series.data.length > 1 &&
        //       point.series.stateMarkerGraphic
        //     ) {
        //       point.series.stateMarkerGraphic.attr({
        //         width: 0,
        //         height: 0,
        //       });
        //     }
        //     minPoint = self.hchart.hoverPoint;
        //     minPoint.point = minPoint;
        //   }
        // }
        // else {
        //   // mouse is specific over a single point (see below: point->events->mousover->tooltipRefresh([point])
        //   minPoint = this.points[0];
        //   minPoint.series.options.andtVal.y = minPoint.y;
        // }

        // with shared: false
        const minPoint = this.point;
        minPoint.point = minPoint;
        minPoint.series.options.andtVal.y = minPoint.y;
        // end with shared: false

        if (
          minPoint.series.type !== HCHART_TYPES.column &&
          minPoint.series.data.length > 1 &&
          minPoint.series.stateMarkerGraphic
        ) {
          minPoint.series.stateMarkerGraphic.attr({
            width: POINT_HOVER_DIAMETER,
            height: POINT_HOVER_DIAMETER,
            translateX: -0.3,
            translateY: -1,
          });
        }

        // Performance wise we work directly against the DOM here
        if (self.props.shouldHideTooltip) {
          ReactDOM.unmountComponentAtNode(self.tooltipElm);
        } else {
          ReactDOM.render(
            <TooltipTemplate
              isExtendedTooltip={isExtendedTooltip}
              tooltipConditions={tooltipConditions}
              timeScale={self.props.timeScale}
              timeZoneName={self.props.timeZoneName}
              bucketStartTimeEnabled={self.props.bucketStartTimeEnabled}
              minPoint={minPoint}
              date={this.x}
              showMetricName={self.props.tooltip.showMetricName}
              anomalyPoint={get(
                minPoint.series.options.andtVal.dataPointsMeta,
                `anomalyStorageArray[${minPoint.point.index}]`,
              )}
              showAnomalyData={self.props.tooltip.showAnomalyData}
              hideMeetIcons={self.props.tooltip.showAnomalyData}
            />,
            self.tooltipElm,
          );
        }
        return '';
      };

      config.plotOptions.series.events.click = this.chartClicked;

      return config;
    };

    showResetZoomButton = () => {
      this.setState({isResetZoomBtnVisible: true});
    };

    resetZoomClicked = () => {
      this.setState({isResetZoomBtnVisible: false});
      this.hchart.xAxis[0].setExtremes(null, null);
      this.props.resetZoom(null, this.props.eventsMeta);
    };

    handleResize = (width) => {
      this.$resizeBehaviorSubject.next(width);
    };

    setRef = (e) => {
      this.chartContainerDomElm = e;
    }; // will be set with null when unmounted

    render() {
      const {id, chartContainerStyle} = this.props;
      const {isResetZoomBtnVisible} = this.state;
      const chartStyle = {height: '100%', userSelect: 'none', ...chartContainerStyle};
      return (
        <>
          <div style={{position: 'relative', zIndex: 10}}>
            {isResetZoomBtnVisible && <ResetZoomButton resetZoomClicked={this.resetZoomClicked} />}
          </div>
          <div id={id} ref={this.setRef} style={chartStyle}>
            Chart is here
          </div>
        </>
      );
    }
  },
);
