import {EVENT_STREAM_FIELDS, EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';
import useFetchDatasetTablePreview from 'dataCollectors/api/useFetchDatasetTablePreview';
import useFetchTablePreview from 'dataCollectors/api/useFetchTablePreview';
import usePostFileUploadPreview from 'dataCollectors/api/usePostFileUploadPreview';

const {TYPE, SOURCE_ID, SCHEMA_NAME, TABLE_NAME, PROJECT_ID, DATA_SET} = EVENT_STREAM_FIELDS;
const {LOCAL_FILE, PSQL, BIG_QUERY} = EVENT_STREAM_TYPES;

export default function usePreviewTableData(stream, fileFormat) {
  // fetch preview data api by stream type
  switch (stream[TYPE.id]) {
    case LOCAL_FILE:
      return usePostFileUploadPreview(stream[SOURCE_ID.id], fileFormat);
    case BIG_QUERY:
      return useFetchDatasetTablePreview(
        'big-query',
        stream[SOURCE_ID.id],
        stream[PROJECT_ID.id],
        stream[DATA_SET.id],
        stream[TABLE_NAME.id],
      );
    case PSQL:
    default:
      return useFetchTablePreview(stream[TYPE.id], stream[SOURCE_ID.id], stream[SCHEMA_NAME.id], stream[TABLE_NAME.id]);
  }
}
