import {combineReducers} from 'redux';
import dataSources from './dataSourcesReducer';
import dataSourceTypes from './dataSourceTypesReducer';
import dataStreams from './dataStreamsReducer/dataStreamsReducer';
import eventStreams from './dataStreamsReducer/eventStreamsReducer';
import dataManager from './dataManagerReducer';
import googleAnalyticsStream from './googleAnalyticsReducer';
import googleAuctionsStream from './googleAuctionsReducer';
import googleAdsStream from './googleAdsReducer';
import fileUploadStream from './fileUploadReducer';
import s3Stream from './s3Reducer';
import googleStorageStream from './gsReducer';
import googleGA4Stream from './googleGA4Reducer';
import googleSearchStream from './googleSearchReducer';
import sqlStream from './sqlReducer';
import adobeStream from './adobeReducer';
import bigQueryStream from './bigQueryReducer';
import mixpanelStream from './mixPanelReducer';
import mParticleStream from './mParticleReducer';
import lookupTables from './lookupTablesReducer';
import kinesisStream from './kinesisReducer';
import salesforceStream from './salesforceReducer';
import parquetStream from './parquetReducer';
import segmentStream from './segmentReducer';
import facebookAdsStream from './facebookAdsReducer';
import coralogixStream from './coralogixReducer';
import newRelicStream from './newRelicReducer';
import datadogStream from './datadogReducer';
import sumologicStream from './sumologicReducer';
import views from './views/viewsReducer';

export default combineReducers({
  dataSourceTypes,
  dataSources,
  eventStreams,
  dataStreams,
  dataManager,
  googleAnalyticsStream,
  googleAuctionsStream,
  googleAdsStream,
  fileUploadStream,
  s3Stream,
  googleStorageStream,
  googleGA4Stream,
  googleSearchStream,
  sqlStream,
  adobeStream,
  bigQueryStream,
  mParticleStream,
  lookupTables,
  parquetStream,
  kinesisStream,
  salesforceStream,
  segmentStream,
  facebookAdsStream,
  coralogixStream,
  newRelicStream,
  datadogStream,
  mixpanelStream,
  sumologicStream,
  views,
});
