// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import {REGIONS} from 'bc/services/genericStorageService';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import * as selectors from 'bc/store/selectors';
import {
  createDataSource as createDataSourceAction,
  resetAddSourceError as resetAddSourceErrorAction,
  fetchTimestreamDataSourceListDatabases as fetchMysqlDataSourceListDatabasesAction,
  resetTimestreamDataSourceListDatabases as resetTimestreamDataSourceListDatabasesAction,
} from 'bc/store/actions';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import Loading from 'metrics/components/Loading';
import DataSourceModal from '../../DataSourceModal';

import '../../DataSourceModal.module.scss';

type PropTypes = {
  isOpen: Boolean,
  isViewOnly: Boolean,
  isEditable: Boolean,
  source: Object,
  sourceType: Object,
  isLoading: Boolean,
  sourceError: Object,
  onClose: Function,
  createDataSource: Function,
  resetAddSourceError: Function,

  fetchTimestreamDataSourceListDatabases: Function,
  resetTimestreamDataSourceListDatabases: Function,
  isDatabaseListLoading: Boolean,
  databaseListError: Object,
  databaseList: Array,
};

export default connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,

    isDatabaseListLoading: selectors.getTimestreamDatabaseList(state).isLoading,
    databaseListError: selectors.getTimestreamDatabaseList(state).error,
    databaseList: selectors.getTimestreamDatabaseListItems(state),
  }),
  {
    createDataSource: createDataSourceAction,
    resetAddSourceError: resetAddSourceErrorAction,

    fetchTimestreamDataSourceListDatabases: fetchMysqlDataSourceListDatabasesAction,
    resetTimestreamDataSourceListDatabases: resetTimestreamDataSourceListDatabasesAction,
  },
)(
  class TimestreamSourceModal extends React.PureComponent {
    props: PropTypes;

    // eslint-disable-next-line no-extra-boolean-cast
    state = !!this.props.source
      ? {
          streamName: this.props.source.kStreamName,
          region: REGIONS.find((reg) => reg.value === this.props.source.region),
          roleARN: this.props.source.roleARN,
          dbName: this.props.source.dbName,
        }
      : {
          streamName: '',
          roleARN: '',
          region: REGIONS[0],
          dbName: '',
        };

    regionChanged = (val) => {
      this.setState({region: val});
    };

    listDatabasesBtnClicked = () => {
      const {sourceType} = this.props;
      const {region, roleARN} = this.state;
      this.setState({dbName: ''});
      this.props.fetchTimestreamDataSourceListDatabases({
        type: getTypeDetails(this.props.sourceType.type).type,
        roleARN,
        externalId: sourceType.externalId,
        region: region.value,
      });
    };

    dbNameChanged = (val) => {
      this.setState({dbName: val});
    };

    isContinueDisabled = () => {
      const {isDatabaseListLoading} = this.props;
      const {region, roleARN, dbName} = this.state;
      return isDatabaseListLoading || !region || !roleARN || !dbName;
    };

    createBtnClicked = () => {
      const {sourceType, createDataSource} = this.props;
      const {region, dbName, roleARN} = this.state;
      const obj = {
        type: getTypeDetails(sourceType.type).type,
        name: `${getTypeDetails(sourceType.type).shortName} ${
          this.state.dbName
        } Data Source ${new Date().getTime().toString()}`,
        region: region.value,
        dbName,
        roleARN,
        externalId: sourceType.externalId,
      };
      createDataSource(obj);
    };

    onClose = () => {
      this.props.resetTimestreamDataSourceListDatabases();
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    render() {
      const {
        isOpen,
        source,
        sourceType,
        sourceError,
        isViewOnly,
        isEditable,
        isLoading,
        isDatabaseListLoading,
        databaseListError,
        databaseList,
      } = this.props;

      const {region, roleARN, dbName} = this.state;

      const errorObj = sourceError || databaseListError;

      const selectedIndexRegion = REGIONS.findIndex((val) => val.value === region.value);
      const selectedIndexDatabase = databaseList.findIndex((val) => dbName === val.name);
      const selectedDatabase = databaseList[selectedIndexDatabase] || '';

      return (
        <DataSourceModal
          isOpen={isOpen}
          isProcessing={isLoading}
          isViewOnly={isViewOnly}
          isRenameEnabled={isEditable}
          source={source}
          sourceType={sourceType}
          logoImageClass={`source-logo-${sourceType.type}`}
          onClose={this.onClose}
          isContinueDisabled={this.isContinueDisabled()}
          onContinue={this.createBtnClicked}
        >
          <div styleName="inputs-wrapper">
            <SelectAndt
              id="s3RegionDdl1"
              automationId="s3Regions"
              styleName="region-dropdown"
              className="andt-dropdown"
              disabled={isViewOnly}
              options={REGIONS}
              getOptionLabel={(val) => val.text}
              getOptionValue={(val) => val.value}
              type={TYPE_NO_SEARCH}
              theme={THEME_LIGHT}
              value={REGIONS[selectedIndexRegion]}
              onChange={(item) => this.regionChanged(item)}
              placeholder="Choose Region"
            />

            <input type="text" readOnly disabled placeholder="Source ID" value={sourceType.externalId} />

            <CopyToClipboardButton
              copyStr={sourceType.externalId}
              tooltipMessage="Copy the External ID to clipboard"
              styleName="copy-clipboard-btn-pos"
              shouldUseIcon
            />

            <input
              type="text"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              styleName="role-margin"
              onChange={(e) => this.setState({roleARN: e.target.value})}
              placeholder="Role ARN"
              value={roleARN}
            />

            <div styleName="test-wrapper">
              <button
                type="button"
                className="btn btn-raised"
                disabled={!roleARN || isViewOnly}
                readOnly={isViewOnly}
                onClick={this.listDatabasesBtnClicked}
              >
                LIST DATABASES
              </button>
              {isDatabaseListLoading ? <Loading styleName="spinner" /> : null}
            </div>

            {isViewOnly ? (
              <input type="text" readOnly disabled value={dbName} />
            ) : (
              <SelectAndt
                id="athenaDatabaseDdl"
                automationId="timestreamDatabase"
                disabled={isDatabaseListLoading || !databaseList.length || isViewOnly}
                options={databaseList}
                getOptionLabel={(val) => val.name}
                getOptionValue={(val) => val.name}
                type={TYPE_NO_SEARCH}
                theme={THEME_LIGHT}
                value={selectedDatabase}
                onChange={(item) => this.dbNameChanged(item.name)}
                placeholder="Database"
              />
            )}

            {!isLoading && errorObj ? (
              <div styleName="error-wrapper-athena">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" /> {errorObj.uMessage}
                </span>
              </div>
            ) : null}

            <div styleName="extra-info">
              <a
                href="https://support.anodot.com/hc/en-us/articles/4404699509522"
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn more
              </a>
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
