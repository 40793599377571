// @flow
import React from 'react';
import {Box, makeStyles, useTheme} from '@material-ui/core';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {getMonths} from 'common/utils/utilsService';

/** Default year range dates from and after current year */
const DEFAULT_YEAR_COUNT = 5;
/** Current year on user Device */
const DEFAULT_CURRENT_YEAR = new Date().getFullYear();
/** Default start year on user device */
const DEFAULT_START_YEAR = DEFAULT_CURRENT_YEAR - DEFAULT_YEAR_COUNT;
/** Default end year on user device */
const DEFAULT_END_YEAR = DEFAULT_CURRENT_YEAR + DEFAULT_YEAR_COUNT;
/** Default ggetYearsRange options */
const DEFAULT_YEARS_DATE_OPTIONS = {
  startYear: DEFAULT_START_YEAR,
  endYear: DEFAULT_END_YEAR,
};
/**
 * Function will return array from start year to end year
 * example: [2016, 2017, 2018, 2019, 2020]
 * @param Empty - will be current year +- 5 years range
 * @param {} - will be current year +- 5 years range
 * @param startYear {Number} - can be start year in Number format, such as: 2016
 * @param endDate {Number} - can be end year in Number format, such as: 2025
 * @return Array - all dates from start to end or by default from
 * current year range [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025]
 */
const getYearsRange = ({startYear = DEFAULT_START_YEAR, endYear = DEFAULT_END_YEAR} = DEFAULT_YEARS_DATE_OPTIONS) => {
  const years = [];
  for (let year = startYear; year < endYear; year++) {
    years.push(year);
  }
  return years;
};

const setMidnight = (date) => {
  if (typeof date === 'number' && String(date).length === 10) {
    return new Date(date * 1000).setHours(0, 0, 0, 0);
  }
  return date && new Date(date).setHours(0, 0, 0, 0);
};

const optionsMonth = getMonths().map((month, index) => ({value: index, label: month}));
const optionsYear = getYearsRange().map((year) => ({value: year, label: year}));

const computeDate = (dateString, dateValue) => {
  const t = new Date(dateString);
  t.setDate(dateValue);
  return {
    date: t.getDate(),
    isInsideMonth: t.getDate() === dateValue,
    isToday: t.toDateString() === new Date().toDateString(),
  };
};

const borderRadiusIndex = (index, isStartDate, isEndDate) => {
  if (index === 0 || isStartDate) {
    return '6px 0 0 6px';
  }

  if (index === 6 || isEndDate) {
    return '0 6px 6px 0';
  }

  return 0;
};

const getMonthItem = (currentMonth) => optionsMonth.find((item) => item.value === currentMonth);
const getYearItem = (currentYear) => optionsYear.find((item) => item.value === currentYear);

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[350],
    fontWeight: 500,
    borderRadius: 6,
    padding: '5px 0',
    margin: '16px 0 15px 0',
  },
  headerCell: {
    display: 'flex',
    flexBasis: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 6px',
    height: 14,
    width: 26,
  },
  cell: {
    display: 'flex',
    flexBasis: '100%',
    height: 20,
    width: 26,
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 6,
    '&:hover': {
      backgroundColor: theme.palette.blue[100],
      color: theme.palette.gray[500],
    },
  },
}));

export default function DatePicker({
  value: {startDate, endDate},
  onChange,
  currentMonth,
  setCurrentMonth,
  currentYear,
  setCurrentYear,
}: {
  value: Object,
  onChange: Function,
  currentMonth: Number,
  setCurrentMonth: Function,
  currentYear: Number,
  setCurrentYear: Function,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const dateString = `${currentYear}-${currentMonth + 1}-01`;

  const date = new Date(dateString);

  const weekday = date.getDay();
  const days = new Date(currentYear, currentMonth, 0).getDate();

  let counter = -weekday;
  const startDateMidnight = setMidnight(startDate);
  const endDateMidnight = setMidnight(endDate);
  return (
    <div>
      <div className="display_flex justifyContent_center">
        <SelectAndt
          automationId="monthCustomDateRange"
          onChange={(month) => setCurrentMonth(month.value)}
          value={getMonthItem(currentMonth)}
          options={optionsMonth}
          optionHeight={40}
          buttonWidth={120}
          type={TYPE_NEW_NO_SEARCH}
          theme={THEME_BLUE_LEAN}
        />
        <div className="ml_1">
          <SelectAndt
            automationId="yearCustomDateRange"
            onChange={(year) => {
              setCurrentYear(year.value);
            }}
            value={getYearItem(currentYear)}
            options={optionsYear}
            optionHeight={40}
            type={TYPE_NEW_NO_SEARCH}
            theme={THEME_BLUE_LEAN}
          />
        </div>
      </div>
      <div className={classes.header}>
        {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((item) => (
          <div key={item} className={classes.headerCell}>
            {item}
          </div>
        ))}
      </div>
      {Array(Math.ceil((days + weekday) / 7))
        .fill(null)
        .map((_, index) => index)
        .map((rowNumber) => (
          <div className="display_flex mb_1-5" key={rowNumber}>
            {Array(7)
              .fill(null)
              .map((_, index) => index)
              .map((cellNumber, index) => {
                counter += 1;
                const computedDate = computeDate(dateString, counter);
                const fullDate = new Date(dateString);
                fullDate.setDate(counter);
                const fullDateMidnight = setMidnight(fullDate);
                const isSelectedStartDate = fullDateMidnight === startDateMidnight;
                const isSelectedEndDate = fullDateMidnight === endDateMidnight;
                const isSelected = isSelectedStartDate || isSelectedEndDate;
                const isRange = fullDateMidnight >= startDateMidnight && fullDateMidnight <= endDateMidnight;
                const rangeBgColor = isRange ? theme.palette.blue[100] : '';
                const selectedBgColorDate = isSelected ? theme.palette.blue[500] : '';
                const colorDate = computedDate.isInsideMonth ? theme.palette.gray[400] : theme.palette.gray[300];
                return (
                  <Box
                    key={`${rowNumber}_${cellNumber}`}
                    pr={isSelectedEndDate ? undefined : '6px'}
                    pl={isSelectedStartDate ? undefined : '6px'}
                    mr={isSelectedEndDate ? '6px' : undefined}
                    ml={isSelectedStartDate ? '6px' : undefined}
                    bgcolor={rangeBgColor}
                    borderRadius={
                      isRange ? borderRadiusIndex(index, isSelectedStartDate, isSelectedEndDate) : undefined
                    }
                  >
                    <Box
                      className={classes.cell}
                      color={isSelected ? theme.palette.white[500] : colorDate}
                      boxShadow={computedDate.isToday && !isRange ? `0 0 0 2px ${theme.palette.blue[100]}` : undefined}
                      bgcolor={isSelected ? selectedBgColorDate : rangeBgColor}
                      onClick={() => onChange(fullDate)}
                    >
                      {computedDate.date}
                    </Box>
                  </Box>
                );
              })}
          </div>
        ))}
    </div>
  );
}
