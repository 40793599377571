import {createSelector} from 'reselect';
import {selectors as commonSelectors} from 'common';
import * as profileSelectors from 'profile/store/selectors';
import {get} from 'lodash';

const {getAuditLog} = commonSelectors;
const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const isOpenFilters = createSelector(
  profileSelectors.getMeAppSettings,
  (settings) => get(settings, 'activityLog.isFiltersBarOpen', true),
);

export const getFetchAuditLog = createSelector(
  getAuditLog,
  (auditLog) => get(auditLog, 'fetchAuditLog', EMPTY_OBJECT),
);

export const getAuditLogData = createSelector(
  getAuditLog,
  (auditLog) => get(auditLog, 'fetchAuditLog.data', EMPTY_OBJECT),
);

export const getListEntityId = createSelector(
  getAuditLog,
  (auditLog) => get(auditLog, 'fetchListEntityId.data', EMPTY_ARRAY),
);

export const auditLogDataIsLoading = createSelector(
  getAuditLog,
  (auditLog) => get(auditLog, 'fetchAuditLog.isLoading', false),
);

export const getListEntityIdIsLoading = createSelector(
  getAuditLog,
  (auditLog) => get(auditLog, 'fetchEntityId.isLoading', false),
);

export const getNextPageIsLoading = createSelector(
  getAuditLog,
  (auditLog) => get(auditLog, 'fetchAuditLog.isLoadingNextPage', false),
);

export const getTotalCount = createSelector(
  getAuditLog,
  (auditLog) => get(auditLog, 'fetchAuditLog.totalCount', 0),
);

export const getTotalCountIsLoading = createSelector(
  getAuditLog,
  (auditLog) => get(auditLog, 'fetchAuditLog.isLoadingTotalCount', false),
);
