import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {getSelectedAlertDefinition} from 'alerts.management/store/selectors';
import * as actions from './actions';
import * as api from '../services/api';

const fetchAlertActions = makeAsyncEpic(actions.fetchAlertActionsByIds, api.fetchAlertActionsByIds);
const fetchAllActions = makeAsyncEpic(actions.fetchAllActions, api.fetchAllActions);
const createAlertAction = makeAsyncEpic(actions.createAction, api.createAlertAction);
const updateAction = makeAsyncEpic(actions.updateAction, api.updateAlertAction);
const deleteAction = makeAsyncEpic(actions.deleteAction, api.deleteAction);

const updateActionsToAlerts = (action$, {getState}) =>
  action$.ofType(actions.fetchAllActions.success.TYPE).switchMap(({payload}) => {
    const alert = getSelectedAlertDefinition(getState());
    if (alert.data.alertActions && alert.data.alertActions.length) {
      const alertActions = [];
      alert.data.alertActions.forEach((alertFromData) => {
        payload.forEach((action) => {
          if (action.id === alertFromData) {
            alertActions.push(action);
          }
        });
      });
      return [actions.updateActionsToAlerts(alertActions)];
    }
    return [actions.updateActionsToAlerts([])];
  });

export default combineEpics(
  fetchAlertActions,
  fetchAllActions,
  createAlertAction,
  updateAction,
  deleteAction,
  updateActionsToAlerts,
);
