import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const accounts = makeAsyncReducer(actions.fetchGoogleGA4Accounts, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const properties = makeAsyncReducer(actions.fetchGoogleGA4Properties, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const metadata = makeAsyncReducer(actions.fetchGoogleGA4Metadata, {
  defaultData: EMPTY_OBJECT,
  errorHandler: bcErrorHandler,
});

const templates = makeAsyncReducer(actions.fetchGoogleGA4Templates, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const adjust = makeAsyncReducer(actions.fetchGoogleGA4MetadataAdjust, {
  defaultData: EMPTY_OBJECT,
  errorHandler: bcErrorHandler,
});

export default combineReducers({
  accounts,
  properties,
  metadata,
  templates,
  adjust,
});
