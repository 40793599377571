import React, {useMemo} from 'react';
import {treeVisitor} from '../../utils/utilsService';
import Tooltip, {TYPES} from '../Tooltip';
import './ExpressionWarning.module.scss';

type PropsType = {
  expression: Object,
};

const ExpressionWarning = ({expression}: PropsType) => {
  const isMultipleStreams = useMemo(() => {
    const streamList = {};
    treeVisitor(expression.root, 'children', (childNode) => {
      if (childNode.searchObject) {
        childNode.searchObject.expression.forEach((ex) => {
          if (ex.type === 'origin') {
            streamList[ex.value] = 1;
          }
        });
      }
    });

    return Object.keys(streamList).length > 1;
  }, [expression]);

  if (isMultipleStreams) {
    const content = (
      <div>
        <div>Warning</div>
        <div>
          You selected streams that belong to different access groups, only users who have access to both streams can
          see it.
        </div>
      </div>
    );
    return (
      <Tooltip content={content} extraTtClass="expression-warning" type={TYPES.INFO}>
        <div styleName="icon" className="icon icn-general16-warning" />
      </Tooltip>
    );
  }

  return <div />;
};

export default ExpressionWarning;
