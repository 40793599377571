/* eslint-disable react/no-array-index-key */

import React, {memo, useState, Fragment, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {TIMELINE_ENTRIES} from 'investigation/services/investigationService';
import {palette} from 'app/styles/theme';
import {makeStyles} from '@material-ui/core/styles';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import NameFormatShort from 'metrics/components/metricName/NameFormatShort';
import {getTimeZoneName} from 'profile/store/selectors';
import {useSelector} from 'react-redux';
import {getFormattedHumanDuration} from 'common/utils/dateService';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import Info from 'common/componentsV2/Info';
import Date from './Date';
import TimelineStatusLine from './TimelineStatusLine';
import './TimelineEvents.module.scss';

const useStyles = makeStyles(() => ({
  icon: {
    position: 'relative',
    width: 33,
    height: 33,
    border: `2px solid ${palette.white['500']}`,
    borderRadius: '50%',
    marginRight: 6,
    backgroundColor: palette.gray['200'],
    fontSize: 16,
    '&::before': {
      position: 'absolute',
      top: 6,
      left: 7,
    },
  },
  innerChip: {
    margin: '-5px 5px -5px 5px',
  },
}));

const TimelineEventIncremented = (props: PropTypes) => {
  const classes = useStyles();
  const timeZoneName = useSelector(getTimeZoneName);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isExpendable, seIsExpendable] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const rowWrapper = useRef(null);
  const row = useRef(null);
  const {
    type,
    dataTime,
    createdTime,
    metricMeasures,
    title,
    timelineMetrics,
    updateType,
    firstName,
    lastName,
    userName,
    comment,
    topEvents,
    feedbackType,
    ackOperation,
    userId,
    newAssignee,
    newAssigneeFirstName,
    newAssigneeLastName,
    previousAssignee,
    prevAssigneeFirstName,
    prevAssigneeLastName,
    afterTime,
    openFor,
  } = props.timelineEvent;

  useEffect(() => {
    const rowContentHeight = rowWrapper.current.scrollHeight;
    const rowDefaultHeight = TIMELINE_ENTRIES[type].rowDefaultSizeIncremented;

    seIsExpendable(rowContentHeight > rowDefaultHeight);
  }, []);

  useEffect(() => {
    if (isExpanded) {
      setMaxHeight(rowWrapper.current.scrollHeight);
    } else {
      setMaxHeight(TIMELINE_ENTRIES[type].rowDefaultSizeIncremented);
    }
  }, [isExpanded]);

  const getCloseReasonText = (tm) => {
    if (!tm) {
      return '';
    }
    if (!Array.isArray(tm)) {
      return '';
    }
    if (tm.length === 0) {
      return '';
    }
    if (tm[0].closeReasonPhrase) {
      return tm[0].closeReasonPhrase;
    }
    return ' - ';
  };

  const renderMetricMeta = (anomalyType, metrics) => {
    if (anomalyType === TIMELINE_ENTRIES.ANOMALY_UPDATED.updateType.DIRECTION_CHANGED.value) {
      return metrics.map((metric, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`tleMetric${index}`}>
          {(metric.direction === 'UP' || metric.direction === 'MIXED') && (
            <div styleName="directionMeta">
              <span styleName="bold">Spike</span> of {metric.upperPercentageDelta}% (from {metric.upperComparisonValue}{' '}
              to {metric.upperPeak})
            </div>
          )}

          {metric.direction === 'DOWN' && (
            <div styleName="directionMeta">
              <span styleName="bold">Drop</span> of {metric.lowerPercentageDelta}% (from {metric.lowerComparisonValue}{' '}
              to {metric.lowerPeak})
            </div>
          )}

          <NameFormatShort isSmaller measure={metric.measure} dimensions={metric.dimensions} />
        </div>
      ));
    }
    return (
      <div styleName="name-format-container">
        {metrics.map((metric, index) => (
          <NameFormatShort
            isSmaller
            key={
              // eslint-disable-next-line react/no-array-index-key
              `tleMetric${index}`
            }
            measure={metric.measure}
            dimensions={metric.dimensions}
          />
        ))}
      </div>
    );
  };

  const renderMetricAdded = () => (
    <Fragment>
      <span styleName="title orange">Metrics were added</span>{' '}
      <span styleName="title-light">{title || 'Unknown alert'}</span>
      {timelineMetrics.length > 1 && (
        <Fragment>
          <i
            styleName="right chevron"
            className={`icon ${isExpanded ? 'icn-arrow16-chevronup' : 'icn-arrow16-chevrondown'}`}
            onClick={() => {
              if (isExpendable) {
                setIsExpanded(!isExpanded);
              }
            }}
          />
          <span styleName="title-light right">{`${timelineMetrics.length} metrics`}</span>
        </Fragment>
      )}
      {timelineMetrics.length >= 1 && renderMetricMeta(updateType, timelineMetrics)}
    </Fragment>
  );

  const renderMetricClosed = () => (
    <Fragment>
      <span styleName="title orange">Metrics were closed</span>{' '}
      <span styleName="title-light">{title || 'Unknown alert'}</span>
      {timelineMetrics.length > 1 && (
        <Fragment>
          <i
            styleName="right chevron"
            className={`icon ${isExpanded ? 'icn-arrow16-chevronup' : 'icn-arrow16-chevrondown'}`}
            onClick={() => {
              if (isExpendable) {
                setIsExpanded(!isExpanded);
              }
            }}
          />
          <span styleName="title-light right">{`${timelineMetrics.length} metrics`}</span>
        </Fragment>
      )}
      {timelineMetrics.length >= 1 && renderMetricMeta(updateType, timelineMetrics)}
    </Fragment>
  );

  const renderDirectionChanged = () => (
    <Fragment>
      <span styleName="title orange">Direction has Changed</span>

      {timelineMetrics.length > 1 && (
        <div styleName="hintText body">{timelineMetrics.length} metrics changed their direction</div>
      )}
      {timelineMetrics.length >= 1 && renderMetricMeta(updateType, timelineMetrics)}
    </Fragment>
  );

  const renderRowByType = () => {
    switch (type) {
      // Alert Events
      case TIMELINE_ENTRIES.ALERT_OPENED.value:
        return (
          <div styleName="header-with-chip" className="justifyContent_space-between">
            <div styleName="header-with-chip">
              <div styleName="title red">Alert opened</div>{' '}
              {metricMeasures.map((measure, index) => (
                <Tooltip type={TYPES.SMALL} content={measure}>
                  <div>
                    <InnerChip
                      // eslint-disable-next-line react/no-array-index-key
                      key={`tleMeasure${index}`}
                      title={measure}
                      displayOnly
                      type="measure"
                      className={classes.innerChip}
                    />
                  </div>
                </Tooltip>
              ))}
            </div>
            <div styleName="title-light right">
              {afterTime ? `after ${getFormattedHumanDuration(afterTime, 'absolute')}` : null}
            </div>
          </div>
        );
      case TIMELINE_ENTRIES.ALERT_CLOSED.value:
        return (
          <Fragment>
            <span styleName="title mint">Alert Closed</span>{' '}
            <span styleName="title-light">
              {getCloseReasonText(timelineMetrics)}
              <Info
                text={
                  <div>
                    <p>Timeout periods for Static and No Data alerts:</p>
                    <p>1 minute, 5 minutes - 7 days</p>
                    <p>1 hour, 1 day, 1 week - 30 days</p>
                  </div>
                }
                tooltipType={TYPES.LARGE}
              />
            </span>
            <span styleName="title-light right">
              {openFor ? `Over ${getFormattedHumanDuration(openFor, 'absolute')}` : null}
            </span>
          </Fragment>
        );
      case TIMELINE_ENTRIES.ANOTHER_ALERT_ADDED.value:
        return (
          <Fragment>
            <span styleName="title orange">Another alert added</span> <span styleName="title-light">{title}</span>
          </Fragment>
        );
      // Anomaly Events
      case TIMELINE_ENTRIES.ANOMALY_STARTED.value:
        return (
          <div styleName="top-line-container">
            <div styleName="title red">Anomaly Started on </div>
            {metricMeasures.map((measure, index) => (
              <Tooltip type={TYPES.SMALL} content={measure}>
                <div>
                  <InnerChip
                    // eslint-disable-next-line react/no-array-index-key
                    key={`tleMeasure${index}`}
                    title={measure}
                    displayOnly
                    type="measure"
                    className={classes.innerChip}
                  />
                </div>
              </Tooltip>
            ))}
          </div>
        );
      case TIMELINE_ENTRIES.ANOMALY_UPDATED.value:
        if (updateType === 'METRIC_ADDED') {
          return renderMetricAdded();
        }
        if (updateType === 'METRIC_CLOSED') {
          return renderMetricClosed();
        }
        if (updateType === 'DIRECTION_CHANGED') {
          return renderDirectionChanged();
        }
        break;
      case TIMELINE_ENTRIES.NO_DATA_STARTED.value:
        return (
          <div styleName="header-with-chip">
            <span styleName="title red">Data is missing on</span>{' '}
            {metricMeasures.map((measure, index) => (
              <Tooltip type={TYPES.SMALL} content={measure}>
                <div>
                  <InnerChip
                    // eslint-disable-next-line react/no-array-index-key
                    key={`tleMeasure${index}`}
                    title={measure}
                    displayOnly
                    type="measure"
                    className={classes.innerChip}
                  />
                </div>
              </Tooltip>
            ))}
            {/* timelineMetrics.length > 1 && (
              <Fragment>
                <i
                  styleName="right chevron"
                  className={`icon ${isExpanded ? 'icn-arrow16-chevronup' : 'icn-arrow16-chevrondown'}`}
                  onClick={() => {
                    if (isExpendable) {
                      setIsExpanded(!isExpanded);
                    }
                  }}
                />
                <span styleName="title-light right">{`${timelineMetrics.length} metrics`}</span>
              </Fragment>
            ) */}
            {/* timelineMetrics.length >= 1 && renderMetricMeta(updateType, timelineMetrics) */}
          </div>
        );
      case TIMELINE_ENTRIES.STATIC_STARTED.value:
        return (
          <div styleName="header-with-chip">
            <span styleName="title red">Threshold crossed</span>{' '}
            {metricMeasures.map((measure, index) => (
              <Tooltip type={TYPES.SMALL} content={measure}>
                <div>
                  <InnerChip
                    // eslint-disable-next-line react/no-array-index-key
                    key={`tleMeasure${index}`}
                    title={measure}
                    displayOnly
                    type="measure"
                    className={classes.innerChip}
                  />
                </div>
              </Tooltip>
            ))}
          </div>
        );
      case TIMELINE_ENTRIES.STATIC_UPDATED.value:
        return (
          <Fragment>
            <span styleName="title mint">Alert Closed</span>{' '}
            <span styleName="title-light">{title || 'Unknown alert'}</span>
            <span styleName="title-light right">
              {openFor ? `open for ${getFormattedHumanDuration(openFor, 'absolute')}` : null}
            </span>
          </Fragment>
        );
      case TIMELINE_ENTRIES.NO_DATA_UPDATED.value:
        return (
          <Fragment>
            <span styleName="title mint">Alert Closed</span>{' '}
            <span styleName="title-light">{title || 'Unknown alert'}</span>
            <span styleName="title-light right">
              {openFor ? `open for ${getFormattedHumanDuration(openFor, 'absolute')}` : null}
            </span>
          </Fragment>
        );
      // Other Events
      case TIMELINE_ENTRIES.COMMENT.value:
        return (
          <Fragment>
            <div styleName="title name">{firstName || 'Anonymous'}</div>{' '}
            <div styleName="title name">{lastName || null}</div> <div styleName="caption body">{comment}</div>
          </Fragment>
        );
      case TIMELINE_ENTRIES.FEEDBACK.value:
        return (
          <Fragment>
            <span styleName="title name">{firstName || userName || 'Anonymous'}</span>
            {lastName ? ' ' : ''}
            <span styleName="title name">{lastName || null}</span>{' '}
            <span styleName="title-light">marked this alert as</span>{' '}
            <span styleName="title conjunctions">{TIMELINE_ENTRIES.FEEDBACK[feedbackType].title}</span>{' '}
            <span styleName="title-light">{title || 'Unknown alert'}</span>
            {comment ? <div styleName="title-light second-line">{comment}</div> : null}
          </Fragment>
        );
      case TIMELINE_ENTRIES.ACKNOWLEDGE.value:
        return (
          <Fragment>
            <span styleName="title conjunctions">{TIMELINE_ENTRIES.ACKNOWLEDGE[ackOperation].title} by</span>{' '}
            <span styleName="title name">{firstName || 'Anonymous'}</span>
            {lastName ? ' ' : ''}
            <span styleName="title name">{lastName || null}</span>{' '}
            <span styleName="title-light">{title || 'Unknown alert'}</span>
          </Fragment>
        );
      case TIMELINE_ENTRIES.USER_EVENT.value:
        if (topEvents.length > 6) {
          return (
            <Fragment>
              <div styleName="title conjunctions">{`${topEvents.length} Events occurred - ${topEvents[0].category}`}</div>
            </Fragment>
          );
        }
        if (topEvents.length > 1) {
          return (
            <Fragment>
              <div styleName="title conjunctions">
                {`${topEvents.length} Events occurred - ${topEvents[0].category}`}
                <i
                  styleName="right chevron"
                  className={`icon ${isExpanded ? 'icn-arrow16-chevronup' : 'icn-arrow16-chevrondown'}`}
                  onClick={() => {
                    if (isExpendable) {
                      setIsExpanded(!isExpanded);
                    }
                  }}
                />
              </div>
              {topEvents.map((topEvent, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`tleUserEvents${index}`} styleName="body title-light">
                  {topEvent.title}
                </div>
              ))}
            </Fragment>
          );
        }
        return (
          <Fragment>
            <div styleName="title conjunctions">{topEvents[0].title}</div>
            <div styleName="body title-light">{topEvents[0].description}</div>
          </Fragment>
        );
      case TIMELINE_ENTRIES.ASSIGNEE_CHANGED.value:
        return (
          <Fragment>
            {newAssignee ? (
              <>
                <div styleName="plain caption">Assigned</div>
                {previousAssignee && (
                  <>
                    <div styleName="plain caption"> from </div>
                    <div styleName="title name">{`${prevAssigneeFirstName || ''} ${prevAssigneeLastName || ''}`}</div>
                  </>
                )}
                <div styleName="caption plain"> to </div>
                <div styleName="title name">{`${newAssigneeFirstName || ''} ${newAssigneeLastName || ''}`}</div>
              </>
            ) : (
              <>
                <div styleName="caption plain">Unassigned from </div>
                <div styleName="title name">{`${prevAssigneeFirstName || ''} ${prevAssigneeLastName || ''}`}</div>
              </>
            )}
            {userId && (
              <>
                <div styleName="caption plain"> by </div>
                <div styleName="title name">{`${firstName || null} ${lastName || null}`}</div>
              </>
            )}
            <div>
              <div styleName="hintText body">{title}</div>
            </div>
          </Fragment>
        );
      default:
        return <div>default</div>;
    }
    return false;
  };

  return (
    <div styleName="mainContainer">
      <div styleName="eventWrapper">
        <div styleName="iconWrapper">
          <i
            className={`icon ${
              TIMELINE_ENTRIES[type] === TIMELINE_ENTRIES.FEEDBACK
                ? TIMELINE_ENTRIES.FEEDBACK[feedbackType].icon
                : TIMELINE_ENTRIES[type].icon
            } ${classes.icon}`}
            style={{color: TIMELINE_ENTRIES[type].color, backgroundColor: TIMELINE_ENTRIES[type].bgColor}}
          />
        </div>
        <div styleName="dateWrapper">
          <Date startDate={dataTime || createdTime} timeZoneName={timeZoneName} />
        </div>
        <div ref={rowWrapper} styleName="infoWrapper" style={{height: maxHeight}}>
          <div ref={row}>{renderRowByType(TIMELINE_ENTRIES[type].value)}</div>
        </div>
      </div>
      <TimelineStatusLine isLast={props.isLast} alertStatus={props.timelineEvent.alertStatus} top={20} />
    </div>
  );
};

TimelineEventIncremented.propTypes = {
  timelineEvent: PropTypes.objectOf(PropTypes.any).isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default memo(TimelineEventIncremented);
