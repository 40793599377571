import {QUERY_KEYS} from 'reactQuery/queryKeys';

export const FEEDBACK_ACTION_TYPES = {
  SET: 'SET',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
};

export const ASSIGN_ACTION_TYPES = {
  ASSIGN: 'ASSIGN',
  UN_ASSIGN: 'UN_ASSIGN',
};

// Since there is no filter for Read/Not-read triggered alerts - we can update all the query keys cache without resetting.
export const setAlertAsReadInCache = (alertTriggeredId, readParams, queryClient) => {
  queryClient.setQueriesData(QUERY_KEYS.triggeredAlerts, (data) => {
    const triggers = data?.triggers?.length ? [...data.triggers] : [];
    if (triggers) {
      const alertsGroup = triggers.find((ag) => ag.alerts?.find((a) => a.alertTriggerId === alertTriggeredId));
      if (alertsGroup) {
        const alertToSetAsRead = alertsGroup.alerts.find((a) => a.alertTriggerId === alertTriggeredId);
        if (alertToSetAsRead) {
          alertToSetAsRead.read = readParams.isRead;
        }
      }
    }
    return {
      ...data,
      triggers,
    };
  });
};

export const syncAcksInCache = (alertTriggeredId, ackParams, queryKey, queryClient) => {
  queryClient.setQueriesData(queryKey, (data) => {
    const triggers = data?.triggers?.length ? [...data.triggers] : [];
    if (triggers) {
      const alertsGroup = triggers.find((ag) => ag.alerts?.find((a) => a.alertTriggerId === alertTriggeredId));
      if (alertsGroup) {
        const alert = alertsGroup.alerts.find((a) => a.alertTriggerId === alertTriggeredId);
        if (alert) {
          // If acknowledge removed by user and also indeed found in userIds - remove ack.
          if (!ackParams.isAcknowledged && alert.acks?.userIds) {
            const index = alert.acks.userIds.findIndex((user) => user.id === ackParams.meUser?.id);
            if (index > -1) {
              alert.acks.userIds.splice(index, 1);
            }
          }
          // If acknowledged and not already found in userIds - add ack.
          else if (ackParams.isAcknowledged) {
            let index = -1;
            if (!alert.acks?.userIds) {
              alert.acks = {userIds: []};
            } else {
              index = alert.acks.userIds.findIndex((user) => user.id === ackParams.meUser?.id);
            }
            if (index === -1) {
              alert.acks.userIds.push(ackParams.meUser);
            }
          }
        }
      }
    }
    return {
      ...data,
      triggers,
    };
  });
};

export const syncAssigneeInCache = (alertTriggeredId, assigneeParams, queryKey, queryClient) => {
  queryClient.setQueriesData(queryKey, (data) => {
    const triggers = data?.triggers?.length ? [...data.triggers] : [];
    if (triggers) {
      const alertsGroup = triggers.find((ag) => ag.alerts?.find((a) => a.alertTriggerId === alertTriggeredId));
      if (alertsGroup) {
        const alert = alertsGroup.alerts.find((a) => a.alertTriggerId === alertTriggeredId);
        if (alert) {
          if (assigneeParams?.action === ASSIGN_ACTION_TYPES.ASSIGN && assigneeParams?.assignee) {
            alert.assignee = assigneeParams.assignee;
          }
          if (assigneeParams?.action === ASSIGN_ACTION_TYPES.UN_ASSIGN) {
            delete alert.assignee;
          }
        }
      }
    }
    return {
      ...data,
      triggers,
    };
  });
};

export const syncFeedbackInCache = (alertTriggeredId, feedbackAction, feedbackParams, queryKey, queryClient) => {
  queryClient.setQueriesData(queryKey, (data) => {
    const triggers = data?.triggers?.length ? [...data.triggers] : [];
    if (triggers) {
      const alertsGroup = triggers.find((ag) => ag.alerts?.find((a) => a.alertTriggerId === alertTriggeredId));
      if (alertsGroup) {
        const alert = alertsGroup.alerts.find((a) => a.alertTriggerId === alertTriggeredId);
        if (alert) {
          switch (feedbackAction) {
            case FEEDBACK_ACTION_TYPES.SET:
              if (alert.feedback?.length && feedbackParams?.feedbackId) {
                const feedback = alert.feedback.find((f) => f.userId === feedbackParams.userId);
                if (feedback) {
                  feedback.id = feedbackParams.feedbackId;
                }
              } else {
                alert.feedback = alert.feedback || [];
                alert.feedback.push({
                  id: undefined,
                  comment: feedbackParams?.comment || '',
                  modifiedTime: Date.now(),
                  reason: feedbackParams?.reason || '',
                  type: feedbackParams.type,
                  userId: feedbackParams.userId,
                  userName: feedbackParams.userName,
                });
              }
              break;
            case FEEDBACK_ACTION_TYPES.UPDATE:
              if (alert.feedback?.length && feedbackParams?.feedbackId) {
                const feedback = alert.feedback.find((f) => f.id === feedbackParams.feedbackId);
                if (feedback) {
                  feedback.type = feedbackParams.type || alert.feedback.type;
                  feedback.comment = feedbackParams?.comment || alert.feedback.comment;
                  feedback.modifiedTime = Date.now();
                  feedback.reason = feedbackParams?.reason || alert.feedback.reason;
                }
              }
              break;
            case FEEDBACK_ACTION_TYPES.DELETE:
              if (alert.feedback?.length) {
                if (feedbackParams?.feedbackId) {
                  const index = alert.feedback.findIndex((f) => f.id === feedbackParams.feedbackId);
                  if (index > -1) {
                    alert.feedback.splice(index, 1);
                  }
                } else if (feedbackParams.userId) {
                  const index = alert.feedback.findIndex((f) => f.userId === feedbackParams.userId);
                  if (index > -1) {
                    alert.feedback.splice(index, 1);
                  }
                }
              }
              break;
            default:
              break;
          }
        }
      }
    }
    return {
      ...data,
      triggers,
    };
  });
};

export const syncSnoozeInCache = () => {
  // alertTriggeredId, snoozeParams, queryClient
  // TOD (IB): Should I implement?
};
