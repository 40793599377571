// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import {REGIONS} from 'bc/services/genericStorageService';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import * as selectors from 'bc/store/selectors';
import {
  createDataSource as createDataSourceAction,
  fetchAthenaDatabasesList as fetchAthenaDatabasesListAction,
  fetchAthenaWorkgroupDescription as fetchAthenaWorkgroupDescriptionAction,
  fetchAthenaWorkgroupsList as fetchAthenaWorkgroupsListAction,
  resetAddSourceError as resetAddSourceErrorAction,
  resetAthenaDatabasesList as resetAthenaDatabasesListAction,
  resetAthenaWorkgroupDescription as resetAthenaWorkgroupDescriptionAction,
  resetAthenaWorkgroupList as resetAthenaWorkgroupListAction,
} from 'bc/store/actions';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import Loading from 'metrics/components/Loading';
import DataSourceModal from '../../DataSourceModal';

import '../../DataSourceModal.module.scss';

type PropTypes = {
  isOpen: Boolean,
  isViewOnly: Boolean,
  isEditable: Boolean,
  source: Object,
  sourceType: Object,
  isLoading: Boolean,
  sourceError: Object,
  onClose: Function,
  createDataSource: Function,
  resetAddSourceError: Function,

  fetchAthenaWorkgroupsList: Function,
  resetAthenaWorkgroupList: Function,
  isWorkgroupsListLoading: Boolean,
  workgroupsListError: Object,
  workgroupsList: Array,

  fetchAthenaWorkgroupDescription: Function,
  resetAthenaWorkgroupDescription: Function,
  isWorkgroupDescriptionLoading: Boolean,
  workgroupDescriptionError: Object,
  workgroupDescriptionData: Object,

  fetchAthenaDatabasesList: Function,
  resetAthenaDatabasesList: Function,
  isAthenaDatabasesListLoading: Boolean,
  athenaDatabasesListError: Object,
  athenaDatabasesList: Object,
};

export default connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,

    isWorkgroupsListLoading: selectors.getWorkgroupsList(state).isLoading,
    workgroupsListError: selectors.getWorkgroupsList(state).error,
    workgroupsList: selectors.getWorkgroupsListItems(state),

    isWorkgroupDescriptionLoading: selectors.getWorkgroupDescription(state).isLoading,
    workgroupDescriptionError: selectors.getWorkgroupDescription(state).error,
    workgroupDescriptionData: selectors.getWorkgroupDescriptionData(state),

    isAthenaDatabasesListLoading: selectors.getAthenaDatabasesList(state).isLoading,
    athenaDatabasesListError: selectors.getAthenaDatabasesList(state).error,
    athenaDatabasesList: selectors.getAthenaDatabasesListData(state),
  }),
  {
    createDataSource: createDataSourceAction,
    resetAddSourceError: resetAddSourceErrorAction,

    fetchAthenaWorkgroupsList: fetchAthenaWorkgroupsListAction,
    resetAthenaWorkgroupList: resetAthenaWorkgroupListAction,
    fetchAthenaWorkgroupDescription: fetchAthenaWorkgroupDescriptionAction,
    resetAthenaWorkgroupDescription: resetAthenaWorkgroupDescriptionAction,
    fetchAthenaDatabasesList: fetchAthenaDatabasesListAction,
    resetAthenaDatabasesList: resetAthenaDatabasesListAction,
  },
)(
  class AthenaSourceModal extends React.PureComponent {
    props: PropTypes;

    // eslint-disable-next-line no-extra-boolean-cast
    state = !!this.props.source
      ? {
          streamName: this.props.source.kStreamName,
          region: REGIONS.find((reg) => reg.value === this.props.source.region),
          roleARN: this.props.source.roleARN,
          workgroup: this.props.source.workgroup,
          bucketName: this.props.source.bucket,
          bucketPath: this.props.source.path,
          isBucketDisabled: true,
          dbName: this.props.source.dbName,
          listWorkspaceEnabled: true,
        }
      : {
          streamName: '',
          roleARN: '',
          region: REGIONS[0],
          workgroup: '',
          bucketName: '',
          bucketPath: '',
          isBucketDisabled: true,
          dbName: '',
          listWorkspaceEnabled: true,
        };

    componentDidUpdate(prevProps) {
      if (
        this.props.workgroupDescriptionData.bucket &&
        this.props.workgroupDescriptionData.bucket !== prevProps.workgroupDescriptionData.bucket &&
        this.props.workgroupDescriptionData.bucket !== this.state.bucketName
      ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          bucketName: this.props.workgroupDescriptionData.bucket,
          bucketPath: this.props.workgroupDescriptionData.path,
          isBucketDisabled: this.props.workgroupDescriptionData.enforce,
        });
      } else if (
        !this.props.workgroupDescriptionData.bucket &&
        this.props.workgroupDescriptionData.bucket !== prevProps.workgroupDescriptionData.bucket
      ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          bucketName: '',
          bucketPath: '',
          isBucketDisabled: false,
        });
      }
    }

    regionChanged = (val) => {
      this.setState({region: val});
    };

    listWorkgroupsBtnClicked = () => {
      const {sourceType} = this.props;
      const {region, roleARN} = this.state;
      this.props.resetAthenaWorkgroupList();
      this.setState({listWorkspaceEnabled: false});
      this.props.fetchAthenaWorkgroupsList({
        roleARN,
        externalId: sourceType.externalId,
        region: region.value,
      });
    };

    workgroupChanged = (val) => {
      const {sourceType} = this.props;
      const {region, roleARN} = this.state;
      this.setState({workgroup: val, dbName: ''});
      if (val !== 'None') {
        this.props.fetchAthenaWorkgroupDescription({
          roleARN,
          externalId: sourceType.externalId,
          region: region.value,
          workgroup: val,
        });
      } else {
        this.props.resetAthenaWorkgroupDescription();
        this.props.resetAthenaDatabasesList();
        this.setState({
          bucketPath: '',
          bucketName: '',
          isBucketDisabled: false,
        });
      }
    };

    listDatabasesBtnClicked = () => {
      const {sourceType} = this.props;
      const {region, roleARN, workgroup, bucketName, bucketPath} = this.state;

      this.setState({dbName: ''});

      const reqObj = {
        roleARN,
        externalId: sourceType.externalId,
        region: region.value,
        outputLocation: `s3://${bucketName}/${bucketPath}`,
      };
      if (workgroup !== 'None') {
        reqObj.workgroup = workgroup;
      }
      this.props.resetAthenaDatabasesList();
      this.props.fetchAthenaDatabasesList({obj: reqObj});
    };

    dbNameChanged = (val) => {
      this.setState({dbName: val});
    };

    isContinueDisabled = () => {
      const {isWorkgroupsListLoading, isWorkgroupDescriptionLoading, isAthenaDatabasesListLoading} = this.props;
      const {region, roleARN, bucketName, bucketPath, dbName} = this.state;
      return (
        isWorkgroupsListLoading ||
        isWorkgroupDescriptionLoading ||
        isAthenaDatabasesListLoading ||
        !region ||
        !roleARN ||
        !bucketName ||
        !bucketPath ||
        !dbName
      );
    };

    createBtnClicked = () => {
      const {sourceType, createDataSource} = this.props;
      const {region, dbName, roleARN, bucketName, bucketPath, workgroup} = this.state;
      const obj = {
        type: getTypeDetails(sourceType.type).type,
        name: `${getTypeDetails(sourceType.type).shortName} ${
          this.state.dbName
        } Data Source ${new Date().getTime().toString()}`,
        region: region.value,
        dbName,
        roleARN,
        externalId: sourceType.externalId,
        bucket: bucketName,
        path: bucketPath,
      };
      if (this.state.workgroup && this.state.workgroup !== 'None') {
        obj.workgroup = workgroup;
      }
      createDataSource(obj);
    };

    onClose = () => {
      this.props.resetAthenaWorkgroupList();
      this.props.resetAthenaWorkgroupDescription();
      this.props.resetAthenaDatabasesList();
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    render() {
      const {
        isOpen,
        source,
        sourceType,
        sourceError,
        isViewOnly,
        isEditable,
        isLoading,
        isWorkgroupsListLoading,
        workgroupsListError,
        workgroupsList,
        isWorkgroupDescriptionLoading,
        workgroupDescriptionError,
        isAthenaDatabasesListLoading,
        athenaDatabasesListError,
        athenaDatabasesList,
      } = this.props;

      const {
        region,
        roleARN,
        workgroup,
        bucketName,
        bucketPath,
        isBucketDisabled,
        dbName,
        listWorkspaceEnabled,
      } = this.state;

      const errorObj = sourceError || workgroupsListError || workgroupDescriptionError || athenaDatabasesListError;

      const selectedIndexRegion = REGIONS.findIndex((val) => val.value === region.value);
      const selectedIndexWorkgroups = workgroupsList.findIndex((val) => workgroup === val.name);
      const selectedIndexDatabase = athenaDatabasesList.findIndex((val) => dbName === val.name);
      const selectedDatabase = athenaDatabasesList[selectedIndexDatabase] || '';

      return (
        <DataSourceModal
          isOpen={isOpen}
          isProcessing={isLoading}
          isViewOnly={isViewOnly}
          isRenameEnabled={isEditable}
          source={source}
          sourceType={sourceType}
          logoImageClass={`source-logo-${sourceType.type}`}
          onClose={this.onClose}
          isContinueDisabled={this.isContinueDisabled()}
          onContinue={this.createBtnClicked}
        >
          <div styleName="inputs-wrapper">
            <SelectAndt
              id="s3RegionDdl1"
              automationId="s3Regions"
              styleName="region-dropdown"
              className="andt-dropdown"
              disabled={isViewOnly}
              options={REGIONS}
              getOptionLabel={(val) => val.text}
              getOptionValue={(val) => val.value}
              type={TYPE_NO_SEARCH}
              theme={THEME_LIGHT}
              value={REGIONS[selectedIndexRegion]}
              onChange={(item) => this.regionChanged(item)}
              placeholder="Choose Region"
            />

            <input type="text" readOnly disabled placeholder="Source ID" value={sourceType.externalId} />

            <CopyToClipboardButton
              copyStr={sourceType.externalId}
              tooltipMessage="Copy the External ID to clipboard"
              styleName="copy-clipboard-btn-pos"
              shouldUseIcon
            />

            <input
              type="text"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              styleName="role-margin"
              onChange={(e) => this.setState({roleARN: e.target.value, listWorkspaceEnabled: true})}
              placeholder="Role ARN"
              value={roleARN}
            />

            <div styleName="test-wrapper">
              <button
                type="button"
                className="btn btn-raised"
                disabled={!roleARN || !listWorkspaceEnabled || isViewOnly}
                readOnly={isViewOnly}
                onClick={this.listWorkgroupsBtnClicked}
              >
                LIST WORKGROUPS
              </button>
              {isWorkgroupsListLoading ? <Loading styleName="spinner" /> : null}
            </div>

            {isViewOnly ? (
              <input type="text" readOnly disabled value={workgroup} />
            ) : (
              <SelectAndt
                id="athenaWorkgoupsDdl"
                automationId="athenaWorkgoupsDropdown"
                disabled={isWorkgroupsListLoading || !workgroupsList.length || isViewOnly}
                options={workgroupsList}
                getOptionLabel={(val) => val.name}
                getOptionValue={(val) => val.name}
                type={TYPE_NO_SEARCH}
                theme={THEME_LIGHT}
                value={workgroupsList[selectedIndexWorkgroups]}
                onChange={(item) => this.workgroupChanged(item.name)}
                placeholder="Workgroups"
              />
            )}

            <div styleName="test-wrapper">
              <input
                type="text"
                styleName="text-with-spinner"
                readOnly={isBucketDisabled || isViewOnly}
                disabled={isBucketDisabled || isViewOnly}
                onChange={(e) => this.setState({bucketName: e.target.value})}
                placeholder="S3 output bucket name"
                value={bucketName}
              />
              {isWorkgroupDescriptionLoading ? <Loading styleName="spinner-text" /> : null}
            </div>

            <div styleName="test-wrapper">
              <input
                type="text"
                styleName="text-with-spinner"
                readOnly={isBucketDisabled || isViewOnly}
                disabled={isBucketDisabled || isViewOnly}
                onChange={(e) => this.setState({bucketPath: e.target.value})}
                placeholder="S3 output bucket path"
                value={bucketPath}
              />
              {isWorkgroupDescriptionLoading ? <Loading styleName="spinner-text" /> : null}
            </div>

            <div styleName="test-wrapper">
              <button
                type="button"
                className="btn btn-raised"
                disabled={!roleARN || !bucketName || !bucketPath || isViewOnly}
                readOnly={isViewOnly}
                onClick={this.listDatabasesBtnClicked}
              >
                LIST DATABASES
              </button>
              {isAthenaDatabasesListLoading ? <Loading styleName="spinner" /> : null}
            </div>

            {isViewOnly ? (
              <input type="text" readOnly disabled value={dbName} />
            ) : (
              <SelectAndt
                id="athenaDatabaseDdl"
                automationId="athenaDatabase"
                disabled={isAthenaDatabasesListLoading || !athenaDatabasesList.length || isViewOnly}
                options={athenaDatabasesList}
                getOptionLabel={(val) => val.name}
                getOptionValue={(val) => val.name}
                type={TYPE_NO_SEARCH}
                theme={THEME_LIGHT}
                value={selectedDatabase}
                onChange={(item) => this.dbNameChanged(item.name)}
                placeholder="Database"
              />
            )}

            {!isLoading && errorObj ? (
              <div styleName="error-wrapper-athena">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" /> {errorObj.uMessage}
                </span>
              </div>
            ) : null}

            <div styleName="extra-info">
              <a
                href="https://support.anodot.com/hc/en-us/articles/4403273022098-AWS-Athena-JDBC-Collector"
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn more
              </a>
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
