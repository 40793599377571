import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {success} from 'common/utils/notifications/notificationsService';
import {getSelectedDataStream} from 'bc/store/selectors';
import {getRoutingLocation} from 'common/store/selectors';
import * as api from '../services/api';
import * as selectors from './selectors';
import * as actions from './actions';
import {ORIGIN_TYPES, IMPACT_SUCCESS_MSG} from '../services/constants';

const displaySnackBar = (type) => {
  const date = new Date();
  return [
    success({
      title: IMPACT_SUCCESS_MSG[type].title,
      description: IMPACT_SUCCESS_MSG[type].description,
      settings: {
        uid: `impact_success_msg${type}${date.getTime()}`,
      },
    }),
  ];
};

const getSingleImpact = makeAsyncEpic(actions.getSingleImpactNewAlertConsole, api.getSingleImpact);
const getImpactsByOrigin = makeAsyncEpic(actions.getImpactsByOriginNewAlertConsole, api.getImpactsByOrigin);
const putImpact = makeAsyncEpic(actions.putImpactNewAlertConsole, api.putImpact);
const postImpact = makeAsyncEpic(actions.postImpactNewAlertConsole, api.postImpact);
const deleteImpact = makeAsyncEpic(actions.deleteImpactNewAlertConsole, api.deleteImpact);
const deleteImpactsByOrigin = makeAsyncEpic(actions.deleteImpactsByOriginNewAlertConsole, api.deleteImpactsByOrigin);

const addImpact = (action$) =>
  action$
    .ofType(actions.getSingleImpactNewAlertConsole.success.TYPE)
    .switchMap(({payload}) => [actions.addImpactNewAlertConsole(payload)]);

const addImpacts = (action$) =>
  action$
    .ofType(actions.getImpactsByOriginNewAlertConsole.success.TYPE)
    .switchMap(({payload}) => [actions.addImpactsNewAlertConsole(payload)]);

const updateImpact = (action$, {getState}) =>
  action$.ofType(actions.updateImpactNewAlertConsole.TYPE).switchMap(({payload}) => {
    const impacts = selectors.getImpacts(getState());
    const impact = impacts[payload];

    return [
      actions.putImpactNewAlertConsole([
        {
          id: impact.id,
          factor: impact.factor,
          currency: impact.currency,
          spike: impact.spike,
          drop: impact.drop,
        },
      ]),
    ];
  });

const createImpact = (action$, {getState}) =>
  action$.ofType(actions.createImpactNewAlertConsole.TYPE).switchMap(({payload}) => {
    const impacts = selectors.getImpacts(getState());
    const impact = impacts[payload];

    return [
      actions.postImpactNewAlertConsole([
        {
          factor: impact.factor,
          currency: impact.currency,
          spike: impact.spike,
          drop: impact.drop,
          measure: impact.measure,
          originType: impact.originType,
          originId: impact.originId,
        },
      ]),
    ];
  });

const removeImpact = (action$) =>
  action$
    .ofType(actions.removeImpactNewAlertConsole.TYPE)
    .switchMap(({payload}) => [actions.deleteImpactNewAlertConsole([payload])]);

const createSucceeded = (action$) =>
  action$.ofType(actions.postImpactNewAlertConsole.success.TYPE).switchMap(() => displaySnackBar('create'));

const updateSucceeded = (action$) =>
  action$.ofType(actions.putImpactNewAlertConsole.success.TYPE).switchMap(() => displaySnackBar('update'));

const removeSucceeded = (action$) =>
  action$.ofType(actions.deleteImpactNewAlertConsole.success.TYPE).switchMap(() => displaySnackBar('remove'));

const restOperationSucceeded = (action$, {getState}) =>
  action$
    .ofType(
      actions.postImpactNewAlertConsole.success.TYPE,
      actions.putImpactNewAlertConsole.success.TYPE,
      actions.deleteImpactNewAlertConsole.success.TYPE,
    )
    .switchMap(() => {
      const state = getState();
      const selectedDataStreamSource = getSelectedDataStream(state);
      const pathnameArr = getRoutingLocation(state).pathname.split('/');

      if (pathnameArr.includes('bc') || pathnameArr.includes('"data-manager')) {
        return [
          actions.getImpactsByOriginNewAlertConsole({
            originId: selectedDataStreamSource.id,
            originType: ORIGIN_TYPES.stream,
          }),
        ];
      }
      return [];
    });

export default combineEpics(
  getSingleImpact,
  getImpactsByOrigin,
  addImpact,
  addImpacts,
  updateImpact,
  putImpact,
  createImpact,
  postImpact,
  deleteImpact,
  deleteImpactsByOrigin,
  removeImpact,
  createSucceeded,
  updateSucceeded,
  removeSucceeded,
  restOperationSucceeded,
);
