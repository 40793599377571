/* eslint-disable no-param-reassign */
import {createSelector} from 'reselect';
import {cloneDeep, get, sortBy, isEqual} from 'lodash';
import deletePropFromObj from 'common/utils/deletePropFromObj';
import {getUniqueId} from 'common/utils/guid';
import {selectors as commonSelectors} from 'common';
import {getSelectedAlertDefinition} from 'alerts.management/store/selectors';
import {getNotEmptyNodeId, treeVisitor} from '../services/metricsService';
import {STACKING} from '../components/metricExplorer/seriesPropertiesConsts';

export const {getMetrics} = commonSelectors;

/* eslint no-underscore-dangle: 0 */

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const getMetricsData = createSelector(
  getMetrics,
  (metrics) => metrics.data,
);

export const getMetricsView = createSelector(
  getMetrics,
  (metrics) => metrics.views,
);

//* **metrics
export const getMetricResolutions = createSelector(
  getMetricsData,
  (metricsData) => metricsData.resolutions.types,
);
//
export const getCustomerMetricsCount = createSelector(
  getMetricsData,
  (metricsData) => metricsData.customerMetricsCount,
);

export const getCustomerMetricsCountRes = createSelector(
  getCustomerMetricsCount,
  (mc) => mc.data && mc.data.numOfMetrics,
);

export const getCustomerMetricsCountIsLoading = createSelector(
  getCustomerMetricsCount,
  (mc) => mc.isLoading !== false,
);

// for now is hard coded, in future will be api call that will return this meta
export const getMetricValidationMeta = createSelector(
  getMetricsData,
  (metricsData) => metricsData.validationMeta,
);

export const getFetchMetricsTotal = createSelector(
  getMetricsData,
  (metricsData) => get(metricsData, 'fetchMetricsTotal.data.total', null),
);

export const getRefetchExecuteData = createSelector(
  getMetricsView,
  (metricsView) => get(metricsView, 'viewState.refetchExecuteData', null),
);

export const getIsLoadingMetricsTotal = createSelector(
  getMetricsData,
  (metricsData) => get(metricsData, 'fetchMetricsTotal.isLoading', false),
);

export const getPropertiesApiData = createSelector(
  getMetricsData,
  (metricsData) => get(metricsData, 'fetchPropertiesApi.data.properties', []),
);

export const getPropertiesApiIsLoading = createSelector(
  getMetricsData,
  (metricsData) => get(metricsData, 'fetchPropertiesApi.isLoading', false),
);

// Expression Builder
// TODO -- should be in “view” inside the redux store

export const getExpressionBuilder = createSelector(
  getMetricsView,
  (views) => views.expressionBuilder,
);

export const getChartDisplayProperties = createSelector(
  getMetricsView,
  (views) => views.chartDisplayProperties,
);

export const getViewState = createSelector(
  getMetricsView,
  (views) => views.viewState,
);

export const getSelectedTreeChartDisplay = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.selectedTreeChartDisplay,
);

export const getPreviewTimeRange = createSelector(
  // GABPAC - todo -- temporary
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.previewTimeRange || EMPTY_OBJECT,
);

export const getMergedMetrics = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.mergedMetrics || EMPTY_ARRAY,
);

export const getMergedMetricsLoading = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.isMergedMetricLoading,
);

export const getContext = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.context,
);

export const getCurrentCall = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.currentCall,
);

export const getCacheData = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.cacheData || EMPTY_ARRAY,
);

export const getMainPanelOptions = createSelector(
  getCacheData,
  getCurrentCall,
  (cacheData, currentCall) => (cacheData.find((item) => item.index === currentCall) || {}).fetchedData || EMPTY_ARRAY,
);

export const getSecondaryPanelOptions = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.secondPanelOptions || EMPTY_ARRAY,
);

export const getFunctionProperties = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.functionProperties || EMPTY_ARRAY,
);

export const getFetchPropAndValListApi = createSelector(
  getMetricsData,
  (metricData) => metricData.fetchPropAndValListApi || EMPTY_OBJECT,
);

export const getFetchPropIsLoading = createSelector(
  getMetricsData,
  (metricData) => metricData.fetchPropAndValListApi.isLoading,
);

export const getComposites = createSelector(
  getMetricsData,
  (metricData) => metricData.fetchComposites.data,
);

export const getFetchPropAndVal = createSelector(
  getMetricsData,
  (metricData) => metricData.fetchPropAndVal || EMPTY_OBJECT,
);

export const getIsFetchPropAndValLoading = createSelector(
  getCacheData,
  getCurrentCall,
  (cacheData, currentCall) => (cacheData.find((item) => item.index === currentCall) || {}).isLoading,
);

// export const getIsFetchPropAndValLoading = createSelector(//bookmark
//   getFetchPropAndVal,
//   (propAndVal) => propAndVal.isLoading,
// );

export const getIsFetchPropAndValApiListLoading = createSelector(
  getFetchPropAndValListApi,
  (propAndValList) => propAndValList.isLoading,
);

export const getExecuteData = createSelector(
  getMetricsData,
  (metricData) => metricData.execute,
);

export const getCompositeData = createSelector(
  getExecuteData,
  (executeData) => executeData.data || EMPTY_OBJECT,
);

export const getCompositeDataIsLoading = createSelector(
  getExecuteData,
  (executeData) => executeData.isLoading,
);

export const getValidate = createSelector(
  getMetricsData,
  (metricData) => metricData.validate,
);

export const getValidationData = createSelector(
  getValidate,
  (validate) => validate.data || EMPTY_OBJECT,
);

export const getMetricsForCompositeData = createSelector(
  getCompositeData,
  (compositeData) => compositeData.metrics || EMPTY_ARRAY,
);

export const getFunctionDefinitions = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => {
    if (expressionBuilder.functionDefinitions.length) {
      const ret = [
        ...expressionBuilder.functionDefinitions.slice(0, 4),
        {value: 'moreFunctions', displayName: 'More Functions:', header: true},
        ...expressionBuilder.functionDefinitions.slice(4),
      ];
      return ret;
    }
    return EMPTY_ARRAY;
  },
);

export const getRawFunctionDefinitions = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => {
    if (expressionBuilder.rawFunctionDefinitions?.length) {
      return [...expressionBuilder.rawFunctionDefinitions];
    }
    return EMPTY_ARRAY;
  },
);

export const getFunctionDefinitionsFiltered = createSelector(
  getFunctionDefinitions,
  (functionDefinitions) =>
    functionDefinitions.map((definition) => {
      if (definition.multi) {
        return {...definition, multi: definition.multi.filter((item) => item.displayName !== 'Summarize To Date')};
      }
      return definition;
    }),
);

export const getSortFunctionDefinitions = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.sortFunctionDefinitions || EMPTY_ARRAY,
);

export const getOriginInfo = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.originInfo || EMPTY_OBJECT,
);

export const getSortFiltersList = createSelector(
  getSortFunctionDefinitions,
  (functionDefinitions) =>
    sortBy(
      functionDefinitions.map((item) => {
        const label = item.name.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ');
        return {
          value: item.name,
          label: label.slice(0, 1).toUpperCase() + label.slice(1),
        };
      }) || EMPTY_ARRAY,
      'label',
    ),
);

export const getSelectedExpressionId = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.selectedExpressionId,
);

export const getAllExpressionsSelected = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.allExpressionsSelected,
);

export const getSelectedBranch = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.selectedBranch || EMPTY_OBJECT,
);

export const getExpressionTrees = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.expressionTrees || EMPTY_ARRAY,
);

export const getByTreeExp = createSelector(
  getExpressionTrees,
  (expressionTrees) => {
    const transformChartDisplay = (cd) => ({
      type: cd.chartType,
      stacking: cd.stacking === STACKING.NONE ? null : cd.stacking,
      yAxis: {
        opposite: cd.opposite,
        max: cd.higherRange === '' ? null : cd.higherRange,
        min: cd.lowerRange === '' ? null : cd.lowerRange,
        type: cd.yAxisOption,
        minorTickInterval: 'auto',
      },
    });

    return expressionTrees.map((et) => ({id: et.id, options: transformChartDisplay(et.chartDisplay)})) || EMPTY_ARRAY;
  },
);

export const getSelectedChartTree = createSelector(
  getExpressionTrees,
  getSelectedTreeChartDisplay,
  (trees, index) => trees[index] || EMPTY_OBJECT,
);

export const getSelectedTreeDisplayProperties = createSelector(
  getSelectedChartTree,
  (selectedTree) => selectedTree.chartDisplay || EMPTY_OBJECT,
);

export const getChartType = createSelector(
  getSelectedTreeDisplayProperties,
  (displayProps) => displayProps.chartType || '',
);

export const getChartStackingOption = createSelector(
  getSelectedTreeDisplayProperties,
  (displayProperties) => displayProperties.chartStackingOption || '',
);

export const getYAxisOption = createSelector(
  getSelectedTreeDisplayProperties,
  (displayProperties) => displayProperties.yAxisOption || '',
);

export const getYAxisDirection = createSelector(
  getSelectedTreeDisplayProperties,
  (displayProperties) => displayProperties.opposite,
);

export const getLowerRange = createSelector(
  getSelectedTreeDisplayProperties,
  (displayProperties) => displayProperties.lowerRange || '',
);

export const getHigherRange = createSelector(
  getSelectedTreeDisplayProperties,
  (displayProperties) => displayProperties.higherRange || '',
);

export const getIsSeriesPropertiesDirty = createSelector(
  getSelectedTreeDisplayProperties,
  (displayProperties) => displayProperties.isStateDirty,
);

export const getSeriesProperties = createSelector(
  getChartType,
  getChartStackingOption,
  getYAxisOption,
  getYAxisDirection,
  getLowerRange,
  getHigherRange,
  getIsSeriesPropertiesDirty,
  (type, stacking, yAxis, opposite, min, max, isStateDirty) => {
    const ret = {
      type,
      stacking: stacking === STACKING.NONE ? null : stacking,
      yAxis: {
        opposite,
        max: max === '' ? null : max,
        min: min === '' ? null : min,
        type: yAxis,
        minorTickInterval: 'auto',
      },
    };
    return {value: ret, isStateDirty};
  },
);

export const getCurrentExpressionPreviewOption = createSelector(
  getSelectedExpressionId,
  getExpressionTrees,
  (selectedId, expressionTrees) => {
    const selectedExpression = expressionTrees.find((et) => et.id === selectedId);
    if (selectedExpression) {
      return selectedExpression.previewOptions;
    }
    return EMPTY_OBJECT;
  },
);

export const getPreviewOptions = createSelector(
  getExpressionBuilder,
  getCurrentExpressionPreviewOption,
  (expressionBuilder, currentSelectedTreePreview) => {
    const ret = expressionBuilder.previewOptions || EMPTY_OBJECT;
    ret.sort = currentSelectedTreePreview.sort;
    ret.show = currentSelectedTreePreview.show;
    return ret;
  },
);

export const getIsMetricExplorerModalOpen = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.isMetricExplorerModalOpen,
);

export const getSelectedElementId = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.selectedElementId,
);

export const getSelectedTreeRaw = createSelector(
  getSelectedExpressionId,
  getExpressionTrees,
  (selectedExpressionId, expressionsTrees) => {
    const index = expressionsTrees.findIndex((et) => et.id === selectedExpressionId);
    return expressionsTrees[index].expressionTree;
  },
);

export const getExpressionTreeModel = createSelector(
  getSelectedExpressionId,
  getExpressionTrees,
  (selectedExpressionId, expressionTrees) =>
    expressionTrees?.find((treeModel) => treeModel.id === selectedExpressionId),
);

export const getExpressionTree = createSelector(
  getSelectedElementId,
  getSelectedExpressionId,
  getExpressionTrees,
  getAllExpressionsSelected,
  (selectedElementId, selectedExpressionId, expressionsTrees, isAllSelected) => {
    const index = expressionsTrees.findIndex((et) => et.id === selectedExpressionId);
    const rawTree = expressionsTrees[index].expressionTree;
    const {invisible} = expressionsTrees[index];
    expressionsTrees.forEach((et) => {
      if (et.id !== selectedExpressionId) {
        deletePropFromObj(et.expressionTree, 'isSelected');
      }
    });

    deletePropFromObj(rawTree, 'isSelected');
    treeVisitor(rawTree.root, 'children', (node) => {
      if (node.uiData) {
        if (!node.uiData.isSelected) {
          node.uiData.isSelected = node.id === selectedElementId;
        }
      }

      if (isAllSelected) {
        node.uiData.isSelected = !invisible;
      }

      if (node.children.length > 1) {
        (node.children[0].uiData || {}).isSwitchButton = false;
        (node.children[1].uiData || {}).isSwitchButton = false;
        if (['subtractSeries', 'divideSeries', 'ratioPairs', 'pairs'].indexOf(node.function) > -1) {
          if (node.children[1] && node.children[1].uiData) {
            node.children[1].uiData.isSwitchButton = true;
          }
        }
      }

      node.children.forEach((child) => {
        let isDeletable = 'enable';
        let isAutoOpenVal = true;
        let canCreateFunction = true;

        if (child.type === 'metric') {
          isDeletable = 'reset';
        }

        if (['sumSeries', 'subtractSeries', 'divideSeries'].indexOf(node.function) > -1) {
          if (child.type === 'metric') {
            isDeletable = 'reset';
          } else {
            isDeletable = 'enable';
          }
          canCreateFunction = true;
          isAutoOpenVal = true;
        }

        if (['ratioPairs', 'pairs'].indexOf(node.function) > -1) {
          if (child.function === 'groupBy') {
            isDeletable = 'resetParams';
            isAutoOpenVal = false;
            canCreateFunction = false;
          }
        }

        let childIsSelected = false;
        if (node.uiData) {
          childIsSelected = node.uiData.isSelected;
        }

        child.uiData = {
          ...child.uiData,
          isDeletable,
          isAutoOpen: isAutoOpenVal,
          canCreateFunction,
          isSelected: childIsSelected,
        };
      });
    });

    let isDeletable = 'reset';
    if (['ratioPairs', 'pairs', 'sumSeries', 'subtractSeries', 'divideSeries'].indexOf(rawTree.root.function) > -1) {
      if (rawTree.root.children.length > 1) {
        isDeletable = 'resetAndChild';
      } else {
        isDeletable = 'reset';
      }
    }
    if (rawTree.root.children.length > 0 && rawTree.root.children[0].type === 'function') {
      isDeletable = 'enable';
    }
    rawTree.root.uiData = {
      ...rawTree.root.uiData,
      isDeletable,
      isAutoOpen: false,
      canCreateFunction: true,
    };

    return rawTree || EMPTY_OBJECT;
  },
);

export const getStreamsInExpression = createSelector(
  getExpressionTree,
  (expressionTree) => {
    const ret = [];
    treeVisitor(expressionTree.root, 'children', (childNode) => {
      if (childNode.searchObject) {
        childNode.searchObject.expression.forEach((exp) => {
          if (exp.type === 'origin') {
            ret.push(exp.value);
          }
        });
      }
    });
    return ret;
  },
);

export const getExpressionTreeUiData = createSelector(
  getExpressionTree,
  (expressionTree) => expressionTree.root.uiData || EMPTY_OBJECT,
);

export const getIsExecuteValid = createSelector(
  getExpressionTreeUiData,
  (uiData) => {
    if (uiData.failures) {
      //  use this code to ignore the excessive number of metrics error
      //
      if ((uiData.failures[0] || {}).id === 53) {
        return true;
      }
      return uiData.failures.length === 0;
    }
    return false;
  },
);

export const getMultiplePreviewOptions = createSelector(
  getExpressionTrees,
  (expressionTrees) =>
    expressionTrees.map((et) => ({
      show: get(et, 'previewOptions.show', 10),
      sort: get(et, 'previewOptions.sort', ''),
      id: et.id,
    })),
);

export const getTreeOutsideState = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.isFocusOutsideTree,
);

export const getIsTreeEmpty = createSelector(
  getExpressionTree,
  (expressionTree) =>
    get(expressionTree, 'root.children[0].searchObject.expression.length', 1) === 0 &&
    get(expressionTree, 'root.function') === '',
);

export const getAreTreesEmpty = createSelector(
  getExpressionTrees,
  (expressionTrees) =>
    expressionTrees.every(
      (item) =>
        get(item.expressionTree, 'root.children[0].searchObject.expression.length', 1) === 0 &&
        get(item.expressionTree, 'root.function') === '',
    ),
);

export const getIsEmptyFunction = createSelector(
  getExpressionTree,
  (expressionTree) => expressionTree.root && expressionTree.root.function === '',
);

export const getIsRootSelected = createSelector(
  getSelectedBranch,
  getExpressionTree,
  getIsTreeEmpty,
  (selectedBranch, expressionTree, isTreeEmpty) => {
    const selectedId = getNotEmptyNodeId(selectedBranch);
    const rootId = getNotEmptyNodeId(expressionTree);
    return isTreeEmpty || selectedId === rootId;
  },
);

export const getIsExpressionInputSelected = createSelector(
  getExpressionTree,
  getSelectedElementId,
  (expressionTree, selectedElementId) => {
    let isSelected = false;
    treeVisitor(expressionTree.root, 'children', (childNode) => {
      if (childNode.type === 'metric' && selectedElementId === childNode.id) {
        isSelected = true;
      }
    });
    return isSelected;
  },
);

export const getCompositeNameResponse = createSelector(
  getMetricsData,
  (metricData) => metricData.fetchCompositeName || EMPTY_OBJECT,
);

export const getCreateCompositeResponse = createSelector(
  getMetricsData,
  (metricData) => metricData.createComposite || EMPTY_OBJECT,
);

export const getExpressionTreeModelPayload = createSelector(
  getExpressionTree,
  getIsEmptyFunction,
  getSelectedAlertDefinition,
  getExpressionTreeModel,
  (expressionTree, isEmptyFunction, existingAlertDefinition, expressionTreeModel) => {
    const treeCleaned = cloneDeep(isEmptyFunction ? {root: expressionTree.root.children[0]} : expressionTree);
    deletePropFromObj(treeCleaned, 'uiData');

    const isExpressionTouched = !isEqual(
      treeCleaned,
      get(existingAlertDefinition, 'data.expressionTreeModel.expressionTree'),
    );
    return {
      compositeCount: 0,
      displayOnly: isEmptyFunction,
      title: 'Alert',
      excludeComposites: !expressionTreeModel.isShowComposites,
      namingSchema: isEmptyFunction ? undefined : 'COMPOSITE_V2',
      mtype: 'ALERT',
      filter: null,
      uiIndex: 0,
      expressionTree: treeCleaned,
      name: null,
      id: isExpressionTouched ? undefined : get(existingAlertDefinition, 'data.expressionTreeModel.id') || undefined,
    };
  },
);

export const getNewDashboardName = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.newDashboardName || '',
);

export const getValidationResponse = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.validationResponse || EMPTY_OBJECT,
);

export const getLastExpressionSearches = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => expressionBuilder.lastExpressionSearches || EMPTY_OBJECT,
);

export const getIsLastExpressionSearchesLoading = createSelector(
  getMetricsData,
  (metrics) => metrics.fetchLastExpressionSearches.isLoading,
);

export const getIsLastUsedMeasuresItemsLoading = createSelector(
  getMetricsData,
  (metrics) => metrics.fetchLastUsedMeasures.isLoading,
);

export const getLastExpressionSearchesItems = createSelector(
  getLastExpressionSearches,
  (lastExpressionSearches) => lastExpressionSearches.items || EMPTY_ARRAY,
);

export const getLastExpressionSearchesItemsValue = createSelector(
  getLastExpressionSearchesItems,
  (lastExpressionSearchesItems) => lastExpressionSearchesItems.map((item) => item.value) || EMPTY_ARRAY,
);

export const getLastUsedMeasures = createSelector(
  getExpressionBuilder,
  (expressionBuilder) => get(expressionBuilder, 'lastUsedMeasures', []) || EMPTY_OBJECT,
);

export const getLastUsedMeasuresItems = createSelector(
  getLastUsedMeasures,
  (lastUsedMeasures) => lastUsedMeasures.items || EMPTY_ARRAY,
);

export const getChartDashboardSchemaFactory = (multiPreviewOptions) =>
  createSelector(
    getExpressionTrees,
    getPreviewOptions,
    getByTreeExp,
    getNewDashboardName,
    (trees, preview, byTreeExp, name) => {
      const ret = {};

      const correctsTree = (originalTree) => {
        const retTree = cloneDeep(originalTree);
        if (retTree.root.function === '') {
          const child = cloneDeep(retTree.root.children[0]);
          retTree.root = child;
        }
        deletePropFromObj(retTree, 'uiData');
        return retTree;
      };

      const expressions = {};

      trees.forEach((et) => {
        expressions[et.id] = {
          compositeCount: 0,
          displayOnly: true,
          expressionTree: correctsTree(et.expressionTree),
          filter: {
            function: get(multiPreviewOptions, `${et.id}.sort`, 'alphanumeric'),
            parameters: [
              {
                name: 'Top N',
                value: get(multiPreviewOptions, `${et.id}.show`, 10),
              },
            ],
            children: [],
            id: getUniqueId(),
            type: 'function',
          },
          id: et.id,
          name: {
            auto: true,
            prefix: null,
          },
          scalarTransforms: [
            {
              function: 'current',
              children: [],
              id: getUniqueId(),
              parameters: [],
              type: 'function',
            },
          ],
        };
      });

      const seriesProperties = {
        byTreeExp,
      };

      ret.name = name;
      ret.tiles = [
        {
          id: getUniqueId(),
          sizeX: 4,
          sizeY: 6,
          title: {
            text: '',
          },
          lineChart: {
            height: 1,
            gridster: {},
            userTimeZone: 'Browser',
            bucketStartTimeFF: true,
            legend: {
              enabled: true,
              pinned: false,
              isPinnable: true,
              position: 'bottom',
              layout: 'table',
              isLayoutChangable: true,
              ratio: 0.2,
              horizontalRatio: 0.3,
            },
            resolution: {
              value: '',
            },
            eventListeners: {},
            expressionTrees: expressions,
            seriesProperties,
          },
          row: 0,
          col: 0,
        },
      ];
      return ret;
    },
  );
