// @flow
import React from 'react';
import 'moment-timezone';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import * as dateRangeService from 'common/utils/dateRangeService';

import './SchedulerEditorModal.module.scss';

type PropTypes = {
  title: String,
  dateRange: String,
  allowedRangeTypes: Array,
  optionsModifier: Function,
  isCustomAllowed: boolean,
  onChange: Function,
  isCustomAllowed: boolean,
  isUnix: Boolean,
  timeZoneName: string,
};

const preDefinedDates = Object.values(dateRangeService.rangeTypes).filter(
  (r) => [dateRangeService.rangeTypes.c.key].indexOf(r.key) < 0,
);

export default class DateRange extends React.PureComponent {
  props: PropTypes;

  state = {
    preDefinedDatesState: preDefinedDates,
  };

  componentDidMount() {
    this.getPredefinedDateRange();
  }

  componentDidUpdate(prevProps) {
    const {allowedRangeTypes} = this.props;

    if (allowedRangeTypes && allowedRangeTypes.length && prevProps.allowedRangeTypes !== allowedRangeTypes) {
      this.getPredefinedDateRange();
    }
  }

  onSelect = (key) => {
    if (key === dateRangeService.rangeTypes.c.value || key === dateRangeService.rangeTypes.r.value) {
      this.openModal();
    } else {
      this.props.onChange(dateRangeService.getDateValue({constRange: key}, this.props.isUnix, this.props.timeZoneName));
    }
  };

  getPredefinedDateRange = () => {
    const {allowedRangeTypes, isCustomAllowed} = this.props;

    if (allowedRangeTypes && allowedRangeTypes.length) {
      const datesSourse = isCustomAllowed ? Object.values(dateRangeService.rangeTypes) : preDefinedDates;

      const newPredefinedDates = datesSourse.filter((r) => allowedRangeTypes.indexOf(r.key) !== -1);
      this.setState({preDefinedDatesState: newPredefinedDates});
    }
  };

  render() {
    const {preDefinedDatesState} = this.state;
    const {title, dateRange, isCustomAllowed, optionsModifier} = this.props;

    const options = optionsModifier ? optionsModifier(preDefinedDatesState) : preDefinedDatesState;
    const selectedIndex = options.findIndex(
      (v) => v.key === dateRange.constRange || v.id === dateRange.constRange || v.value === dateRange.constRange,
    );
    const filteredOptions = isCustomAllowed ? options : options.filter((item) => item.key !== 'c');

    return (
      <div>
        <div styleName="titleWrapper">{title || 'Ignore Files Older Than'}:</div>
        <div>
          <SelectAndt
            id="schedulerEditorModalHistoricalDateRange"
            automationId="schedulerEditorModalHistoricalDateRange"
            options={filteredOptions}
            getOptionLabel={(val) => val.text}
            getOptionValue={(val) => val.value}
            type={TYPE_NEW_NO_SEARCH}
            theme={THEME_NOT_HIGHLIGHTED}
            value={options[selectedIndex]}
            onChange={(val) => this.onSelect(val.key)}
            placeholder="Date range"
            optionHeight={40}
            buttonWidth={180}
            isClearable={false}
          />
        </div>
      </div>
    );
  }
}
