// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'alerts.management/store/selectors';
import {alertConditionTypes} from 'alerts.management/services/alertsService';
import * as actions from 'alerts.management/store/actions';
import NotificationBar from 'common/components/NotificationBar';
import AlertTypeCondition from './AlertTypeCondition';
import Settings from './Settings';

import './SimulationFilters.module.scss';

type PropTypes = {
  alertDefinitionData: Object,
  filter: Object,
  simulationFilters: Object,
  simEstimations: Object,
  resetExpressionBuilder: Function,
  setSimulationFilters: Function,
  setAlertDefinition: Function,
  setSelectedAlertType: Function,
  setSelectedAlertMinDeltaEstimation: Function,
  setSelectedAlertAutoTuneByAnodot: Function,
};

const getCondition = (conditions, type) => conditions.filter((c) => c.type === type)[0];

export default connect(
  (state) => ({
    simulationFilters: selectors.getSelectedAlertSimulationFilters(state),
  }),
  {
    resetExpressionBuilder: actions.resetExpressionBuilder,
    setSimulationFilters: actions.setSimulationFilters,
    setAlertDefinition: actions.setAlertDefinition,
    setSelectedAlertType: actions.setSelectedAlertType,
    setSelectedAlertAutoTuneByAnodot: actions.setSelectedAlertAutoTuneByAnodot,
    setSelectedAlertMinDeltaEstimation: actions.setSelectedAlertMinDeltaEstimation,
  },
)(
  class SimulationFilters extends PureComponent {
    props: PropTypes;

    componentDidMount() {
      this.props.setSimulationFilters(this.props.filter);
      this.props.setAlertDefinition(this.props.alertDefinitionData);
    }

    componentDidUpdate(prevProps) {
      if (this.props.simEstimations !== prevProps.simEstimations) {
        const deltaCondition = getCondition(prevProps.simulationFilters.conditions, alertConditionTypes.minDelta);
        if (deltaCondition && deltaCondition.enableAutoTuning) {
          this.props.setSelectedAlertMinDeltaEstimation(this.props.simEstimations);
        }
      }
    }

    componentWillUnmount() {
      this.props.resetExpressionBuilder();
    }

    enableAutoTuneByAnodotChanged = (event) => {
      const {target} = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.props.setSelectedAlertAutoTuneByAnodot(value);
    };

    render() {
      const {
        simulationFilters: {type, enableAutoTuneByAnodot},
        setSelectedAlertType,
      } = this.props;

      return (
        <div className="bc" styleName="main" ref={this.setInitialWidth}>
          <NotificationBar />

          <div styleName="tabs-wrapper">
            <AlertTypeCondition alertType={type.value} onChangeType={setSelectedAlertType} />
          </div>

          <div styleName="settings-wrapper">
            <Settings />
          </div>

          <div className="cb2 bottom">
            <input
              type="checkbox"
              name="enableAutoTuneByAnodot"
              id="enableAutoTuneByAnodot"
              checked={enableAutoTuneByAnodot}
              onChange={this.enableAutoTuneByAnodotChanged}
            />
            <label htmlFor="enableAutoTuneByAnodot">Enable Auto Tuning by Anodot</label>
          </div>
        </div>
      );
    }
  },
);
