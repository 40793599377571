import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import useHandleResize from 'common/hooks/useHandleResize';
import {
  fetchAnomalyMetricsNewAlertConsole,
  setIncidentFilterNewAlertConsole,
} from 'investigationNewAlertConsole/store/actions';
import {
  getIncidentTokenMapIsLoading,
  getIncidentTokenMapData,
  getFetchAllMetrics,
  getFetchAlertMetrics,
  getInvModalIncidentFilters,
  getIncidentTokenMapCounter,
  getIncidentTokenMapTotal,
} from 'investigationNewAlertConsole/store/selectors';
import {getTimeZoneName, getTriageFullScreenEnabled} from 'profile/store/selectors';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import Button, {COLORS as ButtonColors} from 'common/componentsV2/Button';
import ContentLoader from 'common/componentsV2/ContentLoader';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import FiltersChips from 'investigationNewAlertConsole/components/incident/FiltersChips';
import MetricsTable from 'investigationNewAlertConsole/components/metricsTable/MetricsTable';
import IncidentDetails from 'investigationNewAlertConsole/components/incident/IncidentDetails';
import TokenMapFilters from 'investigationNewAlertConsole/components/incident/TokenMapFilters';
import {ALERT_TYPES, calculateAnomalyAlertColumns} from 'investigationNewAlertConsole/services/investigationService';
import IncidentTabFakeChevron from 'investigationNewAlertConsole/components/incident/IncidentTabComponents/IncidentTabFakeChevron';
import IncidentTabSubtitle from 'investigationNewAlertConsole/components/incident/IncidentTabComponents/IncidentTabSubtitle';
import {
  getAlertMetricsIncident,
  getAllMetricsIncident,
  getIncidentTokenMapFiltered,
  setMeasures,
} from 'investigationNewAlertConsole/api/utils';

import '../IncidentTab.module.scss';

const IncidentTab = ({trigger, groupId}: {trigger: Object, groupId: String}) => {
  const style = {
    panelRootStyle: {
      flexShrink: 0,
      padding: 0,
      border: 'none',
    },
  };
  const dispatch = useDispatch();
  const timeZoneName = useSelector(getTimeZoneName);
  const tokenMap = useSelector(getIncidentTokenMapData);
  const isTokenMapLoading = useSelector(getIncidentTokenMapIsLoading);
  const incidentTokenMapMetricsCounter = useSelector(getIncidentTokenMapCounter);
  const incidentTokenMapMetricsTotal = useSelector(getIncidentTokenMapTotal);
  const filters = useSelector(getInvModalIncidentFilters);
  const isTriageFullscreenEnabled = useSelector(getTriageFullScreenEnabled);

  const [isExpand, setIsExpand] = useState({});
  const [listOrder, setListOrder] = useState({});
  const bodyContainerHeight = useHandleResize([300]);

  const allMetrics = useSelector(getFetchAllMetrics);
  const alertMetricsIncidentTmp = useSelector(getFetchAlertMetrics);

  const alertMeasures = useMemo(() => {
    if (trigger?.metrics) {
      return setMeasures(trigger?.metrics);
    }
    return undefined;
  }, [trigger?.metrics]);

  const alertMetricsIncident = useMemo(() => {
    if (alertMetricsIncidentTmp && alertMeasures) {
      return getAlertMetricsIncident(alertMetricsIncidentTmp, alertMeasures);
    }
    return undefined;
  }, [alertMetricsIncidentTmp, alertMeasures]);

  const allMetricsIncident = useMemo(() => {
    if (allMetrics && alertMetricsIncidentTmp && alertMeasures) {
      return getAllMetricsIncident(allMetrics, alertMetricsIncidentTmp, alertMeasures);
    }
    return undefined;
  }, [allMetrics, alertMetricsIncidentTmp, alertMeasures]);

  const incidentTokenMap = useMemo(() => {
    if (tokenMap && alertMeasures) {
      return getIncidentTokenMapFiltered(tokenMap, alertMeasures);
    }
    return undefined;
  }, [tokenMap, alertMeasures]);

  const onExpandBoxChange = useCallback(
    (id) => (isExpanded) => {
      setIsExpand((st) => ({...st, [id]: isExpanded}));
    },
    [],
  );

  const onShowMoreMetricsClick = useCallback(
    (what, index, isAlert) => () => {
      dispatch(
        fetchAnomalyMetricsNewAlertConsole(
          {what, index, alertId: isAlert && trigger ? trigger?.alertTriggerId : null, isMore: true, filters},
          {
            groupId,
            timeScale: trigger?.timeScale,
          },
        ),
      );
    },
    [],
  );

  useEffect(() => {
    if (alertMeasures && trigger?.type === ALERT_TYPES.ANOMALY) {
      alertMeasures.map((what) => {
        dispatch(
          fetchAnomalyMetricsNewAlertConsole(
            {what, alertId: trigger?.alertTriggerId, filters},
            {
              groupId,
              timeScale: trigger.timeScale,
            },
          ),
        );
        dispatch(
          fetchAnomalyMetricsNewAlertConsole(
            {what, alertId: null, filters},
            {
              groupId,
              timeScale: trigger?.timeScale,
            },
          ),
        );
        setIsExpand((st) => ({...st, [`${what}-alert-metrics`]: true}));
      });
    }
  }, [alertMeasures, trigger, filters, groupId]);

  const isMoreMetrics = (what, isAlert) => {
    const data = isAlert ? alertMetricsIncident : allMetricsIncident;

    return (
      data[what].data.metrics.length <
      data[what].data.metricsCount.total - (isAlert ? 0 : alertMetricsIncident[what].data.metricsCount.total)
    );
  };

  const isMetricsExist = (what) =>
    !!(
      (alertMetricsIncident[what] &&
        !alertMetricsIncident[what].isLoading &&
        alertMetricsIncident[what].data.metrics.length) ||
      (allMetricsIncident[what] && !allMetricsIncident[what].isLoading && allMetricsIncident[what].data.metrics.length)
    );

  const setFilter = (dimension) => {
    if (dimension.id !== 'other') {
      dispatch(
        setIncidentFilterNewAlertConsole(dimension, {
          groupId,
          timeScale: trigger?.timeScale,
          what: trigger?.metrics?.length ? trigger?.metrics[0].what : '',
          alertMeasures: setMeasures(trigger?.metrics),
        }),
      );
    }
  };

  const alertColumns = useMemo(() => {
    return calculateAnomalyAlertColumns(alertMetricsIncident);
  }, [trigger, alertMetricsIncident]);

  return (
    <>
      {trigger && incidentTokenMap ? (
        <div styleName="incident-tab-container">
          {isTriageFullscreenEnabled ? null : (
            <div styleName="incident-tab-header" style={{marginBottom: isTriageFullscreenEnabled ? 0 : 16}}>
              <IncidentDetails trigger={trigger} />
            </div>
          )}

          <div styleName="incident-tab-metrics">
            {/* TokenMap - for Anomaly alerts */}
            <div styleName={isTriageFullscreenEnabled ? 'incident-tab-dimensions' : 'incident-tab-dimensions-old'}>
              <TokenMapFilters
                isLoading={isTokenMapLoading}
                tokenMap={incidentTokenMap}
                selectedFilters={filters}
                metricsCounter={incidentTokenMapMetricsCounter}
                totalMetrics={incidentTokenMapMetricsTotal}
                onSelect={setFilter}
              />
            </div>

            {/* Metrics */}
            <div styleName="incident-tab-metrics-container" style={{height: bodyContainerHeight}}>
              <FiltersChips filters={filters} onSelect={setFilter} />
              {/* Anomaly Alert Metrics */}
              {Object.keys(alertMetricsIncident).map((what) => {
                const isMetricLoading =
                  (alertMetricsIncident[what] && alertMetricsIncident[what].isLoading) ||
                  (allMetricsIncident[what] && allMetricsIncident[what].isLoading);
                return (
                  <div styleName="anomaly-metrics-container" key={what}>
                    {isMetricLoading && <ContentLoader rows={[{width: 90, height: 27}, {width: 415, height: 20}]} />}

                    {!isMetricLoading && isMetricsExist(what) && (
                      <Tooltip content={what} type={TYPES.SMALL}>
                        <div styleName="tooltip-container">
                          <InnerChip title={what} displayOnly type="measure" />
                        </div>
                      </Tooltip>
                    )}

                    {!isMetricLoading && alertMetricsIncident[what]?.data?.metricsCount && (
                      <div styleName="metrics-in-alert-container">
                        {alertMetricsIncident[what].data.metrics?.length && (
                          <ExpandableBox
                            key={`inc-gr-panel-${what}-alert`}
                            automationId="incWhatGroup"
                            actionElement={<div>actionElement</div>}
                            blockStyle={{transform: 'translateY(20px)'}}
                            nodeLabel={
                              <IncidentTabSubtitle
                                filters={filters}
                                subtitle="Anomalies in alert"
                                counter={alertMetricsIncident[what].data.metricsCount.total}
                              />
                            }
                            expanded={isExpand[`${what}-alert-metrics`]}
                            onChange={onExpandBoxChange(`${what}-alert-metrics`)}
                            rootStyle={style.panelRootStyle}
                          >
                            <MetricsTable
                              columns={alertColumns}
                              listOrder={listOrder}
                              metrics={alertMetricsIncident[what].data.metrics}
                              setListOrder={setListOrder}
                              timeZoneName={timeZoneName}
                              trigger={trigger}
                              isSingleLine={false}
                            />
                            {alertMetricsIncident[what].isLoadingMore && (
                              <div styleName="spinner">
                                <Spinner color="#3d4c59" size={SpinnerSizes.MEDIUM_50} />
                              </div>
                            )}
                            {isMoreMetrics(what, true) && (
                              <div styleName="show-more-metrics-button">
                                {!alertMetricsIncident[what].isLoadingMore && (
                                  <Button
                                    colorSchema={ButtonColors.TRANSPARENT_BLUE}
                                    text="Show more metrics"
                                    onClick={onShowMoreMetricsClick(
                                      what,
                                      alertMetricsIncident[what].data.metrics.length,
                                      true,
                                    )}
                                  />
                                )}
                              </div>
                            )}
                          </ExpandableBox>
                        )}
                        {!alertMetricsIncident[what].data.metrics.length && (
                          <IncidentTabFakeChevron filters={filters} subtitle="Anomalies in alert" />
                        )}
                      </div>
                    )}

                    {/* All Metrics */}
                    {isMetricLoading && (
                      <ContentLoader
                        rows={[{width: '100%', height: 40}, {width: '100%', height: 40}, {width: '100%', height: 40}]}
                      />
                    )}

                    {!isMetricLoading &&
                      allMetricsIncident[what]?.data?.metricsCount &&
                      alertMetricsIncident[what]?.data?.metricsCount && (
                        <div styleName="metrics-not-in-alert-container">
                          {!!allMetricsIncident[what].data.metrics.length && (
                            <ExpandableBox
                              key={`inc-gr-panel-${what}-all`}
                              automationId="incWhatGroup"
                              blockStyle={{transform: 'translateY(20px)'}}
                              nodeLabel={
                                <IncidentTabSubtitle
                                  filters={filters}
                                  subtitle="Anomalies not in alert"
                                  counter={
                                    allMetricsIncident[what].data.metricsCount.total -
                                    alertMetricsIncident[what].data.metricsCount.total
                                  }
                                />
                              }
                              expanded={isExpand[`${what}-all-metrics`]}
                              onChange={onExpandBoxChange(`${what}-all-metrics`)}
                              rootStyle={style.panelRootStyle}
                            >
                              <MetricsTable
                                columns={alertColumns}
                                listOrder={listOrder}
                                metrics={allMetricsIncident[what].data.metrics}
                                setListOrder={setListOrder}
                                timeZoneName={timeZoneName}
                                trigger={trigger}
                                isSingleLine={false}
                              />
                              {allMetricsIncident[what].isLoadingMore && (
                                <div styleName="spinner">
                                  <Spinner color="#3d4c59" size={SpinnerSizes.MEDIUM_50} />
                                </div>
                              )}
                              {isMoreMetrics(what, false) && (
                                <div styleName="show-more-metrics-button">
                                  {!allMetricsIncident[what].isLoadingMore && (
                                    <Button
                                      colorSchema={ButtonColors.TRANSPARENT_BLUE}
                                      text="Show more metrics"
                                      onClick={onShowMoreMetricsClick(
                                        what,
                                        allMetricsIncident[what].data.metrics.length,
                                        false,
                                      )}
                                    />
                                  )}
                                </div>
                              )}
                            </ExpandableBox>
                          )}
                          {!allMetricsIncident[what].data.metrics.length && (
                            <IncidentTabFakeChevron filters={filters} subtitle="Anomalies not in alert" />
                          )}
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default IncidentTab;
