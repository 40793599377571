// @flow
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useQueryParams} from 'use-query-params';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {QUERY_PARAM_MAP} from 'topologyGeneral/services/sidePanelService';
import {useTooltipStyles} from 'topologyGeneral/services/mapService';
import CellInfoPanel from 'topologyGeneral/components/sidePanel/infoSubPanel/CellInfoPanel';
import SiteInfoPanel from 'topologyGeneral/components/sidePanel/infoSubPanel/SiteInfoPanel';
import NodeInfoPanel from 'topologyGeneral/components/sidePanel/infoSubPanel/NodeInfoPanel';
import LinkInfoPanel from 'topologyGeneral/components/sidePanel/infoSubPanel/LinkInfoPanel';
import {palette} from 'app/styles/theme';

const InfoSubPanel = ({isVisible, site}: PropTypes) => {
  const [queryParams] = useQueryParams(QUERY_PARAM_MAP);

  const [isSingle, setIsSingle] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedLink, setSelectedLink] = useState(null);
  const [cellsList, setCellsList] = useState([]);
  const [filteredCellsList, setFilteredCellsList] = useState([]);
  const [showSections, setShowSections] = useState({
    site: true,
    node: false,
    cells: false,
    link: false,
  });

  const classes = useTooltipStyles();

  useEffect(() => {
    if (queryParams.selectedEntityId && site && site.nodes) {
      const findNode = site.nodes.find((n) => n.entityId === queryParams.selectedEntityId);
      const findLink = site.links.find((l) => l.entityId === queryParams.selectedEntityId);
      if (findNode) {
        setSelectedNode(findNode);
        setCellsList([...findNode.cells.topWing, ...findNode.cells.rightWing, ...findNode.cells.leftWing]);
      } else {
        setSelectedNode(null);
        setCellsList([]);
      }
      setSelectedLink(findLink || null);
    } else {
      setSelectedNode(null);
      setSelectedLink(null);
      setCellsList([]);
    }
    setIsSingle(site.nodes.length === 1);
  }, [queryParams.selectedEntityId, site]);

  useEffect(() => {
    if (queryParams.selectedSubEntityId) {
      setFilteredCellsList(cellsList.filter((cell) => cell.subEntityId === queryParams.selectedSubEntityId));
    } else {
      setFilteredCellsList(cellsList);
    }
  }, [cellsList, queryParams.selectedSubEntityId]);

  useEffect(() => {
    const newSections = {
      site: false,
      node: false,
      cells: false,
      link: false,
    };
    if (isSingle) {
      if (!queryParams.selectedSubEntityId) {
        newSections.site = true;
        newSections.node = true;
        newSections.cells = selectedNode && selectedNode.cellIds && selectedNode.cellIds.length;
        newSections.link = false;
      } else if (queryParams.selectedSubEntityId && selectedNode) {
        newSections.site = false;
        newSections.node = queryParams.selectedSubEntityId === selectedNode.subEntityId;
        newSections.cells = queryParams.selectedSubEntityId !== selectedNode.subEntityId;
        newSections.link = false;
      }
    } else if (!isSingle) {
      if (!queryParams.selectedEntityId) {
        newSections.site = true;
        newSections.node = false;
        newSections.cells = false;
        newSections.link = false;
      } else if (selectedLink && queryParams.selectedEntityId === selectedLink.entityId) {
        newSections.site = false;
        newSections.node = false;
        newSections.cells = false;
        newSections.link = true;
      } else if (queryParams.selectedEntityId && !queryParams.selectedSubEntityId) {
        newSections.site = false;
        newSections.node = true;
        newSections.cells = selectedNode && selectedNode.cellIds && selectedNode.cellIds.length;
        newSections.link = false;
      } else if (queryParams.selectedSubEntityId && selectedNode) {
        newSections.site = false;
        newSections.node = queryParams.selectedSubEntityId === selectedNode.subEntityId;
        newSections.cells = queryParams.selectedSubEntityId !== selectedNode.subEntityId;
        newSections.link = false;
      }
    }
    setShowSections(newSections);
  }, [isSingle, selectedNode, selectedLink, queryParams.selectedSubEntityId, queryParams.selectedEntityId]);

  if (!isVisible) {
    return null;
  }

  if (!site) {
    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center" height="100%">
        <Spinner color={palette.gray[500]} size={SIZES.XX_BIG_100} />
      </Box>
    );
  }

  return (
    <Box>
      <SiteInfoPanel site={site} isHidden={!showSections.site} />
      {showSections.site && (showSections.node || showSections.cells) ? (
        <Box className={classes.horizontalDivider} />
      ) : null}
      <NodeInfoPanel node={selectedNode} isHidden={!selectedNode || !showSections.node} />
      <LinkInfoPanel link={selectedLink} isHidden={!selectedLink || !showSections.link} />
      {showSections.node && showSections.cells ? <Box className={classes.horizontalDivider} /> : null}
      {showSections.cells && cellsList.length ? (
        <Box>
          {filteredCellsList.length === cellsList.length ? (
            <Box mb={1} className={classes.title14Normal}>
              {`Cells (${filteredCellsList.length})`}
            </Box>
          ) : (
            <Box mb={1} className={classes.title14Normal}>
              {`Cells (${filteredCellsList.length}/${cellsList.length})`}
            </Box>
          )}
          {filteredCellsList.map((fCell) => (
            <Box mb={2.5} key={`cell-info-${fCell.id}`}>
              <CellInfoPanel cell={fCell} />
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

InfoSubPanel.propTypes = {
  site: PropTypes.objectOf(PropTypes.any).isRequired,
  isVisible: PropTypes.bool,
};

InfoSubPanel.defaultProps = {
  isVisible: true,
};

export default InfoSubPanel;
