// @flow

import React from 'react';
import Spinner, {SIZES as SPINNER_SIZES} from 'common/componentsV2/Spinner';
import './ModalLoadingSpinner.module.scss';
import {palette} from 'app/styles/theme';

type PropTypes = {};

export default class ModalLoadingSpinner extends React.PureComponent {
  props: PropTypes;

  render() {
    return (
      <div styleName="modal">
        <div styleName="spinner-container">
          <Spinner color={palette.gray[500]} size={SPINNER_SIZES.XX_BIG_100} />
        </div>
      </div>
    );
  }
}
