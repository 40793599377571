import React from 'react';
import {Box} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import Info from './Info';

const TooltipArea = ({
  children,
  text,
  isAlwaysVisible,
  automationId,
  placement,
}: {
  automationId: string,
  children: React.Node,
  text: string,
  isAlwaysVisible: boolean,
  placement: string,
}) => {
  const theme = useTheme();
  return (
    <Box
      automation-id={automationId}
      css={{
        '& .icn-general16-info': {
          opacity: isAlwaysVisible ? 1 : 0,
          marginLeft: '8px',
          position: 'relative',
          top: '1px',
          transition: '200ms',
          fontSize: 14,
          color: theme.palette.gray[300],
        },
        '&:hover .icn-general16-info': {
          opacity: 1,
          '&:hover': {
            color: theme.palette.gray[400],
            opacity: 1,
          },
        },
      }}
    >
      {children(<Info text={text} placement={placement} />)}
    </Box>
  );
};

export default TooltipArea;
