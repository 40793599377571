// @flow
import React from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {Modal, Position, RootCloseWrapper} from 'react-overlays';
import {Popover} from 'react-bootstrap';
import {deleteStreamSchemaColumn, setStreamSchemaColumnKeyVal} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import * as metricSelectors from 'metrics/store/selectors';
import {abbrNum} from 'common/utils/numbers';
import {bcTypes} from 'bc/services/bcTypes';
import SmartTooltip from 'common/components/SmartTooltip';
import TransformFuncForm from '../transform/TransformFuncForm';
import NewColumnModal from '../NewColumnModal';
import AggregationModal from '../what/AggregationModal';
import UnitsModal from '../what/UnitsModal';
import ExcludeModal from '../what/ExcludeModal';
import './CellHeader.module.scss';

import DropdownColumn from './DropdownColumn';

type PropTypes = {
  // passed
  col: Object,
  isEditable: boolean,
  isNewColumn: boolean,
  newColumnType: String,
  // connected
  validationMeta: Object,
  stats: Object,
  gaMetricsMeta: Array,
  gaDimensionsMeta: Array,
  fileSchema: Array<Object>,
  streamType: String,
  deleteStreamSchemaColumn: Function,
  setStreamSchemaColumnKeyVal: Function,
};

const getIsDummy = (col) => get(col, 'transform.name', false) === 'dummy';

const getSourceColumn = (col) => {
  const userMadeColumnFanctionNames = ['concat', 'const', 'divide', 'multiply', 'subtract', 'add'];

  const traverse = (trans) => {
    if (trans.sourceColumn) {
      return trans.sourceColumn;
    }
    if (userMadeColumnFanctionNames.find((item) => item === trans.name)) {
      return false;
    }
    if (trans.input.length > 0) {
      return traverse(trans.input[0].transform || trans.input[0]);
    }
    return null; // this return value should never happen - unless there is a bug
  };

  return col.sourceColumn ? col.sourceColumn : traverse(col.transform);
};

const toRenderDelete = (col) => !getSourceColumn(col) && !getIsDummy(col);

const toRenderGeneral = (col) => !col.hidden;

const toRenderIgnore = (col) =>
  !col.hidden &&
  ((col.type === 'metric' && getSourceColumn(col)) ||
    (col.type === 'dimension' && getSourceColumn(col)) ||
    getIsDummy(col));

const toRenderInclude = (col) => col.hidden;

const columnActionTypes = {
  exclude: 'exclude',
  transform: 'transform',
  aggregation: 'aggregation',
  units: 'units',
  newColumn: 'new-column',
  deleteColumn: 'delete-column',
  ignoreColumn: 'ignore-column',
  includeColumn: 'include-column',
};

const dColumnActions = [
  {
    name: 'Modify',
    icon: 'icn-action16-edit align-verticaly-middle',
    type: columnActionTypes.transform,
    canBeDisabled: true,
    toRender: toRenderGeneral,
    toRenderHidden: toRenderInclude,
  },
  {
    name: 'Delete',
    icon: 'icn-action16-delete align-verticaly-middle',
    type: columnActionTypes.deleteColumn,
    toRender: toRenderDelete,
    toRenderHidden: () => false,
  },
  {
    name: 'Exclude Column',
    icon: 'icn-action16-clear align-verticaly-middle',
    type: columnActionTypes.ignoreColumn,
    toRender: toRenderIgnore,
    toRenderHidden: () => false,
  },
  {
    name: 'Include',
    icon: 'icn-action16-plusb align-verticaly-middle',
    type: columnActionTypes.includeColumn,
    toRender: toRenderInclude,
    toRenderHidden: () => false,
  },
];

const mColumnActions = [
  {
    name: 'Aggregation',
    icon: 'icn-general16-aggregation align-verticaly-middle',
    type: columnActionTypes.aggregation,
    canBeDisabled: true,
    toRender: toRenderGeneral,
    toRenderHidden: toRenderInclude,
  },
  {
    name: 'Modify',
    icon: 'icn-action16-edit align-verticaly-middle',
    type: columnActionTypes.transform,
    canBeDisabled: true,
    toRender: toRenderGeneral,
    toRenderHidden: toRenderInclude,
  },
  {
    name: 'Units',
    icon: 'icn-general16-money align-verticaly-middle',
    type: columnActionTypes.units,
    canBeDisabled: true,
    toRender: toRenderGeneral,
    toRenderHidden: toRenderInclude,
  },
  {
    name: 'Delete',
    icon: 'icn-action16-delete align-verticaly-middle',
    type: columnActionTypes.deleteColumn,
    toRender: toRenderDelete,
    toRenderHidden: () => false,
  },
  {
    name: 'Exclude Column',
    icon: 'icn-action16-clear align-verticaly-middle',
    type: columnActionTypes.ignoreColumn,
    toRender: toRenderIgnore,
    toRenderHidden: () => false,
  },
  {
    name: 'Include',
    icon: 'icn-action16-plusb align-verticaly-middle',
    type: columnActionTypes.includeColumn,
    toRender: toRenderInclude,
    toRenderHidden: () => false,
  },
];

export default connect(
  (state) => ({
    stats: selectors.getSelectedStreamStats(state),
    gaMetricsMeta: selectors.getGoogleAnalyticsMetaMetrics(state),
    gaDimensionsMeta: selectors.getGoogleAnalyticsMetaDimensions(state),
    streamType: selectors.getSelectedDataStreamType(state),
    fileSchema: selectors.getFileStreamAnalysisSchema(state),
    validationMeta: metricSelectors.getMetricValidationMeta(state),
  }),
  {
    deleteStreamSchemaColumn,
    setStreamSchemaColumnKeyVal,
  },
)(
  class CellHeader extends React.PureComponent {
    props: PropTypes;

    state = {
      isExcludeModalOpen: false,
      isTransformModalOpen: false,
      isAggregationModalOpen: false,
      isUnitsModalOpen: false,
      isNewColumnModalOpen: false,
      isVisible: false,
      button: null,
      isTargetDropdownVisible: false,
    };

    closeModal = () => {
      this.setState({
        isExcludeModalOpen: false,
        isTransformModalOpen: false,
        isAggregationModalOpen: false,
        isUnitsModalOpen: false,
        isNewColumnModalOpen: false,
      });
    };

    openModal = (type) => {
      const {col} = this.props;

      switch (type) {
        case columnActionTypes.deleteColumn:
          this.props.deleteStreamSchemaColumn(col.id);
          break;
        case columnActionTypes.ignoreColumn:
          if (col.transform) {
            this.setState({isExcludeModalOpen: true, col});
          } else {
            this.props.setStreamSchemaColumnKeyVal(
              {colId: col.id, key: 'hidden', value: true},
              {runStreamPreview: true},
            );
          }
          break;
        case columnActionTypes.includeColumn:
          this.props.setStreamSchemaColumnKeyVal(
            {colId: col.id, key: 'hidden', value: false},
            {runStreamPreview: true},
          );
          break;
        default:
          this.setState({
            isExcludeModalOpen: type === columnActionTypes.exclude,
            isTransformModalOpen: type === columnActionTypes.transform,
            isAggregationModalOpen: type === columnActionTypes.aggregation,
            isUnitsModalOpen: type === columnActionTypes.units,
            isNewColumnModalOpen: type === columnActionTypes.newColumn,
            col,
          });
      }
    };

    isDropdownVisible = (isVisible) => {
      if (this.state.isVisible !== isVisible) {
        this.setState({
          isVisible,
        });
      }
    };

    getNewColumnHeader = () => (
      <div styleName="header-container" automation-id="streamTableCellHeader">
        <div styleName="unique-values-container" />
        <div styleName="name-new-column">
          <button
            type="button"
            automation-id={`streamTableCellHeaderAdd${this.props.newColumnType}Button`}
            onClick={() => this.openModal(columnActionTypes.newColumn)}
            className="btn btn-flat btn-icon"
          >
            <i className="icon icn-icon-tablepopup-plus" />
          </button>
        </div>

        <NewColumnModal
          onClose={this.closeModal}
          colType={this.props.newColumnType}
          isOpen={this.state.isNewColumnModalOpen}
        />
      </div>
    );

    handleAggregationClick = (aggType) => {
      this.props.setStreamSchemaColumnKeyVal({
        colId: this.props.col.id,
        key: 'targetType',
        value: aggType,
      });
      this.handleRootClose();
    };

    handleClick = (event) => {
      this.setState({isTargetDropdownVisible: true});
      event.stopPropagation();
    };

    handleRootClose = () => {
      this.setState({isTargetDropdownVisible: false});
    };

    getOriginalTitle = (isDimension, sourceCol) => {
      switch (this.props.streamType) {
        case bcTypes.local_file.type:
          return get(this.props.fileSchema.find((c) => c.index === parseInt(sourceCol, 10)), 'name', false);

        case bcTypes.google_analytics.type: {
          const coll = isDimension ? this.props.gaDimensionsMeta : this.props.gaMetricsMeta;
          return get(coll.find((m) => m.id === sourceCol), 'uiName', false);
        }
        default:
          return undefined;
      }
    };

    getColumnHeader = (col, stats, isEditable) => {
      const {validationMeta} = this.props;
      const {button, isTargetDropdownVisible} = this.state;
      let originalTitle;
      const sourceCol = getSourceColumn(col);
      const colIgnored = col.hidden ? ' ignored' : '';
      const isUnique = col.type === 'dimension';
      const isMetric = col.type === 'metric';
      let unique = <i className="icon ion-load-c spin" />;

      let aggregationIcon = 'icn-general16-asterisk';
      let aggregationIconToolTip = 'Select aggregation type';
      if (col.targetType === 'counter') {
        aggregationIcon = 'icn-general16-sigma';
        aggregationIconToolTip = 'Sum';
      } else if (col.targetType === 'gauge') {
        aggregationIcon = 'icn-general16-average';
        aggregationIconToolTip = 'Average';
      }

      if (isMetric) {
        originalTitle = this.getOriginalTitle(false, sourceCol);
      } else {
        originalTitle = this.getOriginalTitle(true, sourceCol);
      }

      if (isUnique && stats.isLoading === false) {
        unique = get(stats.data.uniqueValues.find((i) => i.columnId === col.id), 'uniqueValues', '');
        unique = parseFloat(unique) ? abbrNum(parseFloat(unique), 0) : unique;
      }
      return (
        <div styleName="header-container" automation-id="streamTableCellHeader">
          <div styleName="unique-values-container">
            <div styleName="unique-data-container">
              <div styleName="total">{isUnique ? unique : ''}</div>
              <div styleName="sub-title">
                <span className="ellipsis">{isUnique ? 'Unique Values' : ''}</span>
              </div>
            </div>
            <div styleName="unique-values-spacer" />
          </div>

          <div styleName="name">
            {isMetric ? (
              <div className="shell">
                {col.type === 'metric' && !col.hidden && (
                  <div styleName="aggregation-target-type" className="aggregation-target-type">
                    <SmartTooltip placement="top" content={aggregationIconToolTip}>
                      <button
                        type="button"
                        ref={(btn) => !this.state.button && this.setState({button: btn})}
                        onClick={this.handleClick}
                        className="btn btn-flat btn-icon btn-aggregation"
                      >
                        <i className={`icon ${aggregationIcon}`} />
                      </button>
                    </SmartTooltip>

                    <Modal show={isTargetDropdownVisible} backdrop={false} automation-id="dataCollectorPopover">
                      <RootCloseWrapper onRootClose={this.handleRootClose}>
                        <Position placement="bottom" target={button}>
                          <Popover id="popover" styleName="popover" tabIndex={-1}>
                            <div styleName="aggregation-box">
                              <div
                                styleName={
                                  col.targetType === 'counter' ? 'aggregation-type-line-sel' : 'aggregation-type-line'
                                }
                                onClick={() => this.handleAggregationClick('counter')}
                              >
                                Sum
                                {col.targetType === 'counter' && <i className="icn-signed-v-icon" />}
                              </div>
                              <div
                                styleName={
                                  col.targetType === 'gauge' ? 'aggregation-type-line-sel' : 'aggregation-type-line'
                                }
                                onClick={() => this.handleAggregationClick('gauge')}
                              >
                                Average
                                {col.targetType === 'gauge' && <i className="icn-signed-v-icon" />}
                              </div>
                            </div>
                          </Popover>
                        </Position>
                      </RootCloseWrapper>
                    </Modal>
                  </div>
                )}
                {!col.hidden && col.name.length > validationMeta.propNameLength && (
                  <div styleName="missing-target-type">
                    <SmartTooltip placement="top" content="Title max length is 50 keys">
                      <i className="icon icn-icon-extrainfo" />
                    </SmartTooltip>
                  </div>
                )}
                <DropdownColumn
                  title={col.name}
                  colId={col.id}
                  originalTitle={originalTitle}
                  renameTitle={this.props.setStreamSchemaColumnKeyVal}
                  hasDropdown={isMetric}
                  type={col.type}
                  isHidden={col.hidden}
                  isDropdownVisible={this.isDropdownVisible}
                  styleName={
                    this.state.isVisible
                      ? `name-dropdown_metric show-dropdown-button${colIgnored}`
                      : `name-dropdown_metric${colIgnored}`
                  }
                >
                  {mColumnActions
                    .filter((c) => c.toRender === true || c.toRender(col) || c.toRenderHidden(col))
                    .map((t) => (
                      <DropdownColumn.Button
                        key={t.name}
                        title={t.name}
                        disabled={t.canBeDisabled && col.hidden}
                        iconClassName={t.icon}
                        onClick={() => this.openModal(t.type)}
                      />
                    ))}
                </DropdownColumn>
              </div>
            ) : (
              <div className="shell">
                {!col.hidden && col.name.length > validationMeta.propNameLength && (
                  <div styleName="missing-target-type">
                    <SmartTooltip placement="top" content="Title max length is 50 keys">
                      <i className="icon icn-icon-extrainfo" />
                    </SmartTooltip>
                  </div>
                )}

                <DropdownColumn
                  title={col.name}
                  colId={col.id}
                  originalTitle={originalTitle}
                  renameTitle={col.type === 'date' ? null : this.props.setStreamSchemaColumnKeyVal}
                  hasDropdown={isUnique}
                  type={col.type}
                  isHidden={col.hidden}
                  isDropdownVisible={this.isDropdownVisible}
                  styleName={
                    this.state.isVisible
                      ? `name-dropdown show-dropdown-button${colIgnored}`
                      : `name-dropdown${colIgnored}`
                  }
                >
                  {dColumnActions
                    .filter((c) => c.toRender === true || c.toRender(col) || c.toRenderHidden(col))
                    .map((t) => (
                      <DropdownColumn.Button
                        key={t.name}
                        title={t.name}
                        disabled={t.canBeDisabled && col.hidden}
                        iconClassName={t.icon}
                        onClick={() => this.openModal(t.type)}
                      />
                    ))}
                </DropdownColumn>
              </div>
            )}
          </div>

          {this.state.isExcludeModalOpen && (
            <ExcludeModal
              onClose={this.closeModal}
              isOpen={this.state.isExcludeModalOpen}
              col={this.state.col}
              isEditable={isEditable}
            />
          )}

          {this.state.isTransformModalOpen && (
            <TransformFuncForm
              onClose={this.closeModal}
              isOpen={this.state.isTransformModalOpen}
              col={this.state.col}
              isEditable={isEditable}
            />
          )}

          {this.state.isAggregationModalOpen && (
            <AggregationModal
              onClose={this.closeModal}
              isOpen={this.state.isAggregationModalOpen}
              col={this.state.col}
              isEditable={isEditable}
            />
          )}

          {this.state.isUnitsModalOpen && (
            <UnitsModal
              onClose={this.closeModal}
              isOpen={this.state.isUnitsModalOpen}
              col={this.state.col}
              isEditable={isEditable}
            />
          )}
        </div>
      );
    };

    render() {
      const {col, stats, isEditable, isNewColumn} = this.props;

      return <span>{isNewColumn ? this.getNewColumnHeader() : this.getColumnHeader(col, stats, isEditable)}</span>;
    }
  },
);
