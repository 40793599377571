// @flow
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'alerts.console/store/selectors';
import * as actions from 'alerts.console/store/actions';
import {REFRESH_ALERTS, TABLE_TITLES} from 'alerts.console/services/alertsConsoleService';
import AlertsCounter from 'alerts.console/components/alertsList/AlertsCounter';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import IntervalsDropDown from 'common/components/intervalsDropDown/IntervalsDropDown';
import {getAlertAssigneeEnabled} from 'profile/store/selectors';

type PropTypes = {
  setQueryParams: Function,
};

const styles = {
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 70px 80px 80px 80px 60px 60px',
    alignItems: 'center',
    paddingRight: 12,
  },
  isNotAlertAssigneeEnabled: {
    gridTemplateColumns: '1fr 80px 80px 80px 60px 60px',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
};

const AlertsListHeader = (props: PropTypes) => {
  const dispatch = useDispatch();
  // const isListScrolled = useSelector(selectors.getIsListScrolled);
  const queryParamsViews = useSelector(selectors.getQueryParamsViews);
  const displayedTotalAlerts = useSelector(selectors.getDisplayedTotalAlerts);
  const alertsTriggeredTotal = useSelector(selectors.getAlertsTriggeredTotal);
  const isAlertAssigneeEnabled = useSelector(getAlertAssigneeEnabled);
  const handleTitleOrderChange = (columnName) => {
    let updatedDirection = 'desc';

    if (columnName === queryParamsViews.sort) {
      updatedDirection = queryParamsViews.order === 'desc' ? 'asc' : 'desc';
    }
    props.setQueryParams({sort: columnName, order: updatedDirection});
  };

  return (
    <div style={isAlertAssigneeEnabled ? styles.wrapper : {...styles.wrapper, ...styles.isNotAlertAssigneeEnabled}}>
      <div style={styles.rightSide}>
        <AlertsCounter displayedTotalAlerts={displayedTotalAlerts} alertsTriggeredTotal={alertsTriggeredTotal} />
        <IntervalsDropDown
          onAction={() => dispatch(actions.fetchAlerts())}
          listOfIntervals={REFRESH_ALERTS}
          dataSegment="alerts-console-refresh"
        />
      </div>

      {TABLE_TITLES.filter((title) => !(!isAlertAssigneeEnabled && title.id === 5)).map((field) => (
        <div key={field.id} style={styles[field.name]}>
          <TitleHeaderTableOrder
            onChange={handleTitleOrderChange}
            title={field.title}
            selectedDirection={queryParamsViews.order}
            columnName={field.name}
            isSortable={field.isSortable}
            isActive={field.name === queryParamsViews.sort}
          />
        </div>
      ))}
    </div>
  );
};

export default AlertsListHeader;
