import {useCallback} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'dashboards/services/constants';
import {getQueryParamsUrl} from 'dashboards/utils/helpers';

const fetchAnomalyMetrics = async (params) => {
  const root = `/api/v1/anomalies/${params.anomalyId}/metric/${encodeURIComponent(params.metricId)}`;
  return get(getQueryParamsUrl(params, root));
};

export function useFetchAnomalyMetrics(params) {
  const selectFn = useCallback((data) => data, []);
  const queryFn = useCallback(() => fetchAnomalyMetrics(params), [params]);
  const options = {
    select: selectFn,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  };

  const {data = {}, isFetching, isLoading} = useQuery(
    [QUERY_KEYS.anomalyMetrics, ...Object.values(params)],
    queryFn,
    options,
  );

  return {anomalyMetricsResponse: data, isAnomalyMetricsFetching: isFetching, isAnomalyMetricsLoading: isLoading};
}

export function usePrefetchAnomalyChart(params) {
  const queryFn = useCallback(() => fetchAnomalyMetrics(params), [params]);
  const queryClient = useQueryClient();
  queryClient.prefetchQuery([QUERY_KEYS.anomalyMetrics, ...Object.values(params)], queryFn);
}
