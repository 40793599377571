import {makeAsyncAction} from 'common/utils/simplifiedAsync';

const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/admin.permissions/');

export const fetchAccessListForAllSources = macAsync('FETCH_ACCESS_LIST_FOR_ALL_SOURCES');
export const fetchAccessListByGroups = macAsync('FETCH_ACCESS_LIST_BY_GROUPS');
export const setAccesslistForSource = macAsync('SET_ACCESS_LIST_FOR_ALL_SOURCES');
export const getAccesslistForSource = macAsync('GET_ACCESS_LIST_FOR_SOURCE');

export const fetchAccessListForAllChannels = macAsync('FETCH_ACCESS_LIST_FOR_ALL_CHANNELS');
export const setAccesslistForChannel = macAsync('SET_ACCESS_LIST_FOR_CHANNEL');

export const fetchChannelByMetricSource = macAsync('FETCH_CHANNEL_BY_METRIC_SOURCE');
