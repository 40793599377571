import {useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';
import {stringifyQS} from 'common/utils/http';
import {insightsPanelRoot} from 'insightsPanelNewAlertConsole/api/utils';

const fetchTriggeredAlertsFeedbackStats = ({payload}) => {
  return get(`${insightsPanelRoot}feedback-stats?${stringifyQS(payload)}`);
};

export default function fetchTriggeredAlertsFeedbackStatsProvider(queryParams) {
  const queryClient = useQueryClient();

  const queryKey = [QUERY_KEYS.triggeredAlertsInsightPanelFeedbackStats, queryParams];
  return {
    invalidate: (partialKey) => queryClient.invalidateQueries(partialKey ? [partialKey] : queryKey),
    reset: (partialKey) => queryClient.resetQueries(partialKey ? [partialKey] : queryKey),
    fetchQuery: () =>
      queryClient.fetchQuery(
        queryKey,
        fetchTriggeredAlertsFeedbackStats({
          payload: {
            numLastDays: queryParams.numLastDays,
          },
        }),
        {
          retry: false,
        },
      ),
    useQuery: () =>
      useQuery(
        queryKey,
        () =>
          fetchTriggeredAlertsFeedbackStats({
            payload: {
              numLastDays: queryParams.numLastDays,
            },
          }),
        {
          retry: false,
        },
      ),
  };
}
