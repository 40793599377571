// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {get, isEmpty} from 'lodash';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import {
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
  setSelectedStreamUiKeyVal as setSelectedStreamUiKeyValAction,
  fetchParquetAnalysis as fetchParquetAnalysisAction,
} from 'bc/store/actions';
import ParquetCompression from './ParquetCompression';
import ParquetFileFormat from './ParquetFileFormat';
import './ParquetData.module.scss';

type PropTypes = {
  setSelectedStreamKeyVal: Function,
  setSelectedStreamUiKeyVal: Function,
  fetchParquetAnalysis: Function,
  dataStream: Object,
  uiState: Object,
  isUiStateDirty: boolean,
  isCollectAllowed: boolean,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    uiState: selectors.getStreamUiState(state),
    isUiStateDirty: selectors.isParquetStreamUiStateDirty(state),
    isCollectAllowed: selectors.isParquetStreamCollectAllowed(state),
  }),
  {
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
    setSelectedStreamUiKeyVal: setSelectedStreamUiKeyValAction,
    fetchParquetAnalysis: fetchParquetAnalysisAction,
  },
)(
  class ParquetData extends React.PureComponent {
    props: PropTypes;

    state = {};

    componentDidMount() {
      const {dataStream, setSelectedStreamUiKeyVal} = this.props;

      const newState = Object.assign(
        {
          inputLocation: get(dataStream, 'uiState.inputLocation'),
          partitionFolderFormat: get(dataStream, 'uiState.partitionFolderFormat'),
          inputFormat: get(dataStream, 'uiState.inputFormat'),
          compression: get(dataStream, 'uiState.compression'),
        },
        {
          inputLocation: dataStream.inputLocation,
          partitionFolderFormat: dataStream.partitionFolderFormat,
          inputFormat: dataStream.inputFormat,
          compression: dataStream.compression,
        },
      );
      setSelectedStreamUiKeyVal(newState);

      if (isEditRunning(dataStream.id)) {
        this.goClicked();
      }
    }

    valueChanged = (val, inputId) => {
      const newState = {};
      newState[inputId] = val;
      this.props.setSelectedStreamUiKeyVal(newState);
    };

    goClicked = () => {
      const {setSelectedStreamKeyVal, fetchParquetAnalysis, uiState, dataStream} = this.props;

      let res = {};
      if (isEditRunning(dataStream.id)) {
        res = {
          dataSourceId: dataStream.dataSourceId,
          inputLocation: dataStream.inputLocation,
          partitionFolderFormat: dataStream.partitionFolderFormat,
          inputFormat: dataStream.inputFormat,
          compression: dataStream.compression,
        };
      } else {
        res = {
          dataSourceId: dataStream.dataSourceId,
          inputLocation: uiState.inputLocation,
          partitionFolderFormat: uiState.partitionFolderFormat,
          inputFormat: uiState.inputFormat,
          compression: uiState.compression,
        };
      }
      setSelectedStreamKeyVal(res);
      fetchParquetAnalysis(res);
    };

    revertClicked = () => {
      const {setSelectedStreamUiKeyVal, dataStream} = this.props;
      setSelectedStreamUiKeyVal({
        inputLocation: get(dataStream, 'inputLocation', ''),
        partitionFolderFormat: get(dataStream, 'partitionFolderFormat', ''),
        inputFormat: get(dataStream, 'inputFormat', ''),
        compression: get(dataStream, 'compression', ''),
      });
    };

    render() {
      const {dataStream, isUiStateDirty, isCollectAllowed} = this.props;
      const isEditable = isStreamEditable(dataStream.state);
      const isRunning = isEditRunning(dataStream.id);
      const isCollectable = !isEmpty(
        get(dataStream, 'uiState.partitionFolderFormat', dataStream.partitionFolderFormat),
      );
      const isCollectDisabled = (!isRunning && (!isUiStateDirty || !isCollectAllowed)) || (isRunning && !isCollectable);

      return (
        <div className="shell-col" styleName="root">
          <PanelHeader title="Files to Collect" isEditable={isEditable} />

          <div styleName="inputs-wrapper">
            <span styleName="input-description input-description-first">[Optional] Root Path</span>
            <input
              type="text"
              onChange={(e) => this.valueChanged(e.target.value, 'inputLocation')}
              placeholder="Root Path"
              value={get(dataStream, 'uiState.inputLocation', dataStream.inputLocation)}
            />

            <span styleName="input-description">
              Daily Partition Pattern
              <br />
              (The collector requires a daily partition to work on. Define the daily partition pattern here. e.g
              &#123;&#123;YYYY&#125;&#125;-&#123;&#123;MM&#125;&#125;-&#123;&#123;DD&#125;&#125;
            </span>
            <input
              type="text"
              onChange={(e) => this.valueChanged(e.target.value, 'partitionFolderFormat')}
              placeholder="Daily partition pattern"
              value={get(dataStream, 'uiState.partitionFolderFormat', dataStream.partitionFolderFormat)}
            />

            <div className="ellipsis" styleName="input-header">
              File Properties
            </div>

            <span styleName="input-description">Format</span>
            <ParquetFileFormat
              value={get(dataStream, 'uiState.inputFormat', dataStream.inputFormat)}
              styleName="file-pattern"
              onChange={(pattern) => this.valueChanged(pattern, 'inputFormat')}
            />

            <span styleName="input-description">Compression</span>
            <ParquetCompression
              value={get(dataStream, 'uiState.compression', dataStream.compression)}
              styleName="file-pattern"
              onChange={(pattern) => this.valueChanged(pattern, 'compression')}
            />

            <span styleName="input-description">&nbsp;</span>

            <button type="button" className="btn btn-raised" disabled={isCollectDisabled} onClick={this.goClicked}>
              Collect
            </button>
            <button
              type="button"
              className="btn btn-flat btn-secondary"
              styleName="revert-btn"
              disabled={!isUiStateDirty}
              onClick={this.revertClicked}
            >
              REVERT
            </button>
          </div>
        </div>
      );
    }
  },
);
