/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import Select from 'react-select';
import {useDispatch} from 'react-redux';
import {segmentClickEvent} from 'common/store/actions';

const AnodotReactSelect = (props) => {
  const {onChange, automationId, menuIsOpen, onMenuOpen, setMenuIsOpen} = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (menuIsOpen && onMenuOpen) {
      onMenuOpen();
    }
  }, []);

  const selectItem = (item) => {
    dispatch(segmentClickEvent({type: 'click', name: name || automationId}));
    onChange(item);
  };

  return <Select {...props} isSearchable autoFocus onChange={selectItem} onBlur={setMenuIsOpen} />;
};

export default AnodotReactSelect;
