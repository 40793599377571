import {useQuery} from 'react-query';
import {queryClient} from 'reactQuery/queryClient';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';
import {stringifyAndEscapeQS} from 'common/utils/http';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import {alertsConsoleRoot, fetchTriggeredAlertsParams, mapAcknowledgedOwners} from 'alerts.console.new/api/utils';
import {
  setAlertAsReadInCache,
  syncAcksInCache,
  syncAssigneeInCache,
  syncFeedbackInCache,
  syncSnoozeInCache,
} from './triggeredAlertsCacheUpdate';

const fetchTriggeredAlerts = async ({payload}) => {
  let result = [];
  try {
    result = await get(`${alertsConsoleRoot}triggered?${stringifyAndEscapeQS(payload)}`);
    const users = await fetchUsersProvider().fetchQuery();
    const triggers = mapAcknowledgedOwners(result?.triggers, users);
    return {...result, triggers};
  } catch (e) {
    throw e;
  }
};

export default function fetchTriggeredAlertsProvider(queryParams) {
  const params = fetchTriggeredAlertsParams(queryParams);

  // Removing the breachTimeFrom react query key, since it is calculated using Now time, so it keeps changing, thus changing the queryKey.
  const {breachTimeFrom, ...queryKeyParams} = params;
  // Removing the constRange from the alerts-console/triggered api request parameters. Using breachTimeFrom instead.
  const {constRange, ...fetchParams} = params;

  const queryKey = [QUERY_KEYS.triggeredAlerts, queryKeyParams];
  return {
    invalidate: (partialKey) => queryClient.invalidateQueries(partialKey ? [partialKey] : queryKey),
    reset: (partialKey) => queryClient.resetQueries(partialKey ? [partialKey] : queryKey),
    resetAllExceptCurrent: () =>
      queryClient.resetQueries(QUERY_KEYS.triggeredAlerts, {
        predicate: (tmpQueryKey) => {
          return JSON.stringify(tmpQueryKey.queryKey) !== JSON.stringify(queryKey);
        },
      }),
    setAlertAsReadInCache: (alertTriggeredId, readParams) =>
      setAlertAsReadInCache(alertTriggeredId, readParams, queryClient),
    // Functionality that has filters - need to reset all other query cache after these functions are called
    syncAcksInCache: (alertTriggeredId, ackParams) =>
      syncAcksInCache(alertTriggeredId, ackParams, queryKey, queryClient),
    syncAssigneeInCache: (alertTriggeredId, assigneeParams) =>
      syncAssigneeInCache(alertTriggeredId, assigneeParams, queryKey, queryClient),
    syncFeedbackInCache: (alertTriggeredId, feedbackActionType, feedbackParams) =>
      syncFeedbackInCache(alertTriggeredId, feedbackActionType, feedbackParams, queryKey, queryClient),
    syncSnoozeInCache: (alertTriggeredId, snoozeParams) =>
      syncSnoozeInCache(alertTriggeredId, snoozeParams, queryClient),
    useQuery: () =>
      useQuery(queryKey, () => fetchTriggeredAlerts({payload: fetchParams}), {
        retry: false,
      }),
  };
}
