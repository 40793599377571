// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedEditor} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SchedularData from 'bc/components/streams/editor/common/SchedularData';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import '../../sql/sqlSchedular/SqlSchedular.module.scss';

type PropTypes = {
  // connect
  dataStream: Object,
  isLoadingDataSets: boolean,
  setSelectedEditor: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dataStreamState: selectors.getSelectedDataStream(state).state,
  }),
  {
    setSelectedEditor,
  },
)(
  class FacebookAdsSchedular extends React.PureComponent {
    props: PropTypes;

    openEditor = () => {
      this.props.setSelectedEditor({
        type: 'scheduler_fbads',
        editorHeight: 'calc(100% - 210px)',
        maxHeight: '600px',
      });
    };

    render() {
      const {
        dataStream: {state},
        isLoadingDataSets,
      } = this.props;
      const isEditable = (isStreamEditable(state) || isStreamRunning(state)) && !isLoadingDataSets;

      return (
        <div className="shell-col" automation-id="gadsScheduler" styleName={!isEditable ? 'disabled' : ''}>
          <PanelHeader title="Schedule" onSelect={this.openEditor} isEditable={isEditable} />
          <SchedularData />
        </div>
      );
    }
  },
);
