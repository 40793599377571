// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {bcTypes, getTypeDetails} from 'bc/services/bcTypes';
import Loading from 'metrics/components/Loading';
import * as selectors from 'bc/store/selectors';
import {
  createDataSource as createDataSourceAction,
  resetAddSourceError as resetAddSourceErrorAction,
  fetchMysqlDataSourceListDatabases as fetchMysqlDataSourceListDatabasesAction,
  resetMysqlDataSourceListDatabases as resetMysqlDataSourceListDatabasesAction,
} from 'bc/store/actions';
import SNOWFLAKE_REGIONS from 'bc/services/snowflakeService';
import '../../DataSourceModal.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import DataSourceModal from '../../DataSourceModal';

export default connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
    isDbListLoading: selectors.getMySqlDatabaseList(state).isLoading,
    databaseListError: selectors.getMySqlDatabaseList(state).error,
    databaseList: selectors.getMySqlDatabaseListItems(state),
  }),
  {
    createDataSource: createDataSourceAction,
    resetAddSourceError: resetAddSourceErrorAction,
    fetchMysqlDataSourceListDatabases: fetchMysqlDataSourceListDatabasesAction,
    resetMysqlDataSourceListDatabases: resetMysqlDataSourceListDatabasesAction,
  },
)(
  class SqlSourceModal extends React.PureComponent {
    props: {
      isOpen: boolean,
      isViewOnly: boolean,
      isEditable: boolean,
      source: Object,
      sourceType: Object,
      isLoading: boolean,
      isDbListLoading: boolean,
      databaseList: Array,
      sourceError: Object,
      databaseListError: Object,
      onClose: Function,
      createDataSource: Function,
      resetAddSourceError: Function,
      fetchMysqlDataSourceListDatabases: Function,
      resetMysqlDataSourceListDatabases: Function,
    };

    // eslint-disable-next-line no-extra-boolean-cast
    state = !!this.props.source
      ? {
          dbHost: this.props.source.dbHost,
          dbPort: this.props.source.dbPort,
          dbName: this.props.source.dbName,
          SID: this.props.source.SID,
          account: this.props.source.account,
          userName: this.props.source.userName,
          userPassword: this.props.source.userPassword,
          useSSL: this.props.source.useSSL,
          verifyServerCertificate: this.props.source.verifyServerCertificate,
          region: SNOWFLAKE_REGIONS.find((reg) => reg.value === this.props.source.region),
          role: this.props.source.role,
          warehouse: this.props.source.warehouse,
          authType: this.props.source.authType || 'USER_PASS',
          pkPassword: this.props.source.pkPassword,
        }
      : {
          dbHost: '',
          dbPort: this.props.sourceType.defaultPort,
          dbName: '',
          dbhosSID: '',
          account: '',
          userName: '',
          userPassword: '',
          useSSL: true,
          verifyServerCertificate: false,
          region: SNOWFLAKE_REGIONS[0],
          role: '',
          warehouse: '',
          authType: 'USER_PASS',
          pkPassword: '',
        };

    valueChanged = (val, inputId) => {
      const newState = {};
      newState[inputId] = val;
      this.setState(newState);
    };

    regionChanged = (val) => {
      this.setState({region: val});
    };

    radioChanged = (val) => {
      this.setState({authType: val});
    };

    listDatabasesBtnClicked = () => {
      this.props.fetchMysqlDataSourceListDatabases({
        type: getTypeDetails(this.props.sourceType.type).type,
        dbHost: this.state.dbHost,
        dbPort: this.state.dbPort,
        userName: this.state.userName,
        userPassword: this.state.userPassword,
        useSSL: this.state.useSSL,
        verifyServerCertificate: this.state.verifyServerCertificate,
      });
    };

    createBtnClicked = () => {
      const {sourceType, createDataSource} = this.props;

      if (sourceType.type === bcTypes.snowflake.type) {
        const obj = {
          type: getTypeDetails(sourceType.type).type,
          name: `${getTypeDetails(sourceType.type).shortName} ${
            this.state.dbName
          } Data Source ${new Date().getTime().toString()}`,
          dbHost: this.state.dbHost,
          dbPort: this.state.dbPort,
          dbName: this.state.dbName,
          account: this.state.account,
          userName: this.state.userName,
          userPassword: this.state.userPassword,
          useSSL: this.state.useSSL,
          verifyServerCertificate: this.state.verifyServerCertificate,
          region: this.state.region.value,
          role: this.state.role,
          warehouse: this.state.warehouse,
          authType: this.state.authType,
        };
        if (this.state.authType === 'KEY_VALUE') {
          obj.pkPassword = this.state.pkPassword;
        }
        createDataSource(obj);
      } else if (sourceType.type === bcTypes.oracle.type) {
        createDataSource({
          type: getTypeDetails(sourceType.type).type,
          name: `${getTypeDetails(sourceType.type).shortName} ${
            this.state.dbName
          } Data Source ${new Date().getTime().toString()}`,
          dbHost: this.state.dbHost,
          dbPort: this.state.dbPort,
          useSSL: this.state.useSSL,
          SID: this.state.SID,
          userName: this.state.userName,
          userPassword: this.state.userPassword,
        });
      } else {
        createDataSource({
          type: getTypeDetails(sourceType.type).type,
          name: `${getTypeDetails(sourceType.type).shortName} ${
            this.state.dbName
          } Data Source ${new Date().getTime().toString()}`,
          dbHost: this.state.dbHost,
          dbPort: this.state.dbPort,
          dbName: this.state.dbName,
          userName: this.state.userName,
          userPassword: this.state.userPassword,
          useSSL: this.state.useSSL,
          verifyServerCertificate: this.state.verifyServerCertificate,
        });
      }
    };

    dbNameChanged = (val) => {
      this.setState({dbName: val});
    };

    isContinueDisabled = () => {
      const {sourceType} = this.props;
      const {userName, userPassword, role, warehouse, account, dbHost, dbPort, dbName, SID} = this.state;

      if (sourceType.type === bcTypes.teradata.type) {
        return !dbHost || !dbPort || !userName || !userPassword;
      }

      if (sourceType.type === bcTypes.snowflake.type) {
        return !userName || !userPassword || !role || !warehouse || !account;
      }
      if (sourceType.type === bcTypes.oracle.type) {
        return !userName || !userPassword || !SID || !dbHost || !dbPort;
      }
      if (sourceType.type === bcTypes.pinot.type) {
        return !dbHost || !dbPort;
      }
      return !dbName || !dbHost || !dbPort;
    };

    onCloseInternal = () => {
      this.props.resetMysqlDataSourceListDatabases();
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    render() {
      const {
        isOpen,
        isLoading,
        sourceError,
        databaseListError,
        source,
        sourceType,
        databaseList,
        isDbListLoading,
        isViewOnly,
        isEditable,
      } = this.props;
      const {
        dbHost,
        dbPort,
        dbName,
        SID,
        userName,
        userPassword,
        useSSL,
        verifyServerCertificate,
        region,
        role,
        warehouse,
        account,
        authType,
        pkPassword,
      } = this.state;
      const errorObj = sourceError || databaseListError;

      const selectedIndexRegions = SNOWFLAKE_REGIONS.findIndex((val) => region && val.value === region.value);
      const selectedIndexDatabase = databaseList.map((val) => dbName === val);

      return (
        <DataSourceModal
          isOpen={isOpen}
          isContinueDisabled={this.isContinueDisabled()}
          isProcessing={isLoading}
          isViewOnly={isViewOnly}
          isRenameEnabled={isEditable}
          source={source}
          sourceType={sourceType}
          logoImageClass={`source-logo-${sourceType.type}`}
          onClose={this.onCloseInternal}
          onContinue={this.createBtnClicked}
        >
          {sourceType.type === bcTypes.snowflake.type && (
            <div styleName="radio-wrapper">
              <span
                styleName={authType !== 'KEY_VALUE' ? 'radio-panel-active' : 'radio-panel'}
                onClick={() => this.radioChanged('USER_PASS')}
              >
                <div styleName="box-text">User and Password</div>
                <div styleName="box-border" />
              </span>
              <span
                styleName={authType === 'KEY_VALUE' ? 'radio-panel-active' : 'radio-panel'}
                onClick={() => this.radioChanged('KEY_VALUE')}
              >
                <div styleName="box-text">User Key</div>
                <div styleName="box-border" />
              </span>
            </div>
          )}

          <div styleName="inputs-wrapper">
            {![bcTypes.snowflake.type].includes(sourceType.type) && (
              <Fragment>
                <input
                  type="text"
                  automation-id="dataCollectorSqlServerLocationTextbox"
                  onChange={(e) => this.valueChanged(e.target.value, 'dbHost')}
                  placeholder={
                    ![bcTypes.oracle.type, bcTypes.teradata.type].includes(sourceType.type) ? 'Server location' : 'Host'
                  }
                  readOnly={isViewOnly}
                  disabled={isViewOnly}
                  value={dbHost}
                />

                <input
                  type="text"
                  automation-id="dataCollectorSqlPostTextbox"
                  onChange={(e) => this.valueChanged(e.target.value, 'dbPort')}
                  placeholder={sourceType.type === bcTypes.oracle.type ? 'Port: 1521 / SSL Port: 2484' : 'Port '}
                  readOnly={isViewOnly}
                  disabled={isViewOnly}
                  value={dbPort}
                />
              </Fragment>
            )}

            {sourceType.type === bcTypes.oracle.type && (
              <Fragment>
                <input
                  type="text"
                  automation-id="dataCollectorSqlServerLocationTextbox"
                  onChange={(e) => this.valueChanged(e.target.value, 'SID')}
                  placeholder="SID"
                  readOnly={isViewOnly}
                  disabled={isViewOnly}
                  value={SID}
                />
              </Fragment>
            )}

            {[bcTypes.psql.type, bcTypes.redshift.type, bcTypes.mssql.type, bcTypes.teradata.type].includes(
              sourceType.type,
            ) && (
              <input
                type="text"
                automation-id="dataCollectorSqlDbNameTextbox"
                onChange={(e) => this.valueChanged(e.target.value, 'dbName')}
                placeholder={bcTypes.teradata.type === sourceType.type ? 'Database Name  (optional)' : 'Database Name'}
                readOnly={isViewOnly}
                disabled={isViewOnly}
                value={dbName}
              />
            )}

            {[bcTypes.snowflake.type, bcTypes.teradata.type].includes(sourceType.type) && (
              <Fragment>
                <input
                  type="text"
                  automation-id="dataCollectorSqlAccountTextbox"
                  onChange={(e) => this.valueChanged(e.target.value, 'account')}
                  placeholder={[bcTypes.teradata.type].includes(sourceType.type) ? 'Account (optional)' : 'Account'}
                  readOnly={isViewOnly}
                  disabled={isViewOnly}
                  value={account}
                />
              </Fragment>
            )}

            {sourceType.type === bcTypes.snowflake.type && (
              <Fragment>
                <SelectAndt
                  id="region"
                  automationId="sqlRegion"
                  className="andt-dropdown"
                  styleName="region-dropdown"
                  options={SNOWFLAKE_REGIONS}
                  getOptionLabel={(val) => val.text}
                  getOptionValue={(val) => val.value}
                  type={TYPE_NO_SEARCH}
                  theme={THEME_LIGHT}
                  value={SNOWFLAKE_REGIONS[selectedIndexRegions]}
                  onChange={this.regionChanged}
                  disabled={isViewOnly}
                  placeholder="Choose Region"
                />
                <input
                  type="text"
                  automation-id="dataCollectorSqlUserTextbox"
                  onChange={(e) => this.valueChanged(e.target.value, 'userName')}
                  placeholder="User"
                  readOnly={isViewOnly}
                  disabled={isViewOnly}
                  value={userName}
                />
                {authType === 'KEY_VALUE' && (
                  <Fragment>
                    <textarea
                      type="text"
                      styleName="pass-text"
                      automation-id="dataCollectorSqlPasswordTextbox"
                      onChange={(e) => this.valueChanged(e.target.value, 'userPassword')}
                      autoComplete="new-password"
                      placeholder="Key"
                      readOnly={isViewOnly}
                      disabled={isViewOnly}
                      value={userPassword}
                    />
                    <input
                      type="password"
                      automation-id="dataCollectorSqlUserTextbox"
                      onChange={(e) => this.valueChanged(e.target.value, 'pkPassword')}
                      placeholder="Key Password (optional)"
                      autoComplete="new-password"
                      readOnly={isViewOnly}
                      disabled={isViewOnly}
                      value={pkPassword}
                    />
                  </Fragment>
                )}

                {authType !== 'KEY_VALUE' && (
                  <Fragment>
                    <input
                      type="password"
                      automation-id="dataCollectorSqlPasswordTextbox"
                      onChange={(e) => this.valueChanged(e.target.value, 'userPassword')}
                      autoComplete="new-password"
                      placeholder="Password"
                      readOnly={isViewOnly}
                      disabled={isViewOnly}
                      value={userPassword}
                    />
                  </Fragment>
                )}
              </Fragment>
            )}

            {sourceType.type !== bcTypes.snowflake.type && (
              <Fragment>
                <input
                  type="text"
                  automation-id="dataCollectorSqlUserTextbox"
                  onChange={(e) => this.valueChanged(e.target.value, 'userName')}
                  placeholder={
                    ![bcTypes.oracle.type, bcTypes.teradata.type].includes(sourceType.type)
                      ? 'User (optional)'
                      : 'User '
                  }
                  readOnly={isViewOnly}
                  disabled={isViewOnly}
                  value={userName}
                />

                <input
                  type="password"
                  automation-id="dataCollectorSqlPasswordTextbox"
                  onChange={(e) => this.valueChanged(e.target.value, 'userPassword')}
                  autoComplete="new-password"
                  placeholder={
                    ![bcTypes.oracle.type, bcTypes.teradata.type].includes(sourceType.type)
                      ? 'Password (optional)'
                      : 'Password '
                  }
                  readOnly={isViewOnly}
                  disabled={isViewOnly}
                  value={userPassword}
                />
              </Fragment>
            )}

            {sourceType.type === bcTypes.snowflake.type && (
              <Fragment>
                <input
                  type="text"
                  automation-id="dataCollectorSqlRoleTextbox"
                  onChange={(e) => this.valueChanged(e.target.value, 'role')}
                  placeholder="Role"
                  readOnly={isViewOnly}
                  disabled={isViewOnly}
                  value={role}
                />

                <input
                  type="text"
                  automation-id="dataCollectorSqlDbNameTextbox"
                  onChange={(e) => this.valueChanged(e.target.value, 'dbName')}
                  placeholder="Database Name"
                  readOnly={isViewOnly}
                  disabled={isViewOnly}
                  value={dbName}
                />

                <input
                  type="text"
                  automation-id="dataCollectorSqlWarehouseTextbox"
                  onChange={(e) => this.valueChanged(e.target.value, 'warehouse')}
                  placeholder="Warehouse"
                  readOnly={isViewOnly}
                  disabled={isViewOnly}
                  value={warehouse}
                />
              </Fragment>
            )}

            <div styleName="checkboxs-wrapper">
              {![bcTypes.snowflake.type, bcTypes.teradata.type].includes(sourceType.type) && (
                <div className="cb2">
                  <input
                    type="checkbox"
                    automation-id="dataCollectorSqlSslCheckbox"
                    name="useSSL"
                    id="useSSL"
                    checked={useSSL}
                    readOnly={isViewOnly}
                    disabled={isViewOnly}
                    onChange={(e) => this.valueChanged(e.target.checked, 'useSSL')}
                  />
                  <label htmlFor="useSSL">Use SSL</label>
                </div>
              )}

              {![bcTypes.snowflake.type, bcTypes.oracle.type, bcTypes.teradata.type, bcTypes.pinot.type].includes(
                sourceType.type,
              ) && (
                <div className="cb2">
                  <input
                    type="checkbox"
                    automation-id="dataCollectorSqlCertificateCheckbox"
                    name="verifyServerCertificate"
                    id="verifyServerCertificate"
                    disabled={!useSSL || isViewOnly}
                    checked={verifyServerCertificate}
                    readOnly={isViewOnly}
                    onChange={(e) => this.valueChanged(e.target.checked, 'verifyServerCertificate')}
                  />
                  <label htmlFor="verifyServerCertificate">Verify Server Certificate</label>
                </div>
              )}
            </div>

            {[bcTypes.mysql.type, bcTypes.mariadb.type].includes(sourceType.type) && (
              <div styleName="test-wrapper">
                <button
                  type="button"
                  className="btn btn-raised"
                  disabled={!dbHost || !dbPort || isViewOnly}
                  readOnly={isViewOnly}
                  onClick={this.listDatabasesBtnClicked}
                >
                  LIST DATABASES
                </button>
                {isDbListLoading ? <Loading styleName="spinner" /> : null}
              </div>
            )}

            {!isLoading && errorObj ? (
              <div styleName="error-wrapper">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" />{' '}
                  {errorObj.uMessage || 'Could not get list of databases, check the settings and try again'}
                </span>
              </div>
            ) : null}

            {[bcTypes.mysql.type, bcTypes.mariadb.type].includes(sourceType.type) && (
              <SelectAndt
                id="sqlDatabaseDdl"
                automationId="sqlDatabase"
                automation-id="dataCollectorSqlDatabaseDropdown"
                disabled={isDbListLoading || !databaseList.length || isViewOnly}
                options={databaseList}
                getOptionLabel={(val) => val}
                getOptionValue={(val) => val}
                type={TYPE_NO_SEARCH}
                theme={THEME_LIGHT}
                value={databaseList[selectedIndexDatabase]}
                onChange={(item) => this.dbNameChanged(item)}
                placeholder="Database"
              />
            )}

            <div styleName="extra-info">
              <span>* Add</span>
              <a
                href="https://support.anodot.com/hc/en-us/articles/360002844454"
                rel="noopener noreferrer"
                target="_blank"
              >
                Anodot server IPs
              </a>
              <span>to the firewall allowlist to enable access to the db.</span>
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
