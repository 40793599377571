// @flow
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchPropAndValList, fetchProps, setExpression, setShouldShowEvents} from 'userEvents/store/actions';
import * as selectors from 'userEvents/store/selectors';
import {noop, isEqual} from 'lodash';
import ExpressionBuilder from 'common/componentsV2/ExpressionBuilderV2/ExpressionBuilder';
import {useField} from 'react-final-form';
import Box from '@material-ui/core/Box';
import {ExpandableSwitch} from 'common/componentsV2/ExpandableSections';
import TooltipArea from 'common/componentsV2/TooltipArea';
import Checkbox from 'common/componentsV2/Checkbox';
import {TypographyBox} from 'common/componentsV2/boxTools';
import * as metricsSelectors from 'metrics/store/selectors';
import * as amSelectors from 'alerts.management/store/selectors';
import * as profileSelector from 'profile/store/selectors';
import BaloonMessage from 'common/componentsV2/BaloonMessage';
import {palette} from 'app/styles/theme';

const EventExpressionBuilder = () => {
  const {
    input: {value, onChange},
  } = useField('events', {isEqual});

  const {
    input: {value: shouldShowEvents, onChange: changeShouldShowEvents},
  } = useField('shouldShowEvents');

  const {
    input: {value: isInfluencingEvents, onChange: changeIsInfluencingEvents},
  } = useField('isInfluencingEvents');

  const dispatch = useDispatch();

  const expressionTree = useSelector(metricsSelectors.getExpressionTree);
  const rollup = useSelector(amSelectors.getSimulationFiltersRollup);

  const mainPanelOptions = useSelector(selectors.getMainPanelOptions);
  const secondaryPanelOptions = useSelector(selectors.getSecondaryPanelOptions);
  const isFetchPropsLoading = useSelector(selectors.getFetchPropIsLoading);
  const executeResponse = useSelector(selectors.getUserEventsExecute);
  const isFetchPropAndValListApiLoading = useSelector(selectors.getIsFetchPropAndValApiListLoading);
  const regressorsEnabled = useSelector(profileSelector.getRegressorsEnabled);

  const {totalEvents} = executeResponse.data || {};

  const onExpressionChange = useCallback((expression) => {
    dispatch(setExpression(expression, {}));
    onChange(expression);
  }, []);

  const onShouldShowEventsChange = useCallback((isSelected) => {
    dispatch(setShouldShowEvents(isSelected));
    changeShouldShowEvents(isSelected);
  }, []);

  const isEmptyFunction =
    expressionTree.root &&
    expressionTree.root.function === '' &&
    expressionTree.root.children.length === 1 &&
    expressionTree.root.children[0].type === 'metric';

  const isRollupRespected = ['long', 'longlong', 'weekly'].includes(rollup);
  const isInfluencingDisabled = isEmptyFunction || !isRollupRespected;

  return (
    <TooltipArea isAlwaysVisible text="Display selected events and the dates & times when they occurred">
      {(info) => (
        <ExpandableSwitch
          expanded={shouldShowEvents}
          automationId="correlateAnomaliesWithEvents"
          label={
            <React.Fragment>
              Show incidents with events
              {info}
            </React.Fragment>
          }
          onChange={onShouldShowEventsChange}
        >
          <Box mt={1} automation-id="eventsExpressionContainer">
            <ExpressionBuilder
              automationId="EventsInput"
              customPlaceholder="Search events by their title, source, category and properties"
              mainPanelOptions={mainPanelOptions}
              secondaryPanelOptions={secondaryPanelOptions}
              fetchFirstPanel={(val) => dispatch(fetchPropAndValList(val, JSON.stringify(val)))}
              expression={value}
              secondarySearch={(val) => dispatch(fetchProps(val))}
              isPrimaryPanelLoading={isFetchPropAndValListApiLoading}
              isSecondaryPanelLoading={isFetchPropsLoading}
              onExpressionChange={onExpressionChange}
              handleTexInputClick={noop}
              onClick={noop}
              handleNewFunction={noop}
              handleDeleteFunction={noop}
              hideMenuSearchExpression
            />
          </Box>
          <Box fontSize={14} color="gray.400" fontWeight={500} mt={0.5} automation-id="eventsCount">
            {executeResponse.isLoading ? 'Loading...' : `${totalEvents} Events (in the presented time range)`}
          </Box>
          {regressorsEnabled && (
            <div>
              <Box mt={1}>
                <Checkbox
                  isDisabled={isInfluencingDisabled}
                  isChecked={isInfluencingEvents}
                  onChange={changeIsInfluencingEvents}
                  automationId="influencingEvents"
                >
                  <TypographyBox
                    color={isEmptyFunction && palette.gray[300]}
                    variant="h5"
                    ml={0.5}
                    lineHeight="normal"
                    mt="-1px"
                  >
                    Influencing Events
                  </TypographyBox>
                </Checkbox>
              </Box>
              {isInfluencingDisabled && (
                <BaloonMessage>
                  {/* eslint-disable-next-line max-len */}
                  Influencing events can be used when:
                  <Box component="ul" pl={2}>
                    <li>a) There is a function defined for the alert</li>
                    <li>b) The alert timescale is at least one hour</li>
                  </Box>
                </BaloonMessage>
              )}
            </div>
          )}
        </ExpandableSwitch>
      )}
    </TooltipArea>
  );
};

export default React.memo(EventExpressionBuilder);
