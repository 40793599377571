// @flow
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StringParam, useQueryParams} from 'use-query-params';
import FormDdlActionBar from '../common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import {getIsLabelsLoading, getLabelsForFilter} from './store/selectors';
import {fetchLabels} from './store/actions';
import FormDdlSelect from '../common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from '../common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentCheckboxHeaders from '../common/componentsV2/ddl/multiSelectFormDdl/OptionComponentCheckboxHeaders';

type PropTypes = {
  automationId: string,
  onChange: Function,
  selectedLabels: Array<string>, // Should be passed only if not using query params
  menuWidth: number,
  height: number,
  placeholder: string,
  queryParamsName: string,
  disabled: boolean,
  isFullWidthButton: boolean,
};

const LabelFilter = ({
  automationId,
  onChange,
  selectedLabels,
  menuWidth = 240,
  height = 260,
  placeholder = 'Select',
  queryParamsName = 'tag',
  disabled = false,
  isFullWidthButton,
}: {
  ...PropTypes,
}) => {
  const dispatch = useDispatch();
  const labelsList = useSelector(getLabelsForFilter) || [];
  const isLabelsLoading = useSelector(getIsLabelsLoading);
  const [selected, setSelected] = useState([]);
  const [filteredLabel, setFilteredLabel] = useState([]);
  const [labelsQueryParams, setLabelsQueryParams] = useQueryParams({
    [queryParamsName]: StringParam,
  });

  useEffect(() => {
    dispatch(fetchLabels());
  }, []);

  useEffect(() => {
    setFilteredLabel(labelsList);
  }, [labelsList]);

  useEffect(() => {
    const externallySelectedOptions = labelsQueryParams[queryParamsName]?.split(',') || selectedLabels || [];
    setSelected(
      labelsList.filter((label) => externallySelectedOptions.find((selectedLabel) => label.label === selectedLabel)),
    );
  }, [labelsQueryParams, selectedLabels, labelsList]);

  const getFilteredSelectedCount = (selectedOptions, filteredOptions) => {
    if (!selectedOptions) return [];
    const filteredValues = selectedOptions.filter(function(obj) {
      return filteredOptions?.some(function(obj2) {
        return obj.value === obj2.value;
      });
    });
    return filteredValues?.length || 0;
  };

  const toggleSelection = useCallback(
    (values) => {
      // if there filter search in the select
      const selectFilterOption =
        labelsList?.length > values?.length ? getFilteredSelectedCount(selected, values) : selected?.length;

      if (selected?.length > 0 && selectFilterOption > 0) {
        if (labelsList?.length > values?.length) {
          const filteredValues = selected?.filter(function(obj) {
            return !values?.some(function(obj2) {
              return obj.value === obj2.value;
            });
          });
          setLabelsQueryParams({
            [queryParamsName]: filteredValues?.length ? filteredValues.map((i) => i.label).join(',') : undefined,
          });
        } else {
          setLabelsQueryParams({[queryParamsName]: undefined});
        }
      } else {
        const filteredValues = (selected || [])
          .concat(values)
          .map((i) => i.label)
          .join(',');
        setLabelsQueryParams({[queryParamsName]: filteredValues});
      }
    },
    [selected, queryParamsName, labelsList],
  );

  const selectedCount = useMemo(() => {
    if (filteredLabel?.length === labelsList?.length) return selected?.length;
    return getFilteredSelectedCount(selected, filteredLabel);
  }, [selected, filteredLabel, labelsList]);
  return (
    <FormDdlSelect
      placement="auto"
      automationId={automationId}
      disabled={disabled}
      onChange={onChange}
      options={labelsList || []}
      setSearchedOptions={(values) => setFilteredLabel(values)}
      selected={selected}
      isMulti
      menuWidth={menuWidth}
      width={menuWidth}
      maxWidth={menuWidth}
      height={height}
      button={
        <BigDropdownButton
          isPristine={!selected.length}
          placeholder={isLabelsLoading !== false ? 'Loading...' : placeholder}
          label={selected.length === 1 ? selected[0].label : `${selected.length} Selected`}
          shortBlueStyle
          isFullWidth={isFullWidthButton}
        />
      }
      // onToggle={handleToggle}
      checkSelectedByProp="label"
      optionComponent={<OptionComponentCheckboxHeaders />}
      footerComponent={
        <FormDdlActionBar isVisible selectedCount={selectedCount} onClearAll={() => toggleSelection(filteredLabel)} />
      }
    />
  );
};

export default LabelFilter;
