import React, {useState, useCallback, useMemo} from 'react';
import {useHistory} from 'react-router';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import {STREAM_STATE_OPTIONS} from 'dataCollectors/services/dataCollectorsService';
import useActionEventStream from 'dataCollectors/api/useActionEventStream';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import ConfirmationModal from 'dataCollectors/components/confirmationModal/ConfirmationModal';
import useDeleteEventStream from 'dataCollectors/api/useDeleteEventStream';
import useCreateEventStream from 'dataCollectors/api/useCreateEventStream';
import {useToast} from 'common/utils/notifications/notificationsService';
import {isCustomerAdmin} from 'profile/store/selectors';

import './moreActionsEventStream.module.scss';

const SUCCESS_MSGS = {
  duplicate: {
    title: 'Stream Duplicated',
    description: 'The stream was duplicated. The new stream will inject events shortly.',
  },
  delete: {
    title: 'Stream Deleted',
    description: 'We could not find data records to show here.',
  },
  pause: {
    title: 'Stream Paused',
    description: 'The stream was paused, it will stop streaming events until it is resumed.',
  },
  resume: {
    title: 'Stream Resumed',
    description: 'The stream was resumed, it will resume streaming events shortly.',
  },
};

const ACTIONS_TYPES = {
  duplicate: {value: 'duplicate', label: 'Duplicate'},
  delete: {value: 'delete', label: 'Delete'},
  pause: {value: 'pause', label: 'Pause'},
  resume: {value: 'resume', label: 'Resume'},
};

export default function MoreActionEventStream({stream, placement, position, isSmall}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const {createEventStream} = useCreateEventStream();
  const {deleteEventStream, isDeleteEventStreamLoading} = useDeleteEventStream();
  const {mutate: pauseEventStream} = useActionEventStream('pause');
  const {mutate: resumeEventStream} = useActionEventStream('resume');
  const {success} = useToast(dispatch);
  const isAdminUser = useSelector(isCustomerAdmin);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const goToList = useCallback((streamId) => {
    const returnUrl = `/bc/data-manager${history.location.search}`;

    if (streamId) {
      // the "updateId" is to mark the stream that just been created/edited, it will erase after 4 seconds.
      if (history.location.search) {
        history.push(`${returnUrl}&updateId=${streamId}`);
      } else {
        history.push(`${returnUrl}?updateId=${streamId}`);
      }
    } else {
      history.push(returnUrl);
    }
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSuccess = (msgObj) => {
    return (id) => {
      goToList(id);
      success(msgObj);
    };
  };

  const deleteStream = () => {
    const successMsg = onSuccess(SUCCESS_MSGS.delete);

    if (!stream.id) {
      setIsModalOpen(false);
      goToList();
      success(SUCCESS_MSGS.delete);
    } else
      deleteEventStream({
        streamId: stream.id,
        payload: {type: stream.type, shouldDeleteMetrics: false},
        onSuccess: successMsg,
      });
  };

  const duplicateStream = () => {
    const payload = {...stream, id: null, name: `${stream.name ? `${stream.name} copy` : 'copy'} `};
    const successMsg = onSuccess(SUCCESS_MSGS.duplicate);

    createEventStream({payload, onSuccess: (data) => successMsg(data.id)});
  };

  const pauseStream = () => {
    const successMsg = onSuccess(SUCCESS_MSGS.pause);

    pauseEventStream({
      streamId: stream.id,
      onSuccess: (data) => successMsg(data.id),
    });
  };

  const resumeStream = () => {
    const successMsg = onSuccess(SUCCESS_MSGS.resume);

    resumeEventStream({
      streamId: stream.id,
      onSuccess: (data) => successMsg(data.id),
    });
  };

  const filterOptions = useMemo(() => {
    return Object.keys(ACTIONS_TYPES)
      .filter((key) => {
        return (
          (key !== 'pause' && key !== 'resume') ||
          (key === 'pause' && stream.state === STREAM_STATE_OPTIONS.running.value && !stream.paused) ||
          (key === 'resume' && stream.state === STREAM_STATE_OPTIONS.running.value && stream.paused)
        );
      })
      .map((key) => ACTIONS_TYPES[key]);
  }, [stream.state]);

  const onChange = (actionType) => {
    switch (actionType.value) {
      case ACTIONS_TYPES.duplicate.value:
        duplicateStream();
        break;
      case ACTIONS_TYPES.delete.value:
        setIsModalOpen(true);
        break;
      case ACTIONS_TYPES.pause.value:
        pauseStream(true);
        break;
      case ACTIONS_TYPES.resume.value:
        resumeStream(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isAdminUser && (
        <FormDdlSelect
          onChange={onChange}
          options={filterOptions}
          button={
            <i
              styleName={isSmall ? `button-like-dropdown small` : `button-like-dropdown`}
              className="icon icn-icon-3-dots alert-clickable-item"
            />
          }
          optionComponent={<OptionComponentSimple />}
          placement={placement}
          position={position}
          width={200}
        />
      )}

      <ConfirmationModal
        title="Delete Stream"
        text={`Are you sure you want to delete ${stream.name} ?`}
        isOpen={isModalOpen}
        isLoading={isDeleteEventStreamLoading}
        confirmBtnText="Yes, Delete"
        closeModal={closeModal}
        onConfirmModal={deleteStream}
      />
    </>
  );
}

MoreActionEventStream.defaultProps = {
  placement: 'bottom',
  position: 'right',
  isSmall: false,
};

MoreActionEventStream.propTypes = {
  stream: PropTypes.objectOf(PropTypes.any).isRequired,
  placement: PropTypes.string,
  position: PropTypes.string,
  isSmall: PropTypes.bool,
};
