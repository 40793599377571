import React from 'react';
import MixpanelSchedular from './mixpanelSchedular/MixpanelSchedular';
import MixpanelData from './mixpanelData/MixpanelData';
import MixpanelDimensionsAndMetrics from './mixpanelDimensionsAndMetrics/MixpanelDimensionsAndMetrics';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';
import './MixpanelEditor.module.scss';

const MixpanelEditor = () => (
  <div className="shell-col" automation-id="mixpanelEditorSteps">
    <StreamEditorSteps>
      <MixpanelData />
      <MixpanelDimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <MixpanelSchedular />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default MixpanelEditor;
