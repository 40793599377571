/* eslint-disable no-param-reassign */
import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';
import * as api from '../../services/api';

import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';
import {getUniqueId} from '../../../common/utils/guid';
import * as selectors from '../selectors';

const fetchNewRelicAccounts = makeAsyncEpic(actions.fetchNewRelicAccounts, api.fetchNewRelicAccounts);
const fetchNewRelicKeyset = makeAsyncEpic(actions.fetchNewRelicKeyset, api.fetchNewRelicKeyset);
const fetchNewrelicPreview = makeAsyncEpic(actions.fetchNewrelicPreview, api.fetchNewrelicPreview);

const fetchNewRelicKeysetSuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchNewRelicKeyset.success.TYPE).flatMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());
    const res = {
      schema: {
        columns: [],
        sourceColumns: [],
      },
      metrics: [],
      dimensions: [],
      uiState: {
        ...stream.uiState,
        keyset: action.payload,
        unAssignedColumns: [],
      },
    };

    action.payload.dimensions.forEach((dim) => {
      res.schema.columns.push({
        id: getUniqueId(),
        sourceColumn: dim,
        name: dim,
        type: 'dimension',
      });
      res.schema.sourceColumns.push({
        id: dim,
        name: dim,
      });
      res.dimensions.push(dim);
    });
    action.payload.metrics.forEach((metric) => {
      res.schema.columns.push({
        id: getUniqueId(),
        sourceColumn: metric,
        name: metric,
        type: 'metric',
      });
      res.schema.sourceColumns.push({
        id: metric,
        name: metric,
      });
      res.metrics.push(metric);
    });

    return [actions.setSelectedStreamKeyVal(res)];
  });

const newRelictEpic = combineEpics(
  fetchNewRelicAccounts,
  fetchNewRelicKeyset,
  fetchNewRelicKeysetSuccess,
  fetchNewrelicPreview,
);
export default newRelictEpic;
