// @flow
import React from 'react';
import {Route} from 'react-router-dom';
import {get} from 'lodash';
import DeleteTileModal from 'dashboards/components/modals/DeleteTileModal';
import {OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';
import DuplicateTileModal from 'dashboards/components/modals/DuplicateTileModal';
import AddGraphModalContainer from 'dashboards/components/modals/AddGraphModalContainer';
import AddStatModalContainer from 'dashboards/components/modals/AddStatModalContainer';
import AddTextTileModal, {TEXT_TILES_VALUE} from 'dashboards/components/modals/AddTextTileModal';
import AlertModal from 'dashboards/components/modals/AlertModal';
import AddAnomalyModalContainer from 'dashboards/components/modals/AddAnomalyModalContainer';

type PropsType = {
  tileUrl: string,
  returnToDashboardPage: Function,
  dashboardData: Object,
  onConfirmDeleteTile: Function,
  isLoading: boolean,
  id: string,
  tiles: Array<Object>,
  actualLayout: Array<Object>,
  dashboardId: string,
  isOwnerUser: boolean,
};

const TileActionsRouter = ({
  tileUrl,
  returnToDashboardPage,
  dashboardData,
  onConfirmDeleteTile,
  isLoading,
  id,
  tiles,
  actualLayout,
  dashboardId,
  isOwnerUser,
}: PropsType) => (
  <React.Fragment>
    <Route
      path={`${tileUrl}/${OPTION_TILES_VALUES.DELETE}`}
      render={() => (
        <DeleteTileModal
          isOpen
          onClose={returnToDashboardPage}
          dashboardData={dashboardData}
          onConfirm={onConfirmDeleteTile}
          isLoading={isLoading}
          id={id}
        />
      )}
    />
    <Route
      path={`${tileUrl}/${OPTION_TILES_VALUES.DUPLICATE}`}
      render={() => (
        <DuplicateTileModal
          dashboardId={dashboardId}
          layout={actualLayout}
          onClose={returnToDashboardPage}
          data={tiles[id]}
          isOwnerUser={isOwnerUser}
        />
      )}
    />
    <Route
      path={`${tileUrl}/${OPTION_TILES_VALUES.CREATE_ALERT}`}
      render={() => (
        <AlertModal tileUrl={tileUrl} data={tiles[id]} onClose={returnToDashboardPage} dashboardId={dashboardId} />
      )}
    />
    <Route
      path={`${tileUrl}/${OPTION_TILES_VALUES.SETTINGS}`}
      render={() => {
        if (get(tiles[id], 'tileType') === 'anomaliesList') {
          return (
            <AddAnomalyModalContainer
              dashboardId={dashboardId}
              layout={actualLayout}
              data={tiles[id]}
              onClose={returnToDashboardPage}
            />
          );
        }
        if (get(tiles[id], 'lineChart')) {
          return (
            <AddGraphModalContainer
              dashboardId={dashboardId}
              layout={actualLayout}
              data={tiles[id]}
              onClose={returnToDashboardPage}
            />
          );
        }
        if (get(tiles[id], 'andtGauge')) {
          return (
            <AddStatModalContainer
              dashboardId={dashboardId}
              layout={actualLayout}
              data={tiles[id]}
              onClose={returnToDashboardPage}
            />
          );
        }
        if (
          get(tiles[id], 'freeText') ||
          get(tiles[id], 'type') === TEXT_TILES_VALUE.TITLE ||
          get(tiles[id], 'type') === TEXT_TILES_VALUE.NOTE
        ) {
          return (
            <AddTextTileModal
              dashboardId={dashboardId}
              layout={actualLayout}
              data={tiles[id]}
              onClose={returnToDashboardPage}
            />
          );
        }
        return null;
      }}
    />
  </React.Fragment>
);

export default TileActionsRouter;
