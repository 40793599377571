import React from 'react';
import Tooltip from 'common/componentsV2/Tooltip';
import {Link} from 'react-router-dom';
import useFetchDataStreamsList from 'bc/services/useFetchDataStreamList';
import {getSourceIcon} from 'bc/services/dataManagerService';
import './CompositeExpandablePanel.module.scss';
import PropTypes from 'prop-types';

const EMPTY_ARRAY = [];

const OriginSourceList = React.memo(({composite}) => {
  const {dataStreamsList} = useFetchDataStreamsList();
  const computedSourceList = dataStreamsList.filter((s) =>
    (composite.originSourceIds || EMPTY_ARRAY).some((id) => id === s.id),
  );
  return computedSourceList?.map((computedSource) => (
    <div className="display_flex">
      <Tooltip content="source">
        <Link to={`/bc/data-manager?streamPreviewId=${computedSource.id}`} target="_blank" styleName="source-icon">
          <div styleName="source-wrapper">
            {getSourceIcon(computedSource.type)}
            <span>{computedSource.name}</span>
          </div>
        </Link>
      </Tooltip>
    </div>
  ));
});

OriginSourceList.propTypes = {
  composite: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default OriginSourceList;
