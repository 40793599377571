import {composeReducers} from 'common/utils/reducers';
import {combineReducers} from 'redux';
import * as actions from '../actions';

const EMPTY_OBJECT = {};

const valuesToSet = (payload) => ({
  id: payload.id,
  originType: payload.originType,
  measure: payload.measure,
  spike: payload.spike,
  drop: payload.drop,
  currency: payload.currency,
  factor: payload.factor,
});

const modalFromEmail = composeReducers((state = EMPTY_OBJECT, {type, payload}) => {
  switch (type) {
    case actions.isImpactModalOpenNewAlertConsole.TYPE: {
      return {
        ...state,
        isOpen: payload,
      };
    }
    default:
      return state;
  }
});

const impacts = composeReducers((state = EMPTY_OBJECT, {type, payload}) => {
  switch (type) {
    case actions.addImpactsNewAlertConsole.TYPE: {
      const leanImpacts = {};

      payload.forEach((impact) => {
        leanImpacts[impact.id] = valuesToSet(impact);
      });
      return {
        ...state,
        ...leanImpacts,
      };
    }
    case actions.addImpactNewAlertConsole.TYPE: {
      return {
        ...state,
        [payload.id]: {
          ...valuesToSet(payload),
        },
      };
    }
    case actions.setImpactValueNewAlertConsole.TYPE: {
      return {
        ...state,
        [payload.id]: {
          ...state[payload.id],
          [payload.name]: payload.value,
        },
      };
    }
    case actions.setImpactDefaultValuesNewAlertConsole.TYPE: {
      return {
        ...state,
        [payload.id]: payload,
      };
    }
    default:
      return state;
  }
});

export default combineReducers({
  modalFromEmail,
  impacts,
});
