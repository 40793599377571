// @flow
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {StringParam, useQueryParams} from 'use-query-params';
import {
  updateFilterBtn as updateFilterBtnAction,
  updateQueryParams as updateQueryParamsAction,
} from 'alerts.console.new/store/actions';
import * as alertsConsoleSelectors from 'alerts.console.new/store/selectors';
import {
  getAlertAssigneeEnabled,
  getNewAlertConsoleEnabled,
  getNewAlertConsoleMigrationFinished,
} from 'profile/store/selectors';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import SearchBox from 'common/componentsV2/SearchBox';
import Checkbox from 'common/componentsV2/Checkbox';
import LabelsFilter from 'alerts.console.new/components/filters/availableFilters/LabelsFilter';
import AssigneeFilter from 'alerts.console.new/components/filters/availableFilters/AssigneeFilter';
import AlertConsoleQueryParamsContext from 'alerts.console.new/context';
import AckFilter from 'alerts.console.new/components/filters/availableFilters/AckFilter';
import FeedbackFilter from 'alerts.console.new/components/filters/availableFilters/FeedbackFilter';
import DateRangeFilter from 'alerts.console.new/components/filters/availableFilters/DateRangeFilter';
import ChannelsFilter from 'alerts.console.new/components/filters/availableFilters/ChannelsFilter';
import TypeFilter from 'alerts.console.new/components/filters/availableFilters/TypeFilter';
import SeverityFilter from 'alerts.console.new/components/filters/availableFilters/SeverityFilter';
import AlertIsOpenFilter from 'alerts.console.new/components/filters/availableFilters/AlertIsOpenFilter';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS} from 'alerts.console.new/services/alertsConsoleService';
import {parseQS} from 'common/utils/http';

import './AlertsConsoleFilters.module.scss';

/**
 * Convert boolean string to boolean type.
 * Any query param set to "true" or "false" will be converted to a boolean type.
 */
const boolify = (value) => {
  if (typeof value === 'string') {
    const value2 = value.toLowerCase().trim();
    if (value2 === 'true') {
      return true;
    }
    if (value2 === 'false') {
      return false;
    }
  }
  return value;
};
/**
 * Convert query string params object to an array of used params.
 */
const convertToUsedParams = (obj, queryStringParamsList) => {
  return (
    Object.entries(obj)
      // remove un used options
      .filter(([, v]) => v != null && v !== '')
      // remove query params not in use in this screen
      .filter(([k]) => Object.prototype.hasOwnProperty.call(queryStringParamsList, k))
  );
};

/**
 * Checks that all keys in queryString object exists and equal to keys in default query string
 */
const isDifferentThanDefault = (queryString, defaultQueryString) => {
  const allQueryStringKeys = Object.entries(queryString);
  // Check if not every query string params are same as default = added param
  const addedParams = !allQueryStringKeys.every(([key, value]) => !value || defaultQueryString[key] === value);
  // and also check that not every used defaults are in the query params = removed param
  const usedDefaultParam = convertToUsedParams(defaultQueryString, queryString);
  const removedParams = !usedDefaultParam.every(([key, value]) => queryString[key] === value);

  return addedParams || removedParams;
};

const FilterToolbar = ({
  defaultQueryParams,
  isFilteredFromEmail,
}: {
  defaultQueryParams: Object,
  isFilteredFromEmail: Boolean,
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(alertsConsoleSelectors.getIsFiltersBarOpen);

  const isNewAlertConsoleEnabled = useSelector(getNewAlertConsoleEnabled);
  const isNewAlertConsoleMigrationFinished = useSelector(getNewAlertConsoleMigrationFinished);
  const isImpersonating = useSelector(getNewAlertConsoleEnabled);

  const dataQueryParams =
    isNewAlertConsoleEnabled &&
    window.location.href.indexOf('alerts-console-new') >= 0 &&
    (isNewAlertConsoleMigrationFinished || isImpersonating)
      ? useSelector(alertsConsoleSelectors.getQueryParamsDataNewAlertConsole)
      : useSelector(alertsConsoleSelectors.getQueryParamsData);
  const isAlertAssigneeEnabled = useSelector(getAlertAssigneeEnabled);
  const {setContextQueryParams} = useContext(AlertConsoleQueryParamsContext);
  const [isFiltersChanges, setIsFiltersChanges] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams({
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ACK_BY]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ASSIGNEES]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.CHANNELS]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.CONST_RANGE]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.IS_OPEN]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.LABELS]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.NOT_ACK_BY]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEARCH_EXPRESSION]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITIES]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITY_VIEW]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SUBSCRIBERS]: StringParam,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.TYPES]: StringParam,
  });

  const lastQueryParams = useRef(queryParams);

  const getQueryString = () => {
    const parsedQs = parseQS(window.location.hash);
    delete parsedQs.paramsMapping;
    return parsedQs;
  };

  // Query params based on saved view are added in AlertConsole componentDidMount method
  // The purpose of this effect is to add any additional default parameters, such as sort
  // and to override default & data params with query params
  useEffect(() => {
    const updatedQueryParams = getQueryString();
    const usedDefaultParam = Object.fromEntries(convertToUsedParams(defaultQueryParams, queryParams));
    const usedDataParam = Object.fromEntries(convertToUsedParams(dataQueryParams, queryParams));
    const usedQueryParam = Object.fromEntries(convertToUsedParams(updatedQueryParams, queryParams));
    const mergedQueryParams = {
      ...usedDefaultParam,
      ...usedDataParam,
      ...usedQueryParam,
    };
    lastQueryParams.current = {
      ...mergedQueryParams,
      [ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_NUM]: updatedQueryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_NUM] || 0,
    };
    setQueryParams(lastQueryParams.current);
    setContextQueryParams(lastQueryParams.current);
  }, []);

  useEffect(() => {
    const usedQueryParam = {...queryParams};
    // convert strings to bool
    Object.keys(usedQueryParam).forEach((key) => {
      usedQueryParam[key] = boolify(usedQueryParam[key]);
    });

    const updatedQueryParams = getQueryString();

    // compare used query params with default, for the filters button indication in the Alerts Console
    const isQueryParamsChanged = isDifferentThanDefault(updatedQueryParams, {
      ...defaultQueryParams,
      ...dataQueryParams,
      ...usedQueryParam,
    });
    setIsFiltersChanges(isQueryParamsChanged);

    if (isDifferentThanDefault(updatedQueryParams, lastQueryParams.current)) {
      // Need to return to the first page when filters change
      // (This is especially necessary in case there is only 1 page for the selected filter, and we are on page 2 for example)
      setQueryParams({
        [ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_NUM]: 0,
      });
      lastQueryParams.current = {...updatedQueryParams, [ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_NUM]: 0};
      setContextQueryParams({
        ...updatedQueryParams,
        [ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_NUM]: 0,
      });
    }
  }, [queryParams, dataQueryParams, defaultQueryParams]);

  const resetFilters = useCallback(
    (resetToManufacturer) => {
      const usedDefaultParam = Object.fromEntries(convertToUsedParams(defaultQueryParams, queryParams));
      if (resetToManufacturer) {
        // View only
        setQueryParams(usedDefaultParam, 'replace');
        setContextQueryParams({...usedDefaultParam, [ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_NUM]: 0});
        // Save in Data
        dispatch(updateQueryParamsAction({isReset: true}));
      } else {
        const mergedQueryParams = {
          ...usedDefaultParam,
          ...dataQueryParams,
        };
        // View only
        setQueryParams(mergedQueryParams, 'replace');
        setQueryParams({[ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_NUM]: 0});
        setContextQueryParams({...mergedQueryParams, [ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_NUM]: 0});
      }
    },
    [queryParams, defaultQueryParams, dataQueryParams],
  );

  const onSaveViewValueChange = useCallback(
    (id) => {
      if (id === 'reset') {
        resetFilters(true);
      } else {
        dispatch(updateQueryParamsAction({queryParams: parseQS(window.location.hash)}));
      }
    },
    [resetFilters],
  );

  return (
    <div styleName="root">
      <FiltersPanel
        isOpen={isOpen}
        onClose={() => dispatch(updateFilterBtnAction(true))}
        isClearAll={isFiltersChanges && !isFilteredFromEmail}
        onClearAll={() => resetFilters(false)}
        isSaveView={!isFilteredFromEmail}
        onSaveViewChange={onSaveViewValueChange}
      >
        <div styleName="filters-row">
          <SearchBox
            onFilter={(val) => setQueryParams({[ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEARCH_EXPRESSION]: val || undefined})}
            filter={queryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEARCH_EXPRESSION] || ''}
            placeHolder="Search Alerts"
          />
        </div>

        <div styleName="filters-row">
          <span styleName="filters-row-header">Started During</span>
          <DateRangeFilter defaultDateRange={dataQueryParams.constRange || defaultQueryParams.constRange} />
        </div>

        <div styleName="filters-row">
          <AlertIsOpenFilter />
        </div>

        <div styleName="filters-row">
          <span styleName="filters-row-header">Alert Labels</span>
          <LabelsFilter />
        </div>

        <div styleName="filters-row">
          <span styleName="filters-row-header">Alert Type</span>
          <TypeFilter />
        </div>

        <div styleName="filters-row">
          <span styleName="filters-row-header">Recipients</span>
          <ChannelsFilter />
        </div>

        {isAlertAssigneeEnabled && (
          <div styleName="filters-row">
            <span styleName="filters-row-header">Assignee</span>
            <AssigneeFilter />
          </div>
        )}

        <div styleName="filters-row">
          <span styleName="filters-row-header">Acknowledge</span>
          <AckFilter />
        </div>

        <div styleName="filters-row">
          <span styleName="filters-row-header">Feedback</span>
          <FeedbackFilter />
        </div>

        <div styleName="filters-row double" style={{marginBottom: 0}}>
          <div styleName="small">
            <span styleName="filters-row-header">Severity</span>
            <SeverityFilter />
          </div>
          <div styleName="small" style={{marginTop: 34}}>
            <Checkbox
              automationId="alertSeverityCheckboxFilter"
              isChecked={queryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITY_VIEW] === 'true'}
              text="Severity view"
              onChange={(checked) => {
                setQueryParams({
                  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITY_VIEW]: checked === true ? 'true' : undefined,
                  [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SORT_BY]: checked
                    ? 'severity'
                    : defaultQueryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.SORT_BY],
                });
              }}
            />
          </div>
        </div>
      </FiltersPanel>
    </div>
  );
};
export default FilterToolbar;
