import {isEmpty} from 'lodash';
import {EVENT_STREAM_FIELDS, EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';

const {
  STREAM_ID,
  STREAM_NAME,
  STREAM_OWNER,
  SCHEMA_NAME,
  TABLE_NAME,
  EVENT_TITLE,
  TRANSFORM_TABLE,
  EVENT_CATEGORY,
  EVENT_START_TIME,
  EVENT_PROGRESS_INDICATOR,
  TYPE,
  START_TIME_DEFINITION,
  EVENT_END_TIME,
  END_TIME_DEFINITION,
  PROJECT_ID,
  DATA_SET,
} = EVENT_STREAM_FIELDS;

const {LOCAL_FILE, PSQL, BIG_QUERY} = EVENT_STREAM_TYPES;

const isNameExist = (name, id, streams) => {
  const filteredStreams = streams?.filter((stream) => stream.id !== id);

  return filteredStreams?.some((stream) => stream.name === name) || false;
};

const commonValidation = (currentStream, allStreams) => {
  const errors = {};

  if (!currentStream[STREAM_NAME.id]) {
    errors[STREAM_NAME.id] = 'required field';
  }
  if (isNameExist(currentStream[STREAM_NAME.id], currentStream[STREAM_ID.id], allStreams)) {
    errors[STREAM_NAME.id] = 'name already exist';
  }
  if (!currentStream[STREAM_OWNER.id] || isEmpty(currentStream[STREAM_OWNER.id])) {
    errors[STREAM_OWNER.id] = 'required field';
  }
  if (!currentStream[EVENT_TITLE.id]) {
    errors[EVENT_TITLE.id] = 'required field';
  }
  if (!currentStream[EVENT_CATEGORY.id]) {
    errors[EVENT_CATEGORY.id] = 'required field';
  }
  if (!currentStream[EVENT_START_TIME.id]) {
    errors[EVENT_START_TIME.id] = 'required field';
  }
  if (!currentStream[TRANSFORM_TABLE.id]) {
    errors[TRANSFORM_TABLE.id] = 'Transform Table required field';
  }
  return errors;
};

function psqlValidations(currentStream) {
  const errors = {};

  if (!currentStream[SCHEMA_NAME.id]) {
    errors[SCHEMA_NAME.id] = 'required field';
  }
  if (!currentStream[TABLE_NAME.id]) {
    errors[TABLE_NAME.id] = 'required field';
  }
  if (!currentStream[EVENT_PROGRESS_INDICATOR.id]) {
    errors[EVENT_PROGRESS_INDICATOR.id] = 'required field';
  }

  return errors;
}

function bigQueryValidations(currentStream) {
  const errors = {};

  if (!currentStream[PROJECT_ID.id]) {
    errors[PROJECT_ID.id] = 'required field';
  }
  if (!currentStream[DATA_SET.id]) {
    errors[DATA_SET.id] = 'required field';
  }
  if (!currentStream[TABLE_NAME.id]) {
    errors[TABLE_NAME.id] = 'required field';
  }
  if (!currentStream[EVENT_PROGRESS_INDICATOR.id]) {
    errors[EVENT_PROGRESS_INDICATOR.id] = 'required field';
  }

  return errors;
}

function fileUploadValidations(currentStream) {
  const errors = {};

  if (
    currentStream[EVENT_START_TIME.id] &&
    (isEmpty(currentStream[START_TIME_DEFINITION.id]) || !currentStream[START_TIME_DEFINITION.id].timePattern)
  ) {
    errors[START_TIME_DEFINITION.id] = 'time pattern is required';
  }

  if (
    currentStream[EVENT_END_TIME.id] &&
    (isEmpty(currentStream[END_TIME_DEFINITION.id]) || !currentStream[END_TIME_DEFINITION.id].timePattern)
  ) {
    errors[END_TIME_DEFINITION.id] = 'time pattern is required';
  }

  return errors;
}

export default function useFormValidation(currentStream, allStreams) {
  switch (currentStream[TYPE.id]) {
    case LOCAL_FILE:
      return {
        errors: {...commonValidation(currentStream, allStreams), ...fileUploadValidations(currentStream)},
        currentStream,
      };
    case BIG_QUERY:
      return {
        errors: {...commonValidation(currentStream, allStreams), ...bigQueryValidations(currentStream)},
        currentStream,
      };
    case PSQL:
      return {
        errors: {...commonValidation(currentStream, allStreams), ...psqlValidations(currentStream)},
        currentStream,
      };
    default:
      return null;
  }
}
