// @flow
import React, {PureComponent} from 'react';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import EmptyListImageComponent from 'common/componentsV2/EmptyListImageComponent';
import IssueItem from 'topologyLeaflet/components/leftPanel/IssueItem';
import './LeftPanel.module.scss';
import {palette} from 'app/styles/theme';

const EmptyListIndication = () => (
  <div styleName="empty-wrapper">
    <EmptyListImageComponent />
    <div styleName="empty-text">No Issues</div>
  </div>
);

const LoadingIndication = () => (
  <div styleName="loading-wrapper">
    <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
  </div>
);

type PropTypes = {
  issues?: Array,
  isLoading?: boolean,
  selectedIssueId?: String,
  timeZoneName?: String,
  textHighlight?: String,
  onIssueItemClicked: Function,
};

export default class IssuesList extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    issues: [],
    isLoading: false,
    selectedIssueId: null,
    timeZoneName: null,
    textHighlight: '',
  };

  onIssueItemClicked = (issue) => {
    this.props.onIssueItemClicked(issue);
  };

  render() {
    const {issues, isLoading, selectedIssueId, timeZoneName, textHighlight} = this.props;

    if (isLoading) {
      return <LoadingIndication />;
    }

    if (!issues.length) {
      return <EmptyListIndication />;
    }

    return issues.map((issue) => (
      <IssueItem
        textHighlight={textHighlight}
        item={issue}
        key={issue.id}
        styleName={['issue-pos', selectedIssueId === issue.id ? 'selected' : ''].join(' ')}
        timeZoneName={timeZoneName}
        onClick={this.onIssueItemClicked}
      />
    ));
  }
}
