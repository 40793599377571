import React, {useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import './streamSummary.module.scss';
import {useFetchUsers} from 'dataCollectors/api/useFetchUsers';
import {RANGE_TYPES} from 'dataCollectors/services/scheduleService';
import {EVENT_STREAM_FIELDS, EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';

const {TYPE} = EVENT_STREAM_FIELDS;
const {LOCAL_FILE} = EVENT_STREAM_TYPES;

export default function StreamSummary() {
  const {getValues} = useFormContext();
  const stream = getValues();
  const type = getValues(TYPE.id);
  const {users} = useFetchUsers();
  const ownerName = useMemo(() => {
    return users.find((user) => user.value === stream.owner).label;
  }, []);

  return (
    <div styleName="stream-summary-container">
      <div styleName="main-title">Summary</div>

      <div styleName="section">
        <span styleName="title">Stream Info</span>
        <span styleName="border" />
        <div>
          <span styleName="text">Stream Name:</span> <span styleName="text">{stream.name}</span>
        </div>
        <div>
          <span styleName="text">Stream Owner:</span> <span styleName="text">{ownerName}</span>
        </div>
        <div>
          <span>Stream Description:</span>
          <span>{stream.description}</span>
        </div>
      </div>

      {type !== LOCAL_FILE && (
        <div styleName="section">
          <span styleName="title">Table</span>

          <span styleName="border" />
          <div>
            <span styleName="text">Schema:</span> <span styleName="text">{stream.schemaName}</span>
          </div>
          <div>
            <span styleName="text">Table:</span> <span styleName="text">{stream.tableName}</span>
          </div>
        </div>
      )}

      <div styleName="section">
        <span styleName="title">Data Mapper</span>
        <span styleName="border" />
        <div>
          <span styleName="text">Complete</span>
        </div>
      </div>

      <div styleName="section">
        <span styleName="title">Schedule</span>
        <span styleName="border" />
        {type !== LOCAL_FILE && (
          <div>
            <span styleName="text">Collect Every:</span> <span styleName="text">{stream.pollingInterval}</span>
          </div>
        )}

        <div>
          <span styleName="text">Time Zone:</span> <span styleName="text">{stream.timeZone}</span>
        </div>
        {stream.historicalDateRange?.constRange && type !== LOCAL_FILE && (
          <div>
            <span styleName="text">Ignore Files Older:</span>{' '}
            <span styleName="text">{RANGE_TYPES[stream.historicalDateRange.constRange].label}</span>
          </div>
        )}
      </div>

      <div styleName="info">
        <span styleName="main-title">Note</span>
        <p>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Streaming will start once you click "Start streaming". <br />
          There will be a delay before the data is available, We will notify you once it is live.
        </p>
      </div>
    </div>
  );
}
