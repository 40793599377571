// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import {TABLE_TITLES} from 'bc/services/dataManagerService';
import {getDataMangerQueryParams} from 'bc/store/selectors';

import './DataManagerListHeader.module.scss';

type PropTypes = {
  setQueryParams: Function,
  dataQueryParams: Object,
  isRbacEnabled: Boolean,
};

export default connect(
  (state) => ({
    dataQueryParams: getDataMangerQueryParams(state),
  }),
  {},
)(
  class DataManagerListHeader extends PureComponent {
    props: PropTypes;

    handleTitleOrderChange = (columnName) => {
      const {
        dataQueryParams: {sort, order},
      } = this.props;
      let updatedDirection = 'desc';

      if (columnName === sort) {
        updatedDirection = order === 'desc' ? 'asc' : 'desc';
      }
      this.props.setQueryParams({sort: columnName, order: updatedDirection});
    };

    render() {
      const {
        dataQueryParams: {sort, order},
        isRbacEnabled,
      } = this.props;

      const filteredTitles = TABLE_TITLES.filter(
        (field) => field.name !== 'access' || (field.name === 'access' && isRbacEnabled),
      );
      return (
        <div styleName="container">
          {filteredTitles.map((field) => (
            <div key={field.id} styleName={`header ${field.name}`}>
              <TitleHeaderTableOrder
                onChange={this.handleTitleOrderChange}
                title={field.title}
                selectedDirection={order}
                columnName={field.name}
                isSortable={field.isSortable}
                isActive={field.name === sort}
              />
            </div>
          ))}
        </div>
      );
    }
  },
);
