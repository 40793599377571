import React from 'react';
import PropTypes from 'prop-types';
import Spinner from 'common/componentsV2/Spinner';
import CompositeExpandablePanel from 'composites/components/CompositeExpandablePanel';
import AlertCompositeExpandablePanel from 'composites/components/AlertCompositeExpandablePanel';
import {TYPE_COMPOSITES} from 'composites/services/compositesService';
import usePostCompositeMetricsNames from 'composites/api/usePostCompositeMetricsNames';
import {usePostCompositeDelay} from 'composites/api/useUpdateCompositeDelay';
import useFetchDataStreamsList from 'bc/services/useFetchDataStreamList';

import styles from './CompositeItemExpandablePanelContainer.module.scss';

const CompositeItemExpandablePanelContainer = React.memo(({item}) => {
  const {compositeName, isCompositeNameLoading} = usePostCompositeMetricsNames(item?.composite);
  const {compositeDelay, isCompositeDelayLoading} = usePostCompositeDelay(item?.composite?.id);
  const {isDataStreamsListLoading} = useFetchDataStreamsList();
  if (isCompositeNameLoading || isCompositeDelayLoading || isDataStreamsListLoading) {
    return (
      <div
        className={`${styles.loaderWrapper} ${
          item?.composite?.mtype === TYPE_COMPOSITES.COMPOSITES ? styles.compositeWrapper : 0
        }`}
      >
        <Spinner size={50} />
      </div>
    );
  }
  if (item?.composite?.mtype === TYPE_COMPOSITES.COMPOSITES) {
    return (
      <div className={styles.container}>
        <CompositeExpandablePanel
          item={item}
          compositeName={compositeName}
          compositeDelay={compositeDelay?.delay ? compositeDelay?.delay : 0}
        />
      </div>
    );
  }

  return (
    <div className={`${styles.container} ${styles.alert}`}>
      <AlertCompositeExpandablePanel
        item={item}
        compositeName={compositeName}
        compositeDelay={compositeDelay?.delay ? compositeDelay?.delay : 0}
      />
    </div>
  );
});

CompositeItemExpandablePanelContainer.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CompositeItemExpandablePanelContainer;
