import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const anoToken = makeAsyncReducer(actions.fetchSegmentAnoToken, {defaultData: '', errorHandler: bcErrorHandler});
const messageTypes = makeAsyncReducer(actions.fetchSegmentMessageTypes, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const messageMetadata = makeAsyncReducer(actions.fetchSegmentMessageMetadata, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const jsonMetadata = makeAsyncReducer(actions.fetchSegmentJsonDimensions, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

export default combineReducers({
  anoToken,
  messageTypes,
  messageMetadata,
  jsonMetadata,
});
