import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const ACTION_PREFIX = 'anodot/admin.activityLog/';
const mac = (actionName) => makeAction(actionName, ACTION_PREFIX);
const macAsync = (actionName) => makeAsyncAction(actionName, ACTION_PREFIX);

export const updateFilterBtn = mac('UPDATE_FILTER_BTN');
export const fetchAuditLog = macAsync('FETCH_AUDIT_LOG');
export const fetchNextPageAuditLog = macAsync('FETCH_NEXT_PAGE_AUDIT_LOG');
export const fetchTotalCount = macAsync('FETCH_TOTAL_COUNT');
export const fetchListEntityId = macAsync('FETCH_LIST_ENTITY_ID');
export const displayActivitySnackBar = mac('DISPLAY_ACTIVITY_SNACK_BAR');
export const setListEntityId = mac('SET_LIST_ENTITY_ID');
