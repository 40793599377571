// @flow
import React, {useMemo} from 'react';
import {signOut} from 'profile/store/actions';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import ErrorBoundary from 'errorBoundaries/ErrorBoundary';
import {Form} from 'react-final-form';
import * as selectors from 'userSettings/store/selectors';
import * as actions from 'userSettings/store/actions';
import * as profileSelector from 'profile/store/selectors';
import get from 'lodash/get';
import BottomPanel from 'userSettings/components/BottomPanel';
import useAsyncAction from 'common/utils/useAsyncAction';
import SessionTimeout from './SessionTimeout';
import SsoConfigurationsSection from './SsoConfigurationsSection';

const options = [
  {
    label: 'Minutes',
    value: 'minutes',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
];

const useStyles = makeStyles({
  root: {
    paddingBottom: '70px',
  },
});

const AuthenticationSection = () => {
  const dispatch = useDispatch();
  const getDataUser = useSelector(profileSelector.getProfileData);
  const radioSignOnConfig = useSelector(selectors.getEnableRadio);
  const verificationId = useSelector(selectors.getVerificationId);
  const organizationId = useSelector(selectors.getOrganizationId);
  const samlConfig = useSelector(selectors.getSamlConfig);
  const oAuthConfig = useSelector(selectors.getOauthConfig);
  const azureAdConfig = useSelector(selectors.getAzureAdConfig);
  const jwtTtl = useSelector(selectors.getJwtTtl);

  const initialselectTimeoutScale = useMemo(() => {
    if (!jwtTtl) return options[2];
    const timeScale = jwtTtl.match(/[a-z]$/g)[0];
    switch (timeScale) {
      case 'm':
        return options[0];
      case 'h':
        return options[1];
      case 'd':
        return options[2];
      default:
        return options[1];
    }
  }, [jwtTtl]);

  const initialValuesForm = useMemo(
    () => ({
      jwtTtl: (jwtTtl && jwtTtl.match(/[0-9]/g).join('')) || 30,
      selectTimeoutScale: initialselectTimeoutScale,
      radioSignOnConfig,
      enforceSaml: get(samlConfig, 'enforce'),
      loginUrl: get(samlConfig, 'loginUrl'),
      cert: get(samlConfig, 'cert'),
      autoProvisioning: get(oAuthConfig, ['google', 'autoProvisioning']),
      domains: get(oAuthConfig, ['google', 'domains']),
      enforceAzure: get(azureAdConfig, 'enforce'),
      clientId: get(azureAdConfig, 'clientId'),
      clientSecret: get(azureAdConfig, 'clientSecret'),
      directoryId: get(azureAdConfig, 'directoryId'),
      autoProvisioningAzure: get(azureAdConfig, ['autoProvisioning']),
      autoProvisionRolelessUsersAzure: get(azureAdConfig, ['autoProvisionRolelessUsers']),
    }),
    [jwtTtl, samlConfig, radioSignOnConfig, oAuthConfig, azureAdConfig],
  );

  const fetchUpdateSSO = useAsyncAction(actions.updateSSO, getDataUser);

  const actionSubmit = (val, form) => {
    const {jwtTtl: dirtyJwtl, selectTimeoutScale: dirtySelectTimeoutScale} = form.getState().dirtyFields;
    const body = {
      samlConfig: {
        enable: val.radioSignOnConfig === selectors.options[0].nameField,
        loginUrl: val.loginUrl,
        cert: val.cert,
        jitProvisioning: {
          enable: false,
          emailDomains: [],
        },
        enforce: val.enforceSaml || false,
      },
      googleOauthConfig: {
        enable: val.radioSignOnConfig === selectors.options[1].nameField,
        autoProvisioning: val.autoProvisioning,
        domains: val.domains,
      },
      azureAdConfig: {
        enable: val.radioSignOnConfig === selectors.options[2].nameField,
        autoProvisioning: val.autoProvisioningAzure,
        autoProvisionRolelessUsers: val.autoProvisionRolelessUsersAzure,
        clientId: val.clientId,
        clientSecret: val.clientSecret,
        directoryId: val.directoryId,
        enforce: val.enforceAzure,
      },
      accessTokensConfig: {
        jwtTtl: `${val.jwtTtl}${val.selectTimeoutScale.value[0]}`,
        modified: dirtyJwtl || dirtySelectTimeoutScale ? new Date().getTime() : undefined,
      },
      actions: {},
      authConfigs: {
        verificationId,
      },
    };
    return (
      fetchUpdateSSO(body, {organizationId})
        .then(() => {
          if (dirtyJwtl || dirtySelectTimeoutScale) {
            dispatch(signOut());
          } else {
            setTimeout(form.reset);
          }
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error))
    );
  };

  const classes = useStyles();
  return (
    <ErrorBoundary>
      <Form initialValues={initialValuesForm} onSubmit={actionSubmit}>
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit} className={classes.root}>
            <SessionTimeout options={options} />
            <SsoConfigurationsSection />
            {formProps.dirty && (
              <BottomPanel
                errorNumbers={Object.keys(formProps.errors).length}
                isOpenPopper={formProps.submitFailed && formProps.hasValidationErrors}
              />
            )}
          </form>
        )}
      </Form>
    </ErrorBoundary>
  );
};

export default AuthenticationSection;
