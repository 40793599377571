import React from 'react';
import {Box} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';

// Example of usage: <TypographyBox variant='h2' width={100} height={100} color="purple.500">Test</TypographyBox>
// extract color property from typography
// if there is a value in the variant, keep it, if there is no value, use the variant default, else, provide a default

const TypographyBox = ({
  variant,
  isEllipsis,
  color,
  ...rest
}: {
  variant: String,
  isEllipsis: Boolean,
  color: String,
}) => {
  const theme = useTheme();
  return (
    <Box
      /** READ ME BEFORE CHANGING CODE BELOW
       * The code is written in such a way to prioritize certain colors as they are passed. The default value (gray[500])
       * is declared first, then the variant value which may contain an alternate color. Lastly. the unique color
       * is declared last, giving it the highest priority. Only if it has a truthy value
       */
      {...{color: theme.palette.gray[500]}} // default value
      {...{...theme.typography[variant]}} // variant value
      {...(isEllipsis ? {...theme.typography.ellipsis} : null)}
      {...(color ? {color} : null)} // unique color value
      {...rest}
    />
  );
};

export default TypographyBox;
