// @flow
import React from 'react';
import Highlighter from 'react-highlight-words';
import {StringParam, useQueryParam} from 'use-query-params';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import WarningSign from 'common/componentsV2/WarningSign';
import {correctTimestampUnix} from 'common/utils/dateRangeService';

import './CompositeItemHeader.module.scss';

const CompositeItemHeader = React.memo(({item}) => {
  const [searchQuery] = useQueryParam('searchQuery', StringParam);
  const {composite, creationTime, metricsUsageCount, usedInAlerts} = item;
  const dateFormat = creationTime ? moment.unix(correctTimestampUnix(creationTime)).format('MMM DD, YYYY') : '';

  return (
    <div styleName="container list-item">
      <div styleName="title">
        <Tooltip content={composite.title} type={TYPES.SMALL} delay={500}>
          <div styleName="title-text first-item" automation-id="Composite title">
            <Highlighter autoEscape searchWords={[searchQuery]} textToHighlight={composite.title} />
          </div>
        </Tooltip>
      </div>
      <div styleName="field origin">
        <span>{`${composite.mtype[0]}${composite.mtype.slice(1).toLowerCase()}`}</span>
      </div>
      <div styleName="field status">
        {/*! item?.validation - for new composite there is still no validation status */}
        <WarningSign
          notification={item?.validation?.validationErrors.length ? item?.validation?.validationErrors[0]?.message : ''}
          warningLevel={!item?.validation || item?.validation?.passed ? 'alert-0-no-warning' : 'alert-2-warning-red'}
        />
      </div>
      <div styleName="field owner">
        <div styleName="title-text" automation-id="Composite owner">
          <Tooltip content={composite.ownerName} type={TYPES.SMALL}>
            <Highlighter autoEscape searchWords={[searchQuery]} textToHighlight={composite.ownerName} />
          </Tooltip>
        </div>
      </div>
      <div styleName="field dateCreated">
        <div styleName="title-text" automation-id="Composite dateFormat">
          <Highlighter autoEscape searchWords={[searchQuery]} textToHighlight={dateFormat} />
        </div>
      </div>
      <div styleName="field metricsUsage">{metricsUsageCount}</div>
      <div styleName="field usedInAlerts">{usedInAlerts?.length}</div>
    </div>
  );
});

CompositeItemHeader.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CompositeItemHeader;
