import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {
  getAlertRecommendationText,
  RECOMMENDATION_ACTIONS,
  RECOMMENDATION_STATUS,
} from 'recommendations/services/consts';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import AvatarBadge, {COLORS} from 'common/componentsV2/AvatarBadge';
import {correctTimestampUnix} from 'common/utils/dateRangeService';
import fetchRecommendationsProvider from 'recommendations/api/fetchRecommendationsProvider';
import ignoreRecommendation from 'recommendations/api/api';
import {getProfileId} from 'profile/store/selectors';
import {queryErrorHandler} from 'reactQuery/queryClient';
import {GenerateIcon, ICONS} from '@pileus-cloud/anodot-frontend-common';
import fetchRecommendationsByAlertIdProvider from 'recommendations/api/fetchRecommendationsByAlertIdProvider';

import classes from './RecommendationItemExpandablePanel.module.scss';

const RecommendationSubItem = ({
  recommendation,
  recommendationItemId,
  alertConfigurationId,
}: {
  recommendation: Object,
  recommendationItemId: String,
  alertConfigurationId: String,
}) => {
  const meUserId = useSelector(getProfileId);
  const usersResult = fetchUsersProvider().useQuery();
  const recommendationsProvider = fetchRecommendationsProvider();
  const recommendationsProviderByAlertId = fetchRecommendationsByAlertIdProvider(alertConfigurationId);

  const user = useMemo(() => {
    if (recommendation?.userFeId) {
      return usersResult?.data?.find((u) => u.id === recommendation?.userFeId);
    }
    return undefined;
  }, [usersResult?.data, recommendation?.userFeId]);

  const status = useMemo(() => {
    if (recommendation?.applied === true) {
      return (
        <div>
          <GenerateIcon iconName={ICONS.check.name} /> {RECOMMENDATION_STATUS.APPLIED}
        </div>
      );
    }
    if (recommendation?.ignored === true) {
      return (
        <div>
          <GenerateIcon iconName={ICONS.xMark.name} /> {RECOMMENDATION_STATUS.IGNORED}
        </div>
      );
    }
    return '';
  }, [recommendation?.applied, recommendation?.ignored]);

  const getUpdateTime = () => {
    return recommendation?.updatedAt
      ? moment.unix(correctTimestampUnix(recommendation?.updatedAt)).format('h:mm A, MMM DD, YYYY')
      : '';
  };

  const ignore = async () => {
    try {
      recommendationsProvider.syncIgnoreApplyRecommendationInCache(
        recommendationItemId,
        recommendation?.type,
        RECOMMENDATION_ACTIONS.IGNORE,
        meUserId,
      );

      const payload = {
        userId: meUserId,
        type: recommendation?.type,
        ignore: true,
        recommendationId: recommendationItemId,
      };
      await ignoreRecommendation(payload).promise;
      await recommendationsProviderByAlertId.invalidate();
    } catch (ex) {
      const error = {
        title: 'Ignore recommendation error',
        description: `Error while ignoring recommendation`,
      };
      queryErrorHandler(error);
      // Need to synchronize the view of recommendations with the server, since cannot be sure whether the request succeeded or failed.
      await recommendationsProvider.invalidate();
    }
  };

  const apply = () => {
    window.open(
      `/#!/r/alert-manager/edit/${alertConfigurationId}/settings?recommendationTypes=${recommendation?.type}`,
      `_blank-new`,
    );
  };

  return (
    recommendation && (
      <div className={classes.recommendationSubItemContainer}>
        <div className={classes.recommendationText}>{getAlertRecommendationText(recommendation)}</div>
        <div className={classes.recommendationStatus}>{status ?? ''}</div>
        <div className={classes.userWrapper}>
          {user ? (
            <AvatarBadge
              text={user.abbr}
              tooltipText={user.fullName}
              colorSchema={user.defaultGroup ? user.defaultGroup?.colorSchema : COLORS.GRAY}
            />
          ) : (
            <div />
          )}
        </div>
        <div className={classes.recommendationAction}>
          {recommendation?.applied === true || recommendation?.ignored === true ? (
            getUpdateTime()
          ) : (
            <div className={classes.ignoreApplyButtons}>
              <div className={classes.ignore} onClick={ignore}>
                {RECOMMENDATION_ACTIONS.IGNORE}
              </div>
              <div className={classes.apply} onClick={apply}>
                {RECOMMENDATION_ACTIONS.APPLY}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};
export default RecommendationSubItem;
