// @flow
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import {serverAnomalyDirection} from 'alerts.management/services/alertsService';
import Checkbox from 'common/componentsV2/Checkbox';
import TooltipArea from 'common/componentsV2/TooltipArea';
import * as actions from 'alerts.management/store/actions';
import * as selectors from 'alerts.management/store/selectors';

const DirectionSelector = () => {
  const dispatch = useDispatch();
  const {direction} = useSelector(selectors.getDirectionCondition);
  const setSelectedAlertDirectionUp = useCallback(
    (value) => {
      dispatch(
        actions.setSelectedAlertDirection({
          direction: value ? serverAnomalyDirection.both : serverAnomalyDirection.down,
        }),
      );
    },
    [dispatch],
  );

  const setSelectedAlertDirectionDown = useCallback(
    (value) => {
      dispatch(
        actions.setSelectedAlertDirection({
          direction: value ? serverAnomalyDirection.both : serverAnomalyDirection.up,
        }),
      );
    },
    [dispatch],
  );

  return (
    <TooltipArea
      isAlwaysVisible
      automationId="directionContainer"
      text="Choose wether to be alerted on spikes or drops in the data, or both."
    >
      {(info) => (
        <React.Fragment>
          <div className="text16reg lineHeight_16 mb_1-5">
            Alert on Anomalies that
            {info}
          </div>
          <Box display="flex">
            <Box mr={3}>
              <Checkbox
                automationId="anomalySpikesUp"
                isChecked={[serverAnomalyDirection.up, serverAnomalyDirection.both].includes(direction)}
                onChange={setSelectedAlertDirectionUp}
              >
                <div className="text16med lineHeight_16 ml_0-5">Spike</div>
              </Checkbox>
            </Box>
            <Checkbox
              automationId="anomalyDropsDown"
              isChecked={[serverAnomalyDirection.down, serverAnomalyDirection.both].includes(direction)}
              onChange={setSelectedAlertDirectionDown}
            >
              <div className="text16med lineHeight_16 ml_0-5">Drop</div>
            </Checkbox>
          </Box>
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default DirectionSelector;
