// @flow
import React from 'react';
import TriageTab from './TriageTab';

import './TriageTabsBar.module.scss';

const TriageTabsBar = ({
  tabs,
  selectedTabId,
  onTabClick,
}: {
  tabs: Array,
  selectedTabId: String,
  onTabClick: Function,
}) => {
  return (
    <div styleName="container">
      {(tabs || []).map((tab) => (
        <TriageTab
          isEnabled={tab.isEnabled}
          label={tab.label}
          id={tab.id}
          onClick={onTabClick}
          icon={`icn-triage${tab.label}`}
          isSelected={selectedTabId === tab.id}
          key={`inv-tab-bar-${tab.id}`}
        />
      ))}
    </div>
  );
};

export default React.memo(TriageTabsBar);
