/* eslint-disable no-param-reassign */
// @flow

import React from 'react';
import connect from 'react-redux/es/connect/connect';
import * as selectors from 'alerts.management/store/selectors';
import SelectAndt, {TYPE_NEW_MULTI, THEME_HIGHLIGHTED_TRANSPARENT} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import './AlertManagerFooterRecipients.module.scss';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import {segmentClickEvent} from 'common/store/actions';
import shallowEqual from 'common/utils/shallowEqual';

type PropTypes = {
  onApply: Function,
  segmentClickEvent: Function,
  recipientsWithSelectedCheckboxes: [],
  selectedAlertsCheckbox: [],
};

export default connect(
  (state) => ({
    recipientsWithSelectedCheckboxes: selectors.getRecipientsWithSelectedCheckboxes(state),
    selectedAlertsCheckbox: selectors.getSelectedAlertsCheckbox(state),
  }),
  {
    segmentClickEvent,
  },
)(
  class AlertManagerFooterRecipients extends React.PureComponent {
    props: PropTypes;

    state = {
      recipients: [],
      menuIsOpen: false,
      recipientsListNormalized: [],
    };

    componentDidUpdate(prevProps) {
      if (
        !(
          shallowEqual(prevProps.recipientsWithSelectedCheckboxes, this.props.recipientsWithSelectedCheckboxes) &&
          shallowEqual(prevProps.selectedAlertsCheckbox, this.props.selectedAlertsCheckbox)
        )
      ) {
        this.resetRecipientsState(this.props);
      }
      if (prevProps.selectedAlertsCheckbox !== this.props.selectedAlertsCheckbox) {
        this.onMenuClose();
      }
    }

    resetRecipientsState = (props) => {
      const size = props.selectedAlertsCheckbox.length;
      const recipientsListNormalized = props.recipientsWithSelectedCheckboxes.map((recipient) => ({
        icon: recipient.icon,
        label: recipient.label,
        type: recipient.type,
        value: recipient.value,
        isSome: recipient.ids.length > 0,
        isAll: recipient.ids.length === size && size !== 0,
      }));
      const selectedRecipients = [];
      recipientsListNormalized.forEach((recipientNorm) => {
        if (recipientNorm.isSome === true || recipientNorm.isAll === true) {
          selectedRecipients.push(recipientNorm);
        }
      });
      this.setState({recipients: selectedRecipients, recipientsListNormalized});
    };

    handleRecipientsChange = (val) => {
      val.forEach((v) => {
        if (this.state.recipients.findIndex((recipient) => recipient.value === v.value) === -1) {
          v.isAll = true;
          v.isSome = true;
        }
      });
      this.setState({recipients: val});
    };

    handleRecipientsApplyChanges = () => {
      this.props.segmentClickEvent({category: 'alert-manager', name: 'bulk-recipients-add'});
      this.props.onApply(this.state.recipients);
      this.setState({menuIsOpen: false});
    };

    onMenuOpen = () => {
      this.setState({menuIsOpen: true});
    };

    onMenuClose = () => {
      this.resetRecipientsState(this.props);
      this.setState({menuIsOpen: false});
    };

    render() {
      const {recipientsWithSelectedCheckboxes} = this.props;

      const customFilterOption = (option, rawInput) => {
        const checkFunction = (variable, rInput) => variable.label.toLowerCase().indexOf(rInput.toLowerCase()) > -1;

        if (option.data.type === 'HEADER') {
          if (rawInput.length === 0) {
            return true;
          }
          return !!recipientsWithSelectedCheckboxes.find(
            (item) => item.type.toLowerCase() === option.data.value.toLowerCase() && checkFunction(item, rawInput),
          );
        }
        return checkFunction(option, rawInput);
      };

      return (
        <SelectAndt
          automationId="bulkRecipients"
          type={TYPE_NEW_MULTI}
          theme={THEME_HIGHLIGHTED_TRANSPARENT}
          onChange={this.handleRecipientsChange}
          options={this.state.recipientsListNormalized}
          value={this.state.recipients}
          isMulti
          useBoxHalf
          onMenuOpen={this.onMenuOpen}
          onMenuClose={this.onMenuClose}
          placeholder=""
          optionHeight={40}
          offsetLeft={84}
          menuIsOpen={this.state.menuIsOpen}
          menuWidth={330}
          isClearable={false}
          closeMenuOnSelect={false}
          closeMenuOnScroll={false}
          menuFooterComponent={
            <FormDdlActionBar
              isVisible
              isApplyDisabled={this.state.recipients.length === 0}
              onApply={this.handleRecipientsApplyChanges}
            />
          }
          menuPlacement="top"
          filterOption={customFilterOption}
          customComponent={{
            DropdownIndicator: (p) => (
              <div {...p.innerProps} styleName="footer-button-like">
                <i className="icon icn-general16-mail" />
                <span>Recipients</span>
              </div>
            ),
          }}
        />
      );
    }
  },
);
