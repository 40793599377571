import React, {useCallback} from 'react';
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import {STAGES_ACTIONS} from 'recommendations/hooks/useRecommendationsLocalReducer';
import {useRecommendationsContext} from 'recommendations/hooks/useRecommendationsContext';
import RecommendationItemHeader from 'recommendations/components/recommendationsList/RecommendationItemHeader';
import RecommendationItemExpandablePanel from 'recommendations/components/recommendationsList/RecommendationItemExpandablePanel';

const RecommendationItem = ({item, style, isLast}: {item: Object, style: Object, isLast: Boolean}) => {
  const {editState, editDispatch} = useRecommendationsContext();

  const setSelectedItem = useCallback(
    (id) => {
      editDispatch({type: STAGES_ACTIONS.SET_SELECTED_ITEM_ID, payload: id});
    },
    [item],
  );

  return (
    <TableListItem
      automationId="recomendationsTableListItem"
      key={item?.id}
      id={item?.id}
      style={style}
      checkboxVisibility={CHECKBOX_VISIBILITY_TYPES.alwaysHide}
      isSelected={editState.selectedItemId === item?.id}
      setSelectedItem={setSelectedItem}
      isLast={isLast}
      headerComponent={<RecommendationItemHeader item={item} />}
      expandedPanel={<RecommendationItemExpandablePanel recommendationItem={item} />}
      shouldSimulateHover={editState === item?.id}
    />
  );
};
export default RecommendationItem;
