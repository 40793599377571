// @flow
import React, {Fragment, useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {useField} from 'react-final-form';
import Title from 'dashboards/components/fields/Title';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import SwitchButton from 'common/componentsV2/SwitchButton';
import Input from 'common/componentsV2/Input';
import {OPTIONS_DELTA} from 'dashboards/services/dashboardService';
import Checkbox from 'common/componentsV2/Checkbox';
import SimpleExpressionContainer from 'dashboards/components/simpleExpression/SimpleExpressionContainer';

const minutesUnit = 'minutes';
const minDurationOptions = {
  minutes: {value: minutesUnit, label: 'Minutes'},
  hours: {value: 'hours', label: 'Hours'},
  days: {value: 'days', label: 'Days'},
};

const useStyles = makeStyles(() => ({
  leftColumn: {
    fontSize: 14,
    '& .leftColumn': {
      width: 100,
      fontSize: 14,
    },
  },
}));

const PreviewSettingsSidebar = () => {
  const classes = useStyles();

  /* Anomalies Fields */
  const {
    input: {value: scoreValue, onChange: onChangeScoreValue},
  } = useField('score');
  const {
    input: {value: durationUnits, onChange: onChangeMinDurationUnits},
  } = useField('durationUnits');
  const {
    input: {value: durationValue, onChange: onChangeDurationValue},
  } = useField('durationValue');
  const {
    input: {value: deltaValue, onChange: onChangeDeltaValue},
  } = useField('deltaValue');
  const {
    input: {value: deltaType, onChange: onChangeDeltaType},
  } = useField('deltaType');
  const {
    input: {checked: openOnly, onChange: onChangeOpenOnly},
  } = useField('openOnly', {type: 'checkbox'});
  // const {
  //   input: {checked: showEvents, onChange: onShowEvents},
  // } = useField('showEvents', {type: 'checkbox'});
  const {
    input: {checked: directionUp, onChange: onDirectionUp},
  } = useField('directionUp', {type: 'checkbox'});
  const {
    input: {checked: directionDown, onChange: onDirectionDown},
  } = useField('directionDown', {type: 'checkbox'});

  const [isAnomalyOptionsOpen, setAnomalyOptionsOpen] = useState(true);

  return (
    <Fragment>
      <div>
        <Title />
      </div>
      <div className="mt_3 mb_1-5">
        <div className="text14reg lineHeight_16 mb_1">Measure:</div>
        <SimpleExpressionContainer isGroupByEnabled={false} />
      </div>
      <div className={classes.leftColumn}>
        <ExpandableBox
          automationId="anomalyOptionsAnomalyTile"
          label="Anomalies Options"
          expanded={isAnomalyOptionsOpen}
          onChange={setAnomalyOptionsOpen}
          rootStyle={{padding: '1.5em'}}
        >
          <div className="mt_1-5">
            <div className="text14reg lineHeight_16 mb_1">Min Score:</div>
            <Box
              display="flex"
              flexDirection="row"
              css={{'& .score-input': {minWidth: '0', height: 38, width: '90px'}}}
            >
              <div className="mr_2">
                <Input
                  className="score-input"
                  onChange={(e) => onChangeScoreValue(Math.min(e.target.value, 100))}
                  type="number"
                  additionalProps={{max: 100}}
                  value={Number(scoreValue)}
                />
              </div>
            </Box>
          </div>
          <Box
            className="mt_1-5"
            display="flex"
            flexDirection="row"
            css={{'& input': {minWidth: '0', height: 38, width: '90px'}}}
          >
            <div>
              <div className="text14reg lineHeight_16 mt_1-5 mb_1">Min Duration:</div>
              <Input onChange={onChangeDurationValue} type="number" value={durationValue} />
            </div>
            <div className="ml_2-5 mt_4-5">
              <SelectAndt
                automationId="durationOptionsAnomalyTile"
                theme={THEME_NOT_HIGHLIGHTED}
                type={TYPE_NEW_SEARCH}
                options={Object.values(minDurationOptions)}
                optionHeight={40}
                onChange={(o) => onChangeMinDurationUnits(o.value)}
                value={minDurationOptions[durationUnits]}
                buttonWidth={190}
                menuWidth={190}
                minMenuHeight={10}
                maxMenuHeight={250}
                appendToBody={false}
              />
            </div>
          </Box>
          <Box
            className="mt_1-5"
            display="flex"
            flexDirection="row"
            css={{'& input': {minWidth: '0', height: 38, width: '90px'}}}
          >
            <div>
              <div className="text14reg lineHeight_16 mt_1-5 mb_1">Min Delta:</div>
              <Input onChange={onChangeDeltaValue} type="number" value={deltaValue} />
            </div>
            <div className="ml_2-5 mt_4-5">
              <SelectAndt
                automationId="deltaOptionsAnomalyTile"
                theme={THEME_NOT_HIGHLIGHTED}
                type={TYPE_NEW_SEARCH}
                options={OPTIONS_DELTA}
                optionHeight={40}
                onChange={(o) => onChangeDeltaType(o.value)}
                value={OPTIONS_DELTA.find((o) => o.value === deltaType)}
                buttonWidth={190}
                menuWidth={190}
                minMenuHeight={50}
                maxMenuHeight={250}
                appendToBody={false}
              />
            </div>
          </Box>
          <div className="display_flex alignItems_center mt_3">
            <div className="text16reg lineHeight_16 leftColumn">Open Only:</div>
            <div className="ml_1-5">
              <SwitchButton isChecked={openOnly} onChange={onChangeOpenOnly} automationId="openOnly" />
            </div>
          </div>
          <div className="display_flex alignItems_center mt_3">
            <div className="text16reg lineHeight_16 leftColumn">Direction:</div>
            <div className="ml_1-5 mr_2-5 display_flex alignItems_center">
              <Checkbox
                isChecked={directionUp}
                text="Up"
                onChange={(value) => (value || directionDown) && onDirectionUp(value)}
              />
            </div>
            <Checkbox
              isChecked={directionDown}
              text="Down"
              onChange={(value) => (value || directionUp) && onDirectionDown(value)}
            />
          </div>
        </ExpandableBox>
      </div>
    </Fragment>
  );
};

export default PreviewSettingsSidebar;
