import React, {useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {makeStyles} from '@material-ui/core';
import {useHistory} from 'react-router';

import DynamicSvgCollector from 'dataCollectors/components/svgCollector/DynamicSvgCollector';
import CompletionProgressBar from 'dataCollectors/components/completionProgressBar/CompletionProgressBar';
import StreamState from 'dataCollectors/components/streamState/StreamState';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import MoreActionEventStream from 'dataCollectors/components/moreActionEventStream/MoreActionEventStream';

const {STREAM_ID, SOURCE_NAME, STREAM_NAME, TYPE, STREAM_STATE, STREAM_PAUSED} = EVENT_STREAM_FIELDS;

const useStyles = makeStyles(({typography}) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > div': {
      marginLeft: 16,
    },
  },
  stream: {
    position: 'relative',
    ...typography.h1,
    display: 'flex',
    alignItems: 'baseline',
    '&>i': {
      position: 'relative',
      cursor: 'pointer',
      top: -5,
      fontSize: 16,
      marginRight: 5,
    },
  },
  streamName: {
    textTransform: 'capitalize',
    display: 'block',
    maxWidth: 525,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: 6,
    marginRight: 12,
  },
  sourceWrapper: {
    margin: '0 0 0 17px',
    display: 'flex',
    alignItems: 'center',
  },
  sourceImg: {
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  sourceName: {
    marginLeft: 4,
    ...typography.caption,
    display: 'block',
    width: 550,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const StreamHeaderEdit = () => {
  const {getValues} = useFormContext();
  const values = getValues();
  const classes = useStyles();
  const history = useHistory();

  const goToList = useCallback(() => {
    history.push('/bc/data-manager/');
  }, []);

  return (
    <div className={classes.headerWrapper}>
      <div>
        <div className={classes.stream}>
          <i className="icn-arrow16-arrowleft" onClick={goToList} />

          <span>{values[STREAM_ID.id] ? 'Edit Stream: ' : 'Create a New Stream'}</span>
          <span className={classes.streamName}>{values[STREAM_ID.id] ? values[STREAM_NAME.id] : null}</span>
          {'  '}
          <StreamState state={values[STREAM_STATE.id]} isPaused={values[STREAM_PAUSED.id]} />
        </div>

        <div className={classes.sourceWrapper}>
          <span className={classes.sourceImg}>
            <DynamicSvgCollector type={values[TYPE.id]} />
          </span>
          <span className={classes.sourceName}>{values[SOURCE_NAME.id]}</span>
        </div>
      </div>

      <div className={classes.rightSide}>
        <CompletionProgressBar />
        <MoreActionEventStream stream={values} />
      </div>
    </div>
  );
};

export default StreamHeaderEdit;
