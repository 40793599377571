// @flow
import React from 'react';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {calculateAcknowledgedUsers} from 'alerts.console.new/services/alertsConsoleService';

import './Acknowledge.module.scss';

const AcknowledgeIndicator = ({acknowledgedBy}: {acknowledgedBy: Object}) => {
  const usersAcked = calculateAcknowledgedUsers(acknowledgedBy);
  return (
    <>
      {usersAcked ? (
        <SmartTooltip placement="top" content={`Acknowledged By: ${usersAcked}`}>
          <div styleName="ack-indicator">
            <div styleName="wrapper">
              <i styleName="checked-icon" className="icon icn-general16-checkmark" />
              Ack.
            </div>
          </div>
        </SmartTooltip>
      ) : (
        <div styleName="ack-indicator">
          <div styleName="wrapper_empty">&nbsp;</div>
        </div>
      )}
    </>
  );
};

export default AcknowledgeIndicator;
