import React, {useCallback, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {makeStyles} from '@material-ui/core';

import useWatch from 'dataCollectors/hooks/useWatch';
import {useFetchUsers} from 'dataCollectors/api/useFetchUsers';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import {STREAM_STATE_OPTIONS} from 'dataCollectors/services/dataCollectorsService';
import usePreviewClasses from 'dataCollectors/styles/classes.usePreviewClasses';
import {getIsLoading} from 'reactQuery/ReactQueryLoading';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import ContentLoaderPreview from 'dataCollectors/components/loaders/ContentLoaderPreview';
import EditModal from 'dataCollectors/components/EditModal/EditModal';
import StreamInfo from 'dataCollectors/components/streamInfo/StreamInfo';

const useStyles = makeStyles(({palette}) => ({
  infoLabel: {
    color: palette.gray['400'],
  },
}));

const {STREAM_OWNER, MODIFIER, MODIFIER_TIME, CREATOR, CREATE_TIME} = EVENT_STREAM_FIELDS;

const StreamInfoPreview = () => {
  // eslint-disable-next-line no-unused-vars
  const watch = useWatch(); // Do not remove! It make the form to be editable from the preview mode.
  const classes = useStyles();
  const previewClasses = usePreviewClasses();

  const {users} = useFetchUsers();
  const {getValues} = useFormContext();
  const stream = getValues();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const isLoading = getIsLoading([QUERY_KEYS.streams]);

  const findOwner = useCallback(
    (ownerId) => {
      if (!users || !users.length || !ownerId) {
        return {};
      }
      return users.find((user) => user.value === ownerId) || {};
    },
    [users, stream],
  );

  return (
    <div className={previewClasses.sectionWrapper}>
      {isLoading && <ContentLoaderPreview />}
      {!isLoading && (
        <>
          {stream.state === STREAM_STATE_OPTIONS.new.value && (
            <div className={previewClasses.editBtn} onClick={() => setIsModalOpen(!isModalOpen)}>
              Edit
            </div>
          )}

          <div className={previewClasses.content}>
            <div>
              <div className={previewClasses.sectionTitle}>
                <i
                  style={{fontSize: 24, position: 'relative', top: 4, left: -3}}
                  className="icon icn-eventcalendar16"
                />
                Info
              </div>
            </div>

            <div className={previewClasses.row}>
              <span className={classes.infoLabel}>Owner</span>
              <span>{findOwner(stream[STREAM_OWNER.id]).label}</span>
            </div>

            <div className={previewClasses.row}>
              <span className={classes.infoLabel}>Modified by</span>
              <span>{findOwner(stream[MODIFIER.id]).label}</span>
            </div>

            <div className={previewClasses.row}>
              <span className={classes.infoLabel}>Last Modified</span>
              <span>{stream[MODIFIER_TIME.id]}</span>
            </div>

            <div className={previewClasses.row}>
              <span className={classes.infoLabel}>Created By</span>
              <span>{findOwner(stream[CREATOR.id]).label}</span>
            </div>

            <div className={previewClasses.row}>
              <span className={classes.infoLabel}>Created</span>
              <span>{stream[CREATE_TIME.id]}</span>
            </div>
          </div>
        </>
      )}
      <EditModal isOpen={isModalOpen} sectionLevel={0} closeModal={() => setIsModalOpen(false)}>
        <StreamInfo />
      </EditModal>
    </div>
  );
};

export default StreamInfoPreview;
