import {EVENT_STREAM_FIELDS, EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';
import useFetchTableMetadata from 'dataCollectors/api/useFetchTableMetadata';
import usePostFileUploadMetaData from 'dataCollectors/api/usePostFileUploadMetaData';
import useFetchDatasetTableMetadata from 'dataCollectors/api/useFetchDatasetTableMetadata';

const {TYPE, SOURCE_ID, SCHEMA_NAME, TABLE_NAME, PROJECT_ID, DATA_SET} = EVENT_STREAM_FIELDS;
const {LOCAL_FILE, PSQL, BIG_QUERY} = EVENT_STREAM_TYPES;

export default function useDataMapper(stream) {
  // fetch analyze-events or metaData api by stream type
  switch (stream[TYPE.id]) {
    case LOCAL_FILE:
      return usePostFileUploadMetaData(stream[TYPE.id], stream[SOURCE_ID.id]);
    case BIG_QUERY:
      return useFetchDatasetTableMetadata(
        'big-query',
        stream[SOURCE_ID.id],
        stream[PROJECT_ID.id],
        stream[DATA_SET.id],
        stream[TABLE_NAME.id],
      );
    case PSQL:
    default:
      return useFetchTableMetadata(
        stream[TYPE.id],
        stream[SOURCE_ID.id],
        stream[SCHEMA_NAME.id],
        stream[TABLE_NAME.id],
      );
  }
}
