// @flow
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchPropertiesApi} from 'metrics/store/actions';
import {
  getCurrentCall,
  getExpressionTree,
  getPropertiesApiData,
  getPropertiesApiIsLoading,
  getRawFunctionDefinitions,
} from 'metrics/store/selectors';
import {getUniqueId} from 'common/utils/guid';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';

const baseBody = {
  // TODO -- GABPAC -- fill it all with real data, not only the expression tree
  name: {auto: true, prefix: null},
  displayOnly: true,
  excludeComposites: true,
  filter: {
    function: 'alphanumeric',
    parameters: [{name: 'Top N', value: 10}],
    children: [],
    id: getUniqueId(),
    type: 'function',
  },
  scalarTransforms: [
    {
      function: 'current',
      children: [],
      id: getUniqueId(),
      parameters: [],
      type: 'function',
    },
  ],
  context: '',
};
const setBodyForFunction = (expTree) => ({
  ...baseBody,
  expressionTree: expTree,
});
const setBodyForMeasure = (expTree) => ({
  ...baseBody,
  expressionTree: {root: expTree.root.children[0]},
});

const SelectDimensions = (props: {onChange: Function, value: String}) => {
  const dispatch = useDispatch();
  const expressionTree = useSelector(getExpressionTree);
  const properties = useSelector(getPropertiesApiData);
  const currentCall = useSelector(getCurrentCall);
  const propertiesApiIsLoading = useSelector(getPropertiesApiIsLoading);
  const [dimensionsAndTags, setDimensionsAndTags] = useState([]);
  const functions = useSelector(getRawFunctionDefinitions);
  const [filterOptionsByFunction, setFilterOptionsByFunction] = useState(false);
  const [byFunctionOptions, setByFunctionOptions] = useState([]);

  useEffect(
    () => () => {
      if (!dimensionsAndTags.find((item) => item.value === props.value)) {
        props.onChange(null);
      }
    },
    [],
  );

  useEffect(() => {
    if (currentCall === 'noCache') {
      setDimensionsAndTags([]);
    }
    if (expressionTree.root.function.length) {
      dispatch(fetchPropertiesApi(setBodyForFunction(expressionTree)));
    }
    if (!expressionTree.root.function.length) {
      dispatch(fetchPropertiesApi(setBodyForMeasure(expressionTree)));
    }
  }, [currentCall]);

  useEffect(() => {
    if (expressionTree?.root?.function) {
      const functionDef = functions.find((func) => func.name === expressionTree.root.function);
      // if groupBy, ratio pairs, pairs or group functions - present only the group by dimensions
      if (functionDef?.type === 'group' || functionDef?.type === 'grouping') {
        setFilterOptionsByFunction(true);
        // extract group by expression
        let groupByExpression;
        switch (expressionTree.root.function) {
          // for group & groupBy, function, take properties from groupBy expression
          case 'groupBy':
          case 'group':
            groupByExpression = expressionTree.root;
            break;
          // for pairs, take properties from the first group by
          case 'ratioPairs':
          case 'pairs':
            // eslint-disable-next-line prefer-destructuring
            groupByExpression = expressionTree.root.children[0];
            break;
          default:
            groupByExpression = null;
        }
        // extract group py properties from group by expression
        const groupByValue =
          groupByExpression && groupByExpression.parameters
            ? groupByExpression.parameters.find((params) => params.name === 'Group By')?.value
            : null;
        setByFunctionOptions(
          // convert properties list to options
          (groupByValue ? JSON.parse(groupByValue)?.properties : []).map((prop) => ({label: prop, value: prop})),
        );
      } else if (functionDef?.type === 'combined' || functionDef?.functionGroup === 'combined') {
        // if sum or any combine function - do not present any dimension
        setFilterOptionsByFunction(true);
        setByFunctionOptions([]);
      } else {
        // if any other function - present all possible dimensions
        setFilterOptionsByFunction(false);
        setByFunctionOptions([]);
      }
    } else {
      // if there is no function - present all possible dimensions
      setFilterOptionsByFunction(false);
      setByFunctionOptions([]);
    }
  }, [expressionTree, functions]);

  useEffect(() => {
    const propList = properties?.length ? properties.map((option) => ({label: option, value: option})) : [];
    // Apply filtering based on the used function
    const options = filterOptionsByFunction ? byFunctionOptions : propList;
    if (options?.length) {
      setDimensionsAndTags(options.filter((prop) => prop.label.charAt(0) !== '@' && prop.label !== 'what'));
    }
  }, [properties, filterOptionsByFunction, byFunctionOptions]);

  return (
    <>
      <div className="lineHeight_16 mr_1">Route alert by:</div>
      <FormDdlSelect
        button={
          <BigDropdownButton
            isPristine={!dimensionsAndTags.find((item) => item.value === props.value)}
            placeholder={propertiesApiIsLoading ? 'Loading...' : 'Select Dimension or Tag'}
            label={dimensionsAndTags.find((item) => item.value === props.value)?.label}
            blueLean
          />
        }
        options={dimensionsAndTags}
        optionComponent={<OptionComponentSimple />}
        onChange={(item) => props.onChange(item.value)}
        width={160}
        maxWidth={160}
        buttonWidth={160}
        automationId="AlertAckFilter"
        disabled={propertiesApiIsLoading}
        id="alertSettingsMinimumDurationFilter"
      />
    </>
  );
};

export default React.memo(SelectDimensions);
