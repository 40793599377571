import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {palette} from 'app/styles/theme';
import {makeStyles} from '@material-ui/core/styles';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {useDispatch} from 'react-redux';
import {segmentClickEvent} from 'common/store/actions';
import DimensionItem from './DimensionItem';

const OTHER_FROM_LENGTH = 6;

const useStyles = makeStyles(() => ({
  icon: {
    color: palette.gray['350'],
    cursor: 'pointer',
    '&:hover': {
      color: palette.gray['500'],
    },
  },
  dimensionRowContainer: {
    marginBottom: '24px',
  },
  dimensionNameAndPercentage: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  dimensionName: {
    display: 'flex',
    width: '90%',
  },
  subtitle: {
    color: palette.eucaliptus['500'],
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '16px',
    marginRight: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  rightSubtitle: {
    color: palette.gray['400'],
    display: 'flex',
    flexDirection: 'row',
    fontSize: 12,
    gap: 3,
    lineHeight: '16px',
  },
  dimensionItemBar: {
    borderRadius: '4px',
    display: 'flex',
    height: '18px',
    overflow: 'hidden',
  },
}));

const DimensionsBreakdownRow = ({
  token,
  filters,
  onSelect,
  isVisible,
  onChangeVisibility,
  visibilityTooltip,
}: PropTypes) => {
  const classes = useStyles();
  const [isOver, setOver] = useState(false);
  const [mergedToken, setMergedToken] = useState([]);
  const [isKeySelected, setIsKeySelected] = useState([]);
  const dispatch = useDispatch();

  const reportDimensionClickToSegment = () => {
    const segmentEventName = `alert-triage-dimension-breakdown-selected`;
    dispatch(segmentClickEvent({type: 'click', name: segmentEventName}));
  };

  const selected = (event) => {
    reportDimensionClickToSegment();
    onSelect(event);
  };

  const reportDimensionHideShowToSegment = (dimension) => {
    const segmentEventName = `alert-triage-dimension-breakdown-${isVisible ? 'hide' : 'show'}-${dimension}`;
    dispatch(segmentClickEvent({type: 'click', name: segmentEventName}));
  };

  const changedVisibility = (event) => {
    reportDimensionHideShowToSegment(token.key);
    onChangeVisibility(event);
  };

  const makePercentage = (allValues, value) => {
    if (allValues.length === 1) {
      return 100;
    }
    return (value / allValues.reduce((acc, obj) => acc + obj.weight, 0)) * 100;
  };

  const combineOthers = (data) => {
    const other = {
      weight: 0,
      anomalyOccurrences: 0,
      id: 'other',
      value: 'Other',
    };

    data.forEach((value, index) => {
      if (index >= OTHER_FROM_LENGTH) {
        other.weight += value.weight;
        other.anomalyOccurrences += value.anomalyOccurrences;
      }
    });

    const slicedData = data.slice(0, OTHER_FROM_LENGTH);

    if (other.weight === 0) {
      return slicedData;
    }
    return [...slicedData, other];
  };

  useEffect(() => {
    setMergedToken(combineOthers(token.data));
  }, [token]);

  useEffect(() => {
    setIsKeySelected(filters.some((filter) => filter.key === token.key));
  }, [filters]);

  if (isKeySelected) {
    return null;
  }

  const renderVisibilityIcon = () => {
    if (!isOver) {
      return null;
    }
    const icon = isVisible ? 'icn-general16-hide' : 'icn-general16-show';
    if (visibilityTooltip === '') {
      return <div onClick={changedVisibility} className={['icon', icon, classes.icon].join(' ')} />;
    }
    return (
      <Tooltip type={TYPES.SMALL} content={visibilityTooltip}>
        <div>
          <div onClick={changedVisibility} className={['icon', icon, classes.icon].join(' ')} />
        </div>
      </Tooltip>
    );
  };

  return (
    <div
      className={classes.dimensionRowContainer}
      onMouseEnter={() => setOver(true)}
      onMouseLeave={() => setOver(false)}
    >
      {mergedToken.length && (
        <>
          <div className={classes.dimensionNameAndPercentage}>
            <div className={classes.dimensionName}>
              <Tooltip type={TYPES.SMALL} content={token.key}>
                <div className={classes.subtitle}>{token.key}</div>
              </Tooltip>
              {renderVisibilityIcon()}
            </div>
            <div className={`${classes.rightSubtitle}`}>
              <div>{makePercentage(token.data, mergedToken[0].weight).toFixed(0)}%</div>
              <div>{mergedToken[0].value}</div>
            </div>
          </div>
          <div className={classes.dimensionItemBar}>
            {mergedToken.map((dimension, index) => (
              <DimensionItem
                key={dimension.id}
                index={index}
                percentage={makePercentage(token.data, dimension.weight)}
                isLast={index === mergedToken.length - 1}
                dimension={dimension}
                filters={filters}
                onSelect={selected}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

DimensionsBreakdownRow.propTypes = {
  token: PropTypes.objectOf(PropTypes.any).isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onChangeVisibility: PropTypes.func.isRequired,
  visibilityTooltip: PropTypes.string.isRequired,
};

export default DimensionsBreakdownRow;
