// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setPageKeyVal as setPageKeyValAction} from 'admin.dnsTracker/store/actions';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import SearchBox from 'common/components/SearchBox';
import * as selectors from '../store/selectors';
import './PageHeader.module.scss';

type PropTypes = {
  // connect
  searchString: string,
  setPageKeyVal: Function,
};

export default connect(
  (state) => ({
    searchString: selectors.getDnsTrackerSearchString(state),
  }),
  {
    setPageKeyVal: setPageKeyValAction,
  },
)(
  class TrainTrackerPage extends React.PureComponent {
    props: PropTypes;

    onFilter = (val) => {
      const {setPageKeyVal} = this.props;
      setPageKeyVal({searchString: val});
    };

    render() {
      const {searchString} = this.props;

      return (
        <header styleName="header">
          <Title type={TITLE_TYPES.PAGE_TITLE}>DNS Tracker</Title>
          <SearchBox isAutoStretch filter={searchString} onFilter={this.onFilter} />
        </header>
      );
    }
  },
);
