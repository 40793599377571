// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {segmentClickEvent} from 'common/store/actions';
import {useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';

const useStyles = makeStyles(() => ({
  tabItem: {
    alignItems: 'center',
    backgroundColor: palette.white['500'],
    border: `2px solid ${palette.gray['200']}`,
    borderRadius: '18px',
    cursor: 'pointer',
    display: 'inline-flex',
    height: '36px',
    marginRight: '12px',
    padding: '0 16px',
  },
  tabItemSelected: {
    backgroundColor: palette.gray['200'],
  },
  tabItemIcon: {
    alignItems: 'center',
    display: 'flex',
    marginRight: '4px',
  },
  tabItemLabel: {
    color: palette.gray['400'],
    fontSize: '16px',
    fontWeight: '400',
  },
  tabItemLabelSelected: {
    color: palette.blue['500'],
  },
}));

const TabItem = ({label, id, isSelected, icon, onClick, isEnabled}: PropTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reportToSegment = () => {
    dispatch(segmentClickEvent({type: 'click', name: `alert-triage-tab-${label.toLowerCase()}`}));
  };

  const clicked = () => {
    reportToSegment();
    onClick(id);
  };

  return (
    <>
      {isEnabled && (
        <div className={[classes.tabItem, isSelected ? classes.tabItemSelected : ''].join(' ')} onClick={clicked}>
          {icon ? <div className={classes.tabItemIcon}>{icon}</div> : null}
          <div className={[classes.tabItemLabel, isSelected ? classes.tabItemLabelSelected : ''].join(' ')}>
            {label}
          </div>
        </div>
      )}
    </>
  );
};

TabItem.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  isEnabled: PropTypes.bool.isRequired,
};

export default React.memo(TabItem);
