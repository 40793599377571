import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {makeStyles} from '@material-ui/core/styles';
import {mappingToRollup, topElementHeight} from 'investigation/services/investigationService';
import useHandleResize from 'common/hooks/useHandleResize';
import scrollTo from 'common/componentsV2/boxTools/scrollTo';
import useClickOutside from 'common/hooks/useClickOutside';
import {getIsBetweenTime} from 'common/utils/dateService';
import {fetchAlert} from 'alerts.management/store/actions';
import {fetchTimeLine, sendComment} from 'investigation/store/actions';
import {
  getInvModalTrigger,
  getPostCommentIsLoading,
  getTimelineIsLoading,
  getTimelineView,
} from 'investigation/store/selectors';
import {
  getFirstName,
  getProfileId,
  getTimelineUserEventsStartTimeThreshold,
  getTimeZoneName,
} from 'profile/store/selectors';
import {getOrganizationId} from 'userSettings/store/selectors';
import {palette} from 'app/styles/theme';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import Button, {COLORS} from 'common/componentsV2/Button';
import {getAlertData, getAlertIsLoading} from 'alerts.management/store/selectors';
import {segmentClickEvent} from 'common/store/actions';
import TimelineEvent from './TimelineEvent';
import TimelineDayGroup from './TimelineDayGroup';

const useStyles = makeStyles(() => ({
  timelineContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  timelineWrapper: {
    flexGrow: '0',
    maxWidth: '100%',
    flexBasis: '100%',
    margin: '0',
    boxSizing: 'border-box',
  },
  container: {
    position: 'relative',
    padding: '1px 0 1px 0',
    width: '100%',
    overflow: 'hidden',
    height: ({height}) => height,
    marginTop: '8px',
  },
  wrapper: {
    width: 'calc(100% + 35px)',
    position: 'absolute',
    overflowY: 'scroll',
    overflowX: 'hidden',
    overflow: '-moz-scrollbars-none',
    '-ms-overflow-style': 'none',
    top: 0,
    bottom: 0,
    left: 0,
    paddingBottom: '20%',
  },
  spinnerWrapper: {
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowsWrapper: {
    width: 'calc(100% - 35px)',
  },
  maskTop: {
    position: 'absolute',
    zIndex: '2',
    top: 0,
    left: 0,
    height: 30,
    width: '100%',
    background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
  },
  maskBottom: {
    position: 'absolute',
    zIndex: '2',
    bottom: 0,
    left: 0,
    height: 30,
    width: '100%',
    background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
  },
  firstLine: {
    background: palette.red['500'],
    marginLeft: 16,
    width: 2,
    height: 30,
  },
  commentWrapper: {
    marginLeft: 109,
  },
  textareaWrapper: {
    width: '30%',
    marginBottom: 5,
  },
  textarea: {
    width: '100%',
    resize: 'none',
    overflow: 'hidden',
    minHeight: 78,
    borderRadius: 6,
    marginTop: 4,
    padding: 8,
    border: `2px solid ${palette.blue['300']}`,
    '&::placeholder': {
      textTransform: 'capitalize',
      color: palette.gray['300'],
    },
  },
}));

const topHeightToConsider = [
  topElementHeight.minimizingModalMarginTop,
  topElementHeight.headerHeight,
  topElementHeight.tabBarHeight,
  topElementHeight.bodyContainerTopOffset,
];

const TimelineTab = () => {
  let dateToCheck = null;
  const dispatch = useDispatch();
  const timeZoneName = useSelector(getTimeZoneName);
  const customerId = useSelector(getOrganizationId);
  const userId = useSelector(getProfileId);
  const userRollupTime = useSelector(getTimelineUserEventsStartTimeThreshold);
  const firstName = useSelector(getFirstName);
  const trigger = useSelector(getInvModalTrigger);
  const timelineView = useSelector(getTimelineView);
  const alertConfigData = useSelector(getAlertData);
  const alertConfigIsLoading = useSelector(getAlertIsLoading);
  const timelineIsLoading = useSelector(getTimelineIsLoading);
  const postCommentIsLoading = useSelector(getPostCommentIsLoading);
  const [isCommentFieldDisplay, setIsCommentFieldDisplay] = useState(false);
  const [comment, setComment] = useState('');

  const wrapperRef = useRef(null);
  const commentRef = useRef(null);
  const textareaRef = useRef(null);

  const submitComment = () => {
    const params = [
      {
        type: 'comment',
        // triggerId: timelineEvent.triggerId,
        // alertGroupId: trigger.groupId,
        alertGroupId: trigger.groupId,
        // refEventId: timelineEvent.id
        customerId,
        userId,
        text: comment,
      },
    ];
    dispatch(sendComment(params));
    setIsCommentFieldDisplay(false);
    setComment('');
  };

  const bodyContainerHeight = useHandleResize(topHeightToConsider);

  const stylesProps = {
    height: bodyContainerHeight,
  };

  const classes = useStyles(stylesProps);

  useClickOutside(
    textareaRef,
    () => {
      setIsCommentFieldDisplay(false);
    },
    ['comment-send-btn'],
    ['comment-send-btn-class'],
  );

  useEffect(() => {
    if (!isEmpty(trigger) && alertConfigIsLoading === undefined) {
      dispatch(fetchAlert({alertId: trigger.alertConfigurationId}));
    }
  }, []);

  useEffect(() => {
    if (!alertConfigIsLoading && !isEmpty(alertConfigData) && timelineIsLoading === undefined) {
      const isFilterEvent = !!alertConfigData.eventsFilter;

      dispatch(
        fetchTimeLine({
          triggerId: trigger.groupId,
          body: {
            eventsFilters: alertConfigData.eventsFilters,
            rollupTime: userRollupTime[mappingToRollup[trigger.timeScale]],
            userEvents: isFilterEvent
              ? {
                  rollupTime: userRollupTime[mappingToRollup[trigger.timeScale]],
                  aggregation: {...alertConfigData.eventsFilter.aggregation, topEventSize: 1},
                  expression: alertConfigData.eventsFilter.filter?.q?.expression,
                }
              : undefined,
          },
        }),
      );
    }
  }, [alertConfigIsLoading]);

  useEffect(() => {
    if (timelineIsLoading === false) {
      scrollTo(wrapperRef, commentRef);
    }
  }, [timelineIsLoading]);

  const setIsDayGroupHidden = (date) => {
    const isSameDay = getIsBetweenTime(date, dateToCheck, 'day', timeZoneName);

    if (isSameDay) {
      return true;
    }
    dateToCheck = date;
    return false;
  };

  const reportToSegment = () => {
    dispatch(segmentClickEvent({type: 'click', name: `alert-triage-timeline-tab-add-comment`}));
  };

  const addCommentClicked = () => {
    reportToSegment();
    setIsCommentFieldDisplay(true);
  };

  return (
    <div className={classes.timelineContainer}>
      <div className={classes.timelineWrapper}>
        <div className={classes.container}>
          <div className={classes.maskTop} />
          <div className={classes.maskBottom} />
          <div className={classes.wrapper} ref={wrapperRef}>
            {timelineIsLoading && (
              <div className={classes.spinnerWrapper}>
                <Spinner color={palette.gray[500]} size={SpinnerSizes.XX_BIG_150} />
              </div>
            )}
            {timelineIsLoading === false && !!timelineView.length && (
              <div className={classes.rowsWrapper}>
                <div className={classes.firstLine} />
                {timelineView.map((tle, index) => (
                  <Fragment key={tle.id}>
                    <TimelineDayGroup
                      isHidden={setIsDayGroupHidden(tle.dataTime || tle.createdTime)}
                      date={tle.dataTime || tle.createdTime}
                      timeZoneName={timeZoneName}
                      alertStatus={tle.alertStatus}
                    />
                    <TimelineEvent
                      timelineEvent={tle}
                      isLast={index === timelineView.length - 1}
                      timeZoneName={timeZoneName}
                    />
                  </Fragment>
                ))}
                <div ref={commentRef} className={classes.commentWrapper}>
                  {postCommentIsLoading && (
                    <div style={{marginLeft: '8px'}}>
                      <Spinner color={palette.gray[500]} size={SpinnerSizes.SMALL_30} />
                    </div>
                  )}
                  {!isCommentFieldDisplay && !postCommentIsLoading && (
                    <Button
                      automationId="cancel"
                      extraClassName={classes.btnSelected}
                      colorSchema={COLORS.TRANSPARENT_BLUE}
                      icon="icn-general16-chat"
                      text="Add a comment"
                      onClick={addCommentClicked}
                    />
                  )}
                  {isCommentFieldDisplay && !postCommentIsLoading && (
                    <div className={classes.textareaWrapper}>
                      <textarea
                        ref={textareaRef}
                        className={classes.textarea}
                        placeholder={`${firstName}'s comments`}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                      <Button
                        extraClassName="comment-send-btn-class"
                        automationId="sendTimelineComment"
                        text="Send"
                        icon="icn-action16-send"
                        colorSchema={COLORS.BLUE_500}
                        isDisabled={!comment.length}
                        onClick={submitComment}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelineTab;
