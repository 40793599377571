/* eslint-disable radix */
import React, {Fragment, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import {getSelectedDataStream, getSelectedDataStreamDvp, getIsDataStreamUpdating} from 'bc/store/selectors';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {
  DVP,
  getUnitFormatted,
  getValueFormated,
  createDvpObjForServer,
  getMaxDurationHours,
} from 'bc/components/streams/editor/missingDataSection/missingDataService';
import Input from 'common/componentsV2/Input';
import Button, {COLORS} from 'common/componentsV2/Button';

import {pollingIntervals} from 'bc/services/dataStreamService';

const useStyles = makeStyles(({palette}) => ({
  container: {
    height: 255,
    backgroundColor: palette.white[500],
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto, sans-serif',
    fontSize: 14,
    fontWeight: '400',
  },
  title: {
    marginTop: 24,
    marginBottom: 8,
    fontWeight: '500',
  },
  firstSection: {
    display: 'flex',
    alignItems: 'center',
    '& > div, & > span': {
      flexShrink: '0',
      marginRight: 8,
    },
  },
  header: {
    fontSize: 22,
    fontWeight: '300',
    color: palette.gray[500],
    lineHeight: '25px',
    flexShrink: 0,
  },
  footer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    marginRight: 12,
  },
}));

const inputStyle = {width: 60, height: 24, padding: '12px 6px'};

const MissingDataModal = ({isOpen, onClose}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dataStream = useSelector(getSelectedDataStream);
  const dvpConfig = useSelector(getSelectedDataStreamDvp);
  const isLoading = useSelector(getIsDataStreamUpdating);

  const [maxDurationHoursLimit, setMaxDurationHoursLimit] = useState(0);
  const [isMaxHoursInValid, setIsMaxHoursInValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isDvpConfig, setIsDvpConfig] = useState(null);
  const [maxDVPDurationHours, setMaxDVPDurationHours] = useState(0);
  const [maxDVPDurationUnit, setMaxDVPDurationUnit] = useState('');
  const [gaugeKeepLastValue, setGaugeKeepLastValue] = useState(true);
  const [counterKeepLastValue, setCounterKeepLastValue] = useState(false);
  const [gaugeValue, setGaugeValue] = useState(0);
  const [counterValue, setCounterValue] = useState(0);

  const resetValues = () => {
    setIsDvpConfig(DVP.dvpConfig.options[0].value);
    setMaxDVPDurationHours(DVP.maxDVPDurationHours.value);
    setMaxDVPDurationUnit(DVP.maxDVPDurationUnit.options[0].value);
    setGaugeKeepLastValue(DVP.gaugeKeepLastValue.options[0].value);
    setCounterKeepLastValue(DVP.counterKeepLastValue.options[0].value);
    setGaugeValue(DVP.gaugeValue.value);
    setCounterValue(DVP.counterValue.value);
  };

  const syncWithServer = () => {
    setIsDvpConfig(true);
    setMaxDVPDurationHours(getValueFormated(dvpConfig.maxDVPDurationHours));
    setMaxDVPDurationUnit(getUnitFormatted(dvpConfig.maxDVPDurationHours).value);

    setGaugeKeepLastValue(dvpConfig.gaugeValue.keepLastValue);
    setGaugeValue(dvpConfig.gaugeValue.value);

    setCounterKeepLastValue(dvpConfig.counterValue.keepLastValue);
    setCounterValue(dvpConfig.counterValue.value);
  };

  const onCancel = () => {
    setIsDirty(false);

    if (dvpConfig) {
      syncWithServer();
    } else {
      resetValues();
    }
    onClose();
  };

  const onApply = () => {
    const data = createDvpObjForServer({
      [DVP.dvpConfig.type]: isDvpConfig,
      [DVP.maxDVPDurationHours.type]: maxDVPDurationHours,
      [DVP.maxDVPDurationUnit.type]: maxDVPDurationUnit,
      [DVP.gaugeKeepLastValue.type]: gaugeKeepLastValue,
      [DVP.gaugeValue.type]: gaugeValue,
      [DVP.counterKeepLastValue.type]: counterKeepLastValue,
      [DVP.counterValue.type]: counterValue,
      [DVP.baseRollup.type]: pollingIntervals[dataStream.pollingInterval].timeScale,
    });

    dispatch(setSelectedStreamKeyVal(data));
    setIsDirty(false);
  };

  const onChange = (type, value) => {
    setIsDirty(true);

    switch (type) {
      case DVP.dvpConfig.type:
        setIsDvpConfig(value);
        break;
      case DVP.maxDVPDurationHours.type:
        setMaxDVPDurationHours(value);
        break;
      case DVP.maxDVPDurationUnit.type:
        setMaxDVPDurationUnit(value);
        break;
      case DVP.gaugeKeepLastValue.type:
        setGaugeKeepLastValue(value);
        break;
      case DVP.gaugeValue.type:
        setGaugeValue(value);
        break;
      case DVP.counterKeepLastValue.type:
        setCounterKeepLastValue(value);
        break;
      case DVP.counterValue.type:
        setCounterValue(value);
        break;
      default:
        break;
    }
  };

  const setInvalidMsg = () => {
    if (isMaxHoursInValid) {
      const value =
        maxDVPDurationUnit === DVP.maxDVPDurationUnit.options[0].value
          ? maxDurationHoursLimit
          : maxDurationHoursLimit / 24;
      return `Max duration is ${value} ${maxDVPDurationUnit}`;
    }
    return 'Enter minimum duration';
  };

  useEffect(() => {
    if (dvpConfig) {
      syncWithServer();
    } else {
      resetValues();
    }
  }, [dvpConfig]);

  useEffect(() => {
    const limitMaxHours = getMaxDurationHours(pollingIntervals[dataStream.pollingInterval].timeScale);
    const isValueMoreThenLimit =
      maxDVPDurationUnit === DVP.maxDVPDurationUnit.options[0].value
        ? parseInt(maxDVPDurationHours) > limitMaxHours
        : parseInt(maxDVPDurationHours) > limitMaxHours / 24;

    setMaxDurationHoursLimit(limitMaxHours);
    setIsMaxHoursInValid(isValueMoreThenLimit);
  }, [maxDVPDurationHours, maxDVPDurationUnit]);

  useEffect(() => {
    if (isLoading === false) {
      onClose();
      setIsDirty(false);
    }
  }, [isLoading]);

  return (
    <Modal
      classStyle="user-edit-modal"
      id="user-edit-modal"
      isOpen={isOpen}
      onClose={onClose}
      size={SIZES.MEDIUM}
      isStatic={false}
    >
      <div className={classes.container}>
        <div className={classes.header}>Missing Data</div>

        <span className={classes.title}>In case of No Data</span>
        <div className={classes.firstSection}>
          <div>
            <SelectAndt
              automationId="dvpConfigMainDD"
              optionHeight={40}
              menuWidth={255}
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_BLUE_LEAN}
              options={DVP.dvpConfig.options}
              onChange={(i) => onChange(DVP.dvpConfig.type, i.value)}
              value={DVP.dvpConfig.options.find((i) => i.value === isDvpConfig)}
              isDisabled={isLoading}
            />
          </div>

          {isDvpConfig && (
            <Fragment>
              <span>For max duration of</span>
              <div>
                <Input
                  automationId="dvpConfigMainInputMaxDurationHours"
                  style={inputStyle}
                  isInvalid={isMaxHoursInValid || !maxDVPDurationHours || maxDVPDurationHours === '0'}
                  type="number"
                  onChange={(e) => onChange(DVP.maxDVPDurationHours.type, e.target.value)}
                  value={maxDVPDurationHours}
                  fullSize
                  isDisabled={isLoading}
                  invalidMessage={setInvalidMsg()}
                  invalidMessageStyle={{position: 'absolute'}}
                />
              </div>
              <div>
                <SelectAndt
                  automationId="dvpConfigMainInputMaxDurationUnit"
                  optionHeight={40}
                  menuWidth={150}
                  type={TYPE_NEW_NO_SEARCH}
                  theme={THEME_BLUE_LEAN}
                  options={DVP.maxDVPDurationUnit.options}
                  onChange={(i) => onChange(DVP.maxDVPDurationUnit.type, i.value)}
                  value={DVP.maxDVPDurationUnit.options.find((i) => i.value === maxDVPDurationUnit)}
                  isDisabled={isLoading}
                />
              </div>
            </Fragment>
          )}
        </div>

        {isDvpConfig && (
          <Fragment>
            <span className={classes.title} style={{marginBottom: 0}}>
              The Value
            </span>

            {/* Gauge */}
            <div className={classes.firstSection} style={{height: 30}}>
              <span>For Gauge fill in with</span>
              <SelectAndt
                automationId="dvpConfigDDGaugeLastValue"
                optionHeight={40}
                menuWidth={150}
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_BLUE_LEAN}
                options={DVP.gaugeKeepLastValue.options}
                onChange={(item) => onChange(DVP.gaugeKeepLastValue.type, item.value)}
                value={DVP.gaugeKeepLastValue.options.find((i) => i.value === gaugeKeepLastValue)}
                isDisabled={isLoading}
              />
              {!gaugeKeepLastValue && (
                <Input
                  automationId="dvpConfigInputGaugeValue"
                  style={inputStyle}
                  type="number"
                  onChange={(e) => onChange(DVP.gaugeValue.type, e.target.value)}
                  value={gaugeValue}
                  fullSize
                  isDisabled={isLoading}
                  isInvalid={!gaugeKeepLastValue && gaugeValue === ''}
                />
              )}
            </div>

            {/* Counter */}
            <div className={classes.firstSection} style={{height: 40}}>
              <span>For Counters fill in with</span>

              <SelectAndt
                automationId="dvpConfigDDCounterLastValue"
                optionHeight={40}
                menuWidth={150}
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_BLUE_LEAN}
                options={DVP.counterKeepLastValue.options}
                onChange={(item) => onChange(DVP.counterKeepLastValue.type, item.value)}
                value={DVP.counterKeepLastValue.options.find((i) => i.value === counterKeepLastValue)}
                isDisabled={isLoading}
              />
              {!counterKeepLastValue && (
                <Input
                  automationId="dvpConfigInputCounterValue"
                  style={inputStyle}
                  type="number"
                  onChange={(e) => onChange(DVP.counterValue.type, e.target.value)}
                  value={counterValue}
                  fullSize
                  isDisabled={isLoading}
                />
              )}
            </div>
          </Fragment>
        )}
      </div>

      <div className={classes.footer}>
        <span className={classes.cancelBtn}>
          <Button text="Cancel" colorSchema={COLORS.GRAY_300} onClick={onCancel} />
        </span>
        <Button
          text="Apply"
          colorSchema={COLORS.BLUE_500}
          onClick={onApply}
          isDisabled={
            isLoading ||
            !isDirty ||
            isMaxHoursInValid ||
            !maxDVPDurationHours ||
            maxDVPDurationHours === '0' ||
            (!gaugeKeepLastValue && gaugeValue === '')
          }
        />
      </div>
    </Modal>
  );
};

MissingDataModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MissingDataModal;
