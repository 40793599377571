import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {
  getAccessListAllSourcesData,
  getAccessListAllSourcesIsLoading,
  getSetAccessListForSourceIsLoading,
  getGroups,
} from 'admin.permissions/store/selectors';
import {fetchGroupsIsLoading} from 'admin.users/store/selectors';
import * as actions from 'admin.permissions/store/actions';
import {types, ACCESS_LIST_OPTIONS} from 'admin.permissions/services/accessListService';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';
import Button, {COLORS, WIDTHS} from 'common/componentsV2/Button';
import BaloonMessage from 'common/componentsV2/BaloonMessage';
import RadioButtonsList from 'common/componentsV2/RadioButtonsList';

const useStyle = makeStyles(({typography}) => ({
  root: {
    position: 'relative',
    width: '750PX',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    ...typography.h2,
  },
  selectedWrapper: {
    width: 275,
    marginTop: 12,
    marginBottom: 'auto',
  },
  btnsWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  cancelBtn: {
    marginRight: 18,
  },
  selectedMembersWrapper: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    height: 32,
    borderBottom: `1px solid ${palette.gray['200']}`,
    alignItems: 'center',
    padding: 8,
    fontSize: 16,
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  topBorder: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: -1,
    backgroundColor: palette.blue['300'],
    height: 1,
    visibility: 'hidden',
  },
  editBtnWrapper: {
    position: 'absolute',
    top: -5,
    left: 167,
  },
  baloonMsgWrapper: {
    marginTop: 'auto',
    marginLeft: 'auto',
    marginBottom: 20,
    width: '50%',
  },
}));

const AccessListSection = ({streamId}: PropTypes) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const allAccessList = useSelector(getAccessListAllSourcesData);
  const accessListIsLoading = useSelector(getAccessListAllSourcesIsLoading);
  const setAccessListForSourceIsLoading = useSelector(getSetAccessListForSourceIsLoading);
  const groups = useSelector(getGroups);
  const groupsIsLoading = useSelector(fetchGroupsIsLoading);

  const [isDirty, setIsDirty] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]); // DropDown
  const [accessListOption, setAccessListOption] = useState({}); // Radio Btns selected option
  const [accessListOptions, setAccesListOptions] = useState([]); // Radio Btns ptions
  const [displaySelectedGroups, setDisplaySelectedGroups] = useState(false);

  const updateServer = (payload) => {
    dispatch(
      actions.setAccesslistForSource(
        {
          streamId,
          groupsIds: payload,
        },
        {type: types.stream, successMsg: true},
      ),
    );
    setIsDirty(false);
  };

  const syncWithServer = () => {
    const accessList = allAccessList[streamId];

    if (accessList[0] === ACCESS_LIST_OPTIONS.EVERYONE.payload) {
      setAccessListOption(ACCESS_LIST_OPTIONS.EVERYONE);
      setSelectedGroups([]);
      return;
    }
    if (accessList[0] === ACCESS_LIST_OPTIONS.PRIVATE.payload) {
      setAccessListOption(ACCESS_LIST_OPTIONS.PRIVATE);
      setSelectedGroups([]);
      return;
    }
    setAccessListOption(ACCESS_LIST_OPTIONS.SELECTED_GROUPS);
    setSelectedGroups(groups.filter((i) => accessList.find((j) => i.value === j)));
  };

  const cancel = () => {
    syncWithServer();
    setIsDirty(false);
  };

  const update = () => {
    if (accessListOption.value === ACCESS_LIST_OPTIONS.EVERYONE.value) {
      updateServer([ACCESS_LIST_OPTIONS.EVERYONE.payload]);
    }
    if (accessListOption.value === ACCESS_LIST_OPTIONS.PRIVATE.value) {
      updateServer([ACCESS_LIST_OPTIONS.PRIVATE.payload]);
    }
    if (accessListOption.value === ACCESS_LIST_OPTIONS.SELECTED_GROUPS.value) {
      updateServer(selectedGroups.map((i) => i.value));
    }
  };

  const onSelectOption = (selectedOption) => {
    setAccessListOption(selectedOption);
    setIsDirty(true);
  };

  const onSetSelectedGroups = (selectedItems) => {
    setSelectedGroups(selectedItems);
    setIsDirty(true);
  };

  useEffect(() => {
    const accessList = allAccessList[streamId];

    if (!accessListIsLoading && !groupsIsLoading && accessList) {
      syncWithServer();
    }
  }, [accessListIsLoading, groupsIsLoading]);

  useEffect(() => {
    if (accessListOption.value === ACCESS_LIST_OPTIONS.SELECTED_GROUPS.value) {
      setDisplaySelectedGroups(true);
    } else {
      setDisplaySelectedGroups(false);
    }
  }, [accessListOption]);

  useEffect(() => {
    setAccesListOptions(
      Object.keys(ACCESS_LIST_OPTIONS).map((key) => ({
        value: ACCESS_LIST_OPTIONS[key].value,
        label: ACCESS_LIST_OPTIONS[key].title,
        info: ACCESS_LIST_OPTIONS[key].info,
        disabled: accessListIsLoading || groupsIsLoading || setAccessListForSourceIsLoading,
      })),
    );
  }, [accessListIsLoading, groupsIsLoading, setAccessListForSourceIsLoading]);

  return (
    <div className={classes.root}>
      <div>
        <span className={classes.title}>Access Settings</span>
      </div>

      <div className={classes.root}>
        <div style={{marginTop: 24, marginBottom: 18}}>
          <RadioButtonsList
            options={accessListOptions}
            value={accessListOption.value}
            onChange={onSelectOption}
            direction="column"
          />
        </div>

        {displaySelectedGroups && (
          <div style={{marginBottom: 'auto'}}>
            <span>Who can access</span>
            <SelectAndt
              noAutoReorder
              type={TYPE_NEW_MULTI}
              theme={THEME_NOT_HIGHLIGHTED}
              isClearable={false}
              closeMenuOnSelect={false}
              onChange={onSetSelectedGroups}
              options={groups}
              buttonWidth={225}
              optionHeight={40}
              isMulti
              value={selectedGroups}
              placeholder="None"
              automationId="editAccessListCreatedStreamFromStreamModal"
            />
          </div>
        )}

        {isDirty && (
          <div className={classes.baloonMsgWrapper}>
            <BaloonMessage width="100%">
              <div>Updating the access groups may have an effect on the Stream’s linked entities. </div>
            </BaloonMessage>
          </div>
        )}

        <div className={classes.btnsWrapper}>
          <Button
            fixedWidth={WIDTHS.REGULAR}
            colorSchema={COLORS.GRAY_300}
            text="Cancel"
            onClick={cancel}
            automationId="dataManagerExpendAll"
            extraClassName={classes.cancelBtn}
          />
          <Button
            isDisabled={!isDirty}
            fixedWidth={WIDTHS.REGULAR}
            colorSchema={COLORS.BLUE_500}
            text="Update"
            onClick={update}
            automationId="dataManagerExpendAll"
          />
        </div>
      </div>
    </div>
  );
};

AccessListSection.propTypes = {
  streamId: PropTypes.string.isRequired,
};

export default AccessListSection;
