import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {combineReducers} from 'redux';
import * as actions from '../actions';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const fetchSingleImpact = composeReducers(
  makeAsyncReducer(actions.getSingleImpactNewAlertConsole, {
    defaultData: EMPTY_OBJECT,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const fetchImpactsList = composeReducers(
  makeAsyncReducer(actions.getImpactsByOriginNewAlertConsole, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const updateImpact = composeReducers(
  makeAsyncReducer(actions.putImpactNewAlertConsole, {
    defaultData: EMPTY_OBJECT,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const createImpact = composeReducers(
  makeAsyncReducer(actions.postImpactNewAlertConsole, {
    defaultData: EMPTY_OBJECT,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const removeImpact = composeReducers(
  makeAsyncReducer(actions.deleteImpactNewAlertConsole, {
    defaultData: EMPTY_OBJECT,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

export default combineReducers({
  fetchSingleImpact,
  fetchImpactsList,
  updateImpact,
  createImpact,
  removeImpact,
});
