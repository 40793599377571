import React, {useMemo, useState, useEffect, useCallback} from 'react';

import {isEmpty} from 'lodash';
import {useField} from 'react-final-form';
import FiltersArray from 'dashboards/components/filters/FiltersArray';
import {makeFilter} from '../simpleExpression/utils';

const EMPTY_ARRAY = [];

const EMPTY_VALUE = [[]];

const FiltersField = ({isEditable, isApplyMode}: {isEditable: boolean, isApplyMode: boolean}) => {
  const {
    input: {value: formValue, onChange: onChangeForm},
  } = useField('filters');

  const [localValue, onChangeLocalValue] = useState(formValue);

  useEffect(() => {
    onChangeLocalValue(formValue);
  }, [formValue]);

  const value = isApplyMode ? localValue : formValue;

  const onChange = useCallback(
    (changedValue, isCheckbox) => {
      if (isApplyMode && isCheckbox) {
        onChangeLocalValue(changedValue);
      } else {
        onChangeForm(changedValue);
      }
    },
    [isApplyMode],
  );

  // adds an empty filter option for the filter button.
  // for new filter, button is before filters
  const valueWithEmpty = useMemo(() => (value?.length && isEmpty(value[0]) ? value : EMPTY_VALUE.concat(value)), [
    value,
  ]);

  const appliedValueWithEmpty = useMemo(
    () => ((formValue?.length && isEmpty(formValue[0])) || !isEditable ? formValue : EMPTY_VALUE.concat(formValue)),
    [formValue, isEditable],
  );

  const onApply = useCallback(() => onChangeForm(localValue), [localValue]);
  const onBlur = useCallback(() => onChangeLocalValue(formValue), [formValue]);

  return (
    <FiltersArray
      isApplyMode={isApplyMode}
      isEditable={isEditable}
      isDisabled={!isEditable}
      filter={makeFilter(value, true)}
      value={valueWithEmpty || EMPTY_ARRAY}
      onChange={onChange}
      onApply={isApplyMode ? onApply : undefined}
      onBlur={onBlur}
      appliedValue={appliedValueWithEmpty || EMPTY_ARRAY}
    />
  );
};

export default FiltersField;
