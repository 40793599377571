// @flow
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'composites/services/compositesService';

const root = 'api/v1/metrics/';
const getComposites = () => get(`${root}composite`);

export default function useFetchCompositesList(onSuccess) {
  const fallback = [];
  const {
    dataUpdatedAt,
    data: compositesList = fallback,
    isLoading: isCompositesListLoading,
    isFetching: isCompositesListFetching,
    refetch: refetchCompositeList,
  } = useQuery(QUERY_KEYS.COMPOSITE_LIST, getComposites, {
    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
  });

  return {
    updatedAt: dataUpdatedAt,
    compositesList,
    isCompositesListLoading,
    isCompositesListFetching,
    refetchCompositeList,
  };
}
