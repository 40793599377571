// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setSelectedStreamGA4Account,
  setSelectedStreamGA4Property,
  fetchGoogleGA4AccountsAndProperties,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import GaDropdown from './GaDropdown';

import './Accounts.module.scss';

type PropTypes = {
  isLoadingAccounts: boolean,
  accounts: Array,
  account: Object,

  isLoadingProperties: boolean,
  properties: Array,
  property: Object,

  dataStream: Object,

  setSelectedStreamGA4Account: Function,
  setSelectedStreamGA4Property: Function,
  fetchGoogleGA4AccountsAndProperties: Function,
};

export default connect(
  (state) => ({
    isLoadingAccounts: selectors.getGoogleGA4Accounts(state).isLoading,
    accounts: selectors.getGoogleGA4AccountsItems(state),
    account: selectors.getGoogleGA4SelectedAccount(state),

    isLoadingProperties: selectors.getGoogleGA4Properties(state).isLoading,
    properties: selectors.getGoogleGA4PropertiesItems(state),
    property: selectors.getGoogleGA4SelectedProperty(state),

    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamGA4Account,
    setSelectedStreamGA4Property,
    fetchGoogleGA4AccountsAndProperties,
  },
)(
  class Accounts extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {
        dataStream: {dataSourceId, accountId, propertyId},
      } = this.props;
      this.props.fetchGoogleGA4AccountsAndProperties({
        dataSourceId,
        accountId,
        propertyId,
      });
    }

    onAccountSelect = (val) => {
      this.props.setSelectedStreamGA4Account({accountId: val});
    };

    onPropertySelect = (val) => {
      this.props.setSelectedStreamGA4Property({propertyId: val});
    };

    render() {
      const {isLoadingAccounts, accounts, account, isLoadingProperties, properties, property} = this.props;

      const isEditable = isStreamEditable(this.props.dataStream.state) || isStreamRunning(this.props.dataStream.state);

      return (
        <div styleName="root">
          <PanelHeader title="Web Site Data" isEditable={isEditable} />
          <GaDropdown
            isLoading={isLoadingAccounts}
            items={accounts}
            item={account}
            disabled={!isEditable}
            onChange={(v) => this.onAccountSelect(v)}
            ddlId="ga_accounts_ddl"
            ddlTitle="Choose account"
            placeholder="Choose an account"
          />

          <GaDropdown
            isLoading={isLoadingProperties}
            items={properties}
            item={property}
            disabled={!isEditable}
            onChange={(v) => this.onPropertySelect(v)}
            ddlId="ga_properties_ddl"
            ddlTitle="Choose property"
            placeholder="Choose a property"
          />
        </div>
      );
    }
  },
);
