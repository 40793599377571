import {ALERT_TYPES} from 'alerts.console.new/services/alertsConsoleService';

export const FEEDBACK_TYPE = {
  GOOD_CATCH: 'GOOD_CATCH',
  NOT_INTERESTING: 'NOT_INTERESTING',
};

export const NOT_INTERESTING_REASONS = [
  {value: 'noBusinessImpact', title: 'No Business Impact'},
  {value: 'incorrectData', title: 'Incorrect Data'},
  {value: 'notAnAnomaly', title: 'Not an Anomaly'},
];

export const NOT_INTERESTING_REASONS_ANOMALY = [
  {value: 'noBusinessImpact', title: 'There is no business impact'},
  {value: 'notAnAnomaly', title: 'This does not look like an anomaly'},
  {value: 'incorrectData', title: 'The data seems off'},
];

export const NOT_INTERESTING_REASONS_STATIC_NO_DATA = [
  {value: 'noBusinessImpact', title: 'There is no business impact'},
  {value: 'incorrectData', title: 'The alert looks inaccurate'},
  {value: 'notAnAnomaly', title: 'The data seems off'},
];

export const GOOD_CATCH_REASONS = [
  {value: 'outageMajorIncident', title: 'Outage / Major Incident'},
  {value: 'technicalIssue', title: 'Technical Issue'},
  {value: 'businessCompetition', title: 'Business / Competition'},
];

export const FEEDBACK_ORIGIN = {
  ALERT_CONSOLE: 'alertConsole',
  ANOBOARD: 'anoboard',
  INSIGHT_PANEL: 'insightPanel',
  INVESTIGATION: 'investigation',
};

export const FEEDBACK_SIZE = {
  mid: {
    container: {
      width: 70,
    },
    icons: {
      fontSize: 16,
    },
  },
  large: {
    container: {
      width: 90,
    },
    icons: {
      fontSize: 24,
    },
  },
};

export const feedbackReasons = (feedbackType, triggerType) => {
  let reasons = [];
  if (feedbackType === FEEDBACK_TYPE.GOOD_CATCH) {
    reasons = GOOD_CATCH_REASONS;
  }
  if (feedbackType === FEEDBACK_TYPE.NOT_INTERESTING) {
    if (triggerType === ALERT_TYPES.ANOMALY) {
      reasons = NOT_INTERESTING_REASONS_ANOMALY;
    }
    reasons = NOT_INTERESTING_REASONS_STATIC_NO_DATA;
  }
  return reasons;
};
