import React from 'react';
import {useField} from 'react-final-form';
import Checkbox from 'common/componentsV2/Checkbox';
import {makeStyles} from '@material-ui/core';
import {isEmpty} from 'lodash';
import {palette} from 'app/styles/theme';

makeStyles(() => ({
  button: {
    background: palette.blue[100],
    border: `2px solid ${palette.blue[300]}`,
    borderRadius: '6px',
    color: palette.blue[500],
    cursor: 'pointer',
    padding: '10px 12px',
    display: 'inline-block',
    lineHeight: '14px',
    fontWeight: 500,
    pointerEvents: ({errors}) => (isEmpty(errors) ? 'auto' : 'none'),
    opacity: ({errors}) => (isEmpty(errors) ? 1 : 0.5),
  },
}));
const SalesforceLoginSandbox = () => {
  const {
    input: {value: isSandboxChecked, onChange: onSalesforceSandboxChecked},
  } = useField('isSalesforceSandboxChecked');

  return (
    <Checkbox
      id="isSalesforceSandboxChecked"
      automationId="salesforce-sandbox"
      isChecked={isSandboxChecked}
      onChange={() => onSalesforceSandboxChecked(!isSandboxChecked)}
      text="Salesforce sandbox"
    />
  );
};

export default SalesforceLoginSandbox;
