// @flow
import React from 'react';
import {noop} from 'lodash';
import './Tag.module.scss';
import connect from 'react-redux/es/connect/connect';
import {segmentClickEvent} from '../store/actions';
import Tooltip, {TYPES} from './Tooltip';

type PropTypes = {
  title: String,
  isDismissible?: boolean,
  onDismiss?: Function,

  // connect
  segmentClickEvent: Function,
};

export default connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)(
  class Button extends React.PureComponent {
    props: PropTypes;

    static defaultProps = {
      isDismissible: false,
      onDismiss: noop,
    };

    onDismiss = () => {
      this.props.segmentClickEvent({type: 'click', name: 'dismiss'});
      this.props.onDismiss(this.props.title);
    };

    render() {
      return (
        <div styleName="container">
          <Tooltip content={this.props.title} type={TYPES.SMALL}>
            <span styleName="value">{this.props.title}</span>
          </Tooltip>
          {this.props.isDismissible ? (
            <span styleName="dismiss-section">
              <div styleName="gradient" />
              <i className="icon icn-general16-closeb" role="presentation" onClick={this.onDismiss} />
            </span>
          ) : null}
        </div>
      );
    }
  },
);
