import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {palette, typography} from 'app/styles/theme';
import {STREAM_STATE_OPTIONS} from 'dataCollectors/services/dataCollectorsService';

export default function StreamState({state, isPaused}) {
  const setText = useMemo(() => {
    if (state === STREAM_STATE_OPTIONS.done.value) {
      return STREAM_STATE_OPTIONS.done.label;
    }
    if (state === STREAM_STATE_OPTIONS.new.value) {
      return STREAM_STATE_OPTIONS.new.label;
    }
    if (state === STREAM_STATE_OPTIONS.running.value && !isPaused) {
      return STREAM_STATE_OPTIONS.running.label;
    }
    if (state === STREAM_STATE_OPTIONS.running.value && isPaused) {
      return STREAM_STATE_OPTIONS.paused.label;
    }
    return '';
  }, [state, isPaused]);

  const setColor = useMemo(() => {
    if (state === STREAM_STATE_OPTIONS.new.value) {
      return palette.orange[500];
    }
    if (state === STREAM_STATE_OPTIONS.running.value && !isPaused) {
      return palette.eucaliptus[500];
    }
    return palette.gray[500];
  }, [state, isPaused]);

  return (
    state && (
      <span
        style={{
          ...typography.subtitle2,
          padding: '2px 4px',
          borderRadius: 4,
          backgroundColor: palette.gray[200],
          color: setColor,
        }}
      >
        {setText}
      </span>
    )
  );
}

StreamState.propTypes = {
  state: PropTypes.string.isRequired,
  isPaused: PropTypes.bool.isRequired,
};
