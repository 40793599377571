import {getFormattedLLDate} from 'common/utils/dateService';
import {correctTimestampUnix} from 'common/utils/dateRangeService';

export const OPTIONS_VALUE = {
  RANGE: 'range',
  RELATIVE: 'relative',
  RELATIVE_RANGE: 'relative future',
};

export const RELATIVE_OPTIONS = [
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Weeks',
    value: 'weeks',
  },
];

export const AMPM_OPTIONS = [
  {
    label: 'AM',
    value: 'am',
  },
  {
    label: 'PM',
    value: 'pm',
  },
];

export const transformToSeconds = (value, scale) => {
  switch (scale) {
    case 'weeks':
      return value * 3600 * 24 * 7;
    case 'days':
      return value * 3600 * 24;
    case 'hours':
      return value * 3600;
    default:
      return value * 3600 * 24;
  }
};

export const calculateScale = (value, defaultScale) => {
  if (!value) {
    return {
      value: 0,
      scale: defaultScale || 'days',
    };
  }
  const days = value / (3600 * 24);
  const weeks = value / (3600 * 24 * 7);
  const hours = value / 3600;
  const isDays = value % (3600 * 24) === 0;
  const isWeeks = value % (3600 * 24 * 7) === 0;

  return {
    // eslint-disable-next-line no-nested-ternary
    scale: isWeeks ? 'weeks' : isDays ? 'days' : 'hours',
    // eslint-disable-next-line no-nested-ternary
    value: isWeeks ? weeks : isDays ? days : hours,
  };
};

export const formattedValue = (value) => {
  if (value !== '') {
    return getFormattedLLDate(correctTimestampUnix(value));
  }
  return value;
};
