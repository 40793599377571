// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor} from 'bc/store/actions';
import {
  getSelectedDataStream,
  getMixpanelStreamSelectedDimensions,
  getMixpanelStreamSelectedMetrics,
  getMixpanelStreamPreviewIsLoading,
  getMixpanelStreamEntityIsLoading,
} from 'bc/store/selectors';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import {getEditorValidationMessage} from 'bc/services/bqService';
import DimensionsAndMetrics from 'bc/components/streams/editor/common/DimensionsAndMetrics';
import {get} from 'lodash';

type PropTypes = {
  dataStream: Object,
  dimensions: Array,
  metrics: Array,
  setSelectedEditor: Function,
  mixpanelStreamPreviewIsLoading: Boolean,
  mixpanelStreamEntityIsLoading: Boolean,
};

class MixpanelDimensionsAndMetrics extends React.PureComponent {
  props: PropTypes;

  setSelectedEditor = () => {
    this.props.setSelectedEditor({
      type: 'dm_mixpanel',
      editorHeight: 'calc(100% - 210px)',
    });
  };

  render() {
    const {dataStream, dimensions, metrics, mixpanelStreamPreviewIsLoading, mixpanelStreamEntityIsLoading} = this.props;
    const isEditable =
      (isEditRunning(dataStream.id) || isStreamEditable(dataStream.state)) && get(dataStream, 'uiState.eventMetadata');

    return (
      <DimensionsAndMetrics
        dataStream={dataStream}
        dimensions={dimensions}
        metrics={metrics}
        isLoading={mixpanelStreamPreviewIsLoading || mixpanelStreamEntityIsLoading}
        isEditable={isEditable}
        validatorFunction={getEditorValidationMessage}
        onSelectEditor={() => this.setSelectedEditor()}
      />
    );
  }
}

export default connect(
  (state) => ({
    dataStream: getSelectedDataStream(state),
    dimensions: getMixpanelStreamSelectedDimensions(state),
    metrics: getMixpanelStreamSelectedMetrics(state),
    mixpanelStreamPreviewIsLoading: getMixpanelStreamPreviewIsLoading(state),
    mixpanelStreamEntityIsLoading: getMixpanelStreamEntityIsLoading(state),
  }),
  {
    setSelectedEditor,
  },
)(MixpanelDimensionsAndMetrics);
