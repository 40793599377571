// @flow
import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import {getDate} from 'common/utils/dateRangeService';
import Accordion from 'common/componentsV2/accordion/Accordion';
import DateRangesDdl, {THEME} from 'common/components/dateTime/DateRangesDdl';
import OuterRow from 'common/componentsV2/accordion/OuterRow';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';

import * as actions from 'admin.cs-portal/store/actions';
import * as selectors from 'admin.cs-portal/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import {palette} from 'app/styles/theme';
import MetricsCardinalityStream from './MetricsCardinalityStream';
import './MetricsCardinality.module.scss';

type PropTypes = {
  // connect
  selectedCustomer: Object,
  streams: Array,
  fetchStreams: Function,
  isStreamsLoading: Boolean,
  timeZone: String,
  fetchStreamDimensions: Function,
};

export default connect(
  (state) => ({
    selectedCustomer: selectors.getSelectedCustomer(state),
    timeZone: profileSelectors.getTimeZoneName(state),
    streams: selectors.getMergedStreams(state),
    isStreamsLoading: selectors.getIsStreamsLoading(state),
  }),
  {
    fetchStreams: actions.fetchStreams,
    fetchStreamDimensions: actions.fetchStreamDimensions,
  },
)(
  class MetricsCardinality extends React.PureComponent {
    props: PropTypes;

    constructor(props) {
      super(props);

      this.dateRange = getDate(
        {
          constRange: 'h4',
          startDate: null,
          endDate: null,
          relativeLast: null,
          relativeNext: null,
        },
        'Browser',
      );

      this.epochDateRange = {
        ...this.dateRange,
        startDate: moment(this.dateRange.startDate).unix(),
        endDate: moment(this.dateRange.endDate).unix(),
      };

      this.state = {
        dateRange: this.epochDateRange,
      };
    }

    componentDidMount() {
      const {selectedCustomer} = this.props;

      if (selectedCustomer && selectedCustomer.value) {
        this.fetchStreams(selectedCustomer);
      }
    }

    componentDidUpdate(prevProps) {
      const {selectedCustomer} = this.props;

      if (selectedCustomer && selectedCustomer.value && prevProps.selectedCustomer !== selectedCustomer) {
        this.fetchStreams(selectedCustomer);
      }
    }

    fetchStreams = (selectedCustomer) => {
      const {dateRange} = this.state;

      this.props.fetchStreams({
        _id: selectedCustomer.value,
        token: selectedCustomer.token,
        startTime: dateRange.startDate,
        endTime: dateRange.endDate,
      });
    };

    fetchStreamDimensions = (stream) => {
      const {fetchStreamDimensions, selectedCustomer} = this.props;
      const {startDate, endDate} = this.state.dateRange;

      if (stream.dimensions && stream.dimensions.totalMetrics) {
        return;
      }
      fetchStreamDimensions(
        {
          _id: selectedCustomer.value,
          startTime: startDate,
          endTime: endDate,
          streamName: stream.name,
        },
        stream.name,
      );
    };

    dateRangeChange = (dateRange) => {
      this.setState({dateRange}, () => {
        this.fetchStreams(this.props.selectedCustomer);
      });
    };

    render() {
      const {streams} = this.props;

      return (
        <div styleName="container">
          <div styleName="header">
            <span styleName="title">Metric Cardinality</span>
            <DateRangesDdl
              buttonWidth={150}
              onChange={this.dateRangeChange}
              dateRange={this.state.dateRange}
              isUnix
              theme={THEME.BLUE_LEAN}
              timeZoneName={this.props.timeZone}
              position="right"
            />
          </div>

          <div styleName="accordion-wrapper">
            <h3>Streams ({streams.length})</h3>

            {this.props.isStreamsLoading ? (
              <div styleName="loader-wrapper">
                <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
              </div>
            ) : (
              <Accordion allowMultipleOpen>
                {streams.map((stream) => (
                  <OuterRow
                    key={stream.name}
                    isLoading={stream.dimensions && !stream.dimensions.metricsCardinality}
                    label={stream.name}
                    titles={[
                      `${stream.name} (${stream.numMetrics} ${stream.numMetrics === 1 ? 'metric' : 'metrics'})`,
                      'Cardinality',
                    ]}
                    onClick={() => this.fetchStreamDimensions(stream)}
                  >
                    {stream.dimensions && stream.dimensions.totalMetrics && (
                      <MetricsCardinalityStream metricsCardinality={stream.dimensions.metricsCardinality} />
                    )}
                  </OuterRow>
                ))}
              </Accordion>
            )}
          </div>
        </div>
      );
    }
  },
);
