import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {segmentClickEvent} from 'common/store/actions';
import {getTimeZoneName} from 'profile/store/selectors';
import EmptyMessage, {IMAGE_TYPES} from 'common/componentsV2/table/EmptyMessage';
import AlertTriggersTableRowNewAlertConsole from 'alerts.management/components/manager/alertExpandablePanel/AlertTriggersTableRowNewAlertConsole';
import {REF_VALUES} from 'alerts.console.new/services/alertsConsoleService';

import './AlertTriggersTable.module.scss';

const AlertTriggersTableNewAlertConsole = ({
  configurationAlertId,
  triggers,
  totalTriggers,
}: {
  configurationAlertId: String,
  triggers: Array,
  totalTriggers: Number,
}) => {
  const dispatch = useDispatch();
  const timeZoneName = useSelector(getTimeZoneName);

  const goTo = () => {
    dispatch(segmentClickEvent({category: 'triggers-tab', name: 'show-all-triggers'}));
    window.open(
      `/#!/r/alerts-console-new?ref=${REF_VALUES.ALERT_MANAGER}&configIds=${configurationAlertId}&constRange=y1`,
    );
  };

  return (
    <div styleName="container">
      <header>
        <div styleName="title">{`Alert Triggers (${totalTriggers || 0} times in the last 30 days)`}</div>
        <div styleName="startTime">Start Time</div>
        <div styleName="updateTime">Update Time</div>
        <div styleName="duration">Duration</div>
        <div styleName="score">Score</div>
        <div styleName="link" />
      </header>
      {triggers?.length === 0 ? (
        <EmptyMessage imageType={IMAGE_TYPES.NO_TRIGGERED} title="No alerts were triggered in the last 30 days" />
      ) : (
        triggers
          ?.slice(0, 5)
          .map((trigger) => (
            <AlertTriggersTableRowNewAlertConsole
              trigger={trigger}
              key={`trigger-${trigger.alertGroupId}`}
              timeZoneName={timeZoneName}
            />
          ))
      )}
      {totalTriggers > 1 ? (
        <a onClick={goTo} styleName="see-all" target="_blank" rel="noopener noreferrer">
          See All
        </a>
      ) : null}
    </div>
  );
};

export default AlertTriggersTableNewAlertConsole;
