// @flow
import React, {useEffect, useState, useCallback, Fragment} from 'react';
import {debounce} from 'lodash';
import './AlertsConsoleHeader.module.scss';

const NoFace = () => {
  const [show, setShow] = useState(false);
  const [keysCombination, setKeysCombination] = useState('');

  const createKeysCombination = (event) => {
    setKeysCombination((prevState) => prevState + event.key);
  };

  const verify = useCallback(
    debounce((str) => {
      if (str === 'aha') {
        setShow(true);
      }
      setKeysCombination('');
    }, 500),
    [],
  );

  useEffect(() => {
    if (keysCombination !== '') {
      verify(keysCombination);
    }
  }, [keysCombination]);

  useEffect(() => {
    window.addEventListener('keydown', createKeysCombination);
    return () => window.removeEventListener('keydown', createKeysCombination);
  }, []);

  useEffect(() => {
    let timer1 = null;
    if (show) {
      timer1 = setTimeout(() => setShow(false), 4250);
    }
    return () => {
      clearTimeout(timer1);
    };
  }, [show]);

  return <Fragment>{show && <div styleName="no-face" />}</Fragment>;
};

export default NoFace;
