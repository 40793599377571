import React from 'react';
import {Route, useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {CompositesProvider} from 'composites/hooks/useCompositesContext';
import {FormProvider} from 'react-hook-form';
import CompositesPage from 'composites/pages/CompositesPage';
import CompositesModal from 'composites/components/modals/CompositesModal';
import DeleteModal from 'composites/components/modals/DeleteModal';
import DelayModal from 'composites/components/modals/DelayModal';

const CompositesRouter = ({match}) => {
  const history = useHistory();
  return (
    <>
      <CompositesProvider>
        <FormProvider>
          <Route path={`${match.url}`} render={() => <CompositesPage />} />
          <Route
            path={[`${match.url}/duplicate`, `${match.url}/edit`, `${match.url}/new`, `${match.url}/view`]}
            render={() => <CompositesModal backUrl={match.url} />}
          />
          <Route
            path={`${match.url}/delete/:id`}
            render={() => <DeleteModal backUrl={`${match.url}${history.location.search}`} />}
          />
          <Route
            path={`${match.url}/delay/:id`}
            render={() => <DelayModal backUrl={`${match.url}${history.location.search}`} />}
          />
        </FormProvider>
      </CompositesProvider>
    </>
  );
};

CompositesRouter.propTypes = {
  match: PropTypes.string.isRequired,
};

export default CompositesRouter;
