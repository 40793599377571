// @flow
import React, {useCallback} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {ACK} from 'alerts.console/services/alertsConsoleService';

const AckFilter = () => {
  const [ack, setAck] = useQueryParam('ack', StringParam);
  const toggleFilter = useCallback(
    (value) => {
      setAck(value);
    },
    [setAck],
  );

  const optionCurrent = ACK.findIndex((i) => ack === i.value);
  return (
    <SelectAndt
      id="ackFilter"
      automationId="AlertAckFilter"
      extraClassName="alerts-dropdown-btn"
      options={ACK}
      optionHeight={40}
      menuWidth={175}
      type={TYPE_NEW_NO_SEARCH}
      theme={THEME_HIGHLIGHTED}
      onChange={(item) => toggleFilter(item.value)}
      value={ACK[optionCurrent]?.value ? ACK[optionCurrent] : null}
      placeholder="All"
    />
  );
};

export default AckFilter;
