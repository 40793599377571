import {useQuery} from 'react-query';
import {queryClient} from 'reactQuery/queryClient';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const url = 'api/v1/channels/salesforce/clientId';

const fetchSalesforceChannelClientId = async () => {
  const clientId = await get(url);
  return clientId;
};

export default function fetchSalesforceChannelClientIdProvider() {
  const queryKey = [QUERY_KEYS.salesforceChannelClientId];
  return {
    fetchQuery: () =>
      queryClient.fetchQuery(queryKey, () => fetchSalesforceChannelClientId(), {
        retry: false,
      }),
    useQuery: () =>
      useQuery(queryKey, () => fetchSalesforceChannelClientId(), {
        retry: false,
      }),
  };
}
