import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {post} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import useGetPreviewDataForTable from 'dataCollectors/hooks/useGetPreviewDataForTable';

const root = 'api/v1/bc/';

const getFileUploadPreview = (sourceId, fileFormat) => {
  if (!fileFormat) return null;
  return post(`${root}data-sources/local-file/${sourceId}/parsing/preview`, {fileFormat});
};

export default function usePostFileUploadPreview(sourceId, fileFormat) {
  const selectFn = useCallback((res) => {
    return useGetPreviewDataForTable(res);
  }, []);

  const {
    data: tablePreviewData = {},
    isLoading: isTablePreviewLoading,
    isFetching: isTablePreviewFetching,
    refetch: refetchTablePreviewData,
  } = useQuery(`${QUERY_KEYS.filePreview}${sourceId}`, () => getFileUploadPreview(sourceId, fileFormat), {
    enabled: !!(sourceId && fileFormat),
    select: selectFn,
  });

  return {tablePreviewData, isTablePreviewLoading, isTablePreviewFetching, refetchTablePreviewData};
}
