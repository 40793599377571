import {combineEpics} from 'redux-observable';
import * as actions from '../actions';
import * as api from '../../services/api';
import {getUniqueId} from '../../../common/utils/guid';
import {makeAsyncEpic} from '../../../common/utils/simplifiedAsync';

const fetchSumologicPreview = makeAsyncEpic(actions.fetchSumologicPreview, api.fetchSumologicPreview);

const setSumologicSchema = (action$) =>
  action$.ofType(actions.setSumologicSchema.TYPE).switchMap((action) => {
    const streamData = action.payload.metaData;
    const res = {
      query: action.payload.query,
      schema: {
        columns: [],
        sourceColumns: [],
      },
      metrics: [],
      dimensions: [],
      uiState: {
        metaData: action.payload.metaData,
      },
    };
    streamData.forEach((item) => {
      if (item.name !== '_timeslice') {
        if (item.keyField) {
          res.schema.columns.push({
            id: getUniqueId(),
            sourceColumn: item.name,
            name: item.name,
            type: 'dimension',
          });
          res.schema.sourceColumns.push({
            id: item.name,
            name: item.name,
          });
          res.dimensions.push(item.name);
        } else {
          res.schema.columns.push({
            id: getUniqueId(),
            sourceColumn: item.name,
            name: item.name,
            type: 'metric',
          });
          res.schema.sourceColumns.push({
            id: item.name,
            name: item.name,
          });
          res.metrics.push(item.name);
        }
      }
    });
    return [actions.setSelectedStreamKeyVal(res)];
  });

const sumologicEpic = combineEpics(fetchSumologicPreview, setSumologicSchema);

export default sumologicEpic;
