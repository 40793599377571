import 'moment-timezone';
import './pieChartsSettingsService.module.scss';
import {palette} from 'app/styles/theme';

export const FEEDBACK = {
  basicColor: [palette.blue[500], palette.indigo[500]],
  borderColor: palette.gray[100],
  borderWidth: 1,
};

export const ALERTS_SEVERITY = {
  basicColor: [palette.red[500], palette.orange[500], palette.yellow[500], palette.azure[500], palette.gray[400]],
  borderColor: palette.gray[100],
  borderWidth: 1,
};

export const ALERTS_TYPE = {
  basicColor: [palette.blue[500], palette.indigo[400], palette.indigo[500]],
  borderColor: palette.gray[100],
  borderWidth: 1,
};

export const TOKEN_MAP = {
  basicColor: [
    palette.mint[600],
    palette.mint[590],
    palette.mint[580],
    palette.mint[570],
    palette.mint[560],
    palette.mint[550],
    palette.mint[540],
    palette.mint[530],
    palette.mint[520],
    palette.mint[510],
  ],
  borderColor: null,
  borderWidth: 0,
};

const TOOLTIP = {
  shadow: false,
  useHTML: true,
  outside: true,
  backgroundColor: 'transparent',
  borderWidth: 0,
  hideDelay: 50,
};

const DATA_LABELS = {
  enabled: true,
  crop: false,
  shadow: false,
  useHTML: true,
  formatter() {
    /* eslint-disable react/no-this-in-sfc */
    if (this.point.percentage) {
      return `<span style="width: 27px;font-family: Roboto;font-size: 11px;font-weight: 500;color: palette.white[500];">
        ${Math.round(this.point.percentage)}%</span>`;
    }
    /* eslint-enable react/no-this-in-sfc */
    return null;
  },
  distance: -15,
};

export const getHchartConfig = (
  data = {},
  onClickEvent = false,
  labels = false,
  theme = FEEDBACK,
  tooltipFormatter,
  isSelectable = false,
  animation = true,
  isDonut = false,
  hoverEnabled = false,
  onMouseOverEvent,
  onMouseOutEvent,
) => ({
  chart: {
    type: 'pie',
    credits: {
      enabled: false,
    },
    margin: isSelectable ? [2, 2, 2, 2] : [0, 0, 0, 0],
    spacingTop: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    spacingRight: 0,
  },
  title: {text: null},
  plotOptions: {
    series: {
      innerSize: isDonut ? '60%' : '0%',
      cursor: onClickEvent ? 'pointer' : 'default',
      events: {click: onClickEvent},
      point: {
        events: {mouseOver: onMouseOverEvent, mouseOut: onMouseOutEvent},
      },
      states: {
        select: {
          enabled: isSelectable,
          lineWidthPlus: 5,
        },
        hover: {
          enabled: false,
          halo: {
            size: 0,
          },
        },
      },
      animation: animation ? {duration: 500} : false,
      marker: {
        enabled: false,
      },
    },
    pie: {
      colors: theme.basicColor,
      dataLabels: labels ? DATA_LABELS : {enabled: false},
      animation: {
        enabled: false,
      },
      shadow: false,
      allowPointSelect: isSelectable,
      slicedOffset: 0,
      borderWidth: data?.some((i) => i.percentage === 100) ? 0 : theme.borderWidth,
      borderColor: theme.borderColor,
      states: {
        hover: {
          brightness: 0,
          enabled: hoverEnabled || false,
        },
        inactive: {
          enabled: hoverEnabled || false,
          opacity: 0.5,
        },
      },
    },
  },
  tooltip:
    tooltipFormatter && tooltipFormatter.formatter
      ? {...TOOLTIP, formatter: tooltipFormatter.formatter}
      : {enabled: false},
  legend: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  series: [
    {
      showInLegend: false,
      data,
    },
  ],
});
