// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import * as selectors from 'bc/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import * as actions from 'bc/store/actions';
import DataSourceModal from '../../DataSourceModal';
import '../../DataSourceModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  isViewOnly: boolean,
  isEditable: boolean,
  sourceType: Object,
  source: Object,
  onClose: Function,

  // connect
  me: Object,
  isLoading: boolean,
  sourceError: Object,
  anoToken: String,

  updateDataSource: Function,
  createDataSource: Function,
  resetAddSourceError: Function,
  fetchSegmentAnoToken: Function,
};

export default connect(
  (state) => ({
    me: profileSelectors.getUserProfile(state),
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
    anoToken: selectors.getSegmentAnoTokenItem(state),
  }),
  {
    createDataSource: actions.createDataSource,
    updateDataSource: actions.updateDataSource,
    resetAddSourceError: actions.resetAddSourceError,
    fetchSegmentAnoToken: actions.fetchSegmentAnoToken,
  },
)(
  class SegmentSourceModal extends React.PureComponent {
    props: PropTypes;

    // eslint-disable-next-line no-extra-boolean-cast
    state = !!this.props.source
      ? {
          sourceName: this.props.source.name,
        }
      : {
          sourceName: `${this.props.me.firstName} ${this.props.me.lastName} ${new Date().getTime().toString()}`,
        };

    componentDidMount() {
      const {fetchSegmentAnoToken, isViewOnly} = this.props;
      if (!isViewOnly) {
        fetchSegmentAnoToken();
      }
    }

    onCloseInternal = () => {
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    createBtnClicked = () => {
      const {updateDataSource, source, sourceType, anoToken, createDataSource, onClose} = this.props;

      if (this.isUpdate) {
        updateDataSource({
          ...source,
          name: this.state.sourceName,
          anotoken: anoToken,
        });
        onClose();
        return;
      }
      createDataSource({
        type: getTypeDetails(sourceType.type).type,
        name: this.state.sourceName,
        anotoken: anoToken,
      });
    };

    render() {
      const {isOpen, isLoading, sourceError, source, sourceType, anoToken, isViewOnly, isEditable} = this.props;
      const {sourceName} = this.state;

      return (
        <DataSourceModal
          isOpen={isOpen}
          isContinueDisabled={!sourceName || !anoToken}
          isProcessing={isLoading}
          isViewOnly={isViewOnly}
          isRenameEnabled={isEditable}
          source={source}
          sourceType={sourceType}
          logoImageClass="source-logo-segment"
          onClose={this.onCloseInternal}
          onContinue={this.createBtnClicked}
        >
          <div styleName="inputs-wrapper">
            <input
              type="text"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              onChange={(e) => this.setState({sourceName: e.target.value})}
              placeholder="Source Name"
              value={sourceName}
            />

            <input
              type="text"
              readOnly
              disabled
              placeholder="Integration Token"
              value={isViewOnly ? source.anotoken : anoToken}
            />

            <CopyToClipboardButton
              copyStr={anoToken}
              tooltipMessage="Copy the Integration Token to clipboard"
              styleName="copy-clipboard-btn-pos"
              shouldUseIcon
            />

            {!isLoading && sourceError ? (
              <div styleName="error-wrapper">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" /> {sourceError.uMessage}
                </span>
              </div>
            ) : null}

            <div styleName="extra-info">
              <span>* Copy the Integration Token and use it in the Anodot outgoing integration in UI.</span>
              <a
                href="https://support.anodot.com/hc/en-us/articles/360018508380"
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn More
              </a>
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
