// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import * as filterSelector from 'alerts.management/store/filterSelector';
import {getLabelsEnabled} from 'profile/store/selectors';
import LabelFilter from 'admin.labels/LabelFilter';

const EMPTY_ARRAY = [];

type PropTypes = {
  setQueryParams: Function,

  // connect
  tagsList?: Array,
  selectedTags: String,
  isLabelsEnabled: boolean,
};

export default connect(
  (state) => ({
    tagsList: filterSelector.getTagListFilter(state),
    selectedTags: filterSelector.getSelectedFilterTag(state),
    isLabelsEnabled: getLabelsEnabled(state),
  }),
  {},
)(
  class TagsFilter extends React.PureComponent {
    props: PropTypes;

    static defaultProps = {
      tagsList: EMPTY_ARRAY,
    };

    onChange = (items) => {
      const tagVal = items.length ? items.map((item) => item.value || item.name).join(',') : null;
      this.props.setQueryParams({tag: tagVal || undefined});
    };

    onClearAll = () => {
      const {tagsList, selectedTags, setQueryParams} = this.props;
      if (selectedTags?.length > 0) {
        setQueryParams({tag: undefined});
      } else {
        const optionCurrent = tagsList?.map((i) => i.name).join(',');
        setQueryParams({tag: optionCurrent || undefined});
      }
    };

    render() {
      const {tagsList, selectedTags} = this.props;
      const sortedTagsList = tagsList.sort((a, b) => b.count - a.count);

      return this.props.isLabelsEnabled ? (
        <LabelFilter automationId="alertTagsFilter" onChange={this.onChange} placeholder="Labels" />
      ) : (
        <SelectAndt
          automationId="alertTagsFilter"
          type={TYPE_NEW_MULTI}
          theme={THEME_HIGHLIGHTED}
          onChange={this.onChange}
          options={sortedTagsList}
          value={selectedTags}
          isMulti
          placeholder="Labels"
          optionHeight={40}
          menuWidth={270}
          isClearable={false}
          closeMenuOnSelect={false}
          getOptionLabel={(val) => (val.val ? val.val : val.name)}
          getOptionValue={(val) => val.name}
          menuFooterComponent={
            <FormDdlActionBar
              isVisible
              selectedCount={selectedTags ? selectedTags.length : 0}
              onClearAll={this.onClearAll}
            />
          }
        />
      );
    }
  },
);
