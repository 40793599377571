// @flow
import React, {PureComponent} from 'react';
import {fetchSchemas as fetchSchemasAction, setExpandedCube} from 'bc.cubes/store/actions';
import {connect} from 'react-redux';
import Loader from 'common/components/Loader';
import * as selectors from '../store/selectors';
import CubeListItem from './CubeListItem';
import * as bcSelectors from '../../bc/store/selectors';
import {fetchDataStreams as fetchDataStreamsAction} from '../../bc/store/actions';
import './CubesList.module.scss';

type PropTypes = {
  /* connect */
  fetchSchemas: Function,
  isSchemasLoading: boolean,
  schemasItems: Array,
  fetchDataStreams: Function,
  streamsUpdateAt: number,
};

export default connect(
  (state) => ({
    isSchemasLoading: selectors.getIsSchemasLoading(state),
    schemasItems: selectors.getSchemaItemsWithCubes(state),
    expandedCube: selectors.getExpandedCube(state),
    streamsUpdateAt: bcSelectors.getDataStreams(state).streams.updateAt,
  }),
  {
    fetchSchemas: fetchSchemasAction,
    setExpandedCube,
    fetchDataStreams: fetchDataStreamsAction,
  },
)(
  class CubesList extends PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {fetchSchemas, fetchDataStreams, streamsUpdateAt} = this.props;
      fetchSchemas();
      if (!streamsUpdateAt) {
        fetchDataStreams();
      }
    }

    render() {
      const {isSchemasLoading, schemasItems} = this.props;

      if (isSchemasLoading) {
        return <Loader styleName="loader-pos" size="small" />;
      }
      return (
        <div styleName="root">
          {schemasItems.map((item) => (
            <CubeListItem schemaItem={item} key={item.streamSchemaWrapper.schema.id} />
          ))}
        </div>
      );
    }
  },
);
