import {createSelector} from 'reselect';

export const getRoot = (state) => state.insightsPanel;

export const getView = createSelector(
  getRoot,
  (i) => i.view,
);
export const getPanel = createSelector(
  getView,
  (i) => i.panel,
);
export const getIsPanelOpen = createSelector(
  getPanel,
  (i) => i.isPanelOpen,
);

export const getNumLastDays = createSelector(
  getPanel,
  (i) => i.numLastDays,
);
