// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {getUsersData, fetchGroupsData} from 'admin.users/store/selectors';
import {getDataMangerQueryParamsSearchQuery} from 'bc/store/selectors';
import {getIsRbacEnabled, getAccessListAllSourcesData} from 'admin.permissions/store/selectors';
import {setSelectedDataStream, previewEventStream} from 'bc/store/actions';
import {getStreamChip, getOwnerName, isViewStreamAllowed} from 'bc/services/dataManagerService';
import {isApi30schema} from 'bc/services/dataStreamService';
import {
  getAccessGroups,
  accessGroupsShortedNames,
  accessGroupsNames,
} from 'admin.permissions/services/accessListService';
import Tooltip from 'common/componentsV2/Tooltip';
import Highlighter from 'react-highlight-words';

import './DataManagerStreamItem.module.scss';

type PropTypes = {
  stream: Object,
  openStreamViewModal: Function,

  // connect
  isRbacEnabled: Boolean,
  users: Array,
  searchQuery: String,
  setSelectedDataStream: Function,
  accessListAllSourcesData: Array,
  groupsData: Array,
  previewEventStream: Function,
};

export default connect(
  (state) => ({
    isRbacEnabled: getIsRbacEnabled(state),
    users: getUsersData(state),
    searchQuery: getDataMangerQueryParamsSearchQuery(state),
    accessListAllSourcesData: getAccessListAllSourcesData(state),
    groupsData: fetchGroupsData(state),
  }),
  {
    setSelectedDataStream,
    previewEventStream,
  },
)(
  class DataManagerStreamItemData extends PureComponent {
    props: PropTypes;

    openStreamViewModalClick = () => {
      this.props.setSelectedDataStream(this.props.stream.id);
      this.props.openStreamViewModal();
    };

    doNothing = () => {};

    render() {
      const {isRbacEnabled, stream, users, searchQuery, accessListAllSourcesData, groupsData} = this.props;
      const streamChip = getStreamChip(stream);
      const owner = getOwnerName(stream.owner || stream.userId, users);
      const onClickFunction = isViewStreamAllowed(stream) ? this.openStreamViewModalClick : this.doNothing;
      const accessGroups = isRbacEnabled ? getAccessGroups(accessListAllSourcesData[stream.id], groupsData) : null;
      const hideChip = isApi30schema(stream.family);

      return (
        <div
          styleName={[
            'row',
            streamChip.style === 'paused' ? 'paused' : '',
            isViewStreamAllowed(stream) ? ' linked' : '',
          ].join(' ')}
          onClick={stream.isEventStream ? () => this.props.previewEventStream({streamId: stream.id}) : onClickFunction}
          automation-id={`Stream_item_${stream.name}`}
        >
          {stream.isEventStream ? (
            <span styleName="event-stream-icon">
              <i className="icon icn-eventcalendar16" />
            </span>
          ) : null}
          <div styleName="col-title" automation-id={`Stream_name_${stream.name}`}>
            <Highlighter autoEscape searchWords={[searchQuery]} textToHighlight={stream.name} />
          </div>

          {!hideChip && (
            <div styleName="col-status">
              {streamChip.tooltip ? (
                <Tooltip content={streamChip.tooltip} delay={300}>
                  <span styleName={`stream-chip ${streamChip.style}`} automation-id={`Stream_Status_${stream.name}`}>
                    {streamChip.text}
                  </span>
                </Tooltip>
              ) : (
                <span styleName={`stream-chip ${streamChip.style}`} automation-id={`Stream_Status_${stream.name}`}>
                  {streamChip.text}
                </span>
              )}
            </div>
          )}
          {isRbacEnabled && (
            <div styleName="col-owner">
              {' '}
              <Tooltip content={accessGroupsNames(accessGroups)}>
                <span>{accessGroupsShortedNames(accessGroups)}</span>
              </Tooltip>
            </div>
          )}
          <div styleName="col-owner">{owner}</div>
        </div>
      );
    }
  },
);
