// @flow
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Checkbox from 'common/componentsV2/Checkbox';
import Input from 'common/componentsV2/Input';
import TextArea from 'common/componentsV2/TextArea';
import {makeStyles} from '@material-ui/core/styles';
import CSSTransition from 'react-transition-group/CSSTransition';
import {getMeOwnerOrganization, getLeanUserProfile} from 'profile/store/selectors';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import {getBaseClusterURL} from 'common/utils/http';
import useUserSettingsStyles from 'userSettings/pages/useUserSettings.styles';
import {samlConfig} from '../../services/userSettingsService';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.orange[500],
  },
  link: {
    wordBreak: 'break-all',
  },
  btnDetails: {
    display: 'block',
    cursor: 'pointer',
    marginTop: 36,
  },
  subLabel: {
    fontWeight: 700,
    fontSize: '14px',
  },
  moreInfoRow: {
    marginTop: 32,
  },
}));

const isValidUrl = (val) =>
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/.test(val);

const SamlAuth = () => {
  const [isShowDetails, setShowDetails] = useState(false);
  const getUserOrg = useSelector((state) => getMeOwnerOrganization(state));
  const leanMe = useSelector((state) => getLeanUserProfile(state));
  const {
    input: {onChange: handleEnforce, checked},
  } = useField('enforceSaml', {type: 'checkbox'});
  const {
    input: {value: valueLogin, onChange: handleLogin},
    meta: {invalid, touched},
  } = useField('loginUrl', {validate: (val) => !val || !isValidUrl(val)});
  const {
    input: {value: valueCert, onChange: handleCert},
  } = useField('cert');

  const consumeUrl = `${getBaseClusterURL(leanMe)}/api/v1/signin/saml/consume/${getUserOrg}`;
  const loginUrl = `${getBaseClusterURL(leanMe)}/signin/?orgId=${getUserOrg}`;

  const classes = useStyles();
  const userSettingsClasses = useUserSettingsStyles();

  return (
    <>
      <div className={userSettingsClasses.root}>
        <div className={userSettingsClasses.topLine}>
          <Checkbox
            isChecked={checked}
            onChange={handleEnforce}
            text="For Admin users, enforce SAML single sign-on authentication."
          />
        </div>
        <div className={userSettingsClasses.followingLine}>
          <TypographyBox variant="body1" mb={0.5}>
            The Admin password is ignored.
          </TypographyBox>
          <TypographyBox variant="hintText">
            {/* eslint-disable-next-line max-len */}
            We recommend not to enforce SAML authentication before verifying that Authentication has been set up
            successfully.
          </TypographyBox>
        </div>
      </div>
      <div className={userSettingsClasses.section}>
        <div className={userSettingsClasses.container}>
          <div>
            <TypographyBox variant="body1" width={220}>
              Identity Provider Details:
            </TypographyBox>
          </div>
          <div>
            <div className={userSettingsClasses.subSection}>
              <div className={userSettingsClasses.longBottom}>
                <TypographyBox variant="body1">
                  Login URL: <span className={classes.label}>Required</span>
                </TypographyBox>
                <Input
                  type="text"
                  value={valueLogin}
                  onChange={handleLogin}
                  placeHolder="SAML HTTP Post Binding provided by your IDP"
                  isInvalid={touched && invalid}
                  fullSize
                />
              </div>
              <div className={userSettingsClasses.longBottom}>
                <TypographyBox variant="body1">509 Certificate:</TypographyBox>
                <TextArea minRows={4} maxRows={4} value={valueCert} onChange={handleCert} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={userSettingsClasses.section}>
        <div className={userSettingsClasses.container}>
          <div>
            <TypographyBox variant="body1" width={220}>
              Service Provider Details:
            </TypographyBox>
          </div>
          <div>
            <div className={userSettingsClasses.subSection}>
              <div className={userSettingsClasses.longBottom}>
                <TypographyBox variant="body1">Entity ID (issuer ID):</TypographyBox>
                <div className={userSettingsClasses.copyWrapper}>
                  <Input type="text" value={samlConfig.entity} fullSize isDisabled />
                  <div className={userSettingsClasses.copyBtn}>
                    <CopyToClipboardButton
                      copyStr={samlConfig.entity}
                      tooltipMessage="Copied to clipboard"
                      shouldUseIcon
                    />
                  </div>
                </div>
              </div>
              <div className={userSettingsClasses.longBottom}>
                <TypographyBox variant="body1">Assertion Consumer Service URL:</TypographyBox>
                <div className={userSettingsClasses.copyWrapper}>
                  <Input type="text" value={consumeUrl} fullSize isDisabled />
                  <div className={userSettingsClasses.copyBtn}>
                    <CopyToClipboardButton copyStr={consumeUrl} tooltipMessage="Copied to clipboard" shouldUseIcon />
                  </div>
                </div>
              </div>
              <TypographyBox variant="body1" className={classes.subLabel}>
                Login URL:{' '}
                <a href={loginUrl} className={classes.link}>
                  {loginUrl}
                </a>
              </TypographyBox>
              <TypographyBox variant="hintText">Use this URL for SSO or use the default sign-in form</TypographyBox>
              {isShowDetails && (
                <CSSTransition timeout={300} appear in>
                  <div>
                    <div className={classes.moreInfoRow}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        SAML Bindings: Anodot supports HTTP Post Binding for SAML 2.0:
                      </TypographyBox>
                      <TypographyBox variant="body1">{samlConfig.postBindings}</TypographyBox>
                    </div>
                    <div className={classes.moreInfoRow}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        Assertion subject (application username):
                      </TypographyBox>
                      <TypographyBox variant="body1">Email</TypographyBox>
                    </div>
                    <div className={classes.moreInfoRow}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        NameId Format (Email Address):
                      </TypographyBox>
                      <TypographyBox variant="body1">{samlConfig.nameIdFormat}</TypographyBox>
                    </div>
                    <div className={classes.moreInfoRow}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        Attribute Name Format (Uri):
                      </TypographyBox>
                      <TypographyBox variant="body1">urn:oasis:names:tc:SAML:2.0:attrname-format:uri</TypographyBox>
                    </div>
                    <div className={classes.moreInfoRow}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        Attribute Statement:
                      </TypographyBox>
                      <TypographyBox variant="body1">{samlConfig.attrNameFormat}</TypographyBox>
                    </div>
                    <div className={classes.moreInfoRow}>
                      <TypographyBox variant="body1" className={classes.subLabel}>
                        Encryption Algorithm:
                      </TypographyBox>
                      <TypographyBox variant="body1">sha256</TypographyBox>
                    </div>
                  </div>
                </CSSTransition>
              )}
              <a onClick={() => setShowDetails(!isShowDetails)} className={classes.btnDetails}>
                {isShowDetails ? 'Less details' : 'More details'}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SamlAuth;
