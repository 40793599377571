// @flow
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';

const root = 'api/v1/';
const getAlertTrigger = (payload) =>
  get(
    `${root}alerts/triggered?startTime=${payload.startTime}&alertConfigurationIds=${payload.alertConfigurationIds}&startBucketMode=${payload.startBucketMode}`,
  );

export default function useFetchAlertTrigger(payload) {
  const fallback = {};

  const {
    dataUpdatedAt,
    data: alertTrigger = fallback,
    isLoading: isAlertTriggerLoading,
    isFetching: isAlertTriggerFetching,
  } = useQuery(`triggersAlert${payload.alertConfigurationIds}`, () => getAlertTrigger(payload), {
    enabled: !!payload.alertConfigurationIds,
  });

  return {
    updatedAt: dataUpdatedAt,
    alertTrigger,
    isAlertTriggerLoading,
    isAlertTriggerFetching,
  };
}
