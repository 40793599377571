// @flow
import React, {memo, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import {deleteFeedback, setAlertFeedbackPost as setFeedback, updateFeedback} from 'feedback/services/api';
import {getProfileId, getTriageFullScreenEnabled} from 'profile/store/selectors';
import {FEEDBACK_ORIGIN, FEEDBACK_TYPE, feedbackReasons} from 'feedback/services/constants';
import Feedback from 'feedback/FeedbackNew';
import fetchGroupAlertsWithMetricsProvider from 'investigationNewAlertConsole/api/fetchGroupAlertsWithMetricsProvider';
import {FEEDBACK_ACTION_TYPES} from 'alerts.console.new/api/triggeredAlertsCacheUpdate';
import {queryErrorHandler} from 'reactQuery/queryClient';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import fetchTriggeredAlertsNoFeedbackProvider from 'insightsPanelNewAlertConsole/api/fetchTriggeredAlertsNoFeedbackProvider';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import fetchTimelineProvider from 'investigationNewAlertConsole/api/fetchTimelineProvider';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';

const css = makeStyles(() => ({
  feedback: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    border: '2px solid',
    borderColor: palette.gray['300'],
    height: '36px',
    marginLeft: '12px',
    paddingRight: '12px',
    paddingLeft: '12px',
  },
  triageFeedback: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const FeedbackInvestigationWrapper = ({queryParams}: {queryParams: Object}) => {
  const classes = css();
  const isTriageFullscreenEnabled = useSelector(getTriageFullScreenEnabled);
  const meId = useSelector(getProfileId);
  const users = fetchUsersProvider().useQuery();
  const meUser = users?.data?.find((user) => user.id === meId);

  const triggeredAlertGroupProvider = fetchGroupAlertsWithMetricsProvider(queryParams);
  const trigger = triggeredAlertGroupProvider?.useQueryGetTriggeredAlert()?.data;

  const triggeredAlertTimeline = fetchTimelineProvider(trigger?.alertTriggerId);

  const triggeredAlertsProvider = fetchTriggeredAlertsProvider();
  const triggeredAlertsNoFeedbackProvider = fetchTriggeredAlertsNoFeedbackProvider();

  const [currentFeedbackId, setCurrentFeedbackId] = useState();
  const [isFeedbackUpdating, setIsFeedbackUpdating] = useState(false);

  const isFeedbackByMe = (type) => {
    const feedbackByMe = trigger?.feedback?.find((f) => f.userId === meId);
    if (feedbackByMe) {
      return feedbackByMe.type === FEEDBACK_TYPE[type];
    }
    return false;
  };

  const isOthersFeedback = (type) =>
    trigger?.feedback?.filter((f) => f.type === FEEDBACK_TYPE[type] && f.userId !== meId);

  const othersGoodCatch = useMemo(() => {
    return isOthersFeedback(FEEDBACK_TYPE.GOOD_CATCH);
  }, [JSON.stringify(trigger?.feedback)]);

  const otherNotInteresting = useMemo(() => {
    return isOthersFeedback(FEEDBACK_TYPE.NOT_INTERESTING);
  }, [JSON.stringify(trigger?.feedback)]);

  const isGoodCatchByMe = useMemo(() => {
    return isFeedbackByMe(FEEDBACK_TYPE.GOOD_CATCH);
  }, [JSON.stringify(trigger?.feedback)]);

  const isNotInterestingByMe = useMemo(() => {
    return isFeedbackByMe(FEEDBACK_TYPE.NOT_INTERESTING);
  }, [JSON.stringify(trigger?.feedback)]);

  const setFeedbackParams = (type) => ({
    type,
    userId: meId,
    triggerIds: [trigger?.alertTriggerId],
    origin: FEEDBACK_ORIGIN.INVESTIGATION,
    userName: meUser.fullName || 'Unknown',
  });

  const setGoodCatchOrNotInteresting = async (feedbackType) => {
    const params = setFeedbackParams(feedbackType);
    triggeredAlertGroupProvider.syncFeedbackInCache(FEEDBACK_ACTION_TYPES.SET, params);
    try {
      const res = await setFeedback({payload: params}).promise;
      if (res?.id) {
        setCurrentFeedbackId(res.id);
        params.feedbackId = res.id;
        triggeredAlertGroupProvider.syncFeedbackInCache(FEEDBACK_ACTION_TYPES.SET, params);
      }
      // Need to invalidate the cache of triggered alerts in alerts console grid to reset the filtering ReactQuery cache.
      await triggeredAlertsProvider.invalidate(QUERY_KEYS.triggeredAlerts);
      await triggeredAlertsNoFeedbackProvider.reset(QUERY_KEYS.triggeredAlertsInsightPanelNoFeedback);
      await triggeredAlertTimeline?.invalidate();
    } catch (e) {
      const error = {
        title: 'Feedback error',
        description: `Error while setting ${feedbackType} from investigation modal`,
      };
      queryErrorHandler(error);
      // Need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
      await triggeredAlertGroupProvider.invalidate();
    }
  };

  const deleteGoodCatchOrNotInteresting = async (feedbackType) => {
    const feedback = trigger?.feedback?.find((f) => f.userId === meId);
    if (!feedback?.id) {
      return;
    }
    triggeredAlertGroupProvider.syncFeedbackInCache(FEEDBACK_ACTION_TYPES.DELETE, {
      userId: meId,
      feedbackId: feedback.id,
    });
    try {
      await deleteFeedback({payload: feedback.id}).promise;
      setCurrentFeedbackId();
      // Need to invalidate the cache of triggered alerts in alerts console grid to reset the filtering ReactQuery cache.
      await triggeredAlertsProvider.invalidate(QUERY_KEYS.triggeredAlerts);
      await triggeredAlertsNoFeedbackProvider.reset(QUERY_KEYS.triggeredAlertsInsightPanelNoFeedback);
      await triggeredAlertTimeline?.invalidate();
    } catch (e) {
      const error = {
        title: 'Feedback error',
        description: `Error while deleting ${feedbackType} from investigation modal`,
      };
      queryErrorHandler(error);
      // Need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
      await triggeredAlertGroupProvider.invalidate();
    }
  };

  const updateFeedbackComment = async (feedbackParams, feedbackType) => {
    const feedback = trigger?.feedback?.find((f) => f.userId === meId);
    if (!feedback) {
      return;
    }
    const comment = feedbackParams?.comment || '';
    const reason = feedbackParams?.reason || '';
    if (comment || reason) {
      setIsFeedbackUpdating(true);
    }
    const params = setFeedbackParams(feedbackType);
    params.feedbackId = feedback.id;

    triggeredAlertGroupProvider.syncFeedbackInCache(FEEDBACK_ACTION_TYPES.UPDATE, {
      ...params,
      type: feedbackType,
      comment,
      reason,
    });
    try {
      await updateFeedback({
        payload: {
          ...params,
          id: feedback.id,
          comment,
          reason: reason ? feedbackReasons(feedbackType, trigger?.type).find((r) => r.value === reason)?.title : '',
        },
      }).promise;
      // Need to invalidate the cache of triggered alerts in alerts console grid to reset the filtering ReactQuery cache.
      await triggeredAlertsProvider.invalidate(QUERY_KEYS.triggeredAlerts);
      await triggeredAlertTimeline?.invalidate();
    } catch (e) {
      const error = {
        title: 'Feedback error',
        description: `Error while updating ${feedbackType} feedback from investigation modal`,
      };
      queryErrorHandler(error);
      // Need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
      await triggeredAlertGroupProvider.invalidate();
    } finally {
      setIsFeedbackUpdating(false);
    }
  };

  return isFeedbackUpdating ? (
    <Spinner color={palette.gray[500]} size={SIZES.SMALL_30} />
  ) : (
    <div className={isTriageFullscreenEnabled ? classes.triageFeedback : classes.feedback}>
      <Feedback
        alertType={trigger?.type}
        isGoodCatchByMe={isGoodCatchByMe}
        isNotInterestingByMe={isNotInterestingByMe}
        othersGoodCatch={othersGoodCatch}
        otherNotInteresting={otherNotInteresting}
        onSetGoodCatch={() => setGoodCatchOrNotInteresting(FEEDBACK_TYPE.GOOD_CATCH)}
        onSetNotInteresting={() => setGoodCatchOrNotInteresting(FEEDBACK_TYPE.NOT_INTERESTING)}
        onRemoveGoodCatch={() => deleteGoodCatchOrNotInteresting(FEEDBACK_TYPE.GOOD_CATCH)}
        onRemoveNotInteresting={() => deleteGoodCatchOrNotInteresting(FEEDBACK_TYPE.NOT_INTERESTING)}
        onUpdateGoodCatch={(feedbackParams) => updateFeedbackComment(feedbackParams, FEEDBACK_TYPE.GOOD_CATCH)}
        onUpdateNotInteresting={(feedbackParams) =>
          updateFeedbackComment(feedbackParams, FEEDBACK_TYPE.NOT_INTERESTING)
        }
        size={isTriageFullscreenEnabled ? 'mid' : 'large'}
        tooltipPlacement="bottom"
        currentFeedbackId={currentFeedbackId}
      />
    </div>
  );
};

export default memo(FeedbackInvestigationWrapper);
