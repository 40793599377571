export const salesforceLogin = (clientId, me, staging, createSalesforceChannel) => {
  const width = 500;
  const height = 600;
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;
  const state = me.ownerOrganization || 'no-org-id';
  const redirectRelUrl = '/api/v1/signin/oauth/salesforce-channel/callback';
  let redirectURI = `${window.location.origin}${redirectRelUrl}`;

  if (window.location.origin.indexOf('localhost:') === -1) {
    // TODO: (IB) This is for salesforce channel development period.
    //  Cannot test on localhost, since the localhost is 'http://' and thus cannot be a valid redirectUrl for salesforce
    if (window.location.origin.indexOf('feature-sfdc-channel') !== -1) {
      redirectURI = `https://feature-sfdc-channel.ano-dev.com${redirectRelUrl}`;
    } else {
      const baseDnsName = me.baseDNSName === 'anodot-test.com' ? 'ano-dev.com' : me.baseDNSName;
      redirectURI = `https://${me.clusterDNSPrefix}.${baseDnsName}${redirectRelUrl}`;
    }
  }

  const url = `https://${
    staging ? 'test' : 'login'
  }.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectURI}&display=popup&state=${state}`;
  const title = 'login';

  const openedWindow = window.open(
    url,
    title,
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`,
  );

  const pollTimer = window.setInterval(function() {
    if (openedWindow.closed !== false) {
      window.clearInterval(pollTimer);
      createSalesforceChannel({error: 'Salesforce authentication failed'});
    }
  }, 200);

  window.createChannel = function(code) {
    window.clearInterval(pollTimer);
    createSalesforceChannel({code});
  };
};

export const salesforceLoginAsync = async (clientId, meProfile, isSandboxChecked) => {
  return new Promise((resolve) => {
    salesforceLogin(clientId, meProfile, isSandboxChecked, (code) => resolve(code));
  });
};

export default salesforceLogin;
