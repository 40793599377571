import {makeStyles} from '@material-ui/core/styles';

const useUserSettingsStyles = makeStyles((theme) => ({
  root: {
    width: 480,
    marginBottom: 20,
  },
  topLine: {
    marginBottom: 8,
    marginTop: 20,
  },
  followingLine: {
    marginTop: 4,
  },
  detailsSection: {
    display: 'flex',
    marginBottom: 56,
  },
  container: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
  },
  section: {
    padding: '20px 0',
    borderTop: `1px solid ${theme.palette.gray[200]}`,
  },
  subSection: {
    width: 400,
  },
  fullWidth: {
    width: 800,
  },
  longBottom: {
    marginBottom: 28,
  },
  detailsField: {
    marginRight: 12,
  },
  title: {
    marginBottom: 8,
  },
  copyWrapper: {
    position: 'relative',
  },
  copyBtn: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: -25,
    cursor: 'pointer',

    '& .inline': {
      position: 'relative',
      top: 0,
      right: 0,
    },
  },
}));

export default useUserSettingsStyles;
