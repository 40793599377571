// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
// eslint-disable-next-line import/no-cycle
import * as selectors from 'bc/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import {updateDataSource, createDataSource, resetAddSourceError} from 'bc/store/actions';
import {salesforceLogin} from 'bc/services/salesforceService';
// eslint-disable-next-line import/no-cycle
import DataSourceModal from '../../DataSourceModal';
import '../../DataSourceModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  isViewOnly: boolean,
  isEditable: boolean,
  sourceType: Object,
  source: Object,
  onClose: Function,

  // connect
  me: Object,
  isLoading: boolean,
  sourceError: Object,

  createDataSource: Function,
  resetAddSourceError: Function,
};

class SalesforceSourceModal extends React.PureComponent {
  props: PropTypes;

  // eslint-disable-next-line no-extra-boolean-cast
  state = !!this.props.source
    ? {
        useSandbox: this.props.source.useSandbox,
      }
    : {
        useSandbox: false,
      };

  valueChanged = (val, inputId) => {
    const newState = {};
    newState[inputId] = val;
    this.setState(newState);
  };

  onCloseInternal = () => {
    this.props.resetAddSourceError();
    this.props.onClose();
  };

  handleCreate = (args) => {
    this.props.createDataSource({
      ...args,
    });
  };

  createBtnClicked = () => {
    salesforceLogin(this.props.sourceType, this.handleCreate, this.props.me, this.state.useSandbox);
  };

  render() {
    const {isOpen, isLoading, sourceError, source, sourceType, isViewOnly, isEditable} = this.props;
    const {useSandbox} = this.state;

    return (
      <DataSourceModal
        isOpen={isOpen}
        isProcessing={isLoading}
        isViewOnly={isViewOnly}
        isRenameEnabled={isEditable}
        source={source}
        sourceType={sourceType}
        logoImageClass="source-logo-adobe"
        onClose={this.onCloseInternal}
        onContinue={this.createBtnClicked}
      >
        <div styleName="inputs-wrapper">
          {!isEditable && (
            <Fragment>
              <div className="cb2">
                <input
                  type="checkbox"
                  automation-id="dataCollectorSalesforceSandboxCheckbox"
                  name="useSandbox"
                  id="useSandbox"
                  checked={useSandbox}
                  readOnly={isViewOnly}
                  disabled={isViewOnly}
                  onChange={(e) => this.valueChanged(e.target.checked, 'useSandbox')}
                />
                <label htmlFor="useSandbox">Use Sandbox</label>
                <div styleName="hint">
                  <div>Check this box to login to salesforce sandbox at test.salesforce.com</div>
                  <div>Keep it unchecked to login to salesforce production at login.salesforce.com</div>
                </div>
              </div>
            </Fragment>
          )}

          {!isLoading && sourceError ? (
            <div styleName="error-wrapper">
              <span styleName="error-message">
                <i className="icon icn-warning-icon" /> {sourceError.uMessage}
              </span>
            </div>
          ) : null}
        </div>
      </DataSourceModal>
    );
  }
}

export default connect(
  (state) => ({
    me: profileSelectors.getUserProfile(state),
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource,
    updateDataSource,
    resetAddSourceError,
  },
)(SalesforceSourceModal);
