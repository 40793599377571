// @flow
import React from 'react';
import PropTypes from 'prop-types';
import './SchedulerEditorModal.module.scss';

const DelayMinutes = (props: PropTypes) => {
  return (
    <div>
      <div styleName="titleWrapper">Delay (Minutes):</div>
      <div styleName="slideWrapper">
        <div styleName="slideWrapper-text">
          <input
            type="number"
            styleName="textInput_4"
            onChange={(e) => props.onChange(e.target.value)}
            min="0"
            max={props.maxVal}
            value={props.delayMinutes}
          />
        </div>
        <div styleName="slideWrapper-slider" />
      </div>
    </div>
  );
};

DelayMinutes.propTypes = {
  delayMinutes: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  maxVal: PropTypes.number,
};

DelayMinutes.defaultProps = {
  maxVal: 90,
};

export default DelayMinutes;
