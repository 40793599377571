// @flow
import React from 'react';
import {getFormattedDateMini, getFormattedHumanDuration} from 'common/utils/dateService';
import StaticListItem from 'common/componentsV2/table/StaticListItem';
import {REF_VALUES} from 'alerts.console.new/services/alertsConsoleService';

import './AlertTriggersTable.module.scss';

const AlertTriggersTableRowNewAlertConsole = ({trigger, timeZoneName}: {trigger: Object, timeZoneName: String}) => {
  const handleClick = () => {
    const {alerts} = trigger;
    const alertTriggerId = alerts?.length > 0 ? alerts[0].alertTriggerId : null;
    if (alertTriggerId) {
      window.open(
        `/#!/r/alerts-console-new?ref=${REF_VALUES.ALERT_MANAGER}&triggerIds=${alertTriggerId}&constRange=m1`,
        '_blank',
      );
    }
  };

  return (
    <StaticListItem handleClick={handleClick}>
      <div styleName="title">{trigger?.alerts[0]?.title}</div>
      <div styleName="startTime">{getFormattedDateMini(trigger?.alerts[0]?.startTime, timeZoneName)}</div>
      <div styleName="updateTime">{getFormattedDateMini(trigger?.alerts[0]?.endTime, timeZoneName)}</div>
      <div styleName="duration">{getFormattedHumanDuration(trigger?.alerts[0]?.duration)}</div>
      <div styleName="score">{trigger?.alerts[0]?.score ? Math.round(trigger.alerts[0].score * 100) : '0'}</div>
      <div styleName="link" />
    </StaticListItem>
  );
};

export default AlertTriggersTableRowNewAlertConsole;
