// @flow
import React from 'react';
import AcknowledgeAction from 'alerts.console.new/components/alertsList/alertActions/AcknowledgeAction';
import MoreDdlActions from 'alerts.console.new/components/alertsList/alertActions/MoreDdlActions';
import SnoozeAction from 'alerts.console.new/components/alertsList/alertActions/SnoozeAction';
import {ALERT_TYPES} from 'alerts.console.new/services/alertsConsoleService';

import './alertContent.module.scss';

const ROW_DEFAULT_HEIGHT = 46;

const AlertActions = ({
  trigger,
  alertGroupId,
  isDisplay,
  queryParams,
  resolutions,
}: {
  trigger: Object,
  alertGroupId: Number,
  isDisplay: Boolean,
  queryParams: Object,
  resolutions: Array,
}) => {
  return isDisplay ? (
    <div styleName="actions-container" style={{height: ROW_DEFAULT_HEIGHT}}>
      <div styleName="snooze" automation-id="alertConsoleSnoozeButton">
        <SnoozeAction trigger={trigger} queryParams={queryParams} alertGroupId={alertGroupId} />
      </div>

      <div styleName="acknowledge">
        <AcknowledgeAction
          acknowledgedBy={trigger.acks}
          alertGroupId={alertGroupId}
          alertTriggerId={trigger.alertTriggerId}
          automation-id="alertConsoleStarButton"
          queryParams={queryParams}
        />
      </div>

      <div styleName="more-actions">
        <MoreDdlActions
          id={trigger.alertTriggerId}
          automation-id="alertConsoleMoreActionButton"
          triggerId={trigger.alertTriggerId}
          alertConfigurationId={trigger.alertConfigurationId}
          anomalyId={trigger.type === ALERT_TYPES.ANOMALY ? alertGroupId : undefined}
          isImpactEligible={trigger.impactEligible}
          impact={trigger.impact}
          timeScale={Object.values(resolutions).find((a) => a.value2 === trigger.timeScale).value}
        />
      </div>
    </div>
  ) : null;
};

export default AlertActions;
