// @flow
import React from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import FilterButton from 'common/componentsV2/FilterButton';
import {getMeAppSettings} from 'profile/store/selectors';
import {getDynamicRoutingAndLookupData, getFiltersButtonTooltipItems} from 'assets/store/selectors';
import {addDynamicRoutingFile, setAssetsMngFiltersIsOpen} from 'assets/store/actions';
import {setFileUploadName, setFileUploadProgress, setUploadedFileUrl, setUploadFileError} from 'bc/store/actions';
import {palette} from 'app/styles/theme';
import AddNewFile from './AddNewFile';

const classes = {
  root: {
    display: 'flex',
    flex: '1 0 0',
    justifyContent: 'space-between',
  },
  assetsLength: {
    fontSize: 20,
    color: palette.gray[600],
    fontWeight: 400,
    margin: 0,
  },

  leftSide: {
    display: 'flex',
  },

  rightSide: {
    display: 'flex',
  },

  titlePos: {
    marginRight: 22,
  },
};

type PropTypes = {
  // connect
  appSettings: Boolean,
  data: Array,
  filtersButtonTooltipItems: Array<Object>,
  setAssetsMngFiltersIsOpen: Function,
};

export default connect(
  (state) => ({
    appSettings: getMeAppSettings(state),
    data: getDynamicRoutingAndLookupData(state),
    filtersButtonTooltipItems: getFiltersButtonTooltipItems(state),
  }),
  {
    setFileUploadProgress,
    setFileUploadName,
    setUploadedFileUrl,
    setUploadFileError,
    addDynamicRoutingFile,
    setAssetsMngFiltersIsOpen,
  },
)(
  class Header extends React.PureComponent {
    props: PropTypes;

    onFilterButtonClick = () => {
      const isOpen = get(this.props.appSettings, 'assets.isFiltersBarOpen', false);
      this.props.setAssetsMngFiltersIsOpen(!isOpen);
    };

    render() {
      return (
        <div style={classes.root}>
          <div style={classes.leftSide}>
            <span style={classes.titlePos}>
              <Title type={TITLE_TYPES.PAGE_TITLE}>
                Asset Management{' '}
                {this.props.data.length && <span style={classes.assetsLength}>({this.props.data.length})</span>}
              </Title>
            </span>
            <FilterButton tooltipItems={this.props.filtersButtonTooltipItems} onClick={this.onFilterButtonClick} />
          </div>

          <div style={classes.rightSide}>
            <AddNewFile />
          </div>
        </div>
      );
    }
  },
);
