// @flow
import React from 'react';
import {connect} from 'react-redux';
import {fetchUsers} from 'admin.users/store/actions';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import * as selectors from 'bc/store/selectors';
import {getUsersIsLoading} from 'admin.users/store/selectors';
import {getIsStreamOwnerEnabled, getDvpConfigEnabled} from 'profile/store/selectors';
import {getIsRbacEnabled} from 'admin.permissions/store/selectors';
import Input from 'common/componentsV2/Input';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import AccessListSection from 'bc/components/streams/editor/AccessListSection';
import MissingDataSection from 'bc/components/streams/editor/missingDataSection/MissingDataSection';

import './StreamProperties.module.scss';

type PropTypes = {
  stream: Object,
  dataStreams: Array,
  ownersList: Array,
  isLoading: boolean,
  isStreamOwner: boolean,
  setSelectedStreamKeyVal: Function,
  fetchUsers: Function,
  isRbacEnabled: Boolean,
  isDvpConfigEnabled: Boolean,
};

export default connect(
  (state) => ({
    stream: selectors.getSelectedDataStream(state),
    dataStreams: selectors.getDataStreamsItems(state),
    ownersList: selectors.getStreamPossibleOwnersList(state),
    isLoading: getUsersIsLoading(state),
    isStreamOwner: getIsStreamOwnerEnabled(state),
    isRbacEnabled: getIsRbacEnabled(state),
    isDvpConfigEnabled: getDvpConfigEnabled(state),
  }),
  {
    setSelectedStreamKeyVal,
    fetchUsers,
  },
)(
  class StreamProperties extends React.PureComponent {
    props: PropTypes;

    state = {
      name: this.props.stream.name,
      errorMsg: '',
      isVisited: false,
    };

    componentDidMount() {
      const msg = this.isStreamNameValid(this.props.stream.name);
      this.setState({
        errorMsg: msg,
      });

      if (!this.props.ownersList.length) {
        this.props.fetchUsers();
      }
    }

    isStreamNameValid = (val) => {
      const {stream, dataStreams} = this.props;
      let msg = '';
      if (!val || val.trim().length === 0) {
        msg = 'Stream name is empty, fill in the stream name to continue.';
      } else if (dataStreams.length) {
        if (stream.uiState && stream.uiState.id) {
          if (dataStreams.some((i) => i.name === val.trim() && i.id !== 'EditRunning' && i.id !== stream.uiState.id)) {
            msg = 'A stream with that name already exists, fill in a unique name to continue.';
          }
        } else if (dataStreams.some((i) => i.name === val.trim() && i.id !== stream.id)) {
          msg = 'A stream with that name already exists, fill in a unique name to continue.';
        }
      }
      return msg;
    };

    onBlur = () => {
      this.setState((prevState) => {
        const curName = prevState.name.trim();
        if (curName !== this.props.stream.name) {
          this.props.setSelectedStreamKeyVal({name: curName});
        }
        return {
          isVisited: true,
        };
      });
    };

    onChange = (e) => {
      const val = e.target.value.replace("'", '');
      const msg = this.isStreamNameValid(val);
      if (this.state.errorMsg !== msg || !this.state.isVisited) {
        this.props.setSelectedStreamKeyVal({name: val.trim()});
      }
      this.setState({
        errorMsg: msg,
        name: val,
        isVisited: true,
      });
    };

    onOwnerSelect = (val) => {
      this.props.setSelectedStreamKeyVal({owner: val.id});
    };

    render() {
      const {name, errorMsg, isVisited} = this.state;
      const {stream, ownersList, isLoading, isStreamOwner} = this.props;
      const isRunning = isStreamRunning(stream.state);
      const isTitleEditable = isRunning || isStreamEditable(stream.state);

      const currentOwnerIndex = stream.owner ? ownersList.findIndex((option) => option.id === stream.owner) : -1;

      if (!isStreamOwner) {
        return null;
      }

      return (
        <div styleName="root">
          <PanelHeader title="Stream properties" isEditable />

          <div styleName="label first-label">Stream Name:</div>
          <Input
            placeHolder="Set a name for the stream"
            isInvalid={isVisited && !!errorMsg}
            invalidMessage={isVisited ? errorMsg : ''}
            isDisabled={!isTitleEditable}
            value={name}
            onChange={this.onChange}
            onBlur={this.onBlur}
            wrapInvalidMessage
            automationId="streamNameTextbox"
          />

          <div styleName="label" style={{marginTop: 16}}>
            Stream Owner:
          </div>
          <SelectAndt
            type={TYPE_NEW_SEARCH}
            theme={THEME_NOT_HIGHLIGHTED}
            onChange={this.onOwnerSelect}
            options={ownersList}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.id}
            buttonWidth={225}
            optionHeight={40}
            disabled={isLoading}
            value={isLoading || currentOwnerIndex === -1 ? '' : ownersList[currentOwnerIndex]}
            placeholder={isLoading ? 'Loading...' : 'Not Set'}
            automationId="setsStreamOwner"
          />
          {this.props.isRbacEnabled && (
            <AccessListSection
              isStremRunning={isRunning}
              streamId={stream.id === 'EditRunning' ? stream.uiState.id : stream.id}
            />
          )}
          {this.props.isDvpConfigEnabled && <MissingDataSection />}
        </div>
      );
    }
  },
);
