// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedEditor} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SchedularData from 'bc/components/streams/editor/common/SchedularData';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import '../../sql/sqlSchedular/SqlSchedular.module.scss';

type PropTypes = {
  // connect
  dataStream: Object,
  setSelectedEditor: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedEditor,
  },
)(
  class KinesisSchedular extends React.PureComponent {
    props: PropTypes;

    openEditor = () => {
      this.props.setSelectedEditor({
        type: 'scheduler_kinesis',
        editorHeight: 'calc(100% - 210px)',
        maxHeight: '300px',
      });
    };

    render() {
      const {
        dataStream: {state},
      } = this.props;
      const isEditable = isStreamEditable(state) || isStreamRunning(state);

      return (
        <div className="shell-col" automation-id="bigQueryScheduler" styleName={!isEditable ? 'disabled' : ''}>
          <PanelHeader title="Query Schedule" onSelect={this.openEditor} isEditable={isEditable} />
          <SchedularData />
        </div>
      );
    }
  },
);
