// @flow
import React from 'react';
import {connect} from 'react-redux';
import ReactQueryParams from 'common/components/ReactQueryParams';
import {isEmpty, isEqual, pick} from 'lodash';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import {
  DEFAULT_QUERY_PARAMS,
  ALERT_CONSOLE_QUERY_PARAMS_KEYS,
  DEFAULT_QUERY_PARAMS_FILTERS,
} from 'alerts.console.new/services/alertsConsoleService';
import {
  updateQueryParams as updateQueryParamsAction,
  updateFilterBtn as updateFilterBtnAction,
  resetAlertsConsole,
  setQueryParamsToView,
  setAlertsFiltersNewAlertConsole as setAlertsFiltersAction,
  toggleInvestigationModal,
} from 'alerts.console.new/store/actions';
import * as selectors from 'alerts.console.new/store/selectors';
import * as profileSelector from 'profile/store/selectors';
import InsightsPanel from 'insightsPanelNewAlertConsole/InsightsPanel';
import AlertsConsoleFilters from 'alerts.console.new/components/filters/AlertsConsoleFilters';
import AlertsList from 'alerts.console.new/components/alertsList/AlertsList';
import AlertsConsoleHeader from 'alerts.console.new/components/header/AlertsConsoleHeader';
import PageLayout from 'common/componentsV2/PageLayout';
import './AlertsConsole.module.scss';
import AlertConsoleQueryParamsContext from '../context';
import {modalRouting} from '../../investigationNewAlertConsole/services/investigationService';

let isFirstAlertConsoleLifeEver = true;

let prevLifeSearch = null; // store the search query of last visit to alerts-console
let prevSession = null;

type PropTypes = {
  location: Object,
  history: Object,
  // connect
  timeZoneName: String,
  dataQueryParams: Object,
  dataQueryParamsNewAlertConsole: Object,
  updateQueryParams: Function,
  isReadOnlyUser: Boolean,
  setQueryParamsToView: Function,
  setAlertsFiltersNewAlertConsole: Function,
  investigationModal: Object,
  toggleInvestigationModal: Function,
  isTriageFullScreenEnabled: Boolean,
};

export default connect(
  (state) => ({
    timeZoneName: profileSelector.getTimeZoneName(state),
    dataQueryParams: selectors.getQueryParamsData(state),
    dataQueryParamsNewAlertConsole: selectors.getQueryParamsDataNewAlertConsole(state),
    isReadOnlyUser: profileSelector.isReadOnlyUser(state),
    investigationModal: selectors.getAlertConsoleInvestigationModal(state),
    isTriageFullScreenEnabled: profileSelector.getTriageFullScreenEnabled(state),
  }),
  {
    resetAlertsConsole,
    updateQueryParams: updateQueryParamsAction,
    updateFilterBtn: updateFilterBtnAction,
    setQueryParamsToView,
    setAlertsFiltersNewAlertConsole: setAlertsFiltersAction,
    toggleInvestigationModal,
  },
)(
  class AlertsConsole extends ReactQueryParams {
    props: PropTypes;

    defaultQueryParams = {...DEFAULT_QUERY_PARAMS};

    defaultQueryParamsFilters = {...DEFAULT_QUERY_PARAMS_FILTERS};

    setStateQueryParams = (newQueryParams) => {
      const mergedQueryParams = {...this.queryParams, ...newQueryParams};
      const newMergedQueryParams = {...mergedQueryParams};

      this.setState({queryParams: newMergedQueryParams});
    };

    state = {
      queryParams: {},
      refreshInterval: undefined,
    };

    makeObjectParams = (stringParams) => {
      const urlParams = new URLSearchParams(stringParams);
      return Object.fromEntries(urlParams);
    };

    componentDidMount() {
      const dataQueryParamsCalculated =
        window.location.href.indexOf('alerts-console-new') >= 0
          ? this.props.dataQueryParamsNewAlertConsole
          : this.props.dataQueryParams;

      if (!isEmpty(dataQueryParamsCalculated) && !this.isFilteredFromEmail() && !this.props.location.search) {
        this.setQueryParams(dataQueryParamsCalculated);
      }

      if (
        !isEmpty(dataQueryParamsCalculated) &&
        isEqual(this.queryParams, this.defaultQueryParams) &&
        !this.props.location.search
      ) {
        this.setQueryParamsWrapper(dataQueryParamsCalculated);
      } else {
        /* Update the store together with the search from the previous lifecycle */
        this.props.setAlertsFiltersNewAlertConsole(this.queryParams);
      }

      this.getSearchQuery(this.props);
      // call insightPanel
    }

    componentWillUnmount() {
      if (this.props.isTriageFullScreenEnabled) {
        // This is needed, since there when going from Alert Console to Triage and Back, the prevLififeSearxh params shouldn't contain the Triage params.
        const searchParams = new URLSearchParams(this.props.location.search);
        searchParams.delete(modalRouting.INVESTIGATION_MODAL);
        searchParams.delete(modalRouting.TRIGGER_ID);
        searchParams.delete(modalRouting.ANOMALY_ID);
        prevLifeSearch = `?${searchParams.toString()}`;
      } else {
        prevLifeSearch = this.props.location.search;
      }
      prevSession = localStorage.getItem('andt-token');
      // end insightPanel
    }

    componentDidUpdate(prevProps) {
      if (super.componentDidUpdate) {
        super.componentDidUpdate();
      }
      const prevParamsObj = this.makeObjectParams(prevProps.location.search);
      const paramsObj = this.makeObjectParams(this.props.location.search);

      const prevParams = pick(prevParamsObj, Object.keys(DEFAULT_QUERY_PARAMS));
      const currentParams = pick(paramsObj, Object.keys(DEFAULT_QUERY_PARAMS));

      if (!isEqual(prevParams, currentParams)) {
        this.props.setAlertsFiltersNewAlertConsole(this.queryParams);
      }
    }

    getSearchQuery = (props) => {
      const dataQueryParamsCalculated =
        window.location.href.indexOf('alerts-console-new') >= 0
          ? this.props.dataQueryParamsNewAlertConsole
          : this.props.dataQueryParams;

      // both assignments are needed otherwise react will update the component twice
      if (isFirstAlertConsoleLifeEver) {
        isFirstAlertConsoleLifeEver = false;
        if (!props.location.search && isEmpty(dataQueryParamsCalculated)) {
          // props.location.search = `?subscribers=${props.me._id}`;
          this.history.replace(this.history.location.pathname + props.location.search);
        }
      } else if (
        prevLifeSearch &&
        props.location.search !== prevLifeSearch &&
        localStorage.getItem('andt-token') === prevSession
      ) {
        /* eslint-disable */
        props.location.search = prevLifeSearch;
        /* eslint-enable */
        this.history.replace(this.history.location.pathname + prevLifeSearch);
      }
    };

    setQueryParamsWrapper = (queryObj) => {
      this.setQueryParams(queryObj, true);

      let needsUpdate = false;

      Object.entries(queryObj).forEach((e) => {
        needsUpdate = needsUpdate || this.queryParams[e[0]] !== e[1];
      });

      if (needsUpdate) {
        this.setQueryParams(queryObj, true);
      }

      this.setStateQueryParams(this.queryParams);
    };

    isFilteredFromEmail() {
      return (
        this.queryParams &&
        this.queryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.REF] === 'email' &&
        (this.queryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.TRIGGER_IDS] ||
          this.queryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.ALERT_CONFIGURATION_IDS])
      );
    }

    closeInvestigationModal = () => {
      this.props.toggleInvestigationModal({isOpen: false, anomalyId: '', triggerId: ''});
    };

    render() {
      return (
        <AlertConsoleQueryParamsContext.Provider
          value={{
            contextQueryParams: this.state.queryParams,
            setContextQueryParams: this.setStateQueryParams,
            contextRefreshInterval: this.state.refreshInterval,
            setContextRefreshInterval: (value) => {
              this.setState({refreshInterval: value});
            },
          }}
        >
          <>
            <InsightsPanel setQueryParams={this.setQueryParamsWrapper} />
            <div styleName="page-container">
              <PageLayout header={<AlertsConsoleHeader />}>
                <div styleName="page-content-wrapper">
                  <AlertsConsoleFilters
                    defaultQueryParams={this.defaultQueryParamsFilters}
                    isFilteredFromEmail={this.isFilteredFromEmail()}
                  />
                  <AlertsList setQueryParams={this.setQueryParamsWrapper} queryParams={this.state.queryParams} />
                </div>
              </PageLayout>
            </div>
          </>
        </AlertConsoleQueryParamsContext.Provider>
      );
    }
  },
);
