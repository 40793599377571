// @flow
import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import 'moment-timezone';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {getOrganizationTimeZone} from 'profile/store/selectors';
import WarningSign from 'common/componentsV2/WarningSign';

import './SchedulerEditorModal.module.scss';

type PropTypes = {
  warning: String,
  timeZone: String,
  onChange: Function,
  profileTimeZone: string,
};

export default connect(
  (state) => ({
    profileTimeZone: getOrganizationTimeZone(state),
  }),
  {},
)(
  class TimeZone extends React.PureComponent {
    props: PropTypes;

    render() {
      const {onChange, warning, timeZone, profileTimeZone} = this.props;

      const timeZones = moment.tz.names().map((item) => ({
        label: item,
      }));
      timeZones.unshift({label: 'Browser'});

      const selectedIndex = timeZones.findIndex((val) => val.label === timeZone);

      let userTimeZone = profileTimeZone;
      if (userTimeZone === 'Browser') {
        userTimeZone = moment.tz.guess();
      }

      return (
        <div>
          <div styleName="titleWrapper">
            Data Time Zone:
            {warning !== '' ? (
              <div styleName="info">
                <WarningSign notification={warning} warningLevel="alert-2-warning-red" />
              </div>
            ) : null}
          </div>
          <div>
            <SelectAndt
              id="schedulerEditorModalTimeZone"
              automationId="schedulerEditorModalTimeZone"
              options={timeZones}
              getOptionLabel={(val) => val.label}
              getOptionValue={(val) => val.label}
              type={TYPE_NEW_SEARCH}
              theme={THEME_NOT_HIGHLIGHTED}
              value={timeZones[selectedIndex]}
              onChange={(val) => onChange(val.label)}
              placeholder=""
              optionHeight={40}
              buttonWidth={180}
              menuWidth={310}
              maxMenuHeight={300}
              minMenuHeight={300}
              isClearable={false}
            />
          </div>
          <div styleName="textWrapper">This account is set to {userTimeZone}</div>
        </div>
      );
    }
  },
);
