import {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useQuery} from 'react-query';
import {getEnableEventsStreams} from 'profile/store/selectors';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/';

const getStreams = () => get(`${root}event-streams`);

export default function useFetchEventStreams() {
  const enableEventsStreams = useSelector(getEnableEventsStreams);
  const selectFn = useCallback((data) => {
    return data.map((s) => ({...s, isEventStream: true}));
  }, []);

  const fallback = [];
  const {
    dataUpdatedAt,
    data: eventStreams = fallback,
    isLoading: isEventStreamsLoading,
    isFetching: isEventStreamsFetching,
  } = useQuery(QUERY_KEYS.streams, getStreams, {
    select: selectFn,
    enabled: enableEventsStreams,
  });

  return {
    updatedAt: dataUpdatedAt,
    eventStreams,
    isEventStreamsLoading,
    isEventStreamsFetching,
  };
}

export function useFetchEventStream(id) {
  const selectFn = useCallback(
    (data) => {
      return data.find((i) => i.id === id);
    },
    [id],
  );

  const fallback = null;
  const {
    data: eventStream = fallback,
    isLoading: isEventStreamLoading,
    isFetching: isEventStreamFetching,
    isSuccess: isEventStreamSuccess,
  } = useQuery(QUERY_KEYS.streams, getStreams, {
    enabled: !!id,
    select: selectFn,
  });

  return {
    eventStream,
    isEventStreamLoading,
    isEventStreamFetching,
    isEventStreamSuccess,
  };
}
