import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {getUsersIsLoading} from 'admin.users/store/selectors';
import {getStreamPossibleOwnersList} from 'bc/store/selectors';
import {isCustomerAdmin} from 'profile/store/selectors';
import {updateAgentOwner} from 'bc/store/actions';
import {makeStyles} from '@material-ui/core/styles';
import Button, {COLORS, WIDTHS} from 'common/componentsV2/Button';

const useStyle = makeStyles(({typography}) => ({
  root: {
    position: 'relative',
    width: '750PX',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    ...typography.h2,
  },
  selectedWrapper: {
    width: 300,
    marginTop: 24,
    marginBottom: 18,
  },
  btnsWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  cancelBtn: {
    marginRight: 18,
  },
}));

const OwnerSection = (props: {dataStream: Object}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [isDirty, setIsDirty] = useState(false);
  const [owner, setOwner] = useState(props.dataStream?.owner);
  const isLoadingOwnersList = useSelector(getUsersIsLoading);
  const ownersList = useSelector(getStreamPossibleOwnersList);
  const isAdmin = useSelector(isCustomerAdmin);

  const onValChange = (val) => {
    setOwner(val);
    setIsDirty(true);
  };

  const update = () => {
    dispatch(
      updateAgentOwner({
        id: props.dataStream.id,
        owner,
      }),
    );
    setIsDirty(false);
  };

  const cancel = () => {
    setOwner(props.dataStream?.owner);
    setIsDirty(false);
  };

  const currentOwnerIndex = owner ? ownersList.findIndex((option) => option.id === owner) : -1;

  /* eslint-disable no-nested-ternary */
  return (
    <div className={classes.root}>
      <div>
        <span className={classes.title}>Owner</span>
      </div>

      <div className={classes.root}>
        <div className={classes.selectedWrapper}>
          <SelectAndt
            type={TYPE_NEW_SEARCH}
            theme={THEME_NOT_HIGHLIGHTED}
            onChange={(val) => onValChange(val.id)}
            options={ownersList}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.id}
            buttonWidth={225}
            optionHeight={40}
            disabled={isLoadingOwnersList || !isAdmin}
            value={isLoadingOwnersList || currentOwnerIndex === -1 ? '' : ownersList[currentOwnerIndex]}
            placeholder={isLoadingOwnersList ? 'Loading...' : currentOwnerIndex === -1 ? 'Not Set' : ''}
            automationId="setsStreamOwner"
          />
        </div>

        <div className={classes.btnsWrapper}>
          <Button
            fixedWidth={WIDTHS.REGULAR}
            colorSchema={COLORS.GRAY_300}
            text="Cancel"
            onClick={cancel}
            automationId="dataManagerExpendAll"
            extraClassName={classes.cancelBtn}
          />
          <Button
            isDisabled={!isDirty}
            fixedWidth={WIDTHS.REGULAR}
            colorSchema={COLORS.BLUE_500}
            text="Update"
            onClick={update}
            automationId="dataManagerExpendAll"
          />
        </div>
      </div>
    </div>
  );
};

OwnerSection.propTypes = {
  dataStream: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default OwnerSection;
