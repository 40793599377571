import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Backdrop, Modal} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import SettingsContainer from 'composites/components/modals/components/SettingsContainer';
import PreviewContainer from 'composites/components/modals/components/PreviewContainer';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import AsyncButton from 'common/componentsV2/AsyncButton';
import {useSelector} from 'react-redux';
import {getExpressionTree} from 'metrics/store/selectors';
import style from './CompositesModal.module.scss';

const CompositesModal = ({backUrl}) => {
  const history = useHistory();
  const onClose = useCallback(() => {
    history.push(backUrl);
  }, [backUrl]);
  const {location} = history;
  // adding uiData
  useSelector(getExpressionTree);
  const getTitle = useMemo(() => {
    switch (location.pathname) {
      case '/composites/new':
        return 'New Composite';
      default:
        return 'Composite Title';
    }
  }, [location]);
  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={onClose}>
      <div className={style.container}>
        <div className="mb_4">
          <Title type={TITLE_TYPES.PAGE_TITLE}>{getTitle}</Title>
        </div>
        <div className="display_flex flexGrow_1">
          <SettingsContainer />
          <PreviewContainer />
        </div>
        <div className={style.bottomPanel}>
          <div className="mr_1-5">
            <AsyncButton colorSchema="gray.300" onClick={onClose}>
              Cancel
            </AsyncButton>
          </div>
          <AsyncButton>Create Composite</AsyncButton>
        </div>
      </div>
    </Modal>
  );
};

CompositesModal.propTypes = {
  backUrl: PropTypes.string.isRequired,
};

export default CompositesModal;
