import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/insightsPanel/';
const getComposites = () => get(`${root}metrics-usage`);

export default function useFetchMetricsUsage(onSuccess) {
  const fallback = {};
  const {
    dataUpdatedAt,
    data: metricsUsageData = fallback,
    isLoading: isMetricsUsageDataLoading,
    isFetching: isMetricsUsageDataFetching,
  } = useQuery(QUERY_KEYS.metricsUsage, getComposites, {
    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
  });

  return {
    updatedAt: dataUpdatedAt,
    metricsUsageData,
    isMetricsUsageDataLoading,
    isMetricsUsageDataFetching,
  };
}
