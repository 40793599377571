import React from 'react';
import {Form} from 'react-final-form';
import ExpressionTreesPreviewContainer from 'common/containers/ExpressionTreesPreviewContainer';
import * as dateRangeService from 'common/utils/dateRangeService';
import {resolutionTypes} from 'metrics/services/metricsService';

const defaultByTreeExp = {
  type: 'line',
  stacking: null,
  yAxis: {
    type: 'linear',
    lowerRange: '',
    higherRange: '',
    opposite: false,
    isStateDirty: false,
    minorTickInterval: 'auto',
  },
};

const PreviewContainer = () => {
  const initialValues = {
    byTreeExp: defaultByTreeExp,
    dateRange: dateRangeService.getDateValue(dateRangeService.rangeTypes.w1.value),
    timeScale: resolutionTypes.auto,
  };
  return (
    <div>
      <Form initialValues={initialValues} onSubmit={() => null}>
        {() => <ExpressionTreesPreviewContainer isNoSettings isModal />}
      </Form>
    </div>
  );
};

export default PreviewContainer;
