import React from 'react';
import {useFormContext} from 'react-hook-form';
import {makeStyles} from '@material-ui/core';

import PropTypes from 'prop-types';
import usePreviewClasses from 'dataCollectors/styles/classes.usePreviewClasses';
import useDataMapper from './hook/useDataMapper';

const useStyle = makeStyles(({typography, palette}) => ({
  type: {
    ...typography.subtitle3,
    padding: '0 14px',
    display: 'flex',
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: palette.blue['100'],
    borderRadius: 6,
    width: '50%',
    height: 38,
  },
  optional: {
    ...typography.subtitle1,
    color: palette.gray['350'],
    fontStyle: 'italic',
  },
  icon: {
    position: 'relative',
    top: 2,
    marginRight: 6,
  },
  eventType: {
    backgroundColor: palette.gray[100],
    padding: '8px 14px',
  },
  sourceColumn: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  arrowWrapper: {
    position: 'absolute',
    right: 24,
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
  },
}));

const DataMapperItemPreview = ({eventType, sourceColumn}) => {
  const classes = useStyle();
  const previewClasses = usePreviewClasses();
  const {getValues} = useFormContext();
  const {metadata} = useDataMapper(getValues());

  return (
    <>
      <div className={previewClasses.row}>
        <span className={classes.sourceColumn}>
          <span>{metadata && sourceColumn ? metadata.find((i) => i.value === sourceColumn)?.label : sourceColumn}</span>
          <i className={`${classes.arrowWrapper} icon icn-arrow16-arrowright`} />
        </span>

        <span className={classes.eventType}>{eventType}</span>
      </div>
    </>
  );
};

DataMapperItemPreview.propTypes = {
  eventType: PropTypes.string.isRequired,
  sourceColumn: PropTypes.string.isRequired,
};

export default DataMapperItemPreview;
