import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';

import {PREVIEW_TABS} from 'dataCollectors/services/dataCollectorsService';
import {useFormContext} from 'react-hook-form';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';

const useStyles = makeStyles(({typography, palette}) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'baseline',
  },
  name: {
    ...typography.hintText,
    color: palette.gray[400],
    marginRight: 76,
    paddingBottom: 16,
    textTransform: 'capitalize',
    cursor: 'pointer',
    marginBottom: 24,
  },

  active: {
    color: palette.blue[500],
    borderBottom: `solid 3px ${palette.blue[500]}`,
  },
}));
const {TYPE} = EVENT_STREAM_FIELDS;

function Tabs({onSetActiveTabId, activeTabId}) {
  const classes = useStyles();
  const {getValues} = useFormContext();
  const type = getValues(TYPE.id);

  return (
    <div className={classes.wrapper}>
      {Object.keys(PREVIEW_TABS).map(
        (key) =>
          (key !== 'settings' || type !== 'local_file') && (
            <div
              key={key}
              className={`${classes.name} ${activeTabId === PREVIEW_TABS[key].id ? classes.active : null}`}
              onClick={() => onSetActiveTabId(PREVIEW_TABS[key].id)}
            >
              {PREVIEW_TABS[key].label}
            </div>
          ),
      )}
    </div>
  );
}

Tabs.propTypes = {
  onSetActiveTabId: PropTypes.func.isRequired,
  activeTabId: PropTypes.string.isRequired,
};

export default Tabs;
