import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'admin.cs-portal/store/selectors';
import UsagePoint from './UsagePoint';

import './MetricsUsagePoints.module.scss';

type PropTypes = {
  metrics: Object,
  selectedCustomer: Object,
};

export default connect(
  (state) => ({
    selectedCustomer: selectors.getSelectedCustomer(state),
  }),
  {},
)(
  class MetricsUsagePoints extends React.PureComponent {
    props: PropTypes;

    render() {
      const {
        selectedCustomer,
        metrics: {isEpsBreached, isMetricsBreached, actualLimit, indexed, stream, composite, alert},
      } = this.props;

      return (
        <div styleName="container">
          <div>
            <UsagePoint label="Contract Limit" value={selectedCustomer.metrics} />
            <UsagePoint label="Actual Limit" value={actualLimit} />
            <UsagePoint label="Indexed Limit" value={indexed} />
            <UsagePoint label="Metrics Breach" value={isMetricsBreached} />
          </div>
          <div>
            <UsagePoint label="From Streams" value={stream} />
            <UsagePoint label="From Composites" value={composite} />
            <UsagePoint label="From Alert" value={alert} />
            <UsagePoint label="EPS Breach" value={isEpsBreached} />
          </div>
        </div>
      );
    }
  },
);
