import React from 'react';
import {makeStyles} from '@material-ui/core';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import UpdatePolicyCondition from './UpdatePolicyCondition';

const useStyles = makeStyles(({palette}) => ({
  option: {
    paddingBottom: 12,
    paddingTop: 8,
    borderBottom: `solid 1px ${palette.gray['200']}`,
  },
  optionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const NotificationOption = ({
  selected,
  value,
  label,
  isDisabled,
  onChange,
}: {
  selected: boolean,
  value: string,
  label: number,
  isDisabled: boolean,
  onChange: Function,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.option}>
      <div className={classes.optionWrapper}>
        {label}
        {selected && isDisabled ? (
          <i
            className="icon icn-general16-checkmark fontSize_16 color_blue-500"
            style={{width: 32, textAlign: 'center'}}
          />
        ) : (
          <ToggleSwitch
            name={`notification-option-${value}`}
            theme="blue"
            isChecked={selected}
            disabled={isDisabled}
            onToggle={() => {
              onChange(value, !selected);
            }}
          />
        )}
      </div>
      {value === 'updates' && selected && <UpdatePolicyCondition />}
    </div>
  );
};

export default NotificationOption;
