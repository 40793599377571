// @flow
import React from 'react';
import {makeStyles} from '@material-ui/core';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import tableColumns from 'channels/constants/tableColumns';
import {StringParam, useQueryParams} from 'use-query-params';
import Checkbox from 'common/componentsV2/Checkbox';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '8px',
    height: '23px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    '&.sep-down': {
      boxShadow: '0 3px 2px -2px rgba(0, 0, 0, 0.175)',
    },
  },
  header: {
    flexShrink: 0,
    overflow: 'hidden',
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.gray[400],
  },
  checkbox: {
    marginRight: 6,
    marginLeft: 14,
  },
  title: {
    flexGrow: 1,
  },
  access: {
    width: '170px',
  },
  alertsConnected: {
    width: '170px',
  },
  dateCreated: {
    width: '147px',
  },
  owner: {
    width: '170px',
  },
}));

const ChannelsListHeader = ({
  checkboxState,
  onCheckboxClicked,
  isRbacEnabled,
}: {
  checkboxState: String,
  onCheckboxClicked: Function,
  isRbacEnabled: Boolean,
}) => {
  const [{sort, order}, setQueryParams] = useQueryParams({
    sort: StringParam,
    order: StringParam,
  });

  const classes = useStyles();

  const handleTitleOrderChange = (columnName) => {
    let updatedDirection = 'desc';

    if (columnName === sort) {
      updatedDirection = order === 'desc' ? 'asc' : 'desc';
    }
    setQueryParams({sort: columnName, order: updatedDirection});
  };

  const filterTableColumns = tableColumns.filter((field) => field.id !== 2 || (field.id === 2 && isRbacEnabled));

  return (
    <div className={classes.container}>
      <div className={classes.checkbox}>
        <Checkbox threeStatesStatus={checkboxState} onChange={onCheckboxClicked} />
      </div>
      {filterTableColumns.map((field) => (
        <div key={field.id} className={`${classes.header} ${classes[field.name]}`}>
          <TitleHeaderTableOrder
            onChange={handleTitleOrderChange}
            title={field.title}
            selectedDirection={order}
            columnName={field.name}
            isSortable={field.isSortable}
            isActive={field.name === sort}
            automationId={`ChannelSortBy${field.title.replaceAll(' ', '')}`}
          />
        </div>
      ))}
    </div>
  );
};

export default ChannelsListHeader;
