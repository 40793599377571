// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import SmartTooltip from 'common/componentsV2/Tooltip';
import Highlighter from 'react-highlight-words';
import ImpactChip from 'impact/ImpactChip';
import {themesOptions} from 'impact/impactTheme';
import {getHolidayEnable, getEnableFeedbackNodataStatic} from 'profile/store/selectors';
import {ALERT_TYPES} from 'alerts.console.new/services/alertsConsoleService';
import HolidayAlertItemAction from 'common/componentsV2/holiday/HolidayAlertItemAction';
import FeedbackWrapper from 'feedback/FeedbackWrapper';
import AcknowledgeIndicator from '../alertActions/AcknowledgeIndicator';
import SnoozeIndicator from '../alertActions/SnoozeActionIndicator';
import AlertType from './AlertType';

import './alertContent.module.scss';

const AlertTitle = ({
  trigger,
  alertGroupId,
  searchWords,
  meId,
  onMouseOver,
  isRowOpen,
  queryParams,
}: {
  trigger: Object,
  alertGroupId: String,
  searchWords: string,
  meId: Object,
  onMouseOver: Boolean,
  isRowOpen: Boolean,
  queryParams: Object,
}) => {
  const isHolidayEnable = useSelector(getHolidayEnable);
  const enableFeedbackNodataStatic = useSelector(getEnableFeedbackNodataStatic);

  return (
    <div styleName="title">
      <div styleName="text main">
        <SmartTooltip placement="top" content={trigger.title} delay={1000}>
          <span className="ellipsis">
            <Highlighter
              autoEscape
              highlightStyle={{backgroundColor: '#fcd176'}}
              searchWords={[searchWords]}
              textToHighlight={trigger.title}
            />
          </span>
        </SmartTooltip>
        {trigger.type !== ALERT_TYPES.ANOMALY && <AlertType type={trigger.type} />}
        {trigger.impact && (
          <ImpactChip impact={trigger.impact} themeName={themesOptions.tiny} tooltipContent="Impact for this Alert" />
        )}
      </div>

      <div styleName="indicators">
        {(trigger?.numSnoozedMetrics > 0 || trigger?.numStopLearningMetrics > 0) && (
          <SnoozeIndicator trigger={trigger} />
        )}
        {trigger?.acks && <AcknowledgeIndicator acknowledgedBy={trigger.acks} queryParams={queryParams} />}
      </div>

      {trigger.type === ALERT_TYPES.ANOMALY || enableFeedbackNodataStatic ? (
        <div styleName="feedback-container">
          {isHolidayEnable && onMouseOver ? <HolidayAlertItemAction /> : <div />}
          <div styleName="feedback">
            <FeedbackWrapper
              trigger={trigger}
              alertGroupId={alertGroupId}
              isRowOpen={isRowOpen}
              meId={meId}
              queryParams={queryParams}
              onMouseOver={onMouseOver}
            />
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default AlertTitle;
