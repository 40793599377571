// @flow
import React from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {Table, Column} from 'fixed-data-table-2';
import {setStreamPreviewColumnWidth} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/throttleTime';
import {isStreamEditable, isEditRunning} from 'bc/services/dataStreamService';
import DataCell from './tableCells/DataCell';
import CellHeader from './tableCells/CellHeader';
import {VertCellHeader, VertCell} from './tableCells/VertCellHeader';
import StreamTableTop from './StreamTableTop';
import './StreamTablePreview.module.scss';

const EMPTY_ARRAY = [];

type PropTypes = {
  previewData: Object,
  styles: any,
  dataStream: Object,
  columnWidths: Object,
  setStreamPreviewColumnWidth: Function,
};

class StreamTablePreview extends React.PureComponent {
  props: PropTypes;

  state = {};

  componentDidMount() {
    this.updateTableSize();
    // hack after upgrade to webpack4, when the table body has scroll bars the body is not rendered unless this timeout
    setTimeout(() => {
      this.updateTableSize();
    }, 100);
    this.windowResizeSubscription = Observable.fromEvent(window, 'resize')
      .throttleTime(16)
      .subscribe(() => {
        this.updateTableSize();
      });
  }

  componentWillUnmount() {
    this.windowResizeSubscription.unsubscribe();
  }

  idEditable = () => {
    const {dataStream} = this.props;
    return isStreamEditable(dataStream.state) || isEditRunning(dataStream.id);
  };

  updateTableSize = () => {
    this.setState({
      tableWidth: get(this, 'rootElm.clientWidth', document.body.clientWidth - 240),
      tableHeight: window.innerHeight - (60 + 70 + document.getElementById('stream-table-top')?.clientHeight),
      defaultColumnWidth: {
        metric: 120,
      },
    });
  };

  onColumnResizeEndCallback = (newColumnWidth, columnKey) => {
    this.props.setStreamPreviewColumnWidth({
      colId: columnKey,
      colWidth: newColumnWidth,
    });
  };

  getVertColumn = (type) => (
    <Column
      automation-id="dataCollectorColumn"
      key={`sep_${type}`}
      columnKey={`sep_${type}`}
      header={<VertCellHeader isDisabled={!this.idEditable()} type={type} />}
      isResizable={false}
      isReorderable={false}
      width={20}
      minWidth={20}
      allowCellsRecycling
      fixed={false}
      cell={<VertCell isDisabled={!this.idEditable()} type={type} />}
    />
  );

  getColumns = () => {
    let firstDimension = false;
    const {previewData} = this.props;
    const res = previewData.columns.length ? [] : EMPTY_ARRAY;
    previewData.columns.forEach((col, i) => {
      if (i === 1 && col.type === 'metric') {
        res.push(this.getVertColumn(col.type));
      }
      if (col.type === 'dimension' && !firstDimension) {
        firstDimension = true;
        res.push(
          <Column
            key="extra-measure-right-column"
            columnKey="extra-measure-right-column"
            header={<CellHeader isNewColumn newColumnType="metric" />}
            isResizable={false}
            isReorderable={false}
            width={50}
            minWidth={20}
            allowCellsRecycling
            fixed={false}
            cell={() => <span styleName="new-what-column" className="what-metric" />}
          />,
        );
        res.push(this.getVertColumn(col.type));
      }
      res.push(
        <Column
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          columnKey={col.id}
          header={<CellHeader col={col} isEditable={this.idEditable()} />}
          isResizable
          isReorderable={false}
          width={this.props.columnWidths[col.id] || get(this.state.defaultColumnWidth, col.type, null) || 150}
          minWidth={20}
          allowCellsRecycling
          fixed={col.type === 'date'}
          cell={(props) => (
            <DataCell
              {...props}
              callHeaderName={col.name}
              rowIndex={props.rowIndex}
              isWhat={col.type === 'metric'}
              isIgnored={col.hidden}
              cellData={previewData.rows[props.rowIndex][i]}
            />
          )}
        />,
      );
    });
    res.push(
      <Column
        automation-id="dataCollectorColumn"
        key="extra-right-column"
        columnKey="extra-right-column"
        header={<CellHeader isNewColumn newColumnType="dimension" />}
        isResizable={false}
        isReorderable={false}
        width={50}
        minWidth={20}
        allowCellsRecycling
        fixed={false}
        cell={() => <span />}
      />,
    );
    return res;
  };

  setRef = (e) => {
    this.rootElm = e;
  }; // will be set with null when unmounted

  render() {
    const {previewData} = this.props;

    return (
      <div styleName="root" ref={this.setRef}>
        <StreamTableTop />
        <Table
          automationId="dataCollectorStreamTable"
          rowsCount={previewData.rows.length}
          onColumnResizeEndCallback={this.onColumnResizeEndCallback}
          onColumnReorderEndCallback={this.onColumnReorderEndCallback}
          rowHeight={50}
          headerHeight={115}
          isColumnResizing={false}
          width={this.state.tableWidth || 500}
          height={this.state.tableHeight || 1000}
        >
          {this.getColumns()}
        </Table>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    previewData: selectors.getSelectedStreamPreview(state).data,
    columnWidths: selectors.getStreamPreviewColumnWidth(state),
  }),
  {
    setStreamPreviewColumnWidth,
  },
)(StreamTablePreview);
