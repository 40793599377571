/* eslint-disable react/no-find-dom-node */

// @flow
import React, {PureComponent, createRef} from 'react';
import SmartTooltip from 'common/components/SmartTooltip';
import {objectiveOffsetOf} from './positioning';
import './PopoverListItem.module.scss';

type PropTypes = {
  id: string,
  uiName: string,
  selected: boolean,
  disabled: boolean,
  children: Object,
  middleWareOnAddItem: Function,
  onRemoveItem: Function,
  canSelectMore: boolean,
};

export default class PopoverListItem extends PureComponent {
  props: PropTypes;

  state = {
    isMouseOver: false,
  };

  componentDidMount() {
    const buttonEl = this.wrapper.current;
    const placement = objectiveOffsetOf(buttonEl).left < window.innerWidth / 2 ? 'right' : 'left';
    this.setState({placement});
  }

  handleMouseOver = () => {
    this.setState({isMouseOver: true});
  };

  handleMouseOut = () => {
    this.setState({isMouseOver: false});
  };

  onClick = (ev) => {
    ev.preventDefault();
    const {id, selected, disabled, middleWareOnAddItem, onRemoveItem}: any = this.props;

    if (disabled) {
      return null;
    }

    return selected ? onRemoveItem(id) : middleWareOnAddItem(id);
  };

  wrapper = createRef();

  render() {
    const {canSelectMore, selected, disabled, children, id, uiName}: any = this.props;
    const {placement, isMouseOver} = this.state;
    const {handleMouseOver, handleMouseOut} = this;
    // TODO -> Add styling for when the button cannot be selected due too much buttons being selected
    // Do not forget to change this on CubeEditor (maxSelected)

    let styleName = 'button-disabled';
    if (!disabled) {
      if (selected) {
        styleName = 'button-active';
      } else {
        styleName = canSelectMore ? 'button-inactive' : 'button-disabled';
      }
    }

    const button = (
      <button
        type="button"
        ref={this.wrapper}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={this.onClick}
        styleName={styleName}
        automation-id={`diametricsItem-${children}`}
      >
        {children}
      </button>
    );

    const tooltipContent = (
      <div className="concept-tooltip">
        <div>
          Id:
          {id}
        </div>
        <div>
          Name:
          {uiName}
        </div>
      </div>
    );

    return isMouseOver ? (
      <SmartTooltip placement={placement} id={`tooltip-for-${id}`} content={tooltipContent}>
        {button}
      </SmartTooltip>
    ) : (
      button
    );
  }
}
