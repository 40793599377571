import {useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';
import {insightsPanelRoot} from 'insightsPanelNewAlertConsole/api/utils';

const fetchTriggeredAlertsMetricsUsage = () => {
  return get(`${insightsPanelRoot}metrics-usage`);
};

export default function fetchTriggeredAlertsMetricsUsageProvider() {
  const queryClient = useQueryClient();

  const queryKey = [QUERY_KEYS.triggeredAlertsInsightPanelMetricsUsage];
  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchQuery: () =>
      queryClient.fetchQuery(queryKey, fetchTriggeredAlertsMetricsUsage(), {
        retry: false,
      }),
    useQuery: () =>
      useQuery(queryKey, () => fetchTriggeredAlertsMetricsUsage(), {
        retry: false,
      }),
  };
}
