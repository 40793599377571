import React, {useEffect, useMemo} from 'react';
import {getFormattedHumanDuration} from 'common/utils/dateService';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import {makeStyles} from '@material-ui/core';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {abbrNum, setMaxPercentToDisplayTrimmed} from 'common/utils/numbers';
import Direction from '../../general/Direction';

import '../OverwiewTab.module.scss';

const WhatInfoCube = ({
  onIsLoading,
  isTriggerLoading,
  trigger,
}: {
  onIsLoading: Function,
  isTriggerLoading: Boolean,
  trigger: Object,
}) => {
  const css = makeStyles(() => ({
    actionBtn: {
      maxWidth: 254,
    },
    whatTabChip: {
      marginRight: 0,
      maxWidth: 253,
    },
  }));
  const classes = css();
  const what = trigger?.metrics[0]?.what;

  const overview = useMemo(() => {
    const overviewObj = {};

    if (!trigger?.metrics?.length) {
      return overviewObj;
    }

    const lastMetric = trigger.metrics[trigger.metrics.length - 1];
    const lastInterval = lastMetric.intervals[lastMetric.intervals.length - 1];
    const isDrop = lastInterval.direction === 'DOWN';
    overviewObj.isDrop = !overviewObj.isDrop ? isDrop : overviewObj.isDrop;
    overviewObj.isSpike = !overviewObj.isSpike ? !isDrop : overviewObj.isSpike;
    const toVal = lastInterval.peak;
    const fromVal = isDrop
      ? lastInterval.peak + lastInterval.deltaAbsolute
      : lastInterval.peak - lastInterval.deltaAbsolute;
    overviewObj.percentageText = setMaxPercentToDisplayTrimmed(lastInterval.deltaPercentage);
    overviewObj.fromToText = `(from ${abbrNum(fromVal, 2)} to ${abbrNum(toVal, 2)})`;

    return overviewObj;
  }, [trigger.metrics]);

  useEffect(() => {
    onIsLoading(isTriggerLoading);
  }, [isTriggerLoading]);

  const isOpen = trigger?.status?.toLowerCase() === 'open';
  const durText = getFormattedHumanDuration(trigger?.duration, isOpen ? 'absolute' : null).trim();

  const fullDurText = isOpen ? ` for ${durText}` : `, lasted ${durText}`;
  const statusText = isOpen ? 'Open' : 'Closed';

  return (
    <>
      {trigger && trigger?.metrics?.length ? (
        <div styleName="info-cube-container">
          <div>
            <Tooltip content={what} type={TYPES.SMALL}>
              <div styleName="tooltip-container">
                <InnerChip className={classes.whatTabChip} title={what} displayOnly type="measure" />
              </div>
            </Tooltip>
            <div styleName="what-info-cube-direction">
              <Direction isDrop={overview.isDrop} isSpike={overview.isSpike} percentageText={overview.percentageText} />
            </div>
            <div styleName="what-info-cube-from-to-text">{overview.fromToText}</div>
          </div>
          <div>
            <div styleName="what-info-cube-status-container">
              <div styleName={['what-info-cube-status-text', isOpen ? 'open' : ''].join(' ')}>{statusText}</div>
              <span>{fullDurText}</span>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WhatInfoCube;
