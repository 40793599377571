import React, {memo, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import RespondersTooltip from 'feedback/RespondersTooltip';
import {FEEDBACK_TYPE, FEEDBACK_SIZE} from 'feedback/services/constants';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import FeedbackPopover from 'feedback/FeedbackPopover';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: 'auto',
  },
  feedbackWrapper: {
    display: 'flex',
    alignItems: (props) => (props.size === 'large' ? 'flex-end' : 'center'),
  },
  goodCatch: {
    color: (props) => (props.isGoodCatchByMe ? theme.palette.blue['500'] : theme.palette.gray['400']),
    '&:hover': {
      color: theme.palette.blue['500'],
    },
  },
  goodCatchNumber: {
    width: 13,
    color: (props) => (props.isGoodCatchByMe ? theme.palette.blue['500'] : theme.palette.gray['500']),
    paddingBottom: '0px',
  },
  notInteresting: {
    position: 'relative',
    top: 2,
    marginRight: 2,
    color: (props) => (props.isNotInterestingByMe ? theme.palette.blue['500'] : theme.palette.gray['400']),
    '&:hover': {
      color: theme.palette.blue['500'],
    },
  },
  notInterestingNumber: {
    width: 13,
    display: 'contents',
    marginBottom: '2px',
    color: (props) => (props.isNotInterestingByMe ? theme.palette.blue['500'] : theme.palette.gray['500']),
  },
  feedbackPopover: {
    minWidth: '243px !important',
  },
}));

const FeedbackNew = ({
  alertType,
  currentFeedbackId,
  isGoodCatchByMe,
  isNotInterestingByMe,
  isGoodCatchDisplay,
  isNotInterestingDisplay,
  othersGoodCatch,
  otherNotInteresting,
  onSetGoodCatch,
  onUpdateGoodCatch,
  onRemoveGoodCatch,
  onSetNotInteresting,
  onUpdateNotInteresting,
  onRemoveNotInteresting,
  size,
  tooltipPlacement,
}: PropTypes) => {
  const [isGoodCatchPopoverDisplay, setIsGoodCatchPopoverDisplay] = useState(null);
  const [isNotInterestingPopoverDisplay, setIsNotInterestingPopoverDisplay] = useState(null);
  const [isDisableTooltip, setIsDisableTooltip] = useState(false);
  const classes = useStyles({
    isGoodCatchByMe,
    othersGoodCatch,
    isNotInterestingByMe,
    otherNotInteresting,
    size,
  });

  const setGoodCatch = () => {
    setIsDisableTooltip(true);

    if (isGoodCatchByMe) {
      onRemoveGoodCatch();
    }

    if (!isGoodCatchByMe && !isNotInterestingByMe) {
      onSetGoodCatch();
    }

    if (!isGoodCatchByMe && isNotInterestingByMe) {
      onUpdateGoodCatch();
    }
  };

  const setNotInteresting = () => {
    setIsDisableTooltip(true);

    if (isNotInterestingByMe) {
      onRemoveNotInteresting();
    }
    if (!isNotInterestingByMe && !isGoodCatchByMe) {
      onSetNotInteresting();
    }

    if (!isNotInterestingByMe && isGoodCatchByMe) {
      onUpdateNotInteresting();
    }
  };

  const onToggleGoodCatch = useCallback(() => {
    if (isGoodCatchByMe) {
      setIsGoodCatchPopoverDisplay(false);
    } else {
      setIsGoodCatchPopoverDisplay(true);
      setIsNotInterestingPopoverDisplay(false);
    }
  }, [isGoodCatchByMe]);

  const onToggleNotInteresting = useCallback(() => {
    if (isNotInterestingByMe) {
      setIsNotInterestingPopoverDisplay(false);
    } else {
      setIsNotInterestingPopoverDisplay(true);
      setIsGoodCatchPopoverDisplay(false);
    }
  }, [isNotInterestingByMe]);

  return (
    <div
      className={classes.container}
      style={{width: FEEDBACK_SIZE[size].container.width, flexDirection: isGoodCatchDisplay ? 'row' : 'row-reverse'}}
    >
      {isGoodCatchDisplay && (
        <FormDdl
          automationId={isGoodCatchByMe ? 'feedback-good-catch-removed' : 'feedback-good-catch-selected'}
          buttonComponent={
            <RespondersTooltip
              otherResponders={othersGoodCatch}
              isFeedbackByMe={isGoodCatchByMe}
              isDisabled={isDisableTooltip}
              placeholder="Good catch"
              tooltipPlacement={tooltipPlacement}
            >
              <div className={classes.feedbackWrapper}>
                <div className={classes.goodCatchNumber}>
                  {isGoodCatchByMe ? othersGoodCatch?.length + 1 : othersGoodCatch?.length}
                </div>
                <div
                  component="span"
                  onClick={setGoodCatch}
                  className={`icon icn-general24-like ${classes.goodCatch} alert-clickable-item`}
                  style={{fontSize: FEEDBACK_SIZE[size].icons.fontSize}}
                />
              </div>
            </RespondersTooltip>
          }
          isOpen={isGoodCatchPopoverDisplay}
          onToggle={onToggleGoodCatch}
          popoverComponent={
            <FeedbackPopover
              alertType={alertType}
              feedbackType={FEEDBACK_TYPE.GOOD_CATCH}
              onSubmit={onUpdateGoodCatch}
              onClose={() => setIsGoodCatchPopoverDisplay(false)}
              currentFeedbackId={currentFeedbackId}
            />
          }
          placement="auto"
          position="right"
          popoverContainerClassName={classes.feedbackPopover}
        />
      )}
      {isNotInterestingDisplay && (
        <FormDdl
          automationId={isNotInterestingByMe ? 'feedback-not-interesting-removed' : 'feedback-not-interesting-selected'}
          buttonComponent={
            <RespondersTooltip
              otherResponders={otherNotInteresting}
              isFeedbackByMe={isNotInterestingByMe}
              isDisabled={isDisableTooltip}
              placeholder="Not Interesting"
              tooltipPlacement={tooltipPlacement}
            >
              <div className={classes.feedbackWrapper}>
                <div
                  onClick={setNotInteresting}
                  className={`icon icn-general24-dislike ${classes.notInteresting} alert-clickable-item`}
                  style={{
                    fontSize: FEEDBACK_SIZE[size].icons.fontSize,
                    marginRight: 5,
                  }}
                />
                <div className={classes.notInterestingNumber}>
                  {isNotInterestingByMe ? otherNotInteresting?.length + 1 : otherNotInteresting?.length}
                </div>
              </div>
            </RespondersTooltip>
          }
          isOpen={isNotInterestingPopoverDisplay}
          onToggle={onToggleNotInteresting}
          popoverComponent={
            <FeedbackPopover
              alertType={alertType}
              feedbackType={FEEDBACK_TYPE.NOT_INTERESTING}
              onSubmit={onUpdateNotInteresting}
              onClose={() => setIsNotInterestingPopoverDisplay(false)}
              currentFeedbackId={currentFeedbackId}
            />
          }
          placement="auto"
          position="right"
          popoverContainerClassName={classes.feedbackPopover}
        />
      )}
    </div>
  );
};

FeedbackNew.defaultProps = {
  isGoodCatchDisplay: true,
  isNotInterestingDisplay: true,
  othersGoodCatch: [],
  otherNotInteresting: [],
  size: 'mid',
  tooltipPlacement: 'top',
};

FeedbackNew.propTypes = {
  alertType: PropTypes.string.isRequired,
  currentFeedbackId: PropTypes.string.isRequired,
  isGoodCatchByMe: PropTypes.bool.isRequired,
  isGoodCatchDisplay: PropTypes.bool,
  isNotInterestingDisplay: PropTypes.bool,
  isNotInterestingByMe: PropTypes.bool.isRequired,
  otherNotInteresting: PropTypes.arrayOf(PropTypes.object),
  onSetGoodCatch: PropTypes.func.isRequired,
  onSetNotInteresting: PropTypes.func.isRequired,
  onRemoveGoodCatch: PropTypes.func.isRequired,
  onRemoveNotInteresting: PropTypes.func.isRequired,
  onUpdateGoodCatch: PropTypes.func.isRequired,
  onUpdateNotInteresting: PropTypes.func.isRequired,
  othersGoodCatch: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.string,
  tooltipPlacement: PropTypes.string,
};

export default memo(FeedbackNew);
