import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {updateColor} from 'charts/timeSeries/services/timeSeriesHchartService';
import Checkbox from 'common/componentsV2/Checkbox';
import MetricNameMenuDashboard from 'common/components/MetricNameMenuDashboard';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import SmartTooltip from 'common/components/SmartTooltip';
import {format, Humanize} from 'common/utils/utilsService';
import {palette} from 'app/styles/theme';
import {DECIMAL_COUNT} from 'dashboards/services/dashboardService';
import PieSliceTooltip from './PieSliceTooltip';
import {getPropsList} from '../utils';

const useStyles = makeStyles(() => ({
  pieLabelWrapper: {
    maxWidth: '100%',
    marginBottom: '1px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  checkboxWrapper: {
    '& input[type="checkbox"] + label::before': {
      color: ({itemColor}) => itemColor,
    },
    '& input[type="checkbox"]:checked + label::before': {
      color: ({itemColor}) => itemColor,
    },
    marginRight: '6px',
    marginTop: '2px',
    flexShrink: 0,
  },
  value: {
    lineHeight: '16px',
    color: palette.green[650],
    marginRight: 6,
    padding: 4,
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 6,
    cursor: 'pointer',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    backgroundColor: palette.gray[100],
    '&:hover': {
      backgroundColor: palette.blue[100],
    },
  },
  copyWrapper: {
    opacity: 0,
    flexShrink: 0,
  },
  tooltipBox: {
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    maxWidth: 493,
    borderRadius: 8,
    '&.tooltip': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  copyHighlighter: {
    borderRadius: '4px',
    padding: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
    backgroundColor: ({isHighlighted}) => (isHighlighted ? palette.blue[100] : 'transparent'),
    '&:hover': {
      backgroundColor: palette.blue[100],
      '& .copy-wrapper': {
        opacity: '1',
        '& .copy-container-wrapper': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  propertiesWrapper: {
    display: 'flex',
    overflow: 'hidden',
  },
  valueWrapper: {
    display: ({areValuesVisible}) => (areValuesVisible ? 'block' : 'none'),
    flexShrink: 0,
    alignItems: 'center',
    backgroundColor: ({itemColor}) => itemColor,
    borderRadius: '10px',
    height: '18px',
    color: palette.white[500],
    fontSize: '14px',
    fontWeight: 'bold',
    paddingLeft: '4px',
    paddingRight: '4px',
    marginLeft: '8x',
    position: 'absolute',
    right: '0',
  },
}));

const PieLegendRow = ({
  hchart,
  hiddenSeriesMap,
  toggleVisibility,
  index,
  item,
  tileData,
  dashboardId,
  data,
  isHighlighted,
  areValuesVisible,
  isDecimal,
  isAbbreviate,
}: {
  hchart: Object,
  hiddenSeriesMap: Object,
  toggleVisibility: Function,
  index: number,
  item: Object,
  tileData: Object,
  dashboardId: string,
  data: Array,
  isHighlighted: boolean,
  areValuesVisible: boolean,
  isDecimal: boolean,
  isAbbreviate: boolean,
}) => {
  const abbreviateValue = Humanize.humanize(item.y, DECIMAL_COUNT);
  const formattedValue = format(
    isAbbreviate ? abbreviateValue.number : item.y,
    3,
    ',',
    '.',
    isDecimal ? DECIMAL_COUNT : 0,
  );

  // eslint-disable-next-line no-param-reassign,no-return-assign
  const itemColor = updateColor({index, metric: item, tileData});
  const classes = useStyles({itemColor, isHighlighted, areValuesVisible});
  const manageState = (isHover) => {
    if (hchart) {
      hchart.series[0].data.forEach((series) => {
        series.setState(isHover ? 'inactive' : 'active');
      });
      if (isHover) {
        const hoveredSlice = hchart.series[0].data.find((dataItem) => dataItem.options.name === item.name);
        if (hoveredSlice) {
          hoveredSlice.setState('hover');
        }
      }
    }
  };

  const propsList = useMemo(() => getPropsList(item), [item]);
  const tooltipData = useMemo(
    () => ({
      point: {
        options: {...item, percentage: (data.find((dataItem) => dataItem.id === item.id) || {}).percentage},
        color: itemColor,
      },
      y: item.y,
    }),
    [item, itemColor],
  );

  return (
    propsList.length !== 0 && (
      <div className={classes.pieLabelWrapper} onMouseOver={() => manageState(true)} onMouseOut={() => manageState()}>
        <div className={classes.checkboxWrapper}>
          <Checkbox isChecked={!hiddenSeriesMap[index]} onChange={() => toggleVisibility(index)} />
        </div>
        <SmartTooltip
          placement="top"
          content={<PieSliceTooltip isLegend data={tooltipData} isDecimal={isDecimal} isAbbreviate={isAbbreviate} />}
          extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
        >
          <div className={classes.copyHighlighter}>
            <>
              <div className={classes.propertiesWrapper}>
                {propsList.map((propItem) => (
                  <div className={classes.value}>{propItem.value}</div>
                ))}
              </div>
              {tileData ? (
                <div className={`copy-wrapper ${classes.copyWrapper}`}>
                  <CopyToClipboardButton
                    forceHiddenOverflow
                    copyStr={item.name}
                    shouldUseIcon
                    metricNameMenu={
                      <MetricNameMenuDashboard
                        metric={item}
                        tileData={tileData}
                        index={index}
                        dashboardId={dashboardId}
                        isPieChart
                      />
                    }
                  />
                </div>
              ) : null}
            </>
          </div>
        </SmartTooltip>
        <div className={classes.valueWrapper} automation-id={`pieChartSliceValue_${index + 1}`}>
          {`${formattedValue} ${isAbbreviate ? abbreviateValue.postfix : ''}`}
        </div>
      </div>
    )
  );
};

export default PieLegendRow;
