import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const accounts = makeAsyncReducer(actions.fetchNewRelicAccounts, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const keyset = makeAsyncReducer(actions.fetchNewRelicKeyset, {
  defaultData: EMPTY_OBJECT,
  errorHandler: bcErrorHandler,
});

const filePreview = makeAsyncReducer(actions.fetchNewrelicPreview, {
  defaultData: EMPTY_OBJECT,
  errorHandler: bcErrorHandler,
});

export default combineReducers({
  accounts,
  keyset,
  filePreview,
});
