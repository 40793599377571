import {get, capitalize, has} from 'lodash';
import deepSearch from 'common/utils/deepSearch';

const isMetric2o = (metricName) => {
  if (!metricName || metricName.indexOf('.') < 1 || metricName.indexOf('=') < 1) {
    return false;
  }
  return true;
};

export const METRIC_NAME_SEPARATOR = '.';
export const METRIC_NAME_PROPERTY_SEPARATOR = '=';
export const METRIC_NAME_ORIGIN_PREFIX = ' [';
export const METRIC_NAME_ORIGIN_SUFFIX = ']';
export const ORIGIN_PREFIX = '@';
export const METRIC_ORIGIN_TYPES = {
  COMPOSITE: 'COMPOSITE',
  ALERT: 'ALERT',
  STREAM: 'STREAM',
  FORECAST: 'FORECAST',
};

export const durationScales = {
  minutes: {
    text: 'Minutes',
    shortText: 'MIN',
    value: 'minutes',
    seconds: 60,
    min: 60,
    max: 60 * 720,
  },
  hours: {
    text: 'Hours',
    shortText: 'HRS',
    value: 'hours',
    seconds: 60 * 60,
    min: 60 * 60 * 1,
    max: 60 * 60 * 168,
  },
  days: {
    text: 'Days',
    shortText: 'DAY',
    value: 'days',
    seconds: 60 * 60 * 24,
    min: 60 * 60 * 24,
    max: 60 * 60 * 24 * 14,
  },
  weeks: {
    text: 'Weeks',
    shortText: 'WEEK',
    value: 'weeks',
    seconds: 60 * 60 * 24 * 7,
    min: 60 * 60 * 24 * 7,
    max: 60 * 60 * 24 * 7 * 4,
  },
};

// Read from store to get enable state for each resolution
export const resolutionTypes = {
  // value must be same as attribute key (except auto)
  auto: {
    value: undefined,
    value2: '',
    text: 'Auto',
    shortText: 'Auto',
    rank: null,
    ms: 0,
    enabled: true,
  },
  short: {
    value: 'short',
    value2: '1m',
    text: '1 Minute',
    shortText: {num: '1', label: 'MIN'},
    units: 'minutes',
    rank: 1,
    ms: 1000 * 60,
    enabled: true,
  },
  medium: {
    value: 'medium',
    value2: '5m',
    text: '5 Minutes',
    shortText: {num: '5', label: 'MIN'},
    units: 'minutes',
    rank: 2,
    ms: 1000 * 60 * 5,
    enabled: true,
  },
  long: {
    value: 'long',
    value2: '1h',
    text: '1 Hour',
    shortText: {num: '1', label: 'HR'},
    units: 'hours',
    rank: 3,
    ms: 1000 * 60 * 60,
    enabled: true,
  },
  longlong: {
    value: 'longlong',
    value2: '1d',
    text: '1 Day',
    shortText: {num: '1', label: 'DAY'},
    units: 'days',
    rank: 4,
    ms: 1000 * 60 * 60 * 24,
    enabled: true,
  },
  weekly: {
    value: 'weekly',
    value2: '1w',
    text: '1 Week',
    shortText: {num: '1', label: 'WEEK'},
    units: 'weeks',
    rank: 5,
    ms: 1000 * 60 * 60 * 24 * 7,
    enabled: true,
  },
};

export const getMetric2oNameToArr = (metricName) => {
  const res = [];
  if (!metricName) {
    return false;
  }
  if (metricName.indexOf('.') < 1) {
    return [[metricName]];
  }

  const metricNameSplit = metricName.split(METRIC_NAME_SEPARATOR);
  metricNameSplit.forEach((propVal) => {
    const tmp = propVal.split(METRIC_NAME_PROPERTY_SEPARATOR);
    res.push([tmp[0], get(tmp, '[1]')]);
  });
  return res;
};

export const getMetricUnits = (metric) => {
  if (!metric || !isMetric2o(metric.name)) {
    return null;
  }

  const res = getMetric2oNameToArr(metric.name);
  const unit = res.find((a) => ['unit', 'units'].includes(a[0].toLowerCase()));
  const unitsTag = res.find((a) => ['#unit', '#units'].includes(a[0].toLowerCase()));

  return get(unitsTag, '[1]') || get(unit, '[1]');
};

export const getMetricOriginStr = (metric) => {
  if (has(metric, 'origin.title')) {
    return `${METRIC_NAME_ORIGIN_PREFIX + ORIGIN_PREFIX + capitalize(metric.origin.type)}: ${
      metric.origin.title
    }${METRIC_NAME_ORIGIN_SUFFIX}`;
  }
  return '';
};

export const getMetricName = (metric) => metric.name + getMetricOriginStr(metric);

export const cleanupSpecialChars = (value) => {
  if (!value) {
    return null;
  }
  return value.replace(/[\s.]+/gi, '_');
};

export const treeVisitor = (_root, _childPropertyName, _callback) => {
  // eslint-disable-next-line no-underscore-dangle
  const _treeVisitor = (root, childPropertyName, callback, level) => {
    callback(root, level);
    if (root[childPropertyName] && root[childPropertyName].length) {
      root[childPropertyName].forEach((node) => {
        _treeVisitor(node, childPropertyName, callback, level + 1);
      });
    }
  };
  _treeVisitor(_root, _childPropertyName, _callback, 0);
};

export const getSelectedBranch = (expressionTree, nodeId) => {
  let returnBranch = expressionTree.root;
  treeVisitor(expressionTree.root, 'children', (node) => {
    if (node.id === nodeId) {
      returnBranch = node;
    }
  });
  return {root: returnBranch};
};

export const getNotEmptyNodeId = (expressionTree) => {
  const isEmptyFunction =
    expressionTree.root &&
    expressionTree.root.function === '' &&
    expressionTree.root.children.length === 1 &&
    expressionTree.root.children[0].type === 'metric';

  return isEmptyFunction ? expressionTree.root.children[0].id : expressionTree.root && expressionTree.root.id;
};

export const validateExpression = (expressionTree, isAlertType) => {
  let errorFunctionDisplay = false;
  let errorEmptyExpression = false;
  let errorEmptyFunction = false;
  let errorAlertExpression = false;
  treeVisitor(expressionTree.root, 'children', (childNode) => {
    if (childNode.type === 'function') {
      if (childNode.uiData.isFunctionDisplayOnly) {
        errorFunctionDisplay = true;
      }
      if (childNode.function === '') {
        errorEmptyFunction = true;
      }
    }
    if (childNode.type === 'metric') {
      if (childNode.searchObject.expression.length === 0) {
        errorEmptyExpression = true;
      }
    }
  });

  if (deepSearch(expressionTree, 'originType', (k, v) => v.toLowerCase() === 'alert') && isAlertType) {
    errorAlertExpression = true;
  }

  return {errorEmptyExpression, errorFunctionDisplay, errorEmptyFunction, errorAlertExpression};
};

export const isCompositeExpression = (expressionTree) => {
  if (expressionTree.root && expressionTree.root.type === 'function') {
    return expressionTree.root.function !== '';
  }
  return false;
};
