// @flow
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {Backdrop, makeStyles, Modal} from '@material-ui/core';
import {
  ACTION_TYPE_VALUE,
  ENTITY_TYPE_VALUE,
  DEFAULT_PARAMS,
  getEntityTypeByParamName,
  getEntityTypeText,
  getNameActionType,
  getNameEntityType,
  getOptionsMenuRow,
  PARAMS_NAME,
  SYSTEM_USER,
} from 'admin.activityLog/services/activityLogService';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import {getUniqueId} from 'common/utils/guid';
import {get, groupBy, omit} from 'lodash';
import ErrorBoundary from 'errorBoundaries/ErrorBoundary';
import {correctTimestampUnix} from 'common/utils/dateRangeService';
import moment from 'moment';
import {difference} from 'common/utils/utilsService';
import {ReactComponent as Logo} from 'app/images/logo-anodot-new.svg';
import EmptySearch from 'common/componentsV2/EmptySearch';
import InfiniteList from 'common/componentsV2/InfiniteList';
import {getTimeZoneName} from 'profile/store/selectors';

type PropsType = {
  setQueryParams: Function,
  usersData: Array<Object>,
  data: Array<Object>,
  setList: Function,
  loadMoreRows: Function,
  payload: Object,
  nextPage: string,
  isLoadingNextPage: boolean,
  totalCount: number,
};

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const useStyles = makeStyles(({palette, typography}) => ({
  title: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '28px',
    marginBottom: 12,
  },
  wrapper: {
    backgroundColor: palette.gray[100],
    display: 'flex',
    alignItems: 'center',
    padding: '12px 8px 12px 16px',
    borderRadius: 10,
    marginBottom: 8,
    '&:hover': {
      '& $menuRow': {
        visibility: 'visible',
      },
    },
  },
  userWrapper: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    borderRadius: '50%',
    border: `2px solid ${palette.gray[400]}`,
    marginRight: 8,
  },
  systemUserWrapper: {
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    borderRadius: '50%',
    background: palette.white[500],
    marginRight: 8,
    '@global': {
      svg: {
        transform: 'scale(0.6)',
      },
    },
  },
  user: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.gray[500],
    fontWeight: 500,
    backgroundColor: palette.gray[300],
    borderRadius: '50%',
  },
  text: {
    ...typography.ellipsis,
    flexGrow: 1,
    fontSize: 16,
    lineHeight: '24px',
  },
  time: {
    width: 160,
    flexShrink: 0,
    color: palette.gray[400],
    fontWeight: 500,
  },
  menuRow: {
    cursor: 'pointer',
    visibility: 'hidden',
  },
  menuButton: {
    '&:hover': {
      color: palette.blue[500],
    },
  },
  modalWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 800,
    maxWidth: 900,
    minHeight: 500,
    maxHeight: 600,
    backgroundColor: palette.white[500],
    borderRadius: 4,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    paddingTop: 16,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '28px',
    marginBottom: 12,
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  modalCloseButton: {
    position: 'relative',
    top: -6,
    right: -6,
    fontSize: 16,
    color: palette.gray[500],
    cursor: 'pointer',
  },
  textColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '33.3%',
    paddingRight: 20,
    '&:last-child': {
      paddingRight: 0,
    },
  },
  textAreaWrapper: {
    resize: 'none',
    flexGrow: 1,
  },
  noResult: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    height: '100%',
  },
  link: {
    color: palette.blue[500],
    '&:hover': {
      color: palette.blue[500],
    },
  },
}));

const getLink = (entityType, entityName, entityDefinition) => {
  switch (entityType) {
    case ENTITY_TYPE_VALUE.CHANNELS:
      return `/#!/r/channels?searchQuery=${entityName}`;
    case ENTITY_TYPE_VALUE.DATA_SOURCE:
      return `/#!/r/bc/data-manager`;
    case ENTITY_TYPE_VALUE.METRIC_STREAMS:
    case ENTITY_TYPE_VALUE.EVENTS_STREAM:
    case ENTITY_TYPE_VALUE.PIPELINE:
      return `/#!/r/bc/data-manager?searchQuery=${entityName}`;
    case ENTITY_TYPE_VALUE.ALERTS:
      return `/#!/r/alert-manager?searchQuery=${entityName}`;
    case ENTITY_TYPE_VALUE.USER_GROUP:
      return `/#!/r/admin/users-groups?groups=${entityDefinition?._id}`;
    case ENTITY_TYPE_VALUE.USER:
      return `/#!/r/admin/users-groups?searchQuery=${entityName}`;
    case ENTITY_TYPE_VALUE.DYNAMIC_ROUTING:
      return `/#!/r/asset-management`;
    default:
      return '';
  }
};

const getNames = (expressionValues) =>
  expressionValues.map((e, index) => `${e}${index + 1 === expressionValues.length ? '' : ','}`);

const ActivityLogMain = ({
  setQueryParams,
  usersData,
  data,
  setList,
  loadMoreRows,
  payload,
  nextPage,
  isLoadingNextPage,
  totalCount,
}: PropsType) => {
  const classes = useStyles();
  const [modalState, setModalState] = useState({open: false, data: EMPTY_OBJECT});
  const timeZoneName = useSelector(getTimeZoneName);
  const title = (label) => <div className={classes.title}>{label}</div>;

  const handleModal = useCallback(
    (dataModal) => {
      setModalState({open: !modalState.open, data: dataModal || EMPTY_OBJECT});
    },
    [modalState],
  );

  const onChange = useCallback((option, obj) => {
    if (option.value === ACTION_TYPE_VALUE.EDIT) {
      handleModal(obj);
    } else if (option.value === PARAMS_NAME.USERS) {
      setQueryParams({
        ...omit(DEFAULT_PARAMS, [[PARAMS_NAME.CONST_RANGE]]),
        [option.value]: obj.userId || SYSTEM_USER.value,
      });
    } else {
      setQueryParams({
        ...omit(DEFAULT_PARAMS, [[PARAMS_NAME.CONST_RANGE]]),
        [option.value]: obj.entityDefinition.id || obj.entityDefinition._id,
        [PARAMS_NAME.ENTITY_TYPE]: getEntityTypeByParamName(option.value),
      });
    }
  }, []);

  const grouped = useMemo(
    () => groupBy(data, (obj) => moment.unix(correctTimestampUnix(obj.actionTimestamp)).format('D-M-YYYY')),
    [data],
  );

  useEffect(() => {
    setList(grouped);
  }, [grouped]);

  const itemHeader = (key) => {
    const dateArr = key.split('-');
    const date = moment().set({date: dateArr[0], month: dateArr[1] - 1});
    return (
      <div key={getUniqueId()} className="display_flex alignItems_flex-end height_1">
        {title(
          moment
            .unix(correctTimestampUnix(date))
            .calendar(null, {sameDay: '[Today]', lastDay: '[Yesterday]', lastWeek: 'MMM DD', sameElse: 'MMM DD'}),
        )}
      </div>
    );
  };

  const item = (value, obj) => {
    const {actionType, entityType, entityName, actionTimestamp, userId, entityDefinition} = obj;
    const isSystemUser = obj.actionSource === SYSTEM_USER.value;
    const userName = usersData.find((u) => userId === u._id) || (isSystemUser && SYSTEM_USER.label) || EMPTY_OBJECT;

    const expressionValues = get(
      entityDefinition,
      'expressionTreeModel.expressionTree.root.searchObject.expression',
      EMPTY_ARRAY,
    ).map((o) => o.value);

    const userAbbr = `${userName.firstName ? userName.firstName[0].toUpperCase() : ''}${
      userName.lastName ? userName.lastName[0].toUpperCase() : ''
    }`;

    const dateFormat = moment
      .unix(correctTimestampUnix(actionTimestamp))
      .tz(timeZoneName)
      .format('h:mm A, MMM DD, YYYY');

    return (
      <React.Fragment key={getUniqueId()}>
        <div className={classes.wrapper}>
          {isSystemUser ? (
            <div className={classes.systemUserWrapper}>
              <Logo />
            </div>
          ) : (
            <div className={classes.userWrapper}>
              <div className={classes.user}>{userAbbr}</div>
            </div>
          )}
          <div className={classes.text}>
            {`${isSystemUser ? userName : userName.firstName} has ${getNameActionType(actionType)} ${getEntityTypeText({
              actionType,
              entityType,
              entityName,
              entityDefinition,
            })}: `}
            <a
              href={getLink(entityType, entityName, entityDefinition)}
              className={classes.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {`${entityName} ${entityType === ENTITY_TYPE_VALUE.ALERTS ? `per ${getNames(expressionValues)}` : ''}`}
            </a>
          </div>
          <div className={classes.time}>{dateFormat}</div>
          <div className={classes.menuRow}>
            <FormDdlSelect
              options={getOptionsMenuRow(
                getNameEntityType(entityType),
                isSystemUser ? userName : userName.firstName,
                actionType,
              )}
              button={<i className={`icon icn-icon-3-dots ${classes.menuButton}`} />}
              optionComponent={<OptionComponentSimple />}
              onChange={(option) => onChange(option, obj)}
              width={200}
              maxWidth={250}
              position="right"
              automationId="auditLogActions"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  const list = useMemo(() => {
    const result = [];
    Object.entries(grouped).forEach(([key, value]) => {
      result.push(itemHeader(key));
      value.forEach((obj) => {
        result.push(item(value, obj));
      });
    });
    return result;
  }, [grouped, usersData]);

  const rowHeight = ({index}) => {
    if (index === 0) {
      return 40;
    }

    return 64;
  };

  const loadMoreRowsFunction = useCallback(() => {
    loadMoreRows({...payload, pageIterator: nextPage});
  }, [nextPage, payload]);

  return (
    <ErrorBoundary>
      {!list.length ? (
        <EmptySearch error="No Activity Logs to Show" errorExplanation="Change Filters to See Activity Logs" />
      ) : (
        <div className="flexGrow_1">
          <InfiniteList
            loadMoreRows={loadMoreRowsFunction}
            rowHeight={rowHeight}
            threshold={100}
            nextPage={nextPage}
            isLoadingNextPage={isLoadingNextPage}
            list={list}
            styleList={{paddingBottom: 40}}
            totalCount={totalCount}
          />
        </div>
      )}
      <Modal disableEnforceFocus open={modalState.open} BackdropComponent={Backdrop}>
        <div className={classes.modalWrapper}>
          <div className={classes.modalHeader}>
            <span>Change Log</span>
            <i className={`icon icn-general16-closeb ${classes.modalCloseButton}`} onClick={handleModal} />
          </div>
          <div className={classes.modalBody}>
            <div className={classes.textColumn}>
              <div>Actual:</div>
              <textarea
                className={classes.textAreaWrapper}
                value={JSON.stringify(modalState.data.entityDefinition, undefined, ' ')}
                disabled
              />
            </div>
            <div className={classes.textColumn}>
              <div>Previous:</div>
              <textarea
                className={classes.textAreaWrapper}
                value={JSON.stringify(modalState.data.previousVersionDefinition, undefined, ' ')}
                disabled
              />
            </div>
            <div className={classes.textColumn}>
              <div>Difference:</div>
              <textarea
                className={classes.textAreaWrapper}
                value={JSON.stringify(
                  difference(modalState.data.entityDefinition, modalState.data.previousVersionDefinition),
                  undefined,
                  ' ',
                )}
                disabled
              />
            </div>
          </div>
        </div>
      </Modal>
    </ErrorBoundary>
  );
};

export default React.memo(ActivityLogMain);
