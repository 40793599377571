// @flow
import React, {Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {omitBy} from 'lodash';
import {palette} from 'app/styles/theme';
import Tooltip from 'common/componentsV2/Tooltip';
import './IssuesSubPanel.module.scss';

const useStylesTpMetricItem = makeStyles(() => ({
  wrapper: {
    fontSize: 14,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  tooltipContent: {
    color: palette.gray[500],
    fontWeight: 500,
    fontSize: 14,
  },
  for: {
    marginRight: 4,
    marginLeft: 4,
    display: 'inline-block',
    color: palette.gray[400],
    fontWeight: 'normal',
    pointerEvents: 'none',
  },
  tooltipBox: {
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    maxWidth: 493,
    borderRadius: 8,
    '&.tooltip': {
      background: 'transparent',
      boxShadow: 'none',
    },
    '&.tooltip-inner': {
      backgroundColor: `${palette.white[500]} !important`,
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    },
  },
  customPlacement: {
    '&.tooltip': {
      marginTop: -18,
      marginLeft: -12,
    },
    '@global': {
      '.tooltip-inner': {
        position: 'relative',
        transform: ({measureTooltipPlacement}) =>
          `translate(100%, ${measureTooltipPlacement === 'bottom' ? '58%' : '-50%'})`,
        maxWidth: 364,
      },
    },
  },
  propsListKey: {
    width: 80,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    flexShrink: 0,
    marginRight: 8,
    fontSize: 12,
    color: palette.gray[400],
    lineHeight: '16px',
    overflow: 'hidden',
  },
  propsListValue: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 56,
    maxWidth: 348,
    lineHeight: '16px',
    color: palette.mint[600],
    wordBreak: 'break-all',
  },
  what: {
    display: 'inline-block',
    color: palette.purple[600],
  },
  property: {
    display: 'inline-block',
    maxWidth: 295,
    overflow: 'hidden',
    height: 20,
  },
  arrow: {
    display: 'inline-block',
    width: 16,
    height: 16,
    float: 'right',
    marginRight: 10,
    marginTop: 2,
  },
  value: {
    lineHeight: '16px',
    color: palette.eucaliptus[500],
    marginRight: 6,
    paddingRight: 4,
    paddingLeft: 4,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: 2,
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 6,
    backgroundColor: palette.blue[100],
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },
  originValue: {
    color: palette.gray[400],
  },
  icon: {
    border: '1px solid red',
  },
}));

const TpMetricName = ({metric, tooltipPlacement, measureTooltipPlacement, onNameClick, onArrowClick}) => {
  const classes = useStylesTpMetricItem({measureTooltipPlacement});

  const metricProps = useMemo(() => metric.properties.concat(metric.tags.map((t) => ({...t, value: `#${t.value}`}))), [
    metric,
  ]);
  const {origin} = metric;

  const lastPropsKeys = ['target_type', 'mtype', 'func'];
  const lastProps = useMemo(
    () =>
      lastPropsKeys
        .map((key) => {
          const prop = metricProps.find((item) => item.key === key);
          return (
            prop && {
              ...prop,
              isLast: true,
            }
          );
        })
        .filter((item) => item),
    [metricProps],
  );

  let propsList = useMemo(
    () => [...Object.values(omitBy(metricProps, (item) => lastPropsKeys.includes(item.key))), ...lastProps],
    [metricProps, lastProps],
  );
  if (origin) {
    propsList = [
      ...propsList,
      {
        key: `@${origin.type.charAt(0).toUpperCase() + origin.type.slice(1)}`,
        value: origin.title,
        isLast: true,
        isOrigin: true,
      },
    ];
  }

  const tooltip = (
    <div className="fontSize_14 fontWeight_500">
      {metric.what && <div className="display_flex color_purple-500 mb_1-5">{metric.what}</div>}

      {propsList.map((kv, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className="display_flex alignItems_baseline mb_0-5" key={`${kv.key}_${index}`}>
          <div className={classes.propsListKey}>{kv.key}</div>
          <div className={classes.propsListValue}>{kv.value}</div>
        </div>
      ))}
    </div>
  );

  const locMetricNameClick = () => {
    if (onNameClick) {
      onNameClick();
    }
  };

  const locMetricArrowClick = (e) => {
    e.stopPropagation();
    if (onArrowClick) {
      onArrowClick();
    }
  };

  return (
    <Box className={classes.wrapper} onClick={locMetricNameClick}>
      {metric.what && (
        <Tooltip
          placement="left"
          content={tooltip}
          extraTtClass={`metric-name-tooltip ${classes.tooltipBox} ${classes.customPlacement}`}
        >
          <Box component="span" className={classes.property}>
            <Box className={classes.what}>{metric.what}</Box>
          </Box>
        </Tooltip>
      )}
      {metric.what && metric.properties.length > 0 && (
        <Box component="span" className={classes.property}>
          <Box className={classes.for}>for</Box>
        </Box>
      )}

      <Box component="span" className={classes.arrow} onClick={locMetricArrowClick}>
        <span className="icon icn-arrow16-chevrondown" />
      </Box>

      {propsList.map((p, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box component="span" className={classes.property} key={p.key + i}>
          <Fragment>
            {p.isOrigin && (
              <Tooltip
                placement={tooltipPlacement}
                content={<Box className={classes.tooltipContent}>{p.value}</Box>}
                extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
              >
                <Box className={`${classes.value} ${classes.originValue}`}>{p.key}</Box>
              </Tooltip>
            )}

            {!p.isOrigin && !p.isLast && (
              <Tooltip
                placement={tooltipPlacement}
                content={<Box className={classes.tooltipContent}>{p.key}</Box>}
                extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
              >
                <Box className={classes.value}>{p.value}</Box>
              </Tooltip>
            )}
          </Fragment>
        </Box>
      ))}
    </Box>
  );
};

TpMetricName.propTypes = {
  metric: PropTypes.objectOf(PropTypes.any).isRequired,
  tooltipPlacement: PropTypes.string,
  measureTooltipPlacement: PropTypes.string,
  onNameClick: PropTypes.func,
  onArrowClick: PropTypes.func,
};

TpMetricName.defaultProps = {
  tooltipPlacement: 'top',
  measureTooltipPlacement: 'left',
  onNameClick: null,
  onArrowClick: null,
};

export default TpMetricName;
