import {useMutation, useQueryClient} from 'react-query';

import {delete_} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/';

const deleteStream = (id, payload) => {
  return delete_(`${root}event-streams/${id}?type=${payload.type}&deleteMetrics=${payload.shouldDeleteMetrics}`);
};

export default function useDeleteEventStream() {
  const queryClient = useQueryClient();

  const {mutate: deleteEventStream, isLoading: isDeleteEventStreamLoading} = useMutation(
    ({streamId, payload}) => deleteStream(streamId, payload),
    {
      onSuccess: (data, meta) => {
        queryClient.invalidateQueries([QUERY_KEYS.streams]);
        meta.onSuccess(data, meta.payload.id);
      },
    },
  );

  return {deleteEventStream, isDeleteEventStreamLoading};
}
