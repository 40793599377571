import React, {useEffect, useState} from 'react';
import {useQueryParams} from 'use-query-params';
import PageLayout from 'common/componentsV2/PageLayout';
import RecommendationsFilters from 'recommendations/components/filters/RecommendationsFilters';
import {RECOMMENDATIONS_QUERY_PARAMS, RECOMMENDATIONS_QUERY_PARAMS_CONFIG} from 'recommendations/services/consts';
import fetchRecommendationsProvider from 'recommendations/api/fetchRecommendationsProvider';
import RecommendationsHeader from 'recommendations/pages/RecommendationsHeader';
import RecommendationsEmptyState from 'recommendations/components/recommendationsList/RecommendationsEmptyState';
import RecommendationsList from 'recommendations/components/recommendationsList/RecommendationsList';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {RecommendationsProvider} from 'recommendations/hooks/useRecommendationsContext';

import './Recommendations.module.scss';

const Recommendations = () => {
  const [queryParams, setQueryParams] = useQueryParams(RECOMMENDATIONS_QUERY_PARAMS_CONFIG);
  const [filteredRecommendations, setFilteredRecommendations] = useState([]);

  const recommendationsResult = fetchRecommendationsProvider().useQuery();
  const recommendations = recommendationsResult?.data;

  useEffect(() => {
    let filteredList = recommendations;
    if (recommendations?.length) {
      const searchExpression = queryParams?.[RECOMMENDATIONS_QUERY_PARAMS.SEARCH_EXPRESSION.name]?.toLowerCase();
      if (searchExpression) {
        filteredList = recommendations?.filter((recommendation) => {
          if (
            searchExpression &&
            recommendation?.alertConfigurationName?.toLowerCase().indexOf(searchExpression) === -1
          ) {
            return false;
          }
          return true;
        });
      }
    }
    setFilteredRecommendations(filteredList);
  }, [recommendations, queryParams]);

  const getRecommendationsCount = () => {
    if (recommendations?.length >= 0 && filteredRecommendations?.length >= 0) {
      return filteredRecommendations?.length === recommendations?.length
        ? `(${recommendations.length})`
        : `(${filteredRecommendations.length}/${recommendations.length})`;
    }
    return '(...)';
  };

  const orderFilteredList = (orderedList) => {
    setFilteredRecommendations(orderedList);
  };

  return (
    <RecommendationsProvider>
      <PageLayout header={<RecommendationsHeader count={getRecommendationsCount()} />}>
        <div className="display_flex flexGrow_1">
          <RecommendationsFilters queryParams={queryParams} setQueryParams={setQueryParams} />

          <div className="flexGrow_1 mb_2-5">
            {recommendationsResult?.isLoading === true ? (
              <div className="display_flex justifyContent_center alignItems_center">
                <Spinner color="#3d4c59" size={SIZES.XX_BIG_150} />
              </div>
            ) : (
              <>
                {recommendationsResult?.isLoading === false &&
                (recommendations?.length === 0 || recommendationsResult?.isError === true) ? (
                  <RecommendationsEmptyState
                    alertsConfigurationCount={recommendations?.length}
                    isError={recommendationsResult?.isError === true}
                  />
                ) : (
                  <RecommendationsList
                    filteredRecommendations={filteredRecommendations}
                    orderFilteredList={orderFilteredList}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </PageLayout>
    </RecommendationsProvider>
  );
};
export default Recommendations;
