import {get, omitBy} from 'lodash';
import {
  ACK_OPTIONS,
  ALERT_CONSOLE_QUERY_PARAMS_KEYS,
  ALLOWED_FILTER_KEYS,
  DEFAULT_QUERY_PARAMS,
  FILTER_KEY_HEADERS,
} from 'alerts.console.new/services/alertsConsoleService';

const EMPTY_ARRAY = [];

const USERS_HEADER = {
  label: 'USERS',
  value: 'USERS',
  type: 'HEADER',
  headerClass: 'andt-dropdown-option-selected-signed-header',
};

const USERS_UNASSIGNED = {
  label: 'Unassigned',
  value: 'NONE',
  type: 'UNASSIGNED',
  headerClass: 'andt-dropdown-option-selected-signed-header',
};

const CHANNELS_AND_USERS = () => ({
  WEBHOOK: {
    label: 'Webhook',
    type: 'WEBHOOK',
    data: [],
    icon: 'icn-webhook-grey-9-icon',
  },
  PAGER_DUTY: {
    label: 'Pager_Duty',
    type: 'PAGER_DUTY',
    data: [],
    icon: 'icn-other16-pagerduty',
  },
  EMAIL: {
    label: 'Email',
    type: 'EMAIL',
    data: [],
    icon: 'icn-general16-mail',
  },
  SLACK: {
    label: 'Slack',
    type: 'SLACK',
    data: [],
    icon: 'icn-other16-slack',
  },
  MATTERMOST: {
    label: 'Mattermost',
    type: 'MATTERMOST',
    data: [],
    icon: 'icn-mattermost_16',
  },
  SERVICENOW: {
    label: 'Service Now (Incident)',
    type: 'SERVICENOW',
    data: [],
    icon: 'icn-servicenow_16',
  },
  JIRA: {
    label: 'Jira',
    type: 'JIRA',
    data: [],
    icon: 'icn-other16-jira',
  },
  USERS: {
    label: 'Users',
    type: 'USERS',
    data: [],
    icon: 'icn-users-grey-9-icon',
  },
  OPS_GENIE: {
    label: 'Opsgenie',
    type: 'OPS_GENIE',
    data: [],
    icon: 'icn-other16-opsgenie',
  },
  MS_TEAMS: {
    label: 'MS Teams',
    type: 'MS_TEAMS',
    data: [],
    icon: 'icn-other16-opsgenie',
  },
  TAMTAM: {
    label: 'Tam Tam',
    type: 'TAMTAM',
    data: [],
    icon: 'icn-other16-tamtam',
  },
  TELEGRAM: {
    label: 'Telegram',
    type: 'TELEGRAM',
    data: [],
    icon: 'icn-other16-telegram',
  },
  SNS: {
    label: 'SNS',
    type: 'SNS',
    data: [],
    icon: 'icn-other16-sns',
  },
  SLACKAPP: {
    label: 'Slack App',
    type: 'SLACKAPP',
    data: [],
    icon: 'icn-other16-slack',
  },
  SALESFORCE: {
    label: 'Salesforce',
    type: 'SALESFORCE',
    data: [],
    icon: 'icn-salesforce_16',
  },
});

const findAvailableItems = (filterName, values = '') => {
  const selectedItems = ALLOWED_FILTER_KEYS[filterName].filter((item) => values.indexOf(item.value) !== -1);
  return selectedItems && selectedItems.length !== 0 ? selectedItems : null;
};

const aggregateFilterValues = (values) => {
  switch (values.length) {
    case 1:
      return values[0].label;
    case 2:
      return `${values[0].label}, ${values[1].label}`;
    default:
      return `${values[0].label}, ${values[1].label}, +${values.length - 2}`;
  }
};

const findAcknowledgementOptions = (changedAcknowledgement) => {
  let ackFilter;
  if (changedAcknowledgement === ACK_OPTIONS.ACK_BY.value) {
    ackFilter = ACK_OPTIONS.ACK_BY.label;
  } else if (changedAcknowledgement === ACK_OPTIONS.ACK_BY_ME.value) {
    ackFilter = ACK_OPTIONS.ACK_BY_ME.label;
  } else if (changedAcknowledgement === ACK_OPTIONS.NOT_ACK_BY.value) {
    ackFilter = ACK_OPTIONS.NOT_ACK_BY.label;
  }
  return ackFilter;
};

// Assignee Filter
export const getUsersDataForAvatar = (users, groupsData) => {
  let usersDataForAvatar = EMPTY_ARRAY;
  if (users?.length) {
    usersDataForAvatar = users
      .map((user) => ({
        ...user,
        defaultGroup: groupsData?.find((gr) => gr.id === user?.defaultGroup) || user?.defaultGroup,
      }))
      .sort((a, b) => a.fullName?.toLowerCase().localeCompare(b.fullName?.toLowerCase()));
  }
  return usersDataForAvatar;
};

export const getUsersDataForAssigneeFilter = (users, meProfile, groupsData, assignee) => {
  let usersDataForAssigneeFilter = EMPTY_ARRAY;
  if (users?.length) {
    const usersDataForAvatar = getUsersDataForAvatar(users, groupsData);
    if (usersDataForAvatar?.length) {
      usersDataForAssigneeFilter = usersDataForAvatar.map((o) => ({
        label: o.fullName,
        value: o.id,
        isDisabled: false,
        type: 'USERS',
      }));

      if (usersDataForAssigneeFilter?.length) {
        const meUser = usersDataForAssigneeFilter.find((user) => user.value === meProfile?._id);
        if (meUser) {
          meUser.label = `me (${meUser.label})`;
        }

        usersDataForAssigneeFilter.unshift(USERS_HEADER);
        usersDataForAssigneeFilter.unshift(USERS_UNASSIGNED);

        const queryParamsAssignee = assignee;
        let selectedUsers = [];
        if (queryParamsAssignee) {
          if (queryParamsAssignee === USERS_UNASSIGNED.value) {
            selectedUsers.push(USERS_UNASSIGNED);
          } else {
            selectedUsers = usersDataForAssigneeFilter.filter((user) =>
              (queryParamsAssignee || '').includes(user?.value),
            );
          }
        }

        if (!selectedUsers?.length) {
          for (let i = 0; i < usersDataForAssigneeFilter?.length; i++) {
            usersDataForAssigneeFilter[i].isDisabled = false;
          }
        } else if (selectedUsers.find((item) => item.type === USERS_UNASSIGNED.type)) {
          for (let i = 0; i < usersDataForAssigneeFilter?.length; i++) {
            if (
              usersDataForAssigneeFilter[i].type !== USERS_UNASSIGNED.type &&
              usersDataForAssigneeFilter[i].type !== USERS_HEADER.type
            ) {
              usersDataForAssigneeFilter[i].isDisabled = true;
            }
          }
        } else {
          usersDataForAssigneeFilter = usersDataForAssigneeFilter?.filter(
            (item) => item.type !== USERS_UNASSIGNED.type,
          );
        }
      }
    }
  }
  return usersDataForAssigneeFilter;
};

// Channels Filter
export const getChannelsAndUsers = (users = [], channels = []) => {
  const newUsersArr = [];
  const newChannelsArr = [];
  const channelsAndUsersConst = CHANNELS_AND_USERS();
  if (users?.length) {
    users.forEach((user) => {
      if (user?.id) {
        newUsersArr.push({
          label: user.fullName,
          value: user.id,
          type: 'USERS',
          email: user.email,
          icon: channelsAndUsersConst.USERS.icon,
        });
      }
    });
  }
  if (channels?.length) {
    channels.forEach((channel) => {
      if (channel?.id && channel.name && channel.channelMeta?.type && channel.state === 'ACTIVE') {
        newChannelsArr.push({
          label: channel.name,
          value: channel.id,
          type: channel.channelMeta.type,
          icon: channelsAndUsersConst[channel.channelMeta.type].icon,
        });
      }
    });
  }
  return [...newUsersArr, ...newChannelsArr];
};

export const getSortedFlattenChannels = (users, channels) => {
  const sortedFlattenArr = [];
  const channelsAndUsersConst = CHANNELS_AND_USERS();

  function sorting(arr) {
    return arr?.sort((a, b) => a?.label?.toLowerCase()?.localeCompare(b?.label?.toLowerCase()));
  }

  if (users?.length || channels?.length) {
    const channelsAndUsers = getChannelsAndUsers(users, channels);

    if (channelsAndUsers?.length > 0) {
      channelsAndUsers.forEach((i) => {
        if (channelsAndUsersConst[i.type]) {
          channelsAndUsersConst[i.type].data.push(i);
        }
      });
      Object.values(channelsAndUsersConst).forEach((i) => {
        if (i.data?.length > 0) {
          sortedFlattenArr.push({
            label: i.label,
            value: i.type,
            type: 'HEADER',
            icon: i.icon,
            color: i.color,
          });
          sortedFlattenArr.push(...sorting(i.data));
        }
      });
    }
  }
  return sortedFlattenArr;
};

export const getSelectedItems = (sortedFlattenChannels, queryParams) => {
  if (sortedFlattenChannels?.length) {
    const combineIds = `${queryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.CHANNELS] ?? ''},${queryParams[
      ALERT_CONSOLE_QUERY_PARAMS_KEYS.SUBSCRIBERS
    ] ?? ''}`;

    const selectedItems = sortedFlattenChannels.filter((item) => combineIds?.indexOf(item.value) !== -1);
    return selectedItems?.length > 0 ? selectedItems : null;
  }
  return null;
};

// users is a result of getUsersDataForAvatar
export const getSelectedAssignees = (users, queryParams) => {
  const selectedItems = users
    .filter((user) => (queryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.ASSIGNEES] || []).indexOf(user.id) !== -1)
    .map((o) => ({
      label: o.fullName,
      value: o.id,
    }));
  return selectedItems && selectedItems.length !== 0 ? selectedItems : null;
};

// getQueryParamsTagsViews,
export const getSelectedTags = (tags) => {
  return tags
    ? tags.split(',').map((tag) => {
        return {label: tag, value: tag};
      })
    : null;
};

export const getFiltersButtonTooltipItems = (queryParams, sortedFlattenChannels, usersDataForAvatar) => {
  const filterItemsList = [];
  const changedFilters = omitBy(queryParams, (value, key) => value === DEFAULT_QUERY_PARAMS[key]);
  const usersAndChannels = getSelectedItems(sortedFlattenChannels, queryParams);
  const assignees = getSelectedAssignees(usersDataForAvatar, queryParams);
  const tags = getSelectedTags(queryParams.labels);

  Object.keys(changedFilters).forEach((filterKey) => {
    const item = {
      id: get(FILTER_KEY_HEADERS[filterKey], 'id', null),
      header: get(FILTER_KEY_HEADERS[filterKey], 'label', null),
    };

    switch (filterKey) {
      case FILTER_KEY_HEADERS.searchExpression.id:
        filterItemsList.push({
          ...item,
          value: changedFilters[filterKey],
        });
        break;
      case FILTER_KEY_HEADERS.constRange.id:
        filterItemsList.push({
          ...item,
          value: ALLOWED_FILTER_KEYS[filterKey][changedFilters[filterKey]]?.text,
        });
        break;
      case FILTER_KEY_HEADERS.isOpen.id:
        if (changedFilters[filterKey].toString() === 'true') {
          filterItemsList.push({
            ...item,
            value: ALLOWED_FILTER_KEYS[filterKey]?.find((p) => p.value === changedFilters[filterKey]?.toString())?.text,
          });
        }
        break;
      case FILTER_KEY_HEADERS.feedback?.id:
        if (changedFilters[filterKey] !== '') {
          filterItemsList.push({
            ...item,
            value: ALLOWED_FILTER_KEYS[filterKey]?.find((obj) => obj.value === changedFilters[filterKey])?.label,
          });
        }
        break;
      case FILTER_KEY_HEADERS.types.id:
        filterItemsList.push({
          id: FILTER_KEY_HEADERS.types.id,
          header: FILTER_KEY_HEADERS.types.label,
          value: aggregateFilterValues(findAvailableItems(filterKey, changedFilters[filterKey])),
        });
        break;
      case FILTER_KEY_HEADERS.severities.id:
        filterItemsList.push({
          id: FILTER_KEY_HEADERS.severities.id,
          header: FILTER_KEY_HEADERS.severities.label,
          value: aggregateFilterValues(findAvailableItems(filterKey, changedFilters[filterKey])),
        });
        break;
      default:
        break;
    }

    if (Object.values(ACK_OPTIONS).find((obj) => obj.value === filterKey)) {
      // If filter already exists - remove it first.
      const searchExistingAckIndex = filterItemsList.indexOf(
        (filter) => filter.id === FILTER_KEY_HEADERS.acknowledgement.id,
      );
      if (searchExistingAckIndex > -1) {
        filterItemsList.splice(searchExistingAckIndex, 1);
      }
      filterItemsList.push({
        id: FILTER_KEY_HEADERS.acknowledgement.id,
        header: FILTER_KEY_HEADERS.acknowledgement.label,
        value: findAcknowledgementOptions(filterKey),
      });
    }
  });

  if (usersAndChannels?.length) {
    filterItemsList.push({
      id: FILTER_KEY_HEADERS.usersAndChannels.id,
      header: FILTER_KEY_HEADERS.usersAndChannels.label,
      value: aggregateFilterValues(usersAndChannels),
    });
  }
  if (assignees?.length) {
    filterItemsList.push({
      id: FILTER_KEY_HEADERS.assignees.id,
      header: FILTER_KEY_HEADERS.assignees.label,
      value: aggregateFilterValues(assignees),
    });
  }
  if (tags?.length) {
    filterItemsList.push({
      id: FILTER_KEY_HEADERS.labels.id,
      header: FILTER_KEY_HEADERS.labels.label,
      value: aggregateFilterValues(tags),
    });
  }

  return filterItemsList;
};
