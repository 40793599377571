// @flow
import React from 'react';

import {Position, RootCloseWrapper, Modal} from 'react-overlays';
import {Popover} from 'react-bootstrap';
import './DropdownButton.module.scss';

type PropTypes = {
  children: Node,
  isDropdownVisible: Function,
};

class DropdownButton extends React.PureComponent {
  props: PropTypes;

  state = {button: null, isDropdownVisible: false};

  handleClick = (event) => {
    this.setState({isDropdownVisible: true});
    this.props.isDropdownVisible(true);
    event.stopPropagation();
  };

  handleRootClose = () => {
    this.setState({isDropdownVisible: false});
    this.props.isDropdownVisible(false);
  };

  render() {
    const {children} = this.props;
    const {button, isDropdownVisible} = this.state;

    return (
      <div styleName="root">
        <button
          type="button"
          automation-id="dataCollectorDropdownButton"
          ref={(btn) => !this.state.button && this.setState({button: btn})}
          onClick={this.handleClick}
          className="btn btn-flat btn-icon"
          styleName="button"
        >
          <i className="icon icn-icon-white-dropdown" />
        </button>
        <Modal show={isDropdownVisible} backdrop={false} automation-id="dataCollectorPopover">
          <RootCloseWrapper onRootClose={this.handleRootClose} event="mousedown">
            <Position placement="bottom" target={button}>
              <Popover id="popover" styleName="popover" tabIndex={-1}>
                {React.Children.map(children, (child) =>
                  React.cloneElement(child, {
                    ...child.props,
                    onClick: (ev) => {
                      this.handleRootClose();
                      child.props.onClick(ev);
                    },
                  }),
                )}
              </Popover>
            </Position>
          </RootCloseWrapper>
        </Modal>
      </div>
    );
  }
}

export default DropdownButton;
