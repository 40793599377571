import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import CompositeBuildersContainer from 'metrics/components/metricExplorer/CompositeBuildersContainer';
import {emptyExpressionTree} from 'metrics/store/reducers/expressionBuilderReducer';
import {initExpressionTreeModel} from 'metrics/store/actions';
import {Expandable} from 'common/componentsV2/ExpandableSections';
import './SettingsContainer.module.scss';

const SettingsContainer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      initExpressionTreeModel({
        expressionTree: emptyExpressionTree,
      }),
    );
  }, []);
  return (
    <div styleName="container">
      <Expandable
        automationId="compositeBuilderExpandable"
        label="Metric Expression"
        extraStyle={{fontWeight: 300, fontSize: 22}}
        collapsedBottomSpace={20}
        expanded
        reverse
      >
        <div className="mt_2 mb_4">
          <CompositeBuildersContainer isModal />
        </div>
      </Expandable>
      <Expandable
        automationId="infoExpandable"
        label="Info"
        extraStyle={{fontWeight: 300, fontSize: 22}}
        collapsedBottomSpace={20}
        expanded
        reverse
      >
        <div className="mt_2 mb_4">info section</div>
      </Expandable>
    </div>
  );
};

export default SettingsContainer;
