// @flow
import React from 'react';
import TabItem from './TabItem';
import './InvTabsBar.module.scss';
import DownloadTimelineCSV from './DownloadTimelineCSV';

type PropTypes = {
  tabs: Array,
  selectedTabId: String,
  onTabClick: Function,
};
const InvTabsBar = ({tabs, selectedTabId, onTabClick}: PropTypes) => {
  return (
    <div styleName="container">
      <div styleName="buttons">
        {(tabs || []).map((tab) => (
          <TabItem
            isEnabled={tab.isEnabled}
            label={tab.label}
            id={tab.id}
            onClick={onTabClick}
            icon={tab.icon}
            isSelected={selectedTabId === tab.id}
            key={`inv-tab-bar-${tab.id}`}
          />
        ))}
      </div>
      {selectedTabId === 'timeline' && <DownloadTimelineCSV />}
    </div>
  );
};

export default React.memo(InvTabsBar);
