// @flow
import React, {useCallback} from 'react';
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import ExpandedPanel from '../expandedPanel/ExpandedPanel';
import ChannelItemData from './ChannelItemData';
import ChannelItemActions from './ChannelItemActions';

const ChannelItem = ({
  channel,
  selectedItem,
  setSelectedItem,
  checkedItems,
  setCheckedItems,
  isRbacEnabled,
}: {
  channel: Object,
  selectedItem: Function,
  setSelectedItem: Function,
  checkedItems: Array,
  setCheckedItems: Function,
  isRbacEnabled: Boolean,
}) => {
  const toggleChecked = useCallback(
    (id) => {
      setCheckedItems(checkedItems.includes(id) ? checkedItems.filter((i) => i !== id) : [...checkedItems, id]);
    },
    [checkedItems],
  );

  return (
    <div className="display_flex" id={`scrollPosition-${channel.id}`}>
      <TableListItem
        automationId={`channelList-${channel.id}`}
        key={channel.id}
        id={channel.id}
        style={{width: '100%'}}
        headerComponent={<ChannelItemData channel={channel} isRbacEnabled={isRbacEnabled} />}
        expandedPanel={<ExpandedPanel channel={channel} />}
        checkboxVisibility={
          checkedItems.length > 0 ? CHECKBOX_VISIBILITY_TYPES.alwaysShow : CHECKBOX_VISIBILITY_TYPES.onHover
        }
        setSelectedItem={(id) => (id === selectedItem ? setSelectedItem() : setSelectedItem(id))}
        isSelected={selectedItem === channel.id}
        onCheckboxChange={() => toggleChecked(channel.id)}
        isCheckboxChecked={!!checkedItems.find((i) => channel.id === i)}
        actionToolbarComponent={<ChannelItemActions id={channel.id} />}
      />
    </div>
  );
};

export default ChannelItem;
