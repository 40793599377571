// @flow
import React, {useContext, useEffect, useMemo, useState} from 'react';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {
  ALERT_CONSOLE_QUERY_PARAMS_KEYS,
  PAGINATION_MENU_OPTIONS,
} from 'alerts.console.new/services/alertsConsoleService';
import AlertConsoleQueryParamsContext from 'alerts.console.new/context';

import style from './AlertsListPagination.module.scss';

const AlertsListPagination = ({
  pageSize,
  setQueryParams,
  total,
}: {
  pageSize: Number,
  total: Number,
  setQueryParams: Function,
}) => {
  const context = useContext(AlertConsoleQueryParamsContext);
  const pageNumber = Number(context.contextQueryParams.pageNum);

  const totalPages = useMemo(() => {
    return Math.ceil(total / pageSize) || 1;
  }, [total, pageSize]);

  const getIsOptionDisabled = (option) => {
    switch (option) {
      case PAGINATION_MENU_OPTIONS.NEWEST.value:
        return Number(pageNumber || null) === 0;
      case PAGINATION_MENU_OPTIONS.OLDEST.value:
        return Number(pageNumber || null) === totalPages - 1;
      default:
        return undefined;
    }
  };

  const getOptions = () => {
    const tmpOptions = [
      {...PAGINATION_MENU_OPTIONS.NEWEST, isDisabled: getIsOptionDisabled(PAGINATION_MENU_OPTIONS.NEWEST.value)},
      {...PAGINATION_MENU_OPTIONS.OLDEST, isDisabled: getIsOptionDisabled(PAGINATION_MENU_OPTIONS.OLDEST.value)},
    ];
    return tmpOptions;
  };

  const [options, setOptions] = useState(getOptions());

  useEffect(() => {
    const newOptions = getOptions();
    setOptions(newOptions);
  }, [pageNumber, total]);

  const hasNext = () => {
    return totalPages - 1 > pageNumber;
  };

  const hasPrevious = () => {
    return pageNumber > 0;
  };

  const onClickNext = () => {
    if (hasNext()) {
      setQueryParams({pageNum: 1 + pageNumber});
    }
  };

  const onClickPrevious = () => {
    if (hasPrevious()) {
      setQueryParams({pageNum: pageNumber - 1});
    }
  };

  const itemSelected = async (event) => {
    if (event.value) {
      switch (event.value) {
        case PAGINATION_MENU_OPTIONS.NEWEST.value:
          setQueryParams({
            [ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_NUM]: 0,
          });
          break;
        case PAGINATION_MENU_OPTIONS.OLDEST.value:
          setQueryParams({
            [ALERT_CONSOLE_QUERY_PARAMS_KEYS.PAGE_NUM]: totalPages - 1,
          });
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={style.pagesContainer}>
      <div className={style.pageNumberContainer}>
        <div className={style.paginationMenu} role="presentation">
          <FormDdlSelect
            automationId="pagination-numbers"
            isDisabledSorting
            className="alert-clickable-item"
            position="bottom"
            width={130}
            options={options}
            button={
              <div className={style.pageNumber}>
                <span className={style.currentPageNumber}>{pageNumber + 1}</span>
                {` / ${Math.ceil(total / pageSize)}`}
              </div>
            }
            optionComponent={<OptionComponentSimple />}
            onChange={itemSelected}
          />
        </div>
      </div>
      <div
        className={`icn-arrow16-chevronleft ${!hasPrevious() ? style.arrowDisabled : style.arrow}`}
        onClick={onClickPrevious}
      />
      <div
        className={`icn-arrow16-chevronright ${!hasNext() ? style.arrowDisabled : style.arrow}`}
        onClick={onClickNext}
      />
    </div>
  );
};

export default AlertsListPagination;
