// @flow
import React from 'react';
import Highlighter from 'react-highlight-words';
import {getFormattedMDH} from 'common/utils/dateService';
import {TYPES} from 'assets/services/service';
import Tooltip from 'common/componentsV2/Tooltip';

import './UserEventsTable.module.scss';

const UserEventsRowHeader = ({
  event,
  searchString,
  timeZoneName,
}: {
  event: Object,
  searchString: string,
  timeZoneName: string,
}) => {
  const eventDateNotSet = '(not set)';

  return (
    <div styleName="table-row-header" role="none">
      <div styleName="col-title">
        <div className="ellipsis">
          <Tooltip content={event?.title} placement="bottom" type={TYPES.BIG}>
            {/* This span is needed for the tooltip to be displayed in the correct location of the title, because of the Highlighter. */}
            <span>
              <Highlighter autoEscape searchWords={[searchString]} textToHighlight={event?.title} />
            </span>
          </Tooltip>
        </div>
      </div>
      <div styleName="col-category">{event?.category}</div>
      <div styleName="col-startDate">
        {event?.startDate ? getFormattedMDH(event?.startDate, timeZoneName) : eventDateNotSet}
      </div>
      <div styleName="col-endDate">
        {event?.endDate ? getFormattedMDH(event?.endDate, timeZoneName) : eventDateNotSet}
      </div>
    </div>
  );
};

export default UserEventsRowHeader;
