// @flow
import React, {PureComponent} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import {Portal} from '@material-ui/core';

type PropTypes = {};

export default class NotificationBar extends PureComponent {
  props: PropTypes;

  render() {
    return (
      <Portal>
        <ToastContainer
          position={toast.POSITION.BOTTOM_LEFT}
          icon={false}
          autoClose={10000}
          draggable={false}
          closeOnClick={false}
          closeButton={false}
          hideProgressBar
          className="notification-child-container"
        />
      </Portal>
    );
  }
}
