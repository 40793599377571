import {FEEDBACK_TYPE} from 'feedback/services/constants';
import {ALERT_TYPES, FEEDBACK_OPTIONS, SEVERITY} from 'alerts.console.new/services/alertsConsoleService';

export const shouldFetchData = (state) => {
  if (!state.data && !state.isLoading) {
    return true;
  }
  // Don't refresh data if the last refresh is less than 30 min
  return state.updateAt < Math.round(new Date().getTime() / 1000) - 1800;
};

export const panelSliding = {
  defaultStyle: {
    transition: 'right 300ms, box-shadow 300ms',
    right: '-400px',
    boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
  },
  transitionStyles: {
    entering: {
      right: '-400px',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
    entered: {
      right: '0',
      boxShadow: '0 0 14px 0 rgba(0, 0, 0, 0.2)',
    },
    exiting: {
      right: '0',
      boxShadow: '0 0 14px 0 rgba(0, 0, 0, 0.2)',
    },
    exited: {
      right: '-400px',
      boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
    },
  },
};

export const fadeOpacity = {
  duration: 1750, // magic number
  defaultStyle: {
    transition: 'opacity 350ms ease-in-out',
    opacity: 0,
  },
  transitionStyles: {
    entering: {opacity: 0},
    entered: {opacity: 1},
    exiting: {opacity: 0},
    exited: {opacity: 0},
  },
};

export const NUM_LAST_DAYS_OPTIONS = [
  {
    label: 'Last 7 Days',
    value: 7,
    id: 'numLastDays7',
    constRange: 'w1',
    segmentName: 'Select Dropdown 7d',
  },
  {
    label: 'Last 14 Days',
    value: 14,
    id: 'numLastDays14',
    constRange: 'w2',
    segmentName: 'Select Dropdown 14d',
  },
  {
    label: 'Last 30 Days',
    value: 30,
    id: 'numLastDays30',
    constRange: 'm1',
    segmentName: 'Select Dropdown 30d',
  },
];

export const INSIGHT_PANEL_GROUP_BY_OPTIONS = {
  DAILY: 'daily',
  SEVERITY: 'severity',
  ALERT_TYPE: 'alertType',
};

const getPercentage = (valueOf, allValues) => {
  if (isNaN(valueOf) || isNaN(allValues)) {
    return undefined;
  }
  if (allValues === 0) {
    return 0;
  }
  return Math.round((valueOf / allValues) * 100);
};

export const formatAlertsDistribution = (alertsByType, alertsBySeverity) => {
  const anomaly = alertsByType[ALERT_TYPES.ANOMALY] ?? null;
  const statics = alertsByType[ALERT_TYPES.STATIC] ?? null;
  const noData = alertsByType[ALERT_TYPES.NO_DATA] ?? null;

  const critical = alertsBySeverity[SEVERITY.CRITICAL.label] ?? 0;
  const high = alertsBySeverity[SEVERITY.HIGH.label] ?? 0;
  const medium = alertsBySeverity[SEVERITY.MEDIUM.label] ?? 0;
  const low = alertsBySeverity[SEVERITY.LOW.label] ?? 0;
  const info = alertsBySeverity[SEVERITY.INFO.label] ?? 0;

  const total = anomaly + statics + noData;

  return {
    byType: [
      {
        name: 'Anomalies',
        y: anomaly,
        percentage: getPercentage(anomaly, total),
        id: ALERT_TYPES.ANOMALY,
      },
      {
        name: 'Statics',
        y: statics,
        percentage: getPercentage(statics, total),
        id: ALERT_TYPES.STATIC,
      },
      {
        name: 'No Data',
        y: noData,
        percentage: getPercentage(noData, total),
        id: ALERT_TYPES.NO_DATA,
      },
    ],
    bySeverity: [
      {
        name: 'Critical',
        y: critical,
        percentage: getPercentage(critical, total),
        id: SEVERITY.CRITICAL.value,
      },
      {
        name: 'High',
        y: high,
        percentage: getPercentage(high, total),
        id: SEVERITY.HIGH.value,
      },
      {
        name: 'Medium',
        y: medium,
        percentage: getPercentage(medium, total),
        id: SEVERITY.MEDIUM.value,
      },
      {
        name: 'Low',
        y: low,
        percentage: getPercentage(low, total),
        id: SEVERITY.LOW.value,
      },
      {
        name: 'Info',
        y: info,
        percentage: getPercentage(info, total),
        id: SEVERITY.INFO.value,
      },
    ],
  };
};

export const formatFeedbackDistribution = (feedbackDistribution) => {
  const goodCatch = feedbackDistribution?.goodCatchCount ?? 0;
  const notInteresting = feedbackDistribution?.notInterestingCount ?? 0;
  const all = goodCatch + notInteresting;

  return [
    {
      id: FEEDBACK_TYPE.GOOD_CATCH,
      y: goodCatch,
      percentage: getPercentage(goodCatch, all),
      name: FEEDBACK_OPTIONS.POSITIVE.label,
      type: FEEDBACK_OPTIONS.POSITIVE.value,
    },
    {
      id: FEEDBACK_TYPE.NOT_INTERESTING,
      y: notInteresting,
      percentage: getPercentage(notInteresting, all),
      name: FEEDBACK_OPTIONS.NEGATIVE.label,
      type: FEEDBACK_OPTIONS.NEGATIVE.value,
    },
  ];
};

export const formatFeedbackPerMeasure = (feedbackPerMeasure) => {
  const feedbackPerMeasureDataGroups = feedbackPerMeasure?.topMeasures ?? null;
  if (!feedbackPerMeasureDataGroups) {
    return [];
  }
  let primaryArr = [];
  let secondArr = [];
  feedbackPerMeasureDataGroups.forEach((item, index) => {
    const goodCatch = item?.goodCatchCount ?? null;
    const notInteresting = item?.notInterestingCount ?? null;
    const newItem = [
      {
        id: `goodCatch${item.name}`,
        y: goodCatch,
        name: item.name,
        type: 'goodCatch',
        typeName: 'Good Catch',
      },
      {
        id: `notInteresting${item.name}`,
        y: notInteresting,
        name: item.name,
        type: 'notInteresting',
        typeName: 'Not Interesting',
      },
    ];
    if (index !== 0 && index % 3 === 0) {
      primaryArr = [...primaryArr, secondArr];
      secondArr = [];
      secondArr.push(newItem);
    } else {
      secondArr.push(newItem);
    }
  });
  if (secondArr.length) {
    primaryArr = [...primaryArr, secondArr];
  }
  return primaryArr;
};

export const formatTopFeedbackers = (feedbackPerMeasure) => {
  const topFeedbackers = Array.isArray(feedbackPerMeasure) ? feedbackPerMeasure.topFeedbackers : [];
  return topFeedbackers.filter((i) => i.name);
};

export const formatNoFeedbackAlerts = (noFeedbackAlerts) => {
  const newArr = [];
  if (Array.isArray(noFeedbackAlerts?.triggers)) {
    noFeedbackAlerts.triggers.forEach((alertGroup) => {
      alertGroup.alerts.forEach((alert) => {
        newArr.push({...alert});
      });
    });
  }
  return newArr;
};
