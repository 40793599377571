// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import SearchableDdl from 'common/components/SearchableDdl';
import {
  fetchMParticleEventTypes as fetchMParticleEventTypesAction,
  setMParticleStreamEventType,
} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isStreamEditable} from 'bc/services/dataStreamService';
import './MParticleStreamEditor.module.scss';
import {THEME_LIGHT} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

const EMPTY_EVENT_TYPE = {type: ''};

type PropTypes = {
  dataStream: Object,

  // connect
  eventTypes: Array,
  isEventTypesLoading: boolean,

  setMParticleStreamEventType: Function,
  fetchMParticleEventTypes: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    eventTypes: selectors.getMParticleEventTypesItems(state),
    isEventTypesLoading: selectors.getMParticleEventTypesIsLoading(state),
  }),
  {
    setMParticleStreamEventType,
    fetchMParticleEventTypes: fetchMParticleEventTypesAction,
  },
)(
  class EventTypes extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {fetchMParticleEventTypes} = this.props;
      fetchMParticleEventTypes();
    }

    eventTypeChanged = (item) => {
      this.props.setMParticleStreamEventType({eventType: item.type});
    };

    render() {
      const {dataStream, eventTypes, isEventTypesLoading} = this.props;
      const activeEventType = eventTypes.find((evnType) => evnType.type === dataStream.eventType);

      return (
        <div className="shell-col">
          <PanelHeader title="Event Type" isEditable={isStreamEditable(dataStream.state)} />

          <SearchableDdl
            automationId="eventsType"
            items={eventTypes}
            styleName="event-type-ddl"
            theme="white"
            value={activeEventType || EMPTY_EVENT_TYPE}
            isLoading={isEventTypesLoading}
            title="Choose Event Type"
            labelKey="displayName"
            optionHeight={40}
            dropDownTheme={THEME_LIGHT}
            onChange={this.eventTypeChanged}
          />
        </div>
      );
    }
  },
);
