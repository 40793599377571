import {StringParam, withDefault} from 'use-query-params';
import {rangeTypes} from 'common/utils/dateRangeService';

const EMPTY_ARRAY = [];

export const PARAMS_NAME = {
  CONST_RANGE: 'constRange',
  USERS: 'userId',
  ACTION_TYPE: 'actionType',
  ENTITY_TYPE: 'entityType',
  ENTITY_ID: 'entityId',
  ALERT: 'alert',
  METRIC_STREAM: 'metricStream',
  EVENTS_STREAM: 'eventStream',
  CHANNEL: 'channel',
  SOURCE: 'source',
  PIPELINE: 'pipeline',
  USER: 'user',
  USER_GROUP: 'group',
  DYNAMIC_ROUTING: 'Dynamic Routing',
};

export const ENTITY_TYPE_PARAMS = [
  PARAMS_NAME.ALERT,
  PARAMS_NAME.METRIC_STREAM,
  PARAMS_NAME.EVENTS_STREAM,
  PARAMS_NAME.CHANNEL,
  PARAMS_NAME.SOURCE,
  PARAMS_NAME.PIPELINE,
  PARAMS_NAME.USER,
  PARAMS_NAME.USER_GROUP,
];

export const DEFAULT_PARAMS = {
  [PARAMS_NAME.CONST_RANGE]: rangeTypes.w1.value,
  [PARAMS_NAME.USERS]: undefined,
  [PARAMS_NAME.ACTION_TYPE]: undefined,
  [PARAMS_NAME.ENTITY_TYPE]: undefined,
  [PARAMS_NAME.ENTITY_ID]: undefined,
  [PARAMS_NAME.ALERT]: undefined,
  [PARAMS_NAME.METRIC_STREAM]: undefined,
  [PARAMS_NAME.EVENTS_STREAM]: undefined,
  [PARAMS_NAME.CHANNEL]: undefined,
  [PARAMS_NAME.SOURCE]: undefined,
  [PARAMS_NAME.PIPELINE]: undefined,
  [PARAMS_NAME.USER]: undefined,
  [PARAMS_NAME.USER_GROUP]: undefined,
};

export const DEFAULT_PARAMS_CONFIG = {
  [PARAMS_NAME.CONST_RANGE]: withDefault(StringParam, rangeTypes.w1.value),
  [PARAMS_NAME.USERS]: StringParam,
  [PARAMS_NAME.ACTION_TYPE]: StringParam,
  [PARAMS_NAME.ENTITY_TYPE]: StringParam,
  [PARAMS_NAME.ENTITY_ID]: StringParam,
  [PARAMS_NAME.ALERT]: StringParam,
  [PARAMS_NAME.METRIC_STREAM]: StringParam,
  [PARAMS_NAME.EVENTS_STREAM]: StringParam,
  [PARAMS_NAME.CHANNEL]: StringParam,
  [PARAMS_NAME.SOURCE]: StringParam,
  [PARAMS_NAME.PIPELINE]: StringParam,
  [PARAMS_NAME.USER]: StringParam,
  [PARAMS_NAME.USER_GROUP]: StringParam,
};

export const ACTION_TYPE_VALUE = {
  CREATE: 'CREATE',
  EDIT: 'MODIFY',
  DELETE: 'DELETE',
};

export const OPTIONS_ACTION_TYPE = [
  {
    label: 'Edit',
    value: ACTION_TYPE_VALUE.EDIT,
  },
  {
    label: 'Create',
    value: ACTION_TYPE_VALUE.CREATE,
  },
  {
    label: 'Delete',
    value: ACTION_TYPE_VALUE.DELETE,
  },
];

export const ENTITY_TYPE_VALUE = {
  ALL: 'All',
  ALERTS: 'ALERT',
  CHANNELS: 'CHANNEL',
  DATA_SOURCE: 'BC_DATA_SOURCE',
  DYNAMIC_ROUTING: 'DYNAMIC_ROUTING',
  EVENTS_STREAM: 'EVENTS_STREAM',
  METRIC_STREAMS: 'BC_STREAM',
  PIPELINE: 'PIPELINE',
  USER: 'USER',
  USER_GROUP: 'USER_GROUP',
};

export const SYSTEM_USER = {
  label: 'Anodot',
  value: 'SYSTEM',
};

export const getNameEntityType = (value) => {
  switch (value) {
    case ENTITY_TYPE_VALUE.ALERTS:
      return PARAMS_NAME.ALERT;
    case ENTITY_TYPE_VALUE.EVENTS_STREAM:
      return PARAMS_NAME.EVENTS_STREAM;
    case ENTITY_TYPE_VALUE.METRIC_STREAMS:
      return PARAMS_NAME.METRIC_STREAM;
    case ENTITY_TYPE_VALUE.CHANNELS:
      return PARAMS_NAME.CHANNEL;
    case ENTITY_TYPE_VALUE.DATA_SOURCE:
      return PARAMS_NAME.SOURCE;
    case ENTITY_TYPE_VALUE.DYNAMIC_ROUTING:
      return PARAMS_NAME.DYNAMIC_ROUTING;
    case ENTITY_TYPE_VALUE.PIPELINE:
      return PARAMS_NAME.PIPELINE;
    case ENTITY_TYPE_VALUE.USER:
      return PARAMS_NAME.USER;
    case ENTITY_TYPE_VALUE.USER_GROUP:
      return PARAMS_NAME.USER_GROUP;
    default:
      return null;
  }
};

export const getEntityTypeByParamName = (value) => {
  switch (value) {
    case PARAMS_NAME.ALERT:
      return ENTITY_TYPE_VALUE.ALERTS;
    case PARAMS_NAME.METRIC_STREAM:
      return ENTITY_TYPE_VALUE.METRIC_STREAMS;
    case PARAMS_NAME.EVENTS_STREAM:
      return ENTITY_TYPE_VALUE.EVENTS_STREAM;
    case PARAMS_NAME.CHANNEL:
      return ENTITY_TYPE_VALUE.CHANNELS;
    case PARAMS_NAME.SOURCE:
      return ENTITY_TYPE_VALUE.DATA_SOURCE;
    case PARAMS_NAME.DYNAMIC_ROUTING:
      return ENTITY_TYPE_VALUE.DYNAMIC_ROUTING;
    case PARAMS_NAME.PIPELINE:
      return ENTITY_TYPE_VALUE.PIPELINE;
    case PARAMS_NAME.USER:
      return ENTITY_TYPE_VALUE.USER;
    case PARAMS_NAME.USER_GROUP:
      return ENTITY_TYPE_VALUE.USER_GROUP;
    default:
      return null;
  }
};

export const OPTIONS_ENTITY_TYPE = [
  {
    label: 'All',
    value: ENTITY_TYPE_VALUE.ALL,
  },
  {
    label: 'Alerts',
    value: ENTITY_TYPE_VALUE.ALERTS,
  },
  {
    label: 'Channels',
    value: ENTITY_TYPE_VALUE.CHANNELS,
  },
  {
    label: 'Metric Stream',
    value: ENTITY_TYPE_VALUE.METRIC_STREAMS,
  },
  {
    label: 'Event Stream',
    value: ENTITY_TYPE_VALUE.EVENTS_STREAM,
  },
  {
    label: 'Data Sources',
    value: ENTITY_TYPE_VALUE.DATA_SOURCE,
  },
  {
    label: 'Dynamic Routing',
    value: ENTITY_TYPE_VALUE.DYNAMIC_ROUTING,
  },
  {
    label: 'Pipelines',
    value: ENTITY_TYPE_VALUE.PIPELINE,
  },
  {
    label: 'Users',
    value: ENTITY_TYPE_VALUE.USER,
  },
  {
    label: 'Groups',
    value: ENTITY_TYPE_VALUE.USER_GROUP,
  },
];

export const getLabel = (options, value) =>
  (options.flatMap((o) => (value.includes(o.value) ? [o.label] : [])) || EMPTY_ARRAY).join(', ');

export const getOptionsMenuRow = (activityValue, userName, actionType) => {
  const options = [
    {
      value: activityValue,
      label: `Show only this ${activityValue}`,
    },
    {
      value: PARAMS_NAME.USERS,
      label: `Show only from user ${userName}`,
    },
  ];

  if (actionType === ACTION_TYPE_VALUE.EDIT) {
    options.unshift({
      value: ACTION_TYPE_VALUE.EDIT,
      label: 'Change Log',
    });
  }

  return options;
};

export const getNameActionType = (value) => {
  switch (value) {
    case ACTION_TYPE_VALUE.CREATE:
      return 'created';

    case ACTION_TYPE_VALUE.EDIT:
      return 'updated';

    case ACTION_TYPE_VALUE.DELETE:
      return 'deleted';

    default:
      return '';
  }
};

export const getEntityTypeText = ({actionType, entityType, entityDefinition}) => {
  if (entityType === ENTITY_TYPE_VALUE.CHANNELS && actionType === ACTION_TYPE_VALUE.CREATE) {
    return `a new ${entityDefinition.channelMeta.type} channel`;
  }

  if (entityType === ENTITY_TYPE_VALUE.CHANNELS) {
    return `${entityDefinition.channelMeta.type} channel`;
  }

  if (actionType === ACTION_TYPE_VALUE.CREATE) {
    return `a new ${getNameEntityType(entityType)}`;
  }

  return `the ${getNameEntityType(entityType)}`;
};
