// @flow
import React from 'react';
import {useDispatch} from 'react-redux';
import get from 'lodash/get';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

import {unimpersonate} from 'admin.users/store/actions';
import {queryClient} from 'reactQuery/queryClient';
import './ImpersonateMenuItem.module.scss';

type PropsTypes = {
  me: Object,
};
const ImpersonateMenuItem = React.memo(({me}: PropsTypes) => {
  const dispatch = useDispatch();

  const handleUnimpersonate = async () => {
    // gotoState('unimpersonate');
    dispatch(unimpersonate()); // TODO: eli - to activate for Anodot Org uncomment and remove the gotoState
    await queryClient.clear();
  };
  const firstName = get(me, 'firstName');
  const companyName = get(me, ['organization', 'name']);
  // TODO menny: replace this icon with new one
  const tooltipBody = (
    <div styleName="wrapper">
      <div styleName="tooltipTitle">Stop Impersonating</div>
      <div styleName="userInfo">{`${firstName} @ ${companyName}`}</div>
    </div>
  );

  return (
    <>
      <Tooltip content={tooltipBody} type={TYPES.NAV_BAR} placement="right" extraTtClass="impersonate" delay={50}>
        <div styleName="root" onClick={handleUnimpersonate}>
          <i className="icon icn-action24-stopimp" automation-id="reactStopImpersonatingIcon" />
        </div>
      </Tooltip>
    </>
  );
});

export default ImpersonateMenuItem;
