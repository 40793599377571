import React from 'react';
import {useField} from 'react-final-form';
import UsersGroupsSelect from 'admin.users/components/UsersGroupsSelect';
import {Box} from '@material-ui/core';
import TooltipArea from 'common/componentsV2/TooltipArea';

const Owner = () => {
  const {
    input: {value, onChange},
  } = useField('ownerId');
  return (
    <TooltipArea
      isAlwaysVisible
      automationId="ownerContainer"
      text="Choose an owner of the alert - either a user or a group. Only the owner(s) can edit this alert"
    >
      {(info) => (
        <React.Fragment>
          <div className="text16reg lineHeight_16 mb_1">
            Alert Owner
            {info}
          </div>
          <Box width={230} mb={1} css={{'& span[class*=invisible]': {display: 'none'}}}>
            <UsersGroupsSelect
              onChange={(group) => onChange(group.value)}
              value={value}
              disabled={false}
              isSameGroup={false}
              resourceName="alert"
              showGroupSubText
            />
          </Box>
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default Owner;
