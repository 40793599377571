import React from 'react';
import Properties from './properties/Properties';
import GoogleSearchScheduler from './schedular/GoogleSearchSchedular';
import DimensionsAndMetrics from './dimensionsAndMetrics/DimensionsAndMetrics';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

const GoogleSearchStreamEditor = () => (
  <div automation-id="googleSearchStreamEditor" className="shell-col">
    <StreamEditorSteps>
      <Properties />
      <DimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <GoogleSearchScheduler />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default GoogleSearchStreamEditor;
