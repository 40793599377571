import React, {useState, useCallback, useMemo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import useHandleResize from 'common/hooks/useHandleResize';
import {palette} from 'app/styles/theme';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import {TypographyBox} from 'common/componentsV2/boxTools';

import ContentLoader from 'common/componentsV2/ContentLoader';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';

import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import Tokens from './Tokens';

const TokenMapFilters = ({isLoading, tokenMap, selectedFilters, totalMetrics, metricsCounter, onSelect}) => {
  const style = {
    container: {
      position: 'relative',
    },
    anoMapContainer: {
      backgroundColor: palette.gray['100'],
      overflow: 'hidden',
      borderRadius: 10,
    },
    anoMap: {
      position: 'relative',
      left: 16,
      paddingRight: 32,
      overflowY: 'scroll',
    },
    panelRootStyle: {
      flexShrink: 0,
      padding: 0,
      border: 'none',
      marginTop: 24,
      marginBottom: 16,
    },
    anoMapLabel: {
      display: 'flex',
      alignItems: 'center',
      marginTop: -4,
      marginLeft: -5,
      marginBottom: 16,
    },
    gradBox: {
      background: 'linear-gradient(0deg, rgba(245,247,251,1) 15%, rgba(245,247,251,0) 100%)',
      width: '100%',
      height: '50px',
      position: 'absolute',
      bottom: 0,
    },
    anoMapMarginTop: {
      marginTop: 24,
    },
    tooltipContainer: {
      width: 'min-content',
    },
  };

  const [isOpen, setIsOpen] = useState('');
  const bodyContainerHeight = useHandleResize([330]);

  const onExpandBoxChange = useCallback(
    (id) => (isExpanded) => {
      setIsOpen((st) => {
        return {...st, [id]: isExpanded};
      });
    },
    [],
  );

  useEffect(() => {
    const first = Object.keys(tokenMap)[0];
    if (first) {
      setIsOpen((st) => {
        return {...st, [first]: true};
      });
    }
  }, [tokenMap]);

  const filteredTokenMap = useMemo(() => Object.keys(tokenMap).slice(0, 30), [tokenMap]);

  return (
    <div style={style.container}>
      <TypographyBox color={palette.gray['400']} variant="subtitle4" height={16}>
        {!isLoading && !isEmpty(tokenMap) && <span>Dimension Breakdown</span>}
      </TypographyBox>

      <div style={style.anoMapContainer}>
        {filteredTokenMap.length > 0 && (
          <div style={{...style.anoMap, height: bodyContainerHeight}}>
            {isLoading && (
              <div style={style.anoMapMarginTop}>
                <ContentLoader
                  rows={[
                    {width: 90, height: 27, marginBottom: 44},
                    {width: '100%', height: 20, marginBottom: 44},
                    {width: '100%', height: 20, marginBottom: 44},
                    {width: '100%', height: 20},
                  ]}
                />
              </div>
            )}
            {!isLoading &&
              filteredTokenMap.length &&
              filteredTokenMap.map((what) => (
                <div key={`anomap-${what}`}>
                  <ExpandableBox
                    automationId="incidentWhatGroup"
                    expanded={isOpen[what]}
                    onChange={onExpandBoxChange(what)}
                    nodeLabel={
                      <div style={style.anoMapLabel}>
                        <Tooltip content={what} type={TYPES.SMALL}>
                          <div style={style.tooltipContainer}>
                            <InnerChip title={what} displayOnly type="measure" />
                          </div>
                        </Tooltip>
                        {!!selectedFilters.length && !isLoading && (
                          <TypographyBox ml={1} variant="subtitle2" color={palette.teal['500']}>
                            {metricsCounter[what].anomalyOccurrences}/{totalMetrics[what].anomalyOccurrences} Metrics
                          </TypographyBox>
                        )}

                        {!selectedFilters.length && !isLoading && (
                          <TypographyBox ml={1} variant="subtitle2" color={palette.gray['400']}>
                            {metricsCounter[what].anomalyOccurrences} Metrics
                          </TypographyBox>
                        )}
                      </div>
                    }
                    rootStyle={style.panelRootStyle}
                  >
                    {isOpen[what] && <Tokens tokens={tokenMap[what]} filters={selectedFilters} onSelect={onSelect} />}
                  </ExpandableBox>
                </div>
              ))}
          </div>
        )}
      </div>
      <div style={style.gradBox} />
    </div>
  );
};

TokenMapFilters.defaultProps = {
  isLoading: undefined,
};

TokenMapFilters.propTypes = {
  isLoading: PropTypes.bool,
  tokenMap: PropTypes.objectOf(PropTypes.any).isRequired,
  selectedFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  metricsCounter: PropTypes.objectOf(PropTypes.object).isRequired,
  totalMetrics: PropTypes.objectOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default TokenMapFilters;
