import {cloneDeep} from 'lodash';
import {getUniqueId} from 'common/utils/guid';

// MAX_DISPLAYED_EVENTS was set to 25 by the Product. In Alert Console only up to 25 events will be displayed.
export const MAX_DISPLAYED_EVENTS = 25;

export const defaultEventFilter = {
  aggregation: {
    aggregationField: null,
    maxBuckets: null, // When maxBuckets is null - we get the events by the resolution of the alert configuration.
    resolution: 'long',
    // topEventSize: 10, // This parameter doesn't seem to have any affect when maxBuckets is null
  },
  filter: {
    categories: [],
    q: {
      expression: [],
    },
  },
  selectorsFilter: {
    selectors: [],
  },
};

export const getEventsFiltersDefaults = (eventsFilter, rollups) => {
  if (eventsFilter) {
    const modifiedEventsFilters = cloneDeep(eventsFilter);
    modifiedEventsFilters.id = getUniqueId();
    if (!modifiedEventsFilters.filter) {
      modifiedEventsFilters.filter = defaultEventFilter.filter;
    }
    if (!modifiedEventsFilters.selectorsFilter) {
      modifiedEventsFilters.selectorsFilter = defaultEventFilter.selectorsFilter;
    }
    if (modifiedEventsFilters.aggregation) {
      // Set maxBuckets to zero in order to see the events in the correct timeScale and not according to server buckets.
      modifiedEventsFilters.aggregation.maxBuckets = defaultEventFilter.aggregation.maxBuckets;
      // If resolution of events filter is not set, assign the rollups to it.q
      if (!modifiedEventsFilters.aggregation.resolution) {
        modifiedEventsFilters.aggregation.resolution = rollups || defaultEventFilter.aggregation.resolution;
      }
      // If top events size is set to zero - delete it, otherwise the server doesn't send events at all.
      // It also seems that when maxBuckets is set to null - topEvents parameter is ignored.
      if (modifiedEventsFilters.aggregation.topEventSize === 0) {
        const aggFilter = {...modifiedEventsFilters.aggregation};
        const {topEventSize, ...rest} = aggFilter;
        modifiedEventsFilters.aggregation = rest;
      }
    }
    return modifiedEventsFilters;
  }
  return eventsFilter;
};

const getMaxDisplayedNumberOfEventsHelper = (events) => {
  // Assuming the events (inside topEvents property of the items) are ordered by startDate, select 25 closest to incident events.
  let counter = 0;
  return events
    .map((item) => {
      return {
        ...item,
        topEvents: item.topEvents?.filter(() => {
          counter += 1;
          return counter <= MAX_DISPLAYED_EVENTS;
        }),
      };
    })
    .filter((item) => !!item.topEvents.length); // after taking out the events that are needed - take only the topEvents[] that contain at least one event.
};

export const getLimitedAlertEvents = (alertEvents, timeOfIncident) => {
  if (alertEvents?.length && alertEvents.flatMap((event) => event.topEvents)?.length > MAX_DISPLAYED_EVENTS) {
    let beforeIncidentEvents = alertEvents.filter((item) => item.date <= timeOfIncident);
    beforeIncidentEvents = beforeIncidentEvents?.map((item) => ({
      ...item,
      topEvents: item.topEvents.filter((event) => event.startDate <= timeOfIncident),
    }));
    // Events with startDate prior to anomaly - take these events.
    if (beforeIncidentEvents?.length > 0) {
      beforeIncidentEvents = beforeIncidentEvents.sort((a, b) => b.date - a.date);
    }
    beforeIncidentEvents = getMaxDisplayedNumberOfEventsHelper(beforeIncidentEvents);

    let afterIncidentEvents = alertEvents.filter((item) => item.date > timeOfIncident);
    afterIncidentEvents = afterIncidentEvents?.map((item) => ({
      ...item,
      topEvents: item.topEvents.filter((event) => event.startDate > timeOfIncident),
    }));
    // Events with startDate prior to anomaly - take these events.
    if (afterIncidentEvents?.length > 0) {
      afterIncidentEvents = afterIncidentEvents.sort((a, b) => a.date - b.date);
    }
    afterIncidentEvents = getMaxDisplayedNumberOfEventsHelper(afterIncidentEvents);

    return [...beforeIncidentEvents, ...afterIncidentEvents];
  }
  return alertEvents;
};
