// @flow
import React from 'react';
import './RelativeTimeParameterInput.module.scss';
import SelectAndt, {THEME_DARK_LEAN, TYPE_NEW_LEAN} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

const options = [{label: 'Minutes', val: 60}, {label: 'Hours', val: 3600}, {label: 'Days', val: 86400}];

type PropTypes = {
  onValueChange: Function, // f = (value, isValid, [validationErrors]) => {...}
  onTouched: Function,
  value: number,
  isForceFocusIfAvailable: boolean,
};

// GABPAC TODO: Fix the this.props.value to get external values
export default class RelativeTimeParameterInput extends React.PureComponent {
  props: PropTypes;

  state = {
    ddValue: options[2],
    value: 10,
  };

  componentDidMount() {
    const ddValue = [...options].reverse().find((option) => this.props.value % option.val === 0);
    this.setState({value: this.props.value / ddValue.val, ddValue});
  }

  onDDChange = (val) => {
    const value = val;
    this.setState({ddValue: val});
    this.props.onValueChange(value.val * this.state.value, true, []);
  };

  onChange = (val) => {
    const reg = /^[0-9\b]+$/;

    const {value} = val.target;
    if (value === '' || reg.test(value)) {
      this.setState({value: val.target.value});
      this.props.onValueChange(val.target.value * this.state.ddValue.val, true, []);
    }
  };

  render() {
    return (
      <div styleName="container">
        <input
          styleName="value-input"
          onChange={this.onChange}
          value={this.state.value}
          placeholder="Value"
          type="text"
          onBlur={this.props.onTouched}
          autoFocus={this.props.isForceFocusIfAvailable}
        />
        <SelectAndt
          automationId="simpleAlertDimensions"
          type={TYPE_NEW_LEAN}
          theme={THEME_DARK_LEAN}
          onChange={this.onDDChange}
          options={options}
          value={this.state.ddValue}
          placeholder="Add Function"
          optionHeight={40}
          isClearable={false}
          closeMenuOnSelect
          menuWidth={311}
          onMenuOpen={this.props.onTouched}
          getOptionLabel={(val) => val.label}
          getOptionValue={(val) => val}
        />
      </div>
    );
  }
}
