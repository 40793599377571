// @flow
import React, {useEffect, useMemo, useState} from 'react';
import {fetchAlertMetricDataPoints, fetchAlertEvents, fetchAlertMultipleEvents} from 'alerts.console/store/actions';
import {CHART_HEIGHT, ANOMALY_BUFFER_TIME} from 'alerts.console/services/alertsConsoleService';
import {getEventsFiltersDefaults, getLimitedAlertEvents} from 'alerts.console/services/alertsConsoleEventsService';
import {useSelector, useDispatch} from 'react-redux';
import {getMetricResolutions} from 'metrics/store/selectors';
import {getBucketStartTimeEnabled, getEnableMultipleEvents} from 'profile/store/selectors';
import {getEventsResolution} from 'userEvents/store/selectors';
import {getAlertsManagementData} from 'alerts.management/store/selectors';
import MetricEventsChart from 'charts/timeSeries/containers/MetricEventsChart';
import {generateChartSeriesMetricModel} from 'charts/timeSeries/services/timeSeriesDataService';
import {getAckAlerts, getAlertAckEvents, getAlertNoAckEvents, getNoAckAlerts} from '../../../store/selectors';

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: true,
  hideMeetIcons: true,
};

type PropTypes = {
  id: string,
  chartEventsMeta: Object,
  metric: Object,
  alertId: string,
  uiGroupName: string,
  startDate: number,
  endDate: number,
  bucketStartTimeEnabled: boolean,
  timeScale: string,
  timeZoneName: string,
  index: number,
};

const AlertEventsChart = ({
  id,
  metric,
  chartEventsMeta,
  alertId,
  uiGroupName,
  bucketStartTimeEnabled,
  timeScale,
  timeZoneName,
  startDate,
  endDate,
  index,
}: PropTypes) => {
  const dispatch = useDispatch();
  const isUseMultipleEvents = useSelector(getEnableMultipleEvents);
  const resolutions = useSelector(getMetricResolutions);
  const startBucketMode = useSelector(getBucketStartTimeEnabled);
  const eventsResolution = useSelector(getEventsResolution);

  const anomalyAlerts = uiGroupName === 'ack' ? useSelector(getAckAlerts) : useSelector(getNoAckAlerts);
  const events = uiGroupName === 'ack' ? useSelector(getAlertAckEvents) : useSelector(getAlertNoAckEvents);
  const alertsConfigurations = useSelector(getAlertsManagementData);

  const [alertEventsFilter, setAlertEventsFilter] = useState(null);
  const [processedMetricsNames, setProcessedMetricsNames] = useState(null);

  const bufferTime = ANOMALY_BUFFER_TIME[timeScale] || 0;

  const timeOfIncident = metric.intervals[0].startTime;

  const alertEvents = events[chartEventsMeta.key]?.data?.events;

  const alertEventsConfiguration = useMemo(() => {
    const foundAlert = anomalyAlerts.alertGroups
      .flatMap((alertGroup) => alertGroup.alerts)
      .find((alert) => alert.id === alertId);
    if (foundAlert) {
      const foundAlertConfiguration = alertsConfigurations?.fetchAlertConfigurations?.items?.find(
        (item) => item.data.id === foundAlert.alertConfigurationId,
      );
      return foundAlertConfiguration;
    }
    return null;
  }, [JSON.stringify(anomalyAlerts.alertGroups), alertsConfigurations?.fetchAlertConfigurations?.items]);

  const getEventsFilters = () => {
    let tmpEventsFilters;
    if (isUseMultipleEvents) {
      tmpEventsFilters = alertEventsConfiguration?.data?.eventsFilters || null;
      if (tmpEventsFilters?.length) {
        tmpEventsFilters = tmpEventsFilters.map((f) =>
          getEventsFiltersDefaults(f, alertEventsConfiguration?.data?.rollups),
        );
      }
    } else {
      tmpEventsFilters = alertEventsConfiguration?.data?.eventsFilter || null;
      tmpEventsFilters = getEventsFiltersDefaults(tmpEventsFilters, alertEventsConfiguration?.data?.rollups);
    }
    return tmpEventsFilters;
  };

  const loadMetrics = (overrideChartId) => {
    // Get Metrics
    dispatch(
      fetchAlertMetricDataPoints(
        {
          metricId: metric.id,
          endDate: endDate + bufferTime,
          startDate,
          startBucketMode,
          includeBaseline: true,
          timeScale: Object.values(resolutions).find((a) => a.value2 === timeScale).value,
        },
        {
          ...chartEventsMeta,
          chartId: overrideChartId ?? chartEventsMeta.chartId,
          metricId: metric.id,
          intervals: metric.intervals,
          alertId,
        },
      ),
    );
  };

  const loadEvents = () => {
    // Get Events
    const alertConfigEventsFilters = getEventsFilters();
    if (alertConfigEventsFilters) {
      if (isUseMultipleEvents) {
        dispatch(
          fetchAlertMultipleEvents(
            {
              endDate: endDate + bufferTime,
              startDate,
              startBucketMode,
              body: alertConfigEventsFilters,
            },
            {
              ...chartEventsMeta,
              metricId: metric.id,
              intervals: metric.intervals,
              alertId,
            },
          ),
        );
      } else {
        dispatch(
          fetchAlertEvents(
            {
              endDate: endDate + bufferTime,
              startDate,
              startBucketMode,
              body: alertConfigEventsFilters,
            },
            {
              ...chartEventsMeta,
              metricId: metric.id,
              intervals: metric.intervals,
              alertId,
            },
          ),
        );
      }
    }
    // Set state is async, so couldn't use alertEventsFilter in the code above, thus initialize its state here.
    setAlertEventsFilter(alertEventsFilter);
  };

  const tooltipConditions = useMemo(() => {
    const alertConfigConditions = alertEventsConfiguration?.data?.conditions || null;
    if (alertConfigConditions?.length) {
      const significance = alertConfigConditions.find((item) => item.type === 'SIGNIFICANCE_CONDITION');
      const delta = alertConfigConditions.find((item) => item.type === 'DELTA_CONDITION');
      const duration = alertConfigConditions.find((item) => item.type === 'DURATION_CONDITION');
      return {significance, delta, duration};
    }
    return {};
  }, [JSON.stringify(alertEventsConfiguration)]);

  const onEventSelect = (ev) => {
    if (!ev.isFromModal) {
      loadMetrics('events-modal-chart'); // This is the name of the modal chart that is opened by clicking the event.
    }
  };

  useEffect(() => {
    loadEvents();
    loadMetrics();
  }, []);

  useEffect(() => {
    const metricsModelNames = generateChartSeriesMetricModel(metric);
    setProcessedMetricsNames({
      model: metricsModelNames,
      shouldProcessData: false,
    });
  }, [metric]);

  return (
    <MetricEventsChart
      id={id}
      eventsMeta={chartEventsMeta}
      disableActions={false}
      events={alertEvents}
      getLimitedAlertEvents={() => getLimitedAlertEvents(alertEvents, timeOfIncident)}
      shouldShowEvents={!!alertEvents?.length}
      eventsResolution={alertEventsFilter?.aggregation?.eventsResolution || eventsResolution}
      onEventSelect={onEventSelect}
      index={index}
      tooltip={TOOLTIP_OPTIONS}
      tooltipConditions={tooltipConditions}
      height={CHART_HEIGHT}
      bucketStartTimeEnabled={bucketStartTimeEnabled}
      timeScale={timeScale}
      timeZoneName={timeZoneName}
      theme="preview"
      fromDate={Math.ceil(startDate / 1000)}
      toDate={Math.ceil(endDate / 1000)}
      processedMetrics={processedMetricsNames}
      enableClientZoom
      timeOfIncident={timeOfIncident}
    />
  );
};

export default React.memo(AlertEventsChart);
