import {createSelector} from 'reselect';
import {selectors as commonSelectors} from 'common';
import {get} from 'lodash';

import {selectors as profileSelector} from 'profile';
import * as commonSelector from 'common/store/selectors';
import {getPageNameSegment} from 'common/utils/http';

export const {getAlertsConsoleNew} = commonSelectors;

// Views
export const getAlertConsoleViews = createSelector(
  getAlertsConsoleNew,
  (ac) => ac.views,
);

export const getQueryParamsViews = createSelector(
  getAlertConsoleViews,
  (views) => views.queryParams || {},
);

export const getQueryParamsTagsViews = createSelector(
  getQueryParamsViews,
  (qp) => qp.labels,
);

export const getScrollbarWidth = createSelector(
  getAlertConsoleViews,
  (acViews) => (acViews.scrollbarWidth ? acViews.scrollbarWidth * 2 : 0),
);

export const getIsListScrolled = createSelector(
  getAlertConsoleViews,
  (acViews) => acViews.isListScrolled,
);

export const getIsAlertsListScrolling = createSelector(
  getAlertConsoleViews,
  (acViews) => acViews.isListScrolled,
);

export const getIsMoreFilterOpen = createSelector(
  profileSelector.getMeAppSettings,
  (appSettings) => get(appSettings, 'alertsConsole.isMoreFilterOpen', false),
);

export const getIsFiltersBarOpen = createSelector(
  profileSelector.getMeAppSettings,
  (appSettings) => get(appSettings, 'alertsConsole.isFiltersBarOpen', true),
);

export const getAlertConsoleOpenItems = createSelector(
  getAlertConsoleViews,
  (acViews) => acViews.openItems,
);

export const getAlertConsoleInvestigationModal = createSelector(
  getAlertConsoleViews,
  (acViews) => acViews.investigationModal,
);

// Data
export const getAlertConsoleData = createSelector(
  getAlertsConsoleNew,
  (ac) => ac.data,
);

export const getQueryParamsData = createSelector(
  profileSelector.getMeAppSettings,
  (appSettings) => get(appSettings, 'alertsConsole.queryParams', {}),
);

export const getQueryParamsDataNewAlertConsole = createSelector(
  profileSelector.getMeAppSettings,
  (appSettings) => get(appSettings, 'alertsConsole.queryParamsNewAlertConsole', {}),
);

export const getAlertMetrics = createSelector(
  getAlertConsoleData,
  (ac) => ac.metrics,
);

export const getAlertEvents = createSelector(
  getAlertConsoleData,
  (ac) => ac.events,
);

export const getAlertsTriggeredTotal = createSelector(
  getAlertConsoleData,
  (data) => get(data, 'totalTriggeredAlerts.data.total', null),
);

export const getAlertsTriggeredTotalLastDay = createSelector(
  getAlertConsoleData,
  (data) => get(data, 'totalTriggeredAlertsLastDay.data.total', null),
);

export const getIsLoadingAlertsTriggeredTotalLastDay = createSelector(
  getAlertConsoleData,
  (data) => data.totalTriggeredAlertsLastDay.isLoading,
);

export const getIsFirstTimeVisit = createSelector(
  profileSelector.getPageVisited,
  commonSelector.getRoutingLocation,
  (pageVisited, getRoutingLocation) => {
    const isThisPageVisited = pageVisited.find((i) => getPageNameSegment(getRoutingLocation.pathname) === i.name);
    const date = new Date();
    const now = date.getTime();
    if (isThisPageVisited && isThisPageVisited.firstTimeSig) {
      const passingTime = isThisPageVisited ? now - isThisPageVisited.firstTimeSig : 0;
      return passingTime < 30 * 1000;
    }
    return true;
  },
);
