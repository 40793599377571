// @flow
import React, {useCallback, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Highlighter from 'react-highlight-words';

import SmartTooltip from 'common/components/SmartTooltip';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'profile/store/selectors';
import {omit, omitBy} from 'lodash';
import {palette} from 'app/styles/theme';
import {getAlertEditorV2Enabled} from 'alerts.management/store/selectors';
import {success as successAction} from 'common/utils/notifications/notificationsService';
import {clipboardCopied} from 'common/utils/notifications/generalNotificationCodes';
import NameFormat20Tooltip from './NameFormat20Tooltip';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  wrapper: {
    fontSize: 14,
    display: ({isSingleLine}) => (isSingleLine ? 'block' : 'flex'),
    flexWrap: 'wrap',
    maxHeight: ({isSingleLine}) => (isSingleLine ? 'auto' : '55px'),
    lineHeight: ({isSingleLine}) => (isSingleLine ? 'auto' : '25px'),
    overflow: 'hidden',
    minHeight: ({isSpecialHeader}) => (isSpecialHeader ? '25px' : 'inherit'),
  },
  highlightWrapper: {
    display: ({isSingleLine}) => (isSingleLine ? 'inline' : 'block'),
    height: ({isSpecialHeader}) => (isSpecialHeader ? '25px' : 'inherit'),
    fontWeight: 500,
    color: theme.palette.purple[600],
    cursor: 'default',
  },
  for: {
    height: ({isSpecialHeader}) => (isSpecialHeader ? '25px' : 'inherit'),
    marginRight: 4,
    marginLeft: 4,
    display: ({isSingleLine}) => (isSingleLine ? 'inline' : 'block'),
    color: theme.palette.gray[400],
    fontWeight: 'normal',
    pointerEvents: 'none',
  },
  tooltipBox: {
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    maxWidth: 493,
    borderRadius: 8,
    '&.tooltip': {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  customPlacement: {
    '&.tooltip': {
      marginTop: -18,
      marginLeft: -12,
    },
    '@global': {
      '.tooltip-inner': {
        position: 'relative',
        transform: 'translate(100%, -50%)',
      },
    },
  },
  tooltipContent: {
    color: theme.palette.gray[500],
    fontWeight: 500,
    fontSize: 14,
  },
  value: {
    height: ({isSpecialHeader}) => (isSpecialHeader ? '25px' : 'inherit'),
    lineHeight: '16px',
    color: theme.palette.green[650],
    marginRight: 6,
    padding: 4,
    marginBottom: ({isSpecialHeader}) => (isSpecialHeader ? '6px' : '2px'),
    fontSize: 14,
    fontWeight: 500,
    borderRadius: 6,
    cursor: 'pointer',
    backgroundColor: theme.palette.gray[100],
    '&:hover': {
      backgroundColor: theme.palette.blue[100],
    },
  },
  isClickable: {
    textTransform: 'lowercase',
    cursor: 'pointer',
    color: theme.palette.gray[400],
    '&:hover': {
      color: `${theme.palette.blue[500]} !important`,
    },
    '&:visited': {
      color: theme.palette.gray[400],
    },
  },
  ellipsis: {
    ...theme.typography.ellipsis,
  },
}));

type PropTypes = {
  metric: Object,
  highlightText: string,
  tooltipPlacement: string,
  children: React.Node,
  isSingleLine: boolean,
  index: number,
  isSpecialHeader: boolean,
};

const getClickableLink = ({type, id}, isAlertEditorV2Enabled) => {
  if (type === 'ALERT') {
    if (isAlertEditorV2Enabled) {
      return `#!/r/alert-manager/edit/${id}/settings`;
    }
    return `#!/alerts/${id}`;
  }
  if (type === 'STREAM') {
    return `#!/r/bc/data-manager?streamPreviewId=${id}`;
  }
  if (type === 'COMPOSITE') {
    return `/#!/metrics/composite/management/${id}`;
  }
  return '';
};

const MetricNameSingleLine = ({
  metric,
  highlightText,
  tooltipPlacement = 'top',
  children,
  isSingleLine,
  index,
  isSpecialHeader,
}: PropTypes) => {
  const classes = useStyles({isSingleLine, isSpecialHeader});
  const dispatch = useDispatch();
  const [containerClicksCounter, setContainerClicksCounter] = useState(0);

  const leanMe = useSelector(selectors.getLeanUserProfile);
  const isAlertEditorV2Enabled = useSelector(getAlertEditorV2Enabled);

  const metricContainerClicked = useCallback(() => {
    if (leanMe.impersonatorId && (containerClicksCounter + 1) % 3 === 0) {
      const res = {
        env: {
          href: window.location.href,
          host: window.location.host,
        },
        userInfo: leanMe,
        metricInfo: omit(metric, ['baseline', 'dataPoints']),
      };
      navigator.clipboard
        .writeText(JSON.stringify(res))
        .then(() => dispatch(successAction({...clipboardCopied, description: 'Metric info copied to clipboard'})));
    }
    setContainerClicksCounter(containerClicksCounter + 1);
  }, [containerClicksCounter, metric, leanMe]);

  const copyDimension = useCallback((value, event) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(value)
      .then(() => dispatch(successAction({...clipboardCopied, description: 'Dimension value copied to clipboard'})));
  }, []);

  let metricProps = metric?.properties || [];
  if (Array.isArray(metric?.tags)) {
    metricProps = metric.properties.concat(metric.tags.map((t) => ({...t, value: `#${t.value}`})));
  }

  const origin = metric?.metricOrigin || metric?.origin;

  const lastPropsKeys = ['target_type', 'mtype', 'func'];
  const lastProps = lastPropsKeys
    .map((key) => {
      const prop = metricProps?.find((item) => item?.key === key);
      return (
        prop && {
          ...prop,
          isLast: true,
        }
      );
    })
    .filter((item) => item);

  let propsList = [...Object.values(omitBy(metricProps, (item) => lastPropsKeys.includes(item?.key))), ...lastProps];

  if (origin) {
    propsList = [
      ...propsList,
      {
        key: `@${origin.type}`,
        value: origin.title,
        isLast: true,
        isClickable: true,
      },
    ];
  }

  return (
    <div className={`${classes.container}`} automation-id={`metricPresentation_${index}`}>
      <div
        className={`${classes.wrapper} ${isSingleLine && classes.ellipsis}`}
        onClick={metricContainerClicked}
        role="presentation"
      >
        {metric.what && (
          <NameFormat20Tooltip metric={metric}>
            <div className={classes.highlightWrapper}>
              {highlightText ? (
                <Highlighter
                  autoEscape
                  highlightStyle={{backgroundColor: palette.orange[350]}}
                  searchWords={[highlightText]}
                  textToHighlight={metric.what}
                />
              ) : (
                metric.what
              )}
            </div>
          </NameFormat20Tooltip>
        )}
        {metric.what && metric.properties.length > 0 && <div className={classes.for}>for</div>}

        {propsList.map((p, i) =>
          p ? (
            // eslint-disable-next-line react/no-array-index-key
            <div className={isSingleLine ? 'display_inline' : 'display_block'} key={p.key + i}>
              <>
                {p.isClickable && (
                  <SmartTooltip
                    placement={tooltipPlacement}
                    content={<div className={classes.tooltipContent}>{p.value}</div>}
                    extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
                  >
                    <a
                      className={`${classes.value} ${classes.isClickable} ${
                        isSingleLine ? 'display_inline' : 'display_block'
                      }`}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={getClickableLink(origin, isAlertEditorV2Enabled)}
                    >
                      {p.key}
                    </a>
                  </SmartTooltip>
                )}

                {!p.isClickable && (
                  <SmartTooltip
                    placement={tooltipPlacement}
                    content={<div className={classes.tooltipContent}>{p.key}</div>}
                    extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
                  >
                    <div
                      onClick={(e) => copyDimension(p.value, e)}
                      className={`${isSingleLine ? 'display_inline' : 'display_block'} ${classes.value}`}
                    >
                      {highlightText ? (
                        <Highlighter
                          autoEscape
                          highlightStyle={{backgroundColor: palette.orange[350]}}
                          searchWords={[highlightText]}
                          textToHighlight={p.value}
                        />
                      ) : (
                        p.value
                      )}
                    </div>
                  </SmartTooltip>
                )}
              </>
            </div>
          ) : null,
        )}
      </div>
      {children}
    </div>
  );
};

export default MetricNameSingleLine;
