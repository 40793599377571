// @flow
import React, {useRef, useState} from 'react';
import {Modal as BootstrapModal} from 'react-bootstrap';
import './Modal.module.scss';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  centered: {
    position: 'relative',
    top: ({modalHeight}) => `calc(45% - ${modalHeight / 2}px)`,
  },
}));

export const SIZES = {
  SMALL: 'size-small',
  MEDIUM: 'size-medium',
  LARGE: 'size-large',
  XLARGE: 'size-xlarge',
  XXLARGE: 'size-xxlarge',
  MAXWIDTH: 'size-maxwidth',
};

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  size?: string,
  isStatic?: boolean,
  isCloseButtonHidden?: boolean,
  children?: Node,
  id?: string,
  classStyle?: string,
  isNotCentered: boolean,
  className?: string,
  height?: string,
  isAnimation?: boolean,
};
const Modal = ({
  isOpen,
  onClose,
  size,
  isStatic,
  isCloseButtonHidden,
  children,
  id,
  classStyle,
  isNotCentered,
  className,
  height,
  isAnimation,
}: PropTypes) => {
  const modalContainer = useRef(null);
  const [modalHeight, setModalHeight] = useState(0);

  const classes = useStyles({modalHeight});
  const dialogClassName = ['andt-modal-v2', isNotCentered ? '' : classes.centered, classStyle, size || ''].join(' ');

  const onShow = () => {
    // eslint-disable-next-line no-undef
    const resizeObserver = new ResizeObserver(() => {
      setModalHeight(modalContainer && modalContainer.current ? modalContainer.current.clientHeight : 0);
    });

    resizeObserver.observe(modalContainer.current);
  };

  return (
    <div>
      <BootstrapModal
        onShow={onShow}
        enforceFocus={false}
        id={id}
        show={isOpen}
        onHide={onClose}
        backdrop={isStatic ? 'static' : true}
        dialogClassName={dialogClassName}
        animation={isAnimation}
      >
        <div ref={modalContainer} styleName="content-root" className={className} style={{height}}>
          {!isCloseButtonHidden && (
            <i className="icon icn-general16-closeb" onClick={onClose} styleName="close-modal" />
          )}
          {children && children}
        </div>
      </BootstrapModal>
    </div>
  );
};

Modal.defaultProps = {
  size: null,
  isCloseButtonHidden: false,
  isStatic: false,
  children: null,
  id: null,
  classStyle: '',
  className: '',
  height: 'auto',
  isAnimation: false,
};

export default Modal;
