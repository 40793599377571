// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import * as selector from 'alerts.management/store/selectors';
import * as actions from 'alerts.management/store/actions';
import {isEqual} from 'lodash';
import EstimationDisplay from 'common/componentsV2/EstimationDisplay';

type PropTypes = {
  errorMessage: String,
  // Connect
  alert: Object,
  isLoading: boolean,
  estimationText: String,
  simpleAlertCalculateEstimatedRate: Function,
  estimationDateRange: String,
};

export default connect(
  (state) => ({
    alert: selector.getSimpleAlertObject(state),
    isLoading: selector.getExecuteEstimationIsLoading(state),
    estimationText: selector.getExecuteEstimationText(state),
    estimationDateRange: selector.getEstimationestimationDateRange(state),
  }),
  {
    simpleAlertCalculateEstimatedRate: actions.simpleAlertCalculateEstimatedRate,
  },
)(
  class AlertEstimation extends React.PureComponent {
    props: PropTypes;

    componentDidUpdate(prevProps) {
      const {alert, simpleAlertCalculateEstimatedRate} = this.props;
      if (
        prevProps.alert &&
        alert &&
        (!isEqual(prevProps.alert.channels, alert.channels) || !isEqual(prevProps.alert.subscribers, alert.subscribers))
      ) {
        return;
      }

      if (alert && prevProps.alert !== alert) {
        simpleAlertCalculateEstimatedRate();
      }
    }

    render() {
      const {isLoading, estimationText, errorMessage, alert, estimationDateRange} = this.props;

      return (
        <EstimationDisplay
          isLoading={isLoading}
          estimationText={estimationText}
          errorMessage={errorMessage}
          disabled={!alert}
          tooltip={estimationDateRange}
        />
      );
    }
  },
);
