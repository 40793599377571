// @flow
import React, {useCallback, useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Slider from 'common/componentsV2/Slider';
import TooltipArea from 'common/componentsV2/TooltipArea';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'alerts.management/store/selectors';
import * as actions from 'alerts.management/store/actions';
import InputAuto from '../simulationArea/utils/InputAuto';

const SLIDER_POINTS = [
  {
    value: 35,
    title: 'LOW',
  },
  {
    value: 55,
    title: 'MEDIUM',
  },
  {
    value: 75,
    title: 'HIGH',
  },
];

const SignificanceSelector = () => {
  const dispatch = useDispatch();
  const {minSignificance} = useSelector(selectors.getSignificanceCondition);
  const setSelectedAlertSignificance = useCallback(
    (...args) => dispatch(actions.setSelectedAlertSignificance(...args)),
    [dispatch],
  );
  const [localValue, setLocalValue] = useState(minSignificance);
  useEffect(() => {
    setLocalValue(minSignificance);
  }, [minSignificance]);
  return (
    <TooltipArea
      isAlwaysVisible
      automationId="significanceContainer"
      text="Each anomaly has its own sig. score, this allows you to filter out anomalies with low significance."
    >
      {(info) => (
        <React.Fragment>
          <div className="text16reg lineHeight_16 mb_1-5">
            Minimum Significance Score
            {info}
          </div>
          <Box display="flex">
            <Box width="52px" height="30px" mr={2} css={{'& input': {padding: '10px', textAlign: 'center'}}}>
              <InputAuto
                automationId="minSignificance"
                multipleOf={1}
                fullSize
                delay={1000}
                type="number"
                value={Math.floor(localValue * 100)}
                onChange={(e) => setSelectedAlertSignificance({minSignificance: e.target.value / 100})}
                additionalProps={{
                  min: 0,
                  max: 100,
                }}
              />
            </Box>
            <Box flexGrow={1}>
              <Slider
                automationId="minSignificanceSlider"
                value={localValue}
                max={1}
                min={0}
                step={0.01}
                points={SLIDER_POINTS}
                tooltipValueFormatter={(value) => Math.floor(value * 100)}
                onRelease={(value) => setSelectedAlertSignificance({minSignificance: value})}
                onChange={setLocalValue}
              />
            </Box>
          </Box>
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default React.memo(SignificanceSelector);
