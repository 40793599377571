import React from 'react';
import './LabelAndValueRow.module.scss';

type PropTypes = {
  title: string,
  additionalTitle: Node,
  children: Node,
  isBreakLine: boolean,
  isValueLink: boolean,
  onValueLinkClick: Function,
};

const LabelAndValueRow = ({
  title,
  additionalTitle,
  children,
  isBreakLine,
  isValueLink,
  onValueLinkClick,
}: PropTypes) => (
  <div styleName="container">
    <header styleName="header">
      <h3 styleName="title">{title}</h3>
      {additionalTitle}
    </header>
    <div
      styleName={['value', isBreakLine ? 'break-line' : '', isValueLink ? 'value-link' : ''].join(' ').trim()}
      onClick={onValueLinkClick}
    >
      {children}
    </div>
  </div>
);

export default LabelAndValueRow;
