// @flow
import React, {useCallback} from 'react';
import {makeStyles, Menu, MenuItem} from '@material-ui/core';
import {ReactComponent as ArrowDown} from 'app/images/arrow-down.svg';
import {palette} from 'app/styles/theme';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'inline-block',
    position: 'relative',
  },
  menu: {
    '& ul': {
      padding: 0,
    },
  },
  menuItem: {
    cursor: 'pointer',
    margin: '4px',
    padding: 8,
    borderRadius: 6,
    '&:hover, &.Mui-selected:hover': {
      backgroundColor: palette.blue[200],
    },
    '&.disabled:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
    '&.disabled': {
      opacity: 0.7,
    },
    '&.Mui-selected': {
      backgroundColor: palette.cyan[300],
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
}));

type PropTypes = {
  className: string,
  onSelect: Function,
  options: Array,
  activeValue: string,
};

export const ArrowSelect = ({className = '', onSelect, options = [], activeValue}: PropTypes) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onMenuClick = useCallback((o) => {
    onSelect(o);
    setAnchorEl(null);
  }, []);

  return (
    <span className={className || ''}>
      <span onClick={handleClick}>
        <ArrowDown width={8} height={12} />
      </span>
      <Menu
        className={classes.menu}
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.map((o) => (
          <MenuItem
            className={`${classes.menuItem}${o.disabled ? ' disabled' : ''}`}
            disabled={o.disabled}
            onClick={() => onMenuClick(o.value ?? o)}
            selected={o.value === activeValue}
          >
            {o.label}
          </MenuItem>
        ))}
      </Menu>
    </span>
  );
};

export default ArrowSelect;
