// @flow
import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {last} from 'lodash';
import MetricName from 'metrics/components/metricName/MetricName';
import TimeSeriesChart from 'charts/timeSeries/components/TimeSeriesChart';
import AlertEventsChart from 'alerts.console.new/components/alertsList/alertContent/AlertEventsChart';
import {getFormattedDateTime} from 'common/utils/dateService';
import {CHART_HEIGHT} from 'alerts.console.new/services/alertsConsoleService';
import {getAlertConsoleEventsEnabled, getBucketStartTimeEnabled, getTimeZoneName} from 'profile/store/selectors';
import {fetchAlertConsoleMetricsDataPoints} from 'alerts.console.new/store/actions';
import '../alertContent.module.scss';

let counter = 0;

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: false,
  hideMeetIcons: true,
};

const NoDataAlertMetric = ({
  metric,
  alertId,
  alertConfigurationId,
  startDate,
  endDate,
  timeScale,
  isSingleLine = true,
  specialHeader,
  index,
  searchExpression,
}: {
  metric: Object,
  alertId: string,
  alertConfigurationId: string,
  startDate: number,
  endDate: number,
  timeScale: string,
  isSingleLine: boolean,
  specialHeader: boolean,
  index: number,
  searchExpression: string,
}) => {
  const idRef = useRef(`console.noData.chart_${(counter += 1)}`); // idRef.current
  const chartEventsMetaRef = useRef({
    key: `${alertId}_${metric.id}`, // unique ID for chart
    chartId: idRef.current,
  });

  const timeZoneName = useSelector(getTimeZoneName);
  const bucketStartTimeEnabled = useSelector(getBucketStartTimeEnabled);

  const interval = last(metric.intervals);

  const isAlertConsoleEventsEnabled = useSelector(getAlertConsoleEventsEnabled);
  if (!isAlertConsoleEventsEnabled) {
    const dispatch = useDispatch();
    const startBucketMode = useSelector(getBucketStartTimeEnabled);

    useEffect(() => {
      dispatch(
        fetchAlertConsoleMetricsDataPoints(
          {
            metricId: metric.id,
            endDate,
            startDate,
            startBucketMode,
            includeBaseline: false,
            timeScale: 'medium',
          },
          {
            ...chartEventsMetaRef.current,
            metricId: metric.id,
            intervals: metric.intervals,
            alertId,
          },
        ),
      );
    }, []);
  }

  return (
    <div styleName="tile">
      <div styleName={['header', specialHeader ? 'extra-line' : ''].join(' ')}>
        <div styleName="header-row">
          <MetricName
            specialHeader={specialHeader}
            isSingleLine={isSingleLine}
            metric={metric}
            highlightText={searchExpression}
          />
        </div>

        {interval.lastSeenMachineTimeEpoch && (
          <div styleName="header-row">
            <div styleName="row-left">
              This metric was last seen at {getFormattedDateTime(interval.lastSeenMachineTimeEpoch, timeZoneName)}
            </div>
          </div>
        )}
      </div>

      <div styleName={['chart', specialHeader ? 'special-height' : ''].join(' ')}>
        <>
          {isAlertConsoleEventsEnabled ? (
            <AlertEventsChart
              id={idRef.current}
              alertId={alertId}
              alertConfigurationId={alertConfigurationId}
              metric={metric}
              chartEventsMeta={chartEventsMetaRef.current}
              disableActions={false}
              bucketStartTimeEnabled={bucketStartTimeEnabled}
              timeScale={timeScale}
              timeZoneName={timeZoneName}
              startDate={startDate}
              endDate={endDate}
              index={index}
            />
          ) : (
            <TimeSeriesChart
              id={idRef.current}
              tooltip={TOOLTIP_OPTIONS}
              height={CHART_HEIGHT}
              bucketStartTimeEnabled={bucketStartTimeEnabled}
              timeScale={timeScale}
              timeZoneName={timeZoneName}
              enableClientZoom
              eventsMeta={chartEventsMetaRef.current}
            />
          )}
        </>
      </div>
    </div>
  );
};

export default React.memo(NoDataAlertMetric);
