import React from 'react';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import Button, {COLORS} from 'common/componentsV2/Button';
import {useSelector} from 'react-redux';
import {getFormattedDateYMD} from 'common/utils/dateService';
import {getTimelineView} from '../../store/selectors';
import {getTimeZoneName} from '../../../profile/store/selectors';

const DownloadTimelineCSV = () => {
  const timelineView = useSelector(getTimelineView);
  const timeZoneName = useSelector(getTimeZoneName);

  const handleClick = () => {
    const rows = [['timestamp', 'Event Type', 'Description', 'User']];
    timelineView.forEach((ele) => {
      const formattedDate = getFormattedDateYMD(ele.dataTime, timeZoneName);
      let name = ele.firstName ? `${ele.firstName} ${ele.lastName}` : 'Anonymous';
      if (name === 'Anonymous' && ele.userName) {
        name = ele.userName;
      }
      const newRow = [formattedDate.replaceAll(',', '-'), ele.type, ele.title?.replaceAll(',', ' '), name];
      rows.push(newRow);
    });

    const csvContent = `data:text/csv;charset=utf-8,${rows.map((e) => e.join(',')).join('\n')}`;

    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  return (
    <>
      <Tooltip content="Export timeline to CSV" type={TYPES.SMALL}>
        <div>
          <Button
            colorSchema={COLORS.TRANSPARENT}
            icon="icn-action16-download"
            isDisabled={timelineView.length === 0}
            onClick={handleClick}
          />
        </div>
      </Tooltip>
    </>
  );
};

export default DownloadTimelineCSV;
