// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setAlertFiltersIsOpen} from 'alerts.management/store/actions';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import FilterButton from 'common/componentsV2/FilterButton';
import * as filterSelector from 'alerts.management/store/filterSelector';
import * as alertsSelectors from 'alerts.management/store/selectors';
import {getMeAppSettings} from 'profile/store/selectors';
import './AlertManagerHeader.module.scss';
import {get} from 'lodash';
import NewAlertButton from '../editor/simpleAlertForm/NewAlertButton';

type PropTypes = {
  // connect
  alertsCount: number,
  filteredAlertsCount: number,
  tooltipItems: Array,
  appSettings: Object,
  setAlertFiltersIsOpen: Function,
};

export default connect(
  (state) => ({
    appSettings: getMeAppSettings(state),
    alertsCount: alertsSelectors.getAlertConfigurationsCount(state),
    filteredAlertsCount: alertsSelectors.getFilteredAlertManagerHeadersListCount(state),
    tooltipItems: filterSelector.getFiltersButtonTooltipItems(state),
  }),
  {
    setAlertFiltersIsOpen,
  },
)(
  class AlertManagerHeader extends React.PureComponent {
    props: PropTypes;

    onClick = () => {
      const isOpen = get(this.props.appSettings, 'alertManager.isFiltersBarOpen', false);
      this.props.setAlertFiltersIsOpen(!isOpen);
    };

    render() {
      const {alertsCount, filteredAlertsCount, tooltipItems} = this.props;
      return (
        <header styleName="page-header">
          <div styleName="container" automation-id="Alert Manager Header">
            <Title type={TITLE_TYPES.PAGE_TITLE}>Alert Management</Title>
            <Title type={TITLE_TYPES.PAGE_LINE_COUNTER}>
              {filteredAlertsCount === alertsCount ? `(${alertsCount})` : `(${filteredAlertsCount}/${alertsCount})`}
            </Title>
            <FilterButton
              automationId="Alert console filter button"
              tooltipItems={tooltipItems}
              onClick={this.onClick}
            />
          </div>
          <NewAlertButton />
        </header>
      );
    }
  },
);
