/* eslint-disable no-shadow */
// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getUniqueId} from 'common/utils/guid';
import * as selectors from 'alerts.management/store/selectors';
import {
  getServerTreesModelFromCompositeDefinition,
  getTreesDisplayPropertiesFromCompositeDefinition,
  showCompositeModal,
} from 'common/utils/angularServices';
import {
  addSelectedAlertInfluencingMetricsSubCondition,
  fetchInfluencingMetricsInfo,
  removeConditionFromSelectedAlert,
  removeSelectedAlertInfluencingMetricsSubCondition,
  setSelectedAlertInfluencingMetrics,
  setSelectedAlertInfluencingMetricsSubCondition,
  setSuppress,
} from 'alerts.management/store/actions';
import {get, isEmpty} from 'lodash';
import ConditionContainer from '../ConditionContainer';
import TimeScaleCondition from './TimeScaleCondition';
import SectionHeader from './SectionHeader';
import Descriptor from './Descriptor';
import MatchingProperties from './MatchingProperties';
import SubConditions from './SubConditions';
import FailOnAbsence from './FailOnAbsence';
import './InfluencingMetrics.module.scss';

type PropTypes = {
  setSelectedAlertInfluencingMetrics: Function,
  setSelectedAlertInfluencingMetricsSubCondition: Function,
  addSelectedAlertInfluencingMetricsSubCondition: Function,
  removeSelectedAlertInfluencingMetricsSubCondition: Function,
  removeConditionFromSelectedAlert: Function,
  fetchInfluencingMetricsInfo: Function,
  id: String,
  influencingMetrics: Object,
  simulationFiltersValidationResult: Object,
  alertTreeModel: Object,
  influencingMetricsInfo: Object,
  simulationFilter: Object,
  setSuppress: Function,
};

class influencingMetrics extends React.PureComponent {
  props: PropTypes;

  state = {isOpen: false};

  componentDidMount() {
    this.fetchInfluencingMetricsInfo();
    this.setState({isOpen: !this.props.influencingMetrics.expressionTreeModel});
  }

  fetchInfluencingMetricsInfo = () => {
    if (!isEmpty(this.props.influencingMetrics.expressionTreeModel) && !isEmpty(this.props.alertTreeModel)) {
      this.props.fetchInfluencingMetricsInfo(
        {
          influencingMetricsTreeModel: this.props.influencingMetrics.expressionTreeModel,
          alertTreeModel: this.props.alertTreeModel,
        },
        this.props.id,
      );
    }
  };

  openCompositeModal = () => {
    let serverTreesModel = {};

    let treesDisplayProperties = {};

    const curTreeModel = this.props.influencingMetrics.expressionTreeModel;
    if (curTreeModel) {
      curTreeModel.id = curTreeModel.id || getUniqueId();
      serverTreesModel = getServerTreesModelFromCompositeDefinition(curTreeModel);
      treesDisplayProperties = getTreesDisplayPropertiesFromCompositeDefinition(curTreeModel);
    }

    const model = {
      serverTreesModel,
      treesDisplayProperties,
      expressionTreeModelDefaults: {
        displayOnly: true,
        scalarTransforms: null,
        name: null,
        mtype: 'ALERT',
        namingSchema: 'COMPOSITE_V2',
      },
      saveBehaviour: {
        shouldSaveComposite: false,
        shouldShowSaveButton: true,
        shouldSaveOnlySingleTree: true,
      },
      resolution: {value: this.props.influencingMetrics.rollup},
      tip: 'Set Influencing Metrics',
    };

    const modalInstance = showCompositeModal(model);

    modalInstance.result.then(
      (res) => {
        this.props.setSelectedAlertInfluencingMetrics({
          expressionTreeModel: Object.values(res.serverTreesModel)[0],
          matchingProperties: [],
          id: this.props.id,
        });
        this.fetchInfluencingMetricsInfo();
      },
      () => {},
    );
  };

  updateRollup = (rollupObj) => {
    this.props.setSelectedAlertInfluencingMetrics({id: this.props.id, ...rollupObj});
  };

  setSuppress = (value) => {
    const {id, setSuppress} = this.props;
    setSuppress({id, value});
  };

  addSubCondition = () => {
    this.props.addSelectedAlertInfluencingMetricsSubCondition(this.props.id);
  };

  updateIsOpen = () => {
    this.setState({isOpen: true});
  };

  matchingPropertiesErrorMsgs = (failure) => {
    const userSelectionMatchingProperties = this.props.influencingMetrics.matchingProperties;
    const matchingProperties = get(this.props.influencingMetricsInfo, 'data.matchingProperties', []);

    if (failure.id === 6026) {
      if (
        userSelectionMatchingProperties.length &&
        matchingProperties.length &&
        userSelectionMatchingProperties.length === matchingProperties.length
      ) {
        return 'matching property collision, please refine influencing metric definition';
      }
      if (userSelectionMatchingProperties.length) {
        return 'Please select more matching properties to avoid collision.';
      }
      if (matchingProperties.length && !userSelectionMatchingProperties.length) {
        return 'Please select matching properties.';
      }
      return `${failure.message}.`;
    }
    return `${failure.message}.`;
  };

  render() {
    const {
      influencingMetrics,
      removeConditionFromSelectedAlert,
      setSelectedAlertInfluencingMetrics,
      removeSelectedAlertInfluencingMetricsSubCondition,
      setSelectedAlertInfluencingMetricsSubCondition,
      influencingMetricsInfo,
      simulationFiltersValidationResult,
      simulationFilter: {type},
      id,
    } = this.props;
    const conditionErrors = get(simulationFiltersValidationResult, `data.failures[${influencingMetrics.id}]`, []);
    const conditionErrorsIsLoading = simulationFiltersValidationResult.isLoading;
    const hasInfluencingMetric = !!get(influencingMetricsInfo, 'data.what', false);

    if (!influencingMetricsInfo) {
      return null;
    }
    const {isOpen} = this.state;

    return (
      <div onClick={this.updateIsOpen} styleName={isOpen ? '' : 'container'}>
        <ConditionContainer
          title="Influencing Metrics"
          subTitle="Trigger the alert only when the influencing metrics meet the following conditions"
          onClose={removeConditionFromSelectedAlert}
          id={id}
        >
          <div styleName="spacer" />

          <Descriptor
            timeScale={influencingMetrics.rollup}
            subConditions={influencingMetrics.subConditions}
            matchingProperties={influencingMetrics.matchingProperties}
            what={influencingMetricsInfo.data.what}
            isDuring={influencingMetrics.useAnomalyValues}
            isSingle={influencingMetricsInfo.data.total === 1}
            type={type.value}
          />

          {conditionErrorsIsLoading ? (
            <i className="icon ion-load-c spin" styleName="error-loader" />
          ) : (
            hasInfluencingMetric && (
              <ul styleName="errors">
                {conditionErrors.map((f) => (
                  <li key={f.message}>{this.matchingPropertiesErrorMsgs(f)}</li>
                ))}
              </ul>
            )
          )}

          {!isOpen && <div styleName="show-more">...</div>}

          {isOpen && (
            <div styleName="main">
              {/* metrics */}
              <div styleName="section">
                <SectionHeader
                  digit="1"
                  title="Set Influencing Metrics"
                  instruction={!hasInfluencingMetric ? 'Please select a measure in the expression' : null}
                  subTitle="Select the metrics to apply the conditions on"
                />
                <button
                  type="button"
                  className="btn btn-raised btn-outline btn-outline-large btn-outline-light"
                  styleName="btn-metrics"
                  onClick={this.openCompositeModal}
                >
                  SET
                </button>
                <div styleName="what-text">{influencingMetricsInfo.data.what}</div>
              </div>

              {/* matching properties */}
              <div styleName="section section-wide">
                <MatchingProperties
                  influencingMetricsInfo={influencingMetricsInfo}
                  matchingProperties={influencingMetrics.matchingProperties}
                  id={influencingMetrics.id}
                  setSelectedAlertInfluencingMetrics={setSelectedAlertInfluencingMetrics}
                />
              </div>

              {/* timescale */}
              <div styleName="section">
                <TimeScaleCondition
                  rollup={influencingMetrics.rollup}
                  type={type.value}
                  onChange={this.updateRollup}
                  useAnomalyValues={influencingMetrics.useAnomalyValues}
                  omitRollups={['short', 'medium']}
                />
              </div>

              {/* sub-conditions */}
              <div styleName="section section-wide">
                <SectionHeader
                  digit="4"
                  title="Set Conditions"
                  subTitle="Set conditions on the influencing metrics values"
                />
                <SubConditions
                  id={influencingMetrics.id}
                  subConditions={influencingMetrics.subConditions}
                  onChangeSubCondition={setSelectedAlertInfluencingMetricsSubCondition}
                  onRemoveSubCondition={removeSelectedAlertInfluencingMetricsSubCondition}
                />
                <button
                  type="button"
                  onClick={this.addSubCondition}
                  className="btn btn-flat btn-icon"
                  styleName="btn-add"
                >
                  <i className="icon icn-action16-plusa" />
                </button>
              </div>

              <div styleName="section section-wide">
                <SectionHeader digit="5" title="Set Missing Data Conditions " />
                <FailOnAbsence failOnAbsence={influencingMetrics.failOnAbsence} onSetSuppress={this.setSuppress} />
              </div>
            </div>
          )}
        </ConditionContainer>
      </div>
    );
  }
}

export default connect(
  (state, props) => ({
    influencingMetrics: selectors.getSelectedAlertInfluencingMetrics(state).filter((c) => c.id === props.id)[0],
    influencingMetricsInfo: selectors.influencingMetricsInfo(state)[props.id],
    simulationFiltersValidationResult: selectors.getSimulationFiltersValidationResults(state),
    alertTreeModel: selectors.getSelectedAlertExpressionTreeModel(state),
    simulationFilter: selectors.getSelectedAlertSimulationFilters(state),
  }),
  {
    setSelectedAlertInfluencingMetrics,
    setSelectedAlertInfluencingMetricsSubCondition,
    addSelectedAlertInfluencingMetricsSubCondition,
    removeSelectedAlertInfluencingMetricsSubCondition,
    removeConditionFromSelectedAlert,
    fetchInfluencingMetricsInfo,
    setSuppress,
  },
)(influencingMetrics);
