import {useMutation, useQueryClient} from 'react-query';

import {post} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {setCleanStream} from 'dataCollectors/services/eventStreamService';

const root = 'api/v1/bc/event-streams';

const postStartEventStream = (stream) => {
  const cleanStream = setCleanStream(stream);
  return post(`${root}/start`, cleanStream);
};

export default function useStartEventStream() {
  const queryClient = useQueryClient();

  const {mutate: startEventStream, isLoading: startEventStreamIsLoading} = useMutation(
    ({payload}) => postStartEventStream(payload),
    {
      onSuccess: (data, meta) => {
        queryClient.invalidateQueries([QUERY_KEYS.streams]);
        meta.onSuccess(data);
      },
    },
  );
  return {startEventStream, startEventStreamIsLoading};
}
