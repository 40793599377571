// @flow
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {StringParam, useQueryParam} from 'use-query-params';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {ACK_OPTIONS, ALERT_CONSOLE_QUERY_PARAMS_KEYS} from 'alerts.console.new/services/alertsConsoleService';
import {getProfileId} from 'profile/store/selectors';

const AckFilter = () => {
  const meId = useSelector(getProfileId);

  const [ackBy, setAckBy] = useQueryParam(ALERT_CONSOLE_QUERY_PARAMS_KEYS.ACK_BY, StringParam);
  const [notAckBy, setNotAckBy] = useQueryParam(ALERT_CONSOLE_QUERY_PARAMS_KEYS.NOT_ACK_BY, StringParam);

  const toggleFilter = useCallback(
    (item) => {
      switch (item.id) {
        case ACK_OPTIONS.ALL.id:
          setAckBy(undefined);
          setNotAckBy(undefined);
          break;
        case ACK_OPTIONS.ACK_BY.id:
          if (item.value === 'me') {
            setAckBy(meId);
          } else {
            setAckBy('*');
          }
          setNotAckBy(undefined);
          break;
        case ACK_OPTIONS.NOT_ACK_BY.id:
          setAckBy(undefined);
          setNotAckBy('*');
          break;
        default:
          break;
      }
    },
    [meId, setAckBy, setNotAckBy],
  );

  const optionCurrent = useMemo(() => {
    if (ackBy) {
      return ackBy === meId ? ACK_OPTIONS.ACK_BY_ME : ACK_OPTIONS.ACK_BY;
    }
    if (notAckBy) {
      return ACK_OPTIONS.NOT_ACK_BY;
    }
    return null;
  }, [ackBy, notAckBy, meId]);

  return (
    <SelectAndt
      id="ackFilter"
      automationId="AlertAckFilter"
      extraClassName="alerts-dropdown-btn"
      options={Object.values(ACK_OPTIONS)}
      optionHeight={40}
      menuWidth={200}
      type={TYPE_NEW_NO_SEARCH}
      theme={THEME_HIGHLIGHTED}
      onChange={(item) => toggleFilter(item)}
      value={optionCurrent}
      placeholder={ACK_OPTIONS.ALL.label}
    />
  );
};

export default AckFilter;
