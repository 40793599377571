// @flow
import React from 'react';
import {connect} from 'react-redux';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import * as selectors from 'bc/store/selectors';
import {reduceArrayItem} from 'common/utils/reducers';
import {
  newFilter,
  convertGA4UiFiltersToStreamFilters,
  convertGA4StreamFiltersToUiFilters,
} from 'bc/services/ga4Service';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import Ga4FilterItem from './Ga4FilterItem';

import './Ga4Template.module.scss';

const reducerHelper = (item, payload) => ({...item, ...payload});

type PropTypes = {
  dataStream: Object,
  dimensions: Array,
  setSelectedStreamKeyVal: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dimensions: selectors.getSelectedGA4DataStreamDimensionsObj(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)(
  class Ga4Filters extends React.PureComponent {
    props: PropTypes;

    state = {
      filters: this.props.dataStream.filters
        ? convertGA4StreamFiltersToUiFilters(this.props.dataStream.filters)
        : newFilter,
    };

    filterChanged = (res) => {
      const f = {...this.state.filters[res.index]};
      f[res.target] = res.value;
      this.setState((prevState) => {
        const filters = reduceArrayItem(reducerHelper, prevState.filters, res.index, f);
        this.props.setSelectedStreamKeyVal({filters: convertGA4UiFiltersToStreamFilters(filters)});
        return {
          filters,
        };
      });
    };

    removeFilter = (index) => {
      if (this.state.filters.length === 1) {
        this.setState((prevState) => {
          prevState.filters.splice(index, 1);
          const filters = [...prevState.filters, {name: '', values: ''}];
          this.props.setSelectedStreamKeyVal({filters: convertGA4UiFiltersToStreamFilters(filters)});
          return {
            filters,
          };
        });
      } else {
        this.setState((prevState) => {
          prevState.filters.splice(index, 1);
          const filters = [...prevState.filters];
          this.props.setSelectedStreamKeyVal({filters: convertGA4UiFiltersToStreamFilters(filters)});
          return {
            filters,
          };
        });
      }
    };

    addFilter = () => {
      this.setState((prevState) => {
        const filters = [...prevState.filters, {name: '', values: ''}];
        this.props.setSelectedStreamKeyVal({filters: convertGA4UiFiltersToStreamFilters(filters)});
        return {
          filters,
        };
      });
    };

    render() {
      const {dataStream, dimensions} = this.props;

      const isEditable = isStreamEditable(dataStream.state) || isStreamRunning(dataStream.state);

      return (
        <div className="shell-col" styleName={['advance-root', isEditable ? '' : 'disabled'].join(' ')}>
          <div styleName="middle-container">
            <PanelHeader title="Stream Filters" isEditable />

            {this.state.filters.map((filter, i) => (
              <Ga4FilterItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                index={i}
                disabled={!isEditable}
                filter={filter}
                dimensions={dimensions}
                onChange={this.filterChanged}
                onRemove={this.removeFilter}
              />
            ))}

            <div styleName="actions-wrapper">
              <div styleName="add-path-wrapper" onClick={this.addFilter}>
                <i className="icon icn-icon-blue-plus" />
                <span>Add Another Filter</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  },
);
