// @flow
import React from 'react';
import {makeStyles} from '@material-ui/core';

type PropTypes = {
  tag: String,
  title: String,
  children: Object,
  link: String,
  url: String,
  onClose: Function,
  storageKey: String,
  position: string,
  width: number,
  height: number,
  icon: string,
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: 6,
    borderColor: theme.palette.blue[300],
    backgroundColor: theme.palette.blue[100],
    color: theme.palette.gray[500],
    lineHeight: 'normal',
    position: 'relative',
    zIndex: 1,
    wordBreak: 'break-word',
    '&:hover .icon': {
      opacity: 1,
    },
  },
  position: {
    position: 'absolute',
    width: 12,
    height: 12,
    backgroundColor: theme.palette.blue[100],
    transform: 'rotate(45deg)',
  },
  bottom: {
    top: -6,
    right: 22,
  },
  top: {
    bottom: -6,
    right: 'calc(50% - 6px)',
  },
  topLine: {
    display: 'flex',
    fontSize: 0,
    justifyContent: 'space-between',
    fontWeight: 'bold',
    wordBreak: 'break-all',
  },
  closeButton: {
    width: 12,
    height: 12,
    color: theme.palette.gray[400],
    position: 'absolute',
    top: 4,
    right: 6,
    fontSize: 14,
    cursor: 'pointer',
    opacity: 0,
  },
  icon: {
    fontSize: 24,
    position: 'absolute',
    left: 8,
    top: 5,
  },
  link: {
    color: theme.palette.blue[500],
    fontWeight: 500,
    marginTop: 8,
  },
  tag: {
    borderRadius: 9.5,
    backgroundColor: theme.palette.blue[500],
    padding: '1px 5px',
    color: theme.palette.white[500],
    display: 'inline-block',
    marginBottom: 4,
    fontSize: 12,
    font_weight: 'bold',
  },
}));

const BaloonMessage = ({
  tag,
  title,
  children,
  link,
  url,
  onClose,
  storageKey,
  position,
  width,
  height,
  icon,
}: PropTypes) => {
  const classes = useStyles();
  const [storageValue, setStorageValue] = React.useState(localStorage.getItem(storageKey) || '');

  React.useEffect(() => {
    localStorage.setItem(storageKey, storageValue);
  }, [storageValue]);

  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      setStorageValue(true);
    }
  };

  return !storageValue ? (
    <div
      className={classes.wrapper}
      style={{
        width,
        height,
        padding: icon ? '8px 16px 8px 36px' : '8px 16px 8px 16px',
        border: position ? '' : 'solid 2px $blue-300',
      }}
    >
      {position && <div className={`${classes.position} ${classes[position]}`} />}
      {tag && <div className={classes.tag}>{tag}</div>}
      <div className={classes.topLine} style={title ? {marginBottom: 8} : null}>
        {icon && <i className={`${icon} ${classes.icon}`} />}
        {title && <div style={{fontSize: 14}}>{title}</div>}
        {(onClose || storageKey) && (
          <div className={`${classes.closeButton} icon icn-general16-closeb`} onClick={handleClose} />
        )}
      </div>
      {children}
      {url && (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <div className={classes.link}>{link}</div>
        </a>
      )}
    </div>
  ) : null;
};

export default BaloonMessage;
