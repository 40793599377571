import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import DataMapperItemPreview from 'dataCollectors/components/dataMapper/DataMapperItemPreview';
import usePreviewClasses from 'dataCollectors/styles/classes.usePreviewClasses';
import ContentLoaderPreview from 'dataCollectors/components/loaders/ContentLoaderPreview';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {getIsLoading} from 'reactQuery/ReactQueryLoading';

const EVENTS_FIELDS_ARR = Object.keys(EVENT_STREAM_FIELDS)
  .filter((i) => i.includes('EVENT'))
  .map((i) => EVENT_STREAM_FIELDS[i]);

const {STREAM_NAME} = EVENT_STREAM_FIELDS;

const DataMapperPreview = () => {
  const previewClasses = usePreviewClasses();
  const [usedSourceColumns, setUsedSourceColumns] = useState([]);

  const isLoading = getIsLoading([QUERY_KEYS.streams]);
  const {getValues} = useFormContext();
  const values = getValues();

  useEffect(() => {
    if (!isLoading) {
      const newArr = [];

      EVENTS_FIELDS_ARR.forEach((i) => {
        if (values[i.id]) {
          newArr.push({
            eventType: i.name,
            sourceColumn: values[i.id].sourceColumn,
          });
        }
        return null;
      });
      setUsedSourceColumns(newArr);
    }
  }, [values[STREAM_NAME.id]]);

  return (
    <div className={previewClasses.sectionWrapper}>
      {isLoading && <ContentLoaderPreview />}
      {!isLoading && !usedSourceColumns.length && <div className={previewClasses.noData}>No data</div>}
      {!isLoading && !!usedSourceColumns.length && (
        <div className={previewClasses.content}>
          <div>
            <span className={previewClasses.sectionTitle}>Fields</span>
          </div>
          <div className={previewClasses.row}>
            <span>Your Data</span>
            <span>Anodot Content</span>
          </div>
          <div>
            {usedSourceColumns.length &&
              usedSourceColumns.map((i) => (
                <DataMapperItemPreview sourceColumn={i.sourceColumn} eventType={i.eventType} />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DataMapperPreview;
