// @flow
import React from 'react';
import Acknowledge from '../alertActions/Acknowledge';
import SnoozeAction from '../alertActions/SnoozeAction';
import MoreDdlActions from '../alertActions/MoreDdlActions';

import './alertContent.module.scss';

const ROW_DEFAULT_HEIGHT = 46;

const AlertActions = (props: {
  alert: Object,
  uiGroupName: string,
  lastInterval: Object,
  resolutions: Array,
  isDisplay: Boolean,
}) => {
  const {alert, uiGroupName, lastInterval, resolutions, isDisplay} = props;
  return isDisplay ? (
    <div styleName="actions-container" style={{height: ROW_DEFAULT_HEIGHT}}>
      <div styleName="snooze" automation-id="alertConsoleSnoozeButton">
        <SnoozeAction
          triggerId={alert.id}
          alertConfigurationId={alert.alertConfigurationId}
          summary={alert.summary}
          timeScale={alert.timeScale}
          alertType={alert.type}
        />
      </div>

      <div styleName="acknowledge">
        <Acknowledge
          starredBy={alert.acknowledgedBy}
          automation-id="alertConsoleStarButton"
          groupId={alert.groupId}
          alertId={alert.id}
          uiGroupName={uiGroupName}
        />
      </div>

      <div styleName="more-actions">
        <MoreDdlActions
          id={alert.id}
          automation-id="alertConsoleMoreActionButton"
          triggerId={alert.id}
          origin={
            Array.isArray(alert.metrics[0]?.originSourceIds)
              ? alert.metrics[0]?.originSourceIds[0]
              : alert.metrics[0].origin?.id
          }
          measure={alert.metrics[0].what}
          alertConfigurationId={alert.alertConfigurationId}
          anomalyId={lastInterval.anomalyId}
          isImpactEligible={alert.impactEligible}
          impact={alert.impact}
          timeScale={Object.values(resolutions).find((a) => a.value2 === alert.timeScale).value}
        />
      </div>
    </div>
  ) : null;
};

export default AlertActions;
