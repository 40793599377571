// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import * as selectors from 'bc/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import {fetchGoogleAuctionsEmail, updateDataSource, createDataSource, resetAddSourceError} from 'bc/store/actions';
import CopyToClipboardButton from 'common/components/CopyToClipboardButton';
import DataSourceModal from '../../DataSourceModal';
import '../../DataSourceModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  isViewOnly: boolean,
  isEditable: boolean,
  email: String,
  sourceType: Object,
  source: Object,
  onClose: Function,

  // connect
  me: Object,
  email: Object,
  isLoadingEmail: Boolean,
  isLoading: Boolean,
  sourceError: Object,

  fetchGoogleAuctionsEmail: Function,
  updateDataSource: Function,
  createDataSource: Function,
  resetAddSourceError: Function,
};

export default connect(
  (state) => ({
    email: selectors.getGoogleAuctionEmailDataEmail(state),
    isLoadingEmail: selectors.getGoogleAuctionEmailIsLoading(state),
    me: profileSelectors.getUserProfile(state),
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    fetchGoogleAuctionsEmail,
    createDataSource,
    updateDataSource,
    resetAddSourceError,
  },
)(
  class GoogleAuctionsSourceModal extends React.PureComponent {
    props: PropTypes;

    // eslint-disable-next-line no-extra-boolean-cast
    state = !!this.props.source
      ? {
          email: this.props.source.email,
          sourceName: this.props.source.name,
        }
      : {
          email: '',
          sourceName: `${this.props.me.firstName} ${this.props.me.lastName} ${new Date().getTime().toString()}`,
        };

    componentDidMount() {
      if (this.state.email === '') {
        this.props.fetchGoogleAuctionsEmail();
      }
    }

    componentDidUpdate() {
      if (this.props.email && this.props.email !== this.state.email) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({email: this.props.email});
      }
    }

    onCloseInternal = () => {
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    createBtnClicked = () => {
      if (this.isUpdate) {
        this.props.updateDataSource({
          ...this.props.source,
          email: this.state.email,
        });
        this.props.onClose();
        return;
      }
      this.props.createDataSource({
        type: getTypeDetails(this.props.sourceType.type).type,
        name: this.state.sourceName,
        email: this.state.email,
      });
    };

    render() {
      const {isOpen, isLoading, sourceError, source, sourceType, isViewOnly, isEditable, isLoadingEmail} = this.props;
      const {email, sourceName} = this.state;

      return (
        <DataSourceModal
          isOpen={isOpen}
          isContinueDisabled={!email || !sourceName}
          isProcessing={isLoading}
          isViewOnly={isViewOnly}
          isRenameEnabled={isEditable}
          source={source}
          sourceType={sourceType}
          logoImageClass="source-logo-adobe"
          onClose={this.onCloseInternal}
          onContinue={this.createBtnClicked}
        >
          <div styleName="inputs-wrapper">
            {!isViewOnly && (
              <input
                type="text"
                onChange={(e) => this.setState({sourceName: e.target.value})}
                placeholder="Source Name"
                readOnly={isViewOnly}
                disabled={isViewOnly}
                value={sourceName}
              />
            )}

            <input type="text" placeholder="Loading ..." readOnly disabled={isLoadingEmail} value={email} />

            <CopyToClipboardButton
              copyStr={email}
              tooltipMessage="Copy the email to clipboard"
              styleName="copy-clipboard-btn-pos"
              shouldUseIcon
            />

            {!isLoading && sourceError ? (
              <div styleName="error-wrapper">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" /> {sourceError.uMessage}
                </span>
              </div>
            ) : null}

            <div styleName="extra-info">
              <a
                href="https://support.anodot.com/hc/en-us/articles/360021427220"
                rel="noopener noreferrer"
                target="_blank"
              >
                more info
              </a>
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
