import {palette} from 'app/styles/theme';

const PERCENTAGE_RANGE = {
  Low: {value: 0, color: palette.gray[400]},
  Medium: {value: 85, color: palette.blue[500]},
  High: {minValue: 85, maxValue: 95, color: palette.orange[400]},
  Max: {minValue: 95, maxValue: 100, color: palette.red[500]},
};

export default PERCENTAGE_RANGE;
