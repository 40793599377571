// @flow
import React, {useEffect, useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {fetchGroupsData} from 'admin.users/store/selectors';
import {getAlertConfigurationsItems} from 'alerts.management/store/selectors';
import AvatarBadge, {COLORS} from 'common/componentsV2/AvatarBadge';
import {ReactComponent as UserIcon} from 'app/images/profile_empty.svg';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {deleteAssignee, setAssignee} from 'alerts.console.new/api/api';
import {getAlertAssigneeEnabled, isCustomerAdmin as getIsCustomerAdmin, getUserProfile} from 'profile/store/selectors';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import {getUsersDataForAvatar} from 'alerts.console.new/services/alertsConsoleFiltersService';
import fetchGroupAlertsWithMetricsProvider from 'investigationNewAlertConsole/api/fetchGroupAlertsWithMetricsProvider';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import {queryErrorHandler} from 'reactQuery/queryClient';
import {ASSIGN_ACTION_TYPES} from 'alerts.console.new/api/triggeredAlertsCacheUpdate';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

import './TriageHeader.module.scss';

const useStyles = makeStyles((theme) => ({
  assigneeWrapper: {
    display: 'flex',
    '&:hover': {
      '& $closeIcon': {
        opacity: 1,
      },
    },
  },
  closeIcon: {
    borderRadius: 1,
    fontSize: 12,
    opacity: 0,
    width: 0,
  },
  userIcon: {
    width: 22,
    height: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 24,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.gray[400]}`,
    marginTop: 0,
    marginLeft: 0,
  },
  usersList: {
    position: 'absolute',
  },
}));

const AlertAssignee = ({queryParams}: {queryParams: Object}) => {
  const isAlertAssigneeEnabled = useSelector(getAlertAssigneeEnabled);

  const classes = useStyles();
  const me = useSelector(getUserProfile);
  const alertList = useSelector(getAlertConfigurationsItems);
  const isCustomerAdmin = useSelector(getIsCustomerAdmin);
  const groupsData = useSelector(fetchGroupsData);

  const triggeredAlertGroupProvider = fetchGroupAlertsWithMetricsProvider(queryParams);
  const trigger = triggeredAlertGroupProvider?.useQueryGetTriggeredAlert()?.data;

  const triggeredAlertsProvider = fetchTriggeredAlertsProvider();
  const users = fetchUsersProvider()?.useQuery()?.data;

  const usersData = useMemo(() => {
    return getUsersDataForAvatar(users, groupsData);
  }, [users, groupsData]);

  const [assigneeUser, setAssigneeUser] = useState(usersData?.find((user) => user.id === trigger?.assignee));

  useEffect(() => {
    if (trigger?.assignee) {
      setAssigneeUser(usersData?.find((user) => user.id === trigger?.assignee));
    } else {
      setAssigneeUser(undefined);
    }
  }, [trigger?.assignee]);

  const alertAutoAssignmentGroup = useMemo(() => {
    return alertList.find((alert) => alert.data.id === trigger?.alertConfigurationId)?.data?.autoAssignmentGroup;
  }, [alertList, trigger?.alertConfigurationId]);

  const filteredUsersList = useMemo(() => {
    // if the alert has auto assigned group - allow assigning only users inside that group
    // if there is no auto assigned group - allow selecting all users
    return usersData
      .filter(
        (user) => !alertAutoAssignmentGroup || user.allGroups?.find((group) => group === alertAutoAssignmentGroup),
      )
      .map((user) => {
        return {label: user.fullName, value: user.id};
      });
  }, [usersData, alertAutoAssignmentGroup]);

  const isEditable = useMemo(() => {
    // Enable change assignee only for Admins or users from the same group
    return (
      isCustomerAdmin || !alertAutoAssignmentGroup || me.groups.find((group) => group === alertAutoAssignmentGroup)
    );
  }, [isCustomerAdmin, alertAutoAssignmentGroup]);

  const handleChange = async (selectedAssignee) => {
    triggeredAlertGroupProvider.syncAssigneeInCache({
      alertTriggerId: trigger?.alertTriggerId,
      assignee: selectedAssignee?.value,
      action: ASSIGN_ACTION_TYPES.ASSIGN,
    });
    try {
      await setAssignee({alertTriggerId: trigger?.alertTriggerId, assignee: selectedAssignee.value}).promise;
      await triggeredAlertsProvider.invalidate(QUERY_KEYS.triggeredAlerts);
    } catch (err) {
      const error = {
        title: 'Assignment error',
        description: `Error while assigning alert to ${selectedAssignee.label}.\nError is: ${err}`,
      };
      queryErrorHandler(error);
      // In case of exception, need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
      await triggeredAlertGroupProvider.invalidate(QUERY_KEYS.triggeredAlertGroupMetrics);
    }
  };

  const handleRemove = async () => {
    triggeredAlertGroupProvider.syncAssigneeInCache({
      alertTriggerId: trigger?.alertTriggerId,
      action: ASSIGN_ACTION_TYPES.UN_ASSIGN,
    });
    try {
      await deleteAssignee({alertTriggerId: trigger?.alertTriggerId}).promise;
      await triggeredAlertsProvider.invalidate(QUERY_KEYS.triggeredAlerts);
    } catch (err) {
      const error = {
        title: 'Assignment error',
        description: `Error while trying to un-assign user from alert.\nError is: ${err}`,
      };
      queryErrorHandler(error);
      // In case of exception, need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
      await triggeredAlertGroupProvider.invalidate(QUERY_KEYS.triggeredAlertGroupMetrics);
    }
  };

  const emptyAvatar = isEditable ? (
    <i className={classes.userIcon}>
      <UserIcon />
    </i>
  ) : null;

  const assigneeAvatar = assigneeUser ? (
    <div className={classes.assigneeWrapper}>
      <AvatarBadge
        isSmall
        colorSchema={assigneeUser.defaultGroup ? assigneeUser.defaultGroup?.colorSchema : COLORS.GRAY}
        text={assigneeUser.abbr}
      />
      {isEditable && (
        <div className={classes.closeIcon}>
          <i className="icon icn-general16-closea alert-clickable-item" onClick={handleRemove} />
        </div>
      )}
    </div>
  ) : (
    emptyAvatar
  );

  return isAlertAssigneeEnabled ? (
    <Tooltip
      content={assigneeUser?.fullName ? assigneeUser.fullName : 'Assignee'}
      type={TYPES.SMALL}
      delay={500}
      placement="bottom"
    >
      <div className="alert-clickable-item" styleName="assignee" role="button">
        <FormDdlSelect
          button={assigneeAvatar}
          placement="auto"
          options={filteredUsersList}
          disabled={!isEditable}
          isUseSearch
          optionComponent={<OptionComponentSimple />}
          onChange={handleChange}
          width={290}
          height={230}
          automationId="assigneeSelectionTriage"
        />
      </div>
    </Tooltip>
  ) : null;
};

export default React.memo(AlertAssignee);
