import {useMutation, useQueryClient} from 'react-query';

import {put} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {setCleanStream} from 'dataCollectors/services/eventStreamService';

const root = 'api/v1/bc/';

const putDataStream = (id, stream) => {
  const cleanStream = setCleanStream(stream);
  return put(`${root}event-streams/${id}`, cleanStream);
};

export default function useUpdateEventStream() {
  const queryClient = useQueryClient();

  const {mutate: updateEventStream, isLoading: isUpdateEventStreamLoading} = useMutation(
    ({streamId, payload}) => putDataStream(streamId, payload),
    {
      onSuccess: (data, meta) => {
        queryClient.invalidateQueries([QUERY_KEYS.streams]);
        meta.onSuccess(data, meta.payload.id);
      },
    },
  );

  return {updateEventStream, isUpdateEventStreamLoading};
}
