import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/';

const getDataStreams = () => get(`${root}data-streams`);

export default function useFetchDataStreams() {
  const formatDataStream = useCallback((data) => {
    return data.map((i) => ({...i, label: i.name, value: i.id}));
  });

  const fallback = [];
  const {data: streams = fallback, isLoading: isDataStreamsLoading} = useQuery(QUERY_KEYS.dataStreams, getDataStreams, {
    select: formatDataStream,
  });

  return {
    streams,
    isDataStreamsLoading,
  };
}
