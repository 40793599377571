import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import * as amSelectors from 'alerts.management/store/selectors';
import * as actions from 'alerts.management/store/actions';
import * as metricsSelectors from 'metrics/store/selectors';
import AbsPercentageConditionTemplate from './AbsPercentageConditionTemplate';

const MinimumDeltaCondition = () => {
  const dispatch = useDispatch();
  const deltaCondition = useSelector(amSelectors.getDeltaCondition);
  const minDurationUI = useSelector(amSelectors.getSimulationFiltersMinDurationUI);
  const rollup = useSelector(amSelectors.getSimulationFiltersRollup);
  const isLoadingEstimations = useSelector(amSelectors.simulationFiltersIsLoadingEstimations);
  const isLoadingEstimationsCalced = isLoadingEstimations && deltaCondition && deltaCondition.enableAutoTuning;

  const onPercentChange = useCallback((value) => dispatch(actions.setSelectedAlertMinDeltaPercent(value)), [dispatch]);
  const onAbsChange = useCallback((value) => dispatch(actions.setSelectedAlertMinDeltaAbs(value)), [dispatch]);
  const onAutoBoolValChange = useCallback((value) => dispatch(actions.setSelectedAlertMinDeltaAutoBoolVal(value)), [
    dispatch,
  ]);
  const setDeltaDurationEnabled = useCallback((value) => dispatch(actions.setDeltaDurationEnabled(value)), [dispatch]);
  const setDeltaDurationNumOfPoints = useCallback((value) => dispatch(actions.setDeltaDurationNumOfPoints(value)), [
    dispatch,
  ]);
  const alertSimulation = useSelector(amSelectors.getAlertSimulation);
  const isCompositeDataLoading = useSelector((state) => metricsSelectors.getCompositeDataIsLoading(state));

  return (
    <AbsPercentageConditionTemplate
      tooltipContent="Set the minimum value of an anomaly (from the normal range) in order to trigger an alert,
      either in percent or actual value. Optional."
      automationId="minDelta"
      title="Minimum Delta"
      denyZeroAbsolute
      absValue={deltaCondition.deltaAbsolute}
      percentValue={deltaCondition.deltaPercentage}
      onPercentChange={onPercentChange}
      onAbsChange={onAbsChange}
      enableAutoBoolVal={deltaCondition.enableAutoTuning}
      enableAutoIsLoading={isLoadingEstimationsCalced}
      onAutoBoolValChange={onAutoBoolValChange}
      rollup={rollup}
      minDurationUI={minDurationUI}
      deltaDuration={deltaCondition.deltaDuration}
      setDeltaDurationEnabled={setDeltaDurationEnabled}
      setDeltaDurationNumOfPoints={setDeltaDurationNumOfPoints}
      id={deltaCondition.id}
      absoluteElement={<Box css={{opacity: 0.5}}>Absolute Value</Box>}
      percentageElement={<Box css={{opacity: 0.5}}>Percentage</Box>}
      isLoading={alertSimulation.isLoading || isCompositeDataLoading}
      enableSpinner={deltaCondition.enableAutoTuning}
    />
  );
};

export default React.memo(MinimumDeltaCondition);
