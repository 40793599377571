// @flow
import React from 'react';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import connect from 'react-redux/es/connect/connect';
import * as selector from 'alerts.management/store/selectors';
import {simpleAlertSetTimeScale} from 'alerts.management/store/actions';
import './TimeScaleDdl.module.scss';

type PropTypes = {
  disabled: boolean,
  simpleAlertSetTimeScale: Function,
  timeScale: String,
  timeScaleOptions: Array,
};
export default connect(
  (state) => ({
    timeScale: selector.getSimpleAlertSelectedTimeScale(state),
    timeScaleOptions: selector.getSimpleAlertTimeScaleOptions(state),
  }),
  {
    simpleAlertSetTimeScale,
  },
)(
  class TimeScaleDdl extends React.PureComponent {
    props: PropTypes;

    onChange = (item) => {
      this.props.simpleAlertSetTimeScale(item);
    };

    render() {
      const {timeScale, timeScaleOptions} = this.props;
      return (
        <SelectAndt
          automationId="simpleAlertTimeScale"
          styleName="time-scale"
          type={TYPE_NEW_NO_SEARCH}
          theme={THEME_BLUE_LEAN}
          onChange={this.onChange}
          options={timeScaleOptions}
          value={timeScale}
          placeholder="Scale"
          optionHeight={40}
          buttonWidth={100}
          menuWidth={150}
          getOptionLabel={(val) => val.charAt(0).toUpperCase() + val.slice(1)}
          getOptionValue={(val) => val}
          disabled={this.props.disabled}
        />
      );
    }
  },
);
