// @flow
import React, {Fragment, useCallback, useContext, useEffect, useReducer, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEqual} from 'lodash';
import {useField} from 'react-final-form';
import TooltipArea from 'common/componentsV2/TooltipArea';
import * as amSelectors from 'alerts.management/store/selectors';
import './EventsInput.module.scss';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import * as metricsSelectors from 'metrics/store/selectors';
import {fetchEventsCount, getMultipleEvents, setShouldShowEvents} from 'userEvents/store/actions';
import {getEventsCount, getUserMultipleEventsExecute, getAggregation, getDateRange} from 'userEvents/store/selectors';
import {fetchPropertiesApi} from 'metrics/store/actions';
import {getUniqueId} from 'common/utils/guid';
import AlertSettingsContext from 'alerts.management/components/editor/simulationArea/context';
import {getIsEnableSimulationReferenceDate} from 'profile/store/selectors';
import EventDetail from './EventDetails';
import {eventOptions, localActions, localReducer, localInitialState, createJsonFromState} from './EventsFilterService';

const EventsMultipleInput = () => {
  const {
    input: {value: eventsFilters, onChange: onEventsFiltersChange},
  } = useField('eventsFilters', {isEqual});

  const {
    input: {onChange: onEventChange},
  } = useField('events', {isEqual});

  const {
    input: {onChange: changeShouldShowEvents},
  } = useField('shouldShowEvents');

  const {
    input: {onChange: changeIsInfluencingEvents},
  } = useField('isInfluencingEvents');

  const dispatch = useDispatch();

  const expressionTree = useSelector(metricsSelectors.getExpressionTree);
  const rollup = useSelector(amSelectors.getSimulationFiltersRollup);
  const executeResponse = useSelector(getUserMultipleEventsExecute);
  const eventsCount = useSelector(getEventsCount);
  const aggregation = useSelector(getAggregation);
  const dateRange = useSelector(getDateRange);
  const isSimulationReferenceDateEnabled = useSelector(getIsEnableSimulationReferenceDate);

  const {isAutoSimulationEnabled} = useContext(AlertSettingsContext);

  useEffect(() => {
    const payload = {
      name: {
        auto: true,
        prefix: null,
      },
      displayOnly: true,
      excludeComposites: true,
      filter: {
        function: 'alphanumeric',
        parameters: [
          {
            name: 'Top N',
            value: 10,
          },
        ],
        children: [],
        id: getUniqueId(),
        type: 'function',
      },
      scalarTransforms: [
        {
          function: 'current',
          children: [],
          id: getUniqueId(),
          parameters: [],
          type: 'function',
        },
      ],
      context: '',
      expressionTree,
    };

    const isExpressionEmpty = expressionTree.root.type === 'function' && expressionTree.root.function === '';
    if (!isExpressionEmpty) {
      dispatch(fetchPropertiesApi(payload));
    }
  }, [JSON.stringify(expressionTree)]);

  const isEmptyFunction =
    expressionTree.root &&
    expressionTree.root.function === '' &&
    expressionTree.root.children.length === 1 &&
    expressionTree.root.children[0].type === 'metric';

  const isRollupRespected = ['long', 'longlong', 'weekly'].includes(rollup);
  const isInfluencingDisabled = isEmptyFunction || !isRollupRespected;

  const [localState, localDispatch] = useReducer(localReducer, localInitialState);

  const addEvent = useCallback((event) => {
    localDispatch({type: localActions.ADD_EVENT_FILTER, payload: event});
  }, []);

  useEffect(() => {
    const now = new Date().getTime();
    dispatch(fetchEventsCount(`fromDate=0&toDate=${Math.floor(now / 1000)}`));
  }, []);

  const lastCall = useRef();

  useEffect(() => {
    if (localState.validation.length > 0 && !localState.validation.some((v) => v.isValid)) {
      return;
    }
    if (localState.events.length > 0) {
      changeShouldShowEvents(true);
    }
    const stateJson = createJsonFromState(localState, aggregation);
    const validEvents = [];
    localState.validation.forEach((val, index) => {
      if (val.isValid) {
        validEvents.push(localState.events[index]);
      }
    });

    if (JSON.stringify(lastCall.current) !== JSON.stringify(validEvents)) {
      changeIsInfluencingEvents(true);
      dispatch(setShouldShowEvents(true));
      if (validEvents.length > 0) {
        onEventChange(validEvents[0].expression);
      }
      onEventsFiltersChange(stateJson);
      if (validEvents.length > 0) {
        dispatch(getMultipleEvents({validEvents, isAutoSimulationEnabled, isSimulationReferenceDateEnabled}));
      }
      lastCall.current = JSON.stringify(validEvents);
    }
    if (!localState.validation.some((val) => val.isValid)) {
      changeIsInfluencingEvents(false);
      dispatch(setShouldShowEvents(false));
    }
  }, [isInfluencingDisabled, localState?.checkCount, dateRange, expressionTree]);

  useEffect(() => {
    if (localState.events.length >= 0) {
      localDispatch({
        type: localActions.CHECK_VALIDATION,
        payload: isInfluencingDisabled,
      });
    }
  }, [JSON.stringify(localState.events), isInfluencingDisabled]);

  useEffect(() => {
    if (eventsFilters && eventsFilters.length) {
      eventsFilters.forEach((event) => {
        if (localState.events.length === 0) {
          localDispatch({type: localActions.ADD_INIT_EVENT_FILTER, payload: event});
        }
      });
    }
  }, [JSON.stringify(eventsFilters)]);

  useEffect(() => {
    if (executeResponse && executeResponse.data && Array.isArray(executeResponse.data)) {
      // eslint-disable-next-line no-unused-expressions
      executeResponse.data?.forEach((res) => {
        if (res.id) {
          localDispatch({type: localActions.SET_TOTAL_EVENTS, payload: {id: res.id, totalEvents: res.totalEvents}});
        }
      });
    }
  }, [JSON.stringify(executeResponse)]);

  const count = useCallback(
    (event) => {
      if (eventsCount.data) {
        if (event.eventType.value === 'SHOW') {
          return eventsCount.data.INFLUENCE;
        }
        return eventsCount.data[event.eventType.value];
      }
      return '-';
    },
    [JSON.stringify(eventsCount)],
  );

  return (
    <TooltipArea
      isAlwaysVisible
      automationId="eventsConditions"
      text="Add multiple event conditions. Display / Influencing / Suppressing / Office hours events. Learn more in our docs."
    >
      {(info) => (
        <Fragment>
          <div styleName="container">
            <div styleName="title">Events{info}</div>
            <FormDdlSelect
              options={eventOptions}
              button={
                <div styleName="add">
                  <i styleName="ico" className="icon icn-action16-plusb" />
                  Add
                </div>
              }
              optionComponent={<OptionComponentSimple />}
              onChange={addEvent}
              width={180}
              automationId="correlateAnomaliesWithEvents"
              position="right"
            />
          </div>
          {localState.events.map((event, index) => (
            <EventDetail
              automationId={index + event.eventType.label}
              key={event.id}
              details={event}
              validation={localState.validation[index]}
              localDispatch={localDispatch}
              count={count(event)}
            />
          ))}
        </Fragment>
      )}
    </TooltipArea>
  );
};

export default React.memo(EventsMultipleInput);
