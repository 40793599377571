export default {
  email: 'https://support.anodot.com/hc/en-us/articles/208206905-Anodot-Email-integration',
  jira: 'https://support.anodot.com/hc/en-us/articles/360015193313-Anodot-JIRA-integration',
  mattermost: 'https://support.anodot.com/hc/en-us/articles/5771732481042-Anodot-Mattermost-integration',
  msteams: 'https://support.anodot.com/hc/en-us/articles/360023167973-Anodot-Microsoft-Teams-integration',
  opsgenie: 'https://support.anodot.com/hc/en-us/articles/360015904454-Anodot-Opsgenie-integration',
  pagerduty: 'https://support.anodot.com/hc/en-us/articles/360002657494-Anodot-PagerDuty-integration',
  salesforce: 'https://support.anodot.com/hc/en-us/articles/6684064700060',
  servicenow: 'https://support.anodot.com/hc/en-us/articles/6412890606738-Anodot-ServiceNow-Integration',
  slack: 'https://support.anodot.com/hc/en-us/articles/207497499-Anodot-Slack-integration',
  slackapp: 'https://support.anodot.com/hc/en-us/articles/360021042120-Anodot-Slack-Application',
  sns: 'https://support.anodot.com/hc/en-us/articles/360018891020-Anodot-AWS-SNS-integration',
  tamtam: 'https://support.anodot.com/hc/en-us/articles/360013667780-Anodot-TamTam-Chat-integration',
  telegram: 'https://support.anodot.com/hc/en-us/articles/5371065910930-Anodot-Telegram-Integration',
  webhook: 'https://support.anodot.com/hc/en-us/articles/208206925-Anodot-Webhook-integration',
};
