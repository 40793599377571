// @flow
import React from 'react';
import './FormDdlSearchBar.module.scss';

type PropTypes = {
  onChange: Function,
  value: string,
  isAutofocus: boolean,
  automationId: string,
};

export default class FormDdlSearchBar extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    if (this.input && this.props.isAutofocus) {
      setTimeout(() => {
        if (this.input) {
          this.input.focus();
        }
      }, 500);
    }
  }

  render() {
    const {onChange} = this.props;

    return (
      <div className="alert-clickable-item" styleName="root" automation-id={this.props.automationId}>
        <i className="icon icn-general16-search" />
        <input
          ref={(input) => {
            this.input = input;
          }}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={this.props.value}
          placeholder="Search"
          autoFocus={this.props.isAutofocus}
        />
        {this.props.value ? (
          <i
            className="icon icn-general16-closeb"
            styleName="close-icon"
            onClick={(e) => {
              e.stopPropagation();
              onChange('');
            }}
          />
        ) : null}
      </div>
    );
  }
}
