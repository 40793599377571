/* eslint-disable complexity */
import {composeReducers} from 'common/utils/reducers';
import {allowedFilterKeys, DEFAULT_QUERY_PARAMS} from 'alerts.management/services/alertManagementService';
import {resolutionTypes} from 'metrics/services/metricsService';
import {intersection} from 'lodash';
import * as commonActions from 'common/store/actions';
import {fetchDashboards, fetchDashboardsV1} from 'dashboards/store/actions';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const cleanFilterVal = (filterVal, allowedVals, defaultVal) => {
  if (filterVal === defaultVal) {
    return defaultVal;
  }

  const filterArr = filterVal.split(',');
  const intersect = intersection(filterArr, allowedVals);

  if (intersect.length === 0) {
    return defaultVal;
  }
  if (filterArr.length === intersect.length) {
    return filterVal;
  }
  if (intersect.length === 1) {
    return intersect[0];
  }
  return intersect.join(',');
};

const cleanLegalFilterValues = (filters) => {
  const overWrite = {
    whose: cleanFilterVal(filters.whose, Object.keys(allowedFilterKeys.whose), DEFAULT_QUERY_PARAMS.whose),
    status: cleanFilterVal(filters.status, Object.keys(allowedFilterKeys.status), DEFAULT_QUERY_PARAMS.status),
    type: cleanFilterVal(filters.type, Object.keys(allowedFilterKeys.type), DEFAULT_QUERY_PARAMS.type),
    severity: cleanFilterVal(filters.severity, Object.keys(allowedFilterKeys.severity), DEFAULT_QUERY_PARAMS.severity),
    anomalyDirection: cleanFilterVal(
      filters.anomalyDirection,
      Object.keys(allowedFilterKeys.anomalyDirection),
      DEFAULT_QUERY_PARAMS.anomalyDirection,
    ),
    timeScale: cleanFilterVal(filters.timeScale, Object.keys(resolutionTypes), DEFAULT_QUERY_PARAMS.timeScale),
    notifications: filters.notifications === true ? filters.notifications : DEFAULT_QUERY_PARAMS.notifications,
    recommendations: filters.recommendations === true ? filters.recommendations : DEFAULT_QUERY_PARAMS.recommendations,
  };

  return {
    ...filters,
    ...overWrite,
  };
};

const alertManagerReducer = composeReducers(
  (
    state = {
      selectedItemId: '',
      alertListOrder: {column: 'title', direction: 'asc'},
      filters: {
        ...DEFAULT_QUERY_PARAMS,
      },
      selectedAlertsCheckbox: EMPTY_ARRAY,
      alertManagementKeyVal: {currentDropdownOpen: ''},
      deleteAlertId: '',
      selectedAlertTab: 0,
      estimations: EMPTY_ARRAY,
      dashboardLinks: EMPTY_ARRAY,
      streamMetricCount: EMPTY_ARRAY,
      influencingMetrics: EMPTY_ARRAY,
      nameModal: EMPTY_OBJECT,
      retryNameCounter: 0,
      alertToScrollAndOpen: '',
      alertFiltersIsMore: false,
    },
    {type, payload, meta},
  ) => {
    switch (type) {
      case actions.setSelectedAlert.TYPE:
        return state.selectedItemId !== payload
          ? {
              ...state,
              selectedItemId: payload,
            }
          : {
              ...state,
              selectedItemId: '',
            };
      case commonActions.routeChanged.TYPE:
        return {
          ...state,
          selectedItemId: '',
          selectedAlertsCheckbox: EMPTY_ARRAY,
          estimations: EMPTY_ARRAY,
          dashboardLinks: EMPTY_ARRAY,
        };
      case actions.setAlertListOrder.TYPE:
        return {
          ...state,
          alertListOrder: {...payload},
        };
      case actions.setAlertFilters.TYPE:
        return {
          ...state,
          filters: {
            ...cleanLegalFilterValues(payload),
          },
        };
      case actions.setAlertFiltersKeyVal.TYPE:
        return {
          ...state,
          filters: {
            ...state.filters,
            ...payload,
          },
        };
      case actions.setAlertFiltersIsMore.TYPE:
        return {
          ...state,
          alertFiltersIsMore: payload,
        };
      case actions.singleAlertCheckboxClick.TYPE: {
        const index = state.selectedAlertsCheckbox.findIndex((alert) => alert === payload);
        if (index > -1) {
          return {
            ...state,
            selectedAlertsCheckbox: [
              ...state.selectedAlertsCheckbox.slice(0, index),
              ...state.selectedAlertsCheckbox.slice(index + 1),
            ],
          };
        }
        return {
          ...state,
          selectedAlertsCheckbox: [...state.selectedAlertsCheckbox, payload],
        };
      }
      case actions.setSelectedAlertTab.TYPE:
        return {
          ...state,
          selectedAlertTab: payload,
        };
      case actions.setAlertManagementKeyVal.TYPE:
        return {
          ...state,
          alertManagementKeyVal: {
            ...state.alertManagementKeyVal,
            ...payload,
          },
        };
      case actions.setCheckboxAll.TYPE:
        return {
          ...state,
          selectedAlertsCheckbox: [...payload],
        };
      case actions.deleteAlert.TYPE: {
        const index = state.selectedAlertsCheckbox.findIndex((alert) => alert === payload);
        return {
          ...state,
          selectedAlertsCheckbox: state.selectedAlertsCheckbox
            .slice(0, index)
            .concat(state.selectedAlertsCheckbox.slice(index + 1)),
          deleteAlertId: payload,
        };
      }
      case actions.deleteAlertBulkApi.success.TYPE: {
        return {
          ...state,
          selectedAlertsCheckbox: EMPTY_ARRAY,
        };
      }
      case actions.duplicateAlert.TYPE:
        return {
          ...state,
          duplicateAlertId: payload,
        };
      case actions.executeEstimation.success.TYPE:
        if (meta) {
          return {
            ...state,
            estimations: [
              ...state.estimations,
              {id: meta, text: payload.totalEstimation, minDelta: payload.minDeltaEstimation},
            ],
          };
        }
        return state;
      case fetchDashboardsV1.success.TYPE:
      case fetchDashboards.success.TYPE: {
        return {
          ...state,
          dashboardLinks: [
            ...state.dashboardLinks,
            ...payload.dashboards.flatMap((dashboard) => {
              return dashboard.tiles.flatMap((tile) => {
                const alert = (dashboard.tileAlerts || EMPTY_ARRAY).find((tileAlert) => tileAlert.tileId === tile.id);
                if (alert) {
                  return [
                    {
                      id: alert.alertId,
                      text: tile.title.text ? `${dashboard.name} - ${tile.title.text}` : dashboard.name,
                      url:
                        type === fetchDashboardsV1.success.TYPE
                          ? `#!/dashboards?activeTab=1&tabs=main;${dashboard._id}`
                          : `#!/r/dashboards/${dashboard._id}`,
                    },
                  ];
                }
                return [];
              });
            }),
          ],
        };
      }
      case actions.setStreamMetricCountToAlert.TYPE: {
        return {
          ...state,
          streamMetricCount: [...state.streamMetricCount, {id: payload.id, size: payload.size}],
        };
      }
      case actions.fetchInfluencingMetricsInfo.success.TYPE:
        return {
          ...state,
          influencingMetrics: [...state.influencingMetrics, {id: meta, what: payload.what}],
        };
      case actions.openAlertNameModal.TYPE:
        return {
          ...state,
          nameModal: payload,
        };
      case actions.createAlert.success.TYPE: {
        if (
          payload &&
          payload.eventValidationResult &&
          payload.eventValidationResult.alertValidation &&
          payload.eventValidationResult.alertValidation.failures.length > 0 &&
          payload.eventValidationResult.alertValidation.failures[0].id === 6009
        ) {
          return {
            ...state,
            retryNameCounter: state.retryNameCounter + 1,
          };
        }
        return {
          ...state,
          retryNameCounter: 0,
        };
      }
      case actions.setAlertToScrollAndOpen.TYPE: {
        if ((meta && meta.preserveFilters) || state.preserveFilters) {
          return {
            ...state,
            alertToScrollAndOpen: payload,
            preserveFilters: true,
          };
        }
        return {
          ...state,
          alertToScrollAndOpen: payload,
          filters: {
            ...DEFAULT_QUERY_PARAMS,
          },
        };
      }
      default:
        return state;
    }
  },
);

export default alertManagerReducer;
