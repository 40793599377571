// @flow
import React from 'react';
import {resolutions, resolutionsShort} from 'bc/services/dataStreamService';
import {getIsGAShortIntervalEnabled} from 'profile/store/selectors';
import SelectAndt, {THEME_BLUE, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {useSelector} from 'react-redux';

type PropTypes = {resolution: number, interval: string, onChange: Function};

const GaResolution = (props: PropTypes) => {
  const isGAShortIntervalEnabled = useSelector(getIsGAShortIntervalEnabled);
  let resolutionsValues = Object.values(resolutions);
  if (isGAShortIntervalEnabled) {
    resolutionsValues = Object.values(resolutionsShort);
  }
  const selectedIndex = resolutionsValues.findIndex((val) =>
    props.resolution !== null ? val.key === props.resolution : val.key === props.interval,
  );

  return (
    <div role="presentation" onClick={(evt) => evt.preventDefault()}>
      <SelectAndt
        id="GaResolution"
        automationId="gaResolution"
        className="andt-dropdown"
        options={resolutionsValues}
        getOptionLabel={(val) => val.label}
        getOptionValue={(val) => val.key}
        type={TYPE_NO_SEARCH}
        theme={THEME_BLUE}
        value={resolutionsValues[selectedIndex]}
        onChange={(item) => props.onChange(item.key)}
        placeholder="Timescale"
      />
    </div>
  );
};

export default GaResolution;
