const STAGES_INITIAL_STATE = {
  hoverItemId: '',
  selectedItemId: '',
};
const STAGES_ACTIONS = {
  SET_HOVER_ITEM_ID: 'setHoverId',
  SET_SELECTED_ITEM_ID: 'setSelectedItemId',
};

function useEditReducer(state, {type, payload}) {
  switch (type) {
    case STAGES_ACTIONS.SET_HOVER_ITEM_ID:
      return {
        ...state,
        hoverItemId: payload,
      };
    case STAGES_ACTIONS.SET_SELECTED_ITEM_ID:
      return {
        ...state,
        selectedItemId: state.selectedItemId === payload ? '' : payload,
      };

    default:
      throw new Error('useEditReducer action type is incorrect.');
  }
}

export {useEditReducer, STAGES_INITIAL_STATE, STAGES_ACTIONS};
