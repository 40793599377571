import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {Observable} from 'rxjs';
import moment from 'moment';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

const fetchMixpanelEntities = makeAsyncEpic(actions.fetchMixpanelEntities, api.fetchMixpanelEntities);
const fetchMixpanelEntityMetadata = makeAsyncEpic(actions.fetchMixpanelEntityMetadata, api.fetchMixpanelEntityMetadata);
const verifyMixpanelQuery = makeAsyncEpic(actions.verifyMixpanelQuery, api.verifyMixpanelQuery);
const testConnectionDataSource = makeAsyncEpic(actions.testConnectionDataSource, api.testConnectionDataSource);

const fetchMixpanelQueryPreview = (action$, {getState}) =>
  action$.ofType(actions.fetchMixpanelQueryPreview.TYPE).switchMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());

    return api
      .fetchMixpanelQueryPreview({
        payload: {
          query: action.payload.query,
          historyRange: null,
          dataSourceId: stream.dataSourceId,
          timeZone: stream.timeZone || moment.tz.guess(),
        },
      })
      .map((payload) => actions.fetchMixpanelQueryPreview.success(payload, action.meta))
      .catch((error) => Observable.of(actions.fetchMixpanelQueryPreview.failure(error, action.meta)));
  });

const setMixpanelDiametricsStreamAnalysisSchemaFromEntity = (action$) =>
  action$
    .ofType(actions.fetchMixpanelEntityMetadata.success.TYPE)
    .flatMap((action) => [actions.setMixpanelDiametricsStreamAnalysisSchema(action.payload)]);

const setMixpanelDiametricsStreamAnalysisSchemaFromQuery = (action$, {getState}) =>
  action$.ofType(actions.verifyMixpanelQuery.success.TYPE).flatMap(() => {
    const previewQuery = selectors.getMixpanelStreamPreview(getState());
    return [actions.setMixpanelDiametricsStreamAnalysisSchema(previewQuery.data.columns)];
  });

const createMixpanelDataSource = (action$) =>
  action$
    .ofType(actions.createMixpanelDataSource.TYPE)
    .flatMap(({payload}) => [actions.testConnectionDataSource(payload, payload)]);

const testConnectionDataSourceSuccess = (action$) =>
  action$.ofType(actions.testConnectionDataSource.success.TYPE).flatMap(({meta}) => {
    return [actions.createDataSource(meta)];
  });

const mixpanelEpic = combineEpics(
  fetchMixpanelEntities,
  fetchMixpanelEntityMetadata,
  fetchMixpanelQueryPreview,
  verifyMixpanelQuery,
  setMixpanelDiametricsStreamAnalysisSchemaFromEntity,
  setMixpanelDiametricsStreamAnalysisSchemaFromQuery,
  createMixpanelDataSource,
  testConnectionDataSource,
  testConnectionDataSourceSuccess,
);

export default mixpanelEpic;
