// @flow
import React from 'react';
import {connect} from 'react-redux';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import {
  fetchGoogleGA4Templates,
  applyGA4TemplateOnSelectedStream as applyGA4TemplateOnSelectedStreamAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

import './Ga4Template.module.scss';

type PropTypes = {
  dataStream: Object,
  isLoading: boolean,
  templates: Array,
  selectedTemplate: Object,
  fetchGoogleGA4Templates: Function,
  applyGA4TemplateOnSelectedStream: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoading: selectors.getGoogleGA4Templates(state).isLoading,
    templates: selectors.getGoogleGA4TemplatesItems(state),
    selectedTemplate: selectors.getGoogleGA4StreamBaseTemplate(state),
  }),
  {
    fetchGoogleGA4Templates,
    applyGA4TemplateOnSelectedStream: applyGA4TemplateOnSelectedStreamAction,
  },
)(
  class Ga4Templates extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      this.props.fetchGoogleGA4Templates(this.props.dataStream.type);
    }

    render() {
      const {templates, isLoading, selectedTemplate, applyGA4TemplateOnSelectedStream, dataStream} = this.props;

      const selectedIndex = templates.findIndex((val) => (selectedTemplate ? selectedTemplate.id === val.id : null));
      const isEditable = isStreamEditable(dataStream.state) || isStreamRunning(dataStream.state);

      return (
        <div styleName="advance-root">
          <div styleName="dim-wrapper">
            <SelectAndt
              id="gaTemplatesDdl"
              disabled={!isEditable}
              options={templates}
              getOptionLabel={(val) => val.name}
              getOptionValue={(val) => val.id}
              type={TYPE_NO_SEARCH}
              theme={THEME_LIGHT}
              value={isLoading ? null : templates[selectedIndex]}
              onChange={(option) => applyGA4TemplateOnSelectedStream(option.id)}
              placeholder={isLoading ? 'Loading...' : 'Choose Template'}
              automationId="chooseTemplate"
            />
          </div>
        </div>
      );
    }
  },
);
