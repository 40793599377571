import {useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';
import {syncIgnoreApplyRecommendationByAlertIdInCache} from 'recommendations/api/recommendationsCacheUpdate';

const recommendationsRoot = 'api/v1/recommendations';

const fetchRecommendationsByAlertId = (alertConfiguration) => {
  if (!alertConfiguration?.recommendationId || !alertConfiguration?.id) {
    return undefined;
  }
  return get(`${recommendationsRoot}/${alertConfiguration?.id}`);
};

export default function fetchRecommendationsByAlertIdProvider(alertConfiguration) {
  const queryClient = useQueryClient();

  const queryKey = [QUERY_KEYS.recommendationsByAlertId, alertConfiguration?.id, alertConfiguration?.recommendationId];
  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchQuery: () => queryClient.fetchQuery(queryKey, () => fetchRecommendationsByAlertId(alertConfiguration)),
    useQuery: () =>
      useQuery(queryKey, () => fetchRecommendationsByAlertId(alertConfiguration), {
        retry: false,
      }),
    syncIgnoreApplyRecommendationByAlertIdInCache: (recommendationType, ignoreApplyProperty) =>
      syncIgnoreApplyRecommendationByAlertIdInCache(queryKey, recommendationType, ignoreApplyProperty, queryClient),
  };
}
