// @flow
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './AutocompleteSelection.module.scss';
import OptionComponentAutocomplete from 'common/componentsV2/autocompleteSelection/OptionComponentAutocomplete';
import {fetchPropertiesApi} from 'metrics/store/actions';
import {
  getCurrentCall,
  getExpressionTree,
  getPropertiesApiData,
  getRawFunctionDefinitions,
} from 'metrics/store/selectors';
import {getUniqueId} from 'common/utils/guid';
import VirtualizedListOfComponents from '../VirtualizedListOfComponents';

type PropTypes = {
  onChange: Function,
  inputValue: string,
  automationId?: string,
  children: React.Node, // Children is expected to be a component wrapped with render props function
  panelHeight?: number,
  bottomOffset?: number, // height of input element, to display auto-complete opn top of it
};

const KEY_UP = 38;
const KEY_DOWN = 40;
const KEY_RETURN = 13;
const KEY_ENTER = 14;
const KEY_ESCAPE = 27;
const KEY_TAB = 9;
const separatorOption = [{label: '-------- System Variables --------', value: ''}];
const preDefinedOptions = [
  {label: 'open_time', value: 'open_time', title: 'The time the anomaly of the first metric opened'},
  {
    label: 'open_time_epoch',
    value: 'open_time_epoch',
    title: 'The time the anomaly of the first metric opened (in epoch format)',
  },
  {label: 'trigger_time', value: 'trigger_time', title: 'The time the trigger was sent'},
  {label: 'trigger_time_epoch', value: 'trigger_time_epoch', title: 'The time the trigger was sent (in epoch format)'},
  {label: 'triggerId', value: 'triggerId', title: 'Unique identifier of the alert trigger'},
  {
    label: 'duration',
    value: 'duration',
    title: 'The duration of the anomaly (from the moment it started to the moment the trigger was sent)',
  },
  {label: 'delta', value: 'delta', title: 'The numerical value in the metric value from the baseline'},
  {label: 'direction', value: 'direction', title: 'Whether the change is a spike/drop/anomaly'},
  {label: 'impact', value: 'impact', title: 'The financial impact of the alert'},
  {label: 'state', value: 'state', title: 'The state of the alert (open/updated/closed)'},
];
export const optionHeight = 40;

// Helper function for handleChange, finds the first matching var block for auto complete
// @varBlock - array of strings
// @cursorPosition - int
// @Return - object with selected block information
const selectVarBlock = (varBlocks, cursorPosition) => {
  // Each element in varBlocks represent a split section of in the input, split by {{
  let start = -1;
  // Disregard first block since it is before first {{
  let currentStartingPos = varBlocks[0].length;
  for (let i = 1; i < varBlocks.length; i++) {
    currentStartingPos += 2; // Add space of {{
    const closingPos = varBlocks[i].indexOf('}}');
    // if open block (without closing }})
    if (closingPos === -1) {
      start = currentStartingPos;
      // find the next space
      const spaceIndex = varBlocks[i].indexOf(' ');
      // Since this is open block, varBlockEnd = start of current block + index of following space or the end of the string
      // Return since we are looking for the first block
      return {
        start,
        varBlockEnd: currentStartingPos + (spaceIndex < 0 ? varBlocks[i].length : spaceIndex),
        Search: spaceIndex < 0 ? varBlocks[i] : varBlocks[i].substring(0, spaceIndex),
      };
    }
    // If closed block (with }} and perhaps something after
    // Check if current cursor position is inside {{ }}
    if (cursorPosition >= currentStartingPos && cursorPosition <= currentStartingPos + closingPos) {
      // Open list for var in current cursor position
      start = currentStartingPos;
      // Since this block already have closing }}, varBlockEnd needs to take into consideration the closing }}
      // Therefore it is start of current block + location of }} inside block + 2
      // Return since we are looking for the first block
      return {start, varBlockEnd: currentStartingPos + closingPos + 2, Search: varBlocks[i].substring(0, closingPos)};
    }
    // Move to the next block
    currentStartingPos += varBlocks[i].length;
  }
  return null;
};

const baseBody = {
  // TODO -- GABPAC -- fill it all with real data, not only the expression tree
  name: {auto: true, prefix: null},
  displayOnly: true,
  excludeComposites: true,
  filter: {
    function: 'alphanumeric',
    parameters: [{name: 'Top N', value: 10}],
    children: [],
    id: getUniqueId(),
    type: 'function',
  },
  scalarTransforms: [
    {
      function: 'current',
      children: [],
      id: getUniqueId(),
      parameters: [],
      type: 'function',
    },
  ],
  context: '',
};
const setBodyForFunction = (expTree) => ({
  ...baseBody,
  expressionTree: expTree,
});
const setBodyForMeasure = (expTree) => ({
  ...baseBody,
  expressionTree: {root: expTree.root.children[0]},
});

const AutocompleteSelection = ({
  onChange,
  inputValue,
  automationId,
  children,
  panelHeight,
  bottomOffset,
}: PropTypes) => {
  const currentCall = useSelector(getCurrentCall);
  const dispatch = useDispatch();
  const expressionTree = useSelector(getExpressionTree);
  const properties = useSelector(getPropertiesApiData);
  const functions = useSelector(getRawFunctionDefinitions);
  const [varStart, setVarStart] = useState(-1);
  const [varBlockEnd, setVarBlockEnd] = useState(-1);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState(0);
  const [showVarsList, setShowVarsList] = useState(false);
  const [varOptions, setVarOptions] = useState([]);
  const [filterOptionsByFunction, setFilterOptionsByFunction] = useState(false);
  const [byFunctionOptions, setByFunctionOptions] = useState([]);

  const topStyle = bottomOffset ? {bottom: `${bottomOffset}px`} : null;
  // Fetch properties list based on selected metrics
  useEffect(() => {
    if (currentCall === 'noCache') {
      setVarOptions([]);
    }
    if (expressionTree.root.function.length) {
      dispatch(fetchPropertiesApi(setBodyForFunction(expressionTree)));
    }
    if (!expressionTree.root.function.length) {
      dispatch(fetchPropertiesApi(setBodyForMeasure(expressionTree)));
    }
  }, [currentCall]);

  useEffect(() => {
    if (expressionTree?.root?.function) {
      const functionDef = functions.find((func) => func.name === expressionTree.root.function);
      // if groupBy, ratio pairs, pairs or group functions - present only the group by dimensions
      if (functionDef?.type === 'group' || functionDef?.type === 'grouping') {
        setFilterOptionsByFunction(true);
        // extract group by expression
        let groupByExpression;
        switch (expressionTree.root.function) {
          // for group & groupBy, function, take properties from groupBy expression
          case 'groupBy':
          case 'group':
            groupByExpression = expressionTree.root;
            break;
          // for pairs, take properties from the first group by
          case 'ratioPairs':
          case 'pairs':
            // eslint-disable-next-line prefer-destructuring
            groupByExpression = expressionTree.root.children[0];
            break;
          default:
            groupByExpression = null;
        }
        // extract group py properties from group by expression
        const groupByValue =
          groupByExpression && groupByExpression.parameters
            ? groupByExpression.parameters.find((params) => params.name === 'Group By')?.value
            : null;
        setByFunctionOptions(
          // convert properties list to options
          (groupByValue ? JSON.parse(groupByValue)?.properties : []).map((prop) => ({label: prop, value: prop})),
        );
      } else if (functionDef?.type === 'combined' || functionDef?.functionGroup === 'combined') {
        // if sum or any combine function - do not present any dimension
        setFilterOptionsByFunction(true);
        setByFunctionOptions([]);
      } else {
        // if any other function - present all possible dimensions
        setFilterOptionsByFunction(false);
        setByFunctionOptions([]);
      }
    } else {
      // if there is no function - present all possible dimensions
      setFilterOptionsByFunction(false);
      setByFunctionOptions([]);
    }
  }, [expressionTree, functions]);

  // Create var list based on fetched properties, pre-defined options and current search
  useEffect(() => {
    const propList = properties?.length ? properties.map((option) => ({label: option, value: option})) : [];
    // apply filtering based on the used function
    const externalOptions = filterOptionsByFunction ? byFunctionOptions : propList;
    // Only display separator in case there are external options
    const additionalOptions = externalOptions.length ? separatorOption : [];
    setVarOptions(
      [...externalOptions, ...additionalOptions, ...preDefinedOptions]
        // Filter options based on search criteria
        .filter(
          (option) =>
            option.label.charAt(0) !== '@' &&
            option.label !== 'what' &&
            option.label.toLowerCase().includes(search.toLowerCase()),
        ),
    );
    // Adjust selected
    setSelected(0);
  }, [properties, search, setSelected, filterOptionsByFunction, byFunctionOptions]);

  const handleSelection = useCallback(
    (selectedVar) => {
      const varLabel = selectedVar || varOptions[selected]?.label || '';
      // Insert the selected var (+ closing }}) between opening {{ and next {{
      const newValue = `${inputValue.substring(0, varStart)}${varLabel}}}${inputValue.substring(varBlockEnd)}`;
      // Add selected option to input field
      onChange(newValue);
      // Close the options list
      setShowVarsList(false);
    },
    [inputValue, varStart, varBlockEnd, varOptions, selected, onChange, setShowVarsList],
  );

  const onSelect = useCallback(
    (val) => {
      if (val) {
        // Set selected option based on val
        const varLabel = varOptions.find((option) => option.value === val.value).label;
        handleSelection(varLabel);
      }
    },
    [varOptions, handleSelection],
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (showVarsList) {
        switch (event.keyCode) {
          case KEY_ESCAPE:
            event.preventDefault();
            setShowVarsList(false);
            break;
          case KEY_UP:
            event.preventDefault();
            setSelected((varOptions.length + selected - 1) % varOptions.length);
            break;
          case KEY_DOWN:
            event.preventDefault();
            setSelected((selected + 1) % varOptions.length);
            break;
          case KEY_ENTER:
          case KEY_RETURN:
            handleSelection();
            break;
          case KEY_TAB:
            handleSelection();
            break;
          default:
          // Do nothing
        }
      }
    },
    [showVarsList, selected, varOptions],
  );

  // Handle change in input field: control if to display the options list or not
  const handleChange = useCallback(
    (event) => {
      // Call the Form onChange
      onChange(event);
      // Split input value by {{ to find all variable blocks
      const varBlocks = event.target.value.split('{{');
      // Find first block without closing }} or with current cursor position
      const selectedBlock = selectVarBlock(varBlocks, event.target.selectionStart);
      if (selectedBlock) {
        setVarStart(selectedBlock.start);
        setVarBlockEnd(selectedBlock.varBlockEnd);
        setSearch(selectedBlock.Search);
        setShowVarsList(true);
      } else {
        // If there are no open blocks
        setSearch('');
        setShowVarsList(false);
      }
    },
    [setVarStart, setVarBlockEnd, setSearch, setShowVarsList],
  );

  return (
    <div styleName="autocomplete-wrapper">
      {/* Wrap the input child */}
      {children(handleChange, handleKeyDown)}
      {showVarsList && varOptions && (
        <div styleName="autocomplete-selection" style={topStyle}>
          <VirtualizedListOfComponents
            optionComponent={<OptionComponentAutocomplete />}
            options={varOptions}
            selectedValue={varOptions[selected] ? varOptions[selected] : ''}
            onSelect={onSelect}
            rowHeight={optionHeight}
            panelHeight={
              varOptions.length * optionHeight > panelHeight ? panelHeight : varOptions.length * optionHeight
            }
            useHeader
            automationId={automationId}
            isFocused
            useExternalNavigation
            externalScrollToIndex={selected}
          />
        </div>
      )}
    </div>
  );
};

AutocompleteSelection.defaultProps = {
  automationId: '',
  panelHeight: 8 * optionHeight,
  bottomOffset: null,
};
export default AutocompleteSelection;
