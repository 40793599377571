// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc.cubes/store/selectors';
import {setEditableTopCubeKeyVal} from 'bc.cubes/store/actions';
import {get} from 'lodash';
import './CubeDimetrics.module.scss';
import SelectAndt, {THEME_BLUE, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  className: string,
  schema: Object,
  editableTopCube: Object,

  setEditableTopCubeKeyVal: Function,
};

export default connect(
  (state) => ({
    schema: selectors.getSelectedSchema(state),
    editableTopCube: selectors.getEditableTopCube(state),
  }),
  {
    setEditableTopCubeKeyVal,
  },
)(
  class CubeMeasures extends PureComponent {
    props: PropTypes;

    constructor(props) {
      super(props);
      const measuresList = Object.keys(this.props.schema.measurements || {});
      const options = measuresList.map((measure) => ({value: measure, label: measure}));
      this.state = {
        dropdownOptions: options,
      };
    }

    onSelect = (measure) => {
      this.props.setEditableTopCubeKeyVal({measure: measure.label}, {isPreviewCube: true});
    };

    render() {
      const {className, schema, editableTopCube} = this.props;

      const optionCurrent = this.state.dropdownOptions.findIndex(
        (option) => editableTopCube.measure && editableTopCube.measure === option.value,
      );
      return (
        <div styleName="root " id="cube-editor-measures" className={className}>
          <div className="andt-section-header-h4">Measures</div>
          <div styleName="measures-wrapper">
            <div>
              <SelectAndt
                id="ddl-cube-editor-measure"
                className="andt-dropdown"
                options={this.state.dropdownOptions}
                type={TYPE_NO_SEARCH}
                theme={THEME_BLUE}
                value={this.state.dropdownOptions[optionCurrent]}
                onChange={this.onSelect}
                placeholder="Choose measure"
                automationId="cubeMeasures"
              />
            </div>
            <div styleName="label">
              <span>{get(schema, `measurements.${editableTopCube.measure}.aggregation`, 'Aggregation')}</span>
            </div>
            <div styleName="label">
              <span>{get(schema, `measurements.${editableTopCube.measure}.units`, 'Units')}</span>
            </div>
          </div>
        </div>
      );
    }
  },
);
