// @flow
import React, {useMemo} from 'react';
import fetchTriggeredAlertMetricsProvider from 'alerts.console.new/api/fetchTriggeredAlertMetricsProvider';
import {ALERT_TYPES} from 'alerts.console.new/services/alertsConsoleService';
import AnomalyAlert from './anomalyAlert/AnomalyAlert';
import StaticAlert from './staticAlert/StaticAlert';
import NoDataAlert from './noDataAlert/NoDataAlert';

const GraphContent = ({
  open,
  opacity,
  trigger,
  onChangeChartWrapperStyle,
  index,
  queryParams,
}: {
  open: Boolean,
  opacity: Number,
  trigger: Object,
  onChangeChartWrapperStyle: Function,
  index: Number,
  queryParams: Object,
}) => {
  // The pageNum and pageSize of query params in the url are for the alerts list and are not relevant for metrics.
  // TODO: (IB) - Need to understand which params are relevant for metrics. Maybe need to use a different query params mechanism for each one?
  const {pageNum, pageSize, ...metricsQueryParams} = queryParams ?? {};
  const triggerMetricsResult = fetchTriggeredAlertMetricsProvider(
    trigger?.alertTriggerId,
    metricsQueryParams,
  ).useQuery();

  const triggeredAlertWithMetrics = useMemo(() => {
    return {...trigger, metrics: triggerMetricsResult.data?.triggers || []};
  }, [JSON.stringify(triggerMetricsResult.data?.triggers)]);

  const style = {
    graphsWrapper: {
      opacity,
      transition: ['opacity .3s ease-in-out'],
    },
  };

  return (
    <>
      {open && triggerMetricsResult.isLoading === false && (
        <div style={style.graphsWrapper} className="alert-clickable-item">
          {trigger.type === ALERT_TYPES.ANOMALY && (
            <AnomalyAlert
              isSingleLine={false}
              specialHeader
              alert={triggeredAlertWithMetrics}
              getWrapperStyle={onChangeChartWrapperStyle}
              index={index}
              searchExpression={queryParams.searchExpression}
            />
          )}
          {trigger.type === ALERT_TYPES.STATIC && (
            <StaticAlert
              isSingleLine={false}
              specialHeader
              alert={triggeredAlertWithMetrics}
              getWrapperStyle={onChangeChartWrapperStyle}
              searchExpression={queryParams.searchExpression}
            />
          )}
          {trigger.type === ALERT_TYPES.NO_DATA && (
            <NoDataAlert
              isSingleLine={false}
              specialHeader
              alert={triggeredAlertWithMetrics}
              getWrapperStyle={onChangeChartWrapperStyle}
              searchExpression={queryParams.searchExpression}
            />
          )}
        </div>
      )}
    </>
  );
};

export default GraphContent;
