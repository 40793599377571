import {useField} from 'react-final-form';

export const useMultipleFinalFormFields = (fieldsNames, config, getter) =>
  fieldsNames.reduce((res, fieldName) => {
    const field = useField(fieldName, config);
    if (typeof getter === 'function') {
      res[fieldName] = getter(field);
    } else {
      res[fieldName] = field.input;
    }
    return res;
  }, {});

export default useMultipleFinalFormFields;
