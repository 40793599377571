// @flow
import React, {PureComponent, useEffect, useState} from 'react';
import {get} from 'lodash';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import BaloonMessage from 'common/componentsV2/BaloonMessage';
import Spinner, {SIZES as SPINNER_SIZES} from 'common/componentsV2/Spinner';
import Checkbox from 'common/componentsV2/Checkbox';
import Slider from 'common/componentsV2/Slider';
import {durationScales, resolutionTypes} from 'metrics/services/metricsService';
import TooltipArea from 'common/componentsV2/TooltipArea';
import {palette} from 'app/styles/theme';
import InputAuto from '../simulationArea/utils/InputAuto';

type PropTypes = {
  title: string,
  subTitle: string,
  absValue: number,
  percentValue: number,
  enableAutoBoolVal: boolean,
  enableAutoIsLoading: boolean,
  denyZeroAbsolute: boolean,
  minDurationUI: Object,
  rollup: string,
  deltaDuration: Object,
  onAutoBoolValChange: Function,
  onPercentChange: Function,
  onAbsChange: Function,
  setDeltaDurationEnabled: Function,
  setDeltaDurationNumOfPoints: Function,
  absoluteElement: React.Node,
  percentageElement: React.Node,
  automationId: string,
  enableSpinner: boolean,
  isLoading: boolean,
  tooltipContent: string,
};

const DeltaDurationCondition = ({
  minDuration,
  minDurationUI,
  setDeltaDurationNumOfPoints,
  automationId,
  deltaDuration,
  rollup,
}: {
  minDuration: Object,
  minDurationUI: Object,
  setDeltaDurationNumOfPoints: Function,
  automationId: string,
  deltaDuration: number,
  rollup: string,
}) => {
  const [localValue, setLocalValue] = useState(minDuration);
  const deltaDurationValue = get(deltaDuration, 'minDuration', '');

  useEffect(() => {
    setLocalValue(deltaDurationValue);
  }, [deltaDurationValue]);

  const secondsPerUnit = durationScales[resolutionTypes[rollup].units].seconds;

  const numOfPointsFormatted = parseFloat(deltaDurationValue / durationScales[resolutionTypes[rollup].units].seconds);

  const deltaDurationUnits = resolutionTypes[rollup].units;
  const rollupBySec = resolutionTypes[rollup].ms / 1000;

  return (
    <>
      <div style={{marginBottom: 12}}>Alert will be triggered if delta lasts at least:</div>
      <div style={{display: 'flex'}}>
        <div style={{flexShrink: 0}}>
          <div style={{width: 67, height: 30, marginRight: 16}}>
            <InputAuto
              fullSize
              automationId={`${automationId}_volumeValue`}
              disabled
              type="number"
              name="volume-value"
              value={numOfPointsFormatted}
              onChange={(event) => {
                setDeltaDurationNumOfPoints(event.target.value * secondsPerUnit);
              }}
              delay={1000}
              additionalProps={{
                min: parseFloat(rollupBySec / secondsPerUnit),
                max: parseFloat(minDurationUI.value / secondsPerUnit),
              }}
            />
          </div>
          <div style={{marginBottom: 12, marginTop: 4, textTransform: 'capitalize'}}>{deltaDurationUnits}</div>
        </div>
        <Slider
          automationId={`${automationId}_deltaDurationSlider`}
          value={localValue}
          onChange={setLocalValue}
          onRelease={setDeltaDurationNumOfPoints}
          min={rollupBySec}
          max={minDurationUI.value}
          step={rollupBySec}
          tooltipValueFormatter={(value) => parseFloat(value / secondsPerUnit)}
        />
      </div>
    </>
  );
};

const absoluteInputAdditionalProps = {
  min: 0,
};

const percentageInputAdditionalProps = {
  min: 0,
  max: 1000,
};

class AbsPercentageConditionTemplate extends PureComponent {
  props: PropTypes;

  state = {
    isDeltaDurationActive: false,
  };

  componentDidUpdate() {
    if (this.props.rollup) this.setIsActive();
  }

  autoCheckedChanged = (value) => {
    const {onAutoBoolValChange} = this.props;
    onAutoBoolValChange(!value);
  };

  onPercentChange = (e) => {
    this.props.onPercentChange(e.target.value);
  };

  onAbsChange = (e) => {
    this.props.onAbsChange(e.target.value);
  };

  setDeltaDurationEnabled = (isDeltaDurationEnabled) => {
    this.props.setDeltaDurationEnabled(!isDeltaDurationEnabled);
  };

  setDeltaDurationNumOfPoints = (value) => {
    const {setDeltaDurationNumOfPoints, rollup} = this.props;
    setDeltaDurationNumOfPoints({value, rollup});
  };

  getUnitsByRollup = () => resolutionTypes[this.props.rollup].units;

  getRollupBySecond = () => resolutionTypes[this.props.rollup].ms / 1000;

  formatDuration = () => {
    const {deltaDuration, rollup} = this.props;
    const deltaDurationValue = get(deltaDuration, 'minDuration', 0);

    return Math.ceil(deltaDurationValue / durationScales[resolutionTypes[rollup].units].seconds);
  };

  deltaDurationInactiveMsgRendered = () => {
    const numOfPointsFormatted = this.formatDuration();
    let deltaDurationUnits = this.getUnitsByRollup();
    if (numOfPointsFormatted === 1 && deltaDurationUnits.slice(-1) === 's') {
      deltaDurationUnits = deltaDurationUnits.slice(0, deltaDurationUnits.length - 1);
    }
    return (
      <div style={{margin: '8px 0'}}>
        <BaloonMessage>
          In order to use this condition, the Minimum Duration for the anomaly needs to be larger than{' '}
          <span>
            {numOfPointsFormatted} {deltaDurationUnits}
          </span>
        </BaloonMessage>
      </div>
    );
  };

  setIsActive = () => {
    const {minDurationUI} = this.props;
    const rollupBySec = this.getRollupBySecond();
    this.setState({isDeltaDurationActive: minDurationUI && minDurationUI.value > rollupBySec});
  };

  render() {
    const {
      absValue,
      percentValue,
      minDurationUI,
      deltaDuration,
      title,
      subTitle,
      absoluteElement,
      percentageElement,
      automationId,
      enableSpinner,
      isLoading,
      denyZeroAbsolute,
      tooltipContent,
    } = this.props;
    const {isDeltaDurationActive} = this.state;

    const {enabled, minDuration} = deltaDuration || {};
    const safeAutoBoolVal = !!this.props.enableAutoBoolVal;

    return (
      <>
        <TooltipArea isAlwaysVisible automationId={`${automationId}_absPercentageContainer`} text={tooltipContent}>
          {(info) => (
            <>
              <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16}}>
                <div className="text16med lineHeight_16">
                  {title}
                  {tooltipContent && info}
                </div>
                <div style={{position: 'relative', display: 'flex', flexShrink: 0}}>
                  {this.props.enableAutoIsLoading && (
                    <div style={{position: 'absolute', right: 145, bottom: 7}}>
                      <Spinner color={palette.white[500]} size={SPINNER_SIZES.SMALL_30} />
                    </div>
                  )}
                  {this.props.onAutoBoolValChange && (
                    <>
                      <div
                        style={{
                          marginRight: 12,
                          color: safeAutoBoolVal ? palette.purple['600'] : palette.gray['400'],
                          fontWeight: 'bold',
                          fontSize: 16,
                        }}
                      >
                        Automatic
                      </div>
                      <ToggleSwitch
                        automationId={`${automationId}_automaticSwitch`}
                        theme="purple"
                        isChecked={safeAutoBoolVal}
                        onToggle={() => this.autoCheckedChanged(safeAutoBoolVal)}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="text16reg lineHeight_16">{subTitle}</div>
              {/* spacing={2} */}
              <div style={{display: 'flex', gap: 16}} automation-id="alertSettingsPercentagePane">
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <div style={{width: 124, height: 36, marginBottom: 4}}>
                    <InputAuto
                      allowEmpty
                      denyZero={denyZeroAbsolute}
                      automationId={`${automationId}_absoluteInput`}
                      fullSize
                      type="number"
                      className="dark-input"
                      placeholder="Absolute"
                      isDisabled={safeAutoBoolVal}
                      step={safeAutoBoolVal ? '0.1' : '1'}
                      value={absValue || ''}
                      delay={1000}
                      onChange={this.onAbsChange}
                      additionalProps={absoluteInputAdditionalProps}
                    />
                  </div>
                  {absoluteElement}
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <div style={{width: 124, height: 36, marginBottom: 4}}>
                    <InputAuto
                      allowEmpty
                      automationId={`${automationId}_percentageInput`}
                      fullSize
                      type="number"
                      className="dark-input"
                      placeholder="Percentage"
                      additionalProps={percentageInputAdditionalProps}
                      isDisabled={safeAutoBoolVal}
                      value={percentValue || ''}
                      delay={1000}
                      onChange={this.onPercentChange}
                    />
                  </div>
                  {percentageElement}
                </div>
                {enableSpinner && isLoading && (
                  <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 36, height: 36}}>
                    <div style={{fontSize: 24, color: palette.gray[400]}} className="icon ion-load-c spin" />
                  </div>
                )}
              </div>
            </>
          )}
        </TooltipArea>
        {this.props.rollup && (
          <TooltipArea
            isAlwaysVisible
            automationId={`${automationId}_deltaDurationContainer`}
            text="Set the minimum duration the anomaly should surpass the delta set above in order to trigger an alert"
          >
            {(info) => (
              <>
                <div style={{marginTop: 24, marginBottom: 8, display: 'flex'}}>
                  <Checkbox
                    isDisabled={!isDeltaDurationActive}
                    isChecked={enabled}
                    onChange={() => this.setDeltaDurationEnabled(enabled)}
                  >
                    <div className="text16med lineHeight_16 ml_0-5 color_inherit">Override Delta Duration</div>
                  </Checkbox>
                  {info}
                </div>
                {!isDeltaDurationActive && this.deltaDurationInactiveMsgRendered()}
                {enabled && isDeltaDurationActive && (
                  <DeltaDurationCondition
                    minDuration={minDuration}
                    minDurationUI={minDurationUI}
                    deltaDuration={deltaDuration}
                    rollup={this.props.rollup}
                    setDeltaDurationNumOfPoints={(value) => this.setDeltaDurationNumOfPoints(value)}
                  />
                )}
              </>
            )}
          </TooltipArea>
        )}
      </>
    );
  }
}

export default AbsPercentageConditionTemplate;
