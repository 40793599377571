import {useMutation, useQueryClient} from 'react-query';

import {put} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/event-streams';

const setEventStreamAction = (actionType) => {
  return (streamId) => {
    return put(`${root}/${streamId}/state?action=${actionType}`);
  };
};

export default function useActionEventStream(actionType) {
  const queryClient = useQueryClient();
  const eventStreamAction = setEventStreamAction(actionType);

  const {mutate, isLoading} = useMutation(({streamId}) => eventStreamAction(streamId), {
    onSuccess: (data, meta) => {
      queryClient.invalidateQueries([QUERY_KEYS.streams]);
      meta.onSuccess(data);
    },
  });
  return {mutate, isLoading};
}
