import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/';

const getDatasetTables = async (type, sourceId, projectId, datasetId) => {
  return get(`${root}data-sources/${type}/${sourceId}/tables?projectId=${projectId}&datasetId=${datasetId}`);
};

export default function useFetchDatasetTables(type, sourceId, projectId, datasetId) {
  const fallback = [];

  const selectFn = useCallback(
    (data) => {
      return data ? data.map((i) => ({...i, value: i.id, label: i.id})) : [];
    },
    [sourceId],
  );

  const {
    data: datasetTables = fallback,
    isLoading: isDatasetTablesLoading,
    isFetching: isDatasetTablesFetching,
    refetch: refetchDatasetTablesFetching,
  } = useQuery(QUERY_KEYS.datasetTables, () => getDatasetTables(type, sourceId, projectId, datasetId), {
    enabled: !!(type && sourceId && projectId && datasetId),
    select: selectFn,
  });

  return {datasetTables, isDatasetTablesLoading, isDatasetTablesFetching, refetchDatasetTablesFetching};
}
