import {useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';
import {stringifyQS} from 'common/utils/http';

export const alertsConsoleRoot = 'api/v1/alerts-console/';

const fetchTriggeredAlertWithMetricsById = ({payload}) => {
  const url = `${alertsConsoleRoot}triggered-by-id/get-with-metrics?${stringifyQS(payload)}`;
  return get(url);
};

export default function fetchAlertsForImpactProvider(queryKeyParams) {
  const queryClient = useQueryClient();

  const queryKey = [QUERY_KEYS.triggeredAlertForImpact, queryKeyParams];
  return {
    invalidate: (partialKey) => queryClient.invalidateQueries(partialKey ? [partialKey] : queryKey),
    useQuery: () =>
      useQuery(
        queryKey,
        () => fetchTriggeredAlertWithMetricsById({payload: {triggerId: queryKeyParams.alertTriggerId}}),
        {
          retry: false,
        },
      ),
    fetchQuery: (alertTriggerId) =>
      queryClient.fetchQuery(
        queryKey,
        () =>
          fetchTriggeredAlertWithMetricsById({
            payload: {triggerId: alertTriggerId || queryKey.alertTriggerId},
          }),
        {
          retry: false,
        },
      ),
  };
}
