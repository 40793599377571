// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import {get} from 'lodash';
import {Carousel} from 'react-responsive-carousel';
import {humanizeHourOfTheDay} from 'common/utils/utilsService';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import {dismissOnBoarding, skippedMission} from 'profile/store/actions';
import {segmentClickEvent} from 'common/store/actions';
import gaugesToRender from 'onBoarding/services/onBoardingService';

import {getAlertsTriggeredTotalLastDay, getIsLoadingAlertsTriggeredTotalLastDay} from 'alerts.console/store/selectors';
import {getAlertConfigurationsTotal, getIsLoadingAlertConfigurationsTotal} from 'alerts.management/store/selectors';
import {getFetchDashboardTotal, getIsLoadingFetchDashboardTotal} from 'dashboards/store/selectors';
import {getFetchMetricsTotal, getIsLoadingMetricsTotal} from 'metrics/store/selectors';
import {getActiveStreamsTotal, getIsDataStreamsLoading} from 'bc/store/selectors';
import Button, {COLORS} from 'common/componentsV2/Button';
import {getDashboardV2Enabled, getEnableNewMetricExplorer} from 'profile/store/selectors';

import './Onboarding.module.scss';

type PropTypes = {
  userData: Object,
  tasks: Array,
  shouldBeOpen: Boolean,
  isAllTasksAccomplished: Boolean,
  // connect
  alertsTriggeredTotalLastDay: Number,
  isLoadingAlertsTriggeredTotalLastDay: Boolean,
  fetchDashboardTotal: Number,
  isLoadingFetchDashboardTotal: Boolean,
  fetchMetricsTotal: Number,
  isLoadingMetricsTotal: Boolean,
  fetchActiveStreamsTotal: Number,
  isDataStreamsLoading: Boolean,
  dismissOnBoarding: Function,
  skippedMission: Function,
  segmentClickEvent: Function,
  alertConfigurationsTotal: Number,
  getIsLoadingAlertConfigurationsTotal: Boolean,
  isDashboardsV2Enabled: Boolean,
  isNewMetricExplorerEnabled: Boolean,
};

const COMMON_SETTINGS = {
  showIndicators: false,
  infiniteLoop: false,
  showThumbs: false,
  showStatus: false,
  transitionTime: 400,
  width: '710px',
};

export default connect(
  (state) => ({
    alertsTriggeredTotal: getAlertsTriggeredTotalLastDay(state),
    isLoadingAlertsTriggeredTotal: getIsLoadingAlertsTriggeredTotalLastDay(state),
    fetchDashboardTotal: getFetchDashboardTotal(state),
    isLoadingFetchDashboardTotal: getIsLoadingFetchDashboardTotal(state),
    fetchMetricsTotal: getFetchMetricsTotal(state),
    isLoadingMetricsTotal: getIsLoadingMetricsTotal(state),
    fetchActiveStreamsTotal: getActiveStreamsTotal(state),
    isDataStreamsLoading: getIsDataStreamsLoading(state),
    alertConfigurationsTotal: getAlertConfigurationsTotal(state),
    isLoadingAlertConfigurationsTotal: getIsLoadingAlertConfigurationsTotal(state),
    isDashboardsV2Enabled: getDashboardV2Enabled(state),
    isNewMetricExplorerEnabled: getEnableNewMetricExplorer(state),
  }),
  {
    dismissOnBoarding,
    segmentClickEvent,
    skippedMission,
  },
)(
  class OnBoarding extends PureComponent {
    props: PropTypes;

    state = {
      showWidget: false,
      selectedItem: 0,
    };

    componentDidMount() {
      this.setState({showWidget: this.props.shouldBeOpen});
      this.pickOpeningSlide();
    }

    toggleWidgetDisplay = () => {
      const {showWidget} = this.state;
      this.setState({showWidget: !showWidget}, () => {
        this.props.segmentClickEvent({category: 'on-boarding', name: `ftue-${!showWidget ? 'open' : 'close'}`});
      });
    };

    slideChange = (index) => {
      this.setState({selectedItem: index}, () => {
        this.props.segmentClickEvent({category: 'on-boarding', name: `slide-change-to-${index}`});
      });
    };

    pickOpeningSlide = () => {
      const {tasks} = this.props;
      const uncompletedIndexesTasks = tasks.findIndex((task) => !task.missionAccomplished);
      const selectedIndexItem = uncompletedIndexesTasks || 0;
      this.setState({selectedItem: selectedIndexItem});
    };

    renderDots = (tasks) =>
      tasks.map((task, index) => {
        if (this.state.selectedItem === index) {
          if (task.missionAccomplished) {
            return (
              <li
                styleName="selected-done"
                onClick={() => this.slideChange(index)}
                key={`main-slide-images ${task.id}`}
              />
            );
          }
          return (
            <li styleName="selected" onClick={() => this.slideChange(index)} key={`main-slide-images ${task.id}`} />
          );
        }

        if (task.missionAccomplished) {
          return <li styleName="done" onClick={() => this.slideChange(index)} key={`main-slide-images ${task.id}`} />;
        }
        return <li onClick={() => this.slideChange(index)} key={`main-slide-images ${task.id}`} />;
      });

    goTo = (task) => {
      let endPoint = task.btnLink;
      const {isDashboardsV2Enabled, isNewMetricExplorerEnabled} = this.props;
      const {origin} = window.location;
      if (isDashboardsV2Enabled && task.id === 'checkDashboards') {
        endPoint = 'r/dashboards';
      } else if (isNewMetricExplorerEnabled && task.id === 'metricSearch') {
        endPoint = 'r/metrics-explorer';
      }
      window.open(`${origin}/#!/${endPoint}`, '_self');
      this.props.segmentClickEvent({category: 'on-boarding', name: `CTA-${endPoint}`});
    };

    chooseTextToDisplay = (task, type) =>
      this.props[task.condition] && this.props[task.condition] > 0 ? task[type].withData : task[type].noData;

    dismissOnBoarding = () => {
      this.props.dismissOnBoarding(true);
      this.props.segmentClickEvent({category: 'on-boarding', name: 'dismiss'});
    };

    skippedMission = (mission) => {
      this.props.skippedMission(mission);
      this.props.segmentClickEvent({category: 'on-boarding', name: `skipped-${mission}`});
    };

    render() {
      const {tasks, userData, isAllTasksAccomplished} = this.props;
      const {showWidget, selectedItem} = this.state;
      const greetingText = `good ${humanizeHourOfTheDay()}, ${userData.firstName}`;
      const orgName = get(userData, 'organization.name', '');

      return (
        <FormDdl
          buttonComponent={
            <Button
              colorSchema={COLORS.INDIGO_500}
              text="Getting Started"
              onClick={this.toggleWidgetDisplay}
              icon="ftue-main-btn"
            />
          }
          onToggle={this.toggleWidgetDisplay}
          isOpen={showWidget}
          position="right"
          withArrow
          popoverComponent={
            <div styleName="onBoarding-container">
              <header styleName="header">
                <h1>{greetingText}</h1>
                {isAllTasksAccomplished && <span onClick={() => this.dismissOnBoarding()}>Dismiss this tutorial</span>}
              </header>

              <div className="carousel-wrapper top">
                <Carousel
                  {...COMMON_SETTINGS}
                  onChange={this.slideChange}
                  selectedItem={selectedItem}
                  useKeyboardArrows
                >
                  {tasks.map((task) =>
                    task.missionAccomplished ? (
                      <div key={`${task.id}_completed}`} styleName="top-container mission-accomplished">
                        <div styleName={`main-slide-images ${task.imgClass}`} />
                        <h1>{task.title.withData}</h1>
                      </div>
                    ) : (
                      <div key={`${task.id}_top`} styleName="top-container">
                        <span styleName="skip-btn" onClick={() => this.skippedMission(task.url)}>
                          Skip
                        </span>
                        <div styleName={this.props[task.conditionLoading] ? 'img-wrapper loading' : 'img-wrapper'}>
                          <div styleName={`main-slide-images ${task.imgClass}`} />
                        </div>

                        <div styleName={this.props[task.conditionLoading] ? 'text-wrapper loading' : 'text-wrapper'}>
                          <div styleName="content-wrapper">
                            <h1>{this.chooseTextToDisplay(task, 'title')}</h1>
                            <p>{this.chooseTextToDisplay(task, 'description')}</p>
                          </div>
                        </div>
                        <div styleName={this.props[task.conditionLoading] ? 'btn-wrapper loading' : 'btn-wrapper'}>
                          <button type="button" onClick={() => this.goTo(task)}>
                            {task.btnTitle}
                          </button>
                        </div>
                      </div>
                    ),
                  )}
                </Carousel>
              </div>

              <div className="carousel-wrapper bottom">
                <Carousel
                  {...COMMON_SETTINGS}
                  selectedItem={selectedItem}
                  transitionTime={COMMON_SETTINGS.transitionTime + 100}
                  showArrows={false}
                >
                  {tasks.map((task, i) => (
                    <div key={`${task.id}_bottom}`} styleName="bottom-container">
                      <span styleName="org-name">{orgName} has:</span>
                      <div styleName="gauge-wrapper">
                        {task.gauges.map((gauge) =>
                          gaugesToRender[gauge.componentName]({
                            ...gauge,
                            selectedItem,
                            index: i,
                            key: gauge.componentName,
                          }),
                        )}
                      </div>
                    </div>
                  ))}
                </Carousel>
              </div>

              <ul styleName="custom-dots">{this.renderDots(tasks)}</ul>
            </div>
          }
        />
      );
    }
  },
);
