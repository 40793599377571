import {put} from 'common/utils/http';

const recommendationsRoot = 'api/v1/recommendations';

const ignoreRecommendation = (payload) => {
  if (!payload?.recommendationId) {
    return Promise.reject(new Error('recommendationId is not set in ignoreRecommendation'));
  }
  const ignoreTypes = payload?.type ? `&type=${payload?.type}` : '';
  const ignoreQueryParams = `userFeId=${payload?.userId}${ignoreTypes}&ignore=${payload?.ignore}`;
  return put(`${recommendationsRoot}/${payload?.recommendationId}/ignore?${ignoreQueryParams}`);
};

export default ignoreRecommendation;
