import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';

const segmentStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});
  const stream = state.streams.data[getSelectedIndex()];

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  switch (type) {
    case actions.setSegmentMessageTypes.TYPE:
      return redWrapper({
        ...payload,
        metrics: [],
        dimensions: [],
        schema: {
          columns: [],
          sourceColumns: [],
        },
        uiState: {
          ...stream.uiState,
          unAssignedColumns: [],
        },
      });

    case actions.setSegmentStreamClearAllDiametrics.TYPE: {
      const metricsDimensions = stream.dimensions.concat(stream.metrics);
      const mod = {
        metrics: [],
        dimensions: [],
        schema: {
          columns: [],
          sourceColumns: [],
        },
        uiState: {
          ...stream.uiState,
          unAssignedColumns: [...stream.uiState.unAssignedColumns, ...metricsDimensions],
        },
      };
      return redWrapper(mod);
    }

    case actions.removeSegmentStreamDiametrics.TYPE: {
      const mod = {};
      if (stream.metrics.indexOf(payload) >= 0) {
        mod.metrics = stream.metrics.filter((a) => a !== payload);
      } else if (stream.dimensions.indexOf(payload) >= 0) {
        mod.dimensions = stream.dimensions.filter((a) => a !== payload);
      }
      mod.uiState = {
        ...stream.uiState,
        unAssignedColumns: [...stream.uiState.unAssignedColumns, payload],
      };
      return redWrapper(mod);
    }

    case actions.setSegmentStreamDiametricsChange.TYPE: {
      const streamModifications = {
        uiState: {...stream.uiState},
      };

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      if (payload.source.droppableId === payload.destination.droppableId) {
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = reorder(
              stream.uiState.unAssignedColumns,
              payload.source.index,
              payload.destination.index,
            );
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = reorder(stream.metrics, payload.source.index, payload.destination.index);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = reorder(
              stream.dimensions,
              payload.source.index,
              payload.destination.index,
            );
            break;
          }
          default:
            return state;
        }
      } else {
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = [...stream.uiState.unAssignedColumns];
            streamModifications.uiState.unAssignedColumns.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = [...stream.metrics];
            streamModifications.metrics.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = [...stream.dimensions];
            streamModifications.dimensions.splice(payload.destination.index, 0, payload.draggableId);
            break;
          }
          default:
            return state;
        }

        switch (payload.source.droppableId) {
          case 'dmAllColumns': {
            streamModifications.uiState.unAssignedColumns = [
              ...(streamModifications.uiState.unAssignedColumns || stream.uiState.unAssignedColumns),
            ];
            streamModifications.uiState.unAssignedColumns.splice(
              streamModifications.uiState.unAssignedColumns.findIndex((a) => a === payload.draggableId),
              1,
            );
            break;
          }
          case 'dmMetrics': {
            streamModifications.metrics = [...stream.metrics];
            streamModifications.metrics.splice(
              streamModifications.metrics.findIndex((a) => a === payload.draggableId),
              1,
            );
            break;
          }
          case 'dmDimensions': {
            streamModifications.dimensions = [...stream.dimensions];
            streamModifications.dimensions.splice(
              streamModifications.dimensions.findIndex((a) => a === payload.draggableId),
              1,
            );
            break;
          }
          default:
            return state;
        }
      }
      return redWrapper(streamModifications);
    }

    default:
      return state;
  }
});

export default segmentStreamReducer;
