// @ flow
import React, {useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useField} from 'react-final-form';
import TileHeader from 'dashboards/components/metricTiles/TileHeader';
import {getDateValue} from 'common/utils/dateRangeService';
import {get} from 'lodash';
import {makeFiltersPayload, parseFiltersPayload} from 'dashboards/utils';
import {useSelector} from 'react-redux';
import {getDashboardUserSettings} from 'dashboards/store/selectors';
import AnomalyChartsContainer, {presavedParamsPath} from './AnomalyChartsContainer';
import {getMeasureNameAndExpression} from './helpers';
import {ArrowSelect} from './ArrowSelect';

const EMPTY_OBJECT = {};

const useStyles = makeStyles(() => ({
  wrapperTile: {
    flexGrow: 1,
    fontSize: 16,
    borderRadius: 6,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 8,
    '&:hover': {
      '@global': {
        '.editMode, .editWrapper': {
          display: 'flex',
          visibility: 'visible',
        },
      },
    },
  },
  arrowSelect: {
    marginLeft: 5,
    cursor: 'pointer',
  },
  postfix: {
    lineHeight: '28px',
    flexShrink: 0,
  },
}));

const sortAnomaliesOptions = {
  alphaNumeric: {value: '', label: 'Alphanumeric', disabled: true},
  startDate: {value: 'startDate', label: 'Start Date'},
  score: {value: 'score', label: 'Score'},
  delta: {value: 'deltaValue', label: 'Absolute Delta'},
};

const AnomalyTileContainer = ({
  tileId,
  tileData,
  matchUrl,
  isOwnerUser,
  dashboardData,
  isAnonymous,
  actualTileLayout,
}: {
  tileId: string,
  tileData: Object,
  matchUrl: string,
  isOwnerUser: boolean,
  dashboardData: Object,
  isAnonymous: boolean,
  actualTileLayout: Object,
}) => {
  const classes = useStyles();
  const {data: dashboardUserSettings} = useSelector(getDashboardUserSettings);
  const tileSettingsValue = get(dashboardUserSettings, `tiles[${tileId}]`, EMPTY_OBJECT);

  const {
    input: {value: dateRange},
  } = useField('dateRange');
  const {
    input: {value: filters},
  } = useField('filters');
  const {
    input: {value: timeScale},
  } = useField('timeScale');

  const {
    input: {value: isEditableMode},
  } = useField('isEditableMode');

  const {measureName} = getMeasureNameAndExpression(get(tileData, presavedParamsPath, {})?.simpleExpressionTree);

  const commonValues = useMemo(
    () => ({
      dateRange: getDateValue(dateRange),
      timeScale,
      filters: parseFiltersPayload(makeFiltersPayload(filters)),
    }),
    [dateRange, timeScale, filters],
  );

  const computedValues = useMemo(
    () => ({
      dateRange: getDateValue(get(tileSettingsValue, 'dateRange', dateRange)),
      timeScale: get(tileSettingsValue, 'timeScale', timeScale),
      filters: parseFiltersPayload(get(tileSettingsValue, 'selectorsFilter.selectors', makeFiltersPayload(filters))),
    }),
    [tileSettingsValue, dateRange, timeScale, filters],
  );

  const [anomaliesData, setAnomaliesData] = useState([]);
  const [localSorting, setLocalSorting] = useState(null);

  const sortingOptions = useMemo(() => {
    return Object.values(sortAnomaliesOptions).map((o) => ({
      ...o,
      disabled: o.value && anomaliesData[2]?.length && !anomaliesData[2]?.includes(o.value),
    }));
  }, [sortAnomaliesOptions, anomaliesData[2]]);

  const sortBy = anomaliesData?.[0] ? (
    <div className={classes.postfix}>
      ({anomaliesData[0]}/{anomaliesData[1]})
      <ArrowSelect
        activeValue={localSorting}
        className={classes.arrowSelect}
        options={sortingOptions}
        onSelect={setLocalSorting}
      />
    </div>
  ) : (
    ''
  );

  return (
    <div className={`${classes.wrapperTile} bgcolor_white-500`}>
      <TileHeader
        dashboardData={dashboardData}
        tileData={tileData}
        nameMeasure={measureName}
        isEditableMode={isEditableMode}
        matchUrl={matchUrl}
        formValues={computedValues}
        commonValues={commonValues}
        isOwnerUser={isOwnerUser}
        isAnonymous={isAnonymous}
        titlePostfix={sortBy}
      />
      <AnomalyChartsContainer
        resizeTrigger={JSON.stringify(actualTileLayout)}
        width={400}
        tileData={tileData}
        dateRange={dateRange}
        onGetData={setAnomaliesData}
        localSorting={localSorting}
      />
    </div>
  );
};

export default React.memo(AnomalyTileContainer);
