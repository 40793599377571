import React from 'react';
import ContentLoader from 'common/componentsV2/ContentLoader';

const ContentLoaderPreview = () => {
  return (
    <>
      <ContentLoader rows={[{width: '20%', height: 32}]} />
      <ContentLoader rows={[{width: '40%', height: 24}]} />
      <ContentLoader rows={[{marginTop: 30, width: '50%', height: 16}]} />
      <ContentLoader rows={[{width: '60%', height: 16}]} />
      <ContentLoader rows={[{width: '50%', height: 16}]} />
      <ContentLoader rows={[{width: '60%', height: 16}]} />
      <ContentLoader rows={[{width: '50%', height: 16}]} />
    </>
  );
};

export default ContentLoaderPreview;
