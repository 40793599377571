// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as dataSelectors from 'bc/store/selectors';
import {setIsTimeZoneConfirmModalOpen, setSelectedEditor} from '../../../store/actions';
import * as selectors from '../../../store/selectors';
import GaDimensionsAndMetricsEditor from './byType/googleAnalytics/dimensionsAndMetrics/DimensionsAndMetricsEditor';
import Ga4DimensionsAndMetricsEditor from './byType/googleAnalyticsGA4/dimensionsAndMetrics/DimensionsAndMetricsEditor';
import GoogleSearchDimensionsAndMetricsEditor from './byType/googleSearch/dimensionsAndMetrics/GoogleSearchDimensionsAndMetricsEditor';
import SchedulerEditor from './scheduler/SchedulerEditor';
import FuDimensionsAndMetricsEditor from './byType/fileUpload/fileDimensionsAndMetrics/FuDimensionsAndMetricsEditor';
import S3DimensionsAndMetricsEditor from './byType/s3/s3DimensionsAndMetrics/S3DimensionsAndMetricsEditor';
import GoogleStorageDimensionsAndMetricsEditor from './byType/googleStorage/googleStorageDimensionsAndMetrics/GoogleStorageDimensionsAndMetricsEditor';
import KinesisDimensionsAndMetricsEditor from './byType/kinesis/kinesisDimensionsAndMetrics/KinesisDimensionsAndMetricsEditor';
import SqlDimensionsAndMetricsEditor from './byType/sql/sqlDimensionsAndMetrics/SqlDimensionsAndMetricsEditor';
import BqDimensionsAndMetricsEditor from './byType/bigQuery/bqDimensionsAndMetrics/BqDimensionsAndMetricsEditor';
import MParticleDimensionsAndMetricsEditor from './byType/mParticle/mParticleDimensionsAndMetrics/MParticleDimensionsAndMetricsEditor';
import SalesforceDimensionsAndMetricsEditor from './byType/salesforce/salesforceDimensionsAndMetrics/SalesforceDimensionsAndMetricsEditor';
import SegmentDimensionsAndMetricsEditor from './byType/segment/segmentDimensionsAndMetrics/SegmentDimensionsAndMetricsEditor';
import ParquetDimensionsAndMetricsEditor from './byType/parquet/parquetDimensionsAndMetrics/ParquetDimensionsAndMetricsEditor';
import GoogleAucDimensionsAndMetricsEditor from './byType/googleAuctions/googleAucDimensionsAndMetrics/GoogleAucDimensionsAndMetricsEditor';
import S3SchedularEditor from './byType/s3/s3Schedular/S3SchedularEditor';
import ParquetSchedularEditor from './byType/parquet/parquetSchedular/ParquetSchedularEditor';
import GsSchedularEditor from './byType/googleStorage/googleStorageSchedular/GoogleStorageSchedularEditor';
import SqlSchedularEditor from './byType/sql/sqlSchedular/SqlSchedularEditor';
import AdobeSchedulerEditor from './byType/adobe/adobeScheduler/AdobeSchedulerEditor';
import BqSchedularEditor from './byType/bigQuery/bqSchedular/BqSchedularEditor';
import GoogleAdsSchedularEditor from './byType/googleAds/gadsSchedular/GoogleAdsSchedularEditor';
import KinesisSchedularEditor from './byType/kinesis/kinesisSchedular/KinesisSchedularEditor';
import SalesforceSchedularEditor from './byType/salesforce/salesforceSchedular/SalesforceSchedularEditor';
import FacebookAdsSchedularEditor from './byType/facebookAds/schedular/FacebookAdsSchedularEditor';
import CoralogixDimensionsAndMetricsEditor from './byType/coralogix/coralogixDimensionsAndMetrics/CoralogixDimensionsAndMetricsEditor';
import NewrelicDimensionsAndMetricsEditor from './byType/newrelic/newrelicDimensionsAndMetrics/NewrelicDimensionsAndMetricsEditor';
import MixpanelDimensionsAndMetricsEditor from './byType/mixpanel/mixpanelDimensionsAndMetrics/MixpanelDimensionsAndMetricsEditor';
import style from './StreamEditorPanel.module.scss';

type PropTypes = {
  selectedEditor: Object,
  setSelectedEditor: Function,
  isTimeZoneConfirmModalOpen: Boolean,
  setIsTimeZoneConfirmModalOpen: Function,
};

export default connect(
  (state) => ({
    selectedEditor: selectors.getDataStreams(state).selectedEditor,
    isTimeZoneConfirmModalOpen: dataSelectors.getIsTimeZoneConfirmModalOpen(state),
  }),
  {
    setSelectedEditor,
    setIsTimeZoneConfirmModalOpen,
  },
)(
  class StreamEditorPanel extends React.PureComponent {
    props: PropTypes;

    componentDidMount = function() {
      document.addEventListener('click', this.onClickLayout, false);
    };

    componentWillUnmount = function() {
      document.removeEventListener('click', this.onClickLayout, false);
    };

    isEditorPanelCollupsed = true;

    getEditor = () => {
      switch (this.props.selectedEditor.type) {
        case 'dm':
          return <GaDimensionsAndMetricsEditor />;
        case 'dm_ga4':
          return <Ga4DimensionsAndMetricsEditor />;
        case 'dm_gsearch':
          return <GoogleSearchDimensionsAndMetricsEditor />;
        case 'scheduler':
          return <SchedulerEditor />;
        case 'dm_fu':
          return <FuDimensionsAndMetricsEditor />;
        case 'dm_s3':
          return <S3DimensionsAndMetricsEditor />;
        case 'dm_gs':
          return <GoogleStorageDimensionsAndMetricsEditor />;
        case 'dm_sql':
          return <SqlDimensionsAndMetricsEditor />;
        case 'dm_bigquery':
          return <BqDimensionsAndMetricsEditor />;
        case 'dm_mparticle':
          return <MParticleDimensionsAndMetricsEditor />;
        case 'dm_salesforce':
          return <SalesforceDimensionsAndMetricsEditor />;
        case 'dm_segment':
          return <SegmentDimensionsAndMetricsEditor />;
        case 'dm_parquet':
          return <ParquetDimensionsAndMetricsEditor />;
        case 'dm_auctions':
          return <GoogleAucDimensionsAndMetricsEditor />;
        case 'schedular_s3':
          return <S3SchedularEditor />;
        case 'schedular_parquet':
          return <ParquetSchedularEditor />;
        case 'schedular_gs':
          return <GsSchedularEditor />;
        case 'schedular_sql':
          return <SqlSchedularEditor />;
        case 'scheduler_adobe':
          return <AdobeSchedulerEditor />;
        case 'scheduler_bigquery':
          return <BqSchedularEditor />;
        case 'scheduler_gads':
          return <GoogleAdsSchedularEditor />;
        case 'dm_kinesis':
          return <KinesisDimensionsAndMetricsEditor />;
        case 'scheduler_kinesis':
          return <KinesisSchedularEditor />;
        case 'scheduler_salesforce':
          return <SalesforceSchedularEditor />;
        case 'scheduler_fbads':
          return <FacebookAdsSchedularEditor />;
        case 'dm_coralogix':
          return <CoralogixDimensionsAndMetricsEditor />;
        case 'dm_newrelic':
          return <NewrelicDimensionsAndMetricsEditor />;
        case 'dm_mixpanel':
          return <MixpanelDimensionsAndMetricsEditor />;

        default:
          return null;
      }
    };

    editorRef = (e) => {
      this.editorRefEle = e;
    };

    onClickLayout = (event) => {
      if (event.defaultPrevented) {
        return;
      }

      if (this.props.isTimeZoneConfirmModalOpen) {
        this.props.setIsTimeZoneConfirmModalOpen(false);
        this.isEditorPanelCollupsed = false;
      } else if (!this.isEditorPanelCollupsed && !this.editorRefEle.contains(event.target)) {
        // click outside editor - so we need to close the editor
        this.isEditorPanelCollupsed = true;
        this.props.setSelectedEditor({
          editorHeight: this.props.selectedEditor.editorHeight,
        });
      } else {
        this.isEditorPanelCollupsed = false;
      }
    };

    onCloseButtonClicked = (event) => {
      event.preventDefault();
      this.isEditorPanelCollupsed = true;
      this.props.setSelectedEditor({
        editorHeight: this.props.selectedEditor.editorHeight,
      });
    };

    render() {
      const editorClassName = style.editor;
      const editorStyle = {
        height: this.props.selectedEditor.editorHeight || '550px',
        maxHeight: this.props.selectedEditor.maxHeight || this.props.selectedEditor.editorHeight || '550px',
        display: 'flex',
      };
      if (this.props.selectedEditor.noDisplayFlex) {
        delete editorStyle.display;
      }

      return (
        <div className={this.props.selectedEditor.type ? `${style.root} ${style.animate}` : style.root}>
          <div
            ref={this.editorRef}
            automation-id="streamQueryEditorPane"
            className={`streamQueryEditorPane ${editorClassName}`}
            style={editorStyle}
          >
            <button
              type="button"
              className={`btn btn-flat btn-icon btn-close-dark ${style.btnClose}`}
              onClick={this.onCloseButtonClicked}
            >
              <i className="icon icn-icon-white-close" automation-id="dataCollectorStreamEditorButton" />
            </button>
            {this.getEditor()}
          </div>
        </div>
      );
    }
  },
);
