// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {Alert} from 'anodot-objects-models';
import './AlertNameEdit.module.scss';
import Button, {COLORS} from 'common/componentsV2/Button';
import {createAlert} from 'alerts.management/store/actions';

export const TYPE = {
  DUPLICATE_NAME: 'duplicateName',
  SUGGESTED_NAME: 'suggestedName',
};

type PropTypes = {
  alert: Alert,
  type: String,
  origin: String,
  close: Function,
  createAlert: Function,
};

export default connect(
  () => ({}),
  {
    createAlert,
  },
)(
  class AlertNameEdit extends React.PureComponent {
    props: PropTypes;

    state = {
      inputValue: this.props.alert.title,
    };

    onInputChange = (val) => {
      this.setState({inputValue: val.target.value});
    };

    onSave = () => {
      const {alert} = this.props;
      const newName = this.state.inputValue;
      alert.expressionTreeModel.title = newName;
      alert.title = newName;
      this.props.createAlert(
        {
          qs: {allowEmptyCompositeRes: true, origin: this.props.origin},
          body: this.props.alert.exportClientFormat(),
        },
        this.props.alert,
      );
      this.props.close();
    };

    render() {
      const title = this.props.type === TYPE.DUPLICATE_NAME ? 'Alert Name is Taken' : 'Suggestion';
      const bodyText =
        this.props.type === TYPE.DUPLICATE_NAME
          ? 'There is another alert with that name already. Please use a different name for this alert:'
          : 'Suggestion';
      return (
        <div styleName="container">
          <header>
            <h2>{title}</h2>
          </header>
          <div styleName="body-text">{bodyText}</div>
          <input onChange={this.onInputChange} value={this.state.inputValue} />
          <div styleName="buttons">
            <Button colorSchema={COLORS.GRAY_300} text="Cancel" onClick={this.props.close} />
            <Button colorSchema={COLORS.BLUE_500} text="Save" onClick={this.onSave} />
          </div>
        </div>
      );
    }
  },
);
