import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import {makeStyles} from '@material-ui/core';
import {isEmpty} from 'lodash';
import {useFormContext} from 'react-hook-form';
import Button, {COLORS} from 'common/componentsV2/Button';
import useFieldsToTriggerByStep from 'dataCollectors/hooks/useFieldsToTriggerByStep';
import {useQueryParam, StringParam} from 'use-query-params';
import useUpdateEventStream from 'dataCollectors/api/useUpdateEventStream';
import {useToast} from 'common/utils/notifications/notificationsService';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles(() => ({
  footer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  spaceRight: {
    marginRight: 12,
  },
}));

export default function EditModal({children, isOpen, sectionLevel, closeModal, additionalText}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [streamId] = useQueryParam('streamId', StringParam);
  const {formState, getValues, trigger, reset} = useFormContext();
  const fieldsToTrigger = useFieldsToTriggerByStep(sectionLevel, 'section');
  const {updateEventStream, isUpdateEventStreamLoading} = useUpdateEventStream();
  const {success} = useToast(dispatch);

  const cancel = useCallback(() => {
    closeModal();
  }, []);

  const onSuccess = (data, id = null) => {
    cancel();

    if (id) {
      success({
        title: 'Stream was update.',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor s',
      });
    } else {
      success({
        title: 'New Stream was Created.',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor s',
      });
    }
  };

  const save = useCallback(async () => {
    const payload = getValues();
    await trigger(fieldsToTrigger);
    reset({}, {keepValues: true, keepTouched: false, keepErrors: true});

    if (isEmpty(formState.errors)) {
      updateEventStream({streamId, payload, onSuccess});
    }
  }, [sectionLevel, formState]);

  return (
    <>
      <Modal id="2-22-222" isOpen={isOpen} onClose={closeModal} size={SIZES.MEDIUM} isStatic>
        <div>{children}</div>

        <div className={classes.footer}>
          <div>{additionalText}</div>
          <div className={classes.left}>
            <Button
              automationId="exportActivityLog"
              text="Cancel"
              colorSchema={COLORS.GRAY_200}
              onClick={cancel}
              extraClassName={classes.spaceRight}
            />
            <Button
              automationId="exportActivityLog"
              text="Save"
              colorSchema={COLORS.BLUE_500}
              onClick={save}
              isLoading={isUpdateEventStreamLoading}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

EditModal.defaultProps = {
  additionalText: null,
  sectionLevel: 0,
};

EditModal.propTypes = {
  additionalText: PropTypes.string,
  sectionLevel: PropTypes.number,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};
