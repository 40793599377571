import {makeAsyncAction} from 'common/utils/simplifiedAsync';

const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/alerts.channels/');

export const fetchChannels = macAsync('FETCH_CHANNELS');
export const postChannel = macAsync('POST_CHANNEL');
export const testChannel = macAsync('TEST_CHANNEL');
export const putChannel = macAsync('PUT_CHANNEL');
export const bulkPutChannels = macAsync('BULK_PUT_CHANNELS');
export const createInitialConnection = macAsync('CREATE_CHANNEL_INITIAL_CONNECTION');
