// @flow
import React from 'react';
import {connect} from 'react-redux';
import {fetchGoogleSearchStreamSites, setGoogleSearchSchemaToStream, setSelectedStreamKeyVal} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import {SEARCH_TYPES_ARR, getAllowedAggregationTypesArr} from 'bc/services/gsearchService';
import Dropdown from './Dropdown';

import './GaDropdown.module.scss';

type PropTypes = {
  isLoading: boolean,
  dataStream: Object,
  sites: Array,

  fetchGoogleSearchStreamSites: Function,
  setGoogleSearchSchemaToStream: Function,
  setSelectedStreamKeyVal: Function,
};

export default connect(
  (state) => ({
    isLoading: selectors.getGoogleSearchStreamSitesIsLoading(state),
    sites: selectors.getGoogleSearchStreamSitesData(state),
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    fetchGoogleSearchStreamSites,
    setGoogleSearchSchemaToStream,
    setSelectedStreamKeyVal,
  },
)(
  class Properties extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {
        dataStream: {dataSourceId, schema, searchType},
      } = this.props;

      this.props.fetchGoogleSearchStreamSites({dataSourceId});

      if (!searchType) {
        this.onValueSelect('searchType', SEARCH_TYPES_ARR[0].id);
      }

      if (!schema?.sourceColumns || !schema.sourceColumns.length) {
        this.props.setGoogleSearchSchemaToStream();
      }
    }

    componentDidUpdate() {
      const {
        dataStream: {site},
        sites,
        isLoading,
      } = this.props;

      if (sites && !isLoading && !site && sites.length) {
        this.onValueSelect('site', sites[0].id);
      }
    }

    onValueSelect = (varName, val) => {
      this.props.setSelectedStreamKeyVal({[varName]: val});
    };

    updateSelectedAggregationType = () => {
      const {dataStream} = this.props;
      if (
        (dataStream.dimensions.includes('page') || ['discover', 'googleNews'].includes(dataStream.searchType)) &&
        dataStream.aggregationType !== 'byPage'
      ) {
        this.onValueSelect('aggregationType', 'byPage');
      }
      return dataStream.aggregationType;
    };

    render() {
      const {dataStream, isLoading, sites} = this.props;
      const isEditable = (isStreamEditable(dataStream.state) || isStreamRunning(dataStream.state)) && !isLoading;

      return (
        <div className="shell-col" styleName="root">
          <PanelHeader title="Web Site Data" isEditable={isEditable} />

          <Dropdown
            isLoading={isLoading}
            items={sites}
            item={dataStream.site}
            disabled={!isEditable}
            onChange={(v) => this.onValueSelect('site', v)}
            ddlId="ga_accounts_ddl"
            ddlTitle="Site"
            placeholder="Choose a site"
          />

          <Dropdown
            isLoading={false}
            items={SEARCH_TYPES_ARR}
            item={dataStream.searchType}
            disabled={!isEditable}
            onChange={(v) => this.onValueSelect('searchType', v)}
            ddlId="gs_search_ddl"
            ddlTitle="Search type"
            placeholder="Search type"
          />

          <Dropdown
            isLoading={false}
            items={getAllowedAggregationTypesArr(dataStream)}
            item={this.updateSelectedAggregationType()}
            disabled={!isEditable}
            onChange={(v) => this.onValueSelect('aggregationType', v)}
            ddlId="gs_search_ddl"
            ddlTitle="Search type"
            placeholder="Group results by"
          />
        </div>
      );
    }
  },
);
