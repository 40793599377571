export const NUMBER_OF_METRICS_DELTA = 1.2;
export const ALLOWED_FILTER_KEYS = {
  status: {
    all: {
      label: 'All',
      value: 'all',
    },
    enabled: {
      label: 'Enabled',
      value: 'enabled',
    },
    disabled: {
      label: 'Disabled',
      value: 'disabled',
    },
  },
  paying: {
    all: {
      label: 'All',
      value: 'all',
    },
    true: {
      label: 'Yes',
      value: true,
    },
    false: {
      label: 'No',
      value: false,
    },
  },
  selfService: {
    all: {
      label: 'All',
      value: 'all',
    },
    true: {
      label: 'Yes',
      value: true,
    },
    false: {
      label: 'No',
      value: false,
    },
  },
};

export const DEFAULT_QUERY_PARAMS = {
  searchQuery: '',
  status: 'all',
  paying: 'all',
  selfService: 'all',
};

export const NEW_CUSTOMER_TEMPLATE = {
  admin: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
  customer: {
    settings: {
      auth: {
        oauth: {
          google: {
            enable: true,
          },
        },
      },
      bc: {
        enabled: true,
      },
      apiTokens: {
        enabled: true,
      },
      payingCustomer: false,
      sfdcId: null,
      sierra: {
        selfService: false,
        selfServicePackage: null,
      },
      accessTokens: {
        jwtTtl: '1h',
      },
    },
    numberOfMetrics: 50000,
    tags: [],
    name: '',
    dnsPrefix: '',
  },
};

export const SELF_SERVICE_PACKAGE = [
  {
    id: 'GAD',
    text: 'Google AdWords',
  },
  {
    id: 'AWS_TRIAL',
    text: 'AWS Trial',
  },
  {
    id: 'AWS_STARTER',
    text: 'AWS Starter',
  },
  {
    id: 'AWS_PRO',
    text: 'AWS Pro',
  },
];
export const FIRST_DAY_OF_WEEK = [
  {
    id: 1,
    text: 'Monday',
  },
  {
    id: 0,
    text: 'Sunday',
  },
];
export const ROLLUPS = {
  SHORTROLLUP: {
    text: '1 min',
    selected: false,
  },
  MEDIUMROLLUP: {
    text: '5 min',
    selected: true,
    readOnly: true,
  },
  LONGROLLUP: {
    text: '1 hour',
    selected: true,
    readOnly: true,
  },
  LONGLONGROLLUP: {
    text: '1 day',
    selected: true,
    readOnly: true,
  },
  WEEKLY: {
    text: '1 week',
    selected: false,
  },
};
