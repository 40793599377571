import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {convertUiFiltersToKinesisStreamFilters} from 'bc/services/kinesisService';
import {get} from 'lodash';
import * as selectors from '../selectors';
import * as api from '../../services/api';
import * as actions from '../actions';

const fetchKinesisPathsAndValues = makeAsyncEpic(actions.fetchKinesisPathsAndValues, api.fetchKinesisPathsAndValues);
const fetchKinesisStreamAnalysis = makeAsyncEpic(actions.fetchKinesisStreamAnalysis, api.fetchKinesisStreamAnalysis);

const applyKinesisStreamFilters = (action$, {getState}) =>
  action$.ofType(actions.applyKinesisStreamFilters.TYPE).switchMap(() => {
    const selectedStream = selectors.getSelectedDataStream(getState());
    const obj = {
      recordType: selectedStream.uiState.recordType + get(selectedStream.uiState, 'recordTypeDelimiter', ''),
      filters: convertUiFiltersToKinesisStreamFilters(selectedStream.uiState.uiFilters),
    };
    return [
      actions.setSelectedStreamKeyVal(obj),
      actions.fetchKinesisStreamAnalysis({
        ...obj,
        type: selectedStream.type,
        dataSourceId: selectedStream.dataSourceId,
      }),
    ];
  });

const fetchKinesisStreamAnalysisSuccess = (action$) =>
  action$
    .ofType(actions.fetchKinesisStreamAnalysis.success.TYPE)
    .flatMap((action) => [actions.setKinesisDiametricsStreamAnalysisSchema(action.payload)]);

const setKinesisStreamUiStateDataValues = (action$, {getState}) =>
  action$
    .ofType(actions.setKinesisDiametricsStreamAnalysisSchema.TYPE)
    .switchMap(() => [selectors.getSelectedDataStream(getState())])
    .flatMap((stream) => [
      actions.setSelectedStreamKeyVal({
        schema: stream.schema,
        timeDefinition: stream.timeDefinition,
        useExternalTime: stream.useExternalTime,
        dimensions: stream.dimensions,
        metrics: stream.metrics,
      }),
    ]);

const kinesisEpic = combineEpics(
  fetchKinesisPathsAndValues,
  applyKinesisStreamFilters,
  fetchKinesisStreamAnalysis,
  fetchKinesisStreamAnalysisSuccess,
  setKinesisStreamUiStateDataValues,
);
export default kinesisEpic;
