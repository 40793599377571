import React from 'react';
import {Box} from '@material-ui/core';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

const DimensionChip = ({label, small = false}: {label: String, small: Boolean}) => {
  const style = {
    root: {
      display: 'flex',
      alignItems: 'center',
      height: 20,
      color: 'rgb(0, 147, 78)',
      backgroundColor: 'rgba(0, 147, 78, 0.2)',
      borderRadius: '3px',
      maxWidth: 130,
      paddingRight: '4px',
      paddingLeft: '4px',
    },
    rootSmall: {
      color: 'rgb(0, 147, 78)',
      maxWidth: 130,
    },
  };

  return (
    <>
      {label ? (
        <Box style={small ? style.rootSmall : style.root}>
          <Tooltip content={label} hideIfEqualTo={label?.length > 12 ? label[0] : label} type={TYPES.LARGE}>
            <Box component="span" lineHeight={1} className="ellipsis">
              {label}
            </Box>
          </Tooltip>
        </Box>
      ) : null}
    </>
  );
};

export default DimensionChip;
