import {combineEpics} from 'redux-observable';
import {get as _get} from 'lodash';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {getUniqueId} from 'common/utils/guid';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

const fetchGA4Accounts = makeAsyncEpic(actions.fetchGoogleGA4Accounts, api.fetchGA4Accounts);
const fetchGA4Properties = makeAsyncEpic(actions.fetchGoogleGA4Properties, api.fetchGA4Properties);
const fetchGA4Metadata = makeAsyncEpic(actions.fetchGoogleGA4Metadata, api.fetchGA4Metadata);
const fetchGA4Templates = makeAsyncEpic(actions.fetchGoogleGA4Templates, api.fetchStreamTemplates);
const fetchGA4MetadataAdjust = makeAsyncEpic(actions.fetchGoogleGA4MetadataAdjust, api.fetchGA4MetadataAdjust);

const setDefaultGA4Account = (action$, {getState}) =>
  action$.ofType(actions.fetchGoogleGA4Accounts.success.TYPE).flatMap((action) => {
    if (
      _get(action, 'payload.accounts[0].id') &&
      selectors.getSelectedDataStream(getState()) &&
      !selectors.getGoogleGA4SelectedAccount(getState())
    ) {
      return [actions.setSelectedStreamGA4Account({accountId: action.payload.accounts[0].id})];
    }
    return [];
  });

const setGA4Account = (action$, {getState}) =>
  action$.ofType(actions.setSelectedStreamGA4Account.TYPE).flatMap((action) => [
    actions.setSelectedStreamKeyVal(action.payload),
    actions.fetchGoogleGA4Properties({
      dataSourceId: selectors.getSelectedDataStream(getState()).dataSourceId,
      ...action.payload,
    }),
    actions.setSelectedStreamKeyVal({propertyId: null}),
  ]);

const setDefaultGA4Property = (action$, {getState}) =>
  action$.flatMap((action) => {
    if (
      _get(action, 'payload.properties[0].id') &&
      selectors.getSelectedDataStream(getState()) &&
      !selectors.getGoogleGA4SelectedProperty(getState())
    ) {
      return [actions.setSelectedStreamGA4Property({propertyId: action.payload.properties[0].id})];
    }
    return [];
  });

const setGA4Property = (action$, {getState}) =>
  action$.ofType(actions.setSelectedStreamGA4Property.TYPE).flatMap((action) => [
    actions.setSelectedStreamKeyVal(action.payload),
    actions.fetchGoogleGA4Metadata({
      dataSourceId: selectors.getSelectedDataStream(getState()).dataSourceId,
      ...action.payload,
    }),
  ]);

const fetchGoogleGA4AccountsAndProperties = (action$) =>
  action$.ofType(actions.fetchGoogleGA4AccountsAndProperties.TYPE).flatMap((action) => {
    const res = [];
    res.push(actions.fetchGoogleGA4Accounts(action.payload.dataSourceId));
    if (action.payload.accountId) {
      res.push(
        actions.fetchGoogleGA4Properties({
          dataSourceId: action.payload.dataSourceId,
          accountId: action.payload.accountId,
        }),
      );
      if (action.payload.propertyId) {
        res.push(
          actions.fetchGoogleGA4Metadata({
            dataSourceId: action.payload.dataSourceId,
            propertyId: action.payload.propertyId,
          }),
        );
      }
    }
    return res;
  });

const applyGa4TemplateToStream = (action$, {getState}) =>
  action$.ofType(actions.applyGA4TemplateOnSelectedStream.TYPE).flatMap((action) => {
    const template = selectors.getGoogleGA4TemplatesItems(getState()).find((t) => t.id === action.payload);
    const metaDimensions = selectors.getGoogleGA4MetaDimensions(getState());
    const metaMetrics = selectors.getGoogleGA4MetaMetrics(getState());
    const tsCandidate = selectors.getGoogleGA4tsCandidate(getState());

    const selectedDimensions = metaDimensions.filter((item) => template.dimensions.includes(item.apiName));
    const selectedMetrics = metaMetrics.filter((item) => template.metrics.includes(item.apiName));

    const res = {
      basedOnTemplateId: action.payload,
      timeZone: selectors.getGoogleGA4MetaTimezone(getState()),
      timestampField: tsCandidate.apiName,
      timestampType: 'timestamp',
      filters: [],
      dimensions: [],
      metrics: [],
      schema: {
        columns: [],
        sourceColumns: [],
      },
    };

    selectedDimensions.forEach((item) => {
      res.dimensions.push(item.apiName);
      res.schema.columns.push({
        id: getUniqueId(),
        name: item.uiName,
        type: 'dimension',
        sourceColumn: item.apiName,
      });
      res.schema.sourceColumns.push({
        id: item.apiName,
        name: item.uiName,
      });
    });

    selectedMetrics.forEach((item) => {
      res.metrics.push(item.apiName);
      res.schema.columns.push({
        id: getUniqueId(),
        name: item.uiName,
        type: 'metric',
        sourceColumn: item.apiName,
      });
      res.schema.sourceColumns.push({
        id: item.apiName,
        name: item.uiName,
      });
    });

    return [actions.setSelectedStreamKeyVal(res)];
  });

const googleGA4Epic = combineEpics(
  fetchGA4Accounts,
  fetchGA4Properties,
  fetchGA4Metadata,
  fetchGA4MetadataAdjust,
  setDefaultGA4Account,
  setGA4Account,
  setGA4Property,
  setDefaultGA4Property,
  fetchGoogleGA4AccountsAndProperties,
  fetchGA4Templates,
  applyGa4TemplateToStream,
);
export default googleGA4Epic;
