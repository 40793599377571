// @flow
import React, {useCallback, useEffect, useState} from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {useDispatch} from 'react-redux';
import './SwitchButton.module.scss';

type PropTypes = {
  isChecked: boolean,
  onChange: Function,
  eventKey: String,
  disabled: boolean,
  automationId: string,
};
let globalSwitchIndex = 0;

const SwitchButton = ({isChecked, onChange, eventKey, disabled, automationId}: PropTypes) => {
  const dispatch = useDispatch();
  const [switchIndex, setSwitchIndex] = useState(0);

  useEffect(() => {
    setSwitchIndex(globalSwitchIndex);
    globalSwitchIndex += 1;
  }, []);

  const toggleChange = useCallback(() => {
    dispatch(segmentClickEvent({type: 'toggle', name: automationId}));
    if (eventKey) {
      onChange({eventKey, value: !isChecked});
    } else {
      onChange(!isChecked);
    }
  }, [eventKey, onChange, isChecked]);

  return (
    <div
      styleName="wrapper"
      style={{
        pointerEvents: disabled ? 'none' : 'auto',
      }}
    >
      <input
        type="checkbox"
        checked={isChecked}
        onChange={() => {}}
        styleName="toggle"
        id={`switch-${switchIndex}`}
        disabled={disabled}
        automation-id={automationId}
      />
      <label htmlFor={`switch-${switchIndex}`} onClick={toggleChange} />
    </div>
  );
};

export default SwitchButton;
