import {createSelector} from 'reselect';
import moment from 'moment';
import {Group} from 'anodot-objects-models';
import {intersection, difference, isString, get, omit} from 'lodash';
import {
  getRoles,
  getTimeZoneName,
  getAllowedRoles,
  getUserProfile,
  getMeAppSettings,
  isAnodot,
} from 'profile/store/selectors';
import {getCorrectTimezoneName} from 'common/utils/dateService';
import {getCustomersData} from 'admin.customers/store/selectors';
import {selectors as commonSelectors} from '../../common';

export const {getUsers, getAlertsConsole} = commonSelectors;

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = [];

const GROUPS_HEADER = {
  label: 'GROUPS',
  value: 'GROUPS',
  type: 'HEADER',
  headerClass: 'andt-dropdown-option-selected-signed-header',
};

const USERS_HEADER = {
  label: 'USERS',
  value: 'USERS',
  type: 'HEADER',
  headerClass: 'andt-dropdown-option-selected-signed-header',
};

const USERS_UNASSIGNED = {
  label: 'Unassigned',
  value: 'NONE',
  type: 'UNASSIGNED',
  headerClass: 'andt-dropdown-option-selected-signed-header',
};

export const getUsersViewsSection = createSelector(
  getUsers,
  (users) => users.views || EMPTY_ARRAY,
);

export const getUsersGroupsFilters = createSelector(
  getUsersViewsSection,
  (viewSection) => viewSection.filters || EMPTY_OBJECT,
);

export const getUsersDataSection = createSelector(
  getUsers,
  (users) => users.data || EMPTY_ARRAY,
);

export const getUsersData = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.users.data || EMPTY_ARRAY,
);

export const getUsersDataCount = createSelector(
  getUsersData,
  (usersData) => usersData.length,
);

export const getUsersUpdatedAt = createSelector(
  getUsersDataSection,
  (dataSection) =>
    dataSection.users.updateAt ||
    moment()
      .unix()
      .valueOf(),
);

export const getUsersIsLoading = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.users.isLoading !== false,
);

export const editUsersBulkApi = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.editUsersBulkApi || EMPTY_OBJECT,
);

export const editUsersBulkApiIsLoading = createSelector(
  editUsersBulkApi,
  (bulk) => bulk.isLoading,
);

export const deleteUsersBulkApi = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.deleteUsersBulkApi || EMPTY_OBJECT,
);

export const deleteUsersBulkApiIsLoading = createSelector(
  deleteUsersBulkApi,
  (bulk) => bulk.isLoading,
);

export const fetchGroups = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.fetchGroups || EMPTY_OBJECT,
);

export const fetchGroupsData = createSelector(
  fetchGroups,
  (groups) => {
    if (!groups.data) {
      return EMPTY_ARRAY;
    }

    return groups.data.map((gr) => new Group(gr));
  },
);

export const fetchGroupsIsLoading = createSelector(
  fetchGroups,
  (groups) => groups.isLoading,
);

export const fetchGroupsEditNewUser = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.fetchGroupsEditNewUser || EMPTY_OBJECT,
);

export const fetchGroupsEditNewUserIsLoading = createSelector(
  fetchGroupsEditNewUser,
  (groups) => groups.isLoading,
);

export const fetchGroupsEditNewUserData = createSelector(
  fetchGroupsEditNewUser,
  (groups) => {
    if (!groups.data) {
      return EMPTY_ARRAY;
    }

    return groups.data.map((gr) => new Group(gr));
  },
);

export const getGroupsDataList = createSelector(
  isAnodot,
  fetchGroupsData,
  fetchGroupsEditNewUserData,
  (isAndt, groupsData, editNewGroupData) => (isAndt ? editNewGroupData : groupsData),
);

export const fetchUser = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.fetchUser || EMPTY_OBJECT,
);

export const fetchUserIsLoading = createSelector(
  fetchUser,
  (user) => user.isLoading,
);

export const cretaeUsers = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.createUsers || EMPTY_OBJECT,
);

export const createUsersIsLoading = createSelector(
  cretaeUsers,
  (createUsers) => createUsers.isLoading,
);

export const createUsersData = createSelector(
  cretaeUsers,
  (createUsers) => createUsers.data || EMPTY_OBJECT,
);

export const createUsersDataNewUsers = createSelector(
  createUsersData,
  (usersData) => usersData.users,
);

export const createUsersDataValidationFailures = createSelector(
  createUsersData,
  (usersData) => get(usersData, 'validationResult.failures') || EMPTY_ARRAY,
);

// groupsModal

export const getIsGroupSectionOpen = createSelector(
  getMeAppSettings,
  (appSettings) => get(appSettings, 'usersGroups.isGroupSectionOpen', true),
);

export const getGroupsModalView = createSelector(
  getUsersViewsSection,
  (viewSection) => viewSection.groups || EMPTY_OBJECT,
);

export const getGroupsModalIsOpen = createSelector(
  getGroupsModalView,
  (groups) => groups.isGroupModalOpen,
);

export const getGroupsModalEditingGroupId = createSelector(
  getGroupsModalView,
  (groups) => groups.editingGroupId,
);

export const getGroupsModalGroupName = createSelector(
  getGroupsModalView,
  (groups) => groups.groupName,
);

export const getGroupsModalGroupColorSchema = createSelector(
  getGroupsModalView,
  (groups) => groups.selectedColor,
);

export const getGroupsModalGroupUsers = createSelector(
  getGroupsModalView,
  (groups) => groups.selectedUsers || EMPTY_ARRAY,
);

export const getGroupsDeleteModal = createSelector(
  getGroupsModalView,
  (groupsView) => groupsView.deleteModal || EMPTY_ARRAY,
);

export const getGroupsDeleteModalIsOpen = createSelector(
  getGroupsDeleteModal,
  (groupsDeleteModal) => groupsDeleteModal.isOpen,
);

export const getGroupsDeleteModalGroupId = createSelector(
  getGroupsDeleteModal,
  (groupsDeleteModal) => groupsDeleteModal.groupId,
);

export const getGroupsDeleteModalIsDelegate = createSelector(
  getGroupsDeleteModal,
  (groupsDeleteModal) => groupsDeleteModal.isDelegate,
);

export const getGroupsDeleteModalDelegateUserId = createSelector(
  getGroupsDeleteModal,
  (groupsDeleteModal) => groupsDeleteModal.delegateUserId,
);

export const getGroupsDeleteModalGroupItem = createSelector(
  getGroupsDeleteModalGroupId,
  fetchGroupsData,
  (groupId, groupList) => groupList.find((gr) => gr.id === groupId) || EMPTY_OBJECT,
);

export const getGroupModalSideBarTabs = createSelector(
  getGroupsModalGroupUsers,
  (selectedUsers) => [
    {
      id: 0,
      label: 'Properties',
    },
    {
      id: 1,
      label: selectedUsers.length ? `Members (${selectedUsers.length})` : 'Members',
    },
    {
      id: 2,
      label: 'Group Access',
    },
  ],
);

export const getEditingGroup = createSelector(
  getGroupsModalEditingGroupId,
  fetchGroupsData,
  (groupId, groups) => {
    const groupFound = groups.find((group) => group.id === groupId);
    return groupFound || null;
  },
);

export const getUsersDataForGroupsModal = createSelector(
  getUsersData,
  (users) => {
    if (!users.length) {
      return EMPTY_ARRAY;
    }

    return users
      .map((user) => ({
        id: user._id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        abbr: (user.firstName[0] + user.lastName[0]).toUpperCase(),
      }))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  },
);

export const getUsersDataForAvatar = createSelector(
  getUsersData,
  fetchGroupsData,
  (users, groupsData) => {
    if (!users.length) {
      return EMPTY_ARRAY;
    }

    return users
      .map((user) => ({
        id: user._id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        abbr: (user.firstName[0] + user.lastName[0]).toUpperCase(),
        defaultGroup: groupsData.find((gr) => gr.id === user.defaultGroup) || null,
        allGroups: user.groups,
      }))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  },
);

export const getUsersDataForAssigneeFilter = createSelector(
  getUsersDataForAvatar,
  getUserProfile,
  getAlertsConsole,
  (users, meUserProfile, alertsConsole) => {
    if (!users.length) {
      return EMPTY_ARRAY;
    }

    let mappedUsers = users.map((o) => ({
      label: o.name,
      value: o.id,
      isDisabled: false,
      type: 'USERS',
    }));

    const meUser = mappedUsers.find((user) => user.value === meUserProfile._id);
    meUser.label = `me (${meUser.label})`;

    mappedUsers.unshift(USERS_HEADER);
    mappedUsers.unshift(USERS_UNASSIGNED);

    const queryParamsAssignee = alertsConsole.views.queryParams?.assignee;
    let selectedUsers = [];
    if (queryParamsAssignee) {
      if (queryParamsAssignee === USERS_UNASSIGNED.value) {
        selectedUsers.push(USERS_UNASSIGNED);
      } else {
        selectedUsers = mappedUsers.filter((user) => (queryParamsAssignee || '').includes(user.value));
      }
    }

    if (!selectedUsers.length) {
      for (let i = 0; i < mappedUsers?.length; i++) {
        mappedUsers[i].isDisabled = false;
      }
    } else if (selectedUsers.find((item) => item.type === USERS_UNASSIGNED.type)) {
      for (let i = 0; i < mappedUsers?.length; i++) {
        if (mappedUsers[i].type !== USERS_UNASSIGNED.type && mappedUsers[i].type !== USERS_HEADER.type) {
          mappedUsers[i].isDisabled = true;
        }
      }
    } else {
      mappedUsers = mappedUsers.filter((item) => item.type !== USERS_UNASSIGNED.type);
    }

    return mappedUsers;
  },
);

export const getGroupSectionGroupList = createSelector(
  fetchGroupsData,
  getUsersDataForGroupsModal,
  (groups, modalUsers) => {
    if (!modalUsers.length || !groups.length) {
      return EMPTY_ARRAY;
    }

    return groups
      .map((group) => {
        const users = modalUsers.filter((modalUser) => group.users.indexOf(modalUser.id) !== -1);

        return {
          id: group._id,
          name: group.name,
          colorSchema: group.colorSchema,
          users,
        };
      })
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  },
);

export const getEditingSelectedUsers = createSelector(
  getEditingGroup,
  getUsersDataForGroupsModal,
  (editingGroup, modalUsers) => {
    if (!editingGroup || !modalUsers.length) {
      return EMPTY_ARRAY;
    }

    return modalUsers.filter((modalUser) => editingGroup.users.indexOf(modalUser.id) !== -1);
  },
);

// user section

export const getUsersView = createSelector(
  getUsersViewsSection,
  (viewSection) => viewSection.users || EMPTY_OBJECT,
);

export const getUsersViewEditModal = createSelector(
  getUsersView,
  (viewSection) => viewSection.editModal,
);

export const getSelectedUsersCheckbox = createSelector(
  getUsersView,
  (viewSection) => viewSection.selectedUsersCheckbox || EMPTY_ARRAY,
);

export const getUsersListOrder = createSelector(
  getUsersView,
  (usersView) => usersView.listOrder,
);

export const getUserEditingModalIsOpen = createSelector(
  getUsersViewEditModal,
  (editModalView) => editModalView.isOpen,
);

export const getEditingUserId = createSelector(
  getUsersViewEditModal,
  (editModalView) => editModalView.editingUserId,
);

export const getEditingUserFirstName = createSelector(
  getUsersViewEditModal,
  (editModalView) => editModalView.firstName,
);

export const getEditingUserLastName = createSelector(
  getUsersViewEditModal,
  (editModalView) => editModalView.lastName,
);

export const getEditingUserEmail = createSelector(
  getUsersViewEditModal,
  (editModalView) => editModalView.email,
);

export const getEditingUserOwnerOrganization = createSelector(
  getUsersViewEditModal,
  (editModalView) => editModalView.ownerOrganization,
);

export const getEditingUserIsDisabled = createSelector(
  getUsersViewEditModal,
  (editModalView) => editModalView.disabled,
);

export const getEditingUserOrgName = createSelector(
  getUsersViewEditModal,
  (editModalView) => editModalView.orgName,
);

export const getEditingUserSelectedGroups = createSelector(
  getUsersViewEditModal,
  getGroupsDataList,
  (editModalView, groupsData) => {
    if (!editModalView.groups || !editModalView.groups.length || !groupsData.length) {
      return EMPTY_ARRAY;
    }

    return groupsData.filter((gr) => intersection(editModalView.groups, [gr.id]).length !== 0);
  },
);

export const getEditingUserSelectedDefaultGroup = createSelector(
  getUsersViewEditModal,
  getGroupsDataList,
  (editModalView, groupsData) => {
    if (!editModalView.defaultGroup || !groupsData.length) {
      return EMPTY_OBJECT;
    }

    return groupsData.find((gr) => gr.id === editModalView.defaultGroup);
  },
);

export const getEditingUserRole = createSelector(
  getUsersViewEditModal,
  getAllowedRoles,
  (editModalView, roles) => {
    if (!editModalView.roles || !editModalView.roles.length || !roles.length) {
      return EMPTY_OBJECT;
    }

    return roles.find((r) => r.role === editModalView.roles[0]);
  },
);

export const getEditingUserAllowedRoles = createSelector(
  getUsersViewEditModal,
  getAllowedRoles,
  (editModalView, roles) => {
    if (!editModalView.roles || !editModalView.roles.length || !roles.length) {
      return EMPTY_ARRAY;
    }

    if (editModalView.originalRole.indexOf('anodot-') !== -1) {
      return roles.filter((r) => r.role.indexOf('anodot-') !== -1);
    }

    if (editModalView.originalRole === 'customer-read-only') {
      return roles.filter((r) => r.role.indexOf('customer-') !== -1);
    }

    return roles.filter((r) => r.role.indexOf('customer-') !== -1 && r.role !== 'customer-read-only');
  },
);

export const getEditingUserObject = createSelector(
  getUsersViewEditModal,
  (editModalView) => omit(editModalView, ['editingUserId', 'isOpen', 'orgName']),
);

export const getUsersViewNewUserModal = createSelector(
  getUsersView,
  (viewSection) => viewSection.newModal,
);

export const getNewUserModalIsOpen = createSelector(
  getUsersViewNewUserModal,
  (newModal) => newModal.isOpen,
);

export const getNewUserModalIsEmailExistOpen = createSelector(
  getUsersViewNewUserModal,
  (newModal) => newModal.isEmailExistOpen,
);

export const getNewUserModalUsersInputValue = createSelector(
  getUsersViewNewUserModal,
  (newModal) => newModal.inputValue,
);

export const getNewUserModalUsersMessage = createSelector(
  getUsersViewNewUserModal,
  (newModal) => newModal.message,
);

export const getNewUserModalUsersSelectedRole = createSelector(
  getUsersViewNewUserModal,
  (newModal) => newModal.selectedRole,
);

export const getNewUserModalUsersSelectedOrg = createSelector(
  getUsersViewNewUserModal,
  (newModal) => newModal.selectedOrg,
);

export const getNewUserModalTimeZoneName = createSelector(
  getUsersViewNewUserModal,
  getTimeZoneName,
  (newModal, timeZoneName) => newModal.timeZoneName || timeZoneName,
);

export const getNewUserModalUsersSelectedGroups = createSelector(
  getUsersViewNewUserModal,
  getGroupsDataList,
  (newModalView, groupsData) => {
    if (!newModalView.groups || !newModalView.groups.length || !groupsData.length) {
      return EMPTY_ARRAY;
    }

    return groupsData.filter((gr) => intersection(newModalView.groups, [gr.id]).length !== 0);
  },
);

export const getNewUserModalUsersSelectedDefaultGroup = createSelector(
  getUsersViewNewUserModal,
  getGroupsDataList,
  (newModalView, groupsData) => {
    if (!newModalView.defaultGroup || !groupsData.length) {
      return EMPTY_OBJECT;
    }

    return groupsData.find((gr) => gr.id === newModalView.defaultGroup);
  },
);

export const getNewUserModalUsersList = createSelector(
  getUsersViewNewUserModal,
  (newModal) => newModal.users || EMPTY_ARRAY,
);

export const getNewUserModalUsersListError = createSelector(
  getUsersViewNewUserModal,
  (newModal) => {
    const findInvalidItem = newModal.users.find((user) => user.error);
    return findInvalidItem ? findInvalidItem.error : '';
  },
);

export const getNewUserModalAllowedRoles = createSelector(
  getNewUserModalUsersSelectedOrg,
  getAllowedRoles,
  (selectedOrg, roles) => {
    if (!roles.length) {
      return EMPTY_ARRAY;
    }

    if (selectedOrg && selectedOrg._id === '5418101dfcba5daa31e38c93') {
      return roles.filter((r) => r.role.indexOf('anodot-') !== -1);
    }

    return roles.filter((r) => r.role.indexOf('customer-') !== -1);
  },
);

export const getUserSectionUsersList = createSelector(
  getUsersData,
  getRoles,
  fetchGroupsData,
  getCustomersData,
  getTimeZoneName,
  (usersData, roles, groupsData, customersData, timeZoneName) => {
    if (!usersData.length) {
      return EMPTY_ARRAY;
    }

    const defaultGroupName = (userGroups, defaultGroup) => {
      if (!defaultGroup && userGroups && userGroups.length) {
        return userGroups.length === 1 ? '1 Group' : `${userGroups.length} Groups`;
      }

      if (!defaultGroup) {
        return '';
      }

      const findGroup = groupsData.find((gr) => gr.id === defaultGroup);
      return findGroup ? findGroup.name : '';
    };

    const extraGroupsObj = (userGroups, defaultGroup) => {
      if (!userGroups || !defaultGroup || !userGroups.length || !groupsData.length) {
        return null;
      }

      const extraUserGroups = difference(userGroups, [defaultGroup]);
      const filteredGroups = groupsData.filter((gr) => intersection(extraUserGroups, [gr.id]).length !== 0);

      if (filteredGroups && filteredGroups.length) {
        return {
          text: `+${filteredGroups.length}`,
          tooltip: filteredGroups.map((gr) => gr.name).join(', '),
        };
      }

      return null;
    };

    const orgVal = (userOrgId) => {
      if (!customersData || !customersData.length) {
        return '';
      }

      const findOrg = customersData.find((c) => c._id === userOrgId);
      return findOrg ? findOrg.name : '';
    };

    const activeLastText = (lastActive) => {
      if (!lastActive) {
        return '';
      }

      const now = moment.tz(getCorrectTimezoneName(timeZoneName));
      const lastMoment = moment.tz(lastActive, getCorrectTimezoneName(timeZoneName));

      if (now.year() === lastMoment.year() && now.dayOfYear() === lastMoment.dayOfYear()) {
        return 'Today';
      }

      if (now.year() === lastMoment.year() && now.dayOfYear() === lastMoment.dayOfYear() + 1) {
        return 'Yesterday';
      }

      return lastMoment.format('ll');
    };

    return usersData.map((user) => ({
      id: user._id,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      firstName: user.firstName,
      lastName: user.lastName,
      abbr: (user.firstName[0] + user.lastName[0]).toUpperCase(),
      role: roles.find((i) => user.roles && i.role === user.roles[0]).name,
      userRole: user.roles[0],
      status: user.disabled,
      lastActive: user.lastActive ? moment(user.lastActive).unix() : 0,
      lastActiveText: activeLastText(user.lastActive),
      organization: orgVal(user.ownerOrganization),
      ownerOrganization: user.ownerOrganization,
      groups: defaultGroupName(user.groups, user.defaultGroup),
      extraGroups: extraGroupsObj(user.groups, user.defaultGroup),
      defaultGroup: groupsData.find((gr) => gr.id === user.defaultGroup) || null,
      userGroups: user.groups,
    }));
  },
);

export const getSortedUserSectionUsersList = createSelector(
  getUserSectionUsersList,
  getUsersListOrder,
  (usersList, listOrder) => {
    const sortingFunction = (arr, sortBy, direction) =>
      arr.sort((a, b) => {
        const itemA = isString(a[sortBy]) ? a[sortBy].toLowerCase() : a[sortBy];
        const itemB = isString(b[sortBy]) ? b[sortBy].toLowerCase() : b[sortBy];
        if (itemA < itemB) {
          return -1 * direction;
        }
        if (itemA > itemB) {
          return direction;
        }
        return 0;
      });

    return [...sortingFunction(usersList, listOrder.column, listOrder.direction === 'desc' ? -1 : 1)];
  },
);

export const getUsersViewStatusModal = createSelector(
  getUsersView,
  (viewSection) => viewSection.userStatusModal,
);

export const getUsersViewStatusModalUserName = createSelector(
  getUsersViewStatusModal,
  getUserSectionUsersList,
  (userStatusModal, sectionUserList) => {
    const findUser = sectionUserList.find((user) => user.id === userStatusModal.userId);
    return findUser ? findUser.name : 'Unknown';
  },
);

export const getBulkDeleteUsersModal = createSelector(
  getUsersView,
  (viewSection) => viewSection.bulkDeleteModal,
);

export const getBulkDeleteUsersModalIsDelegate = createSelector(
  getBulkDeleteUsersModal,
  (bulkDeleteModal) => bulkDeleteModal.isDelegate,
);

export const getBulkDeleteUsersModalDelegateUserId = createSelector(
  getBulkDeleteUsersModal,
  (bulkDeleteModal) => bulkDeleteModal.delegateUserId,
);

export const getBulkDeleteUsersModalDelegateUser = createSelector(
  getBulkDeleteUsersModalDelegateUserId,
  getUserSectionUsersList,
  (delegateUserId, sectionUserList) => {
    const findUser = sectionUserList.find((user) => user.id === delegateUserId);
    return findUser || null;
  },
);

export const getBulkSelectedUsers = createSelector(
  getSelectedUsersCheckbox,
  getUserSectionUsersList,
  (selectedUsersCheckbox, sectionUserList) =>
    sectionUserList.filter((userItem) => selectedUsersCheckbox.indexOf(userItem.id) !== -1),
);

export const getBulkSelectedUsersHighestRole = createSelector(
  getBulkSelectedUsers,
  (bulkSelectedUsers) => {
    const isCustomerAdminPresent = bulkSelectedUsers.some((bulkUser) => bulkUser.userRole === 'customer-admin');
    return isCustomerAdminPresent ? 'customer-admin' : 'customer-user';
  },
);

export const isBulkDeleteSelectedUsers = createSelector(
  isAnodot,
  getBulkSelectedUsers,
  (isMeAnodot, bulkSelectedUsers) => {
    if (!isMeAnodot) {
      return true;
    }

    return !bulkSelectedUsers.some((u) => u.userRole.indexOf('anodot-') === -1);
  },
);

export const getBulkDeleteUsersModalDelegateUsersList = createSelector(
  getUserSectionUsersList,
  getSelectedUsersCheckbox,
  getBulkSelectedUsersHighestRole,
  (sectionUserList, selectedUsersCheckbox, deleteUserRole) =>
    sectionUserList.filter((user) => {
      if (selectedUsersCheckbox.indexOf(user.id) !== -1) {
        return false;
      }
      if (user.userRole === 'customer-admin') {
        return true;
      }

      if (deleteUserRole === 'customer-user') {
        return user.userRole === 'customer-user';
      }

      return false;
    }),
);

export const getUsersViewDeleteModal = createSelector(
  getUsersView,
  (viewSection) => viewSection.deleteModal,
);

export const getUsersViewDeleteModalIsDelegate = createSelector(
  getUsersViewDeleteModal,
  (deleteModal) => deleteModal.isDelegate,
);

export const getUsersViewDeleteModalUserId = createSelector(
  getUsersViewDeleteModal,
  (deleteModal) => deleteModal.userId,
);

export const getUsersViewDeleteModalDelegateUserId = createSelector(
  getUsersViewDeleteModal,
  (deleteModal) => deleteModal.delegateUserId,
);

export const getUsersViewDeleteModalDelegateUser = createSelector(
  getUsersViewDeleteModal,
  getUserSectionUsersList,
  (deleteModal, sectionUserList) => {
    const findUser = sectionUserList.find((user) => user.id === deleteModal.delegateUserId);
    return findUser || null;
  },
);

export const getUsersViewDeleteModalUser = createSelector(
  getUsersViewDeleteModal,
  getUserSectionUsersList,
  (deleteModal, sectionUserList) => {
    const findUser = sectionUserList.find((user) => user.id === deleteModal.userId);
    return findUser || null;
  },
);

export const getUsersViewDeleteModalUserName = createSelector(
  getUsersViewDeleteModalUser,
  (deleteUser) => (deleteUser ? deleteUser.name : 'Unknown'),
);

export const getUsersViewDeleteModalDelegateUsersList = createSelector(
  getUserSectionUsersList,
  getUsersViewDeleteModalUser,
  (sectionUserList, deleteUser) =>
    sectionUserList.filter((user) => {
      const deleteUserRole = deleteUser ? deleteUser.userRole : 'customer-admin';
      if (deleteUser && deleteUser.id === user.id) {
        return false;
      }
      if (user.userRole === 'customer-admin') {
        return true;
      }

      if (deleteUserRole === 'customer-user') {
        return user.userRole === 'customer-user';
      }

      return false;
    }),
);

export const fetchOwnerDashboardsCount = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.fetchOwnerDashboardsCount || EMPTY_OBJECT,
);

export const getOwnerDashboardsCountIsLoading = createSelector(
  fetchOwnerDashboardsCount,
  (res) => res.isLoading !== false,
);

export const getOwnerDashboardsCountValue = createSelector(
  fetchOwnerDashboardsCount,
  (res) => res.data.total,
);

export const fetchOwnerAnoboardsCount = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.fetchOwnerAnoboardsCount || EMPTY_OBJECT,
);

export const getOwnerAnoboardsCountIsLoading = createSelector(
  fetchOwnerAnoboardsCount,
  (res) => res.isLoading !== false,
);

export const getOwnerAnoboardsCountValue = createSelector(
  fetchOwnerAnoboardsCount,
  (res) => res.data.total,
);

export const fetchOwnerAlertsCount = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.fetchOwnerAlertsCount || EMPTY_OBJECT,
);

export const getOwnerAlertsCountIsLoading = createSelector(
  fetchOwnerAlertsCount,
  (res) => res.isLoading !== false,
);

export const getOwnerAlertsCountValue = createSelector(
  fetchOwnerAlertsCount,
  (res) => res.data.total,
);

export const fetchOwnerChannelsCount = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.fetchOwnerChannelsCount || EMPTY_OBJECT,
);

export const getOwnerChannelsCountIsLoading = createSelector(
  fetchOwnerChannelsCount,
  (res) => res.isLoading !== false,
);

export const getOwnerChannelsCountValue = createSelector(
  fetchOwnerChannelsCount,
  (res) => res.data.total,
);

export const fetchOwnerCompositesCount = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.fetchOwnerCompositesCount || EMPTY_OBJECT,
);

export const getOwnerCompositesCountIsLoading = createSelector(
  fetchOwnerCompositesCount,
  (res) => res.isLoading !== false,
);

export const getOwnerCompositesCountValue = createSelector(
  fetchOwnerCompositesCount,
  (res) => res.data.total,
);

export const fetchOwnerRefreshTokensCount = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.fetchOwnerRefreshTokensCount || EMPTY_OBJECT,
);

export const getOwnerRefreshTokensCountIsLoading = createSelector(
  fetchOwnerRefreshTokensCount,
  (res) => res.isLoading !== false,
);

export const getOwnerRefreshTokensCountValue = createSelector(
  fetchOwnerRefreshTokensCount,
  (res) => res.data.total,
);

export const fetchOwnerStreamsCount = createSelector(
  getUsersDataSection,
  (dataSection) => dataSection.fetchOwnerStreamsCount || EMPTY_OBJECT,
);

export const getOwnerStreamsCountIsLoading = createSelector(
  fetchOwnerStreamsCount,
  (res) => res.isLoading !== false,
);

export const getOwnerStreamsCountValue = createSelector(
  fetchOwnerStreamsCount,
  (res) => res.data.total || 0,
);

/**
 * Users and Groups Select - selectors
 * */

export const getUsersGroupsLists = createSelector(
  getUsersData,
  fetchGroupsData,
  (users, groups) => {
    if (!users.length) {
      return null;
    }

    const groupsArr = groups
      .map((group) => ({
        label: group.name,
        value: group.id,
        email: 'andt-group',
        _id: group.id,
        type: 'GROUPS',
        count: `${group.users.length} Members`,
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

    const userArr = users
      .map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user._id,
        email: user.email,
        _id: user._id,
        role: user.roles[0],
        type: 'USERS',
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

    return {
      userList: userArr,
      groupList: groupsArr,
    };
  },
);

export const getUsersGroupsListsWithoutDeleteGroup = createSelector(
  getUsersGroupsLists,
  getGroupsDeleteModalGroupId,
  (lists, deleteGroupId) => {
    if (!lists) {
      return EMPTY_ARRAY;
    }

    const groupList = lists.groupList.filter((grItem) => grItem.value !== deleteGroupId);

    let resArr = EMPTY_ARRAY;
    if (groupList.length) {
      resArr = [GROUPS_HEADER, ...groupList];
    }
    if (lists.userList.length) {
      resArr = [...resArr, USERS_HEADER, ...lists.userList];
    }

    return resArr;
  },
);

export const getUsersGroupsListsFull = createSelector(
  getUsersGroupsLists,
  (lists) => {
    if (!lists) {
      return EMPTY_ARRAY;
    }

    let resArr = EMPTY_ARRAY;
    if (lists.groupList.length) {
      resArr = [GROUPS_HEADER, ...lists.groupList];
    }
    if (lists.userList.length) {
      resArr = [...resArr, USERS_HEADER, ...lists.userList];
    }

    return resArr;
  },
);

export const getUsersGroupsListsFilterByUserProfile = createSelector(
  getUsersGroupsLists,
  fetchGroupsData,
  getUserProfile,
  (lists, groups, me) => {
    if (!lists || !me) {
      return EMPTY_ARRAY;
    }

    if (me && me.roles && me.roles[0] !== 'customer-user') {
      return [GROUPS_HEADER, ...lists.groupList, USERS_HEADER, ...lists.userList];
    }

    const myGroupIds = me.groups || EMPTY_ARRAY;
    let myGroupUserIds = [];
    groups
      .filter((gr) => intersection(me.groups || EMPTY_ARRAY, [gr.id]).length !== 0)
      .forEach((myGr) => {
        myGroupUserIds = [...myGroupUserIds, ...myGr.users];
      });

    const filteredGroupList = lists.groupList.filter(
      (grListItem) => intersection(myGroupIds, [grListItem._id]).length !== 0,
    );
    const filteredUserList = lists.userList.filter((uListItem) => {
      if (uListItem.role === 'customer-admin') {
        return true;
      }

      if (uListItem.role === 'customer-read-only') {
        return false;
      }

      return intersection(myGroupUserIds, [uListItem._id]).length !== 0;
    });

    let resArr = EMPTY_ARRAY;
    if (filteredGroupList.length) {
      resArr = [GROUPS_HEADER, ...filteredGroupList];
    }
    if (filteredUserList.length) {
      resArr = [...resArr, USERS_HEADER, ...filteredUserList];
    }

    return resArr;
  },
);

export const getMyGroups = createSelector(
  getUserProfile,
  fetchGroupsData,
  (me, groups) => {
    if (!me || !me.groups || !me.groups.length || !groups || !groups.length) {
      return EMPTY_ARRAY;
    }

    return groups.filter((group) => me.groups.indexOf(group._id) !== -1);
  },
);
