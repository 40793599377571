// @flow

import React, {useCallback, useState} from 'react';
import Button, {COLORS as BUTTON_COLORS} from 'common/componentsV2/Button';
import DeleteChannel from 'channels/pages/DeleteChannel';
import './ChannelsFooter.module.scss';

const ChannelsFooter = ({selectedItems, setCheckedItems}: {selectedItems: Array, setCheckedItems: Function}) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const onDeleteClick = useCallback(() => {
    setIsDeleteModalOpen(true);
  }, []);

  return (
    <>
      <div styleName="button-wrapper">
        <Button
          colorSchema={BUTTON_COLORS.TRANSPARENT}
          text="Delete"
          onClick={onDeleteClick}
          icon="icn-action16-delete"
        />
      </div>
      {isDeleteModalOpen && (
        <DeleteChannel
          onClose={() => setIsDeleteModalOpen(false)}
          isBulk
          selectedItems={selectedItems}
          setCheckedItems={setCheckedItems}
        />
      )}
    </>
  );
};

export default ChannelsFooter;
