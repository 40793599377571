import React, {useMemo} from 'react';
import {INVESTIGATION_MODAL_TABS, ALERT_TYPES} from 'investigationNewAlertConsole/services/investigationService';
import './OverwiewTab.module.scss';
import WhatInfoCube from './infoCubes/WhatInfoCube';
import WhereInfoCube from './infoCubes/WhereInfoCube';
import CorrelationsInfoCube from './infoCubes/CorrelationsInfoCube';
import AlertActionsCube from './infoCubes/AlertActionsCube';
import InfoCube from './infoCubes/InfoCube';
import WhatInfoCubeStatic from './infoCubes/WhatInfoCubeStatic';
import WhereInfoCubeStatic from './infoCubes/WhereInfoCubeStatic';
import HowOftenCubeStatic from './infoCubes/HowOftenCubeStatic';
import WhatInfoCubeNoData from './infoCubes/WhatInfoCubeNoData';
import WhatWithDataStream from './infoCubes/WhatWithDataStream';

const getSteps = (trigger, isTriggerLoading) => {
  switch (trigger.type) {
    case ALERT_TYPES.ANOMALY:
      return [
        {
          number: '1',
          id: 'what',
          title: 'What happened?',
          body: <WhatInfoCube trigger={trigger} isTriggerLoading={isTriggerLoading} />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '2',
          id: 'where',
          title: 'Where did it happen?',
          body: <WhereInfoCube trigger={trigger} isTriggerLoading={isTriggerLoading} />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '3',
          id: 'why',
          title: 'Why did it happen?',
          body: <CorrelationsInfoCube trigger={trigger} isTriggerLoading={isTriggerLoading} />,
          targetTab: INVESTIGATION_MODAL_TABS.correlations.id,
        },
        {
          number: '4',
          id: 'actions',
          title: 'What to do next?',
          body: <AlertActionsCube trigger={trigger} isTriggerLoading={isTriggerLoading} />,
        },
      ];
    case ALERT_TYPES.STATIC:
      return [
        {
          number: '1',
          id: 'what',
          title: 'What happened?',
          body: <WhatInfoCubeStatic trigger={trigger} isTriggerLoading={isTriggerLoading} />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '2',
          id: 'where',
          title: 'Where did it happen?',
          body: <WhereInfoCubeStatic trigger={trigger} isTriggerLoading={isTriggerLoading} />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '3',
          id: 'why',
          title: 'How often does it happen?',
          body: <HowOftenCubeStatic trigger={trigger} isTriggerLoading={isTriggerLoading} />,
          targetTab: INVESTIGATION_MODAL_TABS.timeline.id,
        },
        {
          number: '4',
          id: 'actions',
          title: 'What to do next?',
          body: <AlertActionsCube trigger={trigger} isTriggerLoading={isTriggerLoading} />,
        },
      ];
    case ALERT_TYPES.NO_DATA:
      return [
        {
          number: '1',
          id: 'what',
          title: 'What happened?',
          body: <WhatInfoCubeNoData trigger={trigger} isTriggerLoading={isTriggerLoading} />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '2',
          id: 'where',
          title: 'Where did it happen?',
          body: <WhereInfoCubeStatic trigger={trigger} isTriggerLoading={isTriggerLoading} />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '3',
          id: 'why',
          title: 'What’s with the data stream?',
          body: <WhatWithDataStream trigger={trigger} isTriggerLoading={isTriggerLoading} />,
          targetTab: INVESTIGATION_MODAL_TABS.timeline.id,
        },
        {
          number: '4',
          id: 'actions',
          title: 'What to do next?',
          body: <AlertActionsCube trigger={trigger} isTriggerLoading={isTriggerLoading} />,
        },
      ];
    default:
      return [];
  }
};

const OverviewTab = ({
  onCubeClick,
  onShareClicked,
  onCloseBalloon,
  trigger,
  isTriggerLoading,
}: {
  onCubeClick: Function,
  onShareClicked: Function,
  onCloseBalloon: Function,
  trigger: Object,
  isTriggerLoading: Boolean,
}) => {
  const infoCubes = useMemo(
    () => (
      <>
        {!isTriggerLoading &&
          trigger &&
          getSteps(trigger, isTriggerLoading).map((st) => (
            <InfoCube
              num={st.number}
              title={st.title}
              key={`overview-step-${st.number}`}
              isClickable={!!st.targetTab}
              onClick={() => (st.targetTab ? onCubeClick(st.targetTab) : null)}
              onShareClicked={st.id === 'actions' ? onShareClicked : null}
              onCloseBalloon={st.id === 'actions' ? onCloseBalloon : null}
            >
              {st.body}
            </InfoCube>
          ))}
      </>
    ),
    [isTriggerLoading, trigger],
  );

  return !isTriggerLoading && trigger ? <div styleName="overview-tab-content">{infoCubes}</div> : <div />;
};

export default OverviewTab;
