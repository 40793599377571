// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import * as selector from 'admin.users/store/selectors';
import {toggleNewUserModal} from 'admin.users/store/actions';
import NewUserForm from './NewUserForm';
import './NewUserFrom.module.scss';

type PropTypes = {
  isOpen: boolean,
  toggleNewUserModal: Function,
};

export default connect(
  (state) => ({
    isOpen: selector.getNewUserModalIsOpen(state),
  }),
  {
    toggleNewUserModal,
  },
)(
  class NewUserModal extends React.PureComponent {
    props: PropTypes;

    onModalClose = () => {
      this.props.toggleNewUserModal({isOpen: false});
    };

    render() {
      return (
        <Modal
          classStyle="new-user-modal"
          id="new-user-modal"
          isOpen={this.props.isOpen}
          onClose={this.onModalClose}
          size={SIZES.SMALL}
          isStatic
        >
          <NewUserForm />
        </Modal>
      );
    }
  },
);
