import React, {useCallback, useState} from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';

// This function is being called from 2 places
// First it is called from the handleChange, with a string value
// Then it is called for the second time from Validate which is triggered as a result of changing the form value
const isInvalid = (value) => {
  // When called from validate, we might get a parsed object, which is valid
  if (typeof value === 'object') return false;
  // if a string, try to validate
  try {
    // eslint-disable-next-line no-unused-vars
    const test = JSON.parse(value || '{}');
  } catch (e) {
    return true;
  }
  return false;
};

const validate = (value) => {
  return isInvalid(value) ? 'Not a valid JSON object' : undefined;
};

const Headers = () => {
  const {
    input: {value, onChange},
    meta,
  } = useField('headers', {validate});
  const [stringVal, setStringVal] = useState(value ? JSON.stringify(value) : null);

  const handleChange = useCallback((e) => {
    // always set local state to update current value
    setStringVal(e.target.value);
    // if new value is not a valid JSON, trigger a change to an hard coded string value, that will set the field as invalid with proper error
    // and will block submission of the form until fix
    // if value is a valid JSON, change the form value to the parsed object
    // Please note that the below line will trigger isInvalid twice:
    // First, isInvalid(e.target.value)
    // Second time it is triggered as a resault of changing the form value
    onChange(isInvalid(e.target.value) ? 'invalid JSON' : JSON.parse(e.target.value || 'null'));
  }, []);

  return (
    <div>
      <div>Http Headers</div>
      <Input
        placeHolder="{''name'':''value''}"
        automationId="jiraHeader"
        isInvalid={meta.error}
        invalidMessage={meta.error}
        value={stringVal}
        onChange={handleChange}
      />
    </div>
  );
};

export default Headers;
