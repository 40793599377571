// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getUniqueId} from 'common/utils/guid';
import {
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
  fetchGoogleAdsAccounts as fetchGoogleAdsAccountsAction,
  setSelectedStreamUiKeyVal as setSelectedStreamUiKeyValAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import GadsDropdown from './GadsDropdown';
import GadsTemplates from './GadsTemplates';
import './GadsTemplate.module.scss';

type PropTypes = {
  isLoadingAccounts: boolean,
  accounts: Array,
  account: Object,

  dataStream: Object,

  fetchGoogleAdsAccounts: Function,
  setSelectedStreamKeyVal: Function,
  setSelectedStreamUiKeyVal: Function,
};

export default connect(
  (state) => ({
    isLoadingAccounts: selectors.getGoogleAdsAccounts(state).isLoading,
    accounts: selectors.getGoogleAdsAccountsItems(state),
    account: selectors.getGoogleAdsSelectedAccount(state),

    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    fetchGoogleAdsAccounts: fetchGoogleAdsAccountsAction,
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
    setSelectedStreamUiKeyVal: setSelectedStreamUiKeyValAction,
  },
)(
  class Accounts extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      this.props.fetchGoogleAdsAccounts(this.props.dataStream.dataSourceId);
      if (!this.props.isLoadingAccounts && !this.props.accounts.length) {
        // eslint-disable-next-line no-console
        console.info('no accounts');
      }
    }

    onChange = (val) => {
      const {dataStream} = this.props;

      const res = {
        clientCustomerId: val.clientCustomerId,
        timezone: val.timezone,
      };

      let columnsArr = [];
      let sourceColumnsArr = [];

      if (dataStream.schema && dataStream.schema.columns && dataStream.schema.columns.length) {
        columnsArr = dataStream.schema.columns.filter((col) => col.sourceColumn !== '_ACCOUNT_NAME_');
        sourceColumnsArr = dataStream.schema.sourceColumns.filter((col) => col.id !== '_ACCOUNT_NAME_');
      }

      if (val.clientCustomerId && val.clientCustomerId.length > 1) {
        columnsArr.push({
          id: getUniqueId(),
          sourceColumn: '_ACCOUNT_NAME_',
          name: 'Account Name',
          type: 'dimension',
        });
        sourceColumnsArr.push({
          id: '_ACCOUNT_NAME_',
          name: '_ACCOUNT_NAME_',
        });
      }

      res.schema = {
        columns: columnsArr,
        sourceColumns: sourceColumnsArr,
      };

      this.props.setSelectedStreamKeyVal(res);
    };

    getIsValidAccountsSelected = () => {
      const {dataStream, accounts} = this.props;
      let msg = null;
      let allEqual = true;

      if (dataStream && Array.isArray(dataStream.clientCustomerId) && dataStream.clientCustomerId.length && accounts) {
        const selectedItems = accounts.filter((item) => dataStream.clientCustomerId.includes(item.id.toString()));

        allEqual = selectedItems.length
          ? selectedItems.every((item) => item.timeZone === selectedItems[0].timeZone)
          : true;

        if (!allEqual) {
          msg = `When you add multiple Google Ads accounts, they must all share the same timezone.<br/>
          Try splitting the stream in order to use these different accounts.<br/>`;

          selectedItems.forEach((item) => {
            msg += `${item.name}  (${item.timeZone})<br/>`;
          });
        }
      }

      if (dataStream) {
        if (!dataStream.uiState || (dataStream.uiState && dataStream.uiState.timeZoneError !== !allEqual)) {
          this.props.setSelectedStreamUiKeyVal({timeZoneError: !allEqual});
        }
      }
      return msg;
    };

    render() {
      const msg = this.getIsValidAccountsSelected();

      return (
        <div className="shell-col" styleName="root">
          <PanelHeader title="Select Account" isEditable={isStreamEditable(this.props.dataStream.state)} />

          <GadsDropdown
            isLoading={this.props.isLoadingAccounts}
            items={this.props.accounts}
            selectedAccounts={this.props.account}
            disabled={!isStreamEditable(this.props.dataStream.state) && !isStreamRunning(this.props.dataStream.state)}
            onChange={this.onChange}
            ddlId="gads_accounts_ddl"
            ddlTitle="Choose account"
            placeholder="Choose an account"
          />

          <div styleName="input-description">
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{__html: msg}} />
          </div>

          <div styleName="error">
            {!this.props.isLoadingAccounts && !this.props.accounts.length ? (
              <div>
                Anodot could not access any Google Ads accounts with the credentials you provided in the data source. To
                complete the creation of this data stream, make sure your current Google account has access to run
                reports on the desired Google Ads account
              </div>
            ) : (
              ''
            )}
          </div>

          <GadsTemplates />
        </div>
      );
    }
  },
);
