// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import {getRecommendationsEnabled} from 'profile/store/selectors';
import RecommendationSign from 'common/componentsV2/RecommendationSign';

import './alertContent.module.scss';

const AlertRecommendation = ({
  hasRecommendations,
  alertConfigurationId,
}: {
  hasRecommendations: Boolean,
  alertConfigurationId: String,
}) => {
  const isRecommendationsEnabled = useSelector(getRecommendationsEnabled);

  const recommendationClicked = () => {
    window.open(`/#!/r/alert-manager/edit/${alertConfigurationId}/settings`, `_blank-new`);
  };

  return (
    <>
      {isRecommendationsEnabled ? (
        <RecommendationSign
          tooltipContent="Recommendations"
          hasRecommendations={hasRecommendations}
          recommendationClicked={() => recommendationClicked()}
        />
      ) : null}
    </>
  );
};

export default React.memo(AlertRecommendation);
