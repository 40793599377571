// @flow
import React from 'react';
import GaDataSection from 'bc/components/dataManager/viewStreamSummaryModalV2/byType/googleAnalytics/GaDataSection';
import FileData from 'bc/components/dataManager/viewStreamSummaryModalV2/byType/fileUpload/FileData';
import MParticleData from 'bc/components/dataManager/viewStreamSummaryModalV2/byType/mParticle/MParticleDataSection';
import GAdsDataSection from 'bc/components/dataManager/viewStreamSummaryModalV2/byType/googleAds/GoogleAdsDataSection';
import {KINESIS_RECORD_TYPES, KINESIS_RECORD_TYPES_DELIMITER} from 'bc/services/kinesisService';
import {bcTypes} from 'bc/services/bcTypes';
import {get} from 'lodash';
import './ViewStreamSummaryModalV2.module.scss';

const CollectionData = (props: {dataStream: Object, dataSource: Object}) => (
  <div styleName="section">
    <div styleName="vs-section-header">RECORDS COLLECTION DATA</div>
    {props.dataSource.dbName && (
      <div styleName="vs-row">
        <div styleName="vs-label">Database</div>
        <div styleName="vs-value">{props.dataSource.dbName}</div>
      </div>
    )}
    {props.dataStream.schemaName && (
      <div styleName="vs-row">
        <div styleName="vs-label">Schema</div>
        <div styleName="vs-value">{props.dataStream.schemaName}</div>
      </div>
    )}
    {props.dataStream.tableName && (
      <div styleName="vs-row">
        <div styleName="vs-label">Table</div>
        <div styleName="vs-value">{props.dataStream.tableName}</div>
      </div>
    )}
    {props.dataStream.missingDimPolicy && props.dataStream.missingDimPolicy.action === 'fill' && (
      <div styleName="vs-row">
        <div styleName="vs-label">Fill empty cells</div>
        <div styleName="vs-value">unknown</div>
      </div>
    )}
    {props.dataStream.timeDefinition && props.dataStream.timeDefinition.timeZone && (
      <div styleName="vs-row">
        <div styleName="vs-label">Record Time Zone</div>
        <div styleName="vs-value">{props.dataStream.timeDefinition.timeZone}</div>
      </div>
    )}
  </div>
);

const ContextData = (props: {dataStream: Object}) => (
  <div styleName="section">
    <div styleName="vs-section-header">CONTEXT DATA</div>
    <div styleName="vs-row">
      <div styleName="vs-label">Project Id</div>
      <div styleName="vs-value">{props.dataStream.projectId}</div>
    </div>
    {props.dataStream.defaultDataset && (
      <div styleName="vs-row">
        <div styleName="vs-label">Dataset</div>
        <div styleName="vs-value">{props.dataStream.defaultDataset}</div>
      </div>
    )}
    {props.dataStream.timeDefinition && props.dataStream.timeDefinition.timeZone && (
      <div styleName="vs-row">
        <div styleName="vs-label">Record Time Zone</div>
        <div styleName="vs-value">{props.dataStream.timeDefinition.timeZone}</div>
      </div>
    )}
  </div>
);

const S3CollectionData = (props: {dataStream: Object, dataSource: Object}) => {
  const path = get(props.dataSource, 'path', '') + (props.dataSource.path ? '/' : '') + props.dataStream.path;

  return (
    <div styleName="section">
      <div styleName="vs-section-header">FILE COLLECTION DATA</div>
      <div styleName="vs-row">
        <div styleName="vs-label">Bucket</div>
        <div styleName="vs-value">{props.dataSource.bucket}</div>
      </div>
      {path && (
        <div styleName="vs-row">
          <div styleName="vs-label">Path</div>
          <div styleName="vs-value">{path}</div>
        </div>
      )}
      {props.dataStream.fileNamePrefix && (
        <div styleName="vs-row">
          <div styleName="vs-label">File Prefix</div>
          <div styleName="vs-value">{props.dataStream.fileNamePrefix}</div>
        </div>
      )}
      {props.dataStream.fileNameSuffix && (
        <div styleName="vs-row">
          <div styleName="vs-label">File Suffix</div>
          <div styleName="vs-value">{props.dataStream.fileNameSuffix}</div>
        </div>
      )}
      <div styleName="vs-row">
        <div styleName="vs-label">Date Pattern</div>
        <div styleName="vs-value">{props.dataStream.fileNamePattern}</div>
      </div>
      {props.dataStream.timeDefinition && props.dataStream.timeDefinition.timeZone && (
        <div styleName="vs-row">
          <div styleName="vs-label">Record Time Zone</div>
          <div styleName="vs-value">{props.dataStream.timeDefinition.timeZone}</div>
        </div>
      )}
    </div>
  );
};

const GsCollectionData = (props: {dataStream: Object}) => (
  <div styleName="section">
    <div styleName="vs-section-header">FILE COLLECTION DATA</div>
    <div styleName="vs-row">
      <div styleName="vs-label">Project Id</div>
      <div styleName="vs-value">{props.dataStream.projectId}</div>
    </div>
    <div styleName="vs-row">
      <div styleName="vs-label">Bucket</div>
      <div styleName="vs-value">{props.dataStream.bucket}</div>
    </div>
    {props.dataStream.path && (
      <div styleName="vs-row">
        <div styleName="vs-label">Path</div>
        <div styleName="vs-value">{props.dataStream.path}</div>
      </div>
    )}
    {props.dataStream.fileNamePrefix && (
      <div styleName="vs-row">
        <div styleName="vs-label">File Prefix</div>
        <div styleName="vs-value">{props.dataStream.fileNamePrefix}</div>
      </div>
    )}
    {props.dataStream.fileNameSuffix && (
      <div styleName="vs-row">
        <div styleName="vs-label">File Suffix</div>
        <div styleName="vs-value">{props.dataStream.fileNameSuffix}</div>
      </div>
    )}
    <div styleName="vs-row">
      <div styleName="vs-label">Date Pattern</div>
      <div styleName="vs-value">{props.dataStream.fileNamePattern}</div>
    </div>
    {props.dataStream.timeDefinition && props.dataStream.timeDefinition.timeZone && (
      <div styleName="vs-row">
        <div styleName="vs-label">Record Time Zone</div>
        <div styleName="vs-value">{props.dataStream.timeDefinition.timeZone}</div>
      </div>
    )}
  </div>
);

const KinesisContextData = (props: {dataStream: Object}) => {
  const {dataStream} = props;
  const recordTypeFilter = dataStream.recordType;
  let recordType = KINESIS_RECORD_TYPES[0].name;
  let recordTypeDelimiter = '';

  if (
    recordTypeFilter &&
    recordTypeFilter !== KINESIS_RECORD_TYPES[0].name &&
    recordTypeFilter !== KINESIS_RECORD_TYPES[0].value
  ) {
    recordType = KINESIS_RECORD_TYPES[1].name;

    KINESIS_RECORD_TYPES_DELIMITER.find((item) => KINESIS_RECORD_TYPES[1].value + item.value === recordTypeDelimiter);

    KINESIS_RECORD_TYPES_DELIMITER.forEach((item) => {
      if (recordTypeFilter === KINESIS_RECORD_TYPES[1].value + item.value) {
        recordTypeDelimiter = item.name;
      }
    });
  }

  return (
    <div styleName="section">
      <div styleName="vs-section-header">CONTEXT FILTER</div>
      <div styleName="vs-row">
        <div styleName="vs-label">Record Type</div>
        <div styleName="vs-value">{recordType}</div>
      </div>
      {recordTypeDelimiter !== '' && (
        <div styleName="vs-row">
          <div styleName="vs-label">Delimiter</div>
          <div styleName="vs-value">{recordTypeDelimiter}</div>
        </div>
      )}
      <div styleName="vs-row">
        <div styleName="vs-label">Key</div>
        <div styleName="vs-value">{dataStream.filters[0].path}</div>
      </div>
      <div styleName="vs-row">
        <div styleName="vs-label">File Suffix</div>
        <div styleName="vs-value">{get(dataStream.filters[0], 'value', 'ANY')}</div>
      </div>
    </div>
  );
};

const ParquetCollectionData = (props: {dataStream: Object}) => (
  <div styleName="section">
    <div styleName="vs-section-header">FILE COLLECTION DATA</div>
    <div styleName="vs-row">
      <div styleName="vs-label">Root Path</div>
      <div styleName="vs-value">{props.dataStream.inputLocation}</div>
    </div>
    <div styleName="vs-row">
      <div styleName="vs-label">Pattern</div>
      <div styleName="vs-value">{props.dataStream.partitionFolderFormat}</div>
    </div>
    <div styleName="vs-row">
      <div styleName="vs-label">File Format</div>
      <div styleName="vs-value">{props.dataStream.inputFormat}</div>
    </div>
    <div styleName="vs-row">
      <div styleName="vs-label">File Compression</div>
      <div styleName="vs-value">{props.dataStream.compression}</div>
    </div>
  </div>
);

const CurCollectionData = (props: {dataStream: Object}) => (
  <div styleName="section">
    <div styleName="vs-section-header">FILE COLLECTION DATA</div>
    <div styleName="vs-row">
      <div styleName="vs-label">Reports Path</div>
      <div styleName="vs-value">{props.dataStream.path}</div>
    </div>
  </div>
);

const DataSection = (props: {dataStream: Object, dataSource: Object}) => {
  if (!props.dataStream || !props.dataSource) {
    return null;
  }

  switch (props.dataStream.type) {
    case bcTypes.google_analytics.type:
      return <GaDataSection dataStream={props.dataStream} />;
    case bcTypes.adobe.type:
      return null;
    case bcTypes.bigquery.type:
      return <ContextData dataStream={props.dataStream} />;
    case bcTypes.mysql.type:
    case bcTypes.psql.type:
    case bcTypes.mssql.type:
    case bcTypes.mariadb.type:
    case bcTypes.redshift.type:
    case bcTypes.snowflake.type:
    case bcTypes.oracle.type:
    case bcTypes.salesforce.type:
    case bcTypes.databricks.type:
    case bcTypes.teradata.type:
    case bcTypes.athena_sql.type:
    case bcTypes.pinot.type:
    case bcTypes.timestream.type:
      return <CollectionData dataStream={props.dataStream} dataSource={props.dataSource} />;
    case bcTypes.local_file.type:
      return <FileData dataStream={props.dataStream} />;
    case bcTypes.s3.type:
      return <S3CollectionData dataStream={props.dataStream} dataSource={props.dataSource} />;
    case bcTypes.google_storage.type:
      return <GsCollectionData dataStream={props.dataStream} />;
    case bcTypes.kinesis.type:
    case bcTypes.eventhubs.type:
      return <KinesisContextData dataStream={props.dataStream} />;
    case bcTypes.mparticle.type:
      return <MParticleData dataStream={props.dataStream} />;
    case bcTypes.athena.type:
      return <ParquetCollectionData dataStream={props.dataStream} />;
    case bcTypes.google_ads.type:
      return <GAdsDataSection dataStream={props.dataStream} />;
    case bcTypes.aws_cur.type:
      return <CurCollectionData dataStream={props.dataStream} />;
    default:
      return null;
  }
};

export default DataSection;
