import React, {memo, useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {ReactComponent as StatusIcon} from 'investigationNewAlertConsole/images/open_alert.svg';
import {ALERT_STATUS, modalRouting} from 'investigationNewAlertConsole/services/investigationService';
import IncidentDetails from 'investigationNewAlertConsole/components/incident/IncidentDetails';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';

import './TriageHeader.module.scss';

const TriageAlertHeader = ({alertsGroup, queryParams /* , setQueryParams */}: PropTypes) => {
  const [currentTrigger, setCurrentTrigger] = useState();
  const [currentTriggerIndex, setCurrentTriggerIndex] = useState(0);
  const [alertsGroupIds, setAlertsGroupIds] = useState([]);

  useEffect(() => {
    if (alertsGroup?.alerts?.length > 0) {
      const tmpTriggerIndex = alertsGroup.alerts.findIndex(
        (trigger) => trigger.alertTriggerId === queryParams?.[modalRouting.TRIGGER_ID],
      );
      if (tmpTriggerIndex !== -1) {
        setCurrentTriggerIndex(tmpTriggerIndex);
        setCurrentTrigger(alertsGroup.alerts[tmpTriggerIndex]);
      }

      const alertsIds = alertsGroup.alerts.map((alert) => ({value: alert.alertTriggerId, label: alert.title}));
      setAlertsGroupIds(alertsIds);
    }
  }, [JSON.stringify(alertsGroup?.alerts), queryParams?.[modalRouting.TRIGGER_ID]]);

  const onCurrentTriggerChanged = useCallback(
    (event) => {
      if (event?.value) {
        const tmpTriggerIndex = alertsGroup.alerts.findIndex((trigger) => trigger.alertTriggerId === event?.value);
        if (tmpTriggerIndex !== -1) {
          setCurrentTriggerIndex(tmpTriggerIndex);
          setCurrentTrigger(alertsGroup.alerts[tmpTriggerIndex]);
        }
      }
    },
    [JSON.stringify(alertsGroup?.alerts)],
  );

  const selectTriggerButton = (
    <div styleName="select-trigger">
      <div styleName="select-trigger-button">{`${currentTriggerIndex + 1}/${alertsGroup?.alerts?.length} Alerts`}</div>
      <i className="icon icn-arrow16-triangledown jss86" />
    </div>
  );

  return alertsGroup?.alerts?.length ? (
    <div styleName="triage-title-container">
      <div styleName="triage-title">
        {currentTrigger?.status === ALERT_STATUS.OPEN ? (
          <div styleName="status">
            <StatusIcon />
          </div>
        ) : null}
        <Tooltip placement="top" content={currentTrigger?.title} type={TYPES.SMALL} delay={50}>
          <div styleName="title" className="ellipsis">
            {currentTrigger?.title}
          </div>
        </Tooltip>
        {alertsGroup?.alerts.length > 1 ? (
          <div>
            <FormDdlSelect
              automationId="triage-select-alert-from-group"
              selected={{label: currentTrigger?.title, value: currentTrigger?.alertTriggerId}}
              position="right"
              placement="right"
              width={100}
              maxWidth={250}
              options={alertsGroupIds}
              button={selectTriggerButton}
              optionComponent={<OptionComponentSimple />}
              onChange={(event) => onCurrentTriggerChanged(event)}
            />
          </div>
        ) : null}
      </div>
      <IncidentDetails trigger={currentTrigger} />
    </div>
  ) : null;
};

TriageAlertHeader.propTypes = {
  alertsGroup: PropTypes.objectOf(PropTypes.any),
  queryParams: PropTypes.objectOf(PropTypes.any),
  // setQueryParams: PropTypes.func,
};

TriageAlertHeader.defaultProps = {
  queryParams: undefined,
  // setQueryParams: undefined,
  alertsGroup: undefined,
};

export default memo(TriageAlertHeader);
