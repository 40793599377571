// @flow
import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {success} from 'common/utils/notifications/notificationsService';
import useAsyncAction from 'common/utils/useAsyncAction';
import {getActiveChannels, getFetchChannels, getPostChannel} from 'alerts.channels/store/selectors';
import {fetchChannels, postChannel} from 'alerts.channels/store/actions';
import {useHistory, useLocation} from 'react-router-dom';
import Button, {
  COLORS as BUTTON_COLORS,
  HEIGHTS as BUTTON_HEIGHTS,
  WIDTHS as BUTTON_WIDTHS,
} from 'common/componentsV2/Button';
import Tooltip from 'common/componentsV2/Tooltip';
import SelectAndt, {
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {useDispatch, useSelector} from 'react-redux';
import {ENTITY_TYPE_VALUE, PARAMS_NAME} from 'admin.activityLog/services/activityLogService';
import {rangeTypes} from 'common/utils/dateRangeService';
import {getActivityLogEnabled, isCustomerAdmin as getIsCustomerAdmin} from 'profile/store/selectors';
import {keyBy} from 'lodash';
import channelTypes from 'channels/constants/channelTypes';

const useStyles = makeStyles(({palette}) => ({
  actionsContainer: {
    position: 'relative',
    top: '1px',
    display: 'flex',
    height: '49px',
    alignItems: 'center',
    background: `linear-gradient(to right, rgba(233, 240, 255, 0), ${palette.blue[100]} 30%)`,
    paddingLeft: '33px',
    paddingRight: '20px',
  },
  btn: {
    marginLeft: '10px',
    '&:first-child': {
      marginLeft: 0,
    },
  },
  buttonLikeDropdown: {
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: 400,
    height: '30px',
    width: '32px',
    padding: '0 8px',
    backgroundColor: palette.gray[300],
    position: 'relative',
    cursor: 'pointer',
    '&::before': {
      position: 'relative',
      top: '7px',
      width: '16px',
      height: '16px',
      fontSize: '16px',
    },
    '&:hover': {opacity: 0.9},
  },
}));

const channelsByValue = keyBy(channelTypes, 'value');

const ChannelItemActions = ({id}: {id: string}) => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const channelsData = useSelector(getActiveChannels);
  const isActivityLogEnabled = useSelector(getActivityLogEnabled);
  const isCustomerAdmin = useSelector(getIsCustomerAdmin);
  const postChannelAsync = useAsyncAction(postChannel, getPostChannel);
  const fetchChannelsAsync = useAsyncAction(fetchChannels, getFetchChannels);
  const onEditClick = () => history.push(`/channels/edit/${id}${location.search}`);
  const channel = channelsData.find((item) => item.id === id);
  const newChannelName = `${channel.name}-copy`;
  const channelType = channel.channelMeta.id;

  const dropDownOptions = useMemo(() => {
    const res = [];
    res.push({label: 'Duplicate', value: 'duplicate', disabled: ['jira', 'opsgenie'].includes(channelType)});
    res.push({label: 'Delete', value: 'delete'});
    if (isActivityLogEnabled && isCustomerAdmin) {
      res.push({label: 'View Activity Log', value: 'activity'});
    }
    return res;
  }, [isActivityLogEnabled, isCustomerAdmin, channelType]);

  const actionItemSelected = (eventKey) => {
    switch (eventKey.value) {
      case 'delete':
        history.push(`/channels/delete/${id}${location.search}`);
        break;
      case 'duplicate':
        postChannelAsync({channelType, body: {...channel, name: newChannelName}})
          .then(() => fetchChannelsAsync({refresh: true}))
          .then(() => {
            dispatch(
              success({
                settings: {
                  autoDismiss: 2.5,
                },
                title: `${channelsByValue[channelType].label} Channel Created Successfully`,
                description: `"${newChannelName}".\r\n You can connect alerts to your channel in Alert Management`,
              }),
            );
          });
        break;
      case 'activity':
        window.open(
          `/#!/r/admin/activity-log?${PARAMS_NAME.ENTITY_TYPE}=${ENTITY_TYPE_VALUE.CHANNELS}&${PARAMS_NAME.ENTITY_ID}=${id}&${PARAMS_NAME.CONST_RANGE}=${rangeTypes.m3.value}`,
        );
        break;
      default:
        break;
    }
    return null;
  };

  return (
    <div className={classes.actionsContainer}>
      <div className={classes.btn}>
        <Tooltip content="Edit" delay={300}>
          <Button
            colorSchema={BUTTON_COLORS.BLUE_500}
            text="Edit"
            onClick={onEditClick}
            height={BUTTON_HEIGHTS.TIGHT30}
            width={BUTTON_WIDTHS.REGULAR}
          />
        </Tooltip>
      </div>

      <div className={classes.btn}>
        <Tooltip content="More" delay={300}>
          <div>
            <SelectAndt
              options={dropDownOptions}
              type={TYPE_SIMPLE}
              theme={THEME_TRANSPARENT}
              menuWidth={150}
              onChange={actionItemSelected}
              direction={DIRECTION_LEFT}
              automationId="channelItem"
              offsetLeft={32}
              customComponent={{
                DropdownIndicator: (p) => (
                  <i {...p.innerProps} className={`${classes.buttonLikeDropdown} icon icn-general16-3dot`} />
                ),
              }}
            />
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default ChannelItemActions;
