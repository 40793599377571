// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal, setIsTimeZoneConfirmModalOpen} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import TimeZone from 'bc/components/streams/editor/common/TimeZone';
import SchedularDateRange from 'bc/components/streams/editor/common/SchedularDateRange';
import PollingInterval from 'bc/components/streams/editor/common/PollingInterval';
import {allowedPollingIntervalsByFileDatePattern} from 'bc/services/genericStorageService';
import './GoogleStorageSchedularEditor.module.scss';
import {getAllowedRangeTypesByPollingInterval} from 'bc/services/dataStreamService';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';

type PropTypes = {
  dataStream: Object,
  setSelectedStreamKeyVal: Function,
  setIsTimeZoneConfirmModalOpen: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamKeyVal,
    setIsTimeZoneConfirmModalOpen,
  },
)(
  class GoogleStorageSchedularEditor extends React.PureComponent {
    props: PropTypes;

    state = {
      isTimeZoneConfirmModalOpen: false,
      newTimeZoneObj: null,
    };

    closeAbortConfirmModal = () => {
      this.setState({isTimeZoneConfirmModalOpen: false});
      this.props.setSelectedStreamKeyVal({timeZone: this.props.dataStream.timeDefinition.timeZone});
    };

    onConfirmTimeZoneChange = () => {
      this.props.setSelectedStreamKeyVal(this.state.newTimeZoneObj);
      this.setState({isTimeZoneConfirmModalOpen: false});
    };

    openConfirmTimeZoneModal = () => {
      this.props.setIsTimeZoneConfirmModalOpen(true);
      this.setState({isTimeZoneConfirmModalOpen: true});
    };

    pollingChanged = (val) => {
      this.props.setSelectedStreamKeyVal({pollingInterval: val});
    };

    dateRangeChanged = (val) => {
      this.props.setSelectedStreamKeyVal({historicalDateRange: val});
    };

    timeZoneChanged = (obj) => {
      if (
        !this.props.dataStream.timeDefinition.timeZone ||
        this.props.dataStream.timeDefinition.timeZone === obj.timeZone
      ) {
        this.props.setSelectedStreamKeyVal(obj);
      } else {
        this.setState({newTimeZoneObj: obj});
        this.openConfirmTimeZoneModal();
      }
    };

    valueChanged = (e) => {
      const val = parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0;
      this.props.setSelectedStreamKeyVal({maxMissingFiles: val});
    };

    render() {
      const message =
        'You have chosen a Timezone different than the data records’ timezone. ' +
        'This could result in ignored rows in the streaming process. Press OK to confirm, or ' +
        'cancel to revert to the records’ timezone.';
      return (
        <div styleName="root" automation-id="gsSchedulerEditor">
          <PanelHeader title="Schedule File Collection" />
          <div styleName="inner-root">
            <div styleName="item" automation-id="gsSchedulerEditorItem">
              <span styleName="item-title">Collect Files Every</span>
              <span styleName="item-description">Set the collection interval.</span>
              <PollingInterval
                pollingInterval={this.props.dataStream.pollingInterval}
                allowedValues={allowedPollingIntervalsByFileDatePattern[this.props.dataStream.fileNamePattern]}
                styleName="item-width"
                onChange={this.pollingChanged}
              />
            </div>

            <div styleName="item" automation-id="gsSchedulerEditorItem">
              <span className="ellipsis" styleName="item-title">
                Ignore Files Older Than
              </span>
              <div styleName="item-width">
                <SchedularDateRange
                  dateRange={this.props.dataStream.historicalDateRange}
                  title="Time span"
                  isUnix
                  allowedRangeTypes={getAllowedRangeTypesByPollingInterval(this.props.dataStream.pollingInterval)}
                  onChange={this.dateRangeChanged}
                />
              </div>
            </div>
          </div>
          <div styleName="inner-root">
            <div styleName="item" automation-id="gsSchedulerEditorItem">
              <span className="ellipsis" styleName="item-title">
                Files Time Zone
              </span>
              <span styleName="item-description">Process records according to this time zone.</span>
              <TimeZone
                timeZone={this.props.dataStream.timeZone}
                extraClassName="blue-style"
                styleName="item-width"
                onChange={this.timeZoneChanged}
              />
            </div>

            <div styleName="item collect-on">
              <span className="ellipsis" styleName="item-title">
                Lagging Files Policy
              </span>
              <span styleName="item-description">
                Files might arrive late.
                <br />
                Set the number of intervals to wait for lagging files.
              </span>
              <input type="number" onChange={this.valueChanged} min="0" value={this.props.dataStream.maxMissingFiles} />
            </div>
          </div>

          {this.state.isTimeZoneConfirmModalOpen && (
            <ConfirmationModal
              onClose={this.closeAbortConfirmModal}
              onConfirm={this.onConfirmTimeZoneChange}
              title="Timezone Changed"
              message={message}
              isOpen={this.state.isTimeZoneConfirmModalOpen}
              buttons={['Cancel', 'OK']}
            />
          )}
        </div>
      );
    }
  },
);
