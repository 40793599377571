// @flow
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchChannels} from 'alerts.channels/store/actions';
import * as channelsSelectors from 'alerts.channels/store/selectors';
import {fetchUsers} from 'admin.users/store/actions';
import * as usersSelectors from 'admin.users/store/selectors';

import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import {StringParam, useQueryParam} from 'use-query-params';

const ChannelsFilter = () => {
  const dispatch = useDispatch();
  const isLoadingChannels = useSelector(channelsSelectors.getChannelsLoading);
  const isLoadingUsers = useSelector(usersSelectors.getUsersIsLoading);
  const sortedFlattenChannels = useSelector(channelsSelectors.getSortedFlattenChannels);
  const selectedItems = useSelector(channelsSelectors.getSelectedItems);
  const [, setSubscribersQs] = useQueryParam('subscribers', StringParam);
  const [, setChannelsQs] = useQueryParam('channels', StringParam);

  useEffect(() => {
    dispatch(fetchChannels());
    dispatch(fetchUsers());
  }, []);

  const onChange = useCallback(
    (values) => {
      const subscribers = [];
      const channels = [];

      values.forEach((i) => {
        if (i.type === 'USERS') {
          subscribers.push(i.value);
        } else {
          channels.push(i.value);
        }
      });
      setSubscribersQs(subscribers.length ? subscribers.map((i) => i).join(',') : undefined);
      setChannelsQs(channels.length ? channels.map((i) => i).join(',') : undefined);
    },
    [setSubscribersQs, setChannelsQs],
  );

  const onClearAll = useCallback(() => {
    if (selectedItems && selectedItems.length > 0) {
      setSubscribersQs(undefined);
      setChannelsQs(undefined);
    } else {
      const subscribers = [];
      const channels = [];
      sortedFlattenChannels.forEach((i) => {
        if (i.type === 'USERS') {
          subscribers.push(i.value);
        } else {
          channels.push(i.value);
        }
      });
      setSubscribersQs(subscribers.length ? subscribers.map((i) => i).join(',') : undefined);
      setChannelsQs(channels.length ? channels.map((i) => i).join(',') : undefined);
    }
  }, [selectedItems, sortedFlattenChannels, setSubscribersQs, setChannelsQs]);

  return (
    <UsersChannelsFilter
      placeholder={isLoadingChannels || isLoadingUsers ? 'Loading...' : 'Select'}
      onChange={onChange}
      sortedFlattenChannels={sortedFlattenChannels}
      value={selectedItems}
      automationId="channelsFilter"
      isFullWidthButton
      footerComponent={
        <FormDdlActionBar
          isVisible
          selectedCount={selectedItems && selectedItems.length ? selectedItems.length : 0}
          onClearAll={onClearAll}
        />
      }
    />
  );
};
export default ChannelsFilter;
