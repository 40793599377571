import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as timeSeriesChartActions from 'charts/timeSeries/store/actions';
import {getUniqueId} from 'common/utils/guid';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

export const createAlertsReducer = (action) =>
  makeAsyncReducer(action, {
    shouldSpread: true,
    defaultData: {alertGroups: EMPTY_ARRAY},
    dataProp: 'alertGroups',
  });

export const createMetricsReducer = (stateGroup) =>
  composeReducers((state = {}, {type, payload, meta}) => {
    if (!meta || meta.stateGroup !== stateGroup) {
      return state;
    }
    const defaults = !state[meta.key] ? {hiddenSeries: []} : {};

    switch (type) {
      case actions.fetchAlertMetricDataPoints.TYPE: {
        return {
          ...state,
          [meta.key]: {
            ...state[meta.key],
            ...defaults,
            isLoading: true,
            startDate: payload.startDate,
            endDate: payload.endDate,
            seriesProperties: {byTreeExp: []},
          },
        };
      }

      case actions.fetchAlertMetricDataPoints.success.TYPE: {
        return {...state, [meta.key]: {...state[meta.key], isLoading: false, data: payload}};
      }

      case actions.fetchAlertMetricDataPoints.failure.TYPE: {
        return {...state, [meta.key]: {...state[meta.key], isLoading: false, error: payload}};
      }

      case timeSeriesChartActions.dateRangeChanged.TYPE: {
        return {...state, [meta.key]: {...state[meta.key], zoomRange: payload}};
      }

      case timeSeriesChartActions.resetZoom.TYPE: {
        return {...state, [meta.key]: {...state[meta.key], zoomRange: null}};
      }

      default:
        return state;
    }
  });

export const createEventsReducer = (stateGroup) =>
  composeReducers((state = {}, {type, payload, meta}) => {
    if (!meta || meta.stateGroup !== stateGroup) {
      return state;
    }

    switch (type) {
      case actions.fetchAlertEvents.TYPE: {
        return {
          ...state,
          [meta.key]: {
            ...state[meta.key],
            isLoading: true,
            startDate: payload.startDate,
            endDate: payload.endDate,
          },
        };
      }

      case actions.fetchAlertEvents.success.TYPE: {
        let {events} = payload;
        if (events) {
          events = events.filter((item) => item.topEvents?.length).map((item) => ({...item, id: getUniqueId()}));
        }
        return {
          ...state,
          [meta.key]: {...state[meta.key], isLoading: false, data: {...payload, events}},
        };
      }

      case actions.fetchAlertEvents.failure.TYPE: {
        return {...state, [meta.key]: {...state[meta.key], isLoading: false, error: payload}};
      }

      default:
        return state;
    }
  });

export const createMultipleEventsReducer = (stateGroup) =>
  composeReducers((state = {}, {type, payload, meta}) => {
    if (!meta || meta.stateGroup !== stateGroup) {
      return state;
    }

    switch (type) {
      case actions.fetchAlertMultipleEvents.TYPE: {
        return {
          ...state,
          [meta.key]: {
            ...state[meta.key],
            isLoading: true,
            startDate: payload.startDate,
            endDate: payload.endDate,
          },
        };
      }

      case actions.fetchAlertMultipleEvents.success.TYPE: {
        if (!Array.isArray(payload)) {
          return state;
        }

        // TODO: Need to add handling for errors returned from server (AL-11691).
        // TODO: The multiple events api is using Promise.all and returns array of promises resolutions.

        let events = payload
          .filter((ev) => !ev?.HttpError)
          .map((arr) => arr?.events)
          .flat();
        if (events) {
          events = events.filter((item) => item.topEvents?.length).map((item) => ({...item, id: getUniqueId()}));
        }
        return {
          ...state,
          [meta.key]: {...state[meta.key], isLoading: false, data: {...payload, events}}, // Should I use multiple events objectName in the store?
        };
      }

      case actions.fetchAlertMultipleEvents.failure.TYPE: {
        return {...state, [meta.key]: {...state[meta.key], isLoading: false, error: payload}};
      }

      default:
        return state;
    }
  });
