// @flow
import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {
  predefinedPresets,
  businessPresets,
  createCustomLabel,
  createRelativeLabel,
  DEFAULT_RANGE_OPTIONS,
} from 'common/utils/dateRangeService';
import {getPredefinedDateUserSetting, userSettingIsLoading} from 'profile/store/selectors';
import AsyncButton from 'common/componentsV2/AsyncButton';
import {updatePredefinedDatesUserSettings} from 'common/components/dateTime/store/actions';
import isEqual from 'lodash/isEqual';
import Checkbox from '../Checkbox';
import Button, {COLORS, WIDTHS} from '../Button';

type PropsType = {
  data: Object,
  isSelected: boolean,
  isIndividual: boolean,
  list: Array,
  onChange: Function,
};

const useStyles = makeStyles(({palette}) => ({
  layout: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  titleLine: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 300,
    fontSize: 22,
    lineHeight: '26px',
    paddingLeft: '8px',
  },
  backBtn: {
    cursor: 'pointer',
    borderRadius: '1px',
    padding: '0 4px',
  },
  description: {
    paddingTop: 16,
    fontWeight: 400,
    color: palette.gray[400],
  },
  presetsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 24,
    flexGrow: 1,
  },
  presetsHeaders: {
    display: 'flex',
    color: palette.gray[400],
    fontWeight: 500,
    paddingBottom: 12,
  },
  presetsList: {
    display: 'flex',
  },
  presetColumn: {
    width: '30%',
    overflow: 'auto',
    marginRight: '3%',
  },
  footer: {
    justifySelf: 'flex-end',
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: 12,
    paddingRight: 16,
    cursor: 'default',
    '&:hover $iconClose': {
      visibility: 'visible',
    },
    "& input[type='checkbox']:checked + label::before": {
      color: palette.gray[500],
    },
  },
  iconClose: {
    cursor: 'pointer',
    visibility: 'hidden',
    marginLeft: 8,
    marginBottom: -4,
    fontSize: 16,
  },
}));

const Option = ({data, isSelected, isIndividual = false, list, onChange}: PropsType) => {
  const classes = useStyles();

  const handleSelect = () => {
    if (isIndividual) {
      onChange(
        list.map((d) => {
          if (d.id === data.id) {
            return {
              ...data,
              isSelected: !data.isSelected,
            };
          }
          return d;
        }),
      );
    } else if (isSelected) {
      onChange(list.filter((d) => d !== data.key));
    } else {
      onChange([...list, data.key]);
    }
  };

  const handleDelete = () => {
    onChange(list.filter((d) => d.id !== data.id));
  };

  const title =
    data.text ||
    (data.startDate && createCustomLabel(data.startDate, data.endDate)) ||
    (data.relativeLast && createRelativeLabel(data.relativeLast, data.relativeNext));

  return (
    <div className={classes.option}>
      <Checkbox text={title} onChange={handleSelect} isChecked={isSelected} />
      {isIndividual && (
        <div className={classes.iconClose} onClick={handleDelete}>
          <i className="icon icn-general16-closea" />
        </div>
      )}
    </div>
  );
};

const TimePreset = ({showDateRange}: {showDateRange: Function}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dataPredefinedDatesUser = useSelector(getPredefinedDateUserSetting);
  const isLoadingUserSetting = useSelector(userSettingIsLoading);
  const [predefinedDateState, setPredefinedDateState] = useState(
    dataPredefinedDatesUser.preDefinedDates || DEFAULT_RANGE_OPTIONS,
  );
  const [customDateState, setCustomDateState] = useState(dataPredefinedDatesUser.customDates || []);

  useEffect(() => {
    setPredefinedDateState(dataPredefinedDatesUser.preDefinedDates || DEFAULT_RANGE_OPTIONS);
    setCustomDateState(dataPredefinedDatesUser.customDates);
  }, [dataPredefinedDatesUser]);

  const handleSave = useCallback(() => {
    dispatch(
      updatePredefinedDatesUserSettings({
        preDefinedDates: predefinedDateState,
        customDates: customDateState,
      }),
    );
  }, [predefinedDateState, customDateState]);
  const handleRestore = useCallback(() => {
    setPredefinedDateState(DEFAULT_RANGE_OPTIONS);
  }, []);
  const isDirty = useMemo(() => {
    return (
      !isEqual(predefinedDateState, dataPredefinedDatesUser.preDefinedDates || DEFAULT_RANGE_OPTIONS) ||
      !isEqual(customDateState, dataPredefinedDatesUser.customDates || [])
    );
  }, [predefinedDateState, dataPredefinedDatesUser, customDateState, dataPredefinedDatesUser]);

  return (
    <div className={classes.layout}>
      <div className={classes.titleLine}>
        <div className={classes.backBtn} onClick={showDateRange}>
          <i className="icon ion-chevron-left" />
        </div>
        <div className={classes.title}>Manage Presets</div>
      </div>
      <div className={classes.description}>These will show up in the time frame dropdown in dashboards</div>
      <div className={classes.presetsContainer}>
        <div className={classes.presetsHeaders}>
          <div style={{width: '33%'}}>Anodot Presets</div>
          <div style={{width: '33%'}}>Business Presets</div>
          <div style={{width: '33%'}}>Your Presets</div>
        </div>
        <div className={classes.presetsList}>
          <div className={classes.presetColumn}>
            {predefinedPresets.map((o) => (
              <Option
                key={o.key}
                data={o}
                list={predefinedDateState}
                isSelected={predefinedDateState.includes(o.key)}
                onChange={setPredefinedDateState}
              />
            ))}
          </div>
          <div style={{width: '33%'}}>
            {businessPresets.map((o) => (
              <Option
                key={o.key}
                data={o}
                list={predefinedDateState}
                isSelected={predefinedDateState.includes(o.key)}
                onChange={setPredefinedDateState}
              />
            ))}
          </div>
          <div style={{width: '33%'}}>
            {customDateState.map((o) => (
              <Option
                key={o.id}
                data={o}
                isSelected={o.isSelected}
                isIndividual
                list={customDateState}
                onChange={setCustomDateState}
              />
            ))}
            <Button
              automationId="newPreset"
              colorSchema={COLORS.TRANSPARENT_BLUE}
              text="New Preset"
              onClick={showDateRange}
              icon="icn-action16-plusb"
              width={WIDTHS.NO_PADDING}
            />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Button
          automationId="restoreDefaults"
          colorSchema={COLORS.TRANSPARENT_BLUE}
          text="Restore Defaults"
          onClick={handleRestore}
          width={WIDTHS.NO_PADDING}
        />
        <AsyncButton
          colorSchema="blue.500"
          type="submit"
          onClick={handleSave}
          text="Save"
          isLoading={isLoadingUserSetting}
          isDisabled={!isDirty}
        />
      </div>
    </div>
  );
};

export default TimePreset;
