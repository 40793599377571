import React, {useEffect, useState} from 'react';
import {GenerateIcon, ICONS, SIZES} from '@pileus-cloud/anodot-frontend-common';

import classes from './AlertRecommendationsMenu.module.scss';

const AlertRecommendationsMenuButton = ({
  alertRecommendations,
  isMenuOpen,
}: {
  alertRecommendations: Array,
  isMenuOpen: Boolean,
}) => {
  const [unreadRecommendationsCounter, setUnreadRecommendationsCounter] = useState();

  useEffect(() => {
    if (alertRecommendations?.length) {
      const unreadRecommendations = alertRecommendations?.filter(
        (recommendation) => recommendation.applied !== true && recommendation.ignored !== true, // TODO: ask about the new property: recommendation.new === true
      );
      setUnreadRecommendationsCounter(unreadRecommendations?.length || 0);
    }
  }, [alertRecommendations]);

  return (
    <div className={classes.recommendationsButton}>
      <div className={`${classes.recommendationIndicator} ${isMenuOpen ? classes.isMenuOpen : ''}`}>
        <GenerateIcon iconName={ICONS.recommendations.name} size={SIZES.xl} />
      </div>
      <div
        className={`${classes.unreadRecommendationsIndicator} ${
          !unreadRecommendationsCounter || isMenuOpen ? classes.recommendationsIndicatorHidden : ''
        }`}
      />
    </div>
  );
};

export default AlertRecommendationsMenuButton;
