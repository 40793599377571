// @flow
import React from 'react';
import {connect} from 'react-redux';
import SmartTooltip from 'common/components/SmartTooltip';
import {durationScales} from 'metrics/services/metricsService';
import {alertTypes} from 'alerts.management/services/alertsService';
import {broadcast} from 'common/utils/angularServices';
import * as selectors from 'alerts.management/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import * as metricSelectors from 'metrics/store/selectors';
import * as actions from 'alerts.management/store/actions';
import TooltipArea from 'common/componentsV2/TooltipArea';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import InputAuto from '../simulationArea/utils/InputAuto';

type PropTypes = {
  noDataAlert: boolean,
  noDataDuration: Object,
  alertType: string,
  onChange: Function,
  onEnabledChange: Function,
  isComposite: boolean,
  noDataMinDuration: number,
  noDataMinCompositeDuration: number,
};

class NoDataSelector extends React.PureComponent {
  props: PropTypes;

  state = {noDataTooltip: '', noDataError: false};

  componentDidUpdate(prevProps) {
    if (
      (this.props.noDataDuration.scale !== prevProps.noDataDuration.scale ||
        this.props.isComposite !== prevProps.isComposite) &&
      this.formatDuration() < this.calculateMinDuration()
    ) {
      this.handleValueChange(this.calculateMinDuration());
    }
  }

  calculateMinDuration = () => {
    const {noDataDuration, noDataMinDuration, noDataMinCompositeDuration, isComposite} = this.props;
    const noDataMinDurationComputed = isComposite ? noDataMinCompositeDuration : noDataMinDuration;
    return Math.ceil(noDataMinDurationComputed / durationScales[noDataDuration.scale].seconds);
  };

  formatDuration = () => {
    const {noDataDuration} = this.props;
    // eslint-disable-next-line radix
    return parseInt(noDataDuration.value / durationScales[noDataDuration.scale].seconds);
  };

  validateEventName = 'e:andt.metrics.alerts.validateNoDataDuration';

  handleValueChange = (val) => {
    const {noDataDuration} = this.props;
    const value = val * durationScales[noDataDuration.scale].seconds;
    this.handleChange(value, noDataDuration.scale);
  };

  handleScaleChange = (scale) => {
    const {noDataDuration} = this.props;
    const value = (noDataDuration.value / durationScales[noDataDuration.scale].seconds) * durationScales[scale].seconds;
    this.handleChange(value, scale);
  };

  handleChange = (value, scale, isEnabled) => {
    const data = {
      value,
      validationObject: {
        valid: true,
        validationMessage: '',
      },
      isEnabled:
        isEnabled !== undefined
          ? isEnabled
          : this.props.noDataAlert || this.props.alertType === alertTypes.noData.value,
    };
    broadcast(this.validateEventName, data);
    this.setState({noDataTooltip: data.validationObject.validationMessage, noDataError: !data.validationObject.valid});
    this.props.onChange({value, scale});
  };

  handleYesNoClicked = (val) => {
    this.props.onEnabledChange(val);
    this.handleChange(this.props.noDataDuration.value, this.props.noDataDuration.scale, val);
  };

  render() {
    return (
      <TooltipArea
        isAlwaysVisible
        text="Choose to be alerted when metrics from this alert are no longer being streamed into Anodot"
        automation-id="noDataConditionContainer"
      >
        {() => (
          <div style={{paddingTop: 8, paddingBottom: 8}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div>Data is missing</div>
              {this.props.alertType === alertTypes.noData.value ? (
                <i
                  className="icon icn-general16-checkmark fontSize_16 color_blue-500"
                  style={{width: 32, textAlign: 'center'}}
                />
              ) : (
                <ToggleSwitch
                  name="notification-option-noData"
                  theme="blue"
                  isChecked={this.props.noDataAlert || this.props.alertType === alertTypes.noData.value}
                  disabled={this.props.alertType === alertTypes.noData.value}
                  onToggle={() => this.handleYesNoClicked(!this.props.noDataAlert)}
                />
              )}
            </div>
            {(this.props.noDataAlert || this.props.alertType === alertTypes.noData.value) && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{marginRight: 8}}>For At Least:</div>
                <SmartTooltip content={this.state.noDataError ? this.state.noDataTooltip : null}>
                  <div style={{width: 30, height: 24, marginRight: 8}}>
                    <InputAuto
                      style={{padding: 0, textAlign: 'center'}}
                      fullSize
                      delay={1000}
                      type="number"
                      additionalProps={{
                        min: this.calculateMinDuration(),
                      }}
                      automationId="alertSettingsNoDataTextbox"
                      className={`dark-input ${this.state.noDataError ? 'invalid' : ''}`}
                      value={this.formatDuration()}
                      onChange={(e) => this.handleValueChange(e.target.value)}
                    />
                  </div>
                </SmartTooltip>
                <NoDataSelectorScale scale={this.props.noDataDuration.scale} onChange={this.handleScaleChange} />
              </div>
            )}
          </div>
        )}
      </TooltipArea>
    );
  }
}

const durationTypes = [
  {
    label: 'Minutes',
    value: 'minutes',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Weeks',
    value: 'weeks',
  },
];

// eslint-disable-next-line react/no-multi-comp
const NoDataSelectorScale = React.memo((props: {scale: Object, onChange: Function}) => (
  <div style={{width: 144, height: 24}}>
    <FormDdlSelect
      button={
        <BigDropdownButton
          isPristine={!durationTypes.find((item) => item.value === props.scale)}
          placeholder="All"
          label={durationTypes.find((item) => item.value === props.scale)?.label}
          blueLean
        />
      }
      options={durationTypes}
      optionComponent={<OptionComponentSimple />}
      onChange={(item) => props.onChange(item.value)}
      width={100}
      maxWidth={100}
      buttonWidth={100}
      id="durationScaleFilter"
      automationId="durationScaleFilter"
    />
  </div>
));

export default connect(
  (state) => {
    const {noDataAlert, noDataDuration, type} = selectors.getSelectedAlertSimulationFilters(state);
    return {
      noDataAlert,
      noDataDuration,
      alertType: type.value,
      noDataMinDuration: profileSelectors.getOrganizationNoDataMinDuration(state),
      noDataMinCompositeDuration: profileSelectors.getOrganizationNoDataMinCompositeDuration(state),
      isComposite: !metricSelectors.getIsEmptyFunction(state),
    };
  },
  {
    onChange: actions.setSelectedAlertNoDataDuration,
    onEnabledChange: actions.setSelectedAlertNoDataEnabled,
  },
)(NoDataSelector);
