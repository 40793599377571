import React, {useMemo} from 'react';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {useDispatch, useSelector} from 'react-redux';
import {getFormattedDateYMD} from 'common/utils/dateService';
import {MORE_ACTIONS_OPTIONS} from 'investigationNewAlertConsole/services/investigationService';
import {getTimeZoneName} from 'profile/store/selectors';
import {ALERT_TYPES} from 'alerts.console.new/services/alertsConsoleService';
import {getMetricResolutions} from 'metrics/store/selectors';
import {
  modalRouting1 as impactRouting1,
  modalRouting2 as impactRouting2,
} from 'impactNewAlertConsole/services/constants';
import {copyToClipBoard, segmentClickEvent} from 'common/store/actions';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';

import classes from './TriageHeader.module.scss';

const TriageMoreActions = ({trigger, timeline}: {trigger: Object, timeline: Object}) => {
  const dispatch = useDispatch();
  const timeZoneName = useSelector(getTimeZoneName);
  const resolutions = useSelector(getMetricResolutions);

  const timeScale = Object.values(resolutions)?.find((a) => a.value2 === trigger?.timeScale)?.value;

  const ORIGIN_SOURCE_IDS_TYPE = 'STREAM';

  const [, setQueryParams] = useQueryParams({
    [impactRouting1.IMPACT_MODAL]: BooleanParam,
    [impactRouting1.ORIGIN_ID]: StringParam,
    [impactRouting1.ORIGIN_TYPE]: StringParam,
    [impactRouting1.ALERT_TRIGGER_ID]: StringParam,
    [impactRouting1.MEASURE]: StringParam,
    [impactRouting2.IMPACT_ID]: StringParam,
  });

  const moreActions = useMemo(() => {
    let tmpActions = Object.values(MORE_ACTIONS_OPTIONS);
    if (trigger.type === ALERT_TYPES.NO_DATA || trigger.type === ALERT_TYPES.STATIC) {
      tmpActions = tmpActions.filter((option) => option.label !== MORE_ACTIONS_OPTIONS.INVESTIGATE_ANOMALY.label);
    }
    if (!trigger?.metrics[0]?.originSourceIds) {
      tmpActions = tmpActions.filter((option) => option.label !== MORE_ACTIONS_OPTIONS.EDIT_IMPACT.label);
    }
    return tmpActions;
  }, []);

  const exportTimelineToCsv = () => {
    const rows = [['timestamp', 'Event Type', 'Description', 'User']];
    if (timeline) {
      timeline.forEach((ele) => {
        const formattedDate = getFormattedDateYMD(ele.dataTime, timeZoneName);
        const name = ele.userFullName || (ele.userName ? ele.userName : 'Anonymous');
        const newRow = [formattedDate.replaceAll(',', '-'), ele.type, ele.title?.replaceAll(',', ' '), name];
        rows.push(newRow);
      });
    }
    const csvContent = `data:text/csv;charset=utf-8,${rows.map((e) => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  const setImpactUrl = async () => {
    setQueryParams({
      [impactRouting1.IMPACT_MODAL]: true,
      [impactRouting1.ALERT_TRIGGER_ID]: trigger?.alertTriggerId,
      [impactRouting1.ORIGIN_ID]: Array.isArray(trigger?.metrics[0]?.originSourceIds)
        ? trigger?.metrics[0]?.originSourceIds[0]
        : undefined,
      [impactRouting1.ORIGIN_TYPE]: Array.isArray(trigger?.metrics[0]?.originSourceIds)
        ? ORIGIN_SOURCE_IDS_TYPE
        : undefined,
      [impactRouting1.MEASURE]: !trigger?.impact ? trigger?.metrics[0]?.what : undefined,
      [impactRouting2.IMPACT_ID]: trigger?.impact?.impactId || undefined,
    });
  };

  const onShare = () => {
    const text = `${window.location.href.split('?')[0]}?anomalyIdInv=${trigger?.anomalyId}&triggerIdInv=${
      trigger?.alertTriggerId
    }&investigationModal=1`;
    dispatch(
      copyToClipBoard({
        event: {},
        text,
        description:
          'You can send this link to anyone who has an Anodot account with the sufficient permission to view this data',
        title: 'Link to this investigation copied',
      }),
    );
  };

  const actionItemSelected = async (event) => {
    if (event.value) {
      dispatch(
        segmentClickEvent({
          type: 'click',
          name: event.value,
        }),
      );

      switch (event.value) {
        case MORE_ACTIONS_OPTIONS.EDIT_ALERT.value:
          window.open(
            `/#!/r/alert-manager/edit/${trigger?.alertConfigurationId}/settings`,
            `_blank-${trigger?.alertConfigurationId}`,
          );
          break;
        case MORE_ACTIONS_OPTIONS.EXPORT_TIMELINE.value:
          exportTimelineToCsv();
          break;
        case MORE_ACTIONS_OPTIONS.INVESTIGATE_ANOMALY.value:
          window.open(
            `/#!/anomalies?tabs=main;0&activeTab=1&anomalies=;0(${trigger?.anomalyId})&duration=;1(1)&durationScale=;minutes(minutes)&delta=;1(1)&deltaType=;percentage(percentage)&resolution=;${timeScale}(${timeScale})&score=;0(0)&state=;both(both)&direction=;both(both)&alertId=;(${trigger?.alertTriggerId})&sort=;significance(significance)&q=;()&constRange=;1h(c)&startDate=;0(0)&endDate=;0(0)`,
            '_newtab',
          );
          break;
        case MORE_ACTIONS_OPTIONS.EDIT_IMPACT.value:
          await setImpactUrl();
          break;
        case MORE_ACTIONS_OPTIONS.SHARE.value:
          onShare();
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={classes.moreActions}>
      <FormDdlSelect
        automationId="inv-header-more-actions"
        position="right"
        width={135}
        options={moreActions}
        button={<div className="icon icn-icon-3-dots" />}
        optionComponent={<OptionComponentSimple />}
        onChange={(event) => actionItemSelected(event)}
      />
    </div>
  );
};

export default TriageMoreActions;
