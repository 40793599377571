// @flow

import React from 'react';
import {components} from 'react-select2';
import SelectAndt, {
  TYPE_SIMPLE,
  THEME_TRANSPARENT,
  DIRECTION_CENTER,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import './AlertManagerFooterPause.module.scss';
import {segmentClickEvent} from 'common/store/actions';
import connect from 'react-redux/es/connect/connect';

type PropTypes = {
  onApply: Function,
  segmentClickEvent: Function,
};

export default connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)(
  class AlertManagerFooterPause extends React.PureComponent {
    props: PropTypes;

    state = {
      menuIsOpen: false,
    };

    handlePauseChange = (val) => {
      this.props.segmentClickEvent({category: 'alert-manager', name: 'bulk-pause_alerts'});
      this.props.onApply(val);
    };

    pauseOptions = [
      {value: 'hour', label: 'Pause for an hour'},
      {value: 'day', label: 'Pause for a day'},
      {value: 'week', label: 'Pause for a week'},
      {value: 'indefinitely', label: 'Pause indefinitely'},
    ];

    onMenuOpen = () => {
      this.setState({menuIsOpen: true});
    };

    onMenuClose = () => {
      this.setState({menuIsOpen: false});
    };

    render() {
      return (
        <SelectAndt
          type={TYPE_SIMPLE}
          theme={THEME_TRANSPARENT}
          onChange={this.handlePauseChange}
          options={this.pauseOptions}
          direction={DIRECTION_CENTER}
          menuWidth={200}
          automationId="bulkPause"
          menuPlacement="top"
          onMenuOpen={this.onMenuOpen}
          onMenuClose={this.onMenuClose}
          menuIsOpen={this.state.menuIsOpen}
          customComponent={{
            DropdownIndicator: (p) => (
              <div {...p.innerProps} styleName="footer-button-like">
                <i className="icn-action16-pause" />
                <span>Pause</span>
              </div>
            ),
            IndicatorsContainer: (p) => (
              <div style={{height: '38px', display: 'flex'}}>
                <components.IndicatorsContainer {...p}>{p.children}</components.IndicatorsContainer>
              </div>
            ),
          }}
        />
      );
    }
  },
);
