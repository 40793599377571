// @flow
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import Box from '@material-ui/core/Box';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import TinyScrollBox from 'common/componentsV2/boxTools/TinyScrollBox';
import {
  getIssuesTypeOptions,
  getSelectedIssuesTypeOption,
  getTMLeftPanelIssuesList,
  getTMFetchTopologyIssuesIsLoading,
  getTMLeftPanelSelectedIssueId,
} from 'topologyLeaflet/store/selectors';
import {getTimeZoneName} from 'profile/store/selectors';
import {segmentClickEvent as segmentTrackingMouseEventAction} from 'common/store/actions';
import {PANEL_SIZES} from 'topologyLeaflet/services/leftPanelService';
import IssuesList from 'topologyLeaflet/components/leftPanel/IssuesList';
import IssueDateRange from 'topologyLeaflet/components/leftPanel/IssueDateRange';
import './LeftPanel.module.scss';

type PropTypes = {
  onIssueItemClick?: Function,
  setQueryParams: Function,

  // connect
  timeZoneName: String,
  selectedIssueId: String,
  issues: Array,
  issuesIsLoading: boolean,
  issuesOptions: Array,
  selectedIssueOption: Object,
  segmentClickEvent: Function,
};

export default connect(
  (state) => ({
    timeZoneName: getTimeZoneName(state),
    selectedIssueId: getTMLeftPanelSelectedIssueId(state),
    issues: getTMLeftPanelIssuesList(state),
    issuesIsLoading: getTMFetchTopologyIssuesIsLoading(state),
    issuesOptions: getIssuesTypeOptions(state),
    selectedIssueOption: getSelectedIssuesTypeOption(state),
  }),
  {
    segmentClickEvent: segmentTrackingMouseEventAction,
  },
)(
  class LeftPanel extends PureComponent {
    props: PropTypes;

    static defaultProps = {
      onIssueItemClick: null,
    };

    onChange = (item) => {
      const {selectedIssueOption, segmentClickEvent} = this.props;

      if (selectedIssueOption.value !== item.value && item.value === 'anomalies') {
        segmentClickEvent({category: 'topology/dropdown', name: 'Drop down Anomalies'});
      }
      if (selectedIssueOption.value !== item.value && item.value === 'alerts') {
        segmentClickEvent({category: 'topology/dropdown', name: 'Drop down Alerts'});
      }
      this.props.setQueryParams({issuesType: item.value, selectedIssueId: undefined});
    };

    onIssueItemClicked = (item) => {
      const itemId = this.props.selectedIssueId === item.id ? undefined : item.id;
      this.props.setQueryParams({selectedIssueId: itemId});

      if (this.props.onIssueItemClick) {
        this.props.onIssueItemClick(itemId);
      }
    };

    render() {
      const {issuesOptions, selectedIssueOption, issues, issuesIsLoading, selectedIssueId, timeZoneName} = this.props;

      if (selectedIssueId) {
        return null;
      }

      return (
        <Fragment>
          <div styleName="issues-type-wrapper">
            <span styleName="issues-ddl-wrapper">
              <SelectAndt
                automationId="tpIssuesType"
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_BLUE_LEAN}
                onChange={this.onChange}
                options={issuesOptions}
                value={selectedIssueOption}
                placeholder="Num"
                optionHeight={40}
                menuWidth={170}
                getOptionLabel={(val) => val.label}
                getOptionValue={(val) => val.value}
              />
              <span styleName="issues-label">from the</span>
            </span>
            <span styleName="date-range-wrapper">
              <IssueDateRange />
            </span>
          </div>
          <TinyScrollBox
            mt={1.5}
            pb={4}
            width={PANEL_SIZES.tinyScrollBox}
            height="100%"
            css={{overflowY: 'auto', overflowX: 'hidden'}}
          >
            <Box width={PANEL_SIZES.issueWidth}>
              <IssuesList
                onIssueItemClicked={this.onIssueItemClicked}
                issues={issues}
                isLoading={issuesIsLoading}
                selectedIssueId={selectedIssueId}
                timeZoneName={timeZoneName}
              />
            </Box>
          </TinyScrollBox>
        </Fragment>
      );
    }
  },
);
