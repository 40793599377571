// @flow

import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';
import {fetchUsers, fetchGroups} from 'admin.users/store/actions';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {
  getUsersGroupsListsFull,
  getUsersGroupsListsFilterByUserProfile,
  fetchGroupsData,
} from 'admin.users/store/selectors';
import {
  getAccessListAllSourcesData,
  getAccesslistForSourceData,
  getIsRbacEnabled,
} from 'admin.permissions/store/selectors';
import {getAccesslistForSource} from 'admin.permissions/store/actions';
import {getMeOwnerOrganization} from 'profile/store/selectors';
import {getStreamsInExpression} from '../../metrics/store/selectors';
import './UsersGroupsSelect.module.scss';
import {getAccessListByStream} from '../../admin.permissions/store/selectors';

type PropTypes = {
  value: String,
  onChange: Function,
  disabled: boolean,
  resourceName: String,
  // eslint-disable-next-line react/no-unused-prop-types
  isSameGroup: boolean,
  showGroupSubText: boolean,
  selectProps: Object,
  menuFooterComponent: any,
  onMenuClose: Function,
  refSelectComponent: Function,
};

const EMPTY_OBJECT = {};

const UsersGroupsSelect = ({
  value,
  onChange,
  disabled,
  resourceName,
  isSameGroup,
  showGroupSubText,
  selectProps,
  menuFooterComponent,
  onMenuClose,
  refSelectComponent,
}: PropTypes) => {
  const dispatch = useDispatch();
  const ownerOrganization = useSelector(getMeOwnerOrganization);
  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchGroups({ownerOrganization}));
  }, [ownerOrganization]);

  const itemsList = isSameGroup
    ? useSelector(getUsersGroupsListsFilterByUserProfile)
    : useSelector(getUsersGroupsListsFull);
  const streamsInExpression = useSelector(getStreamsInExpression);
  const accessListAllSourcesData = useSelector(getAccessListAllSourcesData);
  const accesslistForSourceData = useSelector(getAccesslistForSourceData);
  const accessListByStream = useSelector(getAccessListByStream);
  const isRbacEnabled = useSelector(getIsRbacEnabled);
  const groupData = useSelector(fetchGroupsData);

  const channelsAvailableByAllStreams = useMemo(() => {
    const intersection = Object.values(accessListByStream).reduce((a, b) => a.filter((c) => b.includes(c)), ['*']);
    return intersection;
  }, [JSON.stringify(accessListByStream)]);

  const streamsFilteredByAccesslist = useMemo(() => {
    if (!isRbacEnabled) {
      return itemsList;
    }
    if (streamsInExpression.length === 0) {
      return itemsList;
    }

    itemsList.forEach((item) => {
      if (groupData.length > 0) {
        if (item.type === 'USERS') {
          const group = groupData.find((gd) => gd.users.includes(item.value));
          // eslint-disable-next-line no-param-reassign
          item.groupId = group ? group.id : null;
        }
      }
    });

    let filtered = cloneDeep(itemsList);

    Object.values(streamsInExpression).forEach((stream) => {
      const allowedGroupsForThisStream = accessListAllSourcesData[stream];
      if (allowedGroupsForThisStream) {
        filtered = filtered.filter((f) => {
          if (allowedGroupsForThisStream.includes('*')) {
            return true;
          }
          if (f.type === 'USERS') {
            return allowedGroupsForThisStream.includes(f.groupId);
          }
          if (f.type === 'GROUPS') {
            return allowedGroupsForThisStream.includes(f.value);
          }
          if (f.type === 'HEADER') {
            return true;
          }
          return true;
        });
      }
    });

    const listOfOrfansHeaders = filtered.filter((channel) => {
      if (channel.type === 'HEADER') {
        const contentCount = filtered.filter((chan) => chan.type === channel.value).length;
        if (contentCount === 0) {
          return true;
        }
      }
      return false;
    });

    listOfOrfansHeaders.forEach((header) => {
      const index = filtered.findIndex((channel) => channel.value === header.value);
      filtered.splice(index, 1);
    });

    return filtered;
  }, [
    JSON.stringify(streamsInExpression),
    JSON.stringify(accessListAllSourcesData),
    JSON.stringify(itemsList),
    JSON.stringify(accesslistForSourceData),
    JSON.stringify(channelsAvailableByAllStreams),
  ]);

  useEffect(() => {
    if (streamsInExpression && streamsInExpression.length) {
      streamsInExpression.forEach((stream) => {
        if (!accessListByStream[stream]) {
          dispatch(getAccesslistForSource({streamId: stream}, {type: 'STREAM', streamId: stream}));
        }
      });
    }
  }, [JSON.stringify(streamsInExpression), JSON.stringify(accessListByStream)]);

  const customFilterOption = useCallback((option, rawInput) => {
    const checkFunction = (variable, rInput) => variable.label.toLowerCase().indexOf(rInput.toLowerCase()) > -1;

    if (option.data.type === 'HEADER') {
      if (rawInput.length === 0) {
        return true;
      }
      return !!itemsList.find(
        (item) => item.type.toLowerCase() === option.data.value.toLowerCase() && checkFunction(item, rawInput),
      );
    }
    return checkFunction(option, rawInput);
  }, []);

  const selectedItem = itemsList.find((item) => item.value === value) || EMPTY_OBJECT;
  const count = selectedItem.count ? selectedItem.count.split(' ')[0] : '';
  const resName = resourceName || 'resource';

  useEffect(() => {
    streamsFilteredByAccesslist.forEach((s) => {
      // eslint-disable-next-line no-param-reassign
      delete s.groupId;
    });
  }, [streamsFilteredByAccesslist]);

  return (
    <div>
      <SelectAndt
        automationId="setAlertOwnerSelect"
        type={TYPE_NEW_SEARCH}
        theme={THEME_NOT_HIGHLIGHTED}
        onChange={onChange}
        options={streamsFilteredByAccesslist}
        value={selectedItem.label ? selectedItem : null}
        placeholder="Search Owner"
        optionHeight={40}
        menuWidth={310}
        filterOption={customFilterOption}
        disabled={disabled}
        showValue
        onMenuClose={onMenuClose}
        refSelectComponent={refSelectComponent}
        menuFooterComponent={menuFooterComponent || null}
        {...(selectProps || EMPTY_OBJECT)}
      />
      {showGroupSubText && (
        <span styleName={['sub-text', selectedItem.type !== 'GROUPS' ? 'invisible' : ''].join(' ')}>
          {`All ${count} members of the ${selectedItem.label} team will be able to edit this ${resName}.`}
        </span>
      )}
    </div>
  );
};

export default UsersGroupsSelect;
