/* eslint-disable no-param-reassign */
import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';
import * as api from '../../services/api';

import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';
import * as selectors from '../selectors';
import {getUniqueId} from '../../../common/utils/guid';

const fetchSegmentAnoToken = makeAsyncEpic(actions.fetchSegmentAnoToken, api.fetchSegmentAnoToken);
const fetchSegmentMessageTypes = makeAsyncEpic(actions.fetchSegmentMessageTypes, api.fetchSegmentMessageTypes);
const fetchSegmentMessageMetadata = makeAsyncEpic(actions.fetchSegmentMessageMetadata, api.fetchSegmentMessageMetadata);
const fetchSegmentJsonDimensions = makeAsyncEpic(actions.fetchSegmentJsonDimensions, api.fetchSegmentJsonDimensions);

const clearSegmentData = (action$, {getState}) =>
  action$.ofType(actions.clearSegmentData.TYPE).flatMap(() => {
    const stream = selectors.getSelectedDataStream(getState());
    const res = {
      schema: {
        columns: [],
        sourceColumns: [],
      },
      metrics: [],
      dimensions: [],
      uiState: {
        ...stream.uiState,
        messageMetadata: [],
        unAssignedColumns: [],
      },
    };
    return [actions.setSelectedStreamKeyVal(res)];
  });

const setSegmentMessageTypes = (action$, {getState}) =>
  action$.ofType(actions.setSegmentMessageTypes.TYPE).flatMap((action) => [
    actions.fetchSegmentMessageMetadata({
      dataSourceId: selectors.getSelectedDataStream(getState()).dataSourceId,
      messageTypes: action.payload.messageTypes,
    }),
  ]);

const fetchSegmentMessageMetadataSuccess = (action$, {getState}) =>
  action$
    .ofType(actions.fetchSegmentMessageMetadata.success.TYPE, actions.fetchSegmentJsonDimensions.success.TYPE)
    .flatMap((action) => {
      const stream = selectors.getSelectedDataStream(getState());
      const res = {
        schema: {
          columns: [],
          sourceColumns: [],
        },
        metrics: [],
        dimensions: [],
        uiState: {
          ...stream.uiState,
          messageMetadata: action.payload,
          unAssignedColumns: [],
        },
      };
      action.payload.dimensions.forEach((dim) => {
        res.schema.columns.push({
          id: getUniqueId(),
          sourceColumn: dim.path,
          name: dim.name,
          type: 'dimension',
        });
        res.schema.sourceColumns.push({
          id: dim.path,
          path: dim.path,
        });
        res.dimensions.push(dim.path);
      });

      return [actions.setSelectedStreamKeyVal(res)];
    });

const segmentEpic = combineEpics(
  clearSegmentData,
  fetchSegmentAnoToken,
  fetchSegmentMessageTypes,
  fetchSegmentMessageMetadata,
  setSegmentMessageTypes,
  fetchSegmentJsonDimensions,
  fetchSegmentMessageMetadataSuccess,
);
export default segmentEpic;
