import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectors as commonSelectors} from 'common';
import {isImpactModalOpen} from 'impact/store/actions';
import {isImpactModalOpenNewAlertConsole} from 'impactNewAlertConsole/store/actions';
import {
  setIsMinimize as investigationSetIsMinimize,
  setIsOpen as investigationSetIsOpen,
} from 'investigation/store/actions';
import {
  setIsMinimize as investigationSetIsMinimizeNew,
  setIsOpen as investigationSetIsOpenNew,
} from 'investigationNewAlertConsole/store/actions';
import LoadingBar from 'common/components/LoadingBar';
import NotificationBar from 'common/components/NotificationBar';
import ImpactModal from 'impact/ImpactModal';
import ImpactModalNew from 'impactNewAlertConsole/ImpactModal';
import InvestigationModal from 'investigation/pages/InvestigationModal';
import InvestigationModalNew from 'investigationNewAlertConsole/pages/InvestigationModal';
import {modalRouting as investigationRouting} from 'investigation/services/investigationService';
import {modalRouting as investigationRoutingNew} from 'investigationNewAlertConsole/services/investigationService';
import {modalRouting1 as impactRouting1, modalRouting2 as impactRouting2} from 'impact/services/constants';
import {
  modalRouting1 as impactRouting1New,
  modalRouting2 as impactRouting2New,
} from 'impactNewAlertConsole/services/constants';
import {
  getIsImpersonate,
  getNewAlertConsoleEnabled,
  getNewAlertConsoleMigrationFinished,
  getTriageFullScreenEnabled,
} from 'profile/store/selectors';

const checking = (routingLocation, modalRouting) => {
  if (!routingLocation || !routingLocation.query) {
    return false;
  }
  return Object.values(modalRouting).every((queryParam) => routingLocation.query[queryParam]);
};

const Overlays = () => {
  const dispatch = useDispatch();
  const routingLocation = useSelector(commonSelectors.getRoutingLocation);
  const isNewAlertConsoleEnabled = useSelector(getNewAlertConsoleEnabled);
  const isNewAlertConsoleMigrationFinished = useSelector(getNewAlertConsoleMigrationFinished);
  const isImpersonating = useSelector(getIsImpersonate);
  const isTriageFullscreenEnabled = useSelector(getTriageFullScreenEnabled);

  const openImpactModalOpen = () => {
    if (
      isNewAlertConsoleEnabled &&
      (isImpersonating || isNewAlertConsoleMigrationFinished) &&
      routingLocation.pathname === '/alerts-console-new'
    ) {
      if (checking(routingLocation, impactRouting1New) || checking(routingLocation, impactRouting2New)) {
        dispatch(isImpactModalOpenNewAlertConsole(true));
      }
    } else if (checking(routingLocation, impactRouting1) || checking(routingLocation, impactRouting2)) {
      dispatch(isImpactModalOpen(true));
    }
  };

  const openInvestigationModalOpen = () => {
    if (
      isNewAlertConsoleEnabled &&
      (isImpersonating || isNewAlertConsoleMigrationFinished) &&
      routingLocation.pathname === '/alerts-console-new'
    ) {
      if (!isTriageFullscreenEnabled) {
        if (checking(routingLocation, investigationRoutingNew)) {
          dispatch(investigationSetIsOpenNew(true));
          dispatch(investigationSetIsMinimizeNew(false));
        }
      }
    } else if (checking(routingLocation, investigationRouting)) {
      dispatch(investigationSetIsOpen(true));
      dispatch(investigationSetIsMinimize(false));
    }
  };

  useEffect(() => {
    openImpactModalOpen();
    openInvestigationModalOpen();
  }, [routingLocation]);

  return (
    <>
      <LoadingBar className="loading-bar" />
      <NotificationBar />
      {isNewAlertConsoleEnabled &&
      (isImpersonating || isNewAlertConsoleMigrationFinished) &&
      routingLocation.pathname === '/alerts-console-new' ? (
        <ImpactModalNew />
      ) : (
        <ImpactModal />
      )}
      {isNewAlertConsoleEnabled &&
      (isImpersonating || isNewAlertConsoleMigrationFinished) &&
      routingLocation.pathname === '/alerts-console-new' ? (
        <InvestigationModalNew />
      ) : (
        <InvestigationModal />
      )}
    </>
  );
};

export default Overlays;
