// @flow
import UserEventsRowHeader from 'userEvents/components/UserEventsTable/UserEventsRowHeader';
import UserEventsExpendablePanel from 'userEvents/components/UserEventsTable/UserEventsExpendablePanel';
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import React, {PureComponent} from 'react';
import styles from './UserEventsTable.module.scss';

type PropTypes = {
  event: Object,
  searchString: string,
  timeZoneName: string,
  isSelected: boolean,
  setSelectedItem: Function,
};

export default class UsersTableRow extends PureComponent {
  props: PropTypes;

  render() {
    const {event, searchString, timeZoneName, isSelected, setSelectedItem} = this.props;
    return (
      <div>
        <TableListItem
          key={event.id}
          id={event.id}
          style={{paddingRight: 2}}
          expandedPanelClass={isSelected ? styles.tableRowOpen : ''}
          headerComponent={
            <UserEventsRowHeader event={event} searchString={searchString} timeZoneName={timeZoneName} />
          }
          expandedPanel={<UserEventsExpendablePanel event={event} />}
          checkboxVisibility={CHECKBOX_VISIBILITY_TYPES.alwaysHide}
          setSelectedItem={setSelectedItem}
          isSelected={isSelected}
        />
      </div>
    );
  }
}
