// @flow
import React from 'react';
import EmptyMessage, {IMAGE_TYPES} from 'common/componentsV2/table/EmptyMessage';

import '../../../common/componentsV2/table/EmptyMessage.module.scss';

const AlertConsoleEmptyState = ({
  alertsConfigurationCount,
  isError = false,
}: {
  alertsConfigurationCount: Number,
  isError: Boolean,
}) => {
  return (
    <div styleName="container">
      {isError === true ? (
        <EmptyMessage imageType={IMAGE_TYPES.NO_TRIGGERED} title="Error" subtitle="Error while bringing alerts" />
      ) : (
        <>
          {alertsConfigurationCount === 0 ? (
            <EmptyMessage
              imageType={IMAGE_TYPES.NO_ALERTS}
              title="No Alerts Yet"
              subtitle="Start by creating the first alert"
            />
          ) : (
            <EmptyMessage
              imageType={IMAGE_TYPES.ALERTS_FILTERED_OUT}
              title="No Alerts to Show"
              subtitle="Change Filters to See Alerts"
            />
          )}{' '}
        </>
      )}
    </div>
  );
};

export default AlertConsoleEmptyState;
