// @flow
import React, {useCallback, useMemo} from 'react';
import {isEmpty} from 'lodash';
import {durationScales, resolutionTypes} from 'metrics/services/metricsService';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TooltipArea from 'common/componentsV2/TooltipArea';
import * as actions from 'alerts.management/store/actions';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'alerts.management/store/selectors';
import InputAuto from '../simulationArea/utils/InputAuto';

const durationTypes = [
  {
    label: 'Minutes',
    value: 'minutes',
  },
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Weeks',
    value: 'weeks',
  },
];

const MinDurationScale = (props: {scale: Object, onChange: Function}) => {
  const rollup = useSelector(selectors.getSimulationFiltersRollup);
  const availableOptions = useMemo(
    () =>
      durationTypes.filter(
        (item, index) => index >= durationTypes.findIndex((dtype) => resolutionTypes[rollup].units === dtype.value),
      ),
    [rollup],
  );
  return (
    <SelectAndt
      id="alertSettingsMinimumDurationFilter"
      automationId="AlertAckFilter"
      extraClassName="alerts-dropdown-btn"
      options={availableOptions}
      optionHeight={40}
      type={TYPE_NEW_NO_SEARCH}
      theme={THEME_NOT_HIGHLIGHTED}
      onChange={(item) => props.onChange(item.value)}
      value={durationTypes.find((item) => item.value === props.scale)}
    />
  );
};

const formatDuration = (minDuration) => parseInt(minDuration.value / durationScales[minDuration.scale].seconds, 10);
const transformNewDuration = (value, scale) => value * durationScales[scale].seconds;
const transformScale = (value, scale) => value / durationScales[scale].seconds;

const MinDurationSelector = () => {
  const dispatch = useDispatch();
  const minDurationUI = useSelector(selectors.getSimulationFiltersMinDurationUI);
  const setSelectedAlertMinDuration = useCallback((...args) => dispatch(actions.setSelectedAlertMinDuration(...args)), [
    dispatch,
  ]);
  const setSelectedAlertMinDurationScale = useCallback(
    (...args) => dispatch(actions.setSelectedAlertMinDurationScale(...args)),
    [dispatch],
  );
  const alertType = useSelector(selectors.getAlertType);

  if (isEmpty(minDurationUI)) {
    return null;
  }

  return (
    <TooltipArea
      isAlwaysVisible
      text="Select the time that the anomaly should last before an alert is triggered."
      automation-id="minDurationContainer"
    >
      {(info) => (
        <React.Fragment>
          <div className="text16reg lineHeight_16 mb_1">
            {alertType === 'static' ? 'Breaches' : 'Anomalies'}
            {' With a '}
            <b>Minimum Duration</b>
            {' of'}
            {info}
          </div>
          <Grid container>
            <Grid item>
              <Box width={92} height={36} mr={1}>
                <InputAuto
                  fullSize
                  automationId="minimumDuration"
                  delay={1000}
                  key={minDurationUI.scale}
                  value={formatDuration(minDurationUI)}
                  type="number"
                  multipleOf={minDurationUI.scale === 'minutes' && minDurationUI.step === 300 ? 5 : 1}
                  onChange={(e) =>
                    setSelectedAlertMinDuration(transformNewDuration(e.target.value, minDurationUI.scale))
                  }
                  additionalProps={{
                    min: transformScale(minDurationUI.min, minDurationUI.scale),
                    max: transformScale(minDurationUI.max, minDurationUI.scale),
                    step: transformScale(minDurationUI.step, minDurationUI.scale),
                  }}
                  tooltip={false}
                  isInvalid={!minDurationUI.value}
                  invalidMessage="Cannot be empty"
                  allowEmpty
                />
              </Box>
            </Grid>
            <Grid item>
              <Box width={144} height={36}>
                <MinDurationScale onChange={setSelectedAlertMinDurationScale} scale={minDurationUI.scale} />
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default React.memo(MinDurationSelector);
