// @flow
/* eslint-disable */
import React from 'react';
import {get, isEqual} from 'lodash';
import VirtualizedListOfComponents from 'common/componentsV2/VirtualizedListOfComponents';
import FormDdl from '../FormDdl';
import FormDdlSearchBar from '../FormDdlSearchBar';
import './MultiLevelDropdown.module.scss';
import DDLLoader from '../../../DDLLoader';
import {ReactComponent as EmptyIcon} from '../../../../../app/images/empty-dropdown.svg';
import TypographyBox from '../../../boxTools/TypographyBox';
import Box from '@material-ui/core/Box';

type PropTypes = {
  optionComponent: Node,
  multiOptionComponent: Node,
  footerComponent: Node,
  options: Array,
  button: Node,
  onSelect: Function,
  rowHeight: Number,
  position: String,
  placement: String,
  isUseSearch: boolean,
  width: Number,
  height: Number,
  disabled: boolean,
  isOpen: boolean,
  onToggle: Function,
  usePropsForOpenState: boolean,
  onFocusedItem: Function,
  isMulti: boolean,
  getOptionLabel: Function,
  getOptionValue: Function,
  onFirstSelect: Function,
  value: any,
  secondLevelTitle: String,
  filterFunction: Function, // (value: string, options: Array) return filtered: Array
  searchText: String,
  onSearchChange: Function, // (value: string)
  useHeader: boolean,
  isFirstPanelLoading: boolean,
  isSecondPanelLoading: boolean,
  returnToFirstLevelAfterSelect: boolean,
  isAsyncData: boolean,
  buttonWidth: number,
  automationId: string,
  horizontalOffset: number,
};
export default class MultiLevelDropdown extends React.PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      filteredList: props.options,
      secondList: [],
      highlightText: '',
      isNextTab: false,
      selectedValue: props.isMulti ? [] : {},
      secondListTitle: null,
      nextValue: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.options, this.props.options)) {
      if (this.props.isAsyncData && this.state.isNextTab) {
        this.setState((state) => ({
          filteredList: this.props.options,
          secondList: get(this.props.options.find((item) => item.value === state.nextValue), 'multi', []),
        }));
      } else {
        this.reset();
      }
    }
  }

  reset = () => {
    this.onSearchChange(this.props.searchText || '');
    this.setState({
      filteredList: this.props.options,
      selectedValue: this.props.isMulti ? [] : {},
      isNextTab: false,
    });
  };

  onToggle = (val) => {
    if (this.props.onToggle) {
      this.props.onToggle(val);
    }
    this.setState({isOpen: val});
  };

  label = (option) => (this.props.getOptionLabel ? this.props.getOptionLabel(option) : option.label);

  value = (option) => (this.props.getOptionValue ? this.props.getOptionValue(option) : option.value);

  onSearchChange = (val) => {
    let filtered = [];
    if (this.props.onSearchChange) {
      this.props.onSearchChange(val);
    }
    if (this.props.filterFunction) {
      filtered = this.props.filterFunction(val, this.props.options);
    } else {
      this.props.options.forEach((option) => {
        const lab = this.label(option).toLocaleLowerCase();
        const ret = lab.indexOf(val.toLocaleLowerCase());
        if (ret > -1) {
          filtered.push(option);
        }

        // -- old system that used to filter the measures in the 1st level AND the 2nd level too
        // -- at the same time. It was changed by a simple system where the list is filtered using only the keys.

        // if (!option.multi) {
        //   const lab = option.label.toLocaleLowerCase();
        //   const ret = lab.indexOf(val.toLocaleLowerCase());
        //   if (ret > -1) {
        //     filtered.push(option);
        //   }
        // }
        // else {
        //   const multipleOptions = [];
        //   option.multi.forEach((optionMulti) => {
        //     const lab = optionMulti.label.toLocaleLowerCase();
        //     const ret = lab.indexOf(val.toLocaleLowerCase());
        //     if (ret > -1) {
        //       multipleOptions.push(optionMulti);
        //     }
        //   });
        //   if (multipleOptions.length > 0) {
        //     filtered.push({
        //       ...option,
        //       multi: multipleOptions,
        //     });
        //   }
        // }
      });
    }
    this.setState({
      filteredList: filtered,
      highlightText: val,
    });
  };

  handleSelectFirst = (val) => {
    if (!val) {
      return;
    }
    this.onSearchChange('');
    if (!val.multi) {
      if (this.props.isMulti) {
        this.props.onSelect([val]);
        this.setState({selectedValue: [val]});
      } else {
        this.props.onSelect(val);
        this.setState({selectedValue: val});
      }
      this.onToggle();
    } else {
      if (this.props.onFirstSelect) {
        this.props.onFirstSelect(val);
      }
      this.setState({
        isNextTab: true,
        secondList: val.multi,
        nextValue: val.value,
        secondListTitle: val.displayName,
      });
    }
  };

  handleSelectSecond = (val) => {
    this.onSearchChange('');
    const selectedValue = this.props.value || this.state.selectedValue;
    const {returnToFirstLevelAfterSelect} = this.props;
    if (this.props.isMulti) {
      let newArr = [];
      if (selectedValue.find((item) => item.value === val.value)) {
        if (val.value !== '*') {
          newArr = selectedValue.filter((item) => item.value !== val.value && item.value !== '*');
        }
        this.props.onSelect(newArr);
        this.setState(() => ({selectedValue: newArr, isNextTab: !returnToFirstLevelAfterSelect}));
      } else {
        if (val.value !== '*') {
          this.props.onSelect([...selectedValue, val]);
          this.setState(() => ({selectedValue: [...selectedValue, val], isNextTab: !returnToFirstLevelAfterSelect}));
        } else {
          this.props.onSelect(this.state.secondList);
          this.setState(() => ({selectedValue: this.state.secondList, isNextTab: !returnToFirstLevelAfterSelect}));
        }
      }
    } else {
      this.props.onSelect(val);
      this.setState({selectedValue: val, isNextTab: !returnToFirstLevelAfterSelect});
      this.onToggle();
    }
  };

  handleBackToFirstTab = () => {
    this.setState({isNextTab: false});
  };

  renderInnerComponent = () => {
    const {
      optionComponent,
      multiOptionComponent,
      footerComponent,
      rowHeight,
      isUseSearch,
      width,
      height,
      isFirstPanelLoading,
      isSecondPanelLoading,
      isAsyncData,
    } = this.props;

    return (
      <div className={'idle-external-click'} style={{width}} styleName="container">
        <div style={{width: width * 2}} styleName={`pane ${this.state.isNextTab ? 'second' : 'first'}`}>
          <div style={{width}} styleName="first-tab">
            {isUseSearch && (
              <FormDdlSearchBar
                isAutofocus
                value={this.state.highlightText}
                onChange={this.onSearchChange}
                automationId={`${this.props.automationId}Search`}
              />
            )}
            {!isFirstPanelLoading && this.state.filteredList?.length === 0 && (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                flexGrow={1}
                height={400}
              >
                <EmptyIcon width={101} height={71} />
                <TypographyBox variant="subtitle2" marginTop={2}>
                  Nothing Found
                </TypographyBox>
              </Box>
            )}
            {!isFirstPanelLoading ? (
              <VirtualizedListOfComponents
                isAsyncData={isAsyncData}
                optionComponent={optionComponent}
                options={this.state.filteredList}
                selectedValue={this.props.value || this.state.selectedValue}
                onSelect={this.handleSelectFirst}
                rowHeight={rowHeight}
                panelWidth={width}
                panelHeight={height}
                highlightText={this.state.highlightText}
                isFocused={!this.state.isNextTab}
                onItemFocus={this.props.onFocusedItem}
                getOptionLabel={this.props.getOptionLabel}
                useHeader={this.props.useHeader}
                isMultiValues={this.props.isMulti}
                automationId={this.props.automationId}
              />
            ) : (
              <div styleName="spinner">
                <DDLLoader />
              </div>
            )}
            {footerComponent ? footerComponent : null}
          </div>
          <div style={{width}} styleName="second-tab">
            <h2 styleName="list-header with-chevron" onClick={this.handleBackToFirstTab}>
              {this.props.secondLevelTitle || this.state.secondListTitle}
            </h2>
            {!isSecondPanelLoading ? (
              <VirtualizedListOfComponents
                isAsyncData={isAsyncData}
                optionComponent={this.props.isMulti ? multiOptionComponent : optionComponent}
                options={this.state.secondList}
                selectedValue={this.props.value || this.state.selectedValue}
                onSelect={this.handleSelectSecond}
                onBack={() => {
                  this.setState({isNextTab: false});
                }}
                rowHeight={rowHeight}
                panelWidth={width}
                panelHeight={height}
                highlightText=""
                isFocused={this.state.isNextTab}
                onItemFocus={this.props.onFocusedItem}
                getOptionLabel={this.props.getOptionLabel}
                isMultiValues={this.props.isMulti}
                selectedValuesArr={this.props.value || this.state.selectedValue}
                useMulti={this.props.isMulti}
                automationId={this.props.automationId}
              />
            ) : (
              <div styleName="spinner">
                <DDLLoader />
              </div>
            )}
            {footerComponent ? footerComponent : null}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {buttonWidth} = this.props;
    return (
      <FormDdl
        buttonComponent={
          this.props.button || (
            <button type="button" disabled={this.props.disabled}>
              Listed Component Button
            </button>
          )
        }
        buttonWidth={buttonWidth}
        popoverComponent={this.renderInnerComponent()}
        onToggle={this.onToggle}
        position={this.props.position}
        placement={this.props.placement}
        isOpen={this.props.usePropsForOpenState ? this.props.isOpen : this.state.isOpen}
        disabled={this.props.disabled}
        width={this.props.width}
        horizontalOffset={this.props.horizontalOffset}
        exceptionsClickOutside={['no-external-click']}
        automationId={this.props.automationId}
      />
    );
  }
}
/* eslint-enable */
