import React, {createContext, useContext, useReducer} from 'react';
import PropTypes from 'prop-types';

import {useEditReducer, STAGES_INITIAL_STATE} from 'recommendations/hooks/useRecommendationsLocalReducer';

const StageStateContext = createContext(undefined);
function RecommendationsProvider({children}) {
  const [editState, editDispatch] = useReducer(useEditReducer, STAGES_INITIAL_STATE);
  const value = {
    editState,
    editDispatch,
  };

  return <StageStateContext.Provider value={value}>{children}</StageStateContext.Provider>;
}

function useRecommendationsContext() {
  const context = useContext(StageStateContext);
  if (context === undefined) {
    throw new Error('useRecommendationsContext must be used within a RecommendationsProvider');
  }
  return context;
}

RecommendationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {RecommendationsProvider, useRecommendationsContext};
