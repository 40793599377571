import React, {useMemo} from 'react';
import {get} from 'lodash';
import {useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import SelectStandart from 'common/componentsV2/ddl/SelectStandart';
import {getCreateInitialConnection} from 'alerts.channels/store/selectors';

const validate = (value) => {
  return !value && 'Jira Project ID is mandatory';
};

const JiraProjectId = () => {
  const {
    input: {value, onChange},
    // meta,
  } = useField('jiraProjectId', {validate});

  const projectsResponse = useSelector(getCreateInitialConnection);
  const options = useMemo(
    () => (get(projectsResponse, 'data.projects', []) || []).map((item) => ({value: item.id, label: item.name})),
    [projectsResponse],
  );

  const selectedOption = useMemo(() => options.find((item) => item.value === value), [options, value]);

  return (
    <div>
      <div>Jira Project Id</div>
      <SelectStandart
        id="jiraProjectId"
        onChange={(item) => onChange(item.value)}
        value={selectedOption}
        options={options}
      />
    </div>
  );
};

export default JiraProjectId;
