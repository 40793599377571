import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';

import './Button.module.scss';

export const COLORS = {
  // TODO - colorSchema
  AZURE_500: 'azure-500',
  GRAY_200: 'gray-200', // #e9e9f0;
  GRAY_300: 'gray-300',
  GRAY_400: 'gray-400',
  GRAY_500: 'gray-500',
  INDIGO_500: 'indigo-500',
  BLUE_500: 'blue-500',
  RED_500: 'red-500',
  GREEN_500: 'green-500',
  WHITE: 'white',
  TRANSPARENT: 'transparent',
  TRANSPARENT_BLUE: 'transparent-blue',
  GHOST_BLUE: 'ghost-blue',
  GHOST_LIGHT_BLUE: 'ghost-blue-light',
};

export const HEIGHTS = {
  TIGHT30: 'tight-30',
  TIGHT28: 'tight-28',
  REGULAR: 'regular-height',
  TALL: 'tall',
};

export const WIDTHS = {
  WIDE: 'wide',
  REGULAR: 'regular-width',
  NARROW: 'narrow',
  NARROW_TIGHT: 'narrowTight',
  NO_PADDING: 'noPadding',
};

const useStyles = makeStyles(() => ({
  container: {
    '&:hover': {},
    '& *': {
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

export default function SimpleButton({children, colorSchema, onClick, isDisabled, automationId, extraClassName}) {
  const container = useRef(null);
  const classes = useStyles();

  const buttonStyle = ['andt-button', colorSchema];

  return (
    <button
      ref={container}
      type="button"
      onClick={onClick}
      disabled={isDisabled}
      styleName={buttonStyle.join(' ')}
      className={`${classes.container} ${extraClassName}`}
      automation-id={automationId}
    >
      {children}
    </button>
  );
}

SimpleButton.defaultProps = {
  extraClassName: '',
  isDisabled: false,
};

SimpleButton.propTypes = {
  children: PropTypes.node.isRequired,
  colorSchema: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  automationId: PropTypes.string.isRequired,
  extraClassName: PropTypes.string,
};
