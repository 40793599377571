import React, {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';
import Button, {COLORS, HEIGHTS as BUTTON_HEIGHTS, WIDTHS as BUTTON_WIDTHS} from 'common/componentsV2/Button';
import {DATE_FORMATS} from 'bc/services/fuService';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import moment from 'moment';

import styles from './TimeSettings.module.scss';

const BROWSER = 'Browser';
const clientTz = {label: moment.tz.guess()};
const timeZones = moment.tz.names().map((item) => ({
  label: item,
}));
timeZones.unshift({label: BROWSER});

export default function TimeSettings({settingsName, closeModal, sourceColumnName}: PropTypes) {
  const {getValues, setValue} = useFormContext();
  const [timeSettings, setTimeSettings] = useState(getValues(settingsName));
  const [hasTimeZone, setHasTimeZone] = useState(timeSettings?.timeZone);
  const [formats, setFormats] = useState([]);
  const [searchedFormats, setSearchedFormats] = useState([]);
  const [searchedTimezones, setSearchedTimezones] = useState([]);
  const [searchFormatStr, setSearchFormatStr] = useState('');
  const [searchTimeZoneStr, setSearchTimeZoneStr] = useState('');
  const [selectedPattern, setSelectedPattern] = useState('');
  const [selectedTimeZone, setSelectedTimeZone] = useState('');

  useEffect(() => {
    const tmpArr = [...DATE_FORMATS];

    if (timeSettings?.timePattern && tmpArr.indexOf(timeSettings?.timePattern) === -1) {
      tmpArr.push(timeSettings?.timePattern);
    }
    const dateFormats = tmpArr
      .filter((item, index) => tmpArr.indexOf(item) === index)
      .map((j) => {
        const timePattern = j.toLowerCase();
        const includeTimeZone = timePattern.indexOf('z') === -1 && timePattern.indexOf("'z") === -1;
        return {
          label: j.indexOf('epoch') >= 0 ? j.replace('_', ' ').replace(/\b\w/g, (l) => l.toUpperCase()) : j,
          value: j,
          hasTimeZone: includeTimeZone && !['epoch_seconds', 'epoch_millis', 'epoch_micros'].includes(j),
        };
      })
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    setFormats(dateFormats);
    setSearchedFormats(dateFormats);
    const selectFormat = dateFormats.find(
      (format) => format?.value === timeSettings?.timePattern || format?.value === timeSettings?.timePattern?.value,
    );
    setSelectedPattern(selectFormat);
    setHasTimeZone(selectFormat?.hasTimeZone);
    setSelectedTimeZone(timeZones.find((tz) => tz?.label === timeSettings?.timeZone));
    setSearchedTimezones([...timeZones]);
  }, []);

  const saveSettings = () => {
    const sourceColumnIdx = getValues(sourceColumnName)?.sourceColumn;
    const time = {
      timePattern: timeSettings?.timePattern.value || timeSettings?.timePattern,
      timeColumnIdx: sourceColumnIdx || timeSettings?.timeColumnIdx,
      timeZone: timeSettings?.timeZone?.label || timeSettings?.timeZone,
    };
    setValue(settingsName, time, {shouldDirty: true, shouldTouch: true});
    closeModal();
  };

  const changePattern = (value) => {
    // add new format
    if (value.isCreateMode) {
      const newValue = {
        value: value.value,
        label: value.value,
        hasTimeZone: false,
      };
      formats.push(newValue);
      setSearchedFormats({...formats});
      setSelectedPattern(newValue);
    } else {
      setSelectedPattern(value);
    }

    setTimeSettings({timePattern: value, timeColumnIdx: timeSettings?.timeColumnIdx, timeZone: null});
    setSelectedTimeZone(null);

    setHasTimeZone(value.hasTimeZone);
  };

  const searchPattern = (value) => {
    setSearchFormatStr(value);
    const searchedItems = formats.filter((f) => {
      return f.label.toLowerCase().indexOf(value.toLowerCase()) > -1;
    });
    if (searchedItems.length === 0) {
      setSearchedFormats([
        {
          label: `+ Create ${value}`,
          isCreateMode: true,
          value,
        },
      ]);
      return;
    }
    setSearchedFormats(searchedItems);
  };

  const searchTimeZone = (value) => {
    setSearchTimeZoneStr(value);
    const searchedItems = timeZones.filter((f) => {
      return f.label.toLowerCase().indexOf(value.toLowerCase()) > -1;
    });
    setSearchedTimezones(searchedItems);
  };

  return (
    <>
      <div className={styles.header}>Time Settings</div>

      <div className={styles.contentWrapper}>
        <div>
          <div className={styles.label}>Date-time Pattern</div>
          <div className={styles.selectWrapper}>
            <FormDdlSelect
              isDisabledSorting
              options={searchedFormats}
              selected={timeSettings?.timePattern}
              onChange={changePattern}
              isUseSearch
              onParentSearchChange={searchPattern}
              onParentSearchValue={searchFormatStr}
              button={
                <BigDropdownButton
                  isPristine={!selectedPattern?.label?.length}
                  placeholder="Select"
                  label={selectedPattern?.label}
                  shortBlueStyle
                  isFullWidth
                />
              }
              optionComponent={<OptionComponentSimple />}
              width={160}
              height={240}
            />
          </div>
        </div>
        <div>
          <div className={styles.label}>Time Zone</div>
          <div className={!hasTimeZone ? `${styles.selectWrapper} ${styles.disable}` : styles.selectWrapper}>
            <FormDdlSelect
              isDisabledSorting
              options={searchedTimezones}
              disabled={!hasTimeZone}
              selected={timeSettings?.timeZone}
              isUseSearch
              onParentSearchChange={searchTimeZone}
              onParentSearchValue={searchTimeZoneStr}
              onChange={(value) => {
                setTimeSettings({
                  ...timeSettings,
                  timeZone: value.label === BROWSER ? clientTz : value,
                });
                setSelectedTimeZone(value.label === BROWSER ? clientTz : value);
              }}
              button={
                <BigDropdownButton
                  isPristine={!selectedTimeZone?.label?.length}
                  placeholder="Select"
                  label={selectedTimeZone?.label}
                  isFullWidth
                  shortBlueStyle
                />
              }
              optionComponent={<OptionComponentSimple />}
              width={160}
              height={240}
            />
          </div>
        </div>
      </div>

      <div className={styles.footerWrapper}>
        <div className={styles.buttonWrapper}>
          <Button
            colorSchema={COLORS.BLUE_500}
            height={BUTTON_HEIGHTS.REGULAR}
            width={BUTTON_WIDTHS.REGULAR}
            text="Confirm"
            onClick={saveSettings}
            automation-id="applySettingsButton"
          />
        </div>

        <Button text="Cancel" onClick={closeModal} colorSchema={COLORS.GRAY_200} />
      </div>
    </>
  );
}

TimeSettings.propTypes = {
  closeModal: PropTypes.func.isRequired,
};
