import React from 'react';
import {useSelector} from 'react-redux';
import {Navbar, Notification, BizPack, BIZ_PACK_OPTIONS, ClarityLogo} from '@pileus-cloud/anodot-frontend-common';
import {getNotifications} from 'common/store/selectors';
import {useHistory} from 'react-router-dom';
import UsrSettings from './UsrSettings';
import NavbarNotifications from './NavbarNotifications';

function TopNavbar() {
  const notifications = useSelector(getNotifications);
  const history = useHistory();

  const navbarHandleOnClick = () => {
    history.push('/');
  };
  const BizPackOnChange = ({value}) => {
    const bizPackKey = Object.keys(BIZ_PACK_OPTIONS).find((key) => BIZ_PACK_OPTIONS[key].value === value);
    const token = localStorage.getItem('andt-token') || sessionStorage.getItem('andt-token');

    window.open(`${BIZ_PACK_OPTIONS[bizPackKey].url}/#id_token=${token}`, '_blank');
  };

  const revenue = BIZ_PACK_OPTIONS.businessMonitoring.value;

  return (
    <Navbar value={revenue} onClick={navbarHandleOnClick} logo={<ClarityLogo />}>
      <Notification tooltip={`Notifications (${notifications.length})`} isOn={!!notifications.length}>
        <NavbarNotifications />
      </Notification>
      <BizPack options={BIZ_PACK_OPTIONS} onChange={BizPackOnChange} defaultValue={revenue} />
      <div style={{marginRight: 12}}>
        <UsrSettings />
      </div>
    </Navbar>
  );
}

export default TopNavbar;
