// @flow
import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import {CURRENCY_OPTIONS, prettifyImpactNumber, setSentiment} from 'impact/services/constants';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';

type PropTypes = {
  sumDeltas: Number,
  factor: Number,
  currency: String,
  direction: String,
  spike: String,
  drop: String,
};

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: 6,
    width: 302,
    height: 116,
    border: 'solid 1px',
  },
  header: {
    fontSize: 18,
    fontWeight: 500,
  },
  impactValue: {
    fontSize: 30,
    fontWeight: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  impactIcon: {
    fontSize: 16,
    position: 'relative',
    top: 1,
    marginRight: 3,
  },
  txt: {
    textAlign: 'center',
    color: palette.gray['500'],
  },
}));

const ImpactSum = (props: PropTypes) => {
  const classes = useStyles();
  const [isSentimentPositive, setIsSentimentPositive] = useState(false);
  const currency = CURRENCY_OPTIONS[props.currency];

  useEffect(() => {
    const {direction, spike, drop} = props;
    setIsSentimentPositive(setSentiment(direction, spike, drop));
  }, [props.direction, props.spike, props.drop]);

  const style = {
    container: {
      background: isSentimentPositive
        ? 'linear-gradient(0deg, rgba(216,251,212,1) 0%, rgba(255,255,255,1) 100%)'
        : 'linear-gradient(0deg, rgba(251,213,212,1) 0%, rgba(255,255,255,1) 100%)',
      borderColor: isSentimentPositive ? palette.green['600'] : palette.tomato['500'],
    },
    header: {
      color: isSentimentPositive ? palette.green['600'] : palette.tomato['500'],
    },
    impactValue: {
      flexDirection: currency && currency.direction === 'rtl' ? 'row-reverse' : '',
      color: isSentimentPositive ? palette.green['600'] : palette.tomato['500'],
    },
    impactIcon: {
      color: isSentimentPositive ? palette.green['600'] : palette.tomato['500'],
    },
  };

  return (
    <Box p={1} className={classes.container} style={style.container}>
      <TypographyBox className={classes.header} style={style.header}>
        <i className={`icn-general16-impact ${classes.impactIcon}`} style={style.impactIcon} />
        <span>Impact</span>
      </TypographyBox>

      <Box className={classes.impactValue} style={style.impactValue}>
        <TypographyBox component="span" fontSize={16} mr={0.5}>
          {currency.symbol}
        </TypographyBox>
        <TypographyBox component="span">{prettifyImpactNumber(props.sumDeltas, props.factor)}</TypographyBox>
      </Box>

      <Box className={classes.txt}>
        {isSentimentPositive ? 'Gain for this anomaly so far' : 'Loss for this anomaly so far'}
      </Box>
    </Box>
  );
};

export default ImpactSum;
