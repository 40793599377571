// @flow
import React, {Fragment, useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {ThemeProvider} from '@material-ui/core/styles';
import moment from 'moment';
import {escape, isEmpty, isNil, omitBy} from 'lodash';
import {computeDecimal} from 'common/utils/numbers';
import formatNumber from 'common/utils/formatNumber';
import {getCorrectTimezoneName} from 'common/utils/dateService';
import {getMetricName, resolutionTypes} from 'metrics/services/metricsService';
import theme, {palette} from 'app/styles/theme';

const useStyles = makeStyles(() => ({
  tooltipWrapper: {
    maxWidth: 493,
    borderRadius: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    padding: 8,
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    fontSize: 12,
    color: palette.gray[500],
    fontWeight: 400,
  },
  measureWrapper: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 400,
    color: palette.gray[400],
    marginBottom: '4px',
  },
  propsWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 500,
  },
  propsListKey: {
    width: 80,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    flexShrink: 0,
    marginRight: 8,
    fontSize: 12,
    color: palette.gray[400],
    lineHeight: '16px',
    overflow: 'hidden',
  },
  propsListValue: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 56,
    maxWidth: 348,
    lineHeight: '16px',
    color: palette.mint[600],
    wordBreak: 'break-all',
  },
  whatText: {
    color: palette.purple[600],
    fontWeight: 500,
  },
  propertyWrapper: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 400,
    color: palette.gray[400],
  },
  propertyText: {
    color: palette.green[700],
    fontWeight: 500,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  anomalyPropTitle: {
    margin: '0 4px',
  },
  anomalyPropValue: {
    display: 'inline',
    color: palette.gray['500'],
  },
}));

const AnomalyTemplate = (props: {
  anomalyPoint: Object,
  type: string,
  tooltipConditions: Object,
  hideMeetIcons: boolean,
}) => {
  const classes = useStyles();
  if (!props.anomalyPoint) {
    return null;
  }
  const anoOptions = props.anomalyPoint.anomalyOptions;
  const hasSignificance = !isNil(anoOptions.significance) && anoOptions.significance >= 0;
  const hasDelta = !isEmpty(anoOptions.delta);

  const {significance, delta, duration} = props.tooltipConditions || {};

  const wrongSignificance = significance && anoOptions.significance < significance.minSignificance;

  const wrongDelta =
    delta && (anoOptions.deltaAbsolute < delta.deltaAbsolute || anoOptions.deltaPercentage < delta.deltaPercentage);
  const wrongDuration = duration && anoOptions.durationAsSeconds < duration.value;

  return (
    <ThemeProvider theme={theme}>
      <div style={{color: palette.gray['400'], fontWeight: 500, fontSize: 14}}>
        {props.type === 'anomalySecondary' && (
          <div style={{display: 'flex', justifyContent: 'center', marginBottom: 8, textAlign: 'center'}}>
            <div style={{width: 144, fontSize: 12}}>This Anomaly did not meet all the conditions</div>
          </div>
        )}
        {hasSignificance && (
          <div
            style={{
              color: wrongSignificance ? palette.red['500'] : palette.gray['400'],
            }}
            className={classes.flexCenter}
          >
            {!props.hideMeetIcons && (
              <React.Fragment>
                {wrongSignificance ? (
                  <i className="icon icn-general16-closea" />
                ) : (
                  <i className="icon icn-general16-checkmark" />
                )}
              </React.Fragment>
            )}
            <div className={classes.anomalyPropTitle}>Score:</div>
            <div className={classes.anomalyPropValue}>{Math.round(anoOptions.significance * 100)}</div>
          </div>
        )}
        {hasDelta && (
          <div
            style={{
              color: wrongDelta ? palette.red['500'] : palette.gray['400'],
            }}
            className={classes.flexCenter}
          >
            {!props.hideMeetIcons && (
              <React.Fragment>
                {wrongDelta ? (
                  <i className="icon icn-general16-closea" />
                ) : (
                  <i className="icon icn-general16-checkmark" />
                )}
              </React.Fragment>
            )}
            <div className={classes.anomalyPropTitle}>Delta:</div>
            <div className={classes.anomalyPropValue}>{anoOptions.delta} </div>
          </div>
        )}
        <div
          style={{
            color: wrongDuration ? palette.red['500'] : palette.gray['400'],
          }}
          className={classes.flexCenter}
        >
          {!props.hideMeetIcons && (
            <React.Fragment>
              {wrongDuration ? (
                <i className="icon icn-general16-closea" />
              ) : (
                <i className="icon icn-general16-checkmark" />
              )}
            </React.Fragment>
          )}
          <div className={classes.anomalyPropTitle}>Duration:</div>
          <div className={classes.anomalyPropValue}>{anoOptions.duration}</div>
        </div>
      </div>
    </ThemeProvider>
  );
};

const MetricNameTemplate = (props: {metric: Object}) => {
  const {metric} = props;
  if (metric.properties) {
    return (
      <div className="metric-name-tooltip" style={{boxShadow: 'none'}}>
        {metric.what && (
          <Fragment>
            Measure <span className="what-segment">{escape(metric.what)}</span>
            <div className="name-sep" />
          </Fragment>
        )}
        {metric.properties.concat(metric.tags).map((p) => (
          <div className="dimensions-list">
            {p.key}
            <span className="dimension-segment">{escape(p.value)}</span>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="metric-name-tooltip" style={{boxShadow: 'none'}}>
      {escape(getMetricName(metric))}
    </div>
  );
};
const getMetricUnits = (metric) => {
  if (!metric?.properties) return null;
  return (metric?.tags ? [...metric.properties, ...metric?.tags] : metric.properties)?.find((m) =>
    ['#unit', '#units', 'unit', 'units'].includes(m.key?.toLowerCase()),
  )?.value;
};
const PointValueTemplate = (props: {minPoint: Object}) => {
  const {minPoint} = props;
  const unit = getMetricUnits(minPoint.series.options.andtVal.metric);
  const unitPlacementLeft = ['$', '€', '₪', '¥', '£', '¥', '₹', '₩', '₱', '₴'].includes(unit);

  const percentVal = minPoint.percentage ? `${minPoint.percentage.toFixed(2)}% | ` : '';

  return (
    <div style={{display: 'flex', justifyContent: 'flex-start', margin: '8px 0'}}>
      <div
        style={{
          backgroundColor: minPoint.series.color,
          borderRadius: 10,
          lineHeight: 1,
          padding: '2px 8px',
          fontSize: 16,
          fontWeight: 'bold',
          color: palette.white[500],
        }}
      >
        <span>
          {`${unit && unitPlacementLeft ? unit : ''}${percentVal +
            formatNumber(parseFloat(minPoint.y.toFixed(computeDecimal(minPoint.y))), 3, ',', '.')}`}{' '}
        </span>
        {unit && !unitPlacementLeft ? <span style={{marginLeft: 2, fontSize: 12}}>{unit}</span> : ''}
        {/* old abbreviation abbrNum(minPoint.y, computeDecimal(minPoint.y)) */}
      </div>
    </div>
  );
};

const TotalTemplate = (props: {minPoint: Object}) => {
  return (
    <span>
      {`Total: ${formatNumber(
        parseFloat(props.minPoint.total.toFixed(computeDecimal(props.minPoint.total))),
        3,
        ',',
        '.',
      )}`}
    </span>
  );
};

const DateTemplate = (props: {date: Object, timeScale: String, timeZoneName: String}) => {
  const roolup = Object.values(resolutionTypes).find((a) => a.value2 === props.timeScale).value;
  const momentDate = moment(props.date);
  const timeZone = getCorrectTimezoneName(props.timeZoneName);

  if (roolup === 'weekly') {
    const weekFromDate = props.date + 60 * 60 * 24 * 7 * 1000;
    const sameMonth = momentDate.isSame(moment(weekFromDate), 'month');

    if (sameMonth) {
      return (
        <span>
          {' '}
          {momentDate.tz(timeZone).format('D')} ‒
          {moment(weekFromDate)
            .tz(timeZone)
            .format('D MMM')}{' '}
          ({timeZone})
        </span>
      );
    }
    return (
      <span>
        {momentDate.tz(timeZone).format('D MMM')} ‒
        {moment(weekFromDate)
          .tz(timeZone)
          .format('D MMM')}{' '}
        ({timeZone})
      </span>
    );
  }

  if (roolup === 'longlong') {
    return (
      <span>
        {momentDate.tz(timeZone).format('ddd, MMM D')} ({props.timeZoneName})
      </span>
    );
  }
  return <span>{momentDate.tz(timeZone).format('ddd, MMM D [@] h:mm A')}</span>;
};

const lastPropsKeys = ['target_type', 'mtype', 'func'];

const ExtendedData = ({metric}: {metric: Object}) => {
  const classes = useStyles();

  const metricProps = useMemo(() => metric.properties.concat(metric.tags), [metric]);

  const lastProps = useMemo(
    () =>
      lastPropsKeys
        .map((key) => {
          const prop = metricProps.find((item) => item.key === key);
          return (
            prop && {
              ...prop,
              isLast: true,
            }
          );
        })
        .filter((item) => item),
    [metricProps],
  );

  const propsList = useMemo(
    () => [...Object.values(omitBy(metricProps, (item) => lastPropsKeys.includes(item.key))), ...lastProps],
    [metricProps, lastProps],
  );

  return (
    <React.Fragment>
      {metric.what && (
        <div className={classes.measureWrapper}>
          <div className={classes.whatText}>{metric.what}</div>
        </div>
      )}

      {propsList.map((kv, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={classes.propsWrapper} key={`${kv.key}_${index}`}>
          <div className={classes.propsListKey}>{kv.key}</div>
          <div className={classes.propsListValue}>{kv.value}</div>
        </div>
      ))}
    </React.Fragment>
  );
};

const TooltipTemplate = (props: {
  minPoint: Object,
  date: Object,
  timeScale: String,
  timeZoneName: String,
  bucketStartTimeEnabled: Boolean,
  showMetricName: boolean,
  anomalyPoint: Object,
  showAnomalyData: boolean,
  tooltipConditions: Object,
  isExtendedTooltip: boolean,
  hideMeetIcons: boolean,
}) => {
  if (!props.minPoint.series) {
    return null;
  }
  const classes = useStyles();
  // const {color} = props.minPoint.series;
  const {metric, type} = props.minPoint.series.options.andtVal;
  const {tooltipConditions, isExtendedTooltip, hideMeetIcons} = props;

  return (
    <div className={classes.tooltipWrapper}>
      <DateTemplate
        date={props.date}
        timeScale={props.timeScale}
        timeZoneName={props.timeZoneName}
        bucketStartTimeEnabled={props.bucketStartTimeEnabled}
      />
      <span>
        <PointValueTemplate minPoint={props.minPoint} />
        {props.minPoint.total && props.minPoint.percentage && props.minPoint.percentage.toFixed(0) !== '100' && (
          <TotalTemplate minPoint={props.minPoint} />
        )}
        {props.showMetricName && <MetricNameTemplate metric={metric} />}
        {props.anomalyPoint && props.showAnomalyData && (
          <AnomalyTemplate
            anomalyPoint={props.anomalyPoint}
            type={type}
            tooltipConditions={tooltipConditions}
            hideMeetIcons={hideMeetIcons}
          />
        )}
      </span>
      {isExtendedTooltip && (
        <div style={{marginTop: 8}}>
          <ExtendedData metric={metric} />
        </div>
      )}
    </div>
  );
};

export default TooltipTemplate;
