// @flow
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import SmartTooltip from 'common/componentsV2/Tooltip';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import HolidayIcons from 'common/componentsV2/holiday/HolidayIcons';
import {
  getHolidayChristmas,
  getHolidayHalloween,
  getHolidayStPatrickDay,
  getClassNameHoliday,
} from 'profile/store/selectors';

const useStyles = makeStyles(() => ({
  modal: {
    minWidth: 'auto',
    maxHeight: 231,
  },
}));

const HolidayAlertItemAction = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const isHalloweenEnable = useSelector(getHolidayHalloween);
  const isChristmas = useSelector(getHolidayChristmas);
  const isStPatrickDayEnable = useSelector(getHolidayStPatrickDay);
  const classNameHoliday = useSelector(getClassNameHoliday);

  const getContent = () => {
    if (isHalloweenEnable) {
      return 'Spooky catch...';
    }
    if (isChristmas) {
      return 'Naughty catch...';
    }
    if (isStPatrickDayEnable) {
      return 'Lucky catch...';
    }
    return '';
  };

  return (
    <SmartTooltip
      placement="top"
      content={getContent()}
      delay={1000}
      extraTtClass={classNameHoliday}
      hideIfEqualTo={isOpen ? getContent() : null}
    >
      <div className="position_relative mr_1">
        <FormDdl
          popoverComponent={<HolidayIcons title="Modal" />}
          buttonComponent={
            <div onClick={() => setIsOpen(true)} className="alert-clickable-item">
              <HolidayIcons title="AlertItemIcon" />
            </div>
          }
          isOpen={isOpen}
          popoverContainerClassName={classes.modal}
          position="center"
          placement="top"
          transparent
        />
      </div>
    </SmartTooltip>
  );
};

export default HolidayAlertItemAction;
