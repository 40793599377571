import React from 'react';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {GenerateIcon} from '@pileus-cloud/anodot-frontend-common';

import './OptionComponentSimple.module.scss';

const OptionComponentSimple = ({data, isSelected, tooltip}: {data: Object, isSelected: boolean, tooltip: Object}) => (
  <div
    styleName={[
      'container',
      isSelected ? 'selected' : '',
      data.isDisabled ? 'disabled' : '',
      data.type === 'HEADER' ? 'header' : '',
    ].join(' ')}
    className="form-option idle-external-click"
  >
    <div styleName={`label ${tooltip ? 'tooltip' : ''}`}>
      {data.icon && <i className={`icon ${data.icon}`} styleName="icon" />}
      {data.faIcon && <GenerateIcon iconName={data.faIcon} styleName="icon" />}
      <Tooltip content={data.label || data.text} type={TYPES.SMALL}>
        <div automation-id={data.automationId} styleName="ellipsis">
          {data.label || data.text}
        </div>
      </Tooltip>
      {tooltip && (
        <Tooltip content={tooltip.text}>
          {tooltip.icon && <i styleName="tooltip-icon" className={`icon ${tooltip.icon}`} />}
        </Tooltip>
      )}
    </div>
    {isSelected && <i className="icon icn-general16-checkmark" />}
  </div>
);

export default OptionComponentSimple;
