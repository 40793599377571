import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'admin.cs-portal/store/selectors';
import {getUsersData, getUsersIsLoading} from 'admin.users/store/selectors';
import * as actions from 'admin.cs-portal/store/actions';
import {fetchUsers} from 'admin.users/store/actions';
import Button from 'common/componentsV2/Button';
import Modal, {SIZES as MODAL_SIZES} from 'common/componentsV2/modal/Modal';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';

import './MetricsActions.module.scss';
import SelectAndt, {
  THEME_NOT_HIGHLIGHTED,
  TYPE_NEW_NO_SEARCH,
  TYPE_NEW_SEARCH,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  // connect
  selectedCustomer: Object,
  flushMetrics: Function,
  isFlushLoading: Boolean,
  searchMetrics: Function,
  search: Object,
  isSearchLoading: Boolean,
  deleteMetrics: Function,
  delete_: Object,
  getMetricsEps: Function,
  eps: Object,
  isGetMetricsEpsLoading: Boolean,
  generateTemplates: Function,
  generateTemplatesSuccess: Boolean,
  usersData: Array,
  usersIsLoading: Boolean,
  isGenerateTemplatesLoading: Boolean,
  fetchUsers: Array,
  isFlushSuccess: Boolean,
};

const templatesOptions = [
  {
    value: 'LIKE_GAD',
    label: 'Google AdWords',
  },
  {
    value: 'LIKE_AWS_TRIAL',
    label: 'AWS Trial',
  },
  {
    value: 'LIKE_AWS_STARTER',
    label: 'AWS Starter',
  },
  {
    value: 'LIKE_AWS_PRO',
    label: 'AWS Pro',
  },
  {
    value: 'PAYMENTS',
    label: 'Payments',
  },
  {
    value: 'FUNNEL',
    label: 'Funnel',
  },
];

export default connect(
  (state) => ({
    selectedCustomer: selectors.getSelectedCustomer(state),
    flush: selectors.flushMetrics(state),
    isFlushLoading: selectors.getIsFlushLoading(state),
    isFlushSuccess: selectors.getIsFlushSuccess(state),
    search: selectors.searchMetrics(state),
    isSearchLoading: selectors.getIsSearchLoading(state),
    delete_: selectors.deleteMetrics(state),
    eps: selectors.getMetricsEps(state),
    isGetMetricsEpsLoading: selectors.getIsGetMetricsEpsLoading(state),
    isGenerateTemplatesLoading: selectors.getIsGenerateTemplatesLoading(state),
    generateTemplatesSuccess: selectors.getGenerateTemplatesSuccess(state),
    usersData: getUsersData(state),
    usersIsLoading: getUsersIsLoading(state),
  }),
  {
    flushMetrics: actions.flushMetrics,
    searchMetrics: actions.searchMetrics,
    deleteMetrics: actions.deleteMetrics,
    getMetricsEps: actions.getMetricsEps,
    generateTemplates: actions.generateTemplates,
    fetchUsers,
  },
)(
  class MetricsActions extends React.PureComponent {
    props: PropTypes;

    state = {
      showDeleteMetrics: false,
      queryParamsInput: {
        expression: [
          {
            type: 'property',
            key: 'what',
            value: 'impressions',
            isExact: 'true',
          },
        ],
      },
      hasSearchChanged: true,
      showFlush: false,
      showGetMetricsEps: false,
      showGenerateTemplates: false,
      selectedUser: null,
      selectedTemplate: null,
    };

    componentDidUpdate(prevProps) {
      if (prevProps.generateTemplatesSuccess !== this.props.generateTemplatesSuccess) {
        if (this.props.generateTemplatesSuccess) {
          this.handleClose('generateTemplates');
        } else if (this.props.generateTemplatesSuccess === false) {
          this.handleClose('generateTemplates');
        }
      }
    }

    handleOpen = (modal) => {
      switch (modal) {
        case 'delete-metrics':
          this.setState({showDeleteMetrics: true});
          break;
        case 'flush':
          this.setState({showFlush: true});
          break;
        case 'get-metrics-eps':
          this.setState({showGetMetricsEps: true});
          break;
        case 'generateTemplates':
          this.setState({showGenerateTemplates: true});
          this.props.fetchUsers();
          break;
        default:
          break;
      }
    };

    handleClose = (modal) => {
      switch (modal) {
        case 'delete-metrics':
          this.setState({showDeleteMetrics: false});
          break;
        case 'flush':
          this.setState({showFlush: false});
          break;
        case 'get-metrics-eps':
          this.setState({showGetMetricsEps: false});
          break;
        case 'generateTemplates':
          this.setState({showGenerateTemplates: false, selectedUser: null, selectedTemplate: null});
          break;
        default:
          break;
      }
    };

    submitFlush = () => {
      const {selectedCustomer} = this.props;
      if (selectedCustomer && selectedCustomer.value) {
        this.props.flushMetrics({
          _id: selectedCustomer.value,
        });
      }
    };

    submitGetMetricsEps = () => {
      const {selectedCustomer} = this.props;
      if (selectedCustomer && selectedCustomer.token) {
        this.props.getMetricsEps({
          customer_token: selectedCustomer.token,
        });
      }
    };

    submitSearchMetrics = () => {
      const {selectedCustomer} = this.props;
      if (selectedCustomer && selectedCustomer.token) {
        const json =
          typeof this.state.queryParamsInput === 'object'
            ? this.state.queryParamsInput
            : JSON.parse(this.state.queryParamsInput);
        this.props.searchMetrics({
          token: selectedCustomer.token,
          body: json,
        });
        this.setState({hasSearchChanged: false});
      }
    };

    updateQueryParamsInput = (event) => {
      this.setState({queryParamsInput: event.target.value});
      this.setState({hasSearchChanged: true});
    };

    submitDeleteMetrics = () => {
      const {selectedCustomer} = this.props;
      if (selectedCustomer && selectedCustomer.token) {
        const json =
          typeof this.state.queryParamsInput === 'object'
            ? this.state.queryParamsInput
            : JSON.parse(this.state.queryParamsInput);
        this.props.deleteMetrics({
          token: selectedCustomer.token,
          body: json,
        });
      }
    };

    renderEps = () => (
      <table styleName="epsTable">
        <thead>
          <tr>
            <td styleName="metric">Metric</td>
            <td styleName="eps">EPS</td>
          </tr>
        </thead>
        <tbody>{this.renderEpsBody()}</tbody>
      </table>
    );

    renderEpsBody = () =>
      this.props.eps.data.map((item, index) => (
        <tr key={item.metric} style={{backgroundColor: index % 2 === 0 ? palette.gray[200] : palette.white[500]}}>
          <td styleName="metric">{item.metric}</td>
          <td styleName="eps">{Number(item.eps).toFixed(2)}</td>
        </tr>
      ));

    selectUser = (user) => {
      this.setState({selectedUser: user});
    };

    selectTemplate = (template) => {
      this.setState({selectedTemplate: template});
    };

    normalizeUsers = (users) => {
      const activeCustomerUsers = users.filter(
        (user) => !user.disabled && user.ownerOrganization === this.props.selectedCustomer.value,
      );
      return activeCustomerUsers.map((user) => {
        const isAdmin = user.roles.some((i) => i === 'anodot-admin');
        return {
          value: user._id,
          label: `${user.email}${isAdmin ? ' (Admin)' : ''}`,
        };
      });
    };

    generateTemplates = () => {
      this.props.generateTemplates({
        customerId: this.props.selectedCustomer.value,
        userId: this.state.selectedUser.value,
        selfServicePackage: this.state.selectedTemplate.value,
      });
    };

    render() {
      const {delete_, selectedCustomer, usersData, usersIsLoading, isGenerateTemplatesLoading} = this.props;
      const {
        showGenerateTemplates,
        showDeleteMetrics,
        showFlush,
        showGetMetricsEps,
        selectedUser,
        selectedTemplate,
      } = this.state;
      const users = this.normalizeUsers(usersData);
      const json =
        typeof this.state.queryParamsInput === 'object'
          ? JSON.stringify(this.state.queryParamsInput, null, 2)
          : this.state.queryParamsInput;
      return (
        <div styleName="container">
          <Button
            text="Delete Metrics"
            colorSchema={palette.blue[500]}
            fixedWidth={173}
            onClick={() => this.handleOpen('delete-metrics')}
          />

          <Button
            text="Flush Metrics"
            colorSchema={palette.blue[500]}
            fixedWidth={173}
            onClick={() => this.handleOpen('flush')}
          />

          <Button
            text="Metrics EPS"
            colorSchema={palette.blue[500]}
            fixedWidth={173}
            onClick={() => this.handleOpen('get-metrics-eps')}
          />

          <Button
            text="Generate Template"
            colorSchema={palette.blue[500]}
            fixedWidth={173}
            onClick={() => this.handleOpen('generateTemplates')}
          />

          <Modal
            onClose={() => this.handleClose('generateTemplates')}
            isOpen={showGenerateTemplates}
            backdrop="static"
            size={MODAL_SIZES.MEDIUM}
            isNotCentered
            isCloseButtonHidden
          >
            <h2 styleName="title">
              Generate Templates: <span>{selectedCustomer.label}</span>
            </h2>
            <div styleName="generate-templates-wrapper">
              <div styleName="generate-templates-selections">
                <SelectAndt
                  type={TYPE_NEW_SEARCH}
                  theme={THEME_NOT_HIGHLIGHTED}
                  optionHeight={40}
                  menuWidth={475}
                  buttonWidth={325}
                  className="andt-dropdown"
                  options={users}
                  placeholder={usersIsLoading ? 'Loading Users...' : 'Select User'}
                  value={selectedUser}
                  onChange={this.selectUser}
                  automationId="selectUserForGenerateTemplates"
                />

                <SelectAndt
                  type={TYPE_NEW_NO_SEARCH}
                  theme={THEME_NOT_HIGHLIGHTED}
                  optionHeight={40}
                  menuWidth={175}
                  buttonWidth={175}
                  className="andt-dropdown"
                  options={templatesOptions}
                  placeholder="Select Templates"
                  value={selectedTemplate}
                  onChange={this.selectTemplate}
                  automationId="selectTemplateForGenerateTemplates"
                />
              </div>
              <div styleName="generate-templates-submit">
                <Button
                  isLoading={isGenerateTemplatesLoading}
                  spinnerColor={palette.azure[500]}
                  isDisabled={!selectedUser || !selectedTemplate || isGenerateTemplatesLoading}
                  text="Generate"
                  colorSchema={palette.blue[500]}
                  fixedWidth={135}
                  onClick={this.generateTemplates}
                />
              </div>
            </div>
          </Modal>

          <Modal
            onClose={() => this.handleClose('delete-metrics')}
            isOpen={showDeleteMetrics}
            backdrop="static"
            size={MODAL_SIZES.SMALL}
            isNotCentered
            isCloseButtonHidden
          >
            <div styleName="delete-metrics-wrapper">
              <h2 styleName="title">Delete Metrics</h2>
              <p>Expression</p>
              <textarea rows="15" cols="60" defaultValue={json} onChange={this.updateQueryParamsInput} />

              <div styleName="submit-area">
                <Button
                  isLoading={this.props.search && this.props.search.isLoading}
                  text="Check"
                  colorSchema={palette.gray[500]}
                  onClick={this.submitSearchMetrics}
                  fixedWidth={125}
                  spinnerColor={palette.white[500]}
                />

                <div styleName="result-wrapper">
                  {this.props.search && this.props.search.data && Number.isInteger(this.props.search.data.size) ? (
                    <table>
                      <thead>
                        <tr style={{borderBottom: '1px solid gray', fontWeight: 'bold'}}>
                          <td>Metric Type</td>
                          <td style={{textAlign: 'right'}}>Metrics to be Deleted</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Normal metrics:</td>
                          <td style={{textAlign: 'right'}}>{this.props.search.data.size}</td>
                        </tr>
                        <tr>
                          <td>Composite metrics:</td>
                          <td style={{textAlign: 'right'}}>{this.props.search.data.compositeCount}</td>
                        </tr>
                      </tbody>
                    </table>
                  ) : null}
                </div>

                <Button
                  fixedWidth={125}
                  text="Submit"
                  colorSchema={palette.blue[500]}
                  onClick={this.submitDeleteMetrics}
                  isDisabled={this.state.hasSearchChanged || this.props.isSearchLoading}
                />
              </div>

              <div>
                {delete_ && delete_.isLoading ? <Spinner color={palette.gray[500]} size={SIZES.SMALL_30} /> : null}
                {delete_ &&
                delete_.data &&
                !delete_.isLoading &&
                delete_.data.validation &&
                delete_.data.validation.passed ? (
                  <p>Delete request was successful.</p>
                ) : null}
                {delete_ && delete_.data && delete_.data.validation && !delete_.data.validation.passed ? (
                  <p>There was an error processing the delete request.</p>
                ) : null}
              </div>
            </div>
          </Modal>

          <Modal
            onClose={() => this.handleClose('flush')}
            isOpen={showFlush}
            backdrop="static"
            size={MODAL_SIZES.SMALL}
            isNotCentered
            isCloseButtonHidden
          >
            <h2 styleName="title">Flush</h2>
            <div styleName="flushInfoContainer">
              <Button
                text="Submit Flush"
                onClick={this.submitFlush}
                colorSchema={palette.blue[500]}
                isDisabled={this.props.isFlushLoading}
                fixedWidth={200}
              />
              {this.props.isFlushLoading && <Spinner color={palette.gray[500]} size={SIZES.SMALL_30} />}
              {!this.props.isFlushLoading && this.props.isFlushSuccess && <b>Success</b>}
            </div>
          </Modal>

          <Modal
            onClose={() => this.handleClose('get-metrics-eps')}
            isOpen={showGetMetricsEps}
            backdrop="static"
            size={MODAL_SIZES.LARGE}
            isNotCentered
            isCloseButtonHidden
          >
            <h2 styleName="title">Get Metrics EPS</h2>
            <div styleName="getMetricsEpsContainer">
              <div styleName="getMetricsEpsContainerHeader">
                <Button
                  text="Get Metrics EPS"
                  onClick={this.submitGetMetricsEps}
                  colorSchema={palette.blue[500]}
                  isDisabled={this.props.isGetMetricsEpsLoading}
                  fixedWidth={200}
                />
                {this.props.isGetMetricsEpsLoading ? <Spinner color={palette.gray[500]} size={SIZES.SMALL_30} /> : null}
              </div>
              {this.props.eps && this.props.eps.data ? (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {this.renderEps()}
                </div>
              ) : null}
            </div>
          </Modal>
        </div>
      );
    }
  },
);
