// @flow
import React, {useCallback, useMemo, useRef, useState} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import {makeStyles} from '@material-ui/core/styles';
import {useRouteMatch} from 'react-router-dom';
import {get, keyBy} from 'lodash';
import {StringParam, useQueryParam} from 'use-query-params';
import WidthProvider from 'dashboards/components/WidthProvider';
import {Responsive} from 'react-grid-layout';
import FiltersField from 'dashboards/components/filters/FiltersField';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import BackgroundGrid from 'dashboards/components/BackgroundGrid';
import {visibilityOffset} from 'dashboards/components/DashboardsMain';
import {useField} from 'react-final-form';
import GraphTileContainer from 'dashboards/components/metricTiles/GraphTileContainer';
import StatTileContainer from 'dashboards/components/metricTiles/StatTileContainer';
import TextTileContainer from 'dashboards/components/metricTiles/TextTileContainer';
import StartByAddingATile from 'dashboards/components/metricTiles/StartByAddingATile';
import EmptyDashboard from 'dashboards/components/metricTiles/EmptyDashboard';
import AnomaliesTileContainer from 'dashboards/components/metricTiles/anomaliesTile/TileContainer';
import {useSelector} from 'react-redux';
import {STAT_TILES_VALUES} from './modals/statTile/StatTileSettings';
import {getDashboardAnomalyTileEnabled} from '../../profile/store/selectors';

type PropsType = {
  history: Object,
  invitationId: string,
  dashboardData: Object,
  isFullSize: boolean,
  tileDateRangeRef: Object,
  tileFiltersRef: Object,
  returnToDashboardPage: Function,
  matchUrl: string,
  handleSaveEdit: Function,
  setSettingsModalOpened: Function,
  isLoading: boolean,
  setEditableMode: Function,
  isOwnerUser: boolean,
  tiles: Array<Object>,
  actualLayout: Array<Object>,
  values: Object,
  tileId: string,
  mouseMoveHandler: Function,
  isResize: boolean,
  defaultProps: Object,
  onLayoutChange: Function,
  layouts: Array<Object>,
  setResize: Function,
  gridRef: Object,
  handleFullSize: Function,
  computedTileOptions: Array<Object>,
  onTileAction: Function,
};

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const defaultProps = {
  className: 'layout',
  rowHeight: 60,
  isDraggable: false,
  isResizable: false,
  margin: [12, 12],
  breakpoints: {
    lg: 1175,
  },
};

const scrollBoxCss = {overflowY: 'auto', overflowX: 'hidden'};

const useStyles = makeStyles({
  shadowBox: {
    height: '4px',
    boxShadow: 'rgb(0 0 0 / 17%) 0px 3px 4px 0px',
    marginLeft: '-12px',
    marginRight: '-12px',
    flexShrink: 0,
    zIndex: 2,
    opacity: ({isScrolled}) => (isScrolled ? 1 : 0),
    transition: '400ms',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  gridWrapper: {
    opacity: ({isEditableMode}) => (isEditableMode ? 0.8 : 1),
    marginLeft: -12,
  },
});

const DashboardBody = ({
  isOwnerUser,
  dashboardData,
  mouseMoveHandler,
  onLayoutChange,
  layouts,
  gridRef,
  tiles,
  actualLayout,
}: PropsType) => {
  const [invitationId] = useQueryParam('invitationId', StringParam);
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollableArea = useRef();
  const innerElementRef = useRef();
  const isAnomalyTileEnabled = useSelector(getDashboardAnomalyTileEnabled);

  const {
    input: {value: valueFilters},
  } = useField('filters');
  const {
    input: {value: isEditableMode},
  } = useField('isEditableMode');
  const {
    input: {value: isResize, onChange: setResize},
  } = useField('isResize');

  const onResizeStop = useCallback(() => setResize(!isResize), [isResize]);
  const onResize = (layout, oldItem, layoutItem) => {
    const isGaugeTile =
      get(tiles[layoutItem.i], 'andtGauge.displayProperties.byTreeExp[0].options.andtGauge.mainValue.tileType') ===
      STAT_TILES_VALUES.GAUGE;
    const isPieOrDonutTile = ['pie', 'donut'].includes(
      get(tiles[layoutItem.i], 'andtGauge.displayProperties.byTreeExp[0].options.andtGauge.mainValue.tileType'),
    );
    if (isGaugeTile || isPieOrDonutTile) {
      // Force tile to be one of the options: 2X2, 2X3, 2X4, 3X3, 4X4
      const layoutItemRef = layoutItem;
      if (layoutItemRef.w >= 2 && layoutItemRef.h === 3) {
        layoutItemRef.w = 3;
        layoutItemRef.minW = 3;
      } else if (layoutItemRef.w >= 2 && layoutItemRef.h > 3) {
        layoutItemRef.w = 4;
        layoutItemRef.minW = 4;
      } else {
        layoutItemRef.minW = 2;
      }
    }
  };
  const tilesMap = useMemo(() => keyBy(get(dashboardData, 'tiles', []), 'id'), [dashboardData]);
  const {url: matchUrl} = useRouteMatch('/dashboards/:id');

  const handleScroll = useCallback(() => {
    if (scrollableArea.current.scrollTop > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }, []);

  const classes = useStyles({isScrolled, isEditableMode});

  if (dashboardData.tiles.length === 0) {
    if (isOwnerUser) {
      return <StartByAddingATile matchUrl={matchUrl} />;
    }
    return <EmptyDashboard />;
  }

  return (
    <div className={classes.wrapper}>
      <div className="mt_1-5 flexShrink_0">
        {(isOwnerUser || valueFilters?.length > 0) && (
          <FiltersField isApplyMode={dashboardData.mode === 'apply'} isEditable={isEditableMode && !invitationId} />
        )}
      </div>
      <div className={classes.shadowBox} />
      <TinyScrollBox
        ref={scrollableArea}
        css={scrollBoxCss}
        flexGrow={1}
        position="relative"
        pb={isEditableMode ? 18 : 0}
        pr={1}
        mr={-1}
        pt={1.5}
        onScroll={handleScroll}
        className={classes.gridWrapper}
      >
        {isEditableMode && (
          <BackgroundGrid
            isResize={isResize}
            heightGrid={(innerElementRef.current && innerElementRef.current.clientHeight) || 0}
            rowHeight={get(gridRef, 'current.props.rowHeight', 0)}
          />
        )}
        <ResponsiveReactGridLayout
          {...defaultProps}
          onLayoutChange={onLayoutChange}
          layouts={layouts}
          onResize={onResize}
          onResizeStop={onResizeStop}
          onWidthChange={onResizeStop}
          measureBeforeMount
          useCSSTransforms={false}
          compactType="vertical"
          preventCollision={false}
          isDraggable={isEditableMode}
          isResizable={isEditableMode}
          gridRef={gridRef}
          innerRef={innerElementRef}
          draggableHandle=".dragHandlerClassName"
        >
          {dashboardData.tiles &&
            layouts.lg.map((l) => {
              if (tilesMap[l.i].lineChart) {
                return (
                  <div className="display_flex flexDirection_column" key={l.i}>
                    <VisibilitySensor key={l.i} partialVisibility offset={visibilityOffset}>
                      {({isVisible}) => (
                        <GraphTileContainer
                          isAnonymous={!!invitationId}
                          onMouseMove={mouseMoveHandler}
                          tileId={l.i}
                          tileData={tilesMap[l.i]}
                          isOwnerUser={isOwnerUser}
                          // eliminate dashboard data from here
                          dashboardData={dashboardData}
                          matchUrl={matchUrl}
                          isVisible={isVisible}
                        />
                      )}
                    </VisibilitySensor>
                  </div>
                );
              }
              if (tilesMap[l.i].tileType === 'anomaliesList' && isAnomalyTileEnabled) {
                return (
                  <div className="display_flex flexDirection_column" key={l.i}>
                    <VisibilitySensor key={l.i} partialVisibility offset={visibilityOffset}>
                      {({isVisible}) => (
                        <AnomaliesTileContainer
                          isAnonymous={!!invitationId}
                          tileId={l.i}
                          tileData={tilesMap[l.i]}
                          isOwnerUser={isOwnerUser}
                          // eliminate dashboard data from here
                          dashboardData={dashboardData}
                          matchUrl={matchUrl}
                          isVisible={isVisible}
                          actualTileLayout={actualLayout.find((tileLayout) => tileLayout.i === l.i)}
                        />
                      )}
                    </VisibilitySensor>
                  </div>
                );
              }
              if (tilesMap[l.i].andtGauge) {
                return (
                  <div className="display_flex flexDirection_column" key={l.i}>
                    <VisibilitySensor key={l.i} partialVisibility offset={visibilityOffset}>
                      {({isVisible}) => (
                        <StatTileContainer
                          isAnonymous={!!invitationId}
                          tileId={l.i}
                          tileData={tilesMap[l.i]}
                          isOwnerUser={isOwnerUser}
                          // eliminate dashboard data from here
                          dashboardData={dashboardData}
                          matchUrl={matchUrl}
                          isVisible={isVisible}
                          actualTileLayout={actualLayout.find((tileLayout) => tileLayout.i === l.i)}
                        />
                      )}
                    </VisibilitySensor>
                  </div>
                );
              }
              if (tilesMap[l.i].tileType === 'text' || tilesMap[l.i].type === 'title') {
                return (
                  <div className="display_flex flexDirection_column" key={l.i}>
                    <TextTileContainer
                      isAnonymous={!!invitationId}
                      tileId={l.i}
                      tileData={tilesMap[l.i]}
                      isOwnerUser={isOwnerUser}
                      // eliminate dashboard data from here
                      dashboardData={dashboardData}
                      matchUrl={matchUrl}
                    />
                  </div>
                );
              }
              return <React.Fragment key={l.i} />;
            })}
        </ResponsiveReactGridLayout>
      </TinyScrollBox>
    </div>
  );
};

export default React.memo(DashboardBody);
