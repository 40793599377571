// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import ConveyorBelt from 'common/componentsV2/ConveyorBelt';
import {
  fetchGroups,
  cleanFetchGroups,
  createGroup,
  toggleGroupsModal,
  toggleDeleteGroupModal,
  setGroupSectionIsOpen,
} from 'admin.users/store/actions';
import {getMeOwnerOrganization, isAnodot} from 'profile/store/selectors';
import * as selectors from 'admin.users/store/selectors';
import {inFiltersOnlyOneGroupSelected} from 'admin.users/store/filterSelector';
import {DEFAULT_QUERY_PARAMS} from 'admin.users/services/usersAndGroupsService';
import AddGroupComponent from './AddGroupComponent';
import GroupsItem from './GroupsItem';
import DeleteGroupModal from './DeleteGroupModal';
import './UserGroupsSections.module.scss';

type PropTypes = {
  setQueryParams: Function,

  // connect
  isMeAnodot: boolean,
  isPanelOpen: boolean,
  groups: Array,
  ownerOrganization: String,
  onlyGroupSelected: String,
  fetchGroups: Function,
  cleanFetchGroups: Function,
  createGroup: Function,
  toggleGroupsModal: Function,
  toggleDeleteGroupModal: Function,
  setGroupSectionIsOpen: Function,
};

export default connect(
  (state) => ({
    isMeAnodot: isAnodot(state),
    isPanelOpen: selectors.getIsGroupSectionOpen(state),
    ownerOrganization: getMeOwnerOrganization(state),
    groups: selectors.getGroupSectionGroupList(state),
    onlyGroupSelected: inFiltersOnlyOneGroupSelected(state),
  }),
  {
    fetchGroups,
    cleanFetchGroups,
    createGroup,
    toggleGroupsModal,
    toggleDeleteGroupModal,
    setGroupSectionIsOpen,
  },
)(
  class GroupsSection extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      if (this.props.isMeAnodot) {
        this.props.cleanFetchGroups();
      } else {
        this.props.fetchGroups({ownerOrganization: this.props.ownerOrganization});
      }
    }

    rootStyle = [];

    bodyPanelStyle = [];

    headerClicked = () => {
      this.props.setGroupSectionIsOpen(!this.props.isPanelOpen);
    };

    addGroupClicked = () => {
      this.props.toggleGroupsModal({isOpen: true});
    };

    groupItemClicked = (groupId) => {
      const {onlyGroupSelected} = this.props;

      if (onlyGroupSelected && onlyGroupSelected === groupId) {
        this.props.setQueryParams({
          ...DEFAULT_QUERY_PARAMS,
        });
      } else {
        this.props.setQueryParams({
          ...DEFAULT_QUERY_PARAMS,
          groups: groupId,
        });
      }
    };

    menuItemClicked = (item) => {
      switch (item.action) {
        case 'duplicate': {
          const itemToDuplicate = this.props.groups.find((group) => group.id === item.groupId);
          const newGroup = {
            name: `Copy of ${itemToDuplicate.name} - ${new Date().getTime().toString()}`,
            users: itemToDuplicate.users.map((u) => u.id),
            colorSchema: itemToDuplicate.colorSchema,
          };
          this.props.createGroup({ownerOrganization: this.props.ownerOrganization, group: newGroup});
          break;
        }
        case 'edit': {
          this.props.toggleGroupsModal({isOpen: true, groupId: item.groupId});
          break;
        }
        case 'delete': {
          this.props.toggleDeleteGroupModal({isOpen: true, groupId: item.groupId});
          break;
        }
        default:
          break;
      }
    };

    renderGroupItems = () => {
      const {groups, onlyGroupSelected} = this.props;

      if (groups.length === 0) {
        return [<AddGroupComponent key="add-group-component" onClick={this.addGroupClicked} />];
      }

      const res = groups.map((group) => (
        <GroupsItem
          onClick={this.groupItemClicked}
          onMenuItemClick={this.menuItemClicked}
          title={group.name}
          colorSchema={group.colorSchema}
          users={group.users}
          groupId={group.id}
          isHighlighted={onlyGroupSelected ? group.id === onlyGroupSelected : true}
          key={`group-item-${group.id}`}
        />
      ));
      res.push(<AddGroupComponent key="add-group-component" onClick={this.addGroupClicked} />);
      return res;
    };

    render() {
      const {groups, isMeAnodot, isPanelOpen} = this.props;

      if (isMeAnodot) {
        return null;
      }

      this.rootStyle = ['root', !isPanelOpen ? 'short' : ''];
      this.bodyPanelStyle = ['panel', !isPanelOpen ? 'hide-panel' : ''];

      return (
        <div styleName={this.rootStyle.join(' ')}>
          <div styleName="header">
            <span styleName="clickable-header" role="presentation" onClick={this.headerClicked}>
              <span>Groups</span>
              <span styleName="counter">{`(${groups.length})`}</span>
              <span styleName="icon" className={isPanelOpen ? 'icn-arrow16-chevrondown' : 'icn-arrow16-chevronright'} />
            </span>
          </div>
          <div styleName={this.bodyPanelStyle.join(' ')}>
            <ConveyorBelt itemHeight={90} itemWidth={250}>
              {this.renderGroupItems()}
            </ConveyorBelt>
          </div>
          <DeleteGroupModal />
        </div>
      );
    }
  },
);
