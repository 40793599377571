// @flow
import React from 'react';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

import './Ga4Template.module.scss';

type PropTypes = {
  filter: Object,
  onChange: Function,
  onRemove: Function,
  index: number,
  disabled: boolean,
  dimensions: Array,
};

export default class GA4FilterItem extends React.PureComponent {
  props: PropTypes;

  render() {
    const {filter, onChange, onRemove, index, disabled, dimensions} = this.props;

    const dimensionsChanged = (d) => {
      onChange({
        value: d.sourceColumn,
        target: 'name',
        index,
      });
    };

    const inputValueChanged = (e) => {
      onChange({
        value: e.target.value,
        target: 'values',
        index,
      });
    };

    const removeItem = () => {
      onRemove(index);
    };

    const selectedDimension = dimensions.find((dim) => dim.sourceColumn === filter.name) || null;

    return (
      <div styleName="advance-root ga4-select-block">
        <i styleName="remove-item" className="icon icn-icon-table-delete" onClick={removeItem} />
        <div styleName="dim-wrapper">
          <SelectAndt
            type={TYPE_NO_SEARCH}
            theme={THEME_LIGHT}
            onChange={dimensionsChanged}
            options={dimensions}
            value={selectedDimension}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.sourceColumn}
            isCreatable
            automationId="ga4FilterDimension"
            optionHeight={40}
            placeholder="Choose Dimensions"
          />
        </div>
        <div styleName="txt-wrapper">Includes</div>
        <div styleName="val-wrapper">
          <input
            type="text"
            disabled={disabled}
            onChange={inputValueChanged}
            placeholder="separate multiple values with commas"
            value={filter.values || ''}
            automation-id="ga4FilterValues"
          />
        </div>
        <div styleName="seperator" />
      </div>
    );
  }
}
