// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Button, {
  COLORS as BUTTON_COLORS,
  HEIGHTS as BUTTON_HEIGHTS,
  WIDTHS as BUTTON_WIDTHS,
} from 'common/componentsV2/Button';
import {
  isAgent,
  isAlowedEditRunning,
  isStreamEditable,
  isStreamFailed,
  isStreamFileUpload,
  isStreamFinished,
  isStreamRunning,
  isAgentMetrics30,
  isApi30schema,
} from 'bc/services/dataStreamService';
import {
  duplicateStream,
  editDataStream,
  editLiveStream,
  pauseResumeDataStream,
  setSelectedDataStream,
  editEventStream,
} from 'bc/store/actions';
import {initExpressionTreeModel} from 'metrics/store/actions';
import {getNewStreamsCount} from 'bc/store/selectors';
import * as commonSelectors from 'profile/store/selectors';
import Tooltip from 'common/componentsV2/Tooltip';
import SelectAndt, {
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {
  getEmptyExpression,
  getEmptyTree,
  getSearchObjectOriginExpression,
  getSearchObjectPropertyExpression,
  getTreesDisplayPropertiesFromCompositeDefinition,
  gotoState,
  setStateModel,
} from 'common/utils/angularServices';
import {cleanupSpecialChars} from 'metrics/services/metricsService';
import {isViewStreamAllowed} from 'bc/services/dataManagerService';
import {getUniqueId} from 'common/utils/guid';

import './DataManagerStreamItemActions.module.scss';
import {ENTITY_TYPE_VALUE, PARAMS_NAME} from 'admin.activityLog/services/activityLogService';
import {rangeTypes} from 'common/utils/dateRangeService';
import {STREAMS_FAMILY_TYPES, STREAM_STATE_OPTIONS} from 'dataCollectors/services/dataCollectorsService';
import MoreActionEventStream from 'dataCollectors/components/moreActionEventStream/MoreActionEventStream';

type PropTypes = {
  stream: Object,
  isBcReadOnly: Boolean,
  openStreamViewModal: Function,
  openConfirmationModal: Function,
  openLiveStreamEditModal: Function,
  openStreamsLimitModal: Function,
  duplicateStream: Function,
  editLiveStream: Function,
  onActionItemSelected: Function,

  // connect
  newStreamsCount: Number,
  incompleteSteamsLimit: Number,
  isNewMetricExplorer: Boolean,
  editDataStream: Function,
  editEventStream: Function,
  pauseResumeDataStream: Function,
  setSelectedDataStream: Function,
  initExpressionTreeMode: Function,
  initExpressionTreeModel: Function,
  isActivityLogEnabled: Boolean,
  isCustomerAdmin: Boolean,
};

export default connect(
  (state) => ({
    newStreamsCount: getNewStreamsCount(state),
    isNewMetricExplorer: commonSelectors.getIsNewMetricExplorer(state),
    incompleteSteamsLimit: commonSelectors.getIncompleteSteamsLimit(state),
    isActivityLogEnabled: commonSelectors.getActivityLogEnabled(state),
    isCustomerAdmin: commonSelectors.isCustomerAdmin(state),
  }),
  {
    editDataStream,
    editEventStream,
    pauseResumeDataStream,
    duplicateStream,
    editLiveStream,
    setSelectedDataStream,
    initExpressionTreeModel,
  },
)(
  class DataManagerStreamItemActions extends PureComponent {
    props: PropTypes;

    onEditClick = () => {
      this.props.editDataStream(this.props.stream.id);
    };

    onEditLiveClick = () => {
      this.props.editLiveStream({id: this.props.stream.id});
    };

    openStreamViewModalClick = () => {
      this.props.setSelectedDataStream(this.props.stream.id);
      this.props.openStreamViewModal();
    };

    openConfirmationModalClick = () => {
      this.props.setSelectedDataStream(this.props.stream.id);
      this.props.openConfirmationModal();
    };

    openLiveStreamEditModalClick = () => {
      this.props.setSelectedDataStream(this.props.stream.id);
      this.props.openLiveStreamEditModal();
    };

    openStreamsLimitModalClick = () => {
      this.props.setSelectedDataStream(this.props.stream.id);
      this.props.openStreamsLimitModal();
    };

    showMetrics = () => {
      // eslint-disable-next-line no-shadow
      const {stream, initExpressionTreeModel, isNewMetricExplorer} = this.props;
      if (isNewMetricExplorer) {
        const exp = [];
        if (isAgent(stream.family)) {
          exp.push(
            {
              key: '#pipeline_id',
              type: 'property',
              value: stream.id,
              isExact: true,
            },
            {
              key: '#pipeline_type',
              type: 'property',
              value: stream.type,
              isExact: true,
            },
          );
        } else {
          exp.push({
            key: 'originId',
            type: 'origin',
            originType: 'Stream',
            value: stream.id,
            isExact: true,
          });
        }
        const expression = {
          searchObject: {
            expression: exp,
          },
          children: [],
          type: 'metric',
          id: getUniqueId(),
          uiIndex: 0,
        };
        initExpressionTreeModel({
          expressionTree: {
            root: expression,
          },
        });
        window.location.replace('/#!/r/metrics-explorer');
      } else {
        const whats = [];
        const serverTreesModel = {};
        const node = getEmptyExpression();
        const aggregation = {
          counter: 'Sum',
          gauge: 'Avg',
        };
        let groupByAggregation = null;
        if (
          this.isStreamNameClickable(stream.state, stream.status) ||
          isAgent(stream.family) ||
          isApi30schema(stream.family)
        ) {
          stream.schema.columns.forEach((col) => {
            if (col.type === 'metric') {
              whats.push(cleanupSpecialChars(col.name));
              groupByAggregation = !groupByAggregation ? aggregation[col.targetType] : groupByAggregation;
            }
          });
          if (isAgent(stream.family) && !isAgentMetrics30(stream.config)) {
            node.searchObject.expression.push(getSearchObjectPropertyExpression('#pipeline_id', stream.id, true));
            node.searchObject.expression.push(getSearchObjectPropertyExpression('#pipeline_type', stream.type, true));
          } else {
            node.searchObject.expression.push(getSearchObjectOriginExpression('@Stream', stream.id, true));
          }
          const tree = getEmptyTree(null, {}, null, null, node, node);
          serverTreesModel[tree.id] = tree;

          setStateModel('metricComposite', {
            serverTreesModel,
            shouldValidateOnLoad: true,
            treesDisplayProperties: getTreesDisplayPropertiesFromCompositeDefinition(tree),
          });
          gotoState('metricComposite');
        }
      }
    };

    isStreamNameClickable = (state, status) => status === 'ok' && (state === 'running' || state === 'finished');

    actionItemSelected = (eventKey) => {
      this.props.onActionItemSelected(eventKey.value);
      switch (eventKey.value) {
        case 'delete':
          this.openConfirmationModalClick();
          break;
        case 'show-metrics': //
          this.showMetrics();
          break;
        case 'duplicate':
          if (this.props.newStreamsCount >= this.props.incompleteSteamsLimit) {
            this.openStreamsLimitModalClick();
            return null;
          }
          return this.props.duplicateStream({id: this.props.stream.id});
        case 'pause':
        case 'resume':
          return this.props.pauseResumeDataStream({id: this.props.stream.id, action: eventKey.value});
        case 'activity':
          return window.open(
            `/#!/r/admin/activity-log?${[PARAMS_NAME.ENTITY_TYPE]}=${ENTITY_TYPE_VALUE.METRIC_STREAMS}&${[
              PARAMS_NAME.ENTITY_ID,
            ]}=${this.props.stream.id}&${PARAMS_NAME.CONST_RANGE}=${rangeTypes.m3.value}`,
          );
        default:
      }
      return null;
    };

    getDropdownOptions = () => {
      const ret = [];
      const {isBcReadOnly, stream} = this.props;

      if (isAgent(stream.family) || isApi30schema(stream.family)) {
        ret.push({label: 'Show Metrics', value: 'show-metrics'}, {label: 'Delete', value: 'delete'});
      } else if (isBcReadOnly && this.isStreamNameClickable(stream.state, stream.status)) {
        ret.push({label: 'Show Metrics', value: 'show-metrics'});
      } else {
        if (!isStreamEditable(stream.state) && this.isStreamNameClickable(stream.state, stream.status)) {
          ret.push({label: 'Show Metrics', value: 'show-metrics'});
        }
        if (isStreamFailed(stream.status)) {
          ret.push({label: 'Resume', value: 'resume'});
        } else if (!(isStreamEditable(stream.state) || isStreamFinished(stream.state))) {
          ret.push({
            label: stream.paused ? 'Resume' : 'Pause',
            value: stream.paused ? 'resume' : 'pause',
          });
        }
        if (!isStreamFailed(stream.status)) {
          ret.push({label: 'Duplicate', value: 'duplicate'});
        }
        if (this.props.isActivityLogEnabled && this.props.isCustomerAdmin) {
          ret.push({label: 'View Activity Log', value: 'activity'});
        }
        ret.push({label: 'Delete', value: 'delete'});
      }
      return ret;
    };

    getEditButton = () => {
      const {stream} = this.props;

      if (!this.props.isCustomerAdmin) {
        return null;
      }

      if (isStreamEditable(stream.state)) {
        return (
          <div styleName="btn">
            <Tooltip content="Edit" delay={300}>
              <Button
                automation-id={`Edit_Stream_${stream.name}`}
                colorSchema={BUTTON_COLORS.BLUE_500}
                text="Edit"
                onClick={this.onEditClick}
                icon="icn-action16-edit"
                height={BUTTON_HEIGHTS.TIGHT30}
                width={BUTTON_WIDTHS.REGULAR}
              />
            </Tooltip>
          </div>
        );
      }
      if (isAlowedEditRunning(stream.type) && isStreamRunning(stream.state) && !isAgent(stream.family)) {
        // edit live stream modal
        return (
          <div styleName="btn">
            <Tooltip content="Edit" delay={300}>
              <Button
                automation-id={`Edit_Stream_${stream.name}`}
                colorSchema={BUTTON_COLORS.BLUE_500}
                text="Edit"
                onClick={this.onEditLiveClick}
                icon="icn-action16-edit"
                height={BUTTON_HEIGHTS.TIGHT30}
                width={BUTTON_WIDTHS.REGULAR}
              />
            </Tooltip>
          </div>
        );
      }
      if (
        !isStreamFileUpload(stream.type) &&
        isStreamRunning(stream.state) &&
        !stream.paused &&
        !isAgent(stream.family)
      ) {
        // edit live stream
        return (
          <div styleName="btn">
            <Tooltip content="Edit" delay={300}>
              <Button
                automation-id={`Edit_Stream_${stream.name}`}
                colorSchema={BUTTON_COLORS.BLUE_500}
                text="Edit"
                onClick={this.openLiveStreamEditModalClick}
                icon="icn-action16-edit"
                height={BUTTON_HEIGHTS.TIGHT30}
                width={BUTTON_WIDTHS.REGULAR}
              />
            </Tooltip>
          </div>
        );
      }

      return null;
    };

    getEditEventStreamButton = () => {
      const {stream} = this.props;

      if (
        !this.props.isCustomerAdmin ||
        stream.state === STREAM_STATE_OPTIONS.running.value ||
        stream.state === STREAM_STATE_OPTIONS.done.value
      ) {
        return null;
      }
      return (
        <div styleName="btn">
          <Tooltip content="Edit" delay={300}>
            <Button
              automation-id={`Edit_Stream_${stream.name}`}
              colorSchema={BUTTON_COLORS.BLUE_500}
              text="Edit"
              onClick={() => this.props.editEventStream({streamId: this.props.stream.id})}
              icon="icn-action16-edit"
              height={BUTTON_HEIGHTS.TIGHT30}
              width={BUTTON_WIDTHS.REGULAR}
            />
          </Tooltip>
        </div>
      );
    };

    render() {
      const {stream, isBcReadOnly} = this.props;
      const editButton = this.getEditButton();
      const editEventStreamButton = this.getEditEventStreamButton();

      return (
        <div styleName="actions-container">
          {stream.isEventStream ? editEventStreamButton : editButton}
          {isViewStreamAllowed(stream) && (
            <div styleName="btn">
              <Tooltip content="View" delay={300}>
                <Button
                  automationId={`View_Stream_${stream.name}`}
                  colorSchema={BUTTON_COLORS.GRAY_300}
                  onClick={this.openStreamViewModalClick}
                  icon="icn-general16-show"
                  height={BUTTON_HEIGHTS.TIGHT30}
                  width={BUTTON_WIDTHS.NARROW}
                />
              </Tooltip>
            </div>
          )}

          <div styleName="btn">
            <div>
              {stream.family && stream.family === STREAMS_FAMILY_TYPES.event ? (
                <MoreActionEventStream stream={stream} placement="top" isSmall />
              ) : (
                <SelectAndt
                  automation-id={`3_dots_stream_extension_${stream.name}`}
                  options={this.getDropdownOptions()}
                  disabled={isStreamEditable(stream.state) && isBcReadOnly}
                  type={TYPE_SIMPLE}
                  theme={THEME_TRANSPARENT}
                  menuWidth={150}
                  onChange={this.actionItemSelected}
                  direction={DIRECTION_LEFT}
                  automationId="streamListItem"
                  offsetLeft={32}
                  customComponent={{
                    DropdownIndicator: (p) => (
                      <i {...p.innerProps} styleName="button-like-dropdown" className="icon icn-general16-3dot" />
                    ),
                  }}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  },
);
