import React from 'react';
import {useField} from 'react-final-form';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  theme: string,
};

const resolutions = [
  {value: '', label: 'Auto'},
  {value: 'short', label: '1 Minute'},
  {value: 'medium', label: '5 Minutes'},
  {value: 'long', label: '1 Hour'},
  {value: 'longlong', label: '1 Day'},
  {value: 'weekly', label: '1 Week'},
];

const TimeScale = ({theme = THEME_BLUE_LEAN}: PropTypes) => {
  const {
    input: {value, onChange},
  } = useField('timeScale');
  return (
    <SelectAndt
      id="timeScaleField"
      automationId="timeScaleField"
      options={resolutions}
      optionHeight={40}
      menuWidth={150}
      type={TYPE_NEW_NO_SEARCH}
      theme={theme}
      onChange={(item) => onChange(item.value)}
      value={resolutions.find((item) => item.value === value)}
      placeholder="Auto"
    />
  );
};

export default TimeScale;
