import React from 'react';
import {useField, useForm, useFormState} from 'react-final-form';
import {makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {userSettingIsLoading} from 'profile/store/selectors';
import AsyncButton from '../AsyncButton';
import Checkbox from '../Checkbox';
import Button, {COLORS} from '../Button';

const useStyles = makeStyles(() => ({
  footer: {
    paddingTop: 30,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
type PropsType = {
  showPresets: Function,
};
const CustomDateRangeFooter = ({showPresets}: PropsType) => {
  const classes = useStyles();
  const isLoadingUserSetting = useSelector(userSettingIsLoading);
  const formState = useFormState();
  const form = useForm();
  const {
    input: {value: savePreset, onChange: setSavePreset},
  } = useField('savePreset');

  return (
    <div className={classes.footer}>
      <div>
        <Button
          automationId="managePresets"
          colorSchema={COLORS.TRANSPARENT_BLUE}
          text="Manage Presets"
          icon="icn-nav16-settings"
          onClick={showPresets}
        />
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div style={{paddingRight: '16px'}}>
          <Checkbox text="Save Preset" onChange={setSavePreset} isChecked={savePreset} />
        </div>
        <AsyncButton
          colorSchema="blue.500"
          type="submit"
          onClick={form.submit}
          text="Apply"
          isDisabled={!formState.valid}
          isLoading={isLoadingUserSetting}
        />
      </div>
    </div>
  );
};

export default CustomDateRangeFooter;
