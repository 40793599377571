import {useQuery} from 'react-query';
import {post} from 'reactQuery/axiosInstance';
import {error} from 'common/utils/notifications/notificationsService';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';

const root = 'api/v1/bc/';
const {LOCAL_FILE} = EVENT_STREAM_TYPES;
const supportedTypes = [LOCAL_FILE];

const postFileUploadAnalyze = async (type, sourceId, fileFormat) => {
  if (!fileFormat || !sourceId || !type) return null;
  const res = await post(
    `${root}data-sources/${type}/${sourceId}/parsing/analyze-events`,
    Object.keys(fileFormat).length > 0 ? {fileFormat} : {},
  );
  if (res?.fileErrors && res?.fileErrors?.length > 0) {
    error({title: 'Failed to analyze data', code: `errorAnalyzeEvents`, description: res.fileErrors[0].message});
  }
  return res;
};

export default function usePostFileUploadAnalyze(type, sourceId, fileFormat) {
  const fallback = {};
  const {
    data: analyzeData = fallback,
    isLoading: isMetadataLoading,
    isFetching: isMetadataFetching,
    isSuccess: isMetadataSuccess,
    refetch: refetchMetadataFetching,
  } = useQuery(`${QUERY_KEYS.fileAnalyze}${sourceId}`, () => postFileUploadAnalyze(type, sourceId, fileFormat), {
    enabled: !!(sourceId || fileFormat || type) && supportedTypes.includes(type),
  });

  return {analyzeData, isMetadataLoading, isMetadataFetching, isMetadataSuccess, refetchMetadataFetching};
}
