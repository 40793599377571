// @flow
import React, {PureComponent, Fragment} from 'react';
import './AlertType.module.scss';

const mapper = {
  noData: {
    class: 'no-data',
    text: 'No data',
  },
  static: {
    class: 'static',
    text: 'Static',
  },
  anomaly: {
    class: 'anomaly',
    text: 'Anomaly',
  },
};

type PropTypes = {
  type: ?String,
};

export default class AlertType extends PureComponent {
  props: PropTypes;

  render() {
    const {type} = this.props;
    return (
      <Fragment>
        <span styleName={`alert-type ${mapper[type].class}`}>{mapper[type].text}</span>
      </Fragment>
    );
  }
}
