import {combineReducers} from 'redux';
import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from './actions';

const EMPTY_ARRAY = [];

const fetchLabels = composeReducers(
  makeAsyncReducer(actions.fetchLabels, {defaultData: EMPTY_ARRAY}),
  (state, {type, payload}) => {
    switch (type) {
      case actions.fetchLabels.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

export default combineReducers({
  fetchLabels,
});
