// @flow
import React from 'react';
import Checkbox from 'common/componentsV2/Checkbox';

type PropTypes = {
  isChecked: boolean,
  onChanged: Function,
  // connect
};

export default class DataCollectorsFilter extends React.PureComponent {
  props: PropTypes;

  render() {
    return (
      <Checkbox
        isChecked={this.props.isChecked}
        text="Data Collectors (BC)"
        onChange={(value) => this.props.onChanged(value)}
      />
    );
  }
}
