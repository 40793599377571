import {combineEpics} from 'redux-observable';
import {Observable} from 'rxjs/Observable';
import * as selectors from 'bc/store/selectors';
import * as actions from 'bc/store/actions';
import * as api from 'bc/services/api';
import {bcTypes} from 'bc/services/bcTypes';

const defaultObservableResponce = {
  timeColumn: null,
  timePattern: null,
  timeZone: null,
};

const getStreamSummaryModalDimetricsTimeDefinitionObservable = (stream) => {
  switch (stream?.type) {
    case bcTypes.local_file.type: {
      return api
        .fetchFuCsvFormatAnalysis({
          payload: {
            fileFormat: stream.fileFormat,
            dataSourceId: stream.dataSourceId,
          },
        })
        .map((payload) => {
          const timeColumnItem = payload.fileSchema.find((col) => col.index === stream.timeDefinition.timeColumnIdx);

          return {
            timeColumn: timeColumnItem ? timeColumnItem.name : stream.timeDefinition.timeColumnIdx,
            timePattern: stream.timeDefinition.timePattern,
            timeZone: stream.timeDefinition.timeZone,
          };
        })
        .catch((error) => Observable.of(actions.fetchFuCsvFormatAnalysis.failure(error)));
    }
    case bcTypes.s3.type: {
      return api
        .fetchS3CsvFormatAnalysis({
          payload: {
            fileFormat: stream.fileFormat,
            path: stream.path,
            fileNameSuffix: stream.fileNameSuffix,
            fileNamePrefix: stream.fileNamePrefix || '',
            fileNamePattern: stream.fileNamePattern || 'yyyyMMddHH',
            dataSourceId: stream.dataSourceId,
          },
        })
        .map((payload) => {
          const timeColumnItem = payload.fileSchema.find((col) => col.index === stream.timeDefinition.timeColumnIdx);
          return {
            timeColumn: timeColumnItem ? timeColumnItem.name : stream.timeDefinition.timeColumnIdx,
            timePattern: stream.timeDefinition.timePattern,
            timeZone: stream.timeDefinition.timeZone,
          };
        })
        .catch((error) => Observable.of(actions.fetchS3CsvFormatAnalysis.failure(error)));
    }
    case bcTypes.google_storage.type: {
      return api
        .fetchGoogleStorageCsvFormatAnalysis({
          payload: {
            projectId: stream.projectId,
            bucket: stream.bucket,
            fileFormat: stream.fileFormat,
            path: stream.path,
            fileNameSuffix: stream.fileNameSuffix,
            fileNamePrefix: stream.fileNamePrefix || '',
            fileNamePattern: stream.fileNamePattern || 'yyyyMMddHH',
            dataSourceId: stream.dataSourceId,
          },
        })
        .map((payload) => {
          const timeColumnItem = payload.fileSchema.find((col) => col.index === stream.timeDefinition.timeColumnIdx);

          return {
            timeColumn: timeColumnItem ? timeColumnItem.name : stream.timeDefinition.timeColumnIdx,
            timePattern: stream.timeDefinition.timePattern,
            timeZone: stream.timeDefinition.timeZone,
          };
        })
        .catch((error) => Observable.of(actions.fetchGoogleStorageCsvFormatAnalysis.failure(error)));
    }
    case bcTypes.mparticle.type:
    case bcTypes.bigquery.type:
    case bcTypes.mysql.type:
    case bcTypes.mariadb.type:
    case bcTypes.redshift.type:
    case bcTypes.snowflake.type:
    case bcTypes.oracle.type:
    case bcTypes.mssql.type:
    case bcTypes.psql.type:
    case bcTypes.pinot.type:
    case bcTypes.databricks.type:
    case bcTypes.teradata.type:
    case bcTypes.athena_sql.type:
    case bcTypes.timestream.type: {
      return Observable.of({
        timeColumn: stream.timestampColumn,
        timePattern: stream.timestampType ? stream.timestampType.toLowerCase() : null,
        timeZone: null,
      });
    }
    case bcTypes.kinesis.type:
    case bcTypes.eventhubs.type: {
      return Observable.of({
        timeColumn: stream.timeDefinition.path,
        timePattern: stream.timeDefinition.timePattern,
        timeZone: stream.timeDefinition.timeZone,
      });
    }
    case bcTypes.athena.type: {
      return Observable.of({
        timeColumn: stream?.timeDefinition?.name,
        timePattern: stream?.timeDefinition?.timePattern,
        timeZone: stream?.timeDefinition?.timeZone || null,
      });
    }
    case bcTypes.salesforce.type: {
      return Observable.of({
        timeColumn: stream?.timeDefinition?.field || null,
        timePattern: stream?.timeDefinition?.timePattern || null,
        timeZone: stream?.timeDefinition?.timeZone || null,
      });
    }
    default:
      return Observable.of(defaultObservableResponce);
  }
};

const getStreamSummaryModalDimetricsTimeDefinition = (action$, {getState}) =>
  action$
    .ofType(actions.getViewStreamSummaryDimetricsTimeDefinition.TYPE)
    .switchMap((action) => {
      const stream = selectors.getDataStreamsItems(getState()).find((ds) => ds.id === action.payload);
      return getStreamSummaryModalDimetricsTimeDefinitionObservable(stream);
    })
    .switchMap((res) => [actions.setViewStreamSummaryDimetricsTimeDefinition(res)]);

const viewStreamSummayModalEpic = combineEpics(getStreamSummaryModalDimetricsTimeDefinition);
export default viewStreamSummayModalEpic;
