// @flow
import React, {Fragment, useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector, useDispatch} from 'react-redux';
import PageLayout from 'common/componentsV2/PageLayout';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import SideBarTabs from 'common/componentsV2/sideBarTabs/SideBarTabs';
import {fetchUsers} from 'admin.users/store/actions';
import {getUsersData} from 'admin.users/store/selectors';

import DetailsAndSecuritySection from 'userSettings/components/detailsAndSecuritySection/DetailsAndSecuritySection';
import AuthenticationSection from 'userSettings/components/authenticationSection/AuthenticationSection';

import * as selectors from 'userSettings/store/selectors';
import ApiTokenManagement from '../components/apiTokenManagement/ApiTokenManagement';
import GeneralSection from '../components/generalSection/GeneralSection';

const useStyles = makeStyles(() => ({
  sidebarWrapper: {
    width: 305,
    paddingRight: 64,
  },
}));

const header = (
  <header>
    <Title type={TITLE_TYPES.PAGE_TITLE}>Settings</Title>
  </header>
);

const UserSettings = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState('detailsAndSecurity');
  const getSidebarTabs = useSelector((state) => selectors.getSidebarTabs(state));
  const users = useSelector((state) => getUsersData(state));

  useEffect(() => {
    if (!users.length > 0) {
      dispatch(fetchUsers());
    }
  }, []);

  const actionTab = (tabId) => {
    setActiveTab(() => tabId);
  };

  const renderBody = () => {
    switch (activeTab) {
      case 'detailsAndSecurity':
        return <DetailsAndSecuritySection />;
      case 'apiTokens':
        return <ApiTokenManagement />;
      case 'Authentication':
        return <AuthenticationSection />;
      case 'general':
        return <GeneralSection />;
      default:
        return '';
    }
  };

  return (
    <Fragment>
      <PageLayout header={header} shouldOverflowY>
        <div className="display_flex">
          <div className={classes.sidebarWrapper}>
            <SideBarTabs tabs={getSidebarTabs} selectedTabId={activeTab} onTabClick={actionTab} />
          </div>
          <div className="flexGrow_1">{renderBody()}</div>
        </div>
      </PageLayout>
    </Fragment>
  );
};

export default UserSettings;
