// @flow
import React, {useEffect, useRef} from 'react';
import MetricName from 'metrics/components/metricName/MetricName';
import {CHART_HEIGHT, ANOMALY_BUFFER_TIME} from 'alerts.console/services/alertsConsoleService';
import ImpactChip from 'impact/ImpactChip';
import {themesOptions} from 'impact/impactTheme';
import {useSelector, useDispatch} from 'react-redux';
import {getBucketStartTimeEnabled, getAlertConsoleEventsEnabled} from 'profile/store/selectors';
import {getMetricResolutions} from 'metrics/store/selectors';
import {getQueryParamsViews} from 'alerts.console/store/selectors';
import {getTimeZoneName} from 'bc/store/selectors';
import TimeSeriesChart from 'charts/timeSeries/components/TimeSeriesChart';
import {fetchAlertMetricDataPoints} from 'alerts.console/store/actions';
import AlertEventsChart from 'alerts.console/components/alertsList/alertContent/AlertEventsChart';
import AnomalyAlertDelta from './AnomalyAlertDelta';
import '../alertContent.module.scss';

let counter = 0;

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: true,
  hideMeetIcons: true,
};

type PropTypes = {
  metric: Object,
  anomalyId: string,
  alertId: string,
  uiGroupName: string,
  startDate: number,
  endDate: number,
  timeScale: string,
  isSingleLine: boolean,
  specialHeader: boolean,
  index: number,
};

const AnomalyAlertMetric = ({
  metric,
  alertId,
  anomalyId,
  uiGroupName,
  timeScale,
  startDate,
  endDate,
  isSingleLine,
  specialHeader,
  index,
}: PropTypes) => {
  const idRef = useRef(`console.anomaly.chart_${(counter += 1)}`); // idRef.current
  const chartEventsMetaRef = useRef({
    key: `${anomalyId}_${alertId}_${metric.id}`, // unique ID for chart
    chartId: idRef.current,
    stateGroup: uiGroupName,
  });

  const queryParamsViews = useSelector(getQueryParamsViews);
  const timeZoneName = useSelector(getTimeZoneName);
  const bucketStartTimeEnabled = useSelector(getBucketStartTimeEnabled);

  const isAlertConsoleEventsEnabled = useSelector(getAlertConsoleEventsEnabled);
  if (!isAlertConsoleEventsEnabled) {
    const dispatch = useDispatch();
    const resolutions = useSelector(getMetricResolutions);
    const startBucketMode = useSelector(getBucketStartTimeEnabled);

    useEffect(() => {
      const bufferTime = ANOMALY_BUFFER_TIME[timeScale] || 0;

      dispatch(
        fetchAlertMetricDataPoints(
          {
            metricId: metric.id,
            endDate: endDate + bufferTime,
            startDate,
            startBucketMode,
            includeBaseline: true,
            timeScale: Object.values(resolutions).find((a) => a.value2 === timeScale).value,
          },
          {
            ...chartEventsMetaRef.current,
            metricId: metric.id,
            intervals: metric.intervals,
            alertId,
          },
        ),
      );
    }, []);
  }

  return (
    <div styleName="tile">
      <div styleName={['header', specialHeader ? 'extra-line' : ''].join(' ')}>
        <div styleName="header-row">
          <MetricName isSingleLine={isSingleLine} metric={metric} highlightText={queryParamsViews.searchQuery} />
        </div>

        <div styleName="header-row">
          <div styleName="row-left">
            <AnomalyAlertDelta metric={metric} />
            {metric.impact && (
              <ImpactChip
                impact={metric.impact}
                themeName={themesOptions.tiny}
                tooltipContent="Impact for this Metric"
                text="Impact:"
                icon
              />
            )}
          </div>
        </div>
      </div>

      <div styleName={['chart', specialHeader ? 'special-height' : ''].join(' ')} id="chartWrapper">
        <>
          {isAlertConsoleEventsEnabled ? (
            <AlertEventsChart
              id={idRef.current}
              alertId={alertId}
              uiGroupName={uiGroupName}
              metric={metric}
              chartEventsMeta={chartEventsMetaRef.current}
              disableActions={false}
              bucketStartTimeEnabled={bucketStartTimeEnabled}
              timeScale={timeScale}
              timeZoneName={timeZoneName}
              startDate={startDate}
              endDate={endDate}
              index={index}
            />
          ) : (
            <TimeSeriesChart
              id={idRef.current}
              eventsMeta={chartEventsMetaRef.current}
              tooltip={TOOLTIP_OPTIONS}
              height={CHART_HEIGHT}
              bucketStartTimeEnabled={bucketStartTimeEnabled}
              timeScale={timeScale}
              timeZoneName={timeZoneName}
              enableClientZoom
            />
          )}
        </>
      </div>
    </div>
  );
};

export default React.memo(AnomalyAlertMetric);
