import {get, put, post, delete_} from 'common/utils/http';
import 'rxjs/add/observable/fromPromise';

const root = 'api/v1/customers/';

export const fetchCustomers = () => get(root);
export const fetchEditCustomerData = ({payload}) => get(`${root}${payload.customerId}`);
export const fetchCustomerDefaultSettings = () => get(`${root}defaultSettings`);
export const updateCustomer = ({payload}) =>
  put(`${root}${payload.customer._id}`, {
    ...payload.customer,
  });
export const updateCustomerGeneralSettings = ({payload}) =>
  put(`${root}${payload.customer._id}/general`, {
    ...payload.data,
  });
export const deleteCustomer = ({payload}) => delete_(`${root}${payload.customerId}`);
export const createCustomer = ({payload}) => post(root, {...payload});
