import React, {useEffect, useState, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';

const useStyles = makeStyles(() => ({
  slider: {
    position: 'relative',
    height: 9,
    borderRadius: 4,
    backgroundColor: palette.gray['250'],
    width: '100%',
  },
  wrapper: {
    paddingTop: 10.5,
    paddingBottom: 27.5,
    width: '100%',
  },
  title: {
    position: 'absolute',
    fontSize: 12,
    fontWeight: 'bold',
    color: palette.gray['400'],
    top: 20,
    userSelect: 'none',
  },
  topTitle: {
    position: 'absolute',
    fontSize: 10,
    fontWeight: 500,
    color: palette.gray['400'],
    top: -30,
    userSelect: 'none',
  },
  unBold: {
    fontSize: 10,
    fontWeight: 500,
  },
  thumb: {
    width: 21,
    height: 30,
    borderRadius: 10.5,
    boxShadow: '0 1px 6px -1px rgba(23, 64, 111, 0.7)',
    position: 'absolute',
    cursor: 'pointer',
    top: -10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    userSelect: 'none',
    backgroundColor: palette.blue['500'],
    '&:hover > .tooltip': {
      opacity: 1,
    },
  },
  point: {
    position: 'absolute',
    width: 5,
    height: 5,
    top: 2,
    borderRadius: '100%',
    opacity: 0.5,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
  draggable: {
    width: 7,
    height: 2,
    backgroundColor: palette.blue['500'],
    margin: 1,
    borderRadius: 2,
    pointerEvents: 'none',
    userSelect: 'none',
  },
  showPoint: {
    backgroundColor: palette.gray['500'],
  },
  disabled: {
    opacity: 0.5,
  },
  tooltipWrapper: {
    backgroundColor: 'rgba(23, 64, 111, 0.95)',
    border: 0,
    color: palette.white['500'],
    fontSize: 14,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.25)',
    borderRadius: 4,
    padding: '5px 11px',
    maxWidth: 250,
    lineHeight: 1.5,
  },
}));

const getPercentage = (current, max) => (100 * current) / max;

const getViewPercentage = (current, max) => {
  let computed = current;
  if (computed < 0) {
    computed = 0;
  }
  if (computed > max) {
    computed = max;
  }
  return computed / max;
};

const getValue = (percentage, max) => (max / 100) * percentage;

const THUMB_HALF_WIDTH = 10.5;
const DOT_HALF_WIDTH = 2.5;

type PropTypes = {
  value: number,
  max: number,
  min: number,
  step: number,
  automationId: string,
  onChange: Function,
  onRelease: Function,
  points: Array,
  tooltipValueFormatter: Function,
  isRed: Boolean,
  hidePoint: boolean,
  isDisabled: boolean,
  alignLeft: boolean,
};

const Slider = ({
  value,
  max,
  min = 0,
  step,
  onChange,
  onRelease,
  points = [],
  tooltipValueFormatter = (v) => v,
  automationId,
  isRed,
  hidePoint,
  isDisabled,
  alignLeft,
}: PropTypes) => {
  const classes = useStyles();
  const distance = max - min;
  const offsetValue = value - min;

  const viewPercentage = getViewPercentage(offsetValue, distance);
  const [sliderRef, setSliderRef] = useState({current: {}});
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  const measuredSliderRef = useCallback((node) => {
    if (node !== null) {
      setSliderRef({current: node});
    }
  }, []);

  const thumbRef = React.useRef();
  const tooltipValueRef = React.useRef();

  const processMousePosition = (event) => {
    let newX = event.clientX - sliderRef.current.getBoundingClientRect().left;
    const end = sliderRef.current.offsetWidth - THUMB_HALF_WIDTH;

    const start = 0;

    if (newX < start) {
      newX = 0;
    }

    if (newX > end) {
      newX = end;
    }

    const newPercentage = getPercentage(newX, end);
    let newValue = getValue(newPercentage, distance) + min;
    if (newValue < min) {
      newValue = min;
    } else if (newValue > max) {
      newValue = max;
    }
    const calculatedValue = step ? Math.round(newValue / step) * step : newValue;

    const leftPixels =
      getViewPercentage(calculatedValue - min, distance) * (sliderRef.current.offsetWidth - THUMB_HALF_WIDTH) -
      THUMB_HALF_WIDTH;
    thumbRef.current.style.transform = `translate(${leftPixels}px, 0)`;
    tooltipValueRef.current.innerHTML = tooltipValueFormatter(calculatedValue);

    return calculatedValue;
  };

  const handleMouseUp = (event) => {
    if (event) {
      const newValue = processMousePosition(event);
      onRelease(newValue);
    }
    document.removeEventListener('mouseup', handleMouseUp);
    document.removeEventListener('mousemove', processMousePosition);
    setTooltipVisible(false);
  };

  const handleMouseDown = (event) => {
    if (event.button === 0) {
      document.addEventListener('mousemove', processMousePosition);
      document.addEventListener('mouseup', handleMouseUp);
      setTooltipVisible(true);
    }
  };

  const handleMouseClick = (event) => {
    const newValue = processMousePosition(event);
    onRelease(newValue);
  };

  const handleLabelClick = (event, percentage) => {
    event.stopPropagation();
    let newValue = getValue(percentage, distance) + min;
    if (newValue < min) {
      newValue = min;
    } else if (newValue > max) {
      newValue = max;
    }

    const calculatedValue = step ? Math.round(newValue / step) * step : newValue;

    const leftPixels =
      getViewPercentage(calculatedValue - min, distance) * (sliderRef.current.offsetWidth - THUMB_HALF_WIDTH) -
      THUMB_HALF_WIDTH;
    thumbRef.current.style.transform = `translate(${leftPixels}px, 0)`;

    onChange(calculatedValue);
    onRelease(calculatedValue);
  };

  useEffect(() => handleMouseUp, []);

  return (
    <div className={`${classes.wrapper}`}>
      <div
        automation-id={automationId}
        className={`${classes.slider} ${isDisabled && classes.disabled}`}
        ref={measuredSliderRef}
        onClick={handleMouseClick}
      >
        {points.map((point) => (
          <div>
            {point.formattedVal && (
              <div
                className={classes.point}
                style={{
                  left: (point.value / 100) * (sliderRef.current.offsetWidth - THUMB_HALF_WIDTH) - DOT_HALF_WIDTH,
                }}
              >
                <div className={classes.topTitle} automation-id={point.formattedVal}>
                  {point.formattedVal}
                </div>
              </div>
            )}
            <div
              key={point.value}
              className={`${classes.point} ${!hidePoint && classes.showPoint}`}
              style={{left: (point.value / 100) * (sliderRef.current.offsetWidth - THUMB_HALF_WIDTH) - DOT_HALF_WIDTH}}
              onClick={(event) => handleLabelClick(event, point.value)}
            >
              <div className={`${classes.title} ${point.formattedVal && classes.unBold}`} automation-id={point.title}>
                {point.title}
              </div>
            </div>
          </div>
        ))}
        <div
          ref={thumbRef}
          onMouseDown={handleMouseDown}
          className={classes.thumb}
          automation-id={`${automationId}Pointer`}
          style={{
            transform: `translate(${viewPercentage * (sliderRef.current.offsetWidth - THUMB_HALF_WIDTH) -
              THUMB_HALF_WIDTH}px, 0)`,
            backgroundColor: isRed && palette.tomato['400'],
            marginLeft: alignLeft && '9px',
          }}
        >
          {[1, 2, 3].map((item) => (
            <div key={item} className={classes.draggable} style={{backgroundColor: palette.white['500']}} />
          ))}
          <div className={`tooltip fade top ${isTooltipVisible ? 'in' : ''}`} style={{marginTop: -60}}>
            <div className="tooltip-arrow" />
            <div className={classes.tooltipWrapper} ref={tooltipValueRef}>
              {tooltipValueFormatter(value)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
