import React, {useEffect, useRef, useState} from 'react';
import Radio from 'common/componentsV2/Radio';
import {
  NOT_INTERESTING_REASONS_ANOMALY,
  NOT_INTERESTING_REASONS_STATIC_NO_DATA,
  FEEDBACK_TYPE,
  GOOD_CATCH_REASONS,
} from 'feedback/services/constants';
import Button, {COLORS, HEIGHTS} from 'common/componentsV2/Button';
import {ALERT_TYPES} from 'alerts.console.new/services/alertsConsoleService';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';

import './Feedback.module.scss';

const FeedbackPopover = ({
  alertType,
  currentFeedbackId,
  feedbackType,
  onSubmit,
  onClose,
}: {
  alertType: string,
  currentFeedbackId: string,
  feedbackType: string,
  onSubmit: Function,
  onClose: Function,
}) => {
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [options, setOptions] = useState([]);

  const timeoutHandleRef = useRef(0);
  const isClosedRef = useRef(false);

  const closePopoverTimer = () => {
    if (!timeoutHandleRef.current && isClosedRef.current !== true) {
      isClosedRef.current = true;
      timeoutHandleRef.current = setTimeout(() => {
        clearTimeout(timeoutHandleRef.current);
        onClose();
      }, 1000);
    }
  };

  useEffect(
    () => () => {
      // cleanup
      if (!isClosedRef.current) {
        closePopoverTimer();
      }
    },
    [],
  );

  useEffect(() => {
    if (feedbackType === FEEDBACK_TYPE.NOT_INTERESTING) {
      if (alertType === ALERT_TYPES.ANOMALY) {
        setOptions(NOT_INTERESTING_REASONS_ANOMALY);
      } else {
        setOptions(NOT_INTERESTING_REASONS_STATIC_NO_DATA);
      }
    } else if (feedbackType === FEEDBACK_TYPE.GOOD_CATCH) {
      setOptions(GOOD_CATCH_REASONS);
    }
  }, [feedbackType, alertType]);

  const submit = () => {
    setIsSubmitted(true);
    onSubmit({reason, comment});
    closePopoverTimer();
  };

  return (
    <div className="alert-clickable-item" styleName="content-body" automation-id="FeedbackPanel">
      <header automation-id="FeedbackRespondingHeader">Thank you for your feedback!</header>
      {!isSubmitted ? (
        <>
          {currentFeedbackId ? (
            <div styleName="wrapper">
              <p>
                {feedbackType === FEEDBACK_TYPE.GOOD_CATCH
                  ? 'Why this alert was a good catch?'
                  : 'Why this alert was not interesting?'}
              </p>
              <Radio options={options} onSelect={setReason} groupName="FEEDBACK_REASONS" />
              <textarea
                automation-id="FeedbackTextarea"
                placeholder={feedbackType === FEEDBACK_TYPE.GOOD_CATCH ? 'Optional' : 'Optional, tell us more...'}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <div styleName="submit-wrapper">
                <Button
                  automationId="FeedbackSubmit"
                  isDisabled={!comment.length && !reason.length}
                  colorSchema={COLORS.BLUE_500}
                  onClick={submit}
                  height={HEIGHTS.TIGHT28}
                  text="Submit"
                />
              </div>
            </div>
          ) : (
            <Spinner color={palette.gray[500]} size={SIZES.SMALL_30} />
          )}
        </>
      ) : null}
    </div>
  );
};

export default FeedbackPopover;
