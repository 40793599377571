// @flow
import React, {Fragment, PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import {get} from 'lodash';
import {alertConditionTypes, alertTypes, serverAnomalyDirection} from 'alerts.management/services/alertsService';
import * as selectors from 'alerts.management/store/selectors';
import * as profileSelector from 'profile/store/selectors';
import * as actions from 'alerts.management/store/actions';
import SignificanceCondition from './SignificanceCondition';
import MinDurationCondition from './MinDurationCondition';
import TimeScaleCondition from './TimeScaleCondition';
import DirectionCondition from './DirectionCondition';
import EventsCondition from './EventsCondition';
import SendAlertOnCondition from './SendAlertOnCondition';
import NoDataCondition from './NoDataCondition';
import MinDelta from './MinDelta';
import StaticThresholdCondition from './StaticThresholdCondition';
import UpdatePolicyCondition from './UpdatePolicyCondition';
import AddConditionBtn from './AddConditionBtn';
import Conditions from './Conditions';
import VolumeCondition from './VolumeCondition';

import './SimulationFilters.module.scss';

type PropTypes = {
  simulationFilters: Object,
  autoVolumeEnabled: Boolean,
  isInfluencingStaticEnabled: Boolean,
  setSelectedAlertSignificance: Function,
  setSelectedAlertMinDuration: Function,
  setSelectedAlertMinDurationScale: Function,
  setSelectedAlertTimeScale: Function,
  setSelectedAlertDirection: Function,
  setSelectedAlertShouldShowEvents: Function,
  setIsInfluencingEvents: Function,
  setSelectedAlertNoDataDuration: Function,
  setSelectedAlertNoDataEnabled: Function,
  setSelectedAlertMinDeltaAbs: Function,
  setSelectedAlertMinDeltaPercent: Function,
  setSelectedAlertMinDeltaAutoBoolVal: Function,
  setDeltaDurationEnabled: Function,
  setDeltaDurationNumOfPoints: Function,
  setSelectedAlertStaticThreshold: Function,
  setSelectedAlertUpdatePolicy: Function,
  addConditionToSelectedAlert: Function,
  availableConditions: Function,
  setSelectedAlertMaxMetricsAbs: Function,
  setSelectedAlertMaxMetricsPercentage: Function,
  setSelectedAlertMinMetricsAbs: Function,
  setSelectedAlertMinMetricsPercentage: Function,
  setSelectedAlertNotifyOnlyOpenBoolVal: Function,
  isLoadingEstimations: Function,
  shouldHideAdvanceMode: Function,
  // setAdvancedModeOpen: Function,
  removeConditionFromSelectedAlert: Function,
  isAlertEnableAnomalyUpdate: Boolean,
  regressorsEnabled: Boolean,
  me: Object,
};

// TODO: can be .find
const getCondition = (conditions, type) => conditions.filter((condition) => condition.type === type)[0];
const ToggleTitle = ({isOpen, handleToggle, title}: {isOpen: boolean, handleToggle: Function, title: string}) => (
  <span styleName="title toggle" onClick={handleToggle}>
    <i className={isOpen ? 'icon ion-android-arrow-dropdown' : 'icon ion-android-arrow-dropright'} />
    <span>{title}</span>
  </span>
);

export default connect(
  (state) => ({
    simulationFilters: selectors.getSelectedAlertSimulationFilters(state),
    availableConditions: selectors.getSelectedAlertAvailableConditions(state),
    isLoadingEstimations: selectors.simulationFiltersIsLoadingEstimations(state),
    me: profileSelector.getUserProfile(state),
    shouldHideAdvanceMode: profileSelector.getShouldHideAdvanceMode(state),
    isAlertEnableAnomalyUpdate: profileSelector.getIsAlertEnableAnomalyUpdate(state),
    autoVolumeEnabled: profileSelector.getAutoVolumeEnabled(state),
    isInfluencingStaticEnabled: profileSelector.getInfluencingStaticEnabled(state),
    regressorsEnabled: profileSelector.getRegressorsEnabled(state),
  }),
  {
    resetExpressionBuilder: actions.resetExpressionBuilder,
    setSelectedAlertType: actions.setSelectedAlertType,
    setSelectedAlertSignificance: actions.setSelectedAlertSignificance,
    setSelectedAlertMinDuration: actions.setSelectedAlertMinDuration,
    setSelectedAlertMinDurationScale: actions.setSelectedAlertMinDurationScale,
    setSelectedAlertTimeScale: actions.setSelectedAlertTimeScale,
    setSelectedAlertDirection: actions.setSelectedAlertDirection,
    setSelectedAlertShouldShowEvents: actions.setSelectedAlertShouldShowEvents,
    setIsInfluencingEvents: actions.setIsInfluencingEvents,
    setSelectedAlertNoDataDuration: actions.setSelectedAlertNoDataDuration,
    setSelectedAlertNoDataEnabled: actions.setSelectedAlertNoDataEnabled,
    setSelectedAlertMinDeltaAbs: actions.setSelectedAlertMinDeltaAbs,
    setSelectedAlertMinDeltaPercent: actions.setSelectedAlertMinDeltaPercent,
    setSelectedAlertStaticThreshold: actions.setSelectedAlertStaticThreshold,
    setSelectedAlertUpdatePolicy: actions.setSelectedAlertUpdatePolicy,
    addConditionToSelectedAlert: actions.addConditionToSelectedAlert,
    setSelectedAlertMinMetricsAbs: actions.setSelectedAlertMinMetricsAbs,
    setSelectedAlertMinMetricsPercentage: actions.setSelectedAlertMinMetricsPercentage,
    setSelectedAlertAutoTuneByAnodot: actions.setSelectedAlertAutoTuneByAnodot,
    removeConditionFromSelectedAlert: actions.removeConditionFromSelectedAlert,
    setSelectedAlertMinDeltaAutoBoolVal: actions.setSelectedAlertMinDeltaAutoBoolVal,
    setDeltaDurationEnabled: actions.setDeltaDurationEnabled,
    setDeltaDurationNumOfPoints: actions.setDeltaDurationNumOfPoints,
    setSelectedAlertNotifyOnlyOpenBoolVal: actions.setSelectedAlertNotifyOnlyOpenBoolVal,
    setSelectedAlertMinDeltaEstimation: actions.setSelectedAlertMinDeltaEstimation,
    setVolumeRollup: actions.setVolumeRollup,
    setVolumeEnabled: actions.setVolumeEnabled,
  },
)(
  class Settings extends PureComponent {
    props: PropTypes;

    state = {
      isNotificationsOpen: true,
      isAdvancedOpen: this.props.regressorsEnabled,
      isAutomaticOpen: true,
    };

    toggledSections = (stateKey) => {
      this.setState((prevState) => ({[stateKey]: !prevState[stateKey]}));
    };

    render() {
      const {
        simulationFilters: {
          type,
          minDurationUI,
          rollup,
          eventsFilter,
          noDataDuration,
          updatePolicyInterval,
          conditions,
          noDataAlert,
          notifyOnlyOpen,
          isInfluencingEvents,
        },
        autoVolumeEnabled,
        isInfluencingStaticEnabled,
        setSelectedAlertSignificance,
        setSelectedAlertMinDuration,
        setSelectedAlertMinDurationScale,
        setSelectedAlertTimeScale,
        setSelectedAlertDirection,
        setSelectedAlertShouldShowEvents,
        setIsInfluencingEvents,
        setSelectedAlertNoDataDuration,
        setSelectedAlertNoDataEnabled,
        setSelectedAlertMinDeltaAbs,
        setSelectedAlertMinDeltaPercent,
        setSelectedAlertMinDeltaAutoBoolVal,
        setDeltaDurationEnabled,
        setDeltaDurationNumOfPoints,
        setSelectedAlertStaticThreshold,
        setSelectedAlertUpdatePolicy,
        addConditionToSelectedAlert,
        availableConditions,
        setSelectedAlertMaxMetricsAbs,
        setSelectedAlertMaxMetricsPercentage,
        setSelectedAlertMinMetricsAbs,
        setSelectedAlertMinMetricsPercentage,
        setSelectedAlertNotifyOnlyOpenBoolVal,
        isLoadingEstimations,
        shouldHideAdvanceMode,
        isAlertEnableAnomalyUpdate,
        removeConditionFromSelectedAlert,
        regressorsEnabled,
        me,
      } = this.props;

      const {isAdvancedOpen, isAutomaticOpen, isNotificationsOpen} = this.state;

      const isImpersonating = get(me, 'impersonatorId');

      const anomalyType = type.value === alertTypes.anomaly.value;
      const staticType = type.value === alertTypes.static.value;
      const noDataType = type.value === alertTypes.noData.value;

      const thresholdCondition = getCondition(conditions, alertConditionTypes.staticThreshold);
      const deltaCondition = getCondition(conditions, alertConditionTypes.minDelta);
      const significanceCondition = getCondition(conditions, alertConditionTypes.significanceCondition);
      const directionCondition = getCondition(conditions, alertConditionTypes.directionCondition);
      const influencingMetricsCondition = availableConditions.find(
        (con) => con.dataObj.type === alertConditionTypes.influencingMetrics,
      );
      const volumeCondition = getCondition(conditions, alertConditionTypes.volumeCondition);
      const isLoadingEstimationsCalced = isLoadingEstimations && deltaCondition && deltaCondition.enableAutoTuning;

      return (
        <Fragment>
          {(anomalyType || staticType) && (
            <TimeScaleCondition rollup={rollup} useContainer onChange={setSelectedAlertTimeScale} />
          )}

          {(anomalyType || staticType) && (
            <MinDurationCondition
              minDuration={minDurationUI}
              onMinDurationChange={setSelectedAlertMinDuration}
              onMinDurationScaleChange={setSelectedAlertMinDurationScale}
            />
          )}

          {anomalyType && directionCondition && (
            <DirectionCondition direction={directionCondition.direction} onChange={setSelectedAlertDirection} />
          )}

          {anomalyType && significanceCondition && (
            <div styleName="push-bottom">
              <SignificanceCondition significance={significanceCondition} onChange={setSelectedAlertSignificance} />
            </div>
          )}

          {staticType && (
            <div styleName="push-bottom">
              <StaticThresholdCondition
                staticThreshold={thresholdCondition}
                direction={get(directionCondition, 'direction', serverAnomalyDirection.both)}
                alertType={type.value}
                onChange={setSelectedAlertStaticThreshold}
              />
            </div>
          )}

          {/* Notification */}
          <ToggleTitle
            isOpen={isNotificationsOpen}
            handleToggle={() => this.toggledSections('isNotificationsOpen')}
            title="Notifications"
          />

          {(anomalyType || staticType) && isNotificationsOpen && (
            <div styleName="pull-from-top">
              <SendAlertOnCondition
                notifyOnlyOpen={notifyOnlyOpen}
                alertType={type.value}
                onChange={setSelectedAlertNotifyOnlyOpenBoolVal}
              />
            </div>
          )}

          {((anomalyType && isAlertEnableAnomalyUpdate) || staticType || noDataType) && isNotificationsOpen && (
            <UpdatePolicyCondition
              updatePolicyInterval={updatePolicyInterval}
              onChange={setSelectedAlertUpdatePolicy}
            />
          )}

          {isNotificationsOpen && (
            <div styleName="push-bottom">
              <NoDataCondition
                noDataAlert={noDataAlert}
                noDataDuration={noDataDuration}
                alertType={type.value}
                onChange={setSelectedAlertNoDataDuration}
                onEnabledChange={setSelectedAlertNoDataEnabled}
              />
            </div>
          )}
          {/* End Notification */}

          {noDataType && (
            <div styleName="add-influence-no-data">
              <span>Influencing Metrics</span>
              <i
                className="icon icn-icon-tablepopup-plus"
                onClick={() =>
                  influencingMetricsCondition
                    ? addConditionToSelectedAlert(influencingMetricsCondition.dataObj, {})
                    : null
                }
              />
            </div>
          )}

          {noDataType && (
            <div styleName="push-bottom">
              <Conditions
                conditions={conditions.filter((con) => con.type === alertConditionTypes.influencingMetrics)}
              />
            </div>
          )}

          {/* Advanced */}
          {(anomalyType || staticType) && !shouldHideAdvanceMode && (
            <ToggleTitle
              isOpen={isAdvancedOpen}
              handleToggle={() => this.toggledSections('isAdvancedOpen')}
              title="Advanced"
            />
          )}

          {(anomalyType || staticType) && isAdvancedOpen && (
            <div styleName="pull-from-top">
              <EventsCondition
                shouldShowEvents={eventsFilter.shouldShowEvents}
                onChange={setSelectedAlertShouldShowEvents}
                onSetIsInfluencingEvents={setIsInfluencingEvents}
                isInfluencingEvents={isInfluencingEvents}
                regressorsEnabled={regressorsEnabled}
                isImpersonating={isImpersonating}
              />
            </div>
          )}

          {isInfluencingStaticEnabled && staticType && isAdvancedOpen && (
            <div styleName="add-influence-no-data">
              <span>Influencing Metrics</span>
              <i
                className="icon icn-icon-tablepopup-plus"
                onClick={() =>
                  influencingMetricsCondition
                    ? addConditionToSelectedAlert(influencingMetricsCondition.dataObj, {})
                    : null
                }
              />
            </div>
          )}

          {isInfluencingStaticEnabled && staticType && isAdvancedOpen && (
            <div styleName="push-bottom">
              <Conditions
                conditions={conditions.filter((con) => con.type === alertConditionTypes.influencingMetrics)}
              />
            </div>
          )}

          {anomalyType && isAdvancedOpen && (
            <div styleName="push-bottom">
              <AddConditionBtn
                availableConditions={availableConditions}
                onSelectCondition={addConditionToSelectedAlert}
              />
            </div>
          )}

          {anomalyType && isAdvancedOpen && (
            <div styleName="push-bottom">
              <Conditions
                type={type}
                conditions={conditions}
                setSelectedAlertMaxMetricsPercentage={setSelectedAlertMaxMetricsPercentage}
                setSelectedAlertMinMetricsPercentage={setSelectedAlertMinMetricsPercentage}
                setSelectedAlertMaxMetricsAbs={setSelectedAlertMaxMetricsAbs}
                setSelectedAlertMinMetricsAbs={setSelectedAlertMinMetricsAbs}
                thresholdCondition={thresholdCondition}
                directionCondition={directionCondition}
                setSelectedAlertStaticThreshold={setSelectedAlertStaticThreshold}
                removeConditionFromSelectedAlert={removeConditionFromSelectedAlert}
              />
            </div>
          )}
          {/* End Advanced */}

          {/* Automatic */}
          {anomalyType && (
            <ToggleTitle
              isOpen={isAutomaticOpen}
              handleToggle={() => this.toggledSections('isAutomaticOpen')}
              title="Automatic Conditions"
            />
          )}
          {minDurationUI && anomalyType && deltaCondition && isAutomaticOpen && (
            <div styleName="pull-from-top">
              <MinDelta
                title="Minimum Delta"
                subTitle={
                  <span>
                    Set the minimum difference from the expected range needed to trigger the alert. You can set a
                    numeric value, a percentage or both.
                  </span>
                }
                absValue={deltaCondition.deltaAbsolute}
                percentValue={deltaCondition.deltaPercentage}
                onPercentChange={setSelectedAlertMinDeltaPercent}
                onAbsChange={setSelectedAlertMinDeltaAbs}
                enableAutoBoolVal={deltaCondition.enableAutoTuning}
                enableAutoIsLoading={isLoadingEstimationsCalced}
                onAutoBoolValChange={setSelectedAlertMinDeltaAutoBoolVal}
                rollup={rollup}
                minDurationUI={minDurationUI}
                deltaDuration={deltaCondition.deltaDuration}
                setDeltaDurationEnabled={setDeltaDurationEnabled}
                setDeltaDurationNumOfPoints={setDeltaDurationNumOfPoints}
                id={deltaCondition.id}
              />
            </div>
          )}
          {autoVolumeEnabled && anomalyType && isAutomaticOpen && volumeCondition && <VolumeCondition />}
          {/* End Automatic */}
        </Fragment>
      );
    }
  },
);
