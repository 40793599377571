// @flow
import React, {useState, useEffect} from 'react';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import {palette} from 'app/styles/theme';
import MetricsTableRow from './MetricsTableRow';
import './MetricsTable.module.scss';

type PropTypes = {
  columns: Array<Object>,
  metrics: Array<Object>,
  isLoading: boolean,
  timeZoneName: string,
  optionalTitle: string,
  listOrder: Object,
  setListOrder: Function,
};

const EMPTY_ARRAY = [];
const DEFAULT_SORT_COLUMN = 'score';

const sortingFunction = (arr, sortBy, direction) => {
  return arr.sort((a, b) => {
    const itemA = a[sortBy];
    const itemB = b[sortBy];

    if (itemA < itemB) {
      return -1 * direction;
    }
    if (itemA > itemB) {
      return direction;
    }
    return 0;
  });
};

const MetricsTable = ({
  columns,
  metrics,
  isLoading,
  timeZoneName,
  optionalTitle = '',
  listOrder,
  setListOrder,
}: PropTypes) => {
  const [selectedItemsIdsList, setSelectedItemsIdsList] = useState(EMPTY_ARRAY);
  const [sortedMetrics, setSortedMetrics] = useState(EMPTY_ARRAY);
  const [localColumns, setLocalColumns] = useState(EMPTY_ARRAY);

  useEffect(() => {
    if (columns?.length > 0) {
      setLocalColumns([...columns]);
      if (optionalTitle) {
        const titleObj = localColumns.find((o) => o.name === 'title');
        titleObj.title = optionalTitle;
      }
    }
  }, [columns]);

  useEffect(() => {
    const sortBy = listOrder.column ? listOrder.column : DEFAULT_SORT_COLUMN;
    const direction = !listOrder.direction || listOrder.direction === 'desc' ? -1 : 1;

    setSelectedItemsIdsList([]);
    setSortedMetrics([...sortingFunction(metrics, sortBy, direction)]);
  }, [metrics, listOrder, setSortedMetrics]);

  const handleTitleOrderChange = (columnName) => {
    let updatedDirection = 'desc';
    if (columnName === listOrder.column) {
      updatedDirection = listOrder.direction === 'desc' ? 'asc' : 'desc';
    }

    setListOrder({
      column: columnName,
      direction: updatedDirection,
    });
  };

  const setSelectedItem = (itemId) => {
    if (selectedItemsIdsList.indexOf(itemId) === -1) {
      setSelectedItemsIdsList((state) => [...state, itemId]);
    } else {
      setSelectedItemsIdsList((state) => state.filter((item) => item !== itemId));
    }
  };

  if (isLoading) {
    return (
      <div styleName="spinner-wrapper">
        <Spinner color={palette.gray[500]} size={SpinnerSizes.XX_BIG_100} />
      </div>
    );
  }

  return (
    <div styleName="table">
      <div styleName="table-header">
        <div styleName="col-is-open" />
        {localColumns.map((col) => (
          <div styleName={['col-header', `col-${col.name}`].join(' ')} key={`user-events-list-${col.id}`}>
            <TitleHeaderTableOrder
              title={col.title}
              columnName={col.name}
              onChange={handleTitleOrderChange}
              selectedDirection={listOrder.direction}
              isSortable={col.isSortable}
              isActive={col.name === listOrder.column}
            />
          </div>
        ))}
      </div>

      {sortedMetrics.map((metric) => (
        <MetricsTableRow
          key={metric.id}
          metric={metric}
          columns={localColumns}
          timeZoneName={timeZoneName}
          setSelectedItem={setSelectedItem}
          isSelected={selectedItemsIdsList.indexOf(metric.id) !== -1}
        />
      ))}
    </div>
  );
};

export default MetricsTable;
