import React, {useEffect, useState} from 'react';
import {get} from 'lodash';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from '@material-ui/core';
import {
  fetchPropAndValList,
  initExpressionTreeModel,
  fetchLastExpressionSearches,
  fetchLastUsedMeasures,
} from 'metrics/store/actions';
import * as profile from 'profile/store/selectors';

import {
  getCacheData,
  getLastExpressionSearchesItemsValue,
  getLastUsedMeasuresItems,
  getOriginInfo,
  getIsLastExpressionSearchesLoading,
  getIsLastUsedMeasuresItemsLoading,
} from 'metrics/store/selectors';
import {getUniqueId} from 'common/utils/guid';
import ExpressionLineDisplay from 'common/componentsV2/ExpressionBuilderV2/ExpressionLineDisplay';
import ExpressionItem from 'common/componentsV2/ExpressionBuilderV2/ExpressionItem';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {segmentClickEvent} from 'common/store/actions';

// in the end, this list will influence what is in the 1st panel of the expression builder in case it's empty
const measuresPayload = {
  expression: '',
  listDescription: [
    {valueType: 'measures', count: 20},
    {valueType: 'dimensions', count: 20},
    {valueType: 'streams', count: 10},
    {valueType: 'alerts', count: 10},
    {valueType: 'composites', count: 10},
    {valueType: 'forecasts', count: 10},
    {valueType: 'tags', count: 10},
  ],
  filter: [],
  search: '',
};

const useStyles = makeStyles(({palette}) => ({
  chips: {
    padding: 8,
    borderRadius: 4,
    marginRight: 12,
    marginTop: 8,
    maxWidth: '40%',
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 500,
  },
  chipGray: {
    backgroundColor: 'rgba(61, 76, 89, 0.25)',
    color: palette.gray[500],
  },
  chipPurple: {
    backgroundColor: 'rgba(104,38,171,0.2)',
    color: palette.purple[500],
  },
  quickStartWrapper: {
    width: '570px',
    minHeight: '366px',
    borderRadius: '10px',
    padding: '16px',
    backgroundColor: palette.gray[100],
    display: 'flex',
    flexDirection: 'column',
  },
  fakeChip: {
    borderRadius: '4px',
    backgroundColor: palette.gray[300],
    height: '30px',
    marginRight: '16px',
    marginTop: '8px',
  },
}));

const QuickActions = ({
  maxExpressionsCount,
  defaultFunction,
}: {
  maxExpressionsCount: number,
  defaultFunction: string,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const cacheData = useSelector(getCacheData);
  const isForecastEnabled = useSelector(profile.getForecastEnabled);
  const isLastExpressionSearchesLoading = useSelector(getIsLastExpressionSearchesLoading);
  const isLastUsedMeasuresItemsLoading = useSelector(getIsLastUsedMeasuresItemsLoading);
  const useProfileId = useSelector(profile.getProfileId);
  const lastExpressionSearchesItems = useSelector(getLastExpressionSearchesItemsValue);
  const lastUsedMeasures = useSelector(getLastUsedMeasuresItems);
  const originInfo = useSelector(getOriginInfo);
  const [expressions, setExpressions] = useState([]);
  const [streams, setStreams] = useState([]);
  const propsAndValList = cacheData.find((obj) => obj.index === 'noCache');

  const measures = (lastUsedMeasures || [])
    .map((item) => item.value)
    .filter((item) => typeof item !== 'undefined' && item.value);

  if (!lastUsedMeasures || (lastUsedMeasures && lastUsedMeasures.length === 0)) {
    get(propsAndValList, 'fetchedData', [])
      .slice(1, 5)
      .forEach((item) => {
        if (!item.header) {
          const ei = {
            key: 'what',
            type: 'property',
            value: item.value,
            isExact: true,
          };
          measures.push(ei);
        }
      });
  }

  useEffect(() => {
    setStreams(get(propsAndValList, 'fetchedData', []).filter((item) => item.parent === 'Streams'));
  }, [propsAndValList]);

  useEffect(() => {
    dispatch(fetchPropAndValList(measuresPayload, 'noCache'));
    dispatch(fetchLastExpressionSearches({}, {userId: useProfileId}));
    dispatch(fetchLastUsedMeasures({}, {userId: useProfileId}));
  }, []);

  useEffect(() => {
    if (isForecastEnabled !== true) {
      measuresPayload.listDescription = measuresPayload.listDescription.filter(
        (listDescOption) => listDescOption.valueType !== 'forecasts',
      );
    }
  }, [isForecastEnabled]);
  useEffect(() => {
    if (lastExpressionSearchesItems && lastExpressionSearchesItems.length) {
      let tempExpressions = [];

      lastExpressionSearchesItems.forEach((exp) => {
        if (exp.length) {
          const expZero = exp[0];
          if (expZero.length) {
            const expression = expZero.map((expItem) => ExpressionItem.setExpressionFromObject(expItem, originInfo));
            tempExpressions.push(expression);
          }
        }
      });
      if (maxExpressionsCount) {
        tempExpressions = tempExpressions.slice(0, maxExpressionsCount);
      }
      setExpressions(tempExpressions);
    }
  }, [lastExpressionSearchesItems.length]);

  const handleClick = (value, type) => {
    dispatch(segmentClickEvent({type: 'click', name: `select-${type}`}));
    const exp = [];
    switch (type) {
      case 'measure':
        exp.push(value);
        break;
      case 'stream':
        exp.push({
          key: 'originId',
          type: 'origin',
          originType: 'Stream',
          value: value.value,
          isExact: true,
        });
        break;
      default:
    }

    const expression = {
      searchObject: {
        expression: exp,
      },
      children: [],
      type: 'metric',
      id: getUniqueId(),
      uiIndex: 0,
    };

    if (defaultFunction) {
      dispatch(
        initExpressionTreeModel({
          expressionTree: {
            root: {
              children: [expression],
              function: defaultFunction,
              id: getUniqueId(),
              parameters: [],
              type: 'function',
              uiIndex: 0,
            },
          },
        }),
      );
    } else {
      dispatch(
        initExpressionTreeModel({
          expressionTree: {
            root: expression,
          },
        }),
      );
    }
  };

  const handleExpressionClick = (index) => {
    dispatch(segmentClickEvent({type: 'click', name: 'select-last-used'}));
    dispatch(
      initExpressionTreeModel({
        expressionTree: {
          root: {
            searchObject: {
              expression: lastExpressionSearchesItems[index][0],
            },
            children: [],
            type: 'metric',
            id: getUniqueId(),
            uiIndex: 0,
          },
        },
      }),
    );
  };

  const renderFakeChips = (amount, size) => {
    const emptyArr = new Array(amount);
    const arr = emptyArr.fill(0);
    return arr.map((ele, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className={classes.fakeChip} style={{width: size}} />
    ));
  };

  const renderTitle = (title) => (
    <div automation-id={`${title.replace(/\s/g, '')}Title`} className="text16bold lineHeight_16">
      {title}
    </div>
  );

  const renderStreams = () => {
    if (!streams.length) {
      return (
        <div>
          {renderTitle('Data Streams')}
          <div className="display_flex">{renderFakeChips(2, 172)}</div>
        </div>
      );
    }

    return (
      <div automation-id="metricExplorerDataStreamTitle">
        {renderTitle('Data Streams')}
        <div className="display_flex overflow_hidden">
          {streams.slice(0, 3).map((stream, index) => (
            <Tooltip content={stream.label} type={TYPES.SMALL} key={stream.label + getUniqueId()}>
              <div
                automation-id={`quickStartStream_${index}`}
                className={`${classes.chips} ${classes.chipGray}`}
                onClick={() => handleClick(stream, 'stream')}
              >
                {stream.label}
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  };

  const renderMeasures = () => {
    if (isLastUsedMeasuresItemsLoading) {
      return (
        <div className="mt_3">
          {renderTitle('Recent Measures')}
          <div className="display_flex">{renderFakeChips(4, 112)}</div>
        </div>
      );
    }

    if (!isLastUsedMeasuresItemsLoading && !!measures.length) {
      return (
        <div automation-id="metricExplorerRecentMeasureTitle" className="mt_3">
          {renderTitle('Recent Measures')}
          <div className="display_flex overflow_hidden">
            {measures.slice(0, 4).map((measure, index) => (
              <Tooltip content={measure.value} type={TYPES.SMALL} key={measure.value + getUniqueId()}>
                <div
                  automation-id={`quickStartMeasure_${index}`}
                  className={`${classes.chips} ${classes.chipPurple}`}
                  onClick={() => handleClick(measure, 'measure')}
                >
                  {measure.value}
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      );
    }

    return '';
  };

  const renderExpressions = () => {
    if (isLastExpressionSearchesLoading) {
      return (
        <div className="mt_4-5">
          {renderTitle('Recently used expressions')}
          <div className="display_flex flexDirection_column mt_0-5">
            <div className="display_flex flexDirection_row">{renderFakeChips(3, 93)}</div>
            <div className="display_flex flexDirection_row">{renderFakeChips(3, 106)}</div>
          </div>
        </div>
      );
    }

    if (!isLastExpressionSearchesLoading && !!expressions.length) {
      return (
        <div className="mt_4-5">
          {renderTitle('Recently used expressions')}
          <div className="display_flex flexDirection_column mt_0-5">
            {expressions.slice(0, 2).map((expression, index) => (
              <Tooltip
                content="Click to add"
                type={TYPES.SMALL}
                placement="left"
                key={JSON.stringify(expression[0]) + getUniqueId()}
              >
                <div
                  automation-id={`quickStartExpression_${index}`}
                  className="mb_0-25"
                  onClick={() => handleExpressionClick(index)}
                >
                  <ExpressionLineDisplay expression={expression} />
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      );
    }

    return '';
  };

  return (
    <div className="idle-external-click width_1 height_1 display_flex justifyContent_center alignItems_center">
      <div automation-id="metricExplorerQuickStartBox" className={classes.quickStartWrapper}>
        <div className="text20light-gray600 mb_0-5 alignSelf_center">Quick Start</div>
        <div className="text14med lineHeight_14 alignSelf_center mb_4">Click to add to the metric search query</div>
        {renderStreams()}
        {renderMeasures()}
        {renderExpressions()}
      </div>
    </div>
  );
};

export default QuickActions;
