import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import useGetPreviewDataForTable from 'dataCollectors/hooks/useGetPreviewDataForTable';

const root = 'api/v1/bc/';

const getDatasetTablePreview = (sourceType, sourceId, projectId, datasetId, tableId) => {
  if (!projectId || !datasetId || !tableId) return null;
  return get(
    `${root}data-sources/${sourceType}/${sourceId}/previewTable?projectId=${projectId}&datasetId=${datasetId}&tableId=${tableId}`,
  );
};

export default function useFetchDatasetTablePreview(sourceType, sourceId, projectId, datasetId, tableId) {
  const selectFn = useCallback((res) => {
    return useGetPreviewDataForTable(res);
  }, []);

  const {
    data: tablePreviewData = {},
    isLoading: isTablePreviewLoading,
    isFetching: isTablePreviewFetching,
    refetch: refetchTablePreviewData,
  } = useQuery(
    QUERY_KEYS.datasetTablePreview,
    () => getDatasetTablePreview(sourceType, sourceId, projectId, datasetId, tableId),
    {
      enabled: !!(sourceType && sourceId && projectId && datasetId && tableId),
      select: selectFn,
    },
  );

  return {tablePreviewData, isTablePreviewFetching, isTablePreviewLoading, refetchTablePreviewData};
}
