// @flow
import React from 'react';
import Gauge from 'common/componentsV2/gauges/Gauge';
import fetchTriggeredAlertsTotalProvider from 'alerts.console.new/api/fetchTriggeredAlertsTotalProvider';
import {rangeTypes} from 'common/utils/dateRangeService';

const AlertsTriggeredGauge = ({
  description,
  index,
  selectedItem,
}: {
  description: String,
  index: Number,
  selectedItem: Number,
}) => {
  const totalTriggeredAlertsQuery = fetchTriggeredAlertsTotalProvider({constRange: rangeTypes.d1.key}).useQuery();

  const fetchData = () => {
    return totalTriggeredAlertsQuery.data?.total;
  };

  return (
    <Gauge
      description={description}
      value={totalTriggeredAlertsQuery.data?.total}
      isLoading={totalTriggeredAlertsQuery.isLoading}
      index={index}
      selectedItem={selectedItem}
      fetchData={fetchData}
    />
  );
};

export default AlertsTriggeredGauge;
