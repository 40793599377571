import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import {palette} from 'app/styles/theme';

const css = makeStyles(() => ({
  '@keyframes placeholder_animation': {
    '0%': {
      backgroundColor: palette.gray[150],
      animationTimingFunction: 'cubic-bezier(0.4, 0, 1, 1)',
    },
    '50%': {
      backgroundColor: palette.gray[225],
      animationTimingFunction: 'cubic-bezier(0, 0, 0.6, 1)',
    },
    '100%': {
      backgroundColor: palette.gray[150],
    },
  },
  loadingPlaceholder: {
    width: '100%',
    height: '100%',
    maxHeight: 250,
    flexGrow: 1,
    marginBottom: 16,
    borderRadius: 6,
    animation: '$placeholder_animation 1.4s linear infinite both',
  },
}));

const ContentLoader = ({rows}: PropTypes) => {
  const classes = css();

  // eslint-disable-next-line react/no-array-index-key
  return rows.map((row, index) => <div key={index} className={classes.loadingPlaceholder} style={row} />);
};

ContentLoader.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any),
};

export default ContentLoader;
