import React, {useCallback, useMemo, useRef, useState} from 'react';
import makeFirstSearchObjectCustomizer from 'common/utils/metrics/makeFirstSearchObjectCustomizer';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {cloneDeepWith, get, omit} from 'lodash';
import * as selectors from 'profile/store/selectors';
import {success} from 'common/utils/notifications/notificationsService';
import ColorPicker, {COLOR_NAME} from 'common/componentsV2/ColorPicker';
import {getDashboardDataFactory, getIsDashboardOwnerSelectorFactory} from 'dashboards/store/selectors';
import {makeStyles} from '@material-ui/core';
import {updateDashboard} from 'dashboards/store/actions';
import useDashboardState from 'dashboards/hooks/useDashboardState';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import {makeUpdateTilePayload} from 'dashboards/utils';
import {CHART_COLORS} from 'charts/timeSeries/services/timeSeriesHchartSettingsService';
import useAsyncAction from 'common/utils/useAsyncAction';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

type PropTypes = {
  metric: Object,
  onCopy: Function,
  tileData: Object,
  onChangeColor: Function,
  index: number,
  dashboardId: string,
  isPieChart: boolean,
};

const optionsColor = [
  COLOR_NAME.BLUE,
  COLOR_NAME.AZURE,
  COLOR_NAME.TEAL,
  COLOR_NAME.INDIGO_400,
  COLOR_NAME.PURPLE,
  COLOR_NAME.LILACH_400,
  COLOR_NAME.EGGPLANT,
  COLOR_NAME.ROSE,
  COLOR_NAME.ROSE_400,
  COLOR_NAME.TOMATO,
  COLOR_NAME.BROWN_400,
  COLOR_NAME.EUCALIPTUS,
  COLOR_NAME.MINT_600,
  COLOR_NAME.LIME,
];

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.gray[400],
    '&:hover': {
      color: theme.palette.blue[500],
    },
  },
  option: {
    margin: 4,
    padding: 8,
    borderRadius: 4,
    lineHeight: '16px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.blue[100],
    },
  },
  divider: {
    margin: 'auto',
    height: 1,
    width: 160,
    backgroundColor: theme.palette.gray[300],
  },
}));

const updateColor = ({metric, tileData, index, dataKey}) =>
  (get(tileData, `${dataKey}.legend.colors`, EMPTY_ARRAY).find((o) => (metric.id || '').includes(o.id)) || EMPTY_OBJECT)
    .color || CHART_COLORS.SERIES_COLORS_DASHBOARD[index % CHART_COLORS.SERIES_COLORS_DASHBOARD.length];

const MetricNameMenu = ({metric, onCopy, tileData, onChangeColor, index, dashboardId, isPieChart}: PropTypes) => {
  const dataKey = isPieChart ? 'andtGauge' : 'lineChart';
  const {
    location: {pathname},
  } = useHistory();
  const dashboardStateRef = useRef();
  const [color, setColor] = useState(updateColor({tileData, metric, index, dataKey}));
  const [isOpen, setIsOpen] = useState(false);

  const classes = useStyles();
  const store = useStore();

  const dispatch = useDispatch();
  const me = useSelector(selectors.getUserProfile);
  const isImpersonating = get(me, 'impersonatorId');

  const dashboard = useDashboardState(dashboardId);
  dashboardStateRef.current = dashboard;
  const isOwnerUserSelector = useMemo(() => getIsDashboardOwnerSelectorFactory(dashboardId), [dashboardId]);
  const isOwnerUser = useSelector(isOwnerUserSelector);

  const updateDashboardAsync = useAsyncAction(updateDashboard, dashboard);

  const metricDebugInfo = useMemo(() => {
    const leanMe = omit(me, ['imageSrc', '__v', 'aptrinsicHmac', 'profiles', 'createdAt', 'appSettings', 'disabled']);
    if (me.organization) {
      leanMe.organization = omit(me, ['settings']);
    }

    return {
      env: {
        href: window.location.href,
        host: window.location.host,
      },
      userInfo: leanMe,
      metricInfo: omit(metric, ['baseline', 'dataPoints']),
    };
  }, [me, metric]);

  const handleChangeDashboardSettings = useCallback(
    (option) => {
      if (option.value === 'copyMetricAsText') {
        navigator.clipboard.writeText(metric.name);
        dispatch(success({description: 'Metric copied successfully'}));
        return;
      }
      if (option.value === 'copyInfo') {
        navigator.clipboard.writeText(JSON.stringify(metricDebugInfo));
        dispatch(success({description: 'Info copied successfully'}));
        return;
      }
      window.sessionStorage.setItem(
        'metricExplorerTree',
        JSON.stringify(cloneDeepWith(metric.compositeObject.expressionTree, makeFirstSearchObjectCustomizer(metric))),
      );
      window.open('/#!/r/metrics-explorer');
    },
    [metric, onCopy, metricDebugInfo],
  );

  const options = useMemo(
    () => [
      ...(!pathname.includes('metrics-explorer')
        ? [
            {
              value: 'showInMetricExplorer',
              label: 'Show in Metric Explorer',
            },
          ]
        : []),
      {
        value: 'copyMetricAsText',
        label: 'Copy Metric as Text',
      },
      ...(isImpersonating && !isPieChart
        ? [
            {
              value: 'copyInfo',
              label: 'Copy Info.',
            },
          ]
        : []),
    ],
    [pathname, isImpersonating, isPieChart],
  );

  const handleChange = (val) => {
    const {id} = metric;
    const isExisting =
      get(tileData, `${dataKey}.legend.colors`, EMPTY_ARRAY).find((o) => (id || '').includes(o.id)) || EMPTY_OBJECT;
    const colors = get(tileData, `${dataKey}.legend.colors`, EMPTY_ARRAY);
    const tile = {
      ...tileData,
      [dataKey]: {
        ...tileData[dataKey],
        legend: {
          ...tileData[dataKey].legend,
          colors: Object.keys(isExisting).length
            ? colors.map((obj) => {
                if (obj.id === isExisting.id) {
                  return {
                    ...isExisting,
                    color: val,
                  };
                }

                return {
                  id,
                  color: val,
                };
              })
            : [
                ...colors,
                {
                  id,
                  color: val,
                },
              ],
        },
      },
    };
    updateDashboardAsync(makeUpdateTilePayload(dashboardStateRef.current.data, tile, tileData.id)).then(() => {
      setColor(val);
      if (onChangeColor) {
        onChangeColor({
          metricId: id,
          color: val,
        });
      }
    });
  };

  const handleRestore = () => {
    const tile = {
      ...tileData,
      [dataKey]: {
        ...tileData[dataKey],
        legend: {
          ...tileData[dataKey].legend,
          colors: EMPTY_ARRAY,
        },
      },
    };
    updateDashboardAsync(makeUpdateTilePayload(dashboardStateRef.current.data, tile, tileData.id)).then(() => {
      const response = getDashboardDataFactory(dashboardId)(store.getState());
      const newTileData = get(response, 'data.tiles', EMPTY_ARRAY).find((t) => t.id === tileData.id) || tileData;
      setColor(updateColor({tileData: newTileData, metric, index, dataKey}));
    });
  };

  const menuComponent = useMemo(
    () => (
      <React.Fragment>
        {metric.compositeObject && (
          <>
            {options.map((o) => (
              <div key={o.value} onClick={() => handleChangeDashboardSettings(o)} className={classes.option}>
                {o.label}
              </div>
            ))}
            <div className={classes.divider} />
          </>
        )}
        {isOwnerUser ? (
          <ColorPicker
            value={color}
            onChange={handleChange}
            options={optionsColor}
            handleRestore={handleRestore}
            withoutButton
          />
        ) : (
          undefined
        )}
      </React.Fragment>
    ),
    [pathname, isOwnerUser, handleChangeDashboardSettings, color],
  );

  const buttonComponent = useMemo(() => <div className={`icon icn-general16-3dot ${classes.button}`} />, []);

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <FormDdl
      popoverContainerClassName="metric-name-menu-container"
      onToggle={handleToggle}
      popoverComponent={menuComponent}
      buttonComponent={buttonComponent}
      isOpen={isOpen}
      width={184}
      maxWidth={184}
    />
  );
};

export default React.memo(MetricNameMenu);
