// @flow
import React from 'react';
import {connect} from 'react-redux';
import {bcTypes} from 'bc/services/bcTypes';
import {datadogSiteUrls} from 'bc/services/genericStorageService';
import * as selectors from 'bc/store/selectors';
import {createDataSource, resetAddSourceError} from 'bc/store/actions';
import '../../DataSourceModal.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import DataSourceModal from '../../DataSourceModal';

type PropTypes = {
  isOpen: boolean,
  isViewOnly: boolean,
  isEditable: boolean,
  sourceType: Object,
  source: Object,
  onClose: Function,

  // connect
  isLoading: boolean,
  sourceError: Object,

  createDataSource: Function,
  resetAddSourceError: Function,
};

export default connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource,
    resetAddSourceError,
  },
)(
  class DatadogSourceModal extends React.PureComponent {
    props: PropTypes;

    // eslint-disable-next-line no-extra-boolean-cast
    state = !!this.props.source
      ? {
          site: datadogSiteUrls.find((siteUrl) => siteUrl.value === this.props.source.site),
          apiKey: this.props.source.apiKey,
          applicationKey: this.props.source.applicationKey,
        }
      : {
          apiKey: '',
          applicationKey: '',
          site: datadogSiteUrls[0],
        };

    valueChanged = (val, inputId) => {
      const newState = {};
      newState[inputId] = val;
      this.setState(newState);
    };

    siteChanged = (val) => {
      this.setState({site: val});
    };

    createBtnClicked = () => {
      this.props.createDataSource({
        type: bcTypes.datadog.type,
        name: `Datadog_${this.state.streamName} Data Source ${new Date().getTime().toString()}`,
        site: this.state.site.value,
        apiKey: this.state.apiKey,
        applicationKey: this.state.applicationKey,
      });
    };

    onClose = () => {
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    render() {
      const {isOpen, source, sourceType, isLoading, sourceError, isViewOnly, isEditable} = this.props;
      const {site, apiKey, applicationKey} = this.state;
      const selectedIndex = datadogSiteUrls.findIndex((val) => val.value === site.value);

      return (
        <DataSourceModal
          isOpen={isOpen}
          isProcessing={isLoading}
          isViewOnly={isViewOnly}
          isRenameEnabled={isEditable}
          source={source}
          sourceType={sourceType}
          logoImageClass="source-datadog"
          onClose={this.onClose}
          isContinueDisabled={!apiKey || !applicationKey}
          onContinue={this.createBtnClicked}
        >
          <div styleName="inputs-wrapper">
            <SelectAndt
              id="s3RegionDdl1"
              automationId="s3Regions"
              styleName="region-dropdown"
              className="andt-dropdown"
              disabled={isViewOnly}
              options={datadogSiteUrls}
              getOptionLabel={(val) => val.text}
              getOptionValue={(val) => val.value}
              type={TYPE_NO_SEARCH}
              theme={THEME_LIGHT}
              value={datadogSiteUrls[selectedIndex]}
              onChange={(item) => this.siteChanged(item)}
              placeholder="Site"
            />
            <input
              type="text"
              className={!isLoading && sourceError ? 'invalid' : ''}
              onChange={(e) => this.valueChanged(e.target.value, 'apiKey')}
              placeholder="API Key"
              required
              readOnly={isViewOnly}
              disabled={isViewOnly}
              value={apiKey}
            />

            <input
              type="text"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              onChange={(e) => this.valueChanged(e.target.value, 'applicationKey')}
              placeholder="Application Key"
              value={applicationKey}
            />

            {!isLoading && sourceError ? (
              <div styleName="error-wrapper error-wrapper-s3">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" /> {sourceError.uMessage}
                </span>
              </div>
            ) : null}

            <div styleName="extra-info">
              <a
                href="https://support.anodot.com/hc/en-us/articles/4405505421202"
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn more
              </a>
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
