import React, {useRef} from 'react';

import {Box, Popper} from '@material-ui/core';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Spinner from 'common/componentsV2/Spinner';

const Estimation = ({
  isSimulationLoading,
  autoSimulationEnabled,
  totalEstimation,
  isSimulationViewActive,
  onExecuteSimulation,
  isSimulationDisabled,
  isAutoSimulationPristine,
}: {
  isSimulationLoading: boolean,
  autoSimulationEnabled: boolean,
  totalEstimation: string,
  isSimulationViewActive: boolean,
  isSimulationDisabled: boolean,
  onExecuteSimulation: Function,
  isAutoSimulationPristine: boolean,
}) => {
  const startSimulationRef = useRef();
  let content = (
    <Box fontSize={14} fontWeight={500} color="gray.500" css={{opacity: 0.6}} width={214} textAlign="center">
      Estimated numbers of alerts will show once simulation is complete
    </Box>
  );
  if (!isSimulationDisabled && isSimulationLoading) {
    content = <Spinner size={50} />;
  } else if (isSimulationViewActive) {
    const estimationArr = (totalEstimation || '').split('/');
    const estimationInt = parseInt(estimationArr[0] || '', 10);
    if (estimationArr[0] === 'No alerts') {
      content = <div className="text16reg lineHeight_16">Less than one Alert</div>;
    } else {
      content = (
        <React.Fragment>
          <div className="text16reg lineHeight_16">Estimated rate of</div>
          <Box fontSize={35.4} fontWeight={500} color="gray.500" mx={1}>
            {estimationArr[0] && estimationArr[0] !== 'No alerts' && !isNaN(estimationInt) ? estimationInt : 0}
          </Box>
          <TypographyBox width={48} variant="subtitle1" textAlign="center">
            Alerts /{` ${estimationArr[1] || 'Day'}`}
          </TypographyBox>
        </React.Fragment>
      );
    }
  }

  if (!autoSimulationEnabled) {
    return (
      <Box
        padding={1}
        height={48}
        borderRadius={6}
        bgcolor="rgba(38, 113, 255, 0.1)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        minWidth={238}
        automation-id="startSimulation"
        css={isSimulationDisabled ? {opacity: 0.59, pointerEvents: 'none'} : {cursor: 'pointer'}}
        onClick={() => {
          onExecuteSimulation(0);
        }}
      >
        <Popper
          open={(isAutoSimulationPristine && !isSimulationDisabled && startSimulationRef.current && true) || false}
          anchorEl={startSimulationRef.current}
          placement="top"
          disablePortal
        >
          <Box
            borderRadius={6}
            position="relative"
            width={273}
            height={66}
            bgcolor="blue.500"
            py={1}
            px={1.5}
            lineHeight="normal"
            color="white.500"
          >
            Once you’re done with the <b>Alert Metrics</b>
            , start the simulation to see how many alerts you will get.
            <Box
              position="absolute"
              width={12}
              height={12}
              bottom={-6}
              left="calc(50% - 6px)"
              bgcolor="blue.500"
              css={{transform: 'rotate(45deg)'}}
            />
          </Box>
        </Popper>
        <Box ref={startSimulationRef} fontSize={22} fontWeight={500} color="blue.500">
          Start Simulation
        </Box>
      </Box>
    );
  }

  return (
    <Box
      padding={1}
      height={48}
      borderRadius={6}
      bgcolor="rgba(38, 113, 255, 0.1)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      minWidth={238}
    >
      {content}
    </Box>
  );
};

export default Estimation;
