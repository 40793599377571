// @flow
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {GenerateIcon, ICONS} from '@pileus-cloud/anodot-frontend-common';

const useStyles = makeStyles(() => ({
  recommendationIcon: {
    color: palette.blue[500],
  },
}));

const RecommendationSign = ({
  tooltipContent,
  hasRecommendations,
  recommendationClicked,
}: {
  tooltipContent: String,
  hasRecommendations: Boolean,
  recommendationClicked: Function,
}) => {
  const classes = useStyles();

  const onRecommendationClick = () => {
    if (recommendationClicked) {
      recommendationClicked();
    }
  };

  return hasRecommendations ? (
    <div className="alert-clickable-item" role="button" onClick={() => onRecommendationClick()}>
      <Tooltip content={tooltipContent} type={TYPES.SMALL} delay={100}>
        <GenerateIcon iconName={ICONS.recommendations.name} className={classes.recommendationIcon} />
      </Tooltip>
    </div>
  ) : (
    <div />
  );
};

export default RecommendationSign;
