import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import useGetPreviewDataForTable from 'dataCollectors/hooks/useGetPreviewDataForTable';

const root = 'api/v1/bc/';

const getTablePreview = (sourceType, sourceId, schemaName, tableName) => {
  if (!schemaName || !tableName) return null;
  return get(`${root}data-sources/${sourceType}/${sourceId}/preview?schemaName=${schemaName}&tableName=${tableName}`);
};

export default function useFetchTablePreview(sourceType, sourceId, schemaName, tableName) {
  const selectFn = useCallback((res) => {
    return useGetPreviewDataForTable(res);
  }, []);

  const {
    data: tablePreviewData = {},
    isLoading: isTablePreviewLoading,
    isFetching: isTablePreviewFetching,
    refetch: refetchTablePreviewData,
  } = useQuery(QUERY_KEYS.tablePreview, () => getTablePreview(sourceType, sourceId, schemaName, tableName), {
    enabled: !!(sourceType && sourceId && schemaName && tableName),
    select: selectFn,
  });

  return {tablePreviewData, isTablePreviewFetching, isTablePreviewLoading, refetchTablePreviewData};
}
