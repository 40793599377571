// @flow
import React from 'react';
import {connect} from 'react-redux';
import {warning} from 'common/utils/notifications/notificationsService';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import TimeZone from 'bc/components/streams/editor/common/TimeZone';
import SchedularDateRange from 'bc/components/streams/editor/common/SchedularDateRange';
import PollingInterval from 'bc/components/streams/editor/common/PollingInterval';
import {getAllowedRangeTypesByPollingInterval, pollingIntervals} from 'bc/services/dataStreamService';
import './SqlSchedularEditor.module.scss';
import {DVP, getMaxDurationHours} from 'bc/components/streams/editor/missingDataSection/missingDataService';

type PropTypes = {
  dataStream: Object,
  setSelectedStreamKeyVal: Function,
};

class SqlSchedularEditor extends React.PureComponent {
  props: PropTypes;

  setNewDuration = (newPollingInterval) => {
    const {dataStream} = this.props;
    const maxDurationHours = getMaxDurationHours(pollingIntervals[newPollingInterval].timeScale);

    if (dataStream.dvpConfig[DVP.maxDVPDurationHours.type] <= maxDurationHours) {
      return dataStream.dvpConfig[DVP.maxDVPDurationHours.type];
    }
    const newMinDuration = getMaxDurationHours(pollingIntervals[newPollingInterval].timeScale);

    warning({
      title: 'Please Note',
      description: `Changing this data stream's collection time to ${pollingIntervals[newPollingInterval].label}, 
      automatically changes the max duration value to ${newMinDuration} hours in the Missing Data settings.`,
    });
    return newMinDuration;
  };

  matchDvpConfigValues = (newPollingInterval) => {
    const {dataStream} = this.props;

    if (dataStream.dvpConfig) {
      return {
        ...dataStream.dvpConfig,
        [DVP.baseRollup.type]: pollingIntervals[newPollingInterval].timeScale,
        [DVP.maxDVPDurationHours.type]: this.setNewDuration(newPollingInterval),
      };
    }
    return null;
  };

  pollingChanged = (val) => {
    const dvpConfig = this.matchDvpConfigValues(val);
    this.props.setSelectedStreamKeyVal({pollingInterval: val, dvpConfig});
  };

  dateRangeChanged = (val) => {
    this.props.setSelectedStreamKeyVal({historicalDateRange: val});
  };

  timeZoneChanged = (obj) => {
    this.props.setSelectedStreamKeyVal(obj);
  };

  valueChanged = (e) => {
    const val = parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0;
    this.props.setSelectedStreamKeyVal({delayMinutes: val});
  };

  maxBackFillIntervalsChanged = (e) => {
    const val = parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0;
    this.props.setSelectedStreamKeyVal({maxBackFillIntervals: val});
  };

  render() {
    return (
      <div styleName="root">
        <PanelHeader title="Schedule Records Collection" />
        <div styleName="inner-root">
          <div styleName="item">
            <span styleName="item-title">Query every</span>
            <PollingInterval
              pollingInterval={this.props.dataStream.pollingInterval}
              styleName="item-width"
              allowedValues={['daily', 'h12', 'h8', 'h6', 'h4', 'h3', 'h2', 'hourly', 'm15', 'm5', 'm1']}
              onChange={(v) => this.pollingChanged(v)}
            />
          </div>

          <div styleName="item collect-on">
            <span className="ellipsis" styleName="item-title">
              Records Delay (Minutes)
            </span>
            <span styleName="item-description">
              To minimize partial results in queries, set the delay according to the delay it takes your internal
              process to prepare the data records.
            </span>
            <input type="number" onChange={this.valueChanged} min="0" value={this.props.dataStream.delayMinutes} />
          </div>
        </div>

        <div styleName="inner-root">
          <div styleName="item">
            <span className="ellipsis" styleName="item-title">
              Records Time Zone
            </span>
            <span styleName="item-description">Process records according to this time zone.</span>
            <TimeZone
              timeZone={this.props.dataStream.timeZone}
              extraClassName="blue-style"
              styleName="item-width"
              onChange={(v) => this.timeZoneChanged(v)}
            />
          </div>

          <div styleName="item collect-on">
            <span className="ellipsis" styleName="item-title">
              Query Backfill Policy
            </span>
            <span styleName="item-description">
              Set policy to backfill missing data records after stream Init.
              <br />
              0: Query last interval, 1: Query preceding and last intervals.
            </span>
            <input
              type="number"
              onChange={this.maxBackFillIntervalsChanged}
              min="0"
              value={this.props.dataStream.maxBackFillIntervals}
            />
          </div>
        </div>

        <div styleName="inner-root">
          <div styleName="item">
            <span className="ellipsis" styleName="item-title">
              Historical Time Span
            </span>
            <span styleName="item-description">Time span of records to query while initializing a stream.</span>
            <div styleName="item-width">
              <SchedularDateRange
                dateRange={this.props.dataStream.historicalDateRange}
                title="Time span"
                isUnix
                allowedRangeTypes={getAllowedRangeTypesByPollingInterval(this.props.dataStream.pollingInterval)}
                onChange={(v) => this.dateRangeChanged(v)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)(SqlSchedularEditor);
