// @flow
import React from 'react';
import ConfModal from 'common/componentsV2/modal/ConfModal';

type PropsType = {
  onClose: Function,
  onConfirm: Function,
  isLoading: boolean,
};

const SaveModal = ({onClose, onConfirm, isLoading}: PropsType) => (
  <ConfModal
    isOpen
    onClose={onClose}
    onConfirm={onConfirm}
    confirmText="Yes, save"
    title="You are about to change the default view"
    isAnimation={false}
    isAsync
    isLoading={isLoading}
  >
    <div className="fontSize14 lineHeight_18 mb_6">
      {/* eslint-disable-next-line max-len */}
      Saving these filters will create a new default view of the dashboard for all users. Are you sure you want to save
      these filters?
    </div>
  </ConfModal>
);

export default SaveModal;
