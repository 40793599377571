import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';

import useHandleResize from 'common/hooks/useHandleResize';
import StepsIndicator from 'dataCollectors/components/stepIndicator/StepsIndicator';
import StreamHeaderEdit from 'dataCollectors/components/StreamHeader/StreamHeaderEdit';
import FooterNav from 'dataCollectors/components/editStreamFooter/EditStreamFooter';
import PageLayout from 'common/componentsV2/PageLayout';
import {useEventStreamContext} from 'dataCollectors/hooks/useEventStreamContext';
import ExtractPage from 'dataCollectors/pages/eventStream/editPages/ExtractPage';
import TransformPage from 'dataCollectors/pages/eventStream/editPages/TransformPage';
import LoadPage from 'dataCollectors/pages/eventStream/editPages/LoadPage';

const leftSideWidth = 200;

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'relative',
    display: 'flex',
  },
  mainLeft: {
    width: leftSideWidth,
  },
  mainRight: {
    right: 0,
    position: 'absolute',
    overflow: 'scroll',
    left: leftSideWidth,
    height: '100%',
  },
  footerContainer: {
    height: 70,
    left: 60,
    right: 0,
    bottom: 0,
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0 10px 0 rgb(0 0 0 / 20%)',
    backgroundColor: theme.palette.white[500],
  },
}));

function PageSelector() {
  const {editStreamPageReducerState} = useEventStreamContext();
  const currentStageIndex = editStreamPageReducerState.findIndex((stage) => stage.isCurrent);

  const pageSelector = useMemo(() => {
    switch (currentStageIndex) {
      case 0:
        return <ExtractPage />;
      case 1:
        return <TransformPage />;
      case 2:
        return <LoadPage />;
      case 3:
      default:
        return new Error(`currentStageIndex is ${currentStageIndex} and not found`);
    }
  });

  return <>{pageSelector}</>;
}

const EventStreamEdit = () => {
  const classes = useStyles();
  const bodyHeight = useHandleResize([275]);

  return (
    <PageLayout header={<StreamHeaderEdit />}>
      <div className={classes.mainContainer} style={{height: bodyHeight}}>
        <div className={classes.mainLeft}>
          <StepsIndicator />
        </div>
        <div className={classes.mainRight}>
          <PageSelector />
        </div>
      </div>
      <div className={classes.footerContainer}>
        <div style={{height: 180}} />
        <FooterNav />
      </div>
    </PageLayout>
  );
};

export default EventStreamEdit;
