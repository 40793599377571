// @flow
import React from 'react';
import PropTypes from 'prop-types';
import './SchedulerEditorModal.module.scss';

const DelayMinutes = (props: PropTypes) => {
  return (
    <div>
      <div styleName="titleWrapper">Delay (Hours):</div>
      <div styleName="slideWrapper">
        <div styleName="slideWrapper-text">
          <input
            type="number"
            styleName="textInput_4"
            onChange={(e) => props.onChange(e.target.value * 60)}
            min={props.minVal / 60}
            max={props.maxVal / 60}
            value={props.delayMinutes / 60}
          />
        </div>
        <div styleName="slideWrapper-slider" />
      </div>
    </div>
  );
};

DelayMinutes.propTypes = {
  delayMinutes: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  maxVal: PropTypes.number.isRequired,
  minVal: PropTypes.number.isRequired,
};

export default DelayMinutes;
