// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {Group} from 'anodot-objects-models';
import Button, {COLORS as ButtonColors} from 'common/componentsV2/Button';
import SideBarTabs from 'common/componentsV2/sideBarTabs/SideBarTabs';
import {getMeOwnerOrganization} from 'profile/store/selectors';
import {getIsRbacEnabled} from 'admin.permissions/store/selectors';
import {
  getEditingGroup,
  getEditingSelectedUsers,
  getGroupModalSideBarTabs,
  getGroupsModalGroupColorSchema,
  getGroupsModalGroupName,
  getGroupsModalGroupUsers,
} from 'admin.users/store/selectors';
import {
  createGroup,
  setGroupProps as setGroupPropsAction,
  toggleGroupsModal,
  updateGroup,
} from 'admin.users/store/actions';
import PropertiesSection from './PropertiesSection';
import MembersSection from './MembersSection';
import GroupAccessSection from './GroupAccessSection';
import {COLORS as GroupItemColors} from '../GroupsItem';
import './GroupForm.module.scss';

type PropTypes = {
  group: Object,
  groupSelectedUsers: Array,
  ownerOrganization: String,
  groupName: String,
  selectedColor: String,
  selectedUsers: Array,
  tabs: Array,
  createGroup: Function,
  updateGroup: Function,
  toggleGroupsModal: Function,
  setGroupProps: Function,
  isRbacEnabled: Boolean,
};

export default connect(
  (state) => ({
    ownerOrganization: getMeOwnerOrganization(state),
    groupName: getGroupsModalGroupName(state),
    selectedColor: getGroupsModalGroupColorSchema(state),
    selectedUsers: getGroupsModalGroupUsers(state),
    group: getEditingGroup(state),
    groupSelectedUsers: getEditingSelectedUsers(state),
    tabs: getGroupModalSideBarTabs(state),
    isRbacEnabled: getIsRbacEnabled(state),
  }),
  {
    createGroup,
    updateGroup,
    toggleGroupsModal,
    setGroupProps: setGroupPropsAction,
  },
)(
  class GroupForm extends React.PureComponent {
    props: PropTypes;

    state = {
      selectedTabId: 0,
    };

    componentDidMount() {
      const {group, groupSelectedUsers, setGroupProps} = this.props;
      if (group) {
        setGroupProps({
          groupName: group.name,
          selectedColor: group.colorSchema,
          selectedUsers: groupSelectedUsers,
        });
      } else {
        setGroupProps({groupName: '', selectedColor: GroupItemColors.GRAY, selectedUsers: []});
      }
    }

    tabClicked = (tabId) => {
      this.setState({
        selectedTabId: tabId,
      });
    };

    cancelClicked = () => {
      this.props.toggleGroupsModal({isOpen: false});
    };

    okClicked = () => {
      const {ownerOrganization, groupName, selectedColor, selectedUsers, group} = this.props;

      this.props.toggleGroupsModal({isOpen: false});

      const newGr = group ? new Group(group.toObject()) : new Group({});
      newGr.name = groupName;
      newGr.colorSchema = selectedColor;
      newGr.users = selectedUsers.map((u) => u.id);
      newGr.ownerOrganization = ownerOrganization;

      if (group) {
        this.props.updateGroup(
          {
            ownerOrganization,
            group: newGr.toObject(),
          },
          {groupUsersIds: group.users},
        );
      } else {
        this.props.createGroup({
          ownerOrganization,
          group: newGr.toObject(),
        });
      }
    };

    renderMainTab = () => {
      switch (this.state.selectedTabId) {
        case 0:
          return <PropertiesSection />;
        case 1:
          return <MembersSection />;
        case 2:
          return <GroupAccessSection />;
        default:
          return null;
      }
    };

    render() {
      const {group, groupName, tabs, isRbacEnabled} = this.props;
      const {selectedTabId} = this.state;

      return (
        <div styleName="container">
          <div styleName="body">
            <div styleName="sidebar">
              <div styleName="header">{group ? 'Edit Group' : 'New Group'}</div>
              <SideBarTabs
                styleName="tabs-menu"
                tabs={tabs.filter((tab) => tab.id !== 2 || (tab.id === 2 && isRbacEnabled))}
                selectedTabId={selectedTabId}
                onTabClick={this.tabClicked}
              />
            </div>
            <div styleName="main">{this.renderMainTab()}</div>
          </div>
          <div styleName="footer">
            <span styleName="cancel-btn">
              <Button text="Cancel" colorSchema={ButtonColors.GRAY_300} onClick={this.cancelClicked} />
            </span>
            <Button
              text={group ? 'Update' : 'Create'}
              colorSchema={ButtonColors.BLUE_500}
              onClick={this.okClicked}
              isDisabled={!groupName || Group.isGroupNameInvalid(Group.cleanGroupName(groupName))}
            />
          </div>
        </div>
      );
    }
  },
);
