import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Highlighter from 'react-highlight-words';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {getFormattedDateMini, getFormattedDateFull} from 'common/utils/dateService';
import TableItemActions from 'assets/components/actions/TableItemActions';
import Tooltip from 'common/componentsV2/Tooltip';
import {commonStyle, TYPES} from 'assets/services/service';
import {fetchLookupTablePreview} from 'bc/store/actions';
import {viewDynamicRoutingFile} from 'assets/store/actions';
import {getIsLookupPreviewDataLoading, getLookupPreviewDataNoHeaders} from 'bc/store/selectors';
import {getViewDynamicRoutingFileData, getViewDynamicRoutingFileIsLoading} from 'assets/store/selectors';
import CsvPreviewModal from 'assets/components/page/CsvPreviewModal';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';

type PropTypes = {
  id: String,
  title: String,
  type: String,
  ownerName: String,
  creationTime: Number,
  editTime: Number,
  alertsUsed: Array,
  searchedWords: String,
};

const useStyles = makeStyles(({palette}) => ({
  main: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: commonStyle.tableRow.padding,
    backgroundColor: palette.white['500'],
    borderBottom: `solid 1px ${palette.gray['200']}`,
    cursor: 'default',
    '&:hover': {
      backgroundColor: palette.blue['100'],
    },
  },
  item: {
    padding: commonStyle.tableItem.padding,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  typeWrapper: {
    borderRadius: 12,
    backgroundColor: palette.gray['200'],
    padding: '2px 8px',
    display: 'inline-block',
  },
  typeIcon: {
    position: 'relative',
    top: 1,
    marginRight: 5,
  },
  tooltipWrapper: {
    whiteSpace: 'pre-line',
  },
  alertsUsedNumber: {
    '&:hover': {
      color: palette.blue['500'],
      cursor: 'pointer',
    },
  },
  alertsUsedNumberDisabled: {
    color: palette.gray['300'],
    cursor: 'auto',
  },
}));

const TableItem = ({id, type, title, creationTime, editTime, ownerName, alertsUsed, searchedWords}: PropTypes) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [hover, setHover] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const isLookupPreviewDataLoading = useSelector(getIsLookupPreviewDataLoading);
  const viewDynamicRoutingFileIsLoading = useSelector(getViewDynamicRoutingFileIsLoading);
  const lookupPreviewData = useSelector(getLookupPreviewDataNoHeaders);
  const viewDynamicRoutingFileData = useSelector(getViewDynamicRoutingFileData);

  const openFileInModal = () => {
    setIsPreviewModalOpen(true);

    if (type === TYPES.LOOKUP) {
      dispatch(fetchLookupTablePreview({id}));
    } else if (type === TYPES.DYNAMIC_ROUTING) {
      dispatch(viewDynamicRoutingFile({id}));
    }
  };

  const openAlertConfiguration = (alertConfiguration) => {
    window.open(`/#!/r/alert-manager/edit/${alertConfiguration.id}/settings`, `_blank-${alertConfiguration.id}`);
  };

  return (
    <Grid container className={classes.main} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <Grid item className={classes.item} sm={2}>
        <Tooltip content={title}>
          <TypographyBox variant="caption" component="span">
            <Highlighter
              autoEscape
              highlightStyle={{backgroundColor: 'palette.orange[350]'}}
              searchWords={[searchedWords]}
              textToHighlight={title}
            />
          </TypographyBox>
        </Tooltip>
      </Grid>
      <Grid item className={classes.item} sm={2}>
        <Tooltip content={type}>
          <div className={classes.typeWrapper}>
            <i
              className={
                type === TYPES.LOOKUP
                  ? `${classes.typeIcon} icn-general16-document`
                  : `${classes.typeIcon} icn-general16-split`
              }
            />
            <TypographyBox variant="subtitle1" component="span">
              {type === TYPES.LOOKUP ? 'Lookup Table' : 'Dynamic Routing'}
            </TypographyBox>
          </div>
        </Tooltip>
      </Grid>
      <Grid item className={classes.item} sm={2}>
        <Tooltip content={ownerName}>
          <TypographyBox variant="subtitle1" component="span">
            {ownerName}
          </TypographyBox>
        </Tooltip>
      </Grid>
      <Grid item className={classes.item} sm={2}>
        {type === TYPES.DYNAMIC_ROUTING ? (
          <FormDdlSelect
            button={
              <Tooltip
                content={Array.isArray(alertsUsed) ? alertsUsed.map((alert) => alert.title).join('\r\n') : undefined}
                extraTtClass={classes.tooltipWrapper}
              >
                <span className={alertsUsed?.length ? classes.alertsUsedNumber : classes.alertsUsedNumberDisabled}>
                  {alertsUsed?.length || 0}
                </span>
              </Tooltip>
            }
            disabled={!alertsUsed?.length}
            optionComponent={<OptionComponentSimple />}
            options={alertsUsed?.map((a) => ({label: a.title, value: a.title, id: a.id}))}
            onChange={openAlertConfiguration}
            width={252}
          />
        ) : (
          <Tooltip content="Not relevant for Lookup Table">
            <span>n/a</span>
          </Tooltip>
        )}
      </Grid>
      <Grid item className={classes.item} sm={2}>
        <Tooltip content={getFormattedDateFull(creationTime)}>
          <TypographyBox variant="subtitle1" component="span">
            {getFormattedDateMini(creationTime)}
          </TypographyBox>
        </Tooltip>
      </Grid>
      <Grid item className={classes.item} sm={2}>
        <Tooltip content={getFormattedDateFull(editTime)}>
          <TypographyBox variant="subtitle1" component="span">
            {getFormattedDateMini(editTime)}
          </TypographyBox>
        </Tooltip>
      </Grid>
      {hover && (
        <TableItemActions
          file={{
            id,
            type,
            title,
            creationTime,
            editTime,
            ownerName,
          }}
          openFileInModal={openFileInModal}
        />
      )}
      <CsvPreviewModal
        isSmall
        isOpen={isPreviewModalOpen}
        onClose={() => {
          setIsPreviewModalOpen(false);
        }}
        ownerName={ownerName}
        title={type === TYPES.LOOKUP ? 'Preview Reference Table' : 'Preview Routing Table'}
        subTitle={title}
        previewData={type === TYPES.LOOKUP ? lookupPreviewData : viewDynamicRoutingFileData}
        isLoading={type === TYPES.LOOKUP ? isLookupPreviewDataLoading : viewDynamicRoutingFileIsLoading}
      />
    </Grid>
  );
};

export default TableItem;
