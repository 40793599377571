// @flow
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'bc/services/dataStreamService';

const root = 'api/v1/bc/';
const fetchDataStreams = () => get(`${root}data-streams`);

export default function useFetchDataStreamsList() {
  const fallback = [];
  const {
    dataUpdatedAt,
    data: dataStreamsList = fallback,
    isLoading: isDataStreamsListLoading,
    isFetching: isDataStreamsLisFetching,
  } = useQuery(QUERY_KEYS.DATA_STREAM, fetchDataStreams);

  return {
    updatedAt: dataUpdatedAt,
    dataStreamsList,
    isDataStreamsListLoading,
    isDataStreamsLisFetching,
  };
}
