/* eslint-disable import/prefer-default-export */
import React from 'react';
import {ReactComponent as Overview} from 'investigationNewAlertConsole/images/color24-overview.svg';
import {ReactComponent as Incident} from 'investigationNewAlertConsole/images/color24-incident.svg';
import {ReactComponent as Correlations} from 'investigationNewAlertConsole/images/color24-correlations.svg';
import {ReactComponent as Timeline} from 'investigationNewAlertConsole/images/color24-timeline.svg';
import {palette} from 'app/styles/theme';
import {omit} from 'lodash';

export const modalRouting = {
  INVESTIGATION_MODAL: 'investigationModal',
  ANOMALY_ID: 'anomalyIdInv',
  TRIGGER_ID: 'triggerIdInv',
};

export const ALERT_TYPES = {
  ANOMALY: 'ANOMALY',
  STATIC: 'STATIC',
  NO_DATA: 'NO_DATA',
};

export const ALERT_TYPES_LABELS = {
  ANOMALY: 'Anomaly',
  STATIC: 'Static',
  NO_DATA: 'No Data',
};

export const ANOMALY_COLUMNS = {
  TITLE: {
    id: 0,
    name: 'title',
    title: '',
    isSortable: false,
  },
  // Until BE will add this field to metrics in anomalies/metrics API
  // IMPACT: {
  //   id: 1,
  //   name: 'impact',
  //   title: 'Impact',
  //   isSortable: true,
  // },
  DURATION: {
    id: 2,
    name: 'duration',
    title: 'Duration',
    isSortable: true,
  },
  DELTA: {
    id: 3,
    name: 'delta',
    title: 'Delta',
    isSortable: true,
  },
  SCORE: {
    id: 5,
    name: 'score',
    title: 'Score',
    isSortable: true,
  },
};

export const STATIC_NO_DATA_COLUMNS = {
  TITLE: {
    id: 0,
    name: 'title',
    title: '',
    isSortable: false,
  },
  // Until BE will add this field to metrics in anomalies/metrics API
  // IMPACT: {
  //   id: 1,
  //   name: 'impact',
  //   title: 'Impact',
  //   isSortable: true,
  // },
  START_TIME: {
    id: 2,
    name: 'startTime',
    title: 'Started',
    isSortable: true,
  },
  DURATION: {
    id: 3,
    name: 'duration',
    title: 'Duration',
    isSortable: true,
  },
};

export const calculateAnomalyAlertColumns = (measures) => {
  let columns = [];

  let metricWithDelta;
  // let impact;
  if (Object.keys(measures).length > 0) {
    columns = Object.values(ANOMALY_COLUMNS);
    Object.keys(measures).forEach((what) => {
      if (measures[what].isLoading === false) {
        if (!metricWithDelta && measures[what]?.data.metrics.find((metric) => metric.lowerPercentageDelta)) {
          metricWithDelta = measures[what].data.metrics.find((metric) => metric.lowerPercentageDelta);
        }
        if (!metricWithDelta && measures[what].data.metrics.find((metric) => metric.upperPercentageDelta)) {
          metricWithDelta = measures[what].data.metrics.find((metric) => metric.upperPercentageDelta);
        }
        // if (!impact && measures[what].data.originSourceIds) {
        //   impact = measures[what].data.originSourceIds;
        // }
      }
    });
  }
  if (!metricWithDelta) {
    columns = columns.filter((column) => column.name !== ANOMALY_COLUMNS.DELTA.name);
  }
  // if (!impact) {
  //   columns = columns.filter((column) => column.name !== ANOMALY_COLUMNS.IMPACT.name);
  // }
  return columns;
};

export const calculateTimelineView = (timeline, users) => {
  if (timeline?.length && users?.length) {
    return timeline?.map((tle) => {
      const userFound = users.find((user) => tle.userId && tle.userId === user.id);
      const newAssigneeUserFound = users.find((user) => tle.newAssignee && tle.newAssignee === user.id);
      const prevAssigneeUserFound = users.find((user) => tle.previousAssignee && tle.previousAssignee === user.id);

      return {
        ...tle,
        userFullName: userFound?.fullName,
        newAssigneeFullName: newAssigneeUserFound?.fullName,
        prevAssigneeFullName: prevAssigneeUserFound?.fullName,
      };
    });
  }
  return [];
};

export const INVESTIGATION_MODAL_TABS = {
  overview: {
    label: 'Overview',
    id: 'overview',
    isEnabled: true,
    icon: <Overview />,
  },
  incident: {
    label: 'Incident',
    id: 'incident',
    isEnabled: true,
    icon: <Incident />,
  },
  correlations: {
    label: 'Correlations',
    id: 'correlations',
    isEnabled: true,
    icon: <Correlations />,
  },
  timeline: {
    label: 'Timeline',
    id: 'timeline',
    isEnabled: true,
    icon: <Timeline />,
  },
};

export const getInvestigationModalTabs = (type) => {
  switch (type) {
    case ALERT_TYPES.ANOMALY:
      return INVESTIGATION_MODAL_TABS;
    case ALERT_TYPES.STATIC:
    case ALERT_TYPES.NO_DATA:
      return omit(INVESTIGATION_MODAL_TABS, ['correlations']);
    default:
      return {};
  }
};

export const BUFFER_TIMES = {
  alerts: {
    '1m': 120,
    '5m': 600,
    '1h': 3600,
    '1d': 86400,
    '1w': 0,
  },
  anomalies: {
    short: 60 * 60 * 13,
    medium: 60 * 60 * 13,
    long: 60 * 60 * 24 * 15,
    longlong: 60 * 60 * 24 * 15,
    weekly: 60 * 60 * 24 * 15,
  },
};

export const ANOMALIES_DEFAULT_QUERY_PARAMS = {
  alertId: '',
  anomalyType: 'all',
  baseline: false,
  correlation: '',
  datapoints: false,
  delta: 1,
  deltaType: 'percentage',
  durationUnit: 'minutes',
  durationValue: 1,
  index: 0,
  order: 'desc',
  resolution: 'medium',
  score: 0,
  size: 10,
  sort: 'score',
  state: 'both',
  valueDirection: 'both',
};

export const anomalyIntervalsToAlertTriggerFormat = (anomalyIntervals) => {
  if (!anomalyIntervals || anomalyIntervals.length === 0) {
    return null;
  }

  return (anomalyIntervals || []).map((a) => ({
    startTime: a.startDate,
    endTime: a.endDate,
    duration: a.endDate - a.startDate,
    status: a.state.toLowerCase() === 'open' ? 'OPEN' : 'CLOSE',
    score: a.score,
    direction: a.directionUp ? 'UP' : 'DOWN',
    peak: a.peakValue,
    deltaAbsolute: a.absoluteDelta,
    deltaPercentage: a.percentageDelta,
    sumDeltas: a.anomalySumDeltas,
  }));
};

const ROW_DEFAULT_HEIGHT = {
  sm: 48,
  mid: 80,
};

export const TIMELINE_ENTRIES = {
  ALERT_OPENED: {
    value: 'ALERT_OPENED',
    title: 'Alert Opened',
    icon: 'icn-nav24-alertconsole1',
    color: palette.red['500'],
    bgColor: palette.red['10'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  ANOTHER_ALERT_ADDED: {
    value: 'ANOTHER_ALERT_ADDED',
    title: 'Another alert added',
    icon: 'icn-nav24-alertconsole1',
    color: palette.orange['500'],
    bgColor: palette.orange['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.mid,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  ALERT_CLOSED: {
    value: 'ALERT_CLOSED',
    title: 'Alert Closed',
    icon: 'icn-nav24-alertconsole1',
    color: palette.mint['600'],
    bgColor: palette.mint['100'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  ANOMALY_STARTED: {
    value: 'ANOMALY_STARTED',
    title: 'Anomaly started',
    icon: 'icn-general16-anomaly',
    color: palette.red['500'],
    bgColor: palette.red['10'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
  },
  ANOMALY_UPDATED: {
    value: 'ANOMALY_UPDATED',
    updateType: {
      METRIC_ADDED: {
        value: 'METRIC_ADDED',
      },
      METRIC_CLOSED: {
        value: 'METRIC_CLOSED',
      },
      DIRECTION_CHANGED: {
        value: 'DIRECTION_CHANGED',
      },
    },
    title: 'Anomaly updated',
    icon: 'icn-general16-anomaly',
    color: palette.orange['500'],
    bgColor: palette.orange['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.mid,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  COMMENT: {
    value: 'COMMENT',
    title: 'Comment',
    icon: 'icn-general16-chat',
    color: palette.gray['500'],
    bgColor: palette.gray['200'],
    rowDefaultSize: 'mid ',
    rowDefaultSizeIncremented: 'mid ',
  },
  FEEDBACK: {
    value: 'FEEDBACK',
    title: 'Feedback',
    GOOD_CATCH: {title: 'good catch', icon: 'icn-general24-like'},
    NOT_INTERESTING: {title: 'not interesting', icon: 'icn-general24-dislike'},
    color: palette.gray['500'],
    bgColor: palette.gray['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  ACKNOWLEDGE: {
    value: 'ACKNOWLEDGE',
    title: 'Acknowledge',
    ADD_STAR: {title: 'Acknowledged'},
    REMOVE_STAR: {title: 'Acknowledged removed'},
    icon: 'icn-action16-acknowledge',
    color: palette.gray['500'],
    bgColor: palette.gray['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  USER_EVENT: {
    value: 'USER_EVENT',
    title: 'User event',
    icon: 'icn-general16-marker',
    color: palette.gray['500'],
    bgColor: palette.gray['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.mid,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  ASSIGNEE_CHANGED: {
    value: 'ASSIGNEE_CHANGED',
    title: 'Assignee changed',
    icon: 'icn-assignee16',
    color: palette.gray['500'],
    bgColor: palette.gray['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  STATIC_UPDATED: {
    value: 'STATIC_UPDATED',
    title: 'Static Updated',
    icon: 'icn-nav24-alertconsole1',
    color: palette.mint['600'],
    bgColor: palette.mint['100'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  STATIC_STARTED: {
    value: 'STATIC_STARTED',
    title: 'Static Started',
    icon: 'icn-general16-anomaly',
    color: palette.red['500'],
    bgColor: palette.red['10'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  NO_DATA_STARTED: {
    value: 'NO_DATA_STARTED',
    title: 'Data is missing',
    icon: 'icn-general16-anomaly',
    color: palette.red['500'],
    bgColor: palette.red['10'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  NO_DATA_UPDATED: {
    value: 'NO_DATA_UPDATED',
    title: 'Data is missing',
    icon: 'icn-nav24-alertconsole1',
    color: palette.mint['600'],
    bgColor: palette.mint['100'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  ACTION: {
    value: 'ACTION',
    title: 'Action',
    icon: 'icn-general16-actions',
    color: palette.gray['500'],
    bgColor: palette.gray['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
};

export const ALERT_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const mappingToRollup = {
  '1m': 'SHORTROLLUP',
  '5m': 'MEDIUMROLLUP',
  '1h': 'LONGROLLUP',
  '1d': 'LONGLONGROLLUP',
  '1w': 'WEEKLY',
};

export const topElementHeight = {
  minimizingModalMarginTop: 60,
  headerHeight: 100,
  tabBarHeight: 76,
  infoPanelsHeight: 180,
  bodyContainerTopOffset: 16,
};

export const MORE_ACTIONS_OPTIONS = {
  EDIT_ALERT: {value: 'editAlert', label: 'Edit alert', icon: 'icn-action16-edit'},
  EDIT_IMPACT: {value: 'editImpavt', label: 'Edit Impact', icon: 'icn-general16-impact'},
  EXPORT_TIMELINE: {value: 'exportTimelineToCsv', label: 'Export Timeline to CSV', icon: 'icn-action16-download'},
  INVESTIGATE_ANOMALY: {value: 'investigate', label: 'Investigate', icon: 'icn-general16-search'},
  SHARE: {value: 'share', label: 'Share', icon: 'icn-Share24'},
};
