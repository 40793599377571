// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';

import {getIsUsersGroupsFiltersBarOpen, isAnodot} from 'profile/store/selectors';
import {setUsersGroupsFiltersIsOpen} from 'admin.users/store/actions';

import UGSearchQueryFilter from 'admin.users/components/userFilters/UGSearchQueryFilter';
import UGStatusFilter from 'admin.users/components/userFilters/UGStatusFilter';
import UGRolesFilter from 'admin.users/components/userFilters/UGRolesFilter';
import UGGroupsFilter from 'admin.users/components/userFilters/UGGroupsFilter';
import UGCustomerFilter from 'admin.users/components/userFilters/UGCustomerFilter';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import './UsersAndGroupsFilters.module.scss';

type PropTypes = {
  setQueryParams: Function,

  // connect
  isFiltersBarOpen: boolean,
  isAndt: boolean,
  setUsersGroupsFiltersIsOpen: Function,
};

export default connect(
  (state) => ({
    isFiltersBarOpen: getIsUsersGroupsFiltersBarOpen(state),
    isAndt: isAnodot(state),
  }),
  {
    setUsersGroupsFiltersIsOpen,
  },
)(
  class UsersAndGroupsFilters extends React.PureComponent {
    props: PropTypes;

    onCloseFiltersClicked = () => {
      this.props.setUsersGroupsFiltersIsOpen(false);
    };

    render() {
      const {isFiltersBarOpen, setQueryParams, isAndt} = this.props;

      return (
        <FiltersPanel
          isOpen={isFiltersBarOpen}
          isClearAll={false}
          isSaveView={false}
          isShareLink={false}
          onClose={this.onCloseFiltersClicked}
        >
          <div styleName="filters-row">
            <UGSearchQueryFilter setQueryParams={setQueryParams} />
          </div>
          <div styleName="filters-row">
            <span styleName="filters-row-header">Status</span>
            <UGStatusFilter setQueryParams={setQueryParams} />
          </div>
          {isAndt ? (
            <div styleName="filters-row">
              <UGCustomerFilter setQueryParams={setQueryParams} />
            </div>
          ) : (
            <div styleName="filters-row">
              <UGGroupsFilter setQueryParams={setQueryParams} />
            </div>
          )}
          <div styleName="filters-row">
            <UGRolesFilter setQueryParams={setQueryParams} />
          </div>
        </FiltersPanel>
      );
    }
  },
);
