/* eslint-disable no-useless-escape */
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {get} from 'lodash';
import {Modal} from 'react-bootstrap';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import SmartTooltip from 'common/componentsV2/Tooltip';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import Button, {COLORS as BUTTON_COLORS, COLORS, WIDTHS} from 'common/componentsV2/Button';
import * as selectors from 'admin.email-digest/store/selectors';
import * as actions from 'admin.email-digest/store/actions';

import './EmailDigestHeader.module.scss';
import {palette} from 'app/styles/theme';

const EmailDigestHeader = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectors.getLoader);
  const isError = useSelector(selectors.getError);
  const reqInfo = useSelector(selectors.getProccessSuccess);
  const champions = useSelector(selectors.getChampionsData);
  const isLoadingChampions = useSelector(selectors.getIsLoadingChampionsData);

  const [isModalOpen, setModalToggle] = useState(false);
  const [isModalOpen2, setModalToggle2] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [modal2Msg, setModal2Msg] = useState('');
  const [notValidUsers, setNotValidUsers] = useState([]);
  const [emailSentFlag, setEmailSentFlag] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');
  const [jsonValid, setJsonValid] = useState(false);
  const [jsonObj, setJsonStringToObj] = useState(0);

  const openModal = () => {
    setModalToggle(true);
  };

  const openModal2 = () => {
    setModalToggle2(true);
  };

  const closeModal = () => {
    setModalToggle(false);
  };

  const closeModal2 = () => {
    setModalToggle2(false);
  };

  const closeWarningModal = () => {
    setWarningModal(false);
  };

  const sendEmails = () => {
    setWarningModal(true);
    setModalToggle(false);
    try {
      setJsonStringToObj(JSON.parse(textareaValue));
    } catch (e) {
      throw e;
    }
  };

  const sendEmailsWithoutRegret = () => {
    dispatch(actions.sendMail(textareaValue));
    setEmailSentFlag(true);
    setWarningModal(false);
  };

  const getChampions = () => {
    setTextareaValue('');
    dispatch(actions.getChampions());
  };

  const validateJson = () => {
    if (
      /^[\],:{}\s]*$/.test(
        textareaValue
          .replace(/\\["\\\/bfnrtu]/g, '@')
          .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
          .replace(/(?:^|:|,)(?:\s*\[)+/g, ''),
      )
    ) {
      setJsonValid(true);
    } else {
      setJsonValid(false);
    }
  };

  useEffect(() => {
    const isEmailsInProcess = get(reqInfo, 'payload.emailSent', null);
    const notValidUsersArr = get(reqInfo, 'payload.notValidEmails', []);

    if (isLoading === false && isError) {
      return;
    }

    if (isLoading === false && emailSentFlag) {
      closeModal();
      setEmailSentFlag(false);
      if (isEmailsInProcess && notValidUsersArr.length) {
        setModal2Msg('Some of the emails addresses are not valid:');
        setNotValidUsers(notValidUsersArr);
        openModal2();
      }
      if (isEmailsInProcess && !notValidUsersArr.length) {
        setModal2Msg('All the Emails are valid!');
        setNotValidUsers([]);
        openModal2();
      }
      if (isEmailsInProcess === false) {
        setModal2Msg('All the emails addresses are not valid:');
        setNotValidUsers(notValidUsersArr);
        openModal2();
      }
    }
  });

  useEffect(() => {
    if (champions.length) {
      setTextareaValue(JSON.stringify(champions));
    }
  }, [champions]);

  useEffect(() => {
    validateJson();
  }, [textareaValue]);

  return (
    <div styleName="main-container">
      <div styleName="header">
        <Title type={TITLE_TYPES.PAGE_TITLE}>Email Digest</Title>
      </div>

      <div styleName="download-report-button">
        <Button
          text="Add Emails"
          colorSchema={COLORS.GRAY_300}
          spinnerColor={COLORS.GRAY_500}
          width={WIDTHS.WIDE}
          onClick={openModal}
          isDisabled={false}
          isLoading={false}
          icon="icn-action16-download"
        />
      </div>

      <Modal
        onHide={closeModal}
        show={isModalOpen}
        bsSize="large"
        dialogClassName="bc modal-xlg email-digest-json-modal"
      >
        <div styleName="modal-wrapper">
          <header>
            <h3> Add some users..</h3>
          </header>
          <div>
            <textarea value={textareaValue} onChange={(e) => setTextareaValue(e.target.value)} />
          </div>
          <div styleName="send-btn-wrapper">
            <Button
              text="Send Emails"
              colorSchema={COLORS.BLUE_500}
              spinnerColor={COLORS.GRAY_500}
              width={WIDTHS.REGULAR}
              onClick={sendEmails}
              isDisabled={!textareaValue.length || !jsonValid}
              isLoading={isLoading}
            />

            {isLoadingChampions ? (
              <div styleName="get-champion-loader">
                <Spinner color={palette.gray[500]} size={SIZES.SMALL_30} />
              </div>
            ) : (
              <SmartTooltip content="Get Champions from Gainsight">
                <div>
                  <Button icon="icn-general16-user" colorSchema={BUTTON_COLORS.GRAY_200} onClick={getChampions} />
                </div>
              </SmartTooltip>
            )}
          </div>
        </div>
      </Modal>

      <Modal onHide={closeWarningModal} show={warningModal} bsSize="small" dialogClassName="bc modal-xlg">
        <div styleName="modal-wrapper">
          <header>
            <h3>
              Your are about to send <span>{jsonObj.length}</span> {} {jsonObj.length > 1 ? 'Emails' : 'Email'}
            </h3>
          </header>

          <div>
            {jsonObj.length && (
              <ul>
                {jsonObj.map((i) => (
                  <li>
                    {i.emailAddress} {i.month}/{i.year}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div styleName="send-btn-wrapper">
            <Button
              text="Send an email without regret"
              colorSchema={COLORS.BLUE_500}
              spinnerColor={COLORS.GRAY_500}
              width={WIDTHS.REGULAR}
              onClick={sendEmailsWithoutRegret}
            />
          </div>
        </div>
      </Modal>

      <Modal onHide={closeModal2} show={isModalOpen2} bsSize="small" dialogClassName="bc modal-xlg">
        <div styleName="modal-wrapper">
          <header>
            <h3>Thank you</h3>
          </header>

          <div>
            <p>
              <strong>{modal2Msg}</strong>
            </p>
            {notValidUsers.length ? (
              <ul>
                {notValidUsers.map((user) => (
                  <li key={user.id}>
                    {user.name ? <span>{user.name}</span> : null}
                    {user.name ? <br /> : null}
                    <span>{user.emailAddress}</span>
                    <br />
                    <span>{user.userId}</span>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EmailDigestHeader;

/* eslint-enable no-useless-escape */
