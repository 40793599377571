// @flow
import React, {Fragment} from 'react';
import {SPIKE_OPTIONS} from 'impactNewAlertConsole/services/constants';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  value: String,
  setValue: Function,
};

const SpikeSelector = (props: PropTypes) => {
  const setValue = (value) => {
    props.setValue({value, name: 'spike'});
  };

  return (
    <Fragment>
      <SelectAndt
        id="impactSpikeSelector"
        automationId="impactSpikeSelector"
        extraClassName="alerts-dropdown-btn"
        optionHeight={40}
        menuWidth={150}
        type={TYPE_NEW_NO_SEARCH}
        theme={THEME_BLUE_LEAN}
        options={SPIKE_OPTIONS}
        onChange={(i) => setValue(i.value)}
        value={SPIKE_OPTIONS.find((i) => i.value === props.value)}
      />
    </Fragment>
  );
};

export default SpikeSelector;
