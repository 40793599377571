import {delete_, post, put} from 'common/utils/http';

export const setAlertFeedbackPost = ({payload}) => {
  return post('api/v1/feedbacks', payload);
};

export const updateFeedback = ({payload}) => {
  const {id, ...restPayload} = payload;
  return put(`api/v1/feedbacks/${payload.id}`, restPayload);
};

export const deleteFeedback = ({payload}) => {
  return delete_(`api/v1/feedbacks/${payload}`, {});
};
