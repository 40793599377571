import {makeAsyncAction, makeAction} from 'common/utils/simplifiedAsync';

const ACTION_PREFIX = 'anodot/impact/';
const mac = (actionName) => makeAction(actionName, ACTION_PREFIX);
const macAsync = (actionName) => makeAsyncAction(actionName, ACTION_PREFIX);

export const isImpactModalOpen = mac('IS_IMPACT_MODAL_OPEN');

export const getSingleImpact = macAsync('GET_SINGLE_IMPACT');
export const getImpactsByOrigin = macAsync('GET_IMPACTS_BY_ORIGIN');
export const putImpact = macAsync('PUT_IMPACT');
export const postImpact = macAsync('POST_IMPACT');
export const deleteImpact = macAsync('DELETE_IMPACT');
export const deleteImpactsByOrigin = macAsync('DELETE_IMPACTS_BY_ORIGIN');

export const addImpact = mac('ADD_IMPACT');
export const addImpacts = mac('ADD_IMPACTS');
export const addAlertById = mac('ADD_ALERT_BY_ID');
export const setImpactValue = mac('SET_IMPACT_VALUE');
export const setImpactDefaultValues = mac('SET_IMPACT_DEFAULT_VALUES');
export const updateImpact = mac('UPDATE_IMPACT');
export const createImpact = mac('CREATE_IMPACT');
export const removeImpact = mac('REMOVE_IMPACT');
