// @flow
/* eslint-disable */

import React, {useState} from 'react';
import {Box, makeStyles, useTheme} from '@material-ui/core';
import TooltipArea from 'common/componentsV2/TooltipArea';
import {useField} from 'react-final-form';

const useStyles = makeStyles((theme) => ({
  underline: {
    marginRight: 16,
    fontSize: 16,
    fontWeight: 500,
    cursor: 'pointer',
    position: 'relative',
  },
  border: {
    position: 'absolute',
    height: 3,
    width: '100%',
    borderRadius: '3px 3px 0 0',
    bottom: -4,
    backgroundColor: theme.palette.blue[500],
  },
  line: {
    position: 'absolute',
    height: 1,
    width: '100%',
    bottom: -5,
    backgroundColor: theme.palette.gray[200],
  },
}));

const UnderlinedTabs = ({tabs, value, onChange}: {tabs: Array, value: string, onChange: Function}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box display="flex">
      {tabs.map((tab) => (
        <Box
          onClick={() => onChange(tab.value)}
          color={value === tab.value ? theme.palette.blue[500] : theme.palette.gray[500]}
          className={classes.underline}
          key={tab.value}
        >
          {tab.label}
          <Box className={value === tab.value ? classes.border : ''} />
        </Box>
      ))}
    </Box>
  );
};

const Tabs = ({
  tabs,
  nameField,
  text,
  components,
  onChange,
  value,
}: {
  tabs: Array<Object>,
  nameField: String,
  components: Array<Object>,
}) => {
  const classes = useStyles();
  const [mode, setMode] = useState(tabs[0].value);

  return (
    <TooltipArea isAlwaysVisible automationId="tabItem" text={text}>
      {() => (
        <React.Fragment>
          <Box mb={2} position="relative">
            <UnderlinedTabs
              tabs={tabs}
              value={value || mode}
              onChange={(val) => {
                setMode(val);
                if (onChange) {
                  onChange(val);
                }
              }}
            />
            <Box className={classes.line} />
          </Box>
          {components.find((component) => (value || mode) === component.name).component}
        </React.Fragment>
      )}
    </TooltipArea>
  );
};

export default Tabs;
