/* eslint-disable max-len */
import {get, put, post, delete_} from 'common/utils/http';

const root = 'api/v1/impact/definitions';

export const getSingleImpact = ({payload}) => get(`${root}/${payload}`);

export const getImpactsByOrigin = ({payload}) =>
  get(`${root}?originId=${payload.originId}&originType=${payload.originType}`);

export const putImpact = ({payload}) => put(root, payload);

export const postImpact = ({payload}) => post(root, payload);

export const deleteImpact = ({payload}) => delete_(root, payload);

export const deleteImpactsByOrigin = ({payload}) =>
  delete_(`${root}/all?originId=${payload.originId}&originType=${payload.originType}`);
