import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isEmpty} from 'lodash';
import useHandleResize from 'common/hooks/useHandleResize';
import {fetchAnomalyMetrics, setIncidentFilter} from 'investigation/store/actions';
import {
  getInvAlertMeasures,
  getIncidentTokenMapIsLoading,
  getInvModalTrigger,
  getIncidentTokenMapDataFiltered,
  getFetchAllMetricsIncident,
  getFetchAlertMetricsIncident,
  getInvModalIncidentFilters,
  getIncidentTokenMapCounter,
  getIncidentTokenMapTotal,
} from 'investigation/store/selectors';
import {getTimeZoneName} from 'profile/store/selectors';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import Button, {COLORS as ButtonColors} from 'common/componentsV2/Button';
import ContentLoader from 'common/componentsV2/ContentLoader';
import {palette} from 'app/styles/theme';
import FiltersChips from 'investigation/components/incident/FiltersChips';
import MetricsTable from 'investigation/components/metricsTable/MetricsTable';
import IncidentDetails from 'investigation/components/incident/IncidentDetails';
import {ALERT_TYPES, calculateAnomalyAlertColumns} from 'investigationNewAlertConsole/services/investigationService';
import TokenMapFilters from 'investigation/components/incident/TokenMapFilters';
import IncidentTabFakeChevron from 'investigation/components/incident/IncidentTabComponents/IncidentTabFakeChevron';
import IncidentTabSubtitle from 'investigation/components/incident/IncidentTabComponents/IncidentTabSubtitle';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

import '../IncidentTab.module.scss';

const IncidentTab = () => {
  const style = {
    panelRootStyle: {
      flexShrink: 0,
      padding: 0,
      border: 'none',
    },
  };
  const dispatch = useDispatch();

  const timeZoneName = useSelector(getTimeZoneName);

  const trigger = useSelector(getInvModalTrigger);
  const alertMeasures = useSelector(getInvAlertMeasures);
  const incidentTokenMap = useSelector(getIncidentTokenMapDataFiltered);
  const isTokenMapLoading = useSelector(getIncidentTokenMapIsLoading);
  const incidentTokenMapMetricsCounter = useSelector(getIncidentTokenMapCounter);
  const incidentTokenMapMetricsTotal = useSelector(getIncidentTokenMapTotal);

  const allMetricsIncident = useSelector(getFetchAllMetricsIncident);
  const alertMetricsIncident = useSelector(getFetchAlertMetricsIncident);

  const filters = useSelector(getInvModalIncidentFilters);

  const [isExpand, setIsExpand] = useState({});
  const [listOrder, setListOrder] = useState({});
  const bodyContainerHeight = useHandleResize([300]);

  const onExpandBoxChange = useCallback(
    (id) => (isExpanded) => {
      setIsExpand((st) => ({...st, [id]: isExpanded}));
    },
    [],
  );

  const onShowMoreMetricsClick = useCallback(
    (what, index, isAlert) => () => {
      dispatch(fetchAnomalyMetrics({what, index, alertId: isAlert ? trigger.id : null, isMore: true, filters}));
    },
    [],
  );

  useEffect(() => {
    if (!isTokenMapLoading && (!isEmpty(incidentTokenMap) || trigger.type !== ALERT_TYPES.ANOMALY)) {
      if (trigger.type === ALERT_TYPES.ANOMALY) {
        alertMeasures.map((what) => {
          dispatch(fetchAnomalyMetrics({what, alertId: trigger.id, filters}));
          dispatch(fetchAnomalyMetrics({what, alertId: null, filters}));
          setIsExpand((st) => ({...st, [`${what}-alert-metrics`]: true}));
        });
      }
    }
  }, [isTokenMapLoading]);

  const isMoreMetrics = (what, isAlert) => {
    const data = isAlert ? alertMetricsIncident : allMetricsIncident;

    return (
      data[what].data.metrics.length <
      data[what].data.metricsCount.total - (isAlert ? 0 : alertMetricsIncident[what].data.metricsCount.total)
    );
  };

  const isMetricsExist = (what) =>
    !!(
      (alertMetricsIncident[what] &&
        !alertMetricsIncident[what].isLoading &&
        alertMetricsIncident[what].data.metrics.length) ||
      (allMetricsIncident[what] && !allMetricsIncident[what].isLoading && allMetricsIncident[what].data.metrics.length)
    );

  const setFilter = (dimension) => {
    if (dimension.id !== 'other') {
      dispatch(setIncidentFilter(dimension));
    }
  };

  const alertColumns = useMemo(() => {
    return calculateAnomalyAlertColumns(alertMetricsIncident);
  }, [trigger, alertMetricsIncident]);

  return (
    <div styleName="incident-tab-container">
      <div styleName="incident-tab-header">
        <IncidentDetails />
      </div>

      <div styleName="incident-tab-metrics">
        {/* TokenMap - for Anomaly alerts */}
        <div styleName="incident-tab-dimensions">
          <TokenMapFilters
            isLoading={isTokenMapLoading}
            tokenMap={incidentTokenMap}
            selectedFilters={filters}
            metricsCounter={incidentTokenMapMetricsCounter}
            totalMetrics={incidentTokenMapMetricsTotal}
            onSelect={setFilter}
          />
        </div>

        {/* Metrics */}
        <div styleName="incident-tab-metrics-container" style={{height: bodyContainerHeight}}>
          <FiltersChips filters={filters} onSelect={setFilter} />
          {/* Anomaly Alert Metrics */}
          {Object.keys(alertMetricsIncident).map((what) => {
            const isMetricLoading =
              (alertMetricsIncident[what] && alertMetricsIncident[what].isLoading) ||
              (allMetricsIncident[what] && allMetricsIncident[what].isLoading);
            return (
              <div styleName="anomaly-metrics-container" key={what}>
                {isMetricLoading && <ContentLoader rows={[{width: 90, height: 27}, {width: 415, height: 20}]} />}

                {!isMetricLoading && isMetricsExist(what) && (
                  <Tooltip content={what} type={TYPES.SMALL}>
                    <div styleName="tooltip-container">
                      <InnerChip title={what} displayOnly type="measure" />
                    </div>
                  </Tooltip>
                )}

                {alertMetricsIncident[what] &&
                  !isMetricLoading &&
                  alertMetricsIncident[what].data &&
                  alertMetricsIncident[what].data.metricsCount && (
                    <div styleName="metrics-in-alert-container">
                      {!!alertMetricsIncident[what].data.metrics.length && (
                        <ExpandableBox
                          key={`inc-gr-panel-${what}-alert`}
                          automationId="incWhatGroup"
                          actionElement={<div>actionElement</div>}
                          blockStyle={{transform: 'translateY(20px)'}}
                          nodeLabel={
                            <IncidentTabSubtitle
                              filters={filters}
                              subtitle="Anomalies in alert"
                              counter={alertMetricsIncident[what].data.metricsCount.total}
                            />
                          }
                          expanded={isExpand[`${what}-alert-metrics`]}
                          onChange={onExpandBoxChange(`${what}-alert-metrics`)}
                          rootStyle={style.panelRootStyle}
                        >
                          <MetricsTable
                            columns={alertColumns}
                            metrics={alertMetricsIncident[what].data.metrics}
                            timeZoneName={timeZoneName}
                            listOrder={listOrder}
                            setListOrder={setListOrder}
                          />
                          {alertMetricsIncident[what].isLoadingMore && (
                            <div styleName="spinner">
                              <Spinner color={palette.gray[500]} size={SpinnerSizes.MEDIUM_50} />
                            </div>
                          )}
                          {isMoreMetrics(what, true) && (
                            <div styleName="show-more-metrics-button">
                              {!alertMetricsIncident[what].isLoadingMore && (
                                <Button
                                  colorSchema={ButtonColors.TRANSPARENT_BLUE}
                                  text="Show more metrics"
                                  onClick={onShowMoreMetricsClick(
                                    what,
                                    alertMetricsIncident[what].data.metrics.length,
                                    true,
                                  )}
                                />
                              )}
                            </div>
                          )}
                        </ExpandableBox>
                      )}
                      {!alertMetricsIncident[what].data.metrics.length && (
                        <IncidentTabFakeChevron filters={filters} subtitle="Anomalies in alert" />
                      )}
                    </div>
                  )}

                {/* All Metrics */}
                {isMetricLoading && (
                  <ContentLoader
                    rows={[{width: '100%', height: 40}, {width: '100%', height: 40}, {width: '100%', height: 40}]}
                  />
                )}

                {allMetricsIncident[what] &&
                  !isMetricLoading &&
                  allMetricsIncident[what].data &&
                  allMetricsIncident[what].data.metricsCount &&
                  alertMetricsIncident[what].data.metricsCount && (
                    <div styleName="metrics-not-in-alert-container">
                      {!!allMetricsIncident[what].data.metrics.length && (
                        <ExpandableBox
                          key={`inc-gr-panel-${what}-all`}
                          automationId="incWhatGroup"
                          blockStyle={{transform: 'translateY(20px)'}}
                          nodeLabel={
                            <IncidentTabSubtitle
                              filters={filters}
                              subtitle="Anomalies not in alert"
                              counter={
                                allMetricsIncident[what].data.metricsCount.total -
                                alertMetricsIncident[what].data.metricsCount.total
                              }
                            />
                          }
                          expanded={isExpand[`${what}-all-metrics`]}
                          onChange={onExpandBoxChange(`${what}-all-metrics`)}
                          rootStyle={style.panelRootStyle}
                        >
                          <MetricsTable
                            columns={alertColumns}
                            metrics={allMetricsIncident[what].data.metrics}
                            timeZoneName={timeZoneName}
                            listOrder={listOrder}
                            setListOrder={setListOrder}
                          />
                          {allMetricsIncident[what].isLoadingMore && (
                            <div styleName="spinner">
                              <Spinner color={palette.gray[500]} size={SpinnerSizes.MEDIUM_50} />
                            </div>
                          )}
                          {isMoreMetrics(what, false) && (
                            <div styleName="show-more-metrics-button">
                              {!allMetricsIncident[what].isLoadingMore && (
                                <Button
                                  colorSchema={ButtonColors.TRANSPARENT_BLUE}
                                  text="Show more metrics"
                                  onClick={onShowMoreMetricsClick(
                                    what,
                                    allMetricsIncident[what].data.metrics.length,
                                    false,
                                  )}
                                />
                              )}
                            </div>
                          )}
                        </ExpandableBox>
                      )}
                      {!allMetricsIncident[what].data.metrics.length && (
                        <IncidentTabFakeChevron filters={filters} subtitle="Anomalies not in alert" />
                      )}
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default IncidentTab;
