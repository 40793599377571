// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {getDataMangerQueryParams, getDatamanagerStreamsCurrentOwners} from 'bc/store/selectors';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import {segmentClickEvent} from 'common/store/actions';

type PropTypes = {
  setQueryParams: Function,

  // connect
  dataQueryParams: Object,
  owners: Object,
  segmentClickEvent: Function,
};

export default connect(
  (state) => ({
    dataQueryParams: getDataMangerQueryParams(state),
    owners: getDatamanagerStreamsCurrentOwners(state),
  }),
  {
    segmentClickEvent,
  },
)(
  class OwnerFilter extends PureComponent {
    props: PropTypes;

    onChange = (values) => {
      const {setQueryParams} = this.props;
      const currentValues = values.length ? values.map((i) => i.value).join(',') : undefined;
      this.props.segmentClickEvent({
        category: 'data-management-filter',
        name: `owners-${currentValues}`,
      });
      setQueryParams({owner: currentValues});
    };

    onClearAll = () => {
      const {dataQueryParams, owners, setQueryParams} = this.props;
      if (dataQueryParams.owner && dataQueryParams.owner.length > 0) {
        setQueryParams({owner: undefined});
      } else {
        const optionCurrent = owners.map((i) => i.value).join(',');
        setQueryParams({owner: optionCurrent});
      }
    };

    render() {
      const {dataQueryParams, owners} = this.props;
      const selectOwnersArr = dataQueryParams.owner ? dataQueryParams.owner.split(',') : [];
      const optionCurrent = owners.filter((i) => selectOwnersArr.includes(i.value));

      return (
        <SelectAndt
          id="ownerFilter"
          automationId="DataManagerStatusOwnerFilter"
          extraClassName="alerts-dropdown-btn"
          options={owners}
          buttonWidth={190}
          optionHeight={40}
          type={TYPE_NEW_MULTI}
          theme={THEME_HIGHLIGHTED}
          onChange={this.onChange}
          value={optionCurrent}
          isMulti
          placeholder="All"
          isClearable={false}
          closeMenuOnSelect={false}
          menuFooterComponent={
            <FormDdlActionBar
              isVisible
              selectedCount={optionCurrent ? optionCurrent.length : 0}
              onClearAll={this.onClearAll}
            />
          }
        />
      );
    }
  },
);
