// @flow
import React, {useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useQueryParams} from 'use-query-params';
import {cloneDeep} from 'lodash';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {
  QUERY_PARAM_MAP,
  SELECTED_SITE_PANEL_TABS,
  PANEL_SIZES,
  createLinkEntity,
  createNodeEntity,
  filteredIssuesList,
} from 'topologyGeneral/services/sidePanelService';
import TabsBar from 'topologyGeneral/components/common/TabsBar';
import BackButton from 'topologyGeneral/components/common/BackButton';
import SelectedRegionItem from 'topologyGeneral/components/common/SelectedRegionItem';
import SiteEntitiesCarousel from 'topologyGeneral/components/sidePanel/SiteEntitiesCarousel';
import InfoSubPanel from 'topologyGeneral/components/sidePanel/infoSubPanel/InfoSubPanel';
import IssuesSubList from 'topologyGeneral/components/sidePanel/issuesSubPanel/IssuesSubList';
import TinyScrollBox from 'common/componentsV2/boxTools/TinyScrollBox';
import {
  getGtpMapSites,
  getGtpMapIssues,
  getGtpMapRegions,
  isLoadingGtpMapRegionsSitesList,
} from 'topologyGeneral/store/selectors';
import './SidePanel.module.scss';
import {palette} from 'app/styles/theme';

const SelectedSitePanel = ({isVisible}: PropTypes) => {
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);

  const regionItems = useSelector(getGtpMapRegions);
  const sitesItems = useSelector(getGtpMapSites);
  const issues = useSelector(getGtpMapIssues);
  const isSitesListLoading = useSelector(isLoadingGtpMapRegionsSitesList);

  const [selectedSitePanelTabs, setSelectedSitePanelTabs] = useState(SELECTED_SITE_PANEL_TABS);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);
  const [filteredIssues, setFilteredIssues] = useState([]);

  useEffect(() => {
    if (selectedSite) {
      const fil = filteredIssuesList(issues, null, [selectedSite]);
      const cloned = cloneDeep(SELECTED_SITE_PANEL_TABS);
      cloned.alerts.label += ` (${fil.relevant}/${fil.total})`;
      setFilteredIssues(fil.filteredIssues);
      setSelectedSitePanelTabs(cloned);
    } else {
      setSelectedSitePanelTabs(SELECTED_SITE_PANEL_TABS);
    }
  }, [selectedSite, issues]);

  useEffect(() => {
    const findSite = sitesItems.find((site) => site.id === queryParams.siteId);
    const findRegion = findSite ? regionItems.find((r) => r.id === findSite.parentRegionId) : null;
    if (findSite && findSite.nodes) {
      findSite.nodes = findSite.nodes.map((n) => createNodeEntity(findSite, n));
    }
    if (findSite && findSite.links) {
      findSite.links = findSite.links.map((l) => createLinkEntity(l));
    }
    setSelectedSite(findSite || null);
    setSelectedRegion(findRegion || null);
  }, [queryParams.siteId, regionItems, sitesItems]);

  const tabClicked = useCallback((tabId) => {
    setQueryParams({selectedSiteTabId: tabId});
  }, []);

  const onBackClick = () => {
    setQueryParams({
      siteId: undefined,
      selectedSiteTabId: undefined,
      selectedEntityId: undefined,
    });
  };

  if (!isVisible || !selectedRegion) {
    return null;
  }

  if (!selectedSite) {
    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center" height="100%">
        <Spinner color={palette.gray[500]} size={SIZES.XX_BIG_100} />
      </Box>
    );
  }

  return (
    <Box mr={2} ml={2}>
      <Box display="flex" mt={3} mb={1} height={16}>
        <BackButton text={selectedRegion ? selectedRegion.title : 'Back'} onClick={onBackClick} />
      </Box>
      <SelectedRegionItem region={selectedSite} />
      <Box display="flex" mt={2}>
        <SiteEntitiesCarousel site={selectedSite} />
      </Box>
      <Box display="flex" borderBottom={`1px solid ${palette.gray[300]}`} pl={0.5} mt={2.75}>
        <TabsBar
          tabs={Object.values(selectedSitePanelTabs)}
          selectedTabId={queryParams.selectedSiteTabId}
          onTabClick={tabClicked}
        />
      </Box>
      <TinyScrollBox
        position="absolute"
        top={310}
        bottom={0}
        right={3}
        left={0}
        css={{overflowY: 'auto', overflowX: 'hidden'}}
      >
        <Box width={PANEL_SIZES.innerPanelWidth} ml={2}>
          {queryParams.selectedSiteTabId === SELECTED_SITE_PANEL_TABS.alerts.id && (
            <IssuesSubList issues={filteredIssues} isLoading={isSitesListLoading} />
          )}
          {queryParams.selectedSiteTabId === SELECTED_SITE_PANEL_TABS.info.id && <InfoSubPanel site={selectedSite} />}
        </Box>
      </TinyScrollBox>
    </Box>
  );
};

SelectedSitePanel.propTypes = {
  // mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
  isVisible: PropTypes.bool,
};

SelectedSitePanel.defaultProps = {
  isVisible: true,
};

export default SelectedSitePanel;
