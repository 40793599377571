import React from 'react';
import toCamelCase from 'common/utils/toCamelCase';
import './IntegrationsFilterSelector.module.scss';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

type PropTypes = {
  category: Array,
  onSelect: Function,
  isSelected: boolean,
};

const IntegrationsFilterSelector = ({category, onSelect, isSelected}: PropTypes) => {
  let showTooltip = false;
  if (category.isNew) {
    if (category.categoryTitle.length > 9) {
      showTooltip = true;
    }
  } else if (category.categoryTitle.length > 19) {
    showTooltip = true;
  }
  return (
    <div
      styleName={['container', isSelected ? 'selected' : ''].join(' ')}
      onClick={() => onSelect(category.categoryName)}
      automation-id={toCamelCase(`catalogCategory ${category.categoryName}`)}
    >
      {showTooltip ? (
        <Tooltip content={category.categoryTitle} type={TYPES.SMALL}>
          <div>{category.categoryTitle}</div>
        </Tooltip>
      ) : (
        <div>{category.categoryTitle}</div>
      )}
      {category.isNew && <div styleName="new">New</div>}
    </div>
  );
};

export default IntegrationsFilterSelector;
