// @flow
import React from 'react';
import {Box} from '@material-ui/core';
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import MetricsTableRowHeader from './MetricsTableRowHeader';
import MetricsTableExpandablePanel from './MetricsTableExpandablePanel';
import styles from './MetricsTable.module.scss';

type PropTypes = {
  metric: Object,
  timeZoneName: string,
  isSelected: boolean,
  setSelectedItem: Function,
  columns: Object,
};

const MetricTableRow = ({metric, timeZoneName, isSelected, setSelectedItem, columns}: PropTypes) => (
  <Box className={styles.tableRowRoot}>
    <TableListItem
      style={{paddingRight: 0, margin: 0}}
      key={metric.id}
      id={metric.id}
      expandedPanelClass={isSelected ? `${styles.tableRow} ${styles.tableRowOpen}` : styles.tableRow}
      headerComponent={<MetricsTableRowHeader columns={columns} metric={metric} timeZoneName={timeZoneName} />}
      expandedPanel={<MetricsTableExpandablePanel metric={metric} timeZoneName={timeZoneName} />}
      checkboxVisibility={CHECKBOX_VISIBILITY_TYPES.alwaysHide}
      setSelectedItem={setSelectedItem}
      isSelected={isSelected}
    />
  </Box>
);

export default MetricTableRow;
