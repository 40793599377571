import React from 'react';
import './OptionComponentCheckboxHeaders.module.scss';
import Tooltip, {TYPES} from '../../Tooltip';

type PropTypes = {
  data: Object,
  isSelected: boolean,
  isDisabled: boolean,
};

const OptionComponentCheckboxHeaders = (props: PropTypes) => {
  if (props.data.type === 'HEADER') {
    return (
      <div styleName="container-header" className="form-option">
        <div styleName="list-header" className="andt-dropdown-option-header">
          {props.data.label}
        </div>
      </div>
    );
  }
  return props.data?.type === 'SEPARATOR' ? (
    <div styleName="options-separator" />
  ) : (
    <div styleName="container" className="form-option">
      <div
        styleName={['ico', props.data.isDisabled ? 'disabled' : ''].join(' ')}
        className={
          props.isSelected && !props.data.isDisabled
            ? 'icon icn-selection16-checkboxon'
            : 'icon icn-selection16-checkboxoff'
        }
      />
      {props.data.email ? (
        <Tooltip content={props.data.email} type={TYPES.SMALL}>
          <>
            <div styleName="label">{typeof props.data === 'string' ? props.data : props.data.label}</div>
            {props.data.count && <div styleName="group">{props.data.count}</div>}
          </>
        </Tooltip>
      ) : (
        <>
          <div styleName={['label', props.data.isDisabled ? 'disabled' : ''].join(' ')}>
            {typeof props.data === 'string' ? props.data : props.data.label}
          </div>
          {props.data?.icon && <div className={props.data.icon} styleName="option-icon" />}
          {props.data.count && <div styleName="group">{props.data.count}</div>}
        </>
      )}
    </div>
  );
};

export default OptionComponentCheckboxHeaders;
