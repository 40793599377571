import {isString, isNumber} from 'lodash';

/**
 * abbreviate the number
 * (http://stackoverflow.com/questions/2685911/is-there-a-way-to-round-numbers-into-a-reader-friendly-format-e-g-1-1k)
 * @param number
 * @param decPlaces
 * @returns {*}
 */

const intlFormat = (number) => new Intl.NumberFormat().format(Math.round(number * 10) / 10);

export const computeDecimal = (number) => {
  const absoluteValue = Math.abs(number);
  if (absoluteValue < 2) {
    return 6;
  }
  if (absoluteValue < 10) {
    return 4;
  }
  return 2;
};

export const abbrNum = (number, decPlaces = null, isAbbrKilo = false) => {
  if (!isNumber(number)) {
    return null;
  }
  if (isString(number)) {
    return number;
  }

  if (isAbbrKilo && number > 999) {
    const num = Math.floor(number);
    if (num >= 1000000000) return `${intlFormat(num / 1000000000)}G`;
    if (num >= 1000000) return `${intlFormat(num / 1000000)}M`;
    if (num >= 1000) return `${intlFormat(num / 1000)}k`;
    return intlFormat(num);
  }

  const cleanDecimal = (dec) => Number.parseFloat(dec);

  const precisionFactor = 6;
  const symbols = {
    '-8': 'y',
    '-7': 'z',
    '-6': 'a',
    '-5': 'f',
    '-4': 'p',
    '-3': 'n',
    '-2': 'µ',
    // '-1': 'm',
    // '0':  '',
    // '1': 'k',
    2: 'M',
    3: 'G',
    4: 'T',
    5: 'P',
    6: 'E',
    7: 'Z',
    8: 'Y',
    9: 'H',
  };
  const negativeCoeficiant = number < 0 ? -1 : 1;
  let res = Math.abs(number);
  let retNum = null;
  let scale = 0;
  // very big number
  const pow9 = 1000 ** 9;
  if (res / pow9 > 1) {
    res = negativeCoeficiant * res.toExponential(decPlaces !== null ? decPlaces : precisionFactor - 1);
    return cleanDecimal(res);
  }

  // very small number
  const pow8 = 1000 ** 8;
  if (res * pow8 < 1) {
    res = negativeCoeficiant * res.toExponential(decPlaces !== null ? decPlaces : precisionFactor - 1);
    return cleanDecimal(res);
  }

  if (res > 1) {
    res /= 1000;
    while (res >= 1) {
      scale += 1;
      res /= 1000;
    }

    if (scale < 2) {
      res =
        decPlaces !== null
          ? parseFloat(number.toPrecision(precisionFactor)).toFixed(decPlaces)
          : number.toPrecision(precisionFactor);
      return cleanDecimal(res);
    }

    retNum =
      decPlaces !== null
        ? parseFloat((negativeCoeficiant * res * 1000).toPrecision(precisionFactor)).toFixed(decPlaces)
        : (negativeCoeficiant * res * 1000).toPrecision(precisionFactor);
    return !symbols[scale] ? number : cleanDecimal(retNum) + symbols[scale];
  }
  if (res > 0 && res < 1) {
    res *= 1000;
    scale = -1;
    while (res < 1) {
      scale -= 1;
      res *= 1000;
    }

    if (scale > -3) {
      if (Math.abs(number) >= 0.0001) {
        res = decPlaces !== null ? parseFloat(number).toFixed(decPlaces) : parseFloat(number).toFixed(precisionFactor);
        return cleanDecimal(res);
      }
    }

    retNum =
      decPlaces !== null
        ? parseFloat((negativeCoeficiant * res).toPrecision(precisionFactor)).toFixed(decPlaces)
        : (negativeCoeficiant * res).toPrecision(precisionFactor);
    return !symbols[scale] ? number : cleanDecimal(retNum) + symbols[scale];
  }
  return negativeCoeficiant * res;
};

export const commasSepNumber = (value) => value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const abbrBytesToFileSize = (bytes, decimals = 2) => {
  if (bytes === 0 || !isNumber(bytes)) {
    return '0 Bytes';
  }
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  // eslint-disable-next-line no-restricted-properties
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`;
};

export const setMaxNumberToDisplay = (value, maxValueToDisplay = 1000, toFixed = 2) => {
  if (!isNumber(value)) {
    return null;
  }

  if (isString(value)) {
    return value;
  }

  if (value > maxValueToDisplay) {
    return `${maxValueToDisplay}+`;
  }
  return value.toFixed(toFixed);
};

export const setMaxPercentToDisplayTrimmed = (value, maxValueToDisplay = 1000) => {
  const percentTextVal = setMaxNumberToDisplay(value, maxValueToDisplay, 2);
  if (percentTextVal.substring(percentTextVal.length - 3, percentTextVal.length) === '.00') {
    return percentTextVal.substring(0, percentTextVal.length - 3);
  }

  if (percentTextVal.substring(percentTextVal.length - 1, percentTextVal.length) === '0') {
    return percentTextVal.substring(0, percentTextVal.length - 1);
  }

  return percentTextVal;
};
