import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/user-events/';

const getUserEventsCategories = () => get(`${root}categories`);

export default function useFetchUserEventCategories() {
  const selectFn = useCallback((data) => {
    return data.map((i) => ({
      label: i.name,
      value: i.name,
    }));
  }, []);

  const fallback = [];
  const {
    data: userEventCategories = fallback,
    isLoading: isUserEventCategoriesLoading,
    isFetching: isUserEventCategoriesFetching,
  } = useQuery(QUERY_KEYS.userEventsCategories, getUserEventsCategories, {
    select: selectFn,
  });

  return {
    userEventCategories,
    isUserEventCategoriesLoading,
    isUserEventCategoriesFetching,
  };
}
