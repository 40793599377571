// @flow
import React from 'react';
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import MetricsTableRowHeader from './MetricsTableRowHeader';
import MetricsTableExpandablePanel from './MetricsTableExpandablePanel';
import * as styles from './MetricsTable.module.scss';

const MetricTableRow = ({
  trigger,
  metric,
  timeZoneName,
  isSelected,
  setSelectedItem,
  columns,
  isSingleLine,
}: {
  trigger: Object,
  metric: Object,
  timeZoneName: string,
  isSelected: boolean,
  setSelectedItem: Function,
  columns: Object,
  isSingleLine: Boolean,
}) => (
  <div className={styles.tableRowRoot}>
    <TableListItem
      style={{paddingRight: 0, margin: 0}}
      key={metric.id}
      id={metric.id}
      expandedPanelClass={isSelected ? `${styles.tableRow} ${styles.tableRowOpen}` : styles.tableRow}
      headerComponent={
        <MetricsTableRowHeader
          columns={columns}
          metric={metric}
          timeZoneName={timeZoneName}
          isSingleLine={isSingleLine}
        />
      }
      expandedPanel={<MetricsTableExpandablePanel trigger={trigger} metric={metric} timeZoneName={timeZoneName} />}
      checkboxVisibility={CHECKBOX_VISIBILITY_TYPES.alwaysHide}
      setSelectedItem={setSelectedItem}
      isSelected={isSelected}
    />
  </div>
);

export default MetricTableRow;
