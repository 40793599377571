import React from 'react';
import {makeStyles} from '@material-ui/core';
import ChannelField from './ChannelField';

const useStyles = makeStyles(() => ({
  firstColumn: {
    width: '173px',
  },
  secondColumn: {
    width: '204px',
  },
}));

const JiraDetails = ({channel}: {channel: Object}) => {
  // icn-general16-user, icn-general16-group

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={`mr_4 display_flex flexDirection_column ${classes.firstColumn}`}>
        <div className="mb_1-5">
          <ChannelField maxWidth={173} label="URL" value={channel.channelData.jiraBaseURL} />
        </div>
        <div className="mb_1-5">
          <ChannelField maxWidth={173} label="Time Zone" value={channel.timezone || 'Browser'} />
        </div>
        <ChannelField maxWidth={173} label="Created by" value={channel.createdBy} />
      </div>
      <div className={`display_flex flexDirection_column ${classes.secondColumn}`}>
        <div className="mb_1-5">
          <ChannelField maxWidth={204} label="JIRA User" value={channel.channelData.userEmail} />
        </div>
        <div className="mb_1-5">
          <ChannelField maxWidth={204} label="JIRA issue type" value={channel.channelData.issueType} />
        </div>
        <ChannelField maxWidth={204} label="JIRA project ID" value={channel.channelData.projectId} />
      </div>
    </React.Fragment>
  );
};

export default JiraDetails;
