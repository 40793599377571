// flow
import React, {Fragment, PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import * as selectors from 'insightsPanel/store/selectors';
import * as actions from 'insightsPanel/store/actions';
import {segmentClickEvent as segmentTrackingMouseEventAction} from 'common/store/actions';
import {Transition} from 'react-transition-group';
import {Carousel} from 'react-responsive-carousel';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {shouldFetchData, fadeOpacity} from 'insightsPanel/services/constants';
import PieChart from 'charts/pieChart/PieChart';
import {FEEDBACK} from 'charts/pieChart/pieChartSettingsService';

import './FeedbackPerMeasure.module.scss';

type PropTypes = {
  title: String,
  onFilterAlerts: Function,
  // connect
  feedbackPerMeasure: Object,
  feedbackPerMeasureData: Array<Object>,
  isLoading: Boolean,
  numLastDays: Object,
  isPanelOpen: Object,
  fetchFeedbackPerMeasure: Function,
  segmentClickEvent: Function,
};

const CAROUSEL_SETTINGS = {
  showIndicators: true,
  infiniteLoop: false,
  showThumbs: false,
  showStatus: false,
  transitionTime: 400,
};

export default connect(
  (state) => ({
    feedbackPerMeasure: selectors.getFeedbackPerMeasure(state),
    feedbackPerMeasureData: selectors.getFeedbackPerMeasureData(state),
    isLoading: selectors.getIsLoadingFeedbackPerMeasure(state),
    numLastDays: selectors.getNumLastDays(state),
    isPanelOpen: selectors.getIsPanelOpen(state),
  }),
  {
    fetchFeedbackPerMeasure: actions.fetchFeedbackPerMeasure,
    segmentClickEvent: segmentTrackingMouseEventAction,
  },
)(
  class FeedbackPerMeasure extends PureComponent {
    props: PropTypes;

    state = {
      showData: false,
    };

    componentDidMount() {
      const {fetchFeedbackPerMeasure, numLastDays} = this.props;
      const params = {
        numLastDays: numLastDays.value,
      };
      fetchFeedbackPerMeasure(params);
    }

    componentDidUpdate(prevProps) {
      const {
        fetchFeedbackPerMeasure,
        feedbackPerMeasure,
        feedbackPerMeasureData,
        numLastDays,
        isPanelOpen,
      } = this.props;
      if (prevProps.isPanelOpen !== isPanelOpen && isPanelOpen && shouldFetchData(feedbackPerMeasure)) {
        const params = {
          numLastDays: numLastDays.value,
        };
        fetchFeedbackPerMeasure(params);
      }
      if (prevProps.numLastDays.value !== numLastDays.value) {
        const params = {
          numLastDays: numLastDays.value,
        };
        fetchFeedbackPerMeasure(params);
      }

      if (prevProps.feedbackPerMeasureData !== feedbackPerMeasureData && feedbackPerMeasureData) {
        this.timer = setTimeout(() => this.setState({showData: true}), fadeOpacity.duration);
      }
    }

    componentWillUnmount() {
      clearTimeout(this.timer);
    }

    timer;

    segmentTracking = (name) => {
      this.props.segmentClickEvent({
        category: 'Insights Panel',
        name,
      });
    };

    clickOnMetricsName = (metricsName) => {
      this.props.onFilterAlerts({searchQuery: metricsName});
      this.segmentTracking('Feedback Per Measure Widget - Navigate');
    };

    clickOnFeedback = (event, metricsName) => {
      const feedback = event.point.type;
      if (feedback === 'goodCatch') {
        this.props.onFilterAlerts({positiveFeedback: true, searchQuery: metricsName});
      } else if (feedback === 'notInteresting') {
        this.props.onFilterAlerts({negativeFeedback: true, searchQuery: metricsName});
      }
      this.segmentTracking(`Feedback Per Measure Widget - ${feedback} - ${metricsName}`);
    };

    tooltipFormatter = {
      formatter() {
        return `<div class="pie-chart-tooltip">${Math.round(Math.round(this.percentage))}%${' '}(${this.y})${' - '}${
          this.point.typeName
        }</div>`;
      },
    };

    render() {
      const {isLoading, feedbackPerMeasureData, title} = this.props;
      return (
        <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
          {isLoading === false && feedbackPerMeasureData && (
            <Transition in={this.state.showData} timeout={0}>
              {(state) => (
                <Fragment>
                  <header
                    styleName="header"
                    style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}
                  >
                    {title}
                  </header>
                  <div
                    styleName={feedbackPerMeasureData.length < 3 ? 'container static' : 'container'}
                    style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}
                  >
                    {!feedbackPerMeasureData.length ? (
                      <div styleName="no-feedback">
                        <span>Not enough data to show</span>
                      </div>
                    ) : (
                      <Carousel {...CAROUSEL_SETTINGS} showIndicators={feedbackPerMeasureData.length > 1}>
                        {feedbackPerMeasureData.map((primaryArr, index) => (
                          /* eslint-disable */
                          <div key={`group_${index}`} styleName="charts-group-wrapper">
                            {primaryArr.map((secondaryArr) => {
                              const feedbackItem = secondaryArr.find((i) => i);
                              return (
                                <div id={feedbackItem.id} styleName="chart-wrapper" key={feedbackItem.id}>
                                  <PieChart
                                    onClickEvent={(e) => this.clickOnFeedback(e, feedbackItem.name)}
                                    id={`insightsPanelFeedbackPerMeasure_${feedbackItem.id}`}
                                    height={82}
                                    width={82}
                                    data={secondaryArr}
                                    theme={FEEDBACK}
                                    labels
                                    tooltipFormatter={this.tooltipFormatter}
                                  />
                                  <SmartTooltip content={feedbackItem.name} delay={300}>
                                    <div
                                      styleName="feedback-name"
                                      onClick={() => this.clickOnMetricsName(feedbackItem.name)}
                                    >
                                      <span className="ellipsis">{feedbackItem.name}</span>
                                    </div>
                                  </SmartTooltip>
                                </div>
                              );
                            })}
                          </div>
                          /* eslint-enable */
                        ))}
                      </Carousel>
                    )}
                  </div>
                </Fragment>
              )}
            </Transition>
          )}
        </div>
      );
    }
  },
);
