import _, {cloneDeep, defaults, get, includes, omitBy} from 'lodash';
import {emptyExpressionTree} from 'metrics/store/reducers/expressionBuilderReducer';
import {getUniqueId} from 'common/utils/guid';
import {OPTION_TILES_VALUES, SIZE_TILES} from 'dashboards/services/dashboardService';
import {treeVisitor} from 'metrics/services/metricsService';
import deletePropFromObj from 'common/utils/deletePropFromObj';
import {getDate, getDateValue, rangeTypes} from 'common/utils/dateRangeService';
import {palette} from 'app/styles/theme';

const COLUMS_AMOUNT = 12;

export const findFreeSpace = (tiles, size) => {
  const grid = [];
  Object.values(tiles).forEach((tile) => {
    for (let {x} = tile; x < tile.x + tile.w; x++) {
      for (let {y} = tile; y < tile.y + tile.h; y++) {
        if (!grid[x]) {
          grid[x] = [];
        }
        grid[x][y] = 1;
      }
    }
  });
  let x;
  let y;
  // eslint-disable-next-line no-labels,no-restricted-syntax
  findPosition: for (y = 0; ; y++) {
    for (x = 0; x < COLUMS_AMOUNT - size.x + 1; x++) {
      let available = true;
      // eslint-disable-next-line no-labels,no-restricted-syntax
      matchShape: for (let sx = 0; sx < size.x; sx++) {
        for (let sy = 0; sy < size.y; sy++) {
          if (grid[x + sx] && grid[x + sx][y + sy]) {
            available = false;
            // eslint-disable-next-line no-labels
            break matchShape;
          }
        }
      }
      if (available) {
        // eslint-disable-next-line no-labels
        break findPosition;
      }
    }
  }
  return {x, y};
};

export const emptyExpressionTreeModel = {
  compositeCount: 0,
  displayOnly: true,
  expressionTree: emptyExpressionTree,
  filter: {
    function: 'alphanumeric',
    parameters: [
      {
        name: 'Top N',
        value: 10,
      },
    ],
    children: [],
    id: getUniqueId(),
    type: 'function',
  },
  id: getUniqueId(),
  name: {
    auto: true,
    prefix: null,
  },
  scalarTransforms: [
    {
      function: 'current',
      children: [],
      id: getUniqueId(),
      parameters: [],
      type: 'function',
    },
  ],
  uiIndex: 0,
};

export const defaultEmptyGraphTile = {
  sizeX: SIZE_TILES.GRAPH_TILE.sizeX,
  sizeY: SIZE_TILES.GRAPH_TILE.sizeY,
  title: {
    text: '',
  },
  row: null,
  col: null,
  expressionTreeType: 'simple',
  lineChart: {
    height: 1,
    gridster: {},
    userTimeZone: 'Europe/Kiev',
    bucketStartTimeFF: true,
    expressionTrees: {
      [getUniqueId()]: emptyExpressionTreeModel,
    },
    seriesProperties: {
      byTreeExp: [
        {
          id: getUniqueId(),
          options: {
            type: 'line',
            stacking: null,
            yAxis: {
              opposite: false,
              max: null,
              min: null,
              type: 'linear',
              minorTickInterval: 'auto',
            },
            andtGauge: {
              type: 'andtGauge',
              mainValue: {
                fontSize: 100,
                decimalPlaces: 4,
                unitText: '',
                abbreviate: true,
                thousandsSeparator: true,
              },
              prefix: {
                text: '',
                fontSize: 50,
              },
              suffix: {
                text: '',
                fontSize: 50,
              },
              orientation: 'leftRight',
              thresholdColorElement: 'foreground',
              defaultColor: {
                hex: '',
                text: 'Transparent',
              },
              thresholds: [
                {
                  order: 1,
                  color: {
                    hex: palette.green[450],
                    text: 'Light Green',
                  },
                  value: '',
                  operator: '>=',
                },
                {
                  order: 2,
                  color: {
                    hex: palette.orange[400],
                    text: 'Orange',
                  },
                  value: '',
                  operator: '>=',
                },
                {
                  order: 3,
                  color: {
                    hex: palette.red[500],
                    text: 'Red',
                  },
                  value: '',
                  operator: '>=',
                },
              ],
            },
          },
        },
      ],
    },
    legend: {
      enabled: false,
      pinned: false,
      isPinnable: true,
      position: 'bottom',
      layout: 'table',
      isLayoutChangable: true,
      ratio: 0.2,
      horizontalRatio: 0.3,
    },
    resolution: {
      value: '',
    },
    eventListeners: {},
  },
};

export const defaultEmptyStatTile = {
  sizeX: SIZE_TILES.STAT_TILE.sizeX,
  sizeY: SIZE_TILES.STAT_TILE.sizeY,
  title: {
    text: '',
  },
  row: null,
  col: null,
  expressionTreeType: 'simple',
  andtGauge: {
    legend: {
      enabled: true,
    },
    bucketStartTimeFF: true,
    displayProperties: {
      byTreeExp: [
        {
          id: getUniqueId(),
          options: {
            andtGauge: {
              thresholdColorElement: 'foreground',
              type: 'andtGauge',
              orientation: 'leftRight',
              suffix: {
                fontSize: 50,
                text: '',
              },
              prefix: {
                fontSize: 50,
                text: '',
              },
              mainValue: {
                fontSize: 100,
                decimalPlaces: 2,
                displayDecimal: false,
                thousandsSeparator: true,
                unitText: 'auto',
                abbreviate: false,
                showMeasure: true,
                showGraph: true,
                inputNoData: 'No Data',
              },
              defaultColor: {
                hex: '',
                text: 'Transparent',
              },
              thresholds: [
                {
                  color: {
                    hex: palette.green[450],
                    text: 'Light Green',
                  },
                  operator: '>=',
                  order: 1,
                  value: '',
                },
              ],
            },
            type: 'line',
            stacking: null,
            yAxis: {
              max: null,
              min: null,
              minorTickInterval: 'auto',
              opposite: false,
              type: 'linear',
            },
          },
        },
      ],
    },
    eventListeners: {},
    expressionTrees: {
      [getUniqueId()]: emptyExpressionTreeModel,
    },
    gridster: {},
    resolution: {
      value: '',
    },
  },
};

export const defaultEmptyAnomalyTile = {
  sizeX: SIZE_TILES.ANOMALY_TILE.sizeX,
  sizeY: SIZE_TILES.ANOMALY_TILE.sizeY,
  title: {
    text: 'Anomalies',
  },
  row: null,
  col: null,
  expressionTreeType: 'simple',
  tileType: 'anomaliesList',
  tileConfig: {
    legend: {
      enabled: true,
    },
    bucketStartTimeFF: true,
    displayProperties: {
      byTreeExp: [
        {
          id: getUniqueId(),
          options: {
            anomaliesList: {
              type: 'anomaliesList',
              orientation: 'leftRight',
              suffix: {
                fontSize: 50,
                text: '',
              },
              prefix: {
                fontSize: 50,
                text: '',
              },
              mainValue: {
                state: 'both',
                sort: 'score',
                size: '5',
                direction: 'both',
                score: '75',
                delta: '0',
                deltaType: 'absolute',
                durationUnits: 'minutes',
                durationValue: '5',
                resolution: 'medium',
              },
              defaultColor: {
                hex: '',
                text: 'Transparent',
              },
            },
            type: 'line',
            stacking: null,
            yAxis: {
              max: null,
              min: null,
              minorTickInterval: 'auto',
              opposite: false,
              type: 'linear',
            },
          },
        },
      ],
    },
    eventListeners: {},
    expressionTrees: {
      [getUniqueId()]: emptyExpressionTreeModel,
    },
    gridster: {},
    resolution: {
      value: '',
    },
  },
};

export const defaultEmptyAlertsTile = {
  sizeX: SIZE_TILES.ALERTS_TILE.sizeX,
  sizeY: SIZE_TILES.ALERTS_TILE.sizeY,
  title: {
    text: 'Alerts',
  },
  row: null,
  col: null,
  expressionTreeType: 'simple',
  tileType: 'alertsList',
  tileConfig: {
    legend: {
      enabled: true,
    },
    bucketStartTimeFF: true,
    displayProperties: {
      byTreeExp: [
        {
          id: getUniqueId(),
          options: {
            alertsList: {
              type: 'alertsList',
              orientation: 'leftRight',
              suffix: {
                fontSize: 50,
                text: '',
              },
              prefix: {
                fontSize: 50,
                text: '',
              },
              mainValue: {
                state: 'both',
                sort: 'score',
                size: '5',
                direction: 'both',
                score: '75',
                delta: '0',
                deltaType: 'absolute',
                durationUnits: 'minutes',
                durationValue: '5',
                resolution: 'medium',
              },
              defaultColor: {
                hex: '',
                text: 'Transparent',
              },
            },
            type: 'line',
            stacking: null,
            yAxis: {
              max: null,
              min: null,
              minorTickInterval: 'auto',
              opposite: false,
              type: 'linear',
            },
          },
        },
      ],
    },
    eventListeners: {},
    expressionTrees: {
      [getUniqueId()]: emptyExpressionTreeModel,
    },
    gridster: {},
    resolution: {
      value: '',
    },
  },
};

export const defaultEmptyTextTile = {
  sizeX: SIZE_TILES.TEXT_TILE.sizeX,
  sizeY: SIZE_TILES.TEXT_TILE.sizeY,
  row: null,
  col: null,
  type: 'title',
  freeText: {
    text: '',
    type: 'plain',
    class: 'dashboard-title',
  },
  class: 'dashboard-title',
  title: {
    text: '',
  },
  color: 'white',
  tileType: 'text',
};

// eslint-disable-next-line no-unused-vars
const isRootFunctionEmpty = (expressionTree) =>
  expressionTree.root &&
  (expressionTree.root.type === 'metric' ||
    (expressionTree.root.function === '' &&
      expressionTree.root.children.length === 1 &&
      expressionTree.root.children[0].type === 'metric'));

const prepareTree = (expressionTreeSource) => {
  const expressionTree = cloneDeep(expressionTreeSource);
  treeVisitor(expressionTree.root, 'children', (childNode) => {
    // eslint-disable-next-line no-param-reassign
    childNode.uiData = {
      ...childNode.uiData,
      failures: [],
    };

    if (childNode.searchObject) {
      // eslint-disable-next-line no-param-reassign
      childNode.searchObject = {
        ...childNode.searchObject,
        expression: (childNode.searchObject.expression || []).filter((item) => item.value),
      };
    }
  });
  return expressionTree.root && expressionTree.root.function === ''
    ? {root: expressionTree.root.children[0]}
    : expressionTree;
};

const notDefinedColumn = 0;
const notDefinedRow = 100;

export const generateLayout = (tiles) =>
  tiles.map((item) => {
    const isStat = !!item.andtGauge;
    const isText = !!item.freeText;
    const minHText = isText && item.type === 'note' ? 2 : SIZE_TILES.TEXT_TILE.sizeY;
    const minW = isStat ? SIZE_TILES.STAT_TILE.sizeX : SIZE_TILES.GRAPH_TILE.sizeX;
    // eslint-disable-next-line no-nested-ternary
    const minH = isStat ? SIZE_TILES.STAT_TILE.sizeY : isText ? minHText : SIZE_TILES.GRAPH_TILE.sizeY;
    const maxW = isStat ? 4 : undefined;
    // eslint-disable-next-line no-nested-ternary
    const maxH = isText && item.type === 'title' ? SIZE_TILES.TEXT_TILE.sizeY : isStat ? 4 : undefined;
    return {
      x: item.col === undefined ? notDefinedColumn : item.col,
      y: item.row === undefined ? notDefinedRow : item.row,
      w: item.sizeX,
      h: item.sizeY,
      i: item.id,
      minW,
      minH,
      maxW,
      maxH,
    };
  });

export const makeUpdateTilePayload = (dashboard, newTile, id, computedTiles) => ({
  ...dashboard,
  tiles: id
    ? dashboard.tiles.map((existingTile) => (existingTile.id === id ? newTile : existingTile))
    : [
        ...dashboard.tiles.map((tile, index) =>
          computedTiles
            ? {
                ...tile,
                col: computedTiles[index].x,
                row: computedTiles[index].y,
                sizeX: computedTiles[index].w,
                sizeY: computedTiles[index].h,
              }
            : tile,
        ),
        newTile,
      ],
});

export const makeDeleteTilePayload = (dashboard, layout, id) => {
  const filteredTiles = dashboard.tiles
    .map((item, index) => ({
      ...item,
      col: layout[index].x,
      row: layout[index].y,
      sizeX: layout[index].w,
      sizeY: layout[index].h,
    }))
    .filter((existingTile) => existingTile.id !== id);
  return {
    ...dashboard,
    tiles: filteredTiles,
  };
};

export const makeUpdateLegendPayload = (data, size, isLegendRight) => ({
  ...data,
  lineChart: {
    ...data.lineChart,
    legend: {
      ...data.lineChart.legend,
      position: isLegendRight ? 'right' : 'bottom',
      [isLegendRight ? 'horizontalRatio' : 'ratio']: size,
    },
  },
});

export const makeUpdatePieLegendPayload = (data, size, actualTileLayout) => ({
  ...data,
  sizeX: actualTileLayout.w,
  sizeY: actualTileLayout.h,
  andtGauge: {
    ...data.andtGauge,
    legend: {
      ...data.andtGauge.legend,
      horizontalRatio: size,
    },
  },
});

export const makeTileAlertsPayload = (dashboardData, tileId, alertId) => ({
  ...dashboardData,
  tileAlerts: [
    ...(dashboardData.tileAlerts || []),
    {
      tileId,
      alertId,
    },
  ],
});

export const detachAlertFromTilePayload = (dashboardData, tileId, alertId) => ({
  ...dashboardData,
  tileAlerts:
    dashboardData.tileAlerts.filter((tileAlert) => alertId !== tileAlert.alertId && tileId !== tileAlert.tileId) || [],
});

export const makeGraphTilePayload = (data, formValues, position) => {
  const validTreeIds = formValues.expressionTrees.map((item) => item.id);
  return {
    ...data,
    lineChart: {
      ...data.lineChart,
      expressionTrees: formValues.expressionTrees.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: {
            ...emptyExpressionTreeModel,
            expressionTree: prepareTree(item.expressionTree),
            filter: {
              // eslint-disable-next-line max-len
              function: (formValues.multiPreviewOptions[item.id] || formValues.multiPreviewOptions['*']).sort,
              parameters: [
                {
                  name: 'Top N',
                  // eslint-disable-next-line max-len
                  value: (formValues.multiPreviewOptions[item.id] || formValues.multiPreviewOptions['*']).show,
                },
              ],
              children: [],
              id: getUniqueId(),
              type: 'function',
            },
            resolution: {
              value: formValues.timeScale,
            },
            excludeComposites: !item.isShowComposites,
          },
        }),
        {},
      ),
      seriesProperties: {
        // needs array for compatibility
        byTreeExp: Object.entries(formValues.byTreeExp).flatMap(([id, options]) =>
          validTreeIds.includes(id) ? [{id, options}] : [],
        ),
      },
      legend: {
        ...data.lineChart.legend,
        enabled: formValues.showLegend,
        position: formValues.legendPosition,
      },
      staticLine: formValues.staticLine,
    },
    title: {
      text: formValues.title,
    },
    expressionTreeType: formValues.expressionTreeType,
    id: data.id || getUniqueId(),
    ...(!data.id ? {col: position.x, row: position.y} : {}),
  };
};

export const makeStatTilePayload = (data, formValues, position) => {
  const payload = cloneDeep(data);
  const {andtGauge} = payload.andtGauge.displayProperties.byTreeExp[0].options;
  andtGauge.mainValue = {
    ...andtGauge.mainValue,
    displayDecimal: formValues.decimal,
    unitText: (formValues.unit || {}).value || '',
    abbreviate: formValues.abbreviate,
    showMeasure: formValues.showMeasure,
    showGraph: formValues.showGraph,
    inputNoData: formValues.inputNoData,
    tileType: formValues.tileType,
    minScale: formValues.minScale,
    maxScale: formValues.maxScale,
    isShowValueLabels: formValues.isShowValueLabels,
    thresholds: formValues.thresholds,
  };
  const expressionTree = prepareTree(
    formValues.expressionTreeType === 'simple'
      ? formValues.simpleExpressionTree.expressionTree
      : formValues.advancedExpressionTree[0].expressionTree,
  );
  return {
    ...payload,
    andtGauge: {
      ...payload.andtGauge,
      expressionTrees: {
        [getUniqueId()]: {
          ...emptyExpressionTreeModel,
          expressionTree,
          scalarTransforms: [
            {
              children: [],
              function: formValues.scalarTransformType,
              id: getUniqueId(),
              parameters: [],
              type: 'function',
            },
          ],
          filter: {
            function: 'alphanumeric',
            parameters: [
              {
                name: 'Top N',
                value: ['pie', 'donut'].includes(formValues.tileType) ? 50 : 1,
              },
            ],
            children: [],
            id: getUniqueId(),
            type: 'function',
          },
          excludeComposites: !(formValues.expressionTreeType === 'simple'
            ? formValues.simpleExpressionTree.isShowComposites
            : formValues.advancedExpressionTree[0].isShowComposites),
        },
      },
      legend: {
        enabled: formValues.showLegend,
      },
    },
    title: {
      text: formValues.title,
    },
    expressionTreeType: formValues.expressionTreeType,
    id: data.id || getUniqueId(),
    ...(!data.id ? {col: position.x, row: position.y} : {}),
  };
};

export const makeTextTilePayload = (data, formValues, position) => ({
  ...data,
  freeText: {
    ...data.freeText,
    text: formValues.description,
  },
  title: {
    text: formValues.title,
  },
  color: formValues.color,
  type: formValues.type,
  id: data.id || getUniqueId(),
  ...(!data.id ? {col: position.x, row: position.y} : {}),
});

export const makeOtherTilePayload = (data, formValues, position, mainValue, tileType) => {
  const payload = cloneDeep(data);
  const configKey = 'tileConfig';
  const {options} = payload[configKey].displayProperties.byTreeExp[0];
  const tileOptions = options[tileType];
  tileOptions.mainValue = {...tileOptions.mainValue, ...mainValue};
  const expressionTree = prepareTree(formValues.expressionTrees[0].expressionTree);
  return {
    ...payload,
    [configKey]: {
      ...payload[configKey],
      expressionTrees: {
        [getUniqueId()]: {
          ...emptyExpressionTreeModel,
          expressionTree,
          scalarTransforms: [
            {
              children: [],
              function: formValues.scalarTransformType,
              id: getUniqueId(),
              parameters: [],
              type: 'function',
            },
          ],
          filter: {
            function: 'alphanumeric',
            parameters: [
              {
                name: 'Top N',
                value: ['pie', 'donut'].includes(formValues.tileType) ? 50 : 1,
              },
            ],
            children: [],
            id: getUniqueId(),
            type: 'function',
          },
          excludeComposites: !formValues.showComposites,
        },
      },
      legend: {
        enabled: formValues.showLegend,
      },
    },
    title: {
      text: formValues.title,
    },
    expressionTreeType: formValues.expressionTreeType,
    id: data.id || getUniqueId(),
    ...(!data.id ? {col: position.x, row: position.y} : {}),
  };
};

export const parseFiltersPayload = (payload) =>
  payload.map((item) =>
    // eslint-disable-next-line no-nested-ternary
    item.selectedValue
      ? item.selectedValue.isAll
        ? [{key: item.expressions[0].key, value: '*', label: 'All (*)'}]
        : item.selectedValue.value.split(' OR ').map((value) => ({
            key: item.expressions[0].key,
            label: value,
            value,
          }))
      : [],
  );

export const makeFiltersPayload = (arr) =>
  arr.flatMap((filter) => {
    const isAll = filter.some((item) => item.value === '*');
    const joinedValue = filter.map((item) => item.value).join(' OR ');
    const filterPropertyValues = ''; // regex
    return filter.length > 0
      ? [
          {
            name: filter[0].key, // selector name
            selectedValue: {
              isAll,
              value: isAll ? filterPropertyValues : joinedValue,
              name: isAll ? 'All' : joinedValue,
            },
            expressions: [
              {
                value: filterPropertyValues, // regex
                key: filter[0].key,
                type: 'property',
              },
            ],
          },
        ]
      : [];
  });

export const getSizeTileType = (tile) => {
  const keys = Object.keys(tile);
  switch (keys) {
    case keys.includes('andtGauge'):
      return SIZE_TILES.STAT_TILE;
    case keys.includes('lineChart'):
      return SIZE_TILES.GRAPH_TILE;
    case keys.includes('freeText'):
      return SIZE_TILES.TEXT_TILE;
    default:
      return SIZE_TILES.STAT_TILE;
  }
};

export const getTextTypeModal = (type) => {
  switch (type) {
    case OPTION_TILES_VALUES.SETTINGS:
      return {
        title: 'Edit',
        btn: 'Update',
      };
    case OPTION_TILES_VALUES.DUPLICATE:
      return {
        title: 'Duplicate',
        btn: 'Duplicate',
      };
    case OPTION_TILES_VALUES.NEW:
      return {
        title: 'New',
        btn: 'Create',
      };
    case OPTION_TILES_VALUES.COPY:
      return {
        title: 'Copy',
        btn: 'Copy',
      };
    default:
      return {
        title: '',
        btn: 'Save',
      };
  }
};

export const makeVolumeCondition = (data) => ({
  type: 'VOLUME_CONDITION',
  bound: data.bound,
  enableAutoTuning: true,
  enabled: true,
  numLastPoints: data.numLastPoints,
  rollup: data.rollup,
  value: data.value,
});

export const makeNewAlertDataPayload = ({compositeObject, ownerId, dashboardId, tileId, conditions, title}) => ({
  ownerId,
  type: 'incident',
  severity: 'high',
  noDataDuration: null,
  expressionTreeModel: compositeObject,
  eventsFilter: {
    aggregation: {
      aggregationField: null,
      resolution: null,
      topEventSize: 0,
      maxBuckets: 100,
    },
    filter: null,
  },
  isInfluencingEvents: false,
  title,
  story: '',
  staticAlert: false,
  anomalyAlert: true,
  noDataAlert: false,
  rollups: 'medium',
  channels: [],
  pauseTime: null,
  paused: false,
  enableAutoTuneByAnodot: true,
  advancedModeOpen: false,
  notifyOnlyOpen: false,
  conditions,
  connectedTiles: [
    {
      dashboardId,
      tileId,
    },
  ],
  updatePolicy: {
    minInterval: 900,
    triggerOnMetricsChange: true,
    type: 'parameterChange',
  },
});

export const removeAliasRootFuncFromTree = (tree) => {
  const exTree = cloneDeep(tree);
  if (includes(['alias', 'aliasByGroup', 'aliasByProperty'], get(exTree, 'expressionTree.root.function', null))) {
    const aliasChildNode = get(exTree, 'expressionTree.root.children[0]');
    defaults(aliasChildNode, exTree.expressionTree.root);
    exTree.expressionTree.root = aliasChildNode;
  }
  return exTree;
};

export const removeUIData = (tree) => {
  const treeCleaned = cloneDeep(tree);
  deletePropFromObj(treeCleaned, 'uiData');
  deletePropFromObj(treeCleaned, 'q');
  deletePropFromObj(treeCleaned, 'ids');
  return treeCleaned;
};

const expressionTreeTiles = ['lineChart', 'andtGauge'];
const nodeCleanableProperties = [
  '$$hashKey',
  'failures',
  'warnings',
  'treeId',
  'watchId',
  'needsValidation',
  'description',
  'activateOnCreate',
  'uiChartsLayout',
];
const paramCleanableProperties = [
  '$$hashKey',
  'defaultValue',
  'description',
  'mandatory',
  'type',
  'optionalValues',
  'validators',
  'displayType',
];
const propsToRemoveWhenSavingPreset = ['dateRange', 'size', 'index', 'selectedEventDateRange'];
const maxTopEvents = 0; // per bucket
const maxBuckets = 100;
const shouldShowEventsDefaultValue = false;

const cleanChartOptions = (options) => {
  const cleanDisplayProps = (displayProps) => {
    const propsToRemove = ['type', 'stacking'];
    _.forEach(displayProps.byTreeExp, (treeExp) => {
      _.forEach(propsToRemove, (prop) => {
        if (treeExp.options[prop] === get(displayProps, 'all[prop]', null)) {
          // eslint-disable-next-line no-param-reassign
          delete treeExp.options[prop];
        }
      });
      _.forEach(_.keys(treeExp.options.yAxis), (prop) => {
        if (treeExp.options.yAxis[prop] === get(displayProps, 'all.yAxis[prop]', null)) {
          // eslint-disable-next-line no-param-reassign
          delete treeExp.options.yAxis[prop];
        }
      });
    });
    // eslint-disable-next-line no-param-reassign
    delete displayProps.all;
  };

  const propsToRemove = [
    'id',
    'dateRange',
    'eventListeners',
    'actions',
    'destroying',
    'tooltip',
    'enableClientZoom',
    'resolution',
    'mobileMaxChartHeight',
  ];
  const tmpResolution = _.get(options, 'resolution.value');
  _.forEach(propsToRemove, (prop) => {
    // eslint-disable-next-line no-param-reassign
    delete options[prop];
  });
  if (options.seriesProperties) {
    cleanDisplayProps(options.seriesProperties);
  }
  // eslint-disable-next-line no-param-reassign
  options.resolution = _.isString(tmpResolution) ? {value: tmpResolution} : null;
  if (_.get(options, 'legend')) {
    // eslint-disable-next-line no-param-reassign
    delete options.legend.extra;
  }
  // eslint-disable-next-line no-param-reassign
  options.eventListeners = {};
};

const cleanGaugeOptions = (options) => {
  const propsToRemove = ['id', 'dateRange', 'eventListeners', 'actions', 'destroying'];
  const tmpResolution = _.get(options, 'resolution.value');
  _.forEach(propsToRemove, (prop) => {
    // eslint-disable-next-line no-param-reassign
    delete options[prop];
  });
  if (options.displayProperties) {
    // eslint-disable-next-line no-param-reassign
    delete options.displayProperties.all;
  }
  // eslint-disable-next-line no-param-reassign
  options.resolution = _.isString(tmpResolution) ? {value: tmpResolution} : null;
  // eslint-disable-next-line no-param-reassign
  options.eventListeners = {};
};

const cleanExpressionTree = (tree) => {
  if (!tree) {
    return;
  }
  const newTree = _.cloneDeep(tree);
  treeVisitor(newTree, 'children', (nd) => {
    _.forEach(nodeCleanableProperties, (prop) => {
      // eslint-disable-next-line no-param-reassign
      delete nd[prop];
    });
    _.forEach(nd.parameters, (param) => {
      _.forEach(paramCleanableProperties, (prop) => {
        // eslint-disable-next-line no-param-reassign
        delete param[prop];
      });
    });
  });
  // eslint-disable-next-line consistent-return
  return newTree;
};

const cleanNodeArray = (arr) => {
  if (!arr) {
    return;
  }
  const newArr = _.clone(arr);
  _.forEach(arr, (node, index) => {
    newArr[index] = cleanExpressionTree(node);
  });
  // eslint-disable-next-line consistent-return
  return newArr;
};

const getCleanExpressionTrees = (expressionTrees, isSavable) => {
  const newExpressionTrees = _.cloneDeep(expressionTrees);
  _.forEach(newExpressionTrees, (tree) => {
    _.forEach(nodeCleanableProperties, (prop) => {
      // eslint-disable-next-line no-param-reassign
      delete tree[prop];
    });
    // eslint-disable-next-line no-param-reassign
    tree.expressionTree.root = cleanExpressionTree(tree.expressionTree.root);
    // eslint-disable-next-line no-param-reassign
    tree.filter = cleanExpressionTree(tree.filter);
    // eslint-disable-next-line no-param-reassign
    tree.scalarTransforms = cleanNodeArray(tree.scalarTransforms);

    if (isSavable) {
      // eslint-disable-next-line no-param-reassign
      delete tree.includeCubes;
    }
  });

  return newExpressionTrees;
};

const cleanSelectorsFilter = (selectorsFilter) => {
  const newSelectors = [];
  _.forEach(selectorsFilter?.selectors || [], (selector) => {
    const selectorWithId = {...selector, id: getUniqueId()};
    newSelectors.push(_.pick(selectorWithId, ['id', 'name', 'expressions', 'selectedValue', 'dependencyGroup']));
  });

  return {selectors: newSelectors};
};

const cleanTimeRangesFilter = (timeRangesFilter) => {
  const newTimeRanges = [];
  _.forEach(timeRangesFilter.timeRanges, (timeRanges) => {
    newTimeRanges.push(_.pick(timeRanges, ['id', 'name', 'type', 'selectedValue', 'dependencyGroup']));
  });

  return {timeRanges: newTimeRanges};
};

const cleanPreset = (preset) => {
  _.forEach(propsToRemoveWhenSavingPreset, (prop) => {
    // eslint-disable-next-line no-param-reassign
    delete preset[prop];
  });
};

const cleanEventsFilter = (eventsFilter) => {
  const res = {presets: [], current: {}, shouldShowEvents: eventsFilter.shouldShowEvents};
  _.forEach(eventsFilter.presets, (preset) => {
    // eslint-disable-next-line no-underscore-dangle
    const preset_ = _.cloneDeep(preset);
    cleanPreset(preset_);
    res.presets.push(preset_);
  });
  res.current.id = eventsFilter.current.id;
  return res;
};

const getEmptyFilter = () => ({
  filter: {
    categories: [],
    q: {
      expression: [],
    },
  },
  aggregation: {
    aggregationField: null,
    resolution: null,
    topEventSize: maxTopEvents,
    maxBuckets,
  },
  dateRange: getDate(),
  size: 0,
  index: 0,
});

const getDefaultEventsFilter = () => ({
  presets: [],
  current: getEmptyFilter(),
  shouldShowEvents: shouldShowEventsDefaultValue,
  // isInfluencingEvents: false,
});

const getCleanDashboard = (dashboard, isOwner, values) => {
  const dash = _.cloneDeep(dashboard);
  _.forEach(dash.tiles, (tile) => {
    _.forEach(['collapseHeader', 'actions', 'isEditable', 'minSizeX', 'minSizeY'], (prop) => {
      // eslint-disable-next-line no-param-reassign
      delete tile[prop];
    });
    if (tile.lineChart) {
      cleanChartOptions(tile.lineChart);
    }
    if (tile.andtGauge) {
      cleanGaugeOptions(tile.andtGauge);
    }
    if (tile.timeRange) {
      // eslint-disable-next-line no-param-reassign
      delete tile.timeRange.dependencyGroup;
      // eslint-disable-next-line no-param-reassign
      delete tile.timeRange.selectedValue;
    }

    const intersect = _.intersection(_.keys(tile), expressionTreeTiles);
    if (intersect.length) {
      // eslint-disable-next-line no-param-reassign
      tile[intersect[0]].expressionTrees = getCleanExpressionTrees(tile[intersect[0]].expressionTrees);
    }
  });
  dash.selectorsFilter = cleanSelectorsFilter(dash.selectorsFilter);
  dash.timeRangesFilter = dash.timeRangesFilter && cleanTimeRangesFilter(dash.timeRangesFilter);
  dash.dateRange = isOwner ? getDateValue(values.dateRange) : getDateValue(dash.dateRange);
  dash.timeScale = isOwner ? values.timeScale : dash.timeScale;
  if (dash.timeRangesFilter) {
    _.forEach(dash.timeRangesFilter.timeRanges, (timeRange) => {
      // eslint-disable-next-line no-param-reassign
      timeRange.selectedValue =
        timeRange.selectedValue.constRange === rangeTypes.c.value // clean the c values
          ? getDate()
          : getDateValue(timeRange.selectedValue);
    });
  }
  const eventsFilter = cleanEventsFilter(getDefaultEventsFilter()); // $scope.filter.eventsFilter
  if (isOwner && dash.eventsFilter) {
    // only owner gets to set defaults for DB
    eventsFilter.current.id = dash.eventsFilter.current.id; // only owner gets to set defaults for DB
    eventsFilter.current.shouldShowEvents = dash.eventsFilter.current.shouldShowEvents;
  }
  dash.eventsFilter = eventsFilter;
  return dash;
};

export const getCleanDashboardForExport = (dashboard, isOwner, values) => {
  const dash = getCleanDashboard(dashboard, isOwner, values);
  const dashPropsToRemove = ['_id', '__v', 'created', 'modified', 'ownerUser', 'ownerOrganization', 'owner'];
  _.forEach(dashPropsToRemove, (prop) => {
    delete dash[prop];
  });
  dash.tileAlerts = [];
  dash.star = [];
  dash.editableBy = [];
  dash.isV2 = true;
  return dash;
};

export const getPropsList = (metric) => {
  const metricProps = metric.properties.concat(metric.tags);
  const {origin} = metric;

  const lastPropsKeys = ['target_type', 'mtype', 'func'];
  let propsList = [...Object.values(omitBy(metricProps, (propItem) => lastPropsKeys.includes(propItem.key)))];

  if (origin) {
    propsList = [
      ...propsList,
      {
        key: `@${origin.type}`,
        value: origin.title,
        isLast: true,
        isClickable: true,
      },
    ];
  }

  return propsList;
};
