// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  getSelectedDataStream,
  getMixpanelStreamEntitiesData,
  getMixpanelStreamEntitiesIsLoading,
  getMixpanelStreamEntityData,
  getMixpanelStreamEntityIsLoading,
} from 'bc/store/selectors';
import {
  fetchMixpanelEntities as fetchMixpanelEntitiesAction,
  fetchMixpanelEntityMetadata as fetchMixpanelEntityMetadataAction,
  setMixpanelStreamEntity as setMixpanelStreamEntityAction,
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
} from 'bc/store/actions';

import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  dataStream: Object,

  // connect
  isEntityLoading: Boolean,
  isEntitiesLoading: Boolean,
  isDisabled: Boolean,
  entities: Object,
  entity: Object,
  fetchMixpanelEntities: Function,
  setMixpanelStreamEntity: Function,
  fetchMixpanelEntityMetadata: Function,
  setSelectedStreamKeyVal: Function,
};

export default connect(
  (state) => ({
    dataStream: getSelectedDataStream(state),
    entities: getMixpanelStreamEntitiesData(state),
    isEntitiesLoading: getMixpanelStreamEntitiesIsLoading(state),
    entity: getMixpanelStreamEntityData(state),
    isEntityLoading: getMixpanelStreamEntityIsLoading(state),
  }),
  {
    fetchMixpanelEntities: fetchMixpanelEntitiesAction,
    fetchMixpanelEntityMetadata: fetchMixpanelEntityMetadataAction,
    setMixpanelStreamEntity: setMixpanelStreamEntityAction,
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
  },
)(
  class Entities extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {fetchMixpanelEntities, dataStream: stream} = this.props;

      fetchMixpanelEntities({dataSourceId: stream.dataSourceId});
    }

    componentDidUpdate() {
      const {setMixpanelStreamEntity, isEntityLoading, entity} = this.props;

      if (isEntityLoading === false) {
        setMixpanelStreamEntity({entity});
      }
    }

    projectChanged = (item) => {
      const {fetchMixpanelEntityMetadata, setSelectedStreamKeyVal, dataStream: stream} = this.props;

      fetchMixpanelEntityMetadata({dataSourceId: stream.dataSourceId, entityType: item.type});
      setSelectedStreamKeyVal({entityType: item.type});
    };

    render() {
      const {dataStream, entities, isEntitiesLoading, isDisabled} = this.props;
      const options = entities.map((entity) => ({name: entity, type: entity}));
      const selectedIndex = options.findIndex((option) => option.type === dataStream.entityType);

      return (
        <div className="shell-col">
          <SelectAndt
            disabled={isDisabled}
            optionHeight={40}
            className="andt-dropdown"
            options={options}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.projectId}
            type={TYPE_SEARCH}
            theme={THEME_LIGHT}
            value={isEntitiesLoading ? null : options[selectedIndex]}
            onChange={this.projectChanged}
            placeholder={isEntitiesLoading ? 'Loading...' : 'Choose an Entity'}
            automationId="mixpanel-entities-dropdown"
          />
        </div>
      );
    }
  },
);
