import {useEffect} from 'react';
import {useFormContext} from 'react-hook-form';

function useWatch(currentStageIndex) {
  const currentStageIndexStr = currentStageIndex ? currentStageIndex.toString() : undefined;
  const {watch} = useFormContext();
  let formWatcher = {};

  useEffect(() => {
    watch(currentStageIndexStr);
  }, []);

  formWatcher = watch(currentStageIndexStr);

  return formWatcher;
}

export default useWatch;
