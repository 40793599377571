// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'admin.cs-portal/store/selectors';
import * as actions from 'admin.cs-portal/store/actions';
import {viewsToRender} from 'admin.cs-portal/services/csPortalService';
import PageLayout from 'common/componentsV2/PageLayout';
import CsPortalHeader from '../components/header/CsPortalHeader';
import NavigationTabs from '../components/navigation-tabs/NavigationTabs';

import './csPortal.module.scss';

type PropTypes = {
  // connect
  tabs: Array<Object>,
  selectedTab: Object,
  selectedCustomer: Object,
  changeTab: Function,
};

export default connect(
  (state) => ({
    tabs: selectors.getTabsList(state),
    selectedTab: selectors.getSelectedTab(state),
    selectedCustomer: selectors.getSelectedCustomer(state),
  }),
  {
    changeTab: actions.changeTab,
  },
)(
  class CsPortal extends React.PureComponent {
    props: PropTypes;

    render() {
      const {changeTab, selectedCustomer, tabs, selectedTab} = this.props;

      return (
        <PageLayout header={<CsPortalHeader selected={selectedTab} />} shouldOverflowY>
          {selectedCustomer && selectedCustomer.value ? (
            <div>
              <NavigationTabs tabsList={tabs} onChange={(tab) => changeTab(tab)} />
              {viewsToRender[selectedTab.componentName]()}
            </div>
          ) : (
            <div styleName="empty-state-container">
              <div>
                <p>Select Customer</p>
                <i className="icon icn-nav24-user" />
              </div>
            </div>
          )}
        </PageLayout>
      );
    }
  },
);
