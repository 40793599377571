// @flow
import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import {
  getAllowedRangeTypesByPollingInterval,
  getNotificationDelayOption,
  pollingIntervals,
} from 'bc/services/dataStreamService';
import Button, {COLORS} from 'common/componentsV2/Button';
import NotificationDelay from 'bc/components/streams/editor/scheduler/NotificationDelay';
import PollingInterval from 'bc/components/streams/editor/scheduler/PollingInterval';
import TimeZone from 'bc/components/streams/editor/scheduler/TimeZone';
import MaxMissingFiles from 'bc/components/streams/editor/scheduler/MaxMissingFiles';
import DateRange from 'bc/components/streams/editor/scheduler/DateRange';
import {getSelectedDataStream, getSelectedDataStreamDvp} from 'bc/store/selectors';
import {
  createDvpObjForServer,
  DVP,
  getUnitFormatted,
  getValueFormated,
} from 'bc/components/streams/editor/missingDataSection/missingDataService';

import './SqlSchedularModal.module.scss';

type PropTypes = {
  setSelectedStreamKeyVal: Function,

  dataStream: Object,
  dvpConfig: Object,
  onClose: Function,
};

export default connect(
  (state) => ({
    dataStream: getSelectedDataStream(state),
    dvpConfig: getSelectedDataStreamDvp(state),
  }),
  {
    setSelectedStreamKeyVal,
    getSelectedDataStreamDvp,
  },
)(
  class SqlSchedularModal extends React.PureComponent {
    props: PropTypes;

    state = {
      pollingInterval: this.props.dataStream.pollingInterval,
      historicalDateRange: this.props.dataStream.historicalDateRange,
      timeZone: this.props.dataStream.timeZone,
      maxBackFillIntervals: this.props.dataStream.maxBackFillIntervals,
      notificationDelay: this.props.dataStream.notificationDelay,
      delayMinutes: this.props.dataStream.delayMinutes,
      isTimeZoneAlertOpen: false,
    };

    onCloseButtonClicked = () => {
      this.props.onClose();
    };

    doNothing = () => {};

    pollingChanged = (val) => {
      this.setState({pollingInterval: val});
    };

    timeZoneChanged = (val) => {
      let isTimeZoneAlertOpen = false;
      let value = val;
      if (value === 'Browser') {
        value = moment.tz.guess();
      }
      if (this.props.dataStream.timeDefinition?.timeZone && this.props.dataStream.timeDefinition?.timeZone !== val) {
        isTimeZoneAlertOpen = true;
      }
      this.setState({
        isTimeZoneAlertOpen,
        timeZone: value,
      });
    };

    maxMissingFilesChanged = (val) => {
      this.setState({maxBackFillIntervals: val});
    };

    delayMinutesChanged = (val) => {
      this.setState({delayMinutes: val});
    };

    historicalDateRangeChanged = (val) => {
      this.setState({historicalDateRange: val});
    };

    notificationDelayChanged = (val) => {
      this.setState({notificationDelay: val});
    };

    onSave = () => {
      let selectedStreamKeyValue = {
        pollingInterval: this.state.pollingInterval,
        historicalDateRange: this.state.historicalDateRange,
        timeZone: this.state.timeZone,
        maxBackFillIntervals: this.state.maxBackFillIntervals,
        delayMinutes: this.state.delayMinutes,
        notificationDelay: this.state.notificationDelay,
      };
      if (this.props.dvpConfig) {
        const currentDvpConfig = createDvpObjForServer({
          [DVP.dvpConfig.type]: true,
          [DVP.maxDVPDurationHours.type]: getValueFormated(this.props.dvpConfig?.maxDVPDurationHours),
          [DVP.maxDVPDurationUnit.type]: getUnitFormatted(this.props.dvpConfig?.maxDVPDurationUnit).value,
          [DVP.gaugeKeepLastValue.type]: this.props.dvpConfig?.gaugeValue?.keepLastValue,
          [DVP.gaugeValue.type]: this.props.dvpConfig?.gaugeValue?.value,
          [DVP.counterKeepLastValue.type]: this.props.dvpConfig?.counterValue?.keepLastValue,
          [DVP.counterValue.type]: this.props.dvpConfig?.counterValue?.value,
          [DVP.baseRollup.type]: pollingIntervals[this.state.pollingInterval].timeScale,
        });
        selectedStreamKeyValue = {...selectedStreamKeyValue, dvpConfig: currentDvpConfig.dvpConfig};
      }

      this.props.setSelectedStreamKeyVal(selectedStreamKeyValue);
      this.onCloseButtonClicked();
    };

    render() {
      return (
        <div styleName="modal-body">
          <div styleName="itemContainer">
            <PollingInterval
              title="Query every"
              pollingInterval={this.state.pollingInterval}
              allowedValues={['daily', 'h12', 'h8', 'h6', 'h4', 'h3', 'h2', 'hourly', 'm15', 'm5', 'm1']}
              onChange={this.pollingChanged}
            />
          </div>
          <div styleName="itemContainer">
            <DateRange
              title="Collect Since"
              allowedRangeTypes={getAllowedRangeTypesByPollingInterval(this.props.dataStream.pollingInterval)}
              dateRange={this.state.historicalDateRange}
              onChange={this.historicalDateRangeChanged}
              isUnix
            />
          </div>

          {/* ********************************************************************************************* */}

          <div styleName="itemContainer">
            <MaxMissingFiles maxMissingFiles={this.state.maxBackFillIntervals} onChange={this.maxMissingFilesChanged} />
          </div>

          <div styleName="itemContainer">
            <div styleName="textTitle">Delay (Minutes):</div>
            <div styleName="slideWrapper-text">
              <input
                type="number"
                min="0"
                onChange={(e) => this.delayMinutesChanged(e.target.value)}
                styleName="textInput"
                automation-id="schedulerEditorModalDelay"
                value={this.state.delayMinutes}
              />
            </div>
          </div>
          <div styleName="itemContainer">
            <TimeZone timeZone={this.state.timeZone} onChange={this.timeZoneChanged} />
          </div>

          {this.state.isTimeZoneAlertOpen ? (
            <div styleName="itemContainer">
              <div styleName="textWrapper">
                You have chosen a Timezone different than the data records’ timezone. This could result in ignored rows
                in the streaming process.
              </div>
            </div>
          ) : null}

          <div styleName="itemContainer">
            <NotificationDelay
              notificationDelayOption={getNotificationDelayOption()}
              pollingInterval={this.state.pollingInterval}
              notificationDelay={this.state.notificationDelay}
              onChange={this.notificationDelayChanged}
            />
          </div>

          <div styleName="footer">
            <div styleName="left-button">
              <Button
                colorSchema={COLORS.GRAY_400}
                text="Cancel"
                automation-id="schedulerEditorModalCancel"
                onClick={this.onCloseButtonClicked}
              />
            </div>
            <div>
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Save"
                automation-id="schedulerEditorModalSave"
                onClick={this.onSave}
              />
            </div>
          </div>
        </div>
      );
    }
  },
);
