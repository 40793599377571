import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core';
import {useField} from 'react-final-form';
import {fetchGroupsData} from 'admin.users/store/selectors';
import {getAlertAutoAssignmentEnabled} from 'profile/store/selectors';
import {useSelector} from 'react-redux';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import Button, {COLORS} from 'common/componentsV2/Button';
import SmartTooltip from 'common/componentsV2/Tooltip';

const useStyles = makeStyles(({palette}) => ({
  assigmentGroup: {
    margin: '8px 0px',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 8,
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '28px',
  },
  closeIcon: {
    borderRadius: 1,
    cursor: 'pointer',
    paddingLeft: 5,
    paddingBottom: 8,
    color: `${palette.gray['400']}`,
  },
  addButton: {
    width: 180,
  },
}));

const AssignmentGroup = () => {
  const classes = useStyles();
  const groupsData = useSelector(fetchGroupsData);
  const isAlertAutoAssignmentEnabled = useSelector(getAlertAutoAssignmentEnabled);
  const {
    input: {value, onChange},
  } = useField('autoAssignmentGroup');

  const [isOpen, SetIsOpen] = useState(!!value);

  return (
    <>
      {isAlertAutoAssignmentEnabled ? (
        <div className={classes.assigmentGroup}>
          {isOpen ? (
            <>
              <div className={classes.titleWrapper}>
                <div className={classes.title}>Assignment Group</div>
                <SmartTooltip placement="top" content="Remove Assignment Group">
                  <i
                    className={`icon icn-general16-closea ${classes.closeIcon}`}
                    role="presentation"
                    onClick={() => {
                      onChange(null);
                      SetIsOpen(false);
                    }}
                  />
                </SmartTooltip>
              </div>
              <span>Which group should manage triggers?</span>
              <FormDdlSelect
                button={
                  <BigDropdownButton
                    isPristine={!value}
                    placeholder="Select"
                    label={value && groupsData?.find((group) => group._id === value).name}
                    shortBlueStyle
                  />
                }
                placement="auto"
                options={groupsData.map((group) => {
                  return {label: group.name, value: group._id};
                })}
                isUseSearch
                optionComponent={<OptionComponentSimple />}
                onChange={(opt) => onChange(opt.value)}
                width={290}
                height={260}
                automationId="autoAssignmentGroup"
                footerComponent={
                  <FormDdlActionBar
                    isVisible
                    onClearAll={() => onChange(null)}
                    hideCount
                    selectedCount={1} // to always show only clear
                    clearButtonText="Clear"
                  />
                }
              />
            </>
          ) : (
            <div className={classes.addButton}>
              <Button
                colorSchema={COLORS.GHOST_LIGHT_BLUE}
                onClick={() => SetIsOpen(true)}
                text="Assignment Group"
                icon="icn-action16-plusa"
              />
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default AssignmentGroup;
