// @flow
import React from 'react';
import AlertListItem from './AlertListItem';
import './AlertGroupsList.module.scss';

const AlertGroupsList = ({
  triggers,
  isAlertsListScrolling,
  queryParams,
}: {
  triggers: Array<Object>,
  isAlertsListScrolling: Boolean,
  queryParams: Object,
}) => {
  return (
    <>
      {triggers?.length > 0 &&
        triggers.map((triggerGroup) => (
          <div key={triggerGroup.alertGroupId} styleName="grouped-alerts-panel" automation-id="alertConsolePanelItem">
            <div styleName="grouped-alerts" id="groupedAlerts">
              {triggerGroup.alerts.map((trigger, index) => (
                <AlertListItem
                  key={trigger.alertTriggerId}
                  id={`listItem_${index}`}
                  trigger={trigger}
                  alertGroupId={triggerGroup.alertGroupId}
                  index={index}
                  queryParams={queryParams}
                  isAlertsListScrolling={isAlertsListScrolling}
                />
              ))}
            </div>
          </div>
        ))}
    </>
  );
};

export default AlertGroupsList;
