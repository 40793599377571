import React, {useQueryClient, useIsFetching, useIsMutating} from 'react-query';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {QUERY_STATUSES} from 'reactQuery/reactQueryService';

export default function GlobalFetching() {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();
  const display = isFetching || isMutating;

  if (display) {
    return (
      <div className="display_flex alignItems_center justifyContent_center height_1vh width_1vw">
        <span>ReactQueryLoading..</span>
        <Spinner color="gray.500" size={SIZES.XX_BIG_100} />
      </div>
    );
  }
  return null;
}

export function getIsLoading(queryKey) {
  const queryClient = useQueryClient();

  return queryClient.getQueryState(queryKey).status === QUERY_STATUSES.loading;
}
