// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import PollingInterval from 'bc/components/streams/editor/common/PollingInterval';
import './SalesforceSchedularEditor.module.scss';
import SchedularDateRange from 'bc/components/streams/editor/common/SchedularDateRange';
import {getAllowedRangeTypesByPollingInterval} from 'bc/services/dataStreamService';

type PropTypes = {
  // connect
  dataStream: Object,
  setSelectedStreamKeyVal: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)(
  class SalesforceSchedularEditor extends React.PureComponent {
    props: PropTypes;

    pollingChanged = (val) => {
      this.props.setSelectedStreamKeyVal({pollingInterval: val});
    };

    timeZoneChanged = (obj) => {
      this.props.setSelectedStreamKeyVal(obj);
    };

    dateRangeChanged = (val) => {
      this.props.setSelectedStreamKeyVal({historicalDateRange: val});
    };

    render() {
      const {dataStream} = this.props;

      return (
        <div styleName="root">
          <PanelHeader title="Query Schedule" />
          <div styleName="inner-root">
            <div styleName="item">
              <span styleName="item-title">Query every</span>
              <PollingInterval
                pollingInterval={dataStream.pollingInterval}
                allowedValues={['daily', 'hourly']}
                styleName="item-width"
                onChange={(v) => this.pollingChanged(v)}
              />
            </div>
          </div>

          {dataStream.historicalDateRange && (
            <div styleName="inner-root">
              <div styleName="item">
                <span className="ellipsis" styleName="item-title">
                  Historical Time Span
                </span>
                <span styleName="item-description">Time span of records to query while initializing a stream.</span>
                <div styleName="item-width">
                  <SchedularDateRange
                    dateRange={dataStream.historicalDateRange}
                    title="Time span"
                    isUnix
                    allowedRangeTypes={getAllowedRangeTypesByPollingInterval(dataStream.pollingInterval)}
                    onChange={this.dateRangeChanged}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  },
);
