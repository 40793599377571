// @flow
import React, {PureComponent} from 'react';
import SmartTooltip from 'common/components/SmartTooltip';
import {objectiveOffsetOf} from './positioning';
import makeConceptTooltip from './makeConceptToolip';

import './ConceptButton.module.scss';

type PropTypes = {
  apiName: string,
  selected: boolean,
  disabled: boolean,
  children: Object,
  description: string,
  onAddItem: Function,
  onRemoveItem: Function,
};

class ConceptButton extends PureComponent {
  props: PropTypes;

  state = {
    placement: null,
  };

  componentDidMount() {
    const buttonEl = this.btn;
    const placement = objectiveOffsetOf(buttonEl).left < window.innerWidth / 2 ? 'right' : 'left';
    this.setState({placement});
  }

  onClick = (e) => {
    const {apiName, selected, disabled, onAddItem, onRemoveItem}: any = this.props;
    e.stopPropagation();
    if (!disabled) {
      if (selected) {
        onRemoveItem(apiName);
      } else {
        onAddItem(apiName);
      }
    }
  };

  render() {
    const {apiName, selected, disabled, children, description}: any = this.props;
    const {placement} = this.state;
    const {onClick} = this;

    let styleName = 'button-disabled';
    if (!disabled) {
      styleName = selected ? 'button-active' : 'button-inactive';
    }
    return (
      <SmartTooltip
        placement={placement}
        id={`tooltip-for-${apiName}`}
        content={makeConceptTooltip({
          id: apiName,
          description,
          disabled,
        })}
      >
        <button
          automation-id={`diametricsItem-${children}`}
          type="button"
          ref={(btn) => {
            this.btn = btn;
          }}
          onClick={onClick}
          styleName={styleName}
        >
          {children}
        </button>
      </SmartTooltip>
    );
  }
}

export default ConceptButton;
