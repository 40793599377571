import React, {createContext, useContext, useReducer} from 'react';
import PropTypes from 'prop-types';

import {editPageReducer, STAPES_INITIAL_STATE} from '../pages/eventStream/hooks/editPageReducer';

const StageStateContext = createContext(undefined);
function EventStreamProvider({children}) {
  const [editStreamPageReducerState, editStreamPageReducerDispatch] = useReducer(editPageReducer, STAPES_INITIAL_STATE);
  const value = {
    editStreamPageReducerState,
    editStreamPageReducerDispatch,
    currentStepIndex: editStreamPageReducerState.findIndex((step) => step.isCurrent),
    maxPages: editStreamPageReducerState.length,
  };

  return <StageStateContext.Provider value={value}>{children}</StageStateContext.Provider>;
}

function useEventStreamContext() {
  const context = useContext(StageStateContext);
  if (context === undefined) {
    throw new Error('useEventStreamContext must be used within a EventStreamProvider');
  }
  return context;
}

EventStreamProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {EventStreamProvider, useEventStreamContext};
