// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import * as filterSelector from 'alerts.management/store/filterSelector';

const EMPTY_ARRAY = [];

type PropTypes = {
  setQueryParams: Function,

  // connect
  sortedFlattenChannels?: Array,
  selectedRecipients?: any,
};

export default connect(
  (state) => ({
    sortedFlattenChannels: filterSelector.getFilterAlertRecipients(state),
    selectedRecipients: filterSelector.getSelectedFilterRecipients(state),
  }),
  {},
)(
  class RecipientsFilter extends React.PureComponent {
    props: PropTypes;

    static defaultProps = {
      sortedFlattenChannels: EMPTY_ARRAY,
      selectedRecipients: undefined,
    };

    onChange = (items) => {
      const subscribers = [];
      const channels = [];
      const labels = [];

      items.forEach((i) => {
        labels.push(i.label);
        if (i.type === 'USERS') {
          subscribers.push(i.value);
        } else {
          channels.push(i.value);
        }
      });

      this.props.setQueryParams({
        subscribers: subscribers.length ? subscribers.join(',') : undefined,
        channels: channels.length ? channels.join(',') : undefined,
      });
    };

    onClearAll = () => {
      const {sortedFlattenChannels, selectedRecipients, setQueryParams} = this.props;
      if (selectedRecipients?.length > 0) {
        setQueryParams({subscribers: undefined, channels: undefined});
      } else {
        const optionsSubscribers = sortedFlattenChannels
          .filter((item) => item.type === 'USERS')
          .map((item) => item.value)
          .join(',');
        const optionsChannels = sortedFlattenChannels
          .filter((item) => item.type !== 'USERS')
          .map((item) => item.value)
          .join(',');
        setQueryParams({subscribers: optionsSubscribers || undefined, channels: optionsChannels || undefined});
      }
    };

    render() {
      const {selectedRecipients, sortedFlattenChannels} = this.props;

      return (
        <UsersChannelsFilter
          onChange={this.onChange}
          sortedFlattenChannels={sortedFlattenChannels}
          value={selectedRecipients}
          footerComponent={
            <FormDdlActionBar
              isVisible
              selectedCount={selectedRecipients ? selectedRecipients.length : 0}
              onClearAll={this.onClearAll}
            />
          }
        />
      );
    }
  },
);
