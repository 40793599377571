// @flow
import React from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import './StaticListItem.module.scss';

type PropTypes = {
  handleClick: Function,
  children: Node,
  segmentClickEvent: Function,
};

export default connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)(
  class StaticListItem extends React.PureComponent {
    props: PropTypes;

    onClick = () => {
      this.props.segmentClickEvent({
        type: 'click',
        name: 'static list item',
      });
      this.props.handleClick();
    };

    render() {
      const {children} = this.props;
      return (
        <div role="button" onKeyPress={this.onClick} tabIndex={0} styleName="row" onClick={this.onClick}>
          {children}
          <div styleName="link">
            <div className="icon icn-nav16-goto" />
          </div>
        </div>
      );
    }
  },
);
