// @flow
import React from 'react';
import {get} from 'lodash';
import './AlertDetailsPanel.module.scss';
import LabelAndValueRow from 'common/componentsV2/LabelAndValueRow';
import connect from 'react-redux/es/connect/connect';
import * as channelsSelectors from 'alerts.channels/store/selectors';
import {getFetchDashboardsLoading} from 'dashboards/store/selectors';
import {fetchChannels as fetchChannelsAction} from 'alerts.channels/store/actions';
import {
  fetchDataStreamMetricsCount as fetchDataStreamMetricsCountAction,
  fetchInfluencingMetricsInfo as fetchInfluencingMetricsInfoAction,
  removeAlertTag,
} from 'alerts.management/store/actions';
import * as selectors from 'alerts.management/store/selectors';
import {getChannelsDynamicRoutingEnabled} from 'profile/store/selectors';
import Spinner, {SIZES as SPINNER_SIZES} from 'common/componentsV2/Spinner';
import TagList from 'common/componentsV2/TagList';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import Button, {COLORS} from 'common/componentsV2/Button';
import {segmentClickEvent} from 'common/store/actions';
import LabelAndValueCol from 'common/componentsV2/LabelAndValueCol';
import {palette} from 'app/styles/theme';
import {resolutionTypes} from 'metrics/services/metricsService';
import * as assetsSelectors from 'assets/store/selectors';
import {getDynamicRoutingFiles} from 'assets/store/actions';
import {downloadFile} from 'assets/services/api';
import {
  fetchDashboards as fetchDashboardsAction,
  fetchDashboardsV1 as fetchDashboardsV1Action,
} from 'dashboards/store/actions';
import RecipientItem, {TYPES} from './RecipientItem';

type PropTypes = {
  alert: Object,
  // connect
  fetchChannels: Function,
  removeAlertTag: Function,
  isChannelsLoading: boolean,
  recipients: Array,
  dashboardLink: Object,
  segmentClickEvent: Function,
  fetchDataStreamMetricsCount: Function,
  streamMetricCount: Number,
  streamMetricsCountIsLoading: boolean,
  fetchInfluencingMetricsInfo: Function,
  fetchDynamicRoutingFiles: Function,
  fetchDashboards: Function,
  fetchDashboardsV1: Function,
  influencingMetricsWhat: String,
  influencingMetricsIsLoading: boolean,
  isChannelsDynamicRoutingEnabled: boolean,
  dynamicRoutingFilesIsLoading: boolean,
  dynamicRoutingFilesData: Array,
  dashboardsAreLoading: boolean,
};

export default connect(
  (state) => ({
    dashboardsAreLoading: getFetchDashboardsLoading(state),
    recipients: selectors.getRecipients(state),
    isChannelsLoading: channelsSelectors.getChannelsLoading(state),
    dashboardLink: selectors.getDashboardLinkForCurrentOpenAlert(state),
    isFetchDashboardLoading: selectors.getFetchDashboardIsLoading(state),
    streamMetricCount: selectors.getStreamMetricCountCurrentAlert(state),
    streamMetricsCountIsLoading: selectors.streamMetricsCountIsLoading(state),
    influencingMetricsWhat: selectors.getInfluencingMetricsWhatCurrentAlert(state),
    influencingMetricsIsLoading: selectors.getInfluencingMetricsIsLoading(state),
    isChannelsDynamicRoutingEnabled: getChannelsDynamicRoutingEnabled(state),
    dynamicRoutingFilesData: assetsSelectors.getDynamicRoutingFilesData(state),
    dynamicRoutingFilesIsLoading: assetsSelectors.getDynamicRoutingFilesIsLoading(state),
  }),
  {
    fetchChannels: fetchChannelsAction,
    removeAlertTag,
    segmentClickEvent,
    fetchDataStreamMetricsCount: fetchDataStreamMetricsCountAction,
    fetchInfluencingMetricsInfo: fetchInfluencingMetricsInfoAction,
    fetchDynamicRoutingFiles: getDynamicRoutingFiles,
    fetchDashboards: fetchDashboardsAction,
    fetchDashboardsV1: fetchDashboardsV1Action,
  },
)(
  class AlertDetailsPanel extends React.PureComponent {
    props: PropTypes;

    state = {
      isTagModalOpen: false,
      currentModal: '',
      isShowAllButton: false,
    };

    componentDidMount() {
      const {
        alert,
        recipients,
        fetchChannels,
        fetchDataStreamMetricsCount,
        fetchInfluencingMetricsInfo,
        fetchDynamicRoutingFiles,
        streamMetricCount,
        influencingMetricsWhat,
        isChannelsDynamicRoutingEnabled,
        fetchDashboards,
        fetchDashboardsV1,
        dashboardLink,
      } = this.props;

      fetchChannels();
      this.setState({isShowAllButton: recipients.length > 4});

      if (!dashboardLink) {
        fetchDashboards();
        fetchDashboardsV1();
      }

      if (!streamMetricCount) {
        fetchDataStreamMetricsCount();
      }
      if (!influencingMetricsWhat && alert.details.influencingTreeModels && alert.details.expressionTreeModel) {
        alert.details.influencingTreeModels.forEach((influencingTreeModel) => {
          fetchInfluencingMetricsInfo(
            {
              influencingMetricsTreeModel: influencingTreeModel,
              alertTreeModel: alert.details.expressionTreeModel,
            },
            influencingTreeModel.id,
          );
        });
      }
      if (isChannelsDynamicRoutingEnabled && alert.alertConfiguration.dynamicRouting) {
        fetchDynamicRoutingFiles();
      }
    }

    findChannelsDynamicRouting = () => {
      const files = this.props.dynamicRoutingFilesData;
      if (Array.isArray(files) && files.length) {
        return files.find((i) => i.id === this.props.alert.alertConfiguration.dynamicRouting.metaId);
      }
      return undefined;
    };

    onFileDownloadClick = () => {
      const file = this.findChannelsDynamicRouting();
      if (file) {
        downloadFile(file.id).promise.then((data) => {
          const byteArray = new Uint8Array(data);
          const a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/octet-stream'}));
          a.download = file.title;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      }
    };

    onTagClick = () => {
      this.props.segmentClickEvent({category: 'alert-manager', name: 'openalert-detailes-label_seeall'});
      this.setState({
        isTagModalOpen: true,
        currentModal: 'tags',
      });
    };

    onRecipientsClick = () => {
      this.props.segmentClickEvent({category: 'alert-manager', name: 'openalert-detailes-rec_seeall'});
      this.setState({
        isTagModalOpen: true,
        currentModal: 'recipients',
      });
    };

    onDescriptionClick = () => {
      this.setState({
        isTagModalOpen: true,
        currentModal: 'description',
      });
    };

    onModalClose = () => {
      this.setState({isTagModalOpen: false});
    };

    onTagDismissClick = (tag) => {
      this.props.removeAlertTag(tag);
    };

    renderModal = () => {
      const {alert, recipients} = this.props;

      switch (this.state.currentModal) {
        case 'tags':
          return (
            <div styleName="tags-modal">
              <TagList
                tags={alert.details.tags}
                isAllList
                isDismissible={alert.authorization.delete}
                onDismiss={this.onTagDismissClick}
              />
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Done"
                onClick={() => {
                  this.props.segmentClickEvent({
                    category: 'alert-manager',
                    name: 'openalert-details-label_seeall-done',
                  });
                  this.setState({isTagModalOpen: false});
                }}
              />
            </div>
          );
        case 'recipients':
          return (
            <div styleName="recipients-modal">
              <div styleName="top-block">
                <div styleName="modal-header">
                  <h3>{`Recipients (${recipients.length})`}</h3>
                </div>
                <div styleName="recipients-container">
                  {recipients.map((rec) => (
                    <RecipientItem key={rec.id} name={rec.name} type={rec.type} />
                  ))}
                </div>
              </div>
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Done"
                onClick={() => {
                  this.props.segmentClickEvent({
                    category: 'alert-manager',
                    name: 'openalert-details-rec_seeall-done',
                  });
                  this.setState({isTagModalOpen: false});
                }}
              />
            </div>
          );
        case 'description':
          return (
            <div styleName="recipients-modal">
              <div>
                <h3 styleName="modal-header">Description</h3>
                <p>{alert.details.description}</p>
              </div>
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Done"
                onClick={() => {
                  this.props.segmentClickEvent({
                    category: 'alert-manager',
                    name: 'openalert-details-rec_seeall-done',
                  });
                  this.setState({isTagModalOpen: false});
                }}
              />
            </div>
          );
        default:
          return null;
      }
    };

    render() {
      const {
        alert,
        isChannelsLoading,
        recipients,
        dashboardLink,
        streamMetricsCountIsLoading,
        influencingMetricsWhat,
        influencingMetricsIsLoading,
        isChannelsDynamicRoutingEnabled,
        dynamicRoutingFilesIsLoading,
        dashboardsAreLoading,
      } = this.props;

      const {numOfMetricsMin} = alert.details.alertSummaryConfiguration;
      const {numOfMetricsMax} = alert.details.alertSummaryConfiguration;
      const influencingMetricsFormatted = influencingMetricsWhat && influencingMetricsWhat !== 'NA' ? 'Yes' : 'No';
      const deltaDuration = alert.alertConfiguration?.conditions?.delta?.deltaDuration;
      const getRollupBySecond = () => {
        return resolutionTypes[deltaDuration.rollup].ms / 1000;
      };

      return (
        <>
          <Modal size={SIZES.LARGE} isOpen={this.state.isTagModalOpen} onClose={this.onModalClose}>
            {this.renderModal()}
          </Modal>
          <div styleName="container">
            <div styleName="column column-1">
              <div styleName="row">
                <LabelAndValueRow title="Type">{alert.details.type}</LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Included Metrics">
                  {streamMetricsCountIsLoading ? (
                    <Spinner color={palette.gray[500]} size={22} />
                  ) : (
                    this.props.streamMetricCount
                  )}
                </LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Timescale">{alert.alertConfiguration.timeScaleUI}</LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Min. Duration">
                  {alert.details.alertSummaryConfiguration.duration}
                </LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Direction">
                  {alert.details.alertSummaryConfiguration.anomalyDirection}
                </LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Significance Score">{alert.details.minSignificanceScore}</LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Min. Delta (Auto)">{alert.details.minDelta}</LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Delta Duration">
                  {deltaDuration?.enabled && alert.alertConfiguration?.conditions?.duration > getRollupBySecond()
                    ? alert.details.alertSummaryConfiguration.deltaDuration
                    : ''}
                </LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Ignore Low Vol (Auto)">
                  {get(alert, 'details.alertSummaryConfiguration.volumeCondition.enabled') ? 'Yes' : 'No'}
                </LabelAndValueRow>
              </div>
            </div>
            <div styleName="margin" />
            <div styleName="column column-2">
              <div styleName="row">
                <LabelAndValueRow title="Correlate with Events">{alert.details.isCorrelateWithEvents}</LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow isBreakLine title="Num. Of Metrics">
                  {numOfMetricsMin ? <p>{numOfMetricsMin}</p> : null}
                  {numOfMetricsMax ? <p>{numOfMetricsMax}</p> : null}
                </LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Min. Threshold">
                  {alert.details.alertSummaryConfiguration.minThreshold}
                </LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Influencing Metrics">
                  {influencingMetricsIsLoading ? (
                    <Spinner color={palette.gray[500]} size={22} />
                  ) : (
                    influencingMetricsFormatted
                  )}
                </LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Alert when Anomaly">{alert.details.sendAlertWhen}</LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Alert on missing data">
                  {alert.details.alertOnMissingData}
                  {alert.details.alertOnMissingData === 'Yes'
                    ? `, ${alert.details.alertSummaryConfiguration.noDataDuration}`
                    : null}
                </LabelAndValueRow>
              </div>
              {alert.details.connectedTiles ? (
                <div styleName="row">
                  <LabelAndValueRow title="Dashboard">
                    {dashboardsAreLoading ? (
                      <Spinner color={palette.gray[500]} size={SPINNER_SIZES.SMALL_30} />
                    ) : (
                      dashboardLink && (
                        <div styleName="dashboard-link">
                          <a href={dashboardLink.url}>{dashboardLink.text}</a>
                        </div>
                      )
                    )}
                  </LabelAndValueRow>
                </div>
              ) : (
                <LabelAndValueRow title="No connected dashboard" />
              )}
            </div>
            <div styleName="margin" />
            <div styleName="column column-3">
              <div styleName="row">
                <LabelAndValueRow title="Owner">{alert.owner}</LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Date Created">{alert.dateCreated}</LabelAndValueRow>
              </div>
              <div styleName="row">
                <LabelAndValueRow title="Modified by" isBreakLine>
                  {alert.modifiedBy} {alert.dateModified}
                </LabelAndValueRow>
              </div>
              {alert.alertConfiguration.autoAssignmentGroup && (
                <div styleName="row">
                  <LabelAndValueRow title="Assigment Group">
                    <RecipientItem name={alert.alertConfiguration.autoAssignmentGroup} type={TYPES.USER} />
                  </LabelAndValueRow>
                </div>
              )}
              <div styleName="row">
                <LabelAndValueCol
                  isSmallTitle
                  title={`Recipients (${isChannelsLoading ? '-' : recipients.length})`}
                  additionalTitle={
                    this.state.isShowAllButton ? (
                      <button type="button" styleName="recipient-button" onClick={this.onRecipientsClick}>
                        See all
                      </button>
                    ) : null
                  }
                >
                  {isChannelsLoading ? (
                    <Spinner color={palette.gray[500]} size={SPINNER_SIZES.SMALL_30} />
                  ) : (
                    <>
                      {recipients.map((rec, index) => {
                        if (index <= 3) {
                          return <RecipientItem key={rec.id} name={rec.name} type={rec.type} />;
                        }
                        return null;
                      })}
                    </>
                  )}
                </LabelAndValueCol>
              </div>
              {isChannelsDynamicRoutingEnabled && (
                <>
                  <div styleName="row">
                    <LabelAndValueRow title="Dynamic Routing">
                      {alert.alertConfiguration.dynamicRouting ? 'Yes' : 'No'}
                    </LabelAndValueRow>
                  </div>
                  {dynamicRoutingFilesIsLoading && <Spinner color={palette.gray[500]} size={SPINNER_SIZES.SMALL_30} />}
                  {alert.alertConfiguration.dynamicRouting && !dynamicRoutingFilesIsLoading && (
                    <>
                      <div styleName="row">
                        <LabelAndValueRow title="Route alert by:">
                          {Array.isArray(alert.alertConfiguration.dynamicRouting?.dimensions)
                            ? alert.alertConfiguration.dynamicRouting?.dimensions[0]?.name
                            : ''}
                        </LabelAndValueRow>
                      </div>
                      <div styleName="row">
                        <LabelAndValueRow title="From:" isValueLink onValueLinkClick={this.onFileDownloadClick}>
                          {this.findChannelsDynamicRouting()?.title || ''}
                        </LabelAndValueRow>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      );
    }
  },
);
