import React, {useEffect, useRef} from 'react';
import Highcharts from 'highcharts';
import {getHchartConfig} from './pieChartSettingsService';

type PropTypes = {
  id: String,
  width: Number,
  height: Number,
  data: Array<Object>,
  tooltipFormatter: Object,
  labels: boolean,
  isResize: boolean,
  isSelectable: boolean,
  animation: boolean,
  isDonut: boolean,
  hoverEnabled: boolean,
  theme: Object,
  onClickEvent: Function,
  onMouseOverEvent: Function,
  onMouseOutEvent: Function,
  getHighChartRef: Function,
};

const PieChart = (props: PropTypes) => {
  const hchart = useRef(null);
  const chartContainerDomElm = useRef(null);
  const {
    id,
    width,
    height,
    data,
    onClickEvent,
    onMouseOverEvent,
    onMouseOutEvent,
    labels,
    theme,
    tooltipFormatter,
    getHighChartRef,
    isSelectable,
    animation,
    isDonut,
    hoverEnabled,
    isResize,
  } = props;

  useEffect(() => {
    hchart.current = Highcharts.chart(
      id,
      getHchartConfig(
        data,
        onClickEvent,
        labels,
        theme,
        tooltipFormatter,
        isSelectable,
        animation,
        isDonut,
        hoverEnabled,
        onMouseOverEvent,
        onMouseOutEvent,
      ),
    );
    if (getHighChartRef) {
      getHighChartRef(hchart.current);
    }
    return () => {
      if (hchart.current) {
        hchart.current.destroy();
        if (getHighChartRef) {
          getHighChartRef(null);
        }
      }
    };
  }, []);

  useEffect(() => {
    hchart.current.setSize(chartContainerDomElm.current.clientWidth, chartContainerDomElm.current.clientHeight);
  }, [isResize]);

  return (
    <div
      ref={chartContainerDomElm}
      id={id}
      style={{
        minWidth: width,
        maxWidth: width,
        height,
        margin: '0',
      }}
    >
      Chart is here
    </div>
  );
};

export default PieChart;
