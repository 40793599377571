// @flow
import React from 'react';
import {connect} from 'react-redux';
import {omitBy} from 'lodash';
import {isAnodotAdmin as isAnodotAdminSelector} from 'profile/store/selectors';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import Modal, {SIZES as MODAL_SIZES} from 'common/componentsV2/modal/Modal';
import Button, {COLORS, HEIGHTS} from 'common/componentsV2/Button';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import * as actions from '../store/actions';
import * as selectors from '../store/selectors';

type PropTypes = {
  // connect
  customersData: Object,
  globalsView: Object,
  isAnodotAdmin: Boolean,
  fetchCustomers: Function,
  updateCustomersAndGlobal: Function,
  customersDifference: Array,
  globalsDifference: Array,
  updateCustomersAndGlobalIsLoading: Boolean,
  customersAndGlobalIsLoading: Boolean,
  defaultsIsLoading: Boolean,
};

export default connect(
  (state) => ({
    defaults: selectors.getDefaults(state),
    updatedCustomersAndGlobal: selectors.getUpdateCustomersAndGlobal(state),
    defaultsIsLoading: selectors.getDefaultsIsLoading(state),
    customersAndGlobalIsLoading: selectors.getCustomersAndGlobalIsLoading(state),
    isAnodotAdmin: isAnodotAdminSelector(state),
    globalsView: selectors.getMergedGlobalUI(state),
    customersDifference: selectors.getCustomersDifference(state),
    globalsDifference: selectors.getGlobalsDifference(state),
    customersData: selectors.getMergedCustomersOrigin(state),
    updateCustomersAndGlobalIsLoading: selectors.getUpdateCustomersAndGlobalIsLoading(state),
  }),
  {
    fetchCustomers: actions.fetchCustomers,
    updateCustomersAndGlobal: actions.updateCustomersAndGlobal,
  },
)(
  class Header extends React.PureComponent {
    props: PropTypes;

    state = {
      isModalOpen: false,
    };

    updateCustomersAndGlobal = () => {
      const {globalsView, updateCustomersAndGlobal, customersDifference, globalsDifference} = this.props;

      const params = {
        customers: null,
        global: null,
      };

      if (globalsDifference.length) {
        const obj = {};
        params.global = globalsView.forEach((i) => {
          obj[i.name] = i.globalDefault;
        });
        params.global = obj;
      }

      if (customersDifference.length) {
        params.customers = customersDifference;
      }

      updateCustomersAndGlobal(params);
      this.setState({isModalOpen: false});
    };

    customersDifference = () => {
      const customers = [];
      if (this.state.isModalOpen) {
        const {customersDifference, customersData} = this.props;
        customersDifference.forEach((i) =>
          customersData.forEach((j) => {
            if (i.id === j.id) {
              customers.push({
                customerId: i.id,
                customerName: i.name,
                diffFeatures: omitBy(i.ff, (v, k) => j.ff[k] === v),
              });
            }
          }),
        );
      }
      return customers;
    };

    globalDifference = () => {
      const globals = [];
      if (this.state.isModalOpen) {
        const {globalsDifference} = this.props;

        globalsDifference.forEach((i) => {
          globals.push({name: i.name, value: i.globalDefault});
        });
      }
      return globals;
    };

    render() {
      const {
        defaultsIsLoading,
        customersAndGlobalIsLoading,
        updateCustomersAndGlobalIsLoading,
        isAnodotAdmin,
        fetchCustomers,
        customersDifference,
        globalsDifference,
      } = this.props;
      const isLoading = defaultsIsLoading || customersAndGlobalIsLoading || updateCustomersAndGlobalIsLoading;
      const customersDiff = this.customersDifference();
      const globalDiff = this.globalDifference();
      const isDirty = globalsDifference.length || customersDifference.length;

      return (
        <header
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 34,
          }}
        >
          <Title type={TITLE_TYPES.PAGE_TITLE}>Feature Flags</Title>

          <div>
            {isAnodotAdmin && (
              <div style={{display: 'flex', justifyContent: 'flex-end', padding: '12px 0 0'}}>
                <div style={{marginRight: 12}}>
                  <Button
                    text="Reset"
                    onClick={fetchCustomers}
                    isDisabled={isLoading}
                    colorSchema={COLORS.GRAY_400}
                    height={HEIGHTS.REGULAR}
                  />
                </div>
                <Button
                  height={HEIGHTS.REGULAR}
                  colorSchema={COLORS.BLUE_500}
                  isDisabled={isLoading || !isDirty}
                  text="Save"
                  style={{marginLeft: '17px'}}
                  onClick={() => this.setState({isModalOpen: true})}
                />
              </div>
            )}
            <Modal
              isOpen={this.state.isModalOpen}
              size={MODAL_SIZES.LARGE}
              isStatic
              onClose={() => this.setState({isModalOpen: false})}
              isCloseButtonHidden
            >
              <div>You are going to change the following:</div>
              <TinyScrollBox
                width="100%"
                maxHeight={575}
                height={1}
                pb={1.5}
                pt={1.5}
                css={{overflowY: 'auto', overflowX: 'hidden'}}
              >
                <div>
                  {!!customersDiff.length && (
                    <div>
                      <p style={{fontSize: 16}}>Customers</p>
                      <ul>
                        {customersDiff.map((i) => (
                          <li key={i.customerId}>
                            <div>{i.customerName}</div>
                            <ul>
                              {Object.keys(i.diffFeatures).map((j) => (
                                <li key={j}>
                                  <div>
                                    {j}:<b> {i.diffFeatures[j].toString()}</b>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {!!globalDiff.length && (
                    <div>
                      <p style={{fontSize: 16}}>Globals</p>
                      <ul>
                        {globalDiff.map((i) => (
                          <li key={i.name}>
                            <div>
                              {i.name}: <b>{i.value.toString()}</b>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </TinyScrollBox>
              <div style={{display: 'flex', justifyContent: 'flex-end', padding: '12px 0 0'}}>
                <div style={{marginRight: 12}}>
                  <Button
                    colorSchema={COLORS.GRAY_400}
                    height={HEIGHTS.REGULAR}
                    text="Cancel"
                    onClick={() => this.setState({isModalOpen: false})}
                  />
                </div>
                <Button
                  colorSchema={COLORS.BLUE_500}
                  height={HEIGHTS.REGULAR}
                  text={updateCustomersAndGlobalIsLoading ? 'Saving...' : 'Save'}
                  onClick={this.updateCustomersAndGlobal}
                />
              </div>
            </Modal>
          </div>
        </header>
      );
    }
  },
);
