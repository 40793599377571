import {combineReducers} from 'redux';
import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from 'admin.activityLog/store/actions';

const fetchAuditLog = (
  state = {
    isLoading: false,
    data: {entries: []},
    isLoadingTotalCount: false,
    totalCount: 0,
    isLoadingNextPage: false,
  },
  {type, payload, meta},
) => {
  switch (type) {
    case actions.fetchAuditLog.TYPE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.fetchAuditLog.success.TYPE:
      return {
        ...state,
        isLoading: false,
        data: payload,
        totalCount: !meta.isFiltersApply ? payload.totalMatches : state.totalCount,
      };
    case actions.fetchNextPageAuditLog.TYPE:
      return {
        ...state,
        isLoadingNextPage: true,
      };
    case actions.fetchNextPageAuditLog.success.TYPE:
      return {
        ...state,
        data: {
          ...state.data,
          entries: [...state.data.entries, ...payload.entries],
          nextPage: payload.nextPage,
        },
        isLoadingNextPage: false,
      };
    case actions.fetchTotalCount.TYPE:
      return {
        ...state,
        isLoadingTotalCount: true,
      };
    case actions.fetchTotalCount.success.TYPE:
      return {
        ...state,
        totalCount: payload.totalMatches,
        isLoadingTotalCount: false,
      };
    default:
      return state;
  }
};

const fetchListEntityId = composeReducers(
  makeAsyncReducer(actions.fetchListEntityId),
  (state = {}, {type, payload}) => {
    switch (type) {
      case actions.fetchListEntityId.success.TYPE:
      case actions.setListEntityId.TYPE:
        return {
          ...state,
          data: payload.entries,
        };
      default:
        return state;
    }
  },
);

export default combineReducers({
  fetchAuditLog,
  fetchListEntityId,
});
