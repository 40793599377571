import React, {useEffect, useMemo, useRef, useState} from 'react';
import ErrorBoundary from 'errorBoundaries/ErrorBoundary';
import ReactResizeDetector from 'react-resize-detector';
import {AutoSizer, List} from 'react-virtualized';
import {RECOMMENDATIONS_COLUMN_HEADERS, RECOMMENDATIONS_ORDER} from 'recommendations/services/consts';
import {useRecommendationsContext} from 'recommendations/hooks/useRecommendationsContext';
import PropTypes from 'prop-types';
import {STAGES_ACTIONS} from 'recommendations/hooks/useRecommendationsLocalReducer';
import RecommendationsTableHeader from 'recommendations/components/recommendationsList/RecommendationsTableHeader';
import RecommendationItem from 'recommendations/components/recommendationsList/RecommendationItem';

const RecommendationsList = ({
  filteredRecommendations,
  orderFilteredList,
}: {
  filteredRecommendations: Array,
  orderFilteredList: Function,
}) => {
  const listRef = useRef();
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const {editState, editDispatch} = useRecommendationsContext();
  const [order, setOrder] = useState({column: 'title', direction: RECOMMENDATIONS_ORDER.DESC});

  const changedIndex = useMemo(() => {
    if (Array.isArray(filteredRecommendations)) {
      return filteredRecommendations.findIndex((recommendation) => recommendation.id === editState.selectedItemId);
    }
    return undefined;
  }, [filteredRecommendations, editState.selectedItemId]);

  const rowRenderer = ({index, style}: PropTypes) => {
    const item = filteredRecommendations?.[index];
    return (
      <RecommendationItem
        key={item.id}
        item={item}
        style={style}
        isLast={index === filteredRecommendations?.length - 1}
      />
    );
  };

  const getSortRecommendationsByTime = (direction, property) => {
    if (Array.isArray(filteredRecommendations)) {
      if (direction === RECOMMENDATIONS_ORDER.DESC) {
        return filteredRecommendations?.sort((a, b) => (a[property] < b[property] ? 1 : -1));
      }
      return filteredRecommendations?.sort((a, b) => (a[property] > b[property] ? 1 : -1));
    }
    return [];
  };

  const recommendationsListOrder = (orderByColumn) => {
    if (!orderByColumn?.column) {
      return;
    }
    switch (orderByColumn.column) {
      case RECOMMENDATIONS_COLUMN_HEADERS.LAST_UPDATED.name:
        orderFilteredList(
          getSortRecommendationsByTime(
            orderByColumn.direction,
            RECOMMENDATIONS_COLUMN_HEADERS.LAST_UPDATED.propertyName,
          ),
        );
        break;
      case RECOMMENDATIONS_COLUMN_HEADERS.DATE_GIVEN.name:
      default:
        orderFilteredList(
          getSortRecommendationsByTime(orderByColumn.direction, RECOMMENDATIONS_COLUMN_HEADERS.DATE_GIVEN.propertyName),
        );
        break;
    }

    editDispatch({type: STAGES_ACTIONS.SET_SELECTED_ITEM_ID, payload: editState.selectedItemId});
    setOrder(orderByColumn);
  };

  const rowHeight = ({index}) => {
    if (index !== changedIndex) {
      return 50;
    }
    const recommendationsHeight = 50 + (filteredRecommendations?.[index]?.recommendations?.length + 1) * 50;
    const falsePositivesHeight = 280;
    return Math.max(recommendationsHeight, falsePositivesHeight);
  };

  const recalculateScrollbarWidth = () => {
    const elements = document.getElementsByClassName('ReactVirtualized__List');
    let scrollWidth = 0;
    if (elements?.length > 0) {
      const ele = elements[0];
      scrollWidth = ele.offsetWidth - ele.clientWidth;
    }
    setScrollBarWidth(scrollWidth);
  };

  useEffect(() => {
    if (!filteredRecommendations?.length) {
      return;
    }
    recalculateScrollbarWidth();
    if (listRef?.current) {
      listRef.current.recomputeRowHeights(0);
    }
  });

  useEffect(() => {
    recommendationsListOrder(order);
  }, [filteredRecommendations, order]);

  return (
    filteredRecommendations?.length >= 0 && (
      <ErrorBoundary>
        <ReactResizeDetector handleHeight handleWidth onResize={recalculateScrollbarWidth} />
        <RecommendationsTableHeader
          offset={scrollBarWidth}
          onCheck={() => null}
          order={order}
          setRecommendationsListOrder={recommendationsListOrder}
        />
        <AutoSizer>
          {({height, width}) => {
            return (
              <List
                ref={listRef}
                width={width}
                height={height}
                rowCount={filteredRecommendations?.length}
                rowHeight={rowHeight}
                rowRenderer={rowRenderer}
              />
            );
          }}
        </AutoSizer>
      </ErrorBoundary>
    )
  );
};

export default RecommendationsList;
