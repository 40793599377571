import React, {Fragment, useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {isEqual} from 'lodash';

import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {
  getAccessListAllSourcesData,
  getAccessListAllSourcesIsLoading,
  getSetAccessListForSourceIsLoading,
  getAccessListAllSourcesIsError,
  getGroups,
} from 'admin.permissions/store/selectors';
import {fetchGroupsIsLoading} from 'admin.users/store/selectors';
import * as actions from 'admin.permissions/store/actions';
import {types, ACCESS_LIST_OPTIONS} from 'admin.permissions/services/accessListService';
import RadioButtonsList from 'common/componentsV2/RadioButtonsList';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import BaloonMessage from 'common/componentsV2/BaloonMessage';

const AccessListSection = ({streamId, isStremRunning}: PropTypes) => {
  const dispatch = useDispatch();
  const allAccessList = useSelector(getAccessListAllSourcesData);
  const accessListIsLoading = useSelector(getAccessListAllSourcesIsLoading);
  const accessListIsError = useSelector(getAccessListAllSourcesIsError);
  const setAccessListForSourceIsLoading = useSelector(getSetAccessListForSourceIsLoading);

  const groups = useSelector(getGroups);
  const groupsIsLoading = useSelector(fetchGroupsIsLoading);

  const firstUpdate = useRef(true);

  const [selectedGroups, setSelectedGroups] = useState([]); // DropDown
  const [accessListOption, setAccessListOption] = useState({}); // Radio Btns selected option
  const [accessListOptions, setAccesListOptions] = useState([]); // Radio Btns ptions
  const [displaySelectedGroups, setDisplaySelectedGroups] = useState(false);

  const updateServer = (payload) => {
    dispatch(
      actions.setAccesslistForSource(
        {
          streamId,
          groupsIds: payload,
        },
        {type: types.stream, successMsg: false},
      ),
    );
  };

  const syncWithServer = () => {
    const accessList = allAccessList[streamId];

    if (accessList[0] === ACCESS_LIST_OPTIONS.EVERYONE.payload) {
      setAccessListOption(ACCESS_LIST_OPTIONS.EVERYONE);
      setSelectedGroups([]);
      return;
    }
    if (accessList[0] === ACCESS_LIST_OPTIONS.PRIVATE.payload) {
      setAccessListOption(ACCESS_LIST_OPTIONS.PRIVATE);
      setSelectedGroups([]);
      return;
    }
    setAccessListOption(ACCESS_LIST_OPTIONS.SELECTED_GROUPS);
    setSelectedGroups(groups.filter((i) => accessList.find((j) => i.value === j)));
  };

  const isNeedToUpdateSelectedGroups = () => {
    const accessList = allAccessList[streamId];

    if (accessList[0] === ACCESS_LIST_OPTIONS.PRIVATE.payload && !selectedGroups.length) {
      return false;
    }
    return !isEqual(accessList, selectedGroups.map((i) => i.value));
  };

  const onSelectGroups = () => {
    const isNeedUpdating = isNeedToUpdateSelectedGroups();
    if (isNeedUpdating && selectedGroups && selectedGroups.length) {
      return updateServer(selectedGroups.map((i) => i.value));
    }
    if (isNeedUpdating && selectedGroups && !selectedGroups.length) {
      return updateServer([ACCESS_LIST_OPTIONS.PRIVATE.payload]);
    }
    return null;
  };

  const onSelectOptions = (selectedOption) => {
    if (selectedOption.value === ACCESS_LIST_OPTIONS.EVERYONE.value) {
      updateServer([ACCESS_LIST_OPTIONS.EVERYONE.payload]);
    }
    if (selectedOption.value === ACCESS_LIST_OPTIONS.PRIVATE.value) {
      updateServer([ACCESS_LIST_OPTIONS.PRIVATE.payload]);
    }
    if (selectedOption.value === ACCESS_LIST_OPTIONS.SELECTED_GROUPS.value) {
      setAccessListOption(ACCESS_LIST_OPTIONS.SELECTED_GROUPS);
    }
  };

  useEffect(() => {
    dispatch(actions.fetchAccessListForAllSources({}, {type: types.stream}));
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
  }, []);

  useEffect(() => {
    if (accessListIsLoading === false && groupsIsLoading === false && !accessListIsError) {
      const accessList = allAccessList[streamId];
      // if it's the first time we force accessList for everyone ([*])
      if (!accessList) {
        updateServer([ACCESS_LIST_OPTIONS.EVERYONE.payload]);
      } else {
        syncWithServer();
      }
    }
  }, [accessListIsLoading, groupsIsLoading]);

  useEffect(() => {
    if (accessListOption.value === ACCESS_LIST_OPTIONS.SELECTED_GROUPS.value) {
      setDisplaySelectedGroups(true);
    } else {
      setDisplaySelectedGroups(false);
    }
  }, [accessListOption]);

  useEffect(() => {
    setAccesListOptions(
      Object.keys(ACCESS_LIST_OPTIONS).map((key) => ({
        value: ACCESS_LIST_OPTIONS[key].value,
        label: ACCESS_LIST_OPTIONS[key].title,
        info: ACCESS_LIST_OPTIONS[key].info,
        disabled: accessListIsLoading || groupsIsLoading || setAccessListForSourceIsLoading,
      })),
    );
  }, [accessListIsLoading, groupsIsLoading, setAccessListForSourceIsLoading]);

  return (
    <Fragment>
      {isStremRunning && (
        <div style={{marginTop: 32, width: '100%'}}>
          <BaloonMessage width="100%" title="Editing Access Groups">
            <div>To edit access group go to the “Access” tab in the stream summary </div>
          </BaloonMessage>
        </div>
      )}

      {!isStremRunning && (
        <Fragment>
          <PanelHeader title="Access settings" isEditable />
          <RadioButtonsList
            options={accessListOptions}
            value={accessListOption.value}
            onChange={onSelectOptions}
            direction="column"
          />

          {displaySelectedGroups && (
            <Fragment>
              <span>Who can access</span>
              <SelectAndt
                onMenuClose={onSelectGroups}
                noAutoReorder
                type={TYPE_NEW_MULTI}
                theme={THEME_NOT_HIGHLIGHTED}
                isClearable={false}
                closeMenuOnSelect={false}
                onChange={setSelectedGroups}
                options={groups}
                buttonWidth={225}
                optionHeight={40}
                isMulti
                value={selectedGroups}
                placeholder={accessListIsLoading || groupsIsLoading ? 'Loading..' : 'None'}
                automationId="EditAccessListCreatedStreamFromCreateStreamPage"
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

AccessListSection.propTypes = {
  streamId: PropTypes.string.isRequired,
  isStremRunning: PropTypes.bool.isRequired,
};

export default AccessListSection;
