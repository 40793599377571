// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import UsersChannelsFilter from 'alerts.channels/components/UsersChannelsFilter';
import * as filterSelector from 'alerts.management/store/filterSelector';
import {segmentClickEvent} from 'common/store/actions';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';

type PropTypes = {
  setQueryParams: Function,

  // connect
  ownerList: Array,
  selectedOwners: Array,
  segmentClickEvent: Function,
};

export default connect(
  (state) => ({
    ownerList: filterSelector.getFilterOwnerList(state),
    selectedOwners: filterSelector.getSelectedFilterOwner(state),
  }),
  {
    segmentClickEvent,
  },
)(
  class OwnerFilter extends React.PureComponent {
    props: PropTypes;

    onChange = (items) => {
      const ownerVal = items.length ? items.map((item) => item.value).join(',') : undefined;
      this.props.segmentClickEvent({category: 'alert-manager', name: `filter-owner-${ownerVal}`});
      this.props.setQueryParams({owner: ownerVal});
    };

    onClearAll = () => {
      const {ownerList, selectedOwners, setQueryParams} = this.props;
      this.props.segmentClickEvent({
        category: 'alert-manager',
        name: 'filter-owner-clear-all',
      });
      if (selectedOwners && selectedOwners.length > 0) {
        setQueryParams({owner: undefined});
      } else {
        const optionCurrent = ownerList.map((i) => i.value).join(',');
        setQueryParams({owner: optionCurrent});
      }
    };

    render() {
      const {selectedOwners, ownerList} = this.props;

      return (
        <UsersChannelsFilter
          onChange={this.onChange}
          sortedFlattenChannels={ownerList}
          value={selectedOwners}
          footerComponent={
            <FormDdlActionBar
              isVisible
              selectedCount={selectedOwners ? selectedOwners.length : 0}
              onClearAll={this.onClearAll}
            />
          }
        />
      );
    }
  },
);
