// @flow
import React, {useCallback, useMemo} from 'react';
import {getActiveChannels} from 'alerts.channels/store/selectors';
import {useSelector} from 'react-redux';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';

import {StringParam, useQueryParam} from 'use-query-params';
import {getUsersData} from 'admin.users/store/selectors';

const OwnerFilter = () => {
  const users = useSelector(getUsersData);
  const channelsData = useSelector(getActiveChannels);

  const usersEmails = useMemo(() => channelsData.map((channel) => JSON.parse(channel.tags).owner), [channelsData]);
  const owners = useMemo(() => {
    const result = [];
    users.forEach((user) => {
      if (usersEmails.includes(user.email)) {
        result.push({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`,
        });
      }
    });
    return result;
  }, [users, usersEmails]);

  const [owner, setOwner] = useQueryParam('owner', StringParam);
  const selectOwnersArr = owner ? owner.split(',') : [];
  const optionCurrent = owners.filter((i) => selectOwnersArr.includes(i.value));

  const onChange = useCallback(
    (values) => {
      setOwner(values.length ? values.map((i) => i.value).join(',') : undefined);
    },
    [setOwner],
  );

  const onClearAll = useCallback(() => {
    if (optionCurrent && optionCurrent.length > 0) {
      setOwner(undefined);
    } else {
      const selectedOption = owners.map((i) => i.value).join(',');
      setOwner(selectedOption);
    }
  }, [owners, optionCurrent]);

  return (
    <SelectAndt
      id="ownerFilter"
      automationId="channelsOwnerFilter"
      extraClassName="alerts-dropdown-btn"
      options={owners}
      buttonWidth={190}
      optionHeight={40}
      menuWidth={240}
      type={TYPE_NEW_MULTI}
      theme={THEME_HIGHLIGHTED}
      onChange={onChange}
      value={optionCurrent}
      isMulti
      placeholder="All"
      isClearable={false}
      closeMenuOnSelect={false}
      menuFooterComponent={<FormDdlActionBar isVisible selectedCount={optionCurrent.length} onClearAll={onClearAll} />}
    />
  );
};

export default OwnerFilter;
