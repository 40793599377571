export const OPTIONS_EXPRESSION_SEARCH_VALUE = {
  ADD_FUNCTION: 'add_function',
  COPY_QUERY: 'copy_query',
  ADD_QUERY: 'add_query',
  DELETE: 'delete',
};

export const OPTIONS_EXPRESSION_SEARCH = [
  {
    label: 'Add Function',
    value: OPTIONS_EXPRESSION_SEARCH_VALUE.ADD_FUNCTION,
    icon: 'icn-Functions16',
  },
  {
    label: 'Add Query',
    value: OPTIONS_EXPRESSION_SEARCH_VALUE.ADD_QUERY,
    icon: 'icn-action24-plusb',
  },
  {
    label: 'Copy Query',
    value: OPTIONS_EXPRESSION_SEARCH_VALUE.COPY_QUERY,
    icon: 'icn-action16-copy',
  },
  {
    label: 'Delete',
    value: OPTIONS_EXPRESSION_SEARCH_VALUE.DELETE,
    icon: 'icn-action16-delete',
  },
];
