// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setSalesforceStreamClearAllDiametrics as setSalesforceStreamClearAllDiametricsAction,
  setStreamDiametricsEditorFilterTextInput as setStreamDiametricsEditorFilterTextInputAction,
  setSalesforceStreamDiametricsChange as setSalesforceStreamDiametricsChangeAction,
  removeSalesforceStreamDiametrics as removeSalesforceStreamDiametricsAction,
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import DimensionsAndMetricsEditor from 'bc/components/streams/editor/common/DimensionsAndMetricsEditor';

type PropTypes = {
  dimensions: Array<Object>,
  metrics: Array<Object>,
  filteredUnassignedColumns: Array<Object>,
  timeDefinitionArr: Array,
  timestampType: String,
  filterTextInput: String,
  setSalesforceStreamClearAllDiametrics: Function,
  setStreamDiametricsEditorFilterTextInput: Function,
  setSalesforceStreamDiametricsChange: Function,
  removeSalesforceStreamDiametrics: Function,
  setSelectedStreamKeyVal: Function,
  isStreamUpdating: Boolean,
};

export default connect(
  (state) => ({
    dimensions: selectors.getSalesforceDimensions(state),
    metrics: selectors.getSalesforceMetrics(state),
    timeDefinitionArr: selectors.getSalesforceStreamSelectedTimestampColumnArr(state),
    timestampType: selectors.getSalesforceStreamSelectedTimestampType(state),
    filterTextInput: selectors.getStreamMetricsDimentionsFilterTextInput(state),
    filteredUnassignedColumns: selectors.getFilteredSalesforceStreamUnAssignedColumns(state),
    isStreamUpdating: selectors.getIsDataStreamUpdating(state),
  }),
  {
    setSalesforceStreamClearAllDiametrics: setSalesforceStreamClearAllDiametricsAction,
    setStreamDiametricsEditorFilterTextInput: setStreamDiametricsEditorFilterTextInputAction,
    setSalesforceStreamDiametricsChange: setSalesforceStreamDiametricsChangeAction,
    removeSalesforceStreamDiametrics: removeSalesforceStreamDiametricsAction,
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
  },
)(
  class SalesforceDimensionsAndMetricsEditor extends React.PureComponent {
    props: PropTypes;

    render() {
      const {
        metrics,
        dimensions,
        filterTextInput,
        filteredUnassignedColumns,
        timestampType,
        timeDefinitionArr,
        removeSalesforceStreamDiametrics,
        setStreamDiametricsEditorFilterTextInput,
        setSalesforceStreamDiametricsChange,
        setSalesforceStreamClearAllDiametrics,
        isStreamUpdating,
        setSelectedStreamKeyVal,
      } = this.props;

      return (
        <DimensionsAndMetricsEditor
          dimensions={dimensions}
          metrics={metrics}
          filteredUnassignedColumns={filteredUnassignedColumns}
          timeDefinitionArr={timeDefinitionArr}
          timestampType={timestampType}
          onTimestampTypeChange={setSelectedStreamKeyVal}
          filterTextInput={filterTextInput}
          isStreamUpdating={isStreamUpdating}
          setStreamClearAllDiametrics={setSalesforceStreamClearAllDiametrics}
          setMetricDimentionEditorFilterTextInput={setStreamDiametricsEditorFilterTextInput}
          setStreamDiametricsChange={setSalesforceStreamDiametricsChange}
          removeStreamDiametrics={removeSalesforceStreamDiametrics}
        />
      );
    }
  },
);
