import * as actions from 'bc/store/actions';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {getUniqueId} from 'common/utils/guid';
import {rangeTypes, getDateValue} from 'common/utils/dateRangeService';
import {get, cloneDeep, findLastIndex} from 'lodash';
import generateUnitsList from 'bc/services/unitsService';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import {getAllowedRangeTypesByPollingInterval} from 'bc/services/dataStreamService';

import {bcTypes} from 'bc/services/bcTypes';
import baseDataStreamReducer from './baseDataStreamReducer';
import fileDiametricsDataStreamReducer from './fileDiametricsDataStreamReducer';
import gaDataStreamReducer from './gaDataStreamReducer';
import ga4DataStreamReducer from './ga4DataStreamReducer';
import gadsDataStreamReducer from './gadsDataStreamReducer';
import adobeDataStreamReducer from './adobeDataStreamReducer';
import bigQueryDataStreamReducer from './bigQueryDataStreamReducer';
import fuDataStreamReducer from './fuDataStreamReducer';
import s3DataStreamReducer from './s3DataStreamReducer';
import awsCurDataStreamReducer from './awsCurDataStreamReducer';
import gsDataStreamReducer from './gsDataStreamReducer';
import sqlDataStreamReducer from './sqlDataStreamReducer';
import mParticleStreamReducer from './mParticleStreamReducer';
import segmentStreamReducer from './segmentStreamReducer';
import kinesisDataStreamReducer from './kinesisDataStreamReducer';
import salesforceDataStreamReducer from './salesforceDataStreamReducer';
import parquetDataStreamReducer from './parquetDataStreamReducer';
import facebookAdsDataStreamReducer from './facebookAdsDataStreamReducer';
import googleAuctionsDataStreamReducer from './googleAuctionsDataStreamReducer';
import googleSearchDataStreamReducer from './googleSearchDataStreamReducer';
import coralogixDataStreamReducer from './coralogixDataStreamReducer';
import newrelicStreamReducer from './newrelicStreamReducer';
import mixpanelStreamReducer from './mixpanelStreamReducer';
import datadogDataStreamReducer from './datadogDataStreamReducer';

const EMPTY_ARRAY = [];
const EMPTY_OBJ = {};

const dataStreams = composeReducers(
  // eslint-disable-next-line complexity
  (
    state = {
      selectedItemId: '',
      selectedEditor: {},
      isCreatingNewStream: false,
      isUpdatingStream: false,
      isStreamNeedUpdating: false,
      selectedStreamPreviewColumnWidth: {},
      eventStreams: EMPTY_ARRAY,
    },
    {type, payload, meta},
  ) => {
    const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

    const red = (item, itemPayload) => ({...item, ...itemPayload});

    const redWrapper = (_payload, index = getSelectedIndex()) => ({
      ...state,
      streams: {
        ...state.streams,
        data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
      },
    });

    switch (type) {
      case actions.updateStreamName.TYPE:
      case actions.updateAgentOwner.TYPE:
      case actions.updateDataStreamScheduling.TYPE: {
        const payloadUpdate = {...payload};
        return {...redWrapper(payloadUpdate), isStreamNeedUpdating: true};
      }

      case actions.setEventStreamsFromReactQueryToReducer.TYPE: {
        return {
          ...state,
          eventStreams: payload,
        };
      }

      case actions.setSelectedStreamKeyVal.TYPE: {
        const payloadInner = {...payload};
        const bcTypesArr = [bcTypes.kinesis.type, bcTypes.google_ads.type, bcTypes.eventhubs.type];

        if (payloadInner.pollingInterval && !bcTypesArr.includes(state.streams.data[getSelectedIndex()].type)) {
          const selectedStream = cloneDeep(state.streams.data[getSelectedIndex()]);
          const allowedRange = getAllowedRangeTypesByPollingInterval(payloadInner.pollingInterval);
          const fullRange = Reflect.ownKeys(rangeTypes);

          if (allowedRange.indexOf(selectedStream.historicalDateRange.constRange) === -1) {
            const indexPos = fullRange.indexOf(selectedStream.historicalDateRange.constRange);
            if (indexPos !== -1) {
              payloadInner.historicalDateRange = getDateValue(
                allowedRange[indexPos < 5 ? 0 : allowedRange.length - 1],
                true,
              );
            }
          }
        }
        return {...redWrapper(payloadInner), isStreamNeedUpdating: true};
      }

      case actions.setSelectedStreamUiKeyVal.TYPE: {
        const stream = state.streams.data[getSelectedIndex()];
        const streamModifications = {
          uiState: {
            ...stream.uiState,
            ...payload,
          },
        };
        return redWrapper(streamModifications);
      }

      case actions.setStreamPreviewColumnWidth.TYPE:
        return {
          ...state,
          selectedStreamPreviewColumnWidth: {
            ...state.selectedStreamPreviewColumnWidth,
            [payload.colId]: payload.colWidth,
          },
        };

      case actions.setSelectedEditorBounced.TYPE:
        return {...state, selectedEditor: payload || EMPTY_OBJ};

      case actions.openSchedulerEditorBounced.TYPE:
        return {...state, selectedEditor: payload || EMPTY_OBJ};

      case actions.setStreamSchemaTransform.TYPE: {
        const stream = cloneDeep(state.streams.data[getSelectedIndex()]);
        const col = stream.schema.columns.find((i) => i.id === payload.colId);
        const getColumnRoot = (trans) => {
          // column root can be nested in a leaf
          if (!trans.sourceColumn && !trans.input) {
            return false;
          }
          if (trans.sourceColumn) {
            return [{sourceColumn: trans.sourceColumn}];
          }
          if (trans.name === 'concat') {
            return trans.input;
          }
          return getColumnRoot(trans.input[0].transform || trans.input[0]);
        };
        const colRoot =
          col.sourceColumn !== undefined ? [{sourceColumn: col.sourceColumn}] : getColumnRoot(col.transform);
        const lastConcatFunc = findLastIndex(payload.transforms, (t) => t.name === 'concat');

        if (payload.transforms.length) {
          col.transform = {};
          payload.transforms.forEach((t, i) => {
            const trans = {
              parameters: t.parameters.map((p) => p.value),
              name: t.name,
            };
            if (i === lastConcatFunc || i === 0) {
              if (colRoot) {
                trans.input = colRoot;
              }
            } else {
              trans.input = [{transform: col.transform}];
            }
            col.transform = trans;
          });
          delete col.sourceColumn;
        } else {
          if (colRoot[0].sourceColumn) {
            col.sourceColumn = colRoot[0].sourceColumn;
          }
          delete col.transform;
        }

        return {...redWrapper(stream)};
      }

      case actions.setStreamSchemaColumnKeyVal.TYPE: {
        const applyToAllMetrics = get(meta, 'applyToAllMetrics', false);
        const selectedStream = cloneDeep(state.streams.data[getSelectedIndex()]);
        const applyChangesToColumns = (columns) =>
          columns.map((i) => {
            if (applyToAllMetrics && i.type === 'metric') {
              return {...i, ...{[payload.key]: payload.value}};
            }
            if (i.id === payload.colId) {
              return {...i, ...{[payload.key]: payload.value}};
            }
            return i;
          });

        selectedStream.schema.columns = applyChangesToColumns(selectedStream.schema.columns);

        if (!get(meta, 'runStreamPreview', false)) {
          const selectedStreamPreview = cloneDeep(state.selectedStreamPreview);
          selectedStreamPreview.data.columns = applyChangesToColumns(selectedStreamPreview.data.columns);
          return {...redWrapper(selectedStream), selectedStreamPreview};
        }
        return {...redWrapper(selectedStream)};
      }

      case actions.createStreamSchemaNewColumn.TYPE: {
        const currentStream = cloneDeep(state.streams.data[getSelectedIndex()]);

        const newCol = {
          id: getUniqueId(),
          name: payload.colName,
          type: payload.colType,
        };

        if (payload.transform) {
          newCol.transform = payload.transform;
        }

        if (payload.targetType) {
          newCol.targetType = payload.targetType;
        }

        if (payload.sourceColumn) {
          newCol.sourceColumn = payload.sourceColumn;
        }

        currentStream.schema.columns.push(newCol);
        return {...redWrapper(currentStream)};
      }

      case actions.deleteStreamSchemaColumn.TYPE: {
        const currentStream = cloneDeep(state.streams.data[getSelectedIndex()]);

        currentStream.schema.columns = currentStream.schema.columns.filter((c) => c.id !== payload);
        return {...redWrapper(currentStream)};
      }

      case actions.setStreamDiametricsEditorFilterTextInput.TYPE: {
        const stream = state.streams.data[getSelectedIndex()];
        const streamModifications = {
          uiState: {
            ...stream.uiState,
            filterTextInput: payload,
          },
        };
        return redWrapper(streamModifications);
      }

      default:
        return state;
    }
  },
  {
    streams: composeReducers(
      makeAsyncReducer(actions.fetchDataStreams, {
        defaultData: EMPTY_ARRAY,
        shouldDestroyData: false,
        includeUpdateAt: true,
      }),
    ),
  },

  {
    deleteStreamState: makeAsyncReducer(actions.deleteDataStream, {
      shouldDestroyData: false,
    }),
  },
  {
    deletePipelineState: makeAsyncReducer(actions.deletePipelineStream, {
      shouldDestroyData: false,
    }),
  },
  {
    selectedStreamPreview: composeReducers(
      makeAsyncReducer(actions.fetchDataStreamPreview, {
        shouldDestroyData: false,
        errorHandler: bcErrorHandler,
      }),
      (state, {type}) => {
        switch (type) {
          case actions.fetchLiveDataStreamPreview.TYPE:
            return {data: EMPTY_OBJ, isLoading: true};
          default:
            return state;
        }
      },
    ),
  },
  {
    selectedStreamStats: composeReducers(
      makeAsyncReducer(actions.fetchDataStreamStats, {
        shouldDestroyData: false,
        errorHandler: bcErrorHandler,
      }),
      (state, {type}) => {
        switch (type) {
          case actions.fetchLiveDataStreamStats.TYPE:
            return {data: EMPTY_OBJ, isLoading: true};
          default:
            return state;
        }
      },
    ),
  },
  {
    selectedStreamCardinality: makeAsyncReducer(actions.fetchDataStreamCardinality, {
      shouldDestroyData: false,
      errorHandler: bcErrorHandler,
    }),
  },
  {
    selectedStreamHistoryLogs: makeAsyncReducer(actions.fetchStreamHistoryLogs, {
      errorHandler: bcErrorHandler,
    }),
  },
  {
    selectedStreamLastRun: makeAsyncReducer(actions.fetchStreamLastRun, {
      shouldDestroyData: false,
      errorHandler: bcErrorHandler,
    }),
  },
  {
    transformFunctions: makeAsyncReducer(actions.fetchTransformFunctions, {
      defaultData: EMPTY_ARRAY,
      dataProp: 'items',
      shouldDestroyData: false,
    }),
  },
  {
    dashAndAlertTitles: makeAsyncReducer(actions.fetchDashAndAlertTitles, {
      defaultData: EMPTY_ARRAY,
      dataProp: 'items',
      shouldDestroyData: false,
    }),
  },
  {
    streamsStats: makeAsyncReducer(actions.fetchStreamsStats, {
      defaultData: {metrics: EMPTY_OBJ, rate: EMPTY_OBJ},
      includeUpdateAt: true,
      shouldSpread: true,
    }),
  },
  {
    unitList: generateUnitsList,
  },
  baseDataStreamReducer,
  gaDataStreamReducer,
  ga4DataStreamReducer,
  gadsDataStreamReducer,
  adobeDataStreamReducer,
  bigQueryDataStreamReducer,
  fileDiametricsDataStreamReducer,
  fuDataStreamReducer,
  s3DataStreamReducer,
  awsCurDataStreamReducer,
  gsDataStreamReducer,
  sqlDataStreamReducer,
  mParticleStreamReducer,
  segmentStreamReducer,
  kinesisDataStreamReducer,
  salesforceDataStreamReducer,
  parquetDataStreamReducer,
  facebookAdsDataStreamReducer,
  googleAuctionsDataStreamReducer,
  googleSearchDataStreamReducer,
  coralogixDataStreamReducer,
  newrelicStreamReducer,
  mixpanelStreamReducer,
  datadogDataStreamReducer,
);

export default dataStreams;
