import React from 'react';
import useGetToken from '../services/api';

const ValueReport = () => {
  const {embeddedToken, tokenIsLoading} = useGetToken();
  if (tokenIsLoading || !embeddedToken?.token) {
    return null;
  }
  const embedCֹode = '5ae624c44853f12eea96583b';
  const src = `https://us.holistics.io/embed/${embedCֹode}?_token=${embeddedToken.token}`;

  return (
    <iframe
      style={{'z-index': '1', paddingLeft: '64px'}}
      src={src}
      title="holistics"
      scrolling="auto"
      width="100%"
      height="100%"
      frameBorder="0"
      allowFullScreen
    />
  );
};
export default ValueReport;
