// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import SearchBox from 'common/componentsV2/SearchBox';
import * as selectors from '../store/selectors';
import * as actions from '../store/actions';

import RndOnlyFilter from './RndOnlyFilter';
import GlobalDefaultFilter from './GlobalDefaultFilter';
import DataCollectorsFilter from './DataCollectorsFilter';

import './FeaturesFlagsFilter.module.scss';

type PropTypes = {
  // connect
  rndOnly: Boolean,
  queryCustomers: String,
  changeQueryCustomers: Function,
  queryGlobal: String,
  globalDefaultOn: Boolean,
  changeQueryGlobal: Function,
  changeRndOnly: Function,
  changeGlobalDefault: Function,
  featureFlagsDefaults: Array,
  customersView: Array,
  changeDataCollectors: Function,
  dataCollectors: Boolean,
};

export default connect(
  (state) => ({
    queryGlobal: selectors.getQueryGlobals(state),
    queryCustomers: selectors.getQueryCustomers(state),
    rndOnly: selectors.getRndOnly(state),
    dataCollectors: selectors.getDataCollectors(state),
    globalDefaultOn: selectors.getGlobalDefaultOn(state),
    customersView: selectors.getDataUIStateFiltered(state),
    featureFlagsDefaults: selectors.getDefaults(state),
  }),
  {
    changeQueryCustomers: actions.changeQueryCustomers,
    changeQueryGlobal: actions.changeQueryGlobal,
    changeRndOnly: actions.changeRndOnly,
    changeGlobalDefault: actions.changeGlobalDefaultOn,
    changeDataCollectors: actions.changeDataCollectors,
  },
)(
  class FeaturesFlagsFilter extends React.PureComponent {
    props: PropTypes;

    setSearchQueryCustomers = (value) => {
      this.props.changeQueryCustomers(value);
    };

    setSearchQueryGlobal = (value) => {
      this.props.changeQueryGlobal(value);
    };

    render() {
      const {
        queryCustomers,
        queryGlobal,
        rndOnly,
        changeRndOnly,
        globalDefaultOn,
        changeGlobalDefault,
        featureFlagsDefaults,
        customersView,
        dataCollectors,
        changeDataCollectors,
      } = this.props;

      return (
        <div styleName="root">
          <FiltersPanel isOpen isSaveView={false} isShareLink={false}>
            <div styleName="filters-row">
              <SearchBox
                isAutoFocus
                filter={queryGlobal}
                onFilter={this.setSearchQueryGlobal}
                autoFocus={false}
                placeHolder={
                  featureFlagsDefaults.data.length ? `Features: ${featureFlagsDefaults.data.length} total` : 'Features'
                }
              />
            </div>

            <div styleName="filters-row">
              <SearchBox
                filter={queryCustomers}
                onFilter={this.setSearchQueryCustomers}
                autoFocus={false}
                placeHolder={customersView.length ? `Customers: ${customersView.length - 1} total` : 'Customers'} // we dont consider the global customer
              />
            </div>

            <div styleName="filters-row">
              <RndOnlyFilter isChecked={rndOnly} onChanged={changeRndOnly} />
            </div>

            <div styleName="filters-row">
              <GlobalDefaultFilter isChecked={globalDefaultOn} onChanged={changeGlobalDefault} />
            </div>

            <div styleName="filters-row">
              <DataCollectorsFilter isChecked={dataCollectors} onChanged={changeDataCollectors} />
            </div>
          </FiltersPanel>
        </div>
      );
    }
  },
);
