// @flow
import React from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import Highlighter from 'react-highlight-words';
import './Checkbox.module.scss';

type PropTypes = {
  isChecked: boolean,
  threeStatesStatus?: string,
  automationId: string,
  onChange: Function,
  text?: String,
  highlightText?: String,
  isDisabled?: boolean,
  eventKey?: String,
  className?: String,
  children: Object,
  segmentClickEvent: Function,
};

let checkboxIndex = 0;

export const THREE_STATES = {
  UNCHECKED: 'unchecked',
  HALF_CHECKED: 'halfChecked',
  FULL_CHECKED: 'fullChecked',
};

export default connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)(
  class Checkbox extends React.PureComponent {
    props: PropTypes;

    static defaultProps = {
      text: '',
      highlightText: '',
      isDisabled: false,
      eventKey: '',
      className: '',
      threeStatesStatus: '',
    };

    state = {
      checkboxIndex,
    };

    componentDidMount() {
      this.setState({
        checkboxIndex,
      });
      checkboxIndex += 1;
    }

    onChange = (event) => {
      this.props.segmentClickEvent({
        type: 'check',
        name: this.props.text || this.props.automationId,
        automationId: this.props.automationId,
      });
      if (this.props.threeStatesStatus === '') {
        if (this.props.eventKey !== '') {
          this.props.onChange({
            eventKey: this.props.eventKey,
            value: event.target.checked,
          });
        } else if (this.props.isChecked) {
          this.props.onChange(false);
        } else {
          this.props.onChange(true);
        }
      } else if (
        this.props.threeStatesStatus === THREE_STATES.HALF_CHECKED ||
        this.props.threeStatesStatus === THREE_STATES.FULL_CHECKED
      ) {
        this.props.onChange(false);
      } else {
        this.props.onChange(true);
      }
    };

    getLabelText = () => {
      const {text, highlightText} = this.props;

      if (text) {
        return (
          <span styleName="label-text">
            <Highlighter autoEscape searchWords={[highlightText || '']} textToHighlight={text} />
          </span>
        );
      }
      return '';
    };

    render() {
      const {isChecked, className, isDisabled, threeStatesStatus, children, automationId} = this.props;
      let threeStatesStatusStyle = '';
      let checked = isChecked;

      if (threeStatesStatus !== '') {
        switch (threeStatesStatus) {
          case THREE_STATES.FULL_CHECKED:
            threeStatesStatusStyle = 'full-checked';
            checked = true;
            break;
          case THREE_STATES.HALF_CHECKED:
            threeStatesStatusStyle = 'half-checked';
            checked = true;
            break;
          default:
        }
      }
      const rootStyle = ['root', isDisabled ? 'disabled' : '', threeStatesStatusStyle].join(' ');
      const rootClass = className || '';
      return (
        <div styleName={rootStyle} className={rootClass}>
          <input
            type="checkbox"
            checked={checked || false}
            disabled={isDisabled}
            id={`checkbox-${this.state.checkboxIndex}`}
            onChange={() => {}}
            automation-id={automationId}
          />
          <label onClick={isDisabled ? () => {} : this.onChange} htmlFor={`checkbox-${this.state.checkboxIndex}`}>
            {this.getLabelText()}
            {children}
          </label>
        </div>
      );
    }
  },
);
