// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {isStreamEditable} from 'bc/services/dataStreamService';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import Loader from 'common/components/Loader';

import '../../common/DimensionsAndMetrics.module.scss';

type PropTypes = {
  // connect
  dataStream: Object,
  isReportLoading: boolean,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isReportLoading: selectors.getAdobeStreamReportDescriptionIsLoading(state),
  }),
  {},
)(
  class AdobeJsonViewer extends React.PureComponent {
    props: PropTypes;

    render() {
      const {dataStream, isReportLoading} = this.props;
      const isEditable = isStreamEditable(dataStream.state);

      if (isReportLoading === true) {
        return (
          <div className="shell-col" styleName="root">
            <PanelHeader title="Adobe Analytics Report Definition" isEditable={isEditable} />
            <Loader size="small" />
          </div>
        );
      }

      return (
        <div className="shell-col" styleName={['root', isEditable ? '' : 'disabled'].join(' ')}>
          <PanelHeader
            title="Adobe Analytics Report Definition"
            isEditable={isEditable && !isReportLoading && dataStream.reportDescription}
          />
          {dataStream.reportDescription && (
            <div className="shell">
              <pre styleName="json-viewer">{JSON.stringify(dataStream.reportDescription, null, 2)}</pre>
            </div>
          )}
        </div>
      );
    }
  },
);
