import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {useFormContext} from 'react-hook-form';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import InfoBox from 'dataCollectors/components/infoBox/InfoBox';
import useSectionsClasses from 'dataCollectors/styles/classes.useSectionsClasses';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';

import useFetchUserEventsCategories from 'dataCollectors/api/useFetchUserEventsCategories';

const {EVENT_CATEGORY} = EVENT_STREAM_FIELDS;
const {sections} = useSectionsClasses();

export default function UserEventsCategory({info, leftSideWidth, isLast}) {
  const sectionsClasses = sections({leftSideWidth});

  const {userEventCategories, isUserEventCategoriesLoading} = useFetchUserEventsCategories();

  const {
    setValue,
    getValues,
    formState: {errors},
  } = useFormContext();

  const values = getValues();

  const [selectedItemState, setSelectedItemState] = useState(null);

  const onChange = (item) => {
    setValue(EVENT_CATEGORY.id, item.value);
  };

  useEffect(() => {
    if (values[EVENT_CATEGORY.id]) {
      setSelectedItemState(userEventCategories.find((i) => i.value === values[EVENT_CATEGORY.id]));
    } else {
      setSelectedItemState(null);
    }
  }, [userEventCategories, values[EVENT_CATEGORY.id]]);

  return (
    <div className={`${!isLast ? sectionsClasses.root : sectionsClasses.rootLast}`}>
      <div className={sectionsClasses.container}>
        <div className={sectionsClasses.left}>
          <div className={sectionsClasses.title}>Events category</div>
          <div className={sectionsClasses.subTitle}>Select a category for events coming from this stream.</div>

          <div className={sectionsClasses.inputsWrapper2}>
            <div className={`${sectionsClasses.input} ${sectionsClasses.marginTop}`}>
              <div className={sectionsClasses.label}>{EVENT_CATEGORY.name}</div>
              <FormDdlSelect
                isDisabledSorting
                options={userEventCategories}
                selected={selectedItemState}
                onChange={onChange}
                button={
                  <BigDropdownButton
                    isPristine={!selectedItemState}
                    label={selectedItemState?.label}
                    placeholder={isUserEventCategoriesLoading ? 'Loading..' : 'Select'}
                    shortBlueStyle
                    isFullWidth
                  />
                }
                optionComponent={<OptionComponentSimple />}
                width={240}
                height={260}
                placement="auto"
              />
              {errors[EVENT_CATEGORY.id] && !selectedItemState && (
                <span className={sectionsClasses.inputError}>{errors[EVENT_CATEGORY.id]}</span>
              )}
            </div>
          </div>
        </div>
        {info && <InfoBox title={info.title} body={info.body} />}
      </div>
    </div>
  );
}

UserEventsCategory.defaultProps = {
  info: false,
  leftSideWidth: '100%',
  isLast: false,
};

UserEventsCategory.propTypes = {
  info: PropTypes.bool,
  leftSideWidth: PropTypes.number,
  isLast: PropTypes.bool,
};
