// @flow
import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import * as commonSelectors from 'profile/store/selectors';
import * as dateRangeService from 'common/utils/dateRangeService';
import {setSelectedStreamKeyVal as setSelectedStreamKeyValAction} from 'bc/store/actions';
import {getNotificationDelay, pollingIntervals, getMissingHours} from 'bc/services/dataStreamService';
import {bcTypes, isMaxBackFillIntervalsShouldBePresent} from 'bc/services/bcTypes';
import {getAggrIntervalLabel} from 'bc/services/coralogixService';
import {ROLLUP_AGGREGATION_FUNCTION, ROLLUP_PERIODS} from 'bc/services/datadogService';
import './SchedularData.module.scss';

type PropTypes = {
  dataStream: Object,
  timeZoneName: String,
  setSelectedStreamKeyVal: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    timeZoneName: commonSelectors.getTimeZoneName(state),
  }),
  {
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
  },
)(
  class SchedularData extends PureComponent {
    props: PropTypes;

    constructor(props) {
      super(props);
      const {dataStream, setSelectedStreamKeyVal} = this.props;

      if (
        dataStream &&
        !(dataStream.maxBackFillIntervals >= 0) &&
        isMaxBackFillIntervalsShouldBePresent(dataStream.type)
      ) {
        setSelectedStreamKeyVal({maxBackFillIntervals: 0});
      }
    }

    render() {
      const {dataStream, timeZoneName} = this.props;
      const historicalTimeZone = dataStream.timeZone || timeZoneName;

      let rollupAggregationFunctionLabel = '';
      let rollupAggregationPeriodLabel = '';
      if (dataStream.type === bcTypes.datadog.type) {
        rollupAggregationFunctionLabel = ROLLUP_AGGREGATION_FUNCTION.find(
          (raf) => raf.value === dataStream.rollupAggregation,
        ).label;
        rollupAggregationPeriodLabel = ROLLUP_PERIODS.find((raf) => raf.value === dataStream.resolutionMinutes).label;
      }

      return (
        <Fragment>
          {![bcTypes.kinesis.type, bcTypes.eventhubs.type, bcTypes.segment.type].includes(dataStream.type) ? (
            <div>
              <span styleName="title">Collect Every</span>
              <span styleName="data">{pollingIntervals[dataStream.pollingInterval].labelShort}</span>
            </div>
          ) : null}
          {dataStream.delayMinutes >= 0 &&
          ![bcTypes.google_search.type, bcTypes.coralogix.type].includes(dataStream.type) ? (
            <div>
              <span styleName="title">Delay (Minutes)</span>
              <span styleName="data">{dataStream.delayMinutes}</span>
            </div>
          ) : null}
          {dataStream.delayMinutes >= 0 && bcTypes.google_search.type === dataStream.type ? (
            <div>
              <span styleName="title">Delay (Hours)</span>
              <span styleName="data">{dataStream.delayMinutes / 60}</span>
            </div>
          ) : null}
          {dataStream.maxBackFillIntervals >= 0 ? (
            <div>
              <span styleName="title">Retry Policy</span>
              <span styleName="data">{dataStream.maxBackFillIntervals}</span>
            </div>
          ) : null}
          {dataStream.maxMissingFiles >= 0 ? (
            <div>
              <span styleName="title">Lagging Files Policy</span>
              <span styleName="data">{dataStream.maxMissingFiles}</span>
            </div>
          ) : null}
          {dataStream.timeZone ? (
            <div>
              <span styleName="title">Time Zone</span>
              <span styleName="data">{dataStream.timeZone}</span>
            </div>
          ) : null}
          {dataStream.historicalDateRange ? (
            <div>
              <span styleName="title">Collect Since</span>
              <span styleName="data">
                {dateRangeService.getDate(dataStream.historicalDateRange, historicalTimeZone).dateText}
              </span>
            </div>
          ) : null}
          {dataStream.aggrInterval ? (
            <div>
              <span styleName="title">Bucketing Options</span>
              <span styleName="data">{getAggrIntervalLabel(dataStream.aggrInterval)}</span>
            </div>
          ) : null}
          {[bcTypes.s3.type, bcTypes.coralogix.type].includes(dataStream.type) ? (
            <div>
              <span styleName="title">No data notification after:</span>
              <span styleName="data">{getNotificationDelay(dataStream.notificationDelay)}</span>
            </div>
          ) : null}
          {[bcTypes.coralogix.type].includes(dataStream.type) ? (
            <div>
              <span styleName="title">Fill missing data for:</span>
              <span styleName="data">{getMissingHours(dataStream.fillMissingHours)}</span>
            </div>
          ) : null}
          {dataStream.type === bcTypes.google_ads.type && dataStream.pollingInterval !== 'daily' ? (
            <div>
              <span styleName="title">Daily Accumulation:</span>
              <span styleName="data">{dataStream.notificationDelay === true ? 'Yes' : 'No'}</span>
            </div>
          ) : null}
          {dataStream.type === bcTypes.datadog.type && rollupAggregationFunctionLabel ? (
            <div>
              <span styleName="title">Rollup Function:</span>
              <span styleName="data">{rollupAggregationFunctionLabel}</span>
            </div>
          ) : null}
          {dataStream.type === bcTypes.datadog.type && rollupAggregationPeriodLabel ? (
            <div>
              <span styleName="title">Rollup Period:</span>
              <span styleName="data">{rollupAggregationPeriodLabel}</span>
            </div>
          ) : null}
        </Fragment>
      );
    }
  },
);
