import React from 'react';
import './SmallDropdownButton.module.scss';

type PropTypes = {
  value: string,
  disable: boolean,
  placeholder: string,
  useAllWidth: boolean,
};

const SmallDropdownButton = (props: PropTypes) => (
  <div
    className="form-button"
    styleName={['container', props.disable ? 'disable' : '', props.useAllWidth ? 'use-all-width' : ''].join(' ')}
  >
    <div styleName={props.value ? 'value' : 'placeholder'}>{props.value || props.placeholder}</div>
    <div styleName="triangle" className="icon icn-arrow16-triangledown" />
  </div>
);

export default SmallDropdownButton;
