// @flow
import SmartTooltip from 'common/components/SmartTooltip';
import React, {PureComponent} from 'react';
import Highlighter from 'react-highlight-words';
import {ENVS} from './Table';
import './Table.module.scss';

type PropTypes = {
  pr: Object,
  expandedPullReqId: any,
  searchString: string,
  setPageKeyVal: Function,
};

export default class RowHeader extends PureComponent {
  props: PropTypes;

  rowClicked = () => {
    const {pr, setPageKeyVal, expandedPullReqId} = this.props;
    setPageKeyVal({expandedPullReqId: expandedPullReqId === pr.id ? '' : pr.id});
  };

  renderEnvironmentChkMark = (pr, env) => (pr.environments.includes(env) ? <i className="icon ion-checkmark" /> : null);

  render = () => {
    const {pr, expandedPullReqId, searchString} = this.props;
    const {renderEnvironmentChkMark, rowClicked} = this;

    return (
      <div
        styleName={['table-row-header', expandedPullReqId === pr.id ? 'expanded-row' : '  '].join(' ')}
        onClick={rowClicked}
        role="none"
      >
        <div styleName="col-pr">
          <div className="ellipsis">
            <Highlighter autoEscape searchWords={[searchString]} textToHighlight={pr.title} />
          </div>
          <div styleName="pr-description" className="ellipsis">
            <Highlighter autoEscape searchWords={[searchString]} textToHighlight={pr.description} />
          </div>
        </div>
        <div styleName="col-date">
          <SmartTooltip content={pr.updated_on}>
            <span>{pr.updated_on_mini}</span>
          </SmartTooltip>
        </div>
        <div styleName="col-author">
          <Highlighter autoEscape searchWords={[searchString]} textToHighlight={pr.author.display_name} />
        </div>
        {ENVS.map((env) => (
          <div key={env.key} styleName="col-env">
            {renderEnvironmentChkMark(pr, env.key)}
          </div>
        ))}
      </div>
    );
  };
}
