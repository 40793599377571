import React, {useState} from 'react';

import Button from 'common/componentsV2/Button';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import FilterSelector from 'dashboards/components/filters/FilterSelector';

const SimpleButtonSelect = ({
  options,
  onChange,
  value,
  text,
  icon,
  onMenuOpen,
  isLoading,
  searchPlaceholder,
  isDisabled,
}: {
  options: Array,
  onChange: Function,
  onMenuOpen: Function,
  value: Object,
  text: string,
  icon: string,
  isLoading: boolean,
  searchPlaceholder: string,
  isDisabled: boolean,
}) => {
  const [showFilterSelect, setShowFilterSelect] = useState(false);

  return (
    <div>
      <Tooltip
        content="Available in Edit Mode"
        type={TYPES.SMALL}
        placement="bottom"
        hideIfEqualTo={!isDisabled ? 'Available in Edit Mode' : null}
      >
        <div>
          <Button
            style={{cursor: 'default'}}
            extraClassName="ghost-blue-light tooltip-btn"
            text={text}
            icon={icon}
            onClick={() => {
              setShowFilterSelect(!showFilterSelect);
            }}
            isDisabled={isDisabled}
          />
        </div>
      </Tooltip>
      {showFilterSelect && (
        <FilterSelector
          options={[{label: 'DIMENESIONS', options}]}
          onChange={(item) => {
            setShowFilterSelect(false);
            onChange(item);
          }}
          onMenuOpen={onMenuOpen}
          value={value}
          openMenuInLoading={showFilterSelect}
          icon={icon}
          setMenuIsOpen={(e) => {
            // support close menu in filters button
            if (
              (e?.relatedTarget?.type === 'button' && e?.relatedTarget?.textContent === 'Filters') ||
              e.currentTarget.name === 'clearBtn'
            )
              return;
            setShowFilterSelect(!showFilterSelect);
          }}
          placeholder={searchPlaceholder}
          width={330}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default SimpleButtonSelect;
