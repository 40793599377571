import {makeStyles} from '@material-ui/core';

export default function usePreviewClasses(props) {
  const classes = makeStyles(({typography, palette}) => ({
    main: {},
    noData: {},
    loading: {},

    container: {
      display: 'grid',
      gridGap: 20,
      gridTemplateColumns: 'repeat(2, minmax(auto, 1fr));',
      // paddingBottom: 50,
      gridRowStart: 2,
      gridAutoRows: 'minmax(300px, auto)',
    },

    sectionWrapper: {
      height: '100%',
      position: 'relative',
      border: `2px solid ${palette.grey['200']}`,
      borderRadius: 4,
      padding: 32,
    },
    sectionTitle: {
      display: 'block',
      marginBottom: 18,
      ...typography.subtitle3,
      textTransform: 'uppercase',
    },
    editBtn: {
      ...typography.subtitle2,
      position: 'absolute',
      color: palette.blue['500'],
      right: 32,
      top: 20,
      cursor: 'pointer',
    },
    row: {
      display: 'flex',
      width: '70%',
      justifyContent: 'space-between',
      marginBottom: 12,

      '& span:first-child': {
        textTransform: 'capitalize',
        width: '50%',
      },
      '& span:nth-child(2)': {
        textTransform: 'capitalize',
        flexGrow: 1,
      },
    },
  }));

  return classes(props);
}
