import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {ASSIGN_ACTION_TYPES, FEEDBACK_ACTION_TYPES} from 'alerts.console.new/api/triggeredAlertsCacheUpdate';
import {modalRouting} from 'investigationNewAlertConsole/services/investigationService';
import {isEmpty} from 'lodash';

// To avoid calling multiple times to triggered-group-by-anomaly/get-with-metrics api in case there are multiple triggered
// alerts for same anomaly group - set the key of the ReactQuery to be the anomalyId without the triggerId.
export const calculateQueryKey = (params) => {
  if (params[modalRouting.ANOMALY_ID] || params[modalRouting.TRIGGER_ID]) {
    const isAnomaly =
      params[modalRouting.ANOMALY_ID] && params[modalRouting.ANOMALY_ID] !== params[modalRouting.TRIGGER_ID];
    return isAnomaly
      ? {[modalRouting.ANOMALY_ID]: params[modalRouting.ANOMALY_ID]}
      : {[modalRouting.TRIGGER_ID]: params[modalRouting.TRIGGER_ID]};
  }
  return undefined;
};

export const syncAcksInCache = (queryKeyParams, ackParams, queryClient) => {
  const queryKey = [QUERY_KEYS.triggeredAlertGroupMetrics, queryKeyParams];
  queryClient.setQueryData(queryKey, (trigger) => {
    // If cache doesn't exist yet - no need to update it.
    if (!isEmpty(trigger)) {
      const alert = trigger.alerts?.find((a) => a.alertTriggerId === ackParams?.alertTriggerId);
      if (alert) {
        // If acknowledge removed by user and also indeed found in userIds - remove ack.
        if (!ackParams?.isAcknowledged && alert.acks?.userIds) {
          const index = alert.acks?.userIds.findIndex((user) => user.id === ackParams?.meUser?.id);
          if (index > -1) {
            alert.acks.userIds.splice(index, 1);
          }
        }
        // If acknowledged and not already found in userIds - add ack.
        else if (ackParams.isAcknowledged) {
          let index = -1;
          if (!alert.acks?.userIds) {
            alert.acks = {userIds: []};
          } else {
            index = alert.acks.userIds.findIndex((user) => user.id === ackParams.meUser?.id);
          }
          if (index === -1) {
            alert.acks.userIds.push(ackParams.meUser);
          }
        }
      }
    }

    return {
      ...trigger,
    };
  });
};

export const syncAssigneeInCache = (queryKeyParams, assigneeParams, queryClient) => {
  const queryKey = [QUERY_KEYS.triggeredAlertGroupMetrics, queryKeyParams];
  queryClient.setQueriesData(queryKey, (trigger) => {
    // If cache doesn't exist yet - no need to update it.
    if (!isEmpty(trigger)) {
      const alert = trigger.alerts?.find((a) => a.alertTriggerId === assigneeParams?.alertTriggerId);
      if (alert) {
        if (assigneeParams?.action === ASSIGN_ACTION_TYPES.ASSIGN && assigneeParams?.assignee) {
          alert.assignee = assigneeParams.assignee;
        }
        if (assigneeParams?.action === ASSIGN_ACTION_TYPES.UN_ASSIGN) {
          delete alert.assignee;
        }
      }
    }

    return {
      ...trigger,
    };
  });
};

export const syncFeedbackInCache = (queryKeyParams, feedbackAction, feedbackParams, queryClient) => {
  const queryKey = [QUERY_KEYS.triggeredAlertGroupMetrics, queryKeyParams];
  queryClient.setQueryData(queryKey, (trigger) => {
    // If cache doesn't exist yet - no need to update it.
    if (!isEmpty(trigger)) {
      const alert = trigger.alerts?.find((a) => a.alertTriggerId === queryKeyParams.triggerIdInv);
      if (alert) {
        switch (feedbackAction) {
          case FEEDBACK_ACTION_TYPES.SET:
            if (alert.feedback?.length && feedbackParams?.feedbackId) {
              const feedback = alert.feedback.find((f) => f.userId === feedbackParams.userId);
              if (feedback) {
                feedback.id = feedbackParams.feedbackId;
              }
            } else {
              alert.feedback = alert.feedback || [];
              alert.feedback.push({
                id: undefined,
                comment: feedbackParams?.comment || '',
                modifiedTime: Date.now(),
                reason: feedbackParams?.reason || '',
                type: feedbackParams?.type,
                userId: feedbackParams?.userId,
                userName: feedbackParams?.userName,
              });
            }
            break;
          case FEEDBACK_ACTION_TYPES.UPDATE:
            if (alert.feedback?.length && feedbackParams?.feedbackId) {
              const feedback = alert.feedback.find((f) => f.id === feedbackParams?.feedbackId);
              if (feedback) {
                feedback.type = feedbackParams?.type || trigger.feedback?.type;
                feedback.comment = feedbackParams?.comment || trigger.feedback?.comment || '';
                feedback.modifiedTime = Date.now();
                feedback.reason = feedbackParams?.reason || trigger.feedback?.reason || '';
              }
            }
            break;
          case FEEDBACK_ACTION_TYPES.DELETE:
            if (alert.feedback?.length) {
              if (feedbackParams?.feedbackId) {
                const index = alert.feedback.findIndex((f) => f.id === feedbackParams.feedbackId);
                if (index > -1) {
                  alert.feedback.splice(index, 1);
                }
              } else if (feedbackParams.userId) {
                const index = alert.feedback.findIndex((f) => f.userId === feedbackParams.userId);
                if (index > -1) {
                  alert.feedback.splice(index, 1);
                }
              }
            }
            break;
          default:
            break;
        }
      }
    }

    return {
      ...trigger,
    };
  });
};
