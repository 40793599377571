// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import './CubeListItem.module.scss';
import CubeItemDetails from './CubeItemDetails';
import * as selectors from '../store/selectors';
import {getTypeDetails} from '../../bc/services/bcTypes';

type PropTypes = {
  schemaItem: any,

  // connector //
  searchCubeString: string,
  expandedCube: string,
};

const EMPTY_ARRAY = [];

export default connect(
  (state) => ({
    searchCubeString: selectors.getSearchCubeString(state),
    expandedCube: selectors.getExpandedCube(state),
  }),
  {},
)(
  class CubeListItem extends PureComponent {
    props: PropTypes;

    render() {
      const {schemaItem, searchCubeString, expandedCube} = this.props;

      const {streamSchemaWrapper} = schemaItem;
      const streamSchemaId = streamSchemaWrapper.schema.id;
      const {schemaCubesWrapper} = schemaItem;

      if (schemaCubesWrapper.cubes) {
        const {cubes} = schemaCubesWrapper;
        const {topLevelCubes} = cubes;
        const tlc = topLevelCubes ? Object.values(topLevelCubes) : EMPTY_ARRAY;

        let filtered = tlc;
        if (searchCubeString !== '') {
          filtered = tlc.filter((c) => c.name.toLowerCase().indexOf(searchCubeString.toLowerCase()) >= 0);
        }
        const {type} = schemaItem;
        const ico = type ? `image-${getTypeDetails(type).iconStyle}` : null;
        if (filtered.length > 0) {
          return (
            <div styleName="root">
              <div styleName="parent-stream">
                <div styleName="name">
                  {type ? <div styleName="ico" className={ico} /> : <div styleName="ico-placeholder" />}
                  {streamSchemaWrapper.schema.name}
                </div>
              </div>

              {filtered.map((cube) => (
                <CubeItemDetails
                  key={cube.id}
                  streamSchemaId={streamSchemaId}
                  cubeDetails={cube}
                  isCubeExpanded={cube.id === expandedCube}
                />
              ))}
            </div>
          );
        }
        return null;
      }
      return null;
    }
  },
);
