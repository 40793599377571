/* global d3 */
import React, {Fragment} from 'react';
import {renderToString} from 'react-dom/server';
import L from 'topologyLeaflet/services/d3SvgOverlayService';
import * as mapService from 'topologyLeaflet/services/tpMapService';
import {palette} from 'app/styles/theme';
import TPTooltip from './TPTooltip';
import './TopologyMap.module.scss';

const POLY_HEIGHT = 24;
const POLY_WIDTH = 24;
const NODE_HEIGHT = 20;
const NODE_MIN_WIDTH = 24;
const NODE_TEXT_PADDING = 4;
const NODE_CORNER_RADIUS = 6;

const LABEL_FONT_SIZE = 14;
const CLUSTER_NUM_FONT_SIZE = 12;

const TOOLTIP_HEIGHT = 150;
const TOOLTIP_WIDTH = 270;

const TEXT_TOOLTIP_HEIGHT = 56;
const TEXT_TOOLTIP_WIDTH = 250;

const hideTooltip = (sel, proj) => {
  proj.map.scrollWheelZoom.enable();
  const tooltipContainer = sel.select('.tooltip-container');
  tooltipContainer.style('visibility', 'hidden');
};

const showTooltip = (sel, proj) => {
  proj.map.scrollWheelZoom.disable();
  const tooltipContainer = sel.select('.tooltip-container');
  tooltipContainer.style('visibility', 'visible');
};

const createForeignObjectTooltip = (sel, proj, d) => {
  let tooltipContainer = sel.select('.tooltip-container');
  let tooltipNode = d ? [d] : null;
  let tooltipText = '';
  if (d) {
    if (d.nodes && d.nodes.length) {
      tooltipNode = d.nodes;
    }

    if (d.type === 'RING' || (d.rings && d.rings.length)) {
      tooltipText = mapService.getRingTooltipText(d);
    }
  }

  if (!tooltipNode && !tooltipText) {
    tooltipContainer.style('visibility', 'hidden');
    return;
  }
  if (!tooltipContainer.node()) {
    tooltipContainer = sel.append('foreignObject').classed('tooltip-container', true);
  }

  proj.map.scrollWheelZoom.disable();
  tooltipContainer
    .on('mouseenter', () => {
      showTooltip(sel, proj);
    })
    .on('mouseleave', () => {
      hideTooltip(sel, proj);
    });

  if (tooltipText) {
    tooltipContainer.html(renderToString(<TPTooltip id="andt-tp-tooltip" text={tooltipText} />));

    tooltipContainer.attr('height', TEXT_TOOLTIP_HEIGHT + 10);
    tooltipContainer.attr('width', TEXT_TOOLTIP_WIDTH);
    tooltipContainer.attr('y', -(TEXT_TOOLTIP_HEIGHT + 10 + NODE_HEIGHT / 2));
    tooltipContainer.attr('x', -(TEXT_TOOLTIP_WIDTH / 2));
  } else {
    tooltipContainer.html(renderToString(<TPTooltip id="andt-tp-tooltip" nodes={tooltipNode} />));

    tooltipContainer.attr('height', TOOLTIP_HEIGHT + 10);
    tooltipContainer.attr('width', TOOLTIP_WIDTH);
    tooltipContainer.attr('y', -(TOOLTIP_HEIGHT + 10 + NODE_HEIGHT / 2));
    tooltipContainer.attr('x', -(TOOLTIP_WIDTH / 2));
  }

  tooltipContainer
    .style('visibility', 'visible')
    .attr(
      'transform',
      `translate(${proj.latLngToLayerPointNoRounding(d.latLng).x},${
        proj.latLngToLayerPointNoRounding(d.latLng).y
      }) scale(${1 / proj.scale})`,
    );
};

const createNode = (d, index, nodes) => {
  const g = d3.select(nodes[index]);

  g.append('rect').style('fill', mapService.getLabelFill(d));

  g.append('polygon').classed('search-outline', true);

  g.append('polygon').classed('node-item', true);

  g.append('text')
    .classed('cluster-num', true)
    .text('')
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .style('font-weight', '500')
    .style('fill', mapService.nodeStrokeByType(d));

  g.append('text')
    .classed('label-text', true)
    .text(d.name)
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .style('fill', mapService.getLabelStroke(d))
    .style('font-weight', '500');
};

const updateNode = (d, index, nodes, proj) => {
  const g = d3.select(nodes[index]);

  const border = g
    .select('rect')
    .attr('y', NODE_HEIGHT / 2 / proj.scale)
    .attr('height', NODE_HEIGHT / proj.scale)
    .attr('rx', NODE_CORNER_RADIUS / proj.scale)
    .attr('ry', NODE_CORNER_RADIUS / proj.scale)
    .attr('stroke-width', 2 / proj.scale)
    .style('stroke', d.isHighlighted ? palette.blue[500] : 'none')
    .style('fill', mapService.getLabelFill(d));

  const label = g
    .select('.label-text')
    .text(mapService.getNodeDisplayName(d))
    .attr('y', (NODE_HEIGHT + 2) / proj.scale)
    .style('font-size', LABEL_FONT_SIZE / proj.scale)
    .style('fill', mapService.getLabelStroke(d));

  g.select('.cluster-num')
    .text(mapService.getNodesClusterNumIndicator(d))
    .attr('y', -1 / proj.scale)
    .style('font-size', CLUSTER_NUM_FONT_SIZE / proj.scale)
    .style('fill', mapService.nodeStrokeByType(d));

  const poligon = g
    .select('polygon.node-item')
    .attr('points', mapService.getNodeShapePoints(d))
    .attr('stroke-width', 2)
    .attr('filter', d.isHighlighted ? 'none' : 'url(#shadow)')
    .style('stroke-linejoin', 'round')
    .style('fill', mapService.nodeFillByType(d))
    .style('stroke', mapService.nodeStrokeByType(d));

  // eslint-disable-next-line prefer-destructuring
  let status = d.status;
  if (d.nodes && d.nodes.length === 1) {
    // eslint-disable-next-line prefer-destructuring
    status = d.nodes[0].status;
  }
  if (['\u0014', '(', '\u0000\u008C'].indexOf(status) !== -1) {
    poligon.style('stroke-dasharray', '6 2');
  }

  const polyFactor = mapService.getNodeShapeFactor(d);
  const selectedFactor = mapService.getHighlightedShapeFactor(d);
  const box = label.node().getBBox();
  const width = Math.max(box.width + (NODE_TEXT_PADDING * 2) / proj.scale, NODE_MIN_WIDTH / proj.scale);
  border.attr('x', -width / 2).attr('width', width);
  poligon.attr(
    'transform',
    `translate(-${(POLY_WIDTH + polyFactor) / proj.scale / 2},-${(POLY_HEIGHT + polyFactor + 6) /
      2 /
      proj.scale}) scale(${1 / proj.scale})`,
  );

  g.select('polygon.search-outline')
    .attr('points', mapService.getNodeShapePoints(d))
    .attr('stroke-width', 2)
    .attr('filter', 'url(#shadow)')
    .style('fill', 'transparent')
    .style('stroke', palette.blue[500])
    .style('stroke-linejoin', 'round')
    .style('display', d.isHighlighted ? 'inline' : 'none')
    .attr(
      'transform',
      `translate(-${(POLY_WIDTH + polyFactor + selectedFactor) / proj.scale / 2},-${(POLY_HEIGHT +
        polyFactor +
        6 +
        selectedFactor) /
        2 /
        proj.scale}) scale(${1.2 / proj.scale})`,
    );
};

const renderNodes = (sel, proj, data, onClick) => {
  const nodesLayer = sel.select('.nodes-layer');

  const allNodes = nodesLayer.selectAll('.node').data(data, (d) => (d ? d.id : this.id));

  const entered = allNodes
    .enter()
    .append('g')
    .attr('id', (d) => d.id)
    .classed('node', true)
    .style('cursor', 'pointer')
    .on('click', (d) => {
      // d3.select(g[i]).select('text').style('fill', 'red');
      onClick(d);
    })
    .on('mouseenter', (d) => {
      createForeignObjectTooltip(sel, proj, d);
    })
    .on('mouseleave', () => {
      hideTooltip(sel, proj);
    });

  entered.each(createNode);

  entered
    .merge(allNodes)
    .attr(
      'transform',
      (d) =>
        `translate(${proj.latLngToLayerPointNoRounding(d.latLng).x},${proj.latLngToLayerPointNoRounding(d.latLng).y})`,
    )
    .each((d, i, n) => updateNode(d, i, n, proj));

  allNodes.exit().remove();
};

const processLinksData = (proj, linksData) => {
  const mapLinks = (link, index) => ({
    ...link,
    fX: proj.latLngToLayerPointNoRounding(link.fromLatLng).x,
    fY: proj.latLngToLayerPointNoRounding(link.fromLatLng).y,
    tX: proj.latLngToLayerPointNoRounding(link.toLatLng).x,
    tY: proj.latLngToLayerPointNoRounding(link.toLatLng).y,
    index,
  });

  return linksData.map(mapLinks);
};

const createRings = (d, index, nodes) => {
  const g = d3.select(nodes[index]);

  g.append('rect').style('fill', mapService.getLabelFill(d));

  g.append('circle');

  g.append('text')
    .classed('cluster-num', true)
    .text('')
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .style('font-weight', '500')
    .style('fill', mapService.nodeStrokeByType(d));

  g.append('text')
    .classed('label-text', true)
    .text(d.name)
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .style('fill', mapService.getLabelStroke(d))
    .style('font-weight', '500');
};

const updateRings = (d, index, nodes, proj) => {
  const g = d3.select(nodes[index]);

  const border = g
    .select('rect')
    .attr('y', NODE_HEIGHT / 2 / proj.scale)
    .attr('height', NODE_HEIGHT / proj.scale)
    .attr('rx', NODE_CORNER_RADIUS / proj.scale)
    .attr('ry', NODE_CORNER_RADIUS / proj.scale)
    .style('fill', mapService.getLabelFill(d));

  const label = g
    .select('.label-text')
    .text(mapService.getRingDisplayName(d))
    .attr('y', (NODE_HEIGHT + 2) / proj.scale)
    .style('font-size', LABEL_FONT_SIZE / proj.scale)
    .style('fill', mapService.getLabelStroke(d));

  g.select('.cluster-num')
    .text(mapService.getRingClusterNumIndicator(d))
    .style('font-size', CLUSTER_NUM_FONT_SIZE / proj.scale)
    .style('fill', mapService.nodeStrokeByType(d));

  g.select('circle')
    .attr('r', 10 / proj.scale)
    .attr('cx', 0)
    .attr('cy', -1 / proj.scale)
    .attr('stroke-width', 2 / proj.scale)
    .attr('filter', 'url(#shadow)')
    .style('fill', mapService.nodeFillByType(d))
    .style('stroke', mapService.nodeStrokeByType(d));

  const box = label.node().getBBox();
  const width = Math.max(box.width + (NODE_TEXT_PADDING * 2) / proj.scale, NODE_MIN_WIDTH / proj.scale);
  border.attr('x', -width / 2).attr('width', width);
};

const renderRings = (sel, proj, data, onClick) => {
  const ringsLayer = sel.select('.rings-layer');

  const allRings = ringsLayer.selectAll('.ring').data(data, (d) => (d ? d.id : this.id));

  const entered = allRings
    .enter()
    .append('g')
    .attr('id', (d) => d.id)
    .classed('ring', true)
    .style('cursor', 'pointer')
    .on('click', (d) => {
      onClick(d);
    })
    .on('mouseenter', (d) => {
      createForeignObjectTooltip(sel, proj, d);
    })
    .on('mouseleave', () => {
      hideTooltip(sel, proj);
    });

  entered.each(createRings);

  entered
    .merge(allRings)
    .attr(
      'transform',
      (d) =>
        `translate(${proj.latLngToLayerPointNoRounding(d.latLng).x},${proj.latLngToLayerPointNoRounding(d.latLng).y})`,
    )
    .each((d, i, n) => updateRings(d, i, n, proj));

  allRings.exit().remove();
};

// will need be used when with arrow heads - and will need
/*
const linkPathComplex = (link) => {
  const {
    fX, fY, tX, tY, fW, tW, endCorrection,
  } = link;
  const dX = tX - fX;
  const dY = tY - fY;

  let cfX;
  let cfY;
  let ctX;
  let ctY;
  const sX = ((NODE_HEIGHT / 2) * dX) / dY;
  if (Math.abs(sX) < fW / 2) {
    cfX = fX - Math.abs(sX) * (fX > tX ? 1 : -1);
    cfY = fY - (NODE_HEIGHT / 2) * (fY > tY ? 1 : -1);
  }
  else {
    const sY = ((fW / 2) * dY) / dX;
    cfX = fX - (fW / 2) * (fX > tX ? 1 : -1);
    cfY = fY - Math.abs(sY) * (fY > tY ? 1 : -1);
  }

  const DEFAULT_END_CORRECTION = 6;
  const distance = Math.hypot(dX, dY);
  const ec = endCorrection || DEFAULT_END_CORRECTION;
  const ecX = (ec * dX) / distance;
  const ecY = (ec * dY) / distance;
  if (Math.abs(sX) < tW / 2) {
    ctX = tX + Math.abs(sX) * (fX > tX ? 1 : -1) - ecX;
    ctY = tY + (NODE_HEIGHT / 2) * (fY > tY ? 1 : -1) - ecY;
  }
  else {
    const sY = ((tW / 2) * dY) / dX;
    ctX = tX + (tW / 2) * (fX > tX ? 1 : -1) - ecX;
    ctY = tY + Math.abs(sY) * (fY > tY ? 1 : -1) - ecY;
  }
  return `M ${cfX},${cfY} L ${ctX},${ctY}`;
};
*/

const linkPathSimple = (link) => {
  const {fX, fY, tX, tY} = link;
  return `M ${fX},${fY} L ${tX},${tY}`;
};

const updateLink = (d, index, links, proj, ignoreAnomalous) => {
  const g = d3.select(links[index]);

  const link = g
    .select('path')
    .style('stroke-width', 2 / proj.scale)
    .style('opacity', 0.5)
    .style('stroke', mapService.getLinkStroke(d, ignoreAnomalous));

  if (d.isAnomalous && !ignoreAnomalous) {
    link.style('stroke-dasharray', `${(6 / proj.scale).toString()} ${(4 / proj.scale).toString()}`).style('opacity', 1);
  }
};

const renderLinks = (sel, proj, data, linkLayerClass = '.links-layer', ignoreAnomalous = false) => {
  const linksLayer = sel.select(linkLayerClass);

  const processedData = processLinksData(proj, data);
  const allLinks = linksLayer.selectAll('.link').data(processedData, (d) => (d ? d.id : this.id));

  const entered = allLinks
    .enter()
    .append('g')
    .attr('id', (d) => d.id)
    .attr('to-id', (d) => d.toId)
    .attr('from-id', (d) => d.fromId)
    .classed('link', true)
    .style('cursor', 'pointer');

  entered
    .append('path')
    .classed('visual-path', true)
    .style('fill', 'none')
    // .attr('marker-end', 'url(#regular-arrow-head)') // simplePath is without arrows
    .attr('d', linkPathSimple);

  entered.merge(allLinks).each((d, i, n) => updateLink(d, i, n, proj, ignoreAnomalous));

  allLinks.exit().remove();
};

const initOverlays = (sel) => {
  const container = sel.append('g').classed('container', true);
  container.append('g').classed('links-layer', true);
  container.append('g').classed('rings-links-layer', true);
  container.append('g').classed('nodes-layer', true);
  container.append('g').classed('rings-layer', true);
};

const ArrowMarker = (props: {name: String, shift: number}) => (
  <marker
    id={`${props.name}-arrow-head`}
    viewBox="0 -8 36 36"
    refX={props.shift !== undefined ? props.shift : 8}
    refY="0"
    markerWidth="16"
    markerHeight="16"
    orient="auto"
  >
    <path d="M 8, 0 L 0,-8 L 16,0 L 0,8 Z" fill={palette.black[500]} stroke="none" />
  </marker>
);

type PropTypes = {
  nodes?: Array,
  links?: Array,
  rings?: Array,
  ringLinks?: Array,
  filterOptions?: Object,
  searchItem?: Object,
  onNodeClick?: Function,
  onRingClick?: Function,
  getMapRef: Function,
};

export default class D3TopologyMap extends React.Component {
  props: PropTypes;

  static defaultProps = {
    nodes: [],
    links: [],
    rings: [],
    ringLinks: [],
    filterOptions: {
      zoom: true,
      bounds: true,
      anomaly: false,
    },
    searchItem: null,
    onNodeClick: () => {},
    onRingClick: () => {},
  };

  componentDidMount() {
    // create map
    this.map = L.map('topology-map', {
      center: mapService.DEFAULT_MAP_CENTER,
      zoom: mapService.DEFAULT_MAP_ZOOM,
      minZoom: mapService.DEFAULT_MAP_ZOOM,
      maxZoom: mapService.MAX_MAP_ZOOM,
      zoomSnap: mapService.ZOOM_DELTA,
      zoomDelta: mapService.ZOOM_DELTA,
      maxBounds: [[54, 8.5], [49.5, 1.5]],
      maxBoundsViscosity: 1.0,
    });
    this.itemsOverlay = null;
    this.projection = null;
    this.selection = null;

    /* eslint-disable max-len */
    const mapBox = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      maxZoom: 18,
      id: 'mapbox/light-v10', // {username}/{style_id}
      tileSize: 512,
      opacity: 0.95,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoiZWxpYW5vZG90IiwiYSI6ImNrYnRiZWVpeTA4OGwyc242dDEzdXVycGYifQ.6rXsWgI19GRcPkzbUiYSGw',
    });

    this.map.attributionControl.setPrefix(
      'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    );

    this.map.zoomControl.setPosition('bottomright');
    /* eslint-enable */

    this.props.getMapRef(this.map);
    mapBox.addTo(this.map);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.nodes.length && this.props.nodes.length && !this.itemsOverlay) {
      this.itemsOverlay = L.d3SvgOverlay(
        (sel, proj) => {
          if (sel.select('.container').empty()) {
            initOverlays(sel);
          }
          this.projection = proj;
          this.selection = sel;
          const {nodes, links, rings, ringLinks, filterOptions, searchItem} = this.props;

          const zoom = proj.getZoom();
          const bounds = mapService.getBoundsFromLeafletBoundsObject(proj.getBounds());
          const filteredNodes = mapService
            .filterNodes(zoom, bounds, nodes, filterOptions, searchItem)
            .sort(mapService.sortNodesByType);
          const filteredLinks = mapService.filterLinks(zoom, bounds, links, filteredNodes, filterOptions, searchItem);
          const filteredRings = mapService
            .filterRings(zoom, bounds, rings, filterOptions, searchItem)
            .sort(mapService.sortRingsByType);
          const filteredRingLinks = mapService.filterRingLinks(zoom, bounds, ringLinks, filterOptions, searchItem);

          renderNodes(sel, proj, filteredNodes, this.onNodeClicked);
          renderLinks(sel, proj, filteredLinks);
          renderRings(sel, proj, filteredRings, this.onRingClick);
          renderLinks(sel, proj, filteredRingLinks, '.rings-links-layer', true);
        },
        {zoomHide: false},
      );

      this.itemsOverlay.addTo(this.map);
    }
    if (this.projection && this.selection) {
      const {nodes, links, rings, ringLinks, filterOptions, searchItem} = this.props;

      const zoom = this.projection.getZoom();
      const bounds = mapService.getBoundsFromLeafletBoundsObject(this.projection.getBounds());
      const filteredNodes = mapService
        .filterNodes(zoom, bounds, nodes, filterOptions, searchItem)
        .sort(mapService.sortNodesByType);
      const filteredLinks = mapService.filterLinks(zoom, bounds, links, filteredNodes, filterOptions, searchItem);
      const filteredRings = mapService
        .filterRings(zoom, bounds, rings, filterOptions, searchItem)
        .sort(mapService.sortRingsByType);
      const filteredRingLinks = mapService.filterRingLinks(zoom, bounds, ringLinks, filterOptions, searchItem);

      renderNodes(this.selection, this.projection, filteredNodes, this.onNodeClicked);
      renderLinks(this.selection, this.projection, filteredLinks);
      renderRings(this.selection, this.projection, filteredRings, this.onRingClick);
      renderLinks(this.selection, this.projection, filteredRingLinks, '.rings-links-layer', true);
    }
  }

  onNodeClicked = (d) => {
    const {onNodeClick} = this.props;
    hideTooltip(this.selection, this.projection);
    onNodeClick(d);
  };

  onRingClick = (d) => {
    const {onRingClick} = this.props;
    hideTooltip(this.selection, this.projection);
    onRingClick(d);
  };

  render() {
    return (
      <Fragment>
        <div id="topology-map" />
        <svg styleName="hide-svg-defs">
          <defs>
            <ArrowMarker name="regular" />
            <filter id="shadow" x="-20%" y="-20%" width="200%" height="200%">
              <feDropShadow dx="0" dy="1" stdDeviation="3" floodColor={palette.black[500]} floodOpacity="0.5" />
            </filter>
          </defs>
        </svg>
      </Fragment>
    );
  }
}
