/* eslint-disable */

import {forEach, has, map, find, escapeRegExp, trimStart} from 'lodash';

import cleanExpressionTree from './cleanExpressionTree';
import cleanNodeArray from './cleanNodeArray';
import treeVisitor from './treeVisitor';

const varPrefix = '$';

function getReplaceReg(val) {
  return new RegExp(escapeRegExp(val), 'g');
}

function getDefiningProperty(selector) {
  if (!selector) {
    return null;
  }
  return find(selector.expressions, {type: 'property'});
}

function parseNodeSelectors(nodeSearchObject, selectorsFilter) {
  forEach(nodeSearchObject.expression, function(ex) {
    if (ex.type === 'selector') {
      const selector = find(selectorsFilter.selectors, {name: trimStart(ex.value, varPrefix)});
      const property = getDefiningProperty(selector);
      ex.type = 'property';
      ex.key = property.key;
      ex.value = selector.selectedValue && selector.selectedValue.value ? selector.selectedValue.value : '';
      ex.isExact = !selector.selectedValue.isAll;
    } else if (ex.type === 'search') {
      forEach(selectorsFilter.selectors, function(selector) {
        ex.value = ex.value.replace(
          getReplaceReg(varPrefix + selector.name),
          selector.selectedValue && selector.selectedValue.value ? selector.selectedValue.value : '',
        );
      });
    }
  });
}

function parseNode(node, selectorsFilter) {
  if (!selectorsFilter) {
    return node;
  }

  treeVisitor(node, 'children', function(node) {
    if (has(node, 'searchObject')) {
      parseNodeSelectors(node.searchObject, selectorsFilter);
    }
  });
  return node;
}

function getApiTreeForNode(node, treeModel, selectorsFilter, measurementsContext) {
  const root = selectorsFilter ? parseNode(cleanExpressionTree(node), selectorsFilter) : cleanExpressionTree(node);
  return {
    name: node === treeModel.expressionTree.root ? treeModel.name : null,
    displayOnly: treeModel.displayOnly,
    excludeComposites: treeModel.excludeComposites,
    includeCubes: treeModel.includeCubes,
    mtype: treeModel.mtype,
    title: treeModel.title,
    namingSchema: node === treeModel.expressionTree.root ? treeModel.namingSchema : null,
    filter: cleanExpressionTree(treeModel.filter),
    expressionTree: {
      root,
    },
    scalarTransforms: cleanNodeArray(treeModel.scalarTransforms),
    context: map(measurementsContext, function(key, val) {
      return key && val ? `${val?.toString()}=${key?.toString()}` : ``;
    }).join('.'),
  };
}

export default getApiTreeForNode;
