// @flow
import React, {useEffect, useMemo, useState} from 'react';
import './NothingToShow.module.scss';
import Button, {COLORS} from 'common/componentsV2/Button';
import {useSelector} from 'react-redux';
import {resolutionTypes} from 'metrics/services/metricsService';
import {useField} from 'react-final-form';
import {pick} from 'lodash';
import {getSelectedExpressionId, getExpressionTrees} from 'metrics/store/selectors';
import * as dateRangeService from 'common/utils/dateRangeService';
import {getTimeZoneName, getPredefinedDateUserSetting} from 'profile/store/selectors';

type PropTypes = {
  validationId: string,
  validationMessage: string,
  isAlert: boolean,
};

const NothingToShow = ({validationId, validationMessage, isAlert}: PropTypes) => {
  const {
    input: {value: timeScale, onChange: changeResolution},
  } = useField('timeScale');

  const {
    input: {value: dateRange, onChange: changeDateRange},
  } = useField('dateRange');

  const selectedTreeId = useSelector(getSelectedExpressionId);
  const expressionTrees = useSelector(getExpressionTrees);
  const timeZoneName = useSelector(getTimeZoneName);
  const predefinedDateUserSetting = useSelector(getPredefinedDateUserSetting);

  const {
    input: {value, onChange},
  } = useField('multiPreviewOptions');

  const {
    input: {value: sortAlert, onChange: onChangeSortAlert},
  } = useField('sort');

  const multiPreviewOptions = useMemo(
    () =>
      expressionTrees.reduce(
        (acc, expressionTree) => ({
          ...acc,
          [expressionTree.id]: value[expressionTree.id] || value['*'],
        }),
        value,
      ),
    [expressionTrees, value],
  );

  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    switch (String(validationId)) {
      case '26':
        //  failure key of no data
        setMessage('Try to refine your search expression or changing the display settings');
        setTitle('Nothing to show');
        break;
      case '53':
        //  failure key of too much data
        setMessage('Try to refine your search expression or changing the display settings');
        setTitle('Too much data');
        break;
      case '25':
        setTitle('No Metrics Were Found');
        setMessage('Try to expand your expression');
        break;
      default:
        setMessage(validationMessage);
        setTitle('Nothing to show');
    }
  }, [validationId]);

  const onSortByClicked = () => {
    if (isAlert) {
      onChangeSortAlert('alphanumeric');
    } else {
      onChange({
        ...multiPreviewOptions,
        [selectedTreeId]: {...multiPreviewOptions[selectedTreeId], sort: 'alphanumeric'},
      });
    }
  };

  const onTimescaleClicked = (val) => {
    changeResolution(val.value);
  };

  const onTimeframeClicked = (val) => {
    changeDateRange(dateRangeService.getDateValue(val.value, false, timeZoneName));
  };

  const newResolution = useMemo(() => {
    const resolutionTypesArr = Object.keys(resolutionTypes);
    const index = resolutionTypesArr.findIndex((item) => item === timeScale);
    if (!isAlert && !!index && Object.keys(resolutionTypes).length >= index + 1) {
      return Object.values(resolutionTypes)[index + 1];
    }

    return null;
  }, [timeScale]);

  const newDateRange = useMemo(() => {
    const userDates = Object.values(pick(dateRangeService.rangeTypes, predefinedDateUserSetting.preDefinedDates));
    const preDefinedDates = (!!userDates.length && userDates) || dateRangeService.predefinedOptions;
    const optionsUserSettings = Object.values(dateRangeService.rangeTypes).filter((o) =>
      preDefinedDates.some((date) => date.key === o.key),
    );
    const index = optionsUserSettings.findIndex((item) => item.value === dateRange.constRange);
    if (!!index && index - 1 > -1) {
      return optionsUserSettings[index - 1];
    }
    return null;
  }, [predefinedDateUserSetting, dateRange]);

  const renderButtons = () => {
    switch (String(validationId)) {
      case '26':
      case '53':
        return (
          <div styleName="try-buttons-container">
            <div styleName="try-these">Try These:</div>
            <div styleName="buttons">
              {(sortAlert !== 'alphanumeric' ||
                (value && multiPreviewOptions[selectedTreeId].sort !== 'alphanumeric')) && (
                <div styleName="button-container">
                  <div>Sort by:</div>
                  <Button
                    icon="icn-general16-timescale"
                    text="Alphanumeric"
                    colorSchema={COLORS.BLUE_500}
                    onClick={onSortByClicked}
                  />
                </div>
              )}
              {newResolution && !isAlert && (
                <div styleName="button-container">
                  <div>Timescale:</div>
                  <Button
                    icon="icn-general16-timescale"
                    text={newResolution.text}
                    colorSchema={COLORS.BLUE_500}
                    onClick={() => onTimescaleClicked(newResolution)}
                  />
                </div>
              )}
              {newDateRange && isAlert && (
                <div styleName="button-container">
                  <div>Time Frame:</div>
                  <Button
                    icon="icn-general16-timescale"
                    text={newDateRange.text}
                    colorSchema={COLORS.BLUE_500}
                    onClick={() => onTimeframeClicked(newDateRange)}
                  />
                </div>
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div styleName="container">
      <div styleName="title">{title}</div>
      <div styleName="message">{message}</div>
      {((newDateRange && isAlert) || (newResolution && !isAlert) || sortAlert !== 'alphanumeric') && renderButtons()}
    </div>
  );
};

export default NothingToShow;
