import {EVENT_STREAM_FIELDS, EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';
import {POLLING_INTERVAL_OPTIONS} from 'dataCollectors/services/scheduleService';
import {rangeTypes, getDateValue} from 'common/utils/dateRangeService';
import {guessTimeZone} from 'dataCollectors/utils/timeZone';
import {STREAMS_FAMILY_TYPES, DEFAULT_TIME_ZONE} from 'dataCollectors/services/dataCollectorsService';

const {
  STREAM_FAMILY,
  STREAM_NAME,
  STREAM_OWNER,
  STREAM_DESCRIPTION,
  STREAM_ID,
  SOURCE_ID,
  TYPE,
  SOURCE_NAME,
  DATABASE_NAME,
  MODIFIER,
  MODIFIER_TIME,
  CREATOR,
  CREATE_TIME,
  STREAM_STATE,
  STREAM_STATUS,
  SCHEMA_NAME,
  TABLE_NAME,
  EVENT_TITLE,
  EVENT_DESCRIPTION,
  EVENT_TYPE,
  EVENT_ACTION,
  EVENT_SOURCE,
  EVENT_CATEGORY,
  EVENT_START_TIME,
  EVENT_END_TIME,
  EVENT_PROPERTIES,
  EVENT_PROGRESS_INDICATOR,
  EVENT_KEY,
  POLLING_INTERVAL,
  HISTORY_DATE_RANGE,
  TIMEZONE,
  NOTIFY_ON_NO_DATA,
  NOTIFY_ON_ERRORS,
  NOTIFY_RECIPIENTS,
  FILE_NAME,
  FILE_FORMAT,
  START_TIME_DEFINITION,
  END_TIME_DEFINITION,
  STREAM_PAUSED,
  PROJECT_ID,
  DATA_SET,
  CUSTOM_QUERY,
} = EVENT_STREAM_FIELDS;

const {LOCAL_FILE, PSQL, BIG_QUERY} = EVENT_STREAM_TYPES;

function getPsqlDefaultValues(stream, source, me) {
  const defaultValues = {
    [STREAM_FAMILY.id]: STREAMS_FAMILY_TYPES.event,
    [STREAM_ID.id]: stream?.id,
    [STREAM_NAME.id]: stream?.name || '',
    [STREAM_STATE.id]: stream?.state || '',
    [STREAM_PAUSED.id]: stream?.paused || false,
    [STREAM_STATUS.id]: stream?.status || '',
    [STREAM_OWNER.id]: stream?.owner || me.id || {},
    [STREAM_DESCRIPTION.id]: stream?.description || '',
    [SCHEMA_NAME.id]: stream?.schemaName || '',
    [TABLE_NAME.id]: stream?.tableName || '',

    [TYPE.id]: source?.type || '',
    [SOURCE_ID.id]: stream?.dataSourceId || source?.id,
    [SOURCE_NAME.id]: source?.name || '',
    [DATABASE_NAME.id]: source?.[DATABASE_NAME.id] || '',
    [FILE_NAME.id]: source?.fileName || '',

    [CREATOR.id]: stream?.creator || '',
    [CREATE_TIME.id]: stream?.createTime || '',
    [MODIFIER.id]: stream?.modifier || '',
    [MODIFIER_TIME.id]: stream?.modifyTime || '',

    [EVENT_TITLE.id]: stream?.eventTitle || null,
    [EVENT_DESCRIPTION.id]: stream?.eventDescription || null,
    [EVENT_TYPE.id]: stream?.eventType || null,
    [EVENT_ACTION.id]: stream?.eventAction || null,
    [EVENT_SOURCE.id]: stream?.eventSource || null,
    [EVENT_START_TIME.id]: stream?.eventStart || null,
    [EVENT_END_TIME.id]: stream?.eventEnd || null,
    [EVENT_PROPERTIES.id]: stream?.eventProperties || null,
    [EVENT_PROGRESS_INDICATOR.id]: stream?.eventProgressIndicator || null,
    [EVENT_KEY.id]: stream?.eventKey || null,

    [EVENT_CATEGORY.id]: stream?.[EVENT_CATEGORY.id] || null,

    [POLLING_INTERVAL.id]: stream?.pollingInterval || POLLING_INTERVAL_OPTIONS.daily.value,
    [HISTORY_DATE_RANGE.id]:
      stream?.[HISTORY_DATE_RANGE.id] || getDateValue({constRange: rangeTypes.d1.key}, true, DEFAULT_TIME_ZONE),
    [TIMEZONE.id]:
      guessTimeZone(stream?.[TIMEZONE.id]) || guessTimeZone(me.timeZone) || guessTimeZone(DEFAULT_TIME_ZONE),

    [NOTIFY_ON_NO_DATA.id]: stream ? stream[NOTIFY_ON_NO_DATA.id] : '6h',
    [NOTIFY_ON_ERRORS.id]: stream ? stream[NOTIFY_ON_ERRORS.id] : true,
    [NOTIFY_RECIPIENTS.id]: stream?.owner || me.id || {},
  };

  return defaultValues;
}

function getBigQueryDefaultValues(stream, source, me) {
  const defaultValues = {
    [STREAM_FAMILY.id]: STREAMS_FAMILY_TYPES.event,
    [STREAM_ID.id]: stream?.id,
    [STREAM_NAME.id]: stream?.name || '',
    [STREAM_STATE.id]: stream?.state || '',
    [STREAM_PAUSED.id]: stream?.paused || false,
    [STREAM_STATUS.id]: stream?.status || '',
    [STREAM_OWNER.id]: stream?.owner || me.id || {},
    [STREAM_DESCRIPTION.id]: stream?.description || '',
    // [SCHEMA_NAME.id]: stream?.schemaName || null,
    [TABLE_NAME.id]: stream?.tableName || '',

    [TYPE.id]: source?.type || '',
    [SOURCE_ID.id]: stream?.dataSourceId || source?.id,
    [SOURCE_NAME.id]: source?.name || '',
    // [DATABASE_NAME.id]: source?.[DATABASE_NAME.id] || null,
    [FILE_NAME.id]: source?.fileName || '',

    [CREATOR.id]: stream?.creator || '',
    [CREATE_TIME.id]: stream?.createTime || '',
    [MODIFIER.id]: stream?.modifier || '',
    [MODIFIER_TIME.id]: stream?.modifyTime || '',

    [EVENT_TITLE.id]: stream?.eventTitle || null,
    [EVENT_DESCRIPTION.id]: stream?.eventDescription || null,
    [EVENT_TYPE.id]: stream?.eventType || null,
    [EVENT_ACTION.id]: stream?.eventAction || null,
    [EVENT_SOURCE.id]: stream?.eventSource || null,
    [EVENT_START_TIME.id]: stream?.eventStart || null,
    [EVENT_END_TIME.id]: stream?.eventEnd || null,
    [EVENT_PROPERTIES.id]: stream?.eventProperties || null,
    [EVENT_PROGRESS_INDICATOR.id]: stream?.eventProgressIndicator || null,
    [EVENT_KEY.id]: stream?.eventKey || null,

    [EVENT_CATEGORY.id]: stream?.[EVENT_CATEGORY.id] || null,

    [PROJECT_ID.id]: stream?.[PROJECT_ID.id] || null,
    [DATA_SET.id]: stream?.[DATA_SET.id] || null,
    // [TABLE_ID.id]: stream?.[TABLE_ID.id] || null,

    [POLLING_INTERVAL.id]: stream?.pollingInterval || POLLING_INTERVAL_OPTIONS.daily.value,
    [HISTORY_DATE_RANGE.id]:
      stream?.[HISTORY_DATE_RANGE.id] || getDateValue({constRange: rangeTypes.d1.key}, true, DEFAULT_TIME_ZONE),
    [TIMEZONE.id]:
      guessTimeZone(stream?.[TIMEZONE.id]) || guessTimeZone(me.timeZone) || guessTimeZone(DEFAULT_TIME_ZONE),

    [NOTIFY_ON_NO_DATA.id]: stream ? stream[NOTIFY_ON_NO_DATA.id] : '6h',
    [NOTIFY_ON_ERRORS.id]: stream ? stream[NOTIFY_ON_ERRORS.id] : true,
    [NOTIFY_RECIPIENTS.id]: stream?.owner || me.id || {},
    [CUSTOM_QUERY.id]: stream?.customQuery || false,
  };
  return defaultValues;
}
function getFileUploadDefaultValues(stream, source, me, analyzeData) {
  const defaultValues = {
    [STREAM_FAMILY.id]: STREAMS_FAMILY_TYPES.event,
    [STREAM_ID.id]: stream?.id,
    [STREAM_NAME.id]: stream?.name || '',
    [STREAM_STATE.id]: stream?.state || '',
    [STREAM_STATUS.id]: stream?.status || '',
    [STREAM_OWNER.id]: stream?.owner || me.id || {},
    [STREAM_DESCRIPTION.id]: stream?.description || '',

    [TYPE.id]: source?.type || '',
    [SOURCE_ID.id]: stream?.dataSourceId || source?.id,
    [SOURCE_NAME.id]: 'File Upload',

    [CREATOR.id]: stream?.creator || '',
    [CREATE_TIME.id]: stream?.createTime || '',
    [MODIFIER.id]: stream?.modifier || '',
    [MODIFIER_TIME.id]: stream?.modifyTime || '',

    [EVENT_TITLE.id]: stream?.eventTitle || null,
    [EVENT_DESCRIPTION.id]: stream?.eventDescription || null,
    [EVENT_TYPE.id]: stream?.eventType || null,
    [EVENT_ACTION.id]: stream?.eventAction || null,
    [EVENT_SOURCE.id]: stream?.eventSource || null,
    [EVENT_START_TIME.id]: stream?.eventStart || null,
    [EVENT_END_TIME.id]: stream?.eventEnd || null,
    [EVENT_PROPERTIES.id]: stream?.eventProperties || null,
    [EVENT_KEY.id]: stream?.eventKey || null,
    [EVENT_CATEGORY.id]: stream?.[EVENT_CATEGORY.id] || null,

    [FILE_FORMAT.id]:
      stream && stream[FILE_FORMAT.id] && Object.keys(stream[FILE_FORMAT.id]).length > 0
        ? stream[FILE_FORMAT.id]
        : analyzeData?.[FILE_FORMAT.id] || {},

    [START_TIME_DEFINITION.id]:
      stream && stream[START_TIME_DEFINITION.id] && Object.keys(stream[START_TIME_DEFINITION.id]).length > 0
        ? stream[START_TIME_DEFINITION.id]
        : analyzeData?.[START_TIME_DEFINITION.id] || null,

    [END_TIME_DEFINITION.id]:
      stream && stream[END_TIME_DEFINITION.id] && Object.keys(stream[END_TIME_DEFINITION.id]).length > 0
        ? stream[END_TIME_DEFINITION.id]
        : analyzeData?.[END_TIME_DEFINITION.id] || null,

    [HISTORY_DATE_RANGE.id]:
      stream?.[HISTORY_DATE_RANGE.id] || getDateValue({constRange: rangeTypes.d1.key}, true, DEFAULT_TIME_ZONE),

    [TIMEZONE.id]:
      guessTimeZone(stream?.[TIMEZONE.id]) || guessTimeZone(me.timeZone) || guessTimeZone(DEFAULT_TIME_ZONE),

    [NOTIFY_ON_NO_DATA.id]: stream ? stream[NOTIFY_ON_NO_DATA.id] : '6h',
    [NOTIFY_ON_ERRORS.id]: stream ? stream[NOTIFY_ON_ERRORS.id] : true,
    [NOTIFY_RECIPIENTS.id]: stream?.owner || me.id || {},
  };

  return defaultValues;
}

export default function useDefaultValues(stream, source, me, analyzeData) {
  switch (source?.type) {
    case LOCAL_FILE:
      return getFileUploadDefaultValues(stream, source, me, analyzeData);
    case BIG_QUERY:
      return getBigQueryDefaultValues(stream, source, me);
    case PSQL:
    default:
      return getPsqlDefaultValues(stream, source, me);
  }
}
