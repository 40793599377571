export function getQueryParamsUrl(params = {}, url = '') {
  const format = (str, key) => {
    if (params[key]) {
      return `${str + (str === url ? '?' : '&')}${key}=${params[key]}`;
    }
    return str;
  };

  return Object.keys(params).reduce(format, url);
}

export default getQueryParamsUrl;
