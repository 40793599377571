import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import {palette} from 'app/styles/theme';
import DimensionsBreakdownRow from './DimensionsBreakdownRow';

const DEFAULT_SIZE = 3;
const MAX_SIZE = 6;

const Tokens = ({tokens, filters, onSelect}: PropTypes) => {
  const style = {
    metricsContainer: {
      marginTop: 8,
      position: 'relative',
      left: 24,
      paddingRight: 24,
      paddingLeft: 1,
      overflowY: 'scroll',
    },
    panelRootStyle: {
      flexShrink: 0,
      padding: 0,
      border: 'none',
      marginTop: 8,
      marginBottom: 16,
    },
    metricLabel: {
      display: 'flex',
      marginTop: 5,
    },
  };

  const [isOpen, setOpen] = useState(false);
  const [visibleDimensionsArray, setVisibleDimensionsArray] = useState([]);
  const [hiddenDimensionsArray, setHiddenDimensionsArray] = useState([]);

  useEffect(() => {
    const dimensionsArray = Object.keys(tokens);
    setVisibleDimensionsArray(dimensionsArray.slice(0, DEFAULT_SIZE));
    setHiddenDimensionsArray(dimensionsArray.slice(DEFAULT_SIZE));
  }, [tokens]);

  const handleSetHide = (index) => {
    if (visibleDimensionsArray.length === 1) {
      return;
    }
    const removedObj = visibleDimensionsArray[index];
    setVisibleDimensionsArray(visibleDimensionsArray.slice(0, index).concat(visibleDimensionsArray.slice(index + 1)));
    setHiddenDimensionsArray([removedObj, ...hiddenDimensionsArray]);
  };

  const handleSetVisible = (index) => {
    if (visibleDimensionsArray.length >= MAX_SIZE) {
      return;
    }
    const removedObj = hiddenDimensionsArray[index];
    setHiddenDimensionsArray(hiddenDimensionsArray.slice(0, index).concat(hiddenDimensionsArray.slice(index + 1)));
    setVisibleDimensionsArray([...visibleDimensionsArray, removedObj]);
  };

  return (
    <div>
      {visibleDimensionsArray.map((tokenKey, index) => (
        <DimensionsBreakdownRow
          isVisible
          key={tokenKey}
          token={tokens[tokenKey]}
          filters={filters}
          onSelect={onSelect}
          onChangeVisibility={() => handleSetHide(index)}
          visibilityTooltip={
            visibleDimensionsArray.length === 1 ? "Can't hide. At least one dimension needs to be selected" : ''
          }
        />
      ))}
      {hiddenDimensionsArray.length > 0 && (
        <ExpandableBox
          key="hiddenDimensions"
          automationId="incWhatGroup"
          nodeLabel={
            <TypographyBox variant="subtitle1" color={palette.gray['400']} mt={0.5}>
              More Dimensions
            </TypographyBox>
          }
          expanded={isOpen}
          onChange={setOpen}
          rootStyle={style.panelRootStyle}
        >
          {isOpen &&
            hiddenDimensionsArray.map((tokenKey, index) => (
              <DimensionsBreakdownRow
                isVisible={false}
                key={tokenKey}
                token={tokens[tokenKey]}
                filters={filters}
                onSelect={onSelect}
                onChangeVisibility={() => handleSetVisible(index)}
                visibilityTooltip={
                  visibleDimensionsArray.length >= 6 ? "Can't show. Six dimensions are the maximum to be selected" : ''
                }
              />
            ))}
        </ExpandableBox>
      )}
    </div>
  );
};

Tokens.propTypes = {
  tokens: PropTypes.objectOf(PropTypes.any).isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Tokens;
