// @flow
import React, {Fragment, PureComponent} from 'react';
import './JsonEditorModal.module.scss';

const GUIDELINES = [
  <Fragment>You can use a complete event as a sample or a subset of an event</Fragment>,
  <Fragment>The input must be a valid JSON to enable parsing</Fragment>,
  <Fragment>The JSON structure must match the structure of the messages that are expected to flow later on</Fragment>,
  <Fragment>The field must exist in the sample JSON</Fragment>,
  <Fragment>Fields cannot be values within arrays</Fragment>,
];

export default class JsonGuideLines extends PureComponent {
  render() {
    return (
      <div styleName="guidelines-wrapper">
        {GUIDELINES.map((val, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} styleName="items-wrapper">
            <div styleName="item-num">{index + 1}</div>
            <div styleName="item-text">{val}</div>
          </div>
        ))}
      </div>
    );
  }
}
