import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  getBucketStartTimeEnabled,
  getIsTimelineEnabled,
  getStaticNoDataTriageEnabled,
  getTimelineUserEventsStartTimeThreshold,
} from 'profile/store/selectors';
import {copyToClipBoard} from 'common/store/actions';
import {setIncidentFilterNewAlertConsole, setInvestigationSettings} from 'investigationNewAlertConsole/store/actions';
import {
  ALERT_TYPES,
  calculateTimelineView,
  getInvestigationModalTabs,
  INVESTIGATION_MODAL_TABS,
  modalRouting,
} from 'investigationNewAlertConsole/services/investigationService';
import {BooleanParam, StringParam, useQueryParams} from 'use-query-params';
import fetchGroupAlertsWithMetricsProvider from 'investigationNewAlertConsole/api/fetchGroupAlertsWithMetricsProvider';
import {makeStyles} from '@material-ui/core/styles';
import {setMeasures} from 'investigationNewAlertConsole/api/utils';
import TriageAlertHeader from 'investigationNewAlertConsole/components/header/TriageAlertHeader';
import TriageTabsBar from 'investigationNewAlertConsole/components/tabs/TriageTabsBar';
import OverviewTab from 'investigationNewAlertConsole/components/overview/OverviewTab';
import IncidentTab from 'investigationNewAlertConsole/components/incident/IncidentTab';
import CorrelationsTab from 'investigationNewAlertConsole/components/correlations/CorrelationsTab';
import TimelineTab from 'investigationNewAlertConsole/components/timeline/TimelineTab';
import TimelineTabIncremented from 'investigationNewAlertConsole/components/timeline/TimelineTabIncremented';
import TriageHeaderActionsBar from 'investigationNewAlertConsole/components/header/TriageHeaderActionsBar';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';
import PageLayout from 'common/componentsV2/PageLayout';
import fetchTimelineProvider from 'investigationNewAlertConsole/api/fetchTimelineProvider';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';

import './Triage.module.scss';

const useStyles = makeStyles(() => ({
  tabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 21,
  },
  loader: {
    position: 'absolute',
    top: '20%',
    left: '40%',
  },
}));

const Triage = () => {
  const classes = useStyles();
  const firstUpdate = useRef(true);
  const dispatch = useDispatch();
  const userRollupTime = useSelector(getTimelineUserEventsStartTimeThreshold);
  const startBucketMode = useSelector(getBucketStartTimeEnabled);
  const isTimelineEnabled = useSelector(getIsTimelineEnabled);
  const isStaticNoDataTriageEnabled = useSelector(getStaticNoDataTriageEnabled);

  const [selectedTabId, setSelectedTabId] = useState(INVESTIGATION_MODAL_TABS.overview.id);
  const [availableTabs, setAvailableTabs] = useState([]);
  const [timelineView, setTimelineView] = useState([]);

  const [queryParams, setQueryParams] = useQueryParams({
    [modalRouting.ANOMALY_ID]: StringParam,
    [modalRouting.TRIGGER_ID]: StringParam,
    [modalRouting.INVESTIGATION_MODAL]: BooleanParam,
  });
  const anomalyId = queryParams.anomalyIdInv;
  const triggerId = queryParams.triggerIdInv;

  const triggeredAlertResult = fetchGroupAlertsWithMetricsProvider(queryParams)?.useQueryGetGroupAlertsWithMetrics();
  const isTriggeredAlertLoading = triggeredAlertResult?.isLoading;
  const alertsGroup = fetchGroupAlertsWithMetricsProvider(queryParams)?.useQueryGetGroupAlertsWithMetrics()?.data;

  const timeLineProvider = fetchTimelineProvider(triggerId).useQuery({queryParams, userRollupTime, startBucketMode});
  const timeline = timeLineProvider?.data;

  const usersProvider = fetchUsersProvider()?.useQuery();
  const users = usersProvider?.data;

  const trigger = useMemo(() => {
    let tmpTrigger;
    if (triggeredAlertResult?.data?.alerts?.length > 0) {
      tmpTrigger = triggeredAlertResult.data.alerts.find(
        (alert) => alert.alertTriggerId === queryParams?.[modalRouting.TRIGGER_ID],
      );
    }
    return tmpTrigger;
  }, [JSON.stringify(triggeredAlertResult?.data?.alerts), queryParams?.[modalRouting.TRIGGER_ID]]);

  const checkFF = () => {
    const modalTabs = getInvestigationModalTabs(trigger?.type);
    return Object.values(modalTabs).map((tab) => {
      if (tab.id === INVESTIGATION_MODAL_TABS.timeline.id) {
        return {...tab, isEnabled: isTimelineEnabled};
      }
      return tab;
    });
  };

  useEffect(() => {
    if (timeline && users) {
      const tmp = calculateTimelineView(timeline, users);
      setTimelineView(tmp);
    }
  }, [timeline, users]);

  useEffect(() => {
    firstUpdate.current = false;
  }, []);

  useEffect(() => {
    if (trigger) {
      setAvailableTabs(checkFF());
    }
  }, [isTimelineEnabled, trigger]);

  // fetch tokenMapIncident
  useEffect(() => {
    if (trigger?.type === ALERT_TYPES.ANOMALY) {
      dispatch(
        setIncidentFilterNewAlertConsole(undefined, {
          groupId: anomalyId,
          timeScale: trigger?.timeScale,
          what: trigger?.metrics?.length ? trigger?.metrics[0].what : '',
          alertMeasures: setMeasures(trigger?.metrics),
        }),
      );
    }
  }, [trigger]);

  const onShareClicked = () => {
    const text = `${
      window.location.href.split('?')[0]
    }?anomalyIdInv=${anomalyId}&triggerIdInv=${triggerId}&investigationModal=1`;
    dispatch(
      copyToClipBoard({
        event: {},
        text,
        description:
          'You can send this link to anyone who has an Anodot account with the sufficient permission to view this data',
        title: 'Link to this investigation copied',
      }),
    );
  };

  const onCloseBalloon = useCallback((tabName, operation) => {
    dispatch(setInvestigationSettings(true, {tabName, operation}));
  }, []);

  const tabClicked = useCallback((tabId) => {
    setSelectedTabId(tabId);
  }, []);

  const overviewInfoCubeClicked = useCallback((tabId) => {
    if (tabId) {
      setSelectedTabId(tabId);
    }
  }, []);

  const selectedTabComponent = useMemo(() => {
    switch (selectedTabId) {
      case INVESTIGATION_MODAL_TABS.overview.id:
        return (
          <OverviewTab
            onCubeClick={overviewInfoCubeClicked}
            onShareClicked={onShareClicked}
            onCloseBalloon={onCloseBalloon}
            trigger={trigger}
            isLoading={isTriggeredAlertLoading}
          />
        );
      case INVESTIGATION_MODAL_TABS.incident.id:
        return <IncidentTab trigger={trigger} groupId={anomalyId} isTriggerLoading={isTriggeredAlertLoading} />;
      case INVESTIGATION_MODAL_TABS.correlations.id:
        return <CorrelationsTab trigger={trigger} groupId={anomalyId} isTriggerLoading={isTriggeredAlertLoading} />;
      case INVESTIGATION_MODAL_TABS.timeline.id:
        if (isStaticNoDataTriageEnabled) {
          return <TimelineTabIncremented trigger={trigger} groupId={anomalyId} timeline={timelineView} />;
        }
        return <TimelineTab trigger={trigger} groupId={anomalyId} />;
      default:
        return null;
    }
  }, [selectedTabId, trigger, anomalyId, isTriggeredAlertLoading, timelineView]);

  return (
    <>
      {trigger ? (
        <>
          <div styleName="page-container">
            <TriageHeaderActionsBar
              alertsGroup={alertsGroup}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              timeline={timelineView}
              trigger={trigger}
            />
            <PageLayout>
              <div styleName="page-content-wrapper">
                <TriageAlertHeader
                  alertsGroup={alertsGroup}
                  onShareClicked={onShareClicked}
                  queryParams={queryParams}
                  setQueryParams={setQueryParams}
                  selectedTabId={selectedTabId}
                />
                <div className={classes.tabsContainer}>
                  <TriageTabsBar tabs={availableTabs} onTabClick={tabClicked} selectedTabId={selectedTabId} />
                  <div>{selectedTabComponent}</div>
                </div>
              </div>
            </PageLayout>
          </div>
        </>
      ) : (
        <div className={classes.loader}>
          <Spinner color={palette.gray[500]} size={SIZES.XX_BIG_150} />
        </div>
      )}
    </>
  );
};

export default Triage;
