export const STREAM_STATE_OPTIONS = {
  running: {value: 'running', label: 'Live'},
  done: {value: 'finished', label: 'Done'},
  paused: {pause: 'paused', label: 'Paused'},
  new: {value: 'new', label: 'Incomplete '},
};

export const STREAM_STATUS = {
  ok: 'ok',
  error: 'error',
};

export const PAGES_TYPES = {
  edit: 'edit',
  preview: 'preview',
};

export const STREAMS_FAMILY_TYPES = {
  agents: 'agents',
  metric: 'bcmetrics',
  event: 'bcevents',
};

export const PREVIEW_TABS = {
  overview: {id: 'overview', label: 'Overview'},
  historyLog: {id: 'historyLog', label: 'HistoryLog'},
  settings: {id: 'settings', label: 'Settings'},
};

export const DEFAULT_TIME_ZONE = 'Browser';

export const COLUMNS_TYPES = {
  other: {value: 'other', label: 'Others', icon: 'icn-string16'},
  integer: {value: 'integer', label: 'Integer', icon: 'icn-number16'},
  number: {value: 'number', label: 'Number', icon: 'icn-number16'},
  timestamp: {value: 'timestamp', label: 'Timestamp', icon: 'icn-Clock16'},
};

export const COLLECTORS_IMAGES_URL = 'https://anodot-images.s3.amazonaws.com/collectors/';
export const JASON_TEMPLATES = 'data-collectors';
export const INFO_TEXT = 'helpText.json';
