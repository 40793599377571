import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useAsyncDebounce} from 'react-table';
import Input from 'common/componentsV2/Input';

export default function GlobalFilter({filter, setFilter}) {
  const [value, setValue] = useState(filter);

  const onChange = useAsyncDebounce(() => {
    // Todo Yoav - fix
    setFilter(value);
  }, 300);

  return (
    <div style={{width: 200}}>
      <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeHolder="Search"
        style={{height: 32}}
      />
    </div>
  );
}

GlobalFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};
