import React, {useCallback} from 'react';
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import CompositeItemHeader from 'composites/components/CompositeItemHeader';
import CompositeItemActionToolbar from 'composites/components/CompositeItemActionToolbar';
import PropTypes from 'prop-types';
import CompositeItemExpandablePanelContainer from 'composites/components/CompositeItemExpandablePanelContainer';
import {useCompositesContext} from 'composites/hooks/useCompositesContext';
import {STAGES_ACTIONS} from 'composites/store/reducers/useCompositesReducer';

const CompositeItem = React.memo(({item, style, isLast}: PropTypes) => {
  const {editState, editDispatch} = useCompositesContext();
  const {id: compositeId} = item.composite;

  const setSelectedItem = useCallback(
    (id) => {
      editDispatch({type: STAGES_ACTIONS.SET_SELECTED_ITEM_ID, payload: id});
    },
    [item],
  );

  return (
    <TableListItem
      automationId="Table list item"
      key={compositeId}
      id={compositeId}
      style={style}
      checkboxVisibility={CHECKBOX_VISIBILITY_TYPES.alwaysHide}
      isSelected={editState.selectedItemId === compositeId}
      setSelectedItem={setSelectedItem}
      isLast={isLast}
      headerComponent={<CompositeItemHeader item={item} />}
      actionToolbarComponent={
        <CompositeItemActionToolbar
          // onDropdownStateChange={(state) => this.onDropdownStateChange(state, alert.id)}
          unSelectItem={() => setSelectedItem(null)}
          onDropdownStateChange={() => null}
          item={item}
        />
      }
      expandedPanel={<CompositeItemExpandablePanelContainer item={item} />}
      shouldSimulateHover={editState === compositeId}
    />
  );
});

CompositeItem.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  style: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CompositeItem;
