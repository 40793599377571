/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import {get, post, stringifyQS} from 'common/utils/http';

const alertsRoot = 'api/v1/alerts/';
const anomaliesRoot = 'api/v1/anomalies/';
const metricsRoot = 'api/v1/metrics/';

export const fetchMetrics = ({payload}) =>
  get(
    `${anomaliesRoot}${payload.anomalyId}/metrics?alertId=${
      payload.alertId ? payload.alertId : ''
    }&anomalyType=all&baseline=false&correlation=&datapoints=false&delta=1&deltaType=percentage&durationUnit=minutes&durationValue=1&index=${
      payload.index
    }&order=desc&resolution=${payload.resolution}&q=${encodeURIComponent(
      JSON.stringify(payload.expression),
    )}&score=0&size=10&sort=score&startBucketMode=true&state=both&valueDirection=both`,
  );

export const fetchTriggeredAlert = ({payload}) =>
  get(`${alertsRoot}triggered/${payload.triggerId}?startBucketMode=${payload.startBucketMode}`);

export const postAcknowledge = ({payload}) =>
  post(`${alertsRoot}triggered/${payload.triggerId}/star/${payload.action}`, {userId: payload.userId});

export const fetchAlertMetricDataPoints = ({payload}) =>
  post(
    `${metricsRoot}composite/execute?fromDate=${payload.startDate}&toDate=${payload.endDate}&startBucketMode=${payload.startBucketMode}&includeBaseline=${payload.includeBaseline}&index=0&size=1&maxDataPoints=500&resolution=${payload.timeScale}`,
    payload.body,
  );

export const fetchAnomaliesTokenMap = ({payload}) => {
  let tokenMapUrl = `${anomaliesRoot}tokenMap?anomalyId=${
    payload.anomalyId
  }&anomalyType=all&bookmark=&clusteringKey=what&delta=1&deltaType=percentage&durationUnit=minutes&durationValue=1&filteredTokens=&index=0&order=desc&resolution=${
    payload.resolution
  }&q=${encodeURIComponent(
    JSON.stringify(payload.expression),
  )}&score=0&size=10&sort=score&state=both&tokenMapClusterLimit=100&tokenMapClustersLimit=100&valueDirection=both`;

  if (payload.mandatoryExpression) {
    tokenMapUrl = `${tokenMapUrl}&mandatoryExpression=${encodeURIComponent(
      JSON.stringify(payload.mandatoryExpression),
    )}`;
  }
  const result = get(tokenMapUrl);
  return result;
};

export const fetchAnomalyMetrics = ({payload}) =>
  get(`${anomaliesRoot}${payload.anomalyId}/metrics?${stringifyQS(payload.query)}`);

export const fetchTimeLine = ({payload}) => post(`api/v1/timeline/triggered/${payload.triggerId}`, payload.body);

export const sendComment = ({payload}) => post('api/v1/timeline/comments', payload);
