// @flow
import React from 'react';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import {bcTypes} from 'bc/services/bcTypes';

import S3SchedularModal from 'bc/components/streams/editor/byType/s3/s3Schedular/S3SchedularModal';
import MixpanelSchedularModal from 'bc/components/streams/editor/byType/mixpanel/mixpanelSchedular/MixpanelSchedularModal';
import CoralogixSchedularModal from 'bc/components/streams/editor/byType/coralogix/corlogixSchedular/CoralogixSchedularModal';
import SqlSchedularModal from 'bc/components/streams/editor/byType/sql/sqlSchedular/SqlSchedularModal';
import NewRelicSchedularModal from 'bc/components/streams/editor/byType/newrelic/newrelicSchedular/NewrelicSchedularModal';
import DatadogSchedularModal from 'bc/components/streams/editor/byType/datadog/datadogSchedular/DatadogSchedularModal';
import SumologicSchedularModal from 'bc/components/streams/editor/byType/sumologic/sumologicSchedular/SumologicSchedularModal';
import GA4SchedularModal from 'bc/components/streams/editor/byType/googleAnalyticsGA4/schedular/GA4SchedularModal';
import GoogleSearchSchedularModal from 'bc/components/streams/editor/byType/googleSearch/schedular/GoogleSearchSchedularModal';
import SegmentSchedularModal from 'bc/components/streams/editor/byType/segment/schedular/SegmentSchedularModal';

import './SchedulerEditorModal.module.scss';

type PropTypes = {
  dataStream: Object,
  isModalOpen: Boolean,
  onClose: Function,
};

export default class SchedulerEditorModal extends React.PureComponent {
  props: PropTypes;

  getEditor = () => {
    switch (this.props.dataStream.type) {
      case bcTypes.s3.type:
        return <S3SchedularModal onClose={this.props.onClose} />;
      case bcTypes.mysql.type:
      case bcTypes.psql.type:
      case bcTypes.mssql.type:
      case bcTypes.mariadb.type:
      case bcTypes.redshift.type:
      case bcTypes.snowflake.type:
      case bcTypes.oracle.type:
      case bcTypes.databricks.type:
      case bcTypes.teradata.type:
      case bcTypes.athena_sql.type:
      case bcTypes.pinot.type:
      case bcTypes.timestream.type:
        return <SqlSchedularModal onClose={this.props.onClose} />;
      case bcTypes.coralogix.type:
        return <CoralogixSchedularModal onClose={this.props.onClose} />;
      case bcTypes.newrelic.type:
        return <NewRelicSchedularModal onClose={this.props.onClose} />;
      case bcTypes.datadog.type:
        return <DatadogSchedularModal onClose={this.props.onClose} />;
      case bcTypes.mxpnl.type:
        return <MixpanelSchedularModal onClose={this.props.onClose} />;
      case bcTypes.sumologic.type:
        return <SumologicSchedularModal onClose={this.props.onClose} />;
      case bcTypes.google_analytics_ga4.type:
        return <GA4SchedularModal onClose={this.props.onClose} />;
      case bcTypes.google_search.type:
        return <GoogleSearchSchedularModal onClose={this.props.onClose} />;
      case bcTypes.segment.type:
        return <SegmentSchedularModal onClose={this.props.onClose} />;
      default:
        return null;
    }
  };

  render() {
    return (
      <Modal isOpen={this.props.isModalOpen} onClose={this.doNothing} size={SIZES.SMALL} isCloseButtonHidden>
        <div styleName="root">
          <header>Scheduling Options</header>
          {this.getEditor()}
        </div>
      </Modal>
    );
  }
}
