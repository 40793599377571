import React, {memo} from 'react';
import FilterChipItem from 'investigation/components/incident/FilterChipItem';
import PropTypes from 'prop-types';
import Tokens from './Tokens';

import './IncidentTab.module.scss';

const FilterChips = ({filters, onSelect}: PropTypes) => {
  return (
    <>
      {!!filters.length && (
        <div styleName="filter-chip-container">
          {filters.map((dimension) => (
            <FilterChipItem key={dimension.id} dimension={dimension} onSelect={onSelect} />
          ))}
        </div>
      )}
    </>
  );
};

Tokens.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default memo(FilterChips);
