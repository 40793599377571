import React, {useCallback} from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';

const AccessToken = ({isCreate}: {isCreate: boolean}) => {
  const validate = useCallback(
    (value) => {
      // Todo Michael - convert all channels validations to use enum instead of strings.
      return isCreate && !value && 'Access Token is mandatory';
    },
    [isCreate],
  );

  const {
    input: {value, onChange, onBlur},
    meta,
  } = useField('accessToken', {validate});
  return (
    <div>
      <div>Access Token</div>
      <div>
        <Input
          placeHolder="Select something"
          fullSize
          automationId="AccessToken"
          type={isCreate ? 'text' : 'password'}
          isInvalid={meta.touched && meta.error}
          invalidMessage={meta.error}
          isDisabled={!isCreate}
          value={isCreate ? value : '************'}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export default AccessToken;
