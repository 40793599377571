// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Loading from 'metrics/components/Loading';
import * as selectors from 'bc/store/selectors';
// eslint-disable-next-line max-len
import {getGoogleAnalyticsAccountsViewsProperties as getGoogleAnalyticsAccountsViewsPropertiesAction} from 'bc/store/actions';
import '../../ViewStreamSummaryModalV2.module.scss';

type PropTypes = {
  dataStream: Object,

  // connect
  account: String,
  property: String,
  view: String,
  getGoogleAnalyticsAccountsViewsProperties: Function,
};

export default connect(
  (state) => ({
    account: selectors.getGoogleAnalyticsSelectedAccount(state),
    property: selectors.getGoogleAnalyticsSelectedProperty(state),
    view: selectors.getGoogleAnalyticsSelectedView(state),
  }),
  {
    getGoogleAnalyticsAccountsViewsProperties: getGoogleAnalyticsAccountsViewsPropertiesAction,
  },
)(
  class GaDataSection extends PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {
        getGoogleAnalyticsAccountsViewsProperties,
        dataStream: {dataSourceId, accountId, propertyId},
      } = this.props;

      getGoogleAnalyticsAccountsViewsProperties({
        dataSourceId,
        accountId,
        propertyId,
      });
    }

    render() {
      const {account, property, view} = this.props;

      return (
        <div styleName="section">
          <div styleName="vs-section-header">WEB SITE DATA</div>
          <div styleName="vs-row">
            <div styleName="vs-label">Account</div>
            {account ? <div styleName="vs-value">{account.name}</div> : <Loading styleName="spinner" />}
          </div>
          <div styleName="vs-row">
            <div styleName="vs-label">Property</div>
            {property ? <div styleName="vs-value">{property.name}</div> : <Loading styleName="spinner" />}
          </div>
          <div styleName="vs-row">
            <div styleName="vs-label">View</div>
            {view ? <div styleName="vs-value">{view.name}</div> : <Loading styleName="spinner" />}
          </div>
        </div>
      );
    }
  },
);
