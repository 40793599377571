import {combineEpics} from 'redux-observable';
import {routeChanged} from 'common/store/actions';
import {Observable} from 'rxjs/Observable';
import Highcharts from 'highcharts';
import * as investigationActions from 'investigation/store/actions';
import * as alertsConsoleAction from 'alerts.console/store/actions';
import * as alertsManagmentAction from 'alerts.management/store/actions';
import * as adminUsersAction from 'admin.users/store/actions';
import * as customersAction from 'admin.customers/store/actions';
import * as insightsPanelActions from 'insightsPanel/store/actions';
import * as dashboardsActions from 'dashboards/store/actions';
import * as activityLog from 'admin.activityLog/store/actions';
import * as recommendations from 'recommendations/store/actions';
import * as predefinedDatesActions from 'common/components/dateTime/store/actions';
import {difference, get, pick} from 'lodash';
import {CAN_BE_SAVED_QUERY_PARAMS, DEFAULT_QUERY_PARAMS} from 'alerts.console/services/alertsConsoleService';
import * as alertsConsoleSelectors from 'alerts.console/store/selectors';
import {info} from 'common/utils/notifications/notificationsService';
import {getPageNameSegment} from 'common/utils/http';
import {updateUserSettings} from 'common/utils/angularServices';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as profileActions from 'profile/store/actions';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import {
  ALERT_CONSOLE_QUERY_PARAMS_KEYS,
  DEFAULT_QUERY_PARAMS_FILTERS,
} from 'alerts.console.new/services/alertsConsoleService';
import * as actions from './actions';
import * as selectors from './selectors';
import * as api from '../services/api';

const saveQueryParamsMsg = {
  title: 'Saved This View',
  description: 'This is your default view for Alert Console now.',
  settings: {
    autoDismiss: 5,
  },
};
const updateMe = makeAsyncEpic(actions.updateMe, api.updateMe);
const updateUserNameOnly = makeAsyncEpic(actions.updateUserNameOnly, api.updateUserNameOnly);
const updateMeLastActive = makeAsyncEpic(actions.updateMeLastActive, api.updateMeLastActive);

const canBeSaved = (queryParams = {}) => {
  const queryParamsToBeSaved = {};
  Object.keys(queryParams).forEach((i) => {
    if (CAN_BE_SAVED_QUERY_PARAMS.includes(i)) {
      queryParamsToBeSaved[i] = queryParams[i];
    }
  });
  return queryParamsToBeSaved;
};

const keepOnlyDiffFromDefault = (original = {}) => {
  const diff = {};
  Object.keys(original).forEach((i) => {
    if (
      original[i] !== DEFAULT_QUERY_PARAMS[i] &&
      original[i] !== undefined &&
      original[i] !== 'undefined' &&
      original[i]
    ) {
      diff[i] = original[i];
    }
  });
  return diff;
};

const canBeSavedNewAlertConsole = (queryParams = {}) => {
  const queryParamsToBeSaved = {};
  Object.keys(queryParams).forEach((i) => {
    if (Object.keys(DEFAULT_QUERY_PARAMS_FILTERS).includes(i)) {
      queryParamsToBeSaved[i] = queryParams[i];
    }
  });
  return queryParamsToBeSaved;
};

const keepOnlyDiffFromDefaultNewAlertConsole = (original = {}) => {
  const diff = {};
  Object.keys(original).forEach((i) => {
    if (
      original[i] !== ALERT_CONSOLE_QUERY_PARAMS_KEYS[i] &&
      original[i] !== undefined &&
      original[i] !== 'undefined' &&
      original[i]
    ) {
      diff[i] = original[i];
    }
  });
  return diff;
};

const updatePagesVisited = (cleanedHash, appSettings, skipped) => {
  if (cleanedHash === 'unimpersonate') {
    return [];
  }
  if (appSettings) {
    const date = new Date();
    const firstTimeSig = date.getTime();
    // if the user never been in any page and pageVisited is not exist or the user didn't been in this specific page.
    if (!appSettings.pageVisited || appSettings.pageVisited.every((i) => i.name !== cleanedHash)) {
      const updatedAppSettings = {
        ...appSettings,
        pageVisited: [
          ...(appSettings.pageVisited || []),
          {
            name: cleanedHash,
            firstTimeSig: skipped ? false : firstTimeSig,
          },
        ],
      };
      return [actions.updateUserSettings(updatedAppSettings)];
    }
    // if the user skipped on this page, mining this page is exist but without sig time.
    if (
      appSettings.pageVisited &&
      appSettings.pageVisited.find((i) => (i.name === cleanedHash ? !i.firstTimeSig : false))
    ) {
      const updatedAppSettings = {
        ...appSettings,
        pageVisited: appSettings.pageVisited.map((i) => {
          if (i.name === cleanedHash && !i.firstTimeSig) {
            return {
              name: i.name,
              firstTimeSig,
            };
          }
          return i;
        }),
      };
      return [actions.updateUserSettings(updatedAppSettings)];
    }
    return [];
  }
  return [];
};

const updateUserSettingsSuccess = (action$, {getState}) =>
  action$.ofType(actions.updateUserSettings.success.TYPE).flatMap(() => {
    const timeZoneName = selectors.getTimeZoneName(getState());
    Highcharts.setOptions({
      time: {
        timezone: timeZoneName,
      },
    });
    return [];
  });

const setProfileAppSettings = (action$, {getState}) =>
  action$.ofType(actions.updateUserSettings.TYPE).flatMap(() => {
    const me = selectors.getUserProfile(getState());
    return api
      .updateUserSettings(me)
      .map((payload) => {
        updateUserSettings(me.appSettings); // we update angular
        return actions.updateUserSettings.success(payload);
      })
      .catch((error) => Observable.of(actions.updateUserSettings.failure(error)));
  });

const updateQueryParams = (action$, {getState}) =>
  action$.ofType(alertsConsoleAction.updateQueryParams.TYPE).flatMap(({payload}) => {
    const currentAppSettings = selectors.getMeAppSettings(getState());
    const isAlertsConsoleEnabledAndActive = selectors.getNewAlertConsoleEnabledAndActive(getState());
    const queryParamsView = isAlertsConsoleEnabledAndActive
      ? payload.queryParams
      : alertsConsoleSelectors.getQueryParamsViews(getState());
    const queryParamsThatCanBeSaved = isAlertsConsoleEnabledAndActive
      ? canBeSavedNewAlertConsole(queryParamsView)
      : canBeSaved(queryParamsView);
    const queryParamsDiffFromDefault = isAlertsConsoleEnabledAndActive
      ? keepOnlyDiffFromDefaultNewAlertConsole(queryParamsThatCanBeSaved)
      : keepOnlyDiffFromDefault(queryParamsThatCanBeSaved);
    // If isRest = true we save an empty object (In the old alert console payload = true, means that we need to save the defaults).
    const isReset = isAlertsConsoleEnabledAndActive ? payload.isReset : payload === true;

    const appSettings = {
      ...currentAppSettings,
      alertsConsole: isAlertsConsoleEnabledAndActive
        ? {
            ...currentAppSettings.alertsConsole,
            queryParamsNewAlertConsole: isReset ? {} : queryParamsDiffFromDefault,
          }
        : {
            ...currentAppSettings.alertsConsole,
            queryParams: isReset ? {} : queryParamsDiffFromDefault,
          },
    };

    return [actions.updateUserSettings(appSettings), info(saveQueryParamsMsg)];
  });

const updateFilterBtn = (action$, {getState}) =>
  action$.ofType(alertsConsoleAction.updateFilterBtn.TYPE).flatMap(({payload}) => {
    const currentAppSettings = selectors.getMeAppSettings(getState());

    const appSettings = {
      ...currentAppSettings,
      alertsConsole: {
        ...currentAppSettings.alertsConsole,
        isFiltersBarOpen: !payload,
      },
    };

    return [actions.updateUserSettings(appSettings)];
  });

const IsMoreFilterOpen = (action$, {getState}) =>
  action$.ofType(alertsConsoleAction.IsMoreFilterOpen.TYPE).flatMap(({payload}) => {
    const appSettings = selectors.getMeAppSettings(getState());

    return [
      actions.updateUserSettings({
        ...appSettings,
        alertsConsole: {
          ...appSettings.alertsConsole,
          isMoreFilterOpen: payload,
        },
      }),
    ];
  });

const setInvestigationSettings = (action$, {getState}) =>
  action$.ofType(investigationActions.setInvestigationSettings.TYPE).flatMap(({payload, meta}) => {
    const appSettings = selectors.getMeAppSettings(getState());

    return [
      actions.updateUserSettings({
        ...appSettings,
        investigation: {
          ...appSettings.investigation,
          [meta.tabName]: {
            [meta.operation]: payload,
          },
        },
      }),
    ];
  });

const updateFilterBtnDashboard = (action$, {getState}) =>
  action$.ofType(dashboardsActions.updateFilterBtn.TYPE).flatMap(({payload}) => {
    const currentAppSettings = selectors.getMeAppSettings(getState());

    const appSettings = {
      ...currentAppSettings,
      dashboards: {
        ...currentAppSettings.dashboards,
        isFiltersBarOpen: !payload,
      },
    };

    return [actions.updateUserSettings(appSettings)];
  });

const updateFilterBtnActivityLog = (action$, {getState}) =>
  action$.ofType(activityLog.updateFilterBtn.TYPE).flatMap(({payload}) => {
    const currentAppSettings = selectors.getMeAppSettings(getState());

    const appSettings = {
      ...currentAppSettings,
      activityLog: {
        ...currentAppSettings.activityLog,
        isFiltersBarOpen: !payload,
      },
    };

    return [actions.updateUserSettings(appSettings)];
  });

const updateFilterRecommendations = (action$, {getState}) =>
  action$.ofType(recommendations.updateFilterBtn.TYPE).flatMap(({payload}) => {
    const currentAppSettings = selectors.getMeAppSettings(getState());

    const appSettings = {
      ...currentAppSettings,
      recommendations: {
        ...currentAppSettings.recommendations,
        isFiltersBarOpen: !payload,
      },
    };

    return [actions.updateUserSettings(appSettings)];
  });

const updateInsightsPanelTime = (action$, {getState}) =>
  action$.ofType(insightsPanelActions.updateInsightsPanelTime.TYPE).flatMap(({payload}) => {
    const appSettings = selectors.getMeAppSettings(getState());

    const updatedAppSettings = {
      ...appSettings,
      insightsPanel: {
        ...appSettings.insightsPanel,
        timeRange: payload,
      },
    };

    return [actions.updateUserSettings(updatedAppSettings)];
  });

const updatePredefinedDatesUserSettings = (action$, {getState}) =>
  action$.ofType(predefinedDatesActions.updatePredefinedDatesUserSettings.TYPE).flatMap(({payload}) => {
    const appSettings = selectors.getMeAppSettings(getState());

    const updatedAppSettings = {
      ...appSettings,
      predefinedDateSettings: {
        ...appSettings.predefinedDateSettings,
        preDefinedDates: payload.preDefinedDates,
        customDates: payload.customDates,
      },
    };
    const me = selectors.getUserProfile(getState());
    if (me.invitationId) {
      return [];
    }
    return [actions.updateUserSettings(updatedAppSettings)];
  });

const dismissOnBoarding = (action$, {getState}) =>
  action$.ofType(actions.dismissOnBoarding.TYPE).flatMap(() => {
    const appSettings = selectors.getMeAppSettings(getState());
    return [actions.updateUserSettings(appSettings)];
  });

const updatePageVisitedSetMe = (action$, {getState}) =>
  action$.ofType(actions.setMe.TYPE, actions.fetchMe.success.TYPE).flatMap(() => {
    const appSettings = selectors.getMeAppSettings(getState());
    const cleanedHash = getPageNameSegment(location.hash);
    const shouldSkipRenameNeeded = get(appSettings, 'usersGroups.isRenameNeeded', false);

    const me = selectors.getUserProfile(getState());
    if (me.invitationId) {
      return [];
    }
    return updatePagesVisited(cleanedHash, appSettings, shouldSkipRenameNeeded);
  });

const updateTimeZoneSetMe = (action$) =>
  action$.ofType(actions.setMe.TYPE, actions.fetchMe.success.TYPE).flatMap(({payload}) => {
    const payloadTimeZone = get(payload, 'appSettings.timeZone.name');
    return [actions.timeZoneChanged(payloadTimeZone)];
  });

const updateLastActiveSetMe = (action$) =>
  action$.ofType(actions.setMe.TYPE, actions.fetchMe.success.TYPE).flatMap(({payload}) => {
    if (payload.impersonatorId || payload.invitationId) {
      return [];
    }
    return [actions.updateMeLastActive()];
  });

const updatePageVisitedRouteChange = (action$, {getState}) =>
  action$.ofType(routeChanged.TYPE).flatMap(({payload}) => {
    const me = selectors.getUserProfile(getState());
    if (!me._id || me.invitationId) {
      return [];
    }
    const appSettings = selectors.getMeAppSettings(getState());
    const hash = payload.newURL.slice(payload.newURL.indexOf('#'));
    const cleanedHash = getPageNameSegment(hash);
    const shouldSkipRenameNeeded = get(appSettings, 'usersGroups.isRenameNeeded', false);
    return updatePagesVisited(cleanedHash, appSettings, shouldSkipRenameNeeded);
  });

const skippedMission = (action$, {getState}) =>
  action$.ofType(actions.skippedMission.TYPE).flatMap(({payload}) => {
    const appSettings = selectors.getMeAppSettings(getState());
    return updatePagesVisited(payload, appSettings, true);
  });

const updateAlertManagerFilterBtn = (action$, {getState}) =>
  action$
    .ofType(alertsManagmentAction.setAlertFiltersIsOpen.TYPE)
    .debounceTime(200)
    .distinctUntilChanged((x, y) => x.payload === y.payload)
    .flatMap(({payload}) => {
      const currentAppSettings = selectors.getMeAppSettings(getState());

      const appSettings = {
        ...currentAppSettings,
        alertManager: {
          ...currentAppSettings.alertManager,
          isFiltersBarOpen: payload,
        },
      };

      return [actions.updateUserSettings(appSettings)];
    });

const updateUsersGroupsFilterBtn = (action$, {getState}) =>
  action$
    .ofType(adminUsersAction.setUsersGroupsFiltersIsOpen.TYPE)
    .debounceTime(200)
    .distinctUntilChanged((x, y) => x.payload === y.payload)
    .flatMap(({payload}) => {
      const currentAppSettings = selectors.getMeAppSettings(getState());

      const appSettings = {
        ...currentAppSettings,
        usersGroups: {
          ...currentAppSettings.usersGroups,
          isFiltersBarOpen: payload,
        },
      };

      return [actions.updateUserSettings(appSettings)];
    });

const updateCustomersFilterBtn = (action$, {getState}) =>
  action$
    .ofType(customersAction.setCustomersFiltersIsOpen.TYPE)
    .debounceTime(200)
    .distinctUntilChanged((x, y) => x.payload === y.payload)
    .flatMap(({payload}) => {
      const currentAppSettings = selectors.getMeAppSettings(getState());

      const appSettings = {
        ...currentAppSettings,
        customers: {
          ...currentAppSettings.customers,
          isFiltersBarOpen: payload,
        },
      };

      return [actions.updateUserSettings(appSettings)];
    });

const updateUser = (action$, {getState}) =>
  action$.ofType(adminUsersAction.updateUser.TYPE).flatMap(({payload}) => {
    const me = selectors.getUserProfile(getState());
    if (payload._id !== me._id) {
      return [];
    }

    const updatedProps = pick(payload, ['firstName', 'lastName', 'groups', 'defaultGroup', 'roles', 'disabled']);
    const updatedMe = {...me, ...updatedProps};
    return [actions.setMe(updatedMe, {skip: true})];
  });

const createGroup = (action$, {getState}) =>
  action$.ofType(adminUsersAction.createGroup.success.TYPE).flatMap(({payload}) => {
    const me = selectors.getUserProfile(getState());
    if (!payload.users.length || !payload.users.some((userId) => me._id === userId)) {
      return [];
    }

    const groups = me.groups && me.groups.length ? [...me.groups, payload._id] : [payload._id];
    const defaultGroup = me.defaultGroup ? me.defaultGroup : me.defaultGroup;

    const updatedMe = {...me, groups, defaultGroup};
    return [actions.setMe(updatedMe, {skip: true})];
  });

const updateGroup = (action$, {getState}) =>
  action$.ofType(adminUsersAction.updateGroup.TYPE).flatMap(({payload, meta}) => {
    const me = selectors.getUserProfile(getState());
    const usersToRemove = difference(meta.groupUsersIds, payload.group.users);
    const usersToAdd = difference(payload.group.users, meta.groupUsersIds);

    if (usersToRemove.some((userId) => me._id === userId)) {
      const groups1 = (me.groups || []).filter((gr) => gr !== payload.group.id);
      const defaultGroup1 = me.defaultGroup && me.defaultGroup === payload.group.id ? '' : me.defaultGroup;

      const updatedMe1 = {...me, groups: groups1, defaultGroup: defaultGroup1};
      return [actions.setMe(updatedMe1, {skip: true})];
    }

    if (usersToAdd.some((userId) => me._id === userId)) {
      const groups2 = me.groups && me.groups.length ? [...me.groups, payload.group.id] : [payload.group.id];
      const defaultGroup2 = me.defaultGroup ? me.defaultGroup : payload.group.id;

      const updatedMe2 = {...me, groups: groups2, defaultGroup: defaultGroup2};
      return [actions.setMe(updatedMe2, {skip: true})];
    }

    return [];
  });

const deleteGroup = (action$, {getState}) =>
  action$.ofType(adminUsersAction.deleteGroup.success.TYPE).flatMap(({payload}) => {
    const me = selectors.getUserProfile(getState());
    if (!payload.users.length || !payload.users.some((userId) => me._id === userId)) {
      return [];
    }

    const groups = (me.groups || []).filter((gr) => gr !== payload._id);
    const defaultGroup = me.defaultGroup && me.defaultGroup === payload._id ? '' : me.defaultGroup;

    const updatedMe = {...me, groups, defaultGroup};
    return [actions.setMe(updatedMe, {skip: true})];
  });

const addGroupsToUser = (action$, {getState}) =>
  action$.ofType(adminUsersAction.addGroupsToUser.success.TYPE).flatMap(({payload}) => {
    const me = selectors.getUserProfile(getState());
    if (payload.user._id !== me._id) {
      return [];
    }

    return [actions.setMe(payload.user, {skip: true})];
  });

const bulkUserEdit = (action$, {getState}) =>
  action$.ofType(adminUsersAction.editUsersBulkApi.success.TYPE).flatMap(({payload}) => {
    const me = selectors.getUserProfile(getState());
    if (!payload.users || !payload.users.length || !payload.users.some((user) => me._id === user._id)) {
      return [];
    }

    const newMe = payload.users.find((user) => me._id === user._id);

    const updatedProps = pick(newMe, ['groups', 'defaultGroup', 'disabled']);
    const updatedMe = {...me, ...updatedProps};
    return [actions.setMe(updatedMe, {skip: true})];
  });

const fetchMeFailure = (action$) => {
  return action$.ofType(profileActions.fetchMe.failure.TYPE).flatMap(({payload}) => {
    if (payload.data === 'user not found') {
      window.location.href =
        'https://www.anodot.com/payment-transaction-monitoring/?utm_source=smartburger&utm_medium=clarity';
      return true;
    }
    return [];
  });
};

const signOutSuccess = (action$, {getState}) =>
  action$.ofType(profileActions.signOut.success.TYPE).flatMap((action) => {
    const me = selectors.getUserProfile(getState());

    if (me && window.isProduction && window.FS) {
      window.FS.identify(false);
    }

    localStorage.removeItem('andt-token');
    localStorage.removeItem('andt-token-ttl');
    localStorage.removeItem('andt-token-iat');
    sessionStorage.removeItem('andt-token');
    sessionStorage.removeItem('andt-token-ttl');
    sessionStorage.removeItem('andt-token-iat');

    if (get(action, 'meta.status') === 401) {
      window.location.href = `${window.location.origin}/signin/?err=401&returnUrl=${encodeURIComponent(
        window.location.href,
      )
        .split('%')
        .join('~')}`;
    } else {
      window.location.href = `${window.location.origin}/signin/`;
    }

    return [];
  });

const fetchMeEpic = makeAsyncEpic(actions.fetchMe, api.fetchMe);
const signOutEpic = makeAsyncEpic(actions.signOut, api.signOut);

const epic = combineEpics(
  setProfileAppSettings,
  updateQueryParams,
  updateFilterBtn,
  IsMoreFilterOpen,
  updateFilterBtnDashboard,
  updateFilterBtnActivityLog,
  updateFilterRecommendations,
  updateInsightsPanelTime,
  updatePredefinedDatesUserSettings,
  dismissOnBoarding,
  skippedMission,
  updatePageVisitedSetMe,
  updatePageVisitedRouteChange,
  updateAlertManagerFilterBtn,
  updateTimeZoneSetMe,
  updateMeLastActive,
  updateLastActiveSetMe,
  updateUserNameOnly,
  updateMe,
  updateUsersGroupsFilterBtn,
  updateUser,
  updateUserSettingsSuccess,
  updateCustomersFilterBtn,
  createGroup,
  updateGroup,
  deleteGroup,
  addGroupsToUser,
  bulkUserEdit,
  setInvestigationSettings,
  fetchMeEpic,
  signOutEpic,
  signOutSuccess,
  fetchMeFailure,
);

export default epic;
