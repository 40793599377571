import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import {composeReducers} from '../../../common/utils/reducers';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export default combineReducers({
  entities: composeReducers(
    makeAsyncReducer(actions.fetchMixpanelEntities, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
  entity: composeReducers(
    makeAsyncReducer(actions.fetchMixpanelEntityMetadata, {defaultData: EMPTY_OBJECT, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
  preview: composeReducers(
    makeAsyncReducer(actions.fetchMixpanelQueryPreview, {defaultData: EMPTY_OBJECT, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
});
