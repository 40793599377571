import {useMutation, useQueryClient} from 'react-query';

import {post} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/';

const postEventSource = (payload) => {
  if (!payload?.type) return null;
  return post(`${root}data-sources?type=${payload.type}`, payload);
};

export default function useCreateEventSource() {
  const queryClient = useQueryClient();

  const {mutate: createEventSource, isLoading: isCreateEventSourceLoading} = useMutation(
    ({payload}) => postEventSource(payload),
    {
      onSuccess: (data, meta) => {
        queryClient.invalidateQueries([QUERY_KEYS.sources]);
        meta.onSuccess(data);
      },
    },
  );

  return {createEventSource, isCreateEventSourceLoading};
}
