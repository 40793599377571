import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import useHandleResize from 'common/hooks/useHandleResize';
import {
  fetchAnomalyMetricsNewAlertConsole,
  setCorrelationsFilterNewAlertConsole,
} from 'investigationNewAlertConsole/store/actions';
import {
  getCorrelationsTokenMapCounter,
  getCorrelationsTokenMapDataFiltered,
  getCorrelationsTokenMapIsLoading,
  getCorrelationsTokenMapTotal,
  getFetchAlertMetricsCorrelation,
  getInvModalCorrelationsFilters,
  getInvOtherMeasures,
} from 'investigationNewAlertConsole/store/selectors';
import {getTimeZoneName} from 'profile/store/selectors';
import {palette} from 'app/styles/theme';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {ReactComponent as EmptyState} from 'investigationNewAlertConsole/images/correlationsEmptyState.svg';
import Spinner, {SIZES, SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import ContentLoader from 'common/componentsV2/ContentLoader';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import Button, {COLORS as ButtonColors} from 'common/componentsV2/Button';
import FiltersChips from 'investigationNewAlertConsole/components/incident/FiltersChips';
import TokenMapFilters from 'investigationNewAlertConsole/components/incident/TokenMapFilters';
import {calculateAnomalyAlertColumns} from 'investigationNewAlertConsole/services/investigationService';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import MetricsTable from 'investigationNewAlertConsole/components/metricsTable/MetricsTable';
import {setMeasures} from 'investigationNewAlertConsole/api/utils';

import classes from './CorrelationDetails.module.scss';

const CorrelationTab = ({trigger, groupId}: {trigger: Object, groupId: String}) => {
  const style = {
    panelRootStyle: {
      flexShrink: 0,
      padding: 0,
      border: 'none',
      marginTop: 16,
      marginBottom: 16,
    },
  };

  const dispatch = useDispatch();
  const timeZoneName = useSelector(getTimeZoneName);

  const isTokenMapLoading = useSelector(getCorrelationsTokenMapIsLoading);
  const correlationsTokenMap = useSelector(getCorrelationsTokenMapDataFiltered);
  const tokenMapMetricsCounter = useSelector(getCorrelationsTokenMapCounter);
  const tokenMapMetricsTotal = useSelector(getCorrelationsTokenMapTotal);

  const allMetrics = useSelector(getFetchAlertMetricsCorrelation);

  const otherMeasures = useSelector(getInvOtherMeasures);

  const filters = useSelector(getInvModalCorrelationsFilters);

  const [isExpand, setIsExpand] = useState({});
  const [listOrder, setListOrder] = useState({});
  const bodyContainerHeight = useHandleResize([300]);

  useEffect(() => {
    if (isTokenMapLoading === undefined && trigger && groupId) {
      dispatch(
        setCorrelationsFilterNewAlertConsole(undefined, {
          groupId, // or can just use the queryParams...
          timeScale: trigger.timeScale,
          alertMeasures: setMeasures(trigger?.metrics),
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (!isTokenMapLoading && !isEmpty(correlationsTokenMap)) {
      const firstWhat = Object.keys(correlationsTokenMap)[0];
      dispatch(
        fetchAnomalyMetricsNewAlertConsole(
          {what: firstWhat, alertId: null, filters, isMore: false},
          {
            groupId,
            timeScale: trigger?.timeScale,
            alertMeasures: setMeasures(trigger?.metrics),
          },
        ),
      );

      setIsExpand({[`${firstWhat}-all-metrics`]: true});
    }
  }, [isTokenMapLoading, correlationsTokenMap, filters, groupId, trigger]);

  const onExpandBoxChange = useCallback(
    (id) => (isExpanded) => {
      dispatch(
        fetchAnomalyMetricsNewAlertConsole(
          {what: id, alertId: null, filters, isMore: false},
          {
            groupId,
            timeScale: trigger.timeScale,
          },
        ),
      );
      setIsExpand((st) => ({...st, [`${id}-all-metrics`]: isExpanded}));
    },
    [filters, groupId, trigger],
  );

  const onShowMoreMetricsClick = useCallback(
    (what, index) => () => {
      dispatch(
        fetchAnomalyMetricsNewAlertConsole(
          {what, index, isMore: true, filters},
          {
            groupId,
            timeScale: trigger.timeScale,
          },
        ),
      );
    },
    [filters, groupId, trigger],
  );

  const isMoreMetrics = (what) => allMetrics[what].data.metrics.length < allMetrics[what].data.metricsCount.total;

  const setFilter = (dimension) => {
    if (isTokenMapLoading !== true && dimension.id !== 'other') {
      dispatch(
        setCorrelationsFilterNewAlertConsole(dimension, {
          groupId,
          timeScale: trigger?.timeScale,
        }),
      );
    }
  };

  const correlationsColumns = useMemo(() => {
    return calculateAnomalyAlertColumns(otherMeasures);
  }, [otherMeasures]);

  if (isTokenMapLoading) {
    return (
      <div className={classes.loader}>
        <Spinner color={palette.gray[500]} size={SIZES.XX_BIG_150} />
      </div>
    );
  }

  return !otherMeasures.length ? (
    <div className={classes.emptyState}>
      <EmptyState />
      <TypographyBox variant="subtitle2" color={palette.gray['500']} mt={2}>
        After analyzing all the metrics,{' '}
        <TypographyBox variant="subtitle1" mt={1}>
          We found the incident is contained within the original measure, no other measures are affected.
        </TypographyBox>
      </TypographyBox>
    </div>
  ) : (
    <div className={classes.correlationsContainer}>
      {/* TokenMap */}
      <div className={classes.dimensionsBreakdown}>
        <TokenMapFilters
          tokenMap={correlationsTokenMap}
          selectedFilters={filters}
          isLoading={isTokenMapLoading}
          metricsCounter={tokenMapMetricsCounter}
          totalMetrics={tokenMapMetricsTotal}
          onSelect={setFilter}
        />
      </div>
      {/* Metrics */}
      <div className={classes.correlationsMetrics}>
        <div className={classes.metricsContainer} height={bodyContainerHeight}>
          <FiltersChips filters={filters} onSelect={setFilter} />
          {/* All Metrics */}
          {otherMeasures?.map((what) => (
            <>
              {tokenMapMetricsCounter[what]?.anomalyOccurrences > 0 && (
                <div className={classes.otherMeasuresContainer} key={what}>
                  <Tooltip content={what} type={TYPES.SMALL}>
                    <div className={classes.tooltipContainer}>
                      <InnerChip title={what} displayOnly type="measure" />
                    </div>
                  </Tooltip>
                  <ExpandableBox
                    key={`inc-gr-panel-${what}-all`}
                    automationId="incWhatGroup"
                    nodeLabel={
                      <TypographyBox variant="h5" color={palette.gray['400']} mt={0.5}>
                        Anomalies in correlation{' '}
                        <TypographyBox
                          component="span"
                          color={filters.length ? palette.teal['500'] : palette.gray['400']}
                        >
                          {tokenMapMetricsCounter[what]?.anomalyOccurrences}
                        </TypographyBox>
                      </TypographyBox>
                    }
                    expanded={isExpand[`${what}-all-metrics`]}
                    onChange={onExpandBoxChange(what)}
                    rootStyle={style.panelRootStyle}
                  >
                    <div>
                      {allMetrics && allMetrics[what]?.isLoading && (
                        <ContentLoader
                          rows={[
                            {width: 90, height: 27},
                            {width: 415, height: 20},
                            {width: '100%', height: 40},
                            {width: '100%', height: 40},
                            {width: '100%', height: 40},
                          ]}
                        />
                      )}
                      {allMetrics && !allMetrics[what]?.isLoading && allMetrics[what]?.data?.metricsCount && (
                        <>
                          <MetricsTable
                            columns={correlationsColumns}
                            listOrder={listOrder}
                            metrics={allMetrics[what]?.data?.metrics}
                            setListOrder={setListOrder}
                            timeZoneName={timeZoneName}
                            trigger={trigger}
                            isSingleLine={false}
                          />
                          {allMetrics[what].isLoadingMore && (
                            <div className={classes.isLoadingContainer}>
                              <Spinner color="#3d4c59" size={SpinnerSizes.MEDIUM_50} />
                            </div>
                          )}
                          {isMoreMetrics(what) && (
                            <div className={classes.isLoadingMoreContainer}>
                              {!allMetrics[what].isLoadingMore && (
                                <Button
                                  colorSchema={ButtonColors.TRANSPARENT_BLUE}
                                  text="Show more metrics"
                                  onClick={onShowMoreMetricsClick(what, allMetrics[what].data.metrics.length)}
                                />
                              )}
                            </div>
                          )}
                          {!allMetrics[what]?.data?.metrics?.length && (
                            <TypographyBox variant="h5" color={palette.gray['400']} margin="16px 0 0 22px">
                              Anomalies in correlation{' '}
                              <TypographyBox
                                component="span"
                                color={filters.length ? palette.teal['500'] : palette.gray['400']}
                              />
                              (0)
                            </TypographyBox>
                          )}
                        </>
                      )}
                    </div>
                  </ExpandableBox>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CorrelationTab;
