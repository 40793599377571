import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {COLUMNS_TYPES} from 'dataCollectors/services/dataCollectorsService';

const root = 'api/v1/bc/';

// const fetchSchemas = async (sourceId) => {
const getTableMetadata = async (type, sourceId, schemaName, tableName) => {
  return get(`${root}data-sources/${type}/${sourceId}/metadata?schemaName=${schemaName}&tableName=${tableName}`);
};

export default function useFetchTableMetadata(type, sourceId, schemaName, tableName) {
  const fallback = [];

  const selectFn = useCallback(
    (data) => {
      return data.map((i) => ({type: i.type, label: i.name, value: i.name, icon: COLUMNS_TYPES[i.type].icon}));
    },
    [sourceId],
  );

  const {
    data: metadata = fallback,
    isLoading: isMetadataLoading,
    isFetching: isMetadataFetching,
    refetch: refetchMetadataFetching,
  } = useQuery(QUERY_KEYS.tableMetadata, () => getTableMetadata(type, sourceId, schemaName, tableName), {
    enabled: !!(type && sourceId && schemaName && tableName),
    select: selectFn,
  });

  return {metadata, isMetadataLoading, isMetadataFetching, refetchMetadataFetching};
}
