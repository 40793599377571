// @flow
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {noop} from 'lodash';
import {useQueryParam, StringParam} from 'use-query-params';

import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import DataManagerStreamItemData from './DataManagerStreamItemData';
import DataManagerStreamItemActions from './DataManagerStreamItemActions';
import './DataManagerStreamItem.module.scss';

export default function DataManagerStreamItem({
  stream,
  isBcReadOnly,
  openStreamViewModal,
  openConfirmationModal,
  openLiveStreamEditModal,
  openStreamsLimitModal,
}) {
  const [updateId, setUpdatedId] = useQueryParam('updateId', StringParam);

  const [actionItemSelected, setActionItemSelected] = useState('');
  const [glow, setGlow] = useState(false);

  const onActionItemSelected = (selectedItem) => {
    setActionItemSelected(selectedItem);
  };

  useEffect(() => {
    let timer1 = null;

    if (updateId && updateId === stream.id) {
      setGlow(true);
      timer1 = setTimeout(() => {
        setGlow(false);
        setUpdatedId(undefined);
      }, 4000);
    } else {
      setUpdatedId(undefined);
    }
    return () => {
      clearTimeout(timer1);
    };
  }, []);

  return (
    <div styleName={glow ? 'table glow' : 'table'}>
      <TableListItem
        key={stream.id}
        id={stream.id}
        style={{width: '100%'}}
        headerComponent={<DataManagerStreamItemData stream={stream} openStreamViewModal={openStreamViewModal} />}
        checkboxVisibility={CHECKBOX_VISIBILITY_TYPES.alwaysHide}
        setSelectedItem={noop}
        isSelected={false}
        selectedActionItem={actionItemSelected}
        actionToolbarComponent={
          <DataManagerStreamItemActions
            stream={stream}
            isBcReadOnly={isBcReadOnly}
            openStreamViewModal={openStreamViewModal}
            openConfirmationModal={openConfirmationModal}
            openLiveStreamEditModal={openLiveStreamEditModal}
            openStreamsLimitModal={openStreamsLimitModal}
            onActionItemSelected={onActionItemSelected}
          />
        }
      />
    </div>
  );
}

DataManagerStreamItem.propTypes = {
  stream: PropTypes.objectOf(PropTypes.any).isRequired,
  isBcReadOnly: PropTypes.bool.isRequired,
  openStreamViewModal: PropTypes.func.isRequired,
  openConfirmationModal: PropTypes.func.isRequired,
  openLiveStreamEditModal: PropTypes.func.isRequired,
  openStreamsLimitModal: PropTypes.func.isRequired,
};
