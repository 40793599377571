// @flow
import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import Box from '@material-ui/core/Box';
import SmartTooltip from 'common/componentsV2/Tooltip';
import TinyScrollBox from 'common/componentsV2/boxTools/TinyScrollBox';
import Button, {COLORS} from 'common/componentsV2/Button';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import EmptyListImageComponent from 'common/componentsV2/EmptyListImageComponent';
import {getTMLeftPanelSelectedIssue, getTMLeftPanelSpecialIssueNotFound} from 'topologyLeaflet/store/selectors';
import {segmentClickEvent} from 'common/store/actions';
import {PANEL_SIZES} from 'topologyLeaflet/services/leftPanelService';
import MetricItem from 'topologyLeaflet/components/leftPanel/MetricItem';
import {palette} from 'app/styles/theme';
import './LeftPanel.module.scss';

const EmptyListIndication = () => (
  <div styleName="empty-wrapper">
    <EmptyListImageComponent />
    <div styleName="empty-text">Issue Not Found</div>
  </div>
);

const LoadingIndication = () => (
  <div styleName="loading-wrapper">
    <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
  </div>
);

type PropTypes = {
  onBackClick: Function,
  onMetricItemClick: Function,
  isLoading: boolean,
  isHideInvestigationPanel: boolean,

  // connect
  selectedIssue: Object,
  isIssueNotFound: boolean,
  segmentClickEvent: Function,
};

export default connect(
  (state) => ({
    selectedIssue: getTMLeftPanelSelectedIssue(state),
    isIssueNotFound: getTMLeftPanelSpecialIssueNotFound(state),
  }),
  {
    segmentClickEvent,
  },
)(
  class SelectedIssuePanel extends PureComponent {
    props: PropTypes;

    onMetricItemClick = (item) => {
      if (this.props.onMetricItemClick) {
        this.props.onMetricItemClick(item);
      }
    };

    onInvestigationClicked = () => {
      this.props.segmentClickEvent({category: 'topology/investigate', name: 'Investigation'});
      window.open(this.props.selectedIssue.investigationUrl, '_newtab');
    };

    render() {
      const {selectedIssue, onBackClick, isLoading, isIssueNotFound, isHideInvestigationPanel} = this.props;

      if (isLoading) {
        return <LoadingIndication />;
      }

      if (isIssueNotFound) {
        return (
          <Fragment>
            <div styleName="back-button" onClick={onBackClick}>
              <i className="icn-arrow16-arrowleft" />
              <span>BACK</span>
            </div>
            <EmptyListIndication />
          </Fragment>
        );
      }

      if (!selectedIssue) {
        return null;
      }

      return (
        <Fragment>
          <div styleName="back-button" onClick={onBackClick}>
            <i className="icn-arrow16-arrowleft" />
            <span>BACK</span>
          </div>
          <div styleName="selected-issue-wrapper">
            <div styleName="selected-issue-name-wrapper">
              {selectedIssue.isOpen && (
                <div styleName="icon-section">
                  <SmartTooltip placement="top" content="Open Issue" delay={400}>
                    <i styleName="status-icon" />
                  </SmartTooltip>
                </div>
              )}
              <div styleName="data-section">
                <div className="ellipsis" styleName="name">
                  {selectedIssue.name}
                </div>
              </div>
            </div>
            <div styleName="summery-section">
              <div styleName="number">{selectedIssue.total}</div>
              <div styleName="sub-text">{selectedIssue.totalText}</div>
            </div>
          </div>
          <TinyScrollBox
            mt={1.5}
            width={PANEL_SIZES.tinyScrollBox}
            height="100%"
            css={{overflowY: 'auto', overflowX: 'hidden'}}
          >
            <Box pb={selectedIssue.investigationUrl ? 14.5 : 8} width={PANEL_SIZES.issueWidth}>
              {selectedIssue.metrics.map((metric, index) => (
                <MetricItem item={metric} key={metric.metricId} index={index} onClick={this.onMetricItemClick} />
              ))}
            </Box>
          </TinyScrollBox>
          {selectedIssue.investigationUrl && !isHideInvestigationPanel ? (
            <Box styleName="investigation-wrapper">
              <Box position="absolute" right="4px" left="-8px" bgcolor="gray.200" css={{height: '1px'}} />
              <Box display="flex" justifyContent="flex-end" pr={1.75} pl={1.75} mt={1.25}>
                <Button colorSchema={COLORS.BLUE_500} text="Investigation" onClick={this.onInvestigationClicked} />
              </Box>
            </Box>
          ) : null}
        </Fragment>
      );
    }
  },
);
