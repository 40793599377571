/* eslint-disable */
import {clone} from 'lodash';
function getUpdatePolicyByRollup(rollup, enabled) {
  const updatePolicies = {
    short: {
      step: 5,
      min: 5,
      value: 5,
      seconds: 60,
      scaleText: 'minutes',
      enabled,
    },
    medium: {
      step: 5,
      min: 5,
      value: 15,
      seconds: 60,
      scaleText: 'minutes',
      enabled,
    },
    long: {
      step: 5,
      min: 30,
      value: 30,
      seconds: 60,
      scaleText: 'minutes',
      enabled,
    },
    longlong: {
      step: 1,
      min: 1,
      value: 3,
      seconds: 60 * 60,
      scaleText: 'hours',
      enabled,
    },
    weekly: {
      step: 1,
      min: 1,
      value: 7,
      seconds: 60 * 60 * 24,
      scaleText: 'days',
      enabled,
    },
  };

  return clone(updatePolicies[rollup]);
}

const metricsConstants = {
  events: {
    clusterMapPropertyRemoveClicked: 'e:andt.metrics.metricsService.clusterMapPropertyRemoveClicked',
  },
  resolution: {
    // value must be same as attribute key (except auto)
    auto: {
      value: '',
      text: 'Auto',
      shortText: 'Auto',
      rank: null,
      ms: 0,
      enabled: true,
    },
    short: {
      value: 'short',
      text: '1 Minute',
      shortText: '1min',
      rank: 1,
      ms: 1000 * 60,
      enabled: true,
    },
    medium: {
      value: 'medium',
      text: '5 Minutes',
      shortText: '5min',
      rank: 2,
      ms: 1000 * 60 * 5,
      enabled: true,
    }, // always enabled
    long: {
      value: 'long',
      text: '1 Hour',
      shortText: '1hr',
      rank: 3,
      ms: 1000 * 60 * 60,
      enabled: true,
    },
    longlong: {
      value: 'longlong',
      text: '1 Day',
      shortText: '1day',
      rank: 4,
      ms: 1000 * 60 * 60 * 24,
      enabled: true,
    }, // always enabled
    weekly: {
      value: 'weekly',
      text: '1 Week',
      shortText: '1week',
      rank: 5,
      ms: 1000 * 60 * 60 * 24 * 7,
      enabled: true,
    },
  },
  metricNameSeparator: '.',
  metricNamePropertySeparator: '=',
  metricNameOriginPrefix: ' [',
  metricNameOriginSuffix: ']',
  originPrefix: '@',
  metricOriginTypes: {
    composite: 'COMPOSITE',
    alert: 'ALERT',
    stream: 'STREAM',
    forecast: 'FORECAST',
  },
  channel: {
    email: {
      value: 'email',
      text: 'Email',
      group: 1,
    },
    webhook: {
      value: 'webhook',
      text: 'Webhook',
      group: 2,
    },
    slack: {
      value: 'slack',
      text: 'Slack',
      group: 3,
    },
    pagerduty: {
      value: 'pagerduty',
      text: 'PagerDuty',
      group: 4,
    },
    jira: {
      value: 'jira',
      text: 'JIRA',
      group: 5,
    },
    opsgenie: {
      value: 'opsgenie',
      text: 'Opsgenie',
      group: 6,
    },
    msteams: {
      value: 'msteams',
      text: 'MS Teams',
      group: 7,
    },
    tamtam: {
      value: 'tamtam',
      text: 'Tam Tam',
      group: 8,
    },
  },
  usersChannel: {
    value: 'users',
    text: 'Users',
  },
  deltaTypes: {
    percentage: {
      value: 'percentage',
      text: 'Percentage',
      shortText: '%',
      min: 0,
      max: 1000,
      step: 1,
    },
    absolute: {
      value: 'absolute',
      text: 'Absolute',
      shortText: '',
    },
  },
  durationScales: {
    minutes: {
      text: 'Minutes',
      value: 'minutes',
      seconds: 60,
      min: 60,
      max: 60 * 60,
    },
    hours: {
      text: 'Hours',
      value: 'hours',
      seconds: 60 * 60,
      min: 60 * 60,
      max: 60 * 60 * 24,
    },
    days: {
      text: 'Days',
      value: 'days',
      seconds: 60 * 60 * 24,
      min: 60 * 60 * 24,
      max: 60 * 60 * 24 * 7,
    },
    weeks: {
      text: 'Weeks',
      value: 'weeks',
      seconds: 60 * 60 * 24 * 7,
      min: 60 * 60 * 24 * 7,
      max: 60 * 60 * 24 * 7 * 4,
    },
  },
  autoRefresh: {
    off: {value: -1, text: 'Off', badge: '-'},
    m1: {value: 60, text: 'Every Minute', badge: '1m'},
    m5: {value: 60 * 5, text: 'Every 5 Minutes', badge: '5m'},
    m10: {value: 60 * 10, text: 'Every 10 Minutes', badge: '10m'},
    m15: {value: 60 * 15, text: 'Every 15 Minutes', badge: '15m'},
    m30: {value: 60 * 30, text: 'Every 30 Minutes', badge: '30m'},
    h1: {value: 60 * 60, text: 'Every Hour', badge: '1h'},
  },
};

export function getDefaultUpdatePolicy() {
  const updatePolicy = getUpdatePolicyByRollup(metricsConstants.resolution.long.value);
  return {
    minInterval: updatePolicy.seconds * updatePolicy.value,
    scaleText: updatePolicy.scaleText,
    triggerOnMetricsChange: true,
    type: 'parameterChange',
  };
}

const constants = {
  namesPreviewMaxItems: 10,
  newAlertTitle: 'New Alert',
  serverAnomalyDirection: {
    up: 'up',
    down: 'down',
    both: 'both',
  },
  pauseOptions: {
    active: {
      id: 'active',
      title: 'Active',
    },
    hour: {
      moment: [1, 'hours'],
      title: 'For an hour',
      id: 'hour',
    },
    day: {
      moment: [1, 'days'],
      title: 'For a day',
      id: 'day',
    },
    week: {
      moment: [7, 'days'],
      title: 'For a week',
      id: 'week',
    },
    indefinitely: {
      id: 'indefinitely',
      title: 'Indefinitely',
    },
  },
  severity: {
    critical: {value: 'critical', text: 'Critical'},
    high: {value: 'high', text: 'High'},
    medium: {value: 'medium', text: 'Medium'},
    low: {value: 'low', text: 'Low'},
    info: {value: 'info', text: 'Info'},
  },
  alertConditionTypes: {
    staticThreshold: 'THRESHOLD_CONDITION',
    minDelta: 'DELTA_CONDITION',
    maxParticipatingMetrics: 'MAX_PARTICIPATING_METRICS_CONDITION',
    minParticipatingMetrics: 'MIN_PARTICIPATING_METRICS_CONDITION',
    influencingMetrics: 'INFLUENCING_METRICS_CONDITION',
    significanceCondition: 'SIGNIFICANCE_CONDITION',
    directionCondition: 'DIRECTION_CONDITION',
    durationCondition: 'DURATION_CONDITION',
    volumeCondition: 'VOLUME_CONDITION',
  },
  channel: {
    email: {value: 'email', text: 'Email'},
    webhook: {value: 'webhook', text: 'Webhhok'},
  },
  alertType: {
    anomaly: {
      text: 'Anomaly',
      value: 'anomaly',
    },
    static: {
      text: 'Static',
      value: 'static',
    },
    noData: {
      text: 'No Data Only',
      text2: 'No Data',
      value: 'noData',
    },
  },
  pausedType: {
    paused: {
      text: 'Paused',
    },
    active: {
      text: 'Active',
    },
  },
  defaultAlertSettingsFilter: {
    eventsFilter: getDefaultAlertEventsFilter(),
    isInfluencingEvents: false,
    rollup: metricsConstants.resolution.medium.value,
    updatePolicy: getDefaultUpdatePolicy(),
    noDataDuration: {
      min: metricsConstants.durationScales.hours.min,
      max: metricsConstants.durationScales.hours.max,
      step: metricsConstants.durationScales.hours.seconds,
      value: metricsConstants.durationScales.hours.min,
      scale: metricsConstants.durationScales.minutes,
    },
    type: {
      text: 'Anomaly',
      value: 'anomaly',
    },
  },
  alertFilter: {
    all: 0,
    myAlerts: 1,
    mySubs: 2,
  },
  events: {
    alertInfoChanged: 'e:andt.metrics.alerts.alertInfoChanged',
    alertInitialization: 'e:andt.metrics.alerts.alertInitialization',
    alertFilterChanged: 'e:andt.metrics.alerts.alertFilterChanged',
    alertTogglePause: 'e:andt.metrics.alerts.alertTogglefMute',
    alertToggleDuplicate: 'e:andt.metrics.alerts.alertTogglefDuplicate',
    alertToggleSubscribe: 'e:andt.metrics.alerts.alertToggleSubscribe',
    alertRemove: 'e:andt.metrics.alerts.alertRemove',
    alertAdded: 'e:andt.metrics.alerts.alertAdded',
    alertPulled: 'e:andt.metrics.alerts.alertPulled',
    sidebarClosed: 'e:andt.metrics.alerts.sidebarClosed',
    alertCreated: 'e:andt.metrics.alerts.alertCreated',
    alertEdited: 'e:andt.metrics.alerts.alertEdited',
    retrieveChannels: 'e:andt.metrics.alerts.retrieveChannels',
    updateFiltersFromReact: 'e:andt.metrics.alerts.updateFiltersFromReact',
    validateNoDataDuration: 'e:andt.metrics.alerts.validateNoDataDuration',
    setClientThresholdError: 'e:andt.metrics.alerts.setClientThresholdError',
  },
};

function getDefaultAlertEventsFilter() {
  const eventFilter = {
    presets: [],
    current: {
      filter: {
        categories: [],
        q: {
          expression: [],
        },
      },
      aggregation: {
        aggregationField: null,
        resolution: null,
        topEventSize: 0,
        maxBuckets: 100,
      },
      // dateRange: dateRangeService.getDate(),
      size: 0,
      index: 0,
    },
    shouldShowEvents: false,
  };
  eventFilter.current.filter.q.expression.push({
    type: 'property',
    key: 'source',
    value: 'NOT(anodot)',
  });
  return eventFilter;
}

export function convertDefinitionToFilter(alert) {
  const filter = {
    expressionTreeModel: alert.data.expressionTreeModel,
    filter: {
      rollup: alert.data.rollups,

      noDataAlert: alert.data.noDataAlert,
      eventsFilter: alert.data.eventsFilter,
      noDataDuration: {
        value: alert.data.noDataDuration,
      },
      isInfluencingEvents: alert.data.isInfluencingEvents,
      type: alert.data.staticAlert
        ? constants.alertType.static
        : !alert.data.anomalyAlert
        ? constants.alertType.noData
        : constants.alertType.anomaly,

      updatePolicy: alert.data.updatePolicy,
      conditions: _.cloneDeep(alert.data.conditions),
      enableAutoTuneByAnodot: alert.data.enableAutoTuneByAnodot || false,
      advancedModeOpen: alert.advancedModeOpen,
      notifyOnlyOpen: alert.data.notifyOnlyOpen,
    },
    pauseTime: alert.data.pauseTime,
    paused: alert.data.paused,
    owner: alert.owner,
    title: alert.data.title,
    story: alert.data.story,
    severity: getSeverityObject(alert.data.severity),
    channels: alert.data.channels,
  };

  // setEventsFilter(alert, filter);
  const defualtFilter = _.cloneDeep(constants.defaultAlertSettingsFilter);
  _.defaults(filter.filter, defualtFilter); // set the defualt values of the filter
  // setOldValidNoDataMinCompositeValueOrDefault(filter);
  return filter;
}

function getSeverityObject(severity) {
  switch (severity) {
    case constants.severity.critical.value:
      return constants.severity.critical;
    case constants.severity.high.value:
      return constants.severity.high;
    case constants.severity.medium.value:
      return constants.severity.medium;
    case constants.severity.low.value:
      return constants.severity.low;
    case constants.severity.info.value:
      return constants.severity.info;
  }
}
