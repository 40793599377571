// @flow
import {useMutation, useQueryClient} from 'react-query';
import {delete_} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'composites/services/compositesService';

const root = 'api/v1/metrics/';
const onDeleteComposite = (id) => {
  return delete_(`${root}composite/${id}`);
};

export default function useDeleteComposite() {
  const queryClient = useQueryClient();

  const {mutate: deleteComposite, isLoading: isDeleteCompositeLoading} = useMutation(
    ({payload}) => onDeleteComposite(payload),
    {
      onSuccess: (data, meta) => {
        queryClient.invalidateQueries([QUERY_KEYS.COMPOSITE_LIST]);
        meta.onSuccess(data);
      },
    },
  );

  return {deleteComposite, isDeleteCompositeLoading};
}
