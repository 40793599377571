import TimeZoneCommon from 'common/componentsV2/TimeZone';

import React, {useCallback} from 'react';
import {useField} from 'react-final-form';
import moment from 'moment';

const selectProps = {
  maxMenuHeight: 250,
  minMenuHeight: 250,
};

const TimeZone = () => {
  const {
    input: {value, onChange},
  } = useField('timeZone');

  const handleChange = useCallback(
    (timeZone) => {
      onChange(timeZone === 'Browser' ? moment.tz.guess() : timeZone);
    },
    [onChange],
  );
  return (
    <div>
      <div>Time Zone</div>
      <TimeZoneCommon automationId="timeZone" value={value} onChange={handleChange} selectProps={selectProps} />
    </div>
  );
};

export default TimeZone;
