// @flow
import React from 'react';
import {useField} from 'react-final-form';
import SelectAndt, {
  THEME_NOT_HIGHLIGHTED,
  TYPE_NEW_SEARCH,
} from '../../../common/componentsV2/ddl/selectAndt/SelectAndt';

const options = [
  {
    label: 'Case',
    value: 'case',
  },
  {
    label: 'Incident',
    value: 'incident',
  },
];

const selectProps = {
  maxMenuHeight: 250,
  minMenuHeight: 250,
};

export default function TypeFilter() {
  const {
    input: {value, onChange},
  } = useField('snowTable', {initialValue: options[0]});

  const initialValue = options.filter((option) => option.value === value)[0] ?? null;

  return (
    <div>
      <div>Table</div>
      <SelectAndt
        type={TYPE_NEW_SEARCH}
        theme={THEME_NOT_HIGHLIGHTED}
        onChange={onChange}
        automationId="snowTable"
        value={initialValue || value || options[0]}
        options={options}
        optionHeight={40}
        {...selectProps}
      />
    </div>
  );
}
