import moment from 'moment';
import {find, isObject, omit, pick} from 'lodash';
import {resolutionTypes} from 'metrics/services/metricsService';
import {getCorrectTimezoneName} from './dateService';

export const rangeTypes = {
  h1: {
    value: '1h',
    key: 'h1',
    momentStart: [1, 'hours'],
    text: 'Last Hour',
    shortText: 'an hour',
  },
  h3: {
    value: '3h',
    key: 'h3',
    momentStart: [3, 'hours'],
    text: 'Last 3 Hours',
  },
  h4: {
    value: '4h',
    key: 'h4',
    momentStart: [4, 'hours'],
    text: 'Last 4 Hours',
  },
  h12: {
    value: '12h',
    key: 'h12',
    momentStart: [12, 'hours'],
    text: 'Last 12 Hours',
  },
  d1: {
    value: '1d',
    key: 'd1',
    momentStart: [1, 'days'],
    text: 'Last 24 Hours',
    shortText: 'a day',
  },
  d2: {
    value: '2d',
    key: 'd2',
    momentStart: [2, 'days'],
    text: 'Last 48 Hours',
  },
  d3: {
    value: '3d',
    key: 'd3',
    momentStart: [3, 'days'],
    text: 'Last 3 Days',
  },
  w1: {
    value: '1w',
    key: 'w1',
    momentStart: [7, 'days'],
    text: 'Last 7 Days',
    shortText: 'a week',
  },
  d8: {
    value: '8d',
    key: 'd8',
    momentStart: [8, 'days'],
    text: 'Last 8 Days',
  },
  w2: {
    value: '2w',
    key: 'w2',
    momentStart: [14, 'days'],
    text: 'Last 14 Days',
    shortText: '2 weeks',
  },
  d15: {
    value: '15d',
    key: 'd15',
    momentStart: [15, 'days'],
    text: 'Last 15 Days',
  },
  m1: {
    value: '1m',
    key: 'm1',
    momentStart: [1, 'month'],
    text: 'Last 30 Days',
    shortText: 'a month',
  },
  m2: {
    value: '2m',
    key: 'm2',
    momentStart: [2, 'month'],
    text: 'Last 60 Days',
  },
  m3: {
    value: '3m',
    key: 'm3',
    momentStart: [3, 'month'],
    text: 'Last 90 Days',
    shortText: '3 month',
  },
  m6: {
    value: '6m',
    key: 'm6',
    momentStart: [6, 'month'],
    text: 'Last 180 Days',
    shortText: '6 month',
  },
  y1: {
    value: '1y',
    key: 'y1',
    momentStart: [1, 'year'],
    text: 'Last 365 Days',
    shortText: '1 year',
  },
  y2: {
    value: '2y',
    key: 'y2',
    momentStart: [2, 'year'],
    text: 'Last 2 Years',
    shortText: '2 year',
  },
  y5: {
    value: '5y',
    key: 'y5',
    momentStart: [5, 'year'],
    text: 'Last 5 Years',
    shortText: '5 year',
  },
  y10: {
    value: '10y',
    key: 'y10',
    momentStart: [10, 'year'],
    text: 'Last 10 Years',
    shortText: '10 year',
  },
  y15: {
    value: '15y',
    key: 'y15',
    momentStart: [15, 'year'],
    text: 'Last 15 Years',
    shortText: '15 year',
  },
  y20: {
    value: '20y',
    key: 'y20',
    momentStart: [20, 'year'],
    text: 'Last 20 Years',
    shortText: '120 year',
  },
  today: {
    value: 'today',
    key: 'today',
    momentStart: [0, 'days'],
    timePeriod: 'day',
    text: 'Today',
    shortText: 'Today',
  },
  yesterday: {
    value: 'yesterday',
    key: 'yesterday',
    momentStart: [1, 'days'],
    momentEnd: [1, 'days'],
    timePeriod: 'day',
    text: 'Yesterday',
    shortText: 'yesterday',
  },
  thisWeek: {
    value: 'thisWeek',
    key: 'thisWeek',
    momentStart: [0, 'days'],
    timePeriod: 'week',
    text: 'This Week',
    shortText: 'This Week',
  },
  lastWeek: {
    value: 'lastWeek',
    key: 'lastWeek',
    momentStart: [7, 'days'],
    momentEnd: [7, 'days'],
    timePeriod: 'week',
    text: 'Last Week',
    shortText: 'Last Week',
  },
  thisMonth: {
    value: 'thisMonth',
    key: 'thisMonth',
    momentStart: [0, 'months'],
    timePeriod: 'month',
    text: 'This Month',
    shortText: 'This Month',
  },
  lastMonth: {
    value: 'lastMonth',
    key: 'lastMonth',
    momentStart: [1, 'months'],
    momentEnd: [1, 'months'],
    timePeriod: 'month',
    text: 'Last Month',
    shortText: 'Last Month',
  },
  custom: {
    value: 'custom',
    key: 'custom',
    text: 'Custom',
  },
  c: {
    value: 'c',
    key: 'c',
    text: 'Custom Date',
  },
  r: {
    value: 'r',
    key: 'r',
    text: 'Relative',
  },
};

const businessPresetsKeys = ['today', 'yesterday', 'thisWeek', 'lastWeek', 'thisMonth', 'lastMonth'];

export const businessPresets = Object.values(pick(rangeTypes, businessPresetsKeys));

export const hourFormat = 'HH:mm';
export const dateFormat = `l ${hourFormat}`;

export const predefinedPresets = Object.values(
  omit(rangeTypes, ['h4', 'y2', 'y5', 'y10', 'y15', 'y20', 'c', 'r', 'custom', ...businessPresetsKeys]),
);
export const predefinedOptions = Object.values(
  omit(rangeTypes, ['h4', 'y2', 'y5', 'y10', 'y15', 'y20', 'r', 'c', ...businessPresetsKeys]),
);
export const DEFAULT_RANGE_OPTIONS = ['h3', 'd1', 'w1', 'm1', 'm2', 'custom'];
export const DEFAULT_SHARE_PUBLIC_RANGE_OPTIONS = ['m3', 'm6'];

const getNewMoment = (date) => {
  if (!moment.isMoment(date)) {
    return date && date.toString().length === 10 ? moment.unix(date) : moment(date);
  }
  return moment(date);
};

export const correctTimestamp = (timestamp) => {
  if (moment.isMoment(timestamp)) {
    return timestamp.valueOf();
  }

  if (timestamp && String(timestamp).length === 10) {
    return moment.unix(timestamp).valueOf();
  }

  return moment(timestamp).valueOf();
};

export const correctTimestampUnix = (timestamp) => moment(correctTimestamp(timestamp)).unix();

export const getDate = (val, timeZoneName, defaultVal = rangeTypes.m1) => {
  let type = val;
  const dateRange = {};

  if (isObject(val)) {
    type = val.constRange || val.value;

    // clone
    dateRange.constRange = val.constRange || val.value;
    dateRange.startDate = getNewMoment(val.startDate);
    dateRange.endDate = getNewMoment(val.endDate);
    dateRange.dateText = val.dateText;
    dateRange.shortText = val.shortText;
    dateRange.relativeLast = val.relativeLast;
    dateRange.relativeNext = val.relativeNext;
  }
  if (type === rangeTypes.c.value) {
    dateRange.constRange = rangeTypes.c.value;
    if (!moment.isMoment(dateRange.startDate)) {
      dateRange.startDate = getNewMoment(dateRange.startDate);
    }
    if (!moment.isMoment(dateRange.endDate)) {
      dateRange.endDate = getNewMoment(dateRange.endDate);
    }
    // eslint-disable-next-line no-param-reassign
    timeZoneName = getCorrectTimezoneName(timeZoneName);

    dateRange.dateText = `${dateRange.startDate
      .utcOffset(timeZoneName)
      .format(dateFormat)} - ${dateRange.endDate.utcOffset(timeZoneName).format(dateFormat)}`;
  } else if (type === rangeTypes.r.value) {
    dateRange.constRange = rangeTypes.r.value;
    const rangeType = {
      last: [dateRange.relativeLast, 'second'],
      next: [dateRange.relativeNext, 'second'],
    };
    dateRange.startDate = rangeType.momentEnd
      ? moment().subtract(rangeType.last[0], rangeType.last[1])
      : moment().subtract(rangeType.last[0], rangeType.last[1]);
    dateRange.endDate = rangeType.next ? moment().add(rangeType.next[0], rangeType.next[1]) : moment();
    dateRange.dateText = rangeType.text;
    dateRange.shortText = rangeType.shortText;
  } else {
    const rangeType = find(rangeTypes, {value: type}) || find(rangeTypes, {key: type}) || defaultVal;
    dateRange.constRange = rangeType.value;
    dateRange.startDate =
      rangeType.momentEnd || rangeType.timePeriod
        ? moment()
            .subtract(rangeType.momentStart[0], rangeType.momentStart[1])
            .startOf(rangeType.timePeriod)
        : moment().subtract(rangeType.momentStart[0], rangeType.momentStart[1]);
    dateRange.endDate = rangeType.momentEnd
      ? moment()
          .subtract(rangeType.momentEnd[0], rangeType.momentEnd[1])
          .endOf(rangeType.timePeriod)
      : moment();
    dateRange.dateText = rangeType.text;
    dateRange.shortText = rangeType.shortText;
  }

  return dateRange;
};

export const getDateValue = (val, isUnix, timeZoneName) => {
  const valuesWithMomentObj = getDate(val, timeZoneName);

  const rangeType =
    find(rangeTypes, {value: valuesWithMomentObj.constRange}) ||
    find(rangeTypes, {key: valuesWithMomentObj.constRange});

  return {
    constRange: isUnix ? rangeType.key : valuesWithMomentObj.constRange,
    startDate: isUnix ? valuesWithMomentObj.startDate.unix() : valuesWithMomentObj.startDate.valueOf(),
    endDate: isUnix ? valuesWithMomentObj.endDate.unix() : valuesWithMomentObj.endDate.valueOf(),
    relativeLast: valuesWithMomentObj.relativeLast,
    relativeNext: valuesWithMomentObj.relativeNext,
  };
};

export const getTimeRangeByRollup = (rollup) => {
  let timeRange = null;

  switch (rollup) {
    case resolutionTypes.short.value: {
      timeRange = rangeTypes.w1.value;
      break;
    }
    case resolutionTypes.medium.value: {
      timeRange = rangeTypes.m1.value;
      break;
    }
    case resolutionTypes.long.value: {
      timeRange = rangeTypes.m6.value;
      break;
    }
    case resolutionTypes.longlong.value: {
      timeRange = rangeTypes.y1.value;
      break;
    }
    case resolutionTypes.weekly.value: {
      timeRange = rangeTypes.y1.value;
      break;
    }
    default:
  }
  return getDate(timeRange);
};

export const createRelativeLabel = (relativeLast, relativeNext) => {
  const getValue = (value) => (value % (3600 * 24) === 0 ? value / (3600 * 24) : value / 3600);
  const formatRelative = (relativeValue) => {
    return `${getValue(relativeValue)} ${relativeValue % (3600 * 24) === 0 ? 'day' : 'hour'}${
      getValue(relativeValue) === 1 ? '' : 's'
    }`;
  };
  return `Last ${formatRelative(relativeLast)}${relativeNext ? `, next ${formatRelative(relativeNext)}` : ''}`;
};

export const createCustomLabel = (startDate, endDate) => {
  const timeStampStartDate = moment.unix(correctTimestampUnix(startDate));
  const timeStampEndDate = moment.unix(correctTimestampUnix(endDate));
  const isSameMonth = timeStampStartDate.month() === timeStampEndDate.month();
  const isSameYear = timeStampStartDate.year() === timeStampEndDate.year();

  if (timeStampStartDate.dayOfYear() === timeStampEndDate.dayOfYear()) {
    return timeStampStartDate.format('MMM D, YYYY');
  }

  if (isSameMonth && isSameYear) {
    return `${timeStampStartDate.format('MMM D')} - ${timeStampEndDate.format('D, YYYY')}`;
  }

  if (isSameYear) {
    return `${timeStampStartDate.format('MMM D')} - ${timeStampEndDate.format('ll')}`;
  }

  if (!isSameYear) {
    return `${timeStampStartDate.format('ll')} - ${timeStampEndDate.format('ll')}`;
  }

  return `${timeStampStartDate.format('ll')} - ${timeStampEndDate.format('ll')}`;
};

export const getDateRange = (constRange) => {
  return rangeTypes[constRange] ? rangeTypes[constRange].text : rangeTypes.d3.text;
};
