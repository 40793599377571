import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import {composeReducers} from '../../../common/utils/reducers';
import * as actions from '../actions';

const EMPTY_OBJECT = {};

export default combineReducers({
  preview: composeReducers(
    makeAsyncReducer(actions.fetchSumologicPreview, {defaultData: EMPTY_OBJECT, errorHandler: bcErrorHandler}),
  ),
});
