import React, {useState} from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';

type PropTypes = {
  isCreate: Boolean,
};

const additionalProps = {
  autoComplete: 'off',
};

const Password = ({isCreate}: PropTypes) => {
  const {
    input: {value, onChange},
    meta,
  } = useField('password');

  const [initialValue, setInitialValue] = useState(!isCreate ? 'fake password' : '');

  return (
    <Input
      additionalProps={additionalProps}
      type="password"
      placeHolder="Password"
      automationId="password"
      isInvalid={meta.touched && meta.error}
      invalidMessage={meta.error}
      value={value || initialValue}
      onChange={onChange}
      onFocus={() => {
        setInitialValue('');
      }}
    />
  );
};

export default Password;
