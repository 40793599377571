import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {COLUMNS_TYPES} from 'dataCollectors/services/dataCollectorsService';

const root = 'api/v1/bc/';

const getDatasetTableMetadata = async (type, sourceId, projectId, datasetId, tableName) => {
  return get(
    `${root}data-sources/${type}/${sourceId}/metadata?projectId=${projectId}&datasetId=${datasetId}&tableId=${tableName}`,
  );
};

export default function useFetchDatasetTableMetadata(type, sourceId, projectId, datasetId, tableName) {
  const fallback = [];

  const selectFn = useCallback(
    (data) => {
      return data
        ? data.map((i) => ({type: i.type, label: i.name, value: i.name, icon: COLUMNS_TYPES[i.type].icon}))
        : [];
    },
    [sourceId],
  );

  const {
    data: metadata = fallback,
    isLoading: isMetadataLoading,
    isFetching: isMetadataFetching,
    refetch: refetchMetadataFetching,
  } = useQuery(
    QUERY_KEYS.datasetTablesMetadata,
    () => getDatasetTableMetadata(type, sourceId, projectId, datasetId, tableName),
    {
      enabled: !!(type && sourceId && projectId && datasetId && tableName),
      select: selectFn,
    },
  );

  return {metadata, isMetadataLoading, isMetadataFetching, refetchMetadataFetching};
}
