import React, {useMemo, useRef, useCallback, useState} from 'react';
import {removeUIData, removeAliasRootFuncFromTree, detachAlertFromTilePayload} from 'dashboards/utils';
import {get, keyBy, isEqual} from 'lodash';
import deepSearch from 'common/utils/deepSearch';
import NotificationModal from 'dashboards/components/modals/NotificationModal';
import AlertUpdateConfirmationModal from 'dashboards/components/modals/AlertUpdateConfirmationModal';
import useAsyncAction from 'common/utils/useAsyncAction';
import {updateDashboard} from 'dashboards/store/actions';

const useAlertUpdateConfirmation = ({tileData, alerts, dashboard}) => {
  const [isConfirmationVisible, setConfirmationVisible] = useState(false);
  const [notification, setNotification] = useState(null);

  const alertMap = useMemo(() => keyBy(alerts, 'tileId'), [alerts]);
  const attachedAlertId = get(alertMap[tileData.id], 'alertId');

  const alertConfirmationDeferred = useRef();
  const [actualCompositeObject, setActualCompositeObject] = useState();

  const updateDashboardAsync = useAsyncAction(updateDashboard, dashboard);

  const savedCompositeObject = useMemo(() => {
    let dataKey = tileData.lineChart ? 'lineChart' : 'andtGauge';
    if (tileData.tileType) dataKey = 'tileConfig';
    if (tileData.tileConfig || tileData.lineChart || tileData.andtGauge) {
      return Object.values(tileData[dataKey].expressionTrees)?.[0];
    }
    return undefined;
  }, [tileData]);
  const onAlertUpdateConfirm = useCallback(() => {
    setConfirmationVisible(false);
    alertConfirmationDeferred.current.resolve(true);
  }, []);
  const onAlertUpdateClose = useCallback(() => {
    setConfirmationVisible(false);
    setNotification(null);
    alertConfirmationDeferred.current.resolve(false);
  }, []);
  const onDetachAlert = useCallback(() => {
    updateDashboardAsync(detachAlertFromTilePayload(dashboard.data, tileData.id, attachedAlertId)).then(() => {
      setConfirmationVisible(false);
      alertConfirmationDeferred.current.resolve(true);
    });
  }, [dashboard]);

  const askForConfirmation = (formValues) =>
    new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      const expressionTrees =
        formValues.expressionTreeType === 'simple'
          ? [formValues.simpleExpressionTree]
          : formValues.advancedExpressionTree;
      const noEmptyFunctionExpressionTree =
        get(expressionTrees[0].expressionTree, 'root.function') === ''
          ? {root: expressionTrees[0].expressionTree.root.children[0]}
          : expressionTrees[0].expressionTree;

      const actualExpressionTree = removeUIData(noEmptyFunctionExpressionTree);

      const isTreeChanged = !isEqual(removeUIData(savedCompositeObject.expressionTree), actualExpressionTree);

      alertConfirmationDeferred.current = {resolve, reject};
      if (expressionTrees.length > 1 && attachedAlertId) {
        setNotification({
          title: 'Cannot Update Alert',
          description: 'There are multiple expressions in this tile, an alert must be on a single expression.',
        });
        return;
      }
      // eslint-disable-next-line max-len
      if (
        attachedAlertId &&
        deepSearch(
          removeAliasRootFuncFromTree({expressionTree: noEmptyFunctionExpressionTree}).expressionTree,
          'isFunctionDisplayOnly',
          (k, v) => v === true,
        )
      ) {
        setNotification({
          title: 'Cannot Update Alert',
          description: 'Cannot update attached alert because the expression uses a “Display only” function.',
        });
        return;
      }
      if (attachedAlertId && isTreeChanged) {
        const noAliasTree = removeAliasRootFuncFromTree({expressionTree: actualExpressionTree}).expressionTree;
        const isEmptyFunction = !noAliasTree.root.function;
        setActualCompositeObject({
          compositeCount: 0,
          // displayOnly: isEmptyFunction,
          displayOnly: false, // TODO: double check on dashboards V1
          title: 'Alert',
          excludeComposites: !isEmptyFunction,
          namingSchema: isEmptyFunction ? undefined : 'COMPOSITE_V2',
          mtype: 'ALERT',
          filter: null,
          uiIndex: 0,
          expressionTree: noAliasTree,
          name: null,
        });
        setConfirmationVisible(true);
      } else {
        resolve(true);
      }
    });

  const notificationModalElement = useMemo(
    () => (notification ? <NotificationModal {...notification} onClose={onAlertUpdateClose} /> : null),
    [notification, onAlertUpdateClose],
  );

  const confirmationModalElement = useMemo(
    () =>
      isConfirmationVisible ? (
        <AlertUpdateConfirmationModal
          compositeObject={actualCompositeObject}
          attachedAlertId={attachedAlertId}
          onConfirm={onAlertUpdateConfirm}
          onClose={onAlertUpdateClose}
          onDetachAlert={onDetachAlert}
          isUpdateDashboardLoading={dashboard.isLoading}
        />
      ) : null,
    [
      attachedAlertId,
      onAlertUpdateConfirm,
      onAlertUpdateClose,
      actualCompositeObject,
      isConfirmationVisible,
      dashboard,
    ],
  );
  return {
    askForConfirmation,
    confirmationModalElement: notificationModalElement || confirmationModalElement,
  };
};

export default useAlertUpdateConfirmation;
