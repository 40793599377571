import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import {computeDecimal, setMaxPercentToDisplayTrimmed} from 'common/utils/numbers';
import * as dateRangeService from 'common/utils/dateRangeService';
import {format, Humanize} from 'common/utils/utilsService';
import {DECIMAL_COUNT} from 'dashboards/services/dashboardService';
import formatNumber from 'common/utils/formatNumber';
import {getPropsList} from '../utils';

const useStyles = makeStyles(() => ({
  tooltipWrapper: {
    fontFamily: 'Roboto',
    width: 'max-content',
    borderRadius: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    padding: ({isLegend}) => (isLegend ? 0 : '8px'),
    boxShadow: ({isLegend}) => (isLegend ? 'none' : '0 2px 10px 0 rgba(0, 0, 0, 0.2)'),
    fontSize: 12,
    fontWeight: 400,
    maxWidth: '233px',
  },
  valueWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '8px 0',
  },
  value: {
    borderRadius: 10,
    lineHeight: 1,
    padding: '2px 8px',
    fontSize: 16,
    fontWeight: 'bold',
    color: palette.white[500],
  },
  measureWrapper: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: 400,
    color: palette.gray[400],
    marginBottom: '4px',
  },
  whatText: {
    color: palette.purple[600],
    fontSize: '14px',
    fontWeight: 500,
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  propsWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 4,
    fontSize: 14,
    fontWeight: 500,
  },
  propsListKey: {
    width: 85,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    flexShrink: 0,
    marginRight: 8,
    fontSize: 12,
    color: palette.gray[400],
    lineHeight: '16px',
    overflow: 'hidden',
  },
  propsListValue: {
    minWidth: 56,
    lineHeight: '16px',
    color: palette.mint[600],
    wordBreak: 'break-all',
    whiteSpace: 'normal',
  },
}));

const TotalTemplate = (props: {minPoint: Object}) => {
  return (
    <span>
      {`Total: ${formatNumber(
        parseFloat(props.minPoint.total.toFixed(computeDecimal(props.minPoint.total))),
        3,
        ',',
        '.',
      )}`}
    </span>
  );
};

const PieSliceTooltip = ({
  data,
  isLegend,
  isDecimal,
  isAbbreviate,
}: {
  data: Object,
  isLegend: boolean,
  isDecimal: boolean,
  isAbbreviate: boolean,
}) => {
  const classes = useStyles({isLegend});

  const {
    dateRange: {startDate, endDate},
    what,
    percentage,
  } = data.point.options;

  const propsList = useMemo(() => getPropsList(data.point.options), [data]);

  const abbreviateValue = Humanize.humanize(data.y, DECIMAL_COUNT);
  const formattedValue = format(
    isAbbreviate ? abbreviateValue.number : data.y,
    3,
    ',',
    '.',
    isDecimal ? DECIMAL_COUNT : 0,
  );

  return (
    <div>
      <div className={classes.tooltipWrapper}>
        <div className="text12med">{dateRangeService.createCustomLabel(startDate, endDate)}</div>
        <div className={classes.valueWrapper}>
          <div className={classes.value} style={{backgroundColor: data.point.color}}>
            {`${formattedValue} ${isAbbreviate ? abbreviateValue.postfix : ''}`}
            {percentage !== undefined ? ` | ${setMaxPercentToDisplayTrimmed(percentage)}%` : ''}
          </div>
        </div>
        {data.point.total && percentage && percentage.toFixed(0) !== '100' && <TotalTemplate minPoint={data.point} />}
        <div className={classes.measureWrapper}>
          <div className={`${classes.whatText} ${classes.ellipsis}`}>{what}</div>
        </div>
        {propsList.map((item) => (
          <div className={classes.propsWrapper} key={item.key}>
            <div className={`${classes.propsListKey} ${classes.ellipsis}`}>{item.key}</div>
            <div className={`${classes.propsListValue}`}>{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieSliceTooltip;
