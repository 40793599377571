import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import Spinner from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';
import useSectionsClasses from 'dataCollectors/styles/classes.useSectionsClasses';
import Table1 from 'dataCollectors/components/tables/Table1';
import {useFormContext} from 'react-hook-form';
import usePreviewTableData from './hook/usePreviewTableData';

const {sections} = useSectionsClasses();

const useStyles = makeStyles(({typography}) => ({
  previewWrapper: {
    position: 'relative',
  },
  previewLink: {
    ...typography.subtitle3,
    color: palette.blue['500'],
    cursor: 'pointer',
  },
  previewLinkDisabled: {
    ...typography.subtitle3,
    pointerEvents: 'none',
    cursor: 'default',
    color: palette.blue['300'],
  },
  loaderWrapper: {
    position: 'absolute',
  },
  tableContainer: {
    overflow: 'auto',
    width: '100%',
  },
}));

export default function PreviewTable({leftSideWidth, reftechPreviewTable, fileFormat}) {
  const sectionsClasses = sections({leftSideWidth});
  const classes = useStyles();

  const {getValues} = useFormContext();
  const {isTablePreviewFetching, tablePreviewData, refetchTablePreviewData} = usePreviewTableData(
    getValues(),
    fileFormat,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    refetchTablePreviewData({cancelRefetch: true});
  }, [reftechPreviewTable]);

  return (
    <>
      <div>
        <div className={`${sectionsClasses.previewWrapper} ${sectionsClasses.marginTop}`}>
          <div className={classes.loaderWrapper}>
            {isTablePreviewFetching ? (
              <Spinner color={palette.gray[500]} size={30} />
            ) : (
              <span
                onClick={() => setIsModalOpen(true)}
                className={
                  tablePreviewData?.columns && tablePreviewData?.columns?.length
                    ? classes.previewLink
                    : classes.previewLinkDisabled
                }
              >
                {fileFormat ? 'Preview File' : 'Preview Table'}
              </span>
            )}
          </div>
        </div>
        <Modal
          id="2-22-222"
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          size={SIZES.MAXWIDTH}
          isStatic={false}
          height={650}
        >
          <Table1
            columns={tablePreviewData?.columns || []}
            data={tablePreviewData?.data || []}
            isSortable
            isGlobalSearch
            height={650}
          />
        </Modal>
      </div>
    </>
  );
}

PreviewTable.defaultProps = {
  fileFormat: null,
  leftSideWidth: '100%',
};

PreviewTable.propTypes = {
  reftechPreviewTable: PropTypes.bool.isRequired,
  fileFormat: PropTypes.objectOf(PropTypes.string),
  leftSideWidth: PropTypes.number,
};
