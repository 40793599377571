import React from 'react';
import {useField} from 'react-final-form';
import Checkbox from 'common/componentsV2/Checkbox';
import {CHANNEL_TYPES} from 'channels/constants/channelTypes';

const UngroupAlertChannel = ({
  isChannelUngrouped = false,
  channelType = '',
}: {
  isChannelUngrouped: boolean,
  channelType: string,
}) => {
  const {
    input: {value: isUngrouped, onChange: onUngroupChannel},
  } = useField('isChannelUngrouped', {initialValue: isChannelUngrouped});

  const {
    input: {value: accountDimension},
  } = useField('accountDimension');

  return (
    <>
      <Checkbox
        automationId={`ungroup-channel-${channelType}`}
        isChecked={isUngrouped}
        isDisabled={!!(channelType === CHANNEL_TYPES.SALESFORCE && accountDimension)}
        onChange={() => onUngroupChannel(!isUngrouped)}
        text="Ungroup metrics in alert"
      />
    </>
  );
};

export default UngroupAlertChannel;
