import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/features/');

const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/features/');

export const fetchCustomers = macAsync('FETCH_CUSTOMERS');
export const fetchDefaults = macAsync('FETCH_DEFAULTS');
export const updateCustomersAndGlobal = macAsync('UPDATE_CUSTOMERS_AND_GLOBAL');

export const mergeCustomers = mac('MERGE_CUSTOMERS');
export const mergeGlobal = mac('MERGE_GLOBAL');
export const toggleFeatureCustomer = mac('TOGGLE_FEATURE_CUSTOMER');
export const toggleFeatureGlobal = mac('TOGGLE_FEATURE_GLOBAL');
export const checkIfAll = mac('CHECK_IF_ALL');
export const toggleAll = mac('TOGGLE_ALL');
export const resetGlobal = mac('RESET_GLOBAL');
export const resetCustomers = mac('RESET_CUSTOMERS');
export const changeQueryCustomers = mac('CHANGE_QUERY_CUSTOMER');
export const changeQueryGlobal = mac('CHANGE_QUERY_GLOBAL');
export const changeRndOnly = mac('CHANGE_RND_ONLY');
export const changeGlobalDefaultOn = mac('CHANGE_GLOBAL_DEFAULT_ON');
export const changeDataCollectors = mac('CHANGE_DATA_COLLECTORS_ON');
