// @flow

import React from 'react';
import {connect} from 'react-redux';
import * as actions from 'common/store/actions';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import {getRoutingLocation} from 'common/store/selectors';
import {toast} from 'react-toastify';

import './Snackbar.module.scss';

type PropTypes = {
  notificationData: {
    title: String,
    description: String,
    moreInfo: String,
    children: any,
    isOpenInfoInModal: boolean,
  },
  type: String,
  uid: String,
  isDismissible: boolean,
  actions: [],

  // connect
  // eslint-disable-next-line react/no-unused-prop-types
  location: any,
  segmentClickEvent: Function,
};

export const TYPES = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

export default connect(
  (state) => ({
    location: getRoutingLocation(state),
  }),
  {
    segmentClickEvent: actions.segmentClickEvent,
  },
)(
  class SnackBar extends React.PureComponent {
    props: PropTypes;

    state = {
      moreInfo: false,
      isModalOpen: false,
    };

    componentDidMount() {
      this.props.segmentClickEvent({
        type: 'notification',
        notificationType: this.props.type,
        notificationData: this.props.notificationData,
      });
    }

    removeNotification = () => {
      toast.dismiss(this.props.uid);
      this.onModalClose();
    };

    handleAction = (actionCallback) => {
      actionCallback();
      toast.dismiss(this.props.uid);
    };

    showMoreInfo = () => {
      if (this.props.notificationData.isOpenInfoInModal) {
        this.setState({isModalOpen: true});
      } else {
        const {moreInfo} = this.state;
        this.setState({moreInfo: !moreInfo});
      }
    };

    onModalClose = () => {
      this.setState({isModalOpen: false});
    };

    render() {
      // eslint-disable-next-line no-shadow
      const {notificationData, type, isDismissible, actions} = this.props;
      const formattedDescription =
        notificationData.description && notificationData.description.length
          ? notificationData.description.split('\n')
          : null;

      const {moreInfo} = this.state;

      return (
        <div styleName={`notification-child-container ${type}`}>
          <Modal
            isCloseButtonHidden={false}
            size={SIZES.LARGE}
            isOpen={this.state.isModalOpen}
            onClose={this.onModalClose}
          >
            <div styleName="modal-message">
              <p>{notificationData.moreInfo}</p>
            </div>
          </Modal>
          {isDismissible && <i className="icn-general16-closeb" onClick={this.removeNotification} />}
          {notificationData.title && <h2>{notificationData.title}</h2>}

          {notificationData.children && <p>{notificationData.children}</p>}

          {formattedDescription && (
            <div>
              {formattedDescription.map((item) => (
                <p key={item}>{item}</p>
              ))}
              {moreInfo && <p>{notificationData.moreInfo}</p>}
            </div>
          )}

          {!notificationData.description && notificationData.moreInfo && <p>{notificationData.moreInfo}</p>}

          {(actions && actions.length) || (notificationData.description && notificationData.moreInfo && !moreInfo) ? (
            <div styleName="actions-bar">
              {notificationData.description && notificationData.moreInfo && !moreInfo && (
                <a styleName="more-info-btn" onClick={this.showMoreInfo}>
                  <span>More Info</span>
                </a>
              )}

              {actions &&
                actions.length &&
                actions.map((action) => (
                  <a
                    styleName="more-info-btn"
                    onClick={() => this.handleAction(action.callback)}
                    key={`snakeBar-action-${action.label}`}
                  >
                    <span>{action.label}</span>
                  </a>
                ))}
            </div>
          ) : null}
        </div>
      );
    }
  },
);
