import moment from 'moment';
import {BUFFER_TIMES} from 'investigationNewAlertConsole/services/investigationService';

export const setMeasures = (metrics) => {
  const obj = {};

  if (metrics?.length) {
    metrics.forEach((metric) => {
      if (metric.what && obj[metric.what]) {
        // eslint-disable-next-line operator-assignment
        obj[metric.what] = obj[metric.what] + 1;
      } else if (metric.what && !obj[metric.what]) {
        obj[metric.what] = 1;
      }
    });
  }
  return Object.keys(obj)?.sort((a, b) => obj[b] - obj[a]);
};

export const anomalyIntervalsToAlertTriggerFormat = (anomalyIntervals) => {
  if (!anomalyIntervals || anomalyIntervals.length === 0) {
    return null;
  }

  return (anomalyIntervals || []).map((a) => ({
    startTime: a.startDate,
    endTime: a.endDate,
    duration: a.endDate - a.startDate,
    status: a.state.toLowerCase() === 'open' ? 'OPEN' : 'CLOSE',
    score: a.score,
    direction: a.directionUp ? 'UP' : 'DOWN',
    peak: a.peakValue,
    deltaAbsolute: a.absoluteDelta,
    deltaPercentage: a.percentageDelta,
    sumDeltas: a.anomalySumDeltas,
  }));
};

const setDuration = (metric) => {
  const lastAnomalyInterval = metric.currentAnomalyIntervals.length ? metric.currentAnomalyIntervals.length - 1 : 0;
  const {startDate} = metric.currentAnomalyIntervals[0];
  const {endDate} = metric.currentAnomalyIntervals[lastAnomalyInterval];

  return endDate - startDate;
};

export const mergeMetrics = (metrics, resolution) => {
  return metrics.map((metric) => ({
    ...metric,
    duration: setDuration(metric),
    deltaPercentage: metric.lowerPercentageDelta ? metric.lowerPercentageDelta * -1 : metric.upperPercentageDelta,
    bufferedStartTime: metric.currentAnomalyIntervals[0].startDate - (BUFFER_TIMES.anomalies[resolution] || 0),
    bufferedEndTime: Math.min(
      metric.currentAnomalyIntervals[0].endDate + (BUFFER_TIMES.anomalies[resolution] || 0),
      Math.floor(moment.now() / 1000),
    ),
    intervals: anomalyIntervalsToAlertTriggerFormat(
      metric.otherAnomalyIntervals.concat(metric.currentAnomalyIntervals),
    ),
  }));
};

export const getAlertMetricsIncident = (metrics, measures) => {
  const filteredMetrics = {};
  Object.keys(metrics).forEach((what) => {
    if (measures.includes(what)) {
      filteredMetrics[what] = metrics[what];
    }
  });
  return filteredMetrics;
};

export const getAllMetricsIncident = (allData, alertData, measures) => {
  const relevantData = {};

  Object.keys(allData).forEach((what) => {
    const all = allData[what];
    const alert = alertData[what];
    if (all?.data && alert?.data && measures?.includes(what)) {
      const allMetrics = all.data.metrics;
      const alertMetrics = alert.data.metrics;
      const filteredMetrics = allMetrics.filter((i) =>
        alertMetrics.length === 0 ? allMetrics : !alertMetrics.some((j) => i.id === j.id),
      );

      relevantData[what] = {
        ...all,
        data: {
          ...all.data,
          metrics: filteredMetrics,
        },
      };
    }
  });
  return relevantData;
};

export const getIncidentTokenMapFiltered = (tokenMap, measures) => {
  const filteredTokens = {};

  if (!tokenMap || !measures?.length) {
    return filteredTokens;
  }

  measures.forEach((what) => {
    if (tokenMap.dimMap[what]) {
      const filteredFromMetaData = {};
      Object.keys(tokenMap.dimMap[what]).forEach((key) => {
        if (key !== 'func' && key !== 'mtype') {
          filteredFromMetaData[key] = tokenMap.dimMap[what][key];
        }
      });
      filteredTokens[what] = filteredFromMetaData;
    }
  });

  return filteredTokens;
};

export const findTrigger = (triggeredAlertsGroup, alertTriggerId) => {
  let tmpTrigger;
  if (triggeredAlertsGroup?.alerts?.length > 0) {
    tmpTrigger = triggeredAlertsGroup.alerts.find((alert) => alert.alertTriggerId === alertTriggerId);
  }
  return tmpTrigger;
};
