import {useSelector} from 'react-redux';
import {
  getMattermostEnabled,
  getSlackappEnabled,
  getTelegramEnabled,
  getServiceNowEnabled,
  getSalesforceEnabled,
} from 'profile/store/selectors';
import channelTypes, {CHANNEL_TYPES} from 'channels/constants/channelTypes';

export default function useChannelsTypesFiltered() {
  const isSlackappEnabled = useSelector(getSlackappEnabled);
  const isTelegramEnabled = useSelector(getTelegramEnabled);
  const isMattermostEnabled = useSelector(getMattermostEnabled);
  const isServiceNowEnabled = useSelector(getServiceNowEnabled);
  const isSalesforceEnabled = useSelector(getSalesforceEnabled);

  return channelTypes.filter((channelType) => {
    if (channelType.value === CHANNEL_TYPES.SLACKAPP) {
      return isSlackappEnabled;
    }
    if (channelType.value === CHANNEL_TYPES.TELEGRAM) {
      return isTelegramEnabled;
    }
    if (channelType.value === CHANNEL_TYPES.MATTERMOST) {
      return isMattermostEnabled;
    }
    if (channelType.value === CHANNEL_TYPES.SERVICENOW) {
      return isServiceNowEnabled;
    }
    if (channelType.value === CHANNEL_TYPES.SALESFORCE) {
      return isSalesforceEnabled;
    }
    return true;
  });
}
