// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import DataTablePreviewModal from 'bc/components/streams/editor/common/DataTablePreviewModal';
import {fetchNewrelicPreview as fetchNewrelicPreviewAction} from 'bc/store/actions';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,

  // connect
  dataStream: Object,
  fetchNewrelicPreview: Function,
  isLoading: Boolean,
  previewData: Object,
};

class NewrelicPreview extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {dataStream, fetchNewrelicPreview} = this.props;
    fetchNewrelicPreview({
      dataSourceId: dataStream.dataSourceId,
      accountId: dataStream.accountId,
      eventType: dataStream.eventType,
    });
  }

  render() {
    const {isOpen, onClose, previewData, isLoading, fetchNewrelicPreview} = this.props;

    return (
      <DataTablePreviewModal
        isOpen={isOpen}
        onClose={onClose}
        title="Preview"
        subTitle=""
        previewData={previewData}
        isLoading={isLoading}
        fetchPreview={fetchNewrelicPreview}
      />
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    previewData: selectors.getNewrelicPreviewDataRows(state),
    isLoading: selectors.getNewrelicPreview(state).isLoading,
  }),
  {
    fetchNewrelicPreview: fetchNewrelicPreviewAction,
  },
)(NewrelicPreview);
