// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import * as filterSelector from 'alerts.management/store/filterSelector';

const EMPTY_ARRAY = [];

type PropTypes = {
  setQueryParams: Function,

  // connect
  alertTypes?: Array,
  selectedAlertType: Array,
};

export default connect(
  (state) => ({
    alertTypes: filterSelector.getFilterAlertTypeWithCount(state),
    selectedAlertType: filterSelector.getSelectedFilterType(state),
  }),
  {},
)(
  class TypeFilter extends React.PureComponent {
    props: PropTypes;

    static defaultProps = {
      alertTypes: EMPTY_ARRAY,
    };

    onChange = (items) => {
      const typeVal = items.length ? items.map((item) => item.value).join(',') : undefined;
      this.props.setQueryParams({type: typeVal});
    };

    render() {
      const {alertTypes, selectedAlertType} = this.props;
      return (
        <SelectAndt
          automationId="alertTypeFilter"
          type={TYPE_NEW_MULTI_NO_SEARCH}
          theme={THEME_HIGHLIGHTED}
          onChange={this.onChange}
          getOptionLabel={(val) => val.text}
          getOptionValue={(val) => val.value}
          value={selectedAlertType}
          options={alertTypes}
          isMulti
          optionHeight={40}
          isClearable={false}
          closeMenuOnSelect={false}
          menuWidth={155}
          minMenuHeight={100}
          placeholder="Type"
          noAutoReorder
        />
      );
    }
  },
);
