import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as actions from 'admin.activityLog/store/actions';
import * as api from 'admin.activityLog/services/api';
import {success} from 'common/utils/notifications/notificationsService';

const fetchAuditLog = makeAsyncEpic(actions.fetchAuditLog, api.fetchAuditLog);
const fetchListEntityId = makeAsyncEpic(actions.fetchListEntityId, api.fetchAuditLog);
const fetchNextPageAuditLog = makeAsyncEpic(actions.fetchNextPageAuditLog, api.fetchAuditLog);
const fetchTotalCount = makeAsyncEpic(actions.fetchTotalCount, api.fetchAuditLog);

const displayActivitySnackBar = (action$) =>
  action$.ofType(actions.displayActivitySnackBar.TYPE).switchMap(({payload}) => [
    success({
      title: payload.title,
      description: payload.description,
      settings: {
        uid: 'activity_log_import_success_msg',
      },
    }),
  ]);

export default combineEpics(
  fetchAuditLog,
  fetchListEntityId,
  fetchNextPageAuditLog,
  fetchTotalCount,
  displayActivitySnackBar,
);
