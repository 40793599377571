// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import * as selectors from 'bc/store/selectors';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {regionOptions} from 'bc/services/sumologicService';
import {
  createDataSource as createDataSourceAction,
  resetAddSourceError as resetAddSourceErrorAction,
} from 'bc/store/actions';
import DataSourceModal from '../../DataSourceModal';
import '../../DataSourceModal.module.scss';

export default connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource: createDataSourceAction,
    resetAddSourceError: resetAddSourceErrorAction,
  },
)(
  class SumologicSourceModal extends React.PureComponent {
    props: {
      isOpen: boolean,
      isViewOnly: boolean,
      isEditable: boolean,
      source: Object,
      sourceType: Object,
      isLoading: boolean,
      sourceError: Object,
      onClose: Function,
      createDataSource: Function,
      resetAddSourceError: Function,
    };

    // eslint-disable-next-line no-extra-boolean-cast
    state = !!this.props.source
      ? {
          name: this.props.source.name,
          deployment: this.props.source.deployment,
          accessId: this.props.source.accessId,
          accessKey: this.props.source.accessKey,
        }
      : {
          name: '',
          deployment: regionOptions[8].value,
          accessId: '',
          accessKey: '',
        };

    valueChanged = (val, inputId) => {
      const newState = {};
      newState[inputId] = val;
      this.setState(newState);
    };

    regionChanged = (val) => {
      this.setState({deployment: val.value});
    };

    createBtnClicked = () => {
      const {sourceType, createDataSource} = this.props;

      const obj = {
        type: getTypeDetails(sourceType.type).type,
        name: `${getTypeDetails(sourceType.type).shortName} Data Source ${new Date().getTime().toString()}`,
        authType: 'ACCESS_ID',
        deployment: this.state.deployment,
        accessId: this.state.accessId,
        accessKey: this.state.accessKey,
      };
      createDataSource(obj);
    };

    isContinueDisabled = () => {
      const {accessId, accessKey} = this.state;
      return !accessId || !accessKey;
    };

    onCloseInternal = () => {
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    render() {
      const {isOpen, isLoading, sourceError, source, sourceType, isViewOnly, isEditable} = this.props;
      const {deployment, accessId, accessKey} = this.state;

      const selectedIndex = regionOptions.findIndex((val) => val.value === deployment);

      return (
        <DataSourceModal
          isOpen={isOpen}
          isContinueDisabled={this.isContinueDisabled()}
          isProcessing={isLoading}
          isViewOnly={isViewOnly}
          isRenameEnabled={isEditable}
          source={source}
          sourceType={sourceType}
          logoImageClass="source-sumologic"
          onClose={this.onCloseInternal}
          onContinue={this.createBtnClicked}
        >
          <div styleName="inputs-wrapper">
            <SelectAndt
              id="sumologicSourceRegion"
              automationId="sumologicSourceRegion"
              styleName="region-dropdown"
              className="andt-dropdown"
              disabled={isViewOnly}
              options={regionOptions}
              getOptionLabel={(val) => val.text}
              getOptionValue={(val) => val.value}
              type={TYPE_NO_SEARCH}
              theme={THEME_LIGHT}
              value={regionOptions[selectedIndex]}
              onChange={(item) => this.regionChanged(item)}
              placeholder="Data Center Location"
            />
            <input
              type="text"
              automation-id="sumologicSourceAccessId"
              onChange={(e) => this.valueChanged(e.target.value, 'accessId')}
              placeholder="Access Id"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              value={accessId}
            />
            <input
              type="text"
              automation-id="sumologicSourceAccessKey"
              onChange={(e) => this.valueChanged(e.target.value, 'accessKey')}
              placeholder="Access Key"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              value={accessKey}
            />
            {!isLoading && sourceError ? (
              <div styleName="error-wrapper">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" />{' '}
                  {sourceError.uMessage || 'Could not connect, check the settings and try again'}
                </span>
              </div>
            ) : null}
            <div styleName="extra-info">
              <a
                href="https://support.anodot.com/hc/en-us/articles/4417658474514-Sumologic-Collector"
                rel="noopener noreferrer"
                target="_blank"
              >
                Learn more
              </a>
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
