// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {fetchPropAndVal, fetchPropAndValList, fetchProps, setExpression} from 'userEvents/store/actions';
import './EventExpressionBuilder.module.scss';
import * as selectors from 'userEvents/store/selectors';
import {throttle, noop} from 'lodash';
import ExpressionItem from 'common/componentsV2/ExpressionBuilderV2/ExpressionItem';
import ExpressionBuilder from 'common/componentsV2/ExpressionBuilderV2/ExpressionBuilder';

type PropTypes = {
  fetchPropAndValList: Function,
  mainPanelOptions: Array<ExpressionItem>,
  secondaryPanelOptions: Array<ExpressionItem>,
  isFetchPropAndValListApiLoading: boolean,
  fetchProps: Function,
  isFetchPropsLoading: Boolean,
  setExpression: Function,
  expression: Array<ExpressionItem>,
  isExecuteOnUpdate: boolean,
};

export default connect(
  (state) => ({
    mainPanelOptions: selectors.getMainPanelOptions(state),
    secondaryPanelOptions: selectors.getSecondaryPanelOptions(state),
    isFetchPropsLoading: selectors.getFetchPropIsLoading(state),
    isFetchPropAndValListApiLoading: selectors.getIsFetchPropAndValApiListLoading(state),
    expression: selectors.getExpression(state),
    expressionForExecute: selectors.getExpressionForExecute(state),
  }),
  {
    fetchPropAndVal,
    fetchPropAndValList,
    fetchProps,
    setExpression,
  },
)(
  class EventExpressionBuilder extends React.PureComponent {
    props: PropTypes;

    handlePrimarySearchChange = throttle((val) => {
      this.props.fetchPropAndValList(val, JSON.stringify(val));
    }, 1000);

    handleFetchFirstPanel = (val) => {
      this.props.fetchPropAndValList(val, JSON.stringify(val));
    };

    handleSecondarySearch = (val) => {
      this.props.fetchProps(val);
    };

    handleExpressionChange = (val) => {
      // debugger;
      this.props.setExpression(val, this.props.isExecuteOnUpdate);
    };

    render() {
      return (
        <ExpressionBuilder
          automationId="Event"
          mainPanelOptions={this.props.mainPanelOptions}
          secondaryPanelOptions={this.props.secondaryPanelOptions}
          fetchFirstPanel={this.handleFetchFirstPanel}
          expression={this.props.expression}
          secondarySearch={this.handleSecondarySearch}
          isPrimaryPanelLoading={this.props.isFetchPropAndValListApiLoading}
          isSecondaryPanelLoading={this.props.isFetchPropsLoading}
          onExpressionChange={this.handleExpressionChange}
          handleTexInputClick={noop}
          onClick={noop}
        />
      );
    }
  },
);
