import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import PropTypes from 'prop-types';
import Button, {COLORS, HEIGHTS as BUTTON_HEIGHTS, WIDTHS as BUTTON_WIDTHS} from 'common/componentsV2/Button';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import Spinner from 'common/componentsV2/Spinner';
import {uploadFile} from 'bc/services/fileUploadToAwsService';
import {setFileUploadProgress, setUploadFileError, createDataStream, createEventStream} from 'bc/store/actions';
import {bcTypes} from 'bc/services/bcTypes';
import {getEnableEventsStreams, getIsOnPremNewStreamEnabled, getUserProfile} from 'profile/store/selectors';
import useCreateEventSource from 'dataCollectors/api/useCreateEventSource';
import STREAMS_OPTIONS from 'dataCollectors/services/dataStreamService';
import styles from './fileUploadModal.module.scss';

export default function FileUploadModal({children, isOpen, closeModal, sourceType}: PropTypes) {
  const isOnPremNewStreamEnabled = useSelector(getIsOnPremNewStreamEnabled);
  const userProfile = useSelector(getUserProfile);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [payload, setPayload] = useState({});
  const enableEventsStreams = useSelector(getEnableEventsStreams);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [sourceData, setSourceData] = useState({});

  const {createEventSource, isCreateEventSourceLoading} = useCreateEventSource();
  const dispatch = useDispatch();

  //   new stream button enabled check
  let isAuthorizedNewStream = !sourceType?.family || sourceType?.family !== 'agents';
  if (isAuthorizedNewStream) {
    const authorizedRoles = ['anodot-admin', 'anodot-user', 'reseller-admin', 'reseller-user', 'customer-admin'];
    isAuthorizedNewStream = userProfile.roles.some((r) => authorizedRoles.includes(r));
    if (isAuthorizedNewStream && window.isOnPremise) {
      // also check feature flag for OnPremise users
      isAuthorizedNewStream = isOnPremNewStreamEnabled;
    }
  }

  const uploadFileError = (err) => {
    dispatch(setUploadFileError(err.message));
    setIsFileUploaded(false);
    closeModal();
  };

  const onSuccessSave = (data) => {
    setSourceData(data);
  };

  const uploadFileSuccess = (result) => {
    if (!result.s3url) {
      // reset file URL in the store before uploading new file
      return;
    }

    const [file, folderName] = result.s3url.split('/').reverse();
    const fileNameDec = decodeURIComponent(file);
    const folderNameDec = decodeURIComponent(folderName);

    const createSourcePayload = {
      name: `${fileNameDec} Data Source ${new Date().getTime().toString()}`,
      fileName: `${folderNameDec}/${fileNameDec}`,
      type: bcTypes.local_file.type,
    };
    setPayload(createSourcePayload);
    createEventSource({payload: createSourcePayload, onSuccess: onSuccessSave});
  };

  const onChange = (e) => {
    if (e.target.files.length) {
      setIsFileSelected(true);
      let {uploadPath} = sourceType;
      uploadPath = uploadPath[0] === '/' ? uploadPath.slice(1) : uploadPath;
      const file = e.target.files[0];
      setIsFileUploaded(true);
      uploadFile(file, sourceType?.uploadBucket, uploadPath, uploadFileSuccess, uploadFileError, setFileUploadProgress);
    }
  };

  const onNewStreamClicked = (item) => {
    if (item.value === STREAMS_OPTIONS.dataStream.value) {
      dispatch(
        createDataStream({
          type: sourceData.type,
          dataSourceId: sourceData.id,
        }),
      );
    } else if (item.value === STREAMS_OPTIONS.eventStream.value) {
      dispatch(
        createEventStream({
          type: sourceData.type,
          sourceId: sourceData.id,
        }),
      );
    }
  };

  useEffect(() => {
    if (payload?.type) {
      setIsFileUploaded(false);
    }
  }, [payload]);

  return (
    <>
      <Modal id="fileUploadModal" isOpen={isOpen} onClose={closeModal} size={SIZES.MEDIUM} isStatic>
        <div>{children}</div>

        <div className={styles.header}>File Upload</div>
        <span>Onetime upload a CSV file for Anodot processing</span>

        <div className={styles.uploadWrapper}>
          <span className={styles.bold}>Choose file to upload</span>
          <div className={styles.uploadInputWrapper}>
            <input
              id="fileInput"
              className={!isFileSelected ? styles.placeholder : ''}
              type="file"
              accept=".csv"
              title=""
              onChange={onChange}
            />
            <button
              type="button"
              // role="presentation"
              className={styles.uploadBtn}
              onClick={() => document.getElementById('fileInput').click()}
              automation-id="uploadFile"
            >
              {isFileUploaded || isCreateEventSourceLoading ? (
                <div className={styles.loaderWrapper}>
                  <Spinner color="#2671ff" size={30} />
                </div>
              ) : (
                'Upload'
              )}
            </button>
          </div>
        </div>

        <div className={styles.footerWrapper}>
          {isAuthorizedNewStream && !enableEventsStreams && (
            <div className={styles.buttonWrapper}>
              <Button
                colorSchema={COLORS.BLUE_500}
                height={BUTTON_HEIGHTS.REGULAR}
                width={BUTTON_WIDTHS.REGULAR}
                isDisabled={!sourceData?.id}
                text="Metrics Stream"
                onClick={() => onNewStreamClicked(STREAMS_OPTIONS.dataStream)}
                automation-id="dataManagerNewSourceButton"
              />
            </div>
          )}
          {isAuthorizedNewStream && enableEventsStreams && (
            <div className={styles.buttonWrapper}>
              <FormDdlSelect
                automationId="more-actions-ddl-s"
                position="right"
                width={135}
                options={Object.keys(STREAMS_OPTIONS).map((key) => STREAMS_OPTIONS[key])}
                button={
                  <Button
                    colorSchema={COLORS.BLUE_500}
                    isDisabled={!sourceData?.id}
                    height={BUTTON_HEIGHTS.REGULAR}
                    width={BUTTON_WIDTHS.REGULAR}
                    text="Create New Stream"
                    automation-id="dataManagerNewSourceSelector"
                  />
                }
                optionComponent={<OptionComponentSimple />}
                onChange={onNewStreamClicked}
              />
            </div>
          )}
          <Button text="Cancel" onClick={() => closeModal()} colorSchema={COLORS.GRAY_200} />
        </div>
      </Modal>
    </>
  );
}

FileUploadModal.defaultProps = {
  children: <></>,
  sourceType: {},
};

FileUploadModal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  sourceType: PropTypes.objectOf(PropTypes.any),
};
