// @flow
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Highcharts from 'highcharts';
import TimeSeriesWithEventsChart from 'charts/timeSeries/components/TimeSeriesWithEventsChart';
import {fetchAlertMetricDataPoints} from 'investigationNewAlertConsole/store/actions';
import {getMetricList} from 'investigationNewAlertConsole/store/selectors';
import {getBucketStartTimeEnabled, getEnableMultipleEvents, getTriageFullScreenEnabled} from 'profile/store/selectors';
import {getMetricResolutions} from 'metrics/store/selectors';
import {generateChartSeriesMetricModel, processSeriesData} from 'charts/timeSeries/services/timeSeriesDataService';
import {endLoad, pushSeries, startLoad} from 'charts/timeSeries/services/timeSeriesHchartService';
import {ALERT_TYPES} from 'investigationNewAlertConsole/services/investigationService';
import {getAlertEvents} from 'alerts.console.new/store/selectors';
import fetchConfigurationAlertByIdProvider from 'alerts.management/api/fetchConfigurationAlertByIdProvider';
import {fetchAlertEvents, fetchAlertMultipleEvents} from 'alerts.console.new/store/actions';
import {getEventsFiltersDefaults, getLimitedAlertEvents} from 'alerts.console.new/services/alertsConsoleEventsService';
import MetricEventsChart from 'charts/timeSeries/containers/MetricEventsChart';
import {getEventsResolution} from 'userEvents/store/selectors';
import {getEventsFilteredByMatchingProperties} from 'charts/timeSeries/services/timeSeriesEventService';

import './MetricsTable.module.scss';

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: true,
};

const MetricsTableExpandablePanel = ({
  trigger,
  metric,
  timeZoneName,
}: {
  trigger: Object,
  metric: Object,
  timeZoneName: String,
}) => {
  const dispatch = useDispatch();
  const bucketStartTimeEnabled = useSelector(getBucketStartTimeEnabled);
  const resolutions = useSelector(getMetricResolutions);
  const metricList = useSelector(getMetricList);
  const eventsResolution = useSelector(getEventsResolution);
  const isTriageFullScreenEnabled = useSelector(getTriageFullScreenEnabled);
  const isUseMultipleEvents = useSelector(getEnableMultipleEvents);

  const events = useSelector(getAlertEvents);
  const alertConfigurationResult = fetchConfigurationAlertByIdProvider(trigger?.alertConfigurationId)?.useQuery();
  const alertEventsConfiguration = alertConfigurationResult?.data;

  const [alertEventsFilters, setAlertEventsFilters] = useState(null);
  const [processedMetricsNames, setProcessedMetricsNames] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const chartIdRef = useRef(`investigation.modal.chart_${metric.id}`);
  const chartIdEventsModalRef = useRef('events-modal-chart');

  const chartEventsMeta = useMemo(() => {
    return {
      key: `investigation_modal_${metric.id}`,
      chartId: isModalOpen ? chartIdEventsModalRef.current : chartIdRef.current,
    };
  }, [isModalOpen]);

  const timeOfIncident = metric?.intervals[0].startTime;
  const metricStartTime = metric?.bufferedStartTime;
  const metricEndTime = metric?.bufferedEndTime;

  const alertEvents = useMemo(() => {
    const currentEvents = events[chartEventsMeta.key]?.data?.events;
    const metrics = processedMetricsNames?.model?.properties;
    if (currentEvents?.length && metrics?.length) {
      return getEventsFilteredByMatchingProperties(currentEvents, alertEventsFilters, metrics);
    }
    return currentEvents;
  }, [events[chartEventsMeta.key]?.data?.events, alertEventsFilters]);

  const tooltipConditions = useMemo(() => {
    const alertConfigConditions = alertEventsConfiguration?.data?.conditions || null;
    if (alertConfigConditions?.length) {
      const significance = alertConfigConditions.find((item) => item.type === 'SIGNIFICANCE_CONDITION');
      const delta = alertConfigConditions.find((item) => item.type === 'DELTA_CONDITION');
      const duration = alertConfigConditions.find((item) => item.type === 'DURATION_CONDITION');
      return {significance, delta, duration};
    }
    return {};
  }, [JSON.stringify(alertEventsConfiguration)]);

  const getEventsFilters = () => {
    let tmpEventsFilters;
    if (isUseMultipleEvents) {
      tmpEventsFilters = alertEventsConfiguration?.data?.eventsFilters || null;
      if (tmpEventsFilters?.length) {
        tmpEventsFilters = tmpEventsFilters.map((f) =>
          getEventsFiltersDefaults(f, alertEventsConfiguration?.data?.rollups),
        );
      }
    } else {
      tmpEventsFilters = alertEventsConfiguration?.data?.eventsFilter || null;
      tmpEventsFilters = getEventsFiltersDefaults(tmpEventsFilters, alertEventsConfiguration?.data?.rollups);
    }
    return tmpEventsFilters;
  };

  const loadMetrics = () => {
    dispatch(
      fetchAlertMetricDataPoints(
        {
          metricId: metric.id,
          startDate: metricStartTime,
          endDate: metricEndTime,
          startBucketMode: bucketStartTimeEnabled,
          includeBaseline: true,
          timeScale: Object.values(resolutions).find((a) => a.value2 === trigger?.timeScale)?.value,
        },
        {
          ...chartEventsMeta,
          chartId: chartEventsMeta.chartId,
          metricId: metric?.id,
          alertId: trigger?.alertTriggerId,
        },
      ),
    );
  };

  const onEventSelect = (ev) => {
    if (!ev.isFromModal) {
      setIsModalOpen(true);
      loadMetrics();
    }
  };

  const onEventsModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    loadMetrics();
  }, []);

  useEffect(() => {
    const hchart = Highcharts.charts.find((chart) => chart && chart.renderTo.id === chartEventsMeta.chartId);
    const selectedMetric = metricList[chartEventsMeta.key];
    const selectedMetricData = Array.isArray(selectedMetric?.data?.metrics) ? selectedMetric.data.metrics[0] : null;

    if (selectedMetric?.isLoading) {
      startLoad(hchart);
    }

    if (selectedMetricData && !hchart.series.length) {
      const metricsModel = generateChartSeriesMetricModel(metric, undefined, 0);
      const processedSeriesData = processSeriesData(
        selectedMetricData.dataPoints,
        selectedMetricData.baseline,
        [ALERT_TYPES.ANOMALY, ALERT_TYPES.STATIC].includes(trigger?.type)
          ? metric.intervals || metric.currentAnomalyIntervals
          : null,
        null,
      );

      pushSeries(hchart, {byTreeExp: []}, metricsModel, processedSeriesData, true);

      endLoad(hchart, metric?.bufferedStartTime, metric?.bufferedEndTime);
    }
  }, [metricList, metric]);

  useEffect(() => {
    if (isTriageFullScreenEnabled) {
      // Get Events
      const alertConfigEventsFilters = getEventsFilters();
      if (alertConfigEventsFilters) {
        if (isUseMultipleEvents) {
          dispatch(
            fetchAlertMultipleEvents(
              {
                startDate: metricStartTime,
                endDate: metricEndTime,
                startBucketMode: bucketStartTimeEnabled,
                body: alertConfigEventsFilters,
              },
              {
                ...chartEventsMeta,
                metricId: metric.id,
                intervals: metric.intervals,
                alertId: trigger?.alertTriggerId,
              },
            ),
          );
        } else {
          dispatch(
            fetchAlertEvents(
              {
                startDate: metricStartTime,
                endDate: metricEndTime,
                startBucketMode: bucketStartTimeEnabled,
                body: alertConfigEventsFilters,
              },
              {
                ...chartEventsMeta,
                metricId: metric.id,
                intervals: metric.intervals,
                alertId: trigger?.alertTriggerId,
              },
            ),
          );
        }
      }
      // Set state is async, so couldn't use alertEventsFilters in the code above, thus initialize its state here.
      setAlertEventsFilters(alertConfigEventsFilters);
    }
  }, [alertEventsConfiguration?.data?.eventsFilters]);

  useEffect(() => {
    const metricsModelNames = generateChartSeriesMetricModel(metric);
    setProcessedMetricsNames({
      model: metricsModelNames,
      shouldProcessData: false,
    });
  }, [metric]);

  return (
    <div styleName="container">
      {isTriageFullScreenEnabled ? (
        <MetricEventsChart
          id={chartIdRef.current}
          eventsMeta={chartEventsMeta}
          disableActions={false}
          events={alertEvents}
          getLimitedAlertEvents={() => getLimitedAlertEvents(alertEvents, timeOfIncident)}
          shouldShowEvents={!!alertEvents?.length}
          eventsResolution={alertEventsFilters?.aggregation?.eventsResolution || eventsResolution}
          onEventSelect={onEventSelect}
          onEventsModalClose={onEventsModalClose}
          tooltip={TOOLTIP_OPTIONS}
          tooltipConditions={tooltipConditions}
          bucketStartTimeEnabled={bucketStartTimeEnabled}
          timeScale={trigger?.timeScale}
          timeZoneName={timeZoneName}
          theme="preview"
          fromDate={Math.ceil(metricStartTime / 1000)}
          toDate={Math.ceil(metricEndTime / 1000)}
          processedMetrics={processedMetricsNames}
          enableClientZoom
          timeOfIncident={timeOfIncident}
          chartContainerStyle={{height: '230px', width: '100%'}}
        />
      ) : (
        <TimeSeriesWithEventsChart
          disableActions
          id={chartEventsMeta.chartId}
          eventsMeta={chartEventsMeta}
          height={230}
          bucketStartTimeEnabled={bucketStartTimeEnabled}
          timeScale={trigger?.timeScale}
          timeZoneName={timeZoneName}
          enableClientZoom
          tooltip={TOOLTIP_OPTIONS}
          chartContainerStyle={{height: '230px', width: '100%'}}
        />
      )}
    </div>
  );
};

export default MetricsTableExpandablePanel;
