// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import Button, {COLORS} from 'common/componentsV2/Button';
import PollingInterval from 'bc/components/streams/editor/scheduler/PollingInterval';
import DateRange from 'bc/components/streams/editor/scheduler/DateRange';
import AggregationInterval from 'bc/components/streams/editor/scheduler/AggregationInterval';
import MissingHours from 'bc/components/streams/editor/scheduler/MissingHours';
import NotificationDelay from 'bc/components/streams/editor/scheduler/NotificationDelay';
import {CORALOGIX_AGGR_INTERVAL_TYPES} from 'bc/services/coralogixService';
import {getAllowedRangeTypesByPollingInterval, getNotificationDelayOption} from 'bc/services/dataStreamService';

import './CoralogixSchedularModal.module.scss';

type PropTypes = {
  setSelectedStreamKeyVal: Function,

  dataStream: Object,
  onClose: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)(
  class CoralogixSchedularModal extends React.PureComponent {
    props: PropTypes;

    state = {
      pollingInterval: this.props.dataStream.pollingInterval,
      historicalDateRange: this.props.dataStream.historicalDateRange,
      aggrInterval: this.props.dataStream.aggrInterval,
      notificationDelay: this.props.dataStream.notificationDelay,
      fillMissingHours: this.props.dataStream.fillMissingHours || 0,
    };

    onCloseButtonClicked = () => {
      this.props.onClose();
    };

    pollingChanged = (val) => {
      this.setState({pollingInterval: val});
    };

    historicalDateRangeChanged = (val) => {
      this.setState({historicalDateRange: val});
    };

    aggrIntervalChanged = (val) => {
      this.setState({aggrInterval: val});
    };

    notificationDelayChanged = (val) => {
      this.setState({notificationDelay: val});
    };

    fillMissingHoursChanged = (val) => {
      this.setState({fillMissingHours: val});
    };

    onSave = () => {
      this.props.setSelectedStreamKeyVal({
        pollingInterval: this.state.pollingInterval,
        historicalDateRange: this.state.historicalDateRange,
        aggrInterval: this.state.aggrInterval,
        notificationDelay: this.state.notificationDelay,
        fillMissingHours: this.state.fillMissingHours,
      });
      this.onCloseButtonClicked();
    };

    render() {
      return (
        <div styleName="modal-body">
          <div styleName="itemContainer">
            <PollingInterval
              title="Collect Interval"
              pollingInterval={this.state.pollingInterval}
              allowedValues={['daily', 'h12', 'h8', 'h6', 'h4', 'h3', 'h2', 'hourly', 'm15', 'm5', 'm1']}
              onChange={this.pollingChanged}
            />
          </div>

          <div styleName="itemContainer">
            <AggregationInterval
              aggregationIntervalOption={CORALOGIX_AGGR_INTERVAL_TYPES}
              aggregationInterval={this.state.aggrInterval}
              pollingInterval={this.state.pollingInterval}
              onChange={this.aggrIntervalChanged}
            />
          </div>

          <div styleName="itemContainer">
            <DateRange
              title="Collect Since"
              allowedRangeTypes={getAllowedRangeTypesByPollingInterval(this.state.pollingInterval)}
              dateRange={this.state.historicalDateRange}
              onChange={this.historicalDateRangeChanged}
              isUnix
            />
          </div>

          <div styleName="itemContainer">
            <MissingHours fillMissingHours={this.state.fillMissingHours} onChange={this.fillMissingHoursChanged} />
          </div>

          <div styleName="itemContainer">
            <NotificationDelay
              notificationDelayOption={getNotificationDelayOption()}
              pollingInterval={this.state.pollingInterval}
              notificationDelay={this.state.notificationDelay}
              onChange={this.notificationDelayChanged}
            />
          </div>

          <div styleName="footer">
            <div styleName="left-button">
              <Button
                colorSchema={COLORS.GRAY_400}
                text="Cancel"
                automation-id="schedulerEditorModalCancel"
                onClick={this.onCloseButtonClicked}
              />
            </div>
            <div>
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Save"
                automation-id="schedulerEditorModalSave"
                onClick={this.onSave}
              />
            </div>
          </div>
        </div>
      );
    }
  },
);
