import {StringParam} from 'use-query-params';
import {getTimeAndScaleForDuration} from 'alerts.management/services/durationAndScaleService';

export const EMPTY_OBJECT = {};

export const NO_MORE_RECOMMENDATIONS = 'No more recommendations';

export const RECOMMENDATION_TYPES = {
  ALERT_TUNING: 'Alert Tuning',
};

export const RECOMMENDATIONS_ORDER = {
  DESC: 'DESC',
  ASC: 'ASC',
};

export const RECOMMENDATIONS_SORT_BY = {
  CREATE_TIME: 'createdAt',
  UPDATE_TIME: 'updatedAt',
};

export const RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM = {
  RECOMMENDATION_TYPES: 'recommendationTypes',
};

export const RECOMMENDATIONS_QUERY_PARAMS = {
  SORT_BY: {name: 'sortBy', defaultValue: undefined},
  ORDER: {name: 'order', defaultValue: undefined},
  SEARCH_EXPRESSION: {name: 'search', defaultValue: undefined},
};

export const RECOMMENDATIONS_QUERY_PARAMS_CONFIG = {
  [RECOMMENDATIONS_QUERY_PARAMS.SORT_BY.name]: StringParam,
  [RECOMMENDATIONS_QUERY_PARAMS.ORDER.name]: StringParam,
  [RECOMMENDATIONS_QUERY_PARAMS.SEARCH_EXPRESSION.name]: StringParam,
};

export const RECOMMENDATIONS_COLUMN_HEADERS = {
  TITLE: {id: 0, name: 'title', title: 'Title', isSortable: false},
  TYPE: {id: 1, name: 'type', title: 'Type', isSortable: false},
  DATE_GIVEN: {id: 2, name: 'dateGiven', title: 'Date given', propertyName: RECOMMENDATIONS_SORT_BY.CREATE_TIME},
  LAST_UPDATED: {id: 3, name: 'lastUpdated', title: 'Last updated', propertyName: RECOMMENDATIONS_SORT_BY.UPDATE_TIME},
  RECOMMENDATIONS_APPLIED: {id: 4, name: 'recommendationsApplied', title: '', isSortable: false},
};

export const RECOMMENDATION_STATUS = {
  APPLIED: 'Applied',
  IGNORED: 'Ignored',
};

export const RECOMMENDATION_ACTIONS = {
  APPLY: 'Apply',
  IGNORE: 'Ignore',
};

export const ALERT_RECOMMENDATION_TYPES = {
  DELTA_ABSOLUTE: {name: 'DELTA_ABSOLUTE', text: 'Delta Absolute Value'},
  DELTA_PERCENTAGE: {name: 'DELTA_PERCENTAGE', text: 'Delta Percentage (%)'},
  MINIMUM_SCORE: {name: 'MINIMUM_SCORE', text: 'Minimum Significance Score'},
  MINIMUM_DURATION: {name: 'MINIMUM_DURATION', text: 'Minimum Duration'},
};

export const ALERT_RECOMMENDATION_TEXT_ACTIONS = {
  INCREASE: 'Increase',
  REDUCE: 'Reduce',
  SET: 'Set',
};

export const getAlertRecommendationText = (recommendation) => {
  const alertRecommendationType = recommendation?.type;
  const previousValue = Number.isSafeInteger(recommendation?.previousValue)
    ? recommendation?.previousValue
    : recommendation?.previousValue.toFixed(2);
  const value = Number.isSafeInteger(recommendation?.value) ? recommendation?.value : recommendation?.value.toFixed(2);

  if (!alertRecommendationType) {
    return '';
  }

  let action = ALERT_RECOMMENDATION_TEXT_ACTIONS.INCREASE;
  if (!previousValue) {
    action = ALERT_RECOMMENDATION_TEXT_ACTIONS.SET;
  }
  if (previousValue > value) {
    action = ALERT_RECOMMENDATION_TEXT_ACTIONS.REDUCE;
  }

  let fromPreviousValue = previousValue ? ` from ${previousValue}` : '';
  let toNewValue = `${value}`;
  if (alertRecommendationType === ALERT_RECOMMENDATION_TYPES.MINIMUM_DURATION.name) {
    const previousTimeAndScale = getTimeAndScaleForDuration(previousValue);
    if (previousTimeAndScale?.duration && previousTimeAndScale?.scale) {
      fromPreviousValue = fromPreviousValue
        ? ` from ${previousTimeAndScale?.duration} ${previousTimeAndScale?.scale}`
        : '';
    }

    const newTimeAndScale = getTimeAndScaleForDuration(value);
    if (newTimeAndScale?.duration && newTimeAndScale?.scale) {
      toNewValue = `${newTimeAndScale?.duration} ${newTimeAndScale?.scale}`;
    }
  }

  if (alertRecommendationType === ALERT_RECOMMENDATION_TYPES.DELTA_PERCENTAGE.name) {
    fromPreviousValue = fromPreviousValue ? ` ${fromPreviousValue}%` : '';
    toNewValue = `${toNewValue}%`;
  }

  return `${action} ${ALERT_RECOMMENDATION_TYPES[alertRecommendationType].text} ${fromPreviousValue} to ${toNewValue}`;
};
