// @flow

/* GABPAC - 04/11/19 - Smart textInput
 * This will take care of all the special events and cases, such as mapping the
 * Enter, Escape and Backspace as events. It is also auto sizable.
 */

import React from 'react';
import fastdom from 'fastdom';
import './TextInput.module.scss';

const MAX_WIDTH_INNER = 50;

type PropTypes = {
  isInner: boolean,
  onChange: Function,
  onClick: Function,
  onBlur: Function,
  onSelect: Function,
  onClear: Function,
  onEscape: Function,
  value: String,
  onNavigation: Function,
  placeholder: string,
  onPaste: Function,
  automationId: string,
};

export default class TextInput extends React.PureComponent {
  props: PropTypes;

  // Focus state is not in use currently, but it stores the Fosussed status
  state = {
    // isOnFocus: false,
  };

  inputChange = (val) => {
    const inputValue = val.target.value;
    this.props.onChange(inputValue);
  };

  onKeyDown = (val) => {
    switch (val.key) {
      // case 'Enter':
      case 'Enter':
        this.props.onSelect();
        this.props.onNavigation(val);
        break;
      case 'Backspace':
        if (this.props.value.length === 0) {
          this.props.onClear();
        }
        break;
      case 'Escape':
        this.props.onEscape();
        break;
      case 'Tab':
        val.preventDefault();
        break;
      case 'ArrowDown':
      case 'ArrowUp':
      case 'ArrowRight':
      case 'ArrowLeft':
        this.props.onNavigation(val);
        break;
      default:
    }
  };

  handleOnFocus = () => {
    // this.setState({isOnFocus: true});
    this.props.onClick();
  };

  handleOnBlur = (e) => {
    // this.setState({isOnFocus: false});
    this.props.onBlur(e);
  };

  forceFocus = () => {
    if (this.textInput) {
      this.textInput.focus();
    }
  };

  render() {
    let sizerWidth = 0;
    if (this.props.isInner) {
      fastdom.measure(() => {
        if (this.sizer) {
          sizerWidth = this.sizer.getBoundingClientRect().width;
        }
      });
      fastdom.mutate(() => {
        if (this.textInput) {
          if (this.props.isInner && sizerWidth > MAX_WIDTH_INNER) {
            sizerWidth = MAX_WIDTH_INNER;
          }
          this.textInput.style.width = `${sizerWidth}px`;
        }
      });
    }

    return (
      <div styleName={['container', this.props.isInner ? 'inner' : ''].join(' ').trim()}>
        <span
          styleName="sizer"
          ref={(spn) => {
            this.sizer = spn;
          }}
        >
          {this.props.value}
        </span>
        <input
          automation-id={`${this.props.automationId}CompositeTextInput`}
          spellCheck={false}
          autoCorrect="off"
          ref={(inp) => {
            this.textInput = inp;
          }}
          data-lpignore="true"
          className="no-close text-input"
          autoFocus={this.props.isInner}
          onChange={this.inputChange}
          onKeyDown={this.onKeyDown}
          value={this.props.value}
          onFocus={this.handleOnFocus}
          // onClick={this.props.onClick}
          onBlur={this.handleOnBlur}
          placeholder={this.props.placeholder}
          onPaste={this.props.onPaste}
        />
      </div>
    );
  }
}
