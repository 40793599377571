import React from 'react';
import {connect} from 'react-redux';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import * as actions from 'admin.cs-portal/store/actions';
import * as selectors from 'admin.cs-portal/store/selectors';
import {palette} from 'app/styles/theme';
import StreamErrors from './StreamErrors';
import AlertErrors from './AlertErrors';
import CompositeErrors from './CompositeErrors';

import './ErrorsView.module.scss';

type PropTypes = {
  selectedCustomer: Object,
  fetchStreamsReport: Function,
  streamErrorsCount: Number,
  areStreamErrorsLoading: Boolean,
  fetchAlertsErrors: Function,
  alertErrorsCount: Number,
  areAlertErrorsLoading: Boolean,
  fetchCompositeErrors: Function,
  compositeErrorsCount: Number,
  areCompositeErrorsLoading: Boolean,
};

export default connect(
  (state) => ({
    selectedCustomer: selectors.getSelectedCustomer(state),
    streamErrorsCount: selectors.getStreamErrorsCount(state),
    areStreamErrorsLoading: selectors.getIsStreamsReportLoading(state),
    alertErrorsCount: selectors.getAlertErrorsCount(state),
    areAlertErrorsLoading: selectors.getAreAlertErrorsLoading(state),
    compositeErrorsCount: selectors.getCompositeErrorsCount(state),
    areCompositeErrorsLoading: selectors.getAreCompositeErrorsLoading(state),
  }),
  {
    fetchStreamsReport: actions.fetchStreamsReport,
    fetchAlertsErrors: actions.fetchAlertsErrors,
    fetchCompositeErrors: actions.fetchCompositeErrors,
  },
)(
  class ErrorsTables extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {selectedCustomer} = this.props;
      if (selectedCustomer && selectedCustomer.value && selectedCustomer.token) {
        this.props.fetchStreamsReport({
          _id: selectedCustomer.value,
        });
        this.props.fetchAlertsErrors({
          token: selectedCustomer.token,
        });
        this.props.fetchCompositeErrors({
          _id: selectedCustomer.value,
        });
      }
    }

    componentDidUpdate(prevProps) {
      const {selectedCustomer} = prevProps;
      if (
        selectedCustomer &&
        selectedCustomer.value &&
        selectedCustomer.token &&
        this.props.selectedCustomer !== selectedCustomer
      ) {
        this.props.fetchStreamsReport({
          _id: this.props.selectedCustomer.value,
        });
        this.props.fetchAlertsErrors({
          token: this.props.selectedCustomer.token,
        });
        this.props.fetchCompositeErrors({
          _id: this.props.selectedCustomer.value,
        });
      }
    }

    render() {
      const {
        areStreamErrorsLoading,
        streamErrorsCount,
        areAlertErrorsLoading,
        alertErrorsCount,
        areCompositeErrorsLoading,
        compositeErrorsCount,
      } = this.props;

      return (
        <div>
          {!areStreamErrorsLoading ? (
            [streamErrorsCount && streamErrorsCount > 0 ? <StreamErrors key={`stream${streamErrorsCount}`} /> : null]
          ) : (
            <div key={`stream${streamErrorsCount}`} styleName="centered-text">
              <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
            </div>
          )}
          {!areAlertErrorsLoading ? (
            [alertErrorsCount && alertErrorsCount > 0 ? <AlertErrors key={`alert${alertErrorsCount}`} /> : null]
          ) : (
            <div key={`alert${alertErrorsCount}`} styleName="centered-text">
              <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
            </div>
          )}
          {!areCompositeErrorsLoading ? (
            [
              compositeErrorsCount && compositeErrorsCount > 0 ? (
                <CompositeErrors key={`composite${compositeErrorsCount}`} />
              ) : null,
            ]
          ) : (
            <div key={`composite${compositeErrorsCount}`} styleName="centered-text">
              <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
            </div>
          )}
        </div>
      );
    }
  },
);
