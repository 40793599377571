// @flow
import React from 'react';

const makeConceptTooltip = ({description}: any) => {
  return (
    <div className="concept-tooltip">
      <div>{description}</div>
    </div>
  );
};

export default makeConceptTooltip;
