import React, {useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {useDispatch} from 'react-redux';
import {Backdrop, Modal} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import Button, {COLORS} from 'common/componentsV2/Button';
import Checkbox from 'common/componentsV2/Checkbox';
import Input from 'common/componentsV2/Input';
import Slider from 'common/componentsV2/Slider';
import Spinner from 'common/componentsV2/Spinner';
import TooltipArea from 'common/componentsV2/TooltipArea';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import SmallDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/SmallDropdownButton';
import {success, error} from 'common/utils/notifications/notificationsService';
import EmptyOptions from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/EmptyOptions';
import AsyncButton from 'common/componentsV2/AsyncButton';
import useFetchCompositesList from 'composites/api/useFetchComposites';
import {useUpdateCompositeDelay, usePostCompositeDelay} from 'composites/api/useUpdateCompositeDelay';
import {calculateTime, DELAY_TIME_SCALES} from 'composites/services/compositesService';

import styles from './DelayModal.module.scss';

const DelayModal = ({backUrl}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const {compositesList, isCompositesListLoading} = useFetchCompositesList();
  const {compositeDelay, isCompositeDelayLoading} = usePostCompositeDelay(id);
  const [selectComposite, setSelectComposite] = useState({});
  const [selectedTimeScale, setSelectedTimeScale] = useState(DELAY_TIME_SCALES[0]);
  const [useAutoCalculatedDelay, setUseAutoCalculatedDelay] = useState(false);
  const [notEnoughData, setNotEnoughData] = useState(false);
  const [compositeDelayHasError, setCompositeDelayHasError] = useState(false);
  const [localValue, setLocalValue] = useState(0);
  const [delayPoints, setDelayPoints] = useState([]);
  const {updateCompositeDelay, isUpdateCompositeDelayLoading} = useUpdateCompositeDelay();
  const [calculationDelay, setCalculationDelay] = useState(0);
  const [calculationDelayDisplayValue, setCalculationDelayDisplayValue] = useState(0);

  useEffect(() => {
    const composite = compositesList.find((c) => c.composite.id === id);
    if (!composite) return;
    setSelectComposite(composite?.composite);
    setUseAutoCalculatedDelay(!composite?.composite?.calculationDelay);
  }, [compositesList]);

  useEffect(() => {
    if (Object.keys(compositeDelay).length === 0) return;
    let delaysMap = [];
    // define slider points. 2 values each point(up and down)
    if (compositeDelay.delays) {
      let index = -4;
      delaysMap = Object.keys(compositeDelay.delays).map(function(key) {
        index += 5;
        let formatVal = '';
        const value = compositeDelay.delays[key];

        if (value < 60) formatVal = `${value}s`;
        else if (value < 60 * 60)
          formatVal = `${moment
            .duration(value, 'seconds')
            .asMinutes()
            ?.toFixed(1)}m`;
        else if (value < 60 * 60 * 72)
          formatVal = `${moment
            .duration(value, 'seconds')
            .asHours()
            ?.toFixed(1)}h`;
        else
          formatVal = `${moment
            .duration(value, 'seconds')
            .asDays()
            ?.toFixed(1)}d`;

        return {
          formattedVal: formatVal,
          value: index,
          title: key <= 99 ? Number(key) : key,
          initVal: value,
        };
      });
      setDelayPoints(delaysMap);
    } else {
      if (compositeDelay.validation?.failures) {
        let notEnough = false;
        Object.values(compositeDelay.validation?.failures).forEach(([value]) => {
          if (value.id === 77) {
            notEnough = true;
          }
        });
        setNotEnoughData(notEnough);
        if (!notEnough && !compositeDelay.validation?.failures.passed) {
          setCompositeDelayHasError(true);
        }
      }
      setUseAutoCalculatedDelay(false);
    }
  }, [compositeDelay]);

  const calculateDelay = (useAuto) => {
    const compositeCalculation = selectComposite?.calculationDelay
      ? selectComposite?.calculationDelay
      : delayPoints[0].initVal;
    const calculateValue = useAuto ? compositeDelay.delay : compositeCalculation;

    return calculateValue;
  };

  const delayTimeScaleChanged = (timeScale, updateCalculateDelay) => {
    setSelectedTimeScale(timeScale);
    if (updateCalculateDelay) {
      setCalculationDelay((calculationDelay * selectedTimeScale.factor) / timeScale.factor);
      setCalculationDelayDisplayValue(
        ((calculationDelay * selectedTimeScale.factor) / timeScale.factor).toFixed(3).replace(/\.0+$/, ''),
      );
    }
  };

  const changeTimeScaleByCalculationDelayValue = (calcDelay) => {
    const timeScale = calculateTime(calcDelay);
    delayTimeScaleChanged(timeScale);
    return timeScale;
  };

  const selectLocalValue = (selectedPoint, points) => {
    const point = points.find((p) => p.initVal === selectedPoint);
    if (point) {
      setLocalValue(point.value);
      return;
    }
    if (!point) {
      for (let i = 0; i < points.length; i++) {
        if (points[i].initVal <= selectedPoint && (i + 1 === points.length || points[i + 1].initVal > selectedPoint)) {
          const value = i + 1 === points.length ? points[i].value : (points[i].value + points[i + 1].value) / 2;
          setLocalValue(value);
          return;
        }
      }
    }
    setLocalValue(0);
  };

  useEffect(() => {
    if (!delayPoints?.length && (Object.keys(selectComposite)?.length === 0 || !selectComposite?.calculationDelay)) {
      return;
    }
    const delayValue = calculateDelay(!selectComposite?.calculationDelay, selectedTimeScale);
    const timeScale = changeTimeScaleByCalculationDelayValue(delayValue);
    setCalculationDelay(delayValue / timeScale.factor);
    setCalculationDelayDisplayValue((delayValue / timeScale.factor).toFixed(3).replace(/\.0+$/, ''));
    if (delayPoints?.length) selectLocalValue(delayValue, delayPoints);
  }, [selectComposite, delayPoints]);

  const onUpdateSuccess = (data) => {
    if (data.passed) {
      dispatch(success({description: `Composite Delay Updated successfully  [${selectComposite.title}]`}));
    } else if (data.failures) {
      dispatch(error({description: Object.values(data.failures)[0][0].message}));
    }
    history.push(backUrl);
  };

  const onUpdateError = (err) => {
    dispatch(error({description: err.description}));
  };

  const onConfirm = async () => {
    updateCompositeDelay({
      payload: {id, delay: !useAutoCalculatedDelay && (calculationDelay * selectedTimeScale.factor).toFixed(0)},
      onSuccess: onUpdateSuccess,
      onError: onUpdateError,
    });
  };

  const delayItemClicked = (delay) => {
    setUseAutoCalculatedDelay(false);
    const timeScale = changeTimeScaleByCalculationDelayValue(delay.initVal);
    setCalculationDelay(delay.initVal / timeScale.factor);
    setCalculationDelayDisplayValue((delay.initVal / timeScale.factor).toFixed(3).replace(/\.0+$/, ''));
  };

  const getSelectPoint = (value) => {
    for (let i = 0; i < delayPoints.length; i++) {
      if (delayPoints[i].value <= value && (i + 1 === delayPoints.length || delayPoints[i + 1].value > value)) {
        return delayPoints[i];
      }
    }
    return delayPoints[0];
  };

  const selectPoint = (value) => {
    const point = getSelectPoint(value);
    delayItemClicked(point);
    setLocalValue(point.value);
  };

  const changeAutomatic = () => {
    setUseAutoCalculatedDelay(!useAutoCalculatedDelay);

    const calculateValue = calculateDelay(!useAutoCalculatedDelay);
    const timeScale = changeTimeScaleByCalculationDelayValue(calculateValue);
    setCalculationDelay(calculateValue / timeScale.factor);
    setCalculationDelayDisplayValue((calculateValue / timeScale.factor).toFixed(3).replace(/\.0+$/, ''));
    selectLocalValue(calculateValue, delayPoints);
  };

  const changeCalculationDelayValue = (val) => {
    let {value} = val.target;
    if (selectedTimeScale.factor === 1) {
      value = val.target.value.replace(/[.]/g, '');
    }
    setCalculationDelay(value);
    setCalculationDelayDisplayValue(value);
    selectLocalValue(value * selectedTimeScale.factor, delayPoints);
  };

  const loader = useMemo(
    () => (
      <div className={styles.loaderWrapper}>
        <Spinner size={130} />
      </div>
    ),
    [],
  );

  const autoCalculate = useMemo(
    () => (
      <div className={`${styles.flex} ${styles.ml}`}>
        <Checkbox
          isDisabled={notEnoughData}
          isChecked={useAutoCalculatedDelay}
          onChange={changeAutomatic}
          className={styles.automaticCB}
        />
        <TooltipArea
          isAlwaysVisible
          automationId="delayModalContainer"
          text="When enabled, the auto calculated delay waits for 85% of the input data to be received. You can disable it to manually select your delay"
        >
          {(info) => (
            <React.Fragment>
              <div>
                <span>Auto calculated delay enabled</span>
                {info}
              </div>
            </React.Fragment>
          )}
        </TooltipArea>
      </div>
    ),
    [notEnoughData, useAutoCalculatedDelay, changeAutomatic],
  );

  const delayTime = useMemo(
    () => (
      <>
        {' '}
        {notEnoughData && (
          <div className={styles.mt20}>
            <span> There is not enough data to calculate an automatic delay</span>
          </div>
        )}
        <div className={styles.delayTimeWrapper}>
          <span>Delay Time:</span>
          <div className={styles.inputWrapper}>
            <Input
              onChange={changeCalculationDelayValue}
              value={calculationDelayDisplayValue}
              fullSize
              isDisabled={useAutoCalculatedDelay}
              automationId="delayTime"
              className={!useAutoCalculatedDelay && calculationDelay === '0' && styles.invalid}
            />
            {!useAutoCalculatedDelay && calculationDelay === '0' && styles.invalid && (
              <span>Must be greater than 0</span>
            )}
          </div>
          <div className={`${styles.timeScaleWrapper} ${useAutoCalculatedDelay && styles.disabled}`}>
            <FormDdlSelect
              options={DELAY_TIME_SCALES}
              selected={selectedTimeScale}
              button={<SmallDropdownButton disabled={useAutoCalculatedDelay} value={selectedTimeScale.text} />}
              optionComponent={<OptionComponentSimple />}
              disabled={useAutoCalculatedDelay}
              onChange={(value) => delayTimeScaleChanged(value, true)}
              width={200}
              maxWidth={200}
            />
          </div>
        </div>
      </>
    ),
    [changeCalculationDelayValue, useAutoCalculatedDelay, delayTimeScaleChanged, calculationDelay, notEnoughData],
  );

  const delaySlider = useMemo(
    () => (
      <div className={styles.mt20}>
        <span className={`${styles.instructions} ${!useAutoCalculatedDelay && styles.enable}`}>
          This scale represents % of data points received and the corresponding delay to wait
        </span>
        <div className={styles.mt20}>
          {delayPoints?.length > 0 && (
            <div className={styles.sliderWrapper}>
              <Slider
                automationId="delayTimeSlider"
                value={localValue}
                max={delayPoints[delayPoints.length - 1].value}
                min={delayPoints[0].value}
                step={1}
                points={delayPoints}
                hidePoint
                isDisabled={useAutoCalculatedDelay}
                tooltipValueFormatter={() => calculationDelayDisplayValue}
                alignLeft
                onRelease={selectPoint}
                onChange={selectPoint}
              />
              <div className={styles.labelWrapper}>
                <div className={styles.top}>
                  <span className={useAutoCalculatedDelay && styles.disabled}>Time</span>
                </div>
                <div className={styles.bottom}>
                  <span className={useAutoCalculatedDelay && styles.disabled}>%</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    ),
    [delayPoints, useAutoCalculatedDelay, selectPoint],
  );

  const content = useMemo(
    () => (
      <div>
        {autoCalculate}

        {delayTime}
        {!notEnoughData && delaySlider}
      </div>
    ),
    [notEnoughData, delayPoints, localValue, selectPoint, selectedTimeScale],
  );

  const errorContent = useMemo(
    () => (
      <div className={styles.errorWrapper}>
        <EmptyOptions />
        <span>Delay could not be evaluated because composite metric has errors</span>
      </div>
    ),
    [],
  );

  const contentWrapper = useMemo(() => (compositeDelayHasError ? errorContent : content), [
    compositeDelayHasError,
    notEnoughData,
    delayPoints,
    localValue,
    selectPoint,
    selectedTimeScale,
  ]);

  const footer = useMemo(
    () => (
      <div className={`${styles.footerWrapper} ${styles.lastItem}`}>
        <div className={styles.buttonWrapper}>
          {!compositeDelayHasError && (
            <AsyncButton
              type="submit"
              isDisabled={isUpdateCompositeDelayLoading || (!useAutoCalculatedDelay && calculationDelay === '0')}
              isLoading={isUpdateCompositeDelayLoading}
              automationId="confirmButton"
              onClick={onConfirm}
            >
              Confirm
            </AsyncButton>
          )}
        </div>
        <Button text="Cancel" onClick={() => history.push(backUrl)} colorSchema={COLORS.GRAY_200} />
      </div>
    ),
    [compositeDelayHasError, isUpdateCompositeDelayLoading, useAutoCalculatedDelay, calculationDelay],
  );

  const header = useMemo(
    () => (
      <>
        <span className={styles.header}>Composite Delay</span>
        <div>
          <span className={styles.instructions}>
            The composite delay determines the waiting time before Anodot computes the next data point.
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.anodot.com/hc/en-us/articles/360016146680-Managing-delays-in-Composite-Metrics"
              className={styles.link}
            >
              Learn more
            </a>
          </span>
        </div>
      </>
    ),
    [],
  );

  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={() => history.push(backUrl)}>
      <div className={styles.container}>
        {header}
        <div className={styles.flexColumn}>
          {isCompositeDelayLoading || isCompositesListLoading ? loader : contentWrapper}
          {footer}
        </div>
      </div>
    </Modal>
  );
};

DelayModal.propTypes = {
  backUrl: PropTypes.string.isRequired,
};

export default DelayModal;
