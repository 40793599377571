import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import useClickOutside from 'common/hooks/useClickOutside';
import {segmentClickEvent} from 'common/store/actions';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import ExpressionWarning from 'common/componentsV2/ExpressionBuilderV2/ExpressionWarning';
import {getIsRbacEnabled} from 'admin.permissions/store/selectors';
import CompositeBuilder from 'metrics/components/compositeBuilder/CompositeBuilder';
import * as metricsSelectors from 'metrics/store/selectors';
import {selectTreeBranch, setAllExpressionsSelected, setTreeVisible} from 'metrics/store/actions';
// TODO: move storage/selectors to common
import {getExpressionErrorsFactory} from 'dashboards/store/selectors';
import Tooltip from 'common/componentsV2/Tooltip';
import ExpressionActions from 'metrics/components/metricExplorer/ExpressionActions';
import './CompositeBuildersContainer.module.scss';

type PropTypes = {
  isModal: boolean,
  children: React.Node,
};

const CompositeBuildersContainer = ({isModal, children}: PropTypes) => {
  const dispatch = useDispatch();
  const expressionTrees = useSelector(metricsSelectors.getExpressionTrees);
  const selectedExpressionId = useSelector(metricsSelectors.getSelectedExpressionId);
  const allExpressionsSelected = useSelector(metricsSelectors.getAllExpressionsSelected);
  const isAllSelected = useSelector(metricsSelectors.getAllExpressionsSelected);
  const isUseRbac = useSelector(getIsRbacEnabled);

  const ref = useRef();

  const clickOutside = () => {
    if (!isAllSelected) {
      dispatch(setAllExpressionsSelected(true));
    }
    if (expressionTrees.length === 1) {
      const treeId = expressionTrees[0].id;
      const branchId = expressionTrees[0].expressionTree.root.id;
      dispatch(selectTreeBranch({branchId, selectedExpressionId: treeId}));
    }
  };

  useEffect(() => {
    const stripedTree = JSON.stringify(expressionTrees.map((et) => et.expressionTree.root));
    const numberEmptyFunctions = stripedTree.split('"function":""').length - 1;
    const functionAmount = stripedTree.split('"type":"function"').length - 1 - numberEmptyFunctions;
    const metricsAmount = stripedTree.split('"type":"metric"').length - 1;
    const expressionTreesLength = expressionTrees.length;

    return () => {
      dispatch(
        segmentClickEvent({
          type: 'metric-explorer',
          data: {
            trees: expressionTreesLength,
            functions: functionAmount,
            lines: metricsAmount,
            emptyFunctions: numberEmptyFunctions,
          },
        }),
      );
    };
  }, [JSON.stringify(expressionTrees.map((et) => et.expressionTree.root))]);

  useClickOutside(
    ref,
    clickOutside,
    ['idle-external-click'],
    ['idle-external-click', 'andt-dropdown-menu-list-virtualize', 'form-menu'],
  );

  const [closedIds, setClosedIds] = useState([]);

  const getLetter = (index) => String.fromCharCode(65 + index);

  const handleHolderClick = (id) => {
    const index = closedIds.findIndex((c) => c === id);
    if (index > -1) {
      dispatch(segmentClickEvent({type: 'click', name: 'expand-expression'}));
      setClosedIds([...closedIds.slice(0, index), ...closedIds.slice(index + 1)]);
    } else {
      dispatch(segmentClickEvent({type: 'click', name: 'collapse-expression'}));
      setClosedIds([...closedIds, id]);
    }
  };

  const handleContainerClick = (id) => {
    // eslint-disable-next-line no-unused-vars
    const selectedET = expressionTrees.find((et) => et.id === id).expressionTree;
    // dispatch(selectTreeBranch({branchId: selectedET.root.id, selectedExpressionId: id}));
  };

  const handleClickVisible = (id, invisible) => {
    dispatch(
      segmentClickEvent(segmentClickEvent({type: 'click', name: invisible ? 'hide-expression' : 'show-expression'})),
    );
    dispatch(setTreeVisible({id, invisible}));
    if (selectedExpressionId !== id) {
      const selectedET = expressionTrees.find((et) => et.id === id).expressionTree;
      dispatch(selectTreeBranch({branchId: selectedET.root.id, selectedExpressionId: id}));
    }
  };

  const compositeErrorsSelector = useMemo(() => getExpressionErrorsFactory('expressionTreesPreview'));
  const compositeErrors = useSelector(compositeErrorsSelector);

  return (
    <DndProvider backend={HTML5Backend}>
      <div styleName="over" ref={ref}>
        {expressionTrees.map((et, index) => (
          <div
            key={index === 0 ? 'initial' : et.id}
            automation-id="metricExplorerExpressionTree"
            onClick={() => handleContainerClick(et.id)}
            styleName={['container', et.id === selectedExpressionId || allExpressionsSelected ? 'selected' : ''].join(
              ' ',
            )}
          >
            <div styleName="top-line">
              <div
                automation-id="metricExplorerExpressionTreeLetter"
                styleName={['holder', et.invisible ? 'invisible' : ''].join(' ')}
                onClick={() => handleHolderClick(et.id)}
                role="button"
              >
                <div
                  styleName={`ico  ${closedIds.findIndex((c) => c === et.id) > -1 ? 'collapsed' : ''}`}
                  className="icon icn-arrow16-chevrondown"
                />
                <div styleName="letter">{getLetter(index)}</div>
              </div>
              <div onClick={(e) => e.stopPropagation()} styleName="holder-menu">
                <Tooltip content={et.invisible ? 'Show Expression' : 'Hide Expression'}>
                  <div
                    onClick={() => handleClickVisible(et.id, !et.invisible)}
                    automation-id="metricExplorerExpressionTreeEye"
                    className={[
                      'icon',
                      et.invisible ? 'icn-general16-show' : 'icn-general16-hide',
                      'color_gray-400',
                    ].join(' ')}
                    styleName="eye-icon"
                    role="button"
                  />
                </Tooltip>
                <ExpressionActions expressionTreeId={et.id} isModal={isModal} />
              </div>
            </div>
            {closedIds.findIndex((c) => c === et.id) > -1 ? null : (
              <Fragment>
                {isUseRbac && (
                  <ExpressionWarning
                    expression={(expressionTrees.find((exp) => exp.id === et.id) || {}).expressionTree}
                  />
                )}
                <CompositeBuilder
                  errors={compositeErrors}
                  expressionTrees={expressionTrees}
                  expressionTreeId={et.id}
                  isInvisible={et.invisible}
                  isShowDisplayOnlyFunctions
                  isHideTextFunctions={isModal}
                  isAllowDuplicateExpression={!isModal}
                  useDropDownExternalProvider={!isModal}
                />
              </Fragment>
            )}
            {children}
          </div>
        ))}
      </div>
    </DndProvider>
  );
};

export default React.memo(CompositeBuildersContainer);
