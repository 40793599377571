import React, {useCallback, useState} from 'react';
import {noop, uniq} from 'lodash';
import {useSelector, useDispatch} from 'react-redux';
import {
  fetchPropAndValList,
  fetchProps,
  fetchPropsApi,
  fetchEventsProperties,
  resetEventsProperties,
} from 'userEvents/store/actions';
import * as selectors from 'userEvents/store/selectors';
import ExpressionBuilder from 'common/componentsV2/ExpressionBuilderV2/ExpressionBuilder';
import './EventDetails.module.scss';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import SmallDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/SmallDropdownButton';
import OptionComponent from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/OptionComponent';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import MenuSectionSearch from 'common/componentsV2/ExpressionBuilderV2/MenuSectionSearch';
import {getPropertiesApiData} from 'metrics/store/selectors';
import {eventOptions, localActions} from './EventsFilterService';

const EventDetail = ({
  details,
  validation,
  localDispatch,
  count,
  automationId,
}: {
  details: Object,
  validation: Object,
  localDispatch: Function,
  count: Number,
  automationId: string,
}) => {
  const dispatch = useDispatch();
  const onClear = useCallback(() => {
    localDispatch({type: localActions.REMOVE_EVENT_FILTER, payload: details.id});
  }, []);

  const mainPanelOptions = useSelector(selectors.getMainPanelOptions);
  const secondaryPanelOptions = useSelector(selectors.getSecondaryPanelOptions);
  const isFetchPropsLoading = useSelector(selectors.getFetchPropIsLoading);
  const isFetchPropAndValListApiLoading = useSelector(selectors.getIsFetchPropAndValApiListLoading);
  const metrics = useSelector(getPropertiesApiData);
  const eventsProperties = useSelector(selectors.getEventsProperties);
  const [topSelectorMenu, setTopSelectorMenu] = useState(0);

  const onExpressionChange = (value) => {
    dispatch(resetEventsProperties());
    if (value.length === 0) {
      localDispatch({type: localActions.RESET_PROP_AND_DIM, payload: {id: details.id}});
    }
    localDispatch({type: localActions.CHANGE_QUERY_EXPRESSION, payload: {id: details.id, expression: value}});
  };

  const changeEventType = (value) => {
    localDispatch({type: localActions.CHANGE_EVENT_TYPE, payload: {id: details.id, eventType: value}});
  };

  const changeMetricFilter = useCallback((value, index) => {
    localDispatch({
      type: localActions.CHANGE_EVENT_PROPERTY,
      payload: {id: details.id, eventMetricFilter: value, index},
    });
  }, []);

  const changeMetricDimension = useCallback((value, index) => {
    localDispatch({
      type: localActions.CHANGE_EVENT_METRIC,
      payload: {id: details.id, metricDimension: value, index},
    });
  }, []);

  const addNewPropAndDim = useCallback(() => {
    localDispatch({
      type: localActions.ADD_PROP_AND_DIM,
      payload: {id: details.id},
    });
  }, []);

  const removePropAndDim = useCallback((index) => {
    localDispatch({
      type: localActions.REMOVE_PROP_AND_DIM,
      payload: {id: details.id, index},
    });
  }, []);

  const renderPropAndDimSelector = useCallback(
    (filter, index) => {
      let addRemoveIcon = (
        <div styleName="add-remove-selector" onClick={() => removePropAndDim(index)}>
          <i className="icon icn-general16-closea" />
        </div>
      );
      if (index === details.eventMetricFilter.length - 1) {
        addRemoveIcon = (
          <div styleName="add-remove-selector" onClick={addNewPropAndDim}>
            <i className="icon icn-action16-plusb" />
          </div>
        );
      }
      if (index === 3) {
        addRemoveIcon = (
          <Tooltip content="Can't add more, maximum 4" type={TYPES.SMALL}>
            <div>
              <div styleName="add-remove-selector disabled">
                <i className="icon icn-action16-plusb" />
              </div>
            </div>
          </Tooltip>
        );
      }

      const handleEventPropertyOpen = () => {
        const obj = {
          expression: '',
          filter: details.expression.map((ex) => ex.getExpressionTreeObjectification()),
          properties: [],
          size: 50,
        };

        dispatch(fetchEventsProperties(obj));
      };

      return (
        <>
          <FormDdlSelect
            options={uniq(eventsProperties).map((p) => ({value: p, label: p}))}
            selected={filter.eventProperty ? {value: filter.eventProperty, label: filter.eventProperty} : null}
            button={<SmallDropdownButton useAllWidth value={filter.eventProperty} placeholder="Event Property" />}
            onChange={(value) => changeMetricFilter(value, index)}
            buttonWidth={126}
            width={290}
            optionComponent={<OptionComponent />}
            isUseSearch
            onOpen={handleEventPropertyOpen}
          />
          <div styleName="connector-word">to</div>
          <FormDdlSelect
            options={(metrics || []).filter((f) => f !== 'what').map((p) => ({value: p, label: p}))}
            selected={filter.metricDimension ? {value: filter.metricDimension, label: filter.metricDimension} : null}
            button={<SmallDropdownButton useAllWidth value={filter.metricDimension} placeholder="Metric Dimension" />}
            onChange={(value) => changeMetricDimension(value, index)}
            buttonWidth={126}
            width={290}
            optionComponent={<OptionComponent />}
            isUseSearch
          />
          {addRemoveIcon}
        </>
      );
    },
    [details, eventsProperties, metrics],
  );

  const renderPropAndDimensionsBlock = (
    <div styleName="prop-and-dimensions-block">
      <div styleName="instructions">Match event property to metric dimension</div>
      <div>
        {details.eventMetricFilter?.map((filter, index) => (
          <div
            styleName="prop-and-dim-selector"
            key={
              // eslint-disable-next-line react/no-array-index-key
              index
            }
          >
            {renderPropAndDimSelector(filter, index)}
          </div>
        ))}
      </div>
    </div>
  );

  // const renderMatchValue = (
  //   <div styleName="match-value-container">
  //     Match Values
  //     <ModalGetResource
  //       type="test"
  //       button={
  //         <div styleName="chevron-button">
  //           <i className="icon icn-arrow16-chevrondown" />
  //         </div>
  //       }
  //     />
  //   </div>
  // );
  const renderMatchValue = null; // GABPAC - temporary until this part is developed

  const topMenuValues = ['all', 'title', 'category', 'properties', 'source'];

  let mainPanelOptionsFiltered = topSelectorMenu === 0 ? mainPanelOptions : secondaryPanelOptions;

  if (topSelectorMenu === 3) {
    mainPanelOptionsFiltered = mainPanelOptionsFiltered.filter(
      (opt) => !(opt.parent === 'title' || opt.parent === 'category' || opt.parent === 'source'),
    );
  }

  const handleChangeTopMenu = useCallback((val) => {
    let prop = [val];
    if (val === 'all' || val === 'properties') {
      prop = [];
    }
    let filteredValue = null;
    if (details.eventType.value === 'SUPPRESS' || details.eventType.value === 'OFFICE_HOURS') {
      filteredValue = {
        isExact: true,
        key: 'type',
        type: 'field',
        value: details.eventType.value,
      };
    }
    const filter = details.expression.map((ex) => ex.getExpressionTreeObjectification());
    if (filteredValue) {
      filter.push(filteredValue);
    }
    dispatch(
      fetchPropsApi({
        expression: '',
        filter,
        properties: prop,
        size: 50,
      }),
    );
    setTopSelectorMenu(topMenuValues.findIndex((v) => v === val));
  }, []);

  const callFirstPanel = (val) => {
    let filteredValue = null;
    if (details.eventType.value === 'SUPPRESS' || details.eventType.value === 'OFFICE_HOURS') {
      filteredValue = {
        isExact: true,
        key: 'type',
        type: 'field',
        value: details.eventType.value,
      };
    }
    if (filteredValue) {
      val.filter.push(filteredValue);
    }
    dispatch(fetchPropAndValList(val, JSON.stringify(val)));
  };

  let counterDisplay = `(${details.totalEvents}/${count})`;
  if (details.totalEvents === undefined || details.expression.length === 0) {
    counterDisplay = `(${count})`;
  }

  return (
    <div styleName="container">
      <div styleName="top-line">
        <div styleName="header">
          <div styleName="title">{details.eventType?.name}</div>
          <div styleName="count">{counterDisplay}</div>
          <FormDdlSelect
            options={eventOptions}
            button={
              <div styleName="chevron-button">
                <i className="icon icn-arrow16-chevrondown" />
              </div>
            }
            select={eventOptions.find((option) => option.value === details.eventType.value)}
            optionComponent={<OptionComponentSimple />}
            onChange={changeEventType}
            width={180}
            automationId="correlateAnomaliesWithEvents"
            position="right"
          />
        </div>
        <div styleName="clear" onClick={() => onClear()}>
          <i className="icon icn-general16-closea" />
        </div>
      </div>
      <div styleName="expression-builder-container">
        <ExpressionBuilder
          automationId={automationId}
          customPlaceholder="Search events by their title, source, category and properties"
          mainPanelOptions={mainPanelOptionsFiltered}
          secondaryPanelOptions={secondaryPanelOptions}
          fetchFirstPanel={callFirstPanel}
          expression={details.expression}
          secondarySearch={(val) => dispatch(fetchProps(val))}
          isPrimaryPanelLoading={isFetchPropAndValListApiLoading}
          isSecondaryPanelLoading={isFetchPropsLoading}
          onExpressionChange={onExpressionChange}
          handleTexInputClick={noop}
          onClick={noop}
          handleNewFunction={noop}
          handleDeleteFunction={noop}
          clearSideMenu
          useTopMenu
          topMenu={
            <MenuSectionSearch
              selectedIndex={topSelectorMenu}
              options={['all', 'title', 'category', 'properties', 'source']}
              onChange={handleChangeTopMenu}
            />
          }
          isForceCheckbox={topSelectorMenu !== 0}
        />
      </div>
      {details.eventType.properties.usesMatchingProp && renderPropAndDimensionsBlock}
      {!validation?.isValid && !details.isPristine && <div styleName="validate-blue">{validation?.message}</div>}
      {renderMatchValue}
    </div>
  );
};

export default EventDetail;
