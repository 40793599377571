// @flow
import React, {useCallback, useMemo} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import {FEEDBACK_OPTIONS, FEEDBACK_PARAMS} from 'alerts.console/services/alertsConsoleService';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentCheckboxHeaders from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentCheckboxHeaders';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';

const AckFilter = () => {
  const [positiveFeedback, setPositiveFeedback] = useQueryParam(FEEDBACK_PARAMS.POSITIVE_FEEDBACK, StringParam);
  const [negativeFeedback, setNegativeFeedback] = useQueryParam(FEEDBACK_PARAMS.NEGATIVE_FEEDBACK, StringParam);
  const [noFeedback, setNoFeedback] = useQueryParam(FEEDBACK_PARAMS.NO_FEEDBACK, StringParam);

  const selectedFeedback = useMemo(() => {
    const tmpSelected = [];
    if (positiveFeedback) {
      tmpSelected.push(FEEDBACK_OPTIONS.find((item) => item.id === FEEDBACK_PARAMS.POSITIVE_FEEDBACK));
    }
    if (negativeFeedback) {
      tmpSelected.push(FEEDBACK_OPTIONS.find((item) => item.id === FEEDBACK_PARAMS.NEGATIVE_FEEDBACK));
    }
    if (noFeedback) {
      tmpSelected.push(FEEDBACK_OPTIONS.find((item) => item.id === FEEDBACK_PARAMS.NO_FEEDBACK));
    }
    return tmpSelected;
  }, [positiveFeedback, negativeFeedback, noFeedback]);

  const toggleFilter = useCallback(
    (items) => {
      let difference = items.filter((item) => !selectedFeedback.includes(item));
      if (difference.length > 0) {
        if (difference.find((item) => item.value === FEEDBACK_PARAMS.NO_FEEDBACK)) {
          setNoFeedback('true');
          setPositiveFeedback(undefined);
          setNegativeFeedback(undefined);
        } else {
          setNoFeedback(undefined);
          if (items.find((item) => item.value === FEEDBACK_PARAMS.POSITIVE_FEEDBACK)) {
            setPositiveFeedback('true');
          } else setPositiveFeedback(undefined);
          if (items.find((item) => item.value === FEEDBACK_PARAMS.NEGATIVE_FEEDBACK)) {
            setNegativeFeedback('true');
          } else setNegativeFeedback(undefined);
        }
      } else {
        difference = selectedFeedback.filter((item) => !items.includes(item));
        if (difference.find((item) => item.value === FEEDBACK_PARAMS.POSITIVE_FEEDBACK)) {
          setPositiveFeedback(undefined);
        }
        if (difference.find((item) => item.value === FEEDBACK_PARAMS.NEGATIVE_FEEDBACK)) {
          setNegativeFeedback(undefined);
        }
        if (difference.find((item) => item.value === FEEDBACK_PARAMS.NO_FEEDBACK)) {
          setNoFeedback(undefined);
        }
      }
    },
    [setPositiveFeedback, setNegativeFeedback, setNoFeedback, selectedFeedback],
  );

  const filterLabel = useMemo(() => {
    if (selectedFeedback?.length) {
      return selectedFeedback.map((item) => item.label).join(', ');
    }
    return 'Select';
  }, [selectedFeedback]);

  return (
    <FormDdlSelect
      id="props.automationId "
      automationId="AlertFeedbackFilter"
      extraClassName="alerts-dropdown-btn"
      options={FEEDBACK_OPTIONS}
      selected={selectedFeedback}
      width={240}
      maxWidth={240}
      onChange={(items) => toggleFilter(items)}
      value={selectedFeedback}
      placeholder="Select"
      placement="auto"
      button={
        <BigDropdownButton
          isPristine={selectedFeedback && !selectedFeedback.length}
          placeholder="Select"
          label={filterLabel}
          shortBlueStyle
          isFullWidth
        />
      }
      isMulti
      isUseSearch={false}
      isDisabledSorting
      checkSelectedByProp="value"
      optionComponent={<OptionComponentCheckboxHeaders />}
    />
  );
};
export default AckFilter;
