import React from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';

const validate = (value) => {
  return !value && 'Channel Data Name is mandatory';
};

const ChannelDataName = ({channelName}: {channelName: String}) => {
  const channelDataName = channelName ? `${channelName} channel name` : 'Channel Data Name';
  const {
    input: {value, onChange, onBlur},
    meta,
  } = useField('channelDataName', {validate});
  return (
    <div>
      <div>{channelDataName}</div>
      <Input
        placeHolder={channelDataName}
        automationId={channelName ? `${channelName}-channel-name` : 'channel-data-name'}
        isInvalid={meta.touched && meta.error}
        invalidMessage={meta.error}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default ChannelDataName;
