// @flow
/* eslint-disable */
import React, {useEffect, useRef, useCallback} from 'react';
import {useField} from 'react-final-form';
import Highcharts from 'highcharts';
import {useSelector} from 'react-redux';
import {resolutionTypes} from 'metrics/services/metricsService';
import {Box} from '@material-ui/core';
import {TypographyBox} from 'common/componentsV2/boxTools';
import * as commonSelectors from 'profile/store/selectors';
import TimeSeriesChart from 'charts/timeSeries/components/TimeSeriesChart';
import {CHART_HEIGHT} from 'alerts.console/services/alertsConsoleService';
import MetricName from 'metrics/components/metricName/MetricName';
import {generateChartSeriesMetricModel, processSeriesData} from 'charts/timeSeries/services/timeSeriesDataService';
import {endLoad, pushSeries} from 'charts/timeSeries/services/timeSeriesHchartService';
import Spinner, {SIZES as SPINNER_SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: true,
};

type PropTypes = {
  metric: Object,
  timeScale: string,
  index: number,
  mouseMoveHandler: Function,
  onEndLoad: Function,
  isZoomLoading: boolean,
  expressionTreeId: string,
  byTreeExp: Array,
  dateRange: Object,
};

const MetricExplorerSimulation = ({
  metric,
  timeScale,
  index,
  mouseMoveHandler,
  onEndLoad,
  isZoomLoading,
  expressionTreeId,
  byTreeExp,
  dateRange,
}: PropTypes) => {
  const hchartInstance = useRef();
  const id = `expression-builder-${metric.id}-${index}`;
  const estimationArr = (metric.estimation || '').split('/');

  const bucketStartTimeEnabled = useSelector(commonSelectors.getBucketStartTimeEnabled);
  const timeZoneName = useSelector(commonSelectors.getTimeZoneName);

  useEffect(() => {
    const chartId = `expression-builder-${metric.id}-${index}`;
    const hchart = Highcharts.charts.find((chart) => chart && chart.renderTo.id === chartId);
    hchartInstance.current = hchart;
    const fromDate = dateRange ? Math.ceil(dateRange.startDate / 1000) : null;
    const toDate = dateRange ? Math.ceil(dateRange.endDate / 1000) : null;

    const metricsModel = generateChartSeriesMetricModel(metric, expressionTreeId, index || 0);
    const processedSeriesData = processSeriesData(metric.dataPoints, metric.baseline, false, null);
    metricsModel.expressionTreeId = expressionTreeId;
    while (hchart.series && hchart.series.length) {
      hchart.series[0].remove();
    }
    pushSeries(hchart, {byTreeExp}, metricsModel, processedSeriesData, true);
    endLoad(hchart, fromDate, toDate);
    onEndLoad();
  }, [metric, byTreeExp]);

  const {
    input: {onChange: onChangeDateRange},
  } = useField('dateRange');

  const onRangeSelection = useCallback((data) =>
    onChangeDateRange({
      constRange: 'c',
      startDate: data.startDate * 1000,
      endDate: data.endDate * 1000,
    }),
  );

  return (
    <React.Fragment>
      <Box mt={0.5} mb={1} display="flex" justifyContent="space-between">
        {metric && <MetricName metric={metric} highlightText={null} />}
        <Box flexShrink={0} display="flex" padding={0.5}>
          <TypographyBox
            variant={estimationArr[1] ? 'h5' : 'caption'}
            mr={estimationArr[1] ? '5px' : 0}
            css={{textTransform: 'capitalize'}}
          >
            {estimationArr[0]}
          </TypographyBox>
          {estimationArr[1] && (
            <TypographyBox variant="caption">
              {'/ '}
              {estimationArr[1]}
            </TypographyBox>
          )}
        </Box>
      </Box>
      <Box
        position="relative"
        height={250}
        bgcolor="white.500"
        id="chartWrapper"
        // Commented the stopPropagation as it seams that it does not needed anymore in React 17
        // for more info look at https://reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation
        // Keeping this causing chart zoom to not work as a result of not getting the mouse event.
        // onMouseDownCapture={(event) => event.stopPropagation()}
        mb={4}
      >
        {isZoomLoading && (
          <Box
            width={1}
            height={1}
            position="absolute"
            bgcolor="white.500"
            css={{opacity: 0.5, zIndex: 1}}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner color={palette.gray[500]} size={SPINNER_SIZES.MEDIUM_50} />
          </Box>
        )}
        <TimeSeriesChart
          onMouseMove={mouseMoveHandler}
          onMouseLeave={mouseMoveHandler}
          disableActions
          id={id}
          eventsMeta={{
            key: `${metric.id}`,
            chartId: id,
          }}
          index={index}
          tooltip={TOOLTIP_OPTIONS}
          height={CHART_HEIGHT}
          bucketStartTimeEnabled={bucketStartTimeEnabled}
          timeScale={resolutionTypes[timeScale === '' ? 'auto' : timeScale].value2}
          timeZoneName={timeZoneName}
          theme={'preview'}
          onRangeSelection={onRangeSelection}
        />
      </Box>
    </React.Fragment>
  );
};

export default React.memo(MetricExplorerSimulation);
