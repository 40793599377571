import React from 'react';
import './IntegrationsFilter.module.scss';
import Input from 'common/componentsV2/Input';
import {useQueryParams, StringParam} from 'use-query-params';
import IntegrationsFilterSelector from './IntegrationsFilterSelector';

const EMPTY_ARRAY = [];

type PropTypes = {
  pageConfig: Object,
};

const IntegrationsFilter = ({pageConfig}: PropTypes) => {
  const [query, setQuery] = useQueryParams({
    selectedCategory: StringParam,
    filterText: StringParam,
    selectedTab: StringParam,
  });

  const {selectedCategory, filterText, selectedTab} = query;

  const handleOnSelect = (category) => {
    setQuery({selectedCategory: category, filterText: ''});
  };

  const filterList = pageConfig ? pageConfig[selectedTab]?.filters : EMPTY_ARRAY;
  const onTabClick = (tab) => {
    setQuery({selectedTab: tab, filterText: '', selectedCategory: 'all'});
  };

  const onFilterTextChange = (e) => {
    setQuery({filterText: e.target.value, selectedCategory: 'all'});
  };

  return (
    <div>
      <Input automationId="catalogSearchField" value={filterText} onChange={onFilterTextChange} placeHolder="Search" />
      <div styleName="tab-container">
        <div
          automation-id="catalogSouresTab"
          styleName={['tab', selectedTab === 'sources' ? 'selected' : ''].join(' ')}
          onClick={() => onTabClick('sources')}
        >
          Sources
        </div>
        <div
          automation-id="catalogChannelsTab"
          styleName={['tab', selectedTab === 'channels' ? 'selected' : ''].join(' ')}
          onClick={() => onTabClick('channels')}
        >
          Channels
        </div>
      </div>
      {filterList?.map((group) => (
        <div key={group.groupName}>
          <div styleName="group-title">{group.groupTitle || ''}</div>
          {group.categories.map((cat) => (
            <IntegrationsFilterSelector
              isSelected={cat.categoryName === selectedCategory}
              key={cat.categoryName}
              category={cat}
              onSelect={handleOnSelect}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default IntegrationsFilter;
