// flow
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Transition} from 'react-transition-group';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {getNumLastDays} from 'insightsPanelNewAlertConsole/store/selectors';
import PieChart from 'charts/pieChart/PieChart';
import {FEEDBACK} from 'charts/pieChart/pieChartSettingsService';
import {fadeOpacity, formatFeedbackDistribution} from 'insightsPanelNewAlertConsole/services/constants';
import {segmentClickEvent} from 'common/store/actions';
import fetchTriggeredAlertsFeedbackStatsProvider from 'insightsPanelNewAlertConsole/api/fetchTriggeredAlertsFeedbackStatsProvider';
import {FEEDBACK_TYPE} from 'feedback/services/constants';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS, FEEDBACK_OPTIONS} from 'alerts.console.new/services/alertsConsoleService';
import {isEmpty} from 'lodash';

import './FeedbackDistribution.module.scss';

const FeedbackDistribution = ({title, onFilterAlerts}: {title: String, onFilterAlerts: Function}) => {
  const numLastDays = useSelector(getNumLastDays);
  const dispatch = useDispatch();

  const feedbackStatsProvider = fetchTriggeredAlertsFeedbackStatsProvider({numLastDays: numLastDays?.value}).useQuery();
  const isLoading = feedbackStatsProvider?.isLoading;

  const [showData, setShowData] = useState(false);

  const feedbackStatsData = useMemo(() => {
    let feedbacksDistribution;
    if (!isEmpty(feedbackStatsProvider?.data)) {
      feedbacksDistribution = formatFeedbackDistribution(feedbackStatsProvider?.data);
    }
    return feedbacksDistribution;
  }, [feedbackStatsProvider]);

  useEffect(() => {
    if (feedbackStatsData) {
      setShowData(true);
    }
  }, [feedbackStatsData]);

  const segmentTracking = (name) => {
    dispatch(
      segmentClickEvent({
        category: 'Insights Panel',
        name,
      }),
    );
  };

  const clickOnFeedback = (event) => {
    let feedback = null;

    if (typeof event === 'object') {
      feedback = event.point.type;
    }
    if (typeof event === 'string') {
      feedback = event;
    }
    if (feedback === FEEDBACK_TYPE.GOOD_CATCH) {
      onFilterAlerts({[ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK]: FEEDBACK_OPTIONS.POSITIVE.value});
    } else if (feedback === FEEDBACK_TYPE.NOT_INTERESTING) {
      onFilterAlerts({[ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK]: FEEDBACK_OPTIONS.NEGATIVE.value});
    }
    segmentTracking(`Feedback Per Measure Widget - ${feedback}`);
  };

  const goodCatch = feedbackStatsData?.find((i) => i.id === FEEDBACK_TYPE.GOOD_CATCH)?.percentage ?? 0;
  const notInteresting = feedbackStatsData?.find((i) => i.id === FEEDBACK_TYPE.NOT_INTERESTING)?.percentage ?? 0;

  const tooltipFormatter = {
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      return `<div class="pie-chart-tooltip">${Math.round(this.percentage)}% (${this.y}) - ${this.key}</div>`;
    },
  };

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && feedbackStatsData && (
        <Transition in={showData} timeout={fadeOpacity.duration}>
          {(state) => (
            <>
              <header styleName="header" style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}>
                {title}
              </header>
              <div styleName="container">
                {goodCatch === 0 && notInteresting === 0 ? (
                  <div styleName="no-feedback" />
                ) : (
                  <PieChart
                    onClickEvent={clickOnFeedback}
                    id="insightsPanelMetricsUsage"
                    height={61}
                    width={61}
                    data={feedbackStatsData}
                    theme={FEEDBACK}
                    tooltipFormatter={tooltipFormatter}
                  />
                )}

                <SmartTooltip content="Show Alerts" delay={300}>
                  <div
                    onClick={() => clickOnFeedback(FEEDBACK_TYPE.GOOD_CATCH)}
                    styleName="legend positive"
                    style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}
                  >
                    <div styleName="number">
                      <span>{goodCatch}%</span>
                      <i className="icon icn-general24-like" />
                    </div>

                    <span styleName="text">Good Catch</span>
                  </div>
                </SmartTooltip>

                <SmartTooltip content="Show Alerts" delay={300}>
                  <div
                    onClick={() => clickOnFeedback(FEEDBACK_TYPE.NOT_INTERESTING)}
                    styleName="legend"
                    style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}
                  >
                    <div styleName="number">
                      <span>{notInteresting}%</span>
                      <i className="icon icn-general24-dislike" />
                    </div>
                    <span styleName="text">Not Interesting</span>
                  </div>
                </SmartTooltip>
              </div>
            </>
          )}
        </Transition>
      )}
    </div>
  );
};

export default FeedbackDistribution;
