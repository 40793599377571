import React, {useState, useEffect, memo} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';
import Button, {COLORS} from 'common/componentsV2/Button';
import {palette} from 'app/styles/theme';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {ReactComponent as StatusIcon} from 'investigationNewAlertConsole/images/open_alert.svg';
import FeedbackInvestigationWrapper from 'feedback/FeedbackInvestigationWrapper';
import {modalRouting} from 'investigationNewAlertConsole/services/investigationService';
import {Severity} from 'investigationNewAlertConsole/components/tabs/Badges';
import Acknowledged from 'investigationNewAlertConsole/components/header/Acknowledged';
import MoreActions from 'investigationNewAlertConsole/components/header/MoreActions';

import './InvModalHeader.module.scss';

const useActionBarStyles = makeStyles(() => ({
  iconBtn: {
    height: '36px',
    width: '36px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: palette.gray['500'],
  },
}));

const InvModalHeader = ({alertsGroup, onShareClicked, queryParams, setQueryParams, selectedTabId}: PropTypes) => {
  const classes = useActionBarStyles();
  const [currentTriggerIndex, setCurrentTriggerIndex] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const [isPrev, setIsPrev] = useState(false);

  useEffect(() => {
    if (alertsGroup?.alerts?.length > 1) {
      const triggerIndex = alertsGroup.alerts.findIndex(
        (alert) => alert.alertTriggerId === queryParams?.[modalRouting.TRIGGER_ID],
      );
      setCurrentTriggerIndex(triggerIndex);
      setIsNext(alertsGroup.alerts.length > triggerIndex + 1);
      setIsPrev(triggerIndex > 0);
    }
  }, [JSON.stringify(alertsGroup?.alerts), queryParams?.[modalRouting.TRIGGER_ID]]);

  const nextTrigger = () => {
    if (isNext) {
      setQueryParams({
        [modalRouting.TRIGGER_ID]: alertsGroup?.alerts[currentTriggerIndex + 1].alertTriggerId,
      });
    }
  };

  const prevTrigger = () => {
    if (isPrev) {
      setQueryParams({
        [modalRouting.TRIGGER_ID]: alertsGroup?.alerts[currentTriggerIndex - 1].alertTriggerId,
      });
    }
  };

  return (
    <>
      {!alertsGroup?.alerts?.length ? (
        <div className={classes.main} />
      ) : (
        <div styleName="main">
          <div styleName="title">
            {alertsGroup.alerts.length > 1 && (
              <div styleName="nav">
                <i
                  onClick={prevTrigger}
                  styleName="iconArrow"
                  className="icon icn-arrow16-triangleleft"
                  style={{
                    opacity: isPrev ? '1' : '0.45',
                    cursor: isPrev ? 'pointer' : 'default',
                  }}
                />

                <div styleName="alertsTitle">
                  {currentTriggerIndex + 1}/{alertsGroup.alerts.length} Alerts
                </div>
                <i
                  onClick={nextTrigger}
                  styleName="iconArrow"
                  className="icon icn-arrow16-triangleright"
                  style={{
                    opacity: isNext ? '1' : '0.45',
                    cursor: isNext ? 'pointer' : 'default',
                  }}
                />
              </div>
            )}
            <div styleName="trigger-title-container">
              <div styleName="status-icon-container">
                {alertsGroup?.alerts[currentTriggerIndex].status?.toLowerCase() === 'open' ? <StatusIcon /> : <div />}
              </div>
              <Tooltip
                placement="top"
                content={alertsGroup.alerts[currentTriggerIndex].title}
                type={TYPES.SMALL}
                delay={50}
              >
                <div styleName="trigger-title" className="ellipsis">
                  {alertsGroup?.alerts[currentTriggerIndex].title}
                </div>
              </Tooltip>
              <Severity level={alertsGroup.alerts[currentTriggerIndex].severity} />
            </div>
          </div>

          <div styleName="acknowledge-container">
            <Acknowledged queryParams={queryParams} />
            <FeedbackInvestigationWrapper queryParams={queryParams} />
            <div styleName="share-button-container">
              <Button
                colorSchema={COLORS.GRAY_300}
                icon="icn-Share24"
                extraClassName={classes.iconBtn}
                onClick={onShareClicked}
              />
            </div>
            <MoreActions trigger={alertsGroup.alerts[currentTriggerIndex]} selectedTabId={selectedTabId} />
          </div>
        </div>
      )}
    </>
  );
};

InvModalHeader.propTypes = {
  onShareClicked: PropTypes.func.isRequired,
  alertsGroup: PropTypes.objectOf(PropTypes.any),
  queryParams: PropTypes.objectOf(PropTypes.any),
  setQueryParams: PropTypes.func,
  selectedTabId: PropTypes.string,
};

InvModalHeader.defaultProps = {
  queryParams: undefined,
  setQueryParams: undefined,
  alertsGroup: undefined,
  selectedTabId: undefined,
};

export default memo(InvModalHeader);
