// @flow
/* eslint-disable no-shadow */
import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchChannels} from 'alerts.channels/store/actions';
import {Route, useHistory} from 'react-router-dom';
import {fetchAlertConfigurations} from 'alerts.management/store/actions';
import {fetchUsers} from 'admin.users/store/actions';
import {fetchAccessListForAllChannels} from 'admin.permissions/store/actions';
import PageLayout from 'common/componentsV2/PageLayout';
import ChannelsHeader from 'channels/components/header/ChannelsHeader';
import ChannelsFilters from 'channels/components/filter/ChannelsFilters';
import ChannelsListHeader from 'channels/components/dataManager/ChannelsListHeader';
import ChannelsListBody from 'channels/components/dataManager/ChannelsListBody';

import {StringParam, useQueryParams} from 'use-query-params';
import {getActiveChannels} from 'alerts.channels/store/selectors';
import {getUsersData} from 'admin.users/store/selectors';
import {getIsRbacEnabled} from 'admin.permissions/store/selectors';
import Footer from 'common/componentsV2/Footer';
import {THREE_STATES} from 'common/componentsV2/Checkbox';
import ChannelsFooter from 'channels/components/footer/ChannelsFooter';
import DeleteChannel from './DeleteChannel';
import NewEditChannel from './NewEditChannel';
import * as amSelectors from '../../alerts.management/store/selectors';
import BottomPanel, {BOTTOM_PANEL_TYPE} from '../../common/componentsV2/BottomPanel';

const getUsedAlerts = (alerts, instance) => alerts.filter((alert) => alert.data.channels.includes(instance.id));

const Channels = ({match, location}: {match: Object, location: Object}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [queryParams] = useQueryParams({
    searchQuery: StringParam,
    type: StringParam,
    owner: StringParam,
    accessGroups: StringParam,
  });
  const isRbacEnabled = useSelector(getIsRbacEnabled);

  const [selectedItem, setSelectedItem] = useState();
  const [checkedItems, setCheckedItems] = useState([]);
  const [scrollToId, setScrollToId] = useState();

  const onModalClose = useCallback(
    (id) => {
      history.replace(`${match.url}${location.search}`);
      if (id) {
        setSelectedItem(id);
        setScrollToId(id);
      }
    },
    [match, location, setSelectedItem],
  );

  useEffect(() => {
    if (scrollToId) {
      const node = document.getElementById(`scrollPosition-${scrollToId}`);
      if (node) {
        node.scrollIntoView();
        setScrollToId('');
      }
    }
  }, [scrollToId]);

  useEffect(() => {
    dispatch(fetchAlertConfigurations());
    dispatch(fetchChannels());
    dispatch(fetchUsers());
    if (getIsRbacEnabled) {
      dispatch(fetchAccessListForAllChannels());
    }
  }, []);

  const alertConfigurations = useSelector(amSelectors.getAlertConfigurations);

  const [areFiltersOpen, setFiltersOpen] = useState(true);
  const [listState, setListState] = useState('EXPAND');

  const setMixedListState = useCallback(() => {
    setListState('MIXED');
  }, [setListState]);

  const collapseAll = useCallback(() => {
    setListState('COLLAPSE');
  }, [setListState]);

  const expandAll = useCallback(() => {
    setListState('EXPAND');
  }, [setListState]);

  const channelsDataRaw = useSelector(getActiveChannels);
  const users = useSelector(getUsersData);

  const usersNamesMap = useMemo(
    () => users.reduce((acc, user) => ({...acc, [user.email]: `${user.firstName} ${user.lastName}`}), {}),
    [users],
  );
  const usersEmailsByID = useMemo(() => users.reduce((acc, user) => ({...acc, [user._id]: user.email}), {}), [users]);
  const channelsData = channelsDataRaw
    .filter((channel) => {
      if (queryParams.searchQuery && !channel.name.toLowerCase().includes(queryParams.searchQuery.toLowerCase())) {
        return false;
      }
      if (queryParams.type && !queryParams.type.split(',').includes(channel.channelMeta.id)) {
        return false;
      }
      if (
        queryParams.owner &&
        !queryParams.owner
          .split(',')
          .map((id) => usersEmailsByID[id])
          .includes(JSON.parse(channel.tags).owner)
      ) {
        return false;
      }
      if (
        queryParams.accessGroups &&
        channel.accessGroups &&
        !channel.accessGroups.find((accessGroupId) =>
          queryParams.accessGroups.split(',').some((groupId) => accessGroupId === groupId),
        )
      ) {
        return false;
      }
      return true;
    })
    .map((channel) => ({
      ...channel,
      alerts: getUsedAlerts(alertConfigurations.items, channel),
      createdBy: usersNamesMap[JSON.parse(channel.tags).owner],
    }));
  const toggleSelectAll = useCallback(() => {
    if (checkedItems.length === channelsData.length) {
      setCheckedItems([]);
    } else {
      setCheckedItems(channelsData.map((channel) => channel.id));
    }
  }, [checkedItems, channelsData]);

  return (
    <Fragment>
      <div className="position_relative flexGrow_1">
        <PageLayout
          header={
            <ChannelsHeader
              listState={listState}
              dataStreamsCount={channelsDataRaw.length}
              filteredDataStreamsCount={channelsData.length}
              areFiltersOpen={areFiltersOpen}
              setFiltersOpen={setFiltersOpen}
              onCollapseAll={collapseAll}
              onExpandAll={expandAll}
            />
          }
        >
          <div className="display_flex flexGrow_1">
            <ChannelsFilters areFiltersOpen={areFiltersOpen} setFiltersOpen={setFiltersOpen} />
            <div className="flexGrow_1 display_flex flexDirection_column">
              <ChannelsListHeader
                checkboxState={
                  // eslint-disable-next-line no-nested-ternary
                  checkedItems.length > 0
                    ? checkedItems.length === channelsData.length
                      ? THREE_STATES.FULL_CHECKED
                      : THREE_STATES.HALF_CHECKED
                    : THREE_STATES.UNCHECKED
                }
                onCheckboxClicked={toggleSelectAll}
                isRbacEnabled={isRbacEnabled}
              />
              <ChannelsListBody
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                channelsData={channelsData}
                listState={listState}
                isNoChannels={channelsDataRaw.length === 0}
                setMixedListState={setMixedListState}
              />
            </div>
          </div>
        </PageLayout>
      </div>
      <BottomPanel type={BOTTOM_PANEL_TYPE.blue} isActive={checkedItems.length > 0}>
        <Footer
          selectedItemsCount={checkedItems.length}
          checkboxState={
            checkedItems.length === channelsData.length ? THREE_STATES.FULL_CHECKED : THREE_STATES.HALF_CHECKED
          }
          onCheckboxClicked={toggleSelectAll}
          leftPadding={areFiltersOpen ? 326 : 20}
          isCheckbox={false}
        >
          <ChannelsFooter selectedItems={checkedItems} setCheckedItems={setCheckedItems} />
        </Footer>
      </BottomPanel>
      <Route
        path={[`${match.url}/new/:channelType`, `${match.url}/edit/:channelId`]}
        render={(props) => <NewEditChannel {...props} onClose={onModalClose} />}
      />
      <Route
        path={`${match.url}/delete/:channelId`}
        render={(props) => {
          return (
            <DeleteChannel
              {...props}
              setCheckedItems={setCheckedItems}
              selectedItems={checkedItems}
              channelsData={channelsData}
              onClose={onModalClose}
            />
          );
        }}
      />
    </Fragment>
  );
};

export default Channels;
