import React, {useState} from 'react';
import {palette} from 'app/styles/theme';

type PropTypes = {
  children: Node,
};

const FakeChevron = ({children}: PropTypes) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => setOpen(!isOpen)}>
      <span
        style={{margin: '3px 8px 0 0', fontSize: '14px', color: palette.gray[500]}}
        className={isOpen ? 'icn-arrow16-chevrondown' : 'icn-arrow16-chevronright'}
      />
      {children}
    </div>
  );
};

export default FakeChevron;
