import React, {useCallback, useMemo} from 'react';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {getUsersData} from 'admin.users/store/selectors';
import {useField} from 'react-final-form';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';

const EMPTY_ARRAY = [];

const validate = (value, values) => {
  if (isEmpty(values.email) && isEmpty(values.members)) {
    return 'At least one member or email is mandatory';
  }
  return undefined;
};

const Members = () => {
  const {
    input: {value, onChange},
    // meta,
  } = useField('members', {validate, defaultValue: EMPTY_ARRAY});

  const users = useSelector(getUsersData);

  const owners = useMemo(() => {
    const result = [];
    users.forEach((user) => {
      result.push({
        value: user.email,
        label: `${user.firstName} ${user.lastName}`,
      });
    });
    return result;
  }, [users]);

  const onClearAll = useCallback(
    (val) => {
      if (val && val.length) {
        onChange(undefined);
      } else {
        onChange(owners);
      }
    },
    [owners],
  );

  return (
    <div>
      <div>Add Members</div>
      <SelectAndt
        id="ownerFilter"
        automationId="channelsOwnerFilter"
        extraClassName="alerts-dropdown-btn"
        options={owners}
        menuWidth={290}
        buttonWidth={190}
        optionHeight={40}
        type={TYPE_NEW_MULTI}
        theme={THEME_HIGHLIGHTED}
        onChange={onChange}
        value={value}
        isMulti
        placeholder="Users"
        isClearable={false}
        closeMenuOnSelect={false}
        menuFooterComponent={
          <FormDdlActionBar isVisible selectedCount={value.length} onClearAll={() => onClearAll(value)} />
        }
      />
    </div>
  );
};

export default Members;
