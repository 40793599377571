import {useQuery} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {post} from 'reactQuery/axiosInstance';
import {stringifyAndEscapeQS} from 'common/utils/http';
import {mappingToRollup} from 'investigationNewAlertConsole/services/investigationService';
import fetchGroupAlertsWithMetricsProvider from 'investigationNewAlertConsole/api/fetchGroupAlertsWithMetricsProvider';
import {findTrigger} from 'investigationNewAlertConsole/api/utils';
import fetchConfigurationAlertByIdProvider from 'alerts.management/api/fetchConfigurationAlertByIdProvider';
import {modalRouting} from 'investigation/services/investigationService';
import {queryClient} from 'reactQuery/queryClient';

const timelineRoot = 'api/v1/timeline/';

const fetchTimeline = (payload) => {
  return post(`${timelineRoot}triggered/${payload.triggerId}?${stringifyAndEscapeQS(payload.query)}`, payload.body);
};

const fetchTimelineData = async ({queryParams, userRollupTime, startBucketMode}) => {
  const triggerId = queryParams?.[modalRouting.TRIGGER_ID];
  const anomalyId = queryParams?.[modalRouting.ANOMALY_ID];

  const triggeredAlertsGroup = await fetchGroupAlertsWithMetricsProvider(
    queryParams,
  )?.fetchQueryGetGroupAlertsWithMetrics();
  const trigger = findTrigger(triggeredAlertsGroup, triggerId);

  if (trigger) {
    const alertConfiguration = await fetchConfigurationAlertByIdProvider().fetchQuery(trigger?.alertConfigurationId);
    const isFilterEvent = !!alertConfiguration?.eventsFilter;

    const payload = {
      triggerId: anomalyId,
      query: {
        startBucketMode,
      },
      body: {
        eventsFilters: alertConfiguration?.eventsFilters,
        rollupTime: userRollupTime[mappingToRollup[(trigger?.timeScale)]],
        userEvents: isFilterEvent
          ? {
              rollupTime: userRollupTime[mappingToRollup[(trigger?.timeScale)]],
              aggregation: {...alertConfiguration?.eventsFilter?.aggregation, topEventSize: 1},
              expression: alertConfiguration?.eventsFilter?.filter?.q?.expression,
            }
          : undefined,
      },
    };

    return fetchTimeline(payload);
  }
  return [];
};

const syncTimelineInCache = (queryKey, updateTimeline) => {
  queryClient.setQueriesData(queryKey, (data) => {
    let timelineToUpdate = data;
    if (data) {
      timelineToUpdate = [...timelineToUpdate, ...updateTimeline];
    }
    return timelineToUpdate;
  });
};

export default function fetchTimelineProvider(triggerId) {
  const queryKey = [QUERY_KEYS.triggeredAlertTimeline, triggerId];
  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    useQuery: (payload) =>
      useQuery(queryKey, () => fetchTimelineData(payload), {
        retry: false,
        refetchOnMount: 'always', // since the timeline might change all the time: alert acknowledged, feedback added, metric closed,... etc.
      }),
    syncTimelineInCache: (updateTimeline) => syncTimelineInCache(queryKey, updateTimeline),
  };
}
