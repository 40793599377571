// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import * as selector from 'admin.users/store/selectors';
import {toggleUserEditModal} from 'admin.users/store/actions';
import EditUserForm from './EditUserForm';
import './EditUserForm.module.scss';

type PropTypes = {
  isOpen: boolean,
  toggleUserEditModal: Function,
};

export default connect(
  (state) => ({
    isOpen: selector.getUserEditingModalIsOpen(state),
  }),
  {
    toggleUserEditModal,
  },
)(
  class EditUserModal extends React.PureComponent {
    props: PropTypes;

    onModalClose = () => {
      this.props.toggleUserEditModal({isOpen: false});
    };

    render() {
      return (
        <Modal
          classStyle="user-edit-modal"
          id="user-edit-modal"
          isOpen={this.props.isOpen}
          onClose={this.onModalClose}
          size={SIZES.SMALL}
          isStatic
        >
          <EditUserForm />
        </Modal>
      );
    }
  },
);
