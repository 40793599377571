import React from 'react';
import './UnauthorizedChart.module.scss';
import PropTypes from 'prop-types';

const UnauthorizedChart = ({isTooMuchData}) => (
  <div styleName="container">
    <div styleName="info-icon" className="icon icn-general16-info" />
    {isTooMuchData ? (
      <span>Too much results</span>
    ) : (
      <div styleName="sub-container">
        <span>Permissions are</span>
        <span>needed to see this data</span>
      </div>
    )}
  </div>
);

export default UnauthorizedChart;

UnauthorizedChart.defaultProps = {
  isTooMuchData: false,
};

UnauthorizedChart.propTypes = {
  isTooMuchData: PropTypes.bool,
};
