// @flow
import React from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import './FormDdlActionBar.module.scss';

type PropTypes = {
  isApplyDisabled?: boolean,
  selectedCount?: Number,
  hideCount?: boolean,
  isVisible: boolean,
  onClearAll?: Function,
  onApply?: Function,
  className?: String,
  clearButtonText?: String,
  selectAllButtonText?: String,
  ApplyButtonText?: String,
  segmentClickEvent: Function,
};

export default connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)(
  class FormDdlActionBar extends React.PureComponent {
    props: PropTypes;

    static defaultProps = {
      isApplyDisabled: false,
      selectedCount: null,
      hideCount: false,
      onClearAll: null,
      onApply: null,
      className: '',
      clearButtonText: 'Clear All',
      selectAllButtonText: 'Select All',
      ApplyButtonText: 'Apply',
    };

    handleClearAll = () => {
      this.props.segmentClickEvent({
        type: 'click',
        name: 'clear all',
      });
      this.props.onClearAll();
    };

    handleApply = () => {
      this.props.segmentClickEvent({
        type: 'click',
        name: 'apply',
      });
      this.props.onApply();
    };

    render() {
      const {isApplyDisabled, selectedCount, hideCount, isVisible, onClearAll, onApply, className} = this.props;
      const rootClass = className || '';
      const applyStyle = ['action-btn', isApplyDisabled ? 'disabled' : ''];
      const buttonText =
        selectedCount && selectedCount > 0 ? this.props.clearButtonText : this.props.selectAllButtonText;

      if (!isVisible) {
        return null;
      }

      return (
        <div styleName="root" className={rootClass}>
          <div styleName="action-bar">
            {onClearAll && (
              <div styleName="action-btn">
                <span styleName="action-btn disabled">
                  {!hideCount && selectedCount && selectedCount > 0 ? `(${selectedCount}) ` : null}
                </span>
                <span role="presentation" onClick={this.handleClearAll}>
                  {buttonText}
                </span>
              </div>
            )}
            {onApply && (
              <div styleName={applyStyle.join(' ')}>
                <span role="presentation" onClick={this.handleApply}>
                  {this.props.ApplyButtonText}
                </span>
              </div>
            )}
          </div>
        </div>
      );
    }
  },
);
