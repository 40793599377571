// @flow
import React from 'react';
import {connect} from 'react-redux';
import HexagonBorder from 'common/components/hexagon/HexagonBorder';
import {bcTypes} from 'bc/services/bcTypes';
import {createDataSource as createDataSourceAction} from 'bc/store/actions';
import {getSelfService, getUserProfile} from 'profile/store/selectors';
import FileUploadToAws from './FileUploadToAws';
import './SourceTypeListItem67.module.scss';

type PropTypes = {
  item: Object,
  me: Object,
  createDataSource: Function,
  isSelfService: Boolean,
};

export default connect(
  (state, ownProps) => ({
    item: ownProps.item,
    me: getUserProfile(state),
    isSelfService: getSelfService(state),
  }),
  {
    createDataSource: createDataSourceAction,
  },
)(
  class SourceTypeListItem extends React.PureComponent {
    props: PropTypes;

    state = {
      isCreateSourceModalOpen: false,
    };

    modal = null;

    clickedSourceButton = () => {
      const {item, createDataSource, me} = this.props;
      this.modal = bcTypes[item.type].action(item, null, createDataSource, false, me);
      if (this.modal) {
        this.setState({isCreateSourceModalOpen: true});
      }
    };

    closeCreateSourceModal = () => {
      this.setState({isCreateSourceModalOpen: false});
    };

    getActionForType = () => {
      const {item} = this.props;

      switch (item.collectionType) {
        case 'DC':
          return (
            <div
              styleName="action"
              href=""
              onClick={this.clickedSourceButton}
              automation-id={`sourceTypeActionButton-${item.name}`}
              data-segment={`name: start-authentication-${item.id}, category: data-collectors`}
            >
              {item.actionText}
            </div>
          );
        case 'Agent':
          return (
            <a
              styleName="action"
              href={item.actionLink}
              rel="noopener noreferrer"
              target="_blank"
              data-segment={`name: instructions-${item.id}, category: data-collectors`}
            >
              {item.actionText}
            </a>
          );
        default:
          return (
            <div styleName="action" href="" data-segment="name: file-upload, category: data-collectors">
              <FileUploadToAws sourceType={item} />
            </div>
          );
      }
    };

    getUpgradeState = () => {
      const {item, isSelfService} = this.props;

      if (isSelfService) {
        return (
          <a
            styleName="action upgrade"
            href="mailto:support@anodot.com?subject=[Upgrade] I would like to upgrade my Anodot Account"
            rel="noopener noreferrer"
            automation-id="sourceTypeActionButton-Upgrade"
            data-segment={`name: upgrade-${item.id}, category: data-collectors`}
          >
            Upgrade
          </a>
        );
      }
      return null;
    };

    setRootStyle = () => {
      const {item, isSelfService} = this.props;

      if (item.isActive) {
        return 'root';
      }
      if (!item.isActive && !isSelfService) {
        return 'root soon';
      }
      if (!item.isActive && isSelfService) {
        return 'root soon upgrade';
      }
      return '';
    };

    render() {
      const {item} = this.props;

      const imageStyle = `type-image image-${item.type}`;

      return (
        <div styleName={this.setRootStyle()} automation-id="dataCollectorSourceTypeListItem">
          <div styleName="logo-section">
            <HexagonBorder>
              <div styleName={imageStyle} />
            </HexagonBorder>
            <div styleName="logo-name">{item.name}</div>
          </div>
          <div styleName="info" automation-id="dataCollectorSourceTypeListItemInfo">
            {item.description}
          </div>
          <div styleName="action-wrapper">{item.isActive ? this.getActionForType() : this.getUpgradeState()}</div>
          {this.state.isCreateSourceModalOpen
            ? React.cloneElement(this.modal, {
                isOpen: this.state.isCreateSourceModalOpen,
                onClose: this.closeCreateSourceModal,
              })
            : null}
        </div>
      );
    }
  },
);
