import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';

import {palette} from 'app/styles/theme';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import SimpleButton, {COLORS} from 'common/simpleBtn/SimpleButton';
import Spinner from 'common/componentsV2/Spinner';

const useStyles = makeStyles(({typography}) => ({
  title: {
    ...typography.h1,
    marginBottom: 12,
  },
  text: {
    ...typography.caption,
    marginBottom: 36,
  },
  footer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: 12,
  },
  spaceRight: {
    marginRight: 12,
  },
}));

export default function EditModal({title, text, isOpen, closeModal, onConfirmModal, isLoading, confirmBtnText}) {
  const classes = useStyles();
  return (
    <>
      <Modal id="2-22-222" isOpen={isOpen} onClose={closeModal} size={SIZES.MEDIUM} isStatic>
        <div className={classes.title}>{title}</div>
        <div className={classes.text}>{text}</div>
        <div className={classes.footer}>
          <SimpleButton
            automationId="exportActivityLog"
            colorSchema={COLORS.GRAY_200}
            onClick={closeModal}
            extraClassName={classes.spaceRight}
          >
            <div>
              <span>Cancel</span>
            </div>
          </SimpleButton>

          <SimpleButton automationId="exportActivityLog" colorSchema={COLORS.BLUE_500} onClick={onConfirmModal}>
            <div>
              {isLoading && <Spinner size={30} color={palette.white[500]} />}
              <span>{confirmBtnText || 'Yes'}</span>
            </div>
          </SimpleButton>
        </div>
      </Modal>
    </>
  );
}

EditModal.defaultProps = {
  isLoading: false,
  confirmBtnText: null,
};

EditModal.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  onConfirmModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  confirmBtnText: PropTypes.string,
};
