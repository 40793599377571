import {get} from 'lodash';

export const DELIMITERS = [
  {value: ',', text: 'Comma ( , )'},
  {value: '&', text: 'Ampersand ( & )'},
  {value: '\t', text: 'TAB'},
  {value: '|', text: 'Pipe ( | )'},
  {value: ';', text: 'Semicolon ( ; )'},
  {value: ' ', text: 'White Space'},
];

export const ESCAPE_CHARACTERS = [
  {value: null, text: 'None'},
  {value: '\\', text: 'Back Slash ( \\ )'},
  {value: '^', text: 'Circumflex ( ^ )'},
  {value: String.fromCharCode(30), text: 'Record Separator'},
];

export const QUOTE_CHARACTERS = [
  {value: null, text: 'None'},
  {value: '"', text: 'Double Quotes ( " )'},
  {value: "'", text: `Single Quote ( ' )`},
];

export const DECIMAL_POINTS = [{value: null, text: 'None'}, {value: ',', text: 'Comma'}, {value: '.', text: 'Dot'}];

export const THOUSANDS_SEPARATORS = [
  {value: null, text: 'None'},
  {value: ',', text: 'Comma ( , )'},
  {value: '.', text: 'Dot ( . )'},
  {value: ' ', text: 'White Space'},
];

export const DATE_FORMATS = [
  'MM/dd/yyyy',
  'MM/dd/yyyy HH:mm',
  'MM/dd/yyyy HH:mm:ss',
  "MM/dd/yyyy'T'HH:mm:ss",
  "MM/dd/yyyy'T'HH:mm:ss.SSS",
  "MM/dd/yyyy'T'HH:mm:ss.SSS'Z'",
  "MM/dd/yyyy'T'HH:mm:ss.SSSZ",
  "MM/dd/yyyy'T'HH:mm:ssZ",
  'dd/MM/yyyy',
  'dd/MM/yyyy HH:mm',
  'dd/MM/yyyy HH:mm:ss',
  "dd/MM/yyyy'T'HH:mm:ss",
  "dd/MM/yyyy'T'HH:mm:ss.SSS",
  "dd/MM/yyyy'T'HH:mm:ss.SSS'Z'",
  "dd/MM/yyyy'T'HH:mm:ss.SSSZ",
  "dd/MM/yyyy'T'HH:mm:ssZ",
  'MM/dd/yy',
  'MM/dd/yy HH:mm',
  'MM/dd/yy HH:mm:ss',
  "MM/dd/yy'T'HH:mm:ss",
  "MM/dd/yy'T'HH:mm:ss.SSS",
  "MM/dd/yy'T'HH:mm:ss.SSS'Z'",
  "MM/dd/yy'T'HH:mm:ss.SSSZ",
  "MM/dd/yy'T'HH:mm:ssZ",
  'dd/MM/yy',
  'dd/MM/yy HH:mm',
  'dd/MM/yy HH:mm:ss',
  "dd/MM/yy'T'HH:mm:ss",
  "dd/MM/yy'T'HH:mm:ss.SSS",
  "dd/MM/yy'T'HH:mm:ss.SSS'Z'",
  "dd/MM/yy'T'HH:mm:ss.SSSZ",
  "dd/MM/yy'T'HH:mm:ssZ",
  'MM-dd-yyyy',
  'MM-dd-yyyy HH:mm',
  'MM-dd-yyyy HH:mm:ss',
  "MM-dd-yyyy'T'HH:mm:ss",
  "MM-dd-yyyy'T'HH:mm:ss.SSS",
  "MM-dd-yyyy'T'HH:mm:ss.SSS'Z'",
  "MM-dd-yyyy'T'HH:mm:ss.SSSZ",
  "MM-dd-yyyy'T'HH:mm:ssZ",
  'dd-MM-yyyy',
  'dd-MM-yyyy HH:mm',
  'dd-MM-yyyy HH:mm:ss',
  "dd-MM-yyyy'T'HH:mm:ss",
  "dd-MM-yyyy'T'HH:mm:ss.SSS",
  "dd-MM-yyyy'T'HH:mm:ss.SSS'Z'",
  "dd-MM-yyyy'T'HH:mm:ss.SSSZ",
  "dd-MM-yyyy'T'HH:mm:ssZ",
  'MM-dd-yy',
  'MM-dd-yy HH:mm',
  'MM-dd-yy HH:mm:ss',
  "MM-dd-yy'T'HH:mm:ss",
  "MM-dd-yy'T'HH:mm:ss.SSS",
  "MM-dd-yy'T'HH:mm:ss.SSS'Z'",
  "MM-dd-yy'T'HH:mm:ss.SSSZ",
  "MM-dd-yy'T'HH:mm:ssZ",
  'dd-MM-yy',
  'dd-MM-yy HH:mm',
  'dd-MM-yy HH:mm:ss',
  "dd-MM-yy'T'HH:mm:ss",
  "dd-MM-yy'T'HH:mm:ss.SSS",
  "dd-MM-yy'T'HH:mm:ss.SSS'Z'",
  "dd-MM-yy'T'HH:mm:ss.SSSZ",
  "dd-MM-yy'T'HH:mm:ssZ",
  'MMddyyyy',
  'MMddyyyy HH:mm',
  'MMddyyyy HH:mm:ss',
  "MMddyyyy'T'HH:mm:ss",
  "MMddyyyy'T'HH:mm:ss.SSS",
  "MMddyyyy'T'HH:mm:ss.SSS'Z'",
  "MMddyyyy'T'HH:mm:ss.SSSZ",
  "MMddyyyy'T'HH:mm:ssZ",
  'ddMMyyyy',
  'ddMMyyyy HH:mm',
  'ddMMyyyy HH:mm:ss',
  "ddMMyyyy'T'HH:mm:ss",
  "ddMMyyyy'T'HH:mm:ss.SSS",
  "ddMMyyyy'T'HH:mm:ss.SSS'Z'",
  "ddMMyyyy'T'HH:mm:ss.SSSZ",
  "ddMMyyyy'T'HH:mm:ssZ",
  'MMddyy',
  'MMddyy HH:mm',
  'MMddyy HH:mm:ss',
  "MMddyy'T'HH:mm:ss",
  "MMddyy'T'HH:mm:ss.SSS",
  "MMddyy'T'HH:mm:ss.SSS'Z'",
  "MMddyy'T'HH:mm:ss.SSSZ",
  "MMddyy'T'HH:mm:ssZ",
  'ddMMyy',
  'ddMMyy HH:mm',
  'ddMMyy HH:mm:ss',
  "ddMMyy'T'HH:mm:ss",
  "ddMMyy'T'HH:mm:ss.SSS",
  "ddMMyy'T'HH:mm:ss.SSS'Z'",
  "ddMMyy'T'HH:mm:ss.SSSZ",
  "ddMMyy'T'HH:mm:ssZ",
  'yyyy/MM/dd',
  'yyyy/MM/dd HH:mm',
  'yyyy/MM/dd HH:mm:ss',
  "yyyy/MM/dd'T'HH:mm:ss",
  "yyyy/MM/dd'T'HH:mm:ss.SSS",
  "yyyy/MM/dd'T'HH:mm:ss.SSS'Z'",
  "yyyy/MM/dd'T'HH:mm:ss.SSSZ",
  "yyyy/MM/dd'T'HH:mm:ssZ",
  'yyyy/dd/MM',
  'yyyy/dd/MM HH:mm',
  'yyyy/dd/MM HH:mm:ss',
  "yyyy/dd/MM'T'HH:mm:ss",
  "yyyy/dd/MM'T'HH:mm:ss.SSS",
  "yyyy/dd/MM'T'HH:mm:ss.SSS'Z'",
  "yyyy/dd/MM'T'HH:mm:ss.SSSZ",
  "yyyy/dd/MM'T'HH:mm:ssZ",
  'yy/MM/dd',
  'yy/MM/dd HH:mm',
  'yy/MM/dd HH:mm:ss',
  "yy/MM/dd'T'HH:mm:ss",
  "yy/MM/dd'T'HH:mm:ss.SSS",
  "yy/MM/dd'T'HH:mm:ss.SSS'Z'",
  "yy/MM/dd'T'HH:mm:ss.SSSZ",
  "yy/MM/dd'T'HH:mm:ssZ",
  'yy/dd/MM',
  'yy/dd/MM HH:mm',
  'yy/dd/MM HH:mm:ss',
  "yy/dd/MM'T'HH:mm:ss",
  "yy/dd/MM'T'HH:mm:ss.SSS",
  "yy/dd/MM'T'HH:mm:ss.SSS'Z'",
  "yy/dd/MM'T'HH:mm:ss.SSSZ",
  "yy/dd/MM'T'HH:mm:ssZ",
  'yyyy-MM-dd',
  'yyyy-MM-dd HH:mm',
  'yyyy-MM-dd HH:mm:ss',
  "yyyy-MM-dd'T'HH:mm:ss",
  "yyyy-MM-dd'T'HH:mm:ss.SSS",
  "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
  "yyyy-MM-dd'T'HH:mm:ss.SSSZ",
  "yyyy-MM-dd'T'HH:mm:ssZ",
  'yyyy-dd-MM',
  'yyyy-dd-MM HH:mm',
  'yyyy-dd-MM HH:mm:ss',
  "yyyy-dd-MM'T'HH:mm:ss",
  "yyyy-dd-MM'T'HH:mm:ss.SSS",
  "yyyy-dd-MM'T'HH:mm:ss.SSS'Z'",
  "yyyy-dd-MM'T'HH:mm:ss.SSSZ",
  "yyyy-dd-MM'T'HH:mm:ssZ",
  'yy-MM-dd',
  'yy-MM-dd HH:mm',
  'yy-MM-dd HH:mm:ss',
  "yy-MM-dd'T'HH:mm:ss",
  "yy-MM-dd'T'HH:mm:ss.SSS",
  "yy-MM-dd'T'HH:mm:ss.SSS'Z'",
  "yy-MM-dd'T'HH:mm:ss.SSSZ",
  "yy-MM-dd'T'HH:mm:ssZ",
  'yy-dd-MM',
  'yy-dd-MM HH:mm',
  'yy-dd-MM HH:mm:ss',
  "yy-dd-MM'T'HH:mm:ss",
  "yy-dd-MM'T'HH:mm:ss.SSS",
  "yy-dd-MM'T'HH:mm:ss.SSS'Z'",
  "yy-dd-MM'T'HH:mm:ss.SSSZ",
  "yy-dd-MM'T'HH:mm:ssZ",
  'yyyyMMdd',
  'yyyyMMdd HH:mm',
  'yyyyMMdd HH:mm:ss',
  "yyyyMMdd'T'HH:mm:ss",
  "yyyyMMdd'T'HH:mm:ss.SSS",
  "yyyyMMdd'T'HH:mm:ss.SSS'Z'",
  "yyyyMMdd'T'HH:mm:ss.SSSZ",
  "yyyyMMdd'T'HH:mm:ssZ",
  'yyyyddMM',
  'yyyyddMM HH:mm',
  'yyyyddMM HH:mm:ss',
  "yyyyddMM'T'HH:mm:ss",
  "yyyyddMM'T'HH:mm:ss.SSS",
  "yyyyddMM'T'HH:mm:ss.SSS'Z'",
  "yyyyddMM'T'HH:mm:ss.SSSZ",
  "yyyyddMM'T'HH:mm:ssZ",
  'yyMMdd',
  'yyMMdd HH:mm',
  'yyMMdd HH:mm:ss',
  "yyMMdd'T'HH:mm:ss",
  "yyMMdd'T'HH:mm:ss.SSS",
  "yyMMdd'T'HH:mm:ss.SSS'Z'",
  "yyMMdd'T'HH:mm:ss.SSSZ",
  "yyMMdd'T'HH:mm:ssZ",
  'yyddMM',
  'yyddMM HH:mm',
  'yyddMM HH:mm:ss',
  "yyddMM'T'HH:mm:ss",
  "yyddMM'T'HH:mm:ss.SSS",
  "yyddMM'T'HH:mm:ss.SSS'Z'",
  "yyddMM'T'HH:mm:ss.SSSZ",
  "yyddMM'T'HH:mm:ssZ",
  'yyyyMMdd HHmm',
  'yyyyMMdd HHmmss',
  'yyyyMMddHHmm',
  'yyyyMMddHHmmss',
  'dd MMM yyyy HH:mm',
  'dd MMM yyyy HH:mm:ss',
  'dd MMM yyyy',
  'dd MMMM yyyy HH:mm',
  'dd MMMM yyyy HH:mm:ss',
  'dd MMMM yyyy',
  'EEE MMM dd HH:mm:ss zzz yyyy',
  'epoch_seconds',
  'epoch_millis',
  'epoch_micros',
];

export const getEditorValidationMessage = (stream, isAll = false) => {
  const isPristine = !get(stream, 'uiState.analysisResult');
  const res = [];
  const timePattern = get(stream.timeDefinition, 'timePattern');
  const isKinesisUseExternalTime = ['kinesis'].includes(stream.type) && stream.useExternalTime === true;

  if (!isPristine && (!stream.dimensions.length && !stream.metrics.length)) {
    res.push('At least one dimension or metric is required');
  }
  if (['kinesis'].includes(stream.type)) {
    if (!isPristine && !get(stream.timeDefinition, 'path') && !stream.useExternalTime) {
      res.push('Please run Collect, since time definition is missing.');
    }
  }
  if (['kinesis', 'eventhubs'].includes(stream.type)) {
    if (!isPristine && !get(stream.timeDefinition, 'path') && !isKinesisUseExternalTime) {
      res.push('Timestamp definition is required');
    }
  } else if (!isPristine && !Number.isInteger(get(stream.timeDefinition, 'timeColumnIdx'))) {
    res.push('Timestamp definition is required');
  }

  if (!isPristine && !timePattern && !isKinesisUseExternalTime) {
    res.push('Time pattern is required');
  }
  if (
    !isPristine &&
    timePattern &&
    !get(stream.timeDefinition, 'timeZone') &&
    !(
      timePattern.toLowerCase().indexOf('z') >= 0 ||
      ['epoch_seconds', 'epoch_millis', 'epoch_micros'].includes(timePattern)
    ) &&
    !isKinesisUseExternalTime
  ) {
    res.push('Timezone is required');
  }
  if (get(stream.uiState, 'analysisResult.fileErrors.length') && !isAll) {
    // only for the NEXT btn tt
    res.push('File parsing failed');
  }

  if (isAll) {
    return res;
  }
  return res[0];
};
