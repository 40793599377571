import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {isEmpty} from 'lodash';
import {palette} from 'app/styles/theme';

import useWatch from 'dataCollectors/hooks/useWatch';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';

const useStyles = makeStyles(() => ({
  metricsWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${palette.gray[200]}`,
    borderRadius: 6,
    width: 280,
    height: 36,
    padding: '0 8px',
  },
  progressBarWrapper: {
    width: 140,
    margin: '0 auto',
    height: 13,
    borderRadius: 6,
    padding: '0 4px',
    backgroundColor: palette.blue[300],
    display: 'flex',
    alignItems: 'center',
  },
  progressBarFill: {
    display: 'block',
    maxWidth: '100%',
    height: 5,
    borderRadius: 4,
    transition: `width 750ms ease-out, background-color 750ms ease-out`,
  },
}));

export default function CompletionProgressBar() {
  const classes = useStyles();
  const watch = useWatch();

  const totalValue = useMemo(() => {
    return Object.keys(EVENT_STREAM_FIELDS)
      .map((key) => EVENT_STREAM_FIELDS[key].percentage || 0)
      .reduce((prevValue, currentValue) => prevValue + currentValue, 0);
  }, [watch]);

  const totalTouchedValue = useMemo(() => {
    return Object.keys(EVENT_STREAM_FIELDS)
      .filter(
        (key) =>
          (watch[EVENT_STREAM_FIELDS[key].id] && watch[EVENT_STREAM_FIELDS[key].id].length) ||
          (watch[EVENT_STREAM_FIELDS[key].id] && !isEmpty(watch[EVENT_STREAM_FIELDS[key].id])),
      )
      .map((i) => EVENT_STREAM_FIELDS[i].percentage || 0)
      .reduce((prevValue, currentValue) => prevValue + currentValue, 0);
  }, [watch]);

  const progressBarPercentage = useMemo(() => {
    return Math.round((totalTouchedValue / totalValue) * 100);
  }, [watch]);

  const setDynamicColor = useMemo(() => {
    if (progressBarPercentage === 100) {
      return palette.eucaliptus[500];
    }
    return palette.yellow[500];
  }, [progressBarPercentage]);

  const setDynamicColorBg = useMemo(() => {
    if (progressBarPercentage === 100) {
      return palette.mint[100];
    }
    return palette.pale[500];
  }, [progressBarPercentage]);

  return (
    <div>
      <div className={classes.metricsWrap}>
        <span>Completion {progressBarPercentage}%</span>
        <span>
          <div className={classes.progressBarWrapper} style={{backgroundColor: setDynamicColorBg}}>
            <span
              className={classes.progressBarFill}
              style={{width: `${progressBarPercentage}%`, backgroundColor: setDynamicColor}}
            />
          </div>
        </span>
      </div>
    </div>
  );
}
