import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {POLLING_INTERVAL_OPTIONS} from 'dataCollectors/services/scheduleService';

const pollingIntervalsKeys = Object.keys(POLLING_INTERVAL_OPTIONS);
export default function PollingInterval({onChange, selectedValue, optionsFrom, isLoading}) {
  const [options, setOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const setRangeOptions = (index) => {
    const slicedPollingIntervalsKeys = pollingIntervalsKeys.slice(index);
    const filteredOptions = [];

    slicedPollingIntervalsKeys.forEach((i) => {
      filteredOptions.push(POLLING_INTERVAL_OPTIONS[i]);
    });

    setOptions(filteredOptions);
  };

  useEffect(() => {
    const index = pollingIntervalsKeys.indexOf(optionsFrom);

    if (index > -1) {
      setRangeOptions(index);
    } else {
      setRangeOptions(0);
    }
  }, []);

  useEffect(() => {
    const selectedIndex = options.findIndex((val) => val.value === selectedValue);
    setSelectedItem(options[selectedIndex]);
  }, [options, selectedValue]);

  return (
    <>
      <FormDdlSelect
        placement="top"
        isDisabledSorting
        options={options}
        selected={selectedItem}
        onChange={(item) => onChange(item.value)}
        button={
          <BigDropdownButton
            isPristine={!selectedItem}
            label={selectedItem?.label}
            placeholder={isLoading ? 'Loading..' : 'Select'}
            shortBlueStyle
            isFullWidth
          />
        }
        optionComponent={<OptionComponentSimple />}
        width={240}
        height={260}
      />
    </>
  );
}

PollingInterval.defaultProps = {optionsFrom: ''};

PollingInterval.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  optionsFrom: PropTypes.string,
};
