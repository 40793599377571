/* eslint-disable import/prefer-default-export */
import React from 'react';
import {ReactComponent as Overview} from 'investigation/images/color24-overview.svg';
import {ReactComponent as Incident} from 'investigation/images/color24-incident.svg';
import {ReactComponent as Correlations} from 'investigation/images/color24-correlations.svg';
import {ReactComponent as Timeline} from 'investigation/images/color24-timeline.svg';
import {palette} from 'app/styles/theme';
import {omit} from 'lodash';

export const modalRouting = {
  INVESTIGATION_MODAL: 'investigationModal',
  ANOMALY_ID: 'anomalyIdInv',
  TRIGGER_ID: 'triggerIdInv',
};

export const ALERT_TYPES = {
  ANOMALY: 'anomaly',
  STATIC: 'static',
  NO_DATA: 'noData',
};

export const ANOMALY_COLUMNS = [
  {
    id: 0,
    name: 'title',
    title: '',
    isSortable: false,
  },
  // Until BE will add this field to metrics in anomalies/metrics API
  // {
  //   id: 1,
  //   name: 'impact',
  //   title: 'Impact',
  //   isSortable: true,
  // },
  {
    id: 2,
    name: 'duration',
    title: 'Duration',
    isSortable: true,
  },
  {
    id: 3,
    name: 'upperPercentageDelta',
    title: 'Delta Up',
    isSortable: true,
  },
  {
    id: 4,
    name: 'lowerPercentageDelta',
    title: 'Delta Down',
    isSortable: true,
  },
  {
    id: 5,
    name: 'score',
    title: 'Score',
    isSortable: true,
  },
];

export const STATIC_NO_DATA_COLUMNS = [
  {
    id: 0,
    name: 'title',
    title: '',
    isSortable: false,
  },
  // Until BE will add this field to metrics in anomalies/metrics API
  // {
  //   id: 1,
  //   name: 'impact',
  //   title: 'Impact',
  //   isSortable: true,
  // },
  {
    id: 2,
    name: 'startTime',
    title: 'Started',
    isSortable: true,
  },
  {
    id: 3,
    name: 'duration',
    title: 'Duration',
    isSortable: true,
  },
];

export const calculateAlertColumns = (measures) => {
  let columns = ANOMALY_COLUMNS;
  if (measures) {
    let metricWithLowerPercentageDelta;
    let metricWithUpperPercentageDelta;
    Object.keys(measures).forEach((what) => {
      if (measures[what].isLoading === false) {
        if (
          !metricWithLowerPercentageDelta &&
          measures[what]?.data.metrics.find((metric) => metric.lowerPercentageDelta)
        ) {
          metricWithLowerPercentageDelta = measures[what].data.metrics.find((metric) => metric.lowerPercentageDelta);
        }
        if (
          !metricWithUpperPercentageDelta &&
          measures[what].data.metrics.find((metric) => metric.upperPercentageDelta)
        ) {
          metricWithUpperPercentageDelta = measures[what].data.metrics.find((metric) => metric.upperPercentageDelta);
        }
      }
    });
    if (!metricWithLowerPercentageDelta) {
      columns = ANOMALY_COLUMNS.filter((column) => column.name !== 'lowerPercentageDelta');
    }
    if (!metricWithUpperPercentageDelta) {
      columns = ANOMALY_COLUMNS.filter((column) => column.name !== 'upperPercentageDelta');
    }
  }
  return columns;
};

export const INVESTIGATION_MODAL_TABS = {
  overview: {
    label: 'Overview',
    id: 'overview',
    isEnabled: true,
    icon: <Overview />,
  },
  incident: {
    label: 'Incident',
    id: 'incident',
    isEnabled: true,
    icon: <Incident />,
  },
  correlations: {
    label: 'Correlations',
    id: 'correlations',
    isEnabled: true,
    icon: <Correlations />,
  },
  timeline: {
    label: 'Timeline',
    id: 'timeline',
    isEnabled: true,
    icon: <Timeline />,
  },
};

export const getInvestigationModalTabs = (type) => {
  switch (type) {
    case 'anomaly':
      return INVESTIGATION_MODAL_TABS;
    case 'static':
    case 'noData':
      return omit(INVESTIGATION_MODAL_TABS, ['correlations']);
    default:
      return {};
  }
};

export const BUFFER_TIMES = {
  alerts: {
    '1m': 120,
    '5m': 600,
    '1h': 3600,
    '1d': 86400,
    '1w': 0,
  },
  anomalies: {
    short: 60 * 60 * 13,
    medium: 60 * 60 * 13,
    long: 60 * 60 * 24 * 15,
    longlong: 60 * 60 * 24 * 15,
    weekly: 60 * 60 * 24 * 15,
  },
};

export const ANOMALIES_DEFAULT_QUERY_PARAMS = {
  alertId: '',
  anomalyType: 'all',
  baseline: false,
  correlation: '',
  datapoints: false,
  delta: 1,
  deltaType: 'percentage',
  durationUnit: 'minutes',
  durationValue: 1,
  index: 0,
  order: 'desc',
  resolution: 'medium',
  score: 0,
  size: 10,
  sort: 'score',
  state: 'both',
  valueDirection: 'both',
};

export const anomalyIntervalsToAlertTriggerFormat = (anomalyIntervals) => {
  if (!anomalyIntervals || anomalyIntervals.length === 0) {
    return null;
  }

  return (anomalyIntervals || []).map((a) => ({
    startTime: a.startDate,
    endTime: a.endDate,
    duration: a.endDate - a.startDate,
    status: a.state.toLowerCase() === 'open' ? 'OPEN' : 'CLOSE',
    score: a.score,
    direction: a.directionUp ? 'UP' : 'DOWN',
    peak: a.peakValue,
    deltaAbsolute: a.absoluteDelta,
    deltaPercentage: a.percentageDelta,
    sumDeltas: a.anomalySumDeltas,
  }));
};

const ROW_DEFAULT_HEIGHT = {
  sm: 48,
  mid: 80,
};

export const TIMELINE_ENTRIES = {
  ALERT_OPENED: {
    value: 'ALERT_OPENED',
    title: 'Alert Opened',
    icon: 'icn-nav24-alertconsole1',
    color: palette.red['500'],
    bgColor: palette.red['10'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  ANOTHER_ALERT_ADDED: {
    value: 'ANOTHER_ALERT_ADDED',
    title: 'Another alert added',
    icon: 'icn-nav24-alertconsole1',
    color: palette.orange['500'],
    bgColor: palette.orange['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.mid,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  ALERT_CLOSED: {
    value: 'ALERT_CLOSED',
    title: 'Alert Closed',
    icon: 'icn-nav24-alertconsole1',
    color: palette.mint['600'],
    bgColor: palette.mint['100'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  ANOMALY_STARTED: {
    value: 'ANOMALY_STARTED',
    title: 'Anomaly started',
    icon: 'icn-general16-anomaly',
    color: palette.red['500'],
    bgColor: palette.red['10'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
  },
  ANOMALY_UPDATED: {
    value: 'ANOMALY_UPDATED',
    updateType: {
      METRIC_ADDED: {
        value: 'METRIC_ADDED',
      },
      METRIC_CLOSED: {
        value: 'METRIC_CLOSED',
      },
      DIRECTION_CHANGED: {
        value: 'DIRECTION_CHANGED',
      },
    },
    title: 'Anomaly updated',
    icon: 'icn-general16-anomaly',
    color: palette.orange['500'],
    bgColor: palette.orange['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.mid,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  COMMENT: {
    value: 'COMMENT',
    title: 'Comment',
    icon: 'icn-general16-chat',
    color: palette.gray['500'],
    bgColor: palette.gray['200'],
    rowDefaultSize: 'mid ',
    rowDefaultSizeIncremented: 'mid ',
  },
  FEEDBACK: {
    value: 'FEEDBACK',
    title: 'Feedback',
    GOOD_CATCH: {title: 'good catch', icon: 'icn-general24-like'},
    NOT_INTERESTING: {title: 'not interesting', icon: 'icn-general24-dislike'},
    color: palette.gray['500'],
    bgColor: palette.gray['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  ACKNOWLEDGE: {
    value: 'ACKNOWLEDGE',
    title: 'Acknowledge',
    ADD_STAR: {title: 'Acknowledged'},
    REMOVE_STAR: {title: 'Acknowledged removed'},
    icon: 'icn-action16-acknowledge',
    color: palette.gray['500'],
    bgColor: palette.gray['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  USER_EVENT: {
    value: 'USER_EVENT',
    title: 'User event',
    icon: 'icn-general16-marker',
    color: palette.gray['500'],
    bgColor: palette.gray['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.mid,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  ASSIGNEE_CHANGED: {
    value: 'ASSIGNEE_CHANGED',
    title: 'Assignee changed',
    icon: 'icn-assignee16',
    color: palette.gray['500'],
    bgColor: palette.gray['200'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  STATIC_UPDATED: {
    value: 'STATIC_UPDATED',
    title: 'Static Updated',
    icon: 'icn-nav24-alertconsole1',
    color: palette.mint['600'],
    bgColor: palette.mint['100'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  STATIC_STARTED: {
    value: 'STATIC_STARTED',
    title: 'Static Started',
    icon: 'icn-general16-anomaly',
    color: palette.red['500'],
    bgColor: palette.red['10'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  NO_DATA_STARTED: {
    value: 'NO_DATA_STARTED',
    title: 'Data is missing',
    icon: 'icn-general16-anomaly',
    color: palette.red['500'],
    bgColor: palette.red['10'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
  NO_DATA_UPDATED: {
    value: 'NO_DATA_UPDATED',
    title: 'Data is missing',
    icon: 'icn-nav24-alertconsole1',
    color: palette.mint['600'],
    bgColor: palette.mint['100'],
    rowDefaultSize: ROW_DEFAULT_HEIGHT.sm,
    rowDefaultSizeIncremented: ROW_DEFAULT_HEIGHT.sm,
  },
};

export const ALERT_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};

export const mappingToRollup = {
  '1m': 'SHORTROLLUP',
  '5m': 'MEDIUMROLLUP',
  '1h': 'LONGROLLUP',
  '1d': 'LONGLONGROLLUP',
  '1w': 'WEEKLY',
};

export const topElementHeight = {
  minimizingModalMarginTop: 60,
  headerHeight: 100,
  tabBarHeight: 76,
  infoPanelsHeight: 180,
  bodyContainerTopOffset: 16,
};
