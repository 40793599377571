// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {getFormattedDateMini, getFormattedHumanDuration} from 'common/utils/dateService';
import StaticListItem from 'common/componentsV2/table/StaticListItem';
import {get} from 'lodash';
import './AlertTriggersTable.module.scss';

type PropTypes = {
  trigger: Object,
  timeZoneName: String,
};

class AlertTriggersTableRow extends React.PureComponent {
  props: PropTypes;

  handleClick = () => {
    const {alerts} = this.props.trigger;
    let triggerId = null;
    if (alerts.length > 0) {
      triggerId = alerts[0].id;
    }
    if (triggerId) {
      window.open(
        // eslint-disable-next-line max-len
        `/#!/r/alerts-console?ref=email&triggerIds=${triggerId}&constRange=m1`,
        '_blank',
      );
    }
  };

  render() {
    const {trigger, timeZoneName} = this.props;
    const score = get(trigger, 'alerts[0].metrics[0].intervals[0].score', null);
    return (
      <StaticListItem handleClick={this.handleClick}>
        <div styleName="title">{trigger.name}</div>
        <div styleName="startTime">{getFormattedDateMini(trigger.startTime, timeZoneName)}</div>
        <div styleName="updateTime">{getFormattedDateMini(trigger.updateTime, timeZoneName)}</div>
        <div styleName="duration">{getFormattedHumanDuration(trigger.updateTime - trigger.startTime)}</div>
        <div styleName="score">{score !== null ? Math.round(score * 100) : ''}</div>
        <div styleName="link" />
      </StaticListItem>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertTriggersTableRow);
