import React, {useState, useEffect, useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import {isEmpty} from 'lodash';

import {useEventStreamContext} from 'dataCollectors/hooks/useEventStreamContext';
import {STEPS_ACTIONS} from 'dataCollectors/pages/eventStream/hooks/editPageReducer';
import {EVENT_STREAM_FIELDS, EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';
import usePostStreamEventsPreview from 'dataCollectors/api/usePostStreamEventsPreview';
import Table1 from 'dataCollectors/components/tables/Table1';
import ContentLoaderPreviewBig from 'dataCollectors/components/loaders/ContentLoaderPreviewBig';
import useSectionsClasses from 'dataCollectors/styles/classes.useSectionsClasses';
import useHandleResize from 'common/hooks/useHandleResize';

const {LOCAL_FILE} = EVENT_STREAM_TYPES;
const {sections} = useSectionsClasses();

const {
  EVENT_TITLE,
  EVENT_DESCRIPTION,
  EVENT_TYPE,
  EVENT_ACTION,
  EVENT_START_TIME,
  EVENT_END_TIME,
  EVENT_PROGRESS_INDICATOR,
  EVENT_PROPERTIES,
  EVENT_KEY,
  EVENT_CATEGORY,
  START_TIME_DEFINITION,
  END_TIME_DEFINITION,
} = EVENT_STREAM_FIELDS;

const EVENTS_FIELDS_ARR = [
  EVENT_TITLE.id,
  EVENT_DESCRIPTION.id,
  EVENT_TYPE.id,
  EVENT_ACTION.id,
  EVENT_START_TIME.id,
  EVENT_END_TIME.id,
  EVENT_PROGRESS_INDICATOR.id,
  EVENT_PROPERTIES.id,
  EVENT_KEY.id,
  EVENT_CATEGORY.id,
  START_TIME_DEFINITION.id,
  END_TIME_DEFINITION.id,
];

const updateColumnsTitles = (columns, eventProperties) => {
  if (!columns || !columns.length) {
    return columns;
  }
  if (!eventProperties || isEmpty(eventProperties)) {
    return columns;
  }

  return columns.map((column) => {
    let newColumn = {...column};

    Object.entries(eventProperties).forEach(([key, value]) => {
      if (value.sourceColumn === column.id) {
        newColumn = {...column, title: key};
      }
    });

    return newColumn;
  });
};

const updateDataAccessors = (data, eventProperties) => {
  if (!data || !data.length) {
    return data;
  }

  if (!eventProperties || isEmpty(eventProperties)) {
    return data;
  }

  return data.map((row) => {
    const newColumn = {...row};

    Object.entries(eventProperties).forEach(([key, value]) => {
      if (row[value.id]) {
        delete newColumn[value.id];
        newColumn[key] = row[value.id];
      }
    });

    return newColumn;
  });
};

const TransformPage = () => {
  const bodyHeight = useHandleResize([315]);
  const sectionsClasses = sections();

  const {
    getValues,
    setValue,
    formState: {touchedFields},
  } = useFormContext();

  const stream = getValues();

  const {editStreamPageReducerState, editStreamPageReducerDispatch, currentStepIndex} = useEventStreamContext();

  const isTouchedEventFields = EVENTS_FIELDS_ARR.some((key) => touchedFields[key]);

  const {
    tablePreviewTransformData,
    tablePreviewTransformRefetch,
    tablePreviewTransformIsFetching,
    tablePreviewTransformIsSuccess,
    tablePreviewTransformIsError,
    tablePreviewTransformError,
  } = usePostStreamEventsPreview(stream);

  const [transformColumns, setTransformColumns] = useState([]);
  const [data, setData] = useState();

  const setEventProperties = useCallback(
    (id, newTitle) => {
      const newEventProperties = {};

      Object.entries(stream.eventProperties).forEach(([key, value]) => {
        if (value.sourceColumn === id) {
          newEventProperties[newTitle] = value;
        } else {
          newEventProperties[key] = value;
        }
      });

      setValue(EVENT_PROPERTIES.id, newEventProperties);
    },
    [stream.eventProperties],
  );

  const resetData = useCallback(() => {
    setTransformColumns(tablePreviewTransformData.columns);
  }, []);

  const addIdsToEventProperties = useCallback(
    (propColumns) => {
      const newEventProperties = {...stream.eventProperties};

      if (propColumns?.length && stream?.eventProperties) {
        Object.entries(stream.eventProperties).map(([key, value]) => {
          const found = propColumns.find((column) => column.title === key);
          if (found) {
            newEventProperties[key] = {...value, id: found.title};
          }
        });

        setValue(EVENT_PROPERTIES.id, newEventProperties);
      }
    },
    [stream.eventProperties],
  );

  useEffect(() => {
    // set all the data just after the first render or new data is received.
    if (!tablePreviewTransformIsFetching) {
      setValue(EVENT_STREAM_FIELDS.TRANSFORM_TABLE.id, tablePreviewTransformIsSuccess);
      addIdsToEventProperties(tablePreviewTransformData.columns);
      setTransformColumns(tablePreviewTransformData.columns);
      setData(tablePreviewTransformData.data);
    }
  }, [tablePreviewTransformIsFetching]);

  useEffect(() => {
    // reset the data for the first time or user changed data mapper.
    if (
      editStreamPageReducerState[currentStepIndex].isFirstVisit ||
      isTouchedEventFields ||
      tablePreviewTransformIsError
    ) {
      editStreamPageReducerDispatch({type: STEPS_ACTIONS.SET_FIRST_TIME, payload: {index: currentStepIndex}});
      setTransformColumns([]);
      setData([]);
      tablePreviewTransformRefetch({cancelRefetch: true});
    }
  }, []);

  useEffect(() => {
    if (stream.eventProperties && !isEmpty(stream.eventProperties)) {
      // update titles to be the key of the event properties.
      setTransformColumns(updateColumnsTitles(tablePreviewTransformData.columns, stream.eventProperties));
      // update the rows according to the new titles.
      setData(updateDataAccessors(tablePreviewTransformData.data, stream.eventProperties));
    } else {
      setTransformColumns(tablePreviewTransformData.columns);
      setData(tablePreviewTransformData.data);
    }
  }, [stream.eventProperties, tablePreviewTransformIsFetching]);

  return (
    <>
      <div className={sectionsClasses.title}>Data Table</div>
      <div className={sectionsClasses.subTitle}>
        Below is a preview of the data you chose to collect. To make any changes to the data, go back to the previous
        step.
      </div>
      <br />
      {tablePreviewTransformIsFetching && <ContentLoaderPreviewBig />}

      {!tablePreviewTransformIsFetching && tablePreviewTransformIsError && (
        <>
          <br />
          <br />
          <div className={`${sectionsClasses.title}`} style={{color: '#DE5773'}}>
            Error to load preview table
          </div>
          <div className={`${sectionsClasses.subTitle}`} style={{color: '#D10F37'}}>
            {tablePreviewTransformError?.uMessage}
          </div>
        </>
      )}

      {!tablePreviewTransformIsFetching && tablePreviewTransformIsSuccess && (
        <Table1
          columns={transformColumns}
          data={data}
          updateData={setEventProperties}
          resetData={resetData}
          supportAccessorFormat={stream.type === LOCAL_FILE}
          isSortable
          isGlobalSearch
          height={bodyHeight}
        />
      )}
    </>
  );
};

export default TransformPage;
