// @flow
import React, {useEffect, useRef} from 'react';
import {palette} from 'app/styles/theme';
import SelectAndt, {THEME_TRANSPARENT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {components} from 'react-select2';
import {REFRESH_ALERTS} from 'alerts.console.new/services/alertsConsoleService';
import DEFAULT_INTERVALS_PERIODS from './intervalsService';

import './IntervalsDropDown.module.scss';

const IntervalsDropDown = ({
  onAction,
  listOfIntervals,
  initialInterval,
  onIntervalChange,
}: {
  onAction: Function,
  listOfIntervals: Object,
  initialInterval: String,
  onIntervalChange: Function,
}) => {
  const listOfIntervalsCalc = listOfIntervals
    ? DEFAULT_INTERVALS_PERIODS.filter((d) => listOfIntervals.some((i) => d.id === i))
    : DEFAULT_INTERVALS_PERIODS;

  let initialIntervalObj = listOfIntervalsCalc[1];
  if (initialInterval) {
    initialIntervalObj = DEFAULT_INTERVALS_PERIODS.find((d) => d.id === initialInterval);
  }

  const currentIntervalRef = useRef(initialIntervalObj);

  let intervalId;

  const runMethodByInterval = (interval) => {
    clearInterval(intervalId);

    if (!interval) {
      return;
    }
    intervalId = setInterval(() => {
      onAction();
    }, interval);
  };

  const handleSelection = (selectedItem) => {
    if (selectedItem.value === REFRESH_ALERTS.NOW) {
      onAction(REFRESH_ALERTS.NOW);
    } else {
      if (onIntervalChange) {
        onIntervalChange(selectedItem);
      }
      currentIntervalRef.current = selectedItem;
      runMethodByInterval(selectedItem.value);
    }
  };

  useEffect(() => {
    if (initialInterval && initialInterval !== REFRESH_ALERTS.OFF && initialInterval !== REFRESH_ALERTS.NOW) {
      runMethodByInterval(initialIntervalObj.value);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const selectedIndex =
    currentIntervalRef.current &&
    listOfIntervalsCalc.findIndex((val) => currentIntervalRef.current?.value === val.value);

  return listOfIntervalsCalc ? (
    <div styleName="refresh-alerts-dd">
      <SelectAndt
        automationId="intervals"
        options={listOfIntervalsCalc}
        getOptionLabel={(val) => val.label}
        getOptionValue={(val) => val.value}
        type={TYPE_NO_SEARCH}
        theme={THEME_TRANSPARENT}
        value={listOfIntervalsCalc[selectedIndex]}
        onChange={handleSelection}
        placeholder=""
        customComponent={{
          SingleValue: (p) => (
            <components.SingleValue {...p}>
              <div styleName="wrapper">
                <i className="icon icn-general16-refresh" />
                <span styleName="title">
                  {currentIntervalRef.current.value !== undefined ? currentIntervalRef.current.title : ''}
                </span>
              </div>
            </components.SingleValue>
          ),
          Option: (p) => {
            if (p.data.value === 'now') {
              return (
                <div style={{borderBottom: `1px solid ${palette.gray[200]}`}}>
                  <components.Option {...p}>
                    <div>{p.data.label}</div>
                  </components.Option>
                </div>
              );
            }
            return <components.Option {...p} />;
          },
        }}
      />
    </div>
  ) : (
    <div />
  );
};

export default IntervalsDropDown;
