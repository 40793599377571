// @flow
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {fetchDataSourceTypes, fetchDataSources} from 'bc/store/actions';
import DataManagerListHeader from 'bc/components/dataManager/DataManagerListHeader';
import DataManagerListBody from 'bc/components/dataManager/DataManagerListBody';
import useFetchEventStreams from 'dataCollectors/api/useFetchEventStreams';
import {usePrefetchUsers} from 'dataCollectors/api/useFetchUsers';

import {usePrefetchSources} from 'dataCollectors/api/useFetchSources';

type PropTypes = {
  setQueryParams: Function,
  listState: string,
  onListStateChange: Function,
  isRbacEnabled: boolean,
};

export default function DataManagerList({setQueryParams, listState, onListStateChange, isRbacEnabled}: PropTypes) {
  const dispatch = useDispatch();

  const {eventStreams, isEventStreamsLoading} = useFetchEventStreams();
  usePrefetchUsers();
  usePrefetchSources();

  useEffect(() => {
    dispatch(fetchDataSourceTypes());
    dispatch(fetchDataSources());
  }, []);

  return (
    <>
      <DataManagerListHeader setQueryParams={setQueryParams} isRbacEnabled={isRbacEnabled} />
      <DataManagerListBody
        listState={listState}
        onListStateChange={onListStateChange}
        setQueryParams={setQueryParams}
        eventStreams={eventStreams}
        isLoadingEventStreams={isEventStreamsLoading}
      />
    </>
  );
}
