// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import ConfModal from 'common/componentsV2/modal/ConfModal';
import * as selector from 'admin.users/store/selectors';
import {toggleUserChangeStatusConfirmationModal, updateUserStatus} from 'admin.users/store/actions';
import './UsersModals.module.scss';

type PropTypes = {
  userStatusModal: Object,
  userName: string,
  toggleUserChangeStatusConfirmationModal: Function,
  updateUserStatus: Function,
};

export default connect(
  (state) => ({
    userStatusModal: selector.getUsersViewStatusModal(state),
    userName: selector.getUsersViewStatusModalUserName(state),
  }),
  {
    toggleUserChangeStatusConfirmationModal,
    updateUserStatus,
  },
)(
  class UserStatusModal extends React.PureComponent {
    props: PropTypes;

    onModalClose = () => {
      this.props.toggleUserChangeStatusConfirmationModal({isOpen: false});
    };

    onConfirm = () => {
      const {userId, action} = this.props.userStatusModal;
      this.props.updateUserStatus({userId, body: {disabled: action === 'disable'}});
      this.props.toggleUserChangeStatusConfirmationModal({isOpen: false});
    };

    render() {
      const {userName} = this.props;
      const {isOpen, action} = this.props.userStatusModal;

      const actionText = action === 'enable' ? 'Enable' : 'Disable';

      return (
        <ConfModal
          isOpen={isOpen}
          onClose={this.onModalClose}
          onConfirm={this.onConfirm}
          confirmText={actionText}
          title={`${actionText}: ${userName}?`}
        >
          <div styleName="status-body">
            {action === 'enable' ? (
              <span>Are you sure you want to enable the user?</span>
            ) : (
              <span>Are you sure you want to disable the user?</span>
            )}
          </div>
        </ConfModal>
      );
    }
  },
);
