// @flow
import React from 'react';
import Checkbox from 'common/componentsV2/Checkbox';
import TitleHeaderTableOrder from 'common/componentsV2/table/TitleHeaderTableOrder';
import connect from 'react-redux/es/connect/connect';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import * as profileSelectors from 'profile/store/selectors';
import {ICONS} from '@pileus-cloud/anodot-frontend-common';
import {setAlertListOrder} from '../../store/actions';
import * as selectors from '../../store/selectors';

import './ExpandableListItemHeader.module.scss'; // TODO CR add comment on this

type PropTypes = {
  onCheck: Function,
  setAlertListOrder: Function,
  offset: Number,

  order: Object,
  allAlertsCheckboxState: string,
  isRecommendationEnabled: Boolean,
};

export default connect(
  (state) => ({
    order: selectors.getAlertListOrder(state),
    allAlertsCheckboxState: selectors.getAllAlertsCheckboxState(state),
    isRecommendationEnabled: profileSelectors.getRecommendationsEnabled(state),
  }),
  {
    setAlertListOrder,
  },
)(
  class AlertsManagerTableHeader extends React.PureComponent {
    props: PropTypes;

    handleTitleOrderChange = (columnName) => {
      const {
        order: {column, direction},
      } = this.props;
      let updatedDirection = 'desc';

      if (columnName === column) {
        updatedDirection = direction === 'desc' ? 'asc' : 'desc';
      }
      this.props.setAlertListOrder({column: columnName, direction: updatedDirection});
    };

    render() {
      const {order, onCheck} = this.props;
      const fields = [
        {id: 0, name: 'title', title: 'Title'},
        {id: 1, name: 'warnings', title: '', faIcon: ICONS.triangleExclamation.name, tooltip: 'Notifications'},
        {id: 2, name: 'recommendations', title: '', faIcon: ICONS.recommendations.name, tooltip: 'Recommendations'},
        {id: 3, name: 'owner', title: 'Owner'},
        {id: 4, name: 'dateModified', title: 'Date Modified'},
        {id: 5, name: 'status', title: 'Status'},
        {
          id: 6,
          name: 'estimation',
          title: 'Triggered',
          tooltip: 'Triggered in the last 7 days',
        },
      ];

      return (
        <div styleName="container list-header" style={{marginRight: this.props.offset}}>
          <div styleName="checkbox">
            <Checkbox threeStatesStatus={this.props.allAlertsCheckboxState} onChange={onCheck} />
          </div>
          {fields
            .filter((field) => !(!this.props.isRecommendationEnabled && field.name === 'recommendations'))
            .map((field) => (
              <Tooltip key={`field-${field.id}`} content={field.tooltip} hideIfEqualTo={null} type={TYPES.SMALL}>
                <div styleName={['field', field.name, order.column === field.name ? 'ordered-by' : null].join(' ')}>
                  <TitleHeaderTableOrder
                    onChange={this.handleTitleOrderChange}
                    title={field.title}
                    tooltip={field.tooltip}
                    faIcon={field.faIcon}
                    selectedDirection={order.direction}
                    columnName={field.name}
                    isSortable
                    isActive={field.name === order.column}
                  />
                </div>
              </Tooltip>
            ))}
        </div>
      );
    }
  },
);
