import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import '../OverwiewTab.module.scss';
import {fetchDataStreams} from 'bc/store/actions';
import {getSourceIcon, getStreamChip} from 'bc/services/dataManagerService';
import {isEmpty} from 'lodash';
import {getDataStreamsItems} from 'bc/store/selectors';
import Tooltip from 'common/componentsV2/Tooltip';
import {getFetchTriggeredAlertIsLoading, getTriggeredAlertConfigurations} from '../../../store/selectors';

const HowOftenCubeStatic = ({onIsLoading}: {onIsLoading: Function}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getFetchTriggeredAlertIsLoading);
  const dataStreams = useSelector(getDataStreamsItems);

  const alertConfigData = useSelector(getTriggeredAlertConfigurations);

  const linkClicked = (e) => e.stopPropagation();

  const sourceIds = alertConfigData?.data?.originSourceIds;

  let alertData = null;
  let icon = null;
  let sourceId = null;

  if (sourceIds) {
    // eslint-disable-next-line prefer-destructuring
    sourceId = sourceIds[0];
    alertData = dataStreams.find((stream) => stream.id === sourceId) || {};
    icon = getSourceIcon(alertData.type);
  }

  useEffect(() => {
    dispatch(fetchDataStreams());
  }, []);

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);

  if ((!sourceIds && !alertData) || isEmpty(alertData)) {
    return (
      <div styleName="info-cube-container">
        <div styleName="stream-name">No specific stream</div>
      </div>
    );
  }
  const streamChip = getStreamChip(alertData);

  return (
    <div styleName="info-cube-container">
      <div styleName="stream-name">
        <div styleName="icon">{icon}</div>
        {alertData && (
          <a
            href={`/#!/r/bc/data-manager?searchQuery=${alertData?.name}&expandAll=true`}
            rel="noopener noreferrer"
            target="_blank"
            onClick={linkClicked}
          >
            {alertData.name}
          </a>
        )}
        <div styleName="col-status">
          <Tooltip content={streamChip.tooltip}>
            <span styleName={`stream-chip ${streamChip.style}`}>{streamChip.text}</span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default HowOftenCubeStatic;
