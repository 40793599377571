// @flow
import React from 'react';
import {connect} from 'react-redux';
import {get, noop} from 'lodash';
import {setRoute as setRouteAction} from 'common/store/actions';
import {isStreamEditable} from 'bc/services/dataStreamService';
import * as selectors from 'bc/store/selectors';
import WizardStepMenuItem from './WizardStepMenuItem';
import './WizardStepMenu.module.scss';

export const crumbs = {
  stream: {
    key: 'stream',
    label: 'Stream Query',
    location: (id) => `/bc/data-streams/${id}`,
    index: 1,
  },
  tablePreview: {
    key: 'tablePreview',
    label: 'Stream Table',
    location: (id) => `/bc/data-streams/${id}/preview`,
    index: 2,
  },
};

type PropTypes = {current: String, dataStream: Object, setRoute: Function};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setRoute: setRouteAction,
  },
)(
  class WizardStepMenu extends React.PureComponent {
    props: PropTypes;

    render() {
      const {current, dataStream, setRoute} = this.props;
      const currentIndex = Object.values(crumbs).find((i) => i.key === current).index;
      return (
        <div styleName="root" automation-id="topbarBreadCrums">
          {Object.values(crumbs)
            .filter((item) => (isStreamEditable(get(dataStream, 'state')) ? true : item.index !== 3))
            .map((c, i) => (
              <WizardStepMenuItem
                key={c.key}
                label={c.label}
                isActive={current === c.key}
                isDisabled={i >= currentIndex || currentIndex === 3}
                isFirst={i === 0}
                isLast={i === 1}
                onClick={() => (currentIndex >= c.index ? setRoute(c.location(get(dataStream, 'id'))) : noop())}
              />
            ))}
        </div>
      );
    }
  },
);
