// @flow
import React from 'react';
import RecommendationSubItem from 'recommendations/components/recommendationsList/RecommendationSubItem';
import RecommendationFalsePositives from './listComponents/RecommendationFalsePositives';

import classes from './RecommendationItemExpandablePanel.module.scss';

const RecommendationItemExpandablePanel = ({recommendationItem}: {recommendationItem: Object}) => {
  return recommendationItem?.recommendations?.length ? (
    <div className={classes.container}>
      <div className={classes.recommendations}>
        {recommendationItem?.recommendations?.map((recommendation) => (
          <RecommendationSubItem
            recommendation={recommendation}
            recommendationItemId={recommendationItem?.id}
            alertConfigurationId={recommendationItem?.alertConfigurationId}
          />
        ))}
      </div>
      <RecommendationFalsePositives falsePositivesRate={recommendationItem.falsePositivesRate} />
    </div>
  ) : null;
};

export default RecommendationItemExpandablePanel;
