import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'common/componentsV2/Tooltip';
import {calculateTime, getDelayText} from 'composites/services/compositesService';
import OriginSourceList from './OriginSourceList';

import styles from './CompositeExpandablePanel.module.scss';

const CompositeExpandablePanel = React.memo(({item, compositeName, compositeDelay}: PropTypes) => {
  const timeScale = calculateTime(
    item?.composite?.calculationDelay ? item?.composite?.calculationDelay : compositeDelay,
  );

  const delayNumber = useMemo(
    () => (
      <div className={styles.value}>
        {item?.composite?.calculationDelay
          ? getDelayText('Manual', item?.composite?.calculationDelay, timeScale)
          : getDelayText('Auto', compositeDelay, timeScale)}
      </div>
    ),
    [compositeDelay, timeScale],
  );

  const firstBlock = useMemo(
    () => (
      <div className={styles.firstBlockContainer}>
        <div className={styles.title}>Input Metrics</div>
        <div className={styles.value}>{compositeName?.validation?.totalMetricsInput || 0}</div>
        <div className={styles.title}>Output Metrics</div>
        <div className={styles.value}>{item?.metricsUsageCount || 0}</div>
        <div className={styles.title}>Stream Source</div>
        <div className={styles.value}>
          <OriginSourceList composite={item} />
        </div>
      </div>
    ),
    [item],
  );

  const middleBlock = useMemo(
    () => (
      <div className={styles.middleBlockContainer}>
        <div className={styles.middleBlockInnerWrapper}>
          <div className="display_flex alignItems_center">
            <div className={styles.title}>
              Used in Alerts {item?.usedInAlerts?.length !== undefined && `(${item?.usedInAlerts?.length})`}
            </div>
          </div>
          {item?.usedInAlerts?.map((alert) => (
            <div className={styles.alertRow}>
              {alert.alertName}
              <Tooltip content="See Alert">
                <a href={`#!/r/alert-manager?searchQuery=${alert.alertName}`} target="_blank" rel="noopener noreferrer">
                  <i className={`icon icn-nav16-goto ${styles.iconGoTo}`} />
                </a>
              </Tooltip>
            </div>
          ))}
          <div className={styles.mt}>
            <div className={styles.title}>Composite Delay</div>
            {delayNumber}
          </div>
        </div>
      </div>
    ),
    [item],
  );

  // const lastBlock = useMemo(
  //   () => (
  //     <div className={styles.description}>
  //       <div className={styles.title}>Description</div>
  //       <div className={`text16reg ${styles.textField}`}>{item?.composite?.description}</div>
  //     </div>
  //   ),
  //   [item],
  // );

  return (
    <div className={styles.container}>
      {firstBlock}
      {middleBlock}
      {/* remove till create new composite not available */}
      {/* {lastBlock} */}
    </div>
  );
});

CompositeExpandablePanel.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  compositeDelay: PropTypes.number.isRequired,
};

export default CompositeExpandablePanel;
