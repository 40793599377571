import {useCallback} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/';

const getSchemas = async (type, sourceId) => {
  return get(`${root}data-sources/${type}/${sourceId}/schemas`);
};

export default function useFetchSchemas(type, sourceId) {
  const fallback = [];

  const selectFn = useCallback(
    (data) => {
      return data.map((i) => ({value: i, label: i}));
    },
    [sourceId],
  );

  const {data: schemas = fallback, isLoading: isSchemasLoading, isFetching: isSchemasFetching} = useQuery(
    QUERY_KEYS.schemas,
    () => getSchemas(type, sourceId),
    {
      enabled: !!(type && sourceId),
      select: selectFn,
    },
  );

  return {schemas, isSchemasLoading, isSchemasFetching};
}

export function usePrefetchSchemas(sourceId) {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery(QUERY_KEYS.schemas, () => getSchemas(sourceId), {
    enabled: !!sourceId,
  });
}
