import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {success} from 'common/utils/notifications/notificationsService';
import * as actions from 'admin.permissions/store/actions';
import * as api from 'admin.permissions/services/api';

import {getSelectedDataStream} from 'bc/store/selectors';

const notificationSuccessMsg = {
  title: 'Access updated successfully',
  description: '',
  settings: {
    uid: 'notificationSuccessMsg',
  },
};

const fetchAccessListForAllSources = makeAsyncEpic(
  actions.fetchAccessListForAllSources,
  api.fetchAccessListForAllSources,
);

const fetchAccessListForAllChannels = makeAsyncEpic(
  actions.fetchAccessListForAllChannels,
  api.fetchAccessListForAllChannels,
);

const fetchAccessListByGroups = makeAsyncEpic(actions.fetchAccessListByGroups, api.fetchAccessListByGroups);

const setAccesslistForSource = makeAsyncEpic(actions.setAccesslistForSource, api.setAccesslistForSource);

const setAccesslistForChannel = makeAsyncEpic(actions.setAccesslistForChannel, api.setAccesslistForChannel);

const getAccesslistForSource = makeAsyncEpic(actions.getAccesslistForSource, api.getAccesslistForSource);

const fetchChannelByMetricSource = makeAsyncEpic(actions.fetchChannelByMetricSource, api.fetchChannelByMetricSource);

const syncAccessListForAllSources = (action$) =>
  action$
    .ofType(actions.setAccesslistForSource.success.TYPE, actions.setAccesslistForSource.failure.TYPE)
    .switchMap(({meta}) => {
      return [actions.fetchAccessListForAllSources({}, meta)];
    });

const syncAccessListForChannel = (action$) =>
  action$
    .ofType(actions.setAccesslistForChannel.success.TYPE, actions.setAccesslistForChannel.failure.TYPE)
    .switchMap(() => {
      return [actions.fetchAccessListForAllChannels()];
    });

const successMsgAfterSet = (action$, {getState}) =>
  action$.ofType(actions.setAccesslistForSource.success.TYPE).switchMap(({meta}) => {
    const stream = getSelectedDataStream(getState());
    if (meta.successMsg) {
      return [success({...notificationSuccessMsg, description: stream ? stream.name : ''})];
    }
    return [];
  });

export default combineEpics(
  fetchAccessListForAllSources,
  fetchAccessListByGroups,
  setAccesslistForSource,
  syncAccessListForAllSources,
  successMsgAfterSet,
  fetchAccessListForAllChannels,
  setAccesslistForChannel,
  syncAccessListForChannel,
  fetchChannelByMetricSource,
  getAccesslistForSource,
);
