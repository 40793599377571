// @flow
import React, {useCallback} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS, SEVERITY} from 'alerts.console.new/services/alertsConsoleService';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

const SeverityFilter = () => {
  const [severities, setSeverities] = useQueryParam(ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITIES, StringParam);
  const onChange = useCallback(
    (values) => {
      const currentType = values.length ? values.map((i) => i.value).join(',') : undefined;

      setSeverities(currentType);
    },
    [setSeverities],
  );

  return (
    <SelectAndt
      automationId="alertSeverityFilter"
      type={TYPE_NEW_MULTI_NO_SEARCH}
      theme={THEME_HIGHLIGHTED}
      onChange={onChange}
      options={Object.values(SEVERITY)}
      value={Object.values(SEVERITY).filter((item) => severities?.split(',')?.includes(item.value))}
      isMulti
      placeholder="All"
      optionHeight={40}
      menuWidth={155}
      isClearable={false}
      closeMenuOnSelect={false}
      noAutoReorder
    />
  );
};

export default SeverityFilter;
