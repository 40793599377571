import React from 'react';
import {useFormContext} from 'react-hook-form';

import useFetchTemplate from 'dataCollectors/api/useFetchTemplate';
import {JASON_TEMPLATES, INFO_TEXT} from 'dataCollectors/services/dataCollectorsService';
import {EVENT_STREAM_FIELDS, EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';
import StreamInfo from 'dataCollectors/components/streamInfo/StreamInfo';
import DataMapper from 'dataCollectors/components/dataMapper/DataMapper';
import Schedule from 'dataCollectors/components/schedule/Schedule';
import UserEventsCategory from 'dataCollectors/components/userEventsCategory/UserEventsCategory';
import {SourceData} from 'dataCollectors/components/sourceData/services/sourceDataService';
import PropTypes from 'prop-types';

const {TYPE} = EVENT_STREAM_FIELDS;
const {LOCAL_FILE} = EVENT_STREAM_TYPES;

export default function ExtractPage() {
  const {getValues} = useFormContext();
  const {templateJson} = useFetchTemplate(INFO_TEXT, JASON_TEMPLATES);
  const type = getValues(TYPE.id);
  const template = templateJson?.eventStream ? templateJson.eventStream[type] : null;
  return (
    <>
      <StreamInfo leftSideWidth={590} info={template?.streamInfo} />
      <SourceData template={template} type={type} />
      <DataMapper leftSideWidth={450} info={template?.dataMapperInfo} />
      <UserEventsCategory leftSideWidth={590} info={template?.eventCategoryInfo} isLast={type === LOCAL_FILE} />
      <Schedule leftSideWidth={590} info={template?.scheduleInfo} isVisible={type !== LOCAL_FILE} />
    </>
  );
}

SourceData.defaultProps = {
  template: null,
};

SourceData.propTypes = {
  template: PropTypes.objectOf(PropTypes.object),
  type: PropTypes.string.isRequired,
};
