import React, {useState} from 'react';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import MissingDataModal from 'bc/components/streams/editor/missingDataSection/MissingDataModal';
import MissingDataSummary from 'bc/components/streams/editor/missingDataSection/MissingDataSummary';

const MissingDataSection = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="shell-col">
      <PanelHeader title="Missing data" onSelect={openModal} isEditable />
      <MissingDataSummary />
      <MissingDataModal isOpen={modalIsOpen} onClose={closeModal} />
    </div>
  );
};

MissingDataSection.propTypes = {};

export default MissingDataSection;
