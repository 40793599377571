// @flow
/* eslint-disable no-case-declarations */
import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import * as actions from 'admin.activityLog/store/actions';
import * as selectors from 'admin.activityLog/store/selectors';
import PageLayout from 'common/componentsV2/PageLayout';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import FilterButton from 'common/componentsV2/FilterButton';
import {omitBy, isEqual, omit} from 'lodash';
import {
  DEFAULT_PARAMS,
  DEFAULT_PARAMS_CONFIG,
  getEntityTypeByParamName,
  getLabel,
  getNameEntityType,
  OPTIONS_ACTION_TYPE,
  OPTIONS_ENTITY_TYPE,
  PARAMS_NAME,
  SYSTEM_USER,
} from 'admin.activityLog/services/activityLogService';
import {useQueryParams} from 'use-query-params';
import ActivityLogFilter from 'admin.activityLog/components/ActivityLogFilters';
import ActivityLogMain from 'admin.activityLog/components/ActivityLogMain';
import {getUsersData} from 'admin.users/store/selectors';
import {getMeOwnerOrganization, getTimeZoneName} from 'profile/store/selectors';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {getDateValue, rangeTypes} from 'common/utils/dateRangeService';
import Button, {COLORS} from 'common/componentsV2/Button';
import useAsyncAction from 'common/utils/useAsyncAction';
import {useLocation} from 'react-router-dom';
import {parseQS} from 'common/utils/http';

const EMPTY_OBJECT = {};

const ActivityLog = () => {
  const dispatch = useDispatch();
  const prevDateRangeRef = useRef();
  const isOpenFilters = useSelector(selectors.isOpenFilters);
  const usersData = useSelector(getUsersData);
  const auditLogSelector = useSelector(selectors.getFetchAuditLog);
  const {entries: data, totalMatches, nextPage} = useSelector(selectors.getAuditLogData);
  const listEntityId = useSelector(selectors.getListEntityId);
  const customerId = useSelector(getMeOwnerOrganization);
  const totalCount = useSelector(selectors.getTotalCount);
  const isLoading = useSelector(selectors.auditLogDataIsLoading);
  const isLoadingNextPage = useSelector(selectors.getNextPageIsLoading);
  const isLoadingTotalCount = useSelector(selectors.getTotalCountIsLoading);
  const listEntityIdIsLoading = useSelector(selectors.getListEntityIdIsLoading);
  const timeZoneName = useSelector(getTimeZoneName);
  const [list, setList] = useState(EMPTY_OBJECT);

  const [queryParams, setQueryParams] = useQueryParams(DEFAULT_PARAMS_CONFIG);

  const location = useLocation();

  const dateRange = useMemo(
    () => getDateValue({constRange: queryParams[PARAMS_NAME.CONST_RANGE]}, false, timeZoneName),
    [queryParams[PARAMS_NAME.CONST_RANGE]],
  );

  const payload = useMemo(() => {
    // Query params is updated in ActivityLogMain.js, but does not apply here, so need to take the entityId from location.
    const entityType = getNameEntityType(queryParams[PARAMS_NAME.ENTITY_TYPE]);
    const currentEntityId = parseQS(location.search)[entityType] || parseQS(location.search)[PARAMS_NAME.ENTITY_ID];
    return {
      customerId,
      fromTime: dateRange.startDate,
      toTime: dateRange.endDate,
      actionTypes:
        queryParams[PARAMS_NAME.ACTION_TYPE] !== DEFAULT_PARAMS[PARAMS_NAME.ACTION_TYPE]
          ? queryParams[PARAMS_NAME.ACTION_TYPE].split(',')
          : undefined,
      userId: queryParams[PARAMS_NAME.USERS] !== SYSTEM_USER.value ? queryParams[PARAMS_NAME.USERS] : undefined,
      entityTypes: queryParams[PARAMS_NAME.ENTITY_TYPE] ? [queryParams[PARAMS_NAME.ENTITY_TYPE]] : undefined,
      entityId: currentEntityId,
      actionSource: queryParams[PARAMS_NAME.USERS] === SYSTEM_USER.value ? SYSTEM_USER.value : undefined,
    };
  }, [queryParams]);

  const loadMoreRows = useAsyncAction(actions.fetchNextPageAuditLog, auditLogSelector);
  const isFiltersApply = useMemo(
    () => !isEqual(omit(queryParams, PARAMS_NAME.CONST_RANGE), omit(DEFAULT_PARAMS, PARAMS_NAME.CONST_RANGE)),
    [queryParams],
  );

  useEffect(() => {
    dispatch(actions.fetchAuditLog(payload, {isFiltersApply}));
  }, [queryParams, isFiltersApply]);

  useEffect(() => {
    if (isFiltersApply && !isEqual(prevDateRangeRef.current, dateRange)) {
      dispatch(
        actions.fetchTotalCount({
          customerId,
          fromTime: dateRange.startDate,
          toTime: dateRange.endDate,
        }),
      );
    }
    prevDateRangeRef.current = dateRange;
  }, [dateRange, isFiltersApply]);

  useEffect(() => {
    if (queryParams[PARAMS_NAME.ENTITY_TYPE] && queryParams[PARAMS_NAME.ENTITY_ID]) {
      dispatch(actions.fetchListEntityId({...payload, entityId: undefined}));
    }
  }, []);

  useEffect(() => {
    if (queryParams[PARAMS_NAME.ENTITY_TYPE] && !isLoading && !queryParams[PARAMS_NAME.ENTITY_ID]) {
      dispatch(actions.setListEntityId({entries: data}));
    }
  }, [data, isLoading, queryParams[PARAMS_NAME.ENTITY_TYPE], queryParams[PARAMS_NAME.ENTITY_ID]]);

  const toggleFilters = useCallback(() => {
    dispatch(actions.updateFilterBtn(isOpenFilters));
  }, [isOpenFilters]);

  const handleExport = useCallback(() => {
    const dataStr = JSON.stringify(list, null, 2);
    const dataUri = `data:application/json;charset=utf-8,${encodeURIComponent(dataStr)}`;
    const exportFileDefaultName = `activity-log${moment().format('DD-M-YYYY k:mm')}.json`;

    const linkElement = document.createElement('a');
    linkElement.setAttribute('href', dataUri);
    linkElement.setAttribute('download', exportFileDefaultName);
    linkElement.click();

    dispatch(
      actions.displayActivitySnackBar({
        title: 'Activity Log Exported successfully!',
      }),
    );
  }, [list]);

  const tooltipItems = useMemo(() => {
    const obj = omitBy(queryParams, (value, key) => value === DEFAULT_PARAMS[key]);
    return Object.entries(obj).map(([key, value]) => {
      switch (key) {
        case PARAMS_NAME.CONST_RANGE:
          return {
            id: value,
            header: 'Date Range',
            value: (Object.values(rangeTypes).find((r) => r.value === value) || EMPTY_OBJECT).text,
          };
        case PARAMS_NAME.USERS:
          const isAnodotUser = value.includes(SYSTEM_USER.value);
          const user = usersData.flatMap((u) => (value.includes(u._id) ? `${u.firstName} ${u.lastName}` : []));
          return {
            header: 'Users',
            value: [...user, isAnodotUser ? SYSTEM_USER.label : []].join(', '),
            id: value,
          };
        case PARAMS_NAME.ACTION_TYPE:
          return {
            header: 'Action Type',
            value: getLabel(OPTIONS_ACTION_TYPE, value),
            id: value,
          };
        case PARAMS_NAME.ENTITY_TYPE:
          return {
            header: 'Show Activity of',
            value: getLabel(OPTIONS_ENTITY_TYPE, value),
            id: value,
          };
        case PARAMS_NAME.SOURCE:
        case PARAMS_NAME.METRIC_STREAM:
        case PARAMS_NAME.EVENTS_STREAM:
        case PARAMS_NAME.ALERT:
        case PARAMS_NAME.CHANNEL:
          return {
            header: `Filter by ${getLabel(OPTIONS_ENTITY_TYPE, getEntityTypeByParamName(key))}`,
            value: (data.find((o) => o.entityDefinition.id === value) || EMPTY_OBJECT).entityName,
            id: value,
          };
        default:
          return EMPTY_OBJECT;
      }
    });
  }, [queryParams, usersData, data]);

  const header = (
    <div className="display_flex width_1 justifyContent_space-between">
      <div className="display_flex">
        <div className="mr_1-5">
          <Title type={TITLE_TYPES.PAGE_TITLE}>Activity Log</Title>
        </div>
        <div className="mr_1-5">
          <Title type={TITLE_TYPES.PAGE_LINE_COUNTER}>
            {isLoading || isLoadingTotalCount
              ? '(...)'
              : `(${isFiltersApply ? `${totalMatches}/${totalCount}` : totalMatches})`}
          </Title>
        </div>
        <FilterButton tooltipItems={tooltipItems} onClick={toggleFilters} />
      </div>
      <Button
        automationId="exportActivityLog"
        icon="icn-action16-download"
        text="Export Log"
        colorSchema={COLORS.GRAY_200}
        onClick={handleExport}
        isDisabled={!Object.keys(list).length}
      />
    </div>
  );

  return (
    <PageLayout header={header}>
      <div className="display_flex flexGrow_1">
        <ActivityLogFilter
          listEntityId={listEntityId}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          isOpenFilters={isOpenFilters}
          toggleFilters={toggleFilters}
          usersData={usersData}
          timeZoneName={timeZoneName}
          isLoading={isLoading}
          listEntityIdIsLoading={listEntityIdIsLoading}
        />
        {isLoading ? (
          <div className="display_flex alignItems_center justifyContent_center height_1vh width_1vw">
            <Spinner color="gray.500" size={SIZES.XX_BIG_100} />
          </div>
        ) : (
          <ActivityLogMain
            data={data}
            setQueryParams={setQueryParams}
            usersData={usersData}
            setList={setList}
            loadMoreRows={loadMoreRows}
            payload={payload}
            nextPage={nextPage}
            isLoadingNextPage={isLoadingNextPage}
            totalCount={totalCount}
          />
        )}
      </div>
    </PageLayout>
  );
};

export default ActivityLog;
