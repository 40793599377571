// @flow
import React, {useEffect, useState} from 'react';
import {get, last} from 'lodash';
import * as actions from 'alerts.console.new/store/actions';
import * as commonActions from 'common/store/actions';
import {useSelector} from 'react-redux';
import SeverityMarker from 'common/componentsV2/SeverityMarker';
import {getAlertAssigneeEnabled, getProfileId, getRecommendationsEnabled} from 'profile/store/selectors';
import {getTimeZoneName} from 'bc/store/selectors';
import {getMetricResolutions} from 'metrics/store/selectors';
import {setAlertAsRead} from 'alerts.console.new/api/api';
import {getAlertConsoleOpenItems} from 'alerts.console.new/store/selectors';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import {queryErrorHandler} from 'reactQuery/queryClient';
import AlertTitle from 'alerts.console.new/components/alertsList/alertContent/AlertTitle';
import AlertDate from 'alerts.console.new/components/alertsList/alertContent/AlertDate';
import AlertStatusIcon from 'alerts.console.new/components/alertsList/alertContent/AlertStatusIcon';
import AlertDuration from 'alerts.console.new/components/alertsList/alertContent/AlertDuration';
import AlertScore from 'alerts.console.new/components/alertsList/alertContent/AlertScore';
import GraphContent from 'alerts.console.new/components/alertsList/alertContent/GraphContent';
import AlertActions from 'alerts.console.new/components/alertsList/alertContent/AlertActions';
import AlertAssignee from 'alerts.console.new/components/alertsList/alertContent/AlertAssignee';
import AlertRecommendation from 'alerts.console.new/components/alertsList/alertContent/AlertRecommendation';

import './AlertListItem.module.scss';

const ROW_DEFAULT_HEIGHT = 49;

const AlertListItem = ({
  trigger,
  alertGroupId,
  index,
  isAlertsListScrolling,
  queryParams,
}: {
  trigger: Object,
  alertGroupId: Number,
  index: Number,
  isAlertsListScrolling: Boolean,
  queryParams: Object,
}) => {
  const meId = useSelector(getProfileId);
  const timeZoneName = useSelector(getTimeZoneName);
  const openItems = useSelector(getAlertConsoleOpenItems);
  const resolutions = useSelector(getMetricResolutions);
  const isAlertAssigneeEnabled = useSelector(getAlertAssigneeEnabled);
  const isRecommendationsEnabled = useSelector(getRecommendationsEnabled);

  const triggeredAlertsProvider = fetchTriggeredAlertsProvider(queryParams);

  const [alertState, setAlertState] = useState({
    rowOpen: false,
    containerHeight: ROW_DEFAULT_HEIGHT,
    rowAnimation: 0,
    overflow: 'unset',
    opacityContent: 1,
  });

  const [onMouseOver, setOnMouseOver] = useState(false);

  const closeRow = () => {
    setAlertState((prevState) => ({
      ...prevState,
      opacityContent: 0,
    }));

    setTimeout(() => {
      actions.setSelectedTriggeredAlert(trigger.alertTriggerId);
      setAlertState((prevState) => ({
        ...alertState,
        rowOpen: false,
        overflow: 'unset',
        containerHeight: ROW_DEFAULT_HEIGHT,
        rowAnimation: prevState.rowAnimation / 1.5,
      }));
    }, 200);
  };

  const openRow = (alertClicked) => {
    if (alertClicked) {
      // we checked if the alert was triggered from outside (for example: came from Email),
      // the Store already have the triggeredId.
      actions.setSelectedTriggeredAlert(trigger.alertTriggerId);
    }

    if (!trigger.read) {
      setTimeout(async () => {
        triggeredAlertsProvider.setAlertAsReadInCache(trigger.alertTriggerId, {isRead: true});
        try {
          setAlertAsRead(trigger.alertTriggerId);
        } catch (e) {
          const error = {
            title: 'Mark as read error',
            description: `Error while marking alert as Read`,
          };
          queryErrorHandler(error);
          // Need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
          await triggeredAlertsProvider.invalidate();
        }
      }, 200);
    }

    setAlertState((prevState) => ({
      ...prevState,
      rowOpen: true,
      opacityContent: 1,
    }));
  };

  const alertClicked = (e) => {
    if (!e.target.closest('.alert-clickable-item')) {
      if (!alertState.rowOpen) {
        openRow(true);
        commonActions.segmentClickEvent({type: 'click', name: 'alert-open'});
      } else {
        closeRow();
        commonActions.segmentClickEvent({type: 'click', name: 'alert-close'});
      }
    }
  };

  const changeChartWrapperStyle = (alertContentHeight = 0) => {
    setAlertState((prevState) => ({
      ...prevState,
      overflow: 'hidden',
      containerHeight: alertContentHeight + ROW_DEFAULT_HEIGHT,
      // eslint-disable-next-line no-restricted-properties
      rowAnimation: Math.pow(alertContentHeight, 0.3) * 0.05,
    }));
  };

  useEffect(() => {
    if (openItems.length === 1 && openItems[0] === alert.id) {
      openRow(false);
    }
  }, []);

  const EMPTY_OBJECT = {};
  const firstMetric = get(trigger, 'metrics[0]', EMPTY_OBJECT);
  const lastInterval = last(get(firstMetric, 'intervals', [])) || EMPTY_OBJECT;

  const style = {
    container: {
      overflow: alertState.overflow,
      height: alertState.containerHeight,
      transition: [`height ${alertState.rowAnimation}s ease-in-out`],
    },
  };

  const getGridColumnsStyle = () => {
    if (isAlertAssigneeEnabled && !isRecommendationsEnabled) {
      return 'row-wrapper isNotRecommendationsEnabled';
    }
    if (!isAlertAssigneeEnabled && isRecommendationsEnabled) {
      return 'row-wrapper isNotAlertAssigneeEnabled';
    }
    if (!isAlertAssigneeEnabled && !isRecommendationsEnabled) {
      return 'row-wrapper isNotAlertAssigneeNorRecommendationsEnabled';
    }
    return 'row-wrapper';
  };

  const alertStyle = [getGridColumnsStyle(), alertState.rowOpen ? 'open' : '', trigger.read ? 'markAsRead' : ''];

  return (
    <div styleName="container" style={style.container}>
      {index !== 0 ? <div styleName="group-sep" className="group-sep-global" /> : null}
      <div
        style={{height: ROW_DEFAULT_HEIGHT}}
        styleName={alertStyle.join(' ')}
        className="row-wrapper-global"
        key={trigger.alertTriggerId}
        onClick={alertClicked}
        onMouseOver={() => setOnMouseOver(true)}
        onFocus={() => setOnMouseOver(true)}
        onMouseLeave={() => setOnMouseOver(false)}
        role="button"
      >
        <div style={{opacity: `${queryParams?.severityView ? 'inherit' : '0'}`}}>
          <SeverityMarker severity={trigger.severity} isLargeMarker />
        </div>
        <AlertStatusIcon status={trigger.status} />
        <AlertTitle
          trigger={trigger}
          alertGroupId={alertGroupId}
          searchWords={queryParams?.searchExpression}
          meId={meId}
          onMouseOver={onMouseOver}
          isRowOpen={alertState.rowOpen}
          isAlertsListScrolling={isAlertsListScrolling}
          queryParams={queryParams}
        />
        <AlertRecommendation
          hasRecommendations={trigger.recommendationId}
          alertConfigurationId={trigger.alertConfigurationId}
        />
        <AlertAssignee
          assignee={trigger.assignee}
          alertConfigurationId={trigger.alertConfigurationId}
          alertGroupId={alertGroupId}
          alertTriggerId={trigger.alertTriggerId}
          isDisplay={onMouseOver}
          queryParams={queryParams}
        />
        <AlertDate startDate={trigger.startTime} timeZone={timeZoneName} />
        <AlertDate startDate={trigger.endTime} timeZone={timeZoneName} />
        <AlertDuration duration={trigger.duration} />
        <AlertScore value={trigger?.score ? Math.round(trigger?.score * 100) : undefined} />
        <AlertScore value={trigger?.numMetrics} />
        <AlertActions
          trigger={trigger}
          alertGroupId={alertGroupId}
          isDisplay={onMouseOver}
          lastInterval={lastInterval}
          queryParams={queryParams}
          resolutions={resolutions}
          timeScale={Object.values(resolutions).find((a) => a.value2 === trigger.timeScale).value}
        />
        {alertState.rowOpen === true ? (
          <GraphContent
            open={alertState.rowOpen}
            opacity={alertState.opacityContent}
            trigger={trigger}
            onChangeChartWrapperStyle={changeChartWrapperStyle}
            index={index}
            queryParams={queryParams}
          />
        ) : null}
      </div>
    </div>
  );
};

export default AlertListItem;
