import {combineReducers} from 'redux';

import {composeReducers} from 'common/utils/reducers';
import * as actions from 'admin.permissions/store/actions';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';

const EMPTY_ARRAY = [];

const fetchAccessListForAllSources = composeReducers(
  makeAsyncReducer(actions.fetchAccessListForAllSources, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const fetchAccessListForAllChannels = composeReducers(
  makeAsyncReducer(actions.fetchAccessListForAllChannels, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const fetchAccessListByGroups = composeReducers(
  makeAsyncReducer(actions.fetchAccessListByGroups, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const setAccesslistForSource = composeReducers(
  makeAsyncReducer(actions.setAccesslistForSource, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const setAccesslistForChannel = composeReducers(
  makeAsyncReducer(actions.setAccesslistForChannel, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const getAccesslistForSource = composeReducers(
  makeAsyncReducer(actions.getAccesslistForSource, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const fetchChannelByMetricSource = composeReducers(
  makeAsyncReducer(actions.fetchChannelByMetricSource, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

export default combineReducers({
  fetchAccessListForAllSources,
  fetchAccessListByGroups,
  setAccesslistForSource,
  fetchAccessListForAllChannels,
  setAccesslistForChannel,
  fetchChannelByMetricSource,
  getAccesslistForSource,
});
