import React, {useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useQueryParam, StringParam} from 'use-query-params';

import {useFetchEventStream} from 'dataCollectors/api/useFetchEventStreams';
import Input from 'common/componentsV2/Input';
import TextArea from 'common/componentsV2/TextArea';
import {useFormContext} from 'react-hook-form';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import InfoBox from 'dataCollectors/components/infoBox/InfoBox';
import useSectionsClasses from 'dataCollectors/styles/classes.useSectionsClasses';
import Users from 'dataCollectors/components/users/Users';

const {STREAM_NAME, STREAM_OWNER, STREAM_DESCRIPTION} = EVENT_STREAM_FIELDS;

const {sections} = useSectionsClasses();

const StreamInfo = ({info, leftSideWidth}) => {
  const sectionsClasses = sections({leftSideWidth});

  const [streamId] = useQueryParam('streamId', StringParam);

  const {isEventStreamLoading} = useFetchEventStream(streamId);

  const {
    setValue,
    getValues,
    formState: {errors},
  } = useFormContext();

  const values = getValues();

  const setName = useCallback(
    (e) => {
      setValue(STREAM_NAME.id, e.target.value);
    },
    [values[STREAM_NAME.id]],
  );

  const setDescription = useCallback((e) => {
    setValue(STREAM_DESCRIPTION.id, e.target.value);
  }, []);

  useEffect(() => {}, [values[STREAM_NAME.id]]);

  return (
    <div className={sectionsClasses.root}>
      <div className={sectionsClasses.container}>
        <div className={sectionsClasses.left}>
          <div className={sectionsClasses.title}>Stream Information</div>
          <div className={sectionsClasses.subTitle}>Collect events for improved management and tuning of alerts</div>

          <div className={sectionsClasses.inputsWrapper}>
            <div className={`${sectionsClasses.container} ${sectionsClasses.marginTop}`}>
              <div className={`${sectionsClasses.wrapperHalfRow} ${sectionsClasses.marginRight}`}>
                <div className={sectionsClasses.label}>Name</div>
                <Input
                  additionalProps={{maxLength: 55}}
                  style={{height: 38, paddingBottom: 14}}
                  value={values[STREAM_NAME.id]}
                  onChange={setName}
                  invalidMessage={errors[STREAM_NAME.id]}
                  isInvalid={errors[STREAM_NAME.id] && !values[STREAM_NAME.id]}
                  placeholder={isEventStreamLoading ? 'Loading..' : ''}
                  automationId={STREAM_NAME.id}
                />
              </div>
              <div className={`${sectionsClasses.wrapperHalfRow} ${sectionsClasses.marginLeft}`}>
                <Users
                  onChange={(value) => setValue(STREAM_OWNER.id, value)}
                  selectedValue={values[STREAM_OWNER.id]}
                  error={errors[STREAM_OWNER.id] && !values[STREAM_OWNER.id]}
                  isLoading={isEventStreamLoading}
                  name={STREAM_OWNER.name}
                />
              </div>
            </div>
            <div className={`${sectionsClasses.description} ${sectionsClasses.marginTop}`}>
              <div className={sectionsClasses.label}>Description</div>
              <TextArea
                minRows={4}
                maxRows={4}
                value={values[STREAM_DESCRIPTION.id]}
                onChange={setDescription}
                automationId={STREAM_DESCRIPTION.id}
                placeholder={isEventStreamLoading ? 'Loading..' : ''}
              />
            </div>
          </div>
        </div>
        {info && <InfoBox title={info.title} body={info.body} />}
      </div>
    </div>
  );
};

StreamInfo.defaultProps = {
  info: null,
  leftSideWidth: '100%',
};

StreamInfo.propTypes = {
  info: PropTypes.objectOf(PropTypes.string),
  leftSideWidth: PropTypes.number,
};

export default StreamInfo;
