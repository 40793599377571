import {useQuery} from 'react-query';
import {queryClient} from 'reactQuery/queryClient';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/channels/';

const fetchChannels = async () => {
  const channels = await get(root);
  return channels;
};

export default function fetchChannelsProvider() {
  const queryKey = [QUERY_KEYS.triggeredAlertsChannels];
  return {
    fetchQuery: () =>
      queryClient.fetchQuery(queryKey, () => fetchChannels(), {
        retry: false,
      }),
    useQuery: () =>
      useQuery(queryKey, () => fetchChannels(), {
        retry: false,
      }),
  };
}
