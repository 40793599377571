import {delete_, post, put} from 'common/utils/http';
import {alertsConsoleRoot} from './utils';

export const metricsRoot = 'api/v1/metrics/';
export const userEventsRoot = 'api/v1/user-events/';

export const fetchAlertConsoleMetricsDataPoints = ({payload}) =>
  post(
    `${metricsRoot}composite/execute?fromDate=${payload.startDate}&toDate=${payload.endDate}&startBucketMode=${payload.startBucketMode}&includeBaseline=${payload.includeBaseline}&index=0&size=1&maxDataPoints=500&resolution=${payload.timeScale}`,
    payload.body,
  );

export const fetchAlertEvents = ({payload}) =>
  post(
    `${userEventsRoot}execute?fromDate=${payload.startDate}&index=0&startBucketMode=${payload.startBucketMode}&toDate=${payload.endDate}`,
    payload.body,
  );

export const fetchAlertMultipleEvents = ({payload}) =>
  post(
    `${userEventsRoot}execute-multiple-events?fromDate=${payload.startDate}&index=0&startBucketMode=${payload.startBucketMode}&toDate=${payload.endDate}`,
    payload.body,
  );

// READ
export const setAlertAsRead = (alertTriggerId) => {
  return post(`${alertsConsoleRoot}triggered/${alertTriggerId}/read/add`);
};

// ACKNOWLEDGEMENT
export const setAlertTriggerAcknowledgement = (payload) => {
  const apiPath = `${alertsConsoleRoot}triggered/${payload.alertTriggerId}/ack`;
  if (payload.isAcknowledged) {
    return post(apiPath, {userId: payload.meId});
  }
  return delete_(apiPath, {userId: payload.meId});
};

// SNOOZE
export const snoozeTriggerMetrics = ({payload, meta}) => {
  return post(`${alertsConsoleRoot}config/${meta.alertConfigurationId}/snooze?includeSTL=${meta.includeSTL}`, payload);
};

export const endSnoozeTriggerMetrics = ({payload, meta}) => {
  return delete_(
    `${alertsConsoleRoot}config/${meta.alertConfigurationId}/snooze?includeSTL=${meta.includeSTL}`,
    payload,
  );
};

export const stopLearningTriggerMetrics = ({payload}) => {
  return post(`${metricsRoot}stl/add`, payload);
};

export const endStopLearningTriggerMetrics = ({payload}) => {
  return post(`${metricsRoot}stl/remove`, payload);
};

// ASSIGNEE
export const setAssignee = (payload) => {
  return put(`${alertsConsoleRoot}triggered/${payload.alertTriggerId}/assignee`, {userId: payload.assignee});
};

export const deleteAssignee = (payload) => {
  return delete_(`${alertsConsoleRoot}triggered/${payload.alertTriggerId}/assignee`);
};
