// @flow
import React from 'react';
import {ReactComponent as AlertConsole} from 'common/componentsV2/holiday/halloween/images/alert-console.svg';
import {ReactComponent as Anomalies} from 'common/componentsV2/holiday/halloween/images/anomalies.svg';
import {ReactComponent as Catalog} from 'common/componentsV2/holiday/halloween/images/catalog.svg';
import {ReactComponent as Dashboard} from 'common/componentsV2/holiday/halloween/images/dashboard.svg';
import {ReactComponent as Integrations} from 'common/componentsV2/holiday/halloween/images/integrations.svg';
import {ReactComponent as Management} from 'common/componentsV2/holiday/halloween/images/management.svg';
import {ReactComponent as MetricExplorer} from 'common/componentsV2/holiday/halloween/images/metric-explorer.svg';
import {ReactComponent as User} from 'common/componentsV2/holiday/halloween/images/user.svg';
import {ReactComponent as LogoHalloween} from 'common/componentsV2/holiday/halloween/images/logo-anodot-halloween.svg';
import {ReactComponent as Topology} from 'common/componentsV2/holiday/halloween/images/topology.svg';
import {ReactComponent as Frankenstein} from 'common/componentsV2/holiday/halloween/images/frankenstein.svg';
import {ReactComponent as SpookyModal} from 'common/componentsV2/holiday/halloween/images/spooky-modal.svg';

const HalloweenIcons = ({title}: {title: string}) => {
  const renderBody = () => {
    switch (title) {
      case 'Logo':
        return <LogoHalloween width={34} height={34} />;
      case 'Alert Console':
        return <AlertConsole />;
      case 'Anomalies':
        return <Anomalies />;
      case 'Metric Explorer':
        return <MetricExplorer />;
      case 'Dashboards':
        return <Dashboard />;
      case 'Catalog':
        return <Catalog />;
      case 'Integrations':
        return <Integrations />;
      case 'Management':
        return <Management />;
      case 'Topology':
        return <Topology />;
      case 'User':
        return <User />;
      case 'AlertItemIcon':
        return <Frankenstein />;
      case 'Modal':
        return <SpookyModal className="alert-clickable-item" />;
      default:
        return null;
    }
  };
  return renderBody();
};

export default HalloweenIcons;
