import React from 'react';
import {useFormContext} from 'react-hook-form';
import usePreviewClasses from 'dataCollectors/styles/classes.usePreviewClasses';
import SchedulePreview from 'dataCollectors/components/schedule/SchedulePreview';
import StreamTablePreview from 'dataCollectors/components/sourceData/streamTable/StreamTablePreview';

import {EVENT_STREAM_FIELDS, EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';

const {TYPE} = EVENT_STREAM_FIELDS;
const {LOCAL_FILE} = EVENT_STREAM_TYPES;

export default function Settings() {
  const previewClasses = usePreviewClasses();
  const {getValues} = useFormContext();
  const type = getValues(TYPE.id);
  return (
    <div className={previewClasses.container}>
      {type !== LOCAL_FILE && <SchedulePreview />}
      <StreamTablePreview />
    </div>
  );
}
