// @flow
/* eslint-disable no-shadow */
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import ReactQueryParams from 'common/components/ReactQueryParams';
import PageLayout from 'common/componentsV2/PageLayout';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import DataManagerHeader from 'bc/components/header/DataManagerHeader';
import DataManagerFilters from 'bc/components/filter/DataManagerFilters';
import DataManagerList from 'bc/components/dataManager/DataManagerList';
import {updateDataMangerQueryParams} from 'bc/store/actions';
import {getDataMangerQueryParams} from 'bc/store/selectors';
import {getIsRbacEnabled} from 'admin.permissions/store/selectors';
import {getQuery} from 'common/store/selectors';
import {DEFAULT_QUERY_PARAMS} from 'bc/services/dataManagerService';

import './DataManagerNew.module.scss';

type PropTypes = {
  location: Object,
  history: Object,

  // connect
  dataQueryParams: Object,
  updateDataMangerQueryParams: Function,
};

export default connect(
  (state) => ({
    dataQueryParams: getDataMangerQueryParams(state),
    queryParams: getQuery(state),
    isRbacEnabled: getIsRbacEnabled(state),
  }),
  {
    updateDataMangerQueryParams,
  },
)(
  class DataManager extends ReactQueryParams {
    props: PropTypes;

    defaultQueryParams = {...DEFAULT_QUERY_PARAMS};

    state = {
      listState: null,
    };

    componentDidMount() {
      const {dataQueryParams, updateDataMangerQueryParams, queryParams} = this.props;

      if (queryParams && queryParams.expandAll) {
        this.expandAll();
      }

      if (!isEmpty(dataQueryParams)) {
        this.setQueryParams(dataQueryParams);
      } else {
        updateDataMangerQueryParams(this.queryParams);
      }
    }

    componentDidUpdate(prevProps) {
      if (super.componentDidUpdate) {
        super.componentDidUpdate();
      }
      if (prevProps.location.search !== this.props.location.search) {
        this.props.updateDataMangerQueryParams(this.queryParams);
      }
    }

    setQueryParamsWrapper = (queryObj) => {
      this.setQueryParams(queryObj, true);
    };

    collapseAll = () => {
      this.setState({listState: 'COLLAPSE'});
    };

    expandAll = () => {
      this.setState({listState: 'EXPAND'});
    };

    onListStateChange = () => {
      this.setState({listState: 'DONE'});
    };

    render() {
      return (
        <Fragment>
          <div styleName="page-container">
            <PageLayout header={<DataManagerHeader onCollapseAll={this.collapseAll} onExpandAll={this.expandAll} />}>
              <div styleName="page-content-wrapper">
                <DataManagerFilters setQueryParams={this.setQueryParamsWrapper} queryParams={this.queryParams} />
                <div styleName="data-manager-list-container">
                  <DataManagerList
                    setQueryParams={this.setQueryParamsWrapper}
                    listState={this.state.listState}
                    onListStateChange={this.onListStateChange}
                    isRbacEnabled={this.props.isRbacEnabled}
                  />
                </div>
              </div>
            </PageLayout>
          </div>
        </Fragment>
      );
    }
  },
);
