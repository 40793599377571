import {
  ALERT_CONSOLE_COLUMN_HEADERS,
  ALERT_CONSOLE_QUERY_PARAMS_KEYS,
  ALERT_TYPES,
  FEEDBACK_OPTIONS,
  ORDER_DIRECTIONS,
} from 'alerts.console.new/services/alertsConsoleService';
import {modalRouting as INVESTIGATION_PARAMS} from 'investigationNewAlertConsole/services/investigationService';
import {
  modalRouting1 as IMPACT_PARAMS1,
  modalRouting2 as IMPACT_PARAMS2,
} from 'impactNewAlertConsole/services/constants';

// This needs to be copied here since the old alert console will be eventually deleted
export const OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS = {
  ACK: 'ack',
  ALERT_CONFIGURATION_IDS: 'alertConfigurationIds',
  ASSIGNEE: 'assignee',
  CHANNELS: 'channels',
  CONST_RANGE: 'constRange',
  END_TIME: 'endTime',
  INDEX: 'index',
  LABELS: 'labels',
  MAKE_ACK: 'makeAck',
  NEGATIVE_FEEDBACK: 'negativeFeedback',
  NO_FEEDBACK: 'noFeedback',
  ORDER: 'order',
  POSITIVE_FEEDBACK: 'positiveFeedback',
  REF: 'ref',
  RELATIVE_LAST: 'relativeLast',
  RELATIVE_NEXT: 'relativeNext',
  SEARCH_QUERY: 'searchQuery',
  SEVERITIES: 'severities',
  SEVERITY_VIEW: 'severityView',
  SIZE: 'size',
  SORT: 'sort',
  START_TIME: 'startTime',
  STATUS: 'status',
  SUBSCRIBERS: 'subscribers',
  TRIGGER_IDS: 'triggerIds',
  TYPES: 'types',
};

export const OLD_SORT_PARAMETER = {
  ASSIGNEE: 'assignee',
  START_TIME: 'startTime',
  SCORE: 'score',
  UPDATE_TIME: 'updatedTime',
};

export const OLD_ALERT_STATUS_PARAMETER = {ALL: undefined, OPEN: 'OPEN'};

export const OLD_ACK_PARAMETER = {
  ACK: 'ACK',
  NOACK: 'NOACK',
};

export const OLD_ALERT_TYPE_PARAMETER = {
  ANOMALY: 'anomaly',
  STATIC: 'static',
  NO_DATA: 'noData',
};

export const OLD_SEVERITY_PARAMETER = {
  CRITICAL: 'CRITICAL',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
  INFO: 'INFO',
};

export const OLD_FEEDBACK_PARAMS = {
  POSITIVE_FEEDBACK: 'positiveFeedback',
  NEGATIVE_FEEDBACK: 'negativeFeedback',
  NO_FEEDBACK: 'noFeedback',
};

export const OLD_ORDER_DIRECTIONS_PARAMS = {
  DESC: 'desc',
  ASC: 'asc',
};

export const mapAlertConsoleOldToNewQueryParameters = (queryParams) => {
  const oldQueryParamsInRoute = Object.entries(queryParams).filter(
    (qp) => Object.values(OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS).indexOf(qp[0]) !== -1,
  );
  if (oldQueryParamsInRoute.length === 0) {
    return [];
  }

  let mappedQueryParams = Object.fromEntries(
    Object.entries(queryParams).filter(
      (qp) =>
        qp[1] &&
        (Object.values(ALERT_CONSOLE_QUERY_PARAMS_KEYS).indexOf(qp[0]) !== -1 ||
          Object.values(INVESTIGATION_PARAMS).indexOf(qp[0]) !== -1 ||
          [...Object.values(IMPACT_PARAMS1), ...Object.values(IMPACT_PARAMS2)].indexOf(qp[0]) !== -1),
    ),
  );

  const feedbackParams = Object.keys(
    Object.fromEntries(
      Object.entries(queryParams)?.filter((p) => p[1] && Object.values(OLD_FEEDBACK_PARAMS).includes(p[0])),
    ),
  );
  if (Array.isArray(feedbackParams)) {
    if (feedbackParams?.length === 1) {
      switch (feedbackParams[0]) {
        case OLD_FEEDBACK_PARAMS.POSITIVE_FEEDBACK:
          mappedQueryParams = {
            ...mappedQueryParams,
            [ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK]: FEEDBACK_OPTIONS.POSITIVE.value,
          };
          break;
        case OLD_FEEDBACK_PARAMS.NEGATIVE_FEEDBACK:
          mappedQueryParams = {
            ...mappedQueryParams,
            [ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK]: FEEDBACK_OPTIONS.NEGATIVE.value,
          };
          break;
        case OLD_FEEDBACK_PARAMS.NO_FEEDBACK:
          mappedQueryParams = {
            ...mappedQueryParams,
            [ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK]: FEEDBACK_OPTIONS.NONE.value,
          };
          break;
        default:
          break;
      }
    } else if (feedbackParams.length === 2) {
      mappedQueryParams = {
        ...mappedQueryParams,
        [ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK]: FEEDBACK_OPTIONS.ANY.value,
      };
    }
  }

  Object.values(oldQueryParamsInRoute).map((queryParamKey) => {
    switch (queryParamKey[0]) {
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.ACK:
        if (queryParamKey[1]) {
          if (queryParamKey[1] === OLD_ACK_PARAMETER.ACK) {
            mappedQueryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.ACK_BY] = '*';
          } else if (queryParamKey[1] === OLD_ACK_PARAMETER.NOACK) {
            mappedQueryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.NOT_ACK_BY] = '*';
          }
        }
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.ALERT_CONFIGURATION_IDS:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.ASSIGNEE:
        if (queryParamKey[1]) {
          mappedQueryParams = {...mappedQueryParams, [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ASSIGNEES]: queryParamKey[1]};
        }
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.CHANNELS:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.CONST_RANGE:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.LABELS:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.MAKE_ACK:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.NEGATIVE_FEEDBACK:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.NO_FEEDBACK:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.ORDER:
        if (queryParamKey[1]) {
          if (queryParamKey[1] === OLD_ORDER_DIRECTIONS_PARAMS.ASC) {
            mappedQueryParams = {...mappedQueryParams, [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ORDER]: ORDER_DIRECTIONS.ASC};
          } else if (queryParamKey[1] === OLD_ORDER_DIRECTIONS_PARAMS.DESC) {
            mappedQueryParams = {...mappedQueryParams, [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ORDER]: ORDER_DIRECTIONS.DESC};
          }
        }
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.POSITIVE_FEEDBACK:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.REF:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITIES:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.SEVERITY_VIEW:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.SORT:
        if (queryParamKey[1]) {
          let sortValue;
          if (queryParamKey[1] === OLD_SORT_PARAMETER.ASSIGNEE) {
            sortValue = ALERT_CONSOLE_COLUMN_HEADERS.ASSIGNEE.name;
          } else if (queryParamKey[1] === OLD_SORT_PARAMETER.SCORE) {
            sortValue = ALERT_CONSOLE_COLUMN_HEADERS.SCORE.name;
          } else if (queryParamKey[1] === OLD_SORT_PARAMETER.START_TIME) {
            sortValue = ALERT_CONSOLE_COLUMN_HEADERS.START_TIME.name;
          } else if (queryParamKey[1] === OLD_SORT_PARAMETER.UPDATE_TIME) {
            sortValue = ALERT_CONSOLE_COLUMN_HEADERS.UPDATE_TIME.name;
          }
          mappedQueryParams = {...mappedQueryParams, [ALERT_CONSOLE_QUERY_PARAMS_KEYS.SORT_BY]: sortValue};
        }
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.STATUS:
        if (queryParamKey[1]) {
          mappedQueryParams = {
            ...mappedQueryParams,
            [ALERT_CONSOLE_QUERY_PARAMS_KEYS.IS_OPEN]:
              queryParamKey[1] === OLD_ALERT_STATUS_PARAMETER.OPEN ? true : undefined,
          };
        }
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.SUBSCRIBERS:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.TRIGGER_IDS:
        break;
      case OLD_ALERT_CONSOLE_QUERY_PARAMS_KEYS.TYPES:
        if (queryParamKey[1]) {
          if (queryParamKey[1] === OLD_ALERT_TYPE_PARAMETER.ANOMALY) {
            mappedQueryParams = {...mappedQueryParams, [ALERT_CONSOLE_QUERY_PARAMS_KEYS.TYPES]: ALERT_TYPES.ANOMALY};
          } else if (queryParamKey[1] === OLD_ALERT_TYPE_PARAMETER.NO_DATA) {
            mappedQueryParams = {...mappedQueryParams, [ALERT_CONSOLE_QUERY_PARAMS_KEYS.TYPES]: ALERT_TYPES.NO_DATA};
          } else if (queryParamKey[1] === OLD_ALERT_TYPE_PARAMETER.STATIC) {
            mappedQueryParams = {...mappedQueryParams, [ALERT_CONSOLE_QUERY_PARAMS_KEYS.TYPES]: ALERT_TYPES.STATIC};
          }
        }
        break;
      default:
        break;
    }
  });

  return mappedQueryParams;
};
