// @flow

import React from 'react';
import Button, {COLORS as BUTTON_COLORS} from 'common/componentsV2/Button';
import connect from 'react-redux/es/connect/connect';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import ModalLoadingSpinner from 'common/componentsV2/modal/ModalLoadingSpinner';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import UsersGroupsSelect from 'admin.users/components/UsersGroupsSelect';
import {deleteAlertBulk, editAlertBulk} from 'alerts.management/store/actions';
import * as selector from 'alerts.management/store/selectors';
import AlertManagerFooterRecipients from './AlertManagerFooterRecipients';
import AlertManagerFooterTags from './AlertManagerFooterTags';
import AlertManagerFooterPause from './AlertManagerFooterPause';
import AlertManagerFooterMore from './AlertManagerFooterMore';

import './AlertManagerFooter.module.scss';

const USER_GROUPS_EXTRA_SELECT_PROPS = {
  closeMenuOnSelect: false,
  closeMenuOnScroll: false,
  automationId: 'setAlertOwnerBulkAssign',
  placeholder: 'Assign Owner',
  openMenuOnFocus: true,
};

type PropTypes = {
  editAlertBulk: Function,
  deleteAlertBulk: Function,
  selectedAlertsCheckbox: Array,
  alerts: Array,
  isLoadingBulk: boolean,
  isDeletingBulk: boolean,
};

export default connect(
  (state) => ({
    selectedAlertsCheckbox: selector.getSelectedAlertsCheckbox(state),
    alerts: selector.getAlertManagerHeadersList(state),
    isLoadingBulk: selector.isBulkLoading(state),
    isDeletingBulk: selector.getDeleteAlertBulkApiIsLoading(state),
  }),
  {
    editAlertBulk,
    deleteAlertBulk,
  },
)(
  class AlertManagerFooter extends React.PureComponent {
    props: PropTypes;

    state = {
      isModalOpen: false,
      isDeleteModalOpen: false,
      isAssignOwnerModalOpen: false,
      assignOwnerSelected: null,
    };

    onModalClickClose = () => {
      this.setState({isModalOpen: false});
    };

    authorization = (action) => {
      const {selectedAlertsCheckbox, alerts} = this.props;
      const selectedAlerts = [];
      selectedAlertsCheckbox.forEach((selected) => {
        const foundAlert = alerts.find((a) => a.id === selected);

        if (foundAlert) {
          selectedAlerts.push(foundAlert);
        }
      });
      let isAuthorized = true;
      selectedAlerts.forEach((alert) => {
        if (!alert.authorization[action]) {
          isAuthorized = false;
        }
      });
      return isAuthorized;
    };

    onRecipientsChange = (values, action) => {
      if (!this.authorization('delete')) {
        // originally was 'subscribe_unsubscribe'
        this.setState({isModalOpen: true});
        return;
      }
      this.props.editAlertBulk({
        values: values.map((value) => ({
          ...value,
          field: value.type === 'USERS' ? 'subscribers' : 'channels',
        })),
        action,
        fieldName: 'recipients',
      });
    };

    onTagsChange = (values, action) => {
      if (!this.authorization('delete')) {
        this.setState({isModalOpen: true});
        return;
      }
      this.props.editAlertBulk({values, action, fieldName: 'alertTags'});
    };

    onDeleteClick = () => {
      if (!this.authorization('delete')) {
        this.setState({isModalOpen: true});
        return;
      }
      this.setState({isDeleteModalOpen: true});
    };

    onPauseChange = (val) => {
      if (!this.authorization('pause_resume')) {
        this.setState({isModalOpen: true});
        return;
      }
      this.props.editAlertBulk({time: val.value, fieldName: 'paused'});
    };

    onMoreChange = (val) => {
      switch (val.value) {
        case 'resume':
          this.props.editAlertBulk({time: 'resume', fieldName: 'resumed'});
          break;
        case 'assignOwner':
          if (!this.authorization('delete')) {
            this.setState({isModalOpen: true});
            return;
          }
          this.setState({isAssignOwnerModalOpen: true});
          break;
        default:
      }
    };

    onCloseAssignOwnerModal = () => {
      this.setState({isAssignOwnerModalOpen: false, assignOwnerSelected: null});
    };

    onAssignOwnerChange = (item) => {
      this.setState({assignOwnerSelected: item});
    };

    handleAssignOwnerApplyChanges = () => {
      this.props.editAlertBulk({assignOwner: this.state.assignOwnerSelected});
      this.setState({isAssignOwnerModalOpen: false, assignOwnerSelected: null});
    };

    renderDeleteModal = () => (
      <Modal isOpen={this.state.isDeleteModalOpen} size={SIZES.SMALL} onClose={this.onClose} isCloseButtonHidden>
        <div styleName="modal-recipients">
          <h5>Delete Selected Alerts?</h5>
          <p>This action is permanent and cannot be undone.</p>
          <div styleName="modal-recipients-buttons">
            <Button
              colorSchema={BUTTON_COLORS.GRAY_400}
              text="Cancel"
              onClick={() => {
                this.setState({isDeleteModalOpen: false});
              }}
            />
            <Button
              colorSchema={BUTTON_COLORS.RED_500}
              text={`Delete ${this.props.selectedAlertsCheckbox.length} Alert(s)`}
              onClick={() => {
                this.props.deleteAlertBulk();
                this.setState({isDeleteModalOpen: false});
              }}
            />
          </div>
        </div>
      </Modal>
    );

    modalRef = (el) => {
      if (el) {
        el.select.focus();
      }
    };

    renderAssignOwnerModal = () => (
      <Modal
        isOpen={this.state.isAssignOwnerModalOpen}
        size={SIZES.SMALL}
        onClose={this.onCloseAssignOwnerModal}
        isCloseButtonHidden
        isNotCentered
        classStyle="modal-hidden"
      >
        <div styleName="assign-owner-modal">
          <UsersGroupsSelect
            onChange={this.onAssignOwnerChange}
            refSelectComponent={this.modalRef}
            value={this.state.assignOwnerSelected ? this.state.assignOwnerSelected.value : null}
            selectProps={USER_GROUPS_EXTRA_SELECT_PROPS}
            onMenuClose={this.onCloseAssignOwnerModal}
            menuFooterComponent={
              <FormDdlActionBar
                isVisible
                isApplyDisabled={!this.state.assignOwnerSelected}
                onApply={this.handleAssignOwnerApplyChanges}
              />
            }
          />
        </div>
      </Modal>
    );

    render() {
      return (
        <>
          {this.renderDeleteModal()}
          {this.renderAssignOwnerModal()}
          {this.props.isLoadingBulk || this.props.isDeletingBulk ? <ModalLoadingSpinner /> : null}
          <Modal isOpen={this.state.isModalOpen} onClose={this.onModalClickClose} size={SIZES.SMALL}>
            <div styleName="modal-content">
              <h5>Not Authorized</h5>
              <p>
                You cannot edit some or all of the selected alerts. Make sure you are the owner of the alerts you want
                to edit.
              </p>
              <Button colorSchema={BUTTON_COLORS.BLUE_500} text="Got It" onClick={this.onModalClickClose} />
            </div>
          </Modal>
          <AlertManagerFooterRecipients onApply={this.onRecipientsChange} />
          <AlertManagerFooterPause onApply={this.onPauseChange} />
          <AlertManagerFooterTags onApply={this.onTagsChange} />
          <div styleName="delete-button">
            <Button
              colorSchema={BUTTON_COLORS.TRANSPARENT}
              text="Delete"
              onClick={this.onDeleteClick}
              icon="icn-action16-delete"
            />
          </div>
          <AlertManagerFooterMore onApply={this.onMoreChange} />
        </>
      );
    }
  },
);
