// @flow
import React, {Fragment} from 'react';
import './EditCustomerForm.module.scss';
import {Field} from 'react-final-form';
import Input from 'common/componentsV2/Input';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';

const EditCustomerFormClientSettings = () => {
  return (
    <Fragment>
      <div styleName="title">Client Settings</div>
      <Field name="customer.settings.apiTokens.enabled">
        {({input}) => (
          <div styleName="row">
            <div styleName="label">API Tokens</div>
            <ToggleSwitch
              name={input.name}
              theme="blue"
              isChecked={input.value}
              onToggle={() => {
                input.onChange(!input.value);
              }}
            />
          </div>
        )}
      </Field>
      <Field name="customer.settings.bc.enabled">
        {({input}) => (
          <div styleName="row">
            <div styleName="label">Business Collector</div>
            <ToggleSwitch
              name={input.name}
              theme="blue"
              isChecked={input.value}
              onToggle={() => {
                input.onChange(!input.value);
              }}
            />
          </div>
        )}
      </Field>
      <Field name="customer.apiToken" parse={(v) => v}>
        {({input}) => (
          <div styleName="row" automationId="apiTokenInputContainer">
            <div styleName="label">API Token</div>
            <Input name={input.name} value={input.value} onChange={input.onChange} automationId="apiTokenInput" />
            <span styleName="sub">
              Editing the token may cause data loss for the customer. For new customers this can be left blank.
            </span>
          </div>
        )}
      </Field>
    </Fragment>
  );
};
export default EditCustomerFormClientSettings;
