import React, {useMemo} from 'react';
import {INVESTIGATION_MODAL_TABS} from 'investigation/services/investigationService';
import './OverwiewTab.module.scss';
import {useSelector} from 'react-redux';
import WhatInfoCube from './infoCubes/WhatInfoCube';
import WhereInfoCube from './infoCubes/WhereInfoCube';
import CorrelationsInfoCube from './infoCubes/CorrelationsInfoCube';
import AlertActionsCube from './infoCubes/AlertActionsCube';
import InfoCube from './infoCubes/InfoCube';
import {getInvModalTrigger} from '../../store/selectors';
import WhatInfoCubeStatic from './infoCubes/WhatInfoCubeStatic';
import WhereInfoCubeStatic from './infoCubes/WhereInfoCubeStatic';
import HowOftenCubeStatic from './infoCubes/HowOftenCubeStatic';
import WhatInfoCubeNoData from './infoCubes/WhatInfoCubeNoData';
import WhatWithDataStream from './infoCubes/WhatWithDataStream';

const getSteps = (alertType) => {
  switch (alertType) {
    case 'anomaly':
      return [
        {
          number: '1',
          id: 'what',
          title: 'What happened?',
          body: <WhatInfoCube />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '2',
          id: 'where',
          title: 'Where did it happen?',
          body: <WhereInfoCube />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '3',
          id: 'why',
          title: 'Why did it happen?',
          body: <CorrelationsInfoCube />,
          targetTab: INVESTIGATION_MODAL_TABS.correlations.id,
        },
        {
          number: '4',
          id: 'actions',
          title: 'What to do next?',
          body: <AlertActionsCube />,
        },
      ];
    case 'static':
      return [
        {
          number: '1',
          id: 'what',
          title: 'What happened?',
          body: <WhatInfoCubeStatic />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '2',
          id: 'where',
          title: 'Where did it happen?',
          body: <WhereInfoCubeStatic />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '3',
          id: 'why',
          title: 'How often does it happen?',
          body: <HowOftenCubeStatic />,
          targetTab: INVESTIGATION_MODAL_TABS.timeline.id,
        },
        {
          number: '4',
          id: 'actions',
          title: 'What to do next?',
          body: <AlertActionsCube />,
        },
      ];
    case 'noData':
      return [
        {
          number: '1',
          id: 'what',
          title: 'What happened?',
          body: <WhatInfoCubeNoData />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '2',
          id: 'where',
          title: 'Where did it happen?',
          body: <WhereInfoCubeStatic />,
          targetTab: INVESTIGATION_MODAL_TABS.incident.id,
        },
        {
          number: '3',
          id: 'why',
          title: 'What’s with the data stream?',
          body: <WhatWithDataStream />,
          targetTab: INVESTIGATION_MODAL_TABS.timeline.id,
        },
        {
          number: '4',
          id: 'actions',
          title: 'What to do next?',
          body: <AlertActionsCube />,
        },
      ];
    default:
      return [];
  }
};

const OverviewTab = ({
  onCubeClick,
  onShareClicked,
  onCloseBalloon,
}: {
  onCubeClick: Function,
  onShareClicked: Function,
  onCloseBalloon: Function,
}) => {
  const trigger = useSelector(getInvModalTrigger);
  const infoCubes = useMemo(
    () => (
      <>
        {getSteps(trigger.type).map((st) => (
          <InfoCube
            num={st.number}
            title={st.title}
            key={`overview-step-${st.number}`}
            isClickable={!!st.targetTab}
            onClick={() => (st.targetTab ? onCubeClick(st.targetTab) : null)}
            onShareClicked={st.id === 'actions' ? onShareClicked : null}
            onCloseBalloon={st.id === 'actions' ? onCloseBalloon : null}
          >
            {st.body}
          </InfoCube>
        ))}
      </>
    ),
    [trigger],
  );

  return <div styleName="overview-tab-content">{infoCubes}</div>;
};

export default OverviewTab;
