// @flow
import React from 'react';
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import './Input.module.scss';

const EMPTY_OBJECT = {};

type PropTypes = {
  id?: string,
  placeHolder: string,
  isDisabled: boolean,
  isInvalid: boolean,
  type: string,
  additionalProps?: Object,
  className?: String,
  value: string,
  onChange: Function,
  onBlur: Function,
  onFocus: Function,
  onKeyDown: Function,
  isAutoFocus: boolean,
  invalidMessage?: string,
  invalidMessageStyle?: Object,
  wrapInvalidMessage?: boolean,
  automationId: string,
  fullSize: Boolean,
  style?: Object,
  segmentClickEvent: Function,
  tabIndex: string,
  withIcon?: Boolean,
};

export default connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)(
  class Input extends React.PureComponent {
    props: PropTypes;

    static defaultProps = {
      additionalProps: EMPTY_OBJECT,
      className: '',
      invalidMessage: '',
      invalidMessageStyle: {},
      wrapInvalidMessage: false,
      style: EMPTY_OBJECT,
      withIcon: false,
      id: '',
    };

    handleFocus = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.props.segmentClickEvent({type: 'focus', automationId: this.props.automationId});
      if (this.props.onFocus) {
        this.props.onFocus(e);
      }
    };

    render() {
      const {
        placeHolder,
        isDisabled,
        isInvalid,
        additionalProps,
        isAutoFocus,
        className,
        invalidMessage,
        invalidMessageStyle,
        type,
        value,
        onChange,
        onBlur,
        onKeyDown,
        fullSize,
        automationId,
        wrapInvalidMessage,
        style,
        tabIndex,
        withIcon,
        id,
      } = this.props;

      return (
        <React.Fragment>
          <input
            {...additionalProps}
            type={type}
            className={className}
            styleName={[
              'andt-input',
              isInvalid ? 'invalid' : null,
              fullSize ? 'full-size' : null,
              withIcon ? 'with-icon' : null,
            ].join(' ')}
            style={style}
            placeholder={placeHolder}
            disabled={isDisabled}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={this.handleFocus}
            onKeyDown={onKeyDown}
            autoFocus={isAutoFocus}
            automation-id={automationId}
            tabIndex={tabIndex}
            id={id}
          />
          {isInvalid && invalidMessage && (
            <div
              style={invalidMessageStyle}
              styleName={['invalid-message', wrapInvalidMessage ? 'wrap' : null].join(' ')}
            >
              {invalidMessage}
            </div>
          )}
        </React.Fragment>
      );
    }
  },
);
