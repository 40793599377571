// @flow
import React, {useState} from 'react';
import {useField} from 'react-final-form';
import {Box, makeStyles} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import AnomalyChartsContainer from './AnomalyChartsContainer';
import PreviewSettingsHeader from './PreviewSettingsHeader';
import {TypographyBox} from '../../../../common/componentsV2/boxTools';
import {getMeasureNameAndExpression} from './helpers';
import EmptySearch from '../../../../common/componentsV2/EmptySearch';

const useStyles = makeStyles(() => ({
  previewWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 100,
    alignSelf: 'stretch',
  },
}));

const PreviewContainer = () => {
  const classes = useStyles();
  const [anomaliesData, setAnomaliesData] = useState([]);

  const {
    input: {value: dateRange},
  } = useField('dateRange');

  const {
    input: {value: simpleExpressionTree},
  } = useField('simpleExpressionTree');

  const {measureName} = getMeasureNameAndExpression(simpleExpressionTree);

  return (
    <Box className={classes.previewWrapper}>
      <TypographyBox
        sx={{borderBottom: `1px solid ${palette.gray[300]}`, pb: '10px', mb: '12px', mt: '-10px'}}
        variant="h2"
      >
        Showing out {anomaliesData[0]} of {anomaliesData[1]} Metrics
      </TypographyBox>
      <PreviewSettingsHeader />
      {measureName ? (
        <AnomalyChartsContainer dateRange={dateRange} onGetData={setAnomaliesData} />
      ) : (
        <EmptySearch errorExplanation="Select measure" />
      )}
    </Box>
  );
};

export default PreviewContainer;
