// @flow
import React, {useEffect, useState, useRef, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import Modal, {SIZES as MODAL_SIZES} from 'common/componentsV2/modal/Modal';
import {Cell, Column, Table} from 'fixed-data-table-2';
import Table1 from 'dataCollectors/components/tables/Table1';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';
import {getChannelsDynamicRoutingEnabled} from 'profile/store/selectors';
import {channelDynamicRoutingDataToTableFormat, CHANNELS_DYNAMIC_ROUTING_CSV_HEADERS} from 'assets/services/service';

import style from './CsvPreviewModal.module.scss';

type PropTypes = {
  previewData: Object,
  onClose: Function,
  isOpen: Boolean,
  title: String,
  subTitle: String,
  isLoading: Boolean,
  ownerName: String,
};

const MODAL_HEIGHT = 600;
const ROW_HEIGHT = 37;

const TextCell = (props: any) => (
  <Cell className="CsvPreviewModalCell">{props.rows[props.rowIndex][props.columnKey]}</Cell>
);

const CsvPreviewModal = (props: PropTypes) => {
  const [tableWidth, setTableWidth] = useState(0);
  const [rows, setRows] = useState([]);
  const tableContainer = useRef();
  const isChannelsDynamicRoutingEnabled = useSelector(getChannelsDynamicRoutingEnabled);

  const isChannelDynamicRoutingAsset = useMemo(() => {
    if (isChannelsDynamicRoutingEnabled && props.previewData?.rows?.length) {
      return (
        props.previewData.rows[0][0].toLowerCase() ===
        CHANNELS_DYNAMIC_ROUTING_CSV_HEADERS.DIMENSION_TAG_VALUE.toLowerCase()
      );
    }
    return false;
  }, [JSON.stringify(props.previewData?.rows)]);

  const channelsDynamicRoutingTable = useMemo(() => {
    if (isChannelDynamicRoutingAsset) {
      const table = channelDynamicRoutingDataToTableFormat(props.previewData?.rows);
      return table;
    }
    return undefined;
  }, [JSON.stringify(props.previewData?.rows)]);

  useEffect(() => {
    if (!isChannelDynamicRoutingAsset) {
      if (tableContainer?.current) {
        setTableWidth(tableContainer?.current.scrollWidth);
      }
    }
  }, [props.isLoading]);

  useEffect(() => {
    setRows(props.previewData?.rows || []);
    return () => {
      setRows([]);
    };
  }, [JSON.stringify(props.previewData?.rows)]);

  return (
    <Modal
      onClose={() => props.onClose()}
      isOpen={props.isOpen}
      size={MODAL_SIZES.MEDIUM}
      isCloseButtonHidden={false}
      isStatic
      height={MODAL_HEIGHT}
    >
      <div className={style.headerContainer}>
        <div className={style.headerTitle}>{props.title}</div>
        <div className={style.headerSubTitle}>
          {props.subTitle} | Created by: {props.ownerName}
        </div>
      </div>

      {props.isLoading ? (
        <div className={style.loader}>
          <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
        </div>
      ) : (
        <div className={style.tableContainer}>
          {isChannelsDynamicRoutingEnabled &&
          isChannelDynamicRoutingAsset &&
          channelsDynamicRoutingTable?.columnHeaders ? (
            <Table1
              columns={channelsDynamicRoutingTable.columnHeaders}
              data={channelsDynamicRoutingTable.rowsData}
              isGlobalSearch
              isSortable
              height={MODAL_HEIGHT - 100}
            />
          ) : (
            <TinyScrollBox
              width="100%"
              height={MODAL_HEIGHT - 115}
              css={{overflowY: 'auto', overflowX: 'hidden', flexShrink: 0}}
              ref={tableContainer}
            >
              <Table
                rowHeight={ROW_HEIGHT}
                rowsCount={rows.length}
                headerHeight={0}
                scrollLeft={0}
                scrollTop={0}
                isColumnResizing={false}
                width={tableWidth}
                height={rows.length * ROW_HEIGHT + 20}
              >
                {rows.map((row, index) => (
                  <Column
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    columnKey={index}
                    width={tableWidth / 2}
                    allowCellsRecycling
                    isResizable={false}
                    isReorderable={false}
                    fixed={false}
                    cell={<TextCell rows={rows} />}
                  />
                ))}
              </Table>
            </TinyScrollBox>
          )}
        </div>
      )}
    </Modal>
  );
};

export default CsvPreviewModal;
