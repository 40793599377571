// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import {palette} from 'app/styles/theme';
import {getBucketStartTimeEnabled} from 'profile/store/selectors';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS} from 'alerts.console.new/services/alertsConsoleService';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import AlertTriggersTableNewAlertConsole from './AlertTriggersTableNewAlertConsole';

import './AlertTriggerPanel.module.scss';

const AlertTriggerPanelNewAlertConsole = ({configurationAlert}: {configurationAlert: Object}) => {
  const startBucketMode = useSelector(getBucketStartTimeEnabled);
  const thirtyDaysTimestamp = Math.floor(Date.now() / 1000) - 60 * 60 * 24 * 30;

  const queryParamsAPI = {
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.BREACH_TIME_FROM]: thirtyDaysTimestamp,
    [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ALERT_CONFIGURATION_IDS]: configurationAlert.id,
    startBucketMode,
  };

  const triggeredAlertsResult = fetchTriggeredAlertsProvider(queryParamsAPI)?.useQuery();

  return (
    <div styleName="container">
      <div styleName="column column-1">
        {triggeredAlertsResult?.isLoading === false ? (
          <AlertTriggersTableNewAlertConsole
            triggers={triggeredAlertsResult?.data?.triggers}
            totalTriggers={triggeredAlertsResult?.data?.total}
            configurationAlertId={configurationAlert.id}
          />
        ) : (
          <div styleName="spinner">
            <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />
          </div>
        )}
      </div>
    </div>
  );
};

export default AlertTriggerPanelNewAlertConsole;
