// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getUniqueId} from 'common/utils/guid';
import {
  fetchGoogleGA4MetadataAdjust as fetchGoogleGA4MetadataAdjustAction,
  applyGA4TemplateOnSelectedStream as applyGA4TemplateOnSelectedStreamAction,
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import GaDimetric from './ga4Dimetric/GaDimetric';

import './DimensionsAndMetricsEditor.module.scss';

type PropTypes = {
  dataStream: Object,

  basedOnTemplate: string,

  selectedDimensions: Array,
  possibleDimensions: Array,
  dimensionsInputVal: string,

  selectedMetrics: Array,
  possibleMetrics: Array,
  metricsInputVal: string,

  fetchGoogleGA4MetadataAdjust: Function,
  applyGA4TemplateOnSelectedStream: Function,
  setSelectedStreamKeyVal: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    basedOnTemplate: selectors.getGoogleGA4StreamBaseTemplate(state),
    selectedDimensions: selectors.getSelectedGA4DataStreamDimensionsObj(state),
    possibleDimensions: selectors.getGoogleGA4MetaDimensions(state),
    dimensionsInputVal: state.bc.googleAnalyticsStream.extraUiState.dimensionsInputVal,
    selectedMetrics: selectors.getSelectedGA4DataStreamMetricsObj(state),
    possibleMetrics: selectors.getGoogleGA4MetaMetrics(state),
    metricsInputVal: state.bc.googleAnalyticsStream.extraUiState.metricsInputVal,
  }),
  {
    fetchGoogleGA4MetadataAdjust: fetchGoogleGA4MetadataAdjustAction,
    applyGA4TemplateOnSelectedStream: applyGA4TemplateOnSelectedStreamAction,
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
  },
)(
  class DimensionsAndMetricsEditor extends React.PureComponent {
    props: PropTypes;

    onClearAll = () => {
      const {dataStream, applyGA4TemplateOnSelectedStream} = this.props;
      applyGA4TemplateOnSelectedStream(dataStream.basedOnTemplateId);
    };

    addOrRemoveItem = (id, isMetric, isAdded) => {
      const {dataStream, possibleMetrics, possibleDimensions} = this.props;
      const res = {
        ...dataStream,
        dimensions: dataStream.dimensions.slice(),
        metrics: dataStream.metrics.slice(),
        schema: {
          columns: dataStream.schema.columns.slice(),
          sourceColumns: dataStream.schema.sourceColumns.slice(),
        },
      };

      if (!isAdded) {
        // remove exiting  diametric
        if (isMetric) {
          res.metrics.splice(res.metrics.indexOf(id), 1);
        } else {
          res.dimensions.splice(res.dimensions.indexOf(id), 1);
        }
        res.schema.columns = res.schema.columns.filter((item) => item.sourceColumn !== id);
        res.schema.sourceColumns = res.schema.sourceColumns.filter((item) => item.id !== id);
      } else if (isMetric) {
        // add a new diametric
        const selectedMetricObj = possibleMetrics.find((item) => item.apiName === id);
        res.metrics.push(selectedMetricObj.apiName);
        res.schema.columns.push({
          id: getUniqueId(),
          name: selectedMetricObj.uiName,
          type: 'metric',
          sourceColumn: selectedMetricObj.apiName,
        });
        res.schema.sourceColumns.push({
          id: selectedMetricObj.apiName,
          name: selectedMetricObj.uiName,
        });
      } else {
        const selectedDimensionObj = possibleDimensions.find((item) => item.apiName === id);
        res.dimensions.push(selectedDimensionObj.apiName);
        res.schema.columns.push({
          id: getUniqueId(),
          name: selectedDimensionObj.uiName,
          type: 'dimension',
          sourceColumn: selectedDimensionObj.apiName,
        });
        res.schema.sourceColumns.push({
          id: selectedDimensionObj.apiName,
          name: selectedDimensionObj.uiName,
        });
      }
      this.props.setSelectedStreamKeyVal(res); // update the stream

      const resAdjust = {
        selectedDimensions: res.dimensions.slice(),
        selectedMetrics: res.metrics.slice(),
        dataSourceId: dataStream.dataSourceId,
        propertyId: dataStream.propertyId,
        selectedTsField: dataStream.timestampField,
      };
      this.props.fetchGoogleGA4MetadataAdjust(resAdjust); // fetch updated meta
    };

    onAddMetric = (id) => this.addOrRemoveItem(id, true, true);

    onRemoveMetric = (id) => this.addOrRemoveItem(id, true, false);

    onAddDimension = (id) => this.addOrRemoveItem(id, false, true);

    onRemoveDimension = (id) => this.addOrRemoveItem(id, false, false);

    render() {
      const {possibleMetrics, selectedMetrics, possibleDimensions, selectedDimensions} = this.props;
      const {onClearAll, onAddMetric, onRemoveMetric, onAddDimension, onRemoveDimension} = this;

      return (
        <div styleName="root">
          <div styleName="dm-container">
            <div styleName="dm-panel">
              <GaDimetric
                {...{
                  possibleMetrics,
                  selectedMetrics,
                  possibleDimensions,
                  selectedDimensions,
                  onAddMetric,
                  onRemoveMetric,
                  onAddDimension,
                  onRemoveDimension,
                  onClearAll,
                }}
              />
              <a
                href="https://developers.google.com/analytics/devguides/reporting/core/dimsmets#mode=api"
                styleName="dm-copyright"
              >
                Taken from GA Reporting API v4
              </a>
            </div>
          </div>
        </div>
      );
    }
  },
);
