import {composeReducers} from 'common/utils/reducers';
import {
  createEventsReducer,
  createMetricsReducer,
  createMultipleEventsReducer,
} from 'alerts.console.new/store/reducers/consoleGroupReducerFactory';

const initialState = {};

export default composeReducers(
  (state = initialState) => state,
  {
    metrics: createMetricsReducer(),
  },
  {
    events: createEventsReducer(),
  },
  {
    events: createMultipleEventsReducer(),
  },
);
