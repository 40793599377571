import React, {useState} from 'react';
import Button, {COLORS} from 'common/componentsV2/Button';
import Input from 'common/componentsV2/Input';

type PropTypes = {
  onClose: Function,
  onOkClick: Function,
};

const InfluencingCompositeNameModal = ({onClose, onOkClick}: PropTypes) => {
  const [name, setName] = useState('');

  const onNameChange = (val) => {
    setName(val);
  };

  return (
    <div>
      <div className="fontWeight_300 mb_4 fontSize_22" automation-id="influencingMetricsCompositeExpressionTitle">
        Save Influencing Metrics Expression
      </div>
      <div>Name</div>
      <Input placeHolder="Set a unique name" value={name} onChange={(event) => onNameChange(event.target.value)} />
      <div className="display_flex justifyContent_flex-end mt_8-5">
        <div className="mr_1">
          <Button text="Cancel" colorSchema={COLORS.GRAY_300} onClick={onClose} />
        </div>
        <Button
          text="OK"
          isDisabled={name === ''}
          colorSchema={COLORS.BLUE_500}
          onClick={() => onOkClick(name)}
          automationId="metricExplorerCompositeSave"
        />
      </div>
    </div>
  );
};

export default InfluencingCompositeNameModal;
