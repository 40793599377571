// @flow
import React, {Fragment} from 'react';
import useV1LinkMaker from 'dashboards/hooks/useV1LinkMaker';
import {makeStyles} from '@material-ui/core';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import {Field, Form} from 'react-final-form';
import Button, {COLORS} from 'common/componentsV2/Button';
import AsyncButton from 'common/componentsV2/AsyncButton';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import FileLoader from 'common/componentsV2/FileLoader';
import {displayDashboardSnackBar, uploadDashboard} from 'dashboards/store/actions';
import {getUploadDashboard, getUploadDashboardIsLoading} from 'dashboards/store/selectors';
import {error} from 'common/utils/notifications/notificationsService';
import useAsyncAction from 'common/utils/useAsyncAction';

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 24,
  },
}));

const ImportModal = ({onClose}: {onClose: Function}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();
  const classes = useStyles();
  const isLoading = useSelector(getUploadDashboardIsLoading);
  const selectorGetUploadDashboard = useSelector(getUploadDashboard);
  const importFileAction = useAsyncAction(uploadDashboard, selectorGetUploadDashboard);

  const makeV1Link = useV1LinkMaker();

  const actionSubmit = ({file}) => {
    const reader = new FileReader();

    reader.onload = () => {
      // eslint-disable-next-line prefer-destructuring
      const result = JSON.parse(reader.result);
      importFileAction(file, {isV2: result.isV2}).then(
        () => {
          const response = getUploadDashboard(store.getState());
          if (response?.data?.passed === false) {
            error({title: 'Failed to import dashboard'});
            history.push(`/dashboards/`);
            return;
          }
          onClose();
          dispatch(displayDashboardSnackBar({title: 'Dashboard created successfully!'}));
          if (result.isV2) {
            history.push(`/dashboards/${response.data._id}`);
          } else {
            window.location.href = makeV1Link(response.data._id);
          }
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        },
      );
    };
    reader.readAsText(file.get('file'));
  };

  return (
    <Modal
      id="dashboard-import-modal"
      onClose={onClose}
      isOpen
      size={SIZES.MEDIUM}
      isAnimation={false}
      isCloseButtonHidden
    >
      <TypographyBox variant="h5" mb={1}>
        Import Dashboard
      </TypographyBox>
      <div className="fontSize14 lineHeight_18 mb_1-5">Only json files</div>
      <Form initialValues={{file: null}} onSubmit={actionSubmit}>
        {({handleSubmit, values}) => (
          <Fragment>
            <Field name="file">
              {({input: {onChange}}) => (
                <FileLoader fileType="application/json" errorMessageFileType="Only json files" onChange={onChange} />
              )}
            </Field>
            <div className={classes.buttonWrapper}>
              <div className="mr_1-5">
                <Button
                  colorSchema={COLORS.GRAY_300}
                  automationId="importDashboardCancel"
                  text="Cancel"
                  onClick={onClose}
                />
              </div>
              <AsyncButton
                isLoading={isLoading}
                onClick={handleSubmit}
                text="Import"
                automationId="importDashboard"
                isDisabled={isLoading || !values.file || typeof values.file.get('file') !== 'object'}
              />
            </div>
          </Fragment>
        )}
      </Form>
    </Modal>
  );
};

export default ImportModal;
