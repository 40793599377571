// @flow
import React from 'react';
import {connect} from 'react-redux';
// eslint-disable-next-line import/no-cycle
import {getTypeDetails} from 'bc/services/bcTypes';
import {getFormattedLLDate, getFormattedTime} from 'common/utils/dateService';
import {Modal} from 'react-bootstrap';
import HexagonBorder from 'common/components/hexagon/HexagonBorder';
import {getUsersData} from 'admin.users/store/selectors';
import {getTimeZoneName} from 'profile/store/selectors';
import {updateDataSource as updateDataSourceAction} from 'bc/store/actions';
import './DataSourceModal.module.scss';
import dsTypes from '../../../images/dsTypes';

type PropTypes = {
  isOpen: boolean,
  isContinueDisabled: boolean,
  isProcessing: boolean,
  isViewOnly: boolean,
  isShowName: boolean,
  isRenameEnabled: boolean,
  source: Object,
  sourceType: Object,
  onClose: Function,
  onContinue: Function,
  logoImageClass: String,
  children: any,

  // connect
  users: Array,
  timeZoneName: String,
  updateDataSource: Function,
};

export default connect(
  (state) => ({
    users: getUsersData(state),
    timeZoneName: getTimeZoneName(state),
  }),
  {
    updateDataSource: updateDataSourceAction,
  },
)(
  class DataSourceModal extends React.PureComponent {
    props: PropTypes;

    state = {
      sourceName: this.props.source ? this.props.source.name : '',
    };

    getCreatorModifierStrings = () => {
      const {source, isViewOnly, users, timeZoneName} = this.props;

      if (!isViewOnly || !source) {
        return null;
      }

      const {createTime, modifyTime, creator, modifier} = source;

      const userCreator = creator && users.length ? users.find((user) => user._id === creator) : null;
      const createdAt = `${getFormattedLLDate(createTime, timeZoneName)}. ${getFormattedTime(
        createTime,
        timeZoneName,
      )}`;
      const creatorString = userCreator
        ? `Created by ${userCreator.firstName} ${userCreator.lastName} on ${createdAt}`
        : `Created on ${createdAt}`;

      if (createTime === modifyTime) {
        return {
          creator: creatorString,
          modifier: '',
        };
      }

      const userModifier = modifier && users.length ? users.find((user) => user._id === modifier) : null;
      const modAt = `${getFormattedLLDate(modifyTime, timeZoneName)}. ${getFormattedTime(modifyTime, timeZoneName)}`;
      const modifierString = userModifier
        ? `Updated by ${userModifier.firstName} ${userModifier.lastName} on ${modAt}`
        : `Updated on ${modAt}`;

      return {
        creator: creatorString,
        modifier: modifierString,
      };
    };

    updateClicked = () => {
      const {updateDataSource, source, onClose} = this.props;
      updateDataSource({id: source.id, type: source.type, name: this.state.sourceName.trim()});
      onClose();
    };

    sourceNameChanged = (e) => {
      this.setState({
        sourceName: e.target.value,
      });
    };

    render() {
      const {
        isContinueDisabled,
        isProcessing,
        isOpen,
        isViewOnly,
        isShowName,
        isRenameEnabled,
        onClose,
        source,
        sourceType,
        onContinue,
        children,
        logoImageClass,
      } = this.props;

      const {sourceName} = this.state;
      const timeStrings = this.getCreatorModifierStrings();

      return (
        <div>
          <Modal show={isOpen} dialogClassName="bc data-source-modal" onHide={onClose}>
            <Modal.Header bsClass="bc-modal-header">
              <button type="button" className="btn btn-flat btn-icon-36 btn-secondary" onClick={onClose}>
                <i className="icon icn-icon-table-delete" />
              </button>
            </Modal.Header>

            <Modal.Body>
              <HexagonBorder styleName="hex-border">
                <img
                  alt="Source icon"
                  className={logoImageClass}
                  src={dsTypes[getTypeDetails(sourceType.type).icon.replace('.', '_')]}
                />
              </HexagonBorder>
              {isShowName !== false && <div styleName="source-type-name">{getTypeDetails(sourceType.type).name}</div>}
              {isViewOnly && timeStrings && (
                <div styleName="creator-section">
                  <div>{timeStrings.creator}</div>
                  <div>{timeStrings.modifier}</div>
                </div>
              )}
              <div styleName="rename-section">
                {isRenameEnabled && (
                  <input
                    type="text"
                    className={!sourceName || !sourceName.trim() ? 'invalid' : ''}
                    automation-id="dataCollectorRenameSourceNameTextbox"
                    onChange={this.sourceNameChanged}
                    placeholder="Source name"
                    value={sourceName}
                  />
                )}
              </div>

              {children}
            </Modal.Body>
            {!isViewOnly && (
              <Modal.Footer styleName="footer">
                <button
                  type="button"
                  className="btn btn-flat"
                  automation-id="dataCollectorSqlCloseButton"
                  onClick={onClose}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-raised"
                  automation-id="dataCollectorSqlContinueButton"
                  disabled={isContinueDisabled}
                  onClick={onContinue}
                  data-segment={`name: complete-authentication-${sourceType.id}, category: data-collectors`}
                >
                  CONTINUE {isProcessing ? <i className="icon ion-load-c spin" /> : ''}
                </button>
              </Modal.Footer>
            )}
            {isViewOnly && isRenameEnabled && source && (
              <Modal.Footer styleName="footer">
                <button
                  type="button"
                  className="btn btn-flat"
                  automation-id="dataCollectorSqlCloseButton"
                  onClick={onClose}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-raised"
                  automation-id="dataCollectorRenameButton"
                  disabled={!sourceName || !sourceName.trim() || sourceName.trim() === source.name}
                  onClick={this.updateClicked}
                  data-segment={`name: source-rename-${sourceType.id}, category: data-collectors`}
                >
                  RENAME
                </button>
              </Modal.Footer>
            )}
          </Modal>
        </div>
      );
    }
  },
);
