// @flow
import React from 'react';
import {isEqual} from 'lodash';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import * as selectors from 'bc/store/selectors';
import {connect} from 'react-redux';
import {fetchGoogleGA4MetadataAdjust as fetchGoogleGA4MetadataAdjustAction} from 'bc/store/actions';
import ConceptsPanel from './ConceptsPanel';

import './GaDimetric.module.scss';

type PropTypes = {
  dataStream: Object,
  adjustData: Object,
  possibleMetrics: Array,
  possibleDimensions: Array,
  selectedMetrics: Array,
  selectedDimensions: Array,
  onClearAll: Function,
  onAddMetric: Function,
  onRemoveMetric: Function,
  onAddDimension: Function,
  onRemoveDimension: Function,

  fetchGoogleGA4MetadataAdjust: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    adjustData: selectors.getGoogleGA4AdjustData(state),
  }),
  {
    fetchGoogleGA4MetadataAdjust: fetchGoogleGA4MetadataAdjustAction,
  },
)(
  class GaDimetric extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {dataStream} = this.props;
      const resAdjust = {
        selectedDimensions: dataStream.dimensions,
        selectedMetrics: dataStream.metrics,
        dataSourceId: dataStream.dataSourceId,
        propertyId: dataStream.propertyId,
        selectedTsField: dataStream.timestampField,
      };
      this.props.fetchGoogleGA4MetadataAdjust(resAdjust);
    }

    componentDidUpdate(prevProps) {
      const {dataStream} = this.props;

      if (
        !isEqual(prevProps.dataStream.metrics, dataStream.metrics) ||
        !isEqual(prevProps.dataStream.dimensions, dataStream.dimensions)
      ) {
        const resAdjust = {
          selectedDimensions: dataStream.dimensions,
          selectedMetrics: dataStream.metrics,
          dataSourceId: dataStream.dataSourceId,
          propertyId: dataStream.propertyId,
          selectedTsField: dataStream.timestampField,
        };
        this.props.fetchGoogleGA4MetadataAdjust(resAdjust);
      }
    }

    render() {
      const {
        possibleMetrics,
        possibleDimensions,
        selectedMetrics,
        selectedDimensions,
        onClearAll,
        onAddMetric,
        onRemoveMetric,
        onAddDimension,
        onRemoveDimension,
        adjustData,
        dataStream,
      } = this.props;

      let combinedDimensions = [];
      let combinedMetrics = [];

      if (adjustData) {
        const adjustDimensions = adjustData.dimensions.map((item) => item.apiName);
        const adjustMetrics = adjustData.metrics.map((item) => item.apiName);

        combinedDimensions = possibleDimensions.map((item) => ({
          ...item,
          selected: dataStream.dimensions.includes(item.apiName),
          disabled: !adjustDimensions.includes(item.apiName),
        }));
        combinedMetrics = possibleMetrics.map((item) => ({
          ...item,
          selected: dataStream.metrics.includes(item.apiName),
          disabled: !adjustMetrics.includes(item.apiName),
        }));
      }

      return (
        <div styleName="main">
          <PanelHeader title="Measures & Dimensions">
            <button type="button" className="btn btn-flat btn-blue" onClick={onClearAll}>
              Reset All
            </button>
          </PanelHeader>
          <div styleName="concepts">
            <ConceptsPanel
              title="Measures"
              possibleConcepts={combinedMetrics}
              selectedConcepts={selectedMetrics}
              onAddItem={onAddMetric}
              onRemoveItem={onRemoveMetric}
            />
            <div styleName="vr" />
            <ConceptsPanel
              title="Dimensions"
              possibleConcepts={combinedDimensions}
              selectedConcepts={selectedDimensions}
              onAddItem={onAddDimension}
              onRemoveItem={onRemoveDimension}
            />
          </div>
        </div>
      );
    }
  },
);
