// @flow
import React, {useState, useEffect, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import {Backdrop, Modal, makeStyles} from '@material-ui/core';
import {getAnonymousInvitation, revokeAnonymousInvitations} from 'dashboards/store/actions';
import {
  getAnonymousInvitation as getAnonymousInvitationSelector,
  revokeAnonymousInvitations as revokeAnonymousInvitationsSelector,
} from 'dashboards/store/selectors';
import Input from 'common/componentsV2/Input';
import Button, {COLORS} from 'common/componentsV2/Button';
import Checkbox from 'common/componentsV2/Checkbox';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import * as actions from 'common/store/actions';
import {getDisablePublicDashboards} from 'userSettings/store/selectors';
import {palette} from 'app/styles/theme';

type PropTypes = {onClose: Function, dashboardId: string};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 600,
    height: 424,
    backgroundColor: theme.palette.white[500],
    borderRadius: 6,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    padding: 24,
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
  },
  subTitle: {
    fontSize: 18,
    marginTop: 24,
  },
  text: {
    fontSize: 14,
    marginBottom: 12,
  },
  link: {
    height: 36,
    flexGrow: 1,
    marginRight: 8,
  },
  include: {
    fontSize: 16,
    fontWeight: 500,
    marginLeft: 4,
  },
  revoke: {
    fontSize: 16,
    fontWeight: 400,
  },
  revokeButton: {
    color: theme.palette.blue[500],
    cursor: 'pointer',
  },
  revokeIndicationSections: {
    display: 'flex',
    color: theme.palette.gray[500],
    height: 30,
  },
  revokeLoading: {
    position: 'relative',
    left: -6,
  },
  revokedIcon: {
    color: theme.palette.green[500],
    fontSize: 20,
    marginRight: 2,
  },
  revokedText: {
    lineHeight: '30px',
  },
  doneButton: {
    alignSelf: 'flex-end',
    marginTop: 'auto',
  },
}));

const ShareModal = ({onClose, dashboardId}: PropTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [shareFiltersAndSettings, setShareFiltersAndSettings] = useState(true);
  const [isRevokePristine, setIsRevokePristine] = useState(true);
  const anonymousInvitation = useSelector(getAnonymousInvitationSelector);
  const revokedAnonymousInvitations = useSelector(revokeAnonymousInvitationsSelector);
  const disablePublicDashboards = useSelector(getDisablePublicDashboards);

  const copyButtonClicked = useCallback((event, text) => {
    dispatch(actions.copyToClipBoard({event, text, description: 'Copied to clipboard!'}));
  }, []);

  const onRevokeAllClick = () => {
    setIsRevokePristine(false);
    dispatch(
      revokeAnonymousInvitations({
        optionId: dashboardId,
        keepInvitationId: get(anonymousInvitation, 'data.invitationId', ''),
      }),
    );
  };

  useEffect(() => {
    dispatch(getAnonymousInvitation({type: 'dashboard', id: dashboardId, options: {}}));
  }, []);

  const currentUrl = location.href.replace('/share', '') + location.search;
  const internalUrl = shareFiltersAndSettings ? currentUrl : currentUrl.split('?')[0];
  // eslint-disable-next-line max-len
  const publicUrl = `${internalUrl}${internalUrl.includes('?') ? '&' : '?'}invitationId=${get(
    anonymousInvitation,
    'data.invitationId',
    '',
  )}`;

  return (
    <Modal open BackdropComponent={Backdrop} onClose={() => onClose()}>
      <div automation-id="addGraphStatTileModal" className={classes.wrapper}>
        <div className={classes.title}>Share Dashboard</div>
        <div className={classes.subTitle}>Internal Share</div>
        <div className={classes.text}>Share this dashboard with others through Anodot (login required)</div>

        <div className="display_flex mb_4">
          <div className={classes.link}>
            <Input fullSize value={internalUrl} isDisabled />
          </div>
          <Button
            icon="icn-action16-copy"
            automationId="copyInternalUrl"
            onClick={(event) => copyButtonClicked(event, internalUrl)}
          />
        </div>

        {!disablePublicDashboards && (
          <>
            <div className={classes.subTitle}>Public Share</div>
            <div className={classes.text}>Share this dashboard with everyone, even outside of Anodot</div>

            <div className="display_flex mb_4">
              <div className={classes.link}>
                <Input fullSize value={publicUrl} isDisabled />
              </div>
              <Button
                isDisabled={!anonymousInvitation?.data}
                icon="icn-action16-copy"
                automationId="copyPublicUrl"
                onClick={(event) => copyButtonClicked(event, publicUrl)}
              />
            </div>
            <div className="display_flex alignItems_baseline justifyContent_space-between mt_1">
              <Checkbox
                automationId="includeCurrentFiltersAndSettings"
                isChecked={shareFiltersAndSettings}
                onChange={() => setShareFiltersAndSettings(!shareFiltersAndSettings)}
              >
                <div className={classes.include}>Include current filters & settings</div>
              </Checkbox>
              <div className={classes.revoke}>
                <div className={classes.revokeButton} onClick={onRevokeAllClick}>
                  Revoke all previous links
                </div>
                <div className={classes.revokeIndicationSections}>
                  {revokedAnonymousInvitations.isLoading && (
                    <div className={classes.revokeLoading}>
                      <Spinner color={palette.gray[500]} size={SIZES.SMALL_30} />
                    </div>
                  )}
                  {!isRevokePristine && !revokedAnonymousInvitations.isLoading && (
                    <div className="display_flex">
                      <span className={classes.revokedIcon}>
                        <i className="icon icn-general16-checkmark" />
                      </span>
                      <span className={classes.revokedText}>Revoked</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        <div className={classes.doneButton}>
          <Button automationId="shareDone" text="Done" colorSchema={COLORS.GRAY_300} onClick={onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default ShareModal;
