// @flow
import React, {useMemo, useRef} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {get, isEqual} from 'lodash';
import {Backdrop, Modal} from '@material-ui/core';
import {Form, useField} from 'react-final-form';
import PreviewSettingsSidebar from 'dashboards/components/metricTiles/anomaliesTile/PreviewSettingsSidebar';
import PreviewContainer from 'dashboards/components/metricTiles/anomaliesTile/PreviewContainer';
import useAsyncAction from 'common/utils/useAsyncAction';
import {updateDashboard} from 'dashboards/store/actions';
import {findFreeSpace, makeOtherTilePayload, makeUpdateTilePayload} from 'dashboards/utils';
import {emptyExpressionTree} from 'metrics/store/reducers/expressionBuilderReducer';
import ModalContainer from 'dashboards/components/modals/ModalContainer';
import {OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';
import useDashboardState from 'dashboards/hooks/useDashboardState';
import useAlertUpdateConfirmation from 'dashboards/hooks/useAlertUpdateConfirmation';
import {getUniqueId} from 'common/utils/guid';
import {makeTileDataCompatible} from 'dashboards/compatibility';
import {presavedParamsPath} from '../metricTiles/anomaliesTile/AnomalyChartsContainer';

type PropTypes = {onClose: Function, data: Object, layout: Object, dashboardId: string, type: String};

const emptySimpleExpressionTree = {
  id: getUniqueId(),
  expressionTree: emptyExpressionTree,
  isShowComposites: true,
};

const AddAnomalyModalContainer = ({onClose, data: tileData, layout, dashboardId, type}: PropTypes) => {
  const {
    params: {tileType},
  } = useRouteMatch('/dashboards/:id/:tileType');

  const data = useMemo(() => makeTileDataCompatible(tileData), [tileData]);
  const dashboardStateRef = useRef();
  const dashboard = useDashboardState(dashboardId);
  dashboardStateRef.current = dashboard;
  const isNew = type === OPTION_TILES_VALUES.NEW;
  const updateDashboardAsync = useAsyncAction(updateDashboard, dashboard);

  const presavedParams = get(tileData, presavedParamsPath, {});

  const {
    input: {value: dateRangeValue},
  } = useField('dateRange', {isEqual});

  const {
    input: {value: timeScaleValue},
  } = useField('timeScale');

  const {askForConfirmation, confirmationModalElement} = useAlertUpdateConfirmation({
    tileData: data,
    alerts: dashboard.data.tileAlerts,
    dashboard,
  });

  const handleAsyncSubmit = (formValues) =>
    askForConfirmation(formValues).then((isConfirmed) => {
      if (isConfirmed) {
        const tile = makeOtherTilePayload(
          data,
          {
            ...formValues,
            expressionTrees: [formValues.simpleExpressionTree],
          },
          findFreeSpace(layout, {x: data.sizeX, y: data.sizeY}),
          formValues,
          'anomaliesList',
        );
        return (
          updateDashboardAsync(makeUpdateTilePayload(dashboardStateRef.current.data, tile, data.id, layout))
            .then(() => {
              onClose();
            })
            // eslint-disable-next-line no-console
            .catch((error) => console.log(error))
        );
      }
      return null;
    });

  const initialValues = {
    tileType,
    title: get(data, 'title.text', ''),
    dateRange: dateRangeValue,
    show: Object.values(data.tileConfig.expressionTrees)[0].filter.parameters[0].value,
    sort: Object.values(data.tileConfig.expressionTrees)[0].filter.function,
    timeScale: timeScaleValue,
    expressionTreeType: 'simple',
    simpleExpressionTree: emptySimpleExpressionTree,

    /* Anomalies filter */
    deltaValue: 0,
    deltaType: 'absolute',
    durationUnits: 'minutes',
    durationValue: 5,
    score: 75,
    sortAnomalies: 'score',
    anomaliesAmount: '5',
    directionUp: true,
    directionDown: true,
    ...presavedParams,
  };

  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={onClose}>
      <div>
        <Form initialValues={initialValues} onSubmit={handleAsyncSubmit}>
          {({handleSubmit}) => (
            <React.Fragment>
              <ModalContainer
                isV2Modal
                title={`${isNew ? 'Add' : 'Edit'} Anomaly Tile`}
                settingContainer={PreviewSettingsSidebar}
                previewContainer={PreviewContainer}
                onClose={onClose}
                onSubmit={handleSubmit}
                isLoading={dashboard.isLoading}
              />
              {confirmationModalElement}
            </React.Fragment>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default AddAnomalyModalContainer;
