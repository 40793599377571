import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useForm, FormProvider} from 'react-hook-form';
import {Route} from 'react-router-dom';
import {useQueryParam, StringParam} from 'use-query-params';

import {getProfileId, getTimeZoneName} from 'profile/store/selectors';
import useFetchEventStreams, {useFetchEventStream} from 'dataCollectors/api/useFetchEventStreams';
import {useFetchSource} from 'dataCollectors/api/useFetchSources';
import {EventStreamProvider} from 'dataCollectors/hooks/useEventStreamContext';
import EventStreamEdit from 'dataCollectors/pages/eventStream/editPages/EditContainer';
import EventStreamPreview from 'dataCollectors/pages/eventStream/previewPages/PreviewContainer';
import useFormValidation from 'dataCollectors/pages/eventStream/hooks/useFormValidation';
import useDefaultValues from 'dataCollectors/pages/eventStream/hooks/useStreamDefaults';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import usePostFileUploadAnalyze from 'dataCollectors/api/usePostFileUploadAnalyze';

const {SOURCE_ID, STREAM_ID} = EVENT_STREAM_FIELDS;

const DataCollectors = ({match}) => {
  const [streamId] = useQueryParam('streamId', StringParam);
  const [sourceId] = useQueryParam('sourceId', StringParam);

  const {eventStreams} = useFetchEventStreams();

  const id = useSelector(getProfileId);
  const timeZone = useSelector(getTimeZoneName);

  const {eventStream, isEventStreamLoading, isEventStreamFetching, isEventStreamSuccess} = useFetchEventStream(
    streamId,
  );
  const {source, isSourceLoading, isSourceFetching, isSourceSuccess} = useFetchSource(
    eventStream?.dataSourceId || sourceId,
  );
  const {analyzeData} = usePostFileUploadAnalyze(source?.type, sourceId, eventStream?.fileFormat || {});

  const defaultValues = useDefaultValues(eventStream, source, {id, timeZone}, analyzeData);
  // Please note that on initial opening of this component, analyze data is fetched after form is initialized
  // Therefore, form data is also updated in FileUpload component
  // we still need this here for prefetch of sources or stream

  const methods = useForm({
    defaultValues,
    resolver: (currentStream) => useFormValidation(currentStream, eventStreams),
    shouldFocusError: true,
  });

  // Update the form for the 1st time and after garbage collecting.
  useEffect(() => {
    if (isSourceSuccess || isEventStreamSuccess) {
      methods.reset(defaultValues);
    }
  }, [isEventStreamLoading, isSourceLoading]);

  // Edit mode, Update the form only when form have'nt received data.
  useEffect(() => {
    const streamIdInForm = methods.getValues(STREAM_ID.id);

    if (streamId && !streamIdInForm) {
      methods.reset(defaultValues);
    }
  }, [isEventStreamFetching]);

  // New mode, Update the form only when form have'nt received data.
  useEffect(() => {
    const sourceNameInForm = methods.getValues(SOURCE_ID.id);

    if (sourceId && !sourceNameInForm) {
      methods.reset(defaultValues);
    }
  }, [isSourceFetching]);

  return (
    <>
      <EventStreamProvider>
        <FormProvider {...methods}>
          <Route path={`${match.url}/event-stream/preview`} render={() => <EventStreamPreview />} />
          <Route path={`${match.url}/event-stream/edit`} render={() => <EventStreamEdit />} />
        </FormProvider>
      </EventStreamProvider>
    </>
  );
};

DataCollectors.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DataCollectors;
