import React from 'react';
import {connect} from 'react-redux';
import {components} from 'react-select2';
import SelectAndt, {
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {
  getIsLookupPreviewDataLoading,
  getLookupPreviewDataNoHeaders,
  getFetchLookupTablesData,
} from 'bc/store/selectors';
import {getAlertConfigurationsItems, getAlertConfigurationsIsLoading} from 'alerts.management/store/selectors';
import * as actions from 'assets/store/actions';
import * as bcActions from 'bc/store/actions';
import {fetchAlertConfigurations as fetchAlertConfigurationsAction} from 'alerts.management/store/actions';
import {segmentClickEvent} from 'common/store/actions';
import * as api from 'assets/services/api';
import {prepareFile, TYPES} from 'assets/services/service';
import {getDownloadRequest, uploadLookup} from 'bc/services/lookupService';
import DeleteFile from 'assets/components/actions/DeleteFile';

type PropTypes = {
  file: Object,
  openFileInModal: Function,
  // connect
  deleteLookupTable: Function,
  setFileUploadProgress: Function,
  setFileUploadName: Function,
  setUploadedFileUrl: Function,
  setUploadFileError: Function,
  setDeleteLookupError: Function,
  setDownloadLookupError: Function,
  segmentCausingEvent: Function,
  updateDynamicRoutingFile: Function,
  deleteDynamicRoutingFile: Function,
  viewDynamicRoutingFile: Function,
  lookupPreviewData: Object,
  isLookupPreviewDataLoading: Boolean,
  viewDynamicRoutingFileData: Object,
  viewDynamicRoutingFileIsLoading: Boolean,
  lookupTablesData: Array<Object>,
  displaySnackBar: Function,
  segmentClickEvent: Function,
  updateLookupTable: Function,
};

const classes = {
  main: {
    position: 'absolute',
    right: 9,
    top: 7,
  },
  dropdownOptionIcon: {
    paddingRight: 10,
    position: 'relative',
    top: 2,
  },
  buttonLikeDropdown: {
    fontSize: 22,
    fontWeight: '400',
    padding: '7px 16px 0 0',
    backgroundColor: 300,
    position: 'relative',
    cursor: 'pointer',
  },
};

const moreOptions = [
  {value: 'view', label: 'View', icon: 'icn-general16-show'},
  {value: 'update', label: 'Update', icon: 'icn-action16-upload'},
  {value: 'download', label: 'Download', icon: 'icn-action16-download'},
  {value: 'delete', label: 'Delete', icon: 'icn-action16-delete'},
];

export default connect(
  (state) => ({
    lookupTablesData: getFetchLookupTablesData(state),
    lookupPreviewData: getLookupPreviewDataNoHeaders(state),
    isLookupPreviewDataLoading: getIsLookupPreviewDataLoading(state),
    alertConfigurationsItems: getAlertConfigurationsItems(state),
    alertConfigurationsIsLoading: getAlertConfigurationsIsLoading(state),
  }),
  {
    deleteLookupTable: bcActions.deleteLookupTable,
    setFileUploadProgress: bcActions.setFileUploadProgress,
    setFileUploadName: bcActions.setFileUploadName,
    setUploadedFileUrl: bcActions.setUploadedFileUrl,
    setUploadFileError: bcActions.setUploadFileError,
    setDeleteLookupError: bcActions.setDeleteLookupError,
    setDownloadLookupError: bcActions.setDownloadLookupError,
    updateDynamicRoutingFile: actions.updateDynamicRoutingFile,
    deleteDynamicRoutingFile: actions.deleteDynamicRoutingFile,
    displaySnackBar: actions.displaySnackBar,
    updateLookupTable: bcActions.updateLookupTable,
    fetchAlertConfigurations: fetchAlertConfigurationsAction,
    segmentClickEvent,
  },
)(
  class TableItemActions extends React.PureComponent {
    props: PropTypes;

    state = {
      isConfirmationMessageOpen: false,
    };

    segmentTracking = (name) => {
      this.props.segmentClickEvent({
        category: 'Asset Management - context',
        name,
      });
    };

    uploadFileError = () => {
      this.props.setUploadFileError();
      this.props.setFileUploadName('');
    };

    uploadFileSuccess = () => {
      this.props.setFileUploadName();
      this.props.setUploadedFileUrl({});
    };

    updateFile = (e) => {
      const {
        file: {id, type, title},
        lookupTablesData,
        setFileUploadProgress,
        setFileUploadName,
        setUploadedFileUrl,
        updateDynamicRoutingFile,
        displaySnackBar,
        updateLookupTable,
      } = this.props;
      const file = prepareFile(e, title);

      if (type === TYPES.LOOKUP && file.errorCode) {
        displaySnackBar({type: 'failure', errorCode: file.errorCode});
      } else if (type === TYPES.LOOKUP && !file.errorCode) {
        const item = lookupTablesData.find((i) => i.id === id);
        const folder = item.fileName.split('/')[0];
        setFileUploadProgress(0);
        setFileUploadName(file.data.name);
        setUploadedFileUrl({});
        uploadLookup(
          file.data,
          this.uploadFileSuccess,
          this.uploadFileError,
          setFileUploadProgress,
          folder,
          displaySnackBar,
          () => updateLookupTable(id),
        );
      } else if (type === TYPES.DYNAMIC_ROUTING) {
        updateDynamicRoutingFile({file, id});
      }
    };

    downloadFile = () => {
      const {
        file: {id, type, title},
        lookupTablesData,
      } = this.props;

      if (type === TYPES.LOOKUP) {
        const item = lookupTablesData.find((i) => i.id === id);
        const folder = item.fileName.split('/')[0];
        const file = item.fileName.split('/')[1];
        getDownloadRequest(file, () => {}, () => {}, folder);
      } else if (type === TYPES.DYNAMIC_ROUTING) {
        api.downloadFile(id).promise.then((data) => {
          const byteArray = new Uint8Array(data);
          const a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/octet-stream'}));
          a.download = title;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      }
    };

    setIsConfirmationMessageOpen = (state) => {
      if (state) {
        this.setState({isConfirmationMessageOpen: state});
      } else {
        this.setState((prevState) => ({isConfirmationMessageOpen: !prevState.isConfirmationMessageOpen}));
      }
    };

    onChange = (selection) => {
      switch (selection.value) {
        case 'view':
          this.props.openFileInModal();
          this.segmentTracking('view');
          break;
        case 'download':
          this.downloadFile();
          this.segmentTracking('download');
          break;
        case 'delete':
          this.setIsConfirmationMessageOpen(true);
          this.segmentTracking('delete');
          break;
        case 'update':
          document.getElementById(`fileInput_${this.props.file.id}`).click();
          this.segmentTracking('update');
          break;
        default:
      }
    };

    render() {
      const {isConfirmationMessageOpen} = this.state;
      const {
        file: {id, type, title},
      } = this.props;

      return (
        <div style={classes.main}>
          <input
            onChange={this.updateFile}
            id={`fileInput_${id}`}
            type="file"
            style={{display: 'none'}}
            placeholder=""
          />
          <SelectAndt
            type={TYPE_SIMPLE}
            theme={THEME_TRANSPARENT}
            options={moreOptions}
            onChange={this.onChange}
            direction={DIRECTION_LEFT}
            menuWidth={200}
            automationId="assetsMoreOptions"
            customComponent={{
              Option: (p) => (
                <components.Option {...p}>
                  <div>
                    <div style={classes.dropdownOptionIcon} className={['icon', p.data.icon].join(' ')} />
                    {p.children}
                  </div>
                </components.Option>
              ),
              DropdownIndicator: (p) => (
                <i {...p.innerProps} style={classes.buttonLikeDropdown} className="icon icn-general16-3dot" />
              ),
            }}
          />
          <DeleteFile
            fileName={title}
            id={id}
            type={type}
            IsConfirmationMessageOpen={isConfirmationMessageOpen}
            onSetIsConfirmationMessageOpen={this.setIsConfirmationMessageOpen}
          />
        </div>
      );
    }
  },
);
