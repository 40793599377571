import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {post} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {setCleanStream} from 'dataCollectors/services/eventStreamService';

const root = 'api/v1/bc/';

const postStreamEventsPreview = (stream) => {
  const cleanStream = setCleanStream(stream);
  return post(`${root}event-streams/preview`, cleanStream);
};

export default function usePostStreamEventsPreview(stream) {
  const selectFn = useCallback(
    (res) => {
      const columns = [];
      let data = [];

      if (res?.events?.length) {
        const headers = res.events[0];

        Object.keys(headers).forEach((key) => {
          if (typeof headers[key] === 'object') {
            // properties
            Object.keys(headers[key]).forEach((innerKey) => {
              columns.push({
                title: innerKey,
                id: innerKey,
                editable: true,
              });
            });
          } else {
            columns.push({
              title: key,
              id: key,
            });
          }
        });

        data = res.events.map((i) => {
          const obj = {};
          Object.keys(i).forEach((key) => {
            if (typeof i[key] === 'object') {
              // properties
              Object.keys(i[key]).forEach((innerKey) => {
                obj[innerKey] = i[key][innerKey];
              });
            } else {
              obj[key] = i[key];
            }
          });
          return obj;
        });
      }

      return {columns, data};
    },
    [stream],
  );

  const fallback = {
    columns: [],
    data: [],
  };

  const {
    data: tablePreviewTransformData = fallback,
    refetch: tablePreviewTransformRefetch,
    isLoading: tablePreviewTransformIsLoading,
    isFetching: tablePreviewTransformIsFetching,
    isSuccess: tablePreviewTransformIsSuccess,
    isError: tablePreviewTransformIsError,
    error: tablePreviewTransformError,
  } = useQuery(QUERY_KEYS.tablePreviewTransform, () => postStreamEventsPreview(stream), {
    enabled: false,
    select: selectFn,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: 0,
    keepPreviousData: false,
  });

  return {
    tablePreviewTransformData,
    tablePreviewTransformRefetch,
    tablePreviewTransformIsLoading,
    tablePreviewTransformIsFetching,
    tablePreviewTransformIsSuccess,
    tablePreviewTransformIsError,
    tablePreviewTransformError,
  };
}
