import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {getSelectedDataStreamDvp} from 'bc/store/selectors';
import {
  DVP,
  getUnitFormatted,
  getValueFormated,
} from 'bc/components/streams/editor/missingDataSection/missingDataService';
import styles from './ViewStreamSummaryModalV2.module.scss';

const DVPData = () => {
  const dvpConfig = useSelector(getSelectedDataStreamDvp);

  const gaugeValue = dvpConfig?.gaugeValue?.value !== undefined ? dvpConfig?.gaugeValue?.value : 'Not set';
  const counterValue = dvpConfig?.counterValue?.value !== undefined ? dvpConfig?.counterValue?.value : 'Not set';

  return (
    <div className={styles.dvpSection}>
      <div className={styles.dvpTitle}>Fill Missing Data</div>
      {dvpConfig && (
        <div>
          <div>
            <span className={styles.dvpLabel}>In case of no data</span> {DVP.dvpConfig.options[1].label}
          </div>

          <div>
            <span className={styles.dvpLabel}>Fill data duration</span>
            <span>
              {getValueFormated(dvpConfig.maxDVPDurationHours)} {getUnitFormatted(dvpConfig.maxDVPDurationHours).label}
            </span>
          </div>
          <div>
            <span className={styles.dvpLabel}>Fill Gauge measures</span>
            {dvpConfig?.gaugeValue?.keepLastValue ? DVP.gaugeKeepLastValue.options[0].label : gaugeValue}
          </div>
          <div>
            <span className={styles.dvpLabel}>Fill counter measures </span>
            {dvpConfig?.counterValue?.keepLastValue ? DVP.counterKeepLastValue.options[1].label : counterValue}
          </div>
        </div>
      )}
      {!dvpConfig && (
        <Fragment>
          <div>
            <span className={styles.dvpLabel}>In case of no data</span> <span>{DVP.dvpConfig.options[0].label}</span>
          </div>
        </Fragment>
      )}
    </div>
  );
};
export default DVPData;
