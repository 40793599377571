// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setFileUploadProgress, setFileUploadName, setUploadedFileUrl, setUploadFileError} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {uploadFile, abortFileUpload} from 'bc/services/fileUploadToAwsService';
import './FileUploadToAws.module.scss';
import ProgressModal from './ProgressModal';

export default connect(
  (state, ownProps) => ({
    sourceType: ownProps.sourceType,
    fileName: selectors.getFileUploadName(state),
    progress: selectors.getFileUploadProgress(state),
  }),
  {
    setFileUploadProgress,
    setFileUploadName,
    setUploadedFileUrl,
    setUploadFileError,
  },
)(
  class FileUploadToAws extends React.PureComponent {
    props: {
      sourceType: Object,
      setFileUploadProgress: Function,
      setFileUploadName: Function,
      setUploadedFileUrl: Function,
      setUploadFileError: Function,
      text?: string,
      automationId?: string,
      fileName: String,
      progress: Number,
    };

    static defaultProps = {
      text: 'START',
      automationId: '',
    };

    uploadFileError = (/* err */) => {
      this.props.setUploadFileError();
      this.props.setFileUploadName('');
    };

    onChange = (e) => {
      if (e.target.files.length) {
        let {uploadPath} = this.props.sourceType;
        uploadPath = uploadPath[0] === '/' ? uploadPath.slice(1) : uploadPath;

        const file = e.target.files[0];
        this.props.setFileUploadProgress(0);
        this.props.setFileUploadName(file.name);
        this.props.setUploadedFileUrl({});
        uploadFile(
          file,
          this.props.sourceType.uploadBucket,
          uploadPath,
          this.props.setUploadedFileUrl,
          this.uploadFileError,
          this.props.setFileUploadProgress,
        );
      }
    };

    clicked = () => {
      document.getElementById('fileInput').click();
    };

    render() {
      const {automationId} = this.props;
      return (
        <React.Fragment>
          <form styleName="root">
            <span
              role="presentation"
              onClick={this.clicked}
              automation-id={automationId || `sourceTypeActionButton-${this.props.sourceType.name}`}
            >
              {this.props.text}
            </span>
            <input
              id="fileInput"
              type="file"
              onChange={this.onChange}
              automation-id={automationId || `sourceTypeActionInput-${this.props.sourceType.name}`}
            />
          </form>
          {this.props.fileName ? (
            <ProgressModal
              onClose={() => {}}
              onAbort={abortFileUpload}
              fileName={this.props.fileName}
              progress={this.props.progress}
              isOpen={this.props.fileName !== ''}
            />
          ) : null}
        </React.Fragment>
      );
    }
  },
);
