import {cloneDeep} from 'lodash';
import * as actions from './actions';
import {composeReducers} from '../utils/reducers';

export function reducer(state = {}, {type}) {
  switch (type) {
    case actions.appInit.TYPE:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function outstandingLoadingBarRequests(state = {}, {type, payload}) {
  switch (type) {
    case actions.addLoading.TYPE:
      return {...state, [payload.typePrefix]: true};
    case actions.removeLoading.TYPE:
      return {...state, [payload.typePrefix]: false};
    default:
      return state;
  }
}

export function configurationsData(
  state = {
    configurationFile: [],
  },
  {type, payload, meta},
) {
  switch (type) {
    case actions.fetchConfigurationFile.success.TYPE: {
      const configurationFileIndex = state.configurationFile.findIndex((ele) => ele.name === meta);
      if (configurationFileIndex === -1) {
        return {
          ...state,
          configurationFile: [...state.configurationFile, {name: meta.name, data: payload}],
        };
      }
      const newConfiguration = cloneDeep(state.configurationFile);
      newConfiguration[configurationFileIndex].data = payload;
      return {
        ...state,
        configurationFile: newConfiguration,
      };
    }
    default:
      return state;
  }
}

export function notificationsData(
  state = {
    notifications: [],
  },
  {type, payload},
) {
  switch (type) {
    case actions.logNotification.TYPE: {
      return {
        ...state,
        notifications: [payload, ...state.notifications],
      };
    }
    case actions.clearNotifications.TYPE: {
      return {
        ...state,
        notifications: [],
      };
    }
    default:
      return state;
  }
}

export default composeReducers(reducer, {outstandingLoadingBarRequests}, {configurationsData}, {notificationsData});
