import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {Alert} from 'anodot-objects-models';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const getResponseIndex = (payload, state) => state.items.findIndex((item) => item.data.id === payload);

const setAlertData = (originalAlert, payload) => ({
  ...originalAlert,
  pausedBy: payload.pausedBy,
  pausedTime: payload.pausedTime,
  pausedId: payload.pausedId,
  data: {
    ...originalAlert.data,
    paused: payload.data.paused,
    pauseTime: payload.data.pauseTime,
  },
});

const setAlertDataSubscribed = (originalAlert, payload) => ({
  ...originalAlert,
  data: {
    ...originalAlert.data,
    subscribers: payload.subscribers,
  },
});

const dataReducer = composeReducers(
  {
    fetchAlertConfigurations: composeReducers(
      makeAsyncReducer(actions.fetchAlertConfigurations, {
        defaultData: EMPTY_ARRAY,
        dataProp: 'items',
        includeUpdateAt: true,
        shouldDestroyData: false,
      }),
      (state = EMPTY_OBJECT, {type, payload, meta}) => {
        switch (type) {
          case actions.pauseAlert.TYPE:
          case actions.resumeAlert.TYPE: {
            const index = getResponseIndex(payload.id, state);
            return {
              ...state,
              items: reduceArrayItem(setAlertData, state.items, index, payload.newState),
            };
          }
          case actions.pauseAlert.failure.TYPE:
          case actions.resumeAlert.failure.TYPE: {
            const index = getResponseIndex(meta?.body?.data?.id, state);
            return {
              ...state,
              items: index !== -1 ? reduceArrayItem(setAlertData, state.items, index, meta.body) : state.items,
            };
          }
          case actions.subscribeToAlert.TYPE:
          case actions.unsubscribeToAlert.TYPE: {
            const index = getResponseIndex(payload.id, state);
            return {
              ...state,
              items: reduceArrayItem(setAlertDataSubscribed, state.items, index, payload.newState),
            };
          }
          case actions.subscribeToAlert.failure.TYPE:
          case actions.unsubscribeToAlert.failure.TYPE: {
            const index = getResponseIndex(meta?.body?.data?.id, state);
            return {
              ...state,
              items:
                index !== -1 ? reduceArrayItem(setAlertDataSubscribed, state.items, index, meta.body) : state.items,
            };
          }
          case actions.clearAlertFromList.TYPE: {
            const index = getResponseIndex(payload, state);
            return {
              ...state,
              items: [...state.items.slice(0, index), ...state.items.slice(index + 1)],
            };
          }
          case actions.deleteAlertBulkApi.success.TYPE: {
            const deletedAlertIds = meta;
            const newItems = state.items.filter((item) => {
              const itemId = item.data.id;
              const isItemIdInDeletedIds = deletedAlertIds.findIndex((id) => id === itemId) > -1;
              return !isItemIdInDeletedIds;
            });
            return {
              ...state,
              items: newItems,
            };
          }
          case actions.editAlertBulkApi.success.TYPE: {
            const newItems = [];
            state.items.forEach((item) => {
              const itemId = item.data.id;
              const itemInMeta = meta.body.find((ele) => ele.id === itemId);
              const itemInPayload = payload.find((ele) => ele.id === itemId);
              if (itemInMeta && itemInPayload.success) {
                const newItem = {
                  ...item,
                  ...(itemInMeta.tag || {}),
                  data: {
                    ...item.data,
                    ...itemInMeta.data,
                  },
                };
                newItems.push(newItem);
              } else {
                newItems.push({...item});
              }
            });
            return {
              ...state,
              items: newItems,
            };
          }
          case actions.createAlert.success.TYPE: {
            if (payload && payload.event) {
              const alert = new Alert(payload.event);
              return {
                ...state,
                items: [...state.items, alert.exportClientFormat()],
              };
            }
            return state;
          }
          default:
            return state;
        }
      },
    ),
    fetchTriggeredAlertsByConfiguration: composeReducers(
      makeAsyncReducer(actions.fetchTriggeredAlertsByConfiguration, {
        defaultData: EMPTY_ARRAY,
        dataProp: 'items',
        includeUpdateAt: true,
        shouldDestroyData: false,
      }),
      (state = {}, {type}) => {
        switch (type) {
          default:
            return state;
        }
      },
    ),
    executeEstimation: composeReducers(
      makeAsyncReducer(actions.executeEstimation, {
        defaultData: EMPTY_ARRAY,
        includeUpdateAt: true,
        shouldDestroyData: true,
      }),
      (state = {}, {type}) => {
        switch (type) {
          default:
            return state;
        }
      },
    ),
    getVolumeCondition: composeReducers(makeAsyncReducer(actions.getVolumeCondition, {defaultData: null})),
    fetchAlertsConfigurationTotal: composeReducers(
      makeAsyncReducer(actions.fetchAlertsConfigurationTotal, {defaultData: null}),
    ),
    editAlertBulkApi: composeReducers(
      makeAsyncReducer(actions.editAlertBulkApi, {
        defaultData: null,
      }),
      (state = {}, {type, meta}) => {
        switch (type) {
          // overide in order to cancel the bulkEdit blanket
          case actions.editAlertBulkApi.TYPE: {
            return {...state, isLoading: !meta.noBulkScreen}; // bulkEdit blanket is activated if isLoading = true
          }
          default:
            return state;
        }
      },
    ),
    deleteAlertBulkApi: composeReducers(
      makeAsyncReducer(actions.deleteAlertBulkApi, {
        defaultData: null,
      }),
      (state = {}, {type, meta}) => {
        switch (type) {
          // overide in order to cancel the bulkEdit blanket
          case actions.deleteAlertBulkApi.TYPE: {
            return {...state, isLoading: !meta.noBulkScreen}; // bulkEdit blanket is activated if isLoading = true
          }
          default:
            return state;
        }
      },
    ),
    fetchDashboard: composeReducers(makeAsyncReducer(actions.fetchDashboard, {defaultData: null})),
    fetchSimulationData: composeReducers(makeAsyncReducer(actions.fetchSimulationData, {defaultData: null})),
    fetchTags: composeReducers(makeAsyncReducer(actions.getAlertsTags, {defaultData: EMPTY_OBJECT})),
    fetchDataStreamMetricsCountApiCall: composeReducers(
      makeAsyncReducer(actions.fetchDataStreamMetricsCountApiCall, {defaultData: null}),
    ),
    fetchInfluencingMetricsInfo: composeReducers(
      makeAsyncReducer(actions.fetchInfluencingMetricsInfo, {defaultData: null}),
    ),
    fetchAlert: makeAsyncReducer(actions.fetchAlert, {defaultData: null, shouldSpread: true}),
    updateAlert: makeAsyncReducer(actions.updateAlert, {defaultData: null, shouldSpread: true}),
    createNewAlert: makeAsyncReducer(actions.createNewAlert, {defaultData: null, shouldSpread: true}),
    executeAlertSimulation: (state = {data: {metrics: []}}, {type, payload, meta}) => {
      switch (type) {
        case actions.executeAlertSimulation.TYPE:
          return {
            ...state,
            isLoading: true,
            isLoadMore: payload.index,
          };
        case actions.executeAlertSimulation.success.TYPE:
          return {
            isLoading: false,
            data: {
              ...payload,
              metrics: meta.index ? [...state.data.metrics, ...payload.metrics] : payload.metrics,
            },
          };
        default:
          return state;
      }
    },
  },
  (state = {}, {type}) => {
    switch (type) {
      default:
        return state;
    }
  },
);

export default dataReducer;
