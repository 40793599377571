import React, {useEffect, useState, useCallback} from 'react';
import {useFormContext} from 'react-hook-form';
import PropTypes from 'prop-types';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import useIsMount from 'dataCollectors/hooks/useIsMount';
import useFetchSchemas from 'dataCollectors/api/useFetchSchemas';
import useFetchTables from 'dataCollectors/api/useFetchTables';
import InfoBox from 'dataCollectors/components/infoBox/InfoBox';
import PreviewTable from 'dataCollectors/components/previewTable/PreviewTable';
import useSectionsClasses from 'dataCollectors/styles/classes.useSectionsClasses';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';

const {TYPE, SOURCE_ID, SCHEMA_NAME, TABLE_NAME} = EVENT_STREAM_FIELDS;

const {sections} = useSectionsClasses();

export default function StreamTable({info, leftSideWidth}) {
  const sectionsClasses = sections({leftSideWidth});

  const isMount = useIsMount();

  const {
    setValue,
    getValues,
    formState: {errors, touchedFields},
  } = useFormContext();

  const values = getValues();

  const {schemas, isSchemasLoading} = useFetchSchemas(values[TYPE.id], values[SOURCE_ID.id]);

  const {tables, isTablesLoading, refetchTablesFetching} = useFetchTables(
    values[TYPE.id],
    values[SOURCE_ID.id],
    values[SCHEMA_NAME.id],
  );

  const [selectedItemSchemas, setSelectedItemSchemas] = useState(null);
  const [selectedItemTables, setSelectedItemTables] = useState(null);
  const [refetchTablePreview, setRefetchTablePreview] = useState(false);

  const setSchema = useCallback((item) => {
    setValue(SCHEMA_NAME.id, item.value, {shouldTouch: true});
  }, []);

  const setTable = useCallback((item) => {
    setValue(TABLE_NAME.id, item.value, {shouldTouch: true});
  }, []);

  useEffect(() => {
    const selectedIndex = schemas.findIndex((i) => i.value === values[SCHEMA_NAME.id]);
    setSelectedItemSchemas(schemas[selectedIndex]);
  }, [schemas, values[SCHEMA_NAME.id]]);

  useEffect(() => {
    const selectedIndex = tables.findIndex((i) => i.value === values[TABLE_NAME.id]);
    setSelectedItemTables(tables[selectedIndex]);
  }, [tables, values[TABLE_NAME.id]]);

  useEffect(() => {
    if (touchedFields[SCHEMA_NAME.id] && !isMount) {
      setValue(TABLE_NAME.id, null);
    }
    if (touchedFields[SCHEMA_NAME.id]) {
      refetchTablesFetching();
    }
  }, [values[SCHEMA_NAME.id]]);

  useEffect(() => {
    if (values[TYPE.id] && values[SOURCE_ID.id] && values[SCHEMA_NAME.id] && values[TABLE_NAME.id]) {
      setRefetchTablePreview(!refetchTablePreview);
    }
  }, [values[TABLE_NAME.id]]);

  return (
    <div className={sectionsClasses.root}>
      <div className={sectionsClasses.container}>
        <div className={sectionsClasses.left}>
          <div className={sectionsClasses.title}>Table</div>
          <div className={sectionsClasses.subTitle}>
            Choose the table containing the data you want to load as events.
          </div>

          <div className={sectionsClasses.inputsWrapper}>
            <div className={`${sectionsClasses.container} ${sectionsClasses.marginTop}`}>
              <div className={`${sectionsClasses.wrapperHalfRow} ${sectionsClasses.marginRight}`}>
                <div className={sectionsClasses.label}>Schemas</div>
                <FormDdlSelect
                  isDisabledSorting
                  options={schemas}
                  selected={selectedItemSchemas}
                  onChange={setSchema}
                  button={
                    <BigDropdownButton
                      isPristine={!selectedItemSchemas}
                      label={selectedItemSchemas?.label}
                      placeholder={isSchemasLoading ? 'Loading..' : 'Select'}
                      shortBlueStyle
                      isFullWidth
                    />
                  }
                  optionComponent={<OptionComponentSimple />}
                  width={240}
                />
                {errors[SCHEMA_NAME.id] && !selectedItemSchemas && (
                  <span className={sectionsClasses.inputError}>{errors[SCHEMA_NAME.id]}</span>
                )}
              </div>

              <div className={`${sectionsClasses.wrapperHalfRow} ${sectionsClasses.marginLeft}`}>
                <div className={sectionsClasses.label}>Table</div>
                <FormDdlSelect
                  disabled={!selectedItemSchemas}
                  isDisabledSorting
                  isUseSearch
                  options={tables}
                  selected={selectedItemTables}
                  onChange={setTable}
                  button={
                    <BigDropdownButton
                      isPristine={!selectedItemTables}
                      label={selectedItemTables?.label}
                      placeholder={isTablesLoading ? 'Loading..' : 'Select'}
                      shortBlueStyle
                      isFullWidth
                    />
                  }
                  optionComponent={<OptionComponentSimple />}
                  width={240}
                />
                {errors[TABLE_NAME.id] && !selectedItemTables && (
                  <span className={sectionsClasses.inputError}>{errors[TABLE_NAME.id]}</span>
                )}
              </div>
            </div>
            <PreviewTable reftechPreviewTable={refetchTablePreview} leftSideWidth={leftSideWidth} />
          </div>
        </div>
        {info && <InfoBox title={info.title} body={info.body} />}
      </div>
    </div>
  );
}

StreamTable.defaultProps = {
  info: null,
  leftSideWidth: '100%',
};

StreamTable.propTypes = {
  info: PropTypes.objectOf(PropTypes.string),
  leftSideWidth: PropTypes.number,
};
