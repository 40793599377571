import {createSelector} from 'reselect';
import {selectors as commonSelectors} from 'common';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const tabs = [
  {
    id: 1,
    label: 'Events Sources',
  },
  {
    id: 2,
    label: 'Events Categories',
  },
];

export const {getUserEvents} = commonSelectors;

export const getUserEventsData = createSelector(
  getUserEvents,
  (ue) => ue.data,
);

export const getUserEventsExecute = createSelector(
  getUserEventsData,
  (ue) => ue.execute,
);

export const getUserMultipleEventsExecute = createSelector(
  getUserEventsData,
  (ue) => ue.executeMultipleEvents,
);

export const getEventsCount = createSelector(
  getUserEventsData,
  (ue) => ue.fetchEventsCount,
);

export const getMetricDimensions = createSelector(
  getUserEventsData,
  (ue) => ue.fetchMetricDimensions || EMPTY_OBJECT,
);

export const getMetricDimensionsData = createSelector(
  getMetricDimensions,
  (md) => md.data || EMPTY_OBJECT,
);

export const getMetricDimensionsProperties = createSelector(
  getMetricDimensionsData,
  (mdd) => mdd.properties?.properties || EMPTY_ARRAY,
);

//* ** categories
export const getUserEventsCategories = createSelector(
  getUserEventsData,
  (ueData) => ueData.eventsCategories,
);

export const getUserEventsCategoriesIsLoading = createSelector(
  getUserEventsCategories,
  (uec) => uec.isLoading !== false,
);

export const getUserEventsCategoriesItems = createSelector(
  getUserEventsCategories,
  (uec) => uec.items,
);

export const getCreateUserEventCategory = createSelector(
  getUserEventsCategories,
  (i) => i.newUserEventCategory.isLoading,
);

export const getDeleteUserEventsCategory = createSelector(
  getUserEventsCategories,
  (i) => i.deleteUserEventsCategory.isLoading,
);

//* ** sources
export const getUserEventsSources = createSelector(
  getUserEventsData,
  (ueData) => ueData.eventSources,
);
export const getUserEventsSourcesIsLoading = createSelector(
  getUserEventsSources,
  (i) => i.isLoading,
);
export const getUserEventsSourcesItems = createSelector(
  getUserEventsSources,
  (i) => i.items,
);

export const getCreateUserEventsSource = createSelector(
  getUserEventsSources,
  (i) => i.addUserEventSource.isLoading,
);

export const getUserEventsView = createSelector(
  getUserEvents,
  (ue) => ue.views || EMPTY_OBJECT,
);

export const getEventsExpressionBuilder = createSelector(
  getUserEventsView,
  (ueView) => ueView.eventsExpressionBuilderReducer || EMPTY_OBJECT,
);

export const getFetchPropAndVal = createSelector(
  getUserEventsData,
  (ueData) => ueData.fetchPropAndValListApi || EMPTY_OBJECT,
);

export const getFetchPropsApi = createSelector(
  getUserEventsData,
  (ueData) => ueData.fetchPropsApi || EMPTY_OBJECT,
);

export const getMainPanelOptions = createSelector(
  getEventsExpressionBuilder,
  (eeB) => eeB.firstPanel || EMPTY_ARRAY,
);

export const getProps = createSelector(
  getEventsExpressionBuilder,
  (eeB) => eeB.eventsProps || EMPTY_ARRAY,
);

export const getSecondaryPanelOptions = createSelector(
  getEventsExpressionBuilder,
  (eeB) => eeB.secondPanel || EMPTY_ARRAY,
);

export const getExpression = createSelector(
  getEventsExpressionBuilder,
  (eeB) => eeB.expression || EMPTY_ARRAY,
);

export const getEventsProperties = createSelector(
  getEventsExpressionBuilder,
  (eeB) => {
    const ret = eeB.eventsProperties?.propertyValues;
    if (ret) {
      return ret.map((p) => p.key) || EMPTY_ARRAY;
    }
    return EMPTY_ARRAY;
  },
);

export const getEvents = createSelector(
  getEventsExpressionBuilder,
  (eeB) => eeB.events || EMPTY_ARRAY,
);

export const getMultipleEvents = createSelector(
  getEventsExpressionBuilder,
  (eeB) => eeB.multipleEvents || EMPTY_ARRAY,
);

export const getMultipleEventsFilters = createSelector(
  getEventsExpressionBuilder,
  (eeB) => eeB.multipleEventsFilters || EMPTY_ARRAY,
);

export const getEventsResolution = createSelector(
  getEventsExpressionBuilder,
  (eeB) => eeB.eventsResolution || null,
);

export const getShouldShowEvents = createSelector(
  getEventsExpressionBuilder,
  (eeB) => eeB.shouldShowEvents || false,
);

export const getDateRange = createSelector(
  getEventsExpressionBuilder,
  (eeB) => eeB.dateRange || EMPTY_OBJECT,
);

export const getAggregation = createSelector(
  getEventsExpressionBuilder,
  (eeB) => eeB.aggregation || null,
);

export const getExpressionForExecute = createSelector(
  getExpression,
  (expression) => {
    if (expression && expression.length) {
      return expression.map((item) => item.getExpressionTreeObjectification());
    }
    return EMPTY_ARRAY;
  },
);

export const getFetchPropIsLoading = createSelector(
  getFetchPropsApi,
  (props) => props.isLoading,
);

export const getIsFetchPropAndValApiListLoading = createSelector(
  getFetchPropAndVal,
  (propAndVal) => propAndVal.isLoading,
);
