import {createSelector} from 'reselect';
import {selectors as commonSelectors} from 'common';
import {get} from 'lodash';
import {selectors as profileSelector} from 'profile';

export const {getRecommendations} = commonSelectors;

export const getIsFiltersBarOpen = createSelector(
  profileSelector.getMeAppSettings,
  (appSettings) => get(appSettings, 'recommendations.isFiltersBarOpen', true),
);
