// flow
import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import {Transition} from 'react-transition-group';
import {getNumLastDays} from 'insightsPanelNewAlertConsole/store/selectors';
import ColumnChart from 'charts/columnChart/ColumnChart';
import {fadeOpacity} from 'insightsPanelNewAlertConsole/services/constants';
import {getConstRangeByNumberOfLastDays} from 'insightsPanelNewAlertConsole/api/utils';
import fetchTriggeredAlertsCountByProvider from 'insightsPanelNewAlertConsole/api/fetchTriggeredAlertsCountByProvider';

import './AlertsTriggered.module.scss';

const AlertsTriggered = ({title}: {title: String}) => {
  const numLastDays = useSelector(getNumLastDays);
  const [showData, setShowData] = useState(false);

  const constRange = useMemo(() => {
    return getConstRangeByNumberOfLastDays(numLastDays?.value)?.constRange;
  }, [numLastDays?.value]);

  const triggeredAlertsProvider = fetchTriggeredAlertsCountByProvider({constRange}).useQuery();
  const triggeredAlertsData = triggeredAlertsProvider?.data?.resultByDay;
  const isLoading = triggeredAlertsProvider?.isLoading;

  useEffect(() => {
    if (triggeredAlertsData) {
      setShowData(true);
    }
  }, [triggeredAlertsData]);

  const columnTooltipFormatter = {
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      return `<div class="column-chart-tooltip">${this.y} Alerts Triggered<br/> ${moment
        // eslint-disable-next-line react/no-this-in-sfc
        .unix(Object.keys(triggeredAlertsData)[this.point.index])
        .format('MMM, D')}</div>`;
    },
  };

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && triggeredAlertsData && (
        <Transition in={showData} timeout={fadeOpacity.duration}>
          {(state) => (
            <>
              <header styleName="header" style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}>
                {title}
              </header>
              <div styleName="container" style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}>
                <ColumnChart
                  id="insightsPanelAlertsTriggered"
                  height={90}
                  width="100%"
                  data={triggeredAlertsData}
                  tooltipFormatter={columnTooltipFormatter}
                />
              </div>
            </>
          )}
        </Transition>
      )}
    </div>
  );
};

export default AlertsTriggered;
