import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/';

const getDatasets = async (type, sourceId, projectId) => {
  return get(`${root}data-sources/${type}/${sourceId}/datasets?projectId=${projectId}`);
};

export default function useFetchDatasets(type, sourceId, projectId) {
  const fallback = [];

  const selectFn = useCallback(
    (data) => {
      return data.map((i) => ({...i, value: i.id, label: i.id}));
    },
    [sourceId],
  );

  const {
    data: datasets = fallback,
    isLoading: isDatasetsLoading,
    isFetching: isDatasetsFetching,
    refetch: refetchDatasetsFetching,
  } = useQuery(QUERY_KEYS.datasets, () => getDatasets(type, sourceId, projectId), {
    enabled: !!(type && sourceId && projectId),
    select: selectFn,
  });

  return {datasets, isDatasetsLoading, isDatasetsFetching, refetchDatasetsFetching};
}
