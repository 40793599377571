// @flow
/* eslint-disable complexity */
import {createSelector} from 'reselect';
import * as usersSelectors from 'admin.users/store/selectors';
import {getEnableEventsStreams} from 'profile/store/selectors';
import {selectors as commonSelectors} from 'common';
import {selectors as profileSelectors} from 'profile';
import {get, groupBy, isEmpty, isEqual, union, uniqBy} from 'lodash';
import {getUniqueId} from 'common/utils/guid';
import {getPageNameSegment} from 'common/utils/http';
import {getFormattedDateTime} from 'common/utils/dateService';
import {getAlertWarningLevel, getAlertWarningMessage} from 'alerts.management/services/alertsService';
import {getAccessGroups, accessGroupsShortedNames} from 'admin.permissions/services/accessListService';
import {DIMENSIONS_ARR as googleSearchDimensions} from 'bc/services/gsearchService';
import {DATE_FORMATS} from '../services/fuService';
import {getTypeDetails} from '../services/bcTypes';
import {DEFAULT_QUERY_PARAMS, getStreamChip, STATUS} from '../services/dataManagerService';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const {getPermissions} = commonSelectors;

// AccessList
export const getAccessList = createSelector(
  getPermissions,
  (permissions) => permissions.accessList,
);

export const getAccessListAllSourcesData = createSelector(
  getAccessList,
  (accessList) => accessList.fetchAccessListForAllSources.data,
);

// end of AccessList

const getOwner = (item, users, groups = []) => {
  const foundUser = users.find((user) => user._id === item.ownerId || user.email === item.owner);
  const foundGroup = groups.find((group) => group.id === item.ownerId);
  let owner = foundUser ? `${foundUser.firstName} ${foundUser.lastName}` : 'Unknown';
  owner = foundGroup ? foundGroup.name : owner;
  return owner;
};

const getStreamOwnerName = (ownerId, users) => {
  const owner = ownerId && users.length ? users.find((user) => user._id === ownerId) : '';
  if (owner) {
    return `${owner.firstName} ${owner.lastName}`;
  }
  return 'Unknown';
};

export const {getBusinessCollectors} = commonSelectors;
export const {getRoutingLocation} = commonSelectors;

export const {
  getOrganizationSettingsBC,
  getPageVisited,
  getIsLookupEnabled,
  getOrganizationFeatureFlags,
  getTimeZoneName,
} = profileSelectors;

export const isBcReadOnlyPermision = createSelector(
  profileSelectors.isReadOnlyUser,
  profileSelectors.isCustomerUser,
  (isReadOnlyUser, isCustomerUser) => isReadOnlyUser || isCustomerUser,
);

//* ** bc views: viewStreamSummaryModal
export const getBcViews = createSelector(
  getBusinessCollectors,
  (bc) => bc.views,
);

export const getViewStreamSummaryModal = createSelector(
  getBcViews,
  (bcViews) => bcViews.viewStreamSummaryModal || EMPTY_OBJECT,
);

export const getViewStreamSummaryModalTimeDefinition = createSelector(
  getViewStreamSummaryModal,
  (summaryModal) => summaryModal.timeDefinition || EMPTY_OBJECT,
);

export const getStreamSummaryTimeDefintionIsLoading = createSelector(
  getViewStreamSummaryModalTimeDefinition,
  (summaryTimeDefinition) => summaryTimeDefinition.isLoading !== false,
);

export const getStreamSummaryTimeDefintionTimeColumn = createSelector(
  getViewStreamSummaryModalTimeDefinition,
  (summaryTimeDefinition) => summaryTimeDefinition.timeColumn || null,
);

export const getStreamSummaryTimeDefintionTimePattern = createSelector(
  getViewStreamSummaryModalTimeDefinition,
  (summaryTimeDefinition) => summaryTimeDefinition.timePattern || null,
);

export const getStreamSummaryTimeDefintionTimeZone = createSelector(
  getViewStreamSummaryModalTimeDefinition,
  (summaryTimeDefinition) => summaryTimeDefinition.timeZone || null,
);

export const getViewStreamSummaryAssets = createSelector(
  getViewStreamSummaryModal,
  (summaryModal) => summaryModal.assets,
);

export const getViewStreamSummaryAssetsStreamId = createSelector(
  getViewStreamSummaryAssets,
  (assets) => assets.streamId,
);

export const getViewStreamSummaryAlertsAssets = createSelector(
  getViewStreamSummaryAssets,
  (assets) => assets.alerts,
);

export const getViewStreamSummaryAlertsAssetsItems = createSelector(
  usersSelectors.getUsersData,
  usersSelectors.getGroupsDataList,
  getViewStreamSummaryAlertsAssets,
  (users, groups, alertAssets) =>
    alertAssets.items.map((item) => ({
      id: item.id,
      title: item.title,
      owner: getOwner(item, users, groups),
      severity: item.severity,
      warningLevel: getAlertWarningLevel(item.validation),
      warningMessage: getAlertWarningMessage(item.validation),
    })),
);

export const getViewStreamSummaryDashboardsAssets = createSelector(
  getViewStreamSummaryAssets,
  (assets) => assets.dashboards,
);

export const getViewStreamSummaryDashboardsAssetsItems = createSelector(
  usersSelectors.getUsersData,
  usersSelectors.getGroupsDataList,
  getViewStreamSummaryDashboardsAssets,
  (users, groups, dashboardAssets) =>
    dashboardAssets.items.map((item) => ({
      id: item.id,
      title: item.title,
      owner: getOwner(item, users, groups),
      version: 'v1',
    })),
);

export const getViewStreamSummaryDashboardsV2Assets = createSelector(
  getViewStreamSummaryAssets,
  (assets) => assets.dashboardsV2,
);

export const getViewStreamSummaryDashboardsV2AssetsItems = createSelector(
  usersSelectors.getUsersData,
  usersSelectors.getGroupsDataList,
  getViewStreamSummaryDashboardsV2Assets,
  (users, groups, dashboardsV2Assets) =>
    dashboardsV2Assets.items.map((item) => ({
      id: item.id,
      title: item.title,
      owner: getOwner(item, users, groups),
      version: 'v2',
    })),
);

export const getViewStreamSummaryAllDashboardsAssetsItems = createSelector(
  getViewStreamSummaryDashboardsAssetsItems,
  getViewStreamSummaryDashboardsV2AssetsItems,
  (dashItems, dashV2Items) => [...dashItems, ...dashV2Items],
);

export const getViewStreamSummaryCompositesAssets = createSelector(
  getViewStreamSummaryAssets,
  (assets) => assets.composites,
);

export const getViewStreamSummaryCompositesAssetsItems = createSelector(
  usersSelectors.getUsersData,
  getViewStreamSummaryCompositesAssets,
  (users, compositeAssets) =>
    compositeAssets.items.map((item) => ({
      id: item.id,
      title: item.title,
      owner: getOwner(item, users),
    })),
);

export const getViewStreamSummaryAssetsIsLoading = createSelector(
  getViewStreamSummaryAlertsAssets,
  getViewStreamSummaryCompositesAssets,
  getViewStreamSummaryDashboardsAssets,
  (alertAssets, compositeAssets, dashboardAssets) =>
    alertAssets.isLoading || compositeAssets.isLoading || dashboardAssets.isLoading,
);

export const getStreamPossibleOwnersList = createSelector(
  usersSelectors.getUsersData,
  (users) => {
    if (!users || !users.length) {
      return EMPTY_ARRAY;
    }

    return users
      .filter((user) => user.roles[0] === 'customer-admin')
      .map((item) => ({
        id: item._id,
        email: item.email,
        name: `${item.firstName} ${item.lastName}`,
      }))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  },
);

//* **data source types
export const getDataSourceTypes = createSelector(
  getBusinessCollectors,
  (bc) => bc.dataSourceTypes,
);

export const getDataSourceTypesItemsIsLoading = createSelector(
  getDataSourceTypes,
  (dataSourceTypes) => dataSourceTypes.types.isLoading,
);

export const getDataSourceTypesItems = createSelector(
  getDataSourceTypes,
  (dataSourceTypes) => dataSourceTypes.types.data || [],
);

export const getIntegrationPageConfig = createSelector(
  getDataSourceTypesItems,
  commonSelectors.getConfigurationsData,
  (sourceTypes, configurationsData) => {
    const integrationPageConfig = (configurationsData.find((item) => item.name === 'integrationsConfig') || {}).data;
    if (!integrationPageConfig || !sourceTypes.length) {
      return undefined;
    }

    const newSourceItems = ((integrationPageConfig.sources || {}).sourcesItems || []).map((sItem) => {
      const findSource = sourceTypes.find((src) => sItem.type === src.type) || {};
      return {...findSource, ...sItem};
    });
    return {
      ...integrationPageConfig,
      sources: {
        ...integrationPageConfig.sources,
        sourcesItems: newSourceItems,
      },
    };
  },
);

export const getDataSourceTypesFilterText = createSelector(
  getDataSourceTypes,
  (dataSourceTypes) => dataSourceTypes.filterTextActual,
);

export const getIntegrationdDataSourceTypes = createSelector(
  getDataSourceTypes,
  (dataSourceTypes) => dataSourceTypes.integrationTypes,
);

export const getIntegrationdDataSourceTypesCategories = createSelector(
  getIntegrationdDataSourceTypes,
  (integrationTypes) => integrationTypes.data.categories,
);

export const getIntegrationdDataSourceTypesItems = createSelector(
  getDataSourceTypesItems,
  getIntegrationdDataSourceTypes,
  getOrganizationFeatureFlags,
  (bcTypes, integrationTypes, featureFlags) => {
    const tempArr = [];
    (integrationTypes.data.items || []).forEach((item) => {
      const bcTypeFound = bcTypes.find((bcItem) => bcItem.type === item.type);
      const typeFlag = getTypeDetails(item.type).featureFlag || item.featureFlag;
      // eslint-disable-next-line no-param-reassign
      item.isActive = get(featureFlags, typeFlag, false);
      if (bcTypeFound) {
        tempArr.push({
          ...bcTypeFound,
          ...item,
        });
      } else {
        tempArr.push({...item});
      }
    });

    return tempArr;
  },
);

export const getFilteredIntegrationdDataSourceTypes = createSelector(
  getIntegrationdDataSourceTypesItems,
  getDataSourceTypesFilterText,
  (typeItems, filterTextActual) =>
    (typeItems || EMPTY_ARRAY).filter((item) => item.name.toLowerCase().indexOf(filterTextActual.toLowerCase()) >= 0),
);

//* ** data sources
export const getDataSources = createSelector(
  getBusinessCollectors,
  (bc) => bc.dataSources,
);

export const getDataSourcesItems = createSelector(
  getDataSources,
  (dataSources) =>
    (dataSources.sources.data || EMPTY_ARRAY).sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    ) || EMPTY_ARRAY,
);

export const getDataSourcesItemsNoLookup = createSelector(
  getDataSources,
  (dataSources) => (dataSources.sources.data || EMPTY_ARRAY).filter((item) => item.id !== 'lookupSource'),
);

export const getDataSourcesItemsWithLookup = createSelector(
  getDataSourcesItems,
  getIsLookupEnabled,
  (sources, isLookupEnabled) => {
    if (isLookupEnabled) {
      const lookupSrc = {
        id: 'lookupSource',
        type: 'lookup',
        name: 'lookup',
        items: [
          {
            id: 'LOOKUPTABLE',
            name: 'look Up Table',
            type: 'lookup',
          },
        ],
      };
      sources.push(lookupSrc);
    }
    return sources;
  },
);

export const getSelectedDataSourceId = createSelector(
  getDataSources,
  (dataSources) => dataSources.selectedItemId,
);

export const getAddDataSourceState = createSelector(
  getDataSources,
  (dataSources) => dataSources.addSourceState,
);

//* **streams

export const getEventStreams = createSelector(
  getBusinessCollectors,
  (bc) => bc.eventStreams.data,
);

export const getDataStreams = createSelector(
  getBusinessCollectors,
  (bc) => bc.dataStreams,
);

export const getIsDataStreamsLoading = createSelector(
  getDataStreams,
  (dataStreams) => dataStreams.streams.isLoading,
);

export const getIsDataStreamUpdating = createSelector(
  getDataStreams,
  (streams) => streams.isUpdatingStream,
);

export const getDataStreamsItems = createSelector(
  getDataStreams,
  (dataStreams) => dataStreams.streams.data || EMPTY_ARRAY,
);

export const getDataStreamsDateFormats = createSelector(
  getDataStreamsItems,
  (streams) => {
    const tmpArr = [...DATE_FORMATS];

    streams.forEach((i) => {
      if (i.timeDefinition && i.timeDefinition.timePattern) {
        tmpArr.push(i.timeDefinition.timePattern);
      }
    });

    return tmpArr
      .filter((item, index) => tmpArr.indexOf(item) === index)
      .map((j) => {
        const timePattern = j.toLowerCase();
        const hasTimeZone = timePattern.indexOf('z') >= 0 && timePattern.indexOf("'z") === -1;
        return {
          label: j.indexOf('epoch') >= 0 ? j.replace('_', ' ').replace(/\b\w/g, (l) => l.toUpperCase()) : j,
          value: j,
          hasTimeZone: hasTimeZone || ['epoch_seconds', 'epoch_millis', 'epoch_micros'].includes(j),
        };
      })
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  },
);

export const getDataStreamsItemsBySelectedSource = createSelector(
  getDataStreamsItems,
  getSelectedDataSourceId,
  (dataStreams, selectedSourceId) =>
    dataStreams.filter((i) => (selectedSourceId ? i.dataSourceId === selectedSourceId : true)),
);

export const getIsDataStreamNeedUpdating = createSelector(
  getDataStreams,
  (dataStreams) => dataStreams.isStreamNeedUpdating,
);

export const getSelectedDataStreamId = createSelector(
  getDataStreams,
  (dataStreams) => dataStreams.selectedItemId,
);

export const getSelectedDataStream = createSelector(
  getDataStreamsItems,
  getEventStreams,
  getSelectedDataStreamId,
  (dataStreams, eventStreams, selectedId) => [...dataStreams, ...eventStreams].find((ds) => ds.id === selectedId),
);

export const getSelectedDataStreamDvp = createSelector(
  getSelectedDataStream,
  (stream) => stream?.dvpConfig || stream?.config?.dvpConfig,
);

export const getSelectedDataStreamType = createSelector(
  getSelectedDataStream,
  (stream) => stream.type,
);

export const getSelectedDataStreamMetrics = createSelector(
  getSelectedDataStream,
  (stream) => stream.metrics,
);

export const getSelectedDataStreamDimensions = createSelector(
  getSelectedDataStream,
  (stream) => stream.dimensions,
);

export const getSelectedDataStreamSchema = createSelector(
  getSelectedDataStream,
  (stream) => stream.schema || EMPTY_OBJECT,
);

export const getSelectedDataStreamSchemaColumns = createSelector(
  getSelectedDataStreamSchema,
  (schema) => schema.columns || EMPTY_ARRAY,
);

export const getSelectedDataStreamSchemaColumnsMetrics = createSelector(
  getSelectedDataStreamSchemaColumns,
  (columns) => columns.filter((col) => col.type === 'metric' && !col.hidden),
);

export const getSelectedDataStreamSchemaColumnsDimensions = createSelector(
  getSelectedDataStreamSchemaColumns,
  (columns) => columns.filter((col) => col.type === 'dimension' && !col.hidden),
);

export const getStreamUiState = createSelector(
  getSelectedDataStream,
  (stream) => stream.uiState || EMPTY_OBJECT,
);

export const getStreamMetricsDimentionsFilterTextInput = createSelector(
  getStreamUiState,
  (uiState) => uiState.filterTextInput || '',
);

export const getIsSelectedDataStreamUniqueName = createSelector(
  getDataStreamsItems,
  getSelectedDataStream,
  (streams, selectedStream) => {
    if (!streams.length || !selectedStream || selectedStream.id === 'EditRunning') {
      return true;
    }

    return !streams.some((i) => i.name === selectedStream.name && i.id !== selectedStream.id);
  },
);

// /***ga stream-preview
export const getSelectedStreamPreview = createSelector(
  getDataStreams,
  (streams) => streams.selectedStreamPreview,
);

export const getStreamPreviewColumnWidth = createSelector(
  getDataStreams,
  (streams) => streams.selectedStreamPreviewColumnWidth,
);

export const getIsPreviewLoading = createSelector(
  getIsDataStreamsLoading,
  getSelectedStreamPreview,
  (isLoadingStreams, streamPreview) => isLoadingStreams || streamPreview.isLoading !== false,
);

export const hasPreviewErrors = createSelector(
  getSelectedStreamPreview,
  (streamPreview) => Object.values(streamPreview.error || {}).length > 0,
);

export const getStreamsStats = createSelector(
  getDataStreams,
  (streams) => streams.streamsStats,
);

export const getStreamsStatsIsLoading = createSelector(
  getStreamsStats,
  (streamsStats) => streamsStats.isLoading,
);

export const getStreamsStatsMetrics = createSelector(
  getStreamsStats,
  (streamsStats) => get(streamsStats.metrics, 'cached', 0),
);

// /***ga stream-stats
export const getSelectedStreamStats = createSelector(
  getDataStreams,
  (streams) => streams.selectedStreamStats,
);

export const getIsStatsLoading = createSelector(
  getIsDataStreamsLoading,
  getSelectedStreamStats,
  (isLoadingStreams, streamStats) => isLoadingStreams || streamStats.isLoading !== false,
);

export const hasStatsErrors = createSelector(
  getSelectedStreamStats,
  (streamStats) => Object.values(streamStats.error || {}).length > 0,
);

export const getSelectedStreamStatsTotalMetrics = createSelector(
  getSelectedStreamStats,
  getIsStatsLoading,
  (streamStats, isLoading) => (isLoading ? '' : get(streamStats.data, 'totalMetrics', 0)),
);

export const getSelectedStreamMetricsCount = createSelector(
  getDataStreams,
  (streams) => streams.selectedStreamMetricsCount,
);

export const getSelectedStreamCardinality = createSelector(
  getDataStreams,
  (streams) => streams.selectedStreamCardinality || EMPTY_OBJECT,
);

export const getSelectedStreamCardinalityIsLoading = createSelector(
  getSelectedStreamCardinality,
  (stCardinality) => stCardinality.isLoading,
);

export const getSelectedStreamCardinalityTotal = createSelector(
  getSelectedStreamCardinality,
  getSelectedStreamCardinalityIsLoading,
  (stCardinality, isLoading) => (isLoading ? '' : get(stCardinality.data, 'totalMetrics', 0)),
);

export const getSelectedStreamHistoryLog = createSelector(
  getDataStreams,
  (streams) => get(streams, ['selectedStreamHistoryLogs', 'data'], EMPTY_OBJECT),
);

export const getSelectedStreamHistoryLogIsLoading = createSelector(
  getDataStreams,
  (streams) => get(streams, ['selectedStreamHistoryLogs', 'isLoading'], EMPTY_OBJECT),
);

export const getSelectedStreamLastRun = createSelector(
  getDataStreams,
  (streams) => streams.selectedStreamLastRun || EMPTY_OBJECT,
);

export const getSelectedStreamLastRunIsLoading = createSelector(
  getSelectedStreamLastRun,
  (lastRun) => lastRun.isLoading !== false,
);

export const getSelectedStreamCardinalityItems = createSelector(
  getSelectedStreamCardinality,
  (stCardinality) => get(stCardinality, 'data.metricsCardinality', EMPTY_OBJECT),
);

//* **google search
export const getGoogleSearchStream = createSelector(
  getBusinessCollectors,
  (bc) => {
    return bc.googleSearchStream;
  },
);

export const getGoogleSearchStreamSites = createSelector(
  getGoogleSearchStream,
  (stream) => stream.sites || EMPTY_OBJECT,
);

export const getGoogleSearchStreamSitesIsLoading = createSelector(
  getGoogleSearchStreamSites,
  (sites) => sites.isLoading !== false,
);

export const getGoogleSearchStreamSitesData = createSelector(
  getGoogleSearchStreamSites,
  (sites) => (sites?.data ? sites.data.map((item) => ({id: item, name: item})) : EMPTY_ARRAY),
);

//* **ga4 stream
export const getGoogleGA4Stream = createSelector(
  getBusinessCollectors,
  (bc) => {
    return bc.googleGA4Stream;
  },
);

//* **ga4 accounts
export const getGoogleGA4Accounts = createSelector(
  getGoogleGA4Stream,
  (stream) => stream.accounts || EMPTY_OBJECT,
);

export const getGoogleGA4AccountsItems = createSelector(
  getGoogleGA4Accounts,
  (accounts) => accounts.data?.accounts || EMPTY_ARRAY,
);

export const getGoogleGA4SelectedAccount = createSelector(
  getGoogleGA4AccountsItems,
  getSelectedDataStream,
  (accounts, dataStream) => accounts.find((acc) => acc.id === dataStream.accountId),
);

//* **ga4 properties
export const getGoogleGA4Properties = createSelector(
  getGoogleGA4Stream,
  (stream) => stream.properties || EMPTY_OBJECT,
);

export const getGoogleGA4PropertiesItems = createSelector(
  getGoogleGA4Properties,
  (properties) => properties.data?.properties || EMPTY_ARRAY,
);

export const getGoogleGA4SelectedProperty = createSelector(
  getGoogleGA4PropertiesItems,
  getSelectedDataStream,
  (properties, dataStream) => properties.find((prop) => prop.id === dataStream.propertyId),
);

//* ** ga4 meta
export const getGoogleGA4AnalyticsMeta = createSelector(
  getGoogleGA4Stream,
  (stream) => stream.metadata || EMPTY_OBJECT,
);

export const getGoogleGA4MetaTimezone = createSelector(
  getGoogleGA4AnalyticsMeta,
  (meta) => meta.data.timezone || null,
);

export const getGoogleGA4MetaDimensions = createSelector(
  getGoogleGA4AnalyticsMeta,
  (meta) =>
    (meta.data.dimensions || EMPTY_ARRAY).sort((a, b) => a.uiName.toLowerCase().localeCompare(b.uiName.toLowerCase())),
);

export const getGoogleGA4MetaMetrics = createSelector(
  getGoogleGA4AnalyticsMeta,
  (meta) =>
    (meta.data.metrics || EMPTY_ARRAY).sort((a, b) => a.uiName.toLowerCase().localeCompare(b.uiName.toLowerCase())),
);

export const getGoogleGA4tsCandidates = createSelector(
  getGoogleGA4AnalyticsMeta,
  (meta) => meta.data.tsCandidates || EMPTY_ARRAY,
);

export const getGoogleGA4tsCandidate = createSelector(
  getGoogleGA4AnalyticsMeta,
  (meta) =>
    meta.data.tsCandidates?.length
      ? meta.data.tsCandidates.find((item) => item.apiName === 'dateHour') || meta.data.tsCandidates[0]
      : {apiName: ''},
);

//* ** ga4 templates
export const getGoogleGA4Templates = createSelector(
  getGoogleGA4Stream,
  (stream) => stream.templates || EMPTY_OBJECT,
);

export const getGoogleGA4TemplatesItems = createSelector(
  getGoogleGA4Templates,
  (templates) => {
    if (isEmpty(templates.data)) {
      return EMPTY_ARRAY;
    }
    return templates.data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  },
);

export const getGoogleGA4QuerySelectedTemplate = createSelector(
  getGoogleGA4TemplatesItems,
  getSelectedDataStream,
  (items, stream) =>
    items.find(
      (t) => isEqual(t.metrics.sort(), stream.metrics.sort()) && isEqual(t.dimensions.sort(), stream.dimensions.sort()),
    ),
);

export const getGoogleGA4StreamBaseTemplate = createSelector(
  getGoogleGA4TemplatesItems,
  getSelectedDataStream,
  (templates, stream) => templates.find((t) => t.id === stream.basedOnTemplateId),
);

//* **GA4 adjust meta data
export const getGoogleGA4Adjust = createSelector(
  getGoogleGA4Stream,
  (stream) => stream.adjust || EMPTY_OBJECT,
);

export const getGoogleGA4AdjustData = createSelector(
  getGoogleGA4Adjust,
  (adjust) => (!isEmpty(adjust.data) ? adjust.data : null),
);

export const getSelectedGA4DataStreamMetricsObj = createSelector(
  getSelectedDataStreamMetrics,
  getSelectedDataStreamSchemaColumns,
  (metrics, columns) =>
    columns
      .filter((a) => metrics.includes(a.sourceColumn))
      .sort((a, b) => metrics.indexOf(a.name) - metrics.indexOf(b.name)),
);

export const getSelectedGA4DataStreamMetrics = createSelector(
  getSelectedGA4DataStreamMetricsObj,
  (metrics) => metrics.map((item) => item.name),
);

export const getSelectedGA4DataStreamDimensionsObj = createSelector(
  getSelectedDataStreamDimensions,
  getSelectedDataStreamSchemaColumns,
  (dimensions, columns) =>
    columns
      .filter((a) => dimensions.includes(a.sourceColumn))
      .sort((a, b) => dimensions.indexOf(a.name) - dimensions.indexOf(b.name)),
);

export const getSelectedGA4DataStreamDimensions = createSelector(
  getSelectedGA4DataStreamDimensionsObj,
  (dimensions) => dimensions.map((item) => item.name),
);

//* **ga stream
export const getGoogleAnalyticsStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.googleAnalyticsStream,
);

//* **ga accounts
export const getGoogleAnalyticsAccounts = createSelector(
  getGoogleAnalyticsStream,
  (gaStream) => gaStream.accounts,
);

export const getGoogleAnalyticsAccountsItems = createSelector(
  getGoogleAnalyticsAccounts,
  (accounts) => accounts.data || EMPTY_ARRAY,
);

export const getGoogleAnalyticsSelectedAccount = createSelector(
  getGoogleAnalyticsAccountsItems,
  getSelectedDataStream,
  (accounts, dataStream) => accounts.find((acc) => acc.id === dataStream.accountId),
);

//* **ga properties
export const getGoogleAnalyticsProperties = createSelector(
  getGoogleAnalyticsStream,
  (gaStream) => gaStream.properties,
);

export const getGoogleAnalyticsPropertiesItems = createSelector(
  getGoogleAnalyticsProperties,
  (properties) => properties.data || EMPTY_ARRAY,
);

export const getGoogleAnalyticsSelectedProperty = createSelector(
  getGoogleAnalyticsPropertiesItems,
  getSelectedDataStream,
  (properties, dataStream) => properties.find((prop) => prop.id === dataStream.propertyId),
);

//* **ga views
export const getGoogleAnalyticsViews = createSelector(
  getGoogleAnalyticsStream,
  (gaStream) => gaStream.views,
);

export const getGoogleAnalyticsViewsItems = createSelector(
  getGoogleAnalyticsViews,
  (views) => views.data || EMPTY_ARRAY,
);

export const getGoogleAnalyticsSelectedView = createSelector(
  getGoogleAnalyticsViewsItems,
  getSelectedDataStream,
  (views, dataStream) => views.find((vw) => vw.id === dataStream.viewId),
);

//* ** ga templates
export const getGoogleAnalyticsTemplates = createSelector(
  getGoogleAnalyticsStream,
  (gaStreamMeta) => gaStreamMeta.templates,
);

export const getGoogleAnalyticsTemplatesItems = createSelector(
  getGoogleAnalyticsTemplates,
  (templates) => templates.data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
);

export const getGoogleAnalyticsQuerySelectedTemplate = createSelector(
  getGoogleAnalyticsTemplatesItems,
  getSelectedDataStream,
  (items, stream) =>
    items.find(
      (t) => isEqual(t.metrics.sort(), stream.metrics.sort()) && isEqual(t.dimensions.sort(), stream.dimensions.sort()),
    ),
);

export const getGoogleAnalyticsStreamBaseTemplate = createSelector(
  getGoogleAnalyticsTemplatesItems,
  getSelectedDataStream,
  (templates, stream) => templates.find((t) => t.id === stream.basedOnTemplateId),
);

//* ** generated dashboard and alerts titles
export const getDashAndAlertsTitles = createSelector(
  getDataStreams,
  (dataStreams) => dataStreams.dashAndAlertTitles || EMPTY_ARRAY,
);

export const getDashAndAlertsTitlesItems = createSelector(
  getDashAndAlertsTitles,
  (data) => data.items || EMPTY_ARRAY,
);

//* ** ga transform functions
export const getTransformFunctions = createSelector(
  getDataStreams,
  (dataStreams) => dataStreams.transformFunctions || EMPTY_ARRAY,
);

export const getTransformFunctionsItems = createSelector(
  getTransformFunctions,
  getIsLookupEnabled,
  (trans, isLookupEnabled) => {
    const transformFunctions = trans.items.filter((i) => i.enabled);
    if (!isLookupEnabled) {
      return transformFunctions.filter((i) => i.name !== 'replacelookuptable');
    }
    return transformFunctions;
  },
);

export const getTransformFunctionsItemsView = createSelector(
  getTransformFunctionsItems,
  (transArr) => {
    transArr.push({
      name: 'copy',
      displayName: 'Copy',
      description: 'Create a copy of a column as a new column',
      type: 'Copy',
      enabled: true,
    });
    return transArr;
  },
);

export const getIsLoadingTransformFunctions = createSelector(
  getTransformFunctions,
  (trans) => trans.isLoading !== false,
);

//* ** ga segments
export const getSegments = createSelector(
  getGoogleAnalyticsStream,
  (gaStream) => gaStream.segments,
);

export const getSegmentsIsLoading = createSelector(
  getSegments,
  (gaSegments) => gaSegments.isLoading !== false,
);

export const getSegmentsData = createSelector(
  getSegments,
  (gaSegments) => gaSegments.data,
);

export const getSelectedSegments = createSelector(
  getSelectedDataStream,
  (stream) => stream.segments || EMPTY_ARRAY,
);

export const getDdlSelectedSegments = createSelector(
  getSegmentsData,
  getSelectedSegments,
  (segments, selectedSegments) => segments.filter((item) => selectedSegments.includes(item.id)) || EMPTY_ARRAY,
);

export const getSegmentStreamMessageTypeMetadataData = createSelector(
  getStreamUiState,
  (uiState) => uiState.messageMetadata || EMPTY_OBJECT,
);

export const getSegmentMessageTypeMetadataAllMetricsAndDimensions = createSelector(
  getSegmentStreamMessageTypeMetadataData,
  (meta) => {
    if (isEmpty(meta)) {
      return EMPTY_ARRAY;
    }
    const metrics = meta.metrics || EMPTY_ARRAY;
    const dimensions = meta.dimensions || EMPTY_ARRAY;
    return metrics.concat(dimensions);
  },
);

export const getSegmentStreamSelectedDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getSegmentMessageTypeMetadataAllMetricsAndDimensions,
  (dimensions, meta) =>
    meta
      .filter((a) => dimensions.includes(a.path))
      .sort((a, b) => dimensions.indexOf(a.path) - dimensions.indexOf(b.path)),
);

export const getSegmentStreamSelectedMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getSegmentMessageTypeMetadataAllMetricsAndDimensions,
  (metrics, meta) =>
    meta.filter((a) => metrics.includes(a.path)).sort((a, b) => metrics.indexOf(a.path) - metrics.indexOf(b.path)),
);

export const getSegmentStreamUnAssignedNames = createSelector(
  getStreamUiState,
  (uiState) => uiState.unAssignedColumns || EMPTY_ARRAY,
);

export const getSegmentStreamUnAssignedColumns = createSelector(
  getSegmentStreamUnAssignedNames,
  getSegmentStreamMessageTypeMetadataData,
  (unAssignedNames, meta) => {
    if (!isEmpty(meta)) {
      const metrics = meta.metrics || EMPTY_ARRAY;
      const dimensions = meta.dimensions || EMPTY_ARRAY;
      const items = metrics.concat(dimensions);
      return items
        .filter((a) => unAssignedNames.includes(a.path))
        .sort((a, b) => unAssignedNames.indexOf(a.path) - unAssignedNames.indexOf(b.path));
    }
    return EMPTY_ARRAY;
  },
);

export const getFilteredSegmentStreamUnAssignedColumns = createSelector(
  getSegmentStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.name.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

/** **** */

//* ** ga meta
export const getGoogleAnalyticsMeta = createSelector(
  getGoogleAnalyticsStream,
  (gaStream) => gaStream.metadata.data.items || EMPTY_ARRAY,
);

export const getGoogleAnalyticsMetaDimensionsAndMetrics = createSelector(
  getGoogleAnalyticsMeta,
  (meta) =>
    meta
      .filter((item) => item.attributes && item.attributes.status !== 'DEPRECATED' && item.attributes.group !== 'TIME')
      .map((m) => ({
        ...m.attributes,
        id: m.id,
        label: m.attributes.uiName,
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
);

export const getGoogleAnalyticsMetaDimensions = createSelector(
  getGoogleAnalyticsMetaDimensionsAndMetrics,
  (meta) => meta.filter((item) => item.type === 'DIMENSION'),
);

export const getGoogleAnalyticsMetaMetrics = createSelector(
  getGoogleAnalyticsMetaDimensionsAndMetrics,
  (meta) => meta.filter((item) => item.type === 'METRIC'),
);

export const getAllCubes = createSelector(getGoogleAnalyticsStream);
//* ** ga dimetric
const uniqueConcatReducer = (a, b) => a.concat(b.filter((itemB) => a.indexOf(itemB) < 0));

const cubesSelector = createSelector(
  getGoogleAnalyticsStream,
  (gaStream) => gaStream.cubes.data,
);

const conceptCubesSelector = (concept, cubes) =>
  Object.entries(cubes).reduce((result, [cube, concepts]) => (concepts[concept] ? result.concat([cube]) : result), []);

const useFallbackWhenEmpty = (a, b) => (a.length ? a : b);

// TODO: CUBE - Need to check this is working!!!
/* eslint-disable */
const enabledConceptsForSelectedConceptsSelector = (cubes, selectedConcepts) =>
  useFallbackWhenEmpty(
    selectedConcepts
      .map((concept) => conceptCubesSelector(concept, cubes))
      .reduce(
        (results, conceptCubes) =>
          conceptCubes.reduce(
            (result, conceptCube) => (
              (result[conceptCube] = (result[conceptCube] || 0) + 1),
              result[conceptCube] === selectedConcepts.length && result.selectedCubes.push(conceptCube),
              result
            ),
            results,
          ),
        {selectedCubes: []},
      ).selectedCubes,
    Object.keys(cubes),
  ).reduce((result, selectedCube) => uniqueConcatReducer(result, Object.keys(cubes[selectedCube])), []);
/* eslint-enable */

const filterSelected = (all: Array<Object>, selection: Array<string>): Array<Object> =>
  selection ? all.filter(({id}) => selection.indexOf(id) >= 0) : EMPTY_ARRAY;

const filterUnselected = (all: Array<Object>, selection: Array<string>): Array<Object> =>
  selection ? all.filter(({id}) => selection.indexOf(id) < 0) : all;

const addDisabled = (list: Array<Object>, enabled: Array<string>): Array<Object> =>
  list.map((item) => ({
    ...item,
    disabled: enabled.indexOf(item.id) < 0,
  }));

export const getGoogleAnalyticsStreamSelectedDimensions = createSelector(
  getGoogleAnalyticsMetaDimensions,
  getSelectedDataStream,
  (metaDimensions, {dimensions}) => filterSelected(metaDimensions, dimensions),
);

export const getGoogleAnalyticsStreamSelectedMetrics = createSelector(
  getGoogleAnalyticsMetaMetrics,
  getSelectedDataStream,
  (metaMetrics, {metrics}) => filterSelected(metaMetrics, metrics),
);

const selectedConceptsSelector = createSelector(
  getGoogleAnalyticsStreamSelectedMetrics,
  getGoogleAnalyticsStreamSelectedDimensions,
  (selectedMetrics = [], selectedDimensions) => selectedMetrics.concat(selectedDimensions).map(({id}) => id),
);

const enabledConceptsSelector = createSelector(
  cubesSelector,
  selectedConceptsSelector,
  getSelectedDataStream,
  (cubes, selectedConcepts, {pollingResolution}) => {
    if (pollingResolution === 'm30' || pollingResolution === 'm15') {
      return enabledConceptsForSelectedConceptsSelector(cubes, [...selectedConcepts, 'ga:minte']);
    }
    return enabledConceptsForSelectedConceptsSelector(cubes, selectedConcepts);
  },
);

export const getGoogleAnalyticsStreamPossibleDimensions = createSelector(
  getGoogleAnalyticsMetaDimensions,
  getSelectedDataStream,
  enabledConceptsSelector,
  (metaDimensions, {dimensions}, enabledConcepts) =>
    addDisabled(filterUnselected(metaDimensions, dimensions), enabledConcepts),
);

export const getGoogleAnalyticsStreamPossibleMetrics = createSelector(
  getGoogleAnalyticsMetaMetrics,
  getSelectedDataStream,
  enabledConceptsSelector,
  (metaMetrics, {metrics}, enabledConcepts) => addDisabled(filterUnselected(metaMetrics, metrics), enabledConcepts),
);

const filterConflictingConceptsWith = (withConcept, concepts, meta, cubes) =>
  concepts
    .filter((concept) => enabledConceptsForSelectedConceptsSelector(cubes, [concept]).indexOf(withConcept) < 0)
    .map((concept) => meta.find(({id}) => id === concept));

export const conceptsInConflictWith = createSelector(
  cubesSelector,
  getSelectedDataStream,
  getGoogleAnalyticsMetaMetrics,
  getGoogleAnalyticsMetaDimensions,
  (cubes, {metrics, dimensions}, metaMetrics, metaDimensions) => (withConcept) => ({
    metrics: filterConflictingConceptsWith(withConcept, metrics, metaMetrics, cubes),
    dimensions: filterConflictingConceptsWith(withConcept, dimensions, metaDimensions, cubes),
  }),
);

//* **data manager
export const getDataManager = createSelector(
  getBusinessCollectors,
  (bc) => bc.dataManager,
);

//* ** Data Manager Pages settings and filters
export const getDataManagerOpenedSources = createSelector(
  getDataManager,
  (manager) => get(manager, 'openSources', null),
);

export const getDataManagerModalFilter = createSelector(
  getDataManager,
  (manager) => get(manager, 'sourceFilter', ''),
);

export const getDataManagerFiltersIsOpen = createSelector(
  getDataManager,
  (manager) => get(manager, 'isFiltersBarOpen', true),
);

export const getDataMangerQueryParams = createSelector(
  getDataManager,
  (manager) => get(manager, 'queryParams', {}),
);

export const getDataMangerQueryParamsSearchQuery = createSelector(
  getDataMangerQueryParams,
  (queryParams) => get(queryParams, 'searchQuery', ''),
);

export const getDataMangerQueryParamsIsChanged = createSelector(
  getDataMangerQueryParams,
  (queryParams) =>
    (queryParams.searchQuery &&
      queryParams.searchQuery !== '' &&
      queryParams.searchQuery !== DEFAULT_QUERY_PARAMS.status) ||
    (queryParams.status && queryParams.status !== DEFAULT_QUERY_PARAMS.searchQuery) ||
    (queryParams.type && queryParams.type !== DEFAULT_QUERY_PARAMS.type) ||
    (queryParams.owner && queryParams.owner !== DEFAULT_QUERY_PARAMS.owner) ||
    (queryParams.accessGroups && queryParams.accessGroups !== DEFAULT_QUERY_PARAMS.accessGroups),
);

export const getDataMangerFilterToolTip = createSelector(
  getDataMangerQueryParams,
  usersSelectors.getUsersData,
  usersSelectors.fetchGroupsData,
  (param, users, groupsData) => {
    const filterItemsList = [];
    if (param.searchQuery && param.searchQuery !== '') {
      filterItemsList.push({
        id: 'searchQuery',
        header: 'Search',
        value: param.searchQuery,
      });
    }
    if (param.status && param.status.length) {
      filterItemsList.push({
        id: 'status',
        header: 'Status',
        value: param.status
          .split(',')
          .map((item) => STATUS.find((st) => st.id === item).label)
          .join(' '),
      });
    }
    if (param.type && param.type.length) {
      filterItemsList.push({
        id: 'type',
        header: 'Types',
        value: param.type
          .split(',')
          .map((item) => getTypeDetails(item).name)
          .join(' '),
      });
    }
    if (param.owner && param.owner.length) {
      filterItemsList.push({
        id: 'owner',
        header: 'Owners',
        value: param.owner
          .split(',')
          .map((item) => getStreamOwnerName(item, users))
          .join(' '),
      });
    }
    if (param.accessGroups && param.accessGroups.length) {
      filterItemsList.push({
        id: 'accessGroups',
        header: 'Access Groups',
        value: accessGroupsShortedNames(getAccessGroups(param.accessGroups.split(','), groupsData)),
      });
    }
    return filterItemsList;
  },
);

export const getDataManagerListOrder = createSelector(
  getDataMangerQueryParams,
  (param) => get(param, 'order', 'desc'),
);

export const getDataManagerListSort = createSelector(
  getDataMangerQueryParams,
  (param) => get(param, 'sort', 'title'),
);

export const getDataManagerFilterText = createSelector(
  getDataManager,
  (manager) => manager.filterTextActual,
);

export const getIsTimeZoneConfirmModalOpen = createSelector(
  getDataManager,
  (manager) => manager.isTimeZoneConfirmModalOpen,
);

export const getDataStreamsItemsOrdered = createSelector(
  getDataStreamsItems,
  getEventStreams,
  getDataManagerListOrder,
  getDataManagerListSort,
  usersSelectors.getUsersData,
  (dataStreams = EMPTY_ARRAY, eventStreams = EMPTY_ARRAY, dataOrder, dataSort, users) => {
    const streams = [...dataStreams, ...eventStreams];

    switch (dataSort) {
      case 'owner':
        return [
          ...streams.sort((a, b) => {
            const aOwner = getStreamOwnerName(a.owner || a.userId, users);
            const bOwner = getStreamOwnerName(b.owner || b.userId, users);
            if (dataOrder === 'desc') {
              return aOwner.toLowerCase().localeCompare(bOwner.toLowerCase());
            }
            return bOwner.toLowerCase().localeCompare(aOwner.toLowerCase());
          }),
        ];
      case 'status':
        return [
          ...streams.sort((a, b) => {
            const aRank = getStreamChip(a).score;
            const bRank = getStreamChip(b).score;
            if (dataOrder === 'desc') {
              return aRank - bRank;
            }
            return bRank - aRank;
          }),
        ];
      default:
        return [
          ...streams.sort((a, b) => {
            if (dataOrder === 'desc') {
              return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
            }
            return b.name.toLowerCase().localeCompare(a.name.toLowerCase());
          }),
        ];
    }
  },
);

export const getDataStreamsItemsOrderedAndFiltered = createSelector(
  getDataStreamsItemsOrdered,
  getDataMangerQueryParams,
  getAccessListAllSourcesData,

  (streams, queryParams, accessList) => {
    if (
      !streams.length ||
      ((!queryParams.searchQuery || queryParams.searchQuery === '') &&
        (!queryParams.status || queryParams.status === '') &&
        (!queryParams.type || queryParams.type === '') &&
        (!queryParams.owner || queryParams.owner === '') &&
        (!queryParams.accessGroups || queryParams.accessGroups === ''))
    ) {
      return streams;
    }

    let streamsArr = [];

    if (queryParams.searchQuery && queryParams.searchQuery !== '') {
      streamsArr = union(
        streamsArr,
        streams.filter(
          (stream) =>
            stream.name.toLowerCase().includes(queryParams.searchQuery.toLowerCase()) ||
            stream.id.toLowerCase().includes(queryParams.searchQuery.toLowerCase()),
        ),
      );
    } else {
      streamsArr = [...streams];
    }

    if (queryParams.status && queryParams.status !== '') {
      let statusArr0 = [];
      let statusArr1 = [];
      let statusArr2 = [];
      let statusArr3 = [];
      let statusArr4 = [];
      let statusArr5 = [];
      let statusArr6 = [];
      const pausedStatus = ['STOPPED', 'EDITED', 'DISCONNECTED'];
      if (queryParams.status.includes('running')) {
        statusArr0 = streamsArr.filter(
          (stream) =>
            stream.status === 'ok' &&
            ((stream.family === 'agents' && stream.state === 'RUNNING' && !pausedStatus.includes(stream.state)) ||
              (stream.family !== 'agents' && stream.state === 'running' && !stream.paused)),
        );
      }
      if (queryParams.status.includes('finished')) {
        statusArr1 = streamsArr.filter(
          (stream) =>
            (stream.family === 'agents' && stream.state === 'FINISHED') ||
            (stream.family !== 'agents' && stream.state === 'finished'),
        );
      }
      if (queryParams.status.includes('Completed')) {
        statusArr2 = streamsArr.filter(
          (stream) =>
            stream.family !== 'agents' && stream.state === 'complete' && !stream.paused && stream.status !== 'failed',
        );
      }
      if (queryParams.status.includes('initializing')) {
        statusArr3 = streamsArr.filter(
          (stream) =>
            (stream.family === 'agents' && (stream.state === 'STARTING' || stream.state === 'CONNECTING')) ||
            (stream.family !== 'agents' &&
              stream.state &&
              stream.state.indexOf('initializing_p') > -1 &&
              !stream.paused &&
              stream.status !== 'failed'),
        );
      }
      if (queryParams.status.includes('new')) {
        statusArr4 = streamsArr.filter(
          (stream) =>
            stream.family !== 'agents' && stream.state === 'new' && !stream.paused && stream.status !== 'failed',
        );
      }
      if (queryParams.status.includes('paused')) {
        statusArr5 = streamsArr.filter(
          (stream) =>
            (stream.family === 'agents' && pausedStatus.includes(stream.state)) ||
            (stream.family !== 'agents' && stream.paused),
        );
      }
      if (queryParams.status.includes('error')) {
        statusArr6 = streamsArr.filter(
          (stream) =>
            (stream.family === 'agents' && ['START_ERROR', 'RUN_ERROR', 'STOP_ERROR'].includes(stream.state)) ||
            (stream.family !== 'agents' && stream.status === 'failed' && !stream.paused),
        );
      }
      streamsArr = union(statusArr0, statusArr1, statusArr2, statusArr3, statusArr4, statusArr5, statusArr6);
    }

    if (queryParams.type && queryParams.type !== '') {
      const queryParamsTypeArr = queryParams.type.split(',');
      streamsArr = streamsArr.filter((stream) => queryParamsTypeArr.includes(stream.type));
    }

    if (queryParams.owner && queryParams.owner !== '') {
      const queryParamsOwnerArr = queryParams.owner.split(',');
      streamsArr = streamsArr.filter(
        (stream) =>
          queryParamsOwnerArr.includes(stream.owner) || (queryParamsOwnerArr.includes('unknown') && !stream.owner),
      );
    }

    if (queryParams.accessGroups && queryParams.accessGroups !== '') {
      streamsArr = streamsArr.filter((stream) => {
        return queryParams.accessGroups
          .split(',')
          .some((groupId) => (accessList[stream.id] ? accessList[stream.id].includes(groupId) : false));
      });
    }

    streamsArr = streamsArr.filter((stream) => stream.id !== 'EditRunning');

    return streamsArr;
  },
);

export const getDatamanagerStreamsCurrentOwners = createSelector(
  getDataStreamsItems,
  usersSelectors.getUsersData,
  (streams, users) => {
    const result = [];
    const map = new Map();
    // eslint-disable-next-line
    for (const stream of streams) {
      if (!map.has(stream.owner)) {
        map.set(stream.owner, true); // set any value to Map
        result.push({
          value: stream.owner || 'unknown',
          label: getStreamOwnerName(stream.owner || stream.userId, users),
        });
      }
    }
    return result.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  },
);

export const getDatamanagerStreamsCurrentTypes = createSelector(
  getDataStreamsItems,
  (streams) => {
    const result = [];
    const map = new Map();
    // eslint-disable-next-line
    for (const stream of streams) {
      if (!map.has(stream.type)) {
        map.set(stream.type, true); // set any value to Map
        result.push({
          value: stream.type,
          label: getTypeDetails(stream.type).name,
        });
      }
    }
    return result.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  },
);

export const getDataSourcesItemsOrdered = createSelector(
  getDataSources,
  getDataSourceTypesItems,
  getEnableEventsStreams,
  (dataSources, types, enableEventsStreams) => {
    let mergedSources = EMPTY_ARRAY;

    if (enableEventsStreams && types.length && dataSources.sources.data) {
      mergedSources = dataSources.sources.data.map((source) => {
        const found = types.find((type) => type.type === source.type);

        return {...source, supportData: found?.supportData, supportEvents: found?.supportEvents};
      });
    } else if (dataSources.sources.data) {
      mergedSources = dataSources.sources.data;
    }

    return mergedSources.sort((a, b) => {
      const aOrderBy = a.family
        ? getTypeDetails(a.type).name + a.family + a.name
        : getTypeDetails(a.type).name + a.name;
      const bOrderBy = b.family
        ? getTypeDetails(b.type).name + b.family + b.name
        : getTypeDetails(b.type).name + b.name;
      return aOrderBy.toLowerCase().localeCompare(bOrderBy.toLowerCase());
    });
  },
);

export const getSelectedDataStreamSource = createSelector(
  getDataSourcesItemsOrdered,
  getSelectedDataStream,
  (sources, selectedStream) => {
    if (!selectedStream) {
      return null;
    }
    return sources.find((ds) => ds.id === selectedStream.dataSourceId);
  },
);

export const getSelectedDataSource = createSelector(
  getDataSourcesItemsOrdered,
  getSelectedDataSourceId,
  (sourceItems, selectedId) => sourceItems.find((ds) => ds.id === selectedId),
);

export const getDataSourcesItemsOrderedForModal = createSelector(
  getDataSourcesItemsOrdered,
  getDataManagerModalFilter,
  (dataSources, query) =>
    dataSources.filter(
      (source) =>
        (!source.family || source.family !== 'agents') && source.name.toLowerCase().indexOf(query.toLowerCase()) > -1,
    ),
);

export const getLookupTables = createSelector(
  getBusinessCollectors,
  (bc) => bc.lookupTables,
);

export const getFetchLookupTablesData = createSelector(
  getLookupTables,
  (lookupTables) => lookupTables.fetchLookupTables.data || EMPTY_ARRAY,
);

export const getFetchLookupTablesIsLoading = createSelector(
  getLookupTables,
  (lookupTables) => lookupTables.fetchLookupTables.isLoading,
);

export const getUpdateLookupTableIsLoading = createSelector(
  getLookupTables,
  (lookupTables) => lookupTables.updateLookupTable.isLoading,
);

export const getLookupTablesItems = createSelector(
  getLookupTables,
  getIsLookupEnabled,
  (lookupTables, isLookupEnabled) => {
    if (isLookupEnabled) {
      const lookupsArr = lookupTables.fetchLookupTables.data || EMPTY_ARRAY;
      const lookupsNewArr = lookupsArr.map((item) => ({
        ...item,
        type: 'lookup',
        state: 'lookup',
        dataSourceId: 'lookupSource',
      }));
      return lookupsNewArr;
    }
    return EMPTY_ARRAY;
  },
);

export const getLookupTablesItemsNames = createSelector(
  getLookupTablesItems,
  (lookupItems) => lookupItems.map((a) => a.name),
);

export const getStreamsAndLookupItems = createSelector(
  getDataStreamsItems,
  getLookupTablesItems,
  (streams, lookups) => [...streams, ...lookups],
);

export const getDataStreamsAndLookupsItemsBySelectedSource = createSelector(
  getStreamsAndLookupItems,
  getSelectedDataSourceId,
  (dataStreams, selectedSourceId) =>
    dataStreams.filter((i) => (selectedSourceId ? i.dataSourceId === selectedSourceId : true)),
);

export const getDataManagerFilteredStreamsAndLookups = createSelector(
  getDataStreamsAndLookupsItemsBySelectedSource,
  getStreamsAndLookupItems,
  getDataManagerFilterText,
  (streamsBySource, streams, filter) => {
    const filteredStreams = (streamsBySource || streams)
      .filter((item) => getTypeDetails(item.type) && item.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    const groups = groupBy(filteredStreams, 'type');
    const groupsArray = Object.keys(groups).map((key) => ({
      type: key,
      items: groups[key],
    }));

    const sortedGroups = groupsArray.sort((a, b) =>
      getTypeDetails(a.type)
        .name.toLowerCase()
        .localeCompare(getTypeDetails(b.type).name.toLowerCase()),
    );

    /* move lookup table type to the end of the list */
    const idx = sortedGroups.findIndex((item) => item.type === 'lookup');
    const lookupGroup = sortedGroups.splice(idx, 1);
    if (lookupGroup.length > 0) {
      sortedGroups.push(lookupGroup[0]);
    }
    return sortedGroups;
  },
);

export const getDataManagerStreamsAndLookupsCount = createSelector(
  getDataStreamsAndLookupsItemsBySelectedSource,
  getStreamsAndLookupItems,
  getDataManagerFilteredStreamsAndLookups,
  (streamsBySource, streamsAll, streamsFiltered) => ({
    bySource: streamsBySource.length,
    all: streamsAll.length,
    filtered: streamsFiltered[0] ? streamsFiltered[0].items.length : 0,
  }),
);

export const getNewStreamsCount = createSelector(
  getDataStreamsItems,
  (streams) => streams.filter((stream) => stream.state === 'new').length,
);

export const getActiveStreamsCount = createSelector(
  getDataStreamsItems,
  (streams) => streams.filter((stream) => stream.state === 'running').length,
);

export const getDataManagerFilteredSourcesAndLookups = createSelector(
  getDataSourcesItemsWithLookup,
  getDataManagerFilterText,
  (sources, filter) => {
    const filteredSources = sources
      .filter((item) => getTypeDetails(item.type) && item.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    const groups = groupBy(filteredSources, 'type');
    const groupsArray = Object.keys(groups).map((key) => ({
      type: key,
      items: groups[key],
    }));
    const sortedGroups = groupsArray.sort((a, b) =>
      getTypeDetails(a.type)
        .name.toLowerCase()
        .localeCompare(getTypeDetails(b.type).name.toLowerCase()),
    );

    /* move lookup table type to the end of the list */
    const idx = sortedGroups.findIndex((item) => item.type === 'lookup');
    const lookupGroup = sortedGroups.splice(idx, 1);
    if (lookupGroup.length > 0) {
      sortedGroups.push(lookupGroup[0]);
    }

    return sortedGroups;
  },
);

export const getLookupPreview = createSelector(
  getLookupTables,
  (lookupTables) => lookupTables.fetchLookupTablePreview,
);

export const getLookupPreviewData = createSelector(
  getLookupPreview,
  (lookupPreview) => {
    const obj = {};
    if (lookupPreview.data.rows) {
      const {rows} = lookupPreview.data;
      const header = rows[0];
      rows.shift();
      obj.header = header;
      obj.rows = rows;
    }
    return obj;
  },
);

export const getLookupPreviewDataNoHeaders = createSelector(
  getLookupPreview,
  (lookupPreview) => {
    const obj = {};
    if (lookupPreview.data.rows) {
      obj.rows = lookupPreview.data.rows;
    }
    return obj;
  },
);

export const getIsLookupPreviewDataLoading = createSelector(
  getLookupPreview,
  (lookupPreview) => lookupPreview.isLoading !== false,
);

export const getDataManagerStreamPreviewId = createSelector(
  getDataManager,
  (manager) => manager.streamPreviewId,
);

//* **upload file
export const getFileUploadProgress = createSelector(
  getDataManager,
  (manager) => manager.uploadFile.uploadProgress,
);

export const getFileUploadName = createSelector(
  getDataManager,
  (manager) => manager.uploadFile.fileName,
);

//* **fu stream
export const getFileUploadStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.fileUploadStream,
);

export const getCsvFormatAnalysis = createSelector(
  getFileUploadStream,
  (fus) => fus.csvFormatAnalysis,
);

export const getCsvFormatAnalysisData = createSelector(
  getCsvFormatAnalysis,
  (cfa) => cfa.data,
);

export const getFilePreview = createSelector(
  getFileUploadStream,
  (fus) => fus.filePreview,
);

export const getFilePreviewData = createSelector(
  getFilePreview,
  (fp) => fp.data || EMPTY_OBJECT,
);

export const getFileProperties = createSelector(
  getFileUploadStream,
  (fus) => fus.fileProperties,
);

export const getFilePropertiesData = createSelector(
  getFileProperties,
  (fp) => fp.data,
);

//* **file diametrics
export const getFileStreamUnAssignedIndices = createSelector(
  getStreamUiState,
  (uiState) => uiState.unAssignedColumns || EMPTY_ARRAY,
);

export const getFileStreamAnalysisResult = createSelector(
  getStreamUiState,
  (uiState) => uiState.analysisResult || EMPTY_OBJECT,
);

export const getFileStreamAnalysisSchema = createSelector(
  getFileStreamAnalysisResult,
  (analysisResult) => analysisResult.fileSchema || EMPTY_ARRAY,
);

export const getFileStreamSelectedDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getFileStreamAnalysisSchema,
  (dimensions, schema) =>
    schema
      .filter((a) => dimensions.includes(a.index))
      .sort((a, b) => dimensions.indexOf(a.index) - dimensions.indexOf(b.index)),
);

export const getFileStreamSelectedMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getFileStreamAnalysisSchema,
  (metrics, schema) =>
    schema.filter((a) => metrics.includes(a.index)).sort((a, b) => metrics.indexOf(a.index) - metrics.indexOf(b.index)),
);

// stream time def may not have timeColumnIdx but we still need the pattern and time zone values
export const getFileStreamSelectedTimeDefinition = createSelector(
  getSelectedDataStream,
  (stream) => stream.timeDefinition,
);

export const getFileStreamSelectedTimeDefinitionCol = createSelector(
  getSelectedDataStream,
  getFileStreamAnalysisSchema,
  (stream, schema) => schema.find((c) => c.index === get(stream.timeDefinition, 'timeColumnIdx')) || EMPTY_OBJECT,
);

export const getFileStreamSelectedTimeDefinitionColArr = createSelector(
  getFileStreamSelectedTimeDefinitionCol,
  (td) => (!isEmpty(td) ? [td] : EMPTY_ARRAY),
);

export const getFileStreamUnAssignedColumns = createSelector(
  getFileStreamUnAssignedIndices,
  getFileStreamAnalysisSchema,
  (unAssignedIndices, schema) =>
    schema
      .filter((a) => unAssignedIndices.includes(a.index))
      .sort((a, b) => unAssignedIndices.indexOf(a.index) - unAssignedIndices.indexOf(b.index)),
);

export const getFilteredFileStreamUnAssignedColumns = createSelector(
  getFileStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.name.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

//* **google storage stream
export const isGoogleStorageStreamUiStateDirty = createSelector(
  getSelectedDataStream,
  (stream) => {
    const isDirty =
      stream.path !== get(stream, 'uiState.path') ||
      stream.fileNameSuffix !== get(stream, 'uiState.fileNameSuffix') ||
      stream.fileNamePrefix !== get(stream, 'uiState.fileNamePrefix') ||
      stream.fileNamePattern !== get(stream, 'uiState.fileNamePattern');

    let isBucketDirty = false;
    if (get(stream, 'uiState.bucket')) {
      isBucketDirty = stream.bucket !== get(stream, 'uiState.bucket');
    }

    let isProjectIdDirty = false;
    if (get(stream, 'uiState.projectId')) {
      isProjectIdDirty = stream.projectId !== get(stream, 'uiState.projectId');
    }

    return isDirty || isBucketDirty || isProjectIdDirty;
  },
);

export const isGoogleStorageProjectAndBucket = createSelector(
  getSelectedDataStream,
  (stream) => get(stream.uiState, 'projectId', null) !== null && get(stream.uiState, 'bucket', null) !== null,
);

export const getGoogleStorageStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.googleStorageStream,
);

export const getGoogleStorageFilePreview = createSelector(
  getGoogleStorageStream,
  (gs) => gs.filePreview,
);

export const getGoogleStorageFilePreviewData = createSelector(
  getGoogleStorageFilePreview,
  (gs) => gs.data || EMPTY_OBJECT,
);

export const getGoogleStorageStreamProjects = createSelector(
  getGoogleStorageStream,
  (gs) => gs.projects,
);

export const getGoogleStorageStreamProjectsIsLoading = createSelector(
  getGoogleStorageStreamProjects,
  (gs) => gs.isLoading !== false,
);

export const getGoogleStorageStreamProjectsItems = createSelector(
  getGoogleStorageStreamProjects,
  (gs) => gs.data || EMPTY_ARRAY,
);

// /
export const getGoogleStorageStreamBuckets = createSelector(
  getGoogleStorageStream,
  (gs) => gs.buckets,
);

export const getGoogleStorageStreamBucketsIsLoading = createSelector(
  getGoogleStorageStreamBuckets,
  (gs) => gs.isLoading !== false,
);

export const getGoogleStorageStreamBucketsItems = createSelector(
  getGoogleStorageStreamBuckets,
  (gs) => gs.data || EMPTY_ARRAY,
);

//* **s3 stream
export const isS3StreamUiStateDirty = createSelector(
  getSelectedDataStream,
  (stream) =>
    stream.path !== get(stream, 'uiState.path') ||
    stream.fileNameSuffix !== get(stream, 'uiState.fileNameSuffix') ||
    stream.fileNamePrefix !== get(stream, 'uiState.fileNamePrefix') ||
    stream.fileNamePattern !== get(stream, 'uiState.fileNamePattern'),
);

export const isS3StreamUiStateValidForAnalyze = createSelector(
  getSelectedDataStream,
  (stream) => get(stream, 'uiState.fileNameSuffix', '') !== '',
);

export const getS3Stream = createSelector(
  getBusinessCollectors,
  (bc) => bc.s3Stream,
);

export const getS3FilePreview = createSelector(
  getS3Stream,
  (s3s) => s3s.filePreview,
);

export const getS3CsvFormatAnalysis = createSelector(
  getS3Stream,
  (s3s) => s3s.csvFormatAnalysis || EMPTY_OBJECT,
);

export const isS3CsvFormatAnalysisLoading = createSelector(
  getS3CsvFormatAnalysis,
  (s3analysis) => s3analysis.isLoading,
);

export const getS3FilePreviewData = createSelector(
  getS3FilePreview,
  (s3fp) => s3fp.data || EMPTY_OBJECT,
);

//* ** google search
export const getSelectedGoogleSearchDataStreamDimensionsObj = createSelector(
  getSelectedDataStreamDimensions,
  getSelectedDataStreamSchemaColumns,
  (dimensions, columns) =>
    columns
      .filter((a) => dimensions.includes(a.sourceColumn))
      .sort((a, b) => dimensions.indexOf(a.name) - dimensions.indexOf(b.name)),
);

export const getSelectedGoogleSearchDataStreamDimensions = createSelector(
  getSelectedGoogleSearchDataStreamDimensionsObj,
  (dimensions) => dimensions.map((item) => item.name),
);

export const getSelectedGoogleSearchDataStreamMetricsObj = createSelector(
  getSelectedDataStreamMetrics,
  getSelectedDataStreamSchemaColumns,
  (metrics, columns) =>
    columns
      .filter((a) => metrics.includes(a.sourceColumn))
      .sort((a, b) => metrics.indexOf(a.name) - metrics.indexOf(b.name)),
);

export const getSelectedGoogleSearchDataStreamMetrics = createSelector(
  getSelectedGoogleSearchDataStreamMetricsObj,
  (metrics) => metrics.map((item) => item.name),
);

export const getGoogleSearchStreamUnAssignedColumns = createSelector(
  getFileStreamUnAssignedIndices,
  (unAssignedIndices) =>
    googleSearchDimensions
      .filter((a) => unAssignedIndices.includes(a.id))
      .sort((a, b) => unAssignedIndices.indexOf(a.name) - unAssignedIndices.indexOf(b.name))
      .map((a) => ({id: a.id, name: a.id})),
);

export const getFilteredGoogleSearchStreamUnAssignedColumns = createSelector(
  getGoogleSearchStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.name.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

//* ** adobe
export const getAdobeStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.adobeStream,
);

export const getAdobeStreamBookmarks = createSelector(
  getAdobeStream,
  (adobe) => adobe.bookmarks,
);

export const getAdobeStreamBookmarksIsLoading = createSelector(
  getAdobeStreamBookmarks,
  (bm) => bm.isLoading !== false,
);

export const getAdobeStreamBookmarksData = createSelector(
  getAdobeStreamBookmarks,
  (bm) => bm.data || EMPTY_OBJECT,
);

export const getAdobeStreamBookmarksItems = createSelector(
  getAdobeStreamBookmarksData,
  (bmData) => {
    let bookmarks = [];
    const folders = get(bmData, 'folders', EMPTY_ARRAY);
    if (folders.length) {
      folders.forEach((folder) => {
        if (folder.bookmarks && folder.bookmarks.length) {
          bookmarks = [...bookmarks, ...folder.bookmarks];
        }
      });
    }
    return bookmarks;
  },
);

export const getAdobeStreamReportDescription = createSelector(
  getAdobeStream,
  (adobe) => adobe.reportDescription,
);

export const getAdobeStreamReportDescriptionIsLoading = createSelector(
  getAdobeStreamReportDescription,
  (rd) => rd.isLoading,
);

export const getAdobeStreamReportDescriptionData = createSelector(
  getAdobeStreamReportDescription,
  (rd) => rd.data || EMPTY_OBJECT,
);

//* ** mixpanel ** *
export const getMixpanelStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.mixpanelStream,
);

// query
export const getMixpanelIsQuery = createSelector(
  getSelectedDataStream,
  (stream) => !!get(stream, 'customQuery', false),
);

export const getMixpanelStreamPreview = createSelector(
  getMixpanelStream,
  (mixpanelStream) => mixpanelStream.preview,
);

export const getMixpanelStreamPreviewData = createSelector(
  getMixpanelStreamPreview,
  (preview) => preview.data,
);

export const getMixpanelStreamPreviewIsLoading = createSelector(
  getMixpanelStreamPreview,
  (preview) => preview.isLoading,
);

export const getMixpanelStreamPreviewError = createSelector(
  getMixpanelStreamPreview,
  (preview) => preview.error,
);

export const getMixpanelStreamPreviewQuery = createSelector(
  getMixpanelStreamPreviewData,
  (data) => data.query,
);

export const getMixpanelStreamQueryPreviewItems = createSelector(
  getTimeZoneName,
  getMixpanelStreamPreview,
  (tzn, prv) => {
    if (prv.data && !isEmpty(prv.data) && prv.data.columns) {
      const tzColIndexArr = [];
      const tmpPrv = {...prv, data: {...prv.data}};
      tmpPrv.data.rows = (prv.data.rows || []).map((row) => {
        const tmpRow = [...row];
        tzColIndexArr.forEach((i) => {
          tmpRow[i] = getFormattedDateTime(tmpRow[i], tzn);
        });
        return tmpRow;
      });
      return tmpPrv.data;
    }
    return EMPTY_OBJECT;
  },
);

export const getMixpanelPreviewData = createSelector(
  getMixpanelStreamQueryPreviewItems,
  (prvItems) => {
    if (prvItems.rows) {
      const previewData = {
        header: [],
        rows: prvItems.rows,
      };
      Object.keys(prvItems.columns).forEach((key) => {
        previewData.header.push(prvItems.columns[key].path);
      });
      previewData.query = prvItems.query;
      return previewData;
    }
    return {};
  },
);

// dropDown
export const getMixpanelStreamEntities = createSelector(
  getMixpanelStream,
  (mx) => mx.entities,
);
export const getMixpanelStreamEntitiesData = createSelector(
  getMixpanelStreamEntities,
  (entities) => entities.data,
);
export const getMixpanelStreamEntitiesIsLoading = createSelector(
  getMixpanelStreamEntities,
  (entities) => entities.isLoading,
);
export const getMixpanelStreamEntity = createSelector(
  getMixpanelStream,
  (mx) => mx.entity,
);
export const getMixpanelStreamEntityData = createSelector(
  getMixpanelStreamEntity,
  (entity) => entity.data,
);
export const getMixpanelStreamEntityIsLoading = createSelector(
  getMixpanelStreamEntity,
  (entity) => entity.isLoading,
);
export const getMixpanelPreviewColumns = createSelector(
  getStreamUiState,
  (uiState) => {
    return uiState && uiState.eventMetadata
      ? uiState.eventMetadata.map((item) => ({
          ...item,
          type: ['Integer', 'Long'].includes(item.objType) ? 'number' : item.objType,
        }))
      : [];
  },
);
export const getMixpanelStreamUnAssignedNames = createSelector(
  getStreamUiState,
  (uiState) => uiState.unAssignedColumns || EMPTY_ARRAY,
);
export const getMixpanelStreamSelectedDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getMixpanelPreviewColumns,
  (dimensions, meta) => {
    return meta
      .filter((a) => dimensions.includes(a.name))
      .sort((a, b) => dimensions.indexOf(a.name) - dimensions.indexOf(b.name));
  },
);
export const getMixpanelStreamSelectedMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getMixpanelPreviewColumns,
  (metrics, meta) =>
    meta.filter((a) => metrics.includes(a.name)).sort((a, b) => metrics.indexOf(a.name) - metrics.indexOf(b.name)),
);
export const getMixpanelStreamUnAssignedColumns = createSelector(
  getMixpanelStreamUnAssignedNames,
  getMixpanelPreviewColumns,
  (unAssignedNames, meta) =>
    meta
      .filter((a) => unAssignedNames.includes(a.name))
      .sort((a, b) => unAssignedNames.indexOf(a.name) - unAssignedNames.indexOf(b.name)),
);
export const getFilteredMixpanelStreamUnAssignedColumns = createSelector(
  getMixpanelStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.name.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

//* ** sumologic
export const getSumologicStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.sumologicStream,
);

export const getSumologicPreview = createSelector(
  getSumologicStream,
  (st) => st.preview,
);

export const getSumologicPreviewData = createSelector(
  getSumologicPreview,
  (preview) => {
    if (preview.data && preview.data.rows) {
      const prevData = {
        header: [],
        rows: preview.data.rows,
      };
      preview.data.schema.fields.forEach((item) => {
        prevData.header.push(item.name);
      });
      return prevData;
    }
    return EMPTY_OBJECT;
  },
);

export const getSumologicPreviewDataError = createSelector(
  getSumologicPreview,
  (preview) => preview.error,
);

export const getSumologicPreviewDataIsLoading = createSelector(
  getSumologicPreview,
  (preview) => preview.isLoading,
);

export const getSumologicPreviewDataItems = createSelector(
  getSumologicPreview,
  (preview) => get(preview, 'data.schema.fields', EMPTY_OBJECT),
);

export const getSumologicDataStreamDimensions = createSelector(
  getSelectedDataStream,
  (stream) => {
    const newArr = [];
    stream.dimensions.forEach((dim) => newArr.push({name: dim}));
    return newArr;
  },
);

export const getSumologicDataStreamMetrics = createSelector(
  getSelectedDataStream,
  (stream) => {
    const newArr = [];
    stream.metrics.forEach((met) => newArr.push({name: met}));
    return newArr;
  },
);

//* ** bigquery
export const getBigQueryStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.bigQueryStream,
);

export const getBigQueryStreamDataSets = createSelector(
  getBigQueryStream,
  (bq) => bq.dataSets,
);

export const getBigQueryStreamDataSetsIsLoading = createSelector(
  getBigQueryStreamDataSets,
  (ds) => ds.isLoading !== false,
);

export const getBigQueryStreamDataSetsItems = createSelector(
  getBigQueryStreamDataSets,
  (ds) => ds.data || EMPTY_ARRAY,
);

export const getBigQueryStreamDataSetsError = createSelector(
  getBigQueryStreamDataSets,
  (ds) => ds.error || EMPTY_OBJECT,
);

export const getBigQueryStreamProjects = createSelector(
  getBigQueryStream,
  (bq) => bq.projects,
);

export const getBigQueryStreamProjectsIsLoading = createSelector(
  getBigQueryStreamProjects,
  (pr) => pr.isLoading !== false,
);

export const getBigQueryStreamProjectsItems = createSelector(
  getBigQueryStreamProjects,
  (pr) => pr.data || EMPTY_ARRAY,
);

export const getBigQueryStreamQueryPreview = createSelector(
  getBigQueryStream,
  (bq) => bq.preview,
);

export const getBigQueryStreamQueryPreviewIsLoading = createSelector(
  getBigQueryStreamQueryPreview,
  (prv) => prv.isLoading,
);

export const getBigQueryStreamQueryPreviewItems = createSelector(
  getTimeZoneName,
  getBigQueryStreamQueryPreview,
  (tzn, prv) => {
    if (prv.data && !isEmpty(prv.data) && prv.data.columns) {
      const tzColIndexArr = [];
      prv.data.columns.forEach((col, i) => {
        if (col.type === 'timestamp') {
          tzColIndexArr.push(i);
        }
      });
      const tmpPrv = {...prv, data: {...prv.data}};
      tmpPrv.data.rows = (prv.data.rows || []).map((row) => {
        const tmpRow = [...row];
        tzColIndexArr.forEach((i) => {
          tmpRow[i] = getFormattedDateTime(tmpRow[i], tzn);
        });
        return tmpRow;
      });
      return tmpPrv.data;
    }
    return EMPTY_OBJECT;
  },
);

export const getBigQueryStreamQueryPreviewError = createSelector(
  getBigQueryStreamQueryPreview,
  (prv) => prv.error || EMPTY_OBJECT,
);

export const getBigQueryPreviewData = createSelector(
  getBigQueryStreamQueryPreviewItems,
  (prvItems) => {
    if (prvItems.columns) {
      const previewData = {
        header: [],
        rows: prvItems.rows,
      };
      prvItems.columns.forEach((item) => {
        previewData.header.push(item.name);
      });
      previewData.query = prvItems.query;
      return previewData;
    }
    return {};
  },
);

export const getBigQueryStreamVerifyQuery = createSelector(
  getBigQueryStream,
  (bq) => bq.verifyQuery,
);

export const getBigQueryStreamVerifyQueryIsLoading = createSelector(
  getBigQueryStreamVerifyQuery,
  (vq) => vq.isLoading === true,
);

export const getBigQueryStreamVerifyQueryData = createSelector(
  getBigQueryStreamVerifyQuery,
  (vq) => vq.data || EMPTY_OBJECT,
);

export const getBqQueryPreviewColumns = createSelector(
  getStreamUiState,
  (uiState) => uiState.queryPreviewColumns || [],
);

export const getBqStreamSelectedDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getBqQueryPreviewColumns,
  (dimensions, meta) =>
    meta
      .filter((a) => dimensions.includes(a.name))
      .sort((a, b) => dimensions.indexOf(a.name) - dimensions.indexOf(b.name)),
);

export const getBqStreamSelectedMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getBqQueryPreviewColumns,
  (metrics, meta) =>
    meta.filter((a) => metrics.includes(a.name)).sort((a, b) => metrics.indexOf(a.name) - metrics.indexOf(b.name)),
);

export const getBqStreamSelectedTimestampColumn = createSelector(
  getSelectedDataStream,
  getBqQueryPreviewColumns,
  (stream, meta) => meta.find((c) => c.name === stream.timestampColumn) || EMPTY_OBJECT,
);

export const getBqStreamSelectedTimestampColumnArr = createSelector(
  getBqStreamSelectedTimestampColumn,
  (td) => (!isEmpty(td) ? [td] : EMPTY_ARRAY),
);

export const getBqStreamUnAssignedNames = createSelector(
  getStreamUiState,
  (uiState) => uiState.unAssignedColumns || EMPTY_ARRAY,
);

export const getBqStreamUnAssignedColumns = createSelector(
  getBqStreamUnAssignedNames,
  getBqQueryPreviewColumns,
  (unAssignedNames, meta) =>
    meta
      .filter((a) => unAssignedNames.includes(a.name))
      .sort((a, b) => unAssignedNames.indexOf(a.name) - unAssignedNames.indexOf(b.name)),
);

export const getFilteredBqStreamUnAssignedColumns = createSelector(
  getBqStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.name.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

//* ** sql
export const getSqlStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.sqlStream,
);

export const getMySqlDatabaseList = createSelector(
  getSqlStream,
  (sql) => sql.mysqlDatabasesList,
);

export const getMySqlDatabaseListItems = createSelector(
  getMySqlDatabaseList,
  (sqlDbList) => sqlDbList.data || EMPTY_ARRAY,
);

export const getSchemasList = createSelector(
  getSqlStream,
  (sql) => sql.schemasList,
);

export const getSchemasListIsLoading = createSelector(
  getSchemasList,
  (sqlSchemasList) => sqlSchemasList.isLoading !== false,
);

export const getSchemasListItems = createSelector(
  getSchemasList,
  (sqlSchemasList) => sqlSchemasList.data.map((a) => ({name: a})),
);

export const getTablesViewsList = createSelector(
  getSqlStream,
  (sql) => sql.tablesViewsList,
);

export const getTablesViewsListIsLoading = createSelector(
  getTablesViewsList,
  (sqlTableList) => sqlTableList.isLoading !== false,
);

export const getTablesViewsListItems = createSelector(
  getTablesViewsList,
  (sqlTableList) => sqlTableList.data,
);

export const getTablesViewsMetadata = createSelector(
  getSqlStream,
  (sql) => sql.tablesViewsMetadata,
);

export const getTablesViewsMetadataItems = createSelector(
  getTablesViewsMetadata,
  (meta) => meta.data,
);

export const getSqlTablePreview = createSelector(
  getSqlStream,
  (sql) => sql.tablePreview,
);

export const getSqlTablePreviewData = createSelector(
  getSqlTablePreview,
  (sqlPreview) => sqlPreview.data || EMPTY_OBJECT,
);

export const getSqlStreamTablesMetadata = createSelector(
  getStreamUiState,
  (uiState) => uiState.tablesViewsMetadata || [],
);

export const getSqlQueryPreviewColumns = createSelector(
  getStreamUiState,
  (uiState) => uiState.queryPreviewColumns || [],
);

export const getSqlStreamSelectedDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getSqlStreamTablesMetadata,
  getSqlQueryPreviewColumns,
  (dimensions, meta, queryMeta) => {
    if (meta.length) {
      return uniqBy(
        meta
          .filter((a) => dimensions.includes(a.name))
          .sort((a, b) => dimensions.indexOf(a.name) - dimensions.indexOf(b.name)),
        'name',
      );
    }
    return uniqBy(
      queryMeta
        .filter((a) => dimensions.includes(a.name))
        .sort((a, b) => dimensions.indexOf(a.name) - dimensions.indexOf(b.name)),
      'name',
    );
  },
);

export const getSqlStreamSelectedMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getSqlStreamTablesMetadata,
  getSqlQueryPreviewColumns,
  (metrics, meta, queryMeta) => {
    if (meta.length) {
      return uniqBy(
        meta.filter((a) => metrics.includes(a.name)).sort((a, b) => metrics.indexOf(a.name) - metrics.indexOf(b.name)),
        'name',
      );
    }
    return uniqBy(
      queryMeta
        .filter((a) => metrics.includes(a.name))
        .sort((a, b) => metrics.indexOf(a.name) - metrics.indexOf(b.name)),
      'name',
    );
  },
);

export const getSqlStreamSelectedTimestampColumn = createSelector(
  getSelectedDataStream,
  getSqlStreamTablesMetadata,
  (stream, meta) => meta.find((c) => c.name === stream.timestampColumn) || EMPTY_OBJECT,
);

export const getSqlStreamSelectedTimestampType = createSelector(
  getSelectedDataStream,
  (stream) => stream.timestampType || 'timestamp',
);

export const getSqlFillAction = createSelector(
  getSelectedDataStream,
  (stream) => get(stream, 'missingDimPolicy.action', 'ignore'),
);

export const getSqlStreamSelectedTimestampColumnArr = createSelector(
  getSqlStreamSelectedTimestampColumn,
  (td) => (!isEmpty(td) ? [td] : EMPTY_ARRAY),
);

export const getSqlStreamUnAssignedNames = createSelector(
  getStreamUiState,
  (uiState) => uiState.unAssignedColumns || EMPTY_ARRAY,
);

export const getStreamType = createSelector(
  getSelectedDataStream,
  getStreamUiState,
  (stream, uiState) => {
    if (get(stream, 'uiState.selectedRadio', false) && stream.uiState.selectedRadio !== '') {
      return uiState.selectedRadio;
    }

    if (get(stream, 'customQuery', false)) {
      return !get(stream, 'basedOnTemplateId', '') ? 'query' : 'template';
    }
    return 'table';
  },
);

export const getSqlStreamUnAssignedColumns = createSelector(
  getSqlStreamUnAssignedNames,
  getSqlStreamTablesMetadata,
  (unAssignedNames, meta) =>
    meta
      .filter((a) => unAssignedNames.includes(a.name))
      .sort((a, b) => unAssignedNames.indexOf(a.name) - unAssignedNames.indexOf(b.name)),
);

export const getFilteredSqlStreamUnAssignedColumns = createSelector(
  getSqlStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.name.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

export const getSqlQueryStreamQueryPreview = createSelector(
  getSqlStream,
  (sql) => sql.preview,
);

export const getSqlQueryStreamQueryPreviewItems = createSelector(
  getTimeZoneName,
  getSqlQueryStreamQueryPreview,
  (tzn, prv) => {
    if (prv.data && !isEmpty(prv.data) && prv.data.columns) {
      const tzColIndexArr = [];
      prv.data.columns.forEach((col, i) => {
        if (col.type === 'timestamp') {
          tzColIndexArr.push(i);
        }
      });
      const tmpPrv = {...prv, data: {...prv.data}};
      tmpPrv.data.rows = (prv.data.rows || []).map((row) => {
        const tmpRow = [...row];
        tzColIndexArr.forEach((i) => {
          tmpRow[i] = getFormattedDateTime(tmpRow[i], tzn);
        });
        return tmpRow;
      });
      return tmpPrv.data;
    }
    return EMPTY_OBJECT;
  },
);

export const getSqlQueryStreamQueryPreviewError = createSelector(
  getSqlQueryStreamQueryPreview,
  (prv) => prv.error || EMPTY_OBJECT,
);

export const getSqlQueryPreviewData = createSelector(
  getSqlQueryStreamQueryPreviewItems,
  (prvItems) => {
    if (prvItems.columns) {
      const previewData = {
        header: [],
        rows: prvItems.rows,
      };
      prvItems.columns.forEach((item) => {
        previewData.header.push(item.name);
      });
      previewData.query = prvItems.query;
      return previewData;
    }
    return {};
  },
);

export const getSqlQueryStreamVerifyQuery = createSelector(
  getSqlStream,
  (sql) => sql.verifyQuery,
);

export const getSqlQueryStreamVerifyQueryIsLoading = createSelector(
  getSqlQueryStreamVerifyQuery,
  (vq) => vq.isLoading === true,
);

export const getSqlQueryStreamVerifyQueryData = createSelector(
  getSqlQueryStreamVerifyQuery,
  (vq) => vq.data || EMPTY_OBJECT,
);

export const getSqlQueryStreamQueryPreviewIsLoading = createSelector(
  getSqlQueryStreamQueryPreview,
  (prv) => prv.isLoading,
);

export const getSqlQueryTemplates = createSelector(
  getSqlStream,
  (sql) => sql.queryTemplates,
);

export const getSqlQueryTemplatesIsLoading = createSelector(
  getSqlQueryTemplates,
  (vq) => vq.isLoading === true,
);

export const getSqlQueryTemplatesData = createSelector(
  getSqlQueryTemplates,
  (vq) => vq.data || EMPTY_ARRAY,
);

export const getSqlQueryTemplatePreview = createSelector(
  getSqlStream,
  (sql) => sql.queryTemplatePreview,
);

export const getSqlDimensionsAndMetricsMetadataIsLoading = createSelector(
  getTablesViewsMetadata,
  getSqlQueryTemplatePreview,
  (tblMeta, qTempMeta) => tblMeta.isLoading || qTempMeta.isLoading,
);

export const getWorkgroupsList = createSelector(
  getSqlStream,
  (sql) => sql.athenaWorkgroupsList,
);

export const getWorkgroupsListItems = createSelector(
  getWorkgroupsList,
  (workgroups) => {
    if (workgroups.data && workgroups.data.length > 0) {
      workgroups.data.unshift({name: 'None', description: ''});
      return workgroups.data;
    }
    return EMPTY_ARRAY;
  },
);

export const getWorkgroupDescription = createSelector(
  getSqlStream,
  (sql) => sql.athenaWorkgroupDescription,
);

export const getWorkgroupDescriptionData = createSelector(
  getWorkgroupDescription,
  (description) => description.data || EMPTY_OBJECT,
);

export const getAthenaDatabasesList = createSelector(
  getSqlStream,
  (sql) => sql.athenaDatabasesList,
);

export const getAthenaDatabasesListData = createSelector(
  getAthenaDatabasesList,
  (dbs) => (dbs.data ? dbs.data.map((item) => ({name: item, text: item})) : EMPTY_ARRAY),
);

//* ** aws timestream
export const getTimestreamDatabaseList = createSelector(
  getSqlStream,
  (sql) => sql.timestreamDatabasesList,
);

export const getTimestreamDatabaseListItems = createSelector(
  getTimestreamDatabaseList,
  (dbs) => (dbs.data ? dbs.data.map((item) => ({name: item, text: item})) : EMPTY_ARRAY),
);

//* ** segment
export const getSegmentStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.segmentStream,
);

export const getSegmentAnoToken = createSelector(
  getSegmentStream,
  (segment) => segment.anoToken || EMPTY_OBJECT,
);

export const getSegmentAnoTokenItem = createSelector(
  getSegmentAnoToken,
  (segmentAnoToken) => get(segmentAnoToken.data, 'ano-token', ''),
);

export const getSegmentMessageTypes = createSelector(
  getSegmentStream,
  (segment) => segment.messageTypes || EMPTY_ARRAY,
);

export const getSegmentMessageTypesIsLoading = createSelector(
  getSegmentMessageTypes,
  (messageTypes) => messageTypes.isLoading,
);

export const getSegmentMessageTypesItems = createSelector(
  getSegmentMessageTypes,
  (messageTypes) =>
    get(messageTypes, 'data', []).map((item) => ({label: item.charAt(0).toUpperCase() + item.slice(1), value: item})),
);

//* ** mParticle
export const getMParticleStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.mParticleStream,
);

export const getMParticleAnoToken = createSelector(
  getMParticleStream,
  (mparticle) => mparticle.anoToken || EMPTY_OBJECT,
);

export const getMParticleAnoTokenItem = createSelector(
  getMParticleAnoToken,
  (mpAnoToken) => get(mpAnoToken.data, 'ano-token', ''),
);

export const getMParticleEventTypes = createSelector(
  getMParticleStream,
  (mparticle) => mparticle.eventTypes || EMPTY_ARRAY,
);

export const getMParticleEventTypesIsLoading = createSelector(
  getMParticleEventTypes,
  (mpEventTypes) => mpEventTypes.isLoading,
);

export const getMParticleEventTypesItems = createSelector(
  getMParticleEventTypes,
  (mpEventTypes) => {
    const eventTypes = mpEventTypes.data.map((item) => {
      if (item.custom) {
        return {
          ...item,
          displayName: `Custom: ${item.displayName}`,
        };
      }
      return {...item};
    });

    return eventTypes.sort((a, b) => {
      if (!a.custom && b.custom) {
        return -1000;
      }
      if (a.custom && !b.custom) {
        return 1000;
      }
      return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
    });
  },
);

export const getEventTypeMetadata = createSelector(
  getMParticleStream,
  (mparticle) => mparticle.eventMetadata,
);

export const getMParticleStreamEventTypeMetadataData = createSelector(
  getStreamUiState,
  (uiState) => uiState.eventMetadata || EMPTY_OBJECT,
);

export const getMParticleStreamEventTypeMetadataAllMetricsAndDimensions = createSelector(
  getMParticleStreamEventTypeMetadataData,
  (meta) => {
    if (isEmpty(meta)) {
      return EMPTY_ARRAY;
    }
    return meta.metrics.concat(meta.dimensions);
  },
);

export const getMParticleStreamSelectedDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getMParticleStreamEventTypeMetadataAllMetricsAndDimensions,
  (dimensions, meta) =>
    meta
      .filter((a) => dimensions.includes(a.path))
      .sort((a, b) => dimensions.indexOf(a.path) - dimensions.indexOf(b.path)),
);

export const getMParticleStreamSelectedMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getMParticleStreamEventTypeMetadataAllMetricsAndDimensions,
  (metrics, meta) =>
    meta.filter((a) => metrics.includes(a.path)).sort((a, b) => metrics.indexOf(a.path) - metrics.indexOf(b.path)),
);

export const getMParticleStreamUnAssignedNames = createSelector(
  getStreamUiState,
  (uiState) => uiState.unAssignedColumns || EMPTY_ARRAY,
);

export const getMParticleStreamUnAssignedColumns = createSelector(
  getMParticleStreamUnAssignedNames,
  getMParticleStreamEventTypeMetadataData,
  (unAssignedNames, meta) => {
    if (!isEmpty(meta)) {
      const items = meta.metrics.concat(meta.dimensions);
      return items
        .filter((a) => unAssignedNames.includes(a.path))
        .sort((a, b) => unAssignedNames.indexOf(a.path) - unAssignedNames.indexOf(b.name));
    }
    return EMPTY_ARRAY;
  },
);

export const getFilteredMParticleStreamUnAssignedColumns = createSelector(
  getMParticleStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.name.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

// ** Kinesis
export const getKinesisStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.kinesisStream,
);

export const getKinesisIsLoading = createSelector(
  getKinesisStream,
  (ks) => ks.kinesisPathsAndValues.isLoading === true,
);

export const getKinesisPathsAndValues = createSelector(
  getKinesisStream,
  (ks) => ks.kinesisPathsAndValues.data.paths || EMPTY_ARRAY,
);

export const getKinesisPaths = createSelector(
  getKinesisPathsAndValues,
  (ks) => Object.keys(ks).map((path) => ({label: path, value: path})),
);

export const getKinesisPathsIncludeCustom = createSelector(
  getKinesisPathsAndValues,
  getStreamUiState,
  (ks, uiState) => {
    const paths = Object.keys(ks);
    const uiFilters = get(uiState, 'uiFilters', EMPTY_ARRAY);
    uiFilters.forEach((filter) => {
      if (filter.path && paths.indexOf(filter.path.trim()) === -1) {
        paths.push(filter.path.trim());
      }
    });
    return paths.map((path) => ({label: path, value: path}));
  },
);

export const getKinesIsLoadingAnalysis = createSelector(
  getKinesisStream,
  (ks) => get(ks, 'kinesisFormatAnalysis.isLoading'),
);

export const getKinesisDefaultValue = createSelector(() => ({
  value: null,
  name: 'ANY',
}));

export const getKinesisValues = createSelector(
  getKinesisPathsAndValues,
  getStreamUiState,
  getKinesisIsLoading,
  getKinesisDefaultValue,
  (ks, uiState, isLoading, defaultValue) =>
    !isLoading && uiState.path && ks[uiState.path]
      ? [defaultValue].concat(
          ks[uiState.path].map((value) => ({
            value,
            name: value,
          })),
        )
      : EMPTY_ARRAY,
);

export const getKinesisStreamAnalysisSchema = createSelector(
  getFileStreamAnalysisResult,
  (analysisResult) => analysisResult.streamSchema || EMPTY_ARRAY,
);

export const getKinesisMetaDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getKinesisStreamAnalysisSchema,
  (dimensions, schema) =>
    schema
      .filter((a) => dimensions.includes(a.path))
      .sort((a, b) => dimensions.indexOf(a.path) - dimensions.indexOf(b.path)),
);

export const getKinesisMetaMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getKinesisStreamAnalysisSchema,
  (metrics, schema) =>
    schema.filter((a) => metrics.includes(a.path)).sort((a, b) => metrics.indexOf(a.path) - metrics.indexOf(b.path)),
);

export const getKinesisTimeDefinition = createSelector(
  getSelectedDataStream,
  (stream) => stream.timeDefinition || EMPTY_ARRAY,
);

export const getKinesisIsExternalTimeUsed = createSelector(
  getSelectedDataStream,
  (stream) => stream.useExternalTime,
);

export const getKinesisStreamSelectedTimeDefinitionCol = createSelector(
  getSelectedDataStream,
  getKinesisStreamAnalysisSchema,
  (stream, schema) => schema.find((c) => c.path === get(stream.timeDefinition, 'path')) || EMPTY_OBJECT,
);

export const getKinesisStreamSelectedTimeDefinitionColArr = createSelector(
  getKinesisStreamSelectedTimeDefinitionCol,
  (td) => (!isEmpty(td) ? [td] : EMPTY_ARRAY),
);

export const getKinesisStreamUnAssignedColumns = createSelector(
  getFileStreamUnAssignedIndices,
  getKinesisStreamAnalysisSchema,
  (unAssignedIndices, schema) =>
    schema
      .filter((a) => unAssignedIndices.includes(a.path))
      .sort((a, b) => unAssignedIndices.indexOf(a.path) - unAssignedIndices.indexOf(b.path)),
);

export const getFilteredKinesisStreamUnAssignedColumns = createSelector(
  getKinesisStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.path.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

// ** Salesforce
export const getSalesforceStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.salesforceStream,
);

export const getSalesforceIsLoading = createSelector(
  getSalesforceStream,
  (sf) => sf.salesforceObjects.isLoading === true,
);

export const getSalesforceObjects = createSelector(
  getSalesforceStream,
  (sf) => sf.salesforceObjects.items || EMPTY_ARRAY,
);

export const getSalesforceObjectsSorted = createSelector(
  getSalesforceObjects,
  (objects) => objects.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
);

export const getSalesforceObjData = createSelector(
  getSalesforceStream,
  (sf) => get(sf.salesforceObjectData, 'items.childRelationships') || EMPTY_ARRAY,
);

export const getSalesforceStreamTablesMetadata = createSelector(
  getStreamUiState,
  (uiState) => uiState.eventMetadata || EMPTY_OBJECT,
);

export const getSalesforceSelectedObject = createSelector(
  getSelectedDataStream,
  (stream) => get(stream, 'objects[0]', EMPTY_ARRAY),
);

// gets sources ids used by items recursively
const getSourceIdsArr = (sourcesIdArr, item) => {
  if (item.sourceColumn) {
    if (!sourcesIdArr.includes(item.sourceColumn)) {
      sourcesIdArr.push(item.sourceColumn);
    }
  } else if (item?.transform?.input) {
    item.transform.input.forEach((a) => {
      getSourceIdsArr(sourcesIdArr, a);
    });
  }
  return sourcesIdArr;
};

const getOriginalByName = (schemaColumn) => {
  if (schemaColumn?.transform?.input) {
    const sourceIdArr = getSourceIdsArr([], schemaColumn)[0];
    if (sourceIdArr) {
      if (typeof sourceIdArr === 'string') {
        return sourceIdArr;
      }
      if (sourceIdArr.name) {
        return sourceIdArr.name;
      }
    }
  }
  if (schemaColumn.sourceColumn) {
    return schemaColumn.sourceColumn;
  }
  return schemaColumn.name;
};

const getMetaDimetrics = (dimetrics, schema, meta, objectName) => {
  const newArr = [];
  if (!isEmpty(meta)) {
    schema
      .filter((a) => dimetrics.includes(getOriginalByName(a)))
      .sort((a, b) => dimetrics.indexOf(getOriginalByName(a)) - dimetrics.indexOf(getOriginalByName(b)))
      .forEach((k) => {
        const item = meta.fields
          ? meta.fields.find((l) => `${objectName}.${l.name}` === getOriginalByName(k))
          : meta.streamSchema.find((l) => l.path === getOriginalByName(k));
        if (!item) {
          return;
        }
        newArr.push({
          ...k,
          possibleTypes: item.possibleTypes,
        });
      });
  }
  return newArr;
};

export const getSalesforceDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getSelectedDataStreamSchemaColumns,
  getSalesforceStreamTablesMetadata,
  getSalesforceSelectedObject,
  (dimensions, schema, meta, objectName) => getMetaDimetrics(dimensions, schema, meta, objectName),
);

export const getSalesforceMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getSelectedDataStreamSchemaColumns,
  getSalesforceStreamTablesMetadata,
  getSalesforceSelectedObject,
  (metrics, schema, meta, objectName) => getMetaDimetrics(metrics, schema, meta, objectName),
);

export const getSalesforceTimestampColumnObj = createSelector(
  getSelectedDataStream,
  (stream) => ({name: stream.timestampColumn || get(stream, 'timeDefinition.field', null)}),
);

export const getSalesforceStreamSelectedTimestampColumn = createSelector(
  getSalesforceTimestampColumnObj,
  getSalesforceStreamTablesMetadata,
  getSalesforceSelectedObject,
  (timestampObj, meta, objectName) => {
    if (meta.streamSchema) {
      // sql query
      return meta.streamSchema.find((c) => c.path === timestampObj.name) || EMPTY_OBJECT;
    }
    // object selector
    return meta.fields.find((c) => `${objectName}.${c.name}` === timestampObj.name) || EMPTY_OBJECT;
  },
);

export const getSalesforceStreamSelectedTimestampType = createSelector(
  getSelectedDataStream,
  (stream) => stream.timestampType || 'timestamp',
);

export const getSalesforceStreamSelectedTimestampColumnArr = createSelector(
  getSalesforceStreamSelectedTimestampColumn,
  getSalesforceSelectedObject,
  // (td, objectName) => (!isEmpty(td) ? [{...td, name: `${objectName}.${td.name}`}] : EMPTY_ARRAY),
  (td, objectName) => {
    let res = EMPTY_ARRAY;
    let name = '';
    if (td.path) {
      name = td.path;
    } else {
      name = `${objectName}.${td.name}`;
    }
    if (!isEmpty(td)) {
      res = [
        {
          ...td,
          name,
        },
      ];
    }
    return res;
  },
);

export const getSalesforceStreamUnAssignedIndices = createSelector(
  getStreamUiState,
  (uiState) => uiState.unAssignedColumns || EMPTY_ARRAY,
);

export const getSalesforceIsQuery = createSelector(
  getSelectedDataStream,
  (stream) => !!get(stream, 'customQuery', false),
);

export const getSalesforceAnalysisSchemaFields = createSelector(
  getStreamUiState,
  getSalesforceIsQuery,
  (uiState, isQuery) => {
    if (isQuery) {
      return uiState.eventMetadata.streamSchema || EMPTY_ARRAY;
    }
    return uiState.eventMetadata.fields || EMPTY_ARRAY;
  },
);

export const getSalesforceStreamUnAssignedColumns = createSelector(
  getSalesforceStreamUnAssignedIndices,
  getSalesforceAnalysisSchemaFields,
  getSalesforceSelectedObject,
  getSalesforceIsQuery,
  (unAssignedIndices, fields, objectName, isQuery) => {
    if (isQuery) {
      return fields
        .map((field) => ({
          ...field,
          path: field.path,
        }))
        .filter((a) => unAssignedIndices.includes(a.path))
        .sort((a, b) => unAssignedIndices.indexOf(a.path) - unAssignedIndices.indexOf(b.path));
    }
    return fields
      .map((field) => ({
        ...field,
        name: `${objectName}.${field.name}`,
      }))
      .filter((a) => unAssignedIndices.includes(a.name))
      .sort((a, b) => unAssignedIndices.indexOf(a.name) - unAssignedIndices.indexOf(b.name));
  },
);

export const getFilteredSalesforceStreamUnAssignedColumns = createSelector(
  getSalesforceStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  getSalesforceIsQuery,
  (unAssignedColumns, filterTextInput, isQuery) =>
    filterTextInput
      ? unAssignedColumns.filter(
          (item) => item[isQuery ? 'path' : 'name'].toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1,
        )
      : unAssignedColumns,
);

export const isSalesforceDimatricsNotEmpty = createSelector(
  getSelectedDataStream,
  getSalesforceStreamUnAssignedIndices,
  (dataStream, unAssignedIndices) =>
    get(dataStream, 'dimensions', []).length + get(dataStream, 'metrics', []).length + unAssignedIndices.length > 0,
);

export const getActiveStreamsTotal = createSelector(
  getDataStreamsItems,
  (streams) => {
    const streamsItems = streams.filter((stream) => stream.state === 'running');
    return streamsItems.length ? streamsItems.length : 0;
  },
);

export const getSalesforceQueryStreamQueryPreview = createSelector(
  getSalesforceStream,
  (sql) => sql.preview,
);

export const getSalesforceQueryStreamQueryPreviewItems = createSelector(
  getTimeZoneName,
  getSalesforceQueryStreamQueryPreview,
  (tzn, prv) => {
    if (prv.data && !isEmpty(prv.data) && prv.data.streamSchema) {
      const tzColIndexArr = [];
      prv.data.streamSchema.forEach((col, i) => {
        if (col.type === 'timestamp') {
          tzColIndexArr.push(i);
        }
      });
      const tmpPrv = {...prv, data: {...prv.data}};
      tmpPrv.data.rows = (prv.data.rows || []).map((row) => {
        const tmpRow = [...row];
        tzColIndexArr.forEach((i) => {
          tmpRow[i] = getFormattedDateTime(tmpRow[i], tzn);
        });
        return tmpRow;
      });
      return tmpPrv.data;
    }
    return EMPTY_OBJECT;
  },
);

export const getSalesforceQueryStreamQueryPreviewError = createSelector(
  getSalesforceQueryStreamQueryPreview,
  (prv) => prv.error || EMPTY_OBJECT,
);

export const getSalesforceQueryPreviewData = createSelector(
  getSalesforceQueryStreamQueryPreviewItems,
  (prvItems) => {
    if (prvItems.rows) {
      const previewData = {
        header: [],
        rows: prvItems.rows,
      };
      prvItems.streamSchema.forEach((item) => {
        previewData.header.push(item.path);
      });
      previewData.query = prvItems.query;
      return previewData;
    }
    return {};
  },
);

export const getSalesforceQueryStreamQueryPreviewIsLoading = createSelector(
  getSalesforceQueryStreamQueryPreview,
  (prv) => prv.isLoading,
);

export const getIsFirstTimeVisit = createSelector(
  getPageVisited,
  getRoutingLocation,
  (pageVisited, routingLocation) => {
    const isThisPageVisited = pageVisited.find(
      (i) => getPageNameSegment(routingLocation.pathname) === i.name && i.firstTimeSig,
    );
    const date = new Date();
    const now = date.getTime();
    if (isThisPageVisited && isThisPageVisited.firstTimeSig) {
      const passingTime = isThisPageVisited ? now - isThisPageVisited.firstTimeSig : 0;
      return passingTime < 30 * 1000;
    }
    return true;
  },
);

//* **gads stream
export const getGoogleAdsStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.googleAdsStream,
);

//* **gads accounts
export const getGoogleAdsAccounts = createSelector(
  getGoogleAdsStream,
  (stream) => stream.accounts,
);

export const getGoogleAdsAccountsItems = createSelector(
  getGoogleAdsAccounts,
  (accounts) => accounts.data || EMPTY_ARRAY,
);

export const getGoogleAdsSelectedAccount = createSelector(
  getGoogleAdsAccountsItems,
  getSelectedDataStream,
  (accounts, dataStream) => {
    if (dataStream.clientCustomerId) {
      if (Array.isArray(dataStream.clientCustomerId)) {
        if (dataStream.clientCustomerId.length) {
          return accounts.filter((acc) => dataStream.clientCustomerId.includes(acc.id.toString()));
        }
        return [];
      }
      // support for older streams with one client id
      if (accounts.length) {
        const accArr = [];
        accArr.push(
          accounts.find(
            (acc) =>
              acc.id && dataStream.clientCustomerId && acc.id.toString() === dataStream.clientCustomerId.toString(),
          ),
        );
        return accArr;
      }
      return [];
    }
    return [];
  },
);

//* ** ga templates
export const getGoogleAdsTemplates = createSelector(
  getGoogleAdsStream,
  (stream) => stream.templates,
);

export const getGoogleAdsTemplatesItems = createSelector(
  getGoogleAdsTemplates,
  (templates) => templates.data || EMPTY_ARRAY,
);

export const getGoogleAdsTemplatesItemsOrdered = createSelector(
  getGoogleAdsTemplatesItems,
  (templatesItems) =>
    templatesItems.sort((a, b) => {
      if (a.id === '45') {
        // conversion analytics is to be first
        return -1;
      }
      if (b.id === '45') {
        return 1;
      }
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }),
);

export const getGoogleAdsSelectedTemplate = createSelector(
  getGoogleAdsTemplatesItemsOrdered,
  getSelectedDataStream,
  (templates, dataStream) => templates.find((acc) => acc.id === dataStream.basedOnTemplateId),
);

export const getSelectedDataStreamMetaMetrics = createSelector(
  getSelectedDataStreamMetrics,
  (metrics) => metrics.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
);

export const getSelectedDataStreamMetaDimensions = createSelector(
  getSelectedDataStreamDimensions,
  (dimensions) => dimensions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
);

export const getSelectedDataStreamMetaDimensionsWithoutTags = createSelector(
  getSelectedDataStreamDimensions,
  (dimensions) =>
    dimensions
      .filter((item) => item.indexOf('resourceTags') < 0)
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
);

//* **parquet  stream
export const getParquetStreamAnalysisResult = createSelector(
  getStreamUiState,
  (uiState) => uiState.analysisResult || EMPTY_OBJECT,
);

export const getParquetStreamAnalysisSchema = createSelector(
  getParquetStreamAnalysisResult,
  (analysisResult) => analysisResult.columns || EMPTY_ARRAY,
);

export const getParquetStreamSelectedDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getParquetStreamAnalysisSchema,
  (dimensions, schema) =>
    schema
      .filter((a) => dimensions.includes(a.name))
      .sort((a, b) => dimensions.indexOf(a.name) - dimensions.indexOf(b.name)),
);

export const getParquetStreamSelectedMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getParquetStreamAnalysisSchema,
  (metrics, schema) =>
    schema.filter((a) => metrics.includes(a.name)).sort((a, b) => metrics.indexOf(a.name) - metrics.indexOf(b.name)),
);

export const getParquetStreamSelectedTimeDefinitionObj = createSelector(
  getSelectedDataStream,
  (stream) => get(stream, 'timeDefinition') || EMPTY_OBJECT,
);

export const getParquetStreamSelectedTimeDefinitionCol = createSelector(
  getSelectedDataStream,
  getParquetStreamAnalysisSchema,
  (stream, schema) => schema.find((c) => c.name === get(stream, 'timeDefinition.name')) || EMPTY_OBJECT,
);

export const getParquetStreamSelectedTimeDefinitionColArr = createSelector(
  getParquetStreamSelectedTimeDefinitionCol,
  (td) => (!isEmpty(td) ? [td] : EMPTY_ARRAY),
);

export const isParquetAnalysisLoading = createSelector(
  getParquetStreamAnalysisResult,
  (analysisResult) => analysisResult.isLoading,
);

export const getParquetStreamUnAssignedIndices = createSelector(
  getStreamUiState,
  (uiState) => uiState.unAssignedColumns || EMPTY_ARRAY,
);

export const getParquetStreamUnAssignedColumns = createSelector(
  getParquetStreamUnAssignedIndices,
  getParquetStreamAnalysisSchema,
  (unAssignedIndices, schema) =>
    schema
      .filter((a) => unAssignedIndices.includes(a.name))
      .sort((a, b) => unAssignedIndices.indexOf(a.name) - unAssignedIndices.indexOf(b.name)),
);

export const getFilteredParquetStreamUnAssignedColumns = createSelector(
  getParquetStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.name.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

export const isParquetStreamUiStateDirty = createSelector(
  getSelectedDataStream,
  (stream) =>
    stream.inputLocation !== get(stream, 'uiState.inputLocation') ||
    stream.partitionFolderFormat !== get(stream, 'uiState.partitionFolderFormat') ||
    stream.inputFormat !== get(stream, 'uiState.inputFormat') ||
    stream.compression !== get(stream, 'uiState.compression'),
);

export const isParquetStreamCollectAllowed = createSelector(
  getSelectedDataStream,
  (stream) =>
    get(stream, 'uiState.partitionFolderFormat', false) &&
    get(stream, 'uiState.inputFormat', false) &&
    get(stream, 'uiState.compression', false),
);

//* ** AWS CUR
export const getCurStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.awsCurStream,
);

export const getCurReportsIsLoading = createSelector(
  getStreamUiState,
  (uiState) => uiState.reportsIsLoading !== false,
);

export const getCurReportsItems = createSelector(
  getStreamUiState,
  (uiState) => uiState.reports || [],
);

export const getCurReportsItemsObj = createSelector(
  getCurReportsItems,
  (curReportsItems) => {
    const periods = [];
    if (curReportsItems.length) {
      const regex = /\d{6}-\d{6}/g;
      if (curReportsItems[0].match(regex)) {
        curReportsItems.forEach((item) =>
          periods.push({
            value: item,
            label: `${item.substr(4, 2)}-${item.substr(0, 4)}`,
          }),
        );
      }
    }
    if (curReportsItems.length) {
      periods.push({
        value: null,
        label: 'collect from now on',
      });
    }
    return periods;
  },
);

export const awsCurSelectedTemplatesId = createSelector(
  getSelectedDataStream,
  (stream) => stream.basedOnTemplateId,
);

export const awsCurTemplatesIsLoading = createSelector(
  getStreamUiState,
  (uiState) => uiState.templatesIsLoading || false,
);

export const awsCurTemplates = createSelector(
  getStreamUiState,
  (uiState) => uiState.templates || [],
);

export const awsCurTemplatesItems = createSelector(
  awsCurTemplates,
  (templates) => templates.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
);

export const awsCurManifestsIsLoading = createSelector(
  getStreamUiState,
  (uiState) => uiState.manifestsIsLoading,
);

export const awsCurManifests = createSelector(
  getStreamUiState,
  (uiState) => uiState.manifests || [],
);

export const awsCurManifestsItems = createSelector(
  awsCurManifests,
  (manifests) => manifests.columns || [],
);

export const awsCurTagsItems = createSelector(
  awsCurManifestsItems,
  (items) =>
    items
      .filter((item) => item.category === 'resourceTags')
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .map((item) => ({id: `${item.category}/${item.name}`, name: item.name})),
);

export const awsCurSelectedTagsItems = createSelector(
  getSelectedDataStream,
  awsCurTagsItems,
  (stream, items) => items.filter((item) => stream.dimensions.includes(item.id)) || [],
);

export const getStreamSummaryTimestampColumn = createSelector(
  getSelectedDataStream,
  (stream) => stream.timestampColumn || null,
);

//* **facebook ds stream
export const getFacebookAdsStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.facebookAdsStream,
);

//* **facebook ads accounts
export const getFacebookAdsAccounts = createSelector(
  getFacebookAdsStream,
  (stream) => stream.accounts || EMPTY_OBJECT,
);

export const getFacebookAdsAccountsItems = createSelector(
  getFacebookAdsAccounts,
  (accounts) => accounts.data.ids || EMPTY_ARRAY,
);

export const getFacebookAdsReports = createSelector(
  getFacebookAdsStream,
  (stream) => stream.reports || EMPTY_OBJECT,
);

export const getFacebookAdsReportTypes = createSelector(
  getFacebookAdsReports,
  (reports) =>
    get(reports, 'data.model.reportTypes', EMPTY_ARRAY).map((item) => ({
      id: item,
      name: item.charAt(0).toUpperCase() + item.slice(1),
    })),
);

export const getFacebookAdsDimensionsByReportType = createSelector(
  getFacebookAdsReports,
  (reports) => get(reports, 'data.model.dimensionsByReportType', EMPTY_ARRAY),
);

export const getFacebookAdsTemplates = createSelector(
  getFacebookAdsStream,
  (stream) => stream.templates,
);

export const getFacebookAdsTemplatesData = createSelector(
  getFacebookAdsTemplates,
  (templates) => templates.data || EMPTY_ARRAY,
);

//* **google auctions
export const getGoogleAuctionsStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.googleAuctionsStream,
);

export const getGoogleAuctionsTemplates = createSelector(
  getGoogleAuctionsStream,
  (googleAuctionsStreamMeta) => googleAuctionsStreamMeta.templates,
);

export const getGoogleAuctionsTemplatesItems = createSelector(
  getGoogleAuctionsTemplates,
  (templates) => templates.data.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
);

export const getGoogleAuctionEmail = createSelector(
  getGoogleAuctionsStream,
  (gauctions) => gauctions.email || EMPTY_OBJECT,
);

export const getGoogleAuctionEmailIsLoading = createSelector(
  getGoogleAuctionEmail,
  (email) => email.isLoading !== false,
);

export const getGoogleAuctionEmailDataObject = createSelector(
  getGoogleAuctionEmail,
  (email) => email.data || EMPTY_OBJECT,
);

export const getGoogleAuctionEmailDataEmail = createSelector(
  getGoogleAuctionEmailDataObject,
  (data) => data.email || null,
);

export const getAuctionsStreamSelectedDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getFileStreamAnalysisSchema,
  (dimensions, schema) =>
    schema
      .filter((a) => dimensions.includes(a.name))
      .sort((a, b) => dimensions.indexOf(a.name) - dimensions.indexOf(b.name))
      .map((a) => ({name: a.name, possibleTypes: a.possibleTypes})),
);

export const getAuctionsStreamSelectedMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getFileStreamAnalysisSchema,
  (metrics, schema) =>
    schema
      .filter((a) => metrics.includes(a.name))
      .sort((a, b) => metrics.indexOf(a.name) - metrics.indexOf(b.name))
      .map((a) => ({name: a.name, possibleTypes: a.possibleTypes})),
);

export const getAuctionsStreamUnAssignedColumns = createSelector(
  getFileStreamUnAssignedIndices,
  getFileStreamAnalysisSchema,
  (unAssignedIndices, schema) =>
    schema
      .filter((a) => unAssignedIndices.includes(a.name))
      .sort((a, b) => unAssignedIndices.indexOf(a.name) - unAssignedIndices.indexOf(b.name))
      .map((a) => ({name: a.name, possibleTypes: a.possibleTypes})),
);

export const getFilteredAuctionsStreamUnAssignedColumns = createSelector(
  getAuctionsStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.name.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

//* **coralogix
export const getCoralogixStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.coralogixStream,
);

export const getCoralogixStreamMetrics = createSelector(
  getCoralogixStream,
  (cs) => cs.metrics,
);

export const getCoralogixStreamMetricsIsLoading = createSelector(
  getCoralogixStreamMetrics,
  (metrics) => metrics.isLoading !== false,
);

export const getCoralogixStreamMetricsItems = createSelector(
  getCoralogixStreamMetrics,
  (metrics) => metrics.data || EMPTY_ARRAY,
);

export const getCoralogixStreamLabels = createSelector(
  getCoralogixStream,
  (cs) => cs.labels,
);

export const getCoralogixStreamLabelsIsLoading = createSelector(
  getCoralogixStreamLabels,
  (labels) => labels.isLoading === true,
);

export const getCoralogixStreamLabelsItems = createSelector(
  getCoralogixStreamLabels,
  (labels) => labels.data || null,
);

export const getCoralogixStreamMeasures = createSelector(
  getCoralogixStream,
  (cs) => cs.measures,
);

export const getCoralogixStreamMeasuresIsLoading = createSelector(
  getCoralogixStreamMeasures,
  (measures) => measures.isLoading !== false,
);

export const getCoralogixStreamMeasuresItems = createSelector(
  getCoralogixStreamMeasures,
  (measures) => measures.data || null,
);

export const getCoralogixMetaDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getSelectedDataStreamSchemaColumns,
  (dimensions, columns) =>
    columns
      .filter((a) => dimensions.includes(a.name))
      .sort((a, b) => dimensions.indexOf(a.name) - dimensions.indexOf(b.name)),
);

export const getCoralogixMetaMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getSelectedDataStreamSchemaColumns,
  (metrics, columns) =>
    columns.filter((a) => metrics.includes(a.name)).sort((a, b) => metrics.indexOf(a.name) - metrics.indexOf(b.name)),
);

export const getCoralogixStreamUnAssignedIndices = createSelector(
  getStreamUiState,
  (uiState) =>
    uiState.unAssignedColumns
      ? uiState.unAssignedColumns.map((item) => ({
          id: getUniqueId(),
          name: item,
          sourceColumn: item,
        }))
      : EMPTY_ARRAY,
);

export const getFilteredCoralogixStreamUnAssignedColumns = createSelector(
  getFileStreamUnAssignedIndices,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.name.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

//* ** new relic
export const getNewRelicStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.newRelicStream,
);

export const getNewRelicStreamAccounts = createSelector(
  getNewRelicStream,
  (stream) => stream.accounts || EMPTY_ARRAY,
);

export const getNewRelicStreamAccountsIsLoading = createSelector(
  getNewRelicStreamAccounts,
  (accounts) => accounts.isLoading,
);

export const getNewRelicStreamAccountsItems = createSelector(
  getNewRelicStreamAccounts,
  (accounts) => get(accounts, 'data.accounts', []),
);

export const getNewrelicStreamkeysetMetadataData = createSelector(
  getStreamUiState,
  (uiState) => uiState.keyset || EMPTY_OBJECT,
);

export const getNewrelicKeysetMetadataAllMetricsAndDimensions = createSelector(
  getNewrelicStreamkeysetMetadataData,
  (meta) => {
    if (isEmpty(meta)) {
      return EMPTY_ARRAY;
    }
    const metrics = meta.metrics || EMPTY_ARRAY;
    const dimensions = meta.dimensions || EMPTY_ARRAY;
    return metrics.concat(dimensions);
  },
);

export const getNewrelicKeysetIsLoading = createSelector(
  getNewrelicStreamkeysetMetadataData,
  (meta) => meta.isLoading,
);

export const getNewrelicStreamSelectedDimensions = createSelector(
  getSelectedDataStreamDimensions,
  getNewrelicKeysetMetadataAllMetricsAndDimensions,
  (dimensions, meta) => {
    return meta
      .filter((a) => dimensions.includes(a))
      .sort((a, b) => dimensions.indexOf(a) - dimensions.indexOf(b))
      .map((i) => {
        return {name: i};
      });
  },
);

export const getNewrelicStreamSelectedMetrics = createSelector(
  getSelectedDataStreamMetrics,
  getNewrelicKeysetMetadataAllMetricsAndDimensions,
  (metrics, meta) => {
    return meta
      .filter((a) => metrics.includes(a))
      .sort((a, b) => metrics.indexOf(a) - metrics.indexOf(b))
      .map((i) => {
        return {name: i};
      });
  },
);

export const getNewrelicStreamUnAssignedNames = createSelector(
  getStreamUiState,
  (uiState) => uiState.unAssignedColumns || EMPTY_ARRAY,
);

export const getNewrelicStreamUnAssignedColumns = createSelector(
  getNewrelicStreamUnAssignedNames,
  getNewrelicStreamkeysetMetadataData,
  (unAssignedNames, meta) => {
    if (!isEmpty(meta)) {
      const metrics = meta.metrics || EMPTY_ARRAY;
      const dimensions = meta.dimensions || EMPTY_ARRAY;
      const items = metrics.concat(dimensions);
      return items
        .filter((a) => unAssignedNames.includes(a))
        .sort((a, b) => unAssignedNames.indexOf(a) - unAssignedNames.indexOf(b))
        .map((i) => {
          return {name: i};
        });
    }
    return EMPTY_ARRAY;
  },
);

export const getFilteredNewrelicStreamUnAssignedColumns = createSelector(
  getNewrelicStreamUnAssignedColumns,
  getStreamMetricsDimentionsFilterTextInput,
  (unAssignedColumns, filterTextInput) =>
    filterTextInput
      ? unAssignedColumns.filter((item) => item.name.toLowerCase().indexOf(filterTextInput.toLowerCase()) !== -1)
      : unAssignedColumns,
);

export const getNewrelicPreview = createSelector(
  getNewRelicStream,
  (stream) => stream.filePreview,
);

export const getNewrelicPreviewData = createSelector(
  getNewrelicPreview,
  (preview) => preview.data || EMPTY_OBJECT,
);

export const getNewrelicPreviewDataRows = createSelector(
  getNewrelicPreviewData,
  (data) => {
    if (data.rows) {
      let rowCount = 0;
      const previewData = {
        header: [],
        rows: [],
      };
      // eslint-disable-next-line no-restricted-syntax, no-unused-vars
      for (const [key, value] of Object.entries(data.rows)) {
        previewData.header.push(key);
        rowCount = value.length;
      }
      for (let i = 0; i < rowCount; i++) {
        previewData.header.forEach((element, key) => {
          if (key === 0) {
            previewData.rows.push([]);
          }

          let val = data.rows[element][i];
          if (typeof val === 'object') {
            val = JSON.stringify(val);
          }
          previewData.rows[i].push(val);
        });
      }
      return previewData;
    }
    return EMPTY_OBJECT;
  },
);

//* **datadog
export const getDatadogStream = createSelector(
  getBusinessCollectors,
  (bc) => bc.datadogStream,
);

export const getDatadogStreamMetrics = createSelector(
  getDatadogStream,
  (dd) => dd.metrics,
);

export const getDatadogStreamMetricsIsLoading = createSelector(
  getDatadogStreamMetrics,
  (metrics) => metrics.isLoading !== false,
);

export const getDatadogStreamMetricsItems = createSelector(
  getDatadogStreamMetrics,
  (metrics) => metrics.data || EMPTY_ARRAY,
);

export const getDatadogStreamMetricDescription = createSelector(
  getDatadogStream,
  (dd) => dd.metricDescription,
);

export const getDatadogStreamMetricDescriptionIsLoading = createSelector(
  getDatadogStreamMetricDescription,
  (mDesc) => mDesc.isLoading === true,
);

export const getDatadogStreamQueryPreview = createSelector(
  getDatadogStream,
  (dd) => dd.queryPreview,
);

export const getDatadogStreamQueryPreviewIsLoading = createSelector(
  getDatadogStreamQueryPreview,
  (prev) => prev.isLoading === true,
);

export const getDatadogStreamQueryPreviewData = createSelector(
  getDatadogStreamQueryPreview,
  (prev) => prev.data || EMPTY_OBJECT,
);

export const getDatadogStreamQueryPreviewError = createSelector(
  getSqlQueryStreamQueryPreview,
  (prev) => prev.error || EMPTY_OBJECT,
);

export const getDatadogStreamQueryPreviewDataProcessed = createSelector(
  getDatadogStreamQueryPreviewData,
  (prevData) => {
    if (prevData.columns) {
      const previewData = {
        header: ['timestamp', prevData.metricName, ...prevData.columns],
        rows: (prevData.rows || []).map((row) => {
          return [row.timestamp, row.value, ...(row.tags || [])];
        }),
        query: prevData.query,
      };
      return previewData;
    }
    return {};
  },
);

export const getDatadogStreamUiStateDescribeMetric = createSelector(
  getStreamUiState,
  (uiState) => uiState.describeMetric || EMPTY_OBJECT,
);

export const getDatadogStreamUiStateTagsAndValues = createSelector(
  getDatadogStreamUiStateDescribeMetric,
  (uiStateDescribeMetric) => uiStateDescribeMetric.tagsAndValues || EMPTY_OBJECT,
);

export const getDatadogStreamUiStateTags = createSelector(
  getDatadogStreamUiStateTagsAndValues,
  (tagsAndValues) =>
    (Object.keys(tagsAndValues) || EMPTY_ARRAY).map((t) => ({
      id: t,
      name: t,
    })),
);
