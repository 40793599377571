import {combineReducers} from 'redux';
import {composeReducers} from 'common/utils/reducers';

const labelsView = composeReducers((state = [], {type}) => {
  switch (type) {
    default:
      return state;
  }
});

export default combineReducers({
  labelsView,
});
