// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Tooltip from 'common/componentsV2/Tooltip';
import Loading from 'metrics/components/Loading';
import * as selectors from 'bc/store/selectors';
import {fetchFuFileProperties as fetchFuFilePropertiesAction} from 'bc/store/actions';
import {abbrBytesToFileSize} from 'common/utils/numbers';
import '../../ViewStreamSummaryModalV2.module.scss';

type PropTypes = {
  dataStream: Object,

  // connect
  file: Object,
  fetchFuFileProperties: Function,
};

export default connect(
  (state) => ({
    file: selectors.getFileProperties(state),
  }),
  {
    fetchFuFileProperties: fetchFuFilePropertiesAction,
  },
)(
  class FileData extends PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {
        dataStream: {dataSourceId},
        fetchFuFileProperties,
      } = this.props;

      fetchFuFileProperties(dataSourceId);
    }

    render() {
      const {file} = this.props;

      return (
        <div styleName="section">
          <div styleName="vs-section-header">File Data</div>
          <div styleName="vs-row">
            <div styleName="vs-label">Name</div>
            {!file.isLoading ? (
              <div styleName="vs-value with-ellipsis">
                <Tooltip placement="top" content={file.data.fileName} delay={400}>
                  <span>{file.data.fileName}</span>
                </Tooltip>
              </div>
            ) : (
              <Loading styleName="spinner" />
            )}
          </div>
          <div styleName="vs-row">
            <div styleName="vs-label">Size</div>
            {!file.isLoading ? (
              <div styleName="vs-value">{abbrBytesToFileSize(file.data.fileSize)}</div>
            ) : (
              <Loading styleName="spinner" />
            )}
          </div>
        </div>
      );
    }
  },
);
