export default function useGetPreviewDataForTable(res) {
  let columns = [];
  const data = [];

  if (res?.header?.length) {
    columns = res.header.map((i) => ({
      title: i,
      id: i,
    }));
  }

  if (res?.rows?.length) {
    res.rows.forEach((row) => {
      const newRow = {};
      row.forEach((i, index) => {
        newRow[res.header[index]] = i;
      });
      data.push(newRow);
    });
  }

  return {columns, data};
}
