import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {palette} from 'app/styles/theme';

const MinimizeTitle = ({title}: PropTypes) => {
  return (
    <>
      {title ? (
        <TypographyBox width="90%" variant="h5" color={palette.gray['400']} mt={0.5} isEllipsis>
          {title}
        </TypographyBox>
      ) : null}
    </>
  );
};

MinimizeTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default memo(MinimizeTitle);
