import {useCallback, useEffect} from 'react';
import useFieldsToTriggerByStep from 'dataCollectors/hooks/useFieldsToTriggerByStep';
import {useFormContext} from 'react-hook-form';
import {isEmpty} from 'lodash';

import {useEventStreamContext} from 'dataCollectors/hooks/useEventStreamContext';
import {STEPS_ACTIONS} from 'dataCollectors/pages/eventStream/hooks/editPageReducer';

export default function useNavigationAndTriggerForm(currentStepIndex) {
  const {
    trigger,
    formState: {errors},
    reset,
  } = useFormContext();

  const fieldsToTrigger = useFieldsToTriggerByStep(currentStepIndex);
  const {editStreamPageReducerDispatch, maxPages} = useEventStreamContext();

  const triggerErrors = async (nextStep) => {
    if (nextStep > currentStepIndex) {
      await trigger(fieldsToTrigger, {shouldFocus: true});
      reset({}, {keepValues: true, keepTouched: true, keepErrors: true, keepDirty: true});
    }
    // No need to trigger errors when user go backwards.
    if (nextStep < currentStepIndex) {
      reset({}, {keepValues: true, keepTouched: false, keepErrors: false, keepDirty: true});
    }
  };

  const jumpToStep = useCallback(
    async (nextStep, callback = null) => {
      await triggerErrors(nextStep);

      if (nextStep !== maxPages && isEmpty(errors)) {
        editStreamPageReducerDispatch({type: STEPS_ACTIONS.SET_CURRENT_STEP, payload: {index: nextStep}});
      }

      if (nextStep === maxPages && isEmpty(errors)) {
        callback();
      }
    },
    [errors, currentStepIndex],
  );

  useEffect(() => {
    // we clean the errors every time step is change because the form keep errors from other steps if there were some and then we can't move to next step and deal with them.
    reset({}, {keepValues: true, keepTouched: false, keepErrors: false, keepDirty: true});
  }, [currentStepIndex]);

  return {jumpToStep};
}
