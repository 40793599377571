import React from 'react';
import StreamSummary from 'dataCollectors/components/streamSumary/StreamSummary';

const LoadPage = () => {
  return (
    <>
      <StreamSummary />
    </>
  );
};

LoadPage.propTypes = {};

export default LoadPage;
