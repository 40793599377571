// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {fetchBigQueryDataSets as fetchBigQueryDataSetsAction, setBigQueryStreamDataSet} from 'bc/store/actions';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  dataStream: Object,

  // connect
  isLoadingDataSets: boolean,
  dataSets: Object,
  dataSetsError: Object,
  fetchBigQueryDataSets: Function,
  setBigQueryStreamDataSet: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoadingDataSets: selectors.getBigQueryStreamDataSetsIsLoading(state),
    dataSetsError: selectors.getBigQueryStreamDataSetsError(state),
    dataSets: selectors.getBigQueryStreamDataSetsItems(state),
  }),
  {
    fetchBigQueryDataSets: fetchBigQueryDataSetsAction,
    setBigQueryStreamDataSet,
  },
)(
  class DataSets extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {fetchBigQueryDataSets, dataStream: stream} = this.props;
      if (stream.projectId) {
        fetchBigQueryDataSets({dataSourceId: stream.dataSourceId, projectId: stream.projectId});
      }
    }

    datasetChanged = (item) => {
      this.props.setBigQueryStreamDataSet({defaultDataset: item ? `${item.project}.${item.id}` : ''});
    };

    render() {
      const {dataStream, isLoadingDataSets, dataSets, dataSetsError} = this.props;
      const selectedIndex = dataSets.findIndex((val) => `${val.project}.${val.id}` === dataStream.defaultDataset);
      return (
        <div className="shell-col">
          <SelectAndt
            optionHeight={40}
            className="andt-dropdown"
            disabled={!dataStream.projectId || isLoadingDataSets || dataSetsError.uMessage}
            options={dataSets}
            getOptionLabel={(val) => `${val.id}`}
            getOptionValue={(val) => `${val.project}.${val.id}`}
            type={TYPE_SEARCH}
            theme={THEME_LIGHT}
            value={isLoadingDataSets ? null : dataSets[selectedIndex]}
            onChange={this.datasetChanged}
            placeholder={isLoadingDataSets ? 'Loading...' : 'Data Set (Optional)'}
            automationId="dataSet"
          />
        </div>
      );
    }
  },
);
