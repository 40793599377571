// @flow
import React, {useCallback, useMemo} from 'react';
import {InfiniteLoader, AutoSizer, List} from 'react-virtualized';

// docs: https://github.com/bvaughn/react-virtualized/blob/master/docs/InfiniteLoader.md

type PropsType = {
  list: Array<Object>,
  rowHeight: Function | number,
  isLoadingNextPage: boolean,
  nextPage: string,
  threshold: number,
  loadMoreRows: Function,
  styleList: Object,
  totalCount: number,
};

const NOOP = () => {};

const InfiniteList = ({
  list,
  rowHeight,
  nextPage,
  isLoadingNextPage,
  threshold,
  loadMoreRows,
  styleList,
  totalCount,
}: PropsType) => {
  // eslint-disable-next-line react/prop-types
  const rowRenderer = ({index, key, style}) => (
    <div key={key} style={style}>
      {list[index]}
    </div>
  );

  const isRowLoaded = ({index}: PropsType) => !!list[index];

  const handleLoadMoreRows = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    nextPage && !isLoadingNextPage ? loadMoreRows() : NOOP;
  }, [nextPage, loadMoreRows, isLoadingNextPage]);

  const rowCount = useMemo(() => (nextPage ? totalCount : list.length), [list, nextPage, totalCount]);

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={handleLoadMoreRows}
      rowCount={rowCount}
      threshold={threshold}
    >
      {({onRowsRendered, registerChild}) => (
        <AutoSizer>
          {/* eslint-disable-next-line react/prop-types */}
          {({height, width}) => (
            <List
              height={height}
              onRowsRendered={onRowsRendered}
              rowCount={rowCount}
              ref={registerChild}
              rowHeight={rowHeight}
              width={width}
              rowRenderer={rowRenderer}
              style={styleList}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};

export default React.memo(InfiniteList);
