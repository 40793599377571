import React, {useEffect, useState} from 'react';
import {useQueryClient} from 'react-query';

import useWatch from 'dataCollectors/hooks/useWatch';
import {rangeTypes} from 'common/utils/dateRangeService';
import {useFormContext} from 'react-hook-form';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import {POLLING_INTERVAL_OPTIONS} from 'dataCollectors/services/scheduleService';
import {STREAM_STATE_OPTIONS} from 'dataCollectors/services/dataCollectorsService';
import ContentLoaderPreview from 'dataCollectors/components/loaders/ContentLoaderPreview';
import {QUERY_STATUSES} from 'reactQuery/reactQueryService';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import usePreviewClasses from 'dataCollectors/styles/classes.usePreviewClasses';
import EditModal from 'dataCollectors/components/EditModal/EditModal';
import Schedule from 'dataCollectors/components/schedule/Schedule';

const {POLLING_INTERVAL, HISTORY_DATE_RANGE, TIMEZONE} = EVENT_STREAM_FIELDS;

const {STREAM_ID} = EVENT_STREAM_FIELDS;

const SchedulePreview = () => {
  // eslint-disable-next-line no-unused-vars
  const watch = useWatch(); // Dont remove! It make the form to be editable from the preview mode.
  const previewClasses = usePreviewClasses();
  const queryClient = useQueryClient();

  const [dateRange, setDateRange] = useState(null);
  const [timeZone, setTimeZone] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isLoading = queryClient.getQueryState([QUERY_KEYS.streams]).status === QUERY_STATUSES.loading;

  const {getValues} = useFormContext();
  const stream = getValues();

  useEffect(() => {
    setDateRange(rangeTypes[stream[HISTORY_DATE_RANGE.id].constRange].text);
    setTimeZone(stream[TIMEZONE.id]);
  }, [stream[STREAM_ID.id]]);

  return (
    <div>
      <div className={previewClasses.sectionWrapper}>
        {isLoading && <ContentLoaderPreview />}
        {!isLoading && (
          <>
            {stream.state === STREAM_STATE_OPTIONS.new.value && (
              <div className={previewClasses.editBtn} onClick={() => setIsModalOpen(!isModalOpen)}>
                Edit
              </div>
            )}

            <div className={previewClasses.content}>
              <div>
                <span className={previewClasses.sectionTitle}>Schedule file collection</span>
              </div>
              <div className={previewClasses.row}>
                <span>{POLLING_INTERVAL?.name}</span>
                <span>{POLLING_INTERVAL_OPTIONS[stream[POLLING_INTERVAL.id]]?.label}</span>
              </div>

              <div className={previewClasses.row}>
                <span>{HISTORY_DATE_RANGE.name}</span>
                <span>{dateRange}</span>
              </div>

              <div className={previewClasses.row}>
                <span>{TIMEZONE.name}</span>
                <span>{timeZone}</span>
              </div>
            </div>
          </>
        )}
        <EditModal
          isOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          additionalText="Changes may affect the notifications settings"
        >
          <Schedule />
        </EditModal>
      </div>
    </div>
  );
};

export default SchedulePreview;
