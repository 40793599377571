// @flow
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import moment from 'moment';
import {makeStyles} from '@material-ui/core';
import Input from 'common/componentsV2/Input';
import DatePicker from 'common/componentsV2/customDateRangeV2/DatePicker';
import {correctTimestamp} from 'common/utils/dateRangeService';
import useClickOutside from 'common/hooks/useClickOutside';
import TimePicker from './TimePicker';
import {formattedValue} from './customDateRangeService';

const useStyles = makeStyles(() => ({
  arrowRight: {
    position: 'absolute',
    cursor: 'pointer',
    right: 20,
    top: 20,
  },
  arrowLeft: {
    position: 'absolute',
    cursor: 'pointer',
    left: 20,
    top: 20,
  },
  rangeWrapper: {
    display: 'flex',
  },
  rangeSelection: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 20,
  },
  datePickerWrapper: {
    position: 'relative',
    width: 302,
    minHeight: 283,
    backgroundColor: 'white',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 8,
    padding: 20,
    zIndex: 1,
  },
}));

const CalendarRange = ({
  value,
  errors,
  onChange,
  disabled,
}: {
  value: Object,
  errors: Object,
  onChange: Function,
  disabled: boolean,
}) => {
  const classes = useStyles();
  const dropdownRef = useRef();
  const [isStartMode, setIsStartMode] = useState(true);
  const [isDatePickkerVisible, setIsDatePickkerVisible] = useState(false);
  const actualMonth = new Date().getMonth();
  const actualYear = new Date().getFullYear();
  const endMonth = value.constRange === 'c' && value.endDate && new Date(correctTimestamp(value.endDate)).getMonth();
  const endYear = value.constRange === 'c' && value.endDate && new Date(correctTimestamp(value.endDate)).getFullYear();
  const [date, setDate] = useState({
    currentMonth: endMonth === 0 ? 0 : endMonth || actualMonth,
    currentYear: endYear || actualYear,
  });

  // Set default for today
  useEffect(() => {
    const start = new Date();
    start.setHours(0, 0, 0);
    const end = new Date();
    end.setHours(23, 59, 59);
    if (!value?.startDate && !value?.endDate) {
      onChange({...value, startDate: start, endDate: end});
    }
  }, [disabled]);

  const setSlide = useCallback(
    (direction) => {
      let updatedMonth;
      let updatedYear;
      if (direction === 'next') {
        updatedMonth = date.currentMonth === 11 ? 0 : date.currentMonth + 1;
        updatedYear = date.currentMonth === 11 ? date.currentYear + 1 : date.currentYear;
      }
      if (direction === 'prev') {
        updatedMonth = date.currentMonth === 0 ? 11 : date.currentMonth - 1;
        updatedYear = date.currentMonth === 0 ? date.currentYear - 1 : date.currentYear;
      }
      setDate({
        ...date,
        currentMonth: updatedMonth,
        currentYear: updatedYear,
      });
    },
    [date],
  );
  const setCurrentMonth = useCallback((month) => setDate({...date, currentMonth: month}), [date]);
  const setCurrentYear = useCallback((year) => setDate({...date, currentYear: year}), [date]);

  const displayNextSlide = useCallback(() => setSlide('next'), [setSlide]);
  const displayPrevSlide = useCallback(() => setSlide('prev'), [setSlide]);

  const displayDatePicker = useCallback(() => setIsDatePickkerVisible(true), [setIsDatePickkerVisible]);
  const daysCount = useMemo(() => {
    const start = moment(value.startDate);
    const end = moment(value.endDate);
    return end.diff(start, 'days') + 1;
  }, [value]);

  const handleClickOutside = () => {
    // if only start date selected, set default end date
    if (!isStartMode) {
      onChange({...value, endDate: new Date(value.startDate).setHours(23, 59, 59)});
      setIsStartMode(!isStartMode);
    }
    setIsDatePickkerVisible(false);
  };
  useClickOutside(dropdownRef, () => handleClickOutside());
  const onDatePickerChange = (pickedDate) => {
    if (isStartMode) {
      onChange({...value, startDate: new Date(pickedDate).setHours(0, 0, 0), endDate: '', isInvalid: false});
      setIsStartMode(!isStartMode);
    } else if (value.startDate > pickedDate) {
      onChange({...value, startDate: new Date(pickedDate).setHours(0, 0, 0), endDate: '', isInvalid: false});
    } else {
      onChange({
        ...value,
        startDate: value.startDate,
        endDate: new Date(pickedDate).setHours(23, 59, 59),
        isInvalid: false,
      });
      setIsStartMode(!isStartMode);
      setIsDatePickkerVisible(false);
    }
  };

  return (
    <div ref={dropdownRef}>
      <div className={classes.rangeWrapper}>
        <div className={classes.rangeSelection}>
          From
          <Input
            value={formattedValue(value.startDate)}
            onChange={() => {}}
            fullSize
            style={{height: 36, width: 144}}
            onFocus={() => displayDatePicker()}
            automationId="fromDate"
            tabIndex={disabled ? '-1' : null}
          />
          <TimePicker
            value={value.startDate}
            errors={errors?.startTime}
            onChange={(newDate) => onChange({...value, startDate: newDate, isInvalid: false})}
            setValidity={(state) => onChange({...value, isInvalid: state})}
            automationId="fromTime"
            tabIndex={disabled ? '-1' : null}
          />
        </div>
        <div className={classes.rangeSelection}>
          To
          <Input
            value={formattedValue(value.endDate)}
            onChange={() => {}}
            fullSize
            style={{height: 36, width: 144}}
            onFocus={() => displayDatePicker()}
            automationId="toDate"
            tabIndex={disabled ? '-1' : null}
          />
          <TimePicker
            value={value.endDate}
            errors={errors?.endTime}
            onChange={(newDate) => onChange({...value, endDate: newDate, isInvalid: false})}
            setValidity={(state) => onChange({...value, isInvalid: state})}
            automationId="toTime"
            tabIndex={disabled ? '-1' : null}
          />
        </div>
        {!isNaN(daysCount) && (
          <div style={{paddingTop: 30}}>{`(${daysCount} ${daysCount === 1 ? 'Day' : 'Days'})`}</div>
        )}
      </div>
      {isDatePickkerVisible && (
        <div className={classes.datePickerWrapper}>
          <div className={classes.arrowRight} onClick={displayNextSlide}>
            <i className="icon icn-arrow16-chevronright" />
          </div>
          <div className={classes.arrowLeft} onClick={displayPrevSlide}>
            <i className="icon icn-arrow16-chevronleft" />
          </div>
          <DatePicker
            value={value}
            onChange={onDatePickerChange}
            currentMonth={date.currentMonth}
            setCurrentMonth={setCurrentMonth}
            currentYear={date.currentYear}
            setCurrentYear={setCurrentYear}
          />
        </div>
      )}
    </div>
  );
};

export default CalendarRange;
