import React, {memo} from 'react';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {getFormattedHumanDuration} from 'common/utils/dateService';
import {getTimeZoneName} from 'profile/store/selectors';
import {SIZE as BadgeSizes, TriggerType} from 'investigation/components/tabs/Badges';
import {palette} from 'app/styles/theme';
import {ALERT_TYPES} from 'investigation/services/investigationService';
import {getInvModalTrigger, getInvScore} from 'investigation/store/selectors';
import ImpactChip from 'impact/ImpactChip';
import IncidentDateTimeProperties from './IncidentDateTimeProperties';

import './IncidentTab.module.scss';

const IncidentDetails = () => {
  const trigger = useSelector(getInvModalTrigger);
  if (isEmpty(trigger)) {
    return null;
  }
  const score = useSelector(getInvScore);
  const timeZoneName = useSelector(getTimeZoneName);

  const {startTime, triggerTime, duration, status} = trigger;
  const isOpen = status.toLowerCase() === 'open';
  const durText = getFormattedHumanDuration(duration).trim();
  const fullDurText = isOpen ? `for ${durText}` : `lasted ${durText}`;
  const statusText = isOpen ? 'Open' : 'Closed,';

  const getMinLastSeenTime = (metrics) => {
    const lastSeenTimes = metrics
      ?.flatMap((metric) => metric.intervals)
      ?.filter((interval) => interval.lastSeenTime)
      .map((interval) => interval.lastSeenTime);
    return Math.min(...lastSeenTimes);
  };

  return (
    <div styleName="header-container">
      <div styleName="badges">
        <TriggerType type={trigger.type} size={BadgeSizes.SMALL} />

        {trigger.type === ALERT_TYPES.ANOMALY && (
          <div styleName="score-container">
            <div styleName="score">{`Score: ${Math.round(score * 100)}`}</div>
          </div>
        )}
      </div>

      <div styleName="status-container">
        <div styleName="info-label">Status:</div>
        <div styleName="alert-status-container">
          <div style={{color: isOpen ? palette.red['500'] : palette.gray['500']}}>{statusText}</div>
          <div styleName="info-value">{fullDurText}</div>
        </div>
      </div>

      {trigger.type === ALERT_TYPES.ANOMALY && (
        <IncidentDateTimeProperties
          label="Anomaly Started:"
          startTime={startTime}
          triggerTime={triggerTime || startTime}
          timeZoneName={timeZoneName}
        />
      )}

      {trigger.type === ALERT_TYPES.STATIC && (
        <IncidentDateTimeProperties
          label="Breach Started:"
          startTime={startTime}
          triggerTime={triggerTime || startTime}
          timeZoneName={timeZoneName}
        />
      )}

      {trigger.type === ALERT_TYPES.NO_DATA && (
        <IncidentDateTimeProperties
          label="Data missing since:"
          startTime={getMinLastSeenTime(trigger.metrics)}
          triggerTime={triggerTime || startTime}
          timeZoneName={timeZoneName}
        />
      )}
      {trigger.type === ALERT_TYPES.ANOMALY && trigger?.impact && (
        <ImpactChip impact={trigger.impact} tooltipContent="Total Impact" />
      )}
    </div>
  );
};

export default memo(IncidentDetails);
