// @flow
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {get} from 'lodash';
import {getMeOwnerOrganization, getProfileId, getUserProfile} from 'profile/store/selectors';
import RadioButtonsList from 'common/componentsV2/RadioButtonsList';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {
  addTile as addTileAction,
  fetchDashboardListV2,
  fetchDashboards,
  createDuplicateDashboard,
} from 'dashboards/store/actions';
import * as dashboards from 'dashboards/store/selectors';
import {
  getAlertConfigurationsItems,
  getFetchDashboard,
  getUpdateAlertResponse,
} from 'alerts.management/store/selectors';
import {fetchDashboard, fetchAlertConfigurations} from 'alerts.management/store/actions';
import {getChartDashboardSchemaFactory} from 'metrics/store/selectors';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import Button, {COLORS} from 'common/componentsV2/Button';
import Input from 'common/componentsV2/Input';
import {setNewDashboardName} from 'metrics/store/actions';
import {
  defaultEmptyGraphTile,
  emptyExpressionTreeModel,
  findFreeSpace,
  generateLayout,
  makeGraphTilePayload,
} from 'dashboards/utils';
import {getUniqueId} from 'common/utils/guid';
import useTileAlertSync from 'dashboards/hooks/useTileAlertSync';
import useAsyncAction from 'common/utils/useAsyncAction';
import {COLOR_NAME} from 'common/componentsV2/ColorPicker';
import AsyncButton from '../AsyncButton';

const EMPTY_ARRAY = [];

export const TYPE_REFERER = {
  METRIC_EXPLORER: 'metric',
  ALERT_MNG: 'alert_mng',
};

const renderToDashboardOptions = [{label: 'Existing', value: 'existing'}, {label: 'New', value: 'new'}];

const additionalValues = {
  multiPreviewOptions: {
    '*': {
      sort: 'alphanumeric',
      show: 10,
    },
  },
  byTreeExp: {
    '*': {
      type: 'line',
      stacking: null,
      yAxis: {
        type: 'linear',
        lowerRange: '',
        higherRange: '',
        opposite: false,
        isStateDirty: false,
        minorTickInterval: 'auto',
      },
    },
  },
};

type PropTypes = {
  onCancel: Function,
  type: string,
  alertId: Object,
  multiPreviewOptions: Object,
};

const SaveToDashboardModal = (props: PropTypes) => {
  const {alertId, type, onCancel, multiPreviewOptions} = props;
  const dispatch = useDispatch();
  const store = useStore();
  const dashboardList = useSelector(dashboards.getDashboardListV2);
  const userData = useSelector(getUserProfile);
  const userId = useSelector(getProfileId);
  const meOwnerOrganization = useSelector(getMeOwnerOrganization);

  const chartDashboardSchemaSelector = useMemo(() => getChartDashboardSchemaFactory(multiPreviewOptions), [
    multiPreviewOptions,
  ]);
  const chartDashboardSchema = useSelector(chartDashboardSchemaSelector);

  const {data: dashboardData} = useSelector(getFetchDashboard);
  const createDashboardSelector = useSelector(dashboards.getCreateDuplicateDashboards);
  const getAddTileSelector = useSelector(dashboards.getAddTile);
  const updateAlertIsLoading = useSelector(getUpdateAlertResponse).isLoading;
  const isAlertThingLoading = createDashboardSelector.isLoading || getAddTileSelector.isLoading || updateAlertIsLoading;

  const alertList = useSelector(getAlertConfigurationsItems);
  const alert = useMemo(() => alertList.find((item) => item.data.id === alertId), [alertList, alertId]);

  const [existingDashboard, setExistingDashboard] = useState(renderToDashboardOptions[0]);
  const [selectedDashboard, setSelectedDashboard] = useState('');
  const [dashboardNameState, setDashboardNameState] = useState('');

  const {updateAlert} = useTileAlertSync({dashboardId: get(dashboardData, '_id', undefined)});
  const createDashboard = useAsyncAction(createDuplicateDashboard, createDashboardSelector);
  const addTile = useAsyncAction(addTileAction, getAddTileSelector);

  const onExistingDashboardChange = (val) => {
    setExistingDashboard(val);
    setDashboardNameState('');
    setSelectedDashboard('');
  };

  const selectDashboard = (val) => {
    setSelectedDashboard(val);
  };

  useEffect(() => {
    dispatch(fetchDashboardListV2());
  }, []);

  const dashboardListOptions = useMemo(() => {
    if (dashboardList && dashboardList.dashboards) {
      return dashboardList.dashboards
        .filter((d) => d.ownerUser === userData._id || get(userData, 'groups', []).includes(d.ownerUser))
        .map((dashboard) => ({value: dashboard._id, label: dashboard.name}));
    }

    return EMPTY_ARRAY;
  }, [dashboardList]);

  const onNameChange = (val) => {
    dispatch(setNewDashboardName(val.target.value));
    setDashboardNameState(val.target.value);
  };

  useEffect(() => {
    if (type === TYPE_REFERER.ALERT_MNG) {
      if (selectedDashboard) {
        dispatch(fetchDashboard({id: selectedDashboard.value, schemaType: 'dashboardsV2'}));
      }
    }
  }, [type, selectedDashboard]);

  const saveWithAlert = useCallback(
    ({id, isNewDashboard}) => {
      const tileId = getUniqueId();
      updateAlert({
        ...alert,
        connectedTiles: [{dashboardId: id, tileId}],
      }).then(() => {
        const bodyFromAlert = () => {
          const tile = makeGraphTilePayload(
            {...defaultEmptyGraphTile, id: tileId},
            {
              ...defaultEmptyGraphTile,
              title: '',
              expressionTrees: [get(alert, 'data.expressionTreeModel', emptyExpressionTreeModel)],
              ...additionalValues,
            },
            findFreeSpace(generateLayout(get(dashboardData, 'tiles', EMPTY_ARRAY)), {
              x: defaultEmptyGraphTile.sizeX,
              y: defaultEmptyGraphTile.sizeY,
            }),
          );
          return {name: dashboardNameState || selectedDashboard.label, tiles: [tile], alert: {alertId, tileId}};
        };

        addTile(
          {
            id,
            body: bodyFromAlert(),
          },
          {
            type: TYPE_REFERER.ALERT_MNG,
            isNewDashboard,
          },
        ).then(() => {
          dispatch(fetchAlertConfigurations());
          dispatch(fetchDashboards());
          onCancel();
        });
      });
    },
    [alert, selectedDashboard, dashboardData, onCancel],
  );

  const bodyDashboard = useMemo(
    () => ({
      name: dashboardNameState,
      editableBy: [userId],
      tiles: [],
      ownerUser: userId,
      icon: 'Default',
      color: COLOR_NAME.GRAY,
      ownerOrganization: meOwnerOrganization,
    }),
    [dashboardNameState, userId, meOwnerOrganization],
  );

  const onSaveClick = useCallback(() => {
    const id = selectedDashboard === '' ? null : selectedDashboard.value;
    if (alert) {
      if (id) {
        saveWithAlert({id});
      } else {
        // first create DB to get dashboardId, to update alert correct
        createDashboard(bodyDashboard).then(() => {
          const response = dashboards.getCreateDuplicateDashboards(store.getState());
          saveWithAlert({id: response.data._id, isNewDashboard: true});
        });
      }
    } else {
      addTile(
        {
          id,
          body: chartDashboardSchema,
        },
        {type: TYPE_REFERER.METRIC_EXPLORER},
      ).then(() => {
        onCancel();
      });
    }
  }, [alert, selectedDashboard, chartDashboardSchema, dashboardData, onCancel]);

  return (
    <div>
      <div className="fontWeight_300 fontSize_22 mb_0-5">Create Dashbooard Tile</div>
      <div>View the alert more easily and create a dashboard tile</div>
      <div className="mt_2 mb_2-5" automation-id="metricExplorerNewDashboardRadioButton">
        <RadioButtonsList
          options={renderToDashboardOptions}
          value={existingDashboard.value}
          onChange={onExistingDashboardChange}
          direction="row"
        />
      </div>
      <div className="mb_6">
        {existingDashboard.value === 'new' ? (
          <Input onChange={onNameChange} value={dashboardNameState} placeHolder="Dashboard Name" />
        ) : (
          <FormDdlSelect
            options={dashboardListOptions}
            isUseSearch
            button={
              <BigDropdownButton
                placeholder="Select dashboard"
                isPristine={selectedDashboard === ''}
                label={selectedDashboard.label}
              />
            }
            optionComponent={<OptionComponentSimple />}
            onChange={selectDashboard}
            width={353}
            disabled={existingDashboard.value === 'new'}
          />
        )}
      </div>
      <div className="display_flex justifyContent_flex-end">
        <div className="mr_1-5">
          <Button text="Cancel" colorSchema={COLORS.GRAY_200} onClick={props.onCancel} />
        </div>
        <AsyncButton
          isDisabled={
            (existingDashboard.value === 'new' && dashboardNameState === '') ||
            (existingDashboard.value === 'existing' && selectedDashboard === '')
          }
          text="Save"
          colorSchema="blue.500"
          onClick={onSaveClick}
          isLoading={isAlertThingLoading}
          automationId="metricExplorerDashboardSaveButton"
        />
      </div>
    </div>
  );
};

export default React.memo(SaveToDashboardModal);
