import {QueryClient} from 'react-query';
import {useToast} from 'common/utils/notifications/notificationsService';
import {generalErrorMsg} from 'common/utils/notifications/generalNotificationCodes';
import {getConfiguredStore} from 'configureStore';

export function queryErrorHandler(err) {
  const {error} = useToast(getConfiguredStore().dispatch);
  const title = err?.title || err?.message || generalErrorMsg.title;
  const code = title === generalErrorMsg.title ? generalErrorMsg.code : err?.andtErrorCode || null;
  const description =
    err?.description || err?.additionalInfo?.info || title === generalErrorMsg.title
      ? generalErrorMsg.description
      : null;
  const settings = err?.settings || generalErrorMsg.settings || {};
  settings.uid = 'react-query-error';

  error({
    code,
    title,
    description,
    ...settings,
  });
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
      staleTime: 60000, // 10 min
      cacheTime: 90000, // 15 min
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    },
    mutations: {
      onError: queryErrorHandler,
    },
  },
});
