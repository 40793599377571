// @flow
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchPropertiesApi} from 'metrics/store/actions';
import {
  getCurrentCall,
  getExpressionTree,
  getPropertiesApiData,
  getPropertiesApiIsLoading,
} from 'metrics/store/selectors';
import {getUniqueId} from 'common/utils/guid';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';

const baseBody = {
  // TODO -- GABPAC -- fill it all with real data, not only the expression tree
  name: {auto: true, prefix: null},
  displayOnly: true,
  excludeComposites: true,
  filter: {
    function: 'alphanumeric',
    parameters: [{name: 'Top N', value: 10}],
    children: [],
    id: getUniqueId(),
    type: 'function',
  },
  scalarTransforms: [
    {
      function: 'current',
      children: [],
      id: getUniqueId(),
      parameters: [],
      type: 'function',
    },
  ],
  context: '',
};
const setBodyForFunction = (expTree) => ({
  ...baseBody,
  expressionTree: expTree,
});
const setBodyForMeasure = (expTree) => ({
  ...baseBody,
  expressionTree: {root: expTree.root.children[0]},
});

const SelectDimensions = (props: {onChange: Function, value: String}) => {
  const dispatch = useDispatch();
  const expressionTree = useSelector(getExpressionTree);
  const properties = useSelector(getPropertiesApiData);
  const currentCall = useSelector(getCurrentCall);
  const propertiesApiIsLoading = useSelector(getPropertiesApiIsLoading);
  const [cleanProperties, setCleanProperties] = useState([]);

  useEffect(
    () => () => {
      if (!cleanProperties.find((item) => item.value === props.value)) {
        props.onChange(null);
      }
    },
    [],
  );

  useEffect(() => {
    if (properties.length) {
      setCleanProperties(
        properties
          .filter((prop) => prop.charAt(0) !== '#' && prop.charAt(0) !== '@' && prop !== 'what')
          .map((cleanProp) => ({label: cleanProp, value: cleanProp})),
      );
    }
  }, [properties]);

  useEffect(() => {
    if (currentCall === 'noCache') {
      setCleanProperties([]);
    }
    if (expressionTree.root.function.length) {
      dispatch(fetchPropertiesApi(setBodyForFunction(expressionTree)));
    }
    if (!expressionTree.root.function.length) {
      dispatch(fetchPropertiesApi(setBodyForMeasure(expressionTree)));
    }
  }, [currentCall]);

  return (
    <>
      <div className="lineHeight_16 mr_1">Route alert by:</div>
      <FormDdlSelect
        button={
          <BigDropdownButton
            isPristine={!cleanProperties.find((item) => item.value === props.value)}
            placeholder={propertiesApiIsLoading ? 'Loading...' : 'Select Dimension or Tag'}
            label={cleanProperties.find((item) => item.value === props.value)?.label}
            blueLean
          />
        }
        options={cleanProperties}
        optionComponent={<OptionComponentSimple />}
        onChange={(item) => props.onChange(item.value)}
        width={160}
        maxWidth={160}
        buttonWidth={160}
        automationId="AlertAckFilter"
        disabled={propertiesApiIsLoading}
        id="alertSettingsMinimumDurationFilter"
      />
    </>
  );
};

export default React.memo(SelectDimensions);
