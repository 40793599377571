// @flow
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import FilterButton from 'common/componentsV2/FilterButton';
import Button, {COLORS} from 'common/componentsV2/Button';
import OnBoarding from 'onBoarding/Onboarding';
import * as commonSelectors from 'profile/store/selectors';
import * as onBoardingSelectors from 'onBoarding/store/selectors';
import * as alertsConsoleSelectors from 'alerts.console.new/store/selectors';
import * as actions from 'alerts.console.new/store/actions';
import {segmentCausingEvent} from 'common/store/actions';
import NewAlertButton from 'alerts.management/components/editor/simpleAlertForm/NewAlertButton';
import {
  getSortedFlattenChannels,
  getUsersDataForAvatar,
  getFiltersButtonTooltipItems,
} from 'alerts.console.new/services/alertsConsoleFiltersService';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import fetchChannelsProvider from 'alerts.console.new/api/fetchChannelsProvider';
import {fetchGroupsData} from 'admin.users/store/selectors';
import AlertConsoleQueryParamsContext from 'alerts.console.new/context';
import NoFace from './NoFace';

import './AlertsConsoleHeader.module.scss';

const AlertsConsoleHeader = () => {
  const dispatch = useDispatch();
  const isFiltersBarOpen = useSelector(alertsConsoleSelectors.getIsFiltersBarOpen);
  const userProfile = useSelector(commonSelectors.getUserProfile);
  const isOnBoardingDismiss = useSelector(commonSelectors.getIsOnBoardingDismiss);
  const onBoardingTasks = useSelector(onBoardingSelectors.getMergedTasks);
  const isAllTasksAccomplished = useSelector(onBoardingSelectors.getIsAllTasksAccomplished);
  const featureOnBoarding = useSelector(commonSelectors.getFeatureOnBoarding);
  const isFirstTimeVisit = useSelector(alertsConsoleSelectors.getIsFirstTimeVisit);
  const isNewAlertConsoleEnabledAndActive = useSelector(commonSelectors.getNewAlertConsoleEnabledAndActive);
  const groupsData = useSelector(fetchGroupsData);

  const [initialAllTasksAccomplished] = useState(isAllTasksAccomplished);

  const {contextQueryParams} = useContext(AlertConsoleQueryParamsContext);

  const usersProviderData = fetchUsersProvider().useQuery();
  const users = usersProviderData?.data;

  const channelsProviderData = fetchChannelsProvider().useQuery();
  const channelsData = channelsProviderData?.data;

  const isLoading = usersProviderData?.isLoading || channelsProviderData?.isLoading;

  const usersOptions = useMemo(() => {
    if (Array.isArray(users) && Array.isArray(groupsData)) {
      return getUsersDataForAvatar(users, groupsData);
    }
    return undefined;
  }, [users, groupsData]);

  const sortedFlattenChannels = useMemo(() => {
    // Need both of the ReactQuery providers to return value - i.e. not undefined, and also at least one not empty.
    if (
      !isLoading &&
      (Array.isArray(usersOptions) && Array.isArray(channelsData)) &&
      (usersOptions?.length > 0 || channelsData?.length > 0)
    ) {
      return getSortedFlattenChannels(usersOptions, channelsData);
    }
    return undefined;
  }, [isLoading, usersOptions, channelsData]);

  const filtersButtonTooltipItems = useMemo(() => {
    // Need both of the ReactQuery providers to return value - i.e. not undefined, and also at least one not empty.
    if (isLoading === false && Array.isArray(sortedFlattenChannels) && Array.isArray(usersOptions)) {
      return getFiltersButtonTooltipItems(contextQueryParams, sortedFlattenChannels, usersOptions);
    }
    return [];
  }, [isLoading, contextQueryParams, sortedFlattenChannels, usersOptions]);

  const reportSegment = (category, name) => {
    dispatch(
      segmentCausingEvent({
        segment: {category, name},
      }),
    );
  };

  const toggleFiltersBar = () => {
    dispatch(actions.updateFilterBtn(isFiltersBarOpen));
  };

  const goto = () => {
    const {origin} = window.location;
    if (isNewAlertConsoleEnabledAndActive) {
      window.open(`${origin}/#!/r/alert-manager`, `_blank_top`);
    } else {
      window.open(`${origin}/#!/r/alert-manager`, '_top');
    }
  };

  useEffect(() => {
    if (isAllTasksAccomplished && initialAllTasksAccomplished !== isAllTasksAccomplished) {
      reportSegment('onBoarding', 'all-task-completed');
    }
  }, [isAllTasksAccomplished]);

  return (
    <header styleName="page-header">
      <div styleName="container" automation-id="Alert Console Header">
        <Title type={TITLE_TYPES.PAGE_TITLE}>Alert Console</Title>
        <FilterButton tooltipItems={filtersButtonTooltipItems} onClick={toggleFiltersBar} />
      </div>

      <div styleName="container right">
        <div styleName="onBoarding-container">
          {featureOnBoarding && !isOnBoardingDismiss && (
            <OnBoarding
              isAllTasksAccomplished={isAllTasksAccomplished}
              tasks={onBoardingTasks}
              shouldBeOpen={isFirstTimeVisit}
              userData={userProfile}
            />
          )}
        </div>

        <Button colorSchema={COLORS.GRAY_200} text="Alert Management" onClick={goto} />

        <NewAlertButton />
      </div>
      <NoFace />
    </header>
  );
};

export default AlertsConsoleHeader;
