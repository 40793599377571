/* eslint-disable no-param-reassign */
// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {intersection} from 'lodash';
import {fetchGroupsData, getSelectedUsersCheckbox} from 'admin.users/store/selectors';
import SelectAndt, {THEME_HIGHLIGHTED_TRANSPARENT, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import shallowEqual from 'common/utils/shallowEqual';
import './UGFooterGeneral.module.scss';

type PropTypes = {
  onApply: Function,

  // connect
  selectedCheckboxes: Array,
  groups: Array,
};

export default connect(
  (state) => ({
    selectedCheckboxes: getSelectedUsersCheckbox(state),
    groups: fetchGroupsData(state),
  }),
  {},
)(
  class UGFooterAddToGroups extends React.PureComponent {
    props: PropTypes;

    state = {
      groups: [],
      menuIsOpen: false,
      groupsListNormalized: [],
    };

    componentDidUpdate(prevProps) {
      if (
        !(
          shallowEqual(prevProps.groups, this.props.groups) &&
          shallowEqual(prevProps.selectedCheckboxes, this.props.selectedCheckboxes)
        )
      ) {
        this.resetGroupsState(this.props);
      }
      if (prevProps.selectedCheckboxes !== this.props.selectedCheckboxes) {
        this.onMenuClose();
      }
    }

    resetGroupsState = (props) => {
      const size = props.selectedCheckboxes.length;
      const groupsListNormalized = props.groups.map((gr) => {
        const inersecArr = intersection(props.selectedCheckboxes, gr.users);
        return {
          label: gr.name,
          value: gr.id,
          isSome: inersecArr.length > 0,
          isAll: inersecArr.length === size && size !== 0,
        };
      });
      const selectedGroups = [];
      groupsListNormalized.forEach((grNorm) => {
        if (grNorm.isSome || grNorm.isAll) {
          selectedGroups.push(grNorm);
        }
      });
      this.setState({groups: selectedGroups, groupsListNormalized});
    };

    handleGroupsChange = (val) => {
      val.forEach((v) => {
        if (this.state.groups.findIndex((gr) => gr.value === v.value) === -1) {
          v.isAll = true;
          v.isSome = true;
        }
      });
      this.setState({groups: val});
    };

    handleGroupsApplyChanges = () => {
      this.props.onApply(this.state.groups);
      this.setState({menuIsOpen: false});
    };

    onMenuOpen = () => {
      this.setState({menuIsOpen: true});
    };

    onMenuClose = () => {
      this.resetGroupsState(this.props);
      this.setState({menuIsOpen: false});
    };

    render() {
      const {groups, groupsListNormalized, menuIsOpen} = this.state;

      return (
        <SelectAndt
          automationId="bulkAddToGroups"
          type={TYPE_NEW_MULTI}
          theme={THEME_HIGHLIGHTED_TRANSPARENT}
          onChange={this.handleGroupsChange}
          options={groupsListNormalized}
          value={groups}
          isMulti
          useBoxHalf
          onMenuOpen={this.onMenuOpen}
          onMenuClose={this.onMenuClose}
          placeholder=""
          optionHeight={40}
          offsetLeft={75}
          menuIsOpen={menuIsOpen}
          menuWidth={330}
          isClearable={false}
          closeMenuOnSelect={false}
          closeMenuOnScroll={false}
          menuFooterComponent={
            <FormDdlActionBar isVisible isApplyDisabled={groups.length === 0} onApply={this.handleGroupsApplyChanges} />
          }
          menuPlacement="top"
          customComponent={{
            DropdownIndicator: (p) => (
              <div {...p.innerProps} styleName="footer-button-like">
                <i className="icon icn-general16-group" />
                <span>Add to Group</span>
              </div>
            ),
          }}
        />
      );
    }
  },
);
