const STAPES_INITIAL_STATE = [
  {
    label: 'extract',
    isCurrent: true,
    isFirstVisit: true,
  },
  {
    label: 'transform',
    isCurrent: false,
    isFirstVisit: true,
  },
  {
    label: 'load',
    isCurrent: false,
    isFirstVisit: true,
  },
];

const STEPS_ACTIONS = {
  SET_CURRENT_STEP: 'setCurrentStep',
  SET_FIRST_TIME: 'setFirstTime',
};

function editPageReducer(state, {type, payload}) {
  switch (type) {
    case STEPS_ACTIONS.SET_CURRENT_STEP:
      return state.map((i, index) => {
        if (index === payload.index) {
          return {...i, isCurrent: true};
        }
        return {...i, isCurrent: false};
      });
    case STEPS_ACTIONS.SET_FIRST_TIME:
      return state.map((i, index) => {
        if (index === payload.index) {
          return {...i, isFirstVisit: false};
        }
        return {...i, isFirstVisit: true};
      });
    default:
      throw new Error();
  }
}

export {editPageReducer, STAPES_INITIAL_STATE, STEPS_ACTIONS};
