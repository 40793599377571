import {useState, useEffect, useCallback, useMemo, useRef} from 'react';
import {groupBy, flatten, omit, merge, get, isEqual} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useQueryParams, useQueryParam, NumberParam, StringParam} from 'use-query-params';
import * as dateRangeService from 'common/utils/dateRangeService';
import {makeFiltersPayload, parseFiltersPayload} from 'dashboards/utils';
import {fetchDashboardUserSettings, updateDashboardUserSettings} from 'dashboards/store/actions';
import {getDashboardUserSettings} from 'dashboards/store/selectors';
import {getProfileId} from 'profile/store/selectors';
import {resolutionTypes} from 'metrics/services/metricsService';
import moment from 'moment';
import {autoRefreshOptions} from 'dashboards/components/AutoRefreshContainer';
import useDashboardState from './useDashboardState';

const FiltersParam = {
  encode: (filters) => {
    if (!filters.length) {
      return undefined;
    }
    return JSON.stringify(
      Object.entries(groupBy(flatten(filters), 'key')).reduce((acc, [key, item]) => {
        acc[key] = item.map((t) => t.value).join(',');
        return acc;
      }, {}),
    )
      .replace('{', '')
      .replace('}', '')
      .replaceAll('":"', '"_"')
      .replaceAll(',', '.');
  },

  decode: (arrayStr) => {
    if (arrayStr) {
      try {
        return Object.entries(JSON.parse(`{${arrayStr.replaceAll('"_"', '":"').replaceAll('.', ',')}}`)).map(
          ([key, arr]) => arr.split(',').map((value) => ({key, value})),
        );
      } catch (err) {
        return [];
      }
    } else return [];
  },
};

export const getDateParams = (dateRange) => {
  if (dateRange.constRange === 'c') {
    return dateRange;
  }
  if (dateRange.constRange === 'r') {
    return {
      constRange: dateRange.constRange,
      relativeLast: dateRange.relativeLast,
      relativeNext: dateRange.relativeNext,
      id: dateRange.id,
    };
  }
  return {
    constRange: dateRange.constRange,
    startDate: undefined,
    endDate: undefined,
    relativeLast: undefined,
    relativeNext: undefined,
    id: undefined,
  };
};

export const getTimeScale = (value) => resolutionTypes[value] || resolutionTypes.auto;

export const getAutoRefreshInterval = (value) =>
  autoRefreshOptions.find((o) => o.value === value) || autoRefreshOptions[0];

const useDashboardSync = (dashboardId, tileId) => {
  const lastSavedPayload = useRef({});
  const dispatch = useDispatch();
  const [initialDashboardState, setInitialDashboardState] = useState(null);
  const [lastQueryState, setLastQueryState] = useState(null);
  const [invitationId] = useQueryParam('invitationId', StringParam);
  const [query, setQuery] = useQueryParams({
    constRange: StringParam,
    startDate: NumberParam,
    endDate: NumberParam,
    relativeLast: NumberParam,
    relativeNext: NumberParam,
    filters: FiltersParam,
    timeScale: StringParam,
    id: StringParam,
    autoRefreshInterval: NumberParam,
    invitationId: StringParam,
  });
  const dashboardIdRef = useRef(dashboardId);

  const userId = useSelector(getProfileId);
  const {data: dashboardData} = useDashboardState(dashboardId);
  const {data: dashboardUserSettings} = useSelector(getDashboardUserSettings);
  const tileDataFullSize = useMemo(
    () => tileId && dashboardData && dashboardData.tiles.find((tile) => tile.id === tileId),
    [dashboardData, tileId],
  );

  useEffect(() => {
    if (userId) {
      dispatch(fetchDashboardUserSettings({dashboardId, userId}));
    }
  }, [userId, dashboardId]);

  useEffect(() => {
    if (
      dashboardData &&
      dashboardUserSettings &&
      !initialDashboardState &&
      (dashboardUserSettings.dashboardId === dashboardId || !dashboardUserSettings.dashboardId)
    ) {
      const dashboardSettings = {
        dateRange: dateRangeService.getDateValue(dashboardData.dateRange),
        selectorsFilter: dashboardData.selectorsFilter || {selectors: []},
        timeScale: getTimeScale(get(dashboardData, 'timeScale.value')),
        autoRefreshInterval: dashboardData.autoRefreshInterval,
      };

      const computedDashboardUserSettings =
        dashboardUserSettings && moment(dashboardUserSettings.modified).unix() > moment(dashboardData.modified).unix()
          ? {
              selectorsFilter: dashboardUserSettings.selectorsFilter,
              dateRange: dateRangeService.getDateValue(dashboardUserSettings.dateRange),
              timeScale: getTimeScale(get(dashboardUserSettings, 'timeScale.value')),
              autoRefreshInterval: dashboardUserSettings.autoRefreshInterval,
            }
          : {};
      const queryParamsSettings =
        !tileDataFullSize && dashboardIdRef.current === dashboardId
          ? {
              dateRange: query.constRange
                ? dateRangeService.getDateValue(
                    omit(query, ['filters', 'timeScale', 'autoRefreshInterval', 'invitationId']),
                  )
                : {},
              selectorsFilter: query.filters ? {selectors: makeFiltersPayload(query.filters)} : {},
              timeScale: query.timeScale ? getTimeScale(query.timeScale) : {},
              autoRefreshInterval: query.autoRefreshInterval || {},
            }
          : {};

      const mergedDashboardSettings = merge(
        {dashboardId, userId},
        dashboardSettings,
        dashboardUserSettings.dashboardId ? computedDashboardUserSettings : {}, // remove dashboardUserSettings from merge for uploaded dashboards
        queryParamsSettings,
      );

      mergedDashboardSettings.selectorsFilter.selectors = dashboardSettings.selectorsFilter.selectors.map(
        (selector) => {
          const queryLevelSelector = get(queryParamsSettings, 'selectorsFilter.selectors', []).find(
            (item) => item.name === selector.name,
          );
          if (queryLevelSelector) {
            return queryLevelSelector;
          }
          const userLevelSelector = get(computedDashboardUserSettings, 'selectorsFilter.selectors', []).find(
            (item) => item.name === selector.name,
          );
          if (userLevelSelector) {
            return userLevelSelector;
          }
          return selector;
        },
      );

      const initial = {
        dateRange: mergedDashboardSettings.dateRange,
        filters: parseFiltersPayload(mergedDashboardSettings.selectorsFilter.selectors),
        timeScale: mergedDashboardSettings.timeScale.value,
        isEditableMode: false,
        isResize: false,
        autoRefreshInterval: getAutoRefreshInterval(mergedDashboardSettings.autoRefreshInterval),
      };
      setInitialDashboardState(initial);
      const computedQuery = {
        ...getDateParams(initial.dateRange),
        filters: initial.filters,
        timeScale: initial.timeScale,
        autoRefreshInterval: initial.autoRefreshInterval.value,
        invitationId: query.invitationId,
      };
      if (dashboardIdRef.current !== dashboardId) {
        dashboardIdRef.current = dashboardId;
      }
      if (!tileDataFullSize) {
        setQuery(computedQuery, 'replace');
      }
      setLastQueryState(computedQuery);
    }
  }, [dashboardUserSettings, dashboardData, tileDataFullSize, dashboardId]);

  useEffect(() => {
    if (!tileId && lastQueryState) {
      setQuery(lastQueryState, 'replace');
    }
  }, [tileId]);

  useEffect(
    () => () => {
      setInitialDashboardState(null);
    },
    [dashboardId],
  );

  const syncDashboard = useCallback(
    ({dateRange, filters, timeScale, autoRefreshInterval}) => {
      const computedQuery = {
        ...getDateParams(dateRange),
        filters,
        timeScale: getTimeScale(timeScale).value,
        autoRefreshInterval: autoRefreshInterval.value,
        invitationId: query.invitationId,
      };
      setQuery(computedQuery, 'replace');
      setLastQueryState(computedQuery);
      if (!invitationId) {
        const settingsPayload = {
          dateRange: getDateParams(dateRange),
          selectorsFilter: filters ? {selectors: makeFiltersPayload(filters)} : {},
          timeScale: getTimeScale(timeScale),
          autoRefreshInterval: autoRefreshInterval.value,
        };

        if (isEqual(lastSavedPayload.current, settingsPayload)) {
          return;
        }

        lastSavedPayload.current = settingsPayload;

        dispatch(
          updateDashboardUserSettings({
            dashboardId,
            userId,
            ...settingsPayload,
          }),
        );
      }
    },
    [dashboardId, dashboardData, userId],
  );
  return {initialDashboardState, syncDashboard};
};

export default useDashboardSync;
