// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import MultiButton from 'common/componentsV2/multiButton/MultiButton';
import {allowedFilterKeys} from 'alerts.management/services/alertManagementService';
import * as filterSelector from 'alerts.management/store/filterSelector';

type PropTypes = {
  setQueryParams: Function,
  status: String,
};

const automationIdsMap = {
  all: 'Alert Status All',
  paused: 'Alert Status Paused',
  active: 'Alert Status Active',
};

const STATUS_ALERTS = Object.values(allowedFilterKeys.status).map((item) => ({
  ...item,
  automationId: automationIdsMap[item.value],
}));

export default connect(
  (state) => ({
    status: filterSelector.getAlertFiltersStatus(state),
  }),
  {},
)(
  class StatusFilter extends React.PureComponent {
    props: PropTypes;

    onChange = (val) => {
      this.props.setQueryParams({status: val || undefined});
    };

    render() {
      const {status} = this.props;
      return <MultiButton value={status} options={STATUS_ALERTS} onChange={this.onChange} />;
    }
  },
);
