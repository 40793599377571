// @flow
// eslint-disable-next-line import/no-cycle
import RowHeader from 'admin.trainTracker/components/RowHeader';
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {setPageKeyVal as setPageKeyValAction} from 'admin.trainTracker/store/actions';
import * as selectors from 'admin.trainTracker/store/selectors';
import TableRowInfo from './TableRowInfo';
import './Table.module.scss';

type PropTypes = {
  pr: Object,

  // connect
  expandedPullReqId: any,
  searchString: string,
  setPageKeyVal: Function,
};

export default connect(
  (state) => ({
    expandedPullReqId: selectors.getTrainTrackerExpandedPullReqId(state),
    searchString: selectors.getTrainTrackerSearchString(state),
  }),
  {
    setPageKeyVal: setPageKeyValAction,
  },
)(
  class TableRow extends PureComponent {
    props: PropTypes;

    state = {};

    setIsOpen = () => {
      this.setState((prevState) => ({isOpen: !prevState.isOpen}));
    };

    render() {
      const {pr, setPageKeyVal, expandedPullReqId, searchString} = this.props;
      return (
        <div styleName="table-row">
          <TableListItem
            key={pr.id}
            headerComponent={
              <RowHeader
                expandedPullReqId={expandedPullReqId}
                pr={pr}
                searchString={searchString}
                setPageKeyVal={setPageKeyVal}
              />
            }
            expandedPanel={<TableRowInfo pr={pr} searchString={searchString} />}
            checkboxVisibility={CHECKBOX_VISIBILITY_TYPES.alwaysHide}
            setSelectedItem={this.setIsOpen}
            isSelected={this.state.isOpen}
          />
        </div>
      );
    }
  },
);
