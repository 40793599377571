import React from 'react';
import {StringParam, useQueryParams} from 'use-query-params';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import SearchBox from 'common/componentsV2/SearchBox';
import {RECOMMENDATIONS_QUERY_PARAMS} from 'recommendations/services/consts';
import {useDispatch, useSelector} from 'react-redux';
import {getIsFiltersBarOpen} from 'recommendations/store/selectors';
import {updateFilterBtn as updateFilterBtnAction} from 'recommendations/store/actions';
import {isEqual} from 'lodash';

import './RecommendationsFilters.module.scss';

const RecommendationsFilters = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsFiltersBarOpen);

  const [queryParams, setQueryParams] = useQueryParams({
    [RECOMMENDATIONS_QUERY_PARAMS.SEARCH_EXPRESSION.name]: StringParam,
  });

  const updateFiltersState = () => {
    dispatch(updateFilterBtnAction(isOpen));
  };

  const clearFilters = () => {
    Object.values(RECOMMENDATIONS_QUERY_PARAMS).forEach((param) =>
      setQueryParams({[param.name]: [param.defaultValue]}),
    );
  };

  return (
    <FiltersPanel
      isOpen={isOpen}
      isSaveView={false}
      isShareLink={false}
      onClose={() => updateFiltersState()}
      onClearAll={() => clearFilters()}
      isClearAll={!isEqual(queryParams, Object.values(RECOMMENDATIONS_QUERY_PARAMS)?.map((item) => item.defaultValue))}
    >
      <div styleName="filters-row">
        <SearchBox
          onFilter={(val) =>
            setQueryParams({
              [RECOMMENDATIONS_QUERY_PARAMS.SEARCH_EXPRESSION.name]:
                val || RECOMMENDATIONS_QUERY_PARAMS.SEARCH_EXPRESSION.defaultValue,
            })
          }
          filter={
            queryParams[RECOMMENDATIONS_QUERY_PARAMS.SEARCH_EXPRESSION.name] ||
            RECOMMENDATIONS_QUERY_PARAMS.SEARCH_EXPRESSION.defaultValue
          }
          placeHolder="Search"
        />
      </div>
    </FiltersPanel>
  );
};
export default RecommendationsFilters;
