import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import '../OverwiewTab.module.scss';
import {fetchTriggeredAlertsByConfiguration} from 'alerts.management/store/actions';
import {getTriggeredAlertsByConfigurationCount} from 'alerts.management/store/selectors';
import {getFetchTriggeredAlertIsLoading, getInvDataFetchTriggeredAlertData} from '../../../store/selectors';

const HowOftenCubeStatic = ({onIsLoading}: {onIsLoading: Function}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getFetchTriggeredAlertIsLoading);
  const triggeredAlertData = useSelector(getInvDataFetchTriggeredAlertData);
  const triggersCount = useSelector(getTriggeredAlertsByConfigurationCount);

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const thirtyDaysTimestamp = Math.floor(Date.now() / 1000) - 60 * 60 * 24 * 30;

    const queryAPI = {
      startTime: thirtyDaysTimestamp,
      alertConfigurationIds: triggeredAlertData.alertConfigurationId,
    };

    dispatch(fetchTriggeredAlertsByConfiguration(queryAPI));
  }, []);

  const goTo = () => {
    window.open(
      `/#!/r/alerts-console?ref=email&alertConfigurationIds=${triggeredAlertData.alertConfigurationId}&status=&size=100&index=0&startTime=&endTime=&constRange=y1&ack=&subscribers=&channels=`,
    );
  };

  return (
    <div styleName="info-cube-container">
      {triggersCount > 0 ? (
        <div>
          <div styleName="how-often-text">
            {`Alert was triggered ${triggersCount} additional time${triggersCount > 1 ? 's' : ''} in the last 30 days`}
          </div>
          <a styleName="see-all" onClick={goTo} target="_blank" rel="noopener noreferrer">
            See All
          </a>
        </div>
      ) : (
        <div>No triggers in the last 30 days</div>
      )}
    </div>
  );
};

export default HowOftenCubeStatic;
