// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setFileStreamDiametricsChange as setFileStreamDiametricsChangeAction,
  setFileStreamDiametricsTimeDefinition as setFileStreamDiametricsTimeDefinitionAction,
  removeFileStreamDiametrics as removeFileStreamDiametricsAction,
  setFileStreamClearAllDiametrics as setFileStreamClearAllDiametricsAction,
  setStreamDiametricsEditorFilterTextInput as setStreamDiametricsEditorFilterTextInputAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import DimensionsAndMetricsEditor from 'bc/components/streams/editor/common/DimensionsAndMetricsEditor';
import GsFilePreview from '../googleStorageData/GoogleStorageFilePreview';

type PropTypes = {
  dimensions: Array<Object>,
  metrics: Array<Object>,
  filteredUnassignedColumns: Array<Object>,
  timeDefinitionArr: Array,
  timeDefinition: Object,
  filterTextInput: String,
  setFileStreamDiametricsChange: Function,
  setFileStreamClearAllDiametrics: Function,
  setFileStreamDiametricsTimeDefinition: Function,
  removeFileStreamDiametrics: Function,
  setStreamDiametricsEditorFilterTextInput: Function,
  dateFormats: Array<Object>,
  isStreamUpdating: Boolean,
};

export default connect(
  (state) => ({
    dimensions: selectors.getFileStreamSelectedDimensions(state),
    metrics: selectors.getFileStreamSelectedMetrics(state),
    timeDefinition: selectors.getFileStreamSelectedTimeDefinition(state),
    timeDefinitionArr: selectors.getFileStreamSelectedTimeDefinitionColArr(state),
    filteredUnassignedColumns: selectors.getFilteredFileStreamUnAssignedColumns(state),
    filterTextInput: selectors.getStreamMetricsDimentionsFilterTextInput(state),
    dateFormats: selectors.getDataStreamsDateFormats(state),
    isStreamUpdating: selectors.getIsDataStreamUpdating(state),
  }),
  {
    setFileStreamDiametricsChange: setFileStreamDiametricsChangeAction,
    setFileStreamClearAllDiametrics: setFileStreamClearAllDiametricsAction,
    setFileStreamDiametricsTimeDefinition: setFileStreamDiametricsTimeDefinitionAction,
    removeFileStreamDiametrics: removeFileStreamDiametricsAction,
    setStreamDiametricsEditorFilterTextInput: setStreamDiametricsEditorFilterTextInputAction,
  },
)(
  class GoogleStorageDimensionsAndMetricsEditor extends React.PureComponent {
    props: PropTypes;

    render() {
      const {
        metrics,
        dimensions,
        setFileStreamDiametricsTimeDefinition,
        filterTextInput,
        filteredUnassignedColumns,
        timeDefinition,
        timeDefinitionArr,
        removeFileStreamDiametrics,
        setStreamDiametricsEditorFilterTextInput,
        setFileStreamDiametricsChange,
        setFileStreamClearAllDiametrics,
        dateFormats,
        isStreamUpdating,
      } = this.props;

      return (
        <DimensionsAndMetricsEditor
          dimensions={dimensions}
          metrics={metrics}
          filteredUnassignedColumns={filteredUnassignedColumns}
          timeDefinitionArr={timeDefinitionArr}
          timeDefinition={timeDefinition}
          filterTextInput={filterTextInput}
          setStreamDiametricsChange={setFileStreamDiametricsChange}
          setStreamClearAllDiametrics={setFileStreamClearAllDiametrics}
          setStreamTimeDefinition={setFileStreamDiametricsTimeDefinition}
          removeStreamDiametrics={removeFileStreamDiametrics}
          setMetricDimentionEditorFilterTextInput={setStreamDiametricsEditorFilterTextInput}
          preview={<GsFilePreview />}
          dateFormats={dateFormats}
          isStreamUpdating={isStreamUpdating}
        />
      );
    }
  },
);
