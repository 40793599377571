import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {differenceBy} from 'lodash';
import {palette} from 'app/styles/theme';
import Grid from '@material-ui/core/Grid';
import {Box, Popper} from '@material-ui/core';
import {TinyScrollBox, TypographyBox} from 'common/componentsV2/boxTools';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import BaloonMessage from 'common/componentsV2/BaloonMessage';
import Button, {COLORS} from 'common/componentsV2/Button';
import {makeStyles} from '@material-ui/core/styles';
import * as actions from '../store/actions';
import * as selectors from '../store/selectors';
import {SORTED_OPTIONS, commonStyle} from '../services/service';
import ActionItem from './ActionItem';

const useStyles = makeStyles(() => ({
  main: {
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
  },
  header: {
    height: commonStyle.headerHeight,
  },
  listContainer: {
    height: commonStyle.listHeight,
  },
  footer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const AllAlertActions = (props: PropTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const addToAlertRef = useRef();

  const allActionsData = useSelector(selectors.getAllAlertActionsData);
  const allAlertActionsIsLoading = useSelector(selectors.getAllAlertActionsIsLoading);
  const createActionData = useSelector(selectors.getCreateActionData);
  const createActionIsLoading = useSelector(selectors.getCreateActionIsLoading);
  const deleteActionIsLoading = useSelector(selectors.getDeleteActionIsLoading);
  const alertActionsView = useSelector(selectors.getAlertActionsView);

  const [allActionsSorted, setAllActionsSorted] = useState([]);
  const [markedActions, setMarkedActions] = useState([]);
  const [selectedSort, setSelectedSort] = useState(SORTED_OPTIONS[0]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isPopperErrorDisplay, setIsPopperErrorDisplay] = useState(false);

  const sortedList = (sortBy) => allActionsData.sort((a, b) => b[sortBy] - a[sortBy]);

  const onSortedMenuChange = (sortBy) => {
    setSelectedSort(SORTED_OPTIONS.find((i) => i.value === sortBy));
    setAllActionsSorted([...sortedList(sortBy)]);
  };

  const checkActionLength = () => {
    const maxActionsAllowedToAdd = 3;
    if (markedActions.length > maxActionsAllowedToAdd) {
      setErrorMsg(`Can’t add more than ${maxActionsAllowedToAdd} actions to an alert`);
    } else {
      setErrorMsg(null);
    }
  };

  const addActionToMarkedList = (action) => {
    const actionFound = markedActions.find((actionItem) => action.id === actionItem.id);

    if (actionFound) {
      setMarkedActions(markedActions.filter((actionItem) => actionItem.id !== action.id));
    } else {
      setMarkedActions([...markedActions, action]);
    }
  };

  const setIsActive = (id) => markedActions.some((actionItem) => actionItem.id === id);

  const updateActionsToAlerts = () => {
    if (errorMsg) {
      setIsPopperErrorDisplay(true);
    } else {
      setIsPopperErrorDisplay(false);
      dispatch(actions.updateActionsToAlerts(markedActions));
      props.onCloseModal(false);
    }
  };

  useEffect(() => {
    setAllActionsSorted([...sortedList(selectedSort.value)]);
    setMarkedActions([...alertActionsView]);
  }, [allActionsData]);

  useEffect(() => {
    checkActionLength();
    setIsPopperErrorDisplay(false);
    if (
      markedActions.length === alertActionsView.length &&
      !differenceBy(markedActions, alertActionsView, 'id').length
    ) {
      setIsBtnDisabled(true);
    } else {
      setIsBtnDisabled(false);
    }
  }, [markedActions]);

  useEffect(() => {
    if (createActionIsLoading === false) {
      addActionToMarkedList(createActionData[0]);
    }
  }, [createActionIsLoading]);

  return (
    <Grid container className={classes.main}>
      <Grid item sm={12} className={classes.header}>
        <Grid container>
          <Grid item sm={9}>
            <TypographyBox variant="h2">Set possible actions for alert</TypographyBox>
          </Grid>
          <Grid item sm={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <SelectAndt
              id="444"
              automationId="444"
              optionHeight={40}
              buttonWidth={155}
              menuWidth={175}
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_BLUE_LEAN}
              options={SORTED_OPTIONS}
              onChange={(i) => onSortedMenuChange(i.value)}
              value={selectedSort}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12} className={classes.listContainer}>
        <TinyScrollBox
          width="calc(100% + 10px)"
          height="95%"
          pr={1.25}
          css={{overflowY: 'auto', overflowX: 'hidden', flexShrink: 0}}
        >
          {(allAlertActionsIsLoading || deleteActionIsLoading) && (
            <Box height="95%" display="flex" justifyContent="center" alignItems="center" mt={1}>
              <Spinner color={palette.gray['400']} size={SIZES.X_BIG_90} />
            </Box>
          )}

          {!allAlertActionsIsLoading &&
            !deleteActionIsLoading &&
            !!allActionsSorted.length &&
            allActionsSorted.map((action) => (
              <ActionItem
                key={action.id}
                action={action}
                onAddActionToMarkedList={addActionToMarkedList}
                isActive={setIsActive(action.id)}
                onChange={props.onChange}
              />
            ))}

          {!allAlertActionsIsLoading && !allActionsSorted.length && (
            <Box height="100%" display="flex" justifyContent="center" alignItems="center">
              <BaloonMessage
                width={255}
                position
                title="Outside Link Actions"
                link="Learn More"
                url="https://support.anodot.com/hc/en-us/articles/115002938989-Creating-and-Managing-Users-and-Groups"
              >
                Use these actions to add a link to a dashboard, a playbook of actions, or any other relevant pages that
                will help you once you get an alert.
              </BaloonMessage>
            </Box>
          )}
        </TinyScrollBox>
      </Grid>

      <Grid item sm={12} className={classes.footer}>
        <Grid container>
          <Grid container item sm={6}>
            <Button
              colorSchema={COLORS.TRANSPARENT_BLUE}
              onClick={props.onNewAction}
              text="New Action"
              icon="icn-action16-plusa"
            />
          </Grid>
          <Grid container item sm={6} justify="flex-end">
            <Button text="Cancel" onClick={() => props.onCloseModal(false)} />
            <Box>
              <Popper open={isPopperErrorDisplay} anchorEl={addToAlertRef.current} placement="top-end" disablePortal>
                <Box
                  borderRadius={6}
                  position="relative"
                  width={315}
                  bgcolor="red.500"
                  py={1}
                  px={1.5}
                  color="white.500"
                  fontWeight={500}
                  fontSize={16}
                  bottom={15}
                >
                  <div>{errorMsg}</div>
                  <Box
                    position="absolute"
                    width={12}
                    height={12}
                    bottom={-6}
                    right={54}
                    bgcolor="red.500"
                    css={{transform: 'rotate(45deg)'}}
                  />
                </Box>
              </Popper>
              <Box ref={addToAlertRef} ml={1.5}>
                <Button
                  onClick={updateActionsToAlerts}
                  isDisabled={isBtnDisabled}
                  colorSchema={COLORS.BLUE_500}
                  text="Add To Alert"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

AllAlertActions.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  onNewAction: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AllAlertActions;
