// @flow
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {get} from 'lodash';

import {getMeAppSettings} from 'profile/store/selectors';
import {setCustomersFiltersIsOpen} from 'admin.customers/store/actions';
import {getFiltersButtonTooltipItems} from 'admin.customers/store/filterSelector';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import Button, {COLORS} from 'common/componentsV2/Button';
import FilterButton from 'common/componentsV2/FilterButton';
import './CustomersPageHeader.module.scss';

const CustomersPageHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appSettings = useSelector(getMeAppSettings);
  const tooltipItems = useSelector(getFiltersButtonTooltipItems);

  const onCreateNewClick = () => {
    history.push(`/admin/customers/new${history.location.search}`);
  };

  const onFilterButtonClick = () => {
    const filterOpenState = get(appSettings, 'customers.isFiltersBarOpen', false);
    dispatch(setCustomersFiltersIsOpen(!filterOpenState));
  };

  return (
    <div styleName="root">
      <div styleName="left-side">
        <span styleName="title-pos">
          <Title type={TITLE_TYPES.PAGE_TITLE}>Customers</Title>
        </span>
        <FilterButton tooltipItems={tooltipItems} onClick={onFilterButtonClick} automationId="customersFilterButton" />
      </div>
      <div styleName="right-side">
        <Button
          colorSchema={COLORS.BLUE_500}
          text="New"
          onClick={onCreateNewClick}
          icon="icn-action16-plusa"
          automationId="customerNew"
        />
      </div>
    </div>
  );
};

export default CustomersPageHeader;
