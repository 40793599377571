import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {composeReducers} from 'common/utils/reducers';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const metrics = makeAsyncReducer(actions.fetchDatadogMetrics, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const metricDescription = makeAsyncReducer(actions.fetchDatadogDescribeMetric, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const queryPreview = composeReducers(
  makeAsyncReducer(actions.fetchDatadogQueryPreview, {defaultData: EMPTY_OBJECT, errorHandler: bcErrorHandler}),
  (state, {type}) => {
    switch (type) {
      case actions.setSelectedDataStream.TYPE:
        return {data: EMPTY_ARRAY};
      default:
        return state;
    }
  },
);

export default combineReducers({
  metrics,
  metricDescription,
  queryPreview,
});
