// @flow
import React, {PureComponent} from 'react';

import './ConceptButton.module.scss';

type PropTypes = {
  category: string,
  selectedCategory: string,
  setSelectedCategory: Function,
};

class GroupButton extends PureComponent {
  props: PropTypes;

  state = {};

  render() {
    const {category, selectedCategory, setSelectedCategory}: any = this.props;

    return (
      <button
        type="button"
        styleName={selectedCategory === category ? 'button-active' : 'button-inactive'}
        onClick={() => setSelectedCategory(category)}
        automation-id={`diametricsItem-${category}`}
      >
        {category}
        <span />
        <div className="icon icn-icon-dimmet-group" styleName="group-button-icon" />
      </button>
    );
  }
}

export default GroupButton;
