// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as actions from 'admin.cs-portal/store/actions';
import * as selectors from 'admin.cs-portal/store/selectors';
import MetricsUsageGraph from './MetricsUsageGraph';
import MetricsUsagePoints from './MetricsUsagePoints';

import './MetricsSummary.module.scss';

type PropTypes = {
  // connect
  metrics: Object,
  // rate: Object,
  fetchStats: Function,
  selectedCustomer: Object,
  selectedCustomerMetrics: Number,
  isLoadingStats: Boolean,
};

export default connect(
  (state) => ({
    isLoadingStats: selectors.getIsLoadingStats(state),
    metrics: selectors.getMetrics(state),
    rate: selectors.getRate(state),
    selectedCustomer: selectors.getSelectedCustomer(state),
    selectedCustomerMetrics: selectors.getSelectedCustomerMetrics(state),
  }),
  {
    fetchStats: actions.fetchStats,
  },
)(
  class MetricsSummary extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {selectedCustomer} = this.props;
      if (selectedCustomer && selectedCustomer.value) {
        this.fetchStats(selectedCustomer);
      }
    }

    componentDidUpdate(prevProps) {
      const {selectedCustomer} = this.props;

      if (selectedCustomer && selectedCustomer.value && prevProps.selectedCustomer !== selectedCustomer) {
        this.fetchStats(selectedCustomer);
      }
    }

    fetchStats = (selectedCustomer) => {
      this.props.fetchStats({
        _id: selectedCustomer.value,
      });
    };

    render() {
      const {
        metrics: {actualLimit, cached},
        metrics,
        isLoadingStats,
        selectedCustomerMetrics,
      } = this.props;

      return (
        <div styleName="container">
          <div styleName="right">
            <MetricsUsageGraph
              isLoading={isLoadingStats}
              contractLimit={selectedCustomerMetrics}
              actualLimit={actualLimit}
              cached={cached}
            />
          </div>

          <div styleName="left">
            <MetricsUsagePoints metrics={metrics} />
          </div>
        </div>
      );
    }
  },
);
