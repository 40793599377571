import React, {useEffect, useMemo, useState} from 'react';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {useSelector} from 'react-redux';
import {getFormattedDateYMD} from 'common/utils/dateService';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import {calculateTimelineView} from 'investigationNewAlertConsole/services/investigationService';
import {getInvestigationView} from 'investigationNewAlertConsole/store/selectors';
import {getTimeZoneName} from 'profile/store/selectors';

import classes from './InvModalHeader.module.scss';

const MORE_ACTIONS_OPTIONS = {
  EDIT_ALERT: {value: 'editAlert', label: 'Edit alert'},
  EXPORT_TIMELINE: {value: 'exportTimelineToCsv', label: 'Export Timeline to CSV'},
};

const MoreActions = ({trigger, selectedTabId}: {trigger: Object, selectedTabId: String}) => {
  const timeZoneName = useSelector(getTimeZoneName);
  const investigationView = useSelector(getInvestigationView);
  const [timelineView, setTimelineView] = useState();

  const usersProvider = fetchUsersProvider()?.useQuery();
  const users = usersProvider?.data;

  useEffect(() => {
    if (investigationView && users) {
      const tmp = calculateTimelineView(investigationView, users);
      setTimelineView(tmp);
    }
  }, [investigationView, users]);

  const moreActions = useMemo(() => {
    const tmpMoreOptions = [MORE_ACTIONS_OPTIONS.EDIT_ALERT];
    if (selectedTabId === 'timeline') {
      tmpMoreOptions.push(MORE_ACTIONS_OPTIONS.EXPORT_TIMELINE);
    }
    return tmpMoreOptions;
  }, [selectedTabId]);

  const exportTimelineToCsv = () => {
    const rows = [['timestamp', 'Event Type', 'Description', 'User']];
    if (timelineView) {
      timelineView.forEach((ele) => {
        const formattedDate = getFormattedDateYMD(ele.dataTime, timeZoneName);
        const name = ele.userFullName || (ele.userName ? ele.userName : 'Anonymous');
        const newRow = [formattedDate.replaceAll(',', '-'), ele.type, ele.title?.replaceAll(',', ' '), name];
        rows.push(newRow);
      });
    }
    const csvContent = `data:text/csv;charset=utf-8,${rows.map((e) => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  function goTo(value) {
    switch (value) {
      case MORE_ACTIONS_OPTIONS.EDIT_ALERT.value:
        window.open(`/#!/r/alert-manager/edit/${trigger.alertConfigurationId}/settings`);
        break;
      case MORE_ACTIONS_OPTIONS.EXPORT_TIMELINE.value:
        exportTimelineToCsv();
        break;
      default:
    }
  }

  return (
    <div className={classes.moreActions}>
      <FormDdlSelect
        automationId="inv-header-more-actions"
        position="right"
        width={135}
        options={moreActions}
        button={<div className="icon icn-icon-3-dots" />}
        optionComponent={<OptionComponentSimple />}
        onChange={(e) => goTo(e.value)}
      />
    </div>
  );
};

export default MoreActions;
