// @flow
import React from 'react';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {
  fetchDatadogMetrics as fetchDatadogMetricsAction,
  fetchDatadogDescribeMetric as fetchDatadogDescribeMetricAction,
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
  fetchDatadogQueryPreview as fetchDatadogQueryPreviewAction,
  setDatadogStreamQuery as setDatadogStreamQueryAction,
} from 'bc/store/actions';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import QueryEditorModal from 'bc/components/streams/editor/byType/sql/queryEditorModal/QueryEditorModal';
import './DatadogData.module.scss';

const DEFAULT_QUERY = 'sum:system.disk.read_time{*} by {device,host}.rollup(sum, 300).as_count()';

type PropTypes = {
  dataStream: Object,
  isLoadingMetrics: Boolean,
  metrics: Object,
  streamType: string,
  previewData: Object,
  previewDataError: Object,
  queryPreviewRes: Object,
  isPreviewDataLoading: boolean,

  fetchDatadogMetrics: Function,
  fetchDatadogDescribeMetric: Function,
  fetchDatadogQueryPreview: Function,
  setDatadogStreamQuery: Function,
  setSelectedStreamKeyVal: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoadingMetrics: selectors.getDatadogStreamMetricsIsLoading(state),
    metrics: selectors.getDatadogStreamMetricsItems(state),
    streamType: selectors.getStreamType(state),
    previewData: selectors.getDatadogStreamQueryPreviewDataProcessed(state),
    previewDataError: selectors.getDatadogStreamQueryPreviewError(state),
    queryPreviewRes: selectors.getDatadogStreamQueryPreviewData(state),
    isPreviewDataLoading: selectors.getDatadogStreamQueryPreviewIsLoading(state),
  }),
  {
    fetchDatadogMetrics: fetchDatadogMetricsAction,
    fetchDatadogDescribeMetric: fetchDatadogDescribeMetricAction,
    fetchDatadogQueryPreview: fetchDatadogQueryPreviewAction,
    setDatadogStreamQuery: setDatadogStreamQueryAction,
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
  },
)(
  class DatadogData extends React.PureComponent {
    props: PropTypes;

    state = {
      isOpenQueryEditorModal: false,
    };

    // eslint-disable-next-line react/sort-comp
    _isMounted = false;

    // eslint-disable-next-line react/sort-comp
    _gotMetricDescription = false;

    componentDidMount() {
      const {dataStream, fetchDatadogMetrics} = this.props;

      if (!this._isMounted) {
        const payload = {
          type: dataStream.type,
          dataSourceId: dataStream.dataSourceId,
        };
        fetchDatadogMetrics(payload);
        this._isMounted = true;
      }
    }

    componentDidUpdate() {
      const {dataStream, fetchDatadogDescribeMetric} = this.props;

      if (!this._gotMetricDescription && get(dataStream, 'metricName') && !get(dataStream, 'uiState.describeMetric')) {
        fetchDatadogDescribeMetric(
          {
            type: dataStream.type,
            dataSourceId: dataStream.dataSourceId,
            metricName: dataStream.metricName,
          },
          {
            metricName: dataStream.metricName,
            isEditRunning: isEditRunning(dataStream.id),
          },
        );
        this._gotMetricDescription = true;
      }
    }

    setSelectedRadio = (changeEvent) => {
      const {setSelectedStreamKeyVal} = this.props;
      const isCustomQuery = changeEvent.target.value !== 'table';
      setSelectedStreamKeyVal({
        customQuery: isCustomQuery,
      });
    };

    openQueryEditorModal = () => {
      this.setState({isOpenQueryEditorModal: true});
    };

    closeQueryEditorModal = () => {
      this.setState({isOpenQueryEditorModal: false});
    };

    metricChanged = (selectedMetric) => {
      const {dataStream, setSelectedStreamKeyVal, fetchDatadogDescribeMetric} = this.props;

      if (selectedMetric !== dataStream.metricName) {
        setSelectedStreamKeyVal({
          ...dataStream,
          dimensions: [],
          metrics: [],
          metricName: selectedMetric,
          schema: {
            columns: [],
            sourceColumns: [],
          },
          uiState: {
            ...dataStream.uiState,
            uiFilters: [
              {
                label: '',
                selectedValue: [],
                isInclude: true,
              },
            ],
          },
        });
        fetchDatadogDescribeMetric(
          {
            type: dataStream.type,
            dataSourceId: dataStream.dataSourceId,
            metricName: selectedMetric,
          },
          {
            metricName: selectedMetric,
          },
        );
      }
    };

    render() {
      const {
        dataStream,
        streamType,
        metrics,
        isLoadingMetrics,
        fetchDatadogQueryPreview,
        isPreviewDataLoading,
        previewDataError,
        previewData,
        queryPreviewRes,
        setDatadogStreamQuery,
      } = this.props;
      const isEditable = isStreamEditable(dataStream.state) || isEditRunning(dataStream.id);
      const metric = dataStream.metricName;
      const selectedIndex = metrics.findIndex((val) => metric === val);
      const query = dataStream.query ? dataStream.query : DEFAULT_QUERY;

      return (
        <div className="shell-col" styleName="root">
          <PanelHeader title="Datadog Metric" isEditable={isEditable} />

          <div styleName="block">
            <div styleName="radio-block">
              <input
                type="radio"
                name="queryType"
                value="table"
                checked={streamType === 'table'}
                onChange={this.setSelectedRadio}
              />
            </div>

            <div styleName="select-block">
              <SelectAndt
                id="datadogMetricSelect"
                automationId="coralogixMetricSelect"
                options={metrics}
                getOptionLabel={(val) => val}
                getOptionValue={(val) => val}
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_NOT_HIGHLIGHTED}
                value={isLoadingMetrics ? null : metrics[selectedIndex]}
                onChange={(e) => this.metricChanged(e, 'metric')}
                placeholder={isLoadingMetrics ? 'Loading...' : 'Choose a Metric'}
                disabled={!isEditable || isLoadingMetrics || streamType !== 'table'}
                optionHeight={40}
                isClearable={false}
              />
            </div>
          </div>

          <div styleName="block">
            <div styleName="radio-block">
              <input
                type="radio"
                name="queryType"
                value="query"
                checked={streamType === 'query'}
                onChange={this.setSelectedRadio}
              />
            </div>

            <div styleName="select-block">
              <button
                type="button"
                className="btn btn-raised"
                styleName="btn-compose"
                automation-id="datadogComposeQueryButton"
                disabled={streamType !== 'query'}
                onClick={this.openQueryEditorModal}
              >
                Compose Query
              </button>
            </div>
          </div>

          {this.state.isOpenQueryEditorModal && (
            <QueryEditorModal
              isOpen={this.state.isOpenQueryEditorModal}
              onClose={this.closeQueryEditorModal}
              dataStream={dataStream}
              query={query}
              hideActualQuery
              noQueryVerify
              runQueryPreview={fetchDatadogQueryPreview}
              previewData={previewData}
              previewDataError={previewDataError}
              isPreviewDataLoading={isPreviewDataLoading}
              queryPreviewColumns={queryPreviewRes}
              setStreamQuery={setDatadogStreamQuery}
            />
          )}
        </div>
      );
    }
  },
);
