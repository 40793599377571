import React, {useState} from 'react';

import {useFormContext} from 'react-hook-form';
import {makeStyles} from '@material-ui/core';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import usePreviewClasses from 'dataCollectors/styles/classes.usePreviewClasses';
import {getIsLoading} from 'reactQuery/ReactQueryLoading';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import ContentLoaderPreview from 'dataCollectors/components/loaders/ContentLoaderPreview';
import EditModal from 'dataCollectors/components/EditModal/EditModal';
import StreamTable from 'dataCollectors/components/sourceData/streamTable/StreamTable';

const useStyles = makeStyles(({typography, palette}) => ({
  editBtn: {
    ...typography.subtitle2,
    position: 'absolute',
    color: palette.blue['500'],
    right: 32,
    top: 20,
    cursor: 'pointer',
  },
  header: {
    marginBottom: 48,
  },
  title: {
    marginBottom: 16,
    '& span': {
      ...typography.subtitle2,
      display: 'block',
    },
    '& span:first-child': {
      ...typography.h1,
    },
    '& span:nth-child(2)': {
      ...typography.subtitle2,
      marginBottom: 6,
    },
  },
  description: {
    ...typography.subtitle1,
    whiteSpace: 'break-spaces',
    lineHeight: '20px',
  },
  infoLabel: {
    color: palette.gray['400'],
  },
}));

const {TABLE_NAME, SCHEMA_NAME, DATABASE_NAME} = EVENT_STREAM_FIELDS;

const StreamTablePreview = () => {
  const classes = useStyles();
  const previewClasses = usePreviewClasses();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const {getValues} = useFormContext();
  const values = getValues();

  const isLoading = getIsLoading([QUERY_KEYS.streams]);

  return (
    <div>
      <div className={previewClasses.sectionWrapper}>
        {isLoading && <ContentLoaderPreview />}
        {!isLoading && (
          <>
            <div className={classes.body}>
              <div>
                <span className={previewClasses.sectionTitle}>Record collection data</span>
              </div>

              <div className={previewClasses.row}>
                <span className={classes.infoLabel}>{DATABASE_NAME.name}</span>
                <span>{values[DATABASE_NAME.id]}</span>
              </div>

              <div className={previewClasses.row}>
                <span className={classes.infoLabel}>{TABLE_NAME.name}</span>
                <span>{values[TABLE_NAME.id]}</span>
              </div>

              <div className={previewClasses.row}>
                <span className={classes.infoLabel}>{SCHEMA_NAME.name}</span>
                <span>{values[SCHEMA_NAME.id]}</span>
              </div>
            </div>
          </>
        )}
      </div>
      <EditModal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
        <StreamTable />
      </EditModal>
    </div>
  );
};

export default StreamTablePreview;
