// @flow
import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {get} from 'lodash';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {
  setSelectedStreamKeyVal,
  setSelectedStreamUiKeyVal,
  fetchAwsCurReports,
  fetchAwsCurManifest,
  fetchAwsTemplates,
} from 'bc/store/actions';
import {isEditRunning} from 'bc/services/dataStreamService';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import './AwsCurData.module.scss';

type PropTypes = {
  dataStream: Object,
  periods: Array,
  reportsIsLoading: Boolean,

  // connect
  setSelectedStreamKeyVal: Function,
  setSelectedStreamUiKeyVal: Function,
  fetchAwsCurReports: Function,
  fetchAwsCurManifest: Function,
  fetchAwsTemplates: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    periods: selectors.getCurReportsItemsObj(state),
    reportsIsLoading: selectors.getCurReportsIsLoading(state),
  }),
  {
    setSelectedStreamKeyVal,
    setSelectedStreamUiKeyVal,
    fetchAwsCurReports,
    fetchAwsCurManifest,
    fetchAwsTemplates,
  },
)(
  class AwsCurData extends React.PureComponent {
    props: PropTypes;

    state = {
      path: get(this.props.dataStream, 'uiState.path', this.props.dataStream.path) || '',
      period: get(this.props.dataStream, 'uiState.period', this.props.dataStream.period),
    };

    componentDidMount() {
      this.props.fetchAwsTemplates(this.props.dataStream.type);
      this.goClicked();
    }

    componentDidUpdate() {
      const {period} = this.state;
      const {dataStream, periods} = this.props;
      if (isEditRunning(dataStream.id) && periods && periods.length && period === undefined) {
        this.onPeriodSelect(periods[periods.length - 1]);
      }
    }

    valueChanged = (val, inputId) => {
      const newState = {};
      newState[inputId] = val;
      this.setState(newState);
      this.props.setSelectedStreamUiKeyVal(newState);
    };

    goClicked = () => {
      if (this.state.path && this.state.path !== '') {
        if (this.props.dataStream.path !== this.state.path) {
          this.props.setSelectedStreamKeyVal({path: this.state.path});
        }
        this.props.fetchAwsCurReports({
          reportPath: this.state.path,
          dataSourceId: this.props.dataStream.dataSourceId,
        });
        this.props.fetchAwsCurManifest({
          path: this.state.path,
          dataSourceId: this.props.dataStream.dataSourceId,
        });
      }
    };

    isGoDisabled = () =>
      this.state.path === '' || get(this.props.dataStream, 'uiState.path') === this.props.dataStream.path;

    onPeriodSelect = (val) => {
      if (val !== this.state.period) {
        this.valueChanged(val.value, 'period');
      }
      if (val.value !== null) {
        const ts = moment(`01-${val.label} 00:00`, 'DD-MM-YYYY H:mm').unix();
        this.props.setSelectedStreamKeyVal({
          historicalDateRange: {
            constRange: 'c',
            startDate: ts,
          },
        });
      } else {
        this.props.setSelectedStreamKeyVal({
          historicalDateRange: null,
        });
      }
    };

    render() {
      const {path, period} = this.state;
      const {reportsIsLoading, periods} = this.props;

      const periodCurrent = periods.findIndex((option) => option.value === period);

      return (
        <div className="shell-col" styleName="root">
          <PanelHeader title="Reports to collect" isEditable />

          <div styleName="inputs-wrapper">
            <span styleName="input-title">CUR Reports Path</span>
            <input
              type="text"
              onChange={(e) => this.valueChanged(e.target.value, 'path')}
              placeholder="Path: Type the report path"
              value={path}
            />
          </div>

          <div styleName="inputs-wrapper">
            <button type="button" className="btn btn-raised" disabled={this.isGoDisabled()} onClick={this.goClicked}>
              Go
            </button>
          </div>

          <div styleName="error">
            {this.props.dataStream.path && !periods.length && reportsIsLoading === false ? (
              <div>
                We couldn&rsquo;t find any report periods in the path you entered. This could be because there are no
                CUR files in this path yet, or because the path is incorrect. Please check the report path is correct
                and try again.
              </div>
            ) : (
              ''
            )}
          </div>

          <div styleName="inputs-wrapper">
            <span className="ellipsis" styleName="input-title">
              First CUR Report
            </span>
            <span styleName="input-description">Specify the first report period you wish to collect</span>
            <SelectAndt
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_NOT_HIGHLIGHTED}
              onChange={this.onPeriodSelect}
              options={periods}
              buttonWidth={180}
              menuWidth={250}
              optionHeight={40}
              value={reportsIsLoading || !periods.length || periodCurrent === -1 ? '' : periods[periodCurrent]}
              placeholder={reportsIsLoading === true ? 'Loading...' : 'Choose First Report period'}
              disabled={reportsIsLoading !== false || !periods.length}
              automationId="awsCurChooseDateRange"
            />
          </div>
        </div>
      );
    }
  },
);
