import {get} from 'lodash';
import GoogleOAuth2 from 'google-oauth2-web-client';
import {bcTypes} from './bcTypes';

export const newFilter = [{name: '', values: ''}];

export const doGa4AuthFlow = (clientId, createDataSource, me) => {
  let locOrigin = window.location.origin;
  if (locOrigin.indexOf('localhost:') === -1) {
    const baseDnsName = me.baseDNSName === 'anodot-test.com' ? 'ano-dev.com' : me.baseDNSName;
    locOrigin = `https://${me.clusterDNSPrefix}.${baseDnsName}`;
  }

  const go2 = new GoogleOAuth2({
    clientId,
    redirectUri: `${locOrigin}/api/v1/bc/signin/oauth/google/callback`,
    scope:
      // eslint-disable-next-line max-len
      'https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/analytics.manage.users.readonly',
    responseType: 'code',
    accessType: 'offline',
    state: me.ownerOrganization || 'no-org-id',
    popupHeight: 600,
    popupWidth: 600,
  });
  go2.login(true, false);

  window.createDataSource = function(token) {
    createDataSource({
      code: token,
      type: bcTypes.google_analytics_ga4.type,
      name: `Google GA4 Data Source ${new Date().getTime().toString()}`,
      authenticationType: 'refresh_token',
      redirectURI: `${locOrigin}/api/v1/bc/signin/oauth/google/callback`,
    });
  };
};

export const convertGA4UiFiltersToStreamFilters = (uiFilters) => {
  if (!uiFilters.length) {
    return [];
  }
  const filters = [];
  uiFilters.forEach((uiFilter) => {
    if (uiFilter.name) {
      const filterValValue = uiFilter.values
        ? uiFilter.values
            .trim()
            .split(',')
            .map((val) => val.trim())
        : '';
      filters.push({name: uiFilter.name, values: filterValValue, op: 'INCLUDE', type: 'DIMENSION'});
    }
  });
  return filters;
};

export const convertGA4StreamFiltersToUiFilters = (sFilters) => {
  if (!sFilters || !sFilters.length) {
    return newFilter;
  }
  const filters = [];
  sFilters.forEach((sFilter) => {
    const values = (sFilter.values || []).join(', ');
    filters.push({name: sFilter.name, values});
  });
  return filters;
};

export const getEditorValidationMessage = (stream) => {
  if (!stream.accountId) {
    return 'Google analytics account is mandatory';
  }
  if (!stream.propertyId) {
    return 'Google analytics property is mandatory';
  }
  if (!stream.basedOnTemplateId || stream.basedOnTemplateId === '') {
    return 'A template is mandatory';
  }
  if (!get(stream, 'metrics.length')) {
    return 'Data stream should have at least one metric';
  }
  if (get(stream, 'dimensions.length') > 7) {
    return 'Data stream can have up to 7 dimension';
  }
  if (get(stream, 'metrics.length') > 10) {
    return 'Data stream can have up to 10 metrics';
  }
  const isEmptyFilter = get(stream, 'filters', []).find((filter) => filter.values === '');
  if (isEmptyFilter) {
    return 'Filter must have a value';
  }
  return null;
};
