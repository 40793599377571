// @flow
import React from 'react';
import {Box} from '@material-ui/core';
import {setMaxNumberToDisplay} from 'common/utils/numbers';
import {getFormattedHumanDuration, getFormattedMDH} from 'common/utils/dateService';
import MetricName from 'metrics/components/metricName/MetricName';
import {ReactComponent as StatusIcon} from 'investigation/images/open_alert.svg';
import ImpactChip from 'impact/ImpactChip';
import {themesOptions} from 'impact/impactTheme';
import './MetricsTable.module.scss';

const MetricsTableRowHeader = ({
  metric,
  timeZoneName,
  columns,
}: {
  metric: Object,
  timeZoneName: string,
  columns: Object,
}) => {
  const interval = metric.intervals
    ? metric.intervals[metric.intervals.length - 1]
    : metric.currentAnomalyIntervals[metric.currentAnomalyIntervals.length - 1];

  const columnsNames = columns.map((i) => i.name);

  return (
    <Box styleName="table-row-header">
      <div styleName="col-is-open">{metric.state === 'open' && <StatusIcon style={{flexShrink: 0}} />}</div>
      {columnsNames.includes('title') && (
        <div styleName="col-title">
          <MetricName isSingleLine isDisableCopy metric={metric} highlightText={null} />
        </div>
      )}
      {columnsNames.includes('impact') && (
        <div styleName="col-impact">
          {metric.impact ? (
            <ImpactChip
              impact={metric.impact}
              themeName={themesOptions.small}
              tooltipContent="Impact for this Metric"
            />
          ) : (
            <span>No Impact</span>
          )}
        </div>
      )}
      {columnsNames.includes('startTime') && (
        <div styleName="col-startTime">{getFormattedMDH(interval.startTime, timeZoneName)}</div>
      )}

      {columnsNames.includes('duration') && (
        <div styleName="col-duration">{getFormattedHumanDuration(metric.duration)}</div>
      )}

      {columnsNames.includes('upperPercentageDelta') &&
        (metric.upperPercentageDelta ? (
          <div styleName="col-upperPercentageDelta">
            <i className="icon icn-arrow16-arrowup" style={{marginLeft: -4}} />
            {setMaxNumberToDisplay(metric.upperPercentageDelta * Math.sign(metric.upperPercentageDelta), 1000, 1)}%
          </div>
        ) : (
          <div styleName="col-upperPercentageDelta" />
        ))}

      {columnsNames.includes('lowerPercentageDelta') &&
        (metric.lowerPercentageDelta ? (
          <div styleName="col-lowerPercentageDelta">
            <i className="icon icn-arrow16-arrowdown" style={{marginLeft: -4}} />
            {setMaxNumberToDisplay(metric.lowerPercentageDelta * Math.sign(metric.lowerPercentageDelta), 1000, 1)}%
          </div>
        ) : (
          <div styleName="col-lowerPercentageDelta" />
        ))}

      {columnsNames.includes('score') && <div styleName="col-score">{Math.round(interval.score * 100)}</div>}
    </Box>
  );
};

export default MetricsTableRowHeader;
