import {createSelector} from 'reselect';
import {get, isString, uniqBy} from 'lodash';
import {getUsersData} from 'admin.users/store/selectors';
import {getFetchLookupTablesData} from 'bc/store/selectors';
import {TYPES, FILTER_TYPES_OPTIONS, filterKeyHeaders} from 'assets/services/service';
import {getAlertConfigurationsItems} from 'alerts.management/store/selectors';

const getUserName = (user) => {
  if (!user) {
    return null;
  }
  const firstName = user.firstName ? user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1) : null;
  const lastName = user.lastName ? user.lastName.charAt(0).toUpperCase() : null;
  return firstName && lastName ? `${firstName}. ${lastName}` : user.email;
};

// Data
const getRoot = (state) => state.assets;

const getData = createSelector(
  getRoot,
  (root) => root.data,
);

const getDynamicRoutingFiles = createSelector(
  getData,
  (data) => data.dynamicRoutingFiles,
);

export const getDynamicRoutingFilesData = createSelector(
  getDynamicRoutingFiles,
  getAlertConfigurationsItems,
  (dynamicRoutingFiles, alertsConiguration) => {
    const dynamicRoutingFilesWithAlerts =
      dynamicRoutingFiles.data?.length && alertsConiguration.length
        ? dynamicRoutingFiles.data.map((i) => {
            const alertsUsingDynamicRouting = alertsConiguration.filter(
              (alert) => alert.data?.dynamicRouting && alert.data?.dynamicRouting.metaId === i.id,
            );
            const formattedAlertsUsingDynamicRouting = alertsUsingDynamicRouting.map((alertUsed) => ({
              id: alertUsed.data.id,
              title: alertUsed.data.title,
            }));
            return {
              ...i,
              alertsUsed: formattedAlertsUsingDynamicRouting,
            };
          })
        : dynamicRoutingFiles.data;
    return dynamicRoutingFilesWithAlerts;
  },
);

export const getDynamicRoutingFilesIsLoading = createSelector(
  getDynamicRoutingFiles,
  (dynamicRoutingFiles) => dynamicRoutingFiles.isLoading,
);

export const getDynamicRoutingAndLookupData = createSelector(
  getFetchLookupTablesData,
  getDynamicRoutingFilesData,
  getUsersData,
  (lookup, dynamic, userData) => {
    if (userData.length && (lookup || dynamic)) {
      const lookupData = lookup.length
        ? lookup.map((i) => {
            const user = userData.find((u) => u._id === i.creator);
            return {
              creationTime: i.createTime,
              editTime: i.modifyTime || i.createTime,
              owner: i.creator,
              ownerName: getUserName(user),
              type: TYPES.LOOKUP,
              title: i.name,
              id: i.id,
            };
          })
        : [];
      const dynamicData = dynamic.length
        ? dynamic.map((i) => {
            const user = userData.find((u) => u._id === i.owner);
            return {
              creationTime: i.creationTime,
              editTime: i.editTime || i.creationTime,
              owner: i.owner,
              ownerName: getUserName(user),
              type: TYPES.DYNAMIC_ROUTING,
              title: i.title,
              id: i.id,
              alertsUsed: i.alertsUsed,
            };
          })
        : [];
      return [...lookupData, ...dynamicData];
    }
    return [];
  },
);

export const getUploadFile = createSelector(
  getData,
  (data) => data.uploadDynamicRoutingFile,
);

export const getUploadFileData = createSelector(
  getUploadFile,
  (uploadDynamicRoutingFile) => uploadDynamicRoutingFile.data,
);

export const getUploadFileError = createSelector(
  getUploadFile,
  (uploadDynamicRoutingFile) => uploadDynamicRoutingFile.error,
);

export const getUploadFileIsLoading = createSelector(
  getUploadFile,
  (uploadFile) => uploadFile.isLoading,
);

const getViewDynamicRoutingFile = createSelector(
  getData,
  (data) => data.viewDynamicRoutingFile,
);

export const getViewDynamicRoutingFileData = createSelector(
  getViewDynamicRoutingFile,
  (viewDynamicRoutingFile) => {
    const obj = {};
    if (viewDynamicRoutingFile.data) {
      obj.rows = viewDynamicRoutingFile.data;
    }
    return obj;
  },
);

export const getViewDynamicRoutingFileIsLoading = createSelector(
  getViewDynamicRoutingFile,
  (viewDynamicRoutingFile) => viewDynamicRoutingFile.isLoading,
);

// View
const getView = createSelector(
  getRoot,
  (root) => root.view,
);

export const getScrollbarWidth = createSelector(
  getView,
  (view) => view.tables.scrollbarWidth,
);

export const getFilesListOrder = createSelector(
  getView,
  (view) => view.tables.order,
);

export const getFilters = createSelector(
  getView,
  (view) => view.tables.filters,
);

export const getActiveFilters = createSelector(
  getFilters,
  (filters) => {
    const obj = {};
    // eslint-disable-next-line
    for (const key in filters) {
      if (Object.prototype.hasOwnProperty.call(filters, key) && filters[key] && filters[key].length) {
        obj[key] = filters[key];
      }
    }
    return obj;
  },
);

export const getSelectedFilterSearchQuery = createSelector(
  getFilters,
  (filters) => get(filters, 'searchQuery', ''),
);

export const getOwners = createSelector(
  getDynamicRoutingAndLookupData,
  getUsersData,
  (ownerData, userData) => {
    if (ownerData.length && userData.length) {
      return uniqBy(ownerData, 'owner')
        .map((i) => {
          const user = userData.find((u) => u._id === i.owner);
          return {
            label: getUserName(user),
            value: i.owner,
          };
        })
        .filter((f) => f.value);
    }
    return [];
  },
);

export const getSelectedFilterOwner = createSelector(
  getOwners,
  getFilters,
  (ownerList, filters) => {
    const selectedOwnersArr = filters.owner ? filters.owner.split(',') : null;
    return selectedOwnersArr && ownerList.length !== 0
      ? ownerList.filter((i) => selectedOwnersArr.indexOf(i.value) !== -1)
      : null;
  },
);

export const getSelectedFilterType = createSelector(
  getFilters,
  (filters) => {
    const selectedTypesArr = filters.type ? filters.type.split(',') : null;
    return selectedTypesArr ? FILTER_TYPES_OPTIONS.filter((i) => selectedTypesArr.indexOf(i.value) !== -1) : null;
  },
);

export const getFilteredDynamicAndLookup = createSelector(
  getFilters,
  getDynamicRoutingAndLookupData,
  (filters, items) =>
    items.filter((item) => {
      if (filters.searchQuery && item.title.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) === -1) {
        return false;
      }
      if (filters.owner && filters.owner.split(',').indexOf(item.owner) === -1) {
        return false;
      }
      if (filters.type && filters.type.split(',').indexOf(item.type) === -1) {
        return false;
      }
      return true;
    }),
);

export const getSortedDynamicRoutingAndLookup = createSelector(
  getFilteredDynamicAndLookup,
  getFilesListOrder,
  getSelectedFilterType,
  (data, order) => {
    const sortingFunction = (arr, sortBy, direction) => {
      if (sortBy === 'added' || sortBy === 'lastEdited') {
        const sortValue = sortBy === 'added' ? 'creationTime' : 'editTime';
        return arr.sort((a, b) => {
          const aa = a[sortValue];
          const bb = b[sortValue];
          if (aa < bb) {
            return -1 * direction;
          }
          if (aa > bb) {
            return direction;
          }
          return 0;
        });
      }
      return arr.sort((a, b) => {
        const itemA = isString(a[sortBy]) ? a[sortBy].toLowerCase() : a[sortBy];
        const itemB = isString(b[sortBy]) ? b[sortBy].toLowerCase() : b[sortBy];
        if (itemA < itemB) {
          return -1 * direction;
        }
        if (itemA > itemB) {
          return direction;
        }
        return 0;
      });
    };

    return [...sortingFunction(data, order.column, order.direction === 'desc' ? -1 : 1)];
  },
);

export const getFiltersButtonTooltipItems = createSelector(
  getActiveFilters,
  getUsersData,
  (changedFilters, userData) => {
    const filterItemsList = [];
    Object.keys(changedFilters).forEach((filterKey) => {
      const item = {
        id: get(filterKeyHeaders[filterKey], 'id', null),
        header: get(filterKeyHeaders[filterKey], 'label', null),
      };

      switch (filterKey) {
        case filterKeyHeaders.searchQuery.id:
          filterItemsList.push({
            ...item,
            value: changedFilters[filterKey],
          });
          break;
        case filterKeyHeaders.type.id:
          filterItemsList.push({
            ...item,
            value: changedFilters[filterKey],
          });
          break;
        case filterKeyHeaders.owner.id:
          filterItemsList.push({
            ...item,
            value: userData.find((user) =>
              getUserName(changedFilters[filterKey].split(',').find((id) => user._id === id)),
            ),
          });
          break;
        default:
          break;
      }
    });

    return filterItemsList;
  },
);
