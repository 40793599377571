// @flow
import React, {useEffect, useState} from 'react';
import {getDate, rangeTypes} from 'common/utils/dateRangeService';
import DateRangesDdl, {THEME} from 'common/components/dateTime/DateRangesDdl';
import {useSelector} from 'react-redux';
import {getTimeZoneName} from 'profile/store/selectors';
import {StringParam, useQueryParams} from 'use-query-params';

const DateRangeFilter = ({defaultDateRange}: {defaultDateRange: string}) => {
  const timeZoneName = useSelector(getTimeZoneName);
  const [queryParams, setQueryParams] = useQueryParams({
    constRange: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    relativeLast: StringParam,
    relativeNext: StringParam,
  });
  const [dateRange, setDateRange] = useState({});
  useEffect(() => {
    setDateRange(
      getDate(
        {
          constRange: queryParams.constRange,
          startDate: queryParams.startTime,
          endDate: queryParams.endTime,
          relativeLast: queryParams.relativeLast,
          relativeNext: queryParams.relativeNext,
        },
        timeZoneName,
        rangeTypes[defaultDateRange],
      ),
    );
  }, [queryParams, timeZoneName, defaultDateRange]);

  const dateRangeChange = (dr) => {
    const isCustom = dr.constRange === rangeTypes.c.value;
    const isRelative = dr.constRange === rangeTypes.r.value;
    const times = {
      startTime: isCustom ? dr.startDate : undefined, // null is OK, before api req will calc relative date
      endTime: isCustom ? dr.endDate : undefined,
      relativeLast: isRelative ? dr.relativeLast : undefined,
      relativeNext: isRelative && String(dr.relativeNext) !== String(0) ? dr.relativeNext : undefined,
    };

    setQueryParams({constRange: dr.constRange, ...times});
  };

  return (
    <DateRangesDdl
      onChange={dateRangeChange}
      dateRange={dateRange}
      theme={THEME.HIGHLIGHTED}
      allowedRangeTypes={['h1', 'h4', 'd1', 'd3', 'w1', 'w2', 'm1', 'm3', 'm6', 'y1']}
      allowedCustomDates={false}
      isUnix
    />
  );
};
export default React.memo(DateRangeFilter);
