export const QUERY_KEYS = {
  alertActions: 'alertActions',
  configurationAlertsTotal: 'configurationAlertsTotal',
  configurationAlerts: 'configurationAlerts',
  configurationAlertById: 'configurationAlertById',
  createSource: 'createSource',
  dataStreams: 'dataStreams',
  datasets: 'datasets',
  datasetTables: 'datasetTables',
  datasetTablesMetadata: 'datasetTablesMetadata',
  datasetTablePreview: 'dataSetTablePreview',
  eventStreamLog: 'eventStreamLog',
  eventStreamLastRun: 'eventStreamLastRun',
  fileMetaData: 'fileMetaData',
  fileAnalyze: 'fileAnalyze',
  filePreview: 'filePreview',
  metricsUsage: 'metricsUsage',
  projects: 'projects',
  recommendations: 'recommendations',
  recommendationsByAlertId: 'recommendationsByAlertId',
  salesforceChannelClientId: 'salesforceChannelClientId',
  schemas: 'schemas',
  sources: 'sources',
  streams: 'streams',
  svg: 'svg',
  tables: 'tables',
  tableMetadata: 'tableMetadata',
  tablePreview: 'tablePreview',
  tablePreviewTransform: 'tablePreviewTransform',
  template: 'template',
  triggeredAlerts: 'triggeredAlerts',
  triggeredAlertsChannels: 'triggeredAlertsChannels',
  triggeredAlertGroupMetrics: 'triggeredAlertGroupMetrics',
  triggeredAlertForImpact: 'triggeredAlertForImpact',
  triggeredAlertsInsightPanelCountBy: 'triggeredAlertsInsightPanelCountBy',
  triggeredAlertsInsightPanelFeedbackStats: 'triggeredAlertsInsightPanelFeedbackStats',
  triggeredAlertsInsightPanelMetricsUsage: 'triggeredAlertsInsightPanelMetricsUsage',
  triggeredAlertsInsightPanelNoFeedback: 'triggeredAlertsInsightPanelNoFeedback',
  triggeredAlertMetrics: 'triggeredAlertMetrics',
  triggeredAlertTimeline: 'triggeredAlertTimeline',
  triggeredAlertsTotal: 'triggeredAlertsTotal',
  triggeredAlertsUsers: 'triggeredAlertsUsers',
  userEventsCategories: 'userEventsCategories',
  users: 'users',
  valueReportToken: 'valueReportToken',
};

export const POPO = {};
