import {useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {isEmpty} from 'lodash';
import {
  ALERT_ACTION_PARAMS,
  ICONS_TYPES,
} from 'alerts.management/components/editor/simulationArea/alertActions/services/service';
import Button, {COLORS, WIDTHS} from 'common/componentsV2/Button';
import '../OverwiewTab.module.scss';
import {makeStyles} from '@material-ui/core';
import {getFetchTriggeredAlertIsLoading, getInvModalTrigger} from '../../../store/selectors';

const AlertActionsCube = ({onShareClicked, onIsLoading}: {onShareClicked: Function, onIsLoading: Function}) => {
  const css = makeStyles(() => ({
    actionBtn: {
      maxWidth: 254,
    },
    whatTabChip: {
      maxWidth: 253,
    },
  }));
  const classes = css();
  const trigger = useSelector(getInvModalTrigger);
  const isLoading = useSelector(getFetchTriggeredAlertIsLoading);

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);

  if (isEmpty(trigger)) {
    return null;
  }

  const {alertActions} = trigger;

  const goTo = (url) => {
    window.open(url);
  };

  return (
    <div styleName="info-cube-container alert-action-cube">
      {alertActions &&
        alertActions.map((action) => (
          <div key={action[ALERT_ACTION_PARAMS.ACTION_ID]}>
            <Button
              extraClassName={classes.actionBtn}
              text={action[ALERT_ACTION_PARAMS.BTN_NAME]}
              colorSchema={COLORS.BLUE_500}
              width={WIDTHS.NARROW}
              icon={`icon ${[ICONS_TYPES[action[ALERT_ACTION_PARAMS.ACTION_TYPE]]]}`}
              onClick={() => goTo(action[ALERT_ACTION_PARAMS.DATA][ALERT_ACTION_PARAMS.DATA_URL])}
            />
          </div>
        ))}
      {(!alertActions || alertActions.length < 3) && (
        <Button
          text="Send to.."
          width={WIDTHS.NARROW_TIGHT}
          colorSchema={COLORS.BLUE_500}
          icon="icn-action16-send"
          onClick={onShareClicked}
        />
      )}
    </div>
  );
};

export default AlertActionsCube;
