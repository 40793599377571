import React, {useRef, useState} from 'react';
import useClickOutside from 'common/hooks/useClickOutside';
import Button, {COLORS as BUTTON_COLORS} from 'common/componentsV2/Button';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 214,
    borderRadius: 6,
    boxShadow: '0 4px 12px -2px rgba(0, 0, 36, 0.25)',
    backgroundColor: theme.palette.white[500],
    padding: 4,
    position: 'absolute',
    right: 0,
    top: 37,
    zIndex: 3,
  },
  logo: {
    width: '14px',
    height: '14px',
    position: 'relative',
    display: 'inline-block',
  },
  title: {
    left: '6px',
    color: theme.palette.gray[500],
    position: 'relative',
  },
  option: {
    padding: '8px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    marginBottom: '4px',
    '&:last-child': {
      marginBottom: 0,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.blue[100],
    },
  },
}));

const SimpleButtonIconList = ({
  options,
  onSelect,
  text,
  icon,
  automationId,
}: {
  onSelect: Function,
  options: Array,
  text: string,
  icon: string,
  automationId: String,
}) => {
  const dropdownRef = useRef();
  const [isDropdownOpened, setDropdownOpened] = useState(false);
  useClickOutside(dropdownRef, () => setDropdownOpened(false));
  const classes = useStyles();

  return (
    <div className="position_relative">
      <Button
        colorSchema={BUTTON_COLORS.BLUE_500}
        text={text}
        icon={icon}
        onClick={() => setDropdownOpened(true)}
        automationId={automationId || 'SimpleButtonIconList'}
      />
      {isDropdownOpened && (
        <div ref={dropdownRef} className={classes.wrapper}>
          {options.map((option) => (
            <div
              className={classes.option}
              onClick={() => {
                onSelect(option);
                setDropdownOpened(false);
              }}
              automation-id={`${automationId || 'SimpleButtonIconList'}-option-${option.label}`}
            >
              <option.icon width={30} height={30} viewBox="0 0 50 50" />
              <span className={classes.title}>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SimpleButtonIconList;
