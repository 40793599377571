// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import Checkbox from 'common/componentsV2/Checkbox';
import * as filterSelector from 'alerts.management/store/filterSelector';
import {DEFAULT_QUERY_PARAMS} from 'alerts.management/services/alertManagementService';

const RecommendationsFilter = ({setQueryParams}: {setQueryParams: Function}) => {
  const isRecommendations = useSelector(filterSelector.getAlertFiltersRecommendations);
  const onChange = (val) => {
    setQueryParams({recommendations: val === true ? val : DEFAULT_QUERY_PARAMS.recommendations});
  };

  return (
    <Checkbox
      isChecked={isRecommendations === true}
      text="Alerts with recommendations"
      automationId="alertsWithRecommendations"
      onChange={onChange}
    />
  );
};

export default React.memo(RecommendationsFilter);
