// @flow
import React, {useState, useRef, useEffect, useMemo} from 'react';
import {useHistory} from 'react-router';
import {AutoSizer, List} from 'react-virtualized';
import ReactResizeDetector from 'react-resize-detector';
import PropTypes from 'prop-types';
import ErrorBoundary from 'errorBoundaries/ErrorBoundary';
import EmptySearch from 'common/componentsV2/EmptySearch';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import Button, {COLORS} from 'common/componentsV2/Button';
import CompositeItem from 'composites/components/CompositeItem';
import CompositesTableHeader from 'composites/components/CompositesTableHeader';
import useFetchCompositesList from 'composites/api/useFetchComposites';
import {useCompositesContext} from 'composites/hooks/useCompositesContext';
import {COMPOSITE_COLUMNS, TYPE_COMPOSITES} from 'composites/services/compositesService';
import {STAGES_ACTIONS} from 'composites/store/reducers/useCompositesReducer';
import fetchConfigurationAlertsProvider from 'alerts.management/api/fetchConfigurationAlertsProvider';

const CompositesList = React.memo(({compositesList, setCompositeList, noData}: PropTypes) => {
  const listRef = useRef();
  const history = useHistory();
  const {isCompositesListLoading} = useFetchCompositesList();
  const {editState, editDispatch} = useCompositesContext();
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const [order, setOrder] = useState({column: 'title', direction: 'asc'});

  const isAlertsDataLoading = fetchConfigurationAlertsProvider()?.useQuery()?.isLoading;

  const changedIndex = useMemo(
    () => compositesList.findIndex((composite) => composite.composite.id === editState.selectedItemId),
    [compositesList, editState.selectedItemId],
  );

  const rowRenderer = ({index, style}: PropTypes) => {
    const item = compositesList[index];
    return (
      <CompositeItem key={item.composite.id} item={item} style={style} isLast={index === compositesList.length - 1} />
    );
  };

  const recalculateScrollbarWidth = () => {
    const elements = document.getElementsByClassName('ReactVirtualized__List');
    let scrollWidth = 0;
    if (elements && elements.length > 0) {
      const ele = elements[0];
      scrollWidth = ele.offsetWidth - ele.clientWidth;
    }
    setScrollBarWidth(scrollWidth);
  };

  const rowHeight = ({index}) => {
    if (index !== changedIndex) {
      return 50;
    }
    if (compositesList[index].composite.mtype === TYPE_COMPOSITES.ALERT) {
      return 149;
    }

    return 291;
  };

  useEffect(() => {
    if (!compositesList.length) {
      return;
    }
    recalculateScrollbarWidth();
    if (listRef?.current) {
      listRef.current.recomputeRowHeights(0);
    }
  });

  const getSortCompositesByError = (direction) => {
    if (direction === 'desc')
      return compositesList.sort((a, b) => (a.validation?.passed && !b.validation?.passed ? 1 : -1));
    return compositesList.sort((a, b) => (!a.validation?.passed && b.validation?.passed ? 1 : -1));
  };

  const getSortCompositesByCreationTime = (direction) => {
    if (direction === 'desc') return compositesList.sort((a, b) => (a.creationTime < b.creationTime ? 1 : -1));
    return compositesList.sort((a, b) => (a.creationTime > b.creationTime ? 1 : -1));
  };

  const getSortCompositesByAlertUsage = (direction) => {
    if (direction === 'desc')
      return compositesList.sort((a, b) => (a.usedInAlerts?.length < b.usedInAlerts?.length ? 1 : -1));
    return compositesList.sort((a, b) => (a.usedInAlerts?.length > b.usedInAlerts?.length ? 1 : -1));
  };

  const getSortCompositesByMetricUsage = (direction) => {
    if (direction === 'desc')
      return compositesList.sort((a, b) => (a.metricsUsageCount < b.metricsUsageCount ? 1 : -1));
    return compositesList.sort((a, b) => (a.metricsUsageCount > b.metricsUsageCount ? 1 : -1));
  };

  const getSortCompositesByColumn = (direction, column) => {
    if (direction === 'desc')
      return compositesList.sort((a, b) =>
        !a.composite[column] || a.composite[column]?.toLowerCase() < b.composite[column]?.toLowerCase() ? 1 : -1,
      );
    return compositesList.sort((a, b) =>
      !b.composite[column] || a.composite[column]?.toLowerCase() > b.composite[column]?.toLowerCase() ? 1 : -1,
    );
  };

  const compositeListOrder = (sortColumn) => {
    if (!sortColumn.column) return;
    switch (sortColumn.column) {
      case COMPOSITE_COLUMNS.ERROR:
        setCompositeList(getSortCompositesByError(sortColumn.direction));
        break;
      case COMPOSITE_COLUMNS.DATECREATE:
        setCompositeList(getSortCompositesByCreationTime(sortColumn.direction));
        break;
      case COMPOSITE_COLUMNS.METRICS_USAGE:
        setCompositeList(getSortCompositesByMetricUsage(sortColumn.direction));
        break;
      case COMPOSITE_COLUMNS.ALERT_USAGE:
        setCompositeList(getSortCompositesByAlertUsage(sortColumn.direction));
        break;
      default:
        setCompositeList(getSortCompositesByColumn(sortColumn.direction, sortColumn.column));
        break;
    }

    editDispatch({type: STAGES_ACTIONS.SET_SELECTED_ITEM_ID, payload: editState.selectedItemId});

    setOrder(sortColumn);
  };

  useEffect(() => {
    compositeListOrder(order);
  }, [compositesList]);

  const buttonNewComposite = useMemo(
    () => (
      <Button
        automationId="newComposite"
        icon="icn-action16-plusa"
        text="New Composite"
        colorSchema={COLORS.BLUE_500}
        onClick={() => history.push('/metrics-explorer')}
      />
    ),
    [],
  );

  if (isCompositesListLoading || isAlertsDataLoading) {
    return (
      <div className="display_flex flexGrow_1 justifyContent_center alignItems_center">
        <Spinner color="gray.500" size={SIZES.XX_BIG_100} />
      </div>
    );
  }

  if (noData) {
    return (
      <ErrorBoundary>
        <EmptySearch
          error="No Calculated Metrics"
          errorExplanation="To get started, create a new composite in the Metric Explorer"
          link={
            <a
              href="https://support.anodot.com/hc/en-us/articles/360016072559-Calculated-Metrics"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn More
            </a>
          }
          button={buttonNewComposite}
        />
      </ErrorBoundary>
    );
  }

  if (!compositesList.length) {
    return (
      <ErrorBoundary>
        <EmptySearch error="Nothing to Show" errorExplanation="change Filters to See Composites" />
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      <div className="flexGrow_1 mb_2-5">
        <ReactResizeDetector handleHeight handleWidth onResize={recalculateScrollbarWidth} />
        <CompositesTableHeader
          offset={scrollBarWidth}
          onCheck={() => null}
          order={order}
          setCompositeListOrder={compositeListOrder}
        />
        <AutoSizer>
          {({height, width}) => (
            <List
              ref={listRef}
              width={width}
              height={height}
              rowCount={compositesList.length}
              rowHeight={rowHeight}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      </div>
    </ErrorBoundary>
  );
});

CompositesList.propTypes = {
  compositesList: PropTypes.instanceOf(Array).isRequired,
  setCompositeList: PropTypes.func.isRequired,
  noData: PropTypes.bool.isRequired,
};

export default CompositesList;
