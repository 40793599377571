// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import SearchableDdl from 'common/components/SearchableDdl';
import {
  fetchAdobeBookmarks as fetchAdobeBookmarksAction,
  setAdobeStreamUiBookmarkId as setAdobeStreamUiBookmarkIdAction,
} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isStreamEditable} from 'bc/services/dataStreamService';
import {get} from 'lodash';
import './AdobeAnalyticsStreamEditor.module.scss';

type PropTypes = {
  dataStream: Object,

  // connect
  isLoadingBookmarks: boolean,
  bookmarks: Object,
  fetchAdobeBookmarks: Function,
  setAdobeStreamUiBookmarkId: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isLoadingBookmarks: selectors.getAdobeStreamBookmarksIsLoading(state),
    bookmarks: selectors.getAdobeStreamBookmarksItems(state),
  }),
  {
    fetchAdobeBookmarks: fetchAdobeBookmarksAction,
    setAdobeStreamUiBookmarkId: setAdobeStreamUiBookmarkIdAction,
  },
)(
  class Bookmarks extends React.PureComponent {
    props: PropTypes;

    componentDidMount() {
      const {fetchAdobeBookmarks, dataStream: stream} = this.props;
      fetchAdobeBookmarks({dataSourceId: stream.dataSourceId, type: stream.type});
    }

    bookmarkChanged = (item) => {
      const {setAdobeStreamUiBookmarkId} = this.props;
      setAdobeStreamUiBookmarkId(item.id);
    };

    render() {
      const {dataStream, bookmarks, isLoadingBookmarks} = this.props;
      const activeBookmark = bookmarks.find((a) => a.id === get(dataStream, 'uiState.bookmarkId', ''));

      return (
        <div className="shell-col">
          <PanelHeader title="Stream Context" isEditable={isStreamEditable(dataStream.state)} />
          {/* {JSON.stringify(bookmarks)} */}

          <SearchableDdl
            automationId="bookmarks"
            items={bookmarks}
            styleName="report-suite-ddl"
            theme="white"
            value={activeBookmark || {name: ''}}
            isLoading={isLoadingBookmarks}
            title="Choose Bookmark"
            labelKey="name"
            optionHeight={40}
            onChange={this.bookmarkChanged}
          />
        </div>
      );
    }
  },
);
