import React, {useState} from 'react';
import {useField} from 'react-final-form';
import {makeStyles} from '@material-ui/core';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import LabelWithInfo from 'channels/components/fields/LabelWithInfo';
import InputField from 'channels/components/fields/InputField';
import {AUTH_METHODS} from 'channels/constants/channelTypes';

const useStyles = makeStyles(({palette}) => ({
  container: {
    backgroundColor: palette.blue[50],
    minHeight: 48,
    padding: 14,
  },
}));

const Authentication = ({isCreate}: {isCreate: Boolean}) => {
  const classes = useStyles();

  const {
    input: {value: authentication, onChange},
  } = useField('authentication');

  const [selectedAuthMethod, setSelectedAuthMethod] = useState(
    authentication ? Object.values(AUTH_METHODS).find((item) => item.value === authentication) : AUTH_METHODS.NO_AUTH,
  );

  const onAuthMethodChange = (authMethodLabelValue) => {
    setSelectedAuthMethod(authMethodLabelValue);
    onChange(authMethodLabelValue?.value);
  };

  return (
    <div className={classes.container}>
      <div className="display_flex">
        <div className="mr_1 fontWeight_500">Authentication</div>
        <FormDdlSelect
          id="webhookAuthMethod"
          selected={selectedAuthMethod}
          button={
            <BigDropdownButton
              id="webhookAuthMethodSelection"
              isPristine={!selectedAuthMethod}
              placeholder="Select"
              label={selectedAuthMethod?.label}
              blueLean
            />
          }
          options={Object.values(AUTH_METHODS)}
          optionComponent={<OptionComponentSimple />}
          onChange={onAuthMethodChange}
          width={180}
          maxWidth={200}
          buttonWidth={200}
          placement="auto"
          automationId="webhook-auth-configuration"
        />
      </div>
      {selectedAuthMethod.value === AUTH_METHODS.BASIC.value && (
        <>
          <div className="my_2">
            <LabelWithInfo label="User" tooltipContent="User" />
            <InputField fieldFormName="user" placeholder="User" isCreate={isCreate} />
          </div>
          <div className="mb_1">
            <LabelWithInfo label="Password" tooltipContent="Password" />
            <InputField fieldFormName="password" placeholder="Password" isCreate={isCreate} isPassword />
          </div>
        </>
      )}
      {selectedAuthMethod.value === AUTH_METHODS.OKTA.value && (
        <>
          <div className="my_2">
            <LabelWithInfo label="Okta issuer URL" tooltipContent="Okta issuer URL" />
            <InputField fieldFormName="oktaIssuerUrl" placeholder="e.g., https://oktaice.okta.com" />
          </div>
          <div className="mb_2-5">
            <LabelWithInfo
              label="Client ID"
              tooltipContent="Public identifier for the client that is required for all OAuth flows"
            />
            <InputField fieldFormName="oktaClientId" placeholder="e.g., 0oa2hl2inow5Uqc6c357" />
          </div>
          <div className="mb_1">
            <LabelWithInfo
              label="Scope"
              tooltipContent="Scopes represent high-level operations that can be performed on your API endpoints"
            />
            <InputField fieldFormName="oktaScope" placeholder="Scope" isCreate={isCreate} />
          </div>
        </>
      )}
    </div>
  );
};

export default Authentication;
