// @flow
import React, {Fragment, useCallback, useMemo} from 'react';
import {useField} from 'react-final-form';
import {isEqual, omit} from 'lodash';
import {useSelector, useDispatch} from 'react-redux';
import {Box} from '@material-ui/core';
import Button, {WIDTHS, COLORS} from 'common/componentsV2/Button';
import {getSelectedExpressionId, getExpressionTrees} from 'metrics/store/selectors';

import DateRanges from 'metrics/components/metricExplorer/DateRanges';
import MultiPreviewOptions from 'metrics/components/metricExplorer/MultiPreviewOptions';
import Show from 'metrics/components/metricExplorer/components/Show';
import Sort from 'metrics/components/metricExplorer/components/Sort';

import TimeScaleSelectorField from 'metrics/components/metricExplorer/TImeScaleSelectorField';
import ChartRenderSettingsMenu from 'metrics/components/metricExplorer/ChartSettings/ChartRenderSettingsMenu';
import {getEnableRefreshButtonMetricExplorer, getIsImpersonate} from 'profile/store/selectors';
import Tooltip from 'common/componentsV2/Tooltip';
import {refetchExecuteData} from 'metrics/store/actions';
import {clearChartCache} from 'dashboards/store/actions';
import './MetricExplorerChartHeaderTools.module.scss';

type PropTypes = {
  isLoading: boolean,
  totalAmount: number,
  metrics: Array,
  providedResolution: string,
  setCollapsedView: Function,
  isCollapsedView: boolean,
  noSettings: boolean,
  isMultiExpressions: boolean,
  validationId: number,
};

const MetricExplorerChartHeaderTools = ({
  providedResolution,
  isLoading,
  totalAmount,
  metrics,
  isCollapsedView,
  setCollapsedView,
  noSettings,
  isMultiExpressions,
  validationId,
}: PropTypes) => {
  const dispatch = useDispatch();
  const selectedTreeId = useSelector(getSelectedExpressionId);
  const expressionTrees = useSelector(getExpressionTrees);
  const isImpersonate = useSelector(getIsImpersonate);
  const isEnableRefreshButton = useSelector(getEnableRefreshButtonMetricExplorer);

  const {
    input: {value, onChange},
  } = useField('multiPreviewOptions', {isEqual});

  const multiPreviewOptions = useMemo(
    () =>
      expressionTrees.reduce(
        (acc, expressionTree) => ({
          ...acc,
          [expressionTree.id]: value[expressionTree.id] || value['*'],
        }),
        value,
      ),
    [expressionTrees, value],
  );

  const valuesWithoutDefault = Object.values(omit(multiPreviewOptions, '*'));
  const isShowTheSame = valuesWithoutDefault.every((item, index, arr) => item.show === arr[0].show);
  const isSortTheSame = valuesWithoutDefault.every((item, index, arr) => item.sort === arr[0].sort);

  const handleRefreshButton = useCallback(() => {
    dispatch(clearChartCache({}, {chartId: 'expressionTreesPreview'}));
    dispatch(refetchExecuteData());
  }, [dispatch]);

  const headerText = (metricsData, total) => {
    const noData = (!metricsData || metricsData.length === 0) && Math.max(total, metricsData.length) === 0;
    if (!noData) return `Showing ${metricsData?.length || 0} out of ${Math.max(total, metricsData.length)} Metrics`;
    // failure key of too much data
    if (validationId === 53) return 'Too much data';
    return 'Nothing to show';
  };

  return (
    <Fragment>
      <div styleName="container">
        <div styleName="top-container">
          <div styleName="main-title" automation-id="metricExplorerGraphTitle">
            {isLoading ? (
              'Loading...'
            ) : (
              <div className="display_flex alignItems_center">
                {headerText(metrics, totalAmount)}
                {(isImpersonate || isEnableRefreshButton) && (
                  <Tooltip content="Refresh">
                    <i className="icon icn-general16-refresh ml_1" role="button" onClick={handleRefreshButton} />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
          {!noSettings && (
            <div styleName="buttons-container">
              <div styleName="chart-render-button">
                <ChartRenderSettingsMenu />
              </div>
              <Button
                fixedWidth={35}
                width={WIDTHS.NARROW_TIGHT}
                colorSchema={COLORS.GRAY_300}
                icon={isCollapsedView ? 'icn-action16-expand' : 'icn-action16-collapse'}
                onClick={() => setCollapsedView(!isCollapsedView)}
                automationId="metricExplorerSplitGraphButton"
              />
            </div>
          )}
        </div>
      </div>

      <Box mb={0.5} mt={1} flexShrink={0}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Box display="flex" flexDirection="column" mr={3}>
              <div styleName="subtitle">Time Frame</div>
              <DateRanges />
            </Box>
            <Box display="flex" flexDirection="column" flexShrink={0}>
              <div styleName="subtitle">Timescale</div>
              <TimeScaleSelectorField providedResolution={providedResolution} />
            </Box>
          </Box>
          <Box display="flex" position="relative">
            {isMultiExpressions && (
              <Box position="absolute" right={0}>
                <MultiPreviewOptions value={multiPreviewOptions} onChange={onChange} />
              </Box>
            )}
            <Box display="flex" css={isMultiExpressions ? {pointerEvents: 'none'} : {}}>
              <Box display="flex" flexDirection="column" mr={3}>
                <div styleName="subtitle">Show</div>
                {isMultiExpressions ? (
                  <Show placeholder="Multi" value={isShowTheSame ? valuesWithoutDefault[0].show : null} />
                ) : (
                  <Show
                    onChange={(selectedValue) =>
                      onChange({
                        ...multiPreviewOptions,
                        [selectedTreeId]: {...multiPreviewOptions[selectedTreeId], show: selectedValue},
                      })
                    }
                    value={multiPreviewOptions[selectedTreeId].show}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column">
                <div styleName="subtitle">Sort by</div>
                {isMultiExpressions ? (
                  <Sort placeholder="Multi" value={isSortTheSame ? valuesWithoutDefault[0].sort : null} />
                ) : (
                  <Sort
                    onChange={(selectedValue) =>
                      onChange({
                        ...multiPreviewOptions,
                        [selectedTreeId]: {...multiPreviewOptions[selectedTreeId], sort: selectedValue},
                      })
                    }
                    value={multiPreviewOptions[selectedTreeId].sort}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default MetricExplorerChartHeaderTools;
