import React from 'react';
import FakeChevron from '../../../../common/componentsV2/FakeChevron';
import IncidentTabSubtitle from './IncidentTabSubtitle';

type PropTypes = {
  filters: Object,
  subtitle: String,
};

const IncidentTabFakeChevron = ({filters, subtitle}: PropTypes) => {
  return (
    <FakeChevron>
      <IncidentTabSubtitle filters={filters} subtitle={subtitle} counter={0} />
    </FakeChevron>
  );
};

export default IncidentTabFakeChevron;
