import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {groupBy} from 'lodash';
import useHandleResize from 'common/hooks/useHandleResize';
import {getInvModalIncidentFilters} from 'investigationNewAlertConsole/store/selectors';
import {getTimeZoneName, getTriageFullScreenEnabled} from 'profile/store/selectors';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import MetricsTable from 'investigationNewAlertConsole/components/metricsTable/MetricsTable';
import IncidentDetails from 'investigationNewAlertConsole/components/incident/IncidentDetails';
import {
  ALERT_TYPES,
  BUFFER_TIMES,
  STATIC_NO_DATA_COLUMNS,
} from 'investigationNewAlertConsole/services/investigationService';
import IncidentTabFakeChevron from 'investigationNewAlertConsole/components/incident/IncidentTabComponents/IncidentTabFakeChevron';
import IncidentTabSubtitle from 'investigationNewAlertConsole/components/incident/IncidentTabComponents/IncidentTabSubtitle';
import moment from 'moment';

import '../IncidentTab.module.scss';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

const IncidentTab = ({trigger}: {trigger: Object}) => {
  const style = {
    panelRootStyle: {
      flexShrink: 0,
      padding: 0,
      border: 'none',
      margin: '-15px 0 30px 0',
    },
  };
  const timeZoneName = useSelector(getTimeZoneName);
  const filters = useSelector(getInvModalIncidentFilters);
  const isTriageFullscreenEnabled = useSelector(getTriageFullScreenEnabled);

  const [isExpand, setIsExpand] = useState({});
  const [listOrder, setListOrder] = useState({});
  const bodyContainerHeight = useHandleResize([300]);

  const onExpandBoxChange = useCallback(
    (id) => (isExpanded) => {
      setIsExpand((st) => ({...st, [id]: isExpanded}));
    },
    [],
  );

  const staticNoDataMetrics = useMemo(() => {
    const metricsWithDuration = trigger?.metrics.map((metric) => ({
      ...metric,
      duration: trigger?.endTime - metric?.startTime, // endTime of a trigger is either the alert end time or current time if alert not closed yet.
      bufferedStartTime: metric?.intervals[0]?.startTime - (BUFFER_TIMES.alerts[trigger.timeScale] || 0),
      bufferedEndTime: Math.min(
        metric?.intervals[0]?.endTime + (BUFFER_TIMES.alerts[trigger.timeScale] || 0),
        Math.floor(moment.now() / 1000),
      ),
    }));
    return groupBy(metricsWithDuration, 'what');
  }, [trigger]);

  useEffect(() => {
    if (staticNoDataMetrics) {
      // eslint-disable-next-line no-unused-expressions
      Object.keys(staticNoDataMetrics)?.map((what) => setIsExpand((st) => ({...st, [`${what}-alert-metrics`]: true})));
    }
  }, [staticNoDataMetrics]);

  const alertColumns = useMemo(() => {
    return Object.values(STATIC_NO_DATA_COLUMNS);
  }, [trigger]);

  return (
    <div styleName="incident-tab-container">
      {isTriageFullscreenEnabled ? null : (
        <div styleName="incident-tab-header" style={{marginBottom: isTriageFullscreenEnabled ? 0 : 16}}>
          <IncidentDetails trigger={trigger} />
        </div>
      )}

      <div styleName="incident-tab-metrics">
        {/* Metrics */}
        <div styleName="incident-tab-metrics-container" style={{height: bodyContainerHeight}}>
          {/* Static and No Data Alert Metrics */}
          {(trigger.type === ALERT_TYPES.STATIC || trigger.type === ALERT_TYPES.NO_DATA) &&
            Object.keys(staticNoDataMetrics).map((what) => {
              return (
                <div key={what}>
                  <Tooltip content={what} style={TYPES.SMALL}>
                    <div styleName="tooltip-container">
                      <InnerChip title={what} displayOnly type="measure" />
                    </div>
                  </Tooltip>

                  {staticNoDataMetrics[what].length && (
                    <div>
                      <ExpandableBox
                        // key={`inc-gr-panel-${item.what}-alert`}
                        automationId="incWhatGroup"
                        actionElement={<div>actionElement</div>}
                        blockStyle={{transform: 'translateY(20px)'}}
                        nodeLabel={
                          <IncidentTabSubtitle
                            filters={filters}
                            subtitle="Metrics in alert"
                            counter={staticNoDataMetrics[what].length}
                          />
                        }
                        expanded={isExpand[`${what}-alert-metrics`]}
                        onChange={onExpandBoxChange(`${what}-alert-metrics`)}
                        rootStyle={style.panelRootStyle}
                      >
                        <MetricsTable
                          columns={alertColumns}
                          listOrder={listOrder}
                          metrics={staticNoDataMetrics[what]}
                          setListOrder={setListOrder}
                          timeZoneName={timeZoneName}
                          trigger={trigger}
                          isSingleLine={false}
                        />
                      </ExpandableBox>
                    </div>
                  )}
                  {!staticNoDataMetrics[what].length && (
                    <IncidentTabFakeChevron filters={filters} subtitle="Metrics in alert" />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default IncidentTab;
