import React, {useState, useCallback, useEffect} from 'react';
import ButtonListedComponent from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/ButtonListedComponent';
import SimpleMeasureDropdown from 'metrics/components/SimpleMeasureDropdown';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {OPTIONS_VALUE_TYPE, OPTIONS_VALUE_TYPE_VALUES} from 'dashboards/services/dashboardService';

import {getSimpleExpression, parseSimpleExpression, makeFilter, makeMeasureFilterProp} from './utils';
import AddButton from './AddButton';
import DimensionsArray from './DimensionsArray';
import GroupByDimensions from './GroupByDimensions';

type PropTypes = {
  value: Object,
  onChange: Function,
  isRatioEnabled: boolean,
  isGroupByEnabled: boolean,
  isSeriesFunctionEnabled: boolean,
};

const SimpleExpression = ({isRatioEnabled, isGroupByEnabled, value, onChange, isSeriesFunctionEnabled}: PropTypes) => {
  const isStreamRenameEnabled = true;
  const [groupByTemporary, setGroupByTemporary] = useState(false);
  const [firstMeasureDdlOptions, setFirstMeasureDdlOptions] = useState([]);
  const [secondMeasureDdlOptions, setSecondMeasureDdlOptions] = useState([]);
  const [parsedParams, setParsedSimpleExpressionParams] = useState({
    isRatioVisible: false,
    isGroupByVisible: false,
    groupBy: [],
    dimensionsValue: [],
    firstMeasure: {},
    secondMeasure: {},
  });

  useEffect(() => {
    const parsed = parseSimpleExpression(value, [...firstMeasureDdlOptions, ...secondMeasureDdlOptions]);
    setParsedSimpleExpressionParams(parsed);
  }, [firstMeasureDdlOptions, secondMeasureDdlOptions, value]);

  const isGroupByShown = parsedParams.isGroupByVisible || groupByTemporary;

  const update = useCallback(
    (param) => {
      setParsedSimpleExpressionParams((params) => ({...params, ...param}));
      onChange(
        getSimpleExpression({
          isStreamRenameEnabled,
          ...parsedParams,
          ...param,
        }),
      );
    },
    [parsedParams, isStreamRenameEnabled],
  );

  return (
    <React.Fragment>
      <div className="display_flex mb_1-5 alignItems_center">
        <SimpleMeasureDropdown
          onSelect={(val) =>
            update({
              firstMeasure: {...val, aggregation: val.aggregation || OPTIONS_VALUE_TYPE_VALUES.SUM},
              dimensionsValue: [],
              groupBy: [],
            })
          }
          button={<ButtonListedComponent placeholder="Select Measure" text={parsedParams.firstMeasure.measure} />}
          value={parsedParams.firstMeasure}
          secondLevelTitle="Select a Stream"
          propAndValPrefix="simpleExpression"
          buttonWidth={parsedParams.isRatioVisible ? 180 : 233}
          onOptionsChange={setFirstMeasureDdlOptions}
        />
        {parsedParams.isRatioVisible && (
          <React.Fragment>
            <div className="mx_1">&</div>
            <SimpleMeasureDropdown
              onSelect={(val) => update({secondMeasure: val, dimensionsValue: []})}
              button={<ButtonListedComponent placeholder="Select Measure" text={parsedParams.secondMeasure.measure} />}
              value={parsedParams.secondMeasure}
              secondLevelTitle="Select a Stream"
              disabled={!parsedParams.firstMeasure.label}
              propAndValPrefix="simpleExpression"
              buttonWidth={180}
              onOptionsChange={setSecondMeasureDdlOptions}
            />
            <div role="button" className="color_gray-400 ml_1" onClick={() => update({isRatioVisible: false})}>
              <i className="icon icon icn-general16-closea" />
            </div>
          </React.Fragment>
        )}
      </div>
      {parsedParams.firstMeasure.measure && (
        <React.Fragment>
          <div className="display_flex flexWrap_wrap">
            {isGroupByShown && (
              <GroupByDimensions
                firstMeasure={parsedParams.firstMeasure}
                secondMeasure={parsedParams.secondMeasure}
                value={parsedParams.groupBy}
                onChange={(val) => update({groupBy: val})}
                onRemove={() => setGroupByTemporary(false)}
              />
            )}
            {!isGroupByShown && !parsedParams.isRatioVisible && !isGroupByEnabled && (
              <DimensionsArray
                filter={[
                  ...makeMeasureFilterProp(parsedParams.firstMeasure),
                  ...makeFilter([...parsedParams.dimensionsValue], true),
                ]}
                numberOfConstantFilters={makeMeasureFilterProp(parsedParams.firstMeasure).length}
                value={parsedParams.dimensionsValue}
                onChange={(val) => update({dimensionsValue: val})}
              />
            )}
            {!isGroupByShown &&
              !parsedParams.isRatioVisible &&
              parsedParams.dimensionsValue.length === 0 &&
              isGroupByEnabled && (
                <div className="mr_1">
                  <AddButton
                    onClick={() => {
                      update({groupBy: []});
                      setGroupByTemporary(true);
                    }}
                  >
                    Group by
                  </AddButton>
                </div>
              )}
            {!parsedParams.isRatioVisible &&
              !isGroupByShown &&
              parsedParams.dimensionsValue.length === 0 &&
              isRatioEnabled && (
                <AddButton onClick={() => update({isRatioVisible: true, secondMeasure: {}})}>Ratio</AddButton>
              )}
          </div>
          {isSeriesFunctionEnabled && !parsedParams.isRatioVisible && (
            <div className="display_flex alignItems_center mt_1-5">
              <div className="text14med lineHeight_14 mr_0-5">Show:</div>
              <SelectAndt
                automationId="valueTypeStatTile"
                theme={THEME_BLUE_LEAN}
                type={TYPE_NEW_NO_SEARCH}
                options={OPTIONS_VALUE_TYPE}
                optionHeight={40}
                onChange={(val) =>
                  update({
                    firstMeasure: {
                      ...parsedParams.firstMeasure,
                      aggregation: val.value,
                    },
                  })
                }
                value={OPTIONS_VALUE_TYPE.find((option) => option.value === parsedParams.firstMeasure.aggregation)}
                buttonWidth={120}
                menuWidth={120}
                appendToBody={false}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default React.memo(SimpleExpression);
