import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {getUniqueId} from 'common/utils/guid';
import {get} from 'lodash';

const datadogDataStreamReducer = composeReducers((state, {type, payload, meta}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const stream = state.streams.data[getSelectedIndex()];

  // const getItemFromSchema = (itemName) => {
  //   let found = stream.schema.columns.find((item) => item.name === itemName);
  //   if (typeof found === 'undefined') {
  //     found = stream.uiState.unAssignedColumns.find((item) => item.name === itemName);
  //   }
  //   return found;
  // };

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  switch (type) {
    case actions.setDatadogDescribeMetric.TYPE: {
      if (payload && get(meta, 'metricName')) {
        const targetType = payload.type === 'count' ? 'counter' : 'gauge';
        const res = {
          metricName: meta.metricName,
          metrics: [meta.metricName],
          dimensions: [],
          customQuery: false,
          schema: {
            columns: [
              {
                id: getUniqueId(),
                sourceColumn: meta.metricName,
                name: meta.metricName,
                type: 'metric',
                targetType,
              },
            ],
            sourceColumns: [
              {
                id: meta.metricName,
                name: meta.metricName,
              },
            ],
          },
          uiState: {
            ...stream.uiState,
            describeMetric: {
              metricName: meta.metricName,
              ...payload,
            },
          },
          aggregation: targetType === 'counter' ? 'sum' : 'avg',
          rollupAggregation: targetType === 'counter' ? 'sum' : 'avg',
        };

        if (payload.unit) {
          const metricTags = {
            unit: payload.perUnit ? `${payload.unit}/${payload.perUnit}` : payload.unit,
          };
          res.schema.columns[0].metricTags = metricTags;
        }

        const queryPreviewData = get(meta, 'queryPreviewData');
        if (queryPreviewData) {
          res.query = queryPreviewData.query;
          res.customQuery = true;
          res.dimensions = queryPreviewData.columns;
          res.schema = {
            columns: [
              ...res.schema.columns,
              ...queryPreviewData.columns.map((c) => ({
                id: getUniqueId(),
                sourceColumn: c,
                name: c,
                type: 'dimension',
              })),
            ],
            sourceColumns: [
              ...res.schema.sourceColumns,
              ...queryPreviewData.columns.map((c) => ({
                id: c,
                name: c,
              })),
            ],
          };
          res.uiState.queryPreviewData = queryPreviewData;
        }

        // return state;
        return {...redWrapper(res)};
      }
      return state;
    }

    default:
      return state;
  }
});

export default datadogDataStreamReducer;
