import {useQuery} from 'react-query';
import {queryClient} from 'reactQuery/queryClient';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/users/';

const formatUsersData = (users) => {
  return users.map((user) => ({
    abbr: (user.firstName[0] + user.lastName[0]).toUpperCase(),
    allGroups: user.groups,
    defaultGroup: user.defaultGroup,
    email: user.email,
    fullName: `${user.firstName} ${user.lastName}`,
    id: user._id,
    shortName: user.firstName.substring(0, 1).toUpperCase(),
  }));
};

const fetchUsers = async () => {
  const users = await get(root);
  return formatUsersData(users);
};

export default function fetchUsersProvider() {
  const queryKey = [QUERY_KEYS.triggeredAlertsUsers];
  return {
    fetchQuery: () =>
      queryClient.fetchQuery(queryKey, () => fetchUsers(), {
        retry: false,
      }),
    useQuery: () =>
      useQuery(queryKey, () => fetchUsers(), {
        retry: false,
      }),
  };
}
