// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {getIsGAdsHourlyEnabled} from 'profile/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import PollingInterval from 'bc/components/streams/editor/common/PollingInterval';
import SchedularDateRange from 'bc/components/streams/editor/common/SchedularDateRange';
import Checkbox from 'common/componentsV2/Checkbox';
import SmartTooltip from 'common/components/SmartTooltip';
import '../../sql/sqlSchedular/SqlSchedularEditor.module.scss';

const constantVars = {
  dailyDelay_min: 60,
  dailyDelay_default: 60,
  dailyDelay_max: 720,
  hourlyDelay_min: 5,
  hourlyDelay_default: 45,
  hourlyDelay_max: 90,
  dailyRangeDefault: 'd1',
  hourlyRangeDefault: 'w1',
};

type PropTypes = {
  // connect
  dataStream: Object,
  setSelectedStreamKeyVal: Function,
  isGAdsHourlyEnabled: Boolean,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isGAdsHourlyEnabled: getIsGAdsHourlyEnabled(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)(
  class AdobeSchedulerEditor extends React.PureComponent {
    props: PropTypes;

    state = {
      delayMin: constantVars.dailyDelay_min,
      delayMax: constantVars.dailyDelay_max,
    };

    componentDidMount() {
      const {dataStream, isGAdsHourlyEnabled} = this.props;

      if (!isGAdsHourlyEnabled && dataStream.pollingInterval === 'hourly') {
        this.pollingChanged('daily');
      }
    }

    toggleRadio = (checked) => {
      this.props.setSelectedStreamKeyVal({
        cumulative: checked,
      });
    };

    dateRangeChanged = (val) => {
      this.props.setSelectedStreamKeyVal({
        historicalDateRange: val,
      });
    };

    delayMinutesChanged = (val) => {
      const value = parseInt(val, 10) >= 0 ? parseInt(val, 10) : 60;
      this.props.setSelectedStreamKeyVal({
        delayMinutes: value,
      });
    };

    pollingChanged = (val) => {
      let constRangeVal = constantVars.dailyRangeDefault;
      let minVal = constantVars.dailyDelay_min;
      let maxVal = constantVars.dailyDelay_max;
      let delayMinutes = constantVars.dailyDelay_default;

      if (val !== 'daily') {
        constRangeVal = constantVars.hourlyRangeDefault;
        minVal = constantVars.hourlyDelay_min;
        maxVal = constantVars.hourlyDelay_max;
        delayMinutes = constantVars.hourlyDelay_default;
      }

      this.props.setSelectedStreamKeyVal({
        pollingInterval: val,
        historicalDateRange: {
          ...this.props.dataStream.historicalDateRange,
          constRange: constRangeVal,
        },
      });

      this.setState({
        delayMin: minVal,
        delayMax: maxVal,
      });
      this.delayMinutesChanged(delayMinutes);
      this.toggleRadio(false);
    };

    render() {
      const {dataStream, isGAdsHourlyEnabled} = this.props;
      const {delayMin, delayMax} = this.state;

      return (
        <div styleName="root">
          <PanelHeader title="Query Schedule" />

          <div styleName="inner-root">
            {isGAdsHourlyEnabled ? (
              <div styleName="item" automation-id="gadsSchedulerEditorItem">
                <span styleName="item-title" id="editorPannel">
                  Collect Files Every
                </span>
                <span styleName="item-description">Set the collection interval.</span>
                <PollingInterval
                  pollingInterval={dataStream.pollingInterval}
                  allowedValues={['daily', 'hourly']}
                  styleName="item-width"
                  onChange={this.pollingChanged}
                />
              </div>
            ) : null}

            <div styleName="item collect-on">
              <span className="ellipsis" styleName="item-title">
                Delay (Minutes)
              </span>
              <span styleName="item-description">
                To minimize partial results in your reports, set the delay according to the time it takes the data to be
                available
              </span>
              <input
                type="number"
                onChange={(e) => this.delayMinutesChanged(e.target.value)}
                min={delayMin}
                max={delayMax}
                value={dataStream.delayMinutes}
              />
            </div>

            <div styleName="item" automation-id="gadsSchedulerEditorItem">
              <span className="ellipsis" styleName="item-title">
                Collect Since
              </span>
              <span styleName="item-description">Time span of data to query while initializing a stream.</span>
              <div styleName="item-width">
                <SchedularDateRange
                  dateRange={dataStream.historicalDateRange}
                  title="Time span"
                  isUnix
                  allowedRangeTypes={['d1', 'd3', 'w1', 'm1', 'm3']}
                  onChange={this.dateRangeChanged}
                />
              </div>
            </div>
          </div>

          <div styleName="inner-root">
            <div styleName="item">
              <SmartTooltip
                placement="top"
                content="View the accumulation of metric data within the day instead of metric data by hour."
              >
                <div styleName="item" automation-id="gadsSchedulerEditorItemCheckbox">
                  <Checkbox
                    styleName="item-checkbox"
                    text="Daily Accumulation"
                    onChange={this.toggleRadio}
                    isChecked={dataStream.cumulative}
                    isDisabled={!isGAdsHourlyEnabled || dataStream.pollingInterval === 'daily'}
                  />
                </div>
              </SmartTooltip>
            </div>
          </div>
        </div>
      );
    }
  },
);
