// @flow
import React from 'react';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import connect from 'react-redux/es/connect/connect';
import * as selector from 'alerts.management/store/selectors';
import {simpleAlertSetDimensions} from 'alerts.management/store/actions';

type PropTypes = {
  disabled: boolean,

  avalableDimensions: Array,
  simpleAlertSetDimensions: Function,
  selectedDimensions: Object,
  appendToBody: boolean,
};

export default connect(
  (state) => ({
    avalableDimensions: selector.getAvailableDimensions(state),
    selectedDimensions: selector.getSimpleAlertSelectedDimensions(state),
  }),
  {
    simpleAlertSetDimensions,
  },
)(
  class DimensionsDdl extends React.PureComponent {
    props: PropTypes;

    onChange = (val) => {
      this.props.simpleAlertSetDimensions(val);
    };

    render() {
      return (
        <SelectAndt
          appendToBody={this.props.appendToBody}
          automationId="simpleAlertDimensions"
          type={TYPE_NEW_MULTI}
          theme={THEME_BLUE_LEAN}
          onChange={this.onChange}
          options={this.props.avalableDimensions}
          value={this.props.selectedDimensions}
          placeholder="Up to 3 Dimensions"
          optionHeight={40}
          isMulti
          isClearable={false}
          closeMenuOnSelect={false}
          menuWidth={311}
          multiDelimiter=", "
          getOptionLabel={(val) => val}
          getOptionValue={(val) => val}
          disabled={this.props.disabled}
          useTooltip
          multiMaxNumOptions={3}
        />
      );
    }
  },
);
