import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import RadioButton from 'common/componentsV2/RadioButton';
import {useField} from 'react-final-form';
import SwitchButton from 'common/componentsV2/SwitchButton';
import RelativeField from 'common/componentsV2/customDateRangeV2/RelativeField';
import {OPTIONS_VALUE} from 'common/componentsV2/customDateRangeV2/customDateRangeService';
import {getForecastEnabled} from 'profile/store/selectors';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: 20,
    borderBottom: `1px solid ${theme.palette.gray[300]}`,
    minHeight: 195,
    maxHeight: 195,
  },
  option: {
    paddingBottom: '9px',
  },
  optionText: {
    color: theme.palette.gray[400],
  },
  timeFrameOptions: {
    padding: '20px 0px 4px 0px',
    display: 'flex',
  },
  disabled: {
    opacity: 0.2,
  },
}));

const RelativeTimeFrame = () => {
  const classes = useStyles();
  const isForecastEnabled = useSelector(getForecastEnabled);
  const {
    input: {value: rangeTypeValue, onChange: onChangeRangeType},
  } = useField('rangeType');
  const {
    input: {value: relativeLastValue, onChange: onChangeLastValue},
    meta: {invalid: relativeLastInvalid},
  } = useField('relativeLast');
  const {
    input: {value: relativeNextValue, onChange: onChangeNextValue},
    meta: {invalid: relativeNextInvalid},
  } = useField('relativeNext');
  const {
    input: {value: relativeLastScale, onChange: onChangeLastScaleValue},
  } = useField('relativeLastScale');
  const {
    input: {value: relativeNextScale, onChange: onChangeNextScaleValue},
  } = useField('relativeNextScale');

  const isSelected = rangeTypeValue === OPTIONS_VALUE.RELATIVE || rangeTypeValue === OPTIONS_VALUE.RELATIVE_RANGE;
  const isRelativeRange = rangeTypeValue === OPTIONS_VALUE.RELATIVE_RANGE || relativeNextValue > 0;

  const handleTypeChange = useCallback(
    (isChecked) => {
      onChangeRangeType(isChecked ? OPTIONS_VALUE.RELATIVE_RANGE : OPTIONS_VALUE.RELATIVE);
      if (!isChecked) onChangeNextValue(0);
    },
    [onChangeRangeType],
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.option}>
        <RadioButton
          checked={isSelected}
          label="Relative"
          onClick={() => onChangeRangeType(OPTIONS_VALUE.RELATIVE)}
          large
        />
      </div>
      <span className={classes.optionText}>Specify the time range the metric may occur within.</span>
      <div
        className={`${classes.timeFrameOptions} ${!isSelected && classes.disabled}`}
        style={{
          pointerEvents: !isSelected ? 'none' : 'auto',
        }}
      >
        <div style={{marginRight: 20}}>
          <span>Show the last</span>
          <RelativeField
            value={relativeLastValue}
            onChange={onChangeLastValue}
            invalid={relativeLastInvalid}
            scaleValue={relativeLastScale}
            onChangeScaleValue={(val) => {
              onChangeLastScaleValue(val);
              onChangeNextScaleValue(val);
            }}
            disabled={!isSelected}
          />
        </div>
        <div>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <SwitchButton
              isChecked={isRelativeRange}
              automationId="relativeNextSwitch"
              onChange={handleTypeChange}
              disabled={!isForecastEnabled}
            />
            <span style={{marginLeft: 4}}>and the next</span>
          </div>
          <div style={{opacity: isSelected && !isRelativeRange ? 0.4 : 1}}>
            <RelativeField
              value={relativeNextValue}
              onChange={onChangeNextValue}
              invalid={relativeNextInvalid}
              scaleValue={relativeNextScale}
              onChangeScaleValue={(val) => {
                onChangeLastScaleValue(val);
                onChangeNextScaleValue(val);
              }}
              disabled={!isRelativeRange || !isSelected}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelativeTimeFrame;
