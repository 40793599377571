import React from 'react';
import AlertsTriggeredGauge from 'alerts.console/components/AlertsTriggeredGauge';
import AlertsTriggeredGaugeNew from 'alerts.console.new/components/AlertsTriggeredGauge';
import AlertsConfigurationGauge from 'alerts.management/components/AlertsConfigurationGauge';
import DashboardsGauge from 'dashboards/components/DashboardsGauge';
import MetricsGauge from 'metrics/components/MetricsGauge';
import StreamsGauge from 'bc/components/StreamsGauge';
import {getNewAlertConsoleEnabledAndActive} from 'profile/store/selectors';
import {getConfiguredStore} from 'configureStore';

const gaugesToRender = {
  AlertsTriggeredGauge: (props) => {
    const state = getConfiguredStore().getState();
    const isNewAlertConsoleEnabledAndActive = getNewAlertConsoleEnabledAndActive(state);
    if (isNewAlertConsoleEnabledAndActive) {
      return <AlertsTriggeredGaugeNew {...props} />;
    }
    return <AlertsTriggeredGauge {...props} />;
  },
  AlertsConfigurationGauge: (props) => <AlertsConfigurationGauge {...props} />,
  DashboardsGauge: (props) => <DashboardsGauge {...props} />,
  MetricsGauge: (props) => <MetricsGauge {...props} />,
  StreamsGauge: (props) => <StreamsGauge {...props} />,
};
export default gaugesToRender;
