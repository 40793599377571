import React, {memo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {segmentClickEvent} from 'common/store/actions';
import {getTimeZoneName} from 'profile/store/selectors';
import EmptyMessage, {IMAGE_TYPES} from 'common/componentsV2/table/EmptyMessage';
import AlertTriggersTableRow from './AlertTriggersTableRow';
import './AlertTriggersTable.module.scss';

const AlertTriggersTable = ({triggers, totalTriggers, alertId}: PropTypes) => {
  const dispatch = useDispatch();
  const timeZoneName = useSelector(getTimeZoneName);

  const goTo = () => {
    dispatch(segmentClickEvent({category: 'triggers-tab', name: 'show-all-triggers'}));
    window.open(
      `/#!/r/alerts-console?ref=email&alertConfigurationIds=${alertId}&status=&size=100&index=0&startTime=&endTime=&constRange=y1&ack=&subscribers=&channels=`,
    );
  };

  return (
    <div styleName="container">
      <header>
        <div styleName="title">{`Alert Triggers (${totalTriggers} times in the last 30 days)`}</div>
        <div styleName="startTime">Start Time</div>
        <div styleName="updateTime">Update Time</div>
        <div styleName="duration">Duration</div>
        <div styleName="score">Score</div>
        <div styleName="link" />
      </header>
      {triggers?.length === 0 ? (
        <EmptyMessage imageType={IMAGE_TYPES.NO_TRIGGERED} title="No alerts were triggered in the last 30 days" />
      ) : (
        <div>
          {triggers
            ?.slice(0, 5)
            .map((trigger) => (
              <AlertTriggersTableRow trigger={trigger} key={`trigger-${trigger.id}`} timeZoneName={timeZoneName} />
            ))}
        </div>
      )}
      {totalTriggers > 1 ? (
        <a onClick={goTo} styleName="see-all" target="_blank" rel="noopener noreferrer">
          See All
        </a>
      ) : null}
    </div>
  );
};

AlertTriggersTable.propTypes = {
  triggers: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  totalTriggers: PropTypes.any.isRequired,
  alertId: PropTypes.string.isRequired,
};

export default memo(AlertTriggersTable);
