import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {Backdrop, Modal} from '@material-ui/core';
import PropTypes from 'prop-types';
import Button, {COLORS} from 'common/componentsV2/Button';
import Spinner from 'common/componentsV2/Spinner';
import {success} from 'common/utils/notifications/notificationsService';
import useFetchCompositesList from 'composites/api/useFetchComposites';
import useDeleteComposite from 'composites/api/useDeleteComposite';
import styles from './DeleteModal.module.scss';

const DeleteModal = ({backUrl}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {id} = useParams();
  const [selectComposite, setSelectComposite] = useState({});

  const onSuccessCompositeList = useCallback(() => {
    dispatch(success({description: 'Composite deleted successfully'}));
    history.push(backUrl);
  }, []);

  const {refetchCompositeList, compositesList, isCompositesListFetching} = useFetchCompositesList(
    onSuccessCompositeList,
  );

  const {deleteComposite, isDeleteCompositeLoading} = useDeleteComposite();

  useEffect(() => {
    const composite = compositesList.find((c) => c.composite.id === id);
    if (!composite) return;
    setSelectComposite(composite);
  }, [compositesList.length]);

  const onDeleteSuccess = useCallback(() => {
    refetchCompositeList({cancelRefetch: true});
  }, []);

  const onDeleteComposite = useCallback(() => {
    deleteComposite({payload: id, onSuccess: onDeleteSuccess});
  }, []);

  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={() => history.push(backUrl)}>
      <div className={styles.container}>
        <h3>Are you sure you want to delete {selectComposite?.composite?.title}?</h3>
        <div className={styles.mb}>
          <span>Deleting this composite is permanent and cannot be undone.</span>
        </div>

        <span className={styles.bold}>
          Please note: This composite may be in use by existing alerts and/or dashboards. Deleting it will disrupt them.
        </span>
        <div className={styles.footerWrapper}>
          <div className={styles.buttonWrapper}>
            <button
              type="button"
              className={styles.deleteBtn}
              onClick={onDeleteComposite}
              automation-id="deleteCompositeButton"
            >
              {isDeleteCompositeLoading || isCompositesListFetching ? (
                <div className={styles.loaderWrapper}>
                  <Spinner color="#ffffff" size={30} />
                </div>
              ) : (
                'Delete'
              )}
            </button>
          </div>
          <Button text="Cancel" onClick={() => history.push(backUrl)} colorSchema={COLORS.GRAY_200} />
        </div>
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  backUrl: PropTypes.string.isRequired,
};

export default DeleteModal;
