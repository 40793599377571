import './utils.scss';
import {createMuiTheme} from '@material-ui/core/styles';

const whitePalette = {
  azure: {
    500: '#ffffff',
    450: '#ffffff',
    400: '#ffffff',
  },
  indigo: {
    500: '#ffffff',
    400: '#ffffff',
  },
  blue: {
    600: '#ffffff',
    500: '#ffffff',
    300: '#ffffff',
    250: '#ffffff',
    200: '#ffffff',
    100: '#ffffff',
    50: '#ffffff',
    hawkesBlue: '#ffffff',
    transparentBlue: '#ffffff',
  },
  black: {
    700: '#ffffff',
    600: '#fffffff',
    500: '#ffffff',
  },
  white: {
    500: '#ffffff',
  },
  offWhite: {
    100: '#ffffff',
    200: '#ffffff',
  },
  red: {
    10: '#ffffff',
    50: '#ffffff',
    100: '#ffffff',
    200: '#ffffff',
    300: '#ffffff',
    400: '#ffffff',
    450: '#ffffff',
    475: '#ffffff',
    500: '#ffffff',
    600: '#ffffff',
  },
  orange: {
    500: '#ffffff',
    400: '#ffffff',
    350: '#ffffff',
    300: '#ffffff',
    200: '#ffffff',
  },
  yellow: {
    100: '#ffffff',
    200: '#ffffff',
    400: '#ffffff',
    500: '#ffffff',
  },
  green: {
    50: '#ffffff',
    100: '#ffffff',
    400: '#ffffff',
    450: '#ffffff',
    500: '#ffffff',
    600: '#ffffff',
    650: '#ffffff',
    700: '#ffffff',
  },

  cyan: {
    300: '#ffffff',
    100: '#ffffff',
  },

  gray: {
    neutral: '#ffffff',
    600: '#ffffff',
    525: '#ffffff',
    500: '#ffffff',
    475: '#ffffff',
    450: '#ffffff',
    400: '#ffffff',
    350: '#ffffff',
    325: '#ffffff',
    300: '#ffffff',
    250: '#ffffff',
    225: '#ffffff',
    200: '#ffffff',
    150: '#ffffff',
    100: '#ffffff',
    50: '#ffffff',
  },
  rose: {
    500: '#ffffff',
    400: '#ffffff',
  },
  tomato: {
    500: '#ffffff',
    400: '#ffffff',
  },
  lime: {
    500: '#ffffff',
    400: '#ffffff',
  },
  mint: {
    600: '#ffffff',
    590: '#ffffff',
    580: '#ffffff',
    570: '#ffffff',
    560: '#ffffff',
    550: '#ffffff',
    540: '#ffffff',
    530: '#ffffff',
    520: '#ffffff',
    510: '#ffffff',
    500: '#ffffff',
    100: '#ffffff',
  },
  eucaliptus: {
    500: '#ffffff',
    400: '#ffffff',
  },
  teal: {
    600: '#ffffff',
    500: '#ffffff',
    400: '#ffffff',
  },
  lilach: {
    600: '#ffffff',
    500: '#ffffff',
    400: '#ffffff',
  },
  purple: {
    600: '#ffffff',
    500: '#ffffff',
    200: '#ffffff',
  },
  brown: {
    600: '#ffffff',
    500: '#ffffff',
    400: '#ffffff',
  },
  mango: {
    500: '#ffffff',
    400: '#ffffff',
  },
  navyblue: {
    500: '#ffffff',
    400: '#ffffff',
  },
  eggplant: {
    500: '#ffffff',
  },
  brorange: {
    500: '#ffffff',
  },
  pale: {
    500: '#ffffff',
  },
  opaqueColors: {
    rose: '#ffffff',
    clearWhite: '#ffffff',
    whiteO: '#ffffff',
    white5: '#ffffff',
    white10: '#ffffff',
    lighterBlue: '#ffffff',
    lightBlue: '#ffffff',
    deepBlue: '#ffffff',
    indigo: '#ffffff',
    green: '#ffffff',
    red: '#ffffff',
    orange: '#ffffff',
    mango: '#ffffff',
    opRed: '#ffffff',
    opOrange: '#ffffff',
    opYellow: '#ffffff',
    opGreen: '#ffffff',
    transparent: '#ffFFFFFF',
    blue500_01: '#ffFFFFFF',
    blue500_05: '#ffFFFFFF',
  },
  titles: {
    newLook: '#ffffff',
    faster: '#ffffff',
    simpleFilters: '#ffffff',
    betterEditMode: '#ffffff',
    newTitles: '#ffffff',
    instantDashboardTemplates: '#ffffff',
  },
};
const originalPalette = {
  azure: {
    500: '#00b7f1',
    450: '#2EA3DD',
    400: '#0982b6',
  },
  indigo: {
    500: '#17406f',
    400: '#3f51b6',
  },
  blue: {
    600: '#1f2c9c',
    500: '#2671ff',
    300: '#c9dbff',
    250: '#ebf2fc',
    200: '#E9F0FF',
    100: '#e9f0ff',
    50: '#F7FAFF',
    hawkesBlue: '#d7effd',
    transparentBlue: '#ddddff',
  },
  black: {
    700: '#222222',
    600: '#212121',
    500: '#1f292e',
  },
  white: {
    500: '#ffffff',
  },
  offWhite: {
    100: '#ececf8',
    200: '#f9f9fd',
  },
  red: {
    10: '#FAE7EB',
    50: '#ED9FAF',
    100: '#ED9FAF',
    200: '#FACBD5',
    300: '#ED9FAF',
    400: '#DE5773',
    450: '#DF5353',
    475: '#ff3d35',
    500: '#D10F37',
    600: '#aa0000',
  },
  orange: {
    500: '#eb6f07',
    400: '#FF9F30',
    350: '#fcd176',
    300: '#FFD0A5',
    200: '#FFEEDE',
  },
  yellow: {
    100: '#DDDF0D',
    200: '#FFE8B2',
    300: '#FFE400',
    400: '#FFC700',
    500: '#F4B92D',
  },
  green: {
    50: '#dff7e4',
    100: '#c7e8ce',
    400: '#64E17B',
    450: '#94D85F',
    500: '#41ca5a',
    600: '#0fc330',
    650: '#009263',
    700: '#0eab2b',
  },

  cyan: {
    300: '#d2e0fa',
    100: '#d1fbf0',
  },

  gray: {
    neutral: '#666666',
    600: '#27363E',
    525: '#404a52',
    500: '#3D4C59',
    475: '#A2A2A2',
    450: '#979797',
    400: '#8995A0',
    350: '#B5BBC3',
    325: '#c8cdd0',
    300: '#D4D9E0',
    250: '#d8d8d8',
    225: '#dee9fc',
    200: '#E9E9F0',
    150: '#ebf2fc',
    100: '#F4F7FB',
    50: '#f5f5f5',
  },
  rose: {
    500: '#E91E63',
    400: '#F06292',
  },
  tomato: {
    500: '#FF5653',
    400: '#FF7777',
  },
  lime: {
    500: '#7CB342',
    400: '#AFD580',
  },
  mint: {
    600: '#1A896A',
    590: '#279677',
    580: '#34A384',
    570: '#40AF90',
    560: '#4DBC9D',
    550: '#5AC9AA',
    540: '#67D6B7',
    530: '#74E3C4',
    520: '#80EFD0',
    510: '#8DFCDD',
    500: '#1AE395',
    100: '#CAF4E1',
  },
  eucaliptus: {
    500: '#00934E',
    400: '#00875A',
  },
  teal: {
    600: '#11d8f0',
    500: '#00BBD4',
    400: '#80DEEA',
  },
  lilach: {
    600: '#8C0DAF',
    500: '#C83CEE',
    400: '#D083E8',
  },
  purple: {
    600: '#6826AB',
    500: '#8947CD',
    200: '#E1D1F2',
  },
  brown: {
    600: '7B3F00',
    500: '#8D5635',
    400: '#BF8563',
  },
  mango: {
    500: '#FF8F24',
    400: '#ffac3d',
  },
  navyblue: {
    500: '#1F2C9C',
    400: '#224057',
  },
  eggplant: {
    500: '#800661',
  },
  brorange: {
    500: '#B2730B',
  },
  pale: {
    500: '#FFF5DD',
  },
  opaqueColors: {
    rose: '#FFE4EAbf',
    clearWhite: '#fffffff2',
    whiteO: '#ffffffbf',
    white5: '#F7FAFFFF',
    white10: '#f2f2f380',
    lighterBlue: '#2671ff0d',
    lightBlue: '#2671ff40',
    deepBlue: '#00002440',
    indigo: '#17406f4d',
    green: '#30de504d',
    red: '#d10f374d',
    orange: '#f9691f4d',
    mango: '#ffac3d4d',
    opRed: '#E9B1C0',
    opOrange: '#F4D9B6',
    opYellow: '#F4E5A8',
    opGreen: '#C9F0D5',
    transparent: '#00FFFFFF',
    blue500_01: '#2671ff1a',
    blue500_05: '#2671ff80',
  },
  titles: {
    newLook: '#ff1551',
    faster: '#0ad7ce',
    simpleFilters: '#ffba21',
    betterEditMode: '#7f4dff',
    newTitles: '#7cb342',
    instantDashboardTemplates: '#24b9ff',
  },
};

export const palette = originalPalette;

const whiteTypography = {
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  h1: {
    fontSize: '30px',
    letterSpacing: 'normal',
    color: '#ffffff',
  },
  h2: {
    fontWeight: 300,
    fontSize: '22px',
    color: '#ffffff',
  },
  h3: {
    // missing in design system
    fontSize: '20px',
    fontWeight: 500,
    color: '#ffffff',
  },
  h4: {
    // missing in design system
    fontSize: '20px',
    fontWeight: 400,
    color: '#ffffff',
  },
  h5: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#ffffff',
    lineHeight: '16px',
  },
  caption: {
    fontSize: '16px',
    color: '#ffffff',
    lineHeight: '16px',
    letterSpacing: 'normal',
  },
  body1: {
    fontSize: '14px',
    color: '#ffffff',
    lineHeight: '18px',
  },
  subtitle1: {
    // missing in design system
    fontSize: '14px',
    color: '#ffffff',
    lineHeight: '14px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#ffffff',
    lineHeight: '14px',
  },
  subtitle3: {
    fontWeight: 500,
    fontSize: '14px',
    color: '#ffffff',
    lineHeight: '14px',
  },
  subtitle4: {
    fontWeight: 500,
    fontSize: '12px',
    color: '#ffffff',
    lineHeight: '12px',
  },
  hintText: {
    // missing in design system
    fontWeight: 400,
    fontSize: '14px',
    color: '#ffffff',
    lineHeight: '14px',
  },
};
const originalTypography = {
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  h1: {
    fontSize: '30px',
    letterSpacing: 'normal',
    color: palette.black[500],
  },
  h2: {
    fontWeight: 300,
    fontSize: '22px',
    color: palette.gray[500],
  },
  h3: {
    // missing in design system
    fontSize: '20px',
    fontWeight: 500,
    color: palette.gray[600],
  },
  h4: {
    // missing in design system
    fontSize: '20px',
    fontWeight: 400,
    color: palette.gray[600],
  },
  h5: {
    fontWeight: 500,
    fontSize: '16px',
    color: palette.gray[500],
    lineHeight: '16px',
  },
  caption: {
    fontSize: '16px',
    color: palette.gray[500],
    lineHeight: '16px',
    letterSpacing: 'normal',
  },
  body1: {
    fontSize: '14px',
    color: palette.gray[500],
    lineHeight: '18px',
  },
  subtitle1: {
    // missing in design system
    fontSize: '14px',
    color: palette.gray[500],
    lineHeight: '14px',
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '14px',
    color: palette.gray[400],
    lineHeight: '14px',
  },
  subtitle3: {
    fontWeight: 500,
    fontSize: '14px',
    color: palette.gray[500],
    lineHeight: '14px',
  },
  subtitle4: {
    fontWeight: 500,
    fontSize: '12px',
    color: palette.gray[500],
    lineHeight: '12px',
  },
  hintText: {
    // missing in design system
    fontWeight: 400,
    fontSize: '14px',
    color: palette.gray[400],
    lineHeight: '14px',
  },
};

export const typography = originalTypography;

export const themeTypes = {
  white: {palette: whitePalette, typography: whiteTypography},
  original: {palette: originalPalette, typography: originalTypography},
};

export const slider = {
  red: {
    slider: palette.gray[250],
    thumb: palette.tomato[400],
    draggable: palette.white[500],
  },
};

const themeOptions = {
  palette,
  typography,
  slider,
  props: {
    MuiTypography: {
      variantMapping: {
        subtitle1: 'div',
        subtitle2: 'div',
      },
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backdropFilter: 'blur(3px)',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
  },
  zIndex: {
    modal: 1,
  },
};

export function darkThemeToggle(newTheme) {
  const [, theme] = newTheme;
  const {palette: newThemePalette, typography: newThemeTypography} = theme;
  const newPalette = {...originalPalette, ...newThemePalette};
  const newTypography = {...originalTypography, ...newThemeTypography};
  const themeObj = {...themeOptions, palette: newPalette, typography: newTypography};
  return createMuiTheme(themeObj);
}
export default createMuiTheme(themeOptions);
