import {useCallback} from 'react';
import {useQuery, useQueryClient} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/users/';

const fetchUsers = async () => {
  return get(root);
};

export function useFetchUsers() {
  const formatUsersData = useCallback((data) =>
    data.map((i) => ({value: i._id, label: `${i.firstName} ${i.lastName}`, email: i.email})),
  );

  const fallback = [];

  const {data: users = fallback, isFetching: isUserFetching, isLoading: isUsersLoading} = useQuery(
    QUERY_KEYS.users,
    fetchUsers,
    {
      select: formatUsersData,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  return {users, isUserFetching, isUsersLoading};
}

export function usePrefetchUsers() {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery(QUERY_KEYS.users, fetchUsers);
}
