/* eslint-disable */

import ADOBE_png from './ADOBE.png';
import ADTECHSAMPLE_png from './ADTECHSAMPLE.png';
import AMAZON_CLOUDWATCH_jpg from './AMAZON_CLOUDWATCH.jpg';
import AMAZON_REDSHIFT_jpg from './AMAZON_REDSHIFT.jpg';
import ANODOT_RELAY_jpg from './ANODOT_RELAY.jpg';
import APACHE_KAFKA_png from './APACHE_KAFKA.png';
import AVRO_png from './AVRO.png';
import AWS_png from './AWS.png';
import Cacti_png from './Cacti.png';
import ClickHouse_png from './ClickHouse.png';
import CODAHALE_png from './CODAHALE.png';
import COLLECTD_jpg from './COLLECTD.jpg';
import Coralogix_png from './Coralogix.png';
import Coralogix_svg from './Coralogix.svg';
import DATABRICKS_jpg from './DATABRICKS.jpg';
import directory_svg from './directory.svg';
import ECOMSAMPLE_png from './ECOMSAMPLE.png';
import ELASTICSEARCH_png from './ELASTICSEARCH.png';
import FACEBOOK_ADS_jpg from './FACEBOOK_ADS.jpg';
import GOOGLE_ANALYTICS_jpg from './GOOGLE_ANALYTICS.jpg';
import GOOGLE_BIGQUERY_jpg from './GOOGLE_BIGQUERY.jpg';
import GOOGLE_SEARCH_CONSOLE_jpg from './GOOGLE_SEARCH_CONSOLE.jpg';
import GOOGLE_SERVICE_jpg from './GOOGLE_SERVICE.jpg';
import GOOGLE_STORAGE_jpg from './GOOGLE_STORAGE.jpg';
import GOOGLE_SEARCH_jpg from './GOOGLE_SEARCH.jpg';
import INFLUX_DB_png from './INFLUX_DB.png';
import int_databricks_svg from './int_databricks.svg';
import int_gs_svg from './int_gs.svg';
import int_redshift_svg from './int_redshift.svg';
import int_s3_svg from './int_s3.svg';
import KINESIS_png from './KINESIS.png';
import LOCAL_FILE_png from './LOCAL_FILE.png';
import LOOKUP_png from './LOOKUP.png';
import MARIADB_jpg from './MARIADB.jpg';
import MIXPANEL_jpg from './MIXPANEL.jpg';
import MONGO_DB_jpg from './MONGO_DB.jpg';
import MPARTICAL_png from './MPARTICAL.png';
import mParticle_Small_png from './mParticle_Small.png';
import MSSQL_jpg from './MSSQL.jpg';
import MSSQL_png from './MSSQL.png';
import MYSQL_AGENT_jpg from './MYSQL_AGENT.jpg';
import MYSQL_TO_S3_png from './MYSQL_TO_S3.png';
import ORACLE_png from './ORACLE.png';
import PARQUET_jpg from './PARQUET.jpg';
import PARQUET_png from './PARQUET.png';
import PROMETHEUS_jpg from './PROMETHEUS.jpg';
import PSQL_jpg from './PSQL.jpg';
import APACHE_PINOT_svg from './APACHE_PINOT.svg';
import PYTHON_SDK_jpg from './PYTHON_SDK.jpg';
import REST_API_jpg from './REST_API.jpg';
import RIDESAMPLE_png from './RIDESAMPLE.png';
import S3_jpg from './S3.jpg';
import sage_png from './sage.png';
import SALESFORCE_png from './SALESFORCE.png';
import SEGMENT_png from './SEGMENT.png';
import SEGMENT_svg from './SEGMENT.svg';
import SITETRAFFICSAMPLE_png from './SITETRAFFICSAMPLE.png';
import snowflake_png from './snowflake.png';
import splunk_svg from './splunk.svg';
import STATSD_jpg from './STATSD.jpg';
import Teradata_png from './Teradata.png';
import VICTORIA_png from './VICTORIA.png';
import VICTORIA_svg from './VICTORIA.svg';
import Zabbix_png from './Zabbix.png';
import Zabbix_svg from './Zabbix.svg';
import Timestream_png from './Timestream.png';
import Athena_sql_png from './Athena_sql.png';
import EVENTHUBS_png from './EVENTHUBS.png';
import NewRelic_png from './NewRelic.png';
import DATADOG_svg from './DATADOG.svg';
import Mixpanel_svg from './Mixpanel.svg';
import Sumologic_svg from './Sumologic.svg';

export default {
  ADOBE_png,
  ADTECHSAMPLE_png,
  AMAZON_CLOUDWATCH_jpg,
  AMAZON_REDSHIFT_jpg,
  ANODOT_RELAY_jpg,
  APACHE_KAFKA_png,
  APACHE_PINOT_svg,
  AVRO_png,
  AWS_png,
  Cacti_png,
  ClickHouse_png,
  CODAHALE_png,
  COLLECTD_jpg,
  Coralogix_png,
  Coralogix_svg,
  DATABRICKS_jpg,
  directory_svg,
  ECOMSAMPLE_png,
  ELASTICSEARCH_png,
  FACEBOOK_ADS_jpg,
  GOOGLE_ANALYTICS_jpg,
  GOOGLE_BIGQUERY_jpg,
  GOOGLE_SEARCH_CONSOLE_jpg,
  GOOGLE_SERVICE_jpg,
  GOOGLE_STORAGE_jpg,
  GOOGLE_SEARCH_jpg,
  INFLUX_DB_png,
  int_databricks_svg,
  int_gs_svg,
  int_redshift_svg,
  int_s3_svg,
  KINESIS_png,
  LOCAL_FILE_png,
  LOOKUP_png,
  MARIADB_jpg,
  MIXPANEL_jpg,
  MONGO_DB_jpg,
  MPARTICAL_png,
  mParticle_Small_png,
  MSSQL_jpg,
  MSSQL_png,
  MYSQL_AGENT_jpg,
  MYSQL_TO_S3_png,
  ORACLE_png,
  PARQUET_jpg,
  PARQUET_png,
  PROMETHEUS_jpg,
  PSQL_jpg,
  PYTHON_SDK_jpg,
  REST_API_jpg,
  RIDESAMPLE_png,
  S3_jpg,
  sage_png,
  SALESFORCE_png,
  SEGMENT_png,
  SEGMENT_svg,
  SITETRAFFICSAMPLE_png,
  snowflake_png,
  splunk_svg,
  STATSD_jpg,
  Teradata_png,
  VICTORIA_png,
  VICTORIA_svg,
  Zabbix_png,
  Zabbix_svg,
  Timestream_png,
  Athena_sql_png,
  EVENTHUBS_png,
  NewRelic_png,
  DATADOG_svg,
  Mixpanel_svg,
  Sumologic_svg,
};
