// @flow
import React, {useEffect, useState, Fragment} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useQueryParams} from 'use-query-params';
import {getTimeZoneName} from 'profile/store/selectors';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import TinyScrollBox from 'common/componentsV2/boxTools/TinyScrollBox';
import BackButton from 'topologyGeneral/components/common/BackButton';
import IssueItem from 'topologyGeneral/components/sidePanel/issuesSubPanel/IssueItem';
import MetricItem from 'topologyGeneral/components/sidePanel/issuesSubPanel/MetricItem';
import {EntitiesCarousel} from 'topologyGeneral/components/sidePanel/SiteEntitiesCarousel';
import {QUERY_PARAM_MAP, PANEL_SIZES} from 'topologyGeneral/services/sidePanelService';
import {
  getGtpMapIssues,
  getIsFetchingIssues,
  getIsFetchingSingleIssue,
  getGtpMapSelectedIssue,
  getGtpMapSelectedIssueEntities,
} from 'topologyGeneral/store/selectors';
import {setViewsKeyVal, getSingleTopologyIssue} from 'topologyGeneral/store/actions';
import {palette} from 'app/styles/theme';
import './SidePanel.module.scss';

const SelectedIssuePanel = ({isVisible, mapRef}: PropTypes) => {
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);
  const issues = useSelector(getGtpMapIssues);
  const isFetchingIssues = useSelector(getIsFetchingIssues);
  const isFetchingSingleIssues = useSelector(getIsFetchingSingleIssue);
  const selectedIssue = useSelector(getGtpMapSelectedIssue);
  const entities = useSelector(getGtpMapSelectedIssueEntities);
  const timeZoneName = useSelector(getTimeZoneName);
  const [openMetricItems, setOpenMetricItem] = useState({});
  const [selectedMetricItem, setSelectedMetricItem] = useState();
  const [numberOfOpenMetricItems, setNumberOfOpenMetricItems] = useState(0);
  const [filteredIssueMetrics, setFilteredIssueMetrics] = useState(null);

  useEffect(() => {
    if (queryParams.issueId) {
      dispatch(
        getSingleTopologyIssue(
          {
            issueId: queryParams.issueId,
            issuesType: queryParams.issuesType,
          },
          {map: mapRef},
        ),
      );
    }
  }, [queryParams.issueId, issues, isFetchingIssues]);

  useEffect(() => {
    if (selectedIssue && !queryParams.issueId) {
      dispatch(setViewsKeyVal({selectedIssue: null}));
    }
  }, [queryParams.issueId, selectedIssue]);

  useEffect(() => {
    // SITE and INTERFACE don't have representation in the entities map
    // CELLS are subEntities in the entities map
    if (
      selectedIssue &&
      queryParams.selectedEntityId &&
      (!queryParams.selectedSubEntityId || queryParams.selectedSubEntityId === queryParams.selectedEntityId)
    ) {
      setFilteredIssueMetrics(
        selectedIssue.metrics.filter((metric) => {
          return (
            metric.entityMapping.REGION.includes(queryParams.selectedEntityId) ||
            metric.entityMapping.NODE.includes(queryParams.selectedEntityId) ||
            metric.entityMapping.LINK.includes(queryParams.selectedEntityId)
          );
        }),
      );
    } else if (selectedIssue && queryParams.selectedEntityId && queryParams.selectedSubEntityId) {
      setFilteredIssueMetrics(
        selectedIssue.metrics.filter((metric) => {
          return metric.entityMapping.CELL.includes(queryParams.selectedSubEntityId);
        }),
      );
    } else {
      setFilteredIssueMetrics(null);
    }
  }, [queryParams.selectedEntityId, queryParams.selectedSubEntityId, selectedIssue]);

  const onBackClick = () => {
    setSelectedMetricItem(null);
    dispatch(setViewsKeyVal({selectedIssueMetric: null}));
    setQueryParams({
      issueId: undefined,
    });
  };

  const onMetricNameClick = (chartId) => {
    let selectedMetric = null;
    const newState = chartId !== selectedMetricItem ? chartId : null;
    if (newState) {
      selectedMetric = selectedIssue.metrics.find((val) => `${selectedIssue.id}-${val.metricId}` === chartId);
    }
    setSelectedMetricItem(newState);
    dispatch(setViewsKeyVal({selectedIssueMetric: selectedMetric}));
  };

  useEffect(() => {
    if (queryParams.issueId && filteredIssueMetrics && filteredIssueMetrics.length) {
      onMetricNameClick(`${queryParams.issueId}-${filteredIssueMetrics[0].metricId}`);
    }
  }, [filteredIssueMetrics]);

  const onMetricArrowClick = (chartId) => {
    const newState = !openMetricItems[chartId];
    setOpenMetricItem({
      ...openMetricItems,
      [chartId]: newState,
    });
    if (newState) {
      setNumberOfOpenMetricItems((count) => count + 1);
    } else {
      setNumberOfOpenMetricItems((count) => count - 1);
    }
  };

  const onToggleAllGraphsClick = () => {
    if (numberOfOpenMetricItems === selectedIssue.metrics.length) {
      setOpenMetricItem({});
      setNumberOfOpenMetricItems(0);
    } else {
      const newState = {};
      selectedIssue.metrics.forEach((item) => {
        newState[`${selectedIssue.id}-${item.metricId}`] = true;
      });
      setOpenMetricItem(newState);
      setNumberOfOpenMetricItems(selectedIssue.metrics.length);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Box mr={2} ml={2}>
      <Box display="flex" mt={3} mb={1} height={16}>
        <BackButton onClick={onBackClick} />
      </Box>
      {(isFetchingIssues || isFetchingSingleIssues) && (
        <Box
          display="flex"
          flexGrow={1}
          alignItems="center"
          justifyContent="center"
          height="100%"
          width={PANEL_SIZES.innerPanelWidth}
          position="absolute"
          top={0}
          css={{pointerEvents: 'none'}}
        >
          <Spinner color={palette.gray[500]} size={SIZES.XX_BIG_100} />
        </Box>
      )}
      {selectedIssue && (
        <Fragment>
          <IssueItem item={selectedIssue} timeZoneName={timeZoneName} />
          <Box height={150} mt={2}>
            <EntitiesCarousel entities={entities} isAutoSelect={false} />
          </Box>
          <Box display="flex" justifyContent="space-between" mt={1}>
            <Box lineHeight="20px">
              {`Metrics (${filteredIssueMetrics ? filteredIssueMetrics.length : selectedIssue.metrics.length}/${
                selectedIssue.metrics.length
              })`}
            </Box>
            <Box display="flex" alignItems="center" css={{cursor: 'pointer'}} onClick={onToggleAllGraphsClick}>
              {numberOfOpenMetricItems === selectedIssue.metrics.length ? (
                <Fragment>
                  <Box mr={1.5} color="gray.500" className="icon icn-action16-collapse" />
                </Fragment>
              ) : (
                <Fragment>
                  <Box mr={1.5} color="gray.500" className="icon icn-action16-expand" />
                </Fragment>
              )}
            </Box>
          </Box>
          <TinyScrollBox
            position="absolute"
            top={330}
            bottom={0}
            right={3}
            left={0}
            css={{overflowY: 'auto', overflowX: 'hidden'}}
          >
            <Box width={PANEL_SIZES.innerPanelWidth} ml={2}>
              {(filteredIssueMetrics || selectedIssue.metrics).map((metric, index) => (
                <MetricItem
                  metric={metric}
                  key={metric.metricId}
                  onNameClick={onMetricNameClick}
                  onArrowClick={onMetricArrowClick}
                  isSelected={selectedMetricItem === `${selectedIssue.id}-${metric.metricId}`}
                  isOpen={openMetricItems[`${selectedIssue.id}-${metric.metricId}`] || false}
                  chartId={`${selectedIssue.id}-${metric.metricId}`}
                  timeScale={selectedIssue.timeScale}
                  tooltipPlacement={index < 2 ? 'bottom' : 'top'}
                />
              ))}
            </Box>
          </TinyScrollBox>
        </Fragment>
      )}
    </Box>
  );
};

SelectedIssuePanel.propTypes = {
  mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
  isVisible: PropTypes.bool,
};

SelectedIssuePanel.defaultProps = {
  isVisible: true,
};

export default SelectedIssuePanel;
