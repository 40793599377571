// @flow

import React from 'react';
import {connect} from 'react-redux';

import ConfirmationModal from 'common/components/modals/ConfirmationModal';

import SimpleCollectionTitles from 'common/components/simpleCollectionMng/SimpleCollectionTitles';
import SimpleCollectionMng from 'common/components/simpleCollectionMng/SimpleCollectionMng';
import {deleteUserEventsCategory, createUserEventsCategories, fetchUserEventsCategories} from '../store/actions';
import * as selectors from '../store/selectors';

import CreateNewEvent from '../components/createNewEvent/CreateNewEvent';
import UserEventsCategoryRow from '../components/userEventRow/UserEventsRow';

import './userEvents.module.scss';

type PropsType = {
  categories: Array<Object>,
  isLoading: boolean,
  deleteUserEventsCategory: Function,
  createUserEventsCategories: Function,
  fetchUserEventsCategories: Function,
  isCreateLoading: boolean,
  isDeleteLoading: boolean,
};

export default connect(
  (state) => ({
    categories: selectors.getUserEventsCategoriesItems(state),
    isLoading: selectors.getUserEventsCategoriesIsLoading(state),
    isCreateLoading: selectors.getCreateUserEventCategory(state),
    isDeleteLoading: selectors.getDeleteUserEventsCategory(state),
  }),
  {
    deleteUserEventsCategory,
    createUserEventsCategories,
    fetchUserEventsCategories,
  },
)(
  class UserEventsCategoriesMng extends React.PureComponent {
    props: PropsType;

    state = {
      isModalOpen: false,
    };

    componentDidMount() {
      this.props.fetchUserEventsCategories();
    }

    deleteCategoryName = '';

    deleteCategoryId = '';

    closeModal = () => {
      this.setState({isModalOpen: false});
    };

    openModal = () => {
      this.setState({isModalOpen: true});
    };

    onConfirmModal = () => {
      this.props.deleteUserEventsCategory(this.deleteCategoryId, this.deleteCategoryId);
      this.setState({isModalOpen: false});
    };

    deleteRow = (categoryId, categoryName) => {
      this.deleteCategoryId = categoryId;
      this.deleteCategoryName = categoryName;
      this.openModal(categoryId);
    };

    createNewItem = (name, img) => {
      const newCategory = {
        category: {
          name,
          imageUrl: img.length === 0 ? undefined : img,
          owner: 'user',
        },
      };
      this.props.createUserEventsCategories(newCategory);
    };

    render() {
      const {isLoading, categories, isCreateLoading, isDeleteLoading} = this.props;

      return (
        <div styleName="main-container" className="user-categories">
          <div styleName="events-wrapper">
            <CreateNewEvent
              onCreateNewItem={this.createNewItem}
              isLoading={isCreateLoading}
              submitName="Add Category"
            />

            <SimpleCollectionMng
              rowIdentifierName="id"
              name="name"
              items={categories}
              onDelete={this.deleteRow}
              titlesNode={<SimpleCollectionTitles />}
              itemNode={<UserEventsCategoryRow />}
              mainLoading={isDeleteLoading}
              isLoading={isLoading}
            />

            <ConfirmationModal
              onClose={this.closeModal}
              onConfirm={this.onConfirmModal}
              title="Delete event category"
              message={`Are you sure you want to delete ${this.deleteCategoryName}?`}
              isOpen={this.state.isModalOpen}
            />
          </div>
        </div>
      );
    }
  },
);
