// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor, openSchedulerEditor} from 'bc/store/actions';
import {segmentCausingEvent} from 'common/store/actions';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SchedularData from 'bc/components/streams/editor/common/SchedularData';
import SchedulerEditorModal from 'bc/components/streams/editor/scheduler/SchedulerEditorModal';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';

import './SegmentSchedular.module.scss';

type PropTypes = {
  dataStream: Object,
  // eslint-disable-next-line react/no-unused-prop-types
  setSelectedEditor: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  openSchedulerEditor: Function,
  // eslint-disable-next-line react/no-unused-prop-types
  segmentCausingEvent: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedEditor,
    segmentCausingEvent,
    openSchedulerEditor,
  },
)(
  class SegmenthSchedular extends React.PureComponent {
    props: PropTypes;

    state = {
      isModalOpen: false,
    };

    onClose = () => {
      this.setState({isModalOpen: false});
    };

    editClicked = () => {
      this.setState({isModalOpen: true});
    };

    render() {
      const {dataStream} = this.props;
      const isEditable = isStreamRunning(dataStream.state) || isStreamEditable(dataStream.state);

      return (
        <div className="shell-col" automation-id="s3Scheduler" styleName={!isEditable ? 'disabled' : ''}>
          <PanelHeader title="Schedule file collection" onSelect={this.editClicked} isEditable={isEditable} />
          <SchedularData />
          {this.state.isModalOpen ? (
            <SchedulerEditorModal dataStream={dataStream} isModalOpen={this.state.isModalOpen} onClose={this.onClose} />
          ) : null}
        </div>
      );
    }
  },
);
