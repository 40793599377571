import React from 'react';
import {useSelector} from 'react-redux';
import {getInvModalTrigger} from 'investigation/store/selectors';
import {ALERT_TYPES} from '../../services/investigationService';
import IncidentTabAnomalyAlert from './IncidentTabComponents/IncidentTabAnomalyAlert';
import IncidentTabStaticNoDataAlert from './IncidentTabComponents/IncidentTabStaticNoDataAlert';

import './IncidentTab.module.scss';

const IncidentTab = () => {
  const trigger = useSelector(getInvModalTrigger);

  return (
    <>
      {trigger.type === ALERT_TYPES.ANOMALY && <IncidentTabAnomalyAlert />}

      {(trigger.type === ALERT_TYPES.STATIC || trigger.type === ALERT_TYPES.NO_DATA) && (
        <IncidentTabStaticNoDataAlert />
      )}
    </>
  );
};

export default IncidentTab;
