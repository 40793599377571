// @flow
import React from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import {ReactComponent as OpenAlert} from 'app/images/open_alert.svg';
import {getChartsOptions, multiplyX} from './getChartsOption';
import SummaryHeader from './ChartsSummaryHeader';
import Spinner, {SIZES} from '../../common/componentsV2/Spinner';

import './anomalyTile.module.scss';

highchartsMore(Highcharts);

const containerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
  marginBottom: 12,
  backgroundColor: 'white',
  paddingTop: 7,
};

type Proptypes = {
  width: number,
  height: number,
  score: number,
  metricsData: Object,
  timeInterval: Array,
  isLoading: boolean,
};

export const AnomalyChart = ({metricsData, score, isLoading, timeInterval, height = 140, width}: Proptypes) => {
  const {
    baseline,
    dataPoints = [],
    tags = [],
    anomalies,
    otherAnomalyIntervals,
    properties,
    what,
    anomalyId,
  } = metricsData;
  return (
    <div style={{...containerStyles, minHeight: height}} key={anomalyId}>
      {isLoading ? (
        <Spinner color="gray.500" size={SIZES.MEDIUM_50} />
      ) : (
        dataPoints.length > 0 && (
          <>
            <SummaryHeader
              properties={properties}
              tags={tags}
              metricName={what}
              prefix={
                <span>
                  <b>{Math.floor(score * 100)}</b>
                  <OpenAlert style={{margin: 'auto 8px -3px 8px'}} width={16} height={16} />
                </span>
              }
            />
            <div className="anomalyChart">
              <HighchartsReact
                highcharts={Highcharts}
                className="anomalies-chart"
                options={getChartsOptions({
                  areaData: multiplyX(baseline),
                  lineData: multiplyX(dataPoints),
                  anomalies,
                  otherAnomalies: otherAnomalyIntervals,
                  chartClassNames: '',
                  timeInterval,
                  width,
                  height,
                })}
              />
            </div>
          </>
        )
      )}
    </div>
  );
};

export default AnomalyChart;
