// @flow
import React from 'react';
import './EstimationDisplay.module.scss';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {palette} from 'app/styles/theme';

type PropTypes = {
  isLoading: boolean,
  estimationText: String,
  errorMessage: String,
  disabled: boolean,
  tooltip: String,
};

export default class EstimationDisplay extends React.PureComponent {
  props: PropTypes;

  parseEstimationText = (estimationText) => {
    const ret = {
      value: '',
      text: '',
    };

    if (estimationText && estimationText.length > 0) {
      const textArr = estimationText.split(' ');
      // eslint-disable-next-line prefer-destructuring
      ret.value = textArr[0];
      ret.text = `${textArr[1]}/ ${textArr[3]}`;
    }
    return ret;
  };

  render() {
    const {disabled, isLoading, errorMessage, estimationText} = this.props;
    if (errorMessage) {
      return (
        <div styleName="container error">
          <span>{errorMessage}</span>
        </div>
      );
    }
    if (!disabled && !isLoading && !estimationText) {
      return (
        <div styleName="container not-enough-data">
          <p>ֿNot Enough Data to</p>
          <p>Get an Alert Estimation</p>
        </div>
      );
    }
    const parsedEstimationText = this.parseEstimationText(estimationText);
    if (estimationText === 'No alerts') {
      return (
        <Tooltip content={this.props.tooltip} type={TYPES.LARGE}>
          <div styleName={`container centered ${this.props.disabled ? 'disabled' : ''}`.trim()}>
            <span>Less than one Alert</span>
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip content={this.props.tooltip} type={TYPES.LARGE}>
        <div styleName={`container ${this.props.disabled ? 'disabled' : ''}`.trim()}>
          <span>Estimated rate of</span>
          {isLoading && <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />}
          <div styleName="value">
            {parsedEstimationText.value}
            <div styleName="scale-block">{parsedEstimationText.text}</div>
          </div>
        </div>
      </Tooltip>
    );
  }
}
