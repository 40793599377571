// @flow
import React from 'react';
import {connect} from 'react-redux';
import {Modal} from 'react-bootstrap';
import * as selectors from 'bc/store/selectors';
import {setStreamSchemaColumnKeyVal} from 'bc/store/actions';
import AggregationDdl from './AggregationDdl';
import './AggregationModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  col: Object,
  isEditable: boolean,

  schema: Object,
  setStreamSchemaColumnKeyVal: Function,
};

export default connect(
  (state) => ({
    schema: selectors.getSelectedDataStream(state).schema,
  }),
  {
    setStreamSchemaColumnKeyVal,
  },
)(
  class AggregationModal extends React.PureComponent {
    props: PropTypes;

    constructor(props) {
      super(props);
      this.originalAggregation = this.props.schema.columns.find((f) => f.id === this.props.col.id).targetType;
      this.state = {
        aggregation: this.originalAggregation,
        isSame: true,
        shouldApplyAggToAll: false,
      };
    }

    originalAggregation = null;

    handleClose = (isOk) => {
      if (isOk) {
        this.props.setStreamSchemaColumnKeyVal(
          {
            colId: this.props.col.id,
            key: 'targetType',
            value: this.state.aggregation,
          },
          {applyToAllMetrics: this.state.shouldApplyAggToAll},
        );
      }
      this.props.onClose();
    };

    onSelect = (aggregation) => {
      this.setState({
        aggregation,
        isSame: this.originalAggregation === aggregation,
      });
    };

    shouldApplyAggToAllChange = (event) => {
      const {target} = event;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const {name} = target;

      this.setState({
        isSame: false,
        [name]: value,
      });
    };

    render() {
      const {isOpen, onClose, col, isEditable} = this.props;
      const {aggregation} = this.state;

      return (
        <div automation-id="aggregationModal">
          <Modal
            show={isOpen}
            dialogClassName="bc overflow-override"
            onHide={onClose}
            bsSize={col.name.length > 30 ? 'lg' : 'small'}
          >
            <Modal.Header bsClass="bc-modal-header">
              <Modal.Title>
                <span styleName="property" className="ellipsis">
                  {col.name}
                </span>
                <span styleName="action">Aggregation</span>
              </Modal.Title>
              <button
                type="button"
                className="btn btn-flat btn-icon-36 btn-secondary"
                onClick={() => this.handleClose(false)}
              >
                <i className="icon icn-icon-table-delete" />
              </button>
            </Modal.Header>

            <Modal.Body styleName="body">
              <AggregationDdl
                styleName="aggregation-ddl"
                automation-id="aggregationModalDropdown"
                aggregation={aggregation}
                disabled={!isEditable}
                onSelect={this.onSelect}
              />

              <div className="cb2" styleName="apply-to-all">
                <input
                  type="checkbox"
                  automation-id="aggregationModalSpplyAllCheckbox"
                  name="shouldApplyAggToAll"
                  id="shouldApplyAggToAll"
                  checked={this.state.shouldApplyAggToAll}
                  onChange={this.shouldApplyAggToAllChange}
                />
                <label
                  styleName="apply-to-all-label"
                  automation-id="aggregationModalApplyAllLabel"
                  htmlFor="shouldApplyAggToAll"
                >
                  Apply to all measures
                </label>
              </div>
            </Modal.Body>

            <Modal.Footer styleName="footer">
              <button
                type="button"
                className="btn btn-raised btn-outline "
                automation-id="aggregationModalUpdateButton"
                onClick={() => this.handleClose(true)}
                disabled={!isEditable || this.state.isSame}
              >
                UPDATE
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  },
);
