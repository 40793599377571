import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';
import {useDispatch} from 'react-redux';
import {segmentClickEvent} from 'common/store/actions';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: palette.teal['500'],
    display: 'flex',
    padding: '4px 8px',
    borderRadius: 16,
    marginRight: 8,
    marginBottom: 4,
  },
  text: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '0.00714em',
    lineHeight: '14px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  key: {
    color: palette.white['500'],
    maxWidth: 135,
    padding: '2px 4px',
  },
  value: {
    backgroundColor: palette.teal['400'],
    borderRadius: 16,
    color: palette.gray['500'],
    maxWidth: 205,
    padding: '2px 6px',
  },
  closeIcon: {
    position: 'relative',
    top: 0,
    left: 2,
    fontSize: 16,
    opacity: '0.7',
    cursor: 'pointer',
    color: palette.teal['400'],
    '&:hover': {
      opacity: '1',
    },
  },
}));

const FilterChipItem = ({dimension, onSelect}: PropTypes) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const reportToSegment = () => {
    const segmentEventName = `alert-triage-dimension-breakdown-unselected`;
    dispatch(segmentClickEvent({type: 'click', name: segmentEventName}));
  };

  const setFilter = useCallback(() => {
    reportToSegment();
    onSelect(dimension);
  }, [dimension]);

  return (
    <div className={classes.wrapper}>
      <div className={`${classes.text} ${classes.key}`}>{dimension.key}</div>
      <div className={`${classes.text} ${classes.value}`}>{dimension.value}</div>
      <i className={`icon icn-general16-closea ${classes.closeIcon}`} onClick={setFilter} />
    </div>
  );
};

FilterChipItem.propTypes = {
  dimension: PropTypes.objectOf(PropTypes.any).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default memo(FilterChipItem);
