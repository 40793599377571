import {useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {stringifyAndEscapeQS} from 'common/utils/http';
import {get} from 'reactQuery/axiosInstance';
import {alertsConsoleRoot, fetchTriggeredAlertsParams} from './utils';

const fetchTriggeredAlertMetrics = ({alertTriggerId, payload}) => {
  return get(`${alertsConsoleRoot}triggered/${alertTriggerId}/metrics?${stringifyAndEscapeQS(payload)}`);
};

export default function fetchTriggeredAlertMetricsProvider(alertTriggerId, queryParams) {
  const queryClient = useQueryClient();

  const params = fetchTriggeredAlertsParams(queryParams);
  // Removing the breachTimeFrom, since it is calculated using Now time, so it keeps changing and as a result changing the queryKey.
  const {breachTimeFrom, ...queryKeyParams} = params;
  // Removing the constRange, since it is not a parameter of the alerts-console/triggered API.
  const {constRange, ...fetchParams} = params;

  const queryKey = [QUERY_KEYS.triggeredAlertMetrics, alertTriggerId, queryKeyParams];

  return {
    invalidate: (partialKey) => queryClient.invalidateQueries(partialKey ? [partialKey] : queryKey),
    reset: (partialKey) => queryClient.resetQueries(partialKey ? [partialKey] : queryKey),
    fetchQuery: () =>
      queryClient.fetchQuery(queryKey, () => fetchTriggeredAlertMetrics({alertTriggerId, payload: fetchParams}), {
        retry: false,
      }),
    useQuery: () =>
      useQuery(queryKey, () => fetchTriggeredAlertMetrics({alertTriggerId, payload: fetchParams}), {
        retry: false,
      }),
  };
}
