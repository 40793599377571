/* eslint-disable */
import React, {Fragment, memo} from 'react';
import {Box} from '@material-ui/core';
import SmartTooltip from 'common/components/SmartTooltip';
import {palette} from 'app/styles/theme';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tooltipBox: {
    borderRadius: '8px',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: palette.opaqueColors.clearWhite,
  },
  propertyBox: {
    display: 'inline',
    color: palette.green[650],
    marginRight: 6,
    marginBottom: '4px',
    fontWeight: 500,
    padding: '0 4px',
    borderRadius: 3,
    backgroundColor: palette.gray[100],
    cursor: 'default',
    '&:hover': {
      backgroundColor: palette.blue[200],
    },
  },
  clickableLabel: {
    display: 'inline',
    color: palette.gray[400],
    '&:hover': {
      color: palette.gray[400],
    },
    '&:visited': {
      color: palette.gray[400],
    },
  },
  tooltipText: {
    color: palette.gray[500],
    fontWeight: 500,
    fontSize: 14,
  },
  simpleLabel: {},
}));

const styles = {
  tooltipBox: {
    borderRadius: '8px',
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: palette.opaqueColors.clearWhite,
    maxWidth: 250,
  },
  tooltipContent: {
    color: palette.gray['500'],
    fontWeight: '500',
    fontSize: 14,
  },
  value: {
    lineHeight: '16px',
    color: palette.green[650],
    marginRight: 6,
    padding: 4,
    marginBottom: 2,
    fontSize: 14,
    fontWeight: '500',
    borderRadius: 6,
    cursor: 'default',
    backgroundColor: palette.gray[100],
    '&:hover': {
      backgroundColor: palette.blue[100],
    },
  },
  isClickable: {
    textTransform: 'lowercase',
    cursor: 'pointer',
    color: palette.gray[400],
    '&:hover': {
      color: `${palette.blue[100]} !important`,
    },
    '&:visited': {
      color: palette.gray[400],
    },
  },
};

const NameFormatShort = (props: PropTypes) => {
  const classes = useStyles();
  const tooltip = (
    <div>
      {props.measure && (
        <Box display="flex" fontSize={14} fontWeight={400} color={palette.gray[400]} mb={0.5}>
          Measure:&nbsp;
          <Box color={palette.purple[600]} fontWeight={500}>
            {props.measure}
          </Box>
        </Box>
      )}

      {props.dimensions.map((d, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box display="flex" fontSize={14} fontWeight={400} color={palette.gray[400]} key={`${d.key}_${index}`}>
          {d.key}
          :&nbsp;
          <Box color={palette.green[650]} fontWeight={500}>
            {d.value}
          </Box>
        </Box>
      ))}
    </div>
  );

  return (
    <Box
      css={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
      fontSize={props.isSmaller ? 14 : 16}
      display="block"
      flexWrap="wrap"
      role="presentation"
    >
      {props.measure && (
        <SmartTooltip
          placement={props.tooltipPlacement}
          content={tooltip}
          extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
        >
          <Box display="inline" fontWeight="500" color="purple.600" css={{cursor: 'default'}}>
            {props.measure}
          </Box>
        </SmartTooltip>
      )}
      {props.dimensions.length > 0 && (
        <Box
          mr={0.5}
          ml={0.5}
          display="inline"
          color={palette.gray[400]}
          fontWeight="normal"
          css={{pointerEvents: 'none'}}
        >
          for
        </Box>
      )}

      {props.dimensions.map((p, i) => (
        <Box display="inline" key={p.key + i}>
          <Fragment>
            <SmartTooltip
              placement={props.tooltipPlacement}
              extraTtClass={`metric-name-tooltip ${classes.tooltipBox}`}
              content={<Box css={styles.tooltipContent}>{p.key}</Box>}
            >
              <Box display="inline" css={styles.value}>
                {p.value}
              </Box>
            </SmartTooltip>
          </Fragment>
        </Box>
      ))}
    </Box>
  );
};

NameFormatShort.defaultProps = {
  tooltipPlacement: 'top',
  dimensions: [],
  isSmaller: false,
};

NameFormatShort.propTypes = {
  measure: PropTypes.string.isRequired,
  dimensions: PropTypes.arrayOf(PropTypes.object),
  tooltipPlacement: PropTypes.string,
  isSmaller: PropTypes.bool,
};

export default memo(NameFormatShort);
