import React, {useCallback} from 'react';
import './CorrelationDetails.module.scss';
import {useSelector} from 'react-redux';
import {getFormattedHumanDuration, getFormattedMDH, getFormattedTime, getIsBetweenTime} from 'common/utils/dateService';
import {getTimeZoneName} from 'profile/store/selectors';
import {getInvModalTrigger, getInvScore} from '../../store/selectors';
import ImpactChip from '../../../impact/ImpactChip';

const CorrelationDetails = () => {
  const trigger = useSelector(getInvModalTrigger);
  const score = useSelector(getInvScore);
  const timeZoneName = useSelector(getTimeZoneName);

  const isOpen = trigger.status.toLowerCase() === 'open';
  const statusText = isOpen ? 'Open' : 'Closed';
  const durText = getFormattedHumanDuration(trigger.duration, isOpen ? 'absolute' : null).trim();
  const fullDurText = isOpen ? ` for ${durText}` : `, lasted ${durText}`;
  const {startTime} = trigger;
  const isSameDay = getIsBetweenTime(startTime, undefined, 'day', timeZoneName);

  const captaliseFirst = useCallback((str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }, []);

  return (
    <div styleName="container">
      <div styleName="type">{captaliseFirst(trigger.type ? trigger.type : '')}</div>
      <div styleName="score">{`Score: ${Math.round(score * 100)}`}</div>
      <div styleName="status">
        <span styleName="title">Status:</span> <span styleName={isOpen ? 'open' : 'closed'}>{statusText}</span>
        {fullDurText}
      </div>
      <div styleName="started">
        <span styleName="title">Anomaly Started:</span>{' '}
        {isSameDay ? getFormattedTime(startTime, timeZoneName) : getFormattedMDH(startTime, timeZoneName)}
      </div>
      <div styleName="triggered">
        <span styleName="title">Anomaly Triggered:</span>{' '}
        {isSameDay
          ? getFormattedTime(trigger.startTime, timeZoneName)
          : getFormattedMDH(trigger.startTime, timeZoneName)}
      </div>
      {trigger?.impact && <ImpactChip impact={trigger.impact} />}
    </div>
  );
};

export default CorrelationDetails;
