import React, {useEffect, useState} from 'react';
import fetchRecommendationsByAlertIdProvider from 'recommendations/api/fetchRecommendationsByAlertIdProvider';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import AlertRecommendationsMenuButton from 'alerts.management/components/editor/simulationArea/alertRecommendations/AlertRecommendationsMenuButton';
import AlertRecommendationsMenuPopover from 'alerts.management/components/editor/simulationArea/alertRecommendations/AlertRecommendationsMenuPopover';
import ignoreRecommendation from 'recommendations/api/api';
import {queryErrorHandler} from 'reactQuery/queryClient';
import fetchRecommendationsProvider from 'recommendations/api/fetchRecommendationsProvider';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {useDispatch, useSelector} from 'react-redux';
import {getProfileId} from 'profile/store/selectors';
import {StringParam, useQueryParams} from 'use-query-params';
import {
  ALERT_RECOMMENDATION_TYPES,
  RECOMMENDATION_ACTIONS,
  RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM,
  NO_MORE_RECOMMENDATIONS,
} from 'recommendations/services/consts';
import {
  setSelectedAlertMinDeltaAbs,
  setSelectedAlertMinDeltaPercent,
  setSelectedAlertMinDuration,
  setSelectedAlertMinDurationScale,
  setSelectedAlertSignificance,
} from 'alerts.management/store/actions';
import {getTimeAndScaleForDuration, transformNewDuration} from 'alerts.management/services/durationAndScaleService';

import classes from './AlertRecommendationsMenu.module.scss';

const AlertRecommendationsMenu = ({alert}: {alert: String}) => {
  const dispatch = useDispatch();
  const meUserId = useSelector(getProfileId);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const recommendationsByAlertIdProvider = fetchRecommendationsByAlertIdProvider(alert);
  const alertRecommendations = recommendationsByAlertIdProvider.useQuery()?.data;

  const recommendationsProvider = fetchRecommendationsProvider();

  const [queryParams, setQueryParams] = useQueryParams({
    [RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM.RECOMMENDATION_TYPES]: StringParam,
  });

  const [recommendationsToDisplayInMenu, setRecommendationsToDisplayInMenu] = useState([]);

  const onToggleMenu = () => {
    if (recommendationsToDisplayInMenu?.[0] !== NO_MORE_RECOMMENDATIONS) {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const applyAlertRecommendation = (recommendationType) => {
    const initial = queryParams[RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM.RECOMMENDATION_TYPES];
    const queryParamsValue = initial ? `${initial},${recommendationType}` : `${recommendationType}`;
    setQueryParams({[RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM.RECOMMENDATION_TYPES]: queryParamsValue});
  };

  const applyAllAlertRecommendations = () => {
    if (alertRecommendations?.recommendations) {
      const alertTypes = alertRecommendations.recommendations
        ?.filter((rec) => !rec.ignored && !rec.applied)
        .map((rec) => rec.type);
      const queryParamsValue = alertTypes?.join(',');
      if (queryParamsValue) {
        setQueryParams({[RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM.RECOMMENDATION_TYPES]: queryParamsValue});
      }
    }
  };

  const ignoreAlertRecommendation = async (recommendationType) => {
    try {
      recommendationsByAlertIdProvider.syncIgnoreApplyRecommendationByAlertIdInCache(
        recommendationType,
        RECOMMENDATION_ACTIONS.IGNORE,
      );

      const payload = {
        userId: meUserId,
        type: recommendationType ?? null,
        ignore: true,
        recommendationId: alertRecommendations?.id,
      };
      await ignoreRecommendation(payload).promise;
      await recommendationsProvider.invalidate(QUERY_KEYS.recommendations);
    } catch (ex) {
      const error = {
        title: 'Ignore recommendation error',
        description: `Error while ignoring recommendation`,
      };
      queryErrorHandler(error);
      // Need to synchronize the view of recommendations with the server, since cannot be sure whether the request succeeded or failed.
      await recommendationsProvider.invalidate();
    }
  };

  const ignoreAllAlertRecommendations = async () => {
    // The backend API will ignore all recommendations once type is null.
    return ignoreAlertRecommendation();
  };

  useEffect(() => {
    const filteredRecommendations = alertRecommendations?.recommendations?.filter(
      (recommendation) =>
        recommendation.ignored !== true &&
        recommendation.applied !== true &&
        !queryParams[RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM.RECOMMENDATION_TYPES]?.includes(recommendation.type),
    );
    if (filteredRecommendations?.length === 0) {
      setRecommendationsToDisplayInMenu([NO_MORE_RECOMMENDATIONS]);
      setTimeout(() => setRecommendationsToDisplayInMenu(filteredRecommendations), 1500);
    } else {
      setRecommendationsToDisplayInMenu(filteredRecommendations);
    }
  }, [
    JSON.stringify(alertRecommendations?.recommendations),
    queryParams?.[RECOMMENDATION_TYPES_APPLIED_QUERY_PARAM.RECOMMENDATION_TYPES],
  ]);

  useEffect(async () => {
    const recommendationTypes = queryParams?.recommendationTypes?.toString();
    if (recommendationTypes) {
      if (alert?.recommendationId) {
        const recommendations = await recommendationsByAlertIdProvider?.fetchQuery();
        // eslint-disable-next-line no-unused-expressions
        recommendations?.recommendations?.forEach((recommendation) => {
          if (!recommendation?.applied) {
            const recommendationValue = Number.isSafeInteger(recommendation.value)
              ? recommendation.value
              : recommendation.value.toFixed(2);
            if (
              recommendation.type === ALERT_RECOMMENDATION_TYPES.DELTA_PERCENTAGE.name &&
              recommendationTypes.includes(ALERT_RECOMMENDATION_TYPES.DELTA_PERCENTAGE.name)
            ) {
              // dispatch(setSelectedAlertMinDeltaAutoBoolVal(false)); // TODO: Do I need to set Automatic to false before the change of Delta?
              dispatch(setSelectedAlertMinDeltaPercent(recommendationValue));
            }
            if (
              recommendation.type === ALERT_RECOMMENDATION_TYPES.DELTA_ABSOLUTE.name &&
              recommendationTypes.includes(ALERT_RECOMMENDATION_TYPES.DELTA_ABSOLUTE.name)
            ) {
              // dispatch(setSelectedAlertMinDeltaAutoBoolVal(false)); // TODO: Do I need to set Automatic to false before the change of Delta?
              dispatch(setSelectedAlertMinDeltaAbs(recommendationValue));
            }
            if (
              recommendation.type === ALERT_RECOMMENDATION_TYPES.MINIMUM_DURATION.name &&
              recommendationTypes.includes(ALERT_RECOMMENDATION_TYPES.MINIMUM_DURATION.name)
            ) {
              const newScaleAndDuration = getTimeAndScaleForDuration(recommendationValue);
              dispatch(
                setSelectedAlertMinDuration(
                  transformNewDuration(newScaleAndDuration?.duration, newScaleAndDuration?.scale),
                ),
              );
              dispatch(setSelectedAlertMinDurationScale(newScaleAndDuration.scale));
            }
            if (
              recommendation.type === ALERT_RECOMMENDATION_TYPES.MINIMUM_SCORE.name &&
              recommendationTypes.includes(ALERT_RECOMMENDATION_TYPES.MINIMUM_SCORE.name)
            ) {
              dispatch(setSelectedAlertSignificance({minSignificance: recommendationValue / 100}));
            }
          }
        });
      }
    }
  }, [queryParams?.recommendationTypes, alert?.recommendationId]);

  return (
    <>
      {recommendationsToDisplayInMenu?.length ? (
        <FormDdl
          automationId="recommendationsButtonAlertEditor"
          buttonComponent={
            <AlertRecommendationsMenuButton
              alertRecommendations={recommendationsToDisplayInMenu}
              isMenuOpen={isMenuOpen}
            />
          }
          isOpen={isMenuOpen}
          onToggle={onToggleMenu}
          popoverComponent={
            recommendationsToDisplayInMenu?.[0] === NO_MORE_RECOMMENDATIONS ? (
              <div className="p_1-5 flex_center">{NO_MORE_RECOMMENDATIONS}</div>
            ) : (
              <AlertRecommendationsMenuPopover
                alertRecommendations={recommendationsToDisplayInMenu}
                onApplyRecommendation={applyAlertRecommendation}
                onApplyAllRecommendations={applyAllAlertRecommendations}
                onIgnoreRecommendation={ignoreAlertRecommendation}
                onIgnoreAllRecommendations={ignoreAllAlertRecommendations}
              />
            )
          }
          placement="bottom"
          position="right"
          popoverContainerClassName={classes.recommendationsPopover}
        />
      ) : (
        undefined
      )}
    </>
  );
};

export default AlertRecommendationsMenu;
