import React, {Fragment, useMemo} from 'react';
import {format, Humanize} from 'common/utils/utilsService';
import {makeStyles} from '@material-ui/core';
import {
  DECIMAL_COUNT,
  getUnitType,
  OPTIONS_UNIT_TYPE,
  OPTIONS_UNIT_TYPE_VALUES,
} from 'dashboards/services/dashboardService';
import {ReactComponent as IconLoader} from 'dashboards/images/anoloader.svg';
import {STAT_TILES_VALUES} from 'dashboards/components/modals/statTile/StatTileSettings';
import GaugeChart from 'charts/GaugeChart';
import AreaSimpleChart from 'common/componentsV2/AreaSimpleChart';
import {palette} from 'app/styles/theme';
import {COLOR_NAME, COLORS_PICKER} from 'common/componentsV2/ColorPicker';
import PieChartContainer from './PieChartContainer';

type PropsType = {
  id: string,
  title: string,
  tileType: string,
  isLoading: boolean,
  isShowMeasure: boolean,
  isResize: boolean,
  nameMeasure: string,
  currentValue: number,
  isAbbreviate: boolean,
  isDecimal: boolean,
  unitText: string,
  metrics: Array<Object>,
  isShowGraph: boolean,
  inputNoData: string,
  isShowTitle: boolean,
  isShowValueLabels: boolean,
  showLegend: boolean,
  minScale: number,
  maxScale: number,
  thresholds: Array<Object>,
  tileData: Object,
  dashboardId: string,
  isShowTitlePreview: boolean,
  actualTileLayout: Object,
  isFullSize: boolean,
  isOwnerUser: boolean,
  isAnonymous: boolean,
  dashboardData: Object,
  isHideResizeHandler: boolean,
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: ({tileType}) => (['pie', 'donut'].includes(tileType) ? 'none' : '60%'),
    padding: '0 8px 16px',
    flexGrow: ({tileType}) => (['pie', 'donut'].includes(tileType) ? 1 : 2),
  },
  name: {
    maxWidth: '100%',
    padding: '0 8px',
    borderRadius: 4,
    backgroundColor: theme.palette.purple[200],
    lineHeight: '28px',
    color: theme.palette.purple[600],
    flexShrink: 0,
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  chartWrapper: {
    width: '100%',
    height: '50%',
    overflow: 'hidden',
    borderRadius: 6,
  },
  label: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },
  loader: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  unitWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    maxWidth: '100%',
    color: theme.palette.black[500],
  },
  gaugeChartWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0 12px 5px',
  },
}));

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

// eslint-disable-next-line complexity
const TileStatElement = ({
  id,
  isLoading,
  tileType,
  isShowMeasure,
  title,
  isResize,
  nameMeasure,
  currentValue,
  isAbbreviate,
  isDecimal,
  unitText,
  metrics,
  isShowGraph,
  inputNoData,
  isShowTitle,
  isShowTitlePreview,
  minScale,
  maxScale,
  thresholds,
  isShowValueLabels,
  tileData,
  dashboardData,
  dashboardId,
  showLegend,
  actualTileLayout,
  isFullSize,
  isOwnerUser,
  isAnonymous,
  isHideResizeHandler,
}: PropsType) => {
  const classes = useStyles({tileType});
  const isMetricEmpty = metrics.length === 0;
  const dataPoints = useMemo(() => (!isMetricEmpty ? metrics[0].dataPoints.map((item) => item[1]) : []), [metrics]);
  const abbreviateValue = Humanize.humanize(currentValue, DECIMAL_COUNT);
  const formatValue = format(
    isAbbreviate ? abbreviateValue.number : currentValue,
    3,
    ',',
    '.',
    isDecimal ? DECIMAL_COUNT : 0,
  );
  const isEmptyTitle = (title || '').length === 0;
  const autoUnitType = !isMetricEmpty
    ? (metrics[0].properties.filter((item) => item.key === 'unit')[0] || {}).value
    : null;
  const updUnitText = unitText === OPTIONS_UNIT_TYPE_VALUES.AUTO && autoUnitType ? autoUnitType : unitText;

  const label = (
    <div className={classes.label}>
      <div className={`text14reg lineHeight_18 ellipsis ${classes.name}`}>{nameMeasure}</div>
    </div>
  );

  const isRight = (OPTIONS_UNIT_TYPE.find((item) => item.value === unitText) || {}).display === 'right';
  const unitTextRender = <div className="text20med-gray600">{getUnitType({value: updUnitText})}</div>;

  const currentColor = (thresholds.map((t) => t.value).filter((o) => o !== '') || EMPTY_ARRAY).some(
    (v) => v <= currentValue,
  )
    ? (thresholds.find((t) => t.value <= currentValue) || EMPTY_OBJECT).color || COLOR_NAME.BLACK
    : COLOR_NAME.BLACK;

  const unitWrapper = (
    <div
      className={classes.unitWrapper}
      style={{color: palette[COLORS_PICKER[currentColor].color][COLORS_PICKER[currentColor].contrast]}}
    >
      <div className={`${classes.ellipsis} display_flex alignItems_baseline`}>
        {!isRight && unitTextRender}
        <div className="title30 ellipsis">{`${formatValue} ${isAbbreviate ? abbreviateValue.postfix : ''}`}</div>
        {isRight && unitTextRender}
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <IconLoader width="30%" />
      </div>
    );
  }

  if (tileType === STAT_TILES_VALUES.GAUGE) {
    return (
      <div className={classes.gaugeChartWrapper}>
        <GaugeChart
          id={id}
          value={!isMetricEmpty ? currentValue : null}
          min={minScale}
          max={maxScale}
          thresholds={thresholds}
          isShowValueLabels={isShowValueLabels}
          unitText={unitText}
          isDecimal={isDecimal}
          autoUnitType={autoUnitType}
          inputNoData={inputNoData}
          isResize={isResize}
          isMetricEmpty={isMetricEmpty}
        />
      </div>
    );
  }

  if (['pie', 'donut'].includes(tileType)) {
    return (
      <div className={classes.contentWrapper}>
        <PieChartContainer
          isResize={isResize}
          showLegend={showLegend}
          dashboardData={dashboardData}
          dashboardId={dashboardId}
          tileData={tileData}
          nameMeasure={nameMeasure}
          metrics={metrics}
          id={id}
          label={label}
          isEmptyTitle={isEmptyTitle}
          isShowMeasure={isShowMeasure}
          isShowTitlePreview={isShowTitlePreview}
          isMetricEmpty={isMetricEmpty}
          inputNoData={inputNoData}
          tileType={tileType}
          title={title}
          actualTileLayout={actualTileLayout}
          isDecimal={isDecimal}
          isAbbreviate={isAbbreviate}
          isFullSize={isFullSize}
          isOwnerUser={isOwnerUser}
          isAnonymous={isAnonymous}
          isHideResizeHandler={isHideResizeHandler}
        />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        {isMetricEmpty ? (
          <Fragment>
            <div className="title30 color_gray-400 mt_0-5">{inputNoData}</div>
          </Fragment>
        ) : (
          <Fragment>
            {isShowTitle && !isEmptyTitle && <div className="text16reg lineHeight_16 ellipsis maxWidth_1">{title}</div>}
            {unitWrapper}
          </Fragment>
        )}
      </div>
      {!isMetricEmpty && (
        <div className={classes.chartWrapper}>
          <AreaSimpleChart
            id={id}
            isResize={isResize}
            dataPoints={dataPoints}
            isShowGraph={isShowGraph}
            isLoading={isLoading}
            thresholds={thresholds}
            currentValue={currentValue}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(TileStatElement);
