// @flow

/* GABPAC - 04/11/19 - This is a container for Value, child of a Key (a Chip).
 * It does have to option of be "stand-alone" that is not being implemented by this version.
 */

import React from 'react';
import './InnerChip.module.scss';
import ExpressionItem from './ExpressionItem';
import Tooltip, {TYPES} from '../Tooltip';

type PropTypes = {
  expression: ExpressionItem,
  onRemoveClicked: Function,
  hasParent: boolean,
  type: String,
  title: String,
  displayOnly: boolean,
  className?: String,
  lean?: boolean,
};

export default class InnerChip extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    className: '',
    lean: false,
  };

  render() {
    const {expression, className} = this.props;
    if (!this.props.expression) {
      return (
        <div
          className={`chip-container ${className}`}
          styleName={[
            'container',
            this.props.hasParent ? '' : 'stand-alone',
            this.props.type,
            this.props.lean ? 'lean' : '',
            this.props.displayOnly ? 'large' : '',
          ]
            .join(' ')
            .trim()}
        >
          <div className="value-chip" styleName="value">
            {this.props.title}
          </div>
        </div>
      );
    }
    return (
      <div
        className={`chip-container ${className}`}
        styleName={[
          'container',
          this.props.hasParent ? '' : 'stand-alone',
          this.props.type,
          this.props.lean ? 'lean' : '',
          this.props.displayOnly ? 'large' : '',
        ]
          .join(' ')
          .trim()}
      >
        <i styleName="chip-icon" className="icn-general16-mail" />
        <Tooltip
          content={this.props.type === 'legacy' ? 'Legacy Expression, click to remove' : expression.label}
          type={TYPES.LARGE}
        >
          <div className="value-chip" styleName={['value', expression.label.length < 3 ? 'small' : ''].join(' ')}>
            {expression.label}
          </div>
        </Tooltip>
        {this.props.displayOnly || this.props.type === 'legacy' ? null : (
          <div
            styleName={['close-button', expression.label.length < 3 ? 'small' : ''].join(' ')}
            className="icon icn-general16-closeb close-inner-chip"
            onMouseDown={() => this.props.onRemoveClicked(this.props.expression)}
          />
        )}
      </div>
    );
  }
}
