import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {success} from 'common/utils/notifications/notificationsService';
import {getSelectedDataStream} from 'bc/store/selectors';
import * as alertsActions from 'alerts.console/store/actions';
import * as api from '../services/api';
import * as selectors from './selectors';
import * as actions from './actions';
import {ORIGIN_TYPES, IMPACT_SUCCESS_MSG} from '../services/constants';
import {getRoutingLocation} from '../../common/store/selectors';

const displaySnackBar = (type) => {
  const date = new Date();
  return [
    success({
      title: IMPACT_SUCCESS_MSG[type].title,
      description: IMPACT_SUCCESS_MSG[type].description,
      settings: {
        uid: `impact_success_msg${type}${date.getTime()}`,
      },
    }),
  ];
};

const getSingleImpact = makeAsyncEpic(actions.getSingleImpact, api.getSingleImpact);
const getImpactsByOrigin = makeAsyncEpic(actions.getImpactsByOrigin, api.getImpactsByOrigin);
const putImpact = makeAsyncEpic(actions.putImpact, api.putImpact);
const postImpact = makeAsyncEpic(actions.postImpact, api.postImpact);
const deleteImpact = makeAsyncEpic(actions.deleteImpact, api.deleteImpact);
const deleteImpactsByOrigin = makeAsyncEpic(actions.deleteImpactsByOrigin, api.deleteImpactsByOrigin);

const addImpact = (action$) =>
  action$.ofType(actions.getSingleImpact.success.TYPE).switchMap(({payload}) => [actions.addImpact(payload)]);

const addImpacts = (action$) =>
  action$.ofType(actions.getImpactsByOrigin.success.TYPE).switchMap(({payload}) => [actions.addImpacts(payload)]);

const addAlertById = (action$) =>
  action$
    .ofType(alertsActions.fetchTriggeredAlertForSnooze.success.TYPE)
    .switchMap(({payload, meta}) => [actions.addAlertById(payload, meta)]);

const updateImpact = (action$, {getState}) =>
  action$.ofType(actions.updateImpact.TYPE).switchMap(({payload}) => {
    const impacts = selectors.getImpacts(getState());
    const impact = impacts[payload];

    return [
      actions.putImpact([
        {
          id: impact.id,
          factor: impact.factor,
          currency: impact.currency,
          spike: impact.spike,
          drop: impact.drop,
        },
      ]),
    ];
  });

const createImpact = (action$, {getState}) =>
  action$.ofType(actions.createImpact.TYPE).switchMap(({payload}) => {
    const impacts = selectors.getImpacts(getState());
    const impact = impacts[payload];

    return [
      actions.postImpact([
        {
          factor: impact.factor,
          currency: impact.currency,
          spike: impact.spike,
          drop: impact.drop,
          measure: impact.measure,
          originType: impact.originType,
          originId: impact.originId,
        },
      ]),
    ];
  });

const removeImpact = (action$) =>
  action$.ofType(actions.removeImpact.TYPE).switchMap(({payload}) => [actions.deleteImpact([payload])]);

const createSucceeded = (action$) =>
  action$.ofType(actions.postImpact.success.TYPE).switchMap(() => displaySnackBar('create'));

const updateSucceeded = (action$) =>
  action$.ofType(actions.putImpact.success.TYPE).switchMap(() => displaySnackBar('update'));

const removeSucceeded = (action$) =>
  action$.ofType(actions.deleteImpact.success.TYPE).switchMap(() => displaySnackBar('remove'));

const restOperationSucceeded = (action$, {getState}) =>
  action$
    .ofType(actions.postImpact.success.TYPE, actions.putImpact.success.TYPE, actions.deleteImpact.success.TYPE)
    .switchMap(() => {
      const state = getState();
      const selectedDataStreamSource = getSelectedDataStream(state);
      const pathnameArr = getRoutingLocation(state).pathname.split('/');

      if (pathnameArr.includes('bc') || pathnameArr.includes('"data-manager')) {
        return [actions.getImpactsByOrigin({originId: selectedDataStreamSource.id, originType: ORIGIN_TYPES.stream})];
      }
      return [];
    });

export default combineEpics(
  getSingleImpact,
  getImpactsByOrigin,
  addImpact,
  addImpacts,
  addAlertById,
  updateImpact,
  putImpact,
  createImpact,
  postImpact,
  deleteImpact,
  deleteImpactsByOrigin,
  removeImpact,
  createSucceeded,
  updateSucceeded,
  removeSucceeded,
  restOperationSucceeded,
);
