import {get, isEmpty} from 'lodash';
import {cleanupSpecialChars} from 'metrics/services/metricsService';
import {
  getEmptyExpression,
  getSearchObjectOriginExpression,
  getSearchObjectPropertyExpression,
} from 'common/utils/angularServices';
import {
  getEmptyFunction,
  getRatioPairFunction,
  getRootExpressionWithGroupByFunction,
} from 'metrics/services/compositeService';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

const seriesFunction = {
  sum: 'sumSeries',
  average: 'averageSeries',
  groupBy: 'groupBy',
};

export const makeFilter = (properties, removeEmpty) =>
  properties
    .map((prop) => ({
      isExact: !(prop[0] && prop[0].value === '*'),
      key: prop[0] && prop[0].key,
      type: 'property',
      value: prop[0] && prop.map((item) => item.value).join(' OR '),
    }))
    .filter((item) => item.key || !removeEmpty);

export const makeMeasureFilterProp = (measure) => {
  if (measure.streamId) {
    return [
      {
        key: 'what',
        value: measure.measure,
        isExact: true,
        type: 'property',
      },
      {
        key: 'originId',
        type: 'origin',
        originType: 'Stream',
        value: measure.streamId,
        isExact: true,
      },
    ];
  }
  return [
    {
      key: 'what',
      value: measure.measure,
      isExact: true,
      type: 'property',
    },
  ];
};

export const getSimpleExpression = ({
  firstMeasure: measure1,
  secondMeasure: measure2,
  groupBy: groupByDimensions,
  dimensionsValue: filterByDimensions,
  isStreamRenameEnabled,
  isRatioVisible: isRatio,
}) => {
  if (isEmpty(measure1)) {
    return null;
  }

  let root;
  let childRoot1;
  const aggregationValues = {
    sum: 'Sum',
    average: 'Avg',
  };

  let groupByPropsValue = '{"properties":[';
  groupByDimensions.forEach((dimension, index) => {
    const comma = index === groupByDimensions.length - 1 ? '' : ',';
    groupByPropsValue = `${groupByPropsValue}"${cleanupSpecialChars(dimension)}"${comma}`;
  });
  groupByPropsValue += ']}';

  const measure1Measure = cleanupSpecialChars(measure1.measure);
  const measure1StreamName = cleanupSpecialChars(measure1.streamName);
  const measure1Aggregation = cleanupSpecialChars(measure1.aggregation);
  const measure2Measure = cleanupSpecialChars(measure2.measure);
  const measure2StreamName = cleanupSpecialChars(measure2.streamName);
  const measure2Aggregation = cleanupSpecialChars(measure2.aggregation);

  let node1 = getEmptyExpression();
  node1.searchObject.expression.push(getSearchObjectPropertyExpression('what', measure1Measure));

  const expressionProperties = makeFilter(filterByDimensions);

  node1 = {
    ...node1,
    searchObject: {
      ...node1.searchObject,
      expression: [...node1.searchObject.expression, ...expressionProperties],
    },
  };

  if (measure1.streamId !== measure1.measure) {
    node1.searchObject.expression.push(
      getSearchObjectOriginExpression(
        '@Stream',
        isStreamRenameEnabled ? measure1.streamId : measure1StreamName,
        isStreamRenameEnabled,
      ),
    );
  }

  if (groupByDimensions.length) {
    childRoot1 = getRootExpressionWithGroupByFunction(
      aggregationValues[measure1Aggregation || 'sum'],
      groupByPropsValue,
      node1,
    );
  } else {
    childRoot1 = getEmptyFunction();
    if (measure1Aggregation) {
      childRoot1.function = seriesFunction[measure1Aggregation];
    }
    childRoot1.children = [node1];
  }
  root = childRoot1;

  if (isRatio) {
    let childRoot2;
    const node2 = getEmptyExpression();
    node2.searchObject.expression.push(getSearchObjectPropertyExpression('what', measure2Measure));
    if (measure2.streamId !== measure1.measure) {
      node2.searchObject.expression.push(
        getSearchObjectOriginExpression(
          '@Stream',
          isStreamRenameEnabled ? measure2.streamId : measure2StreamName,
          isStreamRenameEnabled,
        ),
      );
    }

    if (groupByDimensions.length) {
      childRoot2 = getRootExpressionWithGroupByFunction(
        aggregationValues[measure2Aggregation || 'sum'],
        groupByPropsValue,
        node2,
      );
      root = getRatioPairFunction([childRoot1, childRoot2]);
    } else {
      childRoot2 = getEmptyFunction();
      if (measure2Aggregation) {
        childRoot2.function = seriesFunction[measure2Aggregation];
      }
      childRoot2.children = [node2];

      root = getEmptyFunction();
      root.function = 'divideSeries';
      root.children = [childRoot1, childRoot2];
    }
  }
  return {root};
};

export const getCommonDimensions = (firstMeasure, secondMeasure, fetchedCache) => {
  const dimensions1 = isEmpty(firstMeasure.dimensions)
    ? get(fetchedCache.find((obj) => obj.index === `dimensions-${firstMeasure.value}`), 'fetchedData', [])
        .slice(1)
        .map((item) => item.value)
    : firstMeasure.dimensions;
  const dimensions2 = isEmpty(secondMeasure.dimensions)
    ? get(fetchedCache.find((obj) => obj.index === `dimensions-${secondMeasure.value}`), 'fetchedData', [])
        .slice(1)
        .map((item) => item.value)
    : secondMeasure.dimensions;
  if (Object.keys(secondMeasure).length === 0) {
    return dimensions1;
  }
  const ret = [];
  dimensions1.forEach((dim1) => {
    dimensions2.forEach((dim2) => {
      if (dim1 === dim2) {
        ret.push(dim1);
      }
    });
  });
  return ret;
};

const getSchemaItem = (what, origin, streamSchemas) => {
  let measure = streamSchemas.find((item) => item.value === what.value);

  if (measure && measure.multi) {
    if (origin && origin.originType.toLowerCase() === 'stream') {
      measure = measure.multi.find(
        (nestedItem) =>
          nestedItem.measure === what.value &&
          (origin.value === cleanupSpecialChars(nestedItem.streamName) || origin.value === nestedItem.streamId),
      );
    } else {
      measure = measure.multi.find((nestedItem) => nestedItem.measure === what.value);
    }
  }

  if (!measure && what && what.value) {
    return {
      value: what.value,
      label: what.value,
      streamName: '',
      streamId: '',
      dimensions: [],
      measure: what.value,
      aggregation: '',
    };
  }

  return measure || {};
};

export const parseSimpleExpression = (value, streamSchemas) => {
  if (!value.root) {
    return {
      isRatioVisible: false,
      isGroupByVisible: false,
      groupBy: [],
      dimensionsValue: [],
      firstMeasure: {},
      secondMeasure: {},
    };
  }
  const isRatioVisible = value.root.function === 'divideSeries';
  const isGroupByVisible = value.root.function === 'groupBy';

  const groupByParameters =
    isGroupByVisible && get(value, 'root.parameters', []).find((item) => item.name === 'Group By');

  const groupBy = groupByParameters ? JSON.parse(groupByParameters.value).properties : EMPTY_ARRAY;

  let firstExpression;

  if (value.root.function === 'divideSeries') {
    firstExpression = get(value, 'root.children[0].children[0].searchObject.expression', EMPTY_ARRAY);
  } else if (get(value, 'root.type') === 'metric') {
    firstExpression = get(value, 'root.searchObject.expression', EMPTY_ARRAY);
  } else {
    firstExpression = get(value, 'root.children[0].searchObject.expression', EMPTY_ARRAY);
  }

  const firstWhat = firstExpression.find((item) => item.key === 'what') || EMPTY_OBJECT;
  const firstOrigin = firstExpression.find((item) => item.type === 'origin') || null;

  const secondWhat =
    get(value, 'root.children[1].children[0].searchObject.expression', []).find((item) => item.key === 'what') ||
    EMPTY_OBJECT;
  const secondOrigin =
    get(value, 'root.children[1].children[0].searchObject.expression', []).find((item) => item.type === 'origin') ||
    null;

  const dimensionsValue = (firstExpression || EMPTY_ARRAY)
    .filter((item) => item.key !== 'what' && item.type === 'property')
    .map((item) =>
      item.value ? item.value.split(' OR ').map((val) => ({value: val, label: val, key: item.key})) : [],
    );
  let aggregationValue = [seriesFunction.sum, seriesFunction.average].includes(value.root.function)
    ? Object.keys(seriesFunction).find((key) => seriesFunction[key] === value.root.function)
    : '';

  if (value.root.function === seriesFunction.groupBy) {
    aggregationValue = value.root.parameters[0].value.replace('Avg', 'average').replace('Sum', 'sum');
  }

  const selectedFirstMeasure = getSchemaItem(firstWhat, firstOrigin, streamSchemas);

  const firstMeasure = !isEmpty(selectedFirstMeasure)
    ? {
        ...getSchemaItem(firstWhat, firstOrigin, streamSchemas),
        aggregation: aggregationValue,
      }
    : EMPTY_OBJECT;

  const secondMeasure = getSchemaItem(secondWhat, secondOrigin, streamSchemas);

  return {
    isRatioVisible,
    isGroupByVisible,
    groupBy,
    dimensionsValue,
    firstMeasure,
    secondMeasure,
  };
};
