// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedStreamKeyVal} from 'bc/store/actions';
import {getAllowedRangeTypesByPollingInterval, getNotificationDelayOption} from 'bc/services/dataStreamService';
import Button, {COLORS} from 'common/componentsV2/Button';
import NotificationDelay from 'bc/components/streams/editor/scheduler/NotificationDelay';
import DelayHours from 'bc/components/streams/editor/scheduler/DelayHours';
import DateRange from 'bc/components/streams/editor/scheduler/DateRange';
import {rangeTypes} from 'common/utils/dateRangeService';

import './GoogleSearchSchedularModal.module.scss';

const mixpanelDateRangeLimitation = [rangeTypes.d1.key, rangeTypes.d3.key, rangeTypes.w1.key, rangeTypes.m1.key];

type PropTypes = {
  setSelectedStreamKeyVal: Function,

  dataStream: Object,
  onClose: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamKeyVal,
  },
)(
  class GoogleSearchSchedularModal extends React.PureComponent {
    props: PropTypes;

    state = {
      pollingInterval: this.props.dataStream.pollingInterval,
      delayMinutes: this.props.dataStream.delayMinutes,
      historicalDateRange: this.props.dataStream.historicalDateRange,
      notificationDelay: this.props.dataStream.notificationDelay,
    };

    onCloseButtonClicked = () => {
      this.props.onClose();
    };

    doNothing = () => {};

    delayMinutesChanged = (val) => {
      this.setState({delayMinutes: val});
    };

    historicalDateRangeChanged = (val) => {
      this.setState({historicalDateRange: val});
    };

    notificationDelayChanged = (val) => {
      this.setState({notificationDelay: val});
    };

    onSave = () => {
      this.props.setSelectedStreamKeyVal({
        historicalDateRange: this.state.historicalDateRange,
        delayMinutes: this.state.delayMinutes,
        notificationDelay: this.state.notificationDelay,
      });
      this.onCloseButtonClicked();
    };

    limitDateRange = (dateRange) => {
      if (!dateRange || !dateRange.length) {
        return dateRange;
      }
      return dateRange.filter((i) => mixpanelDateRangeLimitation.includes(i));
    };

    render() {
      return (
        <div styleName="modal-body">
          <div styleName="itemContainer">
            <DateRange
              allowedRangeTypes={getAllowedRangeTypesByPollingInterval(this.props.dataStream.pollingInterval)}
              dateRange={this.state.historicalDateRange}
              onChange={this.historicalDateRangeChanged}
              isUnix
              title="Collect data from"
            />
          </div>

          <div styleName="itemContainer">
            <DelayHours
              delayMinutes={this.state.delayMinutes}
              onChange={this.delayMinutesChanged}
              maxVal={4320}
              minVal={1920}
            />
          </div>

          <div styleName="itemContainer">
            <NotificationDelay
              notificationDelayOption={getNotificationDelayOption()}
              pollingInterval={this.state.pollingInterval}
              notificationDelay={this.state.notificationDelay}
              onChange={this.notificationDelayChanged}
            />
          </div>

          <div styleName="footer">
            <div styleName="left-button">
              <Button
                colorSchema={COLORS.GRAY_400}
                text="Cancel"
                automation-id="schedulerEditorModalCancel"
                onClick={this.onCloseButtonClicked}
              />
            </div>
            <div>
              <Button
                colorSchema={COLORS.BLUE_500}
                text="Save"
                automation-id="schedulerEditorModalSave"
                onClick={this.onSave}
              />
            </div>
          </div>
        </div>
      );
    }
  },
);
