// @flow
/* eslint-disable */
import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import {useField} from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';
import {resolutionTypes} from 'metrics/services/metricsService';
import {Box} from '@material-ui/core';
import {TypographyBox} from 'common/componentsV2/boxTools';
import * as commonSelectors from 'profile/store/selectors';
import {getEnableMultipleEvents} from 'profile/store/selectors';
import * as metricsSelectors from 'metrics/store/selectors';
import MetricEventsChart from 'charts/timeSeries/containers/MetricEventsChart';
import MetricName from 'metrics/components/metricName/MetricName';
import {generateChartSeriesMetricModel, processSeriesData} from 'charts/timeSeries/services/timeSeriesDataService';
import * as amSelectors from 'alerts.management/store/selectors';
import {
  getDeltaCondition,
  getSignificanceCondition,
  getSimulationFiltersMinDurationUI,
} from 'alerts.management/store/selectors';
import {
  getEvents,
  getEventsResolution,
  getMultipleEvents,
  getMultipleEventsFilters,
  getShouldShowEvents,
} from 'userEvents/store/selectors';
import {getMoreTopEvents} from 'userEvents/store/actions';
import {getThresholdPlotLineConfig} from 'charts/timeSeries/services/timeSeriesHchartSettingsService';
import {palette} from 'app/styles/theme';
import Spinner, {SIZES as SPINNER_SIZES} from 'common/componentsV2/Spinner';
import {getEventsFilteredByMatchingProperties} from 'charts/timeSeries/services/timeSeriesEventService';

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: true,
};

type PropTypes = {
  metric: Object,
  anomalyId: string,
  alertId: string,
  timeScale: string,
  index: number,
  isSimulation: boolean,
  mouseMoveHandler: Function,
  onEndLoad: Function,
  isZoomLoading: boolean,
  isCollapsed: boolean,
};

const MetricSimulation = ({
  metric,
  timeScale,
  anomalyId,
  alertId,
  isSimulation,
  index,
  mouseMoveHandler,
  onEndLoad,
  isZoomLoading,
  isCollapsed,
}: PropTypes) => {
  const dispatch = useDispatch();
  let id = isSimulation ? `simulation-chart-${metric.id}-${index}` : `expression-builder-${metric.id}-${index}`;
  if (isCollapsed) {
    id = 'charts-collapsed';
  }
  const estimationArr = (metric.estimation || '').split('/');

  const bucketStartTimeEnabled = useSelector(commonSelectors.getBucketStartTimeEnabled);
  const timeZoneName = useSelector(commonSelectors.getTimeZoneName);

  const getAlertSimulation = useSelector(amSelectors.getAlertSimulation);
  const getSelectedAlertSimulationFilters = useSelector(amSelectors.getSelectedAlertSimulationFilters);
  const getPreviewOptions = useSelector(metricsSelectors.getPreviewOptions);

  const significance = useSelector(getSignificanceCondition);
  const delta = useSelector(getDeltaCondition);
  const duration = useSelector(getSimulationFiltersMinDurationUI);

  const isMultipleEventsEnabled = useSelector(getEnableMultipleEvents);
  const userEventsPoints = isMultipleEventsEnabled ? useSelector(getMultipleEvents) : useSelector(getEvents);
  const multipleEventsFilters = isMultipleEventsEnabled ? useSelector(getMultipleEventsFilters) : undefined;
  const shouldShowEvents = useSelector(getShouldShowEvents);
  const eventsResolution = useSelector(getEventsResolution);

  const alertSimulationEvents = useMemo(() => {
    if (isMultipleEventsEnabled && userEventsPoints?.length && multipleEventsFilters?.length > 0) {
      return getEventsFilteredByMatchingProperties(userEventsPoints, multipleEventsFilters, metric?.properties);
    }
    return userEventsPoints;
  }, [userEventsPoints, multipleEventsFilters, metric?.properties]);

  const tooltipConditions = useMemo(
    () => ({
      significance,
      delta,
      duration,
    }),
    [significance, delta, duration],
  );

  const [processedMetrics, setProcessedMetrics] = useState(null);

  useEffect(() => {
    if (isSimulation) {
      const metricData = getAlertSimulation.data.metrics.find((m) => m.id === metric.id);

      const metricsModel = generateChartSeriesMetricModel(metricData);

      const anomalies = metricData.anomalies
        ? metricData.anomalies.map((anomaly) => ({
            ...anomaly,
            status: 'CLOSE',
            startTime: anomaly.fromDate,
            endTime: anomaly.toDate,
            direction: anomaly.direction ? anomaly.direction.toUpperCase() : '',
          }))
        : [];

      const processedSeriesData = processSeriesData(
        metricData.dataPoints,
        metricData.baseline,
        anomalies.filter((anomaly) => anomaly.meetsCondition),
        anomalies.filter((anomaly) => !anomaly.meetsCondition),
      );

      const threshold = getSelectedAlertSimulationFilters.conditions.find(
        (condition) => condition.type === 'THRESHOLD_CONDITION',
      );
      const directionCondition = getSelectedAlertSimulationFilters.conditions.find(
        (condition) => condition.type === 'DIRECTION_CONDITION',
      );

      const yPlotLines = [];
      if (threshold) {
        const isMinValueActive =
          getSelectedAlertSimulationFilters.type.value === 'static' ||
          ['down', 'both'].includes(directionCondition.direction);
        const isMaxValueActive =
          getSelectedAlertSimulationFilters.type.value === 'static' ||
          ['up', 'both'].includes(directionCondition.direction);
        const metricThreshold = {};
        if (!!threshold.minValue && isMinValueActive) {
          metricThreshold[threshold.lowerBound] = threshold.minValue;
        }
        if (!!threshold.maxValue && isMaxValueActive) {
          metricThreshold[threshold.upperBound] = threshold.maxValue;
        }
        Object.keys(metricThreshold).forEach((key, ind) => {
          const thresholdConfig = getThresholdPlotLineConfig();
          thresholdConfig.id = `threshold_${ind}`;
          thresholdConfig.value = metricThreshold[key];
          thresholdConfig.label.text = key;
          yPlotLines.push(thresholdConfig);
        });
      }

      setProcessedMetrics({
        series: [
          {
            model: metricsModel,
            data: processedSeriesData,
          },
        ],
        yPlotLines: yPlotLines,
      });
    } else {
      const metricsModel = generateChartSeriesMetricModel(metric, undefined, index || 0);
      const processedSeriesData = processSeriesData(metric.dataPoints, metric.baseline, false, null);

      setProcessedMetrics({
        series: [
          {
            model: metricsModel,
            data: processedSeriesData,
          },
        ],
      });
    }
  }, [metric]);

  if (index > 0 && isCollapsed) {
    return null;
  }

  const {
    input: {onChange: onChangeDateRange},
  } = useField('dateRange');

  const onRangeSelection = useCallback((data) =>
    onChangeDateRange({
      constRange: 'c',
      startDate: data.startDate * 1000,
      endDate: data.endDate * 1000,
    }),
  );

  const onEventSelect = (ev) => {
    const selEvent = alertSimulationEvents?.find((item) => item.id === ev.id);
    if (selEvent && selEvent.totalEvents > selEvent.topEvents.length) {
      dispatch(getMoreTopEvents(ev.id));
    }
  };

  return (
    <React.Fragment>
      {!isCollapsed && (
        <Box mt={3} mb={1} display="flex" justifyContent="space-between">
          {metric && <MetricName metric={metric} highlightText={null} index={index} />}
          <Box flexShrink={0} display="flex" padding={0.5}>
            {estimationArr[0] === 'No alerts' ? (
              <TypographyBox variant="caption">Less than one Alert</TypographyBox>
            ) : (
              <TypographyBox
                variant={estimationArr[1] ? 'h5' : 'caption'}
                mr={estimationArr[1] ? '5px' : 0}
                css={{textTransform: 'capitalize'}}
              >
                {estimationArr[0] || (isSimulation && 'Not enough data')}
              </TypographyBox>
            )}
            {estimationArr[1] && (
              <TypographyBox variant="caption">
                {'/ '}
                {estimationArr[1]}
              </TypographyBox>
            )}
          </Box>
        </Box>
      )}
      <Box
        position="relative"
        height={250}
        bgcolor="white.500"
        id="chartWrapper"
        // Commented the stopPropagation as it seams that it does not needed anymore in React 17
        // for more info look at https://reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation
        // Keeping this causing chart zoom to not work as a result of not getting the mouse event.
        // onMouseDownCapture={(event) => event.stopPropagation()}
      >
        {isZoomLoading && (
          <Box
            width={1}
            height={1}
            position="absolute"
            bgcolor="white.500"
            css={{opacity: 0.5, zIndex: 1}}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner color={palette.gray[500]} size={SPINNER_SIZES.MEDIUM_50} />
          </Box>
        )}
        <MetricEventsChart
          onMouseMove={mouseMoveHandler}
          onMouseLeave={mouseMoveHandler}
          disableActions
          id={id}
          eventsMeta={{
            key: `${anomalyId}_${alertId}_${isCollapsed ? 'collapsedMetrics' : metric.id}`,
            chartId: id,
            isSimulation: isSimulation,
          }}
          events={alertSimulationEvents}
          shouldShowEvents={shouldShowEvents}
          eventsResolution={eventsResolution}
          onEventSelect={onEventSelect}
          index={index}
          tooltip={TOOLTIP_OPTIONS}
          bucketStartTimeEnabled={bucketStartTimeEnabled}
          timeScale={timeScale ? resolutionTypes[timeScale].value2 : ''}
          timeZoneName={timeZoneName}
          theme={isSimulation ? 'simulation' : 'preview'}
          enableClientZoom={isSimulation}
          tooltipConditions={tooltipConditions}
          onRangeSelection={onRangeSelection}
          fromDate={getPreviewOptions.dateRange ? Math.ceil(getPreviewOptions.dateRange.startDate / 1000) : null}
          toDate={getPreviewOptions.dateRange ? Math.ceil(getPreviewOptions.dateRange.endDate / 1000) : null}
          processedMetrics={processedMetrics}
          onEndLoad={onEndLoad}
        />
      </Box>
    </React.Fragment>
  );
};

export default React.memo(MetricSimulation);
