import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core';

import {getFormattedDateYMD} from 'common/utils/dateService';
import usePreviewClasses from 'dataCollectors/styles/classes.usePreviewClasses';
import Table1 from 'dataCollectors/components/tables/Table1';
import useFetchEventStreamLog from 'dataCollectors/api/useFetchEventStreamLog';
import useFetchEventStreamLastRun from 'dataCollectors/api/useFetchEventStreamLastRun';
import {useFormContext} from 'react-hook-form';
import ContentLoaderPreview from 'dataCollectors/components/loaders/ContentLoaderPreview';
import ContentLoaderPreviewBig from 'dataCollectors/components/loaders/ContentLoaderPreviewBig';
import {EVENT_STREAM_TYPES} from 'dataCollectors/services/eventStreamService';

const firstRowHeight = 100;
const {LOCAL_FILE} = EVENT_STREAM_TYPES;

const useStyles = makeStyles(({palette}) => ({
  sectionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoLabel: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: '500',
    color: palette.gray['500'],
    marginBottom: 8,
    '& > i': {
      marginRight: 4,
      fontSize: 16,
    },
  },
  success: {color: palette.green['500']},
  failure: {color: palette.red['500']},
  next: {color: palette.teal['500']},
}));
export default function HistoryLog({bodyHeight}) {
  const classes = useStyles();
  const previewClasses = usePreviewClasses();
  const {getValues} = useFormContext();
  const stream = getValues();
  const {eventStreamLogData, eventStreamLogIsLoading} = useFetchEventStreamLog(stream.id);
  const {eventStreamLastRunData, eventStreamLastRunIsLoading} = useFetchEventStreamLastRun(stream.id);

  return (
    <>
      <div className={previewClasses.container} style={{gridAutoRows: `minmax(${firstRowHeight}px, auto)`}}>
        <div className={previewClasses.sectionWrapper}>
          {eventStreamLogIsLoading && <ContentLoaderPreview />}
          {!eventStreamLogIsLoading && (
            <div className={classes.sectionWrapper}>
              <div className={classes.column}>
                <span className={classes.infoLabel}>Total Complete</span>
                <span>{eventStreamLogData.total}</span>
              </div>
              <div className={classes.column}>
                <span className={classes.infoLabel}>
                  <i className={`icon icn-general16-checkmark ${classes.success}`} />
                  Success
                </span>
                <span>{eventStreamLogData.success}</span>
              </div>
              <div className={classes.column}>
                <span className={classes.infoLabel}>
                  <i className={`icon icn-general16-warning ${classes.failure}`} />
                  Failure
                </span>
                <span>{eventStreamLogData.failure}</span>
              </div>
            </div>
          )}
        </div>

        {stream.type !== LOCAL_FILE && (
          <div className={previewClasses.sectionWrapper}>
            {eventStreamLastRunIsLoading && <ContentLoaderPreview />}
            {!eventStreamLastRunIsLoading && (
              <div className={classes.sectionWrapper}>
                <div className={classes.column}>
                  <span className={classes.infoLabel}>
                    <i className={`icon icn-arrow16-arrowright ${classes.next}`} />
                    Next Collection
                  </span>
                  <span>
                    {eventStreamLastRunData.nextTime
                      ? getFormattedDateYMD(eventStreamLastRunData.nextTime, stream.timeZone)
                      : ''}
                  </span>{' '}
                  <span>{stream.timeZone}</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <>
        {eventStreamLogIsLoading && <ContentLoaderPreviewBig />}
        {!eventStreamLogIsLoading && (
          <Table1
            columns={eventStreamLogData.columns}
            data={eventStreamLogData.data}
            timeZone={stream.timeZone}
            isSortable
            height={bodyHeight - firstRowHeight}
          />
        )}
      </>
    </>
  );
}

HistoryLog.defaultProps = {
  bodyHeight: 0,
};

HistoryLog.propTypes = {
  bodyHeight: PropTypes.number,
};
