// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SchedularData from 'bc/components/streams/editor/common/SchedularData';
import SchedulerEditorModal from 'bc/components/streams/editor/scheduler/SchedulerEditorModal';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import {get} from 'lodash';
import './SqlSchedular.module.scss';

type PropTypes = {
  dataStreamState: boolean,
  dataStream: Object,
  uiState: Object,
};

class SqlSchedular extends React.PureComponent {
  props: PropTypes;

  state = {
    isModalOpen: false,
  };

  onClose = () => {
    this.setState({isModalOpen: false});
  };

  editClicked = () => {
    this.setState({isModalOpen: true});
  };

  render() {
    const {uiState, dataStream, dataStreamState} = this.props;

    // const isEditable =
    //   (isStreamEditable(dataStreamState) || isEditRunning(dataStream.id)) &&
    //   get(uiState, 'editEnabled') &&
    //   (get(uiState, 'tablesViewsMetadata') || get(uiState, 'queryPreviewColumns'));
    const isEditable =
      isEditRunning(dataStream.id) ||
      (isStreamEditable(dataStreamState) &&
        get(uiState, 'editEnabled') &&
        (get(uiState, 'tablesViewsMetadata') || get(uiState, 'queryPreviewColumns')));

    return (
      <div className="shell-col" styleName={!isEditable ? 'disabled' : ''}>
        <PanelHeader title="Schedule records collection" onSelect={this.editClicked} isEditable={isEditable} />
        <SchedularData />
        {this.state.isModalOpen && (
          <SchedulerEditorModal dataStream={dataStream} isModalOpen={this.state.isModalOpen} onClose={this.onClose} />
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  dataStreamState: selectors.getSelectedDataStream(state).state,
  dataStream: selectors.getSelectedDataStream(state),
  uiState: selectors.getSelectedDataStream(state).uiState,
}))(SqlSchedular);
