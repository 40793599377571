import React, {useMemo, useState} from 'react';
import useExpressionBuilder from 'dashboards/hooks/useExpressionBuilder';
import Gradient from 'common/componentsV2/Gradient';
import {useField} from 'react-final-form';
import {isEqual} from 'lodash';
import CollapsedMetricsList from 'metrics/components/metricExplorer/CollapsedMetricsList';
import MetricExplorerList from 'metrics/components/metricExplorer/MetricExplorerList';
import MetricExplorerChartHeaderTools from 'metrics/components/metricExplorer/MetricExplorerChartHeaderTools';
import NothingToShow from 'metrics/components/metricExplorer/NothingToShow';
import QuickActions from 'alerts.management/components/editor/simulationArea/QuickActions';

const ExpressionTreesPreviewContainer = ({isNoSettings, isModal}: {isNoSettings: boolean, isModal: boolean}) => {
  const [isCollapsedView, setCollapsedView] = useState(true);

  const {
    input: {value: byTreeExpFormValue},
  } = useField('byTreeExp', {isEqual});

  const byTreeExp = useMemo(() => Object.entries(byTreeExpFormValue).map(([id, options]) => ({id, options})), [
    byTreeExpFormValue,
  ]);

  const {
    input: {value: dateRange},
  } = useField('dateRange');

  const {
    input: {value: timeScale},
  } = useField('timeScale');

  const {
    input: {value: multiPreviewOptions},
  } = useField('multiPreviewOptions');

  const {
    input: {value: staticLine},
  } = useField('staticLine');

  const {
    input: {value: expressionTreeType},
  } = useField('expressionTreeType');

  const {
    providedResolution,
    isCompositeDataLoading,
    isLoading,
    isZoomLoading,
    totalAmount,
    metrics,
    validationId,
    validationMessage,
    needsQuickActions,
    noValidTrees,
    isMultiExpressions,
  } = useExpressionBuilder({
    dateRange,
    timeScale,
    multiPreviewOptions,
    chartId: isModal ? 'influencingMetricsPreview' : undefined,
  });

  if (needsQuickActions) {
    return (
      <QuickActions
        maxExpressionsCount={3}
        defaultFunction={expressionTreeType === 'simple' ? 'sumSeries' : undefined}
      />
    );
  }

  return (
    <div
      className="idle-external-click display_flex flexDirection_column height_1 flexGrow_1"
      automation-id="expression-trees-preview-area"
    >
      <MetricExplorerChartHeaderTools
        providedResolution={providedResolution}
        isLoading={isCompositeDataLoading}
        totalAmount={totalAmount}
        metrics={metrics}
        isCollapsedView={isCollapsedView}
        setCollapsedView={setCollapsedView}
        noSettings={isNoSettings}
        validationId={validationId}
        isMultiExpressions={isMultiExpressions}
      />
      {!isCompositeDataLoading && noValidTrees ? (
        <NothingToShow validationId={validationId} validationMessage={validationMessage} />
      ) : (
        <div className="display_flex flexDirection_column position_relative height_1 mt_0-5">
          {!isCollapsedView && <Gradient />}
          {isCollapsedView ? (
            <CollapsedMetricsList
              isExtendedTooltip
              dateRange={dateRange}
              chartId="expressionTreesPreview"
              isZoomLoading={false}
              timeScale={timeScale}
              metrics={metrics}
              isLoading={isCompositeDataLoading}
              chartHeight={250}
              byTreeExp={byTreeExp}
              staticLine={staticLine}
            />
          ) : (
            <MetricExplorerList
              dateRange={dateRange}
              metrics={metrics}
              isLoading={isLoading}
              isZoomLoading={isZoomLoading}
              isAnyLoading={isCompositeDataLoading}
              isInvalid={!!validationMessage}
              timeScale={timeScale}
              totalAmount={totalAmount}
              byTreeExp={byTreeExp}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ExpressionTreesPreviewContainer;
