// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Checkbox from 'common/componentsV2/Checkbox';
import * as filterSelector from 'alerts.management/store/filterSelector';
import {DEFAULT_QUERY_PARAMS} from 'alerts.management/services/alertManagementService';

type PropTypes = {
  setQueryParams: Function,
  isNotifications: String,
};

export default connect(
  (state) => ({
    isNotifications: filterSelector.getAlertFiltersNotifications(state),
  }),
  {},
)(
  class NotificationsFilter extends React.PureComponent {
    props: PropTypes;

    onChange = (val) => {
      this.props.setQueryParams({notifications: val === true ? val : DEFAULT_QUERY_PARAMS.notifications});
    };

    render() {
      const {isNotifications} = this.props;
      return (
        <Checkbox
          isChecked={isNotifications === true}
          text="Alerts with notifications"
          automationId="alertsWithNotifications"
          onChange={this.onChange}
        />
      );
    }
  },
);
