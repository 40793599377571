// @flow
/* eslint-disable react/no-array-index-key */
import React from 'react';
import './StyleGuideComponents.module.scss';

export default class IconsSection extends React.Component {
  render() {
    const iconList = [
      {
        title: 'Old Icons',
        list: [
          'icn-icon-3-dots',
          'icn-icon-andt-mark-me-empty-icon',
          'icn-icon-anomaly-type-pattern-change',
          'icn-icon-anomaly-type-transient',
          'icn-icon-ban',
          'icn-icon-blackarrow-down',
          'icn-icon-blue-plus',
          'icn-icon-bookmark-empty',
          'icn-icon-bookmark-full',
          'icn-icon-breadcrumbs',
          'icn-icon-chart-area',
          'icn-icon-chart-line',
          'icn-icon-chart-polls',
          'icn-icon-chart-spline',
          'icn-icon-checkbox-full',
          'icn-icon-checkbox',
          'icn-icon-clear-filter',
          'icn-icon-copy',
          'icn-icon-dashboard',
          'icn-icon-dimmet-group',
          'icn-icon-drag-dash',
          'icn-icon-enlarge-win',
          'icn-icon-error',
          'icn-icon-extrainfo',
          'icn-icon-finetune',
          'icn-icon-function',
          'icn-icon-grid',
          'icn-icon-hamburger',
          'icn-icon-help',
          'icn-icon-include',
          'icn-icon-incomplete',
          'icn-icon-initializing',
          'icn-icon-list-wrap',
          'icn-icon-minus-mini',
          'icn-icon-night-paused',
          'icn-icon-pin-off',
          'icn-icon-pin-on',
          'icn-icon-plus-mini',
          'icn-icon-reduce-win',
          'icn-icon-refresh',
          'icn-icon-resume',
          'icn-icon-search',
          'icn-icon-side-menu',
          'icn-icon-sun-live-stream',
          'icn-icon-table-aggregation',
          'icn-icon-table-delete',
          'icn-icon-table-filter',
          'icn-icon-table-ignore',
          'icn-icon-table-units',
          'icn-icon-tablepopup-plus',
          'icn-icon-time-filter',
          'icn-icon-time',
          'icn-icon-triangle-equal-func',
          'icn-icon-triangle-func',
          'icn-icon-triangle-left',
          'icn-icon-triangle-right',
          'icn-icon-unsubscribe',
          'icn-icon-white-close',
          'icn-icon-white-dropdown',
          'icn-icon-white-plus',
          'icn-icon-zoom-out',
        ],
      },
      {
        title: 'Generic Icons',
        list: [
          'icn-alert-console',
          'icn-alert-on-tile-empty',
          'icn-alert-on-tile',
          'icn-alert',
          'icn-alert_new',
          'icn-anomaly',
          'icn-avatar',
          'icn-complete-icon',
          'icn-dashboard',
          'icn-data-management',
          'icn-display-style-icon',
          'icn-edit-pen-icon',
          'icn-email-grey-9-icon',
          'icn-events',
          'icn-management',
          'icn-numbers-icon',
          'icn-open-alert-icon',
          'icn-search-metrics',
          'icn-settings-icon',
          'icn-settings',
          'icn-signed-v-icon',
          'icn-slack-grey-9-icon',
          'icn-snooze-icon',
          'icn-support',
          'icn-text-icon',
          'icn-time-stamp-icon',
          'icn-users-grey-9-icon',
          'icn-view-icon',
          'icn-warning-icon',
          'icn-webhook-grey-9-icon',
        ],
      },
      {
        title: 'Action Icons',
        list: [
          'icn-action16-acknowledge',
          'icn-action16-copy',
          'icn-action16-delete',
          'icn-action16-download',
          'icn-action16-edit',
          'icn-action16-favorite',
          'icn-action16-pause',
          'icn-action16-plusa',
          'icn-action16-plusb',
          'icn-action16-resume',
          'icn-action16-snooze',
          'icn-action16-unfavorite',
          'icn-action16-upload',
          'icn-action24-acknowledge',
          'icn-action24-copy',
          'icn-action24-delete',
          'icn-action24-plusa',
          'icn-action24-plusb',
          'icn-action24-snooze',
          'icn-action24-stopimp',
        ],
      },
      {
        title: 'General Icons',
        list: [
          'icn-general16-3dot',
          'icn-general16-checkmark',
          'icn-general16-closea',
          'icn-general16-closeb',
          'icn-general16-delta',
          'icn-general16-direction',
          'icn-general16-filter',
          'icn-general16-help',
          'icn-general16-hide',
          'icn-general16-info',
          'icn-general16-live',
          'icn-general16-mail',
          'icn-general16-placeholder',
          'icn-general16-search',
          'icn-general16-share',
          'icn-general16-show',
          'icn-general16-tags',
          'icn-general16-timescale',
          'icn-general16-unsubscribe',
          'icn-general16-user',
          'icn-general16-warning',
          'icn-general24-chat',
          'icn-general24-dataerror',
          'icn-general24-dataok',
          'icn-general24-dislike',
          'icn-general24-like',
          'icn-general24-nobusiness',
          'icn-general24-refresh',
        ],
      },
      {
        title: 'Selection Icons',
        list: [
          'icn-selection16-checkboxhalf',
          'icn-selection16-checkboxoff',
          'icn-selection16-checkboxon',
          'icn-selection16-radiooff',
          'icn-selection16-radioon',
        ],
      },
      {
        title: 'Navigation Icons',
        list: [
          'icn-nav16-goto',
          'icn-nav24-alertconsole',
          'icn-nav24-anomalies',
          'icn-nav24-dashboards',
          'icn-nav24-data',
          'icn-nav24-help',
          'icn-nav24-info',
          'icn-nav24-management',
          'icn-nav24-metricsearch',
          'icn-nav24-settings',
          'icn-nav24-summary',
          'icn-nav24-user',
        ],
      },
      {
        title: 'Arrows',
        list: [
          'icn-arrow16-arrowdown',
          'icn-arrow16-arrowleft',
          'icn-arrow16-arrowright',
          'icn-arrow16-arrowup',
          'icn-arrow16-chevrondown',
          'icn-arrow16-chevronleft',
          'icn-arrow16-chevronright',
          'icn-arrow16-chevronup',
          'icn-arrow16-triangledown',
          'icn-arrow16-triangleleft',
          'icn-arrow16-triangleright',
          'icn-arrow16-triangleup',
        ],
      },
      {
        title: 'Other Icons',
        list: [
          'icn-other16-jira',
          'icn-other16-msteams',
          'icn-other16-opsgenie',
          'icn-other16-pagerduty',
          'icn-other16-slack',
          'icn-other16-tamtam',
        ],
      },
    ];

    const handleFocus = (evt) => {
      evt.target.select();
      document.execCommand('copy');
    };

    return (
      <div styleName="icons-component">
        <h4>Icons</h4>
        <div styleName="presentation">
          {iconList.map((iconDescription, i) => (
            <div key={i} styleName="item format-block">
              <h6>{iconDescription.title}</h6>
              <ul styleName="icon-list">
                {iconDescription.list.map((icon, j) => (
                  <li key={j}>
                    <div styleName="icon-list-item" className={['icon', icon].join(' ')} />
                    <input styleName="icon-name" onFocus={handleFocus} value={icon} />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
