// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import {isEqual} from 'lodash';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from 'common/componentsV2/Checkbox';
import {TypographyBox} from 'common/componentsV2/boxTools';
import TooltipArea from 'common/componentsV2/TooltipArea';
import SelectDimensions from 'alerts.management/components/editor/simulationArea/dynamicRouting/SelectDimensions';
import SelectDimensionsAndTags from 'alerts.management/components/editor/simulationArea/dynamicRouting/SelectDimensionsAndTags';
import RoutingModal from 'alerts.management/components/editor/simulationArea/dynamicRouting/RoutingModal';
import {getChannelsDynamicRoutingEnabled} from 'profile/store/selectors';

const useStyles = makeStyles(() => ({
  dimensionWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: 4,
  },
  dynamicRoutingSelectContainer: {
    marginTop: 4,
  },
}));

const tooltipText = (
  <>
    Dynamic routing enables alert triggers to be sent to different recipients based on specified dimension values
    configured for the alert.
    <br />
    For example, you can configure an alert to be sent to Person A if its origin is in the USA and to Person B if its
    origin is in Canada.
  </>
);

const DynamicRouting = () => {
  const classes = useStyles();
  const isChannlsDynamicRoutingEnabled = useSelector(getChannelsDynamicRoutingEnabled);

  const {
    meta: {touched, error},
  } = useField('dynamicRouting', {isEqual});

  const {
    input: {value: isInUseDynamicRouting, onChange: onChangeIsInUseDynamicRouting},
  } = useField('dynamicRouting.isInUseDynamicRouting');

  const {
    input: {value: selectedDimension, onChange: onChangeSelectedDimension},
  } = useField('dynamicRouting.selectedDimension');

  const {
    input: {value: selectedRoutingFile, onChange: onChangeSelectedRoutingFile},
  } = useField('dynamicRouting.selectedRoutingFile');

  return (
    <>
      <TooltipArea isAlwaysVisible automationId="dynamicRoutingContainer" text={tooltipText}>
        {(info) => (
          <Checkbox isChecked={isInUseDynamicRouting} onChange={onChangeIsInUseDynamicRouting}>
            <div className="text14reg lineHeight_16 ml_0-5 position_relative">Dynamic Routing {info}</div>
          </Checkbox>
        )}
      </TooltipArea>

      {isInUseDynamicRouting && (
        <div className={classes.dynamicRoutingSelectContainer} name="dynamicRouting">
          <div className={classes.dimensionWrapper}>
            {isChannlsDynamicRoutingEnabled ? (
              <SelectDimensionsAndTags value={selectedDimension} onChange={onChangeSelectedDimension} />
            ) : (
              <SelectDimensions value={selectedDimension} onChange={onChangeSelectedDimension} />
            )}
          </div>
          <RoutingModal value={selectedRoutingFile} onChange={onChangeSelectedRoutingFile} />
          {touched && error && (
            <TypographyBox variant="subtitle2" mt={0.5} color="red.500">
              {error}
            </TypographyBox>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(DynamicRouting);
