import {useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {isEmpty} from 'lodash';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import {getFetchTriggeredAlertIsLoading, getIncidentTokenMapIsLoading, getInvWhere} from '../../../store/selectors';
import DimensionChip from '../../dimensions/DimensionChip';
import '../OverwiewTab.module.scss';

const WhereInfoCube = ({onIsLoading}: {onIsLoading: Function}) => {
  const where = useSelector(getInvWhere);
  const isTriggerLoading = useSelector(getFetchTriggeredAlertIsLoading);
  const isTokenMapLoading = useSelector(getIncidentTokenMapIsLoading);
  const isLoading = isTriggerLoading || isTokenMapLoading;

  useEffect(() => {
    onIsLoading(isLoading);
  }, [isLoading]);

  if (isEmpty(where)) {
    return null;
  }

  return (
    <div styleName="info-cube-container">
      <div>
        <div styleName="where-info-cube-text">Anomaly occurs in</div>
        <div styleName="where-info-cube-where-container">
          <Tooltip content={where.key} type={TYPES.LARGE}>
            <div styleName="toltip-container">
              <span styleName="where-text">{where.key}</span>
            </div>
          </Tooltip>
          <Tooltip content={where.value} type={TYPES.LARGE}>
            <div styleName="toltip-container">
              <DimensionChip label={where.value} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default WhereInfoCube;
