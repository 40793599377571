import GoogleOAuth2 from 'google-oauth2-web-client';
import {bcTypes} from './bcTypes';

export const SEARCH_TYPES_ARR = [
  {name: 'Discover', id: 'discover'},
  {name: 'Google News', id: 'googleNews'},
  {name: 'Image', id: 'image'},
  {name: 'Video', id: 'video'},
  {name: 'Web', id: 'web'},
];

export const DIMENSIONS_ARR = [
  {name: 'Country', id: 'country'},
  {name: 'Device', id: 'device'},
  {name: 'Page', id: 'page'},
  {name: 'Query', id: 'query'},
  {name: 'Search Appearance', id: 'searchAppearance'},
];

export const MEASURES_ARR = [
  {name: 'Clicks', id: 'clicks', aggr: 'counter'},
  {name: 'Impressions', id: 'impressions', aggr: 'counter'},
  {name: 'Ctr', id: 'ctr', aggr: 'gauge'},
  {name: 'Position', id: 'position', aggr: 'gauge'},
];

export const AGGREGATION_TYPES_ARR = [
  {name: 'Auto', id: 'auto'},
  {name: 'By Page', id: 'byPage'},
  {name: 'By Property', id: 'byProperty'},
];

export const FILTER_TYPES_ARR = [
  {name: 'Contains', id: 'contains'},
  {name: 'Equals', id: 'equals'},
  {name: 'Not Contains', id: 'notContains'},
  {name: 'Not Equals', id: 'notEquals'},
  {name: 'Including Regex', id: 'includingRegex'},
  {name: 'Excluding Regex', id: 'excludingRegex'},
];

export const getAllowedAggregationTypesArr = (dataStream) => {
  if (dataStream.dimensions.includes('page') || ['discover', 'googleNews'].includes(dataStream.searchType)) {
    return [AGGREGATION_TYPES_ARR.find((i) => i.id === 'byPage')];
  }
  return AGGREGATION_TYPES_ARR;
};

export const doGsearchAuthFlow = (clientId, createDataSource, me) => {
  let locOrigin = window.location.origin;
  if (locOrigin.indexOf('localhost:') === -1) {
    const baseDnsName = me.baseDNSName === 'anodot-test.com' ? 'ano-dev.com' : me.baseDNSName;
    locOrigin = `https://${me.clusterDNSPrefix}.${baseDnsName}`;
  }

  const go2 = new GoogleOAuth2({
    clientId,
    redirectUri: `${locOrigin}/api/v1/bc/signin/oauth/google/callback`,
    scope: 'https://www.googleapis.com/auth/webmasters.readonly',
    responseType: 'code',
    accessType: 'offline',
    state: me.ownerOrganization || 'no-org-id',
    popupHeight: 600,
    popupWidth: 600,
  });
  go2.login(true, false);

  window.createDataSource = function(token) {
    createDataSource({
      code: token,
      type: bcTypes.google_search.type,
      name: `Google Search Data Source ${new Date().getTime().toString()}`,
      authenticationType: 'refresh_token',
      redirectURI: `${locOrigin}/api/v1/bc/signin/oauth/google/callback`,
    });
  };
};

export const newFilter = {key: '', op: FILTER_TYPES_ARR[0].id, value: ''};

export const convertUiFiltersToStreamFilters = (uiFilters) => {
  if (!uiFilters.length) {
    return [];
  }
  const filters = [];
  uiFilters.forEach((uiFilter) => {
    if (uiFilter?.key !== '' && uiFilter?.value !== '') {
      filters.push({key: uiFilter.key, value: uiFilter.value, op: uiFilter.op});
    }
  });
  return filters;
};

export const convertStreamFiltersToUiFilters = (sFilters) => {
  if (!sFilters || !sFilters.length) {
    return newFilter;
  }
  const filters = [];
  sFilters.forEach((sFilter) => {
    filters.push({key: sFilter.key, value: sFilter.value, op: sFilter.op});
  });
  return filters;
};

export const getEditorValidationMessage = () => {
  return null;
};
