// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import * as selector from 'admin.users/store/selectors';
import {toggleGroupsModal} from 'admin.users/store/actions';
import GroupForm from './GroupForm';

type PropTypes = {
  isGroupModalOpen: boolean,
  toggleGroupsModal: Function,
};

export default connect(
  (state) => ({
    isGroupModalOpen: selector.getGroupsModalIsOpen(state),
  }),
  {
    toggleGroupsModal,
  },
)(
  class GroupFormModal extends React.PureComponent {
    props: PropTypes;

    onModalClose = () => {
      this.props.toggleGroupsModal({isOpen: false});
    };

    render() {
      return (
        <Modal
          classStyle="group-modal"
          id="group-modal"
          isOpen={this.props.isGroupModalOpen}
          onClose={this.onModalClose}
          isCloseButtonHidden
          size={SIZES.LARGE}
        >
          <GroupForm />
        </Modal>
      );
    }
  },
);
