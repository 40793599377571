// @flow
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {get} from 'lodash';

import {toggleGroupsModal, toggleNewUserModal, setUsersGroupsFiltersIsOpen} from 'admin.users/store/actions';
import {getMeAppSettings, isAnodot, isAnodotUser} from 'profile/store/selectors';
import {getFiltersButtonTooltipItems} from 'admin.users/store/filterSelector';

import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import Title, {TYPES as TITLE_TYPES} from 'common/componentsV2/Title';
import Button, {COLORS} from 'common/componentsV2/Button';
import FilterButton from 'common/componentsV2/FilterButton';
import './UserAndGroupsPageHeader.module.scss';

type PropTypes = {
  isAndt: boolean,
  isAndtUser: boolean,
  appSettings: Object,
  tooltipItems: Array,
  toggleGroupsModal: Function,
  toggleNewUserModal: Function,
  setUsersGroupsFiltersIsOpen: Function,
};

export default connect(
  (state) => ({
    isAndt: isAnodot(state),
    isAndtUser: isAnodotUser(state),
    appSettings: getMeAppSettings(state),
    tooltipItems: getFiltersButtonTooltipItems(state),
  }),
  {
    toggleGroupsModal,
    toggleNewUserModal,
    setUsersGroupsFiltersIsOpen,
  },
)(
  class UsersAndGroups extends React.PureComponent {
    props: PropTypes;

    state = {
      isOpen: false,
    };

    onToggle = (isOpen) => {
      this.setState({isOpen});
    };

    itemNewUserClicked = () => {
      this.props.toggleNewUserModal({isOpen: true});
      this.setState({isOpen: false});
    };

    itemNewGroupClicked = () => {
      this.props.toggleGroupsModal({isOpen: true});
      this.setState({isOpen: false});
    };

    onFilterButtonClick = () => {
      const isOpen = get(this.props.appSettings, 'usersGroups.isFiltersBarOpen', false);
      this.props.setUsersGroupsFiltersIsOpen(!isOpen);
    };

    renderPopOver = () => (
      <div styleName="pop-over">
        <div styleName="item" role="presentation" onClick={this.itemNewUserClicked} automationId="newUser">
          <i className="icn-general16-user" />
          <span>New User</span>
        </div>
        {!this.props.isAndt && (
          <div styleName="item" role="presentation" onClick={this.itemNewGroupClicked} automationId="newGroup">
            <i className="icn-general16-group" />
            <span>New Group</span>
          </div>
        )}
      </div>
    );

    render() {
      const {isOpen} = this.state;
      const {tooltipItems, isAndtUser} = this.props;

      return (
        <div styleName="root">
          <div styleName="left-side">
            <span styleName="title-pos">
              <Title type={TITLE_TYPES.PAGE_TITLE}>Users & Groups</Title>
            </span>
            <FilterButton
              tooltipItems={tooltipItems}
              onClick={this.onFilterButtonClick}
              automationId="userGroupsFilterButton"
            />
          </div>
          {!isAndtUser && (
            <div styleName="right-side">
              <FormDdl
                buttonComponent={
                  <Button
                    colorSchema={COLORS.BLUE_500}
                    text="New"
                    onClick={this.onToggle}
                    icon="icn-action16-plusa"
                    automationId="newUserGroup"
                  />
                }
                onToggle={this.onToggle}
                onClickOutside={this.onClickOutside}
                isOpen={isOpen}
                position="right"
                width={150}
                popoverComponent={this.renderPopOver()}
              />
            </div>
          )}
        </div>
      );
    }
  },
);
