// @flow

import React from 'react';
import './Footer.module.scss';
import Checkbox from './Checkbox';

type PropTypes = {
  selectedItemsCount: number,
  onCheckboxClicked: Function,
  leftPadding: Number,
  children: Node,
  checkboxState: String,
  isCheckbox: Boolean,
};
export default class PageLayout extends React.PureComponent {
  props: PropTypes;

  render() {
    const {selectedItemsCount, children, checkboxState, onCheckboxClicked, leftPadding, isCheckbox = true} = this.props;
    return (
      <div styleName="page-layout">
        <div style={{paddingLeft: leftPadding}} styleName="content-wrapper">
          {isCheckbox ? (
            <div styleName="content col1">
              <Checkbox styleName="checkbox" threeStatesStatus={checkboxState} onChange={onCheckboxClicked} />
              <span>{`${selectedItemsCount} ${selectedItemsCount > 1 ? 'items' : 'item'} selected`}</span>
            </div>
          ) : (
            <div styleName="content col0">
              <span styleName="selectedCount">{selectedItemsCount}</span>
              <span>{`${selectedItemsCount > 1 ? 'items' : 'item'} selected`}</span>
            </div>
          )}
          <div styleName="content col2">{children}</div>
          <div styleName="content col3" />
        </div>
      </div>
    );
  }
}
