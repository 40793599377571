// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {fetchMetricDataPoints as fetchMetricDataPointsAction} from 'topologyLeaflet/store/actions';
import {getBucketStartTimeEnabled, getTimeZoneName} from 'profile/store/selectors';
import {getTMLeftPanelSelectedIssue} from 'topologyLeaflet/store/selectors';
import TimeSeriesChart from 'charts/timeSeries/components/TimeSeriesChart';
import NameFormat20 from 'metrics/components/metricName/NameFormat20';
import './LeftPanel.module.scss';

let counter = 0;

const TOOLTIP_OPTIONS = {
  showMetricName: false,
  showAnomalyData: true,
};

type PropTypes = {
  className?: String,
  item: Object,
  index: Number,
  onClick: Function,

  // connect
  selectedIssue: Object,
  bucketStartTimeEnabled: Boolean,
  timeZoneName: String,
  fetchMetricDataPoints: Function,
};

export default connect(
  (state) => ({
    selectedIssue: getTMLeftPanelSelectedIssue(state),
    bucketStartTimeEnabled: getBucketStartTimeEnabled(state),
    timeZoneName: getTimeZoneName(state),
  }),
  {
    fetchMetricDataPoints: fetchMetricDataPointsAction,
  },
)(
  class MetricItem extends PureComponent {
    props: PropTypes;

    static defaultProps = {
      className: '',
    };

    constructor(props) {
      super(props);
      const {selectedIssue, item} = this.props;
      this.id = `topology.chart_${(counter += 1)}`;
      this.chartEventsMeta = {
        key: `${selectedIssue.id}_${item.metricId}`, // unique key for chart
        chartId: this.id,
      };
    }

    componentDidMount() {
      const {fetchMetricDataPoints, item} = this.props;

      fetchMetricDataPoints(item.metricId, {...this.chartEventsMeta, ...item});
    }

    onItemClick = () => {
      if (this.props.onClick) {
        this.props.onClick(this.props.item);
      }
    };

    render() {
      const {className, item, bucketStartTimeEnabled, timeZoneName, selectedIssue, index} = this.props;

      return (
        <div className={className} styleName="metric-item" onClick={this.onItemClick}>
          <div styleName="metric-name-wrapper">
            <NameFormat20 metric={item} tooltipPlacement={index === 0 ? 'bottom' : 'top'} />
          </div>
          <div styleName="chart">
            <TimeSeriesChart
              id={this.id}
              eventsMeta={this.chartEventsMeta}
              tooltip={TOOLTIP_OPTIONS}
              height={180}
              bucketStartTimeEnabled={bucketStartTimeEnabled}
              timeScale={selectedIssue.timeScale}
              timeZoneName={timeZoneName}
            />
          </div>
        </div>
      );
    }
  },
);
