import React from 'react';
import {ALERT_RECOMMENDATION_TYPES, getAlertRecommendationText} from 'recommendations/services/consts';
import Info from 'common/componentsV2/Info';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {TYPES} from 'common/componentsV2/Tooltip';
import {ICONS} from '@pileus-cloud/anodot-frontend-common';

import classes from './AlertRecommendationsMenu.module.scss';

const AlertRecommendationsMenuPopover = ({
  alertRecommendations,
  onApplyRecommendation,
  onApplyAllRecommendations,
  onIgnoreRecommendation,
  onIgnoreAllRecommendations,
}: {
  alertRecommendations: Array,
  onApplyRecommendation: Function,
  onApplyAllRecommendations: Function,
  onIgnoreRecommendation: Function,
  onIgnoreAllRecommendations: Function,
}) => {
  const infoTooltip = 'Choose which recommendation to apply, then update the alert to save changes.';

  const allRecommendationsOptions = {
    APPLY: {
      value: 'applyAll',
      label: 'Apply all',
      faIcon: ICONS.check.name,
    },
    IGNORE: {
      value: 'ignoreAll',
      label: 'Ignore all',
      faIcon: ICONS.xMark.name,
    },
  };

  const recommendationItem = (recommendation) => {
    return (
      <div className={classes.menuItem}>
        <div className={classes.menuItemTitle}>{ALERT_RECOMMENDATION_TYPES[recommendation.type]?.text ?? 'n/a'}</div>
        <div className={classes.menuItemText}>{getAlertRecommendationText(recommendation)}</div>
        <div className={classes.menuItemButtons}>
          <div className={classes.applyButton} onClick={() => onApplyRecommendation(recommendation.type)}>
            Apply
          </div>
          <div className={classes.ignoreButton} onClick={() => onIgnoreRecommendation(recommendation.type)}>
            Ignore
          </div>
        </div>
      </div>
    );
  };

  const actionSelected = (action) => {
    switch (action.value) {
      case allRecommendationsOptions.APPLY.value:
        onApplyAllRecommendations();
        break;
      case allRecommendationsOptions.IGNORE.value:
        onIgnoreAllRecommendations();
        break;
      default:
        break;
    }
  };

  return alertRecommendations?.length ? (
    <>
      <div className={classes.menuHeader}>
        <div className={classes.menuHeaderLeft}>
          Recommendations <span className={classes.counter}>({alertRecommendations?.length})</span>
          <Info text={infoTooltip} tooltipType={TYPES.SMALL} styleName="info" />
        </div>
        <div className={classes.menuHeaderRight}>
          <div styleName="more-action-wrapper" role="presentation">
            <FormDdlSelect
              automationId="allRecommendationsMenu"
              className="alert-clickable-item"
              position="right"
              width={135}
              options={Object.values(allRecommendationsOptions)}
              button={<div styleName="button-like-dropdown" className="icon icn-icon-3-dots alert-clickable-item" />}
              optionComponent={<OptionComponentSimple />}
              onChange={(action) => actionSelected(action)}
              placement="auto"
            />
          </div>
        </div>
      </div>
      <div className={classes.recommendationsList}>
        {alertRecommendations?.map((recommendation) => recommendationItem(recommendation))}
      </div>
    </>
  ) : (
    undefined
  );
};

export default AlertRecommendationsMenuPopover;
