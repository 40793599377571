// @flow
import React, {useCallback} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import {ALERT_IS_OPEN, ALERT_CONSOLE_QUERY_PARAMS_KEYS} from 'alerts.console.new/services/alertsConsoleService';
import SwitchButton from 'common/componentsV2/SwitchButton';

const AlertIsOpenFilter = () => {
  const [isAlertOpen, setIsAlertOpen] = useQueryParam(ALERT_CONSOLE_QUERY_PARAMS_KEYS.IS_OPEN, StringParam);

  const setIsAlertOpenWrapper = (isOpen) => {
    setIsAlertOpen(isOpen);
  };

  const toggleFilter = useCallback(
    (openOnly) => {
      setIsAlertOpenWrapper(openOnly ? ALERT_IS_OPEN.OPEN.value : ALERT_IS_OPEN.ALL.value);
    },
    [setIsAlertOpen],
  );

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <SwitchButton
        isChecked={isAlertOpen === ALERT_IS_OPEN.OPEN.value}
        automationId="alertIsOpenFilter"
        onChange={toggleFilter}
      />
      <span style={{marginLeft: 7}}>Open Alerts</span>
    </div>
  );
};

export default AlertIsOpenFilter;
