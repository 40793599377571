import {useMutation, useQueryClient} from 'react-query';

import {post} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {setCleanStream} from 'dataCollectors/services/eventStreamService';

const root = 'api/v1/bc/';

const postEventStream = (stream) => {
  const cleanStream = setCleanStream(stream);
  return post(`${root}event-streams/`, cleanStream);
};

export default function useCreateEventStream() {
  const queryClient = useQueryClient();

  const {mutate: createEventStream, isLoading: isCreateEventStreamLoading} = useMutation(
    ({payload}) => postEventStream(payload),
    {
      onSuccess: (data, meta) => {
        queryClient.invalidateQueries([QUERY_KEYS.streams]);
        meta.onSuccess(data);
      },
    },
  );

  return {createEventStream, isCreateEventStreamLoading};
}
