// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';

import CubeMeasures from 'bc.cubes/components/editor/CubeMeasures';
import CubeDimensions from 'bc.cubes/components/editor/CubeDimensions';
import CubePreview from 'bc.cubes/components/editor/CubePreview';
import CubeStatus from 'bc.cubes/components/editor/CubeStatus';
import CubeDescription from 'bc.cubes/components/editor/CubeDescription';
import Loader from 'common/components/Loader';
import {isEmpty} from 'lodash';

import * as selectors from 'bc.cubes/store/selectors';
import {
  fetchSchemas as fetchSchemasAction,
  setSelectedCube as setSelectedCubeAction,
  setSelectedSchema as setSelectedSchemaAction,
  createEditableTopCube as createEditableTopCubeAction,
} from '../store/actions';

import './CubeEditor.module.scss';
import CubeName from './CubeName';
import {getUniqueId} from '../../common/utils/guid';

type PropTypes = {
  match: Object,
  isLoading: boolean,
  schema: Object,
  cube: Object,
  cubesUpdateAt: Number,

  // connect
  setSelectedCube: Function,
  setSelectedSchema: Function,
  fetchSchemas: Function,
  createEditableTopCube: Function,
  selectedCubeDetails: Object,
};

export default connect(
  (state) => ({
    isLoading: selectors.getIsSchemasLoading(state),
    schema: selectors.getSelectedSchema(state),
    cube: selectors.getEditableTopCube(state),
    cubesUpdateAt: selectors.getSchemas(state).updateAt,
    selectedCubeDetails: selectors.getSelectedTopLevelCube(state),
  }),
  {
    setSelectedCube: setSelectedCubeAction,
    setSelectedSchema: setSelectedSchemaAction,
    fetchSchemas: fetchSchemasAction,
    createEditableTopCube: createEditableTopCubeAction,
  },
)(
  class CubeEditor extends PureComponent {
    props: PropTypes;

    state = {
      isNew: true,
    };

    componentDidMount() {
      const {setSelectedSchema, setSelectedCube, cubesUpdateAt, fetchSchemas, match} = this.props;

      const urlArr = match.url.split('/');

      this.setState({
        isNew: urlArr.indexOf('new-cube') > 0,
      });
      setSelectedSchema(match.params.schemaId || null);
      setSelectedCube(match.params.cubeId || null);
      if (!cubesUpdateAt) {
        // if this is a fresh load of the app directly in the editor page,
        // then we need to load all cubes (#!/cubes/<cubeId>)
        fetchSchemas({}, {schemaId: match.params.schemaId, cubeId: match.params.cubeId});
      }
    }

    componentDidUpdate(/* prevProps */) {
      // TODO: menny - after save
      // if (prevProps.dataStream !== this.props.dataStream &&
      // get(this.props.dataStream, 'queryServiceState') === 'complete') {
      //   this.props.setRoute('/bc/data-streams/' + this.props.dataStream.id + '/preview');
      // }
    }

    render() {
      const {isLoading, schema, cube, match, selectedCubeDetails, createEditableTopCube} = this.props;

      if (isEmpty(cube)) {
        if (match.params.cubeId) {
          createEditableTopCube(selectedCubeDetails);
        } else {
          const uniqueId = getUniqueId();
          createEditableTopCube({
            id: uniqueId,
            name: 'new Cube',
            measure: null,
            dimensions: [],
            mandatoryDimensions: [],
          });
        }
      }

      if (isLoading !== false) {
        return <Loader />;
      }

      if (isEmpty(schema)) {
        // TODO menny: this will never work since selectors return empty object if there is no schema
        return <div styleName="cube-not-found">The schema you where looking for cannot be found</div>;
      }

      if (schema && match.params.cubeId && !cube) {
        return <div styleName="cube-not-found">The cube you where looking for cannot be found</div>;
      }

      if (schema && isEmpty(cube)) {
        return <Loader />;
      }

      return (
        <div styleName="root">
          <div styleName="header" automation-id="streamQueryHeader">
            <div styleName="title">
              <span className="andt-page-header">Cube Editor -{this.state.isNew ? 'New' : 'Update'}</span>
            </div>
            <CubeName />
          </div>
          <div styleName="editor">
            <div styleName="go-back-pane" />
            <div styleName="dimetrics-pane" id="cube-editor-dimetrics-pane">
              <div className="andt-section-header">Measures & Dimensions</div>
              <div styleName="andt-section-sub-header">
                Select the measures and dimensions you wish to include in the cube
              </div>

              <CubeMeasures styleName="cube-measures" />
              <CubeDimensions styleName="cube-dimensions" />
              <CubePreview styleName="cube-preview" />
            </div>

            <div styleName="metadata-pane">
              <CubeStatus styleName="cube-status" />
              <CubeDescription styleName="cube-description" />
            </div>
          </div>
        </div>
      );
    }
  },
);
