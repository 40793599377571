import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {topElementHeight} from 'investigationNewAlertConsole/services/investigationService';
import useHandleResize from 'common/hooks/useHandleResize';
import scrollTo from 'common/componentsV2/boxTools/scrollTo';
import useClickOutside from 'common/hooks/useClickOutside';
import {getIsBetweenTime} from 'common/utils/dateService';
import {getPostCommentIsLoading} from 'investigationNewAlertConsole/store/selectors';
import {getFirstName, getProfileId, getTimeZoneName} from 'profile/store/selectors';
import {getOrganizationId} from 'userSettings/store/selectors';
import {palette} from 'app/styles/theme';
import Spinner, {SIZES as SpinnerSizes} from 'common/componentsV2/Spinner';
import Button, {COLORS} from 'common/componentsV2/Button';
import {segmentClickEvent} from 'common/store/actions';
import TimelineDayGroup from 'investigationNewAlertConsole/components/timeline/TimelineDayGroup';
import TimelineEventIncremented from 'investigationNewAlertConsole/components/timeline/TimelineEventIncremented';
import {sendComment} from 'investigationNewAlertConsole/services/api';
import fetchTimelineProvider from 'investigationNewAlertConsole/api/fetchTimelineProvider';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    padding: '1px 0 1px 0',
    width: '100%',
    overflow: 'hidden',
  },
  wrapper: {
    width: 'calc(100% + 35px)',
    position: 'absolute',
    overflowY: 'scroll',
    overflowX: 'hidden',
    overflow: '-moz-scrollbars-none',
    '-ms-overflow-style': 'none',
    top: 0,
    bottom: 0,
    left: 0,
    paddingBottom: '20%',
  },
  rowsWrapper: {
    width: 'calc(100% - 35px)',
  },
  maskTop: {
    position: 'absolute',
    zIndex: '2',
    top: 0,
    left: 0,
    height: 30,
    width: '100%',
    background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
  },
  maskBottom: {
    position: 'absolute',
    zIndex: '2',
    bottom: 0,
    left: 0,
    height: 30,
    width: '100%',
    background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
  },
  firstLine: {
    background: palette.red['500'],
    marginLeft: 16,
    width: 2,
    height: 30,
  },
  commentWrapper: {
    marginLeft: 109,
  },
  textareaWrapper: {
    width: '30%',
    marginBottom: 5,
  },
  textarea: {
    width: '100%',
    resize: 'none',
    overflow: 'hidden',
    minHeight: 78,
    borderRadius: 6,
    marginTop: 4,
    padding: 8,
    border: `2px solid ${palette.blue['300']}`,
    '&::placeholder': {
      textTransform: 'capitalize',
      color: palette.gray['300'],
    },
  },
}));

const topHeightToConsider = [
  topElementHeight.minimizingModalMarginTop,
  topElementHeight.headerHeight,
  topElementHeight.tabBarHeight,
  topElementHeight.bodyContainerTopOffset,
];

const TimelineTabIncremented = ({trigger, groupId, timeline}: {trigger: Object, groupId: String, timeline: Object}) => {
  let dateToCheck = null;
  const classes = useStyles();
  const dispatch = useDispatch();
  const timeZoneName = useSelector(getTimeZoneName);
  const customerId = useSelector(getOrganizationId);
  const userId = useSelector(getProfileId);
  const firstName = useSelector(getFirstName);
  const postCommentIsLoading = useSelector(getPostCommentIsLoading);

  const triggeredAlertTimeline = fetchTimelineProvider(trigger?.alertTriggerId);

  const [isCommentFieldDisplay, setIsCommentFieldDisplay] = useState(false);
  const [comment, setComment] = useState('');

  const wrapperRef = useRef(null);
  const commentRef = useRef(null);
  const textareaRef = useRef(null);

  const submitComment = async () => {
    setIsCommentFieldDisplay(false);
    setComment('');

    const payload = [
      {
        type: 'comment',
        alertGroupId: groupId,
        customerId,
        userId,
        text: comment,
      },
    ];
    const result = await sendComment({payload}).promise;
    if (Array.isArray(result) && triggeredAlertTimeline) {
      triggeredAlertTimeline.syncTimelineInCache(result);
    }
  };

  const bodyContainerHeight = useHandleResize(topHeightToConsider);

  useClickOutside(
    textareaRef,
    () => {
      setIsCommentFieldDisplay(false);
    },
    ['comment-send-btn'],
    ['comment-send-btn-class'],
  );

  useEffect(() => {
    if (timeline) {
      scrollTo(wrapperRef, commentRef);
    }
  }, [timeline]);

  const setIsDayGroupHidden = (date) => {
    const isSameDay = getIsBetweenTime(date, dateToCheck, 'day', timeZoneName);

    if (isSameDay) {
      return true;
    }
    dateToCheck = date;
    return false;
  };

  const reportToSegment = () => {
    dispatch(segmentClickEvent({type: 'click', name: `alert-triage-timeline-tab-add-comment`}));
  };

  const addCommentClicked = () => {
    reportToSegment();
    setIsCommentFieldDisplay(true);
  };

  return (
    <div className={classes.container} style={{height: bodyContainerHeight, marginTop: '4px'}}>
      {!Array.isArray(timeline) ? (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90%'}}>
          <Spinner color="#3d4c59" size={SpinnerSizes.XX_BIG_150} />
        </div>
      ) : (
        <>
          <div className={classes.maskTop} />
          <div className={classes.maskBottom} />
          <div className={classes.wrapper} ref={wrapperRef}>
            {timeline?.length > 0 ? (
              <div className={classes.rowsWrapper}>
                <div className={classes.firstLine} />
                {timeline.map((tle, index) => (
                  <Fragment key={tle.id}>
                    <TimelineDayGroup
                      isHidden={setIsDayGroupHidden(tle.dataTime || tle.createdTime)}
                      date={tle.dataTime || tle.createdTime}
                      timeZoneName={timeZoneName}
                      alertStatus={tle.alertStatus}
                    />
                    <TimelineEventIncremented
                      timelineEvent={tle}
                      isLast={index === timeline.length - 1}
                      timeZoneName={timeZoneName}
                      alertType={trigger?.type}
                    />
                  </Fragment>
                ))}
                <div ref={commentRef} className={classes.commentWrapper}>
                  {postCommentIsLoading && (
                    <div style={{marginLeft: '4px'}}>
                      <Spinner color="#3d4c59" size={SpinnerSizes.SMALL_30} />
                    </div>
                  )}
                  {!isCommentFieldDisplay && !postCommentIsLoading && (
                    <Button
                      automationId="cancel"
                      extraClassName={classes.btnSelected}
                      colorSchema={COLORS.TRANSPARENT_BLUE}
                      icon="icn-general16-chat"
                      text="Add a comment"
                      onClick={addCommentClicked}
                    />
                  )}
                  {isCommentFieldDisplay && !postCommentIsLoading && (
                    <div className={classes.textareaWrapper}>
                      <div>
                        <textarea
                          ref={textareaRef}
                          className={classes.textarea}
                          placeholder={`${firstName}'s comments`}
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        />
                      </div>
                      <Button
                        extraClassName="comment-send-btn-class"
                        automationId="sendTimelineComment"
                        text="Send"
                        icon="icn-action16-send"
                        colorSchema={COLORS.BLUE_500}
                        isDisabled={!comment.length}
                        onClick={submitComment}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TimelineTabIncremented;
