// @flow
import React, {PureComponent} from 'react';
import connect from 'react-redux/es/connect/connect';
import moment from 'moment';
import Gauge from 'common/componentsV2/gauges/Gauge';

import {fetchTriggeredAlertsTotalLastDay as fetchTriggeredAlertsTotalLastDayAction} from 'alerts.console/store/actions';
import * as selectors from 'alerts.console/store/selectors';

type PropTypes = {
  selectedItem: Number,
  index: Number,
  description: String,
  // Connect
  value: Number,
  isLoading: Boolean,
  fetchTriggeredAlertsTotalLastDay: Number,
};

export default connect(
  (state) => ({
    value: selectors.getAlertsTriggeredTotalLastDay(state),
    isLoading: selectors.getIsLoadingAlertsTriggeredTotalLastDay(state),
  }),
  {
    fetchTriggeredAlertsTotalLastDay: fetchTriggeredAlertsTotalLastDayAction,
  },
)(
  class AlertsTriggeredGauge extends PureComponent {
    props: PropTypes;

    fetchData = () => {
      const {value, fetchTriggeredAlertsTotalLastDay} = this.props;
      if (value === null) {
        const tsYesterday = moment()
          .subtract(+1, 'days')
          .unix();
        fetchTriggeredAlertsTotalLastDay({startTime: tsYesterday});
      }
    };

    render() {
      const {value, isLoading, description, selectedItem, index} = this.props;
      return (
        <Gauge
          description={description}
          value={value}
          isLoading={isLoading}
          index={index}
          selectedItem={selectedItem}
          fetchData={this.fetchData}
        />
      );
    }
  },
);
