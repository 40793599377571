// @flow
import React from 'react';
import {setMaxNumberToDisplay} from 'common/utils/numbers';
import {getFormattedHumanDuration, getFormattedMDH} from 'common/utils/dateService';
import MetricName from 'metrics/components/metricName/MetricName';
import {ReactComponent as StatusIcon} from 'investigationNewAlertConsole/images/open_alert.svg';

import './MetricsTable.module.scss';

const MetricsTableRowHeader = ({
  metric,
  timeZoneName,
  columns,
  isSingleLine,
}: {
  metric: Object,
  timeZoneName: string,
  columns: Object,
  isSingleLine: Boolean,
}) => {
  const interval = metric?.intervals
    ? metric?.intervals[metric.intervals?.length - 1]
    : metric?.currentAnomalyIntervals[metric?.currentAnomalyIntervals.length - 1];

  const columnsNames = columns.map((i) => i.name);

  return (
    <div styleName="table-row-header">
      <div styleName="col-is-open">{metric?.state === 'open' && <StatusIcon style={{flexShrink: 0}} />}</div>
      {columnsNames.includes('title') && (
        <div styleName="col-title">
          <MetricName isSingleLine={isSingleLine ?? true} isDisableCopy metric={metric} highlightText={null} />
        </div>
      )}

      {/* !!!Do not delete: BE will add this Impact property to metrics in anomalies/metrics API, so it can be displayed in table */}
      {/* {columnsNames.includes('impact') && ( */}
      {/*  <div styleName="col-impact"> */}
      {/*    {metric?.impact ? ( */}
      {/*      <ImpactChip */}
      {/*        impact={metric.impact} */}
      {/*        themeName={themesOptions.small} */}
      {/*        tooltipContent="Impact for this Metric" */}
      {/*      /> */}
      {/*    ) : ( */}
      {/*      <span>No Impact</span> */}
      {/*    )} */}
      {/*  </div> */}
      {/* )} */}

      {columnsNames.includes('startTime') && (
        <div styleName="col-startTime">{getFormattedMDH(interval.startTime, timeZoneName)}</div>
      )}

      {columnsNames.includes('duration') && (
        <div styleName="col-duration">{getFormattedHumanDuration(metric.duration)}</div>
      )}

      {columnsNames.includes('delta') && metric.delta && (
        <div styleName="col-delta">
          {metric.upperAbsoluteDelta && (
            <div styleName="col-upperAbsoluteDelta">
              <i className="icon icn-arrow16-arrowup" style={{marginLeft: -4}} />
              {setMaxNumberToDisplay(metric.upperAbsoluteDelta * Math.sign(metric.upperPercentageDelta), 1000, 1)}
            </div>
          )}
          {metric.lowerAbsoluteDelta && (
            <div styleName="col-lowerAbsoluteDelta">
              <i className="icon icn-arrow16-arrowdown" style={{marginLeft: -4}} />
              {setMaxNumberToDisplay(metric.lowerAbsoluteDelta * Math.sign(metric.lowerPercentageDelta), 1000, 1)}
            </div>
          )}
        </div>
      )}

      {columnsNames.includes('score') && <div styleName="col-score">{Math.round(interval.score * 100)}</div>}
    </div>
  );
};

export default MetricsTableRowHeader;
