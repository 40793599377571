import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {clearNotifications} from 'common/store/actions';
import {TYPES as NOTIFICATION_TYPES} from 'common/componentsV2/snackBar/SnackBar';
import * as commonSelector from 'common/store/selectors';
import {ReactComponent as NotificationError} from 'app/images/notification-error.svg';
import {ReactComponent as NotificationWarning} from 'app/images/notification-warning.svg';
import {ReactComponent as NotificationInfo} from 'app/images/notification-info.svg';
import {ReactComponent as NotificationSuccess} from 'app/images/notification-success.svg';
import Button, {COLORS, HEIGHTS} from 'common/componentsV2/Button';
import {ToolTipWrapper} from '@pileus-cloud/anodot-frontend-common';
import {makeStyles} from '@material-ui/core';

import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {ReactComponent as EmptyIcon} from 'app/images/empty-dropdown.svg';

const useStyles = makeStyles((theme) => ({
  menu: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 8,
  },
  text: {
    flex: 1,
  },
  wrapper: {
    display: 'block',
    opacity: 1,
    position: 'fixed',
    left: 289,
    bottom: 20,
    zIndex: 1069,
    height: 220,
    backgroundColor: theme.palette.white[500],
    color: theme.palette.gray[500],
    overflow: 'hidden',
    boxShadow: '0 4px 14px -1px rgba(0, 0, 0, 0.15)',
    borderRadius: 6,
    whiteSpace: 'nowrap',
    textAlign: 'left',
    cursor: 'auto',
  },
  emptyListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  icon: {
    marginRight: 8,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: 270,
    minHeight: 150,
    maxHeight: 350,
  },
  list: {
    overflow: 'hidden',
    overflowY: 'auto',
    flex: 1,
  },
  notificationRow: {
    height: 30,
    padding: '0 12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 400,
    '&:hover': {
      backgroundColor: theme.palette.blue[100],
    },
  },
  notificationTitle: {
    width: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: -12,
    fontSize: 12,
    fontWeight: 500,
    borderTop: `1px solid ${theme.palette.gray[300]}`,
  },
  tooltip: {
    textAlign: 'start',
  },
}));

const NavbarNotifications = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const notifications = useSelector(commonSelector.getNotifications);

  const notificationToolTip = (notification) => {
    return (
      <div className={classes.tooltip}>
        <div>{notification.title}</div>
        <div>{notification.description}</div>
      </div>
    );
  };

  const renderTypeIcon = (type) => {
    switch (type) {
      case NOTIFICATION_TYPES.ERROR:
        return <NotificationError />;
      case NOTIFICATION_TYPES.WARNING:
        return <NotificationWarning />;
      case NOTIFICATION_TYPES.INFO:
        return <NotificationInfo />;
      case NOTIFICATION_TYPES.SUCCESS:
        return <NotificationSuccess />;
      default:
        return null;
    }
  };

  const nothingToShow = (
    <div className={classes.emptyListContainer}>
      <EmptyIcon width={101} height={71} />
      <TypographyBox variant="subtitle2" marginTop={2}>
        No new notifications
      </TypographyBox>
    </div>
  );

  return (
    <div className={classes.content}>
      <div className={classes.list}>
        {notifications.length === 0 ? (
          nothingToShow
        ) : (
          <>
            {notifications.map((notification, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={classes.notificationRow} key={`${notification.title}-${index}`}>
                  <div className={classes.notificationTitle}>
                    <ToolTipWrapper content={notificationToolTip(notification)}>
                      <span>{notification.title}</span>
                    </ToolTipWrapper>
                  </div>
                  {renderTypeIcon(notification.type)}
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className={classes.footer}>
        <Button
          height={HEIGHTS.TIGHT28}
          text="Mark as read"
          colorSchema={COLORS.TRANSPARENT_BLUE}
          onClick={() => {
            dispatch(clearNotifications());
          }}
          isDisabled={notifications.length === 0}
        />
      </div>
    </div>
  );
};

export default NavbarNotifications;
