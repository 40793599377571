// @flow
import React from 'react';
import ConceptsPanelHeader from './ConceptsPanelHeader';
import ConceptPanel from './ConceptPanel';

import './ConceptsPanel.module.scss';

const ConceptsPanel = ({title, possibleConcepts, selectedConcepts, onAddItem, onRemoveItem}: any) => (
  <div styleName="panel">
    <ConceptsPanelHeader
      {...{
        title,
        onAddItem,
        onRemoveItem,
        concepts: possibleConcepts,
      }}
    />
    <div styleName="scroller">
      {selectedConcepts
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(({sourceColumn, type, name}) => (
          <ConceptPanel
            key={sourceColumn}
            {...{
              sourceColumn,
              type,
              name,
              onRemoveItem,
            }}
          />
        ))}
    </div>
  </div>
);

export default ConceptsPanel;
