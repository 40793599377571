// @flow
import React, {useCallback} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import {ALERT_STATUS} from 'alerts.console/services/alertsConsoleService';
import SwitchButton from 'common/componentsV2/SwitchButton';

const AlertStatusFilter = () => {
  const [status, setStatus] = useQueryParam('status', StringParam);
  const toggleFilter = useCallback(
    (openOnly) => {
      setStatus(openOnly ? ALERT_STATUS[1].value : ALERT_STATUS[0].value); // undefined
    },
    [setStatus],
  );

  return (
    <div style={{display: 'flex', alignItems: 'center'}}>
      <SwitchButton
        isChecked={status === ALERT_STATUS[1].value}
        automationId="alertStatusFilter"
        onChange={toggleFilter}
      />
      <span style={{marginLeft: 7}}>Open Alerts</span>
    </div>
  );
};

export default AlertStatusFilter;
