export const salesforceLogin = (item, createDataSource, me, staging) => {
  const width = 500;
  const height = 600;
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;
  const state = me.ownerOrganization || 'no-org-id';
  let redirectURI = `${window.location.origin}/api/v1/signin/oauth/salesforce/callback`;

  if (window.location.origin.indexOf('localhost:') === -1) {
    const baseDnsName = me.baseDNSName === 'anodot-test.com' ? 'ano-dev.com' : me.baseDNSName;
    redirectURI = `https://${me.clusterDNSPrefix}.${baseDnsName}/api/v1/signin/oauth/salesforce/callback`;
  }

  const url = `https://${
    staging ? 'test' : 'login'
  }.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${
    item.clientId
  }&redirect_uri=${redirectURI}&display=popup&state=${state}`;
  const title = 'login';

  window.open(
    url,
    title,
    // eslint-disable-next-line max-len
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`,
  );
  window.createDataSource = function(token) {
    createDataSource({
      code: token,
      staging,
      type: 'salesforce',
      name: `Salesforce Data Source ${new Date().getTime().toString()}`,
    });
  };
};

export const getEditorValidationMessage = (stream = false) => {
  const res = [];
  if (!stream.dimensions.length) {
    res.push('At least one dimension is required');
  }
  return res[0];
};
