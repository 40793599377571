import React, {useState, useCallback, useEffect, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {types} from 'admin.permissions/services/accessListService';
import {getAccessListByGroupsIsLoading, getSourcesByGroups} from 'admin.permissions/store/selectors';
import {getGroupsModalEditingGroupId} from 'admin.users/store/selectors';
import {getDataStreams, getDataSourcesItems} from 'bc/store/selectors';
import {fetchAccessListByGroups} from 'admin.permissions/store/actions';
import {fetchDataSources, fetchDataStreams, fetchLookupTables} from 'bc/store/actions';

import {TinyScrollBox} from 'common/componentsV2/boxTools';
import {ExpandableBox} from 'common/componentsV2/ExpandableSections';
import {getSourceIcon} from 'bc/services/dataManagerService';
import {makeStyles} from '@material-ui/core/styles';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import Tooltip from 'common/componentsV2/Tooltip';

const useStyle = makeStyles(({palette}) => ({
  sourceWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    '@global': {
      svg: {
        transform: 'scale(0.4)',
      },
    },
  },
  sourceName: {
    width: '80%',
    position: 'relative',
    top: -36,
    left: -5,
  },
  sourceIcon: {
    position: 'relative',
    left: 0,
    top: -16,
  },

  streamWrapper: {
    position: 'relative',
    top: -40,
    left: 45,
    width: '90%',
    '&:hover $streamLink': {
      display: 'inline-block',
    },
    '&:hover ': {
      background: palette.blue['100'],
    },
    borderRadius: 4,

    padding: 4,
  },
  stream: {
    cursor: 'default',
    marginRight: 8,
  },
  streamLink: {
    position: 'relative',
    top: 2,
    cursor: 'pointer',
    display: 'none',
    color: palette.blue['500'],
  },
  loaderWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  externalLinks: {
    color: palette.blue['500'],
    textTransform: 'capitalize',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
  },
}));

const GroupAccessSection = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const groupId = useSelector(getGroupsModalEditingGroupId);
  const dataSources = useSelector(getDataSourcesItems);
  const isAccessListLoading = useSelector(getAccessListByGroupsIsLoading);
  const isDataStreamLoading = useSelector(getDataStreams).streams.isLoading;
  const sourcesByGroups = useSelector(getSourcesByGroups);
  const [isExpandItems, setIsExpandItems] = useState({});

  const onExpandBoxChange = useCallback(
    (type) => (isExpanded) => {
      setIsExpandItems((prevState) => ({...prevState, [type]: isExpanded}));
    },
    [],
  );

  const calculateItemsNumber = (typeData) => {
    let counter = 0;
    Object.keys(typeData).forEach((key) => {
      counter += typeData[key].length;
    });
    return counter;
  };

  const goTo = (streamId) => {
    const {origin} = window.location;
    window.open(`${origin}/#!/r/bc/data-manager?searchQuery=${streamId}&expandAll=true`);
  };

  useEffect(() => {
    dispatch(fetchDataSources());
    dispatch(fetchDataStreams());
    dispatch(fetchLookupTables());
    dispatch(fetchAccessListByGroups({groupsIds: [groupId]}, {type: types.stream}));
  }, []);

  const renderHeaders = (type) => {
    let linkAddress = '';
    switch (type) {
      case 'STREAM':
        linkAddress = '#!/r/bc/data-manager';
        break;
      case 'CHANNEL':
        linkAddress = '#!/r/channels';
        break;
      case 'COMPOSITE':
        linkAddress = '#!/metrics/composite/management/';
        break;
      default:
    }
    const typeLink = (
      <a
        className={classes.externalLinks}
        href={`${window.location.origin}/${linkAddress}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        {`${type.toLowerCase()}s`}
      </a>
    );

    return (
      <div automationId={`${type}-group-header`} className={classes.headerContainer}>
        <h5 style={{textTransform: 'capitalize', position: 'relative', top: -4}}>
          {calculateItemsNumber(sourcesByGroups[type])} {`${type.toLowerCase()}s`}
        </h5>
        {typeLink}
      </div>
    );
  };

  return (
    <div style={{height: '100%'}}>
      <TinyScrollBox width="100%" pr={1.25} height={1} css={{overflowY: 'auto', overflowX: 'hidden'}}>
        {(isAccessListLoading || isDataStreamLoading) && (
          <div className={classes.loaderWrapper}>
            <Spinner color="gray.500" size={SIZES.MEDIUM_50} />
          </div>
        )}
        {!isAccessListLoading && !isDataStreamLoading && (
          <Fragment>
            {Object.keys(sourcesByGroups).map((type) => (
              <ExpandableBox
                key={type}
                automationId="incWhatGroup"
                nodeLabel={renderHeaders(type)}
                expanded={isExpandItems[type]}
                onChange={onExpandBoxChange(type)}
                rootStyle={{
                  flexShrink: 0,
                  padding: 0,
                  border: 'none',
                  marginTop: 8,
                  marginBottom: 16,
                }}
              >
                <Fragment>
                  {Object.keys(sourcesByGroups[type]).map((id) => (
                    <Fragment key={id}>
                      <div className={classes.sourceWrapper}>
                        <span className={classes.sourceIcon}>
                          {getSourceIcon(dataSources.find((i) => i.id === id).type)}
                        </span>
                        <h5 className={`ellipsis ${classes.sourceName}`}>
                          {dataSources.find((i) => i.id === id).name}
                        </h5>
                      </div>

                      <Fragment>
                        {sourcesByGroups[type][id].map((stream) => (
                          <div className={classes.streamWrapper}>
                            <Tooltip content={stream.name ? stream.name : stream.id}>
                              <span className={`ellipsis ${classes.stream}`} key={stream.id}>
                                {stream.name ? stream.name : `Unnamed stream (${stream.id})`}
                              </span>
                            </Tooltip>
                            <i
                              className={`${classes.streamLink} icon icn-nav16-goto`}
                              onClick={() => goTo(stream.id)}
                            />
                          </div>
                        ))}
                      </Fragment>
                    </Fragment>
                  ))}
                </Fragment>
              </ExpandableBox>
            ))}
          </Fragment>
        )}
      </TinyScrollBox>
    </div>
  );
};

GroupAccessSection.propTypes = {};

export default GroupAccessSection;
