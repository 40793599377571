// @flow
import React from 'react';
import useInput from './useInput';

export default function Radio({options, onSelect, groupName}: {options: Array, onSelect: Function, groupName: String}) {
  const [option, setOption] = useInput('', onSelect);

  return (
    <form>
      {options.map((i) => (
        <label key={i.value}>
          <input type="radio" name={groupName} value={i.value} checked={option === i.value} onChange={setOption} />
          <span style={{opacity: option === i.value ? '1' : '.7'}}>{i.title}</span>
        </label>
      ))}
    </form>
  );
}
