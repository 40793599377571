import {useCallback} from 'react';
import {useQuery} from 'react-query';
import {get} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'reactQuery/queryKeys';

const root = 'api/v1/bc/';

const getProjects = async (type, sourceId) => {
  return get(`${root}data-sources/${type}/${sourceId}/projects`);
};

export default function useFetchProjects(type, sourceId) {
  const fallback = [];

  const selectFn = useCallback(
    (data) => {
      return data.map((i) => ({...i, value: i.projectId, label: i.name}));
    },
    [sourceId],
  );

  const {
    data: projects = fallback,
    isLoading: isProjectsLoading,
    isFetching: isProjectsFetching,
    refetch: refetchProjectsFetching,
  } = useQuery(QUERY_KEYS.projects, () => getProjects(type, sourceId), {
    enabled: !!(type && sourceId),
    select: selectFn,
  });

  return {projects, isProjectsLoading, isProjectsFetching, refetchProjectsFetching};
}
