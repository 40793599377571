import React from 'react';
import Accounts from './accounts/Accounts';
import GA4Scheduler from './schedular/GA4Schedular';
import DimensionsAndMetrics from './dimensionsAndMetrics/DimensionsAndMetrics';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

const GoogleGA4StreamEditor = () => (
  <div automation-id="googleAnalyticsStreamEditor" className="shell-col">
    <StreamEditorSteps>
      <Accounts />
      <DimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <GA4Scheduler />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default GoogleGA4StreamEditor;
