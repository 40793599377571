// @flow
/* global d3 */
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {renderToString} from 'react-dom/server';
import {useSelector} from 'react-redux';
import {useQueryParams} from 'use-query-params';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {palette} from 'app/styles/theme';
import {getOrgTopologySetting} from 'profile/store/selectors';
import L from 'topologyGeneral/services/d3SvgOverlayService';
import {QUERY_PARAM_MAP} from 'topologyGeneral/services/sidePanelService';
import * as mapService from 'topologyGeneral/services/mapService';
import {
  CentralIcon,
  LeftWing,
  RightWing,
  SITE_TYPES,
  TopWing,
  WingMaskDef,
} from 'topologyGeneral/components/mapNodeIcons/SiteSvg';
import RegionTooltip from 'topologyGeneral/components/tooltips/RegionTooltip';
import AzimuthTooltip from 'topologyGeneral/components/tooltips/AzimuthTooltip';
import SiteTooltip from 'topologyGeneral/components/tooltips/SiteTooltip';
import NodesTooltip from 'topologyGeneral/components/tooltips/NodesTooltip';
import LinkTooltip from 'topologyGeneral/components/tooltips/LinkTooltip';
import './GeneralTopologyMap.module.scss';

const EMPTY_ARRAY = [];

const TOOLTIP_TYPES = {
  region: {
    id: 'region',
    width: 204,
  },
  site: {
    id: 'site',
    width: 305,
  },
  azimut: {
    id: 'azimut',
    width: 285,
  },
  nodes: {
    id: 'nodes',
    width: 305,
  },
  links: {
    id: 'links',
    width: 258,
  },
};

const REGION_NODE_HEIGHT = {
  level1: 36,
  level2: 52,
  level3: 68,
};

const SIDE_NODE = {
  height: 85,
  width: 85,
};

const LABEL_PADDING = 4;
const LABEL_RADIUS = 6;
const LABEL_FONT_SIZE = 14;
const LABEL_HEIGHT = 20;

const SEVERITY_FONT_SIZE = 12;

const hideTooltip = (sel, proj) => {
  proj.map.scrollWheelZoom.enable();
  const tooltipContainer = sel.select('.tooltip-container');
  tooltipContainer.style('visibility', 'hidden');
};

const createForeignObjectTooltip = (sel, proj, d, type, tooltipData, curserLatLng) => {
  let tooltipContainer = sel.select('.tooltip-container');

  const ttContainerInit = (htmlNode) => {
    tooltipContainer.html(renderToString(htmlNode)).attr('width', 200);

    if (type === TOOLTIP_TYPES.links.id) {
      tooltipContainer.attr(
        'transform',
        `translate(${proj.latLngToLayerPointNoRounding(curserLatLng).x},${
          proj.latLngToLayerPointNoRounding(curserLatLng).y
        }) scale(${1 / proj.scale})`,
      );
    } else {
      tooltipContainer.attr(
        'transform',
        `translate(${proj.latLngToLayerPointNoRounding(d.geoPoint).x},${
          proj.latLngToLayerPointNoRounding(d.geoPoint).y
        }) scale(${1 / proj.scale})`,
      );
    }

    const innerTp = tooltipContainer.select('.gtp-tp-wrapper');
    const innerTpNode = innerTp.node();
    const height = innerTpNode ? innerTpNode.getBoundingClientRect().height : 100;

    tooltipContainer.attr('height', height);
    return {
      height,
    };
  };

  if (!d) {
    tooltipContainer.style('visibility', 'hidden');
    return;
  }
  if (!tooltipContainer.node()) {
    tooltipContainer = sel.append('foreignObject').classed('tooltip-container', true);
  }

  proj.map.scrollWheelZoom.disable();
  tooltipContainer
    .style('border-radius', '6px')
    .style('background-color', palette.white['500'])
    .style('box-shadow', '0px 3px 10px rgba(0, 0, 36, 0.25)');

  switch (type) {
    case TOOLTIP_TYPES.region.id: {
      const {height} = ttContainerInit(<RegionTooltip item={d} />);
      tooltipContainer.attr('width', TOOLTIP_TYPES.region.width);
      tooltipContainer.attr('y', -(height + REGION_NODE_HEIGHT.level1 / 2));
      tooltipContainer.attr('x', REGION_NODE_HEIGHT.level1 / 2);
      break;
    }
    case TOOLTIP_TYPES.azimut.id: {
      const {height} = ttContainerInit(<AzimuthTooltip cells={tooltipData.cells} nodes={tooltipData.nodes} />);
      tooltipContainer.attr('width', TOOLTIP_TYPES.azimut.width);
      tooltipContainer.attr('y', -(height + (SIDE_NODE.height + 4) / 2));
      tooltipContainer.attr('x', -TOOLTIP_TYPES.azimut.width / 2);
      break;
    }
    case TOOLTIP_TYPES.site.id: {
      const {height} = ttContainerInit(<SiteTooltip site={d} />);
      tooltipContainer.attr('width', TOOLTIP_TYPES.site.width);
      tooltipContainer.attr('y', -(height + (SIDE_NODE.height + 4) / 2));
      tooltipContainer.attr('x', -TOOLTIP_TYPES.site.width / 2);
      break;
    }
    case TOOLTIP_TYPES.nodes.id: {
      const {height} = ttContainerInit(
        <NodesTooltip nodes={tooltipData.nodes} cells={tooltipData.cells} regionName={tooltipData.regionName} />,
      );
      tooltipContainer.attr('width', TOOLTIP_TYPES.nodes.width);
      tooltipContainer.attr('y', -(height + (SIDE_NODE.height + 4) / 2));
      tooltipContainer.attr('x', -TOOLTIP_TYPES.nodes.width / 2);
      break;
    }
    case TOOLTIP_TYPES.links.id: {
      const {height} = ttContainerInit(<LinkTooltip item={d} />);
      tooltipContainer.attr('width', TOOLTIP_TYPES.links.width);
      tooltipContainer.attr('y', -(height + 10));
      tooltipContainer.attr('x', -TOOLTIP_TYPES.links.width / 2);
      break;
    }
    default:
  }

  tooltipContainer.style('visibility', 'visible');
};

const createRegions = (d, index, regions) => {
  const g = d3.select(regions[index]);

  g.append('rect');

  g.append('circle').classed('c-level-3', true);
  g.append('circle').classed('c-level-2', true);
  g.append('circle').classed('c-level-1', true);

  g.append('text')
    .classed('sev-percent', true)
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .style('font-weight', '500')
    .style('fill', palette.white['500']);

  g.append('text')
    .classed('reg-name', true)
    .text(d.title)
    .attr('text-anchor', 'middle')
    .attr('alignment-baseline', 'middle')
    .style('font-weight', '500')
    .style('fill', palette.gray['500']);
};

const updateRegions = (d, index, regions, proj) => {
  const g = d3.select(regions[index]);
  g.style('opacity', d.unSelected ? 0.5 : 1);

  const border = g
    .select('rect')
    .attr('y', REGION_NODE_HEIGHT.level3 / 2 / proj.scale)
    .attr('height', LABEL_HEIGHT / proj.scale)
    .attr('rx', LABEL_RADIUS / proj.scale)
    .attr('ry', LABEL_RADIUS / proj.scale)
    .style('fill', palette.white['500']);

  const label = g
    .select('.reg-name')
    .attr('y', (REGION_NODE_HEIGHT.level3 + LABEL_HEIGHT + 3) / 2 / proj.scale)
    .attr('height', LABEL_HEIGHT / proj.scale)
    .style('font-size', LABEL_FONT_SIZE / proj.scale);

  g.select('.sev-percent')
    .text(`${d.percent}%`)
    .style('font-size', SEVERITY_FONT_SIZE / proj.scale);

  const circleBaseColor = mapService.getBadgeColor(d);
  g.select('.c-level-1')
    .attr('r', REGION_NODE_HEIGHT.level1 / 2 / proj.scale)
    .attr('cx', 0)
    .attr('cy', -1 / proj.scale)
    .attr('stroke-width', 2 / proj.scale)
    .style('fill', circleBaseColor);

  g.select('.c-level-2')
    .attr('r', REGION_NODE_HEIGHT.level2 / 2 / proj.scale)
    .attr('cx', 0)
    .attr('cy', -1 / proj.scale)
    .attr('stroke-width', 2 / proj.scale)
    .style('fill', circleBaseColor)
    .style('opacity', 0.25);

  g.select('.c-level-3')
    .attr('r', REGION_NODE_HEIGHT.level3 / 2 / proj.scale)
    .attr('cx', 0)
    .attr('cy', -1 / proj.scale)
    .attr('stroke-width', 2 / proj.scale)
    .style('fill', circleBaseColor)
    .style('opacity', 0.1);

  const box = label.node().getBBox();
  const width = box.width + (LABEL_PADDING * 2) / proj.scale;
  border.attr('x', -width / 2).attr('width', width);
};

const renderRegions = (sel, proj, data, onClick, onMouseEvent) => {
  const regionsLayer = sel.select('.regions-layer');

  const allRegions = regionsLayer.selectAll('.region').data(data, (d) => (d ? d.id : this.id));

  const entered = allRegions
    .enter()
    .append('g')
    .attr('id', (d) => d.id)
    .classed('region', true)
    .style('cursor', 'pointer')
    .on('click', (d) => {
      onClick(sel, proj, d);
    })
    .on('mouseenter', (d) => {
      const reg = d3.select(`#${d.id}`);
      if (d.unSelected) {
        reg.style('opacity', 1);
      }
      d3.select(`#${d.id} .reg-name`).style('fill', palette.blue['500']);
      createForeignObjectTooltip(sel, proj, d, TOOLTIP_TYPES.region.id);
      onMouseEvent('mouseenter', d);
    })
    .on('mouseleave', (d) => {
      const reg = d3.select(`#${d.id}`);
      if (d.unSelected) {
        reg.style('opacity', 0.5);
      }
      d3.select(`#${d.id} .reg-name`).style('fill', palette.gray['500']);
      onMouseEvent('mouseleave', d);
      hideTooltip(sel, proj);
    });

  entered.each(createRegions);

  entered
    .merge(allRegions)
    .attr(
      'transform',
      (d) =>
        `translate(${proj.latLngToLayerPointNoRounding(d.geoPoint).x},${
          proj.latLngToLayerPointNoRounding(d.geoPoint).y
        })`,
    )
    .each((d, i, n) => updateRegions(d, i, n, proj));

  allRegions.exit().remove();
};

const createSites = (d, index, sites, proj, sel) => {
  const g = d3.select(sites[index]);

  const gg = g.append('g').classed('site-svg', true);

  gg.append('g')
    .classed('central-icon', true)
    .on('mouseenter', () => {
      createForeignObjectTooltip(sel, proj, d, TOOLTIP_TYPES.nodes.id, {
        nodes: d.nodes,
        cells: d.cells,
        regionName: d.parentRegionName,
      });
    })
    .on('mouseleave', () => {
      hideTooltip(sel, proj);
    });

  gg.append('g')
    .classed('left-wing', true)
    .on('mouseenter', () => {
      createForeignObjectTooltip(sel, proj, d, TOOLTIP_TYPES.azimut.id, {
        cells: d.cells.leftWing,
        nodes: d.nodes,
      });
    })
    .on('mouseleave', () => {
      hideTooltip(sel, proj);
    });

  gg.append('g')
    .classed('right-wing', true)
    .on('mouseenter', () => {
      createForeignObjectTooltip(sel, proj, d, TOOLTIP_TYPES.azimut.id, {
        cells: d.cells.rightWing,
        nodes: d.nodes,
      });
    })
    .on('mouseleave', () => {
      hideTooltip(sel, proj);
    });

  gg.append('g')
    .classed('top-wing', true)
    .on('mouseenter', () => {
      createForeignObjectTooltip(sel, proj, d, TOOLTIP_TYPES.azimut.id, {
        cells: d.cells.topWing,
        nodes: d.nodes,
      });
    })
    .on('mouseleave', () => {
      hideTooltip(sel, proj);
    });

  g.append('rect').classed('site-name-rect', true);

  g.append('text')
    .classed('site-name', true)
    .text(d.title)
    .attr('text-anchor', 'start')
    .attr('alignment-baseline', 'middle')
    .style('font-weight', '500')
    .style('fill', palette.gray['500'])
    .on('mouseenter', () => {
      createForeignObjectTooltip(sel, proj, d, TOOLTIP_TYPES.site.id);
    })
    .on('mouseleave', () => {
      hideTooltip(sel, proj);
    });
};

const updateSites = (d, index, sites) => {
  const g = d3.select(sites[index]);
  let centralIconType = SITE_TYPES.multi;
  if (d.nodes.length === 1) {
    centralIconType = mapService.getSiteCentralIcon(d.nodes[0].keywords);
  }

  g.select('.central-icon').html(
    renderToString(
      <CentralIcon
        color={mapService.getBadgeColor(d, d.unSelected || d.isDomainSelected === false)}
        type={centralIconType}
      />,
    ),
  );

  const leftWingColorArr = d.cells.leftWing.slice(0, 5).map((c) => {
    const colorText = c.isError ? 'red' : 'green';
    const optPrefix = d.unSelected || d.isDomainSelected === false ? 'op' : '';
    if (optPrefix) {
      return mapService.MAP_ITEMS_COLOR_PALETTE[optPrefix + colorText[0].toUpperCase() + colorText.substring(1)];
    }
    return mapService.MAP_ITEMS_COLOR_PALETTE[colorText];
  });
  g.select('.left-wing').html(
    renderToString(<LeftWing level={Math.min(5, d.cells.leftWing.length)} colorArr={leftWingColorArr} />),
  );

  const rightWingColorArr = d.cells.rightWing.slice(0, 5).map((c) => {
    const colorText = c.isError ? 'red' : 'green';
    const optPrefix = d.unSelected || d.isDomainSelected === false ? 'op' : '';
    if (optPrefix) {
      return mapService.MAP_ITEMS_COLOR_PALETTE[optPrefix + colorText[0].toUpperCase() + colorText.substring(1)];
    }
    return mapService.MAP_ITEMS_COLOR_PALETTE[colorText];
  });
  g.select('.right-wing').html(
    renderToString(<RightWing level={Math.min(5, d.cells.rightWing.length)} colorArr={rightWingColorArr} />),
  );

  const topWingColorArr = d.cells.topWing.slice(0, 5).map((c) => {
    const colorText = c.isError ? 'red' : 'green';
    const optPrefix = d.unSelected || d.isDomainSelected === false ? 'op' : '';
    if (optPrefix) {
      return mapService.MAP_ITEMS_COLOR_PALETTE[optPrefix + colorText[0].toUpperCase() + colorText.substring(1)];
    }
    return mapService.MAP_ITEMS_COLOR_PALETTE[colorText];
  });
  g.select('.top-wing').html(
    renderToString(<TopWing level={Math.min(5, d.cells.topWing.length)} colorArr={topWingColorArr} />),
  );

  const border = g
    .select('.site-name-rect')
    .attr('y', SIDE_NODE.height - LABEL_HEIGHT - 5)
    .attr('height', LABEL_HEIGHT)
    .attr('rx', LABEL_RADIUS)
    .attr('ry', LABEL_RADIUS)
    .style('opacity', d.unSelected || d.isDomainSelected === false ? 0.3 : 1)
    .style('fill', palette.white['500']);

  const label = g
    .select('.site-name')
    .attr('y', SIDE_NODE.height - LABEL_HEIGHT - 5 + (LABEL_HEIGHT / 2 + 2))
    .attr('height', LABEL_HEIGHT)
    .style('opacity', d.unSelected || d.isDomainSelected === false ? 0.3 : 1)
    .style('fill', d.selected ? palette.blue['500'] : palette.gray['500'])
    .style('font-size', LABEL_FONT_SIZE);

  const box = label.node().getBBox();
  const width = box.width + LABEL_PADDING * 2;
  const delta = SIDE_NODE.width - width;

  label.attr('x', delta / 2 + LABEL_PADDING);
  border.attr('width', width).attr('x', delta / 2);
};

const renderSites = (sel, proj, data, onClick, onMouseEvent) => {
  const sitesLayer = sel.select('.sites-layer');

  const allSites = sitesLayer
    .selectAll('.site')
    .data(data, (d) => (d ? d.id : this.id))
    .order();

  const entered = allSites
    .enter()
    .append('g')
    .attr('id', (d) => d.id)
    .classed('site', true)
    .style('cursor', 'pointer')
    .on('click', (d) => {
      onClick(sel, proj, d);
    })
    .on('mouseenter', (d) => {
      const siteD3 = d3.select(`#${d.id}`);
      const siteName = siteD3.select('.site-name').style('fill', palette.blue['500']);
      if (d.unSelected || d.isDomainSelected === false) {
        siteD3.select('.site-name-rect').style('opacity', 1);
        siteName.style('opacity', 1);

        siteD3.selectAll('.central-icon-fill').attr('fill', mapService.getBadgeColor(d));
        siteD3.selectAll('.wing').attr('fill', (inD, i, n) => {
          const curColor = n[i].attributes.fill.value;
          switch (curColor) {
            case mapService.MAP_ITEMS_COLOR_PALETTE.opGreen:
              return mapService.MAP_ITEMS_COLOR_PALETTE.green;
            case mapService.MAP_ITEMS_COLOR_PALETTE.opRed:
              return mapService.MAP_ITEMS_COLOR_PALETTE.red;
            default:
              return mapService.MAP_ITEMS_COLOR_PALETTE.green;
          }
        });
      }

      onMouseEvent('mouseenter', d);
    })
    .on('mouseleave', (d) => {
      const siteD3 = d3.select(`#${d.id}`);
      const siteName = siteD3.select('.site-name');
      siteName.style('fill', d.selected ? palette.blue['500'] : palette.gray['500']);

      if (d.unSelected || d.isDomainSelected === false) {
        siteD3.select('.site-name-rect').style('opacity', 0.3);
        siteName.style('opacity', 0.3);

        siteD3.selectAll('.central-icon-fill').attr('fill', mapService.getBadgeColor(d, true));
        siteD3.selectAll('.wing').attr('fill', (inD, i, n) => {
          const curColor = n[i].attributes.fill.value;
          switch (curColor) {
            case mapService.MAP_ITEMS_COLOR_PALETTE.green:
              return mapService.MAP_ITEMS_COLOR_PALETTE.opGreen;
            case mapService.MAP_ITEMS_COLOR_PALETTE.red:
              return mapService.MAP_ITEMS_COLOR_PALETTE.opRed;
            default:
              return mapService.MAP_ITEMS_COLOR_PALETTE.opGreen;
          }
        });
      }
      onMouseEvent('mouseleave', d);
    });

  entered.each((d, i, n) => createSites(d, i, n, proj, sel));

  entered
    .merge(allSites)
    .attr(
      'transform',
      (d) =>
        `translate(${proj.latLngToLayerPointNoRounding(d.geoPoint).x -
          SIDE_NODE.width / 2 / proj.scale},${proj.latLngToLayerPointNoRounding(d.geoPoint).y -
          SIDE_NODE.height / 2 / proj.scale}) scale(${1 / proj.scale})`,
    )
    .each(updateSites);

  allSites.exit().remove();
};

const linkPathSimple = (link) => {
  const {fX, fY, tX, tY} = link;
  return `M ${fX},${fY} L ${tX},${tY}`;
};

const updateLink = (d, index, links, proj) => {
  const g = d3.select(links[index]);
  g.style('opacity', d.unSelected ? 0.3 : 1);

  g.select('.outer-path')
    .style('stroke-width', 4 / proj.scale)
    .style('stroke', 'transparent');
  g.select('.visual-path')
    .style('stroke-width', 2 / proj.scale)
    .style('stroke', d.isError ? mapService.MAP_LINK_COLOR.error : mapService.MAP_LINK_COLOR.regular);
};

const renderLinks = (sel, proj, data, curserLatLng) => {
  const linksLayer = sel.select('.links-layer');

  const processedLinks = data.map((d) => ({
    ...d,
    fX: proj.latLngToLayerPointNoRounding(d.sourceNodeGeoLocation).x,
    fY: proj.latLngToLayerPointNoRounding(d.sourceNodeGeoLocation).y,
    tX: proj.latLngToLayerPointNoRounding(d.destNodeGeoLocation).x,
    tY: proj.latLngToLayerPointNoRounding(d.destNodeGeoLocation).y,
  }));

  const allLinks = linksLayer.selectAll('.link').data(processedLinks, (d) => (d ? d.id : this.id));

  const entered = allLinks
    .enter()
    .append('g')
    .attr('id', (d) => d.id)
    .classed('link', true)
    .style('cursor', 'pointer')
    .on('mouseenter', (d) => {
      const l = d3.select(`#${d.id}`);
      if (d.unSelected) {
        l.style('opacity', 1);
      }
      createForeignObjectTooltip(sel, proj, d, TOOLTIP_TYPES.links.id, null, curserLatLng);
    })
    .on('mouseleave', (d) => {
      const l = d3.select(`#${d.id}`);
      if (d.unSelected) {
        l.style('opacity', 0.3);
      }
      hideTooltip(sel, proj);
    });

  entered
    .append('path')
    .classed('outer-path', true)
    .style('fill', 'none')
    .attr('d', linkPathSimple);

  entered
    .append('path')
    .classed('visual-path', true)
    .style('fill', 'none')
    .attr('d', linkPathSimple);

  entered.merge(allLinks).each((d, i, n) => updateLink(d, i, n, proj));

  allLinks.exit().remove();
};

const GeneralTopologyMap = ({
  getMapRef,
  regions,
  sites,
  links,
  onRegionNodeClick,
  onRegionNodeMouseEvent,
  onSiteNodeClick,
  onSiteNodeMouseEvent,
}: PropTypes) => {
  const [queryParams] = useQueryParams(QUERY_PARAM_MAP);

  const isInitialMount = useRef(true);
  const projectionRef = useRef(null);
  const selectionRef = useRef(null);
  const cursorLatLngRef = useRef(null);

  // map items ref - start
  const regionsRef = useRef(EMPTY_ARRAY);
  const sitesRef = useRef(EMPTY_ARRAY);
  const linksRef = useRef(EMPTY_ARRAY);
  // map items ref - end

  const [lMap, setLMap] = useState(null);
  const [isItemsOverlay, setIsItemsOverlay] = useState(false);
  const orgTopologySettings = useSelector(getOrgTopologySetting);

  const initOverlays = (sel) => {
    const container = sel.append('g').classed('container', true);
    container.append('g').classed('links-layer', true);
    container.append('g').classed('regions-layer', true);
    container.append('g').classed('sites-layer', true);
  };

  const onRegionClick = (sel, proj, d) => {
    if (onRegionNodeClick) {
      hideTooltip(sel, proj);
      onRegionNodeClick(d);
    }
  };

  const onRegionMouseEvent = (evName, d) => {
    if (onRegionNodeMouseEvent) {
      onRegionNodeMouseEvent({
        eventName: evName,
        item: d,
      });
    }
  };

  const onSiteClick = (sel, proj, d) => {
    if (onSiteNodeClick) {
      hideTooltip(sel, proj);
      onSiteNodeClick(d, queryParams);
    }
  };

  const onSiteMouseEvent = (evName, d) => {
    if (onSiteNodeMouseEvent) {
      onSiteNodeMouseEvent({
        eventName: evName,
        item: d,
      });
    }
  };

  useEffect(() => {
    mapService.setTpSettings({
      center: [
        get(orgTopologySettings, 'mapCenter.latitude', mapService.MAP_CENTER.LATITUDE),
        get(orgTopologySettings, 'mapCenter.longitude', mapService.MAP_CENTER.LONGITUDE),
      ],
      zoom: get(orgTopologySettings, 'zoom.default', mapService.DEFAULT_MAP_ZOOM),
      minZoom: get(orgTopologySettings, 'zoom.outer', mapService.MIN_ZOOM),
      maxZoom: get(orgTopologySettings, 'zoom.inner', mapService.MAX_ZOOM),
      linkZoom: get(orgTopologySettings, 'zoom.link', mapService.DEFAULT_LINK_ZOOM),
      maxBounds: [
        [
          get(orgTopologySettings, 'bounds.northLatitude', mapService.MAP_BOUNDS.NORTH_LATITUDE),
          get(orgTopologySettings, 'bounds.eastLongitude', mapService.MAP_BOUNDS.EAST_LONGITUDE),
        ],
        [
          get(orgTopologySettings, 'bounds.southLatitude', mapService.MAP_BOUNDS.SOUTH_LATITUDE),
          get(orgTopologySettings, 'bounds.westLongitude', mapService.MAP_BOUNDS.WEST_LONGITUDE),
        ],
      ],
      severityBounds: {
        critical: get(orgTopologySettings, 'severity.critical', 75),
        high: get(orgTopologySettings, 'severity.high', 50),
        medium: get(orgTopologySettings, 'severity.medium', 25),
      },
    });
  }, [orgTopologySettings]);

  useEffect(() => {
    cursorLatLngRef.current = [0, 0];
    const tpSettings = mapService.getTpSettings();
    const m = L.map('topology-map', {
      center: tpSettings.center,
      zoom: tpSettings.zoom,
      minZoom: tpSettings.minZoom,
      maxZoom: tpSettings.maxZoom,
      zoomSnap: mapService.ZOOM_DELTA,
      zoomDelta: mapService.ZOOM_DELTA,
      maxBounds: tpSettings.maxBounds,
      maxBoundsViscosity: 1.0,
    });

    const mapBox = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      maxZoom: 18,
      id: 'ecohen1000/ckmirty10a1hn17ql5cczf5so', // 'mapbox/light-v10', // {username}/{style_id}
      tileSize: 512,
      opacity: 1,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoiZWNvaGVuMTAwMCIsImEiOiJja200eWEwMXMwOTk5MnVqeWx1enkxbDBwIn0.2GGBmh4qYSHUmNj9eFkKBA', // 'pk.eyJ1IjoiZWxpYW5vZG90IiwiYSI6ImNrbjYwY3IydDA5Y3Ayb24xMzJ1NjFzNXEifQ.U7MDiVWKXk8MlxuyxSqwwQ',
    });

    m.zoomControl.setPosition('topright');

    m.attributionControl.setPrefix(
      'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    );

    m.addEventListener('mousemove', (event) => {
      cursorLatLngRef.current[0] = event.latlng.lat;
      cursorLatLngRef.current[1] = event.latlng.lng;
    });

    mapBox.addTo(m);
    setLMap(m);

    // cleanup
    return () => {
      m.removeEventListener('mousemove');
    };
  }, []);

  useEffect(() => {
    if (getMapRef) {
      getMapRef(lMap);
    }
  }, [lMap]);

  useEffect(() => {
    if (!isItemsOverlay && lMap) {
      const itemOverlay = L.d3SvgOverlay(
        (sel, proj) => {
          if (sel.select('.container').empty()) {
            initOverlays(sel);
          }
          selectionRef.current = sel;
          projectionRef.current = proj;
          hideTooltip(sel, proj);

          const bounds = mapService.getBoundsFromLeafletBoundsObject(proj.getBounds());
          const filteredSites = mapService.filterSites(sitesRef.current, bounds);
          const filteredLinks = mapService.filterLinks(linksRef.current, bounds, proj.getZoom());

          // here will be done the overlay layer updates - map panning, zoom, etc.
          renderRegions(sel, proj, regionsRef.current, onRegionClick, onRegionMouseEvent);
          renderSites(sel, proj, filteredSites, onSiteClick, onSiteMouseEvent);
          renderLinks(sel, proj, filteredLinks, cursorLatLngRef.current);
        },
        {zoomHide: false},
      );

      itemOverlay.addTo(lMap);
      setIsItemsOverlay(true);
    }
  }, [isItemsOverlay, lMap]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    }
  });

  // map items ref update on prop change - start
  useEffect(() => {
    regionsRef.current = regions;
  }, [regions]);

  useEffect(() => {
    sitesRef.current = sites;
  }, [sites]);

  useEffect(() => {
    linksRef.current = links;
  }, [links]);
  // map items ref update on prop change - end

  useEffect(() => {
    if (projectionRef.current && selectionRef.current) {
      const bounds = mapService.getBoundsFromLeafletBoundsObject(projectionRef.current.getBounds());
      const filteredSites = mapService.filterSites(sites, bounds);
      const filteredLinks = mapService.filterLinks(links, bounds, projectionRef.current.getZoom());

      // here will be done the overlay layer updates - regions etc. updated
      renderRegions(selectionRef.current, projectionRef.current, regions, onRegionClick, onRegionMouseEvent);
      renderSites(selectionRef.current, projectionRef.current, filteredSites, onSiteClick, onSiteMouseEvent);
      renderLinks(selectionRef.current, projectionRef.current, filteredLinks, cursorLatLngRef.current);
    }
  }, [projectionRef.current, selectionRef.current, regions, sites, links]);

  return (
    <Fragment>
      <div id="topology-map" />
      <svg styleName="hide-svg-defs">
        <defs>
          <WingMaskDef />
        </defs>
      </svg>
    </Fragment>
  );
};

GeneralTopologyMap.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.object),
  sites: PropTypes.arrayOf(PropTypes.object),
  links: PropTypes.arrayOf(PropTypes.object),
  getMapRef: PropTypes.func.isRequired,
  onRegionNodeClick: PropTypes.func,
  onRegionNodeMouseEvent: PropTypes.func,
  onSiteNodeClick: PropTypes.func,
  onSiteNodeMouseEvent: PropTypes.func,
};

GeneralTopologyMap.defaultProps = {
  regions: [],
  sites: [],
  links: [],
  onRegionNodeClick: null,
  onRegionNodeMouseEvent: null,
  onSiteNodeClick: null,
  onSiteNodeMouseEvent: null,
};

export default GeneralTopologyMap;
