import React from 'react';
import {getUniqueId} from 'common/utils/guid';
import {getDate, rangeTypes} from 'common/utils/dateRangeService';
import {getDefaultFilterSortFunction} from 'metrics/services/compositeService';
import {get, isEmpty, values} from 'lodash';

export const alertTypes = {
  anomaly: {
    text: 'Anomaly',
    value: 'anomaly',
  },
  static: {
    text: 'Static',
    value: 'static',
  },
  noData: {
    text: 'No Data',
    value: 'noData',
  },
};

export const serverAnomalyDirection = {
  up: 'up',
  down: 'down',
  both: 'both',
};

export const alertConditionTypes = {
  staticThreshold: 'THRESHOLD_CONDITION',
  influencingMetrics: 'INFLUENCING_METRICS_CONDITION',
  minDelta: 'DELTA_CONDITION',
  maxParticipatingMetrics: 'MAX_PARTICIPATING_METRICS_CONDITION',
  minParticipatingMetrics: 'MIN_PARTICIPATING_METRICS_CONDITION',
  significanceCondition: 'SIGNIFICANCE_CONDITION',
  directionCondition: 'DIRECTION_CONDITION',
  durationCondition: 'DURATION_CONDITION',
  volumeCondition: 'VOLUME_CONDITION',
};

export const subConditionTypes = {
  above: 'ABOVE',
  below: 'BELOW',
  between: 'BETWEEN',
};

export const subConditionsMeta = [
  {
    text: 'Above',
    value: subConditionTypes.above,
    boundsBy: [
      {
        text: (
          <span>
            X
            <i className="icon icn-icon-triangle-equal-func bound-icon" />
          </span>
        ),
        value: 'UPPER_EQUAL',
        moreText: 'or equal to',
      },
      {
        text: (
          <span>
            X
            <i className="icon icn-icon-triangle-func bound-icon" />
          </span>
        ),
        value: 'UPPER',
      },
    ],
  },
  {
    text: 'Below',
    value: subConditionTypes.below,
    boundsBy: [
      {
        text: (
          <span>
            X
            <i className="icon icn-icon-triangle-equal-func icon-flipped-horizontal bound-icon" />
          </span>
        ),
        value: 'LOWER_EQUAL',
        moreText: 'or equal to',
      },
      {
        text: (
          <span>
            X
            <i className="icon icn-icon-triangle-func icon-flipped-horizontal bound-icon" />
          </span>
        ),
        value: 'LOWER',
      },
    ],
  },
  {
    text: 'Between',
    value: subConditionTypes.between,
    boundsBy: [
      {
        text: (
          <span>
            <i className="icon icn-icon-triangle-equal-func bound-icon" />X{' '}
            <i className="icon icn-icon-triangle-equal-func bound-icon" />
          </span>
        ),
        value: 'UPPER_EQUAL_AND_LOWER_EQUAL',
      },
      {
        text: (
          <span>
            <i className="icon icn-icon-triangle-equal-func bound-icon" />X{' '}
            <i className="icon icn-icon-triangle-func bound-icon" />
          </span>
        ),
        value: 'UPPER_EQUAL_AND_LOWER',
      },
      {
        text: (
          <span>
            <i className="icon icn-icon-triangle-func bound-icon" />X{' '}
            <i className="icon icn-icon-triangle-equal-func bound-icon" />
          </span>
        ),
        value: 'UPPER_AND_LOWER_EQUAL',
      },
      {
        text: (
          <span>
            <i className="icon icn-icon-triangle-func bound-icon" />X{' '}
            <i className="icon icn-icon-triangle-func bound-icon" />
          </span>
        ),
        value: 'UPPER_AND_LOWER',
      },
    ],
  },
];

export const createSubConditionByType = (type) => {
  switch (type) {
    case subConditionTypes.below:
      return {type: subConditionTypes.below, lowerBound: 'LOWER', minValue: 100};
    case subConditionTypes.between:
      return {
        type: subConditionTypes.between,
        lowerBound: 'UPPER_EQUAL',
        upperBound: 'LOWER_EQUAL',
        minValue: 100,
        maxValue: 2000,
      };
    default:
      // case subConditionTypes.above:
      return {type: subConditionTypes.above, upperBound: 'UPPER', maxValue: 100};
    // default:
    //   return {type: null,};
  }
};

export const alertConditions = [
  {
    name: 'Direction',
    dataObj: {
      type: alertConditionTypes.directionCondition,
      direction: serverAnomalyDirection.down,
    },
    alertContext: [alertTypes.anomaly.value],
    mandatoryFor: [alertTypes.anomaly.value],
  },
  {
    name: 'Minimum Duration',
    dataObj: {
      type: alertConditionTypes.durationCondition,
      minDuration: 60 * 60, // 1hour - long rollup
    },
    alertContext: [alertTypes.anomaly.value, alertTypes.static.value],
    mandatoryFor: [alertTypes.anomaly.value, alertTypes.static.value],
  },
  {
    name: 'Significance Score',
    dataObj: {
      type: alertConditionTypes.significanceCondition,
      minSignificance: 0.75,
    },
    alertContext: [alertTypes.anomaly.value],
    mandatoryFor: [alertTypes.anomaly.value],
  },
  {
    name: 'Metric Values',
    dataObj: {
      type: alertConditionTypes.staticThreshold,
      upperBound: 'UPPER',
      maxValue: null,
      lowerBound: 'LOWER',
      minValue: null,
    },
    alertContext: [alertTypes.anomaly.value, alertTypes.static.value],
    mandatoryFor: [alertTypes.static.value],
  },
  {
    name: 'Volume',
    dataObj: {
      type: alertConditionTypes.volumeCondition,
      enabled: false,
      enableAutoTuning: true,
      bound: null,
      numLastPoints: null,
      rollup: null,
      value: null,
    },
    alertContext: [alertTypes.anomaly.value],
    mandatoryFor: [alertTypes.anomaly.value],
  },
  {
    name: 'Influencing Metrics',
    maxOccurrences: 3,
    dataObj: {
      type: alertConditionTypes.influencingMetrics,
      expressionTreeModel: null,
      rollup: 'long',
      useAnomalyValues: false,
      failOnAbsence: true,
      matchingProperties: [],
      subConditions: [createSubConditionByType()],
    },
    alertContext: [alertTypes.anomaly.value, alertTypes.static.value, alertTypes.noData.value],
    mandatoryFor: [],
  },
  {
    name: 'Minimum Delta',
    dataObj: {
      type: alertConditionTypes.minDelta,
      deltaAbsolute: null,
      deltaPercentage: null,
      deltaDuration: {
        enabled: null,
        rollup: 'medium',
        minDuration: 60 * 60,
      },
    },
    alertContext: [alertTypes.anomaly.value],
    mandatoryFor: [alertTypes.anomaly.value],
  },
  {
    name: 'Maximum Metrics In Anomaly',
    dataObj: {
      type: alertConditionTypes.maxParticipatingMetrics,
      maxNumOfMetrics: null,
      maxPercentageOfMetrics: null,
    },
    alertContext: [alertTypes.anomaly.value],
    mandatoryFor: [],
  },
  {
    name: 'Minimum Metrics In Anomaly',
    dataObj: {
      type: alertConditionTypes.minParticipatingMetrics,
      minNumOfMetrics: null,
      minPercentageOfMetrics: null,
    },
    alertContext: [alertTypes.anomaly.value],
    mandatoryFor: [],
  },
];

export const alertSeverity = {
  critical: {value: 'critical', text: 'Critical'},
  high: {value: 'high', text: 'High'},
  medium: {value: 'medium', text: 'Medium'},
  low: {value: 'low', text: 'Low'},
  info: {value: 'info', text: 'Info'},
};

const getDefaulAlertDefinitionConditions = (featureFlags) =>
  alertConditions
    .filter((item) => item.mandatoryFor.indexOf(alertTypes.anomaly.value) !== -1)
    .filter(
      (con) =>
        con !== alertConditionTypes.volume ||
        (con === alertConditionTypes.volume && get(featureFlags, 'featuresActivation.VOLUME_CONDITION_ENABLED', false)),
    )
    .map((condition) => {
      const alertCondition = {
        ...condition.dataObj,
        id: getUniqueId(),
      };
      if (condition.dataObj.type === alertConditionTypes.minDelta) {
        return {
          ...alertCondition,
          enableAutoTuning: true,
        };
      }
      return alertCondition;
    });

const getDefaultAlertExpressionTreeModel = (title, rootExpression) => ({
  compositeCount: 0,
  displayOnly: false,
  excludeComposites: true,
  expressionTree: {
    root: rootExpression,
  },
  filter: getDefaultFilterSortFunction(),
  id: getUniqueId(),
  mtype: 'ALERT',
  name: null,
  title,
});

export const getDefaultAlertDefinition = (user, title, rootExpression, origin = 'alert') => ({
  owner: user.defaultGroup ? 'andt-group' : user.email,
  ownerId: user.defaultGroup || user._id,
  subscribers: [
    {
      email: user.email,
      muted: false,
    },
  ],
  extraEmails: [],
  data: {
    ownerId: user.defaultGroup || user._id,
    autoAssignmentGroup: null,
    origin,
    type: 'incident',
    title,
    story: '',
    staticAlert: false,
    anomalyAlert: true,
    noDataAlert: false,
    isInfluencingEvents: false,
    channels: [],
    pauseTime: null,
    paused: false,
    enableAutoTuneByAnodot: true,
    advancedModeOpen: false,
    notifyOnlyOpen: false,
    noDataDuration: null,
    expressionTreeModel: getDefaultAlertExpressionTreeModel(title, rootExpression),
    severity: alertSeverity.high.value,
    eventsFilter: null,
    rollups: 'medium',
    conditions: getDefaulAlertDefinitionConditions(get(user, 'organization.serverConfiguration.featureFlags', {})),
  },
});

// eslint-disable-next-line max-len
export const getMandatoryConditionsForType = (type) =>
  alertConditions.filter((c) => c.mandatoryFor.includes(type)).map((c) => c.dataObj.type);

// eslint-disable-next-line max-len
export const getContextConditionsForType = (type) =>
  alertConditions.filter((c) => c.alertContext.includes(type)).map((c) => c.dataObj.type);

export const getAlertWarningLevel = (validation) => {
  if (!validation) {
    return 'alert-0-no-warning';
  }
  if (!validation.alertValidation.passed) {
    return 'alert-2-warning-red';
  }
  if (!validation.compositeValidation.passed) {
    return 'alert-2-warning-red';
  }
  if (!isEmpty(validation.compositeValidation.warnings)) {
    return 'alert-1-warning-yellow';
  }
  return 'alert-0-no-warning';
};

export const getAlertWarningMessage = (validation) => {
  let message = '';
  if (!validation) {
    return message;
  }
  const passedCompositeValidation = validation.compositeValidation ? validation.compositeValidation.passed : true;
  const passedAlertValidation = validation.alertValidation ? validation.alertValidation.passed : true;
  if (passedCompositeValidation && passedAlertValidation) {
    if (!isEmpty(validation.compositeValidation.warnings)) {
      message = get(values(validation.compositeValidation.warnings), '[0][0].message');
    }
  } else if (!passedCompositeValidation) {
    message = get(values(validation.compositeValidation.failures), '[0][0].message', '');
  } else {
    message = get(validation.alertValidation.failures, '[0].message', '');
  }
  return message;
};

export const getSimulationUserEventsDataRange = (rollup, retentions) => {
  const m1 = 60 * 60 * 24 * 30;
  const m6 = m1 * 6;
  const y1 = 60 * 60 * 24 * 365;
  const y2 = y1 * 2;
  const y5 = y1 * 5;
  switch (rollup) {
    case 'short':
      if (retentions && retentions.SHORTROLLUP > m1) {
        return getDate({
          constRange: rangeTypes.r.value,
          relativeLast: retentions.SHORTROLLUP,
          relativeNext: 0,
        });
      }
      return getDate(rangeTypes.m1.value);
    case 'medium':
      if (retentions && retentions.MEDIUMROLLUP > m1) {
        return getDate({
          constRange: rangeTypes.r.value,
          relativeLast: retentions.MEDIUMROLLUP,
          relativeNext: 0,
        });
      }
      return getDate(rangeTypes.m1.value);
    case 'long':
      if (retentions && retentions.LONGROLLUP > m6) {
        return getDate({
          constRange: rangeTypes.r.value,
          relativeLast: retentions.LONGROLLUP,
          relativeNext: 0,
        });
      }
      return getDate(rangeTypes.m6.value);
    case 'longlong':
      if (retentions && retentions.LONGLONGROLLUP > y2) {
        return getDate({
          constRange: rangeTypes.r.value,
          relativeLast: retentions.LONGLONGROLLUP,
          relativeNext: 0,
        });
      }
      return getDate(rangeTypes.y2.value);
    case 'weekly':
      if (retentions && retentions.WEEKLY > y5) {
        return getDate({
          constRange: rangeTypes.r.value,
          relativeLast: retentions.WEEKLY,
          relativeNext: 0,
        });
      }
      return getDate(rangeTypes.y5.value);
    default:
      return getDate(rangeTypes.m1.value);
  }
};
