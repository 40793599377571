// @flow

import React, {Component} from 'react';
import shallowEqual from 'common/utils/shallowEqual';
import Select from 'react-select2';
import CreatableSelect from 'react-select2/lib/Creatable';
import {isEmpty, isString} from 'lodash';
import './SelectAndt.module.scss';
/* eslint-disable import/no-cycle */
import {segmentClickEvent} from 'common/store/actions';
import {connect} from 'react-redux';
import * as style from './SelectAndtStyling';

import Placeholder from './innerComponents/Placeholder';
import MultiValueLabel from './innerComponents/MultiValueLabel';
import MultiValueRemove from './innerComponents/MultiValueRemove';
import EmptyComponent from './innerComponents/EmptyComponent';
import MenuList from './innerComponents/MenuList';
import MenuListVirtualize from './innerComponents/MenuListVirtualize';
import Control from './innerComponents/Control';
import DropdownIndicatorCaretNoTransform from './innerComponents/DropdownIndicatorCaretNoTransform';
import DropdownIndicatorCaret from './innerComponents/DropdownIndicatorCaret';
import DropDownIndicatorDot from './innerComponents/DropDownIndicatorDot';
import SingleValueWithLabel from './innerComponents/SingleValueWithLabel';
import Option from './innerComponents/Option';
import OptionMulti from './innerComponents/OptionMulti';
import OptionSelectedSigned from './innerComponents/OptionSelectedSigned';
import OptionSingleWithInfo from './innerComponents/OptionSingleWithInfo';
import ValueContainer from './innerComponents/ValueContainer';
import InputInMenu from './innerComponents/InputInMenu';

export const TYPE_SIMPLE = 'TYPE_SIMPLE';
export const TYPE_NO_SEARCH = 'TYPE_NO_SEARCH';
export const TYPE_SEARCH = 'TYPE_SEARCH';
export const TYPE_MULTI = 'TYPE_MULTI';

export const THEME_LIGHT = 'THEME_LIGHT';
export const THEME_BLUE = 'THEME_BLUE';
export const THEME_GREEN = 'THEME_GREEN';
export const THEME_LIGHT_BLUE = 'THEME_LIGHT_BLUE';
export const THEME_GREY_LIGHT_BLUE = 'THEME_GREY_LIGHT_BLUE';
export const THEME_DARK_GREY = 'THEME_DARK_GREY';
export const THEME_TRANSPARENT = 'THEME_TRANSPARENT';

export const DIRECTION_LEFT = 'DIRECTION_LEFT';
export const DIRECTION_CENTER = 'DIRECTION_CENTER';
export const DIRECTION_RIGHT = 'DIRECTION_RIGHT';
export const DIRECTION_RIGHT_NO_OFFSET = 'DIRECTION_RIGHT_NO_OFFSET';

// v2 component TYPEs, THEMEs
export const TYPE_NEW_MULTI_NO_SEARCH = 'TYPE_NEW_MULTI_NO_SEARCH';
export const TYPE_NEW_MULTI_NO_SEARCH_WIDE = 'TYPE_NEW_MULTI_NO_SEARCH_WIDE';
export const TYPE_NEW_MULTI = 'TYPE_NEW_MULTI';
export const TYPE_NEW_SEARCH = 'TYPE_NEW_SEARCH';
export const TYPE_NEW_NO_SEARCH = 'TYPE_NEW_NO_SEARCH';

export const TYPE_NEW_SEARCH_LEAN = 'TYPE_NEW_SEARCH_LEAN';
export const TYPE_NEW_LEAN = 'TYPE_NEW_LEAN';
export const TYPE_NEW_SEARCH_LEAN_MULTI = 'TYPE_NEW_SEARCH_LEAN_MULTI';

export const THEME_HIGHLIGHTED = 'THEME_HIGHLIGHTED';
export const THEME_NOT_HIGHLIGHTED = 'THEME_NOT_HIGHLIGHTED';
export const THEME_HIGHLIGHTED_TRANSPARENT = 'THEME_HIGHLIGHTED_TRANSPARENT';
export const THEME_BLUE_LEAN = 'THEME_BLUE_LEAN';
export const THEME_BLUE_DARK = 'THEME_BLUE_DARK';

export const THEME_DARK_LEAN = 'THEME_DARK_LEAN';
export const THEME_BLUE_LEAN_MODIFIED = 'THEME_BLUE_LEAN_MODIFIED';

type PropTypes = {
  isInputVisible: boolean,
  appendToBody: boolean, // default true - will append to the body of the HTML, instead of immediate parent of the el.
  type: string, // Defined by the constants TYPE_SIMPLE / TYPE_NO_SEARCH / TYPE_SEARCH
  theme: string, // Default will be Light. Options are the constants THEME_LIGHT, THEME_BLUE
  onChange: Function, // Return function
  optionHeight: number, // Height of the line of the virtualized menu item inside the search type
  customComponent: Object, // Additional classes for the elements of the Drop down (Option, Menu, etc)
  direction: string, // DIRECTION_LEFT / DIRECTION_RIGHT for simple dropdown
  showValue: boolean, // when false the dropdown button will only show it's name, not it's value
  automationId: string, // default is the placeholder. Will use automationId when placeholder is null
  isCreatable: boolean, // creatable dropdown
  menuWidth: number, // When using TYPE_SIMPLE or TYPE_NEW... this will setup the width of the menu.
  maxMenuHeight: number,
  minMenuHeight: number,
  buttonWidth: number, // When using TYPE_NEW... this will setup the width of the button.
  buttonHeight: number, // When using TYPE_NO_SEARCH this will setup the height of the button.
  offsetLeft: number, // Offset of the menu when open. Implemented only on Type new-multi
  offsetTop: number, // Offset of the menu when open. Implemented only on TYPE_NEW_LEAN
  multiDelimiter: string, // Show multi values with delimiter instead of 'N Selected'
  multiMaxNumOptions: number, // Maximum number of options
  multiMinNumOptions: number, // Minimum number of options
  useTooltip: boolean, // Show tooltip over the selected value,
  useBoxHalf: boolean, // In case of multi, half selected mark that SOME of the items have the item already selected
  forceResorting: boolean, // When there is change in the multi data and there is need to the sorting function to work
  // on every change
  menuListClass: string,
  noAutoReorder: boolean, // Used for multi. Default mode will reorder multi options. This will disable it.
  noMultiOptionsInnerState: boolean, // Used for multi. Default mode will use multiOptions state. This will disable it.
  menuFooterComponent: any,
  disabled: boolean,
  refSelectComponent: Function,

  // Add to your props all the accepted values by the original Select
  isFocused: boolean,
  isMulti: boolean,
  hasValue: boolean,
  value: any,
  children: any,
  selectProps: any,
  innerProps: any,
  options: any,
  maxHeight: number,
  getValue: Function,
  getStyles: Function,
  getOptionValue: Function,
  getOptionLabel: Function,
  onMenuOpen: Function,
  onMenuClose: Function,
  data: Object,
  isSelected: boolean,
  placeholder: string,
  isMenuListScrollable: boolean,
  segmentClickEvent: Function,
  setFilteredOptions: Function,
};

export default connect(
  () => ({}),
  {
    segmentClickEvent,
  },
)(
  class SelectAndt extends Component {
    props: PropTypes;

    state = {
      menuIsOpen: false,
      inputValue: '',
      multiOptions:
        this.props.isMulti &&
        [TYPE_NEW_MULTI, TYPE_NEW_MULTI_NO_SEARCH, TYPE_NEW_SEARCH_LEAN_MULTI].indexOf(this.props.type) !== -1 &&
        !this.props.noMultiOptionsInnerState
          ? this.props.options
          : null,
    };

    componentDidMount() {
      const {automationId} = this.props;
      if (!automationId) {
        throw new Error('A unique automationID attribute is required for selectAndt to work');
      }
    }

    componentDidUpdate(prevProps) {
      if (!this.props.options || shallowEqual(this.props, prevProps)) {
        return;
      }
      const isCreatedNew = this.props.options.length > this.lastOptionsLength && this.lastOptionsLength > 0;
      this.lastOptionsLength = this.props.options.length;
      const isForceResorting = typeof prevProps.forceResorting === 'boolean' ? prevProps.forceResorting : false;
      if (isForceResorting || !this.state.menuIsOpen || isCreatedNew) {
        this.sortMulti(this.props);
      }
    }

    lastOptionsLength = 0;

    getStyle = (type, theme = THEME_LIGHT, direction = DIRECTION_RIGHT) => {
      switch (type) {
        case TYPE_SEARCH:
          return style.searchStyle(theme);
        case TYPE_NO_SEARCH:
          return style.noSearchStyle(
            theme,
            this.props.buttonHeight,
            this.props.maxMenuHeight,
            this.props.isMenuListScrollable,
          );
        case TYPE_SIMPLE:
          return style.simpleStyle(
            theme,
            direction,
            this.props.menuWidth,
            this.props.isMenuListScrollable,
            this.props.offsetLeft,
          );
        case TYPE_MULTI:
          return style.multiStyle(theme);
        case TYPE_NEW_MULTI_NO_SEARCH_WIDE:
          return style.newMultiStyleNoSearch(theme);
        case TYPE_NEW_MULTI_NO_SEARCH:
          return style.newMultiStyle(theme, {
            menuWidth: this.props.menuWidth,
            minMenuHeight: this.props.minMenuHeight,
            buttonWidth: this.props.buttonWidth,
            offsetLeft: this.props.offsetLeft,
          });
        case TYPE_NEW_MULTI:
          return style.newMultiStyle(
            theme,
            {
              menuWidth: this.props.menuWidth,
              buttonWidth: this.props.buttonWidth,
              minMenuHeight: this.props.minMenuHeight,
              offsetLeft: this.props.offsetLeft,
            },
            true,
            this.props.isInputVisible,
          );
        case TYPE_NEW_SEARCH:
          return style.newSearchStyle(
            theme,
            {
              menuWidth: this.props.menuWidth,
              buttonWidth: this.props.buttonWidth,
              minMenuHeight: this.props.minMenuHeight,
              maxMenuHeight: this.props.maxMenuHeight,
            },
            true,
          );
        case TYPE_NEW_NO_SEARCH:
          return style.newSearchStyle(theme, {
            menuWidth: this.props.menuWidth,
            buttonWidth: this.props.buttonWidth,
            maxMenuHeight: this.props.maxMenuHeight,
            minMenuHeight: this.props.minMenuHeight,
          });
        case TYPE_NEW_LEAN:
          return style.newSearchLeanStyle(theme, {
            menuWidth: this.props.menuWidth,
            buttonWidth: this.props.buttonWidth,
            offsetTop: this.props.offsetTop,
          });
        case TYPE_NEW_SEARCH_LEAN:
        case TYPE_NEW_SEARCH_LEAN_MULTI:
          return style.newSearchLeanStyle(
            theme,
            {
              menuWidth: this.props.menuWidth,
              buttonWidth: this.props.buttonWidth,
            },
            true,
          );
        default:
          return null;
      }
    };

    getComponents = (type) => {
      let ret;
      switch (type) {
        case TYPE_SEARCH:
          ret = {
            Control,
            DropdownIndicator: DropdownIndicatorCaret,
            MenuList: MenuListVirtualize,
            Option,
            SingleValue: SingleValueWithLabel,
          };
          break;
        case TYPE_NO_SEARCH:
          ret = {
            Control,
            DropdownIndicator: DropdownIndicatorCaret,
            MenuList,
            SingleValue: SingleValueWithLabel,
          };
          break;
        case TYPE_SIMPLE:
          ret = {
            Control,
            DropdownIndicator: DropDownIndicatorDot,
            MenuList,
          };
          break;
        case TYPE_MULTI:
          ret = {
            Control,
            DropdownIndicator: DropdownIndicatorCaret,
            MenuList: MenuListVirtualize,
            Option: OptionMulti,
            MultiValueLabel,
            MultiValueRemove,
            IndicatorsContainer: EmptyComponent,
            Placeholder,
          };
          break;
        case TYPE_NEW_MULTI_NO_SEARCH:
        case TYPE_NEW_MULTI_NO_SEARCH_WIDE:
          ret = {
            Control,
            DropdownIndicator: DropdownIndicatorCaretNoTransform,
            MenuList: MenuListVirtualize,
            Option: OptionMulti,
            MultiValue: EmptyComponent,
            SingleValue: EmptyComponent,
            MultiValueRemove: EmptyComponent,
            Placeholder: EmptyComponent,
            ClearIndicator: EmptyComponent,
            ValueContainer,
          };
          break;
        case TYPE_NEW_MULTI:
          ret = {
            Control,
            DropdownIndicator: DropdownIndicatorCaretNoTransform,
            MenuList: MenuListVirtualize,
            Option: OptionMulti,
            MultiValue: EmptyComponent,
            SingleValue: EmptyComponent,
            MultiValueRemove: EmptyComponent,
            Placeholder: EmptyComponent,
            ValueContainer,
            Input: InputInMenu,
          };
          break;
        case TYPE_NEW_SEARCH:
          ret = {
            Control,
            DropdownIndicator: DropdownIndicatorCaretNoTransform,
            MenuList: MenuListVirtualize,
            Option: OptionSelectedSigned,
            SingleValue: EmptyComponent,
            Placeholder: EmptyComponent,
            ValueContainer,
            Input: InputInMenu,
          };
          break;
        case TYPE_NEW_SEARCH_LEAN:
        case TYPE_NEW_LEAN:
          ret = {
            Control,
            DropdownIndicator: DropdownIndicatorCaretNoTransform,
            MenuList: MenuListVirtualize,
            Option: OptionSingleWithInfo,
            SingleValue: EmptyComponent,
            Placeholder: EmptyComponent,
            ValueContainer,
            Input: InputInMenu,
          };
          break;
        case TYPE_NEW_SEARCH_LEAN_MULTI:
          ret = {
            Control,
            DropdownIndicator: DropdownIndicatorCaretNoTransform,
            MenuList: MenuListVirtualize,
            Option: OptionMulti,
            MultiValue: EmptyComponent,
            SingleValue: EmptyComponent,
            MultiValueRemove: EmptyComponent,
            Placeholder: EmptyComponent,
            ValueContainer,
            Input: InputInMenu,
          };
          break;
        case TYPE_NEW_NO_SEARCH:
          ret = {
            Control,
            DropdownIndicator: DropdownIndicatorCaretNoTransform,
            MenuList: MenuListVirtualize,
            Option: OptionSelectedSigned,
            SingleValue: EmptyComponent,
            Placeholder: EmptyComponent,
            ValueContainer,
          };
          break;
        default:
          ret = {};
      }
      Object.assign(ret, this.props.customComponent);
      return ret;
    };

    getIsOptionDisabled = (option) => {
      const {multiMaxNumOptions, multiMinNumOptions, value} = this.props;
      if (multiMaxNumOptions && !isNaN(multiMaxNumOptions)) {
        if (value.length >= multiMaxNumOptions) {
          if (this.props.value.find((opt) => opt === option)) {
            return false;
          }
          return true;
        }
      }

      if (multiMinNumOptions && !isNaN(multiMinNumOptions)) {
        if (value.length <= multiMinNumOptions) {
          if (this.props.value.findIndex((opt) => opt.value === option.value) > -1) {
            return true;
          }
          return false;
        }
      }

      const optionHeaderTypesArr = ['HEADER', 'header'];
      return (
        option.disabled ||
        option.isHeader ||
        optionHeaderTypesArr.indexOf(option.type) !== -1 ||
        option.category === true
      );
    };

    getIsCloseMenuOnScroll = (scrollEvn) => {
      // andt-dropdown-menu-list => scrolling the inner menu
      // contract-trigger - invoked on resizing
      const isKeepOpen = (scrollEvn.path || scrollEvn.composedPath()).some(
        (item) =>
          item.className &&
          isString(item.className) &&
          (item.className.indexOf('andt-dropdown-menu-list') !== -1 ||
          item.className.indexOf('contract-trigger') !== -1 ||
          item.className.indexOf('menu-open') !== -1 || // for when search is inside menu and text scrolls
            item.className.indexOf('andt-dropdown-control') !== -1),
      );
      return !isKeepOpen; // true - to close, false - keep open
    };

    getOptionValue = (option) => {
      if (!option) {
        return undefined;
      }

      const {getOptionValue} = this.props;
      if (getOptionValue) {
        return getOptionValue(option);
      }
      return option.value;
    };

    getOptionLabel = (option) => {
      if (!option) {
        return undefined;
      }

      const {getOptionLabel} = this.props;
      if (getOptionLabel) {
        return getOptionLabel(option);
      }
      return option.label;
    };

    onMenuClose = () => {
      this.sortMulti(this.props);
      this.setState({menuIsOpen: false});
      if (this.props.onMenuClose) {
        this.props.onMenuClose();
      }
    };

    onMenuOpen = () => {
      this.sortMulti(this.props);
      this.setState({menuIsOpen: true});
      if (this.props.onMenuOpen) {
        this.props.onMenuOpen();
      }
    };

    filterOption = (option, rawInput) => {
      if (rawInput.length === 0 || !option) {
        return true;
      }
      return option.label.toLowerCase().indexOf(rawInput.toLowerCase()) > -1;
    };

    sortMulti = (props) => {
      const {isMulti, type, noAutoReorder, noMultiOptionsInnerState} = props;
      if (
        isMulti &&
        [TYPE_NEW_MULTI, TYPE_NEW_MULTI_NO_SEARCH, TYPE_NEW_MULTI_NO_SEARCH_WIDE, TYPE_NEW_SEARCH_LEAN_MULTI].indexOf(
          type,
        ) !== -1
      ) {
        const {options, value} = props;
        if (!options || noAutoReorder || noMultiOptionsInnerState) {
          return;
        }
        const multiOptions = [...options];

        if (value) {
          multiOptions.sort((a, b) => {
            const foundA = value.find((item) => this.getOptionValue(a) === this.getOptionValue(item));
            const foundB = value.find((item) => this.getOptionValue(b) === this.getOptionValue(item));
            if (foundA && foundB) {
              return this.getOptionLabel(foundA).localeCompare(this.getOptionLabel(foundB.name));
            }
            return foundA && !foundB ? -1 : 0;
          });

          this.setState({
            multiOptions,
          });
        } else {
          this.setState({
            multiOptions: options,
          });
        }
      }
    };

    onChange = (val) => {
      this.props.segmentClickEvent({
        type: 'option-selected',
        name: this.props.placeholder || this.props.automationId,
        value: val,
        automationId: this.props.automationId,
      });
      if (this.props.isMulti) {
        this.props.onChange(val);
      } else if (!isEmpty(val)) {
        this.props.onChange(val);
      }
    };

    refSelectComponent = (el) => {
      if (this.props.refSelectComponent) {
        this.props.refSelectComponent(el);
      }
    };

    onInputChange = (inputValue, {action}, allOptions) => {
      let menuIsOpen;
      switch (action) {
        case 'input-change':
          this.setState({inputValue});
          // save searched input value match options from all options
          if (this.props.setFilteredOptions && allOptions?.length) {
            const o = allOptions.filter(
              (op) => !inputValue || op.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
            );
            this.props.setFilteredOptions(o);
          }
          break;
        case 'menu-close':
          if (this.state.inputValue) {
            menuIsOpen = true;
          }
          this.setState({
            menuIsOpen,
            inputValue: '',
          });
          if (this.props.setFilteredOptions) {
            this.props.setFilteredOptions(allOptions || []);
          }
          break;
        case 'set-value':
          this.setState({inputValue: ''});
          break;
        default:
          break;
      }
    };

    render() {
      const appendToBody = this.props.appendToBody === undefined ? true : this.props.appendToBody;
      let SelectComponent = Select;
      if (this.props.isCreatable) {
        SelectComponent = CreatableSelect;
      }

      let options =
        this.props.isMulti &&
        [TYPE_NEW_MULTI, TYPE_NEW_MULTI_NO_SEARCH, TYPE_NEW_MULTI_NO_SEARCH_WIDE, TYPE_NEW_SEARCH_LEAN_MULTI].indexOf(
          this.props.type,
        ) !== -1
          ? this.state.multiOptions
          : this.props.options;
      if (!options || !options.length) {
        // eslint-disable-next-line prefer-destructuring
        options = this.props.options;
      }

      return (
        <SelectComponent
          isSearchable={
            [
              TYPE_SEARCH,
              TYPE_MULTI,
              TYPE_NEW_SEARCH,
              TYPE_NEW_MULTI,
              TYPE_NEW_SEARCH_LEAN,
              TYPE_NEW_SEARCH_LEAN_MULTI,
            ].indexOf(this.props.type) !== -1
          }
          ref={this.refSelectComponent}
          className="select-menu alert-clickable-item"
          menuPortalTarget={appendToBody ? document.body : null}
          styles={this.getStyle(this.props.type, this.props.theme, this.props.direction)}
          components={this.getComponents(this.props.type)}
          menuPlacement="auto"
          minMenuHeight={
            [
              TYPE_NO_SEARCH,
              TYPE_NEW_SEARCH,
              TYPE_NEW_MULTI,
              TYPE_NEW_MULTI_NO_SEARCH,
              TYPE_NEW_MULTI_NO_SEARCH_WIDE,
              TYPE_NEW_SEARCH_LEAN,
              TYPE_NEW_SEARCH_LEAN_MULTI,
            ].indexOf(this.props.type) !== -1
              ? 300
              : 200
          }
          maxMenuHeight={this.props.maxMenuHeight || 300}
          maxHeight={300}
          optionHeight={this.props.optionHeight}
          isOptionDisabled={this.getIsOptionDisabled}
          isDisabled={this.props.disabled}
          hideSelectedOptions={false}
          onInputChange={(value, action) => this.onInputChange(value, action, options)}
          animationDuration={1}
          inputValue={this.state.inputValue}
          isInputInMenu={
            [TYPE_NEW_SEARCH, TYPE_NEW_MULTI, TYPE_NEW_SEARCH_LEAN, TYPE_NEW_SEARCH_LEAN_MULTI].indexOf(
              this.props.type,
            ) !== -1
          }
          captureMenuScroll
          menuIsOpen={this.state.menuIsOpen}
          menuShouldScrollIntoView={false}
          closeMenuOnScroll={this.getIsCloseMenuOnScroll}
          filterOption={this.filterOption}
          {...this.props}
          options={options}
          onMenuClose={this.onMenuClose}
          onMenuOpen={this.onMenuOpen}
          onChange={this.onChange}
        />
      );
    }
  },
);
