// @flow
import React, {useCallback, useMemo} from 'react';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import {makeStyles} from '@material-ui/core';
import {isEqual, isObject, isArray, isString} from 'lodash';
import {
  DEFAULT_PARAMS,
  OPTIONS_TYPE_COMPOSITES,
  OPTIONS_TYPE_COMPOSITES_VALUES,
  PARAMS_NAME,
} from 'composites/services/compositesService';
import SearchBox from 'common/componentsV2/SearchBox';
import MultiButton from 'common/componentsV2/multiButton/MultiButton';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentCheckboxHeaders from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentCheckboxHeaders';
import OptionComponentCheckbox from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentCheckbox';
import TooltipArea from 'common/componentsV2/TooltipArea';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import {useFetchUsers} from 'dataCollectors/api/useFetchUsers';
import useFetchDataStreams from 'dataCollectors/api/useFetchDataStreams';

type PropTypes = {
  queryParams: Object,
  setQueryParams: Function,
  isFilterOpen: Object,
  setIsFilterOpen: Function,
};

const useStyles = makeStyles(({typography}) => ({
  labelFilter: {
    ...typography.subtitle1,
    lineHeight: '24px',
    marginTop: 16,
    marginBottom: 4,
    display: 'flex',
  },
}));

const CompositesFilters = React.memo(({queryParams, setQueryParams, isFilterOpen, setIsFilterOpen}: PropTypes) => {
  const classes = useStyles();
  const {users} = useFetchUsers();
  const {streams} = useFetchDataStreams();
  const onChange = useCallback((item, name) => {
    let value;
    if (isObject(item)) {
      // eslint-disable-next-line prefer-destructuring
      value = item.value;
    }

    if (isArray(item)) {
      value = item?.map((o) => o.value).join(',');
    }

    if (isString(item)) {
      value = item;
    }
    setQueryParams({[name]: value === '' || value === 'all' ? undefined : value});
  }, []);

  const multiSelectedOptions = (name, options) =>
    options?.filter((item) => (queryParams[name] || '').includes(item.value));

  const selectedOwner = useMemo(() => multiSelectedOptions(PARAMS_NAME.OWNER, users), [
    queryParams[PARAMS_NAME.OWNER],
    users,
  ]);

  const selectedStream = useMemo(() => multiSelectedOptions(PARAMS_NAME.STREAM, streams), [
    queryParams[PARAMS_NAME.STREAM],
    streams,
  ]);

  const onClearAllStreams = useCallback(
    (sourceList) => {
      if (queryParams[PARAMS_NAME.STREAM] && queryParams[PARAMS_NAME.STREAM].length) {
        setQueryParams({[PARAMS_NAME.STREAM]: undefined});
      } else {
        const selectedStreams = sourceList?.map((i) => i.value).join(',');
        setQueryParams({[PARAMS_NAME.STREAM]: selectedStreams});
      }
    },
    [queryParams],
  );

  const onClearAllOwners = useCallback(
    (userList) => {
      if (queryParams[PARAMS_NAME.OWNER] && queryParams[PARAMS_NAME.OWNER].length) {
        setQueryParams({[PARAMS_NAME.OWNER]: undefined});
      } else {
        const selectedOwners = userList?.map((i) => i.value).join(',');
        setQueryParams({[PARAMS_NAME.OWNER]: selectedOwners});
      }
    },
    [queryParams],
  );

  return (
    <FiltersPanel
      isOpen={isFilterOpen}
      isSaveView={false}
      isShareLink={false}
      onClose={() => setIsFilterOpen(false)}
      onClearAll={() => setQueryParams(DEFAULT_PARAMS)}
      isClearAll={!isEqual(queryParams, DEFAULT_PARAMS)}
    >
      <SearchBox
        placeHolder="Search"
        filter={queryParams[PARAMS_NAME.SEARCH_QUERY] || ''}
        onFilter={(val) => onChange(val, PARAMS_NAME.SEARCH_QUERY)}
      />

      <TooltipArea isAlwaysVisible automationId="compositeTypeFilter" text="Filter by the calculation method">
        {(info) => (
          <>
            <div className={classes.labelFilter}>
              Type <span>{info}</span>
            </div>

            <MultiButton
              value={
                queryParams[PARAMS_NAME.TYPE] ||
                OPTIONS_TYPE_COMPOSITES.find((o) => o.value === OPTIONS_TYPE_COMPOSITES_VALUES.ALL)
              }
              options={OPTIONS_TYPE_COMPOSITES}
              automationId="compositeOriginMB"
              extraStyleName="first-button-small"
              onChange={(val) => onChange(val, PARAMS_NAME.TYPE)}
            />
          </>
        )}
      </TooltipArea>

      <div className={classes.labelFilter}>Owner</div>
      <FormDdlSelect
        options={users}
        selected={selectedOwner}
        button={
          <BigDropdownButton
            isPristine={!selectedOwner?.length}
            placeholder="Select"
            label={selectedOwner?.length === 1 ? selectedOwner[0]?.label : `${selectedOwner?.length} Selected`}
            shortBlueStyle
            isFullWidth
          />
        }
        optionComponent={<OptionComponentCheckbox />}
        onChange={(items) => onChange(items, PARAMS_NAME.OWNER)}
        width={280}
        maxWidth={280}
        height={260}
        isUseSearch
        isMulti
        footerComponent={
          <FormDdlActionBar
            isVisible
            selectedCount={selectedOwner?.length}
            onClearAll={() => onClearAllOwners(users)}
          />
        }
      />
      <div className={classes.labelFilter}>Stream</div>
      <FormDdlSelect
        options={streams}
        selected={selectedStream}
        button={
          <BigDropdownButton
            isPristine={!selectedStream?.length}
            placeholder="Select"
            label={selectedStream?.length === 1 ? selectedStream[0].label : `${selectedStream?.length} Selected`}
            shortBlueStyle
            isFullWidth
          />
        }
        optionComponent={<OptionComponentCheckboxHeaders />}
        onChange={(items) => onChange(items, PARAMS_NAME.STREAM)}
        width={280}
        maxWidth={280}
        height={260}
        isUseSearch
        isMulti
        footerComponent={
          <FormDdlActionBar
            isVisible
            selectedCount={selectedStream?.length}
            onClearAll={() => onClearAllStreams(streams)}
          />
        }
      />
    </FiltersPanel>
  );
});

export default CompositesFilters;
