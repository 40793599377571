/* eslint-disable */
import React, {useCallback, useMemo} from 'react';
import FilterComponent from 'dashboards/components/filters/Filter';

const updateObjectInArray = (array, i, updated) => array.map((item, index) => (index !== i ? item : updated));
const removeObjectInArray = (array, i) => array.filter((item, index) => index !== i);

const EMPTY_ARRAY = [];

const FilterArrayItem = ({
  index,
  isEditable,
  dimension,
  filterArray,
  value,
  onChange,
  onApply,
  onBlur,
  appliedValue,
}) => {
  const onChangeItem = useCallback(
    (data, isCheckbox) => onChange(updateObjectInArray(value, index, data), isCheckbox),
    [value, index],
  );
  const onRemoveItem = useCallback(() => onChange(removeObjectInArray(value, index)), [value, index]);
  return (
    <FilterComponent
      isEditable={isEditable}
      allFilters={value}
      filter={filterArray[index]}
      value={dimension || EMPTY_ARRAY}
      onChange={onChangeItem}
      onApply={onApply}
      onBlur={onBlur}
      onRemove={onRemoveItem}
      appliedValue={appliedValue || EMPTY_ARRAY}
    />
  );
};

const FiltersArray = ({
  value,
  onChange,
  onApply,
  onBlur,
  filter,
  isEditable,
  appliedValue,
}: {
  value: Array,
  onChange: Function,
  onApply: Function,
  onBlur: Function,
  filter: Array,
  isEditable: boolean,
  appliedValue: Array,
}) => {
  const filterArray = useMemo(
    () =>
      value.map((dimension) => filter.filter((item) => item.value && item.key !== (dimension[0] && dimension[0].key))),
    [filter, value],
  );

  const activeAppliedValue = !value[0].key && appliedValue?.[0]?.length ? [[], ...appliedValue] : appliedValue;

  return (
    <div className="display_flex flexWrap_wrap alignItems_flex-end">
      {value.map((dimension, index) => (
        <div
          key={dimension[0] ? dimension[0].key : 'noDimensionKey'}
          className="display_flex alignItems_center mb_1 mr_2"
        >
          <FilterArrayItem
            value={value}
            index={index}
            isEditable={isEditable}
            filterArray={filterArray}
            dimension={dimension}
            onChange={onChange}
            onApply={onApply}
            onBlur={onBlur}
            appliedValue={activeAppliedValue[index]}
          />
        </div>
      ))}
    </div>
  );
};

export default FiltersArray;
