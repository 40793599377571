// @flow
import React from 'react';
import {connect} from 'react-redux';
import {noop} from 'lodash';
import {Box} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import Button, {COLORS} from 'common/componentsV2/Button';
import {getFetchLookupTablesData} from 'bc/store/selectors';
import {addDynamicRoutingFile, displaySnackBar} from 'assets/store/actions';
import {setFileUploadName, setFileUploadProgress, setUploadedFileUrl, setUploadFileError} from 'bc/store/actions';
import {segmentClickEvent} from 'common/store/actions';
import {prepareFile, TYPES} from 'assets/services/service';
import {uploadLookup} from 'bc/services/lookupService';

const classes = {
  popOver: {
    display: 'flex',
    flexDirection: 'column',
  },

  item: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    fontSize: 14,
    fontWeight: 400,
    alignItems: 'center',
    padding: '9px 8px',
    margin: 4,
    cursor: 'pointer',
    borderRadius: 6,
  },
  itemHover: {
    backgroundColor: palette.blue[100],
  },
  transparentBtn: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 200,
    opacity: 0,
    filter: 'alpha(opacity=0)',
    fontSize: 300,
    cursor: 'pointer',
  },
  dropdownOptionIcon: {
    position: 'absolute',
    zIndex: 0,
    left: 2,
    top: 13,
  },
  text: {
    position: 'absolute',
    zIndex: 0,
    left: 29,
    top: 10,
  },
};

type PropTypes = {
  // connect
  setFileUploadName: Function,
  setFileUploadProgress: Function,
  setUploadedFileUrl: Function,
  setUploadFileError: Function,
  addDynamicRoutingFile: Function,
  displaySnackBar: Function,
  lookupTablesData: Array<Object>,
  dynamicRoutingData: Array<Object>,
  segmentClickEvent: Function,
};

export default connect(
  (state) => ({
    lookupTablesData: getFetchLookupTablesData(state),
  }),
  {
    setFileUploadProgress,
    setFileUploadName,
    setUploadedFileUrl,
    setUploadFileError,
    addDynamicRoutingFile,
    displaySnackBar,
    segmentClickEvent,
  },
)(
  class AddNewFile extends React.PureComponent {
    props: PropTypes;

    state = {
      isOpen: false,
      hoverLT: false,
      hoverDR: false,
    };

    onToggle = (isOpen) => {
      this.setState({isOpen});
    };

    addDynamicRoutingFile = (e) => {
      const file = prepareFile(e, null, this.props.dynamicRoutingData);
      this.props.addDynamicRoutingFile({file});
      this.setState({isOpen: false});
      this.props.segmentClickEvent({
        category: 'Asset Management - main',
        name: 'New Asset - Dynamic Routing',
      });
    };

    uploadLookupFileError = (/* err */) => {
      this.props.setUploadFileError();
      this.props.setFileUploadName('');
    };

    addLookupFile = (e) => {
      const file = prepareFile(e, null, this.props.lookupTablesData);

      if (file.errorCode) {
        this.props.displaySnackBar({type: 'failure', errorCode: file.errorCode});
      } else {
        this.props.setFileUploadProgress(0);
        this.props.setFileUploadName(file.data.name);
        this.props.setUploadedFileUrl({});
        uploadLookup(
          file.data,
          this.props.setUploadedFileUrl,
          this.uploadLookupFileError,
          this.props.setFileUploadProgress,
        );
      }
      this.setState({isOpen: false});
      this.props.segmentClickEvent({
        category: 'Asset Management - main',
        name: 'New Asset - Lookup Table',
      });
    };

    renderPopOver = () => (
      <div style={classes.popOver}>
        <Box
          style={this.state.hoverLT ? {...classes.item, ...classes.itemHover} : classes.item}
          onMouseEnter={() => {
            this.setState((prevState) => ({hoverLT: !prevState.hoverLT}));
          }}
          onMouseLeave={() => {
            this.setState((prevState) => ({hoverLT: !prevState.hoverLT}));
          }}
        >
          <i className="icn-general16-document" />
          <input
            onChange={this.addLookupFile}
            type="file"
            name={TYPES.LOOKUP}
            style={classes.transparentBtn}
            placeholder=""
          />
          <span style={classes.text}>Lookup Table</span>
        </Box>
        <Box
          style={this.state.hoverDR ? {...classes.item, ...classes.itemHover} : classes.item}
          onMouseEnter={() => {
            this.setState((prevState) => ({hoverDR: !prevState.hoverDR}));
          }}
          onMouseLeave={() => {
            this.setState((prevState) => ({hoverDR: !prevState.hoverDR}));
          }}
        >
          <i className="icn-general16-split" />
          <input
            onChange={this.addDynamicRoutingFile}
            type="file"
            name={TYPES.DYNAMIC_ROUTING}
            style={classes.transparentBtn}
            placeholder=""
          />
          <span style={classes.text}>Dynamic Routing</span>
        </Box>
      </div>
    );

    render() {
      const {isOpen} = this.state;

      return (
        <FormDdl
          buttonComponent={
            <Button colorSchema={COLORS.BLUE_500} text="New" onClick={this.onToggle} icon="icn-action16-plusa" />
          }
          onToggle={this.onToggle}
          onClickOutside={noop}
          isOpen={isOpen}
          position="right"
          width={210}
          popoverComponent={this.renderPopOver()}
        />
      );
    }
  },
);
