// @flow
import React from 'react';
import './TableListItem.module.scss';
import Checkbox from 'common/componentsV2/Checkbox';
import ExpandableListItem from './ExpandableListItem';

export const CHECKBOX_VISIBILITY_TYPES = {
  alwaysShow: 1,
  alwaysHide: 2,
  onHover: 3,
};

type PropTypes = {
  onCheckboxChange: Function,
  setSelectedItem: Function,
  id: String,
  isSelected: boolean,
  isCheckboxChecked: boolean,
  headerComponent: Node,
  actionToolbarComponent: Node,
  expandedPanel: Node,
  checkboxVisibility: String, // alwaysShow, alwaysHide, onHover
  shouldSimulateHover: boolean,
  checkboxAutomationId: String,
  style: Object,
  expandedPanelClass: String,
  automationId: String,
  selectedActionItem: String,
  isLast: boolean,
};

export default class TableListItem extends React.PureComponent {
  props: PropTypes;

  render() {
    const {
      headerComponent,
      actionToolbarComponent,
      onCheckboxChange,
      isCheckboxChecked,
      expandedPanel,
      setSelectedItem,
      isSelected,
      id,
      checkboxVisibility,
      shouldSimulateHover,
      checkboxAutomationId,
      style,
      expandedPanelClass,
      automationId,
      selectedActionItem,
      isLast,
    } = this.props;

    return (
      <div styleName="item-row" style={style} automation-id={automationId}>
        {checkboxVisibility !== CHECKBOX_VISIBILITY_TYPES.alwaysHide && (
          <Checkbox
            styleName={[
              'checkbox',
              checkboxVisibility === CHECKBOX_VISIBILITY_TYPES.alwaysShow ? 'should-show' : '',
            ].join(' ')}
            isChecked={isCheckboxChecked}
            onChange={onCheckboxChange}
            automationId={checkboxAutomationId}
          />
        )}
        <ExpandableListItem
          id={id}
          expandedPanelClass={expandedPanelClass}
          isSelected={isSelected}
          setSelectedItem={setSelectedItem}
          actionToolbar={actionToolbarComponent}
          header={headerComponent}
          expandedPanel={expandedPanel}
          shouldSimulateHover={shouldSimulateHover}
          isChecked={isCheckboxChecked}
          selectedActionItem={selectedActionItem}
          isLast={isLast}
        />
      </div>
    );
  }
}
