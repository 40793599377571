import React, {Fragment, useMemo} from 'react';
import './NameFormat20Tooltip.module.scss';
import SmartTooltip from 'common/components/SmartTooltip';

type PropTypes = {
  metric: Object,
  children: Node,
};

const NameFormat20Tooltip = ({metric, children}: PropTypes) => {
  const metricProps = useMemo(() => {
    let metricProperties = metric?.properties || [];
    if (Array.isArray(metric?.tag)) {
      metricProperties = metric.properties.concat(metric.tags);
    }
    return metricProperties;
  }, [metric]);

  const tooltipContent = (
    <div styleName="container">
      {metric.what && <div styleName="title">{metric.what}</div>}
      <div styleName="grid-container">
        {metricProps?.map((kv, index) =>
          kv ? (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              <div styleName="props-list-key">{kv.key}</div>
              <div styleName="props-list-value">{kv.value}</div>
            </Fragment>
          ) : null,
        )}
      </div>
    </div>
  );

  return (
    <SmartTooltip
      placement="left"
      content={tooltipContent}
      extraTtClass="metric-name-tooltip tooltip-box custom-placement"
    >
      {children}
    </SmartTooltip>
  );
};

export default NameFormat20Tooltip;
