import {useQuery, useQueryClient} from 'react-query';
import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {get} from 'reactQuery/axiosInstance';
import {syncIgnoreApplyRecommendationInCache} from 'recommendations/api/recommendationsCacheUpdate';

const recommendationsRoot = 'api/v1/recommendations';

const fetchRecommendations = () => {
  return get(`${recommendationsRoot}`);
};

export default function fetchRecommendationsProvider() {
  const queryClient = useQueryClient();

  const queryKey = [QUERY_KEYS.recommendations];
  return {
    invalidate: () => queryClient.invalidateQueries(queryKey),
    reset: () => queryClient.resetQueries(queryKey),
    fetchQuery: () => queryClient.fetchQuery(queryKey, () => fetchRecommendations()),
    useQuery: () =>
      useQuery(queryKey, () => fetchRecommendations(), {
        retry: false,
      }),
    syncIgnoreApplyRecommendationInCache: (recommendationId, recommendationType, ignoreApplyProperty, userId) =>
      syncIgnoreApplyRecommendationInCache(
        recommendationId,
        recommendationType,
        ignoreApplyProperty,
        userId,
        queryClient,
      ),
  };
}
