import moment from 'moment';

export const HOUR = 60 * 60;

export const QUICK_SNOOZE_KEYS = {
  END_SNOOZE: 'endSnooze',
  ONE_HOUR: '1h',
  SIX_HOURS: '6h',
  ONE_DAY: '1d',
  ONE_WEEK: '1w',
  CUSTOM: 'more',
};

export const QUICK_SNOOZE_OPTIONS = [
  {key: QUICK_SNOOZE_KEYS.END_SNOOZE, text: 'End Snooze'},
  {key: QUICK_SNOOZE_KEYS.ONE_HOUR, text: '1 Hour', value: HOUR},
  {key: QUICK_SNOOZE_KEYS.SIX_HOURS, text: '6 Hours', value: HOUR * 6},
  {key: QUICK_SNOOZE_KEYS.ONE_DAY, text: '1 Day', value: HOUR * 24},
  {key: QUICK_SNOOZE_KEYS.ONE_WEEK, text: '1 Week', value: HOUR * 24 * 7},
  {key: QUICK_SNOOZE_KEYS.CUSTOM, text: 'Custom'},
];

export const setTtlDate = (snoozeSummary, stopLearningSummary) => {
  if (snoozeSummary) {
    return moment.unix(snoozeSummary.minResumeTime).toDate();
  }
  if (stopLearningSummary) {
    return moment.unix(stopLearningSummary.minResumeTime).toDate();
  }
  return null;
};

export const checkSnoozeType = (numSnoozedMetrics, numStopLearningMetrics) => {
  if (numSnoozedMetrics > 0 && numStopLearningMetrics > 0) {
    // Color will be red: #D0021B
    return 'snoozed-and-stl';
  }
  if (numSnoozedMetrics > 0 && numStopLearningMetrics === 0) {
    // Color will be yellow: #FDB21C
    return 'snoozed';
  }
  if (numSnoozedMetrics === 0 && numStopLearningMetrics > 0) {
    // Color will be blue: #38AEE8
    return 'stl';
  }
  return '';
};
