// @flow
import React, {useEffect} from 'react';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import fetchTriggeredAlertsTotalProvider from 'alerts.console.new/api/fetchTriggeredAlertsTotalProvider';
import AlertConsoleEmptyState from 'alerts.console.new/components/alertsList/AlertConsoleEmptyState';
import AlertsListPagination from 'alerts.console.new/components/alertsList/AlertsListPagination';
import AlertsListHeader from 'alerts.console.new/components/alertsList/AlertsListHeader';
import AlertsListBody from 'alerts.console.new/components/alertsList/AlertsListBody';
import fetchConfigurationAlertsTotalProvider from 'alerts.management/api/fetchConfigurationAlertsTotalProvider';
import {
  ALERT_CONSOLE_QUERY_PARAMS_KEYS,
  makeAckSnackbar,
  triggerTheSnackBar,
} from 'alerts.console.new/services/alertsConsoleService';
import * as actions from 'alerts.console.new/store/actions';
import {useSelector} from 'react-redux';
import {getUserProfile} from 'profile/store/selectors';
import {setAlertTriggerAcknowledgement} from 'alerts.console.new/api/api';
import {queryErrorHandler} from 'reactQuery/queryClient';

import './AlertsListBody.module.scss';

const AlertsList = ({setQueryParams, queryParams}: {setQueryParams: Function, queryParams: Object}) => {
  const meUser = useSelector(getUserProfile);

  const totalTriggeredAlertsQuery = fetchTriggeredAlertsTotalProvider({
    constRange: queryParams?.constRange,
  })?.useQuery();
  const alertsConfigurationCountProvider = fetchConfigurationAlertsTotalProvider()?.useQuery();

  const triggeredAlertsProvider = fetchTriggeredAlertsProvider(queryParams);
  const alertsResult = triggeredAlertsProvider.useQuery();

  const alertTriggeredTotal = totalTriggeredAlertsQuery?.data?.total;
  const alertConfigurationTotal = alertsConfigurationCountProvider?.data?.total;

  const acknowledgeTheAlert = async (triggeredAlert) => {
    try {
      const payload = {
        isAcknowledged: true,
        meId: meUser._id,
        alertTriggerId: triggeredAlert.alertTriggerId,
      };
      await setAlertTriggerAcknowledgement(payload).promise;
      triggeredAlertsProvider.syncAcksInCache(triggeredAlert.alertTriggerId, queryParams);
      setQueryParams({
        [ALERT_CONSOLE_QUERY_PARAMS_KEYS.MAKE_ACK]: undefined,
      });

      makeAckSnackbar(triggeredAlert, meUser);
    } catch (e) {
      const error = {
        title: 'Acknowledgement error',
        description: `Error while setting acknowledgement`,
      };
      queryErrorHandler(error);
      // Need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
      await triggeredAlertsProvider.invalidate();
    }
  };

  useEffect(() => {
    if (alertsResult.data) {
      if (
        queryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.TRIGGER_IDS] ||
        queryParams[ALERT_CONSOLE_QUERY_PARAMS_KEYS.ALERT_CONFIGURATION_IDS]
      ) {
        triggerTheSnackBar(queryParams.triggerIds, () => {
          setQueryParams({
            [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ALERT_CONFIGURATION_IDS]: undefined,
            [ALERT_CONSOLE_QUERY_PARAMS_KEYS.TRIGGER_IDS]: undefined,
            [ALERT_CONSOLE_QUERY_PARAMS_KEYS.REF]: undefined,
            [ALERT_CONSOLE_QUERY_PARAMS_KEYS.ANALYTICS]: undefined,
          });
        });
        actions.setSelectedTriggeredAlert(queryParams.triggerIds);
      }
      if (queryParams.makeAck === '1' && alertsResult.data.triggers) {
        const flattenedAlerts = alertsResult.data.triggers.flatMap((alertsGroup) => alertsGroup.alerts);
        const triggeredAlert = flattenedAlerts.find((trigger) => trigger.alertTriggerId === queryParams.triggerIds);
        acknowledgeTheAlert(triggeredAlert);
      }
    }
  }, [alertsResult]);

  return (
    <div styleName="alert-list-container">
      {alertsResult.isLoading === true && (
        <div styleName="loader">
          <Spinner color="#3d4c59" size={SIZES.XX_BIG_150} />
        </div>
      )}

      {(alertsResult.isLoading === false && alertsResult.data?.total === 0) || alertsResult.isError === true ? (
        <AlertConsoleEmptyState
          alertsConfigurationCount={alertConfigurationTotal}
          isError={alertsResult.isError === true}
        />
      ) : null}

      {alertsResult.data?.total >= 0 && alertTriggeredTotal >= 0 && (
        <>
          <AlertsListPagination
            pageSize={alertsResult.data?.pageSize}
            setQueryParams={setQueryParams}
            total={alertsResult.data?.total}
          />
          <AlertsListHeader
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            totalTriggeredAlerts={alertTriggeredTotal}
            displayedTotalTriggeredAlerts={alertsResult.data?.total}
          />
          <AlertsListBody alertsTriggers={alertsResult.data?.triggers} queryParams={queryParams} />
        </>
      )}
    </div>
  );
};

export default AlertsList;
