// @flow
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'alerts.management/store/selectors';
import * as profileSelector from 'profile/store/selectors';
import * as actions from 'alerts.management/store/actions';
import NotificationOption from './NotificationOption';
import NoDataCondition from './NoDataCondition';

const fullList = [
  {value: 'opens', label: 'Alert Opens', isDisabled: true},
  {value: 'closes', label: 'Alert Close'},
  {value: 'updates', label: 'Alert Update'},
];

const getOptions = (type, isUpdatePolicyEnabled) =>
  ({
    anomaly: isUpdatePolicyEnabled
      ? fullList
      : [{value: 'opens', label: 'Alert Opens', isDisabled: true}, {value: 'closes', label: 'Alert Close'}],
    static: fullList,
    noData: [
      {value: 'opens', label: 'Alert Opens', isDisabled: true},
      {value: 'closes', label: 'Alert Close', isDisabled: true},
      {value: 'updates', label: 'Alert Update'},
    ],
  }[type]);

const SendAlertOnCondition = () => {
  const dispatch = useDispatch();
  const isAlertEnableAnomalyUpdate = useSelector(profileSelector.getIsAlertEnableAnomalyUpdate);
  const alertSimulationFilters = useSelector(selectors.getSelectedAlertSimulationFilters);
  const {notifyOnlyOpen, type, updatePolicyInterval, updatePolicy} = alertSimulationFilters;
  const alertType = type.value;
  const isUpdatePolicyEnabled = alertType === 'anomaly' ? isAlertEnableAnomalyUpdate : true;
  const options = getOptions(alertType, isUpdatePolicyEnabled);
  const handleChange = useCallback(
    (item, value) => {
      switch (item) {
        case 'updates':
          dispatch(
            actions.setSelectedAlertUpdatePolicy({
              enabled: value,
              value: updatePolicyInterval.value,
            }),
          );
          break;
        case 'closes':
          dispatch(actions.setSelectedAlertNotifyOnlyOpenBoolVal(!value));
          break;
        default:
          break;
      }
    },
    [updatePolicyInterval],
  );

  return (
    <div automation-id="sendAlertOnConditionContainer">
      <div className="text18med lineHeight_16 mb_1">Notify when</div>
      {options.map((option) => {
        return (
          <NotificationOption
            selected={
              option.value === 'opens' ||
              (updatePolicyInterval.enabled && updatePolicy && option.value === 'updates') ||
              (!notifyOnlyOpen && option.value === 'closes')
            }
            value={option.value}
            label={option.label}
            isDisabled={option.isDisabled}
            onChange={handleChange}
            key={option.value}
          />
        );
      })}
      <NoDataCondition />
    </div>
  );
};

export default SendAlertOnCondition;
