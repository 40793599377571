import React from 'react';
import StreamEditorPanel from '../../StreamEditorPanel';
import DatadogData from './datadogData/DatadogData';
import DatadogFiltering from './datadogData/DatadogFiltering';
import DatadogGrouping from './datadogDimensionsAndMetrics/DatadogGrouping';
import DatadogSchedular from './datadogSchedular/DatadogSchedular';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';
import './datadogData/DatadogData.module.scss';

const DatadogStreamEditor = () => (
  <div className="shell-col">
    <StreamEditorSteps>
      <div styleName="left-container">
        <DatadogData />
      </div>
      <div styleName="middle-container">
        <DatadogGrouping />
        <DatadogFiltering />
      </div>
      <div className="shell-col">
        <StreamProperties />
        <DatadogSchedular />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default DatadogStreamEditor;
