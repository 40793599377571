// @flow
import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {segmentClickEvent as segmentTrackingMouseEventAction} from 'common/store/actions';
import * as selectors from 'bc/store/selectors';
import {
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
  fetchSalesforceObjectData as fetchSalesforceObjectDataAction,
  fetchMixpanelQueryPreview as fetchMixpanelQueryPreviewAction,
  setSalesforceStreamQuery as setSalesforceStreamQueryAction,
  verifyMixpanelQuery as verifyMixpanelQueryAction,
} from 'bc/store/actions';
import {isStreamEditable as isStreamEditableService} from 'bc/services/dataStreamService';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import QueryEditorModal from '../../sql/queryEditorModal/QueryEditorModal';
import Entities from './Entities';

import './MixpanelStreamEditor.module.scss';

const DEFAULT_QUERY = `function main(){
  return Events({"from_date": "{{from_time(yyyy-MM-dd)}}",
  "to_date": "{{to_time(yyyy-MM-dd)}}"})
  .filter(function(event) { return event.name == "Button Clicked: Request Invite Features" })
}`;

const GUIDELINES = [
  <Fragment>The minimal script appears above.</Fragment>,
  <Fragment>Use the from_time to_time variables to allow slicing the data.</Fragment>,
  <Fragment>Script run time must not exceed two minutes.</Fragment>,
  <Fragment>Mixpanel does not provide pagination. We recommend using grouping in your scripts</Fragment>,
  <Fragment>Refer to Anodot documentation for more information</Fragment>,
];

type PropTypes = {
  dataStream: Object,
  // connect
  previewData: Object,
  queryPreviewItems: Object,
  previewDataError: Object,
  isPreviewDataLoading: boolean,
  isQuery: boolean,
  fetchMixpanelQueryPreview: Function,
  setSelectedStreamKeyVal: Function,
  segmentClickEvent: Function,
  verifyMixpanelQuery: Function,
};

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    previewData: selectors.getMixpanelPreviewData(state),
    queryPreviewItems: selectors.getMixpanelStreamQueryPreviewItems(state),
    previewDataError: selectors.getMixpanelStreamPreviewError(state),
    isPreviewDataLoading: selectors.getMixpanelStreamPreviewIsLoading(state),
    isQuery: selectors.getMixpanelIsQuery(state),
  }),
  {
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
    fetchSalesforceObjectData: fetchSalesforceObjectDataAction,
    fetchMixpanelQueryPreview: fetchMixpanelQueryPreviewAction,
    setSalesforceStreamQuery: setSalesforceStreamQueryAction,
    verifyMixpanelQuery: verifyMixpanelQueryAction,
    segmentClickEvent: segmentTrackingMouseEventAction,
  },
)(
  class SalesforceContext extends React.PureComponent {
    props: PropTypes;

    state = {
      isStreamEditable: isStreamEditableService(this.props.dataStream),
      selectedRadio: this.props.isQuery ? 'query' : 'table',
      isOpenQueryEditorModal: false,
    };

    setSelectedRadio = (changeEvent) => {
      this.setState({selectedRadio: changeEvent.target.value}, () => {
        this.props.setSelectedStreamKeyVal({customQuery: this.state.selectedRadio === 'query'});
      });
    };

    closeQueryEditorModal = () => {
      this.setState({isOpenQueryEditorModal: false});
    };

    openQueryEditorModal = () => {
      const {dataStream, segmentClickEvent} = this.props;
      this.setState({isOpenQueryEditorModal: true});
      segmentClickEvent({category: `Data collectors - ${dataStream.type}`, name: 'Query editor'});
    };

    handelContinueClick = (payload) => {
      const {verifyMixpanelQuery} = this.props;
      verifyMixpanelQuery({
        query: payload.query,
      });
      this.closeQueryEditorModal();
    };

    runQuery = (payload) => {
      this.props.setSelectedStreamKeyVal({query: payload.query});
      this.props.fetchMixpanelQueryPreview({query: payload.query});
    };

    render() {
      const {dataStream, previewData, previewDataError, isPreviewDataLoading, queryPreviewItems} = this.props;
      const {isStreamEditable, selectedRadio} = this.state;

      return (
        <div styleName="left-container">
          <PanelHeader title="Stream Filters" isEditable={isStreamEditable} />
          <div styleName="panel">
            <div styleName="radio-panel">
              <input
                type="radio"
                name="queryType"
                value="table"
                checked={selectedRadio === 'table'}
                onChange={this.setSelectedRadio}
              />
            </div>

            <div styleName="salesforce-select">
              <Entities isDisabled={this.state.selectedRadio !== 'table'} />
            </div>
          </div>

          <div styleName="panel">
            <div styleName="radio-panel">
              <input
                type="radio"
                name="queryType"
                value="query"
                checked={selectedRadio === 'query'}
                onChange={this.setSelectedRadio}
              />
            </div>

            <div styleName="select-panel">
              <button
                type="button"
                className="btn btn-raised"
                styleName="btn-compose"
                automation-id="bigQueryComposeQueryButton"
                disabled={this.state.selectedRadio !== 'query'}
                onClick={this.openQueryEditorModal}
              >
                Compose Query
              </button>
              {this.state.isOpenQueryEditorModal && (
                <QueryEditorModal
                  isOpen={this.state.isOpenQueryEditorModal}
                  onClose={this.closeQueryEditorModal}
                  dataStream={dataStream}
                  query={dataStream.query || DEFAULT_QUERY}
                  runQueryPreview={this.runQuery}
                  hideActualQuery={!previewData.query || !dataStream.query}
                  previewData={previewData}
                  previewDataError={previewDataError}
                  isPreviewDataLoading={isPreviewDataLoading}
                  queryPreviewColumns={queryPreviewItems}
                  fetchVerifyQueryFunction={this.handelContinueClick}
                  isVerifyQueryLoading=""
                  verifyQueryData=""
                  guidelines={GUIDELINES}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  },
);
