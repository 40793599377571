import {QUERY_KEYS} from 'reactQuery/queryKeys';
import {RECOMMENDATION_ACTIONS} from '../services/consts';

export const syncIgnoreApplyRecommendationInCache = (
  recommendationId,
  subRecommendationType,
  ignoreOrApplyProperty,
  userId,
  queryClient,
) => {
  queryClient.setQueriesData(QUERY_KEYS.recommendations, (data) => {
    const recommendations = data?.length ? [...data] : [];
    if (recommendations) {
      const recommendation = recommendations.find((rec) => rec.id === recommendationId);
      if (recommendation) {
        const subRecommendation = recommendation.recommendations?.find(
          (subRec) => subRec.type === subRecommendationType,
        );
        if (subRecommendation) {
          if (ignoreOrApplyProperty === RECOMMENDATION_ACTIONS.IGNORE) {
            subRecommendation.ignored = true;
          } else if (ignoreOrApplyProperty === RECOMMENDATION_ACTIONS.APPLY) {
            subRecommendation.applied = true;
          }
          subRecommendation.userFeId = userId;
        }
      }
    }
    return recommendations;
  });
};

export const syncIgnoreApplyRecommendationByAlertIdInCache = (
  queryKey,
  subRecommendationType,
  ignoreOrApplyProperty,
  queryClient,
) => {
  queryClient.setQueriesData(queryKey, (recommendation) => {
    const tmpRecommendation = recommendation;

    if (recommendation) {
      if (subRecommendationType) {
        const subRecommendation = tmpRecommendation.recommendations?.find(
          (subRec) => subRec.type === subRecommendationType,
        );

        // If "Ignore" or "Apply" for a specific recommendation was clicked
        if (subRecommendation) {
          if (ignoreOrApplyProperty === RECOMMENDATION_ACTIONS.IGNORE) {
            subRecommendation.ignored = true;
          } else if (ignoreOrApplyProperty === RECOMMENDATION_ACTIONS.APPLY) {
            subRecommendation.applied = true;
          }
        }
      } else {
        // If "Ignore All" or "Apply All" clicked
        tmpRecommendation.recommendations = recommendation.recommendations.map((subRecommendation) => {
          let {ignored} = subRecommendation;
          let {applied} = subRecommendation;

          if (ignoreOrApplyProperty === RECOMMENDATION_ACTIONS.IGNORE) {
            ignored = true;
          } else if (ignoreOrApplyProperty === RECOMMENDATION_ACTIONS.APPLY) {
            applied = true;
          }
          return {
            ...subRecommendation,
            ignored,
            applied,
          };
        });
      }
    }
    return tmpRecommendation;
  });
};
