import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {fetchTriggeredAlertsByConfiguration} from 'alerts.management/store/actions';
import {getTriggeredAlertsByConfigurationCount} from 'alerts.management/store/selectors';
import {REF_VALUES} from 'alerts.console.new/services/alertsConsoleService';

import '../OverwiewTab.module.scss';

const HowOftenCubeStatic = ({
  trigger,
  isTriggerLoading,
  onIsLoading,
}: {
  trigger: Object,
  isTriggerLoading: Boolean,
  onIsLoading: Function,
}) => {
  const dispatch = useDispatch();
  const triggeredAlertsByConfigurationCount = useSelector(getTriggeredAlertsByConfigurationCount);

  useEffect(() => {
    onIsLoading(isTriggerLoading);
  }, [isTriggerLoading]);

  useEffect(() => {
    const thirtyDaysTimestamp = Math.floor(Date.now() / 1000) - 60 * 60 * 24 * 30;
    const queryAPI = {
      startTime: thirtyDaysTimestamp,
      alertConfigurationIds: trigger?.alertConfigurationId,
    };
    dispatch(fetchTriggeredAlertsByConfiguration(queryAPI));
  }, []);

  const goTo = () => {
    window.open(
      `/#!/r/alerts-console-new?ref=${REF_VALUES.INVESTIGATE}&configIds=${trigger?.alertConfigurationId}&constRange=y1`,
    );
  };

  return (
    <>
      {trigger ? (
        <div styleName="info-cube-container">
          {triggeredAlertsByConfigurationCount > 0 ? (
            <div>
              <div styleName="how-often-text">
                {`Alert was triggered ${triggeredAlertsByConfigurationCount} additional time${
                  triggeredAlertsByConfigurationCount > 1 ? 's' : ''
                } in the last 30 days`}
              </div>
              <a styleName="see-all" onClick={goTo} target="_blank" rel="noopener noreferrer">
                See All
              </a>
            </div>
          ) : (
            <div>No triggers in the last 30 days</div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default HowOftenCubeStatic;
