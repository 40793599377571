// @flow
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {
  createDataSource as createDataSourceAction,
  resetAddSourceError as resetAddSourceErrorAction,
} from 'bc/store/actions';
import DataSourceModal from '../../DataSourceModal';
import '../../DataSourceModal.module.scss';

type PropTypes = {
  isOpen: Boolean,
  isViewOnly: boolean,
  sourceType: Object,
  source: Object,
  onClose: Function,

  // connect
  isLoading: boolean,
  sourceError: Object,

  createDataSource: Function,
  resetAddSourceError: Function,
};

export default connect(
  (state) => ({
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource: createDataSourceAction,
    resetAddSourceError: resetAddSourceErrorAction,
  },
)(
  class FacebookSourceModal extends React.PureComponent {
    props: PropTypes;

    onCloseInternal = () => {
      this.props.resetAddSourceError();
      this.props.onClose();
    };

    responseFacebook = (response) => {
      const {sourceType, createDataSource} = this.props;
      const sourceNamePrefix = sourceType.name;

      createDataSource({
        type: sourceType.type,
        name: `${sourceNamePrefix} Data Source ${new Date().getTime().toString()}`,
        authenticationType: 'service_account',
        accessToken: response.accessToken,
        expiresIn: response.expiresIn,
        data_access_expiration_time: response.data_access_expiration_time,
        userID: response.userID,
      });
    };

    render() {
      const {isOpen, isLoading, sourceError, source, sourceType, isViewOnly} = this.props;

      return (
        <DataSourceModal
          isOpen={isOpen}
          isProcessing={isLoading}
          source={source}
          sourceType={sourceType}
          isShowName={false}
          isViewOnly
          logoImageClass="source-logo-s3"
          onClose={this.onCloseInternal}
        >
          <div styleName="inputs-wrapper">
            <div styleName="header facebook-header">
              <span>Connect Facebook Ads to Anodot</span>
            </div>
            <div styleName="facebook-header">
              Click on the &quot;Login with Facebook&quot; button to authenticate your Facebook account.
              <br />
              Once connected, Anodot will begin to collect and analyze your ad performance metrics.
            </div>

            {isViewOnly ? (
              <span styleName="my-facebook-button-disabled">Login with Facebook</span>
            ) : (
              <FacebookLogin
                appId="250803393048711" // Anodot app
                autoLoad={false}
                fields="name,email,picture"
                scope="ads_read,ads_management"
                callback={this.responseFacebook}
                cssClass="my-facebook-button-class"
                version="10.0"
              />
            )}

            {!isLoading && sourceError ? (
              <div styleName="error-wrapper">
                <span styleName="error-message">
                  <i className="icon icn-warning-icon" /> {sourceError.uMessage}
                </span>
              </div>
            ) : null}

            <div styleName="extra-info">
              <span />
            </div>
          </div>
        </DataSourceModal>
      );
    }
  },
);
