import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {Observable} from 'rxjs';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

const fetchDatadogMetrics = makeAsyncEpic(actions.fetchDatadogMetrics, api.fetchDatadogMetrics);
const fetchDatadogDescribeMetric = makeAsyncEpic(actions.fetchDatadogDescribeMetric, api.fetchDatadogDescribeMetric);

const setDatadogDescribeMetric = (action$, {getState}) =>
  action$.ofType(actions.fetchDatadogDescribeMetric.success.TYPE).flatMap((action) => {
    if (action.meta && action.meta.isEditRunning) {
      const stream = selectors.getSelectedDataStream(getState());
      const convertDatadogStreamFilterToUiFilters = function(ddFilters) {
        if (!ddFilters || !ddFilters.length) {
          return [
            {
              isInclude: true,
              label: '',
              selectedValue: [],
            },
          ];
        }

        const filters = [];
        ddFilters.forEach((ddFilter) => {
          const isInclude = !(ddFilter.notValue || ddFilter.notValues);
          let selectedValue = '';
          if (isInclude) {
            selectedValue = [ddFilter.value];
            if (ddFilter.values) {
              selectedValue = ddFilter.values.join(', ');
            }
          } else {
            selectedValue = [ddFilter.notValue];
            if (ddFilter.notValues) {
              selectedValue = ddFilter.notValues.join(', ');
            }
          }

          filters.push({label: ddFilter.key, isInclude, selectedValue});
        });

        return filters;
      };

      return [
        actions.setSelectedStreamUiKeyVal({
          uiFilters: convertDatadogStreamFilterToUiFilters(stream.filter),
          describeMetric: {
            metricName: action.meta.metricName,
            ...action.payload,
          },
        }),
      ];
    }
    return [actions.setDatadogDescribeMetric(action.payload, action.meta)];
  });

const applyDatadogStreamFilters = (action$, {getState}) =>
  action$.ofType(actions.applyDatadogStreamFilters.TYPE).flatMap(() => {
    const stream = selectors.getSelectedDataStream(getState());
    const res = {
      filter: [],
    };

    stream.uiState.uiFilters.forEach((filter) => {
      if (filter.label !== '') {
        if (filter.selectedValue.length > 1) {
          const filterValKey = filter.isInclude ? 'values' : 'notValues';
          res.filter.push({
            key: filter.label,
            [filterValKey]: filter.selectedValue,
          });
        } else {
          const filterValKey = filter.isInclude ? 'value' : 'notValue';
          res.filter.push({
            key: filter.label,
            [filterValKey]: filter.selectedValue[0] || '',
          });
        }
      }
    });

    return [actions.setSelectedStreamKeyVal(res)];
  });

const fetchDatadogQueryPreview = (action$, {getState}) =>
  action$.ofType(actions.fetchDatadogQueryPreview.TYPE).switchMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());
    const meta = {...action.payload};

    return api
      .fetchDatadogQueryPreview({
        payload: {
          streamType: stream.type,
          query: action.payload.query,
          dataSourceId: stream.dataSourceId,
          timeZone: stream.timeZone || 'UTC',
          pollingInterval: stream.pollingInterval,
        },
      })
      .map((payload) => actions.fetchDatadogQueryPreview.success(payload, meta))
      .catch((error) => Observable.of(actions.fetchDatadogQueryPreview.failure(error, meta)));
  });

const setDatadogStreamQuery = (action$, {getState}) =>
  action$.ofType(actions.setDatadogStreamQuery.TYPE).switchMap(() => {
    const stream = selectors.getSelectedDataStream(getState());
    const queryPreviewData = selectors.getDatadogStreamQueryPreviewData(getState());
    return [
      actions.fetchDatadogDescribeMetric(
        {
          type: stream.type,
          dataSourceId: stream.dataSourceId,
          metricName: queryPreviewData.metricName,
        },
        {
          queryPreviewData,
          metricName: queryPreviewData.metricName,
        },
      ),
    ];
  });

const datadogEpic = combineEpics(
  fetchDatadogMetrics,
  fetchDatadogDescribeMetric,
  setDatadogDescribeMetric,
  applyDatadogStreamFilters,
  fetchDatadogQueryPreview,
  setDatadogStreamQuery,
);

export default datadogEpic;
