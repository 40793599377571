// @flow
import React, {useCallback, useMemo} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import {ALERT_CONSOLE_QUERY_PARAMS_KEYS, FEEDBACK_OPTIONS} from 'alerts.console.new/services/alertsConsoleService';
import OptionComponentCheckboxHeaders from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentCheckboxHeaders';

const AckFilter = () => {
  const [feedback, setFeedback] = useQueryParam(ALERT_CONSOLE_QUERY_PARAMS_KEYS.FEEDBACK, StringParam);

  const selectedFeedback = useMemo(() => {
    const tmpSelected = [];
    if (feedback) {
      if (feedback.indexOf(FEEDBACK_OPTIONS.NONE.value) !== -1) {
        tmpSelected.push(FEEDBACK_OPTIONS.NONE);
      }
      if (feedback.indexOf(FEEDBACK_OPTIONS.POSITIVE.value) !== -1) {
        tmpSelected.push(FEEDBACK_OPTIONS.POSITIVE);
      }
      if (feedback.indexOf(FEEDBACK_OPTIONS.NEGATIVE.value) !== -1) {
        tmpSelected.push(FEEDBACK_OPTIONS.NEGATIVE);
      }
      if (feedback.indexOf(FEEDBACK_OPTIONS.ANY.value) !== -1) {
        tmpSelected.push(FEEDBACK_OPTIONS.NEGATIVE);
        tmpSelected.push(FEEDBACK_OPTIONS.POSITIVE);
      }
    }
    return tmpSelected;
  }, [feedback]);

  const toggleFilter = useCallback(
    (items) => {
      if (Array.isArray(items)) {
        let feedbackToSet;
        if (items.length > 0) {
          let difference = items?.filter((item) => !selectedFeedback?.includes(item));
          if (difference.length > 0) {
            if (difference.find((item) => item.value === FEEDBACK_OPTIONS.NONE.value)) {
              feedbackToSet = FEEDBACK_OPTIONS.NONE.value;
            } else {
              const tmpFeedback = [];
              if (items.find((item) => item.value === FEEDBACK_OPTIONS.POSITIVE.value)) {
                tmpFeedback.push(FEEDBACK_OPTIONS.POSITIVE.value);
              }
              if (items.find((item) => item.value === FEEDBACK_OPTIONS.NEGATIVE.value)) {
                tmpFeedback.push(FEEDBACK_OPTIONS.NEGATIVE.value);
              }
              // If both Positive and Negative feedbacks are selected - send "any" to the API
              if (tmpFeedback?.length > 0) {
                feedbackToSet = tmpFeedback?.length === 2 ? FEEDBACK_OPTIONS.ANY.value : tmpFeedback[0];
              }
            }
          } else {
            // If one of the options is unselected - need to see which one.
            difference = selectedFeedback.filter((item) => !items.includes(item));
            if (difference.length > 0) {
              feedbackToSet = items[0].value;
            }
          }
        }
        setFeedback(feedbackToSet);
      }
    },
    [setFeedback, selectedFeedback],
  );

  const filterLabel = useMemo(() => {
    if (selectedFeedback?.length) {
      return selectedFeedback.map((item) => item.label).join(', ');
    }
    return 'Select';
  }, [selectedFeedback]);

  return (
    <FormDdlSelect
      id="props.automationId "
      automationId="AlertFeedbackFilter"
      extraClassName="alerts-dropdown-btn"
      options={Object.values(FEEDBACK_OPTIONS).filter((option) => !option.isHidden)}
      selected={selectedFeedback}
      width={240}
      maxWidth={240}
      onChange={(items) => toggleFilter(items)}
      value={selectedFeedback}
      placeholder="Select"
      placement="auto"
      button={
        <BigDropdownButton
          isPristine={selectedFeedback && !selectedFeedback.length}
          placeholder="Select"
          label={filterLabel}
          shortBlueStyle
          isFullWidth
        />
      }
      isMulti
      isUseSearch={false}
      isDisabledSorting
      checkSelectedByProp="value"
      optionComponent={<OptionComponentCheckboxHeaders />}
    />
  );
};
export default AckFilter;
