/* eslint-disable max-len */
import {delete_, get, post, put, stringifyQS} from 'common/utils/http';

const alertsRoot = 'api/v1/alerts/';
const metricsRoot = 'api/v1/metrics/';
const userEventsRoot = 'api/v1/user-events/';

const isReservedCharacterFull = (c) => {
  return ['\\', '+', '-', '!', '(', ')', ':', '^', '[', ']', '"', '{', '}', '~', '*', '?', '|', '&', '/'].includes(c);
};

const removeReservedCharacters = (searchQuery) => {
  if (searchQuery) {
    return encodeURIComponent(
      decodeURI(searchQuery)
        .split('')
        .filter((c) => !isReservedCharacterFull(c))
        .join(''),
    );
  }
  return searchQuery;
};

// alerts console
export const fetchTriggeredAlerts = ({payload}) => {
  const noReservedCharactersPayload = payload?.searchQuery
    ? {
        ...payload,
        searchQuery: removeReservedCharacters(payload.searchQuery),
      }
    : payload;

  return get(`${alertsRoot}triggered?${stringifyQS(noReservedCharactersPayload)}`);
};

export const fetchTriggeredAlertsTotal = ({payload}) => get(`${alertsRoot}triggered/total?${stringifyQS(payload)}`);

export const fetchTriggeredAlertForSnooze = ({payload}) => get(`${alertsRoot}triggered/${payload}`);

export const fetchAlertMetricDataPoints = ({payload}) =>
  post(
    `${metricsRoot}composite/execute?fromDate=${payload.startDate}&toDate=${payload.endDate}&startBucketMode=${payload.startBucketMode}&includeBaseline=${payload.includeBaseline}&index=0&size=1&maxDataPoints=500&resolution=${payload.timeScale}`,
    payload.body,
  );

export const fetchAlertEvents = ({payload}) =>
  post(
    `${userEventsRoot}execute?fromDate=${payload.startDate}&index=0&startBucketMode=${payload.startBucketMode}&toDate=${payload.endDate}`,
    payload.body,
  );

export const fetchAlertMultipleEvents = ({payload}) =>
  post(
    `${userEventsRoot}execute-multiple-events?fromDate=${payload.startDate}&index=0&startBucketMode=${payload.startBucketMode}&toDate=${payload.endDate}`,
    payload.body,
  );

export const setStar = ({payload}) => {
  const apiPath = payload.isStarred ? 'star/add' : 'star/remove';
  return post(`${alertsRoot}triggered/${payload.alertId}/${apiPath}`, {userId: payload.meId});
};

export const snoozeTriggerMetrics = ({payload, meta}) => {
  const params = {...payload};
  delete params.includeSTL;

  return post(`${alertsRoot}${meta.alertConfigurationId}/snooze/add?includeSTL=${payload.includeSTL}`, params);
};

export const endSnoozeTriggerMetrics = ({payload, meta}) => {
  const params = {...payload};
  delete params.includeSTL;

  return post(`${alertsRoot}${meta.alertConfigurationId}/snooze/remove?includeSTL=${payload.includeSTL}`, params);
};

export const stlTriggerMetrics = ({payload}) => post(`${metricsRoot}stl/add`, payload);

export const endStlTriggerMetrics = ({payload}) => post(`${metricsRoot}stl/remove`, payload);

export const addMarkAsRead = ({payload}) =>
  post(`${alertsRoot}triggered/${payload.triggeredId}/read/add`, {userId: payload.userId});

export const setAssignee = ({payload}) =>
  put(`${alertsRoot}triggered/${payload.triggerId}/assignee`, {userId: payload.assignee});
export const deleteAssignee = ({payload}) => {
  return delete_(`${alertsRoot}triggered/${payload.triggerId}/assignee`);
};

/* eslint-enable max-len */
