import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useFormContext} from 'react-hook-form';
import {makeStyles} from '@material-ui/core';

import {isCustomerAdmin} from 'profile/store/selectors';
import {EVENT_STREAM_FIELDS} from 'dataCollectors/services/eventStreamService';
import {STREAM_STATE_OPTIONS} from 'dataCollectors/services/dataCollectorsService';
import DynamicSvgCollector from 'dataCollectors/components/svgCollector/DynamicSvgCollector';
import {useHistory} from 'react-router';
import MoreActionEventStream from 'dataCollectors/components/moreActionEventStream/MoreActionEventStream';
import Button, {COLORS} from 'common/componentsV2/Button';
import StreamState from 'dataCollectors/components/streamState/StreamState';

const {SOURCE_NAME, STREAM_NAME, TYPE, STREAM_ID, STREAM_STATE, STREAM_PAUSED} = EVENT_STREAM_FIELDS;

const useStyles = makeStyles(({typography}) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stream: {
    ...typography.h1,
    position: 'relative',

    '&>i': {
      position: 'relative',
      cursor: 'pointer',
      top: -5,
      fontSize: 16,
      marginRight: 5,
    },
  },
  streamName: {
    textTransform: 'capitalize',
  },
  sourceWrapper: {
    marginTop: 4,
    display: 'flex',
    alignItems: 'center',
  },
  sourceImg: {
    '& svg': {
      width: 24,
      height: 24,
    },
  },
  sourceName: {
    marginLeft: 4,
    ...typography.caption,
  },
}));

const StreamHeaderPreview = () => {
  const {getValues} = useFormContext();
  const stream = getValues();
  const classes = useStyles();
  const history = useHistory();
  const isAdminUser = useSelector(isCustomerAdmin);

  const goToList = useCallback(() => {
    history.push('/bc/data-manager/');
  }, []);

  const goToStream = useCallback(() => {
    history.push(`edit?streamId=${stream[STREAM_ID.id]}`);
  }, []);

  return (
    <div className={classes.headerWrapper}>
      <div>
        <div className={classes.stream}>
          <i className="icn-arrow16-arrowleft" onClick={goToList} />
          <span className={classes.streamName}>{stream[STREAM_NAME.id]}</span>
          {'  '}
          <StreamState state={stream[STREAM_STATE.id]} isPaused={stream[STREAM_PAUSED.id]} />
        </div>
        <div className={classes.sourceWrapper}>
          <span className={classes.sourceImg}>
            <DynamicSvgCollector type={stream[TYPE.id]} />
          </span>
          <span className={classes.sourceName}>{stream[SOURCE_NAME.id]}</span>
        </div>
      </div>

      <div className={classes.rightSide}>
        <Button
          automationId="9090909"
          text="Edit stream"
          colorSchema={COLORS.BLUE_500}
          icon="icn-edit-pen-icon"
          onClick={goToStream}
          isDisabled={!isAdminUser || stream.state === STREAM_STATE_OPTIONS.running.value}
        />
        <MoreActionEventStream stream={stream} />
      </div>
    </div>
  );
};

export default StreamHeaderPreview;
