import React from 'react';
import {makeStyles} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import RecommendationFalsePositivesSvg from 'recommendations/components/recommendationsList/listComponents/RecommendationFalsePositivesSvg';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    backgroundColor: palette.blue[50],
    flexDirection: 'column',
    padding: '16px 8px 4px 8px',
    alignItems: 'center',
    gap: 12,
  },
  falsePositivesText: {
    color: palette.gray[400],
    fontWeight: 400,
    fontSize: 16,
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  falsePositivesRate: {
    color: palette.blue[500],
    lineHeight: '80px',
    fontSize: 55,
  },
}));

export default function RecommendationFalsePositives({falsePositivesRate}: {falsePositivesRate: Number}) {
  const classes = useStyles();

  const recommendationFalsePositivesText = `Apply all recommendations to\n reduce false positives by:`;
  return (
    <div>
      <div className={classes.wrapper}>
        <div className={classes.falsePositivesText}>{recommendationFalsePositivesText}</div>
        <RecommendationFalsePositivesSvg falsePositivesPercentage={falsePositivesRate} />
      </div>
    </div>
  );
}
