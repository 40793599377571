/* eslint-disable no-param-reassign */
import {composeReducers} from 'common/utils/reducers';
import {get, cloneDeep, isEmpty} from 'lodash';
import ExpressionItem from 'common/componentsV2/ExpressionBuilderV2/ExpressionItem';
import {CombinedMetric, FunctionDefinition} from 'anodot-objects-models';

import {getUniqueId} from 'common/utils/guid';
import * as dateRangeService from 'common/utils/dateRangeService';
import * as amActions from 'alerts.management/store/actions';
import {fetchDatapoints} from 'dashboards/store/actions';
import deepSearch from 'common/utils/deepSearch';
import {treeVisitor, getSelectedBranch, isCompositeExpression} from 'metrics/services/metricsService';
import * as actions from '../actions';
import {CHART_TYPE, STACKING, Y_AXIS_TYPE} from '../../components/metricExplorer/seriesPropertiesConsts';

// import * as selectors from '../selectors';
const VALIDATION_LIMIT = 1000 * 60 * 2;
const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const persistCaches = ['noCache'];

const clearOutdatedCache = (cacheData) => {
  const validationTime = new Date().getTime() - VALIDATION_LIMIT;
  return cacheData.filter((obj) => persistCaches.includes(obj.index) || obj.time > validationTime);
};

const newDoubleFunction = (originalChild) => {
  let firstChild = [];

  if (originalChild.function === 'groupBy') {
    firstChild = cloneDeep(originalChild);
  } else {
    firstChild = {
      children: originalChild.children,
      function: 'groupBy',
      id: getUniqueId(),
      parameters: [
        {
          name: 'Aggregation',
          value: 'Sum',
        },
        {
          name: 'Group By',
          value: '{"properties":[]}',
        },
      ],
      type: 'function',
    };
  }

  const secondChild = {
    children: [
      {
        searchObject: {
          expression: [],
        },
        children: [],
        type: 'metric',
        id: getUniqueId(),
      },
    ],
    function: 'groupBy',
    id: getUniqueId(),
    parameters: [
      {
        name: 'Aggregation',
        value: 'Sum',
      },
      {
        name: 'Group By',
        value: '{"properties":[]}',
      },
    ],
    type: 'function',
  };

  return [firstChild, secondChild];
};

const newDoubleFunctionSimple = (originalChild) => {
  const firstChild = {
    children: originalChild.children,
    function: 'sumSeries',
    id: getUniqueId(),
    parameters: [],
    type: 'function',
  };

  const secondChild = {
    children: [
      {
        searchObject: {
          expression: [],
        },
        children: [],
        type: 'metric',
        id: getUniqueId(),
      },
    ],
    function: 'sumSeries',
    id: getUniqueId(),
    parameters: [],
    type: 'function',
  };
  return [firstChild, secondChild];
};

export const emptyExpressionTree = {
  root: {
    children: [
      {
        searchObject: {
          expression: [],
        },
        children: [],
        type: 'metric',
        id: getUniqueId(),
        uiIndex: 0,
      },
    ],
    function: '',
    id: getUniqueId(),
    parameters: [],
    type: 'function',
    uiIndex: 0,
  },
};

const getDate = () => {
  const toDate = Math.floor(Date.now());
  const fromDate = toDate - 60 * 60 * 24 * 7;
  return {toDate, fromDate};
};

const getDefaultChartDisplay = () => ({
  chartType: CHART_TYPE.LINE,
  chartStackingOption: STACKING.NONE,
  yAxisOption: Y_AXIS_TYPE.LINEAR,
  lowerRange: '',
  higherRange: '',
  opposite: false,
  isStateDirty: false,
});

const chartDisplayChange = (state, chartDisplayParams) => {
  const index = state.selectedTreeChartDisplay;
  const expressionTree = {
    ...state.expressionTrees[index],
    chartDisplay: {
      ...state.expressionTrees[index].chartDisplay,
      ...chartDisplayParams,
    },
  };

  return {
    ...state,
    expressionTrees: [
      ...state.expressionTrees.slice(0, index),
      expressionTree,
      ...state.expressionTrees.slice(index + 1),
    ],
  };
};

const initId = getUniqueId();
const initialExpressionTree = emptyExpressionTree;

const expressionBuilder = composeReducers(
  (
    state = {
      isFocusOutsideTree: true,
      mainPanelOptions: EMPTY_ARRAY,
      secondPanelOptions: EMPTY_ARRAY,
      cacheData: EMPTY_ARRAY,
      functionDefinitions: EMPTY_ARRAY,
      rawFunctionDefinitions: EMPTY_ARRAY,
      functionProperties: EMPTY_OBJECT,
      previewTimeRange: {
        constRange: '1w',
        startDate: getDate().fromDate,
        endDate: getDate().toDate,
      }, // GABPAC todo -- temporary
      combinedMetric: new CombinedMetric(),
      // expressionTree: exampleExpressionTree,
      expressionTree: initialExpressionTree,
      expressionTrees: [
        {
          id: initId,
          expressionTree: initialExpressionTree,
          chartDisplay: getDefaultChartDisplay(),
          previewOptions: {
            sort: 'highestMax',
            show: 10,
          },
          isShowComposites: true,
        },
      ],
      selectedExpressionId: initId,
      selectedBranch: {},
      selectedElementId: initialExpressionTree.root.id,
      originInfo: EMPTY_OBJECT,
      currentCall: '',
      previewOptions: {
        dateRange: dateRangeService.getDateValue('1w'),
        show: 10,
        sort: 'highestMax',
        timeScale: '',
      },
      newDashboardName: '',
      validationResponse: EMPTY_OBJECT,
      lastExpressionSearches: EMPTY_OBJECT,
      lastUsedMeasures: EMPTY_OBJECT,
      allExpressionsSelected: false,
      mergedMetrics: EMPTY_ARRAY,
      isMergedMetricLoading: false,
      selectedTreeChartDisplay: 0,
      storedState: {},
      isMetricExplorerModalOpen: false,
      isExecuteValid: false,
    },
    {type, payload, meta},
  ) => {
    switch (type) {
      case actions.setMergedMetrics.TYPE: {
        return {
          ...state,
          mergedMetrics: payload,
        };
      }

      case actions.setMetricExplorerModalOpen.TYPE: {
        return {
          ...state,
          isMetricExplorerModalOpen: payload,
        };
      }

      case actions.storeCurrentState.TYPE: {
        return {
          ...state,
          storedState: {
            expressionTree: cloneDeep(state.expressionTree),
            expressionTrees: cloneDeep(state.expressionTrees),
            selectedExpressionId: state.selectedExpressionId,
            selectedBranch: cloneDeep(state.selectedBranch),
            selectedElementId: state.selectedElementId,
          },
        };
      }

      case actions.retrieveStoredState.TYPE: {
        return {
          ...state,
          expressionTree: cloneDeep(state.storedState.expressionTree),
          expressionTrees: cloneDeep(state.storedState.expressionTrees),
          selectedExpressionId: state.storedState.selectedExpressionId,
          selectedBranch: cloneDeep(state.storedState.selectedBranch),
          selectedElementId: state.storedState.selectedElementId,
        };
      }

      case actions.clearAllMetricsExplorer.TYPE: {
        const newInitId = getUniqueId();
        return {
          ...state,
          expressionTree: initialExpressionTree,
          expressionTrees: [
            {
              id: newInitId,
              expressionTree: initialExpressionTree,
              chartDisplay: getDefaultChartDisplay(),
              previewOptions: {
                sort: 'highestMax',
                show: 10,
              },
              isShowComposites: true,
            },
          ],
          selectedExpressionId: newInitId,
          selectedBranch: {},
          selectedElementId: initialExpressionTree.root.id,
        };
      }

      case actions.setMergedMetricsLoading.TYPE: {
        return {
          ...state,
          isMergedMetricLoading: payload,
        };
      }

      case actions.initAlertSettings.TYPE: {
        return {
          ...state,
          previewOptions: {
            dateRange: dateRangeService.getDateValue(get(payload, 'dateRange') || '1w'),
            show: 10,
            sort: 'highestMax',
            timeScale: '',
          },
        };
      }
      case actions.initExpressionTreeModel.TYPE: {
        const expressionTree =
          payload.expressionTree.root.type === 'metric'
            ? {
                root: {
                  children: [cloneDeep(payload.expressionTree.root)],
                  function: '',
                  id: getUniqueId(),
                  parameters: [],
                  type: 'function',
                  uiIndex: 0,
                },
              }
            : cloneDeep(payload.expressionTree);

        treeVisitor(expressionTree.root, 'children', (childNode) => {
          childNode.uiData = {
            ...childNode.uiData,
            failures: [],
          };

          if (childNode.searchObject) {
            childNode.searchObject = {
              ...childNode.searchObject,
              expression: (childNode.searchObject.expression || EMPTY_ARRAY).filter((item) => item.value),
            };
          }
        });

        const index = payload.index || state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        let isShowComposites;
        if (payload.isShowComposites !== undefined) {
          // eslint-disable-next-line prefer-destructuring
          isShowComposites = payload.isShowComposites;
        } else if (payload.excludeComposites !== undefined) {
          isShowComposites = !payload.excludeComposites;
        }
        const expressionTreeEle = {
          id: state.selectedExpressionId,
          expressionTree,
          chartDisplay: state.expressionTrees[index]
            ? state.expressionTrees[index].chartDisplay
            : getDefaultChartDisplay(),
          previewOptions: get(state, `expressionTrees[${index}].previewOptions`, EMPTY_OBJECT),
          isShowComposites:
            payload.isShowComposites !== undefined || payload.excludeComposites !== undefined
              ? isShowComposites
              : !isCompositeExpression(expressionTree),
        };

        return {
          ...state,
          expressionTree,
          selectedBranch: getSelectedBranch(expressionTree, expressionTree.root.id),
          selectedElementId: expressionTree.root.id,
          expressionTrees: [
            ...state.expressionTrees.slice(0, index),
            expressionTreeEle,
            ...state.expressionTrees.slice(index + 1),
          ],
        };
      }
      case actions.fetchPropAndVal.success.TYPE: {
        // Data comes in a very different shape of the format needed to show it. This function will
        // transform it to the view form
        const retArr = [];
        if (payload.originTypes.originTypes.length > 0) {
          retArr.push({label: 'Origin Types:', header: true});
        }
        payload.originTypes.originTypes.forEach((ot) => {
          const ei = new ExpressionItem(ot, ot, null, []);
          retArr.push(ei);
        });
        if (payload.properties.properties.length > 0) {
          retArr.push({label: 'Properties:', header: true});
        }
        payload.properties.properties.forEach((pr) => {
          const ei = new ExpressionItem(pr, pr, null, []);
          retArr.push(ei);
        });
        if (payload.propertyValues.propertyValues.length > 0) {
          retArr.push({label: 'Properties Values:', header: true});
        }
        payload.propertyValues.propertyValues.forEach((pv) => {
          const ei = new ExpressionItem(pv.value, pv.value, pv.key, null);
          ei.isExact = true;
          retArr.push(ei);
        });
        return {
          ...state,
          mainPanelOptions: retArr,
        };
      }

      case actions.fetchPropAndValListApi.TYPE: {
        const now = new Date().getTime();
        const cacheDataObjIndex = state.cacheData.findIndex((item) => item.index === meta);
        if (cacheDataObjIndex > -1) {
          return state;
        }

        const cacheDataObj = {
          index: meta,
          time: now,
          isLoading: true,
          fetchedData: EMPTY_ARRAY,
        };

        return {
          ...state,
          cacheData: [...state.cacheData, cacheDataObj],
        };
      }

      case actions.fetchPropAndValList.TYPE: {
        return {
          ...state,
          currentCall: meta,
        };
      }

      case actions.fetchPropAndValListApi.success.TYPE: {
        let calledExpression = '';

        try {
          const metaJson = JSON.parse(meta);
          calledExpression = metaJson.expression;
        } catch (err) {
          // eslint-disable-next-line no-empty
        }

        const retArr = [];
        if (Array.isArray(payload)) {
          payload.forEach((item) => {
            if (item.values.length > 0) {
              let titleLabel = item.title;
              switch (item.title) {
                case 'Streams':
                  titleLabel = '@Streams';
                  break;
                case 'Composites':
                  titleLabel = '@Composites';
                  break;
                case 'Alerts':
                  titleLabel = '@Alerts';
                  break;
                case 'Tags':
                  titleLabel = '#Tags';
                  break;
                default:
              }
              retArr.push({label: titleLabel, value: item.title, header: true});
              if (item.type === 'dimensions' || item.type === 'tags') {
                item.values.forEach((val) => {
                  const ei = new ExpressionItem(val, val, null, []);
                  retArr.push(ei);
                });
              } else if (item.type === 'measures') {
                item.values.forEach((val) => {
                  const value = val.originId || val.value;
                  const ei = new ExpressionItem(
                    value,
                    val.value,
                    item.title,
                    null,
                    null,
                    null,
                    null,
                    null,
                    true,
                    null,
                    val.streams,
                  );
                  retArr.push(ei);
                });
              } else {
                item.values.forEach((val) => {
                  const value = val.originId || val.value;
                  const ei = new ExpressionItem(value, val.value, item.title, null);
                  ei.isExact = true;
                  retArr.push(ei);
                });
              }
            }
          });
          const firstMeasureIndex = retArr.findIndex((item) => item.label === 'Measures');
          if (firstMeasureIndex > -1 && calledExpression !== '') {
            retArr.splice(
              firstMeasureIndex + 1,
              0,
              new ExpressionItem(
                `${calledExpression}*`,
                `Select All Matching (${calledExpression}*)`,
                'Measures',
                null,
              ),
            );
          }
        }

        const cacheDataObjIndex = state.cacheData.findIndex((item) => item.index === meta);

        return {
          ...state,
          cacheData: [
            ...state.cacheData.slice(0, cacheDataObjIndex),
            {
              ...state.cacheData[cacheDataObjIndex],
              fetchedData: retArr,
              isLoading: false,
            },
            ...state.cacheData.slice(cacheDataObjIndex + 1),
          ],
        };
      }
      case actions.retrievePropAndValListCached.TYPE: {
        const clearCacheData = clearOutdatedCache(state.cacheData);
        return {
          ...state,
          mainPanelOptions: payload.fetchedData,
          cacheData: clearCacheData,
        };
      }

      case actions.fetchPropsApi.TYPE: {
        const now = new Date().getTime();
        const cacheDataObjIndex = state.cacheData.findIndex((item) => item.index === meta);
        if (cacheDataObjIndex > -1) {
          return state;
        }

        const cacheDataObj = {
          index: meta,
          time: now,
          isLoading: true,
          fetchedData: EMPTY_ARRAY,
        };

        return {
          ...state,
          cacheData: [...state.cacheData, cacheDataObj],
        };
      }

      case actions.fetchPropsApi.success.TYPE: {
        const retArr = [];

        payload.propertyValues.forEach((pv) => {
          const ei = new ExpressionItem(pv.value, pv.value, pv.key, null);
          ei.isExact = true;
          retArr.push(ei);
        });

        const cacheDataObjIndex = state.cacheData.findIndex((item) => item.index === meta);

        return {
          ...state,
          cacheData: [
            ...state.cacheData.slice(0, cacheDataObjIndex),
            {
              ...state.cacheData[cacheDataObjIndex],
              fetchedData: retArr,
              isLoading: false,
            },
            ...state.cacheData.slice(cacheDataObjIndex + 1),
          ],
          secondPanelOptions: retArr,
        };
      }

      case actions.retrievePropsCached.TYPE: {
        const clearCacheData = clearOutdatedCache(state.cacheData);
        return {
          ...state,
          secondPanelOptions: payload.fetchedData,
          cacheData: clearCacheData,
        };
      }

      case actions.setPreviewTimeRange.TYPE: {
        // GABPAC todo - Temporary
        return {
          ...state,
          previewTimeRange: payload,
        };
      }

      case actions.setExpressionBuilderContext.TYPE: {
        return {
          ...state,
          context: payload,
        };
      }

      case actions.updateSimulation.TYPE: {
        // GABPAC -- todo check this previewOptions thing
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        const expressionTrees = [
          ...state.expressionTrees.slice(0, index),
          {
            ...state.expressionTrees[index],
            previewOptions: {
              ...get(state, `expressionTrees[${index}].previewOptions`, {}),
              ...payload,
            },
          },
          ...state.expressionTrees.slice(index + 1),
        ];

        return {
          ...state,
          previewOptions: {
            ...state.previewOptions,
            ...payload,
          },
          expressionTrees,
        };
      }

      case actions.setMultipleProperties.TYPE: {
        const index = state.expressionTrees.findIndex((et) => et.id === payload.id);
        const previewOptions = {...state.expressionTrees[index].previewOptions};
        previewOptions[payload.type] = payload.val;
        const expressionTrees = [
          ...state.expressionTrees.slice(0, index),
          {
            ...state.expressionTrees[index],
            previewOptions,
          },
          ...state.expressionTrees.slice(index + 1),
        ];

        return {
          ...state,
          expressionTrees,
        };
      }

      case actions.resetPreviewPagination.TYPE: {
        return {
          ...state,
          previewOptions: {
            ...state.previewOptions,
            show: 10,
          },
        };
      }

      case actions.setNewDashboardName.TYPE: {
        return {
          ...state,
          newDashboardName: payload,
        };
      }

      case actions.fetchFunctionsMetaDataApi.success.TYPE: {
        const functions = [
          {value: 'transform', displayName: 'Transform Functions', multi: []},
          {value: 'combine', displayName: 'Combine Functions', multi: []},
          {value: 'filter', displayName: 'Filter Functions', multi: []},
          {value: 'text', displayName: 'Text Functions', multi: []},
        ];

        payload.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()));
        payload.forEach((item) => {
          if (item.functionGroup === 'top') {
            functions.unshift(new FunctionDefinition(item));
          } else if (item.functionGroup) {
            const func = functions.find((f) => f.value === item.functionGroup);
            func.multi.push(new FunctionDefinition(item));
          }
        });

        const orderingArr = ['Group By', 'Sum', 'Ratio Pairs', 'Scale'];

        orderingArr.forEach((value, i) => {
          const index = functions.findIndex((item) => item.displayName === value);
          const temporaryStore = functions[i];
          functions[i] = functions[index];
          functions[index] = temporaryStore;
        });
        return {
          ...state,
          rawFunctionDefinitions: payload,
          functionDefinitions: functions,
          sortFunctionDefinitions: payload.filter((func) => func.type === 'filter-sort'),
        };
      }
      case actions.setCombinedMetric.TYPE: {
        return {
          ...state,
          combinedMetric: payload,
        };
      }

      case actions.setCompositeObject.TYPE: {
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        const expressionTreeEle = {
          id: state.selectedExpressionId,
          expressionTree: payload,
          chartDisplay: state.expressionTrees[index].chartDisplay,
          previewOptions: state.expressionTrees[index].previewOptions,
          isShowComposites: state.expressionTrees[index].isShowComposites,
        };

        return {
          ...state,
          expressionTree: payload,
          expressionTrees: [
            ...state.expressionTrees.slice(0, index),
            expressionTreeEle,
            ...state.expressionTrees.slice(index + 1),
          ],
        };
      }

      case actions.setAllExpressionsSelected.TYPE:
        return {
          ...state,
          allExpressionsSelected: payload,
        };

      case actions.setExpressionTree.TYPE: {
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);

        const newExpressionTree = cloneDeep(state.expressionTrees[index]);
        newExpressionTree.expressionTree = payload;

        return {
          ...state,
          expressionTree: payload,
          expressionTrees: [
            ...state.expressionTrees.slice(0, index),
            newExpressionTree,
            ...state.expressionTrees.slice(index + 1),
          ],
        };
      }

      case actions.setExpression.TYPE: {
        const {value, id} = payload;
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        const newTree = cloneDeep(state.expressionTrees[index].expressionTree);
        treeVisitor(newTree.root, 'children', (childNode) => {
          if (childNode.id === id) {
            childNode.searchObject.expression = value;
          }
        });

        const expressionTreeEle = {
          id: state.selectedExpressionId,
          expressionTree: newTree,
          chartDisplay: state.expressionTrees[index].chartDisplay,
          previewOptions: state.expressionTrees[index].previewOptions,
          isShowComposites:
            get(value, '[0].originType', '').toLowerCase() === 'composite' ||
            get(value, '[0].originType', '').toLowerCase() === 'alert'
              ? true
              : !isCompositeExpression(newTree),
        };

        // const updateTree = (treeNode, changeNodeId, newValue) => {
        //   if (treeNode.id === changeNodeId) {
        //     return {
        //       ...treeNode,
        //       searchObject: {expression: newValue},
        //       isDirty: true,
        //     };
        //   }
        //   if (treeNode.children.length > 0) {
        //     treeNode.children.forEach((child) => {
        //       if (child !== updateTree(child, changeNodeId, newValue))
        //     })
        //   }
        // };
        return {
          ...state,
          expressionTree: newTree,
          expressionTrees: [
            ...state.expressionTrees.slice(0, index),
            expressionTreeEle,
            ...state.expressionTrees.slice(index + 1),
          ],
        };
      }

      case actions.fetchPropertiesApi.success.TYPE: {
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        if (!state.expressionTree || isEmpty(state.expressionTree)) {
          return state;
        }
        if (payload.properties.length === 0) {
          return state;
        }
        const newTree = cloneDeep(state.expressionTrees[index].expressionTree);
        treeVisitor(newTree.root, 'children', (childNode) => {
          if (childNode.id === meta) {
            if (childNode.parameters.length) {
              const indexOfGroupByParam = childNode.parameters.findIndex((param) => param.name === 'Group By');
              if (indexOfGroupByParam > -1) {
                if (childNode.parameters[indexOfGroupByParam].value) {
                  let parsedValue = [];
                  if (childNode.parameters[indexOfGroupByParam].value !== '{properties:[]}') {
                    parsedValue = JSON.parse(childNode.parameters[indexOfGroupByParam].value).properties;
                    let intersection = [];
                    if (payload.properties.length) {
                      intersection = payload.properties.filter((val) => parsedValue.includes(val));
                      childNode.parameters[indexOfGroupByParam].value = JSON.stringify({properties: intersection});
                    }
                  }
                }
              }
            }

            childNode.uiData = {
              ...childNode.uiData,
              expressionProperties: payload,
            };
          }
        });
        const expressionTreeEle = {
          id: state.selectedExpressionId,
          expressionTree: newTree,
          chartDisplay: state.expressionTrees[index].chartDisplay,
          previewOptions: state.expressionTrees[index].previewOptions,
          isShowComposites: state.expressionTrees[index].isShowComposites,
        };
        return {
          ...state,
          expressionTree: newTree,
          expressionTrees: [
            ...state.expressionTrees.slice(0, index),
            expressionTreeEle,
            ...state.expressionTrees.slice(index + 1),
          ],
        };
      }

      case fetchDatapoints.success.TYPE: {
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        const newTree = cloneDeep(state.expressionTrees[index].expressionTree);
        if (!payload.validation) {
          return state;
        }
        if (!payload.validation.passed) {
          const ids = Object.keys(payload.validation.failures);
          treeVisitor(newTree.root, 'children', (childNode) => {
            if (ids.indexOf(childNode.id) > -1) {
              childNode.uiData = {
                ...childNode.uiData,
                failures: payload.validation.failures[childNode.id],
              };
            } else {
              childNode.uiData = {
                ...childNode.uiData,
                failures: [],
              };
            }
          });
        } else {
          treeVisitor(newTree.root, 'children', (childNode) => {
            childNode.uiData = {
              ...childNode.uiData,
              failures: [],
            };
          });
        }

        const expressionTreeEle = {
          id: state.selectedExpressionId,
          expressionTree: newTree,
          chartDisplay: state.expressionTrees[index].chartDisplay,
          previewOptions: state.expressionTrees[index].previewOptions,
          isShowComposites: state.expressionTrees[index].isShowComposites,
        };

        return {
          ...state,
          isExecuteValid: payload.validation.passed,
          expressionTree: newTree,
          expressionTrees: [
            ...state.expressionTrees.slice(0, index),
            expressionTreeEle,
            ...state.expressionTrees.slice(index + 1),
          ],
        };
      }

      case actions.setFunction.TYPE: {
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        const {value, id} = payload;
        const {expressionTree} = state.expressionTrees[index];
        const newTree = cloneDeep(expressionTree);
        const newId = getUniqueId();
        treeVisitor(newTree.root, 'children', (childNode) => {
          if (childNode.id === id) {
            if (['ratioPairs', 'pairs'].indexOf(value.name) > -1) {
              const meCopy = cloneDeep(childNode);
              meCopy.id = getUniqueId();
              childNode.children = newDoubleFunction(meCopy);
            } else if (
              ['divideSeries', 'subtractSeries'].indexOf(value.name) > -1 &&
              ['divideSeries', 'subtractSeries'].indexOf(expressionTree.root.function) < 0
            ) {
              const meCopy = cloneDeep(childNode);
              meCopy.id = getUniqueId();
              childNode.children = newDoubleFunctionSimple(meCopy);
            }
            Object.keys(childNode).forEach((key) => {
              if (key !== 'children') {
                delete childNode[key];
              }
            });
            childNode.function = value.name;
            childNode.id = newId;
            childNode.parameters = value.parameters.map((param) => ({name: param.name, value: param.defaultValue}));
            childNode.type = 'function';
            childNode.uiData = {
              ...childNode.uiData,
              isFunctionDisplayOnly: value.displayOnly,
            };
          }
        });

        const expressionTreeEle = {
          id: state.selectedExpressionId,
          expressionTree: newTree,
          chartDisplay: state.expressionTrees[index].chartDisplay,
          previewOptions: state.expressionTrees[index].previewOptions,
          isShowComposites: deepSearch(
            newTree,
            'originType',
            (k, v) => v.toLowerCase() === 'composite' || v.toLowerCase() === 'alert',
          )
            ? true
            : !isCompositeExpression(newTree),
        };
        return {
          ...state,
          expressionTree: newTree,
          selectedElementId: newId,
          selectedBranch: getSelectedBranch(newTree, newId),
          expressionTrees: [
            ...state.expressionTrees.slice(0, index),
            expressionTreeEle,
            ...state.expressionTrees.slice(index + 1),
          ],
        };
      }

      case actions.setFunctionParameter.TYPE: {
        const {paramName, newValue, id} = payload;
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        const newTree = cloneDeep(state.expressionTrees[index].expressionTree);
        treeVisitor(newTree.root, 'children', (childNode) => {
          if (childNode.id === id) {
            const newParameters = childNode.parameters.map((param) => {
              if (param.name === paramName) {
                return {name: param.name, value: newValue};
              }
              return param;
            });
            childNode.parameters = newParameters;
          }
        });
        const expressionTreeEle = {
          id: state.selectedExpressionId,
          expressionTree: newTree,
          chartDisplay: state.expressionTrees[index].chartDisplay,
          previewOptions: state.expressionTrees[index].previewOptions,
          isShowComposites: state.expressionTrees[index].isShowComposites,
        };
        return {
          ...state,
          expressionTree: newTree,
          expressionTrees: [
            ...state.expressionTrees.slice(0, index),
            expressionTreeEle,
            ...state.expressionTrees.slice(index + 1),
          ],
        };
      }

      case actions.createNewFunction.TYPE: {
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        const newTree = cloneDeep(state.expressionTrees[index].expressionTree);
        if (newTree.root.id === payload) {
          const newChildNode = cloneDeep(newTree.root);
          const newId = getUniqueId();
          newTree.root = {
            id: newId,
            type: 'function',
            parameters: [],
            function: '',
            children: [newChildNode],
            uiData: {
              ...newTree.root.uiData,
              isSelected: true,
              isSwitchButton: false,
            },
          };
          const expressionTreeEle = {
            id: state.selectedExpressionId,
            expressionTree: newTree,
            chartDisplay: state.expressionTrees[index].chartDisplay,
            previewOptions: state.expressionTrees[index].previewOptions,
            isShowComposites: !isCompositeExpression(newTree),
          };
          return {
            ...state,
            expressionTree: newTree,
            selectedElementId: newId,
            selectedBranch: getSelectedBranch(newTree, newId),
            expressionTrees: [
              ...state.expressionTrees.slice(0, index),
              expressionTreeEle,
              ...state.expressionTrees.slice(index + 1),
            ],
          };
        }

        let parent = {};
        let newChildNode = {};
        let myChildNodeIndex = -1;
        treeVisitor(newTree.root, 'children', (parentOfTheNode) => {
          const childNodeIndex = parentOfTheNode.children.findIndex((node) => node.id === payload);
          if (childNodeIndex > -1) {
            myChildNodeIndex = childNodeIndex;
            parent = parentOfTheNode;
            newChildNode = cloneDeep(parentOfTheNode.children[childNodeIndex]);
          }
        });
        const newId = getUniqueId();
        const newFunction = {
          id: newId,
          type: 'function',
          parameters: [],
          function: '',
          children: [
            {
              ...newChildNode,
              uiData: {
                ...newChildNode.uiData,
                isSwitchButton: false,
              },
            },
          ],
        };
        parent.children[myChildNodeIndex] = newFunction;
        const expressionTreeEle = {
          id: state.selectedExpressionId,
          expressionTree: newTree,
          chartDisplay: state.expressionTrees[index].chartDisplay,
          previewOptions: state.expressionTrees[index].previewOptions,
          isShowComposites: !isCompositeExpression(newTree),
        };
        return {
          ...state,
          selectedElementId: newId,
          selectedBranch: getSelectedBranch(newTree, newId),
          expressionTree: newTree,
          expressionTrees: [
            ...state.expressionTrees.slice(0, index),
            expressionTreeEle,
            ...state.expressionTrees.slice(index + 1),
          ],
        };
      }

      case actions.deleteFunction.TYPE: {
        const resetNode = (node) => {
          node.function = '';
          node.parameters = [];
          if (node.uiData) {
            node.uiData.failures = [];
          }
        };

        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        const newTree = cloneDeep(state.expressionTrees[index].expressionTree);
        let myNode = {};
        let childNodeIndexSelected = 0;
        let clearExpressionInput = null;

        let parentOfMyNode = {};
        if (newTree.root.id === payload.id) {
          myNode = newTree.root;
        } else {
          treeVisitor(newTree.root, 'children', (parentOfNode) => {
            const childNodeIndex = parentOfNode.children.findIndex((node) => node.id === payload.id);
            if (childNodeIndex > -1) {
              myNode = parentOfNode.children[childNodeIndex];
              parentOfMyNode = parentOfNode;
              childNodeIndexSelected = childNodeIndex;
            }
          });
        }
        const deleteAction = payload.uiData.isDeletable;
        if (myNode) {
          switch (deleteAction) {
            case 'reset':
              if (myNode.type === 'function') {
                myNode.parameters = [];
                if (myNode.uiData) {
                  myNode.uiData.failures = [];
                }
                myNode.function = '';
              }
              if (myNode.type === 'metric') {
                myNode.searchObject.expression = [];
                clearExpressionInput = true;
              }
              break;
            case 'resetParams':
              if (myNode.parameters) {
                myNode.parameters.forEach((param) => {
                  param.value = null;
                });
              }
              if (myNode.uiData) {
                myNode.uiData.failures = [];
              }
              break;
            case 'enable':
              if (isEmpty(parentOfMyNode)) {
                // eslint-disable-next-line prefer-destructuring
                newTree.root = myNode.children[0];
              } else if (
                myNode.type === 'function' &&
                (['subtractSeries', 'divideSeries'].indexOf(parentOfMyNode.function) > -1 ||
                  parentOfMyNode.children.length > 1)
              ) {
                parentOfMyNode.children.splice(childNodeIndexSelected, 1, ...myNode.children);
              } else if (parentOfMyNode.children.length > 1) {
                parentOfMyNode.children.splice(childNodeIndexSelected, 1);
              } else {
                parentOfMyNode.children = myNode.children;
              }
              break;
            case 'resetAndChild':
              myNode.children.splice(1, 1);
              resetNode(myNode);
              break;
            case 'deleteAndChild':
              // eslint-disable-next-line prefer-destructuring
              parentOfMyNode.children[0] = myNode.children[0];
              break;
            default:
          }
          if (newTree.root.children.length === 0) {
            newTree.root.children = [
              {
                searchObject: {
                  expression: [],
                },
                children: [],
                type: 'metric',
                id: getUniqueId(),
                uiIndex: 0,
              },
            ];
          }
          const expressionTreeEle = {
            id: state.selectedExpressionId,
            expressionTree: newTree,
            chartDisplay: state.expressionTrees[index].chartDisplay,
            previewOptions: state.expressionTrees[index].previewOptions,
            isShowComposites: clearExpressionInput
              ? state.expressionTrees[index].isShowComposites
              : !isCompositeExpression(newTree),
          };
          return {
            ...state,
            expressionTree: newTree,
            expressionTrees: [
              ...state.expressionTrees.slice(0, index),
              expressionTreeEle,
              ...state.expressionTrees.slice(index + 1),
            ],
          };
        }

        return state;
      }

      case actions.createNewExpression.TYPE: {
        const newId = get(payload, 'id', getUniqueId());
        const thisEmptyExpressionTree = emptyExpressionTree;
        const newExpression = get(payload, 'root') ? payload : thisEmptyExpressionTree;
        const newExpressionTree = {
          id: newId,
          expressionTree: newExpression,
          chartDisplay: getDefaultChartDisplay(),
          previewOptions: {
            sort: 'highestMax',
            timeScale: '',
          },
          isShowComposites: !isCompositeExpression(newExpression),
        };

        const selectedBranch = getSelectedBranch(newExpression, newExpression.root.id);
        return {
          ...state,
          expressionTrees: [...state.expressionTrees, newExpressionTree],
          selectedExpressionId: newId,
          selectedTreeChartDisplay: state.expressionTrees.length,
          selectedElementId: newExpression.root.id,
          selectedBranch,
          allExpressionsSelected: false,
        };
      }

      case actions.removeExpression.TYPE: {
        const index = state.expressionTrees.findIndex((et) => et.id === payload);
        const expressionTrees = state.expressionTrees.slice(0, index).concat(state.expressionTrees.slice(index + 1));
        if (expressionTrees.length === 1) {
          expressionTrees[0].invisible = false;
        }
        return {
          ...state,
          expressionTrees,
          selectedExpressionId: expressionTrees[0].id,
          selectedElementId: expressionTrees[0].expressionTree.root.id,
        };
      }

      case actions.duplicateExpressionTree.TYPE: {
        const index = state.expressionTrees.findIndex((et) => et.id === payload);
        const newExpressionTree = cloneDeep(state.expressionTrees[index]);
        const newId = getUniqueId();
        newExpressionTree.id = newId;
        treeVisitor(newExpressionTree.expressionTree.root, 'children', (childNode) => {
          childNode.id = getUniqueId();
        });

        const expressionTrees = [
          ...state.expressionTrees.slice(0, index),
          newExpressionTree,
          ...state.expressionTrees.slice(index),
        ];

        return {
          ...state,
          expressionTrees,
          selectedExpressionId: newId,
          selectedElementId: newExpressionTree.expressionTree.root.id,
        };
      }

      case actions.setTreeVisible.TYPE: {
        const index = state.expressionTrees.findIndex((et) => et.id === payload.id);
        const expressionTrees = [
          ...state.expressionTrees.slice(0, index),
          {
            ...state.expressionTrees[index],
            invisible: payload.invisible,
          },
          ...state.expressionTrees.slice(index + 1),
        ];

        return {
          ...state,
          expressionTrees,
        };
      }

      case actions.selectTreeBranch.TYPE: {
        const {branchId, selectedExpressionId} = payload;
        const index = state.expressionTrees.findIndex((et) => et.id === selectedExpressionId);
        const {expressionTree} = state.expressionTrees[index];
        const selectedBranch = getSelectedBranch(expressionTree, branchId);
        let isIdPresent = false;
        treeVisitor(expressionTree.root, 'children', (childNode) => {
          if (childNode.id === branchId) {
            isIdPresent = true;
          }
        });

        if (!isIdPresent) {
          return state;
        }

        return {
          ...state,
          selectedBranch,
          selectedElementId: branchId,
          selectedExpressionId,
          previewOptions: {
            ...state.previewOptions,
            sort: state.expressionTrees[index].previewOptions.sort,
            size: state.expressionTrees[index].previewOptions.size,
          },
          selectedTreeChartDisplay: index,
          expressionTree,
          isMergedMetricLoading: false,
          allExpressionsSelected: false,
        };
      }

      case actions.setTreeOutsideState.TYPE: {
        return {
          ...state,
          isFocusOutsideTree: payload,
        };
      }

      case actions.fetchOriginInfo.success.TYPE: {
        return {
          ...state,
          originInfo: payload,
        };
      }

      case actions.validate.success.TYPE: {
        const ret = {...payload, meta};
        return {
          ...state,
          validationResponse: ret,
        };
      }

      case actions.fetchLastExpressionSearches.success.TYPE: {
        return {
          ...state,
          lastExpressionSearches: payload,
        };
      }

      case actions.fetchLastUsedMeasures.success.TYPE: {
        return {
          ...state,
          lastUsedMeasures: payload,
        };
      }

      case amActions.resetExpressionBuilder.TYPE: {
        const newId = get(payload, 'id', getUniqueId());
        return {
          ...state,
          expressionTree: emptyExpressionTree,
          selectedExpressionId: newId,
          expressionTrees: [
            {
              id: newId,
              expressionTree: emptyExpressionTree,
              chartDisplay: getDefaultChartDisplay(),
              previewOptions: {
                sort: 'highestMax',
                show: 10,
              },
              isShowComposites: true,
            },
          ],
        };
      }

      case actions.setSelectedTreeChartDisplay.TYPE: {
        return {
          ...state,
          selectedTreeChartDisplay: payload,
        };
      }

      case actions.setChartType.TYPE: {
        let stacking = STACKING.NONE;
        if (payload === CHART_TYPE.COLUMN) {
          stacking = STACKING.NORMAL;
        }

        return chartDisplayChange(state, {
          chartType: payload,
          isStateDirty: true,
          chartStackingOption: stacking,
        });
      }

      case actions.setChartStackingOption.TYPE: {
        return chartDisplayChange(state, {
          chartStackingOption: payload,
          isStateDirty: true,
        });
      }

      case actions.setYAxisOption.TYPE: {
        return chartDisplayChange(state, {
          yAxisOption: payload,
          isStateDirty: true,
        });
      }

      case actions.setLowerRange.TYPE: {
        return chartDisplayChange(state, {
          lowerRange: payload,
          isStateDirty: true,
        });
      }

      case actions.setHigherRange.TYPE: {
        return chartDisplayChange(state, {
          higherRange: payload,
          isStateDirty: true,
        });
      }

      case actions.setYAxisDirection.TYPE: {
        return chartDisplayChange(state, {
          opposite: payload,
          isStateDirty: true,
        });
      }

      case actions.updateSettings.TYPE: {
        return chartDisplayChange(state, {
          ...state,
          isStateDirty: false,
        });
      }

      case actions.dragExpressionNode.TYPE: {
        const {fromData, intoData} = payload;
        const fromTreeIndex = state.expressionTrees.findIndex((et) => et.id === fromData.treeId);
        const intoTreeIndex = state.expressionTrees.findIndex((et) => et.id === intoData.treeId);
        const fromExpression = fromData.expression;
        const intoExpressionId = intoData.expression.id;

        const newFromExpression = cloneDeep(state.expressionTrees[fromTreeIndex]);
        const newIntoExpression = cloneDeep(state.expressionTrees[intoTreeIndex]);

        // if (fromTreeIndex !== intoTreeIndex) {
        //
        // }

        // remove from tree
        treeVisitor(newFromExpression.expressionTree.root, 'children', (childNode) => {
          let parentNode = {};
          if (childNode.children) {
            const index = childNode.children.findIndex((ele) => ele.id === fromExpression.id);
            if (index > -1) {
              parentNode = childNode;
              parentNode.children = [...parentNode.children.slice(0, index), ...parentNode.children.slice(index + 1)];
            }
          }
        });

        // add into tree
        treeVisitor(newIntoExpression.expressionTree.root, 'children', (childNode) => {
          let parentNode = {};
          if (childNode.children) {
            const index = childNode.children.findIndex((ele) => ele.id === intoExpressionId);
            if (index > -1) {
              parentNode = childNode;
              parentNode.children = [
                ...parentNode.children.slice(0, index),
                cloneDeep(fromExpression),
                ...parentNode.children.slice(index + 1),
              ];
            }
          }
        });

        let expressionTrees = [
          ...state.expressionTrees.slice(0, fromTreeIndex),
          newFromExpression,
          ...state.expressionTrees.slice(fromTreeIndex + 1),
        ];

        expressionTrees = [
          ...expressionTrees.slice(0, intoTreeIndex),
          newIntoExpression,
          ...expressionTrees.slice(intoTreeIndex + 1),
        ];

        // const index = state.expressionTrees.findIndex((et) => et.id === payload);
        // const newExpressionTree = cloneDeep(state.expressionTrees[index]);
        // const newId = getUniqueId();
        // newExpressionTree.id = newId;
        // treeVisitor(newExpressionTree.expressionTree.root, 'children', (childNode) => {
        //   childNode.id = getUniqueId();
        // });
        //
        // const expressionTrees = [
        //   ...state.expressionTrees.slice(0, index),
        //   newExpressionTree,
        //   ...state.expressionTrees.slice(index),
        // ];
        //
        // return {
        //   ...state,
        //   expressionTrees,
        //   selectedExpressionId: newId,
        //   selectedElementId: newExpressionTree.expressionTree.root.id,
        // };
        return {
          ...state,
          expressionTrees,
        };
      }

      case actions.setIsShowComposites.TYPE: {
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        const item = state.expressionTrees[index];
        const expressionTrees = [
          ...state.expressionTrees.slice(0, index),
          {
            ...item,
            isShowComposites: payload,
          },
          ...state.expressionTrees.slice(index + 1),
        ];

        return {
          ...state,
          expressionTrees,
        };
      }

      case actions.addExpressionNode.TYPE: {
        const {id} = payload;
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        const newTree = cloneDeep(state.expressionTrees[index].expressionTree);

        treeVisitor(newTree.root, 'children', (node) => {
          if (node.children.some((child) => child.id === id)) {
            const meCopy = cloneDeep(node);
            meCopy.id = getUniqueId();
            node.children = [
              ...meCopy.children,
              {
                searchObject: {
                  expression: [],
                },
                children: [],
                type: 'metric',
                id: getUniqueId(),
              },
            ];
          }
        });

        const expressionTreeEle = {
          id: state.selectedExpressionId,
          expressionTree: newTree,
          chartDisplay: state.expressionTrees[index].chartDisplay,
          previewOptions: state.expressionTrees[index].previewOptions,
          isShowComposites: state.expressionTrees[index].isShowComposites,
        };
        return {
          ...state,
          expressionTree: newTree,
          expressionTrees: [
            ...state.expressionTrees.slice(0, index),
            expressionTreeEle,
            ...state.expressionTrees.slice(index + 1),
          ],
        };
      }

      case actions.removeExpressionNode.TYPE: {
        const {id} = payload;
        const index = state.expressionTrees.findIndex((et) => et.id === state.selectedExpressionId);
        const newTree = cloneDeep(state.expressionTrees[index].expressionTree);
        treeVisitor(newTree.root, 'children', (childNode) => {
          if (childNode.children.some((child) => child.id === id)) {
            const expressionItemIndex = childNode.children.findIndex((child) => child.id === id);
            childNode.children = [
              ...childNode.children.slice(0, expressionItemIndex),
              ...childNode.children.slice(expressionItemIndex + 1),
            ];
          }
        });

        const expressionTreeEle = {
          id: state.selectedExpressionId,
          expressionTree: newTree,
          chartDisplay: state.expressionTrees[index].chartDisplay,
          previewOptions: state.expressionTrees[index].previewOptions,
          isShowComposites: state.expressionTrees[index].previewOptions,
        };
        return {
          ...state,
          expressionTree: newTree,
          expressionTrees: [
            ...state.expressionTrees.slice(0, index),
            expressionTreeEle,
            ...state.expressionTrees.slice(index + 1),
          ],
        };
      }

      default:
        return state;
    }
  },
);

export default expressionBuilder;
