import React from 'react';
import SolidGauge from 'charts/SolidGauge';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import {palette} from 'app/styles/theme';
import './MetricsUsageGraph.module.scss';

type PropTypes = {
  actualLimit: Number,
  contractLimit: Number,
  cached: Number,
  isLoading: Boolean,
};

export default class MetricsUsageGraph extends React.PureComponent {
  props: PropTypes;

  render() {
    const {contractLimit, actualLimit, cached, isLoading} = this.props;

    return (
      <div styleName={isLoading ? 'container loading' : 'container'}>
        {isLoading && <Spinner color={palette.gray[500]} size={SIZES.BIG_60} />}
        {!isLoading && (
          <div>
            <h3>Cached Metrics</h3>
            <SolidGauge
              id="csMetricsGraph"
              min={0}
              current={cached}
              cutoff={contractLimit}
              max={actualLimit}
              chartHeight={250}
            />
          </div>
        )}
      </div>
    );
  }
}
