import React, {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import OptionComponent from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/OptionComponent';
import ButtonListedComponent from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/ButtonListedComponent';
import MultiLevelDropdown from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/MultiLevelDropdown';
import {fetchDataStreams} from 'bc/store/actions';
import {fetchPropAndValList} from 'metrics/store/actions';
import {getFullyFunctionalDataStreams} from 'alerts.management/store/selectors';
import {getCacheData} from 'metrics/store/selectors';
import {get, isEmpty} from 'lodash';
import {cleanupSpecialChars} from '../services/metricsService';

type PropTypes = {
  onSelect: Function,
  onOptionsChange: Function,
  disabled: boolean,
  rowHeight: Number,
  width: Number,
  height: Number,
  buttonWidth: Number,
  value: any,
  button: Node,
  footerComponent: Node,
  propAndValPrefix: String,
  secondLevelTitle: String,
  isOpen: boolean,
  onToggle: Function,
  usePropsForOpenState: boolean,
};

const SimpleMeasureDropdown = ({
  onSelect,
  onOptionsChange,
  disabled,
  width,
  height,
  buttonWidth,
  rowHeight,
  value,
  button,
  footerComponent,
  propAndValPrefix,
  secondLevelTitle,
  isOpen,
  onToggle,
  usePropsForOpenState,
}: PropTypes) => {
  const dispatch = useDispatch();

  const [ddlOptions, setDdlOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // on first load fetch
  useEffect(() => {
    dispatch(fetchDataStreams());
  }, []);

  const cacheData = useSelector(getCacheData);
  const propsAndValList = useMemo(() => cacheData.find((obj) => obj.index === `${propAndValPrefix}-${searchText}`), [
    cacheData,
    searchText,
  ]);

  // on search text change
  useEffect(() => {
    setIsLoading(true);
    if (!propsAndValList) {
      dispatch(
        fetchPropAndValList(
          {
            expression: searchText,
            listDescription: [
              {
                valueType: 'measures',
                count: 20,
              },
            ],
            filter: [],
            search: '',
          },
          `${propAndValPrefix}-${searchText}`,
        ),
      );
    }
  }, [searchText, propsAndValList]);

  const fullyFunctionalStreams = useSelector(getFullyFunctionalDataStreams);

  // creates\updates dropdown options list
  useEffect(() => {
    setIsLoading(get(propsAndValList, 'isLoading', true));

    const measures = get(propsAndValList, 'fetchedData', [])
      .slice(1)
      .map((item) => item.value);

    const allMeasurementsList = measures.map((measure) => {
      const cleanMeasureName = cleanupSpecialChars(measure);
      return {
        value: cleanMeasureName,
        label: cleanMeasureName,
        streamName: '',
        streamId: '',
        dimensions: [],
        measure: cleanMeasureName,
        aggregation: '',
      };
    });
    const multiLevelMeasurementsList = [];
    const aggregation = {
      counter: 'sum',
      gauge: 'average',
    };
    (fullyFunctionalStreams || []).forEach((item) => {
      const schemaId = item.id;
      const schemaName = item.name;
      const schemaMeasurements = item.schema.columns.filter((col) => col.type === 'metric' && !col.hidden);
      const schemaDimensions = item.schema.columns
        .filter((col) => col.type === 'dimension' && !col.hidden)
        .map((dim) => dim.name);

      schemaMeasurements.forEach((schemaMeasure) => {
        const cleanMeasureName = cleanupSpecialChars(schemaMeasure.name);
        allMeasurementsList.push({
          value: cleanMeasureName,
          label: cleanMeasureName,
          streamName: schemaName,
          streamId: schemaId,
          dimensions: schemaDimensions,
          measure: cleanMeasureName,
          aggregation: aggregation[schemaMeasure.targetType],
        });
      });
    });

    if (!isEmpty(value)) {
      const findValueInAllMeasurementsList = allMeasurementsList.find((item) => item.value === value.value);
      if (!findValueInAllMeasurementsList) {
        allMeasurementsList.push(value);
      }
    }

    allMeasurementsList.forEach((measure) => {
      if (searchText && measure.measure.toLowerCase().indexOf(searchText.toLowerCase()) === -1) {
        return;
      }
      const index = multiLevelMeasurementsList.findIndex((item) => item.value === measure.value);

      if (index === -1) {
        multiLevelMeasurementsList.push(measure);
      } else if (multiLevelMeasurementsList[index].multi) {
        if (measure.streamId) {
          multiLevelMeasurementsList[index].multi.push({
            ...measure,
            value: measure.streamId,
            label: measure.streamName,
          });
        }
      } else {
        if (!multiLevelMeasurementsList[index].streamId && measure.streamId) {
          multiLevelMeasurementsList.push(measure);
        } else if (multiLevelMeasurementsList[index].streamId && measure.streamId) {
          multiLevelMeasurementsList.push({
            value: multiLevelMeasurementsList[index].value,
            label: multiLevelMeasurementsList[index].label,
            multi: [
              {
                ...multiLevelMeasurementsList[index],
                value: multiLevelMeasurementsList[index].streamId,
                label: multiLevelMeasurementsList[index].streamName,
              },
              {...measure, value: measure.streamId, label: measure.streamName},
            ],
          });
        }
        multiLevelMeasurementsList.splice(index, 1);
      }
    });
    multiLevelMeasurementsList.forEach((multilevelMeasurement) => {
      if (multilevelMeasurement.multi) {
        multilevelMeasurement.multi.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      }
    });
    multiLevelMeasurementsList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

    setDdlOptions(multiLevelMeasurementsList);
    if (onOptionsChange) {
      onOptionsChange(multiLevelMeasurementsList);
    }
  }, [fullyFunctionalStreams, propsAndValList, searchText]);

  const onDdlSelect = (val) => {
    setSelectedValue(val);
    if (onSelect) {
      onSelect(val);
    }
  };

  const onDdlSearchChange = (text) => {
    setSearchText(text);
  };

  return (
    <React.Fragment>
      <Box display="flex" alignItems="center">
        <MultiLevelDropdown
          returnToFirstLevelAfterSelect
          optionComponent={<OptionComponent />}
          options={ddlOptions}
          onSelect={onDdlSelect}
          searchText={searchText}
          onSearchChange={onDdlSearchChange}
          rowHeight={rowHeight || 40}
          isUseSearch
          isAsyncData
          width={width || 352}
          height={height || 330}
          buttonWidth={buttonWidth}
          disabled={disabled || false}
          value={value || selectedValue}
          isFirstPanelLoading={isLoading}
          button={button || <ButtonListedComponent placeholder="Select Measure" text={selectedValue.label} />}
          footerComponent={footerComponent}
          secondLevelTitle={secondLevelTitle || 'Select a Source'}
          isOpen={isOpen}
          onToggle={onToggle}
          usePropsForOpenState={usePropsForOpenState}
        />
      </Box>
    </React.Fragment>
  );
};

export default SimpleMeasureDropdown;
