// @flow
import React from 'react';
import './UserEventsTable.module.scss';

type PropTypes = {
  event: Object,
};

export default class UserEventsExpendablePanel extends React.PureComponent {
  props: PropTypes;

  render() {
    const {event} = this.props;

    return (
      <div styleName="event-expandable-container">
        <div styleName="event-expandable-column">
          <div styleName="event-expandable-title">Description: </div>
          <div styleName="event-expandable-content">{event.description}</div>
        </div>
        <div styleName="event-expandable-column">
          <div styleName="event-expandable-title">Properties: </div>
          <div styleName="event-expandable-content">
            {event.properties.map((prop) => (
              <div>{`${prop.key}: ${prop.value}`}</div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
