// @flow
import React, {useMemo} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import {getDateRange} from 'common/utils/dateRangeService';
import {FILTER_KEY_HEADERS} from 'alerts.console/services/alertsConsoleService';
import {palette} from 'app/styles/theme';

const styles = {
  wrapper: {
    height: 20,
    display: 'flex',
    justifyContent: 'flex - end',
  },

  text: {
    fontSize: 14,
    fontWeight: '500',
    color: palette.gray[400],
  },
};

const AlertsCounter = (propTypes: {displayedTotalAlerts: Number, alertsTriggeredTotal: Number}) => {
  const [constRange] = useQueryParam(FILTER_KEY_HEADERS.constRange.id, StringParam);
  const dateRange = useMemo(() => getDateRange(constRange || ''), [constRange]);
  const {displayedTotalAlerts, alertsTriggeredTotal} = propTypes;

  return (
    <div style={styles.wrapper}>
      <span style={styles.text}>
        {`Showing ${
          alertsTriggeredTotal !== null ? `${displayedTotalAlerts}/${alertsTriggeredTotal}` : 'n/a'
        } alerts from the ${dateRange.toLowerCase()}`}
      </span>
    </div>
  );
};

export default AlertsCounter;
