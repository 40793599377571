// @flow
import {useQuery} from 'react-query';
import {post} from 'reactQuery/axiosInstance';
import {QUERY_KEYS} from 'composites/services/compositesService';

const root = 'api/v1/metrics/';
const onGetCompositeName = (payload) => {
  return post(`${root}composite/names?index=0&size=50`, payload);
};

export default function usePostCompositeMetricsNames(payload) {
  const fallback = {};

  const {
    dataUpdatedAt,
    data: compositeName = fallback,
    isLoading: isCompositeNameLoading,
    isFetching: isCompositeNameFetching,
  } = useQuery(`${QUERY_KEYS.COMPOSITE_NAME}${payload.id}`, () => onGetCompositeName(payload), {
    enabled: !!payload?.id,
  });

  return {
    updatedAt: dataUpdatedAt,
    compositeName,
    isCompositeNameLoading,
    isCompositeNameFetching,
  };
}
