import React, {createContext, useContext, useReducer} from 'react';
import PropTypes from 'prop-types';

import {useEditReducer, STAGES_INITIAL_STATE} from 'composites/store/reducers/useCompositesReducer';

const StageStateContext = createContext(undefined);
function CompositesProvider({children}) {
  const [editState, editDispatch] = useReducer(useEditReducer, STAGES_INITIAL_STATE);
  const value = {
    editState,
    editDispatch,
  };

  return <StageStateContext.Provider value={value}>{children}</StageStateContext.Provider>;
}

function useCompositesContext() {
  const context = useContext(StageStateContext);
  if (context === undefined) {
    throw new Error('useCompositesContext must be used within a CompositesProvider');
  }
  return context;
}

CompositesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export {CompositesProvider, useCompositesContext};
