// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {
  setS3StreamFileFormat,
  setS3StreamUiKeyVal,
  setS3StreamFilePathAndNamePattern,
  setSelectedStreamUiKeyVal as setSelectedStreamUiKeyValAction,
} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isEditRunning, isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import FileSettings from 'bc/components/streams/editor/common/FileSettings/FileSettings';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import {segmentCausingEvent} from 'common/store/actions';
import {fileDatePatterns, allowedPollingIntervalsByFileDatePattern} from 'bc/services/genericStorageService';
import SmartTooltip from 'common/components/SmartTooltip';
import {take, get, isEmpty} from 'lodash';
import S3FileNamePattern from './S3FileNamePattern';
import S3FilePreview from './S3FilePreview';
import './S3Data.module.scss';

type PropTypes = {
  dataStream: Object,
  analysisResult: Object,
  isUiStateDirty: boolean,
  isS3FormatAnalysisLoading: boolean,
  setS3StreamFileFormat: Function,
  setS3StreamUiKeyVal: Function,
  setS3StreamFilePathAndNamePattern: Function,
  segmentCausingEvent: Function,
  setSelectedStreamUiKeyVal: Function,
};

const EMPTY_ARRAY = [];

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    analysisResult: selectors.getFileStreamAnalysisResult(state),
    isUiStateDirty: selectors.isS3StreamUiStateDirty(state),
    isS3FormatAnalysisLoading: selectors.isS3CsvFormatAnalysisLoading(state),
  }),
  {
    setS3StreamFileFormat,
    setS3StreamUiKeyVal,
    setS3StreamFilePathAndNamePattern,
    segmentCausingEvent,
    setSelectedStreamUiKeyVal: setSelectedStreamUiKeyValAction,
  },
)(
  class S3Data extends React.PureComponent {
    props: PropTypes;

    state = {
      isOpenFileSettingsModal: false,
      isOpenFilePreviewModal: false,
      isOpenChagePollingIntervalModal: false,
      path: get(this.props.dataStream, 'uiState.path', this.props.dataStream.path),
      fileNameSuffix: get(this.props.dataStream, 'uiState.fileNameSuffix', this.props.dataStream.fileNameSuffix),
      fileNamePrefix: get(this.props.dataStream, 'uiState.fileNamePrefix', this.props.dataStream.fileNamePrefix),
      fileNamePattern: get(this.props.dataStream, 'uiState.fileNamePattern', this.props.dataStream.fileNamePattern),
    };

    componentDidMount() {
      const {dataStream, setSelectedStreamUiKeyVal} = this.props;
      // edit running stream
      if (isStreamRunning(dataStream.state)) {
        // new "analyze" without sever
        if (
          (!dataStream.uiState.analysisResult || isEmpty(dataStream.uiState.analysisResult.fileSchema)) &&
          !isEmpty(dataStream.schema.columns)
        ) {
          const dataArr = [];
          dataStream.schema.columns.forEach((item) => {
            if (!item.transform) {
              const newItem = {
                index: parseInt(item.sourceColumn, 10),
                name: item.name,
                possibleTypes: [item.type],
                type: item.type,
              };
              dataArr.push(newItem);
            }
          });
          setSelectedStreamUiKeyVal({
            ...dataStream.uiState,
            analysisResult: {
              ...dataStream.uiState.analysisResult,
              fileSchema: dataArr,
            },
          });
        }
      }
    }

    getConfirmModalMessage = (fileNamePattern) => (
      <span>
        Timestamp pattern was changed to exclude minutes {fileNamePattern === 'yyyyMMdd' ? 'and hours' : ''}, collection
        interval will be updated to {fileNamePattern === 'yyyyMMdd' ? 'daily' : 'hourly'}
      </span>
    );

    valueChanged = (val, inputId) => {
      const newState = {};
      newState[inputId] = val;
      this.setState(newState);
      this.props.setS3StreamUiKeyVal(newState);
    };

    settingsClicked = () => {
      this.setState((prevState) => ({isOpenFileSettingsModal: !prevState.isOpenFileSettingsModal}));
    };

    fileSettingsChanged = (fileFormat) => {
      this.props.setS3StreamFileFormat({
        fileFormat,
      });
    };

    previewClicked = () => {
      this.setState((prevState) => ({isOpenFilePreviewModal: !prevState.isOpenFilePreviewModal}));
    };

    togglePatternChangedConfirmModal = () => {
      this.setState((prevState) => ({isOpenChagePollingIntervalModal: !prevState.isOpenChagePollingIntervalModal}));
    };

    onConfirmModal = (checkPatternChange = true) => {
      const allowedValues = allowedPollingIntervalsByFileDatePattern[this.state.fileNamePattern];
      if (checkPatternChange) {
        this.togglePatternChangedConfirmModal();
      }

      this.props.setS3StreamFilePathAndNamePattern({
        path: this.state.path,
        fileNameSuffix: this.state.fileNameSuffix,
        fileNamePrefix: this.state.fileNamePrefix,
        fileNamePattern: this.state.fileNamePattern,
        pollingInterval: this.props.dataStream.pollingInterval || allowedValues[allowedValues.length - 1],
      });
    };

    goClicked = () => {
      if (
        fileDatePatterns[this.state.fileNamePattern].order <
        fileDatePatterns[this.props.dataStream.fileNamePattern].order
      ) {
        this.togglePatternChangedConfirmModal();
      } else {
        const editVar = isEditRunning(this.props.dataStream.id) ? '-live' : '';
        this.props.segmentCausingEvent({category: 'bc/stream-query', name: `analyze-s3${editVar}`});
        this.props.setS3StreamFilePathAndNamePattern({
          path: this.state.path,
          fileNameSuffix: this.state.fileNameSuffix,
          fileNamePrefix: this.state.fileNamePrefix,
          fileNamePattern: this.state.fileNamePattern,
        });
      }
    };

    revertClicked = () => {
      const obj = {
        path: this.props.dataStream.path,
        fileNameSuffix: this.props.dataStream.fileNameSuffix,
        fileNamePrefix: this.props.dataStream.fileNamePrefix,
        fileNamePattern: this.props.dataStream.fileNamePattern,
      };
      this.props.setS3StreamUiKeyVal({...obj});
      this.setState({...obj});
    };

    // eslint-disable-next-line complexity
    render() {
      const {path, fileNamePrefix, fileNameSuffix} = this.state;
      const {dataStream, isUiStateDirty, isS3FormatAnalysisLoading} = this.props;
      const {fileFormat, fileName, fileErrors, rowsErrors} = this.props.analysisResult;

      const isEditable = isStreamEditable(dataStream.state);
      const areErrorsPresent = (fileErrors && fileErrors.length) || (rowsErrors && rowsErrors.length);

      return (
        <div className="shell-col" styleName="root">
          <PanelHeader title="Files to Collect" isEditable={isEditable}>
            <button
              type="button"
              className="btn btn-icon btn-icon btn-dark-blue"
              disabled={!fileName || isUiStateDirty || isS3FormatAnalysisLoading}
              onClick={this.previewClicked}
            >
              <i className="icon icn-view-icon" />
            </button>
            <button
              type="button"
              className="btn btn-icon btn-icon btn-dark-blue"
              disabled={!fileName || !isEditable || isUiStateDirty || isS3FormatAnalysisLoading}
              onClick={this.settingsClicked}
            >
              <i className="icon icn-settings-icon" />
            </button>
          </PanelHeader>

          {this.state.isOpenFileSettingsModal && (
            <FileSettings
              isOpen={this.state.isOpenFileSettingsModal}
              onClose={this.settingsClicked}
              onChange={this.fileSettingsChanged}
              fileFormat={fileFormat}
              fileName={fileName}
            />
          )}

          {this.state.isOpenFilePreviewModal && (
            <S3FilePreview isOpen={this.state.isOpenFilePreviewModal} onClose={this.previewClicked} />
          )}

          {this.state.isOpenChagePollingIntervalModal && (
            <ConfirmationModal
              onClose={this.togglePatternChangedConfirmModal}
              onConfirm={this.onConfirmModal}
              title="Change Collection Interval"
              message={this.getConfirmModalMessage(this.state.fileNamePattern)}
              isOpen={this.state.isOpenChagePollingIntervalModal}
            />
          )}

          <div styleName="inputs-wrapper">
            <span className="ellipsis" styleName="input-title">
              Files Path
            </span>
            <span styleName="input-description">[Optional] Specify a path relative to the data source.</span>
            <input
              type="text"
              onChange={(e) => this.valueChanged(e.target.value, 'path')}
              placeholder="Relative path within the bucket"
              value={path}
            />

            <S3FileNamePattern
              value={get(dataStream.uiState, 'fileNamePattern', dataStream.fileNamePattern)}
              styleName="file-pattern"
              onChange={(pattern) => this.valueChanged(pattern, 'fileNamePattern')}
            />

            <span className="ellipsis" styleName="input-title">
              Filename Prefix
            </span>
            <span styleName="input-description">
              [Optional] Filename prefix before date format &quot;YYYYMMDDHH&quot;.
              <br />
              (eg. <b>data_</b>
              2018031822_datafile.csv)
            </span>
            <input
              type="text"
              onChange={(e) => this.valueChanged(e.target.value, 'fileNamePrefix')}
              placeholder="example: data_"
              value={fileNamePrefix}
            />

            <span className="ellipsis" styleName="input-title">
              Filename Suffix
            </span>
            <span styleName="input-description">
              Filename suffix after date format &quot;YYYYMMDDHH&quot;.
              <br />
              (eg. data_2018031822
              <b>_datafile.csv</b>)
            </span>
            <input
              type="text"
              onChange={(e) => this.valueChanged(e.target.value, 'fileNameSuffix')}
              placeholder="example: _datafile.csv"
              value={fileNameSuffix}
            />

            <button
              type="button"
              className="btn btn-raised"
              disabled={!fileNameSuffix && !fileNamePrefix}
              onClick={this.goClicked}
            >
              {areErrorsPresent ? 'RETRY' : 'GO!'}
            </button>
            <button
              type="button"
              className="btn btn-flat btn-secondary"
              styleName="revert-btn"
              disabled={!dataStream.uiState || !isUiStateDirty}
              onClick={this.revertClicked}
            >
              REVERT
            </button>
          </div>

          {(fileErrors || EMPTY_ARRAY).length > 0 && (
            <div>
              <div styleName="error-title">File Errors</div>
              {fileErrors.map((e, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div styleName="error" key={i}>
                  <div className="ellipsis">{e.message}</div>
                </div>
              ))}
            </div>
          )}

          {(rowsErrors || EMPTY_ARRAY).length > 0 && (
            <div>
              <div styleName="error-title">Row Errors</div>
              {take(rowsErrors, 20).map((e) => (
                <div styleName="error" className="shell" key={e.rowIdx}>
                  <div styleName="row-error-num">{e.rowIdx}</div>
                  {e.row ? (
                    <SmartTooltip placement="top" content={e.row}>
                      <div className="ellipsis">{e.message}</div>
                    </SmartTooltip>
                  ) : (
                    <div className="ellipsis">{e.message}</div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
  },
);
