// @flow
import React, {useEffect} from 'react';
import {abbrNum} from 'common/utils/numbers';
import './Gauges.module.scss';

const Gauge = ({
  description,
  index,
  isLoading,
  fetchData,
  selectedItem,
  value,
}: {
  description: String,
  index: Number,
  isLoading: Boolean,
  fetchData: Function,
  selectedItem: Number,
  value: Number,
}) => {
  useEffect(() => {
    if (index === selectedItem && fetchData) {
      fetchData();
    }
  });

  return (
    <div styleName={isLoading ? 'gauge-container loading' : 'gauge-container'}>
      <h5 styleName="value">{abbrNum(value, 1, true)}</h5>
      <span styleName="description">{description}</span>
    </div>
  );
};

export default Gauge;
