// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import {isReadOnlyUser, getProfileId} from 'profile/store/selectors';
import {segmentClickEvent} from 'common/store/actions';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {setAlertTriggerAcknowledgement} from 'alerts.console.new/api/api';
import {queryErrorHandler} from 'reactQuery/queryClient';
import fetchTriggeredAlertsProvider from 'alerts.console.new/api/fetchTriggeredAlertsProvider';
import fetchUsersProvider from 'alerts.console.new/api/fetchUsersProvider';
import fetchGroupAlertsWithMetricsProvider from 'investigationNewAlertConsole/api/fetchGroupAlertsWithMetricsProvider';
import {modalRouting} from 'investigationNewAlertConsole/services/investigationService';

import './Acknowledge.module.scss';

const AcknowledgeAction = ({
  acknowledgedBy,
  alertGroupId,
  alertTriggerId,
  queryParams,
}: {
  acknowledgedBy: Array<String>,
  alertGroupId: String,
  alertTriggerId: String,
  queryParams: Object,
}) => {
  const meId = useSelector(getProfileId);
  const isReadOnly = useSelector(isReadOnlyUser);
  const isAcknowledgedByMe = acknowledgedBy?.userIds?.some((ack) => ack?.id === meId);
  const triggeredAlertsProvider = fetchTriggeredAlertsProvider(queryParams);

  // Need to update the trigger of triage react query cache
  const investigationModalCacheParams = {
    [modalRouting.ANOMALY_ID]: alertGroupId,
    [modalRouting.TRIGGER_ID]: alertTriggerId,
    [modalRouting.INVESTIGATION_MODAL]: true,
  };
  const triggeredAlertGroupProvider = fetchGroupAlertsWithMetricsProvider(investigationModalCacheParams);

  const users = fetchUsersProvider().useQuery();
  const meUser = users?.data?.find((user) => user.id === meId);

  const acknowledgeClicked = async () => {
    if (!isReadOnly) {
      segmentClickEvent({
        type: 'click',
        name: 'acknowledge alert',
      });

      const queryCacheParams = {
        alertTriggerId,
        isAcknowledged: !isAcknowledgedByMe,
        meUser,
      };
      triggeredAlertsProvider.syncAcksInCache(alertTriggerId, queryCacheParams);
      try {
        const payload = {
          isAcknowledged: !isAcknowledgedByMe,
          meId,
          alertTriggerId,
        };
        await setAlertTriggerAcknowledgement(payload).promise;
        await triggeredAlertsProvider.resetAllExceptCurrent();
        // Update the React Query cache of Triage alert trigger in case of success
        triggeredAlertGroupProvider.syncAcksInCache(queryCacheParams);
      } catch (e) {
        const error = {
          title: 'Acknowledgement error',
          description: `Error while ${!isAcknowledgedByMe ? 'setting' : 'removing'} acknowledgement`,
        };
        queryErrorHandler(error);
        // Need to synchronize the view of alerts with the server, since cannot be sure whether the request succeeded or failed.
        await triggeredAlertsProvider.invalidate();
      }
    }
  };

  return (
    <SmartTooltip placement="top" content={isAcknowledgedByMe ? 'Remove Acknowledge' : 'Acknowledge'} delay={400}>
      <div className="alert-clickable-item" onClick={acknowledgeClicked} styleName="ack-action" role="button">
        <i styleName="btn-icon" className="icon icn-action16-acknowledge" />
      </div>
    </SmartTooltip>
  );
};

export default AcknowledgeAction;
