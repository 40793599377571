import {combineEpics} from 'redux-observable';

import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';
import * as actions from 'userEvents/store/actions';
import * as api from 'userEvents/services/api';
import * as selectors from 'userEvents/store/selectors';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {getBucketStartTimeEnabled} from 'profile/store/selectors';

const fetchPropAndValListApi = makeAsyncEpic(actions.fetchPropAndValListApi, api.fetchPropAndValListApi);
const fetchPropsApi = makeAsyncEpic(actions.fetchPropsApi, api.fetchPropsApi);
const execute = makeAsyncEpic(actions.execute, api.execute);
const executeMultipleEvents = makeAsyncEpic(actions.executeMultipleEvents, api.executeMultipleEvents);
const executeMoreTopEvents = makeAsyncEpic(actions.executeMoreTopEvents, api.execute);
const fetchEventsCount = makeAsyncEpic(actions.fetchEventsCount, api.fetchEventsCount);
const fetchMetricDimensions = makeAsyncEpic(actions.fetchMetricDimensions, api.fetchMetricDimensions);

const fetchEventsProperties = makeAsyncEpic(actions.fetchEventsProperties, api.fetchPropsApi);

const fetchPropAndValList = (action$) =>
  action$.ofType(actions.fetchPropAndValList.TYPE).flatMap(({payload, meta}) => {
    const apiCall = {
      expression: payload.expression,
      filter: payload.filter,
      size: 50,
    };
    return [actions.fetchPropAndValListApi(apiCall, meta)];
  });

const setDateRange = (action$) => action$.ofType(actions.setExecuteParams.TYPE).flatMap(() => [actions.getEvents()]);

const setExpression = (action$) =>
  action$.ofType(actions.setExpression.TYPE).flatMap(({meta}) => {
    if (!meta) {
      return [];
    }
    return [actions.getEvents()];
  });

const createUrlForEvents = (getState, isAutoSimulationEnabled, isSimulationReferenceDateEnabled) => {
  const curState = getState();
  const dateRange = selectors.getDateRange(curState);
  const startBucketMode = getBucketStartTimeEnabled(curState);
  const toDate = dateRange.endDate ? Math.ceil(dateRange.endDate / 1000) : Math.ceil(new Date().getTime() / 1000);
  let fromDate = dateRange.startDate ? Math.ceil(dateRange.startDate / 1000) : toDate - 60 * 60 * 24 * 7;
  // Currently, since there is no backend api for bringing events for simulation, it was decided by Product to bring all events 120 days back.
  const daysBack = isSimulationReferenceDateEnabled ? 365 : 120; // For POC bring events 365 days back.
  if (isAutoSimulationEnabled) {
    fromDate = Math.min(fromDate, toDate - 60 * 60 * 24 * daysBack);
  }
  return `?fromDate=${fromDate}&index=0&startBucketMode=${startBucketMode}&toDate=${toDate}`;
};

const createBodyForEvents = (getState) => {
  const curState = getState();
  const aggregation = selectors.getAggregation(curState);
  const expression = selectors.getExpressionForExecute(curState);
  return {
    aggregation,
    filter: {
      categories: [],
      q: {
        expression,
      },
    },
    selectorsFilter: {
      selectors: [],
    },
  };
};

const createBodyForMultipleEvents = (expressions, getState) => {
  const curState = getState();
  const aggregation = selectors.getAggregation(curState);
  const ret = expressions?.map((exp) => {
    const expression = exp.expression.map((ex) => ex.getExpressionTreeObjectification());
    return {
      aggregation,
      filter: {
        categories: [],
        type: exp.eventType.value,
        q: {
          expression,
        },
      },
      id: exp.id,
      selectorsFilter: {
        selectors: [],
      },
    };
  });
  return ret;
};

const getMultipleEvents = (action$, {getState}) =>
  action$.ofType(actions.getMultipleEvents.TYPE).flatMap(({payload}) => {
    const url = createUrlForEvents(
      getState,
      payload?.isAutoSimulationEnabled,
      payload?.isSimulationReferenceDateEnabled,
    );
    const body = createBodyForMultipleEvents(payload?.validEvents, getState);
    return [actions.setMultipleEventsFilters(payload?.validEvents), actions.executeMultipleEvents({url, body})];
  });

const getEvents = (action$, {getState}) =>
  action$.ofType(actions.getEvents.TYPE).flatMap(() => {
    const url = createUrlForEvents(getState);
    const body = createBodyForEvents(getState);
    // console.log('%c GABPAC ------- "return here" ', 'background: #222; color: yellow', 'return here');
    return [actions.execute({url, body})];
    // return [];
  });

const getMoreTopEvents = (action$, {getState}) =>
  action$.ofType(actions.getMoreTopEvents.TYPE).flatMap(({payload}) => {
    const curState = getState();
    const events = selectors.getEvents(curState);
    const event = events.find((ev) => ev.id === payload);
    if (!event) {
      return [];
    }

    const eventsResolution = selectors.getEventsResolution(curState);
    let delta = null;
    switch (eventsResolution) {
      case 'short':
        delta = 60;
        break;
      case 'medium':
        delta = 60 * 5;
        break;
      case 'long':
        delta = 60 * 60;
        break;
      case 'longlong':
        delta = 60 * 60 * 24;
        break;
      case 'weekly':
        delta = 60 * 60 * 24 * 7;
        break;
      default:
        delta = null;
    }
    if (!delta) {
      return [];
    }

    const fromDate = event.date;
    const toDate = event.date + delta - 1;

    const expression = selectors.getExpressionForExecute(curState);
    const url = `?fromDate=${fromDate}&index=0&size=100&startBucketMode=true&toDate=${toDate}`;

    const body = {
      aggregation: null,
      filter: {
        categories: [],
        q: {
          expression,
        },
      },
      selectorsFilter: {
        selectors: [],
      },
    };

    return [actions.executeMoreTopEvents({url, body}, {eventId: payload})];
  });

const fetchProps = (action$) =>
  action$.ofType(actions.fetchProps.TYPE).flatMap(({payload, meta}) => {
    const apiCall = {
      expression: payload.expression,
      filter: payload.filter,
      properties: payload.properties,
      size: 50,
    };
    return [actions.fetchPropsApi(apiCall, meta)];
  });

const userEventEpic = combineEpics(
  fetchPropAndValList,
  fetchPropAndValListApi,
  fetchProps,
  fetchPropsApi,
  execute,
  executeMultipleEvents,
  getMultipleEvents,
  setExpression,
  setDateRange,
  getEvents,
  executeMoreTopEvents,
  getMoreTopEvents,
  fetchEventsCount,
  fetchMetricDimensions,
  fetchEventsProperties,
);
export default userEventEpic;
