import React, {useEffect, useMemo, useState} from 'react';
import {success} from 'common/utils/notifications/notificationsService';
import {fetchChannels, bulkPutChannels} from 'alerts.channels/store/actions';
import useAsyncAction from 'common/utils/useAsyncAction';
import {getActiveChannels, getBulkPutChannels, getFetchChannels, getPutChannel} from 'alerts.channels/store/selectors';
import {keyBy} from 'lodash';
import channelTypes from 'channels/constants/channelTypes';
import AsyncButton from 'common/componentsV2/AsyncButton';
import {Backdrop, makeStyles, Modal} from '@material-ui/core';
import {useSelector, useStore} from 'react-redux';
import * as amSelectors from 'alerts.management/store/selectors';

const useStyles = makeStyles(({palette}) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    height: 318,
    backgroundColor: palette.white[500],
    borderRadius: 7,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.3)',
    margin: 'auto',
    padding: '16px 24px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: '12px',
  },
  channelsList: {
    maxHeight: '100%',
    overflowY: 'auto',
  },
  channel: {
    marginTop: 12,
  },
  text: {
    fontSize: '14px',
    marginBottom: '40px',
  },
  logo: {
    width: '20px',
    height: '20px',
    marginRight: '6px',
  },
}));

const channelsByValue = keyBy(channelTypes, 'value');

const DeleteChannel = ({
  match,
  onClose,
  isBulk,
  selectedItems,
  setCheckedItems,
}: {
  match: Object,
  onClose: Function,
  isBulk: Boolean,
  selectedItems: Array,
  setCheckedItems: Function,
}) => {
  const store = useStore();
  const channelsData = useSelector(getActiveChannels);
  const classes = useStyles();
  const alertConfigurations = useSelector(amSelectors.getAlertConfigurations);
  const {isLoading: isFetchLoading} = useSelector(getFetchChannels);
  const {isLoading: isBulkPutLoading} = useSelector(getBulkPutChannels);
  const {isLoading: isPutLoading} = useSelector(getPutChannel);
  const bulkPutChannelAsync = useAsyncAction(bulkPutChannels, getBulkPutChannels);
  const fetchChannelsAsync = useAsyncAction(fetchChannels, getFetchChannels);

  const [isBulkDelete, setIsBulkDelete] = useState(isBulk);
  useEffect(() => {
    if (isBulk && selectedItems.length === 1) {
      setIsBulkDelete(false);
    }
  }, [isBulk, selectedItems]);

  const channelIds = isBulk ? selectedItems : [match.params.channelId];
  const channelsList = useMemo(() => {
    return (
      isBulkDelete &&
      selectedItems.map((item) => {
        const channelData = channelsData.find((c) => c.id === item);
        const channelAlerts = alertConfigurations.items.filter((alert) => alert.data.channels.includes(item));
        return (
          channelData && (
            <div key={item} className={classes.channel}>
              <div>{`${channelsByValue[channelData.channelMeta.id].label} Channel: ${channelData.name}`}</div>
              <div className="fontWeight_500">
                {`Recipient of ${channelAlerts.length} ${channelAlerts.length === 1 ? 'alert' : 'alerts'}`}
              </div>
            </div>
          )
        );
      })
    );
  }, [channelsData, selectedItems, isBulkDelete]);

  const channel = channelsData.find((item) => channelIds.includes(item.id));
  if (!channel) {
    return <></>;
  }
  const alerts = alertConfigurations.items.filter((alert) => alert.data.channels.includes(channel.id));

  const onConfirm = () => {
    bulkPutChannelAsync({
      body: {
        channelsData: channelIds.map((channelId) => {
          const channelToDelete = channelsData.find((item) => item.id === channelId);
          if (!channelToDelete) {
            return null;
          }
          const channelTypeToDelete = channelToDelete.channelMeta.id;
          return {
            channelType: channelTypeToDelete,
            channelId: channelToDelete.id,
            channelData: {
              ...channelToDelete,
              state: 'inactive',
              type: undefined,
            },
          };
        }),
      },
    })
      .then(() => fetchChannelsAsync({refresh: true}))
      .then(() => {
        const {data: response} = getBulkPutChannels(store.getState());
        if (response?.length > 0) {
          const deletedRecods = response.map((item) => item.id);
          const successMsg = response.map((item) => {
            return `${channelsByValue[item.channelMeta.id].label} Channel: ${item.name} was deleted.`;
          });
          success({
            settings: {
              autoDismiss: 2.5,
            },
            title: `Channel${response?.length > 1 ? 's' : ''} Deleted`,
            description: successMsg.join('\n'),
          });

          if (setCheckedItems) {
            setCheckedItems(selectedItems?.filter((item) => deletedRecods.includes(item.id)));
          }

          onClose();
        }
      });
  };

  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={onClose}>
      <div className={classes.wrapper}>
        <React.Fragment>
          <div className={classes.title}>
            {isBulkDelete
              ? `Delete ${channelsList.length} Channels?`
              : `Delete ${channelsByValue[channel.channelMeta.id].label} Channel: ${channel.name}?`}
          </div>
          <div className="fontWeight_500 mb_1">Deleting a Channel is permanent and cannot be undone.</div>
          <div className="mb_1">
            {isBulkDelete ? (
              <div className={classes.channelsList}>{channelsList}</div>
            ) : (
              <>
                {' '}
                This channel is the recipient of{' '}
                <span className="fontWeight_500">
                  {alerts.length} {alerts.length === 1 ? 'alert' : 'alerts'}
                </span>
              </>
            )}
          </div>
          <div className="flexGrow_1">Alerts won’t be affected</div>

          <div className="display_flex justifyContent_space-between alignItems_center alignSelf_flex-end">
            <div className="mr_1-5">
              <AsyncButton
                automationId="cancelDeleteChannel"
                text="Cancel"
                colorSchema="gray.300"
                isLoading={false}
                onClick={onClose}
              />
            </div>
            <AsyncButton
              colorSchema="red.500"
              isLoading={isPutLoading || isBulkPutLoading || isFetchLoading}
              automationId="deleteChannel"
              text="Delete"
              onClick={onConfirm}
            />
          </div>
        </React.Fragment>
      </div>
    </Modal>
  );
};

export default DeleteChannel;
