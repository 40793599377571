// @flow
import React from 'react';

import GroupButton from './GroupButton';
import './ConceptGroups.module.scss';

const categorizedConcepts = (concepts) =>
  concepts.reduce(
    // eslint-disable-next-line no-return-assign
    (result, {category, uiName}) => (
      // eslint-disable-next-line no-sequences,no-param-reassign
      (result[category] = result[category] || []), result[category].push(uiName), result
    ),
    {},
  );
const flatCategories = (categories) => Object.entries(categories).map(([category, concepts]) => ({category, concepts}));

const ConceptGroups = ({concepts, selectedCategory, setSelectedCategory}: any) => (
  <div styleName="groups">
    {flatCategories(categorizedConcepts(concepts))
      .sort((a, b) => a.category.localeCompare(b.category))
      .map(({category}) => (
        <GroupButton
          key={category}
          {...{
            category,
            concepts,
            selectedCategory,
            setSelectedCategory,
          }}
        />
      ))}
  </div>
);

export default ConceptGroups;
