// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import SchedularData from 'bc/components/streams/editor/common/SchedularData';
import {isStreamEditable} from 'bc/services/dataStreamService';
import {get} from 'lodash';
import './GoogleStorageSchedular.module.scss';

type PropTypes = {
  dataStreamState: boolean,
  uiState: Object,
  setSelectedEditor: Function,
};

export default connect(
  (state) => ({
    dataStreamState: selectors.getSelectedDataStream(state).state,
    uiState: selectors.getSelectedDataStream(state).uiState,
  }),
  {
    setSelectedEditor,
  },
)(
  class GoogleStorageSchedular extends React.PureComponent {
    props: PropTypes;

    editClicked = () => {
      this.props.setSelectedEditor({
        type: 'schedular_gs',
        editorHeight: 'calc(100% - 210px)',
        maxHeight: '600px',
      });
    };

    render() {
      const {dataStreamState, uiState} = this.props;
      const isEditable = isStreamEditable(dataStreamState) && get(uiState, 'analysisResult.fileSchema');

      return (
        <div className="shell-col" automation-id="gsScheduler" styleName={!isEditable ? 'disabled' : ''}>
          <PanelHeader title="Schedule file collection" onSelect={this.editClicked} isEditable={isEditable} />
          <SchedularData />
        </div>
      );
    }
  },
);
