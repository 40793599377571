/* eslint-disable */
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDrag} from 'react-dnd';
import {palette} from 'app/styles/theme';
import './Draggable.module.scss';

type PropsType = {
  children: Node,
  itemData: Object,
  type: string,
  onDragChange: Function,
  onDropIntoValidDropzone: Function, // f(fromId, intoId);
  disabled: boolean,
};

const Draggable = ({children, itemData, type, onDragChange, onDropIntoValidDropzone, disabled}: PropsType) => {
  const refContainer = useRef(null);
  const [collected, drag, preview] = useDrag(() => ({
    type,
    item: itemData,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        onDropIntoValidDropzone(item, dropResult);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  let handle = null;
  if (refContainer.current) {
    const h = refContainer.current.firstChild.getElementsByClassName('handle');
    if (h && h.length > 0) {
      handle = h[0];
    }
  }

  if (!disabled) {
    drag(handle);
  }

  useEffect(() => {
    onDragChange(collected.isDragging);
  }, [collected.isDragging]);

  if (handle) {
    return (
      <div ref={refContainer}>
        {collected.isDragging ? (
          <div
            style={{
              width: refContainer.current.clientWidth,
              height: refContainer.current.offsetHeight,
              border: `solid 2px ${palette.gray[200]}`,
              borderRadius: '6px',
            }}
          />
        ) : (
          <div className="children-holder" style={{transform: 'translate(0, 0)'}} ref={preview}>
            {children}
          </div>
        )}
      </div>
    );
  }

  if (disabled) {
    return <div>{children}</div>;
  }

  return (
    <div ref={refContainer}>
      <div ref={drag}>{children}</div>
    </div>
  );
};

export default Draggable;
