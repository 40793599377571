import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {getDay} from 'common/utils/dateService';
import {palette} from '../../../app/styles/theme';
import TimelineStatusLine from './TimelineStatusLine';

const TimelineDayGroup = ({isHidden, date, timeZoneName, alertStatus}) => {
  if (isHidden) {
    return null;
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: 30,
      }}
    >
      <div
        style={{
          zIndex: '1',
          marginRight: 'auto',
          marginTop: -5,
          padding: '2px 10px',
          borderRadius: 23,
          backgroundColor: palette.gray['200'],
          color: palette.gray['500'],
          border: `2px solid ${palette.white['500']}`,
        }}
      >
        {getDay(date, timeZoneName)}
      </div>
      <TimelineStatusLine isLast={false} alertStatus={alertStatus} top={20} />
    </div>
  );
};

TimelineDayGroup.propTypes = {
  isHidden: PropTypes.bool.isRequired,
  date: PropTypes.number.isRequired,
  timeZoneName: PropTypes.string.isRequired,
  alertStatus: PropTypes.string.isRequired,
};

export default memo(TimelineDayGroup);
