import * as actions from 'bc/store/actions';
import {composeReducers} from 'common/utils/reducers';

const EMPTY_ARRAY = [];

const eventStreams = composeReducers((state = {data: EMPTY_ARRAY}, {type, payload}) => {
  switch (type) {
    case actions.setEventStreamsFromReactQueryToReducer.TYPE: {
      return {
        ...state,
        data: payload,
      };
    }
    default:
      return state;
  }
});

export default eventStreams;
