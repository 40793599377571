// @flow
import React from 'react';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import {DragDropContext} from 'react-beautiful-dnd';
import TimeDefinition from 'bc/components/streams/editor/common/TimeDefinition';
import DiametricsDraggableList from 'bc/components/streams/editor/common/DiametricsDraggableList';
import SearchBox from 'common/components/SearchBox';
import TimestampType from 'common/components/dateTime/TimestampType';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';

import './DimensionsAndMetricsEditor.module.scss';

type PropTypes = {
  dimensions: Array<Object>,
  metrics: Array<Object>,
  filteredUnassignedColumns: Array<Object>,
  overwriteIdentifier: String,
  timeDefinitionArr: Array,
  timeDefinition: Object,
  timestampType: String,
  filterTextInput: String,
  setStreamDiametricsChange: Function,
  setStreamClearAllDiametrics: Function,
  setStreamTimeDefinition: Function,
  removeStreamDiametrics: Function,
  setMetricDimentionEditorFilterTextInput: Function,
  onTimestampTypeChange: Function,
  preview: Node,
  dateFormats: Array<Object>,
  isStreamUpdating: Boolean,
  clearAllText: String,
  isRemoveDateSection: Boolean,
  isRemoveMetricsSection: Boolean,
};

const TRUE = true;

class DimensionsAndMetricsEditor extends React.PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    this.state = {
      isOpenFilePreviewModal: false,
    };
  }

  onDragStart = () => {
    document.body.classList.add('grabbing');
  };

  onDragEnd = (result) => {
    document.body.classList.remove('grabbing');
    if (!result.destination) {
      // dropped outside the list
      return;
    }

    this.props.setStreamDiametricsChange(result);
  };

  previewClicked = () => {
    this.setState((prevState) => ({
      isOpenFilePreviewModal: !prevState.isOpenFilePreviewModal,
    }));
  };

  closeClearAllModal = (ev) => {
    ev.preventDefault();
    this.setState({isClearAllModalOpen: false});
  };

  openClearAllModal = () => {
    this.setState({isClearAllModalOpen: true});
  };

  onConfirmClearAllModal = (ev) => {
    this.closeClearAllModal(ev);
    this.props.setStreamClearAllDiametrics();
  };

  render() {
    const {
      metrics,
      dimensions,
      setStreamTimeDefinition,
      filterTextInput,
      filteredUnassignedColumns,
      overwriteIdentifier,
      timestampType,
      onTimestampTypeChange,
      timeDefinition,
      timeDefinitionArr,
      removeStreamDiametrics,
      setMetricDimentionEditorFilterTextInput,
      dateFormats,
      isStreamUpdating,
      clearAllText,
      isRemoveDateSection,
      isRemoveMetricsSection,
    } = this.props;

    let tdStyleName = 'td-time td-timedefenision';
    if (isRemoveDateSection) {
      tdStyleName = 'td-no-time';
    }
    if (timestampType) {
      tdStyleName = 'td-time td-timedefenision td-timestamp-type';
    }

    return (
      <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        <div className="shell-col">
          <PanelHeader title="Measures & Dimensions">
            <button type="button" className="btn btn-flat btn-blue" onClick={this.openClearAllModal}>
              {clearAllText || 'Clear All'}
            </button>
            {this.state.isClearAllModalOpen && (
              <ConfirmationModal
                onClose={this.closeClearAllModal}
                onConfirm={this.onConfirmClearAllModal}
                title="Clear All"
                message="Are you sure you want to clear all measures & dimensions?"
                isOpen={this.state.isClearAllModalOpen}
              />
            )}
            {this.props.preview ? (
              <button type="button" className="btn btn-icon btn-flat btn-blue" onClick={this.previewClicked}>
                <i className="icon icn-view-icon" />
              </button>
            ) : null}
            {this.state.isOpenFilePreviewModal
              ? React.cloneElement(this.props.preview, {
                  isOpen: this.state.isOpenFilePreviewModal,
                  onClose: this.previewClicked,
                })
              : null}
          </PanelHeader>

          <div styleName="root" className="shell">
            <table styleName="table">
              <tbody>
                <tr>
                  {/* un assigned */}
                  <td rowSpan="2" styleName="left-root">
                    <div styleName="col-unassigned" className="shell-col">
                      <div styleName="title av-fields-title">Available fields</div>
                      <div styleName="search-wrapper">
                        <SearchBox
                          filter={filterTextInput}
                          isBlueBackground
                          onFilter={setMetricDimentionEditorFilterTextInput}
                        />
                      </div>

                      <DiametricsDraggableList
                        id="dmAllColumns"
                        items={filteredUnassignedColumns}
                        overwriteIdentifier={overwriteIdentifier}
                      />
                    </div>

                    <div styleName="spacer" />
                  </td>

                  {/* time */}
                  {!isRemoveDateSection && (
                    <td styleName="td-time">
                      <div styleName="col-time" className="shell-col">
                        <div styleName="title">Date</div>
                        <DiametricsDraggableList
                          id="dmDate"
                          items={timeDefinitionArr}
                          onRemoveItem={removeStreamDiametrics}
                          shouldHideScroll={TRUE}
                          overwriteIdentifier={overwriteIdentifier}
                        />
                      </div>
                    </td>
                  )}
                  <td styleName={tdStyleName}>
                    {timeDefinition && (
                      <TimeDefinition
                        timePattern={timeDefinition.timePattern}
                        timeZone={timeDefinition.timeZone}
                        isLoading={isStreamUpdating}
                        onChange={setStreamTimeDefinition}
                        dateFormats={dateFormats}
                      />
                    )}

                    {timestampType && timestampType !== 'timestamp' && (
                      <TimestampType value={timestampType} onChange={onTimestampTypeChange} />
                    )}
                  </td>
                </tr>
                <tr>
                  {/* metrics */}
                  {!isRemoveMetricsSection && (
                    <td styleName="td-diametrics">
                      <div styleName="col-diametrics" className="shell-col">
                        <div styleName="title">Measures</div>

                        <DiametricsDraggableList
                          id="dmMetrics"
                          items={metrics}
                          onRemoveItem={removeStreamDiametrics}
                          overwriteIdentifier={overwriteIdentifier}
                        />
                      </div>
                    </td>
                  )}

                  {/* dimensions */}
                  <td styleName="td-diametrics">
                    <div styleName="col-diametrics" className="shell-col">
                      <div styleName="title">Dimensions</div>

                      <DiametricsDraggableList
                        id="dmDimensions"
                        items={dimensions}
                        onRemoveItem={removeStreamDiametrics}
                        overwriteIdentifier={overwriteIdentifier}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </DragDropContext>
    );
  }
}

export default DimensionsAndMetricsEditor;
