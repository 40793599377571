/* eslint-disable no-underscore-dangle */

export const types = {
  stream: 'STREAM',
  composite: 'COMPOSITE',
  channel: 'CHANNEL',
};

export const payloadStates = {
  all: '*',
  none: '_',
  specificGroups: 'specific',
};

export const ACCESS_LIST_OPTIONS = {
  EVERYONE: {value: 'everyone', title: 'Everyone', payload: '*', info: 'All users can view the stream data'},
  PRIVATE: {value: 'private', title: 'None', payload: '_', info: 'Only you can view stream data'},
  SELECTED_GROUPS: {
    value: 'selectedGroups',
    title: 'Selected Groups',
    payload: '_',
    info: 'Only selected groups can view the stream data',
  },
};

export const ACCESS_LIST_CHANNELS_OPTIONS = {
  EVERYONE: {value: 'everyone', title: 'Everyone', payload: '*', info: 'All users can view the stream data'},
  SELECTED_GROUPS: {
    value: 'selectedGroups',
    title: 'Selected Groups',
    payload: '',
    info: 'Only selected groups can view the stream data',
  },
};

export const getAccessGroups = (accessListIds, groupsData = []) => {
  if (!accessListIds || !accessListIds.length || accessListIds[0] === ACCESS_LIST_OPTIONS.PRIVATE.payload) {
    return ACCESS_LIST_OPTIONS.PRIVATE.title;
  }
  if (accessListIds[0] === ACCESS_LIST_OPTIONS.EVERYONE.payload) {
    return ACCESS_LIST_OPTIONS.EVERYONE.title;
  }
  return groupsData.filter((group) => accessListIds.find((id) => group._id === id));
};

export const accessGroupsShortedNames = (groupsData) => {
  if (typeof groupsData === 'string') {
    return groupsData;
  }
  if (!groupsData.length) {
    return ACCESS_LIST_OPTIONS.PRIVATE.title;
  }
  if (groupsData.length === 1) {
    return groupsData[0]._name;
  }
  return `${groupsData[0]._name}, + ${groupsData.length - 1}`;
};

export const accessGroupsNames = (groupsData) => {
  if (typeof groupsData === 'string') {
    return groupsData;
  }
  if (!groupsData.length) {
    return ACCESS_LIST_OPTIONS.PRIVATE.title;
  }
  return groupsData.length > 1 ? groupsData.map((i) => i._name).join(', ') : groupsData[0]._name;
};
